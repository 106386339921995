import { Interpolation } from "@emotion/react";
import { Theme } from "@emotion/react";
import { useTheme, css } from "@emotion/react";
import { CSSProperties, ComponentProps, ReactNode } from "react";

import Select from "react-select";

type Props = ComponentProps<typeof Select> & {
  isClearAble?: boolean;
  label?: ReactNode;
  controlStyle?: CSSProperties;
  containerCss?: Interpolation<Theme>;
  origin?: string;
};
const CustomSelect = ({ styles, controlStyle, ...props }: Props) => {
  const theme = useTheme();
  return (
    <Select
      {...props}
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderRadius:
            props.origin === "isCheckoutSignupForm" ? "3px" : theme.radii.small,
          borderColor: ["isCheckoutSignupForm", "signUpForm"].includes(
            props?.origin || "",
          )
            ? "rgba(0,0,0,.25)"
            : theme.colors.inputBorder,
          fontSize: theme.fontSizes.default,
          color: theme.colors.black,
          boxShadow: "none",
          padding: props.origin === "isCheckoutSignupForm" ? "0" : "4px 6px",
          cursor: "pointer",
          backgroundColor: theme.colors.white,
          zIndex: 0,
          ":hover": {
            borderColor: ["isCheckoutSignupForm", "signUpForm"].includes(
              props?.origin || "",
            )
              ? "rgba(0,0,0,.25)"
              : theme.colors.inputBorder,
          },
          ...(controlStyle || {}),
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        option: () => ({
          padding: "0px 16px",
          height: props.origin === "isCheckoutSignupForm" ? 38 : 46,
          alignItems: "center",
          display: "flex",
          color: theme.colors.black,
          cursor: "pointer",
          fontSize: theme.fontSizes.default,
          "&:hover": {
            color: theme.colors.white,
            background: theme.colors.primary,
          },
        }),
        menuList: (base) => ({
          ...base,
          zIndex: 100,
        }),
        multiValue: (base) => ({
          ...base,
          borderRadius: theme.radii.large,
          padding: "0px 6px",
          background: theme.colors.primaryLight,
          color: theme.colors.primary,
        }),
        multiValueLabel: (base) => ({
          ...base,
          color: theme.colors.primary,
        }),
        menu: (base) => ({
          ...base,
          zIndex: 2,
        }),
        ...(styles || {}),
      }}
      // menuIsOpen={true}
    />
  );
};

export const SelectInput = ({ containerCss, label, ...rest }: Props) => {
  return label ? (
    <div css={containerCss} style={{ position: "relative" }}>
      {label && (
        <span
          css={{
            position: "absolute",
            top: -7,
            background: "white",
            left: 12,
            padding: "0px 4px",
            lineHeight: "12px",
            zIndex: 1,
          }}
        >
          <span
            css={(theme) => ({
              opacity: 0.65,
              fontSize: theme.fontSizes.small,
            })}
          >
            {label}
          </span>
        </span>
      )}
      <CustomSelect {...rest} />
    </div>
  ) : (
    <CustomSelect {...rest} />
  );
};
