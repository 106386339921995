import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import _, { toInteger } from "lodash";
import { Flex } from "components/Flex";
import { useWindowSize } from "react-use";
import { useTheme } from "@emotion/react";
import { allExploratoryOptions } from "lib/options/exploratory";
import { currencyFormatter } from "lib/currencyFormatter";
const theme = createTheme({
  palette: {
    mode: "light",
  },
  breakpoints: {},
});

export default function AreaGraph({
  areaConfig,
  plotData,
  isMonthly,
  datapoint,
}) {
  const groupedData = _.groupBy(areaConfig.data, "type");
  const [key1, key2] = Object.keys(groupedData);
  const Uitheme = useTheme();
  const XLabels = plotData.map((item) => item.label);
  const line1 = groupedData[key1].map((item) => toInteger(item.value));
  const line2 = groupedData[key2].map((item) => toInteger(item.value));
  const { height: windowHeight } = useWindowSize();
  const plotHeight = Math.max(windowHeight * 0.55, 450);
  const line3 = line1.map((item, index) => line2[index] - item);
  const differentialLabel =
    allExploratoryOptions.filter((item) => item.value === datapoint)[0].label ||
    "Differential";
  const formatValue = (value) => {
    const positive = `$${Math.abs(value).toString()}`;
    return value > 0 ? positive : "-" + positive;
  };

  return (
    <Flex css={{ width: "100%", height: "100%", background: "white" }}>
      <ThemeProvider theme={theme}>
        <LineChart
          height={toInteger(plotHeight)}
          series={[
            {
              data: line2,
              label: key2,
              area: false,
              stack: "b",
              showMark: !isMonthly,
              curve: "linear",
              color: Uitheme.colors.red,
              valueFormatter(value, context) {
                return currencyFormatter(value || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
            {
              data: line1,
              label: key1,
              area: false,
              stack: "a",
              showMark: !isMonthly,
              curve: "linear",
              type: "line",
              color: Uitheme.colors.seaGreen,
              valueFormatter(value, context) {
                return currencyFormatter(value || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
            {
              data: line3,
              label: differentialLabel,
              area: true,
              stack: "c",
              showMark: !isMonthly,
              color: Uitheme.colors.grayText,
              curve: "linear",
              valueFormatter(value, context) {
                return currencyFormatter(value || 0, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
              },
            },
          ]}
          margin={{ top: 80 }}
          xAxis={[
            {
              scaleType: "point",
              data: XLabels,
              tickInterval: function (value, index) {
                if (isMonthly) {
                  let reverseIndex = XLabels.length - 1 - index;
                  return reverseIndex % 12 === 0;
                }
                return true;
              },
              valueFormatter(value, context) {
                if (
                  context.location === "tooltip" &&
                  (isMonthly || datapoint === "buy_versus_rent_percentage")
                ) {
                  const formattedTooltipDate = new Date(
                    value,
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                  });
                  return formattedTooltipDate.replace(" ", ". ");
                }
                if (isMonthly || datapoint === "buy_versus_rent_percentage") {
                  const year = new Date(value).getFullYear().toString();
                  return year;
                }
                return value.toString();
              },
            },
          ]}
          slotProps={{
            legend: {
              itemGap: 0,
              markGap: 0,
            },
          }}
          sx={{
            width: "100%",
          }}
        />
      </ThemeProvider>
    </Flex>
  );
}
