export const ZipToCity = [
  {
    postal_code: "97367",
    full_city_name: "Lincoln City, OR",
    city_name: "Lincoln City",
  },
  {
    postal_code: "29607",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "34744",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "33021",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "30084",
    full_city_name: "Tucker, GA",
    city_name: "Tucker",
  },
  {
    postal_code: "02852",
    full_city_name: "North Kingstown, RI",
    city_name: "North Kingstown",
  },
  {
    postal_code: "72211",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "40514",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "75034",
    full_city_name: "Frisco, TX",
    city_name: "Frisco",
  },
  {
    postal_code: "45373",
    full_city_name: "Troy, OH",
    city_name: "Troy",
  },
  {
    postal_code: "12901",
    full_city_name: "Plattsburgh, NY",
    city_name: "Plattsburgh",
  },
  {
    postal_code: "37743",
    full_city_name: "Greeneville, TN",
    city_name: "Greeneville",
  },
  {
    postal_code: "78657",
    full_city_name: "Horseshoe Bay, TX",
    city_name: "Horseshoe Bay",
  },
  {
    postal_code: "43054",
    full_city_name: "New Albany, OH",
    city_name: "New Albany",
  },
  {
    postal_code: "60651",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "84404",
    full_city_name: "Ogden, UT",
    city_name: "Ogden",
  },
  {
    postal_code: "12803",
    full_city_name: "South Glens Falls, NY",
    city_name: "South Glens Falls",
  },
  {
    postal_code: "33147",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "77406",
    full_city_name: "Richmond, TX",
    city_name: "Richmond",
  },
  {
    postal_code: "29615",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "30338",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "32720",
    full_city_name: "Deland, FL",
    city_name: "Deland",
  },
  {
    postal_code: "45424",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "35215",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "38111",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "19116",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "77354",
    full_city_name: "Magnolia, TX",
    city_name: "Magnolia",
  },
  {
    postal_code: "75219",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "94801",
    full_city_name: "Richmond, CA",
    city_name: "Richmond",
  },
  {
    postal_code: "76116",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "19041",
    full_city_name: "Haverford, PA",
    city_name: "Haverford",
  },
  {
    postal_code: "21075",
    full_city_name: "Elkridge, MD",
    city_name: "Elkridge",
  },
  {
    postal_code: "94025",
    full_city_name: "Menlo Park, CA",
    city_name: "Menlo Park",
  },
  {
    postal_code: "19901",
    full_city_name: "Dover, DE",
    city_name: "Dover",
  },
  {
    postal_code: "55371",
    full_city_name: "Princeton, MN",
    city_name: "Princeton",
  },
  {
    postal_code: "33547",
    full_city_name: "Lithia, FL",
    city_name: "Lithia",
  },
  {
    postal_code: "37659",
    full_city_name: "Jonesborough, TN",
    city_name: "Jonesborough",
  },
  {
    postal_code: "89145",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "33759",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "76120",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "37830",
    full_city_name: "Oak Ridge, TN",
    city_name: "Oak Ridge",
  },
  {
    postal_code: "27712",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "02532",
    full_city_name: "Buzzards Bay, MA",
    city_name: "Buzzards Bay",
  },
  {
    postal_code: "08016",
    full_city_name: "Burlington, NJ",
    city_name: "Burlington",
  },
  {
    postal_code: "50023",
    full_city_name: "Ankeny, IA",
    city_name: "Ankeny",
  },
  {
    postal_code: "18902",
    full_city_name: "Doylestown, PA",
    city_name: "Doylestown",
  },
  {
    postal_code: "92223",
    full_city_name: "Beaumont, CA",
    city_name: "Beaumont",
  },
  {
    postal_code: "95842",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "39047",
    full_city_name: "Brandon, MS",
    city_name: "Brandon",
  },
  {
    postal_code: "29650",
    full_city_name: "Greer, SC",
    city_name: "Greer",
  },
  {
    postal_code: "28117",
    full_city_name: "Mooresville, NC",
    city_name: "Mooresville",
  },
  {
    postal_code: "89107",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "12180",
    full_city_name: "Troy, NY",
    city_name: "Troy",
  },
  {
    postal_code: "99223",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "31406",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "60089",
    full_city_name: "Buffalo Grove, IL",
    city_name: "Buffalo Grove",
  },
  {
    postal_code: "43017",
    full_city_name: "Dublin, OH",
    city_name: "Dublin",
  },
  {
    postal_code: "06840",
    full_city_name: "New Canaan, CT",
    city_name: "New Canaan",
  },
  {
    postal_code: "28278",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "22191",
    full_city_name: "Woodbridge, VA",
    city_name: "Woodbridge",
  },
  {
    postal_code: "33016",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "75024",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "33776",
    full_city_name: "Seminole, FL",
    city_name: "Seminole",
  },
  {
    postal_code: "39110",
    full_city_name: "Madison, MS",
    city_name: "Madison",
  },
  {
    postal_code: "55422",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "38002",
    full_city_name: "Arlington, TN",
    city_name: "Arlington",
  },
  {
    postal_code: "60527",
    full_city_name: "Willowbrook, IL",
    city_name: "Willowbrook",
  },
  {
    postal_code: "93561",
    full_city_name: "Tehachapi, CA",
    city_name: "Tehachapi",
  },
  {
    postal_code: "76028",
    full_city_name: "Burleson, TX",
    city_name: "Burleson",
  },
  {
    postal_code: "28206",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "33029",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "30518",
    full_city_name: "Buford, GA",
    city_name: "Buford",
  },
  {
    postal_code: "32174",
    full_city_name: "Ormond Beach, FL",
    city_name: "Ormond Beach",
  },
  {
    postal_code: "40217",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "77469",
    full_city_name: "Richmond, TX",
    city_name: "Richmond",
  },
  {
    postal_code: "78258",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "59714",
    full_city_name: "Belgrade, MT",
    city_name: "Belgrade",
  },
  {
    postal_code: "27405",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "32309",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "45331",
    full_city_name: "Greenville, OH",
    city_name: "Greenville",
  },
  {
    postal_code: "84074",
    full_city_name: "Tooele, UT",
    city_name: "Tooele",
  },
  {
    postal_code: "93230",
    full_city_name: "Hanford, CA",
    city_name: "Hanford",
  },
  {
    postal_code: "91042",
    full_city_name: "Tujunga, CA",
    city_name: "Tujunga",
  },
  {
    postal_code: "30542",
    full_city_name: "Flowery Branch, GA",
    city_name: "Flowery Branch",
  },
  {
    postal_code: "78628",
    full_city_name: "Georgetown, TX",
    city_name: "Georgetown",
  },
  {
    postal_code: "32903",
    full_city_name: "Indialantic, FL",
    city_name: "Indialantic",
  },
  {
    postal_code: "85255",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "85323",
    full_city_name: "Avondale, AZ",
    city_name: "Avondale",
  },
  {
    postal_code: "78729",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "33076",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "95340",
    full_city_name: "Merced, CA",
    city_name: "Merced",
  },
  {
    postal_code: "30306",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "60130",
    full_city_name: "Forest Park, IL",
    city_name: "Forest Park",
  },
  {
    postal_code: "90210",
    full_city_name: "Beverly Hills, CA",
    city_name: "Beverly Hills",
  },
  {
    postal_code: "55033",
    full_city_name: "Hastings, MN",
    city_name: "Hastings",
  },
  {
    postal_code: "64155",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "68122",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "78641",
    full_city_name: "Leander, TX",
    city_name: "Leander",
  },
  {
    postal_code: "76021",
    full_city_name: "Bedford, TX",
    city_name: "Bedford",
  },
  {
    postal_code: "64075",
    full_city_name: "Oak Grove, MO",
    city_name: "Oak Grove",
  },
  {
    postal_code: "40330",
    full_city_name: "Harrodsburg, KY",
    city_name: "Harrodsburg",
  },
  {
    postal_code: "43231",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "40047",
    full_city_name: "Mount Washington, KY",
    city_name: "Mount Washington",
  },
  {
    postal_code: "60804",
    full_city_name: "Cicero, IL",
    city_name: "Cicero",
  },
  {
    postal_code: "40601",
    full_city_name: "Frankfort, KY",
    city_name: "Frankfort",
  },
  {
    postal_code: "45140",
    full_city_name: "Loveland, OH",
    city_name: "Loveland",
  },
  {
    postal_code: "60302",
    full_city_name: "Oak Park, IL",
    city_name: "Oak Park",
  },
  {
    postal_code: "44035",
    full_city_name: "Elyria, OH",
    city_name: "Elyria",
  },
  {
    postal_code: "31322",
    full_city_name: "Pooler, GA",
    city_name: "Pooler",
  },
  {
    postal_code: "95828",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "28306",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "93274",
    full_city_name: "Tulare, CA",
    city_name: "Tulare",
  },
  {
    postal_code: "32828",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "21703",
    full_city_name: "Frederick, MD",
    city_name: "Frederick",
  },
  {
    postal_code: "55330",
    full_city_name: "Elk River, MN",
    city_name: "Elk River",
  },
  {
    postal_code: "33175",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "89146",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "42420",
    full_city_name: "Henderson, KY",
    city_name: "Henderson",
  },
  {
    postal_code: "96145",
    full_city_name: "Tahoe City, CA",
    city_name: "Tahoe City",
  },
  {
    postal_code: "50312",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "78738",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "44111",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "68521",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "35124",
    full_city_name: "Pelham, AL",
    city_name: "Pelham",
  },
  {
    postal_code: "43068",
    full_city_name: "Reynoldsburg, OH",
    city_name: "Reynoldsburg",
  },
  {
    postal_code: "80229",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "06492",
    full_city_name: "Wallingford, CT",
    city_name: "Wallingford",
  },
  {
    postal_code: "32819",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "75087",
    full_city_name: "Rockwall, TX",
    city_name: "Rockwall",
  },
  {
    postal_code: "89183",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "93030",
    full_city_name: "Oxnard, CA",
    city_name: "Oxnard",
  },
  {
    postal_code: "61032",
    full_city_name: "Freeport, IL",
    city_name: "Freeport",
  },
  {
    postal_code: "30316",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "72653",
    full_city_name: "Mountain Home, AR",
    city_name: "Mountain Home",
  },
  {
    postal_code: "16505",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "55128",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "60430",
    full_city_name: "Homewood, IL",
    city_name: "Homewood",
  },
  {
    postal_code: "77041",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "63116",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "33578",
    full_city_name: "Riverview, FL",
    city_name: "Riverview",
  },
  {
    postal_code: "50009",
    full_city_name: "Altoona, IA",
    city_name: "Altoona",
  },
  {
    postal_code: "46385",
    full_city_name: "Valparaiso, IN",
    city_name: "Valparaiso",
  },
  {
    postal_code: "21740",
    full_city_name: "Hagerstown, MD",
    city_name: "Hagerstown",
  },
  {
    postal_code: "32713",
    full_city_name: "Debary, FL",
    city_name: "Debary",
  },
  {
    postal_code: "33810",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "12804",
    full_city_name: "Queensbury, NY",
    city_name: "Queensbury",
  },
  {
    postal_code: "63025",
    full_city_name: "Eureka, MO",
    city_name: "Eureka",
  },
  {
    postal_code: "50124",
    full_city_name: "Huxley, IA",
    city_name: "Huxley",
  },
  {
    postal_code: "79602",
    full_city_name: "Abilene, TX",
    city_name: "Abilene",
  },
  {
    postal_code: "41011",
    full_city_name: "Covington, KY",
    city_name: "Covington",
  },
  {
    postal_code: "32206",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "27560",
    full_city_name: "Morrisville, NC",
    city_name: "Morrisville",
  },
  {
    postal_code: "75063",
    full_city_name: "Irving, TX",
    city_name: "Irving",
  },
  {
    postal_code: "77005",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "60439",
    full_city_name: "Lemont, IL",
    city_name: "Lemont",
  },
  {
    postal_code: "40504",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "63376",
    full_city_name: "Saint Peters, MO",
    city_name: "Saint Peters",
  },
  {
    postal_code: "18974",
    full_city_name: "Warminster, PA",
    city_name: "Warminster",
  },
  {
    postal_code: "95747",
    full_city_name: "Roseville, CA",
    city_name: "Roseville",
  },
  {
    postal_code: "08210",
    full_city_name: "Cape May Court House, NJ",
    city_name: "Cape May Court House",
  },
  {
    postal_code: "75167",
    full_city_name: "Waxahachie, TX",
    city_name: "Waxahachie",
  },
  {
    postal_code: "12603",
    full_city_name: "Poughkeepsie, NY",
    city_name: "Poughkeepsie",
  },
  {
    postal_code: "86315",
    full_city_name: "Prescott Valley, AZ",
    city_name: "Prescott Valley",
  },
  {
    postal_code: "33764",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "28092",
    full_city_name: "Lincolnton, NC",
    city_name: "Lincolnton",
  },
  {
    postal_code: "78216",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "28216",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "79934",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "85260",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "08004",
    full_city_name: "Atco, NJ",
    city_name: "Atco",
  },
  {
    postal_code: "29406",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "30548",
    full_city_name: "Hoschton, GA",
    city_name: "Hoschton",
  },
  {
    postal_code: "28112",
    full_city_name: "Monroe, NC",
    city_name: "Monroe",
  },
  {
    postal_code: "19426",
    full_city_name: "Collegeville, PA",
    city_name: "Collegeville",
  },
  {
    postal_code: "54703",
    full_city_name: "Eau Claire, WI",
    city_name: "Eau Claire",
  },
  {
    postal_code: "32765",
    full_city_name: "Oviedo, FL",
    city_name: "Oviedo",
  },
  {
    postal_code: "83854",
    full_city_name: "Post Falls, ID",
    city_name: "Post Falls",
  },
  {
    postal_code: "37748",
    full_city_name: "Harriman, TN",
    city_name: "Harriman",
  },
  {
    postal_code: "23236",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "60181",
    full_city_name: "Villa Park, IL",
    city_name: "Villa Park",
  },
  {
    postal_code: "30350",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "77070",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "89423",
    full_city_name: "Minden, NV",
    city_name: "Minden",
  },
  {
    postal_code: "32712",
    full_city_name: "Apopka, FL",
    city_name: "Apopka",
  },
  {
    postal_code: "12401",
    full_city_name: "Kingston, NY",
    city_name: "Kingston",
  },
  {
    postal_code: "95682",
    full_city_name: "Shingle Springs, CA",
    city_name: "Shingle Springs",
  },
  {
    postal_code: "40222",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "60047",
    full_city_name: "Lake Zurich, IL",
    city_name: "Lake Zurich",
  },
  {
    postal_code: "60513",
    full_city_name: "Brookfield, IL",
    city_name: "Brookfield",
  },
  {
    postal_code: "33050",
    full_city_name: "Marathon, FL",
    city_name: "Marathon",
  },
  {
    postal_code: "45040",
    full_city_name: "Mason, OH",
    city_name: "Mason",
  },
  {
    postal_code: "29732",
    full_city_name: "Rock Hill, SC",
    city_name: "Rock Hill",
  },
  {
    postal_code: "60478",
    full_city_name: "Country Club Hills, IL",
    city_name: "Country Club Hills",
  },
  {
    postal_code: "37615",
    full_city_name: "Johnson City, TN",
    city_name: "Johnson City",
  },
  {
    postal_code: "85345",
    full_city_name: "Peoria, AZ",
    city_name: "Peoria",
  },
  {
    postal_code: "78254",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "78373",
    full_city_name: "Port Aransas, TX",
    city_name: "Port Aransas",
  },
  {
    postal_code: "77090",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "32806",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "77351",
    full_city_name: "Livingston, TX",
    city_name: "Livingston",
  },
  {
    postal_code: "43612",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "44333",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "77003",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "91356",
    full_city_name: "Tarzana, CA",
    city_name: "Tarzana",
  },
  {
    postal_code: "77479",
    full_city_name: "Sugar Land, TX",
    city_name: "Sugar Land",
  },
  {
    postal_code: "80925",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "95838",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "32226",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "85392",
    full_city_name: "Avondale, AZ",
    city_name: "Avondale",
  },
  {
    postal_code: "06424",
    full_city_name: "East Hampton, CT",
    city_name: "East Hampton",
  },
  {
    postal_code: "85139",
    full_city_name: "Maricopa, AZ",
    city_name: "Maricopa",
  },
  {
    postal_code: "63301",
    full_city_name: "Saint Charles, MO",
    city_name: "Saint Charles",
  },
  {
    postal_code: "33145",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "30060",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "29680",
    full_city_name: "Simpsonville, SC",
    city_name: "Simpsonville",
  },
  {
    postal_code: "60616",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "33860",
    full_city_name: "Mulberry, FL",
    city_name: "Mulberry",
  },
  {
    postal_code: "88101",
    full_city_name: "Clovis, NM",
    city_name: "Clovis",
  },
  {
    postal_code: "93012",
    full_city_name: "Camarillo, CA",
    city_name: "Camarillo",
  },
  {
    postal_code: "32082",
    full_city_name: "Ponte Vedra Beach, FL",
    city_name: "Ponte Vedra Beach",
  },
  {
    postal_code: "78703",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "84790",
    full_city_name: "Saint George, UT",
    city_name: "Saint George",
  },
  {
    postal_code: "10012",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "44236",
    full_city_name: "Hudson, OH",
    city_name: "Hudson",
  },
  {
    postal_code: "96740",
    full_city_name: "Kailua Kona, HI",
    city_name: "Kailua Kona",
  },
  {
    postal_code: "85044",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "33610",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "50111",
    full_city_name: "Grimes, IA",
    city_name: "Grimes",
  },
  {
    postal_code: "74019",
    full_city_name: "Claremore, OK",
    city_name: "Claremore",
  },
  {
    postal_code: "32136",
    full_city_name: "Flagler Beach, FL",
    city_name: "Flagler Beach",
  },
  {
    postal_code: "85379",
    full_city_name: "Surprise, AZ",
    city_name: "Surprise",
  },
  {
    postal_code: "19446",
    full_city_name: "Lansdale, PA",
    city_name: "Lansdale",
  },
  {
    postal_code: "33330",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "18964",
    full_city_name: "Souderton, PA",
    city_name: "Souderton",
  },
  {
    postal_code: "94568",
    full_city_name: "Dublin, CA",
    city_name: "Dublin",
  },
  {
    postal_code: "43035",
    full_city_name: "Lewis Center, OH",
    city_name: "Lewis Center",
  },
  {
    postal_code: "27587",
    full_city_name: "Wake Forest, NC",
    city_name: "Wake Forest",
  },
  {
    postal_code: "75025",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "28712",
    full_city_name: "Brevard, NC",
    city_name: "Brevard",
  },
  {
    postal_code: "95307",
    full_city_name: "Ceres, CA",
    city_name: "Ceres",
  },
  {
    postal_code: "34996",
    full_city_name: "Stuart, FL",
    city_name: "Stuart",
  },
  {
    postal_code: "77065",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "95823",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "72714",
    full_city_name: "Bella Vista, AR",
    city_name: "Bella Vista",
  },
  {
    postal_code: "95616",
    full_city_name: "Davis, CA",
    city_name: "Davis",
  },
  {
    postal_code: "77080",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "84121",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "43147",
    full_city_name: "Pickerington, OH",
    city_name: "Pickerington",
  },
  {
    postal_code: "63379",
    full_city_name: "Troy, MO",
    city_name: "Troy",
  },
  {
    postal_code: "67501",
    full_city_name: "Hutchinson, KS",
    city_name: "Hutchinson",
  },
  {
    postal_code: "91335",
    full_city_name: "Reseda, CA",
    city_name: "Reseda",
  },
  {
    postal_code: "12061",
    full_city_name: "East Greenbush, NY",
    city_name: "East Greenbush",
  },
  {
    postal_code: "46163",
    full_city_name: "New Palestine, IN",
    city_name: "New Palestine",
  },
  {
    postal_code: "46368",
    full_city_name: "Portage, IN",
    city_name: "Portage",
  },
  {
    postal_code: "95660",
    full_city_name: "North Highlands, CA",
    city_name: "North Highlands",
  },
  {
    postal_code: "32097",
    full_city_name: "Yulee, FL",
    city_name: "Yulee",
  },
  {
    postal_code: "60060",
    full_city_name: "Mundelein, IL",
    city_name: "Mundelein",
  },
  {
    postal_code: "54660",
    full_city_name: "Tomah, WI",
    city_name: "Tomah",
  },
  {
    postal_code: "95746",
    full_city_name: "Granite Bay, CA",
    city_name: "Granite Bay",
  },
  {
    postal_code: "35242",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "16125",
    full_city_name: "Greenville, PA",
    city_name: "Greenville",
  },
  {
    postal_code: "81521",
    full_city_name: "Fruita, CO",
    city_name: "Fruita",
  },
  {
    postal_code: "34285",
    full_city_name: "Venice, FL",
    city_name: "Venice",
  },
  {
    postal_code: "74134",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "32821",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "60487",
    full_city_name: "Tinley Park, IL",
    city_name: "Tinley Park",
  },
  {
    postal_code: "45365",
    full_city_name: "Sidney, OH",
    city_name: "Sidney",
  },
  {
    postal_code: "29445",
    full_city_name: "Goose Creek, SC",
    city_name: "Goose Creek",
  },
  {
    postal_code: "78155",
    full_city_name: "Seguin, TX",
    city_name: "Seguin",
  },
  {
    postal_code: "48864",
    full_city_name: "Okemos, MI",
    city_name: "Okemos",
  },
  {
    postal_code: "85209",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "33705",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "92234",
    full_city_name: "Cathedral City, CA",
    city_name: "Cathedral City",
  },
  {
    postal_code: "33462",
    full_city_name: "Lake Worth, FL",
    city_name: "Lake Worth",
  },
  {
    postal_code: "32726",
    full_city_name: "Eustis, FL",
    city_name: "Eustis",
  },
  {
    postal_code: "56001",
    full_city_name: "Mankato, MN",
    city_name: "Mankato",
  },
  {
    postal_code: "77063",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "78250",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "68134",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "75243",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "54701",
    full_city_name: "Eau Claire, WI",
    city_name: "Eau Claire",
  },
  {
    postal_code: "76131",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "44023",
    full_city_name: "Chagrin Falls, OH",
    city_name: "Chagrin Falls",
  },
  {
    postal_code: "08075",
    full_city_name: "Riverside, NJ",
    city_name: "Riverside",
  },
  {
    postal_code: "43062",
    full_city_name: "Pataskala, OH",
    city_name: "Pataskala",
  },
  {
    postal_code: "80026",
    full_city_name: "Lafayette, CO",
    city_name: "Lafayette",
  },
  {
    postal_code: "27705",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "60436",
    full_city_name: "Joliet, IL",
    city_name: "Joliet",
  },
  {
    postal_code: "60018",
    full_city_name: "Des Plaines, IL",
    city_name: "Des Plaines",
  },
  {
    postal_code: "38555",
    full_city_name: "Crossville, TN",
    city_name: "Crossville",
  },
  {
    postal_code: "45241",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "17111",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "33193",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "91331",
    full_city_name: "Pacoima, CA",
    city_name: "Pacoima",
  },
  {
    postal_code: "77382",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "19734",
    full_city_name: "Townsend, DE",
    city_name: "Townsend",
  },
  {
    postal_code: "93263",
    full_city_name: "Shafter, CA",
    city_name: "Shafter",
  },
  {
    postal_code: "86314",
    full_city_name: "Prescott Valley, AZ",
    city_name: "Prescott Valley",
  },
  {
    postal_code: "68130",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "33169",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "85138",
    full_city_name: "Maricopa, AZ",
    city_name: "Maricopa",
  },
  {
    postal_code: "72450",
    full_city_name: "Paragould, AR",
    city_name: "Paragould",
  },
  {
    postal_code: "77062",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "33859",
    full_city_name: "Lake Wales, FL",
    city_name: "Lake Wales",
  },
  {
    postal_code: "76010",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "97756",
    full_city_name: "Redmond, OR",
    city_name: "Redmond",
  },
  {
    postal_code: "15044",
    full_city_name: "Gibsonia, PA",
    city_name: "Gibsonia",
  },
  {
    postal_code: "40258",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "22310",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "34446",
    full_city_name: "Homosassa, FL",
    city_name: "Homosassa",
  },
  {
    postal_code: "85614",
    full_city_name: "Green Valley, AZ",
    city_name: "Green Valley",
  },
  {
    postal_code: "85020",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "45458",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "76227",
    full_city_name: "Aubrey, TX",
    city_name: "Aubrey",
  },
  {
    postal_code: "97759",
    full_city_name: "Sisters, OR",
    city_name: "Sisters",
  },
  {
    postal_code: "54521",
    full_city_name: "Eagle River, WI",
    city_name: "Eagle River",
  },
  {
    postal_code: "78201",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "45227",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "32244",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "60304",
    full_city_name: "Oak Park, IL",
    city_name: "Oak Park",
  },
  {
    postal_code: "55423",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "47240",
    full_city_name: "Greensburg, IN",
    city_name: "Greensburg",
  },
  {
    postal_code: "17319",
    full_city_name: "Etters, PA",
    city_name: "Etters",
  },
  {
    postal_code: "28315",
    full_city_name: "Aberdeen, NC",
    city_name: "Aberdeen",
  },
  {
    postal_code: "72764",
    full_city_name: "Springdale, AR",
    city_name: "Springdale",
  },
  {
    postal_code: "84107",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "34772",
    full_city_name: "Saint Cloud, FL",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "60156",
    full_city_name: "Lake In The Hills, IL",
    city_name: "Lake In The Hills",
  },
  {
    postal_code: "98908",
    full_city_name: "Yakima, WA",
    city_name: "Yakima",
  },
  {
    postal_code: "39465",
    full_city_name: "Petal, MS",
    city_name: "Petal",
  },
  {
    postal_code: "60655",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "29745",
    full_city_name: "York, SC",
    city_name: "York",
  },
  {
    postal_code: "77043",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "94401",
    full_city_name: "San Mateo, CA",
    city_name: "San Mateo",
  },
  {
    postal_code: "90047",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "92210",
    full_city_name: "Indian Wells, CA",
    city_name: "Indian Wells",
  },
  {
    postal_code: "95667",
    full_city_name: "Placerville, CA",
    city_name: "Placerville",
  },
  {
    postal_code: "68022",
    full_city_name: "Elkhorn, NE",
    city_name: "Elkhorn",
  },
  {
    postal_code: "85206",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "34135",
    full_city_name: "Bonita Springs, FL",
    city_name: "Bonita Springs",
  },
  {
    postal_code: "80534",
    full_city_name: "Johnstown, CO",
    city_name: "Johnstown",
  },
  {
    postal_code: "52501",
    full_city_name: "Ottumwa, IA",
    city_name: "Ottumwa",
  },
  {
    postal_code: "33609",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "46237",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "85629",
    full_city_name: "Sahuarita, AZ",
    city_name: "Sahuarita",
  },
  {
    postal_code: "32220",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "33948",
    full_city_name: "Port Charlotte, FL",
    city_name: "Port Charlotte",
  },
  {
    postal_code: "41042",
    full_city_name: "Florence, KY",
    city_name: "Florence",
  },
  {
    postal_code: "33611",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "03060",
    full_city_name: "Nashua, NH",
    city_name: "Nashua",
  },
  {
    postal_code: "84108",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "94505",
    full_city_name: "Discovery Bay, CA",
    city_name: "Discovery Bay",
  },
  {
    postal_code: "37066",
    full_city_name: "Gallatin, TN",
    city_name: "Gallatin",
  },
  {
    postal_code: "56601",
    full_city_name: "Bemidji, MN",
    city_name: "Bemidji",
  },
  {
    postal_code: "43214",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "33025",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "79705",
    full_city_name: "Midland, TX",
    city_name: "Midland",
  },
  {
    postal_code: "99217",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "78230",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "17104",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "43537",
    full_city_name: "Maumee, OH",
    city_name: "Maumee",
  },
  {
    postal_code: "89135",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "33617",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "76522",
    full_city_name: "Copperas Cove, TX",
    city_name: "Copperas Cove",
  },
  {
    postal_code: "32065",
    full_city_name: "Orange Park, FL",
    city_name: "Orange Park",
  },
  {
    postal_code: "93940",
    full_city_name: "Monterey, CA",
    city_name: "Monterey",
  },
  {
    postal_code: "60605",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "93221",
    full_city_name: "Exeter, CA",
    city_name: "Exeter",
  },
  {
    postal_code: "81503",
    full_city_name: "Grand Junction, CO",
    city_name: "Grand Junction",
  },
  {
    postal_code: "85029",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "60015",
    full_city_name: "Deerfield, IL",
    city_name: "Deerfield",
  },
  {
    postal_code: "33176",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "17057",
    full_city_name: "Middletown, PA",
    city_name: "Middletown",
  },
  {
    postal_code: "32963",
    full_city_name: "Vero Beach, FL",
    city_name: "Vero Beach",
  },
  {
    postal_code: "21122",
    full_city_name: "Pasadena, MD",
    city_name: "Pasadena",
  },
  {
    postal_code: "77021",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "43229",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "92883",
    full_city_name: "Corona, CA",
    city_name: "Corona",
  },
  {
    postal_code: "70005",
    full_city_name: "Metairie, LA",
    city_name: "Metairie",
  },
  {
    postal_code: "55405",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "33715",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "19934",
    full_city_name: "Camden Wyoming, DE",
    city_name: "Camden Wyoming",
  },
  {
    postal_code: "29456",
    full_city_name: "Ladson, SC",
    city_name: "Ladson",
  },
  {
    postal_code: "55445",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "17901",
    full_city_name: "Pottsville, PA",
    city_name: "Pottsville",
  },
  {
    postal_code: "74011",
    full_city_name: "Broken Arrow, OK",
    city_name: "Broken Arrow",
  },
  {
    postal_code: "92392",
    full_city_name: "Victorville, CA",
    city_name: "Victorville",
  },
  {
    postal_code: "76177",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "94062",
    full_city_name: "Redwood City, CA",
    city_name: "Redwood City",
  },
  {
    postal_code: "37923",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "29566",
    full_city_name: "Little River, SC",
    city_name: "Little River",
  },
  {
    postal_code: "95683",
    full_city_name: "Sloughhouse, CA",
    city_name: "Sloughhouse",
  },
  {
    postal_code: "19401",
    full_city_name: "Norristown, PA",
    city_name: "Norristown",
  },
  {
    postal_code: "33446",
    full_city_name: "Delray Beach, FL",
    city_name: "Delray Beach",
  },
  {
    postal_code: "85719",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "92251",
    full_city_name: "Imperial, CA",
    city_name: "Imperial",
  },
  {
    postal_code: "19808",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "60050",
    full_city_name: "Mchenry, IL",
    city_name: "Mchenry",
  },
  {
    postal_code: "11216",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "93023",
    full_city_name: "Ojai, CA",
    city_name: "Ojai",
  },
  {
    postal_code: "93304",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "37849",
    full_city_name: "Powell, TN",
    city_name: "Powell",
  },
  {
    postal_code: "91504",
    full_city_name: "Burbank, CA",
    city_name: "Burbank",
  },
  {
    postal_code: "32908",
    full_city_name: "Palm Bay, FL",
    city_name: "Palm Bay",
  },
  {
    postal_code: "77536",
    full_city_name: "Deer Park, TX",
    city_name: "Deer Park",
  },
  {
    postal_code: "70065",
    full_city_name: "Kenner, LA",
    city_name: "Kenner",
  },
  {
    postal_code: "94547",
    full_city_name: "Hercules, CA",
    city_name: "Hercules",
  },
  {
    postal_code: "77057",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "92395",
    full_city_name: "Victorville, CA",
    city_name: "Victorville",
  },
  {
    postal_code: "33812",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "30047",
    full_city_name: "Lilburn, GA",
    city_name: "Lilburn",
  },
  {
    postal_code: "02657",
    full_city_name: "Provincetown, MA",
    city_name: "Provincetown",
  },
  {
    postal_code: "60012",
    full_city_name: "Crystal Lake, IL",
    city_name: "Crystal Lake",
  },
  {
    postal_code: "92688",
    full_city_name: "Rancho Santa Margarita, CA",
    city_name: "Rancho Santa Margarita",
  },
  {
    postal_code: "55123",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "50323",
    full_city_name: "Urbandale, IA",
    city_name: "Urbandale",
  },
  {
    postal_code: "34116",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "33054",
    full_city_name: "Opa Locka, FL",
    city_name: "Opa Locka",
  },
  {
    postal_code: "93004",
    full_city_name: "Ventura, CA",
    city_name: "Ventura",
  },
  {
    postal_code: "75249",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "40383",
    full_city_name: "Versailles, KY",
    city_name: "Versailles",
  },
  {
    postal_code: "15090",
    full_city_name: "Wexford, PA",
    city_name: "Wexford",
  },
  {
    postal_code: "55040",
    full_city_name: "Isanti, MN",
    city_name: "Isanti",
  },
  {
    postal_code: "44145",
    full_city_name: "Westlake, OH",
    city_name: "Westlake",
  },
  {
    postal_code: "93311",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "44691",
    full_city_name: "Wooster, OH",
    city_name: "Wooster",
  },
  {
    postal_code: "60007",
    full_city_name: "Elk Grove Village, IL",
    city_name: "Elk Grove Village",
  },
  {
    postal_code: "30024",
    full_city_name: "Suwanee, GA",
    city_name: "Suwanee",
  },
  {
    postal_code: "89031",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "06413",
    full_city_name: "Clinton, CT",
    city_name: "Clinton",
  },
  {
    postal_code: "46228",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "77301",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "43228",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "18976",
    full_city_name: "Warrington, PA",
    city_name: "Warrington",
  },
  {
    postal_code: "19607",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "59105",
    full_city_name: "Billings, MT",
    city_name: "Billings",
  },
  {
    postal_code: "33174",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "75160",
    full_city_name: "Terrell, TX",
    city_name: "Terrell",
  },
  {
    postal_code: "32257",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "93065",
    full_city_name: "Simi Valley, CA",
    city_name: "Simi Valley",
  },
  {
    postal_code: "33990",
    full_city_name: "Cape Coral, FL",
    city_name: "Cape Coral",
  },
  {
    postal_code: "60175",
    full_city_name: "Saint Charles, IL",
    city_name: "Saint Charles",
  },
  {
    postal_code: "63122",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "92530",
    full_city_name: "Lake Elsinore, CA",
    city_name: "Lake Elsinore",
  },
  {
    postal_code: "74014",
    full_city_name: "Broken Arrow, OK",
    city_name: "Broken Arrow",
  },
  {
    postal_code: "74403",
    full_city_name: "Muskogee, OK",
    city_name: "Muskogee",
  },
  {
    postal_code: "77578",
    full_city_name: "Manvel, TX",
    city_name: "Manvel",
  },
  {
    postal_code: "37921",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "28311",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "55406",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "84117",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "76002",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "33480",
    full_city_name: "Palm Beach, FL",
    city_name: "Palm Beach",
  },
  {
    postal_code: "34234",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "84047",
    full_city_name: "Midvale, UT",
    city_name: "Midvale",
  },
  {
    postal_code: "34741",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "84009",
    full_city_name: "South Jordan, UT",
    city_name: "South Jordan",
  },
  {
    postal_code: "08080",
    full_city_name: "Sewell, NJ",
    city_name: "Sewell",
  },
  {
    postal_code: "40356",
    full_city_name: "Nicholasville, KY",
    city_name: "Nicholasville",
  },
  {
    postal_code: "45244",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "74135",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "84065",
    full_city_name: "Riverton, UT",
    city_name: "Riverton",
  },
  {
    postal_code: "08043",
    full_city_name: "Voorhees, NJ",
    city_name: "Voorhees",
  },
  {
    postal_code: "06514",
    full_city_name: "Hamden, CT",
    city_name: "Hamden",
  },
  {
    postal_code: "28741",
    full_city_name: "Highlands, NC",
    city_name: "Highlands",
  },
  {
    postal_code: "44070",
    full_city_name: "North Olmsted, OH",
    city_name: "North Olmsted",
  },
  {
    postal_code: "40509",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "74104",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "21113",
    full_city_name: "Odenton, MD",
    city_name: "Odenton",
  },
  {
    postal_code: "19150",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "55309",
    full_city_name: "Big Lake, MN",
    city_name: "Big Lake",
  },
  {
    postal_code: "28625",
    full_city_name: "Statesville, NC",
    city_name: "Statesville",
  },
  {
    postal_code: "78633",
    full_city_name: "Georgetown, TX",
    city_name: "Georgetown",
  },
  {
    postal_code: "78640",
    full_city_name: "Kyle, TX",
    city_name: "Kyle",
  },
  {
    postal_code: "29708",
    full_city_name: "Fort Mill, SC",
    city_name: "Fort Mill",
  },
  {
    postal_code: "45377",
    full_city_name: "Vandalia, OH",
    city_name: "Vandalia",
  },
  {
    postal_code: "78218",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "19081",
    full_city_name: "Swarthmore, PA",
    city_name: "Swarthmore",
  },
  {
    postal_code: "85251",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "45248",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "60084",
    full_city_name: "Wauconda, IL",
    city_name: "Wauconda",
  },
  {
    postal_code: "55434",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "94110",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "77092",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "76133",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "28786",
    full_city_name: "Waynesville, NC",
    city_name: "Waynesville",
  },
  {
    postal_code: "79911",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "33317",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "08360",
    full_city_name: "Vineland, NJ",
    city_name: "Vineland",
  },
  {
    postal_code: "28213",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "22314",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "80831",
    full_city_name: "Peyton, CO",
    city_name: "Peyton",
  },
  {
    postal_code: "95020",
    full_city_name: "Gilroy, CA",
    city_name: "Gilroy",
  },
  {
    postal_code: "77377",
    full_city_name: "Tomball, TX",
    city_name: "Tomball",
  },
  {
    postal_code: "60620",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "84015",
    full_city_name: "Clearfield, UT",
    city_name: "Clearfield",
  },
  {
    postal_code: "77449",
    full_city_name: "Katy, TX",
    city_name: "Katy",
  },
  {
    postal_code: "32086",
    full_city_name: "Saint Augustine, FL",
    city_name: "Saint Augustine",
  },
  {
    postal_code: "60005",
    full_city_name: "Arlington Heights, IL",
    city_name: "Arlington Heights",
  },
  {
    postal_code: "32829",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "93534",
    full_city_name: "Lancaster, CA",
    city_name: "Lancaster",
  },
  {
    postal_code: "85042",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "78223",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "20151",
    full_city_name: "Chantilly, VA",
    city_name: "Chantilly",
  },
  {
    postal_code: "45459",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "45211",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "11228",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "95126",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "15218",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "95356",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "91350",
    full_city_name: "Santa Clarita, CA",
    city_name: "Santa Clarita",
  },
  {
    postal_code: "03301",
    full_city_name: "Concord, NH",
    city_name: "Concord",
  },
  {
    postal_code: "93010",
    full_city_name: "Camarillo, CA",
    city_name: "Camarillo",
  },
  {
    postal_code: "92336",
    full_city_name: "Fontana, CA",
    city_name: "Fontana",
  },
  {
    postal_code: "34711",
    full_city_name: "Clermont, FL",
    city_name: "Clermont",
  },
  {
    postal_code: "71006",
    full_city_name: "Benton, LA",
    city_name: "Benton",
  },
  {
    postal_code: "33761",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "91381",
    full_city_name: "Stevenson Ranch, CA",
    city_name: "Stevenson Ranch",
  },
  {
    postal_code: "37863",
    full_city_name: "Pigeon Forge, TN",
    city_name: "Pigeon Forge",
  },
  {
    postal_code: "71037",
    full_city_name: "Haughton, LA",
    city_name: "Haughton",
  },
  {
    postal_code: "27513",
    full_city_name: "Cary, NC",
    city_name: "Cary",
  },
  {
    postal_code: "33606",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "75089",
    full_city_name: "Rowlett, TX",
    city_name: "Rowlett",
  },
  {
    postal_code: "32962",
    full_city_name: "Vero Beach, FL",
    city_name: "Vero Beach",
  },
  {
    postal_code: "40515",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "37206",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "19425",
    full_city_name: "Chester Springs, PA",
    city_name: "Chester Springs",
  },
  {
    postal_code: "33467",
    full_city_name: "Lake Worth, FL",
    city_name: "Lake Worth",
  },
  {
    postal_code: "85050",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "80525",
    full_city_name: "Fort Collins, CO",
    city_name: "Fort Collins",
  },
  {
    postal_code: "84092",
    full_city_name: "Sandy, UT",
    city_name: "Sandy",
  },
  {
    postal_code: "60438",
    full_city_name: "Lansing, IL",
    city_name: "Lansing",
  },
  {
    postal_code: "43113",
    full_city_name: "Circleville, OH",
    city_name: "Circleville",
  },
  {
    postal_code: "56345",
    full_city_name: "Little Falls, MN",
    city_name: "Little Falls",
  },
  {
    postal_code: "20152",
    full_city_name: "Chantilly, VA",
    city_name: "Chantilly",
  },
  {
    postal_code: "60638",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "90620",
    full_city_name: "Buena Park, CA",
    city_name: "Buena Park",
  },
  {
    postal_code: "75232",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "55102",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "85268",
    full_city_name: "Fountain Hills, AZ",
    city_name: "Fountain Hills",
  },
  {
    postal_code: "61761",
    full_city_name: "Normal, IL",
    city_name: "Normal",
  },
  {
    postal_code: "77056",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "28227",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "78212",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "89030",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "10075",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "61615",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "48118",
    full_city_name: "Chelsea, MI",
    city_name: "Chelsea",
  },
  {
    postal_code: "56377",
    full_city_name: "Sartell, MN",
    city_name: "Sartell",
  },
  {
    postal_code: "03076",
    full_city_name: "Pelham, NH",
    city_name: "Pelham",
  },
  {
    postal_code: "28516",
    full_city_name: "Beaufort, NC",
    city_name: "Beaufort",
  },
  {
    postal_code: "64015",
    full_city_name: "Blue Springs, MO",
    city_name: "Blue Springs",
  },
  {
    postal_code: "84104",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "08243",
    full_city_name: "Sea Isle City, NJ",
    city_name: "Sea Isle City",
  },
  {
    postal_code: "72701",
    full_city_name: "Fayetteville, AR",
    city_name: "Fayetteville",
  },
  {
    postal_code: "29640",
    full_city_name: "Easley, SC",
    city_name: "Easley",
  },
  {
    postal_code: "23168",
    full_city_name: "Toano, VA",
    city_name: "Toano",
  },
  {
    postal_code: "44146",
    full_city_name: "Bedford, OH",
    city_name: "Bedford",
  },
  {
    postal_code: "46383",
    full_city_name: "Valparaiso, IN",
    city_name: "Valparaiso",
  },
  {
    postal_code: "33301",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "89406",
    full_city_name: "Fallon, NV",
    city_name: "Fallon",
  },
  {
    postal_code: "89138",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "40299",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "06410",
    full_city_name: "Cheshire, CT",
    city_name: "Cheshire",
  },
  {
    postal_code: "63069",
    full_city_name: "Pacific, MO",
    city_name: "Pacific",
  },
  {
    postal_code: "92648",
    full_city_name: "Huntington Beach, CA",
    city_name: "Huntington Beach",
  },
  {
    postal_code: "90069",
    full_city_name: "West Hollywood, CA",
    city_name: "West Hollywood",
  },
  {
    postal_code: "27707",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "90065",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "92592",
    full_city_name: "Temecula, CA",
    city_name: "Temecula",
  },
  {
    postal_code: "32246",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "60491",
    full_city_name: "Homer Glen, IL",
    city_name: "Homer Glen",
  },
  {
    postal_code: "75241",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "40324",
    full_city_name: "Georgetown, KY",
    city_name: "Georgetown",
  },
  {
    postal_code: "19111",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "90024",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "14063",
    full_city_name: "Fredonia, NY",
    city_name: "Fredonia",
  },
  {
    postal_code: "40241",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "48130",
    full_city_name: "Dexter, MI",
    city_name: "Dexter",
  },
  {
    postal_code: "60608",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "77027",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "34442",
    full_city_name: "Hernando, FL",
    city_name: "Hernando",
  },
  {
    postal_code: "95035",
    full_city_name: "Milpitas, CA",
    city_name: "Milpitas",
  },
  {
    postal_code: "47933",
    full_city_name: "Crawfordsville, IN",
    city_name: "Crawfordsville",
  },
  {
    postal_code: "70072",
    full_city_name: "Marrero, LA",
    city_name: "Marrero",
  },
  {
    postal_code: "33534",
    full_city_name: "Gibsonton, FL",
    city_name: "Gibsonton",
  },
  {
    postal_code: "61008",
    full_city_name: "Belvidere, IL",
    city_name: "Belvidere",
  },
  {
    postal_code: "48160",
    full_city_name: "Milan, MI",
    city_name: "Milan",
  },
  {
    postal_code: "20136",
    full_city_name: "Bristow, VA",
    city_name: "Bristow",
  },
  {
    postal_code: "60901",
    full_city_name: "Kankakee, IL",
    city_name: "Kankakee",
  },
  {
    postal_code: "08402",
    full_city_name: "Margate City, NJ",
    city_name: "Margate City",
  },
  {
    postal_code: "44052",
    full_city_name: "Lorain, OH",
    city_name: "Lorain",
  },
  {
    postal_code: "44129",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "85233",
    full_city_name: "Gilbert, AZ",
    city_name: "Gilbert",
  },
  {
    postal_code: "08088",
    full_city_name: "Vincentown, NJ",
    city_name: "Vincentown",
  },
  {
    postal_code: "34112",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "64119",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "84093",
    full_city_name: "Sandy, UT",
    city_name: "Sandy",
  },
  {
    postal_code: "60467",
    full_city_name: "Orland Park, IL",
    city_name: "Orland Park",
  },
  {
    postal_code: "10028",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "72756",
    full_city_name: "Rogers, AR",
    city_name: "Rogers",
  },
  {
    postal_code: "18337",
    full_city_name: "Milford, PA",
    city_name: "Milford",
  },
  {
    postal_code: "31407",
    full_city_name: "Port Wentworth, GA",
    city_name: "Port Wentworth",
  },
  {
    postal_code: "19709",
    full_city_name: "Middletown, DE",
    city_name: "Middletown",
  },
  {
    postal_code: "33401",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "19438",
    full_city_name: "Harleysville, PA",
    city_name: "Harleysville",
  },
  {
    postal_code: "22932",
    full_city_name: "Crozet, VA",
    city_name: "Crozet",
  },
  {
    postal_code: "34685",
    full_city_name: "Palm Harbor, FL",
    city_name: "Palm Harbor",
  },
  {
    postal_code: "33952",
    full_city_name: "Port Charlotte, FL",
    city_name: "Port Charlotte",
  },
  {
    postal_code: "33056",
    full_city_name: "Miami Gardens, FL",
    city_name: "Miami Gardens",
  },
  {
    postal_code: "33511",
    full_city_name: "Brandon, FL",
    city_name: "Brandon",
  },
  {
    postal_code: "30666",
    full_city_name: "Statham, GA",
    city_name: "Statham",
  },
  {
    postal_code: "34654",
    full_city_name: "New Port Richey, FL",
    city_name: "New Port Richey",
  },
  {
    postal_code: "62034",
    full_city_name: "Glen Carbon, IL",
    city_name: "Glen Carbon",
  },
  {
    postal_code: "08053",
    full_city_name: "Marlton, NJ",
    city_name: "Marlton",
  },
  {
    postal_code: "33314",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "40517",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "91362",
    full_city_name: "Thousand Oaks, CA",
    city_name: "Thousand Oaks",
  },
  {
    postal_code: "95370",
    full_city_name: "Sonora, CA",
    city_name: "Sonora",
  },
  {
    postal_code: "60610",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "92691",
    full_city_name: "Mission Viejo, CA",
    city_name: "Mission Viejo",
  },
  {
    postal_code: "95205",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "37620",
    full_city_name: "Bristol, TN",
    city_name: "Bristol",
  },
  {
    postal_code: "33413",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "60026",
    full_city_name: "Glenview, IL",
    city_name: "Glenview",
  },
  {
    postal_code: "68516",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "21771",
    full_city_name: "Mount Airy, MD",
    city_name: "Mount Airy",
  },
  {
    postal_code: "34610",
    full_city_name: "Spring Hill, FL",
    city_name: "Spring Hill",
  },
  {
    postal_code: "78737",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "17019",
    full_city_name: "Dillsburg, PA",
    city_name: "Dillsburg",
  },
  {
    postal_code: "28358",
    full_city_name: "Lumberton, NC",
    city_name: "Lumberton",
  },
  {
    postal_code: "19135",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "75227",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "95361",
    full_city_name: "Oakdale, CA",
    city_name: "Oakdale",
  },
  {
    postal_code: "19006",
    full_city_name: "Huntingdon Valley, PA",
    city_name: "Huntingdon Valley",
  },
  {
    postal_code: "33498",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "89123",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "60455",
    full_city_name: "Bridgeview, IL",
    city_name: "Bridgeview",
  },
  {
    postal_code: "50320",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "44012",
    full_city_name: "Avon Lake, OH",
    city_name: "Avon Lake",
  },
  {
    postal_code: "28601",
    full_city_name: "Hickory, NC",
    city_name: "Hickory",
  },
  {
    postal_code: "32766",
    full_city_name: "Oviedo, FL",
    city_name: "Oviedo",
  },
  {
    postal_code: "19454",
    full_city_name: "North Wales, PA",
    city_name: "North Wales",
  },
  {
    postal_code: "33936",
    full_city_name: "Lehigh Acres, FL",
    city_name: "Lehigh Acres",
  },
  {
    postal_code: "28104",
    full_city_name: "Matthews, NC",
    city_name: "Matthews",
  },
  {
    postal_code: "13905",
    full_city_name: "Binghamton, NY",
    city_name: "Binghamton",
  },
  {
    postal_code: "77083",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "84098",
    full_city_name: "Park City, UT",
    city_name: "Park City",
  },
  {
    postal_code: "89121",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "95632",
    full_city_name: "Galt, CA",
    city_name: "Galt",
  },
  {
    postal_code: "43224",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "43078",
    full_city_name: "Urbana, OH",
    city_name: "Urbana",
  },
  {
    postal_code: "30153",
    full_city_name: "Rockmart, GA",
    city_name: "Rockmart",
  },
  {
    postal_code: "32968",
    full_city_name: "Vero Beach, FL",
    city_name: "Vero Beach",
  },
  {
    postal_code: "30327",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "34102",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "77095",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "02703",
    full_city_name: "Attleboro, MA",
    city_name: "Attleboro",
  },
  {
    postal_code: "19121",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "74105",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "77084",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "60502",
    full_city_name: "Aurora, IL",
    city_name: "Aurora",
  },
  {
    postal_code: "15146",
    full_city_name: "Monroeville, PA",
    city_name: "Monroeville",
  },
  {
    postal_code: "77049",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "60120",
    full_city_name: "Elgin, IL",
    city_name: "Elgin",
  },
  {
    postal_code: "17112",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "30501",
    full_city_name: "Gainesville, GA",
    city_name: "Gainesville",
  },
  {
    postal_code: "33172",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "34434",
    full_city_name: "Dunnellon, FL",
    city_name: "Dunnellon",
  },
  {
    postal_code: "77493",
    full_city_name: "Katy, TX",
    city_name: "Katy",
  },
  {
    postal_code: "12182",
    full_city_name: "Troy, NY",
    city_name: "Troy",
  },
  {
    postal_code: "33470",
    full_city_name: "Loxahatchee, FL",
    city_name: "Loxahatchee",
  },
  {
    postal_code: "29906",
    full_city_name: "Beaufort, SC",
    city_name: "Beaufort",
  },
  {
    postal_code: "18940",
    full_city_name: "Newtown, PA",
    city_name: "Newtown",
  },
  {
    postal_code: "60042",
    full_city_name: "Island Lake, IL",
    city_name: "Island Lake",
  },
  {
    postal_code: "43615",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "72076",
    full_city_name: "Jacksonville, AR",
    city_name: "Jacksonville",
  },
  {
    postal_code: "44839",
    full_city_name: "Huron, OH",
    city_name: "Huron",
  },
  {
    postal_code: "19525",
    full_city_name: "Gilbertsville, PA",
    city_name: "Gilbertsville",
  },
  {
    postal_code: "15226",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "89521",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "60419",
    full_city_name: "Dolton, IL",
    city_name: "Dolton",
  },
  {
    postal_code: "77357",
    full_city_name: "New Caney, TX",
    city_name: "New Caney",
  },
  {
    postal_code: "27284",
    full_city_name: "Kernersville, NC",
    city_name: "Kernersville",
  },
  {
    postal_code: "06095",
    full_city_name: "Windsor, CT",
    city_name: "Windsor",
  },
  {
    postal_code: "08505",
    full_city_name: "Bordentown, NJ",
    city_name: "Bordentown",
  },
  {
    postal_code: "61108",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "63084",
    full_city_name: "Union, MO",
    city_name: "Union",
  },
  {
    postal_code: "32958",
    full_city_name: "Sebastian, FL",
    city_name: "Sebastian",
  },
  {
    postal_code: "60544",
    full_city_name: "Plainfield, IL",
    city_name: "Plainfield",
  },
  {
    postal_code: "19406",
    full_city_name: "King Of Prussia, PA",
    city_name: "King Of Prussia",
  },
  {
    postal_code: "72703",
    full_city_name: "Fayetteville, AR",
    city_name: "Fayetteville",
  },
  {
    postal_code: "80538",
    full_city_name: "Loveland, CO",
    city_name: "Loveland",
  },
  {
    postal_code: "21921",
    full_city_name: "Elkton, MD",
    city_name: "Elkton",
  },
  {
    postal_code: "08094",
    full_city_name: "Williamstown, NJ",
    city_name: "Williamstown",
  },
  {
    postal_code: "10038",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "95351",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "78702",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "62959",
    full_city_name: "Marion, IL",
    city_name: "Marion",
  },
  {
    postal_code: "93021",
    full_city_name: "Moorpark, CA",
    city_name: "Moorpark",
  },
  {
    postal_code: "28210",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "33311",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "76063",
    full_city_name: "Mansfield, TX",
    city_name: "Mansfield",
  },
  {
    postal_code: "11234",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "33543",
    full_city_name: "Wesley Chapel, FL",
    city_name: "Wesley Chapel",
  },
  {
    postal_code: "77385",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "19145",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "60448",
    full_city_name: "Mokena, IL",
    city_name: "Mokena",
  },
  {
    postal_code: "72718",
    full_city_name: "Cave Springs, AR",
    city_name: "Cave Springs",
  },
  {
    postal_code: "28115",
    full_city_name: "Mooresville, NC",
    city_name: "Mooresville",
  },
  {
    postal_code: "45069",
    full_city_name: "West Chester, OH",
    city_name: "West Chester",
  },
  {
    postal_code: "93535",
    full_city_name: "Lancaster, CA",
    city_name: "Lancaster",
  },
  {
    postal_code: "34638",
    full_city_name: "Land O Lakes, FL",
    city_name: "Land O Lakes",
  },
  {
    postal_code: "03303",
    full_city_name: "Concord, NH",
    city_name: "Concord",
  },
  {
    postal_code: "60459",
    full_city_name: "Burbank, IL",
    city_name: "Burbank",
  },
  {
    postal_code: "63304",
    full_city_name: "Saint Charles, MO",
    city_name: "Saint Charles",
  },
  {
    postal_code: "12203",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "55117",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "88310",
    full_city_name: "Alamogordo, NM",
    city_name: "Alamogordo",
  },
  {
    postal_code: "60172",
    full_city_name: "Roselle, IL",
    city_name: "Roselle",
  },
  {
    postal_code: "74063",
    full_city_name: "Sand Springs, OK",
    city_name: "Sand Springs",
  },
  {
    postal_code: "08540",
    full_city_name: "Princeton, NJ",
    city_name: "Princeton",
  },
  {
    postal_code: "71111",
    full_city_name: "Bossier City, LA",
    city_name: "Bossier City",
  },
  {
    postal_code: "50322",
    full_city_name: "Urbandale, IA",
    city_name: "Urbandale",
  },
  {
    postal_code: "30030",
    full_city_name: "Decatur, GA",
    city_name: "Decatur",
  },
  {
    postal_code: "19020",
    full_city_name: "Bensalem, PA",
    city_name: "Bensalem",
  },
  {
    postal_code: "96161",
    full_city_name: "Truckee, CA",
    city_name: "Truckee",
  },
  {
    postal_code: "33138",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "30605",
    full_city_name: "Athens, GA",
    city_name: "Athens",
  },
  {
    postal_code: "35004",
    full_city_name: "Moody, AL",
    city_name: "Moody",
  },
  {
    postal_code: "77099",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "20171",
    full_city_name: "Herndon, VA",
    city_name: "Herndon",
  },
  {
    postal_code: "45419",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "12590",
    full_city_name: "Wappingers Falls, NY",
    city_name: "Wappingers Falls",
  },
  {
    postal_code: "60639",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "22408",
    full_city_name: "Fredericksburg, VA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "84105",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "27959",
    full_city_name: "Nags Head, NC",
    city_name: "Nags Head",
  },
  {
    postal_code: "60657",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "92805",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "38018",
    full_city_name: "Cordova, TN",
    city_name: "Cordova",
  },
  {
    postal_code: "78704",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "28277",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "60067",
    full_city_name: "Palatine, IL",
    city_name: "Palatine",
  },
  {
    postal_code: "28411",
    full_city_name: "Wilmington, NC",
    city_name: "Wilmington",
  },
  {
    postal_code: "20744",
    full_city_name: "Fort Washington, MD",
    city_name: "Fort Washington",
  },
  {
    postal_code: "08619",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "85704",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "53590",
    full_city_name: "Sun Prairie, WI",
    city_name: "Sun Prairie",
  },
  {
    postal_code: "29488",
    full_city_name: "Walterboro, SC",
    city_name: "Walterboro",
  },
  {
    postal_code: "78734",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "85382",
    full_city_name: "Peoria, AZ",
    city_name: "Peoria",
  },
  {
    postal_code: "06111",
    full_city_name: "Newington, CT",
    city_name: "Newington",
  },
  {
    postal_code: "44124",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "29420",
    full_city_name: "North Charleston, SC",
    city_name: "North Charleston",
  },
  {
    postal_code: "95376",
    full_city_name: "Tracy, CA",
    city_name: "Tracy",
  },
  {
    postal_code: "10017",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "46123",
    full_city_name: "Avon, IN",
    city_name: "Avon",
  },
  {
    postal_code: "59047",
    full_city_name: "Livingston, MT",
    city_name: "Livingston",
  },
  {
    postal_code: "33469",
    full_city_name: "Jupiter, FL",
    city_name: "Jupiter",
  },
  {
    postal_code: "27511",
    full_city_name: "Cary, NC",
    city_name: "Cary",
  },
  {
    postal_code: "75142",
    full_city_name: "Kaufman, TX",
    city_name: "Kaufman",
  },
  {
    postal_code: "68138",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "19120",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "46229",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "29910",
    full_city_name: "Bluffton, SC",
    city_name: "Bluffton",
  },
  {
    postal_code: "77388",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "63108",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "93291",
    full_city_name: "Visalia, CA",
    city_name: "Visalia",
  },
  {
    postal_code: "76016",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "75208",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "33473",
    full_city_name: "Boynton Beach, FL",
    city_name: "Boynton Beach",
  },
  {
    postal_code: "40475",
    full_city_name: "Richmond, KY",
    city_name: "Richmond",
  },
  {
    postal_code: "27610",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "33170",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "28428",
    full_city_name: "Carolina Beach, NC",
    city_name: "Carolina Beach",
  },
  {
    postal_code: "95404",
    full_city_name: "Santa Rosa, CA",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "34237",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "77530",
    full_city_name: "Channelview, TX",
    city_name: "Channelview",
  },
  {
    postal_code: "08015",
    full_city_name: "Browns Mills, NJ",
    city_name: "Browns Mills",
  },
  {
    postal_code: "85018",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "55113",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "34746",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "91340",
    full_city_name: "San Fernando, CA",
    city_name: "San Fernando",
  },
  {
    postal_code: "97741",
    full_city_name: "Madras, OR",
    city_name: "Madras",
  },
  {
    postal_code: "44212",
    full_city_name: "Brunswick, OH",
    city_name: "Brunswick",
  },
  {
    postal_code: "10026",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "29414",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "75006",
    full_city_name: "Carrollton, TX",
    city_name: "Carrollton",
  },
  {
    postal_code: "29483",
    full_city_name: "Summerville, SC",
    city_name: "Summerville",
  },
  {
    postal_code: "97739",
    full_city_name: "La Pine, OR",
    city_name: "La Pine",
  },
  {
    postal_code: "92656",
    full_city_name: "Aliso Viejo, CA",
    city_name: "Aliso Viejo",
  },
  {
    postal_code: "34119",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "84049",
    full_city_name: "Midway, UT",
    city_name: "Midway",
  },
  {
    postal_code: "84032",
    full_city_name: "Heber City, UT",
    city_name: "Heber City",
  },
  {
    postal_code: "70461",
    full_city_name: "Slidell, LA",
    city_name: "Slidell",
  },
  {
    postal_code: "70003",
    full_city_name: "Metairie, LA",
    city_name: "Metairie",
  },
  {
    postal_code: "53704",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "29466",
    full_city_name: "Mount Pleasant, SC",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "30141",
    full_city_name: "Hiram, GA",
    city_name: "Hiram",
  },
  {
    postal_code: "79605",
    full_city_name: "Abilene, TX",
    city_name: "Abilene",
  },
  {
    postal_code: "28461",
    full_city_name: "Southport, NC",
    city_name: "Southport",
  },
  {
    postal_code: "29644",
    full_city_name: "Fountain Inn, SC",
    city_name: "Fountain Inn",
  },
  {
    postal_code: "53913",
    full_city_name: "Baraboo, WI",
    city_name: "Baraboo",
  },
  {
    postal_code: "60506",
    full_city_name: "Aurora, IL",
    city_name: "Aurora",
  },
  {
    postal_code: "30009",
    full_city_name: "Alpharetta, GA",
    city_name: "Alpharetta",
  },
  {
    postal_code: "95661",
    full_city_name: "Roseville, CA",
    city_name: "Roseville",
  },
  {
    postal_code: "28211",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "75231",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "90631",
    full_city_name: "La Habra, CA",
    city_name: "La Habra",
  },
  {
    postal_code: "30082",
    full_city_name: "Smyrna, GA",
    city_name: "Smyrna",
  },
  {
    postal_code: "87508",
    full_city_name: "Santa Fe, NM",
    city_name: "Santa Fe",
  },
  {
    postal_code: "44223",
    full_city_name: "Cuyahoga Falls, OH",
    city_name: "Cuyahoga Falls",
  },
  {
    postal_code: "33434",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "23836",
    full_city_name: "Chester, VA",
    city_name: "Chester",
  },
  {
    postal_code: "43230",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "33971",
    full_city_name: "Lehigh Acres, FL",
    city_name: "Lehigh Acres",
  },
  {
    postal_code: "19123",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "32780",
    full_city_name: "Titusville, FL",
    city_name: "Titusville",
  },
  {
    postal_code: "40245",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "34241",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "33015",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "30078",
    full_city_name: "Snellville, GA",
    city_name: "Snellville",
  },
  {
    postal_code: "28146",
    full_city_name: "Salisbury, NC",
    city_name: "Salisbury",
  },
  {
    postal_code: "32024",
    full_city_name: "Lake City, FL",
    city_name: "Lake City",
  },
  {
    postal_code: "77535",
    full_city_name: "Dayton, TX",
    city_name: "Dayton",
  },
  {
    postal_code: "32738",
    full_city_name: "Deltona, FL",
    city_name: "Deltona",
  },
  {
    postal_code: "19609",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "32811",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "03431",
    full_city_name: "Keene, NH",
    city_name: "Keene",
  },
  {
    postal_code: "89002",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "89109",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "03077",
    full_city_name: "Raymond, NH",
    city_name: "Raymond",
  },
  {
    postal_code: "55328",
    full_city_name: "Delano, MN",
    city_name: "Delano",
  },
  {
    postal_code: "40353",
    full_city_name: "Mount Sterling, KY",
    city_name: "Mount Sterling",
  },
  {
    postal_code: "33183",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "76052",
    full_city_name: "Haslet, TX",
    city_name: "Haslet",
  },
  {
    postal_code: "60119",
    full_city_name: "Elburn, IL",
    city_name: "Elburn",
  },
  {
    postal_code: "46235",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "75009",
    full_city_name: "Celina, TX",
    city_name: "Celina",
  },
  {
    postal_code: "33972",
    full_city_name: "Lehigh Acres, FL",
    city_name: "Lehigh Acres",
  },
  {
    postal_code: "31404",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "19014",
    full_city_name: "Aston, PA",
    city_name: "Aston",
  },
  {
    postal_code: "32308",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "32177",
    full_city_name: "Palatka, FL",
    city_name: "Palatka",
  },
  {
    postal_code: "20009",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "76053",
    full_city_name: "Hurst, TX",
    city_name: "Hurst",
  },
  {
    postal_code: "60640",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "91423",
    full_city_name: "Sherman Oaks, CA",
    city_name: "Sherman Oaks",
  },
  {
    postal_code: "99026",
    full_city_name: "Nine Mile Falls, WA",
    city_name: "Nine Mile Falls",
  },
  {
    postal_code: "28805",
    full_city_name: "Asheville, NC",
    city_name: "Asheville",
  },
  {
    postal_code: "74429",
    full_city_name: "Coweta, OK",
    city_name: "Coweta",
  },
  {
    postal_code: "80922",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "75115",
    full_city_name: "Desoto, TX",
    city_name: "Desoto",
  },
  {
    postal_code: "10022",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "34113",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "60564",
    full_city_name: "Naperville, IL",
    city_name: "Naperville",
  },
  {
    postal_code: "76054",
    full_city_name: "Hurst, TX",
    city_name: "Hurst",
  },
  {
    postal_code: "78249",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "19136",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "33809",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "80701",
    full_city_name: "Fort Morgan, CO",
    city_name: "Fort Morgan",
  },
  {
    postal_code: "84043",
    full_city_name: "Lehi, UT",
    city_name: "Lehi",
  },
  {
    postal_code: "62298",
    full_city_name: "Waterloo, IL",
    city_name: "Waterloo",
  },
  {
    postal_code: "33496",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "43920",
    full_city_name: "East Liverpool, OH",
    city_name: "East Liverpool",
  },
  {
    postal_code: "08753",
    full_city_name: "Toms River, NJ",
    city_name: "Toms River",
  },
  {
    postal_code: "34747",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "33028",
    full_city_name: "Pembroke Pines, FL",
    city_name: "Pembroke Pines",
  },
  {
    postal_code: "46227",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "33604",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "78746",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "60516",
    full_city_name: "Downers Grove, IL",
    city_name: "Downers Grove",
  },
  {
    postal_code: "99037",
    full_city_name: "Veradale, WA",
    city_name: "Veradale",
  },
  {
    postal_code: "19040",
    full_city_name: "Hatboro, PA",
    city_name: "Hatboro",
  },
  {
    postal_code: "78414",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "33980",
    full_city_name: "Punta Gorda, FL",
    city_name: "Punta Gorda",
  },
  {
    postal_code: "62236",
    full_city_name: "Columbia, IL",
    city_name: "Columbia",
  },
  {
    postal_code: "46319",
    full_city_name: "Griffith, IN",
    city_name: "Griffith",
  },
  {
    postal_code: "84341",
    full_city_name: "Logan, UT",
    city_name: "Logan",
  },
  {
    postal_code: "08251",
    full_city_name: "Villas, NJ",
    city_name: "Villas",
  },
  {
    postal_code: "33605",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "32208",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "27540",
    full_city_name: "Holly Springs, NC",
    city_name: "Holly Springs",
  },
  {
    postal_code: "95070",
    full_city_name: "Saratoga, CA",
    city_name: "Saratoga",
  },
  {
    postal_code: "92886",
    full_city_name: "Yorba Linda, CA",
    city_name: "Yorba Linda",
  },
  {
    postal_code: "60628",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "60068",
    full_city_name: "Park Ridge, IL",
    city_name: "Park Ridge",
  },
  {
    postal_code: "33950",
    full_city_name: "Punta Gorda, FL",
    city_name: "Punta Gorda",
  },
  {
    postal_code: "44122",
    full_city_name: "Beachwood, OH",
    city_name: "Beachwood",
  },
  {
    postal_code: "95337",
    full_city_name: "Manteca, CA",
    city_name: "Manteca",
  },
  {
    postal_code: "55126",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "77373",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "17055",
    full_city_name: "Mechanicsburg, PA",
    city_name: "Mechanicsburg",
  },
  {
    postal_code: "23224",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "27526",
    full_city_name: "Fuquay Varina, NC",
    city_name: "Fuquay Varina",
  },
  {
    postal_code: "40205",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "30097",
    full_city_name: "Duluth, GA",
    city_name: "Duluth",
  },
  {
    postal_code: "63385",
    full_city_name: "Wentzville, MO",
    city_name: "Wentzville",
  },
  {
    postal_code: "33559",
    full_city_name: "Lutz, FL",
    city_name: "Lutz",
  },
  {
    postal_code: "46038",
    full_city_name: "Fishers, IN",
    city_name: "Fishers",
  },
  {
    postal_code: "92549",
    full_city_name: "Idyllwild, CA",
    city_name: "Idyllwild",
  },
  {
    postal_code: "34472",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "95354",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "37043",
    full_city_name: "Clarksville, TN",
    city_name: "Clarksville",
  },
  {
    postal_code: "60525",
    full_city_name: "La Grange, IL",
    city_name: "La Grange",
  },
  {
    postal_code: "55311",
    full_city_name: "Maple Grove, MN",
    city_name: "Maple Grove",
  },
  {
    postal_code: "33023",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "43040",
    full_city_name: "Marysville, OH",
    city_name: "Marysville",
  },
  {
    postal_code: "94621",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "85326",
    full_city_name: "Buckeye, AZ",
    city_name: "Buckeye",
  },
  {
    postal_code: "40223",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "60561",
    full_city_name: "Darien, IL",
    city_name: "Darien",
  },
  {
    postal_code: "44278",
    full_city_name: "Tallmadge, OH",
    city_name: "Tallmadge",
  },
  {
    postal_code: "93923",
    full_city_name: "Carmel, CA",
    city_name: "Carmel",
  },
  {
    postal_code: "22201",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "61265",
    full_city_name: "Moline, IL",
    city_name: "Moline",
  },
  {
    postal_code: "75146",
    full_city_name: "Lancaster, TX",
    city_name: "Lancaster",
  },
  {
    postal_code: "30517",
    full_city_name: "Braselton, GA",
    city_name: "Braselton",
  },
  {
    postal_code: "06475",
    full_city_name: "Old Saybrook, CT",
    city_name: "Old Saybrook",
  },
  {
    postal_code: "08638",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "95628",
    full_city_name: "Fair Oaks, CA",
    city_name: "Fair Oaks",
  },
  {
    postal_code: "33157",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "80504",
    full_city_name: "Longmont, CO",
    city_name: "Longmont",
  },
  {
    postal_code: "68506",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "70448",
    full_city_name: "Mandeville, LA",
    city_name: "Mandeville",
  },
  {
    postal_code: "33983",
    full_city_name: "Punta Gorda, FL",
    city_name: "Punta Gorda",
  },
  {
    postal_code: "33709",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "92804",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "33027",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "63368",
    full_city_name: "O Fallon, MO",
    city_name: "O Fallon",
  },
  {
    postal_code: "33545",
    full_city_name: "Wesley Chapel, FL",
    city_name: "Wesley Chapel",
  },
  {
    postal_code: "74136",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "34653",
    full_city_name: "New Port Richey, FL",
    city_name: "New Port Richey",
  },
  {
    postal_code: "34109",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "85248",
    full_city_name: "Chandler, AZ",
    city_name: "Chandler",
  },
  {
    postal_code: "38125",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "90068",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "76180",
    full_city_name: "North Richland Hills, TX",
    city_name: "North Richland Hills",
  },
  {
    postal_code: "33178",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "33312",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "33328",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "60305",
    full_city_name: "River Forest, IL",
    city_name: "River Forest",
  },
  {
    postal_code: "80634",
    full_city_name: "Greeley, CO",
    city_name: "Greeley",
  },
  {
    postal_code: "37924",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "08005",
    full_city_name: "Barnegat, NJ",
    city_name: "Barnegat",
  },
  {
    postal_code: "40211",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "70503",
    full_city_name: "Lafayette, LA",
    city_name: "Lafayette",
  },
  {
    postal_code: "75078",
    full_city_name: "Prosper, TX",
    city_name: "Prosper",
  },
  {
    postal_code: "60482",
    full_city_name: "Worth, IL",
    city_name: "Worth",
  },
  {
    postal_code: "38133",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "19140",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "43119",
    full_city_name: "Galloway, OH",
    city_name: "Galloway",
  },
  {
    postal_code: "55374",
    full_city_name: "Rogers, MN",
    city_name: "Rogers",
  },
  {
    postal_code: "95670",
    full_city_name: "Rancho Cordova, CA",
    city_name: "Rancho Cordova",
  },
  {
    postal_code: "33414",
    full_city_name: "Wellington, FL",
    city_name: "Wellington",
  },
  {
    postal_code: "08055",
    full_city_name: "Medford, NJ",
    city_name: "Medford",
  },
  {
    postal_code: "55431",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "60415",
    full_city_name: "Chicago Ridge, IL",
    city_name: "Chicago Ridge",
  },
  {
    postal_code: "22911",
    full_city_name: "Charlottesville, VA",
    city_name: "Charlottesville",
  },
  {
    postal_code: "63119",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "08108",
    full_city_name: "Collingswood, NJ",
    city_name: "Collingswood",
  },
  {
    postal_code: "08096",
    full_city_name: "Woodbury, NJ",
    city_name: "Woodbury",
  },
  {
    postal_code: "30349",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "42134",
    full_city_name: "Franklin, KY",
    city_name: "Franklin",
  },
  {
    postal_code: "53713",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "55303",
    full_city_name: "Anoka, MN",
    city_name: "Anoka",
  },
  {
    postal_code: "46184",
    full_city_name: "Whiteland, IN",
    city_name: "Whiteland",
  },
  {
    postal_code: "91752",
    full_city_name: "Mira Loma, CA",
    city_name: "Mira Loma",
  },
  {
    postal_code: "45440",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "06450",
    full_city_name: "Meriden, CT",
    city_name: "Meriden",
  },
  {
    postal_code: "30312",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "78260",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "75023",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "60202",
    full_city_name: "Evanston, IL",
    city_name: "Evanston",
  },
  {
    postal_code: "28226",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "20715",
    full_city_name: "Bowie, MD",
    city_name: "Bowie",
  },
  {
    postal_code: "77055",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "53718",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "90027",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "78747",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "95815",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "37777",
    full_city_name: "Louisville, TN",
    city_name: "Louisville",
  },
  {
    postal_code: "85298",
    full_city_name: "Gilbert, AZ",
    city_name: "Gilbert",
  },
  {
    postal_code: "46221",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "23120",
    full_city_name: "Moseley, VA",
    city_name: "Moseley",
  },
  {
    postal_code: "19977",
    full_city_name: "Smyrna, DE",
    city_name: "Smyrna",
  },
  {
    postal_code: "95377",
    full_city_name: "Tracy, CA",
    city_name: "Tracy",
  },
  {
    postal_code: "34134",
    full_city_name: "Bonita Springs, FL",
    city_name: "Bonita Springs",
  },
  {
    postal_code: "43201",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "34452",
    full_city_name: "Inverness, FL",
    city_name: "Inverness",
  },
  {
    postal_code: "33903",
    full_city_name: "North Fort Myers, FL",
    city_name: "North Fort Myers",
  },
  {
    postal_code: "33173",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "68505",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "16803",
    full_city_name: "State College, PA",
    city_name: "State College",
  },
  {
    postal_code: "60164",
    full_city_name: "Melrose Park, IL",
    city_name: "Melrose Park",
  },
  {
    postal_code: "30013",
    full_city_name: "Conyers, GA",
    city_name: "Conyers",
  },
  {
    postal_code: "11223",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "46131",
    full_city_name: "Franklin, IN",
    city_name: "Franklin",
  },
  {
    postal_code: "45251",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "45504",
    full_city_name: "Springfield, OH",
    city_name: "Springfield",
  },
  {
    postal_code: "34224",
    full_city_name: "Englewood, FL",
    city_name: "Englewood",
  },
  {
    postal_code: "55313",
    full_city_name: "Buffalo, MN",
    city_name: "Buffalo",
  },
  {
    postal_code: "28803",
    full_city_name: "Asheville, NC",
    city_name: "Asheville",
  },
  {
    postal_code: "68510",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "80503",
    full_city_name: "Longmont, CO",
    city_name: "Longmont",
  },
  {
    postal_code: "85742",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "93555",
    full_city_name: "Ridgecrest, CA",
    city_name: "Ridgecrest",
  },
  {
    postal_code: "32746",
    full_city_name: "Lake Mary, FL",
    city_name: "Lake Mary",
  },
  {
    postal_code: "75230",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "95824",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "10006",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "30092",
    full_city_name: "Peachtree Corners, GA",
    city_name: "Peachtree Corners",
  },
  {
    postal_code: "34609",
    full_city_name: "Spring Hill, FL",
    city_name: "Spring Hill",
  },
  {
    postal_code: "78754",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "88130",
    full_city_name: "Portales, NM",
    city_name: "Portales",
  },
  {
    postal_code: "83001",
    full_city_name: "Jackson, WY",
    city_name: "Jackson",
  },
  {
    postal_code: "75067",
    full_city_name: "Lewisville, TX",
    city_name: "Lewisville",
  },
  {
    postal_code: "10306",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "33901",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "21701",
    full_city_name: "Frederick, MD",
    city_name: "Frederick",
  },
  {
    postal_code: "55129",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "93550",
    full_city_name: "Palmdale, CA",
    city_name: "Palmdale",
  },
  {
    postal_code: "84651",
    full_city_name: "Payson, UT",
    city_name: "Payson",
  },
  {
    postal_code: "30642",
    full_city_name: "Greensboro, GA",
    city_name: "Greensboro",
  },
  {
    postal_code: "30080",
    full_city_name: "Smyrna, GA",
    city_name: "Smyrna",
  },
  {
    postal_code: "79912",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "89166",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "60142",
    full_city_name: "Huntley, IL",
    city_name: "Huntley",
  },
  {
    postal_code: "90278",
    full_city_name: "Redondo Beach, CA",
    city_name: "Redondo Beach",
  },
  {
    postal_code: "63366",
    full_city_name: "O Fallon, MO",
    city_name: "O Fallon",
  },
  {
    postal_code: "03053",
    full_city_name: "Londonderry, NH",
    city_name: "Londonderry",
  },
  {
    postal_code: "68104",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "94404",
    full_city_name: "San Mateo, CA",
    city_name: "San Mateo",
  },
  {
    postal_code: "46013",
    full_city_name: "Anderson, IN",
    city_name: "Anderson",
  },
  {
    postal_code: "60563",
    full_city_name: "Naperville, IL",
    city_name: "Naperville",
  },
  {
    postal_code: "94558",
    full_city_name: "Napa, CA",
    city_name: "Napa",
  },
  {
    postal_code: "33431",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "81122",
    full_city_name: "Bayfield, CO",
    city_name: "Bayfield",
  },
  {
    postal_code: "77365",
    full_city_name: "Porter, TX",
    city_name: "Porter",
  },
  {
    postal_code: "21014",
    full_city_name: "Bel Air, MD",
    city_name: "Bel Air",
  },
  {
    postal_code: "48176",
    full_city_name: "Saline, MI",
    city_name: "Saline",
  },
  {
    postal_code: "55060",
    full_city_name: "Owatonna, MN",
    city_name: "Owatonna",
  },
  {
    postal_code: "32825",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "63020",
    full_city_name: "De Soto, MO",
    city_name: "De Soto",
  },
  {
    postal_code: "79703",
    full_city_name: "Midland, TX",
    city_name: "Midland",
  },
  {
    postal_code: "90230",
    full_city_name: "Culver City, CA",
    city_name: "Culver City",
  },
  {
    postal_code: "39525",
    full_city_name: "Diamondhead, MS",
    city_name: "Diamondhead",
  },
  {
    postal_code: "91320",
    full_city_name: "Newbury Park, CA",
    city_name: "Newbury Park",
  },
  {
    postal_code: "63390",
    full_city_name: "Wright City, MO",
    city_name: "Wright City",
  },
  {
    postal_code: "56353",
    full_city_name: "Milaca, MN",
    city_name: "Milaca",
  },
  {
    postal_code: "93510",
    full_city_name: "Acton, CA",
    city_name: "Acton",
  },
  {
    postal_code: "15236",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "60505",
    full_city_name: "Aurora, IL",
    city_name: "Aurora",
  },
  {
    postal_code: "60174",
    full_city_name: "Saint Charles, IL",
    city_name: "Saint Charles",
  },
  {
    postal_code: "77066",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "45371",
    full_city_name: "Tipp City, OH",
    city_name: "Tipp City",
  },
  {
    postal_code: "40513",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "22963",
    full_city_name: "Palmyra, VA",
    city_name: "Palmyra",
  },
  {
    postal_code: "10016",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "89115",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "92262",
    full_city_name: "Palm Springs, CA",
    city_name: "Palm Springs",
  },
  {
    postal_code: "20747",
    full_city_name: "District Heights, MD",
    city_name: "District Heights",
  },
  {
    postal_code: "67460",
    full_city_name: "Mcpherson, KS",
    city_name: "Mcpherson",
  },
  {
    postal_code: "97707",
    full_city_name: "Bend, OR",
    city_name: "Bend",
  },
  {
    postal_code: "60471",
    full_city_name: "Richton Park, IL",
    city_name: "Richton Park",
  },
  {
    postal_code: "08202",
    full_city_name: "Avalon, NJ",
    city_name: "Avalon",
  },
  {
    postal_code: "85383",
    full_city_name: "Peoria, AZ",
    city_name: "Peoria",
  },
  {
    postal_code: "63021",
    full_city_name: "Ballwin, MO",
    city_name: "Ballwin",
  },
  {
    postal_code: "40220",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "36067",
    full_city_name: "Prattville, AL",
    city_name: "Prattville",
  },
  {
    postal_code: "75228",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "63137",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "22546",
    full_city_name: "Ruther Glen, VA",
    city_name: "Ruther Glen",
  },
  {
    postal_code: "36117",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "33706",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "33510",
    full_city_name: "Brandon, FL",
    city_name: "Brandon",
  },
  {
    postal_code: "46222",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "02649",
    full_city_name: "Mashpee, MA",
    city_name: "Mashpee",
  },
  {
    postal_code: "97754",
    full_city_name: "Prineville, OR",
    city_name: "Prineville",
  },
  {
    postal_code: "90046",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "60609",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "40031",
    full_city_name: "La Grange, KY",
    city_name: "La Grange",
  },
  {
    postal_code: "85715",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "88203",
    full_city_name: "Roswell, NM",
    city_name: "Roswell",
  },
  {
    postal_code: "61853",
    full_city_name: "Mahomet, IL",
    city_name: "Mahomet",
  },
  {
    postal_code: "46176",
    full_city_name: "Shelbyville, IN",
    city_name: "Shelbyville",
  },
  {
    postal_code: "60644",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "34613",
    full_city_name: "Brooksville, FL",
    city_name: "Brooksville",
  },
  {
    postal_code: "94015",
    full_city_name: "Daly City, CA",
    city_name: "Daly City",
  },
  {
    postal_code: "84123",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "77375",
    full_city_name: "Tomball, TX",
    city_name: "Tomball",
  },
  {
    postal_code: "54729",
    full_city_name: "Chippewa Falls, WI",
    city_name: "Chippewa Falls",
  },
  {
    postal_code: "11226",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "12533",
    full_city_name: "Hopewell Junction, NY",
    city_name: "Hopewell Junction",
  },
  {
    postal_code: "54457",
    full_city_name: "Nekoosa, WI",
    city_name: "Nekoosa",
  },
  {
    postal_code: "99212",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "93215",
    full_city_name: "Delano, CA",
    city_name: "Delano",
  },
  {
    postal_code: "30040",
    full_city_name: "Cumming, GA",
    city_name: "Cumming",
  },
  {
    postal_code: "48197",
    full_city_name: "Ypsilanti, MI",
    city_name: "Ypsilanti",
  },
  {
    postal_code: "60543",
    full_city_name: "Oswego, IL",
    city_name: "Oswego",
  },
  {
    postal_code: "95131",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "33880",
    full_city_name: "Winter Haven, FL",
    city_name: "Winter Haven",
  },
  {
    postal_code: "74006",
    full_city_name: "Bartlesville, OK",
    city_name: "Bartlesville",
  },
  {
    postal_code: "33030",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "29720",
    full_city_name: "Lancaster, SC",
    city_name: "Lancaster",
  },
  {
    postal_code: "55331",
    full_city_name: "Excelsior, MN",
    city_name: "Excelsior",
  },
  {
    postal_code: "15001",
    full_city_name: "Aliquippa, PA",
    city_name: "Aliquippa",
  },
  {
    postal_code: "90039",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "76226",
    full_city_name: "Argyle, TX",
    city_name: "Argyle",
  },
  {
    postal_code: "33898",
    full_city_name: "Lake Wales, FL",
    city_name: "Lake Wales",
  },
  {
    postal_code: "37721",
    full_city_name: "Corryton, TN",
    city_name: "Corryton",
  },
  {
    postal_code: "34655",
    full_city_name: "New Port Richey, FL",
    city_name: "New Port Richey",
  },
  {
    postal_code: "45152",
    full_city_name: "Morrow, OH",
    city_name: "Morrow",
  },
  {
    postal_code: "74501",
    full_city_name: "Mcalester, OK",
    city_name: "Mcalester",
  },
  {
    postal_code: "32233",
    full_city_name: "Atlantic Beach, FL",
    city_name: "Atlantic Beach",
  },
  {
    postal_code: "59106",
    full_city_name: "Billings, MT",
    city_name: "Billings",
  },
  {
    postal_code: "86305",
    full_city_name: "Prescott, AZ",
    city_name: "Prescott",
  },
  {
    postal_code: "41018",
    full_city_name: "Erlanger, KY",
    city_name: "Erlanger",
  },
  {
    postal_code: "68136",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "91765",
    full_city_name: "Diamond Bar, CA",
    city_name: "Diamond Bar",
  },
  {
    postal_code: "35235",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "94619",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "95451",
    full_city_name: "Kelseyville, CA",
    city_name: "Kelseyville",
  },
  {
    postal_code: "84084",
    full_city_name: "West Jordan, UT",
    city_name: "West Jordan",
  },
  {
    postal_code: "89103",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "44149",
    full_city_name: "Strongsville, OH",
    city_name: "Strongsville",
  },
  {
    postal_code: "60020",
    full_city_name: "Fox Lake, IL",
    city_name: "Fox Lake",
  },
  {
    postal_code: "19122",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "37738",
    full_city_name: "Gatlinburg, TN",
    city_name: "Gatlinburg",
  },
  {
    postal_code: "27529",
    full_city_name: "Garner, NC",
    city_name: "Garner",
  },
  {
    postal_code: "45322",
    full_city_name: "Englewood, OH",
    city_name: "Englewood",
  },
  {
    postal_code: "47403",
    full_city_name: "Bloomington, IN",
    city_name: "Bloomington",
  },
  {
    postal_code: "84045",
    full_city_name: "Saratoga Springs, UT",
    city_name: "Saratoga Springs",
  },
  {
    postal_code: "33607",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "03867",
    full_city_name: "Rochester, NH",
    city_name: "Rochester",
  },
  {
    postal_code: "87506",
    full_city_name: "Santa Fe, NM",
    city_name: "Santa Fe",
  },
  {
    postal_code: "78745",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "33701",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "30122",
    full_city_name: "Lithia Springs, GA",
    city_name: "Lithia Springs",
  },
  {
    postal_code: "27617",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "64152",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "95618",
    full_city_name: "Davis, CA",
    city_name: "Davis",
  },
  {
    postal_code: "75033",
    full_city_name: "Frisco, TX",
    city_name: "Frisco",
  },
  {
    postal_code: "02886",
    full_city_name: "Warwick, RI",
    city_name: "Warwick",
  },
  {
    postal_code: "19003",
    full_city_name: "Ardmore, PA",
    city_name: "Ardmore",
  },
  {
    postal_code: "68901",
    full_city_name: "Hastings, NE",
    city_name: "Hastings",
  },
  {
    postal_code: "89122",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "23150",
    full_city_name: "Sandston, VA",
    city_name: "Sandston",
  },
  {
    postal_code: "94608",
    full_city_name: "Emeryville, CA",
    city_name: "Emeryville",
  },
  {
    postal_code: "28704",
    full_city_name: "Arden, NC",
    city_name: "Arden",
  },
  {
    postal_code: "95961",
    full_city_name: "Olivehurst, CA",
    city_name: "Olivehurst",
  },
  {
    postal_code: "85746",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "23113",
    full_city_name: "Midlothian, VA",
    city_name: "Midlothian",
  },
  {
    postal_code: "20716",
    full_city_name: "Bowie, MD",
    city_name: "Bowie",
  },
  {
    postal_code: "61611",
    full_city_name: "East Peoria, IL",
    city_name: "East Peoria",
  },
  {
    postal_code: "95946",
    full_city_name: "Penn Valley, CA",
    city_name: "Penn Valley",
  },
  {
    postal_code: "95403",
    full_city_name: "Santa Rosa, CA",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "16001",
    full_city_name: "Butler, PA",
    city_name: "Butler",
  },
  {
    postal_code: "11372",
    full_city_name: "Jackson Heights, NY",
    city_name: "Jackson Heights",
  },
  {
    postal_code: "06070",
    full_city_name: "Simsbury, CT",
    city_name: "Simsbury",
  },
  {
    postal_code: "84663",
    full_city_name: "Springville, UT",
    city_name: "Springville",
  },
  {
    postal_code: "66223",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "29403",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "17036",
    full_city_name: "Hummelstown, PA",
    city_name: "Hummelstown",
  },
  {
    postal_code: "80528",
    full_city_name: "Fort Collins, CO",
    city_name: "Fort Collins",
  },
  {
    postal_code: "15108",
    full_city_name: "Coraopolis, PA",
    city_name: "Coraopolis",
  },
  {
    postal_code: "37920",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "37857",
    full_city_name: "Rogersville, TN",
    city_name: "Rogersville",
  },
  {
    postal_code: "19604",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "48104",
    full_city_name: "Ann Arbor, MI",
    city_name: "Ann Arbor",
  },
  {
    postal_code: "06033",
    full_city_name: "Glastonbury, CT",
    city_name: "Glastonbury",
  },
  {
    postal_code: "40014",
    full_city_name: "Crestwood, KY",
    city_name: "Crestwood",
  },
  {
    postal_code: "59715",
    full_city_name: "Bozeman, MT",
    city_name: "Bozeman",
  },
  {
    postal_code: "77541",
    full_city_name: "Freeport, TX",
    city_name: "Freeport",
  },
  {
    postal_code: "40505",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "19004",
    full_city_name: "Bala Cynwyd, PA",
    city_name: "Bala Cynwyd",
  },
  {
    postal_code: "60540",
    full_city_name: "Naperville, IL",
    city_name: "Naperville",
  },
  {
    postal_code: "28574",
    full_city_name: "Richlands, NC",
    city_name: "Richlands",
  },
  {
    postal_code: "42101",
    full_city_name: "Bowling Green, KY",
    city_name: "Bowling Green",
  },
  {
    postal_code: "77059",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "28304",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "95008",
    full_city_name: "Campbell, CA",
    city_name: "Campbell",
  },
  {
    postal_code: "60611",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "12601",
    full_city_name: "Poughkeepsie, NY",
    city_name: "Poughkeepsie",
  },
  {
    postal_code: "30035",
    full_city_name: "Decatur, GA",
    city_name: "Decatur",
  },
  {
    postal_code: "34689",
    full_city_name: "Tarpon Springs, FL",
    city_name: "Tarpon Springs",
  },
  {
    postal_code: "90292",
    full_city_name: "Marina Del Rey, CA",
    city_name: "Marina Del Rey",
  },
  {
    postal_code: "92692",
    full_city_name: "Mission Viejo, CA",
    city_name: "Mission Viejo",
  },
  {
    postal_code: "33805",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "11706",
    full_city_name: "Bay Shore, NY",
    city_name: "Bay Shore",
  },
  {
    postal_code: "47362",
    full_city_name: "New Castle, IN",
    city_name: "New Castle",
  },
  {
    postal_code: "92277",
    full_city_name: "Twentynine Palms, CA",
    city_name: "Twentynine Palms",
  },
  {
    postal_code: "11520",
    full_city_name: "Freeport, NY",
    city_name: "Freeport",
  },
  {
    postal_code: "28025",
    full_city_name: "Concord, NC",
    city_name: "Concord",
  },
  {
    postal_code: "44514",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "60451",
    full_city_name: "New Lenox, IL",
    city_name: "New Lenox",
  },
  {
    postal_code: "80526",
    full_city_name: "Fort Collins, CO",
    city_name: "Fort Collins",
  },
  {
    postal_code: "46033",
    full_city_name: "Carmel, IN",
    city_name: "Carmel",
  },
  {
    postal_code: "90802",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "41051",
    full_city_name: "Independence, KY",
    city_name: "Independence",
  },
  {
    postal_code: "60827",
    full_city_name: "Riverdale, IL",
    city_name: "Riverdale",
  },
  {
    postal_code: "76031",
    full_city_name: "Cleburne, TX",
    city_name: "Cleburne",
  },
  {
    postal_code: "19055",
    full_city_name: "Levittown, PA",
    city_name: "Levittown",
  },
  {
    postal_code: "78253",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "60098",
    full_city_name: "Woodstock, IL",
    city_name: "Woodstock",
  },
  {
    postal_code: "96743",
    full_city_name: "Kamuela, HI",
    city_name: "Kamuela",
  },
  {
    postal_code: "76262",
    full_city_name: "Roanoke, TX",
    city_name: "Roanoke",
  },
  {
    postal_code: "91730",
    full_city_name: "Rancho Cucamonga, CA",
    city_name: "Rancho Cucamonga",
  },
  {
    postal_code: "92630",
    full_city_name: "Lake Forest, CA",
    city_name: "Lake Forest",
  },
  {
    postal_code: "45233",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "55082",
    full_city_name: "Stillwater, MN",
    city_name: "Stillwater",
  },
  {
    postal_code: "81201",
    full_city_name: "Salida, CO",
    city_name: "Salida",
  },
  {
    postal_code: "84321",
    full_city_name: "Logan, UT",
    city_name: "Logan",
  },
  {
    postal_code: "45150",
    full_city_name: "Milford, OH",
    city_name: "Milford",
  },
  {
    postal_code: "06010",
    full_city_name: "Bristol, CT",
    city_name: "Bristol",
  },
  {
    postal_code: "40229",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "06705",
    full_city_name: "Waterbury, CT",
    city_name: "Waterbury",
  },
  {
    postal_code: "29440",
    full_city_name: "Georgetown, SC",
    city_name: "Georgetown",
  },
  {
    postal_code: "63012",
    full_city_name: "Barnhart, MO",
    city_name: "Barnhart",
  },
  {
    postal_code: "74074",
    full_city_name: "Stillwater, OK",
    city_name: "Stillwater",
  },
  {
    postal_code: "34292",
    full_city_name: "Venice, FL",
    city_name: "Venice",
  },
  {
    postal_code: "30106",
    full_city_name: "Austell, GA",
    city_name: "Austell",
  },
  {
    postal_code: "55014",
    full_city_name: "Circle Pines, MN",
    city_name: "Circle Pines",
  },
  {
    postal_code: "65809",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "30093",
    full_city_name: "Norcross, GA",
    city_name: "Norcross",
  },
  {
    postal_code: "33014",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "60178",
    full_city_name: "Sycamore, IL",
    city_name: "Sycamore",
  },
  {
    postal_code: "72023",
    full_city_name: "Cabot, AR",
    city_name: "Cabot",
  },
  {
    postal_code: "78210",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "19805",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "29609",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "16510",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "76078",
    full_city_name: "Rhome, TX",
    city_name: "Rhome",
  },
  {
    postal_code: "81611",
    full_city_name: "Aspen, CO",
    city_name: "Aspen",
  },
  {
    postal_code: "37931",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "06811",
    full_city_name: "Danbury, CT",
    city_name: "Danbury",
  },
  {
    postal_code: "78233",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "33755",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "92660",
    full_city_name: "Newport Beach, CA",
    city_name: "Newport Beach",
  },
  {
    postal_code: "41015",
    full_city_name: "Latonia, KY",
    city_name: "Latonia",
  },
  {
    postal_code: "64506",
    full_city_name: "Saint Joseph, MO",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "92264",
    full_city_name: "Palm Springs, CA",
    city_name: "Palm Springs",
  },
  {
    postal_code: "63034",
    full_city_name: "Florissant, MO",
    city_name: "Florissant",
  },
  {
    postal_code: "60642",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "74017",
    full_city_name: "Claremore, OK",
    city_name: "Claremore",
  },
  {
    postal_code: "31324",
    full_city_name: "Richmond Hill, GA",
    city_name: "Richmond Hill",
  },
  {
    postal_code: "33616",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "23234",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "20155",
    full_city_name: "Gainesville, VA",
    city_name: "Gainesville",
  },
  {
    postal_code: "27332",
    full_city_name: "Sanford, NC",
    city_name: "Sanford",
  },
  {
    postal_code: "60629",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "89144",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "56003",
    full_city_name: "Mankato, MN",
    city_name: "Mankato",
  },
  {
    postal_code: "79424",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "78213",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "34103",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "72113",
    full_city_name: "Maumelle, AR",
    city_name: "Maumelle",
  },
  {
    postal_code: "90272",
    full_city_name: "Pacific Palisades, CA",
    city_name: "Pacific Palisades",
  },
  {
    postal_code: "35226",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "55301",
    full_city_name: "Albertville, MN",
    city_name: "Albertville",
  },
  {
    postal_code: "76574",
    full_city_name: "Taylor, TX",
    city_name: "Taylor",
  },
  {
    postal_code: "84044",
    full_city_name: "Magna, UT",
    city_name: "Magna",
  },
  {
    postal_code: "95252",
    full_city_name: "Valley Springs, CA",
    city_name: "Valley Springs",
  },
  {
    postal_code: "71105",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "94538",
    full_city_name: "Fremont, CA",
    city_name: "Fremont",
  },
  {
    postal_code: "84059",
    full_city_name: "Vineyard, UT",
    city_name: "Vineyard",
  },
  {
    postal_code: "63628",
    full_city_name: "Bonne Terre, MO",
    city_name: "Bonne Terre",
  },
  {
    postal_code: "60126",
    full_city_name: "Elmhurst, IL",
    city_name: "Elmhurst",
  },
  {
    postal_code: "89015",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "79118",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "50125",
    full_city_name: "Indianola, IA",
    city_name: "Indianola",
  },
  {
    postal_code: "29401",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "60102",
    full_city_name: "Algonquin, IL",
    city_name: "Algonquin",
  },
  {
    postal_code: "30075",
    full_city_name: "Roswell, GA",
    city_name: "Roswell",
  },
  {
    postal_code: "77316",
    full_city_name: "Montgomery, TX",
    city_name: "Montgomery",
  },
  {
    postal_code: "31024",
    full_city_name: "Eatonton, GA",
    city_name: "Eatonton",
  },
  {
    postal_code: "91326",
    full_city_name: "Porter Ranch, CA",
    city_name: "Porter Ranch",
  },
  {
    postal_code: "75080",
    full_city_name: "Richardson, TX",
    city_name: "Richardson",
  },
  {
    postal_code: "46250",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "79015",
    full_city_name: "Canyon, TX",
    city_name: "Canyon",
  },
  {
    postal_code: "44134",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "10463",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "30143",
    full_city_name: "Jasper, GA",
    city_name: "Jasper",
  },
  {
    postal_code: "08550",
    full_city_name: "Princeton Junction, NJ",
    city_name: "Princeton Junction",
  },
  {
    postal_code: "43212",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "32809",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "19803",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "55409",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "30034",
    full_city_name: "Decatur, GA",
    city_name: "Decatur",
  },
  {
    postal_code: "77396",
    full_city_name: "Humble, TX",
    city_name: "Humble",
  },
  {
    postal_code: "55125",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "33131",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "33024",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "46142",
    full_city_name: "Greenwood, IN",
    city_name: "Greenwood",
  },
  {
    postal_code: "30135",
    full_city_name: "Douglasville, GA",
    city_name: "Douglasville",
  },
  {
    postal_code: "06790",
    full_city_name: "Torrington, CT",
    city_name: "Torrington",
  },
  {
    postal_code: "75013",
    full_city_name: "Allen, TX",
    city_name: "Allen",
  },
  {
    postal_code: "34491",
    full_city_name: "Summerfield, FL",
    city_name: "Summerfield",
  },
  {
    postal_code: "95136",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "60477",
    full_city_name: "Tinley Park, IL",
    city_name: "Tinley Park",
  },
  {
    postal_code: "60090",
    full_city_name: "Wheeling, IL",
    city_name: "Wheeling",
  },
  {
    postal_code: "19132",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "70508",
    full_city_name: "Lafayette, LA",
    city_name: "Lafayette",
  },
  {
    postal_code: "37874",
    full_city_name: "Sweetwater, TN",
    city_name: "Sweetwater",
  },
  {
    postal_code: "27527",
    full_city_name: "Clayton, NC",
    city_name: "Clayton",
  },
  {
    postal_code: "63049",
    full_city_name: "High Ridge, MO",
    city_name: "High Ridge",
  },
  {
    postal_code: "50035",
    full_city_name: "Bondurant, IA",
    city_name: "Bondurant",
  },
  {
    postal_code: "22101",
    full_city_name: "Mc Lean, VA",
    city_name: "Mc Lean",
  },
  {
    postal_code: "85750",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "28546",
    full_city_name: "Jacksonville, NC",
    city_name: "Jacksonville",
  },
  {
    postal_code: "66061",
    full_city_name: "Olathe, KS",
    city_name: "Olathe",
  },
  {
    postal_code: "92315",
    full_city_name: "Big Bear Lake, CA",
    city_name: "Big Bear Lake",
  },
  {
    postal_code: "92346",
    full_city_name: "Highland, CA",
    city_name: "Highland",
  },
  {
    postal_code: "28560",
    full_city_name: "New Bern, NC",
    city_name: "New Bern",
  },
  {
    postal_code: "95380",
    full_city_name: "Turlock, CA",
    city_name: "Turlock",
  },
  {
    postal_code: "94610",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "77014",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "33012",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "55444",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "29582",
    full_city_name: "North Myrtle Beach, SC",
    city_name: "North Myrtle Beach",
  },
  {
    postal_code: "19124",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "81504",
    full_city_name: "Grand Junction, CO",
    city_name: "Grand Junction",
  },
  {
    postal_code: "34286",
    full_city_name: "North Port, FL",
    city_name: "North Port",
  },
  {
    postal_code: "75216",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "95817",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "81401",
    full_city_name: "Montrose, CO",
    city_name: "Montrose",
  },
  {
    postal_code: "75248",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "34120",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "34201",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "91405",
    full_city_name: "Van Nuys, CA",
    city_name: "Van Nuys",
  },
  {
    postal_code: "90043",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "93312",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "95843",
    full_city_name: "Antelope, CA",
    city_name: "Antelope",
  },
  {
    postal_code: "68046",
    full_city_name: "Papillion, NE",
    city_name: "Papillion",
  },
  {
    postal_code: "76065",
    full_city_name: "Midlothian, TX",
    city_name: "Midlothian",
  },
  {
    postal_code: "34758",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "20003",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "28732",
    full_city_name: "Fletcher, NC",
    city_name: "Fletcher",
  },
  {
    postal_code: "33772",
    full_city_name: "Seminole, FL",
    city_name: "Seminole",
  },
  {
    postal_code: "30107",
    full_city_name: "Ball Ground, GA",
    city_name: "Ball Ground",
  },
  {
    postal_code: "34743",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "06770",
    full_city_name: "Naugatuck, CT",
    city_name: "Naugatuck",
  },
  {
    postal_code: "15136",
    full_city_name: "Mc Kees Rocks, PA",
    city_name: "Mc Kees Rocks",
  },
  {
    postal_code: "06468",
    full_city_name: "Monroe, CT",
    city_name: "Monroe",
  },
  {
    postal_code: "77019",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "01201",
    full_city_name: "Pittsfield, MA",
    city_name: "Pittsfield",
  },
  {
    postal_code: "95003",
    full_city_name: "Aptos, CA",
    city_name: "Aptos",
  },
  {
    postal_code: "74037",
    full_city_name: "Jenks, OK",
    city_name: "Jenks",
  },
  {
    postal_code: "94605",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "46040",
    full_city_name: "Fortville, IN",
    city_name: "Fortville",
  },
  {
    postal_code: "77568",
    full_city_name: "La Marque, TX",
    city_name: "La Marque",
  },
  {
    postal_code: "32940",
    full_city_name: "Melbourne, FL",
    city_name: "Melbourne",
  },
  {
    postal_code: "33625",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "37725",
    full_city_name: "Dandridge, TN",
    city_name: "Dandridge",
  },
  {
    postal_code: "08002",
    full_city_name: "Cherry Hill, NJ",
    city_name: "Cherry Hill",
  },
  {
    postal_code: "08050",
    full_city_name: "Manahawkin, NJ",
    city_name: "Manahawkin",
  },
  {
    postal_code: "80501",
    full_city_name: "Longmont, CO",
    city_name: "Longmont",
  },
  {
    postal_code: "18951",
    full_city_name: "Quakertown, PA",
    city_name: "Quakertown",
  },
  {
    postal_code: "31419",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "37876",
    full_city_name: "Sevierville, TN",
    city_name: "Sevierville",
  },
  {
    postal_code: "85351",
    full_city_name: "Sun City, AZ",
    city_name: "Sun City",
  },
  {
    postal_code: "15601",
    full_city_name: "Greensburg, PA",
    city_name: "Greensburg",
  },
  {
    postal_code: "45067",
    full_city_name: "Trenton, OH",
    city_name: "Trenton",
  },
  {
    postal_code: "61201",
    full_city_name: "Rock Island, IL",
    city_name: "Rock Island",
  },
  {
    postal_code: "33523",
    full_city_name: "Dade City, FL",
    city_name: "Dade City",
  },
  {
    postal_code: "63110",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "92880",
    full_city_name: "Corona, CA",
    city_name: "Corona",
  },
  {
    postal_code: "85757",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "28027",
    full_city_name: "Concord, NC",
    city_name: "Concord",
  },
  {
    postal_code: "34667",
    full_city_name: "Hudson, FL",
    city_name: "Hudson",
  },
  {
    postal_code: "99019",
    full_city_name: "Liberty Lake, WA",
    city_name: "Liberty Lake",
  },
  {
    postal_code: "90803",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "92821",
    full_city_name: "Brea, CA",
    city_name: "Brea",
  },
  {
    postal_code: "33323",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "70058",
    full_city_name: "Harvey, LA",
    city_name: "Harvey",
  },
  {
    postal_code: "78109",
    full_city_name: "Converse, TX",
    city_name: "Converse",
  },
  {
    postal_code: "55421",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "24551",
    full_city_name: "Forest, VA",
    city_name: "Forest",
  },
  {
    postal_code: "08060",
    full_city_name: "Mount Holly, NJ",
    city_name: "Mount Holly",
  },
  {
    postal_code: "81501",
    full_city_name: "Grand Junction, CO",
    city_name: "Grand Junction",
  },
  {
    postal_code: "28144",
    full_city_name: "Salisbury, NC",
    city_name: "Salisbury",
  },
  {
    postal_code: "15905",
    full_city_name: "Johnstown, PA",
    city_name: "Johnstown",
  },
  {
    postal_code: "80921",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "32773",
    full_city_name: "Sanford, FL",
    city_name: "Sanford",
  },
  {
    postal_code: "95945",
    full_city_name: "Grass Valley, CA",
    city_name: "Grass Valley",
  },
  {
    postal_code: "46268",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "60070",
    full_city_name: "Prospect Heights, IL",
    city_name: "Prospect Heights",
  },
  {
    postal_code: "15210",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "60431",
    full_city_name: "Joliet, IL",
    city_name: "Joliet",
  },
  {
    postal_code: "32757",
    full_city_name: "Mount Dora, FL",
    city_name: "Mount Dora",
  },
  {
    postal_code: "86326",
    full_city_name: "Cottonwood, AZ",
    city_name: "Cottonwood",
  },
  {
    postal_code: "68502",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "32832",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "12211",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "38135",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "32256",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "76240",
    full_city_name: "Gainesville, TX",
    city_name: "Gainesville",
  },
  {
    postal_code: "95123",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "62208",
    full_city_name: "Fairview Heights, IL",
    city_name: "Fairview Heights",
  },
  {
    postal_code: "64086",
    full_city_name: "Lees Summit, MO",
    city_name: "Lees Summit",
  },
  {
    postal_code: "77407",
    full_city_name: "Richmond, TX",
    city_name: "Richmond",
  },
  {
    postal_code: "45243",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "75052",
    full_city_name: "Grand Prairie, TX",
    city_name: "Grand Prairie",
  },
  {
    postal_code: "84115",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "30067",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "40228",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "65301",
    full_city_name: "Sedalia, MO",
    city_name: "Sedalia",
  },
  {
    postal_code: "75287",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "96813",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "60106",
    full_city_name: "Bensenville, IL",
    city_name: "Bensenville",
  },
  {
    postal_code: "81147",
    full_city_name: "Pagosa Springs, CO",
    city_name: "Pagosa Springs",
  },
  {
    postal_code: "89104",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "32803",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "17070",
    full_city_name: "New Cumberland, PA",
    city_name: "New Cumberland",
  },
  {
    postal_code: "33033",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "60148",
    full_city_name: "Lombard, IL",
    city_name: "Lombard",
  },
  {
    postal_code: "38128",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "75056",
    full_city_name: "The Colony, TX",
    city_name: "The Colony",
  },
  {
    postal_code: "20176",
    full_city_name: "Leesburg, VA",
    city_name: "Leesburg",
  },
  {
    postal_code: "84057",
    full_city_name: "Orem, UT",
    city_name: "Orem",
  },
  {
    postal_code: "46226",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "33931",
    full_city_name: "Fort Myers Beach, FL",
    city_name: "Fort Myers Beach",
  },
  {
    postal_code: "94539",
    full_city_name: "Fremont, CA",
    city_name: "Fremont",
  },
  {
    postal_code: "19355",
    full_city_name: "Malvern, PA",
    city_name: "Malvern",
  },
  {
    postal_code: "60630",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "27107",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "62040",
    full_city_name: "Granite City, IL",
    city_name: "Granite City",
  },
  {
    postal_code: "55407",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "74012",
    full_city_name: "Broken Arrow, OK",
    city_name: "Broken Arrow",
  },
  {
    postal_code: "16509",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "28269",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "33130",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "84003",
    full_city_name: "American Fork, UT",
    city_name: "American Fork",
  },
  {
    postal_code: "15102",
    full_city_name: "Bethel Park, PA",
    city_name: "Bethel Park",
  },
  {
    postal_code: "06405",
    full_city_name: "Branford, CT",
    city_name: "Branford",
  },
  {
    postal_code: "58601",
    full_city_name: "Dickinson, ND",
    city_name: "Dickinson",
  },
  {
    postal_code: "30116",
    full_city_name: "Carrollton, GA",
    city_name: "Carrollton",
  },
  {
    postal_code: "34291",
    full_city_name: "North Port, FL",
    city_name: "North Port",
  },
  {
    postal_code: "79110",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "77447",
    full_city_name: "Hockley, TX",
    city_name: "Hockley",
  },
  {
    postal_code: "40342",
    full_city_name: "Lawrenceburg, KY",
    city_name: "Lawrenceburg",
  },
  {
    postal_code: "29527",
    full_city_name: "Conway, SC",
    city_name: "Conway",
  },
  {
    postal_code: "44484",
    full_city_name: "Warren, OH",
    city_name: "Warren",
  },
  {
    postal_code: "36542",
    full_city_name: "Gulf Shores, AL",
    city_name: "Gulf Shores",
  },
  {
    postal_code: "56301",
    full_city_name: "Saint Cloud, MN",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "28031",
    full_city_name: "Cornelius, NC",
    city_name: "Cornelius",
  },
  {
    postal_code: "34293",
    full_city_name: "Venice, FL",
    city_name: "Venice",
  },
  {
    postal_code: "77072",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "59101",
    full_city_name: "Billings, MT",
    city_name: "Billings",
  },
  {
    postal_code: "93313",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "41071",
    full_city_name: "Newport, KY",
    city_name: "Newport",
  },
  {
    postal_code: "55426",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "33461",
    full_city_name: "Lake Worth, FL",
    city_name: "Lake Worth",
  },
  {
    postal_code: "92236",
    full_city_name: "Coachella, CA",
    city_name: "Coachella",
  },
  {
    postal_code: "55046",
    full_city_name: "Lonsdale, MN",
    city_name: "Lonsdale",
  },
  {
    postal_code: "92314",
    full_city_name: "Big Bear City, CA",
    city_name: "Big Bear City",
  },
  {
    postal_code: "94560",
    full_city_name: "Newark, CA",
    city_name: "Newark",
  },
  {
    postal_code: "78681",
    full_city_name: "Round Rock, TX",
    city_name: "Round Rock",
  },
  {
    postal_code: "95203",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "19713",
    full_city_name: "Newark, DE",
    city_name: "Newark",
  },
  {
    postal_code: "82007",
    full_city_name: "Cheyenne, WY",
    city_name: "Cheyenne",
  },
  {
    postal_code: "23141",
    full_city_name: "Quinton, VA",
    city_name: "Quinton",
  },
  {
    postal_code: "74114",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "32935",
    full_city_name: "Melbourne, FL",
    city_name: "Melbourne",
  },
  {
    postal_code: "23803",
    full_city_name: "Petersburg, VA",
    city_name: "Petersburg",
  },
  {
    postal_code: "77584",
    full_city_name: "Pearland, TX",
    city_name: "Pearland",
  },
  {
    postal_code: "46202",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "74137",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "89044",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "91387",
    full_city_name: "Canyon Country, CA",
    city_name: "Canyon Country",
  },
  {
    postal_code: "81211",
    full_city_name: "Buena Vista, CO",
    city_name: "Buena Vista",
  },
  {
    postal_code: "06877",
    full_city_name: "Ridgefield, CT",
    city_name: "Ridgefield",
  },
  {
    postal_code: "76018",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "60440",
    full_city_name: "Bolingbrook, IL",
    city_name: "Bolingbrook",
  },
  {
    postal_code: "74129",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "87507",
    full_city_name: "Santa Fe, NM",
    city_name: "Santa Fe",
  },
  {
    postal_code: "30656",
    full_city_name: "Monroe, GA",
    city_name: "Monroe",
  },
  {
    postal_code: "33549",
    full_city_name: "Lutz, FL",
    city_name: "Lutz",
  },
  {
    postal_code: "94545",
    full_city_name: "Hayward, CA",
    city_name: "Hayward",
  },
  {
    postal_code: "77356",
    full_city_name: "Montgomery, TX",
    city_name: "Montgomery",
  },
  {
    postal_code: "28075",
    full_city_name: "Harrisburg, NC",
    city_name: "Harrisburg",
  },
  {
    postal_code: "60107",
    full_city_name: "Streamwood, IL",
    city_name: "Streamwood",
  },
  {
    postal_code: "78148",
    full_city_name: "Universal City, TX",
    city_name: "Universal City",
  },
  {
    postal_code: "35243",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "84005",
    full_city_name: "Eagle Mountain, UT",
    city_name: "Eagle Mountain",
  },
  {
    postal_code: "91311",
    full_city_name: "Chatsworth, CA",
    city_name: "Chatsworth",
  },
  {
    postal_code: "70114",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "33813",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "85741",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "74107",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "22102",
    full_city_name: "Mc Lean, VA",
    city_name: "Mc Lean",
  },
  {
    postal_code: "15317",
    full_city_name: "Canonsburg, PA",
    city_name: "Canonsburg",
  },
  {
    postal_code: "75035",
    full_city_name: "Frisco, TX",
    city_name: "Frisco",
  },
  {
    postal_code: "84124",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "58701",
    full_city_name: "Minot, ND",
    city_name: "Minot",
  },
  {
    postal_code: "43202",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "95219",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "86004",
    full_city_name: "Flagstaff, AZ",
    city_name: "Flagstaff",
  },
  {
    postal_code: "22152",
    full_city_name: "Springfield, VA",
    city_name: "Springfield",
  },
  {
    postal_code: "13815",
    full_city_name: "Norwich, NY",
    city_name: "Norwich",
  },
  {
    postal_code: "32217",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "78247",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "68007",
    full_city_name: "Bennington, NE",
    city_name: "Bennington",
  },
  {
    postal_code: "10003",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "43235",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "56537",
    full_city_name: "Fergus Falls, MN",
    city_name: "Fergus Falls",
  },
  {
    postal_code: "44011",
    full_city_name: "Avon, OH",
    city_name: "Avon",
  },
  {
    postal_code: "19046",
    full_city_name: "Jenkintown, PA",
    city_name: "Jenkintown",
  },
  {
    postal_code: "63501",
    full_city_name: "Kirksville, MO",
    city_name: "Kirksville",
  },
  {
    postal_code: "46224",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "76118",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "08691",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "33319",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "30168",
    full_city_name: "Austell, GA",
    city_name: "Austell",
  },
  {
    postal_code: "11235",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "30121",
    full_city_name: "Cartersville, GA",
    city_name: "Cartersville",
  },
  {
    postal_code: "44133",
    full_city_name: "North Royalton, OH",
    city_name: "North Royalton",
  },
  {
    postal_code: "76084",
    full_city_name: "Venus, TX",
    city_name: "Venus",
  },
  {
    postal_code: "06489",
    full_city_name: "Southington, CT",
    city_name: "Southington",
  },
  {
    postal_code: "60045",
    full_city_name: "Lake Forest, IL",
    city_name: "Lake Forest",
  },
  {
    postal_code: "91701",
    full_city_name: "Rancho Cucamonga, CA",
    city_name: "Rancho Cucamonga",
  },
  {
    postal_code: "63126",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "77511",
    full_city_name: "Alvin, TX",
    city_name: "Alvin",
  },
  {
    postal_code: "33426",
    full_city_name: "Boynton Beach, FL",
    city_name: "Boynton Beach",
  },
  {
    postal_code: "19475",
    full_city_name: "Spring City, PA",
    city_name: "Spring City",
  },
  {
    postal_code: "33067",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "60030",
    full_city_name: "Grayslake, IL",
    city_name: "Grayslake",
  },
  {
    postal_code: "27612",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "33166",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "19063",
    full_city_name: "Media, PA",
    city_name: "Media",
  },
  {
    postal_code: "91360",
    full_city_name: "Thousand Oaks, CA",
    city_name: "Thousand Oaks",
  },
  {
    postal_code: "28532",
    full_city_name: "Havelock, NC",
    city_name: "Havelock",
  },
  {
    postal_code: "62269",
    full_city_name: "O Fallon, IL",
    city_name: "O Fallon",
  },
  {
    postal_code: "93505",
    full_city_name: "California City, CA",
    city_name: "California City",
  },
  {
    postal_code: "22554",
    full_city_name: "Stafford, VA",
    city_name: "Stafford",
  },
  {
    postal_code: "28540",
    full_city_name: "Jacksonville, NC",
    city_name: "Jacksonville",
  },
  {
    postal_code: "19125",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "92677",
    full_city_name: "Laguna Niguel, CA",
    city_name: "Laguna Niguel",
  },
  {
    postal_code: "46151",
    full_city_name: "Martinsville, IN",
    city_name: "Martinsville",
  },
  {
    postal_code: "19149",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "85205",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "19053",
    full_city_name: "Feasterville Trevose, PA",
    city_name: "Feasterville Trevose",
  },
  {
    postal_code: "19605",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "99207",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "77346",
    full_city_name: "Humble, TX",
    city_name: "Humble",
  },
  {
    postal_code: "78731",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "84106",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "22968",
    full_city_name: "Ruckersville, VA",
    city_name: "Ruckersville",
  },
  {
    postal_code: "27519",
    full_city_name: "Cary, NC",
    city_name: "Cary",
  },
  {
    postal_code: "45431",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "55398",
    full_city_name: "Zimmerman, MN",
    city_name: "Zimmerman",
  },
  {
    postal_code: "75022",
    full_city_name: "Flower Mound, TX",
    city_name: "Flower Mound",
  },
  {
    postal_code: "75189",
    full_city_name: "Royse City, TX",
    city_name: "Royse City",
  },
  {
    postal_code: "33541",
    full_city_name: "Zephyrhills, FL",
    city_name: "Zephyrhills",
  },
  {
    postal_code: "06019",
    full_city_name: "Canton, CT",
    city_name: "Canton",
  },
  {
    postal_code: "46410",
    full_city_name: "Merrillville, IN",
    city_name: "Merrillville",
  },
  {
    postal_code: "06902",
    full_city_name: "Stamford, CT",
    city_name: "Stamford",
  },
  {
    postal_code: "55410",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "33064",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "27406",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "44420",
    full_city_name: "Girard, OH",
    city_name: "Girard",
  },
  {
    postal_code: "03244",
    full_city_name: "Hillsborough, NH",
    city_name: "Hillsborough",
  },
  {
    postal_code: "16801",
    full_city_name: "State College, PA",
    city_name: "State College",
  },
  {
    postal_code: "92260",
    full_city_name: "Palm Desert, CA",
    city_name: "Palm Desert",
  },
  {
    postal_code: "72118",
    full_city_name: "North Little Rock, AR",
    city_name: "North Little Rock",
  },
  {
    postal_code: "33778",
    full_city_name: "Largo, FL",
    city_name: "Largo",
  },
  {
    postal_code: "79106",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "77009",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "32937",
    full_city_name: "Satellite Beach, FL",
    city_name: "Satellite Beach",
  },
  {
    postal_code: "60171",
    full_city_name: "River Grove, IL",
    city_name: "River Grove",
  },
  {
    postal_code: "46055",
    full_city_name: "Mccordsville, IN",
    city_name: "Mccordsville",
  },
  {
    postal_code: "11210",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "02360",
    full_city_name: "Plymouth, MA",
    city_name: "Plymouth",
  },
  {
    postal_code: "27615",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "28443",
    full_city_name: "Hampstead, NC",
    city_name: "Hampstead",
  },
  {
    postal_code: "19082",
    full_city_name: "Upper Darby, PA",
    city_name: "Upper Darby",
  },
  {
    postal_code: "30317",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "11791",
    full_city_name: "Syosset, NY",
    city_name: "Syosset",
  },
  {
    postal_code: "30046",
    full_city_name: "Lawrenceville, GA",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "95330",
    full_city_name: "Lathrop, CA",
    city_name: "Lathrop",
  },
  {
    postal_code: "27834",
    full_city_name: "Greenville, NC",
    city_name: "Greenville",
  },
  {
    postal_code: "29334",
    full_city_name: "Duncan, SC",
    city_name: "Duncan",
  },
  {
    postal_code: "96816",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "89118",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "33405",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "27278",
    full_city_name: "Hillsborough, NC",
    city_name: "Hillsborough",
  },
  {
    postal_code: "85016",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "63090",
    full_city_name: "Washington, MO",
    city_name: "Washington",
  },
  {
    postal_code: "80620",
    full_city_name: "Evans, CO",
    city_name: "Evans",
  },
  {
    postal_code: "33991",
    full_city_name: "Cape Coral, FL",
    city_name: "Cape Coral",
  },
  {
    postal_code: "22309",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "33556",
    full_city_name: "Odessa, FL",
    city_name: "Odessa",
  },
  {
    postal_code: "75051",
    full_city_name: "Grand Prairie, TX",
    city_name: "Grand Prairie",
  },
  {
    postal_code: "44121",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "39180",
    full_city_name: "Vicksburg, MS",
    city_name: "Vicksburg",
  },
  {
    postal_code: "40212",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "91301",
    full_city_name: "Agoura Hills, CA",
    city_name: "Agoura Hills",
  },
  {
    postal_code: "32606",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "74055",
    full_city_name: "Owasso, OK",
    city_name: "Owasso",
  },
  {
    postal_code: "43614",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "78374",
    full_city_name: "Portland, TX",
    city_name: "Portland",
  },
  {
    postal_code: "89081",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "94588",
    full_city_name: "Pleasanton, CA",
    city_name: "Pleasanton",
  },
  {
    postal_code: "08081",
    full_city_name: "Sicklerville, NJ",
    city_name: "Sicklerville",
  },
  {
    postal_code: "34715",
    full_city_name: "Clermont, FL",
    city_name: "Clermont",
  },
  {
    postal_code: "77591",
    full_city_name: "Texas City, TX",
    city_name: "Texas City",
  },
  {
    postal_code: "33018",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "27896",
    full_city_name: "Wilson, NC",
    city_name: "Wilson",
  },
  {
    postal_code: "30004",
    full_city_name: "Alpharetta, GA",
    city_name: "Alpharetta",
  },
  {
    postal_code: "61705",
    full_city_name: "Bloomington, IL",
    city_name: "Bloomington",
  },
  {
    postal_code: "33404",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "85132",
    full_city_name: "Florence, AZ",
    city_name: "Florence",
  },
  {
    postal_code: "75762",
    full_city_name: "Flint, TX",
    city_name: "Flint",
  },
  {
    postal_code: "80302",
    full_city_name: "Boulder, CO",
    city_name: "Boulder",
  },
  {
    postal_code: "46060",
    full_city_name: "Noblesville, IN",
    city_name: "Noblesville",
  },
  {
    postal_code: "92562",
    full_city_name: "Murrieta, CA",
    city_name: "Murrieta",
  },
  {
    postal_code: "55116",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "60429",
    full_city_name: "Hazel Crest, IL",
    city_name: "Hazel Crest",
  },
  {
    postal_code: "60542",
    full_city_name: "North Aurora, IL",
    city_name: "North Aurora",
  },
  {
    postal_code: "33897",
    full_city_name: "Davenport, FL",
    city_name: "Davenport",
  },
  {
    postal_code: "55443",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "75098",
    full_city_name: "Wylie, TX",
    city_name: "Wylie",
  },
  {
    postal_code: "11747",
    full_city_name: "Melville, NY",
    city_name: "Melville",
  },
  {
    postal_code: "55376",
    full_city_name: "Saint Michael, MN",
    city_name: "Saint Michael",
  },
  {
    postal_code: "89147",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "30294",
    full_city_name: "Ellenwood, GA",
    city_name: "Ellenwood",
  },
  {
    postal_code: "78520",
    full_city_name: "Brownsville, TX",
    city_name: "Brownsville",
  },
  {
    postal_code: "46307",
    full_city_name: "Crown Point, IN",
    city_name: "Crown Point",
  },
  {
    postal_code: "19428",
    full_city_name: "Conshohocken, PA",
    city_name: "Conshohocken",
  },
  {
    postal_code: "93309",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "46032",
    full_city_name: "Carmel, IN",
    city_name: "Carmel",
  },
  {
    postal_code: "61350",
    full_city_name: "Ottawa, IL",
    city_name: "Ottawa",
  },
  {
    postal_code: "22030",
    full_city_name: "Fairfax, VA",
    city_name: "Fairfax",
  },
  {
    postal_code: "08052",
    full_city_name: "Maple Shade, NJ",
    city_name: "Maple Shade",
  },
  {
    postal_code: "75209",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "33442",
    full_city_name: "Deerfield Beach, FL",
    city_name: "Deerfield Beach",
  },
  {
    postal_code: "45230",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "21157",
    full_city_name: "Westminster, MD",
    city_name: "Westminster",
  },
  {
    postal_code: "50310",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "30126",
    full_city_name: "Mableton, GA",
    city_name: "Mableton",
  },
  {
    postal_code: "68507",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "55066",
    full_city_name: "Red Wing, MN",
    city_name: "Red Wing",
  },
  {
    postal_code: "61107",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "33955",
    full_city_name: "Punta Gorda, FL",
    city_name: "Punta Gorda",
  },
  {
    postal_code: "20001",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "06820",
    full_city_name: "Darien, CT",
    city_name: "Darien",
  },
  {
    postal_code: "72022",
    full_city_name: "Bryant, AR",
    city_name: "Bryant",
  },
  {
    postal_code: "99203",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "48182",
    full_city_name: "Temperance, MI",
    city_name: "Temperance",
  },
  {
    postal_code: "25414",
    full_city_name: "Charles Town, WV",
    city_name: "Charles Town",
  },
  {
    postal_code: "92591",
    full_city_name: "Temecula, CA",
    city_name: "Temecula",
  },
  {
    postal_code: "94002",
    full_city_name: "Belmont, CA",
    city_name: "Belmont",
  },
  {
    postal_code: "33073",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "32216",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "93257",
    full_city_name: "Porterville, CA",
    city_name: "Porterville",
  },
  {
    postal_code: "60661",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "96826",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "12508",
    full_city_name: "Beacon, NY",
    city_name: "Beacon",
  },
  {
    postal_code: "84070",
    full_city_name: "Sandy, UT",
    city_name: "Sandy",
  },
  {
    postal_code: "27103",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "02050",
    full_city_name: "Marshfield, MA",
    city_name: "Marshfield",
  },
  {
    postal_code: "77521",
    full_city_name: "Baytown, TX",
    city_name: "Baytown",
  },
  {
    postal_code: "96722",
    full_city_name: "Princeville, HI",
    city_name: "Princeville",
  },
  {
    postal_code: "43082",
    full_city_name: "Westerville, OH",
    city_name: "Westerville",
  },
  {
    postal_code: "19720",
    full_city_name: "New Castle, DE",
    city_name: "New Castle",
  },
  {
    postal_code: "34691",
    full_city_name: "Holiday, FL",
    city_name: "Holiday",
  },
  {
    postal_code: "80923",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "34984",
    full_city_name: "Port Saint Lucie, FL",
    city_name: "Port Saint Lucie",
  },
  {
    postal_code: "76548",
    full_city_name: "Harker Heights, TX",
    city_name: "Harker Heights",
  },
  {
    postal_code: "75060",
    full_city_name: "Irving, TX",
    city_name: "Irving",
  },
  {
    postal_code: "22204",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "29577",
    full_city_name: "Myrtle Beach, SC",
    city_name: "Myrtle Beach",
  },
  {
    postal_code: "77047",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "65775",
    full_city_name: "West Plains, MO",
    city_name: "West Plains",
  },
  {
    postal_code: "27596",
    full_city_name: "Youngsville, NC",
    city_name: "Youngsville",
  },
  {
    postal_code: "15010",
    full_city_name: "Beaver Falls, PA",
    city_name: "Beaver Falls",
  },
  {
    postal_code: "20850",
    full_city_name: "Rockville, MD",
    city_name: "Rockville",
  },
  {
    postal_code: "12159",
    full_city_name: "Slingerlands, NY",
    city_name: "Slingerlands",
  },
  {
    postal_code: "95819",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "02368",
    full_city_name: "Randolph, MA",
    city_name: "Randolph",
  },
  {
    postal_code: "30549",
    full_city_name: "Jefferson, GA",
    city_name: "Jefferson",
  },
  {
    postal_code: "72751",
    full_city_name: "Pea Ridge, AR",
    city_name: "Pea Ridge",
  },
  {
    postal_code: "19047",
    full_city_name: "Langhorne, PA",
    city_name: "Langhorne",
  },
  {
    postal_code: "94131",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "33436",
    full_city_name: "Boynton Beach, FL",
    city_name: "Boynton Beach",
  },
  {
    postal_code: "37919",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "76092",
    full_city_name: "Southlake, TX",
    city_name: "Southlake",
  },
  {
    postal_code: "40213",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "16105",
    full_city_name: "New Castle, PA",
    city_name: "New Castle",
  },
  {
    postal_code: "19363",
    full_city_name: "Oxford, PA",
    city_name: "Oxford",
  },
  {
    postal_code: "30114",
    full_city_name: "Canton, GA",
    city_name: "Canton",
  },
  {
    postal_code: "54022",
    full_city_name: "River Falls, WI",
    city_name: "River Falls",
  },
  {
    postal_code: "79109",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "99205",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "16335",
    full_city_name: "Meadville, PA",
    city_name: "Meadville",
  },
  {
    postal_code: "19127",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "23225",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "60510",
    full_city_name: "Batavia, IL",
    city_name: "Batavia",
  },
  {
    postal_code: "62035",
    full_city_name: "Godfrey, IL",
    city_name: "Godfrey",
  },
  {
    postal_code: "21234",
    full_city_name: "Parkville, MD",
    city_name: "Parkville",
  },
  {
    postal_code: "94804",
    full_city_name: "Richmond, CA",
    city_name: "Richmond",
  },
  {
    postal_code: "45239",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "21037",
    full_city_name: "Edgewater, MD",
    city_name: "Edgewater",
  },
  {
    postal_code: "33154",
    full_city_name: "Miami Beach, FL",
    city_name: "Miami Beach",
  },
  {
    postal_code: "76549",
    full_city_name: "Killeen, TX",
    city_name: "Killeen",
  },
  {
    postal_code: "99216",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "64131",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "30102",
    full_city_name: "Acworth, GA",
    city_name: "Acworth",
  },
  {
    postal_code: "91324",
    full_city_name: "Northridge, CA",
    city_name: "Northridge",
  },
  {
    postal_code: "45224",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "75454",
    full_city_name: "Melissa, TX",
    city_name: "Melissa",
  },
  {
    postal_code: "96825",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "37938",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "32792",
    full_city_name: "Winter Park, FL",
    city_name: "Winter Park",
  },
  {
    postal_code: "89178",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "68105",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "90745",
    full_city_name: "Carson, CA",
    city_name: "Carson",
  },
  {
    postal_code: "90026",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "11214",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "85375",
    full_city_name: "Sun City West, AZ",
    city_name: "Sun City West",
  },
  {
    postal_code: "34242",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "28205",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "99202",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "61614",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "63114",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "85143",
    full_city_name: "San Tan Valley, AZ",
    city_name: "San Tan Valley",
  },
  {
    postal_code: "84120",
    full_city_name: "West Valley City, UT",
    city_name: "West Valley City",
  },
  {
    postal_code: "06360",
    full_city_name: "Norwich, CT",
    city_name: "Norwich",
  },
  {
    postal_code: "89509",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "55118",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "19050",
    full_city_name: "Lansdowne, PA",
    city_name: "Lansdowne",
  },
  {
    postal_code: "92394",
    full_city_name: "Victorville, CA",
    city_name: "Victorville",
  },
  {
    postal_code: "36109",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "19422",
    full_city_name: "Blue Bell, PA",
    city_name: "Blue Bell",
  },
  {
    postal_code: "27376",
    full_city_name: "West End, NC",
    city_name: "West End",
  },
  {
    postal_code: "34957",
    full_city_name: "Jensen Beach, FL",
    city_name: "Jensen Beach",
  },
  {
    postal_code: "28467",
    full_city_name: "Calabash, NC",
    city_name: "Calabash",
  },
  {
    postal_code: "28083",
    full_city_name: "Kannapolis, NC",
    city_name: "Kannapolis",
  },
  {
    postal_code: "43207",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "43232",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "28312",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "20169",
    full_city_name: "Haymarket, VA",
    city_name: "Haymarket",
  },
  {
    postal_code: "77379",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "79938",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "14048",
    full_city_name: "Dunkirk, NY",
    city_name: "Dunkirk",
  },
  {
    postal_code: "29730",
    full_city_name: "Rock Hill, SC",
    city_name: "Rock Hill",
  },
  {
    postal_code: "77345",
    full_city_name: "Kingwood, TX",
    city_name: "Kingwood",
  },
  {
    postal_code: "78660",
    full_city_name: "Pflugerville, TX",
    city_name: "Pflugerville",
  },
  {
    postal_code: "32951",
    full_city_name: "Melbourne Beach, FL",
    city_name: "Melbourne Beach",
  },
  {
    postal_code: "48103",
    full_city_name: "Ann Arbor, MI",
    city_name: "Ann Arbor",
  },
  {
    postal_code: "19801",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "78757",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "89011",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "19147",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "34219",
    full_city_name: "Parrish, FL",
    city_name: "Parrish",
  },
  {
    postal_code: "78717",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "19508",
    full_city_name: "Birdsboro, PA",
    city_name: "Birdsboro",
  },
  {
    postal_code: "60046",
    full_city_name: "Lake Villa, IL",
    city_name: "Lake Villa",
  },
  {
    postal_code: "55428",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "68005",
    full_city_name: "Bellevue, NE",
    city_name: "Bellevue",
  },
  {
    postal_code: "44406",
    full_city_name: "Canfield, OH",
    city_name: "Canfield",
  },
  {
    postal_code: "91307",
    full_city_name: "West Hills, CA",
    city_name: "West Hills",
  },
  {
    postal_code: "32824",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "35040",
    full_city_name: "Calera, AL",
    city_name: "Calera",
  },
  {
    postal_code: "96822",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "34211",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "33463",
    full_city_name: "Lake Worth, FL",
    city_name: "Lake Worth",
  },
  {
    postal_code: "50208",
    full_city_name: "Newton, IA",
    city_name: "Newton",
  },
  {
    postal_code: "19809",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "94607",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "11238",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "34714",
    full_city_name: "Clermont, FL",
    city_name: "Clermont",
  },
  {
    postal_code: "19382",
    full_city_name: "West Chester, PA",
    city_name: "West Chester",
  },
  {
    postal_code: "33472",
    full_city_name: "Boynton Beach, FL",
    city_name: "Boynton Beach",
  },
  {
    postal_code: "63111",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "81506",
    full_city_name: "Grand Junction, CO",
    city_name: "Grand Junction",
  },
  {
    postal_code: "31411",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "60192",
    full_city_name: "Hoffman Estates, IL",
    city_name: "Hoffman Estates",
  },
  {
    postal_code: "85308",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "08322",
    full_city_name: "Franklinville, NJ",
    city_name: "Franklinville",
  },
  {
    postal_code: "19104",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "91784",
    full_city_name: "Upland, CA",
    city_name: "Upland",
  },
  {
    postal_code: "93268",
    full_city_name: "Taft, CA",
    city_name: "Taft",
  },
  {
    postal_code: "55337",
    full_city_name: "Burnsville, MN",
    city_name: "Burnsville",
  },
  {
    postal_code: "76108",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "38572",
    full_city_name: "Crossville, TN",
    city_name: "Crossville",
  },
  {
    postal_code: "85226",
    full_city_name: "Chandler, AZ",
    city_name: "Chandler",
  },
  {
    postal_code: "78526",
    full_city_name: "Brownsville, TX",
    city_name: "Brownsville",
  },
  {
    postal_code: "77082",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "99016",
    full_city_name: "Greenacres, WA",
    city_name: "Greenacres",
  },
  {
    postal_code: "55448",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "23237",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "34788",
    full_city_name: "Leesburg, FL",
    city_name: "Leesburg",
  },
  {
    postal_code: "10001",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "55109",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "30339",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "93305",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "33156",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "77478",
    full_city_name: "Sugar Land, TX",
    city_name: "Sugar Land",
  },
  {
    postal_code: "48108",
    full_city_name: "Ann Arbor, MI",
    city_name: "Ann Arbor",
  },
  {
    postal_code: "78023",
    full_city_name: "Helotes, TX",
    city_name: "Helotes",
  },
  {
    postal_code: "60076",
    full_city_name: "Skokie, IL",
    city_name: "Skokie",
  },
  {
    postal_code: "28374",
    full_city_name: "Pinehurst, NC",
    city_name: "Pinehurst",
  },
  {
    postal_code: "70563",
    full_city_name: "New Iberia, LA",
    city_name: "New Iberia",
  },
  {
    postal_code: "95841",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "72761",
    full_city_name: "Siloam Springs, AR",
    city_name: "Siloam Springs",
  },
  {
    postal_code: "08009",
    full_city_name: "Berlin, NJ",
    city_name: "Berlin",
  },
  {
    postal_code: "63136",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "43311",
    full_city_name: "Bellefontaine, OH",
    city_name: "Bellefontaine",
  },
  {
    postal_code: "32952",
    full_city_name: "Merritt Island, FL",
    city_name: "Merritt Island",
  },
  {
    postal_code: "28734",
    full_city_name: "Franklin, NC",
    city_name: "Franklin",
  },
  {
    postal_code: "08110",
    full_city_name: "Pennsauken, NJ",
    city_name: "Pennsauken",
  },
  {
    postal_code: "15205",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "74075",
    full_city_name: "Stillwater, OK",
    city_name: "Stillwater",
  },
  {
    postal_code: "94014",
    full_city_name: "Daly City, CA",
    city_name: "Daly City",
  },
  {
    postal_code: "77581",
    full_city_name: "Pearland, TX",
    city_name: "Pearland",
  },
  {
    postal_code: "95240",
    full_city_name: "Lodi, CA",
    city_name: "Lodi",
  },
  {
    postal_code: "92371",
    full_city_name: "Phelan, CA",
    city_name: "Phelan",
  },
  {
    postal_code: "80517",
    full_city_name: "Estes Park, CO",
    city_name: "Estes Park",
  },
  {
    postal_code: "15301",
    full_city_name: "Washington, PA",
    city_name: "Washington",
  },
  {
    postal_code: "44001",
    full_city_name: "Amherst, OH",
    city_name: "Amherst",
  },
  {
    postal_code: "62002",
    full_city_name: "Alton, IL",
    city_name: "Alton",
  },
  {
    postal_code: "78154",
    full_city_name: "Schertz, TX",
    city_name: "Schertz",
  },
  {
    postal_code: "75010",
    full_city_name: "Carrollton, TX",
    city_name: "Carrollton",
  },
  {
    postal_code: "85340",
    full_city_name: "Litchfield Park, AZ",
    city_name: "Litchfield Park",
  },
  {
    postal_code: "15068",
    full_city_name: "New Kensington, PA",
    city_name: "New Kensington",
  },
  {
    postal_code: "15025",
    full_city_name: "Clairton, PA",
    city_name: "Clairton",
  },
  {
    postal_code: "33325",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "55901",
    full_city_name: "Rochester, MN",
    city_name: "Rochester",
  },
  {
    postal_code: "11201",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "77520",
    full_city_name: "Baytown, TX",
    city_name: "Baytown",
  },
  {
    postal_code: "06513",
    full_city_name: "New Haven, CT",
    city_name: "New Haven",
  },
  {
    postal_code: "84302",
    full_city_name: "Brigham City, UT",
    city_name: "Brigham City",
  },
  {
    postal_code: "60441",
    full_city_name: "Lockport, IL",
    city_name: "Lockport",
  },
  {
    postal_code: "92647",
    full_city_name: "Huntington Beach, CA",
    city_name: "Huntington Beach",
  },
  {
    postal_code: "70507",
    full_city_name: "Lafayette, LA",
    city_name: "Lafayette",
  },
  {
    postal_code: "84054",
    full_city_name: "North Salt Lake, UT",
    city_name: "North Salt Lake",
  },
  {
    postal_code: "37801",
    full_city_name: "Maryville, TN",
    city_name: "Maryville",
  },
  {
    postal_code: "60453",
    full_city_name: "Oak Lawn, IL",
    city_name: "Oak Lawn",
  },
  {
    postal_code: "76210",
    full_city_name: "Denton, TX",
    city_name: "Denton",
  },
  {
    postal_code: "85142",
    full_city_name: "Queen Creek, AZ",
    city_name: "Queen Creek",
  },
  {
    postal_code: "81321",
    full_city_name: "Cortez, CO",
    city_name: "Cortez",
  },
  {
    postal_code: "04901",
    full_city_name: "Waterville, ME",
    city_name: "Waterville",
  },
  {
    postal_code: "33189",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "91384",
    full_city_name: "Castaic, CA",
    city_name: "Castaic",
  },
  {
    postal_code: "12477",
    full_city_name: "Saugerties, NY",
    city_name: "Saugerties",
  },
  {
    postal_code: "19141",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "77471",
    full_city_name: "Rosenberg, TX",
    city_name: "Rosenberg",
  },
  {
    postal_code: "94587",
    full_city_name: "Union City, CA",
    city_name: "Union City",
  },
  {
    postal_code: "30032",
    full_city_name: "Decatur, GA",
    city_name: "Decatur",
  },
  {
    postal_code: "78552",
    full_city_name: "Harlingen, TX",
    city_name: "Harlingen",
  },
  {
    postal_code: "33133",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "30120",
    full_city_name: "Cartersville, GA",
    city_name: "Cartersville",
  },
  {
    postal_code: "12047",
    full_city_name: "Cohoes, NY",
    city_name: "Cohoes",
  },
  {
    postal_code: "78653",
    full_city_name: "Manor, TX",
    city_name: "Manor",
  },
  {
    postal_code: "34207",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "27106",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "60137",
    full_city_name: "Glen Ellyn, IL",
    city_name: "Glen Ellyn",
  },
  {
    postal_code: "77386",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "75071",
    full_city_name: "Mckinney, TX",
    city_name: "Mckinney",
  },
  {
    postal_code: "95673",
    full_city_name: "Rio Linda, CA",
    city_name: "Rio Linda",
  },
  {
    postal_code: "91406",
    full_city_name: "Van Nuys, CA",
    city_name: "Van Nuys",
  },
  {
    postal_code: "11230",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "60411",
    full_city_name: "Chicago Heights, IL",
    city_name: "Chicago Heights",
  },
  {
    postal_code: "89706",
    full_city_name: "Carson City, NV",
    city_name: "Carson City",
  },
  {
    postal_code: "37086",
    full_city_name: "La Vergne, TN",
    city_name: "La Vergne",
  },
  {
    postal_code: "99006",
    full_city_name: "Deer Park, WA",
    city_name: "Deer Park",
  },
  {
    postal_code: "55016",
    full_city_name: "Cottage Grove, MN",
    city_name: "Cottage Grove",
  },
  {
    postal_code: "75093",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "08057",
    full_city_name: "Moorestown, NJ",
    city_name: "Moorestown",
  },
  {
    postal_code: "65049",
    full_city_name: "Lake Ozark, MO",
    city_name: "Lake Ozark",
  },
  {
    postal_code: "06415",
    full_city_name: "Colchester, CT",
    city_name: "Colchester",
  },
  {
    postal_code: "94523",
    full_city_name: "Pleasant Hill, CA",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "75180",
    full_city_name: "Balch Springs, TX",
    city_name: "Balch Springs",
  },
  {
    postal_code: "28212",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "75409",
    full_city_name: "Anna, TX",
    city_name: "Anna",
  },
  {
    postal_code: "85212",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "12110",
    full_city_name: "Latham, NY",
    city_name: "Latham",
  },
  {
    postal_code: "85085",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "11236",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "77573",
    full_city_name: "League City, TX",
    city_name: "League City",
  },
  {
    postal_code: "03304",
    full_city_name: "Bow, NH",
    city_name: "Bow",
  },
  {
    postal_code: "11101",
    full_city_name: "Long Island City, NY",
    city_name: "Long Island City",
  },
  {
    postal_code: "90045",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "44140",
    full_city_name: "Bay Village, OH",
    city_name: "Bay Village",
  },
  {
    postal_code: "19143",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "45840",
    full_city_name: "Findlay, OH",
    city_name: "Findlay",
  },
  {
    postal_code: "60188",
    full_city_name: "Carol Stream, IL",
    city_name: "Carol Stream",
  },
  {
    postal_code: "19118",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "32836",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "55057",
    full_city_name: "Northfield, MN",
    city_name: "Northfield",
  },
  {
    postal_code: "30273",
    full_city_name: "Rex, GA",
    city_name: "Rex",
  },
  {
    postal_code: "28412",
    full_city_name: "Wilmington, NC",
    city_name: "Wilmington",
  },
  {
    postal_code: "38558",
    full_city_name: "Crossville, TN",
    city_name: "Crossville",
  },
  {
    postal_code: "63050",
    full_city_name: "Hillsboro, MO",
    city_name: "Hillsboro",
  },
  {
    postal_code: "77079",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "40214",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "84014",
    full_city_name: "Centerville, UT",
    city_name: "Centerville",
  },
  {
    postal_code: "29405",
    full_city_name: "North Charleston, SC",
    city_name: "North Charleston",
  },
  {
    postal_code: "63026",
    full_city_name: "Fenton, MO",
    city_name: "Fenton",
  },
  {
    postal_code: "30328",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "61822",
    full_city_name: "Champaign, IL",
    city_name: "Champaign",
  },
  {
    postal_code: "30076",
    full_city_name: "Roswell, GA",
    city_name: "Roswell",
  },
  {
    postal_code: "30238",
    full_city_name: "Jonesboro, GA",
    city_name: "Jonesboro",
  },
  {
    postal_code: "31312",
    full_city_name: "Guyton, GA",
    city_name: "Guyton",
  },
  {
    postal_code: "63132",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "28594",
    full_city_name: "Emerald Isle, NC",
    city_name: "Emerald Isle",
  },
  {
    postal_code: "47025",
    full_city_name: "Lawrenceburg, IN",
    city_name: "Lawrenceburg",
  },
  {
    postal_code: "32327",
    full_city_name: "Crawfordville, FL",
    city_name: "Crawfordville",
  },
  {
    postal_code: "60450",
    full_city_name: "Morris, IL",
    city_name: "Morris",
  },
  {
    postal_code: "29451",
    full_city_name: "Isle Of Palms, SC",
    city_name: "Isle Of Palms",
  },
  {
    postal_code: "33071",
    full_city_name: "Coral Springs, FL",
    city_name: "Coral Springs",
  },
  {
    postal_code: "80924",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "95662",
    full_city_name: "Orangevale, CA",
    city_name: "Orangevale",
  },
  {
    postal_code: "84095",
    full_city_name: "South Jordan, UT",
    city_name: "South Jordan",
  },
  {
    postal_code: "33570",
    full_city_name: "Ruskin, FL",
    city_name: "Ruskin",
  },
  {
    postal_code: "77339",
    full_city_name: "Kingwood, TX",
    city_name: "Kingwood",
  },
  {
    postal_code: "33573",
    full_city_name: "Sun City Center, FL",
    city_name: "Sun City Center",
  },
  {
    postal_code: "40403",
    full_city_name: "Berea, KY",
    city_name: "Berea",
  },
  {
    postal_code: "84765",
    full_city_name: "Santa Clara, UT",
    city_name: "Santa Clara",
  },
  {
    postal_code: "75081",
    full_city_name: "Richardson, TX",
    city_name: "Richardson",
  },
  {
    postal_code: "10128",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "60409",
    full_city_name: "Calumet City, IL",
    city_name: "Calumet City",
  },
  {
    postal_code: "40272",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "33065",
    full_city_name: "Coral Springs, FL",
    city_name: "Coral Springs",
  },
  {
    postal_code: "95363",
    full_city_name: "Patterson, CA",
    city_name: "Patterson",
  },
  {
    postal_code: "33403",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "34110",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "03246",
    full_city_name: "Laconia, NH",
    city_name: "Laconia",
  },
  {
    postal_code: "89101",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "94598",
    full_city_name: "Walnut Creek, CA",
    city_name: "Walnut Creek",
  },
  {
    postal_code: "02632",
    full_city_name: "Centerville, MA",
    city_name: "Centerville",
  },
  {
    postal_code: "68123",
    full_city_name: "Bellevue, NE",
    city_name: "Bellevue",
  },
  {
    postal_code: "16508",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "72205",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "77303",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "10471",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "78412",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "95367",
    full_city_name: "Riverbank, CA",
    city_name: "Riverbank",
  },
  {
    postal_code: "37138",
    full_city_name: "Old Hickory, TN",
    city_name: "Old Hickory",
  },
  {
    postal_code: "48910",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "18428",
    full_city_name: "Hawley, PA",
    city_name: "Hawley",
  },
  {
    postal_code: "85339",
    full_city_name: "Laveen, AZ",
    city_name: "Laveen",
  },
  {
    postal_code: "02301",
    full_city_name: "Brockton, MA",
    city_name: "Brockton",
  },
  {
    postal_code: "76182",
    full_city_name: "North Richland Hills, TX",
    city_name: "North Richland Hills",
  },
  {
    postal_code: "33913",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "06457",
    full_city_name: "Middletown, CT",
    city_name: "Middletown",
  },
  {
    postal_code: "33066",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "80909",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "45044",
    full_city_name: "Middletown, OH",
    city_name: "Middletown",
  },
  {
    postal_code: "60649",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "41031",
    full_city_name: "Cynthiana, KY",
    city_name: "Cynthiana",
  },
  {
    postal_code: "32796",
    full_city_name: "Titusville, FL",
    city_name: "Titusville",
  },
  {
    postal_code: "80919",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "32751",
    full_city_name: "Maitland, FL",
    city_name: "Maitland",
  },
  {
    postal_code: "78228",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "10065",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "27502",
    full_city_name: "Apex, NC",
    city_name: "Apex",
  },
  {
    postal_code: "29575",
    full_city_name: "Myrtle Beach, SC",
    city_name: "Myrtle Beach",
  },
  {
    postal_code: "77401",
    full_city_name: "Bellaire, TX",
    city_name: "Bellaire",
  },
  {
    postal_code: "32701",
    full_city_name: "Altamonte Springs, FL",
    city_name: "Altamonte Springs",
  },
  {
    postal_code: "89139",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "19073",
    full_city_name: "Newtown Square, PA",
    city_name: "Newtown Square",
  },
  {
    postal_code: "95610",
    full_city_name: "Citrus Heights, CA",
    city_name: "Citrus Heights",
  },
  {
    postal_code: "41005",
    full_city_name: "Burlington, KY",
    city_name: "Burlington",
  },
  {
    postal_code: "94526",
    full_city_name: "Danville, CA",
    city_name: "Danville",
  },
  {
    postal_code: "90044",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "79907",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "08527",
    full_city_name: "Jackson, NJ",
    city_name: "Jackson",
  },
  {
    postal_code: "02864",
    full_city_name: "Cumberland, RI",
    city_name: "Cumberland",
  },
  {
    postal_code: "28215",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "45213",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "40071",
    full_city_name: "Taylorsville, KY",
    city_name: "Taylorsville",
  },
  {
    postal_code: "13027",
    full_city_name: "Baldwinsville, NY",
    city_name: "Baldwinsville",
  },
  {
    postal_code: "33032",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "62864",
    full_city_name: "Mount Vernon, IL",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "76126",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "11209",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "36064",
    full_city_name: "Pike Road, AL",
    city_name: "Pike Road",
  },
  {
    postal_code: "53593",
    full_city_name: "Verona, WI",
    city_name: "Verona",
  },
  {
    postal_code: "06461",
    full_city_name: "Milford, CT",
    city_name: "Milford",
  },
  {
    postal_code: "92311",
    full_city_name: "Barstow, CA",
    city_name: "Barstow",
  },
  {
    postal_code: "23238",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "46112",
    full_city_name: "Brownsburg, IN",
    city_name: "Brownsburg",
  },
  {
    postal_code: "33953",
    full_city_name: "Port Charlotte, FL",
    city_name: "Port Charlotte",
  },
  {
    postal_code: "02645",
    full_city_name: "Harwich, MA",
    city_name: "Harwich",
  },
  {
    postal_code: "79932",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "33626",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "30044",
    full_city_name: "Lawrenceville, GA",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "18411",
    full_city_name: "Clarks Summit, PA",
    city_name: "Clarks Summit",
  },
  {
    postal_code: "44113",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "32043",
    full_city_name: "Green Cove Springs, FL",
    city_name: "Green Cove Springs",
  },
  {
    postal_code: "55432",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "89128",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "56007",
    full_city_name: "Albert Lea, MN",
    city_name: "Albert Lea",
  },
  {
    postal_code: "19390",
    full_city_name: "West Grove, PA",
    city_name: "West Grove",
  },
  {
    postal_code: "33162",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "34203",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "30103",
    full_city_name: "Adairsville, GA",
    city_name: "Adairsville",
  },
  {
    postal_code: "80911",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "27609",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "28164",
    full_city_name: "Stanley, NC",
    city_name: "Stanley",
  },
  {
    postal_code: "29707",
    full_city_name: "Fort Mill, SC",
    city_name: "Fort Mill",
  },
  {
    postal_code: "60637",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "85053",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "83814",
    full_city_name: "Coeur D Alene, ID",
    city_name: "Coeur D Alene",
  },
  {
    postal_code: "33634",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "29418",
    full_city_name: "North Charleston, SC",
    city_name: "North Charleston",
  },
  {
    postal_code: "55987",
    full_city_name: "Winona, MN",
    city_name: "Winona",
  },
  {
    postal_code: "61704",
    full_city_name: "Bloomington, IL",
    city_name: "Bloomington",
  },
  {
    postal_code: "65079",
    full_city_name: "Sunrise Beach, MO",
    city_name: "Sunrise Beach",
  },
  {
    postal_code: "21093",
    full_city_name: "Lutherville Timonium, MD",
    city_name: "Lutherville Timonium",
  },
  {
    postal_code: "63367",
    full_city_name: "Lake Saint Louis, MO",
    city_name: "Lake Saint Louis",
  },
  {
    postal_code: "55063",
    full_city_name: "Pine City, MN",
    city_name: "Pine City",
  },
  {
    postal_code: "40206",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "28376",
    full_city_name: "Raeford, NC",
    city_name: "Raeford",
  },
  {
    postal_code: "10007",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "77523",
    full_city_name: "Baytown, TX",
    city_name: "Baytown",
  },
  {
    postal_code: "17078",
    full_city_name: "Palmyra, PA",
    city_name: "Palmyra",
  },
  {
    postal_code: "45237",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "06074",
    full_city_name: "South Windsor, CT",
    city_name: "South Windsor",
  },
  {
    postal_code: "44203",
    full_city_name: "Barberton, OH",
    city_name: "Barberton",
  },
  {
    postal_code: "78418",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "76086",
    full_city_name: "Weatherford, TX",
    city_name: "Weatherford",
  },
  {
    postal_code: "22193",
    full_city_name: "Woodbridge, VA",
    city_name: "Woodbridge",
  },
  {
    postal_code: "81301",
    full_city_name: "Durango, CO",
    city_name: "Durango",
  },
  {
    postal_code: "66062",
    full_city_name: "Olathe, KS",
    city_name: "Olathe",
  },
  {
    postal_code: "93955",
    full_city_name: "Seaside, CA",
    city_name: "Seaside",
  },
  {
    postal_code: "29461",
    full_city_name: "Moncks Corner, SC",
    city_name: "Moncks Corner",
  },
  {
    postal_code: "72034",
    full_city_name: "Conway, AR",
    city_name: "Conway",
  },
  {
    postal_code: "41017",
    full_city_name: "Ft Mitchell, KY",
    city_name: "Ft Mitchell",
  },
  {
    postal_code: "32827",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "21811",
    full_city_name: "Berlin, MD",
    city_name: "Berlin",
  },
  {
    postal_code: "40444",
    full_city_name: "Lancaster, KY",
    city_name: "Lancaster",
  },
  {
    postal_code: "43215",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "64055",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "06437",
    full_city_name: "Guilford, CT",
    city_name: "Guilford",
  },
  {
    postal_code: "93222",
    full_city_name: "Pine Mountain Club, CA",
    city_name: "Pine Mountain Club",
  },
  {
    postal_code: "60707",
    full_city_name: "Elmwood Park, IL",
    city_name: "Elmwood Park",
  },
  {
    postal_code: "53562",
    full_city_name: "Middleton, WI",
    city_name: "Middleton",
  },
  {
    postal_code: "08618",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "19512",
    full_city_name: "Boyertown, PA",
    city_name: "Boyertown",
  },
  {
    postal_code: "19126",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "52601",
    full_city_name: "Burlington, IA",
    city_name: "Burlington",
  },
  {
    postal_code: "11215",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "77378",
    full_city_name: "Willis, TX",
    city_name: "Willis",
  },
  {
    postal_code: "30655",
    full_city_name: "Monroe, GA",
    city_name: "Monroe",
  },
  {
    postal_code: "11937",
    full_city_name: "East Hampton, NY",
    city_name: "East Hampton",
  },
  {
    postal_code: "75149",
    full_city_name: "Mesquite, TX",
    city_name: "Mesquite",
  },
  {
    postal_code: "33432",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "22701",
    full_city_name: "Culpeper, VA",
    city_name: "Culpeper",
  },
  {
    postal_code: "46077",
    full_city_name: "Zionsville, IN",
    city_name: "Zionsville",
  },
  {
    postal_code: "27606",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "96746",
    full_city_name: "Kapaa, HI",
    city_name: "Kapaa",
  },
  {
    postal_code: "11746",
    full_city_name: "Huntington Station, NY",
    city_name: "Huntington Station",
  },
  {
    postal_code: "89703",
    full_city_name: "Carson City, NV",
    city_name: "Carson City",
  },
  {
    postal_code: "95215",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "55350",
    full_city_name: "Hutchinson, MN",
    city_name: "Hutchinson",
  },
  {
    postal_code: "60503",
    full_city_name: "Aurora, IL",
    city_name: "Aurora",
  },
  {
    postal_code: "71909",
    full_city_name: "Hot Springs Village, AR",
    city_name: "Hot Springs Village",
  },
  {
    postal_code: "37716",
    full_city_name: "Clinton, TN",
    city_name: "Clinton",
  },
  {
    postal_code: "06801",
    full_city_name: "Bethel, CT",
    city_name: "Bethel",
  },
  {
    postal_code: "95391",
    full_city_name: "Tracy, CA",
    city_name: "Tracy",
  },
  {
    postal_code: "46236",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "84103",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "76051",
    full_city_name: "Grapevine, TX",
    city_name: "Grapevine",
  },
  {
    postal_code: "76543",
    full_city_name: "Killeen, TX",
    city_name: "Killeen",
  },
  {
    postal_code: "11375",
    full_city_name: "Forest Hills, NY",
    city_name: "Forest Hills",
  },
  {
    postal_code: "63118",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "27803",
    full_city_name: "Rocky Mount, NC",
    city_name: "Rocky Mount",
  },
  {
    postal_code: "37814",
    full_city_name: "Morristown, TN",
    city_name: "Morristown",
  },
  {
    postal_code: "33602",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "96734",
    full_city_name: "Kailua, HI",
    city_name: "Kailua",
  },
  {
    postal_code: "19701",
    full_city_name: "Bear, DE",
    city_name: "Bear",
  },
  {
    postal_code: "77004",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "29681",
    full_city_name: "Simpsonville, SC",
    city_name: "Simpsonville",
  },
  {
    postal_code: "42103",
    full_city_name: "Bowling Green, KY",
    city_name: "Bowling Green",
  },
  {
    postal_code: "94044",
    full_city_name: "Pacifica, CA",
    city_name: "Pacifica",
  },
  {
    postal_code: "60190",
    full_city_name: "Winfield, IL",
    city_name: "Winfield",
  },
  {
    postal_code: "60406",
    full_city_name: "Blue Island, IL",
    city_name: "Blue Island",
  },
  {
    postal_code: "01945",
    full_city_name: "Marblehead, MA",
    city_name: "Marblehead",
  },
  {
    postal_code: "29662",
    full_city_name: "Mauldin, SC",
    city_name: "Mauldin",
  },
  {
    postal_code: "93314",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "33186",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "70447",
    full_city_name: "Madisonville, LA",
    city_name: "Madisonville",
  },
  {
    postal_code: "53597",
    full_city_name: "Waunakee, WI",
    city_name: "Waunakee",
  },
  {
    postal_code: "91104",
    full_city_name: "Pasadena, CA",
    city_name: "Pasadena",
  },
  {
    postal_code: "19061",
    full_city_name: "Marcus Hook, PA",
    city_name: "Marcus Hook",
  },
  {
    postal_code: "34987",
    full_city_name: "Port Saint Lucie, FL",
    city_name: "Port Saint Lucie",
  },
  {
    postal_code: "80817",
    full_city_name: "Fountain, CO",
    city_name: "Fountain",
  },
  {
    postal_code: "60613",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "18938",
    full_city_name: "New Hope, PA",
    city_name: "New Hope",
  },
  {
    postal_code: "80910",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "19806",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "65065",
    full_city_name: "Osage Beach, MO",
    city_name: "Osage Beach",
  },
  {
    postal_code: "95382",
    full_city_name: "Turlock, CA",
    city_name: "Turlock",
  },
  {
    postal_code: "15201",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "95360",
    full_city_name: "Newman, CA",
    city_name: "Newman",
  },
  {
    postal_code: "85706",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "15206",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "95820",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "65807",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "33139",
    full_city_name: "Miami Beach, FL",
    city_name: "Miami Beach",
  },
  {
    postal_code: "60634",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "20706",
    full_city_name: "Lanham, MD",
    city_name: "Lanham",
  },
  {
    postal_code: "33132",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "33624",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "30519",
    full_city_name: "Buford, GA",
    city_name: "Buford",
  },
  {
    postal_code: "30309",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "91105",
    full_city_name: "Pasadena, CA",
    city_name: "Pasadena",
  },
  {
    postal_code: "76049",
    full_city_name: "Granbury, TX",
    city_name: "Granbury",
  },
  {
    postal_code: "30307",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "34982",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "25701",
    full_city_name: "Huntington, WV",
    city_name: "Huntington",
  },
  {
    postal_code: "70471",
    full_city_name: "Mandeville, LA",
    city_name: "Mandeville",
  },
  {
    postal_code: "85641",
    full_city_name: "Vail, AZ",
    city_name: "Vail",
  },
  {
    postal_code: "72212",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "12020",
    full_city_name: "Ballston Spa, NY",
    city_name: "Ballston Spa",
  },
  {
    postal_code: "60010",
    full_city_name: "Barrington, IL",
    city_name: "Barrington",
  },
  {
    postal_code: "30179",
    full_city_name: "Temple, GA",
    city_name: "Temple",
  },
  {
    postal_code: "44056",
    full_city_name: "Macedonia, OH",
    city_name: "Macedonia",
  },
  {
    postal_code: "19462",
    full_city_name: "Plymouth Meeting, PA",
    city_name: "Plymouth Meeting",
  },
  {
    postal_code: "78239",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "59901",
    full_city_name: "Kalispell, MT",
    city_name: "Kalispell",
  },
  {
    postal_code: "79936",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "33966",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "94541",
    full_city_name: "Hayward, CA",
    city_name: "Hayward",
  },
  {
    postal_code: "33180",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "75218",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "93560",
    full_city_name: "Rosamond, CA",
    city_name: "Rosamond",
  },
  {
    postal_code: "33872",
    full_city_name: "Sebring, FL",
    city_name: "Sebring",
  },
  {
    postal_code: "15120",
    full_city_name: "Homestead, PA",
    city_name: "Homestead",
  },
  {
    postal_code: "60139",
    full_city_name: "Glendale Heights, IL",
    city_name: "Glendale Heights",
  },
  {
    postal_code: "74008",
    full_city_name: "Bixby, OK",
    city_name: "Bixby",
  },
  {
    postal_code: "28512",
    full_city_name: "Atlantic Beach, NC",
    city_name: "Atlantic Beach",
  },
  {
    postal_code: "20886",
    full_city_name: "Montgomery Village, MD",
    city_name: "Montgomery Village",
  },
  {
    postal_code: "03054",
    full_city_name: "Merrimack, NH",
    city_name: "Merrimack",
  },
  {
    postal_code: "30311",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "77566",
    full_city_name: "Lake Jackson, TX",
    city_name: "Lake Jackson",
  },
  {
    postal_code: "30008",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "32789",
    full_city_name: "Winter Park, FL",
    city_name: "Winter Park",
  },
  {
    postal_code: "33905",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "35173",
    full_city_name: "Trussville, AL",
    city_name: "Trussville",
  },
  {
    postal_code: "20774",
    full_city_name: "Upper Marlboro, MD",
    city_name: "Upper Marlboro",
  },
  {
    postal_code: "77389",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "46260",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "32907",
    full_city_name: "Palm Bay, FL",
    city_name: "Palm Bay",
  },
  {
    postal_code: "32920",
    full_city_name: "Cape Canaveral, FL",
    city_name: "Cape Canaveral",
  },
  {
    postal_code: "32095",
    full_city_name: "Saint Augustine, FL",
    city_name: "Saint Augustine",
  },
  {
    postal_code: "27410",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "33928",
    full_city_name: "Estero, FL",
    city_name: "Estero",
  },
  {
    postal_code: "46011",
    full_city_name: "Anderson, IN",
    city_name: "Anderson",
  },
  {
    postal_code: "33449",
    full_city_name: "Lake Worth, FL",
    city_name: "Lake Worth",
  },
  {
    postal_code: "44256",
    full_city_name: "Medina, OH",
    city_name: "Medina",
  },
  {
    postal_code: "76001",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "72745",
    full_city_name: "Lowell, AR",
    city_name: "Lowell",
  },
  {
    postal_code: "30094",
    full_city_name: "Conyers, GA",
    city_name: "Conyers",
  },
  {
    postal_code: "19802",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "40059",
    full_city_name: "Prospect, KY",
    city_name: "Prospect",
  },
  {
    postal_code: "39042",
    full_city_name: "Brandon, MS",
    city_name: "Brandon",
  },
  {
    postal_code: "86327",
    full_city_name: "Dewey, AZ",
    city_name: "Dewey",
  },
  {
    postal_code: "60632",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "60425",
    full_city_name: "Glenwood, IL",
    city_name: "Glenwood",
  },
  {
    postal_code: "33618",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "92342",
    full_city_name: "Helendale, CA",
    city_name: "Helendale",
  },
  {
    postal_code: "44130",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "85705",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "55104",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "33143",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "55416",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "60099",
    full_city_name: "Zion, IL",
    city_name: "Zion",
  },
  {
    postal_code: "30088",
    full_city_name: "Stone Mountain, GA",
    city_name: "Stone Mountain",
  },
  {
    postal_code: "10009",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "43209",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "91604",
    full_city_name: "Studio City, CA",
    city_name: "Studio City",
  },
  {
    postal_code: "28273",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "08051",
    full_city_name: "Mantua, NJ",
    city_name: "Mantua",
  },
  {
    postal_code: "92649",
    full_city_name: "Huntington Beach, CA",
    city_name: "Huntington Beach",
  },
  {
    postal_code: "32804",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "34684",
    full_city_name: "Palm Harbor, FL",
    city_name: "Palm Harbor",
  },
  {
    postal_code: "03051",
    full_city_name: "Hudson, NH",
    city_name: "Hudson",
  },
  {
    postal_code: "60131",
    full_city_name: "Franklin Park, IL",
    city_name: "Franklin Park",
  },
  {
    postal_code: "53719",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "33830",
    full_city_name: "Bartow, FL",
    city_name: "Bartow",
  },
  {
    postal_code: "60056",
    full_city_name: "Mount Prospect, IL",
    city_name: "Mount Prospect",
  },
  {
    postal_code: "91352",
    full_city_name: "Sun Valley, CA",
    city_name: "Sun Valley",
  },
  {
    postal_code: "82001",
    full_city_name: "Cheyenne, WY",
    city_name: "Cheyenne",
  },
  {
    postal_code: "93536",
    full_city_name: "Lancaster, CA",
    city_name: "Lancaster",
  },
  {
    postal_code: "35213",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "76033",
    full_city_name: "Cleburne, TX",
    city_name: "Cleburne",
  },
  {
    postal_code: "75036",
    full_city_name: "Frisco, TX",
    city_name: "Frisco",
  },
  {
    postal_code: "77502",
    full_city_name: "Pasadena, TX",
    city_name: "Pasadena",
  },
  {
    postal_code: "42701",
    full_city_name: "Elizabethtown, KY",
    city_name: "Elizabethtown",
  },
  {
    postal_code: "95608",
    full_city_name: "Carmichael, CA",
    city_name: "Carmichael",
  },
  {
    postal_code: "95991",
    full_city_name: "Yuba City, CA",
    city_name: "Yuba City",
  },
  {
    postal_code: "44060",
    full_city_name: "Mentor, OH",
    city_name: "Mentor",
  },
  {
    postal_code: "84129",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "06443",
    full_city_name: "Madison, CT",
    city_name: "Madison",
  },
  {
    postal_code: "61036",
    full_city_name: "Galena, IL",
    city_name: "Galena",
  },
  {
    postal_code: "28207",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "77073",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "75043",
    full_city_name: "Garland, TX",
    city_name: "Garland",
  },
  {
    postal_code: "12010",
    full_city_name: "Amsterdam, NY",
    city_name: "Amsterdam",
  },
  {
    postal_code: "90019",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "70001",
    full_city_name: "Metairie, LA",
    city_name: "Metairie",
  },
  {
    postal_code: "53546",
    full_city_name: "Janesville, WI",
    city_name: "Janesville",
  },
  {
    postal_code: "30310",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "70122",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "28774",
    full_city_name: "Sapphire, NC",
    city_name: "Sapphire",
  },
  {
    postal_code: "34761",
    full_city_name: "Ocoee, FL",
    city_name: "Ocoee",
  },
  {
    postal_code: "94577",
    full_city_name: "San Leandro, CA",
    city_name: "San Leandro",
  },
  {
    postal_code: "08831",
    full_city_name: "Monroe Township, NJ",
    city_name: "Monroe Township",
  },
  {
    postal_code: "12065",
    full_city_name: "Clifton Park, NY",
    city_name: "Clifton Park",
  },
  {
    postal_code: "79928",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "48823",
    full_city_name: "East Lansing, MI",
    city_name: "East Lansing",
  },
  {
    postal_code: "15237",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "15217",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "38117",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "94563",
    full_city_name: "Orinda, CA",
    city_name: "Orinda",
  },
  {
    postal_code: "85253",
    full_city_name: "Paradise Valley, AZ",
    city_name: "Paradise Valley",
  },
  {
    postal_code: "34287",
    full_city_name: "North Port, FL",
    city_name: "North Port",
  },
  {
    postal_code: "76104",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "81505",
    full_city_name: "Grand Junction, CO",
    city_name: "Grand Junction",
  },
  {
    postal_code: "98312",
    full_city_name: "Bremerton, WA",
    city_name: "Bremerton",
  },
  {
    postal_code: "76040",
    full_city_name: "Euless, TX",
    city_name: "Euless",
  },
  {
    postal_code: "55438",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "19312",
    full_city_name: "Berwyn, PA",
    city_name: "Berwyn",
  },
  {
    postal_code: "87401",
    full_city_name: "Farmington, NM",
    city_name: "Farmington",
  },
  {
    postal_code: "94501",
    full_city_name: "Alameda, CA",
    city_name: "Alameda",
  },
  {
    postal_code: "61604",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "70131",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "70601",
    full_city_name: "Lake Charles, LA",
    city_name: "Lake Charles",
  },
  {
    postal_code: "77372",
    full_city_name: "Splendora, TX",
    city_name: "Splendora",
  },
  {
    postal_code: "75751",
    full_city_name: "Athens, TX",
    city_name: "Athens",
  },
  {
    postal_code: "57078",
    full_city_name: "Yankton, SD",
    city_name: "Yankton",
  },
  {
    postal_code: "27850",
    full_city_name: "Littleton, NC",
    city_name: "Littleton",
  },
  {
    postal_code: "14206",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "75159",
    full_city_name: "Seagoville, TX",
    city_name: "Seagoville",
  },
  {
    postal_code: "74701",
    full_city_name: "Durant, OK",
    city_name: "Durant",
  },
  {
    postal_code: "53548",
    full_city_name: "Janesville, WI",
    city_name: "Janesville",
  },
  {
    postal_code: "85301",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "20705",
    full_city_name: "Beltsville, MD",
    city_name: "Beltsville",
  },
  {
    postal_code: "39531",
    full_city_name: "Biloxi, MS",
    city_name: "Biloxi",
  },
  {
    postal_code: "85367",
    full_city_name: "Yuma, AZ",
    city_name: "Yuma",
  },
  {
    postal_code: "33126",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "29349",
    full_city_name: "Inman, SC",
    city_name: "Inman",
  },
  {
    postal_code: "60652",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "37821",
    full_city_name: "Newport, TN",
    city_name: "Newport",
  },
  {
    postal_code: "06032",
    full_city_name: "Farmington, CT",
    city_name: "Farmington",
  },
  {
    postal_code: "92532",
    full_city_name: "Lake Elsinore, CA",
    city_name: "Lake Elsinore",
  },
  {
    postal_code: "33647",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "23220",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "08034",
    full_city_name: "Cherry Hill, NJ",
    city_name: "Cherry Hill",
  },
  {
    postal_code: "34773",
    full_city_name: "Saint Cloud, FL",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "11762",
    full_city_name: "Massapequa Park, NY",
    city_name: "Massapequa Park",
  },
  {
    postal_code: "02865",
    full_city_name: "Lincoln, RI",
    city_name: "Lincoln",
  },
  {
    postal_code: "76135",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "03833",
    full_city_name: "Exeter, NH",
    city_name: "Exeter",
  },
  {
    postal_code: "27403",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "06512",
    full_city_name: "East Haven, CT",
    city_name: "East Haven",
  },
  {
    postal_code: "44720",
    full_city_name: "North Canton, OH",
    city_name: "North Canton",
  },
  {
    postal_code: "95206",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "98146",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "32118",
    full_city_name: "Daytona Beach, FL",
    city_name: "Daytona Beach",
  },
  {
    postal_code: "45805",
    full_city_name: "Lima, OH",
    city_name: "Lima",
  },
  {
    postal_code: "15650",
    full_city_name: "Latrobe, PA",
    city_name: "Latrobe",
  },
  {
    postal_code: "11763",
    full_city_name: "Medford, NY",
    city_name: "Medford",
  },
  {
    postal_code: "10005",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "80011",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "79925",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "76119",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "27292",
    full_city_name: "Lexington, NC",
    city_name: "Lexington",
  },
  {
    postal_code: "78504",
    full_city_name: "Mcallen, TX",
    city_name: "Mcallen",
  },
  {
    postal_code: "23229",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "85748",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "52302",
    full_city_name: "Marion, IA",
    city_name: "Marion",
  },
  {
    postal_code: "74145",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "34607",
    full_city_name: "Spring Hill, FL",
    city_name: "Spring Hill",
  },
  {
    postal_code: "30506",
    full_city_name: "Gainesville, GA",
    city_name: "Gainesville",
  },
  {
    postal_code: "27520",
    full_city_name: "Clayton, NC",
    city_name: "Clayton",
  },
  {
    postal_code: "46526",
    full_city_name: "Goshen, IN",
    city_name: "Goshen",
  },
  {
    postal_code: "78669",
    full_city_name: "Spicewood, TX",
    city_name: "Spicewood",
  },
  {
    postal_code: "66202",
    full_city_name: "Mission, KS",
    city_name: "Mission",
  },
  {
    postal_code: "44022",
    full_city_name: "Chagrin Falls, OH",
    city_name: "Chagrin Falls",
  },
  {
    postal_code: "97233",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "76109",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "29841",
    full_city_name: "North Augusta, SC",
    city_name: "North Augusta",
  },
  {
    postal_code: "96821",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "73115",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "60626",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "60803",
    full_city_name: "Alsip, IL",
    city_name: "Alsip",
  },
  {
    postal_code: "88240",
    full_city_name: "Hobbs, NM",
    city_name: "Hobbs",
  },
  {
    postal_code: "94080",
    full_city_name: "South San Francisco, CA",
    city_name: "South San Francisco",
  },
  {
    postal_code: "94611",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "64052",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "32810",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "98031",
    full_city_name: "Kent, WA",
    city_name: "Kent",
  },
  {
    postal_code: "37160",
    full_city_name: "Shelbyville, TN",
    city_name: "Shelbyville",
  },
  {
    postal_code: "97140",
    full_city_name: "Sherwood, OR",
    city_name: "Sherwood",
  },
  {
    postal_code: "26501",
    full_city_name: "Morgantown, WV",
    city_name: "Morgantown",
  },
  {
    postal_code: "37146",
    full_city_name: "Pleasant View, TN",
    city_name: "Pleasant View",
  },
  {
    postal_code: "92509",
    full_city_name: "Jurupa Valley, CA",
    city_name: "Jurupa Valley",
  },
  {
    postal_code: "98168",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "33594",
    full_city_name: "Valrico, FL",
    city_name: "Valrico",
  },
  {
    postal_code: "74127",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "43015",
    full_city_name: "Delaware, OH",
    city_name: "Delaware",
  },
  {
    postal_code: "63146",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "57703",
    full_city_name: "Rapid City, SD",
    city_name: "Rapid City",
  },
  {
    postal_code: "30291",
    full_city_name: "Union City, GA",
    city_name: "Union City",
  },
  {
    postal_code: "54303",
    full_city_name: "Green Bay, WI",
    city_name: "Green Bay",
  },
  {
    postal_code: "46254",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "71203",
    full_city_name: "Monroe, LA",
    city_name: "Monroe",
  },
  {
    postal_code: "44883",
    full_city_name: "Tiffin, OH",
    city_name: "Tiffin",
  },
  {
    postal_code: "91344",
    full_city_name: "Granada Hills, CA",
    city_name: "Granada Hills",
  },
  {
    postal_code: "89508",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "65742",
    full_city_name: "Rogersville, MO",
    city_name: "Rogersville",
  },
  {
    postal_code: "13035",
    full_city_name: "Cazenovia, NY",
    city_name: "Cazenovia",
  },
  {
    postal_code: "44483",
    full_city_name: "Warren, OH",
    city_name: "Warren",
  },
  {
    postal_code: "23805",
    full_city_name: "Petersburg, VA",
    city_name: "Petersburg",
  },
  {
    postal_code: "60452",
    full_city_name: "Oak Forest, IL",
    city_name: "Oak Forest",
  },
  {
    postal_code: "79007",
    full_city_name: "Borger, TX",
    city_name: "Borger",
  },
  {
    postal_code: "45429",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "38355",
    full_city_name: "Medina, TN",
    city_name: "Medina",
  },
  {
    postal_code: "97132",
    full_city_name: "Newberg, OR",
    city_name: "Newberg",
  },
  {
    postal_code: "25526",
    full_city_name: "Hurricane, WV",
    city_name: "Hurricane",
  },
  {
    postal_code: "49286",
    full_city_name: "Tecumseh, MI",
    city_name: "Tecumseh",
  },
  {
    postal_code: "27012",
    full_city_name: "Clemmons, NC",
    city_name: "Clemmons",
  },
  {
    postal_code: "78211",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "20708",
    full_city_name: "Laurel, MD",
    city_name: "Laurel",
  },
  {
    postal_code: "49801",
    full_city_name: "Iron Mountain, MI",
    city_name: "Iron Mountain",
  },
  {
    postal_code: "30540",
    full_city_name: "Ellijay, GA",
    city_name: "Ellijay",
  },
  {
    postal_code: "98101",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "92583",
    full_city_name: "San Jacinto, CA",
    city_name: "San Jacinto",
  },
  {
    postal_code: "91768",
    full_city_name: "Pomona, CA",
    city_name: "Pomona",
  },
  {
    postal_code: "60002",
    full_city_name: "Antioch, IL",
    city_name: "Antioch",
  },
  {
    postal_code: "67205",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "06897",
    full_city_name: "Wilton, CT",
    city_name: "Wilton",
  },
  {
    postal_code: "85737",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "64078",
    full_city_name: "Peculiar, MO",
    city_name: "Peculiar",
  },
  {
    postal_code: "55379",
    full_city_name: "Shakopee, MN",
    city_name: "Shakopee",
  },
  {
    postal_code: "44511",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "20147",
    full_city_name: "Ashburn, VA",
    city_name: "Ashburn",
  },
  {
    postal_code: "17113",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "77531",
    full_city_name: "Clute, TX",
    city_name: "Clute",
  },
  {
    postal_code: "38138",
    full_city_name: "Germantown, TN",
    city_name: "Germantown",
  },
  {
    postal_code: "24523",
    full_city_name: "Bedford, VA",
    city_name: "Bedford",
  },
  {
    postal_code: "77064",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "28305",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "77025",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "72712",
    full_city_name: "Bentonville, AR",
    city_name: "Bentonville",
  },
  {
    postal_code: "33839",
    full_city_name: "Eagle Lake, FL",
    city_name: "Eagle Lake",
  },
  {
    postal_code: "97006",
    full_city_name: "Beaverton, OR",
    city_name: "Beaverton",
  },
  {
    postal_code: "85201",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "84081",
    full_city_name: "West Jordan, UT",
    city_name: "West Jordan",
  },
  {
    postal_code: "46012",
    full_city_name: "Anderson, IN",
    city_name: "Anderson",
  },
  {
    postal_code: "50314",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "61072",
    full_city_name: "Rockton, IL",
    city_name: "Rockton",
  },
  {
    postal_code: "97330",
    full_city_name: "Corvallis, OR",
    city_name: "Corvallis",
  },
  {
    postal_code: "34231",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "12198",
    full_city_name: "Wynantskill, NY",
    city_name: "Wynantskill",
  },
  {
    postal_code: "61115",
    full_city_name: "Machesney Park, IL",
    city_name: "Machesney Park",
  },
  {
    postal_code: "29649",
    full_city_name: "Greenwood, SC",
    city_name: "Greenwood",
  },
  {
    postal_code: "62221",
    full_city_name: "Belleville, IL",
    city_name: "Belleville",
  },
  {
    postal_code: "30068",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "70364",
    full_city_name: "Houma, LA",
    city_name: "Houma",
  },
  {
    postal_code: "92081",
    full_city_name: "Vista, CA",
    city_name: "Vista",
  },
  {
    postal_code: "99301",
    full_city_name: "Pasco, WA",
    city_name: "Pasco",
  },
  {
    postal_code: "12186",
    full_city_name: "Voorheesville, NY",
    city_name: "Voorheesville",
  },
  {
    postal_code: "98597",
    full_city_name: "Yelm, WA",
    city_name: "Yelm",
  },
  {
    postal_code: "60465",
    full_city_name: "Palos Hills, IL",
    city_name: "Palos Hills",
  },
  {
    postal_code: "79924",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "60555",
    full_city_name: "Warrenville, IL",
    city_name: "Warrenville",
  },
  {
    postal_code: "34736",
    full_city_name: "Groveland, FL",
    city_name: "Groveland",
  },
  {
    postal_code: "85364",
    full_city_name: "Yuma, AZ",
    city_name: "Yuma",
  },
  {
    postal_code: "92505",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "11414",
    full_city_name: "Howard Beach, NY",
    city_name: "Howard Beach",
  },
  {
    postal_code: "27104",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "34243",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "33034",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "96753",
    full_city_name: "Kihei, HI",
    city_name: "Kihei",
  },
  {
    postal_code: "75455",
    full_city_name: "Mount Pleasant, TX",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "45030",
    full_city_name: "Harrison, OH",
    city_name: "Harrison",
  },
  {
    postal_code: "53511",
    full_city_name: "Beloit, WI",
    city_name: "Beloit",
  },
  {
    postal_code: "29860",
    full_city_name: "North Augusta, SC",
    city_name: "North Augusta",
  },
  {
    postal_code: "06040",
    full_city_name: "Manchester, CT",
    city_name: "Manchester",
  },
  {
    postal_code: "25177",
    full_city_name: "Saint Albans, WV",
    city_name: "Saint Albans",
  },
  {
    postal_code: "08010",
    full_city_name: "Beverly, NJ",
    city_name: "Beverly",
  },
  {
    postal_code: "34210",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "95825",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "68124",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "85210",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "17202",
    full_city_name: "Chambersburg, PA",
    city_name: "Chambersburg",
  },
  {
    postal_code: "91403",
    full_city_name: "Sherman Oaks, CA",
    city_name: "Sherman Oaks",
  },
  {
    postal_code: "08048",
    full_city_name: "Lumberton, NJ",
    city_name: "Lumberton",
  },
  {
    postal_code: "29803",
    full_city_name: "Aiken, SC",
    city_name: "Aiken",
  },
  {
    postal_code: "38652",
    full_city_name: "New Albany, MS",
    city_name: "New Albany",
  },
  {
    postal_code: "75252",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "55127",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "11753",
    full_city_name: "Jericho, NY",
    city_name: "Jericho",
  },
  {
    postal_code: "14905",
    full_city_name: "Elmira, NY",
    city_name: "Elmira",
  },
  {
    postal_code: "06883",
    full_city_name: "Weston, CT",
    city_name: "Weston",
  },
  {
    postal_code: "11554",
    full_city_name: "East Meadow, NY",
    city_name: "East Meadow",
  },
  {
    postal_code: "46168",
    full_city_name: "Plainfield, IN",
    city_name: "Plainfield",
  },
  {
    postal_code: "13212",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "84335",
    full_city_name: "Smithfield, UT",
    city_name: "Smithfield",
  },
  {
    postal_code: "06117",
    full_city_name: "West Hartford, CT",
    city_name: "West Hartford",
  },
  {
    postal_code: "64083",
    full_city_name: "Raymore, MO",
    city_name: "Raymore",
  },
  {
    postal_code: "27409",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "60659",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "40215",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "20011",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "48423",
    full_city_name: "Davison, MI",
    city_name: "Davison",
  },
  {
    postal_code: "06109",
    full_city_name: "Wethersfield, CT",
    city_name: "Wethersfield",
  },
  {
    postal_code: "30214",
    full_city_name: "Fayetteville, GA",
    city_name: "Fayetteville",
  },
  {
    postal_code: "27856",
    full_city_name: "Nashville, NC",
    city_name: "Nashville",
  },
  {
    postal_code: "32456",
    full_city_name: "Port Saint Joe, FL",
    city_name: "Port Saint Joe",
  },
  {
    postal_code: "77515",
    full_city_name: "Angleton, TX",
    city_name: "Angleton",
  },
  {
    postal_code: "93307",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "07724",
    full_city_name: "Eatontown, NJ",
    city_name: "Eatontown",
  },
  {
    postal_code: "85027",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "98501",
    full_city_name: "Olympia, WA",
    city_name: "Olympia",
  },
  {
    postal_code: "22902",
    full_city_name: "Charlottesville, VA",
    city_name: "Charlottesville",
  },
  {
    postal_code: "11420",
    full_city_name: "South Ozone Park, NY",
    city_name: "South Ozone Park",
  },
  {
    postal_code: "45410",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "92672",
    full_city_name: "San Clemente, CA",
    city_name: "San Clemente",
  },
  {
    postal_code: "23227",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "22025",
    full_city_name: "Dumfries, VA",
    city_name: "Dumfries",
  },
  {
    postal_code: "84062",
    full_city_name: "Pleasant Grove, UT",
    city_name: "Pleasant Grove",
  },
  {
    postal_code: "55305",
    full_city_name: "Hopkins, MN",
    city_name: "Hopkins",
  },
  {
    postal_code: "08628",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "15221",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "33707",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "39426",
    full_city_name: "Carriere, MS",
    city_name: "Carriere",
  },
  {
    postal_code: "30079",
    full_city_name: "Scottdale, GA",
    city_name: "Scottdale",
  },
  {
    postal_code: "98848",
    full_city_name: "Quincy, WA",
    city_name: "Quincy",
  },
  {
    postal_code: "55024",
    full_city_name: "Farmington, MN",
    city_name: "Farmington",
  },
  {
    postal_code: "50036",
    full_city_name: "Boone, IA",
    city_name: "Boone",
  },
  {
    postal_code: "92405",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "06357",
    full_city_name: "Niantic, CT",
    city_name: "Niantic",
  },
  {
    postal_code: "33875",
    full_city_name: "Sebring, FL",
    city_name: "Sebring",
  },
  {
    postal_code: "32608",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "73065",
    full_city_name: "Newcastle, OK",
    city_name: "Newcastle",
  },
  {
    postal_code: "75002",
    full_city_name: "Allen, TX",
    city_name: "Allen",
  },
  {
    postal_code: "38109",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "29450",
    full_city_name: "Huger, SC",
    city_name: "Huger",
  },
  {
    postal_code: "80128",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "28150",
    full_city_name: "Shelby, NC",
    city_name: "Shelby",
  },
  {
    postal_code: "77016",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "98010",
    full_city_name: "Black Diamond, WA",
    city_name: "Black Diamond",
  },
  {
    postal_code: "92024",
    full_city_name: "Encinitas, CA",
    city_name: "Encinitas",
  },
  {
    postal_code: "32221",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "32304",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "54313",
    full_city_name: "Green Bay, WI",
    city_name: "Green Bay",
  },
  {
    postal_code: "29303",
    full_city_name: "Spartanburg, SC",
    city_name: "Spartanburg",
  },
  {
    postal_code: "32901",
    full_city_name: "Melbourne, FL",
    city_name: "Melbourne",
  },
  {
    postal_code: "13041",
    full_city_name: "Clay, NY",
    city_name: "Clay",
  },
  {
    postal_code: "91402",
    full_city_name: "Panorama City, CA",
    city_name: "Panorama City",
  },
  {
    postal_code: "06812",
    full_city_name: "New Fairfield, CT",
    city_name: "New Fairfield",
  },
  {
    postal_code: "64154",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "38655",
    full_city_name: "Oxford, MS",
    city_name: "Oxford",
  },
  {
    postal_code: "33967",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "80421",
    full_city_name: "Bailey, CO",
    city_name: "Bailey",
  },
  {
    postal_code: "66220",
    full_city_name: "Lenexa, KS",
    city_name: "Lenexa",
  },
  {
    postal_code: "61065",
    full_city_name: "Poplar Grove, IL",
    city_name: "Poplar Grove",
  },
  {
    postal_code: "11598",
    full_city_name: "Woodmere, NY",
    city_name: "Woodmere",
  },
  {
    postal_code: "14092",
    full_city_name: "Lewiston, NY",
    city_name: "Lewiston",
  },
  {
    postal_code: "70611",
    full_city_name: "Lake Charles, LA",
    city_name: "Lake Charles",
  },
  {
    postal_code: "38139",
    full_city_name: "Germantown, TN",
    city_name: "Germantown",
  },
  {
    postal_code: "90713",
    full_city_name: "Lakewood, CA",
    city_name: "Lakewood",
  },
  {
    postal_code: "27205",
    full_city_name: "Asheboro, NC",
    city_name: "Asheboro",
  },
  {
    postal_code: "46342",
    full_city_name: "Hobart, IN",
    city_name: "Hobart",
  },
  {
    postal_code: "78413",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "60077",
    full_city_name: "Skokie, IL",
    city_name: "Skokie",
  },
  {
    postal_code: "27569",
    full_city_name: "Princeton, NC",
    city_name: "Princeton",
  },
  {
    postal_code: "75165",
    full_city_name: "Waxahachie, TX",
    city_name: "Waxahachie",
  },
  {
    postal_code: "85054",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "62010",
    full_city_name: "Bethalto, IL",
    city_name: "Bethalto",
  },
  {
    postal_code: "34433",
    full_city_name: "Dunnellon, FL",
    city_name: "Dunnellon",
  },
  {
    postal_code: "93637",
    full_city_name: "Madera, CA",
    city_name: "Madera",
  },
  {
    postal_code: "76012",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "37411",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "15212",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "92683",
    full_city_name: "Westminster, CA",
    city_name: "Westminster",
  },
  {
    postal_code: "33613",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "96793",
    full_city_name: "Wailuku, HI",
    city_name: "Wailuku",
  },
  {
    postal_code: "20105",
    full_city_name: "Aldie, VA",
    city_name: "Aldie",
  },
  {
    postal_code: "95993",
    full_city_name: "Yuba City, CA",
    city_name: "Yuba City",
  },
  {
    postal_code: "45212",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "61821",
    full_city_name: "Champaign, IL",
    city_name: "Champaign",
  },
  {
    postal_code: "40391",
    full_city_name: "Winchester, KY",
    city_name: "Winchester",
  },
  {
    postal_code: "45247",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "70737",
    full_city_name: "Gonzales, LA",
    city_name: "Gonzales",
  },
  {
    postal_code: "33062",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "80204",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "70094",
    full_city_name: "Westwego, LA",
    city_name: "Westwego",
  },
  {
    postal_code: "75090",
    full_city_name: "Sherman, TX",
    city_name: "Sherman",
  },
  {
    postal_code: "38060",
    full_city_name: "Oakland, TN",
    city_name: "Oakland",
  },
  {
    postal_code: "70056",
    full_city_name: "Gretna, LA",
    city_name: "Gretna",
  },
  {
    postal_code: "85718",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "75234",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "45385",
    full_city_name: "Xenia, OH",
    city_name: "Xenia",
  },
  {
    postal_code: "85043",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "49441",
    full_city_name: "Muskegon, MI",
    city_name: "Muskegon",
  },
  {
    postal_code: "32763",
    full_city_name: "Orange City, FL",
    city_name: "Orange City",
  },
  {
    postal_code: "78209",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "63129",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "11220",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "90032",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "91739",
    full_city_name: "Rancho Cucamonga, CA",
    city_name: "Rancho Cucamonga",
  },
  {
    postal_code: "66049",
    full_city_name: "Lawrence, KS",
    city_name: "Lawrence",
  },
  {
    postal_code: "02818",
    full_city_name: "East Greenwich, RI",
    city_name: "East Greenwich",
  },
  {
    postal_code: "73703",
    full_city_name: "Enid, OK",
    city_name: "Enid",
  },
  {
    postal_code: "55902",
    full_city_name: "Rochester, MN",
    city_name: "Rochester",
  },
  {
    postal_code: "34698",
    full_city_name: "Dunedin, FL",
    city_name: "Dunedin",
  },
  {
    postal_code: "92543",
    full_city_name: "Hemet, CA",
    city_name: "Hemet",
  },
  {
    postal_code: "24121",
    full_city_name: "Moneta, VA",
    city_name: "Moneta",
  },
  {
    postal_code: "37363",
    full_city_name: "Ooltewah, TN",
    city_name: "Ooltewah",
  },
  {
    postal_code: "75061",
    full_city_name: "Irving, TX",
    city_name: "Irving",
  },
  {
    postal_code: "92705",
    full_city_name: "Santa Ana, CA",
    city_name: "Santa Ana",
  },
  {
    postal_code: "35216",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "37204",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "07740",
    full_city_name: "Long Branch, NJ",
    city_name: "Long Branch",
  },
  {
    postal_code: "98065",
    full_city_name: "Snoqualmie, WA",
    city_name: "Snoqualmie",
  },
  {
    postal_code: "23230",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "93036",
    full_city_name: "Oxnard, CA",
    city_name: "Oxnard",
  },
  {
    postal_code: "27262",
    full_city_name: "High Point, NC",
    city_name: "High Point",
  },
  {
    postal_code: "45215",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "95822",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "29410",
    full_city_name: "Hanahan, SC",
    city_name: "Hanahan",
  },
  {
    postal_code: "97068",
    full_city_name: "West Linn, OR",
    city_name: "West Linn",
  },
  {
    postal_code: "18603",
    full_city_name: "Berwick, PA",
    city_name: "Berwick",
  },
  {
    postal_code: "33954",
    full_city_name: "Port Charlotte, FL",
    city_name: "Port Charlotte",
  },
  {
    postal_code: "77007",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "44663",
    full_city_name: "New Philadelphia, OH",
    city_name: "New Philadelphia",
  },
  {
    postal_code: "77096",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "28173",
    full_city_name: "Waxhaw, NC",
    city_name: "Waxhaw",
  },
  {
    postal_code: "26003",
    full_city_name: "Wheeling, WV",
    city_name: "Wheeling",
  },
  {
    postal_code: "82414",
    full_city_name: "Cody, WY",
    city_name: "Cody",
  },
  {
    postal_code: "07748",
    full_city_name: "Middletown, NJ",
    city_name: "Middletown",
  },
  {
    postal_code: "28469",
    full_city_name: "Ocean Isle Beach, NC",
    city_name: "Ocean Isle Beach",
  },
  {
    postal_code: "55119",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "47803",
    full_city_name: "Terre Haute, IN",
    city_name: "Terre Haute",
  },
  {
    postal_code: "92544",
    full_city_name: "Hemet, CA",
    city_name: "Hemet",
  },
  {
    postal_code: "58554",
    full_city_name: "Mandan, ND",
    city_name: "Mandan",
  },
  {
    postal_code: "52803",
    full_city_name: "Davenport, IA",
    city_name: "Davenport",
  },
  {
    postal_code: "29672",
    full_city_name: "Seneca, SC",
    city_name: "Seneca",
  },
  {
    postal_code: "64056",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "46901",
    full_city_name: "Kokomo, IN",
    city_name: "Kokomo",
  },
  {
    postal_code: "20876",
    full_city_name: "Germantown, MD",
    city_name: "Germantown",
  },
  {
    postal_code: "92376",
    full_city_name: "Rialto, CA",
    city_name: "Rialto",
  },
  {
    postal_code: "77904",
    full_city_name: "Victoria, TX",
    city_name: "Victoria",
  },
  {
    postal_code: "34217",
    full_city_name: "Bradenton Beach, FL",
    city_name: "Bradenton Beach",
  },
  {
    postal_code: "95125",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "86440",
    full_city_name: "Mohave Valley, AZ",
    city_name: "Mohave Valley",
  },
  {
    postal_code: "30110",
    full_city_name: "Bremen, GA",
    city_name: "Bremen",
  },
  {
    postal_code: "25705",
    full_city_name: "Huntington, WV",
    city_name: "Huntington",
  },
  {
    postal_code: "32311",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "20111",
    full_city_name: "Manassas, VA",
    city_name: "Manassas",
  },
  {
    postal_code: "11776",
    full_city_name: "Port Jefferson Station, NY",
    city_name: "Port Jefferson Station",
  },
  {
    postal_code: "54449",
    full_city_name: "Marshfield, WI",
    city_name: "Marshfield",
  },
  {
    postal_code: "04330",
    full_city_name: "Augusta, ME",
    city_name: "Augusta",
  },
  {
    postal_code: "76123",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "80920",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "84664",
    full_city_name: "Mapleton, UT",
    city_name: "Mapleton",
  },
  {
    postal_code: "38401",
    full_city_name: "Columbia, TN",
    city_name: "Columbia",
  },
  {
    postal_code: "33777",
    full_city_name: "Seminole, FL",
    city_name: "Seminole",
  },
  {
    postal_code: "29601",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "64111",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "27504",
    full_city_name: "Benson, NC",
    city_name: "Benson",
  },
  {
    postal_code: "61101",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "23235",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "24572",
    full_city_name: "Madison Heights, VA",
    city_name: "Madison Heights",
  },
  {
    postal_code: "03102",
    full_city_name: "Manchester, NH",
    city_name: "Manchester",
  },
  {
    postal_code: "30025",
    full_city_name: "Social Circle, GA",
    city_name: "Social Circle",
  },
  {
    postal_code: "19038",
    full_city_name: "Glenside, PA",
    city_name: "Glenside",
  },
  {
    postal_code: "21212",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "44240",
    full_city_name: "Kent, OH",
    city_name: "Kent",
  },
  {
    postal_code: "50401",
    full_city_name: "Mason City, IA",
    city_name: "Mason City",
  },
  {
    postal_code: "29369",
    full_city_name: "Moore, SC",
    city_name: "Moore",
  },
  {
    postal_code: "03251",
    full_city_name: "Lincoln, NH",
    city_name: "Lincoln",
  },
  {
    postal_code: "95531",
    full_city_name: "Crescent City, CA",
    city_name: "Crescent City",
  },
  {
    postal_code: "48036",
    full_city_name: "Clinton Township, MI",
    city_name: "Clinton Township",
  },
  {
    postal_code: "85213",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "49091",
    full_city_name: "Sturgis, MI",
    city_name: "Sturgis",
  },
  {
    postal_code: "80247",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "75225",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "40175",
    full_city_name: "Vine Grove, KY",
    city_name: "Vine Grove",
  },
  {
    postal_code: "34266",
    full_city_name: "Arcadia, FL",
    city_name: "Arcadia",
  },
  {
    postal_code: "19018",
    full_city_name: "Clifton Heights, PA",
    city_name: "Clifton Heights",
  },
  {
    postal_code: "11783",
    full_city_name: "Seaford, NY",
    city_name: "Seaford",
  },
  {
    postal_code: "49464",
    full_city_name: "Zeeland, MI",
    city_name: "Zeeland",
  },
  {
    postal_code: "80549",
    full_city_name: "Wellington, CO",
    city_name: "Wellington",
  },
  {
    postal_code: "66104",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "30189",
    full_city_name: "Woodstock, GA",
    city_name: "Woodstock",
  },
  {
    postal_code: "92570",
    full_city_name: "Perris, CA",
    city_name: "Perris",
  },
  {
    postal_code: "47421",
    full_city_name: "Bedford, IN",
    city_name: "Bedford",
  },
  {
    postal_code: "98028",
    full_city_name: "Kenmore, WA",
    city_name: "Kenmore",
  },
  {
    postal_code: "75019",
    full_city_name: "Coppell, TX",
    city_name: "Coppell",
  },
  {
    postal_code: "11716",
    full_city_name: "Bohemia, NY",
    city_name: "Bohemia",
  },
  {
    postal_code: "55387",
    full_city_name: "Waconia, MN",
    city_name: "Waconia",
  },
  {
    postal_code: "28394",
    full_city_name: "Vass, NC",
    city_name: "Vass",
  },
  {
    postal_code: "85250",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "98045",
    full_city_name: "North Bend, WA",
    city_name: "North Bend",
  },
  {
    postal_code: "91913",
    full_city_name: "Chula Vista, CA",
    city_name: "Chula Vista",
  },
  {
    postal_code: "17015",
    full_city_name: "Carlisle, PA",
    city_name: "Carlisle",
  },
  {
    postal_code: "56379",
    full_city_name: "Sauk Rapids, MN",
    city_name: "Sauk Rapids",
  },
  {
    postal_code: "58801",
    full_city_name: "Williston, ND",
    city_name: "Williston",
  },
  {
    postal_code: "32798",
    full_city_name: "Zellwood, FL",
    city_name: "Zellwood",
  },
  {
    postal_code: "76247",
    full_city_name: "Justin, TX",
    city_name: "Justin",
  },
  {
    postal_code: "64834",
    full_city_name: "Carl Junction, MO",
    city_name: "Carl Junction",
  },
  {
    postal_code: "55386",
    full_city_name: "Victoria, MN",
    city_name: "Victoria",
  },
  {
    postal_code: "75007",
    full_city_name: "Carrollton, TX",
    city_name: "Carrollton",
  },
  {
    postal_code: "11731",
    full_city_name: "East Northport, NY",
    city_name: "East Northport",
  },
  {
    postal_code: "97216",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "66213",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "15229",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "90502",
    full_city_name: "Torrance, CA",
    city_name: "Torrance",
  },
  {
    postal_code: "56011",
    full_city_name: "Belle Plaine, MN",
    city_name: "Belle Plaine",
  },
  {
    postal_code: "82072",
    full_city_name: "Laramie, WY",
    city_name: "Laramie",
  },
  {
    postal_code: "92344",
    full_city_name: "Hesperia, CA",
    city_name: "Hesperia",
  },
  {
    postal_code: "48313",
    full_city_name: "Sterling Heights, MI",
    city_name: "Sterling Heights",
  },
  {
    postal_code: "30268",
    full_city_name: "Palmetto, GA",
    city_name: "Palmetto",
  },
  {
    postal_code: "33566",
    full_city_name: "Plant City, FL",
    city_name: "Plant City",
  },
  {
    postal_code: "98029",
    full_city_name: "Issaquah, WA",
    city_name: "Issaquah",
  },
  {
    postal_code: "33060",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "50158",
    full_city_name: "Marshalltown, IA",
    city_name: "Marshalltown",
  },
  {
    postal_code: "45342",
    full_city_name: "Miamisburg, OH",
    city_name: "Miamisburg",
  },
  {
    postal_code: "79119",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "85208",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "23233",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "34238",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "97524",
    full_city_name: "Eagle Point, OR",
    city_name: "Eagle Point",
  },
  {
    postal_code: "70785",
    full_city_name: "Walker, LA",
    city_name: "Walker",
  },
  {
    postal_code: "97322",
    full_city_name: "Albany, OR",
    city_name: "Albany",
  },
  {
    postal_code: "43420",
    full_city_name: "Fremont, OH",
    city_name: "Fremont",
  },
  {
    postal_code: "59802",
    full_city_name: "Missoula, MT",
    city_name: "Missoula",
  },
  {
    postal_code: "94601",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "21133",
    full_city_name: "Randallstown, MD",
    city_name: "Randallstown",
  },
  {
    postal_code: "73089",
    full_city_name: "Tuttle, OK",
    city_name: "Tuttle",
  },
  {
    postal_code: "97850",
    full_city_name: "La Grande, OR",
    city_name: "La Grande",
  },
  {
    postal_code: "43611",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "92620",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "98204",
    full_city_name: "Everett, WA",
    city_name: "Everett",
  },
  {
    postal_code: "98282",
    full_city_name: "Camano Island, WA",
    city_name: "Camano Island",
  },
  {
    postal_code: "76137",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "66208",
    full_city_name: "Prairie Village, KS",
    city_name: "Prairie Village",
  },
  {
    postal_code: "91764",
    full_city_name: "Ontario, CA",
    city_name: "Ontario",
  },
  {
    postal_code: "98136",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "18330",
    full_city_name: "Effort, PA",
    city_name: "Effort",
  },
  {
    postal_code: "80215",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "60622",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "68133",
    full_city_name: "Papillion, NE",
    city_name: "Papillion",
  },
  {
    postal_code: "91306",
    full_city_name: "Winnetka, CA",
    city_name: "Winnetka",
  },
  {
    postal_code: "94536",
    full_city_name: "Fremont, CA",
    city_name: "Fremont",
  },
  {
    postal_code: "55437",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "14227",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "28479",
    full_city_name: "Winnabow, NC",
    city_name: "Winnabow",
  },
  {
    postal_code: "98107",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "55343",
    full_city_name: "Hopkins, MN",
    city_name: "Hopkins",
  },
  {
    postal_code: "01902",
    full_city_name: "Lynn, MA",
    city_name: "Lynn",
  },
  {
    postal_code: "97221",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "20904",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "90266",
    full_city_name: "Manhattan Beach, CA",
    city_name: "Manhattan Beach",
  },
  {
    postal_code: "94109",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "33716",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "98362",
    full_city_name: "Port Angeles, WA",
    city_name: "Port Angeles",
  },
  {
    postal_code: "76132",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "55391",
    full_city_name: "Wayzata, MN",
    city_name: "Wayzata",
  },
  {
    postal_code: "06824",
    full_city_name: "Fairfield, CT",
    city_name: "Fairfield",
  },
  {
    postal_code: "01040",
    full_city_name: "Holyoke, MA",
    city_name: "Holyoke",
  },
  {
    postal_code: "07405",
    full_city_name: "Butler, NJ",
    city_name: "Butler",
  },
  {
    postal_code: "75094",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "98052",
    full_city_name: "Redmond, WA",
    city_name: "Redmond",
  },
  {
    postal_code: "48371",
    full_city_name: "Oxford, MI",
    city_name: "Oxford",
  },
  {
    postal_code: "22302",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "78750",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "02896",
    full_city_name: "North Smithfield, RI",
    city_name: "North Smithfield",
  },
  {
    postal_code: "27349",
    full_city_name: "Snow Camp, NC",
    city_name: "Snow Camp",
  },
  {
    postal_code: "83617",
    full_city_name: "Emmett, ID",
    city_name: "Emmett",
  },
  {
    postal_code: "08904",
    full_city_name: "Highland Park, NJ",
    city_name: "Highland Park",
  },
  {
    postal_code: "73112",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "78501",
    full_city_name: "Mcallen, TX",
    city_name: "Mcallen",
  },
  {
    postal_code: "92352",
    full_city_name: "Lake Arrowhead, CA",
    city_name: "Lake Arrowhead",
  },
  {
    postal_code: "28152",
    full_city_name: "Shelby, NC",
    city_name: "Shelby",
  },
  {
    postal_code: "33321",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "78577",
    full_city_name: "Pharr, TX",
    city_name: "Pharr",
  },
  {
    postal_code: "77051",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "28658",
    full_city_name: "Newton, NC",
    city_name: "Newton",
  },
  {
    postal_code: "85395",
    full_city_name: "Goodyear, AZ",
    city_name: "Goodyear",
  },
  {
    postal_code: "80219",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "30005",
    full_city_name: "Alpharetta, GA",
    city_name: "Alpharetta",
  },
  {
    postal_code: "76266",
    full_city_name: "Sanger, TX",
    city_name: "Sanger",
  },
  {
    postal_code: "46580",
    full_city_name: "Warsaw, IN",
    city_name: "Warsaw",
  },
  {
    postal_code: "48146",
    full_city_name: "Lincoln Park, MI",
    city_name: "Lincoln Park",
  },
  {
    postal_code: "08021",
    full_city_name: "Clementon, NJ",
    city_name: "Clementon",
  },
  {
    postal_code: "33629",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "98023",
    full_city_name: "Federal Way, WA",
    city_name: "Federal Way",
  },
  {
    postal_code: "98370",
    full_city_name: "Poulsbo, WA",
    city_name: "Poulsbo",
  },
  {
    postal_code: "29316",
    full_city_name: "Boiling Springs, SC",
    city_name: "Boiling Springs",
  },
  {
    postal_code: "10580",
    full_city_name: "Rye, NY",
    city_name: "Rye",
  },
  {
    postal_code: "41048",
    full_city_name: "Hebron, KY",
    city_name: "Hebron",
  },
  {
    postal_code: "29715",
    full_city_name: "Fort Mill, SC",
    city_name: "Fort Mill",
  },
  {
    postal_code: "76706",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "29569",
    full_city_name: "Loris, SC",
    city_name: "Loris",
  },
  {
    postal_code: "63052",
    full_city_name: "Imperial, MO",
    city_name: "Imperial",
  },
  {
    postal_code: "02910",
    full_city_name: "Cranston, RI",
    city_name: "Cranston",
  },
  {
    postal_code: "85743",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "60004",
    full_city_name: "Arlington Heights, IL",
    city_name: "Arlington Heights",
  },
  {
    postal_code: "03820",
    full_city_name: "Dover, NH",
    city_name: "Dover",
  },
  {
    postal_code: "55318",
    full_city_name: "Chaska, MN",
    city_name: "Chaska",
  },
  {
    postal_code: "53575",
    full_city_name: "Oregon, WI",
    city_name: "Oregon",
  },
  {
    postal_code: "17109",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "68523",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "93635",
    full_city_name: "Los Banos, CA",
    city_name: "Los Banos",
  },
  {
    postal_code: "12540",
    full_city_name: "Lagrangeville, NY",
    city_name: "Lagrangeville",
  },
  {
    postal_code: "19134",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "30265",
    full_city_name: "Newnan, GA",
    city_name: "Newnan",
  },
  {
    postal_code: "92307",
    full_city_name: "Apple Valley, CA",
    city_name: "Apple Valley",
  },
  {
    postal_code: "08690",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "32218",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "60118",
    full_city_name: "Dundee, IL",
    city_name: "Dundee",
  },
  {
    postal_code: "39046",
    full_city_name: "Canton, MS",
    city_name: "Canton",
  },
  {
    postal_code: "52806",
    full_city_name: "Davenport, IA",
    city_name: "Davenport",
  },
  {
    postal_code: "16101",
    full_city_name: "New Castle, PA",
    city_name: "New Castle",
  },
  {
    postal_code: "65714",
    full_city_name: "Nixa, MO",
    city_name: "Nixa",
  },
  {
    postal_code: "19320",
    full_city_name: "Coatesville, PA",
    city_name: "Coatesville",
  },
  {
    postal_code: "85024",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "80209",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "30252",
    full_city_name: "Mcdonough, GA",
    city_name: "Mcdonough",
  },
  {
    postal_code: "28791",
    full_city_name: "Hendersonville, NC",
    city_name: "Hendersonville",
  },
  {
    postal_code: "92625",
    full_city_name: "Corona Del Mar, CA",
    city_name: "Corona Del Mar",
  },
  {
    postal_code: "33782",
    full_city_name: "Pinellas Park, FL",
    city_name: "Pinellas Park",
  },
  {
    postal_code: "66007",
    full_city_name: "Basehor, KS",
    city_name: "Basehor",
  },
  {
    postal_code: "96150",
    full_city_name: "South Lake Tahoe, CA",
    city_name: "South Lake Tahoe",
  },
  {
    postal_code: "33711",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "17331",
    full_city_name: "Hanover, PA",
    city_name: "Hanover",
  },
  {
    postal_code: "78108",
    full_city_name: "Cibolo, TX",
    city_name: "Cibolo",
  },
  {
    postal_code: "86303",
    full_city_name: "Prescott, AZ",
    city_name: "Prescott",
  },
  {
    postal_code: "97303",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "05149",
    full_city_name: "Ludlow, VT",
    city_name: "Ludlow",
  },
  {
    postal_code: "35630",
    full_city_name: "Florence, AL",
    city_name: "Florence",
  },
  {
    postal_code: "79606",
    full_city_name: "Abilene, TX",
    city_name: "Abilene",
  },
  {
    postal_code: "34786",
    full_city_name: "Windermere, FL",
    city_name: "Windermere",
  },
  {
    postal_code: "08879",
    full_city_name: "South Amboy, NJ",
    city_name: "South Amboy",
  },
  {
    postal_code: "79416",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "02906",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "19087",
    full_city_name: "Wayne, PA",
    city_name: "Wayne",
  },
  {
    postal_code: "60073",
    full_city_name: "Round Lake, IL",
    city_name: "Round Lake",
  },
  {
    postal_code: "33418",
    full_city_name: "Palm Beach Gardens, FL",
    city_name: "Palm Beach Gardens",
  },
  {
    postal_code: "95762",
    full_city_name: "El Dorado Hills, CA",
    city_name: "El Dorado Hills",
  },
  {
    postal_code: "13152",
    full_city_name: "Skaneateles, NY",
    city_name: "Skaneateles",
  },
  {
    postal_code: "43213",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "43560",
    full_city_name: "Sylvania, OH",
    city_name: "Sylvania",
  },
  {
    postal_code: "90630",
    full_city_name: "Cypress, CA",
    city_name: "Cypress",
  },
  {
    postal_code: "55369",
    full_city_name: "Osseo, MN",
    city_name: "Osseo",
  },
  {
    postal_code: "61021",
    full_city_name: "Dixon, IL",
    city_name: "Dixon",
  },
  {
    postal_code: "87110",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "43725",
    full_city_name: "Cambridge, OH",
    city_name: "Cambridge",
  },
  {
    postal_code: "84029",
    full_city_name: "Grantsville, UT",
    city_name: "Grantsville",
  },
  {
    postal_code: "59937",
    full_city_name: "Whitefish, MT",
    city_name: "Whitefish",
  },
  {
    postal_code: "27525",
    full_city_name: "Franklinton, NC",
    city_name: "Franklinton",
  },
  {
    postal_code: "94954",
    full_city_name: "Petaluma, CA",
    city_name: "Petaluma",
  },
  {
    postal_code: "30277",
    full_city_name: "Sharpsburg, GA",
    city_name: "Sharpsburg",
  },
  {
    postal_code: "74801",
    full_city_name: "Shawnee, OK",
    city_name: "Shawnee",
  },
  {
    postal_code: "49503",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "90280",
    full_city_name: "South Gate, CA",
    city_name: "South Gate",
  },
  {
    postal_code: "48220",
    full_city_name: "Ferndale, MI",
    city_name: "Ferndale",
  },
  {
    postal_code: "98686",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "28401",
    full_city_name: "Wilmington, NC",
    city_name: "Wilmington",
  },
  {
    postal_code: "34759",
    full_city_name: "Kissimmee, FL",
    city_name: "Kissimmee",
  },
  {
    postal_code: "50211",
    full_city_name: "Norwalk, IA",
    city_name: "Norwalk",
  },
  {
    postal_code: "28504",
    full_city_name: "Kinston, NC",
    city_name: "Kinston",
  },
  {
    postal_code: "06119",
    full_city_name: "West Hartford, CT",
    city_name: "West Hartford",
  },
  {
    postal_code: "27605",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "77808",
    full_city_name: "Bryan, TX",
    city_name: "Bryan",
  },
  {
    postal_code: "60586",
    full_city_name: "Plainfield, IL",
    city_name: "Plainfield",
  },
  {
    postal_code: "32966",
    full_city_name: "Vero Beach, FL",
    city_name: "Vero Beach",
  },
  {
    postal_code: "85120",
    full_city_name: "Apache Junction, AZ",
    city_name: "Apache Junction",
  },
  {
    postal_code: "42104",
    full_city_name: "Bowling Green, KY",
    city_name: "Bowling Green",
  },
  {
    postal_code: "24112",
    full_city_name: "Martinsville, VA",
    city_name: "Martinsville",
  },
  {
    postal_code: "49424",
    full_city_name: "Holland, MI",
    city_name: "Holland",
  },
  {
    postal_code: "39208",
    full_city_name: "Pearl, MS",
    city_name: "Pearl",
  },
  {
    postal_code: "08722",
    full_city_name: "Beachwood, NJ",
    city_name: "Beachwood",
  },
  {
    postal_code: "61244",
    full_city_name: "East Moline, IL",
    city_name: "East Moline",
  },
  {
    postal_code: "92801",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "38301",
    full_city_name: "Jackson, TN",
    city_name: "Jackson",
  },
  {
    postal_code: "20007",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "92114",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "85713",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "08723",
    full_city_name: "Brick, NJ",
    city_name: "Brick",
  },
  {
    postal_code: "66203",
    full_city_name: "Shawnee, KS",
    city_name: "Shawnee",
  },
  {
    postal_code: "92027",
    full_city_name: "Escondido, CA",
    city_name: "Escondido",
  },
  {
    postal_code: "83714",
    full_city_name: "Garden City, ID",
    city_name: "Garden City",
  },
  {
    postal_code: "52403",
    full_city_name: "Cedar Rapids, IA",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "92127",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "15057",
    full_city_name: "Mc Donald, PA",
    city_name: "Mc Donald",
  },
  {
    postal_code: "98502",
    full_city_name: "Olympia, WA",
    city_name: "Olympia",
  },
  {
    postal_code: "75862",
    full_city_name: "Trinity, TX",
    city_name: "Trinity",
  },
  {
    postal_code: "21061",
    full_city_name: "Glen Burnie, MD",
    city_name: "Glen Burnie",
  },
  {
    postal_code: "80226",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "46122",
    full_city_name: "Danville, IN",
    city_name: "Danville",
  },
  {
    postal_code: "38118",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "59602",
    full_city_name: "Helena, MT",
    city_name: "Helena",
  },
  {
    postal_code: "90638",
    full_city_name: "La Mirada, CA",
    city_name: "La Mirada",
  },
  {
    postal_code: "06118",
    full_city_name: "East Hartford, CT",
    city_name: "East Hartford",
  },
  {
    postal_code: "92553",
    full_city_name: "Moreno Valley, CA",
    city_name: "Moreno Valley",
  },
  {
    postal_code: "33614",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "47630",
    full_city_name: "Newburgh, IN",
    city_name: "Newburgh",
  },
  {
    postal_code: "60025",
    full_city_name: "Glenview, IL",
    city_name: "Glenview",
  },
  {
    postal_code: "40207",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "63141",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "92211",
    full_city_name: "Palm Desert, CA",
    city_name: "Palm Desert",
  },
  {
    postal_code: "02882",
    full_city_name: "Narragansett, RI",
    city_name: "Narragansett",
  },
  {
    postal_code: "34108",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "28429",
    full_city_name: "Castle Hayne, NC",
    city_name: "Castle Hayne",
  },
  {
    postal_code: "22602",
    full_city_name: "Winchester, VA",
    city_name: "Winchester",
  },
  {
    postal_code: "89701",
    full_city_name: "Carson City, NV",
    city_name: "Carson City",
  },
  {
    postal_code: "32110",
    full_city_name: "Bunnell, FL",
    city_name: "Bunnell",
  },
  {
    postal_code: "77498",
    full_city_name: "Sugar Land, TX",
    city_name: "Sugar Land",
  },
  {
    postal_code: "30525",
    full_city_name: "Clayton, GA",
    city_name: "Clayton",
  },
  {
    postal_code: "46234",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "08534",
    full_city_name: "Pennington, NJ",
    city_name: "Pennington",
  },
  {
    postal_code: "77040",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "33914",
    full_city_name: "Cape Coral, FL",
    city_name: "Cape Coral",
  },
  {
    postal_code: "11949",
    full_city_name: "Manorville, NY",
    city_name: "Manorville",
  },
  {
    postal_code: "29617",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "34949",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "60104",
    full_city_name: "Bellwood, IL",
    city_name: "Bellwood",
  },
  {
    postal_code: "97123",
    full_city_name: "Hillsboro, OR",
    city_name: "Hillsboro",
  },
  {
    postal_code: "30286",
    full_city_name: "Thomaston, GA",
    city_name: "Thomaston",
  },
  {
    postal_code: "27516",
    full_city_name: "Chapel Hill, NC",
    city_name: "Chapel Hill",
  },
  {
    postal_code: "94580",
    full_city_name: "San Lorenzo, CA",
    city_name: "San Lorenzo",
  },
  {
    postal_code: "33445",
    full_city_name: "Delray Beach, FL",
    city_name: "Delray Beach",
  },
  {
    postal_code: "98366",
    full_city_name: "Port Orchard, WA",
    city_name: "Port Orchard",
  },
  {
    postal_code: "28425",
    full_city_name: "Burgaw, NC",
    city_name: "Burgaw",
  },
  {
    postal_code: "08861",
    full_city_name: "Perth Amboy, NJ",
    city_name: "Perth Amboy",
  },
  {
    postal_code: "78227",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "78261",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "55807",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "21619",
    full_city_name: "Chester, MD",
    city_name: "Chester",
  },
  {
    postal_code: "44131",
    full_city_name: "Independence, OH",
    city_name: "Independence",
  },
  {
    postal_code: "76112",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "55744",
    full_city_name: "Grand Rapids, MN",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "98802",
    full_city_name: "East Wenatchee, WA",
    city_name: "East Wenatchee",
  },
  {
    postal_code: "06704",
    full_city_name: "Waterbury, CT",
    city_name: "Waterbury",
  },
  {
    postal_code: "89815",
    full_city_name: "Spring Creek, NV",
    city_name: "Spring Creek",
  },
  {
    postal_code: "46256",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "92009",
    full_city_name: "Carlsbad, CA",
    city_name: "Carlsbad",
  },
  {
    postal_code: "78626",
    full_city_name: "Georgetown, TX",
    city_name: "Georgetown",
  },
  {
    postal_code: "19380",
    full_city_name: "West Chester, PA",
    city_name: "West Chester",
  },
  {
    postal_code: "50010",
    full_city_name: "Ames, IA",
    city_name: "Ames",
  },
  {
    postal_code: "85658",
    full_city_name: "Marana, AZ",
    city_name: "Marana",
  },
  {
    postal_code: "13204",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "43512",
    full_city_name: "Defiance, OH",
    city_name: "Defiance",
  },
  {
    postal_code: "93401",
    full_city_name: "San Luis Obispo, CA",
    city_name: "San Luis Obispo",
  },
  {
    postal_code: "60514",
    full_city_name: "Clarendon Hills, IL",
    city_name: "Clarendon Hills",
  },
  {
    postal_code: "80241",
    full_city_name: "Thornton, CO",
    city_name: "Thornton",
  },
  {
    postal_code: "33870",
    full_city_name: "Sebring, FL",
    city_name: "Sebring",
  },
  {
    postal_code: "67502",
    full_city_name: "Hutchinson, KS",
    city_name: "Hutchinson",
  },
  {
    postal_code: "30907",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "90250",
    full_city_name: "Hawthorne, CA",
    city_name: "Hawthorne",
  },
  {
    postal_code: "19023",
    full_city_name: "Darby, PA",
    city_name: "Darby",
  },
  {
    postal_code: "44055",
    full_city_name: "Lorain, OH",
    city_name: "Lorain",
  },
  {
    postal_code: "30461",
    full_city_name: "Statesboro, GA",
    city_name: "Statesboro",
  },
  {
    postal_code: "34652",
    full_city_name: "New Port Richey, FL",
    city_name: "New Port Richey",
  },
  {
    postal_code: "19522",
    full_city_name: "Fleetwood, PA",
    city_name: "Fleetwood",
  },
  {
    postal_code: "85747",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "40219",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "91942",
    full_city_name: "La Mesa, CA",
    city_name: "La Mesa",
  },
  {
    postal_code: "75048",
    full_city_name: "Sachse, TX",
    city_name: "Sachse",
  },
  {
    postal_code: "08611",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "11422",
    full_city_name: "Rosedale, NY",
    city_name: "Rosedale",
  },
  {
    postal_code: "32807",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "11976",
    full_city_name: "Water Mill, NY",
    city_name: "Water Mill",
  },
  {
    postal_code: "30012",
    full_city_name: "Conyers, GA",
    city_name: "Conyers",
  },
  {
    postal_code: "97501",
    full_city_name: "Medford, OR",
    city_name: "Medford",
  },
  {
    postal_code: "88008",
    full_city_name: "Santa Teresa, NM",
    city_name: "Santa Teresa",
  },
  {
    postal_code: "97361",
    full_city_name: "Monmouth, OR",
    city_name: "Monmouth",
  },
  {
    postal_code: "62656",
    full_city_name: "Lincoln, IL",
    city_name: "Lincoln",
  },
  {
    postal_code: "77338",
    full_city_name: "Humble, TX",
    city_name: "Humble",
  },
  {
    postal_code: "15202",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "17821",
    full_city_name: "Danville, PA",
    city_name: "Danville",
  },
  {
    postal_code: "45432",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "45356",
    full_city_name: "Piqua, OH",
    city_name: "Piqua",
  },
  {
    postal_code: "84109",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "92870",
    full_city_name: "Placentia, CA",
    city_name: "Placentia",
  },
  {
    postal_code: "27517",
    full_city_name: "Chapel Hill, NC",
    city_name: "Chapel Hill",
  },
  {
    postal_code: "29678",
    full_city_name: "Seneca, SC",
    city_name: "Seneca",
  },
  {
    postal_code: "63005",
    full_city_name: "Chesterfield, MO",
    city_name: "Chesterfield",
  },
  {
    postal_code: "44139",
    full_city_name: "Solon, OH",
    city_name: "Solon",
  },
  {
    postal_code: "77429",
    full_city_name: "Cypress, TX",
    city_name: "Cypress",
  },
  {
    postal_code: "10013",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "10314",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "80537",
    full_city_name: "Loveland, CO",
    city_name: "Loveland",
  },
  {
    postal_code: "11787",
    full_city_name: "Smithtown, NY",
    city_name: "Smithtown",
  },
  {
    postal_code: "78613",
    full_city_name: "Cedar Park, TX",
    city_name: "Cedar Park",
  },
  {
    postal_code: "30101",
    full_city_name: "Acworth, GA",
    city_name: "Acworth",
  },
  {
    postal_code: "60631",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "15241",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "97211",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "55304",
    full_city_name: "Andover, MN",
    city_name: "Andover",
  },
  {
    postal_code: "52241",
    full_city_name: "Coralville, IA",
    city_name: "Coralville",
  },
  {
    postal_code: "90503",
    full_city_name: "Torrance, CA",
    city_name: "Torrance",
  },
  {
    postal_code: "75072",
    full_city_name: "Mckinney, TX",
    city_name: "Mckinney",
  },
  {
    postal_code: "91354",
    full_city_name: "Valencia, CA",
    city_name: "Valencia",
  },
  {
    postal_code: "19342",
    full_city_name: "Glen Mills, PA",
    city_name: "Glen Mills",
  },
  {
    postal_code: "02905",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "98026",
    full_city_name: "Edmonds, WA",
    city_name: "Edmonds",
  },
  {
    postal_code: "92506",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "33981",
    full_city_name: "Port Charlotte, FL",
    city_name: "Port Charlotte",
  },
  {
    postal_code: "83616",
    full_city_name: "Eagle, ID",
    city_name: "Eagle",
  },
  {
    postal_code: "97355",
    full_city_name: "Lebanon, OR",
    city_name: "Lebanon",
  },
  {
    postal_code: "28468",
    full_city_name: "Sunset Beach, NC",
    city_name: "Sunset Beach",
  },
  {
    postal_code: "85338",
    full_city_name: "Goodyear, AZ",
    city_name: "Goodyear",
  },
  {
    postal_code: "77590",
    full_city_name: "Texas City, TX",
    city_name: "Texas City",
  },
  {
    postal_code: "96778",
    full_city_name: "Pahoa, HI",
    city_name: "Pahoa",
  },
  {
    postal_code: "63701",
    full_city_name: "Cape Girardeau, MO",
    city_name: "Cape Girardeau",
  },
  {
    postal_code: "70130",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "95864",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "43065",
    full_city_name: "Powell, OH",
    city_name: "Powell",
  },
  {
    postal_code: "78578",
    full_city_name: "Port Isabel, TX",
    city_name: "Port Isabel",
  },
  {
    postal_code: "96782",
    full_city_name: "Pearl City, HI",
    city_name: "Pearl City",
  },
  {
    postal_code: "30011",
    full_city_name: "Auburn, GA",
    city_name: "Auburn",
  },
  {
    postal_code: "29526",
    full_city_name: "Conway, SC",
    city_name: "Conway",
  },
  {
    postal_code: "83864",
    full_city_name: "Sandpoint, ID",
    city_name: "Sandpoint",
  },
  {
    postal_code: "77477",
    full_city_name: "Stafford, TX",
    city_name: "Stafford",
  },
  {
    postal_code: "95242",
    full_city_name: "Lodi, CA",
    city_name: "Lodi",
  },
  {
    postal_code: "13126",
    full_city_name: "Oswego, NY",
    city_name: "Oswego",
  },
  {
    postal_code: "44102",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "33483",
    full_city_name: "Delray Beach, FL",
    city_name: "Delray Beach",
  },
  {
    postal_code: "70043",
    full_city_name: "Chalmette, LA",
    city_name: "Chalmette",
  },
  {
    postal_code: "78221",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "74604",
    full_city_name: "Ponca City, OK",
    city_name: "Ponca City",
  },
  {
    postal_code: "92595",
    full_city_name: "Wildomar, CA",
    city_name: "Wildomar",
  },
  {
    postal_code: "71291",
    full_city_name: "West Monroe, LA",
    city_name: "West Monroe",
  },
  {
    postal_code: "30296",
    full_city_name: "Riverdale, GA",
    city_name: "Riverdale",
  },
  {
    postal_code: "30813",
    full_city_name: "Grovetown, GA",
    city_name: "Grovetown",
  },
  {
    postal_code: "76048",
    full_city_name: "Granbury, TX",
    city_name: "Granbury",
  },
  {
    postal_code: "78701",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "92057",
    full_city_name: "Oceanside, CA",
    city_name: "Oceanside",
  },
  {
    postal_code: "85710",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "28327",
    full_city_name: "Carthage, NC",
    city_name: "Carthage",
  },
  {
    postal_code: "72715",
    full_city_name: "Bella Vista, AR",
    city_name: "Bella Vista",
  },
  {
    postal_code: "92881",
    full_city_name: "Corona, CA",
    city_name: "Corona",
  },
  {
    postal_code: "75134",
    full_city_name: "Lancaster, TX",
    city_name: "Lancaster",
  },
  {
    postal_code: "95228",
    full_city_name: "Copperopolis, CA",
    city_name: "Copperopolis",
  },
  {
    postal_code: "32117",
    full_city_name: "Daytona Beach, FL",
    city_name: "Daytona Beach",
  },
  {
    postal_code: "78645",
    full_city_name: "Leander, TX",
    city_name: "Leander",
  },
  {
    postal_code: "14845",
    full_city_name: "Horseheads, NY",
    city_name: "Horseheads",
  },
  {
    postal_code: "34209",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "95965",
    full_city_name: "Oroville, CA",
    city_name: "Oroville",
  },
  {
    postal_code: "98662",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "29486",
    full_city_name: "Summerville, SC",
    city_name: "Summerville",
  },
  {
    postal_code: "44095",
    full_city_name: "Eastlake, OH",
    city_name: "Eastlake",
  },
  {
    postal_code: "87121",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "72543",
    full_city_name: "Heber Springs, AR",
    city_name: "Heber Springs",
  },
  {
    postal_code: "20109",
    full_city_name: "Manassas, VA",
    city_name: "Manassas",
  },
  {
    postal_code: "97213",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "30529",
    full_city_name: "Commerce, GA",
    city_name: "Commerce",
  },
  {
    postal_code: "59808",
    full_city_name: "Missoula, MT",
    city_name: "Missoula",
  },
  {
    postal_code: "33525",
    full_city_name: "Dade City, FL",
    city_name: "Dade City",
  },
  {
    postal_code: "33406",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "60074",
    full_city_name: "Palatine, IL",
    city_name: "Palatine",
  },
  {
    postal_code: "37115",
    full_city_name: "Madison, TN",
    city_name: "Madison",
  },
  {
    postal_code: "29485",
    full_city_name: "Summerville, SC",
    city_name: "Summerville",
  },
  {
    postal_code: "77532",
    full_city_name: "Crosby, TX",
    city_name: "Crosby",
  },
  {
    postal_code: "92008",
    full_city_name: "Carlsbad, CA",
    city_name: "Carlsbad",
  },
  {
    postal_code: "30260",
    full_city_name: "Morrow, GA",
    city_name: "Morrow",
  },
  {
    postal_code: "48813",
    full_city_name: "Charlotte, MI",
    city_name: "Charlotte",
  },
  {
    postal_code: "30041",
    full_city_name: "Cumming, GA",
    city_name: "Cumming",
  },
  {
    postal_code: "27455",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "60402",
    full_city_name: "Berwyn, IL",
    city_name: "Berwyn",
  },
  {
    postal_code: "78415",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "60656",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "55912",
    full_city_name: "Austin, MN",
    city_name: "Austin",
  },
  {
    postal_code: "43130",
    full_city_name: "Lancaster, OH",
    city_name: "Lancaster",
  },
  {
    postal_code: "50265",
    full_city_name: "West Des Moines, IA",
    city_name: "West Des Moines",
  },
  {
    postal_code: "96738",
    full_city_name: "Waikoloa, HI",
    city_name: "Waikoloa",
  },
  {
    postal_code: "92253",
    full_city_name: "La Quinta, CA",
    city_name: "La Quinta",
  },
  {
    postal_code: "93720",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "76708",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "55906",
    full_city_name: "Rochester, MN",
    city_name: "Rochester",
  },
  {
    postal_code: "45601",
    full_city_name: "Chillicothe, OH",
    city_name: "Chillicothe",
  },
  {
    postal_code: "07755",
    full_city_name: "Oakhurst, NJ",
    city_name: "Oakhurst",
  },
  {
    postal_code: "76071",
    full_city_name: "Newark, TX",
    city_name: "Newark",
  },
  {
    postal_code: "35180",
    full_city_name: "Warrior, AL",
    city_name: "Warrior",
  },
  {
    postal_code: "22712",
    full_city_name: "Bealeton, VA",
    city_name: "Bealeton",
  },
  {
    postal_code: "48021",
    full_city_name: "Eastpointe, MI",
    city_name: "Eastpointe",
  },
  {
    postal_code: "98036",
    full_city_name: "Lynnwood, WA",
    city_name: "Lynnwood",
  },
  {
    postal_code: "76248",
    full_city_name: "Keller, TX",
    city_name: "Keller",
  },
  {
    postal_code: "77075",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "92563",
    full_city_name: "Murrieta, CA",
    city_name: "Murrieta",
  },
  {
    postal_code: "26505",
    full_city_name: "Morgantown, WV",
    city_name: "Morgantown",
  },
  {
    postal_code: "98569",
    full_city_name: "Ocean Shores, WA",
    city_name: "Ocean Shores",
  },
  {
    postal_code: "98087",
    full_city_name: "Lynnwood, WA",
    city_name: "Lynnwood",
  },
  {
    postal_code: "44515",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "60134",
    full_city_name: "Geneva, IL",
    city_name: "Geneva",
  },
  {
    postal_code: "76501",
    full_city_name: "Temple, TX",
    city_name: "Temple",
  },
  {
    postal_code: "77381",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "85128",
    full_city_name: "Coolidge, AZ",
    city_name: "Coolidge",
  },
  {
    postal_code: "37664",
    full_city_name: "Kingsport, TN",
    city_name: "Kingsport",
  },
  {
    postal_code: "85041",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "49316",
    full_city_name: "Caledonia, MI",
    city_name: "Caledonia",
  },
  {
    postal_code: "36203",
    full_city_name: "Oxford, AL",
    city_name: "Oxford",
  },
  {
    postal_code: "92382",
    full_city_name: "Running Springs, CA",
    city_name: "Running Springs",
  },
  {
    postal_code: "48076",
    full_city_name: "Southfield, MI",
    city_name: "Southfield",
  },
  {
    postal_code: "89052",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "46143",
    full_city_name: "Greenwood, IN",
    city_name: "Greenwood",
  },
  {
    postal_code: "08817",
    full_city_name: "Edison, NJ",
    city_name: "Edison",
  },
  {
    postal_code: "84025",
    full_city_name: "Farmington, UT",
    city_name: "Farmington",
  },
  {
    postal_code: "28052",
    full_city_name: "Gastonia, NC",
    city_name: "Gastonia",
  },
  {
    postal_code: "93446",
    full_city_name: "Paso Robles, CA",
    city_name: "Paso Robles",
  },
  {
    postal_code: "27377",
    full_city_name: "Whitsett, NC",
    city_name: "Whitsett",
  },
  {
    postal_code: "91708",
    full_city_name: "Chino, CA",
    city_name: "Chino",
  },
  {
    postal_code: "55112",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "80121",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "96789",
    full_city_name: "Mililani, HI",
    city_name: "Mililani",
  },
  {
    postal_code: "53534",
    full_city_name: "Edgerton, WI",
    city_name: "Edgerton",
  },
  {
    postal_code: "97838",
    full_city_name: "Hermiston, OR",
    city_name: "Hermiston",
  },
  {
    postal_code: "62526",
    full_city_name: "Decatur, IL",
    city_name: "Decatur",
  },
  {
    postal_code: "46201",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "38116",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "23061",
    full_city_name: "Gloucester, VA",
    city_name: "Gloucester",
  },
  {
    postal_code: "50702",
    full_city_name: "Waterloo, IA",
    city_name: "Waterloo",
  },
  {
    postal_code: "14225",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "15243",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "95765",
    full_city_name: "Rocklin, CA",
    city_name: "Rocklin",
  },
  {
    postal_code: "92220",
    full_city_name: "Banning, CA",
    city_name: "Banning",
  },
  {
    postal_code: "22401",
    full_city_name: "Fredericksburg, VA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "13850",
    full_city_name: "Vestal, NY",
    city_name: "Vestal",
  },
  {
    postal_code: "98466",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "20650",
    full_city_name: "Leonardtown, MD",
    city_name: "Leonardtown",
  },
  {
    postal_code: "43023",
    full_city_name: "Granville, OH",
    city_name: "Granville",
  },
  {
    postal_code: "18505",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "63144",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "55025",
    full_city_name: "Forest Lake, MN",
    city_name: "Forest Lake",
  },
  {
    postal_code: "92025",
    full_city_name: "Escondido, CA",
    city_name: "Escondido",
  },
  {
    postal_code: "30340",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "33760",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "12866",
    full_city_name: "Saratoga Springs, NY",
    city_name: "Saratoga Springs",
  },
  {
    postal_code: "64114",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "32967",
    full_city_name: "Vero Beach, FL",
    city_name: "Vero Beach",
  },
  {
    postal_code: "65613",
    full_city_name: "Bolivar, MO",
    city_name: "Bolivar",
  },
  {
    postal_code: "08332",
    full_city_name: "Millville, NJ",
    city_name: "Millville",
  },
  {
    postal_code: "39532",
    full_city_name: "Biloxi, MS",
    city_name: "Biloxi",
  },
  {
    postal_code: "34114",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "98373",
    full_city_name: "Puyallup, WA",
    city_name: "Puyallup",
  },
  {
    postal_code: "17403",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "63042",
    full_city_name: "Hazelwood, MO",
    city_name: "Hazelwood",
  },
  {
    postal_code: "48602",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "86426",
    full_city_name: "Fort Mohave, AZ",
    city_name: "Fort Mohave",
  },
  {
    postal_code: "02889",
    full_city_name: "Warwick, RI",
    city_name: "Warwick",
  },
  {
    postal_code: "39564",
    full_city_name: "Ocean Springs, MS",
    city_name: "Ocean Springs",
  },
  {
    postal_code: "11968",
    full_city_name: "Southampton, NY",
    city_name: "Southampton",
  },
  {
    postal_code: "29687",
    full_city_name: "Taylors, SC",
    city_name: "Taylors",
  },
  {
    postal_code: "77304",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "11720",
    full_city_name: "Centereach, NY",
    city_name: "Centereach",
  },
  {
    postal_code: "08721",
    full_city_name: "Bayville, NJ",
    city_name: "Bayville",
  },
  {
    postal_code: "61114",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "44314",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "92284",
    full_city_name: "Yucca Valley, CA",
    city_name: "Yucca Valley",
  },
  {
    postal_code: "89512",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "38866",
    full_city_name: "Saltillo, MS",
    city_name: "Saltillo",
  },
  {
    postal_code: "98532",
    full_city_name: "Chehalis, WA",
    city_name: "Chehalis",
  },
  {
    postal_code: "84780",
    full_city_name: "Washington, UT",
    city_name: "Washington",
  },
  {
    postal_code: "98335",
    full_city_name: "Gig Harbor, WA",
    city_name: "Gig Harbor",
  },
  {
    postal_code: "91343",
    full_city_name: "North Hills, CA",
    city_name: "North Hills",
  },
  {
    postal_code: "37167",
    full_city_name: "Smyrna, TN",
    city_name: "Smyrna",
  },
  {
    postal_code: "70518",
    full_city_name: "Broussard, LA",
    city_name: "Broussard",
  },
  {
    postal_code: "95776",
    full_city_name: "Woodland, CA",
    city_name: "Woodland",
  },
  {
    postal_code: "44116",
    full_city_name: "Rocky River, OH",
    city_name: "Rocky River",
  },
  {
    postal_code: "29697",
    full_city_name: "Williamston, SC",
    city_name: "Williamston",
  },
  {
    postal_code: "60457",
    full_city_name: "Hickory Hills, IL",
    city_name: "Hickory Hills",
  },
  {
    postal_code: "44136",
    full_city_name: "Strongsville, OH",
    city_name: "Strongsville",
  },
  {
    postal_code: "99577",
    full_city_name: "Eagle River, AK",
    city_name: "Eagle River",
  },
  {
    postal_code: "38651",
    full_city_name: "Nesbit, MS",
    city_name: "Nesbit",
  },
  {
    postal_code: "75181",
    full_city_name: "Mesquite, TX",
    city_name: "Mesquite",
  },
  {
    postal_code: "33811",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "73162",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "11733",
    full_city_name: "East Setauket, NY",
    city_name: "East Setauket",
  },
  {
    postal_code: "21206",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "87120",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "63755",
    full_city_name: "Jackson, MO",
    city_name: "Jackson",
  },
  {
    postal_code: "20110",
    full_city_name: "Manassas, VA",
    city_name: "Manassas",
  },
  {
    postal_code: "54016",
    full_city_name: "Hudson, WI",
    city_name: "Hudson",
  },
  {
    postal_code: "70791",
    full_city_name: "Zachary, LA",
    city_name: "Zachary",
  },
  {
    postal_code: "32669",
    full_city_name: "Newberry, FL",
    city_name: "Newberry",
  },
  {
    postal_code: "45420",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "46356",
    full_city_name: "Lowell, IN",
    city_name: "Lowell",
  },
  {
    postal_code: "33603",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "08031",
    full_city_name: "Bellmawr, NJ",
    city_name: "Bellmawr",
  },
  {
    postal_code: "75211",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "94043",
    full_city_name: "Mountain View, CA",
    city_name: "Mountain View",
  },
  {
    postal_code: "75204",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "48640",
    full_city_name: "Midland, MI",
    city_name: "Midland",
  },
  {
    postal_code: "95112",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "77091",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "26105",
    full_city_name: "Vienna, WV",
    city_name: "Vienna",
  },
  {
    postal_code: "02633",
    full_city_name: "Chatham, MA",
    city_name: "Chatham",
  },
  {
    postal_code: "42301",
    full_city_name: "Owensboro, KY",
    city_name: "Owensboro",
  },
  {
    postal_code: "80601",
    full_city_name: "Brighton, CO",
    city_name: "Brighton",
  },
  {
    postal_code: "24502",
    full_city_name: "Lynchburg, VA",
    city_name: "Lynchburg",
  },
  {
    postal_code: "19465",
    full_city_name: "Pottstown, PA",
    city_name: "Pottstown",
  },
  {
    postal_code: "28081",
    full_city_name: "Kannapolis, NC",
    city_name: "Kannapolis",
  },
  {
    postal_code: "20653",
    full_city_name: "Lexington Park, MD",
    city_name: "Lexington Park",
  },
  {
    postal_code: "33181",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "80113",
    full_city_name: "Englewood, CO",
    city_name: "Englewood",
  },
  {
    postal_code: "60641",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "21078",
    full_city_name: "Havre De Grace, MD",
    city_name: "Havre De Grace",
  },
  {
    postal_code: "17602",
    full_city_name: "Lancaster, PA",
    city_name: "Lancaster",
  },
  {
    postal_code: "77077",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "59635",
    full_city_name: "East Helena, MT",
    city_name: "East Helena",
  },
  {
    postal_code: "17102",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "47802",
    full_city_name: "Terre Haute, IN",
    city_name: "Terre Haute",
  },
  {
    postal_code: "77486",
    full_city_name: "West Columbia, TX",
    city_name: "West Columbia",
  },
  {
    postal_code: "94597",
    full_city_name: "Walnut Creek, CA",
    city_name: "Walnut Creek",
  },
  {
    postal_code: "11959",
    full_city_name: "Quogue, NY",
    city_name: "Quogue",
  },
  {
    postal_code: "95757",
    full_city_name: "Elk Grove, CA",
    city_name: "Elk Grove",
  },
  {
    postal_code: "68137",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "93444",
    full_city_name: "Nipomo, CA",
    city_name: "Nipomo",
  },
  {
    postal_code: "44312",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "62521",
    full_city_name: "Decatur, IL",
    city_name: "Decatur",
  },
  {
    postal_code: "20695",
    full_city_name: "White Plains, MD",
    city_name: "White Plains",
  },
  {
    postal_code: "89130",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "60647",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "30567",
    full_city_name: "Pendergrass, GA",
    city_name: "Pendergrass",
  },
  {
    postal_code: "21043",
    full_city_name: "Ellicott City, MD",
    city_name: "Ellicott City",
  },
  {
    postal_code: "55316",
    full_city_name: "Champlin, MN",
    city_name: "Champlin",
  },
  {
    postal_code: "45036",
    full_city_name: "Lebanon, OH",
    city_name: "Lebanon",
  },
  {
    postal_code: "91710",
    full_city_name: "Chino, CA",
    city_name: "Chino",
  },
  {
    postal_code: "74112",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "30224",
    full_city_name: "Griffin, GA",
    city_name: "Griffin",
  },
  {
    postal_code: "27539",
    full_city_name: "Apex, NC",
    city_name: "Apex",
  },
  {
    postal_code: "33803",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "06776",
    full_city_name: "New Milford, CT",
    city_name: "New Milford",
  },
  {
    postal_code: "44137",
    full_city_name: "Maple Heights, OH",
    city_name: "Maple Heights",
  },
  {
    postal_code: "55044",
    full_city_name: "Lakeville, MN",
    city_name: "Lakeville",
  },
  {
    postal_code: "91745",
    full_city_name: "Hacienda Heights, CA",
    city_name: "Hacienda Heights",
  },
  {
    postal_code: "20170",
    full_city_name: "Herndon, VA",
    city_name: "Herndon",
  },
  {
    postal_code: "22207",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "70115",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "34974",
    full_city_name: "Okeechobee, FL",
    city_name: "Okeechobee",
  },
  {
    postal_code: "60008",
    full_city_name: "Rolling Meadows, IL",
    city_name: "Rolling Meadows",
  },
  {
    postal_code: "60443",
    full_city_name: "Matteson, IL",
    city_name: "Matteson",
  },
  {
    postal_code: "19971",
    full_city_name: "Rehoboth Beach, DE",
    city_name: "Rehoboth Beach",
  },
  {
    postal_code: "27370",
    full_city_name: "Trinity, NC",
    city_name: "Trinity",
  },
  {
    postal_code: "92411",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "27518",
    full_city_name: "Cary, NC",
    city_name: "Cary",
  },
  {
    postal_code: "02904",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "85304",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "48180",
    full_city_name: "Taylor, MI",
    city_name: "Taylor",
  },
  {
    postal_code: "85118",
    full_city_name: "Gold Canyon, AZ",
    city_name: "Gold Canyon",
  },
  {
    postal_code: "20619",
    full_city_name: "California, MD",
    city_name: "California",
  },
  {
    postal_code: "90601",
    full_city_name: "Whittier, CA",
    city_name: "Whittier",
  },
  {
    postal_code: "30002",
    full_city_name: "Avondale Estates, GA",
    city_name: "Avondale Estates",
  },
  {
    postal_code: "37122",
    full_city_name: "Mount Juliet, TN",
    city_name: "Mount Juliet",
  },
  {
    postal_code: "94521",
    full_city_name: "Concord, CA",
    city_name: "Concord",
  },
  {
    postal_code: "46350",
    full_city_name: "La Porte, IN",
    city_name: "La Porte",
  },
  {
    postal_code: "23221",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "14020",
    full_city_name: "Batavia, NY",
    city_name: "Batavia",
  },
  {
    postal_code: "98661",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "52732",
    full_city_name: "Clinton, IA",
    city_name: "Clinton",
  },
  {
    postal_code: "49444",
    full_city_name: "Muskegon, MI",
    city_name: "Muskegon",
  },
  {
    postal_code: "75701",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "72753",
    full_city_name: "Prairie Grove, AR",
    city_name: "Prairie Grove",
  },
  {
    postal_code: "50021",
    full_city_name: "Ankeny, IA",
    city_name: "Ankeny",
  },
  {
    postal_code: "63121",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "54956",
    full_city_name: "Neenah, WI",
    city_name: "Neenah",
  },
  {
    postal_code: "77441",
    full_city_name: "Fulshear, TX",
    city_name: "Fulshear",
  },
  {
    postal_code: "33713",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "44641",
    full_city_name: "Louisville, OH",
    city_name: "Louisville",
  },
  {
    postal_code: "67208",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "84128",
    full_city_name: "West Valley City, UT",
    city_name: "West Valley City",
  },
  {
    postal_code: "33140",
    full_city_name: "Miami Beach, FL",
    city_name: "Miami Beach",
  },
  {
    postal_code: "42141",
    full_city_name: "Glasgow, KY",
    city_name: "Glasgow",
  },
  {
    postal_code: "24073",
    full_city_name: "Christiansburg, VA",
    city_name: "Christiansburg",
  },
  {
    postal_code: "99801",
    full_city_name: "Juneau, AK",
    city_name: "Juneau",
  },
  {
    postal_code: "98837",
    full_city_name: "Moses Lake, WA",
    city_name: "Moses Lake",
  },
  {
    postal_code: "11374",
    full_city_name: "Rego Park, NY",
    city_name: "Rego Park",
  },
  {
    postal_code: "99324",
    full_city_name: "College Place, WA",
    city_name: "College Place",
  },
  {
    postal_code: "89451",
    full_city_name: "Incline Village, NV",
    city_name: "Incline Village",
  },
  {
    postal_code: "83843",
    full_city_name: "Moscow, ID",
    city_name: "Moscow",
  },
  {
    postal_code: "75238",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "65706",
    full_city_name: "Marshfield, MO",
    city_name: "Marshfield",
  },
  {
    postal_code: "45502",
    full_city_name: "Springfield, OH",
    city_name: "Springfield",
  },
  {
    postal_code: "62226",
    full_city_name: "Belleville, IL",
    city_name: "Belleville",
  },
  {
    postal_code: "21128",
    full_city_name: "Perry Hall, MD",
    city_name: "Perry Hall",
  },
  {
    postal_code: "31088",
    full_city_name: "Warner Robins, GA",
    city_name: "Warner Robins",
  },
  {
    postal_code: "10019",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "92037",
    full_city_name: "La Jolla, CA",
    city_name: "La Jolla",
  },
  {
    postal_code: "92865",
    full_city_name: "Orange, CA",
    city_name: "Orange",
  },
  {
    postal_code: "64014",
    full_city_name: "Blue Springs, MO",
    city_name: "Blue Springs",
  },
  {
    postal_code: "11704",
    full_city_name: "West Babylon, NY",
    city_name: "West Babylon",
  },
  {
    postal_code: "23124",
    full_city_name: "New Kent, VA",
    city_name: "New Kent",
  },
  {
    postal_code: "37912",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "61802",
    full_city_name: "Urbana, IL",
    city_name: "Urbana",
  },
  {
    postal_code: "07922",
    full_city_name: "Berkeley Heights, NJ",
    city_name: "Berkeley Heights",
  },
  {
    postal_code: "93906",
    full_city_name: "Salinas, CA",
    city_name: "Salinas",
  },
  {
    postal_code: "40511",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "92637",
    full_city_name: "Laguna Woods, CA",
    city_name: "Laguna Woods",
  },
  {
    postal_code: "78735",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "34470",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "78382",
    full_city_name: "Rockport, TX",
    city_name: "Rockport",
  },
  {
    postal_code: "80205",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "07093",
    full_city_name: "West New York, NJ",
    city_name: "West New York",
  },
  {
    postal_code: "89403",
    full_city_name: "Dayton, NV",
    city_name: "Dayton",
  },
  {
    postal_code: "16506",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "75220",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "72713",
    full_city_name: "Bentonville, AR",
    city_name: "Bentonville",
  },
  {
    postal_code: "22485",
    full_city_name: "King George, VA",
    city_name: "King George",
  },
  {
    postal_code: "20785",
    full_city_name: "Hyattsville, MD",
    city_name: "Hyattsville",
  },
  {
    postal_code: "20190",
    full_city_name: "Reston, VA",
    city_name: "Reston",
  },
  {
    postal_code: "11953",
    full_city_name: "Middle Island, NY",
    city_name: "Middle Island",
  },
  {
    postal_code: "26508",
    full_city_name: "Morgantown, WV",
    city_name: "Morgantown",
  },
  {
    postal_code: "30127",
    full_city_name: "Powder Springs, GA",
    city_name: "Powder Springs",
  },
  {
    postal_code: "20016",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "55811",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "77450",
    full_city_name: "Katy, TX",
    city_name: "Katy",
  },
  {
    postal_code: "65802",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "87571",
    full_city_name: "Taos, NM",
    city_name: "Taos",
  },
  {
    postal_code: "97209",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "71107",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "83669",
    full_city_name: "Star, ID",
    city_name: "Star",
  },
  {
    postal_code: "24060",
    full_city_name: "Blacksburg, VA",
    city_name: "Blacksburg",
  },
  {
    postal_code: "72601",
    full_city_name: "Harrison, AR",
    city_name: "Harrison",
  },
  {
    postal_code: "02817",
    full_city_name: "West Greenwich, RI",
    city_name: "West Greenwich",
  },
  {
    postal_code: "87544",
    full_city_name: "Los Alamos, NM",
    city_name: "Los Alamos",
  },
  {
    postal_code: "78664",
    full_city_name: "Round Rock, TX",
    city_name: "Round Rock",
  },
  {
    postal_code: "27601",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "82009",
    full_city_name: "Cheyenne, WY",
    city_name: "Cheyenne",
  },
  {
    postal_code: "11566",
    full_city_name: "Merrick, NY",
    city_name: "Merrick",
  },
  {
    postal_code: "20817",
    full_city_name: "Bethesda, MD",
    city_name: "Bethesda",
  },
  {
    postal_code: "80301",
    full_city_name: "Boulder, CO",
    city_name: "Boulder",
  },
  {
    postal_code: "33767",
    full_city_name: "Clearwater Beach, FL",
    city_name: "Clearwater Beach",
  },
  {
    postal_code: "21804",
    full_city_name: "Salisbury, MD",
    city_name: "Salisbury",
  },
  {
    postal_code: "41094",
    full_city_name: "Walton, KY",
    city_name: "Walton",
  },
  {
    postal_code: "34771",
    full_city_name: "Saint Cloud, FL",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "28166",
    full_city_name: "Troutman, NC",
    city_name: "Troutman",
  },
  {
    postal_code: "32771",
    full_city_name: "Sanford, FL",
    city_name: "Sanford",
  },
  {
    postal_code: "48316",
    full_city_name: "Utica, MI",
    city_name: "Utica",
  },
  {
    postal_code: "12309",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "30180",
    full_city_name: "Villa Rica, GA",
    city_name: "Villa Rica",
  },
  {
    postal_code: "29936",
    full_city_name: "Ridgeland, SC",
    city_name: "Ridgeland",
  },
  {
    postal_code: "84532",
    full_city_name: "Moab, UT",
    city_name: "Moab",
  },
  {
    postal_code: "31792",
    full_city_name: "Thomasville, GA",
    city_name: "Thomasville",
  },
  {
    postal_code: "60191",
    full_city_name: "Wood Dale, IL",
    city_name: "Wood Dale",
  },
  {
    postal_code: "80465",
    full_city_name: "Morrison, CO",
    city_name: "Morrison",
  },
  {
    postal_code: "46205",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "49738",
    full_city_name: "Grayling, MI",
    city_name: "Grayling",
  },
  {
    postal_code: "34221",
    full_city_name: "Palmetto, FL",
    city_name: "Palmetto",
  },
  {
    postal_code: "93422",
    full_city_name: "Atascadero, CA",
    city_name: "Atascadero",
  },
  {
    postal_code: "60447",
    full_city_name: "Minooka, IL",
    city_name: "Minooka",
  },
  {
    postal_code: "60490",
    full_city_name: "Bolingbrook, IL",
    city_name: "Bolingbrook",
  },
  {
    postal_code: "28723",
    full_city_name: "Cullowhee, NC",
    city_name: "Cullowhee",
  },
  {
    postal_code: "98272",
    full_city_name: "Monroe, WA",
    city_name: "Monroe",
  },
  {
    postal_code: "31605",
    full_city_name: "Valdosta, GA",
    city_name: "Valdosta",
  },
  {
    postal_code: "57785",
    full_city_name: "Sturgis, SD",
    city_name: "Sturgis",
  },
  {
    postal_code: "98383",
    full_city_name: "Silverdale, WA",
    city_name: "Silverdale",
  },
  {
    postal_code: "63080",
    full_city_name: "Sullivan, MO",
    city_name: "Sullivan",
  },
  {
    postal_code: "84337",
    full_city_name: "Tremonton, UT",
    city_name: "Tremonton",
  },
  {
    postal_code: "95833",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "92301",
    full_city_name: "Adelanto, CA",
    city_name: "Adelanto",
  },
  {
    postal_code: "32034",
    full_city_name: "Fernandina Beach, FL",
    city_name: "Fernandina Beach",
  },
  {
    postal_code: "17701",
    full_city_name: "Williamsport, PA",
    city_name: "Williamsport",
  },
  {
    postal_code: "95338",
    full_city_name: "Mariposa, CA",
    city_name: "Mariposa",
  },
  {
    postal_code: "98223",
    full_city_name: "Arlington, WA",
    city_name: "Arlington",
  },
  {
    postal_code: "06851",
    full_city_name: "Norwalk, CT",
    city_name: "Norwalk",
  },
  {
    postal_code: "87031",
    full_city_name: "Los Lunas, NM",
    city_name: "Los Lunas",
  },
  {
    postal_code: "60625",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "29669",
    full_city_name: "Pelzer, SC",
    city_name: "Pelzer",
  },
  {
    postal_code: "80123",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "14850",
    full_city_name: "Ithaca, NY",
    city_name: "Ithaca",
  },
  {
    postal_code: "11364",
    full_city_name: "Oakland Gardens, NY",
    city_name: "Oakland Gardens",
  },
  {
    postal_code: "68164",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "80224",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "32258",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "37763",
    full_city_name: "Kingston, TN",
    city_name: "Kingston",
  },
  {
    postal_code: "44903",
    full_city_name: "Mansfield, OH",
    city_name: "Mansfield",
  },
  {
    postal_code: "63033",
    full_city_name: "Florissant, MO",
    city_name: "Florissant",
  },
  {
    postal_code: "44147",
    full_city_name: "Broadview Heights, OH",
    city_name: "Broadview Heights",
  },
  {
    postal_code: "33415",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "28557",
    full_city_name: "Morehead City, NC",
    city_name: "Morehead City",
  },
  {
    postal_code: "11436",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "76020",
    full_city_name: "Azle, TX",
    city_name: "Azle",
  },
  {
    postal_code: "46158",
    full_city_name: "Mooresville, IN",
    city_name: "Mooresville",
  },
  {
    postal_code: "22312",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "75032",
    full_city_name: "Rockwall, TX",
    city_name: "Rockwall",
  },
  {
    postal_code: "45255",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "85266",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "28650",
    full_city_name: "Maiden, NC",
    city_name: "Maiden",
  },
  {
    postal_code: "44708",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "41001",
    full_city_name: "Alexandria, KY",
    city_name: "Alexandria",
  },
  {
    postal_code: "33852",
    full_city_name: "Lake Placid, FL",
    city_name: "Lake Placid",
  },
  {
    postal_code: "92203",
    full_city_name: "Indio, CA",
    city_name: "Indio",
  },
  {
    postal_code: "77006",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "37405",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "46220",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "34639",
    full_city_name: "Land O Lakes, FL",
    city_name: "Land O Lakes",
  },
  {
    postal_code: "33919",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "08302",
    full_city_name: "Bridgeton, NJ",
    city_name: "Bridgeton",
  },
  {
    postal_code: "98310",
    full_city_name: "Bremerton, WA",
    city_name: "Bremerton",
  },
  {
    postal_code: "97035",
    full_city_name: "Lake Oswego, OR",
    city_name: "Lake Oswego",
  },
  {
    postal_code: "87107",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "84770",
    full_city_name: "Saint George, UT",
    city_name: "Saint George",
  },
  {
    postal_code: "97212",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "76034",
    full_city_name: "Colleyville, TX",
    city_name: "Colleyville",
  },
  {
    postal_code: "55378",
    full_city_name: "Savage, MN",
    city_name: "Savage",
  },
  {
    postal_code: "94531",
    full_city_name: "Antioch, CA",
    city_name: "Antioch",
  },
  {
    postal_code: "46217",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "60044",
    full_city_name: "Lake Bluff, IL",
    city_name: "Lake Bluff",
  },
  {
    postal_code: "60185",
    full_city_name: "West Chicago, IL",
    city_name: "West Chicago",
  },
  {
    postal_code: "97701",
    full_city_name: "Bend, OR",
    city_name: "Bend",
  },
  {
    postal_code: "41091",
    full_city_name: "Union, KY",
    city_name: "Union",
  },
  {
    postal_code: "32207",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "37042",
    full_city_name: "Clarksville, TN",
    city_name: "Clarksville",
  },
  {
    postal_code: "30188",
    full_city_name: "Woodstock, GA",
    city_name: "Woodstock",
  },
  {
    postal_code: "80442",
    full_city_name: "Fraser, CO",
    city_name: "Fraser",
  },
  {
    postal_code: "33844",
    full_city_name: "Haines City, FL",
    city_name: "Haines City",
  },
  {
    postal_code: "75088",
    full_city_name: "Rowlett, TX",
    city_name: "Rowlett",
  },
  {
    postal_code: "23111",
    full_city_name: "Mechanicsville, VA",
    city_name: "Mechanicsville",
  },
  {
    postal_code: "48195",
    full_city_name: "Southgate, MI",
    city_name: "Southgate",
  },
  {
    postal_code: "17408",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "32401",
    full_city_name: "Panama City, FL",
    city_name: "Panama City",
  },
  {
    postal_code: "92131",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "33572",
    full_city_name: "Apollo Beach, FL",
    city_name: "Apollo Beach",
  },
  {
    postal_code: "60031",
    full_city_name: "Gurnee, IL",
    city_name: "Gurnee",
  },
  {
    postal_code: "65721",
    full_city_name: "Ozark, MO",
    city_name: "Ozark",
  },
  {
    postal_code: "29928",
    full_city_name: "Hilton Head Island, SC",
    city_name: "Hilton Head Island",
  },
  {
    postal_code: "99208",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "11978",
    full_city_name: "Westhampton Beach, NY",
    city_name: "Westhampton Beach",
  },
  {
    postal_code: "45405",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "60123",
    full_city_name: "Elgin, IL",
    city_name: "Elgin",
  },
  {
    postal_code: "93041",
    full_city_name: "Port Hueneme, CA",
    city_name: "Port Hueneme",
  },
  {
    postal_code: "76504",
    full_city_name: "Temple, TX",
    city_name: "Temple",
  },
  {
    postal_code: "07843",
    full_city_name: "Hopatcong, NJ",
    city_name: "Hopatcong",
  },
  {
    postal_code: "33155",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "63105",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "85033",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "76013",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "27603",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "85204",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "92840",
    full_city_name: "Garden Grove, CA",
    city_name: "Garden Grove",
  },
  {
    postal_code: "95482",
    full_city_name: "Ukiah, CA",
    city_name: "Ukiah",
  },
  {
    postal_code: "29307",
    full_city_name: "Spartanburg, SC",
    city_name: "Spartanburg",
  },
  {
    postal_code: "29511",
    full_city_name: "Aynor, SC",
    city_name: "Aynor",
  },
  {
    postal_code: "44870",
    full_city_name: "Sandusky, OH",
    city_name: "Sandusky",
  },
  {
    postal_code: "37914",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "94952",
    full_city_name: "Petaluma, CA",
    city_name: "Petaluma",
  },
  {
    postal_code: "11729",
    full_city_name: "Deer Park, NY",
    city_name: "Deer Park",
  },
  {
    postal_code: "98177",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "33756",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "67410",
    full_city_name: "Abilene, KS",
    city_name: "Abilene",
  },
  {
    postal_code: "85353",
    full_city_name: "Tolleson, AZ",
    city_name: "Tolleson",
  },
  {
    postal_code: "30809",
    full_city_name: "Evans, GA",
    city_name: "Evans",
  },
  {
    postal_code: "54166",
    full_city_name: "Shawano, WI",
    city_name: "Shawano",
  },
  {
    postal_code: "97013",
    full_city_name: "Canby, OR",
    city_name: "Canby",
  },
  {
    postal_code: "27703",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "13820",
    full_city_name: "Oneonta, NY",
    city_name: "Oneonta",
  },
  {
    postal_code: "54914",
    full_city_name: "Appleton, WI",
    city_name: "Appleton",
  },
  {
    postal_code: "77803",
    full_city_name: "Bryan, TX",
    city_name: "Bryan",
  },
  {
    postal_code: "42164",
    full_city_name: "Scottsville, KY",
    city_name: "Scottsville",
  },
  {
    postal_code: "48212",
    full_city_name: "Hamtramck, MI",
    city_name: "Hamtramck",
  },
  {
    postal_code: "75215",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "44132",
    full_city_name: "Euclid, OH",
    city_name: "Euclid",
  },
  {
    postal_code: "22960",
    full_city_name: "Orange, VA",
    city_name: "Orange",
  },
  {
    postal_code: "06511",
    full_city_name: "New Haven, CT",
    city_name: "New Haven",
  },
  {
    postal_code: "95678",
    full_city_name: "Roseville, CA",
    city_name: "Roseville",
  },
  {
    postal_code: "17268",
    full_city_name: "Waynesboro, PA",
    city_name: "Waynesboro",
  },
  {
    postal_code: "75039",
    full_city_name: "Irving, TX",
    city_name: "Irving",
  },
  {
    postal_code: "32909",
    full_city_name: "Palm Bay, FL",
    city_name: "Palm Bay",
  },
  {
    postal_code: "92629",
    full_city_name: "Dana Point, CA",
    city_name: "Dana Point",
  },
  {
    postal_code: "85635",
    full_city_name: "Sierra Vista, AZ",
    city_name: "Sierra Vista",
  },
  {
    postal_code: "88061",
    full_city_name: "Silver City, NM",
    city_name: "Silver City",
  },
  {
    postal_code: "97405",
    full_city_name: "Eugene, OR",
    city_name: "Eugene",
  },
  {
    postal_code: "44709",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "62702",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "33019",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "33708",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "22630",
    full_city_name: "Front Royal, VA",
    city_name: "Front Royal",
  },
  {
    postal_code: "78611",
    full_city_name: "Burnet, TX",
    city_name: "Burnet",
  },
  {
    postal_code: "91761",
    full_city_name: "Ontario, CA",
    city_name: "Ontario",
  },
  {
    postal_code: "32250",
    full_city_name: "Jacksonville Beach, FL",
    city_name: "Jacksonville Beach",
  },
  {
    postal_code: "76528",
    full_city_name: "Gatesville, TX",
    city_name: "Gatesville",
  },
  {
    postal_code: "96701",
    full_city_name: "Aiea, HI",
    city_name: "Aiea",
  },
  {
    postal_code: "60548",
    full_city_name: "Sandwich, IL",
    city_name: "Sandwich",
  },
  {
    postal_code: "12205",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "43110",
    full_city_name: "Canal Winchester, OH",
    city_name: "Canal Winchester",
  },
  {
    postal_code: "73013",
    full_city_name: "Edmond, OK",
    city_name: "Edmond",
  },
  {
    postal_code: "98155",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "83647",
    full_city_name: "Mountain Home, ID",
    city_name: "Mountain Home",
  },
  {
    postal_code: "27889",
    full_city_name: "Washington, NC",
    city_name: "Washington",
  },
  {
    postal_code: "36265",
    full_city_name: "Jacksonville, AL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "69361",
    full_city_name: "Scottsbluff, NE",
    city_name: "Scottsbluff",
  },
  {
    postal_code: "78240",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "65020",
    full_city_name: "Camdenton, MO",
    city_name: "Camdenton",
  },
  {
    postal_code: "17225",
    full_city_name: "Greencastle, PA",
    city_name: "Greencastle",
  },
  {
    postal_code: "72758",
    full_city_name: "Rogers, AR",
    city_name: "Rogers",
  },
  {
    postal_code: "95827",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "76107",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "11365",
    full_city_name: "Fresh Meadows, NY",
    city_name: "Fresh Meadows",
  },
  {
    postal_code: "93638",
    full_city_name: "Madera, CA",
    city_name: "Madera",
  },
  {
    postal_code: "85022",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "21015",
    full_city_name: "Bel Air, MD",
    city_name: "Bel Air",
  },
  {
    postal_code: "97386",
    full_city_name: "Sweet Home, OR",
    city_name: "Sweet Home",
  },
  {
    postal_code: "98059",
    full_city_name: "Renton, WA",
    city_name: "Renton",
  },
  {
    postal_code: "92551",
    full_city_name: "Moreno Valley, CA",
    city_name: "Moreno Valley",
  },
  {
    postal_code: "98007",
    full_city_name: "Bellevue, WA",
    city_name: "Bellevue",
  },
  {
    postal_code: "76712",
    full_city_name: "Woodway, TX",
    city_name: "Woodway",
  },
  {
    postal_code: "15642",
    full_city_name: "Irwin, PA",
    city_name: "Irwin",
  },
  {
    postal_code: "29611",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "65672",
    full_city_name: "Hollister, MO",
    city_name: "Hollister",
  },
  {
    postal_code: "91773",
    full_city_name: "San Dimas, CA",
    city_name: "San Dimas",
  },
  {
    postal_code: "77554",
    full_city_name: "Galveston, TX",
    city_name: "Galveston",
  },
  {
    postal_code: "33134",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "32225",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "59404",
    full_city_name: "Great Falls, MT",
    city_name: "Great Falls",
  },
  {
    postal_code: "11207",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "48240",
    full_city_name: "Redford, MI",
    city_name: "Redford",
  },
  {
    postal_code: "27127",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "80108",
    full_city_name: "Castle Rock, CO",
    city_name: "Castle Rock",
  },
  {
    postal_code: "02914",
    full_city_name: "East Providence, RI",
    city_name: "East Providence",
  },
  {
    postal_code: "06078",
    full_city_name: "Suffield, CT",
    city_name: "Suffield",
  },
  {
    postal_code: "80227",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "61554",
    full_city_name: "Pekin, IL",
    city_name: "Pekin",
  },
  {
    postal_code: "39553",
    full_city_name: "Gautier, MS",
    city_name: "Gautier",
  },
  {
    postal_code: "85373",
    full_city_name: "Sun City, AZ",
    city_name: "Sun City",
  },
  {
    postal_code: "99362",
    full_city_name: "Walla Walla, WA",
    city_name: "Walla Walla",
  },
  {
    postal_code: "36532",
    full_city_name: "Fairhope, AL",
    city_name: "Fairhope",
  },
  {
    postal_code: "44087",
    full_city_name: "Twinsburg, OH",
    city_name: "Twinsburg",
  },
  {
    postal_code: "91302",
    full_city_name: "Calabasas, CA",
    city_name: "Calabasas",
  },
  {
    postal_code: "37771",
    full_city_name: "Lenoir City, TN",
    city_name: "Lenoir City",
  },
  {
    postal_code: "66209",
    full_city_name: "Leawood, KS",
    city_name: "Leawood",
  },
  {
    postal_code: "85083",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "49048",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "90221",
    full_city_name: "Compton, CA",
    city_name: "Compton",
  },
  {
    postal_code: "55056",
    full_city_name: "North Branch, MN",
    city_name: "North Branch",
  },
  {
    postal_code: "21225",
    full_city_name: "Brooklyn, MD",
    city_name: "Brooklyn",
  },
  {
    postal_code: "11580",
    full_city_name: "Valley Stream, NY",
    city_name: "Valley Stream",
  },
  {
    postal_code: "85225",
    full_city_name: "Chandler, AZ",
    city_name: "Chandler",
  },
  {
    postal_code: "85224",
    full_city_name: "Chandler, AZ",
    city_name: "Chandler",
  },
  {
    postal_code: "80631",
    full_city_name: "Greeley, CO",
    city_name: "Greeley",
  },
  {
    postal_code: "11710",
    full_city_name: "Bellmore, NY",
    city_name: "Bellmore",
  },
  {
    postal_code: "32563",
    full_city_name: "Gulf Breeze, FL",
    city_name: "Gulf Breeze",
  },
  {
    postal_code: "27604",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "06905",
    full_city_name: "Stamford, CT",
    city_name: "Stamford",
  },
  {
    postal_code: "80516",
    full_city_name: "Erie, CO",
    city_name: "Erie",
  },
  {
    postal_code: "57783",
    full_city_name: "Spearfish, SD",
    city_name: "Spearfish",
  },
  {
    postal_code: "32277",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "48089",
    full_city_name: "Warren, MI",
    city_name: "Warren",
  },
  {
    postal_code: "60177",
    full_city_name: "South Elgin, IL",
    city_name: "South Elgin",
  },
  {
    postal_code: "21207",
    full_city_name: "Gwynn Oak, MD",
    city_name: "Gwynn Oak",
  },
  {
    postal_code: "77008",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "49058",
    full_city_name: "Hastings, MI",
    city_name: "Hastings",
  },
  {
    postal_code: "11803",
    full_city_name: "Plainview, NY",
    city_name: "Plainview",
  },
  {
    postal_code: "75229",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "21042",
    full_city_name: "Ellicott City, MD",
    city_name: "Ellicott City",
  },
  {
    postal_code: "77583",
    full_city_name: "Rosharon, TX",
    city_name: "Rosharon",
  },
  {
    postal_code: "27949",
    full_city_name: "Kitty Hawk, NC",
    city_name: "Kitty Hawk",
  },
  {
    postal_code: "33477",
    full_city_name: "Jupiter, FL",
    city_name: "Jupiter",
  },
  {
    postal_code: "59401",
    full_city_name: "Great Falls, MT",
    city_name: "Great Falls",
  },
  {
    postal_code: "80524",
    full_city_name: "Fort Collins, CO",
    city_name: "Fort Collins",
  },
  {
    postal_code: "95051",
    full_city_name: "Santa Clara, CA",
    city_name: "Santa Clara",
  },
  {
    postal_code: "60607",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "31601",
    full_city_name: "Valdosta, GA",
    city_name: "Valdosta",
  },
  {
    postal_code: "37312",
    full_city_name: "Cleveland, TN",
    city_name: "Cleveland",
  },
  {
    postal_code: "38583",
    full_city_name: "Sparta, TN",
    city_name: "Sparta",
  },
  {
    postal_code: "62704",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "11204",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "33935",
    full_city_name: "Labelle, FL",
    city_name: "Labelle",
  },
  {
    postal_code: "34432",
    full_city_name: "Dunnellon, FL",
    city_name: "Dunnellon",
  },
  {
    postal_code: "96815",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "02871",
    full_city_name: "Portsmouth, RI",
    city_name: "Portsmouth",
  },
  {
    postal_code: "75044",
    full_city_name: "Garland, TX",
    city_name: "Garland",
  },
  {
    postal_code: "94107",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "48044",
    full_city_name: "Macomb, MI",
    city_name: "Macomb",
  },
  {
    postal_code: "27360",
    full_city_name: "Thomasville, NC",
    city_name: "Thomasville",
  },
  {
    postal_code: "07016",
    full_city_name: "Cranford, NJ",
    city_name: "Cranford",
  },
  {
    postal_code: "22405",
    full_city_name: "Fredericksburg, VA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "85712",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "84721",
    full_city_name: "Cedar City, UT",
    city_name: "Cedar City",
  },
  {
    postal_code: "58504",
    full_city_name: "Bismarck, ND",
    city_name: "Bismarck",
  },
  {
    postal_code: "28079",
    full_city_name: "Indian Trail, NC",
    city_name: "Indian Trail",
  },
  {
    postal_code: "57006",
    full_city_name: "Brookings, SD",
    city_name: "Brookings",
  },
  {
    postal_code: "47374",
    full_city_name: "Richmond, IN",
    city_name: "Richmond",
  },
  {
    postal_code: "53219",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "77318",
    full_city_name: "Willis, TX",
    city_name: "Willis",
  },
  {
    postal_code: "32405",
    full_city_name: "Panama City, FL",
    city_name: "Panama City",
  },
  {
    postal_code: "35401",
    full_city_name: "Tuscaloosa, AL",
    city_name: "Tuscaloosa",
  },
  {
    postal_code: "48051",
    full_city_name: "New Baltimore, MI",
    city_name: "New Baltimore",
  },
  {
    postal_code: "12414",
    full_city_name: "Catskill, NY",
    city_name: "Catskill",
  },
  {
    postal_code: "46140",
    full_city_name: "Greenfield, IN",
    city_name: "Greenfield",
  },
  {
    postal_code: "38017",
    full_city_name: "Collierville, TN",
    city_name: "Collierville",
  },
  {
    postal_code: "60422",
    full_city_name: "Flossmoor, IL",
    city_name: "Flossmoor",
  },
  {
    postal_code: "63031",
    full_city_name: "Florissant, MO",
    city_name: "Florissant",
  },
  {
    postal_code: "20120",
    full_city_name: "Centreville, VA",
    city_name: "Centreville",
  },
  {
    postal_code: "80521",
    full_city_name: "Fort Collins, CO",
    city_name: "Fort Collins",
  },
  {
    postal_code: "43220",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "15132",
    full_city_name: "Mckeesport, PA",
    city_name: "Mckeesport",
  },
  {
    postal_code: "73120",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "78748",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "64158",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "97302",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "84078",
    full_city_name: "Vernal, UT",
    city_name: "Vernal",
  },
  {
    postal_code: "97478",
    full_city_name: "Springfield, OR",
    city_name: "Springfield",
  },
  {
    postal_code: "95037",
    full_city_name: "Morgan Hill, CA",
    city_name: "Morgan Hill",
  },
  {
    postal_code: "60706",
    full_city_name: "Harwood Heights, IL",
    city_name: "Harwood Heights",
  },
  {
    postal_code: "60585",
    full_city_name: "Plainfield, IL",
    city_name: "Plainfield",
  },
  {
    postal_code: "77489",
    full_city_name: "Missouri City, TX",
    city_name: "Missouri City",
  },
  {
    postal_code: "91702",
    full_city_name: "Azusa, CA",
    city_name: "Azusa",
  },
  {
    postal_code: "85745",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "92399",
    full_city_name: "Yucaipa, CA",
    city_name: "Yucaipa",
  },
  {
    postal_code: "32003",
    full_city_name: "Fleming Island, FL",
    city_name: "Fleming Island",
  },
  {
    postal_code: "14127",
    full_city_name: "Orchard Park, NY",
    city_name: "Orchard Park",
  },
  {
    postal_code: "32234",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "32960",
    full_city_name: "Vero Beach, FL",
    city_name: "Vero Beach",
  },
  {
    postal_code: "20112",
    full_city_name: "Manassas, VA",
    city_name: "Manassas",
  },
  {
    postal_code: "44313",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "95207",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "12538",
    full_city_name: "Hyde Park, NY",
    city_name: "Hyde Park",
  },
  {
    postal_code: "98072",
    full_city_name: "Woodinville, WA",
    city_name: "Woodinville",
  },
  {
    postal_code: "32073",
    full_city_name: "Orange Park, FL",
    city_name: "Orange Park",
  },
  {
    postal_code: "84601",
    full_city_name: "Provo, UT",
    city_name: "Provo",
  },
  {
    postal_code: "98103",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "14052",
    full_city_name: "East Aurora, NY",
    city_name: "East Aurora",
  },
  {
    postal_code: "43223",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "84036",
    full_city_name: "Kamas, UT",
    city_name: "Kamas",
  },
  {
    postal_code: "60445",
    full_city_name: "Midlothian, IL",
    city_name: "Midlothian",
  },
  {
    postal_code: "76559",
    full_city_name: "Nolanville, TX",
    city_name: "Nolanville",
  },
  {
    postal_code: "86301",
    full_city_name: "Prescott, AZ",
    city_name: "Prescott",
  },
  {
    postal_code: "31405",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "48073",
    full_city_name: "Royal Oak, MI",
    city_name: "Royal Oak",
  },
  {
    postal_code: "23228",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "71201",
    full_city_name: "Monroe, LA",
    city_name: "Monroe",
  },
  {
    postal_code: "49001",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "48066",
    full_city_name: "Roseville, MI",
    city_name: "Roseville",
  },
  {
    postal_code: "49079",
    full_city_name: "Paw Paw, MI",
    city_name: "Paw Paw",
  },
  {
    postal_code: "98664",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "98117",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "07730",
    full_city_name: "Hazlet, NJ",
    city_name: "Hazlet",
  },
  {
    postal_code: "75110",
    full_city_name: "Corsicana, TX",
    city_name: "Corsicana",
  },
  {
    postal_code: "95076",
    full_city_name: "Watsonville, CA",
    city_name: "Watsonville",
  },
  {
    postal_code: "85122",
    full_city_name: "Casa Grande, AZ",
    city_name: "Casa Grande",
  },
  {
    postal_code: "35146",
    full_city_name: "Springville, AL",
    city_name: "Springville",
  },
  {
    postal_code: "91789",
    full_city_name: "Walnut, CA",
    city_name: "Walnut",
  },
  {
    postal_code: "12528",
    full_city_name: "Highland, NY",
    city_name: "Highland",
  },
  {
    postal_code: "78537",
    full_city_name: "Donna, TX",
    city_name: "Donna",
  },
  {
    postal_code: "37918",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "32643",
    full_city_name: "High Springs, FL",
    city_name: "High Springs",
  },
  {
    postal_code: "80906",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "48820",
    full_city_name: "Dewitt, MI",
    city_name: "Dewitt",
  },
  {
    postal_code: "27616",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "67042",
    full_city_name: "El Dorado, KS",
    city_name: "El Dorado",
  },
  {
    postal_code: "60108",
    full_city_name: "Bloomingdale, IL",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "76087",
    full_city_name: "Weatherford, TX",
    city_name: "Weatherford",
  },
  {
    postal_code: "22180",
    full_city_name: "Vienna, VA",
    city_name: "Vienna",
  },
  {
    postal_code: "38654",
    full_city_name: "Olive Branch, MS",
    city_name: "Olive Branch",
  },
  {
    postal_code: "92653",
    full_city_name: "Laguna Hills, CA",
    city_name: "Laguna Hills",
  },
  {
    postal_code: "49014",
    full_city_name: "Battle Creek, MI",
    city_name: "Battle Creek",
  },
  {
    postal_code: "28711",
    full_city_name: "Black Mountain, NC",
    city_name: "Black Mountain",
  },
  {
    postal_code: "97603",
    full_city_name: "Klamath Falls, OR",
    city_name: "Klamath Falls",
  },
  {
    postal_code: "70663",
    full_city_name: "Sulphur, LA",
    city_name: "Sulphur",
  },
  {
    postal_code: "02895",
    full_city_name: "Woonsocket, RI",
    city_name: "Woonsocket",
  },
  {
    postal_code: "19966",
    full_city_name: "Millsboro, DE",
    city_name: "Millsboro",
  },
  {
    postal_code: "19008",
    full_city_name: "Broomall, PA",
    city_name: "Broomall",
  },
  {
    postal_code: "43440",
    full_city_name: "Lakeside Marblehead, OH",
    city_name: "Lakeside Marblehead",
  },
  {
    postal_code: "93015",
    full_city_name: "Fillmore, CA",
    city_name: "Fillmore",
  },
  {
    postal_code: "81620",
    full_city_name: "Avon, CO",
    city_name: "Avon",
  },
  {
    postal_code: "49418",
    full_city_name: "Grandville, MI",
    city_name: "Grandville",
  },
  {
    postal_code: "78539",
    full_city_name: "Edinburg, TX",
    city_name: "Edinburg",
  },
  {
    postal_code: "85622",
    full_city_name: "Green Valley, AZ",
    city_name: "Green Valley",
  },
  {
    postal_code: "33177",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "30165",
    full_city_name: "Rome, GA",
    city_name: "Rome",
  },
  {
    postal_code: "18301",
    full_city_name: "East Stroudsburg, PA",
    city_name: "East Stroudsburg",
  },
  {
    postal_code: "95321",
    full_city_name: "Groveland, CA",
    city_name: "Groveland",
  },
  {
    postal_code: "32835",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "48047",
    full_city_name: "New Baltimore, MI",
    city_name: "New Baltimore",
  },
  {
    postal_code: "98926",
    full_city_name: "Ellensburg, WA",
    city_name: "Ellensburg",
  },
  {
    postal_code: "52340",
    full_city_name: "Tiffin, IA",
    city_name: "Tiffin",
  },
  {
    postal_code: "28086",
    full_city_name: "Kings Mountain, NC",
    city_name: "Kings Mountain",
  },
  {
    postal_code: "11553",
    full_city_name: "Uniondale, NY",
    city_name: "Uniondale",
  },
  {
    postal_code: "43050",
    full_city_name: "Mount Vernon, OH",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "18210",
    full_city_name: "Albrightsville, PA",
    city_name: "Albrightsville",
  },
  {
    postal_code: "28078",
    full_city_name: "Huntersville, NC",
    city_name: "Huntersville",
  },
  {
    postal_code: "98321",
    full_city_name: "Buckley, WA",
    city_name: "Buckley",
  },
  {
    postal_code: "07722",
    full_city_name: "Colts Neck, NJ",
    city_name: "Colts Neck",
  },
  {
    postal_code: "73078",
    full_city_name: "Piedmont, OK",
    city_name: "Piedmont",
  },
  {
    postal_code: "67114",
    full_city_name: "Newton, KS",
    city_name: "Newton",
  },
  {
    postal_code: "37129",
    full_city_name: "Murfreesboro, TN",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "19129",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "34950",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "36116",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "91780",
    full_city_name: "Temple City, CA",
    city_name: "Temple City",
  },
  {
    postal_code: "44138",
    full_city_name: "Olmsted Falls, OH",
    city_name: "Olmsted Falls",
  },
  {
    postal_code: "33781",
    full_city_name: "Pinellas Park, FL",
    city_name: "Pinellas Park",
  },
  {
    postal_code: "80908",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "66226",
    full_city_name: "Shawnee, KS",
    city_name: "Shawnee",
  },
  {
    postal_code: "33947",
    full_city_name: "Rotonda West, FL",
    city_name: "Rotonda West",
  },
  {
    postal_code: "57108",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "95691",
    full_city_name: "West Sacramento, CA",
    city_name: "West Sacramento",
  },
  {
    postal_code: "44646",
    full_city_name: "Massillon, OH",
    city_name: "Massillon",
  },
  {
    postal_code: "98230",
    full_city_name: "Blaine, WA",
    city_name: "Blaine",
  },
  {
    postal_code: "37355",
    full_city_name: "Manchester, TN",
    city_name: "Manchester",
  },
  {
    postal_code: "29464",
    full_city_name: "Mount Pleasant, SC",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "24017",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "93551",
    full_city_name: "Palmdale, CA",
    city_name: "Palmdale",
  },
  {
    postal_code: "33331",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "60534",
    full_city_name: "Lyons, IL",
    city_name: "Lyons",
  },
  {
    postal_code: "45102",
    full_city_name: "Amelia, OH",
    city_name: "Amelia",
  },
  {
    postal_code: "11001",
    full_city_name: "Floral Park, NY",
    city_name: "Floral Park",
  },
  {
    postal_code: "55449",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "54981",
    full_city_name: "Waupaca, WI",
    city_name: "Waupaca",
  },
  {
    postal_code: "28054",
    full_city_name: "Gastonia, NC",
    city_name: "Gastonia",
  },
  {
    postal_code: "55411",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "86323",
    full_city_name: "Chino Valley, AZ",
    city_name: "Chino Valley",
  },
  {
    postal_code: "95111",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "84310",
    full_city_name: "Eden, UT",
    city_name: "Eden",
  },
  {
    postal_code: "94803",
    full_city_name: "El Sobrante, CA",
    city_name: "El Sobrante",
  },
  {
    postal_code: "45503",
    full_city_name: "Springfield, OH",
    city_name: "Springfield",
  },
  {
    postal_code: "29926",
    full_city_name: "Hilton Head Island, SC",
    city_name: "Hilton Head Island",
  },
  {
    postal_code: "08232",
    full_city_name: "Pleasantville, NJ",
    city_name: "Pleasantville",
  },
  {
    postal_code: "37215",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "14301",
    full_city_name: "Niagara Falls, NY",
    city_name: "Niagara Falls",
  },
  {
    postal_code: "06082",
    full_city_name: "Enfield, CT",
    city_name: "Enfield",
  },
  {
    postal_code: "42501",
    full_city_name: "Somerset, KY",
    city_name: "Somerset",
  },
  {
    postal_code: "11768",
    full_city_name: "Northport, NY",
    city_name: "Northport",
  },
  {
    postal_code: "65101",
    full_city_name: "Jefferson City, MO",
    city_name: "Jefferson City",
  },
  {
    postal_code: "71457",
    full_city_name: "Natchitoches, LA",
    city_name: "Natchitoches",
  },
  {
    postal_code: "85234",
    full_city_name: "Gilbert, AZ",
    city_name: "Gilbert",
  },
  {
    postal_code: "59718",
    full_city_name: "Bozeman, MT",
    city_name: "Bozeman",
  },
  {
    postal_code: "33917",
    full_city_name: "North Fort Myers, FL",
    city_name: "North Fort Myers",
  },
  {
    postal_code: "29920",
    full_city_name: "Saint Helena Island, SC",
    city_name: "Saint Helena Island",
  },
  {
    postal_code: "55420",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "37076",
    full_city_name: "Hermitage, TN",
    city_name: "Hermitage",
  },
  {
    postal_code: "91206",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "80015",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "46706",
    full_city_name: "Auburn, IN",
    city_name: "Auburn",
  },
  {
    postal_code: "11050",
    full_city_name: "Port Washington, NY",
    city_name: "Port Washington",
  },
  {
    postal_code: "85653",
    full_city_name: "Marana, AZ",
    city_name: "Marana",
  },
  {
    postal_code: "32583",
    full_city_name: "Milton, FL",
    city_name: "Milton",
  },
  {
    postal_code: "83815",
    full_city_name: "Coeur D Alene, ID",
    city_name: "Coeur D Alene",
  },
  {
    postal_code: "20721",
    full_city_name: "Bowie, MD",
    city_name: "Bowie",
  },
  {
    postal_code: "94583",
    full_city_name: "San Ramon, CA",
    city_name: "San Ramon",
  },
  {
    postal_code: "12302",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "98121",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "55106",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "75407",
    full_city_name: "Princeton, TX",
    city_name: "Princeton",
  },
  {
    postal_code: "27534",
    full_city_name: "Goldsboro, NC",
    city_name: "Goldsboro",
  },
  {
    postal_code: "46052",
    full_city_name: "Lebanon, IN",
    city_name: "Lebanon",
  },
  {
    postal_code: "27592",
    full_city_name: "Willow Spring, NC",
    city_name: "Willow Spring",
  },
  {
    postal_code: "61820",
    full_city_name: "Champaign, IL",
    city_name: "Champaign",
  },
  {
    postal_code: "49319",
    full_city_name: "Cedar Springs, MI",
    city_name: "Cedar Springs",
  },
  {
    postal_code: "32159",
    full_city_name: "Lady Lake, FL",
    city_name: "Lady Lake",
  },
  {
    postal_code: "92243",
    full_city_name: "El Centro, CA",
    city_name: "El Centro",
  },
  {
    postal_code: "37110",
    full_city_name: "Mcminnville, TN",
    city_name: "Mcminnville",
  },
  {
    postal_code: "48161",
    full_city_name: "Monroe, MI",
    city_name: "Monroe",
  },
  {
    postal_code: "10304",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "16066",
    full_city_name: "Cranberry Township, PA",
    city_name: "Cranberry Township",
  },
  {
    postal_code: "76705",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "11205",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "75758",
    full_city_name: "Chandler, TX",
    city_name: "Chandler",
  },
  {
    postal_code: "85283",
    full_city_name: "Tempe, AZ",
    city_name: "Tempe",
  },
  {
    postal_code: "37214",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "72207",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "70454",
    full_city_name: "Ponchatoula, LA",
    city_name: "Ponchatoula",
  },
  {
    postal_code: "55720",
    full_city_name: "Cloquet, MN",
    city_name: "Cloquet",
  },
  {
    postal_code: "27320",
    full_city_name: "Reidsville, NC",
    city_name: "Reidsville",
  },
  {
    postal_code: "39056",
    full_city_name: "Clinton, MS",
    city_name: "Clinton",
  },
  {
    postal_code: "45133",
    full_city_name: "Hillsboro, OH",
    city_name: "Hillsboro",
  },
  {
    postal_code: "49504",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "61071",
    full_city_name: "Rock Falls, IL",
    city_name: "Rock Falls",
  },
  {
    postal_code: "85014",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "98328",
    full_city_name: "Eatonville, WA",
    city_name: "Eatonville",
  },
  {
    postal_code: "22407",
    full_city_name: "Fredericksburg, VA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "21401",
    full_city_name: "Annapolis, MD",
    city_name: "Annapolis",
  },
  {
    postal_code: "80016",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "21704",
    full_city_name: "Frederick, MD",
    city_name: "Frederick",
  },
  {
    postal_code: "33876",
    full_city_name: "Sebring, FL",
    city_name: "Sebring",
  },
  {
    postal_code: "43123",
    full_city_name: "Grove City, OH",
    city_name: "Grove City",
  },
  {
    postal_code: "73003",
    full_city_name: "Edmond, OK",
    city_name: "Edmond",
  },
  {
    postal_code: "34602",
    full_city_name: "Brooksville, FL",
    city_name: "Brooksville",
  },
  {
    postal_code: "63123",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "06488",
    full_city_name: "Southbury, CT",
    city_name: "Southbury",
  },
  {
    postal_code: "32927",
    full_city_name: "Cocoa, FL",
    city_name: "Cocoa",
  },
  {
    postal_code: "73064",
    full_city_name: "Mustang, OK",
    city_name: "Mustang",
  },
  {
    postal_code: "47404",
    full_city_name: "Bloomington, IN",
    city_name: "Bloomington",
  },
  {
    postal_code: "19103",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "43140",
    full_city_name: "London, OH",
    city_name: "London",
  },
  {
    postal_code: "11211",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "64064",
    full_city_name: "Lees Summit, MO",
    city_name: "Lees Summit",
  },
  {
    postal_code: "65401",
    full_city_name: "Rolla, MO",
    city_name: "Rolla",
  },
  {
    postal_code: "66227",
    full_city_name: "Lenexa, KS",
    city_name: "Lenexa",
  },
  {
    postal_code: "49660",
    full_city_name: "Manistee, MI",
    city_name: "Manistee",
  },
  {
    postal_code: "98387",
    full_city_name: "Spanaway, WA",
    city_name: "Spanaway",
  },
  {
    postal_code: "38671",
    full_city_name: "Southaven, MS",
    city_name: "Southaven",
  },
  {
    postal_code: "94010",
    full_city_name: "Burlingame, CA",
    city_name: "Burlingame",
  },
  {
    postal_code: "33351",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "98058",
    full_city_name: "Renton, WA",
    city_name: "Renton",
  },
  {
    postal_code: "33974",
    full_city_name: "Lehigh Acres, FL",
    city_name: "Lehigh Acres",
  },
  {
    postal_code: "95120",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "12304",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "44094",
    full_city_name: "Willoughby, OH",
    city_name: "Willoughby",
  },
  {
    postal_code: "35406",
    full_city_name: "Tuscaloosa, AL",
    city_name: "Tuscaloosa",
  },
  {
    postal_code: "33326",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "34690",
    full_city_name: "Holiday, FL",
    city_name: "Holiday",
  },
  {
    postal_code: "98503",
    full_city_name: "Lacey, WA",
    city_name: "Lacey",
  },
  {
    postal_code: "64093",
    full_city_name: "Warrensburg, MO",
    city_name: "Warrensburg",
  },
  {
    postal_code: "90712",
    full_city_name: "Lakewood, CA",
    city_name: "Lakewood",
  },
  {
    postal_code: "36066",
    full_city_name: "Prattville, AL",
    city_name: "Prattville",
  },
  {
    postal_code: "20601",
    full_city_name: "Waldorf, MD",
    city_name: "Waldorf",
  },
  {
    postal_code: "95204",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "94513",
    full_city_name: "Brentwood, CA",
    city_name: "Brentwood",
  },
  {
    postal_code: "80019",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "95212",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "98346",
    full_city_name: "Kingston, WA",
    city_name: "Kingston",
  },
  {
    postal_code: "12078",
    full_city_name: "Gloversville, NY",
    city_name: "Gloversville",
  },
  {
    postal_code: "13078",
    full_city_name: "Jamesville, NY",
    city_name: "Jamesville",
  },
  {
    postal_code: "84094",
    full_city_name: "Sandy, UT",
    city_name: "Sandy",
  },
  {
    postal_code: "50501",
    full_city_name: "Fort Dodge, IA",
    city_name: "Fort Dodge",
  },
  {
    postal_code: "60714",
    full_city_name: "Niles, IL",
    city_name: "Niles",
  },
  {
    postal_code: "45242",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "08204",
    full_city_name: "Cape May, NJ",
    city_name: "Cape May",
  },
  {
    postal_code: "50315",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "65803",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "30504",
    full_city_name: "Gainesville, GA",
    city_name: "Gainesville",
  },
  {
    postal_code: "33026",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "78542",
    full_city_name: "Edinburg, TX",
    city_name: "Edinburg",
  },
  {
    postal_code: "76082",
    full_city_name: "Springtown, TX",
    city_name: "Springtown",
  },
  {
    postal_code: "27302",
    full_city_name: "Mebane, NC",
    city_name: "Mebane",
  },
  {
    postal_code: "11003",
    full_city_name: "Elmont, NY",
    city_name: "Elmont",
  },
  {
    postal_code: "48310",
    full_city_name: "Sterling Heights, MI",
    city_name: "Sterling Heights",
  },
  {
    postal_code: "87505",
    full_city_name: "Santa Fe, NM",
    city_name: "Santa Fe",
  },
  {
    postal_code: "75401",
    full_city_name: "Greenville, TX",
    city_name: "Greenville",
  },
  {
    postal_code: "92118",
    full_city_name: "Coronado, CA",
    city_name: "Coronado",
  },
  {
    postal_code: "65616",
    full_city_name: "Branson, MO",
    city_name: "Branson",
  },
  {
    postal_code: "46214",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "29412",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "33428",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "85396",
    full_city_name: "Buckeye, AZ",
    city_name: "Buckeye",
  },
  {
    postal_code: "08008",
    full_city_name: "Beach Haven, NJ",
    city_name: "Beach Haven",
  },
  {
    postal_code: "48911",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "34205",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "79701",
    full_city_name: "Midland, TX",
    city_name: "Midland",
  },
  {
    postal_code: "28462",
    full_city_name: "Supply, NC",
    city_name: "Supply",
  },
  {
    postal_code: "66085",
    full_city_name: "Stilwell, KS",
    city_name: "Stilwell",
  },
  {
    postal_code: "32254",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "84414",
    full_city_name: "Ogden, UT",
    city_name: "Ogden",
  },
  {
    postal_code: "84655",
    full_city_name: "Santaquin, UT",
    city_name: "Santaquin",
  },
  {
    postal_code: "14304",
    full_city_name: "Niagara Falls, NY",
    city_name: "Niagara Falls",
  },
  {
    postal_code: "34228",
    full_city_name: "Longboat Key, FL",
    city_name: "Longboat Key",
  },
  {
    postal_code: "84660",
    full_city_name: "Spanish Fork, UT",
    city_name: "Spanish Fork",
  },
  {
    postal_code: "27265",
    full_city_name: "High Point, NC",
    city_name: "High Point",
  },
  {
    postal_code: "29072",
    full_city_name: "Lexington, SC",
    city_name: "Lexington",
  },
  {
    postal_code: "70570",
    full_city_name: "Opelousas, LA",
    city_name: "Opelousas",
  },
  {
    postal_code: "55317",
    full_city_name: "Chanhassen, MN",
    city_name: "Chanhassen",
  },
  {
    postal_code: "92201",
    full_city_name: "Indio, CA",
    city_name: "Indio",
  },
  {
    postal_code: "33184",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "33127",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "68701",
    full_city_name: "Norfolk, NE",
    city_name: "Norfolk",
  },
  {
    postal_code: "32211",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "19064",
    full_city_name: "Springfield, PA",
    city_name: "Springfield",
  },
  {
    postal_code: "92887",
    full_city_name: "Yorba Linda, CA",
    city_name: "Yorba Linda",
  },
  {
    postal_code: "19027",
    full_city_name: "Elkins Park, PA",
    city_name: "Elkins Park",
  },
  {
    postal_code: "98250",
    full_city_name: "Friday Harbor, WA",
    city_name: "Friday Harbor",
  },
  {
    postal_code: "28390",
    full_city_name: "Spring Lake, NC",
    city_name: "Spring Lake",
  },
  {
    postal_code: "31210",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "37416",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "02908",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "61111",
    full_city_name: "Loves Park, IL",
    city_name: "Loves Park",
  },
  {
    postal_code: "27958",
    full_city_name: "Moyock, NC",
    city_name: "Moyock",
  },
  {
    postal_code: "08844",
    full_city_name: "Hillsborough, NJ",
    city_name: "Hillsborough",
  },
  {
    postal_code: "37323",
    full_city_name: "Cleveland, TN",
    city_name: "Cleveland",
  },
  {
    postal_code: "08406",
    full_city_name: "Ventnor City, NJ",
    city_name: "Ventnor City",
  },
  {
    postal_code: "33904",
    full_city_name: "Cape Coral, FL",
    city_name: "Cape Coral",
  },
  {
    postal_code: "92882",
    full_city_name: "Corona, CA",
    city_name: "Corona",
  },
  {
    postal_code: "11558",
    full_city_name: "Island Park, NY",
    city_name: "Island Park",
  },
  {
    postal_code: "17067",
    full_city_name: "Myerstown, PA",
    city_name: "Myerstown",
  },
  {
    postal_code: "21244",
    full_city_name: "Windsor Mill, MD",
    city_name: "Windsor Mill",
  },
  {
    postal_code: "30513",
    full_city_name: "Blue Ridge, GA",
    city_name: "Blue Ridge",
  },
  {
    postal_code: "08203",
    full_city_name: "Brigantine, NJ",
    city_name: "Brigantine",
  },
  {
    postal_code: "62220",
    full_city_name: "Belleville, IL",
    city_name: "Belleville",
  },
  {
    postal_code: "02601",
    full_city_name: "Hyannis, MA",
    city_name: "Hyannis",
  },
  {
    postal_code: "31909",
    full_city_name: "Columbus, GA",
    city_name: "Columbus",
  },
  {
    postal_code: "39073",
    full_city_name: "Florence, MS",
    city_name: "Florence",
  },
  {
    postal_code: "31005",
    full_city_name: "Bonaire, GA",
    city_name: "Bonaire",
  },
  {
    postal_code: "84020",
    full_city_name: "Draper, UT",
    city_name: "Draper",
  },
  {
    postal_code: "44647",
    full_city_name: "Massillon, OH",
    city_name: "Massillon",
  },
  {
    postal_code: "76023",
    full_city_name: "Boyd, TX",
    city_name: "Boyd",
  },
  {
    postal_code: "28391",
    full_city_name: "Stedman, NC",
    city_name: "Stedman",
  },
  {
    postal_code: "94595",
    full_city_name: "Walnut Creek, CA",
    city_name: "Walnut Creek",
  },
  {
    postal_code: "89434",
    full_city_name: "Sparks, NV",
    city_name: "Sparks",
  },
  {
    postal_code: "36526",
    full_city_name: "Daphne, AL",
    city_name: "Daphne",
  },
  {
    postal_code: "43028",
    full_city_name: "Howard, OH",
    city_name: "Howard",
  },
  {
    postal_code: "06903",
    full_city_name: "Stamford, CT",
    city_name: "Stamford",
  },
  {
    postal_code: "77042",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "85378",
    full_city_name: "Surprise, AZ",
    city_name: "Surprise",
  },
  {
    postal_code: "75054",
    full_city_name: "Grand Prairie, TX",
    city_name: "Grand Prairie",
  },
  {
    postal_code: "73110",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "64058",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "45042",
    full_city_name: "Middletown, OH",
    city_name: "Middletown",
  },
  {
    postal_code: "14043",
    full_city_name: "Depew, NY",
    city_name: "Depew",
  },
  {
    postal_code: "98360",
    full_city_name: "Orting, WA",
    city_name: "Orting",
  },
  {
    postal_code: "85646",
    full_city_name: "Tubac, AZ",
    city_name: "Tubac",
  },
  {
    postal_code: "62948",
    full_city_name: "Herrin, IL",
    city_name: "Herrin",
  },
  {
    postal_code: "33542",
    full_city_name: "Zephyrhills, FL",
    city_name: "Zephyrhills",
  },
  {
    postal_code: "32566",
    full_city_name: "Navarre, FL",
    city_name: "Navarre",
  },
  {
    postal_code: "30045",
    full_city_name: "Lawrenceville, GA",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "06484",
    full_city_name: "Shelton, CT",
    city_name: "Shelton",
  },
  {
    postal_code: "18969",
    full_city_name: "Telford, PA",
    city_name: "Telford",
  },
  {
    postal_code: "46902",
    full_city_name: "Kokomo, IN",
    city_name: "Kokomo",
  },
  {
    postal_code: "48708",
    full_city_name: "Bay City, MI",
    city_name: "Bay City",
  },
  {
    postal_code: "83687",
    full_city_name: "Nampa, ID",
    city_name: "Nampa",
  },
  {
    postal_code: "78521",
    full_city_name: "Brownsville, TX",
    city_name: "Brownsville",
  },
  {
    postal_code: "92335",
    full_city_name: "Fontana, CA",
    city_name: "Fontana",
  },
  {
    postal_code: "60617",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "22901",
    full_city_name: "Charlottesville, VA",
    city_name: "Charlottesville",
  },
  {
    postal_code: "39759",
    full_city_name: "Starkville, MS",
    city_name: "Starkville",
  },
  {
    postal_code: "43206",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "85295",
    full_city_name: "Gilbert, AZ",
    city_name: "Gilbert",
  },
  {
    postal_code: "18466",
    full_city_name: "Tobyhanna, PA",
    city_name: "Tobyhanna",
  },
  {
    postal_code: "32209",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "30528",
    full_city_name: "Cleveland, GA",
    city_name: "Cleveland",
  },
  {
    postal_code: "19460",
    full_city_name: "Phoenixville, PA",
    city_name: "Phoenixville",
  },
  {
    postal_code: "92707",
    full_city_name: "Santa Ana, CA",
    city_name: "Santa Ana",
  },
  {
    postal_code: "70592",
    full_city_name: "Youngsville, LA",
    city_name: "Youngsville",
  },
  {
    postal_code: "40291",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "31558",
    full_city_name: "Saint Marys, GA",
    city_name: "Saint Marys",
  },
  {
    postal_code: "19002",
    full_city_name: "Ambler, PA",
    city_name: "Ambler",
  },
  {
    postal_code: "23860",
    full_city_name: "Hopewell, VA",
    city_name: "Hopewell",
  },
  {
    postal_code: "21208",
    full_city_name: "Pikesville, MD",
    city_name: "Pikesville",
  },
  {
    postal_code: "48906",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "77433",
    full_city_name: "Cypress, TX",
    city_name: "Cypress",
  },
  {
    postal_code: "19139",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "91606",
    full_city_name: "North Hollywood, CA",
    city_name: "North Hollywood",
  },
  {
    postal_code: "30125",
    full_city_name: "Cedartown, GA",
    city_name: "Cedartown",
  },
  {
    postal_code: "44107",
    full_city_name: "Lakewood, OH",
    city_name: "Lakewood",
  },
  {
    postal_code: "37209",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "88220",
    full_city_name: "Carlsbad, NM",
    city_name: "Carlsbad",
  },
  {
    postal_code: "29306",
    full_city_name: "Spartanburg, SC",
    city_name: "Spartanburg",
  },
  {
    postal_code: "11377",
    full_city_name: "Woodside, NY",
    city_name: "Woodside",
  },
  {
    postal_code: "90291",
    full_city_name: "Venice, CA",
    city_name: "Venice",
  },
  {
    postal_code: "07928",
    full_city_name: "Chatham, NJ",
    city_name: "Chatham",
  },
  {
    postal_code: "99004",
    full_city_name: "Cheney, WA",
    city_name: "Cheney",
  },
  {
    postal_code: "33055",
    full_city_name: "Opa Locka, FL",
    city_name: "Opa Locka",
  },
  {
    postal_code: "13760",
    full_city_name: "Endicott, NY",
    city_name: "Endicott",
  },
  {
    postal_code: "76140",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "77868",
    full_city_name: "Navasota, TX",
    city_name: "Navasota",
  },
  {
    postal_code: "39507",
    full_city_name: "Gulfport, MS",
    city_name: "Gulfport",
  },
  {
    postal_code: "33908",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "06825",
    full_city_name: "Fairfield, CT",
    city_name: "Fairfield",
  },
  {
    postal_code: "89506",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "66215",
    full_city_name: "Lenexa, KS",
    city_name: "Lenexa",
  },
  {
    postal_code: "97304",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "15701",
    full_city_name: "Indiana, PA",
    city_name: "Indiana",
  },
  {
    postal_code: "76249",
    full_city_name: "Krum, TX",
    city_name: "Krum",
  },
  {
    postal_code: "33037",
    full_city_name: "Key Largo, FL",
    city_name: "Key Largo",
  },
  {
    postal_code: "60653",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "40004",
    full_city_name: "Bardstown, KY",
    city_name: "Bardstown",
  },
  {
    postal_code: "56560",
    full_city_name: "Moorhead, MN",
    city_name: "Moorhead",
  },
  {
    postal_code: "60189",
    full_city_name: "Wheaton, IL",
    city_name: "Wheaton",
  },
  {
    postal_code: "97229",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "06473",
    full_city_name: "North Haven, CT",
    city_name: "North Haven",
  },
  {
    postal_code: "78222",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "78133",
    full_city_name: "Canyon Lake, TX",
    city_name: "Canyon Lake",
  },
  {
    postal_code: "77586",
    full_city_name: "Seabrook, TX",
    city_name: "Seabrook",
  },
  {
    postal_code: "22015",
    full_city_name: "Burke, VA",
    city_name: "Burke",
  },
  {
    postal_code: "12019",
    full_city_name: "Ballston Lake, NY",
    city_name: "Ballston Lake",
  },
  {
    postal_code: "89523",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "28590",
    full_city_name: "Winterville, NC",
    city_name: "Winterville",
  },
  {
    postal_code: "30083",
    full_city_name: "Stone Mountain, GA",
    city_name: "Stone Mountain",
  },
  {
    postal_code: "61832",
    full_city_name: "Danville, IL",
    city_name: "Danville",
  },
  {
    postal_code: "73132",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "52245",
    full_city_name: "Iowa City, IA",
    city_name: "Iowa City",
  },
  {
    postal_code: "67002",
    full_city_name: "Andover, KS",
    city_name: "Andover",
  },
  {
    postal_code: "98408",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "10025",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "98034",
    full_city_name: "Kirkland, WA",
    city_name: "Kirkland",
  },
  {
    postal_code: "28904",
    full_city_name: "Hayesville, NC",
    city_name: "Hayesville",
  },
  {
    postal_code: "34637",
    full_city_name: "Land O Lakes, FL",
    city_name: "Land O Lakes",
  },
  {
    postal_code: "91342",
    full_city_name: "Sylmar, CA",
    city_name: "Sylmar",
  },
  {
    postal_code: "59405",
    full_city_name: "Great Falls, MT",
    city_name: "Great Falls",
  },
  {
    postal_code: "80002",
    full_city_name: "Arvada, CO",
    city_name: "Arvada",
  },
  {
    postal_code: "45056",
    full_city_name: "Oxford, OH",
    city_name: "Oxford",
  },
  {
    postal_code: "07039",
    full_city_name: "Livingston, NJ",
    city_name: "Livingston",
  },
  {
    postal_code: "83661",
    full_city_name: "Payette, ID",
    city_name: "Payette",
  },
  {
    postal_code: "80111",
    full_city_name: "Englewood, CO",
    city_name: "Englewood",
  },
  {
    postal_code: "95901",
    full_city_name: "Marysville, CA",
    city_name: "Marysville",
  },
  {
    postal_code: "76657",
    full_city_name: "Mc Gregor, TX",
    city_name: "Mc Gregor",
  },
  {
    postal_code: "21403",
    full_city_name: "Annapolis, MD",
    city_name: "Annapolis",
  },
  {
    postal_code: "43016",
    full_city_name: "Dublin, OH",
    city_name: "Dublin",
  },
  {
    postal_code: "13662",
    full_city_name: "Massena, NY",
    city_name: "Massena",
  },
  {
    postal_code: "95336",
    full_city_name: "Manteca, CA",
    city_name: "Manteca",
  },
  {
    postal_code: "80233",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "65708",
    full_city_name: "Monett, MO",
    city_name: "Monett",
  },
  {
    postal_code: "33332",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "34614",
    full_city_name: "Brooksville, FL",
    city_name: "Brooksville",
  },
  {
    postal_code: "44685",
    full_city_name: "Uniontown, OH",
    city_name: "Uniontown",
  },
  {
    postal_code: "77571",
    full_city_name: "La Porte, TX",
    city_name: "La Porte",
  },
  {
    postal_code: "94114",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "27215",
    full_city_name: "Burlington, NC",
    city_name: "Burlington",
  },
  {
    postal_code: "24012",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "55436",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "39211",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "84737",
    full_city_name: "Hurricane, UT",
    city_name: "Hurricane",
  },
  {
    postal_code: "10308",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "32080",
    full_city_name: "Saint Augustine, FL",
    city_name: "Saint Augustine",
  },
  {
    postal_code: "73170",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "92557",
    full_city_name: "Moreno Valley, CA",
    city_name: "Moreno Valley",
  },
  {
    postal_code: "94105",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "78724",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "21205",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "23831",
    full_city_name: "Chester, VA",
    city_name: "Chester",
  },
  {
    postal_code: "95630",
    full_city_name: "Folsom, CA",
    city_name: "Folsom",
  },
  {
    postal_code: "92106",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "60093",
    full_city_name: "Winnetka, IL",
    city_name: "Winnetka",
  },
  {
    postal_code: "78634",
    full_city_name: "Hutto, TX",
    city_name: "Hutto",
  },
  {
    postal_code: "92618",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "29676",
    full_city_name: "Salem, SC",
    city_name: "Salem",
  },
  {
    postal_code: "29907",
    full_city_name: "Beaufort, SC",
    city_name: "Beaufort",
  },
  {
    postal_code: "30305",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "85257",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "33712",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "77833",
    full_city_name: "Brenham, TX",
    city_name: "Brenham",
  },
  {
    postal_code: "81435",
    full_city_name: "Telluride, CO",
    city_name: "Telluride",
  },
  {
    postal_code: "85028",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "17046",
    full_city_name: "Lebanon, PA",
    city_name: "Lebanon",
  },
  {
    postal_code: "98011",
    full_city_name: "Bothell, WA",
    city_name: "Bothell",
  },
  {
    postal_code: "95966",
    full_city_name: "Oroville, CA",
    city_name: "Oroville",
  },
  {
    postal_code: "37601",
    full_city_name: "Johnson City, TN",
    city_name: "Johnson City",
  },
  {
    postal_code: "28570",
    full_city_name: "Newport, NC",
    city_name: "Newport",
  },
  {
    postal_code: "91741",
    full_city_name: "Glendora, CA",
    city_name: "Glendora",
  },
  {
    postal_code: "75075",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "97365",
    full_city_name: "Newport, OR",
    city_name: "Newport",
  },
  {
    postal_code: "32817",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "48430",
    full_city_name: "Fenton, MI",
    city_name: "Fenton",
  },
  {
    postal_code: "02653",
    full_city_name: "Orleans, MA",
    city_name: "Orleans",
  },
  {
    postal_code: "87109",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "75135",
    full_city_name: "Caddo Mills, TX",
    city_name: "Caddo Mills",
  },
  {
    postal_code: "75069",
    full_city_name: "Mckinney, TX",
    city_name: "Mckinney",
  },
  {
    postal_code: "39540",
    full_city_name: "Diberville, MS",
    city_name: "Diberville",
  },
  {
    postal_code: "71901",
    full_city_name: "Hot Springs National Park, AR",
    city_name: "Hot Springs National Park",
  },
  {
    postal_code: "98391",
    full_city_name: "Bonney Lake, WA",
    city_name: "Bonney Lake",
  },
  {
    postal_code: "39206",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "95826",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "16148",
    full_city_name: "Hermitage, PA",
    city_name: "Hermitage",
  },
  {
    postal_code: "11040",
    full_city_name: "New Hyde Park, NY",
    city_name: "New Hyde Park",
  },
  {
    postal_code: "98115",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "32839",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "70706",
    full_city_name: "Denham Springs, LA",
    city_name: "Denham Springs",
  },
  {
    postal_code: "92014",
    full_city_name: "Del Mar, CA",
    city_name: "Del Mar",
  },
  {
    postal_code: "48854",
    full_city_name: "Mason, MI",
    city_name: "Mason",
  },
  {
    postal_code: "79382",
    full_city_name: "Wolfforth, TX",
    city_name: "Wolfforth",
  },
  {
    postal_code: "27597",
    full_city_name: "Zebulon, NC",
    city_name: "Zebulon",
  },
  {
    postal_code: "95688",
    full_city_name: "Vacaville, CA",
    city_name: "Vacaville",
  },
  {
    postal_code: "50317",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "43606",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "60152",
    full_city_name: "Marengo, IL",
    city_name: "Marengo",
  },
  {
    postal_code: "64082",
    full_city_name: "Lees Summit, MO",
    city_name: "Lees Summit",
  },
  {
    postal_code: "98292",
    full_city_name: "Stanwood, WA",
    city_name: "Stanwood",
  },
  {
    postal_code: "97045",
    full_city_name: "Oregon City, OR",
    city_name: "Oregon City",
  },
  {
    postal_code: "45414",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "44721",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "21901",
    full_city_name: "North East, MD",
    city_name: "North East",
  },
  {
    postal_code: "92407",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "98682",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "97471",
    full_city_name: "Roseburg, OR",
    city_name: "Roseburg",
  },
  {
    postal_code: "38053",
    full_city_name: "Millington, TN",
    city_name: "Millington",
  },
  {
    postal_code: "46311",
    full_city_name: "Dyer, IN",
    city_name: "Dyer",
  },
  {
    postal_code: "98019",
    full_city_name: "Duvall, WA",
    city_name: "Duvall",
  },
  {
    postal_code: "55446",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "21702",
    full_city_name: "Frederick, MD",
    city_name: "Frederick",
  },
  {
    postal_code: "63640",
    full_city_name: "Farmington, MO",
    city_name: "Farmington",
  },
  {
    postal_code: "08648",
    full_city_name: "Lawrence Township, NJ",
    city_name: "Lawrence Township",
  },
  {
    postal_code: "46064",
    full_city_name: "Pendleton, IN",
    city_name: "Pendleton",
  },
  {
    postal_code: "35007",
    full_city_name: "Alabaster, AL",
    city_name: "Alabaster",
  },
  {
    postal_code: "70119",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "34480",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "15211",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "33540",
    full_city_name: "Zephyrhills, FL",
    city_name: "Zephyrhills",
  },
  {
    postal_code: "85302",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "31021",
    full_city_name: "Dublin, GA",
    city_name: "Dublin",
  },
  {
    postal_code: "66801",
    full_city_name: "Emporia, KS",
    city_name: "Emporia",
  },
  {
    postal_code: "30253",
    full_city_name: "Mcdonough, GA",
    city_name: "Mcdonough",
  },
  {
    postal_code: "06850",
    full_city_name: "Norwalk, CT",
    city_name: "Norwalk",
  },
  {
    postal_code: "85203",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "34481",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "54487",
    full_city_name: "Tomahawk, WI",
    city_name: "Tomahawk",
  },
  {
    postal_code: "91977",
    full_city_name: "Spring Valley, CA",
    city_name: "Spring Valley",
  },
  {
    postal_code: "98270",
    full_city_name: "Marysville, WA",
    city_name: "Marysville",
  },
  {
    postal_code: "98002",
    full_city_name: "Auburn, WA",
    city_name: "Auburn",
  },
  {
    postal_code: "91945",
    full_city_name: "Lemon Grove, CA",
    city_name: "Lemon Grove",
  },
  {
    postal_code: "20723",
    full_city_name: "Laurel, MD",
    city_name: "Laurel",
  },
  {
    postal_code: "22003",
    full_city_name: "Annandale, VA",
    city_name: "Annandale",
  },
  {
    postal_code: "31326",
    full_city_name: "Rincon, GA",
    city_name: "Rincon",
  },
  {
    postal_code: "60517",
    full_city_name: "Woodridge, IL",
    city_name: "Woodridge",
  },
  {
    postal_code: "98275",
    full_city_name: "Mukilteo, WA",
    city_name: "Mukilteo",
  },
  {
    postal_code: "20032",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "31410",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "79601",
    full_city_name: "Abilene, TX",
    city_name: "Abilene",
  },
  {
    postal_code: "32926",
    full_city_name: "Cocoa, FL",
    city_name: "Cocoa",
  },
  {
    postal_code: "33976",
    full_city_name: "Lehigh Acres, FL",
    city_name: "Lehigh Acres",
  },
  {
    postal_code: "32224",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "48917",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "44306",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "42303",
    full_city_name: "Owensboro, KY",
    city_name: "Owensboro",
  },
  {
    postal_code: "92128",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "32514",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "25405",
    full_city_name: "Martinsburg, WV",
    city_name: "Martinsburg",
  },
  {
    postal_code: "83705",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "49507",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "33785",
    full_city_name: "Indian Rocks Beach, FL",
    city_name: "Indian Rocks Beach",
  },
  {
    postal_code: "10002",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "11793",
    full_city_name: "Wantagh, NY",
    city_name: "Wantagh",
  },
  {
    postal_code: "81637",
    full_city_name: "Gypsum, CO",
    city_name: "Gypsum",
  },
  {
    postal_code: "98642",
    full_city_name: "Ridgefield, WA",
    city_name: "Ridgefield",
  },
  {
    postal_code: "30016",
    full_city_name: "Covington, GA",
    city_name: "Covington",
  },
  {
    postal_code: "60612",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "06804",
    full_city_name: "Brookfield, CT",
    city_name: "Brookfield",
  },
  {
    postal_code: "98198",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "37932",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "44320",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "02911",
    full_city_name: "North Providence, RI",
    city_name: "North Providence",
  },
  {
    postal_code: "37040",
    full_city_name: "Clarksville, TN",
    city_name: "Clarksville",
  },
  {
    postal_code: "72209",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "30534",
    full_city_name: "Dawsonville, GA",
    city_name: "Dawsonville",
  },
  {
    postal_code: "34787",
    full_city_name: "Winter Garden, FL",
    city_name: "Winter Garden",
  },
  {
    postal_code: "80640",
    full_city_name: "Henderson, CO",
    city_name: "Henderson",
  },
  {
    postal_code: "92610",
    full_city_name: "Foothill Ranch, CA",
    city_name: "Foothill Ranch",
  },
  {
    postal_code: "08902",
    full_city_name: "North Brunswick, NJ",
    city_name: "North Brunswick",
  },
  {
    postal_code: "27217",
    full_city_name: "Burlington, NC",
    city_name: "Burlington",
  },
  {
    postal_code: "60554",
    full_city_name: "Sugar Grove, IL",
    city_name: "Sugar Grove",
  },
  {
    postal_code: "66206",
    full_city_name: "Leawood, KS",
    city_name: "Leawood",
  },
  {
    postal_code: "21613",
    full_city_name: "Cambridge, MD",
    city_name: "Cambridge",
  },
  {
    postal_code: "32119",
    full_city_name: "Daytona Beach, FL",
    city_name: "Daytona Beach",
  },
  {
    postal_code: "11362",
    full_city_name: "Little Neck, NY",
    city_name: "Little Neck",
  },
  {
    postal_code: "60097",
    full_city_name: "Wonder Lake, IL",
    city_name: "Wonder Lake",
  },
  {
    postal_code: "60466",
    full_city_name: "Park Forest, IL",
    city_name: "Park Forest",
  },
  {
    postal_code: "62703",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "12801",
    full_city_name: "Glens Falls, NY",
    city_name: "Glens Falls",
  },
  {
    postal_code: "13039",
    full_city_name: "Cicero, NY",
    city_name: "Cicero",
  },
  {
    postal_code: "63011",
    full_city_name: "Ballwin, MO",
    city_name: "Ballwin",
  },
  {
    postal_code: "75104",
    full_city_name: "Cedar Hill, TX",
    city_name: "Cedar Hill",
  },
  {
    postal_code: "11217",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "33773",
    full_city_name: "Largo, FL",
    city_name: "Largo",
  },
  {
    postal_code: "77320",
    full_city_name: "Huntsville, TX",
    city_name: "Huntsville",
  },
  {
    postal_code: "33881",
    full_city_name: "Winter Haven, FL",
    city_name: "Winter Haven",
  },
  {
    postal_code: "97080",
    full_city_name: "Gresham, OR",
    city_name: "Gresham",
  },
  {
    postal_code: "29630",
    full_city_name: "Central, SC",
    city_name: "Central",
  },
  {
    postal_code: "06002",
    full_city_name: "Bloomfield, CT",
    city_name: "Bloomfield",
  },
  {
    postal_code: "06482",
    full_city_name: "Sandy Hook, CT",
    city_name: "Sandy Hook",
  },
  {
    postal_code: "06611",
    full_city_name: "Trumbull, CT",
    city_name: "Trumbull",
  },
  {
    postal_code: "14075",
    full_city_name: "Hamburg, NY",
    city_name: "Hamburg",
  },
  {
    postal_code: "43081",
    full_city_name: "Westerville, OH",
    city_name: "Westerville",
  },
  {
    postal_code: "18436",
    full_city_name: "Lake Ariel, PA",
    city_name: "Lake Ariel",
  },
  {
    postal_code: "91711",
    full_city_name: "Claremont, CA",
    city_name: "Claremont",
  },
  {
    postal_code: "93033",
    full_city_name: "Oxnard, CA",
    city_name: "Oxnard",
  },
  {
    postal_code: "08234",
    full_city_name: "Egg Harbor Township, NJ",
    city_name: "Egg Harbor Township",
  },
  {
    postal_code: "97338",
    full_city_name: "Dallas, OR",
    city_name: "Dallas",
  },
  {
    postal_code: "67212",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "30342",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "31047",
    full_city_name: "Kathleen, GA",
    city_name: "Kathleen",
  },
  {
    postal_code: "42071",
    full_city_name: "Murray, KY",
    city_name: "Murray",
  },
  {
    postal_code: "30577",
    full_city_name: "Toccoa, GA",
    city_name: "Toccoa",
  },
  {
    postal_code: "19703",
    full_city_name: "Claymont, DE",
    city_name: "Claymont",
  },
  {
    postal_code: "31069",
    full_city_name: "Perry, GA",
    city_name: "Perry",
  },
  {
    postal_code: "93619",
    full_city_name: "Clovis, CA",
    city_name: "Clovis",
  },
  {
    postal_code: "92507",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "20724",
    full_city_name: "Laurel, MD",
    city_name: "Laurel",
  },
  {
    postal_code: "76009",
    full_city_name: "Alvarado, TX",
    city_name: "Alvarado",
  },
  {
    postal_code: "46037",
    full_city_name: "Fishers, IN",
    city_name: "Fishers",
  },
  {
    postal_code: "11222",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "19945",
    full_city_name: "Frankford, DE",
    city_name: "Frankford",
  },
  {
    postal_code: "55442",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "29576",
    full_city_name: "Murrells Inlet, SC",
    city_name: "Murrells Inlet",
  },
  {
    postal_code: "28012",
    full_city_name: "Belmont, NC",
    city_name: "Belmont",
  },
  {
    postal_code: "49601",
    full_city_name: "Cadillac, MI",
    city_name: "Cadillac",
  },
  {
    postal_code: "33433",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "96761",
    full_city_name: "Lahaina, HI",
    city_name: "Lahaina",
  },
  {
    postal_code: "80238",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "33327",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "78744",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "31329",
    full_city_name: "Springfield, GA",
    city_name: "Springfield",
  },
  {
    postal_code: "33408",
    full_city_name: "North Palm Beach, FL",
    city_name: "North Palm Beach",
  },
  {
    postal_code: "31029",
    full_city_name: "Forsyth, GA",
    city_name: "Forsyth",
  },
  {
    postal_code: "35022",
    full_city_name: "Bessemer, AL",
    city_name: "Bessemer",
  },
  {
    postal_code: "94603",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "60660",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "79407",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "02891",
    full_city_name: "Westerly, RI",
    city_name: "Westerly",
  },
  {
    postal_code: "62234",
    full_city_name: "Collinsville, IL",
    city_name: "Collinsville",
  },
  {
    postal_code: "92102",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "82435",
    full_city_name: "Powell, WY",
    city_name: "Powell",
  },
  {
    postal_code: "85215",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "13031",
    full_city_name: "Camillus, NY",
    city_name: "Camillus",
  },
  {
    postal_code: "30052",
    full_city_name: "Loganville, GA",
    city_name: "Loganville",
  },
  {
    postal_code: "54311",
    full_city_name: "Green Bay, WI",
    city_name: "Green Bay",
  },
  {
    postal_code: "75074",
    full_city_name: "Plano, TX",
    city_name: "Plano",
  },
  {
    postal_code: "34145",
    full_city_name: "Marco Island, FL",
    city_name: "Marco Island",
  },
  {
    postal_code: "28303",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "73034",
    full_city_name: "Edmond, OK",
    city_name: "Edmond",
  },
  {
    postal_code: "33703",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "38556",
    full_city_name: "Jamestown, TN",
    city_name: "Jamestown",
  },
  {
    postal_code: "93001",
    full_city_name: "Ventura, CA",
    city_name: "Ventura",
  },
  {
    postal_code: "30022",
    full_city_name: "Alpharetta, GA",
    city_name: "Alpharetta",
  },
  {
    postal_code: "13045",
    full_city_name: "Cortland, NY",
    city_name: "Cortland",
  },
  {
    postal_code: "49120",
    full_city_name: "Niles, MI",
    city_name: "Niles",
  },
  {
    postal_code: "32092",
    full_city_name: "Saint Augustine, FL",
    city_name: "Saint Augustine",
  },
  {
    postal_code: "36695",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "23112",
    full_city_name: "Midlothian, VA",
    city_name: "Midlothian",
  },
  {
    postal_code: "28445",
    full_city_name: "Holly Ridge, NC",
    city_name: "Holly Ridge",
  },
  {
    postal_code: "21784",
    full_city_name: "Sykesville, MD",
    city_name: "Sykesville",
  },
  {
    postal_code: "03087",
    full_city_name: "Windham, NH",
    city_name: "Windham",
  },
  {
    postal_code: "91436",
    full_city_name: "Encino, CA",
    city_name: "Encino",
  },
  {
    postal_code: "24015",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "19711",
    full_city_name: "Newark, DE",
    city_name: "Newark",
  },
  {
    postal_code: "37813",
    full_city_name: "Morristown, TN",
    city_name: "Morristown",
  },
  {
    postal_code: "15143",
    full_city_name: "Sewickley, PA",
    city_name: "Sewickley",
  },
  {
    postal_code: "33068",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "33460",
    full_city_name: "Lake Worth Beach, FL",
    city_name: "Lake Worth Beach",
  },
  {
    postal_code: "12118",
    full_city_name: "Mechanicville, NY",
    city_name: "Mechanicville",
  },
  {
    postal_code: "60140",
    full_city_name: "Hampshire, IL",
    city_name: "Hampshire",
  },
  {
    postal_code: "79423",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "70124",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "97113",
    full_city_name: "Cornelius, OR",
    city_name: "Cornelius",
  },
  {
    postal_code: "15227",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "38127",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "29323",
    full_city_name: "Chesnee, SC",
    city_name: "Chesnee",
  },
  {
    postal_code: "59711",
    full_city_name: "Anaconda, MT",
    city_name: "Anaconda",
  },
  {
    postal_code: "32222",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "76401",
    full_city_name: "Stephenville, TX",
    city_name: "Stephenville",
  },
  {
    postal_code: "75206",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "33612",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "68845",
    full_city_name: "Kearney, NE",
    city_name: "Kearney",
  },
  {
    postal_code: "28677",
    full_city_name: "Statesville, NC",
    city_name: "Statesville",
  },
  {
    postal_code: "63131",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "29621",
    full_city_name: "Anderson, SC",
    city_name: "Anderson",
  },
  {
    postal_code: "76005",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "60565",
    full_city_name: "Naperville, IL",
    city_name: "Naperville",
  },
  {
    postal_code: "30507",
    full_city_name: "Gainesville, GA",
    city_name: "Gainesville",
  },
  {
    postal_code: "22903",
    full_city_name: "Charlottesville, VA",
    city_name: "Charlottesville",
  },
  {
    postal_code: "46203",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "31008",
    full_city_name: "Byron, GA",
    city_name: "Byron",
  },
  {
    postal_code: "11725",
    full_city_name: "Commack, NY",
    city_name: "Commack",
  },
  {
    postal_code: "13090",
    full_city_name: "Liverpool, NY",
    city_name: "Liverpool",
  },
  {
    postal_code: "98027",
    full_city_name: "Issaquah, WA",
    city_name: "Issaquah",
  },
  {
    postal_code: "81657",
    full_city_name: "Vail, CO",
    city_name: "Vail",
  },
  {
    postal_code: "48629",
    full_city_name: "Houghton Lake, MI",
    city_name: "Houghton Lake",
  },
  {
    postal_code: "34232",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "98021",
    full_city_name: "Bothell, WA",
    city_name: "Bothell",
  },
  {
    postal_code: "86429",
    full_city_name: "Bullhead City, AZ",
    city_name: "Bullhead City",
  },
  {
    postal_code: "72801",
    full_city_name: "Russellville, AR",
    city_name: "Russellville",
  },
  {
    postal_code: "35051",
    full_city_name: "Columbiana, AL",
    city_name: "Columbiana",
  },
  {
    postal_code: "45209",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "18328",
    full_city_name: "Dingmans Ferry, PA",
    city_name: "Dingmans Ferry",
  },
  {
    postal_code: "70769",
    full_city_name: "Prairieville, LA",
    city_name: "Prairieville",
  },
  {
    postal_code: "67207",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "92019",
    full_city_name: "El Cajon, CA",
    city_name: "El Cajon",
  },
  {
    postal_code: "02860",
    full_city_name: "Pawtucket, RI",
    city_name: "Pawtucket",
  },
  {
    postal_code: "23117",
    full_city_name: "Mineral, VA",
    city_name: "Mineral",
  },
  {
    postal_code: "28037",
    full_city_name: "Denver, NC",
    city_name: "Denver",
  },
  {
    postal_code: "80125",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "35126",
    full_city_name: "Pinson, AL",
    city_name: "Pinson",
  },
  {
    postal_code: "28217",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "72762",
    full_city_name: "Springdale, AR",
    city_name: "Springdale",
  },
  {
    postal_code: "85207",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "65738",
    full_city_name: "Republic, MO",
    city_name: "Republic",
  },
  {
    postal_code: "38016",
    full_city_name: "Cordova, TN",
    city_name: "Cordova",
  },
  {
    postal_code: "28314",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "33144",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "28034",
    full_city_name: "Dallas, NC",
    city_name: "Dallas",
  },
  {
    postal_code: "19943",
    full_city_name: "Felton, DE",
    city_name: "Felton",
  },
  {
    postal_code: "30559",
    full_city_name: "Mineral Bluff, GA",
    city_name: "Mineral Bluff",
  },
  {
    postal_code: "83642",
    full_city_name: "Meridian, ID",
    city_name: "Meridian",
  },
  {
    postal_code: "60560",
    full_city_name: "Yorkville, IL",
    city_name: "Yorkville",
  },
  {
    postal_code: "60654",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "94533",
    full_city_name: "Fairfield, CA",
    city_name: "Fairfield",
  },
  {
    postal_code: "93614",
    full_city_name: "Coarsegold, CA",
    city_name: "Coarsegold",
  },
  {
    postal_code: "32081",
    full_city_name: "Ponte Vedra, FL",
    city_name: "Ponte Vedra",
  },
  {
    postal_code: "43302",
    full_city_name: "Marion, OH",
    city_name: "Marion",
  },
  {
    postal_code: "91762",
    full_city_name: "Ontario, CA",
    city_name: "Ontario",
  },
  {
    postal_code: "34117",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "11570",
    full_city_name: "Rockville Centre, NY",
    city_name: "Rockville Centre",
  },
  {
    postal_code: "95973",
    full_city_name: "Chico, CA",
    city_name: "Chico",
  },
  {
    postal_code: "20603",
    full_city_name: "Waldorf, MD",
    city_name: "Waldorf",
  },
  {
    postal_code: "43055",
    full_city_name: "Newark, OH",
    city_name: "Newark",
  },
  {
    postal_code: "77089",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "85040",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "93631",
    full_city_name: "Kingsburg, CA",
    city_name: "Kingsburg",
  },
  {
    postal_code: "20165",
    full_city_name: "Sterling, VA",
    city_name: "Sterling",
  },
  {
    postal_code: "13440",
    full_city_name: "Rome, NY",
    city_name: "Rome",
  },
  {
    postal_code: "93063",
    full_city_name: "Simi Valley, CA",
    city_name: "Simi Valley",
  },
  {
    postal_code: "73025",
    full_city_name: "Edmond, OK",
    city_name: "Edmond",
  },
  {
    postal_code: "13790",
    full_city_name: "Johnson City, NY",
    city_name: "Johnson City",
  },
  {
    postal_code: "98404",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "76901",
    full_city_name: "San Angelo, TX",
    city_name: "San Angelo",
  },
  {
    postal_code: "37643",
    full_city_name: "Elizabethton, TN",
    city_name: "Elizabethton",
  },
  {
    postal_code: "30523",
    full_city_name: "Clarkesville, GA",
    city_name: "Clarkesville",
  },
  {
    postal_code: "19007",
    full_city_name: "Bristol, PA",
    city_name: "Bristol",
  },
  {
    postal_code: "60053",
    full_city_name: "Morton Grove, IL",
    city_name: "Morton Grove",
  },
  {
    postal_code: "84010",
    full_city_name: "Bountiful, UT",
    city_name: "Bountiful",
  },
  {
    postal_code: "28778",
    full_city_name: "Swannanoa, NC",
    city_name: "Swannanoa",
  },
  {
    postal_code: "34669",
    full_city_name: "Hudson, FL",
    city_name: "Hudson",
  },
  {
    postal_code: "48504",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "83709",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "91316",
    full_city_name: "Encino, CA",
    city_name: "Encino",
  },
  {
    postal_code: "22153",
    full_city_name: "Springfield, VA",
    city_name: "Springfield",
  },
  {
    postal_code: "35080",
    full_city_name: "Helena, AL",
    city_name: "Helena",
  },
  {
    postal_code: "32063",
    full_city_name: "Macclenny, FL",
    city_name: "Macclenny",
  },
  {
    postal_code: "23310",
    full_city_name: "Cape Charles, VA",
    city_name: "Cape Charles",
  },
  {
    postal_code: "98126",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "33190",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "37379",
    full_city_name: "Soddy Daisy, TN",
    city_name: "Soddy Daisy",
  },
  {
    postal_code: "02861",
    full_city_name: "Pawtucket, RI",
    city_name: "Pawtucket",
  },
  {
    postal_code: "32708",
    full_city_name: "Winter Springs, FL",
    city_name: "Winter Springs",
  },
  {
    postal_code: "33168",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "01247",
    full_city_name: "North Adams, MA",
    city_name: "North Adams",
  },
  {
    postal_code: "85035",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "11221",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "26330",
    full_city_name: "Bridgeport, WV",
    city_name: "Bridgeport",
  },
  {
    postal_code: "37211",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "98001",
    full_city_name: "Auburn, WA",
    city_name: "Auburn",
  },
  {
    postal_code: "80304",
    full_city_name: "Boulder, CO",
    city_name: "Boulder",
  },
  {
    postal_code: "92122",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "87123",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "70123",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "11545",
    full_city_name: "Glen Head, NY",
    city_name: "Glen Head",
  },
  {
    postal_code: "92555",
    full_city_name: "Moreno Valley, CA",
    city_name: "Moreno Valley",
  },
  {
    postal_code: "10010",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "85711",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "10301",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "75156",
    full_city_name: "Mabank, TX",
    city_name: "Mabank",
  },
  {
    postal_code: "58201",
    full_city_name: "Grand Forks, ND",
    city_name: "Grand Forks",
  },
  {
    postal_code: "30096",
    full_city_name: "Duluth, GA",
    city_name: "Duluth",
  },
  {
    postal_code: "76208",
    full_city_name: "Denton, TX",
    city_name: "Denton",
  },
  {
    postal_code: "50613",
    full_city_name: "Cedar Falls, IA",
    city_name: "Cedar Falls",
  },
  {
    postal_code: "20010",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "29693",
    full_city_name: "Westminster, SC",
    city_name: "Westminster",
  },
  {
    postal_code: "45231",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "64116",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "63028",
    full_city_name: "Festus, MO",
    city_name: "Festus",
  },
  {
    postal_code: "96792",
    full_city_name: "Waianae, HI",
    city_name: "Waianae",
  },
  {
    postal_code: "74432",
    full_city_name: "Eufaula, OK",
    city_name: "Eufaula",
  },
  {
    postal_code: "63401",
    full_city_name: "Hannibal, MO",
    city_name: "Hannibal",
  },
  {
    postal_code: "78602",
    full_city_name: "Bastrop, TX",
    city_name: "Bastrop",
  },
  {
    postal_code: "98264",
    full_city_name: "Lynden, WA",
    city_name: "Lynden",
  },
  {
    postal_code: "34288",
    full_city_name: "North Port, FL",
    city_name: "North Port",
  },
  {
    postal_code: "60016",
    full_city_name: "Des Plaines, IL",
    city_name: "Des Plaines",
  },
  {
    postal_code: "44109",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "30064",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "64118",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "29909",
    full_city_name: "Okatie, SC",
    city_name: "Okatie",
  },
  {
    postal_code: "24018",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "33305",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "60124",
    full_city_name: "Elgin, IL",
    city_name: "Elgin",
  },
  {
    postal_code: "48170",
    full_city_name: "Plymouth, MI",
    city_name: "Plymouth",
  },
  {
    postal_code: "93933",
    full_city_name: "Marina, CA",
    city_name: "Marina",
  },
  {
    postal_code: "93657",
    full_city_name: "Sanger, CA",
    city_name: "Sanger",
  },
  {
    postal_code: "90650",
    full_city_name: "Norwalk, CA",
    city_name: "Norwalk",
  },
  {
    postal_code: "31204",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "32808",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "48060",
    full_city_name: "Port Huron, MI",
    city_name: "Port Huron",
  },
  {
    postal_code: "73116",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "27105",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "37421",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "78624",
    full_city_name: "Fredericksburg, TX",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "79707",
    full_city_name: "Midland, TX",
    city_name: "Midland",
  },
  {
    postal_code: "76904",
    full_city_name: "San Angelo, TX",
    city_name: "San Angelo",
  },
  {
    postal_code: "92675",
    full_city_name: "San Juan Capistrano, CA",
    city_name: "San Juan Capistrano",
  },
  {
    postal_code: "50219",
    full_city_name: "Pella, IA",
    city_name: "Pella",
  },
  {
    postal_code: "63128",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "55315",
    full_city_name: "Carver, MN",
    city_name: "Carver",
  },
  {
    postal_code: "87108",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "73072",
    full_city_name: "Norman, OK",
    city_name: "Norman",
  },
  {
    postal_code: "67219",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "89014",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "34953",
    full_city_name: "Port Saint Lucie, FL",
    city_name: "Port Saint Lucie",
  },
  {
    postal_code: "95648",
    full_city_name: "Lincoln, CA",
    city_name: "Lincoln",
  },
  {
    postal_code: "56303",
    full_city_name: "Saint Cloud, MN",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "98203",
    full_city_name: "Everett, WA",
    city_name: "Everett",
  },
  {
    postal_code: "91910",
    full_city_name: "Chula Vista, CA",
    city_name: "Chula Vista",
  },
  {
    postal_code: "80124",
    full_city_name: "Lone Tree, CO",
    city_name: "Lone Tree",
  },
  {
    postal_code: "76710",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "46062",
    full_city_name: "Noblesville, IN",
    city_name: "Noblesville",
  },
  {
    postal_code: "05356",
    full_city_name: "West Dover, VT",
    city_name: "West Dover",
  },
  {
    postal_code: "30071",
    full_city_name: "Norcross, GA",
    city_name: "Norcross",
  },
  {
    postal_code: "85388",
    full_city_name: "Surprise, AZ",
    city_name: "Surprise",
  },
  {
    postal_code: "61073",
    full_city_name: "Roscoe, IL",
    city_name: "Roscoe",
  },
  {
    postal_code: "98074",
    full_city_name: "Sammamish, WA",
    city_name: "Sammamish",
  },
  {
    postal_code: "78665",
    full_city_name: "Round Rock, TX",
    city_name: "Round Rock",
  },
  {
    postal_code: "33411",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "23434",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "80007",
    full_city_name: "Arvada, CO",
    city_name: "Arvada",
  },
  {
    postal_code: "30344",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "40502",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "80918",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "87501",
    full_city_name: "Santa Fe, NM",
    city_name: "Santa Fe",
  },
  {
    postal_code: "48162",
    full_city_name: "Monroe, MI",
    city_name: "Monroe",
  },
  {
    postal_code: "35244",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "32955",
    full_city_name: "Rockledge, FL",
    city_name: "Rockledge",
  },
  {
    postal_code: "70403",
    full_city_name: "Hammond, LA",
    city_name: "Hammond",
  },
  {
    postal_code: "22406",
    full_city_name: "Fredericksburg, VA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "11572",
    full_city_name: "Oceanside, NY",
    city_name: "Oceanside",
  },
  {
    postal_code: "10014",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "68135",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "32703",
    full_city_name: "Apopka, FL",
    city_name: "Apopka",
  },
  {
    postal_code: "44128",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "07712",
    full_city_name: "Asbury Park, NJ",
    city_name: "Asbury Park",
  },
  {
    postal_code: "46628",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "07760",
    full_city_name: "Rumson, NJ",
    city_name: "Rumson",
  },
  {
    postal_code: "97203",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "89436",
    full_city_name: "Sparks, NV",
    city_name: "Sparks",
  },
  {
    postal_code: "84116",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "33149",
    full_city_name: "Key Biscayne, FL",
    city_name: "Key Biscayne",
  },
  {
    postal_code: "14904",
    full_city_name: "Elmira, NY",
    city_name: "Elmira",
  },
  {
    postal_code: "74601",
    full_city_name: "Ponca City, OK",
    city_name: "Ponca City",
  },
  {
    postal_code: "73036",
    full_city_name: "El Reno, OK",
    city_name: "El Reno",
  },
  {
    postal_code: "34983",
    full_city_name: "Port Saint Lucie, FL",
    city_name: "Port Saint Lucie",
  },
  {
    postal_code: "05641",
    full_city_name: "Barre, VT",
    city_name: "Barre",
  },
  {
    postal_code: "11701",
    full_city_name: "Amityville, NY",
    city_name: "Amityville",
  },
  {
    postal_code: "78207",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "54501",
    full_city_name: "Rhinelander, WI",
    city_name: "Rhinelander",
  },
  {
    postal_code: "72223",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "98338",
    full_city_name: "Graham, WA",
    city_name: "Graham",
  },
  {
    postal_code: "29150",
    full_city_name: "Sumter, SC",
    city_name: "Sumter",
  },
  {
    postal_code: "91304",
    full_city_name: "Canoga Park, CA",
    city_name: "Canoga Park",
  },
  {
    postal_code: "68847",
    full_city_name: "Kearney, NE",
    city_name: "Kearney",
  },
  {
    postal_code: "84405",
    full_city_name: "Ogden, UT",
    city_name: "Ogden",
  },
  {
    postal_code: "34677",
    full_city_name: "Oldsmar, FL",
    city_name: "Oldsmar",
  },
  {
    postal_code: "64132",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "27310",
    full_city_name: "Oak Ridge, NC",
    city_name: "Oak Ridge",
  },
  {
    postal_code: "96749",
    full_city_name: "Keaau, HI",
    city_name: "Keaau",
  },
  {
    postal_code: "78132",
    full_city_name: "New Braunfels, TX",
    city_name: "New Braunfels",
  },
  {
    postal_code: "76692",
    full_city_name: "Whitney, TX",
    city_name: "Whitney",
  },
  {
    postal_code: "30028",
    full_city_name: "Cumming, GA",
    city_name: "Cumming",
  },
  {
    postal_code: "23116",
    full_city_name: "Mechanicsville, VA",
    city_name: "Mechanicsville",
  },
  {
    postal_code: "92130",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "66441",
    full_city_name: "Junction City, KS",
    city_name: "Junction City",
  },
  {
    postal_code: "92101",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "07866",
    full_city_name: "Rockaway, NJ",
    city_name: "Rockaway",
  },
  {
    postal_code: "30132",
    full_city_name: "Dallas, GA",
    city_name: "Dallas",
  },
  {
    postal_code: "02536",
    full_city_name: "East Falmouth, MA",
    city_name: "East Falmouth",
  },
  {
    postal_code: "92503",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "80005",
    full_city_name: "Arvada, CO",
    city_name: "Arvada",
  },
  {
    postal_code: "83713",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "53532",
    full_city_name: "Deforest, WI",
    city_name: "Deforest",
  },
  {
    postal_code: "30705",
    full_city_name: "Chatsworth, GA",
    city_name: "Chatsworth",
  },
  {
    postal_code: "07727",
    full_city_name: "Farmingdale, NJ",
    city_name: "Farmingdale",
  },
  {
    postal_code: "11950",
    full_city_name: "Mastic, NY",
    city_name: "Mastic",
  },
  {
    postal_code: "34420",
    full_city_name: "Belleview, FL",
    city_name: "Belleview",
  },
  {
    postal_code: "85140",
    full_city_name: "San Tan Valley, AZ",
    city_name: "San Tan Valley",
  },
  {
    postal_code: "60614",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "54751",
    full_city_name: "Menomonie, WI",
    city_name: "Menomonie",
  },
  {
    postal_code: "85048",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "37174",
    full_city_name: "Spring Hill, TN",
    city_name: "Spring Hill",
  },
  {
    postal_code: "37917",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "87002",
    full_city_name: "Belen, NM",
    city_name: "Belen",
  },
  {
    postal_code: "12524",
    full_city_name: "Fishkill, NY",
    city_name: "Fishkill",
  },
  {
    postal_code: "76542",
    full_city_name: "Killeen, TX",
    city_name: "Killeen",
  },
  {
    postal_code: "28056",
    full_city_name: "Gastonia, NC",
    city_name: "Gastonia",
  },
  {
    postal_code: "98056",
    full_city_name: "Renton, WA",
    city_name: "Renton",
  },
  {
    postal_code: "06606",
    full_city_name: "Bridgeport, CT",
    city_name: "Bridgeport",
  },
  {
    postal_code: "75402",
    full_city_name: "Greenville, TX",
    city_name: "Greenville",
  },
  {
    postal_code: "37766",
    full_city_name: "La Follette, TN",
    city_name: "La Follette",
  },
  {
    postal_code: "37745",
    full_city_name: "Greeneville, TN",
    city_name: "Greeneville",
  },
  {
    postal_code: "48612",
    full_city_name: "Beaverton, MI",
    city_name: "Beaverton",
  },
  {
    postal_code: "31313",
    full_city_name: "Hinesville, GA",
    city_name: "Hinesville",
  },
  {
    postal_code: "10583",
    full_city_name: "Scarsdale, NY",
    city_name: "Scarsdale",
  },
  {
    postal_code: "40216",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "85331",
    full_city_name: "Cave Creek, AZ",
    city_name: "Cave Creek",
  },
  {
    postal_code: "21060",
    full_city_name: "Glen Burnie, MD",
    city_name: "Glen Burnie",
  },
  {
    postal_code: "44425",
    full_city_name: "Hubbard, OH",
    city_name: "Hubbard",
  },
  {
    postal_code: "34233",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "44471",
    full_city_name: "Struthers, OH",
    city_name: "Struthers",
  },
  {
    postal_code: "11772",
    full_city_name: "Patchogue, NY",
    city_name: "Patchogue",
  },
  {
    postal_code: "06795",
    full_city_name: "Watertown, CT",
    city_name: "Watertown",
  },
  {
    postal_code: "75604",
    full_city_name: "Longview, TX",
    city_name: "Longview",
  },
  {
    postal_code: "77022",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "90805",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "20707",
    full_city_name: "Laurel, MD",
    city_name: "Laurel",
  },
  {
    postal_code: "98294",
    full_city_name: "Sultan, WA",
    city_name: "Sultan",
  },
  {
    postal_code: "30512",
    full_city_name: "Blairsville, GA",
    city_name: "Blairsville",
  },
  {
    postal_code: "49009",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "32725",
    full_city_name: "Deltona, FL",
    city_name: "Deltona",
  },
  {
    postal_code: "75150",
    full_city_name: "Mesquite, TX",
    city_name: "Mesquite",
  },
  {
    postal_code: "88012",
    full_city_name: "Las Cruces, NM",
    city_name: "Las Cruces",
  },
  {
    postal_code: "33619",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "80104",
    full_city_name: "Castle Rock, CO",
    city_name: "Castle Rock",
  },
  {
    postal_code: "34606",
    full_city_name: "Spring Hill, FL",
    city_name: "Spring Hill",
  },
  {
    postal_code: "97217",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "91367",
    full_city_name: "Woodland Hills, CA",
    city_name: "Woodland Hills",
  },
  {
    postal_code: "68526",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "85755",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "46240",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "33322",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "29829",
    full_city_name: "Graniteville, SC",
    city_name: "Graniteville",
  },
  {
    postal_code: "28120",
    full_city_name: "Mount Holly, NC",
    city_name: "Mount Holly",
  },
  {
    postal_code: "95687",
    full_city_name: "Vacaville, CA",
    city_name: "Vacaville",
  },
  {
    postal_code: "59716",
    full_city_name: "Big Sky, MT",
    city_name: "Big Sky",
  },
  {
    postal_code: "21236",
    full_city_name: "Nottingham, MD",
    city_name: "Nottingham",
  },
  {
    postal_code: "80223",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "21044",
    full_city_name: "Columbia, MD",
    city_name: "Columbia",
  },
  {
    postal_code: "28460",
    full_city_name: "Sneads Ferry, NC",
    city_name: "Sneads Ferry",
  },
  {
    postal_code: "30281",
    full_city_name: "Stockbridge, GA",
    city_name: "Stockbridge",
  },
  {
    postal_code: "78259",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "67204",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "30117",
    full_city_name: "Carrollton, GA",
    city_name: "Carrollton",
  },
  {
    postal_code: "85756",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "80017",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "90262",
    full_city_name: "Lynwood, CA",
    city_name: "Lynwood",
  },
  {
    postal_code: "78727",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "53714",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "77302",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "02888",
    full_city_name: "Warwick, RI",
    city_name: "Warwick",
  },
  {
    postal_code: "37072",
    full_city_name: "Goodlettsville, TN",
    city_name: "Goodlettsville",
  },
  {
    postal_code: "90815",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "64034",
    full_city_name: "Greenwood, MO",
    city_name: "Greenwood",
  },
  {
    postal_code: "28209",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "44319",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "07716",
    full_city_name: "Atlantic Highlands, NJ",
    city_name: "Atlantic Highlands",
  },
  {
    postal_code: "93454",
    full_city_name: "Santa Maria, CA",
    city_name: "Santa Maria",
  },
  {
    postal_code: "21409",
    full_city_name: "Annapolis, MD",
    city_name: "Annapolis",
  },
  {
    postal_code: "72740",
    full_city_name: "Huntsville, AR",
    city_name: "Huntsville",
  },
  {
    postal_code: "07462",
    full_city_name: "Vernon, NJ",
    city_name: "Vernon",
  },
  {
    postal_code: "22603",
    full_city_name: "Winchester, VA",
    city_name: "Winchester",
  },
  {
    postal_code: "46815",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "06830",
    full_city_name: "Greenwich, CT",
    city_name: "Greenwich",
  },
  {
    postal_code: "99022",
    full_city_name: "Medical Lake, WA",
    city_name: "Medical Lake",
  },
  {
    postal_code: "98374",
    full_city_name: "Puyallup, WA",
    city_name: "Puyallup",
  },
  {
    postal_code: "92869",
    full_city_name: "Orange, CA",
    city_name: "Orange",
  },
  {
    postal_code: "22033",
    full_city_name: "Fairfax, VA",
    city_name: "Fairfax",
  },
  {
    postal_code: "37208",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "45039",
    full_city_name: "Maineville, OH",
    city_name: "Maineville",
  },
  {
    postal_code: "23009",
    full_city_name: "Aylett, VA",
    city_name: "Aylett",
  },
  {
    postal_code: "21629",
    full_city_name: "Denton, MD",
    city_name: "Denton",
  },
  {
    postal_code: "32801",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "52722",
    full_city_name: "Bettendorf, IA",
    city_name: "Bettendorf",
  },
  {
    postal_code: "48473",
    full_city_name: "Swartz Creek, MI",
    city_name: "Swartz Creek",
  },
  {
    postal_code: "48082",
    full_city_name: "Saint Clair Shores, MI",
    city_name: "Saint Clair Shores",
  },
  {
    postal_code: "56308",
    full_city_name: "Alexandria, MN",
    city_name: "Alexandria",
  },
  {
    postal_code: "95127",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "27944",
    full_city_name: "Hertford, NC",
    city_name: "Hertford",
  },
  {
    postal_code: "61455",
    full_city_name: "Macomb, IL",
    city_name: "Macomb",
  },
  {
    postal_code: "28328",
    full_city_name: "Clinton, NC",
    city_name: "Clinton",
  },
  {
    postal_code: "80003",
    full_city_name: "Arvada, CO",
    city_name: "Arvada",
  },
  {
    postal_code: "49017",
    full_city_name: "Battle Creek, MI",
    city_name: "Battle Creek",
  },
  {
    postal_code: "32812",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "33710",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "30720",
    full_city_name: "Dalton, GA",
    city_name: "Dalton",
  },
  {
    postal_code: "27893",
    full_city_name: "Wilson, NC",
    city_name: "Wilson",
  },
  {
    postal_code: "07036",
    full_city_name: "Linden, NJ",
    city_name: "Linden",
  },
  {
    postal_code: "64870",
    full_city_name: "Webb City, MO",
    city_name: "Webb City",
  },
  {
    postal_code: "98332",
    full_city_name: "Gig Harbor, WA",
    city_name: "Gig Harbor",
  },
  {
    postal_code: "63048",
    full_city_name: "Herculaneum, MO",
    city_name: "Herculaneum",
  },
  {
    postal_code: "76114",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "20906",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "30308",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "90640",
    full_city_name: "Montebello, CA",
    city_name: "Montebello",
  },
  {
    postal_code: "29407",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "35209",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "97070",
    full_city_name: "Wilsonville, OR",
    city_name: "Wilsonville",
  },
  {
    postal_code: "80232",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "98077",
    full_city_name: "Woodinville, WA",
    city_name: "Woodinville",
  },
  {
    postal_code: "96797",
    full_city_name: "Waipahu, HI",
    city_name: "Waipahu",
  },
  {
    postal_code: "06385",
    full_city_name: "Waterford, CT",
    city_name: "Waterford",
  },
  {
    postal_code: "31316",
    full_city_name: "Ludowici, GA",
    city_name: "Ludowici",
  },
  {
    postal_code: "48867",
    full_city_name: "Owosso, MI",
    city_name: "Owosso",
  },
  {
    postal_code: "77384",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "31217",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "27613",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "92065",
    full_city_name: "Ramona, CA",
    city_name: "Ramona",
  },
  {
    postal_code: "77423",
    full_city_name: "Brookshire, TX",
    city_name: "Brookshire",
  },
  {
    postal_code: "30019",
    full_city_name: "Dacula, GA",
    city_name: "Dacula",
  },
  {
    postal_code: "11355",
    full_city_name: "Flushing, NY",
    city_name: "Flushing",
  },
  {
    postal_code: "80020",
    full_city_name: "Broomfield, CO",
    city_name: "Broomfield",
  },
  {
    postal_code: "98092",
    full_city_name: "Auburn, WA",
    city_name: "Auburn",
  },
  {
    postal_code: "11421",
    full_city_name: "Woodhaven, NY",
    city_name: "Woodhaven",
  },
  {
    postal_code: "97479",
    full_city_name: "Sutherlin, OR",
    city_name: "Sutherlin",
  },
  {
    postal_code: "78070",
    full_city_name: "Spring Branch, TX",
    city_name: "Spring Branch",
  },
  {
    postal_code: "50226",
    full_city_name: "Polk City, IA",
    city_name: "Polk City",
  },
  {
    postal_code: "32210",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "44123",
    full_city_name: "Euclid, OH",
    city_name: "Euclid",
  },
  {
    postal_code: "34608",
    full_city_name: "Spring Hill, FL",
    city_name: "Spring Hill",
  },
  {
    postal_code: "32822",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "92078",
    full_city_name: "San Marcos, CA",
    city_name: "San Marcos",
  },
  {
    postal_code: "30314",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "34208",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "98112",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "55124",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "34275",
    full_city_name: "Nokomis, FL",
    city_name: "Nokomis",
  },
  {
    postal_code: "80550",
    full_city_name: "Windsor, CO",
    city_name: "Windsor",
  },
  {
    postal_code: "12831",
    full_city_name: "Gansevoort, NY",
    city_name: "Gansevoort",
  },
  {
    postal_code: "49685",
    full_city_name: "Traverse City, MI",
    city_name: "Traverse City",
  },
  {
    postal_code: "30677",
    full_city_name: "Watkinsville, GA",
    city_name: "Watkinsville",
  },
  {
    postal_code: "11224",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "02809",
    full_city_name: "Bristol, RI",
    city_name: "Bristol",
  },
  {
    postal_code: "07052",
    full_city_name: "West Orange, NJ",
    city_name: "West Orange",
  },
  {
    postal_code: "24333",
    full_city_name: "Galax, VA",
    city_name: "Galax",
  },
  {
    postal_code: "08536",
    full_city_name: "Plainsboro, NJ",
    city_name: "Plainsboro",
  },
  {
    postal_code: "27203",
    full_city_name: "Asheboro, NC",
    city_name: "Asheboro",
  },
  {
    postal_code: "98226",
    full_city_name: "Bellingham, WA",
    city_name: "Bellingham",
  },
  {
    postal_code: "92064",
    full_city_name: "Poway, CA",
    city_name: "Poway",
  },
  {
    postal_code: "77546",
    full_city_name: "Friendswood, TX",
    city_name: "Friendswood",
  },
  {
    postal_code: "29801",
    full_city_name: "Aiken, SC",
    city_name: "Aiken",
  },
  {
    postal_code: "91790",
    full_city_name: "West Covina, CA",
    city_name: "West Covina",
  },
  {
    postal_code: "28203",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "84401",
    full_city_name: "Ogden, UT",
    city_name: "Ogden",
  },
  {
    postal_code: "60545",
    full_city_name: "Plano, IL",
    city_name: "Plano",
  },
  {
    postal_code: "48188",
    full_city_name: "Canton, MI",
    city_name: "Canton",
  },
  {
    postal_code: "33563",
    full_city_name: "Plant City, FL",
    city_name: "Plant City",
  },
  {
    postal_code: "49506",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "95835",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "68310",
    full_city_name: "Beatrice, NE",
    city_name: "Beatrice",
  },
  {
    postal_code: "13088",
    full_city_name: "Liverpool, NY",
    city_name: "Liverpool",
  },
  {
    postal_code: "45014",
    full_city_name: "Fairfield, OH",
    city_name: "Fairfield",
  },
  {
    postal_code: "84060",
    full_city_name: "Park City, UT",
    city_name: "Park City",
  },
  {
    postal_code: "55345",
    full_city_name: "Minnetonka, MN",
    city_name: "Minnetonka",
  },
  {
    postal_code: "65536",
    full_city_name: "Lebanon, MO",
    city_name: "Lebanon",
  },
  {
    postal_code: "96744",
    full_city_name: "Kaneohe, HI",
    city_name: "Kaneohe",
  },
  {
    postal_code: "83638",
    full_city_name: "Mccall, ID",
    city_name: "Mccall",
  },
  {
    postal_code: "83716",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "92404",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "28645",
    full_city_name: "Lenoir, NC",
    city_name: "Lenoir",
  },
  {
    postal_code: "29588",
    full_city_name: "Myrtle Beach, SC",
    city_name: "Myrtle Beach",
  },
  {
    postal_code: "43952",
    full_city_name: "Steubenville, OH",
    city_name: "Steubenville",
  },
  {
    postal_code: "23093",
    full_city_name: "Louisa, VA",
    city_name: "Louisa",
  },
  {
    postal_code: "42001",
    full_city_name: "Paducah, KY",
    city_name: "Paducah",
  },
  {
    postal_code: "94544",
    full_city_name: "Hayward, CA",
    city_name: "Hayward",
  },
  {
    postal_code: "84653",
    full_city_name: "Salem, UT",
    city_name: "Salem",
  },
  {
    postal_code: "80130",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "97914",
    full_city_name: "Ontario, OR",
    city_name: "Ontario",
  },
  {
    postal_code: "19015",
    full_city_name: "Brookhaven, PA",
    city_name: "Brookhaven",
  },
  {
    postal_code: "34683",
    full_city_name: "Palm Harbor, FL",
    city_name: "Palm Harbor",
  },
  {
    postal_code: "68111",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "45417",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "93702",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "63383",
    full_city_name: "Warrenton, MO",
    city_name: "Warrenton",
  },
  {
    postal_code: "17552",
    full_city_name: "Mount Joy, PA",
    city_name: "Mount Joy",
  },
  {
    postal_code: "34473",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "91355",
    full_city_name: "Valencia, CA",
    city_name: "Valencia",
  },
  {
    postal_code: "89027",
    full_city_name: "Mesquite, NV",
    city_name: "Mesquite",
  },
  {
    postal_code: "53711",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "18624",
    full_city_name: "Lake Harmony, PA",
    city_name: "Lake Harmony",
  },
  {
    postal_code: "22304",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "28348",
    full_city_name: "Hope Mills, NC",
    city_name: "Hope Mills",
  },
  {
    postal_code: "38571",
    full_city_name: "Crossville, TN",
    city_name: "Crossville",
  },
  {
    postal_code: "15220",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "54130",
    full_city_name: "Kaukauna, WI",
    city_name: "Kaukauna",
  },
  {
    postal_code: "99352",
    full_city_name: "Richland, WA",
    city_name: "Richland",
  },
  {
    postal_code: "47201",
    full_city_name: "Columbus, IN",
    city_name: "Columbus",
  },
  {
    postal_code: "21045",
    full_city_name: "Columbia, MD",
    city_name: "Columbia",
  },
  {
    postal_code: "10027",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "44301",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "28379",
    full_city_name: "Rockingham, NC",
    city_name: "Rockingham",
  },
  {
    postal_code: "55746",
    full_city_name: "Hibbing, MN",
    city_name: "Hibbing",
  },
  {
    postal_code: "34601",
    full_city_name: "Brooksville, FL",
    city_name: "Brooksville",
  },
  {
    postal_code: "33909",
    full_city_name: "Cape Coral, FL",
    city_name: "Cape Coral",
  },
  {
    postal_code: "13601",
    full_city_name: "Watertown, NY",
    city_name: "Watertown",
  },
  {
    postal_code: "78006",
    full_city_name: "Boerne, TX",
    city_name: "Boerne",
  },
  {
    postal_code: "33444",
    full_city_name: "Delray Beach, FL",
    city_name: "Delray Beach",
  },
  {
    postal_code: "78220",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "39212",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "23838",
    full_city_name: "Chesterfield, VA",
    city_name: "Chesterfield",
  },
  {
    postal_code: "24019",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "98606",
    full_city_name: "Brush Prairie, WA",
    city_name: "Brush Prairie",
  },
  {
    postal_code: "92397",
    full_city_name: "Wrightwood, CA",
    city_name: "Wrightwood",
  },
  {
    postal_code: "48912",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "75214",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "80110",
    full_city_name: "Englewood, CO",
    city_name: "Englewood",
  },
  {
    postal_code: "55447",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "95014",
    full_city_name: "Cupertino, CA",
    city_name: "Cupertino",
  },
  {
    postal_code: "55803",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "12308",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "84088",
    full_city_name: "West Jordan, UT",
    city_name: "West Jordan",
  },
  {
    postal_code: "82716",
    full_city_name: "Gillette, WY",
    city_name: "Gillette",
  },
  {
    postal_code: "93711",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "38804",
    full_city_name: "Tupelo, MS",
    city_name: "Tupelo",
  },
  {
    postal_code: "84096",
    full_city_name: "Herriman, UT",
    city_name: "Herriman",
  },
  {
    postal_code: "27545",
    full_city_name: "Knightdale, NC",
    city_name: "Knightdale",
  },
  {
    postal_code: "97401",
    full_city_name: "Eugene, OR",
    city_name: "Eugene",
  },
  {
    postal_code: "80023",
    full_city_name: "Broomfield, CO",
    city_name: "Broomfield",
  },
  {
    postal_code: "97404",
    full_city_name: "Eugene, OR",
    city_name: "Eugene",
  },
  {
    postal_code: "60153",
    full_city_name: "Maywood, IL",
    city_name: "Maywood",
  },
  {
    postal_code: "49508",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "14224",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "75092",
    full_city_name: "Sherman, TX",
    city_name: "Sherman",
  },
  {
    postal_code: "84319",
    full_city_name: "Hyrum, UT",
    city_name: "Hyrum",
  },
  {
    postal_code: "80207",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "14226",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "12946",
    full_city_name: "Lake Placid, NY",
    city_name: "Lake Placid",
  },
  {
    postal_code: "07003",
    full_city_name: "Bloomfield, NJ",
    city_name: "Bloomfield",
  },
  {
    postal_code: "26062",
    full_city_name: "Weirton, WV",
    city_name: "Weirton",
  },
  {
    postal_code: "11757",
    full_city_name: "Lindenhurst, NY",
    city_name: "Lindenhurst",
  },
  {
    postal_code: "37221",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "92587",
    full_city_name: "Menifee, CA",
    city_name: "Menifee",
  },
  {
    postal_code: "97205",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "40204",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "30536",
    full_city_name: "Ellijay, GA",
    city_name: "Ellijay",
  },
  {
    postal_code: "32754",
    full_city_name: "Mims, FL",
    city_name: "Mims",
  },
  {
    postal_code: "66102",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "85390",
    full_city_name: "Wickenburg, AZ",
    city_name: "Wickenburg",
  },
  {
    postal_code: "61081",
    full_city_name: "Sterling, IL",
    city_name: "Sterling",
  },
  {
    postal_code: "27040",
    full_city_name: "Pfafftown, NC",
    city_name: "Pfafftown",
  },
  {
    postal_code: "75065",
    full_city_name: "Lake Dallas, TX",
    city_name: "Lake Dallas",
  },
  {
    postal_code: "85739",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "26301",
    full_city_name: "Clarksburg, WV",
    city_name: "Clarksburg",
  },
  {
    postal_code: "92026",
    full_city_name: "Escondido, CA",
    city_name: "Escondido",
  },
  {
    postal_code: "08901",
    full_city_name: "New Brunswick, NJ",
    city_name: "New Brunswick",
  },
  {
    postal_code: "20019",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "46303",
    full_city_name: "Cedar Lake, IN",
    city_name: "Cedar Lake",
  },
  {
    postal_code: "77024",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "50266",
    full_city_name: "West Des Moines, IA",
    city_name: "West Des Moines",
  },
  {
    postal_code: "36549",
    full_city_name: "Lillian, AL",
    city_name: "Lillian",
  },
  {
    postal_code: "92694",
    full_city_name: "Ladera Ranch, CA",
    city_name: "Ladera Ranch",
  },
  {
    postal_code: "40210",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "60618",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "80014",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "30701",
    full_city_name: "Calhoun, GA",
    city_name: "Calhoun",
  },
  {
    postal_code: "19146",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "36535",
    full_city_name: "Foley, AL",
    city_name: "Foley",
  },
  {
    postal_code: "59860",
    full_city_name: "Polson, MT",
    city_name: "Polson",
  },
  {
    postal_code: "60103",
    full_city_name: "Bartlett, IL",
    city_name: "Bartlett",
  },
  {
    postal_code: "92129",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "97703",
    full_city_name: "Bend, OR",
    city_name: "Bend",
  },
  {
    postal_code: "44410",
    full_city_name: "Cortland, OH",
    city_name: "Cortland",
  },
  {
    postal_code: "76244",
    full_city_name: "Keller, TX",
    city_name: "Keller",
  },
  {
    postal_code: "14830",
    full_city_name: "Corning, NY",
    city_name: "Corning",
  },
  {
    postal_code: "23059",
    full_city_name: "Glen Allen, VA",
    city_name: "Glen Allen",
  },
  {
    postal_code: "58703",
    full_city_name: "Minot, ND",
    city_name: "Minot",
  },
  {
    postal_code: "80031",
    full_city_name: "Westminster, CO",
    city_name: "Westminster",
  },
  {
    postal_code: "67226",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "07753",
    full_city_name: "Neptune, NJ",
    city_name: "Neptune",
  },
  {
    postal_code: "92040",
    full_city_name: "Lakeside, CA",
    city_name: "Lakeside",
  },
  {
    postal_code: "93292",
    full_city_name: "Visalia, CA",
    city_name: "Visalia",
  },
  {
    postal_code: "94551",
    full_city_name: "Livermore, CA",
    city_name: "Livermore",
  },
  {
    postal_code: "22192",
    full_city_name: "Woodbridge, VA",
    city_name: "Woodbridge",
  },
  {
    postal_code: "66221",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "47711",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "54455",
    full_city_name: "Mosinee, WI",
    city_name: "Mosinee",
  },
  {
    postal_code: "92679",
    full_city_name: "Trabuco Canyon, CA",
    city_name: "Trabuco Canyon",
  },
  {
    postal_code: "89117",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "34105",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "71603",
    full_city_name: "Pine Bluff, AR",
    city_name: "Pine Bluff",
  },
  {
    postal_code: "83706",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "66212",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "37067",
    full_city_name: "Franklin, TN",
    city_name: "Franklin",
  },
  {
    postal_code: "80004",
    full_city_name: "Arvada, CO",
    city_name: "Arvada",
  },
  {
    postal_code: "59847",
    full_city_name: "Lolo, MT",
    city_name: "Lolo",
  },
  {
    postal_code: "78749",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "64079",
    full_city_name: "Platte City, MO",
    city_name: "Platte City",
  },
  {
    postal_code: "19083",
    full_city_name: "Havertown, PA",
    city_name: "Havertown",
  },
  {
    postal_code: "63104",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "62650",
    full_city_name: "Jacksonville, IL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "28204",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "08030",
    full_city_name: "Gloucester City, NJ",
    city_name: "Gloucester City",
  },
  {
    postal_code: "92110",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "97086",
    full_city_name: "Happy Valley, OR",
    city_name: "Happy Valley",
  },
  {
    postal_code: "37772",
    full_city_name: "Lenoir City, TN",
    city_name: "Lenoir City",
  },
  {
    postal_code: "34239",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "40503",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "55417",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "24179",
    full_city_name: "Vinton, VA",
    city_name: "Vinton",
  },
  {
    postal_code: "28906",
    full_city_name: "Murphy, NC",
    city_name: "Murphy",
  },
  {
    postal_code: "39503",
    full_city_name: "Gulfport, MS",
    city_name: "Gulfport",
  },
  {
    postal_code: "30228",
    full_city_name: "Hampton, GA",
    city_name: "Hampton",
  },
  {
    postal_code: "92571",
    full_city_name: "Perris, CA",
    city_name: "Perris",
  },
  {
    postal_code: "92545",
    full_city_name: "Hemet, CA",
    city_name: "Hemet",
  },
  {
    postal_code: "83340",
    full_city_name: "Ketchum, ID",
    city_name: "Ketchum",
  },
  {
    postal_code: "30728",
    full_city_name: "La Fayette, GA",
    city_name: "La Fayette",
  },
  {
    postal_code: "32714",
    full_city_name: "Altamonte Springs, FL",
    city_name: "Altamonte Springs",
  },
  {
    postal_code: "27614",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "50701",
    full_city_name: "Waterloo, IA",
    city_name: "Waterloo",
  },
  {
    postal_code: "44039",
    full_city_name: "North Ridgeville, OH",
    city_name: "North Ridgeville",
  },
  {
    postal_code: "68048",
    full_city_name: "Plattsmouth, NE",
    city_name: "Plattsmouth",
  },
  {
    postal_code: "98144",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "21211",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "55110",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "06610",
    full_city_name: "Bridgeport, CT",
    city_name: "Bridgeport",
  },
  {
    postal_code: "22947",
    full_city_name: "Keswick, VA",
    city_name: "Keswick",
  },
  {
    postal_code: "38305",
    full_city_name: "Jackson, TN",
    city_name: "Jackson",
  },
  {
    postal_code: "06483",
    full_city_name: "Seymour, CT",
    city_name: "Seymour",
  },
  {
    postal_code: "13205",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "51360",
    full_city_name: "Spirit Lake, IA",
    city_name: "Spirit Lake",
  },
  {
    postal_code: "90048",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "18431",
    full_city_name: "Honesdale, PA",
    city_name: "Honesdale",
  },
  {
    postal_code: "16823",
    full_city_name: "Bellefonte, PA",
    city_name: "Bellefonte",
  },
  {
    postal_code: "08226",
    full_city_name: "Ocean City, NJ",
    city_name: "Ocean City",
  },
  {
    postal_code: "34668",
    full_city_name: "Port Richey, FL",
    city_name: "Port Richey",
  },
  {
    postal_code: "13901",
    full_city_name: "Binghamton, NY",
    city_name: "Binghamton",
  },
  {
    postal_code: "30087",
    full_city_name: "Stone Mountain, GA",
    city_name: "Stone Mountain",
  },
  {
    postal_code: "87124",
    full_city_name: "Rio Rancho, NM",
    city_name: "Rio Rancho",
  },
  {
    postal_code: "97305",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "62901",
    full_city_name: "Carbondale, IL",
    city_name: "Carbondale",
  },
  {
    postal_code: "75126",
    full_city_name: "Forney, TX",
    city_name: "Forney",
  },
  {
    postal_code: "11203",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "49417",
    full_city_name: "Grand Haven, MI",
    city_name: "Grand Haven",
  },
  {
    postal_code: "37388",
    full_city_name: "Tullahoma, TN",
    city_name: "Tullahoma",
  },
  {
    postal_code: "33487",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "92124",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "30248",
    full_city_name: "Locust Grove, GA",
    city_name: "Locust Grove",
  },
  {
    postal_code: "28607",
    full_city_name: "Boone, NC",
    city_name: "Boone",
  },
  {
    postal_code: "64068",
    full_city_name: "Liberty, MO",
    city_name: "Liberty",
  },
  {
    postal_code: "22315",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "60035",
    full_city_name: "Highland Park, IL",
    city_name: "Highland Park",
  },
  {
    postal_code: "55075",
    full_city_name: "South Saint Paul, MN",
    city_name: "South Saint Paul",
  },
  {
    postal_code: "70360",
    full_city_name: "Houma, LA",
    city_name: "Houma",
  },
  {
    postal_code: "22980",
    full_city_name: "Waynesboro, VA",
    city_name: "Waynesboro",
  },
  {
    postal_code: "45434",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "24550",
    full_city_name: "Evington, VA",
    city_name: "Evington",
  },
  {
    postal_code: "64701",
    full_city_name: "Harrisonville, MO",
    city_name: "Harrisonville",
  },
  {
    postal_code: "97138",
    full_city_name: "Seaside, OR",
    city_name: "Seaside",
  },
  {
    postal_code: "06418",
    full_city_name: "Derby, CT",
    city_name: "Derby",
  },
  {
    postal_code: "92548",
    full_city_name: "Homeland, CA",
    city_name: "Homeland",
  },
  {
    postal_code: "48152",
    full_city_name: "Livonia, MI",
    city_name: "Livonia",
  },
  {
    postal_code: "43452",
    full_city_name: "Port Clinton, OH",
    city_name: "Port Clinton",
  },
  {
    postal_code: "66224",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "06053",
    full_city_name: "New Britain, CT",
    city_name: "New Britain",
  },
  {
    postal_code: "27249",
    full_city_name: "Gibsonville, NC",
    city_name: "Gibsonville",
  },
  {
    postal_code: "33129",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "06517",
    full_city_name: "Hamden, CT",
    city_name: "Hamden",
  },
  {
    postal_code: "33920",
    full_city_name: "Alva, FL",
    city_name: "Alva",
  },
  {
    postal_code: "60013",
    full_city_name: "Cary, IL",
    city_name: "Cary",
  },
  {
    postal_code: "35404",
    full_city_name: "Tuscaloosa, AL",
    city_name: "Tuscaloosa",
  },
  {
    postal_code: "17815",
    full_city_name: "Bloomsburg, PA",
    city_name: "Bloomsburg",
  },
  {
    postal_code: "90277",
    full_city_name: "Redondo Beach, CA",
    city_name: "Redondo Beach",
  },
  {
    postal_code: "23114",
    full_city_name: "Midlothian, VA",
    city_name: "Midlothian",
  },
  {
    postal_code: "32162",
    full_city_name: "The Villages, FL",
    city_name: "The Villages",
  },
  {
    postal_code: "55008",
    full_city_name: "Cambridge, MN",
    city_name: "Cambridge",
  },
  {
    postal_code: "69101",
    full_city_name: "North Platte, NE",
    city_name: "North Platte",
  },
  {
    postal_code: "24401",
    full_city_name: "Staunton, VA",
    city_name: "Staunton",
  },
  {
    postal_code: "19138",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "55122",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "28602",
    full_city_name: "Hickory, NC",
    city_name: "Hickory",
  },
  {
    postal_code: "78251",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "70815",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "21224",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "20743",
    full_city_name: "Capitol Heights, MD",
    city_name: "Capitol Heights",
  },
  {
    postal_code: "28655",
    full_city_name: "Morganton, NC",
    city_name: "Morganton",
  },
  {
    postal_code: "92252",
    full_city_name: "Joshua Tree, CA",
    city_name: "Joshua Tree",
  },
  {
    postal_code: "55042",
    full_city_name: "Lake Elmo, MN",
    city_name: "Lake Elmo",
  },
  {
    postal_code: "32407",
    full_city_name: "Panama City Beach, FL",
    city_name: "Panama City Beach",
  },
  {
    postal_code: "27927",
    full_city_name: "Corolla, NC",
    city_name: "Corolla",
  },
  {
    postal_code: "12208",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "36856",
    full_city_name: "Fort Mitchell, AL",
    city_name: "Fort Mitchell",
  },
  {
    postal_code: "03842",
    full_city_name: "Hampton, NH",
    city_name: "Hampton",
  },
  {
    postal_code: "34142",
    full_city_name: "Immokalee, FL",
    city_name: "Immokalee",
  },
  {
    postal_code: "37604",
    full_city_name: "Johnson City, TN",
    city_name: "Johnson City",
  },
  {
    postal_code: "97124",
    full_city_name: "Hillsboro, OR",
    city_name: "Hillsboro",
  },
  {
    postal_code: "49721",
    full_city_name: "Cheboygan, MI",
    city_name: "Cheboygan",
  },
  {
    postal_code: "34222",
    full_city_name: "Ellenton, FL",
    city_name: "Ellenton",
  },
  {
    postal_code: "38106",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "44092",
    full_city_name: "Wickliffe, OH",
    city_name: "Wickliffe",
  },
  {
    postal_code: "33458",
    full_city_name: "Jupiter, FL",
    city_name: "Jupiter",
  },
  {
    postal_code: "70607",
    full_city_name: "Lake Charles, LA",
    city_name: "Lake Charles",
  },
  {
    postal_code: "27253",
    full_city_name: "Graham, NC",
    city_name: "Graham",
  },
  {
    postal_code: "71115",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "33982",
    full_city_name: "Punta Gorda, FL",
    city_name: "Punta Gorda",
  },
  {
    postal_code: "31401",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "32301",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "19403",
    full_city_name: "Norristown, PA",
    city_name: "Norristown",
  },
  {
    postal_code: "95742",
    full_city_name: "Rancho Cordova, CA",
    city_name: "Rancho Cordova",
  },
  {
    postal_code: "21144",
    full_city_name: "Severn, MD",
    city_name: "Severn",
  },
  {
    postal_code: "29605",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "23222",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "06107",
    full_city_name: "West Hartford, CT",
    city_name: "West Hartford",
  },
  {
    postal_code: "43004",
    full_city_name: "Blacklick, OH",
    city_name: "Blacklick",
  },
  {
    postal_code: "70068",
    full_city_name: "La Place, LA",
    city_name: "La Place",
  },
  {
    postal_code: "60404",
    full_city_name: "Shorewood, IL",
    city_name: "Shorewood",
  },
  {
    postal_code: "78597",
    full_city_name: "South Padre Island, TX",
    city_name: "South Padre Island",
  },
  {
    postal_code: "78654",
    full_city_name: "Marble Falls, TX",
    city_name: "Marble Falls",
  },
  {
    postal_code: "32534",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "60558",
    full_city_name: "Western Springs, IL",
    city_name: "Western Springs",
  },
  {
    postal_code: "97702",
    full_city_name: "Bend, OR",
    city_name: "Bend",
  },
  {
    postal_code: "30014",
    full_city_name: "Covington, GA",
    city_name: "Covington",
  },
  {
    postal_code: "44446",
    full_city_name: "Niles, OH",
    city_name: "Niles",
  },
  {
    postal_code: "79413",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "07083",
    full_city_name: "Union, NJ",
    city_name: "Union",
  },
  {
    postal_code: "15235",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "98296",
    full_city_name: "Snohomish, WA",
    city_name: "Snohomish",
  },
  {
    postal_code: "34223",
    full_city_name: "Englewood, FL",
    city_name: "Englewood",
  },
  {
    postal_code: "33569",
    full_city_name: "Riverview, FL",
    city_name: "Riverview",
  },
  {
    postal_code: "48111",
    full_city_name: "Belleville, MI",
    city_name: "Belleville",
  },
  {
    postal_code: "49090",
    full_city_name: "South Haven, MI",
    city_name: "South Haven",
  },
  {
    postal_code: "33916",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "34229",
    full_city_name: "Osprey, FL",
    city_name: "Osprey",
  },
  {
    postal_code: "91325",
    full_city_name: "Northridge, CA",
    city_name: "Northridge",
  },
  {
    postal_code: "48858",
    full_city_name: "Mount Pleasant, MI",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "76088",
    full_city_name: "Weatherford, TX",
    city_name: "Weatherford",
  },
  {
    postal_code: "98683",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "21090",
    full_city_name: "Linthicum Heights, MD",
    city_name: "Linthicum Heights",
  },
  {
    postal_code: "98671",
    full_city_name: "Washougal, WA",
    city_name: "Washougal",
  },
  {
    postal_code: "49509",
    full_city_name: "Wyoming, MI",
    city_name: "Wyoming",
  },
  {
    postal_code: "70529",
    full_city_name: "Duson, LA",
    city_name: "Duson",
  },
  {
    postal_code: "94903",
    full_city_name: "San Rafael, CA",
    city_name: "San Rafael",
  },
  {
    postal_code: "60515",
    full_city_name: "Downers Grove, IL",
    city_name: "Downers Grove",
  },
  {
    postal_code: "48322",
    full_city_name: "West Bloomfield, MI",
    city_name: "West Bloomfield",
  },
  {
    postal_code: "33912",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "45103",
    full_city_name: "Batavia, OH",
    city_name: "Batavia",
  },
  {
    postal_code: "11358",
    full_city_name: "Flushing, NY",
    city_name: "Flushing",
  },
  {
    postal_code: "76110",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "95492",
    full_city_name: "Windsor, CA",
    city_name: "Windsor",
  },
  {
    postal_code: "07095",
    full_city_name: "Woodbridge, NJ",
    city_name: "Woodbridge",
  },
  {
    postal_code: "02664",
    full_city_name: "South Yarmouth, MA",
    city_name: "South Yarmouth",
  },
  {
    postal_code: "64134",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "19130",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "27948",
    full_city_name: "Kill Devil Hills, NC",
    city_name: "Kill Devil Hills",
  },
  {
    postal_code: "33309",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "47906",
    full_city_name: "West Lafayette, IN",
    city_name: "West Lafayette",
  },
  {
    postal_code: "85254",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "39401",
    full_city_name: "Hattiesburg, MS",
    city_name: "Hattiesburg",
  },
  {
    postal_code: "60538",
    full_city_name: "Montgomery, IL",
    city_name: "Montgomery",
  },
  {
    postal_code: "12009",
    full_city_name: "Altamont, NY",
    city_name: "Altamont",
  },
  {
    postal_code: "54301",
    full_city_name: "Green Bay, WI",
    city_name: "Green Bay",
  },
  {
    postal_code: "33306",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "40218",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "28613",
    full_city_name: "Conover, NC",
    city_name: "Conover",
  },
  {
    postal_code: "98422",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "27358",
    full_city_name: "Summerfield, NC",
    city_name: "Summerfield",
  },
  {
    postal_code: "98003",
    full_city_name: "Federal Way, WA",
    city_name: "Federal Way",
  },
  {
    postal_code: "90706",
    full_city_name: "Bellflower, CA",
    city_name: "Bellflower",
  },
  {
    postal_code: "99645",
    full_city_name: "Palmer, AK",
    city_name: "Palmer",
  },
  {
    postal_code: "74804",
    full_city_name: "Shawnee, OK",
    city_name: "Shawnee",
  },
  {
    postal_code: "33150",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "83858",
    full_city_name: "Rathdrum, ID",
    city_name: "Rathdrum",
  },
  {
    postal_code: "89503",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "70809",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "52404",
    full_city_name: "Cedar Rapids, IA",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "37148",
    full_city_name: "Portland, TN",
    city_name: "Portland",
  },
  {
    postal_code: "37087",
    full_city_name: "Lebanon, TN",
    city_name: "Lebanon",
  },
  {
    postal_code: "77044",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "11563",
    full_city_name: "Lynbrook, NY",
    city_name: "Lynbrook",
  },
  {
    postal_code: "18324",
    full_city_name: "Bushkill, PA",
    city_name: "Bushkill",
  },
  {
    postal_code: "28403",
    full_city_name: "Wilmington, NC",
    city_name: "Wilmington",
  },
  {
    postal_code: "77840",
    full_city_name: "College Station, TX",
    city_name: "College Station",
  },
  {
    postal_code: "52402",
    full_city_name: "Cedar Rapids, IA",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "13066",
    full_city_name: "Fayetteville, NY",
    city_name: "Fayetteville",
  },
  {
    postal_code: "30643",
    full_city_name: "Hartwell, GA",
    city_name: "Hartwell",
  },
  {
    postal_code: "98122",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "97210",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "30650",
    full_city_name: "Madison, GA",
    city_name: "Madison",
  },
  {
    postal_code: "77020",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "02540",
    full_city_name: "Falmouth, MA",
    city_name: "Falmouth",
  },
  {
    postal_code: "94546",
    full_city_name: "Castro Valley, CA",
    city_name: "Castro Valley",
  },
  {
    postal_code: "92782",
    full_city_name: "Tustin, CA",
    city_name: "Tustin",
  },
  {
    postal_code: "99224",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "77088",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "33035",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "75904",
    full_city_name: "Lufkin, TX",
    city_name: "Lufkin",
  },
  {
    postal_code: "92673",
    full_city_name: "San Clemente, CA",
    city_name: "San Clemente",
  },
  {
    postal_code: "60636",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "08840",
    full_city_name: "Metuchen, NJ",
    city_name: "Metuchen",
  },
  {
    postal_code: "85012",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "94403",
    full_city_name: "San Mateo, CA",
    city_name: "San Mateo",
  },
  {
    postal_code: "24153",
    full_city_name: "Salem, VA",
    city_name: "Salem",
  },
  {
    postal_code: "21108",
    full_city_name: "Millersville, MD",
    city_name: "Millersville",
  },
  {
    postal_code: "46239",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "77340",
    full_city_name: "Huntsville, TX",
    city_name: "Huntsville",
  },
  {
    postal_code: "83634",
    full_city_name: "Kuna, ID",
    city_name: "Kuna",
  },
  {
    postal_code: "52317",
    full_city_name: "North Liberty, IA",
    city_name: "North Liberty",
  },
  {
    postal_code: "22508",
    full_city_name: "Locust Grove, VA",
    city_name: "Locust Grove",
  },
  {
    postal_code: "73401",
    full_city_name: "Ardmore, OK",
    city_name: "Ardmore",
  },
  {
    postal_code: "59801",
    full_city_name: "Missoula, MT",
    city_name: "Missoula",
  },
  {
    postal_code: "93727",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "21286",
    full_city_name: "Towson, MD",
    city_name: "Towson",
  },
  {
    postal_code: "32164",
    full_city_name: "Palm Coast, FL",
    city_name: "Palm Coast",
  },
  {
    postal_code: "77565",
    full_city_name: "Kemah, TX",
    city_name: "Kemah",
  },
  {
    postal_code: "62896",
    full_city_name: "West Frankfort, IL",
    city_name: "West Frankfort",
  },
  {
    postal_code: "33063",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "64151",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "38141",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "92584",
    full_city_name: "Menifee, CA",
    city_name: "Menifee",
  },
  {
    postal_code: "98382",
    full_city_name: "Sequim, WA",
    city_name: "Sequim",
  },
  {
    postal_code: "28779",
    full_city_name: "Sylva, NC",
    city_name: "Sylva",
  },
  {
    postal_code: "41076",
    full_city_name: "Newport, KY",
    city_name: "Newport",
  },
  {
    postal_code: "70433",
    full_city_name: "Covington, LA",
    city_name: "Covington",
  },
  {
    postal_code: "98372",
    full_city_name: "Puyallup, WA",
    city_name: "Puyallup",
  },
  {
    postal_code: "80012",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "49331",
    full_city_name: "Lowell, MI",
    city_name: "Lowell",
  },
  {
    postal_code: "80132",
    full_city_name: "Monument, CO",
    city_name: "Monument",
  },
  {
    postal_code: "55340",
    full_city_name: "Hamel, MN",
    city_name: "Hamel",
  },
  {
    postal_code: "60061",
    full_city_name: "Vernon Hills, IL",
    city_name: "Vernon Hills",
  },
  {
    postal_code: "17837",
    full_city_name: "Lewisburg, PA",
    city_name: "Lewisburg",
  },
  {
    postal_code: "90003",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "90746",
    full_city_name: "Carson, CA",
    city_name: "Carson",
  },
  {
    postal_code: "55347",
    full_city_name: "Eden Prairie, MN",
    city_name: "Eden Prairie",
  },
  {
    postal_code: "83619",
    full_city_name: "Fruitland, ID",
    city_name: "Fruitland",
  },
  {
    postal_code: "44077",
    full_city_name: "Painesville, OH",
    city_name: "Painesville",
  },
  {
    postal_code: "30360",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "46804",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "87043",
    full_city_name: "Placitas, NM",
    city_name: "Placitas",
  },
  {
    postal_code: "76058",
    full_city_name: "Joshua, TX",
    city_name: "Joshua",
  },
  {
    postal_code: "29631",
    full_city_name: "Clemson, SC",
    city_name: "Clemson",
  },
  {
    postal_code: "02840",
    full_city_name: "Newport, RI",
    city_name: "Newport",
  },
  {
    postal_code: "99669",
    full_city_name: "Soldotna, AK",
    city_name: "Soldotna",
  },
  {
    postal_code: "68106",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "98037",
    full_city_name: "Lynnwood, WA",
    city_name: "Lynnwood",
  },
  {
    postal_code: "13206",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "14305",
    full_city_name: "Niagara Falls, NY",
    city_name: "Niagara Falls",
  },
  {
    postal_code: "53717",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "64024",
    full_city_name: "Excelsior Springs, MO",
    city_name: "Excelsior Springs",
  },
  {
    postal_code: "77355",
    full_city_name: "Magnolia, TX",
    city_name: "Magnolia",
  },
  {
    postal_code: "90275",
    full_city_name: "Rancho Palos Verdes, CA",
    city_name: "Rancho Palos Verdes",
  },
  {
    postal_code: "21666",
    full_city_name: "Stevensville, MD",
    city_name: "Stevensville",
  },
  {
    postal_code: "80439",
    full_city_name: "Evergreen, CO",
    city_name: "Evergreen",
  },
  {
    postal_code: "02878",
    full_city_name: "Tiverton, RI",
    city_name: "Tiverton",
  },
  {
    postal_code: "80603",
    full_city_name: "Brighton, CO",
    city_name: "Brighton",
  },
  {
    postal_code: "86401",
    full_city_name: "Kingman, AZ",
    city_name: "Kingman",
  },
  {
    postal_code: "63040",
    full_city_name: "Wildwood, MO",
    city_name: "Wildwood",
  },
  {
    postal_code: "11717",
    full_city_name: "Brentwood, NY",
    city_name: "Brentwood",
  },
  {
    postal_code: "66083",
    full_city_name: "Spring Hill, KS",
    city_name: "Spring Hill",
  },
  {
    postal_code: "32168",
    full_city_name: "New Smyrna Beach, FL",
    city_name: "New Smyrna Beach",
  },
  {
    postal_code: "33137",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "95209",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "93245",
    full_city_name: "Lemoore, CA",
    city_name: "Lemoore",
  },
  {
    postal_code: "92780",
    full_city_name: "Tustin, CA",
    city_name: "Tustin",
  },
  {
    postal_code: "32303",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "66048",
    full_city_name: "Leavenworth, KS",
    city_name: "Leavenworth",
  },
  {
    postal_code: "71118",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "57702",
    full_city_name: "Rapid City, SD",
    city_name: "Rapid City",
  },
  {
    postal_code: "37412",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "92240",
    full_city_name: "Desert Hot Springs, CA",
    city_name: "Desert Hot Springs",
  },
  {
    postal_code: "98584",
    full_city_name: "Shelton, WA",
    city_name: "Shelton",
  },
  {
    postal_code: "02857",
    full_city_name: "North Scituate, RI",
    city_name: "North Scituate",
  },
  {
    postal_code: "29388",
    full_city_name: "Woodruff, SC",
    city_name: "Woodruff",
  },
  {
    postal_code: "68025",
    full_city_name: "Fremont, NE",
    city_name: "Fremont",
  },
  {
    postal_code: "42211",
    full_city_name: "Cadiz, KY",
    city_name: "Cadiz",
  },
  {
    postal_code: "95355",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "98311",
    full_city_name: "Bremerton, WA",
    city_name: "Bremerton",
  },
  {
    postal_code: "85297",
    full_city_name: "Gilbert, AZ",
    city_name: "Gilbert",
  },
  {
    postal_code: "78574",
    full_city_name: "Mission, TX",
    city_name: "Mission",
  },
  {
    postal_code: "19067",
    full_city_name: "Morrisville, PA",
    city_name: "Morrisville",
  },
  {
    postal_code: "08759",
    full_city_name: "Manchester Township, NJ",
    city_name: "Manchester Township",
  },
  {
    postal_code: "29501",
    full_city_name: "Florence, SC",
    city_name: "Florence",
  },
  {
    postal_code: "76513",
    full_city_name: "Belton, TX",
    city_name: "Belton",
  },
  {
    postal_code: "28604",
    full_city_name: "Banner Elk, NC",
    city_name: "Banner Elk",
  },
  {
    postal_code: "28270",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "08085",
    full_city_name: "Swedesboro, NJ",
    city_name: "Swedesboro",
  },
  {
    postal_code: "08037",
    full_city_name: "Hammonton, NJ",
    city_name: "Hammonton",
  },
  {
    postal_code: "98033",
    full_city_name: "Kirkland, WA",
    city_name: "Kirkland",
  },
  {
    postal_code: "44224",
    full_city_name: "Stow, OH",
    city_name: "Stow",
  },
  {
    postal_code: "80435",
    full_city_name: "Dillon, CO",
    city_name: "Dillon",
  },
  {
    postal_code: "07728",
    full_city_name: "Freehold, NJ",
    city_name: "Freehold",
  },
  {
    postal_code: "30241",
    full_city_name: "Lagrange, GA",
    city_name: "Lagrange",
  },
  {
    postal_code: "08046",
    full_city_name: "Willingboro, NJ",
    city_name: "Willingboro",
  },
  {
    postal_code: "30533",
    full_city_name: "Dahlonega, GA",
    city_name: "Dahlonega",
  },
  {
    postal_code: "48038",
    full_city_name: "Clinton Township, MI",
    city_name: "Clinton Township",
  },
  {
    postal_code: "64130",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "33896",
    full_city_name: "Davenport, FL",
    city_name: "Davenport",
  },
  {
    postal_code: "02816",
    full_city_name: "Coventry, RI",
    city_name: "Coventry",
  },
  {
    postal_code: "48642",
    full_city_name: "Midland, MI",
    city_name: "Midland",
  },
  {
    postal_code: "68144",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "78642",
    full_city_name: "Liberty Hill, TX",
    city_name: "Liberty Hill",
  },
  {
    postal_code: "17543",
    full_city_name: "Lititz, PA",
    city_name: "Lititz",
  },
  {
    postal_code: "28001",
    full_city_name: "Albemarle, NC",
    city_name: "Albemarle",
  },
  {
    postal_code: "68116",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "89119",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "86404",
    full_city_name: "Lake Havasu City, AZ",
    city_name: "Lake Havasu City",
  },
  {
    postal_code: "60504",
    full_city_name: "Aurora, IL",
    city_name: "Aurora",
  },
  {
    postal_code: "60914",
    full_city_name: "Bourbonnais, IL",
    city_name: "Bourbonnais",
  },
  {
    postal_code: "44135",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "11901",
    full_city_name: "Riverhead, NY",
    city_name: "Riverhead",
  },
  {
    postal_code: "64029",
    full_city_name: "Grain Valley, MO",
    city_name: "Grain Valley",
  },
  {
    postal_code: "28326",
    full_city_name: "Cameron, NC",
    city_name: "Cameron",
  },
  {
    postal_code: "12303",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "11778",
    full_city_name: "Rocky Point, NY",
    city_name: "Rocky Point",
  },
  {
    postal_code: "87106",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "64089",
    full_city_name: "Smithville, MO",
    city_name: "Smithville",
  },
  {
    postal_code: "19106",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "27542",
    full_city_name: "Kenly, NC",
    city_name: "Kenly",
  },
  {
    postal_code: "44028",
    full_city_name: "Columbia Station, OH",
    city_name: "Columbia Station",
  },
  {
    postal_code: "74066",
    full_city_name: "Sapulpa, OK",
    city_name: "Sapulpa",
  },
  {
    postal_code: "31216",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "06340",
    full_city_name: "Groton, CT",
    city_name: "Groton",
  },
  {
    postal_code: "33334",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "60194",
    full_city_name: "Schaumburg, IL",
    city_name: "Schaumburg",
  },
  {
    postal_code: "72802",
    full_city_name: "Russellville, AR",
    city_name: "Russellville",
  },
  {
    postal_code: "91709",
    full_city_name: "Chino Hills, CA",
    city_name: "Chino Hills",
  },
  {
    postal_code: "34431",
    full_city_name: "Dunnellon, FL",
    city_name: "Dunnellon",
  },
  {
    postal_code: "24141",
    full_city_name: "Radford, VA",
    city_name: "Radford",
  },
  {
    postal_code: "85249",
    full_city_name: "Chandler, AZ",
    city_name: "Chandler",
  },
  {
    postal_code: "08260",
    full_city_name: "Wildwood, NJ",
    city_name: "Wildwood",
  },
  {
    postal_code: "43160",
    full_city_name: "Washington Court House, OH",
    city_name: "Washington Court House",
  },
  {
    postal_code: "15203",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "33579",
    full_city_name: "Riverview, FL",
    city_name: "Riverview",
  },
  {
    postal_code: "87114",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "92860",
    full_city_name: "Norco, CA",
    city_name: "Norco",
  },
  {
    postal_code: "21842",
    full_city_name: "Ocean City, MD",
    city_name: "Ocean City",
  },
  {
    postal_code: "24541",
    full_city_name: "Danville, VA",
    city_name: "Danville",
  },
  {
    postal_code: "07734",
    full_city_name: "Keansburg, NJ",
    city_name: "Keansburg",
  },
  {
    postal_code: "20874",
    full_city_name: "Germantown, MD",
    city_name: "Germantown",
  },
  {
    postal_code: "60085",
    full_city_name: "Waukegan, IL",
    city_name: "Waukegan",
  },
  {
    postal_code: "91901",
    full_city_name: "Alpine, CA",
    city_name: "Alpine",
  },
  {
    postal_code: "29625",
    full_city_name: "Anderson, SC",
    city_name: "Anderson",
  },
  {
    postal_code: "38108",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "32707",
    full_city_name: "Casselberry, FL",
    city_name: "Casselberry",
  },
  {
    postal_code: "12534",
    full_city_name: "Hudson, NY",
    city_name: "Hudson",
  },
  {
    postal_code: "42431",
    full_city_name: "Madisonville, KY",
    city_name: "Madisonville",
  },
  {
    postal_code: "24503",
    full_city_name: "Lynchburg, VA",
    city_name: "Lynchburg",
  },
  {
    postal_code: "28147",
    full_city_name: "Salisbury, NC",
    city_name: "Salisbury",
  },
  {
    postal_code: "23223",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "73533",
    full_city_name: "Duncan, OK",
    city_name: "Duncan",
  },
  {
    postal_code: "80206",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "37922",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "34990",
    full_city_name: "Palm City, FL",
    city_name: "Palm City",
  },
  {
    postal_code: "37660",
    full_city_name: "Kingsport, TN",
    city_name: "Kingsport",
  },
  {
    postal_code: "94550",
    full_city_name: "Livermore, CA",
    city_name: "Livermore",
  },
  {
    postal_code: "45202",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "33040",
    full_city_name: "Key West, FL",
    city_name: "Key West",
  },
  {
    postal_code: "92627",
    full_city_name: "Costa Mesa, CA",
    city_name: "Costa Mesa",
  },
  {
    postal_code: "48732",
    full_city_name: "Essexville, MI",
    city_name: "Essexville",
  },
  {
    postal_code: "22655",
    full_city_name: "Stephens City, VA",
    city_name: "Stephens City",
  },
  {
    postal_code: "50316",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "37804",
    full_city_name: "Maryville, TN",
    city_name: "Maryville",
  },
  {
    postal_code: "54481",
    full_city_name: "Stevens Point, WI",
    city_name: "Stevens Point",
  },
  {
    postal_code: "73701",
    full_city_name: "Enid, OK",
    city_name: "Enid",
  },
  {
    postal_code: "19301",
    full_city_name: "Paoli, PA",
    city_name: "Paoli",
  },
  {
    postal_code: "64012",
    full_city_name: "Belton, MO",
    city_name: "Belton",
  },
  {
    postal_code: "33771",
    full_city_name: "Largo, FL",
    city_name: "Largo",
  },
  {
    postal_code: "94534",
    full_city_name: "Fairfield, CA",
    city_name: "Fairfield",
  },
  {
    postal_code: "60805",
    full_city_name: "Evergreen Park, IL",
    city_name: "Evergreen Park",
  },
  {
    postal_code: "30215",
    full_city_name: "Fayetteville, GA",
    city_name: "Fayetteville",
  },
  {
    postal_code: "11557",
    full_city_name: "Hewlett, NY",
    city_name: "Hewlett",
  },
  {
    postal_code: "64063",
    full_city_name: "Lees Summit, MO",
    city_name: "Lees Summit",
  },
  {
    postal_code: "29626",
    full_city_name: "Anderson, SC",
    city_name: "Anderson",
  },
  {
    postal_code: "29670",
    full_city_name: "Pendleton, SC",
    city_name: "Pendleton",
  },
  {
    postal_code: "78232",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "29902",
    full_city_name: "Beaufort, SC",
    city_name: "Beaufort",
  },
  {
    postal_code: "97306",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "32114",
    full_city_name: "Daytona Beach, FL",
    city_name: "Daytona Beach",
  },
  {
    postal_code: "83646",
    full_city_name: "Meridian, ID",
    city_name: "Meridian",
  },
  {
    postal_code: "27701",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "28451",
    full_city_name: "Leland, NC",
    city_name: "Leland",
  },
  {
    postal_code: "27577",
    full_city_name: "Smithfield, NC",
    city_name: "Smithfield",
  },
  {
    postal_code: "85716",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "32084",
    full_city_name: "Saint Augustine, FL",
    city_name: "Saint Augustine",
  },
  {
    postal_code: "27804",
    full_city_name: "Rocky Mount, NC",
    city_name: "Rocky Mount",
  },
  {
    postal_code: "53527",
    full_city_name: "Cottage Grove, WI",
    city_name: "Cottage Grove",
  },
  {
    postal_code: "30546",
    full_city_name: "Hiawassee, GA",
    city_name: "Hiawassee",
  },
  {
    postal_code: "53589",
    full_city_name: "Stoughton, WI",
    city_name: "Stoughton",
  },
  {
    postal_code: "48827",
    full_city_name: "Eaton Rapids, MI",
    city_name: "Eaton Rapids",
  },
  {
    postal_code: "13021",
    full_city_name: "Auburn, NY",
    city_name: "Auburn",
  },
  {
    postal_code: "21009",
    full_city_name: "Abingdon, MD",
    city_name: "Abingdon",
  },
  {
    postal_code: "55430",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "73130",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "48838",
    full_city_name: "Greenville, MI",
    city_name: "Greenville",
  },
  {
    postal_code: "21826",
    full_city_name: "Fruitland, MD",
    city_name: "Fruitland",
  },
  {
    postal_code: "20735",
    full_city_name: "Clinton, MD",
    city_name: "Clinton",
  },
  {
    postal_code: "30062",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "98520",
    full_city_name: "Aberdeen, WA",
    city_name: "Aberdeen",
  },
  {
    postal_code: "14072",
    full_city_name: "Grand Island, NY",
    city_name: "Grand Island",
  },
  {
    postal_code: "92603",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "79765",
    full_city_name: "Odessa, TX",
    city_name: "Odessa",
  },
  {
    postal_code: "22202",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "08873",
    full_city_name: "Somerset, NJ",
    city_name: "Somerset",
  },
  {
    postal_code: "86403",
    full_city_name: "Lake Havasu City, AZ",
    city_name: "Lake Havasu City",
  },
  {
    postal_code: "15017",
    full_city_name: "Bridgeville, PA",
    city_name: "Bridgeville",
  },
  {
    postal_code: "98105",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "81601",
    full_city_name: "Glenwood Springs, CO",
    city_name: "Glenwood Springs",
  },
  {
    postal_code: "74033",
    full_city_name: "Glenpool, OK",
    city_name: "Glenpool",
  },
  {
    postal_code: "98022",
    full_city_name: "Enumclaw, WA",
    city_name: "Enumclaw",
  },
  {
    postal_code: "49006",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "06239",
    full_city_name: "Danielson, CT",
    city_name: "Danielson",
  },
  {
    postal_code: "45177",
    full_city_name: "Wilmington, OH",
    city_name: "Wilmington",
  },
  {
    postal_code: "44601",
    full_city_name: "Alliance, OH",
    city_name: "Alliance",
  },
  {
    postal_code: "30318",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "11373",
    full_city_name: "Elmhurst, NY",
    city_name: "Elmhurst",
  },
  {
    postal_code: "32953",
    full_city_name: "Merritt Island, FL",
    city_name: "Merritt Island",
  },
  {
    postal_code: "93546",
    full_city_name: "Mammoth Lakes, CA",
    city_name: "Mammoth Lakes",
  },
  {
    postal_code: "94509",
    full_city_name: "Antioch, CA",
    city_name: "Antioch",
  },
  {
    postal_code: "19606",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "11730",
    full_city_name: "East Islip, NY",
    city_name: "East Islip",
  },
  {
    postal_code: "02919",
    full_city_name: "Johnston, RI",
    city_name: "Johnston",
  },
  {
    postal_code: "85008",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "80424",
    full_city_name: "Breckenridge, CO",
    city_name: "Breckenridge",
  },
  {
    postal_code: "80239",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "23024",
    full_city_name: "Bumpass, VA",
    city_name: "Bumpass",
  },
  {
    postal_code: "67235",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "97439",
    full_city_name: "Florence, OR",
    city_name: "Florence",
  },
  {
    postal_code: "92270",
    full_city_name: "Rancho Mirage, CA",
    city_name: "Rancho Mirage",
  },
  {
    postal_code: "30152",
    full_city_name: "Kennesaw, GA",
    city_name: "Kennesaw",
  },
  {
    postal_code: "92586",
    full_city_name: "Menifee, CA",
    city_name: "Menifee",
  },
  {
    postal_code: "19054",
    full_city_name: "Levittown, PA",
    city_name: "Levittown",
  },
  {
    postal_code: "28673",
    full_city_name: "Sherrills Ford, NC",
    city_name: "Sherrills Ford",
  },
  {
    postal_code: "32578",
    full_city_name: "Niceville, FL",
    city_name: "Niceville",
  },
  {
    postal_code: "89012",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "97239",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "30144",
    full_city_name: "Kennesaw, GA",
    city_name: "Kennesaw",
  },
  {
    postal_code: "35206",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "60069",
    full_city_name: "Lincolnshire, IL",
    city_name: "Lincolnshire",
  },
  {
    postal_code: "21239",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "27591",
    full_city_name: "Wendell, NC",
    city_name: "Wendell",
  },
  {
    postal_code: "44057",
    full_city_name: "Madison, OH",
    city_name: "Madison",
  },
  {
    postal_code: "85015",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "95602",
    full_city_name: "Auburn, CA",
    city_name: "Auburn",
  },
  {
    postal_code: "90004",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "21218",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "48091",
    full_city_name: "Warren, MI",
    city_name: "Warren",
  },
  {
    postal_code: "92104",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "92708",
    full_city_name: "Fountain Valley, CA",
    city_name: "Fountain Valley",
  },
  {
    postal_code: "22551",
    full_city_name: "Spotsylvania, VA",
    city_name: "Spotsylvania",
  },
  {
    postal_code: "75605",
    full_city_name: "Longview, TX",
    city_name: "Longview",
  },
  {
    postal_code: "70127",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "22601",
    full_city_name: "Winchester, VA",
    city_name: "Winchester",
  },
  {
    postal_code: "22967",
    full_city_name: "Roseland, VA",
    city_name: "Roseland",
  },
  {
    postal_code: "25801",
    full_city_name: "Beckley, WV",
    city_name: "Beckley",
  },
  {
    postal_code: "14220",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "33714",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "85202",
    full_city_name: "Mesa, AZ",
    city_name: "Mesa",
  },
  {
    postal_code: "95350",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "16046",
    full_city_name: "Mars, PA",
    city_name: "Mars",
  },
  {
    postal_code: "22556",
    full_city_name: "Stafford, VA",
    city_name: "Stafford",
  },
  {
    postal_code: "80249",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "11784",
    full_city_name: "Selden, NY",
    city_name: "Selden",
  },
  {
    postal_code: "98407",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "54952",
    full_city_name: "Menasha, WI",
    city_name: "Menasha",
  },
  {
    postal_code: "07960",
    full_city_name: "Morristown, NJ",
    city_name: "Morristown",
  },
  {
    postal_code: "34997",
    full_city_name: "Stuart, FL",
    city_name: "Stuart",
  },
  {
    postal_code: "89108",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "11754",
    full_city_name: "Kings Park, NY",
    city_name: "Kings Park",
  },
  {
    postal_code: "07078",
    full_city_name: "Short Hills, NJ",
    city_name: "Short Hills",
  },
  {
    postal_code: "14450",
    full_city_name: "Fairport, NY",
    city_name: "Fairport",
  },
  {
    postal_code: "10312",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "84118",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "80498",
    full_city_name: "Silverthorne, CO",
    city_name: "Silverthorne",
  },
  {
    postal_code: "80033",
    full_city_name: "Wheat Ridge, CO",
    city_name: "Wheat Ridge",
  },
  {
    postal_code: "28804",
    full_city_name: "Asheville, NC",
    city_name: "Asheville",
  },
  {
    postal_code: "98290",
    full_city_name: "Snohomish, WA",
    city_name: "Snohomish",
  },
  {
    postal_code: "92651",
    full_city_name: "Laguna Beach, CA",
    city_name: "Laguna Beach",
  },
  {
    postal_code: "33837",
    full_city_name: "Davenport, FL",
    city_name: "Davenport",
  },
  {
    postal_code: "46113",
    full_city_name: "Camby, IN",
    city_name: "Camby",
  },
  {
    postal_code: "29365",
    full_city_name: "Lyman, SC",
    city_name: "Lyman",
  },
  {
    postal_code: "29016",
    full_city_name: "Blythewood, SC",
    city_name: "Blythewood",
  },
  {
    postal_code: "95138",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "20008",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "55105",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "28387",
    full_city_name: "Southern Pines, NC",
    city_name: "Southern Pines",
  },
  {
    postal_code: "33412",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "49505",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "77069",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "27006",
    full_city_name: "Advance, NC",
    city_name: "Advance",
  },
  {
    postal_code: "85123",
    full_city_name: "Arizona City, AZ",
    city_name: "Arizona City",
  },
  {
    postal_code: "30134",
    full_city_name: "Douglasville, GA",
    city_name: "Douglasville",
  },
  {
    postal_code: "27312",
    full_city_name: "Pittsboro, NC",
    city_name: "Pittsboro",
  },
  {
    postal_code: "31302",
    full_city_name: "Bloomingdale, GA",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "25413",
    full_city_name: "Bunker Hill, WV",
    city_name: "Bunker Hill",
  },
  {
    postal_code: "89502",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "34695",
    full_city_name: "Safety Harbor, FL",
    city_name: "Safety Harbor",
  },
  {
    postal_code: "98108",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "79410",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "54304",
    full_city_name: "Green Bay, WI",
    city_name: "Green Bay",
  },
  {
    postal_code: "12828",
    full_city_name: "Fort Edward, NY",
    city_name: "Fort Edward",
  },
  {
    postal_code: "66109",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "93003",
    full_city_name: "Ventura, CA",
    city_name: "Ventura",
  },
  {
    postal_code: "33036",
    full_city_name: "Islamorada, FL",
    city_name: "Islamorada",
  },
  {
    postal_code: "94591",
    full_city_name: "Vallejo, CA",
    city_name: "Vallejo",
  },
  {
    postal_code: "29170",
    full_city_name: "West Columbia, SC",
    city_name: "West Columbia",
  },
  {
    postal_code: "82801",
    full_city_name: "Sheridan, WY",
    city_name: "Sheridan",
  },
  {
    postal_code: "14150",
    full_city_name: "Tonawanda, NY",
    city_name: "Tonawanda",
  },
  {
    postal_code: "78705",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "34947",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "60462",
    full_city_name: "Orland Park, IL",
    city_name: "Orland Park",
  },
  {
    postal_code: "98053",
    full_city_name: "Redmond, WA",
    city_name: "Redmond",
  },
  {
    postal_code: "30341",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "27501",
    full_city_name: "Angier, NC",
    city_name: "Angier",
  },
  {
    postal_code: "30606",
    full_city_name: "Athens, GA",
    city_name: "Athens",
  },
  {
    postal_code: "49696",
    full_city_name: "Traverse City, MI",
    city_name: "Traverse City",
  },
  {
    postal_code: "08003",
    full_city_name: "Cherry Hill, NJ",
    city_name: "Cherry Hill",
  },
  {
    postal_code: "84067",
    full_city_name: "Roy, UT",
    city_name: "Roy",
  },
  {
    postal_code: "11357",
    full_city_name: "Whitestone, NY",
    city_name: "Whitestone",
  },
  {
    postal_code: "60601",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "33598",
    full_city_name: "Wimauma, FL",
    city_name: "Wimauma",
  },
  {
    postal_code: "77539",
    full_city_name: "Dickinson, TX",
    city_name: "Dickinson",
  },
  {
    postal_code: "60062",
    full_city_name: "Northbrook, IL",
    city_name: "Northbrook",
  },
  {
    postal_code: "78163",
    full_city_name: "Bulverde, TX",
    city_name: "Bulverde",
  },
  {
    postal_code: "62711",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "80220",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "11758",
    full_city_name: "Massapequa, NY",
    city_name: "Massapequa",
  },
  {
    postal_code: "94061",
    full_city_name: "Redwood City, CA",
    city_name: "Redwood City",
  },
  {
    postal_code: "32814",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "38672",
    full_city_name: "Southaven, MS",
    city_name: "Southaven",
  },
  {
    postal_code: "65037",
    full_city_name: "Gravois Mills, MO",
    city_name: "Gravois Mills",
  },
  {
    postal_code: "32439",
    full_city_name: "Freeport, FL",
    city_name: "Freeport",
  },
  {
    postal_code: "24014",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "89431",
    full_city_name: "Sparks, NV",
    city_name: "Sparks",
  },
  {
    postal_code: "50703",
    full_city_name: "Waterloo, IA",
    city_name: "Waterloo",
  },
  {
    postal_code: "68132",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "34769",
    full_city_name: "Saint Cloud, FL",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "95129",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "80107",
    full_city_name: "Elizabeth, CO",
    city_name: "Elizabeth",
  },
  {
    postal_code: "32818",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "78028",
    full_city_name: "Kerrville, TX",
    city_name: "Kerrville",
  },
  {
    postal_code: "66216",
    full_city_name: "Shawnee, KS",
    city_name: "Shawnee",
  },
  {
    postal_code: "27909",
    full_city_name: "Elizabeth City, NC",
    city_name: "Elizabeth City",
  },
  {
    postal_code: "23060",
    full_city_name: "Glen Allen, VA",
    city_name: "Glen Allen",
  },
  {
    postal_code: "79072",
    full_city_name: "Plainview, TX",
    city_name: "Plainview",
  },
  {
    postal_code: "55308",
    full_city_name: "Becker, MN",
    city_name: "Becker",
  },
  {
    postal_code: "59601",
    full_city_name: "Helena, MT",
    city_name: "Helena",
  },
  {
    postal_code: "36111",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "67218",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "90501",
    full_city_name: "Torrance, CA",
    city_name: "Torrance",
  },
  {
    postal_code: "07054",
    full_city_name: "Parsippany, NJ",
    city_name: "Parsippany",
  },
  {
    postal_code: "66205",
    full_city_name: "Mission, KS",
    city_name: "Mission",
  },
  {
    postal_code: "19610",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "91321",
    full_city_name: "Newhall, CA",
    city_name: "Newhall",
  },
  {
    postal_code: "73069",
    full_city_name: "Norman, OK",
    city_name: "Norman",
  },
  {
    postal_code: "48224",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "70363",
    full_city_name: "Houma, LA",
    city_name: "Houma",
  },
  {
    postal_code: "21801",
    full_city_name: "Salisbury, MD",
    city_name: "Salisbury",
  },
  {
    postal_code: "11218",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "98229",
    full_city_name: "Bellingham, WA",
    city_name: "Bellingham",
  },
  {
    postal_code: "59102",
    full_city_name: "Billings, MT",
    city_name: "Billings",
  },
  {
    postal_code: "48042",
    full_city_name: "Macomb, MI",
    city_name: "Macomb",
  },
  {
    postal_code: "95758",
    full_city_name: "Elk Grove, CA",
    city_name: "Elk Grove",
  },
  {
    postal_code: "37909",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "32169",
    full_city_name: "New Smyrna Beach, FL",
    city_name: "New Smyrna Beach",
  },
  {
    postal_code: "33313",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "17325",
    full_city_name: "Gettysburg, PA",
    city_name: "Gettysburg",
  },
  {
    postal_code: "91786",
    full_city_name: "Upland, CA",
    city_name: "Upland",
  },
  {
    postal_code: "55429",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "37091",
    full_city_name: "Lewisburg, TN",
    city_name: "Lewisburg",
  },
  {
    postal_code: "92373",
    full_city_name: "Redlands, CA",
    city_name: "Redlands",
  },
  {
    postal_code: "08742",
    full_city_name: "Point Pleasant Beach, NJ",
    city_name: "Point Pleasant Beach",
  },
  {
    postal_code: "11561",
    full_city_name: "Long Beach, NY",
    city_name: "Long Beach",
  },
  {
    postal_code: "16055",
    full_city_name: "Sarver, PA",
    city_name: "Sarver",
  },
  {
    postal_code: "48223",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "83801",
    full_city_name: "Athol, ID",
    city_name: "Athol",
  },
  {
    postal_code: "18966",
    full_city_name: "Southampton, PA",
    city_name: "Southampton",
  },
  {
    postal_code: "67216",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "98367",
    full_city_name: "Port Orchard, WA",
    city_name: "Port Orchard",
  },
  {
    postal_code: "51501",
    full_city_name: "Council Bluffs, IA",
    city_name: "Council Bluffs",
  },
  {
    postal_code: "61701",
    full_city_name: "Bloomington, IL",
    city_name: "Bloomington",
  },
  {
    postal_code: "13502",
    full_city_name: "Utica, NY",
    city_name: "Utica",
  },
  {
    postal_code: "97444",
    full_city_name: "Gold Beach, OR",
    city_name: "Gold Beach",
  },
  {
    postal_code: "52101",
    full_city_name: "Decorah, IA",
    city_name: "Decorah",
  },
  {
    postal_code: "98225",
    full_city_name: "Bellingham, WA",
    city_name: "Bellingham",
  },
  {
    postal_code: "60087",
    full_city_name: "Waukegan, IL",
    city_name: "Waukegan",
  },
  {
    postal_code: "50677",
    full_city_name: "Waverly, IA",
    city_name: "Waverly",
  },
  {
    postal_code: "85541",
    full_city_name: "Payson, AZ",
    city_name: "Payson",
  },
  {
    postal_code: "77076",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "32837",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "08736",
    full_city_name: "Manasquan, NJ",
    city_name: "Manasquan",
  },
  {
    postal_code: "28787",
    full_city_name: "Weaverville, NC",
    city_name: "Weaverville",
  },
  {
    postal_code: "17222",
    full_city_name: "Fayetteville, PA",
    city_name: "Fayetteville",
  },
  {
    postal_code: "80129",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "70714",
    full_city_name: "Baker, LA",
    city_name: "Baker",
  },
  {
    postal_code: "35120",
    full_city_name: "Odenville, AL",
    city_name: "Odenville",
  },
  {
    postal_code: "72204",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "70435",
    full_city_name: "Covington, LA",
    city_name: "Covington",
  },
  {
    postal_code: "90059",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "11767",
    full_city_name: "Nesconset, NY",
    city_name: "Nesconset",
  },
  {
    postal_code: "20901",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "61520",
    full_city_name: "Canton, IL",
    city_name: "Canton",
  },
  {
    postal_code: "68107",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "28782",
    full_city_name: "Tryon, NC",
    city_name: "Tryon",
  },
  {
    postal_code: "20816",
    full_city_name: "Bethesda, MD",
    city_name: "Bethesda",
  },
  {
    postal_code: "13209",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "20854",
    full_city_name: "Potomac, MD",
    city_name: "Potomac",
  },
  {
    postal_code: "49684",
    full_city_name: "Traverse City, MI",
    city_name: "Traverse City",
  },
  {
    postal_code: "08244",
    full_city_name: "Somers Point, NJ",
    city_name: "Somers Point",
  },
  {
    postal_code: "60546",
    full_city_name: "Riverside, IL",
    city_name: "Riverside",
  },
  {
    postal_code: "95621",
    full_city_name: "Citrus Heights, CA",
    city_name: "Citrus Heights",
  },
  {
    postal_code: "80218",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "19473",
    full_city_name: "Schwenksville, PA",
    city_name: "Schwenksville",
  },
  {
    postal_code: "98006",
    full_city_name: "Bellevue, WA",
    city_name: "Bellevue",
  },
  {
    postal_code: "19115",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "63130",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "76006",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "95301",
    full_city_name: "Atwater, CA",
    city_name: "Atwater",
  },
  {
    postal_code: "02673",
    full_city_name: "West Yarmouth, MA",
    city_name: "West Yarmouth",
  },
  {
    postal_code: "92154",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "94566",
    full_city_name: "Pleasanton, CA",
    city_name: "Pleasanton",
  },
  {
    postal_code: "33410",
    full_city_name: "Palm Beach Gardens, FL",
    city_name: "Palm Beach Gardens",
  },
  {
    postal_code: "94553",
    full_city_name: "Martinez, CA",
    city_name: "Martinez",
  },
  {
    postal_code: "28110",
    full_city_name: "Monroe, NC",
    city_name: "Monroe",
  },
  {
    postal_code: "03253",
    full_city_name: "Meredith, NH",
    city_name: "Meredith",
  },
  {
    postal_code: "77015",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "50311",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "32922",
    full_city_name: "Cocoa, FL",
    city_name: "Cocoa",
  },
  {
    postal_code: "96771",
    full_city_name: "Mountain View, HI",
    city_name: "Mountain View",
  },
  {
    postal_code: "47274",
    full_city_name: "Seymour, IN",
    city_name: "Seymour",
  },
  {
    postal_code: "94585",
    full_city_name: "Suisun City, CA",
    city_name: "Suisun City",
  },
  {
    postal_code: "30257",
    full_city_name: "Milner, GA",
    city_name: "Milner",
  },
  {
    postal_code: "91364",
    full_city_name: "Woodland Hills, CA",
    city_name: "Woodland Hills",
  },
  {
    postal_code: "74361",
    full_city_name: "Pryor, OK",
    city_name: "Pryor",
  },
  {
    postal_code: "91750",
    full_city_name: "La Verne, CA",
    city_name: "La Verne",
  },
  {
    postal_code: "78721",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "98284",
    full_city_name: "Sedro Woolley, WA",
    city_name: "Sedro Woolley",
  },
  {
    postal_code: "78620",
    full_city_name: "Dripping Springs, TX",
    city_name: "Dripping Springs",
  },
  {
    postal_code: "97459",
    full_city_name: "North Bend, OR",
    city_name: "North Bend",
  },
  {
    postal_code: "14616",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "58503",
    full_city_name: "Bismarck, ND",
    city_name: "Bismarck",
  },
  {
    postal_code: "76571",
    full_city_name: "Salado, TX",
    city_name: "Salado",
  },
  {
    postal_code: "91505",
    full_city_name: "Burbank, CA",
    city_name: "Burbank",
  },
  {
    postal_code: "21085",
    full_city_name: "Joppa, MD",
    city_name: "Joppa",
  },
  {
    postal_code: "20164",
    full_city_name: "Sterling, VA",
    city_name: "Sterling",
  },
  {
    postal_code: "14217",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "94518",
    full_city_name: "Concord, CA",
    city_name: "Concord",
  },
  {
    postal_code: "19348",
    full_city_name: "Kennett Square, PA",
    city_name: "Kennett Square",
  },
  {
    postal_code: "73505",
    full_city_name: "Lawton, OK",
    city_name: "Lawton",
  },
  {
    postal_code: "44024",
    full_city_name: "Chardon, OH",
    city_name: "Chardon",
  },
  {
    postal_code: "92103",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "84403",
    full_city_name: "Ogden, UT",
    city_name: "Ogden",
  },
  {
    postal_code: "11412",
    full_city_name: "Saint Albans, NY",
    city_name: "Saint Albans",
  },
  {
    postal_code: "06614",
    full_city_name: "Stratford, CT",
    city_name: "Stratford",
  },
  {
    postal_code: "75070",
    full_city_name: "Mckinney, TX",
    city_name: "Mckinney",
  },
  {
    postal_code: "60033",
    full_city_name: "Harvard, IL",
    city_name: "Harvard",
  },
  {
    postal_code: "98106",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "85306",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "45415",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "48609",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "30240",
    full_city_name: "Lagrange, GA",
    city_name: "Lagrange",
  },
  {
    postal_code: "34240",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "76134",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "19963",
    full_city_name: "Milford, DE",
    city_name: "Milford",
  },
  {
    postal_code: "89148",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "15106",
    full_city_name: "Carnegie, PA",
    city_name: "Carnegie",
  },
  {
    postal_code: "91361",
    full_city_name: "Westlake Village, CA",
    city_name: "Westlake Village",
  },
  {
    postal_code: "25404",
    full_city_name: "Martinsburg, WV",
    city_name: "Martinsburg",
  },
  {
    postal_code: "20020",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "97601",
    full_city_name: "Klamath Falls, OR",
    city_name: "Klamath Falls",
  },
  {
    postal_code: "98604",
    full_city_name: "Battle Ground, WA",
    city_name: "Battle Ground",
  },
  {
    postal_code: "08205",
    full_city_name: "Absecon, NJ",
    city_name: "Absecon",
  },
  {
    postal_code: "36804",
    full_city_name: "Opelika, AL",
    city_name: "Opelika",
  },
  {
    postal_code: "07871",
    full_city_name: "Sparta, NJ",
    city_name: "Sparta",
  },
  {
    postal_code: "43204",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "85258",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "38372",
    full_city_name: "Savannah, TN",
    city_name: "Savannah",
  },
  {
    postal_code: "55070",
    full_city_name: "Saint Francis, MN",
    city_name: "Saint Francis",
  },
  {
    postal_code: "33004",
    full_city_name: "Dania, FL",
    city_name: "Dania",
  },
  {
    postal_code: "76550",
    full_city_name: "Lampasas, TX",
    city_name: "Lampasas",
  },
  {
    postal_code: "72736",
    full_city_name: "Gravette, AR",
    city_name: "Gravette",
  },
  {
    postal_code: "36527",
    full_city_name: "Daphne, AL",
    city_name: "Daphne",
  },
  {
    postal_code: "49221",
    full_city_name: "Adrian, MI",
    city_name: "Adrian",
  },
  {
    postal_code: "94928",
    full_city_name: "Rohnert Park, CA",
    city_name: "Rohnert Park",
  },
  {
    postal_code: "61920",
    full_city_name: "Charleston, IL",
    city_name: "Charleston",
  },
  {
    postal_code: "64157",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "71854",
    full_city_name: "Texarkana, AR",
    city_name: "Texarkana",
  },
  {
    postal_code: "06798",
    full_city_name: "Woodbury, CT",
    city_name: "Woodbury",
  },
  {
    postal_code: "95132",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "12571",
    full_city_name: "Red Hook, NY",
    city_name: "Red Hook",
  },
  {
    postal_code: "60915",
    full_city_name: "Bradley, IL",
    city_name: "Bradley",
  },
  {
    postal_code: "20148",
    full_city_name: "Ashburn, VA",
    city_name: "Ashburn",
  },
  {
    postal_code: "77414",
    full_city_name: "Bay City, TX",
    city_name: "Bay City",
  },
  {
    postal_code: "44857",
    full_city_name: "Norwalk, OH",
    city_name: "Norwalk",
  },
  {
    postal_code: "98405",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "14051",
    full_city_name: "East Amherst, NY",
    city_name: "East Amherst",
  },
  {
    postal_code: "76502",
    full_city_name: "Temple, TX",
    city_name: "Temple",
  },
  {
    postal_code: "80211",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "76044",
    full_city_name: "Godley, TX",
    city_name: "Godley",
  },
  {
    postal_code: "70301",
    full_city_name: "Thibodaux, LA",
    city_name: "Thibodaux",
  },
  {
    postal_code: "33196",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "45066",
    full_city_name: "Springboro, OH",
    city_name: "Springboro",
  },
  {
    postal_code: "75703",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "30213",
    full_city_name: "Fairburn, GA",
    city_name: "Fairburn",
  },
  {
    postal_code: "33013",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "33544",
    full_city_name: "Wesley Chapel, FL",
    city_name: "Wesley Chapel",
  },
  {
    postal_code: "63043",
    full_city_name: "Maryland Heights, MO",
    city_name: "Maryland Heights",
  },
  {
    postal_code: "89084",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "89460",
    full_city_name: "Gardnerville, NV",
    city_name: "Gardnerville",
  },
  {
    postal_code: "67601",
    full_city_name: "Hays, KS",
    city_name: "Hays",
  },
  {
    postal_code: "29927",
    full_city_name: "Hardeeville, SC",
    city_name: "Hardeeville",
  },
  {
    postal_code: "08731",
    full_city_name: "Forked River, NJ",
    city_name: "Forked River",
  },
  {
    postal_code: "91401",
    full_city_name: "Van Nuys, CA",
    city_name: "Van Nuys",
  },
  {
    postal_code: "65737",
    full_city_name: "Reeds Spring, MO",
    city_name: "Reeds Spring",
  },
  {
    postal_code: "31032",
    full_city_name: "Gray, GA",
    city_name: "Gray",
  },
  {
    postal_code: "48381",
    full_city_name: "Milford, MI",
    city_name: "Milford",
  },
  {
    postal_code: "29203",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "77494",
    full_city_name: "Katy, TX",
    city_name: "Katy",
  },
  {
    postal_code: "60559",
    full_city_name: "Westmont, IL",
    city_name: "Westmont",
  },
  {
    postal_code: "64133",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "95726",
    full_city_name: "Pollock Pines, CA",
    city_name: "Pollock Pines",
  },
  {
    postal_code: "73020",
    full_city_name: "Choctaw, OK",
    city_name: "Choctaw",
  },
  {
    postal_code: "27030",
    full_city_name: "Mount Airy, NC",
    city_name: "Mount Airy",
  },
  {
    postal_code: "78255",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "37207",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "89110",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "98513",
    full_city_name: "Olympia, WA",
    city_name: "Olympia",
  },
  {
    postal_code: "97202",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "39157",
    full_city_name: "Ridgeland, MS",
    city_name: "Ridgeland",
  },
  {
    postal_code: "80228",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "89134",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "83402",
    full_city_name: "Idaho Falls, ID",
    city_name: "Idaho Falls",
  },
  {
    postal_code: "83704",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "49301",
    full_city_name: "Ada, MI",
    city_name: "Ada",
  },
  {
    postal_code: "14094",
    full_city_name: "Lockport, NY",
    city_name: "Lockport",
  },
  {
    postal_code: "30058",
    full_city_name: "Lithonia, GA",
    city_name: "Lithonia",
  },
  {
    postal_code: "45013",
    full_city_name: "Hamilton, OH",
    city_name: "Hamilton",
  },
  {
    postal_code: "98902",
    full_city_name: "Yakima, WA",
    city_name: "Yakima",
  },
  {
    postal_code: "73142",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "70458",
    full_city_name: "Slidell, LA",
    city_name: "Slidell",
  },
  {
    postal_code: "44118",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "28801",
    full_city_name: "Asheville, NC",
    city_name: "Asheville",
  },
  {
    postal_code: "30038",
    full_city_name: "Lithonia, GA",
    city_name: "Lithonia",
  },
  {
    postal_code: "06067",
    full_city_name: "Rocky Hill, CT",
    city_name: "Rocky Hill",
  },
  {
    postal_code: "32776",
    full_city_name: "Sorrento, FL",
    city_name: "Sorrento",
  },
  {
    postal_code: "74021",
    full_city_name: "Collinsville, OK",
    city_name: "Collinsville",
  },
  {
    postal_code: "71303",
    full_city_name: "Alexandria, LA",
    city_name: "Alexandria",
  },
  {
    postal_code: "71106",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "80237",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "14456",
    full_city_name: "Geneva, NY",
    city_name: "Geneva",
  },
  {
    postal_code: "70808",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "60091",
    full_city_name: "Wilmette, IL",
    city_name: "Wilmette",
  },
  {
    postal_code: "61109",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "11779",
    full_city_name: "Ronkonkoma, NY",
    city_name: "Ronkonkoma",
  },
  {
    postal_code: "98248",
    full_city_name: "Ferndale, WA",
    city_name: "Ferndale",
  },
  {
    postal_code: "25314",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "68118",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "99654",
    full_city_name: "Wasilla, AK",
    city_name: "Wasilla",
  },
  {
    postal_code: "58103",
    full_city_name: "Fargo, ND",
    city_name: "Fargo",
  },
  {
    postal_code: "11510",
    full_city_name: "Baldwin, NY",
    city_name: "Baldwin",
  },
  {
    postal_code: "33907",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "72730",
    full_city_name: "Farmington, AR",
    city_name: "Farmington",
  },
  {
    postal_code: "48081",
    full_city_name: "Saint Clair Shores, MI",
    city_name: "Saint Clair Shores",
  },
  {
    postal_code: "44281",
    full_city_name: "Wadsworth, OH",
    city_name: "Wadsworth",
  },
  {
    postal_code: "38122",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "10011",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "77306",
    full_city_name: "Conroe, TX",
    city_name: "Conroe",
  },
  {
    postal_code: "34236",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "77380",
    full_city_name: "Spring, TX",
    city_name: "Spring",
  },
  {
    postal_code: "07746",
    full_city_name: "Marlboro, NJ",
    city_name: "Marlboro",
  },
  {
    postal_code: "98042",
    full_city_name: "Kent, WA",
    city_name: "Kent",
  },
  {
    postal_code: "84119",
    full_city_name: "West Valley City, UT",
    city_name: "West Valley City",
  },
  {
    postal_code: "28806",
    full_city_name: "Asheville, NC",
    city_name: "Asheville",
  },
  {
    postal_code: "08012",
    full_city_name: "Blackwood, NJ",
    city_name: "Blackwood",
  },
  {
    postal_code: "08758",
    full_city_name: "Waretown, NJ",
    city_name: "Waretown",
  },
  {
    postal_code: "10023",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "37027",
    full_city_name: "Brentwood, TN",
    city_name: "Brentwood",
  },
  {
    postal_code: "93455",
    full_city_name: "Santa Maria, CA",
    city_name: "Santa Maria",
  },
  {
    postal_code: "98531",
    full_city_name: "Centralia, WA",
    city_name: "Centralia",
  },
  {
    postal_code: "30017",
    full_city_name: "Grayson, GA",
    city_name: "Grayson",
  },
  {
    postal_code: "32461",
    full_city_name: "Inlet Beach, FL",
    city_name: "Inlet Beach",
  },
  {
    postal_code: "23231",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "37311",
    full_city_name: "Cleveland, TN",
    city_name: "Cleveland",
  },
  {
    postal_code: "44321",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "76259",
    full_city_name: "Ponder, TX",
    city_name: "Ponder",
  },
  {
    postal_code: "15801",
    full_city_name: "Du Bois, PA",
    city_name: "Du Bois",
  },
  {
    postal_code: "39440",
    full_city_name: "Laurel, MS",
    city_name: "Laurel",
  },
  {
    postal_code: "48706",
    full_city_name: "Bay City, MI",
    city_name: "Bay City",
  },
  {
    postal_code: "89511",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "17315",
    full_city_name: "Dover, PA",
    city_name: "Dover",
  },
  {
    postal_code: "86005",
    full_city_name: "Flagstaff, AZ",
    city_name: "Flagstaff",
  },
  {
    postal_code: "98208",
    full_city_name: "Everett, WA",
    city_name: "Everett",
  },
  {
    postal_code: "50327",
    full_city_name: "Pleasant Hill, IA",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "97381",
    full_city_name: "Silverton, OR",
    city_name: "Silverton",
  },
  {
    postal_code: "45344",
    full_city_name: "New Carlisle, OH",
    city_name: "New Carlisle",
  },
  {
    postal_code: "35160",
    full_city_name: "Talladega, AL",
    city_name: "Talladega",
  },
  {
    postal_code: "77054",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "14731",
    full_city_name: "Ellicottville, NY",
    city_name: "Ellicottville",
  },
  {
    postal_code: "11229",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "93722",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "28716",
    full_city_name: "Canton, NC",
    city_name: "Canton",
  },
  {
    postal_code: "32905",
    full_city_name: "Palm Bay, FL",
    city_name: "Palm Bay",
  },
  {
    postal_code: "60473",
    full_city_name: "South Holland, IL",
    city_name: "South Holland",
  },
  {
    postal_code: "73159",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "77058",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "19601",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "97015",
    full_city_name: "Clackamas, OR",
    city_name: "Clackamas",
  },
  {
    postal_code: "54552",
    full_city_name: "Park Falls, WI",
    city_name: "Park Falls",
  },
  {
    postal_code: "80018",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "28208",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "80916",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "29301",
    full_city_name: "Spartanburg, SC",
    city_name: "Spartanburg",
  },
  {
    postal_code: "39501",
    full_city_name: "Gulfport, MS",
    city_name: "Gulfport",
  },
  {
    postal_code: "45449",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "97402",
    full_city_name: "Eugene, OR",
    city_name: "Eugene",
  },
  {
    postal_code: "30553",
    full_city_name: "Lavonia, GA",
    city_name: "Lavonia",
  },
  {
    postal_code: "08028",
    full_city_name: "Glassboro, NJ",
    city_name: "Glassboro",
  },
  {
    postal_code: "19968",
    full_city_name: "Milton, DE",
    city_name: "Milton",
  },
  {
    postal_code: "34994",
    full_city_name: "Stuart, FL",
    city_name: "Stuart",
  },
  {
    postal_code: "73501",
    full_city_name: "Lawton, OK",
    city_name: "Lawton",
  },
  {
    postal_code: "19973",
    full_city_name: "Seaford, DE",
    city_name: "Seaford",
  },
  {
    postal_code: "44108",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "44144",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "64081",
    full_city_name: "Lees Summit, MO",
    city_name: "Lees Summit",
  },
  {
    postal_code: "95831",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "20815",
    full_city_name: "Chevy Chase, MD",
    city_name: "Chevy Chase",
  },
  {
    postal_code: "06810",
    full_city_name: "Danbury, CT",
    city_name: "Danbury",
  },
  {
    postal_code: "49008",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "38112",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "22079",
    full_city_name: "Lorton, VA",
    city_name: "Lorton",
  },
  {
    postal_code: "17603",
    full_city_name: "Lancaster, PA",
    city_name: "Lancaster",
  },
  {
    postal_code: "98375",
    full_city_name: "Puyallup, WA",
    city_name: "Puyallup",
  },
  {
    postal_code: "31220",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "29572",
    full_city_name: "Myrtle Beach, SC",
    city_name: "Myrtle Beach",
  },
  {
    postal_code: "65584",
    full_city_name: "Saint Robert, MO",
    city_name: "Saint Robert",
  },
  {
    postal_code: "44105",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "30039",
    full_city_name: "Snellville, GA",
    city_name: "Snellville",
  },
  {
    postal_code: "75154",
    full_city_name: "Red Oak, TX",
    city_name: "Red Oak",
  },
  {
    postal_code: "78114",
    full_city_name: "Floresville, TX",
    city_name: "Floresville",
  },
  {
    postal_code: "38119",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "11942",
    full_city_name: "East Quogue, NY",
    city_name: "East Quogue",
  },
  {
    postal_code: "15147",
    full_city_name: "Verona, PA",
    city_name: "Verona",
  },
  {
    postal_code: "62025",
    full_city_name: "Edwardsville, IL",
    city_name: "Edwardsville",
  },
  {
    postal_code: "92139",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "70816",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "21213",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "54235",
    full_city_name: "Sturgeon Bay, WI",
    city_name: "Sturgeon Bay",
  },
  {
    postal_code: "76643",
    full_city_name: "Hewitt, TX",
    city_name: "Hewitt",
  },
  {
    postal_code: "48312",
    full_city_name: "Sterling Heights, MI",
    city_name: "Sterling Heights",
  },
  {
    postal_code: "91016",
    full_city_name: "Monrovia, CA",
    city_name: "Monrovia",
  },
  {
    postal_code: "49512",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "33142",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "96706",
    full_city_name: "Ewa Beach, HI",
    city_name: "Ewa Beach",
  },
  {
    postal_code: "44512",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "34952",
    full_city_name: "Port Saint Lucie, FL",
    city_name: "Port Saint Lucie",
  },
  {
    postal_code: "36330",
    full_city_name: "Enterprise, AL",
    city_name: "Enterprise",
  },
  {
    postal_code: "80013",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "29455",
    full_city_name: "Johns Island, SC",
    city_name: "Johns Island",
  },
  {
    postal_code: "19810",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "48329",
    full_city_name: "Waterford, MI",
    city_name: "Waterford",
  },
  {
    postal_code: "33417",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "29154",
    full_city_name: "Sumter, SC",
    city_name: "Sumter",
  },
  {
    postal_code: "27530",
    full_city_name: "Goldsboro, NC",
    city_name: "Goldsboro",
  },
  {
    postal_code: "97301",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "89801",
    full_city_name: "Elko, NV",
    city_name: "Elko",
  },
  {
    postal_code: "78666",
    full_city_name: "San Marcos, TX",
    city_name: "San Marcos",
  },
  {
    postal_code: "25403",
    full_city_name: "Martinsburg, WV",
    city_name: "Martinsburg",
  },
  {
    postal_code: "33324",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "90049",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "87113",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "77550",
    full_city_name: "Galveston, TX",
    city_name: "Galveston",
  },
  {
    postal_code: "46219",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "80904",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "77650",
    full_city_name: "Port Bolivar, TX",
    city_name: "Port Bolivar",
  },
  {
    postal_code: "55904",
    full_city_name: "Rochester, MN",
    city_name: "Rochester",
  },
  {
    postal_code: "37127",
    full_city_name: "Murfreesboro, TN",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "60193",
    full_city_name: "Schaumburg, IL",
    city_name: "Schaumburg",
  },
  {
    postal_code: "78610",
    full_city_name: "Buda, TX",
    city_name: "Buda",
  },
  {
    postal_code: "18914",
    full_city_name: "Chalfont, PA",
    city_name: "Chalfont",
  },
  {
    postal_code: "28214",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "45050",
    full_city_name: "Monroe, OH",
    city_name: "Monroe",
  },
  {
    postal_code: "83703",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "08330",
    full_city_name: "Mays Landing, NJ",
    city_name: "Mays Landing",
  },
  {
    postal_code: "30274",
    full_city_name: "Riverdale, GA",
    city_name: "Riverdale",
  },
  {
    postal_code: "45011",
    full_city_name: "Hamilton, OH",
    city_name: "Hamilton",
  },
  {
    postal_code: "75040",
    full_city_name: "Garland, TX",
    city_name: "Garland",
  },
  {
    postal_code: "92596",
    full_city_name: "Winchester, CA",
    city_name: "Winchester",
  },
  {
    postal_code: "23226",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "89129",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "32724",
    full_city_name: "Deland, FL",
    city_name: "Deland",
  },
  {
    postal_code: "19114",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "19938",
    full_city_name: "Clayton, DE",
    city_name: "Clayton",
  },
  {
    postal_code: "18347",
    full_city_name: "Pocono Lake, PA",
    city_name: "Pocono Lake",
  },
  {
    postal_code: "60101",
    full_city_name: "Addison, IL",
    city_name: "Addison",
  },
  {
    postal_code: "33558",
    full_city_name: "Lutz, FL",
    city_name: "Lutz",
  },
  {
    postal_code: "91607",
    full_city_name: "Valley Village, CA",
    city_name: "Valley Village",
  },
  {
    postal_code: "07017",
    full_city_name: "East Orange, NJ",
    city_name: "East Orange",
  },
  {
    postal_code: "12054",
    full_city_name: "Delmar, NY",
    city_name: "Delmar",
  },
  {
    postal_code: "48035",
    full_city_name: "Clinton Township, MI",
    city_name: "Clinton Township",
  },
  {
    postal_code: "12095",
    full_city_name: "Johnstown, NY",
    city_name: "Johnstown",
  },
  {
    postal_code: "21220",
    full_city_name: "Middle River, MD",
    city_name: "Middle River",
  },
  {
    postal_code: "44202",
    full_city_name: "Aurora, OH",
    city_name: "Aurora",
  },
  {
    postal_code: "63109",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "17042",
    full_city_name: "Lebanon, PA",
    city_name: "Lebanon",
  },
  {
    postal_code: "27295",
    full_city_name: "Lexington, NC",
    city_name: "Lexington",
  },
  {
    postal_code: "30815",
    full_city_name: "Hephzibah, GA",
    city_name: "Hephzibah",
  },
  {
    postal_code: "29341",
    full_city_name: "Gaffney, SC",
    city_name: "Gaffney",
  },
  {
    postal_code: "22942",
    full_city_name: "Gordonsville, VA",
    city_name: "Gordonsville",
  },
  {
    postal_code: "44004",
    full_city_name: "Ashtabula, OH",
    city_name: "Ashtabula",
  },
  {
    postal_code: "50003",
    full_city_name: "Adel, IA",
    city_name: "Adel",
  },
  {
    postal_code: "61571",
    full_city_name: "Washington, IL",
    city_name: "Washington",
  },
  {
    postal_code: "13037",
    full_city_name: "Chittenango, NY",
    city_name: "Chittenango",
  },
  {
    postal_code: "70506",
    full_city_name: "Lafayette, LA",
    city_name: "Lafayette",
  },
  {
    postal_code: "66086",
    full_city_name: "Tonganoxie, KS",
    city_name: "Tonganoxie",
  },
  {
    postal_code: "47203",
    full_city_name: "Columbus, IN",
    city_name: "Columbus",
  },
  {
    postal_code: "32205",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "40361",
    full_city_name: "Paris, KY",
    city_name: "Paris",
  },
  {
    postal_code: "91605",
    full_city_name: "North Hollywood, CA",
    city_name: "North Hollywood",
  },
  {
    postal_code: "07701",
    full_city_name: "Red Bank, NJ",
    city_name: "Red Bank",
  },
  {
    postal_code: "06066",
    full_city_name: "Vernon Rockville, CT",
    city_name: "Vernon Rockville",
  },
  {
    postal_code: "49071",
    full_city_name: "Mattawan, MI",
    city_name: "Mattawan",
  },
  {
    postal_code: "15666",
    full_city_name: "Mount Pleasant, PA",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "33435",
    full_city_name: "Boynton Beach, FL",
    city_name: "Boynton Beach",
  },
  {
    postal_code: "76039",
    full_city_name: "Euless, TX",
    city_name: "Euless",
  },
  {
    postal_code: "60110",
    full_city_name: "Carpentersville, IL",
    city_name: "Carpentersville",
  },
  {
    postal_code: "28792",
    full_city_name: "Hendersonville, NC",
    city_name: "Hendersonville",
  },
  {
    postal_code: "21620",
    full_city_name: "Chestertown, MD",
    city_name: "Chestertown",
  },
  {
    postal_code: "85021",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "54901",
    full_city_name: "Oshkosh, WI",
    city_name: "Oshkosh",
  },
  {
    postal_code: "56401",
    full_city_name: "Brainerd, MN",
    city_name: "Brainerd",
  },
  {
    postal_code: "11742",
    full_city_name: "Holtsville, NY",
    city_name: "Holtsville",
  },
  {
    postal_code: "49024",
    full_city_name: "Portage, MI",
    city_name: "Portage",
  },
  {
    postal_code: "98418",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "32750",
    full_city_name: "Longwood, FL",
    city_name: "Longwood",
  },
  {
    postal_code: "36561",
    full_city_name: "Orange Beach, AL",
    city_name: "Orange Beach",
  },
  {
    postal_code: "17601",
    full_city_name: "Lancaster, PA",
    city_name: "Lancaster",
  },
  {
    postal_code: "05701",
    full_city_name: "Rutland, VT",
    city_name: "Rutland",
  },
  {
    postal_code: "78730",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "45240",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "90240",
    full_city_name: "Downey, CA",
    city_name: "Downey",
  },
  {
    postal_code: "32778",
    full_city_name: "Tavares, FL",
    city_name: "Tavares",
  },
  {
    postal_code: "83301",
    full_city_name: "Twin Falls, ID",
    city_name: "Twin Falls",
  },
  {
    postal_code: "11010",
    full_city_name: "Franklin Square, NY",
    city_name: "Franklin Square",
  },
  {
    postal_code: "32605",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "60523",
    full_city_name: "Oak Brook, IL",
    city_name: "Oak Brook",
  },
  {
    postal_code: "98118",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "29376",
    full_city_name: "Roebuck, SC",
    city_name: "Roebuck",
  },
  {
    postal_code: "45238",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "80547",
    full_city_name: "Timnath, CO",
    city_name: "Timnath",
  },
  {
    postal_code: "19154",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "14617",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "89142",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "33165",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "06854",
    full_city_name: "Norwalk, CT",
    city_name: "Norwalk",
  },
  {
    postal_code: "73096",
    full_city_name: "Weatherford, OK",
    city_name: "Weatherford",
  },
  {
    postal_code: "96817",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "08225",
    full_city_name: "Northfield, NJ",
    city_name: "Northfield",
  },
  {
    postal_code: "75244",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "19608",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "97814",
    full_city_name: "Baker City, OR",
    city_name: "Baker City",
  },
  {
    postal_code: "21228",
    full_city_name: "Catonsville, MD",
    city_name: "Catonsville",
  },
  {
    postal_code: "37774",
    full_city_name: "Loudon, TN",
    city_name: "Loudon",
  },
  {
    postal_code: "34986",
    full_city_name: "Port Saint Lucie, FL",
    city_name: "Port Saint Lucie",
  },
  {
    postal_code: "11933",
    full_city_name: "Calverton, NY",
    city_name: "Calverton",
  },
  {
    postal_code: "57701",
    full_city_name: "Rapid City, SD",
    city_name: "Rapid City",
  },
  {
    postal_code: "53965",
    full_city_name: "Wisconsin Dells, WI",
    city_name: "Wisconsin Dells",
  },
  {
    postal_code: "64836",
    full_city_name: "Carthage, MO",
    city_name: "Carthage",
  },
  {
    postal_code: "07735",
    full_city_name: "Keyport, NJ",
    city_name: "Keyport",
  },
  {
    postal_code: "11105",
    full_city_name: "Astoria, NY",
    city_name: "Astoria",
  },
  {
    postal_code: "81007",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "22473",
    full_city_name: "Heathsville, VA",
    city_name: "Heathsville",
  },
  {
    postal_code: "28405",
    full_city_name: "Wilmington, NC",
    city_name: "Wilmington",
  },
  {
    postal_code: "50313",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "94520",
    full_city_name: "Concord, CA",
    city_name: "Concord",
  },
  {
    postal_code: "19131",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "70710",
    full_city_name: "Addis, LA",
    city_name: "Addis",
  },
  {
    postal_code: "27954",
    full_city_name: "Manteo, NC",
    city_name: "Manteo",
  },
  {
    postal_code: "79603",
    full_city_name: "Abilene, TX",
    city_name: "Abilene",
  },
  {
    postal_code: "19010",
    full_city_name: "Bryn Mawr, PA",
    city_name: "Bryn Mawr",
  },
  {
    postal_code: "30223",
    full_city_name: "Griffin, GA",
    city_name: "Griffin",
  },
  {
    postal_code: "83201",
    full_city_name: "Pocatello, ID",
    city_name: "Pocatello",
  },
  {
    postal_code: "08610",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "11581",
    full_city_name: "Valley Stream, NY",
    city_name: "Valley Stream",
  },
  {
    postal_code: "78242",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "11764",
    full_city_name: "Miller Place, NY",
    city_name: "Miller Place",
  },
  {
    postal_code: "72015",
    full_city_name: "Benton, AR",
    city_name: "Benton",
  },
  {
    postal_code: "75143",
    full_city_name: "Kemp, TX",
    city_name: "Kemp",
  },
  {
    postal_code: "10303",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "34465",
    full_city_name: "Beverly Hills, FL",
    city_name: "Beverly Hills",
  },
  {
    postal_code: "14221",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "77510",
    full_city_name: "Santa Fe, TX",
    city_name: "Santa Fe",
  },
  {
    postal_code: "11780",
    full_city_name: "Saint James, NY",
    city_name: "Saint James",
  },
  {
    postal_code: "92308",
    full_city_name: "Apple Valley, CA",
    city_name: "Apple Valley",
  },
  {
    postal_code: "48228",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "48603",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "48045",
    full_city_name: "Harrison Township, MI",
    city_name: "Harrison Township",
  },
  {
    postal_code: "77033",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "32068",
    full_city_name: "Middleburg, FL",
    city_name: "Middleburg",
  },
  {
    postal_code: "78723",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "84604",
    full_city_name: "Provo, UT",
    city_name: "Provo",
  },
  {
    postal_code: "95124",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "87111",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "85365",
    full_city_name: "Yuma, AZ",
    city_name: "Yuma",
  },
  {
    postal_code: "32541",
    full_city_name: "Destin, FL",
    city_name: "Destin",
  },
  {
    postal_code: "49686",
    full_city_name: "Traverse City, MI",
    city_name: "Traverse City",
  },
  {
    postal_code: "67217",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "33146",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "95409",
    full_city_name: "Santa Rosa, CA",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "33825",
    full_city_name: "Avon Park, FL",
    city_name: "Avon Park",
  },
  {
    postal_code: "33020",
    full_city_name: "Hollywood, FL",
    city_name: "Hollywood",
  },
  {
    postal_code: "66030",
    full_city_name: "Gardner, KS",
    city_name: "Gardner",
  },
  {
    postal_code: "90505",
    full_city_name: "Torrance, CA",
    city_name: "Torrance",
  },
  {
    postal_code: "49083",
    full_city_name: "Richland, MI",
    city_name: "Richland",
  },
  {
    postal_code: "64801",
    full_city_name: "Joplin, MO",
    city_name: "Joplin",
  },
  {
    postal_code: "49093",
    full_city_name: "Three Rivers, MI",
    city_name: "Three Rivers",
  },
  {
    postal_code: "22801",
    full_city_name: "Harrisonburg, VA",
    city_name: "Harrisonburg",
  },
  {
    postal_code: "54843",
    full_city_name: "Hayward, WI",
    city_name: "Hayward",
  },
  {
    postal_code: "28422",
    full_city_name: "Bolivia, NC",
    city_name: "Bolivia",
  },
  {
    postal_code: "38632",
    full_city_name: "Hernando, MS",
    city_name: "Hernando",
  },
  {
    postal_code: "63303",
    full_city_name: "Saint Charles, MO",
    city_name: "Saint Charles",
  },
  {
    postal_code: "30319",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "83605",
    full_city_name: "Caldwell, ID",
    city_name: "Caldwell",
  },
  {
    postal_code: "30204",
    full_city_name: "Barnesville, GA",
    city_name: "Barnesville",
  },
  {
    postal_code: "63135",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "98271",
    full_city_name: "Marysville, WA",
    city_name: "Marysville",
  },
  {
    postal_code: "54915",
    full_city_name: "Appleton, WI",
    city_name: "Appleton",
  },
  {
    postal_code: "54115",
    full_city_name: "De Pere, WI",
    city_name: "De Pere",
  },
  {
    postal_code: "32127",
    full_city_name: "Port Orange, FL",
    city_name: "Port Orange",
  },
  {
    postal_code: "20878",
    full_city_name: "Gaithersburg, MD",
    city_name: "Gaithersburg",
  },
  {
    postal_code: "77068",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "11743",
    full_city_name: "Huntington, NY",
    city_name: "Huntington",
  },
  {
    postal_code: "61938",
    full_city_name: "Mattoon, IL",
    city_name: "Mattoon",
  },
  {
    postal_code: "58501",
    full_city_name: "Bismarck, ND",
    city_name: "Bismarck",
  },
  {
    postal_code: "23005",
    full_city_name: "Ashland, VA",
    city_name: "Ashland",
  },
  {
    postal_code: "98133",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "95928",
    full_city_name: "Chico, CA",
    city_name: "Chico",
  },
  {
    postal_code: "21237",
    full_city_name: "Rosedale, MD",
    city_name: "Rosedale",
  },
  {
    postal_code: "85296",
    full_city_name: "Gilbert, AZ",
    city_name: "Gilbert",
  },
  {
    postal_code: "93277",
    full_city_name: "Visalia, CA",
    city_name: "Visalia",
  },
  {
    postal_code: "33125",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "40165",
    full_city_name: "Shepherdsville, KY",
    city_name: "Shepherdsville",
  },
  {
    postal_code: "23832",
    full_city_name: "Chesterfield, VA",
    city_name: "Chesterfield",
  },
  {
    postal_code: "98512",
    full_city_name: "Olympia, WA",
    city_name: "Olympia",
  },
  {
    postal_code: "51503",
    full_city_name: "Council Bluffs, IA",
    city_name: "Council Bluffs",
  },
  {
    postal_code: "33161",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "10024",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "30157",
    full_city_name: "Dallas, GA",
    city_name: "Dallas",
  },
  {
    postal_code: "06615",
    full_city_name: "Stratford, CT",
    city_name: "Stratford",
  },
  {
    postal_code: "64850",
    full_city_name: "Neosho, MO",
    city_name: "Neosho",
  },
  {
    postal_code: "32569",
    full_city_name: "Mary Esther, FL",
    city_name: "Mary Esther",
  },
  {
    postal_code: "20602",
    full_city_name: "Waldorf, MD",
    city_name: "Waldorf",
  },
  {
    postal_code: "75707",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "14120",
    full_city_name: "North Tonawanda, NY",
    city_name: "North Tonawanda",
  },
  {
    postal_code: "56071",
    full_city_name: "New Prague, MN",
    city_name: "New Prague",
  },
  {
    postal_code: "72404",
    full_city_name: "Jonesboro, AR",
    city_name: "Jonesboro",
  },
  {
    postal_code: "85303",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "80927",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "36207",
    full_city_name: "Anniston, AL",
    city_name: "Anniston",
  },
  {
    postal_code: "97321",
    full_city_name: "Albany, OR",
    city_name: "Albany",
  },
  {
    postal_code: "33160",
    full_city_name: "North Miami Beach, FL",
    city_name: "North Miami Beach",
  },
  {
    postal_code: "28715",
    full_city_name: "Candler, NC",
    city_name: "Candler",
  },
  {
    postal_code: "32312",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "77807",
    full_city_name: "Bryan, TX",
    city_name: "Bryan",
  },
  {
    postal_code: "78589",
    full_city_name: "San Juan, TX",
    city_name: "San Juan",
  },
  {
    postal_code: "37343",
    full_city_name: "Hixson, TN",
    city_name: "Hixson",
  },
  {
    postal_code: "65810",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "92020",
    full_city_name: "El Cajon, CA",
    city_name: "El Cajon",
  },
  {
    postal_code: "28262",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "87122",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "25411",
    full_city_name: "Berkeley Springs, WV",
    city_name: "Berkeley Springs",
  },
  {
    postal_code: "61401",
    full_city_name: "Galesburg, IL",
    city_name: "Galesburg",
  },
  {
    postal_code: "99163",
    full_city_name: "Pullman, WA",
    city_name: "Pullman",
  },
  {
    postal_code: "78152",
    full_city_name: "Saint Hedwig, TX",
    city_name: "Saint Hedwig",
  },
  {
    postal_code: "13492",
    full_city_name: "Whitesboro, NY",
    city_name: "Whitesboro",
  },
  {
    postal_code: "06001",
    full_city_name: "Avon, CT",
    city_name: "Avon",
  },
  {
    postal_code: "02642",
    full_city_name: "Eastham, MA",
    city_name: "Eastham",
  },
  {
    postal_code: "98445",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "60169",
    full_city_name: "Hoffman Estates, IL",
    city_name: "Hoffman Estates",
  },
  {
    postal_code: "85009",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "33407",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "86409",
    full_city_name: "Kingman, AZ",
    city_name: "Kingman",
  },
  {
    postal_code: "33704",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "44805",
    full_city_name: "Ashland, OH",
    city_name: "Ashland",
  },
  {
    postal_code: "90680",
    full_city_name: "Stanton, CA",
    city_name: "Stanton",
  },
  {
    postal_code: "92504",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "85262",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "47303",
    full_city_name: "Muncie, IN",
    city_name: "Muncie",
  },
  {
    postal_code: "98685",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "55427",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "11249",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "95383",
    full_city_name: "Twain Harte, CA",
    city_name: "Twain Harte",
  },
  {
    postal_code: "01876",
    full_city_name: "Tewksbury, MA",
    city_name: "Tewksbury",
  },
  {
    postal_code: "29710",
    full_city_name: "Clover, SC",
    city_name: "Clover",
  },
  {
    postal_code: "28134",
    full_city_name: "Pineville, NC",
    city_name: "Pineville",
  },
  {
    postal_code: "37862",
    full_city_name: "Sevierville, TN",
    city_name: "Sevierville",
  },
  {
    postal_code: "46514",
    full_city_name: "Elkhart, IN",
    city_name: "Elkhart",
  },
  {
    postal_code: "08079",
    full_city_name: "Salem, NJ",
    city_name: "Salem",
  },
  {
    postal_code: "79720",
    full_city_name: "Big Spring, TX",
    city_name: "Big Spring",
  },
  {
    postal_code: "92585",
    full_city_name: "Menifee, CA",
    city_name: "Menifee",
  },
  {
    postal_code: "76234",
    full_city_name: "Decatur, TX",
    city_name: "Decatur",
  },
  {
    postal_code: "55372",
    full_city_name: "Prior Lake, MN",
    city_name: "Prior Lake",
  },
  {
    postal_code: "97223",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "38104",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "94561",
    full_city_name: "Oakley, CA",
    city_name: "Oakley",
  },
  {
    postal_code: "48336",
    full_city_name: "Farmington, MI",
    city_name: "Farmington",
  },
  {
    postal_code: "93035",
    full_city_name: "Oxnard, CA",
    city_name: "Oxnard",
  },
  {
    postal_code: "37205",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "60646",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "07419",
    full_city_name: "Hamburg, NJ",
    city_name: "Hamburg",
  },
  {
    postal_code: "35475",
    full_city_name: "Northport, AL",
    city_name: "Northport",
  },
  {
    postal_code: "33884",
    full_city_name: "Winter Haven, FL",
    city_name: "Winter Haven",
  },
  {
    postal_code: "79762",
    full_city_name: "Odessa, TX",
    city_name: "Odessa",
  },
  {
    postal_code: "98020",
    full_city_name: "Edmonds, WA",
    city_name: "Edmonds",
  },
  {
    postal_code: "39560",
    full_city_name: "Long Beach, MS",
    city_name: "Long Beach",
  },
  {
    postal_code: "89441",
    full_city_name: "Sparks, NV",
    city_name: "Sparks",
  },
  {
    postal_code: "07480",
    full_city_name: "West Milford, NJ",
    city_name: "West Milford",
  },
  {
    postal_code: "97055",
    full_city_name: "Sandy, OR",
    city_name: "Sandy",
  },
  {
    postal_code: "74133",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "80126",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "90220",
    full_city_name: "Compton, CA",
    city_name: "Compton",
  },
  {
    postal_code: "27514",
    full_city_name: "Chapel Hill, NC",
    city_name: "Chapel Hill",
  },
  {
    postal_code: "84040",
    full_city_name: "Layton, UT",
    city_name: "Layton",
  },
  {
    postal_code: "78121",
    full_city_name: "La Vernia, TX",
    city_name: "La Vernia",
  },
  {
    postal_code: "81212",
    full_city_name: "Canon City, CO",
    city_name: "Canon City",
  },
  {
    postal_code: "78248",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "76207",
    full_city_name: "Denton, TX",
    city_name: "Denton",
  },
  {
    postal_code: "49525",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "78411",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "38115",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "81224",
    full_city_name: "Crested Butte, CO",
    city_name: "Crested Butte",
  },
  {
    postal_code: "22205",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "11426",
    full_city_name: "Bellerose, NY",
    city_name: "Bellerose",
  },
  {
    postal_code: "28751",
    full_city_name: "Maggie Valley, NC",
    city_name: "Maggie Valley",
  },
  {
    postal_code: "08054",
    full_city_name: "Mount Laurel, NJ",
    city_name: "Mount Laurel",
  },
  {
    postal_code: "02909",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "95032",
    full_city_name: "Los Gatos, CA",
    city_name: "Los Gatos",
  },
  {
    postal_code: "37083",
    full_city_name: "Lafayette, TN",
    city_name: "Lafayette",
  },
  {
    postal_code: "70647",
    full_city_name: "Iowa, LA",
    city_name: "Iowa",
  },
  {
    postal_code: "67530",
    full_city_name: "Great Bend, KS",
    city_name: "Great Bend",
  },
  {
    postal_code: "37217",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "59912",
    full_city_name: "Columbia Falls, MT",
    city_name: "Columbia Falls",
  },
  {
    postal_code: "07731",
    full_city_name: "Howell, NJ",
    city_name: "Howell",
  },
  {
    postal_code: "11967",
    full_city_name: "Shirley, NY",
    city_name: "Shirley",
  },
  {
    postal_code: "54220",
    full_city_name: "Manitowoc, WI",
    city_name: "Manitowoc",
  },
  {
    postal_code: "30326",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "78759",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "85374",
    full_city_name: "Surprise, AZ",
    city_name: "Surprise",
  },
  {
    postal_code: "44067",
    full_city_name: "Northfield, OH",
    city_name: "Northfield",
  },
  {
    postal_code: "48236",
    full_city_name: "Grosse Pointe, MI",
    city_name: "Grosse Pointe",
  },
  {
    postal_code: "13208",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "98125",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "90241",
    full_city_name: "Downey, CA",
    city_name: "Downey",
  },
  {
    postal_code: "85023",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "88201",
    full_city_name: "Roswell, NM",
    city_name: "Roswell",
  },
  {
    postal_code: "70634",
    full_city_name: "Deridder, LA",
    city_name: "Deridder",
  },
  {
    postal_code: "30736",
    full_city_name: "Ringgold, GA",
    city_name: "Ringgold",
  },
  {
    postal_code: "54935",
    full_city_name: "Fond Du Lac, WI",
    city_name: "Fond Du Lac",
  },
  {
    postal_code: "55412",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "34202",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "95467",
    full_city_name: "Hidden Valley Lake, CA",
    city_name: "Hidden Valley Lake",
  },
  {
    postal_code: "80751",
    full_city_name: "Sterling, CO",
    city_name: "Sterling",
  },
  {
    postal_code: "44106",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "65072",
    full_city_name: "Rocky Mount, MO",
    city_name: "Rocky Mount",
  },
  {
    postal_code: "33441",
    full_city_name: "Deerfield Beach, FL",
    city_name: "Deerfield Beach",
  },
  {
    postal_code: "55408",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "22206",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "39402",
    full_city_name: "Hattiesburg, MS",
    city_name: "Hattiesburg",
  },
  {
    postal_code: "73107",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "29492",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "79927",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "76179",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "98221",
    full_city_name: "Anacortes, WA",
    city_name: "Anacortes",
  },
  {
    postal_code: "49442",
    full_city_name: "Muskegon, MI",
    city_name: "Muskegon",
  },
  {
    postal_code: "33801",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "45005",
    full_city_name: "Franklin, OH",
    city_name: "Franklin",
  },
  {
    postal_code: "29115",
    full_city_name: "Orangeburg, SC",
    city_name: "Orangeburg",
  },
  {
    postal_code: "85546",
    full_city_name: "Safford, AZ",
    city_name: "Safford",
  },
  {
    postal_code: "92345",
    full_city_name: "Hesperia, CA",
    city_name: "Hesperia",
  },
  {
    postal_code: "49085",
    full_city_name: "Saint Joseph, MI",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "76148",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "75068",
    full_city_name: "Little Elm, TX",
    city_name: "Little Elm",
  },
  {
    postal_code: "60435",
    full_city_name: "Joliet, IL",
    city_name: "Joliet",
  },
  {
    postal_code: "19958",
    full_city_name: "Lewes, DE",
    city_name: "Lewes",
  },
  {
    postal_code: "74070",
    full_city_name: "Skiatook, OK",
    city_name: "Skiatook",
  },
  {
    postal_code: "34604",
    full_city_name: "Brooksville, FL",
    city_name: "Brooksville",
  },
  {
    postal_code: "80112",
    full_city_name: "Englewood, CO",
    city_name: "Englewood",
  },
  {
    postal_code: "77045",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "72704",
    full_city_name: "Fayetteville, AR",
    city_name: "Fayetteville",
  },
  {
    postal_code: "06460",
    full_city_name: "Milford, CT",
    city_name: "Milford",
  },
  {
    postal_code: "33437",
    full_city_name: "Boynton Beach, FL",
    city_name: "Boynton Beach",
  },
  {
    postal_code: "49456",
    full_city_name: "Spring Lake, MI",
    city_name: "Spring Lake",
  },
  {
    postal_code: "12498",
    full_city_name: "Woodstock, NY",
    city_name: "Woodstock",
  },
  {
    postal_code: "20017",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "48439",
    full_city_name: "Grand Blanc, MI",
    city_name: "Grand Blanc",
  },
  {
    postal_code: "19148",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "77327",
    full_city_name: "Cleveland, TX",
    city_name: "Cleveland",
  },
  {
    postal_code: "73012",
    full_city_name: "Edmond, OK",
    city_name: "Edmond",
  },
  {
    postal_code: "48843",
    full_city_name: "Howell, MI",
    city_name: "Howell",
  },
  {
    postal_code: "30233",
    full_city_name: "Jackson, GA",
    city_name: "Jackson",
  },
  {
    postal_code: "98004",
    full_city_name: "Bellevue, WA",
    city_name: "Bellevue",
  },
  {
    postal_code: "83455",
    full_city_name: "Victor, ID",
    city_name: "Victor",
  },
  {
    postal_code: "21214",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "45403",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "06062",
    full_city_name: "Plainville, CT",
    city_name: "Plainville",
  },
  {
    postal_code: "63601",
    full_city_name: "Park Hills, MO",
    city_name: "Park Hills",
  },
  {
    postal_code: "20720",
    full_city_name: "Bowie, MD",
    city_name: "Bowie",
  },
  {
    postal_code: "07869",
    full_city_name: "Randolph, NJ",
    city_name: "Randolph",
  },
  {
    postal_code: "97062",
    full_city_name: "Tualatin, OR",
    city_name: "Tualatin",
  },
  {
    postal_code: "12015",
    full_city_name: "Athens, NY",
    city_name: "Athens",
  },
  {
    postal_code: "98922",
    full_city_name: "Cle Elum, WA",
    city_name: "Cle Elum",
  },
  {
    postal_code: "11385",
    full_city_name: "Ridgewood, NY",
    city_name: "Ridgewood",
  },
  {
    postal_code: "53916",
    full_city_name: "Beaver Dam, WI",
    city_name: "Beaver Dam",
  },
  {
    postal_code: "78676",
    full_city_name: "Wimberley, TX",
    city_name: "Wimberley",
  },
  {
    postal_code: "50138",
    full_city_name: "Knoxville, IA",
    city_name: "Knoxville",
  },
  {
    postal_code: "38004",
    full_city_name: "Atoka, TN",
    city_name: "Atoka",
  },
  {
    postal_code: "15228",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "07733",
    full_city_name: "Holmdel, NJ",
    city_name: "Holmdel",
  },
  {
    postal_code: "90002",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "48309",
    full_city_name: "Rochester, MI",
    city_name: "Rochester",
  },
  {
    postal_code: "93611",
    full_city_name: "Clovis, CA",
    city_name: "Clovis",
  },
  {
    postal_code: "84332",
    full_city_name: "Providence, UT",
    city_name: "Providence",
  },
  {
    postal_code: "87105",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "44125",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "29063",
    full_city_name: "Irmo, SC",
    city_name: "Irmo",
  },
  {
    postal_code: "67401",
    full_city_name: "Salina, KS",
    city_name: "Salina",
  },
  {
    postal_code: "13413",
    full_city_name: "New Hartford, NY",
    city_name: "New Hartford",
  },
  {
    postal_code: "41075",
    full_city_name: "Fort Thomas, KY",
    city_name: "Fort Thomas",
  },
  {
    postal_code: "20853",
    full_city_name: "Rockville, MD",
    city_name: "Rockville",
  },
  {
    postal_code: "06708",
    full_city_name: "Waterbury, CT",
    city_name: "Waterbury",
  },
  {
    postal_code: "95603",
    full_city_name: "Auburn, CA",
    city_name: "Auburn",
  },
  {
    postal_code: "77459",
    full_city_name: "Missouri City, TX",
    city_name: "Missouri City",
  },
  {
    postal_code: "43221",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "80214",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "44143",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "92325",
    full_city_name: "Crestline, CA",
    city_name: "Crestline",
  },
  {
    postal_code: "95341",
    full_city_name: "Merced, CA",
    city_name: "Merced",
  },
  {
    postal_code: "71913",
    full_city_name: "Hot Springs National Park, AR",
    city_name: "Hot Springs National Park",
  },
  {
    postal_code: "77802",
    full_city_name: "Bryan, TX",
    city_name: "Bryan",
  },
  {
    postal_code: "27607",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "92011",
    full_city_name: "Carlsbad, CA",
    city_name: "Carlsbad",
  },
  {
    postal_code: "83318",
    full_city_name: "Burley, ID",
    city_name: "Burley",
  },
  {
    postal_code: "95695",
    full_city_name: "Woodland, CA",
    city_name: "Woodland",
  },
  {
    postal_code: "49426",
    full_city_name: "Hudsonville, MI",
    city_name: "Hudsonville",
  },
  {
    postal_code: "43219",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "58401",
    full_city_name: "Jamestown, ND",
    city_name: "Jamestown",
  },
  {
    postal_code: "48324",
    full_city_name: "West Bloomfield, MI",
    city_name: "West Bloomfield",
  },
  {
    postal_code: "49855",
    full_city_name: "Marquette, MI",
    city_name: "Marquette",
  },
  {
    postal_code: "08361",
    full_city_name: "Vineland, NJ",
    city_name: "Vineland",
  },
  {
    postal_code: "56304",
    full_city_name: "Saint Cloud, MN",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "20194",
    full_city_name: "Reston, VA",
    city_name: "Reston",
  },
  {
    postal_code: "15215",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "98032",
    full_city_name: "Kent, WA",
    city_name: "Kent",
  },
  {
    postal_code: "32653",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "34461",
    full_city_name: "Lecanto, FL",
    city_name: "Lecanto",
  },
  {
    postal_code: "13619",
    full_city_name: "Carthage, NY",
    city_name: "Carthage",
  },
  {
    postal_code: "33304",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "60201",
    full_city_name: "Evanston, IL",
    city_name: "Evanston",
  },
  {
    postal_code: "54904",
    full_city_name: "Oshkosh, WI",
    city_name: "Oshkosh",
  },
  {
    postal_code: "49321",
    full_city_name: "Comstock Park, MI",
    city_name: "Comstock Park",
  },
  {
    postal_code: "74467",
    full_city_name: "Wagoner, OK",
    city_name: "Wagoner",
  },
  {
    postal_code: "47302",
    full_city_name: "Muncie, IN",
    city_name: "Muncie",
  },
  {
    postal_code: "08620",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "48105",
    full_city_name: "Ann Arbor, MI",
    city_name: "Ann Arbor",
  },
  {
    postal_code: "55419",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "92120",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "23103",
    full_city_name: "Manakin Sabot, VA",
    city_name: "Manakin Sabot",
  },
  {
    postal_code: "60463",
    full_city_name: "Palos Heights, IL",
    city_name: "Palos Heights",
  },
  {
    postal_code: "55038",
    full_city_name: "Hugo, MN",
    city_name: "Hugo",
  },
  {
    postal_code: "80654",
    full_city_name: "Wiggins, CO",
    city_name: "Wiggins",
  },
  {
    postal_code: "49546",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "30043",
    full_city_name: "Lawrenceville, GA",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "94070",
    full_city_name: "San Carlos, CA",
    city_name: "San Carlos",
  },
  {
    postal_code: "86442",
    full_city_name: "Bullhead City, AZ",
    city_name: "Bullhead City",
  },
  {
    postal_code: "80513",
    full_city_name: "Berthoud, CO",
    city_name: "Berthoud",
  },
  {
    postal_code: "27576",
    full_city_name: "Selma, NC",
    city_name: "Selma",
  },
  {
    postal_code: "48178",
    full_city_name: "South Lyon, MI",
    city_name: "South Lyon",
  },
  {
    postal_code: "55068",
    full_city_name: "Rosemount, MN",
    city_name: "Rosemount",
  },
  {
    postal_code: "35071",
    full_city_name: "Gardendale, AL",
    city_name: "Gardendale",
  },
  {
    postal_code: "36093",
    full_city_name: "Wetumpka, AL",
    city_name: "Wetumpka",
  },
  {
    postal_code: "97520",
    full_city_name: "Ashland, OR",
    city_name: "Ashland",
  },
  {
    postal_code: "60048",
    full_city_name: "Libertyville, IL",
    city_name: "Libertyville",
  },
  {
    postal_code: "66762",
    full_city_name: "Pittsburg, KS",
    city_name: "Pittsburg",
  },
  {
    postal_code: "63112",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "46952",
    full_city_name: "Marion, IN",
    city_name: "Marion",
  },
  {
    postal_code: "15234",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "60615",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "28513",
    full_city_name: "Ayden, NC",
    city_name: "Ayden",
  },
  {
    postal_code: "91911",
    full_city_name: "Chula Vista, CA",
    city_name: "Chula Vista",
  },
  {
    postal_code: "12189",
    full_city_name: "Watervliet, NY",
    city_name: "Watervliet",
  },
  {
    postal_code: "14006",
    full_city_name: "Angola, NY",
    city_name: "Angola",
  },
  {
    postal_code: "83607",
    full_city_name: "Caldwell, ID",
    city_name: "Caldwell",
  },
  {
    postal_code: "76537",
    full_city_name: "Jarrell, TX",
    city_name: "Jarrell",
  },
  {
    postal_code: "19090",
    full_city_name: "Willow Grove, PA",
    city_name: "Willow Grove",
  },
  {
    postal_code: "94571",
    full_city_name: "Rio Vista, CA",
    city_name: "Rio Vista",
  },
  {
    postal_code: "98116",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "48375",
    full_city_name: "Novi, MI",
    city_name: "Novi",
  },
  {
    postal_code: "29045",
    full_city_name: "Elgin, SC",
    city_name: "Elgin",
  },
  {
    postal_code: "24201",
    full_city_name: "Bristol, VA",
    city_name: "Bristol",
  },
  {
    postal_code: "87710",
    full_city_name: "Angel Fire, NM",
    city_name: "Angel Fire",
  },
  {
    postal_code: "89113",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "12144",
    full_city_name: "Rensselaer, NY",
    city_name: "Rensselaer",
  },
  {
    postal_code: "42503",
    full_city_name: "Somerset, KY",
    city_name: "Somerset",
  },
  {
    postal_code: "64156",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "80122",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "91767",
    full_city_name: "Pomona, CA",
    city_name: "Pomona",
  },
  {
    postal_code: "94066",
    full_city_name: "San Bruno, CA",
    city_name: "San Bruno",
  },
  {
    postal_code: "19078",
    full_city_name: "Ridley Park, PA",
    city_name: "Ridley Park",
  },
  {
    postal_code: "27407",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "43125",
    full_city_name: "Groveport, OH",
    city_name: "Groveport",
  },
  {
    postal_code: "68128",
    full_city_name: "La Vista, NE",
    city_name: "La Vista",
  },
  {
    postal_code: "76903",
    full_city_name: "San Angelo, TX",
    city_name: "San Angelo",
  },
  {
    postal_code: "44126",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "19707",
    full_city_name: "Hockessin, DE",
    city_name: "Hockessin",
  },
  {
    postal_code: "49341",
    full_city_name: "Rockford, MI",
    city_name: "Rockford",
  },
  {
    postal_code: "55804",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "75212",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "77028",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "48836",
    full_city_name: "Fowlerville, MI",
    city_name: "Fowlerville",
  },
  {
    postal_code: "56073",
    full_city_name: "New Ulm, MN",
    city_name: "New Ulm",
  },
  {
    postal_code: "45750",
    full_city_name: "Marietta, OH",
    city_name: "Marietta",
  },
  {
    postal_code: "53538",
    full_city_name: "Fort Atkinson, WI",
    city_name: "Fort Atkinson",
  },
  {
    postal_code: "29302",
    full_city_name: "Spartanburg, SC",
    city_name: "Spartanburg",
  },
  {
    postal_code: "38343",
    full_city_name: "Humboldt, TN",
    city_name: "Humboldt",
  },
  {
    postal_code: "89149",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "80134",
    full_city_name: "Parker, CO",
    city_name: "Parker",
  },
  {
    postal_code: "93552",
    full_city_name: "Palmdale, CA",
    city_name: "Palmdale",
  },
  {
    postal_code: "44830",
    full_city_name: "Fostoria, OH",
    city_name: "Fostoria",
  },
  {
    postal_code: "13104",
    full_city_name: "Manlius, NY",
    city_name: "Manlius",
  },
  {
    postal_code: "38501",
    full_city_name: "Cookeville, TN",
    city_name: "Cookeville",
  },
  {
    postal_code: "46825",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "19970",
    full_city_name: "Ocean View, DE",
    city_name: "Ocean View",
  },
  {
    postal_code: "11413",
    full_city_name: "Springfield Gardens, NY",
    city_name: "Springfield Gardens",
  },
  {
    postal_code: "95210",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "72019",
    full_city_name: "Benton, AR",
    city_name: "Benton",
  },
  {
    postal_code: "95023",
    full_city_name: "Hollister, CA",
    city_name: "Hollister",
  },
  {
    postal_code: "32904",
    full_city_name: "Melbourne, FL",
    city_name: "Melbourne",
  },
  {
    postal_code: "49735",
    full_city_name: "Gaylord, MI",
    city_name: "Gaylord",
  },
  {
    postal_code: "98012",
    full_city_name: "Bothell, WA",
    city_name: "Bothell",
  },
  {
    postal_code: "85037",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "75771",
    full_city_name: "Lindale, TX",
    city_name: "Lindale",
  },
  {
    postal_code: "75766",
    full_city_name: "Jacksonville, TX",
    city_name: "Jacksonville",
  },
  {
    postal_code: "97078",
    full_city_name: "Beaverton, OR",
    city_name: "Beaverton",
  },
  {
    postal_code: "75773",
    full_city_name: "Mineola, TX",
    city_name: "Mineola",
  },
  {
    postal_code: "28539",
    full_city_name: "Hubert, NC",
    city_name: "Hubert",
  },
  {
    postal_code: "98030",
    full_city_name: "Kent, WA",
    city_name: "Kent",
  },
  {
    postal_code: "49849",
    full_city_name: "Ishpeming, MI",
    city_name: "Ishpeming",
  },
  {
    postal_code: "27546",
    full_city_name: "Lillington, NC",
    city_name: "Lillington",
  },
  {
    postal_code: "15122",
    full_city_name: "West Mifflin, PA",
    city_name: "West Mifflin",
  },
  {
    postal_code: "72734",
    full_city_name: "Gentry, AR",
    city_name: "Gentry",
  },
  {
    postal_code: "75662",
    full_city_name: "Kilgore, TX",
    city_name: "Kilgore",
  },
  {
    postal_code: "95829",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "64030",
    full_city_name: "Grandview, MO",
    city_name: "Grandview",
  },
  {
    postal_code: "48442",
    full_city_name: "Holly, MI",
    city_name: "Holly",
  },
  {
    postal_code: "89032",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "44460",
    full_city_name: "Salem, OH",
    city_name: "Salem",
  },
  {
    postal_code: "84606",
    full_city_name: "Provo, UT",
    city_name: "Provo",
  },
  {
    postal_code: "08104",
    full_city_name: "Camden, NJ",
    city_name: "Camden",
  },
  {
    postal_code: "20871",
    full_city_name: "Clarksburg, MD",
    city_name: "Clarksburg",
  },
  {
    postal_code: "21209",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "92320",
    full_city_name: "Calimesa, CA",
    city_name: "Calimesa",
  },
  {
    postal_code: "97007",
    full_city_name: "Beaverton, OR",
    city_name: "Beaverton",
  },
  {
    postal_code: "02879",
    full_city_name: "Wakefield, RI",
    city_name: "Wakefield",
  },
  {
    postal_code: "30741",
    full_city_name: "Rossville, GA",
    city_name: "Rossville",
  },
  {
    postal_code: "73160",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "77023",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "55021",
    full_city_name: "Faribault, MN",
    city_name: "Faribault",
  },
  {
    postal_code: "87102",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "60426",
    full_city_name: "Harvey, IL",
    city_name: "Harvey",
  },
  {
    postal_code: "29651",
    full_city_name: "Greer, SC",
    city_name: "Greer",
  },
  {
    postal_code: "96756",
    full_city_name: "Koloa, HI",
    city_name: "Koloa",
  },
  {
    postal_code: "76014",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "21875",
    full_city_name: "Delmar, MD",
    city_name: "Delmar",
  },
  {
    postal_code: "60619",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "07901",
    full_city_name: "Summit, NJ",
    city_name: "Summit",
  },
  {
    postal_code: "21222",
    full_city_name: "Dundalk, MD",
    city_name: "Dundalk",
  },
  {
    postal_code: "73044",
    full_city_name: "Guthrie, OK",
    city_name: "Guthrie",
  },
  {
    postal_code: "43211",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "99354",
    full_city_name: "Richland, WA",
    city_name: "Richland",
  },
  {
    postal_code: "76008",
    full_city_name: "Aledo, TX",
    city_name: "Aledo",
  },
  {
    postal_code: "85901",
    full_city_name: "Show Low, AZ",
    city_name: "Show Low",
  },
  {
    postal_code: "46041",
    full_city_name: "Frankfort, IN",
    city_name: "Frankfort",
  },
  {
    postal_code: "86001",
    full_city_name: "Flagstaff, AZ",
    city_name: "Flagstaff",
  },
  {
    postal_code: "30183",
    full_city_name: "Waleska, GA",
    city_name: "Waleska",
  },
  {
    postal_code: "48315",
    full_city_name: "Utica, MI",
    city_name: "Utica",
  },
  {
    postal_code: "35043",
    full_city_name: "Chelsea, AL",
    city_name: "Chelsea",
  },
  {
    postal_code: "29201",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "62522",
    full_city_name: "Decatur, IL",
    city_name: "Decatur",
  },
  {
    postal_code: "32826",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "51401",
    full_city_name: "Carroll, IA",
    city_name: "Carroll",
  },
  {
    postal_code: "44221",
    full_city_name: "Cuyahoga Falls, OH",
    city_name: "Cuyahoga Falls",
  },
  {
    postal_code: "99114",
    full_city_name: "Colville, WA",
    city_name: "Colville",
  },
  {
    postal_code: "06716",
    full_city_name: "Wolcott, CT",
    city_name: "Wolcott",
  },
  {
    postal_code: "80487",
    full_city_name: "Steamboat Springs, CO",
    city_name: "Steamboat Springs",
  },
  {
    postal_code: "28409",
    full_city_name: "Wilmington, NC",
    city_name: "Wilmington",
  },
  {
    postal_code: "77018",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "80530",
    full_city_name: "Frederick, CO",
    city_name: "Frederick",
  },
  {
    postal_code: "42754",
    full_city_name: "Leitchfield, KY",
    city_name: "Leitchfield",
  },
  {
    postal_code: "37803",
    full_city_name: "Maryville, TN",
    city_name: "Maryville",
  },
  {
    postal_code: "70806",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "96814",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "98005",
    full_city_name: "Bellevue, WA",
    city_name: "Bellevue",
  },
  {
    postal_code: "11004",
    full_city_name: "Glen Oaks, NY",
    city_name: "Glen Oaks",
  },
  {
    postal_code: "32128",
    full_city_name: "Port Orange, FL",
    city_name: "Port Orange",
  },
  {
    postal_code: "44505",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "85355",
    full_city_name: "Waddell, AZ",
    city_name: "Waddell",
  },
  {
    postal_code: "28465",
    full_city_name: "Oak Island, NC",
    city_name: "Oak Island",
  },
  {
    postal_code: "44305",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "35214",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "95949",
    full_city_name: "Grass Valley, CA",
    city_name: "Grass Valley",
  },
  {
    postal_code: "98901",
    full_city_name: "Yakima, WA",
    city_name: "Yakima",
  },
  {
    postal_code: "02915",
    full_city_name: "Riverside, RI",
    city_name: "Riverside",
  },
  {
    postal_code: "34450",
    full_city_name: "Inverness, FL",
    city_name: "Inverness",
  },
  {
    postal_code: "33763",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "30331",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "29673",
    full_city_name: "Piedmont, SC",
    city_name: "Piedmont",
  },
  {
    postal_code: "29585",
    full_city_name: "Pawleys Island, SC",
    city_name: "Pawleys Island",
  },
  {
    postal_code: "78244",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "75038",
    full_city_name: "Irving, TX",
    city_name: "Irving",
  },
  {
    postal_code: "87144",
    full_city_name: "Rio Rancho, NM",
    city_name: "Rio Rancho",
  },
  {
    postal_code: "32404",
    full_city_name: "Panama City, FL",
    city_name: "Panama City",
  },
  {
    postal_code: "82070",
    full_city_name: "Laramie, WY",
    city_name: "Laramie",
  },
  {
    postal_code: "96146",
    full_city_name: "Olympic Valley, CA",
    city_name: "Olympic Valley",
  },
  {
    postal_code: "46360",
    full_city_name: "Michigan City, IN",
    city_name: "Michigan City",
  },
  {
    postal_code: "66067",
    full_city_name: "Ottawa, KS",
    city_name: "Ottawa",
  },
  {
    postal_code: "20132",
    full_city_name: "Purcellville, VA",
    city_name: "Purcellville",
  },
  {
    postal_code: "33993",
    full_city_name: "Cape Coral, FL",
    city_name: "Cape Coral",
  },
  {
    postal_code: "55433",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "29340",
    full_city_name: "Gaffney, SC",
    city_name: "Gaffney",
  },
  {
    postal_code: "91941",
    full_city_name: "La Mesa, CA",
    city_name: "La Mesa",
  },
  {
    postal_code: "22939",
    full_city_name: "Fishersville, VA",
    city_name: "Fishersville",
  },
  {
    postal_code: "92117",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "44053",
    full_city_name: "Lorain, OH",
    city_name: "Lorain",
  },
  {
    postal_code: "65804",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "98201",
    full_city_name: "Everett, WA",
    city_name: "Everett",
  },
  {
    postal_code: "48624",
    full_city_name: "Gladwin, MI",
    city_name: "Gladwin",
  },
  {
    postal_code: "07840",
    full_city_name: "Hackettstown, NJ",
    city_name: "Hackettstown",
  },
  {
    postal_code: "19317",
    full_city_name: "Chadds Ford, PA",
    city_name: "Chadds Ford",
  },
  {
    postal_code: "73507",
    full_city_name: "Lawton, OK",
    city_name: "Lawton",
  },
  {
    postal_code: "30906",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "02920",
    full_city_name: "Cranston, RI",
    city_name: "Cranston",
  },
  {
    postal_code: "08857",
    full_city_name: "Old Bridge, NJ",
    city_name: "Old Bridge",
  },
  {
    postal_code: "94506",
    full_city_name: "Danville, CA",
    city_name: "Danville",
  },
  {
    postal_code: "33316",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "78217",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "28721",
    full_city_name: "Clyde, NC",
    city_name: "Clyde",
  },
  {
    postal_code: "30680",
    full_city_name: "Winder, GA",
    city_name: "Winder",
  },
  {
    postal_code: "32141",
    full_city_name: "Edgewater, FL",
    city_name: "Edgewater",
  },
  {
    postal_code: "74464",
    full_city_name: "Tahlequah, OK",
    city_name: "Tahlequah",
  },
  {
    postal_code: "32137",
    full_city_name: "Palm Coast, FL",
    city_name: "Palm Coast",
  },
  {
    postal_code: "15139",
    full_city_name: "Oakmont, PA",
    city_name: "Oakmont",
  },
  {
    postal_code: "92879",
    full_city_name: "Corona, CA",
    city_name: "Corona",
  },
  {
    postal_code: "97214",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "11782",
    full_city_name: "Sayville, NY",
    city_name: "Sayville",
  },
  {
    postal_code: "06416",
    full_city_name: "Cromwell, CT",
    city_name: "Cromwell",
  },
  {
    postal_code: "98390",
    full_city_name: "Sumner, WA",
    city_name: "Sumner",
  },
  {
    postal_code: "31907",
    full_city_name: "Columbus, GA",
    city_name: "Columbus",
  },
  {
    postal_code: "98632",
    full_city_name: "Longview, WA",
    city_name: "Longview",
  },
  {
    postal_code: "85381",
    full_city_name: "Peoria, AZ",
    city_name: "Peoria",
  },
  {
    postal_code: "43701",
    full_city_name: "Zanesville, OH",
    city_name: "Zanesville",
  },
  {
    postal_code: "36869",
    full_city_name: "Phenix City, AL",
    city_name: "Phenix City",
  },
  {
    postal_code: "30066",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "70818",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "17011",
    full_city_name: "Camp Hill, PA",
    city_name: "Camp Hill",
  },
  {
    postal_code: "11798",
    full_city_name: "Wyandanch, NY",
    city_name: "Wyandanch",
  },
  {
    postal_code: "06371",
    full_city_name: "Old Lyme, CT",
    city_name: "Old Lyme",
  },
  {
    postal_code: "97089",
    full_city_name: "Damascus, OR",
    city_name: "Damascus",
  },
  {
    postal_code: "27357",
    full_city_name: "Stokesdale, NC",
    city_name: "Stokesdale",
  },
  {
    postal_code: "78619",
    full_city_name: "Driftwood, TX",
    city_name: "Driftwood",
  },
  {
    postal_code: "98038",
    full_city_name: "Maple Valley, WA",
    city_name: "Maple Valley",
  },
  {
    postal_code: "37303",
    full_city_name: "Athens, TN",
    city_name: "Athens",
  },
  {
    postal_code: "91107",
    full_city_name: "Pasadena, CA",
    city_name: "Pasadena",
  },
  {
    postal_code: "37075",
    full_city_name: "Hendersonville, TN",
    city_name: "Hendersonville",
  },
  {
    postal_code: "48433",
    full_city_name: "Flushing, MI",
    city_name: "Flushing",
  },
  {
    postal_code: "61801",
    full_city_name: "Urbana, IL",
    city_name: "Urbana",
  },
  {
    postal_code: "80202",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "21230",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "55364",
    full_city_name: "Mound, MN",
    city_name: "Mound",
  },
  {
    postal_code: "98629",
    full_city_name: "La Center, WA",
    city_name: "La Center",
  },
  {
    postal_code: "30560",
    full_city_name: "Morganton, GA",
    city_name: "Morganton",
  },
  {
    postal_code: "78245",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "71112",
    full_city_name: "Bossier City, LA",
    city_name: "Bossier City",
  },
  {
    postal_code: "19026",
    full_city_name: "Drexel Hill, PA",
    city_name: "Drexel Hill",
  },
  {
    postal_code: "02893",
    full_city_name: "West Warwick, RI",
    city_name: "West Warwick",
  },
  {
    postal_code: "98607",
    full_city_name: "Camas, WA",
    city_name: "Camas",
  },
  {
    postal_code: "90274",
    full_city_name: "Palos Verdes Peninsula, CA",
    city_name: "Palos Verdes Peninsula",
  },
  {
    postal_code: "28301",
    full_city_name: "Fayetteville, NC",
    city_name: "Fayetteville",
  },
  {
    postal_code: "03104",
    full_city_name: "Manchester, NH",
    city_name: "Manchester",
  },
  {
    postal_code: "89408",
    full_city_name: "Fernley, NV",
    city_name: "Fernley",
  },
  {
    postal_code: "11552",
    full_city_name: "West Hempstead, NY",
    city_name: "West Hempstead",
  },
  {
    postal_code: "37377",
    full_city_name: "Signal Mountain, TN",
    city_name: "Signal Mountain",
  },
  {
    postal_code: "48080",
    full_city_name: "Saint Clair Shores, MI",
    city_name: "Saint Clair Shores",
  },
  {
    postal_code: "21076",
    full_city_name: "Hanover, MD",
    city_name: "Hanover",
  },
  {
    postal_code: "19804",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "45439",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "19142",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "84058",
    full_city_name: "Orem, UT",
    city_name: "Orem",
  },
  {
    postal_code: "78573",
    full_city_name: "Mission, TX",
    city_name: "Mission",
  },
  {
    postal_code: "20782",
    full_city_name: "Hyattsville, MD",
    city_name: "Hyattsville",
  },
  {
    postal_code: "33455",
    full_city_name: "Hobe Sound, FL",
    city_name: "Hobe Sound",
  },
  {
    postal_code: "99338",
    full_city_name: "Kennewick, WA",
    city_name: "Kennewick",
  },
  {
    postal_code: "46135",
    full_city_name: "Greencastle, IN",
    city_name: "Greencastle",
  },
  {
    postal_code: "98258",
    full_city_name: "Lake Stevens, WA",
    city_name: "Lake Stevens",
  },
  {
    postal_code: "46544",
    full_city_name: "Mishawaka, IN",
    city_name: "Mishawaka",
  },
  {
    postal_code: "29642",
    full_city_name: "Easley, SC",
    city_name: "Easley",
  },
  {
    postal_code: "11225",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "75462",
    full_city_name: "Paris, TX",
    city_name: "Paris",
  },
  {
    postal_code: "90011",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "37408",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "15239",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "96080",
    full_city_name: "Red Bluff, CA",
    city_name: "Red Bluff",
  },
  {
    postal_code: "75965",
    full_city_name: "Nacogdoches, TX",
    city_name: "Nacogdoches",
  },
  {
    postal_code: "54401",
    full_city_name: "Wausau, WI",
    city_name: "Wausau",
  },
  {
    postal_code: "98178",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "33042",
    full_city_name: "Summerland Key, FL",
    city_name: "Summerland Key",
  },
  {
    postal_code: "27704",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "92324",
    full_city_name: "Colton, CA",
    city_name: "Colton",
  },
  {
    postal_code: "33486",
    full_city_name: "Boca Raton, FL",
    city_name: "Boca Raton",
  },
  {
    postal_code: "28105",
    full_city_name: "Matthews, NC",
    city_name: "Matthews",
  },
  {
    postal_code: "72830",
    full_city_name: "Clarksville, AR",
    city_name: "Clarksville",
  },
  {
    postal_code: "20855",
    full_city_name: "Derwood, MD",
    city_name: "Derwood",
  },
  {
    postal_code: "19951",
    full_city_name: "Harbeson, DE",
    city_name: "Harbeson",
  },
  {
    postal_code: "44030",
    full_city_name: "Conneaut, OH",
    city_name: "Conneaut",
  },
  {
    postal_code: "30161",
    full_city_name: "Rome, GA",
    city_name: "Rome",
  },
  {
    postal_code: "94542",
    full_city_name: "Hayward, CA",
    city_name: "Hayward",
  },
  {
    postal_code: "85031",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "81403",
    full_city_name: "Montrose, CO",
    city_name: "Montrose",
  },
  {
    postal_code: "81252",
    full_city_name: "Westcliffe, CO",
    city_name: "Westcliffe",
  },
  {
    postal_code: "97071",
    full_city_name: "Woodburn, OR",
    city_name: "Woodburn",
  },
  {
    postal_code: "67846",
    full_city_name: "Garden City, KS",
    city_name: "Garden City",
  },
  {
    postal_code: "19086",
    full_city_name: "Wallingford, PA",
    city_name: "Wallingford",
  },
  {
    postal_code: "53545",
    full_city_name: "Janesville, WI",
    city_name: "Janesville",
  },
  {
    postal_code: "28759",
    full_city_name: "Mills River, NC",
    city_name: "Mills River",
  },
  {
    postal_code: "48238",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "80221",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "30345",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "27401",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "29671",
    full_city_name: "Pickens, SC",
    city_name: "Pickens",
  },
  {
    postal_code: "63125",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "50014",
    full_city_name: "Ames, IA",
    city_name: "Ames",
  },
  {
    postal_code: "35405",
    full_city_name: "Tuscaloosa, AL",
    city_name: "Tuscaloosa",
  },
  {
    postal_code: "34212",
    full_city_name: "Bradenton, FL",
    city_name: "Bradenton",
  },
  {
    postal_code: "75460",
    full_city_name: "Paris, TX",
    city_name: "Paris",
  },
  {
    postal_code: "45305",
    full_city_name: "Bellbrook, OH",
    city_name: "Bellbrook",
  },
  {
    postal_code: "20772",
    full_city_name: "Upper Marlboro, MD",
    city_name: "Upper Marlboro",
  },
  {
    postal_code: "33315",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "83702",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "60442",
    full_city_name: "Manhattan, IL",
    city_name: "Manhattan",
  },
  {
    postal_code: "20121",
    full_city_name: "Centreville, VA",
    city_name: "Centreville",
  },
  {
    postal_code: "38103",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "75686",
    full_city_name: "Pittsburg, TX",
    city_name: "Pittsburg",
  },
  {
    postal_code: "20678",
    full_city_name: "Prince Frederick, MD",
    city_name: "Prince Frederick",
  },
  {
    postal_code: "91602",
    full_city_name: "North Hollywood, CA",
    city_name: "North Hollywood",
  },
  {
    postal_code: "75042",
    full_city_name: "Garland, TX",
    city_name: "Garland",
  },
  {
    postal_code: "97470",
    full_city_name: "Roseburg, OR",
    city_name: "Roseburg",
  },
  {
    postal_code: "32219",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "80222",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "11418",
    full_city_name: "Richmond Hill, NY",
    city_name: "Richmond Hill",
  },
  {
    postal_code: "75062",
    full_city_name: "Irving, TX",
    city_name: "Irving",
  },
  {
    postal_code: "11756",
    full_city_name: "Levittown, NY",
    city_name: "Levittown",
  },
  {
    postal_code: "85086",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "21227",
    full_city_name: "Halethorpe, MD",
    city_name: "Halethorpe",
  },
  {
    postal_code: "33010",
    full_city_name: "Hialeah, FL",
    city_name: "Hialeah",
  },
  {
    postal_code: "85607",
    full_city_name: "Douglas, AZ",
    city_name: "Douglas",
  },
  {
    postal_code: "39466",
    full_city_name: "Picayune, MS",
    city_name: "Picayune",
  },
  {
    postal_code: "85281",
    full_city_name: "Tempe, AZ",
    city_name: "Tempe",
  },
  {
    postal_code: "65583",
    full_city_name: "Waynesville, MO",
    city_name: "Waynesville",
  },
  {
    postal_code: "60428",
    full_city_name: "Markham, IL",
    city_name: "Markham",
  },
  {
    postal_code: "93705",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "67220",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "49037",
    full_city_name: "Battle Creek, MI",
    city_name: "Battle Creek",
  },
  {
    postal_code: "52405",
    full_city_name: "Cedar Rapids, IA",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "22182",
    full_city_name: "Vienna, VA",
    city_name: "Vienna",
  },
  {
    postal_code: "97317",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "67209",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "37188",
    full_city_name: "White House, TN",
    city_name: "White House",
  },
  {
    postal_code: "68512",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "10036",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "23139",
    full_city_name: "Powhatan, VA",
    city_name: "Powhatan",
  },
  {
    postal_code: "45208",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "75503",
    full_city_name: "Texarkana, TX",
    city_name: "Texarkana",
  },
  {
    postal_code: "28334",
    full_city_name: "Dunn, NC",
    city_name: "Dunn",
  },
  {
    postal_code: "29690",
    full_city_name: "Travelers Rest, SC",
    city_name: "Travelers Rest",
  },
  {
    postal_code: "29040",
    full_city_name: "Dalzell, SC",
    city_name: "Dalzell",
  },
  {
    postal_code: "95348",
    full_city_name: "Merced, CA",
    city_name: "Merced",
  },
  {
    postal_code: "94806",
    full_city_name: "San Pablo, CA",
    city_name: "San Pablo",
  },
  {
    postal_code: "11801",
    full_city_name: "Hicksville, NY",
    city_name: "Hicksville",
  },
  {
    postal_code: "02842",
    full_city_name: "Middletown, RI",
    city_name: "Middletown",
  },
  {
    postal_code: "85259",
    full_city_name: "Scottsdale, AZ",
    city_name: "Scottsdale",
  },
  {
    postal_code: "66044",
    full_city_name: "Lawrence, KS",
    city_name: "Lawrence",
  },
  {
    postal_code: "94086",
    full_city_name: "Sunnyvale, CA",
    city_name: "Sunnyvale",
  },
  {
    postal_code: "49431",
    full_city_name: "Ludington, MI",
    city_name: "Ludington",
  },
  {
    postal_code: "27282",
    full_city_name: "Jamestown, NC",
    city_name: "Jamestown",
  },
  {
    postal_code: "30276",
    full_city_name: "Senoia, GA",
    city_name: "Senoia",
  },
  {
    postal_code: "72719",
    full_city_name: "Centerton, AR",
    city_name: "Centerton",
  },
  {
    postal_code: "33185",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "33409",
    full_city_name: "West Palm Beach, FL",
    city_name: "West Palm Beach",
  },
  {
    postal_code: "97236",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "72143",
    full_city_name: "Searcy, AR",
    city_name: "Searcy",
  },
  {
    postal_code: "18360",
    full_city_name: "Stroudsburg, PA",
    city_name: "Stroudsburg",
  },
  {
    postal_code: "86406",
    full_city_name: "Lake Havasu City, AZ",
    city_name: "Lake Havasu City",
  },
  {
    postal_code: "08109",
    full_city_name: "Merchantville, NJ",
    city_name: "Merchantville",
  },
  {
    postal_code: "28202",
    full_city_name: "Charlotte, NC",
    city_name: "Charlotte",
  },
  {
    postal_code: "28562",
    full_city_name: "New Bern, NC",
    city_name: "New Bern",
  },
  {
    postal_code: "75224",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "98102",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "91010",
    full_city_name: "Duarte, CA",
    city_name: "Duarte",
  },
  {
    postal_code: "85544",
    full_city_name: "Pine, AZ",
    city_name: "Pine",
  },
  {
    postal_code: "97504",
    full_city_name: "Medford, OR",
    city_name: "Medford",
  },
  {
    postal_code: "72116",
    full_city_name: "North Little Rock, AR",
    city_name: "North Little Rock",
  },
  {
    postal_code: "54911",
    full_city_name: "Appleton, WI",
    city_name: "Appleton",
  },
  {
    postal_code: "98626",
    full_city_name: "Kelso, WA",
    city_name: "Kelso",
  },
  {
    postal_code: "20002",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "06516",
    full_city_name: "West Haven, CT",
    city_name: "West Haven",
  },
  {
    postal_code: "32931",
    full_city_name: "Cocoa Beach, FL",
    city_name: "Cocoa Beach",
  },
  {
    postal_code: "60081",
    full_city_name: "Spring Grove, IL",
    city_name: "Spring Grove",
  },
  {
    postal_code: "25302",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "74820",
    full_city_name: "Ada, OK",
    city_name: "Ada",
  },
  {
    postal_code: "47429",
    full_city_name: "Ellettsville, IN",
    city_name: "Ellettsville",
  },
  {
    postal_code: "70501",
    full_city_name: "Lafayette, LA",
    city_name: "Lafayette",
  },
  {
    postal_code: "77545",
    full_city_name: "Fresno, TX",
    city_name: "Fresno",
  },
  {
    postal_code: "08755",
    full_city_name: "Toms River, NJ",
    city_name: "Toms River",
  },
  {
    postal_code: "13903",
    full_city_name: "Binghamton, NY",
    city_name: "Binghamton",
  },
  {
    postal_code: "95030",
    full_city_name: "Los Gatos, CA",
    city_name: "Los Gatos",
  },
  {
    postal_code: "98406",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "32547",
    full_city_name: "Fort Walton Beach, FL",
    city_name: "Fort Walton Beach",
  },
  {
    postal_code: "20659",
    full_city_name: "Mechanicsville, MD",
    city_name: "Mechanicsville",
  },
  {
    postal_code: "92410",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "49203",
    full_city_name: "Jackson, MI",
    city_name: "Jackson",
  },
  {
    postal_code: "47909",
    full_city_name: "Lafayette, IN",
    city_name: "Lafayette",
  },
  {
    postal_code: "30324",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "15401",
    full_city_name: "Uniontown, PA",
    city_name: "Uniontown",
  },
  {
    postal_code: "92663",
    full_city_name: "Newport Beach, CA",
    city_name: "Newport Beach",
  },
  {
    postal_code: "35205",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "13357",
    full_city_name: "Ilion, NY",
    city_name: "Ilion",
  },
  {
    postal_code: "75020",
    full_city_name: "Denison, TX",
    city_name: "Denison",
  },
  {
    postal_code: "73521",
    full_city_name: "Altus, OK",
    city_name: "Altus",
  },
  {
    postal_code: "74115",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "67052",
    full_city_name: "Goddard, KS",
    city_name: "Goddard",
  },
  {
    postal_code: "29568",
    full_city_name: "Longs, SC",
    city_name: "Longs",
  },
  {
    postal_code: "75702",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "37877",
    full_city_name: "Talbott, TN",
    city_name: "Talbott",
  },
  {
    postal_code: "50263",
    full_city_name: "Waukee, IA",
    city_name: "Waukee",
  },
  {
    postal_code: "92083",
    full_city_name: "Vista, CA",
    city_name: "Vista",
  },
  {
    postal_code: "11501",
    full_city_name: "Mineola, NY",
    city_name: "Mineola",
  },
  {
    postal_code: "97215",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "53716",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "45426",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "95926",
    full_city_name: "Chico, CA",
    city_name: "Chico",
  },
  {
    postal_code: "54902",
    full_city_name: "Oshkosh, WI",
    city_name: "Oshkosh",
  },
  {
    postal_code: "84041",
    full_city_name: "Layton, UT",
    city_name: "Layton",
  },
  {
    postal_code: "91722",
    full_city_name: "Covina, CA",
    city_name: "Covina",
  },
  {
    postal_code: "30620",
    full_city_name: "Bethlehem, GA",
    city_name: "Bethlehem",
  },
  {
    postal_code: "87112",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "27021",
    full_city_name: "King, NC",
    city_name: "King",
  },
  {
    postal_code: "90731",
    full_city_name: "San Pedro, CA",
    city_name: "San Pedro",
  },
  {
    postal_code: "37415",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "03110",
    full_city_name: "Bedford, NH",
    city_name: "Bedford",
  },
  {
    postal_code: "80602",
    full_city_name: "Brighton, CO",
    city_name: "Brighton",
  },
  {
    postal_code: "70817",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "48214",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "11946",
    full_city_name: "Hampton Bays, NY",
    city_name: "Hampton Bays",
  },
  {
    postal_code: "17050",
    full_city_name: "Mechanicsburg, PA",
    city_name: "Mechanicsburg",
  },
  {
    postal_code: "77030",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "83835",
    full_city_name: "Hayden, ID",
    city_name: "Hayden",
  },
  {
    postal_code: "99502",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "93436",
    full_city_name: "Lompoc, CA",
    city_name: "Lompoc",
  },
  {
    postal_code: "37381",
    full_city_name: "Spring City, TN",
    city_name: "Spring City",
  },
  {
    postal_code: "92374",
    full_city_name: "Redlands, CA",
    city_name: "Redlands",
  },
  {
    postal_code: "98110",
    full_city_name: "Bainbridge Island, WA",
    city_name: "Bainbridge Island",
  },
  {
    postal_code: "13219",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "34475",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "99403",
    full_city_name: "Clarkston, WA",
    city_name: "Clarkston",
  },
  {
    postal_code: "44266",
    full_city_name: "Ravenna, OH",
    city_name: "Ravenna",
  },
  {
    postal_code: "45206",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "38801",
    full_city_name: "Tupelo, MS",
    city_name: "Tupelo",
  },
  {
    postal_code: "46814",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "43026",
    full_city_name: "Hilliard, OH",
    city_name: "Hilliard",
  },
  {
    postal_code: "28605",
    full_city_name: "Blowing Rock, NC",
    city_name: "Blowing Rock",
  },
  {
    postal_code: "44622",
    full_city_name: "Dover, OH",
    city_name: "Dover",
  },
  {
    postal_code: "44142",
    full_city_name: "Brookpark, OH",
    city_name: "Brookpark",
  },
  {
    postal_code: "55424",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "36054",
    full_city_name: "Millbrook, AL",
    city_name: "Millbrook",
  },
  {
    postal_code: "91915",
    full_city_name: "Chula Vista, CA",
    city_name: "Chula Vista",
  },
  {
    postal_code: "64804",
    full_city_name: "Joplin, MO",
    city_name: "Joplin",
  },
  {
    postal_code: "48623",
    full_city_name: "Freeland, MI",
    city_name: "Freeland",
  },
  {
    postal_code: "49002",
    full_city_name: "Portage, MI",
    city_name: "Portage",
  },
  {
    postal_code: "30230",
    full_city_name: "Hogansville, GA",
    city_name: "Hogansville",
  },
  {
    postal_code: "29360",
    full_city_name: "Laurens, SC",
    city_name: "Laurens",
  },
  {
    postal_code: "20191",
    full_city_name: "Reston, VA",
    city_name: "Reston",
  },
  {
    postal_code: "85013",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "37216",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "30236",
    full_city_name: "Jonesboro, GA",
    city_name: "Jonesboro",
  },
  {
    postal_code: "39571",
    full_city_name: "Pass Christian, MS",
    city_name: "Pass Christian",
  },
  {
    postal_code: "95821",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "90732",
    full_city_name: "San Pedro, CA",
    city_name: "San Pedro",
  },
  {
    postal_code: "75901",
    full_city_name: "Lufkin, TX",
    city_name: "Lufkin",
  },
  {
    postal_code: "20852",
    full_city_name: "Rockville, MD",
    city_name: "Rockville",
  },
  {
    postal_code: "44310",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "80210",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "33484",
    full_city_name: "Delray Beach, FL",
    city_name: "Delray Beach",
  },
  {
    postal_code: "37135",
    full_city_name: "Nolensville, TN",
    city_name: "Nolensville",
  },
  {
    postal_code: "21030",
    full_city_name: "Cockeysville, MD",
    city_name: "Cockeysville",
  },
  {
    postal_code: "30814",
    full_city_name: "Harlem, GA",
    city_name: "Harlem",
  },
  {
    postal_code: "43064",
    full_city_name: "Plain City, OH",
    city_name: "Plain City",
  },
  {
    postal_code: "85335",
    full_city_name: "El Mirage, AZ",
    city_name: "El Mirage",
  },
  {
    postal_code: "37064",
    full_city_name: "Franklin, TN",
    city_name: "Franklin",
  },
  {
    postal_code: "80917",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "86336",
    full_city_name: "Sedona, AZ",
    city_name: "Sedona",
  },
  {
    postal_code: "80022",
    full_city_name: "Commerce City, CO",
    city_name: "Commerce City",
  },
  {
    postal_code: "97023",
    full_city_name: "Estacada, OR",
    city_name: "Estacada",
  },
  {
    postal_code: "73118",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "07067",
    full_city_name: "Colonia, NJ",
    city_name: "Colonia",
  },
  {
    postal_code: "34453",
    full_city_name: "Inverness, FL",
    city_name: "Inverness",
  },
  {
    postal_code: "78224",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "93704",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "02921",
    full_city_name: "Cranston, RI",
    city_name: "Cranston",
  },
  {
    postal_code: "48198",
    full_city_name: "Ypsilanti, MI",
    city_name: "Ypsilanti",
  },
  {
    postal_code: "78606",
    full_city_name: "Blanco, TX",
    city_name: "Blanco",
  },
  {
    postal_code: "62712",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "92508",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "85648",
    full_city_name: "Rio Rico, AZ",
    city_name: "Rio Rico",
  },
  {
    postal_code: "55092",
    full_city_name: "Wyoming, MN",
    city_name: "Wyoming",
  },
  {
    postal_code: "13057",
    full_city_name: "East Syracuse, NY",
    city_name: "East Syracuse",
  },
  {
    postal_code: "15009",
    full_city_name: "Beaver, PA",
    city_name: "Beaver",
  },
  {
    postal_code: "44054",
    full_city_name: "Sheffield Lake, OH",
    city_name: "Sheffield Lake",
  },
  {
    postal_code: "73018",
    full_city_name: "Chickasha, OK",
    city_name: "Chickasha",
  },
  {
    postal_code: "94115",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "33957",
    full_city_name: "Sanibel, FL",
    city_name: "Sanibel",
  },
  {
    postal_code: "11432",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "48101",
    full_city_name: "Allen Park, MI",
    city_name: "Allen Park",
  },
  {
    postal_code: "38120",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "63139",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "33576",
    full_city_name: "San Antonio, FL",
    city_name: "San Antonio",
  },
  {
    postal_code: "48846",
    full_city_name: "Ionia, MI",
    city_name: "Ionia",
  },
  {
    postal_code: "21550",
    full_city_name: "Oakland, MD",
    city_name: "Oakland",
  },
  {
    postal_code: "28352",
    full_city_name: "Laurinburg, NC",
    city_name: "Laurinburg",
  },
  {
    postal_code: "11777",
    full_city_name: "Port Jefferson, NY",
    city_name: "Port Jefferson",
  },
  {
    postal_code: "45324",
    full_city_name: "Fairborn, OH",
    city_name: "Fairborn",
  },
  {
    postal_code: "78758",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "77362",
    full_city_name: "Pinehurst, TX",
    city_name: "Pinehurst",
  },
  {
    postal_code: "83204",
    full_city_name: "Pocatello, ID",
    city_name: "Pocatello",
  },
  {
    postal_code: "60432",
    full_city_name: "Joliet, IL",
    city_name: "Joliet",
  },
  {
    postal_code: "54961",
    full_city_name: "New London, WI",
    city_name: "New London",
  },
  {
    postal_code: "60041",
    full_city_name: "Ingleside, IL",
    city_name: "Ingleside",
  },
  {
    postal_code: "97038",
    full_city_name: "Molalla, OR",
    city_name: "Molalla",
  },
  {
    postal_code: "28139",
    full_city_name: "Rutherfordton, NC",
    city_name: "Rutherfordton",
  },
  {
    postal_code: "25419",
    full_city_name: "Falling Waters, WV",
    city_name: "Falling Waters",
  },
  {
    postal_code: "11360",
    full_city_name: "Bayside, NY",
    city_name: "Bayside",
  },
  {
    postal_code: "19518",
    full_city_name: "Douglassville, PA",
    city_name: "Douglassville",
  },
  {
    postal_code: "48203",
    full_city_name: "Highland Park, MI",
    city_name: "Highland Park",
  },
  {
    postal_code: "27023",
    full_city_name: "Lewisville, NC",
    city_name: "Lewisville",
  },
  {
    postal_code: "56472",
    full_city_name: "Pequot Lakes, MN",
    city_name: "Pequot Lakes",
  },
  {
    postal_code: "77505",
    full_city_name: "Pasadena, TX",
    city_name: "Pasadena",
  },
  {
    postal_code: "78130",
    full_city_name: "New Braunfels, TX",
    city_name: "New Braunfels",
  },
  {
    postal_code: "17402",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "60133",
    full_city_name: "Hanover Park, IL",
    city_name: "Hanover Park",
  },
  {
    postal_code: "32571",
    full_city_name: "Milton, FL",
    city_name: "Milton",
  },
  {
    postal_code: "83404",
    full_city_name: "Idaho Falls, ID",
    city_name: "Idaho Falls",
  },
  {
    postal_code: "34471",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "32011",
    full_city_name: "Callahan, FL",
    city_name: "Callahan",
  },
  {
    postal_code: "29078",
    full_city_name: "Lugoff, SC",
    city_name: "Lugoff",
  },
  {
    postal_code: "46259",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "62301",
    full_city_name: "Quincy, IL",
    city_name: "Quincy",
  },
  {
    postal_code: "45246",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "35058",
    full_city_name: "Cullman, AL",
    city_name: "Cullman",
  },
  {
    postal_code: "91040",
    full_city_name: "Sunland, CA",
    city_name: "Sunland",
  },
  {
    postal_code: "95834",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "29627",
    full_city_name: "Belton, SC",
    city_name: "Belton",
  },
  {
    postal_code: "85377",
    full_city_name: "Carefree, AZ",
    city_name: "Carefree",
  },
  {
    postal_code: "30297",
    full_city_name: "Forest Park, GA",
    city_name: "Forest Park",
  },
  {
    postal_code: "59068",
    full_city_name: "Red Lodge, MT",
    city_name: "Red Lodge",
  },
  {
    postal_code: "37807",
    full_city_name: "Maynardville, TN",
    city_name: "Maynardville",
  },
  {
    postal_code: "32805",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "70116",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "17404",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "21215",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "43613",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "90006",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "30354",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "02806",
    full_city_name: "Barrington, RI",
    city_name: "Barrington",
  },
  {
    postal_code: "68504",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "11433",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "46304",
    full_city_name: "Chesterton, IN",
    city_name: "Chesterton",
  },
  {
    postal_code: "89029",
    full_city_name: "Laughlin, NV",
    city_name: "Laughlin",
  },
  {
    postal_code: "92867",
    full_city_name: "Orange, CA",
    city_name: "Orange",
  },
  {
    postal_code: "83805",
    full_city_name: "Bonners Ferry, ID",
    city_name: "Bonners Ferry",
  },
  {
    postal_code: "28752",
    full_city_name: "Marion, NC",
    city_name: "Marion",
  },
  {
    postal_code: "21136",
    full_city_name: "Reisterstown, MD",
    city_name: "Reisterstown",
  },
  {
    postal_code: "54494",
    full_city_name: "Wisconsin Rapids, WI",
    city_name: "Wisconsin Rapids",
  },
  {
    postal_code: "92029",
    full_city_name: "Escondido, CA",
    city_name: "Escondido",
  },
  {
    postal_code: "67110",
    full_city_name: "Mulvane, KS",
    city_name: "Mulvane",
  },
  {
    postal_code: "02813",
    full_city_name: "Charlestown, RI",
    city_name: "Charlestown",
  },
  {
    postal_code: "07060",
    full_city_name: "Plainfield, NJ",
    city_name: "Plainfield",
  },
  {
    postal_code: "89156",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "48221",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "76067",
    full_city_name: "Mineral Wells, TX",
    city_name: "Mineral Wells",
  },
  {
    postal_code: "97003",
    full_city_name: "Beaverton, OR",
    city_name: "Beaverton",
  },
  {
    postal_code: "14701",
    full_city_name: "Jamestown, NY",
    city_name: "Jamestown",
  },
  {
    postal_code: "22026",
    full_city_name: "Dumfries, VA",
    city_name: "Dumfries",
  },
  {
    postal_code: "32413",
    full_city_name: "Panama City Beach, FL",
    city_name: "Panama City Beach",
  },
  {
    postal_code: "54913",
    full_city_name: "Appleton, WI",
    city_name: "Appleton",
  },
  {
    postal_code: "06076",
    full_city_name: "Stafford Springs, CT",
    city_name: "Stafford Springs",
  },
  {
    postal_code: "84037",
    full_city_name: "Kaysville, UT",
    city_name: "Kaysville",
  },
  {
    postal_code: "91214",
    full_city_name: "La Crescenta, CA",
    city_name: "La Crescenta",
  },
  {
    postal_code: "37179",
    full_city_name: "Thompsons Station, TN",
    city_name: "Thompsons Station",
  },
  {
    postal_code: "90710",
    full_city_name: "Harbor City, CA",
    city_name: "Harbor City",
  },
  {
    postal_code: "90740",
    full_city_name: "Seal Beach, CA",
    city_name: "Seal Beach",
  },
  {
    postal_code: "94112",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "08820",
    full_city_name: "Edison, NJ",
    city_name: "Edison",
  },
  {
    postal_code: "35094",
    full_city_name: "Leeds, AL",
    city_name: "Leeds",
  },
  {
    postal_code: "11427",
    full_city_name: "Queens Village, NY",
    city_name: "Queens Village",
  },
  {
    postal_code: "81004",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "30269",
    full_city_name: "Peachtree City, GA",
    city_name: "Peachtree City",
  },
  {
    postal_code: "55415",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "72120",
    full_city_name: "Sherwood, AR",
    city_name: "Sherwood",
  },
  {
    postal_code: "12839",
    full_city_name: "Hudson Falls, NY",
    city_name: "Hudson Falls",
  },
  {
    postal_code: "94602",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "95624",
    full_city_name: "Elk Grove, CA",
    city_name: "Elk Grove",
  },
  {
    postal_code: "69001",
    full_city_name: "Mc Cook, NE",
    city_name: "Mc Cook",
  },
  {
    postal_code: "45245",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "94519",
    full_city_name: "Concord, CA",
    city_name: "Concord",
  },
  {
    postal_code: "12822",
    full_city_name: "Corinth, NY",
    city_name: "Corinth",
  },
  {
    postal_code: "08558",
    full_city_name: "Skillman, NJ",
    city_name: "Skillman",
  },
  {
    postal_code: "44120",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "65619",
    full_city_name: "Brookline, MO",
    city_name: "Brookline",
  },
  {
    postal_code: "60423",
    full_city_name: "Frankfort, IL",
    city_name: "Frankfort",
  },
  {
    postal_code: "95223",
    full_city_name: "Arnold, CA",
    city_name: "Arnold",
  },
  {
    postal_code: "66502",
    full_city_name: "Manhattan, KS",
    city_name: "Manhattan",
  },
  {
    postal_code: "28323",
    full_city_name: "Bunnlevel, NC",
    city_name: "Bunnlevel",
  },
  {
    postal_code: "22443",
    full_city_name: "Colonial Beach, VA",
    city_name: "Colonial Beach",
  },
  {
    postal_code: "48340",
    full_city_name: "Pontiac, MI",
    city_name: "Pontiac",
  },
  {
    postal_code: "25425",
    full_city_name: "Harpers Ferry, WV",
    city_name: "Harpers Ferry",
  },
  {
    postal_code: "64080",
    full_city_name: "Pleasant Hill, MO",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "98444",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "21216",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "33596",
    full_city_name: "Valrico, FL",
    city_name: "Valrico",
  },
  {
    postal_code: "97527",
    full_city_name: "Grants Pass, OR",
    city_name: "Grants Pass",
  },
  {
    postal_code: "80138",
    full_city_name: "Parker, CO",
    city_name: "Parker",
  },
  {
    postal_code: "76017",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "11231",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "03103",
    full_city_name: "Manchester, NH",
    city_name: "Manchester",
  },
  {
    postal_code: "19335",
    full_city_name: "Downingtown, PA",
    city_name: "Downingtown",
  },
  {
    postal_code: "92501",
    full_city_name: "Riverside, CA",
    city_name: "Riverside",
  },
  {
    postal_code: "08735",
    full_city_name: "Lavallette, NJ",
    city_name: "Lavallette",
  },
  {
    postal_code: "85032",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "17356",
    full_city_name: "Red Lion, PA",
    city_name: "Red Lion",
  },
  {
    postal_code: "81520",
    full_city_name: "Clifton, CO",
    city_name: "Clifton",
  },
  {
    postal_code: "91724",
    full_city_name: "Covina, CA",
    city_name: "Covina",
  },
  {
    postal_code: "75217",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "99508",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "40243",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "10305",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "37203",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "46818",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "83651",
    full_city_name: "Nampa, ID",
    city_name: "Nampa",
  },
  {
    postal_code: "40701",
    full_city_name: "Corbin, KY",
    city_name: "Corbin",
  },
  {
    postal_code: "06339",
    full_city_name: "Ledyard, CT",
    city_name: "Ledyard",
  },
  {
    postal_code: "27288",
    full_city_name: "Eden, NC",
    city_name: "Eden",
  },
  {
    postal_code: "75709",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "83501",
    full_city_name: "Lewiston, ID",
    city_name: "Lewiston",
  },
  {
    postal_code: "84087",
    full_city_name: "Woods Cross, UT",
    city_name: "Woods Cross",
  },
  {
    postal_code: "73008",
    full_city_name: "Bethany, OK",
    city_name: "Bethany",
  },
  {
    postal_code: "45327",
    full_city_name: "Germantown, OH",
    city_name: "Germantown",
  },
  {
    postal_code: "34482",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "91351",
    full_city_name: "Canyon Country, CA",
    city_name: "Canyon Country",
  },
  {
    postal_code: "33774",
    full_city_name: "Largo, FL",
    city_name: "Largo",
  },
  {
    postal_code: "50131",
    full_city_name: "Johnston, IA",
    city_name: "Johnston",
  },
  {
    postal_code: "27713",
    full_city_name: "Durham, NC",
    city_name: "Durham",
  },
  {
    postal_code: "83869",
    full_city_name: "Spirit Lake, ID",
    city_name: "Spirit Lake",
  },
  {
    postal_code: "99001",
    full_city_name: "Airway Heights, WA",
    city_name: "Airway Heights",
  },
  {
    postal_code: "20613",
    full_city_name: "Brandywine, MD",
    city_name: "Brandywine",
  },
  {
    postal_code: "15238",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "62258",
    full_city_name: "Mascoutah, IL",
    city_name: "Mascoutah",
  },
  {
    postal_code: "78252",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "78617",
    full_city_name: "Del Valle, TX",
    city_name: "Del Valle",
  },
  {
    postal_code: "29646",
    full_city_name: "Greenwood, SC",
    city_name: "Greenwood",
  },
  {
    postal_code: "64138",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "27101",
    full_city_name: "Winston Salem, NC",
    city_name: "Winston Salem",
  },
  {
    postal_code: "84097",
    full_city_name: "Orem, UT",
    city_name: "Orem",
  },
  {
    postal_code: "11550",
    full_city_name: "Hempstead, NY",
    city_name: "Hempstead",
  },
  {
    postal_code: "50325",
    full_city_name: "Clive, IA",
    city_name: "Clive",
  },
  {
    postal_code: "47834",
    full_city_name: "Brazil, IN",
    city_name: "Brazil",
  },
  {
    postal_code: "84028",
    full_city_name: "Garden City, UT",
    city_name: "Garden City",
  },
  {
    postal_code: "40422",
    full_city_name: "Danville, KY",
    city_name: "Danville",
  },
  {
    postal_code: "47591",
    full_city_name: "Vincennes, IN",
    city_name: "Vincennes",
  },
  {
    postal_code: "33702",
    full_city_name: "Saint Petersburg, FL",
    city_name: "Saint Petersburg",
  },
  {
    postal_code: "48187",
    full_city_name: "Canton, MI",
    city_name: "Canton",
  },
  {
    postal_code: "48033",
    full_city_name: "Southfield, MI",
    city_name: "Southfield",
  },
  {
    postal_code: "90042",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "92582",
    full_city_name: "San Jacinto, CA",
    city_name: "San Jacinto",
  },
  {
    postal_code: "32779",
    full_city_name: "Longwood, FL",
    city_name: "Longwood",
  },
  {
    postal_code: "11738",
    full_city_name: "Farmingville, NY",
    city_name: "Farmingville",
  },
  {
    postal_code: "30909",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "18424",
    full_city_name: "Gouldsboro, PA",
    city_name: "Gouldsboro",
  },
  {
    postal_code: "86351",
    full_city_name: "Sedona, AZ",
    city_name: "Sedona",
  },
  {
    postal_code: "77071",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "97424",
    full_city_name: "Cottage Grove, OR",
    city_name: "Cottage Grove",
  },
  {
    postal_code: "24540",
    full_city_name: "Danville, VA",
    city_name: "Danville",
  },
  {
    postal_code: "11727",
    full_city_name: "Coram, NY",
    city_name: "Coram",
  },
  {
    postal_code: "62249",
    full_city_name: "Highland, IL",
    city_name: "Highland",
  },
  {
    postal_code: "29505",
    full_city_name: "Florence, SC",
    city_name: "Florence",
  },
  {
    postal_code: "30115",
    full_city_name: "Canton, GA",
    city_name: "Canton",
  },
  {
    postal_code: "92126",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "98040",
    full_city_name: "Mercer Island, WA",
    city_name: "Mercer Island",
  },
  {
    postal_code: "93060",
    full_city_name: "Santa Paula, CA",
    city_name: "Santa Paula",
  },
  {
    postal_code: "48840",
    full_city_name: "Haslett, MI",
    city_name: "Haslett",
  },
  {
    postal_code: "85336",
    full_city_name: "San Luis, AZ",
    city_name: "San Luis",
  },
  {
    postal_code: "06762",
    full_city_name: "Middlebury, CT",
    city_name: "Middlebury",
  },
  {
    postal_code: "21742",
    full_city_name: "Hagerstown, MD",
    city_name: "Hagerstown",
  },
  {
    postal_code: "96707",
    full_city_name: "Kapolei, HI",
    city_name: "Kapolei",
  },
  {
    postal_code: "31301",
    full_city_name: "Allenhurst, GA",
    city_name: "Allenhurst",
  },
  {
    postal_code: "85342",
    full_city_name: "Morristown, AZ",
    city_name: "Morristown",
  },
  {
    postal_code: "24521",
    full_city_name: "Amherst, VA",
    city_name: "Amherst",
  },
  {
    postal_code: "94582",
    full_city_name: "San Ramon, CA",
    city_name: "San Ramon",
  },
  {
    postal_code: "78741",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "75119",
    full_city_name: "Ennis, TX",
    city_name: "Ennis",
  },
  {
    postal_code: "31904",
    full_city_name: "Columbus, GA",
    city_name: "Columbus",
  },
  {
    postal_code: "92337",
    full_city_name: "Fontana, CA",
    city_name: "Fontana",
  },
  {
    postal_code: "06108",
    full_city_name: "East Hartford, CT",
    city_name: "East Hartford",
  },
  {
    postal_code: "90703",
    full_city_name: "Cerritos, CA",
    city_name: "Cerritos",
  },
  {
    postal_code: "32656",
    full_city_name: "Keystone Heights, FL",
    city_name: "Keystone Heights",
  },
  {
    postal_code: "21231",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "75076",
    full_city_name: "Pottsboro, TX",
    city_name: "Pottsboro",
  },
  {
    postal_code: "92082",
    full_city_name: "Valley Center, CA",
    city_name: "Valley Center",
  },
  {
    postal_code: "37128",
    full_city_name: "Murfreesboro, TN",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "34785",
    full_city_name: "Wildwood, FL",
    city_name: "Wildwood",
  },
  {
    postal_code: "07461",
    full_city_name: "Sussex, NJ",
    city_name: "Sussex",
  },
  {
    postal_code: "44141",
    full_city_name: "Brecksville, OH",
    city_name: "Brecksville",
  },
  {
    postal_code: "49519",
    full_city_name: "Wyoming, MI",
    city_name: "Wyoming",
  },
  {
    postal_code: "30033",
    full_city_name: "Decatur, GA",
    city_name: "Decatur",
  },
  {
    postal_code: "27870",
    full_city_name: "Roanoke Rapids, NC",
    city_name: "Roanoke Rapids",
  },
  {
    postal_code: "77035",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "40744",
    full_city_name: "London, KY",
    city_name: "London",
  },
  {
    postal_code: "37865",
    full_city_name: "Seymour, TN",
    city_name: "Seymour",
  },
  {
    postal_code: "97201",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "99206",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "97526",
    full_city_name: "Grants Pass, OR",
    city_name: "Grants Pass",
  },
  {
    postal_code: "75601",
    full_city_name: "Longview, TX",
    city_name: "Longview",
  },
  {
    postal_code: "29142",
    full_city_name: "Santee, SC",
    city_name: "Santee",
  },
  {
    postal_code: "75757",
    full_city_name: "Bullard, TX",
    city_name: "Bullard",
  },
  {
    postal_code: "70126",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "08822",
    full_city_name: "Flemington, NJ",
    city_name: "Flemington",
  },
  {
    postal_code: "08062",
    full_city_name: "Mullica Hill, NJ",
    city_name: "Mullica Hill",
  },
  {
    postal_code: "27523",
    full_city_name: "Apex, NC",
    city_name: "Apex",
  },
  {
    postal_code: "80021",
    full_city_name: "Broomfield, CO",
    city_name: "Broomfield",
  },
  {
    postal_code: "33853",
    full_city_name: "Lake Wales, FL",
    city_name: "Lake Wales",
  },
  {
    postal_code: "32259",
    full_city_name: "Saint Johns, FL",
    city_name: "Saint Johns",
  },
  {
    postal_code: "37354",
    full_city_name: "Madisonville, TN",
    city_name: "Madisonville",
  },
  {
    postal_code: "17201",
    full_city_name: "Chambersburg, PA",
    city_name: "Chambersburg",
  },
  {
    postal_code: "80203",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "60051",
    full_city_name: "Mchenry, IL",
    city_name: "Mchenry",
  },
  {
    postal_code: "34748",
    full_city_name: "Leesburg, FL",
    city_name: "Leesburg",
  },
  {
    postal_code: "63901",
    full_city_name: "Poplar Bluff, MO",
    city_name: "Poplar Bluff",
  },
  {
    postal_code: "98277",
    full_city_name: "Oak Harbor, WA",
    city_name: "Oak Harbor",
  },
  {
    postal_code: "03079",
    full_city_name: "Salem, NH",
    city_name: "Salem",
  },
  {
    postal_code: "32124",
    full_city_name: "Daytona Beach, FL",
    city_name: "Daytona Beach",
  },
  {
    postal_code: "31206",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "22043",
    full_city_name: "Falls Church, VA",
    city_name: "Falls Church",
  },
  {
    postal_code: "45223",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "17022",
    full_city_name: "Elizabethtown, PA",
    city_name: "Elizabethtown",
  },
  {
    postal_code: "23075",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "68147",
    full_city_name: "Bellevue, NE",
    city_name: "Bellevue",
  },
  {
    postal_code: "60461",
    full_city_name: "Olympia Fields, IL",
    city_name: "Olympia Fields",
  },
  {
    postal_code: "36022",
    full_city_name: "Deatsville, AL",
    city_name: "Deatsville",
  },
  {
    postal_code: "74132",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "27298",
    full_city_name: "Liberty, NC",
    city_name: "Liberty",
  },
  {
    postal_code: "02655",
    full_city_name: "Osterville, MA",
    city_name: "Osterville",
  },
  {
    postal_code: "07306",
    full_city_name: "Jersey City, NJ",
    city_name: "Jersey City",
  },
  {
    postal_code: "68028",
    full_city_name: "Gretna, NE",
    city_name: "Gretna",
  },
  {
    postal_code: "97128",
    full_city_name: "Mcminnville, OR",
    city_name: "Mcminnville",
  },
  {
    postal_code: "81225",
    full_city_name: "Crested Butte, CO",
    city_name: "Crested Butte",
  },
  {
    postal_code: "26764",
    full_city_name: "Terra Alta, WV",
    city_name: "Terra Alta",
  },
  {
    postal_code: "49348",
    full_city_name: "Wayland, MI",
    city_name: "Wayland",
  },
  {
    postal_code: "97030",
    full_city_name: "Gresham, OR",
    city_name: "Gresham",
  },
  {
    postal_code: "39120",
    full_city_name: "Natchez, MS",
    city_name: "Natchez",
  },
  {
    postal_code: "70460",
    full_city_name: "Slidell, LA",
    city_name: "Slidell",
  },
  {
    postal_code: "70112",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "77336",
    full_city_name: "Huffman, TX",
    city_name: "Huffman",
  },
  {
    postal_code: "60115",
    full_city_name: "Dekalb, IL",
    city_name: "Dekalb",
  },
  {
    postal_code: "27801",
    full_city_name: "Rocky Mount, NC",
    city_name: "Rocky Mount",
  },
  {
    postal_code: "02631",
    full_city_name: "Brewster, MA",
    city_name: "Brewster",
  },
  {
    postal_code: "33478",
    full_city_name: "Jupiter, FL",
    city_name: "Jupiter",
  },
  {
    postal_code: "67203",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "11368",
    full_city_name: "Corona, NY",
    city_name: "Corona",
  },
  {
    postal_code: "75442",
    full_city_name: "Farmersville, TX",
    city_name: "Farmersville",
  },
  {
    postal_code: "95062",
    full_city_name: "Santa Cruz, CA",
    city_name: "Santa Cruz",
  },
  {
    postal_code: "14618",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "25427",
    full_city_name: "Hedgesville, WV",
    city_name: "Hedgesville",
  },
  {
    postal_code: "97051",
    full_city_name: "Saint Helens, OR",
    city_name: "Saint Helens",
  },
  {
    postal_code: "30315",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "64057",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "37130",
    full_city_name: "Murfreesboro, TN",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "84075",
    full_city_name: "Syracuse, UT",
    city_name: "Syracuse",
  },
  {
    postal_code: "44444",
    full_city_name: "Newton Falls, OH",
    city_name: "Newton Falls",
  },
  {
    postal_code: "64505",
    full_city_name: "Saint Joseph, MO",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "97206",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "98498",
    full_city_name: "Lakewood, WA",
    city_name: "Lakewood",
  },
  {
    postal_code: "22031",
    full_city_name: "Fairfax, VA",
    city_name: "Fairfax",
  },
  {
    postal_code: "45406",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "59911",
    full_city_name: "Bigfork, MT",
    city_name: "Bigfork",
  },
  {
    postal_code: "11354",
    full_city_name: "Flushing, NY",
    city_name: "Flushing",
  },
  {
    postal_code: "32025",
    full_city_name: "Lake City, FL",
    city_name: "Lake City",
  },
  {
    postal_code: "42276",
    full_city_name: "Russellville, KY",
    city_name: "Russellville",
  },
  {
    postal_code: "06470",
    full_city_name: "Newtown, CT",
    city_name: "Newtown",
  },
  {
    postal_code: "92626",
    full_city_name: "Costa Mesa, CA",
    city_name: "Costa Mesa",
  },
  {
    postal_code: "97034",
    full_city_name: "Lake Oswego, OR",
    city_name: "Lake Oswego",
  },
  {
    postal_code: "31061",
    full_city_name: "Milledgeville, GA",
    city_name: "Milledgeville",
  },
  {
    postal_code: "31064",
    full_city_name: "Monticello, GA",
    city_name: "Monticello",
  },
  {
    postal_code: "42025",
    full_city_name: "Benton, KY",
    city_name: "Benton",
  },
  {
    postal_code: "80610",
    full_city_name: "Ault, CO",
    city_name: "Ault",
  },
  {
    postal_code: "15216",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "20746",
    full_city_name: "Suitland, MD",
    city_name: "Suitland",
  },
  {
    postal_code: "37334",
    full_city_name: "Fayetteville, TN",
    city_name: "Fayetteville",
  },
  {
    postal_code: "77598",
    full_city_name: "Webster, TX",
    city_name: "Webster",
  },
  {
    postal_code: "87558",
    full_city_name: "Red River, NM",
    city_name: "Red River",
  },
  {
    postal_code: "63017",
    full_city_name: "Chesterfield, MO",
    city_name: "Chesterfield",
  },
  {
    postal_code: "15209",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "95476",
    full_city_name: "Sonoma, CA",
    city_name: "Sonoma",
  },
  {
    postal_code: "35633",
    full_city_name: "Florence, AL",
    city_name: "Florence",
  },
  {
    postal_code: "78073",
    full_city_name: "Von Ormy, TX",
    city_name: "Von Ormy",
  },
  {
    postal_code: "48135",
    full_city_name: "Garden City, MI",
    city_name: "Garden City",
  },
  {
    postal_code: "44241",
    full_city_name: "Streetsboro, OH",
    city_name: "Streetsboro",
  },
  {
    postal_code: "91390",
    full_city_name: "Santa Clarita, CA",
    city_name: "Santa Clarita",
  },
  {
    postal_code: "54880",
    full_city_name: "Superior, WI",
    city_name: "Superior",
  },
  {
    postal_code: "44667",
    full_city_name: "Orrville, OH",
    city_name: "Orrville",
  },
  {
    postal_code: "37090",
    full_city_name: "Lebanon, TN",
    city_name: "Lebanon",
  },
  {
    postal_code: "37854",
    full_city_name: "Rockwood, TN",
    city_name: "Rockwood",
  },
  {
    postal_code: "31548",
    full_city_name: "Kingsland, GA",
    city_name: "Kingsland",
  },
  {
    postal_code: "95366",
    full_city_name: "Ripon, CA",
    city_name: "Ripon",
  },
  {
    postal_code: "33167",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "21787",
    full_city_name: "Taneytown, MD",
    city_name: "Taneytown",
  },
  {
    postal_code: "92119",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "26554",
    full_city_name: "Fairmont, WV",
    city_name: "Fairmont",
  },
  {
    postal_code: "80401",
    full_city_name: "Golden, CO",
    city_name: "Golden",
  },
  {
    postal_code: "48125",
    full_city_name: "Dearborn Heights, MI",
    city_name: "Dearborn Heights",
  },
  {
    postal_code: "98274",
    full_city_name: "Mount Vernon, WA",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "11951",
    full_city_name: "Mastic Beach, NY",
    city_name: "Mastic Beach",
  },
  {
    postal_code: "80621",
    full_city_name: "Fort Lupton, CO",
    city_name: "Fort Lupton",
  },
  {
    postal_code: "01810",
    full_city_name: "Andover, MA",
    city_name: "Andover",
  },
  {
    postal_code: "06333",
    full_city_name: "East Lyme, CT",
    city_name: "East Lyme",
  },
  {
    postal_code: "64113",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "38114",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "30707",
    full_city_name: "Chickamauga, GA",
    city_name: "Chickamauga",
  },
  {
    postal_code: "33069",
    full_city_name: "Pompano Beach, FL",
    city_name: "Pompano Beach",
  },
  {
    postal_code: "76801",
    full_city_name: "Brownwood, TX",
    city_name: "Brownwood",
  },
  {
    postal_code: "07860",
    full_city_name: "Newton, NJ",
    city_name: "Newton",
  },
  {
    postal_code: "98665",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "19152",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "92808",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "98166",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "93730",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "28036",
    full_city_name: "Davidson, NC",
    city_name: "Davidson",
  },
  {
    postal_code: "75077",
    full_city_name: "Lewisville, TX",
    city_name: "Lewisville",
  },
  {
    postal_code: "73801",
    full_city_name: "Woodward, OK",
    city_name: "Woodward",
  },
  {
    postal_code: "52804",
    full_city_name: "Davenport, IA",
    city_name: "Davenport",
  },
  {
    postal_code: "11575",
    full_city_name: "Roosevelt, NY",
    city_name: "Roosevelt",
  },
  {
    postal_code: "20024",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "97005",
    full_city_name: "Beaverton, OR",
    city_name: "Beaverton",
  },
  {
    postal_code: "63010",
    full_city_name: "Arnold, MO",
    city_name: "Arnold",
  },
  {
    postal_code: "61356",
    full_city_name: "Princeton, IL",
    city_name: "Princeton",
  },
  {
    postal_code: "98942",
    full_city_name: "Selah, WA",
    city_name: "Selah",
  },
  {
    postal_code: "83401",
    full_city_name: "Idaho Falls, ID",
    city_name: "Idaho Falls",
  },
  {
    postal_code: "94085",
    full_city_name: "Sunnyvale, CA",
    city_name: "Sunnyvale",
  },
  {
    postal_code: "43609",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "44041",
    full_city_name: "Geneva, OH",
    city_name: "Geneva",
  },
  {
    postal_code: "45801",
    full_city_name: "Lima, OH",
    city_name: "Lima",
  },
  {
    postal_code: "18901",
    full_city_name: "Doylestown, PA",
    city_name: "Doylestown",
  },
  {
    postal_code: "35504",
    full_city_name: "Jasper, AL",
    city_name: "Jasper",
  },
  {
    postal_code: "35208",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "91737",
    full_city_name: "Rancho Cucamonga, CA",
    city_name: "Rancho Cucamonga",
  },
  {
    postal_code: "17545",
    full_city_name: "Manheim, PA",
    city_name: "Manheim",
  },
  {
    postal_code: "49015",
    full_city_name: "Battle Creek, MI",
    city_name: "Battle Creek",
  },
  {
    postal_code: "67337",
    full_city_name: "Coffeyville, KS",
    city_name: "Coffeyville",
  },
  {
    postal_code: "21229",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "29073",
    full_city_name: "Lexington, SC",
    city_name: "Lexington",
  },
  {
    postal_code: "18302",
    full_city_name: "East Stroudsburg, PA",
    city_name: "East Stroudsburg",
  },
  {
    postal_code: "67230",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "19060",
    full_city_name: "Garnet Valley, PA",
    city_name: "Garnet Valley",
  },
  {
    postal_code: "73010",
    full_city_name: "Blanchard, OK",
    city_name: "Blanchard",
  },
  {
    postal_code: "11963",
    full_city_name: "Sag Harbor, NY",
    city_name: "Sag Harbor",
  },
  {
    postal_code: "08701",
    full_city_name: "Lakewood, NJ",
    city_name: "Lakewood",
  },
  {
    postal_code: "91001",
    full_city_name: "Altadena, CA",
    city_name: "Altadena",
  },
  {
    postal_code: "90402",
    full_city_name: "Santa Monica, CA",
    city_name: "Santa Monica",
  },
  {
    postal_code: "83406",
    full_city_name: "Idaho Falls, ID",
    city_name: "Idaho Falls",
  },
  {
    postal_code: "93306",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "60521",
    full_city_name: "Hinsdale, IL",
    city_name: "Hinsdale",
  },
  {
    postal_code: "28717",
    full_city_name: "Cashiers, NC",
    city_name: "Cashiers",
  },
  {
    postal_code: "38611",
    full_city_name: "Byhalia, MS",
    city_name: "Byhalia",
  },
  {
    postal_code: "08807",
    full_city_name: "Bridgewater, NJ",
    city_name: "Bridgewater",
  },
  {
    postal_code: "78840",
    full_city_name: "Del Rio, TX",
    city_name: "Del Rio",
  },
  {
    postal_code: "64060",
    full_city_name: "Kearney, MO",
    city_name: "Kearney",
  },
  {
    postal_code: "98801",
    full_city_name: "Wenatchee, WA",
    city_name: "Wenatchee",
  },
  {
    postal_code: "77484",
    full_city_name: "Waller, TX",
    city_name: "Waller",
  },
  {
    postal_code: "29579",
    full_city_name: "Myrtle Beach, SC",
    city_name: "Myrtle Beach",
  },
  {
    postal_code: "48093",
    full_city_name: "Warren, MI",
    city_name: "Warren",
  },
  {
    postal_code: "11726",
    full_city_name: "Copiague, NY",
    city_name: "Copiague",
  },
  {
    postal_code: "48213",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "11694",
    full_city_name: "Rockaway Park, NY",
    city_name: "Rockaway Park",
  },
  {
    postal_code: "92054",
    full_city_name: "Oceanside, CA",
    city_name: "Oceanside",
  },
  {
    postal_code: "23140",
    full_city_name: "Providence Forge, VA",
    city_name: "Providence Forge",
  },
  {
    postal_code: "94565",
    full_city_name: "Pittsburg, CA",
    city_name: "Pittsburg",
  },
  {
    postal_code: "68154",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "45236",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "80260",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "92701",
    full_city_name: "Santa Ana, CA",
    city_name: "Santa Ana",
  },
  {
    postal_code: "22306",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "47448",
    full_city_name: "Nashville, IN",
    city_name: "Nashville",
  },
  {
    postal_code: "90660",
    full_city_name: "Pico Rivera, CA",
    city_name: "Pico Rivera",
  },
  {
    postal_code: "53703",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "91740",
    full_city_name: "Glendora, CA",
    city_name: "Glendora",
  },
  {
    postal_code: "35125",
    full_city_name: "Pell City, AL",
    city_name: "Pell City",
  },
  {
    postal_code: "42003",
    full_city_name: "Paducah, KY",
    city_name: "Paducah",
  },
  {
    postal_code: "49078",
    full_city_name: "Otsego, MI",
    city_name: "Otsego",
  },
  {
    postal_code: "32833",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "57719",
    full_city_name: "Box Elder, SD",
    city_name: "Box Elder",
  },
  {
    postal_code: "56201",
    full_city_name: "Willmar, MN",
    city_name: "Willmar",
  },
  {
    postal_code: "60173",
    full_city_name: "Schaumburg, IL",
    city_name: "Schaumburg",
  },
  {
    postal_code: "66207",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "60645",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "92807",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "37406",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "97266",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "81632",
    full_city_name: "Edwards, CO",
    city_name: "Edwards",
  },
  {
    postal_code: "60136",
    full_city_name: "Gilberts, IL",
    city_name: "Gilberts",
  },
  {
    postal_code: "11577",
    full_city_name: "Roslyn Heights, NY",
    city_name: "Roslyn Heights",
  },
  {
    postal_code: "72032",
    full_city_name: "Conway, AR",
    city_name: "Conway",
  },
  {
    postal_code: "11367",
    full_city_name: "Flushing, NY",
    city_name: "Flushing",
  },
  {
    postal_code: "33924",
    full_city_name: "Captiva, FL",
    city_name: "Captiva",
  },
  {
    postal_code: "89005",
    full_city_name: "Boulder City, NV",
    city_name: "Boulder City",
  },
  {
    postal_code: "29550",
    full_city_name: "Hartsville, SC",
    city_name: "Hartsville",
  },
  {
    postal_code: "71360",
    full_city_name: "Pineville, LA",
    city_name: "Pineville",
  },
  {
    postal_code: "35111",
    full_city_name: "Mc Calla, AL",
    city_name: "Mc Calla",
  },
  {
    postal_code: "27915",
    full_city_name: "Avon, NC",
    city_name: "Avon",
  },
  {
    postal_code: "35096",
    full_city_name: "Lincoln, AL",
    city_name: "Lincoln",
  },
  {
    postal_code: "80027",
    full_city_name: "Louisville, CO",
    city_name: "Louisville",
  },
  {
    postal_code: "67037",
    full_city_name: "Derby, KS",
    city_name: "Derby",
  },
  {
    postal_code: "53705",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "08751",
    full_city_name: "Seaside Heights, NJ",
    city_name: "Seaside Heights",
  },
  {
    postal_code: "19119",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "32548",
    full_city_name: "Fort Walton Beach, FL",
    city_name: "Fort Walton Beach",
  },
  {
    postal_code: "85730",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "27608",
    full_city_name: "Raleigh, NC",
    city_name: "Raleigh",
  },
  {
    postal_code: "32223",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "13669",
    full_city_name: "Ogdensburg, NY",
    city_name: "Ogdensburg",
  },
  {
    postal_code: "80303",
    full_city_name: "Boulder, CO",
    city_name: "Boulder",
  },
  {
    postal_code: "17110",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "66218",
    full_city_name: "Shawnee, KS",
    city_name: "Shawnee",
  },
  {
    postal_code: "24104",
    full_city_name: "Huddleston, VA",
    city_name: "Huddleston",
  },
  {
    postal_code: "35079",
    full_city_name: "Hayden, AL",
    city_name: "Hayden",
  },
  {
    postal_code: "60532",
    full_city_name: "Lisle, IL",
    city_name: "Lisle",
  },
  {
    postal_code: "07090",
    full_city_name: "Westfield, NJ",
    city_name: "Westfield",
  },
  {
    postal_code: "75041",
    full_city_name: "Garland, TX",
    city_name: "Garland",
  },
  {
    postal_code: "11542",
    full_city_name: "Glen Cove, NY",
    city_name: "Glen Cove",
  },
  {
    postal_code: "89448",
    full_city_name: "Zephyr Cove, NV",
    city_name: "Zephyr Cove",
  },
  {
    postal_code: "95677",
    full_city_name: "Rocklin, CA",
    city_name: "Rocklin",
  },
  {
    postal_code: "60403",
    full_city_name: "Crest Hill, IL",
    city_name: "Crest Hill",
  },
  {
    postal_code: "80102",
    full_city_name: "Bennett, CO",
    city_name: "Bennett",
  },
  {
    postal_code: "36530",
    full_city_name: "Elberta, AL",
    city_name: "Elberta",
  },
  {
    postal_code: "97267",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "55401",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "75166",
    full_city_name: "Lavon, TX",
    city_name: "Lavon",
  },
  {
    postal_code: "67206",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "30187",
    full_city_name: "Winston, GA",
    city_name: "Winston",
  },
  {
    postal_code: "21617",
    full_city_name: "Centreville, MD",
    city_name: "Centreville",
  },
  {
    postal_code: "17406",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "91007",
    full_city_name: "Arcadia, CA",
    city_name: "Arcadia",
  },
  {
    postal_code: "17522",
    full_city_name: "Ephrata, PA",
    city_name: "Ephrata",
  },
  {
    postal_code: "37934",
    full_city_name: "Farragut, TN",
    city_name: "Farragut",
  },
  {
    postal_code: "20186",
    full_city_name: "Warrenton, VA",
    city_name: "Warrenton",
  },
  {
    postal_code: "77053",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "23185",
    full_city_name: "Williamsburg, VA",
    city_name: "Williamsburg",
  },
  {
    postal_code: "45205",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "85051",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "08401",
    full_city_name: "Atlantic City, NJ",
    city_name: "Atlantic City",
  },
  {
    postal_code: "18944",
    full_city_name: "Perkasie, PA",
    city_name: "Perkasie",
  },
  {
    postal_code: "33187",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "94303",
    full_city_name: "Palo Alto, CA",
    city_name: "Palo Alto",
  },
  {
    postal_code: "21146",
    full_city_name: "Severna Park, MD",
    city_name: "Severna Park",
  },
  {
    postal_code: "08833",
    full_city_name: "Lebanon, NJ",
    city_name: "Lebanon",
  },
  {
    postal_code: "64772",
    full_city_name: "Nevada, MO",
    city_name: "Nevada",
  },
  {
    postal_code: "63074",
    full_city_name: "Saint Ann, MO",
    city_name: "Saint Ann",
  },
  {
    postal_code: "77034",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "91706",
    full_city_name: "Baldwin Park, CA",
    city_name: "Baldwin Park",
  },
  {
    postal_code: "14223",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "97060",
    full_city_name: "Troutdale, OR",
    city_name: "Troutdale",
  },
  {
    postal_code: "23834",
    full_city_name: "Colonial Heights, VA",
    city_name: "Colonial Heights",
  },
  {
    postal_code: "94103",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "38134",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "92056",
    full_city_name: "Oceanside, CA",
    city_name: "Oceanside",
  },
  {
    postal_code: "22503",
    full_city_name: "Lancaster, VA",
    city_name: "Lancaster",
  },
  {
    postal_code: "98516",
    full_city_name: "Olympia, WA",
    city_name: "Olympia",
  },
  {
    postal_code: "14086",
    full_city_name: "Lancaster, NY",
    city_name: "Lancaster",
  },
  {
    postal_code: "83686",
    full_city_name: "Nampa, ID",
    city_name: "Nampa",
  },
  {
    postal_code: "75418",
    full_city_name: "Bonham, TX",
    city_name: "Bonham",
  },
  {
    postal_code: "19947",
    full_city_name: "Georgetown, DE",
    city_name: "Georgetown",
  },
  {
    postal_code: "22039",
    full_city_name: "Fairfax Station, VA",
    city_name: "Fairfax Station",
  },
  {
    postal_code: "92115",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "48653",
    full_city_name: "Roscommon, MI",
    city_name: "Roscommon",
  },
  {
    postal_code: "73071",
    full_city_name: "Norman, OK",
    city_name: "Norman",
  },
  {
    postal_code: "39520",
    full_city_name: "Bay Saint Louis, MS",
    city_name: "Bay Saint Louis",
  },
  {
    postal_code: "20879",
    full_city_name: "Gaithersburg, MD",
    city_name: "Gaithersburg",
  },
  {
    postal_code: "70810",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "81615",
    full_city_name: "Snowmass Village, CO",
    city_name: "Snowmass Village",
  },
  {
    postal_code: "26260",
    full_city_name: "Davis, WV",
    city_name: "Davis",
  },
  {
    postal_code: "08087",
    full_city_name: "Tuckerton, NJ",
    city_name: "Tuckerton",
  },
  {
    postal_code: "77845",
    full_city_name: "College Station, TX",
    city_name: "College Station",
  },
  {
    postal_code: "43085",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "75103",
    full_city_name: "Canton, TX",
    city_name: "Canton",
  },
  {
    postal_code: "59701",
    full_city_name: "Butte, MT",
    city_name: "Butte",
  },
  {
    postal_code: "35473",
    full_city_name: "Northport, AL",
    city_name: "Northport",
  },
  {
    postal_code: "80010",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "21221",
    full_city_name: "Essex, MD",
    city_name: "Essex",
  },
  {
    postal_code: "97219",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "15644",
    full_city_name: "Jeannette, PA",
    city_name: "Jeannette",
  },
  {
    postal_code: "49010",
    full_city_name: "Allegan, MI",
    city_name: "Allegan",
  },
  {
    postal_code: "97058",
    full_city_name: "The Dalles, OR",
    city_name: "The Dalles",
  },
  {
    postal_code: "89519",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "39204",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "07920",
    full_city_name: "Basking Ridge, NJ",
    city_name: "Basking Ridge",
  },
  {
    postal_code: "89141",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "76105",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "74728",
    full_city_name: "Broken Bow, OK",
    city_name: "Broken Bow",
  },
  {
    postal_code: "95422",
    full_city_name: "Clearlake, CA",
    city_name: "Clearlake",
  },
  {
    postal_code: "58854",
    full_city_name: "Watford City, ND",
    city_name: "Watford City",
  },
  {
    postal_code: "32570",
    full_city_name: "Milton, FL",
    city_name: "Milton",
  },
  {
    postal_code: "70118",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "80543",
    full_city_name: "Milliken, CO",
    city_name: "Milliken",
  },
  {
    postal_code: "52807",
    full_city_name: "Davenport, IA",
    city_name: "Davenport",
  },
  {
    postal_code: "45505",
    full_city_name: "Springfield, OH",
    city_name: "Springfield",
  },
  {
    postal_code: "98008",
    full_city_name: "Bellevue, WA",
    city_name: "Bellevue",
  },
  {
    postal_code: "46375",
    full_city_name: "Schererville, IN",
    city_name: "Schererville",
  },
  {
    postal_code: "85284",
    full_city_name: "Tempe, AZ",
    city_name: "Tempe",
  },
  {
    postal_code: "84720",
    full_city_name: "Cedar City, UT",
    city_name: "Cedar City",
  },
  {
    postal_code: "11722",
    full_city_name: "Central Islip, NY",
    city_name: "Central Islip",
  },
  {
    postal_code: "60418",
    full_city_name: "Crestwood, IL",
    city_name: "Crestwood",
  },
  {
    postal_code: "14420",
    full_city_name: "Brockport, NY",
    city_name: "Brockport",
  },
  {
    postal_code: "31804",
    full_city_name: "Cataula, GA",
    city_name: "Cataula",
  },
  {
    postal_code: "97031",
    full_city_name: "Hood River, OR",
    city_name: "Hood River",
  },
  {
    postal_code: "49053",
    full_city_name: "Galesburg, MI",
    city_name: "Galesburg",
  },
  {
    postal_code: "71446",
    full_city_name: "Leesville, LA",
    city_name: "Leesville",
  },
  {
    postal_code: "75205",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "23002",
    full_city_name: "Amelia Court House, VA",
    city_name: "Amelia Court House",
  },
  {
    postal_code: "94947",
    full_city_name: "Novato, CA",
    city_name: "Novato",
  },
  {
    postal_code: "60449",
    full_city_name: "Monee, IL",
    city_name: "Monee",
  },
  {
    postal_code: "65026",
    full_city_name: "Eldon, MO",
    city_name: "Eldon",
  },
  {
    postal_code: "95148",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "14901",
    full_city_name: "Elmira, NY",
    city_name: "Elmira",
  },
  {
    postal_code: "20640",
    full_city_name: "Indian Head, MD",
    city_name: "Indian Head",
  },
  {
    postal_code: "37055",
    full_city_name: "Dickson, TN",
    city_name: "Dickson",
  },
  {
    postal_code: "22203",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "93908",
    full_city_name: "Salinas, CA",
    city_name: "Salinas",
  },
  {
    postal_code: "06351",
    full_city_name: "Jewett City, CT",
    city_name: "Jewett City",
  },
  {
    postal_code: "94590",
    full_city_name: "Vallejo, CA",
    city_name: "Vallejo",
  },
  {
    postal_code: "08065",
    full_city_name: "Palmyra, NJ",
    city_name: "Palmyra",
  },
  {
    postal_code: "22303",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "92021",
    full_city_name: "El Cajon, CA",
    city_name: "El Cajon",
  },
  {
    postal_code: "85286",
    full_city_name: "Chandler, AZ",
    city_name: "Chandler",
  },
  {
    postal_code: "92069",
    full_city_name: "San Marcos, CA",
    city_name: "San Marcos",
  },
  {
    postal_code: "08083",
    full_city_name: "Somerdale, NJ",
    city_name: "Somerdale",
  },
  {
    postal_code: "70776",
    full_city_name: "Saint Gabriel, LA",
    city_name: "Saint Gabriel",
  },
  {
    postal_code: "30554",
    full_city_name: "Lula, GA",
    city_name: "Lula",
  },
  {
    postal_code: "39272",
    full_city_name: "Byram, MS",
    city_name: "Byram",
  },
  {
    postal_code: "90808",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "97224",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "20037",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "30363",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "21117",
    full_city_name: "Owings Mills, MD",
    city_name: "Owings Mills",
  },
  {
    postal_code: "60187",
    full_city_name: "Wheaton, IL",
    city_name: "Wheaton",
  },
  {
    postal_code: "38237",
    full_city_name: "Martin, TN",
    city_name: "Martin",
  },
  {
    postal_code: "13215",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "20895",
    full_city_name: "Kensington, MD",
    city_name: "Kensington",
  },
  {
    postal_code: "97503",
    full_city_name: "White City, OR",
    city_name: "White City",
  },
  {
    postal_code: "54476",
    full_city_name: "Schofield, WI",
    city_name: "Schofield",
  },
  {
    postal_code: "33770",
    full_city_name: "Largo, FL",
    city_name: "Largo",
  },
  {
    postal_code: "95050",
    full_city_name: "Santa Clara, CA",
    city_name: "Santa Clara",
  },
  {
    postal_code: "67156",
    full_city_name: "Winfield, KS",
    city_name: "Winfield",
  },
  {
    postal_code: "48842",
    full_city_name: "Holt, MI",
    city_name: "Holt",
  },
  {
    postal_code: "12170",
    full_city_name: "Stillwater, NY",
    city_name: "Stillwater",
  },
  {
    postal_code: "92123",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "70117",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "08865",
    full_city_name: "Phillipsburg, NJ",
    city_name: "Phillipsburg",
  },
  {
    postal_code: "30458",
    full_city_name: "Statesboro, GA",
    city_name: "Statesboro",
  },
  {
    postal_code: "07076",
    full_city_name: "Scotch Plains, NJ",
    city_name: "Scotch Plains",
  },
  {
    postal_code: "56716",
    full_city_name: "Crookston, MN",
    city_name: "Crookston",
  },
  {
    postal_code: "70455",
    full_city_name: "Robert, LA",
    city_name: "Robert",
  },
  {
    postal_code: "57754",
    full_city_name: "Lead, SD",
    city_name: "Lead",
  },
  {
    postal_code: "90807",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "13367",
    full_city_name: "Lowville, NY",
    city_name: "Lowville",
  },
  {
    postal_code: "80212",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "29153",
    full_city_name: "Sumter, SC",
    city_name: "Sumter",
  },
  {
    postal_code: "28584",
    full_city_name: "Swansboro, NC",
    city_name: "Swansboro",
  },
  {
    postal_code: "02155",
    full_city_name: "Medford, MA",
    city_name: "Medford",
  },
  {
    postal_code: "95135",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "15061",
    full_city_name: "Monaca, PA",
    city_name: "Monaca",
  },
  {
    postal_code: "54409",
    full_city_name: "Antigo, WI",
    city_name: "Antigo",
  },
  {
    postal_code: "42066",
    full_city_name: "Mayfield, KY",
    city_name: "Mayfield",
  },
  {
    postal_code: "11741",
    full_city_name: "Holbrook, NY",
    city_name: "Holbrook",
  },
  {
    postal_code: "55346",
    full_city_name: "Eden Prairie, MN",
    city_name: "Eden Prairie",
  },
  {
    postal_code: "27330",
    full_city_name: "Sanford, NC",
    city_name: "Sanford",
  },
  {
    postal_code: "33635",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "80403",
    full_city_name: "Golden, CO",
    city_name: "Golden",
  },
  {
    postal_code: "48150",
    full_city_name: "Livonia, MI",
    city_name: "Livonia",
  },
  {
    postal_code: "49307",
    full_city_name: "Big Rapids, MI",
    city_name: "Big Rapids",
  },
  {
    postal_code: "81507",
    full_city_name: "Grand Junction, CO",
    city_name: "Grand Junction",
  },
  {
    postal_code: "48382",
    full_city_name: "Commerce Township, MI",
    city_name: "Commerce Township",
  },
  {
    postal_code: "46218",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "70669",
    full_city_name: "Westlake, LA",
    city_name: "Westlake",
  },
  {
    postal_code: "92028",
    full_city_name: "Fallbrook, CA",
    city_name: "Fallbrook",
  },
  {
    postal_code: "04401",
    full_city_name: "Bangor, ME",
    city_name: "Bangor",
  },
  {
    postal_code: "22042",
    full_city_name: "Falls Church, VA",
    city_name: "Falls Church",
  },
  {
    postal_code: "33584",
    full_city_name: "Seffner, FL",
    city_name: "Seffner",
  },
  {
    postal_code: "92075",
    full_city_name: "Solana Beach, CA",
    city_name: "Solana Beach",
  },
  {
    postal_code: "80915",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "80231",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "90025",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "13069",
    full_city_name: "Fulton, NY",
    city_name: "Fulton",
  },
  {
    postal_code: "68142",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "07882",
    full_city_name: "Washington, NJ",
    city_name: "Washington",
  },
  {
    postal_code: "33141",
    full_city_name: "Miami Beach, FL",
    city_name: "Miami Beach",
  },
  {
    postal_code: "03045",
    full_city_name: "Goffstown, NH",
    city_name: "Goffstown",
  },
  {
    postal_code: "10021",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "31632",
    full_city_name: "Hahira, GA",
    city_name: "Hahira",
  },
  {
    postal_code: "70726",
    full_city_name: "Denham Springs, LA",
    city_name: "Denham Springs",
  },
  {
    postal_code: "20740",
    full_city_name: "College Park, MD",
    city_name: "College Park",
  },
  {
    postal_code: "66210",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "94549",
    full_city_name: "Lafayette, CA",
    city_name: "Lafayette",
  },
  {
    postal_code: "55403",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "48088",
    full_city_name: "Warren, MI",
    city_name: "Warren",
  },
  {
    postal_code: "32408",
    full_city_name: "Panama City, FL",
    city_name: "Panama City",
  },
  {
    postal_code: "73644",
    full_city_name: "Elk City, OK",
    city_name: "Elk City",
  },
  {
    postal_code: "33179",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "35223",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "30263",
    full_city_name: "Newnan, GA",
    city_name: "Newnan",
  },
  {
    postal_code: "35055",
    full_city_name: "Cullman, AL",
    city_name: "Cullman",
  },
  {
    postal_code: "18104",
    full_city_name: "Allentown, PA",
    city_name: "Allentown",
  },
  {
    postal_code: "46241",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "14103",
    full_city_name: "Medina, NY",
    city_name: "Medina",
  },
  {
    postal_code: "43551",
    full_city_name: "Perrysburg, OH",
    city_name: "Perrysburg",
  },
  {
    postal_code: "95128",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "54937",
    full_city_name: "Fond Du Lac, WI",
    city_name: "Fond Du Lac",
  },
  {
    postal_code: "85131",
    full_city_name: "Eloy, AZ",
    city_name: "Eloy",
  },
  {
    postal_code: "91744",
    full_city_name: "La Puente, CA",
    city_name: "La Puente",
  },
  {
    postal_code: "96766",
    full_city_name: "Lihue, HI",
    city_name: "Lihue",
  },
  {
    postal_code: "78541",
    full_city_name: "Edinburg, TX",
    city_name: "Edinburg",
  },
  {
    postal_code: "36567",
    full_city_name: "Robertsdale, AL",
    city_name: "Robertsdale",
  },
  {
    postal_code: "98199",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "44017",
    full_city_name: "Berea, OH",
    city_name: "Berea",
  },
  {
    postal_code: "49548",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "32507",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "12077",
    full_city_name: "Glenmont, NY",
    city_name: "Glenmont",
  },
  {
    postal_code: "54806",
    full_city_name: "Ashland, WI",
    city_name: "Ashland",
  },
  {
    postal_code: "78656",
    full_city_name: "Maxwell, TX",
    city_name: "Maxwell",
  },
  {
    postal_code: "93636",
    full_city_name: "Madera, CA",
    city_name: "Madera",
  },
  {
    postal_code: "58203",
    full_city_name: "Grand Forks, ND",
    city_name: "Grand Forks",
  },
  {
    postal_code: "36092",
    full_city_name: "Wetumpka, AL",
    city_name: "Wetumpka",
  },
  {
    postal_code: "48098",
    full_city_name: "Troy, MI",
    city_name: "Troy",
  },
  {
    postal_code: "45065",
    full_city_name: "South Lebanon, OH",
    city_name: "South Lebanon",
  },
  {
    postal_code: "47725",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "78753",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "35117",
    full_city_name: "Mount Olive, AL",
    city_name: "Mount Olive",
  },
  {
    postal_code: "85307",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "20732",
    full_city_name: "Chesapeake Beach, MD",
    city_name: "Chesapeake Beach",
  },
  {
    postal_code: "23323",
    full_city_name: "Chesapeake, VA",
    city_name: "Chesapeake",
  },
  {
    postal_code: "77551",
    full_city_name: "Galveston, TX",
    city_name: "Galveston",
  },
  {
    postal_code: "28080",
    full_city_name: "Iron Station, NC",
    city_name: "Iron Station",
  },
  {
    postal_code: "60154",
    full_city_name: "Westchester, IL",
    city_name: "Westchester",
  },
  {
    postal_code: "80109",
    full_city_name: "Castle Rock, CO",
    city_name: "Castle Rock",
  },
  {
    postal_code: "91006",
    full_city_name: "Arcadia, CA",
    city_name: "Arcadia",
  },
  {
    postal_code: "80863",
    full_city_name: "Woodland Park, CO",
    city_name: "Woodland Park",
  },
  {
    postal_code: "12561",
    full_city_name: "New Paltz, NY",
    city_name: "New Paltz",
  },
  {
    postal_code: "11361",
    full_city_name: "Bayside, NY",
    city_name: "Bayside",
  },
  {
    postal_code: "80461",
    full_city_name: "Leadville, CO",
    city_name: "Leadville",
  },
  {
    postal_code: "20784",
    full_city_name: "Hyattsville, MD",
    city_name: "Hyattsville",
  },
  {
    postal_code: "48237",
    full_city_name: "Oak Park, MI",
    city_name: "Oak Park",
  },
  {
    postal_code: "32179",
    full_city_name: "Ocklawaha, FL",
    city_name: "Ocklawaha",
  },
  {
    postal_code: "19128",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "07470",
    full_city_name: "Wayne, NJ",
    city_name: "Wayne",
  },
  {
    postal_code: "98903",
    full_city_name: "Yakima, WA",
    city_name: "Yakima",
  },
  {
    postal_code: "12572",
    full_city_name: "Rhinebeck, NY",
    city_name: "Rhinebeck",
  },
  {
    postal_code: "38107",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "20783",
    full_city_name: "Hyattsville, MD",
    city_name: "Hyattsville",
  },
  {
    postal_code: "29102",
    full_city_name: "Manning, SC",
    city_name: "Manning",
  },
  {
    postal_code: "53563",
    full_city_name: "Milton, WI",
    city_name: "Milton",
  },
  {
    postal_code: "31052",
    full_city_name: "Lizella, GA",
    city_name: "Lizella",
  },
  {
    postal_code: "99516",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "92227",
    full_city_name: "Brawley, CA",
    city_name: "Brawley",
  },
  {
    postal_code: "34429",
    full_city_name: "Crystal River, FL",
    city_name: "Crystal River",
  },
  {
    postal_code: "32693",
    full_city_name: "Trenton, FL",
    city_name: "Trenton",
  },
  {
    postal_code: "11735",
    full_city_name: "Farmingdale, NY",
    city_name: "Farmingdale",
  },
  {
    postal_code: "85017",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "29036",
    full_city_name: "Chapin, SC",
    city_name: "Chapin",
  },
  {
    postal_code: "85749",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "64050",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "89410",
    full_city_name: "Gardnerville, NV",
    city_name: "Gardnerville",
  },
  {
    postal_code: "46074",
    full_city_name: "Westfield, IN",
    city_name: "Westfield",
  },
  {
    postal_code: "71953",
    full_city_name: "Mena, AR",
    city_name: "Mena",
  },
  {
    postal_code: "60410",
    full_city_name: "Channahon, IL",
    city_name: "Channahon",
  },
  {
    postal_code: "60417",
    full_city_name: "Crete, IL",
    city_name: "Crete",
  },
  {
    postal_code: "08520",
    full_city_name: "Hightstown, NJ",
    city_name: "Hightstown",
  },
  {
    postal_code: "32949",
    full_city_name: "Grant, FL",
    city_name: "Grant",
  },
  {
    postal_code: "88011",
    full_city_name: "Las Cruces, NM",
    city_name: "Las Cruces",
  },
  {
    postal_code: "97477",
    full_city_name: "Springfield, OR",
    city_name: "Springfield",
  },
  {
    postal_code: "17257",
    full_city_name: "Shippensburg, PA",
    city_name: "Shippensburg",
  },
  {
    postal_code: "25428",
    full_city_name: "Inwood, WV",
    city_name: "Inwood",
  },
  {
    postal_code: "49306",
    full_city_name: "Belmont, MI",
    city_name: "Belmont",
  },
  {
    postal_code: "76209",
    full_city_name: "Denton, TX",
    city_name: "Denton",
  },
  {
    postal_code: "18350",
    full_city_name: "Pocono Pines, PA",
    city_name: "Pocono Pines",
  },
  {
    postal_code: "95118",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "21774",
    full_city_name: "New Market, MD",
    city_name: "New Market",
  },
  {
    postal_code: "49036",
    full_city_name: "Coldwater, MI",
    city_name: "Coldwater",
  },
  {
    postal_code: "23188",
    full_city_name: "Williamsburg, VA",
    city_name: "Williamsburg",
  },
  {
    postal_code: "80127",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "33637",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "12569",
    full_city_name: "Pleasant Valley, NY",
    city_name: "Pleasant Valley",
  },
  {
    postal_code: "76905",
    full_city_name: "San Angelo, TX",
    city_name: "San Angelo",
  },
  {
    postal_code: "03801",
    full_city_name: "Portsmouth, NH",
    city_name: "Portsmouth",
  },
  {
    postal_code: "27858",
    full_city_name: "Greenville, NC",
    city_name: "Greenville",
  },
  {
    postal_code: "47712",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "37013",
    full_city_name: "Antioch, TN",
    city_name: "Antioch",
  },
  {
    postal_code: "32176",
    full_city_name: "Ormond Beach, FL",
    city_name: "Ormond Beach",
  },
  {
    postal_code: "66204",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "80514",
    full_city_name: "Dacono, CO",
    city_name: "Dacono",
  },
  {
    postal_code: "93726",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "12306",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "92602",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "70605",
    full_city_name: "Lake Charles, LA",
    city_name: "Lake Charles",
  },
  {
    postal_code: "65355",
    full_city_name: "Warsaw, MO",
    city_name: "Warsaw",
  },
  {
    postal_code: "20748",
    full_city_name: "Temple Hills, MD",
    city_name: "Temple Hills",
  },
  {
    postal_code: "33615",
    full_city_name: "Tampa, FL",
    city_name: "Tampa",
  },
  {
    postal_code: "33946",
    full_city_name: "Placida, FL",
    city_name: "Placida",
  },
  {
    postal_code: "97230",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "20902",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "29935",
    full_city_name: "Port Royal, SC",
    city_name: "Port Royal",
  },
  {
    postal_code: "07762",
    full_city_name: "Spring Lake, NJ",
    city_name: "Spring Lake",
  },
  {
    postal_code: "72002",
    full_city_name: "Alexander, AR",
    city_name: "Alexander",
  },
  {
    postal_code: "40065",
    full_city_name: "Shelbyville, KY",
    city_name: "Shelbyville",
  },
  {
    postal_code: "80446",
    full_city_name: "Granby, CO",
    city_name: "Granby",
  },
  {
    postal_code: "93105",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "38506",
    full_city_name: "Cookeville, TN",
    city_name: "Cookeville",
  },
  {
    postal_code: "75173",
    full_city_name: "Nevada, TX",
    city_name: "Nevada",
  },
  {
    postal_code: "60526",
    full_city_name: "La Grange Park, IL",
    city_name: "La Grange Park",
  },
  {
    postal_code: "85650",
    full_city_name: "Sierra Vista, AZ",
    city_name: "Sierra Vista",
  },
  {
    postal_code: "99353",
    full_city_name: "West Richland, WA",
    city_name: "West Richland",
  },
  {
    postal_code: "72727",
    full_city_name: "Elkins, AR",
    city_name: "Elkins",
  },
  {
    postal_code: "98273",
    full_city_name: "Mount Vernon, WA",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "32579",
    full_city_name: "Shalimar, FL",
    city_name: "Shalimar",
  },
  {
    postal_code: "78219",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "52577",
    full_city_name: "Oskaloosa, IA",
    city_name: "Oskaloosa",
  },
  {
    postal_code: "49401",
    full_city_name: "Allendale, MI",
    city_name: "Allendale",
  },
  {
    postal_code: "20770",
    full_city_name: "Greenbelt, MD",
    city_name: "Greenbelt",
  },
  {
    postal_code: "28748",
    full_city_name: "Leicester, NC",
    city_name: "Leicester",
  },
  {
    postal_code: "95060",
    full_city_name: "Santa Cruz, CA",
    city_name: "Santa Cruz",
  },
  {
    postal_code: "46075",
    full_city_name: "Whitestown, IN",
    city_name: "Whitestown",
  },
  {
    postal_code: "20181",
    full_city_name: "Nokesville, VA",
    city_name: "Nokesville",
  },
  {
    postal_code: "08724",
    full_city_name: "Brick, NJ",
    city_name: "Brick",
  },
  {
    postal_code: "30622",
    full_city_name: "Bogart, GA",
    city_name: "Bogart",
  },
  {
    postal_code: "07950",
    full_city_name: "Morris Plains, NJ",
    city_name: "Morris Plains",
  },
  {
    postal_code: "32055",
    full_city_name: "Lake City, FL",
    city_name: "Lake City",
  },
  {
    postal_code: "48183",
    full_city_name: "Trenton, MI",
    city_name: "Trenton",
  },
  {
    postal_code: "64468",
    full_city_name: "Maryville, MO",
    city_name: "Maryville",
  },
  {
    postal_code: "49127",
    full_city_name: "Stevensville, MI",
    city_name: "Stevensville",
  },
  {
    postal_code: "78410",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "97502",
    full_city_name: "Central Point, OR",
    city_name: "Central Point",
  },
  {
    postal_code: "70520",
    full_city_name: "Carencro, LA",
    city_name: "Carencro",
  },
  {
    postal_code: "68131",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "31602",
    full_city_name: "Valdosta, GA",
    city_name: "Valdosta",
  },
  {
    postal_code: "48390",
    full_city_name: "Walled Lake, MI",
    city_name: "Walled Lake",
  },
  {
    postal_code: "48328",
    full_city_name: "Waterford, MI",
    city_name: "Waterford",
  },
  {
    postal_code: "07751",
    full_city_name: "Morganville, NJ",
    city_name: "Morganville",
  },
  {
    postal_code: "66013",
    full_city_name: "Bucyrus, KS",
    city_name: "Bucyrus",
  },
  {
    postal_code: "78202",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "43812",
    full_city_name: "Coshocton, OH",
    city_name: "Coshocton",
  },
  {
    postal_code: "13501",
    full_city_name: "Utica, NY",
    city_name: "Utica",
  },
  {
    postal_code: "27886",
    full_city_name: "Tarboro, NC",
    city_name: "Tarboro",
  },
  {
    postal_code: "68522",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "23192",
    full_city_name: "Montpelier, VA",
    city_name: "Montpelier",
  },
  {
    postal_code: "95959",
    full_city_name: "Nevada City, CA",
    city_name: "Nevada City",
  },
  {
    postal_code: "07006",
    full_city_name: "Caldwell, NJ",
    city_name: "Caldwell",
  },
  {
    postal_code: "48503",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "67301",
    full_city_name: "Independence, KS",
    city_name: "Independence",
  },
  {
    postal_code: "20005",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "91601",
    full_city_name: "North Hollywood, CA",
    city_name: "North Hollywood",
  },
  {
    postal_code: "99218",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "21054",
    full_city_name: "Gambrills, MD",
    city_name: "Gambrills",
  },
  {
    postal_code: "31308",
    full_city_name: "Ellabell, GA",
    city_name: "Ellabell",
  },
  {
    postal_code: "37404",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "44614",
    full_city_name: "Canal Fulton, OH",
    city_name: "Canal Fulton",
  },
  {
    postal_code: "87015",
    full_city_name: "Edgewood, NM",
    city_name: "Edgewood",
  },
  {
    postal_code: "18444",
    full_city_name: "Moscow, PA",
    city_name: "Moscow",
  },
  {
    postal_code: "51301",
    full_city_name: "Spencer, IA",
    city_name: "Spencer",
  },
  {
    postal_code: "90265",
    full_city_name: "Malibu, CA",
    city_name: "Malibu",
  },
  {
    postal_code: "78801",
    full_city_name: "Uvalde, TX",
    city_name: "Uvalde",
  },
  {
    postal_code: "20175",
    full_city_name: "Leesburg, VA",
    city_name: "Leesburg",
  },
  {
    postal_code: "90247",
    full_city_name: "Gardena, CA",
    city_name: "Gardena",
  },
  {
    postal_code: "34235",
    full_city_name: "Sarasota, FL",
    city_name: "Sarasota",
  },
  {
    postal_code: "16117",
    full_city_name: "Ellwood City, PA",
    city_name: "Ellwood City",
  },
  {
    postal_code: "19036",
    full_city_name: "Glenolden, PA",
    city_name: "Glenolden",
  },
  {
    postal_code: "96768",
    full_city_name: "Makawao, HI",
    city_name: "Makawao",
  },
  {
    postal_code: "08876",
    full_city_name: "Somerville, NJ",
    city_name: "Somerville",
  },
  {
    postal_code: "07974",
    full_city_name: "New Providence, NJ",
    city_name: "New Providence",
  },
  {
    postal_code: "92111",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "21050",
    full_city_name: "Forest Hill, MD",
    city_name: "Forest Hill",
  },
  {
    postal_code: "12018",
    full_city_name: "Averill Park, NY",
    city_name: "Averill Park",
  },
  {
    postal_code: "06480",
    full_city_name: "Portland, CT",
    city_name: "Portland",
  },
  {
    postal_code: "49445",
    full_city_name: "Muskegon, MI",
    city_name: "Muskegon",
  },
  {
    postal_code: "88345",
    full_city_name: "Ruidoso, NM",
    city_name: "Ruidoso",
  },
  {
    postal_code: "34731",
    full_city_name: "Fruitland Park, FL",
    city_name: "Fruitland Park",
  },
  {
    postal_code: "94555",
    full_city_name: "Fremont, CA",
    city_name: "Fremont",
  },
  {
    postal_code: "94596",
    full_city_name: "Walnut Creek, CA",
    city_name: "Walnut Creek",
  },
  {
    postal_code: "37663",
    full_city_name: "Kingsport, TN",
    city_name: "Kingsport",
  },
  {
    postal_code: "45015",
    full_city_name: "Hamilton, OH",
    city_name: "Hamilton",
  },
  {
    postal_code: "35115",
    full_city_name: "Montevallo, AL",
    city_name: "Montevallo",
  },
  {
    postal_code: "98684",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "33565",
    full_city_name: "Plant City, FL",
    city_name: "Plant City",
  },
  {
    postal_code: "48915",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "31635",
    full_city_name: "Lakeland, GA",
    city_name: "Lakeland",
  },
  {
    postal_code: "92109",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "77979",
    full_city_name: "Port Lavaca, TX",
    city_name: "Port Lavaca",
  },
  {
    postal_code: "37212",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "48009",
    full_city_name: "Birmingham, MI",
    city_name: "Birmingham",
  },
  {
    postal_code: "19702",
    full_city_name: "Newark, DE",
    city_name: "Newark",
  },
  {
    postal_code: "72012",
    full_city_name: "Beebe, AR",
    city_name: "Beebe",
  },
  {
    postal_code: "11435",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "73179",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "02539",
    full_city_name: "Edgartown, MA",
    city_name: "Edgartown",
  },
  {
    postal_code: "83860",
    full_city_name: "Sagle, ID",
    city_name: "Sagle",
  },
  {
    postal_code: "07757",
    full_city_name: "Oceanport, NJ",
    city_name: "Oceanport",
  },
  {
    postal_code: "15012",
    full_city_name: "Belle Vernon, PA",
    city_name: "Belle Vernon",
  },
  {
    postal_code: "16504",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "15003",
    full_city_name: "Ambridge, PA",
    city_name: "Ambridge",
  },
  {
    postal_code: "57103",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "13420",
    full_city_name: "Old Forge, NY",
    city_name: "Old Forge",
  },
  {
    postal_code: "34476",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "72058",
    full_city_name: "Greenbrier, AR",
    city_name: "Greenbrier",
  },
  {
    postal_code: "20015",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "11792",
    full_city_name: "Wading River, NY",
    city_name: "Wading River",
  },
  {
    postal_code: "60446",
    full_city_name: "Romeoville, IL",
    city_name: "Romeoville",
  },
  {
    postal_code: "93905",
    full_city_name: "Salinas, CA",
    city_name: "Salinas",
  },
  {
    postal_code: "85282",
    full_city_name: "Tempe, AZ",
    city_name: "Tempe",
  },
  {
    postal_code: "14207",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "31093",
    full_city_name: "Warner Robins, GA",
    city_name: "Warner Robins",
  },
  {
    postal_code: "28097",
    full_city_name: "Locust, NC",
    city_name: "Locust",
  },
  {
    postal_code: "75495",
    full_city_name: "Van Alstyne, TX",
    city_name: "Van Alstyne",
  },
  {
    postal_code: "62223",
    full_city_name: "Belleville, IL",
    city_name: "Belleville",
  },
  {
    postal_code: "33850",
    full_city_name: "Lake Alfred, FL",
    city_name: "Lake Alfred",
  },
  {
    postal_code: "07079",
    full_city_name: "South Orange, NJ",
    city_name: "South Orange",
  },
  {
    postal_code: "37015",
    full_city_name: "Ashland City, TN",
    city_name: "Ashland City",
  },
  {
    postal_code: "28713",
    full_city_name: "Bryson City, NC",
    city_name: "Bryson City",
  },
  {
    postal_code: "21601",
    full_city_name: "Easton, MD",
    city_name: "Easton",
  },
  {
    postal_code: "48625",
    full_city_name: "Harrison, MI",
    city_name: "Harrison",
  },
  {
    postal_code: "62232",
    full_city_name: "Caseyville, IL",
    city_name: "Caseyville",
  },
  {
    postal_code: "91732",
    full_city_name: "El Monte, CA",
    city_name: "El Monte",
  },
  {
    postal_code: "22657",
    full_city_name: "Strasburg, VA",
    city_name: "Strasburg",
  },
  {
    postal_code: "72088",
    full_city_name: "Fairfield Bay, AR",
    city_name: "Fairfield Bay",
  },
  {
    postal_code: "97420",
    full_city_name: "Coos Bay, OR",
    city_name: "Coos Bay",
  },
  {
    postal_code: "92646",
    full_city_name: "Huntington Beach, CA",
    city_name: "Huntington Beach",
  },
  {
    postal_code: "48141",
    full_city_name: "Inkster, MI",
    city_name: "Inkster",
  },
  {
    postal_code: "37172",
    full_city_name: "Springfield, TN",
    city_name: "Springfield",
  },
  {
    postal_code: "22520",
    full_city_name: "Montross, VA",
    city_name: "Montross",
  },
  {
    postal_code: "66503",
    full_city_name: "Manhattan, KS",
    city_name: "Manhattan",
  },
  {
    postal_code: "33043",
    full_city_name: "Big Pine Key, FL",
    city_name: "Big Pine Key",
  },
  {
    postal_code: "48739",
    full_city_name: "Hale, MI",
    city_name: "Hale",
  },
  {
    postal_code: "22840",
    full_city_name: "Mc Gaheysville, VA",
    city_name: "Mc Gaheysville",
  },
  {
    postal_code: "90604",
    full_city_name: "Whittier, CA",
    city_name: "Whittier",
  },
  {
    postal_code: "29204",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "77098",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "82718",
    full_city_name: "Gillette, WY",
    city_name: "Gillette",
  },
  {
    postal_code: "49428",
    full_city_name: "Jenison, MI",
    city_name: "Jenison",
  },
  {
    postal_code: "49783",
    full_city_name: "Sault Sainte Marie, MI",
    city_name: "Sault Sainte Marie",
  },
  {
    postal_code: "24501",
    full_city_name: "Lynchburg, VA",
    city_name: "Lynchburg",
  },
  {
    postal_code: "32204",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "32607",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "15213",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "98371",
    full_city_name: "Puyallup, WA",
    city_name: "Puyallup",
  },
  {
    postal_code: "64735",
    full_city_name: "Clinton, MO",
    city_name: "Clinton",
  },
  {
    postal_code: "24701",
    full_city_name: "Bluefield, WV",
    city_name: "Bluefield",
  },
  {
    postal_code: "94618",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "80234",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "14085",
    full_city_name: "Lake View, NY",
    city_name: "Lake View",
  },
  {
    postal_code: "55404",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "95620",
    full_city_name: "Dixon, CA",
    city_name: "Dixon",
  },
  {
    postal_code: "48386",
    full_city_name: "White Lake, MI",
    city_name: "White Lake",
  },
  {
    postal_code: "97056",
    full_city_name: "Scappoose, OR",
    city_name: "Scappoose",
  },
  {
    postal_code: "54942",
    full_city_name: "Greenville, WI",
    city_name: "Greenville",
  },
  {
    postal_code: "94949",
    full_city_name: "Novato, CA",
    city_name: "Novato",
  },
  {
    postal_code: "71270",
    full_city_name: "Ruston, LA",
    city_name: "Ruston",
  },
  {
    postal_code: "97141",
    full_city_name: "Tillamook, OR",
    city_name: "Tillamook",
  },
  {
    postal_code: "22041",
    full_city_name: "Falls Church, VA",
    city_name: "Falls Church",
  },
  {
    postal_code: "80482",
    full_city_name: "Winter Park, CO",
    city_name: "Winter Park",
  },
  {
    postal_code: "21210",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "49202",
    full_city_name: "Jackson, MI",
    city_name: "Jackson",
  },
  {
    postal_code: "38637",
    full_city_name: "Horn Lake, MS",
    city_name: "Horn Lake",
  },
  {
    postal_code: "40160",
    full_city_name: "Radcliff, KY",
    city_name: "Radcliff",
  },
  {
    postal_code: "42240",
    full_city_name: "Hopkinsville, KY",
    city_name: "Hopkinsville",
  },
  {
    postal_code: "97008",
    full_city_name: "Beaverton, OR",
    city_name: "Beaverton",
  },
  {
    postal_code: "90035",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "06084",
    full_city_name: "Tolland, CT",
    city_name: "Tolland",
  },
  {
    postal_code: "95133",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "32459",
    full_city_name: "Santa Rosa Beach, FL",
    city_name: "Santa Rosa Beach",
  },
  {
    postal_code: "07042",
    full_city_name: "Montclair, NJ",
    city_name: "Montclair",
  },
  {
    postal_code: "60143",
    full_city_name: "Itasca, IL",
    city_name: "Itasca",
  },
  {
    postal_code: "19930",
    full_city_name: "Bethany Beach, DE",
    city_name: "Bethany Beach",
  },
  {
    postal_code: "91030",
    full_city_name: "South Pasadena, CA",
    city_name: "South Pasadena",
  },
  {
    postal_code: "31721",
    full_city_name: "Albany, GA",
    city_name: "Albany",
  },
  {
    postal_code: "67213",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "62294",
    full_city_name: "Troy, IL",
    city_name: "Troy",
  },
  {
    postal_code: "77061",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "94117",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "34951",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "06880",
    full_city_name: "Westport, CT",
    city_name: "Westport",
  },
  {
    postal_code: "14580",
    full_city_name: "Webster, NY",
    city_name: "Webster",
  },
  {
    postal_code: "31808",
    full_city_name: "Fortson, GA",
    city_name: "Fortson",
  },
  {
    postal_code: "48638",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "98104",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "96720",
    full_city_name: "Hilo, HI",
    city_name: "Hilo",
  },
  {
    postal_code: "93610",
    full_city_name: "Chowchilla, CA",
    city_name: "Chowchilla",
  },
  {
    postal_code: "35023",
    full_city_name: "Bessemer, AL",
    city_name: "Bessemer",
  },
  {
    postal_code: "97225",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "22066",
    full_city_name: "Great Falls, VA",
    city_name: "Great Falls",
  },
  {
    postal_code: "85263",
    full_city_name: "Rio Verde, AZ",
    city_name: "Rio Verde",
  },
  {
    postal_code: "98528",
    full_city_name: "Belfair, WA",
    city_name: "Belfair",
  },
  {
    postal_code: "32536",
    full_city_name: "Crestview, FL",
    city_name: "Crestview",
  },
  {
    postal_code: "73538",
    full_city_name: "Elgin, OK",
    city_name: "Elgin",
  },
  {
    postal_code: "06042",
    full_city_name: "Manchester, CT",
    city_name: "Manchester",
  },
  {
    postal_code: "99515",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "10952",
    full_city_name: "Monsey, NY",
    city_name: "Monsey",
  },
  {
    postal_code: "63044",
    full_city_name: "Bridgeton, MO",
    city_name: "Bridgeton",
  },
  {
    postal_code: "90720",
    full_city_name: "Los Alamitos, CA",
    city_name: "Los Alamitos",
  },
  {
    postal_code: "92058",
    full_city_name: "Oceanside, CA",
    city_name: "Oceanside",
  },
  {
    postal_code: "92010",
    full_city_name: "Carlsbad, CA",
    city_name: "Carlsbad",
  },
  {
    postal_code: "22181",
    full_city_name: "Vienna, VA",
    city_name: "Vienna",
  },
  {
    postal_code: "65109",
    full_city_name: "Jefferson City, MO",
    city_name: "Jefferson City",
  },
  {
    postal_code: "89074",
    full_city_name: "Henderson, NV",
    city_name: "Henderson",
  },
  {
    postal_code: "37073",
    full_city_name: "Greenbrier, TN",
    city_name: "Greenbrier",
  },
  {
    postal_code: "66012",
    full_city_name: "Bonner Springs, KS",
    city_name: "Bonner Springs",
  },
  {
    postal_code: "43623",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "59840",
    full_city_name: "Hamilton, MT",
    city_name: "Hamilton",
  },
  {
    postal_code: "94901",
    full_city_name: "San Rafael, CA",
    city_name: "San Rafael",
  },
  {
    postal_code: "40508",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "78015",
    full_city_name: "Boerne, TX",
    city_name: "Boerne",
  },
  {
    postal_code: "32615",
    full_city_name: "Alachua, FL",
    city_name: "Alachua",
  },
  {
    postal_code: "21040",
    full_city_name: "Edgewood, MD",
    city_name: "Edgewood",
  },
  {
    postal_code: "14519",
    full_city_name: "Ontario, NY",
    city_name: "Ontario",
  },
  {
    postal_code: "75235",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "47401",
    full_city_name: "Bloomington, IN",
    city_name: "Bloomington",
  },
  {
    postal_code: "92107",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "58104",
    full_city_name: "Fargo, ND",
    city_name: "Fargo",
  },
  {
    postal_code: "70560",
    full_city_name: "New Iberia, LA",
    city_name: "New Iberia",
  },
  {
    postal_code: "78237",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "93308",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "22815",
    full_city_name: "Broadway, VA",
    city_name: "Broadway",
  },
  {
    postal_code: "39452",
    full_city_name: "Lucedale, MS",
    city_name: "Lucedale",
  },
  {
    postal_code: "32163",
    full_city_name: "The Villages, FL",
    city_name: "The Villages",
  },
  {
    postal_code: "21074",
    full_city_name: "Hampstead, MD",
    city_name: "Hampstead",
  },
  {
    postal_code: "33182",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "49047",
    full_city_name: "Dowagiac, MI",
    city_name: "Dowagiac",
  },
  {
    postal_code: "92706",
    full_city_name: "Santa Ana, CA",
    city_name: "Santa Ana",
  },
  {
    postal_code: "92835",
    full_city_name: "Fullerton, CA",
    city_name: "Fullerton",
  },
  {
    postal_code: "53508",
    full_city_name: "Belleville, WI",
    city_name: "Belleville",
  },
  {
    postal_code: "21502",
    full_city_name: "Cumberland, MD",
    city_name: "Cumberland",
  },
  {
    postal_code: "92108",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "77074",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "06518",
    full_city_name: "Hamden, CT",
    city_name: "Hamden",
  },
  {
    postal_code: "59725",
    full_city_name: "Dillon, MT",
    city_name: "Dillon",
  },
  {
    postal_code: "93901",
    full_city_name: "Salinas, CA",
    city_name: "Salinas",
  },
  {
    postal_code: "08816",
    full_city_name: "East Brunswick, NJ",
    city_name: "East Brunswick",
  },
  {
    postal_code: "93405",
    full_city_name: "San Luis Obispo, CA",
    city_name: "San Luis Obispo",
  },
  {
    postal_code: "27235",
    full_city_name: "Colfax, NC",
    city_name: "Colfax",
  },
  {
    postal_code: "28470",
    full_city_name: "Shallotte, NC",
    city_name: "Shallotte",
  },
  {
    postal_code: "45320",
    full_city_name: "Eaton, OH",
    city_name: "Eaton",
  },
  {
    postal_code: "92833",
    full_city_name: "Fullerton, CA",
    city_name: "Fullerton",
  },
  {
    postal_code: "35645",
    full_city_name: "Killen, AL",
    city_name: "Killen",
  },
  {
    postal_code: "66614",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "89102",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "14214",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "06896",
    full_city_name: "Redding, CT",
    city_name: "Redding",
  },
  {
    postal_code: "30055",
    full_city_name: "Mansfield, GA",
    city_name: "Mansfield",
  },
  {
    postal_code: "90605",
    full_city_name: "Whittier, CA",
    city_name: "Whittier",
  },
  {
    postal_code: "68008",
    full_city_name: "Blair, NE",
    city_name: "Blair",
  },
  {
    postal_code: "24740",
    full_city_name: "Princeton, WV",
    city_name: "Princeton",
  },
  {
    postal_code: "85019",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "29205",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "14621",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "55077",
    full_city_name: "Inver Grove Heights, MN",
    city_name: "Inver Grove Heights",
  },
  {
    postal_code: "33308",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "32046",
    full_city_name: "Hilliard, FL",
    city_name: "Hilliard",
  },
  {
    postal_code: "55418",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "14215",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "60643",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "90016",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "07834",
    full_city_name: "Denville, NJ",
    city_name: "Denville",
  },
  {
    postal_code: "46947",
    full_city_name: "Logansport, IN",
    city_name: "Logansport",
  },
  {
    postal_code: "22301",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "33823",
    full_city_name: "Auburndale, FL",
    city_name: "Auburndale",
  },
  {
    postal_code: "07726",
    full_city_name: "Englishtown, NJ",
    city_name: "Englishtown",
  },
  {
    postal_code: "76707",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "15668",
    full_city_name: "Murrysville, PA",
    city_name: "Murrysville",
  },
  {
    postal_code: "12496",
    full_city_name: "Windham, NY",
    city_name: "Windham",
  },
  {
    postal_code: "32539",
    full_city_name: "Crestview, FL",
    city_name: "Crestview",
  },
  {
    postal_code: "44089",
    full_city_name: "Vermilion, OH",
    city_name: "Vermilion",
  },
  {
    postal_code: "92612",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "52001",
    full_city_name: "Dubuque, IA",
    city_name: "Dubuque",
  },
  {
    postal_code: "11590",
    full_city_name: "Westbury, NY",
    city_name: "Westbury",
  },
  {
    postal_code: "27028",
    full_city_name: "Mocksville, NC",
    city_name: "Mocksville",
  },
  {
    postal_code: "14212",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "14032",
    full_city_name: "Clarence Center, NY",
    city_name: "Clarence Center",
  },
  {
    postal_code: "30145",
    full_city_name: "Kingston, GA",
    city_name: "Kingston",
  },
  {
    postal_code: "73135",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "35211",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "11530",
    full_city_name: "Garden City, NY",
    city_name: "Garden City",
  },
  {
    postal_code: "70820",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "99623",
    full_city_name: "Wasilla, AK",
    city_name: "Wasilla",
  },
  {
    postal_code: "36106",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "96704",
    full_city_name: "Captain Cook, HI",
    city_name: "Captain Cook",
  },
  {
    postal_code: "73099",
    full_city_name: "Yukon, OK",
    city_name: "Yukon",
  },
  {
    postal_code: "47714",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "70128",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "20187",
    full_city_name: "Warrenton, VA",
    city_name: "Warrenton",
  },
  {
    postal_code: "47568",
    full_city_name: "Plainville, IN",
    city_name: "Plainville",
  },
  {
    postal_code: "23303",
    full_city_name: "Atlantic, VA",
    city_name: "Atlantic",
  },
  {
    postal_code: "15847",
    full_city_name: "Knox Dale, PA",
    city_name: "Knox Dale",
  },
  {
    postal_code: "47949",
    full_city_name: "Hillsboro, IN",
    city_name: "Hillsboro",
  },
  {
    postal_code: "66418",
    full_city_name: "Delia, KS",
    city_name: "Delia",
  },
  {
    postal_code: "17769",
    full_city_name: "Slate Run, PA",
    city_name: "Slate Run",
  },
  {
    postal_code: "72523",
    full_city_name: "Concord, AR",
    city_name: "Concord",
  },
  {
    postal_code: "41240",
    full_city_name: "Paintsville, KY",
    city_name: "Paintsville",
  },
  {
    postal_code: "15315",
    full_city_name: "Bobtown, PA",
    city_name: "Bobtown",
  },
  {
    postal_code: "24093",
    full_city_name: "Glen Lyn, VA",
    city_name: "Glen Lyn",
  },
  {
    postal_code: "95565",
    full_city_name: "Scotia, CA",
    city_name: "Scotia",
  },
  {
    postal_code: "95937",
    full_city_name: "Dunnigan, CA",
    city_name: "Dunnigan",
  },
  {
    postal_code: "41777",
    full_city_name: "Yeaddiss, KY",
    city_name: "Yeaddiss",
  },
  {
    postal_code: "97856",
    full_city_name: "Long Creek, OR",
    city_name: "Long Creek",
  },
  {
    postal_code: "66072",
    full_city_name: "Parker, KS",
    city_name: "Parker",
  },
  {
    postal_code: "54443",
    full_city_name: "Junction City, WI",
    city_name: "Junction City",
  },
  {
    postal_code: "59728",
    full_city_name: "Elliston, MT",
    city_name: "Elliston",
  },
  {
    postal_code: "49657",
    full_city_name: "Mc Bain, MI",
    city_name: "Mc Bain",
  },
  {
    postal_code: "71676",
    full_city_name: "Wilmot, AR",
    city_name: "Wilmot",
  },
  {
    postal_code: "57314",
    full_city_name: "Artesian, SD",
    city_name: "Artesian",
  },
  {
    postal_code: "70531",
    full_city_name: "Egan, LA",
    city_name: "Egan",
  },
  {
    postal_code: "13355",
    full_city_name: "Hubbardsville, NY",
    city_name: "Hubbardsville",
  },
  {
    postal_code: "40855",
    full_city_name: "Lynch, KY",
    city_name: "Lynch",
  },
  {
    postal_code: "96773",
    full_city_name: "Ninole, HI",
    city_name: "Ninole",
  },
  {
    postal_code: "57376",
    full_city_name: "Tripp, SD",
    city_name: "Tripp",
  },
  {
    postal_code: "36052",
    full_city_name: "Mathews, AL",
    city_name: "Mathews",
  },
  {
    postal_code: "59929",
    full_city_name: "Proctor, MT",
    city_name: "Proctor",
  },
  {
    postal_code: "58049",
    full_city_name: "Kathryn, ND",
    city_name: "Kathryn",
  },
  {
    postal_code: "49871",
    full_city_name: "Palmer, MI",
    city_name: "Palmer",
  },
  {
    postal_code: "67568",
    full_city_name: "Plevna, KS",
    city_name: "Plevna",
  },
  {
    postal_code: "70761",
    full_city_name: "Norwood, LA",
    city_name: "Norwood",
  },
  {
    postal_code: "85940",
    full_city_name: "Vernon, AZ",
    city_name: "Vernon",
  },
  {
    postal_code: "59344",
    full_city_name: "Plevna, MT",
    city_name: "Plevna",
  },
  {
    postal_code: "32501",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "22535",
    full_city_name: "Port Royal, VA",
    city_name: "Port Royal",
  },
  {
    postal_code: "25555",
    full_city_name: "Prichard, WV",
    city_name: "Prichard",
  },
  {
    postal_code: "23829",
    full_city_name: "Capron, VA",
    city_name: "Capron",
  },
  {
    postal_code: "26660",
    full_city_name: "Calvin, WV",
    city_name: "Calvin",
  },
  {
    postal_code: "26280",
    full_city_name: "Mill Creek, WV",
    city_name: "Mill Creek",
  },
  {
    postal_code: "88024",
    full_city_name: "Berino, NM",
    city_name: "Berino",
  },
  {
    postal_code: "25676",
    full_city_name: "Lenore, WV",
    city_name: "Lenore",
  },
  {
    postal_code: "88118",
    full_city_name: "Floyd, NM",
    city_name: "Floyd",
  },
  {
    postal_code: "28619",
    full_city_name: "Drexel, NC",
    city_name: "Drexel",
  },
  {
    postal_code: "07838",
    full_city_name: "Great Meadows, NJ",
    city_name: "Great Meadows",
  },
  {
    postal_code: "55780",
    full_city_name: "Sawyer, MN",
    city_name: "Sawyer",
  },
  {
    postal_code: "35460",
    full_city_name: "Epes, AL",
    city_name: "Epes",
  },
  {
    postal_code: "56293",
    full_city_name: "Wabasso, MN",
    city_name: "Wabasso",
  },
  {
    postal_code: "35904",
    full_city_name: "Gadsden, AL",
    city_name: "Gadsden",
  },
  {
    postal_code: "14009",
    full_city_name: "Arcade, NY",
    city_name: "Arcade",
  },
  {
    postal_code: "53947",
    full_city_name: "Marquette, WI",
    city_name: "Marquette",
  },
  {
    postal_code: "39743",
    full_city_name: "Crawford, MS",
    city_name: "Crawford",
  },
  {
    postal_code: "58317",
    full_city_name: "Bisbee, ND",
    city_name: "Bisbee",
  },
  {
    postal_code: "95956",
    full_city_name: "Meadow Valley, CA",
    city_name: "Meadow Valley",
  },
  {
    postal_code: "59450",
    full_city_name: "Highwood, MT",
    city_name: "Highwood",
  },
  {
    postal_code: "68303",
    full_city_name: "Alexandria, NE",
    city_name: "Alexandria",
  },
  {
    postal_code: "26337",
    full_city_name: "Cairo, WV",
    city_name: "Cairo",
  },
  {
    postal_code: "23038",
    full_city_name: "Columbia, VA",
    city_name: "Columbia",
  },
  {
    postal_code: "74840",
    full_city_name: "Earlsboro, OK",
    city_name: "Earlsboro",
  },
  {
    postal_code: "83539",
    full_city_name: "Kooskia, ID",
    city_name: "Kooskia",
  },
  {
    postal_code: "79834",
    full_city_name: "Big Bend National Park, TX",
    city_name: "Big Bend National Park",
  },
  {
    postal_code: "23696",
    full_city_name: "Seaford, VA",
    city_name: "Seaford",
  },
  {
    postal_code: "72036",
    full_city_name: "Cotton Plant, AR",
    city_name: "Cotton Plant",
  },
  {
    postal_code: "79851",
    full_city_name: "Sierra Blanca, TX",
    city_name: "Sierra Blanca",
  },
  {
    postal_code: "29835",
    full_city_name: "Mc Cormick, SC",
    city_name: "Mc Cormick",
  },
  {
    postal_code: "63061",
    full_city_name: "Luebbering, MO",
    city_name: "Luebbering",
  },
  {
    postal_code: "62618",
    full_city_name: "Beardstown, IL",
    city_name: "Beardstown",
  },
  {
    postal_code: "24635",
    full_city_name: "Pocahontas, VA",
    city_name: "Pocahontas",
  },
  {
    postal_code: "58771",
    full_city_name: "Plaza, ND",
    city_name: "Plaza",
  },
  {
    postal_code: "79743",
    full_city_name: "Imperial, TX",
    city_name: "Imperial",
  },
  {
    postal_code: "74959",
    full_city_name: "Spiro, OK",
    city_name: "Spiro",
  },
  {
    postal_code: "17255",
    full_city_name: "Shade Gap, PA",
    city_name: "Shade Gap",
  },
  {
    postal_code: "16821",
    full_city_name: "Allport, PA",
    city_name: "Allport",
  },
  {
    postal_code: "65727",
    full_city_name: "Polk, MO",
    city_name: "Polk",
  },
  {
    postal_code: "57434",
    full_city_name: "Conde, SD",
    city_name: "Conde",
  },
  {
    postal_code: "73054",
    full_city_name: "Luther, OK",
    city_name: "Luther",
  },
  {
    postal_code: "73858",
    full_city_name: "Shattuck, OK",
    city_name: "Shattuck",
  },
  {
    postal_code: "73838",
    full_city_name: "Chester, OK",
    city_name: "Chester",
  },
  {
    postal_code: "50645",
    full_city_name: "Ionia, IA",
    city_name: "Ionia",
  },
  {
    postal_code: "78943",
    full_city_name: "Glidden, TX",
    city_name: "Glidden",
  },
  {
    postal_code: "62439",
    full_city_name: "Lawrenceville, IL",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "23518",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "10980",
    full_city_name: "Stony Point, NY",
    city_name: "Stony Point",
  },
  {
    postal_code: "53218",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "24280",
    full_city_name: "Rosedale, VA",
    city_name: "Rosedale",
  },
  {
    postal_code: "31553",
    full_city_name: "Nahunta, GA",
    city_name: "Nahunta",
  },
  {
    postal_code: "66966",
    full_city_name: "Scandia, KS",
    city_name: "Scandia",
  },
  {
    postal_code: "66415",
    full_city_name: "Centralia, KS",
    city_name: "Centralia",
  },
  {
    postal_code: "38240",
    full_city_name: "Obion, TN",
    city_name: "Obion",
  },
  {
    postal_code: "23119",
    full_city_name: "Moon, VA",
    city_name: "Moon",
  },
  {
    postal_code: "98281",
    full_city_name: "Point Roberts, WA",
    city_name: "Point Roberts",
  },
  {
    postal_code: "56723",
    full_city_name: "Fisher, MN",
    city_name: "Fisher",
  },
  {
    postal_code: "70767",
    full_city_name: "Port Allen, LA",
    city_name: "Port Allen",
  },
  {
    postal_code: "36267",
    full_city_name: "Millerville, AL",
    city_name: "Millerville",
  },
  {
    postal_code: "24945",
    full_city_name: "Greenville, WV",
    city_name: "Greenville",
  },
  {
    postal_code: "48630",
    full_city_name: "Houghton Lake Heights, MI",
    city_name: "Houghton Lake Heights",
  },
  {
    postal_code: "07075",
    full_city_name: "Wood Ridge, NJ",
    city_name: "Wood Ridge",
  },
  {
    postal_code: "82212",
    full_city_name: "Fort Laramie, WY",
    city_name: "Fort Laramie",
  },
  {
    postal_code: "54963",
    full_city_name: "Omro, WI",
    city_name: "Omro",
  },
  {
    postal_code: "43961",
    full_city_name: "Stratton, OH",
    city_name: "Stratton",
  },
  {
    postal_code: "04442",
    full_city_name: "Greenville Junction, ME",
    city_name: "Greenville Junction",
  },
  {
    postal_code: "17876",
    full_city_name: "Shamokin Dam, PA",
    city_name: "Shamokin Dam",
  },
  {
    postal_code: "72571",
    full_city_name: "Rosie, AR",
    city_name: "Rosie",
  },
  {
    postal_code: "15736",
    full_city_name: "Elderton, PA",
    city_name: "Elderton",
  },
  {
    postal_code: "68072",
    full_city_name: "Winslow, NE",
    city_name: "Winslow",
  },
  {
    postal_code: "76621",
    full_city_name: "Abbott, TX",
    city_name: "Abbott",
  },
  {
    postal_code: "72542",
    full_city_name: "Hardy, AR",
    city_name: "Hardy",
  },
  {
    postal_code: "80425",
    full_city_name: "Buffalo Creek, CO",
    city_name: "Buffalo Creek",
  },
  {
    postal_code: "58068",
    full_city_name: "Sheldon, ND",
    city_name: "Sheldon",
  },
  {
    postal_code: "16501",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "52072",
    full_city_name: "Saint Olaf, IA",
    city_name: "Saint Olaf",
  },
  {
    postal_code: "61325",
    full_city_name: "Grand Ridge, IL",
    city_name: "Grand Ridge",
  },
  {
    postal_code: "26208",
    full_city_name: "Camden On Gauley, WV",
    city_name: "Camden On Gauley",
  },
  {
    postal_code: "95547",
    full_city_name: "Hydesville, CA",
    city_name: "Hydesville",
  },
  {
    postal_code: "74726",
    full_city_name: "Bokchito, OK",
    city_name: "Bokchito",
  },
  {
    postal_code: "07717",
    full_city_name: "Avon By The Sea, NJ",
    city_name: "Avon By The Sea",
  },
  {
    postal_code: "55961",
    full_city_name: "Ostrander, MN",
    city_name: "Ostrander",
  },
  {
    postal_code: "98467",
    full_city_name: "University Place, WA",
    city_name: "University Place",
  },
  {
    postal_code: "59201",
    full_city_name: "Wolf Point, MT",
    city_name: "Wolf Point",
  },
  {
    postal_code: "07105",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "62358",
    full_city_name: "Niota, IL",
    city_name: "Niota",
  },
  {
    postal_code: "52647",
    full_city_name: "Olds, IA",
    city_name: "Olds",
  },
  {
    postal_code: "24435",
    full_city_name: "Fairfield, VA",
    city_name: "Fairfield",
  },
  {
    postal_code: "78159",
    full_city_name: "Smiley, TX",
    city_name: "Smiley",
  },
  {
    postal_code: "37350",
    full_city_name: "Lookout Mountain, TN",
    city_name: "Lookout Mountain",
  },
  {
    postal_code: "43547",
    full_city_name: "Neapolis, OH",
    city_name: "Neapolis",
  },
  {
    postal_code: "25245",
    full_city_name: "Given, WV",
    city_name: "Given",
  },
  {
    postal_code: "76435",
    full_city_name: "Carbon, TX",
    city_name: "Carbon",
  },
  {
    postal_code: "98579",
    full_city_name: "Rochester, WA",
    city_name: "Rochester",
  },
  {
    postal_code: "64021",
    full_city_name: "Corder, MO",
    city_name: "Corder",
  },
  {
    postal_code: "99587",
    full_city_name: "Girdwood, AK",
    city_name: "Girdwood",
  },
  {
    postal_code: "27885",
    full_city_name: "Swanquarter, NC",
    city_name: "Swanquarter",
  },
  {
    postal_code: "50581",
    full_city_name: "Rolfe, IA",
    city_name: "Rolfe",
  },
  {
    postal_code: "99330",
    full_city_name: "Eltopia, WA",
    city_name: "Eltopia",
  },
  {
    postal_code: "50435",
    full_city_name: "Floyd, IA",
    city_name: "Floyd",
  },
  {
    postal_code: "66734",
    full_city_name: "Farlington, KS",
    city_name: "Farlington",
  },
  {
    postal_code: "79749",
    full_city_name: "Lenorah, TX",
    city_name: "Lenorah",
  },
  {
    postal_code: "71001",
    full_city_name: "Arcadia, LA",
    city_name: "Arcadia",
  },
  {
    postal_code: "32332",
    full_city_name: "Gretna, FL",
    city_name: "Gretna",
  },
  {
    postal_code: "64483",
    full_city_name: "Rosendale, MO",
    city_name: "Rosendale",
  },
  {
    postal_code: "72583",
    full_city_name: "Viola, AR",
    city_name: "Viola",
  },
  {
    postal_code: "58757",
    full_city_name: "Mandaree, ND",
    city_name: "Mandaree",
  },
  {
    postal_code: "16402",
    full_city_name: "Bear Lake, PA",
    city_name: "Bear Lake",
  },
  {
    postal_code: "38347",
    full_city_name: "Jacks Creek, TN",
    city_name: "Jacks Creek",
  },
  {
    postal_code: "05748",
    full_city_name: "Hancock, VT",
    city_name: "Hancock",
  },
  {
    postal_code: "67853",
    full_city_name: "Ingalls, KS",
    city_name: "Ingalls",
  },
  {
    postal_code: "67004",
    full_city_name: "Argonia, KS",
    city_name: "Argonia",
  },
  {
    postal_code: "38645",
    full_city_name: "Lyon, MS",
    city_name: "Lyon",
  },
  {
    postal_code: "39747",
    full_city_name: "Kilmichael, MS",
    city_name: "Kilmichael",
  },
  {
    postal_code: "15444",
    full_city_name: "Hiller, PA",
    city_name: "Hiller",
  },
  {
    postal_code: "47443",
    full_city_name: "Lyons, IN",
    city_name: "Lyons",
  },
  {
    postal_code: "82836",
    full_city_name: "Dayton, WY",
    city_name: "Dayton",
  },
  {
    postal_code: "67364",
    full_city_name: "Tyro, KS",
    city_name: "Tyro",
  },
  {
    postal_code: "26160",
    full_city_name: "Palestine, WV",
    city_name: "Palestine",
  },
  {
    postal_code: "74343",
    full_city_name: "Fairland, OK",
    city_name: "Fairland",
  },
  {
    postal_code: "24066",
    full_city_name: "Buchanan, VA",
    city_name: "Buchanan",
  },
  {
    postal_code: "66776",
    full_city_name: "Thayer, KS",
    city_name: "Thayer",
  },
  {
    postal_code: "99111",
    full_city_name: "Colfax, WA",
    city_name: "Colfax",
  },
  {
    postal_code: "13486",
    full_city_name: "Westernville, NY",
    city_name: "Westernville",
  },
  {
    postal_code: "75476",
    full_city_name: "Ravenna, TX",
    city_name: "Ravenna",
  },
  {
    postal_code: "97039",
    full_city_name: "Moro, OR",
    city_name: "Moro",
  },
  {
    postal_code: "62533",
    full_city_name: "Farmersville, IL",
    city_name: "Farmersville",
  },
  {
    postal_code: "16912",
    full_city_name: "Blossburg, PA",
    city_name: "Blossburg",
  },
  {
    postal_code: "49408",
    full_city_name: "Fennville, MI",
    city_name: "Fennville",
  },
  {
    postal_code: "63452",
    full_city_name: "Lewistown, MO",
    city_name: "Lewistown",
  },
  {
    postal_code: "16849",
    full_city_name: "Lanse, PA",
    city_name: "Lanse",
  },
  {
    postal_code: "57048",
    full_city_name: "Montrose, SD",
    city_name: "Montrose",
  },
  {
    postal_code: "65059",
    full_city_name: "Mokane, MO",
    city_name: "Mokane",
  },
  {
    postal_code: "18933",
    full_city_name: "Lumberville, PA",
    city_name: "Lumberville",
  },
  {
    postal_code: "72824",
    full_city_name: "Belleville, AR",
    city_name: "Belleville",
  },
  {
    postal_code: "50055",
    full_city_name: "Collins, IA",
    city_name: "Collins",
  },
  {
    postal_code: "72432",
    full_city_name: "Harrisburg, AR",
    city_name: "Harrisburg",
  },
  {
    postal_code: "83126",
    full_city_name: "Smoot, WY",
    city_name: "Smoot",
  },
  {
    postal_code: "47596",
    full_city_name: "Westphalia, IN",
    city_name: "Westphalia",
  },
  {
    postal_code: "72130",
    full_city_name: "Prim, AR",
    city_name: "Prim",
  },
  {
    postal_code: "70355",
    full_city_name: "Gheens, LA",
    city_name: "Gheens",
  },
  {
    postal_code: "36854",
    full_city_name: "Valley, AL",
    city_name: "Valley",
  },
  {
    postal_code: "26325",
    full_city_name: "Auburn, WV",
    city_name: "Auburn",
  },
  {
    postal_code: "47345",
    full_city_name: "Greens Fork, IN",
    city_name: "Greens Fork",
  },
  {
    postal_code: "25920",
    full_city_name: "Slab Fork, WV",
    city_name: "Slab Fork",
  },
  {
    postal_code: "78948",
    full_city_name: "Lincoln, TX",
    city_name: "Lincoln",
  },
  {
    postal_code: "68976",
    full_city_name: "Smithfield, NE",
    city_name: "Smithfield",
  },
  {
    postal_code: "62431",
    full_city_name: "Herrick, IL",
    city_name: "Herrick",
  },
  {
    postal_code: "04912",
    full_city_name: "Athens, ME",
    city_name: "Athens",
  },
  {
    postal_code: "10517",
    full_city_name: "Crompond, NY",
    city_name: "Crompond",
  },
  {
    postal_code: "45776",
    full_city_name: "Shade, OH",
    city_name: "Shade",
  },
  {
    postal_code: "71416",
    full_city_name: "Cloutierville, LA",
    city_name: "Cloutierville",
  },
  {
    postal_code: "47804",
    full_city_name: "Terre Haute, IN",
    city_name: "Terre Haute",
  },
  {
    postal_code: "39662",
    full_city_name: "Ruth, MS",
    city_name: "Ruth",
  },
  {
    postal_code: "98148",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "80455",
    full_city_name: "Jamestown, CO",
    city_name: "Jamestown",
  },
  {
    postal_code: "65588",
    full_city_name: "Winona, MO",
    city_name: "Winona",
  },
  {
    postal_code: "14836",
    full_city_name: "Dalton, NY",
    city_name: "Dalton",
  },
  {
    postal_code: "35575",
    full_city_name: "Lynn, AL",
    city_name: "Lynn",
  },
  {
    postal_code: "24256",
    full_city_name: "Haysi, VA",
    city_name: "Haysi",
  },
  {
    postal_code: "58845",
    full_city_name: "Grenora, ND",
    city_name: "Grenora",
  },
  {
    postal_code: "62544",
    full_city_name: "Macon, IL",
    city_name: "Macon",
  },
  {
    postal_code: "36871",
    full_city_name: "Pittsview, AL",
    city_name: "Pittsview",
  },
  {
    postal_code: "51027",
    full_city_name: "Ireton, IA",
    city_name: "Ireton",
  },
  {
    postal_code: "99743",
    full_city_name: "Healy, AK",
    city_name: "Healy",
  },
  {
    postal_code: "56646",
    full_city_name: "Gully, MN",
    city_name: "Gully",
  },
  {
    postal_code: "79351",
    full_city_name: "Odonnell, TX",
    city_name: "Odonnell",
  },
  {
    postal_code: "62885",
    full_city_name: "Shobonier, IL",
    city_name: "Shobonier",
  },
  {
    postal_code: "65023",
    full_city_name: "Centertown, MO",
    city_name: "Centertown",
  },
  {
    postal_code: "68816",
    full_city_name: "Archer, NE",
    city_name: "Archer",
  },
  {
    postal_code: "57068",
    full_city_name: "Valley Springs, SD",
    city_name: "Valley Springs",
  },
  {
    postal_code: "35773",
    full_city_name: "Toney, AL",
    city_name: "Toney",
  },
  {
    postal_code: "04413",
    full_city_name: "Brookton, ME",
    city_name: "Brookton",
  },
  {
    postal_code: "73432",
    full_city_name: "Coleman, OK",
    city_name: "Coleman",
  },
  {
    postal_code: "29452",
    full_city_name: "Jacksonboro, SC",
    city_name: "Jacksonboro",
  },
  {
    postal_code: "36832",
    full_city_name: "Auburn, AL",
    city_name: "Auburn",
  },
  {
    postal_code: "68368",
    full_city_name: "Hallam, NE",
    city_name: "Hallam",
  },
  {
    postal_code: "25161",
    full_city_name: "Powellton, WV",
    city_name: "Powellton",
  },
  {
    postal_code: "31625",
    full_city_name: "Barney, GA",
    city_name: "Barney",
  },
  {
    postal_code: "25625",
    full_city_name: "Holden, WV",
    city_name: "Holden",
  },
  {
    postal_code: "72928",
    full_city_name: "Branch, AR",
    city_name: "Branch",
  },
  {
    postal_code: "71266",
    full_city_name: "Pioneer, LA",
    city_name: "Pioneer",
  },
  {
    postal_code: "59522",
    full_city_name: "Chester, MT",
    city_name: "Chester",
  },
  {
    postal_code: "72087",
    full_city_name: "Lonsdale, AR",
    city_name: "Lonsdale",
  },
  {
    postal_code: "72555",
    full_city_name: "Marcella, AR",
    city_name: "Marcella",
  },
  {
    postal_code: "29843",
    full_city_name: "Olar, SC",
    city_name: "Olar",
  },
  {
    postal_code: "82301",
    full_city_name: "Rawlins, WY",
    city_name: "Rawlins",
  },
  {
    postal_code: "50651",
    full_city_name: "La Porte City, IA",
    city_name: "La Porte City",
  },
  {
    postal_code: "63763",
    full_city_name: "Mc Gee, MO",
    city_name: "Mc Gee",
  },
  {
    postal_code: "64423",
    full_city_name: "Barnard, MO",
    city_name: "Barnard",
  },
  {
    postal_code: "91523",
    full_city_name: "Burbank, CA",
    city_name: "Burbank",
  },
  {
    postal_code: "16641",
    full_city_name: "Gallitzin, PA",
    city_name: "Gallitzin",
  },
  {
    postal_code: "74869",
    full_city_name: "Sparks, OK",
    city_name: "Sparks",
  },
  {
    postal_code: "67647",
    full_city_name: "Long Island, KS",
    city_name: "Long Island",
  },
  {
    postal_code: "58472",
    full_city_name: "Montpelier, ND",
    city_name: "Montpelier",
  },
  {
    postal_code: "64126",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "83013",
    full_city_name: "Moran, WY",
    city_name: "Moran",
  },
  {
    postal_code: "36320",
    full_city_name: "Cottonwood, AL",
    city_name: "Cottonwood",
  },
  {
    postal_code: "50431",
    full_city_name: "Coulter, IA",
    city_name: "Coulter",
  },
  {
    postal_code: "49886",
    full_city_name: "Spalding, MI",
    city_name: "Spalding",
  },
  {
    postal_code: "25067",
    full_city_name: "East Bank, WV",
    city_name: "East Bank",
  },
  {
    postal_code: "79029",
    full_city_name: "Dumas, TX",
    city_name: "Dumas",
  },
  {
    postal_code: "17263",
    full_city_name: "State Line, PA",
    city_name: "State Line",
  },
  {
    postal_code: "64769",
    full_city_name: "Mindenmines, MO",
    city_name: "Mindenmines",
  },
  {
    postal_code: "53022",
    full_city_name: "Germantown, WI",
    city_name: "Germantown",
  },
  {
    postal_code: "15366",
    full_city_name: "Van Voorhis, PA",
    city_name: "Van Voorhis",
  },
  {
    postal_code: "46377",
    full_city_name: "Shelby, IN",
    city_name: "Shelby",
  },
  {
    postal_code: "30259",
    full_city_name: "Moreland, GA",
    city_name: "Moreland",
  },
  {
    postal_code: "61847",
    full_city_name: "Gifford, IL",
    city_name: "Gifford",
  },
  {
    postal_code: "50536",
    full_city_name: "Emmetsburg, IA",
    city_name: "Emmetsburg",
  },
  {
    postal_code: "44682",
    full_city_name: "Tuscarawas, OH",
    city_name: "Tuscarawas",
  },
  {
    postal_code: "79607",
    full_city_name: "Dyess Afb, TX",
    city_name: "Dyess Afb",
  },
  {
    postal_code: "50676",
    full_city_name: "Tripoli, IA",
    city_name: "Tripoli",
  },
  {
    postal_code: "66539",
    full_city_name: "Silver Lake, KS",
    city_name: "Silver Lake",
  },
  {
    postal_code: "24847",
    full_city_name: "Itmann, WV",
    city_name: "Itmann",
  },
  {
    postal_code: "98320",
    full_city_name: "Brinnon, WA",
    city_name: "Brinnon",
  },
  {
    postal_code: "50134",
    full_city_name: "Kelley, IA",
    city_name: "Kelley",
  },
  {
    postal_code: "37210",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "47969",
    full_city_name: "Newtown, IN",
    city_name: "Newtown",
  },
  {
    postal_code: "54965",
    full_city_name: "Pine River, WI",
    city_name: "Pine River",
  },
  {
    postal_code: "58577",
    full_city_name: "Washburn, ND",
    city_name: "Washburn",
  },
  {
    postal_code: "50165",
    full_city_name: "Millerton, IA",
    city_name: "Millerton",
  },
  {
    postal_code: "25437",
    full_city_name: "Points, WV",
    city_name: "Points",
  },
  {
    postal_code: "68331",
    full_city_name: "Cortland, NE",
    city_name: "Cortland",
  },
  {
    postal_code: "53029",
    full_city_name: "Hartland, WI",
    city_name: "Hartland",
  },
  {
    postal_code: "07506",
    full_city_name: "Hawthorne, NJ",
    city_name: "Hawthorne",
  },
  {
    postal_code: "58243",
    full_city_name: "Hoople, ND",
    city_name: "Hoople",
  },
  {
    postal_code: "13665",
    full_city_name: "Natural Bridge, NY",
    city_name: "Natural Bridge",
  },
  {
    postal_code: "98833",
    full_city_name: "Mazama, WA",
    city_name: "Mazama",
  },
  {
    postal_code: "68071",
    full_city_name: "Winnebago, NE",
    city_name: "Winnebago",
  },
  {
    postal_code: "68438",
    full_city_name: "Sprague, NE",
    city_name: "Sprague",
  },
  {
    postal_code: "55922",
    full_city_name: "Canton, MN",
    city_name: "Canton",
  },
  {
    postal_code: "64642",
    full_city_name: "Gilman City, MO",
    city_name: "Gilman City",
  },
  {
    postal_code: "30547",
    full_city_name: "Homer, GA",
    city_name: "Homer",
  },
  {
    postal_code: "79229",
    full_city_name: "Dickens, TX",
    city_name: "Dickens",
  },
  {
    postal_code: "28308",
    full_city_name: "Pope Army Airfield, NC",
    city_name: "Pope Army Airfield",
  },
  {
    postal_code: "19730",
    full_city_name: "Odessa, DE",
    city_name: "Odessa",
  },
  {
    postal_code: "67575",
    full_city_name: "Rush Center, KS",
    city_name: "Rush Center",
  },
  {
    postal_code: "48363",
    full_city_name: "Oakland, MI",
    city_name: "Oakland",
  },
  {
    postal_code: "81241",
    full_city_name: "Pitkin, CO",
    city_name: "Pitkin",
  },
  {
    postal_code: "88256",
    full_city_name: "Loving, NM",
    city_name: "Loving",
  },
  {
    postal_code: "98233",
    full_city_name: "Burlington, WA",
    city_name: "Burlington",
  },
  {
    postal_code: "54491",
    full_city_name: "White Lake, WI",
    city_name: "White Lake",
  },
  {
    postal_code: "27047",
    full_city_name: "Siloam, NC",
    city_name: "Siloam",
  },
  {
    postal_code: "46958",
    full_city_name: "Mexico, IN",
    city_name: "Mexico",
  },
  {
    postal_code: "07417",
    full_city_name: "Franklin Lakes, NJ",
    city_name: "Franklin Lakes",
  },
  {
    postal_code: "35033",
    full_city_name: "Bremen, AL",
    city_name: "Bremen",
  },
  {
    postal_code: "58325",
    full_city_name: "Churchs Ferry, ND",
    city_name: "Churchs Ferry",
  },
  {
    postal_code: "65589",
    full_city_name: "Yukon, MO",
    city_name: "Yukon",
  },
  {
    postal_code: "43337",
    full_city_name: "Morral, OH",
    city_name: "Morral",
  },
  {
    postal_code: "68313",
    full_city_name: "Beaver Crossing, NE",
    city_name: "Beaver Crossing",
  },
  {
    postal_code: "35581",
    full_city_name: "Phil Campbell, AL",
    city_name: "Phil Campbell",
  },
  {
    postal_code: "42036",
    full_city_name: "Dexter, KY",
    city_name: "Dexter",
  },
  {
    postal_code: "45761",
    full_city_name: "Millfield, OH",
    city_name: "Millfield",
  },
  {
    postal_code: "68030",
    full_city_name: "Homer, NE",
    city_name: "Homer",
  },
  {
    postal_code: "63041",
    full_city_name: "Grubville, MO",
    city_name: "Grubville",
  },
  {
    postal_code: "72419",
    full_city_name: "Caraway, AR",
    city_name: "Caraway",
  },
  {
    postal_code: "64028",
    full_city_name: "Farley, MO",
    city_name: "Farley",
  },
  {
    postal_code: "98834",
    full_city_name: "Methow, WA",
    city_name: "Methow",
  },
  {
    postal_code: "81077",
    full_city_name: "Swink, CO",
    city_name: "Swink",
  },
  {
    postal_code: "95471",
    full_city_name: "Rio Nido, CA",
    city_name: "Rio Nido",
  },
  {
    postal_code: "44627",
    full_city_name: "Fredericksburg, OH",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "50622",
    full_city_name: "Denver, IA",
    city_name: "Denver",
  },
  {
    postal_code: "72376",
    full_city_name: "Proctor, AR",
    city_name: "Proctor",
  },
  {
    postal_code: "84038",
    full_city_name: "Laketown, UT",
    city_name: "Laketown",
  },
  {
    postal_code: "54160",
    full_city_name: "Potter, WI",
    city_name: "Potter",
  },
  {
    postal_code: "38762",
    full_city_name: "Mound Bayou, MS",
    city_name: "Mound Bayou",
  },
  {
    postal_code: "12574",
    full_city_name: "Rhinecliff, NY",
    city_name: "Rhinecliff",
  },
  {
    postal_code: "73080",
    full_city_name: "Purcell, OK",
    city_name: "Purcell",
  },
  {
    postal_code: "39160",
    full_city_name: "Sallis, MS",
    city_name: "Sallis",
  },
  {
    postal_code: "51364",
    full_city_name: "Terril, IA",
    city_name: "Terril",
  },
  {
    postal_code: "67029",
    full_city_name: "Coldwater, KS",
    city_name: "Coldwater",
  },
  {
    postal_code: "54619",
    full_city_name: "Cashton, WI",
    city_name: "Cashton",
  },
  {
    postal_code: "47845",
    full_city_name: "Coalmont, IN",
    city_name: "Coalmont",
  },
  {
    postal_code: "70639",
    full_city_name: "Evans, LA",
    city_name: "Evans",
  },
  {
    postal_code: "62473",
    full_city_name: "Watson, IL",
    city_name: "Watson",
  },
  {
    postal_code: "49718",
    full_city_name: "Carp Lake, MI",
    city_name: "Carp Lake",
  },
  {
    postal_code: "80448",
    full_city_name: "Grant, CO",
    city_name: "Grant",
  },
  {
    postal_code: "40481",
    full_city_name: "Sandgap, KY",
    city_name: "Sandgap",
  },
  {
    postal_code: "81022",
    full_city_name: "Avondale, CO",
    city_name: "Avondale",
  },
  {
    postal_code: "36033",
    full_city_name: "Georgiana, AL",
    city_name: "Georgiana",
  },
  {
    postal_code: "57501",
    full_city_name: "Pierre, SD",
    city_name: "Pierre",
  },
  {
    postal_code: "16236",
    full_city_name: "Mc Grann, PA",
    city_name: "Mc Grann",
  },
  {
    postal_code: "30451",
    full_city_name: "Pulaski, GA",
    city_name: "Pulaski",
  },
  {
    postal_code: "17935",
    full_city_name: "Girardville, PA",
    city_name: "Girardville",
  },
  {
    postal_code: "63631",
    full_city_name: "Caledonia, MO",
    city_name: "Caledonia",
  },
  {
    postal_code: "65202",
    full_city_name: "Columbia, MO",
    city_name: "Columbia",
  },
  {
    postal_code: "73625",
    full_city_name: "Butler, OK",
    city_name: "Butler",
  },
  {
    postal_code: "46772",
    full_city_name: "Monroe, IN",
    city_name: "Monroe",
  },
  {
    postal_code: "53178",
    full_city_name: "Sullivan, WI",
    city_name: "Sullivan",
  },
  {
    postal_code: "81123",
    full_city_name: "Blanca, CO",
    city_name: "Blanca",
  },
  {
    postal_code: "72025",
    full_city_name: "Casa, AR",
    city_name: "Casa",
  },
  {
    postal_code: "47649",
    full_city_name: "Francisco, IN",
    city_name: "Francisco",
  },
  {
    postal_code: "18101",
    full_city_name: "Allentown, PA",
    city_name: "Allentown",
  },
  {
    postal_code: "81131",
    full_city_name: "Crestone, CO",
    city_name: "Crestone",
  },
  {
    postal_code: "96819",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "12765",
    full_city_name: "Neversink, NY",
    city_name: "Neversink",
  },
  {
    postal_code: "17002",
    full_city_name: "Allensville, PA",
    city_name: "Allensville",
  },
  {
    postal_code: "42215",
    full_city_name: "Cerulean, KY",
    city_name: "Cerulean",
  },
  {
    postal_code: "10984",
    full_city_name: "Thiells, NY",
    city_name: "Thiells",
  },
  {
    postal_code: "58639",
    full_city_name: "Hettinger, ND",
    city_name: "Hettinger",
  },
  {
    postal_code: "76238",
    full_city_name: "Era, TX",
    city_name: "Era",
  },
  {
    postal_code: "79756",
    full_city_name: "Monahans, TX",
    city_name: "Monahans",
  },
  {
    postal_code: "49770",
    full_city_name: "Petoskey, MI",
    city_name: "Petoskey",
  },
  {
    postal_code: "57247",
    full_city_name: "Lake City, SD",
    city_name: "Lake City",
  },
  {
    postal_code: "47666",
    full_city_name: "Patoka, IN",
    city_name: "Patoka",
  },
  {
    postal_code: "27813",
    full_city_name: "Black Creek, NC",
    city_name: "Black Creek",
  },
  {
    postal_code: "16829",
    full_city_name: "Clarence, PA",
    city_name: "Clarence",
  },
  {
    postal_code: "25204",
    full_city_name: "Twilight, WV",
    city_name: "Twilight",
  },
  {
    postal_code: "40357",
    full_city_name: "North Middletown, KY",
    city_name: "North Middletown",
  },
  {
    postal_code: "12741",
    full_city_name: "Hankins, NY",
    city_name: "Hankins",
  },
  {
    postal_code: "16868",
    full_city_name: "Pine Grove Mills, PA",
    city_name: "Pine Grove Mills",
  },
  {
    postal_code: "98365",
    full_city_name: "Port Ludlow, WA",
    city_name: "Port Ludlow",
  },
  {
    postal_code: "31045",
    full_city_name: "Jewell, GA",
    city_name: "Jewell",
  },
  {
    postal_code: "16260",
    full_city_name: "Vowinckel, PA",
    city_name: "Vowinckel",
  },
  {
    postal_code: "72073",
    full_city_name: "Humphrey, AR",
    city_name: "Humphrey",
  },
  {
    postal_code: "36318",
    full_city_name: "Coffee Springs, AL",
    city_name: "Coffee Springs",
  },
  {
    postal_code: "36435",
    full_city_name: "Coy, AL",
    city_name: "Coy",
  },
  {
    postal_code: "96054",
    full_city_name: "Lookout, CA",
    city_name: "Lookout",
  },
  {
    postal_code: "50453",
    full_city_name: "Leland, IA",
    city_name: "Leland",
  },
  {
    postal_code: "58272",
    full_city_name: "Petersburg, ND",
    city_name: "Petersburg",
  },
  {
    postal_code: "36314",
    full_city_name: "Black, AL",
    city_name: "Black",
  },
  {
    postal_code: "67117",
    full_city_name: "North Newton, KS",
    city_name: "North Newton",
  },
  {
    postal_code: "46111",
    full_city_name: "Brooklyn, IN",
    city_name: "Brooklyn",
  },
  {
    postal_code: "62445",
    full_city_name: "Montrose, IL",
    city_name: "Montrose",
  },
  {
    postal_code: "31629",
    full_city_name: "Dixie, GA",
    city_name: "Dixie",
  },
  {
    postal_code: "13630",
    full_city_name: "De Kalb Junction, NY",
    city_name: "De Kalb Junction",
  },
  {
    postal_code: "59301",
    full_city_name: "Miles City, MT",
    city_name: "Miles City",
  },
  {
    postal_code: "39705",
    full_city_name: "Columbus, MS",
    city_name: "Columbus",
  },
  {
    postal_code: "51565",
    full_city_name: "Portsmouth, IA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "80546",
    full_city_name: "Severance, CO",
    city_name: "Severance",
  },
  {
    postal_code: "72396",
    full_city_name: "Wynne, AR",
    city_name: "Wynne",
  },
  {
    postal_code: "25259",
    full_city_name: "Looneyville, WV",
    city_name: "Looneyville",
  },
  {
    postal_code: "38341",
    full_city_name: "Holladay, TN",
    city_name: "Holladay",
  },
  {
    postal_code: "24083",
    full_city_name: "Daleville, VA",
    city_name: "Daleville",
  },
  {
    postal_code: "65640",
    full_city_name: "Dunnegan, MO",
    city_name: "Dunnegan",
  },
  {
    postal_code: "51571",
    full_city_name: "Silver City, IA",
    city_name: "Silver City",
  },
  {
    postal_code: "72821",
    full_city_name: "Altus, AR",
    city_name: "Altus",
  },
  {
    postal_code: "24413",
    full_city_name: "Blue Grass, VA",
    city_name: "Blue Grass",
  },
  {
    postal_code: "95389",
    full_city_name: "Yosemite National Park, CA",
    city_name: "Yosemite National Park",
  },
  {
    postal_code: "66041",
    full_city_name: "Lancaster, KS",
    city_name: "Lancaster",
  },
  {
    postal_code: "39648",
    full_city_name: "Mccomb, MS",
    city_name: "Mccomb",
  },
  {
    postal_code: "14783",
    full_city_name: "Steamburg, NY",
    city_name: "Steamburg",
  },
  {
    postal_code: "97476",
    full_city_name: "Sixes, OR",
    city_name: "Sixes",
  },
  {
    postal_code: "37036",
    full_city_name: "Charlotte, TN",
    city_name: "Charlotte",
  },
  {
    postal_code: "14479",
    full_city_name: "Knowlesville, NY",
    city_name: "Knowlesville",
  },
  {
    postal_code: "49766",
    full_city_name: "Ossineke, MI",
    city_name: "Ossineke",
  },
  {
    postal_code: "75105",
    full_city_name: "Chatfield, TX",
    city_name: "Chatfield",
  },
  {
    postal_code: "18927",
    full_city_name: "Hilltown, PA",
    city_name: "Hilltown",
  },
  {
    postal_code: "74555",
    full_city_name: "Lane, OK",
    city_name: "Lane",
  },
  {
    postal_code: "15742",
    full_city_name: "Glen Campbell, PA",
    city_name: "Glen Campbell",
  },
  {
    postal_code: "67352",
    full_city_name: "Longton, KS",
    city_name: "Longton",
  },
  {
    postal_code: "13633",
    full_city_name: "De Peyster, NY",
    city_name: "De Peyster",
  },
  {
    postal_code: "30634",
    full_city_name: "Dewy Rose, GA",
    city_name: "Dewy Rose",
  },
  {
    postal_code: "70740",
    full_city_name: "Grosse Tete, LA",
    city_name: "Grosse Tete",
  },
  {
    postal_code: "35564",
    full_city_name: "Hackleburg, AL",
    city_name: "Hackleburg",
  },
  {
    postal_code: "16027",
    full_city_name: "Connoquenessing, PA",
    city_name: "Connoquenessing",
  },
  {
    postal_code: "42085",
    full_city_name: "Water Valley, KY",
    city_name: "Water Valley",
  },
  {
    postal_code: "50457",
    full_city_name: "Meservey, IA",
    city_name: "Meservey",
  },
  {
    postal_code: "98562",
    full_city_name: "Moclips, WA",
    city_name: "Moclips",
  },
  {
    postal_code: "87026",
    full_city_name: "Laguna, NM",
    city_name: "Laguna",
  },
  {
    postal_code: "48740",
    full_city_name: "Harrisville, MI",
    city_name: "Harrisville",
  },
  {
    postal_code: "85617",
    full_city_name: "Mc Neal, AZ",
    city_name: "Mc Neal",
  },
  {
    postal_code: "63857",
    full_city_name: "Kennett, MO",
    city_name: "Kennett",
  },
  {
    postal_code: "24167",
    full_city_name: "Staffordsville, VA",
    city_name: "Staffordsville",
  },
  {
    postal_code: "65732",
    full_city_name: "Preston, MO",
    city_name: "Preston",
  },
  {
    postal_code: "57268",
    full_city_name: "Toronto, SD",
    city_name: "Toronto",
  },
  {
    postal_code: "87933",
    full_city_name: "Derry, NM",
    city_name: "Derry",
  },
  {
    postal_code: "62365",
    full_city_name: "Plainville, IL",
    city_name: "Plainville",
  },
  {
    postal_code: "65582",
    full_city_name: "Vienna, MO",
    city_name: "Vienna",
  },
  {
    postal_code: "48062",
    full_city_name: "Richmond, MI",
    city_name: "Richmond",
  },
  {
    postal_code: "72568",
    full_city_name: "Pleasant Plains, AR",
    city_name: "Pleasant Plains",
  },
  {
    postal_code: "50860",
    full_city_name: "Redding, IA",
    city_name: "Redding",
  },
  {
    postal_code: "76930",
    full_city_name: "Barnhart, TX",
    city_name: "Barnhart",
  },
  {
    postal_code: "72476",
    full_city_name: "Walnut Ridge, AR",
    city_name: "Walnut Ridge",
  },
  {
    postal_code: "56536",
    full_city_name: "Felton, MN",
    city_name: "Felton",
  },
  {
    postal_code: "67060",
    full_city_name: "Haysville, KS",
    city_name: "Haysville",
  },
  {
    postal_code: "49011",
    full_city_name: "Athens, MI",
    city_name: "Athens",
  },
  {
    postal_code: "65543",
    full_city_name: "Lynchburg, MO",
    city_name: "Lynchburg",
  },
  {
    postal_code: "31542",
    full_city_name: "Hoboken, GA",
    city_name: "Hoboken",
  },
  {
    postal_code: "48075",
    full_city_name: "Southfield, MI",
    city_name: "Southfield",
  },
  {
    postal_code: "67753",
    full_city_name: "Rexford, KS",
    city_name: "Rexford",
  },
  {
    postal_code: "17260",
    full_city_name: "Shirleysburg, PA",
    city_name: "Shirleysburg",
  },
  {
    postal_code: "68661",
    full_city_name: "Schuyler, NE",
    city_name: "Schuyler",
  },
  {
    postal_code: "57039",
    full_city_name: "Lennox, SD",
    city_name: "Lennox",
  },
  {
    postal_code: "56248",
    full_city_name: "Herman, MN",
    city_name: "Herman",
  },
  {
    postal_code: "51561",
    full_city_name: "Pacific Junction, IA",
    city_name: "Pacific Junction",
  },
  {
    postal_code: "81653",
    full_city_name: "Slater, CO",
    city_name: "Slater",
  },
  {
    postal_code: "58794",
    full_city_name: "White Earth, ND",
    city_name: "White Earth",
  },
  {
    postal_code: "59442",
    full_city_name: "Fort Benton, MT",
    city_name: "Fort Benton",
  },
  {
    postal_code: "95514",
    full_city_name: "Blocksburg, CA",
    city_name: "Blocksburg",
  },
  {
    postal_code: "12973",
    full_city_name: "Piercefield, NY",
    city_name: "Piercefield",
  },
  {
    postal_code: "36351",
    full_city_name: "New Brockton, AL",
    city_name: "New Brockton",
  },
  {
    postal_code: "41044",
    full_city_name: "Germantown, KY",
    city_name: "Germantown",
  },
  {
    postal_code: "79539",
    full_city_name: "O Brien, TX",
    city_name: "O Brien",
  },
  {
    postal_code: "18035",
    full_city_name: "Cherryville, PA",
    city_name: "Cherryville",
  },
  {
    postal_code: "17211",
    full_city_name: "Artemas, PA",
    city_name: "Artemas",
  },
  {
    postal_code: "79372",
    full_city_name: "Sundown, TX",
    city_name: "Sundown",
  },
  {
    postal_code: "82324",
    full_city_name: "Elk Mountain, WY",
    city_name: "Elk Mountain",
  },
  {
    postal_code: "84714",
    full_city_name: "Beryl, UT",
    city_name: "Beryl",
  },
  {
    postal_code: "07641",
    full_city_name: "Haworth, NJ",
    city_name: "Haworth",
  },
  {
    postal_code: "52157",
    full_city_name: "Mc Gregor, IA",
    city_name: "Mc Gregor",
  },
  {
    postal_code: "72026",
    full_city_name: "Casscoe, AR",
    city_name: "Casscoe",
  },
  {
    postal_code: "99361",
    full_city_name: "Waitsburg, WA",
    city_name: "Waitsburg",
  },
  {
    postal_code: "97147",
    full_city_name: "Wheeler, OR",
    city_name: "Wheeler",
  },
  {
    postal_code: "46795",
    full_city_name: "Wolcottville, IN",
    city_name: "Wolcottville",
  },
  {
    postal_code: "59219",
    full_city_name: "Dagmar, MT",
    city_name: "Dagmar",
  },
  {
    postal_code: "28135",
    full_city_name: "Polkton, NC",
    city_name: "Polkton",
  },
  {
    postal_code: "70757",
    full_city_name: "Maringouin, LA",
    city_name: "Maringouin",
  },
  {
    postal_code: "87731",
    full_city_name: "Montezuma, NM",
    city_name: "Montezuma",
  },
  {
    postal_code: "74827",
    full_city_name: "Atwood, OK",
    city_name: "Atwood",
  },
  {
    postal_code: "96123",
    full_city_name: "Ravendale, CA",
    city_name: "Ravendale",
  },
  {
    postal_code: "84734",
    full_city_name: "Hanksville, UT",
    city_name: "Hanksville",
  },
  {
    postal_code: "84723",
    full_city_name: "Circleville, UT",
    city_name: "Circleville",
  },
  {
    postal_code: "38474",
    full_city_name: "Mount Pleasant, TN",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "72449",
    full_city_name: "O Kean, AR",
    city_name: "O Kean",
  },
  {
    postal_code: "57037",
    full_city_name: "Irene, SD",
    city_name: "Irene",
  },
  {
    postal_code: "31035",
    full_city_name: "Harrison, GA",
    city_name: "Harrison",
  },
  {
    postal_code: "62537",
    full_city_name: "Harristown, IL",
    city_name: "Harristown",
  },
  {
    postal_code: "71764",
    full_city_name: "Stephens, AR",
    city_name: "Stephens",
  },
  {
    postal_code: "26437",
    full_city_name: "Smithfield, WV",
    city_name: "Smithfield",
  },
  {
    postal_code: "15942",
    full_city_name: "Mineral Point, PA",
    city_name: "Mineral Point",
  },
  {
    postal_code: "74457",
    full_city_name: "Proctor, OK",
    city_name: "Proctor",
  },
  {
    postal_code: "72035",
    full_city_name: "Conway, AR",
    city_name: "Conway",
  },
  {
    postal_code: "67346",
    full_city_name: "Grenola, KS",
    city_name: "Grenola",
  },
  {
    postal_code: "87579",
    full_city_name: "Vadito, NM",
    city_name: "Vadito",
  },
  {
    postal_code: "81422",
    full_city_name: "Naturita, CO",
    city_name: "Naturita",
  },
  {
    postal_code: "77458",
    full_city_name: "Midfield, TX",
    city_name: "Midfield",
  },
  {
    postal_code: "66732",
    full_city_name: "Elsmore, KS",
    city_name: "Elsmore",
  },
  {
    postal_code: "15953",
    full_city_name: "Seanor, PA",
    city_name: "Seanor",
  },
  {
    postal_code: "56289",
    full_city_name: "Sunburg, MN",
    city_name: "Sunburg",
  },
  {
    postal_code: "26621",
    full_city_name: "Flatwoods, WV",
    city_name: "Flatwoods",
  },
  {
    postal_code: "73058",
    full_city_name: "Meridian, OK",
    city_name: "Meridian",
  },
  {
    postal_code: "67022",
    full_city_name: "Caldwell, KS",
    city_name: "Caldwell",
  },
  {
    postal_code: "59274",
    full_city_name: "Vida, MT",
    city_name: "Vida",
  },
  {
    postal_code: "59026",
    full_city_name: "Edgar, MT",
    city_name: "Edgar",
  },
  {
    postal_code: "70449",
    full_city_name: "Maurepas, LA",
    city_name: "Maurepas",
  },
  {
    postal_code: "59256",
    full_city_name: "Raymond, MT",
    city_name: "Raymond",
  },
  {
    postal_code: "62477",
    full_city_name: "West Union, IL",
    city_name: "West Union",
  },
  {
    postal_code: "26410",
    full_city_name: "Newburg, WV",
    city_name: "Newburg",
  },
  {
    postal_code: "68826",
    full_city_name: "Central City, NE",
    city_name: "Central City",
  },
  {
    postal_code: "87564",
    full_city_name: "San Cristobal, NM",
    city_name: "San Cristobal",
  },
  {
    postal_code: "16670",
    full_city_name: "Queen, PA",
    city_name: "Queen",
  },
  {
    postal_code: "78017",
    full_city_name: "Dilley, TX",
    city_name: "Dilley",
  },
  {
    postal_code: "56588",
    full_city_name: "Vining, MN",
    city_name: "Vining",
  },
  {
    postal_code: "66842",
    full_city_name: "Cassoday, KS",
    city_name: "Cassoday",
  },
  {
    postal_code: "61413",
    full_city_name: "Alpha, IL",
    city_name: "Alpha",
  },
  {
    postal_code: "23068",
    full_city_name: "Hallieford, VA",
    city_name: "Hallieford",
  },
  {
    postal_code: "92389",
    full_city_name: "Tecopa, CA",
    city_name: "Tecopa",
  },
  {
    postal_code: "22504",
    full_city_name: "Laneview, VA",
    city_name: "Laneview",
  },
  {
    postal_code: "49303",
    full_city_name: "Bailey, MI",
    city_name: "Bailey",
  },
  {
    postal_code: "74856",
    full_city_name: "Mill Creek, OK",
    city_name: "Mill Creek",
  },
  {
    postal_code: "66520",
    full_city_name: "Olsburg, KS",
    city_name: "Olsburg",
  },
  {
    postal_code: "31649",
    full_city_name: "Stockton, GA",
    city_name: "Stockton",
  },
  {
    postal_code: "72587",
    full_city_name: "Wiseman, AR",
    city_name: "Wiseman",
  },
  {
    postal_code: "58267",
    full_city_name: "Northwood, ND",
    city_name: "Northwood",
  },
  {
    postal_code: "70748",
    full_city_name: "Jackson, LA",
    city_name: "Jackson",
  },
  {
    postal_code: "72639",
    full_city_name: "Harriet, AR",
    city_name: "Harriet",
  },
  {
    postal_code: "82215",
    full_city_name: "Hartville, WY",
    city_name: "Hartville",
  },
  {
    postal_code: "41773",
    full_city_name: "Vicco, KY",
    city_name: "Vicco",
  },
  {
    postal_code: "61336",
    full_city_name: "Magnolia, IL",
    city_name: "Magnolia",
  },
  {
    postal_code: "76943",
    full_city_name: "Ozona, TX",
    city_name: "Ozona",
  },
  {
    postal_code: "53221",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "62821",
    full_city_name: "Carmi, IL",
    city_name: "Carmi",
  },
  {
    postal_code: "38469",
    full_city_name: "Loretto, TN",
    city_name: "Loretto",
  },
  {
    postal_code: "38730",
    full_city_name: "Boyle, MS",
    city_name: "Boyle",
  },
  {
    postal_code: "25544",
    full_city_name: "Myra, WV",
    city_name: "Myra",
  },
  {
    postal_code: "45674",
    full_city_name: "Rio Grande, OH",
    city_name: "Rio Grande",
  },
  {
    postal_code: "13696",
    full_city_name: "West Stockholm, NY",
    city_name: "West Stockholm",
  },
  {
    postal_code: "76371",
    full_city_name: "Munday, TX",
    city_name: "Munday",
  },
  {
    postal_code: "57386",
    full_city_name: "Yale, SD",
    city_name: "Yale",
  },
  {
    postal_code: "67425",
    full_city_name: "Brookville, KS",
    city_name: "Brookville",
  },
  {
    postal_code: "23409",
    full_city_name: "Mears, VA",
    city_name: "Mears",
  },
  {
    postal_code: "14202",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "59532",
    full_city_name: "Kremlin, MT",
    city_name: "Kremlin",
  },
  {
    postal_code: "75643",
    full_city_name: "Gary, TX",
    city_name: "Gary",
  },
  {
    postal_code: "04979",
    full_city_name: "Solon, ME",
    city_name: "Solon",
  },
  {
    postal_code: "62545",
    full_city_name: "Mechanicsburg, IL",
    city_name: "Mechanicsburg",
  },
  {
    postal_code: "66865",
    full_city_name: "Olpe, KS",
    city_name: "Olpe",
  },
  {
    postal_code: "24823",
    full_city_name: "Coal Mountain, WV",
    city_name: "Coal Mountain",
  },
  {
    postal_code: "56078",
    full_city_name: "Pemberton, MN",
    city_name: "Pemberton",
  },
  {
    postal_code: "26411",
    full_city_name: "New Milton, WV",
    city_name: "New Milton",
  },
  {
    postal_code: "98857",
    full_city_name: "Warden, WA",
    city_name: "Warden",
  },
  {
    postal_code: "02894",
    full_city_name: "Wood River Junction, RI",
    city_name: "Wood River Junction",
  },
  {
    postal_code: "62059",
    full_city_name: "Lovejoy, IL",
    city_name: "Lovejoy",
  },
  {
    postal_code: "62077",
    full_city_name: "Panama, IL",
    city_name: "Panama",
  },
  {
    postal_code: "73539",
    full_city_name: "Elmer, OK",
    city_name: "Elmer",
  },
  {
    postal_code: "47354",
    full_city_name: "Losantville, IN",
    city_name: "Losantville",
  },
  {
    postal_code: "62831",
    full_city_name: "Du Bois, IL",
    city_name: "Du Bois",
  },
  {
    postal_code: "97326",
    full_city_name: "Blodgett, OR",
    city_name: "Blodgett",
  },
  {
    postal_code: "48218",
    full_city_name: "River Rouge, MI",
    city_name: "River Rouge",
  },
  {
    postal_code: "56128",
    full_city_name: "Edgerton, MN",
    city_name: "Edgerton",
  },
  {
    postal_code: "82225",
    full_city_name: "Lusk, WY",
    city_name: "Lusk",
  },
  {
    postal_code: "47369",
    full_city_name: "Pennville, IN",
    city_name: "Pennville",
  },
  {
    postal_code: "67106",
    full_city_name: "Milton, KS",
    city_name: "Milton",
  },
  {
    postal_code: "03853",
    full_city_name: "Mirror Lake, NH",
    city_name: "Mirror Lake",
  },
  {
    postal_code: "56319",
    full_city_name: "Carlos, MN",
    city_name: "Carlos",
  },
  {
    postal_code: "82712",
    full_city_name: "Beulah, WY",
    city_name: "Beulah",
  },
  {
    postal_code: "49116",
    full_city_name: "Lakeside, MI",
    city_name: "Lakeside",
  },
  {
    postal_code: "89412",
    full_city_name: "Gerlach, NV",
    city_name: "Gerlach",
  },
  {
    postal_code: "50605",
    full_city_name: "Aredale, IA",
    city_name: "Aredale",
  },
  {
    postal_code: "95488",
    full_city_name: "Westport, CA",
    city_name: "Westport",
  },
  {
    postal_code: "05455",
    full_city_name: "Fairfield, VT",
    city_name: "Fairfield",
  },
  {
    postal_code: "56382",
    full_city_name: "Swanville, MN",
    city_name: "Swanville",
  },
  {
    postal_code: "57271",
    full_city_name: "Vienna, SD",
    city_name: "Vienna",
  },
  {
    postal_code: "55968",
    full_city_name: "Reads Landing, MN",
    city_name: "Reads Landing",
  },
  {
    postal_code: "59483",
    full_city_name: "Sun River, MT",
    city_name: "Sun River",
  },
  {
    postal_code: "42236",
    full_city_name: "Herndon, KY",
    city_name: "Herndon",
  },
  {
    postal_code: "76519",
    full_city_name: "Burlington, TX",
    city_name: "Burlington",
  },
  {
    postal_code: "39461",
    full_city_name: "Neely, MS",
    city_name: "Neely",
  },
  {
    postal_code: "36908",
    full_city_name: "Gilbertown, AL",
    city_name: "Gilbertown",
  },
  {
    postal_code: "17249",
    full_city_name: "Rockhill Furnace, PA",
    city_name: "Rockhill Furnace",
  },
  {
    postal_code: "72926",
    full_city_name: "Boles, AR",
    city_name: "Boles",
  },
  {
    postal_code: "46039",
    full_city_name: "Forest, IN",
    city_name: "Forest",
  },
  {
    postal_code: "71069",
    full_city_name: "Rodessa, LA",
    city_name: "Rodessa",
  },
  {
    postal_code: "02554",
    full_city_name: "Nantucket, MA",
    city_name: "Nantucket",
  },
  {
    postal_code: "36736",
    full_city_name: "Dixons Mills, AL",
    city_name: "Dixons Mills",
  },
  {
    postal_code: "16870",
    full_city_name: "Port Matilda, PA",
    city_name: "Port Matilda",
  },
  {
    postal_code: "50853",
    full_city_name: "Massena, IA",
    city_name: "Massena",
  },
  {
    postal_code: "76357",
    full_city_name: "Byers, TX",
    city_name: "Byers",
  },
  {
    postal_code: "59910",
    full_city_name: "Big Arm, MT",
    city_name: "Big Arm",
  },
  {
    postal_code: "45652",
    full_city_name: "Mc Dermott, OH",
    city_name: "Mc Dermott",
  },
  {
    postal_code: "68873",
    full_city_name: "Saint Paul, NE",
    city_name: "Saint Paul",
  },
  {
    postal_code: "52336",
    full_city_name: "Springville, IA",
    city_name: "Springville",
  },
  {
    postal_code: "61335",
    full_city_name: "Mc Nabb, IL",
    city_name: "Mc Nabb",
  },
  {
    postal_code: "17855",
    full_city_name: "New Berlin, PA",
    city_name: "New Berlin",
  },
  {
    postal_code: "37137",
    full_city_name: "Nunnelly, TN",
    city_name: "Nunnelly",
  },
  {
    postal_code: "47359",
    full_city_name: "Montpelier, IN",
    city_name: "Montpelier",
  },
  {
    postal_code: "38560",
    full_city_name: "Elmwood, TN",
    city_name: "Elmwood",
  },
  {
    postal_code: "36321",
    full_city_name: "Cowarts, AL",
    city_name: "Cowarts",
  },
  {
    postal_code: "62661",
    full_city_name: "Loami, IL",
    city_name: "Loami",
  },
  {
    postal_code: "58370",
    full_city_name: "Saint Michael, ND",
    city_name: "Saint Michael",
  },
  {
    postal_code: "62463",
    full_city_name: "Stewardson, IL",
    city_name: "Stewardson",
  },
  {
    postal_code: "62923",
    full_city_name: "Cypress, IL",
    city_name: "Cypress",
  },
  {
    postal_code: "58429",
    full_city_name: "Dazey, ND",
    city_name: "Dazey",
  },
  {
    postal_code: "62943",
    full_city_name: "Grantsburg, IL",
    city_name: "Grantsburg",
  },
  {
    postal_code: "22834",
    full_city_name: "Linville, VA",
    city_name: "Linville",
  },
  {
    postal_code: "96112",
    full_city_name: "Fort Bidwell, CA",
    city_name: "Fort Bidwell",
  },
  {
    postal_code: "62351",
    full_city_name: "Mendon, IL",
    city_name: "Mendon",
  },
  {
    postal_code: "75448",
    full_city_name: "Klondike, TX",
    city_name: "Klondike",
  },
  {
    postal_code: "76308",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "57528",
    full_city_name: "Colome, SD",
    city_name: "Colome",
  },
  {
    postal_code: "85534",
    full_city_name: "Duncan, AZ",
    city_name: "Duncan",
  },
  {
    postal_code: "40140",
    full_city_name: "Garfield, KY",
    city_name: "Garfield",
  },
  {
    postal_code: "89020",
    full_city_name: "Amargosa Valley, NV",
    city_name: "Amargosa Valley",
  },
  {
    postal_code: "98439",
    full_city_name: "Lakewood, WA",
    city_name: "Lakewood",
  },
  {
    postal_code: "15756",
    full_city_name: "Mc Intyre, PA",
    city_name: "Mc Intyre",
  },
  {
    postal_code: "67030",
    full_city_name: "Colwich, KS",
    city_name: "Colwich",
  },
  {
    postal_code: "38241",
    full_city_name: "Palmersville, TN",
    city_name: "Palmersville",
  },
  {
    postal_code: "26430",
    full_city_name: "Sand Fork, WV",
    city_name: "Sand Fork",
  },
  {
    postal_code: "95571",
    full_city_name: "Weott, CA",
    city_name: "Weott",
  },
  {
    postal_code: "31798",
    full_city_name: "Wray, GA",
    city_name: "Wray",
  },
  {
    postal_code: "74839",
    full_city_name: "Dustin, OK",
    city_name: "Dustin",
  },
  {
    postal_code: "64017",
    full_city_name: "Camden, MO",
    city_name: "Camden",
  },
  {
    postal_code: "38381",
    full_city_name: "Toone, TN",
    city_name: "Toone",
  },
  {
    postal_code: "59430",
    full_city_name: "Denton, MT",
    city_name: "Denton",
  },
  {
    postal_code: "56244",
    full_city_name: "Hancock, MN",
    city_name: "Hancock",
  },
  {
    postal_code: "67228",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "59525",
    full_city_name: "Gildford, MT",
    city_name: "Gildford",
  },
  {
    postal_code: "37809",
    full_city_name: "Midway, TN",
    city_name: "Midway",
  },
  {
    postal_code: "32426",
    full_city_name: "Campbellton, FL",
    city_name: "Campbellton",
  },
  {
    postal_code: "88347",
    full_city_name: "Sacramento, NM",
    city_name: "Sacramento",
  },
  {
    postal_code: "62357",
    full_city_name: "New Salem, IL",
    city_name: "New Salem",
  },
  {
    postal_code: "67857",
    full_city_name: "Kendall, KS",
    city_name: "Kendall",
  },
  {
    postal_code: "48757",
    full_city_name: "Reese, MI",
    city_name: "Reese",
  },
  {
    postal_code: "43346",
    full_city_name: "Roundhead, OH",
    city_name: "Roundhead",
  },
  {
    postal_code: "30810",
    full_city_name: "Gibson, GA",
    city_name: "Gibson",
  },
  {
    postal_code: "53216",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "98392",
    full_city_name: "Suquamish, WA",
    city_name: "Suquamish",
  },
  {
    postal_code: "41754",
    full_city_name: "Krypton, KY",
    city_name: "Krypton",
  },
  {
    postal_code: "41267",
    full_city_name: "Warfield, KY",
    city_name: "Warfield",
  },
  {
    postal_code: "29838",
    full_city_name: "Modoc, SC",
    city_name: "Modoc",
  },
  {
    postal_code: "73063",
    full_city_name: "Mulhall, OK",
    city_name: "Mulhall",
  },
  {
    postal_code: "18202",
    full_city_name: "Hazleton, PA",
    city_name: "Hazleton",
  },
  {
    postal_code: "47174",
    full_city_name: "Sulphur, IN",
    city_name: "Sulphur",
  },
  {
    postal_code: "98047",
    full_city_name: "Pacific, WA",
    city_name: "Pacific",
  },
  {
    postal_code: "17834",
    full_city_name: "Kulpmont, PA",
    city_name: "Kulpmont",
  },
  {
    postal_code: "72457",
    full_city_name: "Portia, AR",
    city_name: "Portia",
  },
  {
    postal_code: "62031",
    full_city_name: "Fieldon, IL",
    city_name: "Fieldon",
  },
  {
    postal_code: "91980",
    full_city_name: "Tecate, CA",
    city_name: "Tecate",
  },
  {
    postal_code: "98651",
    full_city_name: "Underwood, WA",
    city_name: "Underwood",
  },
  {
    postal_code: "45734",
    full_city_name: "Graysville, OH",
    city_name: "Graysville",
  },
  {
    postal_code: "13310",
    full_city_name: "Bouckville, NY",
    city_name: "Bouckville",
  },
  {
    postal_code: "82710",
    full_city_name: "Aladdin, WY",
    city_name: "Aladdin",
  },
  {
    postal_code: "81149",
    full_city_name: "Saguache, CO",
    city_name: "Saguache",
  },
  {
    postal_code: "82845",
    full_city_name: "Wyarno, WY",
    city_name: "Wyarno",
  },
  {
    postal_code: "39663",
    full_city_name: "Silver Creek, MS",
    city_name: "Silver Creek",
  },
  {
    postal_code: "12750",
    full_city_name: "Kenoza Lake, NY",
    city_name: "Kenoza Lake",
  },
  {
    postal_code: "46613",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "57069",
    full_city_name: "Vermillion, SD",
    city_name: "Vermillion",
  },
  {
    postal_code: "13115",
    full_city_name: "Minetto, NY",
    city_name: "Minetto",
  },
  {
    postal_code: "45743",
    full_city_name: "Long Bottom, OH",
    city_name: "Long Bottom",
  },
  {
    postal_code: "78938",
    full_city_name: "Ellinger, TX",
    city_name: "Ellinger",
  },
  {
    postal_code: "73121",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "32568",
    full_city_name: "Mc David, FL",
    city_name: "Mc David",
  },
  {
    postal_code: "36456",
    full_city_name: "Mc Kenzie, AL",
    city_name: "Mc Kenzie",
  },
  {
    postal_code: "61875",
    full_city_name: "Seymour, IL",
    city_name: "Seymour",
  },
  {
    postal_code: "61419",
    full_city_name: "Bishop Hill, IL",
    city_name: "Bishop Hill",
  },
  {
    postal_code: "43037",
    full_city_name: "Martinsburg, OH",
    city_name: "Martinsburg",
  },
  {
    postal_code: "14145",
    full_city_name: "Strykersville, NY",
    city_name: "Strykersville",
  },
  {
    postal_code: "46730",
    full_city_name: "Corunna, IN",
    city_name: "Corunna",
  },
  {
    postal_code: "16720",
    full_city_name: "Austin, PA",
    city_name: "Austin",
  },
  {
    postal_code: "13303",
    full_city_name: "Ava, NY",
    city_name: "Ava",
  },
  {
    postal_code: "04410",
    full_city_name: "Bradford, ME",
    city_name: "Bradford",
  },
  {
    postal_code: "39325",
    full_city_name: "Collinsville, MS",
    city_name: "Collinsville",
  },
  {
    postal_code: "67111",
    full_city_name: "Murdock, KS",
    city_name: "Murdock",
  },
  {
    postal_code: "44861",
    full_city_name: "Old Fort, OH",
    city_name: "Old Fort",
  },
  {
    postal_code: "98530",
    full_city_name: "Bucoda, WA",
    city_name: "Bucoda",
  },
  {
    postal_code: "58384",
    full_city_name: "Willow City, ND",
    city_name: "Willow City",
  },
  {
    postal_code: "17842",
    full_city_name: "Middleburg, PA",
    city_name: "Middleburg",
  },
  {
    postal_code: "99346",
    full_city_name: "Plymouth, WA",
    city_name: "Plymouth",
  },
  {
    postal_code: "72046",
    full_city_name: "England, AR",
    city_name: "England",
  },
  {
    postal_code: "37325",
    full_city_name: "Delano, TN",
    city_name: "Delano",
  },
  {
    postal_code: "07739",
    full_city_name: "Little Silver, NJ",
    city_name: "Little Silver",
  },
  {
    postal_code: "15540",
    full_city_name: "Fort Hill, PA",
    city_name: "Fort Hill",
  },
  {
    postal_code: "40155",
    full_city_name: "Muldraugh, KY",
    city_name: "Muldraugh",
  },
  {
    postal_code: "77475",
    full_city_name: "Sheridan, TX",
    city_name: "Sheridan",
  },
  {
    postal_code: "52147",
    full_city_name: "Hawkeye, IA",
    city_name: "Hawkeye",
  },
  {
    postal_code: "63848",
    full_city_name: "Gideon, MO",
    city_name: "Gideon",
  },
  {
    postal_code: "31772",
    full_city_name: "Oakfield, GA",
    city_name: "Oakfield",
  },
  {
    postal_code: "98613",
    full_city_name: "Centerville, WA",
    city_name: "Centerville",
  },
  {
    postal_code: "88336",
    full_city_name: "Hondo, NM",
    city_name: "Hondo",
  },
  {
    postal_code: "83448",
    full_city_name: "Sugar City, ID",
    city_name: "Sugar City",
  },
  {
    postal_code: "95697",
    full_city_name: "Yolo, CA",
    city_name: "Yolo",
  },
  {
    postal_code: "71369",
    full_city_name: "Simmesport, LA",
    city_name: "Simmesport",
  },
  {
    postal_code: "06258",
    full_city_name: "Pomfret, CT",
    city_name: "Pomfret",
  },
  {
    postal_code: "95415",
    full_city_name: "Boonville, CA",
    city_name: "Boonville",
  },
  {
    postal_code: "61358",
    full_city_name: "Rutland, IL",
    city_name: "Rutland",
  },
  {
    postal_code: "73105",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "45319",
    full_city_name: "Donnelsville, OH",
    city_name: "Donnelsville",
  },
  {
    postal_code: "24086",
    full_city_name: "Eggleston, VA",
    city_name: "Eggleston",
  },
  {
    postal_code: "02836",
    full_city_name: "Kenyon, RI",
    city_name: "Kenyon",
  },
  {
    postal_code: "83436",
    full_city_name: "Newdale, ID",
    city_name: "Newdale",
  },
  {
    postal_code: "48015",
    full_city_name: "Center Line, MI",
    city_name: "Center Line",
  },
  {
    postal_code: "57312",
    full_city_name: "Alpena, SD",
    city_name: "Alpena",
  },
  {
    postal_code: "80452",
    full_city_name: "Idaho Springs, CO",
    city_name: "Idaho Springs",
  },
  {
    postal_code: "16340",
    full_city_name: "Pittsfield, PA",
    city_name: "Pittsfield",
  },
  {
    postal_code: "95585",
    full_city_name: "Leggett, CA",
    city_name: "Leggett",
  },
  {
    postal_code: "71003",
    full_city_name: "Athens, LA",
    city_name: "Athens",
  },
  {
    postal_code: "54106",
    full_city_name: "Black Creek, WI",
    city_name: "Black Creek",
  },
  {
    postal_code: "78162",
    full_city_name: "Tuleta, TX",
    city_name: "Tuleta",
  },
  {
    postal_code: "62339",
    full_city_name: "Golden, IL",
    city_name: "Golden",
  },
  {
    postal_code: "48070",
    full_city_name: "Huntington Woods, MI",
    city_name: "Huntington Woods",
  },
  {
    postal_code: "51540",
    full_city_name: "Hastings, IA",
    city_name: "Hastings",
  },
  {
    postal_code: "98590",
    full_city_name: "Tokeland, WA",
    city_name: "Tokeland",
  },
  {
    postal_code: "52248",
    full_city_name: "Keota, IA",
    city_name: "Keota",
  },
  {
    postal_code: "40176",
    full_city_name: "Webster, KY",
    city_name: "Webster",
  },
  {
    postal_code: "10970",
    full_city_name: "Pomona, NY",
    city_name: "Pomona",
  },
  {
    postal_code: "46565",
    full_city_name: "Shipshewana, IN",
    city_name: "Shipshewana",
  },
  {
    postal_code: "83438",
    full_city_name: "Parker, ID",
    city_name: "Parker",
  },
  {
    postal_code: "98828",
    full_city_name: "Malaga, WA",
    city_name: "Malaga",
  },
  {
    postal_code: "48701",
    full_city_name: "Akron, MI",
    city_name: "Akron",
  },
  {
    postal_code: "65254",
    full_city_name: "Glasgow, MO",
    city_name: "Glasgow",
  },
  {
    postal_code: "30833",
    full_city_name: "Wrens, GA",
    city_name: "Wrens",
  },
  {
    postal_code: "38858",
    full_city_name: "Nettleton, MS",
    city_name: "Nettleton",
  },
  {
    postal_code: "48083",
    full_city_name: "Troy, MI",
    city_name: "Troy",
  },
  {
    postal_code: "18441",
    full_city_name: "Lenoxville, PA",
    city_name: "Lenoxville",
  },
  {
    postal_code: "29364",
    full_city_name: "Lockhart, SC",
    city_name: "Lockhart",
  },
  {
    postal_code: "48417",
    full_city_name: "Burt, MI",
    city_name: "Burt",
  },
  {
    postal_code: "47849",
    full_city_name: "Fairbanks, IN",
    city_name: "Fairbanks",
  },
  {
    postal_code: "26056",
    full_city_name: "New Manchester, WV",
    city_name: "New Manchester",
  },
  {
    postal_code: "98571",
    full_city_name: "Pacific Beach, WA",
    city_name: "Pacific Beach",
  },
  {
    postal_code: "64434",
    full_city_name: "Conception Junction, MO",
    city_name: "Conception Junction",
  },
  {
    postal_code: "44855",
    full_city_name: "North Fairfield, OH",
    city_name: "North Fairfield",
  },
  {
    postal_code: "47613",
    full_city_name: "Elberfeld, IN",
    city_name: "Elberfeld",
  },
  {
    postal_code: "04574",
    full_city_name: "Washington, ME",
    city_name: "Washington",
  },
  {
    postal_code: "85322",
    full_city_name: "Arlington, AZ",
    city_name: "Arlington",
  },
  {
    postal_code: "67645",
    full_city_name: "Lenora, KS",
    city_name: "Lenora",
  },
  {
    postal_code: "67146",
    full_city_name: "Udall, KS",
    city_name: "Udall",
  },
  {
    postal_code: "73669",
    full_city_name: "Thomas, OK",
    city_name: "Thomas",
  },
  {
    postal_code: "64427",
    full_city_name: "Bolckow, MO",
    city_name: "Bolckow",
  },
  {
    postal_code: "31650",
    full_city_name: "Willacoochee, GA",
    city_name: "Willacoochee",
  },
  {
    postal_code: "42721",
    full_city_name: "Caneyville, KY",
    city_name: "Caneyville",
  },
  {
    postal_code: "32465",
    full_city_name: "Wewahitchka, FL",
    city_name: "Wewahitchka",
  },
  {
    postal_code: "89821",
    full_city_name: "Crescent Valley, NV",
    city_name: "Crescent Valley",
  },
  {
    postal_code: "17099",
    full_city_name: "Yeagertown, PA",
    city_name: "Yeagertown",
  },
  {
    postal_code: "77969",
    full_city_name: "La Salle, TX",
    city_name: "La Salle",
  },
  {
    postal_code: "72651",
    full_city_name: "Midway, AR",
    city_name: "Midway",
  },
  {
    postal_code: "15435",
    full_city_name: "Fairbank, PA",
    city_name: "Fairbank",
  },
  {
    postal_code: "79378",
    full_city_name: "Wellman, TX",
    city_name: "Wellman",
  },
  {
    postal_code: "62695",
    full_city_name: "Woodson, IL",
    city_name: "Woodson",
  },
  {
    postal_code: "35057",
    full_city_name: "Cullman, AL",
    city_name: "Cullman",
  },
  {
    postal_code: "48881",
    full_city_name: "Saranac, MI",
    city_name: "Saranac",
  },
  {
    postal_code: "35802",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "31037",
    full_city_name: "Mc Rae Helena, GA",
    city_name: "Mc Rae Helena",
  },
  {
    postal_code: "46570",
    full_city_name: "Tippecanoe, IN",
    city_name: "Tippecanoe",
  },
  {
    postal_code: "65264",
    full_city_name: "Martinsburg, MO",
    city_name: "Martinsburg",
  },
  {
    postal_code: "65440",
    full_city_name: "Boss, MO",
    city_name: "Boss",
  },
  {
    postal_code: "93608",
    full_city_name: "Cantua Creek, CA",
    city_name: "Cantua Creek",
  },
  {
    postal_code: "95528",
    full_city_name: "Carlotta, CA",
    city_name: "Carlotta",
  },
  {
    postal_code: "93255",
    full_city_name: "Onyx, CA",
    city_name: "Onyx",
  },
  {
    postal_code: "55111",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "73668",
    full_city_name: "Texola, OK",
    city_name: "Texola",
  },
  {
    postal_code: "65791",
    full_city_name: "Thayer, MO",
    city_name: "Thayer",
  },
  {
    postal_code: "45716",
    full_city_name: "Buchtel, OH",
    city_name: "Buchtel",
  },
  {
    postal_code: "68663",
    full_city_name: "Silver Creek, NE",
    city_name: "Silver Creek",
  },
  {
    postal_code: "79092",
    full_city_name: "Vega, TX",
    city_name: "Vega",
  },
  {
    postal_code: "62693",
    full_city_name: "Williamsville, IL",
    city_name: "Williamsville",
  },
  {
    postal_code: "70357",
    full_city_name: "Golden Meadow, LA",
    city_name: "Golden Meadow",
  },
  {
    postal_code: "95466",
    full_city_name: "Philo, CA",
    city_name: "Philo",
  },
  {
    postal_code: "29044",
    full_city_name: "Eastover, SC",
    city_name: "Eastover",
  },
  {
    postal_code: "66733",
    full_city_name: "Erie, KS",
    city_name: "Erie",
  },
  {
    postal_code: "42528",
    full_city_name: "Dunnville, KY",
    city_name: "Dunnville",
  },
  {
    postal_code: "50680",
    full_city_name: "Wellsburg, IA",
    city_name: "Wellsburg",
  },
  {
    postal_code: "19559",
    full_city_name: "Strausstown, PA",
    city_name: "Strausstown",
  },
  {
    postal_code: "14880",
    full_city_name: "Scio, NY",
    city_name: "Scio",
  },
  {
    postal_code: "96111",
    full_city_name: "Floriston, CA",
    city_name: "Floriston",
  },
  {
    postal_code: "91916",
    full_city_name: "Descanso, CA",
    city_name: "Descanso",
  },
  {
    postal_code: "24464",
    full_city_name: "Montebello, VA",
    city_name: "Montebello",
  },
  {
    postal_code: "61412",
    full_city_name: "Alexis, IL",
    city_name: "Alexis",
  },
  {
    postal_code: "67511",
    full_city_name: "Albert, KS",
    city_name: "Albert",
  },
  {
    postal_code: "68982",
    full_city_name: "Wilcox, NE",
    city_name: "Wilcox",
  },
  {
    postal_code: "83824",
    full_city_name: "Desmet, ID",
    city_name: "Desmet",
  },
  {
    postal_code: "71063",
    full_city_name: "Pelican, LA",
    city_name: "Pelican",
  },
  {
    postal_code: "95721",
    full_city_name: "Echo Lake, CA",
    city_name: "Echo Lake",
  },
  {
    postal_code: "43805",
    full_city_name: "Blissfield, OH",
    city_name: "Blissfield",
  },
  {
    postal_code: "98946",
    full_city_name: "Thorp, WA",
    city_name: "Thorp",
  },
  {
    postal_code: "79044",
    full_city_name: "Hartley, TX",
    city_name: "Hartley",
  },
  {
    postal_code: "65077",
    full_city_name: "Steedman, MO",
    city_name: "Steedman",
  },
  {
    postal_code: "64645",
    full_city_name: "Harris, MO",
    city_name: "Harris",
  },
  {
    postal_code: "57370",
    full_city_name: "Pukwana, SD",
    city_name: "Pukwana",
  },
  {
    postal_code: "37047",
    full_city_name: "Cornersville, TN",
    city_name: "Cornersville",
  },
  {
    postal_code: "61539",
    full_city_name: "Kingston Mines, IL",
    city_name: "Kingston Mines",
  },
  {
    postal_code: "12518",
    full_city_name: "Cornwall, NY",
    city_name: "Cornwall",
  },
  {
    postal_code: "35582",
    full_city_name: "Red Bay, AL",
    city_name: "Red Bay",
  },
  {
    postal_code: "15783",
    full_city_name: "West Lebanon, PA",
    city_name: "West Lebanon",
  },
  {
    postal_code: "40060",
    full_city_name: "Raywick, KY",
    city_name: "Raywick",
  },
  {
    postal_code: "41271",
    full_city_name: "Williamsport, KY",
    city_name: "Williamsport",
  },
  {
    postal_code: "46974",
    full_city_name: "Roann, IN",
    city_name: "Roann",
  },
  {
    postal_code: "49664",
    full_city_name: "Maple City, MI",
    city_name: "Maple City",
  },
  {
    postal_code: "81239",
    full_city_name: "Parlin, CO",
    city_name: "Parlin",
  },
  {
    postal_code: "39667",
    full_city_name: "Tylertown, MS",
    city_name: "Tylertown",
  },
  {
    postal_code: "52247",
    full_city_name: "Kalona, IA",
    city_name: "Kalona",
  },
  {
    postal_code: "80230",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "24105",
    full_city_name: "Indian Valley, VA",
    city_name: "Indian Valley",
  },
  {
    postal_code: "46798",
    full_city_name: "Yoder, IN",
    city_name: "Yoder",
  },
  {
    postal_code: "61050",
    full_city_name: "Mc Connell, IL",
    city_name: "Mc Connell",
  },
  {
    postal_code: "41189",
    full_city_name: "Tollesboro, KY",
    city_name: "Tollesboro",
  },
  {
    postal_code: "99117",
    full_city_name: "Creston, WA",
    city_name: "Creston",
  },
  {
    postal_code: "47116",
    full_city_name: "Eckerty, IN",
    city_name: "Eckerty",
  },
  {
    postal_code: "45685",
    full_city_name: "Thurman, OH",
    city_name: "Thurman",
  },
  {
    postal_code: "46637",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "47010",
    full_city_name: "Bath, IN",
    city_name: "Bath",
  },
  {
    postal_code: "67341",
    full_city_name: "Dennis, KS",
    city_name: "Dennis",
  },
  {
    postal_code: "50585",
    full_city_name: "Sioux Rapids, IA",
    city_name: "Sioux Rapids",
  },
  {
    postal_code: "25085",
    full_city_name: "Gauley Bridge, WV",
    city_name: "Gauley Bridge",
  },
  {
    postal_code: "46376",
    full_city_name: "Schneider, IN",
    city_name: "Schneider",
  },
  {
    postal_code: "29126",
    full_city_name: "Pomaria, SC",
    city_name: "Pomaria",
  },
  {
    postal_code: "47866",
    full_city_name: "Pimento, IN",
    city_name: "Pimento",
  },
  {
    postal_code: "71483",
    full_city_name: "Winnfield, LA",
    city_name: "Winnfield",
  },
  {
    postal_code: "28447",
    full_city_name: "Ivanhoe, NC",
    city_name: "Ivanhoe",
  },
  {
    postal_code: "65766",
    full_city_name: "Udall, MO",
    city_name: "Udall",
  },
  {
    postal_code: "37046",
    full_city_name: "College Grove, TN",
    city_name: "College Grove",
  },
  {
    postal_code: "49067",
    full_city_name: "Marcellus, MI",
    city_name: "Marcellus",
  },
  {
    postal_code: "58042",
    full_city_name: "Harwood, ND",
    city_name: "Harwood",
  },
  {
    postal_code: "31634",
    full_city_name: "Homerville, GA",
    city_name: "Homerville",
  },
  {
    postal_code: "29042",
    full_city_name: "Denmark, SC",
    city_name: "Denmark",
  },
  {
    postal_code: "31041",
    full_city_name: "Ideal, GA",
    city_name: "Ideal",
  },
  {
    postal_code: "78516",
    full_city_name: "Alamo, TX",
    city_name: "Alamo",
  },
  {
    postal_code: "98831",
    full_city_name: "Manson, WA",
    city_name: "Manson",
  },
  {
    postal_code: "73055",
    full_city_name: "Marlow, OK",
    city_name: "Marlow",
  },
  {
    postal_code: "57451",
    full_city_name: "Ipswich, SD",
    city_name: "Ipswich",
  },
  {
    postal_code: "81076",
    full_city_name: "Sugar City, CO",
    city_name: "Sugar City",
  },
  {
    postal_code: "27982",
    full_city_name: "Waves, NC",
    city_name: "Waves",
  },
  {
    postal_code: "31836",
    full_city_name: "Woodland, GA",
    city_name: "Woodland",
  },
  {
    postal_code: "64733",
    full_city_name: "Chilhowee, MO",
    city_name: "Chilhowee",
  },
  {
    postal_code: "62628",
    full_city_name: "Chapin, IL",
    city_name: "Chapin",
  },
  {
    postal_code: "59412",
    full_city_name: "Belt, MT",
    city_name: "Belt",
  },
  {
    postal_code: "23306",
    full_city_name: "Belle Haven, VA",
    city_name: "Belle Haven",
  },
  {
    postal_code: "75551",
    full_city_name: "Atlanta, TX",
    city_name: "Atlanta",
  },
  {
    postal_code: "48204",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "46561",
    full_city_name: "Osceola, IN",
    city_name: "Osceola",
  },
  {
    postal_code: "57246",
    full_city_name: "Labolt, SD",
    city_name: "Labolt",
  },
  {
    postal_code: "53092",
    full_city_name: "Mequon, WI",
    city_name: "Mequon",
  },
  {
    postal_code: "22641",
    full_city_name: "Strasburg, VA",
    city_name: "Strasburg",
  },
  {
    postal_code: "59751",
    full_city_name: "Silver Star, MT",
    city_name: "Silver Star",
  },
  {
    postal_code: "25193",
    full_city_name: "Sylvester, WV",
    city_name: "Sylvester",
  },
  {
    postal_code: "38476",
    full_city_name: "Primm Springs, TN",
    city_name: "Primm Springs",
  },
  {
    postal_code: "94938",
    full_city_name: "Lagunitas, CA",
    city_name: "Lagunitas",
  },
  {
    postal_code: "05757",
    full_city_name: "Middletown Springs, VT",
    city_name: "Middletown Springs",
  },
  {
    postal_code: "62897",
    full_city_name: "Whittington, IL",
    city_name: "Whittington",
  },
  {
    postal_code: "07463",
    full_city_name: "Waldwick, NJ",
    city_name: "Waldwick",
  },
  {
    postal_code: "63956",
    full_city_name: "Patterson, MO",
    city_name: "Patterson",
  },
  {
    postal_code: "62367",
    full_city_name: "Plymouth, IL",
    city_name: "Plymouth",
  },
  {
    postal_code: "50034",
    full_city_name: "Blairsburg, IA",
    city_name: "Blairsburg",
  },
  {
    postal_code: "50616",
    full_city_name: "Charles City, IA",
    city_name: "Charles City",
  },
  {
    postal_code: "70346",
    full_city_name: "Donaldsonville, LA",
    city_name: "Donaldsonville",
  },
  {
    postal_code: "48463",
    full_city_name: "Otisville, MI",
    city_name: "Otisville",
  },
  {
    postal_code: "62940",
    full_city_name: "Gorham, IL",
    city_name: "Gorham",
  },
  {
    postal_code: "40810",
    full_city_name: "Bledsoe, KY",
    city_name: "Bledsoe",
  },
  {
    postal_code: "50067",
    full_city_name: "Decatur, IA",
    city_name: "Decatur",
  },
  {
    postal_code: "52222",
    full_city_name: "Deep River, IA",
    city_name: "Deep River",
  },
  {
    postal_code: "98394",
    full_city_name: "Vaughn, WA",
    city_name: "Vaughn",
  },
  {
    postal_code: "76888",
    full_city_name: "Voss, TX",
    city_name: "Voss",
  },
  {
    postal_code: "30631",
    full_city_name: "Crawfordville, GA",
    city_name: "Crawfordville",
  },
  {
    postal_code: "57226",
    full_city_name: "Clear Lake, SD",
    city_name: "Clear Lake",
  },
  {
    postal_code: "71962",
    full_city_name: "Okolona, AR",
    city_name: "Okolona",
  },
  {
    postal_code: "48095",
    full_city_name: "Washington, MI",
    city_name: "Washington",
  },
  {
    postal_code: "50447",
    full_city_name: "Kanawha, IA",
    city_name: "Kanawha",
  },
  {
    postal_code: "04853",
    full_city_name: "North Haven, ME",
    city_name: "North Haven",
  },
  {
    postal_code: "26838",
    full_city_name: "Milam, WV",
    city_name: "Milam",
  },
  {
    postal_code: "87936",
    full_city_name: "Garfield, NM",
    city_name: "Garfield",
  },
  {
    postal_code: "80804",
    full_city_name: "Arriba, CO",
    city_name: "Arriba",
  },
  {
    postal_code: "68379",
    full_city_name: "Julian, NE",
    city_name: "Julian",
  },
  {
    postal_code: "52253",
    full_city_name: "Lisbon, IA",
    city_name: "Lisbon",
  },
  {
    postal_code: "81030",
    full_city_name: "Cheraw, CO",
    city_name: "Cheraw",
  },
  {
    postal_code: "56685",
    full_city_name: "Waskish, MN",
    city_name: "Waskish",
  },
  {
    postal_code: "73560",
    full_city_name: "Olustee, OK",
    city_name: "Olustee",
  },
  {
    postal_code: "25651",
    full_city_name: "Wharncliffe, WV",
    city_name: "Wharncliffe",
  },
  {
    postal_code: "74852",
    full_city_name: "Macomb, OK",
    city_name: "Macomb",
  },
  {
    postal_code: "66526",
    full_city_name: "Paxico, KS",
    city_name: "Paxico",
  },
  {
    postal_code: "36455",
    full_city_name: "Lockhart, AL",
    city_name: "Lockhart",
  },
  {
    postal_code: "62563",
    full_city_name: "Rochester, IL",
    city_name: "Rochester",
  },
  {
    postal_code: "07094",
    full_city_name: "Secaucus, NJ",
    city_name: "Secaucus",
  },
  {
    postal_code: "56129",
    full_city_name: "Ellsworth, MN",
    city_name: "Ellsworth",
  },
  {
    postal_code: "41256",
    full_city_name: "Staffordsville, KY",
    city_name: "Staffordsville",
  },
  {
    postal_code: "57766",
    full_city_name: "Oral, SD",
    city_name: "Oral",
  },
  {
    postal_code: "31764",
    full_city_name: "Leslie, GA",
    city_name: "Leslie",
  },
  {
    postal_code: "70542",
    full_city_name: "Gueydan, LA",
    city_name: "Gueydan",
  },
  {
    postal_code: "85934",
    full_city_name: "Pinedale, AZ",
    city_name: "Pinedale",
  },
  {
    postal_code: "59530",
    full_city_name: "Inverness, MT",
    city_name: "Inverness",
  },
  {
    postal_code: "31049",
    full_city_name: "Kite, GA",
    city_name: "Kite",
  },
  {
    postal_code: "28629",
    full_city_name: "Glendale Springs, NC",
    city_name: "Glendale Springs",
  },
  {
    postal_code: "75669",
    full_city_name: "Long Branch, TX",
    city_name: "Long Branch",
  },
  {
    postal_code: "38943",
    full_city_name: "Mc Carley, MS",
    city_name: "Mc Carley",
  },
  {
    postal_code: "57559",
    full_city_name: "Murdo, SD",
    city_name: "Murdo",
  },
  {
    postal_code: "56020",
    full_city_name: "Conger, MN",
    city_name: "Conger",
  },
  {
    postal_code: "60967",
    full_city_name: "Stockland, IL",
    city_name: "Stockland",
  },
  {
    postal_code: "87104",
    full_city_name: "Albuquerque, NM",
    city_name: "Albuquerque",
  },
  {
    postal_code: "57031",
    full_city_name: "Gayville, SD",
    city_name: "Gayville",
  },
  {
    postal_code: "25573",
    full_city_name: "Yawkey, WV",
    city_name: "Yawkey",
  },
  {
    postal_code: "97019",
    full_city_name: "Corbett, OR",
    city_name: "Corbett",
  },
  {
    postal_code: "85925",
    full_city_name: "Eagar, AZ",
    city_name: "Eagar",
  },
  {
    postal_code: "20868",
    full_city_name: "Spencerville, MD",
    city_name: "Spencerville",
  },
  {
    postal_code: "26405",
    full_city_name: "Moatsville, WV",
    city_name: "Moatsville",
  },
  {
    postal_code: "14437",
    full_city_name: "Dansville, NY",
    city_name: "Dansville",
  },
  {
    postal_code: "26291",
    full_city_name: "Slatyfork, WV",
    city_name: "Slatyfork",
  },
  {
    postal_code: "75574",
    full_city_name: "Simms, TX",
    city_name: "Simms",
  },
  {
    postal_code: "17845",
    full_city_name: "Millmont, PA",
    city_name: "Millmont",
  },
  {
    postal_code: "31072",
    full_city_name: "Pitts, GA",
    city_name: "Pitts",
  },
  {
    postal_code: "71222",
    full_city_name: "Bernice, LA",
    city_name: "Bernice",
  },
  {
    postal_code: "51554",
    full_city_name: "Mineola, IA",
    city_name: "Mineola",
  },
  {
    postal_code: "68653",
    full_city_name: "Platte Center, NE",
    city_name: "Platte Center",
  },
  {
    postal_code: "47260",
    full_city_name: "Medora, IN",
    city_name: "Medora",
  },
  {
    postal_code: "98241",
    full_city_name: "Darrington, WA",
    city_name: "Darrington",
  },
  {
    postal_code: "25669",
    full_city_name: "Crum, WV",
    city_name: "Crum",
  },
  {
    postal_code: "42716",
    full_city_name: "Buffalo, KY",
    city_name: "Buffalo",
  },
  {
    postal_code: "81220",
    full_city_name: "Cimarron, CO",
    city_name: "Cimarron",
  },
  {
    postal_code: "47532",
    full_city_name: "Ferdinand, IN",
    city_name: "Ferdinand",
  },
  {
    postal_code: "79009",
    full_city_name: "Bovina, TX",
    city_name: "Bovina",
  },
  {
    postal_code: "72425",
    full_city_name: "Delaplaine, AR",
    city_name: "Delaplaine",
  },
  {
    postal_code: "98536",
    full_city_name: "Copalis Crossing, WA",
    city_name: "Copalis Crossing",
  },
  {
    postal_code: "13636",
    full_city_name: "Ellisburg, NY",
    city_name: "Ellisburg",
  },
  {
    postal_code: "99729",
    full_city_name: "Cantwell, AK",
    city_name: "Cantwell",
  },
  {
    postal_code: "66758",
    full_city_name: "Neosho Falls, KS",
    city_name: "Neosho Falls",
  },
  {
    postal_code: "66010",
    full_city_name: "Blue Mound, KS",
    city_name: "Blue Mound",
  },
  {
    postal_code: "78663",
    full_city_name: "Round Mountain, TX",
    city_name: "Round Mountain",
  },
  {
    postal_code: "49642",
    full_city_name: "Idlewild, MI",
    city_name: "Idlewild",
  },
  {
    postal_code: "38851",
    full_city_name: "Houston, MS",
    city_name: "Houston",
  },
  {
    postal_code: "35744",
    full_city_name: "Dutton, AL",
    city_name: "Dutton",
  },
  {
    postal_code: "57344",
    full_city_name: "Harrison, SD",
    city_name: "Harrison",
  },
  {
    postal_code: "45003",
    full_city_name: "College Corner, OH",
    city_name: "College Corner",
  },
  {
    postal_code: "74966",
    full_city_name: "Wister, OK",
    city_name: "Wister",
  },
  {
    postal_code: "78562",
    full_city_name: "La Villa, TX",
    city_name: "La Villa",
  },
  {
    postal_code: "38879",
    full_city_name: "Verona, MS",
    city_name: "Verona",
  },
  {
    postal_code: "52042",
    full_city_name: "Edgewood, IA",
    city_name: "Edgewood",
  },
  {
    postal_code: "35571",
    full_city_name: "Hodges, AL",
    city_name: "Hodges",
  },
  {
    postal_code: "58495",
    full_city_name: "Wishek, ND",
    city_name: "Wishek",
  },
  {
    postal_code: "83111",
    full_city_name: "Auburn, WY",
    city_name: "Auburn",
  },
  {
    postal_code: "06456",
    full_city_name: "Middle Haddam, CT",
    city_name: "Middle Haddam",
  },
  {
    postal_code: "50484",
    full_city_name: "Woden, IA",
    city_name: "Woden",
  },
  {
    postal_code: "68824",
    full_city_name: "Cairo, NE",
    city_name: "Cairo",
  },
  {
    postal_code: "95910",
    full_city_name: "Alleghany, CA",
    city_name: "Alleghany",
  },
  {
    postal_code: "07627",
    full_city_name: "Demarest, NJ",
    city_name: "Demarest",
  },
  {
    postal_code: "57001",
    full_city_name: "Alcester, SD",
    city_name: "Alcester",
  },
  {
    postal_code: "17959",
    full_city_name: "New Philadelphia, PA",
    city_name: "New Philadelphia",
  },
  {
    postal_code: "73026",
    full_city_name: "Norman, OK",
    city_name: "Norman",
  },
  {
    postal_code: "87742",
    full_city_name: "Rociada, NM",
    city_name: "Rociada",
  },
  {
    postal_code: "01367",
    full_city_name: "Rowe, MA",
    city_name: "Rowe",
  },
  {
    postal_code: "74860",
    full_city_name: "Paden, OK",
    city_name: "Paden",
  },
  {
    postal_code: "25028",
    full_city_name: "Bob White, WV",
    city_name: "Bob White",
  },
  {
    postal_code: "61425",
    full_city_name: "Carman, IL",
    city_name: "Carman",
  },
  {
    postal_code: "71245",
    full_city_name: "Grambling, LA",
    city_name: "Grambling",
  },
  {
    postal_code: "72041",
    full_city_name: "De Valls Bluff, AR",
    city_name: "De Valls Bluff",
  },
  {
    postal_code: "83841",
    full_city_name: "Laclede, ID",
    city_name: "Laclede",
  },
  {
    postal_code: "88025",
    full_city_name: "Buckhorn, NM",
    city_name: "Buckhorn",
  },
  {
    postal_code: "98276",
    full_city_name: "Nooksack, WA",
    city_name: "Nooksack",
  },
  {
    postal_code: "49620",
    full_city_name: "Buckley, MI",
    city_name: "Buckley",
  },
  {
    postal_code: "87410",
    full_city_name: "Aztec, NM",
    city_name: "Aztec",
  },
  {
    postal_code: "89039",
    full_city_name: "Cal Nev Ari, NV",
    city_name: "Cal Nev Ari",
  },
  {
    postal_code: "54447",
    full_city_name: "Lublin, WI",
    city_name: "Lublin",
  },
  {
    postal_code: "58261",
    full_city_name: "Minto, ND",
    city_name: "Minto",
  },
  {
    postal_code: "70660",
    full_city_name: "Singer, LA",
    city_name: "Singer",
  },
  {
    postal_code: "28364",
    full_city_name: "Maxton, NC",
    city_name: "Maxton",
  },
  {
    postal_code: "98255",
    full_city_name: "Hamilton, WA",
    city_name: "Hamilton",
  },
  {
    postal_code: "13122",
    full_city_name: "New Woodstock, NY",
    city_name: "New Woodstock",
  },
  {
    postal_code: "45651",
    full_city_name: "Mc Arthur, OH",
    city_name: "Mc Arthur",
  },
  {
    postal_code: "46746",
    full_city_name: "Howe, IN",
    city_name: "Howe",
  },
  {
    postal_code: "68659",
    full_city_name: "Rogers, NE",
    city_name: "Rogers",
  },
  {
    postal_code: "73030",
    full_city_name: "Davis, OK",
    city_name: "Davis",
  },
  {
    postal_code: "36477",
    full_city_name: "Samson, AL",
    city_name: "Samson",
  },
  {
    postal_code: "67653",
    full_city_name: "Norcatur, KS",
    city_name: "Norcatur",
  },
  {
    postal_code: "65025",
    full_city_name: "Clarksburg, MO",
    city_name: "Clarksburg",
  },
  {
    postal_code: "68879",
    full_city_name: "Taylor, NE",
    city_name: "Taylor",
  },
  {
    postal_code: "89822",
    full_city_name: "Carlin, NV",
    city_name: "Carlin",
  },
  {
    postal_code: "45809",
    full_city_name: "Gomer, OH",
    city_name: "Gomer",
  },
  {
    postal_code: "48426",
    full_city_name: "Decker, MI",
    city_name: "Decker",
  },
  {
    postal_code: "17088",
    full_city_name: "Schaefferstown, PA",
    city_name: "Schaefferstown",
  },
  {
    postal_code: "20771",
    full_city_name: "Greenbelt, MD",
    city_name: "Greenbelt",
  },
  {
    postal_code: "61858",
    full_city_name: "Oakwood, IL",
    city_name: "Oakwood",
  },
  {
    postal_code: "65287",
    full_city_name: "Wooldridge, MO",
    city_name: "Wooldridge",
  },
  {
    postal_code: "17857",
    full_city_name: "Northumberland, PA",
    city_name: "Northumberland",
  },
  {
    postal_code: "46714",
    full_city_name: "Bluffton, IN",
    city_name: "Bluffton",
  },
  {
    postal_code: "66839",
    full_city_name: "Burlington, KS",
    city_name: "Burlington",
  },
  {
    postal_code: "67626",
    full_city_name: "Bunker Hill, KS",
    city_name: "Bunker Hill",
  },
  {
    postal_code: "36460",
    full_city_name: "Monroeville, AL",
    city_name: "Monroeville",
  },
  {
    postal_code: "20624",
    full_city_name: "Clements, MD",
    city_name: "Clements",
  },
  {
    postal_code: "04453",
    full_city_name: "Lagrange, ME",
    city_name: "Lagrange",
  },
  {
    postal_code: "57013",
    full_city_name: "Canton, SD",
    city_name: "Canton",
  },
  {
    postal_code: "41815",
    full_city_name: "Ermine, KY",
    city_name: "Ermine",
  },
  {
    postal_code: "63770",
    full_city_name: "Old Appleton, MO",
    city_name: "Old Appleton",
  },
  {
    postal_code: "58758",
    full_city_name: "Martin, ND",
    city_name: "Martin",
  },
  {
    postal_code: "26152",
    full_city_name: "Munday, WV",
    city_name: "Munday",
  },
  {
    postal_code: "74428",
    full_city_name: "Council Hill, OK",
    city_name: "Council Hill",
  },
  {
    postal_code: "64649",
    full_city_name: "Kidder, MO",
    city_name: "Kidder",
  },
  {
    postal_code: "29849",
    full_city_name: "Ulmer, SC",
    city_name: "Ulmer",
  },
  {
    postal_code: "54232",
    full_city_name: "Saint Nazianz, WI",
    city_name: "Saint Nazianz",
  },
  {
    postal_code: "13475",
    full_city_name: "Van Hornesville, NY",
    city_name: "Van Hornesville",
  },
  {
    postal_code: "37366",
    full_city_name: "Pelham, TN",
    city_name: "Pelham",
  },
  {
    postal_code: "48755",
    full_city_name: "Pigeon, MI",
    city_name: "Pigeon",
  },
  {
    postal_code: "64647",
    full_city_name: "Jameson, MO",
    city_name: "Jameson",
  },
  {
    postal_code: "12886",
    full_city_name: "Wevertown, NY",
    city_name: "Wevertown",
  },
  {
    postal_code: "57531",
    full_city_name: "Draper, SD",
    city_name: "Draper",
  },
  {
    postal_code: "52142",
    full_city_name: "Fayette, IA",
    city_name: "Fayette",
  },
  {
    postal_code: "47519",
    full_city_name: "Cannelburg, IN",
    city_name: "Cannelburg",
  },
  {
    postal_code: "56287",
    full_city_name: "Seaforth, MN",
    city_name: "Seaforth",
  },
  {
    postal_code: "21538",
    full_city_name: "Kitzmiller, MD",
    city_name: "Kitzmiller",
  },
  {
    postal_code: "41622",
    full_city_name: "Eastern, KY",
    city_name: "Eastern",
  },
  {
    postal_code: "57631",
    full_city_name: "Glenham, SD",
    city_name: "Glenham",
  },
  {
    postal_code: "37082",
    full_city_name: "Kingston Springs, TN",
    city_name: "Kingston Springs",
  },
  {
    postal_code: "71323",
    full_city_name: "Center Point, LA",
    city_name: "Center Point",
  },
  {
    postal_code: "72556",
    full_city_name: "Melbourne, AR",
    city_name: "Melbourne",
  },
  {
    postal_code: "15554",
    full_city_name: "New Paris, PA",
    city_name: "New Paris",
  },
  {
    postal_code: "96128",
    full_city_name: "Standish, CA",
    city_name: "Standish",
  },
  {
    postal_code: "25021",
    full_city_name: "Bim, WV",
    city_name: "Bim",
  },
  {
    postal_code: "65258",
    full_city_name: "Holliday, MO",
    city_name: "Holliday",
  },
  {
    postal_code: "31535",
    full_city_name: "Douglas, GA",
    city_name: "Douglas",
  },
  {
    postal_code: "41254",
    full_city_name: "River, KY",
    city_name: "River",
  },
  {
    postal_code: "23824",
    full_city_name: "Blackstone, VA",
    city_name: "Blackstone",
  },
  {
    postal_code: "52169",
    full_city_name: "Wadena, IA",
    city_name: "Wadena",
  },
  {
    postal_code: "39739",
    full_city_name: "Brooksville, MS",
    city_name: "Brooksville",
  },
  {
    postal_code: "31560",
    full_city_name: "Screven, GA",
    city_name: "Screven",
  },
  {
    postal_code: "29206",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "41615",
    full_city_name: "Dana, KY",
    city_name: "Dana",
  },
  {
    postal_code: "87530",
    full_city_name: "El Rito, NM",
    city_name: "El Rito",
  },
  {
    postal_code: "85346",
    full_city_name: "Quartzsite, AZ",
    city_name: "Quartzsite",
  },
  {
    postal_code: "12448",
    full_city_name: "Lake Hill, NY",
    city_name: "Lake Hill",
  },
  {
    postal_code: "97720",
    full_city_name: "Burns, OR",
    city_name: "Burns",
  },
  {
    postal_code: "52170",
    full_city_name: "Waterville, IA",
    city_name: "Waterville",
  },
  {
    postal_code: "76950",
    full_city_name: "Sonora, TX",
    city_name: "Sonora",
  },
  {
    postal_code: "46365",
    full_city_name: "Mill Creek, IN",
    city_name: "Mill Creek",
  },
  {
    postal_code: "99661",
    full_city_name: "Sand Point, AK",
    city_name: "Sand Point",
  },
  {
    postal_code: "98829",
    full_city_name: "Malott, WA",
    city_name: "Malott",
  },
  {
    postal_code: "10527",
    full_city_name: "Granite Springs, NY",
    city_name: "Granite Springs",
  },
  {
    postal_code: "67756",
    full_city_name: "Saint Francis, KS",
    city_name: "Saint Francis",
  },
  {
    postal_code: "82937",
    full_city_name: "Lyman, WY",
    city_name: "Lyman",
  },
  {
    postal_code: "88126",
    full_city_name: "Pep, NM",
    city_name: "Pep",
  },
  {
    postal_code: "07024",
    full_city_name: "Fort Lee, NJ",
    city_name: "Fort Lee",
  },
  {
    postal_code: "98843",
    full_city_name: "Orondo, WA",
    city_name: "Orondo",
  },
  {
    postal_code: "10596",
    full_city_name: "Verplanck, NY",
    city_name: "Verplanck",
  },
  {
    postal_code: "64455",
    full_city_name: "Graham, MO",
    city_name: "Graham",
  },
  {
    postal_code: "02826",
    full_city_name: "Glendale, RI",
    city_name: "Glendale",
  },
  {
    postal_code: "10989",
    full_city_name: "Valley Cottage, NY",
    city_name: "Valley Cottage",
  },
  {
    postal_code: "39746",
    full_city_name: "Hamilton, MS",
    city_name: "Hamilton",
  },
  {
    postal_code: "52313",
    full_city_name: "Mount Auburn, IA",
    city_name: "Mount Auburn",
  },
  {
    postal_code: "49955",
    full_city_name: "Painesdale, MI",
    city_name: "Painesdale",
  },
  {
    postal_code: "49710",
    full_city_name: "Barbeau, MI",
    city_name: "Barbeau",
  },
  {
    postal_code: "44699",
    full_city_name: "Tippecanoe, OH",
    city_name: "Tippecanoe",
  },
  {
    postal_code: "23440",
    full_city_name: "Tangier, VA",
    city_name: "Tangier",
  },
  {
    postal_code: "56580",
    full_city_name: "Sabin, MN",
    city_name: "Sabin",
  },
  {
    postal_code: "72416",
    full_city_name: "Bono, AR",
    city_name: "Bono",
  },
  {
    postal_code: "62510",
    full_city_name: "Assumption, IL",
    city_name: "Assumption",
  },
  {
    postal_code: "14813",
    full_city_name: "Belmont, NY",
    city_name: "Belmont",
  },
  {
    postal_code: "24853",
    full_city_name: "Kimball, WV",
    city_name: "Kimball",
  },
  {
    postal_code: "51038",
    full_city_name: "Merrill, IA",
    city_name: "Merrill",
  },
  {
    postal_code: "14013",
    full_city_name: "Basom, NY",
    city_name: "Basom",
  },
  {
    postal_code: "79502",
    full_city_name: "Aspermont, TX",
    city_name: "Aspermont",
  },
  {
    postal_code: "56384",
    full_city_name: "Upsala, MN",
    city_name: "Upsala",
  },
  {
    postal_code: "29006",
    full_city_name: "Batesburg, SC",
    city_name: "Batesburg",
  },
  {
    postal_code: "57367",
    full_city_name: "Pickstown, SD",
    city_name: "Pickstown",
  },
  {
    postal_code: "38725",
    full_city_name: "Benoit, MS",
    city_name: "Benoit",
  },
  {
    postal_code: "31532",
    full_city_name: "Denton, GA",
    city_name: "Denton",
  },
  {
    postal_code: "98232",
    full_city_name: "Bow, WA",
    city_name: "Bow",
  },
  {
    postal_code: "93603",
    full_city_name: "Badger, CA",
    city_name: "Badger",
  },
  {
    postal_code: "36030",
    full_city_name: "Forest Home, AL",
    city_name: "Forest Home",
  },
  {
    postal_code: "75421",
    full_city_name: "Brookston, TX",
    city_name: "Brookston",
  },
  {
    postal_code: "71635",
    full_city_name: "Crossett, AR",
    city_name: "Crossett",
  },
  {
    postal_code: "52144",
    full_city_name: "Fort Atkinson, IA",
    city_name: "Fort Atkinson",
  },
  {
    postal_code: "31637",
    full_city_name: "Lenox, GA",
    city_name: "Lenox",
  },
  {
    postal_code: "73718",
    full_city_name: "Ames, OK",
    city_name: "Ames",
  },
  {
    postal_code: "17251",
    full_city_name: "Roxbury, PA",
    city_name: "Roxbury",
  },
  {
    postal_code: "25503",
    full_city_name: "Ashton, WV",
    city_name: "Ashton",
  },
  {
    postal_code: "58853",
    full_city_name: "Trenton, ND",
    city_name: "Trenton",
  },
  {
    postal_code: "32423",
    full_city_name: "Bascom, FL",
    city_name: "Bascom",
  },
  {
    postal_code: "82310",
    full_city_name: "Jeffrey City, WY",
    city_name: "Jeffrey City",
  },
  {
    postal_code: "83348",
    full_city_name: "Picabo, ID",
    city_name: "Picabo",
  },
  {
    postal_code: "21714",
    full_city_name: "Braddock Heights, MD",
    city_name: "Braddock Heights",
  },
  {
    postal_code: "68367",
    full_city_name: "Gresham, NE",
    city_name: "Gresham",
  },
  {
    postal_code: "57063",
    full_city_name: "Tabor, SD",
    city_name: "Tabor",
  },
  {
    postal_code: "47264",
    full_city_name: "Norman, IN",
    city_name: "Norman",
  },
  {
    postal_code: "29101",
    full_city_name: "Mc Bee, SC",
    city_name: "Mc Bee",
  },
  {
    postal_code: "52630",
    full_city_name: "Hillsboro, IA",
    city_name: "Hillsboro",
  },
  {
    postal_code: "71752",
    full_city_name: "Mc Neil, AR",
    city_name: "Mc Neil",
  },
  {
    postal_code: "41655",
    full_city_name: "Printer, KY",
    city_name: "Printer",
  },
  {
    postal_code: "46725",
    full_city_name: "Columbia City, IN",
    city_name: "Columbia City",
  },
  {
    postal_code: "81228",
    full_city_name: "Granite, CO",
    city_name: "Granite",
  },
  {
    postal_code: "65541",
    full_city_name: "Lenox, MO",
    city_name: "Lenox",
  },
  {
    postal_code: "69169",
    full_city_name: "Wallace, NE",
    city_name: "Wallace",
  },
  {
    postal_code: "15472",
    full_city_name: "Oliver, PA",
    city_name: "Oliver",
  },
  {
    postal_code: "24609",
    full_city_name: "Cedar Bluff, VA",
    city_name: "Cedar Bluff",
  },
  {
    postal_code: "58576",
    full_city_name: "Underwood, ND",
    city_name: "Underwood",
  },
  {
    postal_code: "55085",
    full_city_name: "Vermillion, MN",
    city_name: "Vermillion",
  },
  {
    postal_code: "50644",
    full_city_name: "Independence, IA",
    city_name: "Independence",
  },
  {
    postal_code: "23702",
    full_city_name: "Portsmouth, VA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "59414",
    full_city_name: "Black Eagle, MT",
    city_name: "Black Eagle",
  },
  {
    postal_code: "40442",
    full_city_name: "Kings Mountain, KY",
    city_name: "Kings Mountain",
  },
  {
    postal_code: "14744",
    full_city_name: "Houghton, NY",
    city_name: "Houghton",
  },
  {
    postal_code: "51639",
    full_city_name: "Farragut, IA",
    city_name: "Farragut",
  },
  {
    postal_code: "07648",
    full_city_name: "Norwood, NJ",
    city_name: "Norwood",
  },
  {
    postal_code: "51533",
    full_city_name: "Emerson, IA",
    city_name: "Emerson",
  },
  {
    postal_code: "03768",
    full_city_name: "Lyme, NH",
    city_name: "Lyme",
  },
  {
    postal_code: "96088",
    full_city_name: "Shingletown, CA",
    city_name: "Shingletown",
  },
  {
    postal_code: "65443",
    full_city_name: "Brinktown, MO",
    city_name: "Brinktown",
  },
  {
    postal_code: "19710",
    full_city_name: "Montchanin, DE",
    city_name: "Montchanin",
  },
  {
    postal_code: "29819",
    full_city_name: "Bradley, SC",
    city_name: "Bradley",
  },
  {
    postal_code: "67351",
    full_city_name: "Liberty, KS",
    city_name: "Liberty",
  },
  {
    postal_code: "04495",
    full_city_name: "Winn, ME",
    city_name: "Winn",
  },
  {
    postal_code: "57220",
    full_city_name: "Bruce, SD",
    city_name: "Bruce",
  },
  {
    postal_code: "45766",
    full_city_name: "New Marshfield, OH",
    city_name: "New Marshfield",
  },
  {
    postal_code: "56652",
    full_city_name: "Leonard, MN",
    city_name: "Leonard",
  },
  {
    postal_code: "67349",
    full_city_name: "Howard, KS",
    city_name: "Howard",
  },
  {
    postal_code: "83218",
    full_city_name: "Basalt, ID",
    city_name: "Basalt",
  },
  {
    postal_code: "16347",
    full_city_name: "Sheffield, PA",
    city_name: "Sheffield",
  },
  {
    postal_code: "89004",
    full_city_name: "Blue Diamond, NV",
    city_name: "Blue Diamond",
  },
  {
    postal_code: "58730",
    full_city_name: "Crosby, ND",
    city_name: "Crosby",
  },
  {
    postal_code: "52555",
    full_city_name: "Exline, IA",
    city_name: "Exline",
  },
  {
    postal_code: "66952",
    full_city_name: "Lebanon, KS",
    city_name: "Lebanon",
  },
  {
    postal_code: "62076",
    full_city_name: "Ohlman, IL",
    city_name: "Ohlman",
  },
  {
    postal_code: "82336",
    full_city_name: "Wamsutter, WY",
    city_name: "Wamsutter",
  },
  {
    postal_code: "71969",
    full_city_name: "Sims, AR",
    city_name: "Sims",
  },
  {
    postal_code: "12086",
    full_city_name: "Hagaman, NY",
    city_name: "Hagaman",
  },
  {
    postal_code: "38337",
    full_city_name: "Gadsden, TN",
    city_name: "Gadsden",
  },
  {
    postal_code: "63463",
    full_city_name: "Philadelphia, MO",
    city_name: "Philadelphia",
  },
  {
    postal_code: "74759",
    full_city_name: "Soper, OK",
    city_name: "Soper",
  },
  {
    postal_code: "71949",
    full_city_name: "Jessieville, AR",
    city_name: "Jessieville",
  },
  {
    postal_code: "16935",
    full_city_name: "Middlebury Center, PA",
    city_name: "Middlebury Center",
  },
  {
    postal_code: "65069",
    full_city_name: "Rhineland, MO",
    city_name: "Rhineland",
  },
  {
    postal_code: "66538",
    full_city_name: "Seneca, KS",
    city_name: "Seneca",
  },
  {
    postal_code: "35972",
    full_city_name: "Gallant, AL",
    city_name: "Gallant",
  },
  {
    postal_code: "41775",
    full_city_name: "Wendover, KY",
    city_name: "Wendover",
  },
  {
    postal_code: "01092",
    full_city_name: "West Warren, MA",
    city_name: "West Warren",
  },
  {
    postal_code: "49235",
    full_city_name: "Clayton, MI",
    city_name: "Clayton",
  },
  {
    postal_code: "37049",
    full_city_name: "Cross Plains, TN",
    city_name: "Cross Plains",
  },
  {
    postal_code: "82649",
    full_city_name: "Shoshoni, WY",
    city_name: "Shoshoni",
  },
  {
    postal_code: "71658",
    full_city_name: "Montrose, AR",
    city_name: "Montrose",
  },
  {
    postal_code: "66449",
    full_city_name: "Leonardville, KS",
    city_name: "Leonardville",
  },
  {
    postal_code: "80930",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "82933",
    full_city_name: "Fort Bridger, WY",
    city_name: "Fort Bridger",
  },
  {
    postal_code: "87046",
    full_city_name: "Regina, NM",
    city_name: "Regina",
  },
  {
    postal_code: "66425",
    full_city_name: "Fairview, KS",
    city_name: "Fairview",
  },
  {
    postal_code: "03466",
    full_city_name: "West Chesterfield, NH",
    city_name: "West Chesterfield",
  },
  {
    postal_code: "65609",
    full_city_name: "Bakersfield, MO",
    city_name: "Bakersfield",
  },
  {
    postal_code: "61258",
    full_city_name: "Hooppole, IL",
    city_name: "Hooppole",
  },
  {
    postal_code: "49419",
    full_city_name: "Hamilton, MI",
    city_name: "Hamilton",
  },
  {
    postal_code: "50050",
    full_city_name: "Churdan, IA",
    city_name: "Churdan",
  },
  {
    postal_code: "95943",
    full_city_name: "Glenn, CA",
    city_name: "Glenn",
  },
  {
    postal_code: "37186",
    full_city_name: "Westmoreland, TN",
    city_name: "Westmoreland",
  },
  {
    postal_code: "66020",
    full_city_name: "Easton, KS",
    city_name: "Easton",
  },
  {
    postal_code: "43914",
    full_city_name: "Cameron, OH",
    city_name: "Cameron",
  },
  {
    postal_code: "51030",
    full_city_name: "Lawton, IA",
    city_name: "Lawton",
  },
  {
    postal_code: "07620",
    full_city_name: "Alpine, NJ",
    city_name: "Alpine",
  },
  {
    postal_code: "98340",
    full_city_name: "Hansville, WA",
    city_name: "Hansville",
  },
  {
    postal_code: "16656",
    full_city_name: "Irvona, PA",
    city_name: "Irvona",
  },
  {
    postal_code: "95978",
    full_city_name: "Stirling City, CA",
    city_name: "Stirling City",
  },
  {
    postal_code: "24476",
    full_city_name: "Steeles Tavern, VA",
    city_name: "Steeles Tavern",
  },
  {
    postal_code: "70780",
    full_city_name: "Sunshine, LA",
    city_name: "Sunshine",
  },
  {
    postal_code: "16937",
    full_city_name: "Mills, PA",
    city_name: "Mills",
  },
  {
    postal_code: "59275",
    full_city_name: "Westby, MT",
    city_name: "Westby",
  },
  {
    postal_code: "39328",
    full_city_name: "De Kalb, MS",
    city_name: "De Kalb",
  },
  {
    postal_code: "26804",
    full_city_name: "Circleville, WV",
    city_name: "Circleville",
  },
  {
    postal_code: "26369",
    full_city_name: "Hepzibah, WV",
    city_name: "Hepzibah",
  },
  {
    postal_code: "68450",
    full_city_name: "Tecumseh, NE",
    city_name: "Tecumseh",
  },
  {
    postal_code: "87828",
    full_city_name: "Polvadera, NM",
    city_name: "Polvadera",
  },
  {
    postal_code: "83440",
    full_city_name: "Rexburg, ID",
    city_name: "Rexburg",
  },
  {
    postal_code: "47432",
    full_city_name: "French Lick, IN",
    city_name: "French Lick",
  },
  {
    postal_code: "53093",
    full_city_name: "Waldo, WI",
    city_name: "Waldo",
  },
  {
    postal_code: "31512",
    full_city_name: "Ambrose, GA",
    city_name: "Ambrose",
  },
  {
    postal_code: "54135",
    full_city_name: "Keshena, WI",
    city_name: "Keshena",
  },
  {
    postal_code: "06065",
    full_city_name: "Riverton, CT",
    city_name: "Riverton",
  },
  {
    postal_code: "53076",
    full_city_name: "Richfield, WI",
    city_name: "Richfield",
  },
  {
    postal_code: "36529",
    full_city_name: "Deer Park, AL",
    city_name: "Deer Park",
  },
  {
    postal_code: "17840",
    full_city_name: "Locust Gap, PA",
    city_name: "Locust Gap",
  },
  {
    postal_code: "06336",
    full_city_name: "Gilman, CT",
    city_name: "Gilman",
  },
  {
    postal_code: "76837",
    full_city_name: "Eden, TX",
    city_name: "Eden",
  },
  {
    postal_code: "53059",
    full_city_name: "Neosho, WI",
    city_name: "Neosho",
  },
  {
    postal_code: "68322",
    full_city_name: "Bruning, NE",
    city_name: "Bruning",
  },
  {
    postal_code: "07002",
    full_city_name: "Bayonne, NJ",
    city_name: "Bayonne",
  },
  {
    postal_code: "61435",
    full_city_name: "Gerlaw, IL",
    city_name: "Gerlaw",
  },
  {
    postal_code: "98421",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "65774",
    full_city_name: "Weaubleau, MO",
    city_name: "Weaubleau",
  },
  {
    postal_code: "58011",
    full_city_name: "Buffalo, ND",
    city_name: "Buffalo",
  },
  {
    postal_code: "50473",
    full_city_name: "Scarville, IA",
    city_name: "Scarville",
  },
  {
    postal_code: "30234",
    full_city_name: "Jenkinsburg, GA",
    city_name: "Jenkinsburg",
  },
  {
    postal_code: "15006",
    full_city_name: "Bairdford, PA",
    city_name: "Bairdford",
  },
  {
    postal_code: "26818",
    full_city_name: "Fisher, WV",
    city_name: "Fisher",
  },
  {
    postal_code: "61091",
    full_city_name: "Woosung, IL",
    city_name: "Woosung",
  },
  {
    postal_code: "95385",
    full_city_name: "Vernalis, CA",
    city_name: "Vernalis",
  },
  {
    postal_code: "66546",
    full_city_name: "Wakarusa, KS",
    city_name: "Wakarusa",
  },
  {
    postal_code: "78050",
    full_city_name: "Leming, TX",
    city_name: "Leming",
  },
  {
    postal_code: "31527",
    full_city_name: "Jekyll Island, GA",
    city_name: "Jekyll Island",
  },
  {
    postal_code: "67053",
    full_city_name: "Goessel, KS",
    city_name: "Goessel",
  },
  {
    postal_code: "10528",
    full_city_name: "Harrison, NY",
    city_name: "Harrison",
  },
  {
    postal_code: "71753",
    full_city_name: "Magnolia, AR",
    city_name: "Magnolia",
  },
  {
    postal_code: "68629",
    full_city_name: "Clarkson, NE",
    city_name: "Clarkson",
  },
  {
    postal_code: "35578",
    full_city_name: "Nauvoo, AL",
    city_name: "Nauvoo",
  },
  {
    postal_code: "84324",
    full_city_name: "Mantua, UT",
    city_name: "Mantua",
  },
  {
    postal_code: "08829",
    full_city_name: "High Bridge, NJ",
    city_name: "High Bridge",
  },
  {
    postal_code: "07750",
    full_city_name: "Monmouth Beach, NJ",
    city_name: "Monmouth Beach",
  },
  {
    postal_code: "71411",
    full_city_name: "Campti, LA",
    city_name: "Campti",
  },
  {
    postal_code: "07444",
    full_city_name: "Pompton Plains, NJ",
    city_name: "Pompton Plains",
  },
  {
    postal_code: "43210",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "82432",
    full_city_name: "Manderson, WY",
    city_name: "Manderson",
  },
  {
    postal_code: "35441",
    full_city_name: "Akron, AL",
    city_name: "Akron",
  },
  {
    postal_code: "14735",
    full_city_name: "Fillmore, NY",
    city_name: "Fillmore",
  },
  {
    postal_code: "66753",
    full_city_name: "Mc Cune, KS",
    city_name: "Mc Cune",
  },
  {
    postal_code: "04943",
    full_city_name: "Hartland, ME",
    city_name: "Hartland",
  },
  {
    postal_code: "52621",
    full_city_name: "Crawfordsville, IA",
    city_name: "Crawfordsville",
  },
  {
    postal_code: "57225",
    full_city_name: "Clark, SD",
    city_name: "Clark",
  },
  {
    postal_code: "52134",
    full_city_name: "Chester, IA",
    city_name: "Chester",
  },
  {
    postal_code: "04646",
    full_city_name: "Islesford, ME",
    city_name: "Islesford",
  },
  {
    postal_code: "54559",
    full_city_name: "Saxon, WI",
    city_name: "Saxon",
  },
  {
    postal_code: "67119",
    full_city_name: "Oxford, KS",
    city_name: "Oxford",
  },
  {
    postal_code: "15352",
    full_city_name: "New Freeport, PA",
    city_name: "New Freeport",
  },
  {
    postal_code: "36451",
    full_city_name: "Grove Hill, AL",
    city_name: "Grove Hill",
  },
  {
    postal_code: "26753",
    full_city_name: "Ridgeley, WV",
    city_name: "Ridgeley",
  },
  {
    postal_code: "28369",
    full_city_name: "Orrum, NC",
    city_name: "Orrum",
  },
  {
    postal_code: "52347",
    full_city_name: "Victor, IA",
    city_name: "Victor",
  },
  {
    postal_code: "23433",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "50454",
    full_city_name: "Little Cedar, IA",
    city_name: "Little Cedar",
  },
  {
    postal_code: "50670",
    full_city_name: "Shell Rock, IA",
    city_name: "Shell Rock",
  },
  {
    postal_code: "44689",
    full_city_name: "Wilmot, OH",
    city_name: "Wilmot",
  },
  {
    postal_code: "82083",
    full_city_name: "Rock River, WY",
    city_name: "Rock River",
  },
  {
    postal_code: "72438",
    full_city_name: "Leachville, AR",
    city_name: "Leachville",
  },
  {
    postal_code: "41636",
    full_city_name: "Hi Hat, KY",
    city_name: "Hi Hat",
  },
  {
    postal_code: "25557",
    full_city_name: "Ranger, WV",
    city_name: "Ranger",
  },
  {
    postal_code: "29168",
    full_city_name: "Wedgefield, SC",
    city_name: "Wedgefield",
  },
  {
    postal_code: "79257",
    full_city_name: "Silverton, TX",
    city_name: "Silverton",
  },
  {
    postal_code: "29743",
    full_city_name: "Smyrna, SC",
    city_name: "Smyrna",
  },
  {
    postal_code: "47598",
    full_city_name: "Winslow, IN",
    city_name: "Winslow",
  },
  {
    postal_code: "50483",
    full_city_name: "Wesley, IA",
    city_name: "Wesley",
  },
  {
    postal_code: "36574",
    full_city_name: "Seminole, AL",
    city_name: "Seminole",
  },
  {
    postal_code: "89316",
    full_city_name: "Eureka, NV",
    city_name: "Eureka",
  },
  {
    postal_code: "57319",
    full_city_name: "Bridgewater, SD",
    city_name: "Bridgewater",
  },
  {
    postal_code: "26705",
    full_city_name: "Aurora, WV",
    city_name: "Aurora",
  },
  {
    postal_code: "31079",
    full_city_name: "Rochelle, GA",
    city_name: "Rochelle",
  },
  {
    postal_code: "48120",
    full_city_name: "Dearborn, MI",
    city_name: "Dearborn",
  },
  {
    postal_code: "14555",
    full_city_name: "Sodus Point, NY",
    city_name: "Sodus Point",
  },
  {
    postal_code: "59487",
    full_city_name: "Vaughn, MT",
    city_name: "Vaughn",
  },
  {
    postal_code: "07082",
    full_city_name: "Towaco, NJ",
    city_name: "Towaco",
  },
  {
    postal_code: "54173",
    full_city_name: "Suamico, WI",
    city_name: "Suamico",
  },
  {
    postal_code: "07058",
    full_city_name: "Pine Brook, NJ",
    city_name: "Pine Brook",
  },
  {
    postal_code: "15754",
    full_city_name: "Lucernemines, PA",
    city_name: "Lucernemines",
  },
  {
    postal_code: "25565",
    full_city_name: "Spurlockville, WV",
    city_name: "Spurlockville",
  },
  {
    postal_code: "16877",
    full_city_name: "Warriors Mark, PA",
    city_name: "Warriors Mark",
  },
  {
    postal_code: "26807",
    full_city_name: "Franklin, WV",
    city_name: "Franklin",
  },
  {
    postal_code: "68733",
    full_city_name: "Emerson, NE",
    city_name: "Emerson",
  },
  {
    postal_code: "27379",
    full_city_name: "Yanceyville, NC",
    city_name: "Yanceyville",
  },
  {
    postal_code: "73446",
    full_city_name: "Madill, OK",
    city_name: "Madill",
  },
  {
    postal_code: "73449",
    full_city_name: "Mead, OK",
    city_name: "Mead",
  },
  {
    postal_code: "95562",
    full_city_name: "Rio Dell, CA",
    city_name: "Rio Dell",
  },
  {
    postal_code: "81141",
    full_city_name: "Manassa, CO",
    city_name: "Manassa",
  },
  {
    postal_code: "30730",
    full_city_name: "Lyerly, GA",
    city_name: "Lyerly",
  },
  {
    postal_code: "81136",
    full_city_name: "Hooper, CO",
    city_name: "Hooper",
  },
  {
    postal_code: "61857",
    full_city_name: "Muncie, IL",
    city_name: "Muncie",
  },
  {
    postal_code: "18053",
    full_city_name: "Germansville, PA",
    city_name: "Germansville",
  },
  {
    postal_code: "98249",
    full_city_name: "Freeland, WA",
    city_name: "Freeland",
  },
  {
    postal_code: "23843",
    full_city_name: "Dolphin, VA",
    city_name: "Dolphin",
  },
  {
    postal_code: "98830",
    full_city_name: "Mansfield, WA",
    city_name: "Mansfield",
  },
  {
    postal_code: "54204",
    full_city_name: "Brussels, WI",
    city_name: "Brussels",
  },
  {
    postal_code: "78959",
    full_city_name: "Waelder, TX",
    city_name: "Waelder",
  },
  {
    postal_code: "16327",
    full_city_name: "Guys Mills, PA",
    city_name: "Guys Mills",
  },
  {
    postal_code: "44638",
    full_city_name: "Lakeville, OH",
    city_name: "Lakeville",
  },
  {
    postal_code: "68950",
    full_city_name: "Holstein, NE",
    city_name: "Holstein",
  },
  {
    postal_code: "57261",
    full_city_name: "Roslyn, SD",
    city_name: "Roslyn",
  },
  {
    postal_code: "62558",
    full_city_name: "Pawnee, IL",
    city_name: "Pawnee",
  },
  {
    postal_code: "29180",
    full_city_name: "Winnsboro, SC",
    city_name: "Winnsboro",
  },
  {
    postal_code: "30293",
    full_city_name: "Woodbury, GA",
    city_name: "Woodbury",
  },
  {
    postal_code: "51063",
    full_city_name: "Whiting, IA",
    city_name: "Whiting",
  },
  {
    postal_code: "53209",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "67518",
    full_city_name: "Beeler, KS",
    city_name: "Beeler",
  },
  {
    postal_code: "73735",
    full_city_name: "Drummond, OK",
    city_name: "Drummond",
  },
  {
    postal_code: "36038",
    full_city_name: "Gantt, AL",
    city_name: "Gantt",
  },
  {
    postal_code: "67024",
    full_city_name: "Cedar Vale, KS",
    city_name: "Cedar Vale",
  },
  {
    postal_code: "72047",
    full_city_name: "Enola, AR",
    city_name: "Enola",
  },
  {
    postal_code: "42157",
    full_city_name: "Mount Hermon, KY",
    city_name: "Mount Hermon",
  },
  {
    postal_code: "38321",
    full_city_name: "Cedar Grove, TN",
    city_name: "Cedar Grove",
  },
  {
    postal_code: "64167",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "03273",
    full_city_name: "South Sutton, NH",
    city_name: "South Sutton",
  },
  {
    postal_code: "40209",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "35072",
    full_city_name: "Goodwater, AL",
    city_name: "Goodwater",
  },
  {
    postal_code: "51104",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "28581",
    full_city_name: "Stacy, NC",
    city_name: "Stacy",
  },
  {
    postal_code: "72840",
    full_city_name: "Hartman, AR",
    city_name: "Hartman",
  },
  {
    postal_code: "71677",
    full_city_name: "Winchester, AR",
    city_name: "Winchester",
  },
  {
    postal_code: "50252",
    full_city_name: "Swan, IA",
    city_name: "Swan",
  },
  {
    postal_code: "37213",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "52644",
    full_city_name: "Mount Union, IA",
    city_name: "Mount Union",
  },
  {
    postal_code: "59465",
    full_city_name: "Neihart, MT",
    city_name: "Neihart",
  },
  {
    postal_code: "41731",
    full_city_name: "Cornettsville, KY",
    city_name: "Cornettsville",
  },
  {
    postal_code: "98260",
    full_city_name: "Langley, WA",
    city_name: "Langley",
  },
  {
    postal_code: "53003",
    full_city_name: "Ashippun, WI",
    city_name: "Ashippun",
  },
  {
    postal_code: "83427",
    full_city_name: "Iona, ID",
    city_name: "Iona",
  },
  {
    postal_code: "99663",
    full_city_name: "Seldovia, AK",
    city_name: "Seldovia",
  },
  {
    postal_code: "06332",
    full_city_name: "Central Village, CT",
    city_name: "Central Village",
  },
  {
    postal_code: "70615",
    full_city_name: "Lake Charles, LA",
    city_name: "Lake Charles",
  },
  {
    postal_code: "36048",
    full_city_name: "Louisville, AL",
    city_name: "Louisville",
  },
  {
    postal_code: "32535",
    full_city_name: "Century, FL",
    city_name: "Century",
  },
  {
    postal_code: "72585",
    full_city_name: "Wideman, AR",
    city_name: "Wideman",
  },
  {
    postal_code: "76951",
    full_city_name: "Sterling City, TX",
    city_name: "Sterling City",
  },
  {
    postal_code: "89825",
    full_city_name: "Jackpot, NV",
    city_name: "Jackpot",
  },
  {
    postal_code: "17889",
    full_city_name: "Winfield, PA",
    city_name: "Winfield",
  },
  {
    postal_code: "25102",
    full_city_name: "Handley, WV",
    city_name: "Handley",
  },
  {
    postal_code: "30816",
    full_city_name: "Keysville, GA",
    city_name: "Keysville",
  },
  {
    postal_code: "41619",
    full_city_name: "Drift, KY",
    city_name: "Drift",
  },
  {
    postal_code: "49748",
    full_city_name: "Hulbert, MI",
    city_name: "Hulbert",
  },
  {
    postal_code: "72550",
    full_city_name: "Locust Grove, AR",
    city_name: "Locust Grove",
  },
  {
    postal_code: "72611",
    full_city_name: "Alpena, AR",
    city_name: "Alpena",
  },
  {
    postal_code: "62319",
    full_city_name: "Camden, IL",
    city_name: "Camden",
  },
  {
    postal_code: "51450",
    full_city_name: "Lake View, IA",
    city_name: "Lake View",
  },
  {
    postal_code: "36855",
    full_city_name: "Five Points, AL",
    city_name: "Five Points",
  },
  {
    postal_code: "71942",
    full_city_name: "Friendship, AR",
    city_name: "Friendship",
  },
  {
    postal_code: "57426",
    full_city_name: "Barnard, SD",
    city_name: "Barnard",
  },
  {
    postal_code: "58079",
    full_city_name: "Wheatland, ND",
    city_name: "Wheatland",
  },
  {
    postal_code: "10510",
    full_city_name: "Briarcliff Manor, NY",
    city_name: "Briarcliff Manor",
  },
  {
    postal_code: "73734",
    full_city_name: "Dover, OK",
    city_name: "Dover",
  },
  {
    postal_code: "66093",
    full_city_name: "Westphalia, KS",
    city_name: "Westphalia",
  },
  {
    postal_code: "71353",
    full_city_name: "Melville, LA",
    city_name: "Melville",
  },
  {
    postal_code: "07046",
    full_city_name: "Mountain Lakes, NJ",
    city_name: "Mountain Lakes",
  },
  {
    postal_code: "17953",
    full_city_name: "Middleport, PA",
    city_name: "Middleport",
  },
  {
    postal_code: "14139",
    full_city_name: "South Wales, NY",
    city_name: "South Wales",
  },
  {
    postal_code: "67009",
    full_city_name: "Attica, KS",
    city_name: "Attica",
  },
  {
    postal_code: "87044",
    full_city_name: "Ponderosa, NM",
    city_name: "Ponderosa",
  },
  {
    postal_code: "49415",
    full_city_name: "Fruitport, MI",
    city_name: "Fruitport",
  },
  {
    postal_code: "53089",
    full_city_name: "Sussex, WI",
    city_name: "Sussex",
  },
  {
    postal_code: "15432",
    full_city_name: "Dunlevy, PA",
    city_name: "Dunlevy",
  },
  {
    postal_code: "38481",
    full_city_name: "Saint Joseph, TN",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "68662",
    full_city_name: "Shelby, NE",
    city_name: "Shelby",
  },
  {
    postal_code: "66033",
    full_city_name: "Greeley, KS",
    city_name: "Greeley",
  },
  {
    postal_code: "50632",
    full_city_name: "Garwin, IA",
    city_name: "Garwin",
  },
  {
    postal_code: "08869",
    full_city_name: "Raritan, NJ",
    city_name: "Raritan",
  },
  {
    postal_code: "66777",
    full_city_name: "Toronto, KS",
    city_name: "Toronto",
  },
  {
    postal_code: "97848",
    full_city_name: "Kimberly, OR",
    city_name: "Kimberly",
  },
  {
    postal_code: "26378",
    full_city_name: "Jane Lew, WV",
    city_name: "Jane Lew",
  },
  {
    postal_code: "82642",
    full_city_name: "Lysite, WY",
    city_name: "Lysite",
  },
  {
    postal_code: "40824",
    full_city_name: "Dayhoit, KY",
    city_name: "Dayhoit",
  },
  {
    postal_code: "36545",
    full_city_name: "Jackson, AL",
    city_name: "Jackson",
  },
  {
    postal_code: "43144",
    full_city_name: "Murray City, OH",
    city_name: "Murray City",
  },
  {
    postal_code: "43359",
    full_city_name: "Wharton, OH",
    city_name: "Wharton",
  },
  {
    postal_code: "30456",
    full_city_name: "Sardis, GA",
    city_name: "Sardis",
  },
  {
    postal_code: "75784",
    full_city_name: "Reklaw, TX",
    city_name: "Reklaw",
  },
  {
    postal_code: "61862",
    full_city_name: "Penfield, IL",
    city_name: "Penfield",
  },
  {
    postal_code: "93669",
    full_city_name: "Wishon, CA",
    city_name: "Wishon",
  },
  {
    postal_code: "18352",
    full_city_name: "Reeders, PA",
    city_name: "Reeders",
  },
  {
    postal_code: "24737",
    full_city_name: "Montcalm, WV",
    city_name: "Montcalm",
  },
  {
    postal_code: "06262",
    full_city_name: "Quinebaug, CT",
    city_name: "Quinebaug",
  },
  {
    postal_code: "68417",
    full_city_name: "Otoe, NE",
    city_name: "Otoe",
  },
  {
    postal_code: "52035",
    full_city_name: "Colesburg, IA",
    city_name: "Colesburg",
  },
  {
    postal_code: "85938",
    full_city_name: "Springerville, AZ",
    city_name: "Springerville",
  },
  {
    postal_code: "38482",
    full_city_name: "Santa Fe, TN",
    city_name: "Santa Fe",
  },
  {
    postal_code: "84752",
    full_city_name: "Minersville, UT",
    city_name: "Minersville",
  },
  {
    postal_code: "70783",
    full_city_name: "Ventress, LA",
    city_name: "Ventress",
  },
  {
    postal_code: "80469",
    full_city_name: "Phippsburg, CO",
    city_name: "Phippsburg",
  },
  {
    postal_code: "86436",
    full_city_name: "Topock, AZ",
    city_name: "Topock",
  },
  {
    postal_code: "04777",
    full_city_name: "Stacyville, ME",
    city_name: "Stacyville",
  },
  {
    postal_code: "95303",
    full_city_name: "Ballico, CA",
    city_name: "Ballico",
  },
  {
    postal_code: "36558",
    full_city_name: "Millry, AL",
    city_name: "Millry",
  },
  {
    postal_code: "96777",
    full_city_name: "Pahala, HI",
    city_name: "Pahala",
  },
  {
    postal_code: "67870",
    full_city_name: "Satanta, KS",
    city_name: "Satanta",
  },
  {
    postal_code: "24375",
    full_city_name: "Sugar Grove, VA",
    city_name: "Sugar Grove",
  },
  {
    postal_code: "14614",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "54565",
    full_city_name: "Upson, WI",
    city_name: "Upson",
  },
  {
    postal_code: "67447",
    full_city_name: "Green, KS",
    city_name: "Green",
  },
  {
    postal_code: "12489",
    full_city_name: "Wawarsing, NY",
    city_name: "Wawarsing",
  },
  {
    postal_code: "39751",
    full_city_name: "Mantee, MS",
    city_name: "Mantee",
  },
  {
    postal_code: "28330",
    full_city_name: "Cordova, NC",
    city_name: "Cordova",
  },
  {
    postal_code: "73032",
    full_city_name: "Dougherty, OK",
    city_name: "Dougherty",
  },
  {
    postal_code: "33877",
    full_city_name: "Waverly, FL",
    city_name: "Waverly",
  },
  {
    postal_code: "56219",
    full_city_name: "Browns Valley, MN",
    city_name: "Browns Valley",
  },
  {
    postal_code: "45767",
    full_city_name: "New Matamoras, OH",
    city_name: "New Matamoras",
  },
  {
    postal_code: "71950",
    full_city_name: "Kirby, AR",
    city_name: "Kirby",
  },
  {
    postal_code: "56728",
    full_city_name: "Hallock, MN",
    city_name: "Hallock",
  },
  {
    postal_code: "58366",
    full_city_name: "Rolette, ND",
    city_name: "Rolette",
  },
  {
    postal_code: "95253",
    full_city_name: "Victor, CA",
    city_name: "Victor",
  },
  {
    postal_code: "73537",
    full_city_name: "Eldorado, OK",
    city_name: "Eldorado",
  },
  {
    postal_code: "50561",
    full_city_name: "Lytton, IA",
    city_name: "Lytton",
  },
  {
    postal_code: "71722",
    full_city_name: "Bluff City, AR",
    city_name: "Bluff City",
  },
  {
    postal_code: "41141",
    full_city_name: "Garrison, KY",
    city_name: "Garrison",
  },
  {
    postal_code: "36790",
    full_city_name: "Stanton, AL",
    city_name: "Stanton",
  },
  {
    postal_code: "81052",
    full_city_name: "Lamar, CO",
    city_name: "Lamar",
  },
  {
    postal_code: "61870",
    full_city_name: "Ridge Farm, IL",
    city_name: "Ridge Farm",
  },
  {
    postal_code: "26833",
    full_city_name: "Maysville, WV",
    city_name: "Maysville",
  },
  {
    postal_code: "41837",
    full_city_name: "Mayking, KY",
    city_name: "Mayking",
  },
  {
    postal_code: "18254",
    full_city_name: "Tresckow, PA",
    city_name: "Tresckow",
  },
  {
    postal_code: "58018",
    full_city_name: "Colfax, ND",
    city_name: "Colfax",
  },
  {
    postal_code: "98564",
    full_city_name: "Mossyrock, WA",
    city_name: "Mossyrock",
  },
  {
    postal_code: "05045",
    full_city_name: "Fairlee, VT",
    city_name: "Fairlee",
  },
  {
    postal_code: "42053",
    full_city_name: "Kevil, KY",
    city_name: "Kevil",
  },
  {
    postal_code: "26136",
    full_city_name: "Big Bend, WV",
    city_name: "Big Bend",
  },
  {
    postal_code: "63967",
    full_city_name: "Williamsville, MO",
    city_name: "Williamsville",
  },
  {
    postal_code: "71071",
    full_city_name: "Sarepta, LA",
    city_name: "Sarepta",
  },
  {
    postal_code: "45663",
    full_city_name: "West Portsmouth, OH",
    city_name: "West Portsmouth",
  },
  {
    postal_code: "74333",
    full_city_name: "Bluejacket, OK",
    city_name: "Bluejacket",
  },
  {
    postal_code: "98847",
    full_city_name: "Peshastin, WA",
    city_name: "Peshastin",
  },
  {
    postal_code: "39367",
    full_city_name: "Waynesboro, MS",
    city_name: "Waynesboro",
  },
  {
    postal_code: "61422",
    full_city_name: "Bushnell, IL",
    city_name: "Bushnell",
  },
  {
    postal_code: "50118",
    full_city_name: "Hartford, IA",
    city_name: "Hartford",
  },
  {
    postal_code: "14091",
    full_city_name: "Lawtons, NY",
    city_name: "Lawtons",
  },
  {
    postal_code: "51243",
    full_city_name: "Little Rock, IA",
    city_name: "Little Rock",
  },
  {
    postal_code: "82401",
    full_city_name: "Worland, WY",
    city_name: "Worland",
  },
  {
    postal_code: "52215",
    full_city_name: "Chelsea, IA",
    city_name: "Chelsea",
  },
  {
    postal_code: "47562",
    full_city_name: "Odon, IN",
    city_name: "Odon",
  },
  {
    postal_code: "47854",
    full_city_name: "Hillsdale, IN",
    city_name: "Hillsdale",
  },
  {
    postal_code: "62553",
    full_city_name: "Oconee, IL",
    city_name: "Oconee",
  },
  {
    postal_code: "07410",
    full_city_name: "Fair Lawn, NJ",
    city_name: "Fair Lawn",
  },
  {
    postal_code: "53928",
    full_city_name: "Doylestown, WI",
    city_name: "Doylestown",
  },
  {
    postal_code: "36544",
    full_city_name: "Irvington, AL",
    city_name: "Irvington",
  },
  {
    postal_code: "58045",
    full_city_name: "Hillsboro, ND",
    city_name: "Hillsboro",
  },
  {
    postal_code: "07070",
    full_city_name: "Rutherford, NJ",
    city_name: "Rutherford",
  },
  {
    postal_code: "04024",
    full_city_name: "East Baldwin, ME",
    city_name: "East Baldwin",
  },
  {
    postal_code: "71852",
    full_city_name: "Nashville, AR",
    city_name: "Nashville",
  },
  {
    postal_code: "58030",
    full_city_name: "Fairmount, ND",
    city_name: "Fairmount",
  },
  {
    postal_code: "14770",
    full_city_name: "Portville, NY",
    city_name: "Portville",
  },
  {
    postal_code: "56721",
    full_city_name: "East Grand Forks, MN",
    city_name: "East Grand Forks",
  },
  {
    postal_code: "48217",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "32565",
    full_city_name: "Jay, FL",
    city_name: "Jay",
  },
  {
    postal_code: "16689",
    full_city_name: "Waterfall, PA",
    city_name: "Waterfall",
  },
  {
    postal_code: "39336",
    full_city_name: "Lawrence, MS",
    city_name: "Lawrence",
  },
  {
    postal_code: "55752",
    full_city_name: "Jacobson, MN",
    city_name: "Jacobson",
  },
  {
    postal_code: "31636",
    full_city_name: "Lake Park, GA",
    city_name: "Lake Park",
  },
  {
    postal_code: "53224",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "69156",
    full_city_name: "Potter, NE",
    city_name: "Potter",
  },
  {
    postal_code: "44678",
    full_city_name: "Somerdale, OH",
    city_name: "Somerdale",
  },
  {
    postal_code: "68421",
    full_city_name: "Peru, NE",
    city_name: "Peru",
  },
  {
    postal_code: "47631",
    full_city_name: "New Harmony, IN",
    city_name: "New Harmony",
  },
  {
    postal_code: "10931",
    full_city_name: "Hillburn, NY",
    city_name: "Hillburn",
  },
  {
    postal_code: "56117",
    full_city_name: "Bigelow, MN",
    city_name: "Bigelow",
  },
  {
    postal_code: "72460",
    full_city_name: "Ravenden Springs, AR",
    city_name: "Ravenden Springs",
  },
  {
    postal_code: "83321",
    full_city_name: "Castleford, ID",
    city_name: "Castleford",
  },
  {
    postal_code: "36071",
    full_city_name: "Rutledge, AL",
    city_name: "Rutledge",
  },
  {
    postal_code: "35950",
    full_city_name: "Albertville, AL",
    city_name: "Albertville",
  },
  {
    postal_code: "59046",
    full_city_name: "Lavina, MT",
    city_name: "Lavina",
  },
  {
    postal_code: "62083",
    full_city_name: "Rosamond, IL",
    city_name: "Rosamond",
  },
  {
    postal_code: "04422",
    full_city_name: "Charleston, ME",
    city_name: "Charleston",
  },
  {
    postal_code: "80105",
    full_city_name: "Deer Trail, CO",
    city_name: "Deer Trail",
  },
  {
    postal_code: "58731",
    full_city_name: "Deering, ND",
    city_name: "Deering",
  },
  {
    postal_code: "98595",
    full_city_name: "Westport, WA",
    city_name: "Westport",
  },
  {
    postal_code: "57623",
    full_city_name: "Dupree, SD",
    city_name: "Dupree",
  },
  {
    postal_code: "31639",
    full_city_name: "Nashville, GA",
    city_name: "Nashville",
  },
  {
    postal_code: "72533",
    full_city_name: "Fifty Six, AR",
    city_name: "Fifty Six",
  },
  {
    postal_code: "14135",
    full_city_name: "Sheridan, NY",
    city_name: "Sheridan",
  },
  {
    postal_code: "44838",
    full_city_name: "Hayesville, OH",
    city_name: "Hayesville",
  },
  {
    postal_code: "14748",
    full_city_name: "Kill Buck, NY",
    city_name: "Kill Buck",
  },
  {
    postal_code: "58650",
    full_city_name: "Regent, ND",
    city_name: "Regent",
  },
  {
    postal_code: "96065",
    full_city_name: "Montgomery Creek, CA",
    city_name: "Montgomery Creek",
  },
  {
    postal_code: "41560",
    full_city_name: "Robinson Creek, KY",
    city_name: "Robinson Creek",
  },
  {
    postal_code: "64673",
    full_city_name: "Princeton, MO",
    city_name: "Princeton",
  },
  {
    postal_code: "48460",
    full_city_name: "New Lothrop, MI",
    city_name: "New Lothrop",
  },
  {
    postal_code: "39069",
    full_city_name: "Fayette, MS",
    city_name: "Fayette",
  },
  {
    postal_code: "50657",
    full_city_name: "Morrison, IA",
    city_name: "Morrison",
  },
  {
    postal_code: "47977",
    full_city_name: "Remington, IN",
    city_name: "Remington",
  },
  {
    postal_code: "39355",
    full_city_name: "Quitman, MS",
    city_name: "Quitman",
  },
  {
    postal_code: "70802",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "86437",
    full_city_name: "Valentine, AZ",
    city_name: "Valentine",
  },
  {
    postal_code: "84656",
    full_city_name: "Scipio, UT",
    city_name: "Scipio",
  },
  {
    postal_code: "39645",
    full_city_name: "Liberty, MS",
    city_name: "Liberty",
  },
  {
    postal_code: "15762",
    full_city_name: "Nicktown, PA",
    city_name: "Nicktown",
  },
  {
    postal_code: "71237",
    full_city_name: "Epps, LA",
    city_name: "Epps",
  },
  {
    postal_code: "49087",
    full_city_name: "Schoolcraft, MI",
    city_name: "Schoolcraft",
  },
  {
    postal_code: "50668",
    full_city_name: "Readlyn, IA",
    city_name: "Readlyn",
  },
  {
    postal_code: "45332",
    full_city_name: "Hollansburg, OH",
    city_name: "Hollansburg",
  },
  {
    postal_code: "17097",
    full_city_name: "Wiconisco, PA",
    city_name: "Wiconisco",
  },
  {
    postal_code: "04932",
    full_city_name: "Dixmont, ME",
    city_name: "Dixmont",
  },
  {
    postal_code: "97490",
    full_city_name: "Walton, OR",
    city_name: "Walton",
  },
  {
    postal_code: "38461",
    full_city_name: "Hampshire, TN",
    city_name: "Hampshire",
  },
  {
    postal_code: "72729",
    full_city_name: "Evansville, AR",
    city_name: "Evansville",
  },
  {
    postal_code: "65626",
    full_city_name: "Caulfield, MO",
    city_name: "Caulfield",
  },
  {
    postal_code: "62688",
    full_city_name: "Tallula, IL",
    city_name: "Tallula",
  },
  {
    postal_code: "56019",
    full_city_name: "Comfrey, MN",
    city_name: "Comfrey",
  },
  {
    postal_code: "56043",
    full_city_name: "Hayward, MN",
    city_name: "Hayward",
  },
  {
    postal_code: "75838",
    full_city_name: "Donie, TX",
    city_name: "Donie",
  },
  {
    postal_code: "65770",
    full_city_name: "Walnut Grove, MO",
    city_name: "Walnut Grove",
  },
  {
    postal_code: "22718",
    full_city_name: "Elkwood, VA",
    city_name: "Elkwood",
  },
  {
    postal_code: "98575",
    full_city_name: "Quinault, WA",
    city_name: "Quinault",
  },
  {
    postal_code: "26254",
    full_city_name: "Bowden, WV",
    city_name: "Bowden",
  },
  {
    postal_code: "45853",
    full_city_name: "Kalida, OH",
    city_name: "Kalida",
  },
  {
    postal_code: "10519",
    full_city_name: "Croton Falls, NY",
    city_name: "Croton Falls",
  },
  {
    postal_code: "41631",
    full_city_name: "Grethel, KY",
    city_name: "Grethel",
  },
  {
    postal_code: "70631",
    full_city_name: "Cameron, LA",
    city_name: "Cameron",
  },
  {
    postal_code: "38579",
    full_city_name: "Quebeck, TN",
    city_name: "Quebeck",
  },
  {
    postal_code: "66416",
    full_city_name: "Circleville, KS",
    city_name: "Circleville",
  },
  {
    postal_code: "68401",
    full_city_name: "Mc Cool Junction, NE",
    city_name: "Mc Cool Junction",
  },
  {
    postal_code: "61949",
    full_city_name: "Redmon, IL",
    city_name: "Redmon",
  },
  {
    postal_code: "15490",
    full_city_name: "White, PA",
    city_name: "White",
  },
  {
    postal_code: "57073",
    full_city_name: "Wakonda, SD",
    city_name: "Wakonda",
  },
  {
    postal_code: "42280",
    full_city_name: "Sharon Grove, KY",
    city_name: "Sharon Grove",
  },
  {
    postal_code: "22845",
    full_city_name: "Orkney Springs, VA",
    city_name: "Orkney Springs",
  },
  {
    postal_code: "59472",
    full_city_name: "Sand Coulee, MT",
    city_name: "Sand Coulee",
  },
  {
    postal_code: "98251",
    full_city_name: "Gold Bar, WA",
    city_name: "Gold Bar",
  },
  {
    postal_code: "31722",
    full_city_name: "Berlin, GA",
    city_name: "Berlin",
  },
  {
    postal_code: "52355",
    full_city_name: "Webster, IA",
    city_name: "Webster",
  },
  {
    postal_code: "78151",
    full_city_name: "Runge, TX",
    city_name: "Runge",
  },
  {
    postal_code: "26543",
    full_city_name: "Osage, WV",
    city_name: "Osage",
  },
  {
    postal_code: "49450",
    full_city_name: "Pullman, MI",
    city_name: "Pullman",
  },
  {
    postal_code: "62949",
    full_city_name: "Hurst, IL",
    city_name: "Hurst",
  },
  {
    postal_code: "16854",
    full_city_name: "Millheim, PA",
    city_name: "Millheim",
  },
  {
    postal_code: "55065",
    full_city_name: "Randolph, MN",
    city_name: "Randolph",
  },
  {
    postal_code: "98586",
    full_city_name: "South Bend, WA",
    city_name: "South Bend",
  },
  {
    postal_code: "59052",
    full_city_name: "Mc Leod, MT",
    city_name: "Mc Leod",
  },
  {
    postal_code: "23704",
    full_city_name: "Portsmouth, VA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "53225",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "01360",
    full_city_name: "Northfield, MA",
    city_name: "Northfield",
  },
  {
    postal_code: "18962",
    full_city_name: "Silverdale, PA",
    city_name: "Silverdale",
  },
  {
    postal_code: "10533",
    full_city_name: "Irvington, NY",
    city_name: "Irvington",
  },
  {
    postal_code: "58273",
    full_city_name: "Pisek, ND",
    city_name: "Pisek",
  },
  {
    postal_code: "05769",
    full_city_name: "Salisbury, VT",
    city_name: "Salisbury",
  },
  {
    postal_code: "10917",
    full_city_name: "Central Valley, NY",
    city_name: "Central Valley",
  },
  {
    postal_code: "51055",
    full_city_name: "Sloan, IA",
    city_name: "Sloan",
  },
  {
    postal_code: "16625",
    full_city_name: "Claysburg, PA",
    city_name: "Claysburg",
  },
  {
    postal_code: "79035",
    full_city_name: "Friona, TX",
    city_name: "Friona",
  },
  {
    postal_code: "48030",
    full_city_name: "Hazel Park, MI",
    city_name: "Hazel Park",
  },
  {
    postal_code: "15671",
    full_city_name: "New Derry, PA",
    city_name: "New Derry",
  },
  {
    postal_code: "38037",
    full_city_name: "Gates, TN",
    city_name: "Gates",
  },
  {
    postal_code: "48128",
    full_city_name: "Dearborn, MI",
    city_name: "Dearborn",
  },
  {
    postal_code: "48841",
    full_city_name: "Henderson, MI",
    city_name: "Henderson",
  },
  {
    postal_code: "35447",
    full_city_name: "Carrollton, AL",
    city_name: "Carrollton",
  },
  {
    postal_code: "49125",
    full_city_name: "Sawyer, MI",
    city_name: "Sawyer",
  },
  {
    postal_code: "72057",
    full_city_name: "Grapevine, AR",
    city_name: "Grapevine",
  },
  {
    postal_code: "50164",
    full_city_name: "Menlo, IA",
    city_name: "Menlo",
  },
  {
    postal_code: "45724",
    full_city_name: "Cutler, OH",
    city_name: "Cutler",
  },
  {
    postal_code: "96016",
    full_city_name: "Cassel, CA",
    city_name: "Cassel",
  },
  {
    postal_code: "45827",
    full_city_name: "Cloverdale, OH",
    city_name: "Cloverdale",
  },
  {
    postal_code: "14525",
    full_city_name: "Pavilion, NY",
    city_name: "Pavilion",
  },
  {
    postal_code: "69151",
    full_city_name: "Maxwell, NE",
    city_name: "Maxwell",
  },
  {
    postal_code: "83271",
    full_city_name: "Rockland, ID",
    city_name: "Rockland",
  },
  {
    postal_code: "26581",
    full_city_name: "Littleton, WV",
    city_name: "Littleton",
  },
  {
    postal_code: "86431",
    full_city_name: "Chloride, AZ",
    city_name: "Chloride",
  },
  {
    postal_code: "54458",
    full_city_name: "Nelsonville, WI",
    city_name: "Nelsonville",
  },
  {
    postal_code: "84334",
    full_city_name: "Riverside, UT",
    city_name: "Riverside",
  },
  {
    postal_code: "83633",
    full_city_name: "King Hill, ID",
    city_name: "King Hill",
  },
  {
    postal_code: "62268",
    full_city_name: "Oakdale, IL",
    city_name: "Oakdale",
  },
  {
    postal_code: "88414",
    full_city_name: "Capulin, NM",
    city_name: "Capulin",
  },
  {
    postal_code: "25136",
    full_city_name: "Montgomery, WV",
    city_name: "Montgomery",
  },
  {
    postal_code: "57471",
    full_city_name: "Roscoe, SD",
    city_name: "Roscoe",
  },
  {
    postal_code: "95019",
    full_city_name: "Freedom, CA",
    city_name: "Freedom",
  },
  {
    postal_code: "58420",
    full_city_name: "Buchanan, ND",
    city_name: "Buchanan",
  },
  {
    postal_code: "14539",
    full_city_name: "Retsof, NY",
    city_name: "Retsof",
  },
  {
    postal_code: "24460",
    full_city_name: "Millboro, VA",
    city_name: "Millboro",
  },
  {
    postal_code: "49304",
    full_city_name: "Baldwin, MI",
    city_name: "Baldwin",
  },
  {
    postal_code: "86441",
    full_city_name: "Dolan Springs, AZ",
    city_name: "Dolan Springs",
  },
  {
    postal_code: "50127",
    full_city_name: "Ira, IA",
    city_name: "Ira",
  },
  {
    postal_code: "71647",
    full_city_name: "Hermitage, AR",
    city_name: "Hermitage",
  },
  {
    postal_code: "12071",
    full_city_name: "Fultonham, NY",
    city_name: "Fultonham",
  },
  {
    postal_code: "98588",
    full_city_name: "Tahuya, WA",
    city_name: "Tahuya",
  },
  {
    postal_code: "61322",
    full_city_name: "Depue, IL",
    city_name: "Depue",
  },
  {
    postal_code: "82922",
    full_city_name: "Bondurant, WY",
    city_name: "Bondurant",
  },
  {
    postal_code: "46911",
    full_city_name: "Amboy, IN",
    city_name: "Amboy",
  },
  {
    postal_code: "36523",
    full_city_name: "Coden, AL",
    city_name: "Coden",
  },
  {
    postal_code: "65586",
    full_city_name: "Wesco, MO",
    city_name: "Wesco",
  },
  {
    postal_code: "49066",
    full_city_name: "Leonidas, MI",
    city_name: "Leonidas",
  },
  {
    postal_code: "71219",
    full_city_name: "Baskin, LA",
    city_name: "Baskin",
  },
  {
    postal_code: "57232",
    full_city_name: "Eden, SD",
    city_name: "Eden",
  },
  {
    postal_code: "57062",
    full_city_name: "Springfield, SD",
    city_name: "Springfield",
  },
  {
    postal_code: "95427",
    full_city_name: "Comptche, CA",
    city_name: "Comptche",
  },
  {
    postal_code: "26323",
    full_city_name: "Anmoore, WV",
    city_name: "Anmoore",
  },
  {
    postal_code: "10578",
    full_city_name: "Purdys, NY",
    city_name: "Purdys",
  },
  {
    postal_code: "52049",
    full_city_name: "Garnavillo, IA",
    city_name: "Garnavillo",
  },
  {
    postal_code: "51041",
    full_city_name: "Orange City, IA",
    city_name: "Orange City",
  },
  {
    postal_code: "26137",
    full_city_name: "Big Springs, WV",
    city_name: "Big Springs",
  },
  {
    postal_code: "62561",
    full_city_name: "Riverton, IL",
    city_name: "Riverton",
  },
  {
    postal_code: "75850",
    full_city_name: "Leona, TX",
    city_name: "Leona",
  },
  {
    postal_code: "72464",
    full_city_name: "Saint Francis, AR",
    city_name: "Saint Francis",
  },
  {
    postal_code: "62893",
    full_city_name: "Walnut Hill, IL",
    city_name: "Walnut Hill",
  },
  {
    postal_code: "42088",
    full_city_name: "Wingo, KY",
    city_name: "Wingo",
  },
  {
    postal_code: "49965",
    full_city_name: "Toivola, MI",
    city_name: "Toivola",
  },
  {
    postal_code: "68416",
    full_city_name: "Ohiowa, NE",
    city_name: "Ohiowa",
  },
  {
    postal_code: "18660",
    full_city_name: "Wapwallopen, PA",
    city_name: "Wapwallopen",
  },
  {
    postal_code: "72137",
    full_city_name: "Rose Bud, AR",
    city_name: "Rose Bud",
  },
  {
    postal_code: "04625",
    full_city_name: "Cranberry Isles, ME",
    city_name: "Cranberry Isles",
  },
  {
    postal_code: "67001",
    full_city_name: "Andale, KS",
    city_name: "Andale",
  },
  {
    postal_code: "73481",
    full_city_name: "Ratliff City, OK",
    city_name: "Ratliff City",
  },
  {
    postal_code: "47981",
    full_city_name: "Romney, IN",
    city_name: "Romney",
  },
  {
    postal_code: "75470",
    full_city_name: "Petty, TX",
    city_name: "Petty",
  },
  {
    postal_code: "98220",
    full_city_name: "Acme, WA",
    city_name: "Acme",
  },
  {
    postal_code: "31017",
    full_city_name: "Danville, GA",
    city_name: "Danville",
  },
  {
    postal_code: "10470",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "99321",
    full_city_name: "Beverly, WA",
    city_name: "Beverly",
  },
  {
    postal_code: "29368",
    full_city_name: "Mayo, SC",
    city_name: "Mayo",
  },
  {
    postal_code: "81612",
    full_city_name: "Aspen, CO",
    city_name: "Aspen",
  },
  {
    postal_code: "36509",
    full_city_name: "Bayou La Batre, AL",
    city_name: "Bayou La Batre",
  },
  {
    postal_code: "87830",
    full_city_name: "Reserve, NM",
    city_name: "Reserve",
  },
  {
    postal_code: "69122",
    full_city_name: "Big Springs, NE",
    city_name: "Big Springs",
  },
  {
    postal_code: "58789",
    full_city_name: "Upham, ND",
    city_name: "Upham",
  },
  {
    postal_code: "71075",
    full_city_name: "Springhill, LA",
    city_name: "Springhill",
  },
  {
    postal_code: "68442",
    full_city_name: "Stella, NE",
    city_name: "Stella",
  },
  {
    postal_code: "79225",
    full_city_name: "Chillicothe, TX",
    city_name: "Chillicothe",
  },
  {
    postal_code: "71971",
    full_city_name: "Umpire, AR",
    city_name: "Umpire",
  },
  {
    postal_code: "53051",
    full_city_name: "Menomonee Falls, WI",
    city_name: "Menomonee Falls",
  },
  {
    postal_code: "36742",
    full_city_name: "Gallion, AL",
    city_name: "Gallion",
  },
  {
    postal_code: "46155",
    full_city_name: "Mays, IN",
    city_name: "Mays",
  },
  {
    postal_code: "12475",
    full_city_name: "Ruby, NY",
    city_name: "Ruby",
  },
  {
    postal_code: "43323",
    full_city_name: "Harpster, OH",
    city_name: "Harpster",
  },
  {
    postal_code: "07456",
    full_city_name: "Ringwood, NJ",
    city_name: "Ringwood",
  },
  {
    postal_code: "50603",
    full_city_name: "Alta Vista, IA",
    city_name: "Alta Vista",
  },
  {
    postal_code: "46755",
    full_city_name: "Kendallville, IN",
    city_name: "Kendallville",
  },
  {
    postal_code: "16664",
    full_city_name: "New Enterprise, PA",
    city_name: "New Enterprise",
  },
  {
    postal_code: "72835",
    full_city_name: "Delaware, AR",
    city_name: "Delaware",
  },
  {
    postal_code: "24136",
    full_city_name: "Pembroke, VA",
    city_name: "Pembroke",
  },
  {
    postal_code: "63735",
    full_city_name: "Bell City, MO",
    city_name: "Bell City",
  },
  {
    postal_code: "37341",
    full_city_name: "Harrison, TN",
    city_name: "Harrison",
  },
  {
    postal_code: "24920",
    full_city_name: "Bartow, WV",
    city_name: "Bartow",
  },
  {
    postal_code: "51563",
    full_city_name: "Persia, IA",
    city_name: "Persia",
  },
  {
    postal_code: "36748",
    full_city_name: "Linden, AL",
    city_name: "Linden",
  },
  {
    postal_code: "52766",
    full_city_name: "Nichols, IA",
    city_name: "Nichols",
  },
  {
    postal_code: "40946",
    full_city_name: "Green Road, KY",
    city_name: "Green Road",
  },
  {
    postal_code: "98331",
    full_city_name: "Forks, WA",
    city_name: "Forks",
  },
  {
    postal_code: "58494",
    full_city_name: "Wing, ND",
    city_name: "Wing",
  },
  {
    postal_code: "73572",
    full_city_name: "Walters, OK",
    city_name: "Walters",
  },
  {
    postal_code: "53122",
    full_city_name: "Elm Grove, WI",
    city_name: "Elm Grove",
  },
  {
    postal_code: "65347",
    full_city_name: "Nelson, MO",
    city_name: "Nelson",
  },
  {
    postal_code: "56090",
    full_city_name: "Vernon Center, MN",
    city_name: "Vernon Center",
  },
  {
    postal_code: "48507",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "66853",
    full_city_name: "Hamilton, KS",
    city_name: "Hamilton",
  },
  {
    postal_code: "65470",
    full_city_name: "Falcon, MO",
    city_name: "Falcon",
  },
  {
    postal_code: "61320",
    full_city_name: "Dalzell, IL",
    city_name: "Dalzell",
  },
  {
    postal_code: "67211",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "52767",
    full_city_name: "Pleasant Valley, IA",
    city_name: "Pleasant Valley",
  },
  {
    postal_code: "59855",
    full_city_name: "Pablo, MT",
    city_name: "Pablo",
  },
  {
    postal_code: "14590",
    full_city_name: "Wolcott, NY",
    city_name: "Wolcott",
  },
  {
    postal_code: "40943",
    full_city_name: "Girdler, KY",
    city_name: "Girdler",
  },
  {
    postal_code: "87412",
    full_city_name: "Blanco, NM",
    city_name: "Blanco",
  },
  {
    postal_code: "07644",
    full_city_name: "Lodi, NJ",
    city_name: "Lodi",
  },
  {
    postal_code: "30216",
    full_city_name: "Flovilla, GA",
    city_name: "Flovilla",
  },
  {
    postal_code: "57051",
    full_city_name: "Oldham, SD",
    city_name: "Oldham",
  },
  {
    postal_code: "63440",
    full_city_name: "Ewing, MO",
    city_name: "Ewing",
  },
  {
    postal_code: "66756",
    full_city_name: "Mulberry, KS",
    city_name: "Mulberry",
  },
  {
    postal_code: "68643",
    full_city_name: "Leigh, NE",
    city_name: "Leigh",
  },
  {
    postal_code: "83123",
    full_city_name: "La Barge, WY",
    city_name: "La Barge",
  },
  {
    postal_code: "57038",
    full_city_name: "Jefferson, SD",
    city_name: "Jefferson",
  },
  {
    postal_code: "10530",
    full_city_name: "Hartsdale, NY",
    city_name: "Hartsdale",
  },
  {
    postal_code: "57243",
    full_city_name: "Henry, SD",
    city_name: "Henry",
  },
  {
    postal_code: "15721",
    full_city_name: "Burnside, PA",
    city_name: "Burnside",
  },
  {
    postal_code: "62085",
    full_city_name: "Sawyerville, IL",
    city_name: "Sawyerville",
  },
  {
    postal_code: "72943",
    full_city_name: "Magazine, AR",
    city_name: "Magazine",
  },
  {
    postal_code: "66864",
    full_city_name: "Neosho Rapids, KS",
    city_name: "Neosho Rapids",
  },
  {
    postal_code: "66501",
    full_city_name: "Mc Farland, KS",
    city_name: "Mc Farland",
  },
  {
    postal_code: "12746",
    full_city_name: "Huguenot, NY",
    city_name: "Huguenot",
  },
  {
    postal_code: "83262",
    full_city_name: "Pingree, ID",
    city_name: "Pingree",
  },
  {
    postal_code: "23523",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "72455",
    full_city_name: "Pocahontas, AR",
    city_name: "Pocahontas",
  },
  {
    postal_code: "68760",
    full_city_name: "Niobrara, NE",
    city_name: "Niobrara",
  },
  {
    postal_code: "94963",
    full_city_name: "San Geronimo, CA",
    city_name: "San Geronimo",
  },
  {
    postal_code: "72932",
    full_city_name: "Cedarville, AR",
    city_name: "Cedarville",
  },
  {
    postal_code: "37149",
    full_city_name: "Readyville, TN",
    city_name: "Readyville",
  },
  {
    postal_code: "16645",
    full_city_name: "Glen Hope, PA",
    city_name: "Glen Hope",
  },
  {
    postal_code: "35976",
    full_city_name: "Guntersville, AL",
    city_name: "Guntersville",
  },
  {
    postal_code: "47438",
    full_city_name: "Jasonville, IN",
    city_name: "Jasonville",
  },
  {
    postal_code: "75477",
    full_city_name: "Roxton, TX",
    city_name: "Roxton",
  },
  {
    postal_code: "72453",
    full_city_name: "Peach Orchard, AR",
    city_name: "Peach Orchard",
  },
  {
    postal_code: "25005",
    full_city_name: "Amma, WV",
    city_name: "Amma",
  },
  {
    postal_code: "78067",
    full_city_name: "San Ygnacio, TX",
    city_name: "San Ygnacio",
  },
  {
    postal_code: "35481",
    full_city_name: "Reform, AL",
    city_name: "Reform",
  },
  {
    postal_code: "68938",
    full_city_name: "Fairfield, NE",
    city_name: "Fairfield",
  },
  {
    postal_code: "50574",
    full_city_name: "Pocahontas, IA",
    city_name: "Pocahontas",
  },
  {
    postal_code: "35060",
    full_city_name: "Docena, AL",
    city_name: "Docena",
  },
  {
    postal_code: "50479",
    full_city_name: "Thornton, IA",
    city_name: "Thornton",
  },
  {
    postal_code: "71225",
    full_city_name: "Calhoun, LA",
    city_name: "Calhoun",
  },
  {
    postal_code: "99723",
    full_city_name: "Barrow, AK",
    city_name: "Barrow",
  },
  {
    postal_code: "14754",
    full_city_name: "Little Genesee, NY",
    city_name: "Little Genesee",
  },
  {
    postal_code: "62360",
    full_city_name: "Payson, IL",
    city_name: "Payson",
  },
  {
    postal_code: "15038",
    full_city_name: "Elrama, PA",
    city_name: "Elrama",
  },
  {
    postal_code: "71827",
    full_city_name: "Buckner, AR",
    city_name: "Buckner",
  },
  {
    postal_code: "79741",
    full_city_name: "Goldsmith, TX",
    city_name: "Goldsmith",
  },
  {
    postal_code: "44840",
    full_city_name: "Jeromesville, OH",
    city_name: "Jeromesville",
  },
  {
    postal_code: "06753",
    full_city_name: "Cornwall, CT",
    city_name: "Cornwall",
  },
  {
    postal_code: "97639",
    full_city_name: "Sprague River, OR",
    city_name: "Sprague River",
  },
  {
    postal_code: "67564",
    full_city_name: "Olmitz, KS",
    city_name: "Olmitz",
  },
  {
    postal_code: "38726",
    full_city_name: "Beulah, MS",
    city_name: "Beulah",
  },
  {
    postal_code: "21607",
    full_city_name: "Barclay, MD",
    city_name: "Barclay",
  },
  {
    postal_code: "51246",
    full_city_name: "Rock Rapids, IA",
    city_name: "Rock Rapids",
  },
  {
    postal_code: "37118",
    full_city_name: "Milton, TN",
    city_name: "Milton",
  },
  {
    postal_code: "52223",
    full_city_name: "Delhi, IA",
    city_name: "Delhi",
  },
  {
    postal_code: "97875",
    full_city_name: "Stanfield, OR",
    city_name: "Stanfield",
  },
  {
    postal_code: "71644",
    full_city_name: "Grady, AR",
    city_name: "Grady",
  },
  {
    postal_code: "92086",
    full_city_name: "Warner Springs, CA",
    city_name: "Warner Springs",
  },
  {
    postal_code: "47637",
    full_city_name: "Tennyson, IN",
    city_name: "Tennyson",
  },
  {
    postal_code: "23508",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "24220",
    full_city_name: "Birchleaf, VA",
    city_name: "Birchleaf",
  },
  {
    postal_code: "50666",
    full_city_name: "Plainfield, IA",
    city_name: "Plainfield",
  },
  {
    postal_code: "07646",
    full_city_name: "New Milford, NJ",
    city_name: "New Milford",
  },
  {
    postal_code: "24250",
    full_city_name: "Fort Blackmore, VA",
    city_name: "Fort Blackmore",
  },
  {
    postal_code: "13611",
    full_city_name: "Belleville, NY",
    city_name: "Belleville",
  },
  {
    postal_code: "26261",
    full_city_name: "Richwood, WV",
    city_name: "Richwood",
  },
  {
    postal_code: "69350",
    full_city_name: "Hyannis, NE",
    city_name: "Hyannis",
  },
  {
    postal_code: "26443",
    full_city_name: "Troy, WV",
    city_name: "Troy",
  },
  {
    postal_code: "62046",
    full_city_name: "Hamel, IL",
    city_name: "Hamel",
  },
  {
    postal_code: "08608",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "25938",
    full_city_name: "Victor, WV",
    city_name: "Victor",
  },
  {
    postal_code: "17210",
    full_city_name: "Amberson, PA",
    city_name: "Amberson",
  },
  {
    postal_code: "58256",
    full_city_name: "Manvel, ND",
    city_name: "Manvel",
  },
  {
    postal_code: "07307",
    full_city_name: "Jersey City, NJ",
    city_name: "Jersey City",
  },
  {
    postal_code: "84636",
    full_city_name: "Holden, UT",
    city_name: "Holden",
  },
  {
    postal_code: "54421",
    full_city_name: "Colby, WI",
    city_name: "Colby",
  },
  {
    postal_code: "46348",
    full_city_name: "La Crosse, IN",
    city_name: "La Crosse",
  },
  {
    postal_code: "76882",
    full_city_name: "Talpa, TX",
    city_name: "Talpa",
  },
  {
    postal_code: "71277",
    full_city_name: "Spearsville, LA",
    city_name: "Spearsville",
  },
  {
    postal_code: "07513",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "73562",
    full_city_name: "Randlett, OK",
    city_name: "Randlett",
  },
  {
    postal_code: "68457",
    full_city_name: "Verdon, NE",
    city_name: "Verdon",
  },
  {
    postal_code: "73042",
    full_city_name: "Gracemont, OK",
    city_name: "Gracemont",
  },
  {
    postal_code: "56221",
    full_city_name: "Chokio, MN",
    city_name: "Chokio",
  },
  {
    postal_code: "99755",
    full_city_name: "Denali National Park, AK",
    city_name: "Denali National Park",
  },
  {
    postal_code: "39861",
    full_city_name: "Jakin, GA",
    city_name: "Jakin",
  },
  {
    postal_code: "64676",
    full_city_name: "Rothville, MO",
    city_name: "Rothville",
  },
  {
    postal_code: "25164",
    full_city_name: "Procious, WV",
    city_name: "Procious",
  },
  {
    postal_code: "63433",
    full_city_name: "Ashburn, MO",
    city_name: "Ashburn",
  },
  {
    postal_code: "63933",
    full_city_name: "Campbell, MO",
    city_name: "Campbell",
  },
  {
    postal_code: "52651",
    full_city_name: "Stockport, IA",
    city_name: "Stockport",
  },
  {
    postal_code: "52227",
    full_city_name: "Ely, IA",
    city_name: "Ely",
  },
  {
    postal_code: "48705",
    full_city_name: "Barton City, MI",
    city_name: "Barton City",
  },
  {
    postal_code: "29039",
    full_city_name: "Cordova, SC",
    city_name: "Cordova",
  },
  {
    postal_code: "35654",
    full_city_name: "Russellville, AL",
    city_name: "Russellville",
  },
  {
    postal_code: "58849",
    full_city_name: "Ray, ND",
    city_name: "Ray",
  },
  {
    postal_code: "35542",
    full_city_name: "Bankston, AL",
    city_name: "Bankston",
  },
  {
    postal_code: "24072",
    full_city_name: "Check, VA",
    city_name: "Check",
  },
  {
    postal_code: "15958",
    full_city_name: "Summerhill, PA",
    city_name: "Summerhill",
  },
  {
    postal_code: "65631",
    full_city_name: "Clever, MO",
    city_name: "Clever",
  },
  {
    postal_code: "56151",
    full_city_name: "Lake Wilson, MN",
    city_name: "Lake Wilson",
  },
  {
    postal_code: "54970",
    full_city_name: "Redgranite, WI",
    city_name: "Redgranite",
  },
  {
    postal_code: "76875",
    full_city_name: "Rowena, TX",
    city_name: "Rowena",
  },
  {
    postal_code: "84742",
    full_city_name: "Kanarraville, UT",
    city_name: "Kanarraville",
  },
  {
    postal_code: "64463",
    full_city_name: "King City, MO",
    city_name: "King City",
  },
  {
    postal_code: "53204",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "47003",
    full_city_name: "West College Corner, IN",
    city_name: "West College Corner",
  },
  {
    postal_code: "37373",
    full_city_name: "Sale Creek, TN",
    city_name: "Sale Creek",
  },
  {
    postal_code: "52136",
    full_city_name: "Cresco, IA",
    city_name: "Cresco",
  },
  {
    postal_code: "40358",
    full_city_name: "Olympia, KY",
    city_name: "Olympia",
  },
  {
    postal_code: "40845",
    full_city_name: "Hulen, KY",
    city_name: "Hulen",
  },
  {
    postal_code: "79742",
    full_city_name: "Grandfalls, TX",
    city_name: "Grandfalls",
  },
  {
    postal_code: "31011",
    full_city_name: "Chauncey, GA",
    city_name: "Chauncey",
  },
  {
    postal_code: "94021",
    full_city_name: "Loma Mar, CA",
    city_name: "Loma Mar",
  },
  {
    postal_code: "68345",
    full_city_name: "Du Bois, NE",
    city_name: "Du Bois",
  },
  {
    postal_code: "37052",
    full_city_name: "Cunningham, TN",
    city_name: "Cunningham",
  },
  {
    postal_code: "70656",
    full_city_name: "Pitkin, LA",
    city_name: "Pitkin",
  },
  {
    postal_code: "45622",
    full_city_name: "Creola, OH",
    city_name: "Creola",
  },
  {
    postal_code: "68654",
    full_city_name: "Polk, NE",
    city_name: "Polk",
  },
  {
    postal_code: "35620",
    full_city_name: "Elkmont, AL",
    city_name: "Elkmont",
  },
  {
    postal_code: "57274",
    full_city_name: "Webster, SD",
    city_name: "Webster",
  },
  {
    postal_code: "54229",
    full_city_name: "New Franken, WI",
    city_name: "New Franken",
  },
  {
    postal_code: "31774",
    full_city_name: "Ocilla, GA",
    city_name: "Ocilla",
  },
  {
    postal_code: "28631",
    full_city_name: "Grassy Creek, NC",
    city_name: "Grassy Creek",
  },
  {
    postal_code: "07921",
    full_city_name: "Bedminster, NJ",
    city_name: "Bedminster",
  },
  {
    postal_code: "73056",
    full_city_name: "Marshall, OK",
    city_name: "Marshall",
  },
  {
    postal_code: "16677",
    full_city_name: "Sandy Ridge, PA",
    city_name: "Sandy Ridge",
  },
  {
    postal_code: "73564",
    full_city_name: "Roosevelt, OK",
    city_name: "Roosevelt",
  },
  {
    postal_code: "53080",
    full_city_name: "Saukville, WI",
    city_name: "Saukville",
  },
  {
    postal_code: "50655",
    full_city_name: "Maynard, IA",
    city_name: "Maynard",
  },
  {
    postal_code: "38947",
    full_city_name: "North Carrollton, MS",
    city_name: "North Carrollton",
  },
  {
    postal_code: "23488",
    full_city_name: "Withams, VA",
    city_name: "Withams",
  },
  {
    postal_code: "57437",
    full_city_name: "Eureka, SD",
    city_name: "Eureka",
  },
  {
    postal_code: "41650",
    full_city_name: "Melvin, KY",
    city_name: "Melvin",
  },
  {
    postal_code: "73453",
    full_city_name: "Overbrook, OK",
    city_name: "Overbrook",
  },
  {
    postal_code: "78548",
    full_city_name: "Grulla, TX",
    city_name: "Grulla",
  },
  {
    postal_code: "07508",
    full_city_name: "Haledon, NJ",
    city_name: "Haledon",
  },
  {
    postal_code: "23847",
    full_city_name: "Emporia, VA",
    city_name: "Emporia",
  },
  {
    postal_code: "54425",
    full_city_name: "Dorchester, WI",
    city_name: "Dorchester",
  },
  {
    postal_code: "72390",
    full_city_name: "West Helena, AR",
    city_name: "West Helena",
  },
  {
    postal_code: "51005",
    full_city_name: "Aurelia, IA",
    city_name: "Aurelia",
  },
  {
    postal_code: "62942",
    full_city_name: "Grand Tower, IL",
    city_name: "Grand Tower",
  },
  {
    postal_code: "18950",
    full_city_name: "Point Pleasant, PA",
    city_name: "Point Pleasant",
  },
  {
    postal_code: "79731",
    full_city_name: "Crane, TX",
    city_name: "Crane",
  },
  {
    postal_code: "05737",
    full_city_name: "Chittenden, VT",
    city_name: "Chittenden",
  },
  {
    postal_code: "99903",
    full_city_name: "Meyers Chuck, AK",
    city_name: "Meyers Chuck",
  },
  {
    postal_code: "99540",
    full_city_name: "Indian, AK",
    city_name: "Indian",
  },
  {
    postal_code: "39335",
    full_city_name: "Lauderdale, MS",
    city_name: "Lauderdale",
  },
  {
    postal_code: "46127",
    full_city_name: "Falmouth, IN",
    city_name: "Falmouth",
  },
  {
    postal_code: "44454",
    full_city_name: "Petersburg, OH",
    city_name: "Petersburg",
  },
  {
    postal_code: "89161",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "05041",
    full_city_name: "East Randolph, VT",
    city_name: "East Randolph",
  },
  {
    postal_code: "13834",
    full_city_name: "Portlandville, NY",
    city_name: "Portlandville",
  },
  {
    postal_code: "47638",
    full_city_name: "Wadesville, IN",
    city_name: "Wadesville",
  },
  {
    postal_code: "99901",
    full_city_name: "Ketchikan, AK",
    city_name: "Ketchikan",
  },
  {
    postal_code: "51002",
    full_city_name: "Alta, IA",
    city_name: "Alta",
  },
  {
    postal_code: "63359",
    full_city_name: "Middletown, MO",
    city_name: "Middletown",
  },
  {
    postal_code: "29570",
    full_city_name: "Mc Coll, SC",
    city_name: "Mc Coll",
  },
  {
    postal_code: "72358",
    full_city_name: "Luxora, AR",
    city_name: "Luxora",
  },
  {
    postal_code: "97026",
    full_city_name: "Gervais, OR",
    city_name: "Gervais",
  },
  {
    postal_code: "97823",
    full_city_name: "Condon, OR",
    city_name: "Condon",
  },
  {
    postal_code: "64125",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "67140",
    full_city_name: "South Haven, KS",
    city_name: "South Haven",
  },
  {
    postal_code: "58772",
    full_city_name: "Portal, ND",
    city_name: "Portal",
  },
  {
    postal_code: "48380",
    full_city_name: "Milford, MI",
    city_name: "Milford",
  },
  {
    postal_code: "61437",
    full_city_name: "Gladstone, IL",
    city_name: "Gladstone",
  },
  {
    postal_code: "29127",
    full_city_name: "Prosperity, SC",
    city_name: "Prosperity",
  },
  {
    postal_code: "16220",
    full_city_name: "Crown, PA",
    city_name: "Crown",
  },
  {
    postal_code: "52218",
    full_city_name: "Coggon, IA",
    city_name: "Coggon",
  },
  {
    postal_code: "68820",
    full_city_name: "Boelus, NE",
    city_name: "Boelus",
  },
  {
    postal_code: "50631",
    full_city_name: "Frederika, IA",
    city_name: "Frederika",
  },
  {
    postal_code: "61323",
    full_city_name: "Dover, IL",
    city_name: "Dover",
  },
  {
    postal_code: "99034",
    full_city_name: "Tumtum, WA",
    city_name: "Tumtum",
  },
  {
    postal_code: "67484",
    full_city_name: "Tescott, KS",
    city_name: "Tescott",
  },
  {
    postal_code: "26347",
    full_city_name: "Flemington, WV",
    city_name: "Flemington",
  },
  {
    postal_code: "07514",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "61340",
    full_city_name: "Mark, IL",
    city_name: "Mark",
  },
  {
    postal_code: "13680",
    full_city_name: "Rensselaer Falls, NY",
    city_name: "Rensselaer Falls",
  },
  {
    postal_code: "24931",
    full_city_name: "Crawley, WV",
    city_name: "Crawley",
  },
  {
    postal_code: "66749",
    full_city_name: "Iola, KS",
    city_name: "Iola",
  },
  {
    postal_code: "99603",
    full_city_name: "Homer, AK",
    city_name: "Homer",
  },
  {
    postal_code: "45863",
    full_city_name: "Middle Point, OH",
    city_name: "Middle Point",
  },
  {
    postal_code: "04936",
    full_city_name: "Eustis, ME",
    city_name: "Eustis",
  },
  {
    postal_code: "29032",
    full_city_name: "Cassatt, SC",
    city_name: "Cassatt",
  },
  {
    postal_code: "26184",
    full_city_name: "Waverly, WV",
    city_name: "Waverly",
  },
  {
    postal_code: "18705",
    full_city_name: "Wilkes Barre, PA",
    city_name: "Wilkes Barre",
  },
  {
    postal_code: "61751",
    full_city_name: "Lawndale, IL",
    city_name: "Lawndale",
  },
  {
    postal_code: "25024",
    full_city_name: "Bloomingrose, WV",
    city_name: "Bloomingrose",
  },
  {
    postal_code: "70730",
    full_city_name: "Ethel, LA",
    city_name: "Ethel",
  },
  {
    postal_code: "82944",
    full_city_name: "Robertson, WY",
    city_name: "Robertson",
  },
  {
    postal_code: "58620",
    full_city_name: "Amidon, ND",
    city_name: "Amidon",
  },
  {
    postal_code: "67583",
    full_city_name: "Turon, KS",
    city_name: "Turon",
  },
  {
    postal_code: "51249",
    full_city_name: "Sibley, IA",
    city_name: "Sibley",
  },
  {
    postal_code: "51440",
    full_city_name: "Dedham, IA",
    city_name: "Dedham",
  },
  {
    postal_code: "26619",
    full_city_name: "Exchange, WV",
    city_name: "Exchange",
  },
  {
    postal_code: "59856",
    full_city_name: "Paradise, MT",
    city_name: "Paradise",
  },
  {
    postal_code: "72004",
    full_city_name: "Altheimer, AR",
    city_name: "Altheimer",
  },
  {
    postal_code: "07524",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "71744",
    full_city_name: "Hampton, AR",
    city_name: "Hampton",
  },
  {
    postal_code: "58033",
    full_city_name: "Fort Ransom, ND",
    city_name: "Fort Ransom",
  },
  {
    postal_code: "76870",
    full_city_name: "Priddy, TX",
    city_name: "Priddy",
  },
  {
    postal_code: "30821",
    full_city_name: "Norwood, GA",
    city_name: "Norwood",
  },
  {
    postal_code: "94957",
    full_city_name: "Ross, CA",
    city_name: "Ross",
  },
  {
    postal_code: "56292",
    full_city_name: "Vesta, MN",
    city_name: "Vesta",
  },
  {
    postal_code: "35660",
    full_city_name: "Sheffield, AL",
    city_name: "Sheffield",
  },
  {
    postal_code: "28520",
    full_city_name: "Cedar Island, NC",
    city_name: "Cedar Island",
  },
  {
    postal_code: "16860",
    full_city_name: "Munson, PA",
    city_name: "Munson",
  },
  {
    postal_code: "29842",
    full_city_name: "Beech Island, SC",
    city_name: "Beech Island",
  },
  {
    postal_code: "62312",
    full_city_name: "Barry, IL",
    city_name: "Barry",
  },
  {
    postal_code: "12910",
    full_city_name: "Altona, NY",
    city_name: "Altona",
  },
  {
    postal_code: "47162",
    full_city_name: "New Washington, IN",
    city_name: "New Washington",
  },
  {
    postal_code: "50863",
    full_city_name: "Tingley, IA",
    city_name: "Tingley",
  },
  {
    postal_code: "63437",
    full_city_name: "Clarence, MO",
    city_name: "Clarence",
  },
  {
    postal_code: "36343",
    full_city_name: "Gordon, AL",
    city_name: "Gordon",
  },
  {
    postal_code: "22850",
    full_city_name: "Singers Glen, VA",
    city_name: "Singers Glen",
  },
  {
    postal_code: "58835",
    full_city_name: "Arnegard, ND",
    city_name: "Arnegard",
  },
  {
    postal_code: "47516",
    full_city_name: "Bruceville, IN",
    city_name: "Bruceville",
  },
  {
    postal_code: "82943",
    full_city_name: "Reliance, WY",
    city_name: "Reliance",
  },
  {
    postal_code: "07092",
    full_city_name: "Mountainside, NJ",
    city_name: "Mountainside",
  },
  {
    postal_code: "83631",
    full_city_name: "Idaho City, ID",
    city_name: "Idaho City",
  },
  {
    postal_code: "30426",
    full_city_name: "Girard, GA",
    city_name: "Girard",
  },
  {
    postal_code: "51358",
    full_city_name: "Ruthven, IA",
    city_name: "Ruthven",
  },
  {
    postal_code: "97886",
    full_city_name: "Weston, OR",
    city_name: "Weston",
  },
  {
    postal_code: "35443",
    full_city_name: "Boligee, AL",
    city_name: "Boligee",
  },
  {
    postal_code: "15460",
    full_city_name: "Martin, PA",
    city_name: "Martin",
  },
  {
    postal_code: "61933",
    full_city_name: "Kansas, IL",
    city_name: "Kansas",
  },
  {
    postal_code: "74834",
    full_city_name: "Chandler, OK",
    city_name: "Chandler",
  },
  {
    postal_code: "84712",
    full_city_name: "Antimony, UT",
    city_name: "Antimony",
  },
  {
    postal_code: "31563",
    full_city_name: "Surrency, GA",
    city_name: "Surrency",
  },
  {
    postal_code: "51357",
    full_city_name: "Royal, IA",
    city_name: "Royal",
  },
  {
    postal_code: "28698",
    full_city_name: "Zionville, NC",
    city_name: "Zionville",
  },
  {
    postal_code: "35462",
    full_city_name: "Eutaw, AL",
    city_name: "Eutaw",
  },
  {
    postal_code: "54410",
    full_city_name: "Arpin, WI",
    city_name: "Arpin",
  },
  {
    postal_code: "49752",
    full_city_name: "Kinross, MI",
    city_name: "Kinross",
  },
  {
    postal_code: "32212",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "35761",
    full_city_name: "New Market, AL",
    city_name: "New Market",
  },
  {
    postal_code: "41616",
    full_city_name: "David, KY",
    city_name: "David",
  },
  {
    postal_code: "23083",
    full_city_name: "Jetersville, VA",
    city_name: "Jetersville",
  },
  {
    postal_code: "97843",
    full_city_name: "Ione, OR",
    city_name: "Ione",
  },
  {
    postal_code: "50659",
    full_city_name: "New Hampton, IA",
    city_name: "New Hampton",
  },
  {
    postal_code: "22650",
    full_city_name: "Rileyville, VA",
    city_name: "Rileyville",
  },
  {
    postal_code: "58007",
    full_city_name: "Ayr, ND",
    city_name: "Ayr",
  },
  {
    postal_code: "16258",
    full_city_name: "Strattanville, PA",
    city_name: "Strattanville",
  },
  {
    postal_code: "68812",
    full_city_name: "Amherst, NE",
    city_name: "Amherst",
  },
  {
    postal_code: "39859",
    full_city_name: "Iron City, GA",
    city_name: "Iron City",
  },
  {
    postal_code: "15462",
    full_city_name: "Melcroft, PA",
    city_name: "Melcroft",
  },
  {
    postal_code: "93453",
    full_city_name: "Santa Margarita, CA",
    city_name: "Santa Margarita",
  },
  {
    postal_code: "10537",
    full_city_name: "Lake Peekskill, NY",
    city_name: "Lake Peekskill",
  },
  {
    postal_code: "97627",
    full_city_name: "Keno, OR",
    city_name: "Keno",
  },
  {
    postal_code: "40863",
    full_city_name: "Pathfork, KY",
    city_name: "Pathfork",
  },
  {
    postal_code: "67045",
    full_city_name: "Eureka, KS",
    city_name: "Eureka",
  },
  {
    postal_code: "18628",
    full_city_name: "Lopez, PA",
    city_name: "Lopez",
  },
  {
    postal_code: "79783",
    full_city_name: "Tarzan, TX",
    city_name: "Tarzan",
  },
  {
    postal_code: "36345",
    full_city_name: "Headland, AL",
    city_name: "Headland",
  },
  {
    postal_code: "58442",
    full_city_name: "Gackle, ND",
    city_name: "Gackle",
  },
  {
    postal_code: "10975",
    full_city_name: "Southfields, NY",
    city_name: "Southfields",
  },
  {
    postal_code: "65679",
    full_city_name: "Kirbyville, MO",
    city_name: "Kirbyville",
  },
  {
    postal_code: "97416",
    full_city_name: "Camas Valley, OR",
    city_name: "Camas Valley",
  },
  {
    postal_code: "50258",
    full_city_name: "Union, IA",
    city_name: "Union",
  },
  {
    postal_code: "48476",
    full_city_name: "Vernon, MI",
    city_name: "Vernon",
  },
  {
    postal_code: "17723",
    full_city_name: "Cammal, PA",
    city_name: "Cammal",
  },
  {
    postal_code: "58566",
    full_city_name: "Saint Anthony, ND",
    city_name: "Saint Anthony",
  },
  {
    postal_code: "46617",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "41836",
    full_city_name: "Mallie, KY",
    city_name: "Mallie",
  },
  {
    postal_code: "72348",
    full_city_name: "Hughes, AR",
    city_name: "Hughes",
  },
  {
    postal_code: "78886",
    full_city_name: "Yancey, TX",
    city_name: "Yancey",
  },
  {
    postal_code: "24092",
    full_city_name: "Glade Hill, VA",
    city_name: "Glade Hill",
  },
  {
    postal_code: "71232",
    full_city_name: "Delhi, LA",
    city_name: "Delhi",
  },
  {
    postal_code: "98616",
    full_city_name: "Cougar, WA",
    city_name: "Cougar",
  },
  {
    postal_code: "62919",
    full_city_name: "Cave In Rock, IL",
    city_name: "Cave In Rock",
  },
  {
    postal_code: "83245",
    full_city_name: "Inkom, ID",
    city_name: "Inkom",
  },
  {
    postal_code: "37398",
    full_city_name: "Winchester, TN",
    city_name: "Winchester",
  },
  {
    postal_code: "62964",
    full_city_name: "Mounds, IL",
    city_name: "Mounds",
  },
  {
    postal_code: "49034",
    full_city_name: "Climax, MI",
    city_name: "Climax",
  },
  {
    postal_code: "50667",
    full_city_name: "Raymond, IA",
    city_name: "Raymond",
  },
  {
    postal_code: "72415",
    full_city_name: "Black Rock, AR",
    city_name: "Black Rock",
  },
  {
    postal_code: "66603",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "57374",
    full_city_name: "Spencer, SD",
    city_name: "Spencer",
  },
  {
    postal_code: "26343",
    full_city_name: "Crawford, WV",
    city_name: "Crawford",
  },
  {
    postal_code: "56032",
    full_city_name: "Freeborn, MN",
    city_name: "Freeborn",
  },
  {
    postal_code: "51443",
    full_city_name: "Glidden, IA",
    city_name: "Glidden",
  },
  {
    postal_code: "32506",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "19940",
    full_city_name: "Delmar, DE",
    city_name: "Delmar",
  },
  {
    postal_code: "25268",
    full_city_name: "Orma, WV",
    city_name: "Orma",
  },
  {
    postal_code: "72048",
    full_city_name: "Ethel, AR",
    city_name: "Ethel",
  },
  {
    postal_code: "95305",
    full_city_name: "Big Oak Flat, CA",
    city_name: "Big Oak Flat",
  },
  {
    postal_code: "64456",
    full_city_name: "Grant City, MO",
    city_name: "Grant City",
  },
  {
    postal_code: "71851",
    full_city_name: "Mineral Springs, AR",
    city_name: "Mineral Springs",
  },
  {
    postal_code: "15486",
    full_city_name: "Vanderbilt, PA",
    city_name: "Vanderbilt",
  },
  {
    postal_code: "23056",
    full_city_name: "Foster, VA",
    city_name: "Foster",
  },
  {
    postal_code: "25812",
    full_city_name: "Ansted, WV",
    city_name: "Ansted",
  },
  {
    postal_code: "78671",
    full_city_name: "Stonewall, TX",
    city_name: "Stonewall",
  },
  {
    postal_code: "47523",
    full_city_name: "Dale, IN",
    city_name: "Dale",
  },
  {
    postal_code: "59427",
    full_city_name: "Cut Bank, MT",
    city_name: "Cut Bank",
  },
  {
    postal_code: "98814",
    full_city_name: "Carlton, WA",
    city_name: "Carlton",
  },
  {
    postal_code: "17887",
    full_city_name: "White Deer, PA",
    city_name: "White Deer",
  },
  {
    postal_code: "56164",
    full_city_name: "Pipestone, MN",
    city_name: "Pipestone",
  },
  {
    postal_code: "02020",
    full_city_name: "Brant Rock, MA",
    city_name: "Brant Rock",
  },
  {
    postal_code: "72326",
    full_city_name: "Colt, AR",
    city_name: "Colt",
  },
  {
    postal_code: "17051",
    full_city_name: "Mc Veytown, PA",
    city_name: "Mc Veytown",
  },
  {
    postal_code: "29130",
    full_city_name: "Ridgeway, SC",
    city_name: "Ridgeway",
  },
  {
    postal_code: "56566",
    full_city_name: "Naytahwaush, MN",
    city_name: "Naytahwaush",
  },
  {
    postal_code: "26271",
    full_city_name: "Hendricks, WV",
    city_name: "Hendricks",
  },
  {
    postal_code: "66521",
    full_city_name: "Onaga, KS",
    city_name: "Onaga",
  },
  {
    postal_code: "55029",
    full_city_name: "Grandy, MN",
    city_name: "Grandy",
  },
  {
    postal_code: "47670",
    full_city_name: "Princeton, IN",
    city_name: "Princeton",
  },
  {
    postal_code: "37022",
    full_city_name: "Bethpage, TN",
    city_name: "Bethpage",
  },
  {
    postal_code: "38253",
    full_city_name: "Rives, TN",
    city_name: "Rives",
  },
  {
    postal_code: "83645",
    full_city_name: "Midvale, ID",
    city_name: "Midvale",
  },
  {
    postal_code: "29824",
    full_city_name: "Edgefield, SC",
    city_name: "Edgefield",
  },
  {
    postal_code: "52257",
    full_city_name: "Luzerne, IA",
    city_name: "Luzerne",
  },
  {
    postal_code: "66039",
    full_city_name: "Kincaid, KS",
    city_name: "Kincaid",
  },
  {
    postal_code: "49724",
    full_city_name: "Dafter, MI",
    city_name: "Dafter",
  },
  {
    postal_code: "38459",
    full_city_name: "Frankewing, TN",
    city_name: "Frankewing",
  },
  {
    postal_code: "70739",
    full_city_name: "Greenwell Springs, LA",
    city_name: "Greenwell Springs",
  },
  {
    postal_code: "71449",
    full_city_name: "Many, LA",
    city_name: "Many",
  },
  {
    postal_code: "49534",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "42020",
    full_city_name: "Almo, KY",
    city_name: "Almo",
  },
  {
    postal_code: "68758",
    full_city_name: "Newman Grove, NE",
    city_name: "Newman Grove",
  },
  {
    postal_code: "67841",
    full_city_name: "Ensign, KS",
    city_name: "Ensign",
  },
  {
    postal_code: "24957",
    full_city_name: "Maxwelton, WV",
    city_name: "Maxwelton",
  },
  {
    postal_code: "35979",
    full_city_name: "Higdon, AL",
    city_name: "Higdon",
  },
  {
    postal_code: "59750",
    full_city_name: "Butte, MT",
    city_name: "Butte",
  },
  {
    postal_code: "14475",
    full_city_name: "Ionia, NY",
    city_name: "Ionia",
  },
  {
    postal_code: "58773",
    full_city_name: "Powers Lake, ND",
    city_name: "Powers Lake",
  },
  {
    postal_code: "52354",
    full_city_name: "Watkins, IA",
    city_name: "Watkins",
  },
  {
    postal_code: "12791",
    full_city_name: "Youngsville, NY",
    city_name: "Youngsville",
  },
  {
    postal_code: "50106",
    full_city_name: "Gilman, IA",
    city_name: "Gilman",
  },
  {
    postal_code: "08346",
    full_city_name: "Newtonville, NJ",
    city_name: "Newtonville",
  },
  {
    postal_code: "29370",
    full_city_name: "Mountville, SC",
    city_name: "Mountville",
  },
  {
    postal_code: "58233",
    full_city_name: "Forest River, ND",
    city_name: "Forest River",
  },
  {
    postal_code: "29584",
    full_city_name: "Patrick, SC",
    city_name: "Patrick",
  },
  {
    postal_code: "62481",
    full_city_name: "Yale, IL",
    city_name: "Yale",
  },
  {
    postal_code: "50626",
    full_city_name: "Dunkerton, IA",
    city_name: "Dunkerton",
  },
  {
    postal_code: "73552",
    full_city_name: "Indiahoma, OK",
    city_name: "Indiahoma",
  },
  {
    postal_code: "85343",
    full_city_name: "Palo Verde, AZ",
    city_name: "Palo Verde",
  },
  {
    postal_code: "47141",
    full_city_name: "Marysville, IN",
    city_name: "Marysville",
  },
  {
    postal_code: "12778",
    full_city_name: "Smallwood, NY",
    city_name: "Smallwood",
  },
  {
    postal_code: "64071",
    full_city_name: "Mayview, MO",
    city_name: "Mayview",
  },
  {
    postal_code: "15553",
    full_city_name: "New Baltimore, PA",
    city_name: "New Baltimore",
  },
  {
    postal_code: "12752",
    full_city_name: "Lake Huntington, NY",
    city_name: "Lake Huntington",
  },
  {
    postal_code: "17220",
    full_city_name: "Dry Run, PA",
    city_name: "Dry Run",
  },
  {
    postal_code: "65606",
    full_city_name: "Alton, MO",
    city_name: "Alton",
  },
  {
    postal_code: "47515",
    full_city_name: "Bristow, IN",
    city_name: "Bristow",
  },
  {
    postal_code: "98499",
    full_city_name: "Lakewood, WA",
    city_name: "Lakewood",
  },
  {
    postal_code: "85927",
    full_city_name: "Greer, AZ",
    city_name: "Greer",
  },
  {
    postal_code: "15856",
    full_city_name: "Rockton, PA",
    city_name: "Rockton",
  },
  {
    postal_code: "83836",
    full_city_name: "Hope, ID",
    city_name: "Hope",
  },
  {
    postal_code: "65255",
    full_city_name: "Hallsville, MO",
    city_name: "Hallsville",
  },
  {
    postal_code: "42566",
    full_city_name: "Yosemite, KY",
    city_name: "Yosemite",
  },
  {
    postal_code: "93529",
    full_city_name: "June Lake, CA",
    city_name: "June Lake",
  },
  {
    postal_code: "52554",
    full_city_name: "Eldon, IA",
    city_name: "Eldon",
  },
  {
    postal_code: "80861",
    full_city_name: "Vona, CO",
    city_name: "Vona",
  },
  {
    postal_code: "32330",
    full_city_name: "Greensboro, FL",
    city_name: "Greensboro",
  },
  {
    postal_code: "63862",
    full_city_name: "Lilbourn, MO",
    city_name: "Lilbourn",
  },
  {
    postal_code: "16839",
    full_city_name: "Grassflat, PA",
    city_name: "Grassflat",
  },
  {
    postal_code: "36767",
    full_city_name: "Orrville, AL",
    city_name: "Orrville",
  },
  {
    postal_code: "23359",
    full_city_name: "Hallwood, VA",
    city_name: "Hallwood",
  },
  {
    postal_code: "24993",
    full_city_name: "Wolfcreek, WV",
    city_name: "Wolfcreek",
  },
  {
    postal_code: "57049",
    full_city_name: "North Sioux City, SD",
    city_name: "North Sioux City",
  },
  {
    postal_code: "71751",
    full_city_name: "Louann, AR",
    city_name: "Louann",
  },
  {
    postal_code: "31623",
    full_city_name: "Argyle, GA",
    city_name: "Argyle",
  },
  {
    postal_code: "62338",
    full_city_name: "Fowler, IL",
    city_name: "Fowler",
  },
  {
    postal_code: "97453",
    full_city_name: "Mapleton, OR",
    city_name: "Mapleton",
  },
  {
    postal_code: "37335",
    full_city_name: "Flintville, TN",
    city_name: "Flintville",
  },
  {
    postal_code: "61936",
    full_city_name: "La Place, IL",
    city_name: "La Place",
  },
  {
    postal_code: "44887",
    full_city_name: "Tiro, OH",
    city_name: "Tiro",
  },
  {
    postal_code: "28006",
    full_city_name: "Alexis, NC",
    city_name: "Alexis",
  },
  {
    postal_code: "24520",
    full_city_name: "Alton, VA",
    city_name: "Alton",
  },
  {
    postal_code: "53057",
    full_city_name: "Mount Calvary, WI",
    city_name: "Mount Calvary",
  },
  {
    postal_code: "58008",
    full_city_name: "Barney, ND",
    city_name: "Barney",
  },
  {
    postal_code: "22811",
    full_city_name: "Bergton, VA",
    city_name: "Bergton",
  },
  {
    postal_code: "18332",
    full_city_name: "Henryville, PA",
    city_name: "Henryville",
  },
  {
    postal_code: "54489",
    full_city_name: "Vesper, WI",
    city_name: "Vesper",
  },
  {
    postal_code: "80833",
    full_city_name: "Rush, CO",
    city_name: "Rush",
  },
  {
    postal_code: "47584",
    full_city_name: "Spurgeon, IN",
    city_name: "Spurgeon",
  },
  {
    postal_code: "28622",
    full_city_name: "Elk Park, NC",
    city_name: "Elk Park",
  },
  {
    postal_code: "54948",
    full_city_name: "Leopolis, WI",
    city_name: "Leopolis",
  },
  {
    postal_code: "98923",
    full_city_name: "Cowiche, WA",
    city_name: "Cowiche",
  },
  {
    postal_code: "80622",
    full_city_name: "Galeton, CO",
    city_name: "Galeton",
  },
  {
    postal_code: "67563",
    full_city_name: "Offerle, KS",
    city_name: "Offerle",
  },
  {
    postal_code: "25203",
    full_city_name: "Turtle Creek, WV",
    city_name: "Turtle Creek",
  },
  {
    postal_code: "42411",
    full_city_name: "Fredonia, KY",
    city_name: "Fredonia",
  },
  {
    postal_code: "77629",
    full_city_name: "Nome, TX",
    city_name: "Nome",
  },
  {
    postal_code: "47950",
    full_city_name: "Idaville, IN",
    city_name: "Idaville",
  },
  {
    postal_code: "62282",
    full_city_name: "Saint Libory, IL",
    city_name: "Saint Libory",
  },
  {
    postal_code: "41563",
    full_city_name: "Shelby Gap, KY",
    city_name: "Shelby Gap",
  },
  {
    postal_code: "65330",
    full_city_name: "Gilliam, MO",
    city_name: "Gilliam",
  },
  {
    postal_code: "87375",
    full_city_name: "Yatahey, NM",
    city_name: "Yatahey",
  },
  {
    postal_code: "68033",
    full_city_name: "Ithaca, NE",
    city_name: "Ithaca",
  },
  {
    postal_code: "63439",
    full_city_name: "Emden, MO",
    city_name: "Emden",
  },
  {
    postal_code: "87537",
    full_city_name: "Hernandez, NM",
    city_name: "Hernandez",
  },
  {
    postal_code: "81020",
    full_city_name: "Aguilar, CO",
    city_name: "Aguilar",
  },
  {
    postal_code: "68624",
    full_city_name: "Bellwood, NE",
    city_name: "Bellwood",
  },
  {
    postal_code: "12934",
    full_city_name: "Ellenburg Center, NY",
    city_name: "Ellenburg Center",
  },
  {
    postal_code: "97876",
    full_city_name: "Summerville, OR",
    city_name: "Summerville",
  },
  {
    postal_code: "39341",
    full_city_name: "Macon, MS",
    city_name: "Macon",
  },
  {
    postal_code: "71462",
    full_city_name: "Noble, LA",
    city_name: "Noble",
  },
  {
    postal_code: "82442",
    full_city_name: "Ten Sleep, WY",
    city_name: "Ten Sleep",
  },
  {
    postal_code: "57021",
    full_city_name: "Davis, SD",
    city_name: "Davis",
  },
  {
    postal_code: "38740",
    full_city_name: "Duncan, MS",
    city_name: "Duncan",
  },
  {
    postal_code: "98644",
    full_city_name: "Seaview, WA",
    city_name: "Seaview",
  },
  {
    postal_code: "93251",
    full_city_name: "Mc Kittrick, CA",
    city_name: "Mc Kittrick",
  },
  {
    postal_code: "49265",
    full_city_name: "Onsted, MI",
    city_name: "Onsted",
  },
  {
    postal_code: "72950",
    full_city_name: "Parks, AR",
    city_name: "Parks",
  },
  {
    postal_code: "63448",
    full_city_name: "La Grange, MO",
    city_name: "La Grange",
  },
  {
    postal_code: "17518",
    full_city_name: "Drumore, PA",
    city_name: "Drumore",
  },
  {
    postal_code: "73102",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "75672",
    full_city_name: "Marshall, TX",
    city_name: "Marshall",
  },
  {
    postal_code: "68863",
    full_city_name: "Overton, NE",
    city_name: "Overton",
  },
  {
    postal_code: "58004",
    full_city_name: "Amenia, ND",
    city_name: "Amenia",
  },
  {
    postal_code: "70377",
    full_city_name: "Montegut, LA",
    city_name: "Montegut",
  },
  {
    postal_code: "78391",
    full_city_name: "Tynan, TX",
    city_name: "Tynan",
  },
  {
    postal_code: "68942",
    full_city_name: "Guide Rock, NE",
    city_name: "Guide Rock",
  },
  {
    postal_code: "17721",
    full_city_name: "Avis, PA",
    city_name: "Avis",
  },
  {
    postal_code: "53816",
    full_city_name: "Mount Hope, WI",
    city_name: "Mount Hope",
  },
  {
    postal_code: "16726",
    full_city_name: "Cyclone, PA",
    city_name: "Cyclone",
  },
  {
    postal_code: "71418",
    full_city_name: "Columbia, LA",
    city_name: "Columbia",
  },
  {
    postal_code: "80246",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "16750",
    full_city_name: "Turtlepoint, PA",
    city_name: "Turtlepoint",
  },
  {
    postal_code: "71346",
    full_city_name: "Lecompte, LA",
    city_name: "Lecompte",
  },
  {
    postal_code: "54490",
    full_city_name: "Westboro, WI",
    city_name: "Westboro",
  },
  {
    postal_code: "73627",
    full_city_name: "Carter, OK",
    city_name: "Carter",
  },
  {
    postal_code: "39462",
    full_city_name: "New Augusta, MS",
    city_name: "New Augusta",
  },
  {
    postal_code: "50238",
    full_city_name: "Russell, IA",
    city_name: "Russell",
  },
  {
    postal_code: "49256",
    full_city_name: "Morenci, MI",
    city_name: "Morenci",
  },
  {
    postal_code: "53007",
    full_city_name: "Butler, WI",
    city_name: "Butler",
  },
  {
    postal_code: "10706",
    full_city_name: "Hastings On Hudson, NY",
    city_name: "Hastings On Hudson",
  },
  {
    postal_code: "79096",
    full_city_name: "Wheeler, TX",
    city_name: "Wheeler",
  },
  {
    postal_code: "25854",
    full_city_name: "Hico, WV",
    city_name: "Hico",
  },
  {
    postal_code: "46928",
    full_city_name: "Fairmount, IN",
    city_name: "Fairmount",
  },
  {
    postal_code: "57234",
    full_city_name: "Estelline, SD",
    city_name: "Estelline",
  },
  {
    postal_code: "58324",
    full_city_name: "Cando, ND",
    city_name: "Cando",
  },
  {
    postal_code: "48720",
    full_city_name: "Bay Port, MI",
    city_name: "Bay Port",
  },
  {
    postal_code: "32427",
    full_city_name: "Caryville, FL",
    city_name: "Caryville",
  },
  {
    postal_code: "98279",
    full_city_name: "Olga, WA",
    city_name: "Olga",
  },
  {
    postal_code: "67831",
    full_city_name: "Ashland, KS",
    city_name: "Ashland",
  },
  {
    postal_code: "17813",
    full_city_name: "Beavertown, PA",
    city_name: "Beavertown",
  },
  {
    postal_code: "73554",
    full_city_name: "Mangum, OK",
    city_name: "Mangum",
  },
  {
    postal_code: "49266",
    full_city_name: "Osseo, MI",
    city_name: "Osseo",
  },
  {
    postal_code: "58418",
    full_city_name: "Bowdon, ND",
    city_name: "Bowdon",
  },
  {
    postal_code: "72068",
    full_city_name: "Higginson, AR",
    city_name: "Higginson",
  },
  {
    postal_code: "78330",
    full_city_name: "Agua Dulce, TX",
    city_name: "Agua Dulce",
  },
  {
    postal_code: "58078",
    full_city_name: "West Fargo, ND",
    city_name: "West Fargo",
  },
  {
    postal_code: "66510",
    full_city_name: "Melvern, KS",
    city_name: "Melvern",
  },
  {
    postal_code: "58351",
    full_city_name: "Minnewaukan, ND",
    city_name: "Minnewaukan",
  },
  {
    postal_code: "62850",
    full_city_name: "Johnsonville, IL",
    city_name: "Johnsonville",
  },
  {
    postal_code: "51234",
    full_city_name: "Boyden, IA",
    city_name: "Boyden",
  },
  {
    postal_code: "45808",
    full_city_name: "Beaverdam, OH",
    city_name: "Beaverdam",
  },
  {
    postal_code: "17768",
    full_city_name: "Shunk, PA",
    city_name: "Shunk",
  },
  {
    postal_code: "98851",
    full_city_name: "Soap Lake, WA",
    city_name: "Soap Lake",
  },
  {
    postal_code: "71295",
    full_city_name: "Winnsboro, LA",
    city_name: "Winnsboro",
  },
  {
    postal_code: "13140",
    full_city_name: "Port Byron, NY",
    city_name: "Port Byron",
  },
  {
    postal_code: "74632",
    full_city_name: "Braman, OK",
    city_name: "Braman",
  },
  {
    postal_code: "62426",
    full_city_name: "Edgewood, IL",
    city_name: "Edgewood",
  },
  {
    postal_code: "66755",
    full_city_name: "Moran, KS",
    city_name: "Moran",
  },
  {
    postal_code: "94971",
    full_city_name: "Tomales, CA",
    city_name: "Tomales",
  },
  {
    postal_code: "52351",
    full_city_name: "Walford, IA",
    city_name: "Walford",
  },
  {
    postal_code: "04460",
    full_city_name: "Medway, ME",
    city_name: "Medway",
  },
  {
    postal_code: "47832",
    full_city_name: "Bloomingdale, IN",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "68406",
    full_city_name: "Milligan, NE",
    city_name: "Milligan",
  },
  {
    postal_code: "35674",
    full_city_name: "Tuscumbia, AL",
    city_name: "Tuscumbia",
  },
  {
    postal_code: "13692",
    full_city_name: "Thousand Island Park, NY",
    city_name: "Thousand Island Park",
  },
  {
    postal_code: "81092",
    full_city_name: "Wiley, CO",
    city_name: "Wiley",
  },
  {
    postal_code: "24221",
    full_city_name: "Blackwater, VA",
    city_name: "Blackwater",
  },
  {
    postal_code: "59410",
    full_city_name: "Augusta, MT",
    city_name: "Augusta",
  },
  {
    postal_code: "17964",
    full_city_name: "Pitman, PA",
    city_name: "Pitman",
  },
  {
    postal_code: "24827",
    full_city_name: "Cyclone, WV",
    city_name: "Cyclone",
  },
  {
    postal_code: "58071",
    full_city_name: "Tower City, ND",
    city_name: "Tower City",
  },
  {
    postal_code: "12792",
    full_city_name: "Yulan, NY",
    city_name: "Yulan",
  },
  {
    postal_code: "25118",
    full_city_name: "Kimberly, WV",
    city_name: "Kimberly",
  },
  {
    postal_code: "72426",
    full_city_name: "Dell, AR",
    city_name: "Dell",
  },
  {
    postal_code: "36029",
    full_city_name: "Fitzpatrick, AL",
    city_name: "Fitzpatrick",
  },
  {
    postal_code: "29112",
    full_city_name: "North, SC",
    city_name: "North",
  },
  {
    postal_code: "62530",
    full_city_name: "Divernon, IL",
    city_name: "Divernon",
  },
  {
    postal_code: "88034",
    full_city_name: "Faywood, NM",
    city_name: "Faywood",
  },
  {
    postal_code: "51101",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "95568",
    full_city_name: "Somes Bar, CA",
    city_name: "Somes Bar",
  },
  {
    postal_code: "35816",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "64653",
    full_city_name: "Linneus, MO",
    city_name: "Linneus",
  },
  {
    postal_code: "10597",
    full_city_name: "Waccabuc, NY",
    city_name: "Waccabuc",
  },
  {
    postal_code: "17246",
    full_city_name: "Pleasant Hall, PA",
    city_name: "Pleasant Hall",
  },
  {
    postal_code: "14549",
    full_city_name: "Silver Lake, NY",
    city_name: "Silver Lake",
  },
  {
    postal_code: "50649",
    full_city_name: "Kesley, IA",
    city_name: "Kesley",
  },
  {
    postal_code: "71061",
    full_city_name: "Oil City, LA",
    city_name: "Oil City",
  },
  {
    postal_code: "36276",
    full_city_name: "Wadley, AL",
    city_name: "Wadley",
  },
  {
    postal_code: "21652",
    full_city_name: "Neavitt, MD",
    city_name: "Neavitt",
  },
  {
    postal_code: "68791",
    full_city_name: "Wisner, NE",
    city_name: "Wisner",
  },
  {
    postal_code: "47575",
    full_city_name: "Saint Anthony, IN",
    city_name: "Saint Anthony",
  },
  {
    postal_code: "44045",
    full_city_name: "Grand River, OH",
    city_name: "Grand River",
  },
  {
    postal_code: "24161",
    full_city_name: "Sandy Level, VA",
    city_name: "Sandy Level",
  },
  {
    postal_code: "35748",
    full_city_name: "Gurley, AL",
    city_name: "Gurley",
  },
  {
    postal_code: "67138",
    full_city_name: "Sharon, KS",
    city_name: "Sharon",
  },
  {
    postal_code: "54983",
    full_city_name: "Weyauwega, WI",
    city_name: "Weyauwega",
  },
  {
    postal_code: "42324",
    full_city_name: "Belton, KY",
    city_name: "Belton",
  },
  {
    postal_code: "44628",
    full_city_name: "Glenmont, OH",
    city_name: "Glenmont",
  },
  {
    postal_code: "58455",
    full_city_name: "Kensal, ND",
    city_name: "Kensal",
  },
  {
    postal_code: "36009",
    full_city_name: "Brantley, AL",
    city_name: "Brantley",
  },
  {
    postal_code: "46957",
    full_city_name: "Matthews, IN",
    city_name: "Matthews",
  },
  {
    postal_code: "62438",
    full_city_name: "Lakewood, IL",
    city_name: "Lakewood",
  },
  {
    postal_code: "37033",
    full_city_name: "Centerville, TN",
    city_name: "Centerville",
  },
  {
    postal_code: "74855",
    full_city_name: "Meeker, OK",
    city_name: "Meeker",
  },
  {
    postal_code: "68843",
    full_city_name: "Hampton, NE",
    city_name: "Hampton",
  },
  {
    postal_code: "83604",
    full_city_name: "Bruneau, ID",
    city_name: "Bruneau",
  },
  {
    postal_code: "79744",
    full_city_name: "Iraan, TX",
    city_name: "Iraan",
  },
  {
    postal_code: "85554",
    full_city_name: "Young, AZ",
    city_name: "Young",
  },
  {
    postal_code: "71044",
    full_city_name: "Ida, LA",
    city_name: "Ida",
  },
  {
    postal_code: "65685",
    full_city_name: "Louisburg, MO",
    city_name: "Louisburg",
  },
  {
    postal_code: "98224",
    full_city_name: "Baring, WA",
    city_name: "Baring",
  },
  {
    postal_code: "99347",
    full_city_name: "Pomeroy, WA",
    city_name: "Pomeroy",
  },
  {
    postal_code: "17868",
    full_city_name: "Riverside, PA",
    city_name: "Riverside",
  },
  {
    postal_code: "68407",
    full_city_name: "Murdock, NE",
    city_name: "Murdock",
  },
  {
    postal_code: "62513",
    full_city_name: "Blue Mound, IL",
    city_name: "Blue Mound",
  },
  {
    postal_code: "71743",
    full_city_name: "Gurdon, AR",
    city_name: "Gurdon",
  },
  {
    postal_code: "76649",
    full_city_name: "Iredell, TX",
    city_name: "Iredell",
  },
  {
    postal_code: "44308",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "31096",
    full_city_name: "Wrightsville, GA",
    city_name: "Wrightsville",
  },
  {
    postal_code: "17864",
    full_city_name: "Port Trevorton, PA",
    city_name: "Port Trevorton",
  },
  {
    postal_code: "87722",
    full_city_name: "Guadalupita, NM",
    city_name: "Guadalupita",
  },
  {
    postal_code: "31070",
    full_city_name: "Pinehurst, GA",
    city_name: "Pinehurst",
  },
  {
    postal_code: "83666",
    full_city_name: "Placerville, ID",
    city_name: "Placerville",
  },
  {
    postal_code: "15865",
    full_city_name: "Sykesville, PA",
    city_name: "Sykesville",
  },
  {
    postal_code: "13626",
    full_city_name: "Copenhagen, NY",
    city_name: "Copenhagen",
  },
  {
    postal_code: "08863",
    full_city_name: "Fords, NJ",
    city_name: "Fords",
  },
  {
    postal_code: "54769",
    full_city_name: "Stockholm, WI",
    city_name: "Stockholm",
  },
  {
    postal_code: "26298",
    full_city_name: "Bergoo, WV",
    city_name: "Bergoo",
  },
  {
    postal_code: "54416",
    full_city_name: "Bowler, WI",
    city_name: "Bowler",
  },
  {
    postal_code: "10918",
    full_city_name: "Chester, NY",
    city_name: "Chester",
  },
  {
    postal_code: "67451",
    full_city_name: "Hope, KS",
    city_name: "Hope",
  },
  {
    postal_code: "47175",
    full_city_name: "Taswell, IN",
    city_name: "Taswell",
  },
  {
    postal_code: "82842",
    full_city_name: "Story, WY",
    city_name: "Story",
  },
  {
    postal_code: "82423",
    full_city_name: "Frannie, WY",
    city_name: "Frannie",
  },
  {
    postal_code: "40402",
    full_city_name: "Annville, KY",
    city_name: "Annville",
  },
  {
    postal_code: "47110",
    full_city_name: "Central, IN",
    city_name: "Central",
  },
  {
    postal_code: "14855",
    full_city_name: "Jasper, NY",
    city_name: "Jasper",
  },
  {
    postal_code: "95551",
    full_city_name: "Loleta, CA",
    city_name: "Loleta",
  },
  {
    postal_code: "35136",
    full_city_name: "Rockford, AL",
    city_name: "Rockford",
  },
  {
    postal_code: "83544",
    full_city_name: "Orofino, ID",
    city_name: "Orofino",
  },
  {
    postal_code: "87539",
    full_city_name: "La Madera, NM",
    city_name: "La Madera",
  },
  {
    postal_code: "24226",
    full_city_name: "Clinchco, VA",
    city_name: "Clinchco",
  },
  {
    postal_code: "40460",
    full_city_name: "Orlando, KY",
    city_name: "Orlando",
  },
  {
    postal_code: "64165",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "43740",
    full_city_name: "Gratiot, OH",
    city_name: "Gratiot",
  },
  {
    postal_code: "41564",
    full_city_name: "Sidney, KY",
    city_name: "Sidney",
  },
  {
    postal_code: "59338",
    full_city_name: "Kinsey, MT",
    city_name: "Kinsey",
  },
  {
    postal_code: "29848",
    full_city_name: "Troy, SC",
    city_name: "Troy",
  },
  {
    postal_code: "25876",
    full_city_name: "Saulsville, WV",
    city_name: "Saulsville",
  },
  {
    postal_code: "63767",
    full_city_name: "Morley, MO",
    city_name: "Morley",
  },
  {
    postal_code: "73724",
    full_city_name: "Canton, OK",
    city_name: "Canton",
  },
  {
    postal_code: "28325",
    full_city_name: "Calypso, NC",
    city_name: "Calypso",
  },
  {
    postal_code: "30251",
    full_city_name: "Luthersville, GA",
    city_name: "Luthersville",
  },
  {
    postal_code: "10985",
    full_city_name: "Thompson Ridge, NY",
    city_name: "Thompson Ridge",
  },
  {
    postal_code: "53233",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "73834",
    full_city_name: "Buffalo, OK",
    city_name: "Buffalo",
  },
  {
    postal_code: "93541",
    full_city_name: "Lee Vining, CA",
    city_name: "Lee Vining",
  },
  {
    postal_code: "54526",
    full_city_name: "Glen Flora, WI",
    city_name: "Glen Flora",
  },
  {
    postal_code: "36541",
    full_city_name: "Grand Bay, AL",
    city_name: "Grand Bay",
  },
  {
    postal_code: "95920",
    full_city_name: "Butte City, CA",
    city_name: "Butte City",
  },
  {
    postal_code: "86032",
    full_city_name: "Joseph City, AZ",
    city_name: "Joseph City",
  },
  {
    postal_code: "73441",
    full_city_name: "Leon, OK",
    city_name: "Leon",
  },
  {
    postal_code: "39364",
    full_city_name: "Toomsuba, MS",
    city_name: "Toomsuba",
  },
  {
    postal_code: "98592",
    full_city_name: "Union, WA",
    city_name: "Union",
  },
  {
    postal_code: "98329",
    full_city_name: "Gig Harbor, WA",
    city_name: "Gig Harbor",
  },
  {
    postal_code: "50065",
    full_city_name: "Davis City, IA",
    city_name: "Davis City",
  },
  {
    postal_code: "70814",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "56051",
    full_city_name: "Kiester, MN",
    city_name: "Kiester",
  },
  {
    postal_code: "52079",
    full_city_name: "Zwingle, IA",
    city_name: "Zwingle",
  },
  {
    postal_code: "58521",
    full_city_name: "Baldwin, ND",
    city_name: "Baldwin",
  },
  {
    postal_code: "99760",
    full_city_name: "Nenana, AK",
    city_name: "Nenana",
  },
  {
    postal_code: "76872",
    full_city_name: "Rochelle, TX",
    city_name: "Rochelle",
  },
  {
    postal_code: "14808",
    full_city_name: "Atlanta, NY",
    city_name: "Atlanta",
  },
  {
    postal_code: "62079",
    full_city_name: "Piasa, IL",
    city_name: "Piasa",
  },
  {
    postal_code: "57035",
    full_city_name: "Humboldt, SD",
    city_name: "Humboldt",
  },
  {
    postal_code: "59477",
    full_city_name: "Simms, MT",
    city_name: "Simms",
  },
  {
    postal_code: "73771",
    full_city_name: "Wakita, OK",
    city_name: "Wakita",
  },
  {
    postal_code: "15921",
    full_city_name: "Beaverdale, PA",
    city_name: "Beaverdale",
  },
  {
    postal_code: "74543",
    full_city_name: "Finley, OK",
    city_name: "Finley",
  },
  {
    postal_code: "27531",
    full_city_name: "Goldsboro, NC",
    city_name: "Goldsboro",
  },
  {
    postal_code: "72522",
    full_city_name: "Charlotte, AR",
    city_name: "Charlotte",
  },
  {
    postal_code: "30511",
    full_city_name: "Baldwin, GA",
    city_name: "Baldwin",
  },
  {
    postal_code: "57223",
    full_city_name: "Castlewood, SD",
    city_name: "Castlewood",
  },
  {
    postal_code: "35053",
    full_city_name: "Crane Hill, AL",
    city_name: "Crane Hill",
  },
  {
    postal_code: "59007",
    full_city_name: "Bearcreek, MT",
    city_name: "Bearcreek",
  },
  {
    postal_code: "39423",
    full_city_name: "Beaumont, MS",
    city_name: "Beaumont",
  },
  {
    postal_code: "48334",
    full_city_name: "Farmington, MI",
    city_name: "Farmington",
  },
  {
    postal_code: "54561",
    full_city_name: "Star Lake, WI",
    city_name: "Star Lake",
  },
  {
    postal_code: "39824",
    full_city_name: "Bluffton, GA",
    city_name: "Bluffton",
  },
  {
    postal_code: "62982",
    full_city_name: "Rosiclare, IL",
    city_name: "Rosiclare",
  },
  {
    postal_code: "57479",
    full_city_name: "Warner, SD",
    city_name: "Warner",
  },
  {
    postal_code: "36769",
    full_city_name: "Pine Hill, AL",
    city_name: "Pine Hill",
  },
  {
    postal_code: "24437",
    full_city_name: "Fort Defiance, VA",
    city_name: "Fort Defiance",
  },
  {
    postal_code: "24631",
    full_city_name: "Oakwood, VA",
    city_name: "Oakwood",
  },
  {
    postal_code: "67330",
    full_city_name: "Altamont, KS",
    city_name: "Altamont",
  },
  {
    postal_code: "17856",
    full_city_name: "New Columbia, PA",
    city_name: "New Columbia",
  },
  {
    postal_code: "70637",
    full_city_name: "Dry Creek, LA",
    city_name: "Dry Creek",
  },
  {
    postal_code: "32421",
    full_city_name: "Altha, FL",
    city_name: "Altha",
  },
  {
    postal_code: "30742",
    full_city_name: "Fort Oglethorpe, GA",
    city_name: "Fort Oglethorpe",
  },
  {
    postal_code: "38913",
    full_city_name: "Banner, MS",
    city_name: "Banner",
  },
  {
    postal_code: "05762",
    full_city_name: "Pittsfield, VT",
    city_name: "Pittsfield",
  },
  {
    postal_code: "62573",
    full_city_name: "Warrensburg, IL",
    city_name: "Warrensburg",
  },
  {
    postal_code: "35034",
    full_city_name: "Brent, AL",
    city_name: "Brent",
  },
  {
    postal_code: "72125",
    full_city_name: "Perry, AR",
    city_name: "Perry",
  },
  {
    postal_code: "15351",
    full_city_name: "Nemacolin, PA",
    city_name: "Nemacolin",
  },
  {
    postal_code: "85352",
    full_city_name: "Tacna, AZ",
    city_name: "Tacna",
  },
  {
    postal_code: "46943",
    full_city_name: "Laketon, IN",
    city_name: "Laketon",
  },
  {
    postal_code: "31775",
    full_city_name: "Omega, GA",
    city_name: "Omega",
  },
  {
    postal_code: "72346",
    full_city_name: "Heth, AR",
    city_name: "Heth",
  },
  {
    postal_code: "35647",
    full_city_name: "Lester, AL",
    city_name: "Lester",
  },
  {
    postal_code: "39664",
    full_city_name: "Smithdale, MS",
    city_name: "Smithdale",
  },
  {
    postal_code: "47462",
    full_city_name: "Springville, IN",
    city_name: "Springville",
  },
  {
    postal_code: "10501",
    full_city_name: "Amawalk, NY",
    city_name: "Amawalk",
  },
  {
    postal_code: "83217",
    full_city_name: "Bancroft, ID",
    city_name: "Bancroft",
  },
  {
    postal_code: "50448",
    full_city_name: "Kensett, IA",
    city_name: "Kensett",
  },
  {
    postal_code: "63965",
    full_city_name: "Van Buren, MO",
    city_name: "Van Buren",
  },
  {
    postal_code: "63743",
    full_city_name: "Daisy, MO",
    city_name: "Daisy",
  },
  {
    postal_code: "12482",
    full_city_name: "South Cairo, NY",
    city_name: "South Cairo",
  },
  {
    postal_code: "88344",
    full_city_name: "Pinon, NM",
    city_name: "Pinon",
  },
  {
    postal_code: "08327",
    full_city_name: "Leesburg, NJ",
    city_name: "Leesburg",
  },
  {
    postal_code: "31801",
    full_city_name: "Box Springs, GA",
    city_name: "Box Springs",
  },
  {
    postal_code: "14463",
    full_city_name: "Hall, NY",
    city_name: "Hall",
  },
  {
    postal_code: "42372",
    full_city_name: "Sacramento, KY",
    city_name: "Sacramento",
  },
  {
    postal_code: "07012",
    full_city_name: "Clifton, NJ",
    city_name: "Clifton",
  },
  {
    postal_code: "57042",
    full_city_name: "Madison, SD",
    city_name: "Madison",
  },
  {
    postal_code: "30901",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "65661",
    full_city_name: "Greenfield, MO",
    city_name: "Greenfield",
  },
  {
    postal_code: "72365",
    full_city_name: "Marked Tree, AR",
    city_name: "Marked Tree",
  },
  {
    postal_code: "54546",
    full_city_name: "Mellen, WI",
    city_name: "Mellen",
  },
  {
    postal_code: "79719",
    full_city_name: "Barstow, TX",
    city_name: "Barstow",
  },
  {
    postal_code: "10520",
    full_city_name: "Croton On Hudson, NY",
    city_name: "Croton On Hudson",
  },
  {
    postal_code: "50057",
    full_city_name: "Columbia, IA",
    city_name: "Columbia",
  },
  {
    postal_code: "13618",
    full_city_name: "Cape Vincent, NY",
    city_name: "Cape Vincent",
  },
  {
    postal_code: "92309",
    full_city_name: "Baker, CA",
    city_name: "Baker",
  },
  {
    postal_code: "82325",
    full_city_name: "Encampment, WY",
    city_name: "Encampment",
  },
  {
    postal_code: "05651",
    full_city_name: "East Montpelier, VT",
    city_name: "East Montpelier",
  },
  {
    postal_code: "83324",
    full_city_name: "Dietrich, ID",
    city_name: "Dietrich",
  },
  {
    postal_code: "75492",
    full_city_name: "Windom, TX",
    city_name: "Windom",
  },
  {
    postal_code: "84628",
    full_city_name: "Eureka, UT",
    city_name: "Eureka",
  },
  {
    postal_code: "99103",
    full_city_name: "Almira, WA",
    city_name: "Almira",
  },
  {
    postal_code: "73737",
    full_city_name: "Fairview, OK",
    city_name: "Fairview",
  },
  {
    postal_code: "97885",
    full_city_name: "Wallowa, OR",
    city_name: "Wallowa",
  },
  {
    postal_code: "55036",
    full_city_name: "Henriette, MN",
    city_name: "Henriette",
  },
  {
    postal_code: "59347",
    full_city_name: "Rosebud, MT",
    city_name: "Rosebud",
  },
  {
    postal_code: "50517",
    full_city_name: "Bancroft, IA",
    city_name: "Bancroft",
  },
  {
    postal_code: "65201",
    full_city_name: "Columbia, MO",
    city_name: "Columbia",
  },
  {
    postal_code: "52576",
    full_city_name: "Ollie, IA",
    city_name: "Ollie",
  },
  {
    postal_code: "70638",
    full_city_name: "Elizabeth, LA",
    city_name: "Elizabeth",
  },
  {
    postal_code: "32083",
    full_city_name: "Raiford, FL",
    city_name: "Raiford",
  },
  {
    postal_code: "62474",
    full_city_name: "Westfield, IL",
    city_name: "Westfield",
  },
  {
    postal_code: "81237",
    full_city_name: "Ohio City, CO",
    city_name: "Ohio City",
  },
  {
    postal_code: "38841",
    full_city_name: "Ecru, MS",
    city_name: "Ecru",
  },
  {
    postal_code: "74833",
    full_city_name: "Castle, OK",
    city_name: "Castle",
  },
  {
    postal_code: "63747",
    full_city_name: "Friedheim, MO",
    city_name: "Friedheim",
  },
  {
    postal_code: "24873",
    full_city_name: "Paynesville, WV",
    city_name: "Paynesville",
  },
  {
    postal_code: "24147",
    full_city_name: "Rich Creek, VA",
    city_name: "Rich Creek",
  },
  {
    postal_code: "73434",
    full_city_name: "Foster, OK",
    city_name: "Foster",
  },
  {
    postal_code: "52594",
    full_city_name: "Unionville, IA",
    city_name: "Unionville",
  },
  {
    postal_code: "22726",
    full_city_name: "Lignum, VA",
    city_name: "Lignum",
  },
  {
    postal_code: "58060",
    full_city_name: "Milnor, ND",
    city_name: "Milnor",
  },
  {
    postal_code: "67547",
    full_city_name: "Kinsley, KS",
    city_name: "Kinsley",
  },
  {
    postal_code: "43902",
    full_city_name: "Alledonia, OH",
    city_name: "Alledonia",
  },
  {
    postal_code: "56152",
    full_city_name: "Lamberton, MN",
    city_name: "Lamberton",
  },
  {
    postal_code: "57355",
    full_city_name: "Kimball, SD",
    city_name: "Kimball",
  },
  {
    postal_code: "14060",
    full_city_name: "Farmersville Station, NY",
    city_name: "Farmersville Station",
  },
  {
    postal_code: "18056",
    full_city_name: "Hereford, PA",
    city_name: "Hereford",
  },
  {
    postal_code: "47464",
    full_city_name: "Stinesville, IN",
    city_name: "Stinesville",
  },
  {
    postal_code: "48445",
    full_city_name: "Kinde, MI",
    city_name: "Kinde",
  },
  {
    postal_code: "70528",
    full_city_name: "Delcambre, LA",
    city_name: "Delcambre",
  },
  {
    postal_code: "35981",
    full_city_name: "Ider, AL",
    city_name: "Ider",
  },
  {
    postal_code: "97901",
    full_city_name: "Adrian, OR",
    city_name: "Adrian",
  },
  {
    postal_code: "04469",
    full_city_name: "Orono, ME",
    city_name: "Orono",
  },
  {
    postal_code: "76841",
    full_city_name: "Fort Mc Kavett, TX",
    city_name: "Fort Mc Kavett",
  },
  {
    postal_code: "73529",
    full_city_name: "Comanche, OK",
    city_name: "Comanche",
  },
  {
    postal_code: "52581",
    full_city_name: "Plano, IA",
    city_name: "Plano",
  },
  {
    postal_code: "56520",
    full_city_name: "Breckenridge, MN",
    city_name: "Breckenridge",
  },
  {
    postal_code: "30823",
    full_city_name: "Stapleton, GA",
    city_name: "Stapleton",
  },
  {
    postal_code: "87014",
    full_city_name: "Cubero, NM",
    city_name: "Cubero",
  },
  {
    postal_code: "49322",
    full_city_name: "Coral, MI",
    city_name: "Coral",
  },
  {
    postal_code: "74370",
    full_city_name: "Wyandotte, OK",
    city_name: "Wyandotte",
  },
  {
    postal_code: "50206",
    full_city_name: "New Providence, IA",
    city_name: "New Providence",
  },
  {
    postal_code: "27839",
    full_city_name: "Halifax, NC",
    city_name: "Halifax",
  },
  {
    postal_code: "59463",
    full_city_name: "Monarch, MT",
    city_name: "Monarch",
  },
  {
    postal_code: "53001",
    full_city_name: "Adell, WI",
    city_name: "Adell",
  },
  {
    postal_code: "59471",
    full_city_name: "Roy, MT",
    city_name: "Roy",
  },
  {
    postal_code: "14743",
    full_city_name: "Hinsdale, NY",
    city_name: "Hinsdale",
  },
  {
    postal_code: "37409",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "36316",
    full_city_name: "Chancellor, AL",
    city_name: "Chancellor",
  },
  {
    postal_code: "29162",
    full_city_name: "Turbeville, SC",
    city_name: "Turbeville",
  },
  {
    postal_code: "78850",
    full_city_name: "D Hanis, TX",
    city_name: "D Hanis",
  },
  {
    postal_code: "99559",
    full_city_name: "Bethel, AK",
    city_name: "Bethel",
  },
  {
    postal_code: "52160",
    full_city_name: "New Albin, IA",
    city_name: "New Albin",
  },
  {
    postal_code: "59733",
    full_city_name: "Gold Creek, MT",
    city_name: "Gold Creek",
  },
  {
    postal_code: "64835",
    full_city_name: "Carterville, MO",
    city_name: "Carterville",
  },
  {
    postal_code: "31089",
    full_city_name: "Tennille, GA",
    city_name: "Tennille",
  },
  {
    postal_code: "26802",
    full_city_name: "Brandywine, WV",
    city_name: "Brandywine",
  },
  {
    postal_code: "56722",
    full_city_name: "Euclid, MN",
    city_name: "Euclid",
  },
  {
    postal_code: "66720",
    full_city_name: "Chanute, KS",
    city_name: "Chanute",
  },
  {
    postal_code: "82935",
    full_city_name: "Green River, WY",
    city_name: "Green River",
  },
  {
    postal_code: "16151",
    full_city_name: "Sheakleyville, PA",
    city_name: "Sheakleyville",
  },
  {
    postal_code: "77830",
    full_city_name: "Anderson, TX",
    city_name: "Anderson",
  },
  {
    postal_code: "10518",
    full_city_name: "Cross River, NY",
    city_name: "Cross River",
  },
  {
    postal_code: "79781",
    full_city_name: "Sheffield, TX",
    city_name: "Sheffield",
  },
  {
    postal_code: "96019",
    full_city_name: "Shasta Lake, CA",
    city_name: "Shasta Lake",
  },
  {
    postal_code: "29845",
    full_city_name: "Plum Branch, SC",
    city_name: "Plum Branch",
  },
  {
    postal_code: "66773",
    full_city_name: "Scammon, KS",
    city_name: "Scammon",
  },
  {
    postal_code: "43350",
    full_city_name: "Sparta, OH",
    city_name: "Sparta",
  },
  {
    postal_code: "31038",
    full_city_name: "Hillsboro, GA",
    city_name: "Hillsboro",
  },
  {
    postal_code: "39653",
    full_city_name: "Meadville, MS",
    city_name: "Meadville",
  },
  {
    postal_code: "31771",
    full_city_name: "Norman Park, GA",
    city_name: "Norman Park",
  },
  {
    postal_code: "73568",
    full_city_name: "Temple, OK",
    city_name: "Temple",
  },
  {
    postal_code: "27832",
    full_city_name: "Gaston, NC",
    city_name: "Gaston",
  },
  {
    postal_code: "41824",
    full_city_name: "Isom, KY",
    city_name: "Isom",
  },
  {
    postal_code: "82441",
    full_city_name: "Shell, WY",
    city_name: "Shell",
  },
  {
    postal_code: "93239",
    full_city_name: "Kettleman City, CA",
    city_name: "Kettleman City",
  },
  {
    postal_code: "38473",
    full_city_name: "Minor Hill, TN",
    city_name: "Minor Hill",
  },
  {
    postal_code: "16341",
    full_city_name: "Pleasantville, PA",
    city_name: "Pleasantville",
  },
  {
    postal_code: "50845",
    full_city_name: "Diagonal, IA",
    city_name: "Diagonal",
  },
  {
    postal_code: "50277",
    full_city_name: "Yale, IA",
    city_name: "Yale",
  },
  {
    postal_code: "04852",
    full_city_name: "Monhegan, ME",
    city_name: "Monhegan",
  },
  {
    postal_code: "47138",
    full_city_name: "Lexington, IN",
    city_name: "Lexington",
  },
  {
    postal_code: "15823",
    full_city_name: "Brockport, PA",
    city_name: "Brockport",
  },
  {
    postal_code: "24322",
    full_city_name: "Cripple Creek, VA",
    city_name: "Cripple Creek",
  },
  {
    postal_code: "57058",
    full_city_name: "Salem, SD",
    city_name: "Salem",
  },
  {
    postal_code: "53176",
    full_city_name: "Springfield, WI",
    city_name: "Springfield",
  },
  {
    postal_code: "53167",
    full_city_name: "Rochester, WI",
    city_name: "Rochester",
  },
  {
    postal_code: "15829",
    full_city_name: "Corsica, PA",
    city_name: "Corsica",
  },
  {
    postal_code: "35601",
    full_city_name: "Decatur, AL",
    city_name: "Decatur",
  },
  {
    postal_code: "53097",
    full_city_name: "Mequon, WI",
    city_name: "Mequon",
  },
  {
    postal_code: "56448",
    full_city_name: "Fifty Lakes, MN",
    city_name: "Fifty Lakes",
  },
  {
    postal_code: "14548",
    full_city_name: "Shortsville, NY",
    city_name: "Shortsville",
  },
  {
    postal_code: "68736",
    full_city_name: "Fordyce, NE",
    city_name: "Fordyce",
  },
  {
    postal_code: "62640",
    full_city_name: "Girard, IL",
    city_name: "Girard",
  },
  {
    postal_code: "66741",
    full_city_name: "Garland, KS",
    city_name: "Garland",
  },
  {
    postal_code: "15959",
    full_city_name: "Tire Hill, PA",
    city_name: "Tire Hill",
  },
  {
    postal_code: "15522",
    full_city_name: "Bedford, PA",
    city_name: "Bedford",
  },
  {
    postal_code: "12977",
    full_city_name: "Ray Brook, NY",
    city_name: "Ray Brook",
  },
  {
    postal_code: "66852",
    full_city_name: "Gridley, KS",
    city_name: "Gridley",
  },
  {
    postal_code: "73550",
    full_city_name: "Hollis, OK",
    city_name: "Hollis",
  },
  {
    postal_code: "16845",
    full_city_name: "Karthaus, PA",
    city_name: "Karthaus",
  },
  {
    postal_code: "26376",
    full_city_name: "Ireland, WV",
    city_name: "Ireland",
  },
  {
    postal_code: "35754",
    full_city_name: "Laceys Spring, AL",
    city_name: "Laceys Spring",
  },
  {
    postal_code: "46759",
    full_city_name: "Keystone, IN",
    city_name: "Keystone",
  },
  {
    postal_code: "93633",
    full_city_name: "Kings Canyon National Pk, CA",
    city_name: "Kings Canyon National Pk",
  },
  {
    postal_code: "81148",
    full_city_name: "Romeo, CO",
    city_name: "Romeo",
  },
  {
    postal_code: "47535",
    full_city_name: "Freelandville, IN",
    city_name: "Freelandville",
  },
  {
    postal_code: "51036",
    full_city_name: "Maurice, IA",
    city_name: "Maurice",
  },
  {
    postal_code: "50672",
    full_city_name: "Steamboat Rock, IA",
    city_name: "Steamboat Rock",
  },
  {
    postal_code: "65690",
    full_city_name: "Couch, MO",
    city_name: "Couch",
  },
  {
    postal_code: "16683",
    full_city_name: "Spruce Creek, PA",
    city_name: "Spruce Creek",
  },
  {
    postal_code: "67649",
    full_city_name: "Luray, KS",
    city_name: "Luray",
  },
  {
    postal_code: "70559",
    full_city_name: "Morse, LA",
    city_name: "Morse",
  },
  {
    postal_code: "24313",
    full_city_name: "Barren Springs, VA",
    city_name: "Barren Springs",
  },
  {
    postal_code: "53517",
    full_city_name: "Blue Mounds, WI",
    city_name: "Blue Mounds",
  },
  {
    postal_code: "74650",
    full_city_name: "Ralston, OK",
    city_name: "Ralston",
  },
  {
    postal_code: "38472",
    full_city_name: "Lynnville, TN",
    city_name: "Lynnville",
  },
  {
    postal_code: "54928",
    full_city_name: "Caroline, WI",
    city_name: "Caroline",
  },
  {
    postal_code: "39877",
    full_city_name: "Parrott, GA",
    city_name: "Parrott",
  },
  {
    postal_code: "38453",
    full_city_name: "Dellrose, TN",
    city_name: "Dellrose",
  },
  {
    postal_code: "48230",
    full_city_name: "Grosse Pointe, MI",
    city_name: "Grosse Pointe",
  },
  {
    postal_code: "14726",
    full_city_name: "Conewango Valley, NY",
    city_name: "Conewango Valley",
  },
  {
    postal_code: "58535",
    full_city_name: "Flasher, ND",
    city_name: "Flasher",
  },
  {
    postal_code: "72683",
    full_city_name: "Vendor, AR",
    city_name: "Vendor",
  },
  {
    postal_code: "36505",
    full_city_name: "Axis, AL",
    city_name: "Axis",
  },
  {
    postal_code: "74643",
    full_city_name: "Lamont, OK",
    city_name: "Lamont",
  },
  {
    postal_code: "40819",
    full_city_name: "Coldiron, KY",
    city_name: "Coldiron",
  },
  {
    postal_code: "56581",
    full_city_name: "Shelly, MN",
    city_name: "Shelly",
  },
  {
    postal_code: "84760",
    full_city_name: "Paragonah, UT",
    city_name: "Paragonah",
  },
  {
    postal_code: "16334",
    full_city_name: "Marble, PA",
    city_name: "Marble",
  },
  {
    postal_code: "52754",
    full_city_name: "Letts, IA",
    city_name: "Letts",
  },
  {
    postal_code: "71766",
    full_city_name: "Thornton, AR",
    city_name: "Thornton",
  },
  {
    postal_code: "57763",
    full_city_name: "Oelrichs, SD",
    city_name: "Oelrichs",
  },
  {
    postal_code: "98355",
    full_city_name: "Mineral, WA",
    city_name: "Mineral",
  },
  {
    postal_code: "58105",
    full_city_name: "Fargo, ND",
    city_name: "Fargo",
  },
  {
    postal_code: "67133",
    full_city_name: "Rose Hill, KS",
    city_name: "Rose Hill",
  },
  {
    postal_code: "39337",
    full_city_name: "Little Rock, MS",
    city_name: "Little Rock",
  },
  {
    postal_code: "12493",
    full_city_name: "West Park, NY",
    city_name: "West Park",
  },
  {
    postal_code: "59642",
    full_city_name: "Ringling, MT",
    city_name: "Ringling",
  },
  {
    postal_code: "58036",
    full_city_name: "Gardner, ND",
    city_name: "Gardner",
  },
  {
    postal_code: "72561",
    full_city_name: "Mount Pleasant, AR",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "65612",
    full_city_name: "Bois D Arc, MO",
    city_name: "Bois D Arc",
  },
  {
    postal_code: "24128",
    full_city_name: "Newport, VA",
    city_name: "Newport",
  },
  {
    postal_code: "14727",
    full_city_name: "Cuba, NY",
    city_name: "Cuba",
  },
  {
    postal_code: "98673",
    full_city_name: "Wishram, WA",
    city_name: "Wishram",
  },
  {
    postal_code: "48745",
    full_city_name: "Mikado, MI",
    city_name: "Mikado",
  },
  {
    postal_code: "65646",
    full_city_name: "Everton, MO",
    city_name: "Everton",
  },
  {
    postal_code: "28442",
    full_city_name: "Hallsboro, NC",
    city_name: "Hallsboro",
  },
  {
    postal_code: "47351",
    full_city_name: "Kennard, IN",
    city_name: "Kennard",
  },
  {
    postal_code: "66047",
    full_city_name: "Lawrence, KS",
    city_name: "Lawrence",
  },
  {
    postal_code: "79530",
    full_city_name: "Lawn, TX",
    city_name: "Lawn",
  },
  {
    postal_code: "38457",
    full_city_name: "Five Points, TN",
    city_name: "Five Points",
  },
  {
    postal_code: "59647",
    full_city_name: "Winston, MT",
    city_name: "Winston",
  },
  {
    postal_code: "39741",
    full_city_name: "Cedarbluff, MS",
    city_name: "Cedarbluff",
  },
  {
    postal_code: "16049",
    full_city_name: "Parker, PA",
    city_name: "Parker",
  },
  {
    postal_code: "55935",
    full_city_name: "Fountain, MN",
    city_name: "Fountain",
  },
  {
    postal_code: "24282",
    full_city_name: "Saint Charles, VA",
    city_name: "Saint Charles",
  },
  {
    postal_code: "74563",
    full_city_name: "Red Oak, OK",
    city_name: "Red Oak",
  },
  {
    postal_code: "99167",
    full_city_name: "Rice, WA",
    city_name: "Rice",
  },
  {
    postal_code: "77615",
    full_city_name: "Evadale, TX",
    city_name: "Evadale",
  },
  {
    postal_code: "96034",
    full_city_name: "Gazelle, CA",
    city_name: "Gazelle",
  },
  {
    postal_code: "76379",
    full_city_name: "Scotland, TX",
    city_name: "Scotland",
  },
  {
    postal_code: "04635",
    full_city_name: "Frenchboro, ME",
    city_name: "Frenchboro",
  },
  {
    postal_code: "99348",
    full_city_name: "Prescott, WA",
    city_name: "Prescott",
  },
  {
    postal_code: "26222",
    full_city_name: "Hacker Valley, WV",
    city_name: "Hacker Valley",
  },
  {
    postal_code: "33122",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "64842",
    full_city_name: "Fairview, MO",
    city_name: "Fairview",
  },
  {
    postal_code: "23967",
    full_city_name: "Saxe, VA",
    city_name: "Saxe",
  },
  {
    postal_code: "41537",
    full_city_name: "Jenkins, KY",
    city_name: "Jenkins",
  },
  {
    postal_code: "24726",
    full_city_name: "Herndon, WV",
    city_name: "Herndon",
  },
  {
    postal_code: "25446",
    full_city_name: "Summit Point, WV",
    city_name: "Summit Point",
  },
  {
    postal_code: "17881",
    full_city_name: "Trevorton, PA",
    city_name: "Trevorton",
  },
  {
    postal_code: "87028",
    full_city_name: "La Joya, NM",
    city_name: "La Joya",
  },
  {
    postal_code: "41855",
    full_city_name: "Thornton, KY",
    city_name: "Thornton",
  },
  {
    postal_code: "50066",
    full_city_name: "Dawson, IA",
    city_name: "Dawson",
  },
  {
    postal_code: "71101",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "51031",
    full_city_name: "Le Mars, IA",
    city_name: "Le Mars",
  },
  {
    postal_code: "76862",
    full_city_name: "Millersview, TX",
    city_name: "Millersview",
  },
  {
    postal_code: "87413",
    full_city_name: "Bloomfield, NM",
    city_name: "Bloomfield",
  },
  {
    postal_code: "39356",
    full_city_name: "Rose Hill, MS",
    city_name: "Rose Hill",
  },
  {
    postal_code: "31714",
    full_city_name: "Ashburn, GA",
    city_name: "Ashburn",
  },
  {
    postal_code: "48502",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "40040",
    full_city_name: "Mackville, KY",
    city_name: "Mackville",
  },
  {
    postal_code: "38643",
    full_city_name: "Lambert, MS",
    city_name: "Lambert",
  },
  {
    postal_code: "15428",
    full_city_name: "Dawson, PA",
    city_name: "Dawson",
  },
  {
    postal_code: "22812",
    full_city_name: "Bridgewater, VA",
    city_name: "Bridgewater",
  },
  {
    postal_code: "52765",
    full_city_name: "New Liberty, IA",
    city_name: "New Liberty",
  },
  {
    postal_code: "31006",
    full_city_name: "Butler, GA",
    city_name: "Butler",
  },
  {
    postal_code: "36613",
    full_city_name: "Eight Mile, AL",
    city_name: "Eight Mile",
  },
  {
    postal_code: "15753",
    full_city_name: "La Jose, PA",
    city_name: "La Jose",
  },
  {
    postal_code: "98403",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "14785",
    full_city_name: "Stow, NY",
    city_name: "Stow",
  },
  {
    postal_code: "53144",
    full_city_name: "Kenosha, WI",
    city_name: "Kenosha",
  },
  {
    postal_code: "59443",
    full_city_name: "Fort Shaw, MT",
    city_name: "Fort Shaw",
  },
  {
    postal_code: "61317",
    full_city_name: "Cherry, IL",
    city_name: "Cherry",
  },
  {
    postal_code: "57025",
    full_city_name: "Elk Point, SD",
    city_name: "Elk Point",
  },
  {
    postal_code: "50423",
    full_city_name: "Britt, IA",
    city_name: "Britt",
  },
  {
    postal_code: "49726",
    full_city_name: "Drummond Island, MI",
    city_name: "Drummond Island",
  },
  {
    postal_code: "72373",
    full_city_name: "Parkin, AR",
    city_name: "Parkin",
  },
  {
    postal_code: "49777",
    full_city_name: "Presque Isle, MI",
    city_name: "Presque Isle",
  },
  {
    postal_code: "73641",
    full_city_name: "Dill City, OK",
    city_name: "Dill City",
  },
  {
    postal_code: "78040",
    full_city_name: "Laredo, TX",
    city_name: "Laredo",
  },
  {
    postal_code: "37380",
    full_city_name: "South Pittsburg, TN",
    city_name: "South Pittsburg",
  },
  {
    postal_code: "38665",
    full_city_name: "Sarah, MS",
    city_name: "Sarah",
  },
  {
    postal_code: "49082",
    full_city_name: "Quincy, MI",
    city_name: "Quincy",
  },
  {
    postal_code: "29105",
    full_city_name: "Monetta, SC",
    city_name: "Monetta",
  },
  {
    postal_code: "50650",
    full_city_name: "Lamont, IA",
    city_name: "Lamont",
  },
  {
    postal_code: "61810",
    full_city_name: "Allerton, IL",
    city_name: "Allerton",
  },
  {
    postal_code: "57779",
    full_city_name: "Saint Onge, SD",
    city_name: "Saint Onge",
  },
  {
    postal_code: "53801",
    full_city_name: "Bagley, WI",
    city_name: "Bagley",
  },
  {
    postal_code: "38257",
    full_city_name: "South Fulton, TN",
    city_name: "South Fulton",
  },
  {
    postal_code: "50439",
    full_city_name: "Goodell, IA",
    city_name: "Goodell",
  },
  {
    postal_code: "49410",
    full_city_name: "Fountain, MI",
    city_name: "Fountain",
  },
  {
    postal_code: "62433",
    full_city_name: "Hutsonville, IL",
    city_name: "Hutsonville",
  },
  {
    postal_code: "45779",
    full_city_name: "Syracuse, OH",
    city_name: "Syracuse",
  },
  {
    postal_code: "07081",
    full_city_name: "Springfield, NJ",
    city_name: "Springfield",
  },
  {
    postal_code: "30256",
    full_city_name: "Meansville, GA",
    city_name: "Meansville",
  },
  {
    postal_code: "68869",
    full_city_name: "Ravenna, NE",
    city_name: "Ravenna",
  },
  {
    postal_code: "36344",
    full_city_name: "Hartford, AL",
    city_name: "Hartford",
  },
  {
    postal_code: "19736",
    full_city_name: "Yorklyn, DE",
    city_name: "Yorklyn",
  },
  {
    postal_code: "14571",
    full_city_name: "Waterport, NY",
    city_name: "Waterport",
  },
  {
    postal_code: "41751",
    full_city_name: "Jeff, KY",
    city_name: "Jeff",
  },
  {
    postal_code: "45672",
    full_city_name: "Ray, OH",
    city_name: "Ray",
  },
  {
    postal_code: "27964",
    full_city_name: "Point Harbor, NC",
    city_name: "Point Harbor",
  },
  {
    postal_code: "37317",
    full_city_name: "Copperhill, TN",
    city_name: "Copperhill",
  },
  {
    postal_code: "72366",
    full_city_name: "Marvell, AR",
    city_name: "Marvell",
  },
  {
    postal_code: "24292",
    full_city_name: "Whitetop, VA",
    city_name: "Whitetop",
  },
  {
    postal_code: "99146",
    full_city_name: "Laurier, WA",
    city_name: "Laurier",
  },
  {
    postal_code: "70652",
    full_city_name: "Longville, LA",
    city_name: "Longville",
  },
  {
    postal_code: "80652",
    full_city_name: "Roggen, CO",
    city_name: "Roggen",
  },
  {
    postal_code: "31305",
    full_city_name: "Darien, GA",
    city_name: "Darien",
  },
  {
    postal_code: "62817",
    full_city_name: "Broughton, IL",
    city_name: "Broughton",
  },
  {
    postal_code: "84338",
    full_city_name: "Trenton, UT",
    city_name: "Trenton",
  },
  {
    postal_code: "88321",
    full_city_name: "Encino, NM",
    city_name: "Encino",
  },
  {
    postal_code: "62451",
    full_city_name: "Palestine, IL",
    city_name: "Palestine",
  },
  {
    postal_code: "29853",
    full_city_name: "Williston, SC",
    city_name: "Williston",
  },
  {
    postal_code: "25088",
    full_city_name: "Glen, WV",
    city_name: "Glen",
  },
  {
    postal_code: "68710",
    full_city_name: "Allen, NE",
    city_name: "Allen",
  },
  {
    postal_code: "17066",
    full_city_name: "Mount Union, PA",
    city_name: "Mount Union",
  },
  {
    postal_code: "63827",
    full_city_name: "Bragg City, MO",
    city_name: "Bragg City",
  },
  {
    postal_code: "26202",
    full_city_name: "Fenwick, WV",
    city_name: "Fenwick",
  },
  {
    postal_code: "62053",
    full_city_name: "Kampsville, IL",
    city_name: "Kampsville",
  },
  {
    postal_code: "15476",
    full_city_name: "Ronco, PA",
    city_name: "Ronco",
  },
  {
    postal_code: "14481",
    full_city_name: "Leicester, NY",
    city_name: "Leicester",
  },
  {
    postal_code: "13450",
    full_city_name: "Roseboom, NY",
    city_name: "Roseboom",
  },
  {
    postal_code: "61731",
    full_city_name: "Cropsey, IL",
    city_name: "Cropsey",
  },
  {
    postal_code: "36830",
    full_city_name: "Auburn, AL",
    city_name: "Auburn",
  },
  {
    postal_code: "63558",
    full_city_name: "New Cambria, MO",
    city_name: "New Cambria",
  },
  {
    postal_code: "42024",
    full_city_name: "Barlow, KY",
    city_name: "Barlow",
  },
  {
    postal_code: "73842",
    full_city_name: "Freedom, OK",
    city_name: "Freedom",
  },
  {
    postal_code: "57047",
    full_city_name: "Monroe, SD",
    city_name: "Monroe",
  },
  {
    postal_code: "23307",
    full_city_name: "Birdsnest, VA",
    city_name: "Birdsnest",
  },
  {
    postal_code: "39078",
    full_city_name: "Georgetown, MS",
    city_name: "Georgetown",
  },
  {
    postal_code: "71660",
    full_city_name: "New Edinburg, AR",
    city_name: "New Edinburg",
  },
  {
    postal_code: "18636",
    full_city_name: "Noxen, PA",
    city_name: "Noxen",
  },
  {
    postal_code: "29210",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "35016",
    full_city_name: "Arab, AL",
    city_name: "Arab",
  },
  {
    postal_code: "84069",
    full_city_name: "Rush Valley, UT",
    city_name: "Rush Valley",
  },
  {
    postal_code: "48885",
    full_city_name: "Sidney, MI",
    city_name: "Sidney",
  },
  {
    postal_code: "25260",
    full_city_name: "Mason, WV",
    city_name: "Mason",
  },
  {
    postal_code: "87514",
    full_city_name: "Arroyo Seco, NM",
    city_name: "Arroyo Seco",
  },
  {
    postal_code: "28909",
    full_city_name: "Warne, NC",
    city_name: "Warne",
  },
  {
    postal_code: "08836",
    full_city_name: "Martinsville, NJ",
    city_name: "Martinsville",
  },
  {
    postal_code: "27862",
    full_city_name: "Pendleton, NC",
    city_name: "Pendleton",
  },
  {
    postal_code: "68415",
    full_city_name: "Odell, NE",
    city_name: "Odell",
  },
  {
    postal_code: "73009",
    full_city_name: "Binger, OK",
    city_name: "Binger",
  },
  {
    postal_code: "01370",
    full_city_name: "Shelburne Falls, MA",
    city_name: "Shelburne Falls",
  },
  {
    postal_code: "80478",
    full_city_name: "Tabernash, CO",
    city_name: "Tabernash",
  },
  {
    postal_code: "50578",
    full_city_name: "Ringsted, IA",
    city_name: "Ringsted",
  },
  {
    postal_code: "63457",
    full_city_name: "Monticello, MO",
    city_name: "Monticello",
  },
  {
    postal_code: "70723",
    full_city_name: "Convent, LA",
    city_name: "Convent",
  },
  {
    postal_code: "15446",
    full_city_name: "Indian Head, PA",
    city_name: "Indian Head",
  },
  {
    postal_code: "26570",
    full_city_name: "Fairview, WV",
    city_name: "Fairview",
  },
  {
    postal_code: "47137",
    full_city_name: "Leavenworth, IN",
    city_name: "Leavenworth",
  },
  {
    postal_code: "14065",
    full_city_name: "Freedom, NY",
    city_name: "Freedom",
  },
  {
    postal_code: "11439",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "52307",
    full_city_name: "Middle Amana, IA",
    city_name: "Middle Amana",
  },
  {
    postal_code: "73852",
    full_city_name: "Mooreland, OK",
    city_name: "Mooreland",
  },
  {
    postal_code: "25140",
    full_city_name: "Naoma, WV",
    city_name: "Naoma",
  },
  {
    postal_code: "42343",
    full_city_name: "Fordsville, KY",
    city_name: "Fordsville",
  },
  {
    postal_code: "41605",
    full_city_name: "Betsy Layne, KY",
    city_name: "Betsy Layne",
  },
  {
    postal_code: "65016",
    full_city_name: "Bonnots Mill, MO",
    city_name: "Bonnots Mill",
  },
  {
    postal_code: "99714",
    full_city_name: "Salcha, AK",
    city_name: "Salcha",
  },
  {
    postal_code: "54448",
    full_city_name: "Marathon, WI",
    city_name: "Marathon",
  },
  {
    postal_code: "28618",
    full_city_name: "Deep Gap, NC",
    city_name: "Deep Gap",
  },
  {
    postal_code: "34739",
    full_city_name: "Kenansville, FL",
    city_name: "Kenansville",
  },
  {
    postal_code: "31733",
    full_city_name: "Chula, GA",
    city_name: "Chula",
  },
  {
    postal_code: "20621",
    full_city_name: "Chaptico, MD",
    city_name: "Chaptico",
  },
  {
    postal_code: "58765",
    full_city_name: "Noonan, ND",
    city_name: "Noonan",
  },
  {
    postal_code: "71227",
    full_city_name: "Choudrant, LA",
    city_name: "Choudrant",
  },
  {
    postal_code: "50707",
    full_city_name: "Evansdale, IA",
    city_name: "Evansdale",
  },
  {
    postal_code: "82213",
    full_city_name: "Glendo, WY",
    city_name: "Glendo",
  },
  {
    postal_code: "46788",
    full_city_name: "Spencerville, IN",
    city_name: "Spencerville",
  },
  {
    postal_code: "77560",
    full_city_name: "Hankamer, TX",
    city_name: "Hankamer",
  },
  {
    postal_code: "84063",
    full_city_name: "Randlett, UT",
    city_name: "Randlett",
  },
  {
    postal_code: "73016",
    full_city_name: "Cashion, OK",
    city_name: "Cashion",
  },
  {
    postal_code: "83876",
    full_city_name: "Worley, ID",
    city_name: "Worley",
  },
  {
    postal_code: "84756",
    full_city_name: "Newcastle, UT",
    city_name: "Newcastle",
  },
  {
    postal_code: "58223",
    full_city_name: "Cummings, ND",
    city_name: "Cummings",
  },
  {
    postal_code: "46764",
    full_city_name: "Larwill, IN",
    city_name: "Larwill",
  },
  {
    postal_code: "48414",
    full_city_name: "Bancroft, MI",
    city_name: "Bancroft",
  },
  {
    postal_code: "72948",
    full_city_name: "Natural Dam, AR",
    city_name: "Natural Dam",
  },
  {
    postal_code: "72152",
    full_city_name: "Sherrill, AR",
    city_name: "Sherrill",
  },
  {
    postal_code: "29639",
    full_city_name: "Due West, SC",
    city_name: "Due West",
  },
  {
    postal_code: "36912",
    full_city_name: "Lisman, AL",
    city_name: "Lisman",
  },
  {
    postal_code: "84751",
    full_city_name: "Milford, UT",
    city_name: "Milford",
  },
  {
    postal_code: "16651",
    full_city_name: "Houtzdale, PA",
    city_name: "Houtzdale",
  },
  {
    postal_code: "74640",
    full_city_name: "Hunter, OK",
    city_name: "Hunter",
  },
  {
    postal_code: "62469",
    full_city_name: "Trilla, IL",
    city_name: "Trilla",
  },
  {
    postal_code: "57356",
    full_city_name: "Lake Andes, SD",
    city_name: "Lake Andes",
  },
  {
    postal_code: "56713",
    full_city_name: "Argyle, MN",
    city_name: "Argyle",
  },
  {
    postal_code: "39766",
    full_city_name: "Steens, MS",
    city_name: "Steens",
  },
  {
    postal_code: "45886",
    full_city_name: "Scott, OH",
    city_name: "Scott",
  },
  {
    postal_code: "94567",
    full_city_name: "Pope Valley, CA",
    city_name: "Pope Valley",
  },
  {
    postal_code: "97358",
    full_city_name: "Lyons, OR",
    city_name: "Lyons",
  },
  {
    postal_code: "26075",
    full_city_name: "Windsor Heights, WV",
    city_name: "Windsor Heights",
  },
  {
    postal_code: "67631",
    full_city_name: "Collyer, KS",
    city_name: "Collyer",
  },
  {
    postal_code: "51047",
    full_city_name: "Peterson, IA",
    city_name: "Peterson",
  },
  {
    postal_code: "35463",
    full_city_name: "Fosters, AL",
    city_name: "Fosters",
  },
  {
    postal_code: "66968",
    full_city_name: "Washington, KS",
    city_name: "Washington",
  },
  {
    postal_code: "58545",
    full_city_name: "Hazen, ND",
    city_name: "Hazen",
  },
  {
    postal_code: "84076",
    full_city_name: "Tridell, UT",
    city_name: "Tridell",
  },
  {
    postal_code: "31743",
    full_city_name: "De Soto, GA",
    city_name: "De Soto",
  },
  {
    postal_code: "78107",
    full_city_name: "Berclair, TX",
    city_name: "Berclair",
  },
  {
    postal_code: "99125",
    full_city_name: "Endicott, WA",
    city_name: "Endicott",
  },
  {
    postal_code: "84643",
    full_city_name: "Mayfield, UT",
    city_name: "Mayfield",
  },
  {
    postal_code: "98570",
    full_city_name: "Onalaska, WA",
    city_name: "Onalaska",
  },
  {
    postal_code: "43548",
    full_city_name: "New Bavaria, OH",
    city_name: "New Bavaria",
  },
  {
    postal_code: "14877",
    full_city_name: "Rexville, NY",
    city_name: "Rexville",
  },
  {
    postal_code: "97441",
    full_city_name: "Gardiner, OR",
    city_name: "Gardiner",
  },
  {
    postal_code: "18331",
    full_city_name: "Gilbert, PA",
    city_name: "Gilbert",
  },
  {
    postal_code: "83637",
    full_city_name: "Lowman, ID",
    city_name: "Lowman",
  },
  {
    postal_code: "36720",
    full_city_name: "Alberta, AL",
    city_name: "Alberta",
  },
  {
    postal_code: "32438",
    full_city_name: "Fountain, FL",
    city_name: "Fountain",
  },
  {
    postal_code: "64855",
    full_city_name: "Oronogo, MO",
    city_name: "Oronogo",
  },
  {
    postal_code: "13202",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "24474",
    full_city_name: "Selma, VA",
    city_name: "Selma",
  },
  {
    postal_code: "38318",
    full_city_name: "Buena Vista, TN",
    city_name: "Buena Vista",
  },
  {
    postal_code: "54857",
    full_city_name: "Mikana, WI",
    city_name: "Mikana",
  },
  {
    postal_code: "73150",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "52140",
    full_city_name: "Dorchester, IA",
    city_name: "Dorchester",
  },
  {
    postal_code: "17888",
    full_city_name: "Wilburton, PA",
    city_name: "Wilburton",
  },
  {
    postal_code: "55602",
    full_city_name: "Brimson, MN",
    city_name: "Brimson",
  },
  {
    postal_code: "58063",
    full_city_name: "Oriska, ND",
    city_name: "Oriska",
  },
  {
    postal_code: "67752",
    full_city_name: "Quinter, KS",
    city_name: "Quinter",
  },
  {
    postal_code: "68359",
    full_city_name: "Friend, NE",
    city_name: "Friend",
  },
  {
    postal_code: "73095",
    full_city_name: "Wayne, OK",
    city_name: "Wayne",
  },
  {
    postal_code: "87713",
    full_city_name: "Chacon, NM",
    city_name: "Chacon",
  },
  {
    postal_code: "71243",
    full_city_name: "Fort Necessity, LA",
    city_name: "Fort Necessity",
  },
  {
    postal_code: "37356",
    full_city_name: "Monteagle, TN",
    city_name: "Monteagle",
  },
  {
    postal_code: "57263",
    full_city_name: "South Shore, SD",
    city_name: "South Shore",
  },
  {
    postal_code: "05858",
    full_city_name: "North Concord, VT",
    city_name: "North Concord",
  },
  {
    postal_code: "15774",
    full_city_name: "Shelocta, PA",
    city_name: "Shelocta",
  },
  {
    postal_code: "57022",
    full_city_name: "Dell Rapids, SD",
    city_name: "Dell Rapids",
  },
  {
    postal_code: "60912",
    full_city_name: "Beaverville, IL",
    city_name: "Beaverville",
  },
  {
    postal_code: "28452",
    full_city_name: "Longwood, NC",
    city_name: "Longwood",
  },
  {
    postal_code: "07304",
    full_city_name: "Jersey City, NJ",
    city_name: "Jersey City",
  },
  {
    postal_code: "64490",
    full_city_name: "Stewartsville, MO",
    city_name: "Stewartsville",
  },
  {
    postal_code: "72301",
    full_city_name: "West Memphis, AR",
    city_name: "West Memphis",
  },
  {
    postal_code: "84072",
    full_city_name: "Tabiona, UT",
    city_name: "Tabiona",
  },
  {
    postal_code: "72666",
    full_city_name: "Parthenon, AR",
    city_name: "Parthenon",
  },
  {
    postal_code: "63474",
    full_city_name: "Wyaconda, MO",
    city_name: "Wyaconda",
  },
  {
    postal_code: "64857",
    full_city_name: "Purcell, MO",
    city_name: "Purcell",
  },
  {
    postal_code: "54651",
    full_city_name: "Ontario, WI",
    city_name: "Ontario",
  },
  {
    postal_code: "37753",
    full_city_name: "Hartford, TN",
    city_name: "Hartford",
  },
  {
    postal_code: "79322",
    full_city_name: "Crosbyton, TX",
    city_name: "Crosbyton",
  },
  {
    postal_code: "87752",
    full_city_name: "Wagon Mound, NM",
    city_name: "Wagon Mound",
  },
  {
    postal_code: "25062",
    full_city_name: "Dry Creek, WV",
    city_name: "Dry Creek",
  },
  {
    postal_code: "36580",
    full_city_name: "Summerdale, AL",
    city_name: "Summerdale",
  },
  {
    postal_code: "72160",
    full_city_name: "Stuttgart, AR",
    city_name: "Stuttgart",
  },
  {
    postal_code: "16943",
    full_city_name: "Sabinsville, PA",
    city_name: "Sabinsville",
  },
  {
    postal_code: "47114",
    full_city_name: "Crandall, IN",
    city_name: "Crandall",
  },
  {
    postal_code: "87419",
    full_city_name: "Navajo Dam, NM",
    city_name: "Navajo Dam",
  },
  {
    postal_code: "68743",
    full_city_name: "Jackson, NE",
    city_name: "Jackson",
  },
  {
    postal_code: "80835",
    full_city_name: "Simla, CO",
    city_name: "Simla",
  },
  {
    postal_code: "67842",
    full_city_name: "Ford, KS",
    city_name: "Ford",
  },
  {
    postal_code: "31007",
    full_city_name: "Byromville, GA",
    city_name: "Byromville",
  },
  {
    postal_code: "71862",
    full_city_name: "Washington, AR",
    city_name: "Washington",
  },
  {
    postal_code: "81023",
    full_city_name: "Beulah, CO",
    city_name: "Beulah",
  },
  {
    postal_code: "46539",
    full_city_name: "Mentone, IN",
    city_name: "Mentone",
  },
  {
    postal_code: "68424",
    full_city_name: "Plymouth, NE",
    city_name: "Plymouth",
  },
  {
    postal_code: "98222",
    full_city_name: "Blakely Island, WA",
    city_name: "Blakely Island",
  },
  {
    postal_code: "58321",
    full_city_name: "Brocket, ND",
    city_name: "Brocket",
  },
  {
    postal_code: "99730",
    full_city_name: "Central, AK",
    city_name: "Central",
  },
  {
    postal_code: "64741",
    full_city_name: "Deerfield, MO",
    city_name: "Deerfield",
  },
  {
    postal_code: "35183",
    full_city_name: "Weogufka, AL",
    city_name: "Weogufka",
  },
  {
    postal_code: "54430",
    full_city_name: "Elton, WI",
    city_name: "Elton",
  },
  {
    postal_code: "99780",
    full_city_name: "Tok, AK",
    city_name: "Tok",
  },
  {
    postal_code: "31323",
    full_city_name: "Riceboro, GA",
    city_name: "Riceboro",
  },
  {
    postal_code: "07422",
    full_city_name: "Highland Lakes, NJ",
    city_name: "Highland Lakes",
  },
  {
    postal_code: "31753",
    full_city_name: "Funston, GA",
    city_name: "Funston",
  },
  {
    postal_code: "07401",
    full_city_name: "Allendale, NJ",
    city_name: "Allendale",
  },
  {
    postal_code: "25977",
    full_city_name: "Meadow Creek, WV",
    city_name: "Meadow Creek",
  },
  {
    postal_code: "40915",
    full_city_name: "Bimble, KY",
    city_name: "Bimble",
  },
  {
    postal_code: "99689",
    full_city_name: "Yakutat, AK",
    city_name: "Yakutat",
  },
  {
    postal_code: "07072",
    full_city_name: "Carlstadt, NJ",
    city_name: "Carlstadt",
  },
  {
    postal_code: "82832",
    full_city_name: "Banner, WY",
    city_name: "Banner",
  },
  {
    postal_code: "53404",
    full_city_name: "Racine, WI",
    city_name: "Racine",
  },
  {
    postal_code: "62807",
    full_city_name: "Alma, IL",
    city_name: "Alma",
  },
  {
    postal_code: "41238",
    full_city_name: "Oil Springs, KY",
    city_name: "Oil Springs",
  },
  {
    postal_code: "98336",
    full_city_name: "Glenoma, WA",
    city_name: "Glenoma",
  },
  {
    postal_code: "78872",
    full_city_name: "La Pryor, TX",
    city_name: "La Pryor",
  },
  {
    postal_code: "18842",
    full_city_name: "South Gibson, PA",
    city_name: "South Gibson",
  },
  {
    postal_code: "45695",
    full_city_name: "Wilkesville, OH",
    city_name: "Wilkesville",
  },
  {
    postal_code: "58456",
    full_city_name: "Kulm, ND",
    city_name: "Kulm",
  },
  {
    postal_code: "68329",
    full_city_name: "Cook, NE",
    city_name: "Cook",
  },
  {
    postal_code: "96786",
    full_city_name: "Wahiawa, HI",
    city_name: "Wahiawa",
  },
  {
    postal_code: "19980",
    full_city_name: "Woodside, DE",
    city_name: "Woodside",
  },
  {
    postal_code: "72826",
    full_city_name: "Blue Mountain, AR",
    city_name: "Blue Mountain",
  },
  {
    postal_code: "24315",
    full_city_name: "Bland, VA",
    city_name: "Bland",
  },
  {
    postal_code: "16640",
    full_city_name: "Flinton, PA",
    city_name: "Flinton",
  },
  {
    postal_code: "56680",
    full_city_name: "Spring Lake, MN",
    city_name: "Spring Lake",
  },
  {
    postal_code: "97859",
    full_city_name: "Meacham, OR",
    city_name: "Meacham",
  },
  {
    postal_code: "26801",
    full_city_name: "Baker, WV",
    city_name: "Baker",
  },
  {
    postal_code: "46941",
    full_city_name: "Lagro, IN",
    city_name: "Lagro",
  },
  {
    postal_code: "24977",
    full_city_name: "Smoot, WV",
    city_name: "Smoot",
  },
  {
    postal_code: "41214",
    full_city_name: "Debord, KY",
    city_name: "Debord",
  },
  {
    postal_code: "72534",
    full_city_name: "Floral, AR",
    city_name: "Floral",
  },
  {
    postal_code: "48762",
    full_city_name: "Spruce, MI",
    city_name: "Spruce",
  },
  {
    postal_code: "61924",
    full_city_name: "Chrisman, IL",
    city_name: "Chrisman",
  },
  {
    postal_code: "49404",
    full_city_name: "Coopersville, MI",
    city_name: "Coopersville",
  },
  {
    postal_code: "16254",
    full_city_name: "Shippenville, PA",
    city_name: "Shippenville",
  },
  {
    postal_code: "70668",
    full_city_name: "Vinton, LA",
    city_name: "Vinton",
  },
  {
    postal_code: "68338",
    full_city_name: "Daykin, NE",
    city_name: "Daykin",
  },
  {
    postal_code: "25133",
    full_city_name: "Maysel, WV",
    city_name: "Maysel",
  },
  {
    postal_code: "13635",
    full_city_name: "Edwards, NY",
    city_name: "Edwards",
  },
  {
    postal_code: "51545",
    full_city_name: "Little Sioux, IA",
    city_name: "Little Sioux",
  },
  {
    postal_code: "48865",
    full_city_name: "Orleans, MI",
    city_name: "Orleans",
  },
  {
    postal_code: "66414",
    full_city_name: "Carbondale, KS",
    city_name: "Carbondale",
  },
  {
    postal_code: "21153",
    full_city_name: "Stevenson, MD",
    city_name: "Stevenson",
  },
  {
    postal_code: "50438",
    full_city_name: "Garner, IA",
    city_name: "Garner",
  },
  {
    postal_code: "70426",
    full_city_name: "Angie, LA",
    city_name: "Angie",
  },
  {
    postal_code: "26218",
    full_city_name: "French Creek, WV",
    city_name: "French Creek",
  },
  {
    postal_code: "80024",
    full_city_name: "Dupont, CO",
    city_name: "Dupont",
  },
  {
    postal_code: "27014",
    full_city_name: "Cooleemee, NC",
    city_name: "Cooleemee",
  },
  {
    postal_code: "70753",
    full_city_name: "Lettsworth, LA",
    city_name: "Lettsworth",
  },
  {
    postal_code: "59915",
    full_city_name: "Elmo, MT",
    city_name: "Elmo",
  },
  {
    postal_code: "13148",
    full_city_name: "Seneca Falls, NY",
    city_name: "Seneca Falls",
  },
  {
    postal_code: "54525",
    full_city_name: "Gile, WI",
    city_name: "Gile",
  },
  {
    postal_code: "24442",
    full_city_name: "Head Waters, VA",
    city_name: "Head Waters",
  },
  {
    postal_code: "65725",
    full_city_name: "Pleasant Hope, MO",
    city_name: "Pleasant Hope",
  },
  {
    postal_code: "79533",
    full_city_name: "Lueders, TX",
    city_name: "Lueders",
  },
  {
    postal_code: "81049",
    full_city_name: "Kim, CO",
    city_name: "Kim",
  },
  {
    postal_code: "50482",
    full_city_name: "Ventura, IA",
    city_name: "Ventura",
  },
  {
    postal_code: "35766",
    full_city_name: "Princeton, AL",
    city_name: "Princeton",
  },
  {
    postal_code: "18602",
    full_city_name: "Bear Creek, PA",
    city_name: "Bear Creek",
  },
  {
    postal_code: "98358",
    full_city_name: "Nordland, WA",
    city_name: "Nordland",
  },
  {
    postal_code: "15750",
    full_city_name: "Josephine, PA",
    city_name: "Josephine",
  },
  {
    postal_code: "61741",
    full_city_name: "Forrest, IL",
    city_name: "Forrest",
  },
  {
    postal_code: "74030",
    full_city_name: "Drumright, OK",
    city_name: "Drumright",
  },
  {
    postal_code: "87548",
    full_city_name: "Medanales, NM",
    city_name: "Medanales",
  },
  {
    postal_code: "50464",
    full_city_name: "Plymouth, IA",
    city_name: "Plymouth",
  },
  {
    postal_code: "76372",
    full_city_name: "Newcastle, TX",
    city_name: "Newcastle",
  },
  {
    postal_code: "61332",
    full_city_name: "Leonore, IL",
    city_name: "Leonore",
  },
  {
    postal_code: "48096",
    full_city_name: "Ray, MI",
    city_name: "Ray",
  },
  {
    postal_code: "73528",
    full_city_name: "Chattanooga, OK",
    city_name: "Chattanooga",
  },
  {
    postal_code: "32577",
    full_city_name: "Molino, FL",
    city_name: "Molino",
  },
  {
    postal_code: "48807",
    full_city_name: "Bannister, MI",
    city_name: "Bannister",
  },
  {
    postal_code: "49917",
    full_city_name: "Copper City, MI",
    city_name: "Copper City",
  },
  {
    postal_code: "07086",
    full_city_name: "Weehawken, NJ",
    city_name: "Weehawken",
  },
  {
    postal_code: "26710",
    full_city_name: "Burlington, WV",
    city_name: "Burlington",
  },
  {
    postal_code: "07066",
    full_city_name: "Clark, NJ",
    city_name: "Clark",
  },
  {
    postal_code: "96069",
    full_city_name: "Oak Run, CA",
    city_name: "Oak Run",
  },
  {
    postal_code: "38621",
    full_city_name: "Crenshaw, MS",
    city_name: "Crenshaw",
  },
  {
    postal_code: "24079",
    full_city_name: "Copper Hill, VA",
    city_name: "Copper Hill",
  },
  {
    postal_code: "62672",
    full_city_name: "Nilwood, IL",
    city_name: "Nilwood",
  },
  {
    postal_code: "16691",
    full_city_name: "Wells Tannery, PA",
    city_name: "Wells Tannery",
  },
  {
    postal_code: "15334",
    full_city_name: "Garards Fort, PA",
    city_name: "Garards Fort",
  },
  {
    postal_code: "49338",
    full_city_name: "Paris, MI",
    city_name: "Paris",
  },
  {
    postal_code: "66543",
    full_city_name: "Vassar, KS",
    city_name: "Vassar",
  },
  {
    postal_code: "19550",
    full_city_name: "Rehrersburg, PA",
    city_name: "Rehrersburg",
  },
  {
    postal_code: "41512",
    full_city_name: "Ashcamp, KY",
    city_name: "Ashcamp",
  },
  {
    postal_code: "49679",
    full_city_name: "Sears, MI",
    city_name: "Sears",
  },
  {
    postal_code: "12512",
    full_city_name: "Chelsea, NY",
    city_name: "Chelsea",
  },
  {
    postal_code: "29052",
    full_city_name: "Gadsden, SC",
    city_name: "Gadsden",
  },
  {
    postal_code: "82331",
    full_city_name: "Saratoga, WY",
    city_name: "Saratoga",
  },
  {
    postal_code: "13410",
    full_city_name: "Nelliston, NY",
    city_name: "Nelliston",
  },
  {
    postal_code: "15848",
    full_city_name: "Luthersburg, PA",
    city_name: "Luthersburg",
  },
  {
    postal_code: "45768",
    full_city_name: "Newport, OH",
    city_name: "Newport",
  },
  {
    postal_code: "72553",
    full_city_name: "Magness, AR",
    city_name: "Magness",
  },
  {
    postal_code: "37166",
    full_city_name: "Smithville, TN",
    city_name: "Smithville",
  },
  {
    postal_code: "72565",
    full_city_name: "Oxford, AR",
    city_name: "Oxford",
  },
  {
    postal_code: "62345",
    full_city_name: "Kinderhook, IL",
    city_name: "Kinderhook",
  },
  {
    postal_code: "98933",
    full_city_name: "Harrah, WA",
    city_name: "Harrah",
  },
  {
    postal_code: "27042",
    full_city_name: "Pine Hall, NC",
    city_name: "Pine Hall",
  },
  {
    postal_code: "72584",
    full_city_name: "Violet Hill, AR",
    city_name: "Violet Hill",
  },
  {
    postal_code: "37360",
    full_city_name: "Normandy, TN",
    city_name: "Normandy",
  },
  {
    postal_code: "69131",
    full_city_name: "Dalton, NE",
    city_name: "Dalton",
  },
  {
    postal_code: "77613",
    full_city_name: "China, TX",
    city_name: "China",
  },
  {
    postal_code: "71260",
    full_city_name: "Marion, LA",
    city_name: "Marion",
  },
  {
    postal_code: "61911",
    full_city_name: "Arthur, IL",
    city_name: "Arthur",
  },
  {
    postal_code: "72823",
    full_city_name: "Atkins, AR",
    city_name: "Atkins",
  },
  {
    postal_code: "71427",
    full_city_name: "Flatwoods, LA",
    city_name: "Flatwoods",
  },
  {
    postal_code: "64449",
    full_city_name: "Fillmore, MO",
    city_name: "Fillmore",
  },
  {
    postal_code: "67215",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "42054",
    full_city_name: "Kirksey, KY",
    city_name: "Kirksey",
  },
  {
    postal_code: "74729",
    full_city_name: "Caddo, OK",
    city_name: "Caddo",
  },
  {
    postal_code: "47576",
    full_city_name: "Saint Croix, IN",
    city_name: "Saint Croix",
  },
  {
    postal_code: "71837",
    full_city_name: "Fouke, AR",
    city_name: "Fouke",
  },
  {
    postal_code: "71835",
    full_city_name: "Emmet, AR",
    city_name: "Emmet",
  },
  {
    postal_code: "50254",
    full_city_name: "Thayer, IA",
    city_name: "Thayer",
  },
  {
    postal_code: "36602",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "13649",
    full_city_name: "Helena, NY",
    city_name: "Helena",
  },
  {
    postal_code: "54537",
    full_city_name: "Kennan, WI",
    city_name: "Kennan",
  },
  {
    postal_code: "67567",
    full_city_name: "Pawnee Rock, KS",
    city_name: "Pawnee Rock",
  },
  {
    postal_code: "47925",
    full_city_name: "Buffalo, IN",
    city_name: "Buffalo",
  },
  {
    postal_code: "56110",
    full_city_name: "Adrian, MN",
    city_name: "Adrian",
  },
  {
    postal_code: "12416",
    full_city_name: "Chichester, NY",
    city_name: "Chichester",
  },
  {
    postal_code: "61759",
    full_city_name: "Minier, IL",
    city_name: "Minier",
  },
  {
    postal_code: "02815",
    full_city_name: "Clayville, RI",
    city_name: "Clayville",
  },
  {
    postal_code: "61452",
    full_city_name: "Littleton, IL",
    city_name: "Littleton",
  },
  {
    postal_code: "15961",
    full_city_name: "Vintondale, PA",
    city_name: "Vintondale",
  },
  {
    postal_code: "87418",
    full_city_name: "La Plata, NM",
    city_name: "La Plata",
  },
  {
    postal_code: "05821",
    full_city_name: "Barnet, VT",
    city_name: "Barnet",
  },
  {
    postal_code: "94946",
    full_city_name: "Nicasio, CA",
    city_name: "Nicasio",
  },
  {
    postal_code: "61440",
    full_city_name: "Industry, IL",
    city_name: "Industry",
  },
  {
    postal_code: "12779",
    full_city_name: "South Fallsburg, NY",
    city_name: "South Fallsburg",
  },
  {
    postal_code: "54820",
    full_city_name: "Brule, WI",
    city_name: "Brule",
  },
  {
    postal_code: "76429",
    full_city_name: "Caddo, TX",
    city_name: "Caddo",
  },
  {
    postal_code: "07621",
    full_city_name: "Bergenfield, NJ",
    city_name: "Bergenfield",
  },
  {
    postal_code: "88252",
    full_city_name: "Jal, NM",
    city_name: "Jal",
  },
  {
    postal_code: "50133",
    full_city_name: "Kellerton, IA",
    city_name: "Kellerton",
  },
  {
    postal_code: "56651",
    full_city_name: "Lengby, MN",
    city_name: "Lengby",
  },
  {
    postal_code: "59710",
    full_city_name: "Alder, MT",
    city_name: "Alder",
  },
  {
    postal_code: "24566",
    full_city_name: "Keeling, VA",
    city_name: "Keeling",
  },
  {
    postal_code: "47125",
    full_city_name: "Hardinsburg, IN",
    city_name: "Hardinsburg",
  },
  {
    postal_code: "87931",
    full_city_name: "Caballo, NM",
    city_name: "Caballo",
  },
  {
    postal_code: "45746",
    full_city_name: "Macksburg, OH",
    city_name: "Macksburg",
  },
  {
    postal_code: "58240",
    full_city_name: "Hatton, ND",
    city_name: "Hatton",
  },
  {
    postal_code: "63782",
    full_city_name: "Sturdivant, MO",
    city_name: "Sturdivant",
  },
  {
    postal_code: "41045",
    full_city_name: "Ghent, KY",
    city_name: "Ghent",
  },
  {
    postal_code: "72029",
    full_city_name: "Clarendon, AR",
    city_name: "Clarendon",
  },
  {
    postal_code: "07603",
    full_city_name: "Bogota, NJ",
    city_name: "Bogota",
  },
  {
    postal_code: "49901",
    full_city_name: "Ahmeek, MI",
    city_name: "Ahmeek",
  },
  {
    postal_code: "80757",
    full_city_name: "Woodrow, CO",
    city_name: "Woodrow",
  },
  {
    postal_code: "25075",
    full_city_name: "Eskdale, WV",
    city_name: "Eskdale",
  },
  {
    postal_code: "64862",
    full_city_name: "Sarcoxie, MO",
    city_name: "Sarcoxie",
  },
  {
    postal_code: "27016",
    full_city_name: "Danbury, NC",
    city_name: "Danbury",
  },
  {
    postal_code: "53188",
    full_city_name: "Waukesha, WI",
    city_name: "Waukesha",
  },
  {
    postal_code: "12411",
    full_city_name: "Bloomington, NY",
    city_name: "Bloomington",
  },
  {
    postal_code: "56134",
    full_city_name: "Hardwick, MN",
    city_name: "Hardwick",
  },
  {
    postal_code: "65705",
    full_city_name: "Marionville, MO",
    city_name: "Marionville",
  },
  {
    postal_code: "71259",
    full_city_name: "Mangham, LA",
    city_name: "Mangham",
  },
  {
    postal_code: "58856",
    full_city_name: "Zahl, ND",
    city_name: "Zahl",
  },
  {
    postal_code: "16837",
    full_city_name: "Glen Richey, PA",
    city_name: "Glen Richey",
  },
  {
    postal_code: "55782",
    full_city_name: "Soudan, MN",
    city_name: "Soudan",
  },
  {
    postal_code: "61955",
    full_city_name: "Vermilion, IL",
    city_name: "Vermilion",
  },
  {
    postal_code: "58783",
    full_city_name: "Souris, ND",
    city_name: "Souris",
  },
  {
    postal_code: "28683",
    full_city_name: "Thurmond, NC",
    city_name: "Thurmond",
  },
  {
    postal_code: "16633",
    full_city_name: "Defiance, PA",
    city_name: "Defiance",
  },
  {
    postal_code: "38650",
    full_city_name: "Myrtle, MS",
    city_name: "Myrtle",
  },
  {
    postal_code: "14057",
    full_city_name: "Eden, NY",
    city_name: "Eden",
  },
  {
    postal_code: "98339",
    full_city_name: "Port Hadlock, WA",
    city_name: "Port Hadlock",
  },
  {
    postal_code: "62846",
    full_city_name: "Ina, IL",
    city_name: "Ina",
  },
  {
    postal_code: "41085",
    full_city_name: "Silver Grove, KY",
    city_name: "Silver Grove",
  },
  {
    postal_code: "22134",
    full_city_name: "Quantico, VA",
    city_name: "Quantico",
  },
  {
    postal_code: "69029",
    full_city_name: "Farnam, NE",
    city_name: "Farnam",
  },
  {
    postal_code: "55749",
    full_city_name: "Holyoke, MN",
    city_name: "Holyoke",
  },
  {
    postal_code: "17266",
    full_city_name: "Walnut Bottom, PA",
    city_name: "Walnut Bottom",
  },
  {
    postal_code: "14476",
    full_city_name: "Kendall, NY",
    city_name: "Kendall",
  },
  {
    postal_code: "54855",
    full_city_name: "Marengo, WI",
    city_name: "Marengo",
  },
  {
    postal_code: "46530",
    full_city_name: "Granger, IN",
    city_name: "Granger",
  },
  {
    postal_code: "41832",
    full_city_name: "Letcher, KY",
    city_name: "Letcher",
  },
  {
    postal_code: "08324",
    full_city_name: "Heislerville, NJ",
    city_name: "Heislerville",
  },
  {
    postal_code: "99615",
    full_city_name: "Kodiak, AK",
    city_name: "Kodiak",
  },
  {
    postal_code: "70051",
    full_city_name: "Garyville, LA",
    city_name: "Garyville",
  },
  {
    postal_code: "07832",
    full_city_name: "Columbia, NJ",
    city_name: "Columbia",
  },
  {
    postal_code: "56710",
    full_city_name: "Alvarado, MN",
    city_name: "Alvarado",
  },
  {
    postal_code: "47847",
    full_city_name: "Dana, IN",
    city_name: "Dana",
  },
  {
    postal_code: "78359",
    full_city_name: "Gregory, TX",
    city_name: "Gregory",
  },
  {
    postal_code: "14507",
    full_city_name: "Middlesex, NY",
    city_name: "Middlesex",
  },
  {
    postal_code: "54639",
    full_city_name: "La Farge, WI",
    city_name: "La Farge",
  },
  {
    postal_code: "53961",
    full_city_name: "Rock Springs, WI",
    city_name: "Rock Springs",
  },
  {
    postal_code: "25039",
    full_city_name: "Cedar Grove, WV",
    city_name: "Cedar Grove",
  },
  {
    postal_code: "52574",
    full_city_name: "Mystic, IA",
    city_name: "Mystic",
  },
  {
    postal_code: "23480",
    full_city_name: "Wachapreague, VA",
    city_name: "Wachapreague",
  },
  {
    postal_code: "67951",
    full_city_name: "Hugoton, KS",
    city_name: "Hugoton",
  },
  {
    postal_code: "31055",
    full_city_name: "Mc Rae Helena, GA",
    city_name: "Mc Rae Helena",
  },
  {
    postal_code: "68714",
    full_city_name: "Bassett, NE",
    city_name: "Bassett",
  },
  {
    postal_code: "13668",
    full_city_name: "Norwood, NY",
    city_name: "Norwood",
  },
  {
    postal_code: "42733",
    full_city_name: "Elk Horn, KY",
    city_name: "Elk Horn",
  },
  {
    postal_code: "84640",
    full_city_name: "Lynndyl, UT",
    city_name: "Lynndyl",
  },
  {
    postal_code: "52231",
    full_city_name: "Harper, IA",
    city_name: "Harper",
  },
  {
    postal_code: "25817",
    full_city_name: "Bolt, WV",
    city_name: "Bolt",
  },
  {
    postal_code: "98822",
    full_city_name: "Entiat, WA",
    city_name: "Entiat",
  },
  {
    postal_code: "56276",
    full_city_name: "Odessa, MN",
    city_name: "Odessa",
  },
  {
    postal_code: "49779",
    full_city_name: "Rogers City, MI",
    city_name: "Rogers City",
  },
  {
    postal_code: "72573",
    full_city_name: "Sage, AR",
    city_name: "Sage",
  },
  {
    postal_code: "49760",
    full_city_name: "Moran, MI",
    city_name: "Moran",
  },
  {
    postal_code: "50612",
    full_city_name: "Buckingham, IA",
    city_name: "Buckingham",
  },
  {
    postal_code: "35951",
    full_city_name: "Albertville, AL",
    city_name: "Albertville",
  },
  {
    postal_code: "97905",
    full_city_name: "Durkee, OR",
    city_name: "Durkee",
  },
  {
    postal_code: "38617",
    full_city_name: "Coahoma, MS",
    city_name: "Coahoma",
  },
  {
    postal_code: "23021",
    full_city_name: "Bohannon, VA",
    city_name: "Bohannon",
  },
  {
    postal_code: "47231",
    full_city_name: "Dupont, IN",
    city_name: "Dupont",
  },
  {
    postal_code: "46146",
    full_city_name: "Homer, IN",
    city_name: "Homer",
  },
  {
    postal_code: "63867",
    full_city_name: "Matthews, MO",
    city_name: "Matthews",
  },
  {
    postal_code: "49423",
    full_city_name: "Holland, MI",
    city_name: "Holland",
  },
  {
    postal_code: "87565",
    full_city_name: "San Jose, NM",
    city_name: "San Jose",
  },
  {
    postal_code: "99504",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "67854",
    full_city_name: "Jetmore, KS",
    city_name: "Jetmore",
  },
  {
    postal_code: "57533",
    full_city_name: "Gregory, SD",
    city_name: "Gregory",
  },
  {
    postal_code: "51460",
    full_city_name: "Ricketts, IA",
    city_name: "Ricketts",
  },
  {
    postal_code: "71060",
    full_city_name: "Mooringsport, LA",
    city_name: "Mooringsport",
  },
  {
    postal_code: "29817",
    full_city_name: "Blackville, SC",
    city_name: "Blackville",
  },
  {
    postal_code: "26743",
    full_city_name: "New Creek, WV",
    city_name: "New Creek",
  },
  {
    postal_code: "45735",
    full_city_name: "Guysville, OH",
    city_name: "Guysville",
  },
  {
    postal_code: "89823",
    full_city_name: "Deeth, NV",
    city_name: "Deeth",
  },
  {
    postal_code: "65001",
    full_city_name: "Argyle, MO",
    city_name: "Argyle",
  },
  {
    postal_code: "52588",
    full_city_name: "Selma, IA",
    city_name: "Selma",
  },
  {
    postal_code: "37057",
    full_city_name: "Dixon Springs, TN",
    city_name: "Dixon Springs",
  },
  {
    postal_code: "99824",
    full_city_name: "Douglas, AK",
    city_name: "Douglas",
  },
  {
    postal_code: "63783",
    full_city_name: "Uniontown, MO",
    city_name: "Uniontown",
  },
  {
    postal_code: "61321",
    full_city_name: "Dana, IL",
    city_name: "Dana",
  },
  {
    postal_code: "41124",
    full_city_name: "Blaine, KY",
    city_name: "Blaine",
  },
  {
    postal_code: "51232",
    full_city_name: "Ashton, IA",
    city_name: "Ashton",
  },
  {
    postal_code: "39745",
    full_city_name: "French Camp, MS",
    city_name: "French Camp",
  },
  {
    postal_code: "18241",
    full_city_name: "Nuremberg, PA",
    city_name: "Nuremberg",
  },
  {
    postal_code: "50032",
    full_city_name: "Berwick, IA",
    city_name: "Berwick",
  },
  {
    postal_code: "81151",
    full_city_name: "Sanford, CO",
    city_name: "Sanford",
  },
  {
    postal_code: "66767",
    full_city_name: "Prescott, KS",
    city_name: "Prescott",
  },
  {
    postal_code: "52356",
    full_city_name: "Wellman, IA",
    city_name: "Wellman",
  },
  {
    postal_code: "51346",
    full_city_name: "Hartley, IA",
    city_name: "Hartley",
  },
  {
    postal_code: "45894",
    full_city_name: "Venedocia, OH",
    city_name: "Venedocia",
  },
  {
    postal_code: "16694",
    full_city_name: "Wood, PA",
    city_name: "Wood",
  },
  {
    postal_code: "57252",
    full_city_name: "Milbank, SD",
    city_name: "Milbank",
  },
  {
    postal_code: "37729",
    full_city_name: "Duff, TN",
    city_name: "Duff",
  },
  {
    postal_code: "36507",
    full_city_name: "Bay Minette, AL",
    city_name: "Bay Minette",
  },
  {
    postal_code: "51020",
    full_city_name: "Galva, IA",
    city_name: "Galva",
  },
  {
    postal_code: "15685",
    full_city_name: "Southwest, PA",
    city_name: "Southwest",
  },
  {
    postal_code: "50669",
    full_city_name: "Reinbeck, IA",
    city_name: "Reinbeck",
  },
  {
    postal_code: "42722",
    full_city_name: "Canmer, KY",
    city_name: "Canmer",
  },
  {
    postal_code: "25093",
    full_city_name: "Gordon, WV",
    city_name: "Gordon",
  },
  {
    postal_code: "39817",
    full_city_name: "Bainbridge, GA",
    city_name: "Bainbridge",
  },
  {
    postal_code: "53526",
    full_city_name: "Cobb, WI",
    city_name: "Cobb",
  },
  {
    postal_code: "83253",
    full_city_name: "May, ID",
    city_name: "May",
  },
  {
    postal_code: "16622",
    full_city_name: "Calvin, PA",
    city_name: "Calvin",
  },
  {
    postal_code: "56245",
    full_city_name: "Hanley Falls, MN",
    city_name: "Hanley Falls",
  },
  {
    postal_code: "54482",
    full_city_name: "Stevens Point, WI",
    city_name: "Stevens Point",
  },
  {
    postal_code: "76864",
    full_city_name: "Mullin, TX",
    city_name: "Mullin",
  },
  {
    postal_code: "12754",
    full_city_name: "Liberty, NY",
    city_name: "Liberty",
  },
  {
    postal_code: "54650",
    full_city_name: "Onalaska, WI",
    city_name: "Onalaska",
  },
  {
    postal_code: "95981",
    full_city_name: "Strawberry Valley, CA",
    city_name: "Strawberry Valley",
  },
  {
    postal_code: "57221",
    full_city_name: "Bryant, SD",
    city_name: "Bryant",
  },
  {
    postal_code: "50075",
    full_city_name: "Ellsworth, IA",
    city_name: "Ellsworth",
  },
  {
    postal_code: "51646",
    full_city_name: "New Market, IA",
    city_name: "New Market",
  },
  {
    postal_code: "85347",
    full_city_name: "Roll, AZ",
    city_name: "Roll",
  },
  {
    postal_code: "67667",
    full_city_name: "Schoenchen, KS",
    city_name: "Schoenchen",
  },
  {
    postal_code: "59638",
    full_city_name: "Jefferson City, MT",
    city_name: "Jefferson City",
  },
  {
    postal_code: "74534",
    full_city_name: "Centrahoma, OK",
    city_name: "Centrahoma",
  },
  {
    postal_code: "05742",
    full_city_name: "East Wallingford, VT",
    city_name: "East Wallingford",
  },
  {
    postal_code: "54484",
    full_city_name: "Stratford, WI",
    city_name: "Stratford",
  },
  {
    postal_code: "45820",
    full_city_name: "Cairo, OH",
    city_name: "Cairo",
  },
  {
    postal_code: "68346",
    full_city_name: "Dunbar, NE",
    city_name: "Dunbar",
  },
  {
    postal_code: "72641",
    full_city_name: "Jasper, AR",
    city_name: "Jasper",
  },
  {
    postal_code: "87821",
    full_city_name: "Datil, NM",
    city_name: "Datil",
  },
  {
    postal_code: "18354",
    full_city_name: "Sciota, PA",
    city_name: "Sciota",
  },
  {
    postal_code: "07632",
    full_city_name: "Englewood Cliffs, NJ",
    city_name: "Englewood Cliffs",
  },
  {
    postal_code: "81036",
    full_city_name: "Eads, CO",
    city_name: "Eads",
  },
  {
    postal_code: "28527",
    full_city_name: "Ernul, NC",
    city_name: "Ernul",
  },
  {
    postal_code: "47586",
    full_city_name: "Tell City, IN",
    city_name: "Tell City",
  },
  {
    postal_code: "72166",
    full_city_name: "Tichnor, AR",
    city_name: "Tichnor",
  },
  {
    postal_code: "62915",
    full_city_name: "Cambria, IL",
    city_name: "Cambria",
  },
  {
    postal_code: "56518",
    full_city_name: "Bluffton, MN",
    city_name: "Bluffton",
  },
  {
    postal_code: "53125",
    full_city_name: "Fontana, WI",
    city_name: "Fontana",
  },
  {
    postal_code: "39776",
    full_city_name: "Woodland, MS",
    city_name: "Woodland",
  },
  {
    postal_code: "27311",
    full_city_name: "Pelham, NC",
    city_name: "Pelham",
  },
  {
    postal_code: "75447",
    full_city_name: "Ivanhoe, TX",
    city_name: "Ivanhoe",
  },
  {
    postal_code: "10926",
    full_city_name: "Harriman, NY",
    city_name: "Harriman",
  },
  {
    postal_code: "56125",
    full_city_name: "Dovray, MN",
    city_name: "Dovray",
  },
  {
    postal_code: "65256",
    full_city_name: "Harrisburg, MO",
    city_name: "Harrisburg",
  },
  {
    postal_code: "16729",
    full_city_name: "Duke Center, PA",
    city_name: "Duke Center",
  },
  {
    postal_code: "50069",
    full_city_name: "De Soto, IA",
    city_name: "De Soto",
  },
  {
    postal_code: "62417",
    full_city_name: "Bridgeport, IL",
    city_name: "Bridgeport",
  },
  {
    postal_code: "60537",
    full_city_name: "Millington, IL",
    city_name: "Millington",
  },
  {
    postal_code: "50148",
    full_city_name: "Liscomb, IA",
    city_name: "Liscomb",
  },
  {
    postal_code: "71251",
    full_city_name: "Jonesboro, LA",
    city_name: "Jonesboro",
  },
  {
    postal_code: "80744",
    full_city_name: "Ovid, CO",
    city_name: "Ovid",
  },
  {
    postal_code: "72949",
    full_city_name: "Ozark, AR",
    city_name: "Ozark",
  },
  {
    postal_code: "93652",
    full_city_name: "Raisin City, CA",
    city_name: "Raisin City",
  },
  {
    postal_code: "67954",
    full_city_name: "Rolla, KS",
    city_name: "Rolla",
  },
  {
    postal_code: "68939",
    full_city_name: "Franklin, NE",
    city_name: "Franklin",
  },
  {
    postal_code: "83677",
    full_city_name: "Yellow Pine, ID",
    city_name: "Yellow Pine",
  },
  {
    postal_code: "65746",
    full_city_name: "Seymour, MO",
    city_name: "Seymour",
  },
  {
    postal_code: "43126",
    full_city_name: "Harrisburg, OH",
    city_name: "Harrisburg",
  },
  {
    postal_code: "47857",
    full_city_name: "Knightsville, IN",
    city_name: "Knightsville",
  },
  {
    postal_code: "04677",
    full_city_name: "Sorrento, ME",
    city_name: "Sorrento",
  },
  {
    postal_code: "82428",
    full_city_name: "Hyattville, WY",
    city_name: "Hyattville",
  },
  {
    postal_code: "59633",
    full_city_name: "Canyon Creek, MT",
    city_name: "Canyon Creek",
  },
  {
    postal_code: "35035",
    full_city_name: "Brierfield, AL",
    city_name: "Brierfield",
  },
  {
    postal_code: "59521",
    full_city_name: "Box Elder, MT",
    city_name: "Box Elder",
  },
  {
    postal_code: "68454",
    full_city_name: "Unadilla, NE",
    city_name: "Unadilla",
  },
  {
    postal_code: "01840",
    full_city_name: "Lawrence, MA",
    city_name: "Lawrence",
  },
  {
    postal_code: "68859",
    full_city_name: "North Loup, NE",
    city_name: "North Loup",
  },
  {
    postal_code: "48370",
    full_city_name: "Oxford, MI",
    city_name: "Oxford",
  },
  {
    postal_code: "58102",
    full_city_name: "Fargo, ND",
    city_name: "Fargo",
  },
  {
    postal_code: "95075",
    full_city_name: "Tres Pinos, CA",
    city_name: "Tres Pinos",
  },
  {
    postal_code: "58381",
    full_city_name: "Warwick, ND",
    city_name: "Warwick",
  },
  {
    postal_code: "39362",
    full_city_name: "State Line, MS",
    city_name: "State Line",
  },
  {
    postal_code: "72040",
    full_city_name: "Des Arc, AR",
    city_name: "Des Arc",
  },
  {
    postal_code: "38839",
    full_city_name: "Derma, MS",
    city_name: "Derma",
  },
  {
    postal_code: "56137",
    full_city_name: "Heron Lake, MN",
    city_name: "Heron Lake",
  },
  {
    postal_code: "58369",
    full_city_name: "Saint John, ND",
    city_name: "Saint John",
  },
  {
    postal_code: "73763",
    full_city_name: "Okeene, OK",
    city_name: "Okeene",
  },
  {
    postal_code: "62362",
    full_city_name: "Perry, IL",
    city_name: "Perry",
  },
  {
    postal_code: "72342",
    full_city_name: "Helena, AR",
    city_name: "Helena",
  },
  {
    postal_code: "12978",
    full_city_name: "Redford, NY",
    city_name: "Redford",
  },
  {
    postal_code: "18370",
    full_city_name: "Swiftwater, PA",
    city_name: "Swiftwater",
  },
  {
    postal_code: "28343",
    full_city_name: "Gibson, NC",
    city_name: "Gibson",
  },
  {
    postal_code: "74422",
    full_city_name: "Boynton, OK",
    city_name: "Boynton",
  },
  {
    postal_code: "22976",
    full_city_name: "Tyro, VA",
    city_name: "Tyro",
  },
  {
    postal_code: "23889",
    full_city_name: "Warfield, VA",
    city_name: "Warfield",
  },
  {
    postal_code: "24924",
    full_city_name: "Buckeye, WV",
    city_name: "Buckeye",
  },
  {
    postal_code: "12024",
    full_city_name: "Brainard, NY",
    city_name: "Brainard",
  },
  {
    postal_code: "36784",
    full_city_name: "Thomasville, AL",
    city_name: "Thomasville",
  },
  {
    postal_code: "64641",
    full_city_name: "Galt, MO",
    city_name: "Galt",
  },
  {
    postal_code: "67356",
    full_city_name: "Oswego, KS",
    city_name: "Oswego",
  },
  {
    postal_code: "58563",
    full_city_name: "New Salem, ND",
    city_name: "New Salem",
  },
  {
    postal_code: "68014",
    full_city_name: "Bruno, NE",
    city_name: "Bruno",
  },
  {
    postal_code: "41847",
    full_city_name: "Redfox, KY",
    city_name: "Redfox",
  },
  {
    postal_code: "30828",
    full_city_name: "Warrenton, GA",
    city_name: "Warrenton",
  },
  {
    postal_code: "26296",
    full_city_name: "Whitmer, WV",
    city_name: "Whitmer",
  },
  {
    postal_code: "45849",
    full_city_name: "Grover Hill, OH",
    city_name: "Grover Hill",
  },
  {
    postal_code: "17505",
    full_city_name: "Bird In Hand, PA",
    city_name: "Bird In Hand",
  },
  {
    postal_code: "04479",
    full_city_name: "Sangerville, ME",
    city_name: "Sangerville",
  },
  {
    postal_code: "70749",
    full_city_name: "Jarreau, LA",
    city_name: "Jarreau",
  },
  {
    postal_code: "53016",
    full_city_name: "Clyman, WI",
    city_name: "Clyman",
  },
  {
    postal_code: "24607",
    full_city_name: "Breaks, VA",
    city_name: "Breaks",
  },
  {
    postal_code: "50530",
    full_city_name: "Dayton, IA",
    city_name: "Dayton",
  },
  {
    postal_code: "57381",
    full_city_name: "Wessington, SD",
    city_name: "Wessington",
  },
  {
    postal_code: "10020",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "73548",
    full_city_name: "Hastings, OK",
    city_name: "Hastings",
  },
  {
    postal_code: "16616",
    full_city_name: "Beccaria, PA",
    city_name: "Beccaria",
  },
  {
    postal_code: "72952",
    full_city_name: "Rudy, AR",
    city_name: "Rudy",
  },
  {
    postal_code: "57241",
    full_city_name: "Hayti, SD",
    city_name: "Hayti",
  },
  {
    postal_code: "81210",
    full_city_name: "Almont, CO",
    city_name: "Almont",
  },
  {
    postal_code: "50264",
    full_city_name: "Weldon, IA",
    city_name: "Weldon",
  },
  {
    postal_code: "38631",
    full_city_name: "Friars Point, MS",
    city_name: "Friars Point",
  },
  {
    postal_code: "47660",
    full_city_name: "Oakland City, IN",
    city_name: "Oakland City",
  },
  {
    postal_code: "99653",
    full_city_name: "Port Alsworth, AK",
    city_name: "Port Alsworth",
  },
  {
    postal_code: "82084",
    full_city_name: "Tie Siding, WY",
    city_name: "Tie Siding",
  },
  {
    postal_code: "61925",
    full_city_name: "Dalton City, IL",
    city_name: "Dalton City",
  },
  {
    postal_code: "64471",
    full_city_name: "New Hampton, MO",
    city_name: "New Hampton",
  },
  {
    postal_code: "68020",
    full_city_name: "Decatur, NE",
    city_name: "Decatur",
  },
  {
    postal_code: "53150",
    full_city_name: "Muskego, WI",
    city_name: "Muskego",
  },
  {
    postal_code: "31827",
    full_city_name: "Talbotton, GA",
    city_name: "Talbotton",
  },
  {
    postal_code: "23161",
    full_city_name: "Stevensville, VA",
    city_name: "Stevensville",
  },
  {
    postal_code: "41064",
    full_city_name: "Mount Olivet, KY",
    city_name: "Mount Olivet",
  },
  {
    postal_code: "51008",
    full_city_name: "Brunsville, IA",
    city_name: "Brunsville",
  },
  {
    postal_code: "23504",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "84784",
    full_city_name: "Hildale, UT",
    city_name: "Hildale",
  },
  {
    postal_code: "76957",
    full_city_name: "Wall, TX",
    city_name: "Wall",
  },
  {
    postal_code: "58474",
    full_city_name: "Oakes, ND",
    city_name: "Oakes",
  },
  {
    postal_code: "46045",
    full_city_name: "Goldsmith, IN",
    city_name: "Goldsmith",
  },
  {
    postal_code: "46705",
    full_city_name: "Ashley, IN",
    city_name: "Ashley",
  },
  {
    postal_code: "17551",
    full_city_name: "Millersville, PA",
    city_name: "Millersville",
  },
  {
    postal_code: "71432",
    full_city_name: "Georgetown, LA",
    city_name: "Georgetown",
  },
  {
    postal_code: "82715",
    full_city_name: "Four Corners, WY",
    city_name: "Four Corners",
  },
  {
    postal_code: "72752",
    full_city_name: "Pettigrew, AR",
    city_name: "Pettigrew",
  },
  {
    postal_code: "68055",
    full_city_name: "Rosalie, NE",
    city_name: "Rosalie",
  },
  {
    postal_code: "54234",
    full_city_name: "Sister Bay, WI",
    city_name: "Sister Bay",
  },
  {
    postal_code: "31903",
    full_city_name: "Columbus, GA",
    city_name: "Columbus",
  },
  {
    postal_code: "04001",
    full_city_name: "Acton, ME",
    city_name: "Acton",
  },
  {
    postal_code: "88346",
    full_city_name: "Ruidoso Downs, NM",
    city_name: "Ruidoso Downs",
  },
  {
    postal_code: "57791",
    full_city_name: "Wasta, SD",
    city_name: "Wasta",
  },
  {
    postal_code: "04657",
    full_city_name: "Meddybemps, ME",
    city_name: "Meddybemps",
  },
  {
    postal_code: "13667",
    full_city_name: "Norfolk, NY",
    city_name: "Norfolk",
  },
  {
    postal_code: "12884",
    full_city_name: "Victory Mills, NY",
    city_name: "Victory Mills",
  },
  {
    postal_code: "67055",
    full_city_name: "Greenwich, KS",
    city_name: "Greenwich",
  },
  {
    postal_code: "12169",
    full_city_name: "Stephentown, NY",
    city_name: "Stephentown",
  },
  {
    postal_code: "92239",
    full_city_name: "Desert Center, CA",
    city_name: "Desert Center",
  },
  {
    postal_code: "75550",
    full_city_name: "Annona, TX",
    city_name: "Annona",
  },
  {
    postal_code: "78829",
    full_city_name: "Batesville, TX",
    city_name: "Batesville",
  },
  {
    postal_code: "17214",
    full_city_name: "Blue Ridge Summit, PA",
    city_name: "Blue Ridge Summit",
  },
  {
    postal_code: "30436",
    full_city_name: "Lyons, GA",
    city_name: "Lyons",
  },
  {
    postal_code: "15784",
    full_city_name: "Worthville, PA",
    city_name: "Worthville",
  },
  {
    postal_code: "40940",
    full_city_name: "Frakes, KY",
    city_name: "Frakes",
  },
  {
    postal_code: "62343",
    full_city_name: "Hull, IL",
    city_name: "Hull",
  },
  {
    postal_code: "81642",
    full_city_name: "Meredith, CO",
    city_name: "Meredith",
  },
  {
    postal_code: "84329",
    full_city_name: "Park Valley, UT",
    city_name: "Park Valley",
  },
  {
    postal_code: "30111",
    full_city_name: "Clarkdale, GA",
    city_name: "Clarkdale",
  },
  {
    postal_code: "71034",
    full_city_name: "Hall Summit, LA",
    city_name: "Hall Summit",
  },
  {
    postal_code: "41180",
    full_city_name: "Webbville, KY",
    city_name: "Webbville",
  },
  {
    postal_code: "41263",
    full_city_name: "Tutor Key, KY",
    city_name: "Tutor Key",
  },
  {
    postal_code: "75926",
    full_city_name: "Apple Springs, TX",
    city_name: "Apple Springs",
  },
  {
    postal_code: "16114",
    full_city_name: "Clarks Mills, PA",
    city_name: "Clarks Mills",
  },
  {
    postal_code: "13756",
    full_city_name: "East Branch, NY",
    city_name: "East Branch",
  },
  {
    postal_code: "95589",
    full_city_name: "Whitethorn, CA",
    city_name: "Whitethorn",
  },
  {
    postal_code: "23505",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "12720",
    full_city_name: "Bethel, NY",
    city_name: "Bethel",
  },
  {
    postal_code: "59464",
    full_city_name: "Moore, MT",
    city_name: "Moore",
  },
  {
    postal_code: "68952",
    full_city_name: "Inavale, NE",
    city_name: "Inavale",
  },
  {
    postal_code: "54652",
    full_city_name: "Readstown, WI",
    city_name: "Readstown",
  },
  {
    postal_code: "13614",
    full_city_name: "Brier Hill, NY",
    city_name: "Brier Hill",
  },
  {
    postal_code: "64437",
    full_city_name: "Craig, MO",
    city_name: "Craig",
  },
  {
    postal_code: "49111",
    full_city_name: "Eau Claire, MI",
    city_name: "Eau Claire",
  },
  {
    postal_code: "98631",
    full_city_name: "Long Beach, WA",
    city_name: "Long Beach",
  },
  {
    postal_code: "83464",
    full_city_name: "Leadore, ID",
    city_name: "Leadore",
  },
  {
    postal_code: "97626",
    full_city_name: "Fort Klamath, OR",
    city_name: "Fort Klamath",
  },
  {
    postal_code: "35621",
    full_city_name: "Eva, AL",
    city_name: "Eva",
  },
  {
    postal_code: "38921",
    full_city_name: "Charleston, MS",
    city_name: "Charleston",
  },
  {
    postal_code: "61367",
    full_city_name: "Sublette, IL",
    city_name: "Sublette",
  },
  {
    postal_code: "29146",
    full_city_name: "Springfield, SC",
    city_name: "Springfield",
  },
  {
    postal_code: "42040",
    full_city_name: "Farmington, KY",
    city_name: "Farmington",
  },
  {
    postal_code: "84051",
    full_city_name: "Mountain Home, UT",
    city_name: "Mountain Home",
  },
  {
    postal_code: "62461",
    full_city_name: "Shumway, IL",
    city_name: "Shumway",
  },
  {
    postal_code: "41746",
    full_city_name: "Happy, KY",
    city_name: "Happy",
  },
  {
    postal_code: "54246",
    full_city_name: "Washington Island, WI",
    city_name: "Washington Island",
  },
  {
    postal_code: "98262",
    full_city_name: "Lummi Island, WA",
    city_name: "Lummi Island",
  },
  {
    postal_code: "36319",
    full_city_name: "Columbia, AL",
    city_name: "Columbia",
  },
  {
    postal_code: "19017",
    full_city_name: "Chester Heights, PA",
    city_name: "Chester Heights",
  },
  {
    postal_code: "47017",
    full_city_name: "Cross Plains, IN",
    city_name: "Cross Plains",
  },
  {
    postal_code: "10507",
    full_city_name: "Bedford Hills, NY",
    city_name: "Bedford Hills",
  },
  {
    postal_code: "66073",
    full_city_name: "Perry, KS",
    city_name: "Perry",
  },
  {
    postal_code: "87573",
    full_city_name: "Tererro, NM",
    city_name: "Tererro",
  },
  {
    postal_code: "30453",
    full_city_name: "Reidsville, GA",
    city_name: "Reidsville",
  },
  {
    postal_code: "55990",
    full_city_name: "Wykoff, MN",
    city_name: "Wykoff",
  },
  {
    postal_code: "58559",
    full_city_name: "Mercer, ND",
    city_name: "Mercer",
  },
  {
    postal_code: "58573",
    full_city_name: "Strasburg, ND",
    city_name: "Strasburg",
  },
  {
    postal_code: "54488",
    full_city_name: "Unity, WI",
    city_name: "Unity",
  },
  {
    postal_code: "12911",
    full_city_name: "Keeseville, NY",
    city_name: "Keeseville",
  },
  {
    postal_code: "13158",
    full_city_name: "Truxton, NY",
    city_name: "Truxton",
  },
  {
    postal_code: "55738",
    full_city_name: "Forbes, MN",
    city_name: "Forbes",
  },
  {
    postal_code: "41804",
    full_city_name: "Blackey, KY",
    city_name: "Blackey",
  },
  {
    postal_code: "66087",
    full_city_name: "Troy, KS",
    city_name: "Troy",
  },
  {
    postal_code: "52318",
    full_city_name: "Norway, IA",
    city_name: "Norway",
  },
  {
    postal_code: "37180",
    full_city_name: "Unionville, TN",
    city_name: "Unionville",
  },
  {
    postal_code: "58029",
    full_city_name: "Erie, ND",
    city_name: "Erie",
  },
  {
    postal_code: "13625",
    full_city_name: "Colton, NY",
    city_name: "Colton",
  },
  {
    postal_code: "61931",
    full_city_name: "Humboldt, IL",
    city_name: "Humboldt",
  },
  {
    postal_code: "25650",
    full_city_name: "Verner, WV",
    city_name: "Verner",
  },
  {
    postal_code: "73531",
    full_city_name: "Devol, OK",
    city_name: "Devol",
  },
  {
    postal_code: "93634",
    full_city_name: "Lakeshore, CA",
    city_name: "Lakeshore",
  },
  {
    postal_code: "38569",
    full_city_name: "Lancaster, TN",
    city_name: "Lancaster",
  },
  {
    postal_code: "64830",
    full_city_name: "Alba, MO",
    city_name: "Alba",
  },
  {
    postal_code: "67334",
    full_city_name: "Chautauqua, KS",
    city_name: "Chautauqua",
  },
  {
    postal_code: "45832",
    full_city_name: "Convoy, OH",
    city_name: "Convoy",
  },
  {
    postal_code: "41745",
    full_city_name: "Gays Creek, KY",
    city_name: "Gays Creek",
  },
  {
    postal_code: "36740",
    full_city_name: "Forkland, AL",
    city_name: "Forkland",
  },
  {
    postal_code: "38857",
    full_city_name: "Mooreville, MS",
    city_name: "Mooreville",
  },
  {
    postal_code: "97722",
    full_city_name: "Diamond, OR",
    city_name: "Diamond",
  },
  {
    postal_code: "61750",
    full_city_name: "Lane, IL",
    city_name: "Lane",
  },
  {
    postal_code: "47115",
    full_city_name: "Depauw, IN",
    city_name: "Depauw",
  },
  {
    postal_code: "07661",
    full_city_name: "River Edge, NJ",
    city_name: "River Edge",
  },
  {
    postal_code: "69152",
    full_city_name: "Mullen, NE",
    city_name: "Mullen",
  },
  {
    postal_code: "57365",
    full_city_name: "Oacoma, SD",
    city_name: "Oacoma",
  },
  {
    postal_code: "41348",
    full_city_name: "Lost Creek, KY",
    city_name: "Lost Creek",
  },
  {
    postal_code: "68875",
    full_city_name: "Scotia, NE",
    city_name: "Scotia",
  },
  {
    postal_code: "95606",
    full_city_name: "Brooks, CA",
    city_name: "Brooks",
  },
  {
    postal_code: "39348",
    full_city_name: "Paulding, MS",
    city_name: "Paulding",
  },
  {
    postal_code: "23129",
    full_city_name: "Oilville, VA",
    city_name: "Oilville",
  },
  {
    postal_code: "81044",
    full_city_name: "Hasty, CO",
    city_name: "Hasty",
  },
  {
    postal_code: "29346",
    full_city_name: "Glendale, SC",
    city_name: "Glendale",
  },
  {
    postal_code: "59032",
    full_city_name: "Grass Range, MT",
    city_name: "Grass Range",
  },
  {
    postal_code: "17814",
    full_city_name: "Benton, PA",
    city_name: "Benton",
  },
  {
    postal_code: "58421",
    full_city_name: "Carrington, ND",
    city_name: "Carrington",
  },
  {
    postal_code: "74369",
    full_city_name: "Welch, OK",
    city_name: "Welch",
  },
  {
    postal_code: "82412",
    full_city_name: "Byron, WY",
    city_name: "Byron",
  },
  {
    postal_code: "52329",
    full_city_name: "Rowley, IA",
    city_name: "Rowley",
  },
  {
    postal_code: "16832",
    full_city_name: "Coburn, PA",
    city_name: "Coburn",
  },
  {
    postal_code: "17925",
    full_city_name: "Brockton, PA",
    city_name: "Brockton",
  },
  {
    postal_code: "47386",
    full_city_name: "Springport, IN",
    city_name: "Springport",
  },
  {
    postal_code: "41257",
    full_city_name: "Stambaugh, KY",
    city_name: "Stambaugh",
  },
  {
    postal_code: "99023",
    full_city_name: "Mica, WA",
    city_name: "Mica",
  },
  {
    postal_code: "35746",
    full_city_name: "Fackler, AL",
    city_name: "Fackler",
  },
  {
    postal_code: "30413",
    full_city_name: "Bartow, GA",
    city_name: "Bartow",
  },
  {
    postal_code: "35771",
    full_city_name: "Section, AL",
    city_name: "Section",
  },
  {
    postal_code: "13489",
    full_city_name: "West Leyden, NY",
    city_name: "West Leyden",
  },
  {
    postal_code: "26269",
    full_city_name: "Hambleton, WV",
    city_name: "Hambleton",
  },
  {
    postal_code: "05654",
    full_city_name: "Graniteville, VT",
    city_name: "Graniteville",
  },
  {
    postal_code: "67050",
    full_city_name: "Garden Plain, KS",
    city_name: "Garden Plain",
  },
  {
    postal_code: "80474",
    full_city_name: "Rollinsville, CO",
    city_name: "Rollinsville",
  },
  {
    postal_code: "55402",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "22438",
    full_city_name: "Champlain, VA",
    city_name: "Champlain",
  },
  {
    postal_code: "38873",
    full_city_name: "Tishomingo, MS",
    city_name: "Tishomingo",
  },
  {
    postal_code: "51636",
    full_city_name: "Coin, IA",
    city_name: "Coin",
  },
  {
    postal_code: "32062",
    full_city_name: "Mc Alpin, FL",
    city_name: "Mc Alpin",
  },
  {
    postal_code: "49060",
    full_city_name: "Hickory Corners, MI",
    city_name: "Hickory Corners",
  },
  {
    postal_code: "58257",
    full_city_name: "Mayville, ND",
    city_name: "Mayville",
  },
  {
    postal_code: "24225",
    full_city_name: "Cleveland, VA",
    city_name: "Cleveland",
  },
  {
    postal_code: "73753",
    full_city_name: "Kremlin, OK",
    city_name: "Kremlin",
  },
  {
    postal_code: "50433",
    full_city_name: "Dougherty, IA",
    city_name: "Dougherty",
  },
  {
    postal_code: "30818",
    full_city_name: "Matthews, GA",
    city_name: "Matthews",
  },
  {
    postal_code: "99116",
    full_city_name: "Coulee Dam, WA",
    city_name: "Coulee Dam",
  },
  {
    postal_code: "93432",
    full_city_name: "Creston, CA",
    city_name: "Creston",
  },
  {
    postal_code: "13472",
    full_city_name: "Thendara, NY",
    city_name: "Thendara",
  },
  {
    postal_code: "26238",
    full_city_name: "Volga, WV",
    city_name: "Volga",
  },
  {
    postal_code: "67578",
    full_city_name: "Stafford, KS",
    city_name: "Stafford",
  },
  {
    postal_code: "78935",
    full_city_name: "Alleyton, TX",
    city_name: "Alleyton",
  },
  {
    postal_code: "48209",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "49094",
    full_city_name: "Union City, MI",
    city_name: "Union City",
  },
  {
    postal_code: "53040",
    full_city_name: "Kewaskum, WI",
    city_name: "Kewaskum",
  },
  {
    postal_code: "22948",
    full_city_name: "Locust Dale, VA",
    city_name: "Locust Dale",
  },
  {
    postal_code: "66438",
    full_city_name: "Home, KS",
    city_name: "Home",
  },
  {
    postal_code: "66095",
    full_city_name: "Williamsburg, KS",
    city_name: "Williamsburg",
  },
  {
    postal_code: "62204",
    full_city_name: "East Saint Louis, IL",
    city_name: "East Saint Louis",
  },
  {
    postal_code: "35464",
    full_city_name: "Gainesville, AL",
    city_name: "Gainesville",
  },
  {
    postal_code: "50041",
    full_city_name: "Bradford, IA",
    city_name: "Bradford",
  },
  {
    postal_code: "25971",
    full_city_name: "Lerona, WV",
    city_name: "Lerona",
  },
  {
    postal_code: "49680",
    full_city_name: "South Boardman, MI",
    city_name: "South Boardman",
  },
  {
    postal_code: "56543",
    full_city_name: "Foxhome, MN",
    city_name: "Foxhome",
  },
  {
    postal_code: "30421",
    full_city_name: "Collins, GA",
    city_name: "Collins",
  },
  {
    postal_code: "68967",
    full_city_name: "Oxford, NE",
    city_name: "Oxford",
  },
  {
    postal_code: "38659",
    full_city_name: "Potts Camp, MS",
    city_name: "Potts Camp",
  },
  {
    postal_code: "13694",
    full_city_name: "Waddington, NY",
    city_name: "Waddington",
  },
  {
    postal_code: "16343",
    full_city_name: "Reno, PA",
    city_name: "Reno",
  },
  {
    postal_code: "25864",
    full_city_name: "Layland, WV",
    city_name: "Layland",
  },
  {
    postal_code: "16836",
    full_city_name: "Frenchville, PA",
    city_name: "Frenchville",
  },
  {
    postal_code: "97880",
    full_city_name: "Ukiah, OR",
    city_name: "Ukiah",
  },
  {
    postal_code: "12492",
    full_city_name: "West Kill, NY",
    city_name: "West Kill",
  },
  {
    postal_code: "38670",
    full_city_name: "Sledge, MS",
    city_name: "Sledge",
  },
  {
    postal_code: "75846",
    full_city_name: "Jewett, TX",
    city_name: "Jewett",
  },
  {
    postal_code: "24323",
    full_city_name: "Crockett, VA",
    city_name: "Crockett",
  },
  {
    postal_code: "12041",
    full_city_name: "Clarksville, NY",
    city_name: "Clarksville",
  },
  {
    postal_code: "71742",
    full_city_name: "Fordyce, AR",
    city_name: "Fordyce",
  },
  {
    postal_code: "58274",
    full_city_name: "Portland, ND",
    city_name: "Portland",
  },
  {
    postal_code: "36370",
    full_city_name: "Pansey, AL",
    city_name: "Pansey",
  },
  {
    postal_code: "25162",
    full_city_name: "Pratt, WV",
    city_name: "Pratt",
  },
  {
    postal_code: "69201",
    full_city_name: "Valentine, NE",
    city_name: "Valentine",
  },
  {
    postal_code: "79563",
    full_city_name: "Tye, TX",
    city_name: "Tye",
  },
  {
    postal_code: "76676",
    full_city_name: "Penelope, TX",
    city_name: "Penelope",
  },
  {
    postal_code: "73463",
    full_city_name: "Wilson, OK",
    city_name: "Wilson",
  },
  {
    postal_code: "24439",
    full_city_name: "Goshen, VA",
    city_name: "Goshen",
  },
  {
    postal_code: "52325",
    full_city_name: "Parnell, IA",
    city_name: "Parnell",
  },
  {
    postal_code: "65237",
    full_city_name: "Bunceton, MO",
    city_name: "Bunceton",
  },
  {
    postal_code: "10983",
    full_city_name: "Tappan, NY",
    city_name: "Tappan",
  },
  {
    postal_code: "67740",
    full_city_name: "Hoxie, KS",
    city_name: "Hoxie",
  },
  {
    postal_code: "78076",
    full_city_name: "Zapata, TX",
    city_name: "Zapata",
  },
  {
    postal_code: "97134",
    full_city_name: "Oceanside, OR",
    city_name: "Oceanside",
  },
  {
    postal_code: "48206",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "72051",
    full_city_name: "Fox, AR",
    city_name: "Fox",
  },
  {
    postal_code: "41519",
    full_city_name: "Canada, KY",
    city_name: "Canada",
  },
  {
    postal_code: "38855",
    full_city_name: "Mantachie, MS",
    city_name: "Mantachie",
  },
  {
    postal_code: "56294",
    full_city_name: "Wanda, MN",
    city_name: "Wanda",
  },
  {
    postal_code: "37190",
    full_city_name: "Woodbury, TN",
    city_name: "Woodbury",
  },
  {
    postal_code: "07656",
    full_city_name: "Park Ridge, NJ",
    city_name: "Park Ridge",
  },
  {
    postal_code: "66451",
    full_city_name: "Lyndon, KS",
    city_name: "Lyndon",
  },
  {
    postal_code: "07927",
    full_city_name: "Cedar Knolls, NJ",
    city_name: "Cedar Knolls",
  },
  {
    postal_code: "12194",
    full_city_name: "West Fulton, NY",
    city_name: "West Fulton",
  },
  {
    postal_code: "73062",
    full_city_name: "Mountain View, OK",
    city_name: "Mountain View",
  },
  {
    postal_code: "07732",
    full_city_name: "Highlands, NJ",
    city_name: "Highlands",
  },
  {
    postal_code: "71631",
    full_city_name: "Banks, AR",
    city_name: "Banks",
  },
  {
    postal_code: "63932",
    full_city_name: "Broseley, MO",
    city_name: "Broseley",
  },
  {
    postal_code: "57461",
    full_city_name: "Mellette, SD",
    city_name: "Mellette",
  },
  {
    postal_code: "31078",
    full_city_name: "Roberta, GA",
    city_name: "Roberta",
  },
  {
    postal_code: "95312",
    full_city_name: "Cressey, CA",
    city_name: "Cressey",
  },
  {
    postal_code: "67018",
    full_city_name: "Bluff City, KS",
    city_name: "Bluff City",
  },
  {
    postal_code: "24565",
    full_city_name: "Java, VA",
    city_name: "Java",
  },
  {
    postal_code: "16646",
    full_city_name: "Hastings, PA",
    city_name: "Hastings",
  },
  {
    postal_code: "55949",
    full_city_name: "Lanesboro, MN",
    city_name: "Lanesboro",
  },
  {
    postal_code: "37153",
    full_city_name: "Rockvale, TN",
    city_name: "Rockvale",
  },
  {
    postal_code: "30205",
    full_city_name: "Brooks, GA",
    city_name: "Brooks",
  },
  {
    postal_code: "65075",
    full_city_name: "Saint Elizabeth, MO",
    city_name: "Saint Elizabeth",
  },
  {
    postal_code: "27820",
    full_city_name: "Conway, NC",
    city_name: "Conway",
  },
  {
    postal_code: "64750",
    full_city_name: "Harwood, MO",
    city_name: "Harwood",
  },
  {
    postal_code: "79739",
    full_city_name: "Garden City, TX",
    city_name: "Garden City",
  },
  {
    postal_code: "66424",
    full_city_name: "Everest, KS",
    city_name: "Everest",
  },
  {
    postal_code: "37756",
    full_city_name: "Huntsville, TN",
    city_name: "Huntsville",
  },
  {
    postal_code: "06243",
    full_city_name: "East Killingly, CT",
    city_name: "East Killingly",
  },
  {
    postal_code: "49639",
    full_city_name: "Hersey, MI",
    city_name: "Hersey",
  },
  {
    postal_code: "76250",
    full_city_name: "Lindsay, TX",
    city_name: "Lindsay",
  },
  {
    postal_code: "40115",
    full_city_name: "Custer, KY",
    city_name: "Custer",
  },
  {
    postal_code: "95936",
    full_city_name: "Downieville, CA",
    city_name: "Downieville",
  },
  {
    postal_code: "62050",
    full_city_name: "Hillview, IL",
    city_name: "Hillview",
  },
  {
    postal_code: "16613",
    full_city_name: "Ashville, PA",
    city_name: "Ashville",
  },
  {
    postal_code: "49768",
    full_city_name: "Paradise, MI",
    city_name: "Paradise",
  },
  {
    postal_code: "62694",
    full_city_name: "Winchester, IL",
    city_name: "Winchester",
  },
  {
    postal_code: "16732",
    full_city_name: "Gifford, PA",
    city_name: "Gifford",
  },
  {
    postal_code: "86438",
    full_city_name: "Yucca, AZ",
    city_name: "Yucca",
  },
  {
    postal_code: "99135",
    full_city_name: "Hartline, WA",
    city_name: "Hartline",
  },
  {
    postal_code: "30739",
    full_city_name: "Rock Spring, GA",
    city_name: "Rock Spring",
  },
  {
    postal_code: "07064",
    full_city_name: "Port Reading, NJ",
    city_name: "Port Reading",
  },
  {
    postal_code: "38390",
    full_city_name: "Yuma, TN",
    city_name: "Yuma",
  },
  {
    postal_code: "49728",
    full_city_name: "Eckerman, MI",
    city_name: "Eckerman",
  },
  {
    postal_code: "57072",
    full_city_name: "Volin, SD",
    city_name: "Volin",
  },
  {
    postal_code: "51025",
    full_city_name: "Holstein, IA",
    city_name: "Holstein",
  },
  {
    postal_code: "39666",
    full_city_name: "Summit, MS",
    city_name: "Summit",
  },
  {
    postal_code: "25868",
    full_city_name: "Lookout, WV",
    city_name: "Lookout",
  },
  {
    postal_code: "62638",
    full_city_name: "Franklin, IL",
    city_name: "Franklin",
  },
  {
    postal_code: "73015",
    full_city_name: "Carnegie, OK",
    city_name: "Carnegie",
  },
  {
    postal_code: "42049",
    full_city_name: "Hazel, KY",
    city_name: "Hazel",
  },
  {
    postal_code: "61730",
    full_city_name: "Cooksville, IL",
    city_name: "Cooksville",
  },
  {
    postal_code: "72633",
    full_city_name: "Everton, AR",
    city_name: "Everton",
  },
  {
    postal_code: "13162",
    full_city_name: "Verona Beach, NY",
    city_name: "Verona Beach",
  },
  {
    postal_code: "78650",
    full_city_name: "Mc Dade, TX",
    city_name: "Mc Dade",
  },
  {
    postal_code: "65501",
    full_city_name: "Jadwin, MO",
    city_name: "Jadwin",
  },
  {
    postal_code: "78535",
    full_city_name: "Combes, TX",
    city_name: "Combes",
  },
  {
    postal_code: "16922",
    full_city_name: "Galeton, PA",
    city_name: "Galeton",
  },
  {
    postal_code: "15832",
    full_city_name: "Driftwood, PA",
    city_name: "Driftwood",
  },
  {
    postal_code: "87016",
    full_city_name: "Estancia, NM",
    city_name: "Estancia",
  },
  {
    postal_code: "04408",
    full_city_name: "Aurora, ME",
    city_name: "Aurora",
  },
  {
    postal_code: "72955",
    full_city_name: "Uniontown, AR",
    city_name: "Uniontown",
  },
  {
    postal_code: "73639",
    full_city_name: "Custer City, OK",
    city_name: "Custer City",
  },
  {
    postal_code: "65050",
    full_city_name: "Latham, MO",
    city_name: "Latham",
  },
  {
    postal_code: "93206",
    full_city_name: "Buttonwillow, CA",
    city_name: "Buttonwillow",
  },
  {
    postal_code: "42285",
    full_city_name: "Sweeden, KY",
    city_name: "Sweeden",
  },
  {
    postal_code: "23878",
    full_city_name: "Sedley, VA",
    city_name: "Sedley",
  },
  {
    postal_code: "52043",
    full_city_name: "Elkader, IA",
    city_name: "Elkader",
  },
  {
    postal_code: "41231",
    full_city_name: "Lovely, KY",
    city_name: "Lovely",
  },
  {
    postal_code: "30568",
    full_city_name: "Rabun Gap, GA",
    city_name: "Rabun Gap",
  },
  {
    postal_code: "98295",
    full_city_name: "Sumas, WA",
    city_name: "Sumas",
  },
  {
    postal_code: "22627",
    full_city_name: "Flint Hill, VA",
    city_name: "Flint Hill",
  },
  {
    postal_code: "98858",
    full_city_name: "Waterville, WA",
    city_name: "Waterville",
  },
  {
    postal_code: "67010",
    full_city_name: "Augusta, KS",
    city_name: "Augusta",
  },
  {
    postal_code: "88029",
    full_city_name: "Columbus, NM",
    city_name: "Columbus",
  },
  {
    postal_code: "62655",
    full_city_name: "Kilbourne, IL",
    city_name: "Kilbourne",
  },
  {
    postal_code: "67423",
    full_city_name: "Beverly, KS",
    city_name: "Beverly",
  },
  {
    postal_code: "72776",
    full_city_name: "Witter, AR",
    city_name: "Witter",
  },
  {
    postal_code: "97537",
    full_city_name: "Rogue River, OR",
    city_name: "Rogue River",
  },
  {
    postal_code: "52330",
    full_city_name: "Ryan, IA",
    city_name: "Ryan",
  },
  {
    postal_code: "62854",
    full_city_name: "Kinmundy, IL",
    city_name: "Kinmundy",
  },
  {
    postal_code: "24530",
    full_city_name: "Callands, VA",
    city_name: "Callands",
  },
  {
    postal_code: "40062",
    full_city_name: "Saint Francis, KY",
    city_name: "Saint Francis",
  },
  {
    postal_code: "56659",
    full_city_name: "Max, MN",
    city_name: "Max",
  },
  {
    postal_code: "54475",
    full_city_name: "Rudolph, WI",
    city_name: "Rudolph",
  },
  {
    postal_code: "49434",
    full_city_name: "Macatawa, MI",
    city_name: "Macatawa",
  },
  {
    postal_code: "38685",
    full_city_name: "Waterford, MS",
    city_name: "Waterford",
  },
  {
    postal_code: "39656",
    full_city_name: "Oak Vale, MS",
    city_name: "Oak Vale",
  },
  {
    postal_code: "71378",
    full_city_name: "Wisner, LA",
    city_name: "Wisner",
  },
  {
    postal_code: "32445",
    full_city_name: "Malone, FL",
    city_name: "Malone",
  },
  {
    postal_code: "47528",
    full_city_name: "Edwardsport, IN",
    city_name: "Edwardsport",
  },
  {
    postal_code: "47360",
    full_city_name: "Mooreland, IN",
    city_name: "Mooreland",
  },
  {
    postal_code: "73532",
    full_city_name: "Duke, OK",
    city_name: "Duke",
  },
  {
    postal_code: "26267",
    full_city_name: "Ellamore, WV",
    city_name: "Ellamore",
  },
  {
    postal_code: "48411",
    full_city_name: "Atlas, MI",
    city_name: "Atlas",
  },
  {
    postal_code: "64441",
    full_city_name: "Denver, MO",
    city_name: "Denver",
  },
  {
    postal_code: "98256",
    full_city_name: "Index, WA",
    city_name: "Index",
  },
  {
    postal_code: "08886",
    full_city_name: "Stewartsville, NJ",
    city_name: "Stewartsville",
  },
  {
    postal_code: "57435",
    full_city_name: "Cresbard, SD",
    city_name: "Cresbard",
  },
  {
    postal_code: "40763",
    full_city_name: "Siler, KY",
    city_name: "Siler",
  },
  {
    postal_code: "98566",
    full_city_name: "Neilton, WA",
    city_name: "Neilton",
  },
  {
    postal_code: "82637",
    full_city_name: "Glenrock, WY",
    city_name: "Glenrock",
  },
  {
    postal_code: "22709",
    full_city_name: "Aroda, VA",
    city_name: "Aroda",
  },
  {
    postal_code: "62515",
    full_city_name: "Buffalo, IL",
    city_name: "Buffalo",
  },
  {
    postal_code: "58654",
    full_city_name: "Sentinel Butte, ND",
    city_name: "Sentinel Butte",
  },
  {
    postal_code: "58228",
    full_city_name: "Emerado, ND",
    city_name: "Emerado",
  },
  {
    postal_code: "98325",
    full_city_name: "Chimacum, WA",
    city_name: "Chimacum",
  },
  {
    postal_code: "51466",
    full_city_name: "Wall Lake, IA",
    city_name: "Wall Lake",
  },
  {
    postal_code: "50510",
    full_city_name: "Albert City, IA",
    city_name: "Albert City",
  },
  {
    postal_code: "16235",
    full_city_name: "Lucinda, PA",
    city_name: "Lucinda",
  },
  {
    postal_code: "31094",
    full_city_name: "Warthen, GA",
    city_name: "Warthen",
  },
  {
    postal_code: "62554",
    full_city_name: "Oreana, IL",
    city_name: "Oreana",
  },
  {
    postal_code: "72680",
    full_city_name: "Timbo, AR",
    city_name: "Timbo",
  },
  {
    postal_code: "36010",
    full_city_name: "Brundidge, AL",
    city_name: "Brundidge",
  },
  {
    postal_code: "39096",
    full_city_name: "Lorman, MS",
    city_name: "Lorman",
  },
  {
    postal_code: "35130",
    full_city_name: "Quinton, AL",
    city_name: "Quinton",
  },
  {
    postal_code: "46803",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "36053",
    full_city_name: "Midway, AL",
    city_name: "Midway",
  },
  {
    postal_code: "25261",
    full_city_name: "Millstone, WV",
    city_name: "Millstone",
  },
  {
    postal_code: "40927",
    full_city_name: "Closplint, KY",
    city_name: "Closplint",
  },
  {
    postal_code: "16136",
    full_city_name: "Koppel, PA",
    city_name: "Koppel",
  },
  {
    postal_code: "47874",
    full_city_name: "Rosedale, IN",
    city_name: "Rosedale",
  },
  {
    postal_code: "65320",
    full_city_name: "Arrow Rock, MO",
    city_name: "Arrow Rock",
  },
  {
    postal_code: "52038",
    full_city_name: "Dundee, IA",
    city_name: "Dundee",
  },
  {
    postal_code: "14066",
    full_city_name: "Gainesville, NY",
    city_name: "Gainesville",
  },
  {
    postal_code: "51003",
    full_city_name: "Alton, IA",
    city_name: "Alton",
  },
  {
    postal_code: "24597",
    full_city_name: "Vernon Hill, VA",
    city_name: "Vernon Hill",
  },
  {
    postal_code: "67333",
    full_city_name: "Caney, KS",
    city_name: "Caney",
  },
  {
    postal_code: "67835",
    full_city_name: "Cimarron, KS",
    city_name: "Cimarron",
  },
  {
    postal_code: "67524",
    full_city_name: "Chase, KS",
    city_name: "Chase",
  },
  {
    postal_code: "68460",
    full_city_name: "Waco, NE",
    city_name: "Waco",
  },
  {
    postal_code: "38827",
    full_city_name: "Belmont, MS",
    city_name: "Belmont",
  },
  {
    postal_code: "93549",
    full_city_name: "Olancha, CA",
    city_name: "Olancha",
  },
  {
    postal_code: "44825",
    full_city_name: "Chatfield, OH",
    city_name: "Chatfield",
  },
  {
    postal_code: "98577",
    full_city_name: "Raymond, WA",
    city_name: "Raymond",
  },
  {
    postal_code: "53037",
    full_city_name: "Jackson, WI",
    city_name: "Jackson",
  },
  {
    postal_code: "64096",
    full_city_name: "Waverly, MO",
    city_name: "Waverly",
  },
  {
    postal_code: "52729",
    full_city_name: "Calamus, IA",
    city_name: "Calamus",
  },
  {
    postal_code: "65712",
    full_city_name: "Mount Vernon, MO",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "40145",
    full_city_name: "Hudson, KY",
    city_name: "Hudson",
  },
  {
    postal_code: "36482",
    full_city_name: "Whatley, AL",
    city_name: "Whatley",
  },
  {
    postal_code: "56736",
    full_city_name: "Mentor, MN",
    city_name: "Mentor",
  },
  {
    postal_code: "17215",
    full_city_name: "Burnt Cabins, PA",
    city_name: "Burnt Cabins",
  },
  {
    postal_code: "57311",
    full_city_name: "Alexandria, SD",
    city_name: "Alexandria",
  },
  {
    postal_code: "59743",
    full_city_name: "Melrose, MT",
    city_name: "Melrose",
  },
  {
    postal_code: "37397",
    full_city_name: "Whitwell, TN",
    city_name: "Whitwell",
  },
  {
    postal_code: "57005",
    full_city_name: "Brandon, SD",
    city_name: "Brandon",
  },
  {
    postal_code: "41603",
    full_city_name: "Banner, KY",
    city_name: "Banner",
  },
  {
    postal_code: "57075",
    full_city_name: "Wentworth, SD",
    city_name: "Wentworth",
  },
  {
    postal_code: "05252",
    full_city_name: "East Arlington, VT",
    city_name: "East Arlington",
  },
  {
    postal_code: "73084",
    full_city_name: "Spencer, OK",
    city_name: "Spencer",
  },
  {
    postal_code: "14512",
    full_city_name: "Naples, NY",
    city_name: "Naples",
  },
  {
    postal_code: "98526",
    full_city_name: "Amanda Park, WA",
    city_name: "Amanda Park",
  },
  {
    postal_code: "26631",
    full_city_name: "Napier, WV",
    city_name: "Napier",
  },
  {
    postal_code: "57043",
    full_city_name: "Marion, SD",
    city_name: "Marion",
  },
  {
    postal_code: "98938",
    full_city_name: "Outlook, WA",
    city_name: "Outlook",
  },
  {
    postal_code: "16942",
    full_city_name: "Osceola, PA",
    city_name: "Osceola",
  },
  {
    postal_code: "98188",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "36040",
    full_city_name: "Hayneville, AL",
    city_name: "Hayneville",
  },
  {
    postal_code: "58482",
    full_city_name: "Steele, ND",
    city_name: "Steele",
  },
  {
    postal_code: "72679",
    full_city_name: "Tilly, AR",
    city_name: "Tilly",
  },
  {
    postal_code: "12745",
    full_city_name: "Hortonville, NY",
    city_name: "Hortonville",
  },
  {
    postal_code: "15771",
    full_city_name: "Rochester Mills, PA",
    city_name: "Rochester Mills",
  },
  {
    postal_code: "46542",
    full_city_name: "Milford, IN",
    city_name: "Milford",
  },
  {
    postal_code: "07458",
    full_city_name: "Saddle River, NJ",
    city_name: "Saddle River",
  },
  {
    postal_code: "56045",
    full_city_name: "Hollandale, MN",
    city_name: "Hollandale",
  },
  {
    postal_code: "76678",
    full_city_name: "Prairie Hill, TX",
    city_name: "Prairie Hill",
  },
  {
    postal_code: "83336",
    full_city_name: "Heyburn, ID",
    city_name: "Heyburn",
  },
  {
    postal_code: "89428",
    full_city_name: "Silver City, NV",
    city_name: "Silver City",
  },
  {
    postal_code: "61466",
    full_city_name: "North Henderson, IL",
    city_name: "North Henderson",
  },
  {
    postal_code: "74651",
    full_city_name: "Red Rock, OK",
    city_name: "Red Rock",
  },
  {
    postal_code: "51023",
    full_city_name: "Hawarden, IA",
    city_name: "Hawarden",
  },
  {
    postal_code: "79034",
    full_city_name: "Follett, TX",
    city_name: "Follett",
  },
  {
    postal_code: "31833",
    full_city_name: "West Point, GA",
    city_name: "West Point",
  },
  {
    postal_code: "37301",
    full_city_name: "Altamont, TN",
    city_name: "Altamont",
  },
  {
    postal_code: "03751",
    full_city_name: "Georges Mills, NH",
    city_name: "Georges Mills",
  },
  {
    postal_code: "67068",
    full_city_name: "Kingman, KS",
    city_name: "Kingman",
  },
  {
    postal_code: "83012",
    full_city_name: "Moose, WY",
    city_name: "Moose",
  },
  {
    postal_code: "50150",
    full_city_name: "Lovilia, IA",
    city_name: "Lovilia",
  },
  {
    postal_code: "87827",
    full_city_name: "Pie Town, NM",
    city_name: "Pie Town",
  },
  {
    postal_code: "39867",
    full_city_name: "Morris, GA",
    city_name: "Morris",
  },
  {
    postal_code: "07432",
    full_city_name: "Midland Park, NJ",
    city_name: "Midland Park",
  },
  {
    postal_code: "68301",
    full_city_name: "Adams, NE",
    city_name: "Adams",
  },
  {
    postal_code: "51444",
    full_city_name: "Halbur, IA",
    city_name: "Halbur",
  },
  {
    postal_code: "70659",
    full_city_name: "Rosepine, LA",
    city_name: "Rosepine",
  },
  {
    postal_code: "83542",
    full_city_name: "Lucile, ID",
    city_name: "Lucile",
  },
  {
    postal_code: "29175",
    full_city_name: "Westville, SC",
    city_name: "Westville",
  },
  {
    postal_code: "04763",
    full_city_name: "Oakfield, ME",
    city_name: "Oakfield",
  },
  {
    postal_code: "72364",
    full_city_name: "Marion, AR",
    city_name: "Marion",
  },
  {
    postal_code: "84733",
    full_city_name: "Gunlock, UT",
    city_name: "Gunlock",
  },
  {
    postal_code: "45306",
    full_city_name: "Botkins, OH",
    city_name: "Botkins",
  },
  {
    postal_code: "26161",
    full_city_name: "Petroleum, WV",
    city_name: "Petroleum",
  },
  {
    postal_code: "93528",
    full_city_name: "Johannesburg, CA",
    city_name: "Johannesburg",
  },
  {
    postal_code: "23357",
    full_city_name: "Greenbush, VA",
    city_name: "Greenbush",
  },
  {
    postal_code: "97480",
    full_city_name: "Swisshome, OR",
    city_name: "Swisshome",
  },
  {
    postal_code: "78041",
    full_city_name: "Laredo, TX",
    city_name: "Laredo",
  },
  {
    postal_code: "53083",
    full_city_name: "Sheboygan, WI",
    city_name: "Sheboygan",
  },
  {
    postal_code: "31516",
    full_city_name: "Blackshear, GA",
    city_name: "Blackshear",
  },
  {
    postal_code: "62803",
    full_city_name: "Hoyleton, IL",
    city_name: "Hoyleton",
  },
  {
    postal_code: "98363",
    full_city_name: "Port Angeles, WA",
    city_name: "Port Angeles",
  },
  {
    postal_code: "16634",
    full_city_name: "Dudley, PA",
    city_name: "Dudley",
  },
  {
    postal_code: "41332",
    full_city_name: "Hazel Green, KY",
    city_name: "Hazel Green",
  },
  {
    postal_code: "22821",
    full_city_name: "Dayton, VA",
    city_name: "Dayton",
  },
  {
    postal_code: "16224",
    full_city_name: "Fairmount City, PA",
    city_name: "Fairmount City",
  },
  {
    postal_code: "50540",
    full_city_name: "Fonda, IA",
    city_name: "Fonda",
  },
  {
    postal_code: "50120",
    full_city_name: "Haverhill, IA",
    city_name: "Haverhill",
  },
  {
    postal_code: "50562",
    full_city_name: "Mallard, IA",
    city_name: "Mallard",
  },
  {
    postal_code: "62449",
    full_city_name: "Oblong, IL",
    city_name: "Oblong",
  },
  {
    postal_code: "38030",
    full_city_name: "Finley, TN",
    city_name: "Finley",
  },
  {
    postal_code: "61482",
    full_city_name: "Table Grove, IL",
    city_name: "Table Grove",
  },
  {
    postal_code: "26351",
    full_city_name: "Glenville, WV",
    city_name: "Glenville",
  },
  {
    postal_code: "24868",
    full_city_name: "Northfork, WV",
    city_name: "Northfork",
  },
  {
    postal_code: "39320",
    full_city_name: "Bailey, MS",
    city_name: "Bailey",
  },
  {
    postal_code: "87729",
    full_city_name: "Miami, NM",
    city_name: "Miami",
  },
  {
    postal_code: "62476",
    full_city_name: "West Salem, IL",
    city_name: "West Salem",
  },
  {
    postal_code: "59871",
    full_city_name: "Sula, MT",
    city_name: "Sula",
  },
  {
    postal_code: "53088",
    full_city_name: "Stockbridge, WI",
    city_name: "Stockbridge",
  },
  {
    postal_code: "24426",
    full_city_name: "Covington, VA",
    city_name: "Covington",
  },
  {
    postal_code: "07626",
    full_city_name: "Cresskill, NJ",
    city_name: "Cresskill",
  },
  {
    postal_code: "41513",
    full_city_name: "Belcher, KY",
    city_name: "Belcher",
  },
  {
    postal_code: "68019",
    full_city_name: "Craig, NE",
    city_name: "Craig",
  },
  {
    postal_code: "43529",
    full_city_name: "Hoytville, OH",
    city_name: "Hoytville",
  },
  {
    postal_code: "62518",
    full_city_name: "Chestnut, IL",
    city_name: "Chestnut",
  },
  {
    postal_code: "38076",
    full_city_name: "Williston, TN",
    city_name: "Williston",
  },
  {
    postal_code: "70775",
    full_city_name: "Saint Francisville, LA",
    city_name: "Saint Francisville",
  },
  {
    postal_code: "22830",
    full_city_name: "Fulks Run, VA",
    city_name: "Fulks Run",
  },
  {
    postal_code: "25849",
    full_city_name: "Glen White, WV",
    city_name: "Glen White",
  },
  {
    postal_code: "92363",
    full_city_name: "Needles, CA",
    city_name: "Needles",
  },
  {
    postal_code: "45389",
    full_city_name: "Christiansburg, OH",
    city_name: "Christiansburg",
  },
  {
    postal_code: "71473",
    full_city_name: "Sikes, LA",
    city_name: "Sikes",
  },
  {
    postal_code: "10532",
    full_city_name: "Hawthorne, NY",
    city_name: "Hawthorne",
  },
  {
    postal_code: "49272",
    full_city_name: "Pleasant Lake, MI",
    city_name: "Pleasant Lake",
  },
  {
    postal_code: "81040",
    full_city_name: "Gardner, CO",
    city_name: "Gardner",
  },
  {
    postal_code: "24087",
    full_city_name: "Elliston, VA",
    city_name: "Elliston",
  },
  {
    postal_code: "68370",
    full_city_name: "Hebron, NE",
    city_name: "Hebron",
  },
  {
    postal_code: "24487",
    full_city_name: "Williamsville, VA",
    city_name: "Williamsville",
  },
  {
    postal_code: "68725",
    full_city_name: "Chambers, NE",
    city_name: "Chambers",
  },
  {
    postal_code: "66097",
    full_city_name: "Winchester, KS",
    city_name: "Winchester",
  },
  {
    postal_code: "57335",
    full_city_name: "Fairfax, SD",
    city_name: "Fairfax",
  },
  {
    postal_code: "99118",
    full_city_name: "Curlew, WA",
    city_name: "Curlew",
  },
  {
    postal_code: "40865",
    full_city_name: "Putney, KY",
    city_name: "Putney",
  },
  {
    postal_code: "71847",
    full_city_name: "Mc Caskill, AR",
    city_name: "Mc Caskill",
  },
  {
    postal_code: "59085",
    full_city_name: "Two Dot, MT",
    city_name: "Two Dot",
  },
  {
    postal_code: "61545",
    full_city_name: "Lowpoint, IL",
    city_name: "Lowpoint",
  },
  {
    postal_code: "76228",
    full_city_name: "Bellevue, TX",
    city_name: "Bellevue",
  },
  {
    postal_code: "88418",
    full_city_name: "Des Moines, NM",
    city_name: "Des Moines",
  },
  {
    postal_code: "21540",
    full_city_name: "Luke, MD",
    city_name: "Luke",
  },
  {
    postal_code: "94104",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "65649",
    full_city_name: "Fair Play, MO",
    city_name: "Fair Play",
  },
  {
    postal_code: "83420",
    full_city_name: "Ashton, ID",
    city_name: "Ashton",
  },
  {
    postal_code: "62277",
    full_city_name: "Prairie Du Rocher, IL",
    city_name: "Prairie Du Rocher",
  },
  {
    postal_code: "15925",
    full_city_name: "Cassandra, PA",
    city_name: "Cassandra",
  },
  {
    postal_code: "63563",
    full_city_name: "Rutledge, MO",
    city_name: "Rutledge",
  },
  {
    postal_code: "58237",
    full_city_name: "Grafton, ND",
    city_name: "Grafton",
  },
  {
    postal_code: "96046",
    full_city_name: "Hyampom, CA",
    city_name: "Hyampom",
  },
  {
    postal_code: "73047",
    full_city_name: "Hinton, OK",
    city_name: "Hinton",
  },
  {
    postal_code: "80116",
    full_city_name: "Franktown, CO",
    city_name: "Franktown",
  },
  {
    postal_code: "99664",
    full_city_name: "Seward, AK",
    city_name: "Seward",
  },
  {
    postal_code: "67860",
    full_city_name: "Lakin, KS",
    city_name: "Lakin",
  },
  {
    postal_code: "98832",
    full_city_name: "Marlin, WA",
    city_name: "Marlin",
  },
  {
    postal_code: "38627",
    full_city_name: "Etta, MS",
    city_name: "Etta",
  },
  {
    postal_code: "24985",
    full_city_name: "Wayside, WV",
    city_name: "Wayside",
  },
  {
    postal_code: "24484",
    full_city_name: "Warm Springs, VA",
    city_name: "Warm Springs",
  },
  {
    postal_code: "16319",
    full_city_name: "Cranberry, PA",
    city_name: "Cranberry",
  },
  {
    postal_code: "25966",
    full_city_name: "Green Sulphur Springs, WV",
    city_name: "Green Sulphur Springs",
  },
  {
    postal_code: "71401",
    full_city_name: "Aimwell, LA",
    city_name: "Aimwell",
  },
  {
    postal_code: "52345",
    full_city_name: "Urbana, IA",
    city_name: "Urbana",
  },
  {
    postal_code: "67745",
    full_city_name: "Mc Donald, KS",
    city_name: "Mc Donald",
  },
  {
    postal_code: "58035",
    full_city_name: "Galesburg, ND",
    city_name: "Galesburg",
  },
  {
    postal_code: "52073",
    full_city_name: "Sherrill, IA",
    city_name: "Sherrill",
  },
  {
    postal_code: "77962",
    full_city_name: "Ganado, TX",
    city_name: "Ganado",
  },
  {
    postal_code: "48350",
    full_city_name: "Davisburg, MI",
    city_name: "Davisburg",
  },
  {
    postal_code: "99631",
    full_city_name: "Moose Pass, AK",
    city_name: "Moose Pass",
  },
  {
    postal_code: "16879",
    full_city_name: "Winburne, PA",
    city_name: "Winburne",
  },
  {
    postal_code: "25621",
    full_city_name: "Gilbert, WV",
    city_name: "Gilbert",
  },
  {
    postal_code: "58009",
    full_city_name: "Blanchard, ND",
    city_name: "Blanchard",
  },
  {
    postal_code: "23302",
    full_city_name: "Assawoman, VA",
    city_name: "Assawoman",
  },
  {
    postal_code: "67834",
    full_city_name: "Bucklin, KS",
    city_name: "Bucklin",
  },
  {
    postal_code: "25529",
    full_city_name: "Julian, WV",
    city_name: "Julian",
  },
  {
    postal_code: "37183",
    full_city_name: "Wartrace, TN",
    city_name: "Wartrace",
  },
  {
    postal_code: "50642",
    full_city_name: "Holland, IA",
    city_name: "Holland",
  },
  {
    postal_code: "40813",
    full_city_name: "Calvin, KY",
    city_name: "Calvin",
  },
  {
    postal_code: "71972",
    full_city_name: "Vandervoort, AR",
    city_name: "Vandervoort",
  },
  {
    postal_code: "95017",
    full_city_name: "Davenport, CA",
    city_name: "Davenport",
  },
  {
    postal_code: "24175",
    full_city_name: "Troutville, VA",
    city_name: "Troutville",
  },
  {
    postal_code: "51050",
    full_city_name: "Remsen, IA",
    city_name: "Remsen",
  },
  {
    postal_code: "22476",
    full_city_name: "Hustle, VA",
    city_name: "Hustle",
  },
  {
    postal_code: "66423",
    full_city_name: "Eskridge, KS",
    city_name: "Eskridge",
  },
  {
    postal_code: "41535",
    full_city_name: "Huddy, KY",
    city_name: "Huddy",
  },
  {
    postal_code: "24938",
    full_city_name: "Frankford, WV",
    city_name: "Frankford",
  },
  {
    postal_code: "56022",
    full_city_name: "Darfur, MN",
    city_name: "Darfur",
  },
  {
    postal_code: "76661",
    full_city_name: "Marlin, TX",
    city_name: "Marlin",
  },
  {
    postal_code: "24132",
    full_city_name: "Parrott, VA",
    city_name: "Parrott",
  },
  {
    postal_code: "77359",
    full_city_name: "Oakhurst, TX",
    city_name: "Oakhurst",
  },
  {
    postal_code: "68332",
    full_city_name: "Crab Orchard, NE",
    city_name: "Crab Orchard",
  },
  {
    postal_code: "98840",
    full_city_name: "Okanogan, WA",
    city_name: "Okanogan",
  },
  {
    postal_code: "54967",
    full_city_name: "Poy Sippi, WI",
    city_name: "Poy Sippi",
  },
  {
    postal_code: "50477",
    full_city_name: "Swaledale, IA",
    city_name: "Swaledale",
  },
  {
    postal_code: "71038",
    full_city_name: "Haynesville, LA",
    city_name: "Haynesville",
  },
  {
    postal_code: "62689",
    full_city_name: "Thayer, IL",
    city_name: "Thayer",
  },
  {
    postal_code: "45656",
    full_city_name: "Oak Hill, OH",
    city_name: "Oak Hill",
  },
  {
    postal_code: "43926",
    full_city_name: "Empire, OH",
    city_name: "Empire",
  },
  {
    postal_code: "41642",
    full_city_name: "Ivel, KY",
    city_name: "Ivel",
  },
  {
    postal_code: "80216",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "72501",
    full_city_name: "Batesville, AR",
    city_name: "Batesville",
  },
  {
    postal_code: "66849",
    full_city_name: "Dwight, KS",
    city_name: "Dwight",
  },
  {
    postal_code: "35175",
    full_city_name: "Union Grove, AL",
    city_name: "Union Grove",
  },
  {
    postal_code: "78547",
    full_city_name: "Garciasville, TX",
    city_name: "Garciasville",
  },
  {
    postal_code: "24013",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "62691",
    full_city_name: "Virginia, IL",
    city_name: "Virginia",
  },
  {
    postal_code: "03607",
    full_city_name: "South Acworth, NH",
    city_name: "South Acworth",
  },
  {
    postal_code: "05447",
    full_city_name: "East Berkshire, VT",
    city_name: "East Berkshire",
  },
  {
    postal_code: "52321",
    full_city_name: "Onslow, IA",
    city_name: "Onslow",
  },
  {
    postal_code: "26691",
    full_city_name: "Tioga, WV",
    city_name: "Tioga",
  },
  {
    postal_code: "71820",
    full_city_name: "Alleene, AR",
    city_name: "Alleene",
  },
  {
    postal_code: "78043",
    full_city_name: "Laredo, TX",
    city_name: "Laredo",
  },
  {
    postal_code: "51355",
    full_city_name: "Okoboji, IA",
    city_name: "Okoboji",
  },
  {
    postal_code: "68644",
    full_city_name: "Lindsay, NE",
    city_name: "Lindsay",
  },
  {
    postal_code: "96705",
    full_city_name: "Eleele, HI",
    city_name: "Eleele",
  },
  {
    postal_code: "42330",
    full_city_name: "Central City, KY",
    city_name: "Central City",
  },
  {
    postal_code: "96058",
    full_city_name: "Macdoel, CA",
    city_name: "Macdoel",
  },
  {
    postal_code: "56257",
    full_city_name: "Marietta, MN",
    city_name: "Marietta",
  },
  {
    postal_code: "58641",
    full_city_name: "Lefor, ND",
    city_name: "Lefor",
  },
  {
    postal_code: "70750",
    full_city_name: "Krotz Springs, LA",
    city_name: "Krotz Springs",
  },
  {
    postal_code: "54493",
    full_city_name: "Willard, WI",
    city_name: "Willard",
  },
  {
    postal_code: "93621",
    full_city_name: "Dunlap, CA",
    city_name: "Dunlap",
  },
  {
    postal_code: "31031",
    full_city_name: "Gordon, GA",
    city_name: "Gordon",
  },
  {
    postal_code: "79033",
    full_city_name: "Farnsworth, TX",
    city_name: "Farnsworth",
  },
  {
    postal_code: "24430",
    full_city_name: "Craigsville, VA",
    city_name: "Craigsville",
  },
  {
    postal_code: "56280",
    full_city_name: "Porter, MN",
    city_name: "Porter",
  },
  {
    postal_code: "82930",
    full_city_name: "Evanston, WY",
    city_name: "Evanston",
  },
  {
    postal_code: "77977",
    full_city_name: "Placedo, TX",
    city_name: "Placedo",
  },
  {
    postal_code: "73458",
    full_city_name: "Springer, OK",
    city_name: "Springer",
  },
  {
    postal_code: "88354",
    full_city_name: "Weed, NM",
    city_name: "Weed",
  },
  {
    postal_code: "26205",
    full_city_name: "Craigsville, WV",
    city_name: "Craigsville",
  },
  {
    postal_code: "64670",
    full_city_name: "Pattonsburg, MO",
    city_name: "Pattonsburg",
  },
  {
    postal_code: "72332",
    full_city_name: "Edmondson, AR",
    city_name: "Edmondson",
  },
  {
    postal_code: "53935",
    full_city_name: "Friesland, WI",
    city_name: "Friesland",
  },
  {
    postal_code: "58461",
    full_city_name: "Litchville, ND",
    city_name: "Litchville",
  },
  {
    postal_code: "21920",
    full_city_name: "Elk Mills, MD",
    city_name: "Elk Mills",
  },
  {
    postal_code: "32683",
    full_city_name: "Otter Creek, FL",
    city_name: "Otter Creek",
  },
  {
    postal_code: "48139",
    full_city_name: "Hamburg, MI",
    city_name: "Hamburg",
  },
  {
    postal_code: "72742",
    full_city_name: "Kingston, AR",
    city_name: "Kingston",
  },
  {
    postal_code: "73645",
    full_city_name: "Erick, OK",
    city_name: "Erick",
  },
  {
    postal_code: "02071",
    full_city_name: "South Walpole, MA",
    city_name: "South Walpole",
  },
  {
    postal_code: "51004",
    full_city_name: "Anthon, IA",
    city_name: "Anthon",
  },
  {
    postal_code: "72429",
    full_city_name: "Fisher, AR",
    city_name: "Fisher",
  },
  {
    postal_code: "24627",
    full_city_name: "Mavisdale, VA",
    city_name: "Mavisdale",
  },
  {
    postal_code: "53222",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "23868",
    full_city_name: "Lawrenceville, VA",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "48874",
    full_city_name: "Pompeii, MI",
    city_name: "Pompeii",
  },
  {
    postal_code: "65344",
    full_city_name: "Miami, MO",
    city_name: "Miami",
  },
  {
    postal_code: "10922",
    full_city_name: "Fort Montgomery, NY",
    city_name: "Fort Montgomery",
  },
  {
    postal_code: "56121",
    full_city_name: "Ceylon, MN",
    city_name: "Ceylon",
  },
  {
    postal_code: "92318",
    full_city_name: "Bryn Mawr, CA",
    city_name: "Bryn Mawr",
  },
  {
    postal_code: "35594",
    full_city_name: "Winfield, AL",
    city_name: "Winfield",
  },
  {
    postal_code: "97641",
    full_city_name: "Christmas Valley, OR",
    city_name: "Christmas Valley",
  },
  {
    postal_code: "62410",
    full_city_name: "Allendale, IL",
    city_name: "Allendale",
  },
  {
    postal_code: "67144",
    full_city_name: "Towanda, KS",
    city_name: "Towanda",
  },
  {
    postal_code: "61371",
    full_city_name: "Triumph, IL",
    city_name: "Triumph",
  },
  {
    postal_code: "70805",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "59641",
    full_city_name: "Radersburg, MT",
    city_name: "Radersburg",
  },
  {
    postal_code: "43341",
    full_city_name: "New Bloomington, OH",
    city_name: "New Bloomington",
  },
  {
    postal_code: "27845",
    full_city_name: "Jackson, NC",
    city_name: "Jackson",
  },
  {
    postal_code: "53501",
    full_city_name: "Afton, WI",
    city_name: "Afton",
  },
  {
    postal_code: "36454",
    full_city_name: "Lenox, AL",
    city_name: "Lenox",
  },
  {
    postal_code: "95558",
    full_city_name: "Petrolia, CA",
    city_name: "Petrolia",
  },
  {
    postal_code: "99121",
    full_city_name: "Danville, WA",
    city_name: "Danville",
  },
  {
    postal_code: "88426",
    full_city_name: "Logan, NM",
    city_name: "Logan",
  },
  {
    postal_code: "75412",
    full_city_name: "Bagwell, TX",
    city_name: "Bagwell",
  },
  {
    postal_code: "57033",
    full_city_name: "Hartford, SD",
    city_name: "Hartford",
  },
  {
    postal_code: "18346",
    full_city_name: "Pocono Summit, PA",
    city_name: "Pocono Summit",
  },
  {
    postal_code: "36579",
    full_city_name: "Stockton, AL",
    city_name: "Stockton",
  },
  {
    postal_code: "98845",
    full_city_name: "Palisades, WA",
    city_name: "Palisades",
  },
  {
    postal_code: "41727",
    full_city_name: "Chavies, KY",
    city_name: "Chavies",
  },
  {
    postal_code: "30452",
    full_city_name: "Register, GA",
    city_name: "Register",
  },
  {
    postal_code: "98043",
    full_city_name: "Mountlake Terrace, WA",
    city_name: "Mountlake Terrace",
  },
  {
    postal_code: "39327",
    full_city_name: "Decatur, MS",
    city_name: "Decatur",
  },
  {
    postal_code: "72013",
    full_city_name: "Bee Branch, AR",
    city_name: "Bee Branch",
  },
  {
    postal_code: "63638",
    full_city_name: "Ellington, MO",
    city_name: "Ellington",
  },
  {
    postal_code: "58571",
    full_city_name: "Stanton, ND",
    city_name: "Stanton",
  },
  {
    postal_code: "47928",
    full_city_name: "Cayuga, IN",
    city_name: "Cayuga",
  },
  {
    postal_code: "83228",
    full_city_name: "Clifton, ID",
    city_name: "Clifton",
  },
  {
    postal_code: "62631",
    full_city_name: "Concord, IL",
    city_name: "Concord",
  },
  {
    postal_code: "50558",
    full_city_name: "Livermore, IA",
    city_name: "Livermore",
  },
  {
    postal_code: "50598",
    full_city_name: "Whittemore, IA",
    city_name: "Whittemore",
  },
  {
    postal_code: "47552",
    full_city_name: "Lincoln City, IN",
    city_name: "Lincoln City",
  },
  {
    postal_code: "84007",
    full_city_name: "Bluebell, UT",
    city_name: "Bluebell",
  },
  {
    postal_code: "47713",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "79534",
    full_city_name: "Mc Caulley, TX",
    city_name: "Mc Caulley",
  },
  {
    postal_code: "18245",
    full_city_name: "Quakake, PA",
    city_name: "Quakake",
  },
  {
    postal_code: "28676",
    full_city_name: "State Road, NC",
    city_name: "State Road",
  },
  {
    postal_code: "83114",
    full_city_name: "Cokeville, WY",
    city_name: "Cokeville",
  },
  {
    postal_code: "71331",
    full_city_name: "Effie, LA",
    city_name: "Effie",
  },
  {
    postal_code: "56162",
    full_city_name: "Ormsby, MN",
    city_name: "Ormsby",
  },
  {
    postal_code: "24318",
    full_city_name: "Ceres, VA",
    city_name: "Ceres",
  },
  {
    postal_code: "96028",
    full_city_name: "Fall River Mills, CA",
    city_name: "Fall River Mills",
  },
  {
    postal_code: "45692",
    full_city_name: "Wellston, OH",
    city_name: "Wellston",
  },
  {
    postal_code: "31783",
    full_city_name: "Rebecca, GA",
    city_name: "Rebecca",
  },
  {
    postal_code: "87045",
    full_city_name: "Prewitt, NM",
    city_name: "Prewitt",
  },
  {
    postal_code: "76874",
    full_city_name: "Roosevelt, TX",
    city_name: "Roosevelt",
  },
  {
    postal_code: "58497",
    full_city_name: "Ypsilanti, ND",
    city_name: "Ypsilanti",
  },
  {
    postal_code: "24860",
    full_city_name: "Matheny, WV",
    city_name: "Matheny",
  },
  {
    postal_code: "26384",
    full_city_name: "Linn, WV",
    city_name: "Linn",
  },
  {
    postal_code: "41555",
    full_city_name: "Pinsonfork, KY",
    city_name: "Pinsonfork",
  },
  {
    postal_code: "99166",
    full_city_name: "Republic, WA",
    city_name: "Republic",
  },
  {
    postal_code: "52213",
    full_city_name: "Center Point, IA",
    city_name: "Center Point",
  },
  {
    postal_code: "54113",
    full_city_name: "Combined Locks, WI",
    city_name: "Combined Locks",
  },
  {
    postal_code: "05483",
    full_city_name: "Sheldon, VT",
    city_name: "Sheldon",
  },
  {
    postal_code: "98051",
    full_city_name: "Ravensdale, WA",
    city_name: "Ravensdale",
  },
  {
    postal_code: "53044",
    full_city_name: "Kohler, WI",
    city_name: "Kohler",
  },
  {
    postal_code: "51550",
    full_city_name: "Magnolia, IA",
    city_name: "Magnolia",
  },
  {
    postal_code: "42273",
    full_city_name: "Rochester, KY",
    city_name: "Rochester",
  },
  {
    postal_code: "18817",
    full_city_name: "East Smithfield, PA",
    city_name: "East Smithfield",
  },
  {
    postal_code: "98624",
    full_city_name: "Ilwaco, WA",
    city_name: "Ilwaco",
  },
  {
    postal_code: "63536",
    full_city_name: "Downing, MO",
    city_name: "Downing",
  },
  {
    postal_code: "60949",
    full_city_name: "Ludlow, IL",
    city_name: "Ludlow",
  },
  {
    postal_code: "54440",
    full_city_name: "Hatley, WI",
    city_name: "Hatley",
  },
  {
    postal_code: "98014",
    full_city_name: "Carnation, WA",
    city_name: "Carnation",
  },
  {
    postal_code: "52338",
    full_city_name: "Swisher, IA",
    city_name: "Swisher",
  },
  {
    postal_code: "88040",
    full_city_name: "Hachita, NM",
    city_name: "Hachita",
  },
  {
    postal_code: "59353",
    full_city_name: "Wibaux, MT",
    city_name: "Wibaux",
  },
  {
    postal_code: "70661",
    full_city_name: "Starks, LA",
    city_name: "Starks",
  },
  {
    postal_code: "49268",
    full_city_name: "Palmyra, MI",
    city_name: "Palmyra",
  },
  {
    postal_code: "97410",
    full_city_name: "Azalea, OR",
    city_name: "Azalea",
  },
  {
    postal_code: "82723",
    full_city_name: "Osage, WY",
    city_name: "Osage",
  },
  {
    postal_code: "04476",
    full_city_name: "Penobscot, ME",
    city_name: "Penobscot",
  },
  {
    postal_code: "67073",
    full_city_name: "Lehigh, KS",
    city_name: "Lehigh",
  },
  {
    postal_code: "61602",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "41232",
    full_city_name: "Lowmansville, KY",
    city_name: "Lowmansville",
  },
  {
    postal_code: "49791",
    full_city_name: "Topinabee, MI",
    city_name: "Topinabee",
  },
  {
    postal_code: "63431",
    full_city_name: "Anabel, MO",
    city_name: "Anabel",
  },
  {
    postal_code: "25264",
    full_city_name: "Mount Alto, WV",
    city_name: "Mount Alto",
  },
  {
    postal_code: "99610",
    full_city_name: "Kasilof, AK",
    city_name: "Kasilof",
  },
  {
    postal_code: "98934",
    full_city_name: "Kittitas, WA",
    city_name: "Kittitas",
  },
  {
    postal_code: "50470",
    full_city_name: "Rowan, IA",
    city_name: "Rowan",
  },
  {
    postal_code: "38633",
    full_city_name: "Hickory Flat, MS",
    city_name: "Hickory Flat",
  },
  {
    postal_code: "41539",
    full_city_name: "Kimper, KY",
    city_name: "Kimper",
  },
  {
    postal_code: "18701",
    full_city_name: "Wilkes Barre, PA",
    city_name: "Wilkes Barre",
  },
  {
    postal_code: "27957",
    full_city_name: "Merry Hill, NC",
    city_name: "Merry Hill",
  },
  {
    postal_code: "15763",
    full_city_name: "Northpoint, PA",
    city_name: "Northpoint",
  },
  {
    postal_code: "62954",
    full_city_name: "Junction, IL",
    city_name: "Junction",
  },
  {
    postal_code: "20692",
    full_city_name: "Valley Lee, MD",
    city_name: "Valley Lee",
  },
  {
    postal_code: "64681",
    full_city_name: "Sumner, MO",
    city_name: "Sumner",
  },
  {
    postal_code: "23603",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "46731",
    full_city_name: "Craigville, IN",
    city_name: "Craigville",
  },
  {
    postal_code: "46742",
    full_city_name: "Hamilton, IN",
    city_name: "Hamilton",
  },
  {
    postal_code: "72839",
    full_city_name: "Hagarville, AR",
    city_name: "Hagarville",
  },
  {
    postal_code: "88416",
    full_city_name: "Conchas Dam, NM",
    city_name: "Conchas Dam",
  },
  {
    postal_code: "12851",
    full_city_name: "Minerva, NY",
    city_name: "Minerva",
  },
  {
    postal_code: "84621",
    full_city_name: "Axtell, UT",
    city_name: "Axtell",
  },
  {
    postal_code: "04041",
    full_city_name: "Hiram, ME",
    city_name: "Hiram",
  },
  {
    postal_code: "56157",
    full_city_name: "Lynd, MN",
    city_name: "Lynd",
  },
  {
    postal_code: "14806",
    full_city_name: "Andover, NY",
    city_name: "Andover",
  },
  {
    postal_code: "08348",
    full_city_name: "Port Elizabeth, NJ",
    city_name: "Port Elizabeth",
  },
  {
    postal_code: "65243",
    full_city_name: "Clark, MO",
    city_name: "Clark",
  },
  {
    postal_code: "85320",
    full_city_name: "Aguila, AZ",
    city_name: "Aguila",
  },
  {
    postal_code: "56568",
    full_city_name: "Nielsville, MN",
    city_name: "Nielsville",
  },
  {
    postal_code: "81055",
    full_city_name: "La Veta, CO",
    city_name: "La Veta",
  },
  {
    postal_code: "80449",
    full_city_name: "Hartsel, CO",
    city_name: "Hartsel",
  },
  {
    postal_code: "15225",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "18079",
    full_city_name: "Slatedale, PA",
    city_name: "Slatedale",
  },
  {
    postal_code: "52333",
    full_city_name: "Solon, IA",
    city_name: "Solon",
  },
  {
    postal_code: "49250",
    full_city_name: "Jonesville, MI",
    city_name: "Jonesville",
  },
  {
    postal_code: "66868",
    full_city_name: "Reading, KS",
    city_name: "Reading",
  },
  {
    postal_code: "48765",
    full_city_name: "Turner, MI",
    city_name: "Turner",
  },
  {
    postal_code: "49113",
    full_city_name: "Galien, MI",
    city_name: "Galien",
  },
  {
    postal_code: "35906",
    full_city_name: "Rainbow City, AL",
    city_name: "Rainbow City",
  },
  {
    postal_code: "63954",
    full_city_name: "Neelyville, MO",
    city_name: "Neelyville",
  },
  {
    postal_code: "46968",
    full_city_name: "Ora, IN",
    city_name: "Ora",
  },
  {
    postal_code: "72945",
    full_city_name: "Midland, AR",
    city_name: "Midland",
  },
  {
    postal_code: "12729",
    full_city_name: "Cuddebackville, NY",
    city_name: "Cuddebackville",
  },
  {
    postal_code: "49055",
    full_city_name: "Gobles, MI",
    city_name: "Gobles",
  },
  {
    postal_code: "98550",
    full_city_name: "Hoquiam, WA",
    city_name: "Hoquiam",
  },
  {
    postal_code: "14546",
    full_city_name: "Scottsville, NY",
    city_name: "Scottsville",
  },
  {
    postal_code: "53013",
    full_city_name: "Cedar Grove, WI",
    city_name: "Cedar Grove",
  },
  {
    postal_code: "80423",
    full_city_name: "Bond, CO",
    city_name: "Bond",
  },
  {
    postal_code: "38626",
    full_city_name: "Dundee, MS",
    city_name: "Dundee",
  },
  {
    postal_code: "87517",
    full_city_name: "Carson, NM",
    city_name: "Carson",
  },
  {
    postal_code: "64479",
    full_city_name: "Ravenwood, MO",
    city_name: "Ravenwood",
  },
  {
    postal_code: "23457",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "36617",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "56119",
    full_city_name: "Brewster, MN",
    city_name: "Brewster",
  },
  {
    postal_code: "48897",
    full_city_name: "Woodland, MI",
    city_name: "Woodland",
  },
  {
    postal_code: "58222",
    full_city_name: "Crystal, ND",
    city_name: "Crystal",
  },
  {
    postal_code: "50157",
    full_city_name: "Malcom, IA",
    city_name: "Malcom",
  },
  {
    postal_code: "39040",
    full_city_name: "Bentonia, MS",
    city_name: "Bentonia",
  },
  {
    postal_code: "97844",
    full_city_name: "Irrigon, OR",
    city_name: "Irrigon",
  },
  {
    postal_code: "70076",
    full_city_name: "Mount Airy, LA",
    city_name: "Mount Airy",
  },
  {
    postal_code: "25671",
    full_city_name: "Dingess, WV",
    city_name: "Dingess",
  },
  {
    postal_code: "52737",
    full_city_name: "Columbus City, IA",
    city_name: "Columbus City",
  },
  {
    postal_code: "46992",
    full_city_name: "Wabash, IN",
    city_name: "Wabash",
  },
  {
    postal_code: "52202",
    full_city_name: "Alburnett, IA",
    city_name: "Alburnett",
  },
  {
    postal_code: "05649",
    full_city_name: "East Barre, VT",
    city_name: "East Barre",
  },
  {
    postal_code: "62975",
    full_city_name: "Pomona, IL",
    city_name: "Pomona",
  },
  {
    postal_code: "65660",
    full_city_name: "Graff, MO",
    city_name: "Graff",
  },
  {
    postal_code: "57259",
    full_city_name: "Revillo, SD",
    city_name: "Revillo",
  },
  {
    postal_code: "65785",
    full_city_name: "Stockton, MO",
    city_name: "Stockton",
  },
  {
    postal_code: "57638",
    full_city_name: "Lemmon, SD",
    city_name: "Lemmon",
  },
  {
    postal_code: "16667",
    full_city_name: "Osterburg, PA",
    city_name: "Osterburg",
  },
  {
    postal_code: "14113",
    full_city_name: "North Java, NY",
    city_name: "North Java",
  },
  {
    postal_code: "66840",
    full_city_name: "Burns, KS",
    city_name: "Burns",
  },
  {
    postal_code: "96089",
    full_city_name: "Shasta Lake, CA",
    city_name: "Shasta Lake",
  },
  {
    postal_code: "62248",
    full_city_name: "Hecker, IL",
    city_name: "Hecker",
  },
  {
    postal_code: "61913",
    full_city_name: "Atwood, IL",
    city_name: "Atwood",
  },
  {
    postal_code: "15433",
    full_city_name: "East Millsboro, PA",
    city_name: "East Millsboro",
  },
  {
    postal_code: "46615",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "56453",
    full_city_name: "Hewitt, MN",
    city_name: "Hewitt",
  },
  {
    postal_code: "35579",
    full_city_name: "Oakman, AL",
    city_name: "Oakman",
  },
  {
    postal_code: "14737",
    full_city_name: "Franklinville, NY",
    city_name: "Franklinville",
  },
  {
    postal_code: "63665",
    full_city_name: "Redford, MO",
    city_name: "Redford",
  },
  {
    postal_code: "87527",
    full_city_name: "Dixon, NM",
    city_name: "Dixon",
  },
  {
    postal_code: "69355",
    full_city_name: "Melbeta, NE",
    city_name: "Melbeta",
  },
  {
    postal_code: "75651",
    full_city_name: "Harleton, TX",
    city_name: "Harleton",
  },
  {
    postal_code: "95555",
    full_city_name: "Orick, CA",
    city_name: "Orick",
  },
  {
    postal_code: "66958",
    full_city_name: "Morrowville, KS",
    city_name: "Morrowville",
  },
  {
    postal_code: "31552",
    full_city_name: "Millwood, GA",
    city_name: "Millwood",
  },
  {
    postal_code: "17262",
    full_city_name: "Spring Run, PA",
    city_name: "Spring Run",
  },
  {
    postal_code: "23976",
    full_city_name: "Wylliesburg, VA",
    city_name: "Wylliesburg",
  },
  {
    postal_code: "35739",
    full_city_name: "Ardmore, AL",
    city_name: "Ardmore",
  },
  {
    postal_code: "15054",
    full_city_name: "Langeloth, PA",
    city_name: "Langeloth",
  },
  {
    postal_code: "61871",
    full_city_name: "Royal, IL",
    city_name: "Royal",
  },
  {
    postal_code: "69128",
    full_city_name: "Bushnell, NE",
    city_name: "Bushnell",
  },
  {
    postal_code: "10923",
    full_city_name: "Garnerville, NY",
    city_name: "Garnerville",
  },
  {
    postal_code: "15551",
    full_city_name: "Markleton, PA",
    city_name: "Markleton",
  },
  {
    postal_code: "58265",
    full_city_name: "Neche, ND",
    city_name: "Neche",
  },
  {
    postal_code: "98303",
    full_city_name: "Anderson Island, WA",
    city_name: "Anderson Island",
  },
  {
    postal_code: "35543",
    full_city_name: "Bear Creek, AL",
    city_name: "Bear Creek",
  },
  {
    postal_code: "57532",
    full_city_name: "Fort Pierre, SD",
    city_name: "Fort Pierre",
  },
  {
    postal_code: "76624",
    full_city_name: "Axtell, TX",
    city_name: "Axtell",
  },
  {
    postal_code: "71486",
    full_city_name: "Zwolle, LA",
    city_name: "Zwolle",
  },
  {
    postal_code: "46802",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "35592",
    full_city_name: "Vernon, AL",
    city_name: "Vernon",
  },
  {
    postal_code: "62950",
    full_city_name: "Jacob, IL",
    city_name: "Jacob",
  },
  {
    postal_code: "04939",
    full_city_name: "Garland, ME",
    city_name: "Garland",
  },
  {
    postal_code: "58632",
    full_city_name: "Golva, ND",
    city_name: "Golva",
  },
  {
    postal_code: "46528",
    full_city_name: "Goshen, IN",
    city_name: "Goshen",
  },
  {
    postal_code: "52171",
    full_city_name: "Waucoma, IA",
    city_name: "Waucoma",
  },
  {
    postal_code: "67360",
    full_city_name: "Peru, KS",
    city_name: "Peru",
  },
  {
    postal_code: "36756",
    full_city_name: "Marion, AL",
    city_name: "Marion",
  },
  {
    postal_code: "61773",
    full_city_name: "Sibley, IL",
    city_name: "Sibley",
  },
  {
    postal_code: "47034",
    full_city_name: "Napoleon, IN",
    city_name: "Napoleon",
  },
  {
    postal_code: "67120",
    full_city_name: "Peck, KS",
    city_name: "Peck",
  },
  {
    postal_code: "17238",
    full_city_name: "Needmore, PA",
    city_name: "Needmore",
  },
  {
    postal_code: "59226",
    full_city_name: "Froid, MT",
    city_name: "Froid",
  },
  {
    postal_code: "73059",
    full_city_name: "Minco, OK",
    city_name: "Minco",
  },
  {
    postal_code: "76869",
    full_city_name: "Pontotoc, TX",
    city_name: "Pontotoc",
  },
  {
    postal_code: "67751",
    full_city_name: "Park, KS",
    city_name: "Park",
  },
  {
    postal_code: "71758",
    full_city_name: "Mount Holly, AR",
    city_name: "Mount Holly",
  },
  {
    postal_code: "69026",
    full_city_name: "Danbury, NE",
    city_name: "Danbury",
  },
  {
    postal_code: "38954",
    full_city_name: "Sidon, MS",
    city_name: "Sidon",
  },
  {
    postal_code: "62985",
    full_city_name: "Simpson, IL",
    city_name: "Simpson",
  },
  {
    postal_code: "68419",
    full_city_name: "Panama, NE",
    city_name: "Panama",
  },
  {
    postal_code: "79752",
    full_city_name: "Mc Camey, TX",
    city_name: "Mc Camey",
  },
  {
    postal_code: "68785",
    full_city_name: "Waterbury, NE",
    city_name: "Waterbury",
  },
  {
    postal_code: "71250",
    full_city_name: "Jones, LA",
    city_name: "Jones",
  },
  {
    postal_code: "47136",
    full_city_name: "Lanesville, IN",
    city_name: "Lanesville",
  },
  {
    postal_code: "47974",
    full_city_name: "Perrysville, IN",
    city_name: "Perrysville",
  },
  {
    postal_code: "37085",
    full_city_name: "Lascassas, TN",
    city_name: "Lascassas",
  },
  {
    postal_code: "54420",
    full_city_name: "Chili, WI",
    city_name: "Chili",
  },
  {
    postal_code: "43006",
    full_city_name: "Brinkhaven, OH",
    city_name: "Brinkhaven",
  },
  {
    postal_code: "87940",
    full_city_name: "Rincon, NM",
    city_name: "Rincon",
  },
  {
    postal_code: "39152",
    full_city_name: "Pulaski, MS",
    city_name: "Pulaski",
  },
  {
    postal_code: "48754",
    full_city_name: "Owendale, MI",
    city_name: "Owendale",
  },
  {
    postal_code: "73092",
    full_city_name: "Verden, OK",
    city_name: "Verden",
  },
  {
    postal_code: "41201",
    full_city_name: "Adams, KY",
    city_name: "Adams",
  },
  {
    postal_code: "69170",
    full_city_name: "Wellfleet, NE",
    city_name: "Wellfleet",
  },
  {
    postal_code: "72479",
    full_city_name: "Weiner, AR",
    city_name: "Weiner",
  },
  {
    postal_code: "68979",
    full_city_name: "Sutton, NE",
    city_name: "Sutton",
  },
  {
    postal_code: "74963",
    full_city_name: "Watson, OK",
    city_name: "Watson",
  },
  {
    postal_code: "72372",
    full_city_name: "Palestine, AR",
    city_name: "Palestine",
  },
  {
    postal_code: "62459",
    full_city_name: "Sainte Marie, IL",
    city_name: "Sainte Marie",
  },
  {
    postal_code: "99133",
    full_city_name: "Grand Coulee, WA",
    city_name: "Grand Coulee",
  },
  {
    postal_code: "68517",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "79086",
    full_city_name: "Sunray, TX",
    city_name: "Sunray",
  },
  {
    postal_code: "67142",
    full_city_name: "Spivey, KS",
    city_name: "Spivey",
  },
  {
    postal_code: "20838",
    full_city_name: "Barnesville, MD",
    city_name: "Barnesville",
  },
  {
    postal_code: "16230",
    full_city_name: "Hawthorn, PA",
    city_name: "Hawthorn",
  },
  {
    postal_code: "62534",
    full_city_name: "Findlay, IL",
    city_name: "Findlay",
  },
  {
    postal_code: "26444",
    full_city_name: "Tunnelton, WV",
    city_name: "Tunnelton",
  },
  {
    postal_code: "26767",
    full_city_name: "Wiley Ford, WV",
    city_name: "Wiley Ford",
  },
  {
    postal_code: "13859",
    full_city_name: "Wells Bridge, NY",
    city_name: "Wells Bridge",
  },
  {
    postal_code: "18920",
    full_city_name: "Erwinna, PA",
    city_name: "Erwinna",
  },
  {
    postal_code: "81655",
    full_city_name: "Wolcott, CO",
    city_name: "Wolcott",
  },
  {
    postal_code: "51333",
    full_city_name: "Dickens, IA",
    city_name: "Dickens",
  },
  {
    postal_code: "71226",
    full_city_name: "Chatham, LA",
    city_name: "Chatham",
  },
  {
    postal_code: "02576",
    full_city_name: "West Wareham, MA",
    city_name: "West Wareham",
  },
  {
    postal_code: "57567",
    full_city_name: "Philip, SD",
    city_name: "Philip",
  },
  {
    postal_code: "04411",
    full_city_name: "Bradley, ME",
    city_name: "Bradley",
  },
  {
    postal_code: "41635",
    full_city_name: "Harold, KY",
    city_name: "Harold",
  },
  {
    postal_code: "47561",
    full_city_name: "Oaktown, IN",
    city_name: "Oaktown",
  },
  {
    postal_code: "49102",
    full_city_name: "Berrien Center, MI",
    city_name: "Berrien Center",
  },
  {
    postal_code: "67038",
    full_city_name: "Dexter, KS",
    city_name: "Dexter",
  },
  {
    postal_code: "51015",
    full_city_name: "Climbing Hill, IA",
    city_name: "Climbing Hill",
  },
  {
    postal_code: "62546",
    full_city_name: "Morrisonville, IL",
    city_name: "Morrisonville",
  },
  {
    postal_code: "65262",
    full_city_name: "Kingdom City, MO",
    city_name: "Kingdom City",
  },
  {
    postal_code: "07662",
    full_city_name: "Rochelle Park, NJ",
    city_name: "Rochelle Park",
  },
  {
    postal_code: "36263",
    full_city_name: "Graham, AL",
    city_name: "Graham",
  },
  {
    postal_code: "16685",
    full_city_name: "Todd, PA",
    city_name: "Todd",
  },
  {
    postal_code: "68854",
    full_city_name: "Marquette, NE",
    city_name: "Marquette",
  },
  {
    postal_code: "49227",
    full_city_name: "Allen, MI",
    city_name: "Allen",
  },
  {
    postal_code: "25266",
    full_city_name: "Newton, WV",
    city_name: "Newton",
  },
  {
    postal_code: "97906",
    full_city_name: "Harper, OR",
    city_name: "Harper",
  },
  {
    postal_code: "26276",
    full_city_name: "Kerens, WV",
    city_name: "Kerens",
  },
  {
    postal_code: "57429",
    full_city_name: "Brentford, SD",
    city_name: "Brentford",
  },
  {
    postal_code: "69027",
    full_city_name: "Enders, NE",
    city_name: "Enders",
  },
  {
    postal_code: "72433",
    full_city_name: "Hoxie, AR",
    city_name: "Hoxie",
  },
  {
    postal_code: "66552",
    full_city_name: "Whiting, KS",
    city_name: "Whiting",
  },
  {
    postal_code: "15082",
    full_city_name: "Sturgeon, PA",
    city_name: "Sturgeon",
  },
  {
    postal_code: "68626",
    full_city_name: "Brainard, NE",
    city_name: "Brainard",
  },
  {
    postal_code: "62081",
    full_city_name: "Rockbridge, IL",
    city_name: "Rockbridge",
  },
  {
    postal_code: "24715",
    full_city_name: "Bramwell, WV",
    city_name: "Bramwell",
  },
  {
    postal_code: "54854",
    full_city_name: "Maple, WI",
    city_name: "Maple",
  },
  {
    postal_code: "72828",
    full_city_name: "Briggsville, AR",
    city_name: "Briggsville",
  },
  {
    postal_code: "53045",
    full_city_name: "Brookfield, WI",
    city_name: "Brookfield",
  },
  {
    postal_code: "47341",
    full_city_name: "Fountain City, IN",
    city_name: "Fountain City",
  },
  {
    postal_code: "35466",
    full_city_name: "Gordo, AL",
    city_name: "Gordo",
  },
  {
    postal_code: "67553",
    full_city_name: "Liebenthal, KS",
    city_name: "Liebenthal",
  },
  {
    postal_code: "36728",
    full_city_name: "Catherine, AL",
    city_name: "Catherine",
  },
  {
    postal_code: "29832",
    full_city_name: "Johnston, SC",
    city_name: "Johnston",
  },
  {
    postal_code: "18844",
    full_city_name: "Springville, PA",
    city_name: "Springville",
  },
  {
    postal_code: "05902",
    full_city_name: "Beecher Falls, VT",
    city_name: "Beecher Falls",
  },
  {
    postal_code: "64035",
    full_city_name: "Hardin, MO",
    city_name: "Hardin",
  },
  {
    postal_code: "61312",
    full_city_name: "Arlington, IL",
    city_name: "Arlington",
  },
  {
    postal_code: "23502",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "28007",
    full_city_name: "Ansonville, NC",
    city_name: "Ansonville",
  },
  {
    postal_code: "47457",
    full_city_name: "Scotland, IN",
    city_name: "Scotland",
  },
  {
    postal_code: "21814",
    full_city_name: "Bivalve, MD",
    city_name: "Bivalve",
  },
  {
    postal_code: "98396",
    full_city_name: "Wilkeson, WA",
    city_name: "Wilkeson",
  },
  {
    postal_code: "99341",
    full_city_name: "Lind, WA",
    city_name: "Lind",
  },
  {
    postal_code: "57010",
    full_city_name: "Burbank, SD",
    city_name: "Burbank",
  },
  {
    postal_code: "61318",
    full_city_name: "Compton, IL",
    city_name: "Compton",
  },
  {
    postal_code: "38449",
    full_city_name: "Ardmore, TN",
    city_name: "Ardmore",
  },
  {
    postal_code: "49323",
    full_city_name: "Dorr, MI",
    city_name: "Dorr",
  },
  {
    postal_code: "74450",
    full_city_name: "Oktaha, OK",
    city_name: "Oktaha",
  },
  {
    postal_code: "82932",
    full_city_name: "Farson, WY",
    city_name: "Farson",
  },
  {
    postal_code: "56138",
    full_city_name: "Hills, MN",
    city_name: "Hills",
  },
  {
    postal_code: "72858",
    full_city_name: "Pottsville, AR",
    city_name: "Pottsville",
  },
  {
    postal_code: "50426",
    full_city_name: "Carpenter, IA",
    city_name: "Carpenter",
  },
  {
    postal_code: "67839",
    full_city_name: "Dighton, KS",
    city_name: "Dighton",
  },
  {
    postal_code: "59829",
    full_city_name: "Darby, MT",
    city_name: "Darby",
  },
  {
    postal_code: "82421",
    full_city_name: "Deaver, WY",
    city_name: "Deaver",
  },
  {
    postal_code: "18343",
    full_city_name: "Mount Bethel, PA",
    city_name: "Mount Bethel",
  },
  {
    postal_code: "67353",
    full_city_name: "Moline, KS",
    city_name: "Moline",
  },
  {
    postal_code: "78021",
    full_city_name: "Fowlerton, TX",
    city_name: "Fowlerton",
  },
  {
    postal_code: "56263",
    full_city_name: "Milroy, MN",
    city_name: "Milroy",
  },
  {
    postal_code: "39737",
    full_city_name: "Bellefontaine, MS",
    city_name: "Bellefontaine",
  },
  {
    postal_code: "58580",
    full_city_name: "Zap, ND",
    city_name: "Zap",
  },
  {
    postal_code: "31796",
    full_city_name: "Warwick, GA",
    city_name: "Warwick",
  },
  {
    postal_code: "97830",
    full_city_name: "Fossil, OR",
    city_name: "Fossil",
  },
  {
    postal_code: "25154",
    full_city_name: "Peytona, WV",
    city_name: "Peytona",
  },
  {
    postal_code: "65261",
    full_city_name: "Keytesville, MO",
    city_name: "Keytesville",
  },
  {
    postal_code: "10543",
    full_city_name: "Mamaroneck, NY",
    city_name: "Mamaroneck",
  },
  {
    postal_code: "26148",
    full_city_name: "Macfarlan, WV",
    city_name: "Macfarlan",
  },
  {
    postal_code: "85332",
    full_city_name: "Congress, AZ",
    city_name: "Congress",
  },
  {
    postal_code: "25132",
    full_city_name: "Mammoth, WV",
    city_name: "Mammoth",
  },
  {
    postal_code: "66713",
    full_city_name: "Baxter Springs, KS",
    city_name: "Baxter Springs",
  },
  {
    postal_code: "71082",
    full_city_name: "Vivian, LA",
    city_name: "Vivian",
  },
  {
    postal_code: "93563",
    full_city_name: "Valyermo, CA",
    city_name: "Valyermo",
  },
  {
    postal_code: "10502",
    full_city_name: "Ardsley, NY",
    city_name: "Ardsley",
  },
  {
    postal_code: "35452",
    full_city_name: "Coker, AL",
    city_name: "Coker",
  },
  {
    postal_code: "58579",
    full_city_name: "Wilton, ND",
    city_name: "Wilton",
  },
  {
    postal_code: "37062",
    full_city_name: "Fairview, TN",
    city_name: "Fairview",
  },
  {
    postal_code: "27922",
    full_city_name: "Cofield, NC",
    city_name: "Cofield",
  },
  {
    postal_code: "67149",
    full_city_name: "Viola, KS",
    city_name: "Viola",
  },
  {
    postal_code: "47615",
    full_city_name: "Grandview, IN",
    city_name: "Grandview",
  },
  {
    postal_code: "03441",
    full_city_name: "Ashuelot, NH",
    city_name: "Ashuelot",
  },
  {
    postal_code: "37145",
    full_city_name: "Pleasant Shade, TN",
    city_name: "Pleasant Shade",
  },
  {
    postal_code: "46784",
    full_city_name: "Rome City, IN",
    city_name: "Rome City",
  },
  {
    postal_code: "65083",
    full_city_name: "Ulman, MO",
    city_name: "Ulman",
  },
  {
    postal_code: "83434",
    full_city_name: "Menan, ID",
    city_name: "Menan",
  },
  {
    postal_code: "37710",
    full_city_name: "Briceville, TN",
    city_name: "Briceville",
  },
  {
    postal_code: "72386",
    full_city_name: "Tyronza, AR",
    city_name: "Tyronza",
  },
  {
    postal_code: "47633",
    full_city_name: "Poseyville, IN",
    city_name: "Poseyville",
  },
  {
    postal_code: "28646",
    full_city_name: "Linville, NC",
    city_name: "Linville",
  },
  {
    postal_code: "07663",
    full_city_name: "Saddle Brook, NJ",
    city_name: "Saddle Brook",
  },
  {
    postal_code: "59485",
    full_city_name: "Ulm, MT",
    city_name: "Ulm",
  },
  {
    postal_code: "62446",
    full_city_name: "Mount Erie, IL",
    city_name: "Mount Erie",
  },
  {
    postal_code: "64443",
    full_city_name: "Easton, MO",
    city_name: "Easton",
  },
  {
    postal_code: "30802",
    full_city_name: "Appling, GA",
    city_name: "Appling",
  },
  {
    postal_code: "50434",
    full_city_name: "Fertile, IA",
    city_name: "Fertile",
  },
  {
    postal_code: "97063",
    full_city_name: "Tygh Valley, OR",
    city_name: "Tygh Valley",
  },
  {
    postal_code: "53046",
    full_city_name: "Lannon, WI",
    city_name: "Lannon",
  },
  {
    postal_code: "04685",
    full_city_name: "Swans Island, ME",
    city_name: "Swans Island",
  },
  {
    postal_code: "50599",
    full_city_name: "Woolstock, IA",
    city_name: "Woolstock",
  },
  {
    postal_code: "38646",
    full_city_name: "Marks, MS",
    city_name: "Marks",
  },
  {
    postal_code: "38925",
    full_city_name: "Duck Hill, MS",
    city_name: "Duck Hill",
  },
  {
    postal_code: "74845",
    full_city_name: "Hanna, OK",
    city_name: "Hanna",
  },
  {
    postal_code: "87062",
    full_city_name: "Veguita, NM",
    city_name: "Veguita",
  },
  {
    postal_code: "65335",
    full_city_name: "Ionia, MO",
    city_name: "Ionia",
  },
  {
    postal_code: "46068",
    full_city_name: "Sharpsville, IN",
    city_name: "Sharpsville",
  },
  {
    postal_code: "50432",
    full_city_name: "Crystal Lake, IA",
    city_name: "Crystal Lake",
  },
  {
    postal_code: "16927",
    full_city_name: "Harrison Valley, PA",
    city_name: "Harrison Valley",
  },
  {
    postal_code: "84665",
    full_city_name: "Sterling, UT",
    city_name: "Sterling",
  },
  {
    postal_code: "87001",
    full_city_name: "Algodones, NM",
    city_name: "Algodones",
  },
  {
    postal_code: "44864",
    full_city_name: "Perrysville, OH",
    city_name: "Perrysville",
  },
  {
    postal_code: "40052",
    full_city_name: "New Hope, KY",
    city_name: "New Hope",
  },
  {
    postal_code: "41216",
    full_city_name: "East Point, KY",
    city_name: "East Point",
  },
  {
    postal_code: "61940",
    full_city_name: "Metcalf, IL",
    city_name: "Metcalf",
  },
  {
    postal_code: "25444",
    full_city_name: "Slanesville, WV",
    city_name: "Slanesville",
  },
  {
    postal_code: "39307",
    full_city_name: "Meridian, MS",
    city_name: "Meridian",
  },
  {
    postal_code: "23315",
    full_city_name: "Carrsville, VA",
    city_name: "Carrsville",
  },
  {
    postal_code: "59317",
    full_city_name: "Broadus, MT",
    city_name: "Broadus",
  },
  {
    postal_code: "43793",
    full_city_name: "Woodsfield, OH",
    city_name: "Woodsfield",
  },
  {
    postal_code: "57330",
    full_city_name: "Delmont, SD",
    city_name: "Delmont",
  },
  {
    postal_code: "84529",
    full_city_name: "Kenilworth, UT",
    city_name: "Kenilworth",
  },
  {
    postal_code: "71016",
    full_city_name: "Castor, LA",
    city_name: "Castor",
  },
  {
    postal_code: "24966",
    full_city_name: "Renick, WV",
    city_name: "Renick",
  },
  {
    postal_code: "73002",
    full_city_name: "Alex, OK",
    city_name: "Alex",
  },
  {
    postal_code: "36425",
    full_city_name: "Beatrice, AL",
    city_name: "Beatrice",
  },
  {
    postal_code: "74560",
    full_city_name: "Pittsburg, OK",
    city_name: "Pittsburg",
  },
  {
    postal_code: "68777",
    full_city_name: "Spencer, NE",
    city_name: "Spencer",
  },
  {
    postal_code: "75670",
    full_city_name: "Marshall, TX",
    city_name: "Marshall",
  },
  {
    postal_code: "16665",
    full_city_name: "Newry, PA",
    city_name: "Newry",
  },
  {
    postal_code: "51105",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "56585",
    full_city_name: "Ulen, MN",
    city_name: "Ulen",
  },
  {
    postal_code: "63957",
    full_city_name: "Piedmont, MO",
    city_name: "Piedmont",
  },
  {
    postal_code: "07946",
    full_city_name: "Millington, NJ",
    city_name: "Millington",
  },
  {
    postal_code: "49706",
    full_city_name: "Alanson, MI",
    city_name: "Alanson",
  },
  {
    postal_code: "46985",
    full_city_name: "Star City, IN",
    city_name: "Star City",
  },
  {
    postal_code: "97010",
    full_city_name: "Bridal Veil, OR",
    city_name: "Bridal Veil",
  },
  {
    postal_code: "59918",
    full_city_name: "Fortine, MT",
    city_name: "Fortine",
  },
  {
    postal_code: "49819",
    full_city_name: "Arnold, MI",
    city_name: "Arnold",
  },
  {
    postal_code: "38488",
    full_city_name: "Taft, TN",
    city_name: "Taft",
  },
  {
    postal_code: "16826",
    full_city_name: "Blanchard, PA",
    city_name: "Blanchard",
  },
  {
    postal_code: "44844",
    full_city_name: "Mc Cutchenville, OH",
    city_name: "Mc Cutchenville",
  },
  {
    postal_code: "58276",
    full_city_name: "Saint Thomas, ND",
    city_name: "Saint Thomas",
  },
  {
    postal_code: "26278",
    full_city_name: "Mabie, WV",
    city_name: "Mabie",
  },
  {
    postal_code: "74723",
    full_city_name: "Bennington, OK",
    city_name: "Bennington",
  },
  {
    postal_code: "38221",
    full_city_name: "Big Sandy, TN",
    city_name: "Big Sandy",
  },
  {
    postal_code: "51007",
    full_city_name: "Bronson, IA",
    city_name: "Bronson",
  },
  {
    postal_code: "99328",
    full_city_name: "Dayton, WA",
    city_name: "Dayton",
  },
  {
    postal_code: "97001",
    full_city_name: "Antelope, OR",
    city_name: "Antelope",
  },
  {
    postal_code: "99627",
    full_city_name: "Mc Grath, AK",
    city_name: "Mc Grath",
  },
  {
    postal_code: "70083",
    full_city_name: "Port Sulphur, LA",
    city_name: "Port Sulphur",
  },
  {
    postal_code: "15827",
    full_city_name: "Byrnedale, PA",
    city_name: "Byrnedale",
  },
  {
    postal_code: "57648",
    full_city_name: "Pollock, SD",
    city_name: "Pollock",
  },
  {
    postal_code: "37080",
    full_city_name: "Joelton, TN",
    city_name: "Joelton",
  },
  {
    postal_code: "45843",
    full_city_name: "Forest, OH",
    city_name: "Forest",
  },
  {
    postal_code: "14533",
    full_city_name: "Piffard, NY",
    city_name: "Piffard",
  },
  {
    postal_code: "37310",
    full_city_name: "Charleston, TN",
    city_name: "Charleston",
  },
  {
    postal_code: "57442",
    full_city_name: "Gettysburg, SD",
    city_name: "Gettysburg",
  },
  {
    postal_code: "61051",
    full_city_name: "Milledgeville, IL",
    city_name: "Milledgeville",
  },
  {
    postal_code: "52583",
    full_city_name: "Promise City, IA",
    city_name: "Promise City",
  },
  {
    postal_code: "67880",
    full_city_name: "Ulysses, KS",
    city_name: "Ulysses",
  },
  {
    postal_code: "50231",
    full_city_name: "Randall, IA",
    city_name: "Randall",
  },
  {
    postal_code: "20129",
    full_city_name: "Paeonian Springs, VA",
    city_name: "Paeonian Springs",
  },
  {
    postal_code: "56027",
    full_city_name: "Elmore, MN",
    city_name: "Elmore",
  },
  {
    postal_code: "75469",
    full_city_name: "Pecan Gap, TX",
    city_name: "Pecan Gap",
  },
  {
    postal_code: "78057",
    full_city_name: "Moore, TX",
    city_name: "Moore",
  },
  {
    postal_code: "07305",
    full_city_name: "Jersey City, NJ",
    city_name: "Jersey City",
  },
  {
    postal_code: "15310",
    full_city_name: "Aleppo, PA",
    city_name: "Aleppo",
  },
  {
    postal_code: "98323",
    full_city_name: "Carbonado, WA",
    city_name: "Carbonado",
  },
  {
    postal_code: "66509",
    full_city_name: "Mayetta, KS",
    city_name: "Mayetta",
  },
  {
    postal_code: "10474",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "97443",
    full_city_name: "Glide, OR",
    city_name: "Glide",
  },
  {
    postal_code: "50546",
    full_city_name: "Havelock, IA",
    city_name: "Havelock",
  },
  {
    postal_code: "98537",
    full_city_name: "Cosmopolis, WA",
    city_name: "Cosmopolis",
  },
  {
    postal_code: "14480",
    full_city_name: "Lakeville, NY",
    city_name: "Lakeville",
  },
  {
    postal_code: "36553",
    full_city_name: "Mc Intosh, AL",
    city_name: "Mc Intosh",
  },
  {
    postal_code: "65321",
    full_city_name: "Blackburn, MO",
    city_name: "Blackburn",
  },
  {
    postal_code: "62047",
    full_city_name: "Hardin, IL",
    city_name: "Hardin",
  },
  {
    postal_code: "92257",
    full_city_name: "Niland, CA",
    city_name: "Niland",
  },
  {
    postal_code: "52165",
    full_city_name: "Ridgeway, IA",
    city_name: "Ridgeway",
  },
  {
    postal_code: "48743",
    full_city_name: "Long Lake, MI",
    city_name: "Long Lake",
  },
  {
    postal_code: "45671",
    full_city_name: "Rarden, OH",
    city_name: "Rarden",
  },
  {
    postal_code: "78828",
    full_city_name: "Barksdale, TX",
    city_name: "Barksdale",
  },
  {
    postal_code: "98647",
    full_city_name: "Skamokawa, WA",
    city_name: "Skamokawa",
  },
  {
    postal_code: "99179",
    full_city_name: "Uniontown, WA",
    city_name: "Uniontown",
  },
  {
    postal_code: "29683",
    full_city_name: "Slater, SC",
    city_name: "Slater",
  },
  {
    postal_code: "72447",
    full_city_name: "Monette, AR",
    city_name: "Monette",
  },
  {
    postal_code: "43931",
    full_city_name: "Hannibal, OH",
    city_name: "Hannibal",
  },
  {
    postal_code: "37347",
    full_city_name: "Jasper, TN",
    city_name: "Jasper",
  },
  {
    postal_code: "54467",
    full_city_name: "Plover, WI",
    city_name: "Plover",
  },
  {
    postal_code: "76861",
    full_city_name: "Miles, TX",
    city_name: "Miles",
  },
  {
    postal_code: "58041",
    full_city_name: "Hankinson, ND",
    city_name: "Hankinson",
  },
  {
    postal_code: "29160",
    full_city_name: "Swansea, SC",
    city_name: "Swansea",
  },
  {
    postal_code: "71425",
    full_city_name: "Enterprise, LA",
    city_name: "Enterprise",
  },
  {
    postal_code: "66541",
    full_city_name: "Summerfield, KS",
    city_name: "Summerfield",
  },
  {
    postal_code: "57383",
    full_city_name: "White Lake, SD",
    city_name: "White Lake",
  },
  {
    postal_code: "56760",
    full_city_name: "Viking, MN",
    city_name: "Viking",
  },
  {
    postal_code: "64074",
    full_city_name: "Napoleon, MO",
    city_name: "Napoleon",
  },
  {
    postal_code: "50136",
    full_city_name: "Keswick, IA",
    city_name: "Keswick",
  },
  {
    postal_code: "24471",
    full_city_name: "Port Republic, VA",
    city_name: "Port Republic",
  },
  {
    postal_code: "96075",
    full_city_name: "Paynes Creek, CA",
    city_name: "Paynes Creek",
  },
  {
    postal_code: "83657",
    full_city_name: "Ola, ID",
    city_name: "Ola",
  },
  {
    postal_code: "75847",
    full_city_name: "Kennard, TX",
    city_name: "Kennard",
  },
  {
    postal_code: "80726",
    full_city_name: "Crook, CO",
    city_name: "Crook",
  },
  {
    postal_code: "41826",
    full_city_name: "Jeremiah, KY",
    city_name: "Jeremiah",
  },
  {
    postal_code: "57104",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "10962",
    full_city_name: "Orangeburg, NY",
    city_name: "Orangeburg",
  },
  {
    postal_code: "27831",
    full_city_name: "Garysburg, NC",
    city_name: "Garysburg",
  },
  {
    postal_code: "25125",
    full_city_name: "Lizemores, WV",
    city_name: "Lizemores",
  },
  {
    postal_code: "16619",
    full_city_name: "Blandburg, PA",
    city_name: "Blandburg",
  },
  {
    postal_code: "54213",
    full_city_name: "Forestville, WI",
    city_name: "Forestville",
  },
  {
    postal_code: "53177",
    full_city_name: "Sturtevant, WI",
    city_name: "Sturtevant",
  },
  {
    postal_code: "72863",
    full_city_name: "Scranton, AR",
    city_name: "Scranton",
  },
  {
    postal_code: "48628",
    full_city_name: "Hope, MI",
    city_name: "Hope",
  },
  {
    postal_code: "50590",
    full_city_name: "Swea City, IA",
    city_name: "Swea City",
  },
  {
    postal_code: "45041",
    full_city_name: "Miamitown, OH",
    city_name: "Miamitown",
  },
  {
    postal_code: "72374",
    full_city_name: "Poplar Grove, AR",
    city_name: "Poplar Grove",
  },
  {
    postal_code: "53153",
    full_city_name: "North Prairie, WI",
    city_name: "North Prairie",
  },
  {
    postal_code: "43217",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "15638",
    full_city_name: "Hostetter, PA",
    city_name: "Hostetter",
  },
  {
    postal_code: "50119",
    full_city_name: "Harvey, IA",
    city_name: "Harvey",
  },
  {
    postal_code: "68864",
    full_city_name: "Palmer, NE",
    city_name: "Palmer",
  },
  {
    postal_code: "25823",
    full_city_name: "Coal City, WV",
    city_name: "Coal City",
  },
  {
    postal_code: "41255",
    full_city_name: "Sitka, KY",
    city_name: "Sitka",
  },
  {
    postal_code: "83870",
    full_city_name: "Tensed, ID",
    city_name: "Tensed",
  },
  {
    postal_code: "98068",
    full_city_name: "Snoqualmie Pass, WA",
    city_name: "Snoqualmie Pass",
  },
  {
    postal_code: "05473",
    full_city_name: "North Ferrisburgh, VT",
    city_name: "North Ferrisburgh",
  },
  {
    postal_code: "82520",
    full_city_name: "Lander, WY",
    city_name: "Lander",
  },
  {
    postal_code: "29810",
    full_city_name: "Allendale, SC",
    city_name: "Allendale",
  },
  {
    postal_code: "32440",
    full_city_name: "Graceville, FL",
    city_name: "Graceville",
  },
  {
    postal_code: "07204",
    full_city_name: "Roselle Park, NJ",
    city_name: "Roselle Park",
  },
  {
    postal_code: "39323",
    full_city_name: "Chunky, MS",
    city_name: "Chunky",
  },
  {
    postal_code: "67483",
    full_city_name: "Tampa, KS",
    city_name: "Tampa",
  },
  {
    postal_code: "25524",
    full_city_name: "Harts, WV",
    city_name: "Harts",
  },
  {
    postal_code: "77978",
    full_city_name: "Point Comfort, TX",
    city_name: "Point Comfort",
  },
  {
    postal_code: "14068",
    full_city_name: "Getzville, NY",
    city_name: "Getzville",
  },
  {
    postal_code: "15617",
    full_city_name: "Arona, PA",
    city_name: "Arona",
  },
  {
    postal_code: "65788",
    full_city_name: "Peace Valley, MO",
    city_name: "Peace Valley",
  },
  {
    postal_code: "56355",
    full_city_name: "Nelson, MN",
    city_name: "Nelson",
  },
  {
    postal_code: "68466",
    full_city_name: "Wymore, NE",
    city_name: "Wymore",
  },
  {
    postal_code: "71366",
    full_city_name: "Saint Joseph, LA",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "99363",
    full_city_name: "Wallula, WA",
    city_name: "Wallula",
  },
  {
    postal_code: "25247",
    full_city_name: "Hartford, WV",
    city_name: "Hartford",
  },
  {
    postal_code: "98846",
    full_city_name: "Pateros, WA",
    city_name: "Pateros",
  },
  {
    postal_code: "57020",
    full_city_name: "Crooks, SD",
    city_name: "Crooks",
  },
  {
    postal_code: "37095",
    full_city_name: "Liberty, TN",
    city_name: "Liberty",
  },
  {
    postal_code: "80929",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "22847",
    full_city_name: "Quicksburg, VA",
    city_name: "Quicksburg",
  },
  {
    postal_code: "83650",
    full_city_name: "Murphy, ID",
    city_name: "Murphy",
  },
  {
    postal_code: "67437",
    full_city_name: "Downs, KS",
    city_name: "Downs",
  },
  {
    postal_code: "23893",
    full_city_name: "White Plains, VA",
    city_name: "White Plains",
  },
  {
    postal_code: "99557",
    full_city_name: "Aniak, AK",
    city_name: "Aniak",
  },
  {
    postal_code: "43525",
    full_city_name: "Haskins, OH",
    city_name: "Haskins",
  },
  {
    postal_code: "72579",
    full_city_name: "Sulphur Rock, AR",
    city_name: "Sulphur Rock",
  },
  {
    postal_code: "25234",
    full_city_name: "Arnoldsburg, WV",
    city_name: "Arnoldsburg",
  },
  {
    postal_code: "53223",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "70756",
    full_city_name: "Lottie, LA",
    city_name: "Lottie",
  },
  {
    postal_code: "35614",
    full_city_name: "Athens, AL",
    city_name: "Athens",
  },
  {
    postal_code: "63381",
    full_city_name: "Truxton, MO",
    city_name: "Truxton",
  },
  {
    postal_code: "58258",
    full_city_name: "Mekinock, ND",
    city_name: "Mekinock",
  },
  {
    postal_code: "41093",
    full_city_name: "Wallingford, KY",
    city_name: "Wallingford",
  },
  {
    postal_code: "32609",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "52032",
    full_city_name: "Bernard, IA",
    city_name: "Bernard",
  },
  {
    postal_code: "45745",
    full_city_name: "Lower Salem, OH",
    city_name: "Lower Salem",
  },
  {
    postal_code: "62860",
    full_city_name: "Macedonia, IL",
    city_name: "Macedonia",
  },
  {
    postal_code: "61477",
    full_city_name: "Smithfield, IL",
    city_name: "Smithfield",
  },
  {
    postal_code: "01012",
    full_city_name: "Chesterfield, MA",
    city_name: "Chesterfield",
  },
  {
    postal_code: "98266",
    full_city_name: "Maple Falls, WA",
    city_name: "Maple Falls",
  },
  {
    postal_code: "47879",
    full_city_name: "Shelburn, IN",
    city_name: "Shelburn",
  },
  {
    postal_code: "69121",
    full_city_name: "Arthur, NE",
    city_name: "Arthur",
  },
  {
    postal_code: "61852",
    full_city_name: "Longview, IL",
    city_name: "Longview",
  },
  {
    postal_code: "70397",
    full_city_name: "Theriot, LA",
    city_name: "Theriot",
  },
  {
    postal_code: "61261",
    full_city_name: "Lyndon, IL",
    city_name: "Lyndon",
  },
  {
    postal_code: "65654",
    full_city_name: "Freistatt, MO",
    city_name: "Freistatt",
  },
  {
    postal_code: "54247",
    full_city_name: "Whitelaw, WI",
    city_name: "Whitelaw",
  },
  {
    postal_code: "30750",
    full_city_name: "Lookout Mountain, GA",
    city_name: "Lookout Mountain",
  },
  {
    postal_code: "25820",
    full_city_name: "Camp Creek, WV",
    city_name: "Camp Creek",
  },
  {
    postal_code: "59447",
    full_city_name: "Geyser, MT",
    city_name: "Geyser",
  },
  {
    postal_code: "85928",
    full_city_name: "Heber, AZ",
    city_name: "Heber",
  },
  {
    postal_code: "42339",
    full_city_name: "Dunmor, KY",
    city_name: "Dunmor",
  },
  {
    postal_code: "83809",
    full_city_name: "Careywood, ID",
    city_name: "Careywood",
  },
  {
    postal_code: "31543",
    full_city_name: "Hortense, GA",
    city_name: "Hortense",
  },
  {
    postal_code: "38967",
    full_city_name: "Winona, MS",
    city_name: "Winona",
  },
  {
    postal_code: "36205",
    full_city_name: "Anniston, AL",
    city_name: "Anniston",
  },
  {
    postal_code: "47104",
    full_city_name: "Bethlehem, IN",
    city_name: "Bethlehem",
  },
  {
    postal_code: "04485",
    full_city_name: "Shirley Mills, ME",
    city_name: "Shirley Mills",
  },
  {
    postal_code: "63833",
    full_city_name: "Catron, MO",
    city_name: "Catron",
  },
  {
    postal_code: "60549",
    full_city_name: "Serena, IL",
    city_name: "Serena",
  },
  {
    postal_code: "46932",
    full_city_name: "Galveston, IN",
    city_name: "Galveston",
  },
  {
    postal_code: "14478",
    full_city_name: "Keuka Park, NY",
    city_name: "Keuka Park",
  },
  {
    postal_code: "59832",
    full_city_name: "Drummond, MT",
    city_name: "Drummond",
  },
  {
    postal_code: "24422",
    full_city_name: "Clifton Forge, VA",
    city_name: "Clifton Forge",
  },
  {
    postal_code: "84772",
    full_city_name: "Summit, UT",
    city_name: "Summit",
  },
  {
    postal_code: "66412",
    full_city_name: "Bremen, KS",
    city_name: "Bremen",
  },
  {
    postal_code: "15955",
    full_city_name: "Sidman, PA",
    city_name: "Sidman",
  },
  {
    postal_code: "72437",
    full_city_name: "Lake City, AR",
    city_name: "Lake City",
  },
  {
    postal_code: "80453",
    full_city_name: "Idledale, CO",
    city_name: "Idledale",
  },
  {
    postal_code: "04969",
    full_city_name: "Plymouth, ME",
    city_name: "Plymouth",
  },
  {
    postal_code: "49896",
    full_city_name: "Wilson, MI",
    city_name: "Wilson",
  },
  {
    postal_code: "85923",
    full_city_name: "Clay Springs, AZ",
    city_name: "Clay Springs",
  },
  {
    postal_code: "68761",
    full_city_name: "Oakdale, NE",
    city_name: "Oakdale",
  },
  {
    postal_code: "67108",
    full_city_name: "Mount Hope, KS",
    city_name: "Mount Hope",
  },
  {
    postal_code: "76936",
    full_city_name: "Eldorado, TX",
    city_name: "Eldorado",
  },
  {
    postal_code: "41663",
    full_city_name: "Tram, KY",
    city_name: "Tram",
  },
  {
    postal_code: "23876",
    full_city_name: "Rawlings, VA",
    city_name: "Rawlings",
  },
  {
    postal_code: "71456",
    full_city_name: "Natchez, LA",
    city_name: "Natchez",
  },
  {
    postal_code: "29836",
    full_city_name: "Martin, SC",
    city_name: "Martin",
  },
  {
    postal_code: "56185",
    full_city_name: "Wilmont, MN",
    city_name: "Wilmont",
  },
  {
    postal_code: "46920",
    full_city_name: "Cutler, IN",
    city_name: "Cutler",
  },
  {
    postal_code: "70040",
    full_city_name: "Braithwaite, LA",
    city_name: "Braithwaite",
  },
  {
    postal_code: "46703",
    full_city_name: "Angola, IN",
    city_name: "Angola",
  },
  {
    postal_code: "99143",
    full_city_name: "Lacrosse, WA",
    city_name: "Lacrosse",
  },
  {
    postal_code: "31812",
    full_city_name: "Junction City, GA",
    city_name: "Junction City",
  },
  {
    postal_code: "36350",
    full_city_name: "Midland City, AL",
    city_name: "Midland City",
  },
  {
    postal_code: "97125",
    full_city_name: "Manning, OR",
    city_name: "Manning",
  },
  {
    postal_code: "45681",
    full_city_name: "South Salem, OH",
    city_name: "South Salem",
  },
  {
    postal_code: "39769",
    full_city_name: "Sturgis, MS",
    city_name: "Sturgis",
  },
  {
    postal_code: "49961",
    full_city_name: "Sidnaw, MI",
    city_name: "Sidnaw",
  },
  {
    postal_code: "49310",
    full_city_name: "Blanchard, MI",
    city_name: "Blanchard",
  },
  {
    postal_code: "49440",
    full_city_name: "Muskegon, MI",
    city_name: "Muskegon",
  },
  {
    postal_code: "57255",
    full_city_name: "New Effington, SD",
    city_name: "New Effington",
  },
  {
    postal_code: "27843",
    full_city_name: "Hobgood, NC",
    city_name: "Hobgood",
  },
  {
    postal_code: "26524",
    full_city_name: "Bretz, WV",
    city_name: "Bretz",
  },
  {
    postal_code: "67520",
    full_city_name: "Bison, KS",
    city_name: "Bison",
  },
  {
    postal_code: "72331",
    full_city_name: "Earle, AR",
    city_name: "Earle",
  },
  {
    postal_code: "64861",
    full_city_name: "Rocky Comfort, MO",
    city_name: "Rocky Comfort",
  },
  {
    postal_code: "76958",
    full_city_name: "Water Valley, TX",
    city_name: "Water Valley",
  },
  {
    postal_code: "64767",
    full_city_name: "Milo, MO",
    city_name: "Milo",
  },
  {
    postal_code: "56173",
    full_city_name: "Steen, MN",
    city_name: "Steen",
  },
  {
    postal_code: "57466",
    full_city_name: "Onaka, SD",
    city_name: "Onaka",
  },
  {
    postal_code: "95554",
    full_city_name: "Myers Flat, CA",
    city_name: "Myers Flat",
  },
  {
    postal_code: "24620",
    full_city_name: "Hurley, VA",
    city_name: "Hurley",
  },
  {
    postal_code: "57601",
    full_city_name: "Mobridge, SD",
    city_name: "Mobridge",
  },
  {
    postal_code: "64656",
    full_city_name: "Ludlow, MO",
    city_name: "Ludlow",
  },
  {
    postal_code: "56550",
    full_city_name: "Hendrum, MN",
    city_name: "Hendrum",
  },
  {
    postal_code: "40177",
    full_city_name: "West Point, KY",
    city_name: "West Point",
  },
  {
    postal_code: "24829",
    full_city_name: "Eckman, WV",
    city_name: "Eckman",
  },
  {
    postal_code: "97531",
    full_city_name: "Kerby, OR",
    city_name: "Kerby",
  },
  {
    postal_code: "99704",
    full_city_name: "Clear, AK",
    city_name: "Clear",
  },
  {
    postal_code: "45841",
    full_city_name: "Jenera, OH",
    city_name: "Jenera",
  },
  {
    postal_code: "46536",
    full_city_name: "Lakeville, IN",
    city_name: "Lakeville",
  },
  {
    postal_code: "97640",
    full_city_name: "Summer Lake, OR",
    city_name: "Summer Lake",
  },
  {
    postal_code: "58016",
    full_city_name: "Clifford, ND",
    city_name: "Clifford",
  },
  {
    postal_code: "04424",
    full_city_name: "Danforth, ME",
    city_name: "Danforth",
  },
  {
    postal_code: "57279",
    full_city_name: "Wilmot, SD",
    city_name: "Wilmot",
  },
  {
    postal_code: "23885",
    full_city_name: "Sutherland, VA",
    city_name: "Sutherland",
  },
  {
    postal_code: "72927",
    full_city_name: "Booneville, AR",
    city_name: "Booneville",
  },
  {
    postal_code: "53061",
    full_city_name: "New Holstein, WI",
    city_name: "New Holstein",
  },
  {
    postal_code: "70655",
    full_city_name: "Oberlin, LA",
    city_name: "Oberlin",
  },
  {
    postal_code: "73098",
    full_city_name: "Wynnewood, OK",
    city_name: "Wynnewood",
  },
  {
    postal_code: "39144",
    full_city_name: "Pattison, MS",
    city_name: "Pattison",
  },
  {
    postal_code: "62272",
    full_city_name: "Percy, IL",
    city_name: "Percy",
  },
  {
    postal_code: "92327",
    full_city_name: "Daggett, CA",
    city_name: "Daggett",
  },
  {
    postal_code: "36471",
    full_city_name: "Peterman, AL",
    city_name: "Peterman",
  },
  {
    postal_code: "65777",
    full_city_name: "Moody, MO",
    city_name: "Moody",
  },
  {
    postal_code: "68769",
    full_city_name: "Plainview, NE",
    city_name: "Plainview",
  },
  {
    postal_code: "66409",
    full_city_name: "Berryton, KS",
    city_name: "Berryton",
  },
  {
    postal_code: "25126",
    full_city_name: "London, WV",
    city_name: "London",
  },
  {
    postal_code: "83346",
    full_city_name: "Oakley, ID",
    city_name: "Oakley",
  },
  {
    postal_code: "43778",
    full_city_name: "Salesville, OH",
    city_name: "Salesville",
  },
  {
    postal_code: "47901",
    full_city_name: "Lafayette, IN",
    city_name: "Lafayette",
  },
  {
    postal_code: "17844",
    full_city_name: "Mifflinburg, PA",
    city_name: "Mifflinburg",
  },
  {
    postal_code: "24069",
    full_city_name: "Cascade, VA",
    city_name: "Cascade",
  },
  {
    postal_code: "50469",
    full_city_name: "Rockwell, IA",
    city_name: "Rockwell",
  },
  {
    postal_code: "52057",
    full_city_name: "Manchester, IA",
    city_name: "Manchester",
  },
  {
    postal_code: "17823",
    full_city_name: "Dornsife, PA",
    city_name: "Dornsife",
  },
  {
    postal_code: "79059",
    full_city_name: "Miami, TX",
    city_name: "Miami",
  },
  {
    postal_code: "49061",
    full_city_name: "Jones, MI",
    city_name: "Jones",
  },
  {
    postal_code: "26680",
    full_city_name: "Nallen, WV",
    city_name: "Nallen",
  },
  {
    postal_code: "03780",
    full_city_name: "Pike, NH",
    city_name: "Pike",
  },
  {
    postal_code: "73739",
    full_city_name: "Goltry, OK",
    city_name: "Goltry",
  },
  {
    postal_code: "99574",
    full_city_name: "Cordova, AK",
    city_name: "Cordova",
  },
  {
    postal_code: "56546",
    full_city_name: "Georgetown, MN",
    city_name: "Georgetown",
  },
  {
    postal_code: "98245",
    full_city_name: "Eastsound, WA",
    city_name: "Eastsound",
  },
  {
    postal_code: "65260",
    full_city_name: "Jacksonville, MO",
    city_name: "Jacksonville",
  },
  {
    postal_code: "14881",
    full_city_name: "Slaterville Springs, NY",
    city_name: "Slaterville Springs",
  },
  {
    postal_code: "79083",
    full_city_name: "Stinnett, TX",
    city_name: "Stinnett",
  },
  {
    postal_code: "97362",
    full_city_name: "Mount Angel, OR",
    city_name: "Mount Angel",
  },
  {
    postal_code: "49453",
    full_city_name: "Saugatuck, MI",
    city_name: "Saugatuck",
  },
  {
    postal_code: "42047",
    full_city_name: "Hampton, KY",
    city_name: "Hampton",
  },
  {
    postal_code: "25523",
    full_city_name: "Hamlin, WV",
    city_name: "Hamlin",
  },
  {
    postal_code: "49757",
    full_city_name: "Mackinac Island, MI",
    city_name: "Mackinac Island",
  },
  {
    postal_code: "38864",
    full_city_name: "Randolph, MS",
    city_name: "Randolph",
  },
  {
    postal_code: "57345",
    full_city_name: "Highmore, SD",
    city_name: "Highmore",
  },
  {
    postal_code: "54245",
    full_city_name: "Valders, WI",
    city_name: "Valders",
  },
  {
    postal_code: "24605",
    full_city_name: "Bluefield, VA",
    city_name: "Bluefield",
  },
  {
    postal_code: "13641",
    full_city_name: "Fishers Landing, NY",
    city_name: "Fishers Landing",
  },
  {
    postal_code: "13477",
    full_city_name: "Vernon Center, NY",
    city_name: "Vernon Center",
  },
  {
    postal_code: "41601",
    full_city_name: "Allen, KY",
    city_name: "Allen",
  },
  {
    postal_code: "10505",
    full_city_name: "Baldwin Place, NY",
    city_name: "Baldwin Place",
  },
  {
    postal_code: "14174",
    full_city_name: "Youngstown, NY",
    city_name: "Youngstown",
  },
  {
    postal_code: "67445",
    full_city_name: "Glasco, KS",
    city_name: "Glasco",
  },
  {
    postal_code: "55931",
    full_city_name: "Eitzen, MN",
    city_name: "Eitzen",
  },
  {
    postal_code: "01088",
    full_city_name: "West Hatfield, MA",
    city_name: "West Hatfield",
  },
  {
    postal_code: "71725",
    full_city_name: "Carthage, AR",
    city_name: "Carthage",
  },
  {
    postal_code: "81431",
    full_city_name: "Redvale, CO",
    city_name: "Redvale",
  },
  {
    postal_code: "16213",
    full_city_name: "Callensburg, PA",
    city_name: "Callensburg",
  },
  {
    postal_code: "59422",
    full_city_name: "Choteau, MT",
    city_name: "Choteau",
  },
  {
    postal_code: "40972",
    full_city_name: "Oneida, KY",
    city_name: "Oneida",
  },
  {
    postal_code: "45203",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "38387",
    full_city_name: "Westport, TN",
    city_name: "Westport",
  },
  {
    postal_code: "92258",
    full_city_name: "North Palm Springs, CA",
    city_name: "North Palm Springs",
  },
  {
    postal_code: "47164",
    full_city_name: "Palmyra, IN",
    city_name: "Palmyra",
  },
  {
    postal_code: "63939",
    full_city_name: "Fairdealing, MO",
    city_name: "Fairdealing",
  },
  {
    postal_code: "48801",
    full_city_name: "Alma, MI",
    city_name: "Alma",
  },
  {
    postal_code: "16405",
    full_city_name: "Columbus, PA",
    city_name: "Columbus",
  },
  {
    postal_code: "87823",
    full_city_name: "Lemitar, NM",
    city_name: "Lemitar",
  },
  {
    postal_code: "45378",
    full_city_name: "Verona, OH",
    city_name: "Verona",
  },
  {
    postal_code: "28663",
    full_city_name: "Piney Creek, NC",
    city_name: "Piney Creek",
  },
  {
    postal_code: "68792",
    full_city_name: "Wynot, NE",
    city_name: "Wynot",
  },
  {
    postal_code: "58027",
    full_city_name: "Enderlin, ND",
    city_name: "Enderlin",
  },
  {
    postal_code: "46793",
    full_city_name: "Waterloo, IN",
    city_name: "Waterloo",
  },
  {
    postal_code: "47941",
    full_city_name: "Dayton, IN",
    city_name: "Dayton",
  },
  {
    postal_code: "67036",
    full_city_name: "Danville, KS",
    city_name: "Danville",
  },
  {
    postal_code: "59432",
    full_city_name: "Dupuyer, MT",
    city_name: "Dupuyer",
  },
  {
    postal_code: "24739",
    full_city_name: "Princeton, WV",
    city_name: "Princeton",
  },
  {
    postal_code: "88116",
    full_city_name: "Elida, NM",
    city_name: "Elida",
  },
  {
    postal_code: "83454",
    full_city_name: "Ucon, ID",
    city_name: "Ucon",
  },
  {
    postal_code: "82244",
    full_city_name: "Yoder, WY",
    city_name: "Yoder",
  },
  {
    postal_code: "50040",
    full_city_name: "Boxholm, IA",
    city_name: "Boxholm",
  },
  {
    postal_code: "17228",
    full_city_name: "Harrisonville, PA",
    city_name: "Harrisonville",
  },
  {
    postal_code: "53130",
    full_city_name: "Hales Corners, WI",
    city_name: "Hales Corners",
  },
  {
    postal_code: "96035",
    full_city_name: "Gerber, CA",
    city_name: "Gerber",
  },
  {
    postal_code: "69033",
    full_city_name: "Imperial, NE",
    city_name: "Imperial",
  },
  {
    postal_code: "52769",
    full_city_name: "Stockton, IA",
    city_name: "Stockton",
  },
  {
    postal_code: "84026",
    full_city_name: "Fort Duchesne, UT",
    city_name: "Fort Duchesne",
  },
  {
    postal_code: "52233",
    full_city_name: "Hiawatha, IA",
    city_name: "Hiawatha",
  },
  {
    postal_code: "87939",
    full_city_name: "Monticello, NM",
    city_name: "Monticello",
  },
  {
    postal_code: "88264",
    full_city_name: "Maljamar, NM",
    city_name: "Maljamar",
  },
  {
    postal_code: "49007",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "15635",
    full_city_name: "Hannastown, PA",
    city_name: "Hannastown",
  },
  {
    postal_code: "63834",
    full_city_name: "Charleston, MO",
    city_name: "Charleston",
  },
  {
    postal_code: "65231",
    full_city_name: "Auxvasse, MO",
    city_name: "Auxvasse",
  },
  {
    postal_code: "99777",
    full_city_name: "Tanana, AK",
    city_name: "Tanana",
  },
  {
    postal_code: "49822",
    full_city_name: "Deerton, MI",
    city_name: "Deerton",
  },
  {
    postal_code: "54136",
    full_city_name: "Kimberly, WI",
    city_name: "Kimberly",
  },
  {
    postal_code: "16663",
    full_city_name: "Morann, PA",
    city_name: "Morann",
  },
  {
    postal_code: "41701",
    full_city_name: "Hazard, KY",
    city_name: "Hazard",
  },
  {
    postal_code: "18340",
    full_city_name: "Millrift, PA",
    city_name: "Millrift",
  },
  {
    postal_code: "08329",
    full_city_name: "Mauricetown, NJ",
    city_name: "Mauricetown",
  },
  {
    postal_code: "36605",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "46381",
    full_city_name: "Thayer, IN",
    city_name: "Thayer",
  },
  {
    postal_code: "91210",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "15727",
    full_city_name: "Clune, PA",
    city_name: "Clune",
  },
  {
    postal_code: "42078",
    full_city_name: "Salem, KY",
    city_name: "Salem",
  },
  {
    postal_code: "70086",
    full_city_name: "Saint James, LA",
    city_name: "Saint James",
  },
  {
    postal_code: "59075",
    full_city_name: "Saint Xavier, MT",
    city_name: "Saint Xavier",
  },
  {
    postal_code: "63941",
    full_city_name: "Fremont, MO",
    city_name: "Fremont",
  },
  {
    postal_code: "33521",
    full_city_name: "Coleman, FL",
    city_name: "Coleman",
  },
  {
    postal_code: "43343",
    full_city_name: "Quincy, OH",
    city_name: "Quincy",
  },
  {
    postal_code: "72624",
    full_city_name: "Compton, AR",
    city_name: "Compton",
  },
  {
    postal_code: "85532",
    full_city_name: "Claypool, AZ",
    city_name: "Claypool",
  },
  {
    postal_code: "83344",
    full_city_name: "Murtaugh, ID",
    city_name: "Murtaugh",
  },
  {
    postal_code: "83414",
    full_city_name: "Alta, WY",
    city_name: "Alta",
  },
  {
    postal_code: "30572",
    full_city_name: "Suches, GA",
    city_name: "Suches",
  },
  {
    postal_code: "66830",
    full_city_name: "Admire, KS",
    city_name: "Admire",
  },
  {
    postal_code: "63785",
    full_city_name: "Whitewater, MO",
    city_name: "Whitewater",
  },
  {
    postal_code: "15661",
    full_city_name: "Loyalhanna, PA",
    city_name: "Loyalhanna",
  },
  {
    postal_code: "51248",
    full_city_name: "Sanborn, IA",
    city_name: "Sanborn",
  },
  {
    postal_code: "25540",
    full_city_name: "Midkiff, WV",
    city_name: "Midkiff",
  },
  {
    postal_code: "62932",
    full_city_name: "Elkville, IL",
    city_name: "Elkville",
  },
  {
    postal_code: "58760",
    full_city_name: "Maxbass, ND",
    city_name: "Maxbass",
  },
  {
    postal_code: "80473",
    full_city_name: "Rand, CO",
    city_name: "Rand",
  },
  {
    postal_code: "57328",
    full_city_name: "Corsica, SD",
    city_name: "Corsica",
  },
  {
    postal_code: "38471",
    full_city_name: "Lutts, TN",
    city_name: "Lutts",
  },
  {
    postal_code: "63014",
    full_city_name: "Berger, MO",
    city_name: "Berger",
  },
  {
    postal_code: "24127",
    full_city_name: "New Castle, VA",
    city_name: "New Castle",
  },
  {
    postal_code: "55763",
    full_city_name: "Makinen, MN",
    city_name: "Makinen",
  },
  {
    postal_code: "44822",
    full_city_name: "Butler, OH",
    city_name: "Butler",
  },
  {
    postal_code: "86021",
    full_city_name: "Colorado City, AZ",
    city_name: "Colorado City",
  },
  {
    postal_code: "05040",
    full_city_name: "East Corinth, VT",
    city_name: "East Corinth",
  },
  {
    postal_code: "51037",
    full_city_name: "Meriden, IA",
    city_name: "Meriden",
  },
  {
    postal_code: "72623",
    full_city_name: "Clarkridge, AR",
    city_name: "Clarkridge",
  },
  {
    postal_code: "71241",
    full_city_name: "Farmerville, LA",
    city_name: "Farmerville",
  },
  {
    postal_code: "31015",
    full_city_name: "Cordele, GA",
    city_name: "Cordele",
  },
  {
    postal_code: "49744",
    full_city_name: "Herron, MI",
    city_name: "Herron",
  },
  {
    postal_code: "23404",
    full_city_name: "Locustville, VA",
    city_name: "Locustville",
  },
  {
    postal_code: "41562",
    full_city_name: "Shelbiana, KY",
    city_name: "Shelbiana",
  },
  {
    postal_code: "57568",
    full_city_name: "Presho, SD",
    city_name: "Presho",
  },
  {
    postal_code: "76524",
    full_city_name: "Eddy, TX",
    city_name: "Eddy",
  },
  {
    postal_code: "51365",
    full_city_name: "Wallingford, IA",
    city_name: "Wallingford",
  },
  {
    postal_code: "46737",
    full_city_name: "Fremont, IN",
    city_name: "Fremont",
  },
  {
    postal_code: "59520",
    full_city_name: "Big Sandy, MT",
    city_name: "Big Sandy",
  },
  {
    postal_code: "59451",
    full_city_name: "Hilger, MT",
    city_name: "Hilger",
  },
  {
    postal_code: "16364",
    full_city_name: "Venus, PA",
    city_name: "Venus",
  },
  {
    postal_code: "83523",
    full_city_name: "Craigmont, ID",
    city_name: "Craigmont",
  },
  {
    postal_code: "79842",
    full_city_name: "Marathon, TX",
    city_name: "Marathon",
  },
  {
    postal_code: "58630",
    full_city_name: "Gladstone, ND",
    city_name: "Gladstone",
  },
  {
    postal_code: "38774",
    full_city_name: "Shelby, MS",
    city_name: "Shelby",
  },
  {
    postal_code: "99357",
    full_city_name: "Royal City, WA",
    city_name: "Royal City",
  },
  {
    postal_code: "67122",
    full_city_name: "Piedmont, KS",
    city_name: "Piedmont",
  },
  {
    postal_code: "95345",
    full_city_name: "Midpines, CA",
    city_name: "Midpines",
  },
  {
    postal_code: "98862",
    full_city_name: "Winthrop, WA",
    city_name: "Winthrop",
  },
  {
    postal_code: "39481",
    full_city_name: "Stringer, MS",
    city_name: "Stringer",
  },
  {
    postal_code: "79379",
    full_city_name: "Whiteface, TX",
    city_name: "Whiteface",
  },
  {
    postal_code: "50611",
    full_city_name: "Bristow, IA",
    city_name: "Bristow",
  },
  {
    postal_code: "77865",
    full_city_name: "Marquez, TX",
    city_name: "Marquez",
  },
  {
    postal_code: "73717",
    full_city_name: "Alva, OK",
    city_name: "Alva",
  },
  {
    postal_code: "58040",
    full_city_name: "Gwinner, ND",
    city_name: "Gwinner",
  },
  {
    postal_code: "17812",
    full_city_name: "Beaver Springs, PA",
    city_name: "Beaver Springs",
  },
  {
    postal_code: "99826",
    full_city_name: "Gustavus, AK",
    city_name: "Gustavus",
  },
  {
    postal_code: "32420",
    full_city_name: "Alford, FL",
    city_name: "Alford",
  },
  {
    postal_code: "74042",
    full_city_name: "Lenapah, OK",
    city_name: "Lenapah",
  },
  {
    postal_code: "59469",
    full_city_name: "Raynesford, MT",
    city_name: "Raynesford",
  },
  {
    postal_code: "65752",
    full_city_name: "South Greenfield, MO",
    city_name: "South Greenfield",
  },
  {
    postal_code: "29081",
    full_city_name: "Ehrhardt, SC",
    city_name: "Ehrhardt",
  },
  {
    postal_code: "99008",
    full_city_name: "Edwall, WA",
    city_name: "Edwall",
  },
  {
    postal_code: "59842",
    full_city_name: "Haugan, MT",
    city_name: "Haugan",
  },
  {
    postal_code: "58270",
    full_city_name: "Park River, ND",
    city_name: "Park River",
  },
  {
    postal_code: "99925",
    full_city_name: "Klawock, AK",
    city_name: "Klawock",
  },
  {
    postal_code: "12520",
    full_city_name: "Cornwall On Hudson, NY",
    city_name: "Cornwall On Hudson",
  },
  {
    postal_code: "17344",
    full_city_name: "Mc Sherrystown, PA",
    city_name: "Mc Sherrystown",
  },
  {
    postal_code: "25243",
    full_city_name: "Gandeeville, WV",
    city_name: "Gandeeville",
  },
  {
    postal_code: "99401",
    full_city_name: "Anatone, WA",
    city_name: "Anatone",
  },
  {
    postal_code: "63544",
    full_city_name: "Green Castle, MO",
    city_name: "Green Castle",
  },
  {
    postal_code: "07718",
    full_city_name: "Belford, NJ",
    city_name: "Belford",
  },
  {
    postal_code: "08553",
    full_city_name: "Rocky Hill, NJ",
    city_name: "Rocky Hill",
  },
  {
    postal_code: "65068",
    full_city_name: "Prairie Home, MO",
    city_name: "Prairie Home",
  },
  {
    postal_code: "72916",
    full_city_name: "Fort Smith, AR",
    city_name: "Fort Smith",
  },
  {
    postal_code: "05079",
    full_city_name: "Vershire, VT",
    city_name: "Vershire",
  },
  {
    postal_code: "57441",
    full_city_name: "Frederick, SD",
    city_name: "Frederick",
  },
  {
    postal_code: "58214",
    full_city_name: "Arvilla, ND",
    city_name: "Arvilla",
  },
  {
    postal_code: "14173",
    full_city_name: "Yorkshire, NY",
    city_name: "Yorkshire",
  },
  {
    postal_code: "79505",
    full_city_name: "Benjamin, TX",
    city_name: "Benjamin",
  },
  {
    postal_code: "64481",
    full_city_name: "Ridgeway, MO",
    city_name: "Ridgeway",
  },
  {
    postal_code: "36373",
    full_city_name: "Shorterville, AL",
    city_name: "Shorterville",
  },
  {
    postal_code: "31784",
    full_city_name: "Sale City, GA",
    city_name: "Sale City",
  },
  {
    postal_code: "79517",
    full_city_name: "Fluvanna, TX",
    city_name: "Fluvanna",
  },
  {
    postal_code: "97346",
    full_city_name: "Gates, OR",
    city_name: "Gates",
  },
  {
    postal_code: "74644",
    full_city_name: "Marland, OK",
    city_name: "Marland",
  },
  {
    postal_code: "38833",
    full_city_name: "Burnsville, MS",
    city_name: "Burnsville",
  },
  {
    postal_code: "65730",
    full_city_name: "Powell, MO",
    city_name: "Powell",
  },
  {
    postal_code: "72020",
    full_city_name: "Bradford, AR",
    city_name: "Bradford",
  },
  {
    postal_code: "85325",
    full_city_name: "Bouse, AZ",
    city_name: "Bouse",
  },
  {
    postal_code: "97329",
    full_city_name: "Cascadia, OR",
    city_name: "Cascadia",
  },
  {
    postal_code: "36017",
    full_city_name: "Clio, AL",
    city_name: "Clio",
  },
  {
    postal_code: "04733",
    full_city_name: "Benedicta, ME",
    city_name: "Benedicta",
  },
  {
    postal_code: "38044",
    full_city_name: "Hornsby, TN",
    city_name: "Hornsby",
  },
  {
    postal_code: "26167",
    full_city_name: "Reader, WV",
    city_name: "Reader",
  },
  {
    postal_code: "12456",
    full_city_name: "Mount Marion, NY",
    city_name: "Mount Marion",
  },
  {
    postal_code: "71422",
    full_city_name: "Dodson, LA",
    city_name: "Dodson",
  },
  {
    postal_code: "31071",
    full_city_name: "Pineview, GA",
    city_name: "Pineview",
  },
  {
    postal_code: "57067",
    full_city_name: "Utica, SD",
    city_name: "Utica",
  },
  {
    postal_code: "58241",
    full_city_name: "Hensel, ND",
    city_name: "Hensel",
  },
  {
    postal_code: "59003",
    full_city_name: "Ashland, MT",
    city_name: "Ashland",
  },
  {
    postal_code: "76373",
    full_city_name: "Oklaunion, TX",
    city_name: "Oklaunion",
  },
  {
    postal_code: "23513",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "46702",
    full_city_name: "Andrews, IN",
    city_name: "Andrews",
  },
  {
    postal_code: "61830",
    full_city_name: "Cisco, IL",
    city_name: "Cisco",
  },
  {
    postal_code: "98240",
    full_city_name: "Custer, WA",
    city_name: "Custer",
  },
  {
    postal_code: "71646",
    full_city_name: "Hamburg, AR",
    city_name: "Hamburg",
  },
  {
    postal_code: "46733",
    full_city_name: "Decatur, IN",
    city_name: "Decatur",
  },
  {
    postal_code: "37010",
    full_city_name: "Adams, TN",
    city_name: "Adams",
  },
  {
    postal_code: "39363",
    full_city_name: "Stonewall, MS",
    city_name: "Stonewall",
  },
  {
    postal_code: "49929",
    full_city_name: "Greenland, MI",
    city_name: "Greenland",
  },
  {
    postal_code: "17318",
    full_city_name: "Emigsville, PA",
    city_name: "Emigsville",
  },
  {
    postal_code: "98572",
    full_city_name: "Pe Ell, WA",
    city_name: "Pe Ell",
  },
  {
    postal_code: "52322",
    full_city_name: "Oxford, IA",
    city_name: "Oxford",
  },
  {
    postal_code: "67039",
    full_city_name: "Douglass, KS",
    city_name: "Douglass",
  },
  {
    postal_code: "40873",
    full_city_name: "Wallins Creek, KY",
    city_name: "Wallins Creek",
  },
  {
    postal_code: "49716",
    full_city_name: "Brutus, MI",
    city_name: "Brutus",
  },
  {
    postal_code: "54216",
    full_city_name: "Kewaunee, WI",
    city_name: "Kewaunee",
  },
  {
    postal_code: "04739",
    full_city_name: "Eagle Lake, ME",
    city_name: "Eagle Lake",
  },
  {
    postal_code: "49827",
    full_city_name: "Engadine, MI",
    city_name: "Engadine",
  },
  {
    postal_code: "73090",
    full_city_name: "Union City, OK",
    city_name: "Union City",
  },
  {
    postal_code: "17244",
    full_city_name: "Orrstown, PA",
    city_name: "Orrstown",
  },
  {
    postal_code: "07460",
    full_city_name: "Stockholm, NJ",
    city_name: "Stockholm",
  },
  {
    postal_code: "62070",
    full_city_name: "Mozier, IL",
    city_name: "Mozier",
  },
  {
    postal_code: "65061",
    full_city_name: "Morrison, MO",
    city_name: "Morrison",
  },
  {
    postal_code: "40949",
    full_city_name: "Heidrick, KY",
    city_name: "Heidrick",
  },
  {
    postal_code: "96056",
    full_city_name: "Mcarthur, CA",
    city_name: "Mcarthur",
  },
  {
    postal_code: "49040",
    full_city_name: "Colon, MI",
    city_name: "Colon",
  },
  {
    postal_code: "65652",
    full_city_name: "Fordland, MO",
    city_name: "Fordland",
  },
  {
    postal_code: "67545",
    full_city_name: "Hudson, KS",
    city_name: "Hudson",
  },
  {
    postal_code: "50576",
    full_city_name: "Rembrandt, IA",
    city_name: "Rembrandt",
  },
  {
    postal_code: "30904",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "63787",
    full_city_name: "Zalma, MO",
    city_name: "Zalma",
  },
  {
    postal_code: "15716",
    full_city_name: "Black Lick, PA",
    city_name: "Black Lick",
  },
  {
    postal_code: "95432",
    full_city_name: "Elk, CA",
    city_name: "Elk",
  },
  {
    postal_code: "35548",
    full_city_name: "Brilliant, AL",
    city_name: "Brilliant",
  },
  {
    postal_code: "93645",
    full_city_name: "O Neals, CA",
    city_name: "O Neals",
  },
  {
    postal_code: "41659",
    full_city_name: "Stanville, KY",
    city_name: "Stanville",
  },
  {
    postal_code: "93254",
    full_city_name: "New Cuyama, CA",
    city_name: "New Cuyama",
  },
  {
    postal_code: "58015",
    full_city_name: "Christine, ND",
    city_name: "Christine",
  },
  {
    postal_code: "98257",
    full_city_name: "La Conner, WA",
    city_name: "La Conner",
  },
  {
    postal_code: "88132",
    full_city_name: "Rogers, NM",
    city_name: "Rogers",
  },
  {
    postal_code: "50256",
    full_city_name: "Tracy, IA",
    city_name: "Tracy",
  },
  {
    postal_code: "76637",
    full_city_name: "Cranfills Gap, TX",
    city_name: "Cranfills Gap",
  },
  {
    postal_code: "51034",
    full_city_name: "Mapleton, IA",
    city_name: "Mapleton",
  },
  {
    postal_code: "99329",
    full_city_name: "Dixie, WA",
    city_name: "Dixie",
  },
  {
    postal_code: "35806",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "52308",
    full_city_name: "Millersburg, IA",
    city_name: "Millersburg",
  },
  {
    postal_code: "66851",
    full_city_name: "Florence, KS",
    city_name: "Florence",
  },
  {
    postal_code: "15698",
    full_city_name: "Yukon, PA",
    city_name: "Yukon",
  },
  {
    postal_code: "83211",
    full_city_name: "American Falls, ID",
    city_name: "American Falls",
  },
  {
    postal_code: "27980",
    full_city_name: "Tyner, NC",
    city_name: "Tyner",
  },
  {
    postal_code: "71018",
    full_city_name: "Cotton Valley, LA",
    city_name: "Cotton Valley",
  },
  {
    postal_code: "64867",
    full_city_name: "Stella, MO",
    city_name: "Stella",
  },
  {
    postal_code: "16328",
    full_city_name: "Hydetown, PA",
    city_name: "Hydetown",
  },
  {
    postal_code: "37345",
    full_city_name: "Huntland, TN",
    city_name: "Huntland",
  },
  {
    postal_code: "47585",
    full_city_name: "Stendal, IN",
    city_name: "Stendal",
  },
  {
    postal_code: "36323",
    full_city_name: "Elba, AL",
    city_name: "Elba",
  },
  {
    postal_code: "97122",
    full_city_name: "Hebo, OR",
    city_name: "Hebo",
  },
  {
    postal_code: "57231",
    full_city_name: "De Smet, SD",
    city_name: "De Smet",
  },
  {
    postal_code: "79503",
    full_city_name: "Avoca, TX",
    city_name: "Avoca",
  },
  {
    postal_code: "56590",
    full_city_name: "Wendell, MN",
    city_name: "Wendell",
  },
  {
    postal_code: "17957",
    full_city_name: "Muir, PA",
    city_name: "Muir",
  },
  {
    postal_code: "37184",
    full_city_name: "Watertown, TN",
    city_name: "Watertown",
  },
  {
    postal_code: "96015",
    full_city_name: "Canby, CA",
    city_name: "Canby",
  },
  {
    postal_code: "10969",
    full_city_name: "Pine Island, NY",
    city_name: "Pine Island",
  },
  {
    postal_code: "83334",
    full_city_name: "Hansen, ID",
    city_name: "Hansen",
  },
  {
    postal_code: "70662",
    full_city_name: "Sugartown, LA",
    city_name: "Sugartown",
  },
  {
    postal_code: "83444",
    full_city_name: "Roberts, ID",
    city_name: "Roberts",
  },
  {
    postal_code: "48811",
    full_city_name: "Carson City, MI",
    city_name: "Carson City",
  },
  {
    postal_code: "23065",
    full_city_name: "Gum Spring, VA",
    city_name: "Gum Spring",
  },
  {
    postal_code: "52309",
    full_city_name: "Monmouth, IA",
    city_name: "Monmouth",
  },
  {
    postal_code: "15624",
    full_city_name: "Crabtree, PA",
    city_name: "Crabtree",
  },
  {
    postal_code: "73106",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "56141",
    full_city_name: "Iona, MN",
    city_name: "Iona",
  },
  {
    postal_code: "75946",
    full_city_name: "Garrison, TX",
    city_name: "Garrison",
  },
  {
    postal_code: "74342",
    full_city_name: "Eucha, OK",
    city_name: "Eucha",
  },
  {
    postal_code: "98280",
    full_city_name: "Orcas, WA",
    city_name: "Orcas",
  },
  {
    postal_code: "38359",
    full_city_name: "Milledgeville, TN",
    city_name: "Milledgeville",
  },
  {
    postal_code: "83555",
    full_city_name: "Winchester, ID",
    city_name: "Winchester",
  },
  {
    postal_code: "88032",
    full_city_name: "Dona Ana, NM",
    city_name: "Dona Ana",
  },
  {
    postal_code: "65790",
    full_city_name: "Pottersville, MO",
    city_name: "Pottersville",
  },
  {
    postal_code: "37715",
    full_city_name: "Clairfield, TN",
    city_name: "Clairfield",
  },
  {
    postal_code: "52249",
    full_city_name: "Keystone, IA",
    city_name: "Keystone",
  },
  {
    postal_code: "62649",
    full_city_name: "Hettick, IL",
    city_name: "Hettick",
  },
  {
    postal_code: "16848",
    full_city_name: "Lamar, PA",
    city_name: "Lamar",
  },
  {
    postal_code: "15733",
    full_city_name: "De Lancey, PA",
    city_name: "De Lancey",
  },
  {
    postal_code: "50101",
    full_city_name: "Galt, IA",
    city_name: "Galt",
  },
  {
    postal_code: "72854",
    full_city_name: "Ozone, AR",
    city_name: "Ozone",
  },
  {
    postal_code: "73038",
    full_city_name: "Fort Cobb, OK",
    city_name: "Fort Cobb",
  },
  {
    postal_code: "66523",
    full_city_name: "Osage City, KS",
    city_name: "Osage City",
  },
  {
    postal_code: "70648",
    full_city_name: "Kinder, LA",
    city_name: "Kinder",
  },
  {
    postal_code: "82934",
    full_city_name: "Granger, WY",
    city_name: "Granger",
  },
  {
    postal_code: "39347",
    full_city_name: "Pachuta, MS",
    city_name: "Pachuta",
  },
  {
    postal_code: "52720",
    full_city_name: "Atalissa, IA",
    city_name: "Atalissa",
  },
  {
    postal_code: "10577",
    full_city_name: "Purchase, NY",
    city_name: "Purchase",
  },
  {
    postal_code: "67031",
    full_city_name: "Conway Springs, KS",
    city_name: "Conway Springs",
  },
  {
    postal_code: "01355",
    full_city_name: "New Salem, MA",
    city_name: "New Salem",
  },
  {
    postal_code: "64637",
    full_city_name: "Cowgill, MO",
    city_name: "Cowgill",
  },
  {
    postal_code: "83636",
    full_city_name: "Letha, ID",
    city_name: "Letha",
  },
  {
    postal_code: "67731",
    full_city_name: "Bird City, KS",
    city_name: "Bird City",
  },
  {
    postal_code: "62547",
    full_city_name: "Mount Auburn, IL",
    city_name: "Mount Auburn",
  },
  {
    postal_code: "67332",
    full_city_name: "Bartlett, KS",
    city_name: "Bartlett",
  },
  {
    postal_code: "71652",
    full_city_name: "Kingsland, AR",
    city_name: "Kingsland",
  },
  {
    postal_code: "83868",
    full_city_name: "Smelterville, ID",
    city_name: "Smelterville",
  },
  {
    postal_code: "79853",
    full_city_name: "Tornillo, TX",
    city_name: "Tornillo",
  },
  {
    postal_code: "37028",
    full_city_name: "Bumpus Mills, TN",
    city_name: "Bumpus Mills",
  },
  {
    postal_code: "55973",
    full_city_name: "Sargeant, MN",
    city_name: "Sargeant",
  },
  {
    postal_code: "15466",
    full_city_name: "Newell, PA",
    city_name: "Newell",
  },
  {
    postal_code: "37142",
    full_city_name: "Palmyra, TN",
    city_name: "Palmyra",
  },
  {
    postal_code: "41501",
    full_city_name: "Pikeville, KY",
    city_name: "Pikeville",
  },
  {
    postal_code: "81128",
    full_city_name: "Chromo, CO",
    city_name: "Chromo",
  },
  {
    postal_code: "62883",
    full_city_name: "Scheller, IL",
    city_name: "Scheller",
  },
  {
    postal_code: "72538",
    full_city_name: "Gepp, AR",
    city_name: "Gepp",
  },
  {
    postal_code: "14042",
    full_city_name: "Delevan, NY",
    city_name: "Delevan",
  },
  {
    postal_code: "84776",
    full_city_name: "Tropic, UT",
    city_name: "Tropic",
  },
  {
    postal_code: "23915",
    full_city_name: "Baskerville, VA",
    city_name: "Baskerville",
  },
  {
    postal_code: "75603",
    full_city_name: "Longview, TX",
    city_name: "Longview",
  },
  {
    postal_code: "49004",
    full_city_name: "Kalamazoo, MI",
    city_name: "Kalamazoo",
  },
  {
    postal_code: "66439",
    full_city_name: "Horton, KS",
    city_name: "Horton",
  },
  {
    postal_code: "59024",
    full_city_name: "Custer, MT",
    city_name: "Custer",
  },
  {
    postal_code: "26624",
    full_city_name: "Gassaway, WV",
    city_name: "Gassaway",
  },
  {
    postal_code: "70453",
    full_city_name: "Pine Grove, LA",
    city_name: "Pine Grove",
  },
  {
    postal_code: "81126",
    full_city_name: "Chama, CO",
    city_name: "Chama",
  },
  {
    postal_code: "36028",
    full_city_name: "Dozier, AL",
    city_name: "Dozier",
  },
  {
    postal_code: "81120",
    full_city_name: "Antonito, CO",
    city_name: "Antonito",
  },
  {
    postal_code: "82329",
    full_city_name: "Medicine Bow, WY",
    city_name: "Medicine Bow",
  },
  {
    postal_code: "45854",
    full_city_name: "Lafayette, OH",
    city_name: "Lafayette",
  },
  {
    postal_code: "07842",
    full_city_name: "Hibernia, NJ",
    city_name: "Hibernia",
  },
  {
    postal_code: "35975",
    full_city_name: "Groveoak, AL",
    city_name: "Groveoak",
  },
  {
    postal_code: "58621",
    full_city_name: "Beach, ND",
    city_name: "Beach",
  },
  {
    postal_code: "10953",
    full_city_name: "Mountainville, NY",
    city_name: "Mountainville",
  },
  {
    postal_code: "68403",
    full_city_name: "Manley, NE",
    city_name: "Manley",
  },
  {
    postal_code: "73109",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "14134",
    full_city_name: "Sardinia, NY",
    city_name: "Sardinia",
  },
  {
    postal_code: "63330",
    full_city_name: "Annada, MO",
    city_name: "Annada",
  },
  {
    postal_code: "71654",
    full_city_name: "Mc Gehee, AR",
    city_name: "Mc Gehee",
  },
  {
    postal_code: "95543",
    full_city_name: "Gasquet, CA",
    city_name: "Gasquet",
  },
  {
    postal_code: "81124",
    full_city_name: "Capulin, CO",
    city_name: "Capulin",
  },
  {
    postal_code: "72521",
    full_city_name: "Cave City, AR",
    city_name: "Cave City",
  },
  {
    postal_code: "61928",
    full_city_name: "Gays, IL",
    city_name: "Gays",
  },
  {
    postal_code: "61720",
    full_city_name: "Anchor, IL",
    city_name: "Anchor",
  },
  {
    postal_code: "41034",
    full_city_name: "Dover, KY",
    city_name: "Dover",
  },
  {
    postal_code: "50242",
    full_city_name: "Searsboro, IA",
    city_name: "Searsboro",
  },
  {
    postal_code: "27544",
    full_city_name: "Kittrell, NC",
    city_name: "Kittrell",
  },
  {
    postal_code: "52595",
    full_city_name: "University Park, IA",
    city_name: "University Park",
  },
  {
    postal_code: "66548",
    full_city_name: "Waterville, KS",
    city_name: "Waterville",
  },
  {
    postal_code: "15962",
    full_city_name: "Wilmore, PA",
    city_name: "Wilmore",
  },
  {
    postal_code: "64652",
    full_city_name: "Laredo, MO",
    city_name: "Laredo",
  },
  {
    postal_code: "16422",
    full_city_name: "Harmonsburg, PA",
    city_name: "Harmonsburg",
  },
  {
    postal_code: "87801",
    full_city_name: "Socorro, NM",
    city_name: "Socorro",
  },
  {
    postal_code: "64779",
    full_city_name: "Rich Hill, MO",
    city_name: "Rich Hill",
  },
  {
    postal_code: "67660",
    full_city_name: "Pfeifer, KS",
    city_name: "Pfeifer",
  },
  {
    postal_code: "45629",
    full_city_name: "Franklin Furnace, OH",
    city_name: "Franklin Furnace",
  },
  {
    postal_code: "16344",
    full_city_name: "Rouseville, PA",
    city_name: "Rouseville",
  },
  {
    postal_code: "39837",
    full_city_name: "Colquitt, GA",
    city_name: "Colquitt",
  },
  {
    postal_code: "06387",
    full_city_name: "Wauregan, CT",
    city_name: "Wauregan",
  },
  {
    postal_code: "26855",
    full_city_name: "Cabins, WV",
    city_name: "Cabins",
  },
  {
    postal_code: "71030",
    full_city_name: "Gloster, LA",
    city_name: "Gloster",
  },
  {
    postal_code: "98610",
    full_city_name: "Carson, WA",
    city_name: "Carson",
  },
  {
    postal_code: "57349",
    full_city_name: "Howard, SD",
    city_name: "Howard",
  },
  {
    postal_code: "28617",
    full_city_name: "Crumpler, NC",
    city_name: "Crumpler",
  },
  {
    postal_code: "56039",
    full_city_name: "Granada, MN",
    city_name: "Granada",
  },
  {
    postal_code: "31023",
    full_city_name: "Eastman, GA",
    city_name: "Eastman",
  },
  {
    postal_code: "05362",
    full_city_name: "Williamsville, VT",
    city_name: "Williamsville",
  },
  {
    postal_code: "58001",
    full_city_name: "Abercrombie, ND",
    city_name: "Abercrombie",
  },
  {
    postal_code: "78576",
    full_city_name: "Penitas, TX",
    city_name: "Penitas",
  },
  {
    postal_code: "31735",
    full_city_name: "Cobb, GA",
    city_name: "Cobb",
  },
  {
    postal_code: "14536",
    full_city_name: "Portageville, NY",
    city_name: "Portageville",
  },
  {
    postal_code: "07011",
    full_city_name: "Clifton, NJ",
    city_name: "Clifton",
  },
  {
    postal_code: "99686",
    full_city_name: "Valdez, AK",
    city_name: "Valdez",
  },
  {
    postal_code: "80430",
    full_city_name: "Coalmont, CO",
    city_name: "Coalmont",
  },
  {
    postal_code: "07670",
    full_city_name: "Tenafly, NJ",
    city_name: "Tenafly",
  },
  {
    postal_code: "53211",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "54471",
    full_city_name: "Ringle, WI",
    city_name: "Ringle",
  },
  {
    postal_code: "98243",
    full_city_name: "Deer Harbor, WA",
    city_name: "Deer Harbor",
  },
  {
    postal_code: "05030",
    full_city_name: "Ascutney, VT",
    city_name: "Ascutney",
  },
  {
    postal_code: "93252",
    full_city_name: "Maricopa, CA",
    city_name: "Maricopa",
  },
  {
    postal_code: "42638",
    full_city_name: "Revelo, KY",
    city_name: "Revelo",
  },
  {
    postal_code: "43788",
    full_city_name: "Summerfield, OH",
    city_name: "Summerfield",
  },
  {
    postal_code: "31820",
    full_city_name: "Midland, GA",
    city_name: "Midland",
  },
  {
    postal_code: "54771",
    full_city_name: "Thorp, WI",
    city_name: "Thorp",
  },
  {
    postal_code: "21717",
    full_city_name: "Buckeystown, MD",
    city_name: "Buckeystown",
  },
  {
    postal_code: "79244",
    full_city_name: "Matador, TX",
    city_name: "Matador",
  },
  {
    postal_code: "23405",
    full_city_name: "Machipongo, VA",
    city_name: "Machipongo",
  },
  {
    postal_code: "99688",
    full_city_name: "Willow, AK",
    city_name: "Willow",
  },
  {
    postal_code: "76380",
    full_city_name: "Seymour, TX",
    city_name: "Seymour",
  },
  {
    postal_code: "62479",
    full_city_name: "Wheeler, IL",
    city_name: "Wheeler",
  },
  {
    postal_code: "72517",
    full_city_name: "Brockwell, AR",
    city_name: "Brockwell",
  },
  {
    postal_code: "27981",
    full_city_name: "Wanchese, NC",
    city_name: "Wanchese",
  },
  {
    postal_code: "46946",
    full_city_name: "Liberty Mills, IN",
    city_name: "Liberty Mills",
  },
  {
    postal_code: "36867",
    full_city_name: "Phenix City, AL",
    city_name: "Phenix City",
  },
  {
    postal_code: "97484",
    full_city_name: "Tiller, OR",
    city_name: "Tiller",
  },
  {
    postal_code: "75566",
    full_city_name: "Marietta, TX",
    city_name: "Marietta",
  },
  {
    postal_code: "56161",
    full_city_name: "Okabena, MN",
    city_name: "Okabena",
  },
  {
    postal_code: "49312",
    full_city_name: "Brohman, MI",
    city_name: "Brohman",
  },
  {
    postal_code: "53931",
    full_city_name: "Fairwater, WI",
    city_name: "Fairwater",
  },
  {
    postal_code: "50604",
    full_city_name: "Aplington, IA",
    city_name: "Aplington",
  },
  {
    postal_code: "58852",
    full_city_name: "Tioga, ND",
    city_name: "Tioga",
  },
  {
    postal_code: "98558",
    full_city_name: "Mckenna, WA",
    city_name: "Mckenna",
  },
  {
    postal_code: "22841",
    full_city_name: "Mount Crawford, VA",
    city_name: "Mount Crawford",
  },
  {
    postal_code: "63935",
    full_city_name: "Doniphan, MO",
    city_name: "Doniphan",
  },
  {
    postal_code: "24954",
    full_city_name: "Marlinton, WV",
    city_name: "Marlinton",
  },
  {
    postal_code: "71762",
    full_city_name: "Smackover, AR",
    city_name: "Smackover",
  },
  {
    postal_code: "83535",
    full_city_name: "Juliaetta, ID",
    city_name: "Juliaetta",
  },
  {
    postal_code: "78635",
    full_city_name: "Hye, TX",
    city_name: "Hye",
  },
  {
    postal_code: "04430",
    full_city_name: "East Millinocket, ME",
    city_name: "East Millinocket",
  },
  {
    postal_code: "97324",
    full_city_name: "Alsea, OR",
    city_name: "Alsea",
  },
  {
    postal_code: "17243",
    full_city_name: "Orbisonia, PA",
    city_name: "Orbisonia",
  },
  {
    postal_code: "48760",
    full_city_name: "Silverwood, MI",
    city_name: "Silverwood",
  },
  {
    postal_code: "56118",
    full_city_name: "Bingham Lake, MN",
    city_name: "Bingham Lake",
  },
  {
    postal_code: "29851",
    full_city_name: "Warrenville, SC",
    city_name: "Warrenville",
  },
  {
    postal_code: "10958",
    full_city_name: "New Hampton, NY",
    city_name: "New Hampton",
  },
  {
    postal_code: "53047",
    full_city_name: "Lebanon, WI",
    city_name: "Lebanon",
  },
  {
    postal_code: "97812",
    full_city_name: "Arlington, OR",
    city_name: "Arlington",
  },
  {
    postal_code: "72747",
    full_city_name: "Maysville, AR",
    city_name: "Maysville",
  },
  {
    postal_code: "32455",
    full_city_name: "Ponce De Leon, FL",
    city_name: "Ponce De Leon",
  },
  {
    postal_code: "56083",
    full_city_name: "Sanborn, MN",
    city_name: "Sanborn",
  },
  {
    postal_code: "98860",
    full_city_name: "Wilson Creek, WA",
    city_name: "Wilson Creek",
  },
  {
    postal_code: "71923",
    full_city_name: "Arkadelphia, AR",
    city_name: "Arkadelphia",
  },
  {
    postal_code: "49455",
    full_city_name: "Shelby, MI",
    city_name: "Shelby",
  },
  {
    postal_code: "59526",
    full_city_name: "Harlem, MT",
    city_name: "Harlem",
  },
  {
    postal_code: "98602",
    full_city_name: "Appleton, WA",
    city_name: "Appleton",
  },
  {
    postal_code: "86338",
    full_city_name: "Skull Valley, AZ",
    city_name: "Skull Valley",
  },
  {
    postal_code: "66432",
    full_city_name: "Havensville, KS",
    city_name: "Havensville",
  },
  {
    postal_code: "56575",
    full_city_name: "Ponsford, MN",
    city_name: "Ponsford",
  },
  {
    postal_code: "18619",
    full_city_name: "Hillsgrove, PA",
    city_name: "Hillsgrove",
  },
  {
    postal_code: "44826",
    full_city_name: "Collins, OH",
    city_name: "Collins",
  },
  {
    postal_code: "38871",
    full_city_name: "Thaxton, MS",
    city_name: "Thaxton",
  },
  {
    postal_code: "10987",
    full_city_name: "Tuxedo Park, NY",
    city_name: "Tuxedo Park",
  },
  {
    postal_code: "77614",
    full_city_name: "Deweyville, TX",
    city_name: "Deweyville",
  },
  {
    postal_code: "38260",
    full_city_name: "Troy, TN",
    city_name: "Troy",
  },
  {
    postal_code: "38863",
    full_city_name: "Pontotoc, MS",
    city_name: "Pontotoc",
  },
  {
    postal_code: "43728",
    full_city_name: "Chesterhill, OH",
    city_name: "Chesterhill",
  },
  {
    postal_code: "45775",
    full_city_name: "Rutland, OH",
    city_name: "Rutland",
  },
  {
    postal_code: "05826",
    full_city_name: "Craftsbury, VT",
    city_name: "Craftsbury",
  },
  {
    postal_code: "40759",
    full_city_name: "Rockholds, KY",
    city_name: "Rockholds",
  },
  {
    postal_code: "79353",
    full_city_name: "Pep, TX",
    city_name: "Pep",
  },
  {
    postal_code: "28441",
    full_city_name: "Garland, NC",
    city_name: "Garland",
  },
  {
    postal_code: "23108",
    full_city_name: "Mascot, VA",
    city_name: "Mascot",
  },
  {
    postal_code: "82512",
    full_city_name: "Crowheart, WY",
    city_name: "Crowheart",
  },
  {
    postal_code: "79346",
    full_city_name: "Morton, TX",
    city_name: "Morton",
  },
  {
    postal_code: "29805",
    full_city_name: "Aiken, SC",
    city_name: "Aiken",
  },
  {
    postal_code: "56759",
    full_city_name: "Strathcona, MN",
    city_name: "Strathcona",
  },
  {
    postal_code: "14721",
    full_city_name: "Ceres, NY",
    city_name: "Ceres",
  },
  {
    postal_code: "61812",
    full_city_name: "Armstrong, IL",
    city_name: "Armstrong",
  },
  {
    postal_code: "57201",
    full_city_name: "Watertown, SD",
    city_name: "Watertown",
  },
  {
    postal_code: "15861",
    full_city_name: "Sinnamahoning, PA",
    city_name: "Sinnamahoning",
  },
  {
    postal_code: "36075",
    full_city_name: "Shorter, AL",
    city_name: "Shorter",
  },
  {
    postal_code: "96087",
    full_city_name: "Shasta, CA",
    city_name: "Shasta",
  },
  {
    postal_code: "22663",
    full_city_name: "White Post, VA",
    city_name: "White Post",
  },
  {
    postal_code: "37014",
    full_city_name: "Arrington, TN",
    city_name: "Arrington",
  },
  {
    postal_code: "57626",
    full_city_name: "Faith, SD",
    city_name: "Faith",
  },
  {
    postal_code: "68757",
    full_city_name: "Newcastle, NE",
    city_name: "Newcastle",
  },
  {
    postal_code: "13124",
    full_city_name: "North Pitcher, NY",
    city_name: "North Pitcher",
  },
  {
    postal_code: "19549",
    full_city_name: "Port Clinton, PA",
    city_name: "Port Clinton",
  },
  {
    postal_code: "63546",
    full_city_name: "Greentop, MO",
    city_name: "Greentop",
  },
  {
    postal_code: "35988",
    full_city_name: "Sylvania, AL",
    city_name: "Sylvania",
  },
  {
    postal_code: "25617",
    full_city_name: "Davin, WV",
    city_name: "Davin",
  },
  {
    postal_code: "36480",
    full_city_name: "Uriah, AL",
    city_name: "Uriah",
  },
  {
    postal_code: "17213",
    full_city_name: "Blairs Mills, PA",
    city_name: "Blairs Mills",
  },
  {
    postal_code: "14514",
    full_city_name: "North Chili, NY",
    city_name: "North Chili",
  },
  {
    postal_code: "67735",
    full_city_name: "Goodland, KS",
    city_name: "Goodland",
  },
  {
    postal_code: "50529",
    full_city_name: "Dakota City, IA",
    city_name: "Dakota City",
  },
  {
    postal_code: "72082",
    full_city_name: "Kensett, AR",
    city_name: "Kensett",
  },
  {
    postal_code: "73761",
    full_city_name: "Nash, OK",
    city_name: "Nash",
  },
  {
    postal_code: "52048",
    full_city_name: "Garber, IA",
    city_name: "Garber",
  },
  {
    postal_code: "66761",
    full_city_name: "Piqua, KS",
    city_name: "Piqua",
  },
  {
    postal_code: "50076",
    full_city_name: "Exira, IA",
    city_name: "Exira",
  },
  {
    postal_code: "21669",
    full_city_name: "Taylors Island, MD",
    city_name: "Taylors Island",
  },
  {
    postal_code: "42265",
    full_city_name: "Olmstead, KY",
    city_name: "Olmstead",
  },
  {
    postal_code: "42757",
    full_city_name: "Magnolia, KY",
    city_name: "Magnolia",
  },
  {
    postal_code: "31016",
    full_city_name: "Culloden, GA",
    city_name: "Culloden",
  },
  {
    postal_code: "30295",
    full_city_name: "Zebulon, GA",
    city_name: "Zebulon",
  },
  {
    postal_code: "48749",
    full_city_name: "Omer, MI",
    city_name: "Omer",
  },
  {
    postal_code: "72434",
    full_city_name: "Imboden, AR",
    city_name: "Imboden",
  },
  {
    postal_code: "64487",
    full_city_name: "Skidmore, MO",
    city_name: "Skidmore",
  },
  {
    postal_code: "51238",
    full_city_name: "Hospers, IA",
    city_name: "Hospers",
  },
  {
    postal_code: "61454",
    full_city_name: "Lomax, IL",
    city_name: "Lomax",
  },
  {
    postal_code: "38463",
    full_city_name: "Iron City, TN",
    city_name: "Iron City",
  },
  {
    postal_code: "66866",
    full_city_name: "Peabody, KS",
    city_name: "Peabody",
  },
  {
    postal_code: "47612",
    full_city_name: "Cynthiana, IN",
    city_name: "Cynthiana",
  },
  {
    postal_code: "16744",
    full_city_name: "Rew, PA",
    city_name: "Rew",
  },
  {
    postal_code: "26030",
    full_city_name: "Beech Bottom, WV",
    city_name: "Beech Bottom",
  },
  {
    postal_code: "24927",
    full_city_name: "Cass, WV",
    city_name: "Cass",
  },
  {
    postal_code: "50586",
    full_city_name: "Somers, IA",
    city_name: "Somers",
  },
  {
    postal_code: "87749",
    full_city_name: "Ute Park, NM",
    city_name: "Ute Park",
  },
  {
    postal_code: "14485",
    full_city_name: "Lima, NY",
    city_name: "Lima",
  },
  {
    postal_code: "29516",
    full_city_name: "Blenheim, SC",
    city_name: "Blenheim",
  },
  {
    postal_code: "60969",
    full_city_name: "Union Hill, IL",
    city_name: "Union Hill",
  },
  {
    postal_code: "52752",
    full_city_name: "Grandview, IA",
    city_name: "Grandview",
  },
  {
    postal_code: "57384",
    full_city_name: "Wolsey, SD",
    city_name: "Wolsey",
  },
  {
    postal_code: "62625",
    full_city_name: "Cantrall, IL",
    city_name: "Cantrall",
  },
  {
    postal_code: "49799",
    full_city_name: "Wolverine, MI",
    city_name: "Wolverine",
  },
  {
    postal_code: "22959",
    full_city_name: "North Garden, VA",
    city_name: "North Garden",
  },
  {
    postal_code: "72670",
    full_city_name: "Ponca, AR",
    city_name: "Ponca",
  },
  {
    postal_code: "35234",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "97033",
    full_city_name: "Kent, OR",
    city_name: "Kent",
  },
  {
    postal_code: "87042",
    full_city_name: "Peralta, NM",
    city_name: "Peralta",
  },
  {
    postal_code: "13613",
    full_city_name: "Brasher Falls, NY",
    city_name: "Brasher Falls",
  },
  {
    postal_code: "88324",
    full_city_name: "Glencoe, NM",
    city_name: "Glencoe",
  },
  {
    postal_code: "95525",
    full_city_name: "Blue Lake, CA",
    city_name: "Blue Lake",
  },
  {
    postal_code: "30148",
    full_city_name: "Marble Hill, GA",
    city_name: "Marble Hill",
  },
  {
    postal_code: "92121",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "36877",
    full_city_name: "Smiths Station, AL",
    city_name: "Smiths Station",
  },
  {
    postal_code: "61841",
    full_city_name: "Fairmount, IL",
    city_name: "Fairmount",
  },
  {
    postal_code: "83641",
    full_city_name: "Melba, ID",
    city_name: "Melba",
  },
  {
    postal_code: "62974",
    full_city_name: "Pittsburg, IL",
    city_name: "Pittsburg",
  },
  {
    postal_code: "70772",
    full_city_name: "Rosedale, LA",
    city_name: "Rosedale",
  },
  {
    postal_code: "61743",
    full_city_name: "Graymont, IL",
    city_name: "Graymont",
  },
  {
    postal_code: "48412",
    full_city_name: "Attica, MI",
    city_name: "Attica",
  },
  {
    postal_code: "61845",
    full_city_name: "Foosland, IL",
    city_name: "Foosland",
  },
  {
    postal_code: "80235",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "58727",
    full_city_name: "Columbus, ND",
    city_name: "Columbus",
  },
  {
    postal_code: "18083",
    full_city_name: "Stockertown, PA",
    city_name: "Stockertown",
  },
  {
    postal_code: "62877",
    full_city_name: "Richview, IL",
    city_name: "Richview",
  },
  {
    postal_code: "02713",
    full_city_name: "Cuttyhunk, MA",
    city_name: "Cuttyhunk",
  },
  {
    postal_code: "17778",
    full_city_name: "Westport, PA",
    city_name: "Westport",
  },
  {
    postal_code: "82515",
    full_city_name: "Hudson, WY",
    city_name: "Hudson",
  },
  {
    postal_code: "12007",
    full_city_name: "Alcove, NY",
    city_name: "Alcove",
  },
  {
    postal_code: "46740",
    full_city_name: "Geneva, IN",
    city_name: "Geneva",
  },
  {
    postal_code: "40840",
    full_city_name: "Helton, KY",
    city_name: "Helton",
  },
  {
    postal_code: "74743",
    full_city_name: "Hugo, OK",
    city_name: "Hugo",
  },
  {
    postal_code: "29525",
    full_city_name: "Clio, SC",
    city_name: "Clio",
  },
  {
    postal_code: "54670",
    full_city_name: "Wilton, WI",
    city_name: "Wilton",
  },
  {
    postal_code: "68354",
    full_city_name: "Fairmont, NE",
    city_name: "Fairmont",
  },
  {
    postal_code: "47120",
    full_city_name: "Fredericksburg, IN",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "28508",
    full_city_name: "Albertson, NC",
    city_name: "Albertson",
  },
  {
    postal_code: "62006",
    full_city_name: "Batchtown, IL",
    city_name: "Batchtown",
  },
  {
    postal_code: "72660",
    full_city_name: "Oak Grove, AR",
    city_name: "Oak Grove",
  },
  {
    postal_code: "54126",
    full_city_name: "Greenleaf, WI",
    city_name: "Greenleaf",
  },
  {
    postal_code: "64624",
    full_city_name: "Braymer, MO",
    city_name: "Braymer",
  },
  {
    postal_code: "97737",
    full_city_name: "Gilchrist, OR",
    city_name: "Gilchrist",
  },
  {
    postal_code: "83446",
    full_city_name: "Spencer, ID",
    city_name: "Spencer",
  },
  {
    postal_code: "36870",
    full_city_name: "Phenix City, AL",
    city_name: "Phenix City",
  },
  {
    postal_code: "48847",
    full_city_name: "Ithaca, MI",
    city_name: "Ithaca",
  },
  {
    postal_code: "20630",
    full_city_name: "Drayden, MD",
    city_name: "Drayden",
  },
  {
    postal_code: "38759",
    full_city_name: "Merigold, MS",
    city_name: "Merigold",
  },
  {
    postal_code: "54214",
    full_city_name: "Francis Creek, WI",
    city_name: "Francis Creek",
  },
  {
    postal_code: "97454",
    full_city_name: "Marcola, OR",
    city_name: "Marcola",
  },
  {
    postal_code: "66026",
    full_city_name: "Fontana, KS",
    city_name: "Fontana",
  },
  {
    postal_code: "13364",
    full_city_name: "Leonardsville, NY",
    city_name: "Leonardsville",
  },
  {
    postal_code: "59721",
    full_city_name: "Cardwell, MT",
    city_name: "Cardwell",
  },
  {
    postal_code: "56114",
    full_city_name: "Avoca, MN",
    city_name: "Avoca",
  },
  {
    postal_code: "47270",
    full_city_name: "Paris Crossing, IN",
    city_name: "Paris Crossing",
  },
  {
    postal_code: "57324",
    full_city_name: "Cavour, SD",
    city_name: "Cavour",
  },
  {
    postal_code: "78870",
    full_city_name: "Knippa, TX",
    city_name: "Knippa",
  },
  {
    postal_code: "95417",
    full_city_name: "Branscomb, CA",
    city_name: "Branscomb",
  },
  {
    postal_code: "12787",
    full_city_name: "White Sulphur Springs, NY",
    city_name: "White Sulphur Springs",
  },
  {
    postal_code: "68352",
    full_city_name: "Fairbury, NE",
    city_name: "Fairbury",
  },
  {
    postal_code: "87734",
    full_city_name: "Ocate, NM",
    city_name: "Ocate",
  },
  {
    postal_code: "81143",
    full_city_name: "Moffat, CO",
    city_name: "Moffat",
  },
  {
    postal_code: "83322",
    full_city_name: "Corral, ID",
    city_name: "Corral",
  },
  {
    postal_code: "99140",
    full_city_name: "Keller, WA",
    city_name: "Keller",
  },
  {
    postal_code: "53149",
    full_city_name: "Mukwonago, WI",
    city_name: "Mukwonago",
  },
  {
    postal_code: "31550",
    full_city_name: "Manor, GA",
    city_name: "Manor",
  },
  {
    postal_code: "62355",
    full_city_name: "Nebo, IL",
    city_name: "Nebo",
  },
  {
    postal_code: "58768",
    full_city_name: "Norwich, ND",
    city_name: "Norwich",
  },
  {
    postal_code: "61346",
    full_city_name: "New Bedford, IL",
    city_name: "New Bedford",
  },
  {
    postal_code: "30454",
    full_city_name: "Rockledge, GA",
    city_name: "Rockledge",
  },
  {
    postal_code: "55982",
    full_city_name: "Waltham, MN",
    city_name: "Waltham",
  },
  {
    postal_code: "57024",
    full_city_name: "Egan, SD",
    city_name: "Egan",
  },
  {
    postal_code: "47452",
    full_city_name: "Orleans, IN",
    city_name: "Orleans",
  },
  {
    postal_code: "58271",
    full_city_name: "Pembina, ND",
    city_name: "Pembina",
  },
  {
    postal_code: "81429",
    full_city_name: "Paradox, CO",
    city_name: "Paradox",
  },
  {
    postal_code: "52745",
    full_city_name: "Dixon, IA",
    city_name: "Dixon",
  },
  {
    postal_code: "52216",
    full_city_name: "Clarence, IA",
    city_name: "Clarence",
  },
  {
    postal_code: "39179",
    full_city_name: "Vaughan, MS",
    city_name: "Vaughan",
  },
  {
    postal_code: "65040",
    full_city_name: "Henley, MO",
    city_name: "Henley",
  },
  {
    postal_code: "94304",
    full_city_name: "Palo Alto, CA",
    city_name: "Palo Alto",
  },
  {
    postal_code: "42715",
    full_city_name: "Breeding, KY",
    city_name: "Breeding",
  },
  {
    postal_code: "31097",
    full_city_name: "Yatesville, GA",
    city_name: "Yatesville",
  },
  {
    postal_code: "57249",
    full_city_name: "Lake Preston, SD",
    city_name: "Lake Preston",
  },
  {
    postal_code: "64476",
    full_city_name: "Pickering, MO",
    city_name: "Pickering",
  },
  {
    postal_code: "16671",
    full_city_name: "Ramey, PA",
    city_name: "Ramey",
  },
  {
    postal_code: "58623",
    full_city_name: "Bowman, ND",
    city_name: "Bowman",
  },
  {
    postal_code: "72645",
    full_city_name: "Leslie, AR",
    city_name: "Leslie",
  },
  {
    postal_code: "40902",
    full_city_name: "Arjay, KY",
    city_name: "Arjay",
  },
  {
    postal_code: "42408",
    full_city_name: "Dawson Springs, KY",
    city_name: "Dawson Springs",
  },
  {
    postal_code: "84082",
    full_city_name: "Wallsburg, UT",
    city_name: "Wallsburg",
  },
  {
    postal_code: "16030",
    full_city_name: "Eau Claire, PA",
    city_name: "Eau Claire",
  },
  {
    postal_code: "98119",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "46144",
    full_city_name: "Gwynneville, IN",
    city_name: "Gwynneville",
  },
  {
    postal_code: "57363",
    full_city_name: "Mount Vernon, SD",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "80103",
    full_city_name: "Byers, CO",
    city_name: "Byers",
  },
  {
    postal_code: "38376",
    full_city_name: "Shiloh, TN",
    city_name: "Shiloh",
  },
  {
    postal_code: "15901",
    full_city_name: "Johnstown, PA",
    city_name: "Johnstown",
  },
  {
    postal_code: "53922",
    full_city_name: "Burnett, WI",
    city_name: "Burnett",
  },
  {
    postal_code: "66857",
    full_city_name: "Le Roy, KS",
    city_name: "Le Roy",
  },
  {
    postal_code: "95549",
    full_city_name: "Kneeland, CA",
    city_name: "Kneeland",
  },
  {
    postal_code: "49032",
    full_city_name: "Centreville, MI",
    city_name: "Centreville",
  },
  {
    postal_code: "78572",
    full_city_name: "Mission, TX",
    city_name: "Mission",
  },
  {
    postal_code: "15377",
    full_city_name: "West Finley, PA",
    city_name: "West Finley",
  },
  {
    postal_code: "37313",
    full_city_name: "Coalmont, TN",
    city_name: "Coalmont",
  },
  {
    postal_code: "47327",
    full_city_name: "Cambridge City, IN",
    city_name: "Cambridge City",
  },
  {
    postal_code: "46959",
    full_city_name: "Miami, IN",
    city_name: "Miami",
  },
  {
    postal_code: "84761",
    full_city_name: "Parowan, UT",
    city_name: "Parowan",
  },
  {
    postal_code: "76301",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "53810",
    full_city_name: "Glen Haven, WI",
    city_name: "Glen Haven",
  },
  {
    postal_code: "43521",
    full_city_name: "Fayette, OH",
    city_name: "Fayette",
  },
  {
    postal_code: "99356",
    full_city_name: "Roosevelt, WA",
    city_name: "Roosevelt",
  },
  {
    postal_code: "35572",
    full_city_name: "Houston, AL",
    city_name: "Houston",
  },
  {
    postal_code: "62976",
    full_city_name: "Pulaski, IL",
    city_name: "Pulaski",
  },
  {
    postal_code: "14025",
    full_city_name: "Boston, NY",
    city_name: "Boston",
  },
  {
    postal_code: "79011",
    full_city_name: "Briscoe, TX",
    city_name: "Briscoe",
  },
  {
    postal_code: "57026",
    full_city_name: "Elkton, SD",
    city_name: "Elkton",
  },
  {
    postal_code: "74045",
    full_city_name: "Maramec, OK",
    city_name: "Maramec",
  },
  {
    postal_code: "65744",
    full_city_name: "Rueter, MO",
    city_name: "Rueter",
  },
  {
    postal_code: "46776",
    full_city_name: "Orland, IN",
    city_name: "Orland",
  },
  {
    postal_code: "73759",
    full_city_name: "Medford, OK",
    city_name: "Medford",
  },
  {
    postal_code: "71336",
    full_city_name: "Gilbert, LA",
    city_name: "Gilbert",
  },
  {
    postal_code: "85553",
    full_city_name: "Tonto Basin, AZ",
    city_name: "Tonto Basin",
  },
  {
    postal_code: "73082",
    full_city_name: "Rush Springs, OK",
    city_name: "Rush Springs",
  },
  {
    postal_code: "05462",
    full_city_name: "Huntington, VT",
    city_name: "Huntington",
  },
  {
    postal_code: "77426",
    full_city_name: "Chappell Hill, TX",
    city_name: "Chappell Hill",
  },
  {
    postal_code: "58776",
    full_city_name: "Ross, ND",
    city_name: "Ross",
  },
  {
    postal_code: "07931",
    full_city_name: "Far Hills, NJ",
    city_name: "Far Hills",
  },
  {
    postal_code: "28119",
    full_city_name: "Morven, NC",
    city_name: "Morven",
  },
  {
    postal_code: "42533",
    full_city_name: "Ferguson, KY",
    city_name: "Ferguson",
  },
  {
    postal_code: "68341",
    full_city_name: "De Witt, NE",
    city_name: "De Witt",
  },
  {
    postal_code: "58451",
    full_city_name: "Hurdsfield, ND",
    city_name: "Hurdsfield",
  },
  {
    postal_code: "08511",
    full_city_name: "Cookstown, NJ",
    city_name: "Cookstown",
  },
  {
    postal_code: "44417",
    full_city_name: "Farmdale, OH",
    city_name: "Farmdale",
  },
  {
    postal_code: "95323",
    full_city_name: "Hickman, CA",
    city_name: "Hickman",
  },
  {
    postal_code: "12421",
    full_city_name: "Denver, NY",
    city_name: "Denver",
  },
  {
    postal_code: "48027",
    full_city_name: "Goodells, MI",
    city_name: "Goodells",
  },
  {
    postal_code: "67673",
    full_city_name: "Waldo, KS",
    city_name: "Waldo",
  },
  {
    postal_code: "63339",
    full_city_name: "Curryville, MO",
    city_name: "Curryville",
  },
  {
    postal_code: "54737",
    full_city_name: "Eau Galle, WI",
    city_name: "Eau Galle",
  },
  {
    postal_code: "78145",
    full_city_name: "Pawnee, TX",
    city_name: "Pawnee",
  },
  {
    postal_code: "13684",
    full_city_name: "Russell, NY",
    city_name: "Russell",
  },
  {
    postal_code: "25421",
    full_city_name: "Glengary, WV",
    city_name: "Glengary",
  },
  {
    postal_code: "59474",
    full_city_name: "Shelby, MT",
    city_name: "Shelby",
  },
  {
    postal_code: "51010",
    full_city_name: "Castana, IA",
    city_name: "Castana",
  },
  {
    postal_code: "01096",
    full_city_name: "Williamsburg, MA",
    city_name: "Williamsburg",
  },
  {
    postal_code: "46563",
    full_city_name: "Plymouth, IN",
    city_name: "Plymouth",
  },
  {
    postal_code: "57465",
    full_city_name: "Northville, SD",
    city_name: "Northville",
  },
  {
    postal_code: "36604",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "16678",
    full_city_name: "Saxton, PA",
    city_name: "Saxton",
  },
  {
    postal_code: "56650",
    full_city_name: "Kelliher, MN",
    city_name: "Kelliher",
  },
  {
    postal_code: "52750",
    full_city_name: "Goose Lake, IA",
    city_name: "Goose Lake",
  },
  {
    postal_code: "01505",
    full_city_name: "Boylston, MA",
    city_name: "Boylston",
  },
  {
    postal_code: "58756",
    full_city_name: "Makoti, ND",
    city_name: "Makoti",
  },
  {
    postal_code: "75977",
    full_city_name: "Wiergate, TX",
    city_name: "Wiergate",
  },
  {
    postal_code: "43321",
    full_city_name: "Fulton, OH",
    city_name: "Fulton",
  },
  {
    postal_code: "35643",
    full_city_name: "Hillsboro, AL",
    city_name: "Hillsboro",
  },
  {
    postal_code: "58460",
    full_city_name: "Lehr, ND",
    city_name: "Lehr",
  },
  {
    postal_code: "12788",
    full_city_name: "Woodbourne, NY",
    city_name: "Woodbourne",
  },
  {
    postal_code: "71254",
    full_city_name: "Lake Providence, LA",
    city_name: "Lake Providence",
  },
  {
    postal_code: "64461",
    full_city_name: "Hopkins, MO",
    city_name: "Hopkins",
  },
  {
    postal_code: "24058",
    full_city_name: "Belspring, VA",
    city_name: "Belspring",
  },
  {
    postal_code: "41002",
    full_city_name: "Augusta, KY",
    city_name: "Augusta",
  },
  {
    postal_code: "48894",
    full_city_name: "Westphalia, MI",
    city_name: "Westphalia",
  },
  {
    postal_code: "13639",
    full_city_name: "Fine, NY",
    city_name: "Fine",
  },
  {
    postal_code: "70715",
    full_city_name: "Batchelor, LA",
    city_name: "Batchelor",
  },
  {
    postal_code: "24991",
    full_city_name: "Williamsburg, WV",
    city_name: "Williamsburg",
  },
  {
    postal_code: "56296",
    full_city_name: "Wheaton, MN",
    city_name: "Wheaton",
  },
  {
    postal_code: "99343",
    full_city_name: "Mesa, WA",
    city_name: "Mesa",
  },
  {
    postal_code: "23324",
    full_city_name: "Chesapeake, VA",
    city_name: "Chesapeake",
  },
  {
    postal_code: "72392",
    full_city_name: "Wheatley, AR",
    city_name: "Wheatley",
  },
  {
    postal_code: "49715",
    full_city_name: "Brimley, MI",
    city_name: "Brimley",
  },
  {
    postal_code: "01344",
    full_city_name: "Erving, MA",
    city_name: "Erving",
  },
  {
    postal_code: "59540",
    full_city_name: "Rudyard, MT",
    city_name: "Rudyard",
  },
  {
    postal_code: "38860",
    full_city_name: "Okolona, MS",
    city_name: "Okolona",
  },
  {
    postal_code: "70774",
    full_city_name: "Saint Amant, LA",
    city_name: "Saint Amant",
  },
  {
    postal_code: "45741",
    full_city_name: "Langsville, OH",
    city_name: "Langsville",
  },
  {
    postal_code: "63951",
    full_city_name: "Lowndes, MO",
    city_name: "Lowndes",
  },
  {
    postal_code: "22853",
    full_city_name: "Timberville, VA",
    city_name: "Timberville",
  },
  {
    postal_code: "68876",
    full_city_name: "Shelton, NE",
    city_name: "Shelton",
  },
  {
    postal_code: "52334",
    full_city_name: "South Amana, IA",
    city_name: "South Amana",
  },
  {
    postal_code: "24216",
    full_city_name: "Appalachia, VA",
    city_name: "Appalachia",
  },
  {
    postal_code: "19732",
    full_city_name: "Rockland, DE",
    city_name: "Rockland",
  },
  {
    postal_code: "65707",
    full_city_name: "Miller, MO",
    city_name: "Miller",
  },
  {
    postal_code: "71404",
    full_city_name: "Atlanta, LA",
    city_name: "Atlanta",
  },
  {
    postal_code: "50459",
    full_city_name: "Northwood, IA",
    city_name: "Northwood",
  },
  {
    postal_code: "49825",
    full_city_name: "Eben Junction, MI",
    city_name: "Eben Junction",
  },
  {
    postal_code: "78618",
    full_city_name: "Doss, TX",
    city_name: "Doss",
  },
  {
    postal_code: "67074",
    full_city_name: "Leon, KS",
    city_name: "Leon",
  },
  {
    postal_code: "64161",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "90743",
    full_city_name: "Surfside, CA",
    city_name: "Surfside",
  },
  {
    postal_code: "16623",
    full_city_name: "Cassville, PA",
    city_name: "Cassville",
  },
  {
    postal_code: "45816",
    full_city_name: "Benton Ridge, OH",
    city_name: "Benton Ridge",
  },
  {
    postal_code: "71973",
    full_city_name: "Wickes, AR",
    city_name: "Wickes",
  },
  {
    postal_code: "58483",
    full_city_name: "Streeter, ND",
    city_name: "Streeter",
  },
  {
    postal_code: "10960",
    full_city_name: "Nyack, NY",
    city_name: "Nyack",
  },
  {
    postal_code: "98849",
    full_city_name: "Riverside, WA",
    city_name: "Riverside",
  },
  {
    postal_code: "71441",
    full_city_name: "Kelly, LA",
    city_name: "Kelly",
  },
  {
    postal_code: "72003",
    full_city_name: "Almyra, AR",
    city_name: "Almyra",
  },
  {
    postal_code: "31720",
    full_city_name: "Barwick, GA",
    city_name: "Barwick",
  },
  {
    postal_code: "73662",
    full_city_name: "Sayre, OK",
    city_name: "Sayre",
  },
  {
    postal_code: "52235",
    full_city_name: "Hills, IA",
    city_name: "Hills",
  },
  {
    postal_code: "96010",
    full_city_name: "Big Bar, CA",
    city_name: "Big Bar",
  },
  {
    postal_code: "35824",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "40036",
    full_city_name: "Lockport, KY",
    city_name: "Lockport",
  },
  {
    postal_code: "30425",
    full_city_name: "Garfield, GA",
    city_name: "Garfield",
  },
  {
    postal_code: "18351",
    full_city_name: "Portland, PA",
    city_name: "Portland",
  },
  {
    postal_code: "49128",
    full_city_name: "Three Oaks, MI",
    city_name: "Three Oaks",
  },
  {
    postal_code: "24374",
    full_city_name: "Speedwell, VA",
    city_name: "Speedwell",
  },
  {
    postal_code: "49088",
    full_city_name: "Scotts, MI",
    city_name: "Scotts",
  },
  {
    postal_code: "07624",
    full_city_name: "Closter, NJ",
    city_name: "Closter",
  },
  {
    postal_code: "50126",
    full_city_name: "Iowa Falls, IA",
    city_name: "Iowa Falls",
  },
  {
    postal_code: "45883",
    full_city_name: "Saint Henry, OH",
    city_name: "Saint Henry",
  },
  {
    postal_code: "05081",
    full_city_name: "Wells River, VT",
    city_name: "Wells River",
  },
  {
    postal_code: "87581",
    full_city_name: "Vallecitos, NM",
    city_name: "Vallecitos",
  },
  {
    postal_code: "14620",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "05052",
    full_city_name: "North Hartland, VT",
    city_name: "North Hartland",
  },
  {
    postal_code: "91906",
    full_city_name: "Campo, CA",
    city_name: "Campo",
  },
  {
    postal_code: "99744",
    full_city_name: "Anderson, AK",
    city_name: "Anderson",
  },
  {
    postal_code: "62984",
    full_city_name: "Shawneetown, IL",
    city_name: "Shawneetown",
  },
  {
    postal_code: "93524",
    full_city_name: "Edwards, CA",
    city_name: "Edwards",
  },
  {
    postal_code: "38769",
    full_city_name: "Rosedale, MS",
    city_name: "Rosedale",
  },
  {
    postal_code: "46571",
    full_city_name: "Topeka, IN",
    city_name: "Topeka",
  },
  {
    postal_code: "16420",
    full_city_name: "Grand Valley, PA",
    city_name: "Grand Valley",
  },
  {
    postal_code: "38468",
    full_city_name: "Leoma, TN",
    city_name: "Leoma",
  },
  {
    postal_code: "97721",
    full_city_name: "Princeton, OR",
    city_name: "Princeton",
  },
  {
    postal_code: "07022",
    full_city_name: "Fairview, NJ",
    city_name: "Fairview",
  },
  {
    postal_code: "48041",
    full_city_name: "Memphis, MI",
    city_name: "Memphis",
  },
  {
    postal_code: "59440",
    full_city_name: "Floweree, MT",
    city_name: "Floweree",
  },
  {
    postal_code: "45868",
    full_city_name: "Mount Cory, OH",
    city_name: "Mount Cory",
  },
  {
    postal_code: "45001",
    full_city_name: "Addyston, OH",
    city_name: "Addyston",
  },
  {
    postal_code: "02812",
    full_city_name: "Carolina, RI",
    city_name: "Carolina",
  },
  {
    postal_code: "59875",
    full_city_name: "Victor, MT",
    city_name: "Victor",
  },
  {
    postal_code: "69030",
    full_city_name: "Haigler, NE",
    city_name: "Haigler",
  },
  {
    postal_code: "98359",
    full_city_name: "Olalla, WA",
    city_name: "Olalla",
  },
  {
    postal_code: "54138",
    full_city_name: "Lakewood, WI",
    city_name: "Lakewood",
  },
  {
    postal_code: "99110",
    full_city_name: "Clayton, WA",
    city_name: "Clayton",
  },
  {
    postal_code: "76305",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "65076",
    full_city_name: "Saint Thomas, MO",
    city_name: "Saint Thomas",
  },
  {
    postal_code: "22719",
    full_city_name: "Etlan, VA",
    city_name: "Etlan",
  },
  {
    postal_code: "65334",
    full_city_name: "Hughesville, MO",
    city_name: "Hughesville",
  },
  {
    postal_code: "24603",
    full_city_name: "Big Rock, VA",
    city_name: "Big Rock",
  },
  {
    postal_code: "15625",
    full_city_name: "Darragh, PA",
    city_name: "Darragh",
  },
  {
    postal_code: "51557",
    full_city_name: "Mondamin, IA",
    city_name: "Mondamin",
  },
  {
    postal_code: "73716",
    full_city_name: "Aline, OK",
    city_name: "Aline",
  },
  {
    postal_code: "64018",
    full_city_name: "Camden Point, MO",
    city_name: "Camden Point",
  },
  {
    postal_code: "79345",
    full_city_name: "Meadow, TX",
    city_name: "Meadow",
  },
  {
    postal_code: "79548",
    full_city_name: "Rule, TX",
    city_name: "Rule",
  },
  {
    postal_code: "23415",
    full_city_name: "New Church, VA",
    city_name: "New Church",
  },
  {
    postal_code: "94922",
    full_city_name: "Bodega, CA",
    city_name: "Bodega",
  },
  {
    postal_code: "38923",
    full_city_name: "Coila, MS",
    city_name: "Coila",
  },
  {
    postal_code: "49080",
    full_city_name: "Plainwell, MI",
    city_name: "Plainwell",
  },
  {
    postal_code: "04475",
    full_city_name: "Passadumkeag, ME",
    city_name: "Passadumkeag",
  },
  {
    postal_code: "71070",
    full_city_name: "Saline, LA",
    city_name: "Saline",
  },
  {
    postal_code: "70760",
    full_city_name: "New Roads, LA",
    city_name: "New Roads",
  },
  {
    postal_code: "50514",
    full_city_name: "Armstrong, IA",
    city_name: "Armstrong",
  },
  {
    postal_code: "98237",
    full_city_name: "Concrete, WA",
    city_name: "Concrete",
  },
  {
    postal_code: "51230",
    full_city_name: "Alvord, IA",
    city_name: "Alvord",
  },
  {
    postal_code: "58365",
    full_city_name: "Rocklake, ND",
    city_name: "Rocklake",
  },
  {
    postal_code: "62572",
    full_city_name: "Waggoner, IL",
    city_name: "Waggoner",
  },
  {
    postal_code: "71047",
    full_city_name: "Keithville, LA",
    city_name: "Keithville",
  },
  {
    postal_code: "79330",
    full_city_name: "Justiceburg, TX",
    city_name: "Justiceburg",
  },
  {
    postal_code: "48210",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "68756",
    full_city_name: "Neligh, NE",
    city_name: "Neligh",
  },
  {
    postal_code: "31826",
    full_city_name: "Shiloh, GA",
    city_name: "Shiloh",
  },
  {
    postal_code: "81522",
    full_city_name: "Gateway, CO",
    city_name: "Gateway",
  },
  {
    postal_code: "04923",
    full_city_name: "Cambridge, ME",
    city_name: "Cambridge",
  },
  {
    postal_code: "76932",
    full_city_name: "Big Lake, TX",
    city_name: "Big Lake",
  },
  {
    postal_code: "67516",
    full_city_name: "Bazine, KS",
    city_name: "Bazine",
  },
  {
    postal_code: "44611",
    full_city_name: "Big Prairie, OH",
    city_name: "Big Prairie",
  },
  {
    postal_code: "62082",
    full_city_name: "Roodhouse, IL",
    city_name: "Roodhouse",
  },
  {
    postal_code: "10913",
    full_city_name: "Blauvelt, NY",
    city_name: "Blauvelt",
  },
  {
    postal_code: "97620",
    full_city_name: "Adel, OR",
    city_name: "Adel",
  },
  {
    postal_code: "24986",
    full_city_name: "White Sulphur Springs, WV",
    city_name: "White Sulphur Springs",
  },
  {
    postal_code: "58052",
    full_city_name: "Leonard, ND",
    city_name: "Leonard",
  },
  {
    postal_code: "74027",
    full_city_name: "Delaware, OK",
    city_name: "Delaware",
  },
  {
    postal_code: "48226",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "66429",
    full_city_name: "Grantville, KS",
    city_name: "Grantville",
  },
  {
    postal_code: "68855",
    full_city_name: "Mason City, NE",
    city_name: "Mason City",
  },
  {
    postal_code: "12922",
    full_city_name: "Childwold, NY",
    city_name: "Childwold",
  },
  {
    postal_code: "73569",
    full_city_name: "Terral, OK",
    city_name: "Terral",
  },
  {
    postal_code: "05730",
    full_city_name: "Belmont, VT",
    city_name: "Belmont",
  },
  {
    postal_code: "04859",
    full_city_name: "Spruce Head, ME",
    city_name: "Spruce Head",
  },
  {
    postal_code: "61465",
    full_city_name: "New Windsor, IL",
    city_name: "New Windsor",
  },
  {
    postal_code: "87315",
    full_city_name: "Fence Lake, NM",
    city_name: "Fence Lake",
  },
  {
    postal_code: "67732",
    full_city_name: "Brewster, KS",
    city_name: "Brewster",
  },
  {
    postal_code: "27853",
    full_city_name: "Margarettsville, NC",
    city_name: "Margarettsville",
  },
  {
    postal_code: "81411",
    full_city_name: "Bedrock, CO",
    city_name: "Bedrock",
  },
  {
    postal_code: "49325",
    full_city_name: "Freeport, MI",
    city_name: "Freeport",
  },
  {
    postal_code: "78632",
    full_city_name: "Harwood, TX",
    city_name: "Harwood",
  },
  {
    postal_code: "57248",
    full_city_name: "Lake Norden, SD",
    city_name: "Lake Norden",
  },
  {
    postal_code: "53217",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "65053",
    full_city_name: "Lohman, MO",
    city_name: "Lohman",
  },
  {
    postal_code: "99922",
    full_city_name: "Hydaburg, AK",
    city_name: "Hydaburg",
  },
  {
    postal_code: "31709",
    full_city_name: "Americus, GA",
    city_name: "Americus",
  },
  {
    postal_code: "75411",
    full_city_name: "Arthur City, TX",
    city_name: "Arthur City",
  },
  {
    postal_code: "54470",
    full_city_name: "Rib Lake, WI",
    city_name: "Rib Lake",
  },
  {
    postal_code: "74901",
    full_city_name: "Arkoma, OK",
    city_name: "Arkoma",
  },
  {
    postal_code: "62349",
    full_city_name: "Loraine, IL",
    city_name: "Loraine",
  },
  {
    postal_code: "68326",
    full_city_name: "Carleton, NE",
    city_name: "Carleton",
  },
  {
    postal_code: "83285",
    full_city_name: "Wayan, ID",
    city_name: "Wayan",
  },
  {
    postal_code: "64166",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "45333",
    full_city_name: "Houston, OH",
    city_name: "Houston",
  },
  {
    postal_code: "50619",
    full_city_name: "Clarksville, IA",
    city_name: "Clarksville",
  },
  {
    postal_code: "35764",
    full_city_name: "Paint Rock, AL",
    city_name: "Paint Rock",
  },
  {
    postal_code: "58523",
    full_city_name: "Beulah, ND",
    city_name: "Beulah",
  },
  {
    postal_code: "42081",
    full_city_name: "Smithland, KY",
    city_name: "Smithland",
  },
  {
    postal_code: "23888",
    full_city_name: "Wakefield, VA",
    city_name: "Wakefield",
  },
  {
    postal_code: "14166",
    full_city_name: "Van Buren Point, NY",
    city_name: "Van Buren Point",
  },
  {
    postal_code: "97621",
    full_city_name: "Beatty, OR",
    city_name: "Beatty",
  },
  {
    postal_code: "94972",
    full_city_name: "Valley Ford, CA",
    city_name: "Valley Ford",
  },
  {
    postal_code: "40170",
    full_city_name: "Stephensport, KY",
    city_name: "Stephensport",
  },
  {
    postal_code: "93606",
    full_city_name: "Biola, CA",
    city_name: "Biola",
  },
  {
    postal_code: "26717",
    full_city_name: "Elk Garden, WV",
    city_name: "Elk Garden",
  },
  {
    postal_code: "74054",
    full_city_name: "Osage, OK",
    city_name: "Osage",
  },
  {
    postal_code: "31075",
    full_city_name: "Rentz, GA",
    city_name: "Rentz",
  },
  {
    postal_code: "27926",
    full_city_name: "Corapeake, NC",
    city_name: "Corapeake",
  },
  {
    postal_code: "30410",
    full_city_name: "Ailey, GA",
    city_name: "Ailey",
  },
  {
    postal_code: "44455",
    full_city_name: "Rogers, OH",
    city_name: "Rogers",
  },
  {
    postal_code: "54638",
    full_city_name: "Kendall, WI",
    city_name: "Kendall",
  },
  {
    postal_code: "59074",
    full_city_name: "Ryegate, MT",
    city_name: "Ryegate",
  },
  {
    postal_code: "95564",
    full_city_name: "Samoa, CA",
    city_name: "Samoa",
  },
  {
    postal_code: "15761",
    full_city_name: "Mentcle, PA",
    city_name: "Mentcle",
  },
  {
    postal_code: "56207",
    full_city_name: "Alberta, MN",
    city_name: "Alberta",
  },
  {
    postal_code: "62895",
    full_city_name: "Wayne City, IL",
    city_name: "Wayne City",
  },
  {
    postal_code: "12738",
    full_city_name: "Glen Wild, NY",
    city_name: "Glen Wild",
  },
  {
    postal_code: "96059",
    full_city_name: "Manton, CA",
    city_name: "Manton",
  },
  {
    postal_code: "04851",
    full_city_name: "Matinicus, ME",
    city_name: "Matinicus",
  },
  {
    postal_code: "37151",
    full_city_name: "Riddleton, TN",
    city_name: "Riddleton",
  },
  {
    postal_code: "48891",
    full_city_name: "Vestaburg, MI",
    city_name: "Vestaburg",
  },
  {
    postal_code: "67566",
    full_city_name: "Partridge, KS",
    city_name: "Partridge",
  },
  {
    postal_code: "07505",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "41840",
    full_city_name: "Neon, KY",
    city_name: "Neon",
  },
  {
    postal_code: "73622",
    full_city_name: "Bessie, OK",
    city_name: "Bessie",
  },
  {
    postal_code: "65702",
    full_city_name: "Macomb, MO",
    city_name: "Macomb",
  },
  {
    postal_code: "48806",
    full_city_name: "Ashley, MI",
    city_name: "Ashley",
  },
  {
    postal_code: "66949",
    full_city_name: "Jewell, KS",
    city_name: "Jewell",
  },
  {
    postal_code: "26755",
    full_city_name: "Rio, WV",
    city_name: "Rio",
  },
  {
    postal_code: "37342",
    full_city_name: "Hillsboro, TN",
    city_name: "Hillsboro",
  },
  {
    postal_code: "72577",
    full_city_name: "Sidney, AR",
    city_name: "Sidney",
  },
  {
    postal_code: "72074",
    full_city_name: "Hunter, AR",
    city_name: "Hunter",
  },
  {
    postal_code: "63656",
    full_city_name: "Middle Brook, MO",
    city_name: "Middle Brook",
  },
  {
    postal_code: "48889",
    full_city_name: "Sumner, MI",
    city_name: "Sumner",
  },
  {
    postal_code: "68016",
    full_city_name: "Cedar Creek, NE",
    city_name: "Cedar Creek",
  },
  {
    postal_code: "41647",
    full_city_name: "Mc Dowell, KY",
    city_name: "Mc Dowell",
  },
  {
    postal_code: "87523",
    full_city_name: "Cordova, NM",
    city_name: "Cordova",
  },
  {
    postal_code: "63469",
    full_city_name: "Shelbyville, MO",
    city_name: "Shelbyville",
  },
  {
    postal_code: "62423",
    full_city_name: "Dennison, IL",
    city_name: "Dennison",
  },
  {
    postal_code: "24898",
    full_city_name: "Wyoming, WV",
    city_name: "Wyoming",
  },
  {
    postal_code: "45690",
    full_city_name: "Waverly, OH",
    city_name: "Waverly",
  },
  {
    postal_code: "47453",
    full_city_name: "Owensburg, IN",
    city_name: "Owensburg",
  },
  {
    postal_code: "15430",
    full_city_name: "Dickerson Run, PA",
    city_name: "Dickerson Run",
  },
  {
    postal_code: "53075",
    full_city_name: "Random Lake, WI",
    city_name: "Random Lake",
  },
  {
    postal_code: "66427",
    full_city_name: "Frankfort, KS",
    city_name: "Frankfort",
  },
  {
    postal_code: "58718",
    full_city_name: "Berthold, ND",
    city_name: "Berthold",
  },
  {
    postal_code: "98641",
    full_city_name: "Oysterville, WA",
    city_name: "Oysterville",
  },
  {
    postal_code: "18845",
    full_city_name: "Stevensville, PA",
    city_name: "Stevensville",
  },
  {
    postal_code: "40903",
    full_city_name: "Artemus, KY",
    city_name: "Artemus",
  },
  {
    postal_code: "38224",
    full_city_name: "Cottage Grove, TN",
    city_name: "Cottage Grove",
  },
  {
    postal_code: "13651",
    full_city_name: "Henderson Harbor, NY",
    city_name: "Henderson Harbor",
  },
  {
    postal_code: "26142",
    full_city_name: "Davisville, WV",
    city_name: "Davisville",
  },
  {
    postal_code: "15715",
    full_city_name: "Big Run, PA",
    city_name: "Big Run",
  },
  {
    postal_code: "36783",
    full_city_name: "Thomaston, AL",
    city_name: "Thomaston",
  },
  {
    postal_code: "67347",
    full_city_name: "Havana, KS",
    city_name: "Havana",
  },
  {
    postal_code: "04746",
    full_city_name: "Grand Isle, ME",
    city_name: "Grand Isle",
  },
  {
    postal_code: "45348",
    full_city_name: "New Weston, OH",
    city_name: "New Weston",
  },
  {
    postal_code: "35670",
    full_city_name: "Somerville, AL",
    city_name: "Somerville",
  },
  {
    postal_code: "35801",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "59441",
    full_city_name: "Forest Grove, MT",
    city_name: "Forest Grove",
  },
  {
    postal_code: "62478",
    full_city_name: "West York, IL",
    city_name: "West York",
  },
  {
    postal_code: "37034",
    full_city_name: "Chapel Hill, TN",
    city_name: "Chapel Hill",
  },
  {
    postal_code: "02873",
    full_city_name: "Rockville, RI",
    city_name: "Rockville",
  },
  {
    postal_code: "57014",
    full_city_name: "Centerville, SD",
    city_name: "Centerville",
  },
  {
    postal_code: "18427",
    full_city_name: "Hamlin, PA",
    city_name: "Hamlin",
  },
  {
    postal_code: "38748",
    full_city_name: "Hollandale, MS",
    city_name: "Hollandale",
  },
  {
    postal_code: "85620",
    full_city_name: "Naco, AZ",
    city_name: "Naco",
  },
  {
    postal_code: "14822",
    full_city_name: "Canaseraga, NY",
    city_name: "Canaseraga",
  },
  {
    postal_code: "35087",
    full_city_name: "Joppa, AL",
    city_name: "Joppa",
  },
  {
    postal_code: "07403",
    full_city_name: "Bloomingdale, NJ",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "72354",
    full_city_name: "Lepanto, AR",
    city_name: "Lepanto",
  },
  {
    postal_code: "24946",
    full_city_name: "Hillsboro, WV",
    city_name: "Hillsboro",
  },
  {
    postal_code: "72512",
    full_city_name: "Horseshoe Bend, AR",
    city_name: "Horseshoe Bend",
  },
  {
    postal_code: "39346",
    full_city_name: "Noxapater, MS",
    city_name: "Noxapater",
  },
  {
    postal_code: "64631",
    full_city_name: "Bucklin, MO",
    city_name: "Bucklin",
  },
  {
    postal_code: "38629",
    full_city_name: "Falkner, MS",
    city_name: "Falkner",
  },
  {
    postal_code: "61479",
    full_city_name: "Speer, IL",
    city_name: "Speer",
  },
  {
    postal_code: "18087",
    full_city_name: "Trexlertown, PA",
    city_name: "Trexlertown",
  },
  {
    postal_code: "49769",
    full_city_name: "Pellston, MI",
    city_name: "Pellston",
  },
  {
    postal_code: "88262",
    full_city_name: "Mcdonald, NM",
    city_name: "Mcdonald",
  },
  {
    postal_code: "12780",
    full_city_name: "Sparrow Bush, NY",
    city_name: "Sparrow Bush",
  },
  {
    postal_code: "35221",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "76630",
    full_city_name: "Bruceville, TX",
    city_name: "Bruceville",
  },
  {
    postal_code: "63551",
    full_city_name: "Livonia, MO",
    city_name: "Livonia",
  },
  {
    postal_code: "84085",
    full_city_name: "Whiterocks, UT",
    city_name: "Whiterocks",
  },
  {
    postal_code: "41224",
    full_city_name: "Inez, KY",
    city_name: "Inez",
  },
  {
    postal_code: "32503",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "98409",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "62044",
    full_city_name: "Greenfield, IL",
    city_name: "Greenfield",
  },
  {
    postal_code: "26282",
    full_city_name: "Monterville, WV",
    city_name: "Monterville",
  },
  {
    postal_code: "65251",
    full_city_name: "Fulton, MO",
    city_name: "Fulton",
  },
  {
    postal_code: "62872",
    full_city_name: "Opdyke, IL",
    city_name: "Opdyke",
  },
  {
    postal_code: "37059",
    full_city_name: "Dowelltown, TN",
    city_name: "Dowelltown",
  },
  {
    postal_code: "47861",
    full_city_name: "Merom, IN",
    city_name: "Merom",
  },
  {
    postal_code: "27589",
    full_city_name: "Warrenton, NC",
    city_name: "Warrenton",
  },
  {
    postal_code: "99921",
    full_city_name: "Craig, AK",
    city_name: "Craig",
  },
  {
    postal_code: "44843",
    full_city_name: "Lucas, OH",
    city_name: "Lucas",
  },
  {
    postal_code: "83852",
    full_city_name: "Ponderay, ID",
    city_name: "Ponderay",
  },
  {
    postal_code: "23827",
    full_city_name: "Boykins, VA",
    city_name: "Boykins",
  },
  {
    postal_code: "76626",
    full_city_name: "Blooming Grove, TX",
    city_name: "Blooming Grove",
  },
  {
    postal_code: "54846",
    full_city_name: "High Bridge, WI",
    city_name: "High Bridge",
  },
  {
    postal_code: "50142",
    full_city_name: "Le Grand, IA",
    city_name: "Le Grand",
  },
  {
    postal_code: "59914",
    full_city_name: "Dayton, MT",
    city_name: "Dayton",
  },
  {
    postal_code: "59913",
    full_city_name: "Coram, MT",
    city_name: "Coram",
  },
  {
    postal_code: "58458",
    full_city_name: "Lamoure, ND",
    city_name: "Lamoure",
  },
  {
    postal_code: "10901",
    full_city_name: "Suffern, NY",
    city_name: "Suffern",
  },
  {
    postal_code: "50278",
    full_city_name: "Zearing, IA",
    city_name: "Zearing",
  },
  {
    postal_code: "36786",
    full_city_name: "Uniontown, AL",
    city_name: "Uniontown",
  },
  {
    postal_code: "66936",
    full_city_name: "Burr Oak, KS",
    city_name: "Burr Oak",
  },
  {
    postal_code: "35750",
    full_city_name: "Hazel Green, AL",
    city_name: "Hazel Green",
  },
  {
    postal_code: "74002",
    full_city_name: "Barnsdall, OK",
    city_name: "Barnsdall",
  },
  {
    postal_code: "75555",
    full_city_name: "Bivins, TX",
    city_name: "Bivins",
  },
  {
    postal_code: "14041",
    full_city_name: "Dayton, NY",
    city_name: "Dayton",
  },
  {
    postal_code: "16825",
    full_city_name: "Bigler, PA",
    city_name: "Bigler",
  },
  {
    postal_code: "25932",
    full_city_name: "Surveyor, WV",
    city_name: "Surveyor",
  },
  {
    postal_code: "77991",
    full_city_name: "Vanderbilt, TX",
    city_name: "Vanderbilt",
  },
  {
    postal_code: "02725",
    full_city_name: "Somerset, MA",
    city_name: "Somerset",
  },
  {
    postal_code: "74364",
    full_city_name: "Rose, OK",
    city_name: "Rose",
  },
  {
    postal_code: "25913",
    full_city_name: "Ravencliff, WV",
    city_name: "Ravencliff",
  },
  {
    postal_code: "62011",
    full_city_name: "Bingham, IL",
    city_name: "Bingham",
  },
  {
    postal_code: "17563",
    full_city_name: "Peach Bottom, PA",
    city_name: "Peach Bottom",
  },
  {
    postal_code: "71838",
    full_city_name: "Fulton, AR",
    city_name: "Fulton",
  },
  {
    postal_code: "56727",
    full_city_name: "Grygla, MN",
    city_name: "Grygla",
  },
  {
    postal_code: "52205",
    full_city_name: "Anamosa, IA",
    city_name: "Anamosa",
  },
  {
    postal_code: "82834",
    full_city_name: "Buffalo, WY",
    city_name: "Buffalo",
  },
  {
    postal_code: "29686",
    full_city_name: "Tamassee, SC",
    city_name: "Tamassee",
  },
  {
    postal_code: "64660",
    full_city_name: "Mendon, MO",
    city_name: "Mendon",
  },
  {
    postal_code: "77470",
    full_city_name: "Rock Island, TX",
    city_name: "Rock Island",
  },
  {
    postal_code: "26448",
    full_city_name: "Wallace, WV",
    city_name: "Wallace",
  },
  {
    postal_code: "41264",
    full_city_name: "Ulysses, KY",
    city_name: "Ulysses",
  },
  {
    postal_code: "50481",
    full_city_name: "Toeterville, IA",
    city_name: "Toeterville",
  },
  {
    postal_code: "14550",
    full_city_name: "Silver Springs, NY",
    city_name: "Silver Springs",
  },
  {
    postal_code: "17006",
    full_city_name: "Blain, PA",
    city_name: "Blain",
  },
  {
    postal_code: "58625",
    full_city_name: "Dodge, ND",
    city_name: "Dodge",
  },
  {
    postal_code: "62888",
    full_city_name: "Tamaroa, IL",
    city_name: "Tamaroa",
  },
  {
    postal_code: "25119",
    full_city_name: "Kincaid, WV",
    city_name: "Kincaid",
  },
  {
    postal_code: "63839",
    full_city_name: "Cooter, MO",
    city_name: "Cooter",
  },
  {
    postal_code: "38581",
    full_city_name: "Rock Island, TN",
    city_name: "Rock Island",
  },
  {
    postal_code: "65650",
    full_city_name: "Flemington, MO",
    city_name: "Flemington",
  },
  {
    postal_code: "80463",
    full_city_name: "Mc Coy, CO",
    city_name: "Mc Coy",
  },
  {
    postal_code: "12759",
    full_city_name: "Loch Sheldrake, NY",
    city_name: "Loch Sheldrake",
  },
  {
    postal_code: "31057",
    full_city_name: "Marshallville, GA",
    city_name: "Marshallville",
  },
  {
    postal_code: "93954",
    full_city_name: "San Lucas, CA",
    city_name: "San Lucas",
  },
  {
    postal_code: "99130",
    full_city_name: "Garfield, WA",
    city_name: "Garfield",
  },
  {
    postal_code: "97456",
    full_city_name: "Monroe, OR",
    city_name: "Monroe",
  },
  {
    postal_code: "51366",
    full_city_name: "Webb, IA",
    city_name: "Webb",
  },
  {
    postal_code: "14466",
    full_city_name: "Hemlock, NY",
    city_name: "Hemlock",
  },
  {
    postal_code: "72443",
    full_city_name: "Marmaduke, AR",
    city_name: "Marmaduke",
  },
  {
    postal_code: "48064",
    full_city_name: "Casco, MI",
    city_name: "Casco",
  },
  {
    postal_code: "22725",
    full_city_name: "Leon, VA",
    city_name: "Leon",
  },
  {
    postal_code: "64874",
    full_city_name: "Wheaton, MO",
    city_name: "Wheaton",
  },
  {
    postal_code: "07504",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "73017",
    full_city_name: "Cement, OK",
    city_name: "Cement",
  },
  {
    postal_code: "40771",
    full_city_name: "Woodbine, KY",
    city_name: "Woodbine",
  },
  {
    postal_code: "63350",
    full_city_name: "High Hill, MO",
    city_name: "High Hill",
  },
  {
    postal_code: "74850",
    full_city_name: "Lamar, OK",
    city_name: "Lamar",
  },
  {
    postal_code: "45032",
    full_city_name: "Harveysburg, OH",
    city_name: "Harveysburg",
  },
  {
    postal_code: "57321",
    full_city_name: "Canova, SD",
    city_name: "Canova",
  },
  {
    postal_code: "73151",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "37220",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "63352",
    full_city_name: "Laddonia, MO",
    city_name: "Laddonia",
  },
  {
    postal_code: "39744",
    full_city_name: "Eupora, MS",
    city_name: "Eupora",
  },
  {
    postal_code: "47882",
    full_city_name: "Sullivan, IN",
    city_name: "Sullivan",
  },
  {
    postal_code: "79018",
    full_city_name: "Channing, TX",
    city_name: "Channing",
  },
  {
    postal_code: "12543",
    full_city_name: "Maybrook, NY",
    city_name: "Maybrook",
  },
  {
    postal_code: "76682",
    full_city_name: "Riesel, TX",
    city_name: "Riesel",
  },
  {
    postal_code: "07606",
    full_city_name: "South Hackensack, NJ",
    city_name: "South Hackensack",
  },
  {
    postal_code: "93668",
    full_city_name: "Tranquillity, CA",
    city_name: "Tranquillity",
  },
  {
    postal_code: "10538",
    full_city_name: "Larchmont, NY",
    city_name: "Larchmont",
  },
  {
    postal_code: "62093",
    full_city_name: "Wilsonville, IL",
    city_name: "Wilsonville",
  },
  {
    postal_code: "64658",
    full_city_name: "Marceline, MO",
    city_name: "Marceline",
  },
  {
    postal_code: "71469",
    full_city_name: "Robeline, LA",
    city_name: "Robeline",
  },
  {
    postal_code: "58048",
    full_city_name: "Hunter, ND",
    city_name: "Hunter",
  },
  {
    postal_code: "68937",
    full_city_name: "Elwood, NE",
    city_name: "Elwood",
  },
  {
    postal_code: "26374",
    full_city_name: "Independence, WV",
    city_name: "Independence",
  },
  {
    postal_code: "28399",
    full_city_name: "White Oak, NC",
    city_name: "White Oak",
  },
  {
    postal_code: "72617",
    full_city_name: "Big Flat, AR",
    city_name: "Big Flat",
  },
  {
    postal_code: "17729",
    full_city_name: "Cross Fork, PA",
    city_name: "Cross Fork",
  },
  {
    postal_code: "55753",
    full_city_name: "Keewatin, MN",
    city_name: "Keewatin",
  },
  {
    postal_code: "78012",
    full_city_name: "Christine, TX",
    city_name: "Christine",
  },
  {
    postal_code: "17547",
    full_city_name: "Marietta, PA",
    city_name: "Marietta",
  },
  {
    postal_code: "07657",
    full_city_name: "Ridgefield, NJ",
    city_name: "Ridgefield",
  },
  {
    postal_code: "39772",
    full_city_name: "Weir, MS",
    city_name: "Weir",
  },
  {
    postal_code: "61315",
    full_city_name: "Bureau, IL",
    city_name: "Bureau",
  },
  {
    postal_code: "63822",
    full_city_name: "Bernie, MO",
    city_name: "Bernie",
  },
  {
    postal_code: "97029",
    full_city_name: "Grass Valley, OR",
    city_name: "Grass Valley",
  },
  {
    postal_code: "61553",
    full_city_name: "Norris, IL",
    city_name: "Norris",
  },
  {
    postal_code: "62969",
    full_city_name: "Olive Branch, IL",
    city_name: "Olive Branch",
  },
  {
    postal_code: "52533",
    full_city_name: "Batavia, IA",
    city_name: "Batavia",
  },
  {
    postal_code: "32526",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "38963",
    full_city_name: "Tutwiler, MS",
    city_name: "Tutwiler",
  },
  {
    postal_code: "65259",
    full_city_name: "Huntsville, MO",
    city_name: "Huntsville",
  },
  {
    postal_code: "68070",
    full_city_name: "Weston, NE",
    city_name: "Weston",
  },
  {
    postal_code: "46923",
    full_city_name: "Delphi, IN",
    city_name: "Delphi",
  },
  {
    postal_code: "58235",
    full_city_name: "Gilby, ND",
    city_name: "Gilby",
  },
  {
    postal_code: "36528",
    full_city_name: "Dauphin Island, AL",
    city_name: "Dauphin Island",
  },
  {
    postal_code: "53119",
    full_city_name: "Eagle, WI",
    city_name: "Eagle",
  },
  {
    postal_code: "61436",
    full_city_name: "Gilson, IL",
    city_name: "Gilson",
  },
  {
    postal_code: "56174",
    full_city_name: "Storden, MN",
    city_name: "Storden",
  },
  {
    postal_code: "74572",
    full_city_name: "Tupelo, OK",
    city_name: "Tupelo",
  },
  {
    postal_code: "58278",
    full_city_name: "Thompson, ND",
    city_name: "Thompson",
  },
  {
    postal_code: "99672",
    full_city_name: "Sterling, AK",
    city_name: "Sterling",
  },
  {
    postal_code: "63750",
    full_city_name: "Gipsy, MO",
    city_name: "Gipsy",
  },
  {
    postal_code: "35745",
    full_city_name: "Estillfork, AL",
    city_name: "Estillfork",
  },
  {
    postal_code: "57016",
    full_city_name: "Chester, SD",
    city_name: "Chester",
  },
  {
    postal_code: "35747",
    full_city_name: "Grant, AL",
    city_name: "Grant",
  },
  {
    postal_code: "10988",
    full_city_name: "Unionville, NY",
    city_name: "Unionville",
  },
  {
    postal_code: "50842",
    full_city_name: "Cromwell, IA",
    city_name: "Cromwell",
  },
  {
    postal_code: "75556",
    full_city_name: "Bloomburg, TX",
    city_name: "Bloomburg",
  },
  {
    postal_code: "16370",
    full_city_name: "West Hickory, PA",
    city_name: "West Hickory",
  },
  {
    postal_code: "30711",
    full_city_name: "Crandall, GA",
    city_name: "Crandall",
  },
  {
    postal_code: "57264",
    full_city_name: "Stockholm, SD",
    city_name: "Stockholm",
  },
  {
    postal_code: "62912",
    full_city_name: "Buncombe, IL",
    city_name: "Buncombe",
  },
  {
    postal_code: "59066",
    full_city_name: "Pryor, MT",
    city_name: "Pryor",
  },
  {
    postal_code: "35580",
    full_city_name: "Parrish, AL",
    city_name: "Parrish",
  },
  {
    postal_code: "49346",
    full_city_name: "Stanwood, MI",
    city_name: "Stanwood",
  },
  {
    postal_code: "56629",
    full_city_name: "Birchdale, MN",
    city_name: "Birchdale",
  },
  {
    postal_code: "38821",
    full_city_name: "Amory, MS",
    city_name: "Amory",
  },
  {
    postal_code: "46562",
    full_city_name: "Pierceton, IN",
    city_name: "Pierceton",
  },
  {
    postal_code: "23321",
    full_city_name: "Chesapeake, VA",
    city_name: "Chesapeake",
  },
  {
    postal_code: "54230",
    full_city_name: "Reedsville, WI",
    city_name: "Reedsville",
  },
  {
    postal_code: "74538",
    full_city_name: "Coalgate, OK",
    city_name: "Coalgate",
  },
  {
    postal_code: "29138",
    full_city_name: "Saluda, SC",
    city_name: "Saluda",
  },
  {
    postal_code: "16727",
    full_city_name: "Derrick City, PA",
    city_name: "Derrick City",
  },
  {
    postal_code: "52650",
    full_city_name: "Sperry, IA",
    city_name: "Sperry",
  },
  {
    postal_code: "50151",
    full_city_name: "Lucas, IA",
    city_name: "Lucas",
  },
  {
    postal_code: "43782",
    full_city_name: "Shawnee, OH",
    city_name: "Shawnee",
  },
  {
    postal_code: "31501",
    full_city_name: "Waycross, GA",
    city_name: "Waycross",
  },
  {
    postal_code: "53154",
    full_city_name: "Oak Creek, WI",
    city_name: "Oak Creek",
  },
  {
    postal_code: "72360",
    full_city_name: "Marianna, AR",
    city_name: "Marianna",
  },
  {
    postal_code: "41645",
    full_city_name: "Langley, KY",
    city_name: "Langley",
  },
  {
    postal_code: "92266",
    full_city_name: "Palo Verde, CA",
    city_name: "Palo Verde",
  },
  {
    postal_code: "59349",
    full_city_name: "Terry, MT",
    city_name: "Terry",
  },
  {
    postal_code: "67344",
    full_city_name: "Elk City, KS",
    city_name: "Elk City",
  },
  {
    postal_code: "61937",
    full_city_name: "Lovington, IL",
    city_name: "Lovington",
  },
  {
    postal_code: "76704",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "14544",
    full_city_name: "Rushville, NY",
    city_name: "Rushville",
  },
  {
    postal_code: "41543",
    full_city_name: "Mc Andrews, KY",
    city_name: "Mc Andrews",
  },
  {
    postal_code: "47108",
    full_city_name: "Campbellsburg, IN",
    city_name: "Campbellsburg",
  },
  {
    postal_code: "26257",
    full_city_name: "Coalton, WV",
    city_name: "Coalton",
  },
  {
    postal_code: "61478",
    full_city_name: "Smithshire, IL",
    city_name: "Smithshire",
  },
  {
    postal_code: "53024",
    full_city_name: "Grafton, WI",
    city_name: "Grafton",
  },
  {
    postal_code: "65666",
    full_city_name: "Hardenville, MO",
    city_name: "Hardenville",
  },
  {
    postal_code: "59255",
    full_city_name: "Poplar, MT",
    city_name: "Poplar",
  },
  {
    postal_code: "28624",
    full_city_name: "Ferguson, NC",
    city_name: "Ferguson",
  },
  {
    postal_code: "66933",
    full_city_name: "Barnes, KS",
    city_name: "Barnes",
  },
  {
    postal_code: "06414",
    full_city_name: "Cobalt, CT",
    city_name: "Cobalt",
  },
  {
    postal_code: "99113",
    full_city_name: "Colton, WA",
    city_name: "Colton",
  },
  {
    postal_code: "95717",
    full_city_name: "Gold Run, CA",
    city_name: "Gold Run",
  },
  {
    postal_code: "77419",
    full_city_name: "Blessing, TX",
    city_name: "Blessing",
  },
  {
    postal_code: "56214",
    full_city_name: "Belview, MN",
    city_name: "Belview",
  },
  {
    postal_code: "71675",
    full_city_name: "Wilmar, AR",
    city_name: "Wilmar",
  },
  {
    postal_code: "23837",
    full_city_name: "Courtland, VA",
    city_name: "Courtland",
  },
  {
    postal_code: "26438",
    full_city_name: "Spelter, WV",
    city_name: "Spelter",
  },
  {
    postal_code: "71269",
    full_city_name: "Rayville, LA",
    city_name: "Rayville",
  },
  {
    postal_code: "62425",
    full_city_name: "Dundas, IL",
    city_name: "Dundas",
  },
  {
    postal_code: "71327",
    full_city_name: "Cottonport, LA",
    city_name: "Cottonport",
  },
  {
    postal_code: "47016",
    full_city_name: "Cedar Grove, IN",
    city_name: "Cedar Grove",
  },
  {
    postal_code: "35901",
    full_city_name: "Gadsden, AL",
    city_name: "Gadsden",
  },
  {
    postal_code: "58538",
    full_city_name: "Fort Yates, ND",
    city_name: "Fort Yates",
  },
  {
    postal_code: "17582",
    full_city_name: "Washington Boro, PA",
    city_name: "Washington Boro",
  },
  {
    postal_code: "36346",
    full_city_name: "Jack, AL",
    city_name: "Jack",
  },
  {
    postal_code: "14462",
    full_city_name: "Groveland, NY",
    city_name: "Groveland",
  },
  {
    postal_code: "45836",
    full_city_name: "Dunkirk, OH",
    city_name: "Dunkirk",
  },
  {
    postal_code: "80755",
    full_city_name: "Vernon, CO",
    city_name: "Vernon",
  },
  {
    postal_code: "50675",
    full_city_name: "Traer, IA",
    city_name: "Traer",
  },
  {
    postal_code: "56733",
    full_city_name: "Kennedy, MN",
    city_name: "Kennedy",
  },
  {
    postal_code: "18829",
    full_city_name: "Le Raysville, PA",
    city_name: "Le Raysville",
  },
  {
    postal_code: "69150",
    full_city_name: "Madrid, NE",
    city_name: "Madrid",
  },
  {
    postal_code: "74851",
    full_city_name: "Mcloud, OK",
    city_name: "Mcloud",
  },
  {
    postal_code: "54128",
    full_city_name: "Gresham, WI",
    city_name: "Gresham",
  },
  {
    postal_code: "36322",
    full_city_name: "Daleville, AL",
    city_name: "Daleville",
  },
  {
    postal_code: "97634",
    full_city_name: "Midland, OR",
    city_name: "Midland",
  },
  {
    postal_code: "65279",
    full_city_name: "Rocheport, MO",
    city_name: "Rocheport",
  },
  {
    postal_code: "72136",
    full_city_name: "Romance, AR",
    city_name: "Romance",
  },
  {
    postal_code: "12976",
    full_city_name: "Rainbow Lake, NY",
    city_name: "Rainbow Lake",
  },
  {
    postal_code: "68636",
    full_city_name: "Elgin, NE",
    city_name: "Elgin",
  },
  {
    postal_code: "25984",
    full_city_name: "Rupert, WV",
    city_name: "Rupert",
  },
  {
    postal_code: "43153",
    full_city_name: "South Solon, OH",
    city_name: "South Solon",
  },
  {
    postal_code: "52624",
    full_city_name: "Denmark, IA",
    city_name: "Denmark",
  },
  {
    postal_code: "81639",
    full_city_name: "Hayden, CO",
    city_name: "Hayden",
  },
  {
    postal_code: "61044",
    full_city_name: "Kent, IL",
    city_name: "Kent",
  },
  {
    postal_code: "70736",
    full_city_name: "Glynn, LA",
    city_name: "Glynn",
  },
  {
    postal_code: "17923",
    full_city_name: "Branchdale, PA",
    city_name: "Branchdale",
  },
  {
    postal_code: "04985",
    full_city_name: "West Forks, ME",
    city_name: "West Forks",
  },
  {
    postal_code: "41274",
    full_city_name: "Wittensville, KY",
    city_name: "Wittensville",
  },
  {
    postal_code: "79778",
    full_city_name: "Rankin, TX",
    city_name: "Rankin",
  },
  {
    postal_code: "72933",
    full_city_name: "Charleston, AR",
    city_name: "Charleston",
  },
  {
    postal_code: "30516",
    full_city_name: "Bowersville, GA",
    city_name: "Bowersville",
  },
  {
    postal_code: "75501",
    full_city_name: "Texarkana, TX",
    city_name: "Texarkana",
  },
  {
    postal_code: "38610",
    full_city_name: "Blue Mountain, MS",
    city_name: "Blue Mountain",
  },
  {
    postal_code: "67436",
    full_city_name: "Delphos, KS",
    city_name: "Delphos",
  },
  {
    postal_code: "26815",
    full_city_name: "Sugar Grove, WV",
    city_name: "Sugar Grove",
  },
  {
    postal_code: "71826",
    full_city_name: "Bradley, AR",
    city_name: "Bradley",
  },
  {
    postal_code: "62990",
    full_city_name: "Thebes, IL",
    city_name: "Thebes",
  },
  {
    postal_code: "71475",
    full_city_name: "Slagle, LA",
    city_name: "Slagle",
  },
  {
    postal_code: "73938",
    full_city_name: "Forgan, OK",
    city_name: "Forgan",
  },
  {
    postal_code: "57272",
    full_city_name: "Wallace, SD",
    city_name: "Wallace",
  },
  {
    postal_code: "49345",
    full_city_name: "Sparta, MI",
    city_name: "Sparta",
  },
  {
    postal_code: "16621",
    full_city_name: "Broad Top, PA",
    city_name: "Broad Top",
  },
  {
    postal_code: "16948",
    full_city_name: "Ulysses, PA",
    city_name: "Ulysses",
  },
  {
    postal_code: "48005",
    full_city_name: "Armada, MI",
    city_name: "Armada",
  },
  {
    postal_code: "57340",
    full_city_name: "Fulton, SD",
    city_name: "Fulton",
  },
  {
    postal_code: "80468",
    full_city_name: "Parshall, CO",
    city_name: "Parshall",
  },
  {
    postal_code: "53065",
    full_city_name: "Oakfield, WI",
    city_name: "Oakfield",
  },
  {
    postal_code: "26560",
    full_city_name: "Baxter, WV",
    city_name: "Baxter",
  },
  {
    postal_code: "50006",
    full_city_name: "Alden, IA",
    city_name: "Alden",
  },
  {
    postal_code: "27928",
    full_city_name: "Creswell, NC",
    city_name: "Creswell",
  },
  {
    postal_code: "25505",
    full_city_name: "Big Creek, WV",
    city_name: "Big Creek",
  },
  {
    postal_code: "68740",
    full_city_name: "Hoskins, NE",
    city_name: "Hoskins",
  },
  {
    postal_code: "62926",
    full_city_name: "Dongola, IL",
    city_name: "Dongola",
  },
  {
    postal_code: "84744",
    full_city_name: "Koosharem, UT",
    city_name: "Koosharem",
  },
  {
    postal_code: "31749",
    full_city_name: "Enigma, GA",
    city_name: "Enigma",
  },
  {
    postal_code: "38901",
    full_city_name: "Grenada, MS",
    city_name: "Grenada",
  },
  {
    postal_code: "68340",
    full_city_name: "Deshler, NE",
    city_name: "Deshler",
  },
  {
    postal_code: "48850",
    full_city_name: "Lakeview, MI",
    city_name: "Lakeview",
  },
  {
    postal_code: "57003",
    full_city_name: "Baltic, SD",
    city_name: "Baltic",
  },
  {
    postal_code: "53017",
    full_city_name: "Colgate, WI",
    city_name: "Colgate",
  },
  {
    postal_code: "15781",
    full_city_name: "Walston, PA",
    city_name: "Walston",
  },
  {
    postal_code: "65778",
    full_city_name: "Myrtle, MO",
    city_name: "Myrtle",
  },
  {
    postal_code: "73620",
    full_city_name: "Arapaho, OK",
    city_name: "Arapaho",
  },
  {
    postal_code: "48014",
    full_city_name: "Capac, MI",
    city_name: "Capac",
  },
  {
    postal_code: "65067",
    full_city_name: "Portland, MO",
    city_name: "Portland",
  },
  {
    postal_code: "29145",
    full_city_name: "Silverstreet, SC",
    city_name: "Silverstreet",
  },
  {
    postal_code: "31042",
    full_city_name: "Irwinton, GA",
    city_name: "Irwinton",
  },
  {
    postal_code: "52757",
    full_city_name: "Low Moor, IA",
    city_name: "Low Moor",
  },
  {
    postal_code: "42440",
    full_city_name: "Mortons Gap, KY",
    city_name: "Mortons Gap",
  },
  {
    postal_code: "72937",
    full_city_name: "Hackett, AR",
    city_name: "Hackett",
  },
  {
    postal_code: "64778",
    full_city_name: "Richards, MO",
    city_name: "Richards",
  },
  {
    postal_code: "71108",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "23091",
    full_city_name: "Little Plymouth, VA",
    city_name: "Little Plymouth",
  },
  {
    postal_code: "79010",
    full_city_name: "Boys Ranch, TX",
    city_name: "Boys Ranch",
  },
  {
    postal_code: "50840",
    full_city_name: "Clearfield, IA",
    city_name: "Clearfield",
  },
  {
    postal_code: "56216",
    full_city_name: "Blomkest, MN",
    city_name: "Blomkest",
  },
  {
    postal_code: "16255",
    full_city_name: "Sligo, PA",
    city_name: "Sligo",
  },
  {
    postal_code: "98283",
    full_city_name: "Rockport, WA",
    city_name: "Rockport",
  },
  {
    postal_code: "07423",
    full_city_name: "Ho Ho Kus, NJ",
    city_name: "Ho Ho Kus",
  },
  {
    postal_code: "46761",
    full_city_name: "Lagrange, IN",
    city_name: "Lagrange",
  },
  {
    postal_code: "84731",
    full_city_name: "Greenville, UT",
    city_name: "Greenville",
  },
  {
    postal_code: "78349",
    full_city_name: "Concepcion, TX",
    city_name: "Concepcion",
  },
  {
    postal_code: "36442",
    full_city_name: "Florala, AL",
    city_name: "Florala",
  },
  {
    postal_code: "38914",
    full_city_name: "Big Creek, MS",
    city_name: "Big Creek",
  },
  {
    postal_code: "25114",
    full_city_name: "Jeffrey, WV",
    city_name: "Jeffrey",
  },
  {
    postal_code: "63545",
    full_city_name: "Green City, MO",
    city_name: "Green City",
  },
  {
    postal_code: "49342",
    full_city_name: "Rodney, MI",
    city_name: "Rodney",
  },
  {
    postal_code: "64780",
    full_city_name: "Rockville, MO",
    city_name: "Rockville",
  },
  {
    postal_code: "31544",
    full_city_name: "Jacksonville, GA",
    city_name: "Jacksonville",
  },
  {
    postal_code: "99150",
    full_city_name: "Malo, WA",
    city_name: "Malo",
  },
  {
    postal_code: "59634",
    full_city_name: "Clancy, MT",
    city_name: "Clancy",
  },
  {
    postal_code: "47339",
    full_city_name: "Economy, IN",
    city_name: "Economy",
  },
  {
    postal_code: "62668",
    full_city_name: "Murrayville, IL",
    city_name: "Murrayville",
  },
  {
    postal_code: "92385",
    full_city_name: "Skyforest, CA",
    city_name: "Skyforest",
  },
  {
    postal_code: "65731",
    full_city_name: "Powersite, MO",
    city_name: "Powersite",
  },
  {
    postal_code: "83335",
    full_city_name: "Hazelton, ID",
    city_name: "Hazelton",
  },
  {
    postal_code: "72084",
    full_city_name: "Leola, AR",
    city_name: "Leola",
  },
  {
    postal_code: "04673",
    full_city_name: "Sargentville, ME",
    city_name: "Sargentville",
  },
  {
    postal_code: "23423",
    full_city_name: "Quinby, VA",
    city_name: "Quinby",
  },
  {
    postal_code: "47558",
    full_city_name: "Montgomery, IN",
    city_name: "Montgomery",
  },
  {
    postal_code: "87521",
    full_city_name: "Chamisal, NM",
    city_name: "Chamisal",
  },
  {
    postal_code: "30738",
    full_city_name: "Rising Fawn, GA",
    city_name: "Rising Fawn",
  },
  {
    postal_code: "80646",
    full_city_name: "Lucerne, CO",
    city_name: "Lucerne",
  },
  {
    postal_code: "61278",
    full_city_name: "Rapids City, IL",
    city_name: "Rapids City",
  },
  {
    postal_code: "38642",
    full_city_name: "Lamar, MS",
    city_name: "Lamar",
  },
  {
    postal_code: "88051",
    full_city_name: "Mule Creek, NM",
    city_name: "Mule Creek",
  },
  {
    postal_code: "93526",
    full_city_name: "Independence, CA",
    city_name: "Independence",
  },
  {
    postal_code: "29922",
    full_city_name: "Garnett, SC",
    city_name: "Garnett",
  },
  {
    postal_code: "73840",
    full_city_name: "Fargo, OK",
    city_name: "Fargo",
  },
  {
    postal_code: "47995",
    full_city_name: "Wolcott, IN",
    city_name: "Wolcott",
  },
  {
    postal_code: "64832",
    full_city_name: "Asbury, MO",
    city_name: "Asbury",
  },
  {
    postal_code: "71073",
    full_city_name: "Sibley, LA",
    city_name: "Sibley",
  },
  {
    postal_code: "80120",
    full_city_name: "Littleton, CO",
    city_name: "Littleton",
  },
  {
    postal_code: "22435",
    full_city_name: "Callao, VA",
    city_name: "Callao",
  },
  {
    postal_code: "05904",
    full_city_name: "Gilman, VT",
    city_name: "Gilman",
  },
  {
    postal_code: "51245",
    full_city_name: "Primghar, IA",
    city_name: "Primghar",
  },
  {
    postal_code: "01908",
    full_city_name: "Nahant, MA",
    city_name: "Nahant",
  },
  {
    postal_code: "99588",
    full_city_name: "Glennallen, AK",
    city_name: "Glennallen",
  },
  {
    postal_code: "25962",
    full_city_name: "Rainelle, WV",
    city_name: "Rainelle",
  },
  {
    postal_code: "50571",
    full_city_name: "Palmer, IA",
    city_name: "Palmer",
  },
  {
    postal_code: "36609",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "95364",
    full_city_name: "Pinecrest, CA",
    city_name: "Pinecrest",
  },
  {
    postal_code: "51656",
    full_city_name: "Yorktown, IA",
    city_name: "Yorktown",
  },
  {
    postal_code: "62556",
    full_city_name: "Palmer, IL",
    city_name: "Palmer",
  },
  {
    postal_code: "69154",
    full_city_name: "Oshkosh, NE",
    city_name: "Oshkosh",
  },
  {
    postal_code: "40914",
    full_city_name: "Big Creek, KY",
    city_name: "Big Creek",
  },
  {
    postal_code: "47135",
    full_city_name: "Laconia, IN",
    city_name: "Laconia",
  },
  {
    postal_code: "74058",
    full_city_name: "Pawnee, OK",
    city_name: "Pawnee",
  },
  {
    postal_code: "67003",
    full_city_name: "Anthony, KS",
    city_name: "Anthony",
  },
  {
    postal_code: "75127",
    full_city_name: "Fruitvale, TX",
    city_name: "Fruitvale",
  },
  {
    postal_code: "73756",
    full_city_name: "Loyal, OK",
    city_name: "Loyal",
  },
  {
    postal_code: "47529",
    full_city_name: "Elnora, IN",
    city_name: "Elnora",
  },
  {
    postal_code: "57543",
    full_city_name: "Kadoka, SD",
    city_name: "Kadoka",
  },
  {
    postal_code: "47611",
    full_city_name: "Chrisney, IN",
    city_name: "Chrisney",
  },
  {
    postal_code: "75689",
    full_city_name: "Selman City, TX",
    city_name: "Selman City",
  },
  {
    postal_code: "67635",
    full_city_name: "Dresden, KS",
    city_name: "Dresden",
  },
  {
    postal_code: "07601",
    full_city_name: "Hackensack, NJ",
    city_name: "Hackensack",
  },
  {
    postal_code: "04575",
    full_city_name: "West Boothbay Harbor, ME",
    city_name: "West Boothbay Harbor",
  },
  {
    postal_code: "55711",
    full_city_name: "Brookston, MN",
    city_name: "Brookston",
  },
  {
    postal_code: "45682",
    full_city_name: "South Webster, OH",
    city_name: "South Webster",
  },
  {
    postal_code: "67876",
    full_city_name: "Spearville, KS",
    city_name: "Spearville",
  },
  {
    postal_code: "73738",
    full_city_name: "Garber, OK",
    city_name: "Garber",
  },
  {
    postal_code: "24138",
    full_city_name: "Pilot, VA",
    city_name: "Pilot",
  },
  {
    postal_code: "31309",
    full_city_name: "Fleming, GA",
    city_name: "Fleming",
  },
  {
    postal_code: "25142",
    full_city_name: "Nellis, WV",
    city_name: "Nellis",
  },
  {
    postal_code: "37074",
    full_city_name: "Hartsville, TN",
    city_name: "Hartsville",
  },
  {
    postal_code: "74637",
    full_city_name: "Fairfax, OK",
    city_name: "Fairfax",
  },
  {
    postal_code: "57301",
    full_city_name: "Mitchell, SD",
    city_name: "Mitchell",
  },
  {
    postal_code: "40997",
    full_city_name: "Walker, KY",
    city_name: "Walker",
  },
  {
    postal_code: "22731",
    full_city_name: "Pratts, VA",
    city_name: "Pratts",
  },
  {
    postal_code: "38548",
    full_city_name: "Buffalo Valley, TN",
    city_name: "Buffalo Valley",
  },
  {
    postal_code: "53108",
    full_city_name: "Caledonia, WI",
    city_name: "Caledonia",
  },
  {
    postal_code: "18831",
    full_city_name: "Milan, PA",
    city_name: "Milan",
  },
  {
    postal_code: "35984",
    full_city_name: "Mentone, AL",
    city_name: "Mentone",
  },
  {
    postal_code: "01103",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "60920",
    full_city_name: "Campus, IL",
    city_name: "Campus",
  },
  {
    postal_code: "42367",
    full_city_name: "Powderly, KY",
    city_name: "Powderly",
  },
  {
    postal_code: "61451",
    full_city_name: "Laura, IL",
    city_name: "Laura",
  },
  {
    postal_code: "74026",
    full_city_name: "Davenport, OK",
    city_name: "Davenport",
  },
  {
    postal_code: "58012",
    full_city_name: "Casselton, ND",
    city_name: "Casselton",
  },
  {
    postal_code: "83627",
    full_city_name: "Hammett, ID",
    city_name: "Hammett",
  },
  {
    postal_code: "51578",
    full_city_name: "Westphalia, IA",
    city_name: "Westphalia",
  },
  {
    postal_code: "39339",
    full_city_name: "Louisville, MS",
    city_name: "Louisville",
  },
  {
    postal_code: "17941",
    full_city_name: "Klingerstown, PA",
    city_name: "Klingerstown",
  },
  {
    postal_code: "77444",
    full_city_name: "Guy, TX",
    city_name: "Guy",
  },
  {
    postal_code: "26285",
    full_city_name: "Norton, WV",
    city_name: "Norton",
  },
  {
    postal_code: "54451",
    full_city_name: "Medford, WI",
    city_name: "Medford",
  },
  {
    postal_code: "23517",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "68780",
    full_city_name: "Stuart, NE",
    city_name: "Stuart",
  },
  {
    postal_code: "57382",
    full_city_name: "Wessington Springs, SD",
    city_name: "Wessington Springs",
  },
  {
    postal_code: "23416",
    full_city_name: "Oak Hall, VA",
    city_name: "Oak Hall",
  },
  {
    postal_code: "21914",
    full_city_name: "Charlestown, MD",
    city_name: "Charlestown",
  },
  {
    postal_code: "35480",
    full_city_name: "Ralph, AL",
    city_name: "Ralph",
  },
  {
    postal_code: "71048",
    full_city_name: "Lisbon, LA",
    city_name: "Lisbon",
  },
  {
    postal_code: "56681",
    full_city_name: "Squaw Lake, MN",
    city_name: "Squaw Lake",
  },
  {
    postal_code: "97834",
    full_city_name: "Halfway, OR",
    city_name: "Halfway",
  },
  {
    postal_code: "47564",
    full_city_name: "Otwell, IN",
    city_name: "Otwell",
  },
  {
    postal_code: "97117",
    full_city_name: "Gales Creek, OR",
    city_name: "Gales Creek",
  },
  {
    postal_code: "56715",
    full_city_name: "Brooks, MN",
    city_name: "Brooks",
  },
  {
    postal_code: "98844",
    full_city_name: "Oroville, WA",
    city_name: "Oroville",
  },
  {
    postal_code: "16882",
    full_city_name: "Woodward, PA",
    city_name: "Woodward",
  },
  {
    postal_code: "04645",
    full_city_name: "Isle Au Haut, ME",
    city_name: "Isle Au Haut",
  },
  {
    postal_code: "29075",
    full_city_name: "Little Mountain, SC",
    city_name: "Little Mountain",
  },
  {
    postal_code: "93283",
    full_city_name: "Weldon, CA",
    city_name: "Weldon",
  },
  {
    postal_code: "30755",
    full_city_name: "Tunnel Hill, GA",
    city_name: "Tunnel Hill",
  },
  {
    postal_code: "37016",
    full_city_name: "Auburntown, TN",
    city_name: "Auburntown",
  },
  {
    postal_code: "98261",
    full_city_name: "Lopez Island, WA",
    city_name: "Lopez Island",
  },
  {
    postal_code: "62675",
    full_city_name: "Petersburg, IL",
    city_name: "Petersburg",
  },
  {
    postal_code: "83624",
    full_city_name: "Grand View, ID",
    city_name: "Grand View",
  },
  {
    postal_code: "22843",
    full_city_name: "Mount Solon, VA",
    city_name: "Mount Solon",
  },
  {
    postal_code: "14589",
    full_city_name: "Williamson, NY",
    city_name: "Williamson",
  },
  {
    postal_code: "83546",
    full_city_name: "Pierce, ID",
    city_name: "Pierce",
  },
  {
    postal_code: "29809",
    full_city_name: "New Ellenton, SC",
    city_name: "New Ellenton",
  },
  {
    postal_code: "38865",
    full_city_name: "Rienzi, MS",
    city_name: "Rienzi",
  },
  {
    postal_code: "10506",
    full_city_name: "Bedford, NY",
    city_name: "Bedford",
  },
  {
    postal_code: "47023",
    full_city_name: "Holton, IN",
    city_name: "Holton",
  },
  {
    postal_code: "48003",
    full_city_name: "Almont, MI",
    city_name: "Almont",
  },
  {
    postal_code: "27942",
    full_city_name: "Harrellsville, NC",
    city_name: "Harrellsville",
  },
  {
    postal_code: "76640",
    full_city_name: "Elm Mott, TX",
    city_name: "Elm Mott",
  },
  {
    postal_code: "67556",
    full_city_name: "Mc Cracken, KS",
    city_name: "Mc Cracken",
  },
  {
    postal_code: "29123",
    full_city_name: "Pelion, SC",
    city_name: "Pelion",
  },
  {
    postal_code: "72524",
    full_city_name: "Cord, AR",
    city_name: "Cord",
  },
  {
    postal_code: "63060",
    full_city_name: "Lonedell, MO",
    city_name: "Lonedell",
  },
  {
    postal_code: "14435",
    full_city_name: "Conesus, NY",
    city_name: "Conesus",
  },
  {
    postal_code: "74521",
    full_city_name: "Albion, OK",
    city_name: "Albion",
  },
  {
    postal_code: "31022",
    full_city_name: "Dudley, GA",
    city_name: "Dudley",
  },
  {
    postal_code: "85348",
    full_city_name: "Salome, AZ",
    city_name: "Salome",
  },
  {
    postal_code: "24983",
    full_city_name: "Union, WV",
    city_name: "Union",
  },
  {
    postal_code: "77443",
    full_city_name: "Glen Flora, TX",
    city_name: "Glen Flora",
  },
  {
    postal_code: "70811",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "43541",
    full_city_name: "Milton Center, OH",
    city_name: "Milton Center",
  },
  {
    postal_code: "66537",
    full_city_name: "Scranton, KS",
    city_name: "Scranton",
  },
  {
    postal_code: "60183",
    full_city_name: "Wasco, IL",
    city_name: "Wasco",
  },
  {
    postal_code: "17535",
    full_city_name: "Kinzers, PA",
    city_name: "Kinzers",
  },
  {
    postal_code: "50243",
    full_city_name: "Sheldahl, IA",
    city_name: "Sheldahl",
  },
  {
    postal_code: "14039",
    full_city_name: "Dale, NY",
    city_name: "Dale",
  },
  {
    postal_code: "45715",
    full_city_name: "Beverly, OH",
    city_name: "Beverly",
  },
  {
    postal_code: "64628",
    full_city_name: "Brookfield, MO",
    city_name: "Brookfield",
  },
  {
    postal_code: "98345",
    full_city_name: "Keyport, WA",
    city_name: "Keyport",
  },
  {
    postal_code: "23851",
    full_city_name: "Franklin, VA",
    city_name: "Franklin",
  },
  {
    postal_code: "62862",
    full_city_name: "Mill Shoals, IL",
    city_name: "Mill Shoals",
  },
  {
    postal_code: "47281",
    full_city_name: "Vallonia, IN",
    city_name: "Vallonia",
  },
  {
    postal_code: "20006",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "49302",
    full_city_name: "Alto, MI",
    city_name: "Alto",
  },
  {
    postal_code: "50544",
    full_city_name: "Harcourt, IA",
    city_name: "Harcourt",
  },
  {
    postal_code: "26591",
    full_city_name: "Worthington, WV",
    city_name: "Worthington",
  },
  {
    postal_code: "07878",
    full_city_name: "Mount Tabor, NJ",
    city_name: "Mount Tabor",
  },
  {
    postal_code: "59482",
    full_city_name: "Sunburst, MT",
    city_name: "Sunburst",
  },
  {
    postal_code: "54845",
    full_city_name: "Hertel, WI",
    city_name: "Hertel",
  },
  {
    postal_code: "51242",
    full_city_name: "Lester, IA",
    city_name: "Lester",
  },
  {
    postal_code: "73093",
    full_city_name: "Washington, OK",
    city_name: "Washington",
  },
  {
    postal_code: "25638",
    full_city_name: "Omar, WV",
    city_name: "Omar",
  },
  {
    postal_code: "48069",
    full_city_name: "Pleasant Ridge, MI",
    city_name: "Pleasant Ridge",
  },
  {
    postal_code: "12980",
    full_city_name: "Saint Regis Falls, NY",
    city_name: "Saint Regis Falls",
  },
  {
    postal_code: "84630",
    full_city_name: "Fayette, UT",
    city_name: "Fayette",
  },
  {
    postal_code: "98354",
    full_city_name: "Milton, WA",
    city_name: "Milton",
  },
  {
    postal_code: "39755",
    full_city_name: "Pheba, MS",
    city_name: "Pheba",
  },
  {
    postal_code: "50665",
    full_city_name: "Parkersburg, IA",
    city_name: "Parkersburg",
  },
  {
    postal_code: "48346",
    full_city_name: "Clarkston, MI",
    city_name: "Clarkston",
  },
  {
    postal_code: "91931",
    full_city_name: "Guatay, CA",
    city_name: "Guatay",
  },
  {
    postal_code: "54407",
    full_city_name: "Amherst Junction, WI",
    city_name: "Amherst Junction",
  },
  {
    postal_code: "93287",
    full_city_name: "Woody, CA",
    city_name: "Woody",
  },
  {
    postal_code: "62613",
    full_city_name: "Athens, IL",
    city_name: "Athens",
  },
  {
    postal_code: "49119",
    full_city_name: "New Troy, MI",
    city_name: "New Troy",
  },
  {
    postal_code: "31830",
    full_city_name: "Warm Springs, GA",
    city_name: "Warm Springs",
  },
  {
    postal_code: "01608",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "25107",
    full_city_name: "Hernshaw, WV",
    city_name: "Hernshaw",
  },
  {
    postal_code: "62324",
    full_city_name: "Clayton, IL",
    city_name: "Clayton",
  },
  {
    postal_code: "25902",
    full_city_name: "Odd, WV",
    city_name: "Odd",
  },
  {
    postal_code: "75760",
    full_city_name: "Cushing, TX",
    city_name: "Cushing",
  },
  {
    postal_code: "47708",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "50262",
    full_city_name: "Van Wert, IA",
    city_name: "Van Wert",
  },
  {
    postal_code: "98827",
    full_city_name: "Loomis, WA",
    city_name: "Loomis",
  },
  {
    postal_code: "25831",
    full_city_name: "Danese, WV",
    city_name: "Danese",
  },
  {
    postal_code: "65617",
    full_city_name: "Brighton, MO",
    city_name: "Brighton",
  },
  {
    postal_code: "38259",
    full_city_name: "Trimble, TN",
    city_name: "Trimble",
  },
  {
    postal_code: "40157",
    full_city_name: "Payneville, KY",
    city_name: "Payneville",
  },
  {
    postal_code: "04662",
    full_city_name: "Northeast Harbor, ME",
    city_name: "Northeast Harbor",
  },
  {
    postal_code: "50223",
    full_city_name: "Pilot Mound, IA",
    city_name: "Pilot Mound",
  },
  {
    postal_code: "85626",
    full_city_name: "Pirtleville, AZ",
    city_name: "Pirtleville",
  },
  {
    postal_code: "05441",
    full_city_name: "Bakersfield, VT",
    city_name: "Bakersfield",
  },
  {
    postal_code: "45650",
    full_city_name: "Lynx, OH",
    city_name: "Lynx",
  },
  {
    postal_code: "31565",
    full_city_name: "Waverly, GA",
    city_name: "Waverly",
  },
  {
    postal_code: "39192",
    full_city_name: "West, MS",
    city_name: "West",
  },
  {
    postal_code: "98538",
    full_city_name: "Curtis, WA",
    city_name: "Curtis",
  },
  {
    postal_code: "61338",
    full_city_name: "Manlius, IL",
    city_name: "Manlius",
  },
  {
    postal_code: "23163",
    full_city_name: "Susan, VA",
    city_name: "Susan",
  },
  {
    postal_code: "56574",
    full_city_name: "Perley, MN",
    city_name: "Perley",
  },
  {
    postal_code: "73566",
    full_city_name: "Snyder, OK",
    city_name: "Snyder",
  },
  {
    postal_code: "45673",
    full_city_name: "Richmond Dale, OH",
    city_name: "Richmond Dale",
  },
  {
    postal_code: "63744",
    full_city_name: "Delta, MO",
    city_name: "Delta",
  },
  {
    postal_code: "15053",
    full_city_name: "Joffre, PA",
    city_name: "Joffre",
  },
  {
    postal_code: "62321",
    full_city_name: "Carthage, IL",
    city_name: "Carthage",
  },
  {
    postal_code: "98443",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "47855",
    full_city_name: "Hymera, IN",
    city_name: "Hymera",
  },
  {
    postal_code: "83848",
    full_city_name: "Nordman, ID",
    city_name: "Nordman",
  },
  {
    postal_code: "38348",
    full_city_name: "Lavinia, TN",
    city_name: "Lavinia",
  },
  {
    postal_code: "05043",
    full_city_name: "East Thetford, VT",
    city_name: "East Thetford",
  },
  {
    postal_code: "41651",
    full_city_name: "Minnie, KY",
    city_name: "Minnie",
  },
  {
    postal_code: "69120",
    full_city_name: "Arnold, NE",
    city_name: "Arnold",
  },
  {
    postal_code: "49252",
    full_city_name: "Litchfield, MI",
    city_name: "Litchfield",
  },
  {
    postal_code: "72841",
    full_city_name: "Harvey, AR",
    city_name: "Harvey",
  },
  {
    postal_code: "45772",
    full_city_name: "Reedsville, OH",
    city_name: "Reedsville",
  },
  {
    postal_code: "71424",
    full_city_name: "Elmer, LA",
    city_name: "Elmer",
  },
  {
    postal_code: "24085",
    full_city_name: "Eagle Rock, VA",
    city_name: "Eagle Rock",
  },
  {
    postal_code: "31638",
    full_city_name: "Morven, GA",
    city_name: "Morven",
  },
  {
    postal_code: "18246",
    full_city_name: "Rock Glen, PA",
    city_name: "Rock Glen",
  },
  {
    postal_code: "18249",
    full_city_name: "Sugarloaf, PA",
    city_name: "Sugarloaf",
  },
  {
    postal_code: "68378",
    full_city_name: "Johnson, NE",
    city_name: "Johnson",
  },
  {
    postal_code: "99835",
    full_city_name: "Sitka, AK",
    city_name: "Sitka",
  },
  {
    postal_code: "46524",
    full_city_name: "Etna Green, IN",
    city_name: "Etna Green",
  },
  {
    postal_code: "35765",
    full_city_name: "Pisgah, AL",
    city_name: "Pisgah",
  },
  {
    postal_code: "32530",
    full_city_name: "Bagdad, FL",
    city_name: "Bagdad",
  },
  {
    postal_code: "18617",
    full_city_name: "Glen Lyon, PA",
    city_name: "Glen Lyon",
  },
  {
    postal_code: "31829",
    full_city_name: "Upatoi, GA",
    city_name: "Upatoi",
  },
  {
    postal_code: "37069",
    full_city_name: "Franklin, TN",
    city_name: "Franklin",
  },
  {
    postal_code: "14040",
    full_city_name: "Darien Center, NY",
    city_name: "Darien Center",
  },
  {
    postal_code: "35740",
    full_city_name: "Bridgeport, AL",
    city_name: "Bridgeport",
  },
  {
    postal_code: "46766",
    full_city_name: "Liberty Center, IN",
    city_name: "Liberty Center",
  },
  {
    postal_code: "17329",
    full_city_name: "Glenville, PA",
    city_name: "Glenville",
  },
  {
    postal_code: "59323",
    full_city_name: "Colstrip, MT",
    city_name: "Colstrip",
  },
  {
    postal_code: "82833",
    full_city_name: "Big Horn, WY",
    city_name: "Big Horn",
  },
  {
    postal_code: "65729",
    full_city_name: "Pontiac, MO",
    city_name: "Pontiac",
  },
  {
    postal_code: "83813",
    full_city_name: "Cocolalla, ID",
    city_name: "Cocolalla",
  },
  {
    postal_code: "23032",
    full_city_name: "Church View, VA",
    city_name: "Church View",
  },
  {
    postal_code: "62316",
    full_city_name: "Bowen, IL",
    city_name: "Bowen",
  },
  {
    postal_code: "17949",
    full_city_name: "Mahanoy Plane, PA",
    city_name: "Mahanoy Plane",
  },
  {
    postal_code: "25564",
    full_city_name: "Sod, WV",
    city_name: "Sod",
  },
  {
    postal_code: "07310",
    full_city_name: "Jersey City, NJ",
    city_name: "Jersey City",
  },
  {
    postal_code: "66932",
    full_city_name: "Athol, KS",
    city_name: "Athol",
  },
  {
    postal_code: "56116",
    full_city_name: "Beaver Creek, MN",
    city_name: "Beaver Creek",
  },
  {
    postal_code: "26217",
    full_city_name: "Diana, WV",
    city_name: "Diana",
  },
  {
    postal_code: "61468",
    full_city_name: "Ophiem, IL",
    city_name: "Ophiem",
  },
  {
    postal_code: "65740",
    full_city_name: "Rockaway Beach, MO",
    city_name: "Rockaway Beach",
  },
  {
    postal_code: "72471",
    full_city_name: "Swifton, AR",
    city_name: "Swifton",
  },
  {
    postal_code: "31090",
    full_city_name: "Toomsboro, GA",
    city_name: "Toomsboro",
  },
  {
    postal_code: "73667",
    full_city_name: "Taloga, OK",
    city_name: "Taloga",
  },
  {
    postal_code: "47223",
    full_city_name: "Butlerville, IN",
    city_name: "Butlerville",
  },
  {
    postal_code: "28351",
    full_city_name: "Laurel Hill, NC",
    city_name: "Laurel Hill",
  },
  {
    postal_code: "23045",
    full_city_name: "Diggs, VA",
    city_name: "Diggs",
  },
  {
    postal_code: "44450",
    full_city_name: "North Bloomfield, OH",
    city_name: "North Bloomfield",
  },
  {
    postal_code: "50606",
    full_city_name: "Arlington, IA",
    city_name: "Arlington",
  },
  {
    postal_code: "64651",
    full_city_name: "Laclede, MO",
    city_name: "Laclede",
  },
  {
    postal_code: "54932",
    full_city_name: "Eldorado, WI",
    city_name: "Eldorado",
  },
  {
    postal_code: "39427",
    full_city_name: "Carson, MS",
    city_name: "Carson",
  },
  {
    postal_code: "23138",
    full_city_name: "Port Haywood, VA",
    city_name: "Port Haywood",
  },
  {
    postal_code: "39366",
    full_city_name: "Vossburg, MS",
    city_name: "Vossburg",
  },
  {
    postal_code: "14012",
    full_city_name: "Barker, NY",
    city_name: "Barker",
  },
  {
    postal_code: "62019",
    full_city_name: "Donnellson, IL",
    city_name: "Donnellson",
  },
  {
    postal_code: "57454",
    full_city_name: "Langford, SD",
    city_name: "Langford",
  },
  {
    postal_code: "43468",
    full_city_name: "Williston, OH",
    city_name: "Williston",
  },
  {
    postal_code: "84330",
    full_city_name: "Plymouth, UT",
    city_name: "Plymouth",
  },
  {
    postal_code: "25978",
    full_city_name: "Nimitz, WV",
    city_name: "Nimitz",
  },
  {
    postal_code: "64459",
    full_city_name: "Helena, MO",
    city_name: "Helena",
  },
  {
    postal_code: "12734",
    full_city_name: "Ferndale, NY",
    city_name: "Ferndale",
  },
  {
    postal_code: "01343",
    full_city_name: "Drury, MA",
    city_name: "Drury",
  },
  {
    postal_code: "48050",
    full_city_name: "New Haven, MI",
    city_name: "New Haven",
  },
  {
    postal_code: "52056",
    full_city_name: "Luxemburg, IA",
    city_name: "Luxemburg",
  },
  {
    postal_code: "31566",
    full_city_name: "Waynesville, GA",
    city_name: "Waynesville",
  },
  {
    postal_code: "35954",
    full_city_name: "Attalla, AL",
    city_name: "Attalla",
  },
  {
    postal_code: "31012",
    full_city_name: "Chester, GA",
    city_name: "Chester",
  },
  {
    postal_code: "50441",
    full_city_name: "Hampton, IA",
    city_name: "Hampton",
  },
  {
    postal_code: "85334",
    full_city_name: "Ehrenberg, AZ",
    city_name: "Ehrenberg",
  },
  {
    postal_code: "05901",
    full_city_name: "Averill, VT",
    city_name: "Averill",
  },
  {
    postal_code: "26342",
    full_city_name: "Coxs Mills, WV",
    city_name: "Coxs Mills",
  },
  {
    postal_code: "66970",
    full_city_name: "Webber, KS",
    city_name: "Webber",
  },
  {
    postal_code: "48006",
    full_city_name: "Avoca, MI",
    city_name: "Avoca",
  },
  {
    postal_code: "24976",
    full_city_name: "Sinks Grove, WV",
    city_name: "Sinks Grove",
  },
  {
    postal_code: "56725",
    full_city_name: "Goodridge, MN",
    city_name: "Goodridge",
  },
  {
    postal_code: "24245",
    full_city_name: "Dungannon, VA",
    city_name: "Dungannon",
  },
  {
    postal_code: "68320",
    full_city_name: "Brock, NE",
    city_name: "Brock",
  },
  {
    postal_code: "79376",
    full_city_name: "Tokio, TX",
    city_name: "Tokio",
  },
  {
    postal_code: "65024",
    full_city_name: "Chamois, MO",
    city_name: "Chamois",
  },
  {
    postal_code: "49796",
    full_city_name: "Walloon Lake, MI",
    city_name: "Walloon Lake",
  },
  {
    postal_code: "06390",
    full_city_name: "Fishers Island, NY",
    city_name: "Fishers Island",
  },
  {
    postal_code: "17763",
    full_city_name: "Ralston, PA",
    city_name: "Ralston",
  },
  {
    postal_code: "80470",
    full_city_name: "Pine, CO",
    city_name: "Pine",
  },
  {
    postal_code: "64770",
    full_city_name: "Montrose, MO",
    city_name: "Montrose",
  },
  {
    postal_code: "53002",
    full_city_name: "Allenton, WI",
    city_name: "Allenton",
  },
  {
    postal_code: "23420",
    full_city_name: "Painter, VA",
    city_name: "Painter",
  },
  {
    postal_code: "70516",
    full_city_name: "Branch, LA",
    city_name: "Branch",
  },
  {
    postal_code: "62539",
    full_city_name: "Illiopolis, IL",
    city_name: "Illiopolis",
  },
  {
    postal_code: "49872",
    full_city_name: "Perkins, MI",
    city_name: "Perkins",
  },
  {
    postal_code: "44849",
    full_city_name: "Nevada, OH",
    city_name: "Nevada",
  },
  {
    postal_code: "62816",
    full_city_name: "Bonnie, IL",
    city_name: "Bonnie",
  },
  {
    postal_code: "49701",
    full_city_name: "Mackinaw City, MI",
    city_name: "Mackinaw City",
  },
  {
    postal_code: "48532",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "62540",
    full_city_name: "Kincaid, IL",
    city_name: "Kincaid",
  },
  {
    postal_code: "82323",
    full_city_name: "Dixon, WY",
    city_name: "Dixon",
  },
  {
    postal_code: "71865",
    full_city_name: "Wilton, AR",
    city_name: "Wilton",
  },
  {
    postal_code: "61532",
    full_city_name: "Forest City, IL",
    city_name: "Forest City",
  },
  {
    postal_code: "38379",
    full_city_name: "Stantonville, TN",
    city_name: "Stantonville",
  },
  {
    postal_code: "88267",
    full_city_name: "Tatum, NM",
    city_name: "Tatum",
  },
  {
    postal_code: "01379",
    full_city_name: "Wendell, MA",
    city_name: "Wendell",
  },
  {
    postal_code: "23325",
    full_city_name: "Chesapeake, VA",
    city_name: "Chesapeake",
  },
  {
    postal_code: "35585",
    full_city_name: "Spruce Pine, AL",
    city_name: "Spruce Pine",
  },
  {
    postal_code: "30471",
    full_city_name: "Twin City, GA",
    city_name: "Twin City",
  },
  {
    postal_code: "61232",
    full_city_name: "Andalusia, IL",
    city_name: "Andalusia",
  },
  {
    postal_code: "87347",
    full_city_name: "Jamestown, NM",
    city_name: "Jamestown",
  },
  {
    postal_code: "28320",
    full_city_name: "Bladenboro, NC",
    city_name: "Bladenboro",
  },
  {
    postal_code: "54962",
    full_city_name: "Ogdensburg, WI",
    city_name: "Ogdensburg",
  },
  {
    postal_code: "49970",
    full_city_name: "Watton, MI",
    city_name: "Watton",
  },
  {
    postal_code: "62421",
    full_city_name: "Claremont, IL",
    city_name: "Claremont",
  },
  {
    postal_code: "08837",
    full_city_name: "Edison, NJ",
    city_name: "Edison",
  },
  {
    postal_code: "35458",
    full_city_name: "Elrod, AL",
    city_name: "Elrod",
  },
  {
    postal_code: "50247",
    full_city_name: "State Center, IA",
    city_name: "State Center",
  },
  {
    postal_code: "77659",
    full_city_name: "Sour Lake, TX",
    city_name: "Sour Lake",
  },
  {
    postal_code: "98649",
    full_city_name: "Toutle, WA",
    city_name: "Toutle",
  },
  {
    postal_code: "73540",
    full_city_name: "Faxon, OK",
    city_name: "Faxon",
  },
  {
    postal_code: "70630",
    full_city_name: "Bell City, LA",
    city_name: "Bell City",
  },
  {
    postal_code: "24168",
    full_city_name: "Stanleytown, VA",
    city_name: "Stanleytown",
  },
  {
    postal_code: "66528",
    full_city_name: "Quenemo, KS",
    city_name: "Quenemo",
  },
  {
    postal_code: "39862",
    full_city_name: "Leary, GA",
    city_name: "Leary",
  },
  {
    postal_code: "04607",
    full_city_name: "Gouldsboro, ME",
    city_name: "Gouldsboro",
  },
  {
    postal_code: "01342",
    full_city_name: "Deerfield, MA",
    city_name: "Deerfield",
  },
  {
    postal_code: "08887",
    full_city_name: "Three Bridges, NJ",
    city_name: "Three Bridges",
  },
  {
    postal_code: "31554",
    full_city_name: "Nicholls, GA",
    city_name: "Nicholls",
  },
  {
    postal_code: "07073",
    full_city_name: "East Rutherford, NJ",
    city_name: "East Rutherford",
  },
  {
    postal_code: "88028",
    full_city_name: "Cliff, NM",
    city_name: "Cliff",
  },
  {
    postal_code: "23663",
    full_city_name: "Hampton, VA",
    city_name: "Hampton",
  },
  {
    postal_code: "56521",
    full_city_name: "Callaway, MN",
    city_name: "Callaway",
  },
  {
    postal_code: "62428",
    full_city_name: "Greenup, IL",
    city_name: "Greenup",
  },
  {
    postal_code: "53202",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "05448",
    full_city_name: "East Fairfield, VT",
    city_name: "East Fairfield",
  },
  {
    postal_code: "74881",
    full_city_name: "Wellston, OK",
    city_name: "Wellston",
  },
  {
    postal_code: "49725",
    full_city_name: "De Tour Village, MI",
    city_name: "De Tour Village",
  },
  {
    postal_code: "74359",
    full_city_name: "Oaks, OK",
    city_name: "Oaks",
  },
  {
    postal_code: "61414",
    full_city_name: "Altona, IL",
    city_name: "Altona",
  },
  {
    postal_code: "52237",
    full_city_name: "Hopkinton, IA",
    city_name: "Hopkinton",
  },
  {
    postal_code: "56349",
    full_city_name: "Lowry, MN",
    city_name: "Lowry",
  },
  {
    postal_code: "89833",
    full_city_name: "Ruby Valley, NV",
    city_name: "Ruby Valley",
  },
  {
    postal_code: "58646",
    full_city_name: "Mott, ND",
    city_name: "Mott",
  },
  {
    postal_code: "38552",
    full_city_name: "Chestnut Mound, TN",
    city_name: "Chestnut Mound",
  },
  {
    postal_code: "58352",
    full_city_name: "Munich, ND",
    city_name: "Munich",
  },
  {
    postal_code: "41630",
    full_city_name: "Garrett, KY",
    city_name: "Garrett",
  },
  {
    postal_code: "16256",
    full_city_name: "Smicksburg, PA",
    city_name: "Smicksburg",
  },
  {
    postal_code: "83421",
    full_city_name: "Chester, ID",
    city_name: "Chester",
  },
  {
    postal_code: "71055",
    full_city_name: "Minden, LA",
    city_name: "Minden",
  },
  {
    postal_code: "68437",
    full_city_name: "Shubert, NE",
    city_name: "Shubert",
  },
  {
    postal_code: "18635",
    full_city_name: "Nescopeck, PA",
    city_name: "Nescopeck",
  },
  {
    postal_code: "26169",
    full_city_name: "Rockport, WV",
    city_name: "Rockport",
  },
  {
    postal_code: "98576",
    full_city_name: "Rainier, WA",
    city_name: "Rainier",
  },
  {
    postal_code: "15864",
    full_city_name: "Summerville, PA",
    city_name: "Summerville",
  },
  {
    postal_code: "04920",
    full_city_name: "Bingham, ME",
    city_name: "Bingham",
  },
  {
    postal_code: "42031",
    full_city_name: "Clinton, KY",
    city_name: "Clinton",
  },
  {
    postal_code: "59326",
    full_city_name: "Fallon, MT",
    city_name: "Fallon",
  },
  {
    postal_code: "64426",
    full_city_name: "Blythedale, MO",
    city_name: "Blythedale",
  },
  {
    postal_code: "62427",
    full_city_name: "Flat Rock, IL",
    city_name: "Flat Rock",
  },
  {
    postal_code: "50628",
    full_city_name: "Elma, IA",
    city_name: "Elma",
  },
  {
    postal_code: "87832",
    full_city_name: "San Antonio, NM",
    city_name: "San Antonio",
  },
  {
    postal_code: "12453",
    full_city_name: "Malden On Hudson, NY",
    city_name: "Malden On Hudson",
  },
  {
    postal_code: "63623",
    full_city_name: "Belleview, MO",
    city_name: "Belleview",
  },
  {
    postal_code: "42035",
    full_city_name: "Cunningham, KY",
    city_name: "Cunningham",
  },
  {
    postal_code: "52361",
    full_city_name: "Williamsburg, IA",
    city_name: "Williamsburg",
  },
  {
    postal_code: "15463",
    full_city_name: "Merrittstown, PA",
    city_name: "Merrittstown",
  },
  {
    postal_code: "42159",
    full_city_name: "Oakland, KY",
    city_name: "Oakland",
  },
  {
    postal_code: "51019",
    full_city_name: "Danbury, IA",
    city_name: "Danbury",
  },
  {
    postal_code: "39108",
    full_city_name: "Mc Cool, MS",
    city_name: "Mc Cool",
  },
  {
    postal_code: "78369",
    full_city_name: "Mirando City, TX",
    city_name: "Mirando City",
  },
  {
    postal_code: "82939",
    full_city_name: "Mountain View, WY",
    city_name: "Mountain View",
  },
  {
    postal_code: "35207",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "48614",
    full_city_name: "Brant, MI",
    city_name: "Brant",
  },
  {
    postal_code: "85328",
    full_city_name: "Cibola, AZ",
    city_name: "Cibola",
  },
  {
    postal_code: "13157",
    full_city_name: "Sylvan Beach, NY",
    city_name: "Sylvan Beach",
  },
  {
    postal_code: "38737",
    full_city_name: "Drew, MS",
    city_name: "Drew",
  },
  {
    postal_code: "47581",
    full_city_name: "Shoals, IN",
    city_name: "Shoals",
  },
  {
    postal_code: "04911",
    full_city_name: "Anson, ME",
    city_name: "Anson",
  },
  {
    postal_code: "14592",
    full_city_name: "York, NY",
    city_name: "York",
  },
  {
    postal_code: "31647",
    full_city_name: "Sparks, GA",
    city_name: "Sparks",
  },
  {
    postal_code: "79745",
    full_city_name: "Kermit, TX",
    city_name: "Kermit",
  },
  {
    postal_code: "78549",
    full_city_name: "Hargill, TX",
    city_name: "Hargill",
  },
  {
    postal_code: "76377",
    full_city_name: "Petrolia, TX",
    city_name: "Petrolia",
  },
  {
    postal_code: "17093",
    full_city_name: "Summerdale, PA",
    city_name: "Summerdale",
  },
  {
    postal_code: "68729",
    full_city_name: "Creighton, NE",
    city_name: "Creighton",
  },
  {
    postal_code: "05463",
    full_city_name: "Isle La Motte, VT",
    city_name: "Isle La Motte",
  },
  {
    postal_code: "47601",
    full_city_name: "Boonville, IN",
    city_name: "Boonville",
  },
  {
    postal_code: "77623",
    full_city_name: "High Island, TX",
    city_name: "High Island",
  },
  {
    postal_code: "69345",
    full_city_name: "Harrisburg, NE",
    city_name: "Harrisburg",
  },
  {
    postal_code: "41204",
    full_city_name: "Boons Camp, KY",
    city_name: "Boons Camp",
  },
  {
    postal_code: "71280",
    full_city_name: "Sterlington, LA",
    city_name: "Sterlington",
  },
  {
    postal_code: "62987",
    full_city_name: "Stonefort, IL",
    city_name: "Stonefort",
  },
  {
    postal_code: "15532",
    full_city_name: "Boynton, PA",
    city_name: "Boynton",
  },
  {
    postal_code: "38485",
    full_city_name: "Waynesboro, TN",
    city_name: "Waynesboro",
  },
  {
    postal_code: "67632",
    full_city_name: "Damar, KS",
    city_name: "Damar",
  },
  {
    postal_code: "26534",
    full_city_name: "Granville, WV",
    city_name: "Granville",
  },
  {
    postal_code: "12427",
    full_city_name: "Elka Park, NY",
    city_name: "Elka Park",
  },
  {
    postal_code: "68381",
    full_city_name: "Liberty, NE",
    city_name: "Liberty",
  },
  {
    postal_code: "36467",
    full_city_name: "Opp, AL",
    city_name: "Opp",
  },
  {
    postal_code: "47885",
    full_city_name: "West Terre Haute, IN",
    city_name: "West Terre Haute",
  },
  {
    postal_code: "15724",
    full_city_name: "Cherry Tree, PA",
    city_name: "Cherry Tree",
  },
  {
    postal_code: "62988",
    full_city_name: "Tamms, IL",
    city_name: "Tamms",
  },
  {
    postal_code: "42451",
    full_city_name: "Reed, KY",
    city_name: "Reed",
  },
  {
    postal_code: "72112",
    full_city_name: "Newport, AR",
    city_name: "Newport",
  },
  {
    postal_code: "74565",
    full_city_name: "Savanna, OK",
    city_name: "Savanna",
  },
  {
    postal_code: "97497",
    full_city_name: "Wolf Creek, OR",
    city_name: "Wolf Creek",
  },
  {
    postal_code: "16861",
    full_city_name: "New Millport, PA",
    city_name: "New Millport",
  },
  {
    postal_code: "23833",
    full_city_name: "Church Road, VA",
    city_name: "Church Road",
  },
  {
    postal_code: "05640",
    full_city_name: "Adamant, VT",
    city_name: "Adamant",
  },
  {
    postal_code: "22749",
    full_city_name: "Woodville, VA",
    city_name: "Woodville",
  },
  {
    postal_code: "49347",
    full_city_name: "Trufant, MI",
    city_name: "Trufant",
  },
  {
    postal_code: "59214",
    full_city_name: "Brockway, MT",
    city_name: "Brockway",
  },
  {
    postal_code: "58227",
    full_city_name: "Edinburg, ND",
    city_name: "Edinburg",
  },
  {
    postal_code: "25201",
    full_city_name: "Tad, WV",
    city_name: "Tad",
  },
  {
    postal_code: "38554",
    full_city_name: "Crawford, TN",
    city_name: "Crawford",
  },
  {
    postal_code: "78393",
    full_city_name: "Woodsboro, TX",
    city_name: "Woodsboro",
  },
  {
    postal_code: "77585",
    full_city_name: "Saratoga, TX",
    city_name: "Saratoga",
  },
  {
    postal_code: "15920",
    full_city_name: "Armagh, PA",
    city_name: "Armagh",
  },
  {
    postal_code: "36375",
    full_city_name: "Slocomb, AL",
    city_name: "Slocomb",
  },
  {
    postal_code: "61337",
    full_city_name: "Malden, IL",
    city_name: "Malden",
  },
  {
    postal_code: "36619",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "62934",
    full_city_name: "Equality, IL",
    city_name: "Equality",
  },
  {
    postal_code: "15866",
    full_city_name: "Troutville, PA",
    city_name: "Troutville",
  },
  {
    postal_code: "68745",
    full_city_name: "Laurel, NE",
    city_name: "Laurel",
  },
  {
    postal_code: "76460",
    full_city_name: "Loving, TX",
    city_name: "Loving",
  },
  {
    postal_code: "12785",
    full_city_name: "Westbrookville, NY",
    city_name: "Westbrookville",
  },
  {
    postal_code: "51016",
    full_city_name: "Correctionville, IA",
    city_name: "Correctionville",
  },
  {
    postal_code: "24580",
    full_city_name: "Nelson, VA",
    city_name: "Nelson",
  },
  {
    postal_code: "59934",
    full_city_name: "Trego, MT",
    city_name: "Trego",
  },
  {
    postal_code: "13863",
    full_city_name: "Willet, NY",
    city_name: "Willet",
  },
  {
    postal_code: "43747",
    full_city_name: "Jerusalem, OH",
    city_name: "Jerusalem",
  },
  {
    postal_code: "89003",
    full_city_name: "Beatty, NV",
    city_name: "Beatty",
  },
  {
    postal_code: "81649",
    full_city_name: "Red Cliff, CO",
    city_name: "Red Cliff",
  },
  {
    postal_code: "14026",
    full_city_name: "Bowmansville, NY",
    city_name: "Bowmansville",
  },
  {
    postal_code: "50515",
    full_city_name: "Ayrshire, IA",
    city_name: "Ayrshire",
  },
  {
    postal_code: "29082",
    full_city_name: "Lodge, SC",
    city_name: "Lodge",
  },
  {
    postal_code: "62361",
    full_city_name: "Pearl, IL",
    city_name: "Pearl",
  },
  {
    postal_code: "62667",
    full_city_name: "Modesto, IL",
    city_name: "Modesto",
  },
  {
    postal_code: "24283",
    full_city_name: "Saint Paul, VA",
    city_name: "Saint Paul",
  },
  {
    postal_code: "88056",
    full_city_name: "Rodeo, NM",
    city_name: "Rodeo",
  },
  {
    postal_code: "23462",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "56332",
    full_city_name: "Garfield, MN",
    city_name: "Garfield",
  },
  {
    postal_code: "14824",
    full_city_name: "Cayuta, NY",
    city_name: "Cayuta",
  },
  {
    postal_code: "24712",
    full_city_name: "Athens, WV",
    city_name: "Athens",
  },
  {
    postal_code: "07040",
    full_city_name: "Maplewood, NJ",
    city_name: "Maplewood",
  },
  {
    postal_code: "23148",
    full_city_name: "Saint Stephens Church, VA",
    city_name: "Saint Stephens Church",
  },
  {
    postal_code: "21644",
    full_city_name: "Ingleside, MD",
    city_name: "Ingleside",
  },
  {
    postal_code: "26684",
    full_city_name: "Pool, WV",
    city_name: "Pool",
  },
  {
    postal_code: "56235",
    full_city_name: "Donnelly, MN",
    city_name: "Donnelly",
  },
  {
    postal_code: "82221",
    full_city_name: "Lagrange, WY",
    city_name: "Lagrange",
  },
  {
    postal_code: "62424",
    full_city_name: "Dieterich, IL",
    city_name: "Dieterich",
  },
  {
    postal_code: "13065",
    full_city_name: "Fayette, NY",
    city_name: "Fayette",
  },
  {
    postal_code: "71845",
    full_city_name: "Lewisville, AR",
    city_name: "Lewisville",
  },
  {
    postal_code: "64671",
    full_city_name: "Polo, MO",
    city_name: "Polo",
  },
  {
    postal_code: "44634",
    full_city_name: "Homeworth, OH",
    city_name: "Homeworth",
  },
  {
    postal_code: "87712",
    full_city_name: "Buena Vista, NM",
    city_name: "Buena Vista",
  },
  {
    postal_code: "51109",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "96017",
    full_city_name: "Castella, CA",
    city_name: "Castella",
  },
  {
    postal_code: "35619",
    full_city_name: "Danville, AL",
    city_name: "Danville",
  },
  {
    postal_code: "21734",
    full_city_name: "Funkstown, MD",
    city_name: "Funkstown",
  },
  {
    postal_code: "83445",
    full_city_name: "Saint Anthony, ID",
    city_name: "Saint Anthony",
  },
  {
    postal_code: "57273",
    full_city_name: "Waubay, SD",
    city_name: "Waubay",
  },
  {
    postal_code: "30751",
    full_city_name: "Tennga, GA",
    city_name: "Tennga",
  },
  {
    postal_code: "61848",
    full_city_name: "Henning, IL",
    city_name: "Henning",
  },
  {
    postal_code: "14815",
    full_city_name: "Bradford, NY",
    city_name: "Bradford",
  },
  {
    postal_code: "97020",
    full_city_name: "Donald, OR",
    city_name: "Donald",
  },
  {
    postal_code: "67868",
    full_city_name: "Pierceville, KS",
    city_name: "Pierceville",
  },
  {
    postal_code: "54006",
    full_city_name: "Cushing, WI",
    city_name: "Cushing",
  },
  {
    postal_code: "21130",
    full_city_name: "Perryman, MD",
    city_name: "Perryman",
  },
  {
    postal_code: "76569",
    full_city_name: "Rogers, TX",
    city_name: "Rogers",
  },
  {
    postal_code: "47833",
    full_city_name: "Bowling Green, IN",
    city_name: "Bowling Green",
  },
  {
    postal_code: "28438",
    full_city_name: "Evergreen, NC",
    city_name: "Evergreen",
  },
  {
    postal_code: "73165",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "47147",
    full_city_name: "Nabb, IN",
    city_name: "Nabb",
  },
  {
    postal_code: "27875",
    full_city_name: "Scranton, NC",
    city_name: "Scranton",
  },
  {
    postal_code: "81024",
    full_city_name: "Boncarbo, CO",
    city_name: "Boncarbo",
  },
  {
    postal_code: "39480",
    full_city_name: "Soso, MS",
    city_name: "Soso",
  },
  {
    postal_code: "12808",
    full_city_name: "Adirondack, NY",
    city_name: "Adirondack",
  },
  {
    postal_code: "84540",
    full_city_name: "Thompson, UT",
    city_name: "Thompson",
  },
  {
    postal_code: "97824",
    full_city_name: "Cove, OR",
    city_name: "Cove",
  },
  {
    postal_code: "46302",
    full_city_name: "Boone Grove, IN",
    city_name: "Boone Grove",
  },
  {
    postal_code: "10974",
    full_city_name: "Sloatsburg, NY",
    city_name: "Sloatsburg",
  },
  {
    postal_code: "42371",
    full_city_name: "Rumsey, KY",
    city_name: "Rumsey",
  },
  {
    postal_code: "16627",
    full_city_name: "Coalport, PA",
    city_name: "Coalport",
  },
  {
    postal_code: "30807",
    full_city_name: "Camak, GA",
    city_name: "Camak",
  },
  {
    postal_code: "51028",
    full_city_name: "Kingsley, IA",
    city_name: "Kingsley",
  },
  {
    postal_code: "78608",
    full_city_name: "Briggs, TX",
    city_name: "Briggs",
  },
  {
    postal_code: "19456",
    full_city_name: "Oaks, PA",
    city_name: "Oaks",
  },
  {
    postal_code: "83443",
    full_city_name: "Ririe, ID",
    city_name: "Ririe",
  },
  {
    postal_code: "72935",
    full_city_name: "Dyer, AR",
    city_name: "Dyer",
  },
  {
    postal_code: "18644",
    full_city_name: "Wyoming, PA",
    city_name: "Wyoming",
  },
  {
    postal_code: "04668",
    full_city_name: "Princeton, ME",
    city_name: "Princeton",
  },
  {
    postal_code: "51649",
    full_city_name: "Randolph, IA",
    city_name: "Randolph",
  },
  {
    postal_code: "56314",
    full_city_name: "Bowlus, MN",
    city_name: "Bowlus",
  },
  {
    postal_code: "70393",
    full_city_name: "Plattenville, LA",
    city_name: "Plattenville",
  },
  {
    postal_code: "70812",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "66775",
    full_city_name: "Stark, KS",
    city_name: "Stark",
  },
  {
    postal_code: "87023",
    full_city_name: "Jarales, NM",
    city_name: "Jarales",
  },
  {
    postal_code: "04735",
    full_city_name: "Bridgewater, ME",
    city_name: "Bridgewater",
  },
  {
    postal_code: "23607",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "64432",
    full_city_name: "Clyde, MO",
    city_name: "Clyde",
  },
  {
    postal_code: "49755",
    full_city_name: "Levering, MI",
    city_name: "Levering",
  },
  {
    postal_code: "62612",
    full_city_name: "Ashland, IL",
    city_name: "Ashland",
  },
  {
    postal_code: "13675",
    full_city_name: "Plessis, NY",
    city_name: "Plessis",
  },
  {
    postal_code: "17550",
    full_city_name: "Maytown, PA",
    city_name: "Maytown",
  },
  {
    postal_code: "98402",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "30420",
    full_city_name: "Cobbtown, GA",
    city_name: "Cobbtown",
  },
  {
    postal_code: "72072",
    full_city_name: "Humnoke, AR",
    city_name: "Humnoke",
  },
  {
    postal_code: "48728",
    full_city_name: "Curran, MI",
    city_name: "Curran",
  },
  {
    postal_code: "85172",
    full_city_name: "Stanfield, AZ",
    city_name: "Stanfield",
  },
  {
    postal_code: "35907",
    full_city_name: "Gadsden, AL",
    city_name: "Gadsden",
  },
  {
    postal_code: "76664",
    full_city_name: "Mart, TX",
    city_name: "Mart",
  },
  {
    postal_code: "53120",
    full_city_name: "East Troy, WI",
    city_name: "East Troy",
  },
  {
    postal_code: "23063",
    full_city_name: "Goochland, VA",
    city_name: "Goochland",
  },
  {
    postal_code: "98639",
    full_city_name: "North Bonneville, WA",
    city_name: "North Bonneville",
  },
  {
    postal_code: "57353",
    full_city_name: "Iroquois, SD",
    city_name: "Iroquois",
  },
  {
    postal_code: "78357",
    full_city_name: "Freer, TX",
    city_name: "Freer",
  },
  {
    postal_code: "07980",
    full_city_name: "Stirling, NJ",
    city_name: "Stirling",
  },
  {
    postal_code: "07418",
    full_city_name: "Glenwood, NJ",
    city_name: "Glenwood",
  },
  {
    postal_code: "30746",
    full_city_name: "Sugar Valley, GA",
    city_name: "Sugar Valley",
  },
  {
    postal_code: "50516",
    full_city_name: "Badger, IA",
    city_name: "Badger",
  },
  {
    postal_code: "26335",
    full_city_name: "Burnsville, WV",
    city_name: "Burnsville",
  },
  {
    postal_code: "41121",
    full_city_name: "Argillite, KY",
    city_name: "Argillite",
  },
  {
    postal_code: "49089",
    full_city_name: "Sherwood, MI",
    city_name: "Sherwood",
  },
  {
    postal_code: "87569",
    full_city_name: "Serafina, NM",
    city_name: "Serafina",
  },
  {
    postal_code: "28685",
    full_city_name: "Traphill, NC",
    city_name: "Traphill",
  },
  {
    postal_code: "54441",
    full_city_name: "Hewitt, WI",
    city_name: "Hewitt",
  },
  {
    postal_code: "29831",
    full_city_name: "Jackson, SC",
    city_name: "Jackson",
  },
  {
    postal_code: "58218",
    full_city_name: "Buxton, ND",
    city_name: "Buxton",
  },
  {
    postal_code: "79097",
    full_city_name: "White Deer, TX",
    city_name: "White Deer",
  },
  {
    postal_code: "58339",
    full_city_name: "Hansboro, ND",
    city_name: "Hansboro",
  },
  {
    postal_code: "80745",
    full_city_name: "Padroni, CO",
    city_name: "Padroni",
  },
  {
    postal_code: "46154",
    full_city_name: "Maxwell, IN",
    city_name: "Maxwell",
  },
  {
    postal_code: "25534",
    full_city_name: "Kiahsville, WV",
    city_name: "Kiahsville",
  },
  {
    postal_code: "38478",
    full_city_name: "Pulaski, TN",
    city_name: "Pulaski",
  },
  {
    postal_code: "73757",
    full_city_name: "Lucien, OK",
    city_name: "Lucien",
  },
  {
    postal_code: "68640",
    full_city_name: "Genoa, NE",
    city_name: "Genoa",
  },
  {
    postal_code: "25989",
    full_city_name: "White Oak, WV",
    city_name: "White Oak",
  },
  {
    postal_code: "54412",
    full_city_name: "Auburndale, WI",
    city_name: "Auburndale",
  },
  {
    postal_code: "51241",
    full_city_name: "Larchwood, IA",
    city_name: "Larchwood",
  },
  {
    postal_code: "29377",
    full_city_name: "Startex, SC",
    city_name: "Startex",
  },
  {
    postal_code: "25521",
    full_city_name: "Griffithsville, WV",
    city_name: "Griffithsville",
  },
  {
    postal_code: "97434",
    full_city_name: "Dorena, OR",
    city_name: "Dorena",
  },
  {
    postal_code: "51024",
    full_city_name: "Hinton, IA",
    city_name: "Hinton",
  },
  {
    postal_code: "56111",
    full_city_name: "Alpha, MN",
    city_name: "Alpha",
  },
  {
    postal_code: "79087",
    full_city_name: "Texline, TX",
    city_name: "Texline",
  },
  {
    postal_code: "31805",
    full_city_name: "Cusseta, GA",
    city_name: "Cusseta",
  },
  {
    postal_code: "65257",
    full_city_name: "Higbee, MO",
    city_name: "Higbee",
  },
  {
    postal_code: "50480",
    full_city_name: "Titonka, IA",
    city_name: "Titonka",
  },
  {
    postal_code: "41317",
    full_city_name: "Clayhole, KY",
    city_name: "Clayhole",
  },
  {
    postal_code: "99827",
    full_city_name: "Haines, AK",
    city_name: "Haines",
  },
  {
    postal_code: "08528",
    full_city_name: "Kingston, NJ",
    city_name: "Kingston",
  },
  {
    postal_code: "47574",
    full_city_name: "Rome, IN",
    city_name: "Rome",
  },
  {
    postal_code: "49279",
    full_city_name: "Sand Creek, MI",
    city_name: "Sand Creek",
  },
  {
    postal_code: "43768",
    full_city_name: "Old Washington, OH",
    city_name: "Old Washington",
  },
  {
    postal_code: "23079",
    full_city_name: "Jamaica, VA",
    city_name: "Jamaica",
  },
  {
    postal_code: "38046",
    full_city_name: "La Grange, TN",
    city_name: "La Grange",
  },
  {
    postal_code: "38231",
    full_city_name: "Henry, TN",
    city_name: "Henry",
  },
  {
    postal_code: "51340",
    full_city_name: "Fostoria, IA",
    city_name: "Fostoria",
  },
  {
    postal_code: "48529",
    full_city_name: "Burton, MI",
    city_name: "Burton",
  },
  {
    postal_code: "50008",
    full_city_name: "Allerton, IA",
    city_name: "Allerton",
  },
  {
    postal_code: "61851",
    full_city_name: "Ivesdale, IL",
    city_name: "Ivesdale",
  },
  {
    postal_code: "14210",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "71068",
    full_city_name: "Ringgold, LA",
    city_name: "Ringgold",
  },
  {
    postal_code: "04653",
    full_city_name: "Bass Harbor, ME",
    city_name: "Bass Harbor",
  },
  {
    postal_code: "19436",
    full_city_name: "Gwynedd, PA",
    city_name: "Gwynedd",
  },
  {
    postal_code: "47243",
    full_city_name: "Hanover, IN",
    city_name: "Hanover",
  },
  {
    postal_code: "50104",
    full_city_name: "Gibson, IA",
    city_name: "Gibson",
  },
  {
    postal_code: "49326",
    full_city_name: "Gowen, MI",
    city_name: "Gowen",
  },
  {
    postal_code: "78860",
    full_city_name: "El Indio, TX",
    city_name: "El Indio",
  },
  {
    postal_code: "63852",
    full_city_name: "Holcomb, MO",
    city_name: "Holcomb",
  },
  {
    postal_code: "04449",
    full_city_name: "Hudson, ME",
    city_name: "Hudson",
  },
  {
    postal_code: "62517",
    full_city_name: "Bulpitt, IL",
    city_name: "Bulpitt",
  },
  {
    postal_code: "35752",
    full_city_name: "Hollywood, AL",
    city_name: "Hollywood",
  },
  {
    postal_code: "58230",
    full_city_name: "Finley, ND",
    city_name: "Finley",
  },
  {
    postal_code: "39067",
    full_city_name: "Ethel, MS",
    city_name: "Ethel",
  },
  {
    postal_code: "50522",
    full_city_name: "Burt, IA",
    city_name: "Burt",
  },
  {
    postal_code: "76937",
    full_city_name: "Eola, TX",
    city_name: "Eola",
  },
  {
    postal_code: "13034",
    full_city_name: "Cayuga, NY",
    city_name: "Cayuga",
  },
  {
    postal_code: "66846",
    full_city_name: "Council Grove, KS",
    city_name: "Council Grove",
  },
  {
    postal_code: "41149",
    full_city_name: "Isonville, KY",
    city_name: "Isonville",
  },
  {
    postal_code: "68781",
    full_city_name: "Tilden, NE",
    city_name: "Tilden",
  },
  {
    postal_code: "35586",
    full_city_name: "Sulligent, AL",
    city_name: "Sulligent",
  },
  {
    postal_code: "52550",
    full_city_name: "Delta, IA",
    city_name: "Delta",
  },
  {
    postal_code: "07981",
    full_city_name: "Whippany, NJ",
    city_name: "Whippany",
  },
  {
    postal_code: "67430",
    full_city_name: "Cawker City, KS",
    city_name: "Cawker City",
  },
  {
    postal_code: "15849",
    full_city_name: "Penfield, PA",
    city_name: "Penfield",
  },
  {
    postal_code: "36539",
    full_city_name: "Fruitdale, AL",
    city_name: "Fruitdale",
  },
  {
    postal_code: "07977",
    full_city_name: "Peapack, NJ",
    city_name: "Peapack",
  },
  {
    postal_code: "70504",
    full_city_name: "Lafayette, LA",
    city_name: "Lafayette",
  },
  {
    postal_code: "56270",
    full_city_name: "Morton, MN",
    city_name: "Morton",
  },
  {
    postal_code: "89414",
    full_city_name: "Golconda, NV",
    city_name: "Golconda",
  },
  {
    postal_code: "67764",
    full_city_name: "Winona, KS",
    city_name: "Winona",
  },
  {
    postal_code: "99576",
    full_city_name: "Dillingham, AK",
    city_name: "Dillingham",
  },
  {
    postal_code: "15641",
    full_city_name: "Hyde Park, PA",
    city_name: "Hyde Park",
  },
  {
    postal_code: "52327",
    full_city_name: "Riverside, IA",
    city_name: "Riverside",
  },
  {
    postal_code: "50848",
    full_city_name: "Gravity, IA",
    city_name: "Gravity",
  },
  {
    postal_code: "84749",
    full_city_name: "Lyman, UT",
    city_name: "Lyman",
  },
  {
    postal_code: "76629",
    full_city_name: "Bremond, TX",
    city_name: "Bremond",
  },
  {
    postal_code: "62567",
    full_city_name: "Stonington, IL",
    city_name: "Stonington",
  },
  {
    postal_code: "52162",
    full_city_name: "Postville, IA",
    city_name: "Postville",
  },
  {
    postal_code: "79312",
    full_city_name: "Amherst, TX",
    city_name: "Amherst",
  },
  {
    postal_code: "97050",
    full_city_name: "Rufus, OR",
    city_name: "Rufus",
  },
  {
    postal_code: "06027",
    full_city_name: "East Hartland, CT",
    city_name: "East Hartland",
  },
  {
    postal_code: "12748",
    full_city_name: "Jeffersonville, NY",
    city_name: "Jeffersonville",
  },
  {
    postal_code: "48215",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "59732",
    full_city_name: "Glen, MT",
    city_name: "Glen",
  },
  {
    postal_code: "68330",
    full_city_name: "Cordova, NE",
    city_name: "Cordova",
  },
  {
    postal_code: "04050",
    full_city_name: "Long Island, ME",
    city_name: "Long Island",
  },
  {
    postal_code: "23898",
    full_city_name: "Zuni, VA",
    city_name: "Zuni",
  },
  {
    postal_code: "65039",
    full_city_name: "Hartsburg, MO",
    city_name: "Hartsburg",
  },
  {
    postal_code: "47647",
    full_city_name: "Buckskin, IN",
    city_name: "Buckskin",
  },
  {
    postal_code: "62838",
    full_city_name: "Farina, IL",
    city_name: "Farina",
  },
  {
    postal_code: "53015",
    full_city_name: "Cleveland, WI",
    city_name: "Cleveland",
  },
  {
    postal_code: "35587",
    full_city_name: "Townley, AL",
    city_name: "Townley",
  },
  {
    postal_code: "69038",
    full_city_name: "Maywood, NE",
    city_name: "Maywood",
  },
  {
    postal_code: "73079",
    full_city_name: "Pocasset, OK",
    city_name: "Pocasset",
  },
  {
    postal_code: "44802",
    full_city_name: "Alvada, OH",
    city_name: "Alvada",
  },
  {
    postal_code: "68328",
    full_city_name: "Clatonia, NE",
    city_name: "Clatonia",
  },
  {
    postal_code: "98286",
    full_city_name: "Shaw Island, WA",
    city_name: "Shaw Island",
  },
  {
    postal_code: "44854",
    full_city_name: "New Washington, OH",
    city_name: "New Washington",
  },
  {
    postal_code: "74072",
    full_city_name: "S Coffeyville, OK",
    city_name: "S Coffeyville",
  },
  {
    postal_code: "79544",
    full_city_name: "Rochester, TX",
    city_name: "Rochester",
  },
  {
    postal_code: "26525",
    full_city_name: "Bruceton Mills, WV",
    city_name: "Bruceton Mills",
  },
  {
    postal_code: "82644",
    full_city_name: "Mills, WY",
    city_name: "Mills",
  },
  {
    postal_code: "47846",
    full_city_name: "Cory, IN",
    city_name: "Cory",
  },
  {
    postal_code: "73111",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "73843",
    full_city_name: "Gage, OK",
    city_name: "Gage",
  },
  {
    postal_code: "97622",
    full_city_name: "Bly, OR",
    city_name: "Bly",
  },
  {
    postal_code: "74843",
    full_city_name: "Fitzhugh, OK",
    city_name: "Fitzhugh",
  },
  {
    postal_code: "71367",
    full_city_name: "Saint Landry, LA",
    city_name: "Saint Landry",
  },
  {
    postal_code: "15647",
    full_city_name: "Larimer, PA",
    city_name: "Larimer",
  },
  {
    postal_code: "61476",
    full_city_name: "Seaton, IL",
    city_name: "Seaton",
  },
  {
    postal_code: "10546",
    full_city_name: "Millwood, NY",
    city_name: "Millwood",
  },
  {
    postal_code: "39886",
    full_city_name: "Shellman, GA",
    city_name: "Shellman",
  },
  {
    postal_code: "87715",
    full_city_name: "Cleveland, NM",
    city_name: "Cleveland",
  },
  {
    postal_code: "68957",
    full_city_name: "Lawrence, NE",
    city_name: "Lawrence",
  },
  {
    postal_code: "05820",
    full_city_name: "Albany, VT",
    city_name: "Albany",
  },
  {
    postal_code: "35966",
    full_city_name: "Flat Rock, AL",
    city_name: "Flat Rock",
  },
  {
    postal_code: "46531",
    full_city_name: "Grovertown, IN",
    city_name: "Grovertown",
  },
  {
    postal_code: "99131",
    full_city_name: "Gifford, WA",
    city_name: "Gifford",
  },
  {
    postal_code: "98643",
    full_city_name: "Rosburg, WA",
    city_name: "Rosburg",
  },
  {
    postal_code: "16840",
    full_city_name: "Hawk Run, PA",
    city_name: "Hawk Run",
  },
  {
    postal_code: "23126",
    full_city_name: "Newtown, VA",
    city_name: "Newtown",
  },
  {
    postal_code: "97430",
    full_city_name: "Deadwood, OR",
    city_name: "Deadwood",
  },
  {
    postal_code: "92060",
    full_city_name: "Palomar Mountain, CA",
    city_name: "Palomar Mountain",
  },
  {
    postal_code: "51040",
    full_city_name: "Onawa, IA",
    city_name: "Onawa",
  },
  {
    postal_code: "39166",
    full_city_name: "Silver City, MS",
    city_name: "Silver City",
  },
  {
    postal_code: "38844",
    full_city_name: "Gattman, MS",
    city_name: "Gattman",
  },
  {
    postal_code: "63633",
    full_city_name: "Centerville, MO",
    city_name: "Centerville",
  },
  {
    postal_code: "95039",
    full_city_name: "Moss Landing, CA",
    city_name: "Moss Landing",
  },
  {
    postal_code: "88027",
    full_city_name: "Chamberino, NM",
    city_name: "Chamberino",
  },
  {
    postal_code: "61770",
    full_city_name: "Saybrook, IL",
    city_name: "Saybrook",
  },
  {
    postal_code: "30177",
    full_city_name: "Tate, GA",
    city_name: "Tate",
  },
  {
    postal_code: "60973",
    full_city_name: "Wellington, IL",
    city_name: "Wellington",
  },
  {
    postal_code: "58374",
    full_city_name: "Sheyenne, ND",
    city_name: "Sheyenne",
  },
  {
    postal_code: "78557",
    full_city_name: "Hidalgo, TX",
    city_name: "Hidalgo",
  },
  {
    postal_code: "46076",
    full_city_name: "Windfall, IN",
    city_name: "Windfall",
  },
  {
    postal_code: "96096",
    full_city_name: "Whitmore, CA",
    city_name: "Whitmore",
  },
  {
    postal_code: "42254",
    full_city_name: "La Fayette, KY",
    city_name: "La Fayette",
  },
  {
    postal_code: "87566",
    full_city_name: "Ohkay Owingeh, NM",
    city_name: "Ohkay Owingeh",
  },
  {
    postal_code: "67223",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "05775",
    full_city_name: "West Pawlet, VT",
    city_name: "West Pawlet",
  },
  {
    postal_code: "61957",
    full_city_name: "Windsor, IL",
    city_name: "Windsor",
  },
  {
    postal_code: "02918",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "16372",
    full_city_name: "Clintonville, PA",
    city_name: "Clintonville",
  },
  {
    postal_code: "14516",
    full_city_name: "North Rose, NY",
    city_name: "North Rose",
  },
  {
    postal_code: "49029",
    full_city_name: "Burlington, MI",
    city_name: "Burlington",
  },
  {
    postal_code: "53817",
    full_city_name: "Patch Grove, WI",
    city_name: "Patch Grove",
  },
  {
    postal_code: "21529",
    full_city_name: "Ellerslie, MD",
    city_name: "Ellerslie",
  },
  {
    postal_code: "07031",
    full_city_name: "North Arlington, NJ",
    city_name: "North Arlington",
  },
  {
    postal_code: "68660",
    full_city_name: "Saint Edward, NE",
    city_name: "Saint Edward",
  },
  {
    postal_code: "98650",
    full_city_name: "Trout Lake, WA",
    city_name: "Trout Lake",
  },
  {
    postal_code: "38079",
    full_city_name: "Tiptonville, TN",
    city_name: "Tiptonville",
  },
  {
    postal_code: "64659",
    full_city_name: "Meadville, MO",
    city_name: "Meadville",
  },
  {
    postal_code: "66834",
    full_city_name: "Alta Vista, KS",
    city_name: "Alta Vista",
  },
  {
    postal_code: "26074",
    full_city_name: "West Liberty, WV",
    city_name: "West Liberty",
  },
  {
    postal_code: "59625",
    full_city_name: "Helena, MT",
    city_name: "Helena",
  },
  {
    postal_code: "96039",
    full_city_name: "Happy Camp, CA",
    city_name: "Happy Camp",
  },
  {
    postal_code: "92007",
    full_city_name: "Cardiff By The Sea, CA",
    city_name: "Cardiff By The Sea",
  },
  {
    postal_code: "67523",
    full_city_name: "Burdett, KS",
    city_name: "Burdett",
  },
  {
    postal_code: "41234",
    full_city_name: "Meally, KY",
    city_name: "Meally",
  },
  {
    postal_code: "23308",
    full_city_name: "Bloxom, VA",
    city_name: "Bloxom",
  },
  {
    postal_code: "57012",
    full_city_name: "Canistota, SD",
    city_name: "Canistota",
  },
  {
    postal_code: "18085",
    full_city_name: "Tatamy, PA",
    city_name: "Tatamy",
  },
  {
    postal_code: "62880",
    full_city_name: "Saint Peter, IL",
    city_name: "Saint Peter",
  },
  {
    postal_code: "14141",
    full_city_name: "Springville, NY",
    city_name: "Springville",
  },
  {
    postal_code: "50635",
    full_city_name: "Gladbrook, IA",
    city_name: "Gladbrook",
  },
  {
    postal_code: "10464",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "25839",
    full_city_name: "Fairdale, WV",
    city_name: "Fairdale",
  },
  {
    postal_code: "56654",
    full_city_name: "Loman, MN",
    city_name: "Loman",
  },
  {
    postal_code: "98134",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "74738",
    full_city_name: "Grant, OK",
    city_name: "Grant",
  },
  {
    postal_code: "62899",
    full_city_name: "Xenia, IL",
    city_name: "Xenia",
  },
  {
    postal_code: "59259",
    full_city_name: "Richey, MT",
    city_name: "Richey",
  },
  {
    postal_code: "04481",
    full_city_name: "Sebec, ME",
    city_name: "Sebec",
  },
  {
    postal_code: "47011",
    full_city_name: "Bennington, IN",
    city_name: "Bennington",
  },
  {
    postal_code: "88348",
    full_city_name: "San Patricio, NM",
    city_name: "San Patricio",
  },
  {
    postal_code: "65564",
    full_city_name: "Solo, MO",
    city_name: "Solo",
  },
  {
    postal_code: "50031",
    full_city_name: "Beaver, IA",
    city_name: "Beaver",
  },
  {
    postal_code: "64674",
    full_city_name: "Purdin, MO",
    city_name: "Purdin",
  },
  {
    postal_code: "17727",
    full_city_name: "Cedar Run, PA",
    city_name: "Cedar Run",
  },
  {
    postal_code: "21871",
    full_city_name: "Westover, MD",
    city_name: "Westover",
  },
  {
    postal_code: "65035",
    full_city_name: "Freeburg, MO",
    city_name: "Freeburg",
  },
  {
    postal_code: "24882",
    full_city_name: "Simon, WV",
    city_name: "Simon",
  },
  {
    postal_code: "19931",
    full_city_name: "Bethel, DE",
    city_name: "Bethel",
  },
  {
    postal_code: "36041",
    full_city_name: "Highland Home, AL",
    city_name: "Highland Home",
  },
  {
    postal_code: "23410",
    full_city_name: "Melfa, VA",
    city_name: "Melfa",
  },
  {
    postal_code: "61070",
    full_city_name: "Rock City, IL",
    city_name: "Rock City",
  },
  {
    postal_code: "79506",
    full_city_name: "Blackwell, TX",
    city_name: "Blackwell",
  },
  {
    postal_code: "89019",
    full_city_name: "Jean, NV",
    city_name: "Jean",
  },
  {
    postal_code: "56170",
    full_city_name: "Ruthton, MN",
    city_name: "Ruthton",
  },
  {
    postal_code: "67849",
    full_city_name: "Hanston, KS",
    city_name: "Hanston",
  },
  {
    postal_code: "46950",
    full_city_name: "Lucerne, IN",
    city_name: "Lucerne",
  },
  {
    postal_code: "68928",
    full_city_name: "Bladen, NE",
    city_name: "Bladen",
  },
  {
    postal_code: "99371",
    full_city_name: "Washtucna, WA",
    city_name: "Washtucna",
  },
  {
    postal_code: "71956",
    full_city_name: "Mountain Pine, AR",
    city_name: "Mountain Pine",
  },
  {
    postal_code: "62635",
    full_city_name: "Emden, IL",
    city_name: "Emden",
  },
  {
    postal_code: "63336",
    full_city_name: "Clarksville, MO",
    city_name: "Clarksville",
  },
  {
    postal_code: "76389",
    full_city_name: "Windthorst, TX",
    city_name: "Windthorst",
  },
  {
    postal_code: "23952",
    full_city_name: "Lunenburg, VA",
    city_name: "Lunenburg",
  },
  {
    postal_code: "59830",
    full_city_name: "De Borgia, MT",
    city_name: "De Borgia",
  },
  {
    postal_code: "81646",
    full_city_name: "Molina, CO",
    city_name: "Molina",
  },
  {
    postal_code: "66401",
    full_city_name: "Alma, KS",
    city_name: "Alma",
  },
  {
    postal_code: "14841",
    full_city_name: "Hector, NY",
    city_name: "Hector",
  },
  {
    postal_code: "67123",
    full_city_name: "Potwin, KS",
    city_name: "Potwin",
  },
  {
    postal_code: "29166",
    full_city_name: "Ward, SC",
    city_name: "Ward",
  },
  {
    postal_code: "58488",
    full_city_name: "Tuttle, ND",
    city_name: "Tuttle",
  },
  {
    postal_code: "35651",
    full_city_name: "Mount Hope, AL",
    city_name: "Mount Hope",
  },
  {
    postal_code: "26451",
    full_city_name: "West Milford, WV",
    city_name: "West Milford",
  },
  {
    postal_code: "87027",
    full_city_name: "La Jara, NM",
    city_name: "La Jara",
  },
  {
    postal_code: "99138",
    full_city_name: "Inchelium, WA",
    city_name: "Inchelium",
  },
  {
    postal_code: "45788",
    full_city_name: "Whipple, OH",
    city_name: "Whipple",
  },
  {
    postal_code: "64726",
    full_city_name: "Blairstown, MO",
    city_name: "Blairstown",
  },
  {
    postal_code: "71663",
    full_city_name: "Portland, AR",
    city_name: "Portland",
  },
  {
    postal_code: "41385",
    full_city_name: "Vancleve, KY",
    city_name: "Vancleve",
  },
  {
    postal_code: "84306",
    full_city_name: "Collinston, UT",
    city_name: "Collinston",
  },
  {
    postal_code: "60557",
    full_city_name: "Wedron, IL",
    city_name: "Wedron",
  },
  {
    postal_code: "35554",
    full_city_name: "Eldridge, AL",
    city_name: "Eldridge",
  },
  {
    postal_code: "63636",
    full_city_name: "Des Arc, MO",
    city_name: "Des Arc",
  },
  {
    postal_code: "85545",
    full_city_name: "Roosevelt, AZ",
    city_name: "Roosevelt",
  },
  {
    postal_code: "84064",
    full_city_name: "Randolph, UT",
    city_name: "Randolph",
  },
  {
    postal_code: "32044",
    full_city_name: "Hampton, FL",
    city_name: "Hampton",
  },
  {
    postal_code: "57424",
    full_city_name: "Ashton, SD",
    city_name: "Ashton",
  },
  {
    postal_code: "68431",
    full_city_name: "Rulo, NE",
    city_name: "Rulo",
  },
  {
    postal_code: "65062",
    full_city_name: "Mount Sterling, MO",
    city_name: "Mount Sterling",
  },
  {
    postal_code: "41607",
    full_city_name: "Blue River, KY",
    city_name: "Blue River",
  },
  {
    postal_code: "24082",
    full_city_name: "Critz, VA",
    city_name: "Critz",
  },
  {
    postal_code: "46913",
    full_city_name: "Bringhurst, IN",
    city_name: "Bringhurst",
  },
  {
    postal_code: "48465",
    full_city_name: "Palms, MI",
    city_name: "Palms",
  },
  {
    postal_code: "57551",
    full_city_name: "Martin, SD",
    city_name: "Martin",
  },
  {
    postal_code: "05772",
    full_city_name: "Stockbridge, VT",
    city_name: "Stockbridge",
  },
  {
    postal_code: "25624",
    full_city_name: "Henlawson, WV",
    city_name: "Henlawson",
  },
  {
    postal_code: "72395",
    full_city_name: "Wilson, AR",
    city_name: "Wilson",
  },
  {
    postal_code: "59727",
    full_city_name: "Divide, MT",
    city_name: "Divide",
  },
  {
    postal_code: "58466",
    full_city_name: "Marion, ND",
    city_name: "Marion",
  },
  {
    postal_code: "12965",
    full_city_name: "Nicholville, NY",
    city_name: "Nicholville",
  },
  {
    postal_code: "59261",
    full_city_name: "Saco, MT",
    city_name: "Saco",
  },
  {
    postal_code: "83830",
    full_city_name: "Fernwood, ID",
    city_name: "Fernwood",
  },
  {
    postal_code: "16132",
    full_city_name: "Hillsville, PA",
    city_name: "Hillsville",
  },
  {
    postal_code: "36481",
    full_city_name: "Vredenburgh, AL",
    city_name: "Vredenburgh",
  },
  {
    postal_code: "23828",
    full_city_name: "Branchville, VA",
    city_name: "Branchville",
  },
  {
    postal_code: "76251",
    full_city_name: "Montague, TX",
    city_name: "Montague",
  },
  {
    postal_code: "62323",
    full_city_name: "Chambersburg, IL",
    city_name: "Chambersburg",
  },
  {
    postal_code: "72069",
    full_city_name: "Holly Grove, AR",
    city_name: "Holly Grove",
  },
  {
    postal_code: "29435",
    full_city_name: "Cottageville, SC",
    city_name: "Cottageville",
  },
  {
    postal_code: "39079",
    full_city_name: "Goodman, MS",
    city_name: "Goodman",
  },
  {
    postal_code: "38080",
    full_city_name: "Ridgely, TN",
    city_name: "Ridgely",
  },
  {
    postal_code: "56551",
    full_city_name: "Henning, MN",
    city_name: "Henning",
  },
  {
    postal_code: "59435",
    full_city_name: "Ethridge, MT",
    city_name: "Ethridge",
  },
  {
    postal_code: "70725",
    full_city_name: "Darrow, LA",
    city_name: "Darrow",
  },
  {
    postal_code: "64453",
    full_city_name: "Gentry, MO",
    city_name: "Gentry",
  },
  {
    postal_code: "16145",
    full_city_name: "Sandy Lake, PA",
    city_name: "Sandy Lake",
  },
  {
    postal_code: "41039",
    full_city_name: "Ewing, KY",
    city_name: "Ewing",
  },
  {
    postal_code: "54127",
    full_city_name: "Green Valley, WI",
    city_name: "Green Valley",
  },
  {
    postal_code: "58244",
    full_city_name: "Inkster, ND",
    city_name: "Inkster",
  },
  {
    postal_code: "96027",
    full_city_name: "Etna, CA",
    city_name: "Etna",
  },
  {
    postal_code: "56167",
    full_city_name: "Round Lake, MN",
    city_name: "Round Lake",
  },
  {
    postal_code: "68926",
    full_city_name: "Beaver City, NE",
    city_name: "Beaver City",
  },
  {
    postal_code: "78113",
    full_city_name: "Falls City, TX",
    city_name: "Falls City",
  },
  {
    postal_code: "57342",
    full_city_name: "Geddes, SD",
    city_name: "Geddes",
  },
  {
    postal_code: "36738",
    full_city_name: "Faunsdale, AL",
    city_name: "Faunsdale",
  },
  {
    postal_code: "64428",
    full_city_name: "Burlington Junction, MO",
    city_name: "Burlington Junction",
  },
  {
    postal_code: "25108",
    full_city_name: "Hewett, WV",
    city_name: "Hewett",
  },
  {
    postal_code: "87941",
    full_city_name: "Salem, NM",
    city_name: "Salem",
  },
  {
    postal_code: "71371",
    full_city_name: "Trout, LA",
    city_name: "Trout",
  },
  {
    postal_code: "65623",
    full_city_name: "Butterfield, MO",
    city_name: "Butterfield",
  },
  {
    postal_code: "72837",
    full_city_name: "Dover, AR",
    city_name: "Dover",
  },
  {
    postal_code: "58361",
    full_city_name: "Pekin, ND",
    city_name: "Pekin",
  },
  {
    postal_code: "22514",
    full_city_name: "Milford, VA",
    city_name: "Milford",
  },
  {
    postal_code: "19374",
    full_city_name: "Toughkenamon, PA",
    city_name: "Toughkenamon",
  },
  {
    postal_code: "78953",
    full_city_name: "Rosanky, TX",
    city_name: "Rosanky",
  },
  {
    postal_code: "47427",
    full_city_name: "Coal City, IN",
    city_name: "Coal City",
  },
  {
    postal_code: "14464",
    full_city_name: "Hamlin, NY",
    city_name: "Hamlin",
  },
  {
    postal_code: "83349",
    full_city_name: "Richfield, ID",
    city_name: "Richfield",
  },
  {
    postal_code: "96104",
    full_city_name: "Cedarville, CA",
    city_name: "Cedarville",
  },
  {
    postal_code: "24892",
    full_city_name: "War, WV",
    city_name: "War",
  },
  {
    postal_code: "19953",
    full_city_name: "Hartly, DE",
    city_name: "Hartly",
  },
  {
    postal_code: "74748",
    full_city_name: "Kenefic, OK",
    city_name: "Kenefic",
  },
  {
    postal_code: "59456",
    full_city_name: "Ledger, MT",
    city_name: "Ledger",
  },
  {
    postal_code: "83220",
    full_city_name: "Bern, ID",
    city_name: "Bern",
  },
  {
    postal_code: "78933",
    full_city_name: "Cat Spring, TX",
    city_name: "Cat Spring",
  },
  {
    postal_code: "59248",
    full_city_name: "Nashua, MT",
    city_name: "Nashua",
  },
  {
    postal_code: "36761",
    full_city_name: "Minter, AL",
    city_name: "Minter",
  },
  {
    postal_code: "54933",
    full_city_name: "Embarrass, WI",
    city_name: "Embarrass",
  },
  {
    postal_code: "72842",
    full_city_name: "Havana, AR",
    city_name: "Havana",
  },
  {
    postal_code: "07112",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "29208",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "99606",
    full_city_name: "Iliamna, AK",
    city_name: "Iliamna",
  },
  {
    postal_code: "47859",
    full_city_name: "Marshall, IN",
    city_name: "Marshall",
  },
  {
    postal_code: "80823",
    full_city_name: "Karval, CO",
    city_name: "Karval",
  },
  {
    postal_code: "28537",
    full_city_name: "Hobucken, NC",
    city_name: "Hobucken",
  },
  {
    postal_code: "93203",
    full_city_name: "Arvin, CA",
    city_name: "Arvin",
  },
  {
    postal_code: "67354",
    full_city_name: "Mound Valley, KS",
    city_name: "Mound Valley",
  },
  {
    postal_code: "59739",
    full_city_name: "Lima, MT",
    city_name: "Lima",
  },
  {
    postal_code: "65351",
    full_city_name: "Sweet Springs, MO",
    city_name: "Sweet Springs",
  },
  {
    postal_code: "04623",
    full_city_name: "Columbia Falls, ME",
    city_name: "Columbia Falls",
  },
  {
    postal_code: "57055",
    full_city_name: "Renner, SD",
    city_name: "Renner",
  },
  {
    postal_code: "15413",
    full_city_name: "Allison, PA",
    city_name: "Allison",
  },
  {
    postal_code: "54462",
    full_city_name: "Pearson, WI",
    city_name: "Pearson",
  },
  {
    postal_code: "47930",
    full_city_name: "Clarks Hill, IN",
    city_name: "Clarks Hill",
  },
  {
    postal_code: "25267",
    full_city_name: "Normantown, WV",
    city_name: "Normantown",
  },
  {
    postal_code: "08102",
    full_city_name: "Camden, NJ",
    city_name: "Camden",
  },
  {
    postal_code: "67151",
    full_city_name: "Walton, KS",
    city_name: "Walton",
  },
  {
    postal_code: "32336",
    full_city_name: "Lamont, FL",
    city_name: "Lamont",
  },
  {
    postal_code: "50647",
    full_city_name: "Janesville, IA",
    city_name: "Janesville",
  },
  {
    postal_code: "65557",
    full_city_name: "Roby, MO",
    city_name: "Roby",
  },
  {
    postal_code: "54964",
    full_city_name: "Pickett, WI",
    city_name: "Pickett",
  },
  {
    postal_code: "15348",
    full_city_name: "Millsboro, PA",
    city_name: "Millsboro",
  },
  {
    postal_code: "58431",
    full_city_name: "Dickey, ND",
    city_name: "Dickey",
  },
  {
    postal_code: "24834",
    full_city_name: "Fanrock, WV",
    city_name: "Fanrock",
  },
  {
    postal_code: "29113",
    full_city_name: "Norway, SC",
    city_name: "Norway",
  },
  {
    postal_code: "04497",
    full_city_name: "Wytopitlock, ME",
    city_name: "Wytopitlock",
  },
  {
    postal_code: "64667",
    full_city_name: "Newtown, MO",
    city_name: "Newtown",
  },
  {
    postal_code: "59484",
    full_city_name: "Sweet Grass, MT",
    city_name: "Sweet Grass",
  },
  {
    postal_code: "58645",
    full_city_name: "Medora, ND",
    city_name: "Medora",
  },
  {
    postal_code: "68747",
    full_city_name: "Mclean, NE",
    city_name: "Mclean",
  },
  {
    postal_code: "57780",
    full_city_name: "Scenic, SD",
    city_name: "Scenic",
  },
  {
    postal_code: "72528",
    full_city_name: "Dolph, AR",
    city_name: "Dolph",
  },
  {
    postal_code: "59225",
    full_city_name: "Frazer, MT",
    city_name: "Frazer",
  },
  {
    postal_code: "73832",
    full_city_name: "Arnett, OK",
    city_name: "Arnett",
  },
  {
    postal_code: "59064",
    full_city_name: "Pompeys Pillar, MT",
    city_name: "Pompeys Pillar",
  },
  {
    postal_code: "73951",
    full_city_name: "Tyrone, OK",
    city_name: "Tyrone",
  },
  {
    postal_code: "66408",
    full_city_name: "Bern, KS",
    city_name: "Bern",
  },
  {
    postal_code: "40988",
    full_city_name: "Stoney Fork, KY",
    city_name: "Stoney Fork",
  },
  {
    postal_code: "68029",
    full_city_name: "Herman, NE",
    city_name: "Herman",
  },
  {
    postal_code: "58651",
    full_city_name: "Rhame, ND",
    city_name: "Rhame",
  },
  {
    postal_code: "12837",
    full_city_name: "Hampton, NY",
    city_name: "Hampton",
  },
  {
    postal_code: "22743",
    full_city_name: "Syria, VA",
    city_name: "Syria",
  },
  {
    postal_code: "56240",
    full_city_name: "Graceville, MN",
    city_name: "Graceville",
  },
  {
    postal_code: "39813",
    full_city_name: "Arlington, GA",
    city_name: "Arlington",
  },
  {
    postal_code: "32449",
    full_city_name: "Wewahitchka, FL",
    city_name: "Wewahitchka",
  },
  {
    postal_code: "76854",
    full_city_name: "London, TX",
    city_name: "London",
  },
  {
    postal_code: "82430",
    full_city_name: "Kirby, WY",
    city_name: "Kirby",
  },
  {
    postal_code: "36919",
    full_city_name: "Silas, AL",
    city_name: "Silas",
  },
  {
    postal_code: "59081",
    full_city_name: "Silver Gate, MT",
    city_name: "Silver Gate",
  },
  {
    postal_code: "56231",
    full_city_name: "Danvers, MN",
    city_name: "Danvers",
  },
  {
    postal_code: "67879",
    full_city_name: "Tribune, KS",
    city_name: "Tribune",
  },
  {
    postal_code: "29580",
    full_city_name: "Nesmith, SC",
    city_name: "Nesmith",
  },
  {
    postal_code: "73074",
    full_city_name: "Paoli, OK",
    city_name: "Paoli",
  },
  {
    postal_code: "34138",
    full_city_name: "Chokoloskee, FL",
    city_name: "Chokoloskee",
  },
  {
    postal_code: "89424",
    full_city_name: "Nixon, NV",
    city_name: "Nixon",
  },
  {
    postal_code: "15420",
    full_city_name: "Cardale, PA",
    city_name: "Cardale",
  },
  {
    postal_code: "71031",
    full_city_name: "Goldonna, LA",
    city_name: "Goldonna",
  },
  {
    postal_code: "75415",
    full_city_name: "Ben Franklin, TX",
    city_name: "Ben Franklin",
  },
  {
    postal_code: "70763",
    full_city_name: "Paulina, LA",
    city_name: "Paulina",
  },
  {
    postal_code: "58640",
    full_city_name: "Killdeer, ND",
    city_name: "Killdeer",
  },
  {
    postal_code: "62894",
    full_city_name: "Waltonville, IL",
    city_name: "Waltonville",
  },
  {
    postal_code: "97873",
    full_city_name: "Seneca, OR",
    city_name: "Seneca",
  },
  {
    postal_code: "42275",
    full_city_name: "Roundhill, KY",
    city_name: "Roundhill",
  },
  {
    postal_code: "61039",
    full_city_name: "German Valley, IL",
    city_name: "German Valley",
  },
  {
    postal_code: "49834",
    full_city_name: "Foster City, MI",
    city_name: "Foster City",
  },
  {
    postal_code: "62325",
    full_city_name: "Coatsburg, IL",
    city_name: "Coatsburg",
  },
  {
    postal_code: "70441",
    full_city_name: "Greensburg, LA",
    city_name: "Greensburg",
  },
  {
    postal_code: "59837",
    full_city_name: "Hall, MT",
    city_name: "Hall",
  },
  {
    postal_code: "67552",
    full_city_name: "Lewis, KS",
    city_name: "Lewis",
  },
  {
    postal_code: "64430",
    full_city_name: "Clarksdale, MO",
    city_name: "Clarksdale",
  },
  {
    postal_code: "17235",
    full_city_name: "Marion, PA",
    city_name: "Marion",
  },
  {
    postal_code: "51048",
    full_city_name: "Pierson, IA",
    city_name: "Pierson",
  },
  {
    postal_code: "46104",
    full_city_name: "Arlington, IN",
    city_name: "Arlington",
  },
  {
    postal_code: "15951",
    full_city_name: "Saint Michael, PA",
    city_name: "Saint Michael",
  },
  {
    postal_code: "62468",
    full_city_name: "Toledo, IL",
    city_name: "Toledo",
  },
  {
    postal_code: "68036",
    full_city_name: "Linwood, NE",
    city_name: "Linwood",
  },
  {
    postal_code: "63039",
    full_city_name: "Gray Summit, MO",
    city_name: "Gray Summit",
  },
  {
    postal_code: "93226",
    full_city_name: "Glennville, CA",
    city_name: "Glennville",
  },
  {
    postal_code: "82327",
    full_city_name: "Hanna, WY",
    city_name: "Hanna",
  },
  {
    postal_code: "16733",
    full_city_name: "Hazel Hurst, PA",
    city_name: "Hazel Hurst",
  },
  {
    postal_code: "16329",
    full_city_name: "Irvine, PA",
    city_name: "Irvine",
  },
  {
    postal_code: "31832",
    full_city_name: "Weston, GA",
    city_name: "Weston",
  },
  {
    postal_code: "74367",
    full_city_name: "Strang, OK",
    city_name: "Strang",
  },
  {
    postal_code: "41554",
    full_city_name: "Phyllis, KY",
    city_name: "Phyllis",
  },
  {
    postal_code: "67762",
    full_city_name: "Weskan, KS",
    city_name: "Weskan",
  },
  {
    postal_code: "21766",
    full_city_name: "Little Orleans, MD",
    city_name: "Little Orleans",
  },
  {
    postal_code: "67638",
    full_city_name: "Gaylord, KS",
    city_name: "Gaylord",
  },
  {
    postal_code: "18846",
    full_city_name: "Sugar Run, PA",
    city_name: "Sugar Run",
  },
  {
    postal_code: "63532",
    full_city_name: "Bevier, MO",
    city_name: "Bevier",
  },
  {
    postal_code: "95527",
    full_city_name: "Burnt Ranch, CA",
    city_name: "Burnt Ranch",
  },
  {
    postal_code: "46070",
    full_city_name: "Summitville, IN",
    city_name: "Summitville",
  },
  {
    postal_code: "96076",
    full_city_name: "Platina, CA",
    city_name: "Platina",
  },
  {
    postal_code: "64873",
    full_city_name: "Wentworth, MO",
    city_name: "Wentworth",
  },
  {
    postal_code: "03883",
    full_city_name: "South Tamworth, NH",
    city_name: "South Tamworth",
  },
  {
    postal_code: "45740",
    full_city_name: "Jacksonville, OH",
    city_name: "Jacksonville",
  },
  {
    postal_code: "47224",
    full_city_name: "Canaan, IN",
    city_name: "Canaan",
  },
  {
    postal_code: "81138",
    full_city_name: "Jaroso, CO",
    city_name: "Jaroso",
  },
  {
    postal_code: "81324",
    full_city_name: "Dove Creek, CO",
    city_name: "Dove Creek",
  },
  {
    postal_code: "59411",
    full_city_name: "Babb, MT",
    city_name: "Babb",
  },
  {
    postal_code: "43757",
    full_city_name: "Malaga, OH",
    city_name: "Malaga",
  },
  {
    postal_code: "52207",
    full_city_name: "Baldwin, IA",
    city_name: "Baldwin",
  },
  {
    postal_code: "83238",
    full_city_name: "Geneva, ID",
    city_name: "Geneva",
  },
  {
    postal_code: "52567",
    full_city_name: "Libertyville, IA",
    city_name: "Libertyville",
  },
  {
    postal_code: "79098",
    full_city_name: "Wildorado, TX",
    city_name: "Wildorado",
  },
  {
    postal_code: "56123",
    full_city_name: "Currie, MN",
    city_name: "Currie",
  },
  {
    postal_code: "67877",
    full_city_name: "Sublette, KS",
    city_name: "Sublette",
  },
  {
    postal_code: "35751",
    full_city_name: "Hollytree, AL",
    city_name: "Hollytree",
  },
  {
    postal_code: "72435",
    full_city_name: "Knobel, AR",
    city_name: "Knobel",
  },
  {
    postal_code: "95974",
    full_city_name: "Richvale, CA",
    city_name: "Richvale",
  },
  {
    postal_code: "16111",
    full_city_name: "Atlantic, PA",
    city_name: "Atlantic",
  },
  {
    postal_code: "83277",
    full_city_name: "Springfield, ID",
    city_name: "Springfield",
  },
  {
    postal_code: "18970",
    full_city_name: "Trumbauersville, PA",
    city_name: "Trumbauersville",
  },
  {
    postal_code: "50634",
    full_city_name: "Gilbertville, IA",
    city_name: "Gilbertville",
  },
  {
    postal_code: "87723",
    full_city_name: "Holman, NM",
    city_name: "Holman",
  },
  {
    postal_code: "69025",
    full_city_name: "Curtis, NE",
    city_name: "Curtis",
  },
  {
    postal_code: "71032",
    full_city_name: "Grand Cane, LA",
    city_name: "Grand Cane",
  },
  {
    postal_code: "96780",
    full_city_name: "Papaaloa, HI",
    city_name: "Papaaloa",
  },
  {
    postal_code: "01338",
    full_city_name: "Buckland, MA",
    city_name: "Buckland",
  },
  {
    postal_code: "78588",
    full_city_name: "San Isidro, TX",
    city_name: "San Isidro",
  },
  {
    postal_code: "04451",
    full_city_name: "Kingman, ME",
    city_name: "Kingman",
  },
  {
    postal_code: "42764",
    full_city_name: "Mount Sherman, KY",
    city_name: "Mount Sherman",
  },
  {
    postal_code: "96013",
    full_city_name: "Burney, CA",
    city_name: "Burney",
  },
  {
    postal_code: "56525",
    full_city_name: "Comstock, MN",
    city_name: "Comstock",
  },
  {
    postal_code: "17742",
    full_city_name: "Lairdsville, PA",
    city_name: "Lairdsville",
  },
  {
    postal_code: "80544",
    full_city_name: "Niwot, CO",
    city_name: "Niwot",
  },
  {
    postal_code: "83423",
    full_city_name: "Dubois, ID",
    city_name: "Dubois",
  },
  {
    postal_code: "69144",
    full_city_name: "Keystone, NE",
    city_name: "Keystone",
  },
  {
    postal_code: "71935",
    full_city_name: "Caddo Gap, AR",
    city_name: "Caddo Gap",
  },
  {
    postal_code: "25511",
    full_city_name: "Dunlow, WV",
    city_name: "Dunlow",
  },
  {
    postal_code: "76632",
    full_city_name: "Chilton, TX",
    city_name: "Chilton",
  },
  {
    postal_code: "04085",
    full_city_name: "Steep Falls, ME",
    city_name: "Steep Falls",
  },
  {
    postal_code: "45646",
    full_city_name: "Latham, OH",
    city_name: "Latham",
  },
  {
    postal_code: "63538",
    full_city_name: "Elmer, MO",
    city_name: "Elmer",
  },
  {
    postal_code: "25434",
    full_city_name: "Paw Paw, WV",
    city_name: "Paw Paw",
  },
  {
    postal_code: "04109",
    full_city_name: "Portland, ME",
    city_name: "Portland",
  },
  {
    postal_code: "78008",
    full_city_name: "Campbellton, TX",
    city_name: "Campbellton",
  },
  {
    postal_code: "12036",
    full_city_name: "Charlotteville, NY",
    city_name: "Charlotteville",
  },
  {
    postal_code: "57325",
    full_city_name: "Chamberlain, SD",
    city_name: "Chamberlain",
  },
  {
    postal_code: "13670",
    full_city_name: "Oswegatchie, NY",
    city_name: "Oswegatchie",
  },
  {
    postal_code: "59827",
    full_city_name: "Conner, MT",
    city_name: "Conner",
  },
  {
    postal_code: "69353",
    full_city_name: "Mcgrew, NE",
    city_name: "Mcgrew",
  },
  {
    postal_code: "71726",
    full_city_name: "Chidester, AR",
    city_name: "Chidester",
  },
  {
    postal_code: "72168",
    full_city_name: "Tucker, AR",
    city_name: "Tucker",
  },
  {
    postal_code: "93244",
    full_city_name: "Lemon Cove, CA",
    city_name: "Lemon Cove",
  },
  {
    postal_code: "58433",
    full_city_name: "Edgeley, ND",
    city_name: "Edgeley",
  },
  {
    postal_code: "26662",
    full_city_name: "Canvas, WV",
    city_name: "Canvas",
  },
  {
    postal_code: "67470",
    full_city_name: "New Cambria, KS",
    city_name: "New Cambria",
  },
  {
    postal_code: "36608",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "67342",
    full_city_name: "Edna, KS",
    city_name: "Edna",
  },
  {
    postal_code: "32564",
    full_city_name: "Holt, FL",
    city_name: "Holt",
  },
  {
    postal_code: "02652",
    full_city_name: "North Truro, MA",
    city_name: "North Truro",
  },
  {
    postal_code: "65339",
    full_city_name: "Malta Bend, MO",
    city_name: "Malta Bend",
  },
  {
    postal_code: "15359",
    full_city_name: "Rogersville, PA",
    city_name: "Rogersville",
  },
  {
    postal_code: "66014",
    full_city_name: "Centerville, KS",
    city_name: "Centerville",
  },
  {
    postal_code: "36317",
    full_city_name: "Clopton, AL",
    city_name: "Clopton",
  },
  {
    postal_code: "61007",
    full_city_name: "Baileyville, IL",
    city_name: "Baileyville",
  },
  {
    postal_code: "50609",
    full_city_name: "Beaman, IA",
    city_name: "Beaman",
  },
  {
    postal_code: "79526",
    full_city_name: "Hermleigh, TX",
    city_name: "Hermleigh",
  },
  {
    postal_code: "55957",
    full_city_name: "Millville, MN",
    city_name: "Millville",
  },
  {
    postal_code: "67573",
    full_city_name: "Raymond, KS",
    city_name: "Raymond",
  },
  {
    postal_code: "26852",
    full_city_name: "Purgitsville, WV",
    city_name: "Purgitsville",
  },
  {
    postal_code: "47926",
    full_city_name: "Burnettsville, IN",
    city_name: "Burnettsville",
  },
  {
    postal_code: "71958",
    full_city_name: "Murfreesboro, AR",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "35775",
    full_city_name: "Valhermoso Springs, AL",
    city_name: "Valhermoso Springs",
  },
  {
    postal_code: "08316",
    full_city_name: "Dorchester, NJ",
    city_name: "Dorchester",
  },
  {
    postal_code: "60956",
    full_city_name: "Papineau, IL",
    city_name: "Papineau",
  },
  {
    postal_code: "50152",
    full_city_name: "Luther, IA",
    city_name: "Luther",
  },
  {
    postal_code: "48470",
    full_city_name: "Ruth, MI",
    city_name: "Ruth",
  },
  {
    postal_code: "04454",
    full_city_name: "Lambert Lake, ME",
    city_name: "Lambert Lake",
  },
  {
    postal_code: "83449",
    full_city_name: "Swan Valley, ID",
    city_name: "Swan Valley",
  },
  {
    postal_code: "26814",
    full_city_name: "Riverton, WV",
    city_name: "Riverton",
  },
  {
    postal_code: "42156",
    full_city_name: "Lucas, KY",
    city_name: "Lucas",
  },
  {
    postal_code: "58064",
    full_city_name: "Page, ND",
    city_name: "Page",
  },
  {
    postal_code: "24326",
    full_city_name: "Elk Creek, VA",
    city_name: "Elk Creek",
  },
  {
    postal_code: "78007",
    full_city_name: "Calliham, TX",
    city_name: "Calliham",
  },
  {
    postal_code: "73530",
    full_city_name: "Davidson, OK",
    city_name: "Davidson",
  },
  {
    postal_code: "65550",
    full_city_name: "Newburg, MO",
    city_name: "Newburg",
  },
  {
    postal_code: "56165",
    full_city_name: "Reading, MN",
    city_name: "Reading",
  },
  {
    postal_code: "77994",
    full_city_name: "Westhoff, TX",
    city_name: "Westhoff",
  },
  {
    postal_code: "58344",
    full_city_name: "Lakota, ND",
    city_name: "Lakota",
  },
  {
    postal_code: "74084",
    full_city_name: "Wynona, OK",
    city_name: "Wynona",
  },
  {
    postal_code: "75567",
    full_city_name: "Maud, TX",
    city_name: "Maud",
  },
  {
    postal_code: "68631",
    full_city_name: "Creston, NE",
    city_name: "Creston",
  },
  {
    postal_code: "68958",
    full_city_name: "Loomis, NE",
    city_name: "Loomis",
  },
  {
    postal_code: "25969",
    full_city_name: "Jumping Branch, WV",
    city_name: "Jumping Branch",
  },
  {
    postal_code: "65710",
    full_city_name: "Morrisville, MO",
    city_name: "Morrisville",
  },
  {
    postal_code: "84061",
    full_city_name: "Peoa, UT",
    city_name: "Peoa",
  },
  {
    postal_code: "05827",
    full_city_name: "Craftsbury Common, VT",
    city_name: "Craftsbury Common",
  },
  {
    postal_code: "97021",
    full_city_name: "Dufur, OR",
    city_name: "Dufur",
  },
  {
    postal_code: "27022",
    full_city_name: "Lawsonville, NC",
    city_name: "Lawsonville",
  },
  {
    postal_code: "15611",
    full_city_name: "Adamsburg, PA",
    city_name: "Adamsburg",
  },
  {
    postal_code: "12069",
    full_city_name: "Fort Hunter, NY",
    city_name: "Fort Hunter",
  },
  {
    postal_code: "89427",
    full_city_name: "Schurz, NV",
    city_name: "Schurz",
  },
  {
    postal_code: "12821",
    full_city_name: "Comstock, NY",
    city_name: "Comstock",
  },
  {
    postal_code: "68658",
    full_city_name: "Rising City, NE",
    city_name: "Rising City",
  },
  {
    postal_code: "67560",
    full_city_name: "Ness City, KS",
    city_name: "Ness City",
  },
  {
    postal_code: "65333",
    full_city_name: "Houstonia, MO",
    city_name: "Houstonia",
  },
  {
    postal_code: "63746",
    full_city_name: "Farrar, MO",
    city_name: "Farrar",
  },
  {
    postal_code: "56545",
    full_city_name: "Gary, MN",
    city_name: "Gary",
  },
  {
    postal_code: "62065",
    full_city_name: "Michael, IL",
    city_name: "Michael",
  },
  {
    postal_code: "15684",
    full_city_name: "Slickville, PA",
    city_name: "Slickville",
  },
  {
    postal_code: "58476",
    full_city_name: "Pingree, ND",
    city_name: "Pingree",
  },
  {
    postal_code: "59731",
    full_city_name: "Garrison, MT",
    city_name: "Garrison",
  },
  {
    postal_code: "58056",
    full_city_name: "Luverne, ND",
    city_name: "Luverne",
  },
  {
    postal_code: "39169",
    full_city_name: "Tchula, MS",
    city_name: "Tchula",
  },
  {
    postal_code: "62452",
    full_city_name: "Parkersburg, IL",
    city_name: "Parkersburg",
  },
  {
    postal_code: "13751",
    full_city_name: "Davenport Center, NY",
    city_name: "Davenport Center",
  },
  {
    postal_code: "38920",
    full_city_name: "Cascilla, MS",
    city_name: "Cascilla",
  },
  {
    postal_code: "74829",
    full_city_name: "Boley, OK",
    city_name: "Boley",
  },
  {
    postal_code: "50846",
    full_city_name: "Fontanelle, IA",
    city_name: "Fontanelle",
  },
  {
    postal_code: "15019",
    full_city_name: "Bulger, PA",
    city_name: "Bulger",
  },
  {
    postal_code: "96126",
    full_city_name: "Sierraville, CA",
    city_name: "Sierraville",
  },
  {
    postal_code: "59065",
    full_city_name: "Pray, MT",
    city_name: "Pray",
  },
  {
    postal_code: "70777",
    full_city_name: "Slaughter, LA",
    city_name: "Slaughter",
  },
  {
    postal_code: "36860",
    full_city_name: "Hurtsboro, AL",
    city_name: "Hurtsboro",
  },
  {
    postal_code: "73628",
    full_city_name: "Cheyenne, OK",
    city_name: "Cheyenne",
  },
  {
    postal_code: "25632",
    full_city_name: "Lyburn, WV",
    city_name: "Lyburn",
  },
  {
    postal_code: "04226",
    full_city_name: "East Andover, ME",
    city_name: "East Andover",
  },
  {
    postal_code: "85640",
    full_city_name: "Tumacacori, AZ",
    city_name: "Tumacacori",
  },
  {
    postal_code: "57564",
    full_city_name: "Onida, SD",
    city_name: "Onida",
  },
  {
    postal_code: "39162",
    full_city_name: "Satartia, MS",
    city_name: "Satartia",
  },
  {
    postal_code: "59531",
    full_city_name: "Joplin, MT",
    city_name: "Joplin",
  },
  {
    postal_code: "99830",
    full_city_name: "Kake, AK",
    city_name: "Kake",
  },
  {
    postal_code: "29943",
    full_city_name: "Tillman, SC",
    city_name: "Tillman",
  },
  {
    postal_code: "07851",
    full_city_name: "Layton, NJ",
    city_name: "Layton",
  },
  {
    postal_code: "14716",
    full_city_name: "Brocton, NY",
    city_name: "Brocton",
  },
  {
    postal_code: "95043",
    full_city_name: "Paicines, CA",
    city_name: "Paicines",
  },
  {
    postal_code: "26419",
    full_city_name: "Pine Grove, WV",
    city_name: "Pine Grove",
  },
  {
    postal_code: "83124",
    full_city_name: "Opal, WY",
    city_name: "Opal",
  },
  {
    postal_code: "95674",
    full_city_name: "Rio Oso, CA",
    city_name: "Rio Oso",
  },
  {
    postal_code: "66845",
    full_city_name: "Cottonwood Falls, KS",
    city_name: "Cottonwood Falls",
  },
  {
    postal_code: "39464",
    full_city_name: "Ovett, MS",
    city_name: "Ovett",
  },
  {
    postal_code: "70375",
    full_city_name: "Mathews, LA",
    city_name: "Mathews",
  },
  {
    postal_code: "84336",
    full_city_name: "Snowville, UT",
    city_name: "Snowville",
  },
  {
    postal_code: "36768",
    full_city_name: "Pine Apple, AL",
    city_name: "Pine Apple",
  },
  {
    postal_code: "70658",
    full_city_name: "Reeves, LA",
    city_name: "Reeves",
  },
  {
    postal_code: "99926",
    full_city_name: "Metlakatla, AK",
    city_name: "Metlakatla",
  },
  {
    postal_code: "36483",
    full_city_name: "Wing, AL",
    city_name: "Wing",
  },
  {
    postal_code: "79561",
    full_city_name: "Trent, TX",
    city_name: "Trent",
  },
  {
    postal_code: "24562",
    full_city_name: "Howardsville, VA",
    city_name: "Howardsville",
  },
  {
    postal_code: "17509",
    full_city_name: "Christiana, PA",
    city_name: "Christiana",
  },
  {
    postal_code: "74339",
    full_city_name: "Commerce, OK",
    city_name: "Commerce",
  },
  {
    postal_code: "59640",
    full_city_name: "Marysville, MT",
    city_name: "Marysville",
  },
  {
    postal_code: "67953",
    full_city_name: "Richfield, KS",
    city_name: "Richfield",
  },
  {
    postal_code: "41834",
    full_city_name: "Littcarr, KY",
    city_name: "Littcarr",
  },
  {
    postal_code: "50519",
    full_city_name: "Bode, IA",
    city_name: "Bode",
  },
  {
    postal_code: "15450",
    full_city_name: "La Belle, PA",
    city_name: "La Belle",
  },
  {
    postal_code: "50556",
    full_city_name: "Ledyard, IA",
    city_name: "Ledyard",
  },
  {
    postal_code: "57040",
    full_city_name: "Lesterville, SD",
    city_name: "Lesterville",
  },
  {
    postal_code: "65464",
    full_city_name: "Elk Creek, MO",
    city_name: "Elk Creek",
  },
  {
    postal_code: "35961",
    full_city_name: "Collinsville, AL",
    city_name: "Collinsville",
  },
  {
    postal_code: "83874",
    full_city_name: "Murray, ID",
    city_name: "Murray",
  },
  {
    postal_code: "87416",
    full_city_name: "Fruitland, NM",
    city_name: "Fruitland",
  },
  {
    postal_code: "47862",
    full_city_name: "Montezuma, IN",
    city_name: "Montezuma",
  },
  {
    postal_code: "61067",
    full_city_name: "Ridott, IL",
    city_name: "Ridott",
  },
  {
    postal_code: "99691",
    full_city_name: "Nikolai, AK",
    city_name: "Nikolai",
  },
  {
    postal_code: "97371",
    full_city_name: "Rickreall, OR",
    city_name: "Rickreall",
  },
  {
    postal_code: "25979",
    full_city_name: "Pipestem, WV",
    city_name: "Pipestem",
  },
  {
    postal_code: "29540",
    full_city_name: "Darlington, SC",
    city_name: "Darlington",
  },
  {
    postal_code: "96085",
    full_city_name: "Scott Bar, CA",
    city_name: "Scott Bar",
  },
  {
    postal_code: "74368",
    full_city_name: "Twin Oaks, OK",
    city_name: "Twin Oaks",
  },
  {
    postal_code: "68860",
    full_city_name: "Oconto, NE",
    city_name: "Oconto",
  },
  {
    postal_code: "26651",
    full_city_name: "Summersville, WV",
    city_name: "Summersville",
  },
  {
    postal_code: "19362",
    full_city_name: "Nottingham, PA",
    city_name: "Nottingham",
  },
  {
    postal_code: "22732",
    full_city_name: "Radiant, VA",
    city_name: "Radiant",
  },
  {
    postal_code: "92283",
    full_city_name: "Winterhaven, CA",
    city_name: "Winterhaven",
  },
  {
    postal_code: "38589",
    full_city_name: "Wilder, TN",
    city_name: "Wilder",
  },
  {
    postal_code: "95595",
    full_city_name: "Zenia, CA",
    city_name: "Zenia",
  },
  {
    postal_code: "47522",
    full_city_name: "Crane, IN",
    city_name: "Crane",
  },
  {
    postal_code: "60474",
    full_city_name: "South Wilmington, IL",
    city_name: "South Wilmington",
  },
  {
    postal_code: "44809",
    full_city_name: "Bascom, OH",
    city_name: "Bascom",
  },
  {
    postal_code: "23968",
    full_city_name: "Skipwith, VA",
    city_name: "Skipwith",
  },
  {
    postal_code: "25208",
    full_city_name: "Wharton, WV",
    city_name: "Wharton",
  },
  {
    postal_code: "21051",
    full_city_name: "Fork, MD",
    city_name: "Fork",
  },
  {
    postal_code: "45845",
    full_city_name: "Fort Loramie, OH",
    city_name: "Fort Loramie",
  },
  {
    postal_code: "41166",
    full_city_name: "Quincy, KY",
    city_name: "Quincy",
  },
  {
    postal_code: "51528",
    full_city_name: "Dow City, IA",
    city_name: "Dow City",
  },
  {
    postal_code: "22623",
    full_city_name: "Chester Gap, VA",
    city_name: "Chester Gap",
  },
  {
    postal_code: "99573",
    full_city_name: "Copper Center, AK",
    city_name: "Copper Center",
  },
  {
    postal_code: "52132",
    full_city_name: "Calmar, IA",
    city_name: "Calmar",
  },
  {
    postal_code: "80426",
    full_city_name: "Burns, CO",
    city_name: "Burns",
  },
  {
    postal_code: "32324",
    full_city_name: "Chattahoochee, FL",
    city_name: "Chattahoochee",
  },
  {
    postal_code: "54212",
    full_city_name: "Fish Creek, WI",
    city_name: "Fish Creek",
  },
  {
    postal_code: "64480",
    full_city_name: "Rea, MO",
    city_name: "Rea",
  },
  {
    postal_code: "29596",
    full_city_name: "Wallace, SC",
    city_name: "Wallace",
  },
  {
    postal_code: "99322",
    full_city_name: "Bickleton, WA",
    city_name: "Bickleton",
  },
  {
    postal_code: "86321",
    full_city_name: "Bagdad, AZ",
    city_name: "Bagdad",
  },
  {
    postal_code: "01039",
    full_city_name: "Haydenville, MA",
    city_name: "Haydenville",
  },
  {
    postal_code: "87824",
    full_city_name: "Luna, NM",
    city_name: "Luna",
  },
  {
    postal_code: "57790",
    full_city_name: "Wall, SD",
    city_name: "Wall",
  },
  {
    postal_code: "15538",
    full_city_name: "Fairhope, PA",
    city_name: "Fairhope",
  },
  {
    postal_code: "61471",
    full_city_name: "Raritan, IL",
    city_name: "Raritan",
  },
  {
    postal_code: "36904",
    full_city_name: "Butler, AL",
    city_name: "Butler",
  },
  {
    postal_code: "83251",
    full_city_name: "Mackay, ID",
    city_name: "Mackay",
  },
  {
    postal_code: "59006",
    full_city_name: "Ballantine, MT",
    city_name: "Ballantine",
  },
  {
    postal_code: "58752",
    full_city_name: "Lignite, ND",
    city_name: "Lignite",
  },
  {
    postal_code: "22432",
    full_city_name: "Burgess, VA",
    city_name: "Burgess",
  },
  {
    postal_code: "26575",
    full_city_name: "Hundred, WV",
    city_name: "Hundred",
  },
  {
    postal_code: "35456",
    full_city_name: "Duncanville, AL",
    city_name: "Duncanville",
  },
  {
    postal_code: "75856",
    full_city_name: "Pennington, TX",
    city_name: "Pennington",
  },
  {
    postal_code: "87024",
    full_city_name: "Jemez Pueblo, NM",
    city_name: "Jemez Pueblo",
  },
  {
    postal_code: "68001",
    full_city_name: "Abie, NE",
    city_name: "Abie",
  },
  {
    postal_code: "96048",
    full_city_name: "Junction City, CA",
    city_name: "Junction City",
  },
  {
    postal_code: "29137",
    full_city_name: "Salley, SC",
    city_name: "Salley",
  },
  {
    postal_code: "51527",
    full_city_name: "Defiance, IA",
    city_name: "Defiance",
  },
  {
    postal_code: "17323",
    full_city_name: "Franklintown, PA",
    city_name: "Franklintown",
  },
  {
    postal_code: "16311",
    full_city_name: "Carlton, PA",
    city_name: "Carlton",
  },
  {
    postal_code: "81027",
    full_city_name: "Branson, CO",
    city_name: "Branson",
  },
  {
    postal_code: "06242",
    full_city_name: "Eastford, CT",
    city_name: "Eastford",
  },
  {
    postal_code: "51651",
    full_city_name: "Shambaugh, IA",
    city_name: "Shambaugh",
  },
  {
    postal_code: "02561",
    full_city_name: "Sagamore, MA",
    city_name: "Sagamore",
  },
  {
    postal_code: "41862",
    full_city_name: "Topmost, KY",
    city_name: "Topmost",
  },
  {
    postal_code: "07660",
    full_city_name: "Ridgefield Park, NJ",
    city_name: "Ridgefield Park",
  },
  {
    postal_code: "71724",
    full_city_name: "Calion, AR",
    city_name: "Calion",
  },
  {
    postal_code: "36569",
    full_city_name: "Saint Stephens, AL",
    city_name: "Saint Stephens",
  },
  {
    postal_code: "72567",
    full_city_name: "Pleasant Grove, AR",
    city_name: "Pleasant Grove",
  },
  {
    postal_code: "66772",
    full_city_name: "Savonburg, KS",
    city_name: "Savonburg",
  },
  {
    postal_code: "81073",
    full_city_name: "Springfield, CO",
    city_name: "Springfield",
  },
  {
    postal_code: "45678",
    full_city_name: "Scottown, OH",
    city_name: "Scottown",
  },
  {
    postal_code: "58382",
    full_city_name: "Webster, ND",
    city_name: "Webster",
  },
  {
    postal_code: "25141",
    full_city_name: "Nebo, WV",
    city_name: "Nebo",
  },
  {
    postal_code: "64723",
    full_city_name: "Amsterdam, MO",
    city_name: "Amsterdam",
  },
  {
    postal_code: "95420",
    full_city_name: "Caspar, CA",
    city_name: "Caspar",
  },
  {
    postal_code: "76866",
    full_city_name: "Paint Rock, TX",
    city_name: "Paint Rock",
  },
  {
    postal_code: "47541",
    full_city_name: "Holland, IN",
    city_name: "Holland",
  },
  {
    postal_code: "86445",
    full_city_name: "Willow Beach, AZ",
    city_name: "Willow Beach",
  },
  {
    postal_code: "88318",
    full_city_name: "Corona, NM",
    city_name: "Corona",
  },
  {
    postal_code: "66956",
    full_city_name: "Mankato, KS",
    city_name: "Mankato",
  },
  {
    postal_code: "71072",
    full_city_name: "Shongaloo, LA",
    city_name: "Shongaloo",
  },
  {
    postal_code: "96009",
    full_city_name: "Bieber, CA",
    city_name: "Bieber",
  },
  {
    postal_code: "71029",
    full_city_name: "Gilliam, LA",
    city_name: "Gilliam",
  },
  {
    postal_code: "55712",
    full_city_name: "Bruno, MN",
    city_name: "Bruno",
  },
  {
    postal_code: "79755",
    full_city_name: "Midkiff, TX",
    city_name: "Midkiff",
  },
  {
    postal_code: "57770",
    full_city_name: "Pine Ridge, SD",
    city_name: "Pine Ridge",
  },
  {
    postal_code: "43946",
    full_city_name: "Sardis, OH",
    city_name: "Sardis",
  },
  {
    postal_code: "67458",
    full_city_name: "Longford, KS",
    city_name: "Longford",
  },
  {
    postal_code: "56230",
    full_city_name: "Danube, MN",
    city_name: "Danube",
  },
  {
    postal_code: "82516",
    full_city_name: "Kinnear, WY",
    city_name: "Kinnear",
  },
  {
    postal_code: "99668",
    full_city_name: "Sleetmute, AK",
    city_name: "Sleetmute",
  },
  {
    postal_code: "66951",
    full_city_name: "Kensington, KS",
    city_name: "Kensington",
  },
  {
    postal_code: "65529",
    full_city_name: "Jerome, MO",
    city_name: "Jerome",
  },
  {
    postal_code: "42039",
    full_city_name: "Fancy Farm, KY",
    city_name: "Fancy Farm",
  },
  {
    postal_code: "73542",
    full_city_name: "Frederick, OK",
    city_name: "Frederick",
  },
  {
    postal_code: "33438",
    full_city_name: "Canal Point, FL",
    city_name: "Canal Point",
  },
  {
    postal_code: "56225",
    full_city_name: "Clinton, MN",
    city_name: "Clinton",
  },
  {
    postal_code: "13117",
    full_city_name: "Montezuma, NY",
    city_name: "Montezuma",
  },
  {
    postal_code: "95550",
    full_city_name: "Korbel, CA",
    city_name: "Korbel",
  },
  {
    postal_code: "46778",
    full_city_name: "Petroleum, IN",
    city_name: "Petroleum",
  },
  {
    postal_code: "50071",
    full_city_name: "Dows, IA",
    city_name: "Dows",
  },
  {
    postal_code: "57725",
    full_city_name: "Caputa, SD",
    city_name: "Caputa",
  },
  {
    postal_code: "78944",
    full_city_name: "Industry, TX",
    city_name: "Industry",
  },
  {
    postal_code: "70556",
    full_city_name: "Mermentau, LA",
    city_name: "Mermentau",
  },
  {
    postal_code: "99605",
    full_city_name: "Hope, AK",
    city_name: "Hope",
  },
  {
    postal_code: "68041",
    full_city_name: "Mead, NE",
    city_name: "Mead",
  },
  {
    postal_code: "15410",
    full_city_name: "Adah, PA",
    city_name: "Adah",
  },
  {
    postal_code: "47165",
    full_city_name: "Pekin, IN",
    city_name: "Pekin",
  },
  {
    postal_code: "13468",
    full_city_name: "Springfield Center, NY",
    city_name: "Springfield Center",
  },
  {
    postal_code: "21665",
    full_city_name: "Sherwood, MD",
    city_name: "Sherwood",
  },
  {
    postal_code: "78141",
    full_city_name: "Nordheim, TX",
    city_name: "Nordheim",
  },
  {
    postal_code: "61377",
    full_city_name: "Wenona, IL",
    city_name: "Wenona",
  },
  {
    postal_code: "70729",
    full_city_name: "Erwinville, LA",
    city_name: "Erwinville",
  },
  {
    postal_code: "68423",
    full_city_name: "Pleasant Dale, NE",
    city_name: "Pleasant Dale",
  },
  {
    postal_code: "49276",
    full_city_name: "Riga, MI",
    city_name: "Riga",
  },
  {
    postal_code: "16211",
    full_city_name: "Beyer, PA",
    city_name: "Beyer",
  },
  {
    postal_code: "14752",
    full_city_name: "Lily Dale, NY",
    city_name: "Lily Dale",
  },
  {
    postal_code: "31327",
    full_city_name: "Sapelo Island, GA",
    city_name: "Sapelo Island",
  },
  {
    postal_code: "31789",
    full_city_name: "Sumner, GA",
    city_name: "Sumner",
  },
  {
    postal_code: "59453",
    full_city_name: "Judith Gap, MT",
    city_name: "Judith Gap",
  },
  {
    postal_code: "57018",
    full_city_name: "Colton, SD",
    city_name: "Colton",
  },
  {
    postal_code: "61625",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "60933",
    full_city_name: "Elliott, IL",
    city_name: "Elliott",
  },
  {
    postal_code: "25051",
    full_city_name: "Costa, WV",
    city_name: "Costa",
  },
  {
    postal_code: "61421",
    full_city_name: "Bradford, IL",
    city_name: "Bradford",
  },
  {
    postal_code: "31058",
    full_city_name: "Mauk, GA",
    city_name: "Mauk",
  },
  {
    postal_code: "56660",
    full_city_name: "Mizpah, MN",
    city_name: "Mizpah",
  },
  {
    postal_code: "46914",
    full_city_name: "Bunker Hill, IN",
    city_name: "Bunker Hill",
  },
  {
    postal_code: "67581",
    full_city_name: "Sylvia, KS",
    city_name: "Sylvia",
  },
  {
    postal_code: "83425",
    full_city_name: "Hamer, ID",
    city_name: "Hamer",
  },
  {
    postal_code: "57456",
    full_city_name: "Leola, SD",
    city_name: "Leola",
  },
  {
    postal_code: "51546",
    full_city_name: "Logan, IA",
    city_name: "Logan",
  },
  {
    postal_code: "79095",
    full_city_name: "Wellington, TX",
    city_name: "Wellington",
  },
  {
    postal_code: "39633",
    full_city_name: "Crosby, MS",
    city_name: "Crosby",
  },
  {
    postal_code: "75155",
    full_city_name: "Rice, TX",
    city_name: "Rice",
  },
  {
    postal_code: "61345",
    full_city_name: "Neponset, IL",
    city_name: "Neponset",
  },
  {
    postal_code: "72852",
    full_city_name: "Oark, AR",
    city_name: "Oark",
  },
  {
    postal_code: "59351",
    full_city_name: "Volborg, MT",
    city_name: "Volborg",
  },
  {
    postal_code: "14753",
    full_city_name: "Limestone, NY",
    city_name: "Limestone",
  },
  {
    postal_code: "37765",
    full_city_name: "Kyles Ford, TN",
    city_name: "Kyles Ford",
  },
  {
    postal_code: "50673",
    full_city_name: "Stout, IA",
    city_name: "Stout",
  },
  {
    postal_code: "99923",
    full_city_name: "Hyder, AK",
    city_name: "Hyder",
  },
  {
    postal_code: "55713",
    full_city_name: "Buhl, MN",
    city_name: "Buhl",
  },
  {
    postal_code: "71435",
    full_city_name: "Grayson, LA",
    city_name: "Grayson",
  },
  {
    postal_code: "83541",
    full_city_name: "Lenore, ID",
    city_name: "Lenore",
  },
  {
    postal_code: "41845",
    full_city_name: "Premium, KY",
    city_name: "Premium",
  },
  {
    postal_code: "30660",
    full_city_name: "Rayle, GA",
    city_name: "Rayle",
  },
  {
    postal_code: "52570",
    full_city_name: "Milton, IA",
    city_name: "Milton",
  },
  {
    postal_code: "83342",
    full_city_name: "Malta, ID",
    city_name: "Malta",
  },
  {
    postal_code: "04761",
    full_city_name: "New Limerick, ME",
    city_name: "New Limerick",
  },
  {
    postal_code: "17982",
    full_city_name: "Tuscarora, PA",
    city_name: "Tuscarora",
  },
  {
    postal_code: "95548",
    full_city_name: "Klamath, CA",
    city_name: "Klamath",
  },
  {
    postal_code: "57446",
    full_city_name: "Hecla, SD",
    city_name: "Hecla",
  },
  {
    postal_code: "72030",
    full_city_name: "Cleveland, AR",
    city_name: "Cleveland",
  },
  {
    postal_code: "25936",
    full_city_name: "Thurmond, WV",
    city_name: "Thurmond",
  },
  {
    postal_code: "62571",
    full_city_name: "Tower Hill, IL",
    city_name: "Tower Hill",
  },
  {
    postal_code: "42406",
    full_city_name: "Corydon, KY",
    city_name: "Corydon",
  },
  {
    postal_code: "35442",
    full_city_name: "Aliceville, AL",
    city_name: "Aliceville",
  },
  {
    postal_code: "39478",
    full_city_name: "Sandy Hook, MS",
    city_name: "Sandy Hook",
  },
  {
    postal_code: "96796",
    full_city_name: "Waimea, HI",
    city_name: "Waimea",
  },
  {
    postal_code: "61811",
    full_city_name: "Alvin, IL",
    city_name: "Alvin",
  },
  {
    postal_code: "84739",
    full_city_name: "Joseph, UT",
    city_name: "Joseph",
  },
  {
    postal_code: "56145",
    full_city_name: "Jeffers, MN",
    city_name: "Jeffers",
  },
  {
    postal_code: "31054",
    full_city_name: "Mc Intyre, GA",
    city_name: "Mc Intyre",
  },
  {
    postal_code: "38929",
    full_city_name: "Gore Springs, MS",
    city_name: "Gore Springs",
  },
  {
    postal_code: "80476",
    full_city_name: "Silver Plume, CO",
    city_name: "Silver Plume",
  },
  {
    postal_code: "87029",
    full_city_name: "Lindrith, NM",
    city_name: "Lindrith",
  },
  {
    postal_code: "42461",
    full_city_name: "Uniontown, KY",
    city_name: "Uniontown",
  },
  {
    postal_code: "24239",
    full_city_name: "Davenport, VA",
    city_name: "Davenport",
  },
  {
    postal_code: "16650",
    full_city_name: "Hopewell, PA",
    city_name: "Hopewell",
  },
  {
    postal_code: "62013",
    full_city_name: "Brussels, IL",
    city_name: "Brussels",
  },
  {
    postal_code: "03583",
    full_city_name: "Jefferson, NH",
    city_name: "Jefferson",
  },
  {
    postal_code: "73661",
    full_city_name: "Rocky, OK",
    city_name: "Rocky",
  },
  {
    postal_code: "71447",
    full_city_name: "Lena, LA",
    city_name: "Lena",
  },
  {
    postal_code: "01222",
    full_city_name: "Ashley Falls, MA",
    city_name: "Ashley Falls",
  },
  {
    postal_code: "84775",
    full_city_name: "Torrey, UT",
    city_name: "Torrey",
  },
  {
    postal_code: "58565",
    full_city_name: "Riverdale, ND",
    city_name: "Riverdale",
  },
  {
    postal_code: "50531",
    full_city_name: "Dolliver, IA",
    city_name: "Dolliver",
  },
  {
    postal_code: "12418",
    full_city_name: "Cornwallville, NY",
    city_name: "Cornwallville",
  },
  {
    postal_code: "15060",
    full_city_name: "Midway, PA",
    city_name: "Midway",
  },
  {
    postal_code: "35661",
    full_city_name: "Muscle Shoals, AL",
    city_name: "Muscle Shoals",
  },
  {
    postal_code: "72456",
    full_city_name: "Pollard, AR",
    city_name: "Pollard",
  },
  {
    postal_code: "68865",
    full_city_name: "Phillips, NE",
    city_name: "Phillips",
  },
  {
    postal_code: "05739",
    full_city_name: "Danby, VT",
    city_name: "Danby",
  },
  {
    postal_code: "57738",
    full_city_name: "Fairburn, SD",
    city_name: "Fairburn",
  },
  {
    postal_code: "21034",
    full_city_name: "Darlington, MD",
    city_name: "Darlington",
  },
  {
    postal_code: "54623",
    full_city_name: "Coon Valley, WI",
    city_name: "Coon Valley",
  },
  {
    postal_code: "79772",
    full_city_name: "Pecos, TX",
    city_name: "Pecos",
  },
  {
    postal_code: "74727",
    full_city_name: "Boswell, OK",
    city_name: "Boswell",
  },
  {
    postal_code: "41740",
    full_city_name: "Emmalena, KY",
    city_name: "Emmalena",
  },
  {
    postal_code: "74760",
    full_city_name: "Spencerville, OK",
    city_name: "Spencerville",
  },
  {
    postal_code: "88052",
    full_city_name: "Organ, NM",
    city_name: "Organ",
  },
  {
    postal_code: "57368",
    full_city_name: "Plankinton, SD",
    city_name: "Plankinton",
  },
  {
    postal_code: "71045",
    full_city_name: "Jamestown, LA",
    city_name: "Jamestown",
  },
  {
    postal_code: "15539",
    full_city_name: "Fishertown, PA",
    city_name: "Fishertown",
  },
  {
    postal_code: "21829",
    full_city_name: "Girdletree, MD",
    city_name: "Girdletree",
  },
  {
    postal_code: "54765",
    full_city_name: "Sand Creek, WI",
    city_name: "Sand Creek",
  },
  {
    postal_code: "15459",
    full_city_name: "Markleysburg, PA",
    city_name: "Markleysburg",
  },
  {
    postal_code: "16123",
    full_city_name: "Fombell, PA",
    city_name: "Fombell",
  },
  {
    postal_code: "35593",
    full_city_name: "Vina, AL",
    city_name: "Vina",
  },
  {
    postal_code: "56336",
    full_city_name: "Grey Eagle, MN",
    city_name: "Grey Eagle",
  },
  {
    postal_code: "66714",
    full_city_name: "Benedict, KS",
    city_name: "Benedict",
  },
  {
    postal_code: "16436",
    full_city_name: "Spring Creek, PA",
    city_name: "Spring Creek",
  },
  {
    postal_code: "56226",
    full_city_name: "Clontarf, MN",
    city_name: "Clontarf",
  },
  {
    postal_code: "68728",
    full_city_name: "Concord, NE",
    city_name: "Concord",
  },
  {
    postal_code: "78005",
    full_city_name: "Bigfoot, TX",
    city_name: "Bigfoot",
  },
  {
    postal_code: "29567",
    full_city_name: "Little Rock, SC",
    city_name: "Little Rock",
  },
  {
    postal_code: "62467",
    full_city_name: "Teutopolis, IL",
    city_name: "Teutopolis",
  },
  {
    postal_code: "16352",
    full_city_name: "Tiona, PA",
    city_name: "Tiona",
  },
  {
    postal_code: "52560",
    full_city_name: "Floris, IA",
    city_name: "Floris",
  },
  {
    postal_code: "57313",
    full_city_name: "Armour, SD",
    city_name: "Armour",
  },
  {
    postal_code: "43802",
    full_city_name: "Adamsville, OH",
    city_name: "Adamsville",
  },
  {
    postal_code: "66078",
    full_city_name: "Princeton, KS",
    city_name: "Princeton",
  },
  {
    postal_code: "07457",
    full_city_name: "Riverdale, NJ",
    city_name: "Riverdale",
  },
  {
    postal_code: "12811",
    full_city_name: "Bakers Mills, NY",
    city_name: "Bakers Mills",
  },
  {
    postal_code: "76484",
    full_city_name: "Palo Pinto, TX",
    city_name: "Palo Pinto",
  },
  {
    postal_code: "46988",
    full_city_name: "Twelve Mile, IN",
    city_name: "Twelve Mile",
  },
  {
    postal_code: "50557",
    full_city_name: "Lehigh, IA",
    city_name: "Lehigh",
  },
  {
    postal_code: "05084",
    full_city_name: "West Hartford, VT",
    city_name: "West Hartford",
  },
  {
    postal_code: "70650",
    full_city_name: "Lacassine, LA",
    city_name: "Lacassine",
  },
  {
    postal_code: "24265",
    full_city_name: "Keokee, VA",
    city_name: "Keokee",
  },
  {
    postal_code: "72581",
    full_city_name: "Tumbling Shoals, AR",
    city_name: "Tumbling Shoals",
  },
  {
    postal_code: "53035",
    full_city_name: "Iron Ridge, WI",
    city_name: "Iron Ridge",
  },
  {
    postal_code: "49962",
    full_city_name: "Skanee, MI",
    city_name: "Skanee",
  },
  {
    postal_code: "52586",
    full_city_name: "Rose Hill, IA",
    city_name: "Rose Hill",
  },
  {
    postal_code: "68648",
    full_city_name: "Morse Bluff, NE",
    city_name: "Morse Bluff",
  },
  {
    postal_code: "52155",
    full_city_name: "Lime Springs, IA",
    city_name: "Lime Springs",
  },
  {
    postal_code: "59337",
    full_city_name: "Jordan, MT",
    city_name: "Jordan",
  },
  {
    postal_code: "04613",
    full_city_name: "Birch Harbor, ME",
    city_name: "Birch Harbor",
  },
  {
    postal_code: "94512",
    full_city_name: "Birds Landing, CA",
    city_name: "Birds Landing",
  },
  {
    postal_code: "64632",
    full_city_name: "Cainsville, MO",
    city_name: "Cainsville",
  },
  {
    postal_code: "35634",
    full_city_name: "Florence, AL",
    city_name: "Florence",
  },
  {
    postal_code: "01242",
    full_city_name: "Lenox Dale, MA",
    city_name: "Lenox Dale",
  },
  {
    postal_code: "83232",
    full_city_name: "Dayton, ID",
    city_name: "Dayton",
  },
  {
    postal_code: "54862",
    full_city_name: "Ojibwa, WI",
    city_name: "Ojibwa",
  },
  {
    postal_code: "65663",
    full_city_name: "Half Way, MO",
    city_name: "Half Way",
  },
  {
    postal_code: "95939",
    full_city_name: "Elk Creek, CA",
    city_name: "Elk Creek",
  },
  {
    postal_code: "14441",
    full_city_name: "Dresden, NY",
    city_name: "Dresden",
  },
  {
    postal_code: "63629",
    full_city_name: "Bunker, MO",
    city_name: "Bunker",
  },
  {
    postal_code: "93224",
    full_city_name: "Fellows, CA",
    city_name: "Fellows",
  },
  {
    postal_code: "18333",
    full_city_name: "Kresgeville, PA",
    city_name: "Kresgeville",
  },
  {
    postal_code: "63830",
    full_city_name: "Caruthersville, MO",
    city_name: "Caruthersville",
  },
  {
    postal_code: "81059",
    full_city_name: "Model, CO",
    city_name: "Model",
  },
  {
    postal_code: "79367",
    full_city_name: "Smyer, TX",
    city_name: "Smyer",
  },
  {
    postal_code: "23964",
    full_city_name: "Red Oak, VA",
    city_name: "Red Oak",
  },
  {
    postal_code: "63869",
    full_city_name: "New Madrid, MO",
    city_name: "New Madrid",
  },
  {
    postal_code: "97877",
    full_city_name: "Sumpter, OR",
    city_name: "Sumpter",
  },
  {
    postal_code: "14506",
    full_city_name: "Mendon, NY",
    city_name: "Mendon",
  },
  {
    postal_code: "04774",
    full_city_name: "Saint Francis, ME",
    city_name: "Saint Francis",
  },
  {
    postal_code: "53557",
    full_city_name: "Lowell, WI",
    city_name: "Lowell",
  },
  {
    postal_code: "39752",
    full_city_name: "Mathiston, MS",
    city_name: "Mathiston",
  },
  {
    postal_code: "98330",
    full_city_name: "Elbe, WA",
    city_name: "Elbe",
  },
  {
    postal_code: "50648",
    full_city_name: "Jesup, IA",
    city_name: "Jesup",
  },
  {
    postal_code: "68925",
    full_city_name: "Ayr, NE",
    city_name: "Ayr",
  },
  {
    postal_code: "13777",
    full_city_name: "Glen Aubrey, NY",
    city_name: "Glen Aubrey",
  },
  {
    postal_code: "15559",
    full_city_name: "Schellsburg, PA",
    city_name: "Schellsburg",
  },
  {
    postal_code: "58282",
    full_city_name: "Walhalla, ND",
    city_name: "Walhalla",
  },
  {
    postal_code: "42453",
    full_city_name: "Saint Charles, KY",
    city_name: "Saint Charles",
  },
  {
    postal_code: "15536",
    full_city_name: "Crystal Spring, PA",
    city_name: "Crystal Spring",
  },
  {
    postal_code: "97419",
    full_city_name: "Cheshire, OR",
    city_name: "Cheshire",
  },
  {
    postal_code: "99017",
    full_city_name: "Lamont, WA",
    city_name: "Lamont",
  },
  {
    postal_code: "82831",
    full_city_name: "Arvada, WY",
    city_name: "Arvada",
  },
  {
    postal_code: "50001",
    full_city_name: "Ackworth, IA",
    city_name: "Ackworth",
  },
  {
    postal_code: "88124",
    full_city_name: "Melrose, NM",
    city_name: "Melrose",
  },
  {
    postal_code: "57238",
    full_city_name: "Goodwin, SD",
    city_name: "Goodwin",
  },
  {
    postal_code: "97904",
    full_city_name: "Drewsey, OR",
    city_name: "Drewsey",
  },
  {
    postal_code: "83116",
    full_city_name: "Diamondville, WY",
    city_name: "Diamondville",
  },
  {
    postal_code: "05669",
    full_city_name: "Roxbury, VT",
    city_name: "Roxbury",
  },
  {
    postal_code: "85191",
    full_city_name: "Valley Farms, AZ",
    city_name: "Valley Farms",
  },
  {
    postal_code: "66094",
    full_city_name: "White Cloud, KS",
    city_name: "White Cloud",
  },
  {
    postal_code: "79243",
    full_city_name: "Mcadoo, TX",
    city_name: "Mcadoo",
  },
  {
    postal_code: "87562",
    full_city_name: "Rowe, NM",
    city_name: "Rowe",
  },
  {
    postal_code: "97625",
    full_city_name: "Dairy, OR",
    city_name: "Dairy",
  },
  {
    postal_code: "50859",
    full_city_name: "Prescott, IA",
    city_name: "Prescott",
  },
  {
    postal_code: "33890",
    full_city_name: "Zolfo Springs, FL",
    city_name: "Zolfo Springs",
  },
  {
    postal_code: "82714",
    full_city_name: "Devils Tower, WY",
    city_name: "Devils Tower",
  },
  {
    postal_code: "49788",
    full_city_name: "Kincheloe, MI",
    city_name: "Kincheloe",
  },
  {
    postal_code: "13620",
    full_city_name: "Castorland, NY",
    city_name: "Castorland",
  },
  {
    postal_code: "14054",
    full_city_name: "East Bethany, NY",
    city_name: "East Bethany",
  },
  {
    postal_code: "73949",
    full_city_name: "Texhoma, OK",
    city_name: "Texhoma",
  },
  {
    postal_code: "67844",
    full_city_name: "Fowler, KS",
    city_name: "Fowler",
  },
  {
    postal_code: "38720",
    full_city_name: "Alligator, MS",
    city_name: "Alligator",
  },
  {
    postal_code: "73760",
    full_city_name: "Meno, OK",
    city_name: "Meno",
  },
  {
    postal_code: "71959",
    full_city_name: "Newhope, AR",
    city_name: "Newhope",
  },
  {
    postal_code: "59631",
    full_city_name: "Basin, MT",
    city_name: "Basin",
  },
  {
    postal_code: "74736",
    full_city_name: "Garvin, OK",
    city_name: "Garvin",
  },
  {
    postal_code: "50268",
    full_city_name: "What Cheer, IA",
    city_name: "What Cheer",
  },
  {
    postal_code: "10986",
    full_city_name: "Tomkins Cove, NY",
    city_name: "Tomkins Cove",
  },
  {
    postal_code: "37691",
    full_city_name: "Trade, TN",
    city_name: "Trade",
  },
  {
    postal_code: "63673",
    full_city_name: "Saint Mary, MO",
    city_name: "Saint Mary",
  },
  {
    postal_code: "58792",
    full_city_name: "Voltaire, ND",
    city_name: "Voltaire",
  },
  {
    postal_code: "61775",
    full_city_name: "Strawn, IL",
    city_name: "Strawn",
  },
  {
    postal_code: "89046",
    full_city_name: "Searchlight, NV",
    city_name: "Searchlight",
  },
  {
    postal_code: "95444",
    full_city_name: "Graton, CA",
    city_name: "Graton",
  },
  {
    postal_code: "38746",
    full_city_name: "Gunnison, MS",
    city_name: "Gunnison",
  },
  {
    postal_code: "76845",
    full_city_name: "Gouldbusk, TX",
    city_name: "Gouldbusk",
  },
  {
    postal_code: "36035",
    full_city_name: "Goshen, AL",
    city_name: "Goshen",
  },
  {
    postal_code: "83214",
    full_city_name: "Arimo, ID",
    city_name: "Arimo",
  },
  {
    postal_code: "54446",
    full_city_name: "Loyal, WI",
    city_name: "Loyal",
  },
  {
    postal_code: "14004",
    full_city_name: "Alden, NY",
    city_name: "Alden",
  },
  {
    postal_code: "70049",
    full_city_name: "Edgard, LA",
    city_name: "Edgard",
  },
  {
    postal_code: "80727",
    full_city_name: "Eckley, CO",
    city_name: "Eckley",
  },
  {
    postal_code: "89045",
    full_city_name: "Round Mountain, NV",
    city_name: "Round Mountain",
  },
  {
    postal_code: "17930",
    full_city_name: "Cumbola, PA",
    city_name: "Cumbola",
  },
  {
    postal_code: "15628",
    full_city_name: "Donegal, PA",
    city_name: "Donegal",
  },
  {
    postal_code: "67047",
    full_city_name: "Fall River, KS",
    city_name: "Fall River",
  },
  {
    postal_code: "64498",
    full_city_name: "Westboro, MO",
    city_name: "Westboro",
  },
  {
    postal_code: "18420",
    full_city_name: "Fleetville, PA",
    city_name: "Fleetville",
  },
  {
    postal_code: "43716",
    full_city_name: "Beallsville, OH",
    city_name: "Beallsville",
  },
  {
    postal_code: "64661",
    full_city_name: "Mercer, MO",
    city_name: "Mercer",
  },
  {
    postal_code: "36444",
    full_city_name: "Franklin, AL",
    city_name: "Franklin",
  },
  {
    postal_code: "72412",
    full_city_name: "Beech Grove, AR",
    city_name: "Beech Grove",
  },
  {
    postal_code: "15927",
    full_city_name: "Colver, PA",
    city_name: "Colver",
  },
  {
    postal_code: "34141",
    full_city_name: "Ochopee, FL",
    city_name: "Ochopee",
  },
  {
    postal_code: "56186",
    full_city_name: "Woodstock, MN",
    city_name: "Woodstock",
  },
  {
    postal_code: "73660",
    full_city_name: "Reydon, OK",
    city_name: "Reydon",
  },
  {
    postal_code: "30272",
    full_city_name: "Red Oak, GA",
    city_name: "Red Oak",
  },
  {
    postal_code: "15870",
    full_city_name: "Wilcox, PA",
    city_name: "Wilcox",
  },
  {
    postal_code: "58480",
    full_city_name: "Sanborn, ND",
    city_name: "Sanborn",
  },
  {
    postal_code: "87560",
    full_city_name: "Ribera, NM",
    city_name: "Ribera",
  },
  {
    postal_code: "56522",
    full_city_name: "Campbell, MN",
    city_name: "Campbell",
  },
  {
    postal_code: "36353",
    full_city_name: "Newville, AL",
    city_name: "Newville",
  },
  {
    postal_code: "25908",
    full_city_name: "Princewick, WV",
    city_name: "Princewick",
  },
  {
    postal_code: "25506",
    full_city_name: "Branchland, WV",
    city_name: "Branchland",
  },
  {
    postal_code: "15821",
    full_city_name: "Benezett, PA",
    city_name: "Benezett",
  },
  {
    postal_code: "95493",
    full_city_name: "Witter Springs, CA",
    city_name: "Witter Springs",
  },
  {
    postal_code: "99612",
    full_city_name: "King Cove, AK",
    city_name: "King Cove",
  },
  {
    postal_code: "98817",
    full_city_name: "Chelan Falls, WA",
    city_name: "Chelan Falls",
  },
  {
    postal_code: "55781",
    full_city_name: "Side Lake, MN",
    city_name: "Side Lake",
  },
  {
    postal_code: "95676",
    full_city_name: "Robbins, CA",
    city_name: "Robbins",
  },
  {
    postal_code: "72566",
    full_city_name: "Pineville, AR",
    city_name: "Pineville",
  },
  {
    postal_code: "41739",
    full_city_name: "Dwarf, KY",
    city_name: "Dwarf",
  },
  {
    postal_code: "15780",
    full_city_name: "Valier, PA",
    city_name: "Valier",
  },
  {
    postal_code: "87357",
    full_city_name: "Pinehill, NM",
    city_name: "Pinehill",
  },
  {
    postal_code: "65468",
    full_city_name: "Eunice, MO",
    city_name: "Eunice",
  },
  {
    postal_code: "05840",
    full_city_name: "Granby, VT",
    city_name: "Granby",
  },
  {
    postal_code: "05472",
    full_city_name: "New Haven, VT",
    city_name: "New Haven",
  },
  {
    postal_code: "15350",
    full_city_name: "Muse, PA",
    city_name: "Muse",
  },
  {
    postal_code: "18237",
    full_city_name: "Mcadoo, PA",
    city_name: "Mcadoo",
  },
  {
    postal_code: "56166",
    full_city_name: "Revere, MN",
    city_name: "Revere",
  },
  {
    postal_code: "71357",
    full_city_name: "Newellton, LA",
    city_name: "Newellton",
  },
  {
    postal_code: "47975",
    full_city_name: "Pine Village, IN",
    city_name: "Pine Village",
  },
  {
    postal_code: "13482",
    full_city_name: "West Burlington, NY",
    city_name: "West Burlington",
  },
  {
    postal_code: "90021",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "12943",
    full_city_name: "Keene Valley, NY",
    city_name: "Keene Valley",
  },
  {
    postal_code: "04628",
    full_city_name: "Dennysville, ME",
    city_name: "Dennysville",
  },
  {
    postal_code: "38771",
    full_city_name: "Ruleville, MS",
    city_name: "Ruleville",
  },
  {
    postal_code: "76554",
    full_city_name: "Little River Academy, TX",
    city_name: "Little River Academy",
  },
  {
    postal_code: "28733",
    full_city_name: "Fontana Dam, NC",
    city_name: "Fontana Dam",
  },
  {
    postal_code: "62665",
    full_city_name: "Meredosia, IL",
    city_name: "Meredosia",
  },
  {
    postal_code: "52168",
    full_city_name: "Spillville, IA",
    city_name: "Spillville",
  },
  {
    postal_code: "68834",
    full_city_name: "Eddyville, NE",
    city_name: "Eddyville",
  },
  {
    postal_code: "97842",
    full_city_name: "Imnaha, OR",
    city_name: "Imnaha",
  },
  {
    postal_code: "49613",
    full_city_name: "Arcadia, MI",
    city_name: "Arcadia",
  },
  {
    postal_code: "99040",
    full_city_name: "Wellpinit, WA",
    city_name: "Wellpinit",
  },
  {
    postal_code: "21675",
    full_city_name: "Wingate, MD",
    city_name: "Wingate",
  },
  {
    postal_code: "17080",
    full_city_name: "Pillow, PA",
    city_name: "Pillow",
  },
  {
    postal_code: "56158",
    full_city_name: "Magnolia, MN",
    city_name: "Magnolia",
  },
  {
    postal_code: "62480",
    full_city_name: "Willow Hill, IL",
    city_name: "Willow Hill",
  },
  {
    postal_code: "86036",
    full_city_name: "Marble Canyon, AZ",
    city_name: "Marble Canyon",
  },
  {
    postal_code: "57375",
    full_city_name: "Stickney, SD",
    city_name: "Stickney",
  },
  {
    postal_code: "72410",
    full_city_name: "Alicia, AR",
    city_name: "Alicia",
  },
  {
    postal_code: "54909",
    full_city_name: "Almond, WI",
    city_name: "Almond",
  },
  {
    postal_code: "24237",
    full_city_name: "Dante, VA",
    city_name: "Dante",
  },
  {
    postal_code: "26210",
    full_city_name: "Adrian, WV",
    city_name: "Adrian",
  },
  {
    postal_code: "74472",
    full_city_name: "Whitefield, OK",
    city_name: "Whitefield",
  },
  {
    postal_code: "25517",
    full_city_name: "Genoa, WV",
    city_name: "Genoa",
  },
  {
    postal_code: "61724",
    full_city_name: "Bellflower, IL",
    city_name: "Bellflower",
  },
  {
    postal_code: "35810",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "78142",
    full_city_name: "Normanna, TX",
    city_name: "Normanna",
  },
  {
    postal_code: "56260",
    full_city_name: "Maynard, MN",
    city_name: "Maynard",
  },
  {
    postal_code: "28606",
    full_city_name: "Boomer, NC",
    city_name: "Boomer",
  },
  {
    postal_code: "41558",
    full_city_name: "Ransom, KY",
    city_name: "Ransom",
  },
  {
    postal_code: "95637",
    full_city_name: "Guinda, CA",
    city_name: "Guinda",
  },
  {
    postal_code: "08224",
    full_city_name: "New Gretna, NJ",
    city_name: "New Gretna",
  },
  {
    postal_code: "75207",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "77616",
    full_city_name: "Fred, TX",
    city_name: "Fred",
  },
  {
    postal_code: "51022",
    full_city_name: "Granville, IA",
    city_name: "Granville",
  },
  {
    postal_code: "16259",
    full_city_name: "Templeton, PA",
    city_name: "Templeton",
  },
  {
    postal_code: "47967",
    full_city_name: "New Richmond, IN",
    city_name: "New Richmond",
  },
  {
    postal_code: "78161",
    full_city_name: "Sutherland Springs, TX",
    city_name: "Sutherland Springs",
  },
  {
    postal_code: "16141",
    full_city_name: "New Galilee, PA",
    city_name: "New Galilee",
  },
  {
    postal_code: "89042",
    full_city_name: "Panaca, NV",
    city_name: "Panaca",
  },
  {
    postal_code: "99147",
    full_city_name: "Lincoln, WA",
    city_name: "Lincoln",
  },
  {
    postal_code: "78376",
    full_city_name: "Realitos, TX",
    city_name: "Realitos",
  },
  {
    postal_code: "47325",
    full_city_name: "Brownsville, IN",
    city_name: "Brownsville",
  },
  {
    postal_code: "67669",
    full_city_name: "Stockton, KS",
    city_name: "Stockton",
  },
  {
    postal_code: "29065",
    full_city_name: "Jenkinsville, SC",
    city_name: "Jenkinsville",
  },
  {
    postal_code: "15078",
    full_city_name: "Slovan, PA",
    city_name: "Slovan",
  },
  {
    postal_code: "58348",
    full_city_name: "Maddock, ND",
    city_name: "Maddock",
  },
  {
    postal_code: "69360",
    full_city_name: "Rushville, NE",
    city_name: "Rushville",
  },
  {
    postal_code: "77448",
    full_city_name: "Hungerford, TX",
    city_name: "Hungerford",
  },
  {
    postal_code: "66945",
    full_city_name: "Hanover, KS",
    city_name: "Hanover",
  },
  {
    postal_code: "03259",
    full_city_name: "North Sandwich, NH",
    city_name: "North Sandwich",
  },
  {
    postal_code: "15467",
    full_city_name: "New Geneva, PA",
    city_name: "New Geneva",
  },
  {
    postal_code: "87553",
    full_city_name: "Penasco, NM",
    city_name: "Penasco",
  },
  {
    postal_code: "04766",
    full_city_name: "Perham, ME",
    city_name: "Perham",
  },
  {
    postal_code: "03590",
    full_city_name: "North Stratford, NH",
    city_name: "North Stratford",
  },
  {
    postal_code: "72851",
    full_city_name: "New Blaine, AR",
    city_name: "New Blaine",
  },
  {
    postal_code: "66015",
    full_city_name: "Colony, KS",
    city_name: "Colony",
  },
  {
    postal_code: "68930",
    full_city_name: "Blue Hill, NE",
    city_name: "Blue Hill",
  },
  {
    postal_code: "38953",
    full_city_name: "Scobey, MS",
    city_name: "Scobey",
  },
  {
    postal_code: "78885",
    full_city_name: "Vanderpool, TX",
    city_name: "Vanderpool",
  },
  {
    postal_code: "60958",
    full_city_name: "Pembroke Township, IL",
    city_name: "Pembroke Township",
  },
  {
    postal_code: "12063",
    full_city_name: "East Schodack, NY",
    city_name: "East Schodack",
  },
  {
    postal_code: "10535",
    full_city_name: "Jefferson Valley, NY",
    city_name: "Jefferson Valley",
  },
  {
    postal_code: "72569",
    full_city_name: "Poughkeepsie, AR",
    city_name: "Poughkeepsie",
  },
  {
    postal_code: "67865",
    full_city_name: "Minneola, KS",
    city_name: "Minneola",
  },
  {
    postal_code: "04271",
    full_city_name: "Paris, ME",
    city_name: "Paris",
  },
  {
    postal_code: "72470",
    full_city_name: "Success, AR",
    city_name: "Success",
  },
  {
    postal_code: "99829",
    full_city_name: "Hoonah, AK",
    city_name: "Hoonah",
  },
  {
    postal_code: "04984",
    full_city_name: "Temple, ME",
    city_name: "Temple",
  },
  {
    postal_code: "58069",
    full_city_name: "Stirum, ND",
    city_name: "Stirum",
  },
  {
    postal_code: "76518",
    full_city_name: "Buckholts, TX",
    city_name: "Buckholts",
  },
  {
    postal_code: "77582",
    full_city_name: "Raywood, TX",
    city_name: "Raywood",
  },
  {
    postal_code: "79565",
    full_city_name: "Westbrook, TX",
    city_name: "Westbrook",
  },
  {
    postal_code: "99927",
    full_city_name: "Point Baker, AK",
    city_name: "Point Baker",
  },
  {
    postal_code: "52219",
    full_city_name: "Prairieburg, IA",
    city_name: "Prairieburg",
  },
  {
    postal_code: "99757",
    full_city_name: "Lake Minchumina, AK",
    city_name: "Lake Minchumina",
  },
  {
    postal_code: "67565",
    full_city_name: "Otis, KS",
    city_name: "Otis",
  },
  {
    postal_code: "29590",
    full_city_name: "Salters, SC",
    city_name: "Salters",
  },
  {
    postal_code: "40923",
    full_city_name: "Cannon, KY",
    city_name: "Cannon",
  },
  {
    postal_code: "28091",
    full_city_name: "Lilesville, NC",
    city_name: "Lilesville",
  },
  {
    postal_code: "88434",
    full_city_name: "San Jon, NM",
    city_name: "San Jon",
  },
  {
    postal_code: "27563",
    full_city_name: "Norlina, NC",
    city_name: "Norlina",
  },
  {
    postal_code: "76701",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "55786",
    full_city_name: "Taconite, MN",
    city_name: "Taconite",
  },
  {
    postal_code: "23358",
    full_city_name: "Hacksneck, VA",
    city_name: "Hacksneck",
  },
  {
    postal_code: "52039",
    full_city_name: "Durango, IA",
    city_name: "Durango",
  },
  {
    postal_code: "57433",
    full_city_name: "Columbia, SD",
    city_name: "Columbia",
  },
  {
    postal_code: "83119",
    full_city_name: "Fairview, WY",
    city_name: "Fairview",
  },
  {
    postal_code: "42753",
    full_city_name: "Knifley, KY",
    city_name: "Knifley",
  },
  {
    postal_code: "99633",
    full_city_name: "Naknek, AK",
    city_name: "Naknek",
  },
  {
    postal_code: "51467",
    full_city_name: "Westside, IA",
    city_name: "Westside",
  },
  {
    postal_code: "59486",
    full_city_name: "Valier, MT",
    city_name: "Valier",
  },
  {
    postal_code: "11965",
    full_city_name: "Shelter Island Heights, NY",
    city_name: "Shelter Island Heights",
  },
  {
    postal_code: "80741",
    full_city_name: "Merino, CO",
    city_name: "Merino",
  },
  {
    postal_code: "38658",
    full_city_name: "Pope, MS",
    city_name: "Pope",
  },
  {
    postal_code: "12031",
    full_city_name: "Carlisle, NY",
    city_name: "Carlisle",
  },
  {
    postal_code: "05034",
    full_city_name: "Bridgewater, VT",
    city_name: "Bridgewater",
  },
  {
    postal_code: "48735",
    full_city_name: "Gagetown, MI",
    city_name: "Gagetown",
  },
  {
    postal_code: "16863",
    full_city_name: "Olanta, PA",
    city_name: "Olanta",
  },
  {
    postal_code: "97839",
    full_city_name: "Lexington, OR",
    city_name: "Lexington",
  },
  {
    postal_code: "21930",
    full_city_name: "Georgetown, MD",
    city_name: "Georgetown",
  },
  {
    postal_code: "63436",
    full_city_name: "Center, MO",
    city_name: "Center",
  },
  {
    postal_code: "12045",
    full_city_name: "Coeymans, NY",
    city_name: "Coeymans",
  },
  {
    postal_code: "16853",
    full_city_name: "Milesburg, PA",
    city_name: "Milesburg",
  },
  {
    postal_code: "95569",
    full_city_name: "Redcrest, CA",
    city_name: "Redcrest",
  },
  {
    postal_code: "52158",
    full_city_name: "Marquette, IA",
    city_name: "Marquette",
  },
  {
    postal_code: "41522",
    full_city_name: "Elkhorn City, KY",
    city_name: "Elkhorn City",
  },
  {
    postal_code: "07446",
    full_city_name: "Ramsey, NJ",
    city_name: "Ramsey",
  },
  {
    postal_code: "72167",
    full_city_name: "Traskwood, AR",
    city_name: "Traskwood",
  },
  {
    postal_code: "41559",
    full_city_name: "Regina, KY",
    city_name: "Regina",
  },
  {
    postal_code: "40350",
    full_city_name: "Moorefield, KY",
    city_name: "Moorefield",
  },
  {
    postal_code: "61239",
    full_city_name: "Carbon Cliff, IL",
    city_name: "Carbon Cliff",
  },
  {
    postal_code: "59028",
    full_city_name: "Fishtail, MT",
    city_name: "Fishtail",
  },
  {
    postal_code: "95140",
    full_city_name: "Mount Hamilton, CA",
    city_name: "Mount Hamilton",
  },
  {
    postal_code: "58445",
    full_city_name: "Grace City, ND",
    city_name: "Grace City",
  },
  {
    postal_code: "13660",
    full_city_name: "Madrid, NY",
    city_name: "Madrid",
  },
  {
    postal_code: "10964",
    full_city_name: "Palisades, NY",
    city_name: "Palisades",
  },
  {
    postal_code: "79057",
    full_city_name: "Mclean, TX",
    city_name: "Mclean",
  },
  {
    postal_code: "18854",
    full_city_name: "Wysox, PA",
    city_name: "Wysox",
  },
  {
    postal_code: "57217",
    full_city_name: "Bradley, SD",
    city_name: "Bradley",
  },
  {
    postal_code: "59854",
    full_city_name: "Ovando, MT",
    city_name: "Ovando",
  },
  {
    postal_code: "47881",
    full_city_name: "Staunton, IN",
    city_name: "Staunton",
  },
  {
    postal_code: "13666",
    full_city_name: "Newton Falls, NY",
    city_name: "Newton Falls",
  },
  {
    postal_code: "56748",
    full_city_name: "Plummer, MN",
    city_name: "Plummer",
  },
  {
    postal_code: "27869",
    full_city_name: "Rich Square, NC",
    city_name: "Rich Square",
  },
  {
    postal_code: "76261",
    full_city_name: "Ringgold, TX",
    city_name: "Ringgold",
  },
  {
    postal_code: "68436",
    full_city_name: "Shickley, NE",
    city_name: "Shickley",
  },
  {
    postal_code: "04456",
    full_city_name: "Levant, ME",
    city_name: "Levant",
  },
  {
    postal_code: "46374",
    full_city_name: "San Pierre, IN",
    city_name: "San Pierre",
  },
  {
    postal_code: "76561",
    full_city_name: "Oglesby, TX",
    city_name: "Oglesby",
  },
  {
    postal_code: "54137",
    full_city_name: "Krakow, WI",
    city_name: "Krakow",
  },
  {
    postal_code: "25124",
    full_city_name: "Liberty, WV",
    city_name: "Liberty",
  },
  {
    postal_code: "45634",
    full_city_name: "Hamden, OH",
    city_name: "Hamden",
  },
  {
    postal_code: "78836",
    full_city_name: "Catarina, TX",
    city_name: "Catarina",
  },
  {
    postal_code: "97310",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "14872",
    full_city_name: "Pine Valley, NY",
    city_name: "Pine Valley",
  },
  {
    postal_code: "38226",
    full_city_name: "Dukedom, TN",
    city_name: "Dukedom",
  },
  {
    postal_code: "15546",
    full_city_name: "Jenners, PA",
    city_name: "Jenners",
  },
  {
    postal_code: "53069",
    full_city_name: "Okauchee, WI",
    city_name: "Okauchee",
  },
  {
    postal_code: "43030",
    full_city_name: "Jacksontown, OH",
    city_name: "Jacksontown",
  },
  {
    postal_code: "65614",
    full_city_name: "Bradleyville, MO",
    city_name: "Bradleyville",
  },
  {
    postal_code: "83463",
    full_city_name: "Gibbonsville, ID",
    city_name: "Gibbonsville",
  },
  {
    postal_code: "23874",
    full_city_name: "Newsoms, VA",
    city_name: "Newsoms",
  },
  {
    postal_code: "38368",
    full_city_name: "Reagan, TN",
    city_name: "Reagan",
  },
  {
    postal_code: "03218",
    full_city_name: "Barnstead, NH",
    city_name: "Barnstead",
  },
  {
    postal_code: "27965",
    full_city_name: "Poplar Branch, NC",
    city_name: "Poplar Branch",
  },
  {
    postal_code: "83451",
    full_city_name: "Teton, ID",
    city_name: "Teton",
  },
  {
    postal_code: "08045",
    full_city_name: "Lawnside, NJ",
    city_name: "Lawnside",
  },
  {
    postal_code: "52210",
    full_city_name: "Brandon, IA",
    city_name: "Brandon",
  },
  {
    postal_code: "12810",
    full_city_name: "Athol, NY",
    city_name: "Athol",
  },
  {
    postal_code: "52638",
    full_city_name: "Middletown, IA",
    city_name: "Middletown",
  },
  {
    postal_code: "05486",
    full_city_name: "South Hero, VT",
    city_name: "South Hero",
  },
  {
    postal_code: "26372",
    full_city_name: "Horner, WV",
    city_name: "Horner",
  },
  {
    postal_code: "25882",
    full_city_name: "Mullens, WV",
    city_name: "Mullens",
  },
  {
    postal_code: "87051",
    full_city_name: "San Rafael, NM",
    city_name: "San Rafael",
  },
  {
    postal_code: "45835",
    full_city_name: "Dola, OH",
    city_name: "Dola",
  },
  {
    postal_code: "58331",
    full_city_name: "Egeland, ND",
    city_name: "Egeland",
  },
  {
    postal_code: "46977",
    full_city_name: "Rockfield, IN",
    city_name: "Rockfield",
  },
  {
    postal_code: "93201",
    full_city_name: "Alpaugh, CA",
    city_name: "Alpaugh",
  },
  {
    postal_code: "65535",
    full_city_name: "Leasburg, MO",
    city_name: "Leasburg",
  },
  {
    postal_code: "55979",
    full_city_name: "Utica, MN",
    city_name: "Utica",
  },
  {
    postal_code: "12165",
    full_city_name: "Spencertown, NY",
    city_name: "Spencertown",
  },
  {
    postal_code: "12131",
    full_city_name: "North Blenheim, NY",
    city_name: "North Blenheim",
  },
  {
    postal_code: "67623",
    full_city_name: "Alton, KS",
    city_name: "Alton",
  },
  {
    postal_code: "62201",
    full_city_name: "East Saint Louis, IL",
    city_name: "East Saint Louis",
  },
  {
    postal_code: "82639",
    full_city_name: "Kaycee, WY",
    city_name: "Kaycee",
  },
  {
    postal_code: "89018",
    full_city_name: "Indian Springs, NV",
    city_name: "Indian Springs",
  },
  {
    postal_code: "15665",
    full_city_name: "Manor, PA",
    city_name: "Manor",
  },
  {
    postal_code: "40376",
    full_city_name: "Slade, KY",
    city_name: "Slade",
  },
  {
    postal_code: "87006",
    full_city_name: "Bosque, NM",
    city_name: "Bosque",
  },
  {
    postal_code: "79061",
    full_city_name: "Mobeetie, TX",
    city_name: "Mobeetie",
  },
  {
    postal_code: "61330",
    full_city_name: "La Moille, IL",
    city_name: "La Moille",
  },
  {
    postal_code: "41524",
    full_city_name: "Fedscreek, KY",
    city_name: "Fedscreek",
  },
  {
    postal_code: "05492",
    full_city_name: "Waterville, VT",
    city_name: "Waterville",
  },
  {
    postal_code: "04459",
    full_city_name: "Mattawamkeag, ME",
    city_name: "Mattawamkeag",
  },
  {
    postal_code: "98305",
    full_city_name: "Beaver, WA",
    city_name: "Beaver",
  },
  {
    postal_code: "10933",
    full_city_name: "Johnson, NY",
    city_name: "Johnson",
  },
  {
    postal_code: "56385",
    full_city_name: "Villard, MN",
    city_name: "Villard",
  },
  {
    postal_code: "50444",
    full_city_name: "Hanlontown, IA",
    city_name: "Hanlontown",
  },
  {
    postal_code: "72042",
    full_city_name: "De Witt, AR",
    city_name: "De Witt",
  },
  {
    postal_code: "62621",
    full_city_name: "Bluffs, IL",
    city_name: "Bluffs",
  },
  {
    postal_code: "99572",
    full_city_name: "Cooper Landing, AK",
    city_name: "Cooper Landing",
  },
  {
    postal_code: "55001",
    full_city_name: "Afton, MN",
    city_name: "Afton",
  },
  {
    postal_code: "12763",
    full_city_name: "Mountain Dale, NY",
    city_name: "Mountain Dale",
  },
  {
    postal_code: "47551",
    full_city_name: "Leopold, IN",
    city_name: "Leopold",
  },
  {
    postal_code: "45620",
    full_city_name: "Cheshire, OH",
    city_name: "Cheshire",
  },
  {
    postal_code: "84531",
    full_city_name: "Mexican Hat, UT",
    city_name: "Mexican Hat",
  },
  {
    postal_code: "96136",
    full_city_name: "Wendel, CA",
    city_name: "Wendel",
  },
  {
    postal_code: "95524",
    full_city_name: "Bayside, CA",
    city_name: "Bayside",
  },
  {
    postal_code: "79733",
    full_city_name: "Forsan, TX",
    city_name: "Forsan",
  },
  {
    postal_code: "73750",
    full_city_name: "Kingfisher, OK",
    city_name: "Kingfisher",
  },
  {
    postal_code: "99123",
    full_city_name: "Electric City, WA",
    city_name: "Electric City",
  },
  {
    postal_code: "63936",
    full_city_name: "Dudley, MO",
    city_name: "Dudley",
  },
  {
    postal_code: "37680",
    full_city_name: "Laurel Bloomery, TN",
    city_name: "Laurel Bloomery",
  },
  {
    postal_code: "99568",
    full_city_name: "Clam Gulch, AK",
    city_name: "Clam Gulch",
  },
  {
    postal_code: "25033",
    full_city_name: "Buffalo, WV",
    city_name: "Buffalo",
  },
  {
    postal_code: "42325",
    full_city_name: "Bremen, KY",
    city_name: "Bremen",
  },
  {
    postal_code: "51529",
    full_city_name: "Dunlap, IA",
    city_name: "Dunlap",
  },
  {
    postal_code: "77010",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "52746",
    full_city_name: "Donahue, IA",
    city_name: "Donahue",
  },
  {
    postal_code: "99929",
    full_city_name: "Wrangell, AK",
    city_name: "Wrangell",
  },
  {
    postal_code: "39661",
    full_city_name: "Roxie, MS",
    city_name: "Roxie",
  },
  {
    postal_code: "62314",
    full_city_name: "Baylis, IL",
    city_name: "Baylis",
  },
  {
    postal_code: "87326",
    full_city_name: "Vanderwagen, NM",
    city_name: "Vanderwagen",
  },
  {
    postal_code: "47036",
    full_city_name: "Oldenburg, IN",
    city_name: "Oldenburg",
  },
  {
    postal_code: "76884",
    full_city_name: "Valera, TX",
    city_name: "Valera",
  },
  {
    postal_code: "87578",
    full_city_name: "Truchas, NM",
    city_name: "Truchas",
  },
  {
    postal_code: "49713",
    full_city_name: "Boyne Falls, MI",
    city_name: "Boyne Falls",
  },
  {
    postal_code: "67869",
    full_city_name: "Plains, KS",
    city_name: "Plains",
  },
  {
    postal_code: "16434",
    full_city_name: "Spartansburg, PA",
    city_name: "Spartansburg",
  },
  {
    postal_code: "50597",
    full_city_name: "West Bend, IA",
    city_name: "West Bend",
  },
  {
    postal_code: "87516",
    full_city_name: "Canones, NM",
    city_name: "Canones",
  },
  {
    postal_code: "50026",
    full_city_name: "Bagley, IA",
    city_name: "Bagley",
  },
  {
    postal_code: "79082",
    full_city_name: "Springlake, TX",
    city_name: "Springlake",
  },
  {
    postal_code: "31518",
    full_city_name: "Bristol, GA",
    city_name: "Bristol",
  },
  {
    postal_code: "50858",
    full_city_name: "Orient, IA",
    city_name: "Orient",
  },
  {
    postal_code: "01349",
    full_city_name: "Millers Falls, MA",
    city_name: "Millers Falls",
  },
  {
    postal_code: "79854",
    full_city_name: "Valentine, TX",
    city_name: "Valentine",
  },
  {
    postal_code: "72347",
    full_city_name: "Hickory Ridge, AR",
    city_name: "Hickory Ridge",
  },
  {
    postal_code: "51543",
    full_city_name: "Kimballton, IA",
    city_name: "Kimballton",
  },
  {
    postal_code: "81146",
    full_city_name: "Mosca, CO",
    city_name: "Mosca",
  },
  {
    postal_code: "80736",
    full_city_name: "Iliff, CO",
    city_name: "Iliff",
  },
  {
    postal_code: "65276",
    full_city_name: "Pilot Grove, MO",
    city_name: "Pilot Grove",
  },
  {
    postal_code: "41049",
    full_city_name: "Hillsboro, KY",
    city_name: "Hillsboro",
  },
  {
    postal_code: "65232",
    full_city_name: "Benton City, MO",
    city_name: "Benton City",
  },
  {
    postal_code: "57237",
    full_city_name: "Gary, SD",
    city_name: "Gary",
  },
  {
    postal_code: "25235",
    full_city_name: "Chloe, WV",
    city_name: "Chloe",
  },
  {
    postal_code: "73741",
    full_city_name: "Helena, OK",
    city_name: "Helena",
  },
  {
    postal_code: "88353",
    full_city_name: "Vaughn, NM",
    city_name: "Vaughn",
  },
  {
    postal_code: "66955",
    full_city_name: "Mahaska, KS",
    city_name: "Mahaska",
  },
  {
    postal_code: "29069",
    full_city_name: "Lamar, SC",
    city_name: "Lamar",
  },
  {
    postal_code: "61447",
    full_city_name: "Kirkwood, IL",
    city_name: "Kirkwood",
  },
  {
    postal_code: "73651",
    full_city_name: "Hobart, OK",
    city_name: "Hobart",
  },
  {
    postal_code: "03837",
    full_city_name: "Gilmanton Iron Works, NH",
    city_name: "Gilmanton Iron Works",
  },
  {
    postal_code: "04964",
    full_city_name: "Oquossoc, ME",
    city_name: "Oquossoc",
  },
  {
    postal_code: "29543",
    full_city_name: "Fork, SC",
    city_name: "Fork",
  },
  {
    postal_code: "24861",
    full_city_name: "Maybeury, WV",
    city_name: "Maybeury",
  },
  {
    postal_code: "23846",
    full_city_name: "Elberon, VA",
    city_name: "Elberon",
  },
  {
    postal_code: "57046",
    full_city_name: "Mission Hill, SD",
    city_name: "Mission Hill",
  },
  {
    postal_code: "63942",
    full_city_name: "Gatewood, MO",
    city_name: "Gatewood",
  },
  {
    postal_code: "28672",
    full_city_name: "Scottville, NC",
    city_name: "Scottville",
  },
  {
    postal_code: "82050",
    full_city_name: "Albin, WY",
    city_name: "Albin",
  },
  {
    postal_code: "70532",
    full_city_name: "Elton, LA",
    city_name: "Elton",
  },
  {
    postal_code: "68720",
    full_city_name: "Brunswick, NE",
    city_name: "Brunswick",
  },
  {
    postal_code: "71474",
    full_city_name: "Simpson, LA",
    city_name: "Simpson",
  },
  {
    postal_code: "42368",
    full_city_name: "Reynolds Station, KY",
    city_name: "Reynolds Station",
  },
  {
    postal_code: "15489",
    full_city_name: "West Leisenring, PA",
    city_name: "West Leisenring",
  },
  {
    postal_code: "17747",
    full_city_name: "Loganton, PA",
    city_name: "Loganton",
  },
  {
    postal_code: "15616",
    full_city_name: "Armbrust, PA",
    city_name: "Armbrust",
  },
  {
    postal_code: "72181",
    full_city_name: "Wooster, AR",
    city_name: "Wooster",
  },
  {
    postal_code: "24602",
    full_city_name: "Bandy, VA",
    city_name: "Bandy",
  },
  {
    postal_code: "44049",
    full_city_name: "Kipton, OH",
    city_name: "Kipton",
  },
  {
    postal_code: "67747",
    full_city_name: "Monument, KS",
    city_name: "Monument",
  },
  {
    postal_code: "67859",
    full_city_name: "Kismet, KS",
    city_name: "Kismet",
  },
  {
    postal_code: "73859",
    full_city_name: "Vici, OK",
    city_name: "Vici",
  },
  {
    postal_code: "67838",
    full_city_name: "Deerfield, KS",
    city_name: "Deerfield",
  },
  {
    postal_code: "71067",
    full_city_name: "Princeton, LA",
    city_name: "Princeton",
  },
  {
    postal_code: "81638",
    full_city_name: "Hamilton, CO",
    city_name: "Hamilton",
  },
  {
    postal_code: "58520",
    full_city_name: "Almont, ND",
    city_name: "Almont",
  },
  {
    postal_code: "38661",
    full_city_name: "Red Banks, MS",
    city_name: "Red Banks",
  },
  {
    postal_code: "56153",
    full_city_name: "Leota, MN",
    city_name: "Leota",
  },
  {
    postal_code: "97750",
    full_city_name: "Mitchell, OR",
    city_name: "Mitchell",
  },
  {
    postal_code: "16874",
    full_city_name: "Snow Shoe, PA",
    city_name: "Snow Shoe",
  },
  {
    postal_code: "62376",
    full_city_name: "Ursa, IL",
    city_name: "Ursa",
  },
  {
    postal_code: "05682",
    full_city_name: "Worcester, VT",
    city_name: "Worcester",
  },
  {
    postal_code: "74641",
    full_city_name: "Kaw City, OK",
    city_name: "Kaw City",
  },
  {
    postal_code: "12998",
    full_city_name: "Witherbee, NY",
    city_name: "Witherbee",
  },
  {
    postal_code: "83236",
    full_city_name: "Firth, ID",
    city_name: "Firth",
  },
  {
    postal_code: "38647",
    full_city_name: "Michigan City, MS",
    city_name: "Michigan City",
  },
  {
    postal_code: "62823",
    full_city_name: "Cisne, IL",
    city_name: "Cisne",
  },
  {
    postal_code: "04734",
    full_city_name: "Blaine, ME",
    city_name: "Blaine",
  },
  {
    postal_code: "65082",
    full_city_name: "Tuscumbia, MO",
    city_name: "Tuscumbia",
  },
  {
    postal_code: "54422",
    full_city_name: "Curtiss, WI",
    city_name: "Curtiss",
  },
  {
    postal_code: "59736",
    full_city_name: "Jackson, MT",
    city_name: "Jackson",
  },
  {
    postal_code: "55333",
    full_city_name: "Franklin, MN",
    city_name: "Franklin",
  },
  {
    postal_code: "81248",
    full_city_name: "Sargents, CO",
    city_name: "Sargents",
  },
  {
    postal_code: "83656",
    full_city_name: "Notus, ID",
    city_name: "Notus",
  },
  {
    postal_code: "13655",
    full_city_name: "Hogansburg, NY",
    city_name: "Hogansburg",
  },
  {
    postal_code: "42028",
    full_city_name: "Burna, KY",
    city_name: "Burna",
  },
  {
    postal_code: "56757",
    full_city_name: "Stephen, MN",
    city_name: "Stephen",
  },
  {
    postal_code: "35646",
    full_city_name: "Leighton, AL",
    city_name: "Leighton",
  },
  {
    postal_code: "61930",
    full_city_name: "Hindsboro, IL",
    city_name: "Hindsboro",
  },
  {
    postal_code: "42603",
    full_city_name: "Alpha, KY",
    city_name: "Alpha",
  },
  {
    postal_code: "84763",
    full_city_name: "Rockville, UT",
    city_name: "Rockville",
  },
  {
    postal_code: "65236",
    full_city_name: "Brunswick, MO",
    city_name: "Brunswick",
  },
  {
    postal_code: "24981",
    full_city_name: "Talcott, WV",
    city_name: "Talcott",
  },
  {
    postal_code: "87005",
    full_city_name: "Bluewater, NM",
    city_name: "Bluewater",
  },
  {
    postal_code: "76827",
    full_city_name: "Brookesmith, TX",
    city_name: "Brookesmith",
  },
  {
    postal_code: "25512",
    full_city_name: "East Lynn, WV",
    city_name: "East Lynn",
  },
  {
    postal_code: "58710",
    full_city_name: "Anamoose, ND",
    city_name: "Anamoose",
  },
  {
    postal_code: "08805",
    full_city_name: "Bound Brook, NJ",
    city_name: "Bound Brook",
  },
  {
    postal_code: "88351",
    full_city_name: "Tinnie, NM",
    city_name: "Tinnie",
  },
  {
    postal_code: "56054",
    full_city_name: "Lafayette, MN",
    city_name: "Lafayette",
  },
  {
    postal_code: "36042",
    full_city_name: "Honoraville, AL",
    city_name: "Honoraville",
  },
  {
    postal_code: "51006",
    full_city_name: "Battle Creek, IA",
    city_name: "Battle Creek",
  },
  {
    postal_code: "82052",
    full_city_name: "Buford, WY",
    city_name: "Buford",
  },
  {
    postal_code: "83462",
    full_city_name: "Carmen, ID",
    city_name: "Carmen",
  },
  {
    postal_code: "92267",
    full_city_name: "Parker Dam, CA",
    city_name: "Parker Dam",
  },
  {
    postal_code: "78146",
    full_city_name: "Pettus, TX",
    city_name: "Pettus",
  },
  {
    postal_code: "72669",
    full_city_name: "Pindall, AR",
    city_name: "Pindall",
  },
  {
    postal_code: "66740",
    full_city_name: "Galesburg, KS",
    city_name: "Galesburg",
  },
  {
    postal_code: "15088",
    full_city_name: "West Elizabeth, PA",
    city_name: "West Elizabeth",
  },
  {
    postal_code: "73731",
    full_city_name: "Dacoma, OK",
    city_name: "Dacoma",
  },
  {
    postal_code: "71275",
    full_city_name: "Simsboro, LA",
    city_name: "Simsboro",
  },
  {
    postal_code: "57522",
    full_city_name: "Blunt, SD",
    city_name: "Blunt",
  },
  {
    postal_code: "40025",
    full_city_name: "Glenview, KY",
    city_name: "Glenview",
  },
  {
    postal_code: "22931",
    full_city_name: "Covesville, VA",
    city_name: "Covesville",
  },
  {
    postal_code: "26676",
    full_city_name: "Leivasy, WV",
    city_name: "Leivasy",
  },
  {
    postal_code: "72539",
    full_city_name: "Glencoe, AR",
    city_name: "Glencoe",
  },
  {
    postal_code: "79527",
    full_city_name: "Ira, TX",
    city_name: "Ira",
  },
  {
    postal_code: "73573",
    full_city_name: "Waurika, OK",
    city_name: "Waurika",
  },
  {
    postal_code: "78877",
    full_city_name: "Quemado, TX",
    city_name: "Quemado",
  },
  {
    postal_code: "67103",
    full_city_name: "Mayfield, KS",
    city_name: "Mayfield",
  },
  {
    postal_code: "73073",
    full_city_name: "Orlando, OK",
    city_name: "Orlando",
  },
  {
    postal_code: "66937",
    full_city_name: "Clifton, KS",
    city_name: "Clifton",
  },
  {
    postal_code: "67952",
    full_city_name: "Moscow, KS",
    city_name: "Moscow",
  },
  {
    postal_code: "47449",
    full_city_name: "Newberry, IN",
    city_name: "Newberry",
  },
  {
    postal_code: "79078",
    full_city_name: "Sanford, TX",
    city_name: "Sanford",
  },
  {
    postal_code: "50062",
    full_city_name: "Melcher Dallas, IA",
    city_name: "Melcher Dallas",
  },
  {
    postal_code: "97144",
    full_city_name: "Timber, OR",
    city_name: "Timber",
  },
  {
    postal_code: "73450",
    full_city_name: "Milburn, OK",
    city_name: "Milburn",
  },
  {
    postal_code: "62887",
    full_city_name: "Springerton, IL",
    city_name: "Springerton",
  },
  {
    postal_code: "31648",
    full_city_name: "Statenville, GA",
    city_name: "Statenville",
  },
  {
    postal_code: "16745",
    full_city_name: "Rixford, PA",
    city_name: "Rixford",
  },
  {
    postal_code: "99579",
    full_city_name: "Egegik, AK",
    city_name: "Egegik",
  },
  {
    postal_code: "72564",
    full_city_name: "Oil Trough, AR",
    city_name: "Oil Trough",
  },
  {
    postal_code: "05067",
    full_city_name: "South Pomfret, VT",
    city_name: "South Pomfret",
  },
  {
    postal_code: "57034",
    full_city_name: "Hudson, SD",
    city_name: "Hudson",
  },
  {
    postal_code: "95984",
    full_city_name: "Twain, CA",
    city_name: "Twain",
  },
  {
    postal_code: "04554",
    full_city_name: "New Harbor, ME",
    city_name: "New Harbor",
  },
  {
    postal_code: "13406",
    full_city_name: "Middleville, NY",
    city_name: "Middleville",
  },
  {
    postal_code: "35974",
    full_city_name: "Geraldine, AL",
    city_name: "Geraldine",
  },
  {
    postal_code: "58413",
    full_city_name: "Ashley, ND",
    city_name: "Ashley",
  },
  {
    postal_code: "82411",
    full_city_name: "Burlington, WY",
    city_name: "Burlington",
  },
  {
    postal_code: "27917",
    full_city_name: "Barco, NC",
    city_name: "Barco",
  },
  {
    postal_code: "58649",
    full_city_name: "Reeder, ND",
    city_name: "Reeder",
  },
  {
    postal_code: "33944",
    full_city_name: "Palmdale, FL",
    city_name: "Palmdale",
  },
  {
    postal_code: "16834",
    full_city_name: "Drifting, PA",
    city_name: "Drifting",
  },
  {
    postal_code: "16871",
    full_city_name: "Pottersdale, PA",
    city_name: "Pottersdale",
  },
  {
    postal_code: "88113",
    full_city_name: "Causey, NM",
    city_name: "Causey",
  },
  {
    postal_code: "71043",
    full_city_name: "Hosston, LA",
    city_name: "Hosston",
  },
  {
    postal_code: "68778",
    full_city_name: "Springview, NE",
    city_name: "Springview",
  },
  {
    postal_code: "08733",
    full_city_name: "Lakehurst, NJ",
    city_name: "Lakehurst",
  },
  {
    postal_code: "63441",
    full_city_name: "Frankford, MO",
    city_name: "Frankford",
  },
  {
    postal_code: "63559",
    full_city_name: "Novinger, MO",
    city_name: "Novinger",
  },
  {
    postal_code: "44677",
    full_city_name: "Smithville, OH",
    city_name: "Smithville",
  },
  {
    postal_code: "12139",
    full_city_name: "Piseco, NY",
    city_name: "Piseco",
  },
  {
    postal_code: "16161",
    full_city_name: "Wheatland, PA",
    city_name: "Wheatland",
  },
  {
    postal_code: "61816",
    full_city_name: "Broadlands, IL",
    city_name: "Broadlands",
  },
  {
    postal_code: "27355",
    full_city_name: "Staley, NC",
    city_name: "Staley",
  },
  {
    postal_code: "78946",
    full_city_name: "Ledbetter, TX",
    city_name: "Ledbetter",
  },
  {
    postal_code: "06702",
    full_city_name: "Waterbury, CT",
    city_name: "Waterbury",
  },
  {
    postal_code: "62824",
    full_city_name: "Clay City, IL",
    city_name: "Clay City",
  },
  {
    postal_code: "12164",
    full_city_name: "Speculator, NY",
    city_name: "Speculator",
  },
  {
    postal_code: "25646",
    full_city_name: "Stollings, WV",
    city_name: "Stollings",
  },
  {
    postal_code: "39836",
    full_city_name: "Coleman, GA",
    city_name: "Coleman",
  },
  {
    postal_code: "24935",
    full_city_name: "Forest Hill, WV",
    city_name: "Forest Hill",
  },
  {
    postal_code: "46732",
    full_city_name: "Cromwell, IN",
    city_name: "Cromwell",
  },
  {
    postal_code: "42459",
    full_city_name: "Sturgis, KY",
    city_name: "Sturgis",
  },
  {
    postal_code: "72860",
    full_city_name: "Rover, AR",
    city_name: "Rover",
  },
  {
    postal_code: "93242",
    full_city_name: "Laton, CA",
    city_name: "Laton",
  },
  {
    postal_code: "35469",
    full_city_name: "Knoxville, AL",
    city_name: "Knoxville",
  },
  {
    postal_code: "04929",
    full_city_name: "Detroit, ME",
    city_name: "Detroit",
  },
  {
    postal_code: "24831",
    full_city_name: "Elkhorn, WV",
    city_name: "Elkhorn",
  },
  {
    postal_code: "28708",
    full_city_name: "Balsam Grove, NC",
    city_name: "Balsam Grove",
  },
  {
    postal_code: "62827",
    full_city_name: "Crossville, IL",
    city_name: "Crossville",
  },
  {
    postal_code: "67051",
    full_city_name: "Geuda Springs, KS",
    city_name: "Geuda Springs",
  },
  {
    postal_code: "73728",
    full_city_name: "Cherokee, OK",
    city_name: "Cherokee",
  },
  {
    postal_code: "61450",
    full_city_name: "La Harpe, IL",
    city_name: "La Harpe",
  },
  {
    postal_code: "63541",
    full_city_name: "Glenwood, MO",
    city_name: "Glenwood",
  },
  {
    postal_code: "57015",
    full_city_name: "Chancellor, SD",
    city_name: "Chancellor",
  },
  {
    postal_code: "40363",
    full_city_name: "Perry Park, KY",
    city_name: "Perry Park",
  },
  {
    postal_code: "28587",
    full_city_name: "Vandemere, NC",
    city_name: "Vandemere",
  },
  {
    postal_code: "24934",
    full_city_name: "Dunmore, WV",
    city_name: "Dunmore",
  },
  {
    postal_code: "74741",
    full_city_name: "Hendrix, OK",
    city_name: "Hendrix",
  },
  {
    postal_code: "84755",
    full_city_name: "Mount Carmel, UT",
    city_name: "Mount Carmel",
  },
  {
    postal_code: "71842",
    full_city_name: "Horatio, AR",
    city_name: "Horatio",
  },
  {
    postal_code: "41146",
    full_city_name: "Hitchins, KY",
    city_name: "Hitchins",
  },
  {
    postal_code: "40858",
    full_city_name: "Mozelle, KY",
    city_name: "Mozelle",
  },
  {
    postal_code: "61014",
    full_city_name: "Chadwick, IL",
    city_name: "Chadwick",
  },
  {
    postal_code: "40144",
    full_city_name: "Harned, KY",
    city_name: "Harned",
  },
  {
    postal_code: "51247",
    full_city_name: "Rock Valley, IA",
    city_name: "Rock Valley",
  },
  {
    postal_code: "57322",
    full_city_name: "Carpenter, SD",
    city_name: "Carpenter",
  },
  {
    postal_code: "17306",
    full_city_name: "Bendersville, PA",
    city_name: "Bendersville",
  },
  {
    postal_code: "67450",
    full_city_name: "Holyrood, KS",
    city_name: "Holyrood",
  },
  {
    postal_code: "95429",
    full_city_name: "Dos Rios, CA",
    city_name: "Dos Rios",
  },
  {
    postal_code: "55969",
    full_city_name: "Rollingstone, MN",
    city_name: "Rollingstone",
  },
  {
    postal_code: "62874",
    full_city_name: "Orient, IL",
    city_name: "Orient",
  },
  {
    postal_code: "99684",
    full_city_name: "Unalakleet, AK",
    city_name: "Unalakleet",
  },
  {
    postal_code: "83821",
    full_city_name: "Coolin, ID",
    city_name: "Coolin",
  },
  {
    postal_code: "58572",
    full_city_name: "Sterling, ND",
    city_name: "Sterling",
  },
  {
    postal_code: "95318",
    full_city_name: "El Portal, CA",
    city_name: "El Portal",
  },
  {
    postal_code: "50465",
    full_city_name: "Rake, IA",
    city_name: "Rake",
  },
  {
    postal_code: "45819",
    full_city_name: "Buckland, OH",
    city_name: "Buckland",
  },
  {
    postal_code: "57380",
    full_city_name: "Wagner, SD",
    city_name: "Wagner",
  },
  {
    postal_code: "96119",
    full_city_name: "Madeline, CA",
    city_name: "Madeline",
  },
  {
    postal_code: "98326",
    full_city_name: "Clallam Bay, WA",
    city_name: "Clallam Bay",
  },
  {
    postal_code: "24351",
    full_city_name: "Lambsburg, VA",
    city_name: "Lambsburg",
  },
  {
    postal_code: "46951",
    full_city_name: "Macy, IN",
    city_name: "Macy",
  },
  {
    postal_code: "32443",
    full_city_name: "Greenwood, FL",
    city_name: "Greenwood",
  },
  {
    postal_code: "37078",
    full_city_name: "Hurricane Mills, TN",
    city_name: "Hurricane Mills",
  },
  {
    postal_code: "15940",
    full_city_name: "Loretto, PA",
    city_name: "Loretto",
  },
  {
    postal_code: "39572",
    full_city_name: "Pearlington, MS",
    city_name: "Pearlington",
  },
  {
    postal_code: "63539",
    full_city_name: "Ethel, MO",
    city_name: "Ethel",
  },
  {
    postal_code: "41464",
    full_city_name: "Royalton, KY",
    city_name: "Royalton",
  },
  {
    postal_code: "87551",
    full_city_name: "Los Ojos, NM",
    city_name: "Los Ojos",
  },
  {
    postal_code: "41735",
    full_city_name: "Delphia, KY",
    city_name: "Delphia",
  },
  {
    postal_code: "96124",
    full_city_name: "Calpine, CA",
    city_name: "Calpine",
  },
  {
    postal_code: "71855",
    full_city_name: "Ozan, AR",
    city_name: "Ozan",
  },
  {
    postal_code: "97137",
    full_city_name: "Saint Paul, OR",
    city_name: "Saint Paul",
  },
  {
    postal_code: "61059",
    full_city_name: "Nora, IL",
    city_name: "Nora",
  },
  {
    postal_code: "64668",
    full_city_name: "Norborne, MO",
    city_name: "Norborne",
  },
  {
    postal_code: "39456",
    full_city_name: "Mc Lain, MS",
    city_name: "Mc Lain",
  },
  {
    postal_code: "47942",
    full_city_name: "Earl Park, IN",
    city_name: "Earl Park",
  },
  {
    postal_code: "68774",
    full_city_name: "Saint Helena, NE",
    city_name: "Saint Helena",
  },
  {
    postal_code: "68814",
    full_city_name: "Ansley, NE",
    city_name: "Ansley",
  },
  {
    postal_code: "04558",
    full_city_name: "Pemaquid, ME",
    city_name: "Pemaquid",
  },
  {
    postal_code: "64467",
    full_city_name: "Martinsville, MO",
    city_name: "Martinsville",
  },
  {
    postal_code: "56761",
    full_city_name: "Wannaska, MN",
    city_name: "Wannaska",
  },
  {
    postal_code: "71220",
    full_city_name: "Bastrop, LA",
    city_name: "Bastrop",
  },
  {
    postal_code: "50451",
    full_city_name: "Lakota, IA",
    city_name: "Lakota",
  },
  {
    postal_code: "87061",
    full_city_name: "Torreon, NM",
    city_name: "Torreon",
  },
  {
    postal_code: "82321",
    full_city_name: "Baggs, WY",
    city_name: "Baggs",
  },
  {
    postal_code: "58053",
    full_city_name: "Lidgerwood, ND",
    city_name: "Lidgerwood",
  },
  {
    postal_code: "83526",
    full_city_name: "Ferdinand, ID",
    city_name: "Ferdinand",
  },
  {
    postal_code: "93266",
    full_city_name: "Stratford, CA",
    city_name: "Stratford",
  },
  {
    postal_code: "49958",
    full_city_name: "Pelkie, MI",
    city_name: "Pelkie",
  },
  {
    postal_code: "73726",
    full_city_name: "Carmen, OK",
    city_name: "Carmen",
  },
  {
    postal_code: "51430",
    full_city_name: "Arcadia, IA",
    city_name: "Arcadia",
  },
  {
    postal_code: "79734",
    full_city_name: "Fort Davis, TX",
    city_name: "Fort Davis",
  },
  {
    postal_code: "24850",
    full_city_name: "Jolo, WV",
    city_name: "Jolo",
  },
  {
    postal_code: "38859",
    full_city_name: "New Site, MS",
    city_name: "New Site",
  },
  {
    postal_code: "78563",
    full_city_name: "Linn, TX",
    city_name: "Linn",
  },
  {
    postal_code: "77975",
    full_city_name: "Moulton, TX",
    city_name: "Moulton",
  },
  {
    postal_code: "97870",
    full_city_name: "Richland, OR",
    city_name: "Richland",
  },
  {
    postal_code: "63823",
    full_city_name: "Bertrand, MO",
    city_name: "Bertrand",
  },
  {
    postal_code: "04650",
    full_city_name: "Little Deer Isle, ME",
    city_name: "Little Deer Isle",
  },
  {
    postal_code: "50654",
    full_city_name: "Masonville, IA",
    city_name: "Masonville",
  },
  {
    postal_code: "56035",
    full_city_name: "Geneva, MN",
    city_name: "Geneva",
  },
  {
    postal_code: "14428",
    full_city_name: "Churchville, NY",
    city_name: "Churchville",
  },
  {
    postal_code: "59845",
    full_city_name: "Hot Springs, MT",
    city_name: "Hot Springs",
  },
  {
    postal_code: "31025",
    full_city_name: "Elko, GA",
    city_name: "Elko",
  },
  {
    postal_code: "68827",
    full_city_name: "Chapman, NE",
    city_name: "Chapman",
  },
  {
    postal_code: "42130",
    full_city_name: "Eighty Eight, KY",
    city_name: "Eighty Eight",
  },
  {
    postal_code: "38761",
    full_city_name: "Moorhead, MS",
    city_name: "Moorhead",
  },
  {
    postal_code: "16639",
    full_city_name: "Fallentimber, PA",
    city_name: "Fallentimber",
  },
  {
    postal_code: "42350",
    full_city_name: "Island, KY",
    city_name: "Island",
  },
  {
    postal_code: "63454",
    full_city_name: "Maywood, MO",
    city_name: "Maywood",
  },
  {
    postal_code: "52646",
    full_city_name: "Oakville, IA",
    city_name: "Oakville",
  },
  {
    postal_code: "74766",
    full_city_name: "Wright City, OK",
    city_name: "Wright City",
  },
  {
    postal_code: "53207",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "50420",
    full_city_name: "Alexander, IA",
    city_name: "Alexander",
  },
  {
    postal_code: "65664",
    full_city_name: "Halltown, MO",
    city_name: "Halltown",
  },
  {
    postal_code: "95679",
    full_city_name: "Rumsey, CA",
    city_name: "Rumsey",
  },
  {
    postal_code: "36560",
    full_city_name: "Mount Vernon, AL",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "50541",
    full_city_name: "Gilmore City, IA",
    city_name: "Gilmore City",
  },
  {
    postal_code: "56255",
    full_city_name: "Lucan, MN",
    city_name: "Lucan",
  },
  {
    postal_code: "97522",
    full_city_name: "Butte Falls, OR",
    city_name: "Butte Falls",
  },
  {
    postal_code: "62460",
    full_city_name: "Saint Francisville, IL",
    city_name: "Saint Francisville",
  },
  {
    postal_code: "26716",
    full_city_name: "Eglon, WV",
    city_name: "Eglon",
  },
  {
    postal_code: "24943",
    full_city_name: "Grassy Meadows, WV",
    city_name: "Grassy Meadows",
  },
  {
    postal_code: "29916",
    full_city_name: "Early Branch, SC",
    city_name: "Early Branch",
  },
  {
    postal_code: "65555",
    full_city_name: "Raymondville, MO",
    city_name: "Raymondville",
  },
  {
    postal_code: "84537",
    full_city_name: "Orangeville, UT",
    city_name: "Orangeville",
  },
  {
    postal_code: "43779",
    full_city_name: "Sarahsville, OH",
    city_name: "Sarahsville",
  },
  {
    postal_code: "83233",
    full_city_name: "Dingle, ID",
    city_name: "Dingle",
  },
  {
    postal_code: "35552",
    full_city_name: "Detroit, AL",
    city_name: "Detroit",
  },
  {
    postal_code: "56641",
    full_city_name: "Federal Dam, MN",
    city_name: "Federal Dam",
  },
  {
    postal_code: "61850",
    full_city_name: "Indianola, IL",
    city_name: "Indianola",
  },
  {
    postal_code: "31795",
    full_city_name: "Ty Ty, GA",
    city_name: "Ty Ty",
  },
  {
    postal_code: "78560",
    full_city_name: "La Joya, TX",
    city_name: "La Joya",
  },
  {
    postal_code: "56274",
    full_city_name: "Norcross, MN",
    city_name: "Norcross",
  },
  {
    postal_code: "89883",
    full_city_name: "West Wendover, NV",
    city_name: "West Wendover",
  },
  {
    postal_code: "56139",
    full_city_name: "Holland, MN",
    city_name: "Holland",
  },
  {
    postal_code: "74932",
    full_city_name: "Cameron, OK",
    city_name: "Cameron",
  },
  {
    postal_code: "52656",
    full_city_name: "West Point, IA",
    city_name: "West Point",
  },
  {
    postal_code: "15564",
    full_city_name: "Wellersburg, PA",
    city_name: "Wellersburg",
  },
  {
    postal_code: "72351",
    full_city_name: "Keiser, AR",
    city_name: "Keiser",
  },
  {
    postal_code: "31825",
    full_city_name: "Richland, GA",
    city_name: "Richland",
  },
  {
    postal_code: "70537",
    full_city_name: "Evangeline, LA",
    city_name: "Evangeline",
  },
  {
    postal_code: "96132",
    full_city_name: "Termo, CA",
    city_name: "Termo",
  },
  {
    postal_code: "04686",
    full_city_name: "Wesley, ME",
    city_name: "Wesley",
  },
  {
    postal_code: "72951",
    full_city_name: "Ratcliff, AR",
    city_name: "Ratcliff",
  },
  {
    postal_code: "74338",
    full_city_name: "Colcord, OK",
    city_name: "Colcord",
  },
  {
    postal_code: "83283",
    full_city_name: "Thatcher, ID",
    city_name: "Thatcher",
  },
  {
    postal_code: "26138",
    full_city_name: "Brohard, WV",
    city_name: "Brohard",
  },
  {
    postal_code: "35544",
    full_city_name: "Beaverton, AL",
    city_name: "Beaverton",
  },
  {
    postal_code: "54756",
    full_city_name: "Nelson, WI",
    city_name: "Nelson",
  },
  {
    postal_code: "50235",
    full_city_name: "Rippey, IA",
    city_name: "Rippey",
  },
  {
    postal_code: "32435",
    full_city_name: "Defuniak Springs, FL",
    city_name: "Defuniak Springs",
  },
  {
    postal_code: "32334",
    full_city_name: "Hosford, FL",
    city_name: "Hosford",
  },
  {
    postal_code: "39756",
    full_city_name: "Prairie, MS",
    city_name: "Prairie",
  },
  {
    postal_code: "83314",
    full_city_name: "Bliss, ID",
    city_name: "Bliss",
  },
  {
    postal_code: "18216",
    full_city_name: "Beaver Meadows, PA",
    city_name: "Beaver Meadows",
  },
  {
    postal_code: "71860",
    full_city_name: "Stamps, AR",
    city_name: "Stamps",
  },
  {
    postal_code: "41135",
    full_city_name: "Emerson, KY",
    city_name: "Emerson",
  },
  {
    postal_code: "52584",
    full_city_name: "Pulaski, IA",
    city_name: "Pulaski",
  },
  {
    postal_code: "51552",
    full_city_name: "Marne, IA",
    city_name: "Marne",
  },
  {
    postal_code: "59218",
    full_city_name: "Culbertson, MT",
    city_name: "Culbertson",
  },
  {
    postal_code: "72321",
    full_city_name: "Burdette, AR",
    city_name: "Burdette",
  },
  {
    postal_code: "26320",
    full_city_name: "Alma, WV",
    city_name: "Alma",
  },
  {
    postal_code: "28718",
    full_city_name: "Cedar Mountain, NC",
    city_name: "Cedar Mountain",
  },
  {
    postal_code: "41835",
    full_city_name: "Mc Roberts, KY",
    city_name: "Mc Roberts",
  },
  {
    postal_code: "29164",
    full_city_name: "Wagener, SC",
    city_name: "Wagener",
  },
  {
    postal_code: "65754",
    full_city_name: "Spokane, MO",
    city_name: "Spokane",
  },
  {
    postal_code: "55338",
    full_city_name: "Green Isle, MN",
    city_name: "Green Isle",
  },
  {
    postal_code: "62828",
    full_city_name: "Dahlgren, IL",
    city_name: "Dahlgren",
  },
  {
    postal_code: "86434",
    full_city_name: "Peach Springs, AZ",
    city_name: "Peach Springs",
  },
  {
    postal_code: "83431",
    full_city_name: "Lewisville, ID",
    city_name: "Lewisville",
  },
  {
    postal_code: "31018",
    full_city_name: "Davisboro, GA",
    city_name: "Davisboro",
  },
  {
    postal_code: "54530",
    full_city_name: "Hawkins, WI",
    city_name: "Hawkins",
  },
  {
    postal_code: "74577",
    full_city_name: "Whitesboro, OK",
    city_name: "Whitesboro",
  },
  {
    postal_code: "23438",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "47123",
    full_city_name: "Grantsburg, IN",
    city_name: "Grantsburg",
  },
  {
    postal_code: "05068",
    full_city_name: "South Royalton, VT",
    city_name: "South Royalton",
  },
  {
    postal_code: "58741",
    full_city_name: "Granville, ND",
    city_name: "Granville",
  },
  {
    postal_code: "05670",
    full_city_name: "South Barre, VT",
    city_name: "South Barre",
  },
  {
    postal_code: "17850",
    full_city_name: "Montandon, PA",
    city_name: "Montandon",
  },
  {
    postal_code: "70341",
    full_city_name: "Belle Rose, LA",
    city_name: "Belle Rose",
  },
  {
    postal_code: "79252",
    full_city_name: "Quanah, TX",
    city_name: "Quanah",
  },
  {
    postal_code: "04020",
    full_city_name: "Cornish, ME",
    city_name: "Cornish",
  },
  {
    postal_code: "26590",
    full_city_name: "Wana, WV",
    city_name: "Wana",
  },
  {
    postal_code: "51237",
    full_city_name: "George, IA",
    city_name: "George",
  },
  {
    postal_code: "54485",
    full_city_name: "Summit Lake, WI",
    city_name: "Summit Lake",
  },
  {
    postal_code: "63438",
    full_city_name: "Durham, MO",
    city_name: "Durham",
  },
  {
    postal_code: "78019",
    full_city_name: "Encinal, TX",
    city_name: "Encinal",
  },
  {
    postal_code: "59035",
    full_city_name: "Fort Smith, MT",
    city_name: "Fort Smith",
  },
  {
    postal_code: "88253",
    full_city_name: "Lake Arthur, NM",
    city_name: "Lake Arthur",
  },
  {
    postal_code: "58831",
    full_city_name: "Alexander, ND",
    city_name: "Alexander",
  },
  {
    postal_code: "48883",
    full_city_name: "Shepherd, MI",
    city_name: "Shepherd",
  },
  {
    postal_code: "03101",
    full_city_name: "Manchester, NH",
    city_name: "Manchester",
  },
  {
    postal_code: "30805",
    full_city_name: "Blythe, GA",
    city_name: "Blythe",
  },
  {
    postal_code: "38744",
    full_city_name: "Glen Allan, MS",
    city_name: "Glen Allan",
  },
  {
    postal_code: "13353",
    full_city_name: "Hoffmeister, NY",
    city_name: "Hoffmeister",
  },
  {
    postal_code: "01009",
    full_city_name: "Bondsville, MA",
    city_name: "Bondsville",
  },
  {
    postal_code: "50594",
    full_city_name: "Vincent, IA",
    city_name: "Vincent",
  },
  {
    postal_code: "05671",
    full_city_name: "Waterbury, VT",
    city_name: "Waterbury",
  },
  {
    postal_code: "59867",
    full_city_name: "Saltese, MT",
    city_name: "Saltese",
  },
  {
    postal_code: "76627",
    full_city_name: "Blum, TX",
    city_name: "Blum",
  },
  {
    postal_code: "04017",
    full_city_name: "Chebeague Island, ME",
    city_name: "Chebeague Island",
  },
  {
    postal_code: "45156",
    full_city_name: "Neville, OH",
    city_name: "Neville",
  },
  {
    postal_code: "24165",
    full_city_name: "Spencer, VA",
    city_name: "Spencer",
  },
  {
    postal_code: "41861",
    full_city_name: "Raven, KY",
    city_name: "Raven",
  },
  {
    postal_code: "93435",
    full_city_name: "Harmony, CA",
    city_name: "Harmony",
  },
  {
    postal_code: "01503",
    full_city_name: "Berlin, MA",
    city_name: "Berlin",
  },
  {
    postal_code: "72930",
    full_city_name: "Cecil, AR",
    city_name: "Cecil",
  },
  {
    postal_code: "62289",
    full_city_name: "Summerfield, IL",
    city_name: "Summerfield",
  },
  {
    postal_code: "82440",
    full_city_name: "Ralston, WY",
    city_name: "Ralston",
  },
  {
    postal_code: "99649",
    full_city_name: "Pilot Point, AK",
    city_name: "Pilot Point",
  },
  {
    postal_code: "54466",
    full_city_name: "Pittsville, WI",
    city_name: "Pittsville",
  },
  {
    postal_code: "51045",
    full_city_name: "Oyens, IA",
    city_name: "Oyens",
  },
  {
    postal_code: "67340",
    full_city_name: "Dearing, KS",
    city_name: "Dearing",
  },
  {
    postal_code: "80611",
    full_city_name: "Briggsdale, CO",
    city_name: "Briggsdale",
  },
  {
    postal_code: "83842",
    full_city_name: "Medimont, ID",
    city_name: "Medimont",
  },
  {
    postal_code: "44418",
    full_city_name: "Fowler, OH",
    city_name: "Fowler",
  },
  {
    postal_code: "67105",
    full_city_name: "Milan, KS",
    city_name: "Milan",
  },
  {
    postal_code: "66835",
    full_city_name: "Americus, KS",
    city_name: "Americus",
  },
  {
    postal_code: "60111",
    full_city_name: "Clare, IL",
    city_name: "Clare",
  },
  {
    postal_code: "51448",
    full_city_name: "Kiron, IA",
    city_name: "Kiron",
  },
  {
    postal_code: "23879",
    full_city_name: "Skippers, VA",
    city_name: "Skippers",
  },
  {
    postal_code: "07077",
    full_city_name: "Sewaren, NJ",
    city_name: "Sewaren",
  },
  {
    postal_code: "59461",
    full_city_name: "Lothair, MT",
    city_name: "Lothair",
  },
  {
    postal_code: "25086",
    full_city_name: "Glasgow, WV",
    city_name: "Glasgow",
  },
  {
    postal_code: "32464",
    full_city_name: "Westville, FL",
    city_name: "Westville",
  },
  {
    postal_code: "16624",
    full_city_name: "Chest Springs, PA",
    city_name: "Chest Springs",
  },
  {
    postal_code: "59252",
    full_city_name: "Outlook, MT",
    city_name: "Outlook",
  },
  {
    postal_code: "62639",
    full_city_name: "Frederick, IL",
    city_name: "Frederick",
  },
  {
    postal_code: "17934",
    full_city_name: "Gilberton, PA",
    city_name: "Gilberton",
  },
  {
    postal_code: "48770",
    full_city_name: "Whittemore, MI",
    city_name: "Whittemore",
  },
  {
    postal_code: "50038",
    full_city_name: "Booneville, IA",
    city_name: "Booneville",
  },
  {
    postal_code: "25110",
    full_city_name: "Hugheston, WV",
    city_name: "Hugheston",
  },
  {
    postal_code: "67054",
    full_city_name: "Greensburg, KS",
    city_name: "Greensburg",
  },
  {
    postal_code: "75560",
    full_city_name: "Douglassville, TX",
    city_name: "Douglassville",
  },
  {
    postal_code: "15734",
    full_city_name: "Dixonville, PA",
    city_name: "Dixonville",
  },
  {
    postal_code: "64686",
    full_city_name: "Utica, MO",
    city_name: "Utica",
  },
  {
    postal_code: "89013",
    full_city_name: "Goldfield, NV",
    city_name: "Goldfield",
  },
  {
    postal_code: "04648",
    full_city_name: "Jonesboro, ME",
    city_name: "Jonesboro",
  },
  {
    postal_code: "40856",
    full_city_name: "Miracle, KY",
    city_name: "Miracle",
  },
  {
    postal_code: "95419",
    full_city_name: "Camp Meeker, CA",
    city_name: "Camp Meeker",
  },
  {
    postal_code: "54621",
    full_city_name: "Chaseburg, WI",
    city_name: "Chaseburg",
  },
  {
    postal_code: "57070",
    full_city_name: "Viborg, SD",
    city_name: "Viborg",
  },
  {
    postal_code: "20626",
    full_city_name: "Coltons Point, MD",
    city_name: "Coltons Point",
  },
  {
    postal_code: "04617",
    full_city_name: "Brooksville, ME",
    city_name: "Brooksville",
  },
  {
    postal_code: "05069",
    full_city_name: "South Ryegate, VT",
    city_name: "South Ryegate",
  },
  {
    postal_code: "98619",
    full_city_name: "Glenwood, WA",
    city_name: "Glenwood",
  },
  {
    postal_code: "72140",
    full_city_name: "Saint Charles, AR",
    city_name: "Saint Charles",
  },
  {
    postal_code: "61480",
    full_city_name: "Stronghurst, IL",
    city_name: "Stronghurst",
  },
  {
    postal_code: "35574",
    full_city_name: "Kennedy, AL",
    city_name: "Kennedy",
  },
  {
    postal_code: "57359",
    full_city_name: "Letcher, SD",
    city_name: "Letcher",
  },
  {
    postal_code: "14069",
    full_city_name: "Glenwood, NY",
    city_name: "Glenwood",
  },
  {
    postal_code: "38848",
    full_city_name: "Greenwood Springs, MS",
    city_name: "Greenwood Springs",
  },
  {
    postal_code: "39332",
    full_city_name: "Hickory, MS",
    city_name: "Hickory",
  },
  {
    postal_code: "88039",
    full_city_name: "Glenwood, NM",
    city_name: "Glenwood",
  },
  {
    postal_code: "15712",
    full_city_name: "Arcadia, PA",
    city_name: "Arcadia",
  },
  {
    postal_code: "82727",
    full_city_name: "Rozet, WY",
    city_name: "Rozet",
  },
  {
    postal_code: "72536",
    full_city_name: "Franklin, AR",
    city_name: "Franklin",
  },
  {
    postal_code: "37819",
    full_city_name: "Newcomb, TN",
    city_name: "Newcomb",
  },
  {
    postal_code: "63445",
    full_city_name: "Kahoka, MO",
    city_name: "Kahoka",
  },
  {
    postal_code: "57323",
    full_city_name: "Carthage, SD",
    city_name: "Carthage",
  },
  {
    postal_code: "79247",
    full_city_name: "Odell, TX",
    city_name: "Odell",
  },
  {
    postal_code: "23085",
    full_city_name: "King And Queen Court House, VA",
    city_name: "King And Queen Court House",
  },
  {
    postal_code: "76660",
    full_city_name: "Malone, TX",
    city_name: "Malone",
  },
  {
    postal_code: "85609",
    full_city_name: "Dragoon, AZ",
    city_name: "Dragoon",
  },
  {
    postal_code: "04642",
    full_city_name: "Harborside, ME",
    city_name: "Harborside",
  },
  {
    postal_code: "05442",
    full_city_name: "Belvidere Center, VT",
    city_name: "Belvidere Center",
  },
  {
    postal_code: "76666",
    full_city_name: "Mertens, TX",
    city_name: "Mertens",
  },
  {
    postal_code: "71839",
    full_city_name: "Garland City, AR",
    city_name: "Garland City",
  },
  {
    postal_code: "78117",
    full_city_name: "Hobson, TX",
    city_name: "Hobson",
  },
  {
    postal_code: "64445",
    full_city_name: "Elmo, MO",
    city_name: "Elmo",
  },
  {
    postal_code: "52156",
    full_city_name: "Luana, IA",
    city_name: "Luana",
  },
  {
    postal_code: "84521",
    full_city_name: "Elmo, UT",
    city_name: "Elmo",
  },
  {
    postal_code: "62928",
    full_city_name: "Eddyville, IL",
    city_name: "Eddyville",
  },
  {
    postal_code: "24879",
    full_city_name: "Raysal, WV",
    city_name: "Raysal",
  },
  {
    postal_code: "68971",
    full_city_name: "Republican City, NE",
    city_name: "Republican City",
  },
  {
    postal_code: "56327",
    full_city_name: "Farwell, MN",
    city_name: "Farwell",
  },
  {
    postal_code: "23025",
    full_city_name: "Cardinal, VA",
    city_name: "Cardinal",
  },
  {
    postal_code: "68718",
    full_city_name: "Bloomfield, NE",
    city_name: "Bloomfield",
  },
  {
    postal_code: "29658",
    full_city_name: "Long Creek, SC",
    city_name: "Long Creek",
  },
  {
    postal_code: "28552",
    full_city_name: "Lowland, NC",
    city_name: "Lowland",
  },
  {
    postal_code: "17952",
    full_city_name: "Mary D, PA",
    city_name: "Mary D",
  },
  {
    postal_code: "41528",
    full_city_name: "Freeburn, KY",
    city_name: "Freeburn",
  },
  {
    postal_code: "56369",
    full_city_name: "Rockville, MN",
    city_name: "Rockville",
  },
  {
    postal_code: "43842",
    full_city_name: "Trinway, OH",
    city_name: "Trinway",
  },
  {
    postal_code: "62908",
    full_city_name: "Belknap, IL",
    city_name: "Belknap",
  },
  {
    postal_code: "74830",
    full_city_name: "Bowlegs, OK",
    city_name: "Bowlegs",
  },
  {
    postal_code: "13687",
    full_city_name: "South Colton, NY",
    city_name: "South Colton",
  },
  {
    postal_code: "36583",
    full_city_name: "Tibbie, AL",
    city_name: "Tibbie",
  },
  {
    postal_code: "28719",
    full_city_name: "Cherokee, NC",
    city_name: "Cherokee",
  },
  {
    postal_code: "25181",
    full_city_name: "Seth, WV",
    city_name: "Seth",
  },
  {
    postal_code: "04221",
    full_city_name: "Canton, ME",
    city_name: "Canton",
  },
  {
    postal_code: "75960",
    full_city_name: "Moscow, TX",
    city_name: "Moscow",
  },
  {
    postal_code: "41132",
    full_city_name: "Denton, KY",
    city_name: "Denton",
  },
  {
    postal_code: "24844",
    full_city_name: "Iaeger, WV",
    city_name: "Iaeger",
  },
  {
    postal_code: "42321",
    full_city_name: "Beech Creek, KY",
    city_name: "Beech Creek",
  },
  {
    postal_code: "73650",
    full_city_name: "Hammon, OK",
    city_name: "Hammon",
  },
  {
    postal_code: "16061",
    full_city_name: "West Sunbury, PA",
    city_name: "West Sunbury",
  },
  {
    postal_code: "64473",
    full_city_name: "Oregon, MO",
    city_name: "Oregon",
  },
  {
    postal_code: "62284",
    full_city_name: "Smithboro, IL",
    city_name: "Smithboro",
  },
  {
    postal_code: "74931",
    full_city_name: "Bunch, OK",
    city_name: "Bunch",
  },
  {
    postal_code: "76436",
    full_city_name: "Carlton, TX",
    city_name: "Carlton",
  },
  {
    postal_code: "04216",
    full_city_name: "Andover, ME",
    city_name: "Andover",
  },
  {
    postal_code: "65768",
    full_city_name: "Vanzant, MO",
    city_name: "Vanzant",
  },
  {
    postal_code: "64493",
    full_city_name: "Turney, MO",
    city_name: "Turney",
  },
  {
    postal_code: "60931",
    full_city_name: "Donovan, IL",
    city_name: "Donovan",
  },
  {
    postal_code: "61855",
    full_city_name: "Milmine, IL",
    city_name: "Milmine",
  },
  {
    postal_code: "47177",
    full_city_name: "Underwood, IN",
    city_name: "Underwood",
  },
  {
    postal_code: "71961",
    full_city_name: "Oden, AR",
    city_name: "Oden",
  },
  {
    postal_code: "76681",
    full_city_name: "Richland, TX",
    city_name: "Richland",
  },
  {
    postal_code: "14037",
    full_city_name: "Cowlesville, NY",
    city_name: "Cowlesville",
  },
  {
    postal_code: "06467",
    full_city_name: "Milldale, CT",
    city_name: "Milldale",
  },
  {
    postal_code: "61049",
    full_city_name: "Lindenwood, IL",
    city_name: "Lindenwood",
  },
  {
    postal_code: "21156",
    full_city_name: "Upper Falls, MD",
    city_name: "Upper Falls",
  },
  {
    postal_code: "38450",
    full_city_name: "Collinwood, TN",
    city_name: "Collinwood",
  },
  {
    postal_code: "68973",
    full_city_name: "Roseland, NE",
    city_name: "Roseland",
  },
  {
    postal_code: "04535",
    full_city_name: "Alna, ME",
    city_name: "Alna",
  },
  {
    postal_code: "61378",
    full_city_name: "West Brooklyn, IL",
    city_name: "West Brooklyn",
  },
  {
    postal_code: "67102",
    full_city_name: "Maple City, KS",
    city_name: "Maple City",
  },
  {
    postal_code: "15549",
    full_city_name: "Listie, PA",
    city_name: "Listie",
  },
  {
    postal_code: "35089",
    full_city_name: "Kellyton, AL",
    city_name: "Kellyton",
  },
  {
    postal_code: "67657",
    full_city_name: "Palco, KS",
    city_name: "Palco",
  },
  {
    postal_code: "16638",
    full_city_name: "Entriken, PA",
    city_name: "Entriken",
  },
  {
    postal_code: "68977",
    full_city_name: "Stamford, NE",
    city_name: "Stamford",
  },
  {
    postal_code: "74549",
    full_city_name: "Honobia, OK",
    city_name: "Honobia",
  },
  {
    postal_code: "83011",
    full_city_name: "Kelly, WY",
    city_name: "Kelly",
  },
  {
    postal_code: "57017",
    full_city_name: "Colman, SD",
    city_name: "Colman",
  },
  {
    postal_code: "89017",
    full_city_name: "Hiko, NV",
    city_name: "Hiko",
  },
  {
    postal_code: "16040",
    full_city_name: "Hilliards, PA",
    city_name: "Hilliards",
  },
  {
    postal_code: "30708",
    full_city_name: "Cisco, GA",
    city_name: "Cisco",
  },
  {
    postal_code: "59059",
    full_city_name: "Musselshell, MT",
    city_name: "Musselshell",
  },
  {
    postal_code: "99571",
    full_city_name: "Cold Bay, AK",
    city_name: "Cold Bay",
  },
  {
    postal_code: "85135",
    full_city_name: "Hayden, AZ",
    city_name: "Hayden",
  },
  {
    postal_code: "65479",
    full_city_name: "Hartshorn, MO",
    city_name: "Hartshorn",
  },
  {
    postal_code: "50532",
    full_city_name: "Duncombe, IA",
    city_name: "Duncombe",
  },
  {
    postal_code: "43766",
    full_city_name: "New Straitsville, OH",
    city_name: "New Straitsville",
  },
  {
    postal_code: "69166",
    full_city_name: "Thedford, NE",
    city_name: "Thedford",
  },
  {
    postal_code: "63855",
    full_city_name: "Hornersville, MO",
    city_name: "Hornersville",
  },
  {
    postal_code: "66860",
    full_city_name: "Madison, KS",
    city_name: "Madison",
  },
  {
    postal_code: "26722",
    full_city_name: "Green Spring, WV",
    city_name: "Green Spring",
  },
  {
    postal_code: "79371",
    full_city_name: "Sudan, TX",
    city_name: "Sudan",
  },
  {
    postal_code: "73722",
    full_city_name: "Burlington, OK",
    city_name: "Burlington",
  },
  {
    postal_code: "62818",
    full_city_name: "Browns, IL",
    city_name: "Browns",
  },
  {
    postal_code: "49653",
    full_city_name: "Lake Leelanau, MI",
    city_name: "Lake Leelanau",
  },
  {
    postal_code: "63654",
    full_city_name: "Lesterville, MO",
    city_name: "Lesterville",
  },
  {
    postal_code: "88020",
    full_city_name: "Animas, NM",
    city_name: "Animas",
  },
  {
    postal_code: "62218",
    full_city_name: "Bartelso, IL",
    city_name: "Bartelso",
  },
  {
    postal_code: "06758",
    full_city_name: "Lakeside, CT",
    city_name: "Lakeside",
  },
  {
    postal_code: "97638",
    full_city_name: "Silver Lake, OR",
    city_name: "Silver Lake",
  },
  {
    postal_code: "68941",
    full_city_name: "Glenvil, NE",
    city_name: "Glenvil",
  },
  {
    postal_code: "67950",
    full_city_name: "Elkhart, KS",
    city_name: "Elkhart",
  },
  {
    postal_code: "56227",
    full_city_name: "Correll, MN",
    city_name: "Correll",
  },
  {
    postal_code: "15677",
    full_city_name: "Rector, PA",
    city_name: "Rector",
  },
  {
    postal_code: "05253",
    full_city_name: "East Dorset, VT",
    city_name: "East Dorset",
  },
  {
    postal_code: "54103",
    full_city_name: "Armstrong Creek, WI",
    city_name: "Armstrong Creek",
  },
  {
    postal_code: "06264",
    full_city_name: "Scotland, CT",
    city_name: "Scotland",
  },
  {
    postal_code: "78409",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "66532",
    full_city_name: "Robinson, KS",
    city_name: "Robinson",
  },
  {
    postal_code: "67758",
    full_city_name: "Sharon Springs, KS",
    city_name: "Sharon Springs",
  },
  {
    postal_code: "88022",
    full_city_name: "Arenas Valley, NM",
    city_name: "Arenas Valley",
  },
  {
    postal_code: "37657",
    full_city_name: "Flag Pond, TN",
    city_name: "Flag Pond",
  },
  {
    postal_code: "13333",
    full_city_name: "East Springfield, NY",
    city_name: "East Springfield",
  },
  {
    postal_code: "21543",
    full_city_name: "Midlothian, MD",
    city_name: "Midlothian",
  },
  {
    postal_code: "43435",
    full_city_name: "Helena, OH",
    city_name: "Helena",
  },
  {
    postal_code: "14561",
    full_city_name: "Stanley, NY",
    city_name: "Stanley",
  },
  {
    postal_code: "29593",
    full_city_name: "Society Hill, SC",
    city_name: "Society Hill",
  },
  {
    postal_code: "46980",
    full_city_name: "Servia, IN",
    city_name: "Servia",
  },
  {
    postal_code: "83872",
    full_city_name: "Viola, ID",
    city_name: "Viola",
  },
  {
    postal_code: "24884",
    full_city_name: "Squire, WV",
    city_name: "Squire",
  },
  {
    postal_code: "73057",
    full_city_name: "Maysville, OK",
    city_name: "Maysville",
  },
  {
    postal_code: "83469",
    full_city_name: "Shoup, ID",
    city_name: "Shoup",
  },
  {
    postal_code: "26293",
    full_city_name: "Valley Bend, WV",
    city_name: "Valley Bend",
  },
  {
    postal_code: "78215",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "81129",
    full_city_name: "Conejos, CO",
    city_name: "Conejos",
  },
  {
    postal_code: "63465",
    full_city_name: "Revere, MO",
    city_name: "Revere",
  },
  {
    postal_code: "66422",
    full_city_name: "Emmett, KS",
    city_name: "Emmett",
  },
  {
    postal_code: "84518",
    full_city_name: "Cleveland, UT",
    city_name: "Cleveland",
  },
  {
    postal_code: "43930",
    full_city_name: "Hammondsville, OH",
    city_name: "Hammondsville",
  },
  {
    postal_code: "22509",
    full_city_name: "Loretto, VA",
    city_name: "Loretto",
  },
  {
    postal_code: "63451",
    full_city_name: "Leonard, MO",
    city_name: "Leonard",
  },
  {
    postal_code: "39359",
    full_city_name: "Sebastopol, MS",
    city_name: "Sebastopol",
  },
  {
    postal_code: "47469",
    full_city_name: "West Baden Springs, IN",
    city_name: "West Baden Springs",
  },
  {
    postal_code: "24377",
    full_city_name: "Tannersville, VA",
    city_name: "Tannersville",
  },
  {
    postal_code: "44633",
    full_city_name: "Holmesville, OH",
    city_name: "Holmesville",
  },
  {
    postal_code: "26627",
    full_city_name: "Heaters, WV",
    city_name: "Heaters",
  },
  {
    postal_code: "04359",
    full_city_name: "South Gardiner, ME",
    city_name: "South Gardiner",
  },
  {
    postal_code: "74423",
    full_city_name: "Braggs, OK",
    city_name: "Braggs",
  },
  {
    postal_code: "16225",
    full_city_name: "Fisher, PA",
    city_name: "Fisher",
  },
  {
    postal_code: "39097",
    full_city_name: "Louise, MS",
    city_name: "Louise",
  },
  {
    postal_code: "62627",
    full_city_name: "Chandlerville, IL",
    city_name: "Chandlerville",
  },
  {
    postal_code: "05058",
    full_city_name: "Post Mills, VT",
    city_name: "Post Mills",
  },
  {
    postal_code: "27860",
    full_city_name: "Pantego, NC",
    city_name: "Pantego",
  },
  {
    postal_code: "85922",
    full_city_name: "Blue, AZ",
    city_name: "Blue",
  },
  {
    postal_code: "13415",
    full_city_name: "New Lisbon, NY",
    city_name: "New Lisbon",
  },
  {
    postal_code: "61242",
    full_city_name: "Cordova, IL",
    city_name: "Cordova",
  },
  {
    postal_code: "64783",
    full_city_name: "Schell City, MO",
    city_name: "Schell City",
  },
  {
    postal_code: "16673",
    full_city_name: "Roaring Spring, PA",
    city_name: "Roaring Spring",
  },
  {
    postal_code: "99359",
    full_city_name: "Starbuck, WA",
    city_name: "Starbuck",
  },
  {
    postal_code: "25270",
    full_city_name: "Reedy, WV",
    city_name: "Reedy",
  },
  {
    postal_code: "73749",
    full_city_name: "Jet, OK",
    city_name: "Jet",
  },
  {
    postal_code: "04275",
    full_city_name: "Roxbury, ME",
    city_name: "Roxbury",
  },
  {
    postal_code: "15337",
    full_city_name: "Graysville, PA",
    city_name: "Graysville",
  },
  {
    postal_code: "18070",
    full_city_name: "Palm, PA",
    city_name: "Palm",
  },
  {
    postal_code: "54827",
    full_city_name: "Cornucopia, WI",
    city_name: "Cornucopia",
  },
  {
    postal_code: "54450",
    full_city_name: "Mattoon, WI",
    city_name: "Mattoon",
  },
  {
    postal_code: "25048",
    full_city_name: "Colcord, WV",
    city_name: "Colcord",
  },
  {
    postal_code: "37351",
    full_city_name: "Lupton City, TN",
    city_name: "Lupton City",
  },
  {
    postal_code: "53208",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "49318",
    full_city_name: "Casnovia, MI",
    city_name: "Casnovia",
  },
  {
    postal_code: "88430",
    full_city_name: "Nara Visa, NM",
    city_name: "Nara Visa",
  },
  {
    postal_code: "49618",
    full_city_name: "Boon, MI",
    city_name: "Boon",
  },
  {
    postal_code: "58254",
    full_city_name: "Mcville, ND",
    city_name: "Mcville",
  },
  {
    postal_code: "72021",
    full_city_name: "Brinkley, AR",
    city_name: "Brinkley",
  },
  {
    postal_code: "08350",
    full_city_name: "Richland, NJ",
    city_name: "Richland",
  },
  {
    postal_code: "53510",
    full_city_name: "Belmont, WI",
    city_name: "Belmont",
  },
  {
    postal_code: "14557",
    full_city_name: "South Byron, NY",
    city_name: "South Byron",
  },
  {
    postal_code: "12507",
    full_city_name: "Barrytown, NY",
    city_name: "Barrytown",
  },
  {
    postal_code: "97857",
    full_city_name: "Lostine, OR",
    city_name: "Lostine",
  },
  {
    postal_code: "74949",
    full_city_name: "Muse, OK",
    city_name: "Muse",
  },
  {
    postal_code: "18828",
    full_city_name: "Lawton, PA",
    city_name: "Lawton",
  },
  {
    postal_code: "36036",
    full_city_name: "Grady, AL",
    city_name: "Grady",
  },
  {
    postal_code: "67442",
    full_city_name: "Falun, KS",
    city_name: "Falun",
  },
  {
    postal_code: "76455",
    full_city_name: "Gustine, TX",
    city_name: "Gustine",
  },
  {
    postal_code: "41817",
    full_city_name: "Garner, KY",
    city_name: "Garner",
  },
  {
    postal_code: "55716",
    full_city_name: "Calumet, MN",
    city_name: "Calumet",
  },
  {
    postal_code: "62413",
    full_city_name: "Annapolis, IL",
    city_name: "Annapolis",
  },
  {
    postal_code: "32331",
    full_city_name: "Greenville, FL",
    city_name: "Greenville",
  },
  {
    postal_code: "53505",
    full_city_name: "Avalon, WI",
    city_name: "Avalon",
  },
  {
    postal_code: "57714",
    full_city_name: "Allen, SD",
    city_name: "Allen",
  },
  {
    postal_code: "14511",
    full_city_name: "Mumford, NY",
    city_name: "Mumford",
  },
  {
    postal_code: "16657",
    full_city_name: "James Creek, PA",
    city_name: "James Creek",
  },
  {
    postal_code: "51553",
    full_city_name: "Minden, IA",
    city_name: "Minden",
  },
  {
    postal_code: "66091",
    full_city_name: "Welda, KS",
    city_name: "Welda",
  },
  {
    postal_code: "56734",
    full_city_name: "Lake Bronson, MN",
    city_name: "Lake Bronson",
  },
  {
    postal_code: "69214",
    full_city_name: "Johnstown, NE",
    city_name: "Johnstown",
  },
  {
    postal_code: "35461",
    full_city_name: "Ethelsville, AL",
    city_name: "Ethelsville",
  },
  {
    postal_code: "84657",
    full_city_name: "Sigurd, UT",
    city_name: "Sigurd",
  },
  {
    postal_code: "68722",
    full_city_name: "Butte, NE",
    city_name: "Butte",
  },
  {
    postal_code: "96038",
    full_city_name: "Grenada, CA",
    city_name: "Grenada",
  },
  {
    postal_code: "50660",
    full_city_name: "New Hartford, IA",
    city_name: "New Hartford",
  },
  {
    postal_code: "66873",
    full_city_name: "Wilsey, KS",
    city_name: "Wilsey",
  },
  {
    postal_code: "58770",
    full_city_name: "Parshall, ND",
    city_name: "Parshall",
  },
  {
    postal_code: "72064",
    full_city_name: "Hazen, AR",
    city_name: "Hazen",
  },
  {
    postal_code: "89319",
    full_city_name: "Ruth, NV",
    city_name: "Ruth",
  },
  {
    postal_code: "52214",
    full_city_name: "Central City, IA",
    city_name: "Central City",
  },
  {
    postal_code: "68790",
    full_city_name: "Winside, NE",
    city_name: "Winside",
  },
  {
    postal_code: "13368",
    full_city_name: "Lyons Falls, NY",
    city_name: "Lyons Falls",
  },
  {
    postal_code: "57641",
    full_city_name: "Mc Intosh, SD",
    city_name: "Mc Intosh",
  },
  {
    postal_code: "49868",
    full_city_name: "Newberry, MI",
    city_name: "Newberry",
  },
  {
    postal_code: "15451",
    full_city_name: "Lake Lynn, PA",
    city_name: "Lake Lynn",
  },
  {
    postal_code: "42083",
    full_city_name: "Tiline, KY",
    city_name: "Tiline",
  },
  {
    postal_code: "64475",
    full_city_name: "Parnell, MO",
    city_name: "Parnell",
  },
  {
    postal_code: "56434",
    full_city_name: "Aldrich, MN",
    city_name: "Aldrich",
  },
  {
    postal_code: "56754",
    full_city_name: "Saint Hilaire, MN",
    city_name: "Saint Hilaire",
  },
  {
    postal_code: "13136",
    full_city_name: "Pitcher, NY",
    city_name: "Pitcher",
  },
  {
    postal_code: "14517",
    full_city_name: "Nunda, NY",
    city_name: "Nunda",
  },
  {
    postal_code: "15612",
    full_city_name: "Alverton, PA",
    city_name: "Alverton",
  },
  {
    postal_code: "66943",
    full_city_name: "Greenleaf, KS",
    city_name: "Greenleaf",
  },
  {
    postal_code: "84729",
    full_city_name: "Glendale, UT",
    city_name: "Glendale",
  },
  {
    postal_code: "24316",
    full_city_name: "Broadford, VA",
    city_name: "Broadford",
  },
  {
    postal_code: "63821",
    full_city_name: "Arbyrd, MO",
    city_name: "Arbyrd",
  },
  {
    postal_code: "63530",
    full_city_name: "Atlanta, MO",
    city_name: "Atlanta",
  },
  {
    postal_code: "97920",
    full_city_name: "Westfall, OR",
    city_name: "Westfall",
  },
  {
    postal_code: "14897",
    full_city_name: "Whitesville, NY",
    city_name: "Whitesville",
  },
  {
    postal_code: "97632",
    full_city_name: "Malin, OR",
    city_name: "Malin",
  },
  {
    postal_code: "81335",
    full_city_name: "Yellow Jacket, CO",
    city_name: "Yellow Jacket",
  },
  {
    postal_code: "84308",
    full_city_name: "Cornish, UT",
    city_name: "Cornish",
  },
  {
    postal_code: "42079",
    full_city_name: "Sedalia, KY",
    city_name: "Sedalia",
  },
  {
    postal_code: "57427",
    full_city_name: "Bath, SD",
    city_name: "Bath",
  },
  {
    postal_code: "39045",
    full_city_name: "Camden, MS",
    city_name: "Camden",
  },
  {
    postal_code: "61262",
    full_city_name: "Lynn Center, IL",
    city_name: "Lynn Center",
  },
  {
    postal_code: "50653",
    full_city_name: "Marble Rock, IA",
    city_name: "Marble Rock",
  },
  {
    postal_code: "24637",
    full_city_name: "Pounding Mill, VA",
    city_name: "Pounding Mill",
  },
  {
    postal_code: "29051",
    full_city_name: "Gable, SC",
    city_name: "Gable",
  },
  {
    postal_code: "72170",
    full_city_name: "Ulm, AR",
    city_name: "Ulm",
  },
  {
    postal_code: "58734",
    full_city_name: "Donnybrook, ND",
    city_name: "Donnybrook",
  },
  {
    postal_code: "73565",
    full_city_name: "Ryan, OK",
    city_name: "Ryan",
  },
  {
    postal_code: "29547",
    full_city_name: "Hamer, SC",
    city_name: "Hamer",
  },
  {
    postal_code: "04417",
    full_city_name: "Burlington, ME",
    city_name: "Burlington",
  },
  {
    postal_code: "95972",
    full_city_name: "Rackerby, CA",
    city_name: "Rackerby",
  },
  {
    postal_code: "63625",
    full_city_name: "Black, MO",
    city_name: "Black",
  },
  {
    postal_code: "78579",
    full_city_name: "Progreso, TX",
    city_name: "Progreso",
  },
  {
    postal_code: "58544",
    full_city_name: "Hazelton, ND",
    city_name: "Hazelton",
  },
  {
    postal_code: "65762",
    full_city_name: "Thornfield, MO",
    city_name: "Thornfield",
  },
  {
    postal_code: "31066",
    full_city_name: "Musella, GA",
    city_name: "Musella",
  },
  {
    postal_code: "96014",
    full_city_name: "Callahan, CA",
    city_name: "Callahan",
  },
  {
    postal_code: "49623",
    full_city_name: "Chase, MI",
    city_name: "Chase",
  },
  {
    postal_code: "05744",
    full_city_name: "Florence, VT",
    city_name: "Florence",
  },
  {
    postal_code: "72085",
    full_city_name: "Letona, AR",
    city_name: "Letona",
  },
  {
    postal_code: "25049",
    full_city_name: "Comfort, WV",
    city_name: "Comfort",
  },
  {
    postal_code: "68927",
    full_city_name: "Bertrand, NE",
    city_name: "Bertrand",
  },
  {
    postal_code: "12858",
    full_city_name: "Paradox, NY",
    city_name: "Paradox",
  },
  {
    postal_code: "47358",
    full_city_name: "Modoc, IN",
    city_name: "Modoc",
  },
  {
    postal_code: "13695",
    full_city_name: "Wanakena, NY",
    city_name: "Wanakena",
  },
  {
    postal_code: "35962",
    full_city_name: "Crossville, AL",
    city_name: "Crossville",
  },
  {
    postal_code: "96031",
    full_city_name: "Forks Of Salmon, CA",
    city_name: "Forks Of Salmon",
  },
  {
    postal_code: "55784",
    full_city_name: "Swan River, MN",
    city_name: "Swan River",
  },
  {
    postal_code: "39146",
    full_city_name: "Pickens, MS",
    city_name: "Pickens",
  },
  {
    postal_code: "17024",
    full_city_name: "Elliottsburg, PA",
    city_name: "Elliottsburg",
  },
  {
    postal_code: "40355",
    full_city_name: "New Liberty, KY",
    city_name: "New Liberty",
  },
  {
    postal_code: "63555",
    full_city_name: "Memphis, MO",
    city_name: "Memphis",
  },
  {
    postal_code: "51061",
    full_city_name: "Washta, IA",
    city_name: "Washta",
  },
  {
    postal_code: "70442",
    full_city_name: "Husser, LA",
    city_name: "Husser",
  },
  {
    postal_code: "64635",
    full_city_name: "Chula, MO",
    city_name: "Chula",
  },
  {
    postal_code: "47465",
    full_city_name: "Switz City, IN",
    city_name: "Switz City",
  },
  {
    postal_code: "31001",
    full_city_name: "Abbeville, GA",
    city_name: "Abbeville",
  },
  {
    postal_code: "24150",
    full_city_name: "Ripplemead, VA",
    city_name: "Ripplemead",
  },
  {
    postal_code: "58552",
    full_city_name: "Linton, ND",
    city_name: "Linton",
  },
  {
    postal_code: "57788",
    full_city_name: "Vale, SD",
    city_name: "Vale",
  },
  {
    postal_code: "25173",
    full_city_name: "Robson, WV",
    city_name: "Robson",
  },
  {
    postal_code: "41268",
    full_city_name: "West Van Lear, KY",
    city_name: "West Van Lear",
  },
  {
    postal_code: "88338",
    full_city_name: "Lincoln, NM",
    city_name: "Lincoln",
  },
  {
    postal_code: "15710",
    full_city_name: "Alverda, PA",
    city_name: "Alverda",
  },
  {
    postal_code: "52201",
    full_city_name: "Ainsworth, IA",
    city_name: "Ainsworth",
  },
  {
    postal_code: "59454",
    full_city_name: "Kevin, MT",
    city_name: "Kevin",
  },
  {
    postal_code: "97414",
    full_city_name: "Broadbent, OR",
    city_name: "Broadbent",
  },
  {
    postal_code: "56711",
    full_city_name: "Angle Inlet, MN",
    city_name: "Angle Inlet",
  },
  {
    postal_code: "93641",
    full_city_name: "Miramonte, CA",
    city_name: "Miramonte",
  },
  {
    postal_code: "67232",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "75247",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "82646",
    full_city_name: "Natrona, WY",
    city_name: "Natrona",
  },
  {
    postal_code: "49921",
    full_city_name: "Dodgeville, MI",
    city_name: "Dodgeville",
  },
  {
    postal_code: "82332",
    full_city_name: "Savery, WY",
    city_name: "Savery",
  },
  {
    postal_code: "04285",
    full_city_name: "Weld, ME",
    city_name: "Weld",
  },
  {
    postal_code: "23511",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "68402",
    full_city_name: "Malcolm, NE",
    city_name: "Malcolm",
  },
  {
    postal_code: "57577",
    full_city_name: "Wanblee, SD",
    city_name: "Wanblee",
  },
  {
    postal_code: "38664",
    full_city_name: "Robinsonville, MS",
    city_name: "Robinsonville",
  },
  {
    postal_code: "28349",
    full_city_name: "Kenansville, NC",
    city_name: "Kenansville",
  },
  {
    postal_code: "45698",
    full_city_name: "Zaleski, OH",
    city_name: "Zaleski",
  },
  {
    postal_code: "72440",
    full_city_name: "Lynn, AR",
    city_name: "Lynn",
  },
  {
    postal_code: "69133",
    full_city_name: "Dix, NE",
    city_name: "Dix",
  },
  {
    postal_code: "26141",
    full_city_name: "Creston, WV",
    city_name: "Creston",
  },
  {
    postal_code: "75852",
    full_city_name: "Midway, TX",
    city_name: "Midway",
  },
  {
    postal_code: "68351",
    full_city_name: "Exeter, NE",
    city_name: "Exeter",
  },
  {
    postal_code: "98605",
    full_city_name: "Bingen, WA",
    city_name: "Bingen",
  },
  {
    postal_code: "25165",
    full_city_name: "Racine, WV",
    city_name: "Racine",
  },
  {
    postal_code: "97347",
    full_city_name: "Grand Ronde, OR",
    city_name: "Grand Ronde",
  },
  {
    postal_code: "96051",
    full_city_name: "Lakehead, CA",
    city_name: "Lakehead",
  },
  {
    postal_code: "67658",
    full_city_name: "Paradise, KS",
    city_name: "Paradise",
  },
  {
    postal_code: "79518",
    full_city_name: "Girard, TX",
    city_name: "Girard",
  },
  {
    postal_code: "04541",
    full_city_name: "Chamberlain, ME",
    city_name: "Chamberlain",
  },
  {
    postal_code: "50048",
    full_city_name: "Casey, IA",
    city_name: "Casey",
  },
  {
    postal_code: "83122",
    full_city_name: "Grover, WY",
    city_name: "Grover",
  },
  {
    postal_code: "79042",
    full_city_name: "Happy, TX",
    city_name: "Happy",
  },
  {
    postal_code: "61814",
    full_city_name: "Bismarck, IL",
    city_name: "Bismarck",
  },
  {
    postal_code: "97884",
    full_city_name: "Unity, OR",
    city_name: "Unity",
  },
  {
    postal_code: "36445",
    full_city_name: "Frisco City, AL",
    city_name: "Frisco City",
  },
  {
    postal_code: "72141",
    full_city_name: "Scotland, AR",
    city_name: "Scotland",
  },
  {
    postal_code: "70456",
    full_city_name: "Roseland, LA",
    city_name: "Roseland",
  },
  {
    postal_code: "25083",
    full_city_name: "Gallagher, WV",
    city_name: "Gallagher",
  },
  {
    postal_code: "88046",
    full_city_name: "Mesilla, NM",
    city_name: "Mesilla",
  },
  {
    postal_code: "68651",
    full_city_name: "Osceola, NE",
    city_name: "Osceola",
  },
  {
    postal_code: "95652",
    full_city_name: "Mcclellan, CA",
    city_name: "Mcclellan",
  },
  {
    postal_code: "23839",
    full_city_name: "Dendron, VA",
    city_name: "Dendron",
  },
  {
    postal_code: "17060",
    full_city_name: "Mill Creek, PA",
    city_name: "Mill Creek",
  },
  {
    postal_code: "67741",
    full_city_name: "Kanorado, KS",
    city_name: "Kanorado",
  },
  {
    postal_code: "56533",
    full_city_name: "Elizabeth, MN",
    city_name: "Elizabeth",
  },
  {
    postal_code: "42712",
    full_city_name: "Big Clifty, KY",
    city_name: "Big Clifty",
  },
  {
    postal_code: "79543",
    full_city_name: "Roby, TX",
    city_name: "Roby",
  },
  {
    postal_code: "04360",
    full_city_name: "Vienna, ME",
    city_name: "Vienna",
  },
  {
    postal_code: "24589",
    full_city_name: "Scottsburg, VA",
    city_name: "Scottsburg",
  },
  {
    postal_code: "10963",
    full_city_name: "Otisville, NY",
    city_name: "Otisville",
  },
  {
    postal_code: "57260",
    full_city_name: "Rosholt, SD",
    city_name: "Rosholt",
  },
  {
    postal_code: "62685",
    full_city_name: "Shipman, IL",
    city_name: "Shipman",
  },
  {
    postal_code: "24433",
    full_city_name: "Doe Hill, VA",
    city_name: "Doe Hill",
  },
  {
    postal_code: "58531",
    full_city_name: "Coleharbor, ND",
    city_name: "Coleharbor",
  },
  {
    postal_code: "45778",
    full_city_name: "Stewart, OH",
    city_name: "Stewart",
  },
  {
    postal_code: "50274",
    full_city_name: "Wiota, IA",
    city_name: "Wiota",
  },
  {
    postal_code: "78072",
    full_city_name: "Tilden, TX",
    city_name: "Tilden",
  },
  {
    postal_code: "39082",
    full_city_name: "Harrisville, MS",
    city_name: "Harrisville",
  },
  {
    postal_code: "71356",
    full_city_name: "Morrow, LA",
    city_name: "Morrow",
  },
  {
    postal_code: "61932",
    full_city_name: "Hume, IL",
    city_name: "Hume",
  },
  {
    postal_code: "15077",
    full_city_name: "Shippingport, PA",
    city_name: "Shippingport",
  },
  {
    postal_code: "48878",
    full_city_name: "Rosebush, MI",
    city_name: "Rosebush",
  },
  {
    postal_code: "59417",
    full_city_name: "Browning, MT",
    city_name: "Browning",
  },
  {
    postal_code: "66403",
    full_city_name: "Axtell, KS",
    city_name: "Axtell",
  },
  {
    postal_code: "27844",
    full_city_name: "Hollister, NC",
    city_name: "Hollister",
  },
  {
    postal_code: "05675",
    full_city_name: "Washington, VT",
    city_name: "Washington",
  },
  {
    postal_code: "16250",
    full_city_name: "Sagamore, PA",
    city_name: "Sagamore",
  },
  {
    postal_code: "93249",
    full_city_name: "Lost Hills, CA",
    city_name: "Lost Hills",
  },
  {
    postal_code: "15429",
    full_city_name: "Denbo, PA",
    city_name: "Denbo",
  },
  {
    postal_code: "43739",
    full_city_name: "Glenford, OH",
    city_name: "Glenford",
  },
  {
    postal_code: "04658",
    full_city_name: "Milbridge, ME",
    city_name: "Milbridge",
  },
  {
    postal_code: "96108",
    full_city_name: "Davis Creek, CA",
    city_name: "Davis Creek",
  },
  {
    postal_code: "05050",
    full_city_name: "Mc Indoe Falls, VT",
    city_name: "Mc Indoe Falls",
  },
  {
    postal_code: "67019",
    full_city_name: "Burden, KS",
    city_name: "Burden",
  },
  {
    postal_code: "29015",
    full_city_name: "Blair, SC",
    city_name: "Blair",
  },
  {
    postal_code: "42210",
    full_city_name: "Brownsville, KY",
    city_name: "Brownsville",
  },
  {
    postal_code: "15534",
    full_city_name: "Buffalo Mills, PA",
    city_name: "Buffalo Mills",
  },
  {
    postal_code: "49320",
    full_city_name: "Chippewa Lake, MI",
    city_name: "Chippewa Lake",
  },
  {
    postal_code: "62956",
    full_city_name: "Karnak, IL",
    city_name: "Karnak",
  },
  {
    postal_code: "72838",
    full_city_name: "Gravelly, AR",
    city_name: "Gravelly",
  },
  {
    postal_code: "52037",
    full_city_name: "Delmar, IA",
    city_name: "Delmar",
  },
  {
    postal_code: "62970",
    full_city_name: "Olmsted, IL",
    city_name: "Olmsted",
  },
  {
    postal_code: "44902",
    full_city_name: "Mansfield, OH",
    city_name: "Mansfield",
  },
  {
    postal_code: "62330",
    full_city_name: "Dallas City, IL",
    city_name: "Dallas City",
  },
  {
    postal_code: "49873",
    full_city_name: "Perronville, MI",
    city_name: "Perronville",
  },
  {
    postal_code: "97452",
    full_city_name: "Lowell, OR",
    city_name: "Lowell",
  },
  {
    postal_code: "24479",
    full_city_name: "Swoope, VA",
    city_name: "Swoope",
  },
  {
    postal_code: "23130",
    full_city_name: "Onemo, VA",
    city_name: "Onemo",
  },
  {
    postal_code: "50857",
    full_city_name: "Nodaway, IA",
    city_name: "Nodaway",
  },
  {
    postal_code: "87524",
    full_city_name: "Costilla, NM",
    city_name: "Costilla",
  },
  {
    postal_code: "52320",
    full_city_name: "Olin, IA",
    city_name: "Olin",
  },
  {
    postal_code: "52229",
    full_city_name: "Garrison, IA",
    city_name: "Garrison",
  },
  {
    postal_code: "23337",
    full_city_name: "Wallops Island, VA",
    city_name: "Wallops Island",
  },
  {
    postal_code: "17217",
    full_city_name: "Concord, PA",
    city_name: "Concord",
  },
  {
    postal_code: "68838",
    full_city_name: "Farwell, NE",
    city_name: "Farwell",
  },
  {
    postal_code: "12451",
    full_city_name: "Leeds, NY",
    city_name: "Leeds",
  },
  {
    postal_code: "11970",
    full_city_name: "South Jamesport, NY",
    city_name: "South Jamesport",
  },
  {
    postal_code: "67871",
    full_city_name: "Scott City, KS",
    city_name: "Scott City",
  },
  {
    postal_code: "63851",
    full_city_name: "Hayti, MO",
    city_name: "Hayti",
  },
  {
    postal_code: "67474",
    full_city_name: "Portis, KS",
    city_name: "Portis",
  },
  {
    postal_code: "12419",
    full_city_name: "Cottekill, NY",
    city_name: "Cottekill",
  },
  {
    postal_code: "55736",
    full_city_name: "Floodwood, MN",
    city_name: "Floodwood",
  },
  {
    postal_code: "69220",
    full_city_name: "Sparks, NE",
    city_name: "Sparks",
  },
  {
    postal_code: "63870",
    full_city_name: "Parma, MO",
    city_name: "Parma",
  },
  {
    postal_code: "99647",
    full_city_name: "Pedro Bay, AK",
    city_name: "Pedro Bay",
  },
  {
    postal_code: "01244",
    full_city_name: "Mill River, MA",
    city_name: "Mill River",
  },
  {
    postal_code: "98853",
    full_city_name: "Stratford, WA",
    city_name: "Stratford",
  },
  {
    postal_code: "42741",
    full_city_name: "Glens Fork, KY",
    city_name: "Glens Fork",
  },
  {
    postal_code: "17219",
    full_city_name: "Doylesburg, PA",
    city_name: "Doylesburg",
  },
  {
    postal_code: "95553",
    full_city_name: "Miranda, CA",
    city_name: "Miranda",
  },
  {
    postal_code: "79528",
    full_city_name: "Jayton, TX",
    city_name: "Jayton",
  },
  {
    postal_code: "99667",
    full_city_name: "Skwentna, AK",
    city_name: "Skwentna",
  },
  {
    postal_code: "59843",
    full_city_name: "Helmville, MT",
    city_name: "Helmville",
  },
  {
    postal_code: "12874",
    full_city_name: "Silver Bay, NY",
    city_name: "Silver Bay",
  },
  {
    postal_code: "42163",
    full_city_name: "Rocky Hill, KY",
    city_name: "Rocky Hill",
  },
  {
    postal_code: "51441",
    full_city_name: "Deloit, IA",
    city_name: "Deloit",
  },
  {
    postal_code: "92259",
    full_city_name: "Ocotillo, CA",
    city_name: "Ocotillo",
  },
  {
    postal_code: "41572",
    full_city_name: "Virgie, KY",
    city_name: "Virgie",
  },
  {
    postal_code: "63473",
    full_city_name: "Williamstown, MO",
    city_name: "Williamstown",
  },
  {
    postal_code: "79835",
    full_city_name: "Canutillo, TX",
    city_name: "Canutillo",
  },
  {
    postal_code: "64149",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "67459",
    full_city_name: "Lorraine, KS",
    city_name: "Lorraine",
  },
  {
    postal_code: "05443",
    full_city_name: "Bristol, VT",
    city_name: "Bristol",
  },
  {
    postal_code: "78675",
    full_city_name: "Willow City, TX",
    city_name: "Willow City",
  },
  {
    postal_code: "38917",
    full_city_name: "Carrollton, MS",
    city_name: "Carrollton",
  },
  {
    postal_code: "17976",
    full_city_name: "Shenandoah, PA",
    city_name: "Shenandoah",
  },
  {
    postal_code: "71455",
    full_city_name: "Mora, LA",
    city_name: "Mora",
  },
  {
    postal_code: "24944",
    full_city_name: "Green Bank, WV",
    city_name: "Green Bank",
  },
  {
    postal_code: "54659",
    full_city_name: "Taylor, WI",
    city_name: "Taylor",
  },
  {
    postal_code: "16835",
    full_city_name: "Fleming, PA",
    city_name: "Fleming",
  },
  {
    postal_code: "24054",
    full_city_name: "Axton, VA",
    city_name: "Axton",
  },
  {
    postal_code: "12950",
    full_city_name: "Lewis, NY",
    city_name: "Lewis",
  },
  {
    postal_code: "64648",
    full_city_name: "Jamesport, MO",
    city_name: "Jamesport",
  },
  {
    postal_code: "39354",
    full_city_name: "Preston, MS",
    city_name: "Preston",
  },
  {
    postal_code: "24714",
    full_city_name: "Beeson, WV",
    city_name: "Beeson",
  },
  {
    postal_code: "48122",
    full_city_name: "Melvindale, MI",
    city_name: "Melvindale",
  },
  {
    postal_code: "24916",
    full_city_name: "Asbury, WV",
    city_name: "Asbury",
  },
  {
    postal_code: "73557",
    full_city_name: "Medicine Park, OK",
    city_name: "Medicine Park",
  },
  {
    postal_code: "63766",
    full_city_name: "Millersville, MO",
    city_name: "Millersville",
  },
  {
    postal_code: "39342",
    full_city_name: "Marion, MS",
    city_name: "Marion",
  },
  {
    postal_code: "24570",
    full_city_name: "Lowry, VA",
    city_name: "Lowry",
  },
  {
    postal_code: "61749",
    full_city_name: "Kenney, IL",
    city_name: "Kenney",
  },
  {
    postal_code: "96710",
    full_city_name: "Hakalau, HI",
    city_name: "Hakalau",
  },
  {
    postal_code: "63567",
    full_city_name: "Worthington, MO",
    city_name: "Worthington",
  },
  {
    postal_code: "25844",
    full_city_name: "Glen Daniel, WV",
    city_name: "Glen Daniel",
  },
  {
    postal_code: "73546",
    full_city_name: "Grandfield, OK",
    city_name: "Grandfield",
  },
  {
    postal_code: "68455",
    full_city_name: "Union, NE",
    city_name: "Union",
  },
  {
    postal_code: "19554",
    full_city_name: "Shartlesville, PA",
    city_name: "Shartlesville",
  },
  {
    postal_code: "99820",
    full_city_name: "Angoon, AK",
    city_name: "Angoon",
  },
  {
    postal_code: "63882",
    full_city_name: "Wyatt, MO",
    city_name: "Wyatt",
  },
  {
    postal_code: "43317",
    full_city_name: "Chesterville, OH",
    city_name: "Chesterville",
  },
  {
    postal_code: "73768",
    full_city_name: "Ringwood, OK",
    city_name: "Ringwood",
  },
  {
    postal_code: "49282",
    full_city_name: "Somerset Center, MI",
    city_name: "Somerset Center",
  },
  {
    postal_code: "50538",
    full_city_name: "Farnhamville, IA",
    city_name: "Farnhamville",
  },
  {
    postal_code: "47382",
    full_city_name: "Saratoga, IN",
    city_name: "Saratoga",
  },
  {
    postal_code: "15324",
    full_city_name: "Cokeburg, PA",
    city_name: "Cokeburg",
  },
  {
    postal_code: "97840",
    full_city_name: "Oxbow, OR",
    city_name: "Oxbow",
  },
  {
    postal_code: "67730",
    full_city_name: "Atwood, KS",
    city_name: "Atwood",
  },
  {
    postal_code: "74754",
    full_city_name: "Ringold, OK",
    city_name: "Ringold",
  },
  {
    postal_code: "83320",
    full_city_name: "Carey, ID",
    city_name: "Carey",
  },
  {
    postal_code: "68067",
    full_city_name: "Walthill, NE",
    city_name: "Walthill",
  },
  {
    postal_code: "37852",
    full_city_name: "Robbins, TN",
    city_name: "Robbins",
  },
  {
    postal_code: "32709",
    full_city_name: "Christmas, FL",
    city_name: "Christmas",
  },
  {
    postal_code: "68117",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "68883",
    full_city_name: "Wood River, NE",
    city_name: "Wood River",
  },
  {
    postal_code: "72944",
    full_city_name: "Mansfield, AR",
    city_name: "Mansfield",
  },
  {
    postal_code: "53192",
    full_city_name: "Wilmot, WI",
    city_name: "Wilmot",
  },
  {
    postal_code: "39159",
    full_city_name: "Rolling Fork, MS",
    city_name: "Rolling Fork",
  },
  {
    postal_code: "62344",
    full_city_name: "Huntsville, IL",
    city_name: "Huntsville",
  },
  {
    postal_code: "71333",
    full_city_name: "Evergreen, LA",
    city_name: "Evergreen",
  },
  {
    postal_code: "87511",
    full_city_name: "Alcalde, NM",
    city_name: "Alcalde",
  },
  {
    postal_code: "48179",
    full_city_name: "South Rockwood, MI",
    city_name: "South Rockwood",
  },
  {
    postal_code: "83278",
    full_city_name: "Stanley, ID",
    city_name: "Stanley",
  },
  {
    postal_code: "61442",
    full_city_name: "Keithsburg, IL",
    city_name: "Keithsburg",
  },
  {
    postal_code: "56323",
    full_city_name: "Cyrus, MN",
    city_name: "Cyrus",
  },
  {
    postal_code: "31631",
    full_city_name: "Fargo, GA",
    city_name: "Fargo",
  },
  {
    postal_code: "67664",
    full_city_name: "Prairie View, KS",
    city_name: "Prairie View",
  },
  {
    postal_code: "67646",
    full_city_name: "Logan, KS",
    city_name: "Logan",
  },
  {
    postal_code: "41055",
    full_city_name: "Mayslick, KY",
    city_name: "Mayslick",
  },
  {
    postal_code: "04772",
    full_city_name: "Saint Agatha, ME",
    city_name: "Saint Agatha",
  },
  {
    postal_code: "89828",
    full_city_name: "Lamoille, NV",
    city_name: "Lamoille",
  },
  {
    postal_code: "50107",
    full_city_name: "Grand Junction, IA",
    city_name: "Grand Junction",
  },
  {
    postal_code: "83623",
    full_city_name: "Glenns Ferry, ID",
    city_name: "Glenns Ferry",
  },
  {
    postal_code: "41571",
    full_city_name: "Varney, KY",
    city_name: "Varney",
  },
  {
    postal_code: "04427",
    full_city_name: "Corinth, ME",
    city_name: "Corinth",
  },
  {
    postal_code: "56755",
    full_city_name: "Saint Vincent, MN",
    city_name: "Saint Vincent",
  },
  {
    postal_code: "55765",
    full_city_name: "Meadowlands, MN",
    city_name: "Meadowlands",
  },
  {
    postal_code: "45353",
    full_city_name: "Pemberton, OH",
    city_name: "Pemberton",
  },
  {
    postal_code: "50476",
    full_city_name: "Stacyville, IA",
    city_name: "Stacyville",
  },
  {
    postal_code: "14543",
    full_city_name: "Rush, NY",
    city_name: "Rush",
  },
  {
    postal_code: "19009",
    full_city_name: "Bryn Athyn, PA",
    city_name: "Bryn Athyn",
  },
  {
    postal_code: "68319",
    full_city_name: "Bradshaw, NE",
    city_name: "Bradshaw",
  },
  {
    postal_code: "62297",
    full_city_name: "Walsh, IL",
    city_name: "Walsh",
  },
  {
    postal_code: "61552",
    full_city_name: "Mossville, IL",
    city_name: "Mossville",
  },
  {
    postal_code: "93450",
    full_city_name: "San Ardo, CA",
    city_name: "San Ardo",
  },
  {
    postal_code: "95141",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "17508",
    full_city_name: "Brownstown, PA",
    city_name: "Brownstown",
  },
  {
    postal_code: "25670",
    full_city_name: "Delbarton, WV",
    city_name: "Delbarton",
  },
  {
    postal_code: "87531",
    full_city_name: "Embudo, NM",
    city_name: "Embudo",
  },
  {
    postal_code: "40037",
    full_city_name: "Loretto, KY",
    city_name: "Loretto",
  },
  {
    postal_code: "57054",
    full_city_name: "Ramona, SD",
    city_name: "Ramona",
  },
  {
    postal_code: "89310",
    full_city_name: "Austin, NV",
    city_name: "Austin",
  },
  {
    postal_code: "04745",
    full_city_name: "Frenchville, ME",
    city_name: "Frenchville",
  },
  {
    postal_code: "63866",
    full_city_name: "Marston, MO",
    city_name: "Marston",
  },
  {
    postal_code: "51018",
    full_city_name: "Cushing, IA",
    city_name: "Cushing",
  },
  {
    postal_code: "87937",
    full_city_name: "Hatch, NM",
    city_name: "Hatch",
  },
  {
    postal_code: "22832",
    full_city_name: "Keezletown, VA",
    city_name: "Keezletown",
  },
  {
    postal_code: "70773",
    full_city_name: "Rougon, LA",
    city_name: "Rougon",
  },
  {
    postal_code: "79845",
    full_city_name: "Presidio, TX",
    city_name: "Presidio",
  },
  {
    postal_code: "66050",
    full_city_name: "Lecompton, KS",
    city_name: "Lecompton",
  },
  {
    postal_code: "88072",
    full_city_name: "Vado, NM",
    city_name: "Vado",
  },
  {
    postal_code: "15758",
    full_city_name: "Marchand, PA",
    city_name: "Marchand",
  },
  {
    postal_code: "52731",
    full_city_name: "Charlotte, IA",
    city_name: "Charlotte",
  },
  {
    postal_code: "61344",
    full_city_name: "Mineral, IL",
    city_name: "Mineral",
  },
  {
    postal_code: "50421",
    full_city_name: "Belmond, IA",
    city_name: "Belmond",
  },
  {
    postal_code: "84001",
    full_city_name: "Altamont, UT",
    city_name: "Altamont",
  },
  {
    postal_code: "68647",
    full_city_name: "Monroe, NE",
    city_name: "Monroe",
  },
  {
    postal_code: "12960",
    full_city_name: "Moriah, NY",
    city_name: "Moriah",
  },
  {
    postal_code: "73663",
    full_city_name: "Seiling, OK",
    city_name: "Seiling",
  },
  {
    postal_code: "74038",
    full_city_name: "Jennings, OK",
    city_name: "Jennings",
  },
  {
    postal_code: "68044",
    full_city_name: "Nickerson, NE",
    city_name: "Nickerson",
  },
  {
    postal_code: "56363",
    full_city_name: "Pease, MN",
    city_name: "Pease",
  },
  {
    postal_code: "87711",
    full_city_name: "Anton Chico, NM",
    city_name: "Anton Chico",
  },
  {
    postal_code: "64655",
    full_city_name: "Lucerne, MO",
    city_name: "Lucerne",
  },
  {
    postal_code: "97488",
    full_city_name: "Vida, OR",
    city_name: "Vida",
  },
  {
    postal_code: "22723",
    full_city_name: "Hood, VA",
    city_name: "Hood",
  },
  {
    postal_code: "12490",
    full_city_name: "West Camp, NY",
    city_name: "West Camp",
  },
  {
    postal_code: "59460",
    full_city_name: "Loma, MT",
    city_name: "Loma",
  },
  {
    postal_code: "97107",
    full_city_name: "Bay City, OR",
    city_name: "Bay City",
  },
  {
    postal_code: "16932",
    full_city_name: "Mainesburg, PA",
    city_name: "Mainesburg",
  },
  {
    postal_code: "71651",
    full_city_name: "Jersey, AR",
    city_name: "Jersey",
  },
  {
    postal_code: "86433",
    full_city_name: "Oatman, AZ",
    city_name: "Oatman",
  },
  {
    postal_code: "36034",
    full_city_name: "Glenwood, AL",
    city_name: "Glenwood",
  },
  {
    postal_code: "79005",
    full_city_name: "Booker, TX",
    city_name: "Booker",
  },
  {
    postal_code: "31756",
    full_city_name: "Hartsfield, GA",
    city_name: "Hartsfield",
  },
  {
    postal_code: "58416",
    full_city_name: "Binford, ND",
    city_name: "Binford",
  },
  {
    postal_code: "15542",
    full_city_name: "Garrett, PA",
    city_name: "Garrett",
  },
  {
    postal_code: "66616",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "74558",
    full_city_name: "Nashoba, OK",
    city_name: "Nashoba",
  },
  {
    postal_code: "49451",
    full_city_name: "Ravenna, MI",
    city_name: "Ravenna",
  },
  {
    postal_code: "67622",
    full_city_name: "Almena, KS",
    city_name: "Almena",
  },
  {
    postal_code: "96063",
    full_city_name: "Mineral, CA",
    city_name: "Mineral",
  },
  {
    postal_code: "22972",
    full_city_name: "Somerset, VA",
    city_name: "Somerset",
  },
  {
    postal_code: "13621",
    full_city_name: "Chase Mills, NY",
    city_name: "Chase Mills",
  },
  {
    postal_code: "68786",
    full_city_name: "Wausa, NE",
    city_name: "Wausa",
  },
  {
    postal_code: "82081",
    full_city_name: "Meriden, WY",
    city_name: "Meriden",
  },
  {
    postal_code: "40870",
    full_city_name: "Totz, KY",
    city_name: "Totz",
  },
  {
    postal_code: "95923",
    full_city_name: "Canyon Dam, CA",
    city_name: "Canyon Dam",
  },
  {
    postal_code: "58737",
    full_city_name: "Flaxton, ND",
    city_name: "Flaxton",
  },
  {
    postal_code: "16440",
    full_city_name: "Venango, PA",
    city_name: "Venango",
  },
  {
    postal_code: "50539",
    full_city_name: "Fenton, IA",
    city_name: "Fenton",
  },
  {
    postal_code: "45164",
    full_city_name: "Port William, OH",
    city_name: "Port William",
  },
  {
    postal_code: "47038",
    full_city_name: "Patriot, IN",
    city_name: "Patriot",
  },
  {
    postal_code: "14613",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "52203",
    full_city_name: "Amana, IA",
    city_name: "Amana",
  },
  {
    postal_code: "38578",
    full_city_name: "Pleasant Hill, TN",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "41660",
    full_city_name: "Teaberry, KY",
    city_name: "Teaberry",
  },
  {
    postal_code: "47853",
    full_city_name: "Harmony, IN",
    city_name: "Harmony",
  },
  {
    postal_code: "97473",
    full_city_name: "Scottsburg, OR",
    city_name: "Scottsburg",
  },
  {
    postal_code: "87512",
    full_city_name: "Amalia, NM",
    city_name: "Amalia",
  },
  {
    postal_code: "74740",
    full_city_name: "Haworth, OK",
    city_name: "Haworth",
  },
  {
    postal_code: "03609",
    full_city_name: "North Walpole, NH",
    city_name: "North Walpole",
  },
  {
    postal_code: "41831",
    full_city_name: "Leburn, KY",
    city_name: "Leburn",
  },
  {
    postal_code: "70732",
    full_city_name: "Fordoche, LA",
    city_name: "Fordoche",
  },
  {
    postal_code: "13313",
    full_city_name: "Bridgewater, NY",
    city_name: "Bridgewater",
  },
  {
    postal_code: "49096",
    full_city_name: "Vermontville, MI",
    city_name: "Vermontville",
  },
  {
    postal_code: "51559",
    full_city_name: "Neola, IA",
    city_name: "Neola",
  },
  {
    postal_code: "38778",
    full_city_name: "Sunflower, MS",
    city_name: "Sunflower",
  },
  {
    postal_code: "65449",
    full_city_name: "Cook Sta, MO",
    city_name: "Cook Sta",
  },
  {
    postal_code: "58713",
    full_city_name: "Bantry, ND",
    city_name: "Bantry",
  },
  {
    postal_code: "58251",
    full_city_name: "Larimore, ND",
    city_name: "Larimore",
  },
  {
    postal_code: "84083",
    full_city_name: "Wendover, UT",
    city_name: "Wendover",
  },
  {
    postal_code: "38454",
    full_city_name: "Duck River, TN",
    city_name: "Duck River",
  },
  {
    postal_code: "21649",
    full_city_name: "Marydel, MD",
    city_name: "Marydel",
  },
  {
    postal_code: "83808",
    full_city_name: "Calder, ID",
    city_name: "Calder",
  },
  {
    postal_code: "57334",
    full_city_name: "Ethan, SD",
    city_name: "Ethan",
  },
  {
    postal_code: "24646",
    full_city_name: "Rowe, VA",
    city_name: "Rowe",
  },
  {
    postal_code: "95412",
    full_city_name: "Annapolis, CA",
    city_name: "Annapolis",
  },
  {
    postal_code: "71407",
    full_city_name: "Bentley, LA",
    city_name: "Bentley",
  },
  {
    postal_code: "79239",
    full_city_name: "Lakeview, TX",
    city_name: "Lakeview",
  },
  {
    postal_code: "72355",
    full_city_name: "Lexa, AR",
    city_name: "Lexa",
  },
  {
    postal_code: "36852",
    full_city_name: "Cusseta, AL",
    city_name: "Cusseta",
  },
  {
    postal_code: "60917",
    full_city_name: "Buckingham, IL",
    city_name: "Buckingham",
  },
  {
    postal_code: "69044",
    full_city_name: "Trenton, NE",
    city_name: "Trenton",
  },
  {
    postal_code: "61742",
    full_city_name: "Goodfield, IL",
    city_name: "Goodfield",
  },
  {
    postal_code: "07021",
    full_city_name: "Essex Fells, NJ",
    city_name: "Essex Fells",
  },
  {
    postal_code: "48761",
    full_city_name: "South Branch, MI",
    city_name: "South Branch",
  },
  {
    postal_code: "76366",
    full_city_name: "Holliday, TX",
    city_name: "Holliday",
  },
  {
    postal_code: "59262",
    full_city_name: "Savage, MT",
    city_name: "Savage",
  },
  {
    postal_code: "42154",
    full_city_name: "Knob Lick, KY",
    city_name: "Knob Lick",
  },
  {
    postal_code: "57257",
    full_city_name: "Peever, SD",
    city_name: "Peever",
  },
  {
    postal_code: "17265",
    full_city_name: "Upperstrasburg, PA",
    city_name: "Upperstrasburg",
  },
  {
    postal_code: "72413",
    full_city_name: "Biggers, AR",
    city_name: "Biggers",
  },
  {
    postal_code: "57467",
    full_city_name: "Orient, SD",
    city_name: "Orient",
  },
  {
    postal_code: "66948",
    full_city_name: "Jamestown, KS",
    city_name: "Jamestown",
  },
  {
    postal_code: "88311",
    full_city_name: "Alamogordo, NM",
    city_name: "Alamogordo",
  },
  {
    postal_code: "67739",
    full_city_name: "Herndon, KS",
    city_name: "Herndon",
  },
  {
    postal_code: "59446",
    full_city_name: "Geraldine, MT",
    city_name: "Geraldine",
  },
  {
    postal_code: "68634",
    full_city_name: "Duncan, NE",
    city_name: "Duncan",
  },
  {
    postal_code: "13846",
    full_city_name: "Treadwell, NY",
    city_name: "Treadwell",
  },
  {
    postal_code: "08340",
    full_city_name: "Milmay, NJ",
    city_name: "Milmay",
  },
  {
    postal_code: "10282",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "66963",
    full_city_name: "Randall, KS",
    city_name: "Randall",
  },
  {
    postal_code: "64470",
    full_city_name: "Mound City, MO",
    city_name: "Mound City",
  },
  {
    postal_code: "98552",
    full_city_name: "Humptulips, WA",
    city_name: "Humptulips",
  },
  {
    postal_code: "50602",
    full_city_name: "Allison, IA",
    city_name: "Allison",
  },
  {
    postal_code: "49967",
    full_city_name: "Trout Creek, MI",
    city_name: "Trout Creek",
  },
  {
    postal_code: "73647",
    full_city_name: "Foss, OK",
    city_name: "Foss",
  },
  {
    postal_code: "59524",
    full_city_name: "Dodson, MT",
    city_name: "Dodson",
  },
  {
    postal_code: "72014",
    full_city_name: "Beedeville, AR",
    city_name: "Beedeville",
  },
  {
    postal_code: "41386",
    full_city_name: "Vincent, KY",
    city_name: "Vincent",
  },
  {
    postal_code: "26866",
    full_city_name: "Upper Tract, WV",
    city_name: "Upper Tract",
  },
  {
    postal_code: "36475",
    full_city_name: "Repton, AL",
    city_name: "Repton",
  },
  {
    postal_code: "37359",
    full_city_name: "Mulberry, TN",
    city_name: "Mulberry",
  },
  {
    postal_code: "46991",
    full_city_name: "Van Buren, IN",
    city_name: "Van Buren",
  },
  {
    postal_code: "49902",
    full_city_name: "Alpha, MI",
    city_name: "Alpha",
  },
  {
    postal_code: "78122",
    full_city_name: "Leesville, TX",
    city_name: "Leesville",
  },
  {
    postal_code: "33840",
    full_city_name: "Eaton Park, FL",
    city_name: "Eaton Park",
  },
  {
    postal_code: "79832",
    full_city_name: "Alpine, TX",
    city_name: "Alpine",
  },
  {
    postal_code: "71065",
    full_city_name: "Pleasant Hill, LA",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "72769",
    full_city_name: "Summers, AR",
    city_name: "Summers",
  },
  {
    postal_code: "18059",
    full_city_name: "Laurys Station, PA",
    city_name: "Laurys Station",
  },
  {
    postal_code: "51572",
    full_city_name: "Soldier, IA",
    city_name: "Soldier",
  },
  {
    postal_code: "39654",
    full_city_name: "Monticello, MS",
    city_name: "Monticello",
  },
  {
    postal_code: "11548",
    full_city_name: "Greenvale, NY",
    city_name: "Greenvale",
  },
  {
    postal_code: "87053",
    full_city_name: "San Ysidro, NM",
    city_name: "San Ysidro",
  },
  {
    postal_code: "12460",
    full_city_name: "Oak Hill, NY",
    city_name: "Oak Hill",
  },
  {
    postal_code: "65570",
    full_city_name: "Success, MO",
    city_name: "Success",
  },
  {
    postal_code: "49805",
    full_city_name: "Allouez, MI",
    city_name: "Allouez",
  },
  {
    postal_code: "56236",
    full_city_name: "Dumont, MN",
    city_name: "Dumont",
  },
  {
    postal_code: "47577",
    full_city_name: "Saint Meinrad, IN",
    city_name: "Saint Meinrad",
  },
  {
    postal_code: "56517",
    full_city_name: "Beltrami, MN",
    city_name: "Beltrami",
  },
  {
    postal_code: "52751",
    full_city_name: "Grand Mound, IA",
    city_name: "Grand Mound",
  },
  {
    postal_code: "77974",
    full_city_name: "Meyersville, TX",
    city_name: "Meyersville",
  },
  {
    postal_code: "22946",
    full_city_name: "Keene, VA",
    city_name: "Keene",
  },
  {
    postal_code: "83545",
    full_city_name: "Peck, ID",
    city_name: "Peck",
  },
  {
    postal_code: "65239",
    full_city_name: "Cairo, MO",
    city_name: "Cairo",
  },
  {
    postal_code: "04858",
    full_city_name: "South Thomaston, ME",
    city_name: "South Thomaston",
  },
  {
    postal_code: "52047",
    full_city_name: "Farmersburg, IA",
    city_name: "Farmersburg",
  },
  {
    postal_code: "68823",
    full_city_name: "Burwell, NE",
    city_name: "Burwell",
  },
  {
    postal_code: "16326",
    full_city_name: "Fryburg, PA",
    city_name: "Fryburg",
  },
  {
    postal_code: "87323",
    full_city_name: "Thoreau, NM",
    city_name: "Thoreau",
  },
  {
    postal_code: "26237",
    full_city_name: "Tallmansville, WV",
    city_name: "Tallmansville",
  },
  {
    postal_code: "57476",
    full_city_name: "Tulare, SD",
    city_name: "Tulare",
  },
  {
    postal_code: "27953",
    full_city_name: "Manns Harbor, NC",
    city_name: "Manns Harbor",
  },
  {
    postal_code: "73654",
    full_city_name: "Leedey, OK",
    city_name: "Leedey",
  },
  {
    postal_code: "24839",
    full_city_name: "Hanover, WV",
    city_name: "Hanover",
  },
  {
    postal_code: "59322",
    full_city_name: "Cohagen, MT",
    city_name: "Cohagen",
  },
  {
    postal_code: "68004",
    full_city_name: "Bancroft, NE",
    city_name: "Bancroft",
  },
  {
    postal_code: "61313",
    full_city_name: "Blackstone, IL",
    city_name: "Blackstone",
  },
  {
    postal_code: "82223",
    full_city_name: "Lingle, WY",
    city_name: "Lingle",
  },
  {
    postal_code: "58642",
    full_city_name: "Manning, ND",
    city_name: "Manning",
  },
  {
    postal_code: "56178",
    full_city_name: "Tyler, MN",
    city_name: "Tyler",
  },
  {
    postal_code: "25985",
    full_city_name: "Sandstone, WV",
    city_name: "Sandstone",
  },
  {
    postal_code: "51235",
    full_city_name: "Doon, IA",
    city_name: "Doon",
  },
  {
    postal_code: "46290",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "95979",
    full_city_name: "Stonyford, CA",
    city_name: "Stonyford",
  },
  {
    postal_code: "49903",
    full_city_name: "Amasa, MI",
    city_name: "Amasa",
  },
  {
    postal_code: "63453",
    full_city_name: "Luray, MO",
    city_name: "Luray",
  },
  {
    postal_code: "13860",
    full_city_name: "West Davenport, NY",
    city_name: "West Davenport",
  },
  {
    postal_code: "54667",
    full_city_name: "Westby, WI",
    city_name: "Westby",
  },
  {
    postal_code: "87732",
    full_city_name: "Mora, NM",
    city_name: "Mora",
  },
  {
    postal_code: "61449",
    full_city_name: "La Fayette, IL",
    city_name: "La Fayette",
  },
  {
    postal_code: "52593",
    full_city_name: "Udell, IA",
    city_name: "Udell",
  },
  {
    postal_code: "14203",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "72682",
    full_city_name: "Valley Springs, AR",
    city_name: "Valley Springs",
  },
  {
    postal_code: "15922",
    full_city_name: "Belsano, PA",
    city_name: "Belsano",
  },
  {
    postal_code: "82322",
    full_city_name: "Bairoil, WY",
    city_name: "Bairoil",
  },
  {
    postal_code: "82620",
    full_city_name: "Alcova, WY",
    city_name: "Alcova",
  },
  {
    postal_code: "99685",
    full_city_name: "Unalaska, AK",
    city_name: "Unalaska",
  },
  {
    postal_code: "22820",
    full_city_name: "Criders, VA",
    city_name: "Criders",
  },
  {
    postal_code: "68936",
    full_city_name: "Edison, NE",
    city_name: "Edison",
  },
  {
    postal_code: "71450",
    full_city_name: "Marthaville, LA",
    city_name: "Marthaville",
  },
  {
    postal_code: "74630",
    full_city_name: "Billings, OK",
    city_name: "Billings",
  },
  {
    postal_code: "51446",
    full_city_name: "Irwin, IA",
    city_name: "Irwin",
  },
  {
    postal_code: "50044",
    full_city_name: "Bussey, IA",
    city_name: "Bussey",
  },
  {
    postal_code: "16036",
    full_city_name: "Foxburg, PA",
    city_name: "Foxburg",
  },
  {
    postal_code: "62999",
    full_city_name: "Zeigler, IL",
    city_name: "Zeigler",
  },
  {
    postal_code: "88048",
    full_city_name: "Mesquite, NM",
    city_name: "Mesquite",
  },
  {
    postal_code: "84086",
    full_city_name: "Woodruff, UT",
    city_name: "Woodruff",
  },
  {
    postal_code: "71027",
    full_city_name: "Frierson, LA",
    city_name: "Frierson",
  },
  {
    postal_code: "05903",
    full_city_name: "Canaan, VT",
    city_name: "Canaan",
  },
  {
    postal_code: "88026",
    full_city_name: "Santa Clara, NM",
    city_name: "Santa Clara",
  },
  {
    postal_code: "14898",
    full_city_name: "Woodhull, NY",
    city_name: "Woodhull",
  },
  {
    postal_code: "72773",
    full_city_name: "Wesley, AR",
    city_name: "Wesley",
  },
  {
    postal_code: "70342",
    full_city_name: "Berwick, LA",
    city_name: "Berwick",
  },
  {
    postal_code: "05766",
    full_city_name: "Ripton, VT",
    city_name: "Ripton",
  },
  {
    postal_code: "72134",
    full_city_name: "Roe, AR",
    city_name: "Roe",
  },
  {
    postal_code: "04760",
    full_city_name: "Monticello, ME",
    city_name: "Monticello",
  },
  {
    postal_code: "15688",
    full_city_name: "Tarrs, PA",
    city_name: "Tarrs",
  },
  {
    postal_code: "71640",
    full_city_name: "Eudora, AR",
    city_name: "Eudora",
  },
  {
    postal_code: "58529",
    full_city_name: "Carson, ND",
    city_name: "Carson",
  },
  {
    postal_code: "66843",
    full_city_name: "Cedar Point, KS",
    city_name: "Cedar Point",
  },
  {
    postal_code: "71745",
    full_city_name: "Harrell, AR",
    city_name: "Harrell",
  },
  {
    postal_code: "98950",
    full_city_name: "Vantage, WA",
    city_name: "Vantage",
  },
  {
    postal_code: "59243",
    full_city_name: "Lambert, MT",
    city_name: "Lambert",
  },
  {
    postal_code: "58316",
    full_city_name: "Belcourt, ND",
    city_name: "Belcourt",
  },
  {
    postal_code: "61453",
    full_city_name: "Little York, IL",
    city_name: "Little York",
  },
  {
    postal_code: "97911",
    full_city_name: "Juntura, OR",
    city_name: "Juntura",
  },
  {
    postal_code: "82219",
    full_city_name: "Jay Em, WY",
    city_name: "Jay Em",
  },
  {
    postal_code: "50607",
    full_city_name: "Aurora, IA",
    city_name: "Aurora",
  },
  {
    postal_code: "66507",
    full_city_name: "Maple Hill, KS",
    city_name: "Maple Hill",
  },
  {
    postal_code: "43014",
    full_city_name: "Danville, OH",
    city_name: "Danville",
  },
  {
    postal_code: "39156",
    full_city_name: "Redwood, MS",
    city_name: "Redwood",
  },
  {
    postal_code: "64482",
    full_city_name: "Rock Port, MO",
    city_name: "Rock Port",
  },
  {
    postal_code: "42338",
    full_city_name: "Dundee, KY",
    city_name: "Dundee",
  },
  {
    postal_code: "20632",
    full_city_name: "Faulkner, MD",
    city_name: "Faulkner",
  },
  {
    postal_code: "39301",
    full_city_name: "Meridian, MS",
    city_name: "Meridian",
  },
  {
    postal_code: "76832",
    full_city_name: "Cherokee, TX",
    city_name: "Cherokee",
  },
  {
    postal_code: "26519",
    full_city_name: "Albright, WV",
    city_name: "Albright",
  },
  {
    postal_code: "38042",
    full_city_name: "Hickory Valley, TN",
    city_name: "Hickory Valley",
  },
  {
    postal_code: "52620",
    full_city_name: "Bonaparte, IA",
    city_name: "Bonaparte",
  },
  {
    postal_code: "43759",
    full_city_name: "Morristown, OH",
    city_name: "Morristown",
  },
  {
    postal_code: "04571",
    full_city_name: "Trevett, ME",
    city_name: "Trevett",
  },
  {
    postal_code: "24818",
    full_city_name: "Brenton, WV",
    city_name: "Brenton",
  },
  {
    postal_code: "47943",
    full_city_name: "Fair Oaks, IN",
    city_name: "Fair Oaks",
  },
  {
    postal_code: "12964",
    full_city_name: "New Russia, NY",
    city_name: "New Russia",
  },
  {
    postal_code: "05061",
    full_city_name: "Randolph Center, VT",
    city_name: "Randolph Center",
  },
  {
    postal_code: "50068",
    full_city_name: "Derby, IA",
    city_name: "Derby",
  },
  {
    postal_code: "62878",
    full_city_name: "Rinard, IL",
    city_name: "Rinard",
  },
  {
    postal_code: "39439",
    full_city_name: "Heidelberg, MS",
    city_name: "Heidelberg",
  },
  {
    postal_code: "96121",
    full_city_name: "Milford, CA",
    city_name: "Milford",
  },
  {
    postal_code: "50520",
    full_city_name: "Bradgate, IA",
    city_name: "Bradgate",
  },
  {
    postal_code: "36584",
    full_city_name: "Vinegar Bend, AL",
    city_name: "Vinegar Bend",
  },
  {
    postal_code: "61415",
    full_city_name: "Avon, IL",
    city_name: "Avon",
  },
  {
    postal_code: "61488",
    full_city_name: "Wataga, IL",
    city_name: "Wataga",
  },
  {
    postal_code: "81090",
    full_city_name: "Walsh, CO",
    city_name: "Walsh",
  },
  {
    postal_code: "32355",
    full_city_name: "Saint Marks, FL",
    city_name: "Saint Marks",
  },
  {
    postal_code: "41260",
    full_city_name: "Thelma, KY",
    city_name: "Thelma",
  },
  {
    postal_code: "38601",
    full_city_name: "Abbeville, MS",
    city_name: "Abbeville",
  },
  {
    postal_code: "71940",
    full_city_name: "Delight, AR",
    city_name: "Delight",
  },
  {
    postal_code: "10511",
    full_city_name: "Buchanan, NY",
    city_name: "Buchanan",
  },
  {
    postal_code: "66854",
    full_city_name: "Hartford, KS",
    city_name: "Hartford",
  },
  {
    postal_code: "43115",
    full_city_name: "Clarksburg, OH",
    city_name: "Clarksburg",
  },
  {
    postal_code: "59479",
    full_city_name: "Stanford, MT",
    city_name: "Stanford",
  },
  {
    postal_code: "55793",
    full_city_name: "Warba, MN",
    city_name: "Warba",
  },
  {
    postal_code: "44878",
    full_city_name: "Shiloh, OH",
    city_name: "Shiloh",
  },
  {
    postal_code: "84533",
    full_city_name: "Lake Powell, UT",
    city_name: "Lake Powell",
  },
  {
    postal_code: "58327",
    full_city_name: "Crary, ND",
    city_name: "Crary",
  },
  {
    postal_code: "81327",
    full_city_name: "Lewis, CO",
    city_name: "Lewis",
  },
  {
    postal_code: "88134",
    full_city_name: "Taiban, NM",
    city_name: "Taiban",
  },
  {
    postal_code: "41722",
    full_city_name: "Bulan, KY",
    city_name: "Bulan",
  },
  {
    postal_code: "26610",
    full_city_name: "Birch River, WV",
    city_name: "Birch River",
  },
  {
    postal_code: "64728",
    full_city_name: "Bronaugh, MO",
    city_name: "Bronaugh",
  },
  {
    postal_code: "48856",
    full_city_name: "Middleton, MI",
    city_name: "Middleton",
  },
  {
    postal_code: "14486",
    full_city_name: "Linwood, NY",
    city_name: "Linwood",
  },
  {
    postal_code: "15544",
    full_city_name: "Gray, PA",
    city_name: "Gray",
  },
  {
    postal_code: "41776",
    full_city_name: "Wooton, KY",
    city_name: "Wooton",
  },
  {
    postal_code: "89001",
    full_city_name: "Alamo, NV",
    city_name: "Alamo",
  },
  {
    postal_code: "68447",
    full_city_name: "Table Rock, NE",
    city_name: "Table Rock",
  },
  {
    postal_code: "14423",
    full_city_name: "Caledonia, NY",
    city_name: "Caledonia",
  },
  {
    postal_code: "38753",
    full_city_name: "Inverness, MS",
    city_name: "Inverness",
  },
  {
    postal_code: "12762",
    full_city_name: "Mongaup Valley, NY",
    city_name: "Mongaup Valley",
  },
  {
    postal_code: "65676",
    full_city_name: "Isabella, MO",
    city_name: "Isabella",
  },
  {
    postal_code: "16738",
    full_city_name: "Lewis Run, PA",
    city_name: "Lewis Run",
  },
  {
    postal_code: "50449",
    full_city_name: "Klemme, IA",
    city_name: "Klemme",
  },
  {
    postal_code: "59745",
    full_city_name: "Norris, MT",
    city_name: "Norris",
  },
  {
    postal_code: "99682",
    full_city_name: "Tyonek, AK",
    city_name: "Tyonek",
  },
  {
    postal_code: "68325",
    full_city_name: "Byron, NE",
    city_name: "Byron",
  },
  {
    postal_code: "03871",
    full_city_name: "Rye Beach, NH",
    city_name: "Rye Beach",
  },
  {
    postal_code: "99556",
    full_city_name: "Anchor Point, AK",
    city_name: "Anchor Point",
  },
  {
    postal_code: "89402",
    full_city_name: "Crystal Bay, NV",
    city_name: "Crystal Bay",
  },
  {
    postal_code: "58541",
    full_city_name: "Golden Valley, ND",
    city_name: "Golden Valley",
  },
  {
    postal_code: "44625",
    full_city_name: "East Rochester, OH",
    city_name: "East Rochester",
  },
  {
    postal_code: "66862",
    full_city_name: "Matfield Green, KS",
    city_name: "Matfield Green",
  },
  {
    postal_code: "25081",
    full_city_name: "Foster, WV",
    city_name: "Foster",
  },
  {
    postal_code: "56271",
    full_city_name: "Murdock, MN",
    city_name: "Murdock",
  },
  {
    postal_code: "51520",
    full_city_name: "Arion, IA",
    city_name: "Arion",
  },
  {
    postal_code: "68752",
    full_city_name: "Meadow Grove, NE",
    city_name: "Meadow Grove",
  },
  {
    postal_code: "48475",
    full_city_name: "Ubly, MI",
    city_name: "Ubly",
  },
  {
    postal_code: "57242",
    full_city_name: "Hazel, SD",
    city_name: "Hazel",
  },
  {
    postal_code: "29104",
    full_city_name: "Mayesville, SC",
    city_name: "Mayesville",
  },
  {
    postal_code: "68833",
    full_city_name: "Dunning, NE",
    city_name: "Dunning",
  },
  {
    postal_code: "93234",
    full_city_name: "Huron, CA",
    city_name: "Huron",
  },
  {
    postal_code: "97432",
    full_city_name: "Dillard, OR",
    city_name: "Dillard",
  },
  {
    postal_code: "58570",
    full_city_name: "Solen, ND",
    city_name: "Solen",
  },
  {
    postal_code: "67061",
    full_city_name: "Hazelton, KS",
    city_name: "Hazelton",
  },
  {
    postal_code: "51654",
    full_city_name: "Thurman, IA",
    city_name: "Thurman",
  },
  {
    postal_code: "87012",
    full_city_name: "Coyote, NM",
    city_name: "Coyote",
  },
  {
    postal_code: "03450",
    full_city_name: "Harrisville, NH",
    city_name: "Harrisville",
  },
  {
    postal_code: "25647",
    full_city_name: "Switzer, WV",
    city_name: "Switzer",
  },
  {
    postal_code: "13454",
    full_city_name: "Salisbury Center, NY",
    city_name: "Salisbury Center",
  },
  {
    postal_code: "57428",
    full_city_name: "Bowdle, SD",
    city_name: "Bowdle",
  },
  {
    postal_code: "83215",
    full_city_name: "Atomic City, ID",
    city_name: "Atomic City",
  },
  {
    postal_code: "83643",
    full_city_name: "Mesa, ID",
    city_name: "Mesa",
  },
  {
    postal_code: "25009",
    full_city_name: "Ashford, WV",
    city_name: "Ashford",
  },
  {
    postal_code: "46990",
    full_city_name: "Urbana, IN",
    city_name: "Urbana",
  },
  {
    postal_code: "38391",
    full_city_name: "Denmark, TN",
    city_name: "Denmark",
  },
  {
    postal_code: "52221",
    full_city_name: "Guernsey, IA",
    city_name: "Guernsey",
  },
  {
    postal_code: "65246",
    full_city_name: "Dalton, MO",
    city_name: "Dalton",
  },
  {
    postal_code: "27938",
    full_city_name: "Gatesville, NC",
    city_name: "Gatesville",
  },
  {
    postal_code: "83243",
    full_city_name: "Holbrook, ID",
    city_name: "Holbrook",
  },
  {
    postal_code: "95463",
    full_city_name: "Navarro, CA",
    city_name: "Navarro",
  },
  {
    postal_code: "64672",
    full_city_name: "Powersville, MO",
    city_name: "Powersville",
  },
  {
    postal_code: "74452",
    full_city_name: "Peggs, OK",
    city_name: "Peggs",
  },
  {
    postal_code: "36907",
    full_city_name: "Cuba, AL",
    city_name: "Cuba",
  },
  {
    postal_code: "67059",
    full_city_name: "Haviland, KS",
    city_name: "Haviland",
  },
  {
    postal_code: "74854",
    full_city_name: "Maud, OK",
    city_name: "Maud",
  },
  {
    postal_code: "73766",
    full_city_name: "Pond Creek, OK",
    city_name: "Pond Creek",
  },
  {
    postal_code: "40070",
    full_city_name: "Sulphur, KY",
    city_name: "Sulphur",
  },
  {
    postal_code: "67738",
    full_city_name: "Grinnell, KS",
    city_name: "Grinnell",
  },
  {
    postal_code: "40823",
    full_city_name: "Cumberland, KY",
    city_name: "Cumberland",
  },
  {
    postal_code: "51462",
    full_city_name: "Scranton, IA",
    city_name: "Scranton",
  },
  {
    postal_code: "67427",
    full_city_name: "Bushton, KS",
    city_name: "Bushton",
  },
  {
    postal_code: "13147",
    full_city_name: "Scipio Center, NY",
    city_name: "Scipio Center",
  },
  {
    postal_code: "96729",
    full_city_name: "Hoolehua, HI",
    city_name: "Hoolehua",
  },
  {
    postal_code: "05342",
    full_city_name: "Jacksonville, VT",
    city_name: "Jacksonville",
  },
  {
    postal_code: "73551",
    full_city_name: "Hollister, OK",
    city_name: "Hollister",
  },
  {
    postal_code: "57266",
    full_city_name: "Summit, SD",
    city_name: "Summit",
  },
  {
    postal_code: "26361",
    full_city_name: "Gypsy, WV",
    city_name: "Gypsy",
  },
  {
    postal_code: "67345",
    full_city_name: "Elk Falls, KS",
    city_name: "Elk Falls",
  },
  {
    postal_code: "12985",
    full_city_name: "Schuyler Falls, NY",
    city_name: "Schuyler Falls",
  },
  {
    postal_code: "55950",
    full_city_name: "Lansing, MN",
    city_name: "Lansing",
  },
  {
    postal_code: "73448",
    full_city_name: "Marietta, OK",
    city_name: "Marietta",
  },
  {
    postal_code: "15555",
    full_city_name: "Quecreek, PA",
    city_name: "Quecreek",
  },
  {
    postal_code: "76680",
    full_city_name: "Reagan, TX",
    city_name: "Reagan",
  },
  {
    postal_code: "68766",
    full_city_name: "Page, NE",
    city_name: "Page",
  },
  {
    postal_code: "24131",
    full_city_name: "Paint Bank, VA",
    city_name: "Paint Bank",
  },
  {
    postal_code: "97406",
    full_city_name: "Agness, OR",
    city_name: "Agness",
  },
  {
    postal_code: "71419",
    full_city_name: "Converse, LA",
    city_name: "Converse",
  },
  {
    postal_code: "68321",
    full_city_name: "Brownville, NE",
    city_name: "Brownville",
  },
  {
    postal_code: "69142",
    full_city_name: "Halsey, NE",
    city_name: "Halsey",
  },
  {
    postal_code: "17758",
    full_city_name: "Muncy Valley, PA",
    city_name: "Muncy Valley",
  },
  {
    postal_code: "34661",
    full_city_name: "Nobleton, FL",
    city_name: "Nobleton",
  },
  {
    postal_code: "45621",
    full_city_name: "Coalton, OH",
    city_name: "Coalton",
  },
  {
    postal_code: "12914",
    full_city_name: "Bombay, NY",
    city_name: "Bombay",
  },
  {
    postal_code: "81656",
    full_city_name: "Woody Creek, CO",
    city_name: "Woody Creek",
  },
  {
    postal_code: "44276",
    full_city_name: "Sterling, OH",
    city_name: "Sterling",
  },
  {
    postal_code: "12438",
    full_city_name: "Halcottsville, NY",
    city_name: "Halcottsville",
  },
  {
    postal_code: "93628",
    full_city_name: "Hume, CA",
    city_name: "Hume",
  },
  {
    postal_code: "59643",
    full_city_name: "Toston, MT",
    city_name: "Toston",
  },
  {
    postal_code: "54027",
    full_city_name: "Wilson, WI",
    city_name: "Wilson",
  },
  {
    postal_code: "05867",
    full_city_name: "Sutton, VT",
    city_name: "Sutton",
  },
  {
    postal_code: "40807",
    full_city_name: "Benham, KY",
    city_name: "Benham",
  },
  {
    postal_code: "25287",
    full_city_name: "West Columbia, WV",
    city_name: "West Columbia",
  },
  {
    postal_code: "04611",
    full_city_name: "Beals, ME",
    city_name: "Beals",
  },
  {
    postal_code: "88119",
    full_city_name: "Fort Sumner, NM",
    city_name: "Fort Sumner",
  },
  {
    postal_code: "21711",
    full_city_name: "Big Pool, MD",
    city_name: "Big Pool",
  },
  {
    postal_code: "68872",
    full_city_name: "Saint Libory, NE",
    city_name: "Saint Libory",
  },
  {
    postal_code: "58748",
    full_city_name: "Kramer, ND",
    city_name: "Kramer",
  },
  {
    postal_code: "69045",
    full_city_name: "Wauneta, NE",
    city_name: "Wauneta",
  },
  {
    postal_code: "51033",
    full_city_name: "Linn Grove, IA",
    city_name: "Linn Grove",
  },
  {
    postal_code: "77857",
    full_city_name: "Gause, TX",
    city_name: "Gause",
  },
  {
    postal_code: "25103",
    full_city_name: "Hansford, WV",
    city_name: "Hansford",
  },
  {
    postal_code: "59919",
    full_city_name: "Hungry Horse, MT",
    city_name: "Hungry Horse",
  },
  {
    postal_code: "61863",
    full_city_name: "Pesotum, IL",
    city_name: "Pesotum",
  },
  {
    postal_code: "20812",
    full_city_name: "Glen Echo, MD",
    city_name: "Glen Echo",
  },
  {
    postal_code: "32631",
    full_city_name: "Earleton, FL",
    city_name: "Earleton",
  },
  {
    postal_code: "39088",
    full_city_name: "Holly Bluff, MS",
    city_name: "Holly Bluff",
  },
  {
    postal_code: "88230",
    full_city_name: "Dexter, NM",
    city_name: "Dexter",
  },
  {
    postal_code: "58787",
    full_city_name: "Tolley, ND",
    city_name: "Tolley",
  },
  {
    postal_code: "51051",
    full_city_name: "Rodney, IA",
    city_name: "Rodney",
  },
  {
    postal_code: "36432",
    full_city_name: "Castleberry, AL",
    city_name: "Castleberry",
  },
  {
    postal_code: "62422",
    full_city_name: "Cowden, IL",
    city_name: "Cowden",
  },
  {
    postal_code: "59433",
    full_city_name: "Dutton, MT",
    city_name: "Dutton",
  },
  {
    postal_code: "27509",
    full_city_name: "Butner, NC",
    city_name: "Butner",
  },
  {
    postal_code: "31744",
    full_city_name: "Doerun, GA",
    city_name: "Doerun",
  },
  {
    postal_code: "15757",
    full_city_name: "Mahaffey, PA",
    city_name: "Mahaffey",
  },
  {
    postal_code: "79230",
    full_city_name: "Dodson, TX",
    city_name: "Dodson",
  },
  {
    postal_code: "58031",
    full_city_name: "Fingal, ND",
    city_name: "Fingal",
  },
  {
    postal_code: "59424",
    full_city_name: "Coffee Creek, MT",
    city_name: "Coffee Creek",
  },
  {
    postal_code: "18818",
    full_city_name: "Friendsville, PA",
    city_name: "Friendsville",
  },
  {
    postal_code: "13166",
    full_city_name: "Weedsport, NY",
    city_name: "Weedsport",
  },
  {
    postal_code: "67639",
    full_city_name: "Glade, KS",
    city_name: "Glade",
  },
  {
    postal_code: "57552",
    full_city_name: "Midland, SD",
    city_name: "Midland",
  },
  {
    postal_code: "52749",
    full_city_name: "Fruitland, IA",
    city_name: "Fruitland",
  },
  {
    postal_code: "76887",
    full_city_name: "Voca, TX",
    city_name: "Voca",
  },
  {
    postal_code: "51453",
    full_city_name: "Lohrville, IA",
    city_name: "Lohrville",
  },
  {
    postal_code: "64431",
    full_city_name: "Clearmont, MO",
    city_name: "Clearmont",
  },
  {
    postal_code: "41250",
    full_city_name: "Pilgrim, KY",
    city_name: "Pilgrim",
  },
  {
    postal_code: "84732",
    full_city_name: "Greenwich, UT",
    city_name: "Greenwich",
  },
  {
    postal_code: "60930",
    full_city_name: "Danforth, IL",
    city_name: "Danforth",
  },
  {
    postal_code: "98621",
    full_city_name: "Grays River, WA",
    city_name: "Grays River",
  },
  {
    postal_code: "71468",
    full_city_name: "Provencal, LA",
    city_name: "Provencal",
  },
  {
    postal_code: "04490",
    full_city_name: "Topsfield, ME",
    city_name: "Topsfield",
  },
  {
    postal_code: "15550",
    full_city_name: "Manns Choice, PA",
    city_name: "Manns Choice",
  },
  {
    postal_code: "75853",
    full_city_name: "Montalba, TX",
    city_name: "Montalba",
  },
  {
    postal_code: "12725",
    full_city_name: "Claryville, NY",
    city_name: "Claryville",
  },
  {
    postal_code: "17749",
    full_city_name: "Mc Ewensville, PA",
    city_name: "Mc Ewensville",
  },
  {
    postal_code: "87313",
    full_city_name: "Crownpoint, NM",
    city_name: "Crownpoint",
  },
  {
    postal_code: "30820",
    full_city_name: "Mitchell, GA",
    city_name: "Mitchell",
  },
  {
    postal_code: "74536",
    full_city_name: "Clayton, OK",
    city_name: "Clayton",
  },
  {
    postal_code: "38846",
    full_city_name: "Glen, MS",
    city_name: "Glen",
  },
  {
    postal_code: "25139",
    full_city_name: "Mount Carbon, WV",
    city_name: "Mount Carbon",
  },
  {
    postal_code: "68841",
    full_city_name: "Giltner, NE",
    city_name: "Giltner",
  },
  {
    postal_code: "62624",
    full_city_name: "Browning, IL",
    city_name: "Browning",
  },
  {
    postal_code: "65248",
    full_city_name: "Fayette, MO",
    city_name: "Fayette",
  },
  {
    postal_code: "52535",
    full_city_name: "Birmingham, IA",
    city_name: "Birmingham",
  },
  {
    postal_code: "17929",
    full_city_name: "Cressona, PA",
    city_name: "Cressona",
  },
  {
    postal_code: "74552",
    full_city_name: "Kinta, OK",
    city_name: "Kinta",
  },
  {
    postal_code: "84758",
    full_city_name: "Orderville, UT",
    city_name: "Orderville",
  },
  {
    postal_code: "27556",
    full_city_name: "Middleburg, NC",
    city_name: "Middleburg",
  },
  {
    postal_code: "27818",
    full_city_name: "Como, NC",
    city_name: "Como",
  },
  {
    postal_code: "98821",
    full_city_name: "Dryden, WA",
    city_name: "Dryden",
  },
  {
    postal_code: "72540",
    full_city_name: "Guion, AR",
    city_name: "Guion",
  },
  {
    postal_code: "45630",
    full_city_name: "Friendship, OH",
    city_name: "Friendship",
  },
  {
    postal_code: "85337",
    full_city_name: "Gila Bend, AZ",
    city_name: "Gila Bend",
  },
  {
    postal_code: "36925",
    full_city_name: "York, AL",
    city_name: "York",
  },
  {
    postal_code: "67757",
    full_city_name: "Selden, KS",
    city_name: "Selden",
  },
  {
    postal_code: "45851",
    full_city_name: "Haviland, OH",
    city_name: "Haviland",
  },
  {
    postal_code: "05647",
    full_city_name: "Cabot, VT",
    city_name: "Cabot",
  },
  {
    postal_code: "78389",
    full_city_name: "Skidmore, TX",
    city_name: "Skidmore",
  },
  {
    postal_code: "87317",
    full_city_name: "Gamerco, NM",
    city_name: "Gamerco",
  },
  {
    postal_code: "65634",
    full_city_name: "Cross Timbers, MO",
    city_name: "Cross Timbers",
  },
  {
    postal_code: "45686",
    full_city_name: "Vinton, OH",
    city_name: "Vinton",
  },
  {
    postal_code: "25432",
    full_city_name: "Millville, WV",
    city_name: "Millville",
  },
  {
    postal_code: "49874",
    full_city_name: "Powers, MI",
    city_name: "Powers",
  },
  {
    postal_code: "06031",
    full_city_name: "Falls Village, CT",
    city_name: "Falls Village",
  },
  {
    postal_code: "75478",
    full_city_name: "Saltillo, TX",
    city_name: "Saltillo",
  },
  {
    postal_code: "55939",
    full_city_name: "Harmony, MN",
    city_name: "Harmony",
  },
  {
    postal_code: "16024",
    full_city_name: "Callery, PA",
    city_name: "Callery",
  },
  {
    postal_code: "37731",
    full_city_name: "Eidson, TN",
    city_name: "Eidson",
  },
  {
    postal_code: "50518",
    full_city_name: "Barnum, IA",
    city_name: "Barnum",
  },
  {
    postal_code: "35755",
    full_city_name: "Langston, AL",
    city_name: "Langston",
  },
  {
    postal_code: "87820",
    full_city_name: "Aragon, NM",
    city_name: "Aragon",
  },
  {
    postal_code: "24870",
    full_city_name: "Oceana, WV",
    city_name: "Oceana",
  },
  {
    postal_code: "84711",
    full_city_name: "Annabella, UT",
    city_name: "Annabella",
  },
  {
    postal_code: "76538",
    full_city_name: "Jonesboro, TX",
    city_name: "Jonesboro",
  },
  {
    postal_code: "62374",
    full_city_name: "Tennessee, IL",
    city_name: "Tennessee",
  },
  {
    postal_code: "78584",
    full_city_name: "Roma, TX",
    city_name: "Roma",
  },
  {
    postal_code: "93623",
    full_city_name: "Fish Camp, CA",
    city_name: "Fish Camp",
  },
  {
    postal_code: "56295",
    full_city_name: "Watson, MN",
    city_name: "Watson",
  },
  {
    postal_code: "73730",
    full_city_name: "Covington, OK",
    city_name: "Covington",
  },
  {
    postal_code: "67466",
    full_city_name: "Miltonvale, KS",
    city_name: "Miltonvale",
  },
  {
    postal_code: "39094",
    full_city_name: "Lena, MS",
    city_name: "Lena",
  },
  {
    postal_code: "63950",
    full_city_name: "Lodi, MO",
    city_name: "Lodi",
  },
  {
    postal_code: "01236",
    full_city_name: "Housatonic, MA",
    city_name: "Housatonic",
  },
  {
    postal_code: "15560",
    full_city_name: "Shanksville, PA",
    city_name: "Shanksville",
  },
  {
    postal_code: "40486",
    full_city_name: "Tyner, KY",
    city_name: "Tyner",
  },
  {
    postal_code: "71028",
    full_city_name: "Gibsland, LA",
    city_name: "Gibsland",
  },
  {
    postal_code: "28456",
    full_city_name: "Riegelwood, NC",
    city_name: "Riegelwood",
  },
  {
    postal_code: "59054",
    full_city_name: "Melstone, MT",
    city_name: "Melstone",
  },
  {
    postal_code: "37140",
    full_city_name: "Only, TN",
    city_name: "Only",
  },
  {
    postal_code: "88120",
    full_city_name: "Grady, NM",
    city_name: "Grady",
  },
  {
    postal_code: "76848",
    full_city_name: "Hext, TX",
    city_name: "Hext",
  },
  {
    postal_code: "12743",
    full_city_name: "Highland Lake, NY",
    city_name: "Highland Lake",
  },
  {
    postal_code: "67361",
    full_city_name: "Sedan, KS",
    city_name: "Sedan",
  },
  {
    postal_code: "72458",
    full_city_name: "Powhatan, AR",
    city_name: "Powhatan",
  },
  {
    postal_code: "49654",
    full_city_name: "Leland, MI",
    city_name: "Leland",
  },
  {
    postal_code: "13345",
    full_city_name: "Greig, NY",
    city_name: "Greig",
  },
  {
    postal_code: "66407",
    full_city_name: "Belvue, KS",
    city_name: "Belvue",
  },
  {
    postal_code: "39866",
    full_city_name: "Morgan, GA",
    city_name: "Morgan",
  },
  {
    postal_code: "64422",
    full_city_name: "Amity, MO",
    city_name: "Amity",
  },
  {
    postal_code: "71670",
    full_city_name: "Tillar, AR",
    city_name: "Tillar",
  },
  {
    postal_code: "39840",
    full_city_name: "Cuthbert, GA",
    city_name: "Cuthbert",
  },
  {
    postal_code: "97057",
    full_city_name: "Shaniko, OR",
    city_name: "Shaniko",
  },
  {
    postal_code: "12862",
    full_city_name: "Riparius, NY",
    city_name: "Riparius",
  },
  {
    postal_code: "41159",
    full_city_name: "Martha, KY",
    city_name: "Martha",
  },
  {
    postal_code: "63443",
    full_city_name: "Hunnewell, MO",
    city_name: "Hunnewell",
  },
  {
    postal_code: "29493",
    full_city_name: "Williams, SC",
    city_name: "Williams",
  },
  {
    postal_code: "18220",
    full_city_name: "Delano, PA",
    city_name: "Delano",
  },
  {
    postal_code: "76474",
    full_city_name: "Sidney, TX",
    city_name: "Sidney",
  },
  {
    postal_code: "68966",
    full_city_name: "Orleans, NE",
    city_name: "Orleans",
  },
  {
    postal_code: "12841",
    full_city_name: "Huletts Landing, NY",
    city_name: "Huletts Landing",
  },
  {
    postal_code: "67749",
    full_city_name: "Oberlin, KS",
    city_name: "Oberlin",
  },
  {
    postal_code: "02543",
    full_city_name: "Woods Hole, MA",
    city_name: "Woods Hole",
  },
  {
    postal_code: "36751",
    full_city_name: "Lower Peach Tree, AL",
    city_name: "Lower Peach Tree",
  },
  {
    postal_code: "42370",
    full_city_name: "Rosine, KY",
    city_name: "Rosine",
  },
  {
    postal_code: "27508",
    full_city_name: "Bunn, NC",
    city_name: "Bunn",
  },
  {
    postal_code: "23844",
    full_city_name: "Drewryville, VA",
    city_name: "Drewryville",
  },
  {
    postal_code: "99149",
    full_city_name: "Malden, WA",
    city_name: "Malden",
  },
  {
    postal_code: "53070",
    full_city_name: "Oostburg, WI",
    city_name: "Oostburg",
  },
  {
    postal_code: "66079",
    full_city_name: "Rantoul, KS",
    city_name: "Rantoul",
  },
  {
    postal_code: "30803",
    full_city_name: "Avera, GA",
    city_name: "Avera",
  },
  {
    postal_code: "95573",
    full_city_name: "Willow Creek, CA",
    city_name: "Willow Creek",
  },
  {
    postal_code: "79529",
    full_city_name: "Knox City, TX",
    city_name: "Knox City",
  },
  {
    postal_code: "15353",
    full_city_name: "Nineveh, PA",
    city_name: "Nineveh",
  },
  {
    postal_code: "52033",
    full_city_name: "Cascade, IA",
    city_name: "Cascade",
  },
  {
    postal_code: "38575",
    full_city_name: "Moss, TN",
    city_name: "Moss",
  },
  {
    postal_code: "62378",
    full_city_name: "Versailles, IL",
    city_name: "Versailles",
  },
  {
    postal_code: "62643",
    full_city_name: "Hartsburg, IL",
    city_name: "Hartsburg",
  },
  {
    postal_code: "23436",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "53235",
    full_city_name: "Saint Francis, WI",
    city_name: "Saint Francis",
  },
  {
    postal_code: "03449",
    full_city_name: "Hancock, NH",
    city_name: "Hancock",
  },
  {
    postal_code: "65046",
    full_city_name: "Jamestown, MO",
    city_name: "Jamestown",
  },
  {
    postal_code: "85605",
    full_city_name: "Bowie, AZ",
    city_name: "Bowie",
  },
  {
    postal_code: "57315",
    full_city_name: "Avon, SD",
    city_name: "Avon",
  },
  {
    postal_code: "59263",
    full_city_name: "Scobey, MT",
    city_name: "Scobey",
  },
  {
    postal_code: "71008",
    full_city_name: "Bienville, LA",
    city_name: "Bienville",
  },
  {
    postal_code: "25253",
    full_city_name: "Letart, WV",
    city_name: "Letart",
  },
  {
    postal_code: "31085",
    full_city_name: "Shady Dale, GA",
    city_name: "Shady Dale",
  },
  {
    postal_code: "12115",
    full_city_name: "Malden Bridge, NY",
    city_name: "Malden Bridge",
  },
  {
    postal_code: "64724",
    full_city_name: "Appleton City, MO",
    city_name: "Appleton City",
  },
  {
    postal_code: "73041",
    full_city_name: "Gotebo, OK",
    city_name: "Gotebo",
  },
  {
    postal_code: "64625",
    full_city_name: "Breckenridge, MO",
    city_name: "Breckenridge",
  },
  {
    postal_code: "74569",
    full_city_name: "Stringtown, OK",
    city_name: "Stringtown",
  },
  {
    postal_code: "67640",
    full_city_name: "Gorham, KS",
    city_name: "Gorham",
  },
  {
    postal_code: "57059",
    full_city_name: "Scotland, SD",
    city_name: "Scotland",
  },
  {
    postal_code: "15329",
    full_city_name: "Prosperity, PA",
    city_name: "Prosperity",
  },
  {
    postal_code: "68018",
    full_city_name: "Colon, NE",
    city_name: "Colon",
  },
  {
    postal_code: "43345",
    full_city_name: "Ridgeway, OH",
    city_name: "Ridgeway",
  },
  {
    postal_code: "10973",
    full_city_name: "Slate Hill, NY",
    city_name: "Slate Hill",
  },
  {
    postal_code: "13342",
    full_city_name: "Garrattsville, NY",
    city_name: "Garrattsville",
  },
  {
    postal_code: "74878",
    full_city_name: "Wanette, OK",
    city_name: "Wanette",
  },
  {
    postal_code: "43022",
    full_city_name: "Gambier, OH",
    city_name: "Gambier",
  },
  {
    postal_code: "28436",
    full_city_name: "Delco, NC",
    city_name: "Delco",
  },
  {
    postal_code: "16034",
    full_city_name: "Fenelton, PA",
    city_name: "Fenelton",
  },
  {
    postal_code: "64643",
    full_city_name: "Hale, MO",
    city_name: "Hale",
  },
  {
    postal_code: "43101",
    full_city_name: "Adelphi, OH",
    city_name: "Adelphi",
  },
  {
    postal_code: "58575",
    full_city_name: "Turtle Lake, ND",
    city_name: "Turtle Lake",
  },
  {
    postal_code: "29453",
    full_city_name: "Jamestown, SC",
    city_name: "Jamestown",
  },
  {
    postal_code: "96006",
    full_city_name: "Adin, CA",
    city_name: "Adin",
  },
  {
    postal_code: "36518",
    full_city_name: "Chatom, AL",
    city_name: "Chatom",
  },
  {
    postal_code: "63620",
    full_city_name: "Annapolis, MO",
    city_name: "Annapolis",
  },
  {
    postal_code: "72063",
    full_city_name: "Hattieville, AR",
    city_name: "Hattieville",
  },
  {
    postal_code: "23395",
    full_city_name: "Horntown, VA",
    city_name: "Horntown",
  },
  {
    postal_code: "68627",
    full_city_name: "Cedar Rapids, NE",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "08825",
    full_city_name: "Frenchtown, NJ",
    city_name: "Frenchtown",
  },
  {
    postal_code: "64762",
    full_city_name: "Liberal, MO",
    city_name: "Liberal",
  },
  {
    postal_code: "31019",
    full_city_name: "Dexter, GA",
    city_name: "Dexter",
  },
  {
    postal_code: "58081",
    full_city_name: "Wyndmere, ND",
    city_name: "Wyndmere",
  },
  {
    postal_code: "42131",
    full_city_name: "Etoile, KY",
    city_name: "Etoile",
  },
  {
    postal_code: "73459",
    full_city_name: "Thackerville, OK",
    city_name: "Thackerville",
  },
  {
    postal_code: "43976",
    full_city_name: "Hopedale, OH",
    city_name: "Hopedale",
  },
  {
    postal_code: "80479",
    full_city_name: "Toponas, CO",
    city_name: "Toponas",
  },
  {
    postal_code: "66608",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "47230",
    full_city_name: "Deputy, IN",
    city_name: "Deputy",
  },
  {
    postal_code: "04564",
    full_city_name: "Round Pond, ME",
    city_name: "Round Pond",
  },
  {
    postal_code: "28448",
    full_city_name: "Kelly, NC",
    city_name: "Kelly",
  },
  {
    postal_code: "47467",
    full_city_name: "Tunnelton, IN",
    city_name: "Tunnelton",
  },
  {
    postal_code: "31642",
    full_city_name: "Pearson, GA",
    city_name: "Pearson",
  },
  {
    postal_code: "24578",
    full_city_name: "Natural Bridge, VA",
    city_name: "Natural Bridge",
  },
  {
    postal_code: "56584",
    full_city_name: "Twin Valley, MN",
    city_name: "Twin Valley",
  },
  {
    postal_code: "41859",
    full_city_name: "Dema, KY",
    city_name: "Dema",
  },
  {
    postal_code: "61235",
    full_city_name: "Atkinson, IL",
    city_name: "Atkinson",
  },
  {
    postal_code: "68981",
    full_city_name: "Upland, NE",
    city_name: "Upland",
  },
  {
    postal_code: "59315",
    full_city_name: "Bloomfield, MT",
    city_name: "Bloomfield",
  },
  {
    postal_code: "78839",
    full_city_name: "Crystal City, TX",
    city_name: "Crystal City",
  },
  {
    postal_code: "58038",
    full_city_name: "Grandin, ND",
    city_name: "Grandin",
  },
  {
    postal_code: "39330",
    full_city_name: "Enterprise, MS",
    city_name: "Enterprise",
  },
  {
    postal_code: "58002",
    full_city_name: "Absaraka, ND",
    city_name: "Absaraka",
  },
  {
    postal_code: "38927",
    full_city_name: "Enid, MS",
    city_name: "Enid",
  },
  {
    postal_code: "67049",
    full_city_name: "Freeport, KS",
    city_name: "Freeport",
  },
  {
    postal_code: "41743",
    full_city_name: "Fisty, KY",
    city_name: "Fisty",
  },
  {
    postal_code: "53036",
    full_city_name: "Ixonia, WI",
    city_name: "Ixonia",
  },
  {
    postal_code: "60910",
    full_city_name: "Aroma Park, IL",
    city_name: "Aroma Park",
  },
  {
    postal_code: "65457",
    full_city_name: "Devils Elbow, MO",
    city_name: "Devils Elbow",
  },
  {
    postal_code: "39358",
    full_city_name: "Scooba, MS",
    city_name: "Scooba",
  },
  {
    postal_code: "36046",
    full_city_name: "Lapine, AL",
    city_name: "Lapine",
  },
  {
    postal_code: "55758",
    full_city_name: "Kinney, MN",
    city_name: "Kinney",
  },
  {
    postal_code: "15737",
    full_city_name: "Elmora, PA",
    city_name: "Elmora",
  },
  {
    postal_code: "70788",
    full_city_name: "White Castle, LA",
    city_name: "White Castle",
  },
  {
    postal_code: "29471",
    full_city_name: "Reevesville, SC",
    city_name: "Reevesville",
  },
  {
    postal_code: "87041",
    full_city_name: "Pena Blanca, NM",
    city_name: "Pena Blanca",
  },
  {
    postal_code: "39826",
    full_city_name: "Bronwood, GA",
    city_name: "Bronwood",
  },
  {
    postal_code: "49853",
    full_city_name: "Mc Millan, MI",
    city_name: "Mc Millan",
  },
  {
    postal_code: "15475",
    full_city_name: "Republic, PA",
    city_name: "Republic",
  },
  {
    postal_code: "81121",
    full_city_name: "Arboles, CO",
    city_name: "Arboles",
  },
  {
    postal_code: "12838",
    full_city_name: "Hartford, NY",
    city_name: "Hartford",
  },
  {
    postal_code: "25209",
    full_city_name: "Whitesville, WV",
    city_name: "Whitesville",
  },
  {
    postal_code: "04740",
    full_city_name: "Easton, ME",
    city_name: "Easton",
  },
  {
    postal_code: "38619",
    full_city_name: "Como, MS",
    city_name: "Como",
  },
  {
    postal_code: "53540",
    full_city_name: "Gotham, WI",
    city_name: "Gotham",
  },
  {
    postal_code: "66008",
    full_city_name: "Bendena, KS",
    city_name: "Bendena",
  },
  {
    postal_code: "13806",
    full_city_name: "Meridale, NY",
    city_name: "Meridale",
  },
  {
    postal_code: "27046",
    full_city_name: "Sandy Ridge, NC",
    city_name: "Sandy Ridge",
  },
  {
    postal_code: "96762",
    full_city_name: "Laie, HI",
    city_name: "Laie",
  },
  {
    postal_code: "14777",
    full_city_name: "Rushford, NY",
    city_name: "Rushford",
  },
  {
    postal_code: "20680",
    full_city_name: "Ridge, MD",
    city_name: "Ridge",
  },
  {
    postal_code: "56212",
    full_city_name: "Bellingham, MN",
    city_name: "Bellingham",
  },
  {
    postal_code: "59030",
    full_city_name: "Gardiner, MT",
    city_name: "Gardiner",
  },
  {
    postal_code: "72175",
    full_city_name: "Wabbaseka, AR",
    city_name: "Wabbaseka",
  },
  {
    postal_code: "73444",
    full_city_name: "Hennepin, OK",
    city_name: "Hennepin",
  },
  {
    postal_code: "40868",
    full_city_name: "Stinnett, KY",
    city_name: "Stinnett",
  },
  {
    postal_code: "88135",
    full_city_name: "Texico, NM",
    city_name: "Texico",
  },
  {
    postal_code: "17827",
    full_city_name: "Freeburg, PA",
    city_name: "Freeburg",
  },
  {
    postal_code: "49288",
    full_city_name: "Waldron, MI",
    city_name: "Waldron",
  },
  {
    postal_code: "62045",
    full_city_name: "Hamburg, IL",
    city_name: "Hamburg",
  },
  {
    postal_code: "50122",
    full_city_name: "Hubbard, IA",
    city_name: "Hubbard",
  },
  {
    postal_code: "04768",
    full_city_name: "Portage, ME",
    city_name: "Portage",
  },
  {
    postal_code: "56523",
    full_city_name: "Climax, MN",
    city_name: "Climax",
  },
  {
    postal_code: "71326",
    full_city_name: "Clayton, LA",
    city_name: "Clayton",
  },
  {
    postal_code: "98381",
    full_city_name: "Sekiu, WA",
    city_name: "Sekiu",
  },
  {
    postal_code: "38940",
    full_city_name: "Holcomb, MS",
    city_name: "Holcomb",
  },
  {
    postal_code: "72421",
    full_city_name: "Cash, AR",
    city_name: "Cash",
  },
  {
    postal_code: "63534",
    full_city_name: "Callao, MO",
    city_name: "Callao",
  },
  {
    postal_code: "66411",
    full_city_name: "Blue Rapids, KS",
    city_name: "Blue Rapids",
  },
  {
    postal_code: "21650",
    full_city_name: "Massey, MD",
    city_name: "Massey",
  },
  {
    postal_code: "39176",
    full_city_name: "Vaiden, MS",
    city_name: "Vaiden",
  },
  {
    postal_code: "67519",
    full_city_name: "Belpre, KS",
    city_name: "Belpre",
  },
  {
    postal_code: "52078",
    full_city_name: "Worthington, IA",
    city_name: "Worthington",
  },
  {
    postal_code: "42361",
    full_city_name: "Olaton, KY",
    city_name: "Olaton",
  },
  {
    postal_code: "16222",
    full_city_name: "Dayton, PA",
    city_name: "Dayton",
  },
  {
    postal_code: "62036",
    full_city_name: "Golden Eagle, IL",
    city_name: "Golden Eagle",
  },
  {
    postal_code: "37132",
    full_city_name: "Murfreesboro, TN",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "21723",
    full_city_name: "Cooksville, MD",
    city_name: "Cooksville",
  },
  {
    postal_code: "16218",
    full_city_name: "Cowansville, PA",
    city_name: "Cowansville",
  },
  {
    postal_code: "27826",
    full_city_name: "Fairfield, NC",
    city_name: "Fairfield",
  },
  {
    postal_code: "83632",
    full_city_name: "Indian Valley, ID",
    city_name: "Indian Valley",
  },
  {
    postal_code: "49963",
    full_city_name: "South Range, MI",
    city_name: "South Range",
  },
  {
    postal_code: "78843",
    full_city_name: "Laughlin Afb, TX",
    city_name: "Laughlin Afb",
  },
  {
    postal_code: "67473",
    full_city_name: "Osborne, KS",
    city_name: "Osborne",
  },
  {
    postal_code: "14842",
    full_city_name: "Himrod, NY",
    city_name: "Himrod",
  },
  {
    postal_code: "41843",
    full_city_name: "Pine Top, KY",
    city_name: "Pine Top",
  },
  {
    postal_code: "41606",
    full_city_name: "Bevinsville, KY",
    city_name: "Bevinsville",
  },
  {
    postal_code: "88265",
    full_city_name: "Monument, NM",
    city_name: "Monument",
  },
  {
    postal_code: "45727",
    full_city_name: "Dexter City, OH",
    city_name: "Dexter City",
  },
  {
    postal_code: "58721",
    full_city_name: "Bowbells, ND",
    city_name: "Bowbells",
  },
  {
    postal_code: "79255",
    full_city_name: "Quitaque, TX",
    city_name: "Quitaque",
  },
  {
    postal_code: "69141",
    full_city_name: "Gurley, NE",
    city_name: "Gurley",
  },
  {
    postal_code: "51638",
    full_city_name: "Essex, IA",
    city_name: "Essex",
  },
  {
    postal_code: "67444",
    full_city_name: "Geneseo, KS",
    city_name: "Geneseo",
  },
  {
    postal_code: "86022",
    full_city_name: "Fredonia, AZ",
    city_name: "Fredonia",
  },
  {
    postal_code: "41666",
    full_city_name: "Wayland, KY",
    city_name: "Wayland",
  },
  {
    postal_code: "57750",
    full_city_name: "Interior, SD",
    city_name: "Interior",
  },
  {
    postal_code: "59212",
    full_city_name: "Bainville, MT",
    city_name: "Bainville",
  },
  {
    postal_code: "29741",
    full_city_name: "Ruby, SC",
    city_name: "Ruby",
  },
  {
    postal_code: "28392",
    full_city_name: "Tar Heel, NC",
    city_name: "Tar Heel",
  },
  {
    postal_code: "98859",
    full_city_name: "Wauconda, WA",
    city_name: "Wauconda",
  },
  {
    postal_code: "62875",
    full_city_name: "Patoka, IL",
    city_name: "Patoka",
  },
  {
    postal_code: "66751",
    full_city_name: "La Harpe, KS",
    city_name: "La Harpe",
  },
  {
    postal_code: "68637",
    full_city_name: "Ericson, NE",
    city_name: "Ericson",
  },
  {
    postal_code: "56758",
    full_city_name: "Strandquist, MN",
    city_name: "Strandquist",
  },
  {
    postal_code: "26146",
    full_city_name: "Friendly, WV",
    city_name: "Friendly",
  },
  {
    postal_code: "14874",
    full_city_name: "Pulteney, NY",
    city_name: "Pulteney",
  },
  {
    postal_code: "69028",
    full_city_name: "Eustis, NE",
    city_name: "Eustis",
  },
  {
    postal_code: "61327",
    full_city_name: "Hennepin, IL",
    city_name: "Hennepin",
  },
  {
    postal_code: "74442",
    full_city_name: "Indianola, OK",
    city_name: "Indianola",
  },
  {
    postal_code: "58262",
    full_city_name: "Mountain, ND",
    city_name: "Mountain",
  },
  {
    postal_code: "65630",
    full_city_name: "Chestnutridge, MO",
    city_name: "Chestnutridge",
  },
  {
    postal_code: "78027",
    full_city_name: "Kendalia, TX",
    city_name: "Kendalia",
  },
  {
    postal_code: "29107",
    full_city_name: "Neeses, SC",
    city_name: "Neeses",
  },
  {
    postal_code: "99144",
    full_city_name: "Lamona, WA",
    city_name: "Lamona",
  },
  {
    postal_code: "15615",
    full_city_name: "Ardara, PA",
    city_name: "Ardara",
  },
  {
    postal_code: "65755",
    full_city_name: "Squires, MO",
    city_name: "Squires",
  },
  {
    postal_code: "51548",
    full_city_name: "Mc Clelland, IA",
    city_name: "Mc Clelland",
  },
  {
    postal_code: "71046",
    full_city_name: "Keatchie, LA",
    city_name: "Keatchie",
  },
  {
    postal_code: "63446",
    full_city_name: "Knox City, MO",
    city_name: "Knox City",
  },
  {
    postal_code: "16131",
    full_city_name: "Hartstown, PA",
    city_name: "Hartstown",
  },
  {
    postal_code: "70644",
    full_city_name: "Grant, LA",
    city_name: "Grant",
  },
  {
    postal_code: "58330",
    full_city_name: "Edmore, ND",
    city_name: "Edmore",
  },
  {
    postal_code: "16680",
    full_city_name: "Smithmill, PA",
    city_name: "Smithmill",
  },
  {
    postal_code: "46711",
    full_city_name: "Berne, IN",
    city_name: "Berne",
  },
  {
    postal_code: "39846",
    full_city_name: "Edison, GA",
    city_name: "Edison",
  },
  {
    postal_code: "59221",
    full_city_name: "Fairview, MT",
    city_name: "Fairview",
  },
  {
    postal_code: "58736",
    full_city_name: "Drake, ND",
    city_name: "Drake",
  },
  {
    postal_code: "95607",
    full_city_name: "Capay, CA",
    city_name: "Capay",
  },
  {
    postal_code: "43837",
    full_city_name: "Port Washington, OH",
    city_name: "Port Washington",
  },
  {
    postal_code: "88047",
    full_city_name: "Mesilla Park, NM",
    city_name: "Mesilla Park",
  },
  {
    postal_code: "14708",
    full_city_name: "Alma, NY",
    city_name: "Alma",
  },
  {
    postal_code: "97752",
    full_city_name: "Post, OR",
    city_name: "Post",
  },
  {
    postal_code: "51570",
    full_city_name: "Shelby, IA",
    city_name: "Shelby",
  },
  {
    postal_code: "56074",
    full_city_name: "Nicollet, MN",
    city_name: "Nicollet",
  },
  {
    postal_code: "62886",
    full_city_name: "Sims, IL",
    city_name: "Sims",
  },
  {
    postal_code: "58795",
    full_city_name: "Wildrose, ND",
    city_name: "Wildrose",
  },
  {
    postal_code: "95612",
    full_city_name: "Clarksburg, CA",
    city_name: "Clarksburg",
  },
  {
    postal_code: "17224",
    full_city_name: "Fort Loudon, PA",
    city_name: "Fort Loudon",
  },
  {
    postal_code: "14714",
    full_city_name: "Black Creek, NY",
    city_name: "Black Creek",
  },
  {
    postal_code: "51648",
    full_city_name: "Percival, IA",
    city_name: "Percival",
  },
  {
    postal_code: "05732",
    full_city_name: "Bomoseen, VT",
    city_name: "Bomoseen",
  },
  {
    postal_code: "68448",
    full_city_name: "Talmage, NE",
    city_name: "Talmage",
  },
  {
    postal_code: "95387",
    full_city_name: "Westley, CA",
    city_name: "Westley",
  },
  {
    postal_code: "24458",
    full_city_name: "Mc Dowell, VA",
    city_name: "Mc Dowell",
  },
  {
    postal_code: "87063",
    full_city_name: "Willard, NM",
    city_name: "Willard",
  },
  {
    postal_code: "15938",
    full_city_name: "Lilly, PA",
    city_name: "Lilly",
  },
  {
    postal_code: "77597",
    full_city_name: "Wallisville, TX",
    city_name: "Wallisville",
  },
  {
    postal_code: "07933",
    full_city_name: "Gillette, NJ",
    city_name: "Gillette",
  },
  {
    postal_code: "18462",
    full_city_name: "Starrucca, PA",
    city_name: "Starrucca",
  },
  {
    postal_code: "83525",
    full_city_name: "Elk City, ID",
    city_name: "Elk City",
  },
  {
    postal_code: "80457",
    full_city_name: "Kittredge, CO",
    city_name: "Kittredge",
  },
  {
    postal_code: "52074",
    full_city_name: "Spragueville, IA",
    city_name: "Spragueville",
  },
  {
    postal_code: "16858",
    full_city_name: "Morrisdale, PA",
    city_name: "Morrisdale",
  },
  {
    postal_code: "98582",
    full_city_name: "Salkum, WA",
    city_name: "Salkum",
  },
  {
    postal_code: "29080",
    full_city_name: "Lynchburg, SC",
    city_name: "Lynchburg",
  },
  {
    postal_code: "31823",
    full_city_name: "Pine Mountain Valley, GA",
    city_name: "Pine Mountain Valley",
  },
  {
    postal_code: "01368",
    full_city_name: "Royalston, MA",
    city_name: "Royalston",
  },
  {
    postal_code: "40374",
    full_city_name: "Sharpsburg, KY",
    city_name: "Sharpsburg",
  },
  {
    postal_code: "71661",
    full_city_name: "Parkdale, AR",
    city_name: "Parkdale",
  },
  {
    postal_code: "70343",
    full_city_name: "Bourg, LA",
    city_name: "Bourg",
  },
  {
    postal_code: "95546",
    full_city_name: "Hoopa, CA",
    city_name: "Hoopa",
  },
  {
    postal_code: "72126",
    full_city_name: "Perryville, AR",
    city_name: "Perryville",
  },
  {
    postal_code: "44619",
    full_city_name: "Damascus, OH",
    city_name: "Damascus",
  },
  {
    postal_code: "41548",
    full_city_name: "Mouthcard, KY",
    city_name: "Mouthcard",
  },
  {
    postal_code: "98585",
    full_city_name: "Silver Creek, WA",
    city_name: "Silver Creek",
  },
  {
    postal_code: "36879",
    full_city_name: "Waverly, AL",
    city_name: "Waverly",
  },
  {
    postal_code: "57438",
    full_city_name: "Faulkton, SD",
    city_name: "Faulkton",
  },
  {
    postal_code: "64689",
    full_city_name: "Winston, MO",
    city_name: "Winston",
  },
  {
    postal_code: "03890",
    full_city_name: "West Ossipee, NH",
    city_name: "West Ossipee",
  },
  {
    postal_code: "72379",
    full_city_name: "Snow Lake, AR",
    city_name: "Snow Lake",
  },
  {
    postal_code: "74028",
    full_city_name: "Depew, OK",
    city_name: "Depew",
  },
  {
    postal_code: "54437",
    full_city_name: "Greenwood, WI",
    city_name: "Greenwood",
  },
  {
    postal_code: "62842",
    full_city_name: "Geff, IL",
    city_name: "Geff",
  },
  {
    postal_code: "76631",
    full_city_name: "Bynum, TX",
    city_name: "Bynum",
  },
  {
    postal_code: "59038",
    full_city_name: "Hysham, MT",
    city_name: "Hysham",
  },
  {
    postal_code: "89418",
    full_city_name: "Imlay, NV",
    city_name: "Imlay",
  },
  {
    postal_code: "16923",
    full_city_name: "Genesee, PA",
    city_name: "Genesee",
  },
  {
    postal_code: "17581",
    full_city_name: "Terre Hill, PA",
    city_name: "Terre Hill",
  },
  {
    postal_code: "26563",
    full_city_name: "Carolina, WV",
    city_name: "Carolina",
  },
  {
    postal_code: "17776",
    full_city_name: "Waterville, PA",
    city_name: "Waterville",
  },
  {
    postal_code: "74734",
    full_city_name: "Eagletown, OK",
    city_name: "Eagletown",
  },
  {
    postal_code: "21664",
    full_city_name: "Secretary, MD",
    city_name: "Secretary",
  },
  {
    postal_code: "66779",
    full_city_name: "Uniontown, KS",
    city_name: "Uniontown",
  },
  {
    postal_code: "76556",
    full_city_name: "Milano, TX",
    city_name: "Milano",
  },
  {
    postal_code: "72454",
    full_city_name: "Piggott, AR",
    city_name: "Piggott",
  },
  {
    postal_code: "58769",
    full_city_name: "Palermo, ND",
    city_name: "Palermo",
  },
  {
    postal_code: "57544",
    full_city_name: "Kennebec, SD",
    city_name: "Kennebec",
  },
  {
    postal_code: "58249",
    full_city_name: "Langdon, ND",
    city_name: "Langdon",
  },
  {
    postal_code: "59222",
    full_city_name: "Flaxville, MT",
    city_name: "Flaxville",
  },
  {
    postal_code: "59762",
    full_city_name: "Wise River, MT",
    city_name: "Wise River",
  },
  {
    postal_code: "40806",
    full_city_name: "Baxter, KY",
    city_name: "Baxter",
  },
  {
    postal_code: "52660",
    full_city_name: "Yarmouth, IA",
    city_name: "Yarmouth",
  },
  {
    postal_code: "12742",
    full_city_name: "Harris, NY",
    city_name: "Harris",
  },
  {
    postal_code: "23866",
    full_city_name: "Ivor, VA",
    city_name: "Ivor",
  },
  {
    postal_code: "62418",
    full_city_name: "Brownstown, IL",
    city_name: "Brownstown",
  },
  {
    postal_code: "56080",
    full_city_name: "Saint Clair, MN",
    city_name: "Saint Clair",
  },
  {
    postal_code: "95226",
    full_city_name: "Campo Seco, CA",
    city_name: "Campo Seco",
  },
  {
    postal_code: "50608",
    full_city_name: "Austinville, IA",
    city_name: "Austinville",
  },
  {
    postal_code: "27849",
    full_city_name: "Lewiston Woodville, NC",
    city_name: "Lewiston Woodville",
  },
  {
    postal_code: "69337",
    full_city_name: "Chadron, NE",
    city_name: "Chadron",
  },
  {
    postal_code: "78830",
    full_city_name: "Big Wells, TX",
    city_name: "Big Wells",
  },
  {
    postal_code: "99151",
    full_city_name: "Marcus, WA",
    city_name: "Marcus",
  },
  {
    postal_code: "85540",
    full_city_name: "Morenci, AZ",
    city_name: "Morenci",
  },
  {
    postal_code: "18460",
    full_city_name: "South Sterling, PA",
    city_name: "South Sterling",
  },
  {
    postal_code: "71479",
    full_city_name: "Tullos, LA",
    city_name: "Tullos",
  },
  {
    postal_code: "42214",
    full_city_name: "Center, KY",
    city_name: "Center",
  },
  {
    postal_code: "67131",
    full_city_name: "Rock, KS",
    city_name: "Rock",
  },
  {
    postal_code: "99674",
    full_city_name: "Sutton, AK",
    city_name: "Sutton",
  },
  {
    postal_code: "71740",
    full_city_name: "Emerson, AR",
    city_name: "Emerson",
  },
  {
    postal_code: "65603",
    full_city_name: "Arcola, MO",
    city_name: "Arcola",
  },
  {
    postal_code: "25035",
    full_city_name: "Cabin Creek, WV",
    city_name: "Cabin Creek",
  },
  {
    postal_code: "67861",
    full_city_name: "Leoti, KS",
    city_name: "Leoti",
  },
  {
    postal_code: "57219",
    full_city_name: "Bristol, SD",
    city_name: "Bristol",
  },
  {
    postal_code: "66870",
    full_city_name: "Virgil, KS",
    city_name: "Virgil",
  },
  {
    postal_code: "46175",
    full_city_name: "Russellville, IN",
    city_name: "Russellville",
  },
  {
    postal_code: "17572",
    full_city_name: "Ronks, PA",
    city_name: "Ronks",
  },
  {
    postal_code: "42784",
    full_city_name: "Upton, KY",
    city_name: "Upton",
  },
  {
    postal_code: "72005",
    full_city_name: "Amagon, AR",
    city_name: "Amagon",
  },
  {
    postal_code: "49863",
    full_city_name: "Nadeau, MI",
    city_name: "Nadeau",
  },
  {
    postal_code: "68040",
    full_city_name: "Malmo, NE",
    city_name: "Malmo",
  },
  {
    postal_code: "23963",
    full_city_name: "Red House, VA",
    city_name: "Red House",
  },
  {
    postal_code: "58260",
    full_city_name: "Milton, ND",
    city_name: "Milton",
  },
  {
    postal_code: "42516",
    full_city_name: "Bethelridge, KY",
    city_name: "Bethelridge",
  },
  {
    postal_code: "35470",
    full_city_name: "Livingston, AL",
    city_name: "Livingston",
  },
  {
    postal_code: "65720",
    full_city_name: "Oldfield, MO",
    city_name: "Oldfield",
  },
  {
    postal_code: "75861",
    full_city_name: "Tennessee Colony, TX",
    city_name: "Tennessee Colony",
  },
  {
    postal_code: "30555",
    full_city_name: "Mc Caysville, GA",
    city_name: "Mc Caysville",
  },
  {
    postal_code: "56033",
    full_city_name: "Frost, MN",
    city_name: "Frost",
  },
  {
    postal_code: "51541",
    full_city_name: "Henderson, IA",
    city_name: "Henderson",
  },
  {
    postal_code: "52206",
    full_city_name: "Atkins, IA",
    city_name: "Atkins",
  },
  {
    postal_code: "61519",
    full_city_name: "Bryant, IL",
    city_name: "Bryant",
  },
  {
    postal_code: "57036",
    full_city_name: "Hurley, SD",
    city_name: "Hurley",
  },
  {
    postal_code: "52255",
    full_city_name: "Lowden, IA",
    city_name: "Lowden",
  },
  {
    postal_code: "04492",
    full_city_name: "Waite, ME",
    city_name: "Waite",
  },
  {
    postal_code: "56627",
    full_city_name: "Big Falls, MN",
    city_name: "Big Falls",
  },
  {
    postal_code: "57332",
    full_city_name: "Emery, SD",
    city_name: "Emery",
  },
  {
    postal_code: "21824",
    full_city_name: "Ewell, MD",
    city_name: "Ewell",
  },
  {
    postal_code: "68023",
    full_city_name: "Fort Calhoun, NE",
    city_name: "Fort Calhoun",
  },
  {
    postal_code: "84773",
    full_city_name: "Teasdale, UT",
    city_name: "Teasdale",
  },
  {
    postal_code: "88038",
    full_city_name: "Gila, NM",
    city_name: "Gila",
  },
  {
    postal_code: "10919",
    full_city_name: "Circleville, NY",
    city_name: "Circleville",
  },
  {
    postal_code: "28707",
    full_city_name: "Balsam, NC",
    city_name: "Balsam",
  },
  {
    postal_code: "67867",
    full_city_name: "Montezuma, KS",
    city_name: "Montezuma",
  },
  {
    postal_code: "30455",
    full_city_name: "Rocky Ford, GA",
    city_name: "Rocky Ford",
  },
  {
    postal_code: "57235",
    full_city_name: "Florence, SD",
    city_name: "Florence",
  },
  {
    postal_code: "36089",
    full_city_name: "Union Springs, AL",
    city_name: "Union Springs",
  },
  {
    postal_code: "41640",
    full_city_name: "Hueysville, KY",
    city_name: "Hueysville",
  },
  {
    postal_code: "88112",
    full_city_name: "Broadview, NM",
    city_name: "Broadview",
  },
  {
    postal_code: "58425",
    full_city_name: "Cooperstown, ND",
    city_name: "Cooperstown",
  },
  {
    postal_code: "56034",
    full_city_name: "Garden City, MN",
    city_name: "Garden City",
  },
  {
    postal_code: "50222",
    full_city_name: "Peru, IA",
    city_name: "Peru",
  },
  {
    postal_code: "76842",
    full_city_name: "Fredonia, TX",
    city_name: "Fredonia",
  },
  {
    postal_code: "72686",
    full_city_name: "Witts Springs, AR",
    city_name: "Witts Springs",
  },
  {
    postal_code: "64164",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "15725",
    full_city_name: "Clarksburg, PA",
    city_name: "Clarksburg",
  },
  {
    postal_code: "63622",
    full_city_name: "Belgrade, MO",
    city_name: "Belgrade",
  },
  {
    postal_code: "81064",
    full_city_name: "Pritchett, CO",
    city_name: "Pritchett",
  },
  {
    postal_code: "68318",
    full_city_name: "Blue Springs, NE",
    city_name: "Blue Springs",
  },
  {
    postal_code: "63952",
    full_city_name: "Mill Spring, MO",
    city_name: "Mill Spring",
  },
  {
    postal_code: "98614",
    full_city_name: "Chinook, WA",
    city_name: "Chinook",
  },
  {
    postal_code: "21676",
    full_city_name: "Wittman, MD",
    city_name: "Wittman",
  },
  {
    postal_code: "38780",
    full_city_name: "Wayside, MS",
    city_name: "Wayside",
  },
  {
    postal_code: "68461",
    full_city_name: "Walton, NE",
    city_name: "Walton",
  },
  {
    postal_code: "95041",
    full_city_name: "Mount Hermon, CA",
    city_name: "Mount Hermon",
  },
  {
    postal_code: "13861",
    full_city_name: "West Oneonta, NY",
    city_name: "West Oneonta",
  },
  {
    postal_code: "58761",
    full_city_name: "Mohall, ND",
    city_name: "Mohall",
  },
  {
    postal_code: "30206",
    full_city_name: "Concord, GA",
    city_name: "Concord",
  },
  {
    postal_code: "56224",
    full_city_name: "Clements, MN",
    city_name: "Clements",
  },
  {
    postal_code: "83520",
    full_city_name: "Ahsahka, ID",
    city_name: "Ahsahka",
  },
  {
    postal_code: "29934",
    full_city_name: "Pineland, SC",
    city_name: "Pineland",
  },
  {
    postal_code: "64790",
    full_city_name: "Walker, MO",
    city_name: "Walker",
  },
  {
    postal_code: "95556",
    full_city_name: "Orleans, CA",
    city_name: "Orleans",
  },
  {
    postal_code: "45360",
    full_city_name: "Port Jefferson, OH",
    city_name: "Port Jefferson",
  },
  {
    postal_code: "65688",
    full_city_name: "Brandsville, MO",
    city_name: "Brandsville",
  },
  {
    postal_code: "53103",
    full_city_name: "Big Bend, WI",
    city_name: "Big Bend",
  },
  {
    postal_code: "54839",
    full_city_name: "Grand View, WI",
    city_name: "Grand View",
  },
  {
    postal_code: "64474",
    full_city_name: "Osborn, MO",
    city_name: "Osborn",
  },
  {
    postal_code: "78963",
    full_city_name: "West Point, TX",
    city_name: "West Point",
  },
  {
    postal_code: "16734",
    full_city_name: "James City, PA",
    city_name: "James City",
  },
  {
    postal_code: "39360",
    full_city_name: "Shubuta, MS",
    city_name: "Shubuta",
  },
  {
    postal_code: "36258",
    full_city_name: "Delta, AL",
    city_name: "Delta",
  },
  {
    postal_code: "25251",
    full_city_name: "Left Hand, WV",
    city_name: "Left Hand",
  },
  {
    postal_code: "36376",
    full_city_name: "Webb, AL",
    city_name: "Webb",
  },
  {
    postal_code: "58733",
    full_city_name: "Des Lacs, ND",
    city_name: "Des Lacs",
  },
  {
    postal_code: "22722",
    full_city_name: "Haywood, VA",
    city_name: "Haywood",
  },
  {
    postal_code: "04576",
    full_city_name: "Southport, ME",
    city_name: "Southport",
  },
  {
    postal_code: "35980",
    full_city_name: "Horton, AL",
    city_name: "Horton",
  },
  {
    postal_code: "84018",
    full_city_name: "Croydon, UT",
    city_name: "Croydon",
  },
  {
    postal_code: "96008",
    full_city_name: "Bella Vista, CA",
    city_name: "Bella Vista",
  },
  {
    postal_code: "99161",
    full_city_name: "Palouse, WA",
    city_name: "Palouse",
  },
  {
    postal_code: "36578",
    full_city_name: "Stapleton, AL",
    city_name: "Stapleton",
  },
  {
    postal_code: "64664",
    full_city_name: "Mooresville, MO",
    city_name: "Mooresville",
  },
  {
    postal_code: "05062",
    full_city_name: "Reading, VT",
    city_name: "Reading",
  },
  {
    postal_code: "61432",
    full_city_name: "Fairview, IL",
    city_name: "Fairview",
  },
  {
    postal_code: "79848",
    full_city_name: "Sanderson, TX",
    city_name: "Sanderson",
  },
  {
    postal_code: "59546",
    full_city_name: "Zortman, MT",
    city_name: "Zortman",
  },
  {
    postal_code: "42151",
    full_city_name: "Hestand, KY",
    city_name: "Hestand",
  },
  {
    postal_code: "15368",
    full_city_name: "Vestaburg, PA",
    city_name: "Vestaburg",
  },
  {
    postal_code: "48472",
    full_city_name: "Snover, MI",
    city_name: "Snover",
  },
  {
    postal_code: "20667",
    full_city_name: "Park Hall, MD",
    city_name: "Park Hall",
  },
  {
    postal_code: "99756",
    full_city_name: "Manley Hot Springs, AK",
    city_name: "Manley Hot Springs",
  },
  {
    postal_code: "32567",
    full_city_name: "Laurel Hill, FL",
    city_name: "Laurel Hill",
  },
  {
    postal_code: "68444",
    full_city_name: "Strang, NE",
    city_name: "Strang",
  },
  {
    postal_code: "58329",
    full_city_name: "Dunseith, ND",
    city_name: "Dunseith",
  },
  {
    postal_code: "14781",
    full_city_name: "Sherman, NY",
    city_name: "Sherman",
  },
  {
    postal_code: "52561",
    full_city_name: "Fremont, IA",
    city_name: "Fremont",
  },
  {
    postal_code: "05841",
    full_city_name: "Greensboro, VT",
    city_name: "Greensboro",
  },
  {
    postal_code: "47917",
    full_city_name: "Ambia, IN",
    city_name: "Ambia",
  },
  {
    postal_code: "18469",
    full_city_name: "Tyler Hill, PA",
    city_name: "Tyler Hill",
  },
  {
    postal_code: "12987",
    full_city_name: "Upper Jay, NY",
    city_name: "Upper Jay",
  },
  {
    postal_code: "54657",
    full_city_name: "Steuben, WI",
    city_name: "Steuben",
  },
  {
    postal_code: "67548",
    full_city_name: "La Crosse, KS",
    city_name: "La Crosse",
  },
  {
    postal_code: "62032",
    full_city_name: "Fillmore, IL",
    city_name: "Fillmore",
  },
  {
    postal_code: "08321",
    full_city_name: "Fortescue, NJ",
    city_name: "Fortescue",
  },
  {
    postal_code: "69217",
    full_city_name: "Long Pine, NE",
    city_name: "Long Pine",
  },
  {
    postal_code: "16411",
    full_city_name: "East Springfield, PA",
    city_name: "East Springfield",
  },
  {
    postal_code: "61324",
    full_city_name: "Eldena, IL",
    city_name: "Eldena",
  },
  {
    postal_code: "49263",
    full_city_name: "Norvell, MI",
    city_name: "Norvell",
  },
  {
    postal_code: "95925",
    full_city_name: "Challenge, CA",
    city_name: "Challenge",
  },
  {
    postal_code: "62313",
    full_city_name: "Basco, IL",
    city_name: "Basco",
  },
  {
    postal_code: "26559",
    full_city_name: "Barrackville, WV",
    city_name: "Barrackville",
  },
  {
    postal_code: "82725",
    full_city_name: "Recluse, WY",
    city_name: "Recluse",
  },
  {
    postal_code: "49775",
    full_city_name: "Pointe Aux Pins, MI",
    city_name: "Pointe Aux Pins",
  },
  {
    postal_code: "66413",
    full_city_name: "Burlingame, KS",
    city_name: "Burlingame",
  },
  {
    postal_code: "53142",
    full_city_name: "Kenosha, WI",
    city_name: "Kenosha",
  },
  {
    postal_code: "28020",
    full_city_name: "Casar, NC",
    city_name: "Casar",
  },
  {
    postal_code: "69134",
    full_city_name: "Elsie, NE",
    city_name: "Elsie",
  },
  {
    postal_code: "36758",
    full_city_name: "Plantersville, AL",
    city_name: "Plantersville",
  },
  {
    postal_code: "78594",
    full_city_name: "Sebastian, TX",
    city_name: "Sebastian",
  },
  {
    postal_code: "73040",
    full_city_name: "Geary, OK",
    city_name: "Geary",
  },
  {
    postal_code: "05648",
    full_city_name: "Calais, VT",
    city_name: "Calais",
  },
  {
    postal_code: "74567",
    full_city_name: "Snow, OK",
    city_name: "Snow",
  },
  {
    postal_code: "53403",
    full_city_name: "Racine, WI",
    city_name: "Racine",
  },
  {
    postal_code: "39177",
    full_city_name: "Valley Park, MS",
    city_name: "Valley Park",
  },
  {
    postal_code: "53571",
    full_city_name: "Morrisonville, WI",
    city_name: "Morrisonville",
  },
  {
    postal_code: "43555",
    full_city_name: "Ridgeville Corners, OH",
    city_name: "Ridgeville Corners",
  },
  {
    postal_code: "25559",
    full_city_name: "Salt Rock, WV",
    city_name: "Salt Rock",
  },
  {
    postal_code: "81325",
    full_city_name: "Egnar, CO",
    city_name: "Egnar",
  },
  {
    postal_code: "72749",
    full_city_name: "Morrow, AR",
    city_name: "Morrow",
  },
  {
    postal_code: "66782",
    full_city_name: "West Mineral, KS",
    city_name: "West Mineral",
  },
  {
    postal_code: "13435",
    full_city_name: "Prospect, NY",
    city_name: "Prospect",
  },
  {
    postal_code: "77990",
    full_city_name: "Tivoli, TX",
    city_name: "Tivoli",
  },
  {
    postal_code: "75422",
    full_city_name: "Campbell, TX",
    city_name: "Campbell",
  },
  {
    postal_code: "88422",
    full_city_name: "Gladstone, NM",
    city_name: "Gladstone",
  },
  {
    postal_code: "44702",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "16238",
    full_city_name: "Manorville, PA",
    city_name: "Manorville",
  },
  {
    postal_code: "42027",
    full_city_name: "Boaz, KY",
    city_name: "Boaz",
  },
  {
    postal_code: "15520",
    full_city_name: "Acosta, PA",
    city_name: "Acosta",
  },
  {
    postal_code: "21856",
    full_city_name: "Quantico, MD",
    city_name: "Quantico",
  },
  {
    postal_code: "21405",
    full_city_name: "Annapolis, MD",
    city_name: "Annapolis",
  },
  {
    postal_code: "42369",
    full_city_name: "Rockport, KY",
    city_name: "Rockport",
  },
  {
    postal_code: "43948",
    full_city_name: "Smithfield, OH",
    city_name: "Smithfield",
  },
  {
    postal_code: "57448",
    full_city_name: "Hosmer, SD",
    city_name: "Hosmer",
  },
  {
    postal_code: "35459",
    full_city_name: "Emelle, AL",
    city_name: "Emelle",
  },
  {
    postal_code: "72350",
    full_city_name: "Joiner, AR",
    city_name: "Joiner",
  },
  {
    postal_code: "87577",
    full_city_name: "Tres Piedras, NM",
    city_name: "Tres Piedras",
  },
  {
    postal_code: "56510",
    full_city_name: "Ada, MN",
    city_name: "Ada",
  },
  {
    postal_code: "65032",
    full_city_name: "Eugene, MO",
    city_name: "Eugene",
  },
  {
    postal_code: "73844",
    full_city_name: "Gate, OK",
    city_name: "Gate",
  },
  {
    postal_code: "80101",
    full_city_name: "Agate, CO",
    city_name: "Agate",
  },
  {
    postal_code: "25113",
    full_city_name: "Ivydale, WV",
    city_name: "Ivydale",
  },
  {
    postal_code: "16941",
    full_city_name: "Genesee, PA",
    city_name: "Genesee",
  },
  {
    postal_code: "46785",
    full_city_name: "Saint Joe, IN",
    city_name: "Saint Joe",
  },
  {
    postal_code: "38676",
    full_city_name: "Tunica, MS",
    city_name: "Tunica",
  },
  {
    postal_code: "19936",
    full_city_name: "Cheswold, DE",
    city_name: "Cheswold",
  },
  {
    postal_code: "54632",
    full_city_name: "Genoa, WI",
    city_name: "Genoa",
  },
  {
    postal_code: "76263",
    full_city_name: "Rosston, TX",
    city_name: "Rosston",
  },
  {
    postal_code: "74358",
    full_city_name: "North Miami, OK",
    city_name: "North Miami",
  },
  {
    postal_code: "56626",
    full_city_name: "Bena, MN",
    city_name: "Bena",
  },
  {
    postal_code: "15660",
    full_city_name: "Lowber, PA",
    city_name: "Lowber",
  },
  {
    postal_code: "05776",
    full_city_name: "West Rupert, VT",
    city_name: "West Rupert",
  },
  {
    postal_code: "58492",
    full_city_name: "Wimbledon, ND",
    city_name: "Wimbledon",
  },
  {
    postal_code: "89430",
    full_city_name: "Smith, NV",
    city_name: "Smith",
  },
  {
    postal_code: "62414",
    full_city_name: "Beecher City, IL",
    city_name: "Beecher City",
  },
  {
    postal_code: "16693",
    full_city_name: "Williamsburg, PA",
    city_name: "Williamsburg",
  },
  {
    postal_code: "36540",
    full_city_name: "Gainestown, AL",
    city_name: "Gainestown",
  },
  {
    postal_code: "38625",
    full_city_name: "Dumas, MS",
    city_name: "Dumas",
  },
  {
    postal_code: "63964",
    full_city_name: "Silva, MO",
    city_name: "Silva",
  },
  {
    postal_code: "51058",
    full_city_name: "Sutherland, IA",
    city_name: "Sutherland",
  },
  {
    postal_code: "68364",
    full_city_name: "Goehner, NE",
    city_name: "Goehner",
  },
  {
    postal_code: "61316",
    full_city_name: "Cedar Point, IL",
    city_name: "Cedar Point",
  },
  {
    postal_code: "18934",
    full_city_name: "Mechanicsville, PA",
    city_name: "Mechanicsville",
  },
  {
    postal_code: "77863",
    full_city_name: "Lyons, TX",
    city_name: "Lyons",
  },
  {
    postal_code: "15676",
    full_city_name: "Pleasant Unity, PA",
    city_name: "Pleasant Unity",
  },
  {
    postal_code: "50163",
    full_city_name: "Melcher Dallas, IA",
    city_name: "Melcher Dallas",
  },
  {
    postal_code: "97810",
    full_city_name: "Adams, OR",
    city_name: "Adams",
  },
  {
    postal_code: "65439",
    full_city_name: "Bixby, MO",
    city_name: "Bixby",
  },
  {
    postal_code: "12491",
    full_city_name: "West Hurley, NY",
    city_name: "West Hurley",
  },
  {
    postal_code: "21872",
    full_city_name: "Whaleyville, MD",
    city_name: "Whaleyville",
  },
  {
    postal_code: "45859",
    full_city_name: "Mc Guffey, OH",
    city_name: "Mc Guffey",
  },
  {
    postal_code: "80705",
    full_city_name: "Log Lane Village, CO",
    city_name: "Log Lane Village",
  },
  {
    postal_code: "55742",
    full_city_name: "Goodland, MN",
    city_name: "Goodland",
  },
  {
    postal_code: "69168",
    full_city_name: "Venango, NE",
    city_name: "Venango",
  },
  {
    postal_code: "65010",
    full_city_name: "Ashland, MO",
    city_name: "Ashland",
  },
  {
    postal_code: "66516",
    full_city_name: "Netawaka, KS",
    city_name: "Netawaka",
  },
  {
    postal_code: "72655",
    full_city_name: "Mount Judea, AR",
    city_name: "Mount Judea",
  },
  {
    postal_code: "24962",
    full_city_name: "Pence Springs, WV",
    city_name: "Pence Springs",
  },
  {
    postal_code: "26617",
    full_city_name: "Dille, WV",
    city_name: "Dille",
  },
  {
    postal_code: "56437",
    full_city_name: "Bertha, MN",
    city_name: "Bertha",
  },
  {
    postal_code: "87415",
    full_city_name: "Flora Vista, NM",
    city_name: "Flora Vista",
  },
  {
    postal_code: "38339",
    full_city_name: "Guys, TN",
    city_name: "Guys",
  },
  {
    postal_code: "29018",
    full_city_name: "Bowman, SC",
    city_name: "Bowman",
  },
  {
    postal_code: "02199",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "52251",
    full_city_name: "Ladora, IA",
    city_name: "Ladora",
  },
  {
    postal_code: "69125",
    full_city_name: "Broadwater, NE",
    city_name: "Broadwater",
  },
  {
    postal_code: "63751",
    full_city_name: "Glenallen, MO",
    city_name: "Glenallen",
  },
  {
    postal_code: "50460",
    full_city_name: "Orchard, IA",
    city_name: "Orchard",
  },
  {
    postal_code: "68711",
    full_city_name: "Amelia, NE",
    city_name: "Amelia",
  },
  {
    postal_code: "63430",
    full_city_name: "Alexandria, MO",
    city_name: "Alexandria",
  },
  {
    postal_code: "57452",
    full_city_name: "Java, SD",
    city_name: "Java",
  },
  {
    postal_code: "94060",
    full_city_name: "Pescadero, CA",
    city_name: "Pescadero",
  },
  {
    postal_code: "68713",
    full_city_name: "Atkinson, NE",
    city_name: "Atkinson",
  },
  {
    postal_code: "26731",
    full_city_name: "Lahmansville, WV",
    city_name: "Lahmansville",
  },
  {
    postal_code: "13343",
    full_city_name: "Glenfield, NY",
    city_name: "Glenfield",
  },
  {
    postal_code: "58259",
    full_city_name: "Michigan, ND",
    city_name: "Michigan",
  },
  {
    postal_code: "58533",
    full_city_name: "Elgin, ND",
    city_name: "Elgin",
  },
  {
    postal_code: "53004",
    full_city_name: "Belgium, WI",
    city_name: "Belgium",
  },
  {
    postal_code: "58763",
    full_city_name: "New Town, ND",
    city_name: "New Town",
  },
  {
    postal_code: "83244",
    full_city_name: "Howe, ID",
    city_name: "Howe",
  },
  {
    postal_code: "61374",
    full_city_name: "Van Orin, IL",
    city_name: "Van Orin",
  },
  {
    postal_code: "20880",
    full_city_name: "Washington Grove, MD",
    city_name: "Washington Grove",
  },
  {
    postal_code: "82835",
    full_city_name: "Clearmont, WY",
    city_name: "Clearmont",
  },
  {
    postal_code: "93554",
    full_city_name: "Randsburg, CA",
    city_name: "Randsburg",
  },
  {
    postal_code: "26681",
    full_city_name: "Nettie, WV",
    city_name: "Nettie",
  },
  {
    postal_code: "70543",
    full_city_name: "Iota, LA",
    city_name: "Iota",
  },
  {
    postal_code: "74755",
    full_city_name: "Rufe, OK",
    city_name: "Rufe",
  },
  {
    postal_code: "68980",
    full_city_name: "Trumbull, NE",
    city_name: "Trumbull",
  },
  {
    postal_code: "98542",
    full_city_name: "Ethel, WA",
    city_name: "Ethel",
  },
  {
    postal_code: "68453",
    full_city_name: "Tobias, NE",
    city_name: "Tobias",
  },
  {
    postal_code: "18032",
    full_city_name: "Catasauqua, PA",
    city_name: "Catasauqua",
  },
  {
    postal_code: "62835",
    full_city_name: "Enfield, IL",
    city_name: "Enfield",
  },
  {
    postal_code: "41397",
    full_city_name: "Zoe, KY",
    city_name: "Zoe",
  },
  {
    postal_code: "13063",
    full_city_name: "Fabius, NY",
    city_name: "Fabius",
  },
  {
    postal_code: "74430",
    full_city_name: "Crowder, OK",
    city_name: "Crowder",
  },
  {
    postal_code: "56309",
    full_city_name: "Ashby, MN",
    city_name: "Ashby",
  },
  {
    postal_code: "04006",
    full_city_name: "Biddeford Pool, ME",
    city_name: "Biddeford Pool",
  },
  {
    postal_code: "05850",
    full_city_name: "Lyndon Center, VT",
    city_name: "Lyndon Center",
  },
  {
    postal_code: "83802",
    full_city_name: "Avery, ID",
    city_name: "Avery",
  },
  {
    postal_code: "17233",
    full_city_name: "Mc Connellsburg, PA",
    city_name: "Mc Connellsburg",
  },
  {
    postal_code: "39630",
    full_city_name: "Bude, MS",
    city_name: "Bude",
  },
  {
    postal_code: "12723",
    full_city_name: "Callicoon, NY",
    city_name: "Callicoon",
  },
  {
    postal_code: "53599",
    full_city_name: "Woodford, WI",
    city_name: "Woodford",
  },
  {
    postal_code: "74756",
    full_city_name: "Sawyer, OK",
    city_name: "Sawyer",
  },
  {
    postal_code: "18248",
    full_city_name: "Sheppton, PA",
    city_name: "Sheppton",
  },
  {
    postal_code: "57045",
    full_city_name: "Menno, SD",
    city_name: "Menno",
  },
  {
    postal_code: "43018",
    full_city_name: "Etna, OH",
    city_name: "Etna",
  },
  {
    postal_code: "61876",
    full_city_name: "Sidell, IL",
    city_name: "Sidell",
  },
  {
    postal_code: "04764",
    full_city_name: "Oxbow, ME",
    city_name: "Oxbow",
  },
  {
    postal_code: "76491",
    full_city_name: "Woodson, TX",
    city_name: "Woodson",
  },
  {
    postal_code: "72466",
    full_city_name: "Smithville, AR",
    city_name: "Smithville",
  },
  {
    postal_code: "83424",
    full_city_name: "Felt, ID",
    city_name: "Felt",
  },
  {
    postal_code: "23110",
    full_city_name: "Mattaponi, VA",
    city_name: "Mattaponi",
  },
  {
    postal_code: "52626",
    full_city_name: "Farmington, IA",
    city_name: "Farmington",
  },
  {
    postal_code: "42634",
    full_city_name: "Parkers Lake, KY",
    city_name: "Parkers Lake",
  },
  {
    postal_code: "16029",
    full_city_name: "East Butler, PA",
    city_name: "East Butler",
  },
  {
    postal_code: "71023",
    full_city_name: "Doyline, LA",
    city_name: "Doyline",
  },
  {
    postal_code: "29129",
    full_city_name: "Ridge Spring, SC",
    city_name: "Ridge Spring",
  },
  {
    postal_code: "75631",
    full_city_name: "Beckville, TX",
    city_name: "Beckville",
  },
  {
    postal_code: "50471",
    full_city_name: "Rudd, IA",
    city_name: "Rudd",
  },
  {
    postal_code: "87575",
    full_city_name: "Tierra Amarilla, NM",
    city_name: "Tierra Amarilla",
  },
  {
    postal_code: "05350",
    full_city_name: "Readsboro, VT",
    city_name: "Readsboro",
  },
  {
    postal_code: "27956",
    full_city_name: "Maple, NC",
    city_name: "Maple",
  },
  {
    postal_code: "04418",
    full_city_name: "Greenbush, ME",
    city_name: "Greenbush",
  },
  {
    postal_code: "36047",
    full_city_name: "Letohatchee, AL",
    city_name: "Letohatchee",
  },
  {
    postal_code: "64739",
    full_city_name: "Creighton, MO",
    city_name: "Creighton",
  },
  {
    postal_code: "36782",
    full_city_name: "Sweet Water, AL",
    city_name: "Sweet Water",
  },
  {
    postal_code: "50239",
    full_city_name: "Saint Anthony, IA",
    city_name: "Saint Anthony",
  },
  {
    postal_code: "05842",
    full_city_name: "Greensboro Bend, VT",
    city_name: "Greensboro Bend",
  },
  {
    postal_code: "25515",
    full_city_name: "Gallipolis Ferry, WV",
    city_name: "Gallipolis Ferry",
  },
  {
    postal_code: "45675",
    full_city_name: "Rock Camp, OH",
    city_name: "Rock Camp",
  },
  {
    postal_code: "56534",
    full_city_name: "Erhard, MN",
    city_name: "Erhard",
  },
  {
    postal_code: "63847",
    full_city_name: "Gibson, MO",
    city_name: "Gibson",
  },
  {
    postal_code: "42344",
    full_city_name: "Graham, KY",
    city_name: "Graham",
  },
  {
    postal_code: "23426",
    full_city_name: "Sanford, VA",
    city_name: "Sanford",
  },
  {
    postal_code: "95922",
    full_city_name: "Camptonville, CA",
    city_name: "Camptonville",
  },
  {
    postal_code: "53129",
    full_city_name: "Greendale, WI",
    city_name: "Greendale",
  },
  {
    postal_code: "99724",
    full_city_name: "Beaver, AK",
    city_name: "Beaver",
  },
  {
    postal_code: "86511",
    full_city_name: "Saint Michaels, AZ",
    city_name: "Saint Michaels",
  },
  {
    postal_code: "45782",
    full_city_name: "Trimble, OH",
    city_name: "Trimble",
  },
  {
    postal_code: "71340",
    full_city_name: "Harrisonburg, LA",
    city_name: "Harrisonburg",
  },
  {
    postal_code: "97534",
    full_city_name: "O Brien, OR",
    city_name: "O Brien",
  },
  {
    postal_code: "14082",
    full_city_name: "Java Center, NY",
    city_name: "Java Center",
  },
  {
    postal_code: "71256",
    full_city_name: "Lillie, LA",
    city_name: "Lillie",
  },
  {
    postal_code: "54824",
    full_city_name: "Centuria, WI",
    city_name: "Centuria",
  },
  {
    postal_code: "52801",
    full_city_name: "Davenport, IA",
    city_name: "Davenport",
  },
  {
    postal_code: "68723",
    full_city_name: "Carroll, NE",
    city_name: "Carroll",
  },
  {
    postal_code: "79566",
    full_city_name: "Wingate, TX",
    city_name: "Wingate",
  },
  {
    postal_code: "46986",
    full_city_name: "Swayzee, IN",
    city_name: "Swayzee",
  },
  {
    postal_code: "64636",
    full_city_name: "Coffey, MO",
    city_name: "Coffey",
  },
  {
    postal_code: "83227",
    full_city_name: "Clayton, ID",
    city_name: "Clayton",
  },
  {
    postal_code: "27847",
    full_city_name: "Kelford, NC",
    city_name: "Kelford",
  },
  {
    postal_code: "96117",
    full_city_name: "Litchfield, CA",
    city_name: "Litchfield",
  },
  {
    postal_code: "97481",
    full_city_name: "Tenmile, OR",
    city_name: "Tenmile",
  },
  {
    postal_code: "04253",
    full_city_name: "Livermore, ME",
    city_name: "Livermore",
  },
  {
    postal_code: "26679",
    full_city_name: "Mount Nebo, WV",
    city_name: "Mount Nebo",
  },
  {
    postal_code: "58634",
    full_city_name: "Grassy Butte, ND",
    city_name: "Grassy Butte",
  },
  {
    postal_code: "61431",
    full_city_name: "Ellisville, IL",
    city_name: "Ellisville",
  },
  {
    postal_code: "44032",
    full_city_name: "Dorset, OH",
    city_name: "Dorset",
  },
  {
    postal_code: "04066",
    full_city_name: "Orrs Island, ME",
    city_name: "Orrs Island",
  },
  {
    postal_code: "18851",
    full_city_name: "Warren Center, PA",
    city_name: "Warren Center",
  },
  {
    postal_code: "59078",
    full_city_name: "Shawmut, MT",
    city_name: "Shawmut",
  },
  {
    postal_code: "31567",
    full_city_name: "West Green, GA",
    city_name: "West Green",
  },
  {
    postal_code: "64866",
    full_city_name: "Stark City, MO",
    city_name: "Stark City",
  },
  {
    postal_code: "72368",
    full_city_name: "Moro, AR",
    city_name: "Moro",
  },
  {
    postal_code: "43754",
    full_city_name: "Lewisville, OH",
    city_name: "Lewisville",
  },
  {
    postal_code: "52639",
    full_city_name: "Montrose, IA",
    city_name: "Montrose",
  },
  {
    postal_code: "68748",
    full_city_name: "Madison, NE",
    city_name: "Madison",
  },
  {
    postal_code: "33849",
    full_city_name: "Kathleen, FL",
    city_name: "Kathleen",
  },
  {
    postal_code: "65692",
    full_city_name: "Koshkonong, MO",
    city_name: "Koshkonong",
  },
  {
    postal_code: "50246",
    full_city_name: "Stanhope, IA",
    city_name: "Stanhope",
  },
  {
    postal_code: "26206",
    full_city_name: "Cowen, WV",
    city_name: "Cowen",
  },
  {
    postal_code: "68946",
    full_city_name: "Hendley, NE",
    city_name: "Hendley",
  },
  {
    postal_code: "59713",
    full_city_name: "Avon, MT",
    city_name: "Avon",
  },
  {
    postal_code: "51108",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "41667",
    full_city_name: "Weeksbury, KY",
    city_name: "Weeksbury",
  },
  {
    postal_code: "62867",
    full_city_name: "New Haven, IL",
    city_name: "New Haven",
  },
  {
    postal_code: "86502",
    full_city_name: "Chambers, AZ",
    city_name: "Chambers",
  },
  {
    postal_code: "29530",
    full_city_name: "Coward, SC",
    city_name: "Coward",
  },
  {
    postal_code: "15480",
    full_city_name: "Smock, PA",
    city_name: "Smock",
  },
  {
    postal_code: "46050",
    full_city_name: "Kirklin, IN",
    city_name: "Kirklin",
  },
  {
    postal_code: "66770",
    full_city_name: "Riverton, KS",
    city_name: "Riverton",
  },
  {
    postal_code: "41774",
    full_city_name: "Viper, KY",
    city_name: "Viper",
  },
  {
    postal_code: "44490",
    full_city_name: "Washingtonville, OH",
    city_name: "Washingtonville",
  },
  {
    postal_code: "13483",
    full_city_name: "Westdale, NY",
    city_name: "Westdale",
  },
  {
    postal_code: "65444",
    full_city_name: "Bucyrus, MO",
    city_name: "Bucyrus",
  },
  {
    postal_code: "70733",
    full_city_name: "French Settlement, LA",
    city_name: "French Settlement",
  },
  {
    postal_code: "76483",
    full_city_name: "Throckmorton, TX",
    city_name: "Throckmorton",
  },
  {
    postal_code: "59071",
    full_city_name: "Roscoe, MT",
    city_name: "Roscoe",
  },
  {
    postal_code: "57460",
    full_city_name: "Mansfield, SD",
    city_name: "Mansfield",
  },
  {
    postal_code: "20765",
    full_city_name: "Galesville, MD",
    city_name: "Galesville",
  },
  {
    postal_code: "79538",
    full_city_name: "Novice, TX",
    city_name: "Novice",
  },
  {
    postal_code: "26739",
    full_city_name: "Mount Storm, WV",
    city_name: "Mount Storm",
  },
  {
    postal_code: "50570",
    full_city_name: "Ottosen, IA",
    city_name: "Ottosen",
  },
  {
    postal_code: "36765",
    full_city_name: "Newbern, AL",
    city_name: "Newbern",
  },
  {
    postal_code: "50042",
    full_city_name: "Brayton, IA",
    city_name: "Brayton",
  },
  {
    postal_code: "42266",
    full_city_name: "Pembroke, KY",
    city_name: "Pembroke",
  },
  {
    postal_code: "65345",
    full_city_name: "Mora, MO",
    city_name: "Mora",
  },
  {
    postal_code: "74646",
    full_city_name: "Nardin, OK",
    city_name: "Nardin",
  },
  {
    postal_code: "79064",
    full_city_name: "Olton, TX",
    city_name: "Olton",
  },
  {
    postal_code: "04538",
    full_city_name: "Boothbay Harbor, ME",
    city_name: "Boothbay Harbor",
  },
  {
    postal_code: "73727",
    full_city_name: "Carrier, OK",
    city_name: "Carrier",
  },
  {
    postal_code: "43408",
    full_city_name: "Clay Center, OH",
    city_name: "Clay Center",
  },
  {
    postal_code: "50430",
    full_city_name: "Corwith, IA",
    city_name: "Corwith",
  },
  {
    postal_code: "72341",
    full_city_name: "Haynes, AR",
    city_name: "Haynes",
  },
  {
    postal_code: "67864",
    full_city_name: "Meade, KS",
    city_name: "Meade",
  },
  {
    postal_code: "25106",
    full_city_name: "Henderson, WV",
    city_name: "Henderson",
  },
  {
    postal_code: "16228",
    full_city_name: "Ford Cliff, PA",
    city_name: "Ford Cliff",
  },
  {
    postal_code: "21672",
    full_city_name: "Toddville, MD",
    city_name: "Toddville",
  },
  {
    postal_code: "62462",
    full_city_name: "Sigel, IL",
    city_name: "Sigel",
  },
  {
    postal_code: "49459",
    full_city_name: "Walkerville, MI",
    city_name: "Walkerville",
  },
  {
    postal_code: "29546",
    full_city_name: "Gresham, SC",
    city_name: "Gresham",
  },
  {
    postal_code: "80834",
    full_city_name: "Seibert, CO",
    city_name: "Seibert",
  },
  {
    postal_code: "25862",
    full_city_name: "Lansing, WV",
    city_name: "Lansing",
  },
  {
    postal_code: "07737",
    full_city_name: "Leonardo, NJ",
    city_name: "Leonardo",
  },
  {
    postal_code: "65733",
    full_city_name: "Protem, MO",
    city_name: "Protem",
  },
  {
    postal_code: "68337",
    full_city_name: "Dawson, NE",
    city_name: "Dawson",
  },
  {
    postal_code: "66554",
    full_city_name: "Randolph, KS",
    city_name: "Randolph",
  },
  {
    postal_code: "79839",
    full_city_name: "Fort Hancock, TX",
    city_name: "Fort Hancock",
  },
  {
    postal_code: "79080",
    full_city_name: "Skellytown, TX",
    city_name: "Skellytown",
  },
  {
    postal_code: "16637",
    full_city_name: "East Freedom, PA",
    city_name: "East Freedom",
  },
  {
    postal_code: "58454",
    full_city_name: "Jud, ND",
    city_name: "Jud",
  },
  {
    postal_code: "66518",
    full_city_name: "Oketo, KS",
    city_name: "Oketo",
  },
  {
    postal_code: "15711",
    full_city_name: "Anita, PA",
    city_name: "Anita",
  },
  {
    postal_code: "96040",
    full_city_name: "Hat Creek, CA",
    city_name: "Hat Creek",
  },
  {
    postal_code: "15547",
    full_city_name: "Jennerstown, PA",
    city_name: "Jennerstown",
  },
  {
    postal_code: "66871",
    full_city_name: "Waverly, KS",
    city_name: "Waverly",
  },
  {
    postal_code: "97910",
    full_city_name: "Jordan Valley, OR",
    city_name: "Jordan Valley",
  },
  {
    postal_code: "67028",
    full_city_name: "Coats, KS",
    city_name: "Coats",
  },
  {
    postal_code: "44653",
    full_city_name: "Midvale, OH",
    city_name: "Midvale",
  },
  {
    postal_code: "15629",
    full_city_name: "East Vandergrift, PA",
    city_name: "East Vandergrift",
  },
  {
    postal_code: "73048",
    full_city_name: "Hydro, OK",
    city_name: "Hydro",
  },
  {
    postal_code: "28578",
    full_city_name: "Seven Springs, NC",
    city_name: "Seven Springs",
  },
  {
    postal_code: "50682",
    full_city_name: "Winthrop, IA",
    city_name: "Winthrop",
  },
  {
    postal_code: "15937",
    full_city_name: "Jerome, PA",
    city_name: "Jerome",
  },
  {
    postal_code: "42349",
    full_city_name: "Horse Branch, KY",
    city_name: "Horse Branch",
  },
  {
    postal_code: "29518",
    full_city_name: "Cades, SC",
    city_name: "Cades",
  },
  {
    postal_code: "13103",
    full_city_name: "Mallory, NY",
    city_name: "Mallory",
  },
  {
    postal_code: "73666",
    full_city_name: "Sweetwater, OK",
    city_name: "Sweetwater",
  },
  {
    postal_code: "03875",
    full_city_name: "Silver Lake, NH",
    city_name: "Silver Lake",
  },
  {
    postal_code: "04787",
    full_city_name: "Westfield, ME",
    city_name: "Westfield",
  },
  {
    postal_code: "82410",
    full_city_name: "Basin, WY",
    city_name: "Basin",
  },
  {
    postal_code: "59241",
    full_city_name: "Hinsdale, MT",
    city_name: "Hinsdale",
  },
  {
    postal_code: "43519",
    full_city_name: "Evansport, OH",
    city_name: "Evansport",
  },
  {
    postal_code: "62241",
    full_city_name: "Ellis Grove, IL",
    city_name: "Ellis Grove",
  },
  {
    postal_code: "58782",
    full_city_name: "Sherwood, ND",
    city_name: "Sherwood",
  },
  {
    postal_code: "70039",
    full_city_name: "Boutte, LA",
    city_name: "Boutte",
  },
  {
    postal_code: "15469",
    full_city_name: "Normalville, PA",
    city_name: "Normalville",
  },
  {
    postal_code: "58712",
    full_city_name: "Balfour, ND",
    city_name: "Balfour",
  },
  {
    postal_code: "72629",
    full_city_name: "Dennard, AR",
    city_name: "Dennard",
  },
  {
    postal_code: "50523",
    full_city_name: "Callender, IA",
    city_name: "Callender",
  },
  {
    postal_code: "51350",
    full_city_name: "Melvin, IA",
    city_name: "Melvin",
  },
  {
    postal_code: "84747",
    full_city_name: "Loa, UT",
    city_name: "Loa",
  },
  {
    postal_code: "80840",
    full_city_name: "Usaf Academy, CO",
    city_name: "Usaf Academy",
  },
  {
    postal_code: "66024",
    full_city_name: "Elwood, KS",
    city_name: "Elwood",
  },
  {
    postal_code: "84620",
    full_city_name: "Aurora, UT",
    city_name: "Aurora",
  },
  {
    postal_code: "15043",
    full_city_name: "Georgetown, PA",
    city_name: "Georgetown",
  },
  {
    postal_code: "59314",
    full_city_name: "Biddle, MT",
    city_name: "Biddle",
  },
  {
    postal_code: "73960",
    full_city_name: "Texhoma, TX",
    city_name: "Texhoma",
  },
  {
    postal_code: "47634",
    full_city_name: "Richland, IN",
    city_name: "Richland",
  },
  {
    postal_code: "68764",
    full_city_name: "Orchard, NE",
    city_name: "Orchard",
  },
  {
    postal_code: "51052",
    full_city_name: "Salix, IA",
    city_name: "Salix",
  },
  {
    postal_code: "59211",
    full_city_name: "Antelope, MT",
    city_name: "Antelope",
  },
  {
    postal_code: "15960",
    full_city_name: "Twin Rocks, PA",
    city_name: "Twin Rocks",
  },
  {
    postal_code: "58346",
    full_city_name: "Leeds, ND",
    city_name: "Leeds",
  },
  {
    postal_code: "50565",
    full_city_name: "Marathon, IA",
    city_name: "Marathon",
  },
  {
    postal_code: "31009",
    full_city_name: "Cadwell, GA",
    city_name: "Cadwell",
  },
  {
    postal_code: "29840",
    full_city_name: "Mount Carmel, SC",
    city_name: "Mount Carmel",
  },
  {
    postal_code: "95659",
    full_city_name: "Nicolaus, CA",
    city_name: "Nicolaus",
  },
  {
    postal_code: "51431",
    full_city_name: "Arthur, IA",
    city_name: "Arthur",
  },
  {
    postal_code: "97461",
    full_city_name: "Noti, OR",
    city_name: "Noti",
  },
  {
    postal_code: "68336",
    full_city_name: "Davey, NE",
    city_name: "Davey",
  },
  {
    postal_code: "65281",
    full_city_name: "Salisbury, MO",
    city_name: "Salisbury",
  },
  {
    postal_code: "19534",
    full_city_name: "Lenhartsville, PA",
    city_name: "Lenhartsville",
  },
  {
    postal_code: "37339",
    full_city_name: "Gruetli Laager, TN",
    city_name: "Gruetli Laager",
  },
  {
    postal_code: "80746",
    full_city_name: "Paoli, CO",
    city_name: "Paoli",
  },
  {
    postal_code: "19501",
    full_city_name: "Adamstown, PA",
    city_name: "Adamstown",
  },
  {
    postal_code: "36922",
    full_city_name: "Ward, AL",
    city_name: "Ward",
  },
  {
    postal_code: "72672",
    full_city_name: "Pyatt, AR",
    city_name: "Pyatt",
  },
  {
    postal_code: "52154",
    full_city_name: "Lawler, IA",
    city_name: "Lawler",
  },
  {
    postal_code: "42782",
    full_city_name: "Summersville, KY",
    city_name: "Summersville",
  },
  {
    postal_code: "47524",
    full_city_name: "Decker, IN",
    city_name: "Decker",
  },
  {
    postal_code: "62501",
    full_city_name: "Argenta, IL",
    city_name: "Argenta",
  },
  {
    postal_code: "65280",
    full_city_name: "Rush Hill, MO",
    city_name: "Rush Hill",
  },
  {
    postal_code: "71007",
    full_city_name: "Bethany, LA",
    city_name: "Bethany",
  },
  {
    postal_code: "47166",
    full_city_name: "Ramsey, IN",
    city_name: "Ramsey",
  },
  {
    postal_code: "26440",
    full_city_name: "Thornton, WV",
    city_name: "Thornton",
  },
  {
    postal_code: "31803",
    full_city_name: "Buena Vista, GA",
    city_name: "Buena Vista",
  },
  {
    postal_code: "54563",
    full_city_name: "Tony, WI",
    city_name: "Tony",
  },
  {
    postal_code: "26339",
    full_city_name: "Center Point, WV",
    city_name: "Center Point",
  },
  {
    postal_code: "79547",
    full_city_name: "Rule, TX",
    city_name: "Rule",
  },
  {
    postal_code: "66547",
    full_city_name: "Wamego, KS",
    city_name: "Wamego",
  },
  {
    postal_code: "17771",
    full_city_name: "Trout Run, PA",
    city_name: "Trout Run",
  },
  {
    postal_code: "14033",
    full_city_name: "Colden, NY",
    city_name: "Colden",
  },
  {
    postal_code: "79063",
    full_city_name: "Nazareth, TX",
    city_name: "Nazareth",
  },
  {
    postal_code: "61865",
    full_city_name: "Potomac, IL",
    city_name: "Potomac",
  },
  {
    postal_code: "51046",
    full_city_name: "Paullina, IA",
    city_name: "Paullina",
  },
  {
    postal_code: "14422",
    full_city_name: "Byron, NY",
    city_name: "Byron",
  },
  {
    postal_code: "62977",
    full_city_name: "Raleigh, IL",
    city_name: "Raleigh",
  },
  {
    postal_code: "14522",
    full_city_name: "Palmyra, NY",
    city_name: "Palmyra",
  },
  {
    postal_code: "59053",
    full_city_name: "Martinsdale, MT",
    city_name: "Martinsdale",
  },
  {
    postal_code: "56735",
    full_city_name: "Lancaster, MN",
    city_name: "Lancaster",
  },
  {
    postal_code: "71841",
    full_city_name: "Gillham, AR",
    city_name: "Gillham",
  },
  {
    postal_code: "80483",
    full_city_name: "Yampa, CO",
    city_name: "Yampa",
  },
  {
    postal_code: "42124",
    full_city_name: "Beaumont, KY",
    city_name: "Beaumont",
  },
  {
    postal_code: "23354",
    full_city_name: "Franktown, VA",
    city_name: "Franktown",
  },
  {
    postal_code: "15773",
    full_city_name: "Saint Benedict, PA",
    city_name: "Saint Benedict",
  },
  {
    postal_code: "46543",
    full_city_name: "Millersburg, IN",
    city_name: "Millersburg",
  },
  {
    postal_code: "26338",
    full_city_name: "Camden, WV",
    city_name: "Camden",
  },
  {
    postal_code: "40310",
    full_city_name: "Burgin, KY",
    city_name: "Burgin",
  },
  {
    postal_code: "54734",
    full_city_name: "Downing, WI",
    city_name: "Downing",
  },
  {
    postal_code: "52649",
    full_city_name: "Salem, IA",
    city_name: "Salem",
  },
  {
    postal_code: "59468",
    full_city_name: "Power, MT",
    city_name: "Power",
  },
  {
    postal_code: "14216",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "67438",
    full_city_name: "Durham, KS",
    city_name: "Durham",
  },
  {
    postal_code: "43511",
    full_city_name: "Custar, OH",
    city_name: "Custar",
  },
  {
    postal_code: "31060",
    full_city_name: "Milan, GA",
    city_name: "Milan",
  },
  {
    postal_code: "95957",
    full_city_name: "Meridian, CA",
    city_name: "Meridian",
  },
  {
    postal_code: "15316",
    full_city_name: "Brave, PA",
    city_name: "Brave",
  },
  {
    postal_code: "29434",
    full_city_name: "Cordesville, SC",
    city_name: "Cordesville",
  },
  {
    postal_code: "60951",
    full_city_name: "Martinton, IL",
    city_name: "Martinton",
  },
  {
    postal_code: "97637",
    full_city_name: "Plush, OR",
    city_name: "Plush",
  },
  {
    postal_code: "71763",
    full_city_name: "Sparkman, AR",
    city_name: "Sparkman",
  },
  {
    postal_code: "42409",
    full_city_name: "Dixon, KY",
    city_name: "Dixon",
  },
  {
    postal_code: "31787",
    full_city_name: "Smithville, GA",
    city_name: "Smithville",
  },
  {
    postal_code: "83466",
    full_city_name: "North Fork, ID",
    city_name: "North Fork",
  },
  {
    postal_code: "40445",
    full_city_name: "Livingston, KY",
    city_name: "Livingston",
  },
  {
    postal_code: "95456",
    full_city_name: "Little River, CA",
    city_name: "Little River",
  },
  {
    postal_code: "74724",
    full_city_name: "Bethel, OK",
    city_name: "Bethel",
  },
  {
    postal_code: "71747",
    full_city_name: "Huttig, AR",
    city_name: "Huttig",
  },
  {
    postal_code: "65580",
    full_city_name: "Vichy, MO",
    city_name: "Vichy",
  },
  {
    postal_code: "54413",
    full_city_name: "Babcock, WI",
    city_name: "Babcock",
  },
  {
    postal_code: "67882",
    full_city_name: "Wright, KS",
    city_name: "Wright",
  },
  {
    postal_code: "36039",
    full_city_name: "Hardaway, AL",
    city_name: "Hardaway",
  },
  {
    postal_code: "58636",
    full_city_name: "Halliday, ND",
    city_name: "Halliday",
  },
  {
    postal_code: "81033",
    full_city_name: "Crowley, CO",
    city_name: "Crowley",
  },
  {
    postal_code: "12924",
    full_city_name: "Keeseville, NY",
    city_name: "Keeseville",
  },
  {
    postal_code: "62815",
    full_city_name: "Bone Gap, IL",
    city_name: "Bone Gap",
  },
  {
    postal_code: "91978",
    full_city_name: "Spring Valley, CA",
    city_name: "Spring Valley",
  },
  {
    postal_code: "71430",
    full_city_name: "Forest Hill, LA",
    city_name: "Forest Hill",
  },
  {
    postal_code: "36858",
    full_city_name: "Hatchechubbee, AL",
    city_name: "Hatchechubbee",
  },
  {
    postal_code: "49052",
    full_city_name: "Fulton, MI",
    city_name: "Fulton",
  },
  {
    postal_code: "73857",
    full_city_name: "Sharon, OK",
    city_name: "Sharon",
  },
  {
    postal_code: "54601",
    full_city_name: "La Crosse, WI",
    city_name: "La Crosse",
  },
  {
    postal_code: "85601",
    full_city_name: "Arivaca, AZ",
    city_name: "Arivaca",
  },
  {
    postal_code: "16661",
    full_city_name: "Madera, PA",
    city_name: "Madera",
  },
  {
    postal_code: "30630",
    full_city_name: "Crawford, GA",
    city_name: "Crawford",
  },
  {
    postal_code: "60959",
    full_city_name: "Piper City, IL",
    city_name: "Piper City",
  },
  {
    postal_code: "56218",
    full_city_name: "Boyd, MN",
    city_name: "Boyd",
  },
  {
    postal_code: "45711",
    full_city_name: "Amesville, OH",
    city_name: "Amesville",
  },
  {
    postal_code: "74875",
    full_city_name: "Tryon, OK",
    city_name: "Tryon",
  },
  {
    postal_code: "41759",
    full_city_name: "Sassafras, KY",
    city_name: "Sassafras",
  },
  {
    postal_code: "16926",
    full_city_name: "Granville Summit, PA",
    city_name: "Granville Summit",
  },
  {
    postal_code: "72111",
    full_city_name: "Mount Vernon, AR",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "14415",
    full_city_name: "Bellona, NY",
    city_name: "Bellona",
  },
  {
    postal_code: "04491",
    full_city_name: "Vanceboro, ME",
    city_name: "Vanceboro",
  },
  {
    postal_code: "51558",
    full_city_name: "Moorhead, IA",
    city_name: "Moorhead",
  },
  {
    postal_code: "99567",
    full_city_name: "Chugiak, AK",
    city_name: "Chugiak",
  },
  {
    postal_code: "20687",
    full_city_name: "Scotland, MD",
    city_name: "Scotland",
  },
  {
    postal_code: "15322",
    full_city_name: "Clarksville, PA",
    city_name: "Clarksville",
  },
  {
    postal_code: "56209",
    full_city_name: "Atwater, MN",
    city_name: "Atwater",
  },
  {
    postal_code: "72101",
    full_city_name: "Mc Crory, AR",
    city_name: "Mc Crory",
  },
  {
    postal_code: "56211",
    full_city_name: "Beardsley, MN",
    city_name: "Beardsley",
  },
  {
    postal_code: "24599",
    full_city_name: "Wingina, VA",
    city_name: "Wingina",
  },
  {
    postal_code: "72157",
    full_city_name: "Springfield, AR",
    city_name: "Springfield",
  },
  {
    postal_code: "87064",
    full_city_name: "Youngsville, NM",
    city_name: "Youngsville",
  },
  {
    postal_code: "72677",
    full_city_name: "Summit, AR",
    city_name: "Summit",
  },
  {
    postal_code: "25244",
    full_city_name: "Gay, WV",
    city_name: "Gay",
  },
  {
    postal_code: "17364",
    full_city_name: "Thomasville, PA",
    city_name: "Thomasville",
  },
  {
    postal_code: "63876",
    full_city_name: "Senath, MO",
    city_name: "Senath",
  },
  {
    postal_code: "68784",
    full_city_name: "Wakefield, NE",
    city_name: "Wakefield",
  },
  {
    postal_code: "42462",
    full_city_name: "Waverly, KY",
    city_name: "Waverly",
  },
  {
    postal_code: "71859",
    full_city_name: "Saratoga, AR",
    city_name: "Saratoga",
  },
  {
    postal_code: "72414",
    full_city_name: "Black Oak, AR",
    city_name: "Black Oak",
  },
  {
    postal_code: "60929",
    full_city_name: "Cullom, IL",
    city_name: "Cullom",
  },
  {
    postal_code: "74880",
    full_city_name: "Weleetka, OK",
    city_name: "Weleetka",
  },
  {
    postal_code: "54003",
    full_city_name: "Beldenville, WI",
    city_name: "Beldenville",
  },
  {
    postal_code: "73835",
    full_city_name: "Camargo, OK",
    city_name: "Camargo",
  },
  {
    postal_code: "56742",
    full_city_name: "Oklee, MN",
    city_name: "Oklee",
  },
  {
    postal_code: "31790",
    full_city_name: "Sycamore, GA",
    city_name: "Sycamore",
  },
  {
    postal_code: "97869",
    full_city_name: "Prairie City, OR",
    city_name: "Prairie City",
  },
  {
    postal_code: "72923",
    full_city_name: "Barling, AR",
    city_name: "Barling",
  },
  {
    postal_code: "45111",
    full_city_name: "Camp Dennison, OH",
    city_name: "Camp Dennison",
  },
  {
    postal_code: "84766",
    full_city_name: "Sevier, UT",
    city_name: "Sevier",
  },
  {
    postal_code: "41828",
    full_city_name: "Kite, KY",
    city_name: "Kite",
  },
  {
    postal_code: "45860",
    full_city_name: "Maria Stein, OH",
    city_name: "Maria Stein",
  },
  {
    postal_code: "72628",
    full_city_name: "Deer, AR",
    city_name: "Deer",
  },
  {
    postal_code: "15746",
    full_city_name: "Hillsdale, PA",
    city_name: "Hillsdale",
  },
  {
    postal_code: "23856",
    full_city_name: "Freeman, VA",
    city_name: "Freeman",
  },
  {
    postal_code: "69149",
    full_city_name: "Lodgepole, NE",
    city_name: "Lodgepole",
  },
  {
    postal_code: "52305",
    full_city_name: "Martelle, IA",
    city_name: "Martelle",
  },
  {
    postal_code: "86411",
    full_city_name: "Hackberry, AZ",
    city_name: "Hackberry",
  },
  {
    postal_code: "97451",
    full_city_name: "Lorane, OR",
    city_name: "Lorane",
  },
  {
    postal_code: "76360",
    full_city_name: "Electra, TX",
    city_name: "Electra",
  },
  {
    postal_code: "39054",
    full_city_name: "Cary, MS",
    city_name: "Cary",
  },
  {
    postal_code: "99762",
    full_city_name: "Nome, AK",
    city_name: "Nome",
  },
  {
    postal_code: "56741",
    full_city_name: "Oak Island, MN",
    city_name: "Oak Island",
  },
  {
    postal_code: "56328",
    full_city_name: "Flensburg, MN",
    city_name: "Flensburg",
  },
  {
    postal_code: "51044",
    full_city_name: "Oto, IA",
    city_name: "Oto",
  },
  {
    postal_code: "58475",
    full_city_name: "Pettibone, ND",
    city_name: "Pettibone",
  },
  {
    postal_code: "52352",
    full_city_name: "Walker, IA",
    city_name: "Walker",
  },
  {
    postal_code: "57050",
    full_city_name: "Nunda, SD",
    city_name: "Nunda",
  },
  {
    postal_code: "51343",
    full_city_name: "Greenville, IA",
    city_name: "Greenville",
  },
  {
    postal_code: "83670",
    full_city_name: "Sweet, ID",
    city_name: "Sweet",
  },
  {
    postal_code: "88431",
    full_city_name: "Newkirk, NM",
    city_name: "Newkirk",
  },
  {
    postal_code: "17040",
    full_city_name: "Landisburg, PA",
    city_name: "Landisburg",
  },
  {
    postal_code: "59416",
    full_city_name: "Brady, MT",
    city_name: "Brady",
  },
  {
    postal_code: "15341",
    full_city_name: "Holbrook, PA",
    city_name: "Holbrook",
  },
  {
    postal_code: "31091",
    full_city_name: "Unadilla, GA",
    city_name: "Unadilla",
  },
  {
    postal_code: "48434",
    full_city_name: "Forestville, MI",
    city_name: "Forestville",
  },
  {
    postal_code: "75968",
    full_city_name: "Pineland, TX",
    city_name: "Pineland",
  },
  {
    postal_code: "66769",
    full_city_name: "Redfield, KS",
    city_name: "Redfield",
  },
  {
    postal_code: "58643",
    full_city_name: "Marmarth, ND",
    city_name: "Marmarth",
  },
  {
    postal_code: "36792",
    full_city_name: "Randolph, AL",
    city_name: "Randolph",
  },
  {
    postal_code: "97538",
    full_city_name: "Selma, OR",
    city_name: "Selma",
  },
  {
    postal_code: "46110",
    full_city_name: "Boggstown, IN",
    city_name: "Boggstown",
  },
  {
    postal_code: "62856",
    full_city_name: "Logan, IL",
    city_name: "Logan",
  },
  {
    postal_code: "21052",
    full_city_name: "Fort Howard, MD",
    city_name: "Fort Howard",
  },
  {
    postal_code: "97636",
    full_city_name: "Paisley, OR",
    city_name: "Paisley",
  },
  {
    postal_code: "25019",
    full_city_name: "Bickmore, WV",
    city_name: "Bickmore",
  },
  {
    postal_code: "14839",
    full_city_name: "Greenwood, NY",
    city_name: "Greenwood",
  },
  {
    postal_code: "20611",
    full_city_name: "Bel Alton, MD",
    city_name: "Bel Alton",
  },
  {
    postal_code: "16684",
    full_city_name: "Tipton, PA",
    city_name: "Tipton",
  },
  {
    postal_code: "27242",
    full_city_name: "Eagle Springs, NC",
    city_name: "Eagle Springs",
  },
  {
    postal_code: "13682",
    full_city_name: "Rodman, NY",
    city_name: "Rodman",
  },
  {
    postal_code: "57057",
    full_city_name: "Rutland, SD",
    city_name: "Rutland",
  },
  {
    postal_code: "19960",
    full_city_name: "Lincoln, DE",
    city_name: "Lincoln",
  },
  {
    postal_code: "62051",
    full_city_name: "Irving, IL",
    city_name: "Irving",
  },
  {
    postal_code: "73004",
    full_city_name: "Amber, OK",
    city_name: "Amber",
  },
  {
    postal_code: "67134",
    full_city_name: "Sawyer, KS",
    city_name: "Sawyer",
  },
  {
    postal_code: "16333",
    full_city_name: "Ludlow, PA",
    city_name: "Ludlow",
  },
  {
    postal_code: "48618",
    full_city_name: "Coleman, MI",
    city_name: "Coleman",
  },
  {
    postal_code: "62808",
    full_city_name: "Ashley, IL",
    city_name: "Ashley",
  },
  {
    postal_code: "14802",
    full_city_name: "Alfred, NY",
    city_name: "Alfred",
  },
  {
    postal_code: "68342",
    full_city_name: "Diller, NE",
    city_name: "Diller",
  },
  {
    postal_code: "47455",
    full_city_name: "Patricksburg, IN",
    city_name: "Patricksburg",
  },
  {
    postal_code: "12863",
    full_city_name: "Rock City Falls, NY",
    city_name: "Rock City Falls",
  },
  {
    postal_code: "14604",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "79014",
    full_city_name: "Canadian, TX",
    city_name: "Canadian",
  },
  {
    postal_code: "67159",
    full_city_name: "Zenda, KS",
    city_name: "Zenda",
  },
  {
    postal_code: "31549",
    full_city_name: "Lumber City, GA",
    city_name: "Lumber City",
  },
  {
    postal_code: "95511",
    full_city_name: "Alderpoint, CA",
    city_name: "Alderpoint",
  },
  {
    postal_code: "50103",
    full_city_name: "Garden Grove, IA",
    city_name: "Garden Grove",
  },
  {
    postal_code: "72520",
    full_city_name: "Camp, AR",
    city_name: "Camp",
  },
  {
    postal_code: "40953",
    full_city_name: "Hinkle, KY",
    city_name: "Hinkle",
  },
  {
    postal_code: "28341",
    full_city_name: "Faison, NC",
    city_name: "Faison",
  },
  {
    postal_code: "17980",
    full_city_name: "Tower City, PA",
    city_name: "Tower City",
  },
  {
    postal_code: "26134",
    full_city_name: "Belmont, WV",
    city_name: "Belmont",
  },
  {
    postal_code: "15360",
    full_city_name: "Scenery Hill, PA",
    city_name: "Scenery Hill",
  },
  {
    postal_code: "56113",
    full_city_name: "Arco, MN",
    city_name: "Arco",
  },
  {
    postal_code: "97335",
    full_city_name: "Crabtree, OR",
    city_name: "Crabtree",
  },
  {
    postal_code: "52635",
    full_city_name: "Lockridge, IA",
    city_name: "Lockridge",
  },
  {
    postal_code: "41365",
    full_city_name: "Rogers, KY",
    city_name: "Rogers",
  },
  {
    postal_code: "57748",
    full_city_name: "Howes, SD",
    city_name: "Howes",
  },
  {
    postal_code: "17229",
    full_city_name: "Hustontown, PA",
    city_name: "Hustontown",
  },
  {
    postal_code: "04434",
    full_city_name: "Etna, ME",
    city_name: "Etna",
  },
  {
    postal_code: "68360",
    full_city_name: "Garland, NE",
    city_name: "Garland",
  },
  {
    postal_code: "13488",
    full_city_name: "Westford, NY",
    city_name: "Westford",
  },
  {
    postal_code: "95230",
    full_city_name: "Farmington, CA",
    city_name: "Farmington",
  },
  {
    postal_code: "17017",
    full_city_name: "Dalmatia, PA",
    city_name: "Dalmatia",
  },
  {
    postal_code: "38251",
    full_city_name: "Puryear, TN",
    city_name: "Puryear",
  },
  {
    postal_code: "29929",
    full_city_name: "Islandton, SC",
    city_name: "Islandton",
  },
  {
    postal_code: "42333",
    full_city_name: "Cromwell, KY",
    city_name: "Cromwell",
  },
  {
    postal_code: "75413",
    full_city_name: "Bailey, TX",
    city_name: "Bailey",
  },
  {
    postal_code: "57436",
    full_city_name: "Doland, SD",
    city_name: "Doland",
  },
  {
    postal_code: "62820",
    full_city_name: "Burnt Prairie, IL",
    city_name: "Burnt Prairie",
  },
  {
    postal_code: "46747",
    full_city_name: "Hudson, IN",
    city_name: "Hudson",
  },
  {
    postal_code: "95714",
    full_city_name: "Dutch Flat, CA",
    city_name: "Dutch Flat",
  },
  {
    postal_code: "72760",
    full_city_name: "Saint Paul, AR",
    city_name: "Saint Paul",
  },
  {
    postal_code: "48615",
    full_city_name: "Breckenridge, MI",
    city_name: "Breckenridge",
  },
  {
    postal_code: "84512",
    full_city_name: "Bluff, UT",
    city_name: "Bluff",
  },
  {
    postal_code: "23022",
    full_city_name: "Bremo Bluff, VA",
    city_name: "Bremo Bluff",
  },
  {
    postal_code: "38573",
    full_city_name: "Monroe, TN",
    city_name: "Monroe",
  },
  {
    postal_code: "47597",
    full_city_name: "Wheatland, IN",
    city_name: "Wheatland",
  },
  {
    postal_code: "41763",
    full_city_name: "Slemp, KY",
    city_name: "Slemp",
  },
  {
    postal_code: "55929",
    full_city_name: "Dover, MN",
    city_name: "Dover",
  },
  {
    postal_code: "52046",
    full_city_name: "Farley, IA",
    city_name: "Farley",
  },
  {
    postal_code: "64848",
    full_city_name: "La Russell, MO",
    city_name: "La Russell",
  },
  {
    postal_code: "38567",
    full_city_name: "Hickman, TN",
    city_name: "Hickman",
  },
  {
    postal_code: "26151",
    full_city_name: "Mount Zion, WV",
    city_name: "Mount Zion",
  },
  {
    postal_code: "26273",
    full_city_name: "Huttonsville, WV",
    city_name: "Huttonsville",
  },
  {
    postal_code: "97343",
    full_city_name: "Eddyville, OR",
    city_name: "Eddyville",
  },
  {
    postal_code: "73447",
    full_city_name: "Mannsville, OK",
    city_name: "Mannsville",
  },
  {
    postal_code: "47992",
    full_city_name: "Westpoint, IN",
    city_name: "Westpoint",
  },
  {
    postal_code: "37365",
    full_city_name: "Palmer, TN",
    city_name: "Palmer",
  },
  {
    postal_code: "60479",
    full_city_name: "Verona, IL",
    city_name: "Verona",
  },
  {
    postal_code: "63373",
    full_city_name: "Portage Des Sioux, MO",
    city_name: "Portage Des Sioux",
  },
  {
    postal_code: "41723",
    full_city_name: "Busy, KY",
    city_name: "Busy",
  },
  {
    postal_code: "68727",
    full_city_name: "Coleridge, NE",
    city_name: "Coleridge",
  },
  {
    postal_code: "99160",
    full_city_name: "Orient, WA",
    city_name: "Orient",
  },
  {
    postal_code: "67525",
    full_city_name: "Claflin, KS",
    city_name: "Claflin",
  },
  {
    postal_code: "81331",
    full_city_name: "Pleasant View, CO",
    city_name: "Pleasant View",
  },
  {
    postal_code: "61485",
    full_city_name: "Victoria, IL",
    city_name: "Victoria",
  },
  {
    postal_code: "84637",
    full_city_name: "Kanosh, UT",
    city_name: "Kanosh",
  },
  {
    postal_code: "53953",
    full_city_name: "Packwaukee, WI",
    city_name: "Packwaukee",
  },
  {
    postal_code: "73543",
    full_city_name: "Geronimo, OK",
    city_name: "Geronimo",
  },
  {
    postal_code: "36916",
    full_city_name: "Pennington, AL",
    city_name: "Pennington",
  },
  {
    postal_code: "97633",
    full_city_name: "Merrill, OR",
    city_name: "Merrill",
  },
  {
    postal_code: "57761",
    full_city_name: "New Underwood, SD",
    city_name: "New Underwood",
  },
  {
    postal_code: "75693",
    full_city_name: "White Oak, TX",
    city_name: "White Oak",
  },
  {
    postal_code: "48721",
    full_city_name: "Black River, MI",
    city_name: "Black River",
  },
  {
    postal_code: "36861",
    full_city_name: "Jacksons Gap, AL",
    city_name: "Jacksons Gap",
  },
  {
    postal_code: "76464",
    full_city_name: "Moran, TX",
    city_name: "Moran",
  },
  {
    postal_code: "05660",
    full_city_name: "Moretown, VT",
    city_name: "Moretown",
  },
  {
    postal_code: "37333",
    full_city_name: "Farner, TN",
    city_name: "Farner",
  },
  {
    postal_code: "49782",
    full_city_name: "Beaver Island, MI",
    city_name: "Beaver Island",
  },
  {
    postal_code: "61359",
    full_city_name: "Seatonville, IL",
    city_name: "Seatonville",
  },
  {
    postal_code: "62466",
    full_city_name: "Sumner, IL",
    city_name: "Sumner",
  },
  {
    postal_code: "58275",
    full_city_name: "Reynolds, ND",
    city_name: "Reynolds",
  },
  {
    postal_code: "55962",
    full_city_name: "Peterson, MN",
    city_name: "Peterson",
  },
  {
    postal_code: "33867",
    full_city_name: "River Ranch, FL",
    city_name: "River Ranch",
  },
  {
    postal_code: "26629",
    full_city_name: "Little Birch, WV",
    city_name: "Little Birch",
  },
  {
    postal_code: "29355",
    full_city_name: "Kinards, SC",
    city_name: "Kinards",
  },
  {
    postal_code: "46786",
    full_city_name: "South Milford, IN",
    city_name: "South Milford",
  },
  {
    postal_code: "86018",
    full_city_name: "Parks, AZ",
    city_name: "Parks",
  },
  {
    postal_code: "53919",
    full_city_name: "Brandon, WI",
    city_name: "Brandon",
  },
  {
    postal_code: "72128",
    full_city_name: "Poyen, AR",
    city_name: "Poyen",
  },
  {
    postal_code: "72436",
    full_city_name: "Lafe, AR",
    city_name: "Lafe",
  },
  {
    postal_code: "47160",
    full_city_name: "New Middletown, IN",
    city_name: "New Middletown",
  },
  {
    postal_code: "53019",
    full_city_name: "Eden, WI",
    city_name: "Eden",
  },
  {
    postal_code: "76885",
    full_city_name: "Valley Spring, TX",
    city_name: "Valley Spring",
  },
  {
    postal_code: "25567",
    full_city_name: "Sumerco, WV",
    city_name: "Sumerco",
  },
  {
    postal_code: "45340",
    full_city_name: "Maplewood, OH",
    city_name: "Maplewood",
  },
  {
    postal_code: "61089",
    full_city_name: "Winslow, IL",
    city_name: "Winslow",
  },
  {
    postal_code: "06091",
    full_city_name: "West Hartland, CT",
    city_name: "West Hartland",
  },
  {
    postal_code: "58479",
    full_city_name: "Rogers, ND",
    city_name: "Rogers",
  },
  {
    postal_code: "40020",
    full_city_name: "Fairfield, KY",
    city_name: "Fairfield",
  },
  {
    postal_code: "56376",
    full_city_name: "Saint Martin, MN",
    city_name: "Saint Martin",
  },
  {
    postal_code: "35576",
    full_city_name: "Millport, AL",
    city_name: "Millport",
  },
  {
    postal_code: "45861",
    full_city_name: "Melrose, OH",
    city_name: "Melrose",
  },
  {
    postal_code: "47923",
    full_city_name: "Brookston, IN",
    city_name: "Brookston",
  },
  {
    postal_code: "58021",
    full_city_name: "Davenport, ND",
    city_name: "Davenport",
  },
  {
    postal_code: "64088",
    full_city_name: "Sibley, MO",
    city_name: "Sibley",
  },
  {
    postal_code: "15730",
    full_city_name: "Coolspring, PA",
    city_name: "Coolspring",
  },
  {
    postal_code: "26283",
    full_city_name: "Montrose, WV",
    city_name: "Montrose",
  },
  {
    postal_code: "45876",
    full_city_name: "Ottoville, OH",
    city_name: "Ottoville",
  },
  {
    postal_code: "40979",
    full_city_name: "Roark, KY",
    city_name: "Roark",
  },
  {
    postal_code: "72865",
    full_city_name: "Subiaco, AR",
    city_name: "Subiaco",
  },
  {
    postal_code: "84023",
    full_city_name: "Dutch John, UT",
    city_name: "Dutch John",
  },
  {
    postal_code: "50836",
    full_city_name: "Blockton, IA",
    city_name: "Blockton",
  },
  {
    postal_code: "47117",
    full_city_name: "Elizabeth, IN",
    city_name: "Elizabeth",
  },
  {
    postal_code: "26678",
    full_city_name: "Mount Lookout, WV",
    city_name: "Mount Lookout",
  },
  {
    postal_code: "50681",
    full_city_name: "Westgate, IA",
    city_name: "Westgate",
  },
  {
    postal_code: "83543",
    full_city_name: "Nezperce, ID",
    city_name: "Nezperce",
  },
  {
    postal_code: "17519",
    full_city_name: "East Earl, PA",
    city_name: "East Earl",
  },
  {
    postal_code: "67355",
    full_city_name: "Niotaze, KS",
    city_name: "Niotaze",
  },
  {
    postal_code: "99825",
    full_city_name: "Elfin Cove, AK",
    city_name: "Elfin Cove",
  },
  {
    postal_code: "52760",
    full_city_name: "Moscow, IA",
    city_name: "Moscow",
  },
  {
    postal_code: "29591",
    full_city_name: "Scranton, SC",
    city_name: "Scranton",
  },
  {
    postal_code: "72546",
    full_city_name: "Ida, AR",
    city_name: "Ida",
  },
  {
    postal_code: "38452",
    full_city_name: "Cypress Inn, TN",
    city_name: "Cypress Inn",
  },
  {
    postal_code: "79847",
    full_city_name: "Salt Flat, TX",
    city_name: "Salt Flat",
  },
  {
    postal_code: "81058",
    full_city_name: "Manzanola, CO",
    city_name: "Manzanola",
  },
  {
    postal_code: "17967",
    full_city_name: "Ringtown, PA",
    city_name: "Ringtown",
  },
  {
    postal_code: "59916",
    full_city_name: "Essex, MT",
    city_name: "Essex",
  },
  {
    postal_code: "63447",
    full_city_name: "La Belle, MO",
    city_name: "La Belle",
  },
  {
    postal_code: "71004",
    full_city_name: "Belcher, LA",
    city_name: "Belcher",
  },
  {
    postal_code: "76955",
    full_city_name: "Vancourt, TX",
    city_name: "Vancourt",
  },
  {
    postal_code: "62810",
    full_city_name: "Belle Rive, IL",
    city_name: "Belle Rive",
  },
  {
    postal_code: "87567",
    full_city_name: "Santa Cruz, NM",
    city_name: "Santa Cruz",
  },
  {
    postal_code: "76878",
    full_city_name: "Santa Anna, TX",
    city_name: "Santa Anna",
  },
  {
    postal_code: "46961",
    full_city_name: "New Waverly, IN",
    city_name: "New Waverly",
  },
  {
    postal_code: "05734",
    full_city_name: "Bridport, VT",
    city_name: "Bridport",
  },
  {
    postal_code: "23934",
    full_city_name: "Cullen, VA",
    city_name: "Cullen",
  },
  {
    postal_code: "62092",
    full_city_name: "White Hall, IL",
    city_name: "White Hall",
  },
  {
    postal_code: "25635",
    full_city_name: "Man, WV",
    city_name: "Man",
  },
  {
    postal_code: "50005",
    full_city_name: "Albion, IA",
    city_name: "Albion",
  },
  {
    postal_code: "73764",
    full_city_name: "Omega, OK",
    city_name: "Omega",
  },
  {
    postal_code: "56180",
    full_city_name: "Walnut Grove, MN",
    city_name: "Walnut Grove",
  },
  {
    postal_code: "54550",
    full_city_name: "Montreal, WI",
    city_name: "Montreal",
  },
  {
    postal_code: "38577",
    full_city_name: "Pall Mall, TN",
    city_name: "Pall Mall",
  },
  {
    postal_code: "24412",
    full_city_name: "Bacova, VA",
    city_name: "Bacova",
  },
  {
    postal_code: "76481",
    full_city_name: "South Bend, TX",
    city_name: "South Bend",
  },
  {
    postal_code: "01224",
    full_city_name: "Berkshire, MA",
    city_name: "Berkshire",
  },
  {
    postal_code: "15695",
    full_city_name: "Wyano, PA",
    city_name: "Wyano",
  },
  {
    postal_code: "14709",
    full_city_name: "Angelica, NY",
    city_name: "Angelica",
  },
  {
    postal_code: "37872",
    full_city_name: "Sunbright, TN",
    city_name: "Sunbright",
  },
  {
    postal_code: "18225",
    full_city_name: "Harleigh, PA",
    city_name: "Harleigh",
  },
  {
    postal_code: "03448",
    full_city_name: "Gilsum, NH",
    city_name: "Gilsum",
  },
  {
    postal_code: "95560",
    full_city_name: "Redway, CA",
    city_name: "Redway",
  },
  {
    postal_code: "04616",
    full_city_name: "Brooklin, ME",
    city_name: "Brooklin",
  },
  {
    postal_code: "26264",
    full_city_name: "Durbin, WV",
    city_name: "Durbin",
  },
  {
    postal_code: "58744",
    full_city_name: "Karlsruhe, ND",
    city_name: "Karlsruhe",
  },
  {
    postal_code: "51523",
    full_city_name: "Blencoe, IA",
    city_name: "Blencoe",
  },
  {
    postal_code: "67557",
    full_city_name: "Macksville, KS",
    city_name: "Macksville",
  },
  {
    postal_code: "36511",
    full_city_name: "Bon Secour, AL",
    city_name: "Bon Secour",
  },
  {
    postal_code: "63388",
    full_city_name: "Williamsburg, MO",
    city_name: "Williamsburg",
  },
  {
    postal_code: "66042",
    full_city_name: "Lane, KS",
    city_name: "Lane",
  },
  {
    postal_code: "49894",
    full_city_name: "Wells, MI",
    city_name: "Wells",
  },
  {
    postal_code: "24579",
    full_city_name: "Natural Bridge Station, VA",
    city_name: "Natural Bridge Station",
  },
  {
    postal_code: "56091",
    full_city_name: "Waldorf, MN",
    city_name: "Waldorf",
  },
  {
    postal_code: "68767",
    full_city_name: "Pierce, NE",
    city_name: "Pierce",
  },
  {
    postal_code: "62340",
    full_city_name: "Griggsville, IL",
    city_name: "Griggsville",
  },
  {
    postal_code: "26346",
    full_city_name: "Ellenboro, WV",
    city_name: "Ellenboro",
  },
  {
    postal_code: "97350",
    full_city_name: "Idanha, OR",
    city_name: "Idanha",
  },
  {
    postal_code: "72640",
    full_city_name: "Hasty, AR",
    city_name: "Hasty",
  },
  {
    postal_code: "36031",
    full_city_name: "Fort Davis, AL",
    city_name: "Fort Davis",
  },
  {
    postal_code: "65051",
    full_city_name: "Linn, MO",
    city_name: "Linn",
  },
  {
    postal_code: "27208",
    full_city_name: "Bennett, NC",
    city_name: "Bennett",
  },
  {
    postal_code: "52133",
    full_city_name: "Castalia, IA",
    city_name: "Castalia",
  },
  {
    postal_code: "61541",
    full_city_name: "La Rose, IL",
    city_name: "La Rose",
  },
  {
    postal_code: "19964",
    full_city_name: "Marydel, DE",
    city_name: "Marydel",
  },
  {
    postal_code: "50052",
    full_city_name: "Clio, IA",
    city_name: "Clio",
  },
  {
    postal_code: "15738",
    full_city_name: "Emeigh, PA",
    city_name: "Emeigh",
  },
  {
    postal_code: "47020",
    full_city_name: "Florence, IN",
    city_name: "Florence",
  },
  {
    postal_code: "47024",
    full_city_name: "Laurel, IN",
    city_name: "Laurel",
  },
  {
    postal_code: "41544",
    full_city_name: "Mc Carr, KY",
    city_name: "Mc Carr",
  },
  {
    postal_code: "35540",
    full_city_name: "Addison, AL",
    city_name: "Addison",
  },
  {
    postal_code: "39153",
    full_city_name: "Raleigh, MS",
    city_name: "Raleigh",
  },
  {
    postal_code: "08867",
    full_city_name: "Pittstown, NJ",
    city_name: "Pittstown",
  },
  {
    postal_code: "62063",
    full_city_name: "Medora, IL",
    city_name: "Medora",
  },
  {
    postal_code: "81222",
    full_city_name: "Coaldale, CO",
    city_name: "Coaldale",
  },
  {
    postal_code: "04987",
    full_city_name: "Troy, ME",
    city_name: "Troy",
  },
  {
    postal_code: "17739",
    full_city_name: "Jersey Mills, PA",
    city_name: "Jersey Mills",
  },
  {
    postal_code: "68821",
    full_city_name: "Brewster, NE",
    city_name: "Brewster",
  },
  {
    postal_code: "26845",
    full_city_name: "Old Fields, WV",
    city_name: "Old Fields",
  },
  {
    postal_code: "89421",
    full_city_name: "Mc Dermitt, NV",
    city_name: "Mc Dermitt",
  },
  {
    postal_code: "36749",
    full_city_name: "Jones, AL",
    city_name: "Jones",
  },
  {
    postal_code: "22940",
    full_city_name: "Free Union, VA",
    city_name: "Free Union",
  },
  {
    postal_code: "12770",
    full_city_name: "Pond Eddy, NY",
    city_name: "Pond Eddy",
  },
  {
    postal_code: "72827",
    full_city_name: "Bluffton, AR",
    city_name: "Bluffton",
  },
  {
    postal_code: "97145",
    full_city_name: "Tolovana Park, OR",
    city_name: "Tolovana Park",
  },
  {
    postal_code: "61859",
    full_city_name: "Ogden, IL",
    city_name: "Ogden",
  },
  {
    postal_code: "04471",
    full_city_name: "Orient, ME",
    city_name: "Orient",
  },
  {
    postal_code: "08832",
    full_city_name: "Keasbey, NJ",
    city_name: "Keasbey",
  },
  {
    postal_code: "14545",
    full_city_name: "Scottsburg, NY",
    city_name: "Scottsburg",
  },
  {
    postal_code: "76820",
    full_city_name: "Art, TX",
    city_name: "Art",
  },
  {
    postal_code: "20860",
    full_city_name: "Sandy Spring, MD",
    city_name: "Sandy Spring",
  },
  {
    postal_code: "77951",
    full_city_name: "Bloomington, TX",
    city_name: "Bloomington",
  },
  {
    postal_code: "47030",
    full_city_name: "Metamora, IN",
    city_name: "Metamora",
  },
  {
    postal_code: "21627",
    full_city_name: "Crocheron, MD",
    city_name: "Crocheron",
  },
  {
    postal_code: "67336",
    full_city_name: "Chetopa, KS",
    city_name: "Chetopa",
  },
  {
    postal_code: "62536",
    full_city_name: "Glenarm, IL",
    city_name: "Glenarm",
  },
  {
    postal_code: "22736",
    full_city_name: "Richardsville, VA",
    city_name: "Richardsville",
  },
  {
    postal_code: "49927",
    full_city_name: "Gaastra, MI",
    city_name: "Gaastra",
  },
  {
    postal_code: "96086",
    full_city_name: "Seiad Valley, CA",
    city_name: "Seiad Valley",
  },
  {
    postal_code: "55970",
    full_city_name: "Rose Creek, MN",
    city_name: "Rose Creek",
  },
  {
    postal_code: "07033",
    full_city_name: "Kenilworth, NJ",
    city_name: "Kenilworth",
  },
  {
    postal_code: "71362",
    full_city_name: "Plaucheville, LA",
    city_name: "Plaucheville",
  },
  {
    postal_code: "14736",
    full_city_name: "Findley Lake, NY",
    city_name: "Findley Lake",
  },
  {
    postal_code: "63557",
    full_city_name: "New Boston, MO",
    city_name: "New Boston",
  },
  {
    postal_code: "04237",
    full_city_name: "Hanover, ME",
    city_name: "Hanover",
  },
  {
    postal_code: "88121",
    full_city_name: "House, NM",
    city_name: "House",
  },
  {
    postal_code: "46797",
    full_city_name: "Woodburn, IN",
    city_name: "Woodburn",
  },
  {
    postal_code: "50528",
    full_city_name: "Cylinder, IA",
    city_name: "Cylinder",
  },
  {
    postal_code: "61484",
    full_city_name: "Vermont, IL",
    city_name: "Vermont",
  },
  {
    postal_code: "31712",
    full_city_name: "Arabi, GA",
    city_name: "Arabi",
  },
  {
    postal_code: "70581",
    full_city_name: "Roanoke, LA",
    city_name: "Roanoke",
  },
  {
    postal_code: "13020",
    full_city_name: "Apulia Station, NY",
    city_name: "Apulia Station",
  },
  {
    postal_code: "24269",
    full_city_name: "Mc Clure, VA",
    city_name: "Mc Clure",
  },
  {
    postal_code: "03279",
    full_city_name: "Warren, NH",
    city_name: "Warren",
  },
  {
    postal_code: "84046",
    full_city_name: "Manila, UT",
    city_name: "Manila",
  },
  {
    postal_code: "97429",
    full_city_name: "Days Creek, OR",
    city_name: "Days Creek",
  },
  {
    postal_code: "72648",
    full_city_name: "Marble Falls, AR",
    city_name: "Marble Falls",
  },
  {
    postal_code: "73570",
    full_city_name: "Tipton, OK",
    city_name: "Tipton",
  },
  {
    postal_code: "76635",
    full_city_name: "Coolidge, TX",
    city_name: "Coolidge",
  },
  {
    postal_code: "16864",
    full_city_name: "Orviston, PA",
    city_name: "Orviston",
  },
  {
    postal_code: "51060",
    full_city_name: "Ute, IA",
    city_name: "Ute",
  },
  {
    postal_code: "40921",
    full_city_name: "Bryants Store, KY",
    city_name: "Bryants Store",
  },
  {
    postal_code: "87743",
    full_city_name: "Roy, NM",
    city_name: "Roy",
  },
  {
    postal_code: "21521",
    full_city_name: "Barton, MD",
    city_name: "Barton",
  },
  {
    postal_code: "12726",
    full_city_name: "Cochecton, NY",
    city_name: "Cochecton",
  },
  {
    postal_code: "15729",
    full_city_name: "Commodore, PA",
    city_name: "Commodore",
  },
  {
    postal_code: "63560",
    full_city_name: "Pollock, MO",
    city_name: "Pollock",
  },
  {
    postal_code: "61475",
    full_city_name: "Sciota, IL",
    city_name: "Sciota",
  },
  {
    postal_code: "54536",
    full_city_name: "Iron Belt, WI",
    city_name: "Iron Belt",
  },
  {
    postal_code: "61473",
    full_city_name: "Roseville, IL",
    city_name: "Roseville",
  },
  {
    postal_code: "23418",
    full_city_name: "Onley, VA",
    city_name: "Onley",
  },
  {
    postal_code: "76454",
    full_city_name: "Gorman, TX",
    city_name: "Gorman",
  },
  {
    postal_code: "35805",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "84715",
    full_city_name: "Bicknell, UT",
    city_name: "Bicknell",
  },
  {
    postal_code: "57445",
    full_city_name: "Groton, SD",
    city_name: "Groton",
  },
  {
    postal_code: "33930",
    full_city_name: "Felda, FL",
    city_name: "Felda",
  },
  {
    postal_code: "71238",
    full_city_name: "Eros, LA",
    city_name: "Eros",
  },
  {
    postal_code: "77967",
    full_city_name: "Hochheim, TX",
    city_name: "Hochheim",
  },
  {
    postal_code: "63846",
    full_city_name: "Essex, MO",
    city_name: "Essex",
  },
  {
    postal_code: "72527",
    full_city_name: "Desha, AR",
    city_name: "Desha",
  },
  {
    postal_code: "71438",
    full_city_name: "Hineston, LA",
    city_name: "Hineston",
  },
  {
    postal_code: "25699",
    full_city_name: "Wilsondale, WV",
    city_name: "Wilsondale",
  },
  {
    postal_code: "97447",
    full_city_name: "Idleyld Park, OR",
    city_name: "Idleyld Park",
  },
  {
    postal_code: "24011",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "82063",
    full_city_name: "Jelm, WY",
    city_name: "Jelm",
  },
  {
    postal_code: "42762",
    full_city_name: "Millwood, KY",
    city_name: "Millwood",
  },
  {
    postal_code: "50554",
    full_city_name: "Laurens, IA",
    city_name: "Laurens",
  },
  {
    postal_code: "68638",
    full_city_name: "Fullerton, NE",
    city_name: "Fullerton",
  },
  {
    postal_code: "15545",
    full_city_name: "Hyndman, PA",
    city_name: "Hyndman",
  },
  {
    postal_code: "68314",
    full_city_name: "Bee, NE",
    city_name: "Bee",
  },
  {
    postal_code: "63547",
    full_city_name: "Hurdland, MO",
    city_name: "Hurdland",
  },
  {
    postal_code: "98670",
    full_city_name: "Wahkiacus, WA",
    city_name: "Wahkiacus",
  },
  {
    postal_code: "45351",
    full_city_name: "Osgood, OH",
    city_name: "Osgood",
  },
  {
    postal_code: "58781",
    full_city_name: "Sawyer, ND",
    city_name: "Sawyer",
  },
  {
    postal_code: "91917",
    full_city_name: "Dulzura, CA",
    city_name: "Dulzura",
  },
  {
    postal_code: "63566",
    full_city_name: "Winigan, MO",
    city_name: "Winigan",
  },
  {
    postal_code: "76852",
    full_city_name: "Lohn, TX",
    city_name: "Lohn",
  },
  {
    postal_code: "25169",
    full_city_name: "Ridgeview, WV",
    city_name: "Ridgeview",
  },
  {
    postal_code: "24974",
    full_city_name: "Secondcreek, WV",
    city_name: "Secondcreek",
  },
  {
    postal_code: "13319",
    full_city_name: "Chadwicks, NY",
    city_name: "Chadwicks",
  },
  {
    postal_code: "93932",
    full_city_name: "Lockwood, CA",
    city_name: "Lockwood",
  },
  {
    postal_code: "51049",
    full_city_name: "Quimby, IA",
    city_name: "Quimby",
  },
  {
    postal_code: "71965",
    full_city_name: "Pencil Bluff, AR",
    city_name: "Pencil Bluff",
  },
  {
    postal_code: "79039",
    full_city_name: "Groom, TX",
    city_name: "Groom",
  },
  {
    postal_code: "43843",
    full_city_name: "Walhonding, OH",
    city_name: "Walhonding",
  },
  {
    postal_code: "38736",
    full_city_name: "Doddsville, MS",
    city_name: "Doddsville",
  },
  {
    postal_code: "68947",
    full_city_name: "Hildreth, NE",
    city_name: "Hildreth",
  },
  {
    postal_code: "58581",
    full_city_name: "Zeeland, ND",
    city_name: "Zeeland",
  },
  {
    postal_code: "83120",
    full_city_name: "Freedom, WY",
    city_name: "Freedom",
  },
  {
    postal_code: "92230",
    full_city_name: "Cabazon, CA",
    city_name: "Cabazon",
  },
  {
    postal_code: "83827",
    full_city_name: "Elk River, ID",
    city_name: "Elk River",
  },
  {
    postal_code: "65274",
    full_city_name: "New Franklin, MO",
    city_name: "New Franklin",
  },
  {
    postal_code: "25501",
    full_city_name: "Alkol, WV",
    city_name: "Alkol",
  },
  {
    postal_code: "43942",
    full_city_name: "Powhatan Point, OH",
    city_name: "Powhatan Point",
  },
  {
    postal_code: "05652",
    full_city_name: "Eden, VT",
    city_name: "Eden",
  },
  {
    postal_code: "49864",
    full_city_name: "Nahma, MI",
    city_name: "Nahma",
  },
  {
    postal_code: "96062",
    full_city_name: "Millville, CA",
    city_name: "Millville",
  },
  {
    postal_code: "98813",
    full_city_name: "Bridgeport, WA",
    city_name: "Bridgeport",
  },
  {
    postal_code: "78932",
    full_city_name: "Carmine, TX",
    city_name: "Carmine",
  },
  {
    postal_code: "25649",
    full_city_name: "Verdunville, WV",
    city_name: "Verdunville",
  },
  {
    postal_code: "04691",
    full_city_name: "Whiting, ME",
    city_name: "Whiting",
  },
  {
    postal_code: "64451",
    full_city_name: "Forest City, MO",
    city_name: "Forest City",
  },
  {
    postal_code: "42044",
    full_city_name: "Gilbertsville, KY",
    city_name: "Gilbertsville",
  },
  {
    postal_code: "73853",
    full_city_name: "Mutual, OK",
    city_name: "Mutual",
  },
  {
    postal_code: "70552",
    full_city_name: "Loreauville, LA",
    city_name: "Loreauville",
  },
  {
    postal_code: "67025",
    full_city_name: "Cheney, KS",
    city_name: "Cheney",
  },
  {
    postal_code: "38236",
    full_city_name: "Mansfield, TN",
    city_name: "Mansfield",
  },
  {
    postal_code: "59544",
    full_city_name: "Whitewater, MT",
    city_name: "Whitewater",
  },
  {
    postal_code: "83834",
    full_city_name: "Harvard, ID",
    city_name: "Harvard",
  },
  {
    postal_code: "14740",
    full_city_name: "Gerry, NY",
    city_name: "Gerry",
  },
  {
    postal_code: "47163",
    full_city_name: "Otisco, IN",
    city_name: "Otisco",
  },
  {
    postal_code: "68724",
    full_city_name: "Center, NE",
    city_name: "Center",
  },
  {
    postal_code: "46978",
    full_city_name: "Royal Center, IN",
    city_name: "Royal Center",
  },
  {
    postal_code: "39361",
    full_city_name: "Shuqualak, MS",
    city_name: "Shuqualak",
  },
  {
    postal_code: "69343",
    full_city_name: "Gordon, NE",
    city_name: "Gordon",
  },
  {
    postal_code: "15841",
    full_city_name: "Force, PA",
    city_name: "Force",
  },
  {
    postal_code: "62091",
    full_city_name: "Walshville, IL",
    city_name: "Walshville",
  },
  {
    postal_code: "78884",
    full_city_name: "Utopia, TX",
    city_name: "Utopia",
  },
  {
    postal_code: "34762",
    full_city_name: "Okahumpka, FL",
    city_name: "Okahumpka",
  },
  {
    postal_code: "72572",
    full_city_name: "Saffell, AR",
    city_name: "Saffell",
  },
  {
    postal_code: "51530",
    full_city_name: "Earling, IA",
    city_name: "Earling",
  },
  {
    postal_code: "57065",
    full_city_name: "Trent, SD",
    city_name: "Trent",
  },
  {
    postal_code: "66058",
    full_city_name: "Muscotah, KS",
    city_name: "Muscotah",
  },
  {
    postal_code: "04973",
    full_city_name: "Searsmont, ME",
    city_name: "Searsmont",
  },
  {
    postal_code: "12487",
    full_city_name: "Ulster Park, NY",
    city_name: "Ulster Park",
  },
  {
    postal_code: "74052",
    full_city_name: "Oilton, OK",
    city_name: "Oilton",
  },
  {
    postal_code: "89054",
    full_city_name: "Sloan, NV",
    city_name: "Sloan",
  },
  {
    postal_code: "68944",
    full_city_name: "Harvard, NE",
    city_name: "Harvard",
  },
  {
    postal_code: "16332",
    full_city_name: "Lickingville, PA",
    city_name: "Lickingville",
  },
  {
    postal_code: "31076",
    full_city_name: "Reynolds, GA",
    city_name: "Reynolds",
  },
  {
    postal_code: "92364",
    full_city_name: "Nipton, CA",
    city_name: "Nipton",
  },
  {
    postal_code: "65715",
    full_city_name: "Noble, MO",
    city_name: "Noble",
  },
  {
    postal_code: "38731",
    full_city_name: "Chatham, MS",
    city_name: "Chatham",
  },
  {
    postal_code: "50577",
    full_city_name: "Renwick, IA",
    city_name: "Renwick",
  },
  {
    postal_code: "26133",
    full_city_name: "Belleville, WV",
    city_name: "Belleville",
  },
  {
    postal_code: "32061",
    full_city_name: "Lulu, FL",
    city_name: "Lulu",
  },
  {
    postal_code: "52362",
    full_city_name: "Wyoming, IA",
    city_name: "Wyoming",
  },
  {
    postal_code: "80818",
    full_city_name: "Genoa, CO",
    city_name: "Genoa",
  },
  {
    postal_code: "79040",
    full_city_name: "Gruver, TX",
    city_name: "Gruver",
  },
  {
    postal_code: "61274",
    full_city_name: "Osco, IL",
    city_name: "Osco",
  },
  {
    postal_code: "39841",
    full_city_name: "Damascus, GA",
    city_name: "Damascus",
  },
  {
    postal_code: "85936",
    full_city_name: "Saint Johns, AZ",
    city_name: "Saint Johns",
  },
  {
    postal_code: "27986",
    full_city_name: "Winton, NC",
    city_name: "Winton",
  },
  {
    postal_code: "49446",
    full_city_name: "New Era, MI",
    city_name: "New Era",
  },
  {
    postal_code: "64763",
    full_city_name: "Lowry City, MO",
    city_name: "Lowry City",
  },
  {
    postal_code: "44841",
    full_city_name: "Kansas, OH",
    city_name: "Kansas",
  },
  {
    postal_code: "77655",
    full_city_name: "Sabine Pass, TX",
    city_name: "Sabine Pass",
  },
  {
    postal_code: "30470",
    full_city_name: "Tarrytown, GA",
    city_name: "Tarrytown",
  },
  {
    postal_code: "62630",
    full_city_name: "Chesterfield, IL",
    city_name: "Chesterfield",
  },
  {
    postal_code: "83810",
    full_city_name: "Cataldo, ID",
    city_name: "Cataldo",
  },
  {
    postal_code: "74022",
    full_city_name: "Copan, OK",
    city_name: "Copan",
  },
  {
    postal_code: "47860",
    full_city_name: "Mecca, IN",
    city_name: "Mecca",
  },
  {
    postal_code: "13632",
    full_city_name: "Depauville, NY",
    city_name: "Depauville",
  },
  {
    postal_code: "84022",
    full_city_name: "Dugway, UT",
    city_name: "Dugway",
  },
  {
    postal_code: "66060",
    full_city_name: "Nortonville, KS",
    city_name: "Nortonville",
  },
  {
    postal_code: "84745",
    full_city_name: "La Verkin, UT",
    city_name: "La Verkin",
  },
  {
    postal_code: "29320",
    full_city_name: "Arcadia, SC",
    city_name: "Arcadia",
  },
  {
    postal_code: "62916",
    full_city_name: "Campbell Hill, IL",
    city_name: "Campbell Hill",
  },
  {
    postal_code: "15558",
    full_city_name: "Salisbury, PA",
    city_name: "Salisbury",
  },
  {
    postal_code: "62311",
    full_city_name: "Augusta, IL",
    city_name: "Augusta",
  },
  {
    postal_code: "43106",
    full_city_name: "Bloomingburg, OH",
    city_name: "Bloomingburg",
  },
  {
    postal_code: "66016",
    full_city_name: "Cummings, KS",
    city_name: "Cummings",
  },
  {
    postal_code: "29583",
    full_city_name: "Pamplico, SC",
    city_name: "Pamplico",
  },
  {
    postal_code: "50847",
    full_city_name: "Grant, IA",
    city_name: "Grant",
  },
  {
    postal_code: "93920",
    full_city_name: "Big Sur, CA",
    city_name: "Big Sur",
  },
  {
    postal_code: "57239",
    full_city_name: "Grenville, SD",
    city_name: "Grenville",
  },
  {
    postal_code: "79237",
    full_city_name: "Hedley, TX",
    city_name: "Hedley",
  },
  {
    postal_code: "87325",
    full_city_name: "Tohatchi, NM",
    city_name: "Tohatchi",
  },
  {
    postal_code: "74652",
    full_city_name: "Shidler, OK",
    city_name: "Shidler",
  },
  {
    postal_code: "15313",
    full_city_name: "Beallsville, PA",
    city_name: "Beallsville",
  },
  {
    postal_code: "21626",
    full_city_name: "Crapo, MD",
    city_name: "Crapo",
  },
  {
    postal_code: "61769",
    full_city_name: "Saunemin, IL",
    city_name: "Saunemin",
  },
  {
    postal_code: "52236",
    full_city_name: "Homestead, IA",
    city_name: "Homestead",
  },
  {
    postal_code: "99039",
    full_city_name: "Waverly, WA",
    city_name: "Waverly",
  },
  {
    postal_code: "07849",
    full_city_name: "Lake Hopatcong, NJ",
    city_name: "Lake Hopatcong",
  },
  {
    postal_code: "56029",
    full_city_name: "Emmons, MN",
    city_name: "Emmons",
  },
  {
    postal_code: "45310",
    full_city_name: "Burkettsville, OH",
    city_name: "Burkettsville",
  },
  {
    postal_code: "88231",
    full_city_name: "Eunice, NM",
    city_name: "Eunice",
  },
  {
    postal_code: "60470",
    full_city_name: "Ransom, IL",
    city_name: "Ransom",
  },
  {
    postal_code: "81630",
    full_city_name: "De Beque, CO",
    city_name: "De Beque",
  },
  {
    postal_code: "72079",
    full_city_name: "Jefferson, AR",
    city_name: "Jefferson",
  },
  {
    postal_code: "47118",
    full_city_name: "English, IN",
    city_name: "English",
  },
  {
    postal_code: "13844",
    full_city_name: "South Plymouth, NY",
    city_name: "South Plymouth",
  },
  {
    postal_code: "97493",
    full_city_name: "Westlake, OR",
    city_name: "Westlake",
  },
  {
    postal_code: "96107",
    full_city_name: "Coleville, CA",
    city_name: "Coleville",
  },
  {
    postal_code: "15619",
    full_city_name: "Bovard, PA",
    city_name: "Bovard",
  },
  {
    postal_code: "04643",
    full_city_name: "Harrington, ME",
    city_name: "Harrington",
  },
  {
    postal_code: "43463",
    full_city_name: "Stony Ridge, OH",
    city_name: "Stony Ridge",
  },
  {
    postal_code: "67570",
    full_city_name: "Pretty Prairie, KS",
    city_name: "Pretty Prairie",
  },
  {
    postal_code: "48873",
    full_city_name: "Pewamo, MI",
    city_name: "Pewamo",
  },
  {
    postal_code: "28660",
    full_city_name: "Olin, NC",
    city_name: "Olin",
  },
  {
    postal_code: "99176",
    full_city_name: "Thornton, WA",
    city_name: "Thornton",
  },
  {
    postal_code: "12130",
    full_city_name: "Niverville, NY",
    city_name: "Niverville",
  },
  {
    postal_code: "52161",
    full_city_name: "Ossian, IA",
    city_name: "Ossian",
  },
  {
    postal_code: "95224",
    full_city_name: "Avery, CA",
    city_name: "Avery",
  },
  {
    postal_code: "46149",
    full_city_name: "Lizton, IN",
    city_name: "Lizton",
  },
  {
    postal_code: "72129",
    full_city_name: "Prattsville, AR",
    city_name: "Prattsville",
  },
  {
    postal_code: "39352",
    full_city_name: "Porterville, MS",
    city_name: "Porterville",
  },
  {
    postal_code: "25607",
    full_city_name: "Amherstdale, WV",
    city_name: "Amherstdale",
  },
  {
    postal_code: "22649",
    full_city_name: "Middletown, VA",
    city_name: "Middletown",
  },
  {
    postal_code: "56147",
    full_city_name: "Kenneth, MN",
    city_name: "Kenneth",
  },
  {
    postal_code: "14846",
    full_city_name: "Hunt, NY",
    city_name: "Hunt",
  },
  {
    postal_code: "52065",
    full_city_name: "New Vienna, IA",
    city_name: "New Vienna",
  },
  {
    postal_code: "15562",
    full_city_name: "Springs, PA",
    city_name: "Springs",
  },
  {
    postal_code: "75493",
    full_city_name: "Winfield, TX",
    city_name: "Winfield",
  },
  {
    postal_code: "79046",
    full_city_name: "Higgins, TX",
    city_name: "Higgins",
  },
  {
    postal_code: "58428",
    full_city_name: "Dawson, ND",
    city_name: "Dawson",
  },
  {
    postal_code: "63386",
    full_city_name: "West Alton, MO",
    city_name: "West Alton",
  },
  {
    postal_code: "14884",
    full_city_name: "Swain, NY",
    city_name: "Swain",
  },
  {
    postal_code: "54125",
    full_city_name: "Goodman, WI",
    city_name: "Goodman",
  },
  {
    postal_code: "42464",
    full_city_name: "White Plains, KY",
    city_name: "White Plains",
  },
  {
    postal_code: "36875",
    full_city_name: "Seale, AL",
    city_name: "Seale",
  },
  {
    postal_code: "67736",
    full_city_name: "Gove, KS",
    city_name: "Gove",
  },
  {
    postal_code: "69346",
    full_city_name: "Harrison, NE",
    city_name: "Harrison",
  },
  {
    postal_code: "45363",
    full_city_name: "Russia, OH",
    city_name: "Russia",
  },
  {
    postal_code: "20896",
    full_city_name: "Garrett Park, MD",
    city_name: "Garrett Park",
  },
  {
    postal_code: "57331",
    full_city_name: "Dimock, SD",
    city_name: "Dimock",
  },
  {
    postal_code: "97486",
    full_city_name: "Umpqua, OR",
    city_name: "Umpqua",
  },
  {
    postal_code: "29932",
    full_city_name: "Luray, SC",
    city_name: "Luray",
  },
  {
    postal_code: "95526",
    full_city_name: "Bridgeville, CA",
    city_name: "Bridgeville",
  },
  {
    postal_code: "63863",
    full_city_name: "Malden, MO",
    city_name: "Malden",
  },
  {
    postal_code: "34250",
    full_city_name: "Terra Ceia, FL",
    city_name: "Terra Ceia",
  },
  {
    postal_code: "28637",
    full_city_name: "Hildebran, NC",
    city_name: "Hildebran",
  },
  {
    postal_code: "82051",
    full_city_name: "Bosler, WY",
    city_name: "Bosler",
  },
  {
    postal_code: "67529",
    full_city_name: "Garfield, KS",
    city_name: "Garfield",
  },
  {
    postal_code: "72001",
    full_city_name: "Adona, AR",
    city_name: "Adona",
  },
  {
    postal_code: "84539",
    full_city_name: "Sunnyside, UT",
    city_name: "Sunnyside",
  },
  {
    postal_code: "69042",
    full_city_name: "Stockville, NE",
    city_name: "Stockville",
  },
  {
    postal_code: "14769",
    full_city_name: "Portland, NY",
    city_name: "Portland",
  },
  {
    postal_code: "27946",
    full_city_name: "Hobbsville, NC",
    city_name: "Hobbsville",
  },
  {
    postal_code: "12435",
    full_city_name: "Greenfield Park, NY",
    city_name: "Greenfield Park",
  },
  {
    postal_code: "72028",
    full_city_name: "Choctaw, AR",
    city_name: "Choctaw",
  },
  {
    postal_code: "67448",
    full_city_name: "Gypsum, KS",
    city_name: "Gypsum",
  },
  {
    postal_code: "15018",
    full_city_name: "Buena Vista, PA",
    city_name: "Buena Vista",
  },
  {
    postal_code: "62836",
    full_city_name: "Ewing, IL",
    city_name: "Ewing",
  },
  {
    postal_code: "16644",
    full_city_name: "Glasgow, PA",
    city_name: "Glasgow",
  },
  {
    postal_code: "47921",
    full_city_name: "Boswell, IN",
    city_name: "Boswell",
  },
  {
    postal_code: "45684",
    full_city_name: "Stout, OH",
    city_name: "Stout",
  },
  {
    postal_code: "83325",
    full_city_name: "Eden, ID",
    city_name: "Eden",
  },
  {
    postal_code: "83428",
    full_city_name: "Irwin, ID",
    city_name: "Irwin",
  },
  {
    postal_code: "10998",
    full_city_name: "Westtown, NY",
    city_name: "Westtown",
  },
  {
    postal_code: "45362",
    full_city_name: "Rossburg, OH",
    city_name: "Rossburg",
  },
  {
    postal_code: "57348",
    full_city_name: "Hitchcock, SD",
    city_name: "Hitchcock",
  },
  {
    postal_code: "24634",
    full_city_name: "Pilgrims Knob, VA",
    city_name: "Pilgrims Knob",
  },
  {
    postal_code: "67013",
    full_city_name: "Belle Plaine, KS",
    city_name: "Belle Plaine",
  },
  {
    postal_code: "79052",
    full_city_name: "Kress, TX",
    city_name: "Kress",
  },
  {
    postal_code: "21864",
    full_city_name: "Stockton, MD",
    city_name: "Stockton",
  },
  {
    postal_code: "24941",
    full_city_name: "Gap Mills, WV",
    city_name: "Gap Mills",
  },
  {
    postal_code: "58067",
    full_city_name: "Rutland, ND",
    city_name: "Rutland",
  },
  {
    postal_code: "49820",
    full_city_name: "Curtis, MI",
    city_name: "Curtis",
  },
  {
    postal_code: "82636",
    full_city_name: "Evansville, WY",
    city_name: "Evansville",
  },
  {
    postal_code: "72469",
    full_city_name: "Strawberry, AR",
    city_name: "Strawberry",
  },
  {
    postal_code: "12777",
    full_city_name: "Forestburgh, NY",
    city_name: "Forestburgh",
  },
  {
    postal_code: "22729",
    full_city_name: "Mitchells, VA",
    city_name: "Mitchells",
  },
  {
    postal_code: "17565",
    full_city_name: "Pequea, PA",
    city_name: "Pequea",
  },
  {
    postal_code: "26234",
    full_city_name: "Rock Cave, WV",
    city_name: "Rock Cave",
  },
  {
    postal_code: "95736",
    full_city_name: "Weimar, CA",
    city_name: "Weimar",
  },
  {
    postal_code: "16674",
    full_city_name: "Robertsdale, PA",
    city_name: "Robertsdale",
  },
  {
    postal_code: "16940",
    full_city_name: "Nelson, PA",
    city_name: "Nelson",
  },
  {
    postal_code: "36374",
    full_city_name: "Skipperville, AL",
    city_name: "Skipperville",
  },
  {
    postal_code: "08821",
    full_city_name: "Flagtown, NJ",
    city_name: "Flagtown",
  },
  {
    postal_code: "57760",
    full_city_name: "Newell, SD",
    city_name: "Newell",
  },
  {
    postal_code: "83857",
    full_city_name: "Princeton, ID",
    city_name: "Princeton",
  },
  {
    postal_code: "01354",
    full_city_name: "Gill, MA",
    city_name: "Gill",
  },
  {
    postal_code: "47513",
    full_city_name: "Birdseye, IN",
    city_name: "Birdseye",
  },
  {
    postal_code: "77878",
    full_city_name: "Snook, TX",
    city_name: "Snook",
  },
  {
    postal_code: "24217",
    full_city_name: "Bee, VA",
    city_name: "Bee",
  },
  {
    postal_code: "63825",
    full_city_name: "Bloomfield, MO",
    city_name: "Bloomfield",
  },
  {
    postal_code: "49035",
    full_city_name: "Cloverdale, MI",
    city_name: "Cloverdale",
  },
  {
    postal_code: "88242",
    full_city_name: "Hobbs, NM",
    city_name: "Hobbs",
  },
  {
    postal_code: "54644",
    full_city_name: "Mindoro, WI",
    city_name: "Mindoro",
  },
  {
    postal_code: "68034",
    full_city_name: "Kennard, NE",
    city_name: "Kennard",
  },
  {
    postal_code: "39815",
    full_city_name: "Attapulgus, GA",
    city_name: "Attapulgus",
  },
  {
    postal_code: "48767",
    full_city_name: "Unionville, MI",
    city_name: "Unionville",
  },
  {
    postal_code: "24736",
    full_city_name: "Matoaka, WV",
    city_name: "Matoaka",
  },
  {
    postal_code: "26761",
    full_city_name: "Shanks, WV",
    city_name: "Shanks",
  },
  {
    postal_code: "57782",
    full_city_name: "Smithwick, SD",
    city_name: "Smithwick",
  },
  {
    postal_code: "74633",
    full_city_name: "Burbank, OK",
    city_name: "Burbank",
  },
  {
    postal_code: "14864",
    full_city_name: "Millport, NY",
    city_name: "Millport",
  },
  {
    postal_code: "04265",
    full_city_name: "North Monmouth, ME",
    city_name: "North Monmouth",
  },
  {
    postal_code: "24822",
    full_city_name: "Clear Fork, WV",
    city_name: "Clear Fork",
  },
  {
    postal_code: "15924",
    full_city_name: "Cairnbrook, PA",
    city_name: "Cairnbrook",
  },
  {
    postal_code: "55953",
    full_city_name: "Lyle, MN",
    city_name: "Lyle",
  },
  {
    postal_code: "62555",
    full_city_name: "Owaneco, IL",
    city_name: "Owaneco",
  },
  {
    postal_code: "68344",
    full_city_name: "Douglas, NE",
    city_name: "Douglas",
  },
  {
    postal_code: "80729",
    full_city_name: "Grover, CO",
    city_name: "Grover",
  },
  {
    postal_code: "92332",
    full_city_name: "Essex, CA",
    city_name: "Essex",
  },
  {
    postal_code: "25134",
    full_city_name: "Miami, WV",
    city_name: "Miami",
  },
  {
    postal_code: "75944",
    full_city_name: "Etoile, TX",
    city_name: "Etoile",
  },
  {
    postal_code: "23023",
    full_city_name: "Bruington, VA",
    city_name: "Bruington",
  },
  {
    postal_code: "29321",
    full_city_name: "Buffalo, SC",
    city_name: "Buffalo",
  },
  {
    postal_code: "53580",
    full_city_name: "Rewey, WI",
    city_name: "Rewey",
  },
  {
    postal_code: "72081",
    full_city_name: "Judsonia, AR",
    city_name: "Judsonia",
  },
  {
    postal_code: "41643",
    full_city_name: "Lackey, KY",
    city_name: "Lackey",
  },
  {
    postal_code: "99115",
    full_city_name: "Coulee City, WA",
    city_name: "Coulee City",
  },
  {
    postal_code: "99013",
    full_city_name: "Ford, WA",
    city_name: "Ford",
  },
  {
    postal_code: "50566",
    full_city_name: "Moorland, IA",
    city_name: "Moorland",
  },
  {
    postal_code: "82635",
    full_city_name: "Edgerton, WY",
    city_name: "Edgerton",
  },
  {
    postal_code: "57481",
    full_city_name: "Westport, SD",
    city_name: "Westport",
  },
  {
    postal_code: "48724",
    full_city_name: "Carrollton, MI",
    city_name: "Carrollton",
  },
  {
    postal_code: "11042",
    full_city_name: "New Hyde Park, NY",
    city_name: "New Hyde Park",
  },
  {
    postal_code: "96116",
    full_city_name: "Likely, CA",
    city_name: "Likely",
  },
  {
    postal_code: "83223",
    full_city_name: "Bloomington, ID",
    city_name: "Bloomington",
  },
  {
    postal_code: "78827",
    full_city_name: "Asherton, TX",
    city_name: "Asherton",
  },
  {
    postal_code: "59020",
    full_city_name: "Cooke City, MT",
    city_name: "Cooke City",
  },
  {
    postal_code: "04551",
    full_city_name: "Bremen, ME",
    city_name: "Bremen",
  },
  {
    postal_code: "07711",
    full_city_name: "Allenhurst, NJ",
    city_name: "Allenhurst",
  },
  {
    postal_code: "66080",
    full_city_name: "Richmond, KS",
    city_name: "Richmond",
  },
  {
    postal_code: "87515",
    full_city_name: "Canjilon, NM",
    city_name: "Canjilon",
  },
  {
    postal_code: "53541",
    full_city_name: "Gratiot, WI",
    city_name: "Gratiot",
  },
  {
    postal_code: "96047",
    full_city_name: "Igo, CA",
    city_name: "Igo",
  },
  {
    postal_code: "47512",
    full_city_name: "Bicknell, IN",
    city_name: "Bicknell",
  },
  {
    postal_code: "29114",
    full_city_name: "Olanta, SC",
    city_name: "Olanta",
  },
  {
    postal_code: "22714",
    full_city_name: "Brandy Station, VA",
    city_name: "Brandy Station",
  },
  {
    postal_code: "40104",
    full_city_name: "Battletown, KY",
    city_name: "Battletown",
  },
  {
    postal_code: "71643",
    full_city_name: "Gould, AR",
    city_name: "Gould",
  },
  {
    postal_code: "72179",
    full_city_name: "Wilburn, AR",
    city_name: "Wilburn",
  },
  {
    postal_code: "48621",
    full_city_name: "Fairview, MI",
    city_name: "Fairview",
  },
  {
    postal_code: "87048",
    full_city_name: "Corrales, NM",
    city_name: "Corrales",
  },
  {
    postal_code: "43437",
    full_city_name: "Jerry City, OH",
    city_name: "Jerry City",
  },
  {
    postal_code: "61015",
    full_city_name: "Chana, IL",
    city_name: "Chana",
  },
  {
    postal_code: "56168",
    full_city_name: "Rushmore, MN",
    city_name: "Rushmore",
  },
  {
    postal_code: "51542",
    full_city_name: "Honey Creek, IA",
    city_name: "Honey Creek",
  },
  {
    postal_code: "67008",
    full_city_name: "Atlanta, KS",
    city_name: "Atlanta",
  },
  {
    postal_code: "42410",
    full_city_name: "Earlington, KY",
    city_name: "Earlington",
  },
  {
    postal_code: "47392",
    full_city_name: "Webster, IN",
    city_name: "Webster",
  },
  {
    postal_code: "69339",
    full_city_name: "Crawford, NE",
    city_name: "Crawford",
  },
  {
    postal_code: "76363",
    full_city_name: "Goree, TX",
    city_name: "Goree",
  },
  {
    postal_code: "95248",
    full_city_name: "Rail Road Flat, CA",
    city_name: "Rail Road Flat",
  },
  {
    postal_code: "30905",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "50524",
    full_city_name: "Clare, IA",
    city_name: "Clare",
  },
  {
    postal_code: "15347",
    full_city_name: "Meadow Lands, PA",
    city_name: "Meadow Lands",
  },
  {
    postal_code: "10475",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "17501",
    full_city_name: "Akron, PA",
    city_name: "Akron",
  },
  {
    postal_code: "79713",
    full_city_name: "Ackerly, TX",
    city_name: "Ackerly",
  },
  {
    postal_code: "22060",
    full_city_name: "Fort Belvoir, VA",
    city_name: "Fort Belvoir",
  },
  {
    postal_code: "42442",
    full_city_name: "Nortonville, KY",
    city_name: "Nortonville",
  },
  {
    postal_code: "16233",
    full_city_name: "Leeper, PA",
    city_name: "Leeper",
  },
  {
    postal_code: "59853",
    full_city_name: "Noxon, MT",
    city_name: "Noxon",
  },
  {
    postal_code: "39086",
    full_city_name: "Hermanville, MS",
    city_name: "Hermanville",
  },
  {
    postal_code: "56516",
    full_city_name: "Bejou, MN",
    city_name: "Bejou",
  },
  {
    postal_code: "46979",
    full_city_name: "Russiaville, IN",
    city_name: "Russiaville",
  },
  {
    postal_code: "65282",
    full_city_name: "Santa Fe, MO",
    city_name: "Santa Fe",
  },
  {
    postal_code: "37019",
    full_city_name: "Belfast, TN",
    city_name: "Belfast",
  },
  {
    postal_code: "25111",
    full_city_name: "Indore, WV",
    city_name: "Indore",
  },
  {
    postal_code: "62512",
    full_city_name: "Beason, IL",
    city_name: "Beason",
  },
  {
    postal_code: "37340",
    full_city_name: "Guild, TN",
    city_name: "Guild",
  },
  {
    postal_code: "84073",
    full_city_name: "Talmage, UT",
    city_name: "Talmage",
  },
  {
    postal_code: "05346",
    full_city_name: "Putney, VT",
    city_name: "Putney",
  },
  {
    postal_code: "16620",
    full_city_name: "Brisbin, PA",
    city_name: "Brisbin",
  },
  {
    postal_code: "16746",
    full_city_name: "Roulette, PA",
    city_name: "Roulette",
  },
  {
    postal_code: "93721",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "95951",
    full_city_name: "Hamilton City, CA",
    city_name: "Hamilton City",
  },
  {
    postal_code: "42758",
    full_city_name: "Mannsville, KY",
    city_name: "Mannsville",
  },
  {
    postal_code: "12768",
    full_city_name: "Parksville, NY",
    city_name: "Parksville",
  },
  {
    postal_code: "03456",
    full_city_name: "Marlow, NH",
    city_name: "Marlow",
  },
  {
    postal_code: "16362",
    full_city_name: "Utica, PA",
    city_name: "Utica",
  },
  {
    postal_code: "62334",
    full_city_name: "Elvaston, IL",
    city_name: "Elvaston",
  },
  {
    postal_code: "14081",
    full_city_name: "Irving, NY",
    city_name: "Irving",
  },
  {
    postal_code: "35671",
    full_city_name: "Tanner, AL",
    city_name: "Tanner",
  },
  {
    postal_code: "52739",
    full_city_name: "Conesville, IA",
    city_name: "Conesville",
  },
  {
    postal_code: "23076",
    full_city_name: "Hudgins, VA",
    city_name: "Hudgins",
  },
  {
    postal_code: "24598",
    full_city_name: "Virgilina, VA",
    city_name: "Virgilina",
  },
  {
    postal_code: "66527",
    full_city_name: "Powhattan, KS",
    city_name: "Powhattan",
  },
  {
    postal_code: "62523",
    full_city_name: "Decatur, IL",
    city_name: "Decatur",
  },
  {
    postal_code: "51631",
    full_city_name: "Braddyville, IA",
    city_name: "Braddyville",
  },
  {
    postal_code: "58229",
    full_city_name: "Fairdale, ND",
    city_name: "Fairdale",
  },
  {
    postal_code: "76871",
    full_city_name: "Richland Springs, TX",
    city_name: "Richland Springs",
  },
  {
    postal_code: "17579",
    full_city_name: "Strasburg, PA",
    city_name: "Strasburg",
  },
  {
    postal_code: "58723",
    full_city_name: "Butte, ND",
    city_name: "Butte",
  },
  {
    postal_code: "59324",
    full_city_name: "Ekalaka, MT",
    city_name: "Ekalaka",
  },
  {
    postal_code: "65014",
    full_city_name: "Bland, MO",
    city_name: "Bland",
  },
  {
    postal_code: "63961",
    full_city_name: "Qulin, MO",
    city_name: "Qulin",
  },
  {
    postal_code: "59215",
    full_city_name: "Circle, MT",
    city_name: "Circle",
  },
  {
    postal_code: "51544",
    full_city_name: "Lewis, IA",
    city_name: "Lewis",
  },
  {
    postal_code: "30822",
    full_city_name: "Perkins, GA",
    city_name: "Perkins",
  },
  {
    postal_code: "56324",
    full_city_name: "Dalton, MN",
    city_name: "Dalton",
  },
  {
    postal_code: "52163",
    full_city_name: "Protivin, IA",
    city_name: "Protivin",
  },
  {
    postal_code: "59746",
    full_city_name: "Polaris, MT",
    city_name: "Polaris",
  },
  {
    postal_code: "70091",
    full_city_name: "Venice, LA",
    city_name: "Venice",
  },
  {
    postal_code: "79256",
    full_city_name: "Roaring Springs, TX",
    city_name: "Roaring Springs",
  },
  {
    postal_code: "71861",
    full_city_name: "Taylor, AR",
    city_name: "Taylor",
  },
  {
    postal_code: "23011",
    full_city_name: "Barhamsville, VA",
    city_name: "Barhamsville",
  },
  {
    postal_code: "56569",
    full_city_name: "Ogema, MN",
    city_name: "Ogema",
  },
  {
    postal_code: "75435",
    full_city_name: "Deport, TX",
    city_name: "Deport",
  },
  {
    postal_code: "53826",
    full_city_name: "Wauzeka, WI",
    city_name: "Wauzeka",
  },
  {
    postal_code: "26884",
    full_city_name: "Seneca Rocks, WV",
    city_name: "Seneca Rocks",
  },
  {
    postal_code: "58779",
    full_city_name: "Ryder, ND",
    city_name: "Ryder",
  },
  {
    postal_code: "07934",
    full_city_name: "Gladstone, NJ",
    city_name: "Gladstone",
  },
  {
    postal_code: "67572",
    full_city_name: "Ransom, KS",
    city_name: "Ransom",
  },
  {
    postal_code: "38255",
    full_city_name: "Sharon, TN",
    city_name: "Sharon",
  },
  {
    postal_code: "25674",
    full_city_name: "Kermit, WV",
    city_name: "Kermit",
  },
  {
    postal_code: "61474",
    full_city_name: "Saint Augustine, IL",
    city_name: "Saint Augustine",
  },
  {
    postal_code: "01436",
    full_city_name: "Baldwinville, MA",
    city_name: "Baldwinville",
  },
  {
    postal_code: "70801",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "64066",
    full_city_name: "Levasy, MO",
    city_name: "Levasy",
  },
  {
    postal_code: "24656",
    full_city_name: "Vansant, VA",
    city_name: "Vansant",
  },
  {
    postal_code: "83312",
    full_city_name: "Almo, ID",
    city_name: "Almo",
  },
  {
    postal_code: "87421",
    full_city_name: "Waterflow, NM",
    city_name: "Waterflow",
  },
  {
    postal_code: "95317",
    full_city_name: "El Nido, CA",
    city_name: "El Nido",
  },
  {
    postal_code: "97543",
    full_city_name: "Wilderville, OR",
    city_name: "Wilderville",
  },
  {
    postal_code: "64743",
    full_city_name: "East Lynne, MO",
    city_name: "East Lynne",
  },
  {
    postal_code: "29726",
    full_city_name: "Mc Connells, SC",
    city_name: "Mc Connells",
  },
  {
    postal_code: "71749",
    full_city_name: "Junction City, AR",
    city_name: "Junction City",
  },
  {
    postal_code: "59419",
    full_city_name: "Bynum, MT",
    city_name: "Bynum",
  },
  {
    postal_code: "96033",
    full_city_name: "French Gulch, CA",
    city_name: "French Gulch",
  },
  {
    postal_code: "89008",
    full_city_name: "Caliente, NV",
    city_name: "Caliente",
  },
  {
    postal_code: "23125",
    full_city_name: "New Point, VA",
    city_name: "New Point",
  },
  {
    postal_code: "80475",
    full_city_name: "Shawnee, CO",
    city_name: "Shawnee",
  },
  {
    postal_code: "18656",
    full_city_name: "Sweet Valley, PA",
    city_name: "Sweet Valley",
  },
  {
    postal_code: "10548",
    full_city_name: "Montrose, NY",
    city_name: "Montrose",
  },
  {
    postal_code: "15957",
    full_city_name: "Strongstown, PA",
    city_name: "Strongstown",
  },
  {
    postal_code: "63732",
    full_city_name: "Altenburg, MO",
    city_name: "Altenburg",
  },
  {
    postal_code: "99152",
    full_city_name: "Metaline, WA",
    city_name: "Metaline",
  },
  {
    postal_code: "61563",
    full_city_name: "Saint David, IL",
    city_name: "Saint David",
  },
  {
    postal_code: "43009",
    full_city_name: "Cable, OH",
    city_name: "Cable",
  },
  {
    postal_code: "62841",
    full_city_name: "Freeman Spur, IL",
    city_name: "Freeman Spur",
  },
  {
    postal_code: "58424",
    full_city_name: "Cleveland, ND",
    city_name: "Cleveland",
  },
  {
    postal_code: "46770",
    full_city_name: "Markle, IN",
    city_name: "Markle",
  },
  {
    postal_code: "28553",
    full_city_name: "Marshallberg, NC",
    city_name: "Marshallberg",
  },
  {
    postal_code: "95601",
    full_city_name: "Amador City, CA",
    city_name: "Amador City",
  },
  {
    postal_code: "36525",
    full_city_name: "Creola, AL",
    city_name: "Creola",
  },
  {
    postal_code: "39140",
    full_city_name: "Newhebron, MS",
    city_name: "Newhebron",
  },
  {
    postal_code: "95715",
    full_city_name: "Emigrant Gap, CA",
    city_name: "Emigrant Gap",
  },
  {
    postal_code: "65484",
    full_city_name: "Huggins, MO",
    city_name: "Huggins",
  },
  {
    postal_code: "70589",
    full_city_name: "Washington, LA",
    city_name: "Washington",
  },
  {
    postal_code: "54515",
    full_city_name: "Catawba, WI",
    city_name: "Catawba",
  },
  {
    postal_code: "32013",
    full_city_name: "Day, FL",
    city_name: "Day",
  },
  {
    postal_code: "62898",
    full_city_name: "Woodlawn, IL",
    city_name: "Woodlawn",
  },
  {
    postal_code: "60968",
    full_city_name: "Thawville, IL",
    city_name: "Thawville",
  },
  {
    postal_code: "95983",
    full_city_name: "Taylorsville, CA",
    city_name: "Taylorsville",
  },
  {
    postal_code: "68742",
    full_city_name: "Inman, NE",
    city_name: "Inman",
  },
  {
    postal_code: "59434",
    full_city_name: "East Glacier Park, MT",
    city_name: "East Glacier Park",
  },
  {
    postal_code: "16859",
    full_city_name: "Moshannon, PA",
    city_name: "Moshannon",
  },
  {
    postal_code: "12092",
    full_city_name: "Howes Cave, NY",
    city_name: "Howes Cave",
  },
  {
    postal_code: "13841",
    full_city_name: "Smithville Flats, NY",
    city_name: "Smithville Flats",
  },
  {
    postal_code: "04693",
    full_city_name: "Winter Harbor, ME",
    city_name: "Winter Harbor",
  },
  {
    postal_code: "74636",
    full_city_name: "Deer Creek, OK",
    city_name: "Deer Creek",
  },
  {
    postal_code: "43533",
    full_city_name: "Lyons, OH",
    city_name: "Lyons",
  },
  {
    postal_code: "52543",
    full_city_name: "Cedar, IA",
    city_name: "Cedar",
  },
  {
    postal_code: "38545",
    full_city_name: "Bloomington Springs, TN",
    city_name: "Bloomington Springs",
  },
  {
    postal_code: "17101",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "13784",
    full_city_name: "Harford, NY",
    city_name: "Harford",
  },
  {
    postal_code: "57317",
    full_city_name: "Bonesteel, SD",
    city_name: "Bonesteel",
  },
  {
    postal_code: "97204",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "62353",
    full_city_name: "Mount Sterling, IL",
    city_name: "Mount Sterling",
  },
  {
    postal_code: "24314",
    full_city_name: "Bastian, VA",
    city_name: "Bastian",
  },
  {
    postal_code: "06282",
    full_city_name: "Woodstock Valley, CT",
    city_name: "Woodstock Valley",
  },
  {
    postal_code: "61363",
    full_city_name: "Standard, IL",
    city_name: "Standard",
  },
  {
    postal_code: "24925",
    full_city_name: "Caldwell, WV",
    city_name: "Caldwell",
  },
  {
    postal_code: "50527",
    full_city_name: "Curlew, IA",
    city_name: "Curlew",
  },
  {
    postal_code: "14056",
    full_city_name: "East Pembroke, NY",
    city_name: "East Pembroke",
  },
  {
    postal_code: "62996",
    full_city_name: "Villa Ridge, IL",
    city_name: "Villa Ridge",
  },
  {
    postal_code: "97342",
    full_city_name: "Detroit, OR",
    city_name: "Detroit",
  },
  {
    postal_code: "58558",
    full_city_name: "Menoken, ND",
    city_name: "Menoken",
  },
  {
    postal_code: "76859",
    full_city_name: "Menard, TX",
    city_name: "Menard",
  },
  {
    postal_code: "82839",
    full_city_name: "Ranchester, WY",
    city_name: "Ranchester",
  },
  {
    postal_code: "84726",
    full_city_name: "Escalante, UT",
    city_name: "Escalante",
  },
  {
    postal_code: "52053",
    full_city_name: "Holy Cross, IA",
    city_name: "Holy Cross",
  },
  {
    postal_code: "25252",
    full_city_name: "Le Roy, WV",
    city_name: "Le Roy",
  },
  {
    postal_code: "31519",
    full_city_name: "Broxton, GA",
    city_name: "Broxton",
  },
  {
    postal_code: "74867",
    full_city_name: "Sasakwa, OK",
    city_name: "Sasakwa",
  },
  {
    postal_code: "29378",
    full_city_name: "Una, SC",
    city_name: "Una",
  },
  {
    postal_code: "43152",
    full_city_name: "South Bloomingville, OH",
    city_name: "South Bloomingville",
  },
  {
    postal_code: "61567",
    full_city_name: "Topeka, IL",
    city_name: "Topeka",
  },
  {
    postal_code: "65283",
    full_city_name: "Stoutsville, MO",
    city_name: "Stoutsville",
  },
  {
    postal_code: "88042",
    full_city_name: "Hillsboro, NM",
    city_name: "Hillsboro",
  },
  {
    postal_code: "25115",
    full_city_name: "Kanawha Falls, WV",
    city_name: "Kanawha Falls",
  },
  {
    postal_code: "87525",
    full_city_name: "Taos Ski Valley, NM",
    city_name: "Taos Ski Valley",
  },
  {
    postal_code: "50478",
    full_city_name: "Thompson, IA",
    city_name: "Thompson",
  },
  {
    postal_code: "24649",
    full_city_name: "Swords Creek, VA",
    city_name: "Swords Creek",
  },
  {
    postal_code: "04848",
    full_city_name: "Islesboro, ME",
    city_name: "Islesboro",
  },
  {
    postal_code: "74957",
    full_city_name: "Smithville, OK",
    city_name: "Smithville",
  },
  {
    postal_code: "53170",
    full_city_name: "Silver Lake, WI",
    city_name: "Silver Lake",
  },
  {
    postal_code: "58653",
    full_city_name: "Scranton, ND",
    city_name: "Scranton",
  },
  {
    postal_code: "69352",
    full_city_name: "Lyman, NE",
    city_name: "Lyman",
  },
  {
    postal_code: "08314",
    full_city_name: "Delmont, NJ",
    city_name: "Delmont",
  },
  {
    postal_code: "33865",
    full_city_name: "Ona, FL",
    city_name: "Ona",
  },
  {
    postal_code: "73659",
    full_city_name: "Putnam, OK",
    city_name: "Putnam",
  },
  {
    postal_code: "48733",
    full_city_name: "Fairgrove, MI",
    city_name: "Fairgrove",
  },
  {
    postal_code: "78595",
    full_city_name: "Sullivan City, TX",
    city_name: "Sullivan City",
  },
  {
    postal_code: "81640",
    full_city_name: "Maybell, CO",
    city_name: "Maybell",
  },
  {
    postal_code: "56594",
    full_city_name: "Wolverton, MN",
    city_name: "Wolverton",
  },
  {
    postal_code: "48835",
    full_city_name: "Fowler, MI",
    city_name: "Fowler",
  },
  {
    postal_code: "17981",
    full_city_name: "Tremont, PA",
    city_name: "Tremont",
  },
  {
    postal_code: "16210",
    full_city_name: "Adrian, PA",
    city_name: "Adrian",
  },
  {
    postal_code: "69040",
    full_city_name: "Palisade, NE",
    city_name: "Palisade",
  },
  {
    postal_code: "12046",
    full_city_name: "Coeymans Hollow, NY",
    city_name: "Coeymans Hollow",
  },
  {
    postal_code: "71638",
    full_city_name: "Dermott, AR",
    city_name: "Dermott",
  },
  {
    postal_code: "76472",
    full_city_name: "Santo, TX",
    city_name: "Santo",
  },
  {
    postal_code: "65637",
    full_city_name: "Dora, MO",
    city_name: "Dora",
  },
  {
    postal_code: "68323",
    full_city_name: "Burchard, NE",
    city_name: "Burchard",
  },
  {
    postal_code: "15064",
    full_city_name: "Morgan, PA",
    city_name: "Morgan",
  },
  {
    postal_code: "07420",
    full_city_name: "Haskell, NJ",
    city_name: "Haskell",
  },
  {
    postal_code: "68765",
    full_city_name: "Osmond, NE",
    city_name: "Osmond",
  },
  {
    postal_code: "65756",
    full_city_name: "Stotts City, MO",
    city_name: "Stotts City",
  },
  {
    postal_code: "80131",
    full_city_name: "Louviers, CO",
    city_name: "Louviers",
  },
  {
    postal_code: "65783",
    full_city_name: "Windyville, MO",
    city_name: "Windyville",
  },
  {
    postal_code: "24070",
    full_city_name: "Catawba, VA",
    city_name: "Catawba",
  },
  {
    postal_code: "68825",
    full_city_name: "Callaway, NE",
    city_name: "Callaway",
  },
  {
    postal_code: "24272",
    full_city_name: "Nora, VA",
    city_name: "Nora",
  },
  {
    postal_code: "50167",
    full_city_name: "Minburn, IA",
    city_name: "Minburn",
  },
  {
    postal_code: "54658",
    full_city_name: "Stoddard, WI",
    city_name: "Stoddard",
  },
  {
    postal_code: "57579",
    full_city_name: "White River, SD",
    city_name: "White River",
  },
  {
    postal_code: "25148",
    full_city_name: "Orgas, WV",
    city_name: "Orgas",
  },
  {
    postal_code: "73742",
    full_city_name: "Hennessey, OK",
    city_name: "Hennessey",
  },
  {
    postal_code: "57569",
    full_city_name: "Reliance, SD",
    city_name: "Reliance",
  },
  {
    postal_code: "36773",
    full_city_name: "Safford, AL",
    city_name: "Safford",
  },
  {
    postal_code: "59936",
    full_city_name: "West Glacier, MT",
    city_name: "West Glacier",
  },
  {
    postal_code: "82334",
    full_city_name: "Sinclair, WY",
    city_name: "Sinclair",
  },
  {
    postal_code: "47619",
    full_city_name: "Lynnville, IN",
    city_name: "Lynnville",
  },
  {
    postal_code: "25007",
    full_city_name: "Arnett, WV",
    city_name: "Arnett",
  },
  {
    postal_code: "14723",
    full_city_name: "Cherry Creek, NY",
    city_name: "Cherry Creek",
  },
  {
    postal_code: "74825",
    full_city_name: "Allen, OK",
    city_name: "Allen",
  },
  {
    postal_code: "11739",
    full_city_name: "Great River, NY",
    city_name: "Great River",
  },
  {
    postal_code: "05260",
    full_city_name: "North Pownal, VT",
    city_name: "North Pownal",
  },
  {
    postal_code: "65054",
    full_city_name: "Loose Creek, MO",
    city_name: "Loose Creek",
  },
  {
    postal_code: "87930",
    full_city_name: "Arrey, NM",
    city_name: "Arrey",
  },
  {
    postal_code: "69020",
    full_city_name: "Bartley, NE",
    city_name: "Bartley",
  },
  {
    postal_code: "92347",
    full_city_name: "Hinkley, CA",
    city_name: "Hinkley",
  },
  {
    postal_code: "73664",
    full_city_name: "Sentinel, OK",
    city_name: "Sentinel",
  },
  {
    postal_code: "79062",
    full_city_name: "Morse, TX",
    city_name: "Morse",
  },
  {
    postal_code: "62375",
    full_city_name: "Timewell, IL",
    city_name: "Timewell",
  },
  {
    postal_code: "42441",
    full_city_name: "Nebo, KY",
    city_name: "Nebo",
  },
  {
    postal_code: "72478",
    full_city_name: "Warm Springs, AR",
    city_name: "Warm Springs",
  },
  {
    postal_code: "19319",
    full_city_name: "Cheyney, PA",
    city_name: "Cheyney",
  },
  {
    postal_code: "24363",
    full_city_name: "Mouth Of Wilson, VA",
    city_name: "Mouth Of Wilson",
  },
  {
    postal_code: "05836",
    full_city_name: "East Hardwick, VT",
    city_name: "East Hardwick",
  },
  {
    postal_code: "56688",
    full_city_name: "Wirt, MN",
    city_name: "Wirt",
  },
  {
    postal_code: "37376",
    full_city_name: "Sherwood, TN",
    city_name: "Sherwood",
  },
  {
    postal_code: "10453",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "48456",
    full_city_name: "Minden City, MI",
    city_name: "Minden City",
  },
  {
    postal_code: "61423",
    full_city_name: "Cameron, IL",
    city_name: "Cameron",
  },
  {
    postal_code: "26425",
    full_city_name: "Rowlesburg, WV",
    city_name: "Rowlesburg",
  },
  {
    postal_code: "75928",
    full_city_name: "Bon Wier, TX",
    city_name: "Bon Wier",
  },
  {
    postal_code: "16322",
    full_city_name: "Endeavor, PA",
    city_name: "Endeavor",
  },
  {
    postal_code: "26656",
    full_city_name: "Belva, WV",
    city_name: "Belva",
  },
  {
    postal_code: "04110",
    full_city_name: "Cumberland Foreside, ME",
    city_name: "Cumberland Foreside",
  },
  {
    postal_code: "59036",
    full_city_name: "Harlowton, MT",
    city_name: "Harlowton",
  },
  {
    postal_code: "83255",
    full_city_name: "Moore, ID",
    city_name: "Moore",
  },
  {
    postal_code: "57450",
    full_city_name: "Hoven, SD",
    city_name: "Hoven",
  },
  {
    postal_code: "99586",
    full_city_name: "Gakona, AK",
    city_name: "Gakona",
  },
  {
    postal_code: "16240",
    full_city_name: "Mayport, PA",
    city_name: "Mayport",
  },
  {
    postal_code: "87831",
    full_city_name: "San Acacia, NM",
    city_name: "San Acacia",
  },
  {
    postal_code: "15440",
    full_city_name: "Gibbon Glade, PA",
    city_name: "Gibbon Glade",
  },
  {
    postal_code: "62380",
    full_city_name: "West Point, IL",
    city_name: "West Point",
  },
  {
    postal_code: "38392",
    full_city_name: "Mercer, TN",
    city_name: "Mercer",
  },
  {
    postal_code: "25846",
    full_city_name: "Glen Jean, WV",
    city_name: "Glen Jean",
  },
  {
    postal_code: "70789",
    full_city_name: "Wilson, LA",
    city_name: "Wilson",
  },
  {
    postal_code: "19551",
    full_city_name: "Robesonia, PA",
    city_name: "Robesonia",
  },
  {
    postal_code: "56127",
    full_city_name: "Dunnell, MN",
    city_name: "Dunnell",
  },
  {
    postal_code: "79342",
    full_city_name: "Loop, TX",
    city_name: "Loop",
  },
  {
    postal_code: "47553",
    full_city_name: "Loogootee, IN",
    city_name: "Loogootee",
  },
  {
    postal_code: "79013",
    full_city_name: "Cactus, TX",
    city_name: "Cactus",
  },
  {
    postal_code: "26266",
    full_city_name: "Upperglade, WV",
    city_name: "Upperglade",
  },
  {
    postal_code: "15333",
    full_city_name: "Fredericktown, PA",
    city_name: "Fredericktown",
  },
  {
    postal_code: "28529",
    full_city_name: "Grantsboro, NC",
    city_name: "Grantsboro",
  },
  {
    postal_code: "60948",
    full_city_name: "Loda, IL",
    city_name: "Loda",
  },
  {
    postal_code: "51240",
    full_city_name: "Inwood, IA",
    city_name: "Inwood",
  },
  {
    postal_code: "14585",
    full_city_name: "West Bloomfield, NY",
    city_name: "West Bloomfield",
  },
  {
    postal_code: "64036",
    full_city_name: "Henrietta, MO",
    city_name: "Henrietta",
  },
  {
    postal_code: "60536",
    full_city_name: "Millbrook, IL",
    city_name: "Millbrook",
  },
  {
    postal_code: "18063",
    full_city_name: "Martins Creek, PA",
    city_name: "Martins Creek",
  },
  {
    postal_code: "47142",
    full_city_name: "Mauckport, IN",
    city_name: "Mauckport",
  },
  {
    postal_code: "12856",
    full_city_name: "North River, NY",
    city_name: "North River",
  },
  {
    postal_code: "83626",
    full_city_name: "Greenleaf, ID",
    city_name: "Greenleaf",
  },
  {
    postal_code: "41301",
    full_city_name: "Campton, KY",
    city_name: "Campton",
  },
  {
    postal_code: "68532",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "59015",
    full_city_name: "Broadview, MT",
    city_name: "Broadview",
  },
  {
    postal_code: "74082",
    full_city_name: "Vera, OK",
    city_name: "Vera",
  },
  {
    postal_code: "47521",
    full_city_name: "Celestine, IN",
    city_name: "Celestine",
  },
  {
    postal_code: "84718",
    full_city_name: "Cannonville, UT",
    city_name: "Cannonville",
  },
  {
    postal_code: "54867",
    full_city_name: "Radisson, WI",
    city_name: "Radisson",
  },
  {
    postal_code: "40146",
    full_city_name: "Irvington, KY",
    city_name: "Irvington",
  },
  {
    postal_code: "48871",
    full_city_name: "Perrinton, MI",
    city_name: "Perrinton",
  },
  {
    postal_code: "97758",
    full_city_name: "Riley, OR",
    city_name: "Riley",
  },
  {
    postal_code: "36793",
    full_city_name: "Lawley, AL",
    city_name: "Lawley",
  },
  {
    postal_code: "50575",
    full_city_name: "Pomeroy, IA",
    city_name: "Pomeroy",
  },
  {
    postal_code: "33493",
    full_city_name: "South Bay, FL",
    city_name: "South Bay",
  },
  {
    postal_code: "46340",
    full_city_name: "Hanna, IN",
    city_name: "Hanna",
  },
  {
    postal_code: "04750",
    full_city_name: "Limestone, ME",
    city_name: "Limestone",
  },
  {
    postal_code: "82643",
    full_city_name: "Midwest, WY",
    city_name: "Midwest",
  },
  {
    postal_code: "49818",
    full_city_name: "Cornell, MI",
    city_name: "Cornell",
  },
  {
    postal_code: "64120",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "26690",
    full_city_name: "Swiss, WV",
    city_name: "Swiss",
  },
  {
    postal_code: "83246",
    full_city_name: "Lava Hot Springs, ID",
    city_name: "Lava Hot Springs",
  },
  {
    postal_code: "17321",
    full_city_name: "Fawn Grove, PA",
    city_name: "Fawn Grove",
  },
  {
    postal_code: "58716",
    full_city_name: "Benedict, ND",
    city_name: "Benedict",
  },
  {
    postal_code: "51531",
    full_city_name: "Elk Horn, IA",
    city_name: "Elk Horn",
  },
  {
    postal_code: "51201",
    full_city_name: "Sheldon, IA",
    city_name: "Sheldon",
  },
  {
    postal_code: "98587",
    full_city_name: "Taholah, WA",
    city_name: "Taholah",
  },
  {
    postal_code: "64755",
    full_city_name: "Jasper, MO",
    city_name: "Jasper",
  },
  {
    postal_code: "99628",
    full_city_name: "Manokotak, AK",
    city_name: "Manokotak",
  },
  {
    postal_code: "36866",
    full_city_name: "Notasulga, AL",
    city_name: "Notasulga",
  },
  {
    postal_code: "59537",
    full_city_name: "Loring, MT",
    city_name: "Loring",
  },
  {
    postal_code: "15943",
    full_city_name: "Nanty Glo, PA",
    city_name: "Nanty Glo",
  },
  {
    postal_code: "15929",
    full_city_name: "Dilltown, PA",
    city_name: "Dilltown",
  },
  {
    postal_code: "61565",
    full_city_name: "Sparland, IL",
    city_name: "Sparland",
  },
  {
    postal_code: "68464",
    full_city_name: "Western, NE",
    city_name: "Western",
  },
  {
    postal_code: "71921",
    full_city_name: "Amity, AR",
    city_name: "Amity",
  },
  {
    postal_code: "47980",
    full_city_name: "Reynolds, IN",
    city_name: "Reynolds",
  },
  {
    postal_code: "27924",
    full_city_name: "Colerain, NC",
    city_name: "Colerain",
  },
  {
    postal_code: "71229",
    full_city_name: "Collinston, LA",
    city_name: "Collinston",
  },
  {
    postal_code: "73141",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "76828",
    full_city_name: "Burkett, TX",
    city_name: "Burkett",
  },
  {
    postal_code: "83547",
    full_city_name: "Pollock, ID",
    city_name: "Pollock",
  },
  {
    postal_code: "48654",
    full_city_name: "Rose City, MI",
    city_name: "Rose City",
  },
  {
    postal_code: "58210",
    full_city_name: "Adams, ND",
    city_name: "Adams",
  },
  {
    postal_code: "58444",
    full_city_name: "Goodrich, ND",
    city_name: "Goodrich",
  },
  {
    postal_code: "24867",
    full_city_name: "New Richmond, WV",
    city_name: "New Richmond",
  },
  {
    postal_code: "79313",
    full_city_name: "Anton, TX",
    city_name: "Anton",
  },
  {
    postal_code: "95443",
    full_city_name: "Glenhaven, CA",
    city_name: "Glenhaven",
  },
  {
    postal_code: "26270",
    full_city_name: "Harman, WV",
    city_name: "Harman",
  },
  {
    postal_code: "22436",
    full_city_name: "Caret, VA",
    city_name: "Caret",
  },
  {
    postal_code: "39092",
    full_city_name: "Lake, MS",
    city_name: "Lake",
  },
  {
    postal_code: "35971",
    full_city_name: "Fyffe, AL",
    city_name: "Fyffe",
  },
  {
    postal_code: "12786",
    full_city_name: "White Lake, NY",
    city_name: "White Lake",
  },
  {
    postal_code: "41219",
    full_city_name: "Flatgap, KY",
    city_name: "Flatgap",
  },
  {
    postal_code: "49780",
    full_city_name: "Rudyard, MI",
    city_name: "Rudyard",
  },
  {
    postal_code: "86343",
    full_city_name: "Crown King, AZ",
    city_name: "Crown King",
  },
  {
    postal_code: "68746",
    full_city_name: "Lynch, NE",
    city_name: "Lynch",
  },
  {
    postal_code: "25678",
    full_city_name: "Matewan, WV",
    city_name: "Matewan",
  },
  {
    postal_code: "25061",
    full_city_name: "Drybranch, WV",
    city_name: "Drybranch",
  },
  {
    postal_code: "52323",
    full_city_name: "Oxford Junction, IA",
    city_name: "Oxford Junction",
  },
  {
    postal_code: "75450",
    full_city_name: "Lake Creek, TX",
    city_name: "Lake Creek",
  },
  {
    postal_code: "98539",
    full_city_name: "Doty, WA",
    city_name: "Doty",
  },
  {
    postal_code: "03605",
    full_city_name: "Lempster, NH",
    city_name: "Lempster",
  },
  {
    postal_code: "70719",
    full_city_name: "Brusly, LA",
    city_name: "Brusly",
  },
  {
    postal_code: "52774",
    full_city_name: "Welton, IA",
    city_name: "Welton",
  },
  {
    postal_code: "56239",
    full_city_name: "Ghent, MN",
    city_name: "Ghent",
  },
  {
    postal_code: "24581",
    full_city_name: "Norwood, VA",
    city_name: "Norwood",
  },
  {
    postal_code: "90058",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "74554",
    full_city_name: "Krebs, OK",
    city_name: "Krebs",
  },
  {
    postal_code: "95542",
    full_city_name: "Garberville, CA",
    city_name: "Garberville",
  },
  {
    postal_code: "21523",
    full_city_name: "Bloomington, MD",
    city_name: "Bloomington",
  },
  {
    postal_code: "99741",
    full_city_name: "Galena, AK",
    city_name: "Galena",
  },
  {
    postal_code: "61311",
    full_city_name: "Ancona, IL",
    city_name: "Ancona",
  },
  {
    postal_code: "15934",
    full_city_name: "Elton, PA",
    city_name: "Elton",
  },
  {
    postal_code: "41772",
    full_city_name: "Vest, KY",
    city_name: "Vest",
  },
  {
    postal_code: "03593",
    full_city_name: "Randolph, NH",
    city_name: "Randolph",
  },
  {
    postal_code: "25043",
    full_city_name: "Clay, WV",
    city_name: "Clay",
  },
  {
    postal_code: "38034",
    full_city_name: "Friendship, TN",
    city_name: "Friendship",
  },
  {
    postal_code: "24984",
    full_city_name: "Waiteville, WV",
    city_name: "Waiteville",
  },
  {
    postal_code: "52328",
    full_city_name: "Robins, IA",
    city_name: "Robins",
  },
  {
    postal_code: "05824",
    full_city_name: "Concord, VT",
    city_name: "Concord",
  },
  {
    postal_code: "65779",
    full_city_name: "Wheatland, MO",
    city_name: "Wheatland",
  },
  {
    postal_code: "25917",
    full_city_name: "Scarbro, WV",
    city_name: "Scarbro",
  },
  {
    postal_code: "31622",
    full_city_name: "Alapaha, GA",
    city_name: "Alapaha",
  },
  {
    postal_code: "66431",
    full_city_name: "Harveyville, KS",
    city_name: "Harveyville",
  },
  {
    postal_code: "26321",
    full_city_name: "Alum Bridge, WV",
    city_name: "Alum Bridge",
  },
  {
    postal_code: "58032",
    full_city_name: "Forman, ND",
    city_name: "Forman",
  },
  {
    postal_code: "73673",
    full_city_name: "Willow, OK",
    city_name: "Willow",
  },
  {
    postal_code: "51342",
    full_city_name: "Graettinger, IA",
    city_name: "Graettinger",
  },
  {
    postal_code: "21530",
    full_city_name: "Flintstone, MD",
    city_name: "Flintstone",
  },
  {
    postal_code: "61722",
    full_city_name: "Arrowsmith, IL",
    city_name: "Arrowsmith",
  },
  {
    postal_code: "16695",
    full_city_name: "Woodbury, PA",
    city_name: "Woodbury",
  },
  {
    postal_code: "63943",
    full_city_name: "Grandin, MO",
    city_name: "Grandin",
  },
  {
    postal_code: "63561",
    full_city_name: "Queen City, MO",
    city_name: "Queen City",
  },
  {
    postal_code: "19025",
    full_city_name: "Dresher, PA",
    city_name: "Dresher",
  },
  {
    postal_code: "84638",
    full_city_name: "Leamington, UT",
    city_name: "Leamington",
  },
  {
    postal_code: "72636",
    full_city_name: "Gilbert, AR",
    city_name: "Gilbert",
  },
  {
    postal_code: "15778",
    full_city_name: "Timblin, PA",
    city_name: "Timblin",
  },
  {
    postal_code: "51054",
    full_city_name: "Sergeant Bluff, IA",
    city_name: "Sergeant Bluff",
  },
  {
    postal_code: "94569",
    full_city_name: "Port Costa, CA",
    city_name: "Port Costa",
  },
  {
    postal_code: "85533",
    full_city_name: "Clifton, AZ",
    city_name: "Clifton",
  },
  {
    postal_code: "99129",
    full_city_name: "Fruitland, WA",
    city_name: "Fruitland",
  },
  {
    postal_code: "93208",
    full_city_name: "Camp Nelson, CA",
    city_name: "Camp Nelson",
  },
  {
    postal_code: "42204",
    full_city_name: "Allensville, KY",
    city_name: "Allensville",
  },
  {
    postal_code: "01032",
    full_city_name: "Goshen, MA",
    city_name: "Goshen",
  },
  {
    postal_code: "75933",
    full_city_name: "Call, TX",
    city_name: "Call",
  },
  {
    postal_code: "57369",
    full_city_name: "Platte, SD",
    city_name: "Platte",
  },
  {
    postal_code: "61025",
    full_city_name: "East Dubuque, IL",
    city_name: "East Dubuque",
  },
  {
    postal_code: "41764",
    full_city_name: "Smilax, KY",
    city_name: "Smilax",
  },
  {
    postal_code: "72016",
    full_city_name: "Bigelow, AR",
    city_name: "Bigelow",
  },
  {
    postal_code: "52044",
    full_city_name: "Elkport, IA",
    city_name: "Elkport",
  },
  {
    postal_code: "73939",
    full_city_name: "Goodwell, OK",
    city_name: "Goodwell",
  },
  {
    postal_code: "53543",
    full_city_name: "Highland, WI",
    city_name: "Highland",
  },
  {
    postal_code: "51056",
    full_city_name: "Smithland, IA",
    city_name: "Smithland",
  },
  {
    postal_code: "57474",
    full_city_name: "Stratford, SD",
    city_name: "Stratford",
  },
  {
    postal_code: "01341",
    full_city_name: "Conway, MA",
    city_name: "Conway",
  },
  {
    postal_code: "15421",
    full_city_name: "Chalk Hill, PA",
    city_name: "Chalk Hill",
  },
  {
    postal_code: "57385",
    full_city_name: "Woonsocket, SD",
    city_name: "Woonsocket",
  },
  {
    postal_code: "37079",
    full_city_name: "Indian Mound, TN",
    city_name: "Indian Mound",
  },
  {
    postal_code: "76858",
    full_city_name: "Melvin, TX",
    city_name: "Melvin",
  },
  {
    postal_code: "04667",
    full_city_name: "Perry, ME",
    city_name: "Perry",
  },
  {
    postal_code: "64745",
    full_city_name: "Foster, MO",
    city_name: "Foster",
  },
  {
    postal_code: "25928",
    full_city_name: "Stephenson, WV",
    city_name: "Stephenson",
  },
  {
    postal_code: "05658",
    full_city_name: "Marshfield, VT",
    city_name: "Marshfield",
  },
  {
    postal_code: "76070",
    full_city_name: "Nemo, TX",
    city_name: "Nemo",
  },
  {
    postal_code: "78837",
    full_city_name: "Comstock, TX",
    city_name: "Comstock",
  },
  {
    postal_code: "61370",
    full_city_name: "Tonica, IL",
    city_name: "Tonica",
  },
  {
    postal_code: "95698",
    full_city_name: "Zamora, CA",
    city_name: "Zamora",
  },
  {
    postal_code: "16263",
    full_city_name: "Yatesboro, PA",
    city_name: "Yatesboro",
  },
  {
    postal_code: "62941",
    full_city_name: "Grand Chain, IL",
    city_name: "Grand Chain",
  },
  {
    postal_code: "31711",
    full_city_name: "Andersonville, GA",
    city_name: "Andersonville",
  },
  {
    postal_code: "57278",
    full_city_name: "Willow Lake, SD",
    city_name: "Willow Lake",
  },
  {
    postal_code: "62464",
    full_city_name: "Stoy, IL",
    city_name: "Stoy",
  },
  {
    postal_code: "60952",
    full_city_name: "Melvin, IL",
    city_name: "Melvin",
  },
  {
    postal_code: "28363",
    full_city_name: "Marston, NC",
    city_name: "Marston",
  },
  {
    postal_code: "33039",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "84716",
    full_city_name: "Boulder, UT",
    city_name: "Boulder",
  },
  {
    postal_code: "55366",
    full_city_name: "New Auburn, MN",
    city_name: "New Auburn",
  },
  {
    postal_code: "28611",
    full_city_name: "Collettsville, NC",
    city_name: "Collettsville",
  },
  {
    postal_code: "84736",
    full_city_name: "Henrieville, UT",
    city_name: "Henrieville",
  },
  {
    postal_code: "26847",
    full_city_name: "Petersburg, WV",
    city_name: "Petersburg",
  },
  {
    postal_code: "41821",
    full_city_name: "Hallie, KY",
    city_name: "Hallie",
  },
  {
    postal_code: "68642",
    full_city_name: "Humphrey, NE",
    city_name: "Humphrey",
  },
  {
    postal_code: "59033",
    full_city_name: "Greycliff, MT",
    city_name: "Greycliff",
  },
  {
    postal_code: "49234",
    full_city_name: "Clarklake, MI",
    city_name: "Clarklake",
  },
  {
    postal_code: "61233",
    full_city_name: "Andover, IL",
    city_name: "Andover",
  },
  {
    postal_code: "87021",
    full_city_name: "Milan, NM",
    city_name: "Milan",
  },
  {
    postal_code: "54977",
    full_city_name: "Scandinavia, WI",
    city_name: "Scandinavia",
  },
  {
    postal_code: "05457",
    full_city_name: "Franklin, VT",
    city_name: "Franklin",
  },
  {
    postal_code: "74826",
    full_city_name: "Asher, OK",
    city_name: "Asher",
  },
  {
    postal_code: "32686",
    full_city_name: "Reddick, FL",
    city_name: "Reddick",
  },
  {
    postal_code: "19435",
    full_city_name: "Frederick, PA",
    city_name: "Frederick",
  },
  {
    postal_code: "52070",
    full_city_name: "Sabula, IA",
    city_name: "Sabula",
  },
  {
    postal_code: "46035",
    full_city_name: "Colfax, IN",
    city_name: "Colfax",
  },
  {
    postal_code: "47616",
    full_city_name: "Griffin, IN",
    city_name: "Griffin",
  },
  {
    postal_code: "32094",
    full_city_name: "Wellborn, FL",
    city_name: "Wellborn",
  },
  {
    postal_code: "05071",
    full_city_name: "South Woodstock, VT",
    city_name: "South Woodstock",
  },
  {
    postal_code: "12955",
    full_city_name: "Lyon Mountain, NY",
    city_name: "Lyon Mountain",
  },
  {
    postal_code: "52777",
    full_city_name: "Wheatland, IA",
    city_name: "Wheatland",
  },
  {
    postal_code: "50160",
    full_city_name: "Martensdale, IA",
    city_name: "Martensdale",
  },
  {
    postal_code: "15533",
    full_city_name: "Breezewood, PA",
    city_name: "Breezewood",
  },
  {
    postal_code: "27821",
    full_city_name: "Edward, NC",
    city_name: "Edward",
  },
  {
    postal_code: "17352",
    full_city_name: "New Park, PA",
    city_name: "New Park",
  },
  {
    postal_code: "68716",
    full_city_name: "Beemer, NE",
    city_name: "Beemer",
  },
  {
    postal_code: "75974",
    full_city_name: "Tenaha, TX",
    city_name: "Tenaha",
  },
  {
    postal_code: "99768",
    full_city_name: "Ruby, AK",
    city_name: "Ruby",
  },
  {
    postal_code: "96718",
    full_city_name: "Hawaii National Park, HI",
    city_name: "Hawaii National Park",
  },
  {
    postal_code: "36744",
    full_city_name: "Greensboro, AL",
    city_name: "Greensboro",
  },
  {
    postal_code: "48727",
    full_city_name: "Clifford, MI",
    city_name: "Clifford",
  },
  {
    postal_code: "46162",
    full_city_name: "Needham, IN",
    city_name: "Needham",
  },
  {
    postal_code: "61735",
    full_city_name: "Dewitt, IL",
    city_name: "Dewitt",
  },
  {
    postal_code: "39657",
    full_city_name: "Osyka, MS",
    city_name: "Osyka",
  },
  {
    postal_code: "62280",
    full_city_name: "Rockwood, IL",
    city_name: "Rockwood",
  },
  {
    postal_code: "40958",
    full_city_name: "Kettle Island, KY",
    city_name: "Kettle Island",
  },
  {
    postal_code: "70549",
    full_city_name: "Lake Arthur, LA",
    city_name: "Lake Arthur",
  },
  {
    postal_code: "72739",
    full_city_name: "Hiwasse, AR",
    city_name: "Hiwasse",
  },
  {
    postal_code: "68934",
    full_city_name: "Deweese, NE",
    city_name: "Deweese",
  },
  {
    postal_code: "03579",
    full_city_name: "Errol, NH",
    city_name: "Errol",
  },
  {
    postal_code: "13155",
    full_city_name: "South Otselic, NY",
    city_name: "South Otselic",
  },
  {
    postal_code: "68924",
    full_city_name: "Axtell, NE",
    city_name: "Axtell",
  },
  {
    postal_code: "60961",
    full_city_name: "Reddick, IL",
    city_name: "Reddick",
  },
  {
    postal_code: "67481",
    full_city_name: "Sylvan Grove, KS",
    city_name: "Sylvan Grove",
  },
  {
    postal_code: "57362",
    full_city_name: "Miller, SD",
    city_name: "Miller",
  },
  {
    postal_code: "47884",
    full_city_name: "Universal, IN",
    city_name: "Universal",
  },
  {
    postal_code: "13324",
    full_city_name: "Cold Brook, NY",
    city_name: "Cold Brook",
  },
  {
    postal_code: "76445",
    full_city_name: "Desdemona, TX",
    city_name: "Desdemona",
  },
  {
    postal_code: "69340",
    full_city_name: "Ellsworth, NE",
    city_name: "Ellsworth",
  },
  {
    postal_code: "59231",
    full_city_name: "Saint Marie, MT",
    city_name: "Saint Marie",
  },
  {
    postal_code: "05083",
    full_city_name: "West Fairlee, VT",
    city_name: "West Fairlee",
  },
  {
    postal_code: "47948",
    full_city_name: "Goodland, IN",
    city_name: "Goodland",
  },
  {
    postal_code: "24855",
    full_city_name: "Kyle, WV",
    city_name: "Kyle",
  },
  {
    postal_code: "16843",
    full_city_name: "Hyde, PA",
    city_name: "Hyde",
  },
  {
    postal_code: "25865",
    full_city_name: "Lester, WV",
    city_name: "Lester",
  },
  {
    postal_code: "57029",
    full_city_name: "Freeman, SD",
    city_name: "Freeman",
  },
  {
    postal_code: "36069",
    full_city_name: "Ramer, AL",
    city_name: "Ramer",
  },
  {
    postal_code: "13436",
    full_city_name: "Raquette Lake, NY",
    city_name: "Raquette Lake",
  },
  {
    postal_code: "17353",
    full_city_name: "Orrtanna, PA",
    city_name: "Orrtanna",
  },
  {
    postal_code: "83433",
    full_city_name: "Macks Inn, ID",
    city_name: "Macks Inn",
  },
  {
    postal_code: "12812",
    full_city_name: "Blue Mountain Lake, NY",
    city_name: "Blue Mountain Lake",
  },
  {
    postal_code: "57457",
    full_city_name: "Long Lake, SD",
    city_name: "Long Lake",
  },
  {
    postal_code: "21715",
    full_city_name: "Brownsville, MD",
    city_name: "Brownsville",
  },
  {
    postal_code: "31421",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "59724",
    full_city_name: "Dell, MT",
    city_name: "Dell",
  },
  {
    postal_code: "43524",
    full_city_name: "Hamler, OH",
    city_name: "Hamler",
  },
  {
    postal_code: "39665",
    full_city_name: "Sontag, MS",
    city_name: "Sontag",
  },
  {
    postal_code: "64688",
    full_city_name: "Wheeling, MO",
    city_name: "Wheeling",
  },
  {
    postal_code: "49030",
    full_city_name: "Burr Oak, MI",
    city_name: "Burr Oak",
  },
  {
    postal_code: "31781",
    full_city_name: "Poulan, GA",
    city_name: "Poulan",
  },
  {
    postal_code: "68717",
    full_city_name: "Belden, NE",
    city_name: "Belden",
  },
  {
    postal_code: "36279",
    full_city_name: "Wellington, AL",
    city_name: "Wellington",
  },
  {
    postal_code: "39668",
    full_city_name: "Union Church, MS",
    city_name: "Union Church",
  },
  {
    postal_code: "57430",
    full_city_name: "Britton, SD",
    city_name: "Britton",
  },
  {
    postal_code: "73559",
    full_city_name: "Mountain Park, OK",
    city_name: "Mountain Park",
  },
  {
    postal_code: "05907",
    full_city_name: "Norton, VT",
    city_name: "Norton",
  },
  {
    postal_code: "05360",
    full_city_name: "West Wardsboro, VT",
    city_name: "West Wardsboro",
  },
  {
    postal_code: "14819",
    full_city_name: "Cameron, NY",
    city_name: "Cameron",
  },
  {
    postal_code: "61872",
    full_city_name: "Sadorus, IL",
    city_name: "Sadorus",
  },
  {
    postal_code: "17760",
    full_city_name: "North Bend, PA",
    city_name: "North Bend",
  },
  {
    postal_code: "05073",
    full_city_name: "Taftsville, VT",
    city_name: "Taftsville",
  },
  {
    postal_code: "36051",
    full_city_name: "Marbury, AL",
    city_name: "Marbury",
  },
  {
    postal_code: "58467",
    full_city_name: "Medina, ND",
    city_name: "Medina",
  },
  {
    postal_code: "67137",
    full_city_name: "Severy, KS",
    city_name: "Severy",
  },
  {
    postal_code: "67515",
    full_city_name: "Arnold, KS",
    city_name: "Arnold",
  },
  {
    postal_code: "79245",
    full_city_name: "Memphis, TX",
    city_name: "Memphis",
  },
  {
    postal_code: "46180",
    full_city_name: "Stilesville, IN",
    city_name: "Stilesville",
  },
  {
    postal_code: "03854",
    full_city_name: "New Castle, NH",
    city_name: "New Castle",
  },
  {
    postal_code: "61489",
    full_city_name: "Williamsfield, IL",
    city_name: "Williamsfield",
  },
  {
    postal_code: "45147",
    full_city_name: "Miamiville, OH",
    city_name: "Miamiville",
  },
  {
    postal_code: "65784",
    full_city_name: "Zanoni, MO",
    city_name: "Zanoni",
  },
  {
    postal_code: "74562",
    full_city_name: "Rattan, OK",
    city_name: "Rattan",
  },
  {
    postal_code: "36901",
    full_city_name: "Bellamy, AL",
    city_name: "Bellamy",
  },
  {
    postal_code: "93218",
    full_city_name: "Ducor, CA",
    city_name: "Ducor",
  },
  {
    postal_code: "59069",
    full_city_name: "Reed Point, MT",
    city_name: "Reed Point",
  },
  {
    postal_code: "02791",
    full_city_name: "Westport Point, MA",
    city_name: "Westport Point",
  },
  {
    postal_code: "24258",
    full_city_name: "Hiltons, VA",
    city_name: "Hiltons",
  },
  {
    postal_code: "08858",
    full_city_name: "Oldwick, NJ",
    city_name: "Oldwick",
  },
  {
    postal_code: "65620",
    full_city_name: "Bruner, MO",
    city_name: "Bruner",
  },
  {
    postal_code: "52553",
    full_city_name: "Eddyville, IA",
    city_name: "Eddyville",
  },
  {
    postal_code: "41553",
    full_city_name: "Phelps, KY",
    city_name: "Phelps",
  },
  {
    postal_code: "74363",
    full_city_name: "Quapaw, OK",
    city_name: "Quapaw",
  },
  {
    postal_code: "14126",
    full_city_name: "Olcott, NY",
    city_name: "Olcott",
  },
  {
    postal_code: "62058",
    full_city_name: "Livingston, IL",
    city_name: "Livingston",
  },
  {
    postal_code: "60553",
    full_city_name: "Steward, IL",
    city_name: "Steward",
  },
  {
    postal_code: "68768",
    full_city_name: "Pilger, NE",
    city_name: "Pilger",
  },
  {
    postal_code: "56023",
    full_city_name: "Delavan, MN",
    city_name: "Delavan",
  },
  {
    postal_code: "83846",
    full_city_name: "Mullan, ID",
    city_name: "Mullan",
  },
  {
    postal_code: "71039",
    full_city_name: "Heflin, LA",
    city_name: "Heflin",
  },
  {
    postal_code: "18051",
    full_city_name: "Fogelsville, PA",
    city_name: "Fogelsville",
  },
  {
    postal_code: "71933",
    full_city_name: "Bonnerdale, AR",
    city_name: "Bonnerdale",
  },
  {
    postal_code: "62255",
    full_city_name: "Lenzburg, IL",
    city_name: "Lenzburg",
  },
  {
    postal_code: "14170",
    full_city_name: "West Falls, NY",
    city_name: "West Falls",
  },
  {
    postal_code: "68870",
    full_city_name: "Riverdale, NE",
    city_name: "Riverdale",
  },
  {
    postal_code: "78344",
    full_city_name: "Bruni, TX",
    city_name: "Bruni",
  },
  {
    postal_code: "77446",
    full_city_name: "Prairie View, TX",
    city_name: "Prairie View",
  },
  {
    postal_code: "66612",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "99018",
    full_city_name: "Latah, WA",
    city_name: "Latah",
  },
  {
    postal_code: "78742",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "94528",
    full_city_name: "Diablo, CA",
    city_name: "Diablo",
  },
  {
    postal_code: "63748",
    full_city_name: "Frohna, MO",
    city_name: "Frohna",
  },
  {
    postal_code: "24279",
    full_city_name: "Pound, VA",
    city_name: "Pound",
  },
  {
    postal_code: "71264",
    full_city_name: "Oak Ridge, LA",
    city_name: "Oak Ridge",
  },
  {
    postal_code: "04263",
    full_city_name: "Leeds, ME",
    city_name: "Leeds",
  },
  {
    postal_code: "56036",
    full_city_name: "Glenville, MN",
    city_name: "Glenville",
  },
  {
    postal_code: "50833",
    full_city_name: "Bedford, IA",
    city_name: "Bedford",
  },
  {
    postal_code: "56285",
    full_city_name: "Sacred Heart, MN",
    city_name: "Sacred Heart",
  },
  {
    postal_code: "13318",
    full_city_name: "Cassville, NY",
    city_name: "Cassville",
  },
  {
    postal_code: "05494",
    full_city_name: "Westford, VT",
    city_name: "Westford",
  },
  {
    postal_code: "04238",
    full_city_name: "Hebron, ME",
    city_name: "Hebron",
  },
  {
    postal_code: "08212",
    full_city_name: "Cape May Point, NJ",
    city_name: "Cape May Point",
  },
  {
    postal_code: "97364",
    full_city_name: "Neotsu, OR",
    city_name: "Neotsu",
  },
  {
    postal_code: "07029",
    full_city_name: "Harrison, NJ",
    city_name: "Harrison",
  },
  {
    postal_code: "03852",
    full_city_name: "Milton Mills, NH",
    city_name: "Milton Mills",
  },
  {
    postal_code: "46791",
    full_city_name: "Uniondale, IN",
    city_name: "Uniondale",
  },
  {
    postal_code: "41540",
    full_city_name: "Lick Creek, KY",
    city_name: "Lick Creek",
  },
  {
    postal_code: "80821",
    full_city_name: "Hugo, CO",
    city_name: "Hugo",
  },
  {
    postal_code: "74956",
    full_city_name: "Shady Point, OK",
    city_name: "Shady Point",
  },
  {
    postal_code: "08241",
    full_city_name: "Port Republic, NJ",
    city_name: "Port Republic",
  },
  {
    postal_code: "49248",
    full_city_name: "Jasper, MI",
    city_name: "Jasper",
  },
  {
    postal_code: "67202",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "59523",
    full_city_name: "Chinook, MT",
    city_name: "Chinook",
  },
  {
    postal_code: "52209",
    full_city_name: "Blairstown, IA",
    city_name: "Blairstown",
  },
  {
    postal_code: "58341",
    full_city_name: "Harvey, ND",
    city_name: "Harvey",
  },
  {
    postal_code: "68787",
    full_city_name: "Wayne, NE",
    city_name: "Wayne",
  },
  {
    postal_code: "77664",
    full_city_name: "Warren, TX",
    city_name: "Warren",
  },
  {
    postal_code: "17538",
    full_city_name: "Landisville, PA",
    city_name: "Landisville",
  },
  {
    postal_code: "32603",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "46723",
    full_city_name: "Churubusco, IN",
    city_name: "Churubusco",
  },
  {
    postal_code: "12733",
    full_city_name: "Fallsburg, NY",
    city_name: "Fallsburg",
  },
  {
    postal_code: "77660",
    full_city_name: "Spurger, TX",
    city_name: "Spurger",
  },
  {
    postal_code: "15535",
    full_city_name: "Clearville, PA",
    city_name: "Clearville",
  },
  {
    postal_code: "99683",
    full_city_name: "Trapper Creek, AK",
    city_name: "Trapper Creek",
  },
  {
    postal_code: "24483",
    full_city_name: "Vesuvius, VA",
    city_name: "Vesuvius",
  },
  {
    postal_code: "20675",
    full_city_name: "Pomfret, MD",
    city_name: "Pomfret",
  },
  {
    postal_code: "39823",
    full_city_name: "Blakely, GA",
    city_name: "Blakely",
  },
  {
    postal_code: "74902",
    full_city_name: "Pocola, OK",
    city_name: "Pocola",
  },
  {
    postal_code: "36522",
    full_city_name: "Citronelle, AL",
    city_name: "Citronelle",
  },
  {
    postal_code: "35563",
    full_city_name: "Guin, AL",
    city_name: "Guin",
  },
  {
    postal_code: "38456",
    full_city_name: "Ethridge, TN",
    city_name: "Ethridge",
  },
  {
    postal_code: "10930",
    full_city_name: "Highland Mills, NY",
    city_name: "Highland Mills",
  },
  {
    postal_code: "72856",
    full_city_name: "Pelsor, AR",
    city_name: "Pelsor",
  },
  {
    postal_code: "07063",
    full_city_name: "Plainfield, NJ",
    city_name: "Plainfield",
  },
  {
    postal_code: "32446",
    full_city_name: "Marianna, FL",
    city_name: "Marianna",
  },
  {
    postal_code: "54411",
    full_city_name: "Athens, WI",
    city_name: "Athens",
  },
  {
    postal_code: "49435",
    full_city_name: "Marne, MI",
    city_name: "Marne",
  },
  {
    postal_code: "39638",
    full_city_name: "Gloster, MS",
    city_name: "Gloster",
  },
  {
    postal_code: "48893",
    full_city_name: "Weidman, MI",
    city_name: "Weidman",
  },
  {
    postal_code: "55725",
    full_city_name: "Crane Lake, MN",
    city_name: "Crane Lake",
  },
  {
    postal_code: "40178",
    full_city_name: "Westview, KY",
    city_name: "Westview",
  },
  {
    postal_code: "52747",
    full_city_name: "Durant, IA",
    city_name: "Durant",
  },
  {
    postal_code: "07628",
    full_city_name: "Dumont, NJ",
    city_name: "Dumont",
  },
  {
    postal_code: "14757",
    full_city_name: "Mayville, NY",
    city_name: "Mayville",
  },
  {
    postal_code: "47031",
    full_city_name: "Milan, IN",
    city_name: "Milan",
  },
  {
    postal_code: "35546",
    full_city_name: "Berry, AL",
    city_name: "Berry",
  },
  {
    postal_code: "71846",
    full_city_name: "Lockesburg, AR",
    city_name: "Lockesburg",
  },
  {
    postal_code: "76432",
    full_city_name: "Blanket, TX",
    city_name: "Blanket",
  },
  {
    postal_code: "71417",
    full_city_name: "Colfax, LA",
    city_name: "Colfax",
  },
  {
    postal_code: "67526",
    full_city_name: "Ellinwood, KS",
    city_name: "Ellinwood",
  },
  {
    postal_code: "04626",
    full_city_name: "Cutler, ME",
    city_name: "Cutler",
  },
  {
    postal_code: "17961",
    full_city_name: "Orwigsburg, PA",
    city_name: "Orwigsburg",
  },
  {
    postal_code: "72857",
    full_city_name: "Plainview, AR",
    city_name: "Plainview",
  },
  {
    postal_code: "42337",
    full_city_name: "Drakesboro, KY",
    city_name: "Drakesboro",
  },
  {
    postal_code: "12751",
    full_city_name: "Kiamesha Lake, NY",
    city_name: "Kiamesha Lake",
  },
  {
    postal_code: "23487",
    full_city_name: "Windsor, VA",
    city_name: "Windsor",
  },
  {
    postal_code: "82633",
    full_city_name: "Douglas, WY",
    city_name: "Douglas",
  },
  {
    postal_code: "23301",
    full_city_name: "Accomac, VA",
    city_name: "Accomac",
  },
  {
    postal_code: "96717",
    full_city_name: "Hauula, HI",
    city_name: "Hauula",
  },
  {
    postal_code: "31557",
    full_city_name: "Patterson, GA",
    city_name: "Patterson",
  },
  {
    postal_code: "22713",
    full_city_name: "Boston, VA",
    city_name: "Boston",
  },
  {
    postal_code: "72459",
    full_city_name: "Ravenden, AR",
    city_name: "Ravenden",
  },
  {
    postal_code: "97487",
    full_city_name: "Veneta, OR",
    city_name: "Veneta",
  },
  {
    postal_code: "49705",
    full_city_name: "Afton, MI",
    city_name: "Afton",
  },
  {
    postal_code: "59538",
    full_city_name: "Malta, MT",
    city_name: "Malta",
  },
  {
    postal_code: "14733",
    full_city_name: "Falconer, NY",
    city_name: "Falconer",
  },
  {
    postal_code: "68739",
    full_city_name: "Hartington, NE",
    city_name: "Hartington",
  },
  {
    postal_code: "82925",
    full_city_name: "Cora, WY",
    city_name: "Cora",
  },
  {
    postal_code: "21659",
    full_city_name: "Rhodesdale, MD",
    city_name: "Rhodesdale",
  },
  {
    postal_code: "22715",
    full_city_name: "Brightwood, VA",
    city_name: "Brightwood",
  },
  {
    postal_code: "08027",
    full_city_name: "Gibbstown, NJ",
    city_name: "Gibbstown",
  },
  {
    postal_code: "37175",
    full_city_name: "Stewart, TN",
    city_name: "Stewart",
  },
  {
    postal_code: "17021",
    full_city_name: "East Waterford, PA",
    city_name: "East Waterford",
  },
  {
    postal_code: "14729",
    full_city_name: "East Otto, NY",
    city_name: "East Otto",
  },
  {
    postal_code: "57580",
    full_city_name: "Winner, SD",
    city_name: "Winner",
  },
  {
    postal_code: "14477",
    full_city_name: "Kent, NY",
    city_name: "Kent",
  },
  {
    postal_code: "05770",
    full_city_name: "Shoreham, VT",
    city_name: "Shoreham",
  },
  {
    postal_code: "17965",
    full_city_name: "Port Carbon, PA",
    city_name: "Port Carbon",
  },
  {
    postal_code: "36612",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "80938",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "38333",
    full_city_name: "Eva, TN",
    city_name: "Eva",
  },
  {
    postal_code: "54469",
    full_city_name: "Port Edwards, WI",
    city_name: "Port Edwards",
  },
  {
    postal_code: "73626",
    full_city_name: "Canute, OK",
    city_name: "Canute",
  },
  {
    postal_code: "31641",
    full_city_name: "Naylor, GA",
    city_name: "Naylor",
  },
  {
    postal_code: "70770",
    full_city_name: "Pride, LA",
    city_name: "Pride",
  },
  {
    postal_code: "72411",
    full_city_name: "Bay, AR",
    city_name: "Bay",
  },
  {
    postal_code: "03771",
    full_city_name: "Monroe, NH",
    city_name: "Monroe",
  },
  {
    postal_code: "07465",
    full_city_name: "Wanaque, NJ",
    city_name: "Wanaque",
  },
  {
    postal_code: "71485",
    full_city_name: "Woodworth, LA",
    city_name: "Woodworth",
  },
  {
    postal_code: "67490",
    full_city_name: "Wilson, KS",
    city_name: "Wilson",
  },
  {
    postal_code: "07607",
    full_city_name: "Maywood, NJ",
    city_name: "Maywood",
  },
  {
    postal_code: "48353",
    full_city_name: "Hartland, MI",
    city_name: "Hartland",
  },
  {
    postal_code: "53033",
    full_city_name: "Hubertus, WI",
    city_name: "Hubertus",
  },
  {
    postal_code: "05086",
    full_city_name: "West Topsham, VT",
    city_name: "West Topsham",
  },
  {
    postal_code: "01034",
    full_city_name: "Granville, MA",
    city_name: "Granville",
  },
  {
    postal_code: "56587",
    full_city_name: "Vergas, MN",
    city_name: "Vergas",
  },
  {
    postal_code: "49425",
    full_city_name: "Holton, MI",
    city_name: "Holton",
  },
  {
    postal_code: "45780",
    full_city_name: "The Plains, OH",
    city_name: "The Plains",
  },
  {
    postal_code: "32428",
    full_city_name: "Chipley, FL",
    city_name: "Chipley",
  },
  {
    postal_code: "24613",
    full_city_name: "Falls Mills, VA",
    city_name: "Falls Mills",
  },
  {
    postal_code: "04956",
    full_city_name: "New Vineyard, ME",
    city_name: "New Vineyard",
  },
  {
    postal_code: "96071",
    full_city_name: "Old Station, CA",
    city_name: "Old Station",
  },
  {
    postal_code: "53205",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "14586",
    full_city_name: "West Henrietta, NY",
    city_name: "West Henrietta",
  },
  {
    postal_code: "21779",
    full_city_name: "Rohrersville, MD",
    city_name: "Rohrersville",
  },
  {
    postal_code: "18437",
    full_city_name: "Lake Como, PA",
    city_name: "Lake Como",
  },
  {
    postal_code: "58047",
    full_city_name: "Horace, ND",
    city_name: "Horace",
  },
  {
    postal_code: "08880",
    full_city_name: "South Bound Brook, NJ",
    city_name: "South Bound Brook",
  },
  {
    postal_code: "61038",
    full_city_name: "Garden Prairie, IL",
    city_name: "Garden Prairie",
  },
  {
    postal_code: "71064",
    full_city_name: "Plain Dealing, LA",
    city_name: "Plain Dealing",
  },
  {
    postal_code: "58793",
    full_city_name: "Westhope, ND",
    city_name: "Westhope",
  },
  {
    postal_code: "27212",
    full_city_name: "Blanch, NC",
    city_name: "Blanch",
  },
  {
    postal_code: "49915",
    full_city_name: "Caspian, MI",
    city_name: "Caspian",
  },
  {
    postal_code: "35446",
    full_city_name: "Buhl, AL",
    city_name: "Buhl",
  },
  {
    postal_code: "97818",
    full_city_name: "Boardman, OR",
    city_name: "Boardman",
  },
  {
    postal_code: "40348",
    full_city_name: "Millersburg, KY",
    city_name: "Millersburg",
  },
  {
    postal_code: "73117",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "48085",
    full_city_name: "Troy, MI",
    city_name: "Troy",
  },
  {
    postal_code: "04982",
    full_city_name: "Stratton, ME",
    city_name: "Stratton",
  },
  {
    postal_code: "31046",
    full_city_name: "Juliette, GA",
    city_name: "Juliette",
  },
  {
    postal_code: "84725",
    full_city_name: "Enterprise, UT",
    city_name: "Enterprise",
  },
  {
    postal_code: "79084",
    full_city_name: "Stratford, TX",
    city_name: "Stratford",
  },
  {
    postal_code: "86331",
    full_city_name: "Jerome, AZ",
    city_name: "Jerome",
  },
  {
    postal_code: "29481",
    full_city_name: "Smoaks, SC",
    city_name: "Smoaks",
  },
  {
    postal_code: "17345",
    full_city_name: "Manchester, PA",
    city_name: "Manchester",
  },
  {
    postal_code: "72940",
    full_city_name: "Huntington, AR",
    city_name: "Huntington",
  },
  {
    postal_code: "65635",
    full_city_name: "Dadeville, MO",
    city_name: "Dadeville",
  },
  {
    postal_code: "95938",
    full_city_name: "Durham, CA",
    city_name: "Durham",
  },
  {
    postal_code: "31561",
    full_city_name: "Sea Island, GA",
    city_name: "Sea Island",
  },
  {
    postal_code: "54211",
    full_city_name: "Ephraim, WI",
    city_name: "Ephraim",
  },
  {
    postal_code: "31716",
    full_city_name: "Baconton, GA",
    city_name: "Baconton",
  },
  {
    postal_code: "49629",
    full_city_name: "Elk Rapids, MI",
    city_name: "Elk Rapids",
  },
  {
    postal_code: "17872",
    full_city_name: "Shamokin, PA",
    city_name: "Shamokin",
  },
  {
    postal_code: "83537",
    full_city_name: "Kendrick, ID",
    city_name: "Kendrick",
  },
  {
    postal_code: "14560",
    full_city_name: "Springwater, NY",
    city_name: "Springwater",
  },
  {
    postal_code: "72946",
    full_city_name: "Mountainburg, AR",
    city_name: "Mountainburg",
  },
  {
    postal_code: "20684",
    full_city_name: "Saint Inigoes, MD",
    city_name: "Saint Inigoes",
  },
  {
    postal_code: "73848",
    full_city_name: "Laverne, OK",
    city_name: "Laverne",
  },
  {
    postal_code: "35963",
    full_city_name: "Dawson, AL",
    city_name: "Dawson",
  },
  {
    postal_code: "52337",
    full_city_name: "Stanwood, IA",
    city_name: "Stanwood",
  },
  {
    postal_code: "62443",
    full_city_name: "Mason, IL",
    city_name: "Mason",
  },
  {
    postal_code: "53063",
    full_city_name: "Newton, WI",
    city_name: "Newton",
  },
  {
    postal_code: "30757",
    full_city_name: "Wildwood, GA",
    city_name: "Wildwood",
  },
  {
    postal_code: "75845",
    full_city_name: "Groveton, TX",
    city_name: "Groveton",
  },
  {
    postal_code: "61943",
    full_city_name: "Oakland, IL",
    city_name: "Oakland",
  },
  {
    postal_code: "36255",
    full_city_name: "Cragford, AL",
    city_name: "Cragford",
  },
  {
    postal_code: "48884",
    full_city_name: "Sheridan, MI",
    city_name: "Sheridan",
  },
  {
    postal_code: "17252",
    full_city_name: "Saint Thomas, PA",
    city_name: "Saint Thomas",
  },
  {
    postal_code: "35490",
    full_city_name: "Vance, AL",
    city_name: "Vance",
  },
  {
    postal_code: "99919",
    full_city_name: "Thorne Bay, AK",
    city_name: "Thorne Bay",
  },
  {
    postal_code: "52315",
    full_city_name: "Newhall, IA",
    city_name: "Newhall",
  },
  {
    postal_code: "17878",
    full_city_name: "Stillwater, PA",
    city_name: "Stillwater",
  },
  {
    postal_code: "03226",
    full_city_name: "Center Harbor, NH",
    city_name: "Center Harbor",
  },
  {
    postal_code: "54427",
    full_city_name: "Eland, WI",
    city_name: "Eland",
  },
  {
    postal_code: "57064",
    full_city_name: "Tea, SD",
    city_name: "Tea",
  },
  {
    postal_code: "78118",
    full_city_name: "Karnes City, TX",
    city_name: "Karnes City",
  },
  {
    postal_code: "23503",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "47119",
    full_city_name: "Floyds Knobs, IN",
    city_name: "Floyds Knobs",
  },
  {
    postal_code: "24639",
    full_city_name: "Raven, VA",
    city_name: "Raven",
  },
  {
    postal_code: "81063",
    full_city_name: "Ordway, CO",
    city_name: "Ordway",
  },
  {
    postal_code: "99326",
    full_city_name: "Connell, WA",
    city_name: "Connell",
  },
  {
    postal_code: "53058",
    full_city_name: "Nashotah, WI",
    city_name: "Nashotah",
  },
  {
    postal_code: "72938",
    full_city_name: "Hartford, AR",
    city_name: "Hartford",
  },
  {
    postal_code: "73051",
    full_city_name: "Lexington, OK",
    city_name: "Lexington",
  },
  {
    postal_code: "37018",
    full_city_name: "Beechgrove, TN",
    city_name: "Beechgrove",
  },
  {
    postal_code: "66607",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "24630",
    full_city_name: "North Tazewell, VA",
    city_name: "North Tazewell",
  },
  {
    postal_code: "35968",
    full_city_name: "Fort Payne, AL",
    city_name: "Fort Payne",
  },
  {
    postal_code: "96023",
    full_city_name: "Dorris, CA",
    city_name: "Dorris",
  },
  {
    postal_code: "75572",
    full_city_name: "Queen City, TX",
    city_name: "Queen City",
  },
  {
    postal_code: "78833",
    full_city_name: "Camp Wood, TX",
    city_name: "Camp Wood",
  },
  {
    postal_code: "28386",
    full_city_name: "Shannon, NC",
    city_name: "Shannon",
  },
  {
    postal_code: "95960",
    full_city_name: "North San Juan, CA",
    city_name: "North San Juan",
  },
  {
    postal_code: "14510",
    full_city_name: "Mount Morris, NY",
    city_name: "Mount Morris",
  },
  {
    postal_code: "16636",
    full_city_name: "Dysart, PA",
    city_name: "Dysart",
  },
  {
    postal_code: "18631",
    full_city_name: "Mifflinville, PA",
    city_name: "Mifflinville",
  },
  {
    postal_code: "65760",
    full_city_name: "Tecumseh, MO",
    city_name: "Tecumseh",
  },
  {
    postal_code: "18632",
    full_city_name: "Mildred, PA",
    city_name: "Mildred",
  },
  {
    postal_code: "97833",
    full_city_name: "Haines, OR",
    city_name: "Haines",
  },
  {
    postal_code: "87535",
    full_city_name: "Glorieta, NM",
    city_name: "Glorieta",
  },
  {
    postal_code: "47431",
    full_city_name: "Freedom, IN",
    city_name: "Freedom",
  },
  {
    postal_code: "25063",
    full_city_name: "Duck, WV",
    city_name: "Duck",
  },
  {
    postal_code: "87417",
    full_city_name: "Kirtland, NM",
    city_name: "Kirtland",
  },
  {
    postal_code: "04780",
    full_city_name: "Smyrna Mills, ME",
    city_name: "Smyrna Mills",
  },
  {
    postal_code: "42404",
    full_city_name: "Clay, KY",
    city_name: "Clay",
  },
  {
    postal_code: "13788",
    full_city_name: "Hobart, NY",
    city_name: "Hobart",
  },
  {
    postal_code: "10526",
    full_city_name: "Goldens Bridge, NY",
    city_name: "Goldens Bridge",
  },
  {
    postal_code: "76365",
    full_city_name: "Henrietta, TX",
    city_name: "Henrietta",
  },
  {
    postal_code: "33001",
    full_city_name: "Long Key, FL",
    city_name: "Long Key",
  },
  {
    postal_code: "74824",
    full_city_name: "Agra, OK",
    city_name: "Agra",
  },
  {
    postal_code: "49831",
    full_city_name: "Felch, MI",
    city_name: "Felch",
  },
  {
    postal_code: "21874",
    full_city_name: "Willards, MD",
    city_name: "Willards",
  },
  {
    postal_code: "25958",
    full_city_name: "Charmco, WV",
    city_name: "Charmco",
  },
  {
    postal_code: "35019",
    full_city_name: "Baileyton, AL",
    city_name: "Baileyton",
  },
  {
    postal_code: "59748",
    full_city_name: "Ramsay, MT",
    city_name: "Ramsay",
  },
  {
    postal_code: "72768",
    full_city_name: "Sulphur Springs, AR",
    city_name: "Sulphur Springs",
  },
  {
    postal_code: "56570",
    full_city_name: "Osage, MN",
    city_name: "Osage",
  },
  {
    postal_code: "85611",
    full_city_name: "Elgin, AZ",
    city_name: "Elgin",
  },
  {
    postal_code: "75851",
    full_city_name: "Lovelady, TX",
    city_name: "Lovelady",
  },
  {
    postal_code: "73773",
    full_city_name: "Waukomis, OK",
    city_name: "Waukomis",
  },
  {
    postal_code: "99159",
    full_city_name: "Odessa, WA",
    city_name: "Odessa",
  },
  {
    postal_code: "15772",
    full_city_name: "Rossiter, PA",
    city_name: "Rossiter",
  },
  {
    postal_code: "21056",
    full_city_name: "Gibson Island, MD",
    city_name: "Gibson Island",
  },
  {
    postal_code: "13331",
    full_city_name: "Eagle Bay, NY",
    city_name: "Eagle Bay",
  },
  {
    postal_code: "73014",
    full_city_name: "Calumet, OK",
    city_name: "Calumet",
  },
  {
    postal_code: "43347",
    full_city_name: "Rushsylvania, OH",
    city_name: "Rushsylvania",
  },
  {
    postal_code: "99676",
    full_city_name: "Talkeetna, AK",
    city_name: "Talkeetna",
  },
  {
    postal_code: "72039",
    full_city_name: "Damascus, AR",
    city_name: "Damascus",
  },
  {
    postal_code: "63627",
    full_city_name: "Bloomsdale, MO",
    city_name: "Bloomsdale",
  },
  {
    postal_code: "04928",
    full_city_name: "Corinna, ME",
    city_name: "Corinna",
  },
  {
    postal_code: "83540",
    full_city_name: "Lapwai, ID",
    city_name: "Lapwai",
  },
  {
    postal_code: "56343",
    full_city_name: "Kensington, MN",
    city_name: "Kensington",
  },
  {
    postal_code: "21071",
    full_city_name: "Glyndon, MD",
    city_name: "Glyndon",
  },
  {
    postal_code: "63829",
    full_city_name: "Cardwell, MO",
    city_name: "Cardwell",
  },
  {
    postal_code: "46926",
    full_city_name: "Denver, IN",
    city_name: "Denver",
  },
  {
    postal_code: "39451",
    full_city_name: "Leakesville, MS",
    city_name: "Leakesville",
  },
  {
    postal_code: "05489",
    full_city_name: "Underhill, VT",
    city_name: "Underhill",
  },
  {
    postal_code: "15946",
    full_city_name: "Portage, PA",
    city_name: "Portage",
  },
  {
    postal_code: "74332",
    full_city_name: "Big Cabin, OK",
    city_name: "Big Cabin",
  },
  {
    postal_code: "71770",
    full_city_name: "Waldo, AR",
    city_name: "Waldo",
  },
  {
    postal_code: "28464",
    full_city_name: "Teachey, NC",
    city_name: "Teachey",
  },
  {
    postal_code: "42450",
    full_city_name: "Providence, KY",
    city_name: "Providence",
  },
  {
    postal_code: "73052",
    full_city_name: "Lindsay, OK",
    city_name: "Lindsay",
  },
  {
    postal_code: "29856",
    full_city_name: "Windsor, SC",
    city_name: "Windsor",
  },
  {
    postal_code: "53937",
    full_city_name: "Hillpoint, WI",
    city_name: "Hillpoint",
  },
  {
    postal_code: "05680",
    full_city_name: "Wolcott, VT",
    city_name: "Wolcott",
  },
  {
    postal_code: "52552",
    full_city_name: "Drakesville, IA",
    city_name: "Drakesville",
  },
  {
    postal_code: "94038",
    full_city_name: "Moss Beach, CA",
    city_name: "Moss Beach",
  },
  {
    postal_code: "36862",
    full_city_name: "Lafayette, AL",
    city_name: "Lafayette",
  },
  {
    postal_code: "14030",
    full_city_name: "Chaffee, NY",
    city_name: "Chaffee",
  },
  {
    postal_code: "65638",
    full_city_name: "Drury, MO",
    city_name: "Drury",
  },
  {
    postal_code: "67067",
    full_city_name: "Kechi, KS",
    city_name: "Kechi",
  },
  {
    postal_code: "80451",
    full_city_name: "Hot Sulphur Springs, CO",
    city_name: "Hot Sulphur Springs",
  },
  {
    postal_code: "67457",
    full_city_name: "Little River, KS",
    city_name: "Little River",
  },
  {
    postal_code: "31815",
    full_city_name: "Lumpkin, GA",
    city_name: "Lumpkin",
  },
  {
    postal_code: "72544",
    full_city_name: "Henderson, AR",
    city_name: "Henderson",
  },
  {
    postal_code: "36850",
    full_city_name: "Camp Hill, AL",
    city_name: "Camp Hill",
  },
  {
    postal_code: "54865",
    full_city_name: "Port Wing, WI",
    city_name: "Port Wing",
  },
  {
    postal_code: "62931",
    full_city_name: "Elizabethtown, IL",
    city_name: "Elizabethtown",
  },
  {
    postal_code: "76463",
    full_city_name: "Mingus, TX",
    city_name: "Mingus",
  },
  {
    postal_code: "68731",
    full_city_name: "Dakota City, NE",
    city_name: "Dakota City",
  },
  {
    postal_code: "62560",
    full_city_name: "Raymond, IL",
    city_name: "Raymond",
  },
  {
    postal_code: "35776",
    full_city_name: "Woodville, AL",
    city_name: "Woodville",
  },
  {
    postal_code: "04951",
    full_city_name: "Monroe, ME",
    city_name: "Monroe",
  },
  {
    postal_code: "46777",
    full_city_name: "Ossian, IN",
    city_name: "Ossian",
  },
  {
    postal_code: "36785",
    full_city_name: "Tyler, AL",
    city_name: "Tyler",
  },
  {
    postal_code: "03774",
    full_city_name: "North Haverhill, NH",
    city_name: "North Haverhill",
  },
  {
    postal_code: "14820",
    full_city_name: "Cameron Mills, NY",
    city_name: "Cameron Mills",
  },
  {
    postal_code: "14847",
    full_city_name: "Interlaken, NY",
    city_name: "Interlaken",
  },
  {
    postal_code: "62882",
    full_city_name: "Sandoval, IL",
    city_name: "Sandoval",
  },
  {
    postal_code: "42207",
    full_city_name: "Bee Spring, KY",
    city_name: "Bee Spring",
  },
  {
    postal_code: "54463",
    full_city_name: "Pelican Lake, WI",
    city_name: "Pelican Lake",
  },
  {
    postal_code: "63877",
    full_city_name: "Steele, MO",
    city_name: "Steele",
  },
  {
    postal_code: "30668",
    full_city_name: "Tignall, GA",
    city_name: "Tignall",
  },
  {
    postal_code: "64620",
    full_city_name: "Altamont, MO",
    city_name: "Altamont",
  },
  {
    postal_code: "73461",
    full_city_name: "Wapanucka, OK",
    city_name: "Wapanucka",
  },
  {
    postal_code: "46794",
    full_city_name: "Wawaka, IN",
    city_name: "Wawaka",
  },
  {
    postal_code: "81610",
    full_city_name: "Dinosaur, CO",
    city_name: "Dinosaur",
  },
  {
    postal_code: "10993",
    full_city_name: "West Haverstraw, NY",
    city_name: "West Haverstraw",
  },
  {
    postal_code: "61929",
    full_city_name: "Hammond, IL",
    city_name: "Hammond",
  },
  {
    postal_code: "28668",
    full_city_name: "Roaring Gap, NC",
    city_name: "Roaring Gap",
  },
  {
    postal_code: "47352",
    full_city_name: "Lewisville, IN",
    city_name: "Lewisville",
  },
  {
    postal_code: "54423",
    full_city_name: "Custer, WI",
    city_name: "Custer",
  },
  {
    postal_code: "43319",
    full_city_name: "East Liberty, OH",
    city_name: "East Liberty",
  },
  {
    postal_code: "81654",
    full_city_name: "Snowmass, CO",
    city_name: "Snowmass",
  },
  {
    postal_code: "60962",
    full_city_name: "Roberts, IL",
    city_name: "Roberts",
  },
  {
    postal_code: "35648",
    full_city_name: "Lexington, AL",
    city_name: "Lexington",
  },
  {
    postal_code: "46555",
    full_city_name: "North Webster, IN",
    city_name: "North Webster",
  },
  {
    postal_code: "12486",
    full_city_name: "Tillson, NY",
    city_name: "Tillson",
  },
  {
    postal_code: "49026",
    full_city_name: "Bloomingdale, MI",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "12434",
    full_city_name: "Grand Gorge, NY",
    city_name: "Grand Gorge",
  },
  {
    postal_code: "18327",
    full_city_name: "Delaware Water Gap, PA",
    city_name: "Delaware Water Gap",
  },
  {
    postal_code: "40935",
    full_city_name: "Flat Lick, KY",
    city_name: "Flat Lick",
  },
  {
    postal_code: "79241",
    full_city_name: "Lockney, TX",
    city_name: "Lockney",
  },
  {
    postal_code: "28393",
    full_city_name: "Turkey, NC",
    city_name: "Turkey",
  },
  {
    postal_code: "04231",
    full_city_name: "Stoneham, ME",
    city_name: "Stoneham",
  },
  {
    postal_code: "50643",
    full_city_name: "Hudson, IA",
    city_name: "Hudson",
  },
  {
    postal_code: "21840",
    full_city_name: "Nanticoke, MD",
    city_name: "Nanticoke",
  },
  {
    postal_code: "73053",
    full_city_name: "Lookeba, OK",
    city_name: "Lookeba",
  },
  {
    postal_code: "17309",
    full_city_name: "Brogue, PA",
    city_name: "Brogue",
  },
  {
    postal_code: "97390",
    full_city_name: "Tidewater, OR",
    city_name: "Tidewater",
  },
  {
    postal_code: "31568",
    full_city_name: "White Oak, GA",
    city_name: "White Oak",
  },
  {
    postal_code: "24569",
    full_city_name: "Long Island, VA",
    city_name: "Long Island",
  },
  {
    postal_code: "98628",
    full_city_name: "Klickitat, WA",
    city_name: "Klickitat",
  },
  {
    postal_code: "84311",
    full_city_name: "Fielding, UT",
    city_name: "Fielding",
  },
  {
    postal_code: "17023",
    full_city_name: "Elizabethville, PA",
    city_name: "Elizabethville",
  },
  {
    postal_code: "49403",
    full_city_name: "Conklin, MI",
    city_name: "Conklin",
  },
  {
    postal_code: "36032",
    full_city_name: "Fort Deposit, AL",
    city_name: "Fort Deposit",
  },
  {
    postal_code: "43913",
    full_city_name: "Brilliant, OH",
    city_name: "Brilliant",
  },
  {
    postal_code: "46779",
    full_city_name: "Pleasant Lake, IN",
    city_name: "Pleasant Lake",
  },
  {
    postal_code: "56253",
    full_city_name: "Lake Lillian, MN",
    city_name: "Lake Lillian",
  },
  {
    postal_code: "73729",
    full_city_name: "Cleo Springs, OK",
    city_name: "Cleo Springs",
  },
  {
    postal_code: "53212",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "12577",
    full_city_name: "Salisbury Mills, NY",
    city_name: "Salisbury Mills",
  },
  {
    postal_code: "69123",
    full_city_name: "Brady, NE",
    city_name: "Brady",
  },
  {
    postal_code: "54119",
    full_city_name: "Dunbar, WI",
    city_name: "Dunbar",
  },
  {
    postal_code: "52619",
    full_city_name: "Argyle, IA",
    city_name: "Argyle",
  },
  {
    postal_code: "38370",
    full_city_name: "Saltillo, TN",
    city_name: "Saltillo",
  },
  {
    postal_code: "52653",
    full_city_name: "Wapello, IA",
    city_name: "Wapello",
  },
  {
    postal_code: "61430",
    full_city_name: "East Galesburg, IL",
    city_name: "East Galesburg",
  },
  {
    postal_code: "72060",
    full_city_name: "Griffithville, AR",
    city_name: "Griffithville",
  },
  {
    postal_code: "43464",
    full_city_name: "Vickery, OH",
    city_name: "Vickery",
  },
  {
    postal_code: "81057",
    full_city_name: "Mc Clave, CO",
    city_name: "Mc Clave",
  },
  {
    postal_code: "39145",
    full_city_name: "Pelahatchie, MS",
    city_name: "Pelahatchie",
  },
  {
    postal_code: "63781",
    full_city_name: "Sedgewickville, MO",
    city_name: "Sedgewickville",
  },
  {
    postal_code: "75569",
    full_city_name: "Nash, TX",
    city_name: "Nash",
  },
  {
    postal_code: "56282",
    full_city_name: "Raymond, MN",
    city_name: "Raymond",
  },
  {
    postal_code: "39041",
    full_city_name: "Bolton, MS",
    city_name: "Bolton",
  },
  {
    postal_code: "52590",
    full_city_name: "Seymour, IA",
    city_name: "Seymour",
  },
  {
    postal_code: "51239",
    full_city_name: "Hull, IA",
    city_name: "Hull",
  },
  {
    postal_code: "84325",
    full_city_name: "Mendon, UT",
    city_name: "Mendon",
  },
  {
    postal_code: "65456",
    full_city_name: "Davisville, MO",
    city_name: "Davisville",
  },
  {
    postal_code: "62550",
    full_city_name: "Moweaqua, IL",
    city_name: "Moweaqua",
  },
  {
    postal_code: "39631",
    full_city_name: "Centreville, MS",
    city_name: "Centreville",
  },
  {
    postal_code: "79248",
    full_city_name: "Paducah, TX",
    city_name: "Paducah",
  },
  {
    postal_code: "41649",
    full_city_name: "Martin, KY",
    city_name: "Martin",
  },
  {
    postal_code: "66070",
    full_city_name: "Ozawkie, KS",
    city_name: "Ozawkie",
  },
  {
    postal_code: "73456",
    full_city_name: "Ringling, OK",
    city_name: "Ringling",
  },
  {
    postal_code: "62682",
    full_city_name: "San Jose, IL",
    city_name: "San Jose",
  },
  {
    postal_code: "27824",
    full_city_name: "Engelhard, NC",
    city_name: "Engelhard",
  },
  {
    postal_code: "07074",
    full_city_name: "Moonachie, NJ",
    city_name: "Moonachie",
  },
  {
    postal_code: "54648",
    full_city_name: "Norwalk, WI",
    city_name: "Norwalk",
  },
  {
    postal_code: "83552",
    full_city_name: "Stites, ID",
    city_name: "Stites",
  },
  {
    postal_code: "80732",
    full_city_name: "Hereford, CO",
    city_name: "Hereford",
  },
  {
    postal_code: "50641",
    full_city_name: "Hazleton, IA",
    city_name: "Hazleton",
  },
  {
    postal_code: "38327",
    full_city_name: "Crump, TN",
    city_name: "Crump",
  },
  {
    postal_code: "55785",
    full_city_name: "Swatara, MN",
    city_name: "Swatara",
  },
  {
    postal_code: "30710",
    full_city_name: "Cohutta, GA",
    city_name: "Cohutta",
  },
  {
    postal_code: "29918",
    full_city_name: "Estill, SC",
    city_name: "Estill",
  },
  {
    postal_code: "23176",
    full_city_name: "Wake, VA",
    city_name: "Wake",
  },
  {
    postal_code: "28432",
    full_city_name: "Clarendon, NC",
    city_name: "Clarendon",
  },
  {
    postal_code: "18461",
    full_city_name: "Starlight, PA",
    city_name: "Starlight",
  },
  {
    postal_code: "54731",
    full_city_name: "Conrath, WI",
    city_name: "Conrath",
  },
  {
    postal_code: "27356",
    full_city_name: "Star, NC",
    city_name: "Star",
  },
  {
    postal_code: "97489",
    full_city_name: "Walterville, OR",
    city_name: "Walterville",
  },
  {
    postal_code: "15083",
    full_city_name: "Sutersville, PA",
    city_name: "Sutersville",
  },
  {
    postal_code: "76530",
    full_city_name: "Granger, TX",
    city_name: "Granger",
  },
  {
    postal_code: "17886",
    full_city_name: "West Milton, PA",
    city_name: "West Milton",
  },
  {
    postal_code: "98943",
    full_city_name: "South Cle Elum, WA",
    city_name: "South Cle Elum",
  },
  {
    postal_code: "12457",
    full_city_name: "Mount Tremper, NY",
    city_name: "Mount Tremper",
  },
  {
    postal_code: "17221",
    full_city_name: "Fannettsburg, PA",
    city_name: "Fannettsburg",
  },
  {
    postal_code: "45804",
    full_city_name: "Lima, OH",
    city_name: "Lima",
  },
  {
    postal_code: "05874",
    full_city_name: "Westfield, VT",
    city_name: "Westfield",
  },
  {
    postal_code: "51053",
    full_city_name: "Schaller, IA",
    city_name: "Schaller",
  },
  {
    postal_code: "71024",
    full_city_name: "Dubberly, LA",
    city_name: "Dubberly",
  },
  {
    postal_code: "52341",
    full_city_name: "Toddville, IA",
    city_name: "Toddville",
  },
  {
    postal_code: "42649",
    full_city_name: "Strunk, KY",
    city_name: "Strunk",
  },
  {
    postal_code: "97438",
    full_city_name: "Fall Creek, OR",
    city_name: "Fall Creek",
  },
  {
    postal_code: "29827",
    full_city_name: "Fairfax, SC",
    city_name: "Fairfax",
  },
  {
    postal_code: "66402",
    full_city_name: "Auburn, KS",
    city_name: "Auburn",
  },
  {
    postal_code: "28421",
    full_city_name: "Atkinson, NC",
    city_name: "Atkinson",
  },
  {
    postal_code: "47610",
    full_city_name: "Chandler, IN",
    city_name: "Chandler",
  },
  {
    postal_code: "04292",
    full_city_name: "Sumner, ME",
    city_name: "Sumner",
  },
  {
    postal_code: "59452",
    full_city_name: "Hobson, MT",
    city_name: "Hobson",
  },
  {
    postal_code: "44510",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "15427",
    full_city_name: "Daisytown, PA",
    city_name: "Daisytown",
  },
  {
    postal_code: "85193",
    full_city_name: "Casa Grande, AZ",
    city_name: "Casa Grande",
  },
  {
    postal_code: "15954",
    full_city_name: "Seward, PA",
    city_name: "Seward",
  },
  {
    postal_code: "61060",
    full_city_name: "Orangeville, IL",
    city_name: "Orangeville",
  },
  {
    postal_code: "04069",
    full_city_name: "Pownal, ME",
    city_name: "Pownal",
  },
  {
    postal_code: "35618",
    full_city_name: "Courtland, AL",
    city_name: "Courtland",
  },
  {
    postal_code: "97536",
    full_city_name: "Prospect, OR",
    city_name: "Prospect",
  },
  {
    postal_code: "23180",
    full_city_name: "Water View, VA",
    city_name: "Water View",
  },
  {
    postal_code: "71858",
    full_city_name: "Rosston, AR",
    city_name: "Rosston",
  },
  {
    postal_code: "83239",
    full_city_name: "Georgetown, ID",
    city_name: "Georgetown",
  },
  {
    postal_code: "77853",
    full_city_name: "Dime Box, TX",
    city_name: "Dime Box",
  },
  {
    postal_code: "66440",
    full_city_name: "Hoyt, KS",
    city_name: "Hoyt",
  },
  {
    postal_code: "68715",
    full_city_name: "Battle Creek, NE",
    city_name: "Battle Creek",
  },
  {
    postal_code: "27805",
    full_city_name: "Aulander, NC",
    city_name: "Aulander",
  },
  {
    postal_code: "24473",
    full_city_name: "Rockbridge Baths, VA",
    city_name: "Rockbridge Baths",
  },
  {
    postal_code: "04438",
    full_city_name: "Frankfort, ME",
    city_name: "Frankfort",
  },
  {
    postal_code: "35803",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "58463",
    full_city_name: "Mcclusky, ND",
    city_name: "Mcclusky",
  },
  {
    postal_code: "98581",
    full_city_name: "Ryderwood, WA",
    city_name: "Ryderwood",
  },
  {
    postal_code: "79532",
    full_city_name: "Loraine, TX",
    city_name: "Loraine",
  },
  {
    postal_code: "19954",
    full_city_name: "Houston, DE",
    city_name: "Houston",
  },
  {
    postal_code: "04464",
    full_city_name: "Monson, ME",
    city_name: "Monson",
  },
  {
    postal_code: "18321",
    full_city_name: "Bartonsville, PA",
    city_name: "Bartonsville",
  },
  {
    postal_code: "87935",
    full_city_name: "Elephant Butte, NM",
    city_name: "Elephant Butte",
  },
  {
    postal_code: "68347",
    full_city_name: "Eagle, NE",
    city_name: "Eagle",
  },
  {
    postal_code: "50115",
    full_city_name: "Guthrie Center, IA",
    city_name: "Guthrie Center",
  },
  {
    postal_code: "28338",
    full_city_name: "Ellerbe, NC",
    city_name: "Ellerbe",
  },
  {
    postal_code: "73950",
    full_city_name: "Turpin, OK",
    city_name: "Turpin",
  },
  {
    postal_code: "21647",
    full_city_name: "Mcdaniel, MD",
    city_name: "Mcdaniel",
  },
  {
    postal_code: "54007",
    full_city_name: "Deer Park, WI",
    city_name: "Deer Park",
  },
  {
    postal_code: "71049",
    full_city_name: "Logansport, LA",
    city_name: "Logansport",
  },
  {
    postal_code: "14784",
    full_city_name: "Stockton, NY",
    city_name: "Stockton",
  },
  {
    postal_code: "63071",
    full_city_name: "Richwoods, MO",
    city_name: "Richwoods",
  },
  {
    postal_code: "64150",
    full_city_name: "Riverside, MO",
    city_name: "Riverside",
  },
  {
    postal_code: "81242",
    full_city_name: "Poncha Springs, CO",
    city_name: "Poncha Springs",
  },
  {
    postal_code: "14775",
    full_city_name: "Ripley, NY",
    city_name: "Ripley",
  },
  {
    postal_code: "51103",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "38039",
    full_city_name: "Grand Junction, TN",
    city_name: "Grand Junction",
  },
  {
    postal_code: "14167",
    full_city_name: "Varysburg, NY",
    city_name: "Varysburg",
  },
  {
    postal_code: "83272",
    full_city_name: "Saint Charles, ID",
    city_name: "Saint Charles",
  },
  {
    postal_code: "04097",
    full_city_name: "North Yarmouth, ME",
    city_name: "North Yarmouth",
  },
  {
    postal_code: "93616",
    full_city_name: "Del Rey, CA",
    city_name: "Del Rey",
  },
  {
    postal_code: "66833",
    full_city_name: "Allen, KS",
    city_name: "Allen",
  },
  {
    postal_code: "74471",
    full_city_name: "Welling, OK",
    city_name: "Welling",
  },
  {
    postal_code: "95227",
    full_city_name: "Clements, CA",
    city_name: "Clements",
  },
  {
    postal_code: "12470",
    full_city_name: "Purling, NY",
    city_name: "Purling",
  },
  {
    postal_code: "65250",
    full_city_name: "Franklin, MO",
    city_name: "Franklin",
  },
  {
    postal_code: "96730",
    full_city_name: "Kaaawa, HI",
    city_name: "Kaaawa",
  },
  {
    postal_code: "66754",
    full_city_name: "Mapleton, KS",
    city_name: "Mapleton",
  },
  {
    postal_code: "30411",
    full_city_name: "Alamo, GA",
    city_name: "Alamo",
  },
  {
    postal_code: "48043",
    full_city_name: "Mount Clemens, MI",
    city_name: "Mount Clemens",
  },
  {
    postal_code: "52569",
    full_city_name: "Melrose, IA",
    city_name: "Melrose",
  },
  {
    postal_code: "87942",
    full_city_name: "Williamsburg, NM",
    city_name: "Williamsburg",
  },
  {
    postal_code: "79789",
    full_city_name: "Wink, TX",
    city_name: "Wink",
  },
  {
    postal_code: "72832",
    full_city_name: "Coal Hill, AR",
    city_name: "Coal Hill",
  },
  {
    postal_code: "62570",
    full_city_name: "Tovey, IL",
    city_name: "Tovey",
  },
  {
    postal_code: "94940",
    full_city_name: "Marshall, CA",
    city_name: "Marshall",
  },
  {
    postal_code: "24260",
    full_city_name: "Honaker, VA",
    city_name: "Honaker",
  },
  {
    postal_code: "52326",
    full_city_name: "Quasqueton, IA",
    city_name: "Quasqueton",
  },
  {
    postal_code: "79855",
    full_city_name: "Van Horn, TX",
    city_name: "Van Horn",
  },
  {
    postal_code: "13464",
    full_city_name: "Smyrna, NY",
    city_name: "Smyrna",
  },
  {
    postal_code: "46982",
    full_city_name: "Silver Lake, IN",
    city_name: "Silver Lake",
  },
  {
    postal_code: "13734",
    full_city_name: "Barton, NY",
    city_name: "Barton",
  },
  {
    postal_code: "48877",
    full_city_name: "Riverdale, MI",
    city_name: "Riverdale",
  },
  {
    postal_code: "46601",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "95225",
    full_city_name: "Burson, CA",
    city_name: "Burson",
  },
  {
    postal_code: "66748",
    full_city_name: "Humboldt, KS",
    city_name: "Humboldt",
  },
  {
    postal_code: "20623",
    full_city_name: "Cheltenham, MD",
    city_name: "Cheltenham",
  },
  {
    postal_code: "14767",
    full_city_name: "Panama, NY",
    city_name: "Panama",
  },
  {
    postal_code: "91963",
    full_city_name: "Potrero, CA",
    city_name: "Potrero",
  },
  {
    postal_code: "47140",
    full_city_name: "Marengo, IN",
    city_name: "Marengo",
  },
  {
    postal_code: "63940",
    full_city_name: "Fisk, MO",
    city_name: "Fisk",
  },
  {
    postal_code: "85736",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "04986",
    full_city_name: "Thorndike, ME",
    city_name: "Thorndike",
  },
  {
    postal_code: "52755",
    full_city_name: "Lone Tree, IA",
    city_name: "Lone Tree",
  },
  {
    postal_code: "64444",
    full_city_name: "Edgerton, MO",
    city_name: "Edgerton",
  },
  {
    postal_code: "88341",
    full_city_name: "Nogal, NM",
    city_name: "Nogal",
  },
  {
    postal_code: "21849",
    full_city_name: "Parsonsburg, MD",
    city_name: "Parsonsburg",
  },
  {
    postal_code: "62440",
    full_city_name: "Lerna, IL",
    city_name: "Lerna",
  },
  {
    postal_code: "93517",
    full_city_name: "Bridgeport, CA",
    city_name: "Bridgeport",
  },
  {
    postal_code: "73045",
    full_city_name: "Harrah, OK",
    city_name: "Harrah",
  },
  {
    postal_code: "68038",
    full_city_name: "Lyons, NE",
    city_name: "Lyons",
  },
  {
    postal_code: "57077",
    full_city_name: "Worthing, SD",
    city_name: "Worthing",
  },
  {
    postal_code: "32350",
    full_city_name: "Pinetta, FL",
    city_name: "Pinetta",
  },
  {
    postal_code: "47920",
    full_city_name: "Battle Ground, IN",
    city_name: "Battle Ground",
  },
  {
    postal_code: "67748",
    full_city_name: "Oakley, KS",
    city_name: "Oakley",
  },
  {
    postal_code: "18821",
    full_city_name: "Great Bend, PA",
    city_name: "Great Bend",
  },
  {
    postal_code: "97014",
    full_city_name: "Cascade Locks, OR",
    city_name: "Cascade Locks",
  },
  {
    postal_code: "83323",
    full_city_name: "Declo, ID",
    city_name: "Declo",
  },
  {
    postal_code: "27553",
    full_city_name: "Manson, NC",
    city_name: "Manson",
  },
  {
    postal_code: "54661",
    full_city_name: "Trempealeau, WI",
    city_name: "Trempealeau",
  },
  {
    postal_code: "42653",
    full_city_name: "Whitley City, KY",
    city_name: "Whitley City",
  },
  {
    postal_code: "24421",
    full_city_name: "Churchville, VA",
    city_name: "Churchville",
  },
  {
    postal_code: "19333",
    full_city_name: "Devon, PA",
    city_name: "Devon",
  },
  {
    postal_code: "61756",
    full_city_name: "Maroa, IL",
    city_name: "Maroa",
  },
  {
    postal_code: "17005",
    full_city_name: "Berrysburg, PA",
    city_name: "Berrysburg",
  },
  {
    postal_code: "26294",
    full_city_name: "Valley Head, WV",
    city_name: "Valley Head",
  },
  {
    postal_code: "89426",
    full_city_name: "Paradise Valley, NV",
    city_name: "Paradise Valley",
  },
  {
    postal_code: "29911",
    full_city_name: "Brunson, SC",
    city_name: "Brunson",
  },
  {
    postal_code: "64424",
    full_city_name: "Bethany, MO",
    city_name: "Bethany",
  },
  {
    postal_code: "98050",
    full_city_name: "Preston, WA",
    city_name: "Preston",
  },
  {
    postal_code: "78662",
    full_city_name: "Red Rock, TX",
    city_name: "Red Rock",
  },
  {
    postal_code: "51454",
    full_city_name: "Manilla, IA",
    city_name: "Manilla",
  },
  {
    postal_code: "93434",
    full_city_name: "Guadalupe, CA",
    city_name: "Guadalupe",
  },
  {
    postal_code: "50141",
    full_city_name: "Laurel, IA",
    city_name: "Laurel",
  },
  {
    postal_code: "58561",
    full_city_name: "Napoleon, ND",
    city_name: "Napoleon",
  },
  {
    postal_code: "29727",
    full_city_name: "Mount Croghan, SC",
    city_name: "Mount Croghan",
  },
  {
    postal_code: "50440",
    full_city_name: "Grafton, IA",
    city_name: "Grafton",
  },
  {
    postal_code: "62967",
    full_city_name: "New Burnside, IL",
    city_name: "New Burnside",
  },
  {
    postal_code: "64630",
    full_city_name: "Browning, MO",
    city_name: "Browning",
  },
  {
    postal_code: "85333",
    full_city_name: "Dateland, AZ",
    city_name: "Dateland",
  },
  {
    postal_code: "39643",
    full_city_name: "Kokomo, MS",
    city_name: "Kokomo",
  },
  {
    postal_code: "61917",
    full_city_name: "Brocton, IL",
    city_name: "Brocton",
  },
  {
    postal_code: "05254",
    full_city_name: "Manchester, VT",
    city_name: "Manchester",
  },
  {
    postal_code: "51354",
    full_city_name: "Ocheyedan, IA",
    city_name: "Ocheyedan",
  },
  {
    postal_code: "52565",
    full_city_name: "Keosauqua, IA",
    city_name: "Keosauqua",
  },
  {
    postal_code: "29178",
    full_city_name: "Whitmire, SC",
    city_name: "Whitmire",
  },
  {
    postal_code: "67016",
    full_city_name: "Bentley, KS",
    city_name: "Bentley",
  },
  {
    postal_code: "77456",
    full_city_name: "Markham, TX",
    city_name: "Markham",
  },
  {
    postal_code: "43504",
    full_city_name: "Berkey, OH",
    city_name: "Berkey",
  },
  {
    postal_code: "53924",
    full_city_name: "Cazenovia, WI",
    city_name: "Cazenovia",
  },
  {
    postal_code: "27816",
    full_city_name: "Castalia, NC",
    city_name: "Castalia",
  },
  {
    postal_code: "98836",
    full_city_name: "Monitor, WA",
    city_name: "Monitor",
  },
  {
    postal_code: "56556",
    full_city_name: "Mcintosh, MN",
    city_name: "Mcintosh",
  },
  {
    postal_code: "71368",
    full_city_name: "Sicily Island, LA",
    city_name: "Sicily Island",
  },
  {
    postal_code: "63091",
    full_city_name: "Rosebud, MO",
    city_name: "Rosebud",
  },
  {
    postal_code: "64484",
    full_city_name: "Rushville, MO",
    city_name: "Rushville",
  },
  {
    postal_code: "32059",
    full_city_name: "Lee, FL",
    city_name: "Lee",
  },
  {
    postal_code: "53078",
    full_city_name: "Rubicon, WI",
    city_name: "Rubicon",
  },
  {
    postal_code: "84002",
    full_city_name: "Altonah, UT",
    city_name: "Altonah",
  },
  {
    postal_code: "71970",
    full_city_name: "Story, AR",
    city_name: "Story",
  },
  {
    postal_code: "78719",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "62240",
    full_city_name: "East Carondelet, IL",
    city_name: "East Carondelet",
  },
  {
    postal_code: "58626",
    full_city_name: "Dunn Center, ND",
    city_name: "Dunn Center",
  },
  {
    postal_code: "89010",
    full_city_name: "Dyer, NV",
    city_name: "Dyer",
  },
  {
    postal_code: "36524",
    full_city_name: "Coffeeville, AL",
    city_name: "Coffeeville",
  },
  {
    postal_code: "49812",
    full_city_name: "Carney, MI",
    city_name: "Carney",
  },
  {
    postal_code: "45352",
    full_city_name: "Palestine, OH",
    city_name: "Palestine",
  },
  {
    postal_code: "14804",
    full_city_name: "Almond, NY",
    city_name: "Almond",
  },
  {
    postal_code: "47102",
    full_city_name: "Austin, IN",
    city_name: "Austin",
  },
  {
    postal_code: "67455",
    full_city_name: "Lincoln, KS",
    city_name: "Lincoln",
  },
  {
    postal_code: "51014",
    full_city_name: "Cleghorn, IA",
    city_name: "Cleghorn",
  },
  {
    postal_code: "98603",
    full_city_name: "Ariel, WA",
    city_name: "Ariel",
  },
  {
    postal_code: "17237",
    full_city_name: "Mont Alto, PA",
    city_name: "Mont Alto",
  },
  {
    postal_code: "04008",
    full_city_name: "Bowdoinham, ME",
    city_name: "Bowdoinham",
  },
  {
    postal_code: "98533",
    full_city_name: "Cinebar, WA",
    city_name: "Cinebar",
  },
  {
    postal_code: "01079",
    full_city_name: "Thorndike, MA",
    city_name: "Thorndike",
  },
  {
    postal_code: "28669",
    full_city_name: "Roaring River, NC",
    city_name: "Roaring River",
  },
  {
    postal_code: "40328",
    full_city_name: "Gravel Switch, KY",
    city_name: "Gravel Switch",
  },
  {
    postal_code: "49632",
    full_city_name: "Falmouth, MI",
    city_name: "Falmouth",
  },
  {
    postal_code: "53522",
    full_city_name: "Browntown, WI",
    city_name: "Browntown",
  },
  {
    postal_code: "49255",
    full_city_name: "Montgomery, MI",
    city_name: "Montgomery",
  },
  {
    postal_code: "21522",
    full_city_name: "Bittinger, MD",
    city_name: "Bittinger",
  },
  {
    postal_code: "75936",
    full_city_name: "Chester, TX",
    city_name: "Chester",
  },
  {
    postal_code: "13690",
    full_city_name: "Star Lake, NY",
    city_name: "Star Lake",
  },
  {
    postal_code: "45867",
    full_city_name: "Mount Blanchard, OH",
    city_name: "Mount Blanchard",
  },
  {
    postal_code: "46960",
    full_city_name: "Monterey, IN",
    city_name: "Monterey",
  },
  {
    postal_code: "74470",
    full_city_name: "Webbers Falls, OK",
    city_name: "Webbers Falls",
  },
  {
    postal_code: "62868",
    full_city_name: "Noble, IL",
    city_name: "Noble",
  },
  {
    postal_code: "47143",
    full_city_name: "Memphis, IN",
    city_name: "Memphis",
  },
  {
    postal_code: "16838",
    full_city_name: "Grampian, PA",
    city_name: "Grampian",
  },
  {
    postal_code: "38543",
    full_city_name: "Alpine, TN",
    city_name: "Alpine",
  },
  {
    postal_code: "64841",
    full_city_name: "Duenweg, MO",
    city_name: "Duenweg",
  },
  {
    postal_code: "61721",
    full_city_name: "Armington, IL",
    city_name: "Armington",
  },
  {
    postal_code: "13842",
    full_city_name: "South Kortright, NY",
    city_name: "South Kortright",
  },
  {
    postal_code: "32190",
    full_city_name: "Seville, FL",
    city_name: "Seville",
  },
  {
    postal_code: "95552",
    full_city_name: "Mad River, CA",
    city_name: "Mad River",
  },
  {
    postal_code: "46910",
    full_city_name: "Akron, IN",
    city_name: "Akron",
  },
  {
    postal_code: "43356",
    full_city_name: "Waldo, OH",
    city_name: "Waldo",
  },
  {
    postal_code: "78615",
    full_city_name: "Coupland, TX",
    city_name: "Coupland",
  },
  {
    postal_code: "44651",
    full_city_name: "Mechanicstown, OH",
    city_name: "Mechanicstown",
  },
  {
    postal_code: "55310",
    full_city_name: "Bird Island, MN",
    city_name: "Bird Island",
  },
  {
    postal_code: "95587",
    full_city_name: "Piercy, CA",
    city_name: "Piercy",
  },
  {
    postal_code: "28434",
    full_city_name: "Council, NC",
    city_name: "Council",
  },
  {
    postal_code: "46987",
    full_city_name: "Sweetser, IN",
    city_name: "Sweetser",
  },
  {
    postal_code: "56132",
    full_city_name: "Garvin, MN",
    city_name: "Garvin",
  },
  {
    postal_code: "14469",
    full_city_name: "Bloomfield, NY",
    city_name: "Bloomfield",
  },
  {
    postal_code: "30477",
    full_city_name: "Wadley, GA",
    city_name: "Wadley",
  },
  {
    postal_code: "88435",
    full_city_name: "Santa Rosa, NM",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "42456",
    full_city_name: "Slaughters, KY",
    city_name: "Slaughters",
  },
  {
    postal_code: "77950",
    full_city_name: "Austwell, TX",
    city_name: "Austwell",
  },
  {
    postal_code: "95313",
    full_city_name: "Crows Landing, CA",
    city_name: "Crows Landing",
  },
  {
    postal_code: "98535",
    full_city_name: "Copalis Beach, WA",
    city_name: "Copalis Beach",
  },
  {
    postal_code: "01080",
    full_city_name: "Three Rivers, MA",
    city_name: "Three Rivers",
  },
  {
    postal_code: "58750",
    full_city_name: "Lansford, ND",
    city_name: "Lansford",
  },
  {
    postal_code: "05036",
    full_city_name: "Brookfield, VT",
    city_name: "Brookfield",
  },
  {
    postal_code: "07847",
    full_city_name: "Kenvil, NJ",
    city_name: "Kenvil",
  },
  {
    postal_code: "52346",
    full_city_name: "Van Horne, IA",
    city_name: "Van Horne",
  },
  {
    postal_code: "89318",
    full_city_name: "Mc Gill, NV",
    city_name: "Mc Gill",
  },
  {
    postal_code: "48652",
    full_city_name: "Rhodes, MI",
    city_name: "Rhodes",
  },
  {
    postal_code: "49612",
    full_city_name: "Alden, MI",
    city_name: "Alden",
  },
  {
    postal_code: "66088",
    full_city_name: "Valley Falls, KS",
    city_name: "Valley Falls",
  },
  {
    postal_code: "01434",
    full_city_name: "Devens, MA",
    city_name: "Devens",
  },
  {
    postal_code: "06444",
    full_city_name: "Marion, CT",
    city_name: "Marion",
  },
  {
    postal_code: "89086",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "13146",
    full_city_name: "Savannah, NY",
    city_name: "Savannah",
  },
  {
    postal_code: "18425",
    full_city_name: "Greeley, PA",
    city_name: "Greeley",
  },
  {
    postal_code: "53203",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "97874",
    full_city_name: "Spray, OR",
    city_name: "Spray",
  },
  {
    postal_code: "56014",
    full_city_name: "Bricelyn, MN",
    city_name: "Bricelyn",
  },
  {
    postal_code: "03241",
    full_city_name: "Hebron, NH",
    city_name: "Hebron",
  },
  {
    postal_code: "54498",
    full_city_name: "Withee, WI",
    city_name: "Withee",
  },
  {
    postal_code: "80836",
    full_city_name: "Stratton, CO",
    city_name: "Stratton",
  },
  {
    postal_code: "30220",
    full_city_name: "Grantville, GA",
    city_name: "Grantville",
  },
  {
    postal_code: "56586",
    full_city_name: "Underwood, MN",
    city_name: "Underwood",
  },
  {
    postal_code: "60928",
    full_city_name: "Crescent City, IL",
    city_name: "Crescent City",
  },
  {
    postal_code: "50271",
    full_city_name: "Williams, IA",
    city_name: "Williams",
  },
  {
    postal_code: "21677",
    full_city_name: "Woolford, MD",
    city_name: "Woolford",
  },
  {
    postal_code: "47235",
    full_city_name: "Freetown, IN",
    city_name: "Freetown",
  },
  {
    postal_code: "61470",
    full_city_name: "Prairie City, IL",
    city_name: "Prairie City",
  },
  {
    postal_code: "75937",
    full_city_name: "Chireno, TX",
    city_name: "Chireno",
  },
  {
    postal_code: "12433",
    full_city_name: "Glenford, NY",
    city_name: "Glenford",
  },
  {
    postal_code: "64090",
    full_city_name: "Strasburg, MO",
    city_name: "Strasburg",
  },
  {
    postal_code: "28649",
    full_city_name: "Mc Grady, NC",
    city_name: "Mc Grady",
  },
  {
    postal_code: "97813",
    full_city_name: "Athena, OR",
    city_name: "Athena",
  },
  {
    postal_code: "37023",
    full_city_name: "Big Rock, TN",
    city_name: "Big Rock",
  },
  {
    postal_code: "62027",
    full_city_name: "Eldred, IL",
    city_name: "Eldred",
  },
  {
    postal_code: "72721",
    full_city_name: "Combs, AR",
    city_name: "Combs",
  },
  {
    postal_code: "42732",
    full_city_name: "Eastview, KY",
    city_name: "Eastview",
  },
  {
    postal_code: "55974",
    full_city_name: "Spring Grove, MN",
    city_name: "Spring Grove",
  },
  {
    postal_code: "58005",
    full_city_name: "Argusville, ND",
    city_name: "Argusville",
  },
  {
    postal_code: "55757",
    full_city_name: "Kettle River, MN",
    city_name: "Kettle River",
  },
  {
    postal_code: "51029",
    full_city_name: "Larrabee, IA",
    city_name: "Larrabee",
  },
  {
    postal_code: "61882",
    full_city_name: "Weldon, IL",
    city_name: "Weldon",
  },
  {
    postal_code: "14738",
    full_city_name: "Frewsburg, NY",
    city_name: "Frewsburg",
  },
  {
    postal_code: "50241",
    full_city_name: "Saint Marys, IA",
    city_name: "Saint Marys",
  },
  {
    postal_code: "31020",
    full_city_name: "Dry Branch, GA",
    city_name: "Dry Branch",
  },
  {
    postal_code: "11697",
    full_city_name: "Breezy Point, NY",
    city_name: "Breezy Point",
  },
  {
    postal_code: "68002",
    full_city_name: "Arlington, NE",
    city_name: "Arlington",
  },
  {
    postal_code: "58220",
    full_city_name: "Cavalier, ND",
    city_name: "Cavalier",
  },
  {
    postal_code: "27305",
    full_city_name: "Milton, NC",
    city_name: "Milton",
  },
  {
    postal_code: "59821",
    full_city_name: "Arlee, MT",
    city_name: "Arlee",
  },
  {
    postal_code: "74454",
    full_city_name: "Porter, OK",
    city_name: "Porter",
  },
  {
    postal_code: "62373",
    full_city_name: "Sutter, IL",
    city_name: "Sutter",
  },
  {
    postal_code: "50468",
    full_city_name: "Rockford, IA",
    city_name: "Rockford",
  },
  {
    postal_code: "26865",
    full_city_name: "Yellow Spring, WV",
    city_name: "Yellow Spring",
  },
  {
    postal_code: "29659",
    full_city_name: "Lowndesville, SC",
    city_name: "Lowndesville",
  },
  {
    postal_code: "67035",
    full_city_name: "Cunningham, KS",
    city_name: "Cunningham",
  },
  {
    postal_code: "05833",
    full_city_name: "East Charleston, VT",
    city_name: "East Charleston",
  },
  {
    postal_code: "84767",
    full_city_name: "Springdale, UT",
    city_name: "Springdale",
  },
  {
    postal_code: "52759",
    full_city_name: "Montpelier, IA",
    city_name: "Montpelier",
  },
  {
    postal_code: "67020",
    full_city_name: "Burrton, KS",
    city_name: "Burrton",
  },
  {
    postal_code: "01339",
    full_city_name: "Charlemont, MA",
    city_name: "Charlemont",
  },
  {
    postal_code: "59223",
    full_city_name: "Fort Peck, MT",
    city_name: "Fort Peck",
  },
  {
    postal_code: "13141",
    full_city_name: "Preble, NY",
    city_name: "Preble",
  },
  {
    postal_code: "39063",
    full_city_name: "Durant, MS",
    city_name: "Durant",
  },
  {
    postal_code: "81244",
    full_city_name: "Rockvale, CO",
    city_name: "Rockvale",
  },
  {
    postal_code: "84331",
    full_city_name: "Portage, UT",
    city_name: "Portage",
  },
  {
    postal_code: "08555",
    full_city_name: "Roosevelt, NJ",
    city_name: "Roosevelt",
  },
  {
    postal_code: "31824",
    full_city_name: "Preston, GA",
    city_name: "Preston",
  },
  {
    postal_code: "22740",
    full_city_name: "Sperryville, VA",
    city_name: "Sperryville",
  },
  {
    postal_code: "96044",
    full_city_name: "Hornbrook, CA",
    city_name: "Hornbrook",
  },
  {
    postal_code: "37892",
    full_city_name: "Winfield, TN",
    city_name: "Winfield",
  },
  {
    postal_code: "68413",
    full_city_name: "Nehawka, NE",
    city_name: "Nehawka",
  },
  {
    postal_code: "04287",
    full_city_name: "Bowdoin, ME",
    city_name: "Bowdoin",
  },
  {
    postal_code: "12855",
    full_city_name: "North Hudson, NY",
    city_name: "North Hudson",
  },
  {
    postal_code: "45349",
    full_city_name: "North Hampton, OH",
    city_name: "North Hampton",
  },
  {
    postal_code: "80535",
    full_city_name: "Laporte, CO",
    city_name: "Laporte",
  },
  {
    postal_code: "07935",
    full_city_name: "Green Village, NJ",
    city_name: "Green Village",
  },
  {
    postal_code: "65085",
    full_city_name: "Westphalia, MO",
    city_name: "Westphalia",
  },
  {
    postal_code: "56440",
    full_city_name: "Clarissa, MN",
    city_name: "Clarissa",
  },
  {
    postal_code: "44836",
    full_city_name: "Green Springs, OH",
    city_name: "Green Springs",
  },
  {
    postal_code: "15713",
    full_city_name: "Aultman, PA",
    city_name: "Aultman",
  },
  {
    postal_code: "57373",
    full_city_name: "Saint Lawrence, SD",
    city_name: "Saint Lawrence",
  },
  {
    postal_code: "61737",
    full_city_name: "Ellsworth, IL",
    city_name: "Ellsworth",
  },
  {
    postal_code: "80926",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "27314",
    full_city_name: "Prospect Hill, NC",
    city_name: "Prospect Hill",
  },
  {
    postal_code: "67454",
    full_city_name: "Kanopolis, KS",
    city_name: "Kanopolis",
  },
  {
    postal_code: "02876",
    full_city_name: "Slatersville, RI",
    city_name: "Slatersville",
  },
  {
    postal_code: "08561",
    full_city_name: "Windsor, NJ",
    city_name: "Windsor",
  },
  {
    postal_code: "83867",
    full_city_name: "Silverton, ID",
    city_name: "Silverton",
  },
  {
    postal_code: "61544",
    full_city_name: "London Mills, IL",
    city_name: "London Mills",
  },
  {
    postal_code: "48834",
    full_city_name: "Fenwick, MI",
    city_name: "Fenwick",
  },
  {
    postal_code: "05666",
    full_city_name: "North Montpelier, VT",
    city_name: "North Montpelier",
  },
  {
    postal_code: "29564",
    full_city_name: "Lane, SC",
    city_name: "Lane",
  },
  {
    postal_code: "42740",
    full_city_name: "Glendale, KY",
    city_name: "Glendale",
  },
  {
    postal_code: "12406",
    full_city_name: "Arkville, NY",
    city_name: "Arkville",
  },
  {
    postal_code: "62292",
    full_city_name: "Tilden, IL",
    city_name: "Tilden",
  },
  {
    postal_code: "45812",
    full_city_name: "Alger, OH",
    city_name: "Alger",
  },
  {
    postal_code: "05341",
    full_city_name: "East Dover, VT",
    city_name: "East Dover",
  },
  {
    postal_code: "61379",
    full_city_name: "Wyanet, IL",
    city_name: "Wyanet",
  },
  {
    postal_code: "73547",
    full_city_name: "Granite, OK",
    city_name: "Granite",
  },
  {
    postal_code: "52580",
    full_city_name: "Packwood, IA",
    city_name: "Packwood",
  },
  {
    postal_code: "06409",
    full_city_name: "Centerbrook, CT",
    city_name: "Centerbrook",
  },
  {
    postal_code: "28538",
    full_city_name: "Hookerton, NC",
    city_name: "Hookerton",
  },
  {
    postal_code: "14541",
    full_city_name: "Romulus, NY",
    city_name: "Romulus",
  },
  {
    postal_code: "22964",
    full_city_name: "Piney River, VA",
    city_name: "Piney River",
  },
  {
    postal_code: "74936",
    full_city_name: "Gans, OK",
    city_name: "Gans",
  },
  {
    postal_code: "35610",
    full_city_name: "Anderson, AL",
    city_name: "Anderson",
  },
  {
    postal_code: "94933",
    full_city_name: "Forest Knolls, CA",
    city_name: "Forest Knolls",
  },
  {
    postal_code: "15687",
    full_city_name: "Stahlstown, PA",
    city_name: "Stahlstown",
  },
  {
    postal_code: "03444",
    full_city_name: "Dublin, NH",
    city_name: "Dublin",
  },
  {
    postal_code: "83812",
    full_city_name: "Clarkia, ID",
    city_name: "Clarkia",
  },
  {
    postal_code: "38951",
    full_city_name: "Pittsboro, MS",
    city_name: "Pittsboro",
  },
  {
    postal_code: "68404",
    full_city_name: "Martell, NE",
    city_name: "Martell",
  },
  {
    postal_code: "47385",
    full_city_name: "Spiceland, IN",
    city_name: "Spiceland",
  },
  {
    postal_code: "17052",
    full_city_name: "Mapleton Depot, PA",
    city_name: "Mapleton Depot",
  },
  {
    postal_code: "15623",
    full_city_name: "Claridge, PA",
    city_name: "Claridge",
  },
  {
    postal_code: "47282",
    full_city_name: "Vernon, IN",
    city_name: "Vernon",
  },
  {
    postal_code: "25202",
    full_city_name: "Tornado, WV",
    city_name: "Tornado",
  },
  {
    postal_code: "64623",
    full_city_name: "Bosworth, MO",
    city_name: "Bosworth",
  },
  {
    postal_code: "62979",
    full_city_name: "Ridgway, IL",
    city_name: "Ridgway",
  },
  {
    postal_code: "68669",
    full_city_name: "Ulysses, NE",
    city_name: "Ulysses",
  },
  {
    postal_code: "72017",
    full_city_name: "Biscoe, AR",
    city_name: "Biscoe",
  },
  {
    postal_code: "31780",
    full_city_name: "Plains, GA",
    city_name: "Plains",
  },
  {
    postal_code: "14889",
    full_city_name: "Van Etten, NY",
    city_name: "Van Etten",
  },
  {
    postal_code: "14055",
    full_city_name: "East Concord, NY",
    city_name: "East Concord",
  },
  {
    postal_code: "76239",
    full_city_name: "Forestburg, TX",
    city_name: "Forestburg",
  },
  {
    postal_code: "47273",
    full_city_name: "Scipio, IN",
    city_name: "Scipio",
  },
  {
    postal_code: "51551",
    full_city_name: "Malvern, IA",
    city_name: "Malvern",
  },
  {
    postal_code: "84667",
    full_city_name: "Wales, UT",
    city_name: "Wales",
  },
  {
    postal_code: "05359",
    full_city_name: "West Townshend, VT",
    city_name: "West Townshend",
  },
  {
    postal_code: "80801",
    full_city_name: "Anton, CO",
    city_name: "Anton",
  },
  {
    postal_code: "66531",
    full_city_name: "Riley, KS",
    city_name: "Riley",
  },
  {
    postal_code: "10976",
    full_city_name: "Sparkill, NY",
    city_name: "Sparkill",
  },
  {
    postal_code: "35549",
    full_city_name: "Carbon Hill, AL",
    city_name: "Carbon Hill",
  },
  {
    postal_code: "95651",
    full_city_name: "Lotus, CA",
    city_name: "Lotus",
  },
  {
    postal_code: "68835",
    full_city_name: "Elba, NE",
    city_name: "Elba",
  },
  {
    postal_code: "65074",
    full_city_name: "Russellville, MO",
    city_name: "Russellville",
  },
  {
    postal_code: "37829",
    full_city_name: "Oakdale, TN",
    city_name: "Oakdale",
  },
  {
    postal_code: "13054",
    full_city_name: "Durhamville, NY",
    city_name: "Durhamville",
  },
  {
    postal_code: "03765",
    full_city_name: "Haverhill, NH",
    city_name: "Haverhill",
  },
  {
    postal_code: "62819",
    full_city_name: "Buckner, IL",
    city_name: "Buckner",
  },
  {
    postal_code: "01243",
    full_city_name: "Middlefield, MA",
    city_name: "Middlefield",
  },
  {
    postal_code: "17576",
    full_city_name: "Smoketown, PA",
    city_name: "Smoketown",
  },
  {
    postal_code: "44866",
    full_city_name: "Polk, OH",
    city_name: "Polk",
  },
  {
    postal_code: "52542",
    full_city_name: "Cantril, IA",
    city_name: "Cantril",
  },
  {
    postal_code: "37374",
    full_city_name: "Sequatchie, TN",
    city_name: "Sequatchie",
  },
  {
    postal_code: "74533",
    full_city_name: "Caney, OK",
    city_name: "Caney",
  },
  {
    postal_code: "62261",
    full_city_name: "Modoc, IL",
    city_name: "Modoc",
  },
  {
    postal_code: "56010",
    full_city_name: "Amboy, MN",
    city_name: "Amboy",
  },
  {
    postal_code: "66619",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "62359",
    full_city_name: "Paloma, IL",
    city_name: "Paloma",
  },
  {
    postal_code: "54653",
    full_city_name: "Rockland, WI",
    city_name: "Rockland",
  },
  {
    postal_code: "83212",
    full_city_name: "Arbon, ID",
    city_name: "Arbon",
  },
  {
    postal_code: "68810",
    full_city_name: "Alda, NE",
    city_name: "Alda",
  },
  {
    postal_code: "54405",
    full_city_name: "Abbotsford, WI",
    city_name: "Abbotsford",
  },
  {
    postal_code: "16110",
    full_city_name: "Adamsville, PA",
    city_name: "Adamsville",
  },
  {
    postal_code: "32321",
    full_city_name: "Bristol, FL",
    city_name: "Bristol",
  },
  {
    postal_code: "02875",
    full_city_name: "Shannock, RI",
    city_name: "Shannock",
  },
  {
    postal_code: "50582",
    full_city_name: "Rutland, IA",
    city_name: "Rutland",
  },
  {
    postal_code: "98263",
    full_city_name: "Lyman, WA",
    city_name: "Lyman",
  },
  {
    postal_code: "10914",
    full_city_name: "Blooming Grove, NY",
    city_name: "Blooming Grove",
  },
  {
    postal_code: "37810",
    full_city_name: "Mohawk, TN",
    city_name: "Mohawk",
  },
  {
    postal_code: "79377",
    full_city_name: "Welch, TX",
    city_name: "Welch",
  },
  {
    postal_code: "74557",
    full_city_name: "Moyers, OK",
    city_name: "Moyers",
  },
  {
    postal_code: "43915",
    full_city_name: "Clarington, OH",
    city_name: "Clarington",
  },
  {
    postal_code: "52225",
    full_city_name: "Elberon, IA",
    city_name: "Elberon",
  },
  {
    postal_code: "45729",
    full_city_name: "Fleming, OH",
    city_name: "Fleming",
  },
  {
    postal_code: "41669",
    full_city_name: "Wheelwright, KY",
    city_name: "Wheelwright",
  },
  {
    postal_code: "57276",
    full_city_name: "White, SD",
    city_name: "White",
  },
  {
    postal_code: "12432",
    full_city_name: "Glasco, NY",
    city_name: "Glasco",
  },
  {
    postal_code: "47124",
    full_city_name: "Greenville, IN",
    city_name: "Greenville",
  },
  {
    postal_code: "33960",
    full_city_name: "Venus, FL",
    city_name: "Venus",
  },
  {
    postal_code: "73655",
    full_city_name: "Lone Wolf, OK",
    city_name: "Lone Wolf",
  },
  {
    postal_code: "84633",
    full_city_name: "Goshen, UT",
    city_name: "Goshen",
  },
  {
    postal_code: "56578",
    full_city_name: "Rochert, MN",
    city_name: "Rochert",
  },
  {
    postal_code: "56738",
    full_city_name: "Newfolden, MN",
    city_name: "Newfolden",
  },
  {
    postal_code: "41547",
    full_city_name: "Majestic, KY",
    city_name: "Majestic",
  },
  {
    postal_code: "74941",
    full_city_name: "Keota, OK",
    city_name: "Keota",
  },
  {
    postal_code: "61516",
    full_city_name: "Benson, IL",
    city_name: "Benson",
  },
  {
    postal_code: "03777",
    full_city_name: "Orford, NH",
    city_name: "Orford",
  },
  {
    postal_code: "63540",
    full_city_name: "Gibbs, MO",
    city_name: "Gibbs",
  },
  {
    postal_code: "30669",
    full_city_name: "Union Point, GA",
    city_name: "Union Point",
  },
  {
    postal_code: "75475",
    full_city_name: "Randolph, TX",
    city_name: "Randolph",
  },
  {
    postal_code: "65263",
    full_city_name: "Madison, MO",
    city_name: "Madison",
  },
  {
    postal_code: "08014",
    full_city_name: "Bridgeport, NJ",
    city_name: "Bridgeport",
  },
  {
    postal_code: "72324",
    full_city_name: "Cherry Valley, AR",
    city_name: "Cherry Valley",
  },
  {
    postal_code: "54741",
    full_city_name: "Fairchild, WI",
    city_name: "Fairchild",
  },
  {
    postal_code: "62015",
    full_city_name: "Butler, IL",
    city_name: "Butler",
  },
  {
    postal_code: "57227",
    full_city_name: "Corona, SD",
    city_name: "Corona",
  },
  {
    postal_code: "25174",
    full_city_name: "Rock Creek, WV",
    city_name: "Rock Creek",
  },
  {
    postal_code: "56565",
    full_city_name: "Nashua, MN",
    city_name: "Nashua",
  },
  {
    postal_code: "99128",
    full_city_name: "Farmington, WA",
    city_name: "Farmington",
  },
  {
    postal_code: "68961",
    full_city_name: "Nelson, NE",
    city_name: "Nelson",
  },
  {
    postal_code: "63845",
    full_city_name: "East Prairie, MO",
    city_name: "East Prairie",
  },
  {
    postal_code: "68741",
    full_city_name: "Hubbard, NE",
    city_name: "Hubbard",
  },
  {
    postal_code: "56243",
    full_city_name: "Grove City, MN",
    city_name: "Grove City",
  },
  {
    postal_code: "40346",
    full_city_name: "Means, KY",
    city_name: "Means",
  },
  {
    postal_code: "54465",
    full_city_name: "Pickerel, WI",
    city_name: "Pickerel",
  },
  {
    postal_code: "62054",
    full_city_name: "Kane, IL",
    city_name: "Kane",
  },
  {
    postal_code: "38041",
    full_city_name: "Henning, TN",
    city_name: "Henning",
  },
  {
    postal_code: "95914",
    full_city_name: "Bangor, CA",
    city_name: "Bangor",
  },
  {
    postal_code: "47035",
    full_city_name: "New Trenton, IN",
    city_name: "New Trenton",
  },
  {
    postal_code: "25060",
    full_city_name: "Dorothy, WV",
    city_name: "Dorothy",
  },
  {
    postal_code: "04488",
    full_city_name: "Stetson, ME",
    city_name: "Stetson",
  },
  {
    postal_code: "66717",
    full_city_name: "Buffalo, KS",
    city_name: "Buffalo",
  },
  {
    postal_code: "70752",
    full_city_name: "Lakeland, LA",
    city_name: "Lakeland",
  },
  {
    postal_code: "16679",
    full_city_name: "Six Mile Run, PA",
    city_name: "Six Mile Run",
  },
  {
    postal_code: "59866",
    full_city_name: "Saint Regis, MT",
    city_name: "Saint Regis",
  },
  {
    postal_code: "37171",
    full_city_name: "Southside, TN",
    city_name: "Southside",
  },
  {
    postal_code: "49852",
    full_city_name: "Loretto, MI",
    city_name: "Loretto",
  },
  {
    postal_code: "75437",
    full_city_name: "Dike, TX",
    city_name: "Dike",
  },
  {
    postal_code: "95699",
    full_city_name: "Drytown, CA",
    city_name: "Drytown",
  },
  {
    postal_code: "64854",
    full_city_name: "Noel, MO",
    city_name: "Noel",
  },
  {
    postal_code: "76641",
    full_city_name: "Frost, TX",
    city_name: "Frost",
  },
  {
    postal_code: "50652",
    full_city_name: "Lincoln, IA",
    city_name: "Lincoln",
  },
  {
    postal_code: "84632",
    full_city_name: "Fountain Green, UT",
    city_name: "Fountain Green",
  },
  {
    postal_code: "50630",
    full_city_name: "Fredericksburg, IA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "56223",
    full_city_name: "Clarkfield, MN",
    city_name: "Clarkfield",
  },
  {
    postal_code: "56251",
    full_city_name: "Kandiyohi, MN",
    city_name: "Kandiyohi",
  },
  {
    postal_code: "64859",
    full_city_name: "Reeds, MO",
    city_name: "Reeds",
  },
  {
    postal_code: "64446",
    full_city_name: "Fairfax, MO",
    city_name: "Fairfax",
  },
  {
    postal_code: "45899",
    full_city_name: "Wren, OH",
    city_name: "Wren",
  },
  {
    postal_code: "20758",
    full_city_name: "Friendship, MD",
    city_name: "Friendship",
  },
  {
    postal_code: "68422",
    full_city_name: "Pickrell, NE",
    city_name: "Pickrell",
  },
  {
    postal_code: "59467",
    full_city_name: "Pendroy, MT",
    city_name: "Pendroy",
  },
  {
    postal_code: "97109",
    full_city_name: "Buxton, OR",
    city_name: "Buxton",
  },
  {
    postal_code: "18825",
    full_city_name: "Jackson, PA",
    city_name: "Jackson",
  },
  {
    postal_code: "24915",
    full_city_name: "Arbovale, WV",
    city_name: "Arbovale",
  },
  {
    postal_code: "12969",
    full_city_name: "Owls Head, NY",
    city_name: "Owls Head",
  },
  {
    postal_code: "26035",
    full_city_name: "Colliers, WV",
    city_name: "Colliers",
  },
  {
    postal_code: "62814",
    full_city_name: "Bluford, IL",
    city_name: "Bluford",
  },
  {
    postal_code: "67837",
    full_city_name: "Copeland, KS",
    city_name: "Copeland",
  },
  {
    postal_code: "44506",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "63106",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "25571",
    full_city_name: "West Hamlin, WV",
    city_name: "West Hamlin",
  },
  {
    postal_code: "68621",
    full_city_name: "Ames, NE",
    city_name: "Ames",
  },
  {
    postal_code: "79560",
    full_city_name: "Sylvester, TX",
    city_name: "Sylvester",
  },
  {
    postal_code: "29030",
    full_city_name: "Cameron, SC",
    city_name: "Cameron",
  },
  {
    postal_code: "43467",
    full_city_name: "West Millgrove, OH",
    city_name: "West Millgrove",
  },
  {
    postal_code: "76427",
    full_city_name: "Bryson, TX",
    city_name: "Bryson",
  },
  {
    postal_code: "05444",
    full_city_name: "Cambridge, VT",
    city_name: "Cambridge",
  },
  {
    postal_code: "19562",
    full_city_name: "Topton, PA",
    city_name: "Topton",
  },
  {
    postal_code: "63030",
    full_city_name: "Fletcher, MO",
    city_name: "Fletcher",
  },
  {
    postal_code: "48853",
    full_city_name: "Maple Rapids, MI",
    city_name: "Maple Rapids",
  },
  {
    postal_code: "38773",
    full_city_name: "Shaw, MS",
    city_name: "Shaw",
  },
  {
    postal_code: "52054",
    full_city_name: "La Motte, IA",
    city_name: "La Motte",
  },
  {
    postal_code: "46156",
    full_city_name: "Milroy, IN",
    city_name: "Milroy",
  },
  {
    postal_code: "03745",
    full_city_name: "Cornish, NH",
    city_name: "Cornish",
  },
  {
    postal_code: "57440",
    full_city_name: "Frankfort, SD",
    city_name: "Frankfort",
  },
  {
    postal_code: "63548",
    full_city_name: "Lancaster, MO",
    city_name: "Lancaster",
  },
  {
    postal_code: "12860",
    full_city_name: "Pottersville, NY",
    city_name: "Pottersville",
  },
  {
    postal_code: "28523",
    full_city_name: "Cove City, NC",
    city_name: "Cove City",
  },
  {
    postal_code: "49747",
    full_city_name: "Hubbard Lake, MI",
    city_name: "Hubbard Lake",
  },
  {
    postal_code: "89442",
    full_city_name: "Wadsworth, NV",
    city_name: "Wadsworth",
  },
  {
    postal_code: "15686",
    full_city_name: "Spring Church, PA",
    city_name: "Spring Church",
  },
  {
    postal_code: "68366",
    full_city_name: "Greenwood, NE",
    city_name: "Greenwood",
  },
  {
    postal_code: "55017",
    full_city_name: "Dalbo, MN",
    city_name: "Dalbo",
  },
  {
    postal_code: "67482",
    full_city_name: "Talmage, KS",
    city_name: "Talmage",
  },
  {
    postal_code: "05042",
    full_city_name: "East Ryegate, VT",
    city_name: "East Ryegate",
  },
  {
    postal_code: "36921",
    full_city_name: "Toxey, AL",
    city_name: "Toxey",
  },
  {
    postal_code: "65773",
    full_city_name: "Wasola, MO",
    city_name: "Wasola",
  },
  {
    postal_code: "21667",
    full_city_name: "Still Pond, MD",
    city_name: "Still Pond",
  },
  {
    postal_code: "68307",
    full_city_name: "Avoca, NE",
    city_name: "Avoca",
  },
  {
    postal_code: "61771",
    full_city_name: "Secor, IL",
    city_name: "Secor",
  },
  {
    postal_code: "66105",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "60437",
    full_city_name: "Kinsman, IL",
    city_name: "Kinsman",
  },
  {
    postal_code: "76570",
    full_city_name: "Rosebud, TX",
    city_name: "Rosebud",
  },
  {
    postal_code: "01070",
    full_city_name: "Plainfield, MA",
    city_name: "Plainfield",
  },
  {
    postal_code: "20606",
    full_city_name: "Abell, MD",
    city_name: "Abell",
  },
  {
    postal_code: "57420",
    full_city_name: "Akaska, SD",
    city_name: "Akaska",
  },
  {
    postal_code: "72320",
    full_city_name: "Brickeys, AR",
    city_name: "Brickeys",
  },
  {
    postal_code: "51026",
    full_city_name: "Hornick, IA",
    city_name: "Hornick",
  },
  {
    postal_code: "97389",
    full_city_name: "Tangent, OR",
    city_name: "Tangent",
  },
  {
    postal_code: "22538",
    full_city_name: "Rappahannock Academy, VA",
    city_name: "Rappahannock Academy",
  },
  {
    postal_code: "49638",
    full_city_name: "Harrietta, MI",
    city_name: "Harrietta",
  },
  {
    postal_code: "04063",
    full_city_name: "Ocean Park, ME",
    city_name: "Ocean Park",
  },
  {
    postal_code: "47470",
    full_city_name: "Williams, IN",
    city_name: "Williams",
  },
  {
    postal_code: "62666",
    full_city_name: "Middletown, IL",
    city_name: "Middletown",
  },
  {
    postal_code: "75153",
    full_city_name: "Powell, TX",
    city_name: "Powell",
  },
  {
    postal_code: "94973",
    full_city_name: "Woodacre, CA",
    city_name: "Woodacre",
  },
  {
    postal_code: "61250",
    full_city_name: "Erie, IL",
    city_name: "Erie",
  },
  {
    postal_code: "47588",
    full_city_name: "Troy, IN",
    city_name: "Troy",
  },
  {
    postal_code: "41839",
    full_city_name: "Mousie, KY",
    city_name: "Mousie",
  },
  {
    postal_code: "63013",
    full_city_name: "Beaufort, MO",
    city_name: "Beaufort",
  },
  {
    postal_code: "24622",
    full_city_name: "Jewell Ridge, VA",
    city_name: "Jewell Ridge",
  },
  {
    postal_code: "62543",
    full_city_name: "Latham, IL",
    city_name: "Latham",
  },
  {
    postal_code: "94576",
    full_city_name: "Deer Park, CA",
    city_name: "Deer Park",
  },
  {
    postal_code: "73021",
    full_city_name: "Colony, OK",
    city_name: "Colony",
  },
  {
    postal_code: "74035",
    full_city_name: "Hominy, OK",
    city_name: "Hominy",
  },
  {
    postal_code: "61259",
    full_city_name: "Illinois City, IL",
    city_name: "Illinois City",
  },
  {
    postal_code: "27888",
    full_city_name: "Walstonburg, NC",
    city_name: "Walstonburg",
  },
  {
    postal_code: "27866",
    full_city_name: "Pleasant Hill, NC",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "49959",
    full_city_name: "Ramsay, MI",
    city_name: "Ramsay",
  },
  {
    postal_code: "06023",
    full_city_name: "East Berlin, CT",
    city_name: "East Berlin",
  },
  {
    postal_code: "47145",
    full_city_name: "Milltown, IN",
    city_name: "Milltown",
  },
  {
    postal_code: "01037",
    full_city_name: "Hardwick, MA",
    city_name: "Hardwick",
  },
  {
    postal_code: "49793",
    full_city_name: "Trout Lake, MI",
    city_name: "Trout Lake",
  },
  {
    postal_code: "80750",
    full_city_name: "Snyder, CO",
    city_name: "Snyder",
  },
  {
    postal_code: "35958",
    full_city_name: "Bryant, AL",
    city_name: "Bryant",
  },
  {
    postal_code: "17267",
    full_city_name: "Warfordsburg, PA",
    city_name: "Warfordsburg",
  },
  {
    postal_code: "43746",
    full_city_name: "Hopewell, OH",
    city_name: "Hopewell",
  },
  {
    postal_code: "16611",
    full_city_name: "Alexandria, PA",
    city_name: "Alexandria",
  },
  {
    postal_code: "96120",
    full_city_name: "Markleeville, CA",
    city_name: "Markleeville",
  },
  {
    postal_code: "04732",
    full_city_name: "Ashland, ME",
    city_name: "Ashland",
  },
  {
    postal_code: "75488",
    full_city_name: "Telephone, TX",
    city_name: "Telephone",
  },
  {
    postal_code: "13160",
    full_city_name: "Union Springs, NY",
    city_name: "Union Springs",
  },
  {
    postal_code: "23442",
    full_city_name: "Temperanceville, VA",
    city_name: "Temperanceville",
  },
  {
    postal_code: "43556",
    full_city_name: "Sherwood, OH",
    city_name: "Sherwood",
  },
  {
    postal_code: "98812",
    full_city_name: "Brewster, WA",
    city_name: "Brewster",
  },
  {
    postal_code: "46058",
    full_city_name: "Mulberry, IN",
    city_name: "Mulberry",
  },
  {
    postal_code: "49729",
    full_city_name: "Ellsworth, MI",
    city_name: "Ellsworth",
  },
  {
    postal_code: "53079",
    full_city_name: "Saint Cloud, WI",
    city_name: "Saint Cloud",
  },
  {
    postal_code: "71341",
    full_city_name: "Hessmer, LA",
    city_name: "Hessmer",
  },
  {
    postal_code: "42776",
    full_city_name: "Sonora, KY",
    city_name: "Sonora",
  },
  {
    postal_code: "65284",
    full_city_name: "Sturgeon, MO",
    city_name: "Sturgeon",
  },
  {
    postal_code: "64738",
    full_city_name: "Collins, MO",
    city_name: "Collins",
  },
  {
    postal_code: "36262",
    full_city_name: "Fruithurst, AL",
    city_name: "Fruithurst",
  },
  {
    postal_code: "62848",
    full_city_name: "Irvington, IL",
    city_name: "Irvington",
  },
  {
    postal_code: "47357",
    full_city_name: "Milton, IN",
    city_name: "Milton",
  },
  {
    postal_code: "81221",
    full_city_name: "Coal Creek, CO",
    city_name: "Coal Creek",
  },
  {
    postal_code: "54460",
    full_city_name: "Owen, WI",
    city_name: "Owen",
  },
  {
    postal_code: "97731",
    full_city_name: "Chemult, OR",
    city_name: "Chemult",
  },
  {
    postal_code: "38232",
    full_city_name: "Hornbeak, TN",
    city_name: "Hornbeak",
  },
  {
    postal_code: "99158",
    full_city_name: "Oakesdale, WA",
    city_name: "Oakesdale",
  },
  {
    postal_code: "42452",
    full_city_name: "Robards, KY",
    city_name: "Robards",
  },
  {
    postal_code: "68465",
    full_city_name: "Wilber, NE",
    city_name: "Wilber",
  },
  {
    postal_code: "63333",
    full_city_name: "Bellflower, MO",
    city_name: "Bellflower",
  },
  {
    postal_code: "43804",
    full_city_name: "Baltic, OH",
    city_name: "Baltic",
  },
  {
    postal_code: "65662",
    full_city_name: "Grovespring, MO",
    city_name: "Grovespring",
  },
  {
    postal_code: "95615",
    full_city_name: "Courtland, CA",
    city_name: "Courtland",
  },
  {
    postal_code: "74940",
    full_city_name: "Howe, OK",
    city_name: "Howe",
  },
  {
    postal_code: "55324",
    full_city_name: "Darwin, MN",
    city_name: "Darwin",
  },
  {
    postal_code: "14860",
    full_city_name: "Lodi, NY",
    city_name: "Lodi",
  },
  {
    postal_code: "43908",
    full_city_name: "Bergholz, OH",
    city_name: "Bergholz",
  },
  {
    postal_code: "93241",
    full_city_name: "Lamont, CA",
    city_name: "Lamont",
  },
  {
    postal_code: "22831",
    full_city_name: "Hinton, VA",
    city_name: "Hinton",
  },
  {
    postal_code: "50149",
    full_city_name: "Lorimor, IA",
    city_name: "Lorimor",
  },
  {
    postal_code: "45354",
    full_city_name: "Phillipsburg, OH",
    city_name: "Phillipsburg",
  },
  {
    postal_code: "02763",
    full_city_name: "Attleboro Falls, MA",
    city_name: "Attleboro Falls",
  },
  {
    postal_code: "25275",
    full_city_name: "Sandyville, WV",
    city_name: "Sandyville",
  },
  {
    postal_code: "05075",
    full_city_name: "Thetford Center, VT",
    city_name: "Thetford Center",
  },
  {
    postal_code: "13072",
    full_city_name: "Georgetown, NY",
    city_name: "Georgetown",
  },
  {
    postal_code: "50108",
    full_city_name: "Grand River, IA",
    city_name: "Grand River",
  },
  {
    postal_code: "24527",
    full_city_name: "Blairs, VA",
    city_name: "Blairs",
  },
  {
    postal_code: "29056",
    full_city_name: "Greeleyville, SC",
    city_name: "Greeleyville",
  },
  {
    postal_code: "70534",
    full_city_name: "Estherwood, LA",
    city_name: "Estherwood",
  },
  {
    postal_code: "68779",
    full_city_name: "Stanton, NE",
    city_name: "Stanton",
  },
  {
    postal_code: "26615",
    full_city_name: "Copen, WV",
    city_name: "Copen",
  },
  {
    postal_code: "62432",
    full_city_name: "Hidalgo, IL",
    city_name: "Hidalgo",
  },
  {
    postal_code: "42352",
    full_city_name: "Livermore, KY",
    city_name: "Livermore",
  },
  {
    postal_code: "07014",
    full_city_name: "Clifton, NJ",
    city_name: "Clifton",
  },
  {
    postal_code: "60934",
    full_city_name: "Emington, IL",
    city_name: "Emington",
  },
  {
    postal_code: "01340",
    full_city_name: "Colrain, MA",
    city_name: "Colrain",
  },
  {
    postal_code: "57472",
    full_city_name: "Selby, SD",
    city_name: "Selby",
  },
  {
    postal_code: "15759",
    full_city_name: "Marion Center, PA",
    city_name: "Marion Center",
  },
  {
    postal_code: "88415",
    full_city_name: "Clayton, NM",
    city_name: "Clayton",
  },
  {
    postal_code: "89007",
    full_city_name: "Bunkerville, NV",
    city_name: "Bunkerville",
  },
  {
    postal_code: "07114",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "52077",
    full_city_name: "Volga, IA",
    city_name: "Volga",
  },
  {
    postal_code: "84750",
    full_city_name: "Marysvale, UT",
    city_name: "Marysvale",
  },
  {
    postal_code: "66869",
    full_city_name: "Strong City, KS",
    city_name: "Strong City",
  },
  {
    postal_code: "17774",
    full_city_name: "Unityville, PA",
    city_name: "Unityville",
  },
  {
    postal_code: "95113",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "81062",
    full_city_name: "Olney Springs, CO",
    city_name: "Olney Springs",
  },
  {
    postal_code: "59088",
    full_city_name: "Worden, MT",
    city_name: "Worden",
  },
  {
    postal_code: "30575",
    full_city_name: "Talmo, GA",
    city_name: "Talmo",
  },
  {
    postal_code: "69034",
    full_city_name: "Indianola, NE",
    city_name: "Indianola",
  },
  {
    postal_code: "63944",
    full_city_name: "Greenville, MO",
    city_name: "Greenville",
  },
  {
    postal_code: "47858",
    full_city_name: "Lewis, IN",
    city_name: "Lewis",
  },
  {
    postal_code: "04252",
    full_city_name: "Lisbon Falls, ME",
    city_name: "Lisbon Falls",
  },
  {
    postal_code: "47525",
    full_city_name: "Derby, IN",
    city_name: "Derby",
  },
  {
    postal_code: "68409",
    full_city_name: "Murray, NE",
    city_name: "Murray",
  },
  {
    postal_code: "21082",
    full_city_name: "Hydes, MD",
    city_name: "Hydes",
  },
  {
    postal_code: "50232",
    full_city_name: "Reasnor, IA",
    city_name: "Reasnor",
  },
  {
    postal_code: "50543",
    full_city_name: "Gowrie, IA",
    city_name: "Gowrie",
  },
  {
    postal_code: "48432",
    full_city_name: "Filion, MI",
    city_name: "Filion",
  },
  {
    postal_code: "57028",
    full_city_name: "Flandreau, SD",
    city_name: "Flandreau",
  },
  {
    postal_code: "55941",
    full_city_name: "Hokah, MN",
    city_name: "Hokah",
  },
  {
    postal_code: "97864",
    full_city_name: "Monument, OR",
    city_name: "Monument",
  },
  {
    postal_code: "98638",
    full_city_name: "Naselle, WA",
    city_name: "Naselle",
  },
  {
    postal_code: "69046",
    full_city_name: "Wilsonville, NE",
    city_name: "Wilsonville",
  },
  {
    postal_code: "13681",
    full_city_name: "Richville, NY",
    city_name: "Richville",
  },
  {
    postal_code: "46103",
    full_city_name: "Amo, IN",
    city_name: "Amo",
  },
  {
    postal_code: "48662",
    full_city_name: "Wheeler, MI",
    city_name: "Wheeler",
  },
  {
    postal_code: "84327",
    full_city_name: "Newton, UT",
    city_name: "Newton",
  },
  {
    postal_code: "12177",
    full_city_name: "Tribes Hill, NY",
    city_name: "Tribes Hill",
  },
  {
    postal_code: "46773",
    full_city_name: "Monroeville, IN",
    city_name: "Monroeville",
  },
  {
    postal_code: "15692",
    full_city_name: "Westmoreland City, PA",
    city_name: "Westmoreland City",
  },
  {
    postal_code: "30336",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "15741",
    full_city_name: "Gipsy, PA",
    city_name: "Gipsy",
  },
  {
    postal_code: "74062",
    full_city_name: "Ripley, OK",
    city_name: "Ripley",
  },
  {
    postal_code: "04108",
    full_city_name: "Peaks Island, ME",
    city_name: "Peaks Island",
  },
  {
    postal_code: "40828",
    full_city_name: "Evarts, KY",
    city_name: "Evarts",
  },
  {
    postal_code: "24951",
    full_city_name: "Lindside, WV",
    city_name: "Lindside",
  },
  {
    postal_code: "26224",
    full_city_name: "Helvetia, WV",
    city_name: "Helvetia",
  },
  {
    postal_code: "55919",
    full_city_name: "Brownsville, MN",
    city_name: "Brownsville",
  },
  {
    postal_code: "56297",
    full_city_name: "Wood Lake, MN",
    city_name: "Wood Lake",
  },
  {
    postal_code: "26447",
    full_city_name: "Walkersville, WV",
    city_name: "Walkersville",
  },
  {
    postal_code: "58238",
    full_city_name: "Hamilton, ND",
    city_name: "Hamilton",
  },
  {
    postal_code: "12747",
    full_city_name: "Hurleyville, NY",
    city_name: "Hurleyville",
  },
  {
    postal_code: "80939",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "15464",
    full_city_name: "Mill Run, PA",
    city_name: "Mill Run",
  },
  {
    postal_code: "46998",
    full_city_name: "Young America, IN",
    city_name: "Young America",
  },
  {
    postal_code: "76679",
    full_city_name: "Purdon, TX",
    city_name: "Purdon",
  },
  {
    postal_code: "83840",
    full_city_name: "Kootenai, ID",
    city_name: "Kootenai",
  },
  {
    postal_code: "66533",
    full_city_name: "Rossville, KS",
    city_name: "Rossville",
  },
  {
    postal_code: "68771",
    full_city_name: "Randolph, NE",
    city_name: "Randolph",
  },
  {
    postal_code: "28577",
    full_city_name: "Sealevel, NC",
    city_name: "Sealevel",
  },
  {
    postal_code: "82060",
    full_city_name: "Hillsdale, WY",
    city_name: "Hillsdale",
  },
  {
    postal_code: "81025",
    full_city_name: "Boone, CO",
    city_name: "Boone",
  },
  {
    postal_code: "13774",
    full_city_name: "Fishs Eddy, NY",
    city_name: "Fishs Eddy",
  },
  {
    postal_code: "52066",
    full_city_name: "North Buena Vista, IA",
    city_name: "North Buena Vista",
  },
  {
    postal_code: "24311",
    full_city_name: "Atkins, VA",
    city_name: "Atkins",
  },
  {
    postal_code: "64001",
    full_city_name: "Alma, MO",
    city_name: "Alma",
  },
  {
    postal_code: "05351",
    full_city_name: "South Newfane, VT",
    city_name: "South Newfane",
  },
  {
    postal_code: "94970",
    full_city_name: "Stinson Beach, CA",
    city_name: "Stinson Beach",
  },
  {
    postal_code: "12515",
    full_city_name: "Clintondale, NY",
    city_name: "Clintondale",
  },
  {
    postal_code: "71836",
    full_city_name: "Foreman, AR",
    city_name: "Foreman",
  },
  {
    postal_code: "58061",
    full_city_name: "Mooreton, ND",
    city_name: "Mooreton",
  },
  {
    postal_code: "62673",
    full_city_name: "Oakford, IL",
    city_name: "Oakford",
  },
  {
    postal_code: "41567",
    full_city_name: "Stone, KY",
    city_name: "Stone",
  },
  {
    postal_code: "87582",
    full_city_name: "Velarde, NM",
    city_name: "Velarde",
  },
  {
    postal_code: "68420",
    full_city_name: "Pawnee City, NE",
    city_name: "Pawnee City",
  },
  {
    postal_code: "01350",
    full_city_name: "Monroe Bridge, MA",
    city_name: "Monroe Bridge",
  },
  {
    postal_code: "37181",
    full_city_name: "Vanleer, TN",
    city_name: "Vanleer",
  },
  {
    postal_code: "76388",
    full_city_name: "Weinert, TX",
    city_name: "Weinert",
  },
  {
    postal_code: "62671",
    full_city_name: "New Holland, IL",
    city_name: "New Holland",
  },
  {
    postal_code: "75439",
    full_city_name: "Ector, TX",
    city_name: "Ector",
  },
  {
    postal_code: "43540",
    full_city_name: "Metamora, OH",
    city_name: "Metamora",
  },
  {
    postal_code: "47640",
    full_city_name: "Hazleton, IN",
    city_name: "Hazleton",
  },
  {
    postal_code: "48845",
    full_city_name: "Hubbardston, MI",
    city_name: "Hubbardston",
  },
  {
    postal_code: "45328",
    full_city_name: "Gettysburg, OH",
    city_name: "Gettysburg",
  },
  {
    postal_code: "58759",
    full_city_name: "Max, ND",
    city_name: "Max",
  },
  {
    postal_code: "97466",
    full_city_name: "Powers, OR",
    city_name: "Powers",
  },
  {
    postal_code: "97710",
    full_city_name: "Fields, OR",
    city_name: "Fields",
  },
  {
    postal_code: "18455",
    full_city_name: "Preston Park, PA",
    city_name: "Preston Park",
  },
  {
    postal_code: "62811",
    full_city_name: "Bellmont, IL",
    city_name: "Bellmont",
  },
  {
    postal_code: "68874",
    full_city_name: "Sargent, NE",
    city_name: "Sargent",
  },
  {
    postal_code: "47955",
    full_city_name: "Linden, IN",
    city_name: "Linden",
  },
  {
    postal_code: "93219",
    full_city_name: "Earlimart, CA",
    city_name: "Earlimart",
  },
  {
    postal_code: "14058",
    full_city_name: "Elba, NY",
    city_name: "Elba",
  },
  {
    postal_code: "67855",
    full_city_name: "Johnson, KS",
    city_name: "Johnson",
  },
  {
    postal_code: "63739",
    full_city_name: "Burfordville, MO",
    city_name: "Burfordville",
  },
  {
    postal_code: "44853",
    full_city_name: "New Riegel, OH",
    city_name: "New Riegel",
  },
  {
    postal_code: "14416",
    full_city_name: "Bergen, NY",
    city_name: "Bergen",
  },
  {
    postal_code: "19536",
    full_city_name: "Lyon Station, PA",
    city_name: "Lyon Station",
  },
  {
    postal_code: "41749",
    full_city_name: "Hyden, KY",
    city_name: "Hyden",
  },
  {
    postal_code: "67574",
    full_city_name: "Rozel, KS",
    city_name: "Rozel",
  },
  {
    postal_code: "41602",
    full_city_name: "Auxier, KY",
    city_name: "Auxier",
  },
  {
    postal_code: "68628",
    full_city_name: "Clarks, NE",
    city_name: "Clarks",
  },
  {
    postal_code: "12916",
    full_city_name: "Brushton, NY",
    city_name: "Brushton",
  },
  {
    postal_code: "12844",
    full_city_name: "Kattskill Bay, NY",
    city_name: "Kattskill Bay",
  },
  {
    postal_code: "41838",
    full_city_name: "Millstone, KY",
    city_name: "Millstone",
  },
  {
    postal_code: "14572",
    full_city_name: "Wayland, NY",
    city_name: "Wayland",
  },
  {
    postal_code: "40012",
    full_city_name: "Chaplin, KY",
    city_name: "Chaplin",
  },
  {
    postal_code: "79101",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "85264",
    full_city_name: "Fort Mcdowell, AZ",
    city_name: "Fort Mcdowell",
  },
  {
    postal_code: "42021",
    full_city_name: "Arlington, KY",
    city_name: "Arlington",
  },
  {
    postal_code: "55967",
    full_city_name: "Racine, MN",
    city_name: "Racine",
  },
  {
    postal_code: "68652",
    full_city_name: "Petersburg, NE",
    city_name: "Petersburg",
  },
  {
    postal_code: "62601",
    full_city_name: "Alexander, IL",
    city_name: "Alexander",
  },
  {
    postal_code: "44404",
    full_city_name: "Burghill, OH",
    city_name: "Burghill",
  },
  {
    postal_code: "48741",
    full_city_name: "Kingston, MI",
    city_name: "Kingston",
  },
  {
    postal_code: "58356",
    full_city_name: "New Rockford, ND",
    city_name: "New Rockford",
  },
  {
    postal_code: "03905",
    full_city_name: "Kittery Point, ME",
    city_name: "Kittery Point",
  },
  {
    postal_code: "68380",
    full_city_name: "Lewiston, NE",
    city_name: "Lewiston",
  },
  {
    postal_code: "81081",
    full_city_name: "Trinchera, CO",
    city_name: "Trinchera",
  },
  {
    postal_code: "61043",
    full_city_name: "Holcomb, IL",
    city_name: "Holcomb",
  },
  {
    postal_code: "47537",
    full_city_name: "Gentryville, IN",
    city_name: "Gentryville",
  },
  {
    postal_code: "63648",
    full_city_name: "Irondale, MO",
    city_name: "Irondale",
  },
  {
    postal_code: "96776",
    full_city_name: "Paauilo, HI",
    city_name: "Paauilo",
  },
  {
    postal_code: "59086",
    full_city_name: "Wilsall, MT",
    city_name: "Wilsall",
  },
  {
    postal_code: "57523",
    full_city_name: "Burke, SD",
    city_name: "Burke",
  },
  {
    postal_code: "43029",
    full_city_name: "Irwin, OH",
    city_name: "Irwin",
  },
  {
    postal_code: "95944",
    full_city_name: "Goodyears Bar, CA",
    city_name: "Goodyears Bar",
  },
  {
    postal_code: "17271",
    full_city_name: "Willow Hill, PA",
    city_name: "Willow Hill",
  },
  {
    postal_code: "17212",
    full_city_name: "Big Cove Tannery, PA",
    city_name: "Big Cove Tannery",
  },
  {
    postal_code: "41003",
    full_city_name: "Berry, KY",
    city_name: "Berry",
  },
  {
    postal_code: "27291",
    full_city_name: "Leasburg, NC",
    city_name: "Leasburg",
  },
  {
    postal_code: "95251",
    full_city_name: "Vallecito, CA",
    city_name: "Vallecito",
  },
  {
    postal_code: "18443",
    full_city_name: "Milanville, PA",
    city_name: "Milanville",
  },
  {
    postal_code: "52335",
    full_city_name: "South English, IA",
    city_name: "South English",
  },
  {
    postal_code: "04783",
    full_city_name: "Stockholm, ME",
    city_name: "Stockholm",
  },
  {
    postal_code: "43791",
    full_city_name: "White Cottage, OH",
    city_name: "White Cottage",
  },
  {
    postal_code: "38941",
    full_city_name: "Itta Bena, MS",
    city_name: "Itta Bena",
  },
  {
    postal_code: "64752",
    full_city_name: "Hume, MO",
    city_name: "Hume",
  },
  {
    postal_code: "05778",
    full_city_name: "Whiting, VT",
    city_name: "Whiting",
  },
  {
    postal_code: "58735",
    full_city_name: "Douglas, ND",
    city_name: "Douglas",
  },
  {
    postal_code: "41203",
    full_city_name: "Beauty, KY",
    city_name: "Beauty",
  },
  {
    postal_code: "70356",
    full_city_name: "Gibson, LA",
    city_name: "Gibson",
  },
  {
    postal_code: "04762",
    full_city_name: "New Sweden, ME",
    city_name: "New Sweden",
  },
  {
    postal_code: "72080",
    full_city_name: "Jerusalem, AR",
    city_name: "Jerusalem",
  },
  {
    postal_code: "49919",
    full_city_name: "Covington, MI",
    city_name: "Covington",
  },
  {
    postal_code: "61729",
    full_city_name: "Congerville, IL",
    city_name: "Congerville",
  },
  {
    postal_code: "87745",
    full_city_name: "Sapello, NM",
    city_name: "Sapello",
  },
  {
    postal_code: "01054",
    full_city_name: "Leverett, MA",
    city_name: "Leverett",
  },
  {
    postal_code: "16655",
    full_city_name: "Imler, PA",
    city_name: "Imler",
  },
  {
    postal_code: "14008",
    full_city_name: "Appleton, NY",
    city_name: "Appleton",
  },
  {
    postal_code: "42140",
    full_city_name: "Gamaliel, KY",
    city_name: "Gamaliel",
  },
  {
    postal_code: "62994",
    full_city_name: "Vergennes, IL",
    city_name: "Vergennes",
  },
  {
    postal_code: "25265",
    full_city_name: "New Haven, WV",
    city_name: "New Haven",
  },
  {
    postal_code: "15412",
    full_city_name: "Allenport, PA",
    city_name: "Allenport",
  },
  {
    postal_code: "27840",
    full_city_name: "Hamilton, NC",
    city_name: "Hamilton",
  },
  {
    postal_code: "52254",
    full_city_name: "Lost Nation, IA",
    city_name: "Lost Nation",
  },
  {
    postal_code: "69348",
    full_city_name: "Hemingford, NE",
    city_name: "Hemingford",
  },
  {
    postal_code: "64756",
    full_city_name: "Jerico Springs, MO",
    city_name: "Jerico Springs",
  },
  {
    postal_code: "15032",
    full_city_name: "Curtisville, PA",
    city_name: "Curtisville",
  },
  {
    postal_code: "27209",
    full_city_name: "Biscoe, NC",
    city_name: "Biscoe",
  },
  {
    postal_code: "60913",
    full_city_name: "Bonfield, IL",
    city_name: "Bonfield",
  },
  {
    postal_code: "38486",
    full_city_name: "Westpoint, TN",
    city_name: "Westpoint",
  },
  {
    postal_code: "68050",
    full_city_name: "Prague, NE",
    city_name: "Prague",
  },
  {
    postal_code: "72038",
    full_city_name: "Crocketts Bluff, AR",
    city_name: "Crocketts Bluff",
  },
  {
    postal_code: "28423",
    full_city_name: "Bolton, NC",
    city_name: "Bolton",
  },
  {
    postal_code: "80025",
    full_city_name: "Eldorado Springs, CO",
    city_name: "Eldorado Springs",
  },
  {
    postal_code: "45890",
    full_city_name: "Vanlue, OH",
    city_name: "Vanlue",
  },
  {
    postal_code: "17030",
    full_city_name: "Gratz, PA",
    city_name: "Gratz",
  },
  {
    postal_code: "49126",
    full_city_name: "Sodus, MI",
    city_name: "Sodus",
  },
  {
    postal_code: "56579",
    full_city_name: "Rothsay, MN",
    city_name: "Rothsay",
  },
  {
    postal_code: "61333",
    full_city_name: "Long Point, IL",
    city_name: "Long Point",
  },
  {
    postal_code: "57329",
    full_city_name: "Dante, SD",
    city_name: "Dante",
  },
  {
    postal_code: "18915",
    full_city_name: "Colmar, PA",
    city_name: "Colmar",
  },
  {
    postal_code: "03817",
    full_city_name: "Chocorua, NH",
    city_name: "Chocorua",
  },
  {
    postal_code: "40009",
    full_city_name: "Bradfordsville, KY",
    city_name: "Bradfordsville",
  },
  {
    postal_code: "52220",
    full_city_name: "Conroy, IA",
    city_name: "Conroy",
  },
  {
    postal_code: "21628",
    full_city_name: "Crumpton, MD",
    city_name: "Crumpton",
  },
  {
    postal_code: "54625",
    full_city_name: "Dodge, WI",
    city_name: "Dodge",
  },
  {
    postal_code: "67492",
    full_city_name: "Woodbine, KS",
    city_name: "Woodbine",
  },
  {
    postal_code: "83602",
    full_city_name: "Banks, ID",
    city_name: "Banks",
  },
  {
    postal_code: "78039",
    full_city_name: "La Coste, TX",
    city_name: "La Coste",
  },
  {
    postal_code: "56371",
    full_city_name: "Roscoe, MN",
    city_name: "Roscoe",
  },
  {
    postal_code: "16313",
    full_city_name: "Clarendon, PA",
    city_name: "Clarendon",
  },
  {
    postal_code: "27857",
    full_city_name: "Oak City, NC",
    city_name: "Oak City",
  },
  {
    postal_code: "43840",
    full_city_name: "Stone Creek, OH",
    city_name: "Stone Creek",
  },
  {
    postal_code: "21790",
    full_city_name: "Tuscarora, MD",
    city_name: "Tuscarora",
  },
  {
    postal_code: "53550",
    full_city_name: "Juda, WI",
    city_name: "Juda",
  },
  {
    postal_code: "64650",
    full_city_name: "Kingston, MO",
    city_name: "Kingston",
  },
  {
    postal_code: "43535",
    full_city_name: "Malinta, OH",
    city_name: "Malinta",
  },
  {
    postal_code: "19731",
    full_city_name: "Port Penn, DE",
    city_name: "Port Penn",
  },
  {
    postal_code: "93650",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "79788",
    full_city_name: "Wickett, TX",
    city_name: "Wickett",
  },
  {
    postal_code: "47161",
    full_city_name: "New Salisbury, IN",
    city_name: "New Salisbury",
  },
  {
    postal_code: "17371",
    full_city_name: "York New Salem, PA",
    city_name: "York New Salem",
  },
  {
    postal_code: "65244",
    full_city_name: "Clifton Hill, MO",
    city_name: "Clifton Hill",
  },
  {
    postal_code: "80749",
    full_city_name: "Sedgwick, CO",
    city_name: "Sedgwick",
  },
  {
    postal_code: "31630",
    full_city_name: "Du Pont, GA",
    city_name: "Du Pont",
  },
  {
    postal_code: "57337",
    full_city_name: "Fedora, SD",
    city_name: "Fedora",
  },
  {
    postal_code: "21636",
    full_city_name: "Goldsboro, MD",
    city_name: "Goldsboro",
  },
  {
    postal_code: "68414",
    full_city_name: "Nemaha, NE",
    city_name: "Nemaha",
  },
  {
    postal_code: "47986",
    full_city_name: "Templeton, IN",
    city_name: "Templeton",
  },
  {
    postal_code: "60129",
    full_city_name: "Esmond, IL",
    city_name: "Esmond",
  },
  {
    postal_code: "26327",
    full_city_name: "Berea, WV",
    city_name: "Berea",
  },
  {
    postal_code: "68068",
    full_city_name: "Washington, NE",
    city_name: "Washington",
  },
  {
    postal_code: "43458",
    full_city_name: "Rocky Ridge, OH",
    city_name: "Rocky Ridge",
  },
  {
    postal_code: "72934",
    full_city_name: "Chester, AR",
    city_name: "Chester",
  },
  {
    postal_code: "22711",
    full_city_name: "Banco, VA",
    city_name: "Banco",
  },
  {
    postal_code: "72430",
    full_city_name: "Greenway, AR",
    city_name: "Greenway",
  },
  {
    postal_code: "15565",
    full_city_name: "West Salisbury, PA",
    city_name: "West Salisbury",
  },
  {
    postal_code: "53544",
    full_city_name: "Hollandale, WI",
    city_name: "Hollandale",
  },
  {
    postal_code: "15634",
    full_city_name: "Grapeville, PA",
    city_name: "Grapeville",
  },
  {
    postal_code: "03464",
    full_city_name: "Stoddard, NH",
    city_name: "Stoddard",
  },
  {
    postal_code: "27872",
    full_city_name: "Roxobel, NC",
    city_name: "Roxobel",
  },
  {
    postal_code: "62633",
    full_city_name: "Easton, IL",
    city_name: "Easton",
  },
  {
    postal_code: "17077",
    full_city_name: "Ono, PA",
    city_name: "Ono",
  },
  {
    postal_code: "12495",
    full_city_name: "Willow, NY",
    city_name: "Willow",
  },
  {
    postal_code: "68058",
    full_city_name: "South Bend, NE",
    city_name: "South Bend",
  },
  {
    postal_code: "64440",
    full_city_name: "De Kalb, MO",
    city_name: "De Kalb",
  },
  {
    postal_code: "57585",
    full_city_name: "Wood, SD",
    city_name: "Wood",
  },
  {
    postal_code: "43457",
    full_city_name: "Risingsun, OH",
    city_name: "Risingsun",
  },
  {
    postal_code: "12819",
    full_city_name: "Clemons, NY",
    city_name: "Clemons",
  },
  {
    postal_code: "74546",
    full_city_name: "Haileyville, OK",
    city_name: "Haileyville",
  },
  {
    postal_code: "17247",
    full_city_name: "Quincy, PA",
    city_name: "Quincy",
  },
  {
    postal_code: "93962",
    full_city_name: "Spreckels, CA",
    city_name: "Spreckels",
  },
  {
    postal_code: "54766",
    full_city_name: "Sheldon, WI",
    city_name: "Sheldon",
  },
  {
    postal_code: "24872",
    full_city_name: "Panther, WV",
    city_name: "Panther",
  },
  {
    postal_code: "59341",
    full_city_name: "Mildred, MT",
    city_name: "Mildred",
  },
  {
    postal_code: "58367",
    full_city_name: "Rolla, ND",
    city_name: "Rolla",
  },
  {
    postal_code: "45372",
    full_city_name: "Tremont City, OH",
    city_name: "Tremont City",
  },
  {
    postal_code: "84031",
    full_city_name: "Hanna, UT",
    city_name: "Hanna",
  },
  {
    postal_code: "56644",
    full_city_name: "Gonvick, MN",
    city_name: "Gonvick",
  },
  {
    postal_code: "47244",
    full_city_name: "Hartsville, IN",
    city_name: "Hartsville",
  },
  {
    postal_code: "13321",
    full_city_name: "Clark Mills, NY",
    city_name: "Clark Mills",
  },
  {
    postal_code: "55751",
    full_city_name: "Iron, MN",
    city_name: "Iron",
  },
  {
    postal_code: "81041",
    full_city_name: "Granada, CO",
    city_name: "Granada",
  },
  {
    postal_code: "62998",
    full_city_name: "Wolf Lake, IL",
    city_name: "Wolf Lake",
  },
  {
    postal_code: "25030",
    full_city_name: "Bomont, WV",
    city_name: "Bomont",
  },
  {
    postal_code: "40316",
    full_city_name: "Denniston, KY",
    city_name: "Denniston",
  },
  {
    postal_code: "83450",
    full_city_name: "Terreton, ID",
    city_name: "Terreton",
  },
  {
    postal_code: "63955",
    full_city_name: "Oxly, MO",
    city_name: "Oxly",
  },
  {
    postal_code: "64146",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "96129",
    full_city_name: "Beckwourth, CA",
    city_name: "Beckwourth",
  },
  {
    postal_code: "98560",
    full_city_name: "Matlock, WA",
    city_name: "Matlock",
  },
  {
    postal_code: "27919",
    full_city_name: "Belvidere, NC",
    city_name: "Belvidere",
  },
  {
    postal_code: "54123",
    full_city_name: "Forest Junction, WI",
    city_name: "Forest Junction",
  },
  {
    postal_code: "53825",
    full_city_name: "Stitzer, WI",
    city_name: "Stitzer",
  },
  {
    postal_code: "67154",
    full_city_name: "Whitewater, KS",
    city_name: "Whitewater",
  },
  {
    postal_code: "03443",
    full_city_name: "Chesterfield, NH",
    city_name: "Chesterfield",
  },
  {
    postal_code: "19477",
    full_city_name: "Spring House, PA",
    city_name: "Spring House",
  },
  {
    postal_code: "04003",
    full_city_name: "Bailey Island, ME",
    city_name: "Bailey Island",
  },
  {
    postal_code: "12120",
    full_city_name: "Medusa, NY",
    city_name: "Medusa",
  },
  {
    postal_code: "55385",
    full_city_name: "Stewart, MN",
    city_name: "Stewart",
  },
  {
    postal_code: "62531",
    full_city_name: "Edinburg, IL",
    city_name: "Edinburg",
  },
  {
    postal_code: "48437",
    full_city_name: "Genesee, MI",
    city_name: "Genesee",
  },
  {
    postal_code: "73860",
    full_city_name: "Waynoka, OK",
    city_name: "Waynoka",
  },
  {
    postal_code: "62927",
    full_city_name: "Dowell, IL",
    city_name: "Dowell",
  },
  {
    postal_code: "71358",
    full_city_name: "Palmetto, LA",
    city_name: "Palmetto",
  },
  {
    postal_code: "53034",
    full_city_name: "Hustisford, WI",
    city_name: "Hustisford",
  },
  {
    postal_code: "73841",
    full_city_name: "Fort Supply, OK",
    city_name: "Fort Supply",
  },
  {
    postal_code: "92321",
    full_city_name: "Cedar Glen, CA",
    city_name: "Cedar Glen",
  },
  {
    postal_code: "26253",
    full_city_name: "Beverly, WV",
    city_name: "Beverly",
  },
  {
    postal_code: "35444",
    full_city_name: "Brookwood, AL",
    city_name: "Brookwood",
  },
  {
    postal_code: "96818",
    full_city_name: "Honolulu, HI",
    city_name: "Honolulu",
  },
  {
    postal_code: "32433",
    full_city_name: "Defuniak Springs, FL",
    city_name: "Defuniak Springs",
  },
  {
    postal_code: "35758",
    full_city_name: "Madison, AL",
    city_name: "Madison",
  },
  {
    postal_code: "53405",
    full_city_name: "Racine, WI",
    city_name: "Racine",
  },
  {
    postal_code: "23464",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "10925",
    full_city_name: "Greenwood Lake, NY",
    city_name: "Greenwood Lake",
  },
  {
    postal_code: "49648",
    full_city_name: "Kewadin, MI",
    city_name: "Kewadin",
  },
  {
    postal_code: "49406",
    full_city_name: "Douglas, MI",
    city_name: "Douglas",
  },
  {
    postal_code: "19950",
    full_city_name: "Greenwood, DE",
    city_name: "Greenwood",
  },
  {
    postal_code: "89301",
    full_city_name: "Ely, NV",
    city_name: "Ely",
  },
  {
    postal_code: "49736",
    full_city_name: "Goetzville, MI",
    city_name: "Goetzville",
  },
  {
    postal_code: "29169",
    full_city_name: "West Columbia, SC",
    city_name: "West Columbia",
  },
  {
    postal_code: "10590",
    full_city_name: "South Salem, NY",
    city_name: "South Salem",
  },
  {
    postal_code: "18088",
    full_city_name: "Walnutport, PA",
    city_name: "Walnutport",
  },
  {
    postal_code: "83839",
    full_city_name: "Kingston, ID",
    city_name: "Kingston",
  },
  {
    postal_code: "24053",
    full_city_name: "Ararat, VA",
    city_name: "Ararat",
  },
  {
    postal_code: "97037",
    full_city_name: "Maupin, OR",
    city_name: "Maupin",
  },
  {
    postal_code: "72947",
    full_city_name: "Mulberry, AR",
    city_name: "Mulberry",
  },
  {
    postal_code: "17098",
    full_city_name: "Williamstown, PA",
    city_name: "Williamstown",
  },
  {
    postal_code: "23432",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "23072",
    full_city_name: "Hayes, VA",
    city_name: "Hayes",
  },
  {
    postal_code: "39345",
    full_city_name: "Newton, MS",
    city_name: "Newton",
  },
  {
    postal_code: "16371",
    full_city_name: "Youngsville, PA",
    city_name: "Youngsville",
  },
  {
    postal_code: "77701",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "38751",
    full_city_name: "Indianola, MS",
    city_name: "Indianola",
  },
  {
    postal_code: "35978",
    full_city_name: "Henagar, AL",
    city_name: "Henagar",
  },
  {
    postal_code: "19330",
    full_city_name: "Cochranville, PA",
    city_name: "Cochranville",
  },
  {
    postal_code: "54542",
    full_city_name: "Long Lake, WI",
    city_name: "Long Lake",
  },
  {
    postal_code: "41168",
    full_city_name: "Rush, KY",
    city_name: "Rush",
  },
  {
    postal_code: "94130",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "39203",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "53114",
    full_city_name: "Darien, WI",
    city_name: "Darien",
  },
  {
    postal_code: "75972",
    full_city_name: "San Augustine, TX",
    city_name: "San Augustine",
  },
  {
    postal_code: "49690",
    full_city_name: "Williamsburg, MI",
    city_name: "Williamsburg",
  },
  {
    postal_code: "32580",
    full_city_name: "Valparaiso, FL",
    city_name: "Valparaiso",
  },
  {
    postal_code: "74331",
    full_city_name: "Afton, OK",
    city_name: "Afton",
  },
  {
    postal_code: "67214",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "18240",
    full_city_name: "Nesquehoning, PA",
    city_name: "Nesquehoning",
  },
  {
    postal_code: "47454",
    full_city_name: "Paoli, IN",
    city_name: "Paoli",
  },
  {
    postal_code: "49622",
    full_city_name: "Central Lake, MI",
    city_name: "Central Lake",
  },
  {
    postal_code: "35959",
    full_city_name: "Cedar Bluff, AL",
    city_name: "Cedar Bluff",
  },
  {
    postal_code: "53072",
    full_city_name: "Pewaukee, WI",
    city_name: "Pewaukee",
  },
  {
    postal_code: "08872",
    full_city_name: "Sayreville, NJ",
    city_name: "Sayreville",
  },
  {
    postal_code: "08629",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "53066",
    full_city_name: "Oconomowoc, WI",
    city_name: "Oconomowoc",
  },
  {
    postal_code: "51334",
    full_city_name: "Estherville, IA",
    city_name: "Estherville",
  },
  {
    postal_code: "23703",
    full_city_name: "Portsmouth, VA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "35983",
    full_city_name: "Leesburg, AL",
    city_name: "Leesburg",
  },
  {
    postal_code: "78582",
    full_city_name: "Rio Grande City, TX",
    city_name: "Rio Grande City",
  },
  {
    postal_code: "07421",
    full_city_name: "Hewitt, NJ",
    city_name: "Hewitt",
  },
  {
    postal_code: "10705",
    full_city_name: "Yonkers, NY",
    city_name: "Yonkers",
  },
  {
    postal_code: "31036",
    full_city_name: "Hawkinsville, GA",
    city_name: "Hawkinsville",
  },
  {
    postal_code: "99503",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "54209",
    full_city_name: "Egg Harbor, WI",
    city_name: "Egg Harbor",
  },
  {
    postal_code: "29070",
    full_city_name: "Leesville, SC",
    city_name: "Leesville",
  },
  {
    postal_code: "04609",
    full_city_name: "Bar Harbor, ME",
    city_name: "Bar Harbor",
  },
  {
    postal_code: "36703",
    full_city_name: "Selma, AL",
    city_name: "Selma",
  },
  {
    postal_code: "07924",
    full_city_name: "Bernardsville, NJ",
    city_name: "Bernardsville",
  },
  {
    postal_code: "95501",
    full_city_name: "Eureka, CA",
    city_name: "Eureka",
  },
  {
    postal_code: "53184",
    full_city_name: "Walworth, WI",
    city_name: "Walworth",
  },
  {
    postal_code: "07719",
    full_city_name: "Belmar, NJ",
    city_name: "Belmar",
  },
  {
    postal_code: "29512",
    full_city_name: "Bennettsville, SC",
    city_name: "Bennettsville",
  },
  {
    postal_code: "07059",
    full_city_name: "Warren, NJ",
    city_name: "Warren",
  },
  {
    postal_code: "49635",
    full_city_name: "Frankfort, MI",
    city_name: "Frankfort",
  },
  {
    postal_code: "46807",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "35759",
    full_city_name: "Meridianville, AL",
    city_name: "Meridianville",
  },
  {
    postal_code: "19076",
    full_city_name: "Prospect Park, PA",
    city_name: "Prospect Park",
  },
  {
    postal_code: "07057",
    full_city_name: "Wallington, NJ",
    city_name: "Wallington",
  },
  {
    postal_code: "31201",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "07676",
    full_city_name: "Township Of Washington, NJ",
    city_name: "Township Of Washington",
  },
  {
    postal_code: "92036",
    full_city_name: "Julian, CA",
    city_name: "Julian",
  },
  {
    postal_code: "36521",
    full_city_name: "Chunchula, AL",
    city_name: "Chunchula",
  },
  {
    postal_code: "02535",
    full_city_name: "Chilmark, MA",
    city_name: "Chilmark",
  },
  {
    postal_code: "46929",
    full_city_name: "Flora, IN",
    city_name: "Flora",
  },
  {
    postal_code: "07976",
    full_city_name: "New Vernon, NJ",
    city_name: "New Vernon",
  },
  {
    postal_code: "19510",
    full_city_name: "Blandon, PA",
    city_name: "Blandon",
  },
  {
    postal_code: "46538",
    full_city_name: "Leesburg, IN",
    city_name: "Leesburg",
  },
  {
    postal_code: "97918",
    full_city_name: "Vale, OR",
    city_name: "Vale",
  },
  {
    postal_code: "08562",
    full_city_name: "Wrightstown, NJ",
    city_name: "Wrightstown",
  },
  {
    postal_code: "18040",
    full_city_name: "Easton, PA",
    city_name: "Easton",
  },
  {
    postal_code: "35986",
    full_city_name: "Rainsville, AL",
    city_name: "Rainsville",
  },
  {
    postal_code: "38732",
    full_city_name: "Cleveland, MS",
    city_name: "Cleveland",
  },
  {
    postal_code: "31719",
    full_city_name: "Americus, GA",
    city_name: "Americus",
  },
  {
    postal_code: "70722",
    full_city_name: "Clinton, LA",
    city_name: "Clinton",
  },
  {
    postal_code: "78538",
    full_city_name: "Edcouch, TX",
    city_name: "Edcouch",
  },
  {
    postal_code: "18255",
    full_city_name: "Weatherly, PA",
    city_name: "Weatherly",
  },
  {
    postal_code: "23883",
    full_city_name: "Surry, VA",
    city_name: "Surry",
  },
  {
    postal_code: "35757",
    full_city_name: "Madison, AL",
    city_name: "Madison",
  },
  {
    postal_code: "50461",
    full_city_name: "Osage, IA",
    city_name: "Osage",
  },
  {
    postal_code: "49712",
    full_city_name: "Boyne City, MI",
    city_name: "Boyne City",
  },
  {
    postal_code: "72834",
    full_city_name: "Dardanelle, AR",
    city_name: "Dardanelle",
  },
  {
    postal_code: "02493",
    full_city_name: "Weston, MA",
    city_name: "Weston",
  },
  {
    postal_code: "99611",
    full_city_name: "Kenai, AK",
    city_name: "Kenai",
  },
  {
    postal_code: "46738",
    full_city_name: "Garrett, IN",
    city_name: "Garrett",
  },
  {
    postal_code: "01072",
    full_city_name: "Shutesbury, MA",
    city_name: "Shutesbury",
  },
  {
    postal_code: "18014",
    full_city_name: "Bath, PA",
    city_name: "Bath",
  },
  {
    postal_code: "53146",
    full_city_name: "New Berlin, WI",
    city_name: "New Berlin",
  },
  {
    postal_code: "19310",
    full_city_name: "Atglen, PA",
    city_name: "Atglen",
  },
  {
    postal_code: "02557",
    full_city_name: "Oak Bluffs, MA",
    city_name: "Oak Bluffs",
  },
  {
    postal_code: "14228",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "19012",
    full_city_name: "Cheltenham, PA",
    city_name: "Cheltenham",
  },
  {
    postal_code: "49287",
    full_city_name: "Tipton, MI",
    city_name: "Tipton",
  },
  {
    postal_code: "47346",
    full_city_name: "Hagerstown, IN",
    city_name: "Hagerstown",
  },
  {
    postal_code: "29709",
    full_city_name: "Chesterfield, SC",
    city_name: "Chesterfield",
  },
  {
    postal_code: "17068",
    full_city_name: "New Bloomfield, PA",
    city_name: "New Bloomfield",
  },
  {
    postal_code: "34139",
    full_city_name: "Everglades City, FL",
    city_name: "Everglades City",
  },
  {
    postal_code: "87549",
    full_city_name: "Ojo Caliente, NM",
    city_name: "Ojo Caliente",
  },
  {
    postal_code: "75683",
    full_city_name: "Ore City, TX",
    city_name: "Ore City",
  },
  {
    postal_code: "75639",
    full_city_name: "De Berry, TX",
    city_name: "De Berry",
  },
  {
    postal_code: "17339",
    full_city_name: "Lewisberry, PA",
    city_name: "Lewisberry",
  },
  {
    postal_code: "83442",
    full_city_name: "Rigby, ID",
    city_name: "Rigby",
  },
  {
    postal_code: "35672",
    full_city_name: "Town Creek, AL",
    city_name: "Town Creek",
  },
  {
    postal_code: "71052",
    full_city_name: "Mansfield, LA",
    city_name: "Mansfield",
  },
  {
    postal_code: "46805",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "04406",
    full_city_name: "Abbot, ME",
    city_name: "Abbot",
  },
  {
    postal_code: "53118",
    full_city_name: "Dousman, WI",
    city_name: "Dousman",
  },
  {
    postal_code: "68069",
    full_city_name: "Waterloo, NE",
    city_name: "Waterloo",
  },
  {
    postal_code: "73134",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "53050",
    full_city_name: "Mayville, WI",
    city_name: "Mayville",
  },
  {
    postal_code: "01770",
    full_city_name: "Sherborn, MA",
    city_name: "Sherborn",
  },
  {
    postal_code: "42713",
    full_city_name: "Bonnieville, KY",
    city_name: "Bonnieville",
  },
  {
    postal_code: "18651",
    full_city_name: "Plymouth, PA",
    city_name: "Plymouth",
  },
  {
    postal_code: "07102",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "57212",
    full_city_name: "Arlington, SD",
    city_name: "Arlington",
  },
  {
    postal_code: "96057",
    full_city_name: "Mccloud, CA",
    city_name: "Mccloud",
  },
  {
    postal_code: "04579",
    full_city_name: "Woolwich, ME",
    city_name: "Woolwich",
  },
  {
    postal_code: "21861",
    full_city_name: "Sharptown, MD",
    city_name: "Sharptown",
  },
  {
    postal_code: "04487",
    full_city_name: "Springfield, ME",
    city_name: "Springfield",
  },
  {
    postal_code: "57105",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "19565",
    full_city_name: "Wernersville, PA",
    city_name: "Wernersville",
  },
  {
    postal_code: "25951",
    full_city_name: "Hinton, WV",
    city_name: "Hinton",
  },
  {
    postal_code: "73129",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "46507",
    full_city_name: "Bristol, IN",
    city_name: "Bristol",
  },
  {
    postal_code: "53126",
    full_city_name: "Franksville, WI",
    city_name: "Franksville",
  },
  {
    postal_code: "99518",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "07865",
    full_city_name: "Port Murray, NJ",
    city_name: "Port Murray",
  },
  {
    postal_code: "49727",
    full_city_name: "East Jordan, MI",
    city_name: "East Jordan",
  },
  {
    postal_code: "48421",
    full_city_name: "Columbiaville, MI",
    city_name: "Columbiaville",
  },
  {
    postal_code: "82901",
    full_city_name: "Rock Springs, WY",
    city_name: "Rock Springs",
  },
  {
    postal_code: "07047",
    full_city_name: "North Bergen, NJ",
    city_name: "North Bergen",
  },
  {
    postal_code: "99705",
    full_city_name: "North Pole, AK",
    city_name: "North Pole",
  },
  {
    postal_code: "05843",
    full_city_name: "Hardwick, VT",
    city_name: "Hardwick",
  },
  {
    postal_code: "07856",
    full_city_name: "Mount Arlington, NJ",
    city_name: "Mount Arlington",
  },
  {
    postal_code: "23510",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "29332",
    full_city_name: "Cross Hill, SC",
    city_name: "Cross Hill",
  },
  {
    postal_code: "89040",
    full_city_name: "Overton, NV",
    city_name: "Overton",
  },
  {
    postal_code: "99833",
    full_city_name: "Petersburg, AK",
    city_name: "Petersburg",
  },
  {
    postal_code: "83672",
    full_city_name: "Weiser, ID",
    city_name: "Weiser",
  },
  {
    postal_code: "67701",
    full_city_name: "Colby, KS",
    city_name: "Colby",
  },
  {
    postal_code: "78598",
    full_city_name: "Port Mansfield, TX",
    city_name: "Port Mansfield",
  },
  {
    postal_code: "10549",
    full_city_name: "Mount Kisco, NY",
    city_name: "Mount Kisco",
  },
  {
    postal_code: "92070",
    full_city_name: "Santa Ysabel, CA",
    city_name: "Santa Ysabel",
  },
  {
    postal_code: "05146",
    full_city_name: "Grafton, VT",
    city_name: "Grafton",
  },
  {
    postal_code: "70645",
    full_city_name: "Hackberry, LA",
    city_name: "Hackberry",
  },
  {
    postal_code: "23336",
    full_city_name: "Chincoteague Island, VA",
    city_name: "Chincoteague Island",
  },
  {
    postal_code: "89021",
    full_city_name: "Logandale, NV",
    city_name: "Logandale",
  },
  {
    postal_code: "07857",
    full_city_name: "Netcong, NJ",
    city_name: "Netcong",
  },
  {
    postal_code: "08063",
    full_city_name: "National Park, NJ",
    city_name: "National Park",
  },
  {
    postal_code: "81647",
    full_city_name: "New Castle, CO",
    city_name: "New Castle",
  },
  {
    postal_code: "75657",
    full_city_name: "Jefferson, TX",
    city_name: "Jefferson",
  },
  {
    postal_code: "10968",
    full_city_name: "Piermont, NY",
    city_name: "Piermont",
  },
  {
    postal_code: "97827",
    full_city_name: "Elgin, OR",
    city_name: "Elgin",
  },
  {
    postal_code: "29128",
    full_city_name: "Rembert, SC",
    city_name: "Rembert",
  },
  {
    postal_code: "83213",
    full_city_name: "Arco, ID",
    city_name: "Arco",
  },
  {
    postal_code: "79088",
    full_city_name: "Tulia, TX",
    city_name: "Tulia",
  },
  {
    postal_code: "08826",
    full_city_name: "Glen Gardner, NJ",
    city_name: "Glen Gardner",
  },
  {
    postal_code: "17058",
    full_city_name: "Mifflin, PA",
    city_name: "Mifflin",
  },
  {
    postal_code: "07821",
    full_city_name: "Andover, NJ",
    city_name: "Andover",
  },
  {
    postal_code: "07026",
    full_city_name: "Garfield, NJ",
    city_name: "Garfield",
  },
  {
    postal_code: "07630",
    full_city_name: "Emerson, NJ",
    city_name: "Emerson",
  },
  {
    postal_code: "83328",
    full_city_name: "Filer, ID",
    city_name: "Filer",
  },
  {
    postal_code: "07720",
    full_city_name: "Bradley Beach, NJ",
    city_name: "Bradley Beach",
  },
  {
    postal_code: "36607",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "72513",
    full_city_name: "Ash Flat, AR",
    city_name: "Ash Flat",
  },
  {
    postal_code: "35042",
    full_city_name: "Centreville, AL",
    city_name: "Centreville",
  },
  {
    postal_code: "36049",
    full_city_name: "Luverne, AL",
    city_name: "Luverne",
  },
  {
    postal_code: "29003",
    full_city_name: "Bamberg, SC",
    city_name: "Bamberg",
  },
  {
    postal_code: "07050",
    full_city_name: "Orange, NJ",
    city_name: "Orange",
  },
  {
    postal_code: "83825",
    full_city_name: "Dover, ID",
    city_name: "Dover",
  },
  {
    postal_code: "07023",
    full_city_name: "Fanwood, NJ",
    city_name: "Fanwood",
  },
  {
    postal_code: "36420",
    full_city_name: "Andalusia, AL",
    city_name: "Andalusia",
  },
  {
    postal_code: "78045",
    full_city_name: "Laredo, TX",
    city_name: "Laredo",
  },
  {
    postal_code: "46792",
    full_city_name: "Warren, IN",
    city_name: "Warren",
  },
  {
    postal_code: "07605",
    full_city_name: "Leonia, NJ",
    city_name: "Leonia",
  },
  {
    postal_code: "97369",
    full_city_name: "Otter Rock, OR",
    city_name: "Otter Rock",
  },
  {
    postal_code: "07044",
    full_city_name: "Verona, NJ",
    city_name: "Verona",
  },
  {
    postal_code: "54473",
    full_city_name: "Rosholt, WI",
    city_name: "Rosholt",
  },
  {
    postal_code: "24445",
    full_city_name: "Hot Springs, VA",
    city_name: "Hot Springs",
  },
  {
    postal_code: "13476",
    full_city_name: "Vernon, NY",
    city_name: "Vernon",
  },
  {
    postal_code: "35749",
    full_city_name: "Harvest, AL",
    city_name: "Harvest",
  },
  {
    postal_code: "76309",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "07110",
    full_city_name: "Nutley, NJ",
    city_name: "Nutley",
  },
  {
    postal_code: "07030",
    full_city_name: "Hoboken, NJ",
    city_name: "Hoboken",
  },
  {
    postal_code: "53220",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "96091",
    full_city_name: "Trinity Center, CA",
    city_name: "Trinity Center",
  },
  {
    postal_code: "10927",
    full_city_name: "Haverstraw, NY",
    city_name: "Haverstraw",
  },
  {
    postal_code: "07043",
    full_city_name: "Montclair, NJ",
    city_name: "Montclair",
  },
  {
    postal_code: "36278",
    full_city_name: "Wedowee, AL",
    city_name: "Wedowee",
  },
  {
    postal_code: "89131",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "18069",
    full_city_name: "Orefield, PA",
    city_name: "Orefield",
  },
  {
    postal_code: "08752",
    full_city_name: "Seaside Park, NJ",
    city_name: "Seaside Park",
  },
  {
    postal_code: "49682",
    full_city_name: "Suttons Bay, MI",
    city_name: "Suttons Bay",
  },
  {
    postal_code: "53151",
    full_city_name: "New Berlin, WI",
    city_name: "New Berlin",
  },
  {
    postal_code: "07302",
    full_city_name: "Jersey City, NJ",
    city_name: "Jersey City",
  },
  {
    postal_code: "53406",
    full_city_name: "Racine, WI",
    city_name: "Racine",
  },
  {
    postal_code: "99507",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "23507",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "71801",
    full_city_name: "Hope, AR",
    city_name: "Hope",
  },
  {
    postal_code: "99501",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "49636",
    full_city_name: "Glen Arbor, MI",
    city_name: "Glen Arbor",
  },
  {
    postal_code: "12758",
    full_city_name: "Livingston Manor, NY",
    city_name: "Livingston Manor",
  },
  {
    postal_code: "17931",
    full_city_name: "Frackville, PA",
    city_name: "Frackville",
  },
  {
    postal_code: "17053",
    full_city_name: "Marysville, PA",
    city_name: "Marysville",
  },
  {
    postal_code: "03810",
    full_city_name: "Alton Bay, NH",
    city_name: "Alton Bay",
  },
  {
    postal_code: "39819",
    full_city_name: "Bainbridge, GA",
    city_name: "Bainbridge",
  },
  {
    postal_code: "19085",
    full_city_name: "Villanova, PA",
    city_name: "Villanova",
  },
  {
    postal_code: "29209",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "23320",
    full_city_name: "Chesapeake, VA",
    city_name: "Chesapeake",
  },
  {
    postal_code: "31763",
    full_city_name: "Leesburg, GA",
    city_name: "Leesburg",
  },
  {
    postal_code: "17566",
    full_city_name: "Quarryville, PA",
    city_name: "Quarryville",
  },
  {
    postal_code: "07010",
    full_city_name: "Cliffside Park, NJ",
    city_name: "Cliffside Park",
  },
  {
    postal_code: "07930",
    full_city_name: "Chester, NJ",
    city_name: "Chester",
  },
  {
    postal_code: "32561",
    full_city_name: "Gulf Breeze, FL",
    city_name: "Gulf Breeze",
  },
  {
    postal_code: "35541",
    full_city_name: "Arley, AL",
    city_name: "Arley",
  },
  {
    postal_code: "07205",
    full_city_name: "Hillside, NJ",
    city_name: "Hillside",
  },
  {
    postal_code: "30292",
    full_city_name: "Williamson, GA",
    city_name: "Williamson",
  },
  {
    postal_code: "21863",
    full_city_name: "Snow Hill, MD",
    city_name: "Snow Hill",
  },
  {
    postal_code: "62535",
    full_city_name: "Forsyth, IL",
    city_name: "Forsyth",
  },
  {
    postal_code: "43438",
    full_city_name: "Kelleys Island, OH",
    city_name: "Kelleys Island",
  },
  {
    postal_code: "67005",
    full_city_name: "Arkansas City, KS",
    city_name: "Arkansas City",
  },
  {
    postal_code: "68801",
    full_city_name: "Grand Island, NE",
    city_name: "Grand Island",
  },
  {
    postal_code: "24380",
    full_city_name: "Willis, VA",
    city_name: "Willis",
  },
  {
    postal_code: "24614",
    full_city_name: "Grundy, VA",
    city_name: "Grundy",
  },
  {
    postal_code: "70354",
    full_city_name: "Galliano, LA",
    city_name: "Galliano",
  },
  {
    postal_code: "46506",
    full_city_name: "Bremen, IN",
    city_name: "Bremen",
  },
  {
    postal_code: "49616",
    full_city_name: "Benzonia, MI",
    city_name: "Benzonia",
  },
  {
    postal_code: "62549",
    full_city_name: "Mt Zion, IL",
    city_name: "Mt Zion",
  },
  {
    postal_code: "82609",
    full_city_name: "Casper, WY",
    city_name: "Casper",
  },
  {
    postal_code: "32502",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "10591",
    full_city_name: "Tarrytown, NY",
    city_name: "Tarrytown",
  },
  {
    postal_code: "13617",
    full_city_name: "Canton, NY",
    city_name: "Canton",
  },
  {
    postal_code: "73139",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "07439",
    full_city_name: "Ogdensburg, NJ",
    city_name: "Ogdensburg",
  },
  {
    postal_code: "49333",
    full_city_name: "Middleville, MI",
    city_name: "Middleville",
  },
  {
    postal_code: "42045",
    full_city_name: "Grand Rivers, KY",
    city_name: "Grand Rivers",
  },
  {
    postal_code: "75661",
    full_city_name: "Karnack, TX",
    city_name: "Karnack",
  },
  {
    postal_code: "77632",
    full_city_name: "Orange, TX",
    city_name: "Orange",
  },
  {
    postal_code: "16920",
    full_city_name: "Elkland, PA",
    city_name: "Elkland",
  },
  {
    postal_code: "10560",
    full_city_name: "North Salem, NY",
    city_name: "North Salem",
  },
  {
    postal_code: "56264",
    full_city_name: "Minneota, MN",
    city_name: "Minneota",
  },
  {
    postal_code: "97540",
    full_city_name: "Talent, OR",
    city_name: "Talent",
  },
  {
    postal_code: "73119",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "08066",
    full_city_name: "Paulsboro, NJ",
    city_name: "Paulsboro",
  },
  {
    postal_code: "57004",
    full_city_name: "Beresford, SD",
    city_name: "Beresford",
  },
  {
    postal_code: "72650",
    full_city_name: "Marshall, AR",
    city_name: "Marshall",
  },
  {
    postal_code: "04289",
    full_city_name: "West Paris, ME",
    city_name: "West Paris",
  },
  {
    postal_code: "32667",
    full_city_name: "Micanopy, FL",
    city_name: "Micanopy",
  },
  {
    postal_code: "95570",
    full_city_name: "Trinidad, CA",
    city_name: "Trinidad",
  },
  {
    postal_code: "10607",
    full_city_name: "White Plains, NY",
    city_name: "White Plains",
  },
  {
    postal_code: "25262",
    full_city_name: "Millwood, WV",
    city_name: "Millwood",
  },
  {
    postal_code: "54442",
    full_city_name: "Irma, WI",
    city_name: "Irma",
  },
  {
    postal_code: "83847",
    full_city_name: "Naples, ID",
    city_name: "Naples",
  },
  {
    postal_code: "95536",
    full_city_name: "Ferndale, CA",
    city_name: "Ferndale",
  },
  {
    postal_code: "46938",
    full_city_name: "Jonesboro, IN",
    city_name: "Jonesboro",
  },
  {
    postal_code: "83330",
    full_city_name: "Gooding, ID",
    city_name: "Gooding",
  },
  {
    postal_code: "14411",
    full_city_name: "Albion, NY",
    city_name: "Albion",
  },
  {
    postal_code: "19539",
    full_city_name: "Mertztown, PA",
    city_name: "Mertztown",
  },
  {
    postal_code: "07640",
    full_city_name: "Harrington Park, NJ",
    city_name: "Harrington Park",
  },
  {
    postal_code: "07850",
    full_city_name: "Landing, NJ",
    city_name: "Landing",
  },
  {
    postal_code: "71263",
    full_city_name: "Oak Grove, LA",
    city_name: "Oak Grove",
  },
  {
    postal_code: "07666",
    full_city_name: "Teaneck, NJ",
    city_name: "Teaneck",
  },
  {
    postal_code: "54486",
    full_city_name: "Tigerton, WI",
    city_name: "Tigerton",
  },
  {
    postal_code: "39854",
    full_city_name: "Georgetown, GA",
    city_name: "Georgetown",
  },
  {
    postal_code: "75487",
    full_city_name: "Talco, TX",
    city_name: "Talco",
  },
  {
    postal_code: "67576",
    full_city_name: "St John, KS",
    city_name: "St John",
  },
  {
    postal_code: "04921",
    full_city_name: "Brooks, ME",
    city_name: "Brooks",
  },
  {
    postal_code: "03227",
    full_city_name: "Center Sandwich, NH",
    city_name: "Center Sandwich",
  },
  {
    postal_code: "42058",
    full_city_name: "Ledbetter, KY",
    city_name: "Ledbetter",
  },
  {
    postal_code: "66524",
    full_city_name: "Overbrook, KS",
    city_name: "Overbrook",
  },
  {
    postal_code: "04942",
    full_city_name: "Harmony, ME",
    city_name: "Harmony",
  },
  {
    postal_code: "07201",
    full_city_name: "Elizabeth, NJ",
    city_name: "Elizabeth",
  },
  {
    postal_code: "17557",
    full_city_name: "New Holland, PA",
    city_name: "New Holland",
  },
  {
    postal_code: "49630",
    full_city_name: "Empire, MI",
    city_name: "Empire",
  },
  {
    postal_code: "53806",
    full_city_name: "Cassville, WI",
    city_name: "Cassville",
  },
  {
    postal_code: "18322",
    full_city_name: "Brodheadsville, PA",
    city_name: "Brodheadsville",
  },
  {
    postal_code: "18091",
    full_city_name: "Wind Gap, PA",
    city_name: "Wind Gap",
  },
  {
    postal_code: "72473",
    full_city_name: "Tuckerman, AR",
    city_name: "Tuckerman",
  },
  {
    postal_code: "07206",
    full_city_name: "Elizabethport, NJ",
    city_name: "Elizabethport",
  },
  {
    postal_code: "35741",
    full_city_name: "Brownsboro, AL",
    city_name: "Brownsboro",
  },
  {
    postal_code: "83221",
    full_city_name: "Blackfoot, ID",
    city_name: "Blackfoot",
  },
  {
    postal_code: "36874",
    full_city_name: "Salem, AL",
    city_name: "Salem",
  },
  {
    postal_code: "35474",
    full_city_name: "Moundville, AL",
    city_name: "Moundville",
  },
  {
    postal_code: "07004",
    full_city_name: "Fairfield, NJ",
    city_name: "Fairfield",
  },
  {
    postal_code: "16662",
    full_city_name: "Martinsburg, PA",
    city_name: "Martinsburg",
  },
  {
    postal_code: "66054",
    full_city_name: "Mc Louth, KS",
    city_name: "Mc Louth",
  },
  {
    postal_code: "53090",
    full_city_name: "West Bend, WI",
    city_name: "West Bend",
  },
  {
    postal_code: "04049",
    full_city_name: "Limington, ME",
    city_name: "Limington",
  },
  {
    postal_code: "14129",
    full_city_name: "Perrysburg, NY",
    city_name: "Perrysburg",
  },
  {
    postal_code: "07018",
    full_city_name: "East Orange, NJ",
    city_name: "East Orange",
  },
  {
    postal_code: "36305",
    full_city_name: "Dothan, AL",
    city_name: "Dothan",
  },
  {
    postal_code: "23314",
    full_city_name: "Carrollton, VA",
    city_name: "Carrollton",
  },
  {
    postal_code: "49327",
    full_city_name: "Grant, MI",
    city_name: "Grant",
  },
  {
    postal_code: "48636",
    full_city_name: "Luzerne, MI",
    city_name: "Luzerne",
  },
  {
    postal_code: "32462",
    full_city_name: "Vernon, FL",
    city_name: "Vernon",
  },
  {
    postal_code: "17003",
    full_city_name: "Annville, PA",
    city_name: "Annville",
  },
  {
    postal_code: "07450",
    full_city_name: "Ridgewood, NJ",
    city_name: "Ridgewood",
  },
  {
    postal_code: "23701",
    full_city_name: "Portsmouth, VA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "07826",
    full_city_name: "Branchville, NJ",
    city_name: "Branchville",
  },
  {
    postal_code: "99737",
    full_city_name: "Delta Junction, AK",
    city_name: "Delta Junction",
  },
  {
    postal_code: "19944",
    full_city_name: "Fenwick Island, DE",
    city_name: "Fenwick Island",
  },
  {
    postal_code: "29161",
    full_city_name: "Timmonsville, SC",
    city_name: "Timmonsville",
  },
  {
    postal_code: "31539",
    full_city_name: "Hazlehurst, GA",
    city_name: "Hazlehurst",
  },
  {
    postal_code: "71129",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "53228",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "73169",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "73028",
    full_city_name: "Crescent, OK",
    city_name: "Crescent",
  },
  {
    postal_code: "90014",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "13664",
    full_city_name: "Morristown, NY",
    city_name: "Morristown",
  },
  {
    postal_code: "99101",
    full_city_name: "Addy, WA",
    city_name: "Addy",
  },
  {
    postal_code: "17324",
    full_city_name: "Gardners, PA",
    city_name: "Gardners",
  },
  {
    postal_code: "49676",
    full_city_name: "Rapid City, MI",
    city_name: "Rapid City",
  },
  {
    postal_code: "23942",
    full_city_name: "Green Bay, VA",
    city_name: "Green Bay",
  },
  {
    postal_code: "48208",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "21077",
    full_city_name: "Harmans, MD",
    city_name: "Harmans",
  },
  {
    postal_code: "12760",
    full_city_name: "Long Eddy, NY",
    city_name: "Long Eddy",
  },
  {
    postal_code: "27020",
    full_city_name: "Hamptonville, NC",
    city_name: "Hamptonville",
  },
  {
    postal_code: "62419",
    full_city_name: "Calhoun, IL",
    city_name: "Calhoun",
  },
  {
    postal_code: "94956",
    full_city_name: "Point Reyes Station, CA",
    city_name: "Point Reyes Station",
  },
  {
    postal_code: "43446",
    full_city_name: "Middle Bass, OH",
    city_name: "Middle Bass",
  },
  {
    postal_code: "07852",
    full_city_name: "Ledgewood, NJ",
    city_name: "Ledgewood",
  },
  {
    postal_code: "02142",
    full_city_name: "Cambridge, MA",
    city_name: "Cambridge",
  },
  {
    postal_code: "88041",
    full_city_name: "Hanover, NM",
    city_name: "Hanover",
  },
  {
    postal_code: "26031",
    full_city_name: "Benwood, WV",
    city_name: "Benwood",
  },
  {
    postal_code: "78235",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "50624",
    full_city_name: "Dike, IA",
    city_name: "Dike",
  },
  {
    postal_code: "07643",
    full_city_name: "Little Ferry, NJ",
    city_name: "Little Ferry",
  },
  {
    postal_code: "21132",
    full_city_name: "Pylesville, MD",
    city_name: "Pylesville",
  },
  {
    postal_code: "12064",
    full_city_name: "East Worcester, NY",
    city_name: "East Worcester",
  },
  {
    postal_code: "14739",
    full_city_name: "Friendship, NY",
    city_name: "Friendship",
  },
  {
    postal_code: "12575",
    full_city_name: "Rock Tavern, NY",
    city_name: "Rock Tavern",
  },
  {
    postal_code: "58436",
    full_city_name: "Ellendale, ND",
    city_name: "Ellendale",
  },
  {
    postal_code: "24555",
    full_city_name: "Glasgow, VA",
    city_name: "Glasgow",
  },
  {
    postal_code: "57030",
    full_city_name: "Garretson, SD",
    city_name: "Garretson",
  },
  {
    postal_code: "67104",
    full_city_name: "Medicine Lodge, KS",
    city_name: "Medicine Lodge",
  },
  {
    postal_code: "46710",
    full_city_name: "Avilla, IN",
    city_name: "Avilla",
  },
  {
    postal_code: "71639",
    full_city_name: "Dumas, AR",
    city_name: "Dumas",
  },
  {
    postal_code: "63873",
    full_city_name: "Portageville, MO",
    city_name: "Portageville",
  },
  {
    postal_code: "46919",
    full_city_name: "Converse, IN",
    city_name: "Converse",
  },
  {
    postal_code: "51439",
    full_city_name: "Charter Oak, IA",
    city_name: "Charter Oak",
  },
  {
    postal_code: "15357",
    full_city_name: "Rices Landing, PA",
    city_name: "Rices Landing",
  },
  {
    postal_code: "66967",
    full_city_name: "Smith Center, KS",
    city_name: "Smith Center",
  },
  {
    postal_code: "53227",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "37713",
    full_city_name: "Bybee, TN",
    city_name: "Bybee",
  },
  {
    postal_code: "78954",
    full_city_name: "Round Top, TX",
    city_name: "Round Top",
  },
  {
    postal_code: "59254",
    full_city_name: "Plentywood, MT",
    city_name: "Plentywood",
  },
  {
    postal_code: "98935",
    full_city_name: "Mabton, WA",
    city_name: "Mabton",
  },
  {
    postal_code: "60935",
    full_city_name: "Essex, IL",
    city_name: "Essex",
  },
  {
    postal_code: "53020",
    full_city_name: "Elkhart Lake, WI",
    city_name: "Elkhart Lake",
  },
  {
    postal_code: "05871",
    full_city_name: "West Burke, VT",
    city_name: "West Burke",
  },
  {
    postal_code: "89043",
    full_city_name: "Pioche, NV",
    city_name: "Pioche",
  },
  {
    postal_code: "36005",
    full_city_name: "Banks, AL",
    city_name: "Banks",
  },
  {
    postal_code: "78569",
    full_city_name: "Lyford, TX",
    city_name: "Lyford",
  },
  {
    postal_code: "61410",
    full_city_name: "Abingdon, IL",
    city_name: "Abingdon",
  },
  {
    postal_code: "62017",
    full_city_name: "Coffeen, IL",
    city_name: "Coffeen",
  },
  {
    postal_code: "46763",
    full_city_name: "Laotto, IN",
    city_name: "Laotto",
  },
  {
    postal_code: "05868",
    full_city_name: "Troy, VT",
    city_name: "Troy",
  },
  {
    postal_code: "84052",
    full_city_name: "Myton, UT",
    city_name: "Myton",
  },
  {
    postal_code: "47952",
    full_city_name: "Kingman, IN",
    city_name: "Kingman",
  },
  {
    postal_code: "10920",
    full_city_name: "Congers, NY",
    city_name: "Congers",
  },
  {
    postal_code: "14805",
    full_city_name: "Alpine, NY",
    city_name: "Alpine",
  },
  {
    postal_code: "50257",
    full_city_name: "Truro, IA",
    city_name: "Truro",
  },
  {
    postal_code: "47018",
    full_city_name: "Dillsboro, IN",
    city_name: "Dillsboro",
  },
  {
    postal_code: "67654",
    full_city_name: "Norton, KS",
    city_name: "Norton",
  },
  {
    postal_code: "49619",
    full_city_name: "Brethren, MI",
    city_name: "Brethren",
  },
  {
    postal_code: "39641",
    full_city_name: "Jayess, MS",
    city_name: "Jayess",
  },
  {
    postal_code: "52332",
    full_city_name: "Shellsburg, IA",
    city_name: "Shellsburg",
  },
  {
    postal_code: "84646",
    full_city_name: "Moroni, UT",
    city_name: "Moroni",
  },
  {
    postal_code: "49336",
    full_city_name: "Morley, MI",
    city_name: "Morley",
  },
  {
    postal_code: "05825",
    full_city_name: "Coventry, VT",
    city_name: "Coventry",
  },
  {
    postal_code: "08809",
    full_city_name: "Clinton, NJ",
    city_name: "Clinton",
  },
  {
    postal_code: "62347",
    full_city_name: "Liberty, IL",
    city_name: "Liberty",
  },
  {
    postal_code: "07642",
    full_city_name: "Hillsdale, NJ",
    city_name: "Hillsdale",
  },
  {
    postal_code: "73029",
    full_city_name: "Cyril, OK",
    city_name: "Cyril",
  },
  {
    postal_code: "92059",
    full_city_name: "Pala, CA",
    city_name: "Pala",
  },
  {
    postal_code: "08019",
    full_city_name: "Chatsworth, NJ",
    city_name: "Chatsworth",
  },
  {
    postal_code: "66609",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "72530",
    full_city_name: "Drasco, AR",
    city_name: "Drasco",
  },
  {
    postal_code: "05765",
    full_city_name: "Proctor, VT",
    city_name: "Proctor",
  },
  {
    postal_code: "99126",
    full_city_name: "Evans, WA",
    city_name: "Evans",
  },
  {
    postal_code: "04925",
    full_city_name: "Caratunk, ME",
    city_name: "Caratunk",
  },
  {
    postal_code: "59270",
    full_city_name: "Sidney, MT",
    city_name: "Sidney",
  },
  {
    postal_code: "03597",
    full_city_name: "West Stewartstown, NH",
    city_name: "West Stewartstown",
  },
  {
    postal_code: "40854",
    full_city_name: "Loyall, KY",
    city_name: "Loyall",
  },
  {
    postal_code: "79350",
    full_city_name: "New Deal, TX",
    city_name: "New Deal",
  },
  {
    postal_code: "72562",
    full_city_name: "Newark, AR",
    city_name: "Newark",
  },
  {
    postal_code: "77482",
    full_city_name: "Van Vleck, TX",
    city_name: "Van Vleck",
  },
  {
    postal_code: "50851",
    full_city_name: "Lenox, IA",
    city_name: "Lenox",
  },
  {
    postal_code: "30450",
    full_city_name: "Portal, GA",
    city_name: "Portal",
  },
  {
    postal_code: "18218",
    full_city_name: "Coaldale, PA",
    city_name: "Coaldale",
  },
  {
    postal_code: "71354",
    full_city_name: "Monterey, LA",
    city_name: "Monterey",
  },
  {
    postal_code: "29474",
    full_city_name: "Round O, SC",
    city_name: "Round O",
  },
  {
    postal_code: "25981",
    full_city_name: "Quinwood, WV",
    city_name: "Quinwood",
  },
  {
    postal_code: "17846",
    full_city_name: "Millville, PA",
    city_name: "Millville",
  },
  {
    postal_code: "12480",
    full_city_name: "Shandaken, NY",
    city_name: "Shandaken",
  },
  {
    postal_code: "14858",
    full_city_name: "Lindley, NY",
    city_name: "Lindley",
  },
  {
    postal_code: "67561",
    full_city_name: "Nickerson, KS",
    city_name: "Nickerson",
  },
  {
    postal_code: "62061",
    full_city_name: "Marine, IL",
    city_name: "Marine",
  },
  {
    postal_code: "44846",
    full_city_name: "Milan, OH",
    city_name: "Milan",
  },
  {
    postal_code: "49971",
    full_city_name: "White Pine, MI",
    city_name: "White Pine",
  },
  {
    postal_code: "45881",
    full_city_name: "Rawson, OH",
    city_name: "Rawson",
  },
  {
    postal_code: "56052",
    full_city_name: "Kilkenny, MN",
    city_name: "Kilkenny",
  },
  {
    postal_code: "51525",
    full_city_name: "Carson, IA",
    city_name: "Carson",
  },
  {
    postal_code: "75486",
    full_city_name: "Sumner, TX",
    city_name: "Sumner",
  },
  {
    postal_code: "21679",
    full_city_name: "Wye Mills, MD",
    city_name: "Wye Mills",
  },
  {
    postal_code: "42541",
    full_city_name: "Middleburg, KY",
    city_name: "Middleburg",
  },
  {
    postal_code: "54560",
    full_city_name: "Sayner, WI",
    city_name: "Sayner",
  },
  {
    postal_code: "23413",
    full_city_name: "Nassawadox, VA",
    city_name: "Nassawadox",
  },
  {
    postal_code: "13659",
    full_city_name: "Lorraine, NY",
    city_name: "Lorraine",
  },
  {
    postal_code: "49876",
    full_city_name: "Quinnesec, MI",
    city_name: "Quinnesec",
  },
  {
    postal_code: "23066",
    full_city_name: "Gwynn, VA",
    city_name: "Gwynn",
  },
  {
    postal_code: "12970",
    full_city_name: "Paul Smiths, NY",
    city_name: "Paul Smiths",
  },
  {
    postal_code: "48464",
    full_city_name: "Otter Lake, MI",
    city_name: "Otter Lake",
  },
  {
    postal_code: "96791",
    full_city_name: "Waialua, HI",
    city_name: "Waialua",
  },
  {
    postal_code: "53086",
    full_city_name: "Slinger, WI",
    city_name: "Slinger",
  },
  {
    postal_code: "62207",
    full_city_name: "East Saint Louis, IL",
    city_name: "East Saint Louis",
  },
  {
    postal_code: "66716",
    full_city_name: "Bronson, KS",
    city_name: "Bronson",
  },
  {
    postal_code: "54870",
    full_city_name: "Sarona, WI",
    city_name: "Sarona",
  },
  {
    postal_code: "51035",
    full_city_name: "Marcus, IA",
    city_name: "Marcus",
  },
  {
    postal_code: "36026",
    full_city_name: "Equality, AL",
    city_name: "Equality",
  },
  {
    postal_code: "14568",
    full_city_name: "Walworth, NY",
    city_name: "Walworth",
  },
  {
    postal_code: "39421",
    full_city_name: "Bassfield, MS",
    city_name: "Bassfield",
  },
  {
    postal_code: "79325",
    full_city_name: "Farwell, TX",
    city_name: "Farwell",
  },
  {
    postal_code: "38922",
    full_city_name: "Coffeeville, MS",
    city_name: "Coffeeville",
  },
  {
    postal_code: "20689",
    full_city_name: "Sunderland, MD",
    city_name: "Sunderland",
  },
  {
    postal_code: "01264",
    full_city_name: "Tyringham, MA",
    city_name: "Tyringham",
  },
  {
    postal_code: "71941",
    full_city_name: "Donaldson, AR",
    city_name: "Donaldson",
  },
  {
    postal_code: "65627",
    full_city_name: "Cedarcreek, MO",
    city_name: "Cedarcreek",
  },
  {
    postal_code: "49033",
    full_city_name: "Ceresco, MI",
    city_name: "Ceresco",
  },
  {
    postal_code: "49910",
    full_city_name: "Bergland, MI",
    city_name: "Bergland",
  },
  {
    postal_code: "06103",
    full_city_name: "Hartford, CT",
    city_name: "Hartford",
  },
  {
    postal_code: "62281",
    full_city_name: "Saint Jacob, IL",
    city_name: "Saint Jacob",
  },
  {
    postal_code: "53011",
    full_city_name: "Cascade, WI",
    city_name: "Cascade",
  },
  {
    postal_code: "70036",
    full_city_name: "Barataria, LA",
    city_name: "Barataria",
  },
  {
    postal_code: "18038",
    full_city_name: "Danielsville, PA",
    city_name: "Danielsville",
  },
  {
    postal_code: "61834",
    full_city_name: "Danville, IL",
    city_name: "Danville",
  },
  {
    postal_code: "49460",
    full_city_name: "West Olive, MI",
    city_name: "West Olive",
  },
  {
    postal_code: "72904",
    full_city_name: "Fort Smith, AR",
    city_name: "Fort Smith",
  },
  {
    postal_code: "10588",
    full_city_name: "Shrub Oak, NY",
    city_name: "Shrub Oak",
  },
  {
    postal_code: "89179",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "46590",
    full_city_name: "Winona Lake, IN",
    city_name: "Winona Lake",
  },
  {
    postal_code: "46616",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "14715",
    full_city_name: "Bolivar, NY",
    city_name: "Bolivar",
  },
  {
    postal_code: "97442",
    full_city_name: "Glendale, OR",
    city_name: "Glendale",
  },
  {
    postal_code: "15537",
    full_city_name: "Everett, PA",
    city_name: "Everett",
  },
  {
    postal_code: "54169",
    full_city_name: "Sherwood, WI",
    city_name: "Sherwood",
  },
  {
    postal_code: "82604",
    full_city_name: "Casper, WY",
    city_name: "Casper",
  },
  {
    postal_code: "07652",
    full_city_name: "Paramus, NJ",
    city_name: "Paramus",
  },
  {
    postal_code: "47112",
    full_city_name: "Corydon, IN",
    city_name: "Corydon",
  },
  {
    postal_code: "23456",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "99337",
    full_city_name: "Kennewick, WA",
    city_name: "Kennewick",
  },
  {
    postal_code: "87556",
    full_city_name: "Questa, NM",
    city_name: "Questa",
  },
  {
    postal_code: "80447",
    full_city_name: "Grand Lake, CO",
    city_name: "Grand Lake",
  },
  {
    postal_code: "35622",
    full_city_name: "Falkville, AL",
    city_name: "Falkville",
  },
  {
    postal_code: "23509",
    full_city_name: "Norfolk, VA",
    city_name: "Norfolk",
  },
  {
    postal_code: "31901",
    full_city_name: "Columbus, GA",
    city_name: "Columbus",
  },
  {
    postal_code: "31620",
    full_city_name: "Adel, GA",
    city_name: "Adel",
  },
  {
    postal_code: "49670",
    full_city_name: "Northport, MI",
    city_name: "Northport",
  },
  {
    postal_code: "35653",
    full_city_name: "Russellville, AL",
    city_name: "Russellville",
  },
  {
    postal_code: "04421",
    full_city_name: "Castine, ME",
    city_name: "Castine",
  },
  {
    postal_code: "83429",
    full_city_name: "Island Park, ID",
    city_name: "Island Park",
  },
  {
    postal_code: "07106",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "83660",
    full_city_name: "Parma, ID",
    city_name: "Parma",
  },
  {
    postal_code: "42123",
    full_city_name: "Austin, KY",
    city_name: "Austin",
  },
  {
    postal_code: "05353",
    full_city_name: "Townshend, VT",
    city_name: "Townshend",
  },
  {
    postal_code: "52151",
    full_city_name: "Lansing, IA",
    city_name: "Lansing",
  },
  {
    postal_code: "99652",
    full_city_name: "Big Lake, AK",
    city_name: "Big Lake",
  },
  {
    postal_code: "65772",
    full_city_name: "Washburn, MO",
    city_name: "Washburn",
  },
  {
    postal_code: "67135",
    full_city_name: "Sedgwick, KS",
    city_name: "Sedgwick",
  },
  {
    postal_code: "48722",
    full_city_name: "Bridgeport, MI",
    city_name: "Bridgeport",
  },
  {
    postal_code: "47126",
    full_city_name: "Henryville, IN",
    city_name: "Henryville",
  },
  {
    postal_code: "71234",
    full_city_name: "Downsville, LA",
    city_name: "Downsville",
  },
  {
    postal_code: "58054",
    full_city_name: "Lisbon, ND",
    city_name: "Lisbon",
  },
  {
    postal_code: "49241",
    full_city_name: "Hanover, MI",
    city_name: "Hanover",
  },
  {
    postal_code: "71461",
    full_city_name: "New Llano, LA",
    city_name: "New Llano",
  },
  {
    postal_code: "20615",
    full_city_name: "Broomes Island, MD",
    city_name: "Broomes Island",
  },
  {
    postal_code: "30445",
    full_city_name: "Mount Vernon, GA",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "53023",
    full_city_name: "Glenbeulah, WI",
    city_name: "Glenbeulah",
  },
  {
    postal_code: "73127",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "95421",
    full_city_name: "Cazadero, CA",
    city_name: "Cazadero",
  },
  {
    postal_code: "43822",
    full_city_name: "Frazeysburg, OH",
    city_name: "Frazeysburg",
  },
  {
    postal_code: "47946",
    full_city_name: "Francesville, IN",
    city_name: "Francesville",
  },
  {
    postal_code: "12583",
    full_city_name: "Tivoli, NY",
    city_name: "Tivoli",
  },
  {
    postal_code: "04363",
    full_city_name: "Windsor, ME",
    city_name: "Windsor",
  },
  {
    postal_code: "82214",
    full_city_name: "Guernsey, WY",
    city_name: "Guernsey",
  },
  {
    postal_code: "54141",
    full_city_name: "Little Suamico, WI",
    city_name: "Little Suamico",
  },
  {
    postal_code: "14623",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "50154",
    full_city_name: "Mc Callsburg, IA",
    city_name: "Mc Callsburg",
  },
  {
    postal_code: "55724",
    full_city_name: "Cotton, MN",
    city_name: "Cotton",
  },
  {
    postal_code: "88316",
    full_city_name: "Capitan, NM",
    city_name: "Capitan",
  },
  {
    postal_code: "70819",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "21140",
    full_city_name: "Riva, MD",
    city_name: "Riva",
  },
  {
    postal_code: "55909",
    full_city_name: "Adams, MN",
    city_name: "Adams",
  },
  {
    postal_code: "75925",
    full_city_name: "Alto, TX",
    city_name: "Alto",
  },
  {
    postal_code: "78950",
    full_city_name: "New Ulm, TX",
    city_name: "New Ulm",
  },
  {
    postal_code: "46065",
    full_city_name: "Rossville, IN",
    city_name: "Rossville",
  },
  {
    postal_code: "56762",
    full_city_name: "Warren, MN",
    city_name: "Warren",
  },
  {
    postal_code: "04556",
    full_city_name: "Edgecomb, ME",
    city_name: "Edgecomb",
  },
  {
    postal_code: "58530",
    full_city_name: "Center, ND",
    city_name: "Center",
  },
  {
    postal_code: "46989",
    full_city_name: "Upland, IN",
    city_name: "Upland",
  },
  {
    postal_code: "44114",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "97022",
    full_city_name: "Eagle Creek, OR",
    city_name: "Eagle Creek",
  },
  {
    postal_code: "67070",
    full_city_name: "Kiowa, KS",
    city_name: "Kiowa",
  },
  {
    postal_code: "61840",
    full_city_name: "Dewey, IL",
    city_name: "Dewey",
  },
  {
    postal_code: "79024",
    full_city_name: "Darrouzett, TX",
    city_name: "Darrouzett",
  },
  {
    postal_code: "54205",
    full_city_name: "Casco, WI",
    city_name: "Casco",
  },
  {
    postal_code: "60550",
    full_city_name: "Shabbona, IL",
    city_name: "Shabbona",
  },
  {
    postal_code: "50446",
    full_city_name: "Joice, IA",
    city_name: "Joice",
  },
  {
    postal_code: "07088",
    full_city_name: "Vauxhall, NJ",
    city_name: "Vauxhall",
  },
  {
    postal_code: "83210",
    full_city_name: "Aberdeen, ID",
    city_name: "Aberdeen",
  },
  {
    postal_code: "61334",
    full_city_name: "Lostant, IL",
    city_name: "Lostant",
  },
  {
    postal_code: "21862",
    full_city_name: "Showell, MD",
    city_name: "Showell",
  },
  {
    postal_code: "75431",
    full_city_name: "Como, TX",
    city_name: "Como",
  },
  {
    postal_code: "56449",
    full_city_name: "Fort Ripley, MN",
    city_name: "Fort Ripley",
  },
  {
    postal_code: "80743",
    full_city_name: "Otis, CO",
    city_name: "Otis",
  },
  {
    postal_code: "61230",
    full_city_name: "Albany, IL",
    city_name: "Albany",
  },
  {
    postal_code: "68339",
    full_city_name: "Denton, NE",
    city_name: "Denton",
  },
  {
    postal_code: "61283",
    full_city_name: "Tampico, IL",
    city_name: "Tampico",
  },
  {
    postal_code: "36251",
    full_city_name: "Ashland, AL",
    city_name: "Ashland",
  },
  {
    postal_code: "55741",
    full_city_name: "Gilbert, MN",
    city_name: "Gilbert",
  },
  {
    postal_code: "17064",
    full_city_name: "Mount Gretna, PA",
    city_name: "Mount Gretna",
  },
  {
    postal_code: "30624",
    full_city_name: "Bowman, GA",
    city_name: "Bowman",
  },
  {
    postal_code: "49646",
    full_city_name: "Kalkaska, MI",
    city_name: "Kalkaska",
  },
  {
    postal_code: "35010",
    full_city_name: "Alexander City, AL",
    city_name: "Alexander City",
  },
  {
    postal_code: "07104",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "31503",
    full_city_name: "Waycross, GA",
    city_name: "Waycross",
  },
  {
    postal_code: "82601",
    full_city_name: "Casper, WY",
    city_name: "Casper",
  },
  {
    postal_code: "53183",
    full_city_name: "Wales, WI",
    city_name: "Wales",
  },
  {
    postal_code: "04765",
    full_city_name: "Patten, ME",
    city_name: "Patten",
  },
  {
    postal_code: "07435",
    full_city_name: "Newfoundland, NJ",
    city_name: "Newfoundland",
  },
  {
    postal_code: "66535",
    full_city_name: "Saint George, KS",
    city_name: "Saint George",
  },
  {
    postal_code: "07440",
    full_city_name: "Pequannock, NJ",
    city_name: "Pequannock",
  },
  {
    postal_code: "08535",
    full_city_name: "Millstone Township, NJ",
    city_name: "Millstone Township",
  },
  {
    postal_code: "07645",
    full_city_name: "Montvale, NJ",
    city_name: "Montvale",
  },
  {
    postal_code: "35613",
    full_city_name: "Athens, AL",
    city_name: "Athens",
  },
  {
    postal_code: "53206",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "53185",
    full_city_name: "Waterford, WI",
    city_name: "Waterford",
  },
  {
    postal_code: "70373",
    full_city_name: "Larose, LA",
    city_name: "Larose",
  },
  {
    postal_code: "53968",
    full_city_name: "Wonewoc, WI",
    city_name: "Wonewoc",
  },
  {
    postal_code: "83639",
    full_city_name: "Marsing, ID",
    city_name: "Marsing",
  },
  {
    postal_code: "10514",
    full_city_name: "Chappaqua, NY",
    city_name: "Chappaqua",
  },
  {
    postal_code: "36587",
    full_city_name: "Wilmer, AL",
    city_name: "Wilmer",
  },
  {
    postal_code: "47106",
    full_city_name: "Borden, IN",
    city_name: "Borden",
  },
  {
    postal_code: "30824",
    full_city_name: "Thomson, GA",
    city_name: "Thomson",
  },
  {
    postal_code: "10606",
    full_city_name: "White Plains, NY",
    city_name: "White Plains",
  },
  {
    postal_code: "53074",
    full_city_name: "Port Washington, WI",
    city_name: "Port Washington",
  },
  {
    postal_code: "36081",
    full_city_name: "Troy, AL",
    city_name: "Troy",
  },
  {
    postal_code: "07522",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "28902",
    full_city_name: "Brasstown, NC",
    city_name: "Brasstown",
  },
  {
    postal_code: "07721",
    full_city_name: "Cliffwood, NJ",
    city_name: "Cliffwood",
  },
  {
    postal_code: "77702",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "48184",
    full_city_name: "Wayne, MI",
    city_name: "Wayne",
  },
  {
    postal_code: "68037",
    full_city_name: "Louisville, NE",
    city_name: "Louisville",
  },
  {
    postal_code: "53005",
    full_city_name: "Brookfield, WI",
    city_name: "Brookfield",
  },
  {
    postal_code: "62568",
    full_city_name: "Taylorville, IL",
    city_name: "Taylorville",
  },
  {
    postal_code: "81621",
    full_city_name: "Basalt, CO",
    city_name: "Basalt",
  },
  {
    postal_code: "51106",
    full_city_name: "Sioux City, IA",
    city_name: "Sioux City",
  },
  {
    postal_code: "04730",
    full_city_name: "Houlton, ME",
    city_name: "Houlton",
  },
  {
    postal_code: "37391",
    full_city_name: "Turtletown, TN",
    city_name: "Turtletown",
  },
  {
    postal_code: "08828",
    full_city_name: "Helmetta, NJ",
    city_name: "Helmetta",
  },
  {
    postal_code: "81039",
    full_city_name: "Fowler, CO",
    city_name: "Fowler",
  },
  {
    postal_code: "79836",
    full_city_name: "Clint, TX",
    city_name: "Clint",
  },
  {
    postal_code: "73772",
    full_city_name: "Watonga, OK",
    city_name: "Watonga",
  },
  {
    postal_code: "51250",
    full_city_name: "Sioux Center, IA",
    city_name: "Sioux Center",
  },
  {
    postal_code: "75975",
    full_city_name: "Timpson, TX",
    city_name: "Timpson",
  },
  {
    postal_code: "13658",
    full_city_name: "Lisbon, NY",
    city_name: "Lisbon",
  },
  {
    postal_code: "80459",
    full_city_name: "Kremmling, CO",
    city_name: "Kremmling",
  },
  {
    postal_code: "76430",
    full_city_name: "Albany, TX",
    city_name: "Albany",
  },
  {
    postal_code: "14427",
    full_city_name: "Castile, NY",
    city_name: "Castile",
  },
  {
    postal_code: "10928",
    full_city_name: "Highland Falls, NY",
    city_name: "Highland Falls",
  },
  {
    postal_code: "95540",
    full_city_name: "Fortuna, CA",
    city_name: "Fortuna",
  },
  {
    postal_code: "39365",
    full_city_name: "Union, MS",
    city_name: "Union",
  },
  {
    postal_code: "37402",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "07503",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "10589",
    full_city_name: "Somers, NY",
    city_name: "Somers",
  },
  {
    postal_code: "49649",
    full_city_name: "Kingsley, MI",
    city_name: "Kingsley",
  },
  {
    postal_code: "48084",
    full_city_name: "Troy, MI",
    city_name: "Troy",
  },
  {
    postal_code: "36618",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "54202",
    full_city_name: "Baileys Harbor, WI",
    city_name: "Baileys Harbor",
  },
  {
    postal_code: "96003",
    full_city_name: "Redding, CA",
    city_name: "Redding",
  },
  {
    postal_code: "07005",
    full_city_name: "Boonton, NJ",
    city_name: "Boonton",
  },
  {
    postal_code: "07650",
    full_city_name: "Palisades Park, NJ",
    city_name: "Palisades Park",
  },
  {
    postal_code: "19035",
    full_city_name: "Gladwyne, PA",
    city_name: "Gladwyne",
  },
  {
    postal_code: "46816",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "52324",
    full_city_name: "Palo, IA",
    city_name: "Palo",
  },
  {
    postal_code: "07001",
    full_city_name: "Avenel, NJ",
    city_name: "Avenel",
  },
  {
    postal_code: "08730",
    full_city_name: "Brielle, NJ",
    city_name: "Brielle",
  },
  {
    postal_code: "10965",
    full_city_name: "Pearl River, NY",
    city_name: "Pearl River",
  },
  {
    postal_code: "73075",
    full_city_name: "Pauls Valley, OK",
    city_name: "Pauls Valley",
  },
  {
    postal_code: "54456",
    full_city_name: "Neillsville, WI",
    city_name: "Neillsville",
  },
  {
    postal_code: "31545",
    full_city_name: "Jesup, GA",
    city_name: "Jesup",
  },
  {
    postal_code: "23437",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "47424",
    full_city_name: "Bloomfield, IN",
    city_name: "Bloomfield",
  },
  {
    postal_code: "21851",
    full_city_name: "Pocomoke City, MD",
    city_name: "Pocomoke City",
  },
  {
    postal_code: "76821",
    full_city_name: "Ballinger, TX",
    city_name: "Ballinger",
  },
  {
    postal_code: "95503",
    full_city_name: "Eureka, CA",
    city_name: "Eureka",
  },
  {
    postal_code: "73173",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "85932",
    full_city_name: "Nutrioso, AZ",
    city_name: "Nutrioso",
  },
  {
    postal_code: "07501",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "54982",
    full_city_name: "Wautoma, WI",
    city_name: "Wautoma",
  },
  {
    postal_code: "32448",
    full_city_name: "Marianna, FL",
    city_name: "Marianna",
  },
  {
    postal_code: "73122",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "47959",
    full_city_name: "Monon, IN",
    city_name: "Monon",
  },
  {
    postal_code: "42220",
    full_city_name: "Elkton, KY",
    city_name: "Elkton",
  },
  {
    postal_code: "48302",
    full_city_name: "Bloomfield Hills, MI",
    city_name: "Bloomfield Hills",
  },
  {
    postal_code: "61883",
    full_city_name: "Westville, IL",
    city_name: "Westville",
  },
  {
    postal_code: "96731",
    full_city_name: "Kahuku, HI",
    city_name: "Kahuku",
  },
  {
    postal_code: "35555",
    full_city_name: "Fayette, AL",
    city_name: "Fayette",
  },
  {
    postal_code: "35652",
    full_city_name: "Rogersville, AL",
    city_name: "Rogersville",
  },
  {
    postal_code: "07936",
    full_city_name: "East Hanover, NJ",
    city_name: "East Hanover",
  },
  {
    postal_code: "47320",
    full_city_name: "Albany, IN",
    city_name: "Albany",
  },
  {
    postal_code: "62447",
    full_city_name: "Neoga, IL",
    city_name: "Neoga",
  },
  {
    postal_code: "49309",
    full_city_name: "Bitely, MI",
    city_name: "Bitely",
  },
  {
    postal_code: "23690",
    full_city_name: "Yorktown, VA",
    city_name: "Yorktown",
  },
  {
    postal_code: "17304",
    full_city_name: "Aspers, PA",
    city_name: "Aspers",
  },
  {
    postal_code: "88021",
    full_city_name: "Anthony, NM",
    city_name: "Anthony",
  },
  {
    postal_code: "19533",
    full_city_name: "Leesport, PA",
    city_name: "Leesport",
  },
  {
    postal_code: "89835",
    full_city_name: "Wells, NV",
    city_name: "Wells",
  },
  {
    postal_code: "08067",
    full_city_name: "Pedricktown, NJ",
    city_name: "Pedricktown",
  },
  {
    postal_code: "66605",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "19941",
    full_city_name: "Ellendale, DE",
    city_name: "Ellendale",
  },
  {
    postal_code: "32444",
    full_city_name: "Lynn Haven, FL",
    city_name: "Lynn Haven",
  },
  {
    postal_code: "36693",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "14722",
    full_city_name: "Chautauqua, NY",
    city_name: "Chautauqua",
  },
  {
    postal_code: "07675",
    full_city_name: "Westwood, NJ",
    city_name: "Westwood",
  },
  {
    postal_code: "57350",
    full_city_name: "Huron, SD",
    city_name: "Huron",
  },
  {
    postal_code: "74884",
    full_city_name: "Wewoka, OK",
    city_name: "Wewoka",
  },
  {
    postal_code: "54603",
    full_city_name: "La Crosse, WI",
    city_name: "La Crosse",
  },
  {
    postal_code: "07874",
    full_city_name: "Stanhope, NJ",
    city_name: "Stanhope",
  },
  {
    postal_code: "49056",
    full_city_name: "Grand Junction, MI",
    city_name: "Grand Junction",
  },
  {
    postal_code: "18106",
    full_city_name: "Allentown, PA",
    city_name: "Allentown",
  },
  {
    postal_code: "32343",
    full_city_name: "Midway, FL",
    city_name: "Midway",
  },
  {
    postal_code: "39842",
    full_city_name: "Dawson, GA",
    city_name: "Dawson",
  },
  {
    postal_code: "49720",
    full_city_name: "Charlevoix, MI",
    city_name: "Charlevoix",
  },
  {
    postal_code: "36576",
    full_city_name: "Silverhill, AL",
    city_name: "Silverhill",
  },
  {
    postal_code: "08884",
    full_city_name: "Spotswood, NJ",
    city_name: "Spotswood",
  },
  {
    postal_code: "07442",
    full_city_name: "Pompton Lakes, NJ",
    city_name: "Pompton Lakes",
  },
  {
    postal_code: "07436",
    full_city_name: "Oakland, NJ",
    city_name: "Oakland",
  },
  {
    postal_code: "49046",
    full_city_name: "Delton, MI",
    city_name: "Delton",
  },
  {
    postal_code: "65624",
    full_city_name: "Cape Fair, MO",
    city_name: "Cape Fair",
  },
  {
    postal_code: "71465",
    full_city_name: "Olla, LA",
    city_name: "Olla",
  },
  {
    postal_code: "38342",
    full_city_name: "Hollow Rock, TN",
    city_name: "Hollow Rock",
  },
  {
    postal_code: "83347",
    full_city_name: "Paul, ID",
    city_name: "Paul",
  },
  {
    postal_code: "60472",
    full_city_name: "Robbins, IL",
    city_name: "Robbins",
  },
  {
    postal_code: "26808",
    full_city_name: "High View, WV",
    city_name: "High View",
  },
  {
    postal_code: "54614",
    full_city_name: "Bangor, WI",
    city_name: "Bangor",
  },
  {
    postal_code: "57032",
    full_city_name: "Harrisburg, SD",
    city_name: "Harrisburg",
  },
  {
    postal_code: "07940",
    full_city_name: "Madison, NJ",
    city_name: "Madison",
  },
  {
    postal_code: "57469",
    full_city_name: "Redfield, SD",
    city_name: "Redfield",
  },
  {
    postal_code: "72335",
    full_city_name: "Forrest City, AR",
    city_name: "Forrest City",
  },
  {
    postal_code: "18618",
    full_city_name: "Harveys Lake, PA",
    city_name: "Harveys Lake",
  },
  {
    postal_code: "15521",
    full_city_name: "Alum Bank, PA",
    city_name: "Alum Bank",
  },
  {
    postal_code: "02764",
    full_city_name: "North Dighton, MA",
    city_name: "North Dighton",
  },
  {
    postal_code: "27207",
    full_city_name: "Bear Creek, NC",
    city_name: "Bear Creek",
  },
  {
    postal_code: "12719",
    full_city_name: "Barryville, NY",
    city_name: "Barryville",
  },
  {
    postal_code: "75667",
    full_city_name: "Laneville, TX",
    city_name: "Laneville",
  },
  {
    postal_code: "44813",
    full_city_name: "Bellville, OH",
    city_name: "Bellville",
  },
  {
    postal_code: "29061",
    full_city_name: "Hopkins, SC",
    city_name: "Hopkins",
  },
  {
    postal_code: "05150",
    full_city_name: "North Springfield, VT",
    city_name: "North Springfield",
  },
  {
    postal_code: "28689",
    full_city_name: "Union Grove, NC",
    city_name: "Union Grove",
  },
  {
    postal_code: "12087",
    full_city_name: "Hannacroix, NY",
    city_name: "Hannacroix",
  },
  {
    postal_code: "17047",
    full_city_name: "Loysville, PA",
    city_name: "Loysville",
  },
  {
    postal_code: "84039",
    full_city_name: "Lapoint, UT",
    city_name: "Lapoint",
  },
  {
    postal_code: "03224",
    full_city_name: "Canterbury, NH",
    city_name: "Canterbury",
  },
  {
    postal_code: "55612",
    full_city_name: "Lutsen, MN",
    city_name: "Lutsen",
  },
  {
    postal_code: "12058",
    full_city_name: "Earlton, NY",
    city_name: "Earlton",
  },
  {
    postal_code: "44411",
    full_city_name: "Deerfield, OH",
    city_name: "Deerfield",
  },
  {
    postal_code: "64401",
    full_city_name: "Agency, MO",
    city_name: "Agency",
  },
  {
    postal_code: "56663",
    full_city_name: "Pennington, MN",
    city_name: "Pennington",
  },
  {
    postal_code: "71223",
    full_city_name: "Bonita, LA",
    city_name: "Bonita",
  },
  {
    postal_code: "22938",
    full_city_name: "Faber, VA",
    city_name: "Faber",
  },
  {
    postal_code: "39834",
    full_city_name: "Climax, GA",
    city_name: "Climax",
  },
  {
    postal_code: "75976",
    full_city_name: "Wells, TX",
    city_name: "Wells",
  },
  {
    postal_code: "87728",
    full_city_name: "Maxwell, NM",
    city_name: "Maxwell",
  },
  {
    postal_code: "93461",
    full_city_name: "Shandon, CA",
    city_name: "Shandon",
  },
  {
    postal_code: "18219",
    full_city_name: "Conyngham, PA",
    city_name: "Conyngham",
  },
  {
    postal_code: "61263",
    full_city_name: "Matherville, IL",
    city_name: "Matherville",
  },
  {
    postal_code: "43148",
    full_city_name: "Pleasantville, OH",
    city_name: "Pleasantville",
  },
  {
    postal_code: "05257",
    full_city_name: "North Bennington, VT",
    city_name: "North Bennington",
  },
  {
    postal_code: "94592",
    full_city_name: "Vallejo, CA",
    city_name: "Vallejo",
  },
  {
    postal_code: "45647",
    full_city_name: "Londonderry, OH",
    city_name: "Londonderry",
  },
  {
    postal_code: "98819",
    full_city_name: "Conconully, WA",
    city_name: "Conconully",
  },
  {
    postal_code: "85618",
    full_city_name: "Mammoth, AZ",
    city_name: "Mammoth",
  },
  {
    postal_code: "01718",
    full_city_name: "Acton, MA",
    city_name: "Acton",
  },
  {
    postal_code: "47639",
    full_city_name: "Haubstadt, IN",
    city_name: "Haubstadt",
  },
  {
    postal_code: "05853",
    full_city_name: "Morgan, VT",
    city_name: "Morgan",
  },
  {
    postal_code: "45659",
    full_city_name: "Pedro, OH",
    city_name: "Pedro",
  },
  {
    postal_code: "40464",
    full_city_name: "Parksville, KY",
    city_name: "Parksville",
  },
  {
    postal_code: "53808",
    full_city_name: "Dickeyville, WI",
    city_name: "Dickeyville",
  },
  {
    postal_code: "06230",
    full_city_name: "Abington, CT",
    city_name: "Abington",
  },
  {
    postal_code: "47965",
    full_city_name: "New Market, IN",
    city_name: "New Market",
  },
  {
    postal_code: "64077",
    full_city_name: "Orrick, MO",
    city_name: "Orrick",
  },
  {
    postal_code: "08090",
    full_city_name: "Wenonah, NJ",
    city_name: "Wenonah",
  },
  {
    postal_code: "42166",
    full_city_name: "Summer Shade, KY",
    city_name: "Summer Shade",
  },
  {
    postal_code: "54480",
    full_city_name: "Stetsonville, WI",
    city_name: "Stetsonville",
  },
  {
    postal_code: "04786",
    full_city_name: "Washburn, ME",
    city_name: "Washburn",
  },
  {
    postal_code: "04414",
    full_city_name: "Brownville, ME",
    city_name: "Brownville",
  },
  {
    postal_code: "47620",
    full_city_name: "Mount Vernon, IN",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "95918",
    full_city_name: "Browns Valley, CA",
    city_name: "Browns Valley",
  },
  {
    postal_code: "72442",
    full_city_name: "Manila, AR",
    city_name: "Manila",
  },
  {
    postal_code: "85344",
    full_city_name: "Parker, AZ",
    city_name: "Parker",
  },
  {
    postal_code: "48819",
    full_city_name: "Dansville, MI",
    city_name: "Dansville",
  },
  {
    postal_code: "68114",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "99336",
    full_city_name: "Kennewick, WA",
    city_name: "Kennewick",
  },
  {
    postal_code: "39851",
    full_city_name: "Fort Gaines, GA",
    city_name: "Fort Gaines",
  },
  {
    postal_code: "83676",
    full_city_name: "Wilder, ID",
    city_name: "Wilder",
  },
  {
    postal_code: "57110",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "35903",
    full_city_name: "Gadsden, AL",
    city_name: "Gadsden",
  },
  {
    postal_code: "72846",
    full_city_name: "Lamar, AR",
    city_name: "Lamar",
  },
  {
    postal_code: "07407",
    full_city_name: "Elmwood Park, NJ",
    city_name: "Elmwood Park",
  },
  {
    postal_code: "10570",
    full_city_name: "Pleasantville, NY",
    city_name: "Pleasantville",
  },
  {
    postal_code: "07424",
    full_city_name: "Little Falls, NJ",
    city_name: "Little Falls",
  },
  {
    postal_code: "10524",
    full_city_name: "Garrison, NY",
    city_name: "Garrison",
  },
  {
    postal_code: "16635",
    full_city_name: "Duncansville, PA",
    city_name: "Duncansville",
  },
  {
    postal_code: "17314",
    full_city_name: "Delta, PA",
    city_name: "Delta",
  },
  {
    postal_code: "49655",
    full_city_name: "Leroy, MI",
    city_name: "Leroy",
  },
  {
    postal_code: "82732",
    full_city_name: "Wright, WY",
    city_name: "Wright",
  },
  {
    postal_code: "02215",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "65761",
    full_city_name: "Theodosia, MO",
    city_name: "Theodosia",
  },
  {
    postal_code: "67063",
    full_city_name: "Hillsboro, KS",
    city_name: "Hillsboro",
  },
  {
    postal_code: "83350",
    full_city_name: "Rupert, ID",
    city_name: "Rupert",
  },
  {
    postal_code: "59313",
    full_city_name: "Baker, MT",
    city_name: "Baker",
  },
  {
    postal_code: "54436",
    full_city_name: "Granton, WI",
    city_name: "Granton",
  },
  {
    postal_code: "35772",
    full_city_name: "Stevenson, AL",
    city_name: "Stevenson",
  },
  {
    postal_code: "74349",
    full_city_name: "Ketchum, OK",
    city_name: "Ketchum",
  },
  {
    postal_code: "07823",
    full_city_name: "Belvidere, NJ",
    city_name: "Belvidere",
  },
  {
    postal_code: "78503",
    full_city_name: "Mcallen, TX",
    city_name: "Mcallen",
  },
  {
    postal_code: "42055",
    full_city_name: "Kuttawa, KY",
    city_name: "Kuttawa",
  },
  {
    postal_code: "18704",
    full_city_name: "Kingston, PA",
    city_name: "Kingston",
  },
  {
    postal_code: "53172",
    full_city_name: "South Milwaukee, WI",
    city_name: "South Milwaukee",
  },
  {
    postal_code: "23669",
    full_city_name: "Hampton, VA",
    city_name: "Hampton",
  },
  {
    postal_code: "07013",
    full_city_name: "Clifton, NJ",
    city_name: "Clifton",
  },
  {
    postal_code: "36701",
    full_city_name: "Selma, AL",
    city_name: "Selma",
  },
  {
    postal_code: "16735",
    full_city_name: "Kane, PA",
    city_name: "Kane",
  },
  {
    postal_code: "27562",
    full_city_name: "New Hill, NC",
    city_name: "New Hill",
  },
  {
    postal_code: "19144",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "35640",
    full_city_name: "Hartselle, AL",
    city_name: "Hartselle",
  },
  {
    postal_code: "49615",
    full_city_name: "Bellaire, MI",
    city_name: "Bellaire",
  },
  {
    postal_code: "08830",
    full_city_name: "Iselin, NJ",
    city_name: "Iselin",
  },
  {
    postal_code: "07945",
    full_city_name: "Mendham, NJ",
    city_name: "Mendham",
  },
  {
    postal_code: "23322",
    full_city_name: "Chesapeake, VA",
    city_name: "Chesapeake",
  },
  {
    postal_code: "48032",
    full_city_name: "Jeddo, MI",
    city_name: "Jeddo",
  },
  {
    postal_code: "27865",
    full_city_name: "Pinetown, NC",
    city_name: "Pinetown",
  },
  {
    postal_code: "07649",
    full_city_name: "Oradell, NJ",
    city_name: "Oradell",
  },
  {
    postal_code: "07932",
    full_city_name: "Florham Park, NJ",
    city_name: "Florham Park",
  },
  {
    postal_code: "38261",
    full_city_name: "Union City, TN",
    city_name: "Union City",
  },
  {
    postal_code: "53215",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "65018",
    full_city_name: "California, MO",
    city_name: "California",
  },
  {
    postal_code: "54104",
    full_city_name: "Athelstane, WI",
    city_name: "Athelstane",
  },
  {
    postal_code: "88001",
    full_city_name: "Las Cruces, NM",
    city_name: "Las Cruces",
  },
  {
    postal_code: "48341",
    full_city_name: "Pontiac, MI",
    city_name: "Pontiac",
  },
  {
    postal_code: "07071",
    full_city_name: "Lyndhurst, NJ",
    city_name: "Lyndhurst",
  },
  {
    postal_code: "38614",
    full_city_name: "Clarksdale, MS",
    city_name: "Clarksdale",
  },
  {
    postal_code: "36079",
    full_city_name: "Troy, AL",
    city_name: "Troy",
  },
  {
    postal_code: "01585",
    full_city_name: "West Brookfield, MA",
    city_name: "West Brookfield",
  },
  {
    postal_code: "49544",
    full_city_name: "Grand Rapids, MI",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "16602",
    full_city_name: "Altoona, PA",
    city_name: "Altoona",
  },
  {
    postal_code: "89048",
    full_city_name: "Pahrump, NV",
    city_name: "Pahrump",
  },
  {
    postal_code: "22935",
    full_city_name: "Dyke, VA",
    city_name: "Dyke",
  },
  {
    postal_code: "56044",
    full_city_name: "Henderson, MN",
    city_name: "Henderson",
  },
  {
    postal_code: "36453",
    full_city_name: "Kinston, AL",
    city_name: "Kinston",
  },
  {
    postal_code: "07822",
    full_city_name: "Augusta, NJ",
    city_name: "Augusta",
  },
  {
    postal_code: "30563",
    full_city_name: "Mount Airy, GA",
    city_name: "Mount Airy",
  },
  {
    postal_code: "46743",
    full_city_name: "Harlan, IN",
    city_name: "Harlan",
  },
  {
    postal_code: "14711",
    full_city_name: "Belfast, NY",
    city_name: "Belfast",
  },
  {
    postal_code: "68428",
    full_city_name: "Raymond, NE",
    city_name: "Raymond",
  },
  {
    postal_code: "71454",
    full_city_name: "Montgomery, LA",
    city_name: "Montgomery",
  },
  {
    postal_code: "64784",
    full_city_name: "Sheldon, MO",
    city_name: "Sheldon",
  },
  {
    postal_code: "24549",
    full_city_name: "Dry Fork, VA",
    city_name: "Dry Fork",
  },
  {
    postal_code: "53214",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "22844",
    full_city_name: "New Market, VA",
    city_name: "New Market",
  },
  {
    postal_code: "68418",
    full_city_name: "Palmyra, NE",
    city_name: "Palmyra",
  },
  {
    postal_code: "54610",
    full_city_name: "Alma, WI",
    city_name: "Alma",
  },
  {
    postal_code: "75226",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "46115",
    full_city_name: "Carthage, IN",
    city_name: "Carthage",
  },
  {
    postal_code: "78401",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "39870",
    full_city_name: "Newton, GA",
    city_name: "Newton",
  },
  {
    postal_code: "04676",
    full_city_name: "Sedgwick, ME",
    city_name: "Sedgwick",
  },
  {
    postal_code: "32139",
    full_city_name: "Georgetown, FL",
    city_name: "Georgetown",
  },
  {
    postal_code: "27024",
    full_city_name: "Lowgap, NC",
    city_name: "Lowgap",
  },
  {
    postal_code: "67062",
    full_city_name: "Hesston, KS",
    city_name: "Hesston",
  },
  {
    postal_code: "21640",
    full_city_name: "Henderson, MD",
    city_name: "Henderson",
  },
  {
    postal_code: "07034",
    full_city_name: "Lake Hiawatha, NJ",
    city_name: "Lake Hiawatha",
  },
  {
    postal_code: "75563",
    full_city_name: "Linden, TX",
    city_name: "Linden",
  },
  {
    postal_code: "05653",
    full_city_name: "Eden Mills, VT",
    city_name: "Eden Mills",
  },
  {
    postal_code: "81140",
    full_city_name: "La Jara, CO",
    city_name: "La Jara",
  },
  {
    postal_code: "29628",
    full_city_name: "Calhoun Falls, SC",
    city_name: "Calhoun Falls",
  },
  {
    postal_code: "08103",
    full_city_name: "Camden, NJ",
    city_name: "Camden",
  },
  {
    postal_code: "48831",
    full_city_name: "Elsie, MI",
    city_name: "Elsie",
  },
  {
    postal_code: "42082",
    full_city_name: "Symsonia, KY",
    city_name: "Symsonia",
  },
  {
    postal_code: "54403",
    full_city_name: "Wausau, WI",
    city_name: "Wausau",
  },
  {
    postal_code: "11732",
    full_city_name: "East Norwich, NY",
    city_name: "East Norwich",
  },
  {
    postal_code: "64601",
    full_city_name: "Chillicothe, MO",
    city_name: "Chillicothe",
  },
  {
    postal_code: "22643",
    full_city_name: "Markham, VA",
    city_name: "Markham",
  },
  {
    postal_code: "74944",
    full_city_name: "Mccurtain, OK",
    city_name: "Mccurtain",
  },
  {
    postal_code: "19507",
    full_city_name: "Bethel, PA",
    city_name: "Bethel",
  },
  {
    postal_code: "66615",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "32460",
    full_city_name: "Sneads, FL",
    city_name: "Sneads",
  },
  {
    postal_code: "27941",
    full_city_name: "Harbinger, NC",
    city_name: "Harbinger",
  },
  {
    postal_code: "59087",
    full_city_name: "Winnett, MT",
    city_name: "Winnett",
  },
  {
    postal_code: "24366",
    full_city_name: "Rocky Gap, VA",
    city_name: "Rocky Gap",
  },
  {
    postal_code: "18629",
    full_city_name: "Mehoopany, PA",
    city_name: "Mehoopany",
  },
  {
    postal_code: "14718",
    full_city_name: "Cassadaga, NY",
    city_name: "Cassadaga",
  },
  {
    postal_code: "42170",
    full_city_name: "Woodburn, KY",
    city_name: "Woodburn",
  },
  {
    postal_code: "21918",
    full_city_name: "Conowingo, MD",
    city_name: "Conowingo",
  },
  {
    postal_code: "63468",
    full_city_name: "Shelbina, MO",
    city_name: "Shelbina",
  },
  {
    postal_code: "15824",
    full_city_name: "Brockway, PA",
    city_name: "Brockway",
  },
  {
    postal_code: "21539",
    full_city_name: "Lonaconing, MD",
    city_name: "Lonaconing",
  },
  {
    postal_code: "42323",
    full_city_name: "Beechmont, KY",
    city_name: "Beechmont",
  },
  {
    postal_code: "44859",
    full_city_name: "Nova, OH",
    city_name: "Nova",
  },
  {
    postal_code: "24347",
    full_city_name: "Hiwassee, VA",
    city_name: "Hiwassee",
  },
  {
    postal_code: "84746",
    full_city_name: "Leeds, UT",
    city_name: "Leeds",
  },
  {
    postal_code: "62890",
    full_city_name: "Thompsonville, IL",
    city_name: "Thompsonville",
  },
  {
    postal_code: "37847",
    full_city_name: "Pioneer, TN",
    city_name: "Pioneer",
  },
  {
    postal_code: "04543",
    full_city_name: "Damariscotta, ME",
    city_name: "Damariscotta",
  },
  {
    postal_code: "97054",
    full_city_name: "Deer Island, OR",
    city_name: "Deer Island",
  },
  {
    postal_code: "75691",
    full_city_name: "Tatum, TX",
    city_name: "Tatum",
  },
  {
    postal_code: "81418",
    full_city_name: "Eckert, CO",
    city_name: "Eckert",
  },
  {
    postal_code: "18224",
    full_city_name: "Freeland, PA",
    city_name: "Freeland",
  },
  {
    postal_code: "42051",
    full_city_name: "Hickory, KY",
    city_name: "Hickory",
  },
  {
    postal_code: "19505",
    full_city_name: "Bechtelsville, PA",
    city_name: "Bechtelsville",
  },
  {
    postal_code: "35987",
    full_city_name: "Steele, AL",
    city_name: "Steele",
  },
  {
    postal_code: "22027",
    full_city_name: "Dunn Loring, VA",
    city_name: "Dunn Loring",
  },
  {
    postal_code: "31513",
    full_city_name: "Baxley, GA",
    city_name: "Baxley",
  },
  {
    postal_code: "71051",
    full_city_name: "Elm Grove, LA",
    city_name: "Elm Grove",
  },
  {
    postal_code: "08026",
    full_city_name: "Gibbsboro, NJ",
    city_name: "Gibbsboro",
  },
  {
    postal_code: "18037",
    full_city_name: "Coplay, PA",
    city_name: "Coplay",
  },
  {
    postal_code: "05459",
    full_city_name: "Highgate Center, VT",
    city_name: "Highgate Center",
  },
  {
    postal_code: "68788",
    full_city_name: "West Point, NE",
    city_name: "West Point",
  },
  {
    postal_code: "63660",
    full_city_name: "Mineral Point, MO",
    city_name: "Mineral Point",
  },
  {
    postal_code: "85619",
    full_city_name: "Mount Lemmon, AZ",
    city_name: "Mount Lemmon",
  },
  {
    postal_code: "04455",
    full_city_name: "Lee, ME",
    city_name: "Lee",
  },
  {
    postal_code: "24185",
    full_city_name: "Woolwine, VA",
    city_name: "Woolwine",
  },
  {
    postal_code: "38948",
    full_city_name: "Oakland, MS",
    city_name: "Oakland",
  },
  {
    postal_code: "38674",
    full_city_name: "Tiplersville, MS",
    city_name: "Tiplersville",
  },
  {
    postal_code: "27229",
    full_city_name: "Candor, NC",
    city_name: "Candor",
  },
  {
    postal_code: "34140",
    full_city_name: "Goodland, FL",
    city_name: "Goodland",
  },
  {
    postal_code: "27846",
    full_city_name: "Jamesville, NC",
    city_name: "Jamesville",
  },
  {
    postal_code: "83226",
    full_city_name: "Challis, ID",
    city_name: "Challis",
  },
  {
    postal_code: "87510",
    full_city_name: "Abiquiu, NM",
    city_name: "Abiquiu",
  },
  {
    postal_code: "32430",
    full_city_name: "Clarksville, FL",
    city_name: "Clarksville",
  },
  {
    postal_code: "17037",
    full_city_name: "Ickesburg, PA",
    city_name: "Ickesburg",
  },
  {
    postal_code: "57216",
    full_city_name: "Big Stone City, SD",
    city_name: "Big Stone City",
  },
  {
    postal_code: "77622",
    full_city_name: "Hamshire, TX",
    city_name: "Hamshire",
  },
  {
    postal_code: "44807",
    full_city_name: "Attica, OH",
    city_name: "Attica",
  },
  {
    postal_code: "49095",
    full_city_name: "Vandalia, MI",
    city_name: "Vandalia",
  },
  {
    postal_code: "26542",
    full_city_name: "Masontown, WV",
    city_name: "Masontown",
  },
  {
    postal_code: "95735",
    full_city_name: "Twin Bridges, CA",
    city_name: "Twin Bridges",
  },
  {
    postal_code: "43432",
    full_city_name: "Graytown, OH",
    city_name: "Graytown",
  },
  {
    postal_code: "63056",
    full_city_name: "Leslie, MO",
    city_name: "Leslie",
  },
  {
    postal_code: "41858",
    full_city_name: "Whitesburg, KY",
    city_name: "Whitesburg",
  },
  {
    postal_code: "35989",
    full_city_name: "Valley Head, AL",
    city_name: "Valley Head",
  },
  {
    postal_code: "18932",
    full_city_name: "Line Lexington, PA",
    city_name: "Line Lexington",
  },
  {
    postal_code: "52728",
    full_city_name: "Buffalo, IA",
    city_name: "Buffalo",
  },
  {
    postal_code: "51560",
    full_city_name: "Oakland, IA",
    city_name: "Oakland",
  },
  {
    postal_code: "43720",
    full_city_name: "Blue Rock, OH",
    city_name: "Blue Rock",
  },
  {
    postal_code: "10522",
    full_city_name: "Dobbs Ferry, NY",
    city_name: "Dobbs Ferry",
  },
  {
    postal_code: "57107",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "36083",
    full_city_name: "Tuskegee, AL",
    city_name: "Tuskegee",
  },
  {
    postal_code: "46540",
    full_city_name: "Middlebury, IN",
    city_name: "Middlebury",
  },
  {
    postal_code: "32626",
    full_city_name: "Chiefland, FL",
    city_name: "Chiefland",
  },
  {
    postal_code: "39350",
    full_city_name: "Philadelphia, MS",
    city_name: "Philadelphia",
  },
  {
    postal_code: "04694",
    full_city_name: "Baileyville, ME",
    city_name: "Baileyville",
  },
  {
    postal_code: "86025",
    full_city_name: "Holbrook, AZ",
    city_name: "Holbrook",
  },
  {
    postal_code: "62401",
    full_city_name: "Effingham, IL",
    city_name: "Effingham",
  },
  {
    postal_code: "08001",
    full_city_name: "Alloway, NJ",
    city_name: "Alloway",
  },
  {
    postal_code: "07848",
    full_city_name: "Lafayette, NJ",
    city_name: "Lafayette",
  },
  {
    postal_code: "18643",
    full_city_name: "Pittston, PA",
    city_name: "Pittston",
  },
  {
    postal_code: "04419",
    full_city_name: "Carmel, ME",
    city_name: "Carmel",
  },
  {
    postal_code: "04087",
    full_city_name: "Waterboro, ME",
    city_name: "Waterboro",
  },
  {
    postal_code: "23454",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "31533",
    full_city_name: "Douglas, GA",
    city_name: "Douglas",
  },
  {
    postal_code: "46582",
    full_city_name: "Warsaw, IN",
    city_name: "Warsaw",
  },
  {
    postal_code: "61467",
    full_city_name: "Oneida, IL",
    city_name: "Oneida",
  },
  {
    postal_code: "74085",
    full_city_name: "Yale, OK",
    city_name: "Yale",
  },
  {
    postal_code: "68503",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "18621",
    full_city_name: "Hunlock Creek, PA",
    city_name: "Hunlock Creek",
  },
  {
    postal_code: "99323",
    full_city_name: "Burbank, WA",
    city_name: "Burbank",
  },
  {
    postal_code: "95521",
    full_city_name: "Arcata, CA",
    city_name: "Arcata",
  },
  {
    postal_code: "83101",
    full_city_name: "Kemmerer, WY",
    city_name: "Kemmerer",
  },
  {
    postal_code: "83855",
    full_city_name: "Potlatch, ID",
    city_name: "Potlatch",
  },
  {
    postal_code: "76945",
    full_city_name: "Robert Lee, TX",
    city_name: "Robert Lee",
  },
  {
    postal_code: "17830",
    full_city_name: "Herndon, PA",
    city_name: "Herndon",
  },
  {
    postal_code: "87402",
    full_city_name: "Farmington, NM",
    city_name: "Farmington",
  },
  {
    postal_code: "60460",
    full_city_name: "Odell, IL",
    city_name: "Odell",
  },
  {
    postal_code: "14772",
    full_city_name: "Randolph, NY",
    city_name: "Randolph",
  },
  {
    postal_code: "48207",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "32531",
    full_city_name: "Baker, FL",
    city_name: "Baker",
  },
  {
    postal_code: "17960",
    full_city_name: "New Ringgold, PA",
    city_name: "New Ringgold",
  },
  {
    postal_code: "62677",
    full_city_name: "Pleasant Plains, IL",
    city_name: "Pleasant Plains",
  },
  {
    postal_code: "72853",
    full_city_name: "Ola, AR",
    city_name: "Ola",
  },
  {
    postal_code: "46133",
    full_city_name: "Glenwood, IN",
    city_name: "Glenwood",
  },
  {
    postal_code: "19946",
    full_city_name: "Frederica, DE",
    city_name: "Frederica",
  },
  {
    postal_code: "25186",
    full_city_name: "Smithers, WV",
    city_name: "Smithers",
  },
  {
    postal_code: "48729",
    full_city_name: "Deford, MI",
    city_name: "Deford",
  },
  {
    postal_code: "24448",
    full_city_name: "Iron Gate, VA",
    city_name: "Iron Gate",
  },
  {
    postal_code: "04010",
    full_city_name: "Brownfield, ME",
    city_name: "Brownfield",
  },
  {
    postal_code: "66771",
    full_city_name: "Saint Paul, KS",
    city_name: "Saint Paul",
  },
  {
    postal_code: "17731",
    full_city_name: "Eagles Mere, PA",
    city_name: "Eagles Mere",
  },
  {
    postal_code: "47970",
    full_city_name: "Otterbein, IN",
    city_name: "Otterbein",
  },
  {
    postal_code: "16928",
    full_city_name: "Knoxville, PA",
    city_name: "Knoxville",
  },
  {
    postal_code: "97541",
    full_city_name: "Trail, OR",
    city_name: "Trail",
  },
  {
    postal_code: "57270",
    full_city_name: "Veblen, SD",
    city_name: "Veblen",
  },
  {
    postal_code: "61260",
    full_city_name: "Joy, IL",
    city_name: "Joy",
  },
  {
    postal_code: "56744",
    full_city_name: "Oslo, MN",
    city_name: "Oslo",
  },
  {
    postal_code: "30446",
    full_city_name: "Newington, GA",
    city_name: "Newington",
  },
  {
    postal_code: "14809",
    full_city_name: "Avoca, NY",
    city_name: "Avoca",
  },
  {
    postal_code: "75943",
    full_city_name: "Douglass, TX",
    city_name: "Douglass",
  },
  {
    postal_code: "52549",
    full_city_name: "Cincinnati, IA",
    city_name: "Cincinnati",
  },
  {
    postal_code: "68047",
    full_city_name: "Pender, NE",
    city_name: "Pender",
  },
  {
    postal_code: "17970",
    full_city_name: "Saint Clair, PA",
    city_name: "Saint Clair",
  },
  {
    postal_code: "56284",
    full_city_name: "Renville, MN",
    city_name: "Renville",
  },
  {
    postal_code: "79852",
    full_city_name: "Terlingua, TX",
    city_name: "Terlingua",
  },
  {
    postal_code: "17728",
    full_city_name: "Cogan Station, PA",
    city_name: "Cogan Station",
  },
  {
    postal_code: "47531",
    full_city_name: "Evanston, IN",
    city_name: "Evanston",
  },
  {
    postal_code: "36016",
    full_city_name: "Clayton, AL",
    city_name: "Clayton",
  },
  {
    postal_code: "15722",
    full_city_name: "Carrolltown, PA",
    city_name: "Carrolltown",
  },
  {
    postal_code: "49043",
    full_city_name: "Covert, MI",
    city_name: "Covert",
  },
  {
    postal_code: "73149",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "47578",
    full_city_name: "Sandborn, IN",
    city_name: "Sandborn",
  },
  {
    postal_code: "14505",
    full_city_name: "Marion, NY",
    city_name: "Marion",
  },
  {
    postal_code: "53137",
    full_city_name: "Helenville, WI",
    city_name: "Helenville",
  },
  {
    postal_code: "46767",
    full_city_name: "Ligonier, IN",
    city_name: "Ligonier",
  },
  {
    postal_code: "15056",
    full_city_name: "Leetsdale, PA",
    city_name: "Leetsdale",
  },
  {
    postal_code: "01531",
    full_city_name: "New Braintree, MA",
    city_name: "New Braintree",
  },
  {
    postal_code: "82055",
    full_city_name: "Centennial, WY",
    city_name: "Centennial",
  },
  {
    postal_code: "21524",
    full_city_name: "Corriganville, MD",
    city_name: "Corriganville",
  },
  {
    postal_code: "05076",
    full_city_name: "Topsham, VT",
    city_name: "Topsham",
  },
  {
    postal_code: "40022",
    full_city_name: "Finchville, KY",
    city_name: "Finchville",
  },
  {
    postal_code: "68815",
    full_city_name: "Arcadia, NE",
    city_name: "Arcadia",
  },
  {
    postal_code: "71429",
    full_city_name: "Florien, LA",
    city_name: "Florien",
  },
  {
    postal_code: "44082",
    full_city_name: "Pierpont, OH",
    city_name: "Pierpont",
  },
  {
    postal_code: "68633",
    full_city_name: "Dodge, NE",
    city_name: "Dodge",
  },
  {
    postal_code: "27978",
    full_city_name: "Stumpy Point, NC",
    city_name: "Stumpy Point",
  },
  {
    postal_code: "04674",
    full_city_name: "Seal Cove, ME",
    city_name: "Seal Cove",
  },
  {
    postal_code: "31068",
    full_city_name: "Oglethorpe, GA",
    city_name: "Oglethorpe",
  },
  {
    postal_code: "04029",
    full_city_name: "Sebago, ME",
    city_name: "Sebago",
  },
  {
    postal_code: "53804",
    full_city_name: "Bloomington, WI",
    city_name: "Bloomington",
  },
  {
    postal_code: "50214",
    full_city_name: "Otley, IA",
    city_name: "Otley",
  },
  {
    postal_code: "40737",
    full_city_name: "Keavy, KY",
    city_name: "Keavy",
  },
  {
    postal_code: "13301",
    full_city_name: "Alder Creek, NY",
    city_name: "Alder Creek",
  },
  {
    postal_code: "68343",
    full_city_name: "Dorchester, NE",
    city_name: "Dorchester",
  },
  {
    postal_code: "14504",
    full_city_name: "Manchester, NY",
    city_name: "Manchester",
  },
  {
    postal_code: "79081",
    full_city_name: "Spearman, TX",
    city_name: "Spearman",
  },
  {
    postal_code: "08853",
    full_city_name: "Neshanic Station, NJ",
    city_name: "Neshanic Station",
  },
  {
    postal_code: "08720",
    full_city_name: "Allenwood, NJ",
    city_name: "Allenwood",
  },
  {
    postal_code: "78066",
    full_city_name: "Rio Medina, TX",
    city_name: "Rio Medina",
  },
  {
    postal_code: "01745",
    full_city_name: "Fayville, MA",
    city_name: "Fayville",
  },
  {
    postal_code: "02715",
    full_city_name: "Dighton, MA",
    city_name: "Dighton",
  },
  {
    postal_code: "29074",
    full_city_name: "Liberty Hill, SC",
    city_name: "Liberty Hill",
  },
  {
    postal_code: "02464",
    full_city_name: "Newton Upper Falls, MA",
    city_name: "Newton Upper Falls",
  },
  {
    postal_code: "75468",
    full_city_name: "Pattonville, TX",
    city_name: "Pattonville",
  },
  {
    postal_code: "08323",
    full_city_name: "Greenwich, NJ",
    city_name: "Greenwich",
  },
  {
    postal_code: "92398",
    full_city_name: "Yermo, CA",
    city_name: "Yermo",
  },
  {
    postal_code: "51458",
    full_city_name: "Odebolt, IA",
    city_name: "Odebolt",
  },
  {
    postal_code: "64679",
    full_city_name: "Spickard, MO",
    city_name: "Spickard",
  },
  {
    postal_code: "16647",
    full_city_name: "Hesston, PA",
    city_name: "Hesston",
  },
  {
    postal_code: "01542",
    full_city_name: "Rochdale, MA",
    city_name: "Rochdale",
  },
  {
    postal_code: "82945",
    full_city_name: "Superior, WY",
    city_name: "Superior",
  },
  {
    postal_code: "97868",
    full_city_name: "Pilot Rock, OR",
    city_name: "Pilot Rock",
  },
  {
    postal_code: "25570",
    full_city_name: "Wayne, WV",
    city_name: "Wayne",
  },
  {
    postal_code: "35760",
    full_city_name: "New Hope, AL",
    city_name: "New Hope",
  },
  {
    postal_code: "65285",
    full_city_name: "Thompson, MO",
    city_name: "Thompson",
  },
  {
    postal_code: "21865",
    full_city_name: "Tyaskin, MD",
    city_name: "Tyaskin",
  },
  {
    postal_code: "56475",
    full_city_name: "Randall, MN",
    city_name: "Randall",
  },
  {
    postal_code: "04978",
    full_city_name: "Smithfield, ME",
    city_name: "Smithfield",
  },
  {
    postal_code: "69140",
    full_city_name: "Grant, NE",
    city_name: "Grant",
  },
  {
    postal_code: "21555",
    full_city_name: "Oldtown, MD",
    city_name: "Oldtown",
  },
  {
    postal_code: "47971",
    full_city_name: "Oxford, IN",
    city_name: "Oxford",
  },
  {
    postal_code: "64748",
    full_city_name: "Golden City, MO",
    city_name: "Golden City",
  },
  {
    postal_code: "42286",
    full_city_name: "Trenton, KY",
    city_name: "Trenton",
  },
  {
    postal_code: "57053",
    full_city_name: "Parker, SD",
    city_name: "Parker",
  },
  {
    postal_code: "99752",
    full_city_name: "Kotzebue, AK",
    city_name: "Kotzebue",
  },
  {
    postal_code: "50027",
    full_city_name: "Barnes City, IA",
    city_name: "Barnes City",
  },
  {
    postal_code: "73433",
    full_city_name: "Elmore City, OK",
    city_name: "Elmore City",
  },
  {
    postal_code: "61459",
    full_city_name: "Marietta, IL",
    city_name: "Marietta",
  },
  {
    postal_code: "80860",
    full_city_name: "Victor, CO",
    city_name: "Victor",
  },
  {
    postal_code: "12176",
    full_city_name: "Surprise, NY",
    city_name: "Surprise",
  },
  {
    postal_code: "11958",
    full_city_name: "Peconic, NY",
    city_name: "Peconic",
  },
  {
    postal_code: "17810",
    full_city_name: "Allenwood, PA",
    city_name: "Allenwood",
  },
  {
    postal_code: "53812",
    full_city_name: "Kieler, WI",
    city_name: "Kieler",
  },
  {
    postal_code: "49884",
    full_city_name: "Shingleton, MI",
    city_name: "Shingleton",
  },
  {
    postal_code: "54157",
    full_city_name: "Peshtigo, WI",
    city_name: "Peshtigo",
  },
  {
    postal_code: "12936",
    full_city_name: "Essex, NY",
    city_name: "Essex",
  },
  {
    postal_code: "98385",
    full_city_name: "South Prairie, WA",
    city_name: "South Prairie",
  },
  {
    postal_code: "99137",
    full_city_name: "Hunters, WA",
    city_name: "Hunters",
  },
  {
    postal_code: "96728",
    full_city_name: "Honomu, HI",
    city_name: "Honomu",
  },
  {
    postal_code: "43824",
    full_city_name: "Fresno, OH",
    city_name: "Fresno",
  },
  {
    postal_code: "51009",
    full_city_name: "Calumet, IA",
    city_name: "Calumet",
  },
  {
    postal_code: "61439",
    full_city_name: "Henderson, IL",
    city_name: "Henderson",
  },
  {
    postal_code: "68316",
    full_city_name: "Benedict, NE",
    city_name: "Benedict",
  },
  {
    postal_code: "24350",
    full_city_name: "Ivanhoe, VA",
    city_name: "Ivanhoe",
  },
  {
    postal_code: "26287",
    full_city_name: "Parsons, WV",
    city_name: "Parsons",
  },
  {
    postal_code: "36006",
    full_city_name: "Billingsley, AL",
    city_name: "Billingsley",
  },
  {
    postal_code: "66540",
    full_city_name: "Soldier, KS",
    city_name: "Soldier",
  },
  {
    postal_code: "16878",
    full_city_name: "West Decatur, PA",
    city_name: "West Decatur",
  },
  {
    postal_code: "66953",
    full_city_name: "Linn, KS",
    city_name: "Linn",
  },
  {
    postal_code: "80436",
    full_city_name: "Dumont, CO",
    city_name: "Dumont",
  },
  {
    postal_code: "72461",
    full_city_name: "Rector, AR",
    city_name: "Rector",
  },
  {
    postal_code: "75668",
    full_city_name: "Lone Star, TX",
    city_name: "Lone Star",
  },
  {
    postal_code: "05875",
    full_city_name: "Barton, VT",
    city_name: "Barton",
  },
  {
    postal_code: "47436",
    full_city_name: "Heltonville, IN",
    city_name: "Heltonville",
  },
  {
    postal_code: "81433",
    full_city_name: "Silverton, CO",
    city_name: "Silverton",
  },
  {
    postal_code: "31738",
    full_city_name: "Coolidge, GA",
    city_name: "Coolidge",
  },
  {
    postal_code: "44818",
    full_city_name: "Bloomville, OH",
    city_name: "Bloomville",
  },
  {
    postal_code: "28071",
    full_city_name: "Gold Hill, NC",
    city_name: "Gold Hill",
  },
  {
    postal_code: "75471",
    full_city_name: "Pickton, TX",
    city_name: "Pickton",
  },
  {
    postal_code: "53554",
    full_city_name: "Livingston, WI",
    city_name: "Livingston",
  },
  {
    postal_code: "66549",
    full_city_name: "Westmoreland, KS",
    city_name: "Westmoreland",
  },
  {
    postal_code: "03043",
    full_city_name: "Francestown, NH",
    city_name: "Francestown",
  },
  {
    postal_code: "47451",
    full_city_name: "Oolitic, IN",
    city_name: "Oolitic",
  },
  {
    postal_code: "48454",
    full_city_name: "Melvin, MI",
    city_name: "Melvin",
  },
  {
    postal_code: "25059",
    full_city_name: "Dixie, WV",
    city_name: "Dixie",
  },
  {
    postal_code: "41174",
    full_city_name: "South Portsmouth, KY",
    city_name: "South Portsmouth",
  },
  {
    postal_code: "04568",
    full_city_name: "South Bristol, ME",
    city_name: "South Bristol",
  },
  {
    postal_code: "71466",
    full_city_name: "Otis, LA",
    city_name: "Otis",
  },
  {
    postal_code: "53553",
    full_city_name: "Linden, WI",
    city_name: "Linden",
  },
  {
    postal_code: "75102",
    full_city_name: "Barry, TX",
    city_name: "Barry",
  },
  {
    postal_code: "56756",
    full_city_name: "Salol, MN",
    city_name: "Salol",
  },
  {
    postal_code: "56724",
    full_city_name: "Gatzke, MN",
    city_name: "Gatzke",
  },
  {
    postal_code: "64638",
    full_city_name: "Dawn, MO",
    city_name: "Dawn",
  },
  {
    postal_code: "71825",
    full_city_name: "Blevins, AR",
    city_name: "Blevins",
  },
  {
    postal_code: "47373",
    full_city_name: "Redkey, IN",
    city_name: "Redkey",
  },
  {
    postal_code: "57366",
    full_city_name: "Parkston, SD",
    city_name: "Parkston",
  },
  {
    postal_code: "74553",
    full_city_name: "Kiowa, OK",
    city_name: "Kiowa",
  },
  {
    postal_code: "70759",
    full_city_name: "Morganza, LA",
    city_name: "Morganza",
  },
  {
    postal_code: "18936",
    full_city_name: "Montgomeryville, PA",
    city_name: "Montgomeryville",
  },
  {
    postal_code: "45301",
    full_city_name: "Alpha, OH",
    city_name: "Alpha",
  },
  {
    postal_code: "19352",
    full_city_name: "Lincoln University, PA",
    city_name: "Lincoln University",
  },
  {
    postal_code: "15338",
    full_city_name: "Greensboro, PA",
    city_name: "Greensboro",
  },
  {
    postal_code: "48860",
    full_city_name: "Muir, MI",
    city_name: "Muir",
  },
  {
    postal_code: "04547",
    full_city_name: "Friendship, ME",
    city_name: "Friendship",
  },
  {
    postal_code: "64421",
    full_city_name: "Amazonia, MO",
    city_name: "Amazonia",
  },
  {
    postal_code: "39338",
    full_city_name: "Louin, MS",
    city_name: "Louin",
  },
  {
    postal_code: "04450",
    full_city_name: "Kenduskeag, ME",
    city_name: "Kenduskeag",
  },
  {
    postal_code: "47381",
    full_city_name: "Salamonia, IN",
    city_name: "Salamonia",
  },
  {
    postal_code: "56657",
    full_city_name: "Marcell, MN",
    city_name: "Marcell",
  },
  {
    postal_code: "32466",
    full_city_name: "Youngstown, FL",
    city_name: "Youngstown",
  },
  {
    postal_code: "15468",
    full_city_name: "New Salem, PA",
    city_name: "New Salem",
  },
  {
    postal_code: "52041",
    full_city_name: "Earlville, IA",
    city_name: "Earlville",
  },
  {
    postal_code: "69146",
    full_city_name: "Lemoyne, NE",
    city_name: "Lemoyne",
  },
  {
    postal_code: "12530",
    full_city_name: "Hollowville, NY",
    city_name: "Hollowville",
  },
  {
    postal_code: "62992",
    full_city_name: "Ullin, IL",
    city_name: "Ullin",
  },
  {
    postal_code: "72327",
    full_city_name: "Crawfordsville, AR",
    city_name: "Crawfordsville",
  },
  {
    postal_code: "62611",
    full_city_name: "Arenzville, IL",
    city_name: "Arenzville",
  },
  {
    postal_code: "29821",
    full_city_name: "Clarks Hill, SC",
    city_name: "Clarks Hill",
  },
  {
    postal_code: "25239",
    full_city_name: "Cottageville, WV",
    city_name: "Cottageville",
  },
  {
    postal_code: "41046",
    full_city_name: "Glencoe, KY",
    city_name: "Glencoe",
  },
  {
    postal_code: "80649",
    full_city_name: "Orchard, CO",
    city_name: "Orchard",
  },
  {
    postal_code: "15846",
    full_city_name: "Kersey, PA",
    city_name: "Kersey",
  },
  {
    postal_code: "40046",
    full_city_name: "Mount Eden, KY",
    city_name: "Mount Eden",
  },
  {
    postal_code: "52135",
    full_city_name: "Clermont, IA",
    city_name: "Clermont",
  },
  {
    postal_code: "97345",
    full_city_name: "Foster, OR",
    city_name: "Foster",
  },
  {
    postal_code: "49918",
    full_city_name: "Copper Harbor, MI",
    city_name: "Copper Harbor",
  },
  {
    postal_code: "70514",
    full_city_name: "Baldwin, LA",
    city_name: "Baldwin",
  },
  {
    postal_code: "96061",
    full_city_name: "Mill Creek, CA",
    city_name: "Mill Creek",
  },
  {
    postal_code: "27252",
    full_city_name: "Goldston, NC",
    city_name: "Goldston",
  },
  {
    postal_code: "05736",
    full_city_name: "Center Rutland, VT",
    city_name: "Center Rutland",
  },
  {
    postal_code: "47384",
    full_city_name: "Shirley, IN",
    city_name: "Shirley",
  },
  {
    postal_code: "29475",
    full_city_name: "Ruffin, SC",
    city_name: "Ruffin",
  },
  {
    postal_code: "04924",
    full_city_name: "Canaan, ME",
    city_name: "Canaan",
  },
  {
    postal_code: "15765",
    full_city_name: "Penn Run, PA",
    city_name: "Penn Run",
  },
  {
    postal_code: "66735",
    full_city_name: "Franklin, KS",
    city_name: "Franklin",
  },
  {
    postal_code: "16350",
    full_city_name: "Sugar Grove, PA",
    city_name: "Sugar Grove",
  },
  {
    postal_code: "97732",
    full_city_name: "Crane, OR",
    city_name: "Crane",
  },
  {
    postal_code: "66023",
    full_city_name: "Effingham, KS",
    city_name: "Effingham",
  },
  {
    postal_code: "64494",
    full_city_name: "Union Star, MO",
    city_name: "Union Star",
  },
  {
    postal_code: "69347",
    full_city_name: "Hay Springs, NE",
    city_name: "Hay Springs",
  },
  {
    postal_code: "62806",
    full_city_name: "Albion, IL",
    city_name: "Albion",
  },
  {
    postal_code: "45896",
    full_city_name: "Waynesfield, OH",
    city_name: "Waynesfield",
  },
  {
    postal_code: "55367",
    full_city_name: "New Germany, MN",
    city_name: "New Germany",
  },
  {
    postal_code: "50230",
    full_city_name: "Radcliffe, IA",
    city_name: "Radcliffe",
  },
  {
    postal_code: "15034",
    full_city_name: "Dravosburg, PA",
    city_name: "Dravosburg",
  },
  {
    postal_code: "87056",
    full_city_name: "Stanley, NM",
    city_name: "Stanley",
  },
  {
    postal_code: "47994",
    full_city_name: "Wingate, IN",
    city_name: "Wingate",
  },
  {
    postal_code: "17562",
    full_city_name: "Paradise, PA",
    city_name: "Paradise",
  },
  {
    postal_code: "49836",
    full_city_name: "Germfask, MI",
    city_name: "Germfask",
  },
  {
    postal_code: "65247",
    full_city_name: "Excello, MO",
    city_name: "Excello",
  },
  {
    postal_code: "25442",
    full_city_name: "Shenandoah Junction, WV",
    city_name: "Shenandoah Junction",
  },
  {
    postal_code: "71922",
    full_city_name: "Antoine, AR",
    city_name: "Antoine",
  },
  {
    postal_code: "15047",
    full_city_name: "Greenock, PA",
    city_name: "Greenock",
  },
  {
    postal_code: "50168",
    full_city_name: "Mingo, IA",
    city_name: "Mingo",
  },
  {
    postal_code: "15952",
    full_city_name: "Salix, PA",
    city_name: "Salix",
  },
  {
    postal_code: "05873",
    full_city_name: "West Danville, VT",
    city_name: "West Danville",
  },
  {
    postal_code: "74574",
    full_city_name: "Tuskahoma, OK",
    city_name: "Tuskahoma",
  },
  {
    postal_code: "05032",
    full_city_name: "Bethel, VT",
    city_name: "Bethel",
  },
  {
    postal_code: "62957",
    full_city_name: "Mc Clure, IL",
    city_name: "Mc Clure",
  },
  {
    postal_code: "77835",
    full_city_name: "Burton, TX",
    city_name: "Burton",
  },
  {
    postal_code: "20656",
    full_city_name: "Loveville, MD",
    city_name: "Loveville",
  },
  {
    postal_code: "58231",
    full_city_name: "Fordville, ND",
    city_name: "Fordville",
  },
  {
    postal_code: "45317",
    full_city_name: "Conover, OH",
    city_name: "Conover",
  },
  {
    postal_code: "51463",
    full_city_name: "Templeton, IA",
    city_name: "Templeton",
  },
  {
    postal_code: "13490",
    full_city_name: "Westmoreland, NY",
    city_name: "Westmoreland",
  },
  {
    postal_code: "39750",
    full_city_name: "Maben, MS",
    city_name: "Maben",
  },
  {
    postal_code: "44669",
    full_city_name: "Paris, OH",
    city_name: "Paris",
  },
  {
    postal_code: "66746",
    full_city_name: "Hepler, KS",
    city_name: "Hepler",
  },
  {
    postal_code: "61817",
    full_city_name: "Catlin, IL",
    city_name: "Catlin",
  },
  {
    postal_code: "47272",
    full_city_name: "Saint Paul, IN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "17026",
    full_city_name: "Fredericksburg, PA",
    city_name: "Fredericksburg",
  },
  {
    postal_code: "12449",
    full_city_name: "Lake Katrine, NY",
    city_name: "Lake Katrine",
  },
  {
    postal_code: "71730",
    full_city_name: "El Dorado, AR",
    city_name: "El Dorado",
  },
  {
    postal_code: "47111",
    full_city_name: "Charlestown, IN",
    city_name: "Charlestown",
  },
  {
    postal_code: "32504",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "64683",
    full_city_name: "Trenton, MO",
    city_name: "Trenton",
  },
  {
    postal_code: "16345",
    full_city_name: "Russell, PA",
    city_name: "Russell",
  },
  {
    postal_code: "13672",
    full_city_name: "Parishville, NY",
    city_name: "Parishville",
  },
  {
    postal_code: "08091",
    full_city_name: "West Berlin, NJ",
    city_name: "West Berlin",
  },
  {
    postal_code: "07604",
    full_city_name: "Hasbrouck Heights, NJ",
    city_name: "Hasbrouck Heights",
  },
  {
    postal_code: "49740",
    full_city_name: "Harbor Springs, MI",
    city_name: "Harbor Springs",
  },
  {
    postal_code: "10603",
    full_city_name: "White Plains, NY",
    city_name: "White Plains",
  },
  {
    postal_code: "02568",
    full_city_name: "Vineyard Haven, MA",
    city_name: "Vineyard Haven",
  },
  {
    postal_code: "53110",
    full_city_name: "Cudahy, WI",
    city_name: "Cudahy",
  },
  {
    postal_code: "73131",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "54474",
    full_city_name: "Rothschild, WI",
    city_name: "Rothschild",
  },
  {
    postal_code: "19341",
    full_city_name: "Exton, PA",
    city_name: "Exton",
  },
  {
    postal_code: "89120",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "22716",
    full_city_name: "Castleton, VA",
    city_name: "Castleton",
  },
  {
    postal_code: "54979",
    full_city_name: "Van Dyne, WI",
    city_name: "Van Dyne",
  },
  {
    postal_code: "04949",
    full_city_name: "Liberty, ME",
    city_name: "Liberty",
  },
  {
    postal_code: "87036",
    full_city_name: "Mountainair, NM",
    city_name: "Mountainair",
  },
  {
    postal_code: "67210",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "45833",
    full_city_name: "Delphos, OH",
    city_name: "Delphos",
  },
  {
    postal_code: "81236",
    full_city_name: "Nathrop, CO",
    city_name: "Nathrop",
  },
  {
    postal_code: "36340",
    full_city_name: "Geneva, AL",
    city_name: "Geneva",
  },
  {
    postal_code: "74103",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "54627",
    full_city_name: "Ettrick, WI",
    city_name: "Ettrick",
  },
  {
    postal_code: "26147",
    full_city_name: "Grantsville, WV",
    city_name: "Grantsville",
  },
  {
    postal_code: "04779",
    full_city_name: "Sinclair, ME",
    city_name: "Sinclair",
  },
  {
    postal_code: "19541",
    full_city_name: "Mohrsville, PA",
    city_name: "Mohrsville",
  },
  {
    postal_code: "97913",
    full_city_name: "Nyssa, OR",
    city_name: "Nyssa",
  },
  {
    postal_code: "88350",
    full_city_name: "Timberon, NM",
    city_name: "Timberon",
  },
  {
    postal_code: "46809",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "51461",
    full_city_name: "Schleswig, IA",
    city_name: "Schleswig",
  },
  {
    postal_code: "59928",
    full_city_name: "Polebridge, MT",
    city_name: "Polebridge",
  },
  {
    postal_code: "36603",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "29691",
    full_city_name: "Walhalla, SC",
    city_name: "Walhalla",
  },
  {
    postal_code: "26456",
    full_city_name: "West Union, WV",
    city_name: "West Union",
  },
  {
    postal_code: "24970",
    full_city_name: "Ronceverte, WV",
    city_name: "Ronceverte",
  },
  {
    postal_code: "07512",
    full_city_name: "Totowa, NJ",
    city_name: "Totowa",
  },
  {
    postal_code: "49329",
    full_city_name: "Howard City, MI",
    city_name: "Howard City",
  },
  {
    postal_code: "17820",
    full_city_name: "Catawissa, PA",
    city_name: "Catawissa",
  },
  {
    postal_code: "49640",
    full_city_name: "Honor, MI",
    city_name: "Honor",
  },
  {
    postal_code: "69210",
    full_city_name: "Ainsworth, NE",
    city_name: "Ainsworth",
  },
  {
    postal_code: "54426",
    full_city_name: "Edgar, WI",
    city_name: "Edgar",
  },
  {
    postal_code: "17921",
    full_city_name: "Ashland, PA",
    city_name: "Ashland",
  },
  {
    postal_code: "13652",
    full_city_name: "Hermon, NY",
    city_name: "Hermon",
  },
  {
    postal_code: "47648",
    full_city_name: "Fort Branch, IN",
    city_name: "Fort Branch",
  },
  {
    postal_code: "45616",
    full_city_name: "Blue Creek, OH",
    city_name: "Blue Creek",
  },
  {
    postal_code: "29053",
    full_city_name: "Gaston, SC",
    city_name: "Gaston",
  },
  {
    postal_code: "49838",
    full_city_name: "Gould City, MI",
    city_name: "Gould City",
  },
  {
    postal_code: "74954",
    full_city_name: "Roland, OK",
    city_name: "Roland",
  },
  {
    postal_code: "81430",
    full_city_name: "Placerville, CO",
    city_name: "Placerville",
  },
  {
    postal_code: "18034",
    full_city_name: "Center Valley, PA",
    city_name: "Center Valley",
  },
  {
    postal_code: "35616",
    full_city_name: "Cherokee, AL",
    city_name: "Cherokee",
  },
  {
    postal_code: "21053",
    full_city_name: "Freeland, MD",
    city_name: "Freeland",
  },
  {
    postal_code: "70778",
    full_city_name: "Sorrento, LA",
    city_name: "Sorrento",
  },
  {
    postal_code: "24064",
    full_city_name: "Blue Ridge, VA",
    city_name: "Blue Ridge",
  },
  {
    postal_code: "83536",
    full_city_name: "Kamiah, ID",
    city_name: "Kamiah",
  },
  {
    postal_code: "38666",
    full_city_name: "Sardis, MS",
    city_name: "Sardis",
  },
  {
    postal_code: "71202",
    full_city_name: "Monroe, LA",
    city_name: "Monroe",
  },
  {
    postal_code: "65769",
    full_city_name: "Verona, MO",
    city_name: "Verona",
  },
  {
    postal_code: "01506",
    full_city_name: "Brookfield, MA",
    city_name: "Brookfield",
  },
  {
    postal_code: "73067",
    full_city_name: "Ninnekah, OK",
    city_name: "Ninnekah",
  },
  {
    postal_code: "49237",
    full_city_name: "Concord, MI",
    city_name: "Concord",
  },
  {
    postal_code: "99639",
    full_city_name: "Ninilchik, AK",
    city_name: "Ninilchik",
  },
  {
    postal_code: "16242",
    full_city_name: "New Bethlehem, PA",
    city_name: "New Bethlehem",
  },
  {
    postal_code: "76354",
    full_city_name: "Burkburnett, TX",
    city_name: "Burkburnett",
  },
  {
    postal_code: "75954",
    full_city_name: "Joaquin, TX",
    city_name: "Joaquin",
  },
  {
    postal_code: "10457",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "04756",
    full_city_name: "Madawaska, ME",
    city_name: "Madawaska",
  },
  {
    postal_code: "96002",
    full_city_name: "Redding, CA",
    city_name: "Redding",
  },
  {
    postal_code: "23356",
    full_city_name: "Greenbackville, VA",
    city_name: "Greenbackville",
  },
  {
    postal_code: "07438",
    full_city_name: "Oak Ridge, NJ",
    city_name: "Oak Ridge",
  },
  {
    postal_code: "93260",
    full_city_name: "Posey, CA",
    city_name: "Posey",
  },
  {
    postal_code: "17540",
    full_city_name: "Leola, PA",
    city_name: "Leola",
  },
  {
    postal_code: "18655",
    full_city_name: "Shickshinny, PA",
    city_name: "Shickshinny",
  },
  {
    postal_code: "99320",
    full_city_name: "Benton City, WA",
    city_name: "Benton City",
  },
  {
    postal_code: "76853",
    full_city_name: "Lometa, TX",
    city_name: "Lometa",
  },
  {
    postal_code: "07876",
    full_city_name: "Succasunna, NJ",
    city_name: "Succasunna",
  },
  {
    postal_code: "67487",
    full_city_name: "Wakefield, KS",
    city_name: "Wakefield",
  },
  {
    postal_code: "81050",
    full_city_name: "La Junta, CO",
    city_name: "La Junta",
  },
  {
    postal_code: "47336",
    full_city_name: "Dunkirk, IN",
    city_name: "Dunkirk",
  },
  {
    postal_code: "52349",
    full_city_name: "Vinton, IA",
    city_name: "Vinton",
  },
  {
    postal_code: "72941",
    full_city_name: "Lavaca, AR",
    city_name: "Lavaca",
  },
  {
    postal_code: "21838",
    full_city_name: "Marion Station, MD",
    city_name: "Marion Station",
  },
  {
    postal_code: "04922",
    full_city_name: "Burnham, ME",
    city_name: "Burnham",
  },
  {
    postal_code: "80864",
    full_city_name: "Yoder, CO",
    city_name: "Yoder",
  },
  {
    postal_code: "96713",
    full_city_name: "Hana, HI",
    city_name: "Hana",
  },
  {
    postal_code: "40385",
    full_city_name: "Waco, KY",
    city_name: "Waco",
  },
  {
    postal_code: "19529",
    full_city_name: "Kempton, PA",
    city_name: "Kempton",
  },
  {
    postal_code: "48235",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "14303",
    full_city_name: "Niagara Falls, NY",
    city_name: "Niagara Falls",
  },
  {
    postal_code: "30467",
    full_city_name: "Sylvania, GA",
    city_name: "Sylvania",
  },
  {
    postal_code: "05053",
    full_city_name: "North Pomfret, VT",
    city_name: "North Pomfret",
  },
  {
    postal_code: "97467",
    full_city_name: "Reedsport, OR",
    city_name: "Reedsport",
  },
  {
    postal_code: "07430",
    full_city_name: "Mahwah, NJ",
    city_name: "Mahwah",
  },
  {
    postal_code: "73049",
    full_city_name: "Jones, OK",
    city_name: "Jones",
  },
  {
    postal_code: "68803",
    full_city_name: "Grand Island, NE",
    city_name: "Grand Island",
  },
  {
    postal_code: "14760",
    full_city_name: "Olean, NY",
    city_name: "Olean",
  },
  {
    postal_code: "32550",
    full_city_name: "Miramar Beach, FL",
    city_name: "Miramar Beach",
  },
  {
    postal_code: "19001",
    full_city_name: "Abington, PA",
    city_name: "Abington",
  },
  {
    postal_code: "53182",
    full_city_name: "Union Grove, WI",
    city_name: "Union Grove",
  },
  {
    postal_code: "35769",
    full_city_name: "Scottsboro, AL",
    city_name: "Scottsboro",
  },
  {
    postal_code: "89061",
    full_city_name: "Pahrump, NV",
    city_name: "Pahrump",
  },
  {
    postal_code: "61448",
    full_city_name: "Knoxville, IL",
    city_name: "Knoxville",
  },
  {
    postal_code: "14724",
    full_city_name: "Clymer, NY",
    city_name: "Clymer",
  },
  {
    postal_code: "12740",
    full_city_name: "Grahamsville, NY",
    city_name: "Grahamsville",
  },
  {
    postal_code: "16630",
    full_city_name: "Cresson, PA",
    city_name: "Cresson",
  },
  {
    postal_code: "26288",
    full_city_name: "Webster Springs, WV",
    city_name: "Webster Springs",
  },
  {
    postal_code: "12464",
    full_city_name: "Phoenicia, NY",
    city_name: "Phoenicia",
  },
  {
    postal_code: "13312",
    full_city_name: "Brantingham, NY",
    city_name: "Brantingham",
  },
  {
    postal_code: "73443",
    full_city_name: "Lone Grove, OK",
    city_name: "Lone Grove",
  },
  {
    postal_code: "30830",
    full_city_name: "Waynesboro, GA",
    city_name: "Waynesboro",
  },
  {
    postal_code: "08007",
    full_city_name: "Barrington, NJ",
    city_name: "Barrington",
  },
  {
    postal_code: "36441",
    full_city_name: "Flomaton, AL",
    city_name: "Flomaton",
  },
  {
    postal_code: "35956",
    full_city_name: "Boaz, AL",
    city_name: "Boaz",
  },
  {
    postal_code: "10803",
    full_city_name: "Pelham, NY",
    city_name: "Pelham",
  },
  {
    postal_code: "04743",
    full_city_name: "Fort Kent, ME",
    city_name: "Fort Kent",
  },
  {
    postal_code: "36312",
    full_city_name: "Ashford, AL",
    city_name: "Ashford",
  },
  {
    postal_code: "53049",
    full_city_name: "Malone, WI",
    city_name: "Malone",
  },
  {
    postal_code: "29033",
    full_city_name: "Cayce, SC",
    city_name: "Cayce",
  },
  {
    postal_code: "23453",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "39305",
    full_city_name: "Meridian, MS",
    city_name: "Meridian",
  },
  {
    postal_code: "35967",
    full_city_name: "Fort Payne, AL",
    city_name: "Fort Payne",
  },
  {
    postal_code: "23707",
    full_city_name: "Portsmouth, VA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "53012",
    full_city_name: "Cedarburg, WI",
    city_name: "Cedarburg",
  },
  {
    postal_code: "07087",
    full_city_name: "Union City, NJ",
    city_name: "Union City",
  },
  {
    postal_code: "83113",
    full_city_name: "Big Piney, WY",
    city_name: "Big Piney",
  },
  {
    postal_code: "35811",
    full_city_name: "Huntsville, AL",
    city_name: "Huntsville",
  },
  {
    postal_code: "30439",
    full_city_name: "Metter, GA",
    city_name: "Metter",
  },
  {
    postal_code: "84735",
    full_city_name: "Hatch, UT",
    city_name: "Hatch",
  },
  {
    postal_code: "68770",
    full_city_name: "Ponca, NE",
    city_name: "Ponca",
  },
  {
    postal_code: "83332",
    full_city_name: "Hagerman, ID",
    city_name: "Hagerman",
  },
  {
    postal_code: "48616",
    full_city_name: "Chesaning, MI",
    city_name: "Chesaning",
  },
  {
    postal_code: "29172",
    full_city_name: "West Columbia, SC",
    city_name: "West Columbia",
  },
  {
    postal_code: "54541",
    full_city_name: "Laona, WI",
    city_name: "Laona",
  },
  {
    postal_code: "66436",
    full_city_name: "Holton, KS",
    city_name: "Holton",
  },
  {
    postal_code: "47635",
    full_city_name: "Rockport, IN",
    city_name: "Rockport",
  },
  {
    postal_code: "01098",
    full_city_name: "Worthington, MA",
    city_name: "Worthington",
  },
  {
    postal_code: "61951",
    full_city_name: "Sullivan, IL",
    city_name: "Sullivan",
  },
  {
    postal_code: "95519",
    full_city_name: "Mckinleyville, CA",
    city_name: "Mckinleyville",
  },
  {
    postal_code: "72855",
    full_city_name: "Paris, AR",
    city_name: "Paris",
  },
  {
    postal_code: "07836",
    full_city_name: "Flanders, NJ",
    city_name: "Flanders",
  },
  {
    postal_code: "53073",
    full_city_name: "Plymouth, WI",
    city_name: "Plymouth",
  },
  {
    postal_code: "66757",
    full_city_name: "Neodesha, KS",
    city_name: "Neodesha",
  },
  {
    postal_code: "12721",
    full_city_name: "Bloomingburg, NY",
    city_name: "Bloomingburg",
  },
  {
    postal_code: "19602",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "67147",
    full_city_name: "Valley Center, KS",
    city_name: "Valley Center",
  },
  {
    postal_code: "58075",
    full_city_name: "Wahpeton, ND",
    city_name: "Wahpeton",
  },
  {
    postal_code: "73632",
    full_city_name: "Cordell, OK",
    city_name: "Cordell",
  },
  {
    postal_code: "54665",
    full_city_name: "Viroqua, WI",
    city_name: "Viroqua",
  },
  {
    postal_code: "46501",
    full_city_name: "Argos, IN",
    city_name: "Argos",
  },
  {
    postal_code: "70657",
    full_city_name: "Ragley, LA",
    city_name: "Ragley",
  },
  {
    postal_code: "83250",
    full_city_name: "Mccammon, ID",
    city_name: "Mccammon",
  },
  {
    postal_code: "47579",
    full_city_name: "Santa Claus, IN",
    city_name: "Santa Claus",
  },
  {
    postal_code: "04664",
    full_city_name: "Sullivan, ME",
    city_name: "Sullivan",
  },
  {
    postal_code: "26763",
    full_city_name: "Springfield, WV",
    city_name: "Springfield",
  },
  {
    postal_code: "49643",
    full_city_name: "Interlochen, MI",
    city_name: "Interlochen",
  },
  {
    postal_code: "68102",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "16353",
    full_city_name: "Tionesta, PA",
    city_name: "Tionesta",
  },
  {
    postal_code: "32533",
    full_city_name: "Cantonment, FL",
    city_name: "Cantonment",
  },
  {
    postal_code: "31793",
    full_city_name: "Tifton, GA",
    city_name: "Tifton",
  },
  {
    postal_code: "42717",
    full_city_name: "Burkesville, KY",
    city_name: "Burkesville",
  },
  {
    postal_code: "53402",
    full_city_name: "Racine, WI",
    city_name: "Racine",
  },
  {
    postal_code: "07416",
    full_city_name: "Franklin, NJ",
    city_name: "Franklin",
  },
  {
    postal_code: "48342",
    full_city_name: "Pontiac, MI",
    city_name: "Pontiac",
  },
  {
    postal_code: "16652",
    full_city_name: "Huntingdon, PA",
    city_name: "Huntingdon",
  },
  {
    postal_code: "97119",
    full_city_name: "Gaston, OR",
    city_name: "Gaston",
  },
  {
    postal_code: "71822",
    full_city_name: "Ashdown, AR",
    city_name: "Ashdown",
  },
  {
    postal_code: "63565",
    full_city_name: "Unionville, MO",
    city_name: "Unionville",
  },
  {
    postal_code: "07702",
    full_city_name: "Shrewsbury, NJ",
    city_name: "Shrewsbury",
  },
  {
    postal_code: "29054",
    full_city_name: "Gilbert, SC",
    city_name: "Gilbert",
  },
  {
    postal_code: "35570",
    full_city_name: "Hamilton, AL",
    city_name: "Hamilton",
  },
  {
    postal_code: "23451",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "68776",
    full_city_name: "South Sioux City, NE",
    city_name: "South Sioux City",
  },
  {
    postal_code: "07035",
    full_city_name: "Lincoln Park, NJ",
    city_name: "Lincoln Park",
  },
  {
    postal_code: "53104",
    full_city_name: "Bristol, WI",
    city_name: "Bristol",
  },
  {
    postal_code: "18977",
    full_city_name: "Washington Crossing, PA",
    city_name: "Washington Crossing",
  },
  {
    postal_code: "46933",
    full_city_name: "Gas City, IN",
    city_name: "Gas City",
  },
  {
    postal_code: "07202",
    full_city_name: "Elizabeth, NJ",
    city_name: "Elizabeth",
  },
  {
    postal_code: "48201",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "89085",
    full_city_name: "North Las Vegas, NV",
    city_name: "North Las Vegas",
  },
  {
    postal_code: "18610",
    full_city_name: "Blakeslee, PA",
    city_name: "Blakeslee",
  },
  {
    postal_code: "35565",
    full_city_name: "Haleyville, AL",
    city_name: "Haleyville",
  },
  {
    postal_code: "97539",
    full_city_name: "Shady Cove, OR",
    city_name: "Shady Cove",
  },
  {
    postal_code: "50662",
    full_city_name: "Oelwein, IA",
    city_name: "Oelwein",
  },
  {
    postal_code: "87714",
    full_city_name: "Cimarron, NM",
    city_name: "Cimarron",
  },
  {
    postal_code: "85924",
    full_city_name: "Concho, AZ",
    city_name: "Concho",
  },
  {
    postal_code: "27830",
    full_city_name: "Fremont, NC",
    city_name: "Fremont",
  },
  {
    postal_code: "18634",
    full_city_name: "Nanticoke, PA",
    city_name: "Nanticoke",
  },
  {
    postal_code: "98672",
    full_city_name: "White Salmon, WA",
    city_name: "White Salmon",
  },
  {
    postal_code: "18049",
    full_city_name: "Emmaus, PA",
    city_name: "Emmaus",
  },
  {
    postal_code: "48229",
    full_city_name: "Ecorse, MI",
    city_name: "Ecorse",
  },
  {
    postal_code: "79849",
    full_city_name: "San Elizario, TX",
    city_name: "San Elizario",
  },
  {
    postal_code: "10707",
    full_city_name: "Tuckahoe, NY",
    city_name: "Tuckahoe",
  },
  {
    postal_code: "24176",
    full_city_name: "Union Hall, VA",
    city_name: "Union Hall",
  },
  {
    postal_code: "36572",
    full_city_name: "Satsuma, AL",
    city_name: "Satsuma",
  },
  {
    postal_code: "02575",
    full_city_name: "West Tisbury, MA",
    city_name: "West Tisbury",
  },
  {
    postal_code: "47542",
    full_city_name: "Huntingburg, IN",
    city_name: "Huntingburg",
  },
  {
    postal_code: "26426",
    full_city_name: "Salem, WV",
    city_name: "Salem",
  },
  {
    postal_code: "08029",
    full_city_name: "Glendora, NJ",
    city_name: "Glendora",
  },
  {
    postal_code: "04255",
    full_city_name: "Greenwood, ME",
    city_name: "Greenwood",
  },
  {
    postal_code: "52224",
    full_city_name: "Dysart, IA",
    city_name: "Dysart",
  },
  {
    postal_code: "48079",
    full_city_name: "Saint Clair, MI",
    city_name: "Saint Clair",
  },
  {
    postal_code: "07452",
    full_city_name: "Glen Rock, NJ",
    city_name: "Glen Rock",
  },
  {
    postal_code: "31750",
    full_city_name: "Fitzgerald, GA",
    city_name: "Fitzgerald",
  },
  {
    postal_code: "04983",
    full_city_name: "Strong, ME",
    city_name: "Strong",
  },
  {
    postal_code: "46939",
    full_city_name: "Kewanna, IN",
    city_name: "Kewanna",
  },
  {
    postal_code: "48519",
    full_city_name: "Burton, MI",
    city_name: "Burton",
  },
  {
    postal_code: "97016",
    full_city_name: "Clatskanie, OR",
    city_name: "Clatskanie",
  },
  {
    postal_code: "71601",
    full_city_name: "Pine Bluff, AR",
    city_name: "Pine Bluff",
  },
  {
    postal_code: "83833",
    full_city_name: "Harrison, ID",
    city_name: "Harrison",
  },
  {
    postal_code: "36426",
    full_city_name: "Brewton, AL",
    city_name: "Brewton",
  },
  {
    postal_code: "47330",
    full_city_name: "Centerville, IN",
    city_name: "Centerville",
  },
  {
    postal_code: "39845",
    full_city_name: "Donalsonville, GA",
    city_name: "Donalsonville",
  },
  {
    postal_code: "10805",
    full_city_name: "New Rochelle, NY",
    city_name: "New Rochelle",
  },
  {
    postal_code: "68601",
    full_city_name: "Columbus, NE",
    city_name: "Columbus",
  },
  {
    postal_code: "53226",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "53191",
    full_city_name: "Williams Bay, WI",
    city_name: "Williams Bay",
  },
  {
    postal_code: "49774",
    full_city_name: "Pickford, MI",
    city_name: "Pickford",
  },
  {
    postal_code: "46845",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "46614",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "36606",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "67357",
    full_city_name: "Parsons, KS",
    city_name: "Parsons",
  },
  {
    postal_code: "54171",
    full_city_name: "Sobieski, WI",
    city_name: "Sobieski",
  },
  {
    postal_code: "70633",
    full_city_name: "Dequincy, LA",
    city_name: "Dequincy",
  },
  {
    postal_code: "08247",
    full_city_name: "Stone Harbor, NJ",
    city_name: "Stone Harbor",
  },
  {
    postal_code: "10804",
    full_city_name: "New Rochelle, NY",
    city_name: "New Rochelle",
  },
  {
    postal_code: "89158",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "46936",
    full_city_name: "Greentown, IN",
    city_name: "Greentown",
  },
  {
    postal_code: "49330",
    full_city_name: "Kent City, MI",
    city_name: "Kent City",
  },
  {
    postal_code: "14062",
    full_city_name: "Forestville, NY",
    city_name: "Forestville",
  },
  {
    postal_code: "07069",
    full_city_name: "Watchung, NJ",
    city_name: "Watchung",
  },
  {
    postal_code: "38641",
    full_city_name: "Lake Cormorant, MS",
    city_name: "Lake Cormorant",
  },
  {
    postal_code: "53582",
    full_city_name: "Ridgeway, WI",
    city_name: "Ridgeway",
  },
  {
    postal_code: "46994",
    full_city_name: "Walton, IN",
    city_name: "Walton",
  },
  {
    postal_code: "79838",
    full_city_name: "Fabens, TX",
    city_name: "Fabens",
  },
  {
    postal_code: "71292",
    full_city_name: "West Monroe, LA",
    city_name: "West Monroe",
  },
  {
    postal_code: "07068",
    full_city_name: "Roseland, NJ",
    city_name: "Roseland",
  },
  {
    postal_code: "12737",
    full_city_name: "Glen Spey, NY",
    city_name: "Glen Spey",
  },
  {
    postal_code: "53081",
    full_city_name: "Sheboygan, WI",
    city_name: "Sheboygan",
  },
  {
    postal_code: "10504",
    full_city_name: "Armonk, NY",
    city_name: "Armonk",
  },
  {
    postal_code: "48026",
    full_city_name: "Fraser, MI",
    city_name: "Fraser",
  },
  {
    postal_code: "53139",
    full_city_name: "Kansasville, WI",
    city_name: "Kansasville",
  },
  {
    postal_code: "91935",
    full_city_name: "Jamul, CA",
    city_name: "Jamul",
  },
  {
    postal_code: "32505",
    full_city_name: "Pensacola, FL",
    city_name: "Pensacola",
  },
  {
    postal_code: "54669",
    full_city_name: "West Salem, WI",
    city_name: "West Salem",
  },
  {
    postal_code: "05037",
    full_city_name: "Brownsville, VT",
    city_name: "Brownsville",
  },
  {
    postal_code: "04257",
    full_city_name: "Mexico, ME",
    city_name: "Mexico",
  },
  {
    postal_code: "82443",
    full_city_name: "Thermopolis, WY",
    city_name: "Thermopolis",
  },
  {
    postal_code: "04478",
    full_city_name: "Rockwood, ME",
    city_name: "Rockwood",
  },
  {
    postal_code: "14467",
    full_city_name: "Henrietta, NY",
    city_name: "Henrietta",
  },
  {
    postal_code: "47720",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "10044",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "58059",
    full_city_name: "Mapleton, ND",
    city_name: "Mapleton",
  },
  {
    postal_code: "17972",
    full_city_name: "Schuylkill Haven, PA",
    city_name: "Schuylkill Haven",
  },
  {
    postal_code: "59632",
    full_city_name: "Boulder, MT",
    city_name: "Boulder",
  },
  {
    postal_code: "62910",
    full_city_name: "Brookport, IL",
    city_name: "Brookport",
  },
  {
    postal_code: "38870",
    full_city_name: "Smithville, MS",
    city_name: "Smithville",
  },
  {
    postal_code: "35905",
    full_city_name: "Gadsden, AL",
    city_name: "Gadsden",
  },
  {
    postal_code: "87301",
    full_city_name: "Gallup, NM",
    city_name: "Gallup",
  },
  {
    postal_code: "47991",
    full_city_name: "West Lebanon, IN",
    city_name: "West Lebanon",
  },
  {
    postal_code: "54120",
    full_city_name: "Fence, WI",
    city_name: "Fence",
  },
  {
    postal_code: "62245",
    full_city_name: "Germantown, IL",
    city_name: "Germantown",
  },
  {
    postal_code: "38352",
    full_city_name: "Luray, TN",
    city_name: "Luray",
  },
  {
    postal_code: "18211",
    full_city_name: "Andreas, PA",
    city_name: "Andreas",
  },
  {
    postal_code: "82523",
    full_city_name: "Pavillion, WY",
    city_name: "Pavillion",
  },
  {
    postal_code: "56443",
    full_city_name: "Cushing, MN",
    city_name: "Cushing",
  },
  {
    postal_code: "50456",
    full_city_name: "Manly, IA",
    city_name: "Manly",
  },
  {
    postal_code: "22639",
    full_city_name: "Hume, VA",
    city_name: "Hume",
  },
  {
    postal_code: "49073",
    full_city_name: "Nashville, MI",
    city_name: "Nashville",
  },
  {
    postal_code: "25431",
    full_city_name: "Levels, WV",
    city_name: "Levels",
  },
  {
    postal_code: "23966",
    full_city_name: "Rice, VA",
    city_name: "Rice",
  },
  {
    postal_code: "76690",
    full_city_name: "Walnut Springs, TX",
    city_name: "Walnut Springs",
  },
  {
    postal_code: "29038",
    full_city_name: "Cope, SC",
    city_name: "Cope",
  },
  {
    postal_code: "81152",
    full_city_name: "San Luis, CO",
    city_name: "San Luis",
  },
  {
    postal_code: "49905",
    full_city_name: "Atlantic Mine, MI",
    city_name: "Atlantic Mine",
  },
  {
    postal_code: "62094",
    full_city_name: "Witt, IL",
    city_name: "Witt",
  },
  {
    postal_code: "71040",
    full_city_name: "Homer, LA",
    city_name: "Homer",
  },
  {
    postal_code: "97499",
    full_city_name: "Yoncalla, OR",
    city_name: "Yoncalla",
  },
  {
    postal_code: "65081",
    full_city_name: "Tipton, MO",
    city_name: "Tipton",
  },
  {
    postal_code: "52175",
    full_city_name: "West Union, IA",
    city_name: "West Union",
  },
  {
    postal_code: "48888",
    full_city_name: "Stanton, MI",
    city_name: "Stanton",
  },
  {
    postal_code: "71671",
    full_city_name: "Warren, AR",
    city_name: "Warren",
  },
  {
    postal_code: "13424",
    full_city_name: "Oriskany, NY",
    city_name: "Oriskany",
  },
  {
    postal_code: "17372",
    full_city_name: "York Springs, PA",
    city_name: "York Springs",
  },
  {
    postal_code: "53811",
    full_city_name: "Hazel Green, WI",
    city_name: "Hazel Green",
  },
  {
    postal_code: "43932",
    full_city_name: "Irondale, OH",
    city_name: "Irondale",
  },
  {
    postal_code: "68822",
    full_city_name: "Broken Bow, NE",
    city_name: "Broken Bow",
  },
  {
    postal_code: "61912",
    full_city_name: "Ashmore, IL",
    city_name: "Ashmore",
  },
  {
    postal_code: "24134",
    full_city_name: "Pearisburg, VA",
    city_name: "Pearisburg",
  },
  {
    postal_code: "15828",
    full_city_name: "Clarington, PA",
    city_name: "Clarington",
  },
  {
    postal_code: "22454",
    full_city_name: "Dunnsville, VA",
    city_name: "Dunnsville",
  },
  {
    postal_code: "88232",
    full_city_name: "Hagerman, NM",
    city_name: "Hagerman",
  },
  {
    postal_code: "78879",
    full_city_name: "Rio Frio, TX",
    city_name: "Rio Frio",
  },
  {
    postal_code: "71268",
    full_city_name: "Quitman, LA",
    city_name: "Quitman",
  },
  {
    postal_code: "70807",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "08319",
    full_city_name: "Estell Manor, NJ",
    city_name: "Estell Manor",
  },
  {
    postal_code: "23050",
    full_city_name: "Dutton, VA",
    city_name: "Dutton",
  },
  {
    postal_code: "68333",
    full_city_name: "Crete, NE",
    city_name: "Crete",
  },
  {
    postal_code: "07647",
    full_city_name: "Northvale, NJ",
    city_name: "Northvale",
  },
  {
    postal_code: "66736",
    full_city_name: "Fredonia, KS",
    city_name: "Fredonia",
  },
  {
    postal_code: "17841",
    full_city_name: "Mc Clure, PA",
    city_name: "Mc Clure",
  },
  {
    postal_code: "92401",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "03752",
    full_city_name: "Goshen, NH",
    city_name: "Goshen",
  },
  {
    postal_code: "97041",
    full_city_name: "Mount Hood Parkdale, OR",
    city_name: "Mount Hood Parkdale",
  },
  {
    postal_code: "24243",
    full_city_name: "Dryden, VA",
    city_name: "Dryden",
  },
  {
    postal_code: "58077",
    full_city_name: "Walcott, ND",
    city_name: "Walcott",
  },
  {
    postal_code: "87747",
    full_city_name: "Springer, NM",
    city_name: "Springer",
  },
  {
    postal_code: "12873",
    full_city_name: "Shushan, NY",
    city_name: "Shushan",
  },
  {
    postal_code: "15646",
    full_city_name: "Jones Mills, PA",
    city_name: "Jones Mills",
  },
  {
    postal_code: "97450",
    full_city_name: "Langlois, OR",
    city_name: "Langlois",
  },
  {
    postal_code: "04758",
    full_city_name: "Mars Hill, ME",
    city_name: "Mars Hill",
  },
  {
    postal_code: "92275",
    full_city_name: "Salton City, CA",
    city_name: "Salton City",
  },
  {
    postal_code: "75705",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "90040",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "12766",
    full_city_name: "North Branch, NY",
    city_name: "North Branch",
  },
  {
    postal_code: "01537",
    full_city_name: "North Oxford, MA",
    city_name: "North Oxford",
  },
  {
    postal_code: "16130",
    full_city_name: "Hadley, PA",
    city_name: "Hadley",
  },
  {
    postal_code: "45742",
    full_city_name: "Little Hocking, OH",
    city_name: "Little Hocking",
  },
  {
    postal_code: "12494",
    full_city_name: "West Shokan, NY",
    city_name: "West Shokan",
  },
  {
    postal_code: "91905",
    full_city_name: "Boulevard, CA",
    city_name: "Boulevard",
  },
  {
    postal_code: "94508",
    full_city_name: "Angwin, CA",
    city_name: "Angwin",
  },
  {
    postal_code: "07723",
    full_city_name: "Deal, NJ",
    city_name: "Deal",
  },
  {
    postal_code: "56639",
    full_city_name: "Effie, MN",
    city_name: "Effie",
  },
  {
    postal_code: "95450",
    full_city_name: "Jenner, CA",
    city_name: "Jenner",
  },
  {
    postal_code: "73442",
    full_city_name: "Loco, OK",
    city_name: "Loco",
  },
  {
    postal_code: "14521",
    full_city_name: "Ovid, NY",
    city_name: "Ovid",
  },
  {
    postal_code: "40734",
    full_city_name: "Gray, KY",
    city_name: "Gray",
  },
  {
    postal_code: "69148",
    full_city_name: "Lisco, NE",
    city_name: "Lisco",
  },
  {
    postal_code: "83302",
    full_city_name: "Rogerson, ID",
    city_name: "Rogerson",
  },
  {
    postal_code: "68840",
    full_city_name: "Gibbon, NE",
    city_name: "Gibbon",
  },
  {
    postal_code: "35677",
    full_city_name: "Waterloo, AL",
    city_name: "Waterloo",
  },
  {
    postal_code: "97413",
    full_city_name: "Blue River, OR",
    city_name: "Blue River",
  },
  {
    postal_code: "03230",
    full_city_name: "Danbury, NH",
    city_name: "Danbury",
  },
  {
    postal_code: "44093",
    full_city_name: "Williamsfield, OH",
    city_name: "Williamsfield",
  },
  {
    postal_code: "05153",
    full_city_name: "Proctorsville, VT",
    city_name: "Proctorsville",
  },
  {
    postal_code: "14717",
    full_city_name: "Caneadea, NY",
    city_name: "Caneadea",
  },
  {
    postal_code: "45844",
    full_city_name: "Fort Jennings, OH",
    city_name: "Fort Jennings",
  },
  {
    postal_code: "14782",
    full_city_name: "Sinclairville, NY",
    city_name: "Sinclairville",
  },
  {
    postal_code: "12548",
    full_city_name: "Modena, NY",
    city_name: "Modena",
  },
  {
    postal_code: "78881",
    full_city_name: "Sabinal, TX",
    city_name: "Sabinal",
  },
  {
    postal_code: "17090",
    full_city_name: "Shermans Dale, PA",
    city_name: "Shermans Dale",
  },
  {
    postal_code: "42635",
    full_city_name: "Pine Knot, KY",
    city_name: "Pine Knot",
  },
  {
    postal_code: "29001",
    full_city_name: "Alcolu, SC",
    city_name: "Alcolu",
  },
  {
    postal_code: "68641",
    full_city_name: "Howells, NE",
    city_name: "Howells",
  },
  {
    postal_code: "63662",
    full_city_name: "Patton, MO",
    city_name: "Patton",
  },
  {
    postal_code: "43071",
    full_city_name: "Saint Louisville, OH",
    city_name: "Saint Louisville",
  },
  {
    postal_code: "52212",
    full_city_name: "Center Junction, IA",
    city_name: "Center Junction",
  },
  {
    postal_code: "62642",
    full_city_name: "Greenview, IL",
    city_name: "Greenview",
  },
  {
    postal_code: "95830",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "78055",
    full_city_name: "Medina, TX",
    city_name: "Medina",
  },
  {
    postal_code: "55603",
    full_city_name: "Finland, MN",
    city_name: "Finland",
  },
  {
    postal_code: "65629",
    full_city_name: "Chadwick, MO",
    city_name: "Chadwick",
  },
  {
    postal_code: "52217",
    full_city_name: "Clutier, IA",
    city_name: "Clutier",
  },
  {
    postal_code: "49070",
    full_city_name: "Martin, MI",
    city_name: "Martin",
  },
  {
    postal_code: "12517",
    full_city_name: "Copake Falls, NY",
    city_name: "Copake Falls",
  },
  {
    postal_code: "61368",
    full_city_name: "Tiskilwa, IL",
    city_name: "Tiskilwa",
  },
  {
    postal_code: "14728",
    full_city_name: "Dewittville, NY",
    city_name: "Dewittville",
  },
  {
    postal_code: "24016",
    full_city_name: "Roanoke, VA",
    city_name: "Roanoke",
  },
  {
    postal_code: "82720",
    full_city_name: "Hulett, WY",
    city_name: "Hulett",
  },
  {
    postal_code: "11770",
    full_city_name: "Ocean Beach, NY",
    city_name: "Ocean Beach",
  },
  {
    postal_code: "36474",
    full_city_name: "Red Level, AL",
    city_name: "Red Level",
  },
  {
    postal_code: "74469",
    full_city_name: "Warner, OK",
    city_name: "Warner",
  },
  {
    postal_code: "38756",
    full_city_name: "Leland, MS",
    city_name: "Leland",
  },
  {
    postal_code: "07853",
    full_city_name: "Long Valley, NJ",
    city_name: "Long Valley",
  },
  {
    postal_code: "45337",
    full_city_name: "Laura, OH",
    city_name: "Laura",
  },
  {
    postal_code: "13864",
    full_city_name: "Willseyville, NY",
    city_name: "Willseyville",
  },
  {
    postal_code: "35650",
    full_city_name: "Moulton, AL",
    city_name: "Moulton",
  },
  {
    postal_code: "62865",
    full_city_name: "Mulkeytown, IL",
    city_name: "Mulkeytown",
  },
  {
    postal_code: "38126",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "25003",
    full_city_name: "Alum Creek, WV",
    city_name: "Alum Creek",
  },
  {
    postal_code: "95636",
    full_city_name: "Grizzly Flats, CA",
    city_name: "Grizzly Flats",
  },
  {
    postal_code: "27950",
    full_city_name: "Knotts Island, NC",
    city_name: "Knotts Island",
  },
  {
    postal_code: "93925",
    full_city_name: "Chualar, CA",
    city_name: "Chualar",
  },
  {
    postal_code: "54634",
    full_city_name: "Hillsboro, WI",
    city_name: "Hillsboro",
  },
  {
    postal_code: "78883",
    full_city_name: "Tarpley, TX",
    city_name: "Tarpley",
  },
  {
    postal_code: "94074",
    full_city_name: "San Gregorio, CA",
    city_name: "San Gregorio",
  },
  {
    postal_code: "18439",
    full_city_name: "Lakewood, PA",
    city_name: "Lakewood",
  },
  {
    postal_code: "55370",
    full_city_name: "Plato, MN",
    city_name: "Plato",
  },
  {
    postal_code: "73006",
    full_city_name: "Apache, OK",
    city_name: "Apache",
  },
  {
    postal_code: "61411",
    full_city_name: "Adair, IL",
    city_name: "Adair",
  },
  {
    postal_code: "11796",
    full_city_name: "West Sayville, NY",
    city_name: "West Sayville",
  },
  {
    postal_code: "81091",
    full_city_name: "Weston, CO",
    city_name: "Weston",
  },
  {
    postal_code: "67648",
    full_city_name: "Lucas, KS",
    city_name: "Lucas",
  },
  {
    postal_code: "38061",
    full_city_name: "Pocahontas, TN",
    city_name: "Pocahontas",
  },
  {
    postal_code: "87943",
    full_city_name: "Winston, NM",
    city_name: "Winston",
  },
  {
    postal_code: "79358",
    full_city_name: "Ropesville, TX",
    city_name: "Ropesville",
  },
  {
    postal_code: "94305",
    full_city_name: "Stanford, CA",
    city_name: "Stanford",
  },
  {
    postal_code: "54154",
    full_city_name: "Oconto Falls, WI",
    city_name: "Oconto Falls",
  },
  {
    postal_code: "54626",
    full_city_name: "Eastman, WI",
    city_name: "Eastman",
  },
  {
    postal_code: "56026",
    full_city_name: "Ellendale, MN",
    city_name: "Ellendale",
  },
  {
    postal_code: "13810",
    full_city_name: "Mount Vision, NY",
    city_name: "Mount Vision",
  },
  {
    postal_code: "52064",
    full_city_name: "Miles, IA",
    city_name: "Miles",
  },
  {
    postal_code: "22654",
    full_city_name: "Star Tannery, VA",
    city_name: "Star Tannery",
  },
  {
    postal_code: "60927",
    full_city_name: "Clifton, IL",
    city_name: "Clifton",
  },
  {
    postal_code: "61472",
    full_city_name: "Rio, IL",
    city_name: "Rio",
  },
  {
    postal_code: "64491",
    full_city_name: "Tarkio, MO",
    city_name: "Tarkio",
  },
  {
    postal_code: "04624",
    full_city_name: "Corea, ME",
    city_name: "Corea",
  },
  {
    postal_code: "81253",
    full_city_name: "Wetmore, CO",
    city_name: "Wetmore",
  },
  {
    postal_code: "33585",
    full_city_name: "Sumterville, FL",
    city_name: "Sumterville",
  },
  {
    postal_code: "01031",
    full_city_name: "Gilbertville, MA",
    city_name: "Gilbertville",
  },
  {
    postal_code: "28511",
    full_city_name: "Atlantic, NC",
    city_name: "Atlantic",
  },
  {
    postal_code: "61725",
    full_city_name: "Carlock, IL",
    city_name: "Carlock",
  },
  {
    postal_code: "16050",
    full_city_name: "Petrolia, PA",
    city_name: "Petrolia",
  },
  {
    postal_code: "97907",
    full_city_name: "Huntington, OR",
    city_name: "Huntington",
  },
  {
    postal_code: "53579",
    full_city_name: "Reeseville, WI",
    city_name: "Reeseville",
  },
  {
    postal_code: "64489",
    full_city_name: "Stanberry, MO",
    city_name: "Stanberry",
  },
  {
    postal_code: "48759",
    full_city_name: "Sebewaing, MI",
    city_name: "Sebewaing",
  },
  {
    postal_code: "51039",
    full_city_name: "Moville, IA",
    city_name: "Moville",
  },
  {
    postal_code: "12450",
    full_city_name: "Lanesville, NY",
    city_name: "Lanesville",
  },
  {
    postal_code: "13433",
    full_city_name: "Port Leyden, NY",
    city_name: "Port Leyden",
  },
  {
    postal_code: "68405",
    full_city_name: "Milford, NE",
    city_name: "Milford",
  },
  {
    postal_code: "61854",
    full_city_name: "Mansfield, IL",
    city_name: "Mansfield",
  },
  {
    postal_code: "96125",
    full_city_name: "Sierra City, CA",
    city_name: "Sierra City",
  },
  {
    postal_code: "66536",
    full_city_name: "Saint Marys, KS",
    city_name: "Saint Marys",
  },
  {
    postal_code: "83524",
    full_city_name: "Culdesac, ID",
    city_name: "Culdesac",
  },
  {
    postal_code: "04854",
    full_city_name: "Owls Head, ME",
    city_name: "Owls Head",
  },
  {
    postal_code: "41527",
    full_city_name: "Forest Hills, KY",
    city_name: "Forest Hills",
  },
  {
    postal_code: "04093",
    full_city_name: "Buxton, ME",
    city_name: "Buxton",
  },
  {
    postal_code: "47380",
    full_city_name: "Ridgeville, IN",
    city_name: "Ridgeville",
  },
  {
    postal_code: "54745",
    full_city_name: "Holcombe, WI",
    city_name: "Holcombe",
  },
  {
    postal_code: "84783",
    full_city_name: "Dammeron Valley, UT",
    city_name: "Dammeron Valley",
  },
  {
    postal_code: "58843",
    full_city_name: "Epping, ND",
    city_name: "Epping",
  },
  {
    postal_code: "57401",
    full_city_name: "Aberdeen, SD",
    city_name: "Aberdeen",
  },
  {
    postal_code: "93615",
    full_city_name: "Cutler, CA",
    city_name: "Cutler",
  },
  {
    postal_code: "50240",
    full_city_name: "Saint Charles, IA",
    city_name: "Saint Charles",
  },
  {
    postal_code: "72070",
    full_city_name: "Houston, AR",
    city_name: "Houston",
  },
  {
    postal_code: "95947",
    full_city_name: "Greenville, CA",
    city_name: "Greenville",
  },
  {
    postal_code: "07041",
    full_city_name: "Millburn, NJ",
    city_name: "Millburn",
  },
  {
    postal_code: "41557",
    full_city_name: "Raccoon, KY",
    city_name: "Raccoon",
  },
  {
    postal_code: "76934",
    full_city_name: "Carlsbad, TX",
    city_name: "Carlsbad",
  },
  {
    postal_code: "54517",
    full_city_name: "Clam Lake, WI",
    city_name: "Clam Lake",
  },
  {
    postal_code: "39647",
    full_city_name: "Mc Call Creek, MS",
    city_name: "Mc Call Creek",
  },
  {
    postal_code: "53018",
    full_city_name: "Delafield, WI",
    city_name: "Delafield",
  },
  {
    postal_code: "74116",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "38876",
    full_city_name: "Tremont, MS",
    city_name: "Tremont",
  },
  {
    postal_code: "56037",
    full_city_name: "Good Thunder, MN",
    city_name: "Good Thunder",
  },
  {
    postal_code: "19343",
    full_city_name: "Glenmoore, PA",
    city_name: "Glenmoore",
  },
  {
    postal_code: "14747",
    full_city_name: "Kennedy, NY",
    city_name: "Kennedy",
  },
  {
    postal_code: "52310",
    full_city_name: "Monticello, IA",
    city_name: "Monticello",
  },
  {
    postal_code: "12921",
    full_city_name: "Chazy, NY",
    city_name: "Chazy",
  },
  {
    postal_code: "53189",
    full_city_name: "Waukesha, WI",
    city_name: "Waukesha",
  },
  {
    postal_code: "13786",
    full_city_name: "Harpersfield, NY",
    city_name: "Harpersfield",
  },
  {
    postal_code: "15681",
    full_city_name: "Saltsburg, PA",
    city_name: "Saltsburg",
  },
  {
    postal_code: "70041",
    full_city_name: "Buras, LA",
    city_name: "Buras",
  },
  {
    postal_code: "24485",
    full_city_name: "West Augusta, VA",
    city_name: "West Augusta",
  },
  {
    postal_code: "29563",
    full_city_name: "Lake View, SC",
    city_name: "Lake View",
  },
  {
    postal_code: "24281",
    full_city_name: "Rose Hill, VA",
    city_name: "Rose Hill",
  },
  {
    postal_code: "56028",
    full_city_name: "Elysian, MN",
    city_name: "Elysian",
  },
  {
    postal_code: "17824",
    full_city_name: "Elysburg, PA",
    city_name: "Elysburg",
  },
  {
    postal_code: "79226",
    full_city_name: "Clarendon, TX",
    city_name: "Clarendon",
  },
  {
    postal_code: "30752",
    full_city_name: "Trenton, GA",
    city_name: "Trenton",
  },
  {
    postal_code: "13685",
    full_city_name: "Sackets Harbor, NY",
    city_name: "Sackets Harbor",
  },
  {
    postal_code: "36502",
    full_city_name: "Atmore, AL",
    city_name: "Atmore",
  },
  {
    postal_code: "23109",
    full_city_name: "Mathews, VA",
    city_name: "Mathews",
  },
  {
    postal_code: "10601",
    full_city_name: "White Plains, NY",
    city_name: "White Plains",
  },
  {
    postal_code: "88030",
    full_city_name: "Deming, NM",
    city_name: "Deming",
  },
  {
    postal_code: "04966",
    full_city_name: "Phillips, ME",
    city_name: "Phillips",
  },
  {
    postal_code: "29108",
    full_city_name: "Newberry, SC",
    city_name: "Newberry",
  },
  {
    postal_code: "07109",
    full_city_name: "Belleville, NJ",
    city_name: "Belleville",
  },
  {
    postal_code: "81631",
    full_city_name: "Eagle, CO",
    city_name: "Eagle",
  },
  {
    postal_code: "07481",
    full_city_name: "Wyckoff, NJ",
    city_name: "Wyckoff",
  },
  {
    postal_code: "21822",
    full_city_name: "Eden, MD",
    city_name: "Eden",
  },
  {
    postal_code: "10604",
    full_city_name: "West Harrison, NY",
    city_name: "West Harrison",
  },
  {
    postal_code: "44127",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "29742",
    full_city_name: "Sharon, SC",
    city_name: "Sharon",
  },
  {
    postal_code: "35098",
    full_city_name: "Logan, AL",
    city_name: "Logan",
  },
  {
    postal_code: "49637",
    full_city_name: "Grawn, MI",
    city_name: "Grawn",
  },
  {
    postal_code: "30733",
    full_city_name: "Plainville, GA",
    city_name: "Plainville",
  },
  {
    postal_code: "10472",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "98648",
    full_city_name: "Stevenson, WA",
    city_name: "Stevenson",
  },
  {
    postal_code: "75969",
    full_city_name: "Pollok, TX",
    city_name: "Pollok",
  },
  {
    postal_code: "13431",
    full_city_name: "Poland, NY",
    city_name: "Poland",
  },
  {
    postal_code: "14111",
    full_city_name: "North Collins, NY",
    city_name: "North Collins",
  },
  {
    postal_code: "95449",
    full_city_name: "Hopland, CA",
    city_name: "Hopland",
  },
  {
    postal_code: "88337",
    full_city_name: "La Luz, NM",
    city_name: "La Luz",
  },
  {
    postal_code: "19029",
    full_city_name: "Essington, PA",
    city_name: "Essington",
  },
  {
    postal_code: "77963",
    full_city_name: "Goliad, TX",
    city_name: "Goliad",
  },
  {
    postal_code: "24801",
    full_city_name: "Welch, WV",
    city_name: "Welch",
  },
  {
    postal_code: "54767",
    full_city_name: "Spring Valley, WI",
    city_name: "Spring Valley",
  },
  {
    postal_code: "35768",
    full_city_name: "Scottsboro, AL",
    city_name: "Scottsboro",
  },
  {
    postal_code: "92322",
    full_city_name: "Cedarpines Park, CA",
    city_name: "Cedarpines Park",
  },
  {
    postal_code: "29037",
    full_city_name: "Chappells, SC",
    city_name: "Chappells",
  },
  {
    postal_code: "47393",
    full_city_name: "Williamsburg, IN",
    city_name: "Williamsburg",
  },
  {
    postal_code: "65329",
    full_city_name: "Florence, MO",
    city_name: "Florence",
  },
  {
    postal_code: "19555",
    full_city_name: "Shoemakersville, PA",
    city_name: "Shoemakersville",
  },
  {
    postal_code: "05464",
    full_city_name: "Jeffersonville, VT",
    city_name: "Jeffersonville",
  },
  {
    postal_code: "84781",
    full_city_name: "Pine Valley, UT",
    city_name: "Pine Valley",
  },
  {
    postal_code: "63556",
    full_city_name: "Milan, MO",
    city_name: "Milan",
  },
  {
    postal_code: "74930",
    full_city_name: "Bokoshe, OK",
    city_name: "Bokoshe",
  },
  {
    postal_code: "47368",
    full_city_name: "Parker City, IN",
    city_name: "Parker City",
  },
  {
    postal_code: "71653",
    full_city_name: "Lake Village, AR",
    city_name: "Lake Village",
  },
  {
    postal_code: "35673",
    full_city_name: "Trinity, AL",
    city_name: "Trinity",
  },
  {
    postal_code: "16317",
    full_city_name: "Cooperstown, PA",
    city_name: "Cooperstown",
  },
  {
    postal_code: "39168",
    full_city_name: "Taylorsville, MS",
    city_name: "Taylorsville",
  },
  {
    postal_code: "15442",
    full_city_name: "Grindstone, PA",
    city_name: "Grindstone",
  },
  {
    postal_code: "53095",
    full_city_name: "West Bend, WI",
    city_name: "West Bend",
  },
  {
    postal_code: "59008",
    full_city_name: "Belfry, MT",
    city_name: "Belfry",
  },
  {
    postal_code: "25976",
    full_city_name: "Meadow Bridge, WV",
    city_name: "Meadow Bridge",
  },
  {
    postal_code: "12732",
    full_city_name: "Eldred, NY",
    city_name: "Eldred",
  },
  {
    postal_code: "37309",
    full_city_name: "Calhoun, TN",
    city_name: "Calhoun",
  },
  {
    postal_code: "73068",
    full_city_name: "Noble, OK",
    city_name: "Noble",
  },
  {
    postal_code: "52738",
    full_city_name: "Columbus Junction, IA",
    city_name: "Columbus Junction",
  },
  {
    postal_code: "54966",
    full_city_name: "Plainfield, WI",
    city_name: "Plainfield",
  },
  {
    postal_code: "59070",
    full_city_name: "Roberts, MT",
    city_name: "Roberts",
  },
  {
    postal_code: "19151",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "32431",
    full_city_name: "Cottondale, FL",
    city_name: "Cottondale",
  },
  {
    postal_code: "70450",
    full_city_name: "Mount Hermon, LA",
    city_name: "Mount Hermon",
  },
  {
    postal_code: "49254",
    full_city_name: "Michigan Center, MI",
    city_name: "Michigan Center",
  },
  {
    postal_code: "81332",
    full_city_name: "Rico, CO",
    city_name: "Rico",
  },
  {
    postal_code: "04022",
    full_city_name: "Denmark, ME",
    city_name: "Denmark",
  },
  {
    postal_code: "07027",
    full_city_name: "Garwood, NJ",
    city_name: "Garwood",
  },
  {
    postal_code: "42023",
    full_city_name: "Bardwell, KY",
    city_name: "Bardwell",
  },
  {
    postal_code: "54433",
    full_city_name: "Gilman, WI",
    city_name: "Gilman",
  },
  {
    postal_code: "63945",
    full_city_name: "Harviell, MO",
    city_name: "Harviell",
  },
  {
    postal_code: "20777",
    full_city_name: "Highland, MD",
    city_name: "Highland",
  },
  {
    postal_code: "48815",
    full_city_name: "Clarksville, MI",
    city_name: "Clarksville",
  },
  {
    postal_code: "30725",
    full_city_name: "Flintstone, GA",
    city_name: "Flintstone",
  },
  {
    postal_code: "36555",
    full_city_name: "Magnolia Springs, AL",
    city_name: "Magnolia Springs",
  },
  {
    postal_code: "13026",
    full_city_name: "Aurora, NY",
    city_name: "Aurora",
  },
  {
    postal_code: "63937",
    full_city_name: "Ellsinore, MO",
    city_name: "Ellsinore",
  },
  {
    postal_code: "54153",
    full_city_name: "Oconto, WI",
    city_name: "Oconto",
  },
  {
    postal_code: "26159",
    full_city_name: "Paden City, WV",
    city_name: "Paden City",
  },
  {
    postal_code: "68783",
    full_city_name: "Verdigre, NE",
    city_name: "Verdigre",
  },
  {
    postal_code: "13315",
    full_city_name: "Burlington Flats, NY",
    city_name: "Burlington Flats",
  },
  {
    postal_code: "38559",
    full_city_name: "Doyle, TN",
    city_name: "Doyle",
  },
  {
    postal_code: "45850",
    full_city_name: "Harrod, OH",
    city_name: "Harrod",
  },
  {
    postal_code: "54618",
    full_city_name: "Camp Douglas, WI",
    city_name: "Camp Douglas",
  },
  {
    postal_code: "46940",
    full_city_name: "La Fontaine, IN",
    city_name: "La Fontaine",
  },
  {
    postal_code: "12439",
    full_city_name: "Hensonville, NY",
    city_name: "Hensonville",
  },
  {
    postal_code: "18078",
    full_city_name: "Schnecksville, PA",
    city_name: "Schnecksville",
  },
  {
    postal_code: "83355",
    full_city_name: "Wendell, ID",
    city_name: "Wendell",
  },
  {
    postal_code: "62442",
    full_city_name: "Martinsville, IL",
    city_name: "Martinsville",
  },
  {
    postal_code: "22469",
    full_city_name: "Hague, VA",
    city_name: "Hague",
  },
  {
    postal_code: "49633",
    full_city_name: "Fife Lake, MI",
    city_name: "Fife Lake",
  },
  {
    postal_code: "04660",
    full_city_name: "Mount Desert, ME",
    city_name: "Mount Desert",
  },
  {
    postal_code: "30731",
    full_city_name: "Menlo, GA",
    city_name: "Menlo",
  },
  {
    postal_code: "32096",
    full_city_name: "White Springs, FL",
    city_name: "White Springs",
  },
  {
    postal_code: "83014",
    full_city_name: "Wilson, WY",
    city_name: "Wilson",
  },
  {
    postal_code: "46806",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "07631",
    full_city_name: "Englewood, NJ",
    city_name: "Englewood",
  },
  {
    postal_code: "48335",
    full_city_name: "Farmington, MI",
    city_name: "Farmington",
  },
  {
    postal_code: "53213",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "47807",
    full_city_name: "Terre Haute, IN",
    city_name: "Terre Haute",
  },
  {
    postal_code: "23452",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "18201",
    full_city_name: "Hazleton, PA",
    city_name: "Hazleton",
  },
  {
    postal_code: "55708",
    full_city_name: "Biwabik, MN",
    city_name: "Biwabik",
  },
  {
    postal_code: "72010",
    full_city_name: "Bald Knob, AR",
    city_name: "Bald Knob",
  },
  {
    postal_code: "83622",
    full_city_name: "Garden Valley, ID",
    city_name: "Garden Valley",
  },
  {
    postal_code: "08049",
    full_city_name: "Magnolia, NJ",
    city_name: "Magnolia",
  },
  {
    postal_code: "29541",
    full_city_name: "Effingham, SC",
    city_name: "Effingham",
  },
  {
    postal_code: "19567",
    full_city_name: "Womelsdorf, PA",
    city_name: "Womelsdorf",
  },
  {
    postal_code: "53132",
    full_city_name: "Franklin, WI",
    city_name: "Franklin",
  },
  {
    postal_code: "82240",
    full_city_name: "Torrington, WY",
    city_name: "Torrington",
  },
  {
    postal_code: "54162",
    full_city_name: "Pulaski, WI",
    city_name: "Pulaski",
  },
  {
    postal_code: "87008",
    full_city_name: "Cedar Crest, NM",
    city_name: "Cedar Crest",
  },
  {
    postal_code: "83851",
    full_city_name: "Plummer, ID",
    city_name: "Plummer",
  },
  {
    postal_code: "47567",
    full_city_name: "Petersburg, IN",
    city_name: "Petersburg",
  },
  {
    postal_code: "31520",
    full_city_name: "Brunswick, GA",
    city_name: "Brunswick",
  },
  {
    postal_code: "58072",
    full_city_name: "Valley City, ND",
    city_name: "Valley City",
  },
  {
    postal_code: "35503",
    full_city_name: "Jasper, AL",
    city_name: "Jasper",
  },
  {
    postal_code: "95452",
    full_city_name: "Kenwood, CA",
    city_name: "Kenwood",
  },
  {
    postal_code: "63960",
    full_city_name: "Puxico, MO",
    city_name: "Puxico",
  },
  {
    postal_code: "73541",
    full_city_name: "Fletcher, OK",
    city_name: "Fletcher",
  },
  {
    postal_code: "72901",
    full_city_name: "Fort Smith, AR",
    city_name: "Fort Smith",
  },
  {
    postal_code: "07107",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "72847",
    full_city_name: "London, AR",
    city_name: "London",
  },
  {
    postal_code: "53085",
    full_city_name: "Sheboygan Falls, WI",
    city_name: "Sheboygan Falls",
  },
  {
    postal_code: "35550",
    full_city_name: "Cordova, AL",
    city_name: "Cordova",
  },
  {
    postal_code: "71857",
    full_city_name: "Prescott, AR",
    city_name: "Prescott",
  },
  {
    postal_code: "96712",
    full_city_name: "Haleiwa, HI",
    city_name: "Haleiwa",
  },
  {
    postal_code: "49621",
    full_city_name: "Cedar, MI",
    city_name: "Cedar",
  },
  {
    postal_code: "10576",
    full_city_name: "Pound Ridge, NY",
    city_name: "Pound Ridge",
  },
  {
    postal_code: "54479",
    full_city_name: "Spencer, WI",
    city_name: "Spencer",
  },
  {
    postal_code: "14878",
    full_city_name: "Rock Stream, NY",
    city_name: "Rock Stream",
  },
  {
    postal_code: "19094",
    full_city_name: "Woodlyn, PA",
    city_name: "Woodlyn",
  },
  {
    postal_code: "19547",
    full_city_name: "Oley, PA",
    city_name: "Oley",
  },
  {
    postal_code: "38069",
    full_city_name: "Stanton, TN",
    city_name: "Stanton",
  },
  {
    postal_code: "58647",
    full_city_name: "New England, ND",
    city_name: "New England",
  },
  {
    postal_code: "39735",
    full_city_name: "Ackerman, MS",
    city_name: "Ackerman",
  },
  {
    postal_code: "21776",
    full_city_name: "New Windsor, MD",
    city_name: "New Windsor",
  },
  {
    postal_code: "88044",
    full_city_name: "La Mesa, NM",
    city_name: "La Mesa",
  },
  {
    postal_code: "14605",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "13473",
    full_city_name: "Turin, NY",
    city_name: "Turin",
  },
  {
    postal_code: "75938",
    full_city_name: "Colmesneil, TX",
    city_name: "Colmesneil",
  },
  {
    postal_code: "54864",
    full_city_name: "Poplar, WI",
    city_name: "Poplar",
  },
  {
    postal_code: "22437",
    full_city_name: "Center Cross, VA",
    city_name: "Center Cross",
  },
  {
    postal_code: "52159",
    full_city_name: "Monona, IA",
    city_name: "Monona",
  },
  {
    postal_code: "06785",
    full_city_name: "South Kent, CT",
    city_name: "South Kent",
  },
  {
    postal_code: "60604",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "52625",
    full_city_name: "Donnellson, IA",
    city_name: "Donnellson",
  },
  {
    postal_code: "99517",
    full_city_name: "Anchorage, AK",
    city_name: "Anchorage",
  },
  {
    postal_code: "48022",
    full_city_name: "Emmett, MI",
    city_name: "Emmett",
  },
  {
    postal_code: "62670",
    full_city_name: "New Berlin, IL",
    city_name: "New Berlin",
  },
  {
    postal_code: "48619",
    full_city_name: "Comins, MI",
    city_name: "Comins",
  },
  {
    postal_code: "93223",
    full_city_name: "Farmersville, CA",
    city_name: "Farmersville",
  },
  {
    postal_code: "65337",
    full_city_name: "La Monte, MO",
    city_name: "La Monte",
  },
  {
    postal_code: "48211",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "94924",
    full_city_name: "Bolinas, CA",
    city_name: "Bolinas",
  },
  {
    postal_code: "92061",
    full_city_name: "Pauma Valley, CA",
    city_name: "Pauma Valley",
  },
  {
    postal_code: "83276",
    full_city_name: "Soda Springs, ID",
    city_name: "Soda Springs",
  },
  {
    postal_code: "24228",
    full_city_name: "Clintwood, VA",
    city_name: "Clintwood",
  },
  {
    postal_code: "22488",
    full_city_name: "Kinsale, VA",
    city_name: "Kinsale",
  },
  {
    postal_code: "49315",
    full_city_name: "Byron Center, MI",
    city_name: "Byron Center",
  },
  {
    postal_code: "17985",
    full_city_name: "Zion Grove, PA",
    city_name: "Zion Grove",
  },
  {
    postal_code: "29847",
    full_city_name: "Trenton, SC",
    city_name: "Trenton",
  },
  {
    postal_code: "14098",
    full_city_name: "Lyndonville, NY",
    city_name: "Lyndonville",
  },
  {
    postal_code: "35756",
    full_city_name: "Madison, AL",
    city_name: "Madison",
  },
  {
    postal_code: "89169",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "88260",
    full_city_name: "Lovington, NM",
    city_name: "Lovington",
  },
  {
    postal_code: "72908",
    full_city_name: "Fort Smith, AR",
    city_name: "Fort Smith",
  },
  {
    postal_code: "62557",
    full_city_name: "Pana, IL",
    city_name: "Pana",
  },
  {
    postal_code: "10523",
    full_city_name: "Elmsford, NY",
    city_name: "Elmsford",
  },
  {
    postal_code: "07032",
    full_city_name: "Kearny, NJ",
    city_name: "Kearny",
  },
  {
    postal_code: "04757",
    full_city_name: "Mapleton, ME",
    city_name: "Mapleton",
  },
  {
    postal_code: "50144",
    full_city_name: "Leon, IA",
    city_name: "Leon",
  },
  {
    postal_code: "73438",
    full_city_name: "Healdton, OK",
    city_name: "Healdton",
  },
  {
    postal_code: "45710",
    full_city_name: "Albany, OH",
    city_name: "Albany",
  },
  {
    postal_code: "29212",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "89060",
    full_city_name: "Pahrump, NV",
    city_name: "Pahrump",
  },
  {
    postal_code: "19506",
    full_city_name: "Bernville, PA",
    city_name: "Bernville",
  },
  {
    postal_code: "19153",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "74937",
    full_city_name: "Heavener, OK",
    city_name: "Heavener",
  },
  {
    postal_code: "14741",
    full_city_name: "Great Valley, NY",
    city_name: "Great Valley",
  },
  {
    postal_code: "49012",
    full_city_name: "Augusta, MI",
    city_name: "Augusta",
  },
  {
    postal_code: "07045",
    full_city_name: "Montville, NJ",
    city_name: "Montville",
  },
  {
    postal_code: "54201",
    full_city_name: "Algoma, WI",
    city_name: "Algoma",
  },
  {
    postal_code: "23455",
    full_city_name: "Virginia Beach, VA",
    city_name: "Virginia Beach",
  },
  {
    postal_code: "52314",
    full_city_name: "Mount Vernon, IA",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "46808",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "07830",
    full_city_name: "Califon, NJ",
    city_name: "Califon",
  },
  {
    postal_code: "48367",
    full_city_name: "Leonard, MI",
    city_name: "Leonard",
  },
  {
    postal_code: "89124",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "14787",
    full_city_name: "Westfield, NY",
    city_name: "Westfield",
  },
  {
    postal_code: "04015",
    full_city_name: "Casco, ME",
    city_name: "Casco",
  },
  {
    postal_code: "65668",
    full_city_name: "Hermitage, MO",
    city_name: "Hermitage",
  },
  {
    postal_code: "50452",
    full_city_name: "Latimer, IA",
    city_name: "Latimer",
  },
  {
    postal_code: "68959",
    full_city_name: "Minden, NE",
    city_name: "Minden",
  },
  {
    postal_code: "27809",
    full_city_name: "Battleboro, NC",
    city_name: "Battleboro",
  },
  {
    postal_code: "38564",
    full_city_name: "Granville, TN",
    city_name: "Granville",
  },
  {
    postal_code: "77663",
    full_city_name: "Village Mills, TX",
    city_name: "Village Mills",
  },
  {
    postal_code: "97836",
    full_city_name: "Heppner, OR",
    city_name: "Heppner",
  },
  {
    postal_code: "74872",
    full_city_name: "Stratford, OK",
    city_name: "Stratford",
  },
  {
    postal_code: "84779",
    full_city_name: "Virgin, UT",
    city_name: "Virgin",
  },
  {
    postal_code: "20184",
    full_city_name: "Upperville, VA",
    city_name: "Upperville",
  },
  {
    postal_code: "10594",
    full_city_name: "Thornwood, NY",
    city_name: "Thornwood",
  },
  {
    postal_code: "01262",
    full_city_name: "Stockbridge, MA",
    city_name: "Stockbridge",
  },
  {
    postal_code: "01259",
    full_city_name: "Southfield, MA",
    city_name: "Southfield",
  },
  {
    postal_code: "08345",
    full_city_name: "Newport, NJ",
    city_name: "Newport",
  },
  {
    postal_code: "45760",
    full_city_name: "Middleport, OH",
    city_name: "Middleport",
  },
  {
    postal_code: "32601",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "12857",
    full_city_name: "Olmstedville, NY",
    city_name: "Olmstedville",
  },
  {
    postal_code: "65723",
    full_city_name: "Pierce City, MO",
    city_name: "Pierce City",
  },
  {
    postal_code: "55943",
    full_city_name: "Houston, MN",
    city_name: "Houston",
  },
  {
    postal_code: "43731",
    full_city_name: "Crooksville, OH",
    city_name: "Crooksville",
  },
  {
    postal_code: "35611",
    full_city_name: "Athens, AL",
    city_name: "Athens",
  },
  {
    postal_code: "46402",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "49892",
    full_city_name: "Vulcan, MI",
    city_name: "Vulcan",
  },
  {
    postal_code: "07062",
    full_city_name: "Plainfield, NJ",
    city_name: "Plainfield",
  },
  {
    postal_code: "32066",
    full_city_name: "Mayo, FL",
    city_name: "Mayo",
  },
  {
    postal_code: "46409",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "23035",
    full_city_name: "Cobbs Creek, VA",
    city_name: "Cobbs Creek",
  },
  {
    postal_code: "62863",
    full_city_name: "Mount Carmel, IL",
    city_name: "Mount Carmel",
  },
  {
    postal_code: "08810",
    full_city_name: "Dayton, NJ",
    city_name: "Dayton",
  },
  {
    postal_code: "17065",
    full_city_name: "Mount Holly Springs, PA",
    city_name: "Mount Holly Springs",
  },
  {
    postal_code: "49421",
    full_city_name: "Hesperia, MI",
    city_name: "Hesperia",
  },
  {
    postal_code: "17301",
    full_city_name: "Abbottstown, PA",
    city_name: "Abbottstown",
  },
  {
    postal_code: "35453",
    full_city_name: "Cottondale, AL",
    city_name: "Cottondale",
  },
  {
    postal_code: "70344",
    full_city_name: "Chauvin, LA",
    city_name: "Chauvin",
  },
  {
    postal_code: "08827",
    full_city_name: "Hampton, NJ",
    city_name: "Hampton",
  },
  {
    postal_code: "73007",
    full_city_name: "Arcadia, OK",
    city_name: "Arcadia",
  },
  {
    postal_code: "74873",
    full_city_name: "Tecumseh, OK",
    city_name: "Tecumseh",
  },
  {
    postal_code: "14489",
    full_city_name: "Lyons, NY",
    city_name: "Lyons",
  },
  {
    postal_code: "02111",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "77983",
    full_city_name: "Seadrift, TX",
    city_name: "Seadrift",
  },
  {
    postal_code: "30441",
    full_city_name: "Midville, GA",
    city_name: "Midville",
  },
  {
    postal_code: "49617",
    full_city_name: "Beulah, MI",
    city_name: "Beulah",
  },
  {
    postal_code: "76306",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "39652",
    full_city_name: "Magnolia, MS",
    city_name: "Magnolia",
  },
  {
    postal_code: "12927",
    full_city_name: "Cranberry Lake, NY",
    city_name: "Cranberry Lake",
  },
  {
    postal_code: "68152",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "97535",
    full_city_name: "Phoenix, OR",
    city_name: "Phoenix",
  },
  {
    postal_code: "61501",
    full_city_name: "Astoria, IL",
    city_name: "Astoria",
  },
  {
    postal_code: "84782",
    full_city_name: "Veyo, UT",
    city_name: "Veyo",
  },
  {
    postal_code: "53530",
    full_city_name: "Darlington, WI",
    city_name: "Darlington",
  },
  {
    postal_code: "17983",
    full_city_name: "Valley View, PA",
    city_name: "Valley View",
  },
  {
    postal_code: "83871",
    full_city_name: "Troy, ID",
    city_name: "Troy",
  },
  {
    postal_code: "54841",
    full_city_name: "Haugen, WI",
    city_name: "Haugen",
  },
  {
    postal_code: "43971",
    full_city_name: "Yorkville, OH",
    city_name: "Yorkville",
  },
  {
    postal_code: "57071",
    full_city_name: "Volga, SD",
    city_name: "Volga",
  },
  {
    postal_code: "73108",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "17004",
    full_city_name: "Belleville, PA",
    city_name: "Belleville",
  },
  {
    postal_code: "36256",
    full_city_name: "Daviston, AL",
    city_name: "Daviston",
  },
  {
    postal_code: "31806",
    full_city_name: "Ellaville, GA",
    city_name: "Ellaville",
  },
  {
    postal_code: "93667",
    full_city_name: "Tollhouse, CA",
    city_name: "Tollhouse",
  },
  {
    postal_code: "50229",
    full_city_name: "Prole, IA",
    city_name: "Prole",
  },
  {
    postal_code: "49912",
    full_city_name: "Bruce Crossing, MI",
    city_name: "Bruce Crossing",
  },
  {
    postal_code: "27507",
    full_city_name: "Bullock, NC",
    city_name: "Bullock",
  },
  {
    postal_code: "71439",
    full_city_name: "Hornbeck, LA",
    city_name: "Hornbeck",
  },
  {
    postal_code: "16938",
    full_city_name: "Morris, PA",
    city_name: "Morris",
  },
  {
    postal_code: "47371",
    full_city_name: "Portland, IN",
    city_name: "Portland",
  },
  {
    postal_code: "62305",
    full_city_name: "Quincy, IL",
    city_name: "Quincy",
  },
  {
    postal_code: "70764",
    full_city_name: "Plaquemine, LA",
    city_name: "Plaquemine",
  },
  {
    postal_code: "54940",
    full_city_name: "Fremont, WI",
    city_name: "Fremont",
  },
  {
    postal_code: "07825",
    full_city_name: "Blairstown, NJ",
    city_name: "Blairstown",
  },
  {
    postal_code: "32619",
    full_city_name: "Bell, FL",
    city_name: "Bell",
  },
  {
    postal_code: "21036",
    full_city_name: "Dayton, MD",
    city_name: "Dayton",
  },
  {
    postal_code: "83338",
    full_city_name: "Jerome, ID",
    city_name: "Jerome",
  },
  {
    postal_code: "61490",
    full_city_name: "Woodhull, IL",
    city_name: "Woodhull",
  },
  {
    postal_code: "73527",
    full_city_name: "Cache, OK",
    city_name: "Cache",
  },
  {
    postal_code: "54139",
    full_city_name: "Lena, WI",
    city_name: "Lena",
  },
  {
    postal_code: "83274",
    full_city_name: "Shelley, ID",
    city_name: "Shelley",
  },
  {
    postal_code: "35973",
    full_city_name: "Gaylesville, AL",
    city_name: "Gaylesville",
  },
  {
    postal_code: "04680",
    full_city_name: "Steuben, ME",
    city_name: "Steuben",
  },
  {
    postal_code: "62684",
    full_city_name: "Sherman, IL",
    city_name: "Sherman",
  },
  {
    postal_code: "46511",
    full_city_name: "Culver, IN",
    city_name: "Culver",
  },
  {
    postal_code: "56529",
    full_city_name: "Dilworth, MN",
    city_name: "Dilworth",
  },
  {
    postal_code: "93430",
    full_city_name: "Cayucos, CA",
    city_name: "Cayucos",
  },
  {
    postal_code: "54228",
    full_city_name: "Mishicot, WI",
    city_name: "Mishicot",
  },
  {
    postal_code: "49743",
    full_city_name: "Hawks, MI",
    city_name: "Hawks",
  },
  {
    postal_code: "75650",
    full_city_name: "Hallsville, TX",
    city_name: "Hallsville",
  },
  {
    postal_code: "07677",
    full_city_name: "Woodcliff Lake, NJ",
    city_name: "Woodcliff Lake",
  },
  {
    postal_code: "72903",
    full_city_name: "Fort Smith, AR",
    city_name: "Fort Smith",
  },
  {
    postal_code: "54210",
    full_city_name: "Ellison Bay, WI",
    city_name: "Ellison Bay",
  },
  {
    postal_code: "24174",
    full_city_name: "Thaxton, VA",
    city_name: "Thaxton",
  },
  {
    postal_code: "23350",
    full_city_name: "Exmore, VA",
    city_name: "Exmore",
  },
  {
    postal_code: "48832",
    full_city_name: "Elwell, MI",
    city_name: "Elwell",
  },
  {
    postal_code: "14608",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "72472",
    full_city_name: "Trumann, AR",
    city_name: "Trumann",
  },
  {
    postal_code: "81645",
    full_city_name: "Minturn, CO",
    city_name: "Minturn",
  },
  {
    postal_code: "75451",
    full_city_name: "Leesburg, TX",
    city_name: "Leesburg",
  },
  {
    postal_code: "24133",
    full_city_name: "Patrick Springs, VA",
    city_name: "Patrick Springs",
  },
  {
    postal_code: "23070",
    full_city_name: "Hardyville, VA",
    city_name: "Hardyville",
  },
  {
    postal_code: "68045",
    full_city_name: "Oakland, NE",
    city_name: "Oakland",
  },
  {
    postal_code: "83822",
    full_city_name: "Oldtown, ID",
    city_name: "Oldtown",
  },
  {
    postal_code: "24910",
    full_city_name: "Alderson, WV",
    city_name: "Alderson",
  },
  {
    postal_code: "53048",
    full_city_name: "Lomira, WI",
    city_name: "Lomira",
  },
  {
    postal_code: "38603",
    full_city_name: "Ashland, MS",
    city_name: "Ashland",
  },
  {
    postal_code: "72675",
    full_city_name: "Saint Joe, AR",
    city_name: "Saint Joe",
  },
  {
    postal_code: "17554",
    full_city_name: "Mountville, PA",
    city_name: "Mountville",
  },
  {
    postal_code: "65771",
    full_city_name: "Walnut Shade, MO",
    city_name: "Walnut Shade",
  },
  {
    postal_code: "49283",
    full_city_name: "Spring Arbor, MI",
    city_name: "Spring Arbor",
  },
  {
    postal_code: "65739",
    full_city_name: "Ridgedale, MO",
    city_name: "Ridgedale",
  },
  {
    postal_code: "07111",
    full_city_name: "Irvington, NJ",
    city_name: "Irvington",
  },
  {
    postal_code: "20625",
    full_city_name: "Cobb Island, MD",
    city_name: "Cobb Island",
  },
  {
    postal_code: "36421",
    full_city_name: "Andalusia, AL",
    city_name: "Andalusia",
  },
  {
    postal_code: "07028",
    full_city_name: "Glen Ridge, NJ",
    city_name: "Glen Ridge",
  },
  {
    postal_code: "97530",
    full_city_name: "Jacksonville, OR",
    city_name: "Jacksonville",
  },
  {
    postal_code: "17401",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "21671",
    full_city_name: "Tilghman, MD",
    city_name: "Tilghman",
  },
  {
    postal_code: "24091",
    full_city_name: "Floyd, VA",
    city_name: "Floyd",
  },
  {
    postal_code: "48227",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "07009",
    full_city_name: "Cedar Grove, NJ",
    city_name: "Cedar Grove",
  },
  {
    postal_code: "98342",
    full_city_name: "Indianola, WA",
    city_name: "Indianola",
  },
  {
    postal_code: "08510",
    full_city_name: "Millstone Township, NJ",
    city_name: "Millstone Township",
  },
  {
    postal_code: "98351",
    full_city_name: "Longbranch, WA",
    city_name: "Longbranch",
  },
  {
    postal_code: "16239",
    full_city_name: "Marienville, PA",
    city_name: "Marienville",
  },
  {
    postal_code: "59501",
    full_city_name: "Havre, MT",
    city_name: "Havre",
  },
  {
    postal_code: "18344",
    full_city_name: "Mount Pocono, PA",
    city_name: "Mount Pocono",
  },
  {
    postal_code: "08349",
    full_city_name: "Port Norris, NJ",
    city_name: "Port Norris",
  },
  {
    postal_code: "47394",
    full_city_name: "Winchester, IN",
    city_name: "Winchester",
  },
  {
    postal_code: "32409",
    full_city_name: "Panama City, FL",
    city_name: "Panama City",
  },
  {
    postal_code: "48001",
    full_city_name: "Algonac, MI",
    city_name: "Algonac",
  },
  {
    postal_code: "08514",
    full_city_name: "Cream Ridge, NJ",
    city_name: "Cream Ridge",
  },
  {
    postal_code: "80433",
    full_city_name: "Conifer, CO",
    city_name: "Conifer",
  },
  {
    postal_code: "07885",
    full_city_name: "Wharton, NJ",
    city_name: "Wharton",
  },
  {
    postal_code: "17061",
    full_city_name: "Millersburg, PA",
    city_name: "Millersburg",
  },
  {
    postal_code: "34760",
    full_city_name: "Oakland, FL",
    city_name: "Oakland",
  },
  {
    postal_code: "95563",
    full_city_name: "Salyer, CA",
    city_name: "Salyer",
  },
  {
    postal_code: "14706",
    full_city_name: "Allegany, NY",
    city_name: "Allegany",
  },
  {
    postal_code: "71834",
    full_city_name: "Doddridge, AR",
    city_name: "Doddridge",
  },
  {
    postal_code: "81641",
    full_city_name: "Meeker, CO",
    city_name: "Meeker",
  },
  {
    postal_code: "51436",
    full_city_name: "Breda, IA",
    city_name: "Breda",
  },
  {
    postal_code: "30808",
    full_city_name: "Dearing, GA",
    city_name: "Dearing",
  },
  {
    postal_code: "95306",
    full_city_name: "Catheys Valley, CA",
    city_name: "Catheys Valley",
  },
  {
    postal_code: "36611",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "42647",
    full_city_name: "Stearns, KY",
    city_name: "Stearns",
  },
  {
    postal_code: "04776",
    full_city_name: "Sherman, ME",
    city_name: "Sherman",
  },
  {
    postal_code: "65745",
    full_city_name: "Seligman, MO",
    city_name: "Seligman",
  },
  {
    postal_code: "46975",
    full_city_name: "Rochester, IN",
    city_name: "Rochester",
  },
  {
    postal_code: "35148",
    full_city_name: "Sumiton, AL",
    city_name: "Sumiton",
  },
  {
    postal_code: "32140",
    full_city_name: "Florahome, FL",
    city_name: "Florahome",
  },
  {
    postal_code: "32442",
    full_city_name: "Grand Ridge, FL",
    city_name: "Grand Ridge",
  },
  {
    postal_code: "70339",
    full_city_name: "Pierre Part, LA",
    city_name: "Pierre Part",
  },
  {
    postal_code: "83850",
    full_city_name: "Pinehurst, ID",
    city_name: "Pinehurst",
  },
  {
    postal_code: "66611",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "14125",
    full_city_name: "Oakfield, NY",
    city_name: "Oakfield",
  },
  {
    postal_code: "68649",
    full_city_name: "North Bend, NE",
    city_name: "North Bend",
  },
  {
    postal_code: "73754",
    full_city_name: "Lahoma, OK",
    city_name: "Lahoma",
  },
  {
    postal_code: "15672",
    full_city_name: "New Stanton, PA",
    city_name: "New Stanton",
  },
  {
    postal_code: "43986",
    full_city_name: "Jewett, OH",
    city_name: "Jewett",
  },
  {
    postal_code: "46783",
    full_city_name: "Roanoke, IN",
    city_name: "Roanoke",
  },
  {
    postal_code: "68057",
    full_city_name: "Scribner, NE",
    city_name: "Scribner",
  },
  {
    postal_code: "24747",
    full_city_name: "Rock, WV",
    city_name: "Rock",
  },
  {
    postal_code: "16950",
    full_city_name: "Westfield, PA",
    city_name: "Westfield",
  },
  {
    postal_code: "56458",
    full_city_name: "Lake George, MN",
    city_name: "Lake George",
  },
  {
    postal_code: "54527",
    full_city_name: "Glidden, WI",
    city_name: "Glidden",
  },
  {
    postal_code: "16041",
    full_city_name: "Karns City, PA",
    city_name: "Karns City",
  },
  {
    postal_code: "05072",
    full_city_name: "Strafford, VT",
    city_name: "Strafford",
  },
  {
    postal_code: "13409",
    full_city_name: "Munnsville, NY",
    city_name: "Munnsville",
  },
  {
    postal_code: "04671",
    full_city_name: "Robbinston, ME",
    city_name: "Robbinston",
  },
  {
    postal_code: "05046",
    full_city_name: "Groton, VT",
    city_name: "Groton",
  },
  {
    postal_code: "12957",
    full_city_name: "Moira, NY",
    city_name: "Moira",
  },
  {
    postal_code: "18214",
    full_city_name: "Barnesville, PA",
    city_name: "Barnesville",
  },
  {
    postal_code: "15931",
    full_city_name: "Ebensburg, PA",
    city_name: "Ebensburg",
  },
  {
    postal_code: "78543",
    full_city_name: "Elsa, TX",
    city_name: "Elsa",
  },
  {
    postal_code: "49650",
    full_city_name: "Lake Ann, MI",
    city_name: "Lake Ann",
  },
  {
    postal_code: "49645",
    full_city_name: "Kaleva, MI",
    city_name: "Kaleva",
  },
  {
    postal_code: "74849",
    full_city_name: "Konawa, OK",
    city_name: "Konawa",
  },
  {
    postal_code: "08801",
    full_city_name: "Annandale, NJ",
    city_name: "Annandale",
  },
  {
    postal_code: "17764",
    full_city_name: "Renovo, PA",
    city_name: "Renovo",
  },
  {
    postal_code: "80136",
    full_city_name: "Strasburg, CO",
    city_name: "Strasburg",
  },
  {
    postal_code: "97417",
    full_city_name: "Canyonville, OR",
    city_name: "Canyonville",
  },
  {
    postal_code: "47837",
    full_city_name: "Carbon, IN",
    city_name: "Carbon",
  },
  {
    postal_code: "54630",
    full_city_name: "Galesville, WI",
    city_name: "Galesville",
  },
  {
    postal_code: "73526",
    full_city_name: "Blair, OK",
    city_name: "Blair",
  },
  {
    postal_code: "42050",
    full_city_name: "Hickman, KY",
    city_name: "Hickman",
  },
  {
    postal_code: "04342",
    full_city_name: "Dresden, ME",
    city_name: "Dresden",
  },
  {
    postal_code: "75855",
    full_city_name: "Oakwood, TX",
    city_name: "Oakwood",
  },
  {
    postal_code: "04344",
    full_city_name: "Farmingdale, ME",
    city_name: "Farmingdale",
  },
  {
    postal_code: "76374",
    full_city_name: "Olney, TX",
    city_name: "Olney",
  },
  {
    postal_code: "48436",
    full_city_name: "Gaines, MI",
    city_name: "Gaines",
  },
  {
    postal_code: "04747",
    full_city_name: "Island Falls, ME",
    city_name: "Island Falls",
  },
  {
    postal_code: "04955",
    full_city_name: "New Sharon, ME",
    city_name: "New Sharon",
  },
  {
    postal_code: "48374",
    full_city_name: "Novi, MI",
    city_name: "Novi",
  },
  {
    postal_code: "76825",
    full_city_name: "Brady, TX",
    city_name: "Brady",
  },
  {
    postal_code: "31791",
    full_city_name: "Sylvester, GA",
    city_name: "Sylvester",
  },
  {
    postal_code: "48127",
    full_city_name: "Dearborn Heights, MI",
    city_name: "Dearborn Heights",
  },
  {
    postal_code: "67101",
    full_city_name: "Maize, KS",
    city_name: "Maize",
  },
  {
    postal_code: "19956",
    full_city_name: "Laurel, DE",
    city_name: "Laurel",
  },
  {
    postal_code: "97630",
    full_city_name: "Lakeview, OR",
    city_name: "Lakeview",
  },
  {
    postal_code: "21794",
    full_city_name: "West Friendship, MD",
    city_name: "West Friendship",
  },
  {
    postal_code: "35763",
    full_city_name: "Owens Cross Roads, AL",
    city_name: "Owens Cross Roads",
  },
  {
    postal_code: "49045",
    full_city_name: "Decatur, MI",
    city_name: "Decatur",
  },
  {
    postal_code: "32346",
    full_city_name: "Panacea, FL",
    city_name: "Panacea",
  },
  {
    postal_code: "30740",
    full_city_name: "Rocky Face, GA",
    city_name: "Rocky Face",
  },
  {
    postal_code: "71119",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "42041",
    full_city_name: "Fulton, KY",
    city_name: "Fulton",
  },
  {
    postal_code: "22576",
    full_city_name: "Weems, VA",
    city_name: "Weems",
  },
  {
    postal_code: "88007",
    full_city_name: "Las Cruces, NM",
    city_name: "Las Cruces",
  },
  {
    postal_code: "21536",
    full_city_name: "Grantsville, MD",
    city_name: "Grantsville",
  },
  {
    postal_code: "08848",
    full_city_name: "Milford, NJ",
    city_name: "Milford",
  },
  {
    postal_code: "97148",
    full_city_name: "Yamhill, OR",
    city_name: "Yamhill",
  },
  {
    postal_code: "21912",
    full_city_name: "Warwick, MD",
    city_name: "Warwick",
  },
  {
    postal_code: "29565",
    full_city_name: "Latta, SC",
    city_name: "Latta",
  },
  {
    postal_code: "17074",
    full_city_name: "Newport, PA",
    city_name: "Newport",
  },
  {
    postal_code: "24102",
    full_city_name: "Henry, VA",
    city_name: "Henry",
  },
  {
    postal_code: "45623",
    full_city_name: "Crown City, OH",
    city_name: "Crown City",
  },
  {
    postal_code: "19504",
    full_city_name: "Barto, PA",
    city_name: "Barto",
  },
  {
    postal_code: "04781",
    full_city_name: "Wallagrass, ME",
    city_name: "Wallagrass",
  },
  {
    postal_code: "12836",
    full_city_name: "Hague, NY",
    city_name: "Hague",
  },
  {
    postal_code: "66508",
    full_city_name: "Marysville, KS",
    city_name: "Marysville",
  },
  {
    postal_code: "19021",
    full_city_name: "Croydon, PA",
    city_name: "Croydon",
  },
  {
    postal_code: "07828",
    full_city_name: "Budd Lake, NJ",
    city_name: "Budd Lake",
  },
  {
    postal_code: "07738",
    full_city_name: "Lincroft, NJ",
    city_name: "Lincroft",
  },
  {
    postal_code: "54929",
    full_city_name: "Clintonville, WI",
    city_name: "Clintonville",
  },
  {
    postal_code: "07020",
    full_city_name: "Edgewater, NJ",
    city_name: "Edgewater",
  },
  {
    postal_code: "77612",
    full_city_name: "Buna, TX",
    city_name: "Buna",
  },
  {
    postal_code: "19344",
    full_city_name: "Honey Brook, PA",
    city_name: "Honey Brook",
  },
  {
    postal_code: "08738",
    full_city_name: "Mantoloking, NJ",
    city_name: "Mantoloking",
  },
  {
    postal_code: "08242",
    full_city_name: "Rio Grande, NJ",
    city_name: "Rio Grande",
  },
  {
    postal_code: "96770",
    full_city_name: "Maunaloa, HI",
    city_name: "Maunaloa",
  },
  {
    postal_code: "18626",
    full_city_name: "Laporte, PA",
    city_name: "Laporte",
  },
  {
    postal_code: "28526",
    full_city_name: "Dover, NC",
    city_name: "Dover",
  },
  {
    postal_code: "04684",
    full_city_name: "Surry, ME",
    city_name: "Surry",
  },
  {
    postal_code: "66512",
    full_city_name: "Meriden, KS",
    city_name: "Meriden",
  },
  {
    postal_code: "13322",
    full_city_name: "Clayville, NY",
    city_name: "Clayville",
  },
  {
    postal_code: "28636",
    full_city_name: "Hiddenite, NC",
    city_name: "Hiddenite",
  },
  {
    postal_code: "98057",
    full_city_name: "Renton, WA",
    city_name: "Renton",
  },
  {
    postal_code: "05060",
    full_city_name: "Randolph, VT",
    city_name: "Randolph",
  },
  {
    postal_code: "79041",
    full_city_name: "Hale Center, TX",
    city_name: "Hale Center",
  },
  {
    postal_code: "94111",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "42087",
    full_city_name: "Wickliffe, KY",
    city_name: "Wickliffe",
  },
  {
    postal_code: "97436",
    full_city_name: "Elkton, OR",
    city_name: "Elkton",
  },
  {
    postal_code: "17038",
    full_city_name: "Jonestown, PA",
    city_name: "Jonestown",
  },
  {
    postal_code: "14472",
    full_city_name: "Honeoye Falls, NY",
    city_name: "Honeoye Falls",
  },
  {
    postal_code: "43314",
    full_city_name: "Caledonia, OH",
    city_name: "Caledonia",
  },
  {
    postal_code: "52776",
    full_city_name: "West Liberty, IA",
    city_name: "West Liberty",
  },
  {
    postal_code: "41265",
    full_city_name: "Van Lear, KY",
    city_name: "Van Lear",
  },
  {
    postal_code: "66783",
    full_city_name: "Yates Center, KS",
    city_name: "Yates Center",
  },
  {
    postal_code: "97525",
    full_city_name: "Gold Hill, OR",
    city_name: "Gold Hill",
  },
  {
    postal_code: "91902",
    full_city_name: "Bonita, CA",
    city_name: "Bonita",
  },
  {
    postal_code: "71235",
    full_city_name: "Dubach, LA",
    city_name: "Dubach",
  },
  {
    postal_code: "08098",
    full_city_name: "Woodstown, NJ",
    city_name: "Woodstown",
  },
  {
    postal_code: "31822",
    full_city_name: "Pine Mountain, GA",
    city_name: "Pine Mountain",
  },
  {
    postal_code: "10605",
    full_city_name: "White Plains, NY",
    city_name: "White Plains",
  },
  {
    postal_code: "96708",
    full_city_name: "Haiku, HI",
    city_name: "Haiku",
  },
  {
    postal_code: "56220",
    full_city_name: "Canby, MN",
    city_name: "Canby",
  },
  {
    postal_code: "44047",
    full_city_name: "Jefferson, OH",
    city_name: "Jefferson",
  },
  {
    postal_code: "15851",
    full_city_name: "Reynoldsville, PA",
    city_name: "Reynoldsville",
  },
  {
    postal_code: "56183",
    full_city_name: "Westbrook, MN",
    city_name: "Westbrook",
  },
  {
    postal_code: "29666",
    full_city_name: "Ninety Six, SC",
    city_name: "Ninety Six",
  },
  {
    postal_code: "97110",
    full_city_name: "Cannon Beach, OR",
    city_name: "Cannon Beach",
  },
  {
    postal_code: "64048",
    full_city_name: "Holt, MO",
    city_name: "Holt",
  },
  {
    postal_code: "31757",
    full_city_name: "Thomasville, GA",
    city_name: "Thomasville",
  },
  {
    postal_code: "37058",
    full_city_name: "Dover, TN",
    city_name: "Dover",
  },
  {
    postal_code: "53006",
    full_city_name: "Brownsville, WI",
    city_name: "Brownsville",
  },
  {
    postal_code: "56142",
    full_city_name: "Ivanhoe, MN",
    city_name: "Ivanhoe",
  },
  {
    postal_code: "10992",
    full_city_name: "Washingtonville, NY",
    city_name: "Washingtonville",
  },
  {
    postal_code: "61486",
    full_city_name: "Viola, IL",
    city_name: "Viola",
  },
  {
    postal_code: "97828",
    full_city_name: "Enterprise, OR",
    city_name: "Enterprise",
  },
  {
    postal_code: "11715",
    full_city_name: "Blue Point, NY",
    city_name: "Blue Point",
  },
  {
    postal_code: "76578",
    full_city_name: "Thrall, TX",
    city_name: "Thrall",
  },
  {
    postal_code: "54773",
    full_city_name: "Whitehall, WI",
    city_name: "Whitehall",
  },
  {
    postal_code: "97496",
    full_city_name: "Winston, OR",
    city_name: "Winston",
  },
  {
    postal_code: "85616",
    full_city_name: "Huachuca City, AZ",
    city_name: "Huachuca City",
  },
  {
    postal_code: "47546",
    full_city_name: "Jasper, IN",
    city_name: "Jasper",
  },
  {
    postal_code: "78599",
    full_city_name: "Weslaco, TX",
    city_name: "Weslaco",
  },
  {
    postal_code: "44606",
    full_city_name: "Apple Creek, OH",
    city_name: "Apple Creek",
  },
  {
    postal_code: "48418",
    full_city_name: "Byron, MI",
    city_name: "Byron",
  },
  {
    postal_code: "97227",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "82431",
    full_city_name: "Lovell, WY",
    city_name: "Lovell",
  },
  {
    postal_code: "67851",
    full_city_name: "Holcomb, KS",
    city_name: "Holcomb",
  },
  {
    postal_code: "97462",
    full_city_name: "Oakland, OR",
    city_name: "Oakland",
  },
  {
    postal_code: "54813",
    full_city_name: "Barronett, WI",
    city_name: "Barronett",
  },
  {
    postal_code: "80420",
    full_city_name: "Alma, CO",
    city_name: "Alma",
  },
  {
    postal_code: "31643",
    full_city_name: "Quitman, GA",
    city_name: "Quitman",
  },
  {
    postal_code: "29229",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "52146",
    full_city_name: "Harpers Ferry, IA",
    city_name: "Harpers Ferry",
  },
  {
    postal_code: "72644",
    full_city_name: "Lead Hill, AR",
    city_name: "Lead Hill",
  },
  {
    postal_code: "15148",
    full_city_name: "Wilmerding, PA",
    city_name: "Wilmerding",
  },
  {
    postal_code: "31626",
    full_city_name: "Boston, GA",
    city_name: "Boston",
  },
  {
    postal_code: "28781",
    full_city_name: "Topton, NC",
    city_name: "Topton",
  },
  {
    postal_code: "47665",
    full_city_name: "Owensville, IN",
    city_name: "Owensville",
  },
  {
    postal_code: "97449",
    full_city_name: "Lakeside, OR",
    city_name: "Lakeside",
  },
  {
    postal_code: "56175",
    full_city_name: "Tracy, MN",
    city_name: "Tracy",
  },
  {
    postal_code: "97458",
    full_city_name: "Myrtle Point, OR",
    city_name: "Myrtle Point",
  },
  {
    postal_code: "56232",
    full_city_name: "Dawson, MN",
    city_name: "Dawson",
  },
  {
    postal_code: "98611",
    full_city_name: "Castle Rock, WA",
    city_name: "Castle Rock",
  },
  {
    postal_code: "97457",
    full_city_name: "Myrtle Creek, OR",
    city_name: "Myrtle Creek",
  },
  {
    postal_code: "36020",
    full_city_name: "Coosada, AL",
    city_name: "Coosada",
  },
  {
    postal_code: "48880",
    full_city_name: "Saint Louis, MI",
    city_name: "Saint Louis",
  },
  {
    postal_code: "13060",
    full_city_name: "Elbridge, NY",
    city_name: "Elbridge",
  },
  {
    postal_code: "97820",
    full_city_name: "Canyon City, OR",
    city_name: "Canyon City",
  },
  {
    postal_code: "31788",
    full_city_name: "Moultrie, GA",
    city_name: "Moultrie",
  },
  {
    postal_code: "28690",
    full_city_name: "Valdese, NC",
    city_name: "Valdese",
  },
  {
    postal_code: "83615",
    full_city_name: "Donnelly, ID",
    city_name: "Donnelly",
  },
  {
    postal_code: "44671",
    full_city_name: "Sandyville, OH",
    city_name: "Sandyville",
  },
  {
    postal_code: "32648",
    full_city_name: "Horseshoe Beach, FL",
    city_name: "Horseshoe Beach",
  },
  {
    postal_code: "16668",
    full_city_name: "Patton, PA",
    city_name: "Patton",
  },
  {
    postal_code: "97042",
    full_city_name: "Mulino, OR",
    city_name: "Mulino",
  },
  {
    postal_code: "31773",
    full_city_name: "Ochlocknee, GA",
    city_name: "Ochlocknee",
  },
  {
    postal_code: "97392",
    full_city_name: "Turner, OR",
    city_name: "Turner",
  },
  {
    postal_code: "39828",
    full_city_name: "Cairo, GA",
    city_name: "Cairo",
  },
  {
    postal_code: "97862",
    full_city_name: "Milton Freewater, OR",
    city_name: "Milton Freewater",
  },
  {
    postal_code: "77611",
    full_city_name: "Bridge City, TX",
    city_name: "Bridge City",
  },
  {
    postal_code: "22539",
    full_city_name: "Reedville, VA",
    city_name: "Reedville",
  },
  {
    postal_code: "76935",
    full_city_name: "Christoval, TX",
    city_name: "Christoval",
  },
  {
    postal_code: "97465",
    full_city_name: "Port Orford, OR",
    city_name: "Port Orford",
  },
  {
    postal_code: "48731",
    full_city_name: "Elkton, MI",
    city_name: "Elkton",
  },
  {
    postal_code: "76856",
    full_city_name: "Mason, TX",
    city_name: "Mason",
  },
  {
    postal_code: "39827",
    full_city_name: "Cairo, GA",
    city_name: "Cairo",
  },
  {
    postal_code: "72685",
    full_city_name: "Western Grove, AR",
    city_name: "Western Grove",
  },
  {
    postal_code: "46701",
    full_city_name: "Albion, IN",
    city_name: "Albion",
  },
  {
    postal_code: "97049",
    full_city_name: "Rhododendron, OR",
    city_name: "Rhododendron",
  },
  {
    postal_code: "32628",
    full_city_name: "Cross City, FL",
    city_name: "Cross City",
  },
  {
    postal_code: "24171",
    full_city_name: "Stuart, VA",
    city_name: "Stuart",
  },
  {
    postal_code: "14885",
    full_city_name: "Troupsburg, NY",
    city_name: "Troupsburg",
  },
  {
    postal_code: "44629",
    full_city_name: "Gnadenhutten, OH",
    city_name: "Gnadenhutten",
  },
  {
    postal_code: "97396",
    full_city_name: "Willamina, OR",
    city_name: "Willamina",
  },
  {
    postal_code: "49668",
    full_city_name: "Mesick, MI",
    city_name: "Mesick",
  },
  {
    postal_code: "48632",
    full_city_name: "Lake, MI",
    city_name: "Lake",
  },
  {
    postal_code: "35179",
    full_city_name: "Vinemont, AL",
    city_name: "Vinemont",
  },
  {
    postal_code: "48635",
    full_city_name: "Lupton, MI",
    city_name: "Lupton",
  },
  {
    postal_code: "30538",
    full_city_name: "Eastanollee, GA",
    city_name: "Eastanollee",
  },
  {
    postal_code: "37178",
    full_city_name: "Tennessee Ridge, TN",
    city_name: "Tennessee Ridge",
  },
  {
    postal_code: "96732",
    full_city_name: "Kahului, HI",
    city_name: "Kahului",
  },
  {
    postal_code: "66434",
    full_city_name: "Hiawatha, KS",
    city_name: "Hiawatha",
  },
  {
    postal_code: "13028",
    full_city_name: "Bernhards Bay, NY",
    city_name: "Bernhards Bay",
  },
  {
    postal_code: "13616",
    full_city_name: "Calcium, NY",
    city_name: "Calcium",
  },
  {
    postal_code: "49437",
    full_city_name: "Montague, MI",
    city_name: "Montague",
  },
  {
    postal_code: "80432",
    full_city_name: "Como, CO",
    city_name: "Como",
  },
  {
    postal_code: "39897",
    full_city_name: "Whigham, GA",
    city_name: "Whigham",
  },
  {
    postal_code: "49076",
    full_city_name: "Olivet, MI",
    city_name: "Olivet",
  },
  {
    postal_code: "62565",
    full_city_name: "Shelbyville, IL",
    city_name: "Shelbyville",
  },
  {
    postal_code: "84730",
    full_city_name: "Glenwood, UT",
    city_name: "Glenwood",
  },
  {
    postal_code: "14005",
    full_city_name: "Alexander, NY",
    city_name: "Alexander",
  },
  {
    postal_code: "49651",
    full_city_name: "Lake City, MI",
    city_name: "Lake City",
  },
  {
    postal_code: "67544",
    full_city_name: "Hoisington, KS",
    city_name: "Hoisington",
  },
  {
    postal_code: "39117",
    full_city_name: "Morton, MS",
    city_name: "Morton",
  },
  {
    postal_code: "97492",
    full_city_name: "Westfir, OR",
    city_name: "Westfir",
  },
  {
    postal_code: "50171",
    full_city_name: "Montezuma, IA",
    city_name: "Montezuma",
  },
  {
    postal_code: "44643",
    full_city_name: "Magnolia, OH",
    city_name: "Magnolia",
  },
  {
    postal_code: "31779",
    full_city_name: "Pelham, GA",
    city_name: "Pelham",
  },
  {
    postal_code: "71325",
    full_city_name: "Cheneyville, LA",
    city_name: "Cheneyville",
  },
  {
    postal_code: "97841",
    full_city_name: "Imbler, OR",
    city_name: "Imbler",
  },
  {
    postal_code: "11005",
    full_city_name: "Floral Park, NY",
    city_name: "Floral Park",
  },
  {
    postal_code: "97446",
    full_city_name: "Harrisburg, OR",
    city_name: "Harrisburg",
  },
  {
    postal_code: "15663",
    full_city_name: "Madison, PA",
    city_name: "Madison",
  },
  {
    postal_code: "67441",
    full_city_name: "Enterprise, KS",
    city_name: "Enterprise",
  },
  {
    postal_code: "10704",
    full_city_name: "Yonkers, NY",
    city_name: "Yonkers",
  },
  {
    postal_code: "22482",
    full_city_name: "Kilmarnock, VA",
    city_name: "Kilmarnock",
  },
  {
    postal_code: "72668",
    full_city_name: "Peel, AR",
    city_name: "Peel",
  },
  {
    postal_code: "97114",
    full_city_name: "Dayton, OR",
    city_name: "Dayton",
  },
  {
    postal_code: "97121",
    full_city_name: "Hammond, OR",
    city_name: "Hammond",
  },
  {
    postal_code: "46970",
    full_city_name: "Peru, IN",
    city_name: "Peru",
  },
  {
    postal_code: "95346",
    full_city_name: "Mi Wuk Village, CA",
    city_name: "Mi Wuk Village",
  },
  {
    postal_code: "49689",
    full_city_name: "Wellston, MI",
    city_name: "Wellston",
  },
  {
    postal_code: "48458",
    full_city_name: "Mount Morris, MI",
    city_name: "Mount Morris",
  },
  {
    postal_code: "97325",
    full_city_name: "Aumsville, OR",
    city_name: "Aumsville",
  },
  {
    postal_code: "46787",
    full_city_name: "South Whitley, IN",
    city_name: "South Whitley",
  },
  {
    postal_code: "67449",
    full_city_name: "Herington, KS",
    city_name: "Herington",
  },
  {
    postal_code: "97835",
    full_city_name: "Helix, OR",
    city_name: "Helix",
  },
  {
    postal_code: "59720",
    full_city_name: "Cameron, MT",
    city_name: "Cameron",
  },
  {
    postal_code: "97469",
    full_city_name: "Riddle, OR",
    city_name: "Riddle",
  },
  {
    postal_code: "24089",
    full_city_name: "Fieldale, VA",
    city_name: "Fieldale",
  },
  {
    postal_code: "97127",
    full_city_name: "Lafayette, OR",
    city_name: "Lafayette",
  },
  {
    postal_code: "97231",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "97118",
    full_city_name: "Garibaldi, OR",
    city_name: "Garibaldi",
  },
  {
    postal_code: "29384",
    full_city_name: "Waterloo, SC",
    city_name: "Waterloo",
  },
  {
    postal_code: "65724",
    full_city_name: "Pittsburg, MO",
    city_name: "Pittsburg",
  },
  {
    postal_code: "04988",
    full_city_name: "Unity, ME",
    city_name: "Unity",
  },
  {
    postal_code: "98660",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "16601",
    full_city_name: "Altoona, PA",
    city_name: "Altoona",
  },
  {
    postal_code: "10536",
    full_city_name: "Katonah, NY",
    city_name: "Katonah",
  },
  {
    postal_code: "91008",
    full_city_name: "Duarte, CA",
    city_name: "Duarte",
  },
  {
    postal_code: "47550",
    full_city_name: "Lamar, IN",
    city_name: "Lamar",
  },
  {
    postal_code: "56514",
    full_city_name: "Barnesville, MN",
    city_name: "Barnesville",
  },
  {
    postal_code: "14080",
    full_city_name: "Holland, NY",
    city_name: "Holland",
  },
  {
    postal_code: "64499",
    full_city_name: "Worth, MO",
    city_name: "Worth",
  },
  {
    postal_code: "45770",
    full_city_name: "Portland, OH",
    city_name: "Portland",
  },
  {
    postal_code: "56637",
    full_city_name: "Talmoon, MN",
    city_name: "Talmoon",
  },
  {
    postal_code: "08318",
    full_city_name: "Elmer, NJ",
    city_name: "Elmer",
  },
  {
    postal_code: "01373",
    full_city_name: "South Deerfield, MA",
    city_name: "South Deerfield",
  },
  {
    postal_code: "43007",
    full_city_name: "Broadway, OH",
    city_name: "Broadway",
  },
  {
    postal_code: "52623",
    full_city_name: "Danville, IA",
    city_name: "Danville",
  },
  {
    postal_code: "12736",
    full_city_name: "Fremont Center, NY",
    city_name: "Fremont Center",
  },
  {
    postal_code: "10921",
    full_city_name: "Florida, NY",
    city_name: "Florida",
  },
  {
    postal_code: "47590",
    full_city_name: "Velpen, IN",
    city_name: "Velpen",
  },
  {
    postal_code: "24918",
    full_city_name: "Ballard, WV",
    city_name: "Ballard",
  },
  {
    postal_code: "47557",
    full_city_name: "Monroe City, IN",
    city_name: "Monroe City",
  },
  {
    postal_code: "79370",
    full_city_name: "Spur, TX",
    city_name: "Spur",
  },
  {
    postal_code: "74871",
    full_city_name: "Stonewall, OK",
    city_name: "Stonewall",
  },
  {
    postal_code: "47338",
    full_city_name: "Eaton, IN",
    city_name: "Eaton",
  },
  {
    postal_code: "43748",
    full_city_name: "Junction City, OH",
    city_name: "Junction City",
  },
  {
    postal_code: "10595",
    full_city_name: "Valhalla, NY",
    city_name: "Valhalla",
  },
  {
    postal_code: "74445",
    full_city_name: "Morris, OK",
    city_name: "Morris",
  },
  {
    postal_code: "79250",
    full_city_name: "Petersburg, TX",
    city_name: "Petersburg",
  },
  {
    postal_code: "28579",
    full_city_name: "Smyrna, NC",
    city_name: "Smyrna",
  },
  {
    postal_code: "15631",
    full_city_name: "Everson, PA",
    city_name: "Everson",
  },
  {
    postal_code: "47326",
    full_city_name: "Bryant, IN",
    city_name: "Bryant",
  },
  {
    postal_code: "47527",
    full_city_name: "Dubois, IN",
    city_name: "Dubois",
  },
  {
    postal_code: "49881",
    full_city_name: "Sagola, MI",
    city_name: "Sagola",
  },
  {
    postal_code: "12585",
    full_city_name: "Verbank, NY",
    city_name: "Verbank",
  },
  {
    postal_code: "11939",
    full_city_name: "East Marion, NY",
    city_name: "East Marion",
  },
  {
    postal_code: "42458",
    full_city_name: "Spottsville, KY",
    city_name: "Spottsville",
  },
  {
    postal_code: "47342",
    full_city_name: "Gaston, IN",
    city_name: "Gaston",
  },
  {
    postal_code: "81155",
    full_city_name: "Villa Grove, CO",
    city_name: "Villa Grove",
  },
  {
    postal_code: "97102",
    full_city_name: "Arch Cape, OR",
    city_name: "Arch Cape",
  },
  {
    postal_code: "71282",
    full_city_name: "Tallulah, LA",
    city_name: "Tallulah",
  },
  {
    postal_code: "71720",
    full_city_name: "Bearden, AR",
    city_name: "Bearden",
  },
  {
    postal_code: "41033",
    full_city_name: "De Mossville, KY",
    city_name: "De Mossville",
  },
  {
    postal_code: "46057",
    full_city_name: "Michigantown, IN",
    city_name: "Michigantown",
  },
  {
    postal_code: "67550",
    full_city_name: "Larned, KS",
    city_name: "Larned",
  },
  {
    postal_code: "23897",
    full_city_name: "Yale, VA",
    city_name: "Yale",
  },
  {
    postal_code: "49833",
    full_city_name: "Little Lake, MI",
    city_name: "Little Lake",
  },
  {
    postal_code: "46049",
    full_city_name: "Kempton, IN",
    city_name: "Kempton",
  },
  {
    postal_code: "37881",
    full_city_name: "Thorn Hill, TN",
    city_name: "Thorn Hill",
  },
  {
    postal_code: "23092",
    full_city_name: "Locust Hill, VA",
    city_name: "Locust Hill",
  },
  {
    postal_code: "12789",
    full_city_name: "Woodridge, NY",
    city_name: "Woodridge",
  },
  {
    postal_code: "38477",
    full_city_name: "Prospect, TN",
    city_name: "Prospect",
  },
  {
    postal_code: "15311",
    full_city_name: "Amity, PA",
    city_name: "Amity",
  },
  {
    postal_code: "10454",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "74083",
    full_city_name: "Wann, OK",
    city_name: "Wann",
  },
  {
    postal_code: "01107",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "13153",
    full_city_name: "Skaneateles Falls, NY",
    city_name: "Skaneateles Falls",
  },
  {
    postal_code: "14502",
    full_city_name: "Macedon, NY",
    city_name: "Macedon",
  },
  {
    postal_code: "47355",
    full_city_name: "Lynn, IN",
    city_name: "Lynn",
  },
  {
    postal_code: "71667",
    full_city_name: "Star City, AR",
    city_name: "Star City",
  },
  {
    postal_code: "02534",
    full_city_name: "Cataumet, MA",
    city_name: "Cataumet",
  },
  {
    postal_code: "72122",
    full_city_name: "Paron, AR",
    city_name: "Paron",
  },
  {
    postal_code: "31050",
    full_city_name: "Knoxville, GA",
    city_name: "Knoxville",
  },
  {
    postal_code: "97067",
    full_city_name: "Welches, OR",
    city_name: "Welches",
  },
  {
    postal_code: "15845",
    full_city_name: "Johnsonburg, PA",
    city_name: "Johnsonburg",
  },
  {
    postal_code: "24432",
    full_city_name: "Deerfield, VA",
    city_name: "Deerfield",
  },
  {
    postal_code: "10703",
    full_city_name: "Yonkers, NY",
    city_name: "Yonkers",
  },
  {
    postal_code: "66618",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "04258",
    full_city_name: "Minot, ME",
    city_name: "Minot",
  },
  {
    postal_code: "14622",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "56540",
    full_city_name: "Fertile, MN",
    city_name: "Fertile",
  },
  {
    postal_code: "74029",
    full_city_name: "Dewey, OK",
    city_name: "Dewey",
  },
  {
    postal_code: "14607",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "01151",
    full_city_name: "Indian Orchard, MA",
    city_name: "Indian Orchard",
  },
  {
    postal_code: "12783",
    full_city_name: "Swan Lake, NY",
    city_name: "Swan Lake",
  },
  {
    postal_code: "04945",
    full_city_name: "Jackman, ME",
    city_name: "Jackman",
  },
  {
    postal_code: "10941",
    full_city_name: "Middletown, NY",
    city_name: "Middletown",
  },
  {
    postal_code: "59027",
    full_city_name: "Emigrant, MT",
    city_name: "Emigrant",
  },
  {
    postal_code: "67663",
    full_city_name: "Plainville, KS",
    city_name: "Plainville",
  },
  {
    postal_code: "08041",
    full_city_name: "Jobstown, NJ",
    city_name: "Jobstown",
  },
  {
    postal_code: "47441",
    full_city_name: "Linton, IN",
    city_name: "Linton",
  },
  {
    postal_code: "81243",
    full_city_name: "Powderhorn, CO",
    city_name: "Powderhorn",
  },
  {
    postal_code: "14418",
    full_city_name: "Branchport, NY",
    city_name: "Branchport",
  },
  {
    postal_code: "16374",
    full_city_name: "Kennerdell, PA",
    city_name: "Kennerdell",
  },
  {
    postal_code: "88081",
    full_city_name: "Chaparral, NM",
    city_name: "Chaparral",
  },
  {
    postal_code: "38683",
    full_city_name: "Walnut, MS",
    city_name: "Walnut",
  },
  {
    postal_code: "10990",
    full_city_name: "Warwick, NY",
    city_name: "Warwick",
  },
  {
    postal_code: "53585",
    full_city_name: "Sharon, WI",
    city_name: "Sharon",
  },
  {
    postal_code: "46721",
    full_city_name: "Butler, IN",
    city_name: "Butler",
  },
  {
    postal_code: "36080",
    full_city_name: "Titus, AL",
    city_name: "Titus",
  },
  {
    postal_code: "46765",
    full_city_name: "Leo, IN",
    city_name: "Leo",
  },
  {
    postal_code: "74080",
    full_city_name: "Talala, OK",
    city_name: "Talala",
  },
  {
    postal_code: "04224",
    full_city_name: "Dixfield, ME",
    city_name: "Dixfield",
  },
  {
    postal_code: "04847",
    full_city_name: "Hope, ME",
    city_name: "Hope",
  },
  {
    postal_code: "46741",
    full_city_name: "Grabill, IN",
    city_name: "Grabill",
  },
  {
    postal_code: "48444",
    full_city_name: "Imlay City, MI",
    city_name: "Imlay City",
  },
  {
    postal_code: "56229",
    full_city_name: "Cottonwood, MN",
    city_name: "Cottonwood",
  },
  {
    postal_code: "75420",
    full_city_name: "Brashear, TX",
    city_name: "Brashear",
  },
  {
    postal_code: "49457",
    full_city_name: "Twin Lake, MI",
    city_name: "Twin Lake",
  },
  {
    postal_code: "45382",
    full_city_name: "West Manchester, OH",
    city_name: "West Manchester",
  },
  {
    postal_code: "08741",
    full_city_name: "Pine Beach, NJ",
    city_name: "Pine Beach",
  },
  {
    postal_code: "45148",
    full_city_name: "Midland, OH",
    city_name: "Midland",
  },
  {
    postal_code: "74570",
    full_city_name: "Stuart, OK",
    city_name: "Stuart",
  },
  {
    postal_code: "47234",
    full_city_name: "Flat Rock, IN",
    city_name: "Flat Rock",
  },
  {
    postal_code: "48377",
    full_city_name: "Novi, MI",
    city_name: "Novi",
  },
  {
    postal_code: "39629",
    full_city_name: "Bogue Chitto, MS",
    city_name: "Bogue Chitto",
  },
  {
    postal_code: "13426",
    full_city_name: "Orwell, NY",
    city_name: "Orwell",
  },
  {
    postal_code: "85939",
    full_city_name: "Taylor, AZ",
    city_name: "Taylor",
  },
  {
    postal_code: "47501",
    full_city_name: "Washington, IN",
    city_name: "Washington",
  },
  {
    postal_code: "97011",
    full_city_name: "Brightwood, OR",
    city_name: "Brightwood",
  },
  {
    postal_code: "16930",
    full_city_name: "Liberty, PA",
    city_name: "Liberty",
  },
  {
    postal_code: "08326",
    full_city_name: "Landisville, NJ",
    city_name: "Landisville",
  },
  {
    postal_code: "46554",
    full_city_name: "North Liberty, IN",
    city_name: "North Liberty",
  },
  {
    postal_code: "13081",
    full_city_name: "King Ferry, NY",
    city_name: "King Ferry",
  },
  {
    postal_code: "56567",
    full_city_name: "New York Mills, MN",
    city_name: "New York Mills",
  },
  {
    postal_code: "11547",
    full_city_name: "Glenwood Landing, NY",
    city_name: "Glenwood Landing",
  },
  {
    postal_code: "13061",
    full_city_name: "Erieville, NY",
    city_name: "Erieville",
  },
  {
    postal_code: "47387",
    full_city_name: "Straughn, IN",
    city_name: "Straughn",
  },
  {
    postal_code: "69127",
    full_city_name: "Brule, NE",
    city_name: "Brule",
  },
  {
    postal_code: "05456",
    full_city_name: "Ferrisburgh, VT",
    city_name: "Ferrisburgh",
  },
  {
    postal_code: "69336",
    full_city_name: "Bridgeport, NE",
    city_name: "Bridgeport",
  },
  {
    postal_code: "69037",
    full_city_name: "Max, NE",
    city_name: "Max",
  },
  {
    postal_code: "43963",
    full_city_name: "Tiltonsville, OH",
    city_name: "Tiltonsville",
  },
  {
    postal_code: "78591",
    full_city_name: "Santa Elena, TX",
    city_name: "Santa Elena",
  },
  {
    postal_code: "65722",
    full_city_name: "Phillipsburg, MO",
    city_name: "Phillipsburg",
  },
  {
    postal_code: "49925",
    full_city_name: "Ewen, MI",
    city_name: "Ewen",
  },
  {
    postal_code: "04088",
    full_city_name: "Waterford, ME",
    city_name: "Waterford",
  },
  {
    postal_code: "46915",
    full_city_name: "Burlington, IN",
    city_name: "Burlington",
  },
  {
    postal_code: "14024",
    full_city_name: "Bliss, NY",
    city_name: "Bliss",
  },
  {
    postal_code: "97040",
    full_city_name: "Mosier, OR",
    city_name: "Mosier",
  },
  {
    postal_code: "81434",
    full_city_name: "Somerset, CO",
    city_name: "Somerset",
  },
  {
    postal_code: "50542",
    full_city_name: "Goldfield, IA",
    city_name: "Goldfield",
  },
  {
    postal_code: "97412",
    full_city_name: "Blachly, OR",
    city_name: "Blachly",
  },
  {
    postal_code: "15363",
    full_city_name: "Strabane, PA",
    city_name: "Strabane",
  },
  {
    postal_code: "64771",
    full_city_name: "Moundville, MO",
    city_name: "Moundville",
  },
  {
    postal_code: "67878",
    full_city_name: "Syracuse, KS",
    city_name: "Syracuse",
  },
  {
    postal_code: "01922",
    full_city_name: "Byfield, MA",
    city_name: "Byfield",
  },
  {
    postal_code: "52045",
    full_city_name: "Epworth, IA",
    city_name: "Epworth",
  },
  {
    postal_code: "54832",
    full_city_name: "Drummond, WI",
    city_name: "Drummond",
  },
  {
    postal_code: "23857",
    full_city_name: "Gasburg, VA",
    city_name: "Gasburg",
  },
  {
    postal_code: "81426",
    full_city_name: "Ophir, CO",
    city_name: "Ophir",
  },
  {
    postal_code: "13071",
    full_city_name: "Genoa, NY",
    city_name: "Genoa",
  },
  {
    postal_code: "03827",
    full_city_name: "East Kingston, NH",
    city_name: "East Kingston",
  },
  {
    postal_code: "99840",
    full_city_name: "Skagway, AK",
    city_name: "Skagway",
  },
  {
    postal_code: "51001",
    full_city_name: "Akron, IA",
    city_name: "Akron",
  },
  {
    postal_code: "01068",
    full_city_name: "Oakham, MA",
    city_name: "Oakham",
  },
  {
    postal_code: "15470",
    full_city_name: "Ohiopyle, PA",
    city_name: "Ohiopyle",
  },
  {
    postal_code: "92368",
    full_city_name: "Oro Grande, CA",
    city_name: "Oro Grande",
  },
  {
    postal_code: "71261",
    full_city_name: "Mer Rouge, LA",
    city_name: "Mer Rouge",
  },
  {
    postal_code: "04346",
    full_city_name: "Randolph, ME",
    city_name: "Randolph",
  },
  {
    postal_code: "46781",
    full_city_name: "Poneto, IN",
    city_name: "Poneto",
  },
  {
    postal_code: "66419",
    full_city_name: "Denison, KS",
    city_name: "Denison",
  },
  {
    postal_code: "01083",
    full_city_name: "Warren, MA",
    city_name: "Warren",
  },
  {
    postal_code: "63377",
    full_city_name: "Silex, MO",
    city_name: "Silex",
  },
  {
    postal_code: "01431",
    full_city_name: "Ashby, MA",
    city_name: "Ashby",
  },
  {
    postal_code: "39039",
    full_city_name: "Benton, MS",
    city_name: "Benton",
  },
  {
    postal_code: "77971",
    full_city_name: "Lolita, TX",
    city_name: "Lolita",
  },
  {
    postal_code: "48613",
    full_city_name: "Bentley, MI",
    city_name: "Bentley",
  },
  {
    postal_code: "16217",
    full_city_name: "Cooksburg, PA",
    city_name: "Cooksburg",
  },
  {
    postal_code: "43047",
    full_city_name: "Mingo, OH",
    city_name: "Mingo",
  },
  {
    postal_code: "80819",
    full_city_name: "Green Mountain Falls, CO",
    city_name: "Green Mountain Falls",
  },
  {
    postal_code: "61062",
    full_city_name: "Pearl City, IL",
    city_name: "Pearl City",
  },
  {
    postal_code: "74110",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "66517",
    full_city_name: "Ogden, KS",
    city_name: "Ogden",
  },
  {
    postal_code: "74352",
    full_city_name: "Locust Grove, OK",
    city_name: "Locust Grove",
  },
  {
    postal_code: "01984",
    full_city_name: "Wenham, MA",
    city_name: "Wenham",
  },
  {
    postal_code: "97028",
    full_city_name: "Government Camp, OR",
    city_name: "Government Camp",
  },
  {
    postal_code: "10801",
    full_city_name: "New Rochelle, NY",
    city_name: "New Rochelle",
  },
  {
    postal_code: "12570",
    full_city_name: "Poughquag, NY",
    city_name: "Poughquag",
  },
  {
    postal_code: "73086",
    full_city_name: "Sulphur, OK",
    city_name: "Sulphur",
  },
  {
    postal_code: "92173",
    full_city_name: "San Ysidro, CA",
    city_name: "San Ysidro",
  },
  {
    postal_code: "74330",
    full_city_name: "Adair, OK",
    city_name: "Adair",
  },
  {
    postal_code: "65349",
    full_city_name: "Slater, MO",
    city_name: "Slater",
  },
  {
    postal_code: "01376",
    full_city_name: "Turners Falls, MA",
    city_name: "Turners Falls",
  },
  {
    postal_code: "14209",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "22578",
    full_city_name: "White Stone, VA",
    city_name: "White Stone",
  },
  {
    postal_code: "14001",
    full_city_name: "Akron, NY",
    city_name: "Akron",
  },
  {
    postal_code: "14131",
    full_city_name: "Ransomville, NY",
    city_name: "Ransomville",
  },
  {
    postal_code: "35077",
    full_city_name: "Hanceville, AL",
    city_name: "Hanceville",
  },
  {
    postal_code: "08092",
    full_city_name: "West Creek, NJ",
    city_name: "West Creek",
  },
  {
    postal_code: "48331",
    full_city_name: "Farmington, MI",
    city_name: "Farmington",
  },
  {
    postal_code: "08559",
    full_city_name: "Stockton, NJ",
    city_name: "Stockton",
  },
  {
    postal_code: "74044",
    full_city_name: "Mannford, OK",
    city_name: "Mannford",
  },
  {
    postal_code: "74146",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "12775",
    full_city_name: "Rock Hill, NY",
    city_name: "Rock Hill",
  },
  {
    postal_code: "61427",
    full_city_name: "Cuba, IL",
    city_name: "Cuba",
  },
  {
    postal_code: "71665",
    full_city_name: "Rison, AR",
    city_name: "Rison",
  },
  {
    postal_code: "31768",
    full_city_name: "Moultrie, GA",
    city_name: "Moultrie",
  },
  {
    postal_code: "66781",
    full_city_name: "Weir, KS",
    city_name: "Weir",
  },
  {
    postal_code: "01053",
    full_city_name: "Leeds, MA",
    city_name: "Leeds",
  },
  {
    postal_code: "04092",
    full_city_name: "Westbrook, ME",
    city_name: "Westbrook",
  },
  {
    postal_code: "22738",
    full_city_name: "Rochelle, VA",
    city_name: "Rochelle",
  },
  {
    postal_code: "58655",
    full_city_name: "South Heart, ND",
    city_name: "South Heart",
  },
  {
    postal_code: "81054",
    full_city_name: "Las Animas, CO",
    city_name: "Las Animas",
  },
  {
    postal_code: "15419",
    full_city_name: "California, PA",
    city_name: "California",
  },
  {
    postal_code: "29638",
    full_city_name: "Donalds, SC",
    city_name: "Donalds",
  },
  {
    postal_code: "01081",
    full_city_name: "Wales, MA",
    city_name: "Wales",
  },
  {
    postal_code: "25514",
    full_city_name: "Fort Gay, WV",
    city_name: "Fort Gay",
  },
  {
    postal_code: "10709",
    full_city_name: "Eastchester, NY",
    city_name: "Eastchester",
  },
  {
    postal_code: "98947",
    full_city_name: "Tieton, WA",
    city_name: "Tieton",
  },
  {
    postal_code: "10956",
    full_city_name: "New City, NY",
    city_name: "New City",
  },
  {
    postal_code: "14219",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "74337",
    full_city_name: "Chouteau, OK",
    city_name: "Chouteau",
  },
  {
    postal_code: "49840",
    full_city_name: "Gulliver, MI",
    city_name: "Gulliver",
  },
  {
    postal_code: "24594",
    full_city_name: "Sutherlin, VA",
    city_name: "Sutherlin",
  },
  {
    postal_code: "14569",
    full_city_name: "Warsaw, NY",
    city_name: "Warsaw",
  },
  {
    postal_code: "10579",
    full_city_name: "Putnam Valley, NY",
    city_name: "Putnam Valley",
  },
  {
    postal_code: "70353",
    full_city_name: "Dulac, LA",
    city_name: "Dulac",
  },
  {
    postal_code: "55717",
    full_city_name: "Canyon, MN",
    city_name: "Canyon",
  },
  {
    postal_code: "54721",
    full_city_name: "Arkansaw, WI",
    city_name: "Arkansaw",
  },
  {
    postal_code: "65717",
    full_city_name: "Norwood, MO",
    city_name: "Norwood",
  },
  {
    postal_code: "01827",
    full_city_name: "Dunstable, MA",
    city_name: "Dunstable",
  },
  {
    postal_code: "10994",
    full_city_name: "West Nyack, NY",
    city_name: "West Nyack",
  },
  {
    postal_code: "38317",
    full_city_name: "Bruceton, TN",
    city_name: "Bruceton",
  },
  {
    postal_code: "04930",
    full_city_name: "Dexter, ME",
    city_name: "Dexter",
  },
  {
    postal_code: "48067",
    full_city_name: "Royal Oak, MI",
    city_name: "Royal Oak",
  },
  {
    postal_code: "14211",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "98675",
    full_city_name: "Yacolt, WA",
    city_name: "Yacolt",
  },
  {
    postal_code: "08069",
    full_city_name: "Penns Grove, NJ",
    city_name: "Penns Grove",
  },
  {
    postal_code: "74047",
    full_city_name: "Mounds, OK",
    city_name: "Mounds",
  },
  {
    postal_code: "07008",
    full_city_name: "Carteret, NJ",
    city_name: "Carteret",
  },
  {
    postal_code: "47390",
    full_city_name: "Union City, IN",
    city_name: "Union City",
  },
  {
    postal_code: "46553",
    full_city_name: "New Paris, IN",
    city_name: "New Paris",
  },
  {
    postal_code: "06510",
    full_city_name: "New Haven, CT",
    city_name: "New Haven",
  },
  {
    postal_code: "46619",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "40055",
    full_city_name: "Pendleton, KY",
    city_name: "Pendleton",
  },
  {
    postal_code: "56535",
    full_city_name: "Erskine, MN",
    city_name: "Erskine",
  },
  {
    postal_code: "16120",
    full_city_name: "Enon Valley, PA",
    city_name: "Enon Valley",
  },
  {
    postal_code: "20620",
    full_city_name: "Callaway, MD",
    city_name: "Callaway",
  },
  {
    postal_code: "63841",
    full_city_name: "Dexter, MO",
    city_name: "Dexter",
  },
  {
    postal_code: "08033",
    full_city_name: "Haddonfield, NJ",
    city_name: "Haddonfield",
  },
  {
    postal_code: "08403",
    full_city_name: "Longport, NJ",
    city_name: "Longport",
  },
  {
    postal_code: "22480",
    full_city_name: "Irvington, VA",
    city_name: "Irvington",
  },
  {
    postal_code: "74462",
    full_city_name: "Stigler, OK",
    city_name: "Stigler",
  },
  {
    postal_code: "51351",
    full_city_name: "Milford, IA",
    city_name: "Milford",
  },
  {
    postal_code: "24078",
    full_city_name: "Collinsville, VA",
    city_name: "Collinsville",
  },
  {
    postal_code: "47710",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "63549",
    full_city_name: "La Plata, MO",
    city_name: "La Plata",
  },
  {
    postal_code: "27248",
    full_city_name: "Franklinville, NC",
    city_name: "Franklinville",
  },
  {
    postal_code: "50629",
    full_city_name: "Fairbank, IA",
    city_name: "Fairbank",
  },
  {
    postal_code: "74436",
    full_city_name: "Haskell, OK",
    city_name: "Haskell",
  },
  {
    postal_code: "39601",
    full_city_name: "Brookhaven, MS",
    city_name: "Brookhaven",
  },
  {
    postal_code: "14222",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "85615",
    full_city_name: "Hereford, AZ",
    city_name: "Hereford",
  },
  {
    postal_code: "01038",
    full_city_name: "Hatfield, MA",
    city_name: "Hatfield",
  },
  {
    postal_code: "11514",
    full_city_name: "Carle Place, NY",
    city_name: "Carle Place",
  },
  {
    postal_code: "14414",
    full_city_name: "Avon, NY",
    city_name: "Avon",
  },
  {
    postal_code: "56222",
    full_city_name: "Clara City, MN",
    city_name: "Clara City",
  },
  {
    postal_code: "08341",
    full_city_name: "Minotola, NJ",
    city_name: "Minotola",
  },
  {
    postal_code: "87013",
    full_city_name: "Cuba, NM",
    city_name: "Cuba",
  },
  {
    postal_code: "13164",
    full_city_name: "Warners, NY",
    city_name: "Warners",
  },
  {
    postal_code: "51526",
    full_city_name: "Crescent, IA",
    city_name: "Crescent",
  },
  {
    postal_code: "04260",
    full_city_name: "New Gloucester, ME",
    city_name: "New Gloucester",
  },
  {
    postal_code: "62458",
    full_city_name: "Saint Elmo, IL",
    city_name: "Saint Elmo",
  },
  {
    postal_code: "50110",
    full_city_name: "Gray, IA",
    city_name: "Gray",
  },
  {
    postal_code: "56548",
    full_city_name: "Halstad, MN",
    city_name: "Halstad",
  },
  {
    postal_code: "04461",
    full_city_name: "Milford, ME",
    city_name: "Milford",
  },
  {
    postal_code: "84645",
    full_city_name: "Mona, UT",
    city_name: "Mona",
  },
  {
    postal_code: "84774",
    full_city_name: "Toquerville, UT",
    city_name: "Toquerville",
  },
  {
    postal_code: "01366",
    full_city_name: "Petersham, MA",
    city_name: "Petersham",
  },
  {
    postal_code: "24120",
    full_city_name: "Meadows Of Dan, VA",
    city_name: "Meadows Of Dan",
  },
  {
    postal_code: "18074",
    full_city_name: "Perkiomenville, PA",
    city_name: "Perkiomenville",
  },
  {
    postal_code: "65713",
    full_city_name: "Niangua, MO",
    city_name: "Niangua",
  },
  {
    postal_code: "79019",
    full_city_name: "Claude, TX",
    city_name: "Claude",
  },
  {
    postal_code: "14559",
    full_city_name: "Spencerport, NY",
    city_name: "Spencerport",
  },
  {
    postal_code: "74036",
    full_city_name: "Inola, OK",
    city_name: "Inola",
  },
  {
    postal_code: "97846",
    full_city_name: "Joseph, OR",
    city_name: "Joseph",
  },
  {
    postal_code: "43620",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "35501",
    full_city_name: "Jasper, AL",
    city_name: "Jasper",
  },
  {
    postal_code: "48725",
    full_city_name: "Caseville, MI",
    city_name: "Caseville",
  },
  {
    postal_code: "46516",
    full_city_name: "Elkhart, IN",
    city_name: "Elkhart",
  },
  {
    postal_code: "74525",
    full_city_name: "Atoka, OK",
    city_name: "Atoka",
  },
  {
    postal_code: "56571",
    full_city_name: "Ottertail, MN",
    city_name: "Ottertail",
  },
  {
    postal_code: "74301",
    full_city_name: "Vinita, OK",
    city_name: "Vinita",
  },
  {
    postal_code: "08525",
    full_city_name: "Hopewell, NJ",
    city_name: "Hopewell",
  },
  {
    postal_code: "11709",
    full_city_name: "Bayville, NY",
    city_name: "Bayville",
  },
  {
    postal_code: "08215",
    full_city_name: "Egg Harbor City, NJ",
    city_name: "Egg Harbor City",
  },
  {
    postal_code: "02465",
    full_city_name: "West Newton, MA",
    city_name: "West Newton",
  },
  {
    postal_code: "47122",
    full_city_name: "Georgetown, IN",
    city_name: "Georgetown",
  },
  {
    postal_code: "52002",
    full_city_name: "Dubuque, IA",
    city_name: "Dubuque",
  },
  {
    postal_code: "12701",
    full_city_name: "Monticello, NY",
    city_name: "Monticello",
  },
  {
    postal_code: "48304",
    full_city_name: "Bloomfield Hills, MI",
    city_name: "Bloomfield Hills",
  },
  {
    postal_code: "08006",
    full_city_name: "Barnegat Light, NJ",
    city_name: "Barnegat Light",
  },
  {
    postal_code: "74053",
    full_city_name: "Oologah, OK",
    city_name: "Oologah",
  },
  {
    postal_code: "97106",
    full_city_name: "Banks, OR",
    city_name: "Banks",
  },
  {
    postal_code: "47129",
    full_city_name: "Clarksville, IN",
    city_name: "Clarksville",
  },
  {
    postal_code: "71655",
    full_city_name: "Monticello, AR",
    city_name: "Monticello",
  },
  {
    postal_code: "58790",
    full_city_name: "Velva, ND",
    city_name: "Velva",
  },
  {
    postal_code: "52052",
    full_city_name: "Guttenberg, IA",
    city_name: "Guttenberg",
  },
  {
    postal_code: "97623",
    full_city_name: "Bonanza, OR",
    city_name: "Bonanza",
  },
  {
    postal_code: "74108",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "52068",
    full_city_name: "Peosta, IA",
    city_name: "Peosta",
  },
  {
    postal_code: "43719",
    full_city_name: "Bethesda, OH",
    city_name: "Bethesda",
  },
  {
    postal_code: "39191",
    full_city_name: "Wesson, MS",
    city_name: "Wesson",
  },
  {
    postal_code: "85638",
    full_city_name: "Tombstone, AZ",
    city_name: "Tombstone",
  },
  {
    postal_code: "08328",
    full_city_name: "Malaga, NJ",
    city_name: "Malaga",
  },
  {
    postal_code: "42553",
    full_city_name: "Science Hill, KY",
    city_name: "Science Hill",
  },
  {
    postal_code: "14047",
    full_city_name: "Derby, NY",
    city_name: "Derby",
  },
  {
    postal_code: "97431",
    full_city_name: "Dexter, OR",
    city_name: "Dexter",
  },
  {
    postal_code: "01515",
    full_city_name: "East Brookfield, MA",
    city_name: "East Brookfield",
  },
  {
    postal_code: "21531",
    full_city_name: "Friendsville, MD",
    city_name: "Friendsville",
  },
  {
    postal_code: "14143",
    full_city_name: "Stafford, NY",
    city_name: "Stafford",
  },
  {
    postal_code: "74015",
    full_city_name: "Catoosa, OK",
    city_name: "Catoosa",
  },
  {
    postal_code: "30218",
    full_city_name: "Gay, GA",
    city_name: "Gay",
  },
  {
    postal_code: "28073",
    full_city_name: "Grover, NC",
    city_name: "Grover",
  },
  {
    postal_code: "45153",
    full_city_name: "Moscow, OH",
    city_name: "Moscow",
  },
  {
    postal_code: "45380",
    full_city_name: "Versailles, OH",
    city_name: "Versailles",
  },
  {
    postal_code: "49839",
    full_city_name: "Grand Marais, MI",
    city_name: "Grand Marais",
  },
  {
    postal_code: "24874",
    full_city_name: "Pineville, WV",
    city_name: "Pineville",
  },
  {
    postal_code: "25921",
    full_city_name: "Sophia, WV",
    city_name: "Sophia",
  },
  {
    postal_code: "54733",
    full_city_name: "Dallas, WI",
    city_name: "Dallas",
  },
  {
    postal_code: "45658",
    full_city_name: "Patriot, OH",
    city_name: "Patriot",
  },
  {
    postal_code: "24055",
    full_city_name: "Bassett, VA",
    city_name: "Bassett",
  },
  {
    postal_code: "74003",
    full_city_name: "Bartlesville, OK",
    city_name: "Bartlesville",
  },
  {
    postal_code: "97388",
    full_city_name: "Gleneden Beach, OR",
    city_name: "Gleneden Beach",
  },
  {
    postal_code: "02322",
    full_city_name: "Avon, MA",
    city_name: "Avon",
  },
  {
    postal_code: "14625",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "88210",
    full_city_name: "Artesia, NM",
    city_name: "Artesia",
  },
  {
    postal_code: "26547",
    full_city_name: "Reedsville, WV",
    city_name: "Reedsville",
  },
  {
    postal_code: "54943",
    full_city_name: "Hancock, WI",
    city_name: "Hancock",
  },
  {
    postal_code: "31730",
    full_city_name: "Camilla, GA",
    city_name: "Camilla",
  },
  {
    postal_code: "48326",
    full_city_name: "Auburn Hills, MI",
    city_name: "Auburn Hills",
  },
  {
    postal_code: "27925",
    full_city_name: "Columbia, NC",
    city_name: "Columbia",
  },
  {
    postal_code: "23421",
    full_city_name: "Parksley, VA",
    city_name: "Parksley",
  },
  {
    postal_code: "28901",
    full_city_name: "Andrews, NC",
    city_name: "Andrews",
  },
  {
    postal_code: "49253",
    full_city_name: "Manitou Beach, MI",
    city_name: "Manitou Beach",
  },
  {
    postal_code: "81624",
    full_city_name: "Collbran, CO",
    city_name: "Collbran",
  },
  {
    postal_code: "27326",
    full_city_name: "Ruffin, NC",
    city_name: "Ruffin",
  },
  {
    postal_code: "04606",
    full_city_name: "Addison, ME",
    city_name: "Addison",
  },
  {
    postal_code: "97130",
    full_city_name: "Manzanita, OR",
    city_name: "Manzanita",
  },
  {
    postal_code: "02108",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "01036",
    full_city_name: "Hampden, MA",
    city_name: "Hampden",
  },
  {
    postal_code: "08086",
    full_city_name: "Thorofare, NJ",
    city_name: "Thorofare",
  },
  {
    postal_code: "97009",
    full_city_name: "Boring, OR",
    city_name: "Boring",
  },
  {
    postal_code: "66514",
    full_city_name: "Milford, KS",
    city_name: "Milford",
  },
  {
    postal_code: "74020",
    full_city_name: "Cleveland, OK",
    city_name: "Cleveland",
  },
  {
    postal_code: "42413",
    full_city_name: "Hanson, KY",
    city_name: "Hanson",
  },
  {
    postal_code: "79764",
    full_city_name: "Odessa, TX",
    city_name: "Odessa",
  },
  {
    postal_code: "02466",
    full_city_name: "Auburndale, MA",
    city_name: "Auburndale",
  },
  {
    postal_code: "82730",
    full_city_name: "Upton, WY",
    city_name: "Upton",
  },
  {
    postal_code: "10977",
    full_city_name: "Spring Valley, NY",
    city_name: "Spring Valley",
  },
  {
    postal_code: "97220",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "83629",
    full_city_name: "Horseshoe Bend, ID",
    city_name: "Horseshoe Bend",
  },
  {
    postal_code: "17349",
    full_city_name: "New Freedom, PA",
    city_name: "New Freedom",
  },
  {
    postal_code: "14530",
    full_city_name: "Perry, NY",
    city_name: "Perry",
  },
  {
    postal_code: "23960",
    full_city_name: "Prospect, VA",
    city_name: "Prospect",
  },
  {
    postal_code: "12594",
    full_city_name: "Wingdale, NY",
    city_name: "Wingdale",
  },
  {
    postal_code: "97882",
    full_city_name: "Umatilla, OR",
    city_name: "Umatilla",
  },
  {
    postal_code: "66701",
    full_city_name: "Fort Scott, KS",
    city_name: "Fort Scott",
  },
  {
    postal_code: "08230",
    full_city_name: "Ocean View, NJ",
    city_name: "Ocean View",
  },
  {
    postal_code: "11980",
    full_city_name: "Yaphank, NY",
    city_name: "Yaphank",
  },
  {
    postal_code: "08270",
    full_city_name: "Woodbine, NJ",
    city_name: "Woodbine",
  },
  {
    postal_code: "74883",
    full_city_name: "Wetumka, OK",
    city_name: "Wetumka",
  },
  {
    postal_code: "81082",
    full_city_name: "Trinidad, CO",
    city_name: "Trinidad",
  },
  {
    postal_code: "71104",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "74073",
    full_city_name: "Sperry, OK",
    city_name: "Sperry",
  },
  {
    postal_code: "17361",
    full_city_name: "Shrewsbury, PA",
    city_name: "Shrewsbury",
  },
  {
    postal_code: "74437",
    full_city_name: "Henryetta, OK",
    city_name: "Henryetta",
  },
  {
    postal_code: "04679",
    full_city_name: "Southwest Harbor, ME",
    city_name: "Southwest Harbor",
  },
  {
    postal_code: "01951",
    full_city_name: "Newbury, MA",
    city_name: "Newbury",
  },
  {
    postal_code: "74733",
    full_city_name: "Colbert, OK",
    city_name: "Colbert",
  },
  {
    postal_code: "74547",
    full_city_name: "Hartshorne, OK",
    city_name: "Hartshorne",
  },
  {
    postal_code: "47340",
    full_city_name: "Farmland, IN",
    city_name: "Farmland",
  },
  {
    postal_code: "53506",
    full_city_name: "Avoca, WI",
    city_name: "Avoca",
  },
  {
    postal_code: "03049",
    full_city_name: "Hollis, NH",
    city_name: "Hollis",
  },
  {
    postal_code: "52756",
    full_city_name: "Long Grove, IA",
    city_name: "Long Grove",
  },
  {
    postal_code: "97883",
    full_city_name: "Union, OR",
    city_name: "Union",
  },
  {
    postal_code: "01035",
    full_city_name: "Hadley, MA",
    city_name: "Hadley",
  },
  {
    postal_code: "10954",
    full_city_name: "Nanuet, NY",
    city_name: "Nanuet",
  },
  {
    postal_code: "01564",
    full_city_name: "Sterling, MA",
    city_name: "Sterling",
  },
  {
    postal_code: "07502",
    full_city_name: "Paterson, NJ",
    city_name: "Paterson",
  },
  {
    postal_code: "59858",
    full_city_name: "Philipsburg, MT",
    city_name: "Philipsburg",
  },
  {
    postal_code: "97146",
    full_city_name: "Warrenton, OR",
    city_name: "Warrenton",
  },
  {
    postal_code: "74441",
    full_city_name: "Hulbert, OK",
    city_name: "Hulbert",
  },
  {
    postal_code: "97024",
    full_city_name: "Fairview, OR",
    city_name: "Fairview",
  },
  {
    postal_code: "39059",
    full_city_name: "Crystal Springs, MS",
    city_name: "Crystal Springs",
  },
  {
    postal_code: "10916",
    full_city_name: "Campbell Hall, NY",
    city_name: "Campbell Hall",
  },
  {
    postal_code: "97017",
    full_city_name: "Colton, OR",
    city_name: "Colton",
  },
  {
    postal_code: "10553",
    full_city_name: "Mount Vernon, NY",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "46819",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "37201",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "97048",
    full_city_name: "Rainier, OR",
    city_name: "Rainier",
  },
  {
    postal_code: "74434",
    full_city_name: "Fort Gibson, OK",
    city_name: "Fort Gibson",
  },
  {
    postal_code: "74455",
    full_city_name: "Porum, OK",
    city_name: "Porum",
  },
  {
    postal_code: "81427",
    full_city_name: "Ouray, CO",
    city_name: "Ouray",
  },
  {
    postal_code: "12531",
    full_city_name: "Holmes, NY",
    city_name: "Holmes",
  },
  {
    postal_code: "14615",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "23128",
    full_city_name: "North, VA",
    city_name: "North",
  },
  {
    postal_code: "17974",
    full_city_name: "Seltzer, PA",
    city_name: "Seltzer",
  },
  {
    postal_code: "12160",
    full_city_name: "Sloansville, NY",
    city_name: "Sloansville",
  },
  {
    postal_code: "70665",
    full_city_name: "Sulphur, LA",
    city_name: "Sulphur",
  },
  {
    postal_code: "16153",
    full_city_name: "Stoneboro, PA",
    city_name: "Stoneboro",
  },
  {
    postal_code: "08835",
    full_city_name: "Manville, NJ",
    city_name: "Manville",
  },
  {
    postal_code: "43445",
    full_city_name: "Martin, OH",
    city_name: "Martin",
  },
  {
    postal_code: "96726",
    full_city_name: "Honaunau, HI",
    city_name: "Honaunau",
  },
  {
    postal_code: "72127",
    full_city_name: "Plumerville, AR",
    city_name: "Plumerville",
  },
  {
    postal_code: "81069",
    full_city_name: "Rye, CO",
    city_name: "Rye",
  },
  {
    postal_code: "68846",
    full_city_name: "Hordville, NE",
    city_name: "Hordville",
  },
  {
    postal_code: "72132",
    full_city_name: "Redfield, AR",
    city_name: "Redfield",
  },
  {
    postal_code: "30442",
    full_city_name: "Millen, GA",
    city_name: "Millen",
  },
  {
    postal_code: "61251",
    full_city_name: "Fenton, IL",
    city_name: "Fenton",
  },
  {
    postal_code: "24270",
    full_city_name: "Mendota, VA",
    city_name: "Mendota",
  },
  {
    postal_code: "48446",
    full_city_name: "Lapeer, MI",
    city_name: "Lapeer",
  },
  {
    postal_code: "20609",
    full_city_name: "Avenue, MD",
    city_name: "Avenue",
  },
  {
    postal_code: "65669",
    full_city_name: "Highlandville, MO",
    city_name: "Highlandville",
  },
  {
    postal_code: "95635",
    full_city_name: "Greenwood, CA",
    city_name: "Greenwood",
  },
  {
    postal_code: "76265",
    full_city_name: "Saint Jo, TX",
    city_name: "Saint Jo",
  },
  {
    postal_code: "41179",
    full_city_name: "Vanceburg, KY",
    city_name: "Vanceburg",
  },
  {
    postal_code: "61864",
    full_city_name: "Philo, IL",
    city_name: "Philo",
  },
  {
    postal_code: "63351",
    full_city_name: "Jonesburg, MO",
    city_name: "Jonesburg",
  },
  {
    postal_code: "02746",
    full_city_name: "New Bedford, MA",
    city_name: "New Bedford",
  },
  {
    postal_code: "26385",
    full_city_name: "Lost Creek, WV",
    city_name: "Lost Creek",
  },
  {
    postal_code: "33848",
    full_city_name: "Intercession City, FL",
    city_name: "Intercession City",
  },
  {
    postal_code: "08802",
    full_city_name: "Asbury, NJ",
    city_name: "Asbury",
  },
  {
    postal_code: "45358",
    full_city_name: "Pitsburg, OH",
    city_name: "Pitsburg",
  },
  {
    postal_code: "62089",
    full_city_name: "Taylor Springs, IL",
    city_name: "Taylor Springs",
  },
  {
    postal_code: "48167",
    full_city_name: "Northville, MI",
    city_name: "Northville",
  },
  {
    postal_code: "30673",
    full_city_name: "Washington, GA",
    city_name: "Washington",
  },
  {
    postal_code: "21557",
    full_city_name: "Rawlings, MD",
    city_name: "Rawlings",
  },
  {
    postal_code: "41311",
    full_city_name: "Beattyville, KY",
    city_name: "Beattyville",
  },
  {
    postal_code: "04284",
    full_city_name: "Wayne, ME",
    city_name: "Wayne",
  },
  {
    postal_code: "74571",
    full_city_name: "Talihina, OK",
    city_name: "Talihina",
  },
  {
    postal_code: "75961",
    full_city_name: "Nacogdoches, TX",
    city_name: "Nacogdoches",
  },
  {
    postal_code: "21737",
    full_city_name: "Glenelg, MD",
    city_name: "Glenelg",
  },
  {
    postal_code: "74032",
    full_city_name: "Glencoe, OK",
    city_name: "Glencoe",
  },
  {
    postal_code: "04539",
    full_city_name: "Bristol, ME",
    city_name: "Bristol",
  },
  {
    postal_code: "35135",
    full_city_name: "Riverside, AL",
    city_name: "Riverside",
  },
  {
    postal_code: "65656",
    full_city_name: "Galena, MO",
    city_name: "Galena",
  },
  {
    postal_code: "60953",
    full_city_name: "Milford, IL",
    city_name: "Milford",
  },
  {
    postal_code: "39111",
    full_city_name: "Magee, MS",
    city_name: "Magee",
  },
  {
    postal_code: "72061",
    full_city_name: "Guy, AR",
    city_name: "Guy",
  },
  {
    postal_code: "95459",
    full_city_name: "Manchester, CA",
    city_name: "Manchester",
  },
  {
    postal_code: "29446",
    full_city_name: "Green Pond, SC",
    city_name: "Green Pond",
  },
  {
    postal_code: "38562",
    full_city_name: "Gainesboro, TN",
    city_name: "Gainesboro",
  },
  {
    postal_code: "21917",
    full_city_name: "Colora, MD",
    city_name: "Colora",
  },
  {
    postal_code: "97002",
    full_city_name: "Aurora, OR",
    city_name: "Aurora",
  },
  {
    postal_code: "99153",
    full_city_name: "Metaline Falls, WA",
    city_name: "Metaline Falls",
  },
  {
    postal_code: "17536",
    full_city_name: "Kirkwood, PA",
    city_name: "Kirkwood",
  },
  {
    postal_code: "93445",
    full_city_name: "Oceano, CA",
    city_name: "Oceano",
  },
  {
    postal_code: "41083",
    full_city_name: "Sanders, KY",
    city_name: "Sanders",
  },
  {
    postal_code: "48875",
    full_city_name: "Portland, MI",
    city_name: "Portland",
  },
  {
    postal_code: "62634",
    full_city_name: "Elkhart, IL",
    city_name: "Elkhart",
  },
  {
    postal_code: "27874",
    full_city_name: "Scotland Neck, NC",
    city_name: "Scotland Neck",
  },
  {
    postal_code: "28772",
    full_city_name: "Rosman, NC",
    city_name: "Rosman",
  },
  {
    postal_code: "63769",
    full_city_name: "Oak Ridge, MO",
    city_name: "Oak Ridge",
  },
  {
    postal_code: "76670",
    full_city_name: "Milford, TX",
    city_name: "Milford",
  },
  {
    postal_code: "26431",
    full_city_name: "Shinnston, WV",
    city_name: "Shinnston",
  },
  {
    postal_code: "44652",
    full_city_name: "Middlebranch, OH",
    city_name: "Middlebranch",
  },
  {
    postal_code: "39561",
    full_city_name: "Mc Henry, MS",
    city_name: "Mc Henry",
  },
  {
    postal_code: "41183",
    full_city_name: "Worthington, KY",
    city_name: "Worthington",
  },
  {
    postal_code: "99134",
    full_city_name: "Harrington, WA",
    city_name: "Harrington",
  },
  {
    postal_code: "07055",
    full_city_name: "Passaic, NJ",
    city_name: "Passaic",
  },
  {
    postal_code: "35073",
    full_city_name: "Graysville, AL",
    city_name: "Graysville",
  },
  {
    postal_code: "65461",
    full_city_name: "Duke, MO",
    city_name: "Duke",
  },
  {
    postal_code: "62983",
    full_city_name: "Royalton, IL",
    city_name: "Royalton",
  },
  {
    postal_code: "65686",
    full_city_name: "Kimberling City, MO",
    city_name: "Kimberling City",
  },
  {
    postal_code: "71334",
    full_city_name: "Ferriday, LA",
    city_name: "Ferriday",
  },
  {
    postal_code: "65658",
    full_city_name: "Golden, MO",
    city_name: "Golden",
  },
  {
    postal_code: "68456",
    full_city_name: "Utica, NE",
    city_name: "Utica",
  },
  {
    postal_code: "56356",
    full_city_name: "New Munich, MN",
    city_name: "New Munich",
  },
  {
    postal_code: "80642",
    full_city_name: "Hudson, CO",
    city_name: "Hudson",
  },
  {
    postal_code: "38316",
    full_city_name: "Bradford, TN",
    city_name: "Bradford",
  },
  {
    postal_code: "16321",
    full_city_name: "East Hickory, PA",
    city_name: "East Hickory",
  },
  {
    postal_code: "45339",
    full_city_name: "Ludlow Falls, OH",
    city_name: "Ludlow Falls",
  },
  {
    postal_code: "24090",
    full_city_name: "Fincastle, VA",
    city_name: "Fincastle",
  },
  {
    postal_code: "29135",
    full_city_name: "Saint Matthews, SC",
    city_name: "Saint Matthews",
  },
  {
    postal_code: "16124",
    full_city_name: "Fredonia, PA",
    city_name: "Fredonia",
  },
  {
    postal_code: "44730",
    full_city_name: "East Canton, OH",
    city_name: "East Canton",
  },
  {
    postal_code: "11569",
    full_city_name: "Point Lookout, NY",
    city_name: "Point Lookout",
  },
  {
    postal_code: "47514",
    full_city_name: "Branchville, IN",
    city_name: "Branchville",
  },
  {
    postal_code: "12771",
    full_city_name: "Port Jervis, NY",
    city_name: "Port Jervis",
  },
  {
    postal_code: "38345",
    full_city_name: "Huron, TN",
    city_name: "Huron",
  },
  {
    postal_code: "18232",
    full_city_name: "Lansford, PA",
    city_name: "Lansford",
  },
  {
    postal_code: "16875",
    full_city_name: "Spring Mills, PA",
    city_name: "Spring Mills",
  },
  {
    postal_code: "43033",
    full_city_name: "Kirkersville, OH",
    city_name: "Kirkersville",
  },
  {
    postal_code: "29653",
    full_city_name: "Hodges, SC",
    city_name: "Hodges",
  },
  {
    postal_code: "63344",
    full_city_name: "Eolia, MO",
    city_name: "Eolia",
  },
  {
    postal_code: "49644",
    full_city_name: "Irons, MI",
    city_name: "Irons",
  },
  {
    postal_code: "64849",
    full_city_name: "Neck City, MO",
    city_name: "Neck City",
  },
  {
    postal_code: "48359",
    full_city_name: "Lake Orion, MI",
    city_name: "Lake Orion",
  },
  {
    postal_code: "17407",
    full_city_name: "York, PA",
    city_name: "York",
  },
  {
    postal_code: "29426",
    full_city_name: "Adams Run, SC",
    city_name: "Adams Run",
  },
  {
    postal_code: "92242",
    full_city_name: "Earp, CA",
    city_name: "Earp",
  },
  {
    postal_code: "65608",
    full_city_name: "Ava, MO",
    city_name: "Ava",
  },
  {
    postal_code: "14707",
    full_city_name: "Allentown, NY",
    city_name: "Allentown",
  },
  {
    postal_code: "61441",
    full_city_name: "Ipava, IL",
    city_name: "Ipava",
  },
  {
    postal_code: "62972",
    full_city_name: "Ozark, IL",
    city_name: "Ozark",
  },
  {
    postal_code: "62379",
    full_city_name: "Warsaw, IL",
    city_name: "Warsaw",
  },
  {
    postal_code: "15087",
    full_city_name: "Webster, PA",
    city_name: "Webster",
  },
  {
    postal_code: "17512",
    full_city_name: "Columbia, PA",
    city_name: "Columbia",
  },
  {
    postal_code: "14879",
    full_city_name: "Savona, NY",
    city_name: "Savona",
  },
  {
    postal_code: "44250",
    full_city_name: "Lakemore, OH",
    city_name: "Lakemore",
  },
  {
    postal_code: "54408",
    full_city_name: "Aniwa, WI",
    city_name: "Aniwa",
  },
  {
    postal_code: "66856",
    full_city_name: "Lebo, KS",
    city_name: "Lebo",
  },
  {
    postal_code: "11955",
    full_city_name: "Moriches, NY",
    city_name: "Moriches",
  },
  {
    postal_code: "84309",
    full_city_name: "Deweyville, UT",
    city_name: "Deweyville",
  },
  {
    postal_code: "61953",
    full_city_name: "Tuscola, IL",
    city_name: "Tuscola",
  },
  {
    postal_code: "44099",
    full_city_name: "Windsor, OH",
    city_name: "Windsor",
  },
  {
    postal_code: "86444",
    full_city_name: "Meadview, AZ",
    city_name: "Meadview",
  },
  {
    postal_code: "06353",
    full_city_name: "Montville, CT",
    city_name: "Montville",
  },
  {
    postal_code: "12407",
    full_city_name: "Ashland, NY",
    city_name: "Ashland",
  },
  {
    postal_code: "46320",
    full_city_name: "Hammond, IN",
    city_name: "Hammond",
  },
  {
    postal_code: "54159",
    full_city_name: "Porterfield, WI",
    city_name: "Porterfield",
  },
  {
    postal_code: "12454",
    full_city_name: "Maplecrest, NY",
    city_name: "Maplecrest",
  },
  {
    postal_code: "15110",
    full_city_name: "Duquesne, PA",
    city_name: "Duquesne",
  },
  {
    postal_code: "98620",
    full_city_name: "Goldendale, WA",
    city_name: "Goldendale",
  },
  {
    postal_code: "46183",
    full_city_name: "West Newton, IN",
    city_name: "West Newton",
  },
  {
    postal_code: "93622",
    full_city_name: "Firebaugh, CA",
    city_name: "Firebaugh",
  },
  {
    postal_code: "26268",
    full_city_name: "Glady, WV",
    city_name: "Glady",
  },
  {
    postal_code: "30520",
    full_city_name: "Canon, GA",
    city_name: "Canon",
  },
  {
    postal_code: "55956",
    full_city_name: "Mazeppa, MN",
    city_name: "Mazeppa",
  },
  {
    postal_code: "50227",
    full_city_name: "Popejoy, IA",
    city_name: "Popejoy",
  },
  {
    postal_code: "67554",
    full_city_name: "Lyons, KS",
    city_name: "Lyons",
  },
  {
    postal_code: "83261",
    full_city_name: "Paris, ID",
    city_name: "Paris",
  },
  {
    postal_code: "23970",
    full_city_name: "South Hill, VA",
    city_name: "South Hill",
  },
  {
    postal_code: "18955",
    full_city_name: "Richlandtown, PA",
    city_name: "Richlandtown",
  },
  {
    postal_code: "95454",
    full_city_name: "Laytonville, CA",
    city_name: "Laytonville",
  },
  {
    postal_code: "45810",
    full_city_name: "Ada, OH",
    city_name: "Ada",
  },
  {
    postal_code: "28439",
    full_city_name: "Fair Bluff, NC",
    city_name: "Fair Bluff",
  },
  {
    postal_code: "01610",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "25661",
    full_city_name: "Williamson, WV",
    city_name: "Williamson",
  },
  {
    postal_code: "15956",
    full_city_name: "South Fork, PA",
    city_name: "South Fork",
  },
  {
    postal_code: "29643",
    full_city_name: "Fair Play, SC",
    city_name: "Fair Play",
  },
  {
    postal_code: "80807",
    full_city_name: "Burlington, CO",
    city_name: "Burlington",
  },
  {
    postal_code: "38258",
    full_city_name: "Trezevant, TN",
    city_name: "Trezevant",
  },
  {
    postal_code: "62917",
    full_city_name: "Carrier Mills, IL",
    city_name: "Carrier Mills",
  },
  {
    postal_code: "55932",
    full_city_name: "Elgin, MN",
    city_name: "Elgin",
  },
  {
    postal_code: "45628",
    full_city_name: "Frankfort, OH",
    city_name: "Frankfort",
  },
  {
    postal_code: "30124",
    full_city_name: "Cave Spring, GA",
    city_name: "Cave Spring",
  },
  {
    postal_code: "03770",
    full_city_name: "Meriden, NH",
    city_name: "Meriden",
  },
  {
    postal_code: "29714",
    full_city_name: "Fort Lawn, SC",
    city_name: "Fort Lawn",
  },
  {
    postal_code: "67058",
    full_city_name: "Harper, KS",
    city_name: "Harper",
  },
  {
    postal_code: "24381",
    full_city_name: "Woodlawn, VA",
    city_name: "Woodlawn",
  },
  {
    postal_code: "02744",
    full_city_name: "New Bedford, MA",
    city_name: "New Bedford",
  },
  {
    postal_code: "31569",
    full_city_name: "Woodbine, GA",
    city_name: "Woodbine",
  },
  {
    postal_code: "72405",
    full_city_name: "Jonesboro, AR",
    city_name: "Jonesboro",
  },
  {
    postal_code: "85543",
    full_city_name: "Pima, AZ",
    city_name: "Pima",
  },
  {
    postal_code: "93653",
    full_city_name: "Raymond, CA",
    city_name: "Raymond",
  },
  {
    postal_code: "94020",
    full_city_name: "La Honda, CA",
    city_name: "La Honda",
  },
  {
    postal_code: "12174",
    full_city_name: "Stuyvesant Falls, NY",
    city_name: "Stuyvesant Falls",
  },
  {
    postal_code: "91759",
    full_city_name: "Mt Baldy, CA",
    city_name: "Mt Baldy",
  },
  {
    postal_code: "70721",
    full_city_name: "Carville, LA",
    city_name: "Carville",
  },
  {
    postal_code: "74948",
    full_city_name: "Muldrow, OK",
    city_name: "Muldrow",
  },
  {
    postal_code: "37724",
    full_city_name: "Cumberland Gap, TN",
    city_name: "Cumberland Gap",
  },
  {
    postal_code: "20628",
    full_city_name: "Dameron, MD",
    city_name: "Dameron",
  },
  {
    postal_code: "17302",
    full_city_name: "Airville, PA",
    city_name: "Airville",
  },
  {
    postal_code: "55732",
    full_city_name: "Embarrass, MN",
    city_name: "Embarrass",
  },
  {
    postal_code: "14611",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "54749",
    full_city_name: "Knapp, WI",
    city_name: "Knapp",
  },
  {
    postal_code: "38015",
    full_city_name: "Burlison, TN",
    city_name: "Burlison",
  },
  {
    postal_code: "61772",
    full_city_name: "Shirley, IL",
    city_name: "Shirley",
  },
  {
    postal_code: "25320",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "65674",
    full_city_name: "Humansville, MO",
    city_name: "Humansville",
  },
  {
    postal_code: "36003",
    full_city_name: "Autaugaville, AL",
    city_name: "Autaugaville",
  },
  {
    postal_code: "48617",
    full_city_name: "Clare, MI",
    city_name: "Clare",
  },
  {
    postal_code: "49893",
    full_city_name: "Wallace, MI",
    city_name: "Wallace",
  },
  {
    postal_code: "72121",
    full_city_name: "Pangburn, AR",
    city_name: "Pangburn",
  },
  {
    postal_code: "61517",
    full_city_name: "Brimfield, IL",
    city_name: "Brimfield",
  },
  {
    postal_code: "28125",
    full_city_name: "Mount Ulla, NC",
    city_name: "Mount Ulla",
  },
  {
    postal_code: "13112",
    full_city_name: "Memphis, NY",
    city_name: "Memphis",
  },
  {
    postal_code: "38582",
    full_city_name: "Silver Point, TN",
    city_name: "Silver Point",
  },
  {
    postal_code: "62411",
    full_city_name: "Altamont, IL",
    city_name: "Altamont",
  },
  {
    postal_code: "17532",
    full_city_name: "Holtwood, PA",
    city_name: "Holtwood",
  },
  {
    postal_code: "13493",
    full_city_name: "Williamstown, NY",
    city_name: "Williamstown",
  },
  {
    postal_code: "28455",
    full_city_name: "Nakina, NC",
    city_name: "Nakina",
  },
  {
    postal_code: "06796",
    full_city_name: "West Cornwall, CT",
    city_name: "West Cornwall",
  },
  {
    postal_code: "56511",
    full_city_name: "Audubon, MN",
    city_name: "Audubon",
  },
  {
    postal_code: "62048",
    full_city_name: "Hartford, IL",
    city_name: "Hartford",
  },
  {
    postal_code: "40472",
    full_city_name: "Ravenna, KY",
    city_name: "Ravenna",
  },
  {
    postal_code: "24459",
    full_city_name: "Middlebrook, VA",
    city_name: "Middlebrook",
  },
  {
    postal_code: "43777",
    full_city_name: "Roseville, OH",
    city_name: "Roseville",
  },
  {
    postal_code: "14869",
    full_city_name: "Odessa, NY",
    city_name: "Odessa",
  },
  {
    postal_code: "75416",
    full_city_name: "Blossom, TX",
    city_name: "Blossom",
  },
  {
    postal_code: "51535",
    full_city_name: "Griswold, IA",
    city_name: "Griswold",
  },
  {
    postal_code: "95837",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "20776",
    full_city_name: "Harwood, MD",
    city_name: "Harwood",
  },
  {
    postal_code: "73027",
    full_city_name: "Coyle, OK",
    city_name: "Coyle",
  },
  {
    postal_code: "27897",
    full_city_name: "Woodland, NC",
    city_name: "Woodland",
  },
  {
    postal_code: "99181",
    full_city_name: "Valley, WA",
    city_name: "Valley",
  },
  {
    postal_code: "08889",
    full_city_name: "Whitehouse Station, NJ",
    city_name: "Whitehouse Station",
  },
  {
    postal_code: "15639",
    full_city_name: "Hunker, PA",
    city_name: "Hunker",
  },
  {
    postal_code: "61491",
    full_city_name: "Wyoming, IL",
    city_name: "Wyoming",
  },
  {
    postal_code: "71937",
    full_city_name: "Cove, AR",
    city_name: "Cove",
  },
  {
    postal_code: "68358",
    full_city_name: "Firth, NE",
    city_name: "Firth",
  },
  {
    postal_code: "56668",
    full_city_name: "Ranier, MN",
    city_name: "Ranier",
  },
  {
    postal_code: "52654",
    full_city_name: "Wayland, IA",
    city_name: "Wayland",
  },
  {
    postal_code: "64128",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "51576",
    full_city_name: "Underwood, IA",
    city_name: "Underwood",
  },
  {
    postal_code: "29163",
    full_city_name: "Vance, SC",
    city_name: "Vance",
  },
  {
    postal_code: "74955",
    full_city_name: "Sallisaw, OK",
    city_name: "Sallisaw",
  },
  {
    postal_code: "93643",
    full_city_name: "North Fork, CA",
    city_name: "North Fork",
  },
  {
    postal_code: "48440",
    full_city_name: "Hadley, MI",
    city_name: "Hadley",
  },
  {
    postal_code: "37841",
    full_city_name: "Oneida, TN",
    city_name: "Oneida",
  },
  {
    postal_code: "24317",
    full_city_name: "Cana, VA",
    city_name: "Cana",
  },
  {
    postal_code: "62326",
    full_city_name: "Colchester, IL",
    city_name: "Colchester",
  },
  {
    postal_code: "27551",
    full_city_name: "Macon, NC",
    city_name: "Macon",
  },
  {
    postal_code: "87025",
    full_city_name: "Jemez Springs, NM",
    city_name: "Jemez Springs",
  },
  {
    postal_code: "05839",
    full_city_name: "Glover, VT",
    city_name: "Glover",
  },
  {
    postal_code: "97383",
    full_city_name: "Stayton, OR",
    city_name: "Stayton",
  },
  {
    postal_code: "61726",
    full_city_name: "Chenoa, IL",
    city_name: "Chenoa",
  },
  {
    postal_code: "21718",
    full_city_name: "Burkittsville, MD",
    city_name: "Burkittsville",
  },
  {
    postal_code: "38565",
    full_city_name: "Grimsley, TN",
    city_name: "Grimsley",
  },
  {
    postal_code: "46301",
    full_city_name: "Beverly Shores, IN",
    city_name: "Beverly Shores",
  },
  {
    postal_code: "50548",
    full_city_name: "Humboldt, IA",
    city_name: "Humboldt",
  },
  {
    postal_code: "97027",
    full_city_name: "Gladstone, OR",
    city_name: "Gladstone",
  },
  {
    postal_code: "16113",
    full_city_name: "Clark, PA",
    city_name: "Clark",
  },
  {
    postal_code: "04630",
    full_city_name: "East Machias, ME",
    city_name: "East Machias",
  },
  {
    postal_code: "45062",
    full_city_name: "Seven Mile, OH",
    city_name: "Seven Mile",
  },
  {
    postal_code: "40510",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "05355",
    full_city_name: "Wardsboro, VT",
    city_name: "Wardsboro",
  },
  {
    postal_code: "28318",
    full_city_name: "Autryville, NC",
    city_name: "Autryville",
  },
  {
    postal_code: "55702",
    full_city_name: "Alborn, MN",
    city_name: "Alborn",
  },
  {
    postal_code: "23881",
    full_city_name: "Spring Grove, VA",
    city_name: "Spring Grove",
  },
  {
    postal_code: "05822",
    full_city_name: "Barton, VT",
    city_name: "Barton",
  },
  {
    postal_code: "79339",
    full_city_name: "Littlefield, TX",
    city_name: "Littlefield",
  },
  {
    postal_code: "52721",
    full_city_name: "Bennett, IA",
    city_name: "Bennett",
  },
  {
    postal_code: "11103",
    full_city_name: "Astoria, NY",
    city_name: "Astoria",
  },
  {
    postal_code: "47957",
    full_city_name: "Medaryville, IN",
    city_name: "Medaryville",
  },
  {
    postal_code: "24651",
    full_city_name: "Tazewell, VA",
    city_name: "Tazewell",
  },
  {
    postal_code: "84635",
    full_city_name: "Hinckley, UT",
    city_name: "Hinckley",
  },
  {
    postal_code: "49948",
    full_city_name: "Mass City, MI",
    city_name: "Mass City",
  },
  {
    postal_code: "72024",
    full_city_name: "Carlisle, AR",
    city_name: "Carlisle",
  },
  {
    postal_code: "95913",
    full_city_name: "Artois, CA",
    city_name: "Artois",
  },
  {
    postal_code: "15438",
    full_city_name: "Fayette City, PA",
    city_name: "Fayette City",
  },
  {
    postal_code: "77050",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "75831",
    full_city_name: "Buffalo, TX",
    city_name: "Buffalo",
  },
  {
    postal_code: "13408",
    full_city_name: "Morrisville, NY",
    city_name: "Morrisville",
  },
  {
    postal_code: "39476",
    full_city_name: "Richton, MS",
    city_name: "Richton",
  },
  {
    postal_code: "92004",
    full_city_name: "Borrego Springs, CA",
    city_name: "Borrego Springs",
  },
  {
    postal_code: "84328",
    full_city_name: "Paradise, UT",
    city_name: "Paradise",
  },
  {
    postal_code: "17502",
    full_city_name: "Bainbridge, PA",
    city_name: "Bainbridge",
  },
  {
    postal_code: "36037",
    full_city_name: "Greenville, AL",
    city_name: "Greenville",
  },
  {
    postal_code: "79201",
    full_city_name: "Childress, TX",
    city_name: "Childress",
  },
  {
    postal_code: "63435",
    full_city_name: "Canton, MO",
    city_name: "Canton",
  },
  {
    postal_code: "76520",
    full_city_name: "Cameron, TX",
    city_name: "Cameron",
  },
  {
    postal_code: "14425",
    full_city_name: "Farmington, NY",
    city_name: "Farmington",
  },
  {
    postal_code: "17035",
    full_city_name: "Honey Grove, PA",
    city_name: "Honey Grove",
  },
  {
    postal_code: "26181",
    full_city_name: "Washington, WV",
    city_name: "Washington",
  },
  {
    postal_code: "20612",
    full_city_name: "Benedict, MD",
    city_name: "Benedict",
  },
  {
    postal_code: "62021",
    full_city_name: "Dorsey, IL",
    city_name: "Dorsey",
  },
  {
    postal_code: "46031",
    full_city_name: "Atlanta, IN",
    city_name: "Atlanta",
  },
  {
    postal_code: "13084",
    full_city_name: "La Fayette, NY",
    city_name: "La Fayette",
  },
  {
    postal_code: "43718",
    full_city_name: "Belmont, OH",
    city_name: "Belmont",
  },
  {
    postal_code: "32641",
    full_city_name: "Gainesville, FL",
    city_name: "Gainesville",
  },
  {
    postal_code: "96055",
    full_city_name: "Los Molinos, CA",
    city_name: "Los Molinos",
  },
  {
    postal_code: "38587",
    full_city_name: "Walling, TN",
    city_name: "Walling",
  },
  {
    postal_code: "43940",
    full_city_name: "Neffs, OH",
    city_name: "Neffs",
  },
  {
    postal_code: "42029",
    full_city_name: "Calvert City, KY",
    city_name: "Calvert City",
  },
  {
    postal_code: "65711",
    full_city_name: "Mountain Grove, MO",
    city_name: "Mountain Grove",
  },
  {
    postal_code: "30173",
    full_city_name: "Silver Creek, GA",
    city_name: "Silver Creek",
  },
  {
    postal_code: "46953",
    full_city_name: "Marion, IN",
    city_name: "Marion",
  },
  {
    postal_code: "26571",
    full_city_name: "Farmington, WV",
    city_name: "Farmington",
  },
  {
    postal_code: "57722",
    full_city_name: "Buffalo Gap, SD",
    city_name: "Buffalo Gap",
  },
  {
    postal_code: "43158",
    full_city_name: "Union Furnace, OH",
    city_name: "Union Furnace",
  },
  {
    postal_code: "44637",
    full_city_name: "Killbuck, OH",
    city_name: "Killbuck",
  },
  {
    postal_code: "23169",
    full_city_name: "Topping, VA",
    city_name: "Topping",
  },
  {
    postal_code: "27341",
    full_city_name: "Seagrove, NC",
    city_name: "Seagrove",
  },
  {
    postal_code: "05154",
    full_city_name: "Saxtons River, VT",
    city_name: "Saxtons River",
  },
  {
    postal_code: "83806",
    full_city_name: "Bovill, ID",
    city_name: "Bovill",
  },
  {
    postal_code: "45101",
    full_city_name: "Aberdeen, OH",
    city_name: "Aberdeen",
  },
  {
    postal_code: "30582",
    full_city_name: "Young Harris, GA",
    city_name: "Young Harris",
  },
  {
    postal_code: "04627",
    full_city_name: "Deer Isle, ME",
    city_name: "Deer Isle",
  },
  {
    postal_code: "92539",
    full_city_name: "Anza, CA",
    city_name: "Anza",
  },
  {
    postal_code: "43320",
    full_city_name: "Edison, OH",
    city_name: "Edison",
  },
  {
    postal_code: "72661",
    full_city_name: "Oakland, AR",
    city_name: "Oakland",
  },
  {
    postal_code: "54611",
    full_city_name: "Alma Center, WI",
    city_name: "Alma Center",
  },
  {
    postal_code: "02150",
    full_city_name: "Chelsea, MA",
    city_name: "Chelsea",
  },
  {
    postal_code: "17951",
    full_city_name: "Mar Lin, PA",
    city_name: "Mar Lin",
  },
  {
    postal_code: "56386",
    full_city_name: "Wahkon, MN",
    city_name: "Wahkon",
  },
  {
    postal_code: "61013",
    full_city_name: "Cedarville, IL",
    city_name: "Cedarville",
  },
  {
    postal_code: "76877",
    full_city_name: "San Saba, TX",
    city_name: "San Saba",
  },
  {
    postal_code: "95916",
    full_city_name: "Berry Creek, CA",
    city_name: "Berry Creek",
  },
  {
    postal_code: "62905",
    full_city_name: "Alto Pass, IL",
    city_name: "Alto Pass",
  },
  {
    postal_code: "73061",
    full_city_name: "Morrison, OK",
    city_name: "Morrison",
  },
  {
    postal_code: "56256",
    full_city_name: "Madison, MN",
    city_name: "Madison",
  },
  {
    postal_code: "76041",
    full_city_name: "Forreston, TX",
    city_name: "Forreston",
  },
  {
    postal_code: "68850",
    full_city_name: "Lexington, NE",
    city_name: "Lexington",
  },
  {
    postal_code: "63621",
    full_city_name: "Arcadia, MO",
    city_name: "Arcadia",
  },
  {
    postal_code: "40337",
    full_city_name: "Jeffersonville, KY",
    city_name: "Jeffersonville",
  },
  {
    postal_code: "76671",
    full_city_name: "Morgan, TX",
    city_name: "Morgan",
  },
  {
    postal_code: "45821",
    full_city_name: "Cecil, OH",
    city_name: "Cecil",
  },
  {
    postal_code: "64742",
    full_city_name: "Drexel, MO",
    city_name: "Drexel",
  },
  {
    postal_code: "01128",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "85539",
    full_city_name: "Miami, AZ",
    city_name: "Miami",
  },
  {
    postal_code: "63332",
    full_city_name: "Augusta, MO",
    city_name: "Augusta",
  },
  {
    postal_code: "51650",
    full_city_name: "Riverton, IA",
    city_name: "Riverton",
  },
  {
    postal_code: "49968",
    full_city_name: "Wakefield, MI",
    city_name: "Wakefield",
  },
  {
    postal_code: "29718",
    full_city_name: "Jefferson, SC",
    city_name: "Jefferson",
  },
  {
    postal_code: "62707",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "16915",
    full_city_name: "Coudersport, PA",
    city_name: "Coudersport",
  },
  {
    postal_code: "86047",
    full_city_name: "Winslow, AZ",
    city_name: "Winslow",
  },
  {
    postal_code: "48074",
    full_city_name: "Smiths Creek, MI",
    city_name: "Smiths Creek",
  },
  {
    postal_code: "84520",
    full_city_name: "East Carbon, UT",
    city_name: "East Carbon",
  },
  {
    postal_code: "64139",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "49228",
    full_city_name: "Blissfield, MI",
    city_name: "Blissfield",
  },
  {
    postal_code: "54974",
    full_city_name: "Rosendale, WI",
    city_name: "Rosendale",
  },
  {
    postal_code: "61024",
    full_city_name: "Durand, IL",
    city_name: "Durand",
  },
  {
    postal_code: "51573",
    full_city_name: "Stanton, IA",
    city_name: "Stanton",
  },
  {
    postal_code: "85603",
    full_city_name: "Bisbee, AZ",
    city_name: "Bisbee",
  },
  {
    postal_code: "05077",
    full_city_name: "Tunbridge, VT",
    city_name: "Tunbridge",
  },
  {
    postal_code: "82053",
    full_city_name: "Burns, WY",
    city_name: "Burns",
  },
  {
    postal_code: "48306",
    full_city_name: "Rochester, MI",
    city_name: "Rochester",
  },
  {
    postal_code: "45744",
    full_city_name: "Lowell, OH",
    city_name: "Lowell",
  },
  {
    postal_code: "87513",
    full_city_name: "Arroyo Hondo, NM",
    city_name: "Arroyo Hondo",
  },
  {
    postal_code: "24065",
    full_city_name: "Boones Mill, VA",
    city_name: "Boones Mill",
  },
  {
    postal_code: "38369",
    full_city_name: "Rutherford, TN",
    city_name: "Rutherford",
  },
  {
    postal_code: "25507",
    full_city_name: "Ceredo, WV",
    city_name: "Ceredo",
  },
  {
    postal_code: "06781",
    full_city_name: "Pequabuck, CT",
    city_name: "Pequabuck",
  },
  {
    postal_code: "12436",
    full_city_name: "Haines Falls, NY",
    city_name: "Haines Falls",
  },
  {
    postal_code: "95439",
    full_city_name: "Fulton, CA",
    city_name: "Fulton",
  },
  {
    postal_code: "80827",
    full_city_name: "Lake George, CO",
    city_name: "Lake George",
  },
  {
    postal_code: "99171",
    full_city_name: "Saint John, WA",
    city_name: "Saint John",
  },
  {
    postal_code: "39218",
    full_city_name: "Richland, MS",
    city_name: "Richland",
  },
  {
    postal_code: "28670",
    full_city_name: "Ronda, NC",
    city_name: "Ronda",
  },
  {
    postal_code: "79766",
    full_city_name: "Odessa, TX",
    city_name: "Odessa",
  },
  {
    postal_code: "21798",
    full_city_name: "Woodsboro, MD",
    city_name: "Woodsboro",
  },
  {
    postal_code: "22720",
    full_city_name: "Goldvein, VA",
    city_name: "Goldvein",
  },
  {
    postal_code: "11740",
    full_city_name: "Greenlawn, NY",
    city_name: "Greenlawn",
  },
  {
    postal_code: "62629",
    full_city_name: "Chatham, IL",
    city_name: "Chatham",
  },
  {
    postal_code: "47232",
    full_city_name: "Elizabethtown, IN",
    city_name: "Elizabethtown",
  },
  {
    postal_code: "63663",
    full_city_name: "Pilot Knob, MO",
    city_name: "Pilot Knob",
  },
  {
    postal_code: "88053",
    full_city_name: "Pinos Altos, NM",
    city_name: "Pinos Altos",
  },
  {
    postal_code: "13661",
    full_city_name: "Mannsville, NY",
    city_name: "Mannsville",
  },
  {
    postal_code: "66006",
    full_city_name: "Baldwin City, KS",
    city_name: "Baldwin City",
  },
  {
    postal_code: "96716",
    full_city_name: "Hanapepe, HI",
    city_name: "Hanapepe",
  },
  {
    postal_code: "24657",
    full_city_name: "Whitewood, VA",
    city_name: "Whitewood",
  },
  {
    postal_code: "76486",
    full_city_name: "Perrin, TX",
    city_name: "Perrin",
  },
  {
    postal_code: "52640",
    full_city_name: "Morning Sun, IA",
    city_name: "Morning Sun",
  },
  {
    postal_code: "47850",
    full_city_name: "Farmersburg, IN",
    city_name: "Farmersburg",
  },
  {
    postal_code: "35178",
    full_city_name: "Vincent, AL",
    city_name: "Vincent",
  },
  {
    postal_code: "02462",
    full_city_name: "Newton Lower Falls, MA",
    city_name: "Newton Lower Falls",
  },
  {
    postal_code: "78607",
    full_city_name: "Bluffton, TX",
    city_name: "Bluffton",
  },
  {
    postal_code: "79843",
    full_city_name: "Marfa, TX",
    city_name: "Marfa",
  },
  {
    postal_code: "51555",
    full_city_name: "Missouri Valley, IA",
    city_name: "Missouri Valley",
  },
  {
    postal_code: "83286",
    full_city_name: "Weston, ID",
    city_name: "Weston",
  },
  {
    postal_code: "55018",
    full_city_name: "Dennison, MN",
    city_name: "Dennison",
  },
  {
    postal_code: "51564",
    full_city_name: "Pisgah, IA",
    city_name: "Pisgah",
  },
  {
    postal_code: "52571",
    full_city_name: "Moravia, IA",
    city_name: "Moravia",
  },
  {
    postal_code: "75681",
    full_city_name: "Mount Enterprise, TX",
    city_name: "Mount Enterprise",
  },
  {
    postal_code: "91950",
    full_city_name: "National City, CA",
    city_name: "National City",
  },
  {
    postal_code: "50020",
    full_city_name: "Anita, IA",
    city_name: "Anita",
  },
  {
    postal_code: "49337",
    full_city_name: "Newaygo, MI",
    city_name: "Newaygo",
  },
  {
    postal_code: "26408",
    full_city_name: "Mount Clare, WV",
    city_name: "Mount Clare",
  },
  {
    postal_code: "66861",
    full_city_name: "Marion, KS",
    city_name: "Marion",
  },
  {
    postal_code: "92339",
    full_city_name: "Forest Falls, CA",
    city_name: "Forest Falls",
  },
  {
    postal_code: "11975",
    full_city_name: "Wainscott, NY",
    city_name: "Wainscott",
  },
  {
    postal_code: "99180",
    full_city_name: "Usk, WA",
    city_name: "Usk",
  },
  {
    postal_code: "79357",
    full_city_name: "Ralls, TX",
    city_name: "Ralls",
  },
  {
    postal_code: "61843",
    full_city_name: "Fisher, IL",
    city_name: "Fisher",
  },
  {
    postal_code: "63626",
    full_city_name: "Blackwell, MO",
    city_name: "Blackwell",
  },
  {
    postal_code: "54005",
    full_city_name: "Clear Lake, WI",
    city_name: "Clear Lake",
  },
  {
    postal_code: "14823",
    full_city_name: "Canisteo, NY",
    city_name: "Canisteo",
  },
  {
    postal_code: "48034",
    full_city_name: "Southfield, MI",
    city_name: "Southfield",
  },
  {
    postal_code: "61777",
    full_city_name: "Wapella, IL",
    city_name: "Wapella",
  },
  {
    postal_code: "44880",
    full_city_name: "Sullivan, OH",
    city_name: "Sullivan",
  },
  {
    postal_code: "71078",
    full_city_name: "Stonewall, LA",
    city_name: "Stonewall",
  },
  {
    postal_code: "54836",
    full_city_name: "Foxboro, WI",
    city_name: "Foxboro",
  },
  {
    postal_code: "61877",
    full_city_name: "Sidney, IL",
    city_name: "Sidney",
  },
  {
    postal_code: "30522",
    full_city_name: "Cherry Log, GA",
    city_name: "Cherry Log",
  },
  {
    postal_code: "15863",
    full_city_name: "Stump Creek, PA",
    city_name: "Stump Creek",
  },
  {
    postal_code: "24325",
    full_city_name: "Dugspur, VA",
    city_name: "Dugspur",
  },
  {
    postal_code: "55027",
    full_city_name: "Goodhue, MN",
    city_name: "Goodhue",
  },
  {
    postal_code: "67625",
    full_city_name: "Bogue, KS",
    city_name: "Bogue",
  },
  {
    postal_code: "44645",
    full_city_name: "Marshallville, OH",
    city_name: "Marshallville",
  },
  {
    postal_code: "22802",
    full_city_name: "Harrisonburg, VA",
    city_name: "Harrisonburg",
  },
  {
    postal_code: "65080",
    full_city_name: "Tebbetts, MO",
    city_name: "Tebbetts",
  },
  {
    postal_code: "13316",
    full_city_name: "Camden, NY",
    city_name: "Camden",
  },
  {
    postal_code: "65611",
    full_city_name: "Blue Eye, MO",
    city_name: "Blue Eye",
  },
  {
    postal_code: "18334",
    full_city_name: "Long Pond, PA",
    city_name: "Long Pond",
  },
  {
    postal_code: "55087",
    full_city_name: "Warsaw, MN",
    city_name: "Warsaw",
  },
  {
    postal_code: "37029",
    full_city_name: "Burns, TN",
    city_name: "Burns",
  },
  {
    postal_code: "95494",
    full_city_name: "Yorkville, CA",
    city_name: "Yorkville",
  },
  {
    postal_code: "18832",
    full_city_name: "Monroeton, PA",
    city_name: "Monroeton",
  },
  {
    postal_code: "29667",
    full_city_name: "Norris, SC",
    city_name: "Norris",
  },
  {
    postal_code: "18823",
    full_city_name: "Harford, PA",
    city_name: "Harford",
  },
  {
    postal_code: "25070",
    full_city_name: "Eleanor, WV",
    city_name: "Eleanor",
  },
  {
    postal_code: "84757",
    full_city_name: "New Harmony, UT",
    city_name: "New Harmony",
  },
  {
    postal_code: "65753",
    full_city_name: "Sparta, MO",
    city_name: "Sparta",
  },
  {
    postal_code: "85937",
    full_city_name: "Snowflake, AZ",
    city_name: "Snowflake",
  },
  {
    postal_code: "49880",
    full_city_name: "Rock, MI",
    city_name: "Rock",
  },
  {
    postal_code: "98625",
    full_city_name: "Kalama, WA",
    city_name: "Kalama",
  },
  {
    postal_code: "14424",
    full_city_name: "Canandaigua, NY",
    city_name: "Canandaigua",
  },
  {
    postal_code: "70762",
    full_city_name: "Oscar, LA",
    city_name: "Oscar",
  },
  {
    postal_code: "61462",
    full_city_name: "Monmouth, IL",
    city_name: "Monmouth",
  },
  {
    postal_code: "43157",
    full_city_name: "Thurston, OH",
    city_name: "Thurston",
  },
  {
    postal_code: "47433",
    full_city_name: "Gosport, IN",
    city_name: "Gosport",
  },
  {
    postal_code: "37318",
    full_city_name: "Cowan, TN",
    city_name: "Cowan",
  },
  {
    postal_code: "98951",
    full_city_name: "Wapato, WA",
    city_name: "Wapato",
  },
  {
    postal_code: "46150",
    full_city_name: "Manilla, IN",
    city_name: "Manilla",
  },
  {
    postal_code: "25422",
    full_city_name: "Great Cacapon, WV",
    city_name: "Great Cacapon",
  },
  {
    postal_code: "79521",
    full_city_name: "Haskell, TX",
    city_name: "Haskell",
  },
  {
    postal_code: "21160",
    full_city_name: "Whiteford, MD",
    city_name: "Whiteford",
  },
  {
    postal_code: "97232",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "78071",
    full_city_name: "Three Rivers, TX",
    city_name: "Three Rivers",
  },
  {
    postal_code: "21913",
    full_city_name: "Cecilton, MD",
    city_name: "Cecilton",
  },
  {
    postal_code: "28679",
    full_city_name: "Sugar Grove, NC",
    city_name: "Sugar Grove",
  },
  {
    postal_code: "18250",
    full_city_name: "Summit Hill, PA",
    city_name: "Summit Hill",
  },
  {
    postal_code: "49883",
    full_city_name: "Seney, MI",
    city_name: "Seney",
  },
  {
    postal_code: "39057",
    full_city_name: "Conehatta, MS",
    city_name: "Conehatta",
  },
  {
    postal_code: "56150",
    full_city_name: "Lakefield, MN",
    city_name: "Lakefield",
  },
  {
    postal_code: "10708",
    full_city_name: "Bronxville, NY",
    city_name: "Bronxville",
  },
  {
    postal_code: "71009",
    full_city_name: "Blanchard, LA",
    city_name: "Blanchard",
  },
  {
    postal_code: "27974",
    full_city_name: "Shiloh, NC",
    city_name: "Shiloh",
  },
  {
    postal_code: "53969",
    full_city_name: "Wyocena, WI",
    city_name: "Wyocena",
  },
  {
    postal_code: "12764",
    full_city_name: "Narrowsburg, NY",
    city_name: "Narrowsburg",
  },
  {
    postal_code: "42129",
    full_city_name: "Edmonton, KY",
    city_name: "Edmonton",
  },
  {
    postal_code: "49238",
    full_city_name: "Deerfield, MI",
    city_name: "Deerfield",
  },
  {
    postal_code: "22433",
    full_city_name: "Burr Hill, VA",
    city_name: "Burr Hill",
  },
  {
    postal_code: "02113",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "55756",
    full_city_name: "Kerrick, MN",
    city_name: "Kerrick",
  },
  {
    postal_code: "45813",
    full_city_name: "Antwerp, OH",
    city_name: "Antwerp",
  },
  {
    postal_code: "56592",
    full_city_name: "Winger, MN",
    city_name: "Winger",
  },
  {
    postal_code: "52659",
    full_city_name: "Winfield, IA",
    city_name: "Winfield",
  },
  {
    postal_code: "61052",
    full_city_name: "Monroe Center, IL",
    city_name: "Monroe Center",
  },
  {
    postal_code: "56318",
    full_city_name: "Burtrum, MN",
    city_name: "Burtrum",
  },
  {
    postal_code: "54655",
    full_city_name: "Soldiers Grove, WI",
    city_name: "Soldiers Grove",
  },
  {
    postal_code: "49719",
    full_city_name: "Cedarville, MI",
    city_name: "Cedarville",
  },
  {
    postal_code: "06069",
    full_city_name: "Sharon, CT",
    city_name: "Sharon",
  },
  {
    postal_code: "38668",
    full_city_name: "Senatobia, MS",
    city_name: "Senatobia",
  },
  {
    postal_code: "27013",
    full_city_name: "Cleveland, NC",
    city_name: "Cleveland",
  },
  {
    postal_code: "49068",
    full_city_name: "Marshall, MI",
    city_name: "Marshall",
  },
  {
    postal_code: "04631",
    full_city_name: "Eastport, ME",
    city_name: "Eastport",
  },
  {
    postal_code: "54564",
    full_city_name: "Tripoli, WI",
    city_name: "Tripoli",
  },
  {
    postal_code: "93272",
    full_city_name: "Tipton, CA",
    city_name: "Tipton",
  },
  {
    postal_code: "38464",
    full_city_name: "Lawrenceburg, TN",
    city_name: "Lawrenceburg",
  },
  {
    postal_code: "04083",
    full_city_name: "Springvale, ME",
    city_name: "Springvale",
  },
  {
    postal_code: "56046",
    full_city_name: "Hope, MN",
    city_name: "Hope",
  },
  {
    postal_code: "71463",
    full_city_name: "Oakdale, LA",
    city_name: "Oakdale",
  },
  {
    postal_code: "04220",
    full_city_name: "Buckfield, ME",
    city_name: "Buckfield",
  },
  {
    postal_code: "43945",
    full_city_name: "Salineville, OH",
    city_name: "Salineville",
  },
  {
    postal_code: "24095",
    full_city_name: "Goodview, VA",
    city_name: "Goodview",
  },
  {
    postal_code: "12150",
    full_city_name: "Rotterdam Junction, NY",
    city_name: "Rotterdam Junction",
  },
  {
    postal_code: "81648",
    full_city_name: "Rangely, CO",
    city_name: "Rangely",
  },
  {
    postal_code: "50155",
    full_city_name: "Macksburg, IA",
    city_name: "Macksburg",
  },
  {
    postal_code: "12161",
    full_city_name: "South Bethlehem, NY",
    city_name: "South Bethlehem",
  },
  {
    postal_code: "42133",
    full_city_name: "Fountain Run, KY",
    city_name: "Fountain Run",
  },
  {
    postal_code: "61778",
    full_city_name: "Waynesville, IL",
    city_name: "Waynesville",
  },
  {
    postal_code: "03740",
    full_city_name: "Bath, NH",
    city_name: "Bath",
  },
  {
    postal_code: "41098",
    full_city_name: "Worthville, KY",
    city_name: "Worthville",
  },
  {
    postal_code: "04666",
    full_city_name: "Pembroke, ME",
    city_name: "Pembroke",
  },
  {
    postal_code: "51532",
    full_city_name: "Elliott, IA",
    city_name: "Elliott",
  },
  {
    postal_code: "29532",
    full_city_name: "Darlington, SC",
    city_name: "Darlington",
  },
  {
    postal_code: "21625",
    full_city_name: "Cordova, MD",
    city_name: "Cordova",
  },
  {
    postal_code: "49707",
    full_city_name: "Alpena, MI",
    city_name: "Alpena",
  },
  {
    postal_code: "59826",
    full_city_name: "Condon, MT",
    city_name: "Condon",
  },
  {
    postal_code: "96118",
    full_city_name: "Loyalton, CA",
    city_name: "Loyalton",
  },
  {
    postal_code: "61831",
    full_city_name: "Collison, IL",
    city_name: "Collison",
  },
  {
    postal_code: "80030",
    full_city_name: "Westminster, CO",
    city_name: "Westminster",
  },
  {
    postal_code: "04548",
    full_city_name: "Georgetown, ME",
    city_name: "Georgetown",
  },
  {
    postal_code: "23920",
    full_city_name: "Brodnax, VA",
    city_name: "Brodnax",
  },
  {
    postal_code: "73755",
    full_city_name: "Longdale, OK",
    city_name: "Longdale",
  },
  {
    postal_code: "27559",
    full_city_name: "Moncure, NC",
    city_name: "Moncure",
  },
  {
    postal_code: "44815",
    full_city_name: "Bettsville, OH",
    city_name: "Bettsville",
  },
  {
    postal_code: "01026",
    full_city_name: "Cummington, MA",
    city_name: "Cummington",
  },
  {
    postal_code: "29351",
    full_city_name: "Joanna, SC",
    city_name: "Joanna",
  },
  {
    postal_code: "54664",
    full_city_name: "Viola, WI",
    city_name: "Viola",
  },
  {
    postal_code: "15049",
    full_city_name: "Harwick, PA",
    city_name: "Harwick",
  },
  {
    postal_code: "45303",
    full_city_name: "Ansonia, OH",
    city_name: "Ansonia",
  },
  {
    postal_code: "04068",
    full_city_name: "Porter, ME",
    city_name: "Porter",
  },
  {
    postal_code: "16248",
    full_city_name: "Rimersburg, PA",
    city_name: "Rimersburg",
  },
  {
    postal_code: "13452",
    full_city_name: "Saint Johnsville, NY",
    city_name: "Saint Johnsville",
  },
  {
    postal_code: "17322",
    full_city_name: "Felton, PA",
    city_name: "Felton",
  },
  {
    postal_code: "65466",
    full_city_name: "Eminence, MO",
    city_name: "Eminence",
  },
  {
    postal_code: "95468",
    full_city_name: "Point Arena, CA",
    city_name: "Point Arena",
  },
  {
    postal_code: "68956",
    full_city_name: "Kenesaw, NE",
    city_name: "Kenesaw",
  },
  {
    postal_code: "95935",
    full_city_name: "Dobbins, CA",
    city_name: "Dobbins",
  },
  {
    postal_code: "16232",
    full_city_name: "Knox, PA",
    city_name: "Knox",
  },
  {
    postal_code: "50105",
    full_city_name: "Gilbert, IA",
    city_name: "Gilbert",
  },
  {
    postal_code: "25285",
    full_city_name: "Wallback, WV",
    city_name: "Wallback",
  },
  {
    postal_code: "48738",
    full_city_name: "Greenbush, MI",
    city_name: "Greenbush",
  },
  {
    postal_code: "68960",
    full_city_name: "Naponee, NE",
    city_name: "Naponee",
  },
  {
    postal_code: "42160",
    full_city_name: "Park City, KY",
    city_name: "Park City",
  },
  {
    postal_code: "59846",
    full_city_name: "Huson, MT",
    city_name: "Huson",
  },
  {
    postal_code: "76264",
    full_city_name: "Sadler, TX",
    city_name: "Sadler",
  },
  {
    postal_code: "48124",
    full_city_name: "Dearborn, MI",
    city_name: "Dearborn",
  },
  {
    postal_code: "65548",
    full_city_name: "Mountain View, MO",
    city_name: "Mountain View",
  },
  {
    postal_code: "24273",
    full_city_name: "Norton, VA",
    city_name: "Norton",
  },
  {
    postal_code: "79541",
    full_city_name: "Ovalo, TX",
    city_name: "Ovalo",
  },
  {
    postal_code: "94548",
    full_city_name: "Knightsen, CA",
    city_name: "Knightsen",
  },
  {
    postal_code: "63533",
    full_city_name: "Brashear, MO",
    city_name: "Brashear",
  },
  {
    postal_code: "26582",
    full_city_name: "Mannington, WV",
    city_name: "Mannington",
  },
  {
    postal_code: "95374",
    full_city_name: "Stevinson, CA",
    city_name: "Stevinson",
  },
  {
    postal_code: "76511",
    full_city_name: "Bartlett, TX",
    city_name: "Bartlett",
  },
  {
    postal_code: "21532",
    full_city_name: "Frostburg, MD",
    city_name: "Frostburg",
  },
  {
    postal_code: "50217",
    full_city_name: "Paton, IA",
    city_name: "Paton",
  },
  {
    postal_code: "13750",
    full_city_name: "Davenport, NY",
    city_name: "Davenport",
  },
  {
    postal_code: "25082",
    full_city_name: "Fraziers Bottom, WV",
    city_name: "Fraziers Bottom",
  },
  {
    postal_code: "96863",
    full_city_name: "Mcbh Kaneohe Bay, HI",
    city_name: "Mcbh Kaneohe Bay",
  },
  {
    postal_code: "65230",
    full_city_name: "Armstrong, MO",
    city_name: "Armstrong",
  },
  {
    postal_code: "39038",
    full_city_name: "Belzoni, MS",
    city_name: "Belzoni",
  },
  {
    postal_code: "22660",
    full_city_name: "Toms Brook, VA",
    city_name: "Toms Brook",
  },
  {
    postal_code: "61353",
    full_city_name: "Paw Paw, IL",
    city_name: "Paw Paw",
  },
  {
    postal_code: "49267",
    full_city_name: "Ottawa Lake, MI",
    city_name: "Ottawa Lake",
  },
  {
    postal_code: "64454",
    full_city_name: "Gower, MO",
    city_name: "Gower",
  },
  {
    postal_code: "06259",
    full_city_name: "Pomfret Center, CT",
    city_name: "Pomfret Center",
  },
  {
    postal_code: "15928",
    full_city_name: "Davidsville, PA",
    city_name: "Davidsville",
  },
  {
    postal_code: "06266",
    full_city_name: "South Windham, CT",
    city_name: "South Windham",
  },
  {
    postal_code: "18071",
    full_city_name: "Palmerton, PA",
    city_name: "Palmerton",
  },
  {
    postal_code: "48661",
    full_city_name: "West Branch, MI",
    city_name: "West Branch",
  },
  {
    postal_code: "76470",
    full_city_name: "Ranger, TX",
    city_name: "Ranger",
  },
  {
    postal_code: "95372",
    full_city_name: "Soulsbyville, CA",
    city_name: "Soulsbyville",
  },
  {
    postal_code: "92268",
    full_city_name: "Pioneertown, CA",
    city_name: "Pioneertown",
  },
  {
    postal_code: "24528",
    full_city_name: "Brookneal, VA",
    city_name: "Brookneal",
  },
  {
    postal_code: "45168",
    full_city_name: "Russellville, OH",
    city_name: "Russellville",
  },
  {
    postal_code: "62009",
    full_city_name: "Benld, IL",
    city_name: "Benld",
  },
  {
    postal_code: "14532",
    full_city_name: "Phelps, NY",
    city_name: "Phelps",
  },
  {
    postal_code: "97018",
    full_city_name: "Columbia City, OR",
    city_name: "Columbia City",
  },
  {
    postal_code: "13337",
    full_city_name: "Fly Creek, NY",
    city_name: "Fly Creek",
  },
  {
    postal_code: "81233",
    full_city_name: "Howard, CO",
    city_name: "Howard",
  },
  {
    postal_code: "79901",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "50218",
    full_city_name: "Patterson, IA",
    city_name: "Patterson",
  },
  {
    postal_code: "13167",
    full_city_name: "West Monroe, NY",
    city_name: "West Monroe",
  },
  {
    postal_code: "51433",
    full_city_name: "Auburn, IA",
    city_name: "Auburn",
  },
  {
    postal_code: "13437",
    full_city_name: "Redfield, NY",
    city_name: "Redfield",
  },
  {
    postal_code: "26851",
    full_city_name: "Wardensville, WV",
    city_name: "Wardensville",
  },
  {
    postal_code: "48876",
    full_city_name: "Potterville, MI",
    city_name: "Potterville",
  },
  {
    postal_code: "50007",
    full_city_name: "Alleman, IA",
    city_name: "Alleman",
  },
  {
    postal_code: "43734",
    full_city_name: "Duncan Falls, OH",
    city_name: "Duncan Falls",
  },
  {
    postal_code: "18824",
    full_city_name: "Hop Bottom, PA",
    city_name: "Hop Bottom",
  },
  {
    postal_code: "49050",
    full_city_name: "Dowling, MI",
    city_name: "Dowling",
  },
  {
    postal_code: "48114",
    full_city_name: "Brighton, MI",
    city_name: "Brighton",
  },
  {
    postal_code: "30627",
    full_city_name: "Carlton, GA",
    city_name: "Carlton",
  },
  {
    postal_code: "99139",
    full_city_name: "Ione, WA",
    city_name: "Ione",
  },
  {
    postal_code: "22460",
    full_city_name: "Farnham, VA",
    city_name: "Farnham",
  },
  {
    postal_code: "21841",
    full_city_name: "Newark, MD",
    city_name: "Newark",
  },
  {
    postal_code: "83341",
    full_city_name: "Kimberly, ID",
    city_name: "Kimberly",
  },
  {
    postal_code: "03233",
    full_city_name: "Elkins, NH",
    city_name: "Elkins",
  },
  {
    postal_code: "35118",
    full_city_name: "Mulga, AL",
    city_name: "Mulga",
  },
  {
    postal_code: "65350",
    full_city_name: "Smithton, MO",
    city_name: "Smithton",
  },
  {
    postal_code: "89420",
    full_city_name: "Luning, NV",
    city_name: "Luning",
  },
  {
    postal_code: "14610",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "29448",
    full_city_name: "Harleyville, SC",
    city_name: "Harleyville",
  },
  {
    postal_code: "74354",
    full_city_name: "Miami, OK",
    city_name: "Miami",
  },
  {
    postal_code: "54028",
    full_city_name: "Woodville, WI",
    city_name: "Woodville",
  },
  {
    postal_code: "12192",
    full_city_name: "West Coxsackie, NY",
    city_name: "West Coxsackie",
  },
  {
    postal_code: "44905",
    full_city_name: "Mansfield, OH",
    city_name: "Mansfield",
  },
  {
    postal_code: "38930",
    full_city_name: "Greenwood, MS",
    city_name: "Greenwood",
  },
  {
    postal_code: "26292",
    full_city_name: "Thomas, WV",
    city_name: "Thomas",
  },
  {
    postal_code: "97845",
    full_city_name: "John Day, OR",
    city_name: "John Day",
  },
  {
    postal_code: "10562",
    full_city_name: "Ossining, NY",
    city_name: "Ossining",
  },
  {
    postal_code: "93442",
    full_city_name: "Morro Bay, CA",
    city_name: "Morro Bay",
  },
  {
    postal_code: "76367",
    full_city_name: "Iowa Park, TX",
    city_name: "Iowa Park",
  },
  {
    postal_code: "61243",
    full_city_name: "Deer Grove, IL",
    city_name: "Deer Grove",
  },
  {
    postal_code: "50436",
    full_city_name: "Forest City, IA",
    city_name: "Forest City",
  },
  {
    postal_code: "72529",
    full_city_name: "Cherokee Village, AR",
    city_name: "Cherokee Village",
  },
  {
    postal_code: "53920",
    full_city_name: "Briggsville, WI",
    city_name: "Briggsville",
  },
  {
    postal_code: "39044",
    full_city_name: "Braxton, MS",
    city_name: "Braxton",
  },
  {
    postal_code: "43732",
    full_city_name: "Cumberland, OH",
    city_name: "Cumberland",
  },
  {
    postal_code: "77836",
    full_city_name: "Caldwell, TX",
    city_name: "Caldwell",
  },
  {
    postal_code: "56228",
    full_city_name: "Cosmos, MN",
    city_name: "Cosmos",
  },
  {
    postal_code: "66617",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "44215",
    full_city_name: "Chippewa Lake, OH",
    city_name: "Chippewa Lake",
  },
  {
    postal_code: "74859",
    full_city_name: "Okemah, OK",
    city_name: "Okemah",
  },
  {
    postal_code: "83548",
    full_city_name: "Reubens, ID",
    city_name: "Reubens",
  },
  {
    postal_code: "74366",
    full_city_name: "Spavinaw, OK",
    city_name: "Spavinaw",
  },
  {
    postal_code: "77871",
    full_city_name: "Normangee, TX",
    city_name: "Normangee",
  },
  {
    postal_code: "83654",
    full_city_name: "New Meadows, ID",
    city_name: "New Meadows",
  },
  {
    postal_code: "50854",
    full_city_name: "Mount Ayr, IA",
    city_name: "Mount Ayr",
  },
  {
    postal_code: "24348",
    full_city_name: "Independence, VA",
    city_name: "Independence",
  },
  {
    postal_code: "61443",
    full_city_name: "Kewanee, IL",
    city_name: "Kewanee",
  },
  {
    postal_code: "42455",
    full_city_name: "Sebree, KY",
    city_name: "Sebree",
  },
  {
    postal_code: "08804",
    full_city_name: "Bloomsbury, NJ",
    city_name: "Bloomsbury",
  },
  {
    postal_code: "22849",
    full_city_name: "Shenandoah, VA",
    city_name: "Shenandoah",
  },
  {
    postal_code: "40815",
    full_city_name: "Cawood, KY",
    city_name: "Cawood",
  },
  {
    postal_code: "26050",
    full_city_name: "Newell, WV",
    city_name: "Newell",
  },
  {
    postal_code: "69147",
    full_city_name: "Lewellen, NE",
    city_name: "Lewellen",
  },
  {
    postal_code: "84649",
    full_city_name: "Oak City, UT",
    city_name: "Oak City",
  },
  {
    postal_code: "41472",
    full_city_name: "West Liberty, KY",
    city_name: "West Liberty",
  },
  {
    postal_code: "13646",
    full_city_name: "Hammond, NY",
    city_name: "Hammond",
  },
  {
    postal_code: "54612",
    full_city_name: "Arcadia, WI",
    city_name: "Arcadia",
  },
  {
    postal_code: "24641",
    full_city_name: "Richlands, VA",
    city_name: "Richlands",
  },
  {
    postal_code: "29111",
    full_city_name: "New Zion, SC",
    city_name: "New Zion",
  },
  {
    postal_code: "53115",
    full_city_name: "Delavan, WI",
    city_name: "Delavan",
  },
  {
    postal_code: "53569",
    full_city_name: "Montfort, WI",
    city_name: "Montfort",
  },
  {
    postal_code: "71433",
    full_city_name: "Glenmora, LA",
    city_name: "Glenmora",
  },
  {
    postal_code: "14609",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "22742",
    full_city_name: "Sumerduck, VA",
    city_name: "Sumerduck",
  },
  {
    postal_code: "23974",
    full_city_name: "Victoria, VA",
    city_name: "Victoria",
  },
  {
    postal_code: "05461",
    full_city_name: "Hinesburg, VT",
    city_name: "Hinesburg",
  },
  {
    postal_code: "56087",
    full_city_name: "Springfield, MN",
    city_name: "Springfield",
  },
  {
    postal_code: "67491",
    full_city_name: "Windom, KS",
    city_name: "Windom",
  },
  {
    postal_code: "84719",
    full_city_name: "Brian Head, UT",
    city_name: "Brian Head",
  },
  {
    postal_code: "05048",
    full_city_name: "Hartland, VT",
    city_name: "Hartland",
  },
  {
    postal_code: "54875",
    full_city_name: "Springbrook, WI",
    city_name: "Springbrook",
  },
  {
    postal_code: "45865",
    full_city_name: "Minster, OH",
    city_name: "Minster",
  },
  {
    postal_code: "77577",
    full_city_name: "Liverpool, TX",
    city_name: "Liverpool",
  },
  {
    postal_code: "54763",
    full_city_name: "Ridgeland, WI",
    city_name: "Ridgeland",
  },
  {
    postal_code: "56549",
    full_city_name: "Hawley, MN",
    city_name: "Hawley",
  },
  {
    postal_code: "95485",
    full_city_name: "Upper Lake, CA",
    city_name: "Upper Lake",
  },
  {
    postal_code: "49815",
    full_city_name: "Channing, MI",
    city_name: "Channing",
  },
  {
    postal_code: "08850",
    full_city_name: "Milltown, NJ",
    city_name: "Milltown",
  },
  {
    postal_code: "56016",
    full_city_name: "Clarks Grove, MN",
    city_name: "Clarks Grove",
  },
  {
    postal_code: "38460",
    full_city_name: "Goodspring, TN",
    city_name: "Goodspring",
  },
  {
    postal_code: "16822",
    full_city_name: "Beech Creek, PA",
    city_name: "Beech Creek",
  },
  {
    postal_code: "18641",
    full_city_name: "Pittston, PA",
    city_name: "Pittston",
  },
  {
    postal_code: "28347",
    full_city_name: "Hoffman, NC",
    city_name: "Hoffman",
  },
  {
    postal_code: "21104",
    full_city_name: "Marriottsville, MD",
    city_name: "Marriottsville",
  },
  {
    postal_code: "54241",
    full_city_name: "Two Rivers, WI",
    city_name: "Two Rivers",
  },
  {
    postal_code: "13076",
    full_city_name: "Hastings, NY",
    city_name: "Hastings",
  },
  {
    postal_code: "83467",
    full_city_name: "Salmon, ID",
    city_name: "Salmon",
  },
  {
    postal_code: "13654",
    full_city_name: "Heuvelton, NY",
    city_name: "Heuvelton",
  },
  {
    postal_code: "61572",
    full_city_name: "Yates City, IL",
    city_name: "Yates City",
  },
  {
    postal_code: "22125",
    full_city_name: "Occoquan, VA",
    city_name: "Occoquan",
  },
  {
    postal_code: "15483",
    full_city_name: "Stockdale, PA",
    city_name: "Stockdale",
  },
  {
    postal_code: "48748",
    full_city_name: "National City, MI",
    city_name: "National City",
  },
  {
    postal_code: "02872",
    full_city_name: "Prudence Island, RI",
    city_name: "Prudence Island",
  },
  {
    postal_code: "52211",
    full_city_name: "Brooklyn, IA",
    city_name: "Brooklyn",
  },
  {
    postal_code: "73011",
    full_city_name: "Bradley, OK",
    city_name: "Bradley",
  },
  {
    postal_code: "85321",
    full_city_name: "Ajo, AZ",
    city_name: "Ajo",
  },
  {
    postal_code: "51556",
    full_city_name: "Modale, IA",
    city_name: "Modale",
  },
  {
    postal_code: "43066",
    full_city_name: "Radnor, OH",
    city_name: "Radnor",
  },
  {
    postal_code: "29692",
    full_city_name: "Ware Shoals, SC",
    city_name: "Ware Shoals",
  },
  {
    postal_code: "06710",
    full_city_name: "Waterbury, CT",
    city_name: "Waterbury",
  },
  {
    postal_code: "28905",
    full_city_name: "Marble, NC",
    city_name: "Marble",
  },
  {
    postal_code: "48157",
    full_city_name: "Luna Pier, MI",
    city_name: "Luna Pier",
  },
  {
    postal_code: "56017",
    full_city_name: "Cleveland, MN",
    city_name: "Cleveland",
  },
  {
    postal_code: "72083",
    full_city_name: "Keo, AR",
    city_name: "Keo",
  },
  {
    postal_code: "56277",
    full_city_name: "Olivia, MN",
    city_name: "Olivia",
  },
  {
    postal_code: "56661",
    full_city_name: "Northome, MN",
    city_name: "Northome",
  },
  {
    postal_code: "13159",
    full_city_name: "Tully, NY",
    city_name: "Tully",
  },
  {
    postal_code: "92091",
    full_city_name: "Rancho Santa Fe, CA",
    city_name: "Rancho Santa Fe",
  },
  {
    postal_code: "56630",
    full_city_name: "Blackduck, MN",
    city_name: "Blackduck",
  },
  {
    postal_code: "61535",
    full_city_name: "Groveland, IL",
    city_name: "Groveland",
  },
  {
    postal_code: "26568",
    full_city_name: "Enterprise, WV",
    city_name: "Enterprise",
  },
  {
    postal_code: "65567",
    full_city_name: "Stoutland, MO",
    city_name: "Stoutland",
  },
  {
    postal_code: "27568",
    full_city_name: "Pine Level, NC",
    city_name: "Pine Level",
  },
  {
    postal_code: "41339",
    full_city_name: "Jackson, KY",
    city_name: "Jackson",
  },
  {
    postal_code: "45390",
    full_city_name: "Union City, OH",
    city_name: "Union City",
  },
  {
    postal_code: "43758",
    full_city_name: "Malta, OH",
    city_name: "Malta",
  },
  {
    postal_code: "97738",
    full_city_name: "Hines, OR",
    city_name: "Hines",
  },
  {
    postal_code: "02120",
    full_city_name: "Roxbury Crossing, MA",
    city_name: "Roxbury Crossing",
  },
  {
    postal_code: "02838",
    full_city_name: "Manville, RI",
    city_name: "Manville",
  },
  {
    postal_code: "30417",
    full_city_name: "Claxton, GA",
    city_name: "Claxton",
  },
  {
    postal_code: "47037",
    full_city_name: "Osgood, IN",
    city_name: "Osgood",
  },
  {
    postal_code: "43413",
    full_city_name: "Cygnet, OH",
    city_name: "Cygnet",
  },
  {
    postal_code: "15482",
    full_city_name: "Star Junction, PA",
    city_name: "Star Junction",
  },
  {
    postal_code: "23004",
    full_city_name: "Arvonia, VA",
    city_name: "Arvonia",
  },
  {
    postal_code: "92067",
    full_city_name: "Rancho Santa Fe, CA",
    city_name: "Rancho Santa Fe",
  },
  {
    postal_code: "18072",
    full_city_name: "Pen Argyl, PA",
    city_name: "Pen Argyl",
  },
  {
    postal_code: "56101",
    full_city_name: "Windom, MN",
    city_name: "Windom",
  },
  {
    postal_code: "44662",
    full_city_name: "Navarre, OH",
    city_name: "Navarre",
  },
  {
    postal_code: "30565",
    full_city_name: "Nicholson, GA",
    city_name: "Nicholson",
  },
  {
    postal_code: "27315",
    full_city_name: "Providence, NC",
    city_name: "Providence",
  },
  {
    postal_code: "80653",
    full_city_name: "Weldona, CO",
    city_name: "Weldona",
  },
  {
    postal_code: "61319",
    full_city_name: "Cornell, IL",
    city_name: "Cornell",
  },
  {
    postal_code: "61878",
    full_city_name: "Thomasboro, IL",
    city_name: "Thomasboro",
  },
  {
    postal_code: "95442",
    full_city_name: "Glen Ellen, CA",
    city_name: "Glen Ellen",
  },
  {
    postal_code: "86329",
    full_city_name: "Humboldt, AZ",
    city_name: "Humboldt",
  },
  {
    postal_code: "48416",
    full_city_name: "Brown City, MI",
    city_name: "Brown City",
  },
  {
    postal_code: "35957",
    full_city_name: "Boaz, AL",
    city_name: "Boaz",
  },
  {
    postal_code: "12959",
    full_city_name: "Mooers Forks, NY",
    city_name: "Mooers Forks",
  },
  {
    postal_code: "22949",
    full_city_name: "Lovingston, VA",
    city_name: "Lovingston",
  },
  {
    postal_code: "12422",
    full_city_name: "Durham, NY",
    city_name: "Durham",
  },
  {
    postal_code: "14551",
    full_city_name: "Sodus, NY",
    city_name: "Sodus",
  },
  {
    postal_code: "47468",
    full_city_name: "Unionville, IN",
    city_name: "Unionville",
  },
  {
    postal_code: "39425",
    full_city_name: "Brooklyn, MS",
    city_name: "Brooklyn",
  },
  {
    postal_code: "49762",
    full_city_name: "Naubinway, MI",
    city_name: "Naubinway",
  },
  {
    postal_code: "48225",
    full_city_name: "Harper Woods, MI",
    city_name: "Harper Woods",
  },
  {
    postal_code: "62935",
    full_city_name: "Galatia, IL",
    city_name: "Galatia",
  },
  {
    postal_code: "62914",
    full_city_name: "Cairo, IL",
    city_name: "Cairo",
  },
  {
    postal_code: "55314",
    full_city_name: "Buffalo Lake, MN",
    city_name: "Buffalo Lake",
  },
  {
    postal_code: "80810",
    full_city_name: "Cheyenne Wells, CO",
    city_name: "Cheyenne Wells",
  },
  {
    postal_code: "12937",
    full_city_name: "Fort Covington, NY",
    city_name: "Fort Covington",
  },
  {
    postal_code: "88301",
    full_city_name: "Carrizozo, NM",
    city_name: "Carrizozo",
  },
  {
    postal_code: "78636",
    full_city_name: "Johnson City, TX",
    city_name: "Johnson City",
  },
  {
    postal_code: "17516",
    full_city_name: "Conestoga, PA",
    city_name: "Conestoga",
  },
  {
    postal_code: "97426",
    full_city_name: "Creswell, OR",
    city_name: "Creswell",
  },
  {
    postal_code: "44632",
    full_city_name: "Hartville, OH",
    city_name: "Hartville",
  },
  {
    postal_code: "62067",
    full_city_name: "Moro, IL",
    city_name: "Moro",
  },
  {
    postal_code: "15434",
    full_city_name: "Elco, PA",
    city_name: "Elco",
  },
  {
    postal_code: "30635",
    full_city_name: "Elberton, GA",
    city_name: "Elberton",
  },
  {
    postal_code: "29941",
    full_city_name: "Sheldon, SC",
    city_name: "Sheldon",
  },
  {
    postal_code: "99036",
    full_city_name: "Valleyford, WA",
    city_name: "Valleyford",
  },
  {
    postal_code: "62244",
    full_city_name: "Fults, IL",
    city_name: "Fults",
  },
  {
    postal_code: "78004",
    full_city_name: "Bergheim, TX",
    city_name: "Bergheim",
  },
  {
    postal_code: "63471",
    full_city_name: "Taylor, MO",
    city_name: "Taylor",
  },
  {
    postal_code: "38201",
    full_city_name: "Mc Kenzie, TN",
    city_name: "Mc Kenzie",
  },
  {
    postal_code: "77519",
    full_city_name: "Batson, TX",
    city_name: "Batson",
  },
  {
    postal_code: "97115",
    full_city_name: "Dundee, OR",
    city_name: "Dundee",
  },
  {
    postal_code: "48140",
    full_city_name: "Ida, MI",
    city_name: "Ida",
  },
  {
    postal_code: "08846",
    full_city_name: "Middlesex, NJ",
    city_name: "Middlesex",
  },
  {
    postal_code: "36401",
    full_city_name: "Evergreen, AL",
    city_name: "Evergreen",
  },
  {
    postal_code: "44275",
    full_city_name: "Spencer, OH",
    city_name: "Spencer",
  },
  {
    postal_code: "49950",
    full_city_name: "Mohawk, MI",
    city_name: "Mohawk",
  },
  {
    postal_code: "60963",
    full_city_name: "Rossville, IL",
    city_name: "Rossville",
  },
  {
    postal_code: "55971",
    full_city_name: "Rushford, MN",
    city_name: "Rushford",
  },
  {
    postal_code: "97423",
    full_city_name: "Coquille, OR",
    city_name: "Coquille",
  },
  {
    postal_code: "54927",
    full_city_name: "Butte Des Morts, WI",
    city_name: "Butte Des Morts",
  },
  {
    postal_code: "12185",
    full_city_name: "Valley Falls, NY",
    city_name: "Valley Falls",
  },
  {
    postal_code: "53181",
    full_city_name: "Twin Lakes, WI",
    city_name: "Twin Lakes",
  },
  {
    postal_code: "27855",
    full_city_name: "Murfreesboro, NC",
    city_name: "Murfreesboro",
  },
  {
    postal_code: "30258",
    full_city_name: "Molena, GA",
    city_name: "Molena",
  },
  {
    postal_code: "44847",
    full_city_name: "Monroeville, OH",
    city_name: "Monroeville",
  },
  {
    postal_code: "37362",
    full_city_name: "Old Fort, TN",
    city_name: "Old Fort",
  },
  {
    postal_code: "14816",
    full_city_name: "Breesport, NY",
    city_name: "Breesport",
  },
  {
    postal_code: "67056",
    full_city_name: "Halstead, KS",
    city_name: "Halstead",
  },
  {
    postal_code: "21662",
    full_city_name: "Royal Oak, MD",
    city_name: "Royal Oak",
  },
  {
    postal_code: "56354",
    full_city_name: "Miltona, MN",
    city_name: "Miltona",
  },
  {
    postal_code: "46406",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "54726",
    full_city_name: "Boyd, WI",
    city_name: "Boyd",
  },
  {
    postal_code: "75246",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "99031",
    full_city_name: "Spangle, WA",
    city_name: "Spangle",
  },
  {
    postal_code: "42378",
    full_city_name: "Whitesville, KY",
    city_name: "Whitesville",
  },
  {
    postal_code: "44003",
    full_city_name: "Andover, OH",
    city_name: "Andover",
  },
  {
    postal_code: "01541",
    full_city_name: "Princeton, MA",
    city_name: "Princeton",
  },
  {
    postal_code: "82721",
    full_city_name: "Moorcroft, WY",
    city_name: "Moorcroft",
  },
  {
    postal_code: "04974",
    full_city_name: "Searsport, ME",
    city_name: "Searsport",
  },
  {
    postal_code: "53042",
    full_city_name: "Kiel, WI",
    city_name: "Kiel",
  },
  {
    postal_code: "35150",
    full_city_name: "Sylacauga, AL",
    city_name: "Sylacauga",
  },
  {
    postal_code: "27979",
    full_city_name: "Sunbury, NC",
    city_name: "Sunbury",
  },
  {
    postal_code: "21634",
    full_city_name: "Fishing Creek, MD",
    city_name: "Fishing Creek",
  },
  {
    postal_code: "55795",
    full_city_name: "Willow River, MN",
    city_name: "Willow River",
  },
  {
    postal_code: "58725",
    full_city_name: "Carpio, ND",
    city_name: "Carpio",
  },
  {
    postal_code: "32008",
    full_city_name: "Branford, FL",
    city_name: "Branford",
  },
  {
    postal_code: "80828",
    full_city_name: "Limon, CO",
    city_name: "Limon",
  },
  {
    postal_code: "59761",
    full_city_name: "Wisdom, MT",
    city_name: "Wisdom",
  },
  {
    postal_code: "38568",
    full_city_name: "Hilham, TN",
    city_name: "Hilham",
  },
  {
    postal_code: "75684",
    full_city_name: "Overton, TX",
    city_name: "Overton",
  },
  {
    postal_code: "47353",
    full_city_name: "Liberty, IN",
    city_name: "Liberty",
  },
  {
    postal_code: "03242",
    full_city_name: "Henniker, NH",
    city_name: "Henniker",
  },
  {
    postal_code: "62237",
    full_city_name: "Coulterville, IL",
    city_name: "Coulterville",
  },
  {
    postal_code: "43610",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "48634",
    full_city_name: "Linwood, MI",
    city_name: "Linwood",
  },
  {
    postal_code: "02824",
    full_city_name: "Forestdale, RI",
    city_name: "Forestdale",
  },
  {
    postal_code: "21778",
    full_city_name: "Rocky Ridge, MD",
    city_name: "Rocky Ridge",
  },
  {
    postal_code: "94704",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "45612",
    full_city_name: "Bainbridge, OH",
    city_name: "Bainbridge",
  },
  {
    postal_code: "13361",
    full_city_name: "Jordanville, NY",
    city_name: "Jordanville",
  },
  {
    postal_code: "48094",
    full_city_name: "Washington, MI",
    city_name: "Washington",
  },
  {
    postal_code: "26038",
    full_city_name: "Glen Dale, WV",
    city_name: "Glen Dale",
  },
  {
    postal_code: "26032",
    full_city_name: "Bethany, WV",
    city_name: "Bethany",
  },
  {
    postal_code: "42436",
    full_city_name: "Manitou, KY",
    city_name: "Manitou",
  },
  {
    postal_code: "66052",
    full_city_name: "Linwood, KS",
    city_name: "Linwood",
  },
  {
    postal_code: "38332",
    full_city_name: "Enville, TN",
    city_name: "Enville",
  },
  {
    postal_code: "60911",
    full_city_name: "Ashkum, IL",
    city_name: "Ashkum",
  },
  {
    postal_code: "48092",
    full_city_name: "Warren, MI",
    city_name: "Warren",
  },
  {
    postal_code: "68371",
    full_city_name: "Henderson, NE",
    city_name: "Henderson",
  },
  {
    postal_code: "43727",
    full_city_name: "Chandlersville, OH",
    city_name: "Chandlersville",
  },
  {
    postal_code: "58380",
    full_city_name: "Tolna, ND",
    city_name: "Tolna",
  },
  {
    postal_code: "23921",
    full_city_name: "Buckingham, VA",
    city_name: "Buckingham",
  },
  {
    postal_code: "48441",
    full_city_name: "Harbor Beach, MI",
    city_name: "Harbor Beach",
  },
  {
    postal_code: "50029",
    full_city_name: "Bayard, IA",
    city_name: "Bayard",
  },
  {
    postal_code: "83115",
    full_city_name: "Daniel, WY",
    city_name: "Daniel",
  },
  {
    postal_code: "60511",
    full_city_name: "Big Rock, IL",
    city_name: "Big Rock",
  },
  {
    postal_code: "13606",
    full_city_name: "Adams Center, NY",
    city_name: "Adams Center",
  },
  {
    postal_code: "15055",
    full_city_name: "Lawrence, PA",
    city_name: "Lawrence",
  },
  {
    postal_code: "69162",
    full_city_name: "Sidney, NE",
    city_name: "Sidney",
  },
  {
    postal_code: "17084",
    full_city_name: "Reedsville, PA",
    city_name: "Reedsville",
  },
  {
    postal_code: "05477",
    full_city_name: "Richmond, VT",
    city_name: "Richmond",
  },
  {
    postal_code: "55052",
    full_city_name: "Morristown, MN",
    city_name: "Morristown",
  },
  {
    postal_code: "43333",
    full_city_name: "Lewistown, OH",
    city_name: "Lewistown",
  },
  {
    postal_code: "69356",
    full_city_name: "Minatare, NE",
    city_name: "Minatare",
  },
  {
    postal_code: "35176",
    full_city_name: "Vandiver, AL",
    city_name: "Vandiver",
  },
  {
    postal_code: "29556",
    full_city_name: "Kingstree, SC",
    city_name: "Kingstree",
  },
  {
    postal_code: "44436",
    full_city_name: "Lowellville, OH",
    city_name: "Lowellville",
  },
  {
    postal_code: "56552",
    full_city_name: "Hitterdal, MN",
    city_name: "Hitterdal",
  },
  {
    postal_code: "13813",
    full_city_name: "Nineveh, NY",
    city_name: "Nineveh",
  },
  {
    postal_code: "76651",
    full_city_name: "Italy, TX",
    city_name: "Italy",
  },
  {
    postal_code: "21710",
    full_city_name: "Adamstown, MD",
    city_name: "Adamstown",
  },
  {
    postal_code: "24137",
    full_city_name: "Penhook, VA",
    city_name: "Penhook",
  },
  {
    postal_code: "48461",
    full_city_name: "North Branch, MI",
    city_name: "North Branch",
  },
  {
    postal_code: "48737",
    full_city_name: "Glennie, MI",
    city_name: "Glennie",
  },
  {
    postal_code: "04474",
    full_city_name: "Orrington, ME",
    city_name: "Orrington",
  },
  {
    postal_code: "56081",
    full_city_name: "Saint James, MN",
    city_name: "Saint James",
  },
  {
    postal_code: "69145",
    full_city_name: "Kimball, NE",
    city_name: "Kimball",
  },
  {
    postal_code: "41844",
    full_city_name: "Pippa Passes, KY",
    city_name: "Pippa Passes",
  },
  {
    postal_code: "05142",
    full_city_name: "Cavendish, VT",
    city_name: "Cavendish",
  },
  {
    postal_code: "15031",
    full_city_name: "Cuddy, PA",
    city_name: "Cuddy",
  },
  {
    postal_code: "46102",
    full_city_name: "Advance, IN",
    city_name: "Advance",
  },
  {
    postal_code: "49106",
    full_city_name: "Bridgman, MI",
    city_name: "Bridgman",
  },
  {
    postal_code: "44064",
    full_city_name: "Montville, OH",
    city_name: "Montville",
  },
  {
    postal_code: "47963",
    full_city_name: "Morocco, IN",
    city_name: "Morocco",
  },
  {
    postal_code: "56326",
    full_city_name: "Evansville, MN",
    city_name: "Evansville",
  },
  {
    postal_code: "38588",
    full_city_name: "Whitleyville, TN",
    city_name: "Whitleyville",
  },
  {
    postal_code: "50861",
    full_city_name: "Shannon City, IA",
    city_name: "Shannon City",
  },
  {
    postal_code: "97903",
    full_city_name: "Brogan, OR",
    city_name: "Brogan",
  },
  {
    postal_code: "36752",
    full_city_name: "Lowndesboro, AL",
    city_name: "Lowndesboro",
  },
  {
    postal_code: "54762",
    full_city_name: "Prairie Farm, WI",
    city_name: "Prairie Farm",
  },
  {
    postal_code: "02468",
    full_city_name: "Waban, MA",
    city_name: "Waban",
  },
  {
    postal_code: "25878",
    full_city_name: "Midway, WV",
    city_name: "Midway",
  },
  {
    postal_code: "63102",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "65590",
    full_city_name: "Long Lane, MO",
    city_name: "Long Lane",
  },
  {
    postal_code: "20674",
    full_city_name: "Piney Point, MD",
    city_name: "Piney Point",
  },
  {
    postal_code: "82701",
    full_city_name: "Newcastle, WY",
    city_name: "Newcastle",
  },
  {
    postal_code: "03595",
    full_city_name: "Twin Mountain, NH",
    city_name: "Twin Mountain",
  },
  {
    postal_code: "65463",
    full_city_name: "Eldridge, MO",
    city_name: "Eldridge",
  },
  {
    postal_code: "61234",
    full_city_name: "Annawan, IL",
    city_name: "Annawan",
  },
  {
    postal_code: "23062",
    full_city_name: "Gloucester Point, VA",
    city_name: "Gloucester Point",
  },
  {
    postal_code: "25654",
    full_city_name: "Yolyn, WV",
    city_name: "Yolyn",
  },
  {
    postal_code: "33835",
    full_city_name: "Bradley, FL",
    city_name: "Bradley",
  },
  {
    postal_code: "44624",
    full_city_name: "Dundee, OH",
    city_name: "Dundee",
  },
  {
    postal_code: "97378",
    full_city_name: "Sheridan, OR",
    city_name: "Sheridan",
  },
  {
    postal_code: "82433",
    full_city_name: "Meeteetse, WY",
    city_name: "Meeteetse",
  },
  {
    postal_code: "80832",
    full_city_name: "Ramah, CO",
    city_name: "Ramah",
  },
  {
    postal_code: "16406",
    full_city_name: "Conneautville, PA",
    city_name: "Conneautville",
  },
  {
    postal_code: "72537",
    full_city_name: "Gamaliel, AR",
    city_name: "Gamaliel",
  },
  {
    postal_code: "37727",
    full_city_name: "Del Rio, TN",
    city_name: "Del Rio",
  },
  {
    postal_code: "48466",
    full_city_name: "Peck, MI",
    city_name: "Peck",
  },
  {
    postal_code: "70554",
    full_city_name: "Mamou, LA",
    city_name: "Mamou",
  },
  {
    postal_code: "93424",
    full_city_name: "Avila Beach, CA",
    city_name: "Avila Beach",
  },
  {
    postal_code: "38952",
    full_city_name: "Schlater, MS",
    city_name: "Schlater",
  },
  {
    postal_code: "72845",
    full_city_name: "Knoxville, AR",
    city_name: "Knoxville",
  },
  {
    postal_code: "32622",
    full_city_name: "Brooker, FL",
    city_name: "Brooker",
  },
  {
    postal_code: "62514",
    full_city_name: "Boody, IL",
    city_name: "Boody",
  },
  {
    postal_code: "76579",
    full_city_name: "Troy, TX",
    city_name: "Troy",
  },
  {
    postal_code: "66763",
    full_city_name: "Frontenac, KS",
    city_name: "Frontenac",
  },
  {
    postal_code: "45870",
    full_city_name: "New Hampshire, OH",
    city_name: "New Hampshire",
  },
  {
    postal_code: "26719",
    full_city_name: "Fort Ashby, WV",
    city_name: "Fort Ashby",
  },
  {
    postal_code: "39730",
    full_city_name: "Aberdeen, MS",
    city_name: "Aberdeen",
  },
  {
    postal_code: "63934",
    full_city_name: "Clubb, MO",
    city_name: "Clubb",
  },
  {
    postal_code: "16820",
    full_city_name: "Aaronsburg, PA",
    city_name: "Aaronsburg",
  },
  {
    postal_code: "07758",
    full_city_name: "Port Monmouth, NJ",
    city_name: "Port Monmouth",
  },
  {
    postal_code: "08353",
    full_city_name: "Shiloh, NJ",
    city_name: "Shiloh",
  },
  {
    postal_code: "55150",
    full_city_name: "Mendota, MN",
    city_name: "Mendota",
  },
  {
    postal_code: "02723",
    full_city_name: "Fall River, MA",
    city_name: "Fall River",
  },
  {
    postal_code: "38229",
    full_city_name: "Gleason, TN",
    city_name: "Gleason",
  },
  {
    postal_code: "32702",
    full_city_name: "Altoona, FL",
    city_name: "Altoona",
  },
  {
    postal_code: "29372",
    full_city_name: "Pacolet, SC",
    city_name: "Pacolet",
  },
  {
    postal_code: "52637",
    full_city_name: "Mediapolis, IA",
    city_name: "Mediapolis",
  },
  {
    postal_code: "46917",
    full_city_name: "Camden, IN",
    city_name: "Camden",
  },
  {
    postal_code: "17772",
    full_city_name: "Turbotville, PA",
    city_name: "Turbotville",
  },
  {
    postal_code: "05905",
    full_city_name: "Guildhall, VT",
    city_name: "Guildhall",
  },
  {
    postal_code: "64439",
    full_city_name: "Dearborn, MO",
    city_name: "Dearborn",
  },
  {
    postal_code: "45846",
    full_city_name: "Fort Recovery, OH",
    city_name: "Fort Recovery",
  },
  {
    postal_code: "78931",
    full_city_name: "Bleiblerville, TX",
    city_name: "Bleiblerville",
  },
  {
    postal_code: "85920",
    full_city_name: "Alpine, AZ",
    city_name: "Alpine",
  },
  {
    postal_code: "39773",
    full_city_name: "West Point, MS",
    city_name: "West Point",
  },
  {
    postal_code: "06068",
    full_city_name: "Salisbury, CT",
    city_name: "Salisbury",
  },
  {
    postal_code: "16262",
    full_city_name: "Worthington, PA",
    city_name: "Worthington",
  },
  {
    postal_code: "68376",
    full_city_name: "Humboldt, NE",
    city_name: "Humboldt",
  },
  {
    postal_code: "06120",
    full_city_name: "Hartford, CT",
    city_name: "Hartford",
  },
  {
    postal_code: "90704",
    full_city_name: "Avalon, CA",
    city_name: "Avalon",
  },
  {
    postal_code: "92003",
    full_city_name: "Bonsall, CA",
    city_name: "Bonsall",
  },
  {
    postal_code: "54723",
    full_city_name: "Bay City, WI",
    city_name: "Bay City",
  },
  {
    postal_code: "04681",
    full_city_name: "Stonington, ME",
    city_name: "Stonington",
  },
  {
    postal_code: "03575",
    full_city_name: "Bretton Woods, NH",
    city_name: "Bretton Woods",
  },
  {
    postal_code: "67505",
    full_city_name: "South Hutchinson, KS",
    city_name: "South Hutchinson",
  },
  {
    postal_code: "89049",
    full_city_name: "Tonopah, NV",
    city_name: "Tonopah",
  },
  {
    postal_code: "46835",
    full_city_name: "Fort Wayne, IN",
    city_name: "Fort Wayne",
  },
  {
    postal_code: "65689",
    full_city_name: "Cabool, MO",
    city_name: "Cabool",
  },
  {
    postal_code: "25922",
    full_city_name: "Spanishburg, WV",
    city_name: "Spanishburg",
  },
  {
    postal_code: "76691",
    full_city_name: "West, TX",
    city_name: "West",
  },
  {
    postal_code: "45862",
    full_city_name: "Mendon, OH",
    city_name: "Mendon",
  },
  {
    postal_code: "95645",
    full_city_name: "Knights Landing, CA",
    city_name: "Knights Landing",
  },
  {
    postal_code: "65682",
    full_city_name: "Lockwood, MO",
    city_name: "Lockwood",
  },
  {
    postal_code: "76453",
    full_city_name: "Gordon, TX",
    city_name: "Gordon",
  },
  {
    postal_code: "13797",
    full_city_name: "Lisle, NY",
    city_name: "Lisle",
  },
  {
    postal_code: "49112",
    full_city_name: "Edwardsburg, MI",
    city_name: "Edwardsburg",
  },
  {
    postal_code: "29059",
    full_city_name: "Holly Hill, SC",
    city_name: "Holly Hill",
  },
  {
    postal_code: "13165",
    full_city_name: "Waterloo, NY",
    city_name: "Waterloo",
  },
  {
    postal_code: "22733",
    full_city_name: "Rapidan, VA",
    city_name: "Rapidan",
  },
  {
    postal_code: "13780",
    full_city_name: "Guilford, NY",
    city_name: "Guilford",
  },
  {
    postal_code: "76890",
    full_city_name: "Zephyr, TX",
    city_name: "Zephyr",
  },
  {
    postal_code: "15868",
    full_city_name: "Weedville, PA",
    city_name: "Weedville",
  },
  {
    postal_code: "65322",
    full_city_name: "Blackwater, MO",
    city_name: "Blackwater",
  },
  {
    postal_code: "54217",
    full_city_name: "Luxemburg, WI",
    city_name: "Luxemburg",
  },
  {
    postal_code: "39702",
    full_city_name: "Columbus, MS",
    city_name: "Columbus",
  },
  {
    postal_code: "48145",
    full_city_name: "La Salle, MI",
    city_name: "La Salle",
  },
  {
    postal_code: "94709",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "75558",
    full_city_name: "Cookville, TX",
    city_name: "Cookville",
  },
  {
    postal_code: "41230",
    full_city_name: "Louisa, KY",
    city_name: "Louisa",
  },
  {
    postal_code: "78340",
    full_city_name: "Bayside, TX",
    city_name: "Bayside",
  },
  {
    postal_code: "46404",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "04971",
    full_city_name: "Saint Albans, ME",
    city_name: "Saint Albans",
  },
  {
    postal_code: "18850",
    full_city_name: "Ulster, PA",
    city_name: "Ulster",
  },
  {
    postal_code: "37843",
    full_city_name: "Parrottsville, TN",
    city_name: "Parrottsville",
  },
  {
    postal_code: "35078",
    full_city_name: "Harpersville, AL",
    city_name: "Harpersville",
  },
  {
    postal_code: "03082",
    full_city_name: "Lyndeborough, NH",
    city_name: "Lyndeborough",
  },
  {
    postal_code: "10567",
    full_city_name: "Cortlandt Manor, NY",
    city_name: "Cortlandt Manor",
  },
  {
    postal_code: "42728",
    full_city_name: "Columbia, KY",
    city_name: "Columbia",
  },
  {
    postal_code: "05872",
    full_city_name: "West Charleston, VT",
    city_name: "West Charleston",
  },
  {
    postal_code: "77624",
    full_city_name: "Hillister, TX",
    city_name: "Hillister",
  },
  {
    postal_code: "77412",
    full_city_name: "Altair, TX",
    city_name: "Altair",
  },
  {
    postal_code: "40142",
    full_city_name: "Guston, KY",
    city_name: "Guston",
  },
  {
    postal_code: "50137",
    full_city_name: "Killduff, IA",
    city_name: "Killduff",
  },
  {
    postal_code: "59421",
    full_city_name: "Cascade, MT",
    city_name: "Cascade",
  },
  {
    postal_code: "61818",
    full_city_name: "Cerro Gordo, IL",
    city_name: "Cerro Gordo",
  },
  {
    postal_code: "65604",
    full_city_name: "Ash Grove, MO",
    city_name: "Ash Grove",
  },
  {
    postal_code: "87724",
    full_city_name: "La Loma, NM",
    city_name: "La Loma",
  },
  {
    postal_code: "26060",
    full_city_name: "Valley Grove, WV",
    city_name: "Valley Grove",
  },
  {
    postal_code: "16435",
    full_city_name: "Springboro, PA",
    city_name: "Springboro",
  },
  {
    postal_code: "21155",
    full_city_name: "Upperco, MD",
    city_name: "Upperco",
  },
  {
    postal_code: "23930",
    full_city_name: "Crewe, VA",
    city_name: "Crewe",
  },
  {
    postal_code: "28609",
    full_city_name: "Catawba, NC",
    city_name: "Catawba",
  },
  {
    postal_code: "26404",
    full_city_name: "Meadowbrook, WV",
    city_name: "Meadowbrook",
  },
  {
    postal_code: "16323",
    full_city_name: "Franklin, PA",
    city_name: "Franklin",
  },
  {
    postal_code: "64644",
    full_city_name: "Hamilton, MO",
    city_name: "Hamilton",
  },
  {
    postal_code: "49835",
    full_city_name: "Garden, MI",
    city_name: "Garden",
  },
  {
    postal_code: "26623",
    full_city_name: "Frametown, WV",
    city_name: "Frametown",
  },
  {
    postal_code: "55798",
    full_city_name: "Wright, MN",
    city_name: "Wright",
  },
  {
    postal_code: "72401",
    full_city_name: "Jonesboro, AR",
    city_name: "Jonesboro",
  },
  {
    postal_code: "47456",
    full_city_name: "Quincy, IN",
    city_name: "Quincy",
  },
  {
    postal_code: "50428",
    full_city_name: "Clear Lake, IA",
    city_name: "Clear Lake",
  },
  {
    postal_code: "65787",
    full_city_name: "Roach, MO",
    city_name: "Roach",
  },
  {
    postal_code: "27053",
    full_city_name: "Westfield, NC",
    city_name: "Westfield",
  },
  {
    postal_code: "92305",
    full_city_name: "Angelus Oaks, CA",
    city_name: "Angelus Oaks",
  },
  {
    postal_code: "84652",
    full_city_name: "Redmond, UT",
    city_name: "Redmond",
  },
  {
    postal_code: "52572",
    full_city_name: "Moulton, IA",
    city_name: "Moulton",
  },
  {
    postal_code: "26036",
    full_city_name: "Dallas, WV",
    city_name: "Dallas",
  },
  {
    postal_code: "20763",
    full_city_name: "Savage, MD",
    city_name: "Savage",
  },
  {
    postal_code: "25053",
    full_city_name: "Danville, WV",
    city_name: "Danville",
  },
  {
    postal_code: "91330",
    full_city_name: "Northridge, CA",
    city_name: "Northridge",
  },
  {
    postal_code: "23661",
    full_city_name: "Hampton, VA",
    city_name: "Hampton",
  },
  {
    postal_code: "84316",
    full_city_name: "Howell, UT",
    city_name: "Howell",
  },
  {
    postal_code: "60109",
    full_city_name: "Burlington, IL",
    city_name: "Burlington",
  },
  {
    postal_code: "42274",
    full_city_name: "Rockfield, KY",
    city_name: "Rockfield",
  },
  {
    postal_code: "53128",
    full_city_name: "Genoa City, WI",
    city_name: "Genoa City",
  },
  {
    postal_code: "90742",
    full_city_name: "Sunset Beach, CA",
    city_name: "Sunset Beach",
  },
  {
    postal_code: "76636",
    full_city_name: "Covington, TX",
    city_name: "Covington",
  },
  {
    postal_code: "12790",
    full_city_name: "Wurtsboro, NY",
    city_name: "Wurtsboro",
  },
  {
    postal_code: "51363",
    full_city_name: "Superior, IA",
    city_name: "Superior",
  },
  {
    postal_code: "25214",
    full_city_name: "Winifrede, WV",
    city_name: "Winifrede",
  },
  {
    postal_code: "68866",
    full_city_name: "Pleasanton, NE",
    city_name: "Pleasanton",
  },
  {
    postal_code: "14011",
    full_city_name: "Attica, NY",
    city_name: "Attica",
  },
  {
    postal_code: "43554",
    full_city_name: "Pioneer, OH",
    city_name: "Pioneer",
  },
  {
    postal_code: "78024",
    full_city_name: "Hunt, TX",
    city_name: "Hunt",
  },
  {
    postal_code: "01351",
    full_city_name: "Montague, MA",
    city_name: "Montague",
  },
  {
    postal_code: "65632",
    full_city_name: "Conway, MO",
    city_name: "Conway",
  },
  {
    postal_code: "97374",
    full_city_name: "Scio, OR",
    city_name: "Scio",
  },
  {
    postal_code: "03455",
    full_city_name: "Marlborough, NH",
    city_name: "Marlborough",
  },
  {
    postal_code: "74039",
    full_city_name: "Kellyville, OK",
    city_name: "Kellyville",
  },
  {
    postal_code: "77538",
    full_city_name: "Devers, TX",
    city_name: "Devers",
  },
  {
    postal_code: "18833",
    full_city_name: "New Albany, PA",
    city_name: "New Albany",
  },
  {
    postal_code: "60921",
    full_city_name: "Chatsworth, IL",
    city_name: "Chatsworth",
  },
  {
    postal_code: "97403",
    full_city_name: "Eugene, OR",
    city_name: "Eugene",
  },
  {
    postal_code: "18451",
    full_city_name: "Paupack, PA",
    city_name: "Paupack",
  },
  {
    postal_code: "48610",
    full_city_name: "Alger, MI",
    city_name: "Alger",
  },
  {
    postal_code: "18092",
    full_city_name: "Zionsville, PA",
    city_name: "Zionsville",
  },
  {
    postal_code: "31537",
    full_city_name: "Folkston, GA",
    city_name: "Folkston",
  },
  {
    postal_code: "24088",
    full_city_name: "Ferrum, VA",
    city_name: "Ferrum",
  },
  {
    postal_code: "43845",
    full_city_name: "West Lafayette, OH",
    city_name: "West Lafayette",
  },
  {
    postal_code: "42746",
    full_city_name: "Hardyville, KY",
    city_name: "Hardyville",
  },
  {
    postal_code: "64722",
    full_city_name: "Amoret, MO",
    city_name: "Amoret",
  },
  {
    postal_code: "38315",
    full_city_name: "Bethel Springs, TN",
    city_name: "Bethel Springs",
  },
  {
    postal_code: "61075",
    full_city_name: "Scales Mound, IL",
    city_name: "Scales Mound",
  },
  {
    postal_code: "06256",
    full_city_name: "North Windham, CT",
    city_name: "North Windham",
  },
  {
    postal_code: "61774",
    full_city_name: "Stanford, IL",
    city_name: "Stanford",
  },
  {
    postal_code: "16876",
    full_city_name: "Wallaceton, PA",
    city_name: "Wallaceton",
  },
  {
    postal_code: "54101",
    full_city_name: "Abrams, WI",
    city_name: "Abrams",
  },
  {
    postal_code: "48362",
    full_city_name: "Lake Orion, MI",
    city_name: "Lake Orion",
  },
  {
    postal_code: "92116",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "26149",
    full_city_name: "Middlebourne, WV",
    city_name: "Middlebourne",
  },
  {
    postal_code: "94514",
    full_city_name: "Byron, CA",
    city_name: "Byron",
  },
  {
    postal_code: "45873",
    full_city_name: "Oakwood, OH",
    city_name: "Oakwood",
  },
  {
    postal_code: "14619",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "68935",
    full_city_name: "Edgar, NE",
    city_name: "Edgar",
  },
  {
    postal_code: "48604",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "97837",
    full_city_name: "Hereford, OR",
    city_name: "Hereford",
  },
  {
    postal_code: "38067",
    full_city_name: "Saulsbury, TN",
    city_name: "Saulsbury",
  },
  {
    postal_code: "04736",
    full_city_name: "Caribou, ME",
    city_name: "Caribou",
  },
  {
    postal_code: "12035",
    full_city_name: "Central Bridge, NY",
    city_name: "Central Bridge",
  },
  {
    postal_code: "02461",
    full_city_name: "Newton Highlands, MA",
    city_name: "Newton Highlands",
  },
  {
    postal_code: "85173",
    full_city_name: "Superior, AZ",
    city_name: "Superior",
  },
  {
    postal_code: "30545",
    full_city_name: "Helen, GA",
    city_name: "Helen",
  },
  {
    postal_code: "04640",
    full_city_name: "Hancock, ME",
    city_name: "Hancock",
  },
  {
    postal_code: "98932",
    full_city_name: "Granger, WA",
    city_name: "Granger",
  },
  {
    postal_code: "80427",
    full_city_name: "Central City, CO",
    city_name: "Central City",
  },
  {
    postal_code: "21635",
    full_city_name: "Galena, MD",
    city_name: "Galena",
  },
  {
    postal_code: "04918",
    full_city_name: "Belgrade Lakes, ME",
    city_name: "Belgrade Lakes",
  },
  {
    postal_code: "43773",
    full_city_name: "Quaker City, OH",
    city_name: "Quaker City",
  },
  {
    postal_code: "26143",
    full_city_name: "Elizabeth, WV",
    city_name: "Elizabeth",
  },
  {
    postal_code: "68443",
    full_city_name: "Sterling, NE",
    city_name: "Sterling",
  },
  {
    postal_code: "62454",
    full_city_name: "Robinson, IL",
    city_name: "Robinson",
  },
  {
    postal_code: "02739",
    full_city_name: "Mattapoisett, MA",
    city_name: "Mattapoisett",
  },
  {
    postal_code: "35233",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "66056",
    full_city_name: "Mound City, KS",
    city_name: "Mound City",
  },
  {
    postal_code: "77872",
    full_city_name: "North Zulch, TX",
    city_name: "North Zulch",
  },
  {
    postal_code: "54495",
    full_city_name: "Wisconsin Rapids, WI",
    city_name: "Wisconsin Rapids",
  },
  {
    postal_code: "48857",
    full_city_name: "Morrice, MI",
    city_name: "Morrice",
  },
  {
    postal_code: "13608",
    full_city_name: "Antwerp, NY",
    city_name: "Antwerp",
  },
  {
    postal_code: "62617",
    full_city_name: "Bath, IL",
    city_name: "Bath",
  },
  {
    postal_code: "50864",
    full_city_name: "Villisca, IA",
    city_name: "Villisca",
  },
  {
    postal_code: "76687",
    full_city_name: "Thornton, TX",
    city_name: "Thornton",
  },
  {
    postal_code: "48154",
    full_city_name: "Livonia, MI",
    city_name: "Livonia",
  },
  {
    postal_code: "46182",
    full_city_name: "Waldron, IN",
    city_name: "Waldron",
  },
  {
    postal_code: "46748",
    full_city_name: "Huntertown, IN",
    city_name: "Huntertown",
  },
  {
    postal_code: "48855",
    full_city_name: "Howell, MI",
    city_name: "Howell",
  },
  {
    postal_code: "79553",
    full_city_name: "Stamford, TX",
    city_name: "Stamford",
  },
  {
    postal_code: "24534",
    full_city_name: "Clover, VA",
    city_name: "Clover",
  },
  {
    postal_code: "70395",
    full_city_name: "Schriever, LA",
    city_name: "Schriever",
  },
  {
    postal_code: "67428",
    full_city_name: "Canton, KS",
    city_name: "Canton",
  },
  {
    postal_code: "48451",
    full_city_name: "Linden, MI",
    city_name: "Linden",
  },
  {
    postal_code: "60918",
    full_city_name: "Buckley, IL",
    city_name: "Buckley",
  },
  {
    postal_code: "97734",
    full_city_name: "Culver, OR",
    city_name: "Culver",
  },
  {
    postal_code: "38233",
    full_city_name: "Kenton, TN",
    city_name: "Kenton",
  },
  {
    postal_code: "56281",
    full_city_name: "Prinsburg, MN",
    city_name: "Prinsburg",
  },
  {
    postal_code: "27916",
    full_city_name: "Aydlett, NC",
    city_name: "Aydlett",
  },
  {
    postal_code: "47236",
    full_city_name: "Grammer, IN",
    city_name: "Grammer",
  },
  {
    postal_code: "54513",
    full_city_name: "Brantwood, WI",
    city_name: "Brantwood",
  },
  {
    postal_code: "81424",
    full_city_name: "Nucla, CO",
    city_name: "Nucla",
  },
  {
    postal_code: "59247",
    full_city_name: "Medicine Lake, MT",
    city_name: "Medicine Lake",
  },
  {
    postal_code: "79511",
    full_city_name: "Coahoma, TX",
    city_name: "Coahoma",
  },
  {
    postal_code: "63637",
    full_city_name: "Doe Run, MO",
    city_name: "Doe Run",
  },
  {
    postal_code: "13832",
    full_city_name: "Plymouth, NY",
    city_name: "Plymouth",
  },
  {
    postal_code: "54511",
    full_city_name: "Argonne, WI",
    city_name: "Argonne",
  },
  {
    postal_code: "41314",
    full_city_name: "Booneville, KY",
    city_name: "Booneville",
  },
  {
    postal_code: "05738",
    full_city_name: "Cuttingsville, VT",
    city_name: "Cuttingsville",
  },
  {
    postal_code: "06420",
    full_city_name: "Salem, CT",
    city_name: "Salem",
  },
  {
    postal_code: "54921",
    full_city_name: "Bancroft, WI",
    city_name: "Bancroft",
  },
  {
    postal_code: "43340",
    full_city_name: "Mount Victory, OH",
    city_name: "Mount Victory",
  },
  {
    postal_code: "41819",
    full_city_name: "Gordon, KY",
    city_name: "Gordon",
  },
  {
    postal_code: "41621",
    full_city_name: "Dwale, KY",
    city_name: "Dwale",
  },
  {
    postal_code: "75479",
    full_city_name: "Savoy, TX",
    city_name: "Savoy",
  },
  {
    postal_code: "68852",
    full_city_name: "Litchfield, NE",
    city_name: "Litchfield",
  },
  {
    postal_code: "50559",
    full_city_name: "Lone Rock, IA",
    city_name: "Lone Rock",
  },
  {
    postal_code: "43070",
    full_city_name: "Rosewood, OH",
    city_name: "Rosewood",
  },
  {
    postal_code: "96134",
    full_city_name: "Tulelake, CA",
    city_name: "Tulelake",
  },
  {
    postal_code: "84006",
    full_city_name: "Bingham Canyon, UT",
    city_name: "Bingham Canyon",
  },
  {
    postal_code: "65486",
    full_city_name: "Iberia, MO",
    city_name: "Iberia",
  },
  {
    postal_code: "41043",
    full_city_name: "Foster, KY",
    city_name: "Foster",
  },
  {
    postal_code: "67650",
    full_city_name: "Morland, KS",
    city_name: "Morland",
  },
  {
    postal_code: "59926",
    full_city_name: "Martin City, MT",
    city_name: "Martin City",
  },
  {
    postal_code: "63015",
    full_city_name: "Catawissa, MO",
    city_name: "Catawissa",
  },
  {
    postal_code: "75449",
    full_city_name: "Ladonia, TX",
    city_name: "Ladonia",
  },
  {
    postal_code: "43933",
    full_city_name: "Jacobsburg, OH",
    city_name: "Jacobsburg",
  },
  {
    postal_code: "38425",
    full_city_name: "Clifton, TN",
    city_name: "Clifton",
  },
  {
    postal_code: "51465",
    full_city_name: "Vail, IA",
    city_name: "Vail",
  },
  {
    postal_code: "15655",
    full_city_name: "Laughlintown, PA",
    city_name: "Laughlintown",
  },
  {
    postal_code: "19453",
    full_city_name: "Mont Clare, PA",
    city_name: "Mont Clare",
  },
  {
    postal_code: "67761",
    full_city_name: "Wallace, KS",
    city_name: "Wallace",
  },
  {
    postal_code: "55787",
    full_city_name: "Tamarack, MN",
    city_name: "Tamarack",
  },
  {
    postal_code: "71944",
    full_city_name: "Grannis, AR",
    city_name: "Grannis",
  },
  {
    postal_code: "93250",
    full_city_name: "Mc Farland, CA",
    city_name: "Mc Farland",
  },
  {
    postal_code: "56176",
    full_city_name: "Trimont, MN",
    city_name: "Trimont",
  },
  {
    postal_code: "03841",
    full_city_name: "Hampstead, NH",
    city_name: "Hampstead",
  },
  {
    postal_code: "47924",
    full_city_name: "Buck Creek, IN",
    city_name: "Buck Creek",
  },
  {
    postal_code: "55954",
    full_city_name: "Mabel, MN",
    city_name: "Mabel",
  },
  {
    postal_code: "23435",
    full_city_name: "Suffolk, VA",
    city_name: "Suffolk",
  },
  {
    postal_code: "44695",
    full_city_name: "Bowerston, OH",
    city_name: "Bowerston",
  },
  {
    postal_code: "80812",
    full_city_name: "Cope, CO",
    city_name: "Cope",
  },
  {
    postal_code: "43067",
    full_city_name: "Raymond, OH",
    city_name: "Raymond",
  },
  {
    postal_code: "15696",
    full_city_name: "Youngstown, PA",
    city_name: "Youngstown",
  },
  {
    postal_code: "72045",
    full_city_name: "El Paso, AR",
    city_name: "El Paso",
  },
  {
    postal_code: "93040",
    full_city_name: "Piru, CA",
    city_name: "Piru",
  },
  {
    postal_code: "49806",
    full_city_name: "Au Train, MI",
    city_name: "Au Train",
  },
  {
    postal_code: "03781",
    full_city_name: "Plainfield, NH",
    city_name: "Plainfield",
  },
  {
    postal_code: "21733",
    full_city_name: "Fairplay, MD",
    city_name: "Fairplay",
  },
  {
    postal_code: "29944",
    full_city_name: "Varnville, SC",
    city_name: "Varnville",
  },
  {
    postal_code: "63655",
    full_city_name: "Marquand, MO",
    city_name: "Marquand",
  },
  {
    postal_code: "06750",
    full_city_name: "Bantam, CT",
    city_name: "Bantam",
  },
  {
    postal_code: "15436",
    full_city_name: "Fairchance, PA",
    city_name: "Fairchance",
  },
  {
    postal_code: "56262",
    full_city_name: "Milan, MN",
    city_name: "Milan",
  },
  {
    postal_code: "71765",
    full_city_name: "Strong, AR",
    city_name: "Strong",
  },
  {
    postal_code: "01258",
    full_city_name: "South Egremont, MA",
    city_name: "South Egremont",
  },
  {
    postal_code: "13328",
    full_city_name: "Deansboro, NY",
    city_name: "Deansboro",
  },
  {
    postal_code: "14028",
    full_city_name: "Burt, NY",
    city_name: "Burt",
  },
  {
    postal_code: "33854",
    full_city_name: "Lakeshore, FL",
    city_name: "Lakeshore",
  },
  {
    postal_code: "45898",
    full_city_name: "Willshire, OH",
    city_name: "Willshire",
  },
  {
    postal_code: "01834",
    full_city_name: "Groveland, MA",
    city_name: "Groveland",
  },
  {
    postal_code: "24271",
    full_city_name: "Nickelsville, VA",
    city_name: "Nickelsville",
  },
  {
    postal_code: "29436",
    full_city_name: "Cross, SC",
    city_name: "Cross",
  },
  {
    postal_code: "59420",
    full_city_name: "Carter, MT",
    city_name: "Carter",
  },
  {
    postal_code: "61483",
    full_city_name: "Toulon, IL",
    city_name: "Toulon",
  },
  {
    postal_code: "61846",
    full_city_name: "Georgetown, IL",
    city_name: "Georgetown",
  },
  {
    postal_code: "92281",
    full_city_name: "Westmorland, CA",
    city_name: "Westmorland",
  },
  {
    postal_code: "41604",
    full_city_name: "Beaver, KY",
    city_name: "Beaver",
  },
  {
    postal_code: "67416",
    full_city_name: "Assaria, KS",
    city_name: "Assaria",
  },
  {
    postal_code: "50049",
    full_city_name: "Chariton, IA",
    city_name: "Chariton",
  },
  {
    postal_code: "51445",
    full_city_name: "Ida Grove, IA",
    city_name: "Ida Grove",
  },
  {
    postal_code: "51449",
    full_city_name: "Lake City, IA",
    city_name: "Lake City",
  },
  {
    postal_code: "64863",
    full_city_name: "South West City, MO",
    city_name: "South West City",
  },
  {
    postal_code: "80747",
    full_city_name: "Peetz, CO",
    city_name: "Peetz",
  },
  {
    postal_code: "59014",
    full_city_name: "Bridger, MT",
    city_name: "Bridger",
  },
  {
    postal_code: "54895",
    full_city_name: "Weyerhaeuser, WI",
    city_name: "Weyerhaeuser",
  },
  {
    postal_code: "56068",
    full_city_name: "Minnesota Lake, MN",
    city_name: "Minnesota Lake",
  },
  {
    postal_code: "05471",
    full_city_name: "Montgomery Center, VT",
    city_name: "Montgomery Center",
  },
  {
    postal_code: "15678",
    full_city_name: "Rillton, PA",
    city_name: "Rillton",
  },
  {
    postal_code: "27325",
    full_city_name: "Robbins, NC",
    city_name: "Robbins",
  },
  {
    postal_code: "42326",
    full_city_name: "Browder, KY",
    city_name: "Browder",
  },
  {
    postal_code: "70071",
    full_city_name: "Lutcher, LA",
    city_name: "Lutcher",
  },
  {
    postal_code: "33851",
    full_city_name: "Lake Hamilton, FL",
    city_name: "Lake Hamilton",
  },
  {
    postal_code: "45318",
    full_city_name: "Covington, OH",
    city_name: "Covington",
  },
  {
    postal_code: "54417",
    full_city_name: "Brokaw, WI",
    city_name: "Brokaw",
  },
  {
    postal_code: "43767",
    full_city_name: "Norwich, OH",
    city_name: "Norwich",
  },
  {
    postal_code: "97376",
    full_city_name: "Seal Rock, OR",
    city_name: "Seal Rock",
  },
  {
    postal_code: "95232",
    full_city_name: "Glencoe, CA",
    city_name: "Glencoe",
  },
  {
    postal_code: "02717",
    full_city_name: "East Freetown, MA",
    city_name: "East Freetown",
  },
  {
    postal_code: "61349",
    full_city_name: "Ohio, IL",
    city_name: "Ohio",
  },
  {
    postal_code: "01005",
    full_city_name: "Barre, MA",
    city_name: "Barre",
  },
  {
    postal_code: "83522",
    full_city_name: "Cottonwood, ID",
    city_name: "Cottonwood",
  },
  {
    postal_code: "47968",
    full_city_name: "New Ross, IN",
    city_name: "New Ross",
  },
  {
    postal_code: "84301",
    full_city_name: "Bear River City, UT",
    city_name: "Bear River City",
  },
  {
    postal_code: "48469",
    full_city_name: "Port Sanilac, MI",
    city_name: "Port Sanilac",
  },
  {
    postal_code: "62216",
    full_city_name: "Aviston, IL",
    city_name: "Aviston",
  },
  {
    postal_code: "17867",
    full_city_name: "Rebuck, PA",
    city_name: "Rebuck",
  },
  {
    postal_code: "43750",
    full_city_name: "Kipling, OH",
    city_name: "Kipling",
  },
  {
    postal_code: "62692",
    full_city_name: "Waverly, IL",
    city_name: "Waverly",
  },
  {
    postal_code: "55026",
    full_city_name: "Frontenac, MN",
    city_name: "Frontenac",
  },
  {
    postal_code: "77046",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "13638",
    full_city_name: "Felts Mills, NY",
    city_name: "Felts Mills",
  },
  {
    postal_code: "95941",
    full_city_name: "Forbestown, CA",
    city_name: "Forbestown",
  },
  {
    postal_code: "67485",
    full_city_name: "Tipton, KS",
    city_name: "Tipton",
  },
  {
    postal_code: "54170",
    full_city_name: "Shiocton, WI",
    city_name: "Shiocton",
  },
  {
    postal_code: "45174",
    full_city_name: "Terrace Park, OH",
    city_name: "Terrace Park",
  },
  {
    postal_code: "95915",
    full_city_name: "Belden, CA",
    city_name: "Belden",
  },
  {
    postal_code: "57213",
    full_city_name: "Astoria, SD",
    city_name: "Astoria",
  },
  {
    postal_code: "02035",
    full_city_name: "Foxboro, MA",
    city_name: "Foxboro",
  },
  {
    postal_code: "16749",
    full_city_name: "Smethport, PA",
    city_name: "Smethport",
  },
  {
    postal_code: "82426",
    full_city_name: "Greybull, WY",
    city_name: "Greybull",
  },
  {
    postal_code: "12412",
    full_city_name: "Boiceville, NY",
    city_name: "Boiceville",
  },
  {
    postal_code: "49885",
    full_city_name: "Skandia, MI",
    city_name: "Skandia",
  },
  {
    postal_code: "69132",
    full_city_name: "Dickens, NE",
    city_name: "Dickens",
  },
  {
    postal_code: "43407",
    full_city_name: "Burgoon, OH",
    city_name: "Burgoon",
  },
  {
    postal_code: "48742",
    full_city_name: "Lincoln, MI",
    city_name: "Lincoln",
  },
  {
    postal_code: "49922",
    full_city_name: "Dollar Bay, MI",
    city_name: "Dollar Bay",
  },
  {
    postal_code: "60530",
    full_city_name: "Lee, IL",
    city_name: "Lee",
  },
  {
    postal_code: "78832",
    full_city_name: "Brackettville, TX",
    city_name: "Brackettville",
  },
  {
    postal_code: "55703",
    full_city_name: "Angora, MN",
    city_name: "Angora",
  },
  {
    postal_code: "28453",
    full_city_name: "Magnolia, NC",
    city_name: "Magnolia",
  },
  {
    postal_code: "69022",
    full_city_name: "Cambridge, NE",
    city_name: "Cambridge",
  },
  {
    postal_code: "05404",
    full_city_name: "Winooski, VT",
    city_name: "Winooski",
  },
  {
    postal_code: "33514",
    full_city_name: "Center Hill, FL",
    city_name: "Center Hill",
  },
  {
    postal_code: "52758",
    full_city_name: "Mc Causland, IA",
    city_name: "Mc Causland",
  },
  {
    postal_code: "26817",
    full_city_name: "Bloomery, WV",
    city_name: "Bloomery",
  },
  {
    postal_code: "49284",
    full_city_name: "Springport, MI",
    city_name: "Springport",
  },
  {
    postal_code: "18616",
    full_city_name: "Forksville, PA",
    city_name: "Forksville",
  },
  {
    postal_code: "98555",
    full_city_name: "Lilliwaup, WA",
    city_name: "Lilliwaup",
  },
  {
    postal_code: "50147",
    full_city_name: "Lineville, IA",
    city_name: "Lineville",
  },
  {
    postal_code: "74061",
    full_city_name: "Ramona, OK",
    city_name: "Ramona",
  },
  {
    postal_code: "76653",
    full_city_name: "Kosse, TX",
    city_name: "Kosse",
  },
  {
    postal_code: "43549",
    full_city_name: "Ney, OH",
    city_name: "Ney",
  },
  {
    postal_code: "44817",
    full_city_name: "Bloomdale, OH",
    city_name: "Bloomdale",
  },
  {
    postal_code: "43442",
    full_city_name: "Lindsey, OH",
    city_name: "Lindsey",
  },
  {
    postal_code: "67584",
    full_city_name: "Utica, KS",
    city_name: "Utica",
  },
  {
    postal_code: "44214",
    full_city_name: "Burbank, OH",
    city_name: "Burbank",
  },
  {
    postal_code: "76566",
    full_city_name: "Purmela, TX",
    city_name: "Purmela",
  },
  {
    postal_code: "81251",
    full_city_name: "Twin Lakes, CO",
    city_name: "Twin Lakes",
  },
  {
    postal_code: "16847",
    full_city_name: "Kylertown, PA",
    city_name: "Kylertown",
  },
  {
    postal_code: "53930",
    full_city_name: "Endeavor, WI",
    city_name: "Endeavor",
  },
  {
    postal_code: "23894",
    full_city_name: "Wilsons, VA",
    city_name: "Wilsons",
  },
  {
    postal_code: "95681",
    full_city_name: "Sheridan, CA",
    city_name: "Sheridan",
  },
  {
    postal_code: "69334",
    full_city_name: "Bayard, NE",
    city_name: "Bayard",
  },
  {
    postal_code: "73945",
    full_city_name: "Hooker, OK",
    city_name: "Hooker",
  },
  {
    postal_code: "04357",
    full_city_name: "Richmond, ME",
    city_name: "Richmond",
  },
  {
    postal_code: "43527",
    full_city_name: "Holgate, OH",
    city_name: "Holgate",
  },
  {
    postal_code: "61740",
    full_city_name: "Flanagan, IL",
    city_name: "Flanagan",
  },
  {
    postal_code: "62833",
    full_city_name: "Ellery, IL",
    city_name: "Ellery",
  },
  {
    postal_code: "12525",
    full_city_name: "Gardiner, NY",
    city_name: "Gardiner",
  },
  {
    postal_code: "28525",
    full_city_name: "Deep Run, NC",
    city_name: "Deep Run",
  },
  {
    postal_code: "92655",
    full_city_name: "Midway City, CA",
    city_name: "Midway City",
  },
  {
    postal_code: "83452",
    full_city_name: "Tetonia, ID",
    city_name: "Tetonia",
  },
  {
    postal_code: "15076",
    full_city_name: "Russellton, PA",
    city_name: "Russellton",
  },
  {
    postal_code: "75489",
    full_city_name: "Tom Bean, TX",
    city_name: "Tom Bean",
  },
  {
    postal_code: "15346",
    full_city_name: "Mather, PA",
    city_name: "Mather",
  },
  {
    postal_code: "50521",
    full_city_name: "Burnside, IA",
    city_name: "Burnside",
  },
  {
    postal_code: "56120",
    full_city_name: "Butterfield, MN",
    city_name: "Butterfield",
  },
  {
    postal_code: "28383",
    full_city_name: "Rowland, NC",
    city_name: "Rowland",
  },
  {
    postal_code: "66035",
    full_city_name: "Highland, KS",
    city_name: "Highland",
  },
  {
    postal_code: "79359",
    full_city_name: "Seagraves, TX",
    city_name: "Seagraves",
  },
  {
    postal_code: "54768",
    full_city_name: "Stanley, WI",
    city_name: "Stanley",
  },
  {
    postal_code: "56131",
    full_city_name: "Fulda, MN",
    city_name: "Fulda",
  },
  {
    postal_code: "13755",
    full_city_name: "Downsville, NY",
    city_name: "Downsville",
  },
  {
    postal_code: "63384",
    full_city_name: "Wellsville, MO",
    city_name: "Wellsville",
  },
  {
    postal_code: "50117",
    full_city_name: "Hamlin, IA",
    city_name: "Hamlin",
  },
  {
    postal_code: "16404",
    full_city_name: "Centerville, PA",
    city_name: "Centerville",
  },
  {
    postal_code: "50173",
    full_city_name: "Montour, IA",
    city_name: "Montour",
  },
  {
    postal_code: "84516",
    full_city_name: "Clawson, UT",
    city_name: "Clawson",
  },
  {
    postal_code: "03845",
    full_city_name: "Intervale, NH",
    city_name: "Intervale",
  },
  {
    postal_code: "95668",
    full_city_name: "Pleasant Grove, CA",
    city_name: "Pleasant Grove",
  },
  {
    postal_code: "61438",
    full_city_name: "Good Hope, IL",
    city_name: "Good Hope",
  },
  {
    postal_code: "62920",
    full_city_name: "Cobden, IL",
    city_name: "Cobden",
  },
  {
    postal_code: "95257",
    full_city_name: "Wilseyville, CA",
    city_name: "Wilseyville",
  },
  {
    postal_code: "61372",
    full_city_name: "Troy Grove, IL",
    city_name: "Troy Grove",
  },
  {
    postal_code: "87580",
    full_city_name: "Valdez, NM",
    city_name: "Valdez",
  },
  {
    postal_code: "16160",
    full_city_name: "West Pittsburg, PA",
    city_name: "West Pittsburg",
  },
  {
    postal_code: "76270",
    full_city_name: "Sunset, TX",
    city_name: "Sunset",
  },
  {
    postal_code: "46407",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "03071",
    full_city_name: "New Ipswich, NH",
    city_name: "New Ipswich",
  },
  {
    postal_code: "47958",
    full_city_name: "Mellott, IN",
    city_name: "Mellott",
  },
  {
    postal_code: "02743",
    full_city_name: "Acushnet, MA",
    city_name: "Acushnet",
  },
  {
    postal_code: "29682",
    full_city_name: "Six Mile, SC",
    city_name: "Six Mile",
  },
  {
    postal_code: "43324",
    full_city_name: "Huntsville, OH",
    city_name: "Huntsville",
  },
  {
    postal_code: "06058",
    full_city_name: "Norfolk, CT",
    city_name: "Norfolk",
  },
  {
    postal_code: "79031",
    full_city_name: "Earth, TX",
    city_name: "Earth",
  },
  {
    postal_code: "12981",
    full_city_name: "Saranac, NY",
    city_name: "Saranac",
  },
  {
    postal_code: "16929",
    full_city_name: "Lawrenceville, PA",
    city_name: "Lawrenceville",
  },
  {
    postal_code: "61942",
    full_city_name: "Newman, IL",
    city_name: "Newman",
  },
  {
    postal_code: "78838",
    full_city_name: "Concan, TX",
    city_name: "Concan",
  },
  {
    postal_code: "19492",
    full_city_name: "Zieglerville, PA",
    city_name: "Zieglerville",
  },
  {
    postal_code: "62889",
    full_city_name: "Texico, IL",
    city_name: "Texico",
  },
  {
    postal_code: "61418",
    full_city_name: "Biggsville, IL",
    city_name: "Biggsville",
  },
  {
    postal_code: "15770",
    full_city_name: "Ringgold, PA",
    city_name: "Ringgold",
  },
  {
    postal_code: "67651",
    full_city_name: "Natoma, KS",
    city_name: "Natoma",
  },
  {
    postal_code: "50535",
    full_city_name: "Early, IA",
    city_name: "Early",
  },
  {
    postal_code: "50591",
    full_city_name: "Thor, IA",
    city_name: "Thor",
  },
  {
    postal_code: "96763",
    full_city_name: "Lanai City, HI",
    city_name: "Lanai City",
  },
  {
    postal_code: "52585",
    full_city_name: "Richland, IA",
    city_name: "Richland",
  },
  {
    postal_code: "50143",
    full_city_name: "Leighton, IA",
    city_name: "Leighton",
  },
  {
    postal_code: "40007",
    full_city_name: "Bethlehem, KY",
    city_name: "Bethlehem",
  },
  {
    postal_code: "38328",
    full_city_name: "Darden, TN",
    city_name: "Darden",
  },
  {
    postal_code: "48861",
    full_city_name: "Mulliken, MI",
    city_name: "Mulliken",
  },
  {
    postal_code: "79520",
    full_city_name: "Hamlin, TX",
    city_name: "Hamlin",
  },
  {
    postal_code: "39066",
    full_city_name: "Edwards, MS",
    city_name: "Edwards",
  },
  {
    postal_code: "76351",
    full_city_name: "Archer City, TX",
    city_name: "Archer City",
  },
  {
    postal_code: "60919",
    full_city_name: "Cabery, IL",
    city_name: "Cabery",
  },
  {
    postal_code: "44697",
    full_city_name: "Zoar, OH",
    city_name: "Zoar",
  },
  {
    postal_code: "56252",
    full_city_name: "Kerkhoven, MN",
    city_name: "Kerkhoven",
  },
  {
    postal_code: "99012",
    full_city_name: "Fairfield, WA",
    city_name: "Fairfield",
  },
  {
    postal_code: "19474",
    full_city_name: "Skippack, PA",
    city_name: "Skippack",
  },
  {
    postal_code: "47283",
    full_city_name: "Westport, IN",
    city_name: "Westport",
  },
  {
    postal_code: "56210",
    full_city_name: "Barry, MN",
    city_name: "Barry",
  },
  {
    postal_code: "55031",
    full_city_name: "Hampton, MN",
    city_name: "Hampton",
  },
  {
    postal_code: "17860",
    full_city_name: "Paxinos, PA",
    city_name: "Paxinos",
  },
  {
    postal_code: "43977",
    full_city_name: "Flushing, OH",
    city_name: "Flushing",
  },
  {
    postal_code: "93926",
    full_city_name: "Gonzales, CA",
    city_name: "Gonzales",
  },
  {
    postal_code: "26412",
    full_city_name: "Orlando, WV",
    city_name: "Orlando",
  },
  {
    postal_code: "16844",
    full_city_name: "Julian, PA",
    city_name: "Julian",
  },
  {
    postal_code: "94599",
    full_city_name: "Yountville, CA",
    city_name: "Yountville",
  },
  {
    postal_code: "56266",
    full_city_name: "Morgan, MN",
    city_name: "Morgan",
  },
  {
    postal_code: "28396",
    full_city_name: "Wagram, NC",
    city_name: "Wagram",
  },
  {
    postal_code: "79546",
    full_city_name: "Rotan, TX",
    city_name: "Rotan",
  },
  {
    postal_code: "24224",
    full_city_name: "Castlewood, VA",
    city_name: "Castlewood",
  },
  {
    postal_code: "04669",
    full_city_name: "Prospect Harbor, ME",
    city_name: "Prospect Harbor",
  },
  {
    postal_code: "68922",
    full_city_name: "Arapahoe, NE",
    city_name: "Arapahoe",
  },
  {
    postal_code: "54772",
    full_city_name: "Wheeler, WI",
    city_name: "Wheeler",
  },
  {
    postal_code: "52658",
    full_city_name: "Wever, IA",
    city_name: "Wever",
  },
  {
    postal_code: "29469",
    full_city_name: "Pinopolis, SC",
    city_name: "Pinopolis",
  },
  {
    postal_code: "18614",
    full_city_name: "Dushore, PA",
    city_name: "Dushore",
  },
  {
    postal_code: "38878",
    full_city_name: "Vardaman, MS",
    city_name: "Vardaman",
  },
  {
    postal_code: "41086",
    full_city_name: "Sparta, KY",
    city_name: "Sparta",
  },
  {
    postal_code: "15340",
    full_city_name: "Hickory, PA",
    city_name: "Hickory",
  },
  {
    postal_code: "56683",
    full_city_name: "Tenstrike, MN",
    city_name: "Tenstrike",
  },
  {
    postal_code: "43760",
    full_city_name: "Mount Perry, OH",
    city_name: "Mount Perry",
  },
  {
    postal_code: "14591",
    full_city_name: "Wyoming, NY",
    city_name: "Wyoming",
  },
  {
    postal_code: "36269",
    full_city_name: "Muscadine, AL",
    city_name: "Muscadine",
  },
  {
    postal_code: "59755",
    full_city_name: "Virginia City, MT",
    city_name: "Virginia City",
  },
  {
    postal_code: "24067",
    full_city_name: "Callaway, VA",
    city_name: "Callaway",
  },
  {
    postal_code: "56389",
    full_city_name: "West Union, MN",
    city_name: "West Union",
  },
  {
    postal_code: "66938",
    full_city_name: "Clyde, KS",
    city_name: "Clyde",
  },
  {
    postal_code: "12473",
    full_city_name: "Round Top, NY",
    city_name: "Round Top",
  },
  {
    postal_code: "39095",
    full_city_name: "Lexington, MS",
    city_name: "Lexington",
  },
  {
    postal_code: "72156",
    full_city_name: "Solgohachia, AR",
    city_name: "Solgohachia",
  },
  {
    postal_code: "66710",
    full_city_name: "Altoona, KS",
    city_name: "Altoona",
  },
  {
    postal_code: "05828",
    full_city_name: "Danville, VT",
    city_name: "Danville",
  },
  {
    postal_code: "26362",
    full_city_name: "Harrisville, WV",
    city_name: "Harrisville",
  },
  {
    postal_code: "48419",
    full_city_name: "Carsonville, MI",
    city_name: "Carsonville",
  },
  {
    postal_code: "66090",
    full_city_name: "Wathena, KS",
    city_name: "Wathena",
  },
  {
    postal_code: "88049",
    full_city_name: "Mimbres, NM",
    city_name: "Mimbres",
  },
  {
    postal_code: "01378",
    full_city_name: "Warwick, MA",
    city_name: "Warwick",
  },
  {
    postal_code: "54112",
    full_city_name: "Coleman, WI",
    city_name: "Coleman",
  },
  {
    postal_code: "50039",
    full_city_name: "Bouton, IA",
    city_name: "Bouton",
  },
  {
    postal_code: "93235",
    full_city_name: "Ivanhoe, CA",
    city_name: "Ivanhoe",
  },
  {
    postal_code: "56669",
    full_city_name: "Kabetogama, MN",
    city_name: "Kabetogama",
  },
  {
    postal_code: "25121",
    full_city_name: "Lake, WV",
    city_name: "Lake",
  },
  {
    postal_code: "01082",
    full_city_name: "Ware, MA",
    city_name: "Ware",
  },
  {
    postal_code: "55329",
    full_city_name: "Eden Valley, MN",
    city_name: "Eden Valley",
  },
  {
    postal_code: "65436",
    full_city_name: "Beulah, MO",
    city_name: "Beulah",
  },
  {
    postal_code: "12183",
    full_city_name: "Troy, NY",
    city_name: "Troy",
  },
  {
    postal_code: "68932",
    full_city_name: "Campbell, NE",
    city_name: "Campbell",
  },
  {
    postal_code: "03044",
    full_city_name: "Fremont, NH",
    city_name: "Fremont",
  },
  {
    postal_code: "04965",
    full_city_name: "Palmyra, ME",
    city_name: "Palmyra",
  },
  {
    postal_code: "56375",
    full_city_name: "Saint Stephen, MN",
    city_name: "Saint Stephen",
  },
  {
    postal_code: "96133",
    full_city_name: "Topaz, CA",
    city_name: "Topaz",
  },
  {
    postal_code: "27852",
    full_city_name: "Macclesfield, NC",
    city_name: "Macclesfield",
  },
  {
    postal_code: "48607",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "15478",
    full_city_name: "Smithfield, PA",
    city_name: "Smithfield",
  },
  {
    postal_code: "12405",
    full_city_name: "Acra, NY",
    city_name: "Acra",
  },
  {
    postal_code: "39825",
    full_city_name: "Brinson, GA",
    city_name: "Brinson",
  },
  {
    postal_code: "56136",
    full_city_name: "Hendricks, MN",
    city_name: "Hendricks",
  },
  {
    postal_code: "22846",
    full_city_name: "Penn Laird, VA",
    city_name: "Penn Laird",
  },
  {
    postal_code: "36562",
    full_city_name: "Perdido, AL",
    city_name: "Perdido",
  },
  {
    postal_code: "57269",
    full_city_name: "Twin Brooks, SD",
    city_name: "Twin Brooks",
  },
  {
    postal_code: "60556",
    full_city_name: "Waterman, IL",
    city_name: "Waterman",
  },
  {
    postal_code: "56673",
    full_city_name: "Roosevelt, MN",
    city_name: "Roosevelt",
  },
  {
    postal_code: "44046",
    full_city_name: "Huntsburg, OH",
    city_name: "Huntsburg",
  },
  {
    postal_code: "44804",
    full_city_name: "Arcadia, OH",
    city_name: "Arcadia",
  },
  {
    postal_code: "78614",
    full_city_name: "Cost, TX",
    city_name: "Cost",
  },
  {
    postal_code: "21813",
    full_city_name: "Bishopville, MD",
    city_name: "Bishopville",
  },
  {
    postal_code: "97380",
    full_city_name: "Siletz, OR",
    city_name: "Siletz",
  },
  {
    postal_code: "41425",
    full_city_name: "Ezel, KY",
    city_name: "Ezel",
  },
  {
    postal_code: "08084",
    full_city_name: "Stratford, NJ",
    city_name: "Stratford",
  },
  {
    postal_code: "58784",
    full_city_name: "Stanley, ND",
    city_name: "Stanley",
  },
  {
    postal_code: "82210",
    full_city_name: "Chugwater, WY",
    city_name: "Chugwater",
  },
  {
    postal_code: "62551",
    full_city_name: "Niantic, IL",
    city_name: "Niantic",
  },
  {
    postal_code: "54761",
    full_city_name: "Plum City, WI",
    city_name: "Plum City",
  },
  {
    postal_code: "13425",
    full_city_name: "Oriskany Falls, NY",
    city_name: "Oriskany Falls",
  },
  {
    postal_code: "40171",
    full_city_name: "Union Star, KY",
    city_name: "Union Star",
  },
  {
    postal_code: "40313",
    full_city_name: "Clearfield, KY",
    city_name: "Clearfield",
  },
  {
    postal_code: "32767",
    full_city_name: "Paisley, FL",
    city_name: "Paisley",
  },
  {
    postal_code: "17835",
    full_city_name: "Laurelton, PA",
    city_name: "Laurelton",
  },
  {
    postal_code: "60945",
    full_city_name: "Iroquois, IL",
    city_name: "Iroquois",
  },
  {
    postal_code: "52536",
    full_city_name: "Blakesburg, IA",
    city_name: "Blakesburg",
  },
  {
    postal_code: "50633",
    full_city_name: "Geneva, IA",
    city_name: "Geneva",
  },
  {
    postal_code: "54759",
    full_city_name: "Pepin, WI",
    city_name: "Pepin",
  },
  {
    postal_code: "46130",
    full_city_name: "Fountaintown, IN",
    city_name: "Fountaintown",
  },
  {
    postal_code: "15314",
    full_city_name: "Bentleyville, PA",
    city_name: "Bentleyville",
  },
  {
    postal_code: "60531",
    full_city_name: "Leland, IL",
    city_name: "Leland",
  },
  {
    postal_code: "43944",
    full_city_name: "Richmond, OH",
    city_name: "Richmond",
  },
  {
    postal_code: "58656",
    full_city_name: "Taylor, ND",
    city_name: "Taylor",
  },
  {
    postal_code: "66515",
    full_city_name: "Morrill, KS",
    city_name: "Morrill",
  },
  {
    postal_code: "80830",
    full_city_name: "Matheson, CO",
    city_name: "Matheson",
  },
  {
    postal_code: "13335",
    full_city_name: "Edmeston, NY",
    city_name: "Edmeston",
  },
  {
    postal_code: "93452",
    full_city_name: "San Simeon, CA",
    city_name: "San Simeon",
  },
  {
    postal_code: "97375",
    full_city_name: "Scotts Mills, OR",
    city_name: "Scotts Mills",
  },
  {
    postal_code: "24139",
    full_city_name: "Pittsville, VA",
    city_name: "Pittsville",
  },
  {
    postal_code: "74540",
    full_city_name: "Daisy, OK",
    city_name: "Daisy",
  },
  {
    postal_code: "68439",
    full_city_name: "Staplehurst, NE",
    city_name: "Staplehurst",
  },
  {
    postal_code: "61844",
    full_city_name: "Fithian, IL",
    city_name: "Fithian",
  },
  {
    postal_code: "16214",
    full_city_name: "Clarion, PA",
    city_name: "Clarion",
  },
  {
    postal_code: "56169",
    full_city_name: "Russell, MN",
    city_name: "Russell",
  },
  {
    postal_code: "95310",
    full_city_name: "Columbia, CA",
    city_name: "Columbia",
  },
  {
    postal_code: "36273",
    full_city_name: "Ranburne, AL",
    city_name: "Ranburne",
  },
  {
    postal_code: "67227",
    full_city_name: "Wichita, KS",
    city_name: "Wichita",
  },
  {
    postal_code: "82059",
    full_city_name: "Granite Canon, WY",
    city_name: "Granite Canon",
  },
  {
    postal_code: "66066",
    full_city_name: "Oskaloosa, KS",
    city_name: "Oskaloosa",
  },
  {
    postal_code: "68664",
    full_city_name: "Snyder, NE",
    city_name: "Snyder",
  },
  {
    postal_code: "26055",
    full_city_name: "Proctor, WV",
    city_name: "Proctor",
  },
  {
    postal_code: "54512",
    full_city_name: "Boulder Junction, WI",
    city_name: "Boulder Junction",
  },
  {
    postal_code: "27884",
    full_city_name: "Stokes, NC",
    city_name: "Stokes",
  },
  {
    postal_code: "50234",
    full_city_name: "Rhodes, IA",
    city_name: "Rhodes",
  },
  {
    postal_code: "45789",
    full_city_name: "Wingett Run, OH",
    city_name: "Wingett Run",
  },
  {
    postal_code: "64436",
    full_city_name: "Cosby, MO",
    city_name: "Cosby",
  },
  {
    postal_code: "01535",
    full_city_name: "North Brookfield, MA",
    city_name: "North Brookfield",
  },
  {
    postal_code: "63363",
    full_city_name: "New Florence, MO",
    city_name: "New Florence",
  },
  {
    postal_code: "27054",
    full_city_name: "Woodleaf, NC",
    city_name: "Woodleaf",
  },
  {
    postal_code: "87017",
    full_city_name: "Gallina, NM",
    city_name: "Gallina",
  },
  {
    postal_code: "17241",
    full_city_name: "Newville, PA",
    city_name: "Newville",
  },
  {
    postal_code: "12582",
    full_city_name: "Stormville, NY",
    city_name: "Stormville",
  },
  {
    postal_code: "12124",
    full_city_name: "New Baltimore, NY",
    city_name: "New Baltimore",
  },
  {
    postal_code: "13757",
    full_city_name: "East Meredith, NY",
    city_name: "East Meredith",
  },
  {
    postal_code: "56331",
    full_city_name: "Freeport, MN",
    city_name: "Freeport",
  },
  {
    postal_code: "25841",
    full_city_name: "Flat Top, WV",
    city_name: "Flat Top",
  },
  {
    postal_code: "65534",
    full_city_name: "Laquey, MO",
    city_name: "Laquey",
  },
  {
    postal_code: "12410",
    full_city_name: "Big Indian, NY",
    city_name: "Big Indian",
  },
  {
    postal_code: "53926",
    full_city_name: "Dalton, WI",
    city_name: "Dalton",
  },
  {
    postal_code: "50593",
    full_city_name: "Varina, IA",
    city_name: "Varina",
  },
  {
    postal_code: "32181",
    full_city_name: "Pomona Park, FL",
    city_name: "Pomona Park",
  },
  {
    postal_code: "37688",
    full_city_name: "Shady Valley, TN",
    city_name: "Shady Valley",
  },
  {
    postal_code: "01529",
    full_city_name: "Millville, MA",
    city_name: "Millville",
  },
  {
    postal_code: "18913",
    full_city_name: "Carversville, PA",
    city_name: "Carversville",
  },
  {
    postal_code: "38950",
    full_city_name: "Philipp, MS",
    city_name: "Philipp",
  },
  {
    postal_code: "41171",
    full_city_name: "Sandy Hook, KY",
    city_name: "Sandy Hook",
  },
  {
    postal_code: "01346",
    full_city_name: "Heath, MA",
    city_name: "Heath",
  },
  {
    postal_code: "62030",
    full_city_name: "Fidelity, IL",
    city_name: "Fidelity",
  },
  {
    postal_code: "54520",
    full_city_name: "Crandon, WI",
    city_name: "Crandon",
  },
  {
    postal_code: "55796",
    full_city_name: "Winton, MN",
    city_name: "Winton",
  },
  {
    postal_code: "59828",
    full_city_name: "Corvallis, MT",
    city_name: "Corvallis",
  },
  {
    postal_code: "16421",
    full_city_name: "Harborcreek, PA",
    city_name: "Harborcreek",
  },
  {
    postal_code: "38220",
    full_city_name: "Atwood, TN",
    city_name: "Atwood",
  },
  {
    postal_code: "43164",
    full_city_name: "Williamsport, OH",
    city_name: "Williamsport",
  },
  {
    postal_code: "49064",
    full_city_name: "Lawrence, MI",
    city_name: "Lawrence",
  },
  {
    postal_code: "83234",
    full_city_name: "Downey, ID",
    city_name: "Downey",
  },
  {
    postal_code: "45769",
    full_city_name: "Pomeroy, OH",
    city_name: "Pomeroy",
  },
  {
    postal_code: "47022",
    full_city_name: "Guilford, IN",
    city_name: "Guilford",
  },
  {
    postal_code: "72102",
    full_city_name: "Mc Rae, AR",
    city_name: "Mc Rae",
  },
  {
    postal_code: "68017",
    full_city_name: "Ceresco, NE",
    city_name: "Ceresco",
  },
  {
    postal_code: "13835",
    full_city_name: "Richford, NY",
    city_name: "Richford",
  },
  {
    postal_code: "40075",
    full_city_name: "Turners Station, KY",
    city_name: "Turners Station",
  },
  {
    postal_code: "21853",
    full_city_name: "Princess Anne, MD",
    city_name: "Princess Anne",
  },
  {
    postal_code: "47868",
    full_city_name: "Poland, IN",
    city_name: "Poland",
  },
  {
    postal_code: "41080",
    full_city_name: "Petersburg, KY",
    city_name: "Petersburg",
  },
  {
    postal_code: "96764",
    full_city_name: "Laupahoehoe, HI",
    city_name: "Laupahoehoe",
  },
  {
    postal_code: "84710",
    full_city_name: "Alton, UT",
    city_name: "Alton",
  },
  {
    postal_code: "61042",
    full_city_name: "Harmon, IL",
    city_name: "Harmon",
  },
  {
    postal_code: "28433",
    full_city_name: "Clarkton, NC",
    city_name: "Clarkton",
  },
  {
    postal_code: "46504",
    full_city_name: "Bourbon, IN",
    city_name: "Bourbon",
  },
  {
    postal_code: "52069",
    full_city_name: "Preston, IA",
    city_name: "Preston",
  },
  {
    postal_code: "37337",
    full_city_name: "Grandview, TN",
    city_name: "Grandview",
  },
  {
    postal_code: "29468",
    full_city_name: "Pineville, SC",
    city_name: "Pineville",
  },
  {
    postal_code: "68751",
    full_city_name: "Maskell, NE",
    city_name: "Maskell",
  },
  {
    postal_code: "66076",
    full_city_name: "Pomona, KS",
    city_name: "Pomona",
  },
  {
    postal_code: "57262",
    full_city_name: "Sisseton, SD",
    city_name: "Sisseton",
  },
  {
    postal_code: "84530",
    full_city_name: "La Sal, UT",
    city_name: "La Sal",
  },
  {
    postal_code: "83644",
    full_city_name: "Middleton, ID",
    city_name: "Middleton",
  },
  {
    postal_code: "12022",
    full_city_name: "Berlin, NY",
    city_name: "Berlin",
  },
  {
    postal_code: "75554",
    full_city_name: "Avery, TX",
    city_name: "Avery",
  },
  {
    postal_code: "15004",
    full_city_name: "Atlasburg, PA",
    city_name: "Atlasburg",
  },
  {
    postal_code: "34956",
    full_city_name: "Indiantown, FL",
    city_name: "Indiantown",
  },
  {
    postal_code: "67672",
    full_city_name: "Wakeeney, KS",
    city_name: "Wakeeney",
  },
  {
    postal_code: "93527",
    full_city_name: "Inyokern, CA",
    city_name: "Inyokern",
  },
  {
    postal_code: "24531",
    full_city_name: "Chatham, VA",
    city_name: "Chatham",
  },
  {
    postal_code: "96113",
    full_city_name: "Herlong, CA",
    city_name: "Herlong",
  },
  {
    postal_code: "54828",
    full_city_name: "Couderay, WI",
    city_name: "Couderay",
  },
  {
    postal_code: "74578",
    full_city_name: "Wilburton, OK",
    city_name: "Wilburton",
  },
  {
    postal_code: "28101",
    full_city_name: "Mc Adenville, NC",
    city_name: "Mc Adenville",
  },
  {
    postal_code: "15477",
    full_city_name: "Roscoe, PA",
    city_name: "Roscoe",
  },
  {
    postal_code: "23867",
    full_city_name: "Jarratt, VA",
    city_name: "Jarratt",
  },
  {
    postal_code: "44882",
    full_city_name: "Sycamore, OH",
    city_name: "Sycamore",
  },
  {
    postal_code: "53587",
    full_city_name: "South Wayne, WI",
    city_name: "South Wayne",
  },
  {
    postal_code: "51640",
    full_city_name: "Hamburg, IA",
    city_name: "Hamburg",
  },
  {
    postal_code: "29432",
    full_city_name: "Branchville, SC",
    city_name: "Branchville",
  },
  {
    postal_code: "61314",
    full_city_name: "Buda, IL",
    city_name: "Buda",
  },
  {
    postal_code: "75559",
    full_city_name: "De Kalb, TX",
    city_name: "De Kalb",
  },
  {
    postal_code: "67744",
    full_city_name: "Ludell, KS",
    city_name: "Ludell",
  },
  {
    postal_code: "62086",
    full_city_name: "Sorento, IL",
    city_name: "Sorento",
  },
  {
    postal_code: "18456",
    full_city_name: "Prompton, PA",
    city_name: "Prompton",
  },
  {
    postal_code: "56732",
    full_city_name: "Karlstad, MN",
    city_name: "Karlstad",
  },
  {
    postal_code: "32087",
    full_city_name: "Sanderson, FL",
    city_name: "Sanderson",
  },
  {
    postal_code: "04655",
    full_city_name: "Machiasport, ME",
    city_name: "Machiasport",
  },
  {
    postal_code: "74832",
    full_city_name: "Carney, OK",
    city_name: "Carney",
  },
  {
    postal_code: "12945",
    full_city_name: "Lake Clear, NY",
    city_name: "Lake Clear",
  },
  {
    postal_code: "06081",
    full_city_name: "Tariffville, CT",
    city_name: "Tariffville",
  },
  {
    postal_code: "23938",
    full_city_name: "Dundas, VA",
    city_name: "Dundas",
  },
  {
    postal_code: "62538",
    full_city_name: "Harvel, IL",
    city_name: "Harvel",
  },
  {
    postal_code: "60946",
    full_city_name: "Kempton, IL",
    city_name: "Kempton",
  },
  {
    postal_code: "59851",
    full_city_name: "Milltown, MT",
    city_name: "Milltown",
  },
  {
    postal_code: "52551",
    full_city_name: "Douds, IA",
    city_name: "Douds",
  },
  {
    postal_code: "38754",
    full_city_name: "Isola, MS",
    city_name: "Isola",
  },
  {
    postal_code: "67041",
    full_city_name: "Elbing, KS",
    city_name: "Elbing",
  },
  {
    postal_code: "68061",
    full_city_name: "Tekamah, NE",
    city_name: "Tekamah",
  },
  {
    postal_code: "83811",
    full_city_name: "Clark Fork, ID",
    city_name: "Clark Fork",
  },
  {
    postal_code: "30069",
    full_city_name: "Marietta, GA",
    city_name: "Marietta",
  },
  {
    postal_code: "55088",
    full_city_name: "Webster, MN",
    city_name: "Webster",
  },
  {
    postal_code: "24312",
    full_city_name: "Austinville, VA",
    city_name: "Austinville",
  },
  {
    postal_code: "49952",
    full_city_name: "Nisula, MI",
    city_name: "Nisula",
  },
  {
    postal_code: "44441",
    full_city_name: "Negley, OH",
    city_name: "Negley",
  },
  {
    postal_code: "01534",
    full_city_name: "Northbridge, MA",
    city_name: "Northbridge",
  },
  {
    postal_code: "68015",
    full_city_name: "Cedar Bluffs, NE",
    city_name: "Cedar Bluffs",
  },
  {
    postal_code: "05747",
    full_city_name: "Granville, VT",
    city_name: "Granville",
  },
  {
    postal_code: "40077",
    full_city_name: "Westport, KY",
    city_name: "Westport",
  },
  {
    postal_code: "75929",
    full_city_name: "Broaddus, TX",
    city_name: "Broaddus",
  },
  {
    postal_code: "17765",
    full_city_name: "Roaring Branch, PA",
    city_name: "Roaring Branch",
  },
  {
    postal_code: "40489",
    full_city_name: "Waynesburg, KY",
    city_name: "Waynesburg",
  },
  {
    postal_code: "59061",
    full_city_name: "Nye, MT",
    city_name: "Nye",
  },
  {
    postal_code: "12917",
    full_city_name: "Burke, NY",
    city_name: "Burke",
  },
  {
    postal_code: "15424",
    full_city_name: "Confluence, PA",
    city_name: "Confluence",
  },
  {
    postal_code: "49277",
    full_city_name: "Rives Junction, MI",
    city_name: "Rives Junction",
  },
  {
    postal_code: "29729",
    full_city_name: "Richburg, SC",
    city_name: "Richburg",
  },
  {
    postal_code: "43036",
    full_city_name: "Magnetic Springs, OH",
    city_name: "Magnetic Springs",
  },
  {
    postal_code: "60966",
    full_city_name: "Sheldon, IL",
    city_name: "Sheldon",
  },
  {
    postal_code: "37828",
    full_city_name: "Norris, TN",
    city_name: "Norris",
  },
  {
    postal_code: "12523",
    full_city_name: "Elizaville, NY",
    city_name: "Elizaville",
  },
  {
    postal_code: "12017",
    full_city_name: "Austerlitz, NY",
    city_name: "Austerlitz",
  },
  {
    postal_code: "92066",
    full_city_name: "Ranchita, CA",
    city_name: "Ranchita",
  },
  {
    postal_code: "16936",
    full_city_name: "Millerton, PA",
    city_name: "Millerton",
  },
  {
    postal_code: "13640",
    full_city_name: "Wellesley Island, NY",
    city_name: "Wellesley Island",
  },
  {
    postal_code: "93609",
    full_city_name: "Caruthers, CA",
    city_name: "Caruthers",
  },
  {
    postal_code: "49220",
    full_city_name: "Addison, MI",
    city_name: "Addison",
  },
  {
    postal_code: "12930",
    full_city_name: "Dickinson Center, NY",
    city_name: "Dickinson Center",
  },
  {
    postal_code: "21545",
    full_city_name: "Mount Savage, MD",
    city_name: "Mount Savage",
  },
  {
    postal_code: "03287",
    full_city_name: "Wilmot, NH",
    city_name: "Wilmot",
  },
  {
    postal_code: "61375",
    full_city_name: "Varna, IL",
    city_name: "Varna",
  },
  {
    postal_code: "78940",
    full_city_name: "Fayetteville, TX",
    city_name: "Fayetteville",
  },
  {
    postal_code: "62242",
    full_city_name: "Evansville, IL",
    city_name: "Evansville",
  },
  {
    postal_code: "78116",
    full_city_name: "Gillett, TX",
    city_name: "Gillett",
  },
  {
    postal_code: "03856",
    full_city_name: "Newfields, NH",
    city_name: "Newfields",
  },
  {
    postal_code: "51637",
    full_city_name: "College Springs, IA",
    city_name: "College Springs",
  },
  {
    postal_code: "28430",
    full_city_name: "Cerro Gordo, NC",
    city_name: "Cerro Gordo",
  },
  {
    postal_code: "12563",
    full_city_name: "Patterson, NY",
    city_name: "Patterson",
  },
  {
    postal_code: "41531",
    full_city_name: "Hardy, KY",
    city_name: "Hardy",
  },
  {
    postal_code: "15949",
    full_city_name: "Robinson, PA",
    city_name: "Robinson",
  },
  {
    postal_code: "08032",
    full_city_name: "Grenloch, NJ",
    city_name: "Grenloch",
  },
  {
    postal_code: "13485",
    full_city_name: "West Edmeston, NY",
    city_name: "West Edmeston",
  },
  {
    postal_code: "40862",
    full_city_name: "Partridge, KY",
    city_name: "Partridge",
  },
  {
    postal_code: "96774",
    full_city_name: "Ookala, HI",
    city_name: "Ookala",
  },
  {
    postal_code: "59863",
    full_city_name: "Ravalli, MT",
    city_name: "Ravalli",
  },
  {
    postal_code: "48138",
    full_city_name: "Grosse Ile, MI",
    city_name: "Grosse Ile",
  },
  {
    postal_code: "13494",
    full_city_name: "Woodgate, NY",
    city_name: "Woodgate",
  },
  {
    postal_code: "55389",
    full_city_name: "Watkins, MN",
    city_name: "Watkins",
  },
  {
    postal_code: "68620",
    full_city_name: "Albion, NE",
    city_name: "Albion",
  },
  {
    postal_code: "61460",
    full_city_name: "Media, IL",
    city_name: "Media",
  },
  {
    postal_code: "99721",
    full_city_name: "Anaktuvuk Pass, AK",
    city_name: "Anaktuvuk Pass",
  },
  {
    postal_code: "76656",
    full_city_name: "Lott, TX",
    city_name: "Lott",
  },
  {
    postal_code: "22730",
    full_city_name: "Oakpark, VA",
    city_name: "Oakpark",
  },
  {
    postal_code: "01474",
    full_city_name: "West Townsend, MA",
    city_name: "West Townsend",
  },
  {
    postal_code: "30648",
    full_city_name: "Lexington, GA",
    city_name: "Lexington",
  },
  {
    postal_code: "46382",
    full_city_name: "Union Mills, IN",
    city_name: "Union Mills",
  },
  {
    postal_code: "80438",
    full_city_name: "Empire, CO",
    city_name: "Empire",
  },
  {
    postal_code: "74561",
    full_city_name: "Quinton, OK",
    city_name: "Quinton",
  },
  {
    postal_code: "77363",
    full_city_name: "Plantersville, TX",
    city_name: "Plantersville",
  },
  {
    postal_code: "50132",
    full_city_name: "Kamrar, IA",
    city_name: "Kamrar",
  },
  {
    postal_code: "25644",
    full_city_name: "Sarah Ann, WV",
    city_name: "Sarah Ann",
  },
  {
    postal_code: "54635",
    full_city_name: "Hixton, WI",
    city_name: "Hixton",
  },
  {
    postal_code: "64646",
    full_city_name: "Humphreys, MO",
    city_name: "Humphreys",
  },
  {
    postal_code: "24539",
    full_city_name: "Crystal Hill, VA",
    city_name: "Crystal Hill",
  },
  {
    postal_code: "64097",
    full_city_name: "Wellington, MO",
    city_name: "Wellington",
  },
  {
    postal_code: "64622",
    full_city_name: "Bogard, MO",
    city_name: "Bogard",
  },
  {
    postal_code: "61919",
    full_city_name: "Camargo, IL",
    city_name: "Camargo",
  },
  {
    postal_code: "54524",
    full_city_name: "Fifield, WI",
    city_name: "Fifield",
  },
  {
    postal_code: "66738",
    full_city_name: "Fulton, KS",
    city_name: "Fulton",
  },
  {
    postal_code: "21641",
    full_city_name: "Hillsboro, MD",
    city_name: "Hillsboro",
  },
  {
    postal_code: "98583",
    full_city_name: "Satsop, WA",
    city_name: "Satsop",
  },
  {
    postal_code: "82058",
    full_city_name: "Garrett, WY",
    city_name: "Garrett",
  },
  {
    postal_code: "73736",
    full_city_name: "Fairmont, OK",
    city_name: "Fairmont",
  },
  {
    postal_code: "67464",
    full_city_name: "Marquette, KS",
    city_name: "Marquette",
  },
  {
    postal_code: "59057",
    full_city_name: "Molt, MT",
    city_name: "Molt",
  },
  {
    postal_code: "61279",
    full_city_name: "Reynolds, IL",
    city_name: "Reynolds",
  },
  {
    postal_code: "28444",
    full_city_name: "Harrells, NC",
    city_name: "Harrells",
  },
  {
    postal_code: "55936",
    full_city_name: "Grand Meadow, MN",
    city_name: "Grand Meadow",
  },
  {
    postal_code: "16028",
    full_city_name: "East Brady, PA",
    city_name: "East Brady",
  },
  {
    postal_code: "38374",
    full_city_name: "Scotts Hill, TN",
    city_name: "Scotts Hill",
  },
  {
    postal_code: "50153",
    full_city_name: "Lynnville, IA",
    city_name: "Lynnville",
  },
  {
    postal_code: "74731",
    full_city_name: "Cartwright, OK",
    city_name: "Cartwright",
  },
  {
    postal_code: "61560",
    full_city_name: "Putnam, IL",
    city_name: "Putnam",
  },
  {
    postal_code: "28420",
    full_city_name: "Ash, NC",
    city_name: "Ash",
  },
  {
    postal_code: "05152",
    full_city_name: "Peru, VT",
    city_name: "Peru",
  },
  {
    postal_code: "15376",
    full_city_name: "West Alexander, PA",
    city_name: "West Alexander",
  },
  {
    postal_code: "98565",
    full_city_name: "Napavine, WA",
    city_name: "Napavine",
  },
  {
    postal_code: "19979",
    full_city_name: "Viola, DE",
    city_name: "Viola",
  },
  {
    postal_code: "88055",
    full_city_name: "Redrock, NM",
    city_name: "Redrock",
  },
  {
    postal_code: "35049",
    full_city_name: "Cleveland, AL",
    city_name: "Cleveland",
  },
  {
    postal_code: "02833",
    full_city_name: "Hopkinton, RI",
    city_name: "Hopkinton",
  },
  {
    postal_code: "81133",
    full_city_name: "Fort Garland, CO",
    city_name: "Fort Garland",
  },
  {
    postal_code: "23840",
    full_city_name: "Dewitt, VA",
    city_name: "Dewitt",
  },
  {
    postal_code: "62871",
    full_city_name: "Omaha, IL",
    city_name: "Omaha",
  },
  {
    postal_code: "60941",
    full_city_name: "Herscher, IL",
    city_name: "Herscher",
  },
  {
    postal_code: "47989",
    full_city_name: "Waveland, IN",
    city_name: "Waveland",
  },
  {
    postal_code: "31831",
    full_city_name: "Waverly Hall, GA",
    city_name: "Waverly Hall",
  },
  {
    postal_code: "61420",
    full_city_name: "Blandinsville, IL",
    city_name: "Blandinsville",
  },
  {
    postal_code: "14837",
    full_city_name: "Dundee, NY",
    city_name: "Dundee",
  },
  {
    postal_code: "61728",
    full_city_name: "Colfax, IL",
    city_name: "Colfax",
  },
  {
    postal_code: "24244",
    full_city_name: "Duffield, VA",
    city_name: "Duffield",
  },
  {
    postal_code: "80805",
    full_city_name: "Bethune, CO",
    city_name: "Bethune",
  },
  {
    postal_code: "15050",
    full_city_name: "Hookstown, PA",
    city_name: "Hookstown",
  },
  {
    postal_code: "15675",
    full_city_name: "Penn, PA",
    city_name: "Penn",
  },
  {
    postal_code: "25537",
    full_city_name: "Lesage, WV",
    city_name: "Lesage",
  },
  {
    postal_code: "12549",
    full_city_name: "Montgomery, NY",
    city_name: "Montgomery",
  },
  {
    postal_code: "56144",
    full_city_name: "Jasper, MN",
    city_name: "Jasper",
  },
  {
    postal_code: "06778",
    full_city_name: "Northfield, CT",
    city_name: "Northfield",
  },
  {
    postal_code: "77442",
    full_city_name: "Garwood, TX",
    city_name: "Garwood",
  },
  {
    postal_code: "15488",
    full_city_name: "Waltersburg, PA",
    city_name: "Waltersburg",
  },
  {
    postal_code: "80137",
    full_city_name: "Watkins, CO",
    city_name: "Watkins",
  },
  {
    postal_code: "65764",
    full_city_name: "Tunas, MO",
    city_name: "Tunas",
  },
  {
    postal_code: "62215",
    full_city_name: "Albers, IL",
    city_name: "Albers",
  },
  {
    postal_code: "56737",
    full_city_name: "Middle River, MN",
    city_name: "Middle River",
  },
  {
    postal_code: "04261",
    full_city_name: "Newry, ME",
    city_name: "Newry",
  },
  {
    postal_code: "18066",
    full_city_name: "New Tripoli, PA",
    city_name: "New Tripoli",
  },
  {
    postal_code: "48413",
    full_city_name: "Bad Axe, MI",
    city_name: "Bad Axe",
  },
  {
    postal_code: "87035",
    full_city_name: "Moriarty, NM",
    city_name: "Moriarty",
  },
  {
    postal_code: "45346",
    full_city_name: "New Madison, OH",
    city_name: "New Madison",
  },
  {
    postal_code: "15327",
    full_city_name: "Dilliner, PA",
    city_name: "Dilliner",
  },
  {
    postal_code: "02637",
    full_city_name: "Cummaquid, MA",
    city_name: "Cummaquid",
  },
  {
    postal_code: "83823",
    full_city_name: "Deary, ID",
    city_name: "Deary",
  },
  {
    postal_code: "37060",
    full_city_name: "Eagleville, TN",
    city_name: "Eagleville",
  },
  {
    postal_code: "50129",
    full_city_name: "Jefferson, IA",
    city_name: "Jefferson",
  },
  {
    postal_code: "14894",
    full_city_name: "Wellsburg, NY",
    city_name: "Wellsburg",
  },
  {
    postal_code: "74016",
    full_city_name: "Chelsea, OK",
    city_name: "Chelsea",
  },
  {
    postal_code: "14138",
    full_city_name: "South Dayton, NY",
    city_name: "South Dayton",
  },
  {
    postal_code: "55607",
    full_city_name: "Isabella, MN",
    city_name: "Isabella",
  },
  {
    postal_code: "20711",
    full_city_name: "Lothian, MD",
    city_name: "Lothian",
  },
  {
    postal_code: "55918",
    full_city_name: "Brownsdale, MN",
    city_name: "Brownsdale",
  },
  {
    postal_code: "20608",
    full_city_name: "Aquasco, MD",
    city_name: "Aquasco",
  },
  {
    postal_code: "74051",
    full_city_name: "Ochelata, OK",
    city_name: "Ochelata",
  },
  {
    postal_code: "54805",
    full_city_name: "Almena, WI",
    city_name: "Almena",
  },
  {
    postal_code: "41503",
    full_city_name: "South Williamson, KY",
    city_name: "South Williamson",
  },
  {
    postal_code: "45719",
    full_city_name: "Chauncey, OH",
    city_name: "Chauncey",
  },
  {
    postal_code: "66858",
    full_city_name: "Lincolnville, KS",
    city_name: "Lincolnville",
  },
  {
    postal_code: "43406",
    full_city_name: "Bradner, OH",
    city_name: "Bradner",
  },
  {
    postal_code: "80623",
    full_city_name: "Gilcrest, CO",
    city_name: "Gilcrest",
  },
  {
    postal_code: "49816",
    full_city_name: "Chatham, MI",
    city_name: "Chatham",
  },
  {
    postal_code: "52657",
    full_city_name: "Saint Paul, IA",
    city_name: "Saint Paul",
  },
  {
    postal_code: "16342",
    full_city_name: "Polk, PA",
    city_name: "Polk",
  },
  {
    postal_code: "08812",
    full_city_name: "Dunellen, NJ",
    city_name: "Dunellen",
  },
  {
    postal_code: "06254",
    full_city_name: "North Franklin, CT",
    city_name: "North Franklin",
  },
  {
    postal_code: "92662",
    full_city_name: "Newport Beach, CA",
    city_name: "Newport Beach",
  },
  {
    postal_code: "27890",
    full_city_name: "Weldon, NC",
    city_name: "Weldon",
  },
  {
    postal_code: "56021",
    full_city_name: "Courtland, MN",
    city_name: "Courtland",
  },
  {
    postal_code: "20839",
    full_city_name: "Beallsville, MD",
    city_name: "Beallsville",
  },
  {
    postal_code: "26039",
    full_city_name: "Glen Easton, WV",
    city_name: "Glen Easton",
  },
  {
    postal_code: "43717",
    full_city_name: "Belle Valley, OH",
    city_name: "Belle Valley",
  },
  {
    postal_code: "46760",
    full_city_name: "Kimmell, IN",
    city_name: "Kimmell",
  },
  {
    postal_code: "48126",
    full_city_name: "Dearborn, MI",
    city_name: "Dearborn",
  },
  {
    postal_code: "95465",
    full_city_name: "Occidental, CA",
    city_name: "Occidental",
  },
  {
    postal_code: "71866",
    full_city_name: "Winthrop, AR",
    city_name: "Winthrop",
  },
  {
    postal_code: "54622",
    full_city_name: "Cochrane, WI",
    city_name: "Cochrane",
  },
  {
    postal_code: "17978",
    full_city_name: "Spring Glen, PA",
    city_name: "Spring Glen",
  },
  {
    postal_code: "62069",
    full_city_name: "Mount Olive, IL",
    city_name: "Mount Olive",
  },
  {
    postal_code: "62674",
    full_city_name: "Palmyra, IL",
    city_name: "Palmyra",
  },
  {
    postal_code: "99029",
    full_city_name: "Reardan, WA",
    city_name: "Reardan",
  },
  {
    postal_code: "47982",
    full_city_name: "State Line, IN",
    city_name: "State Line",
  },
  {
    postal_code: "47929",
    full_city_name: "Chalmers, IN",
    city_name: "Chalmers",
  },
  {
    postal_code: "64020",
    full_city_name: "Concordia, MO",
    city_name: "Concordia",
  },
  {
    postal_code: "76364",
    full_city_name: "Harrold, TX",
    city_name: "Harrold",
  },
  {
    postal_code: "38928",
    full_city_name: "Glendora, MS",
    city_name: "Glendora",
  },
  {
    postal_code: "54856",
    full_city_name: "Mason, WI",
    city_name: "Mason",
  },
  {
    postal_code: "50275",
    full_city_name: "Woodburn, IA",
    city_name: "Woodburn",
  },
  {
    postal_code: "04353",
    full_city_name: "Whitefield, ME",
    city_name: "Whitefield",
  },
  {
    postal_code: "56279",
    full_city_name: "Pennock, MN",
    city_name: "Pennock",
  },
  {
    postal_code: "02702",
    full_city_name: "Assonet, MA",
    city_name: "Assonet",
  },
  {
    postal_code: "36538",
    full_city_name: "Frankville, AL",
    city_name: "Frankville",
  },
  {
    postal_code: "72359",
    full_city_name: "Madison, AR",
    city_name: "Madison",
  },
  {
    postal_code: "45855",
    full_city_name: "Latty, OH",
    city_name: "Latty",
  },
  {
    postal_code: "37369",
    full_city_name: "Reliance, TN",
    city_name: "Reliance",
  },
  {
    postal_code: "58046",
    full_city_name: "Hope, ND",
    city_name: "Hope",
  },
  {
    postal_code: "24733",
    full_city_name: "Lashmeet, WV",
    city_name: "Lashmeet",
  },
  {
    postal_code: "60955",
    full_city_name: "Onarga, IL",
    city_name: "Onarga",
  },
  {
    postal_code: "63737",
    full_city_name: "Brazeau, MO",
    city_name: "Brazeau",
  },
  {
    postal_code: "69163",
    full_city_name: "Stapleton, NE",
    city_name: "Stapleton",
  },
  {
    postal_code: "04086",
    full_city_name: "Topsham, ME",
    city_name: "Topsham",
  },
  {
    postal_code: "98952",
    full_city_name: "White Swan, WA",
    city_name: "White Swan",
  },
  {
    postal_code: "53803",
    full_city_name: "Benton, WI",
    city_name: "Benton",
  },
  {
    postal_code: "18615",
    full_city_name: "Falls, PA",
    city_name: "Falls",
  },
  {
    postal_code: "93441",
    full_city_name: "Los Olivos, CA",
    city_name: "Los Olivos",
  },
  {
    postal_code: "28742",
    full_city_name: "Horse Shoe, NC",
    city_name: "Horse Shoe",
  },
  {
    postal_code: "70358",
    full_city_name: "Grand Isle, LA",
    city_name: "Grand Isle",
  },
  {
    postal_code: "66838",
    full_city_name: "Burdick, KS",
    city_name: "Burdick",
  },
  {
    postal_code: "36043",
    full_city_name: "Hope Hull, AL",
    city_name: "Hope Hull",
  },
  {
    postal_code: "46216",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "81045",
    full_city_name: "Haswell, CO",
    city_name: "Haswell",
  },
  {
    postal_code: "74842",
    full_city_name: "Fittstown, OK",
    city_name: "Fittstown",
  },
  {
    postal_code: "39116",
    full_city_name: "Mize, MS",
    city_name: "Mize",
  },
  {
    postal_code: "38371",
    full_city_name: "Sardis, TN",
    city_name: "Sardis",
  },
  {
    postal_code: "45654",
    full_city_name: "New Plymouth, OH",
    city_name: "New Plymouth",
  },
  {
    postal_code: "23153",
    full_city_name: "Sandy Hook, VA",
    city_name: "Sandy Hook",
  },
  {
    postal_code: "68853",
    full_city_name: "Loup City, NE",
    city_name: "Loup City",
  },
  {
    postal_code: "11359",
    full_city_name: "Bayside, NY",
    city_name: "Bayside",
  },
  {
    postal_code: "50051",
    full_city_name: "Clemons, IA",
    city_name: "Clemons",
  },
  {
    postal_code: "50579",
    full_city_name: "Rockwell City, IA",
    city_name: "Rockwell City",
  },
  {
    postal_code: "21915",
    full_city_name: "Chesapeake City, MD",
    city_name: "Chesapeake City",
  },
  {
    postal_code: "14719",
    full_city_name: "Cattaraugus, NY",
    city_name: "Cattaraugus",
  },
  {
    postal_code: "36271",
    full_city_name: "Ohatchee, AL",
    city_name: "Ohatchee",
  },
  {
    postal_code: "93429",
    full_city_name: "Casmalia, CA",
    city_name: "Casmalia",
  },
  {
    postal_code: "59062",
    full_city_name: "Otter, MT",
    city_name: "Otter",
  },
  {
    postal_code: "48649",
    full_city_name: "Oakley, MI",
    city_name: "Oakley",
  },
  {
    postal_code: "30667",
    full_city_name: "Stephens, GA",
    city_name: "Stephens",
  },
  {
    postal_code: "31303",
    full_city_name: "Clyo, GA",
    city_name: "Clyo",
  },
  {
    postal_code: "29717",
    full_city_name: "Hickory Grove, SC",
    city_name: "Hickory Grove",
  },
  {
    postal_code: "79227",
    full_city_name: "Crowell, TX",
    city_name: "Crowell",
  },
  {
    postal_code: "03284",
    full_city_name: "Springfield, NH",
    city_name: "Springfield",
  },
  {
    postal_code: "14825",
    full_city_name: "Chemung, NY",
    city_name: "Chemung",
  },
  {
    postal_code: "67132",
    full_city_name: "Rosalia, KS",
    city_name: "Rosalia",
  },
  {
    postal_code: "19079",
    full_city_name: "Sharon Hill, PA",
    city_name: "Sharon Hill",
  },
  {
    postal_code: "91502",
    full_city_name: "Burbank, CA",
    city_name: "Burbank",
  },
  {
    postal_code: "72663",
    full_city_name: "Onia, AR",
    city_name: "Onia",
  },
  {
    postal_code: "67476",
    full_city_name: "Roxbury, KS",
    city_name: "Roxbury",
  },
  {
    postal_code: "42202",
    full_city_name: "Adairville, KY",
    city_name: "Adairville",
  },
  {
    postal_code: "21657",
    full_city_name: "Queen Anne, MD",
    city_name: "Queen Anne",
  },
  {
    postal_code: "63472",
    full_city_name: "Wayland, MO",
    city_name: "Wayland",
  },
  {
    postal_code: "29613",
    full_city_name: "Greenville, SC",
    city_name: "Greenville",
  },
  {
    postal_code: "78880",
    full_city_name: "Rocksprings, TX",
    city_name: "Rocksprings",
  },
  {
    postal_code: "26714",
    full_city_name: "Delray, WV",
    city_name: "Delray",
  },
  {
    postal_code: "26562",
    full_city_name: "Burton, WV",
    city_name: "Burton",
  },
  {
    postal_code: "91934",
    full_city_name: "Jacumba, CA",
    city_name: "Jacumba",
  },
  {
    postal_code: "29133",
    full_city_name: "Rowesville, SC",
    city_name: "Rowesville",
  },
  {
    postal_code: "05763",
    full_city_name: "Pittsford, VT",
    city_name: "Pittsford",
  },
  {
    postal_code: "38361",
    full_city_name: "Morris Chapel, TN",
    city_name: "Morris Chapel",
  },
  {
    postal_code: "54754",
    full_city_name: "Merrillan, WI",
    city_name: "Merrillan",
  },
  {
    postal_code: "68861",
    full_city_name: "Odessa, NE",
    city_name: "Odessa",
  },
  {
    postal_code: "01929",
    full_city_name: "Essex, MA",
    city_name: "Essex",
  },
  {
    postal_code: "77417",
    full_city_name: "Beasley, TX",
    city_name: "Beasley",
  },
  {
    postal_code: "82711",
    full_city_name: "Alva, WY",
    city_name: "Alva",
  },
  {
    postal_code: "44449",
    full_city_name: "North Benton, OH",
    city_name: "North Benton",
  },
  {
    postal_code: "21837",
    full_city_name: "Mardela Springs, MD",
    city_name: "Mardela Springs",
  },
  {
    postal_code: "47226",
    full_city_name: "Clifford, IN",
    city_name: "Clifford",
  },
  {
    postal_code: "39422",
    full_city_name: "Bay Springs, MS",
    city_name: "Bay Springs",
  },
  {
    postal_code: "70515",
    full_city_name: "Basile, LA",
    city_name: "Basile",
  },
  {
    postal_code: "96752",
    full_city_name: "Kekaha, HI",
    city_name: "Kekaha",
  },
  {
    postal_code: "95934",
    full_city_name: "Crescent Mills, CA",
    city_name: "Crescent Mills",
  },
  {
    postal_code: "50116",
    full_city_name: "Hamilton, IA",
    city_name: "Hamilton",
  },
  {
    postal_code: "13469",
    full_city_name: "Stittville, NY",
    city_name: "Stittville",
  },
  {
    postal_code: "92249",
    full_city_name: "Heber, CA",
    city_name: "Heber",
  },
  {
    postal_code: "08214",
    full_city_name: "Dennisville, NJ",
    city_name: "Dennisville",
  },
  {
    postal_code: "55910",
    full_city_name: "Altura, MN",
    city_name: "Altura",
  },
  {
    postal_code: "55043",
    full_city_name: "Lakeland, MN",
    city_name: "Lakeland",
  },
  {
    postal_code: "64746",
    full_city_name: "Freeman, MO",
    city_name: "Freeman",
  },
  {
    postal_code: "65601",
    full_city_name: "Aldrich, MO",
    city_name: "Aldrich",
  },
  {
    postal_code: "13314",
    full_city_name: "Brookfield, NY",
    city_name: "Brookfield",
  },
  {
    postal_code: "65462",
    full_city_name: "Edgar Springs, MO",
    city_name: "Edgar Springs",
  },
  {
    postal_code: "27018",
    full_city_name: "East Bend, NC",
    city_name: "East Bend",
  },
  {
    postal_code: "47229",
    full_city_name: "Crothersville, IN",
    city_name: "Crothersville",
  },
  {
    postal_code: "29125",
    full_city_name: "Pinewood, SC",
    city_name: "Pinewood",
  },
  {
    postal_code: "68065",
    full_city_name: "Valparaiso, NE",
    city_name: "Valparaiso",
  },
  {
    postal_code: "50835",
    full_city_name: "Benton, IA",
    city_name: "Benton",
  },
  {
    postal_code: "62022",
    full_city_name: "Dow, IL",
    city_name: "Dow",
  },
  {
    postal_code: "06024",
    full_city_name: "East Canaan, CT",
    city_name: "East Canaan",
  },
  {
    postal_code: "98617",
    full_city_name: "Dallesport, WA",
    city_name: "Dallesport",
  },
  {
    postal_code: "43811",
    full_city_name: "Conesville, OH",
    city_name: "Conesville",
  },
  {
    postal_code: "21777",
    full_city_name: "Point Of Rocks, MD",
    city_name: "Point Of Rocks",
  },
  {
    postal_code: "62947",
    full_city_name: "Herod, IL",
    city_name: "Herod",
  },
  {
    postal_code: "03260",
    full_city_name: "North Sutton, NH",
    city_name: "North Sutton",
  },
  {
    postal_code: "95202",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "04352",
    full_city_name: "Mount Vernon, ME",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "15732",
    full_city_name: "Creekside, PA",
    city_name: "Creekside",
  },
  {
    postal_code: "42565",
    full_city_name: "Windsor, KY",
    city_name: "Windsor",
  },
  {
    postal_code: "65591",
    full_city_name: "Montreal, MO",
    city_name: "Montreal",
  },
  {
    postal_code: "76471",
    full_city_name: "Rising Star, TX",
    city_name: "Rising Star",
  },
  {
    postal_code: "73942",
    full_city_name: "Guymon, OK",
    city_name: "Guymon",
  },
  {
    postal_code: "05758",
    full_city_name: "Mount Holly, VT",
    city_name: "Mount Holly",
  },
  {
    postal_code: "78058",
    full_city_name: "Mountain Home, TX",
    city_name: "Mountain Home",
  },
  {
    postal_code: "98645",
    full_city_name: "Silverlake, WA",
    city_name: "Silverlake",
  },
  {
    postal_code: "14034",
    full_city_name: "Collins, NY",
    city_name: "Collins",
  },
  {
    postal_code: "42056",
    full_city_name: "La Center, KY",
    city_name: "La Center",
  },
  {
    postal_code: "67863",
    full_city_name: "Marienthal, KS",
    city_name: "Marienthal",
  },
  {
    postal_code: "57720",
    full_city_name: "Buffalo, SD",
    city_name: "Buffalo",
  },
  {
    postal_code: "37305",
    full_city_name: "Beersheba Springs, TN",
    city_name: "Beersheba Springs",
  },
  {
    postal_code: "17355",
    full_city_name: "Railroad, PA",
    city_name: "Railroad",
  },
  {
    postal_code: "37818",
    full_city_name: "Mosheim, TN",
    city_name: "Mosheim",
  },
  {
    postal_code: "01611",
    full_city_name: "Cherry Valley, MA",
    city_name: "Cherry Valley",
  },
  {
    postal_code: "50424",
    full_city_name: "Buffalo Center, IA",
    city_name: "Buffalo Center",
  },
  {
    postal_code: "96110",
    full_city_name: "Eagleville, CA",
    city_name: "Eagleville",
  },
  {
    postal_code: "21722",
    full_city_name: "Clear Spring, MD",
    city_name: "Clear Spring",
  },
  {
    postal_code: "77837",
    full_city_name: "Calvert, TX",
    city_name: "Calvert",
  },
  {
    postal_code: "28775",
    full_city_name: "Scaly Mountain, NC",
    city_name: "Scaly Mountain",
  },
  {
    postal_code: "38362",
    full_city_name: "Oakfield, TN",
    city_name: "Oakfield",
  },
  {
    postal_code: "72482",
    full_city_name: "Williford, AR",
    city_name: "Williford",
  },
  {
    postal_code: "84013",
    full_city_name: "Cedar Valley, UT",
    city_name: "Cedar Valley",
  },
  {
    postal_code: "61529",
    full_city_name: "Elmwood, IL",
    city_name: "Elmwood",
  },
  {
    postal_code: "03457",
    full_city_name: "Nelson, NH",
    city_name: "Nelson",
  },
  {
    postal_code: "12156",
    full_city_name: "Schodack Landing, NY",
    city_name: "Schodack Landing",
  },
  {
    postal_code: "23962",
    full_city_name: "Randolph, VA",
    city_name: "Randolph",
  },
  {
    postal_code: "27864",
    full_city_name: "Pinetops, NC",
    city_name: "Pinetops",
  },
  {
    postal_code: "38329",
    full_city_name: "Decaturville, TN",
    city_name: "Decaturville",
  },
  {
    postal_code: "73851",
    full_city_name: "May, OK",
    city_name: "May",
  },
  {
    postal_code: "28709",
    full_city_name: "Barnardsville, NC",
    city_name: "Barnardsville",
  },
  {
    postal_code: "28345",
    full_city_name: "Hamlet, NC",
    city_name: "Hamlet",
  },
  {
    postal_code: "16351",
    full_city_name: "Tidioute, PA",
    city_name: "Tidioute",
  },
  {
    postal_code: "45388",
    full_city_name: "Yorkshire, OH",
    city_name: "Yorkshire",
  },
  {
    postal_code: "43787",
    full_city_name: "Stockport, OH",
    city_name: "Stockport",
  },
  {
    postal_code: "95686",
    full_city_name: "Thornton, CA",
    city_name: "Thornton",
  },
  {
    postal_code: "03243",
    full_city_name: "Hill, NH",
    city_name: "Hill",
  },
  {
    postal_code: "62870",
    full_city_name: "Odin, IL",
    city_name: "Odin",
  },
  {
    postal_code: "55925",
    full_city_name: "Dakota, MN",
    city_name: "Dakota",
  },
  {
    postal_code: "97377",
    full_city_name: "Shedd, OR",
    city_name: "Shedd",
  },
  {
    postal_code: "84639",
    full_city_name: "Levan, UT",
    city_name: "Levan",
  },
  {
    postal_code: "24553",
    full_city_name: "Gladstone, VA",
    city_name: "Gladstone",
  },
  {
    postal_code: "84644",
    full_city_name: "Meadow, UT",
    city_name: "Meadow",
  },
  {
    postal_code: "17029",
    full_city_name: "Granville, PA",
    city_name: "Granville",
  },
  {
    postal_code: "55381",
    full_city_name: "Silver Lake, MN",
    city_name: "Silver Lake",
  },
  {
    postal_code: "56311",
    full_city_name: "Barrett, MN",
    city_name: "Barrett",
  },
  {
    postal_code: "69155",
    full_city_name: "Paxton, NE",
    city_name: "Paxton",
  },
  {
    postal_code: "01022",
    full_city_name: "Chicopee, MA",
    city_name: "Chicopee",
  },
  {
    postal_code: "59872",
    full_city_name: "Superior, MT",
    city_name: "Superior",
  },
  {
    postal_code: "96757",
    full_city_name: "Kualapuu, HI",
    city_name: "Kualapuu",
  },
  {
    postal_code: "12008",
    full_city_name: "Alplaus, NY",
    city_name: "Alplaus",
  },
  {
    postal_code: "13495",
    full_city_name: "Yorkville, NY",
    city_name: "Yorkville",
  },
  {
    postal_code: "38620",
    full_city_name: "Courtland, MS",
    city_name: "Courtland",
  },
  {
    postal_code: "38541",
    full_city_name: "Allons, TN",
    city_name: "Allons",
  },
  {
    postal_code: "58438",
    full_city_name: "Fessenden, ND",
    city_name: "Fessenden",
  },
  {
    postal_code: "71833",
    full_city_name: "Dierks, AR",
    city_name: "Dierks",
  },
  {
    postal_code: "01452",
    full_city_name: "Hubbardston, MA",
    city_name: "Hubbardston",
  },
  {
    postal_code: "04290",
    full_city_name: "Peru, ME",
    city_name: "Peru",
  },
  {
    postal_code: "51455",
    full_city_name: "Manning, IA",
    city_name: "Manning",
  },
  {
    postal_code: "19372",
    full_city_name: "Thorndale, PA",
    city_name: "Thorndale",
  },
  {
    postal_code: "56358",
    full_city_name: "Ogilvie, MN",
    city_name: "Ogilvie",
  },
  {
    postal_code: "04351",
    full_city_name: "Manchester, ME",
    city_name: "Manchester",
  },
  {
    postal_code: "15026",
    full_city_name: "Clinton, PA",
    city_name: "Clinton",
  },
  {
    postal_code: "23922",
    full_city_name: "Burkeville, VA",
    city_name: "Burkeville",
  },
  {
    postal_code: "70067",
    full_city_name: "Lafitte, LA",
    city_name: "Lafitte",
  },
  {
    postal_code: "23123",
    full_city_name: "New Canton, VA",
    city_name: "New Canton",
  },
  {
    postal_code: "61417",
    full_city_name: "Berwick, IL",
    city_name: "Berwick",
  },
  {
    postal_code: "05358",
    full_city_name: "West Halifax, VT",
    city_name: "West Halifax",
  },
  {
    postal_code: "75973",
    full_city_name: "Shelbyville, TX",
    city_name: "Shelbyville",
  },
  {
    postal_code: "36473",
    full_city_name: "Range, AL",
    city_name: "Range",
  },
  {
    postal_code: "47324",
    full_city_name: "Boston, IN",
    city_name: "Boston",
  },
  {
    postal_code: "26750",
    full_city_name: "Piedmont, WV",
    city_name: "Piedmont",
  },
  {
    postal_code: "58540",
    full_city_name: "Garrison, ND",
    city_name: "Garrison",
  },
  {
    postal_code: "05847",
    full_city_name: "Lowell, VT",
    city_name: "Lowell",
  },
  {
    postal_code: "16112",
    full_city_name: "Bessemer, PA",
    city_name: "Bessemer",
  },
  {
    postal_code: "19526",
    full_city_name: "Hamburg, PA",
    city_name: "Hamburg",
  },
  {
    postal_code: "02802",
    full_city_name: "Albion, RI",
    city_name: "Albion",
  },
  {
    postal_code: "16841",
    full_city_name: "Howard, PA",
    city_name: "Howard",
  },
  {
    postal_code: "56527",
    full_city_name: "Deer Creek, MN",
    city_name: "Deer Creek",
  },
  {
    postal_code: "38075",
    full_city_name: "Whiteville, TN",
    city_name: "Whiteville",
  },
  {
    postal_code: "84314",
    full_city_name: "Honeyville, UT",
    city_name: "Honeyville",
  },
  {
    postal_code: "67066",
    full_city_name: "Iuka, KS",
    city_name: "Iuka",
  },
  {
    postal_code: "24529",
    full_city_name: "Buffalo Junction, VA",
    city_name: "Buffalo Junction",
  },
  {
    postal_code: "73460",
    full_city_name: "Tishomingo, OK",
    city_name: "Tishomingo",
  },
  {
    postal_code: "83845",
    full_city_name: "Moyie Springs, ID",
    city_name: "Moyie Springs",
  },
  {
    postal_code: "81415",
    full_city_name: "Crawford, CO",
    city_name: "Crawford",
  },
  {
    postal_code: "24352",
    full_city_name: "Laurel Fork, VA",
    city_name: "Laurel Fork",
  },
  {
    postal_code: "12849",
    full_city_name: "Middle Granville, NY",
    city_name: "Middle Granville",
  },
  {
    postal_code: "61285",
    full_city_name: "Thomson, IL",
    city_name: "Thomson",
  },
  {
    postal_code: "17527",
    full_city_name: "Gap, PA",
    city_name: "Gap",
  },
  {
    postal_code: "50249",
    full_city_name: "Stratford, IA",
    city_name: "Stratford",
  },
  {
    postal_code: "21622",
    full_city_name: "Church Creek, MD",
    city_name: "Church Creek",
  },
  {
    postal_code: "55722",
    full_city_name: "Coleraine, MN",
    city_name: "Coleraine",
  },
  {
    postal_code: "67521",
    full_city_name: "Brownell, KS",
    city_name: "Brownell",
  },
  {
    postal_code: "56237",
    full_city_name: "Echo, MN",
    city_name: "Echo",
  },
  {
    postal_code: "64448",
    full_city_name: "Faucett, MO",
    city_name: "Faucett",
  },
  {
    postal_code: "11239",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "75570",
    full_city_name: "New Boston, TX",
    city_name: "New Boston",
  },
  {
    postal_code: "95930",
    full_city_name: "Clipper Mills, CA",
    city_name: "Clipper Mills",
  },
  {
    postal_code: "97867",
    full_city_name: "North Powder, OR",
    city_name: "North Powder",
  },
  {
    postal_code: "14101",
    full_city_name: "Machias, NY",
    city_name: "Machias",
  },
  {
    postal_code: "12094",
    full_city_name: "Johnsonville, NY",
    city_name: "Johnsonville",
  },
  {
    postal_code: "15349",
    full_city_name: "Mount Morris, PA",
    city_name: "Mount Morris",
  },
  {
    postal_code: "55764",
    full_city_name: "Marble, MN",
    city_name: "Marble",
  },
  {
    postal_code: "58490",
    full_city_name: "Verona, ND",
    city_name: "Verona",
  },
  {
    postal_code: "10459",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "27935",
    full_city_name: "Eure, NC",
    city_name: "Eure",
  },
  {
    postal_code: "15474",
    full_city_name: "Point Marion, PA",
    city_name: "Point Marion",
  },
  {
    postal_code: "51549",
    full_city_name: "Macedonia, IA",
    city_name: "Macedonia",
  },
  {
    postal_code: "44115",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "28090",
    full_city_name: "Lawndale, NC",
    city_name: "Lawndale",
  },
  {
    postal_code: "24830",
    full_city_name: "Elbert, WV",
    city_name: "Elbert",
  },
  {
    postal_code: "04435",
    full_city_name: "Exeter, ME",
    city_name: "Exeter",
  },
  {
    postal_code: "19530",
    full_city_name: "Kutztown, PA",
    city_name: "Kutztown",
  },
  {
    postal_code: "37848",
    full_city_name: "Powder Springs, TN",
    city_name: "Powder Springs",
  },
  {
    postal_code: "31555",
    full_city_name: "Odum, GA",
    city_name: "Odum",
  },
  {
    postal_code: "39149",
    full_city_name: "Pinola, MS",
    city_name: "Pinola",
  },
  {
    postal_code: "78384",
    full_city_name: "San Diego, TX",
    city_name: "San Diego",
  },
  {
    postal_code: "62262",
    full_city_name: "Mulberry Grove, IL",
    city_name: "Mulberry Grove",
  },
  {
    postal_code: "16881",
    full_city_name: "Woodland, PA",
    city_name: "Woodland",
  },
  {
    postal_code: "72532",
    full_city_name: "Evening Shade, AR",
    city_name: "Evening Shade",
  },
  {
    postal_code: "84511",
    full_city_name: "Blanding, UT",
    city_name: "Blanding",
  },
  {
    postal_code: "16731",
    full_city_name: "Eldred, PA",
    city_name: "Eldred",
  },
  {
    postal_code: "84525",
    full_city_name: "Green River, UT",
    city_name: "Green River",
  },
  {
    postal_code: "26033",
    full_city_name: "Cameron, WV",
    city_name: "Cameron",
  },
  {
    postal_code: "32009",
    full_city_name: "Bryceville, FL",
    city_name: "Bryceville",
  },
  {
    postal_code: "03884",
    full_city_name: "Strafford, NH",
    city_name: "Strafford",
  },
  {
    postal_code: "03046",
    full_city_name: "Dunbarton, NH",
    city_name: "Dunbarton",
  },
  {
    postal_code: "45312",
    full_city_name: "Casstown, OH",
    city_name: "Casstown",
  },
  {
    postal_code: "37141",
    full_city_name: "Orlinda, TN",
    city_name: "Orlinda",
  },
  {
    postal_code: "38673",
    full_city_name: "Taylor, MS",
    city_name: "Taylor",
  },
  {
    postal_code: "52252",
    full_city_name: "Langworthy, IA",
    city_name: "Langworthy",
  },
  {
    postal_code: "18041",
    full_city_name: "East Greenville, PA",
    city_name: "East Greenville",
  },
  {
    postal_code: "04061",
    full_city_name: "North Waterboro, ME",
    city_name: "North Waterboro",
  },
  {
    postal_code: "56647",
    full_city_name: "Hines, MN",
    city_name: "Hines",
  },
  {
    postal_code: "80624",
    full_city_name: "Gill, CO",
    city_name: "Gill",
  },
  {
    postal_code: "37888",
    full_city_name: "Washburn, TN",
    city_name: "Washburn",
  },
  {
    postal_code: "55945",
    full_city_name: "Kellogg, MN",
    city_name: "Kellogg",
  },
  {
    postal_code: "56678",
    full_city_name: "Solway, MN",
    city_name: "Solway",
  },
  {
    postal_code: "65064",
    full_city_name: "Olean, MO",
    city_name: "Olean",
  },
  {
    postal_code: "42748",
    full_city_name: "Hodgenville, KY",
    city_name: "Hodgenville",
  },
  {
    postal_code: "43450",
    full_city_name: "Pemberville, OH",
    city_name: "Pemberville",
  },
  {
    postal_code: "16331",
    full_city_name: "Kossuth, PA",
    city_name: "Kossuth",
  },
  {
    postal_code: "45893",
    full_city_name: "Vaughnsville, OH",
    city_name: "Vaughnsville",
  },
  {
    postal_code: "59927",
    full_city_name: "Olney, MT",
    city_name: "Olney",
  },
  {
    postal_code: "12926",
    full_city_name: "Constable, NY",
    city_name: "Constable",
  },
  {
    postal_code: "98288",
    full_city_name: "Skykomish, WA",
    city_name: "Skykomish",
  },
  {
    postal_code: "24901",
    full_city_name: "Lewisburg, WV",
    city_name: "Lewisburg",
  },
  {
    postal_code: "66542",
    full_city_name: "Tecumseh, KS",
    city_name: "Tecumseh",
  },
  {
    postal_code: "69043",
    full_city_name: "Stratton, NE",
    city_name: "Stratton",
  },
  {
    postal_code: "57555",
    full_city_name: "Mission, SD",
    city_name: "Mission",
  },
  {
    postal_code: "87321",
    full_city_name: "Ramah, NM",
    city_name: "Ramah",
  },
  {
    postal_code: "01008",
    full_city_name: "Blandford, MA",
    city_name: "Blandford",
  },
  {
    postal_code: "46346",
    full_city_name: "Kingsford Heights, IN",
    city_name: "Kingsford Heights",
  },
  {
    postal_code: "46186",
    full_city_name: "Wilkinson, IN",
    city_name: "Wilkinson",
  },
  {
    postal_code: "62295",
    full_city_name: "Valmeyer, IL",
    city_name: "Valmeyer",
  },
  {
    postal_code: "43836",
    full_city_name: "Plainfield, OH",
    city_name: "Plainfield",
  },
  {
    postal_code: "18076",
    full_city_name: "Red Hill, PA",
    city_name: "Red Hill",
  },
  {
    postal_code: "75481",
    full_city_name: "Sulphur Bluff, TX",
    city_name: "Sulphur Bluff",
  },
  {
    postal_code: "41004",
    full_city_name: "Brooksville, KY",
    city_name: "Brooksville",
  },
  {
    postal_code: "77455",
    full_city_name: "Louise, TX",
    city_name: "Louise",
  },
  {
    postal_code: "93660",
    full_city_name: "San Joaquin, CA",
    city_name: "San Joaquin",
  },
  {
    postal_code: "16443",
    full_city_name: "West Springfield, PA",
    city_name: "West Springfield",
  },
  {
    postal_code: "43755",
    full_city_name: "Lore City, OH",
    city_name: "Lore City",
  },
  {
    postal_code: "24324",
    full_city_name: "Draper, VA",
    city_name: "Draper",
  },
  {
    postal_code: "79821",
    full_city_name: "Anthony, TX",
    city_name: "Anthony",
  },
  {
    postal_code: "50568",
    full_city_name: "Newell, IA",
    city_name: "Newell",
  },
  {
    postal_code: "63537",
    full_city_name: "Edina, MO",
    city_name: "Edina",
  },
  {
    postal_code: "68514",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "96109",
    full_city_name: "Doyle, CA",
    city_name: "Doyle",
  },
  {
    postal_code: "99157",
    full_city_name: "Northport, WA",
    city_name: "Northport",
  },
  {
    postal_code: "15437",
    full_city_name: "Farmington, PA",
    city_name: "Farmington",
  },
  {
    postal_code: "67512",
    full_city_name: "Alden, KS",
    city_name: "Alden",
  },
  {
    postal_code: "83553",
    full_city_name: "Weippe, ID",
    city_name: "Weippe",
  },
  {
    postal_code: "23950",
    full_city_name: "La Crosse, VA",
    city_name: "La Crosse",
  },
  {
    postal_code: "05759",
    full_city_name: "North Clarendon, VT",
    city_name: "North Clarendon",
  },
  {
    postal_code: "10516",
    full_city_name: "Cold Spring, NY",
    city_name: "Cold Spring",
  },
  {
    postal_code: "43939",
    full_city_name: "Mount Pleasant, OH",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "03844",
    full_city_name: "Hampton Falls, NH",
    city_name: "Hampton Falls",
  },
  {
    postal_code: "21835",
    full_city_name: "Linkwood, MD",
    city_name: "Linkwood",
  },
  {
    postal_code: "77420",
    full_city_name: "Boling, TX",
    city_name: "Boling",
  },
  {
    postal_code: "49261",
    full_city_name: "Napoleon, MI",
    city_name: "Napoleon",
  },
  {
    postal_code: "26520",
    full_city_name: "Arthurdale, WV",
    city_name: "Arthurdale",
  },
  {
    postal_code: "03840",
    full_city_name: "Greenland, NH",
    city_name: "Greenland",
  },
  {
    postal_code: "56469",
    full_city_name: "Palisade, MN",
    city_name: "Palisade",
  },
  {
    postal_code: "94937",
    full_city_name: "Inverness, CA",
    city_name: "Inverness",
  },
  {
    postal_code: "77982",
    full_city_name: "Port O Connor, TX",
    city_name: "Port O Connor",
  },
  {
    postal_code: "79366",
    full_city_name: "Ransom Canyon, TX",
    city_name: "Ransom Canyon",
  },
  {
    postal_code: "70390",
    full_city_name: "Napoleonville, LA",
    city_name: "Napoleonville",
  },
  {
    postal_code: "43723",
    full_city_name: "Byesville, OH",
    city_name: "Byesville",
  },
  {
    postal_code: "41514",
    full_city_name: "Belfry, KY",
    city_name: "Belfry",
  },
  {
    postal_code: "05862",
    full_city_name: "Peacham, VT",
    city_name: "Peacham",
  },
  {
    postal_code: "50475",
    full_city_name: "Sheffield, IA",
    city_name: "Sheffield",
  },
  {
    postal_code: "54725",
    full_city_name: "Boyceville, WI",
    city_name: "Boyceville",
  },
  {
    postal_code: "41568",
    full_city_name: "Stopover, KY",
    city_name: "Stopover",
  },
  {
    postal_code: "62219",
    full_city_name: "Beckemeyer, IL",
    city_name: "Beckemeyer",
  },
  {
    postal_code: "41041",
    full_city_name: "Flemingsburg, KY",
    city_name: "Flemingsburg",
  },
  {
    postal_code: "62354",
    full_city_name: "Nauvoo, IL",
    city_name: "Nauvoo",
  },
  {
    postal_code: "31077",
    full_city_name: "Rhine, GA",
    city_name: "Rhine",
  },
  {
    postal_code: "56047",
    full_city_name: "Huntley, MN",
    city_name: "Huntley",
  },
  {
    postal_code: "70031",
    full_city_name: "Ama, LA",
    city_name: "Ama",
  },
  {
    postal_code: "79001",
    full_city_name: "Adrian, TX",
    city_name: "Adrian",
  },
  {
    postal_code: "43451",
    full_city_name: "Portage, OH",
    city_name: "Portage",
  },
  {
    postal_code: "51451",
    full_city_name: "Lanesboro, IA",
    city_name: "Lanesboro",
  },
  {
    postal_code: "61526",
    full_city_name: "Edelstein, IL",
    city_name: "Edelstein",
  },
  {
    postal_code: "84522",
    full_city_name: "Emery, UT",
    city_name: "Emery",
  },
  {
    postal_code: "73440",
    full_city_name: "Lebanon, OK",
    city_name: "Lebanon",
  },
  {
    postal_code: "62028",
    full_city_name: "Elsah, IL",
    city_name: "Elsah",
  },
  {
    postal_code: "59925",
    full_city_name: "Marion, MT",
    city_name: "Marion",
  },
  {
    postal_code: "56312",
    full_city_name: "Belgrade, MN",
    city_name: "Belgrade",
  },
  {
    postal_code: "76693",
    full_city_name: "Wortham, TX",
    city_name: "Wortham",
  },
  {
    postal_code: "61458",
    full_city_name: "Maquon, IL",
    city_name: "Maquon",
  },
  {
    postal_code: "78407",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "56009",
    full_city_name: "Alden, MN",
    city_name: "Alden",
  },
  {
    postal_code: "01464",
    full_city_name: "Shirley, MA",
    city_name: "Shirley",
  },
  {
    postal_code: "43983",
    full_city_name: "Piedmont, OH",
    city_name: "Piedmont",
  },
  {
    postal_code: "38850",
    full_city_name: "Houlka, MS",
    city_name: "Houlka",
  },
  {
    postal_code: "68317",
    full_city_name: "Bennet, NE",
    city_name: "Bennet",
  },
  {
    postal_code: "42234",
    full_city_name: "Guthrie, KY",
    city_name: "Guthrie",
  },
  {
    postal_code: "05161",
    full_city_name: "Weston, VT",
    city_name: "Weston",
  },
  {
    postal_code: "63369",
    full_city_name: "Old Monroe, MO",
    city_name: "Old Monroe",
  },
  {
    postal_code: "26386",
    full_city_name: "Lumberport, WV",
    city_name: "Lumberport",
  },
  {
    postal_code: "74939",
    full_city_name: "Hodgen, OK",
    city_name: "Hodgen",
  },
  {
    postal_code: "68862",
    full_city_name: "Ord, NE",
    city_name: "Ord",
  },
  {
    postal_code: "25502",
    full_city_name: "Apple Grove, WV",
    city_name: "Apple Grove",
  },
  {
    postal_code: "59029",
    full_city_name: "Fromberg, MT",
    city_name: "Fromberg",
  },
  {
    postal_code: "72444",
    full_city_name: "Maynard, AR",
    city_name: "Maynard",
  },
  {
    postal_code: "67118",
    full_city_name: "Norwich, KS",
    city_name: "Norwich",
  },
  {
    postal_code: "18830",
    full_city_name: "Little Meadows, PA",
    city_name: "Little Meadows",
  },
  {
    postal_code: "55606",
    full_city_name: "Hovland, MN",
    city_name: "Hovland",
  },
  {
    postal_code: "29437",
    full_city_name: "Dorchester, SC",
    city_name: "Dorchester",
  },
  {
    postal_code: "45326",
    full_city_name: "Fletcher, OH",
    city_name: "Fletcher",
  },
  {
    postal_code: "68304",
    full_city_name: "Alvo, NE",
    city_name: "Alvo",
  },
  {
    postal_code: "26180",
    full_city_name: "Walker, WV",
    city_name: "Walker",
  },
  {
    postal_code: "43013",
    full_city_name: "Croton, OH",
    city_name: "Croton",
  },
  {
    postal_code: "97344",
    full_city_name: "Falls City, OR",
    city_name: "Falls City",
  },
  {
    postal_code: "03838",
    full_city_name: "Glen, NH",
    city_name: "Glen",
  },
  {
    postal_code: "97108",
    full_city_name: "Beaver, OR",
    city_name: "Beaver",
  },
  {
    postal_code: "05681",
    full_city_name: "Woodbury, VT",
    city_name: "Woodbury",
  },
  {
    postal_code: "56350",
    full_city_name: "Mc Grath, MN",
    city_name: "Mc Grath",
  },
  {
    postal_code: "37770",
    full_city_name: "Lancing, TN",
    city_name: "Lancing",
  },
  {
    postal_code: "53809",
    full_city_name: "Fennimore, WI",
    city_name: "Fennimore",
  },
  {
    postal_code: "25825",
    full_city_name: "Cool Ridge, WV",
    city_name: "Cool Ridge",
  },
  {
    postal_code: "21612",
    full_city_name: "Bozman, MD",
    city_name: "Bozman",
  },
  {
    postal_code: "52208",
    full_city_name: "Belle Plaine, IA",
    city_name: "Belle Plaine",
  },
  {
    postal_code: "23923",
    full_city_name: "Charlotte Court House, VA",
    city_name: "Charlotte Court House",
  },
  {
    postal_code: "12458",
    full_city_name: "Napanoch, NY",
    city_name: "Napanoch",
  },
  {
    postal_code: "23175",
    full_city_name: "Urbanna, VA",
    city_name: "Urbanna",
  },
  {
    postal_code: "01105",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "47848",
    full_city_name: "Dugger, IN",
    city_name: "Dugger",
  },
  {
    postal_code: "72201",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "01073",
    full_city_name: "Southampton, MA",
    city_name: "Southampton",
  },
  {
    postal_code: "28384",
    full_city_name: "Saint Pauls, NC",
    city_name: "Saint Pauls",
  },
  {
    postal_code: "06782",
    full_city_name: "Plymouth, CT",
    city_name: "Plymouth",
  },
  {
    postal_code: "08061",
    full_city_name: "Mount Royal, NJ",
    city_name: "Mount Royal",
  },
  {
    postal_code: "56149",
    full_city_name: "Lake Benton, MN",
    city_name: "Lake Benton",
  },
  {
    postal_code: "65681",
    full_city_name: "Lampe, MO",
    city_name: "Lampe",
  },
  {
    postal_code: "49411",
    full_city_name: "Free Soil, MI",
    city_name: "Free Soil",
  },
  {
    postal_code: "98304",
    full_city_name: "Ashford, WA",
    city_name: "Ashford",
  },
  {
    postal_code: "88339",
    full_city_name: "Mayhill, NM",
    city_name: "Mayhill",
  },
  {
    postal_code: "81524",
    full_city_name: "Loma, CO",
    city_name: "Loma",
  },
  {
    postal_code: "04563",
    full_city_name: "Cushing, ME",
    city_name: "Cushing",
  },
  {
    postal_code: "38916",
    full_city_name: "Calhoun City, MS",
    city_name: "Calhoun City",
  },
  {
    postal_code: "74764",
    full_city_name: "Valliant, OK",
    city_name: "Valliant",
  },
  {
    postal_code: "48449",
    full_city_name: "Lennon, MI",
    city_name: "Lennon",
  },
  {
    postal_code: "04773",
    full_city_name: "Saint David, ME",
    city_name: "Saint David",
  },
  {
    postal_code: "40150",
    full_city_name: "Lebanon Junction, KY",
    city_name: "Lebanon Junction",
  },
  {
    postal_code: "16249",
    full_city_name: "Rural Valley, PA",
    city_name: "Rural Valley",
  },
  {
    postal_code: "94018",
    full_city_name: "El Granada, CA",
    city_name: "El Granada",
  },
  {
    postal_code: "48137",
    full_city_name: "Gregory, MI",
    city_name: "Gregory",
  },
  {
    postal_code: "43156",
    full_city_name: "Tarlton, OH",
    city_name: "Tarlton",
  },
  {
    postal_code: "14826",
    full_city_name: "Cohocton, NY",
    city_name: "Cohocton",
  },
  {
    postal_code: "50450",
    full_city_name: "Lake Mills, IA",
    city_name: "Lake Mills",
  },
  {
    postal_code: "72717",
    full_city_name: "Canehill, AR",
    city_name: "Canehill",
  },
  {
    postal_code: "56181",
    full_city_name: "Welcome, MN",
    city_name: "Welcome",
  },
  {
    postal_code: "17752",
    full_city_name: "Montgomery, PA",
    city_name: "Montgomery",
  },
  {
    postal_code: "03281",
    full_city_name: "Weare, NH",
    city_name: "Weare",
  },
  {
    postal_code: "62205",
    full_city_name: "East Saint Louis, IL",
    city_name: "East Saint Louis",
  },
  {
    postal_code: "59041",
    full_city_name: "Joliet, MT",
    city_name: "Joliet",
  },
  {
    postal_code: "65017",
    full_city_name: "Brumley, MO",
    city_name: "Brumley",
  },
  {
    postal_code: "64084",
    full_city_name: "Rayville, MO",
    city_name: "Rayville",
  },
  {
    postal_code: "78375",
    full_city_name: "Premont, TX",
    city_name: "Premont",
  },
  {
    postal_code: "21610",
    full_city_name: "Betterton, MD",
    city_name: "Betterton",
  },
  {
    postal_code: "47041",
    full_city_name: "Sunman, IN",
    city_name: "Sunman",
  },
  {
    postal_code: "12956",
    full_city_name: "Mineville, NY",
    city_name: "Mineville",
  },
  {
    postal_code: "15618",
    full_city_name: "Avonmore, PA",
    city_name: "Avonmore",
  },
  {
    postal_code: "95641",
    full_city_name: "Isleton, CA",
    city_name: "Isleton",
  },
  {
    postal_code: "61277",
    full_city_name: "Prophetstown, IL",
    city_name: "Prophetstown",
  },
  {
    postal_code: "02032",
    full_city_name: "East Walpole, MA",
    city_name: "East Walpole",
  },
  {
    postal_code: "29374",
    full_city_name: "Pauline, SC",
    city_name: "Pauline",
  },
  {
    postal_code: "01985",
    full_city_name: "West Newbury, MA",
    city_name: "West Newbury",
  },
  {
    postal_code: "74081",
    full_city_name: "Terlton, OK",
    city_name: "Terlton",
  },
  {
    postal_code: "91204",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "63036",
    full_city_name: "French Village, MO",
    city_name: "French Village",
  },
  {
    postal_code: "67637",
    full_city_name: "Ellis, KS",
    city_name: "Ellis",
  },
  {
    postal_code: "04030",
    full_city_name: "East Waterboro, ME",
    city_name: "East Waterboro",
  },
  {
    postal_code: "46044",
    full_city_name: "Frankton, IN",
    city_name: "Frankton",
  },
  {
    postal_code: "38475",
    full_city_name: "Olivehill, TN",
    city_name: "Olivehill",
  },
  {
    postal_code: "25520",
    full_city_name: "Glenwood, WV",
    city_name: "Glenwood",
  },
  {
    postal_code: "58788",
    full_city_name: "Towner, ND",
    city_name: "Towner",
  },
  {
    postal_code: "45314",
    full_city_name: "Cedarville, OH",
    city_name: "Cedarville",
  },
  {
    postal_code: "13693",
    full_city_name: "Three Mile Bay, NY",
    city_name: "Three Mile Bay",
  },
  {
    postal_code: "49343",
    full_city_name: "Sand Lake, MI",
    city_name: "Sand Lake",
  },
  {
    postal_code: "41095",
    full_city_name: "Warsaw, KY",
    city_name: "Warsaw",
  },
  {
    postal_code: "93066",
    full_city_name: "Somis, CA",
    city_name: "Somis",
  },
  {
    postal_code: "02671",
    full_city_name: "West Harwich, MA",
    city_name: "West Harwich",
  },
  {
    postal_code: "92282",
    full_city_name: "Whitewater, CA",
    city_name: "Whitewater",
  },
  {
    postal_code: "80456",
    full_city_name: "Jefferson, CO",
    city_name: "Jefferson",
  },
  {
    postal_code: "05440",
    full_city_name: "Alburgh, VT",
    city_name: "Alburgh",
  },
  {
    postal_code: "76064",
    full_city_name: "Maypearl, TX",
    city_name: "Maypearl",
  },
  {
    postal_code: "01077",
    full_city_name: "Southwick, MA",
    city_name: "Southwick",
  },
  {
    postal_code: "84631",
    full_city_name: "Fillmore, UT",
    city_name: "Fillmore",
  },
  {
    postal_code: "37050",
    full_city_name: "Cumberland City, TN",
    city_name: "Cumberland City",
  },
  {
    postal_code: "61011",
    full_city_name: "Caledonia, IL",
    city_name: "Caledonia",
  },
  {
    postal_code: "38057",
    full_city_name: "Moscow, TN",
    city_name: "Moscow",
  },
  {
    postal_code: "45784",
    full_city_name: "Vincent, OH",
    city_name: "Vincent",
  },
  {
    postal_code: "01330",
    full_city_name: "Ashfield, MA",
    city_name: "Ashfield",
  },
  {
    postal_code: "50140",
    full_city_name: "Lamoni, IA",
    city_name: "Lamoni",
  },
  {
    postal_code: "03221",
    full_city_name: "Bradford, NH",
    city_name: "Bradford",
  },
  {
    postal_code: "85034",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "32424",
    full_city_name: "Blountstown, FL",
    city_name: "Blountstown",
  },
  {
    postal_code: "54642",
    full_city_name: "Melrose, WI",
    city_name: "Melrose",
  },
  {
    postal_code: "65786",
    full_city_name: "Macks Creek, MO",
    city_name: "Macks Creek",
  },
  {
    postal_code: "15142",
    full_city_name: "Presto, PA",
    city_name: "Presto",
  },
  {
    postal_code: "27812",
    full_city_name: "Bethel, NC",
    city_name: "Bethel",
  },
  {
    postal_code: "60072",
    full_city_name: "Ringwood, IL",
    city_name: "Ringwood",
  },
  {
    postal_code: "21869",
    full_city_name: "Vienna, MD",
    city_name: "Vienna",
  },
  {
    postal_code: "01050",
    full_city_name: "Huntington, MA",
    city_name: "Huntington",
  },
  {
    postal_code: "35063",
    full_city_name: "Empire, AL",
    city_name: "Empire",
  },
  {
    postal_code: "68933",
    full_city_name: "Clay Center, NE",
    city_name: "Clay Center",
  },
  {
    postal_code: "02121",
    full_city_name: "Dorchester, MA",
    city_name: "Dorchester",
  },
  {
    postal_code: "28033",
    full_city_name: "Crouse, NC",
    city_name: "Crouse",
  },
  {
    postal_code: "01944",
    full_city_name: "Manchester, MA",
    city_name: "Manchester",
  },
  {
    postal_code: "65566",
    full_city_name: "Viburnum, MO",
    city_name: "Viburnum",
  },
  {
    postal_code: "23149",
    full_city_name: "Saluda, VA",
    city_name: "Saluda",
  },
  {
    postal_code: "64633",
    full_city_name: "Carrollton, MO",
    city_name: "Carrollton",
  },
  {
    postal_code: "23954",
    full_city_name: "Meherrin, VA",
    city_name: "Meherrin",
  },
  {
    postal_code: "17365",
    full_city_name: "Wellsville, PA",
    city_name: "Wellsville",
  },
  {
    postal_code: "14873",
    full_city_name: "Prattsburgh, NY",
    city_name: "Prattsburgh",
  },
  {
    postal_code: "14803",
    full_city_name: "Alfred Station, NY",
    city_name: "Alfred Station",
  },
  {
    postal_code: "16939",
    full_city_name: "Morris Run, PA",
    city_name: "Morris Run",
  },
  {
    postal_code: "54893",
    full_city_name: "Webster, WI",
    city_name: "Webster",
  },
  {
    postal_code: "20818",
    full_city_name: "Cabin John, MD",
    city_name: "Cabin John",
  },
  {
    postal_code: "44217",
    full_city_name: "Creston, OH",
    city_name: "Creston",
  },
  {
    postal_code: "16154",
    full_city_name: "Transfer, PA",
    city_name: "Transfer",
  },
  {
    postal_code: "42048",
    full_city_name: "Hardin, KY",
    city_name: "Hardin",
  },
  {
    postal_code: "53820",
    full_city_name: "Potosi, WI",
    city_name: "Potosi",
  },
  {
    postal_code: "49452",
    full_city_name: "Rothbury, MI",
    city_name: "Rothbury",
  },
  {
    postal_code: "70372",
    full_city_name: "Labadieville, LA",
    city_name: "Labadieville",
  },
  {
    postal_code: "03873",
    full_city_name: "Sandown, NH",
    city_name: "Sandown",
  },
  {
    postal_code: "56360",
    full_city_name: "Osakis, MN",
    city_name: "Osakis",
  },
  {
    postal_code: "96073",
    full_city_name: "Palo Cedro, CA",
    city_name: "Palo Cedro",
  },
  {
    postal_code: "16925",
    full_city_name: "Gillett, PA",
    city_name: "Gillett",
  },
  {
    postal_code: "45856",
    full_city_name: "Leipsic, OH",
    city_name: "Leipsic",
  },
  {
    postal_code: "03835",
    full_city_name: "Farmington, NH",
    city_name: "Farmington",
  },
  {
    postal_code: "41059",
    full_city_name: "Melbourne, KY",
    city_name: "Melbourne",
  },
  {
    postal_code: "46056",
    full_city_name: "Markleville, IN",
    city_name: "Markleville",
  },
  {
    postal_code: "14432",
    full_city_name: "Clifton Springs, NY",
    city_name: "Clifton Springs",
  },
  {
    postal_code: "58631",
    full_city_name: "Glen Ullin, ND",
    city_name: "Glen Ullin",
  },
  {
    postal_code: "21161",
    full_city_name: "White Hall, MD",
    city_name: "White Hall",
  },
  {
    postal_code: "92250",
    full_city_name: "Holtville, CA",
    city_name: "Holtville",
  },
  {
    postal_code: "01741",
    full_city_name: "Carlisle, MA",
    city_name: "Carlisle",
  },
  {
    postal_code: "05070",
    full_city_name: "South Strafford, VT",
    city_name: "South Strafford",
  },
  {
    postal_code: "85632",
    full_city_name: "San Simon, AZ",
    city_name: "San Simon",
  },
  {
    postal_code: "28635",
    full_city_name: "Hays, NC",
    city_name: "Hays",
  },
  {
    postal_code: "37762",
    full_city_name: "Jellico, TN",
    city_name: "Jellico",
  },
  {
    postal_code: "13407",
    full_city_name: "Mohawk, NY",
    city_name: "Mohawk",
  },
  {
    postal_code: "16360",
    full_city_name: "Townville, PA",
    city_name: "Townville",
  },
  {
    postal_code: "97635",
    full_city_name: "New Pine Creek, OR",
    city_name: "New Pine Creek",
  },
  {
    postal_code: "94586",
    full_city_name: "Sunol, CA",
    city_name: "Sunol",
  },
  {
    postal_code: "05667",
    full_city_name: "Plainfield, VT",
    city_name: "Plainfield",
  },
  {
    postal_code: "72422",
    full_city_name: "Corning, AR",
    city_name: "Corning",
  },
  {
    postal_code: "52141",
    full_city_name: "Elgin, IA",
    city_name: "Elgin",
  },
  {
    postal_code: "67642",
    full_city_name: "Hill City, KS",
    city_name: "Hill City",
  },
  {
    postal_code: "65348",
    full_city_name: "Otterville, MO",
    city_name: "Otterville",
  },
  {
    postal_code: "04274",
    full_city_name: "Poland, ME",
    city_name: "Poland",
  },
  {
    postal_code: "38222",
    full_city_name: "Buchanan, TN",
    city_name: "Buchanan",
  },
  {
    postal_code: "32113",
    full_city_name: "Citra, FL",
    city_name: "Citra",
  },
  {
    postal_code: "55965",
    full_city_name: "Preston, MN",
    city_name: "Preston",
  },
  {
    postal_code: "69358",
    full_city_name: "Morrill, NE",
    city_name: "Morrill",
  },
  {
    postal_code: "50466",
    full_city_name: "Riceville, IA",
    city_name: "Riceville",
  },
  {
    postal_code: "63736",
    full_city_name: "Benton, MO",
    city_name: "Benton",
  },
  {
    postal_code: "55368",
    full_city_name: "Norwood Young America, MN",
    city_name: "Norwood Young America",
  },
  {
    postal_code: "43901",
    full_city_name: "Adena, OH",
    city_name: "Adena",
  },
  {
    postal_code: "42259",
    full_city_name: "Mammoth Cave, KY",
    city_name: "Mammoth Cave",
  },
  {
    postal_code: "75438",
    full_city_name: "Dodd City, TX",
    city_name: "Dodd City",
  },
  {
    postal_code: "75436",
    full_city_name: "Detroit, TX",
    city_name: "Detroit",
  },
  {
    postal_code: "40419",
    full_city_name: "Crab Orchard, KY",
    city_name: "Crab Orchard",
  },
  {
    postal_code: "04785",
    full_city_name: "Van Buren, ME",
    city_name: "Van Buren",
  },
  {
    postal_code: "86038",
    full_city_name: "Mormon Lake, AZ",
    city_name: "Mormon Lake",
  },
  {
    postal_code: "72843",
    full_city_name: "Hector, AR",
    city_name: "Hector",
  },
  {
    postal_code: "78061",
    full_city_name: "Pearsall, TX",
    city_name: "Pearsall",
  },
  {
    postal_code: "79104",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "30105",
    full_city_name: "Armuchee, GA",
    city_name: "Armuchee",
  },
  {
    postal_code: "13744",
    full_city_name: "Castle Creek, NY",
    city_name: "Castle Creek",
  },
  {
    postal_code: "04544",
    full_city_name: "East Boothbay, ME",
    city_name: "East Boothbay",
  },
  {
    postal_code: "23845",
    full_city_name: "Ebony, VA",
    city_name: "Ebony",
  },
  {
    postal_code: "62060",
    full_city_name: "Madison, IL",
    city_name: "Madison",
  },
  {
    postal_code: "65734",
    full_city_name: "Purdy, MO",
    city_name: "Purdy",
  },
  {
    postal_code: "12153",
    full_city_name: "Sand Lake, NY",
    city_name: "Sand Lake",
  },
  {
    postal_code: "84654",
    full_city_name: "Salina, UT",
    city_name: "Salina",
  },
  {
    postal_code: "24586",
    full_city_name: "Ringgold, VA",
    city_name: "Ringgold",
  },
  {
    postal_code: "05033",
    full_city_name: "Bradford, VT",
    city_name: "Bradford",
  },
  {
    postal_code: "93205",
    full_city_name: "Bodfish, CA",
    city_name: "Bodfish",
  },
  {
    postal_code: "16410",
    full_city_name: "Cranesville, PA",
    city_name: "Cranesville",
  },
  {
    postal_code: "14859",
    full_city_name: "Lockwood, NY",
    city_name: "Lockwood",
  },
  {
    postal_code: "78011",
    full_city_name: "Charlotte, TX",
    city_name: "Charlotte",
  },
  {
    postal_code: "51577",
    full_city_name: "Walnut, IA",
    city_name: "Walnut",
  },
  {
    postal_code: "61031",
    full_city_name: "Franklin Grove, IL",
    city_name: "Franklin Grove",
  },
  {
    postal_code: "29009",
    full_city_name: "Bethune, SC",
    city_name: "Bethune",
  },
  {
    postal_code: "20690",
    full_city_name: "Tall Timbers, MD",
    city_name: "Tall Timbers",
  },
  {
    postal_code: "23156",
    full_city_name: "Shacklefords, VA",
    city_name: "Shacklefords",
  },
  {
    postal_code: "21562",
    full_city_name: "Westernport, MD",
    city_name: "Westernport",
  },
  {
    postal_code: "43973",
    full_city_name: "Freeport, OH",
    city_name: "Freeport",
  },
  {
    postal_code: "56143",
    full_city_name: "Jackson, MN",
    city_name: "Jackson",
  },
  {
    postal_code: "02718",
    full_city_name: "East Taunton, MA",
    city_name: "East Taunton",
  },
  {
    postal_code: "65647",
    full_city_name: "Exeter, MO",
    city_name: "Exeter",
  },
  {
    postal_code: "62853",
    full_city_name: "Kell, IL",
    city_name: "Kell",
  },
  {
    postal_code: "05038",
    full_city_name: "Chelsea, VT",
    city_name: "Chelsea",
  },
  {
    postal_code: "65644",
    full_city_name: "Elkland, MO",
    city_name: "Elkland",
  },
  {
    postal_code: "48851",
    full_city_name: "Lyons, MI",
    city_name: "Lyons",
  },
  {
    postal_code: "16743",
    full_city_name: "Port Allegany, PA",
    city_name: "Port Allegany",
  },
  {
    postal_code: "20144",
    full_city_name: "Delaplane, VA",
    city_name: "Delaplane",
  },
  {
    postal_code: "95254",
    full_city_name: "Wallace, CA",
    city_name: "Wallace",
  },
  {
    postal_code: "55090",
    full_city_name: "Willernie, MN",
    city_name: "Willernie",
  },
  {
    postal_code: "17048",
    full_city_name: "Lykens, PA",
    city_name: "Lykens",
  },
  {
    postal_code: "24467",
    full_city_name: "Mount Sidney, VA",
    city_name: "Mount Sidney",
  },
  {
    postal_code: "50625",
    full_city_name: "Dumont, IA",
    city_name: "Dumont",
  },
  {
    postal_code: "28435",
    full_city_name: "Currie, NC",
    city_name: "Currie",
  },
  {
    postal_code: "38356",
    full_city_name: "Medon, TN",
    city_name: "Medon",
  },
  {
    postal_code: "49817",
    full_city_name: "Cooks, MI",
    city_name: "Cooks",
  },
  {
    postal_code: "69024",
    full_city_name: "Culbertson, NE",
    city_name: "Culbertson",
  },
  {
    postal_code: "08823",
    full_city_name: "Franklin Park, NJ",
    city_name: "Franklin Park",
  },
  {
    postal_code: "89440",
    full_city_name: "Virginia City, NV",
    city_name: "Virginia City",
  },
  {
    postal_code: "55917",
    full_city_name: "Blooming Prairie, MN",
    city_name: "Blooming Prairie",
  },
  {
    postal_code: "72428",
    full_city_name: "Etowah, AR",
    city_name: "Etowah",
  },
  {
    postal_code: "28431",
    full_city_name: "Chadbourn, NC",
    city_name: "Chadbourn",
  },
  {
    postal_code: "38856",
    full_city_name: "Marietta, MS",
    city_name: "Marietta",
  },
  {
    postal_code: "98948",
    full_city_name: "Toppenish, WA",
    city_name: "Toppenish",
  },
  {
    postal_code: "05845",
    full_city_name: "Irasburg, VT",
    city_name: "Irasburg",
  },
  {
    postal_code: "46051",
    full_city_name: "Lapel, IN",
    city_name: "Lapel",
  },
  {
    postal_code: "99119",
    full_city_name: "Cusick, WA",
    city_name: "Cusick",
  },
  {
    postal_code: "14807",
    full_city_name: "Arkport, NY",
    city_name: "Arkport",
  },
  {
    postal_code: "43332",
    full_city_name: "La Rue, OH",
    city_name: "La Rue",
  },
  {
    postal_code: "43358",
    full_city_name: "West Mansfield, OH",
    city_name: "West Mansfield",
  },
  {
    postal_code: "05039",
    full_city_name: "Corinth, VT",
    city_name: "Corinth",
  },
  {
    postal_code: "13334",
    full_city_name: "Eaton, NY",
    city_name: "Eaton",
  },
  {
    postal_code: "92408",
    full_city_name: "San Bernardino, CA",
    city_name: "San Bernardino",
  },
  {
    postal_code: "76252",
    full_city_name: "Muenster, TX",
    city_name: "Muenster",
  },
  {
    postal_code: "77564",
    full_city_name: "Hull, TX",
    city_name: "Hull",
  },
  {
    postal_code: "28573",
    full_city_name: "Pollocksville, NC",
    city_name: "Pollocksville",
  },
  {
    postal_code: "62214",
    full_city_name: "Addieville, IL",
    city_name: "Addieville",
  },
  {
    postal_code: "55991",
    full_city_name: "Zumbro Falls, MN",
    city_name: "Zumbro Falls",
  },
  {
    postal_code: "78655",
    full_city_name: "Martindale, TX",
    city_name: "Martindale",
  },
  {
    postal_code: "56714",
    full_city_name: "Badger, MN",
    city_name: "Badger",
  },
  {
    postal_code: "43749",
    full_city_name: "Kimbolton, OH",
    city_name: "Kimbolton",
  },
  {
    postal_code: "95701",
    full_city_name: "Alta, CA",
    city_name: "Alta",
  },
  {
    postal_code: "28521",
    full_city_name: "Chinquapin, NC",
    city_name: "Chinquapin",
  },
  {
    postal_code: "95968",
    full_city_name: "Palermo, CA",
    city_name: "Palermo",
  },
  {
    postal_code: "36013",
    full_city_name: "Cecil, AL",
    city_name: "Cecil",
  },
  {
    postal_code: "45874",
    full_city_name: "Ohio City, OH",
    city_name: "Ohio City",
  },
  {
    postal_code: "14445",
    full_city_name: "East Rochester, NY",
    city_name: "East Rochester",
  },
  {
    postal_code: "54750",
    full_city_name: "Maiden Rock, WI",
    city_name: "Maiden Rock",
  },
  {
    postal_code: "17034",
    full_city_name: "Highspire, PA",
    city_name: "Highspire",
  },
  {
    postal_code: "25248",
    full_city_name: "Kenna, WV",
    city_name: "Kenna",
  },
  {
    postal_code: "50276",
    full_city_name: "Woodward, IA",
    city_name: "Woodward",
  },
  {
    postal_code: "51575",
    full_city_name: "Treynor, IA",
    city_name: "Treynor",
  },
  {
    postal_code: "30137",
    full_city_name: "Emerson, GA",
    city_name: "Emerson",
  },
  {
    postal_code: "78962",
    full_city_name: "Weimar, TX",
    city_name: "Weimar",
  },
  {
    postal_code: "42252",
    full_city_name: "Jetson, KY",
    city_name: "Jetson",
  },
  {
    postal_code: "02467",
    full_city_name: "Chestnut Hill, MA",
    city_name: "Chestnut Hill",
  },
  {
    postal_code: "54739",
    full_city_name: "Elk Mound, WI",
    city_name: "Elk Mound",
  },
  {
    postal_code: "01590",
    full_city_name: "Sutton, MA",
    city_name: "Sutton",
  },
  {
    postal_code: "83311",
    full_city_name: "Albion, ID",
    city_name: "Albion",
  },
  {
    postal_code: "14838",
    full_city_name: "Erin, NY",
    city_name: "Erin",
  },
  {
    postal_code: "95002",
    full_city_name: "Alviso, CA",
    city_name: "Alviso",
  },
  {
    postal_code: "55613",
    full_city_name: "Schroeder, MN",
    city_name: "Schroeder",
  },
  {
    postal_code: "62849",
    full_city_name: "Iuka, IL",
    city_name: "Iuka",
  },
  {
    postal_code: "56634",
    full_city_name: "Clearbrook, MN",
    city_name: "Clearbrook",
  },
  {
    postal_code: "12913",
    full_city_name: "Bloomingdale, NY",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "68349",
    full_city_name: "Elmwood, NE",
    city_name: "Elmwood",
  },
  {
    postal_code: "79837",
    full_city_name: "Dell City, TX",
    city_name: "Dell City",
  },
  {
    postal_code: "50162",
    full_city_name: "Melbourne, IA",
    city_name: "Melbourne",
  },
  {
    postal_code: "70080",
    full_city_name: "Paradis, LA",
    city_name: "Paradis",
  },
  {
    postal_code: "01523",
    full_city_name: "Lancaster, MA",
    city_name: "Lancaster",
  },
  {
    postal_code: "56676",
    full_city_name: "Shevlin, MN",
    city_name: "Shevlin",
  },
  {
    postal_code: "64788",
    full_city_name: "Urich, MO",
    city_name: "Urich",
  },
  {
    postal_code: "71945",
    full_city_name: "Hatfield, AR",
    city_name: "Hatfield",
  },
  {
    postal_code: "50830",
    full_city_name: "Afton, IA",
    city_name: "Afton",
  },
  {
    postal_code: "54950",
    full_city_name: "Marion, WI",
    city_name: "Marion",
  },
  {
    postal_code: "27007",
    full_city_name: "Ararat, NC",
    city_name: "Ararat",
  },
  {
    postal_code: "49814",
    full_city_name: "Champion, MI",
    city_name: "Champion",
  },
  {
    postal_code: "45889",
    full_city_name: "Van Buren, OH",
    city_name: "Van Buren",
  },
  {
    postal_code: "15423",
    full_city_name: "Coal Center, PA",
    city_name: "Coal Center",
  },
  {
    postal_code: "52306",
    full_city_name: "Mechanicsville, IA",
    city_name: "Mechanicsville",
  },
  {
    postal_code: "67480",
    full_city_name: "Solomon, KS",
    city_name: "Solomon",
  },
  {
    postal_code: "46510",
    full_city_name: "Claypool, IN",
    city_name: "Claypool",
  },
  {
    postal_code: "04354",
    full_city_name: "Palermo, ME",
    city_name: "Palermo",
  },
  {
    postal_code: "78164",
    full_city_name: "Yorktown, TX",
    city_name: "Yorktown",
  },
  {
    postal_code: "43360",
    full_city_name: "Zanesfield, OH",
    city_name: "Zanesfield",
  },
  {
    postal_code: "50233",
    full_city_name: "Redfield, IA",
    city_name: "Redfield",
  },
  {
    postal_code: "63653",
    full_city_name: "Leadwood, MO",
    city_name: "Leadwood",
  },
  {
    postal_code: "45321",
    full_city_name: "Eldorado, OH",
    city_name: "Eldorado",
  },
  {
    postal_code: "43516",
    full_city_name: "Deshler, OH",
    city_name: "Deshler",
  },
  {
    postal_code: "64019",
    full_city_name: "Centerview, MO",
    city_name: "Centerview",
  },
  {
    postal_code: "54556",
    full_city_name: "Prentice, WI",
    city_name: "Prentice",
  },
  {
    postal_code: "12175",
    full_city_name: "Summit, NY",
    city_name: "Summit",
  },
  {
    postal_code: "02367",
    full_city_name: "Plympton, MA",
    city_name: "Plympton",
  },
  {
    postal_code: "06755",
    full_city_name: "Gaylordsville, CT",
    city_name: "Gaylordsville",
  },
  {
    postal_code: "15331",
    full_city_name: "Ellsworth, PA",
    city_name: "Ellsworth",
  },
  {
    postal_code: "11948",
    full_city_name: "Laurel, NY",
    city_name: "Laurel",
  },
  {
    postal_code: "44867",
    full_city_name: "Republic, OH",
    city_name: "Republic",
  },
  {
    postal_code: "15935",
    full_city_name: "Hollsopple, PA",
    city_name: "Hollsopple",
  },
  {
    postal_code: "15325",
    full_city_name: "Crucible, PA",
    city_name: "Crucible",
  },
  {
    postal_code: "16401",
    full_city_name: "Albion, PA",
    city_name: "Albion",
  },
  {
    postal_code: "01833",
    full_city_name: "Georgetown, MA",
    city_name: "Georgetown",
  },
  {
    postal_code: "52768",
    full_city_name: "Princeton, IA",
    city_name: "Princeton",
  },
  {
    postal_code: "55339",
    full_city_name: "Hamburg, MN",
    city_name: "Hamburg",
  },
  {
    postal_code: "76673",
    full_city_name: "Mount Calm, TX",
    city_name: "Mount Calm",
  },
  {
    postal_code: "45786",
    full_city_name: "Waterford, OH",
    city_name: "Waterford",
  },
  {
    postal_code: "50072",
    full_city_name: "Earlham, IA",
    city_name: "Earlham",
  },
  {
    postal_code: "27891",
    full_city_name: "Whitakers, NC",
    city_name: "Whitakers",
  },
  {
    postal_code: "80644",
    full_city_name: "Kersey, CO",
    city_name: "Kersey",
  },
  {
    postal_code: "04612",
    full_city_name: "Bernard, ME",
    city_name: "Bernard",
  },
  {
    postal_code: "29940",
    full_city_name: "Seabrook, SC",
    city_name: "Seabrook",
  },
  {
    postal_code: "40820",
    full_city_name: "Cranks, KY",
    city_name: "Cranks",
  },
  {
    postal_code: "18470",
    full_city_name: "Union Dale, PA",
    city_name: "Union Dale",
  },
  {
    postal_code: "80481",
    full_city_name: "Ward, CO",
    city_name: "Ward",
  },
  {
    postal_code: "44626",
    full_city_name: "East Sparta, OH",
    city_name: "East Sparta",
  },
  {
    postal_code: "18077",
    full_city_name: "Riegelsville, PA",
    city_name: "Riegelsville",
  },
  {
    postal_code: "27807",
    full_city_name: "Bailey, NC",
    city_name: "Bailey",
  },
  {
    postal_code: "16157",
    full_city_name: "Wampum, PA",
    city_name: "Wampum",
  },
  {
    postal_code: "45162",
    full_city_name: "Pleasant Plain, OH",
    city_name: "Pleasant Plain",
  },
  {
    postal_code: "01504",
    full_city_name: "Blackstone, MA",
    city_name: "Blackstone",
  },
  {
    postal_code: "04032",
    full_city_name: "Freeport, ME",
    city_name: "Freeport",
  },
  {
    postal_code: "13782",
    full_city_name: "Hamden, NY",
    city_name: "Hamden",
  },
  {
    postal_code: "58652",
    full_city_name: "Richardton, ND",
    city_name: "Richardton",
  },
  {
    postal_code: "46105",
    full_city_name: "Bainbridge, IN",
    city_name: "Bainbridge",
  },
  {
    postal_code: "18463",
    full_city_name: "Sterling, PA",
    city_name: "Sterling",
  },
  {
    postal_code: "01468",
    full_city_name: "Templeton, MA",
    city_name: "Templeton",
  },
  {
    postal_code: "16022",
    full_city_name: "Bruin, PA",
    city_name: "Bruin",
  },
  {
    postal_code: "01524",
    full_city_name: "Leicester, MA",
    city_name: "Leicester",
  },
  {
    postal_code: "01983",
    full_city_name: "Topsfield, MA",
    city_name: "Topsfield",
  },
  {
    postal_code: "97348",
    full_city_name: "Halsey, OR",
    city_name: "Halsey",
  },
  {
    postal_code: "43903",
    full_city_name: "Amsterdam, OH",
    city_name: "Amsterdam",
  },
  {
    postal_code: "20779",
    full_city_name: "Tracys Landing, MD",
    city_name: "Tracys Landing",
  },
  {
    postal_code: "28745",
    full_city_name: "Lake Junaluska, NC",
    city_name: "Lake Junaluska",
  },
  {
    postal_code: "74130",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "15728",
    full_city_name: "Clymer, PA",
    city_name: "Clymer",
  },
  {
    postal_code: "47305",
    full_city_name: "Muncie, IN",
    city_name: "Muncie",
  },
  {
    postal_code: "53098",
    full_city_name: "Watertown, WI",
    city_name: "Watertown",
  },
  {
    postal_code: "97455",
    full_city_name: "Pleasant Hill, OR",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "15626",
    full_city_name: "Delmont, PA",
    city_name: "Delmont",
  },
  {
    postal_code: "62681",
    full_city_name: "Rushville, IL",
    city_name: "Rushville",
  },
  {
    postal_code: "56339",
    full_city_name: "Hoffman, MN",
    city_name: "Hoffman",
  },
  {
    postal_code: "08343",
    full_city_name: "Monroeville, NJ",
    city_name: "Monroeville",
  },
  {
    postal_code: "15344",
    full_city_name: "Jefferson, PA",
    city_name: "Jefferson",
  },
  {
    postal_code: "64761",
    full_city_name: "Leeton, MO",
    city_name: "Leeton",
  },
  {
    postal_code: "55926",
    full_city_name: "Dexter, MN",
    city_name: "Dexter",
  },
  {
    postal_code: "28133",
    full_city_name: "Peachland, NC",
    city_name: "Peachland",
  },
  {
    postal_code: "68940",
    full_city_name: "Funk, NE",
    city_name: "Funk",
  },
  {
    postal_code: "61079",
    full_city_name: "Shirland, IL",
    city_name: "Shirland",
  },
  {
    postal_code: "53951",
    full_city_name: "North Freedom, WI",
    city_name: "North Freedom",
  },
  {
    postal_code: "61078",
    full_city_name: "Shannon, IL",
    city_name: "Shannon",
  },
  {
    postal_code: "84053",
    full_city_name: "Neola, UT",
    city_name: "Neola",
  },
  {
    postal_code: "61018",
    full_city_name: "Dakota, IL",
    city_name: "Dakota",
  },
  {
    postal_code: "45831",
    full_city_name: "Continental, OH",
    city_name: "Continental",
  },
  {
    postal_code: "55089",
    full_city_name: "Welch, MN",
    city_name: "Welch",
  },
  {
    postal_code: "23177",
    full_city_name: "Walkerton, VA",
    city_name: "Walkerton",
  },
  {
    postal_code: "51653",
    full_city_name: "Tabor, IA",
    city_name: "Tabor",
  },
  {
    postal_code: "03601",
    full_city_name: "Acworth, NH",
    city_name: "Acworth",
  },
  {
    postal_code: "84035",
    full_city_name: "Jensen, UT",
    city_name: "Jensen",
  },
  {
    postal_code: "73932",
    full_city_name: "Beaver, OK",
    city_name: "Beaver",
  },
  {
    postal_code: "15059",
    full_city_name: "Midland, PA",
    city_name: "Midland",
  },
  {
    postal_code: "77873",
    full_city_name: "Richards, TX",
    city_name: "Richards",
  },
  {
    postal_code: "20617",
    full_city_name: "Bryantown, MD",
    city_name: "Bryantown",
  },
  {
    postal_code: "01965",
    full_city_name: "Prides Crossing, MA",
    city_name: "Prides Crossing",
  },
  {
    postal_code: "95012",
    full_city_name: "Castroville, CA",
    city_name: "Castroville",
  },
  {
    postal_code: "04910",
    full_city_name: "Albion, ME",
    city_name: "Albion",
  },
  {
    postal_code: "37306",
    full_city_name: "Belvidere, TN",
    city_name: "Belvidere",
  },
  {
    postal_code: "13471",
    full_city_name: "Taberg, NY",
    city_name: "Taberg",
  },
  {
    postal_code: "17240",
    full_city_name: "Newburg, PA",
    city_name: "Newburg",
  },
  {
    postal_code: "42076",
    full_city_name: "New Concord, KY",
    city_name: "New Concord",
  },
  {
    postal_code: "31044",
    full_city_name: "Jeffersonville, GA",
    city_name: "Jeffersonville",
  },
  {
    postal_code: "59831",
    full_city_name: "Dixon, MT",
    city_name: "Dixon",
  },
  {
    postal_code: "16872",
    full_city_name: "Rebersburg, PA",
    city_name: "Rebersburg",
  },
  {
    postal_code: "77880",
    full_city_name: "Washington, TX",
    city_name: "Washington",
  },
  {
    postal_code: "44427",
    full_city_name: "Kensington, OH",
    city_name: "Kensington",
  },
  {
    postal_code: "69021",
    full_city_name: "Benkelman, NE",
    city_name: "Benkelman",
  },
  {
    postal_code: "76652",
    full_city_name: "Kopperl, TX",
    city_name: "Kopperl",
  },
  {
    postal_code: "66724",
    full_city_name: "Cherokee, KS",
    city_name: "Cherokee",
  },
  {
    postal_code: "25286",
    full_city_name: "Walton, WV",
    city_name: "Walton",
  },
  {
    postal_code: "12187",
    full_city_name: "Warnerville, NY",
    city_name: "Warnerville",
  },
  {
    postal_code: "42069",
    full_city_name: "Melber, KY",
    city_name: "Melber",
  },
  {
    postal_code: "85931",
    full_city_name: "Forest Lakes, AZ",
    city_name: "Forest Lakes",
  },
  {
    postal_code: "62858",
    full_city_name: "Louisville, IL",
    city_name: "Louisville",
  },
  {
    postal_code: "92273",
    full_city_name: "Seeley, CA",
    city_name: "Seeley",
  },
  {
    postal_code: "52164",
    full_city_name: "Randalia, IA",
    city_name: "Randalia",
  },
  {
    postal_code: "58051",
    full_city_name: "Kindred, ND",
    city_name: "Kindred",
  },
  {
    postal_code: "01256",
    full_city_name: "Savoy, MA",
    city_name: "Savoy",
  },
  {
    postal_code: "15633",
    full_city_name: "Forbes Road, PA",
    city_name: "Forbes Road",
  },
  {
    postal_code: "67621",
    full_city_name: "Agra, KS",
    city_name: "Agra",
  },
  {
    postal_code: "13074",
    full_city_name: "Hannibal, NY",
    city_name: "Hannibal",
  },
  {
    postal_code: "28515",
    full_city_name: "Bayboro, NC",
    city_name: "Bayboro",
  },
  {
    postal_code: "67671",
    full_city_name: "Victoria, KS",
    city_name: "Victoria",
  },
  {
    postal_code: "99030",
    full_city_name: "Rockford, WA",
    city_name: "Rockford",
  },
  {
    postal_code: "62366",
    full_city_name: "Pleasant Hill, IL",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "93640",
    full_city_name: "Mendota, CA",
    city_name: "Mendota",
  },
  {
    postal_code: "55923",
    full_city_name: "Chatfield, MN",
    city_name: "Chatfield",
  },
  {
    postal_code: "97357",
    full_city_name: "Logsden, OR",
    city_name: "Logsden",
  },
  {
    postal_code: "17087",
    full_city_name: "Richland, PA",
    city_name: "Richland",
  },
  {
    postal_code: "64492",
    full_city_name: "Trimble, MO",
    city_name: "Trimble",
  },
  {
    postal_code: "37134",
    full_city_name: "New Johnsonville, TN",
    city_name: "New Johnsonville",
  },
  {
    postal_code: "67522",
    full_city_name: "Buhler, KS",
    city_name: "Buhler",
  },
  {
    postal_code: "16133",
    full_city_name: "Jackson Center, PA",
    city_name: "Jackson Center",
  },
  {
    postal_code: "61256",
    full_city_name: "Hampton, IL",
    city_name: "Hampton",
  },
  {
    postal_code: "15923",
    full_city_name: "Bolivar, PA",
    city_name: "Bolivar",
  },
  {
    postal_code: "95955",
    full_city_name: "Maxwell, CA",
    city_name: "Maxwell",
  },
  {
    postal_code: "62830",
    full_city_name: "Dix, IL",
    city_name: "Dix",
  },
  {
    postal_code: "59480",
    full_city_name: "Stockett, MT",
    city_name: "Stockett",
  },
  {
    postal_code: "63771",
    full_city_name: "Oran, MO",
    city_name: "Oran",
  },
  {
    postal_code: "05088",
    full_city_name: "Wilder, VT",
    city_name: "Wilder",
  },
  {
    postal_code: "21780",
    full_city_name: "Sabillasville, MD",
    city_name: "Sabillasville",
  },
  {
    postal_code: "37694",
    full_city_name: "Watauga, TN",
    city_name: "Watauga",
  },
  {
    postal_code: "56667",
    full_city_name: "Puposky, MN",
    city_name: "Puposky",
  },
  {
    postal_code: "56060",
    full_city_name: "Lewisville, MN",
    city_name: "Lewisville",
  },
  {
    postal_code: "43557",
    full_city_name: "Stryker, OH",
    city_name: "Stryker",
  },
  {
    postal_code: "71286",
    full_city_name: "Transylvania, LA",
    city_name: "Transylvania",
  },
  {
    postal_code: "45773",
    full_city_name: "Reno, OH",
    city_name: "Reno",
  },
  {
    postal_code: "52530",
    full_city_name: "Agency, IA",
    city_name: "Agency",
  },
  {
    postal_code: "67850",
    full_city_name: "Healy, KS",
    city_name: "Healy",
  },
  {
    postal_code: "62851",
    full_city_name: "Keenes, IL",
    city_name: "Keenes",
  },
  {
    postal_code: "62352",
    full_city_name: "Milton, IL",
    city_name: "Milton",
  },
  {
    postal_code: "30285",
    full_city_name: "The Rock, GA",
    city_name: "The Rock",
  },
  {
    postal_code: "95369",
    full_city_name: "Snelling, CA",
    city_name: "Snelling",
  },
  {
    postal_code: "47227",
    full_city_name: "Commiskey, IN",
    city_name: "Commiskey",
  },
  {
    postal_code: "25535",
    full_city_name: "Lavalette, WV",
    city_name: "Lavalette",
  },
  {
    postal_code: "55797",
    full_city_name: "Wrenshall, MN",
    city_name: "Wrenshall",
  },
  {
    postal_code: "56041",
    full_city_name: "Hanska, MN",
    city_name: "Hanska",
  },
  {
    postal_code: "15945",
    full_city_name: "Parkhill, PA",
    city_name: "Parkhill",
  },
  {
    postal_code: "77468",
    full_city_name: "Pledger, TX",
    city_name: "Pledger",
  },
  {
    postal_code: "60424",
    full_city_name: "Gardner, IL",
    city_name: "Gardner",
  },
  {
    postal_code: "12431",
    full_city_name: "Freehold, NY",
    city_name: "Freehold",
  },
  {
    postal_code: "12823",
    full_city_name: "Cossayuna, NY",
    city_name: "Cossayuna",
  },
  {
    postal_code: "45882",
    full_city_name: "Rockford, OH",
    city_name: "Rockford",
  },
  {
    postal_code: "56686",
    full_city_name: "Williams, MN",
    city_name: "Williams",
  },
  {
    postal_code: "38961",
    full_city_name: "Tillatoba, MS",
    city_name: "Tillatoba",
  },
  {
    postal_code: "43943",
    full_city_name: "Rayland, OH",
    city_name: "Rayland",
  },
  {
    postal_code: "01522",
    full_city_name: "Jefferson, MA",
    city_name: "Jefferson",
  },
  {
    postal_code: "43501",
    full_city_name: "Alvordton, OH",
    city_name: "Alvordton",
  },
  {
    postal_code: "54152",
    full_city_name: "Nichols, WI",
    city_name: "Nichols",
  },
  {
    postal_code: "43084",
    full_city_name: "Woodstock, OH",
    city_name: "Woodstock",
  },
  {
    postal_code: "68452",
    full_city_name: "Ong, NE",
    city_name: "Ong",
  },
  {
    postal_code: "50139",
    full_city_name: "Lacona, IA",
    city_name: "Lacona",
  },
  {
    postal_code: "65571",
    full_city_name: "Summersville, MO",
    city_name: "Summersville",
  },
  {
    postal_code: "68063",
    full_city_name: "Uehling, NE",
    city_name: "Uehling",
  },
  {
    postal_code: "53827",
    full_city_name: "Woodman, WI",
    city_name: "Woodman",
  },
  {
    postal_code: "15051",
    full_city_name: "Indianola, PA",
    city_name: "Indianola",
  },
  {
    postal_code: "02452",
    full_city_name: "Waltham, MA",
    city_name: "Waltham",
  },
  {
    postal_code: "66941",
    full_city_name: "Esbon, KS",
    city_name: "Esbon",
  },
  {
    postal_code: "01901",
    full_city_name: "Lynn, MA",
    city_name: "Lynn",
  },
  {
    postal_code: "93545",
    full_city_name: "Lone Pine, CA",
    city_name: "Lone Pine",
  },
  {
    postal_code: "04555",
    full_city_name: "Nobleboro, ME",
    city_name: "Nobleboro",
  },
  {
    postal_code: "15358",
    full_city_name: "Richeyville, PA",
    city_name: "Richeyville",
  },
  {
    postal_code: "53537",
    full_city_name: "Footville, WI",
    city_name: "Footville",
  },
  {
    postal_code: "36775",
    full_city_name: "Sardis, AL",
    city_name: "Sardis",
  },
  {
    postal_code: "50210",
    full_city_name: "New Virginia, IA",
    city_name: "New Virginia",
  },
  {
    postal_code: "73762",
    full_city_name: "Okarche, OK",
    city_name: "Okarche",
  },
  {
    postal_code: "49934",
    full_city_name: "Hubbell, MI",
    city_name: "Hubbell",
  },
  {
    postal_code: "61839",
    full_city_name: "De Land, IL",
    city_name: "De Land",
  },
  {
    postal_code: "68031",
    full_city_name: "Hooper, NE",
    city_name: "Hooper",
  },
  {
    postal_code: "13748",
    full_city_name: "Conklin, NY",
    city_name: "Conklin",
  },
  {
    postal_code: "56155",
    full_city_name: "Lismore, MN",
    city_name: "Lismore",
  },
  {
    postal_code: "78355",
    full_city_name: "Falfurrias, TX",
    city_name: "Falfurrias",
  },
  {
    postal_code: "74060",
    full_city_name: "Prue, OK",
    city_name: "Prue",
  },
  {
    postal_code: "82923",
    full_city_name: "Boulder, WY",
    city_name: "Boulder",
  },
  {
    postal_code: "51347",
    full_city_name: "Lake Park, IA",
    city_name: "Lake Park",
  },
  {
    postal_code: "55768",
    full_city_name: "Mountain Iron, MN",
    city_name: "Mountain Iron",
  },
  {
    postal_code: "62903",
    full_city_name: "Carbondale, IL",
    city_name: "Carbondale",
  },
  {
    postal_code: "76077",
    full_city_name: "Rainbow, TX",
    city_name: "Rainbow",
  },
  {
    postal_code: "15936",
    full_city_name: "Hooversville, PA",
    city_name: "Hooversville",
  },
  {
    postal_code: "02877",
    full_city_name: "Slocum, RI",
    city_name: "Slocum",
  },
  {
    postal_code: "68832",
    full_city_name: "Doniphan, NE",
    city_name: "Doniphan",
  },
  {
    postal_code: "47922",
    full_city_name: "Brook, IN",
    city_name: "Brook",
  },
  {
    postal_code: "47940",
    full_city_name: "Darlington, IN",
    city_name: "Darlington",
  },
  {
    postal_code: "24076",
    full_city_name: "Claudville, VA",
    city_name: "Claudville",
  },
  {
    postal_code: "76565",
    full_city_name: "Pottsville, TX",
    city_name: "Pottsville",
  },
  {
    postal_code: "04952",
    full_city_name: "Morrill, ME",
    city_name: "Morrill",
  },
  {
    postal_code: "81125",
    full_city_name: "Center, CO",
    city_name: "Center",
  },
  {
    postal_code: "36548",
    full_city_name: "Leroy, AL",
    city_name: "Leroy",
  },
  {
    postal_code: "50843",
    full_city_name: "Cumberland, IA",
    city_name: "Cumberland",
  },
  {
    postal_code: "50560",
    full_city_name: "Lu Verne, IA",
    city_name: "Lu Verne",
  },
  {
    postal_code: "50583",
    full_city_name: "Sac City, IA",
    city_name: "Sac City",
  },
  {
    postal_code: "13802",
    full_city_name: "Maine, NY",
    city_name: "Maine",
  },
  {
    postal_code: "99185",
    full_city_name: "Wilbur, WA",
    city_name: "Wilbur",
  },
  {
    postal_code: "45113",
    full_city_name: "Clarksville, OH",
    city_name: "Clarksville",
  },
  {
    postal_code: "05487",
    full_city_name: "Starksboro, VT",
    city_name: "Starksboro",
  },
  {
    postal_code: "43771",
    full_city_name: "Philo, OH",
    city_name: "Philo",
  },
  {
    postal_code: "13746",
    full_city_name: "Chenango Forks, NY",
    city_name: "Chenango Forks",
  },
  {
    postal_code: "15777",
    full_city_name: "Starford, PA",
    city_name: "Starford",
  },
  {
    postal_code: "68836",
    full_city_name: "Elm Creek, NE",
    city_name: "Elm Creek",
  },
  {
    postal_code: "14102",
    full_city_name: "Marilla, NY",
    city_name: "Marilla",
  },
  {
    postal_code: "62074",
    full_city_name: "New Douglas, IL",
    city_name: "New Douglas",
  },
  {
    postal_code: "98852",
    full_city_name: "Stehekin, WA",
    city_name: "Stehekin",
  },
  {
    postal_code: "56025",
    full_city_name: "Easton, MN",
    city_name: "Easton",
  },
  {
    postal_code: "56662",
    full_city_name: "Outing, MN",
    city_name: "Outing",
  },
  {
    postal_code: "16142",
    full_city_name: "New Wilmington, PA",
    city_name: "New Wilmington",
  },
  {
    postal_code: "43534",
    full_city_name: "Mc Clure, OH",
    city_name: "Mc Clure",
  },
  {
    postal_code: "53939",
    full_city_name: "Kingston, WI",
    city_name: "Kingston",
  },
  {
    postal_code: "65011",
    full_city_name: "Barnett, MO",
    city_name: "Barnett",
  },
  {
    postal_code: "55952",
    full_city_name: "Lewiston, MN",
    city_name: "Lewiston",
  },
  {
    postal_code: "87068",
    full_city_name: "Bosque Farms, NM",
    city_name: "Bosque Farms",
  },
  {
    postal_code: "12459",
    full_city_name: "New Kingston, NY",
    city_name: "New Kingston",
  },
  {
    postal_code: "54740",
    full_city_name: "Elmwood, WI",
    city_name: "Elmwood",
  },
  {
    postal_code: "47383",
    full_city_name: "Selma, IN",
    city_name: "Selma",
  },
  {
    postal_code: "25827",
    full_city_name: "Crab Orchard, WV",
    city_name: "Crab Orchard",
  },
  {
    postal_code: "88325",
    full_city_name: "High Rolls Mountain Park, NM",
    city_name: "High Rolls Mountain Park",
  },
  {
    postal_code: "76823",
    full_city_name: "Bangs, TX",
    city_name: "Bangs",
  },
  {
    postal_code: "71968",
    full_city_name: "Royal, AR",
    city_name: "Royal",
  },
  {
    postal_code: "37150",
    full_city_name: "Red Boiling Springs, TN",
    city_name: "Red Boiling Springs",
  },
  {
    postal_code: "84305",
    full_city_name: "Clarkston, UT",
    city_name: "Clarkston",
  },
  {
    postal_code: "45771",
    full_city_name: "Racine, OH",
    city_name: "Racine",
  },
  {
    postal_code: "23055",
    full_city_name: "Fork Union, VA",
    city_name: "Fork Union",
  },
  {
    postal_code: "56024",
    full_city_name: "Eagle Lake, MN",
    city_name: "Eagle Lake",
  },
  {
    postal_code: "27581",
    full_city_name: "Stem, NC",
    city_name: "Stem",
  },
  {
    postal_code: "56338",
    full_city_name: "Hillman, MN",
    city_name: "Hillman",
  },
  {
    postal_code: "38828",
    full_city_name: "Blue Springs, MS",
    city_name: "Blue Springs",
  },
  {
    postal_code: "38388",
    full_city_name: "Wildersville, TN",
    city_name: "Wildersville",
  },
  {
    postal_code: "53021",
    full_city_name: "Fredonia, WI",
    city_name: "Fredonia",
  },
  {
    postal_code: "84722",
    full_city_name: "Central, UT",
    city_name: "Central",
  },
  {
    postal_code: "61238",
    full_city_name: "Cambridge, IL",
    city_name: "Cambridge",
  },
  {
    postal_code: "54826",
    full_city_name: "Comstock, WI",
    city_name: "Comstock",
  },
  {
    postal_code: "68632",
    full_city_name: "David City, NE",
    city_name: "David City",
  },
  {
    postal_code: "66872",
    full_city_name: "White City, KS",
    city_name: "White City",
  },
  {
    postal_code: "93067",
    full_city_name: "Summerland, CA",
    city_name: "Summerland",
  },
  {
    postal_code: "04634",
    full_city_name: "Franklin, ME",
    city_name: "Franklin",
  },
  {
    postal_code: "15030",
    full_city_name: "Creighton, PA",
    city_name: "Creighton",
  },
  {
    postal_code: "46573",
    full_city_name: "Wakarusa, IN",
    city_name: "Wakarusa",
  },
  {
    postal_code: "60180",
    full_city_name: "Union, IL",
    city_name: "Union",
  },
  {
    postal_code: "61284",
    full_city_name: "Taylor Ridge, IL",
    city_name: "Taylor Ridge",
  },
  {
    postal_code: "55030",
    full_city_name: "Grasston, MN",
    city_name: "Grasston",
  },
  {
    postal_code: "17028",
    full_city_name: "Grantville, PA",
    city_name: "Grantville",
  },
  {
    postal_code: "45877",
    full_city_name: "Pandora, OH",
    city_name: "Pandora",
  },
  {
    postal_code: "54746",
    full_city_name: "Humbird, WI",
    city_name: "Humbird",
  },
  {
    postal_code: "01969",
    full_city_name: "Rowley, MA",
    city_name: "Rowley",
  },
  {
    postal_code: "65759",
    full_city_name: "Taneyville, MO",
    city_name: "Taneyville",
  },
  {
    postal_code: "04553",
    full_city_name: "Newcastle, ME",
    city_name: "Newcastle",
  },
  {
    postal_code: "26421",
    full_city_name: "Pullman, WV",
    city_name: "Pullman",
  },
  {
    postal_code: "46745",
    full_city_name: "Hoagland, IN",
    city_name: "Hoagland",
  },
  {
    postal_code: "93202",
    full_city_name: "Armona, CA",
    city_name: "Armona",
  },
  {
    postal_code: "90270",
    full_city_name: "Maywood, CA",
    city_name: "Maywood",
  },
  {
    postal_code: "93513",
    full_city_name: "Big Pine, CA",
    city_name: "Big Pine",
  },
  {
    postal_code: "80733",
    full_city_name: "Hillrose, CO",
    city_name: "Hillrose",
  },
  {
    postal_code: "01607",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "37026",
    full_city_name: "Bradyville, TN",
    city_name: "Bradyville",
  },
  {
    postal_code: "95690",
    full_city_name: "Walnut Grove, CA",
    city_name: "Walnut Grove",
  },
  {
    postal_code: "70755",
    full_city_name: "Livonia, LA",
    city_name: "Livonia",
  },
  {
    postal_code: "38868",
    full_city_name: "Shannon, MS",
    city_name: "Shannon",
  },
  {
    postal_code: "16156",
    full_city_name: "Volant, PA",
    city_name: "Volant",
  },
  {
    postal_code: "15670",
    full_city_name: "New Alexandria, PA",
    city_name: "New Alexandria",
  },
  {
    postal_code: "70057",
    full_city_name: "Hahnville, LA",
    city_name: "Hahnville",
  },
  {
    postal_code: "53586",
    full_city_name: "Shullsburg, WI",
    city_name: "Shullsburg",
  },
  {
    postal_code: "65332",
    full_city_name: "Green Ridge, MO",
    city_name: "Green Ridge",
  },
  {
    postal_code: "92317",
    full_city_name: "Blue Jay, CA",
    city_name: "Blue Jay",
  },
  {
    postal_code: "82514",
    full_city_name: "Fort Washakie, WY",
    city_name: "Fort Washakie",
  },
  {
    postal_code: "17223",
    full_city_name: "Fort Littleton, PA",
    city_name: "Fort Littleton",
  },
  {
    postal_code: "95231",
    full_city_name: "French Camp, CA",
    city_name: "French Camp",
  },
  {
    postal_code: "98635",
    full_city_name: "Lyle, WA",
    city_name: "Lyle",
  },
  {
    postal_code: "27541",
    full_city_name: "Hurdle Mills, NC",
    city_name: "Hurdle Mills",
  },
  {
    postal_code: "63531",
    full_city_name: "Baring, MO",
    city_name: "Baring",
  },
  {
    postal_code: "01375",
    full_city_name: "Sunderland, MA",
    city_name: "Sunderland",
  },
  {
    postal_code: "68856",
    full_city_name: "Merna, NE",
    city_name: "Merna",
  },
  {
    postal_code: "42729",
    full_city_name: "Cub Run, KY",
    city_name: "Cub Run",
  },
  {
    postal_code: "30072",
    full_city_name: "Pine Lake, GA",
    city_name: "Pine Lake",
  },
  {
    postal_code: "53156",
    full_city_name: "Palmyra, WI",
    city_name: "Palmyra",
  },
  {
    postal_code: "11956",
    full_city_name: "New Suffolk, NY",
    city_name: "New Suffolk",
  },
  {
    postal_code: "36311",
    full_city_name: "Ariton, AL",
    city_name: "Ariton",
  },
  {
    postal_code: "13752",
    full_city_name: "Delancey, NY",
    city_name: "Delancey",
  },
  {
    postal_code: "45160",
    full_city_name: "Owensville, OH",
    city_name: "Owensville",
  },
  {
    postal_code: "49667",
    full_city_name: "Merritt, MI",
    city_name: "Merritt",
  },
  {
    postal_code: "61776",
    full_city_name: "Towanda, IL",
    city_name: "Towanda",
  },
  {
    postal_code: "45866",
    full_city_name: "Montezuma, OH",
    city_name: "Montezuma",
  },
  {
    postal_code: "25811",
    full_city_name: "Amigo, WV",
    city_name: "Amigo",
  },
  {
    postal_code: "43565",
    full_city_name: "Tontogany, OH",
    city_name: "Tontogany",
  },
  {
    postal_code: "18503",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "13402",
    full_city_name: "Madison, NY",
    city_name: "Madison",
  },
  {
    postal_code: "43116",
    full_city_name: "Commercial Point, OH",
    city_name: "Commercial Point",
  },
  {
    postal_code: "84528",
    full_city_name: "Huntington, UT",
    city_name: "Huntington",
  },
  {
    postal_code: "04096",
    full_city_name: "Yarmouth, ME",
    city_name: "Yarmouth",
  },
  {
    postal_code: "17832",
    full_city_name: "Marion Heights, PA",
    city_name: "Marion Heights",
  },
  {
    postal_code: "23887",
    full_city_name: "Valentines, VA",
    city_name: "Valentines",
  },
  {
    postal_code: "03047",
    full_city_name: "Greenfield, NH",
    city_name: "Greenfield",
  },
  {
    postal_code: "54082",
    full_city_name: "Houlton, WI",
    city_name: "Houlton",
  },
  {
    postal_code: "78590",
    full_city_name: "San Perlita, TX",
    city_name: "San Perlita",
  },
  {
    postal_code: "89317",
    full_city_name: "Lund, NV",
    city_name: "Lund",
  },
  {
    postal_code: "15563",
    full_city_name: "Stoystown, PA",
    city_name: "Stoystown",
  },
  {
    postal_code: "26178",
    full_city_name: "Smithville, WV",
    city_name: "Smithville",
  },
  {
    postal_code: "58830",
    full_city_name: "Alamo, ND",
    city_name: "Alamo",
  },
  {
    postal_code: "98235",
    full_city_name: "Clearlake, WA",
    city_name: "Clearlake",
  },
  {
    postal_code: "02858",
    full_city_name: "Oakland, RI",
    city_name: "Oakland",
  },
  {
    postal_code: "18837",
    full_city_name: "Rome, PA",
    city_name: "Rome",
  },
  {
    postal_code: "57422",
    full_city_name: "Andover, SD",
    city_name: "Andover",
  },
  {
    postal_code: "55704",
    full_city_name: "Askov, MN",
    city_name: "Askov",
  },
  {
    postal_code: "65063",
    full_city_name: "New Bloomfield, MO",
    city_name: "New Bloomfield",
  },
  {
    postal_code: "56042",
    full_city_name: "Hartland, MN",
    city_name: "Hartland",
  },
  {
    postal_code: "51338",
    full_city_name: "Everly, IA",
    city_name: "Everly",
  },
  {
    postal_code: "42328",
    full_city_name: "Centertown, KY",
    city_name: "Centertown",
  },
  {
    postal_code: "21624",
    full_city_name: "Claiborne, MD",
    city_name: "Claiborne",
  },
  {
    postal_code: "56089",
    full_city_name: "Twin Lakes, MN",
    city_name: "Twin Lakes",
  },
  {
    postal_code: "84080",
    full_city_name: "Vernon, UT",
    city_name: "Vernon",
  },
  {
    postal_code: "40826",
    full_city_name: "Eolia, KY",
    city_name: "Eolia",
  },
  {
    postal_code: "54732",
    full_city_name: "Cornell, WI",
    city_name: "Cornell",
  },
  {
    postal_code: "98547",
    full_city_name: "Grayland, WA",
    city_name: "Grayland",
  },
  {
    postal_code: "95237",
    full_city_name: "Lockeford, CA",
    city_name: "Lockeford",
  },
  {
    postal_code: "73024",
    full_city_name: "Corn, OK",
    city_name: "Corn",
  },
  {
    postal_code: "59820",
    full_city_name: "Alberton, MT",
    city_name: "Alberton",
  },
  {
    postal_code: "54812",
    full_city_name: "Barron, WI",
    city_name: "Barron",
  },
  {
    postal_code: "03861",
    full_city_name: "Lee, NH",
    city_name: "Lee",
  },
  {
    postal_code: "14861",
    full_city_name: "Lowman, NY",
    city_name: "Lowman",
  },
  {
    postal_code: "21668",
    full_city_name: "Sudlersville, MD",
    city_name: "Sudlersville",
  },
  {
    postal_code: "84535",
    full_city_name: "Monticello, UT",
    city_name: "Monticello",
  },
  {
    postal_code: "12028",
    full_city_name: "Buskirk, NY",
    city_name: "Buskirk",
  },
  {
    postal_code: "44086",
    full_city_name: "Thompson, OH",
    city_name: "Thompson",
  },
  {
    postal_code: "12935",
    full_city_name: "Ellenburg Depot, NY",
    city_name: "Ellenburg Depot",
  },
  {
    postal_code: "33846",
    full_city_name: "Highland City, FL",
    city_name: "Highland City",
  },
  {
    postal_code: "79043",
    full_city_name: "Hart, TX",
    city_name: "Hart",
  },
  {
    postal_code: "15380",
    full_city_name: "Wind Ridge, PA",
    city_name: "Wind Ridge",
  },
  {
    postal_code: "46574",
    full_city_name: "Walkerton, IN",
    city_name: "Walkerton",
  },
  {
    postal_code: "47331",
    full_city_name: "Connersville, IN",
    city_name: "Connersville",
  },
  {
    postal_code: "62033",
    full_city_name: "Gillespie, IL",
    city_name: "Gillespie",
  },
  {
    postal_code: "66606",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "31030",
    full_city_name: "Fort Valley, GA",
    city_name: "Fort Valley",
  },
  {
    postal_code: "76431",
    full_city_name: "Chico, TX",
    city_name: "Chico",
  },
  {
    postal_code: "38052",
    full_city_name: "Middleton, TN",
    city_name: "Middleton",
  },
  {
    postal_code: "04236",
    full_city_name: "Greene, ME",
    city_name: "Greene",
  },
  {
    postal_code: "71957",
    full_city_name: "Mount Ida, AR",
    city_name: "Mount Ida",
  },
  {
    postal_code: "61469",
    full_city_name: "Oquawka, IL",
    city_name: "Oquawka",
  },
  {
    postal_code: "29055",
    full_city_name: "Great Falls, SC",
    city_name: "Great Falls",
  },
  {
    postal_code: "08317",
    full_city_name: "Dorothy, NJ",
    city_name: "Dorothy",
  },
  {
    postal_code: "49269",
    full_city_name: "Parma, MI",
    city_name: "Parma",
  },
  {
    postal_code: "01775",
    full_city_name: "Stow, MA",
    city_name: "Stow",
  },
  {
    postal_code: "64098",
    full_city_name: "Weston, MO",
    city_name: "Weston",
  },
  {
    postal_code: "40119",
    full_city_name: "Falls Of Rough, KY",
    city_name: "Falls Of Rough",
  },
  {
    postal_code: "22624",
    full_city_name: "Clear Brook, VA",
    city_name: "Clear Brook",
  },
  {
    postal_code: "98267",
    full_city_name: "Marblemount, WA",
    city_name: "Marblemount",
  },
  {
    postal_code: "55047",
    full_city_name: "Marine On Saint Croix, MN",
    city_name: "Marine On Saint Croix",
  },
  {
    postal_code: "24124",
    full_city_name: "Narrows, VA",
    city_name: "Narrows",
  },
  {
    postal_code: "46635",
    full_city_name: "South Bend, IN",
    city_name: "South Bend",
  },
  {
    postal_code: "81137",
    full_city_name: "Ignacio, CO",
    city_name: "Ignacio",
  },
  {
    postal_code: "48457",
    full_city_name: "Montrose, MI",
    city_name: "Montrose",
  },
  {
    postal_code: "79905",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "03814",
    full_city_name: "Center Ossipee, NH",
    city_name: "Center Ossipee",
  },
  {
    postal_code: "21782",
    full_city_name: "Sharpsburg, MD",
    city_name: "Sharpsburg",
  },
  {
    postal_code: "15767",
    full_city_name: "Punxsutawney, PA",
    city_name: "Punxsutawney",
  },
  {
    postal_code: "05857",
    full_city_name: "Newport Center, VT",
    city_name: "Newport Center",
  },
  {
    postal_code: "78609",
    full_city_name: "Buchanan Dam, TX",
    city_name: "Buchanan Dam",
  },
  {
    postal_code: "05482",
    full_city_name: "Shelburne, VT",
    city_name: "Shelburne",
  },
  {
    postal_code: "49097",
    full_city_name: "Vicksburg, MI",
    city_name: "Vicksburg",
  },
  {
    postal_code: "72031",
    full_city_name: "Clinton, AR",
    city_name: "Clinton",
  },
  {
    postal_code: "06794",
    full_city_name: "Washington Depot, CT",
    city_name: "Washington Depot",
  },
  {
    postal_code: "17368",
    full_city_name: "Wrightsville, PA",
    city_name: "Wrightsville",
  },
  {
    postal_code: "67439",
    full_city_name: "Ellsworth, KS",
    city_name: "Ellsworth",
  },
  {
    postal_code: "26354",
    full_city_name: "Grafton, WV",
    city_name: "Grafton",
  },
  {
    postal_code: "20664",
    full_city_name: "Newburg, MD",
    city_name: "Newburg",
  },
  {
    postal_code: "49683",
    full_city_name: "Thompsonville, MI",
    city_name: "Thompsonville",
  },
  {
    postal_code: "11020",
    full_city_name: "Great Neck, NY",
    city_name: "Great Neck",
  },
  {
    postal_code: "03826",
    full_city_name: "East Hampstead, NH",
    city_name: "East Hampstead",
  },
  {
    postal_code: "04079",
    full_city_name: "Harpswell, ME",
    city_name: "Harpswell",
  },
  {
    postal_code: "23071",
    full_city_name: "Hartfield, VA",
    city_name: "Hartfield",
  },
  {
    postal_code: "29555",
    full_city_name: "Johnsonville, SC",
    city_name: "Johnsonville",
  },
  {
    postal_code: "10924",
    full_city_name: "Goshen, NY",
    city_name: "Goshen",
  },
  {
    postal_code: "20662",
    full_city_name: "Nanjemoy, MD",
    city_name: "Nanjemoy",
  },
  {
    postal_code: "61537",
    full_city_name: "Henry, IL",
    city_name: "Henry",
  },
  {
    postal_code: "74425",
    full_city_name: "Canadian, OK",
    city_name: "Canadian",
  },
  {
    postal_code: "95311",
    full_city_name: "Coulterville, CA",
    city_name: "Coulterville",
  },
  {
    postal_code: "56208",
    full_city_name: "Appleton, MN",
    city_name: "Appleton",
  },
  {
    postal_code: "62922",
    full_city_name: "Creal Springs, IL",
    city_name: "Creal Springs",
  },
  {
    postal_code: "39740",
    full_city_name: "Caledonia, MS",
    city_name: "Caledonia",
  },
  {
    postal_code: "08551",
    full_city_name: "Ringoes, NJ",
    city_name: "Ringoes",
  },
  {
    postal_code: "13327",
    full_city_name: "Croghan, NY",
    city_name: "Croghan",
  },
  {
    postal_code: "15045",
    full_city_name: "Glassport, PA",
    city_name: "Glassport",
  },
  {
    postal_code: "88401",
    full_city_name: "Tucumcari, NM",
    city_name: "Tucumcari",
  },
  {
    postal_code: "29536",
    full_city_name: "Dillon, SC",
    city_name: "Dillon",
  },
  {
    postal_code: "21678",
    full_city_name: "Worton, MD",
    city_name: "Worton",
  },
  {
    postal_code: "08859",
    full_city_name: "Parlin, NJ",
    city_name: "Parlin",
  },
  {
    postal_code: "01581",
    full_city_name: "Westborough, MA",
    city_name: "Westborough",
  },
  {
    postal_code: "23430",
    full_city_name: "Smithfield, VA",
    city_name: "Smithfield",
  },
  {
    postal_code: "46016",
    full_city_name: "Anderson, IN",
    city_name: "Anderson",
  },
  {
    postal_code: "66101",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "19540",
    full_city_name: "Mohnton, PA",
    city_name: "Mohnton",
  },
  {
    postal_code: "36732",
    full_city_name: "Demopolis, AL",
    city_name: "Demopolis",
  },
  {
    postal_code: "94612",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "03261",
    full_city_name: "Northwood, NH",
    city_name: "Northwood",
  },
  {
    postal_code: "11947",
    full_city_name: "Jamesport, NY",
    city_name: "Jamesport",
  },
  {
    postal_code: "35014",
    full_city_name: "Alpine, AL",
    city_name: "Alpine",
  },
  {
    postal_code: "13338",
    full_city_name: "Forestport, NY",
    city_name: "Forestport",
  },
  {
    postal_code: "83327",
    full_city_name: "Fairfield, ID",
    city_name: "Fairfield",
  },
  {
    postal_code: "12468",
    full_city_name: "Prattsville, NY",
    city_name: "Prattsville",
  },
  {
    postal_code: "02126",
    full_city_name: "Mattapan, MA",
    city_name: "Mattapan",
  },
  {
    postal_code: "63089",
    full_city_name: "Villa Ridge, MO",
    city_name: "Villa Ridge",
  },
  {
    postal_code: "71342",
    full_city_name: "Jena, LA",
    city_name: "Jena",
  },
  {
    postal_code: "14710",
    full_city_name: "Ashville, NY",
    city_name: "Ashville",
  },
  {
    postal_code: "93270",
    full_city_name: "Terra Bella, CA",
    city_name: "Terra Bella",
  },
  {
    postal_code: "53039",
    full_city_name: "Juneau, WI",
    city_name: "Juneau",
  },
  {
    postal_code: "43466",
    full_city_name: "Wayne, OH",
    city_name: "Wayne",
  },
  {
    postal_code: "04259",
    full_city_name: "Monmouth, ME",
    city_name: "Monmouth",
  },
  {
    postal_code: "55733",
    full_city_name: "Esko, MN",
    city_name: "Esko",
  },
  {
    postal_code: "36853",
    full_city_name: "Dadeville, AL",
    city_name: "Dadeville",
  },
  {
    postal_code: "04347",
    full_city_name: "Hallowell, ME",
    city_name: "Hallowell",
  },
  {
    postal_code: "38703",
    full_city_name: "Greenville, MS",
    city_name: "Greenville",
  },
  {
    postal_code: "83352",
    full_city_name: "Shoshone, ID",
    city_name: "Shoshone",
  },
  {
    postal_code: "17366",
    full_city_name: "Windsor, PA",
    city_name: "Windsor",
  },
  {
    postal_code: "58622",
    full_city_name: "Belfield, ND",
    city_name: "Belfield",
  },
  {
    postal_code: "23089",
    full_city_name: "Lanexa, VA",
    city_name: "Lanexa",
  },
  {
    postal_code: "12989",
    full_city_name: "Vermontville, NY",
    city_name: "Vermontville",
  },
  {
    postal_code: "93728",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "99712",
    full_city_name: "Fairbanks, AK",
    city_name: "Fairbanks",
  },
  {
    postal_code: "25301",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "79567",
    full_city_name: "Winters, TX",
    city_name: "Winters",
  },
  {
    postal_code: "59230",
    full_city_name: "Glasgow, MT",
    city_name: "Glasgow",
  },
  {
    postal_code: "65043",
    full_city_name: "Holts Summit, MO",
    city_name: "Holts Summit",
  },
  {
    postal_code: "12093",
    full_city_name: "Jefferson, NY",
    city_name: "Jefferson",
  },
  {
    postal_code: "31525",
    full_city_name: "Brunswick, GA",
    city_name: "Brunswick",
  },
  {
    postal_code: "97463",
    full_city_name: "Oakridge, OR",
    city_name: "Oakridge",
  },
  {
    postal_code: "59874",
    full_city_name: "Trout Creek, MT",
    city_name: "Trout Creek",
  },
  {
    postal_code: "14712",
    full_city_name: "Bemus Point, NY",
    city_name: "Bemus Point",
  },
  {
    postal_code: "66739",
    full_city_name: "Galena, KS",
    city_name: "Galena",
  },
  {
    postal_code: "24263",
    full_city_name: "Jonesville, VA",
    city_name: "Jonesville",
  },
  {
    postal_code: "24330",
    full_city_name: "Fries, VA",
    city_name: "Fries",
  },
  {
    postal_code: "08882",
    full_city_name: "South River, NJ",
    city_name: "South River",
  },
  {
    postal_code: "93531",
    full_city_name: "Keene, CA",
    city_name: "Keene",
  },
  {
    postal_code: "04958",
    full_city_name: "North Anson, ME",
    city_name: "North Anson",
  },
  {
    postal_code: "11579",
    full_city_name: "Sea Cliff, NY",
    city_name: "Sea Cliff",
  },
  {
    postal_code: "87552",
    full_city_name: "Pecos, NM",
    city_name: "Pecos",
  },
  {
    postal_code: "01033",
    full_city_name: "Granby, MA",
    city_name: "Granby",
  },
  {
    postal_code: "05774",
    full_city_name: "Wells, VT",
    city_name: "Wells",
  },
  {
    postal_code: "49911",
    full_city_name: "Bessemer, MI",
    city_name: "Bessemer",
  },
  {
    postal_code: "75833",
    full_city_name: "Centerville, TX",
    city_name: "Centerville",
  },
  {
    postal_code: "27983",
    full_city_name: "Windsor, NC",
    city_name: "Windsor",
  },
  {
    postal_code: "27823",
    full_city_name: "Enfield, NC",
    city_name: "Enfield",
  },
  {
    postal_code: "28114",
    full_city_name: "Mooresboro, NC",
    city_name: "Mooresboro",
  },
  {
    postal_code: "96052",
    full_city_name: "Lewiston, CA",
    city_name: "Lewiston",
  },
  {
    postal_code: "23601",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "21738",
    full_city_name: "Glenwood, MD",
    city_name: "Glenwood",
  },
  {
    postal_code: "75630",
    full_city_name: "Avinger, TX",
    city_name: "Avinger",
  },
  {
    postal_code: "21013",
    full_city_name: "Baldwin, MD",
    city_name: "Baldwin",
  },
  {
    postal_code: "82513",
    full_city_name: "Dubois, WY",
    city_name: "Dubois",
  },
  {
    postal_code: "01719",
    full_city_name: "Boxborough, MA",
    city_name: "Boxborough",
  },
  {
    postal_code: "47348",
    full_city_name: "Hartford City, IN",
    city_name: "Hartford City",
  },
  {
    postal_code: "02382",
    full_city_name: "Whitman, MA",
    city_name: "Whitman",
  },
  {
    postal_code: "78580",
    full_city_name: "Raymondville, TX",
    city_name: "Raymondville",
  },
  {
    postal_code: "11596",
    full_city_name: "Williston Park, NY",
    city_name: "Williston Park",
  },
  {
    postal_code: "52401",
    full_city_name: "Cedar Rapids, IA",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "21821",
    full_city_name: "Deal Island, MD",
    city_name: "Deal Island",
  },
  {
    postal_code: "17578",
    full_city_name: "Stevens, PA",
    city_name: "Stevens",
  },
  {
    postal_code: "14482",
    full_city_name: "Le Roy, NY",
    city_name: "Le Roy",
  },
  {
    postal_code: "79830",
    full_city_name: "Alpine, TX",
    city_name: "Alpine",
  },
  {
    postal_code: "53143",
    full_city_name: "Kenosha, WI",
    city_name: "Kenosha",
  },
  {
    postal_code: "53027",
    full_city_name: "Hartford, WI",
    city_name: "Hartford",
  },
  {
    postal_code: "03830",
    full_city_name: "East Wakefield, NH",
    city_name: "East Wakefield",
  },
  {
    postal_code: "04071",
    full_city_name: "Raymond, ME",
    city_name: "Raymond",
  },
  {
    postal_code: "37419",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "48763",
    full_city_name: "Tawas City, MI",
    city_name: "Tawas City",
  },
  {
    postal_code: "06878",
    full_city_name: "Riverside, CT",
    city_name: "Riverside",
  },
  {
    postal_code: "14606",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "30576",
    full_city_name: "Tiger, GA",
    city_name: "Tiger",
  },
  {
    postal_code: "48168",
    full_city_name: "Northville, MI",
    city_name: "Northville",
  },
  {
    postal_code: "18052",
    full_city_name: "Whitehall, PA",
    city_name: "Whitehall",
  },
  {
    postal_code: "47172",
    full_city_name: "Sellersburg, IN",
    city_name: "Sellersburg",
  },
  {
    postal_code: "04614",
    full_city_name: "Blue Hill, ME",
    city_name: "Blue Hill",
  },
  {
    postal_code: "14487",
    full_city_name: "Livonia, NY",
    city_name: "Livonia",
  },
  {
    postal_code: "49412",
    full_city_name: "Fremont, MI",
    city_name: "Fremont",
  },
  {
    postal_code: "02494",
    full_city_name: "Needham Heights, MA",
    city_name: "Needham Heights",
  },
  {
    postal_code: "47520",
    full_city_name: "Cannelton, IN",
    city_name: "Cannelton",
  },
  {
    postal_code: "35044",
    full_city_name: "Childersburg, AL",
    city_name: "Childersburg",
  },
  {
    postal_code: "28333",
    full_city_name: "Dudley, NC",
    city_name: "Dudley",
  },
  {
    postal_code: "45648",
    full_city_name: "Lucasville, OH",
    city_name: "Lucasville",
  },
  {
    postal_code: "42038",
    full_city_name: "Eddyville, KY",
    city_name: "Eddyville",
  },
  {
    postal_code: "93426",
    full_city_name: "Bradley, CA",
    city_name: "Bradley",
  },
  {
    postal_code: "93544",
    full_city_name: "Llano, CA",
    city_name: "Llano",
  },
  {
    postal_code: "65265",
    full_city_name: "Mexico, MO",
    city_name: "Mexico",
  },
  {
    postal_code: "04448",
    full_city_name: "Howland, ME",
    city_name: "Howland",
  },
  {
    postal_code: "03779",
    full_city_name: "Piermont, NH",
    city_name: "Piermont",
  },
  {
    postal_code: "21162",
    full_city_name: "White Marsh, MD",
    city_name: "White Marsh",
  },
  {
    postal_code: "54930",
    full_city_name: "Coloma, WI",
    city_name: "Coloma",
  },
  {
    postal_code: "50674",
    full_city_name: "Sumner, IA",
    city_name: "Sumner",
  },
  {
    postal_code: "49247",
    full_city_name: "Hudson, MI",
    city_name: "Hudson",
  },
  {
    postal_code: "05855",
    full_city_name: "Newport, VT",
    city_name: "Newport",
  },
  {
    postal_code: "62018",
    full_city_name: "Cottage Hills, IL",
    city_name: "Cottage Hills",
  },
  {
    postal_code: "77335",
    full_city_name: "Goodrich, TX",
    city_name: "Goodrich",
  },
  {
    postal_code: "24577",
    full_city_name: "Nathalie, VA",
    city_name: "Nathalie",
  },
  {
    postal_code: "35133",
    full_city_name: "Remlap, AL",
    city_name: "Remlap",
  },
  {
    postal_code: "56433",
    full_city_name: "Akeley, MN",
    city_name: "Akeley",
  },
  {
    postal_code: "53932",
    full_city_name: "Fall River, WI",
    city_name: "Fall River",
  },
  {
    postal_code: "74653",
    full_city_name: "Tonkawa, OK",
    city_name: "Tonkawa",
  },
  {
    postal_code: "24574",
    full_city_name: "Monroe, VA",
    city_name: "Monroe",
  },
  {
    postal_code: "49746",
    full_city_name: "Hillman, MI",
    city_name: "Hillman",
  },
  {
    postal_code: "13052",
    full_city_name: "De Ruyter, NY",
    city_name: "De Ruyter",
  },
  {
    postal_code: "75930",
    full_city_name: "Bronson, TX",
    city_name: "Bronson",
  },
  {
    postal_code: "65325",
    full_city_name: "Cole Camp, MO",
    city_name: "Cole Camp",
  },
  {
    postal_code: "97801",
    full_city_name: "Pendleton, OR",
    city_name: "Pendleton",
  },
  {
    postal_code: "63055",
    full_city_name: "Labadie, MO",
    city_name: "Labadie",
  },
  {
    postal_code: "95724",
    full_city_name: "Norden, CA",
    city_name: "Norden",
  },
  {
    postal_code: "13033",
    full_city_name: "Cato, NY",
    city_name: "Cato",
  },
  {
    postal_code: "26810",
    full_city_name: "Lost City, WV",
    city_name: "Lost City",
  },
  {
    postal_code: "35054",
    full_city_name: "Cropwell, AL",
    city_name: "Cropwell",
  },
  {
    postal_code: "54566",
    full_city_name: "Wabeno, WI",
    city_name: "Wabeno",
  },
  {
    postal_code: "85362",
    full_city_name: "Yarnell, AZ",
    city_name: "Yarnell",
  },
  {
    postal_code: "48468",
    full_city_name: "Port Hope, MI",
    city_name: "Port Hope",
  },
  {
    postal_code: "02451",
    full_city_name: "Waltham, MA",
    city_name: "Waltham",
  },
  {
    postal_code: "95469",
    full_city_name: "Potter Valley, CA",
    city_name: "Potter Valley",
  },
  {
    postal_code: "58301",
    full_city_name: "Devils Lake, ND",
    city_name: "Devils Lake",
  },
  {
    postal_code: "45653",
    full_city_name: "Minford, OH",
    city_name: "Minford",
  },
  {
    postal_code: "13365",
    full_city_name: "Little Falls, NY",
    city_name: "Little Falls",
  },
  {
    postal_code: "26812",
    full_city_name: "Mathias, WV",
    city_name: "Mathias",
  },
  {
    postal_code: "97437",
    full_city_name: "Elmira, OR",
    city_name: "Elmira",
  },
  {
    postal_code: "43443",
    full_city_name: "Luckey, OH",
    city_name: "Luckey",
  },
  {
    postal_code: "48191",
    full_city_name: "Willis, MI",
    city_name: "Willis",
  },
  {
    postal_code: "01255",
    full_city_name: "Sandisfield, MA",
    city_name: "Sandisfield",
  },
  {
    postal_code: "04562",
    full_city_name: "Phippsburg, ME",
    city_name: "Phippsburg",
  },
  {
    postal_code: "13676",
    full_city_name: "Potsdam, NY",
    city_name: "Potsdam",
  },
  {
    postal_code: "24230",
    full_city_name: "Coeburn, VA",
    city_name: "Coeburn",
  },
  {
    postal_code: "08554",
    full_city_name: "Roebling, NJ",
    city_name: "Roebling",
  },
  {
    postal_code: "76844",
    full_city_name: "Goldthwaite, TX",
    city_name: "Goldthwaite",
  },
  {
    postal_code: "32071",
    full_city_name: "O Brien, FL",
    city_name: "O Brien",
  },
  {
    postal_code: "01257",
    full_city_name: "Sheffield, MA",
    city_name: "Sheffield",
  },
  {
    postal_code: "24122",
    full_city_name: "Montvale, VA",
    city_name: "Montvale",
  },
  {
    postal_code: "76487",
    full_city_name: "Poolville, TX",
    city_name: "Poolville",
  },
  {
    postal_code: "17062",
    full_city_name: "Millerstown, PA",
    city_name: "Millerstown",
  },
  {
    postal_code: "31087",
    full_city_name: "Sparta, GA",
    city_name: "Sparta",
  },
  {
    postal_code: "12485",
    full_city_name: "Tannersville, NY",
    city_name: "Tannersville",
  },
  {
    postal_code: "46347",
    full_city_name: "Kouts, IN",
    city_name: "Kouts",
  },
  {
    postal_code: "38862",
    full_city_name: "Plantersville, MS",
    city_name: "Plantersville",
  },
  {
    postal_code: "78160",
    full_city_name: "Stockdale, TX",
    city_name: "Stockdale",
  },
  {
    postal_code: "02538",
    full_city_name: "East Wareham, MA",
    city_name: "East Wareham",
  },
  {
    postal_code: "04219",
    full_city_name: "Bryant Pond, ME",
    city_name: "Bryant Pond",
  },
  {
    postal_code: "61733",
    full_city_name: "Deer Creek, IL",
    city_name: "Deer Creek",
  },
  {
    postal_code: "43570",
    full_city_name: "West Unity, OH",
    city_name: "West Unity",
  },
  {
    postal_code: "40111",
    full_city_name: "Cloverport, KY",
    city_name: "Cloverport",
  },
  {
    postal_code: "83655",
    full_city_name: "New Plymouth, ID",
    city_name: "New Plymouth",
  },
  {
    postal_code: "96050",
    full_city_name: "Klamath River, CA",
    city_name: "Klamath River",
  },
  {
    postal_code: "56446",
    full_city_name: "Eagle Bend, MN",
    city_name: "Eagle Bend",
  },
  {
    postal_code: "96781",
    full_city_name: "Papaikou, HI",
    city_name: "Papaikou",
  },
  {
    postal_code: "71355",
    full_city_name: "Moreauville, LA",
    city_name: "Moreauville",
  },
  {
    postal_code: "03258",
    full_city_name: "Chichester, NH",
    city_name: "Chichester",
  },
  {
    postal_code: "36088",
    full_city_name: "Tuskegee Institute, AL",
    city_name: "Tuskegee Institute",
  },
  {
    postal_code: "49847",
    full_city_name: "Hermansville, MI",
    city_name: "Hermansville",
  },
  {
    postal_code: "38504",
    full_city_name: "Allardt, TN",
    city_name: "Allardt",
  },
  {
    postal_code: "56171",
    full_city_name: "Sherburn, MN",
    city_name: "Sherburn",
  },
  {
    postal_code: "78123",
    full_city_name: "Mc Queeney, TX",
    city_name: "Mc Queeney",
  },
  {
    postal_code: "54747",
    full_city_name: "Independence, WI",
    city_name: "Independence",
  },
  {
    postal_code: "14468",
    full_city_name: "Hilton, NY",
    city_name: "Hilton",
  },
  {
    postal_code: "10455",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "40008",
    full_city_name: "Bloomfield, KY",
    city_name: "Bloomfield",
  },
  {
    postal_code: "11718",
    full_city_name: "Brightwaters, NY",
    city_name: "Brightwaters",
  },
  {
    postal_code: "60029",
    full_city_name: "Golf, IL",
    city_name: "Golf",
  },
  {
    postal_code: "35131",
    full_city_name: "Ragland, AL",
    city_name: "Ragland",
  },
  {
    postal_code: "48829",
    full_city_name: "Edmore, MI",
    city_name: "Edmore",
  },
  {
    postal_code: "76525",
    full_city_name: "Evant, TX",
    city_name: "Evant",
  },
  {
    postal_code: "72531",
    full_city_name: "Elizabeth, AR",
    city_name: "Elizabeth",
  },
  {
    postal_code: "06090",
    full_city_name: "West Granby, CT",
    city_name: "West Granby",
  },
  {
    postal_code: "70533",
    full_city_name: "Erath, LA",
    city_name: "Erath",
  },
  {
    postal_code: "05262",
    full_city_name: "Shaftsbury, VT",
    city_name: "Shaftsbury",
  },
  {
    postal_code: "48002",
    full_city_name: "Allenton, MI",
    city_name: "Allenton",
  },
  {
    postal_code: "15679",
    full_city_name: "Ruffs Dale, PA",
    city_name: "Ruffs Dale",
  },
  {
    postal_code: "17584",
    full_city_name: "Willow Street, PA",
    city_name: "Willow Street",
  },
  {
    postal_code: "95319",
    full_city_name: "Empire, CA",
    city_name: "Empire",
  },
  {
    postal_code: "29487",
    full_city_name: "Wadmalaw Island, SC",
    city_name: "Wadmalaw Island",
  },
  {
    postal_code: "14818",
    full_city_name: "Burdett, NY",
    city_name: "Burdett",
  },
  {
    postal_code: "79381",
    full_city_name: "Wilson, TX",
    city_name: "Wilson",
  },
  {
    postal_code: "02641",
    full_city_name: "East Dennis, MA",
    city_name: "East Dennis",
  },
  {
    postal_code: "12108",
    full_city_name: "Lake Pleasant, NY",
    city_name: "Lake Pleasant",
  },
  {
    postal_code: "96106",
    full_city_name: "Clio, CA",
    city_name: "Clio",
  },
  {
    postal_code: "12190",
    full_city_name: "Wells, NY",
    city_name: "Wells",
  },
  {
    postal_code: "18853",
    full_city_name: "Wyalusing, PA",
    city_name: "Wyalusing",
  },
  {
    postal_code: "06754",
    full_city_name: "Cornwall Bridge, CT",
    city_name: "Cornwall Bridge",
  },
  {
    postal_code: "28627",
    full_city_name: "Glade Valley, NC",
    city_name: "Glade Valley",
  },
  {
    postal_code: "23821",
    full_city_name: "Alberta, VA",
    city_name: "Alberta",
  },
  {
    postal_code: "48849",
    full_city_name: "Lake Odessa, MI",
    city_name: "Lake Odessa",
  },
  {
    postal_code: "05051",
    full_city_name: "Newbury, VT",
    city_name: "Newbury",
  },
  {
    postal_code: "02881",
    full_city_name: "Kingston, RI",
    city_name: "Kingston",
  },
  {
    postal_code: "95436",
    full_city_name: "Forestville, CA",
    city_name: "Forestville",
  },
  {
    postal_code: "61272",
    full_city_name: "New Boston, IL",
    city_name: "New Boston",
  },
  {
    postal_code: "55049",
    full_city_name: "Medford, MN",
    city_name: "Medford",
  },
  {
    postal_code: "67422",
    full_city_name: "Bennington, KS",
    city_name: "Bennington",
  },
  {
    postal_code: "65438",
    full_city_name: "Birch Tree, MO",
    city_name: "Birch Tree",
  },
  {
    postal_code: "42120",
    full_city_name: "Adolphus, KY",
    city_name: "Adolphus",
  },
  {
    postal_code: "95333",
    full_city_name: "Le Grand, CA",
    city_name: "Le Grand",
  },
  {
    postal_code: "17086",
    full_city_name: "Richfield, PA",
    city_name: "Richfield",
  },
  {
    postal_code: "49264",
    full_city_name: "Onondaga, MI",
    city_name: "Onondaga",
  },
  {
    postal_code: "55114",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "22952",
    full_city_name: "Lyndhurst, VA",
    city_name: "Lyndhurst",
  },
  {
    postal_code: "22640",
    full_city_name: "Huntly, VA",
    city_name: "Huntly",
  },
  {
    postal_code: "96783",
    full_city_name: "Pepeekeo, HI",
    city_name: "Pepeekeo",
  },
  {
    postal_code: "48202",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "19043",
    full_city_name: "Holmes, PA",
    city_name: "Holmes",
  },
  {
    postal_code: "72011",
    full_city_name: "Bauxite, AR",
    city_name: "Bauxite",
  },
  {
    postal_code: "74347",
    full_city_name: "Kansas, OK",
    city_name: "Kansas",
  },
  {
    postal_code: "61360",
    full_city_name: "Seneca, IL",
    city_name: "Seneca",
  },
  {
    postal_code: "57759",
    full_city_name: "Nemo, SD",
    city_name: "Nemo",
  },
  {
    postal_code: "04961",
    full_city_name: "New Portland, ME",
    city_name: "New Portland",
  },
  {
    postal_code: "28702",
    full_city_name: "Almond, NC",
    city_name: "Almond",
  },
  {
    postal_code: "77434",
    full_city_name: "Eagle Lake, TX",
    city_name: "Eagle Lake",
  },
  {
    postal_code: "15909",
    full_city_name: "Johnstown, PA",
    city_name: "Johnstown",
  },
  {
    postal_code: "43772",
    full_city_name: "Pleasant City, OH",
    city_name: "Pleasant City",
  },
  {
    postal_code: "61760",
    full_city_name: "Minonk, IL",
    city_name: "Minonk",
  },
  {
    postal_code: "41007",
    full_city_name: "California, KY",
    city_name: "California",
  },
  {
    postal_code: "46171",
    full_city_name: "Reelsville, IN",
    city_name: "Reelsville",
  },
  {
    postal_code: "03868",
    full_city_name: "Rochester, NH",
    city_name: "Rochester",
  },
  {
    postal_code: "65446",
    full_city_name: "Cherryville, MO",
    city_name: "Cherryville",
  },
  {
    postal_code: "11957",
    full_city_name: "Orient, NY",
    city_name: "Orient",
  },
  {
    postal_code: "60924",
    full_city_name: "Cissna Park, IL",
    city_name: "Cissna Park",
  },
  {
    postal_code: "38230",
    full_city_name: "Greenfield, TN",
    city_name: "Greenfield",
  },
  {
    postal_code: "50135",
    full_city_name: "Kellogg, IA",
    city_name: "Kellogg",
  },
  {
    postal_code: "53529",
    full_city_name: "Dane, WI",
    city_name: "Dane",
  },
  {
    postal_code: "78226",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "28623",
    full_city_name: "Ennice, NC",
    city_name: "Ennice",
  },
  {
    postal_code: "13809",
    full_city_name: "Mount Upton, NY",
    city_name: "Mount Upton",
  },
  {
    postal_code: "30457",
    full_city_name: "Soperton, GA",
    city_name: "Soperton",
  },
  {
    postal_code: "31546",
    full_city_name: "Jesup, GA",
    city_name: "Jesup",
  },
  {
    postal_code: "67467",
    full_city_name: "Minneapolis, KS",
    city_name: "Minneapolis",
  },
  {
    postal_code: "39071",
    full_city_name: "Flora, MS",
    city_name: "Flora",
  },
  {
    postal_code: "03784",
    full_city_name: "West Lebanon, NH",
    city_name: "West Lebanon",
  },
  {
    postal_code: "61231",
    full_city_name: "Aledo, IL",
    city_name: "Aledo",
  },
  {
    postal_code: "19962",
    full_city_name: "Magnolia, DE",
    city_name: "Magnolia",
  },
  {
    postal_code: "78570",
    full_city_name: "Mercedes, TX",
    city_name: "Mercedes",
  },
  {
    postal_code: "99701",
    full_city_name: "Fairbanks, AK",
    city_name: "Fairbanks",
  },
  {
    postal_code: "07065",
    full_city_name: "Rahway, NJ",
    city_name: "Rahway",
  },
  {
    postal_code: "41143",
    full_city_name: "Grayson, KY",
    city_name: "Grayson",
  },
  {
    postal_code: "49099",
    full_city_name: "White Pigeon, MI",
    city_name: "White Pigeon",
  },
  {
    postal_code: "01550",
    full_city_name: "Southbridge, MA",
    city_name: "Southbridge",
  },
  {
    postal_code: "96772",
    full_city_name: "Naalehu, HI",
    city_name: "Naalehu",
  },
  {
    postal_code: "21750",
    full_city_name: "Hancock, MD",
    city_name: "Hancock",
  },
  {
    postal_code: "10566",
    full_city_name: "Peekskill, NY",
    city_name: "Peekskill",
  },
  {
    postal_code: "72202",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "04950",
    full_city_name: "Madison, ME",
    city_name: "Madison",
  },
  {
    postal_code: "59804",
    full_city_name: "Missoula, MT",
    city_name: "Missoula",
  },
  {
    postal_code: "75496",
    full_city_name: "Wolfe City, TX",
    city_name: "Wolfe City",
  },
  {
    postal_code: "60938",
    full_city_name: "Gilman, IL",
    city_name: "Gilman",
  },
  {
    postal_code: "12776",
    full_city_name: "Roscoe, NY",
    city_name: "Roscoe",
  },
  {
    postal_code: "21727",
    full_city_name: "Emmitsburg, MD",
    city_name: "Emmitsburg",
  },
  {
    postal_code: "71343",
    full_city_name: "Jonesville, LA",
    city_name: "Jonesville",
  },
  {
    postal_code: "43917",
    full_city_name: "Dillonvale, OH",
    city_name: "Dillonvale",
  },
  {
    postal_code: "63107",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "37097",
    full_city_name: "Lobelville, TN",
    city_name: "Lobelville",
  },
  {
    postal_code: "14755",
    full_city_name: "Little Valley, NY",
    city_name: "Little Valley",
  },
  {
    postal_code: "45764",
    full_city_name: "Nelsonville, OH",
    city_name: "Nelsonville",
  },
  {
    postal_code: "54891",
    full_city_name: "Washburn, WI",
    city_name: "Washburn",
  },
  {
    postal_code: "12817",
    full_city_name: "Chestertown, NY",
    city_name: "Chestertown",
  },
  {
    postal_code: "68978",
    full_city_name: "Superior, NE",
    city_name: "Superior",
  },
  {
    postal_code: "27283",
    full_city_name: "Julian, NC",
    city_name: "Julian",
  },
  {
    postal_code: "78014",
    full_city_name: "Cotulla, TX",
    city_name: "Cotulla",
  },
  {
    postal_code: "53943",
    full_city_name: "Loganville, WI",
    city_name: "Loganville",
  },
  {
    postal_code: "20137",
    full_city_name: "Broad Run, VA",
    city_name: "Broad Run",
  },
  {
    postal_code: "29058",
    full_city_name: "Heath Springs, SC",
    city_name: "Heath Springs",
  },
  {
    postal_code: "41465",
    full_city_name: "Salyersville, KY",
    city_name: "Salyersville",
  },
  {
    postal_code: "56447",
    full_city_name: "Emily, MN",
    city_name: "Emily",
  },
  {
    postal_code: "73430",
    full_city_name: "Burneyville, OK",
    city_name: "Burneyville",
  },
  {
    postal_code: "01337",
    full_city_name: "Bernardston, MA",
    city_name: "Bernardston",
  },
  {
    postal_code: "59824",
    full_city_name: "Charlo, MT",
    city_name: "Charlo",
  },
  {
    postal_code: "99349",
    full_city_name: "Mattawa, WA",
    city_name: "Mattawa",
  },
  {
    postal_code: "77703",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "55335",
    full_city_name: "Gibbon, MN",
    city_name: "Gibbon",
  },
  {
    postal_code: "56589",
    full_city_name: "Waubun, MN",
    city_name: "Waubun",
  },
  {
    postal_code: "70030",
    full_city_name: "Des Allemands, LA",
    city_name: "Des Allemands",
  },
  {
    postal_code: "45614",
    full_city_name: "Bidwell, OH",
    city_name: "Bidwell",
  },
  {
    postal_code: "47951",
    full_city_name: "Kentland, IN",
    city_name: "Kentland",
  },
  {
    postal_code: "49072",
    full_city_name: "Mendon, MI",
    city_name: "Mendon",
  },
  {
    postal_code: "16051",
    full_city_name: "Portersville, PA",
    city_name: "Portersville",
  },
  {
    postal_code: "63347",
    full_city_name: "Foley, MO",
    city_name: "Foley",
  },
  {
    postal_code: "95007",
    full_city_name: "Brookdale, CA",
    city_name: "Brookdale",
  },
  {
    postal_code: "85192",
    full_city_name: "Winkelman, AZ",
    city_name: "Winkelman",
  },
  {
    postal_code: "58740",
    full_city_name: "Glenburn, ND",
    city_name: "Glenburn",
  },
  {
    postal_code: "28766",
    full_city_name: "Penrose, NC",
    city_name: "Penrose",
  },
  {
    postal_code: "52316",
    full_city_name: "North English, IA",
    city_name: "North English",
  },
  {
    postal_code: "01843",
    full_city_name: "Lawrence, MA",
    city_name: "Lawrence",
  },
  {
    postal_code: "16914",
    full_city_name: "Columbia Cross Roads, PA",
    city_name: "Columbia Cross Roads",
  },
  {
    postal_code: "62930",
    full_city_name: "Eldorado, IL",
    city_name: "Eldorado",
  },
  {
    postal_code: "12461",
    full_city_name: "Olivebridge, NY",
    city_name: "Olivebridge",
  },
  {
    postal_code: "44827",
    full_city_name: "Crestline, OH",
    city_name: "Crestline",
  },
  {
    postal_code: "24593",
    full_city_name: "Spout Spring, VA",
    city_name: "Spout Spring",
  },
  {
    postal_code: "71423",
    full_city_name: "Dry Prong, LA",
    city_name: "Dry Prong",
  },
  {
    postal_code: "10573",
    full_city_name: "Port Chester, NY",
    city_name: "Port Chester",
  },
  {
    postal_code: "53186",
    full_city_name: "Waukesha, WI",
    city_name: "Waukesha",
  },
  {
    postal_code: "45640",
    full_city_name: "Jackson, OH",
    city_name: "Jackson",
  },
  {
    postal_code: "03870",
    full_city_name: "Rye, NH",
    city_name: "Rye",
  },
  {
    postal_code: "18020",
    full_city_name: "Bethlehem, PA",
    city_name: "Bethlehem",
  },
  {
    postal_code: "36610",
    full_city_name: "Mobile, AL",
    city_name: "Mobile",
  },
  {
    postal_code: "35006",
    full_city_name: "Adger, AL",
    city_name: "Adger",
  },
  {
    postal_code: "30170",
    full_city_name: "Roopville, GA",
    city_name: "Roopville",
  },
  {
    postal_code: "72662",
    full_city_name: "Omaha, AR",
    city_name: "Omaha",
  },
  {
    postal_code: "79922",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "01364",
    full_city_name: "Orange, MA",
    city_name: "Orange",
  },
  {
    postal_code: "53570",
    full_city_name: "Monticello, WI",
    city_name: "Monticello",
  },
  {
    postal_code: "39083",
    full_city_name: "Hazlehurst, MS",
    city_name: "Hazlehurst",
  },
  {
    postal_code: "42217",
    full_city_name: "Crofton, KY",
    city_name: "Crofton",
  },
  {
    postal_code: "14527",
    full_city_name: "Penn Yan, NY",
    city_name: "Penn Yan",
  },
  {
    postal_code: "47170",
    full_city_name: "Scottsburg, IN",
    city_name: "Scottsburg",
  },
  {
    postal_code: "18018",
    full_city_name: "Bethlehem, PA",
    city_name: "Bethlehem",
  },
  {
    postal_code: "57106",
    full_city_name: "Sioux Falls, SD",
    city_name: "Sioux Falls",
  },
  {
    postal_code: "19967",
    full_city_name: "Millville, DE",
    city_name: "Millville",
  },
  {
    postal_code: "71943",
    full_city_name: "Glenwood, AR",
    city_name: "Glenwood",
  },
  {
    postal_code: "10460",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "62837",
    full_city_name: "Fairfield, IL",
    city_name: "Fairfield",
  },
  {
    postal_code: "13323",
    full_city_name: "Clinton, NY",
    city_name: "Clinton",
  },
  {
    postal_code: "81625",
    full_city_name: "Craig, CO",
    city_name: "Craig",
  },
  {
    postal_code: "75656",
    full_city_name: "Hughes Springs, TX",
    city_name: "Hughes Springs",
  },
  {
    postal_code: "21219",
    full_city_name: "Sparrows Point, MD",
    city_name: "Sparrows Point",
  },
  {
    postal_code: "49246",
    full_city_name: "Horton, MI",
    city_name: "Horton",
  },
  {
    postal_code: "56267",
    full_city_name: "Morris, MN",
    city_name: "Morris",
  },
  {
    postal_code: "61019",
    full_city_name: "Davis, IL",
    city_name: "Davis",
  },
  {
    postal_code: "48040",
    full_city_name: "Marysville, MI",
    city_name: "Marysville",
  },
  {
    postal_code: "36266",
    full_city_name: "Lineville, AL",
    city_name: "Lineville",
  },
  {
    postal_code: "54628",
    full_city_name: "Ferryville, WI",
    city_name: "Ferryville",
  },
  {
    postal_code: "83712",
    full_city_name: "Boise, ID",
    city_name: "Boise",
  },
  {
    postal_code: "48768",
    full_city_name: "Vassar, MI",
    city_name: "Vassar",
  },
  {
    postal_code: "96001",
    full_city_name: "Redding, CA",
    city_name: "Redding",
  },
  {
    postal_code: "92536",
    full_city_name: "Aguanga, CA",
    city_name: "Aguanga",
  },
  {
    postal_code: "48427",
    full_city_name: "Deckerville, MI",
    city_name: "Deckerville",
  },
  {
    postal_code: "56361",
    full_city_name: "Parkers Prairie, MN",
    city_name: "Parkers Prairie",
  },
  {
    postal_code: "75935",
    full_city_name: "Center, TX",
    city_name: "Center",
  },
  {
    postal_code: "47334",
    full_city_name: "Daleville, IN",
    city_name: "Daleville",
  },
  {
    postal_code: "02144",
    full_city_name: "Somerville, MA",
    city_name: "Somerville",
  },
  {
    postal_code: "53140",
    full_city_name: "Kenosha, WI",
    city_name: "Kenosha",
  },
  {
    postal_code: "30541",
    full_city_name: "Epworth, GA",
    city_name: "Epworth",
  },
  {
    postal_code: "59001",
    full_city_name: "Absarokee, MT",
    city_name: "Absarokee",
  },
  {
    postal_code: "84033",
    full_city_name: "Henefer, UT",
    city_name: "Henefer",
  },
  {
    postal_code: "74745",
    full_city_name: "Idabel, OK",
    city_name: "Idabel",
  },
  {
    postal_code: "01747",
    full_city_name: "Hopedale, MA",
    city_name: "Hopedale",
  },
  {
    postal_code: "79401",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "42539",
    full_city_name: "Liberty, KY",
    city_name: "Liberty",
  },
  {
    postal_code: "77831",
    full_city_name: "Bedias, TX",
    city_name: "Bedias",
  },
  {
    postal_code: "48097",
    full_city_name: "Yale, MI",
    city_name: "Yale",
  },
  {
    postal_code: "72114",
    full_city_name: "North Little Rock, AR",
    city_name: "North Little Rock",
  },
  {
    postal_code: "53210",
    full_city_name: "Milwaukee, WI",
    city_name: "Milwaukee",
  },
  {
    postal_code: "91020",
    full_city_name: "Montrose, CA",
    city_name: "Montrose",
  },
  {
    postal_code: "16669",
    full_city_name: "Petersburg, PA",
    city_name: "Petersburg",
  },
  {
    postal_code: "97730",
    full_city_name: "Camp Sherman, OR",
    city_name: "Camp Sherman",
  },
  {
    postal_code: "57002",
    full_city_name: "Aurora, SD",
    city_name: "Aurora",
  },
  {
    postal_code: "02724",
    full_city_name: "Fall River, MA",
    city_name: "Fall River",
  },
  {
    postal_code: "28735",
    full_city_name: "Gerton, NC",
    city_name: "Gerton",
  },
  {
    postal_code: "05650",
    full_city_name: "East Calais, VT",
    city_name: "East Calais",
  },
  {
    postal_code: "29704",
    full_city_name: "Catawba, SC",
    city_name: "Catawba",
  },
  {
    postal_code: "43127",
    full_city_name: "Haydenville, OH",
    city_name: "Haydenville",
  },
  {
    postal_code: "12140",
    full_city_name: "Poestenkill, NY",
    city_name: "Poestenkill",
  },
  {
    postal_code: "20143",
    full_city_name: "Catharpin, VA",
    city_name: "Catharpin",
  },
  {
    postal_code: "64833",
    full_city_name: "Avilla, MO",
    city_name: "Avilla",
  },
  {
    postal_code: "06608",
    full_city_name: "Bridgeport, CT",
    city_name: "Bridgeport",
  },
  {
    postal_code: "49861",
    full_city_name: "Michigamme, MI",
    city_name: "Michigamme",
  },
  {
    postal_code: "95236",
    full_city_name: "Linden, CA",
    city_name: "Linden",
  },
  {
    postal_code: "54631",
    full_city_name: "Gays Mills, WI",
    city_name: "Gays Mills",
  },
  {
    postal_code: "29031",
    full_city_name: "Carlisle, SC",
    city_name: "Carlisle",
  },
  {
    postal_code: "97149",
    full_city_name: "Neskowin, OR",
    city_name: "Neskowin",
  },
  {
    postal_code: "14779",
    full_city_name: "Salamanca, NY",
    city_name: "Salamanca",
  },
  {
    postal_code: "76933",
    full_city_name: "Bronte, TX",
    city_name: "Bronte",
  },
  {
    postal_code: "62907",
    full_city_name: "Ava, IL",
    city_name: "Ava",
  },
  {
    postal_code: "37352",
    full_city_name: "Lynchburg, TN",
    city_name: "Lynchburg",
  },
  {
    postal_code: "79782",
    full_city_name: "Stanton, TX",
    city_name: "Stanton",
  },
  {
    postal_code: "06793",
    full_city_name: "Washington, CT",
    city_name: "Washington",
  },
  {
    postal_code: "18930",
    full_city_name: "Kintnersville, PA",
    city_name: "Kintnersville",
  },
  {
    postal_code: "19030",
    full_city_name: "Fairless Hills, PA",
    city_name: "Fairless Hills",
  },
  {
    postal_code: "02115",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "21817",
    full_city_name: "Crisfield, MD",
    city_name: "Crisfield",
  },
  {
    postal_code: "28789",
    full_city_name: "Whittier, NC",
    city_name: "Whittier",
  },
  {
    postal_code: "49242",
    full_city_name: "Hillsdale, MI",
    city_name: "Hillsdale",
  },
  {
    postal_code: "11366",
    full_city_name: "Fresh Meadows, NY",
    city_name: "Fresh Meadows",
  },
  {
    postal_code: "06751",
    full_city_name: "Bethlehem, CT",
    city_name: "Bethlehem",
  },
  {
    postal_code: "14626",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "48631",
    full_city_name: "Kawkawlin, MI",
    city_name: "Kawkawlin",
  },
  {
    postal_code: "13211",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "27937",
    full_city_name: "Gates, NC",
    city_name: "Gates",
  },
  {
    postal_code: "14433",
    full_city_name: "Clyde, NY",
    city_name: "Clyde",
  },
  {
    postal_code: "02170",
    full_city_name: "Quincy, MA",
    city_name: "Quincy",
  },
  {
    postal_code: "31028",
    full_city_name: "Centerville, GA",
    city_name: "Centerville",
  },
  {
    postal_code: "28783",
    full_city_name: "Tuckasegee, NC",
    city_name: "Tuckasegee",
  },
  {
    postal_code: "47960",
    full_city_name: "Monticello, IN",
    city_name: "Monticello",
  },
  {
    postal_code: "04443",
    full_city_name: "Guilford, ME",
    city_name: "Guilford",
  },
  {
    postal_code: "02635",
    full_city_name: "Cotuit, MA",
    city_name: "Cotuit",
  },
  {
    postal_code: "19952",
    full_city_name: "Harrington, DE",
    city_name: "Harrington",
  },
  {
    postal_code: "18067",
    full_city_name: "Northampton, PA",
    city_name: "Northampton",
  },
  {
    postal_code: "14526",
    full_city_name: "Penfield, NY",
    city_name: "Penfield",
  },
  {
    postal_code: "65270",
    full_city_name: "Moberly, MO",
    city_name: "Moberly",
  },
  {
    postal_code: "18073",
    full_city_name: "Pennsburg, PA",
    city_name: "Pennsburg",
  },
  {
    postal_code: "41653",
    full_city_name: "Prestonsburg, KY",
    city_name: "Prestonsburg",
  },
  {
    postal_code: "16648",
    full_city_name: "Hollidaysburg, PA",
    city_name: "Hollidaysburg",
  },
  {
    postal_code: "18702",
    full_city_name: "Wilkes Barre, PA",
    city_name: "Wilkes Barre",
  },
  {
    postal_code: "08311",
    full_city_name: "Cedarville, NJ",
    city_name: "Cedarville",
  },
  {
    postal_code: "54302",
    full_city_name: "Green Bay, WI",
    city_name: "Green Bay",
  },
  {
    postal_code: "54947",
    full_city_name: "Larsen, WI",
    city_name: "Larsen",
  },
  {
    postal_code: "23662",
    full_city_name: "Poquoson, VA",
    city_name: "Poquoson",
  },
  {
    postal_code: "04294",
    full_city_name: "Wilton, ME",
    city_name: "Wilton",
  },
  {
    postal_code: "25703",
    full_city_name: "Huntington, WV",
    city_name: "Huntington",
  },
  {
    postal_code: "49745",
    full_city_name: "Hessel, MI",
    city_name: "Hessel",
  },
  {
    postal_code: "56050",
    full_city_name: "Kasota, MN",
    city_name: "Kasota",
  },
  {
    postal_code: "40003",
    full_city_name: "Bagdad, KY",
    city_name: "Bagdad",
  },
  {
    postal_code: "02119",
    full_city_name: "Roxbury, MA",
    city_name: "Roxbury",
  },
  {
    postal_code: "49659",
    full_city_name: "Mancelona, MI",
    city_name: "Mancelona",
  },
  {
    postal_code: "28572",
    full_city_name: "Pink Hill, NC",
    city_name: "Pink Hill",
  },
  {
    postal_code: "53525",
    full_city_name: "Clinton, WI",
    city_name: "Clinton",
  },
  {
    postal_code: "95655",
    full_city_name: "Mather, CA",
    city_name: "Mather",
  },
  {
    postal_code: "82420",
    full_city_name: "Cowley, WY",
    city_name: "Cowley",
  },
  {
    postal_code: "75966",
    full_city_name: "Newton, TX",
    city_name: "Newton",
  },
  {
    postal_code: "12997",
    full_city_name: "Wilmington, NY",
    city_name: "Wilmington",
  },
  {
    postal_code: "49021",
    full_city_name: "Bellevue, MI",
    city_name: "Bellevue",
  },
  {
    postal_code: "79027",
    full_city_name: "Dimmitt, TX",
    city_name: "Dimmitt",
  },
  {
    postal_code: "50054",
    full_city_name: "Colfax, IA",
    city_name: "Colfax",
  },
  {
    postal_code: "78379",
    full_city_name: "Riviera, TX",
    city_name: "Riviera",
  },
  {
    postal_code: "93651",
    full_city_name: "Prather, CA",
    city_name: "Prather",
  },
  {
    postal_code: "55710",
    full_city_name: "Britt, MN",
    city_name: "Britt",
  },
  {
    postal_code: "61020",
    full_city_name: "Davis Junction, IL",
    city_name: "Davis Junction",
  },
  {
    postal_code: "13044",
    full_city_name: "Constantia, NY",
    city_name: "Constantia",
  },
  {
    postal_code: "78593",
    full_city_name: "Santa Rosa, TX",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "47841",
    full_city_name: "Clay City, IN",
    city_name: "Clay City",
  },
  {
    postal_code: "61028",
    full_city_name: "Elizabeth, IL",
    city_name: "Elizabeth",
  },
  {
    postal_code: "52645",
    full_city_name: "New London, IA",
    city_name: "New London",
  },
  {
    postal_code: "12409",
    full_city_name: "Bearsville, NY",
    city_name: "Bearsville",
  },
  {
    postal_code: "72959",
    full_city_name: "Winslow, AR",
    city_name: "Winslow",
  },
  {
    postal_code: "68666",
    full_city_name: "Stromsburg, NE",
    city_name: "Stromsburg",
  },
  {
    postal_code: "49405",
    full_city_name: "Custer, MI",
    city_name: "Custer",
  },
  {
    postal_code: "37878",
    full_city_name: "Tallassee, TN",
    city_name: "Tallassee",
  },
  {
    postal_code: "40056",
    full_city_name: "Pewee Valley, KY",
    city_name: "Pewee Valley",
  },
  {
    postal_code: "18471",
    full_city_name: "Waverly, PA",
    city_name: "Waverly",
  },
  {
    postal_code: "94707",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "05158",
    full_city_name: "Westminster, VT",
    city_name: "Westminster",
  },
  {
    postal_code: "24963",
    full_city_name: "Peterstown, WV",
    city_name: "Peterstown",
  },
  {
    postal_code: "08248",
    full_city_name: "Strathmere, NJ",
    city_name: "Strathmere",
  },
  {
    postal_code: "54418",
    full_city_name: "Bryant, WI",
    city_name: "Bryant",
  },
  {
    postal_code: "39119",
    full_city_name: "Mount Olive, MS",
    city_name: "Mount Olive",
  },
  {
    postal_code: "81410",
    full_city_name: "Austin, CO",
    city_name: "Austin",
  },
  {
    postal_code: "04473",
    full_city_name: "Orono, ME",
    city_name: "Orono",
  },
  {
    postal_code: "28458",
    full_city_name: "Rose Hill, NC",
    city_name: "Rose Hill",
  },
  {
    postal_code: "05491",
    full_city_name: "Vergennes, VT",
    city_name: "Vergennes",
  },
  {
    postal_code: "21057",
    full_city_name: "Glen Arm, MD",
    city_name: "Glen Arm",
  },
  {
    postal_code: "43518",
    full_city_name: "Edon, OH",
    city_name: "Edon",
  },
  {
    postal_code: "55395",
    full_city_name: "Winsted, MN",
    city_name: "Winsted",
  },
  {
    postal_code: "44076",
    full_city_name: "Orwell, OH",
    city_name: "Orwell",
  },
  {
    postal_code: "25241",
    full_city_name: "Evans, WV",
    city_name: "Evans",
  },
  {
    postal_code: "44443",
    full_city_name: "New Springfield, OH",
    city_name: "New Springfield",
  },
  {
    postal_code: "16056",
    full_city_name: "Saxonburg, PA",
    city_name: "Saxonburg",
  },
  {
    postal_code: "20130",
    full_city_name: "Paris, VA",
    city_name: "Paris",
  },
  {
    postal_code: "92233",
    full_city_name: "Calipatria, CA",
    city_name: "Calipatria",
  },
  {
    postal_code: "62250",
    full_city_name: "Hoffman, IL",
    city_name: "Hoffman",
  },
  {
    postal_code: "23958",
    full_city_name: "Pamplin, VA",
    city_name: "Pamplin",
  },
  {
    postal_code: "93238",
    full_city_name: "Kernville, CA",
    city_name: "Kernville",
  },
  {
    postal_code: "84624",
    full_city_name: "Delta, UT",
    city_name: "Delta",
  },
  {
    postal_code: "04091",
    full_city_name: "West Baldwin, ME",
    city_name: "West Baldwin",
  },
  {
    postal_code: "13110",
    full_city_name: "Marietta, NY",
    city_name: "Marietta",
  },
  {
    postal_code: "92358",
    full_city_name: "Lytle Creek, CA",
    city_name: "Lytle Creek",
  },
  {
    postal_code: "61273",
    full_city_name: "Orion, IL",
    city_name: "Orion",
  },
  {
    postal_code: "94923",
    full_city_name: "Bodega Bay, CA",
    city_name: "Bodega Bay",
  },
  {
    postal_code: "17555",
    full_city_name: "Narvon, PA",
    city_name: "Narvon",
  },
  {
    postal_code: "49908",
    full_city_name: "Baraga, MI",
    city_name: "Baraga",
  },
  {
    postal_code: "60541",
    full_city_name: "Newark, IL",
    city_name: "Newark",
  },
  {
    postal_code: "01010",
    full_city_name: "Brimfield, MA",
    city_name: "Brimfield",
  },
  {
    postal_code: "84724",
    full_city_name: "Elsinore, UT",
    city_name: "Elsinore",
  },
  {
    postal_code: "04537",
    full_city_name: "Boothbay, ME",
    city_name: "Boothbay",
  },
  {
    postal_code: "31033",
    full_city_name: "Haddock, GA",
    city_name: "Haddock",
  },
  {
    postal_code: "06021",
    full_city_name: "Colebrook, CT",
    city_name: "Colebrook",
  },
  {
    postal_code: "80731",
    full_city_name: "Haxtun, CO",
    city_name: "Haxtun",
  },
  {
    postal_code: "97865",
    full_city_name: "Mount Vernon, OR",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "50237",
    full_city_name: "Runnells, IA",
    city_name: "Runnells",
  },
  {
    postal_code: "81320",
    full_city_name: "Cahone, CO",
    city_name: "Cahone",
  },
  {
    postal_code: "12923",
    full_city_name: "Churubusco, NY",
    city_name: "Churubusco",
  },
  {
    postal_code: "84662",
    full_city_name: "Spring City, UT",
    city_name: "Spring City",
  },
  {
    postal_code: "46161",
    full_city_name: "Morristown, IN",
    city_name: "Morristown",
  },
  {
    postal_code: "47040",
    full_city_name: "Rising Sun, IN",
    city_name: "Rising Sun",
  },
  {
    postal_code: "28654",
    full_city_name: "Moravian Falls, NC",
    city_name: "Moravian Falls",
  },
  {
    postal_code: "86320",
    full_city_name: "Ash Fork, AZ",
    city_name: "Ash Fork",
  },
  {
    postal_code: "61910",
    full_city_name: "Arcola, IL",
    city_name: "Arcola",
  },
  {
    postal_code: "54847",
    full_city_name: "Iron River, WI",
    city_name: "Iron River",
  },
  {
    postal_code: "76849",
    full_city_name: "Junction, TX",
    city_name: "Junction",
  },
  {
    postal_code: "03268",
    full_city_name: "Salisbury, NH",
    city_name: "Salisbury",
  },
  {
    postal_code: "59834",
    full_city_name: "Frenchtown, MT",
    city_name: "Frenchtown",
  },
  {
    postal_code: "53956",
    full_city_name: "Randolph, WI",
    city_name: "Randolph",
  },
  {
    postal_code: "63630",
    full_city_name: "Cadet, MO",
    city_name: "Cadet",
  },
  {
    postal_code: "96007",
    full_city_name: "Anderson, CA",
    city_name: "Anderson",
  },
  {
    postal_code: "13304",
    full_city_name: "Barneveld, NY",
    city_name: "Barneveld",
  },
  {
    postal_code: "95457",
    full_city_name: "Lower Lake, CA",
    city_name: "Lower Lake",
  },
  {
    postal_code: "72619",
    full_city_name: "Bull Shoals, AR",
    city_name: "Bull Shoals",
  },
  {
    postal_code: "47987",
    full_city_name: "Veedersburg, IN",
    city_name: "Veedersburg",
  },
  {
    postal_code: "77457",
    full_city_name: "Matagorda, TX",
    city_name: "Matagorda",
  },
  {
    postal_code: "08056",
    full_city_name: "Mickleton, NJ",
    city_name: "Mickleton",
  },
  {
    postal_code: "78408",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "29560",
    full_city_name: "Lake City, SC",
    city_name: "Lake City",
  },
  {
    postal_code: "74435",
    full_city_name: "Gore, OK",
    city_name: "Gore",
  },
  {
    postal_code: "18062",
    full_city_name: "Macungie, PA",
    city_name: "Macungie",
  },
  {
    postal_code: "88045",
    full_city_name: "Lordsburg, NM",
    city_name: "Lordsburg",
  },
  {
    postal_code: "21919",
    full_city_name: "Earleville, MD",
    city_name: "Earleville",
  },
  {
    postal_code: "15112",
    full_city_name: "East Pittsburgh, PA",
    city_name: "East Pittsburgh",
  },
  {
    postal_code: "49349",
    full_city_name: "White Cloud, MI",
    city_name: "White Cloud",
  },
  {
    postal_code: "59823",
    full_city_name: "Bonner, MT",
    city_name: "Bonner",
  },
  {
    postal_code: "12967",
    full_city_name: "North Lawrence, NY",
    city_name: "North Lawrence",
  },
  {
    postal_code: "38549",
    full_city_name: "Byrdstown, TN",
    city_name: "Byrdstown",
  },
  {
    postal_code: "01104",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "49663",
    full_city_name: "Manton, MI",
    city_name: "Manton",
  },
  {
    postal_code: "56352",
    full_city_name: "Melrose, MN",
    city_name: "Melrose",
  },
  {
    postal_code: "50130",
    full_city_name: "Jewell, IA",
    city_name: "Jewell",
  },
  {
    postal_code: "79329",
    full_city_name: "Idalou, TX",
    city_name: "Idalou",
  },
  {
    postal_code: "20618",
    full_city_name: "Bushwood, MD",
    city_name: "Bushwood",
  },
  {
    postal_code: "13032",
    full_city_name: "Canastota, NY",
    city_name: "Canastota",
  },
  {
    postal_code: "71033",
    full_city_name: "Greenwood, LA",
    city_name: "Greenwood",
  },
  {
    postal_code: "54023",
    full_city_name: "Roberts, WI",
    city_name: "Roberts",
  },
  {
    postal_code: "48301",
    full_city_name: "Bloomfield Hills, MI",
    city_name: "Bloomfield Hills",
  },
  {
    postal_code: "43532",
    full_city_name: "Liberty Center, OH",
    city_name: "Liberty Center",
  },
  {
    postal_code: "15458",
    full_city_name: "Mc Clellandtown, PA",
    city_name: "Mc Clellandtown",
  },
  {
    postal_code: "95814",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "48467",
    full_city_name: "Port Austin, MI",
    city_name: "Port Austin",
  },
  {
    postal_code: "28168",
    full_city_name: "Vale, NC",
    city_name: "Vale",
  },
  {
    postal_code: "54004",
    full_city_name: "Clayton, WI",
    city_name: "Clayton",
  },
  {
    postal_code: "13839",
    full_city_name: "Sidney Center, NY",
    city_name: "Sidney Center",
  },
  {
    postal_code: "58638",
    full_city_name: "Hebron, ND",
    city_name: "Hebron",
  },
  {
    postal_code: "96105",
    full_city_name: "Chilcoot, CA",
    city_name: "Chilcoot",
  },
  {
    postal_code: "26377",
    full_city_name: "Jacksonburg, WV",
    city_name: "Jacksonburg",
  },
  {
    postal_code: "83549",
    full_city_name: "Riggins, ID",
    city_name: "Riggins",
  },
  {
    postal_code: "22922",
    full_city_name: "Arrington, VA",
    city_name: "Arrington",
  },
  {
    postal_code: "82082",
    full_city_name: "Pine Bluffs, WY",
    city_name: "Pine Bluffs",
  },
  {
    postal_code: "98377",
    full_city_name: "Randle, WA",
    city_name: "Randle",
  },
  {
    postal_code: "50236",
    full_city_name: "Roland, IA",
    city_name: "Roland",
  },
  {
    postal_code: "48435",
    full_city_name: "Fostoria, MI",
    city_name: "Fostoria",
  },
  {
    postal_code: "16855",
    full_city_name: "Mineral Springs, PA",
    city_name: "Mineral Springs",
  },
  {
    postal_code: "24077",
    full_city_name: "Cloverdale, VA",
    city_name: "Cloverdale",
  },
  {
    postal_code: "74865",
    full_city_name: "Roff, OK",
    city_name: "Roff",
  },
  {
    postal_code: "12172",
    full_city_name: "Stottville, NY",
    city_name: "Stottville",
  },
  {
    postal_code: "13080",
    full_city_name: "Jordan, NY",
    city_name: "Jordan",
  },
  {
    postal_code: "16442",
    full_city_name: "Wattsburg, PA",
    city_name: "Wattsburg",
  },
  {
    postal_code: "04021",
    full_city_name: "Cumberland Center, ME",
    city_name: "Cumberland Center",
  },
  {
    postal_code: "80728",
    full_city_name: "Fleming, CO",
    city_name: "Fleming",
  },
  {
    postal_code: "21767",
    full_city_name: "Maugansville, MD",
    city_name: "Maugansville",
  },
  {
    postal_code: "84622",
    full_city_name: "Centerfield, UT",
    city_name: "Centerfield",
  },
  {
    postal_code: "93523",
    full_city_name: "Edwards, CA",
    city_name: "Edwards",
  },
  {
    postal_code: "12125",
    full_city_name: "New Lebanon, NY",
    city_name: "New Lebanon",
  },
  {
    postal_code: "03858",
    full_city_name: "Newton, NH",
    city_name: "Newton",
  },
  {
    postal_code: "13083",
    full_city_name: "Lacona, NY",
    city_name: "Lacona",
  },
  {
    postal_code: "50002",
    full_city_name: "Adair, IA",
    city_name: "Adair",
  },
  {
    postal_code: "69165",
    full_city_name: "Sutherland, NE",
    city_name: "Sutherland",
  },
  {
    postal_code: "17009",
    full_city_name: "Burnham, PA",
    city_name: "Burnham",
  },
  {
    postal_code: "45645",
    full_city_name: "Kitts Hill, OH",
    city_name: "Kitts Hill",
  },
  {
    postal_code: "76622",
    full_city_name: "Aquilla, TX",
    city_name: "Aquilla",
  },
  {
    postal_code: "19031",
    full_city_name: "Flourtown, PA",
    city_name: "Flourtown",
  },
  {
    postal_code: "03084",
    full_city_name: "Temple, NH",
    city_name: "Temple",
  },
  {
    postal_code: "45146",
    full_city_name: "Martinsville, OH",
    city_name: "Martinsville",
  },
  {
    postal_code: "37891",
    full_city_name: "Whitesburg, TN",
    city_name: "Whitesburg",
  },
  {
    postal_code: "55332",
    full_city_name: "Fairfax, MN",
    city_name: "Fairfax",
  },
  {
    postal_code: "49262",
    full_city_name: "North Adams, MI",
    city_name: "North Adams",
  },
  {
    postal_code: "16052",
    full_city_name: "Prospect, PA",
    city_name: "Prospect",
  },
  {
    postal_code: "55802",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "65789",
    full_city_name: "Pomona, MO",
    city_name: "Pomona",
  },
  {
    postal_code: "41010",
    full_city_name: "Corinth, KY",
    city_name: "Corinth",
  },
  {
    postal_code: "12465",
    full_city_name: "Pine Hill, NY",
    city_name: "Pine Hill",
  },
  {
    postal_code: "55726",
    full_city_name: "Cromwell, MN",
    city_name: "Cromwell",
  },
  {
    postal_code: "44613",
    full_city_name: "Brewster, OH",
    city_name: "Brewster",
  },
  {
    postal_code: "18623",
    full_city_name: "Laceyville, PA",
    city_name: "Laceyville",
  },
  {
    postal_code: "78341",
    full_city_name: "Benavides, TX",
    city_name: "Benavides",
  },
  {
    postal_code: "80454",
    full_city_name: "Indian Hills, CO",
    city_name: "Indian Hills",
  },
  {
    postal_code: "28373",
    full_city_name: "Pinebluff, NC",
    city_name: "Pinebluff",
  },
  {
    postal_code: "28137",
    full_city_name: "Richfield, NC",
    city_name: "Richfield",
  },
  {
    postal_code: "49259",
    full_city_name: "Munith, MI",
    city_name: "Munith",
  },
  {
    postal_code: "45034",
    full_city_name: "Kings Mills, OH",
    city_name: "Kings Mills",
  },
  {
    postal_code: "32187",
    full_city_name: "San Mateo, FL",
    city_name: "San Mateo",
  },
  {
    postal_code: "59747",
    full_city_name: "Pony, MT",
    city_name: "Pony",
  },
  {
    postal_code: "03874",
    full_city_name: "Seabrook, NH",
    city_name: "Seabrook",
  },
  {
    postal_code: "44065",
    full_city_name: "Newbury, OH",
    city_name: "Newbury",
  },
  {
    postal_code: "67514",
    full_city_name: "Arlington, KS",
    city_name: "Arlington",
  },
  {
    postal_code: "06235",
    full_city_name: "Chaplin, CT",
    city_name: "Chaplin",
  },
  {
    postal_code: "49271",
    full_city_name: "Pittsford, MI",
    city_name: "Pittsford",
  },
  {
    postal_code: "17938",
    full_city_name: "Hegins, PA",
    city_name: "Hegins",
  },
  {
    postal_code: "54175",
    full_city_name: "Townsend, WI",
    city_name: "Townsend",
  },
  {
    postal_code: "41226",
    full_city_name: "Keaton, KY",
    city_name: "Keaton",
  },
  {
    postal_code: "02779",
    full_city_name: "Berkley, MA",
    city_name: "Berkley",
  },
  {
    postal_code: "61732",
    full_city_name: "Danvers, IL",
    city_name: "Danvers",
  },
  {
    postal_code: "31002",
    full_city_name: "Adrian, GA",
    city_name: "Adrian",
  },
  {
    postal_code: "61006",
    full_city_name: "Ashton, IL",
    city_name: "Ashton",
  },
  {
    postal_code: "62271",
    full_city_name: "Okawville, IL",
    city_name: "Okawville",
  },
  {
    postal_code: "94951",
    full_city_name: "Penngrove, CA",
    city_name: "Penngrove",
  },
  {
    postal_code: "23937",
    full_city_name: "Drakes Branch, VA",
    city_name: "Drakes Branch",
  },
  {
    postal_code: "56524",
    full_city_name: "Clitherall, MN",
    city_name: "Clitherall",
  },
  {
    postal_code: "35097",
    full_city_name: "Locust Fork, AL",
    city_name: "Locust Fork",
  },
  {
    postal_code: "46117",
    full_city_name: "Charlottesville, IN",
    city_name: "Charlottesville",
  },
  {
    postal_code: "62265",
    full_city_name: "New Baden, IL",
    city_name: "New Baden",
  },
  {
    postal_code: "27231",
    full_city_name: "Cedar Grove, NC",
    city_name: "Cedar Grove",
  },
  {
    postal_code: "25168",
    full_city_name: "Red House, WV",
    city_name: "Red House",
  },
  {
    postal_code: "16045",
    full_city_name: "Lyndora, PA",
    city_name: "Lyndora",
  },
  {
    postal_code: "47838",
    full_city_name: "Carlisle, IN",
    city_name: "Carlisle",
  },
  {
    postal_code: "29712",
    full_city_name: "Edgemoor, SC",
    city_name: "Edgemoor",
  },
  {
    postal_code: "24290",
    full_city_name: "Weber City, VA",
    city_name: "Weber City",
  },
  {
    postal_code: "45814",
    full_city_name: "Arlington, OH",
    city_name: "Arlington",
  },
  {
    postal_code: "80512",
    full_city_name: "Bellvue, CO",
    city_name: "Bellvue",
  },
  {
    postal_code: "49733",
    full_city_name: "Frederic, MI",
    city_name: "Frederic",
  },
  {
    postal_code: "06481",
    full_city_name: "Rockfall, CT",
    city_name: "Rockfall",
  },
  {
    postal_code: "17049",
    full_city_name: "Mc Alisterville, PA",
    city_name: "Mc Alisterville",
  },
  {
    postal_code: "28524",
    full_city_name: "Davis, NC",
    city_name: "Davis",
  },
  {
    postal_code: "80532",
    full_city_name: "Glen Haven, CO",
    city_name: "Glen Haven",
  },
  {
    postal_code: "44675",
    full_city_name: "Sherrodsville, OH",
    city_name: "Sherrodsville",
  },
  {
    postal_code: "80737",
    full_city_name: "Julesburg, CO",
    city_name: "Julesburg",
  },
  {
    postal_code: "53588",
    full_city_name: "Spring Green, WI",
    city_name: "Spring Green",
  },
  {
    postal_code: "80520",
    full_city_name: "Firestone, CO",
    city_name: "Firestone",
  },
  {
    postal_code: "16020",
    full_city_name: "Boyers, PA",
    city_name: "Boyers",
  },
  {
    postal_code: "13144",
    full_city_name: "Richland, NY",
    city_name: "Richland",
  },
  {
    postal_code: "83832",
    full_city_name: "Genesee, ID",
    city_name: "Genesee",
  },
  {
    postal_code: "23086",
    full_city_name: "King William, VA",
    city_name: "King William",
  },
  {
    postal_code: "63380",
    full_city_name: "Truesdale, MO",
    city_name: "Truesdale",
  },
  {
    postal_code: "56160",
    full_city_name: "Odin, MN",
    city_name: "Odin",
  },
  {
    postal_code: "42355",
    full_city_name: "Maceo, KY",
    city_name: "Maceo",
  },
  {
    postal_code: "61329",
    full_city_name: "Ladd, IL",
    city_name: "Ladd",
  },
  {
    postal_code: "75172",
    full_city_name: "Wilmer, TX",
    city_name: "Wilmer",
  },
  {
    postal_code: "03445",
    full_city_name: "Sullivan, NH",
    city_name: "Sullivan",
  },
  {
    postal_code: "54227",
    full_city_name: "Maribel, WI",
    city_name: "Maribel",
  },
  {
    postal_code: "44814",
    full_city_name: "Berlin Heights, OH",
    city_name: "Berlin Heights",
  },
  {
    postal_code: "59931",
    full_city_name: "Rollins, MT",
    city_name: "Rollins",
  },
  {
    postal_code: "46371",
    full_city_name: "Rolling Prairie, IN",
    city_name: "Rolling Prairie",
  },
  {
    postal_code: "42437",
    full_city_name: "Morganfield, KY",
    city_name: "Morganfield",
  },
  {
    postal_code: "16740",
    full_city_name: "Mount Jewett, PA",
    city_name: "Mount Jewett",
  },
  {
    postal_code: "94929",
    full_city_name: "Dillon Beach, CA",
    city_name: "Dillon Beach",
  },
  {
    postal_code: "17007",
    full_city_name: "Boiling Springs, PA",
    city_name: "Boiling Springs",
  },
  {
    postal_code: "53168",
    full_city_name: "Salem, WI",
    city_name: "Salem",
  },
  {
    postal_code: "97523",
    full_city_name: "Cave Junction, OR",
    city_name: "Cave Junction",
  },
  {
    postal_code: "28740",
    full_city_name: "Green Mountain, NC",
    city_name: "Green Mountain",
  },
  {
    postal_code: "59645",
    full_city_name: "White Sulphur Springs, MT",
    city_name: "White Sulphur Springs",
  },
  {
    postal_code: "52040",
    full_city_name: "Dyersville, IA",
    city_name: "Dyersville",
  },
  {
    postal_code: "13302",
    full_city_name: "Altmar, NY",
    city_name: "Altmar",
  },
  {
    postal_code: "78575",
    full_city_name: "Olmito, TX",
    city_name: "Olmito",
  },
  {
    postal_code: "18252",
    full_city_name: "Tamaqua, PA",
    city_name: "Tamaqua",
  },
  {
    postal_code: "91932",
    full_city_name: "Imperial Beach, CA",
    city_name: "Imperial Beach",
  },
  {
    postal_code: "72576",
    full_city_name: "Salem, AR",
    city_name: "Salem",
  },
  {
    postal_code: "45723",
    full_city_name: "Coolville, OH",
    city_name: "Coolville",
  },
  {
    postal_code: "01451",
    full_city_name: "Harvard, MA",
    city_name: "Harvard",
  },
  {
    postal_code: "17922",
    full_city_name: "Auburn, PA",
    city_name: "Auburn",
  },
  {
    postal_code: "88043",
    full_city_name: "Hurley, NM",
    city_name: "Hurley",
  },
  {
    postal_code: "72315",
    full_city_name: "Blytheville, AR",
    city_name: "Blytheville",
  },
  {
    postal_code: "12428",
    full_city_name: "Ellenville, NY",
    city_name: "Ellenville",
  },
  {
    postal_code: "08078",
    full_city_name: "Runnemede, NJ",
    city_name: "Runnemede",
  },
  {
    postal_code: "05761",
    full_city_name: "Pawlet, VT",
    city_name: "Pawlet",
  },
  {
    postal_code: "58318",
    full_city_name: "Bottineau, ND",
    city_name: "Bottineau",
  },
  {
    postal_code: "04463",
    full_city_name: "Milo, ME",
    city_name: "Milo",
  },
  {
    postal_code: "74346",
    full_city_name: "Jay, OK",
    city_name: "Jay",
  },
  {
    postal_code: "45167",
    full_city_name: "Ripley, OH",
    city_name: "Ripley",
  },
  {
    postal_code: "49656",
    full_city_name: "Luther, MI",
    city_name: "Luther",
  },
  {
    postal_code: "74735",
    full_city_name: "Fort Towson, OK",
    city_name: "Fort Towson",
  },
  {
    postal_code: "78873",
    full_city_name: "Leakey, TX",
    city_name: "Leakey",
  },
  {
    postal_code: "24148",
    full_city_name: "Ridgeway, VA",
    city_name: "Ridgeway",
  },
  {
    postal_code: "49229",
    full_city_name: "Britton, MI",
    city_name: "Britton",
  },
  {
    postal_code: "16617",
    full_city_name: "Bellwood, PA",
    city_name: "Bellwood",
  },
  {
    postal_code: "70085",
    full_city_name: "Saint Bernard, LA",
    city_name: "Saint Bernard",
  },
  {
    postal_code: "37361",
    full_city_name: "Ocoee, TN",
    city_name: "Ocoee",
  },
  {
    postal_code: "62644",
    full_city_name: "Havana, IL",
    city_name: "Havana",
  },
  {
    postal_code: "62924",
    full_city_name: "De Soto, IL",
    city_name: "De Soto",
  },
  {
    postal_code: "32694",
    full_city_name: "Waldo, FL",
    city_name: "Waldo",
  },
  {
    postal_code: "77984",
    full_city_name: "Shiner, TX",
    city_name: "Shiner",
  },
  {
    postal_code: "58746",
    full_city_name: "Kenmare, ND",
    city_name: "Kenmare",
  },
  {
    postal_code: "98930",
    full_city_name: "Grandview, WA",
    city_name: "Grandview",
  },
  {
    postal_code: "85645",
    full_city_name: "Amado, AZ",
    city_name: "Amado",
  },
  {
    postal_code: "52655",
    full_city_name: "West Burlington, IA",
    city_name: "West Burlington",
  },
  {
    postal_code: "55946",
    full_city_name: "Kenyon, MN",
    city_name: "Kenyon",
  },
  {
    postal_code: "17370",
    full_city_name: "York Haven, PA",
    city_name: "York Haven",
  },
  {
    postal_code: "53010",
    full_city_name: "Campbellsport, WI",
    city_name: "Campbellsport",
  },
  {
    postal_code: "17307",
    full_city_name: "Biglerville, PA",
    city_name: "Biglerville",
  },
  {
    postal_code: "46148",
    full_city_name: "Knightstown, IN",
    city_name: "Knightstown",
  },
  {
    postal_code: "27920",
    full_city_name: "Buxton, NC",
    city_name: "Buxton",
  },
  {
    postal_code: "61914",
    full_city_name: "Bethany, IL",
    city_name: "Bethany",
  },
  {
    postal_code: "45644",
    full_city_name: "Kingston, OH",
    city_name: "Kingston",
  },
  {
    postal_code: "95962",
    full_city_name: "Oregon House, CA",
    city_name: "Oregon House",
  },
  {
    postal_code: "69129",
    full_city_name: "Chappell, NE",
    city_name: "Chappell",
  },
  {
    postal_code: "28357",
    full_city_name: "Lumber Bridge, NC",
    city_name: "Lumber Bridge",
  },
  {
    postal_code: "31705",
    full_city_name: "Albany, GA",
    city_name: "Albany",
  },
  {
    postal_code: "01950",
    full_city_name: "Newburyport, MA",
    city_name: "Newburyport",
  },
  {
    postal_code: "05655",
    full_city_name: "Hyde Park, VT",
    city_name: "Hyde Park",
  },
  {
    postal_code: "04027",
    full_city_name: "Lebanon, ME",
    city_name: "Lebanon",
  },
  {
    postal_code: "97064",
    full_city_name: "Vernonia, OR",
    city_name: "Vernonia",
  },
  {
    postal_code: "85630",
    full_city_name: "Saint David, AZ",
    city_name: "Saint David",
  },
  {
    postal_code: "61747",
    full_city_name: "Hopedale, IL",
    city_name: "Hopedale",
  },
  {
    postal_code: "63120",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "22610",
    full_city_name: "Bentonville, VA",
    city_name: "Bentonville",
  },
  {
    postal_code: "65013",
    full_city_name: "Belle, MO",
    city_name: "Belle",
  },
  {
    postal_code: "15411",
    full_city_name: "Addison, PA",
    city_name: "Addison",
  },
  {
    postal_code: "14454",
    full_city_name: "Geneseo, NY",
    city_name: "Geneseo",
  },
  {
    postal_code: "47042",
    full_city_name: "Versailles, IN",
    city_name: "Versailles",
  },
  {
    postal_code: "18326",
    full_city_name: "Cresco, PA",
    city_name: "Cresco",
  },
  {
    postal_code: "38040",
    full_city_name: "Halls, TN",
    city_name: "Halls",
  },
  {
    postal_code: "70359",
    full_city_name: "Gray, LA",
    city_name: "Gray",
  },
  {
    postal_code: "76638",
    full_city_name: "Crawford, TX",
    city_name: "Crawford",
  },
  {
    postal_code: "01245",
    full_city_name: "Monterey, MA",
    city_name: "Monterey",
  },
  {
    postal_code: "17073",
    full_city_name: "Newmanstown, PA",
    city_name: "Newmanstown",
  },
  {
    postal_code: "46517",
    full_city_name: "Elkhart, IN",
    city_name: "Elkhart",
  },
  {
    postal_code: "31707",
    full_city_name: "Albany, GA",
    city_name: "Albany",
  },
  {
    postal_code: "49759",
    full_city_name: "Millersburg, MI",
    city_name: "Millersburg",
  },
  {
    postal_code: "18222",
    full_city_name: "Drums, PA",
    city_name: "Drums",
  },
  {
    postal_code: "48327",
    full_city_name: "Waterford, MI",
    city_name: "Waterford",
  },
  {
    postal_code: "23872",
    full_city_name: "Mc Kenney, VA",
    city_name: "Mc Kenney",
  },
  {
    postal_code: "20119",
    full_city_name: "Catlett, VA",
    city_name: "Catlett",
  },
  {
    postal_code: "48422",
    full_city_name: "Croswell, MI",
    city_name: "Croswell",
  },
  {
    postal_code: "28615",
    full_city_name: "Creston, NC",
    city_name: "Creston",
  },
  {
    postal_code: "35204",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "02482",
    full_city_name: "Wellesley, MA",
    city_name: "Wellesley",
  },
  {
    postal_code: "78358",
    full_city_name: "Fulton, TX",
    city_name: "Fulton",
  },
  {
    postal_code: "98612",
    full_city_name: "Cathlamet, WA",
    city_name: "Cathlamet",
  },
  {
    postal_code: "63966",
    full_city_name: "Wappapello, MO",
    city_name: "Wappapello",
  },
  {
    postal_code: "04864",
    full_city_name: "Warren, ME",
    city_name: "Warren",
  },
  {
    postal_code: "07108",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "54873",
    full_city_name: "Solon Springs, WI",
    city_name: "Solon Springs",
  },
  {
    postal_code: "15233",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "48420",
    full_city_name: "Clio, MI",
    city_name: "Clio",
  },
  {
    postal_code: "54519",
    full_city_name: "Conover, WI",
    city_name: "Conover",
  },
  {
    postal_code: "79022",
    full_city_name: "Dalhart, TX",
    city_name: "Dalhart",
  },
  {
    postal_code: "54636",
    full_city_name: "Holmen, WI",
    city_name: "Holmen",
  },
  {
    postal_code: "48025",
    full_city_name: "Franklin, MI",
    city_name: "Franklin",
  },
  {
    postal_code: "17316",
    full_city_name: "East Berlin, PA",
    city_name: "East Berlin",
  },
  {
    postal_code: "12444",
    full_city_name: "Jewett, NY",
    city_name: "Jewett",
  },
  {
    postal_code: "21631",
    full_city_name: "East New Market, MD",
    city_name: "East New Market",
  },
  {
    postal_code: "66046",
    full_city_name: "Lawrence, KS",
    city_name: "Lawrence",
  },
  {
    postal_code: "11507",
    full_city_name: "Albertson, NY",
    city_name: "Albertson",
  },
  {
    postal_code: "54529",
    full_city_name: "Harshaw, WI",
    city_name: "Harshaw",
  },
  {
    postal_code: "65565",
    full_city_name: "Steelville, MO",
    city_name: "Steelville",
  },
  {
    postal_code: "04349",
    full_city_name: "Kents Hill, ME",
    city_name: "Kents Hill",
  },
  {
    postal_code: "07103",
    full_city_name: "Newark, NJ",
    city_name: "Newark",
  },
  {
    postal_code: "04619",
    full_city_name: "Calais, ME",
    city_name: "Calais",
  },
  {
    postal_code: "70394",
    full_city_name: "Raceland, LA",
    city_name: "Raceland",
  },
  {
    postal_code: "32053",
    full_city_name: "Jennings, FL",
    city_name: "Jennings",
  },
  {
    postal_code: "18640",
    full_city_name: "Pittston, PA",
    city_name: "Pittston",
  },
  {
    postal_code: "19133",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "82501",
    full_city_name: "Riverton, WY",
    city_name: "Riverton",
  },
  {
    postal_code: "81623",
    full_city_name: "Carbondale, CO",
    city_name: "Carbondale",
  },
  {
    postal_code: "78629",
    full_city_name: "Gonzales, TX",
    city_name: "Gonzales",
  },
  {
    postal_code: "48317",
    full_city_name: "Utica, MI",
    city_name: "Utica",
  },
  {
    postal_code: "24301",
    full_city_name: "Pulaski, VA",
    city_name: "Pulaski",
  },
  {
    postal_code: "02446",
    full_city_name: "Brookline, MA",
    city_name: "Brookline",
  },
  {
    postal_code: "58722",
    full_city_name: "Burlington, ND",
    city_name: "Burlington",
  },
  {
    postal_code: "12843",
    full_city_name: "Johnsburg, NY",
    city_name: "Johnsburg",
  },
  {
    postal_code: "18015",
    full_city_name: "Bethlehem, PA",
    city_name: "Bethlehem",
  },
  {
    postal_code: "87047",
    full_city_name: "Sandia Park, NM",
    city_name: "Sandia Park",
  },
  {
    postal_code: "98663",
    full_city_name: "Vancouver, WA",
    city_name: "Vancouver",
  },
  {
    postal_code: "03608",
    full_city_name: "Walpole, NH",
    city_name: "Walpole",
  },
  {
    postal_code: "54009",
    full_city_name: "Dresser, WI",
    city_name: "Dresser",
  },
  {
    postal_code: "44430",
    full_city_name: "Leavittsburg, OH",
    city_name: "Leavittsburg",
  },
  {
    postal_code: "50525",
    full_city_name: "Clarion, IA",
    city_name: "Clarion",
  },
  {
    postal_code: "57718",
    full_city_name: "Black Hawk, SD",
    city_name: "Black Hawk",
  },
  {
    postal_code: "36303",
    full_city_name: "Dothan, AL",
    city_name: "Dothan",
  },
  {
    postal_code: "79735",
    full_city_name: "Fort Stockton, TX",
    city_name: "Fort Stockton",
  },
  {
    postal_code: "86024",
    full_city_name: "Happy Jack, AZ",
    city_name: "Happy Jack",
  },
  {
    postal_code: "75640",
    full_city_name: "Diana, TX",
    city_name: "Diana",
  },
  {
    postal_code: "71964",
    full_city_name: "Pearcy, AR",
    city_name: "Pearcy",
  },
  {
    postal_code: "27947",
    full_city_name: "Jarvisburg, NC",
    city_name: "Jarvisburg",
  },
  {
    postal_code: "12993",
    full_city_name: "Westport, NY",
    city_name: "Westport",
  },
  {
    postal_code: "12564",
    full_city_name: "Pawling, NY",
    city_name: "Pawling",
  },
  {
    postal_code: "49305",
    full_city_name: "Barryton, MI",
    city_name: "Barryton",
  },
  {
    postal_code: "16116",
    full_city_name: "Edinburg, PA",
    city_name: "Edinburg",
  },
  {
    postal_code: "02122",
    full_city_name: "Dorchester, MA",
    city_name: "Dorchester",
  },
  {
    postal_code: "20778",
    full_city_name: "West River, MD",
    city_name: "West River",
  },
  {
    postal_code: "71832",
    full_city_name: "De Queen, AR",
    city_name: "De Queen",
  },
  {
    postal_code: "43988",
    full_city_name: "Scio, OH",
    city_name: "Scio",
  },
  {
    postal_code: "32340",
    full_city_name: "Madison, FL",
    city_name: "Madison",
  },
  {
    postal_code: "04217",
    full_city_name: "Bethel, ME",
    city_name: "Bethel",
  },
  {
    postal_code: "31701",
    full_city_name: "Albany, GA",
    city_name: "Albany",
  },
  {
    postal_code: "59639",
    full_city_name: "Lincoln, MT",
    city_name: "Lincoln",
  },
  {
    postal_code: "02650",
    full_city_name: "North Chatham, MA",
    city_name: "North Chatham",
  },
  {
    postal_code: "56528",
    full_city_name: "Dent, MN",
    city_name: "Dent",
  },
  {
    postal_code: "71322",
    full_city_name: "Bunkie, LA",
    city_name: "Bunkie",
  },
  {
    postal_code: "74953",
    full_city_name: "Poteau, OK",
    city_name: "Poteau",
  },
  {
    postal_code: "04493",
    full_city_name: "West Enfield, ME",
    city_name: "West Enfield",
  },
  {
    postal_code: "48647",
    full_city_name: "Mio, MI",
    city_name: "Mio",
  },
  {
    postal_code: "06607",
    full_city_name: "Bridgeport, CT",
    city_name: "Bridgeport",
  },
  {
    postal_code: "08501",
    full_city_name: "Allentown, NJ",
    city_name: "Allentown",
  },
  {
    postal_code: "85004",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "41175",
    full_city_name: "South Shore, KY",
    city_name: "South Shore",
  },
  {
    postal_code: "84759",
    full_city_name: "Panguitch, UT",
    city_name: "Panguitch",
  },
  {
    postal_code: "31092",
    full_city_name: "Vienna, GA",
    city_name: "Vienna",
  },
  {
    postal_code: "20004",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "50841",
    full_city_name: "Corning, IA",
    city_name: "Corning",
  },
  {
    postal_code: "22734",
    full_city_name: "Remington, VA",
    city_name: "Remington",
  },
  {
    postal_code: "48734",
    full_city_name: "Frankenmuth, MI",
    city_name: "Frankenmuth",
  },
  {
    postal_code: "99173",
    full_city_name: "Springdale, WA",
    city_name: "Springdale",
  },
  {
    postal_code: "53190",
    full_city_name: "Whitewater, WI",
    city_name: "Whitewater",
  },
  {
    postal_code: "71103",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "47032",
    full_city_name: "Moores Hill, IN",
    city_name: "Moores Hill",
  },
  {
    postal_code: "29655",
    full_city_name: "Iva, SC",
    city_name: "Iva",
  },
  {
    postal_code: "03240",
    full_city_name: "Grafton, NH",
    city_name: "Grafton",
  },
  {
    postal_code: "12966",
    full_city_name: "North Bangor, NY",
    city_name: "North Bangor",
  },
  {
    postal_code: "48239",
    full_city_name: "Redford, MI",
    city_name: "Redford",
  },
  {
    postal_code: "89413",
    full_city_name: "Glenbrook, NV",
    city_name: "Glenbrook",
  },
  {
    postal_code: "02140",
    full_city_name: "Cambridge, MA",
    city_name: "Cambridge",
  },
  {
    postal_code: "93518",
    full_city_name: "Caliente, CA",
    city_name: "Caliente",
  },
  {
    postal_code: "35553",
    full_city_name: "Double Springs, AL",
    city_name: "Double Springs",
  },
  {
    postal_code: "37407",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "17347",
    full_city_name: "Mount Wolf, PA",
    city_name: "Mount Wolf",
  },
  {
    postal_code: "02061",
    full_city_name: "Norwell, MA",
    city_name: "Norwell",
  },
  {
    postal_code: "18925",
    full_city_name: "Furlong, PA",
    city_name: "Furlong",
  },
  {
    postal_code: "93280",
    full_city_name: "Wasco, CA",
    city_name: "Wasco",
  },
  {
    postal_code: "57744",
    full_city_name: "Hermosa, SD",
    city_name: "Hermosa",
  },
  {
    postal_code: "22511",
    full_city_name: "Lottsburg, VA",
    city_name: "Lottsburg",
  },
  {
    postal_code: "22637",
    full_city_name: "Gore, VA",
    city_name: "Gore",
  },
  {
    postal_code: "12878",
    full_city_name: "Stony Creek, NY",
    city_name: "Stony Creek",
  },
  {
    postal_code: "56636",
    full_city_name: "Deer River, MN",
    city_name: "Deer River",
  },
  {
    postal_code: "38551",
    full_city_name: "Celina, TN",
    city_name: "Celina",
  },
  {
    postal_code: "72131",
    full_city_name: "Quitman, AR",
    city_name: "Quitman",
  },
  {
    postal_code: "32054",
    full_city_name: "Lake Butler, FL",
    city_name: "Lake Butler",
  },
  {
    postal_code: "71467",
    full_city_name: "Pollock, LA",
    city_name: "Pollock",
  },
  {
    postal_code: "97053",
    full_city_name: "Warren, OR",
    city_name: "Warren",
  },
  {
    postal_code: "61087",
    full_city_name: "Warren, IL",
    city_name: "Warren",
  },
  {
    postal_code: "75426",
    full_city_name: "Clarksville, TX",
    city_name: "Clarksville",
  },
  {
    postal_code: "13403",
    full_city_name: "Marcy, NY",
    city_name: "Marcy",
  },
  {
    postal_code: "49042",
    full_city_name: "Constantine, MI",
    city_name: "Constantine",
  },
  {
    postal_code: "95245",
    full_city_name: "Mokelumne Hill, CA",
    city_name: "Mokelumne Hill",
  },
  {
    postal_code: "68818",
    full_city_name: "Aurora, NE",
    city_name: "Aurora",
  },
  {
    postal_code: "03906",
    full_city_name: "North Berwick, ME",
    city_name: "North Berwick",
  },
  {
    postal_code: "77968",
    full_city_name: "Inez, TX",
    city_name: "Inez",
  },
  {
    postal_code: "54616",
    full_city_name: "Blair, WI",
    city_name: "Blair",
  },
  {
    postal_code: "02110",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "13740",
    full_city_name: "Bovina Center, NY",
    city_name: "Bovina Center",
  },
  {
    postal_code: "50058",
    full_city_name: "Coon Rapids, IA",
    city_name: "Coon Rapids",
  },
  {
    postal_code: "06380",
    full_city_name: "Taftville, CT",
    city_name: "Taftville",
  },
  {
    postal_code: "66021",
    full_city_name: "Edgerton, KS",
    city_name: "Edgerton",
  },
  {
    postal_code: "72626",
    full_city_name: "Cotter, AR",
    city_name: "Cotter",
  },
  {
    postal_code: "55959",
    full_city_name: "Minnesota City, MN",
    city_name: "Minnesota City",
  },
  {
    postal_code: "01835",
    full_city_name: "Haverhill, MA",
    city_name: "Haverhill",
  },
  {
    postal_code: "62844",
    full_city_name: "Grayville, IL",
    city_name: "Grayville",
  },
  {
    postal_code: "32759",
    full_city_name: "Oak Hill, FL",
    city_name: "Oak Hill",
  },
  {
    postal_code: "48453",
    full_city_name: "Marlette, MI",
    city_name: "Marlette",
  },
  {
    postal_code: "56653",
    full_city_name: "Littlefork, MN",
    city_name: "Littlefork",
  },
  {
    postal_code: "77430",
    full_city_name: "Damon, TX",
    city_name: "Damon",
  },
  {
    postal_code: "05035",
    full_city_name: "Bridgewater Corners, VT",
    city_name: "Bridgewater Corners",
  },
  {
    postal_code: "04957",
    full_city_name: "Norridgewock, ME",
    city_name: "Norridgewock",
  },
  {
    postal_code: "16947",
    full_city_name: "Troy, PA",
    city_name: "Troy",
  },
  {
    postal_code: "15431",
    full_city_name: "Dunbar, PA",
    city_name: "Dunbar",
  },
  {
    postal_code: "47459",
    full_city_name: "Solsberry, IN",
    city_name: "Solsberry",
  },
  {
    postal_code: "49130",
    full_city_name: "Union, MI",
    city_name: "Union",
  },
  {
    postal_code: "49753",
    full_city_name: "Lachine, MI",
    city_name: "Lachine",
  },
  {
    postal_code: "05837",
    full_city_name: "East Haven, VT",
    city_name: "East Haven",
  },
  {
    postal_code: "16143",
    full_city_name: "Pulaski, PA",
    city_name: "Pulaski",
  },
  {
    postal_code: "81525",
    full_city_name: "Mack, CO",
    city_name: "Mack",
  },
  {
    postal_code: "93267",
    full_city_name: "Strathmore, CA",
    city_name: "Strathmore",
  },
  {
    postal_code: "64136",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "65618",
    full_city_name: "Brixey, MO",
    city_name: "Brixey",
  },
  {
    postal_code: "35172",
    full_city_name: "Trafford, AL",
    city_name: "Trafford",
  },
  {
    postal_code: "98024",
    full_city_name: "Fall City, WA",
    city_name: "Fall City",
  },
  {
    postal_code: "05743",
    full_city_name: "Fair Haven, VT",
    city_name: "Fair Haven",
  },
  {
    postal_code: "01475",
    full_city_name: "Winchendon, MA",
    city_name: "Winchendon",
  },
  {
    postal_code: "04254",
    full_city_name: "Livermore Falls, ME",
    city_name: "Livermore Falls",
  },
  {
    postal_code: "95689",
    full_city_name: "Volcano, CA",
    city_name: "Volcano",
  },
  {
    postal_code: "45828",
    full_city_name: "Coldwater, OH",
    city_name: "Coldwater",
  },
  {
    postal_code: "46390",
    full_city_name: "Wanatah, IN",
    city_name: "Wanatah",
  },
  {
    postal_code: "83241",
    full_city_name: "Grace, ID",
    city_name: "Grace",
  },
  {
    postal_code: "48159",
    full_city_name: "Maybee, MI",
    city_name: "Maybee",
  },
  {
    postal_code: "65323",
    full_city_name: "Calhoun, MO",
    city_name: "Calhoun",
  },
  {
    postal_code: "61361",
    full_city_name: "Sheffield, IL",
    city_name: "Sheffield",
  },
  {
    postal_code: "48766",
    full_city_name: "Twining, MI",
    city_name: "Twining",
  },
  {
    postal_code: "61012",
    full_city_name: "Capron, IL",
    city_name: "Capron",
  },
  {
    postal_code: "92254",
    full_city_name: "Mecca, CA",
    city_name: "Mecca",
  },
  {
    postal_code: "28344",
    full_city_name: "Godwin, NC",
    city_name: "Godwin",
  },
  {
    postal_code: "23047",
    full_city_name: "Doswell, VA",
    city_name: "Doswell",
  },
  {
    postal_code: "88314",
    full_city_name: "Bent, NM",
    city_name: "Bent",
  },
  {
    postal_code: "60407",
    full_city_name: "Braceville, IL",
    city_name: "Braceville",
  },
  {
    postal_code: "03216",
    full_city_name: "Andover, NH",
    city_name: "Andover",
  },
  {
    postal_code: "45732",
    full_city_name: "Glouster, OH",
    city_name: "Glouster",
  },
  {
    postal_code: "04095",
    full_city_name: "West Newfield, ME",
    city_name: "West Newfield",
  },
  {
    postal_code: "05352",
    full_city_name: "Stamford, VT",
    city_name: "Stamford",
  },
  {
    postal_code: "76534",
    full_city_name: "Holland, TX",
    city_name: "Holland",
  },
  {
    postal_code: "13325",
    full_city_name: "Constableville, NY",
    city_name: "Constableville",
  },
  {
    postal_code: "50207",
    full_city_name: "New Sharon, IA",
    city_name: "New Sharon",
  },
  {
    postal_code: "54770",
    full_city_name: "Strum, WI",
    city_name: "Strum",
  },
  {
    postal_code: "93625",
    full_city_name: "Fowler, CA",
    city_name: "Fowler",
  },
  {
    postal_code: "84523",
    full_city_name: "Ferron, UT",
    city_name: "Ferron",
  },
  {
    postal_code: "38334",
    full_city_name: "Finger, TN",
    city_name: "Finger",
  },
  {
    postal_code: "38487",
    full_city_name: "Williamsport, TN",
    city_name: "Williamsport",
  },
  {
    postal_code: "28528",
    full_city_name: "Gloucester, NC",
    city_name: "Gloucester",
  },
  {
    postal_code: "13101",
    full_city_name: "Mc Graw, NY",
    city_name: "Mc Graw",
  },
  {
    postal_code: "03442",
    full_city_name: "Bennington, NH",
    city_name: "Bennington",
  },
  {
    postal_code: "32358",
    full_city_name: "Sopchoppy, FL",
    city_name: "Sopchoppy",
  },
  {
    postal_code: "40107",
    full_city_name: "Boston, KY",
    city_name: "Boston",
  },
  {
    postal_code: "01270",
    full_city_name: "Windsor, MA",
    city_name: "Windsor",
  },
  {
    postal_code: "26588",
    full_city_name: "Rivesville, WV",
    city_name: "Rivesville",
  },
  {
    postal_code: "56531",
    full_city_name: "Elbow Lake, MN",
    city_name: "Elbow Lake",
  },
  {
    postal_code: "56473",
    full_city_name: "Pillager, MN",
    city_name: "Pillager",
  },
  {
    postal_code: "85637",
    full_city_name: "Sonoita, AZ",
    city_name: "Sonoita",
  },
  {
    postal_code: "62203",
    full_city_name: "East Saint Louis, IL",
    city_name: "East Saint Louis",
  },
  {
    postal_code: "56115",
    full_city_name: "Balaton, MN",
    city_name: "Balaton",
  },
  {
    postal_code: "79373",
    full_city_name: "Tahoka, TX",
    city_name: "Tahoka",
  },
  {
    postal_code: "55924",
    full_city_name: "Claremont, MN",
    city_name: "Claremont",
  },
  {
    postal_code: "13428",
    full_city_name: "Palatine Bridge, NY",
    city_name: "Palatine Bridge",
  },
  {
    postal_code: "06334",
    full_city_name: "Bozrah, CT",
    city_name: "Bozrah",
  },
  {
    postal_code: "16025",
    full_city_name: "Chicora, PA",
    city_name: "Chicora",
  },
  {
    postal_code: "44666",
    full_city_name: "North Lawrence, OH",
    city_name: "North Lawrence",
  },
  {
    postal_code: "48429",
    full_city_name: "Durand, MI",
    city_name: "Durand",
  },
  {
    postal_code: "30434",
    full_city_name: "Louisville, GA",
    city_name: "Louisville",
  },
  {
    postal_code: "04860",
    full_city_name: "Tenants Harbor, ME",
    city_name: "Tenants Harbor",
  },
  {
    postal_code: "46349",
    full_city_name: "Lake Village, IN",
    city_name: "Lake Village",
  },
  {
    postal_code: "23146",
    full_city_name: "Rockville, VA",
    city_name: "Rockville",
  },
  {
    postal_code: "42232",
    full_city_name: "Gracey, KY",
    city_name: "Gracey",
  },
  {
    postal_code: "44704",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "50046",
    full_city_name: "Cambridge, IA",
    city_name: "Cambridge",
  },
  {
    postal_code: "49947",
    full_city_name: "Marenisco, MI",
    city_name: "Marenisco",
  },
  {
    postal_code: "01129",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "52030",
    full_city_name: "Andrew, IA",
    city_name: "Andrew",
  },
  {
    postal_code: "15739",
    full_city_name: "Ernest, PA",
    city_name: "Ernest",
  },
  {
    postal_code: "04927",
    full_city_name: "Clinton, ME",
    city_name: "Clinton",
  },
  {
    postal_code: "15456",
    full_city_name: "Lemont Furnace, PA",
    city_name: "Lemont Furnace",
  },
  {
    postal_code: "50621",
    full_city_name: "Conrad, IA",
    city_name: "Conrad",
  },
  {
    postal_code: "15531",
    full_city_name: "Boswell, PA",
    city_name: "Boswell",
  },
  {
    postal_code: "54177",
    full_city_name: "Wausaukee, WI",
    city_name: "Wausaukee",
  },
  {
    postal_code: "75433",
    full_city_name: "Cumby, TX",
    city_name: "Cumby",
  },
  {
    postal_code: "49115",
    full_city_name: "Harbert, MI",
    city_name: "Harbert",
  },
  {
    postal_code: "18822",
    full_city_name: "Hallstead, PA",
    city_name: "Hallstead",
  },
  {
    postal_code: "13697",
    full_city_name: "Winthrop, NY",
    city_name: "Winthrop",
  },
  {
    postal_code: "61362",
    full_city_name: "Spring Valley, IL",
    city_name: "Spring Valley",
  },
  {
    postal_code: "43430",
    full_city_name: "Genoa, OH",
    city_name: "Genoa",
  },
  {
    postal_code: "57762",
    full_city_name: "Nisland, SD",
    city_name: "Nisland",
  },
  {
    postal_code: "96024",
    full_city_name: "Douglas City, CA",
    city_name: "Douglas City",
  },
  {
    postal_code: "01921",
    full_city_name: "Boxford, MA",
    city_name: "Boxford",
  },
  {
    postal_code: "44423",
    full_city_name: "Hanoverton, OH",
    city_name: "Hanoverton",
  },
  {
    postal_code: "05251",
    full_city_name: "Dorset, VT",
    city_name: "Dorset",
  },
  {
    postal_code: "29945",
    full_city_name: "Yemassee, SC",
    city_name: "Yemassee",
  },
  {
    postal_code: "40045",
    full_city_name: "Milton, KY",
    city_name: "Milton",
  },
  {
    postal_code: "16748",
    full_city_name: "Shinglehouse, PA",
    city_name: "Shinglehouse",
  },
  {
    postal_code: "59740",
    full_city_name: "Mc Allister, MT",
    city_name: "Mc Allister",
  },
  {
    postal_code: "71929",
    full_city_name: "Bismarck, AR",
    city_name: "Bismarck",
  },
  {
    postal_code: "71960",
    full_city_name: "Norman, AR",
    city_name: "Norman",
  },
  {
    postal_code: "90007",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "59037",
    full_city_name: "Huntley, MT",
    city_name: "Huntley",
  },
  {
    postal_code: "30629",
    full_city_name: "Comer, GA",
    city_name: "Comer",
  },
  {
    postal_code: "01612",
    full_city_name: "Paxton, MA",
    city_name: "Paxton",
  },
  {
    postal_code: "03266",
    full_city_name: "Rumney, NH",
    city_name: "Rumney",
  },
  {
    postal_code: "27876",
    full_city_name: "Seaboard, NC",
    city_name: "Seaboard",
  },
  {
    postal_code: "44491",
    full_city_name: "West Farmington, OH",
    city_name: "West Farmington",
  },
  {
    postal_code: "65767",
    full_city_name: "Urbana, MO",
    city_name: "Urbana",
  },
  {
    postal_code: "16851",
    full_city_name: "Lemont, PA",
    city_name: "Lemont",
  },
  {
    postal_code: "04649",
    full_city_name: "Jonesport, ME",
    city_name: "Jonesport",
  },
  {
    postal_code: "12852",
    full_city_name: "Newcomb, NY",
    city_name: "Newcomb",
  },
  {
    postal_code: "55032",
    full_city_name: "Harris, MN",
    city_name: "Harris",
  },
  {
    postal_code: "53923",
    full_city_name: "Cambria, WI",
    city_name: "Cambria",
  },
  {
    postal_code: "02863",
    full_city_name: "Central Falls, RI",
    city_name: "Central Falls",
  },
  {
    postal_code: "38049",
    full_city_name: "Mason, TN",
    city_name: "Mason",
  },
  {
    postal_code: "14218",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "16865",
    full_city_name: "Pennsylvania Furnace, PA",
    city_name: "Pennsylvania Furnace",
  },
  {
    postal_code: "40010",
    full_city_name: "Buckner, KY",
    city_name: "Buckner",
  },
  {
    postal_code: "08089",
    full_city_name: "Waterford Works, NJ",
    city_name: "Waterford Works",
  },
  {
    postal_code: "43222",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "15345",
    full_city_name: "Marianna, PA",
    city_name: "Marianna",
  },
  {
    postal_code: "05760",
    full_city_name: "Orwell, VT",
    city_name: "Orwell",
  },
  {
    postal_code: "04002",
    full_city_name: "Alfred, ME",
    city_name: "Alfred",
  },
  {
    postal_code: "44304",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "95065",
    full_city_name: "Santa Cruz, CA",
    city_name: "Santa Cruz",
  },
  {
    postal_code: "29479",
    full_city_name: "Saint Stephen, SC",
    city_name: "Saint Stephen",
  },
  {
    postal_code: "37754",
    full_city_name: "Heiskell, TN",
    city_name: "Heiskell",
  },
  {
    postal_code: "95460",
    full_city_name: "Mendocino, CA",
    city_name: "Mendocino",
  },
  {
    postal_code: "22969",
    full_city_name: "Schuyler, VA",
    city_name: "Schuyler",
  },
  {
    postal_code: "59018",
    full_city_name: "Clyde Park, MT",
    city_name: "Clyde Park",
  },
  {
    postal_code: "01011",
    full_city_name: "Chester, MA",
    city_name: "Chester",
  },
  {
    postal_code: "49614",
    full_city_name: "Bear Lake, MI",
    city_name: "Bear Lake",
  },
  {
    postal_code: "95462",
    full_city_name: "Monte Rio, CA",
    city_name: "Monte Rio",
  },
  {
    postal_code: "71350",
    full_city_name: "Mansura, LA",
    city_name: "Mansura",
  },
  {
    postal_code: "36091",
    full_city_name: "Verbena, AL",
    city_name: "Verbena",
  },
  {
    postal_code: "28382",
    full_city_name: "Roseboro, NC",
    city_name: "Roseboro",
  },
  {
    postal_code: "25306",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "49821",
    full_city_name: "Daggett, MI",
    city_name: "Daggett",
  },
  {
    postal_code: "34498",
    full_city_name: "Yankeetown, FL",
    city_name: "Yankeetown",
  },
  {
    postal_code: "48393",
    full_city_name: "Wixom, MI",
    city_name: "Wixom",
  },
  {
    postal_code: "54514",
    full_city_name: "Butternut, WI",
    city_name: "Butternut",
  },
  {
    postal_code: "98039",
    full_city_name: "Medina, WA",
    city_name: "Medina",
  },
  {
    postal_code: "47167",
    full_city_name: "Salem, IN",
    city_name: "Salem",
  },
  {
    postal_code: "81652",
    full_city_name: "Silt, CO",
    city_name: "Silt",
  },
  {
    postal_code: "30535",
    full_city_name: "Demorest, GA",
    city_name: "Demorest",
  },
  {
    postal_code: "01982",
    full_city_name: "South Hamilton, MA",
    city_name: "South Hamilton",
  },
  {
    postal_code: "18054",
    full_city_name: "Green Lane, PA",
    city_name: "Green Lane",
  },
  {
    postal_code: "59754",
    full_city_name: "Twin Bridges, MT",
    city_name: "Twin Bridges",
  },
  {
    postal_code: "54624",
    full_city_name: "De Soto, WI",
    city_name: "De Soto",
  },
  {
    postal_code: "01851",
    full_city_name: "Lowell, MA",
    city_name: "Lowell",
  },
  {
    postal_code: "25315",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "18826",
    full_city_name: "Kingsley, PA",
    city_name: "Kingsley",
  },
  {
    postal_code: "78947",
    full_city_name: "Lexington, TX",
    city_name: "Lexington",
  },
  {
    postal_code: "75959",
    full_city_name: "Milam, TX",
    city_name: "Milam",
  },
  {
    postal_code: "87825",
    full_city_name: "Magdalena, NM",
    city_name: "Magdalena",
  },
  {
    postal_code: "49344",
    full_city_name: "Shelbyville, MI",
    city_name: "Shelbyville",
  },
  {
    postal_code: "30817",
    full_city_name: "Lincolnton, GA",
    city_name: "Lincolnton",
  },
  {
    postal_code: "95977",
    full_city_name: "Smartsville, CA",
    city_name: "Smartsville",
  },
  {
    postal_code: "24416",
    full_city_name: "Buena Vista, VA",
    city_name: "Buena Vista",
  },
  {
    postal_code: "54844",
    full_city_name: "Herbster, WI",
    city_name: "Herbster",
  },
  {
    postal_code: "93243",
    full_city_name: "Lebec, CA",
    city_name: "Lebec",
  },
  {
    postal_code: "59749",
    full_city_name: "Sheridan, MT",
    city_name: "Sheridan",
  },
  {
    postal_code: "22534",
    full_city_name: "Partlow, VA",
    city_name: "Partlow",
  },
  {
    postal_code: "04355",
    full_city_name: "Readfield, ME",
    city_name: "Readfield",
  },
  {
    postal_code: "17264",
    full_city_name: "Three Springs, PA",
    city_name: "Three Springs",
  },
  {
    postal_code: "24440",
    full_city_name: "Greenville, VA",
    city_name: "Greenville",
  },
  {
    postal_code: "78417",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "03750",
    full_city_name: "Etna, NH",
    city_name: "Etna",
  },
  {
    postal_code: "78638",
    full_city_name: "Kingsbury, TX",
    city_name: "Kingsbury",
  },
  {
    postal_code: "97352",
    full_city_name: "Jefferson, OR",
    city_name: "Jefferson",
  },
  {
    postal_code: "22943",
    full_city_name: "Greenwood, VA",
    city_name: "Greenwood",
  },
  {
    postal_code: "30222",
    full_city_name: "Greenville, GA",
    city_name: "Greenville",
  },
  {
    postal_code: "62341",
    full_city_name: "Hamilton, IL",
    city_name: "Hamilton",
  },
  {
    postal_code: "31063",
    full_city_name: "Montezuma, GA",
    city_name: "Montezuma",
  },
  {
    postal_code: "12912",
    full_city_name: "Au Sable Forks, NY",
    city_name: "Au Sable Forks",
  },
  {
    postal_code: "62958",
    full_city_name: "Makanda, IL",
    city_name: "Makanda",
  },
  {
    postal_code: "87829",
    full_city_name: "Quemado, NM",
    city_name: "Quemado",
  },
  {
    postal_code: "02767",
    full_city_name: "Raynham, MA",
    city_name: "Raynham",
  },
  {
    postal_code: "81326",
    full_city_name: "Hesperus, CO",
    city_name: "Hesperus",
  },
  {
    postal_code: "49776",
    full_city_name: "Posen, MI",
    city_name: "Posen",
  },
  {
    postal_code: "12941",
    full_city_name: "Jay, NY",
    city_name: "Jay",
  },
  {
    postal_code: "02379",
    full_city_name: "West Bridgewater, MA",
    city_name: "West Bridgewater",
  },
  {
    postal_code: "84713",
    full_city_name: "Beaver, UT",
    city_name: "Beaver",
  },
  {
    postal_code: "04250",
    full_city_name: "Lisbon, ME",
    city_name: "Lisbon",
  },
  {
    postal_code: "35218",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "22747",
    full_city_name: "Washington, VA",
    city_name: "Washington",
  },
  {
    postal_code: "27050",
    full_city_name: "Tobaccoville, NC",
    city_name: "Tobaccoville",
  },
  {
    postal_code: "62869",
    full_city_name: "Norris City, IL",
    city_name: "Norris City",
  },
  {
    postal_code: "21757",
    full_city_name: "Keymar, MD",
    city_name: "Keymar",
  },
  {
    postal_code: "60444",
    full_city_name: "Mazon, IL",
    city_name: "Mazon",
  },
  {
    postal_code: "50658",
    full_city_name: "Nashua, IA",
    city_name: "Nashua",
  },
  {
    postal_code: "05767",
    full_city_name: "Rochester, VT",
    city_name: "Rochester",
  },
  {
    postal_code: "62001",
    full_city_name: "Alhambra, IL",
    city_name: "Alhambra",
  },
  {
    postal_code: "13804",
    full_city_name: "Masonville, NY",
    city_name: "Masonville",
  },
  {
    postal_code: "20198",
    full_city_name: "The Plains, VA",
    city_name: "The Plains",
  },
  {
    postal_code: "56156",
    full_city_name: "Luverne, MN",
    city_name: "Luverne",
  },
  {
    postal_code: "14201",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "62097",
    full_city_name: "Worden, IL",
    city_name: "Worden",
  },
  {
    postal_code: "15714",
    full_city_name: "Northern Cambria, PA",
    city_name: "Northern Cambria",
  },
  {
    postal_code: "27814",
    full_city_name: "Blounts Creek, NC",
    city_name: "Blounts Creek",
  },
  {
    postal_code: "79311",
    full_city_name: "Abernathy, TX",
    city_name: "Abernathy",
  },
  {
    postal_code: "01740",
    full_city_name: "Bolton, MA",
    city_name: "Bolton",
  },
  {
    postal_code: "75568",
    full_city_name: "Naples, TX",
    city_name: "Naples",
  },
  {
    postal_code: "17737",
    full_city_name: "Hughesville, PA",
    city_name: "Hughesville",
  },
  {
    postal_code: "45311",
    full_city_name: "Camden, OH",
    city_name: "Camden",
  },
  {
    postal_code: "24378",
    full_city_name: "Troutdale, VA",
    city_name: "Troutdale",
  },
  {
    postal_code: "26836",
    full_city_name: "Moorefield, WV",
    city_name: "Moorefield",
  },
  {
    postal_code: "13650",
    full_city_name: "Henderson, NY",
    city_name: "Henderson",
  },
  {
    postal_code: "01773",
    full_city_name: "Lincoln, MA",
    city_name: "Lincoln",
  },
  {
    postal_code: "01566",
    full_city_name: "Sturbridge, MA",
    city_name: "Sturbridge",
  },
  {
    postal_code: "70380",
    full_city_name: "Morgan City, LA",
    city_name: "Morgan City",
  },
  {
    postal_code: "07080",
    full_city_name: "South Plainfield, NJ",
    city_name: "South Plainfield",
  },
  {
    postal_code: "30571",
    full_city_name: "Sautee Nacoochee, GA",
    city_name: "Sautee Nacoochee",
  },
  {
    postal_code: "83530",
    full_city_name: "Grangeville, ID",
    city_name: "Grangeville",
  },
  {
    postal_code: "30474",
    full_city_name: "Vidalia, GA",
    city_name: "Vidalia",
  },
  {
    postal_code: "08854",
    full_city_name: "Piscataway, NJ",
    city_name: "Piscataway",
  },
  {
    postal_code: "11416",
    full_city_name: "Ozone Park, NY",
    city_name: "Ozone Park",
  },
  {
    postal_code: "02459",
    full_city_name: "Newton Center, MA",
    city_name: "Newton Center",
  },
  {
    postal_code: "01730",
    full_city_name: "Bedford, MA",
    city_name: "Bedford",
  },
  {
    postal_code: "88005",
    full_city_name: "Las Cruces, NM",
    city_name: "Las Cruces",
  },
  {
    postal_code: "14471",
    full_city_name: "Honeoye, NY",
    city_name: "Honeoye",
  },
  {
    postal_code: "18708",
    full_city_name: "Shavertown, PA",
    city_name: "Shavertown",
  },
  {
    postal_code: "13607",
    full_city_name: "Alexandria Bay, NY",
    city_name: "Alexandria Bay",
  },
  {
    postal_code: "48657",
    full_city_name: "Sanford, MI",
    city_name: "Sanford",
  },
  {
    postal_code: "49675",
    full_city_name: "Onekama, MI",
    city_name: "Onekama",
  },
  {
    postal_code: "68361",
    full_city_name: "Geneva, NE",
    city_name: "Geneva",
  },
  {
    postal_code: "11096",
    full_city_name: "Inwood, NY",
    city_name: "Inwood",
  },
  {
    postal_code: "13042",
    full_city_name: "Cleveland, NY",
    city_name: "Cleveland",
  },
  {
    postal_code: "19543",
    full_city_name: "Morgantown, PA",
    city_name: "Morgantown",
  },
  {
    postal_code: "62884",
    full_city_name: "Sesser, IL",
    city_name: "Sesser",
  },
  {
    postal_code: "48818",
    full_city_name: "Crystal, MI",
    city_name: "Crystal",
  },
  {
    postal_code: "68920",
    full_city_name: "Alma, NE",
    city_name: "Alma",
  },
  {
    postal_code: "31906",
    full_city_name: "Columbus, GA",
    city_name: "Columbus",
  },
  {
    postal_code: "02143",
    full_city_name: "Somerville, MA",
    city_name: "Somerville",
  },
  {
    postal_code: "26711",
    full_city_name: "Capon Bridge, WV",
    city_name: "Capon Bridge",
  },
  {
    postal_code: "84055",
    full_city_name: "Oakley, UT",
    city_name: "Oakley",
  },
  {
    postal_code: "91962",
    full_city_name: "Pine Valley, CA",
    city_name: "Pine Valley",
  },
  {
    postal_code: "38380",
    full_city_name: "Sugar Tree, TN",
    city_name: "Sugar Tree",
  },
  {
    postal_code: "47060",
    full_city_name: "West Harrison, IN",
    city_name: "West Harrison",
  },
  {
    postal_code: "80815",
    full_city_name: "Flagler, CO",
    city_name: "Flagler",
  },
  {
    postal_code: "50458",
    full_city_name: "Nora Springs, IA",
    city_name: "Nora Springs",
  },
  {
    postal_code: "57735",
    full_city_name: "Edgemont, SD",
    city_name: "Edgemont",
  },
  {
    postal_code: "12814",
    full_city_name: "Bolton Landing, NY",
    city_name: "Bolton Landing",
  },
  {
    postal_code: "80480",
    full_city_name: "Walden, CO",
    city_name: "Walden",
  },
  {
    postal_code: "59457",
    full_city_name: "Lewistown, MT",
    city_name: "Lewistown",
  },
  {
    postal_code: "03907",
    full_city_name: "Ogunquit, ME",
    city_name: "Ogunquit",
  },
  {
    postal_code: "38366",
    full_city_name: "Pinson, TN",
    city_name: "Pinson",
  },
  {
    postal_code: "49795",
    full_city_name: "Vanderbilt, MI",
    city_name: "Vanderbilt",
  },
  {
    postal_code: "83610",
    full_city_name: "Cambridge, ID",
    city_name: "Cambridge",
  },
  {
    postal_code: "18109",
    full_city_name: "Allentown, PA",
    city_name: "Allentown",
  },
  {
    postal_code: "37853",
    full_city_name: "Rockford, TN",
    city_name: "Rockford",
  },
  {
    postal_code: "64070",
    full_city_name: "Lone Jack, MO",
    city_name: "Lone Jack",
  },
  {
    postal_code: "51534",
    full_city_name: "Glenwood, IA",
    city_name: "Glenwood",
  },
  {
    postal_code: "97131",
    full_city_name: "Nehalem, OR",
    city_name: "Nehalem",
  },
  {
    postal_code: "43569",
    full_city_name: "Weston, OH",
    city_name: "Weston",
  },
  {
    postal_code: "61030",
    full_city_name: "Forreston, IL",
    city_name: "Forreston",
  },
  {
    postal_code: "17082",
    full_city_name: "Port Royal, PA",
    city_name: "Port Royal",
  },
  {
    postal_code: "93451",
    full_city_name: "San Miguel, CA",
    city_name: "San Miguel",
  },
  {
    postal_code: "13736",
    full_city_name: "Berkshire, NY",
    city_name: "Berkshire",
  },
  {
    postal_code: "06384",
    full_city_name: "Voluntown, CT",
    city_name: "Voluntown",
  },
  {
    postal_code: "04652",
    full_city_name: "Lubec, ME",
    city_name: "Lubec",
  },
  {
    postal_code: "38001",
    full_city_name: "Alamo, TN",
    city_name: "Alamo",
  },
  {
    postal_code: "11692",
    full_city_name: "Arverne, NY",
    city_name: "Arverne",
  },
  {
    postal_code: "34215",
    full_city_name: "Cortez, FL",
    city_name: "Cortez",
  },
  {
    postal_code: "83861",
    full_city_name: "Saint Maries, ID",
    city_name: "Saint Maries",
  },
  {
    postal_code: "85356",
    full_city_name: "Wellton, AZ",
    city_name: "Wellton",
  },
  {
    postal_code: "18055",
    full_city_name: "Hellertown, PA",
    city_name: "Hellertown",
  },
  {
    postal_code: "76302",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "11721",
    full_city_name: "Centerport, NY",
    city_name: "Centerport",
  },
  {
    postal_code: "97218",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "52076",
    full_city_name: "Strawberry Point, IA",
    city_name: "Strawberry Point",
  },
  {
    postal_code: "50563",
    full_city_name: "Manson, IA",
    city_name: "Manson",
  },
  {
    postal_code: "01060",
    full_city_name: "Northampton, MA",
    city_name: "Northampton",
  },
  {
    postal_code: "37826",
    full_city_name: "Niota, TN",
    city_name: "Niota",
  },
  {
    postal_code: "94108",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "80515",
    full_city_name: "Drake, CO",
    city_name: "Drake",
  },
  {
    postal_code: "17520",
    full_city_name: "East Petersburg, PA",
    city_name: "East Petersburg",
  },
  {
    postal_code: "32129",
    full_city_name: "Port Orange, FL",
    city_name: "Port Orange",
  },
  {
    postal_code: "35953",
    full_city_name: "Ashville, AL",
    city_name: "Ashville",
  },
  {
    postal_code: "32348",
    full_city_name: "Perry, FL",
    city_name: "Perry",
  },
  {
    postal_code: "54629",
    full_city_name: "Fountain City, WI",
    city_name: "Fountain City",
  },
  {
    postal_code: "66712",
    full_city_name: "Arma, KS",
    city_name: "Arma",
  },
  {
    postal_code: "05656",
    full_city_name: "Johnson, VT",
    city_name: "Johnson",
  },
  {
    postal_code: "13456",
    full_city_name: "Sauquoit, NY",
    city_name: "Sauquoit",
  },
  {
    postal_code: "56364",
    full_city_name: "Pierz, MN",
    city_name: "Pierz",
  },
  {
    postal_code: "92378",
    full_city_name: "Rimforest, CA",
    city_name: "Rimforest",
  },
  {
    postal_code: "01062",
    full_city_name: "Florence, MA",
    city_name: "Florence",
  },
  {
    postal_code: "61842",
    full_city_name: "Farmer City, IL",
    city_name: "Farmer City",
  },
  {
    postal_code: "56315",
    full_city_name: "Brandon, MN",
    city_name: "Brandon",
  },
  {
    postal_code: "18371",
    full_city_name: "Tamiment, PA",
    city_name: "Tamiment",
  },
  {
    postal_code: "04428",
    full_city_name: "Eddington, ME",
    city_name: "Eddington",
  },
  {
    postal_code: "81019",
    full_city_name: "Colorado City, CO",
    city_name: "Colorado City",
  },
  {
    postal_code: "54850",
    full_city_name: "La Pointe, WI",
    city_name: "La Pointe",
  },
  {
    postal_code: "59844",
    full_city_name: "Heron, MT",
    city_name: "Heron",
  },
  {
    postal_code: "37353",
    full_city_name: "Mc Donald, TN",
    city_name: "Mc Donald",
  },
  {
    postal_code: "96703",
    full_city_name: "Anahola, HI",
    city_name: "Anahola",
  },
  {
    postal_code: "61531",
    full_city_name: "Farmington, IL",
    city_name: "Farmington",
  },
  {
    postal_code: "12824",
    full_city_name: "Diamond Point, NY",
    city_name: "Diamond Point",
  },
  {
    postal_code: "97111",
    full_city_name: "Carlton, OR",
    city_name: "Carlton",
  },
  {
    postal_code: "75144",
    full_city_name: "Kerens, TX",
    city_name: "Kerens",
  },
  {
    postal_code: "96750",
    full_city_name: "Kealakekua, HI",
    city_name: "Kealakekua",
  },
  {
    postal_code: "02338",
    full_city_name: "Halifax, MA",
    city_name: "Halifax",
  },
  {
    postal_code: "97103",
    full_city_name: "Astoria, OR",
    city_name: "Astoria",
  },
  {
    postal_code: "48356",
    full_city_name: "Highland, MI",
    city_name: "Highland",
  },
  {
    postal_code: "18942",
    full_city_name: "Ottsville, PA",
    city_name: "Ottsville",
  },
  {
    postal_code: "19074",
    full_city_name: "Norwood, PA",
    city_name: "Norwood",
  },
  {
    postal_code: "04042",
    full_city_name: "Hollis Center, ME",
    city_name: "Hollis Center",
  },
  {
    postal_code: "81089",
    full_city_name: "Walsenburg, CO",
    city_name: "Walsenburg",
  },
  {
    postal_code: "29223",
    full_city_name: "Columbia, SC",
    city_name: "Columbia",
  },
  {
    postal_code: "04472",
    full_city_name: "Orland, ME",
    city_name: "Orland",
  },
  {
    postal_code: "14059",
    full_city_name: "Elma, NY",
    city_name: "Elma",
  },
  {
    postal_code: "28340",
    full_city_name: "Fairmont, NC",
    city_name: "Fairmont",
  },
  {
    postal_code: "21651",
    full_city_name: "Millington, MD",
    city_name: "Millington",
  },
  {
    postal_code: "14564",
    full_city_name: "Victor, NY",
    city_name: "Victor",
  },
  {
    postal_code: "08515",
    full_city_name: "Chesterfield, NJ",
    city_name: "Chesterfield",
  },
  {
    postal_code: "62448",
    full_city_name: "Newton, IL",
    city_name: "Newton",
  },
  {
    postal_code: "69130",
    full_city_name: "Cozad, NE",
    city_name: "Cozad",
  },
  {
    postal_code: "02030",
    full_city_name: "Dover, MA",
    city_name: "Dover",
  },
  {
    postal_code: "80809",
    full_city_name: "Cascade, CO",
    city_name: "Cascade",
  },
  {
    postal_code: "55007",
    full_city_name: "Brook Park, MN",
    city_name: "Brook Park",
  },
  {
    postal_code: "40078",
    full_city_name: "Willisburg, KY",
    city_name: "Willisburg",
  },
  {
    postal_code: "04981",
    full_city_name: "Stockton Springs, ME",
    city_name: "Stockton Springs",
  },
  {
    postal_code: "02420",
    full_city_name: "Lexington, MA",
    city_name: "Lexington",
  },
  {
    postal_code: "55396",
    full_city_name: "Winthrop, MN",
    city_name: "Winthrop",
  },
  {
    postal_code: "44438",
    full_city_name: "Masury, OH",
    city_name: "Masury",
  },
  {
    postal_code: "47944",
    full_city_name: "Fowler, IN",
    city_name: "Fowler",
  },
  {
    postal_code: "95664",
    full_city_name: "Pilot Hill, CA",
    city_name: "Pilot Hill",
  },
  {
    postal_code: "40109",
    full_city_name: "Brooks, KY",
    city_name: "Brooks",
  },
  {
    postal_code: "13478",
    full_city_name: "Verona, NY",
    city_name: "Verona",
  },
  {
    postal_code: "79356",
    full_city_name: "Post, TX",
    city_name: "Post",
  },
  {
    postal_code: "63953",
    full_city_name: "Naylor, MO",
    city_name: "Naylor",
  },
  {
    postal_code: "62087",
    full_city_name: "South Roxana, IL",
    city_name: "South Roxana",
  },
  {
    postal_code: "44676",
    full_city_name: "Shreve, OH",
    city_name: "Shreve",
  },
  {
    postal_code: "08036",
    full_city_name: "Hainesport, NJ",
    city_name: "Hainesport",
  },
  {
    postal_code: "22971",
    full_city_name: "Shipman, VA",
    city_name: "Shipman",
  },
  {
    postal_code: "44272",
    full_city_name: "Rootstown, OH",
    city_name: "Rootstown",
  },
  {
    postal_code: "37143",
    full_city_name: "Pegram, TN",
    city_name: "Pegram",
  },
  {
    postal_code: "57773",
    full_city_name: "Pringle, SD",
    city_name: "Pringle",
  },
  {
    postal_code: "37714",
    full_city_name: "Caryville, TN",
    city_name: "Caryville",
  },
  {
    postal_code: "54849",
    full_city_name: "Lake Nebagamon, WI",
    city_name: "Lake Nebagamon",
  },
  {
    postal_code: "53507",
    full_city_name: "Barneveld, WI",
    city_name: "Barneveld",
  },
  {
    postal_code: "84542",
    full_city_name: "Wellington, UT",
    city_name: "Wellington",
  },
  {
    postal_code: "84326",
    full_city_name: "Millville, UT",
    city_name: "Millville",
  },
  {
    postal_code: "26175",
    full_city_name: "Sistersville, WV",
    city_name: "Sistersville",
  },
  {
    postal_code: "15321",
    full_city_name: "Cecil, PA",
    city_name: "Cecil",
  },
  {
    postal_code: "55334",
    full_city_name: "Gaylord, MN",
    city_name: "Gaylord",
  },
  {
    postal_code: "35062",
    full_city_name: "Dora, AL",
    city_name: "Dora",
  },
  {
    postal_code: "48703",
    full_city_name: "Au Gres, MI",
    city_name: "Au Gres",
  },
  {
    postal_code: "25901",
    full_city_name: "Oak Hill, WV",
    city_name: "Oak Hill",
  },
  {
    postal_code: "52031",
    full_city_name: "Bellevue, IA",
    city_name: "Bellevue",
  },
  {
    postal_code: "12404",
    full_city_name: "Accord, NY",
    city_name: "Accord",
  },
  {
    postal_code: "84340",
    full_city_name: "Willard, UT",
    city_name: "Willard",
  },
  {
    postal_code: "79903",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "92113",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "11102",
    full_city_name: "Astoria, NY",
    city_name: "Astoria",
  },
  {
    postal_code: "39114",
    full_city_name: "Mendenhall, MS",
    city_name: "Mendenhall",
  },
  {
    postal_code: "76475",
    full_city_name: "Strawn, TX",
    city_name: "Strawn",
  },
  {
    postal_code: "81006",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "18642",
    full_city_name: "Duryea, PA",
    city_name: "Duryea",
  },
  {
    postal_code: "54858",
    full_city_name: "Milltown, WI",
    city_name: "Milltown",
  },
  {
    postal_code: "01754",
    full_city_name: "Maynard, MA",
    city_name: "Maynard",
  },
  {
    postal_code: "38580",
    full_city_name: "Rickman, TN",
    city_name: "Rickman",
  },
  {
    postal_code: "86015",
    full_city_name: "Bellemont, AZ",
    city_name: "Bellemont",
  },
  {
    postal_code: "13354",
    full_city_name: "Holland Patent, NY",
    city_name: "Holland Patent",
  },
  {
    postal_code: "06783",
    full_city_name: "Roxbury, CT",
    city_name: "Roxbury",
  },
  {
    postal_code: "13634",
    full_city_name: "Dexter, NY",
    city_name: "Dexter",
  },
  {
    postal_code: "26726",
    full_city_name: "Keyser, WV",
    city_name: "Keyser",
  },
  {
    postal_code: "59930",
    full_city_name: "Rexford, MT",
    city_name: "Rexford",
  },
  {
    postal_code: "28556",
    full_city_name: "Merritt, NC",
    city_name: "Merritt",
  },
  {
    postal_code: "18706",
    full_city_name: "Wilkes Barre, PA",
    city_name: "Wilkes Barre",
  },
  {
    postal_code: "04350",
    full_city_name: "Litchfield, ME",
    city_name: "Litchfield",
  },
  {
    postal_code: "78046",
    full_city_name: "Laredo, TX",
    city_name: "Laredo",
  },
  {
    postal_code: "73005",
    full_city_name: "Anadarko, OK",
    city_name: "Anadarko",
  },
  {
    postal_code: "22572",
    full_city_name: "Warsaw, VA",
    city_name: "Warsaw",
  },
  {
    postal_code: "14801",
    full_city_name: "Addison, NY",
    city_name: "Addison",
  },
  {
    postal_code: "37357",
    full_city_name: "Morrison, TN",
    city_name: "Morrison",
  },
  {
    postal_code: "97544",
    full_city_name: "Williams, OR",
    city_name: "Williams",
  },
  {
    postal_code: "54174",
    full_city_name: "Suring, WI",
    city_name: "Suring",
  },
  {
    postal_code: "31082",
    full_city_name: "Sandersville, GA",
    city_name: "Sandersville",
  },
  {
    postal_code: "55080",
    full_city_name: "Stanchfield, MN",
    city_name: "Stanchfield",
  },
  {
    postal_code: "30473",
    full_city_name: "Uvalda, GA",
    city_name: "Uvalda",
  },
  {
    postal_code: "32310",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "21645",
    full_city_name: "Kennedyville, MD",
    city_name: "Kennedyville",
  },
  {
    postal_code: "49232",
    full_city_name: "Camden, MI",
    city_name: "Camden",
  },
  {
    postal_code: "54758",
    full_city_name: "Osseo, WI",
    city_name: "Osseo",
  },
  {
    postal_code: "55312",
    full_city_name: "Brownton, MN",
    city_name: "Brownton",
  },
  {
    postal_code: "60184",
    full_city_name: "Wayne, IL",
    city_name: "Wayne",
  },
  {
    postal_code: "28665",
    full_city_name: "Purlear, NC",
    city_name: "Purlear",
  },
  {
    postal_code: "13092",
    full_city_name: "Locke, NY",
    city_name: "Locke",
  },
  {
    postal_code: "96779",
    full_city_name: "Paia, HI",
    city_name: "Paia",
  },
  {
    postal_code: "32344",
    full_city_name: "Monticello, FL",
    city_name: "Monticello",
  },
  {
    postal_code: "48601",
    full_city_name: "Saginaw, MI",
    city_name: "Saginaw",
  },
  {
    postal_code: "45302",
    full_city_name: "Anna, OH",
    city_name: "Anna",
  },
  {
    postal_code: "72065",
    full_city_name: "Hensley, AR",
    city_name: "Hensley",
  },
  {
    postal_code: "75839",
    full_city_name: "Elkhart, TX",
    city_name: "Elkhart",
  },
  {
    postal_code: "37096",
    full_city_name: "Linden, TN",
    city_name: "Linden",
  },
  {
    postal_code: "20733",
    full_city_name: "Churchton, MD",
    city_name: "Churchton",
  },
  {
    postal_code: "05476",
    full_city_name: "Richford, VT",
    city_name: "Richford",
  },
  {
    postal_code: "72936",
    full_city_name: "Greenwood, AR",
    city_name: "Greenwood",
  },
  {
    postal_code: "30346",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "06039",
    full_city_name: "Lakeville, CT",
    city_name: "Lakeville",
  },
  {
    postal_code: "21719",
    full_city_name: "Cascade, MD",
    city_name: "Cascade",
  },
  {
    postal_code: "73439",
    full_city_name: "Kingston, OK",
    city_name: "Kingston",
  },
  {
    postal_code: "75652",
    full_city_name: "Henderson, TX",
    city_name: "Henderson",
  },
  {
    postal_code: "17013",
    full_city_name: "Carlisle, PA",
    city_name: "Carlisle",
  },
  {
    postal_code: "20629",
    full_city_name: "Dowell, MD",
    city_name: "Dowell",
  },
  {
    postal_code: "61884",
    full_city_name: "White Heath, IL",
    city_name: "White Heath",
  },
  {
    postal_code: "35184",
    full_city_name: "West Blocton, AL",
    city_name: "West Blocton",
  },
  {
    postal_code: "05777",
    full_city_name: "West Rutland, VT",
    city_name: "West Rutland",
  },
  {
    postal_code: "04084",
    full_city_name: "Standish, ME",
    city_name: "Standish",
  },
  {
    postal_code: "55454",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "50244",
    full_city_name: "Slater, IA",
    city_name: "Slater",
  },
  {
    postal_code: "54459",
    full_city_name: "Ogema, WI",
    city_name: "Ogema",
  },
  {
    postal_code: "62664",
    full_city_name: "Mason City, IL",
    city_name: "Mason City",
  },
  {
    postal_code: "70079",
    full_city_name: "Norco, LA",
    city_name: "Norco",
  },
  {
    postal_code: "28634",
    full_city_name: "Harmony, NC",
    city_name: "Harmony",
  },
  {
    postal_code: "98356",
    full_city_name: "Morton, WA",
    city_name: "Morton",
  },
  {
    postal_code: "54014",
    full_city_name: "Hager City, WI",
    city_name: "Hager City",
  },
  {
    postal_code: "87083",
    full_city_name: "Cochiti Lake, NM",
    city_name: "Cochiti Lake",
  },
  {
    postal_code: "31027",
    full_city_name: "East Dublin, GA",
    city_name: "East Dublin",
  },
  {
    postal_code: "49887",
    full_city_name: "Stephenson, MI",
    city_name: "Stephenson",
  },
  {
    postal_code: "16917",
    full_city_name: "Covington, PA",
    city_name: "Covington",
  },
  {
    postal_code: "38256",
    full_city_name: "Springville, TN",
    city_name: "Springville",
  },
  {
    postal_code: "80928",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "36571",
    full_city_name: "Saraland, AL",
    city_name: "Saraland",
  },
  {
    postal_code: "76433",
    full_city_name: "Bluff Dale, TX",
    city_name: "Bluff Dale",
  },
  {
    postal_code: "49245",
    full_city_name: "Homer, MI",
    city_name: "Homer",
  },
  {
    postal_code: "48872",
    full_city_name: "Perry, MI",
    city_name: "Perry",
  },
  {
    postal_code: "76648",
    full_city_name: "Hubbard, TX",
    city_name: "Hubbard",
  },
  {
    postal_code: "62080",
    full_city_name: "Ramsey, IL",
    city_name: "Ramsey",
  },
  {
    postal_code: "52540",
    full_city_name: "Brighton, IA",
    city_name: "Brighton",
  },
  {
    postal_code: "43780",
    full_city_name: "Senecaville, OH",
    city_name: "Senecaville",
  },
  {
    postal_code: "26059",
    full_city_name: "Triadelphia, WV",
    city_name: "Triadelphia",
  },
  {
    postal_code: "15541",
    full_city_name: "Friedens, PA",
    city_name: "Friedens",
  },
  {
    postal_code: "61048",
    full_city_name: "Lena, IL",
    city_name: "Lena",
  },
  {
    postal_code: "17103",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "12067",
    full_city_name: "Feura Bush, NY",
    city_name: "Feura Bush",
  },
  {
    postal_code: "12116",
    full_city_name: "Maryland, NY",
    city_name: "Maryland",
  },
  {
    postal_code: "75939",
    full_city_name: "Corrigan, TX",
    city_name: "Corrigan",
  },
  {
    postal_code: "49057",
    full_city_name: "Hartford, MI",
    city_name: "Hartford",
  },
  {
    postal_code: "18080",
    full_city_name: "Slatington, PA",
    city_name: "Slatington",
  },
  {
    postal_code: "39150",
    full_city_name: "Port Gibson, MS",
    city_name: "Port Gibson",
  },
  {
    postal_code: "17859",
    full_city_name: "Orangeville, PA",
    city_name: "Orangeville",
  },
  {
    postal_code: "38547",
    full_city_name: "Brush Creek, TN",
    city_name: "Brush Creek",
  },
  {
    postal_code: "06777",
    full_city_name: "New Preston Marble Dale, CT",
    city_name: "New Preston Marble Dale",
  },
  {
    postal_code: "02492",
    full_city_name: "Needham, MA",
    city_name: "Needham",
  },
  {
    postal_code: "14895",
    full_city_name: "Wellsville, NY",
    city_name: "Wellsville",
  },
  {
    postal_code: "47001",
    full_city_name: "Aurora, IN",
    city_name: "Aurora",
  },
  {
    postal_code: "48730",
    full_city_name: "East Tawas, MI",
    city_name: "East Tawas",
  },
  {
    postal_code: "31794",
    full_city_name: "Tifton, GA",
    city_name: "Tifton",
  },
  {
    postal_code: "01521",
    full_city_name: "Holland, MA",
    city_name: "Holland",
  },
  {
    postal_code: "27343",
    full_city_name: "Semora, NC",
    city_name: "Semora",
  },
  {
    postal_code: "99148",
    full_city_name: "Loon Lake, WA",
    city_name: "Loon Lake",
  },
  {
    postal_code: "13642",
    full_city_name: "Gouverneur, NY",
    city_name: "Gouverneur",
  },
  {
    postal_code: "12440",
    full_city_name: "High Falls, NY",
    city_name: "High Falls",
  },
  {
    postal_code: "05445",
    full_city_name: "Charlotte, VT",
    city_name: "Charlotte",
  },
  {
    postal_code: "17517",
    full_city_name: "Denver, PA",
    city_name: "Denver",
  },
  {
    postal_code: "04046",
    full_city_name: "Kennebunkport, ME",
    city_name: "Kennebunkport",
  },
  {
    postal_code: "04970",
    full_city_name: "Rangeley, ME",
    city_name: "Rangeley",
  },
  {
    postal_code: "13679",
    full_city_name: "Redwood, NY",
    city_name: "Redwood",
  },
  {
    postal_code: "90212",
    full_city_name: "Beverly Hills, CA",
    city_name: "Beverly Hills",
  },
  {
    postal_code: "38105",
    full_city_name: "Memphis, TN",
    city_name: "Memphis",
  },
  {
    postal_code: "22728",
    full_city_name: "Midland, VA",
    city_name: "Midland",
  },
  {
    postal_code: "37144",
    full_city_name: "Petersburg, TN",
    city_name: "Petersburg",
  },
  {
    postal_code: "02770",
    full_city_name: "Rochester, MA",
    city_name: "Rochester",
  },
  {
    postal_code: "02152",
    full_city_name: "Winthrop, MA",
    city_name: "Winthrop",
  },
  {
    postal_code: "03588",
    full_city_name: "Milan, NH",
    city_name: "Milan",
  },
  {
    postal_code: "02210",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "48323",
    full_city_name: "West Bloomfield, MI",
    city_name: "West Bloomfield",
  },
  {
    postal_code: "48216",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "31510",
    full_city_name: "Alma, GA",
    city_name: "Alma",
  },
  {
    postal_code: "02054",
    full_city_name: "Millis, MA",
    city_name: "Millis",
  },
  {
    postal_code: "03465",
    full_city_name: "Troy, NH",
    city_name: "Troy",
  },
  {
    postal_code: "01519",
    full_city_name: "Grafton, MA",
    city_name: "Grafton",
  },
  {
    postal_code: "11972",
    full_city_name: "Speonk, NY",
    city_name: "Speonk",
  },
  {
    postal_code: "62014",
    full_city_name: "Bunker Hill, IL",
    city_name: "Bunker Hill",
  },
  {
    postal_code: "77656",
    full_city_name: "Silsbee, TX",
    city_name: "Silsbee",
  },
  {
    postal_code: "44865",
    full_city_name: "Plymouth, OH",
    city_name: "Plymouth",
  },
  {
    postal_code: "64163",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "19075",
    full_city_name: "Oreland, PA",
    city_name: "Oreland",
  },
  {
    postal_code: "08344",
    full_city_name: "Newfield, NJ",
    city_name: "Newfield",
  },
  {
    postal_code: "04268",
    full_city_name: "Norway, ME",
    city_name: "Norway",
  },
  {
    postal_code: "47250",
    full_city_name: "Madison, IN",
    city_name: "Madison",
  },
  {
    postal_code: "76577",
    full_city_name: "Thorndale, TX",
    city_name: "Thorndale",
  },
  {
    postal_code: "17327",
    full_city_name: "Glen Rock, PA",
    city_name: "Glen Rock",
  },
  {
    postal_code: "56750",
    full_city_name: "Red Lake Falls, MN",
    city_name: "Red Lake Falls",
  },
  {
    postal_code: "07827",
    full_city_name: "Montague, NJ",
    city_name: "Montague",
  },
  {
    postal_code: "48039",
    full_city_name: "Marine City, MI",
    city_name: "Marine City",
  },
  {
    postal_code: "15853",
    full_city_name: "Ridgway, PA",
    city_name: "Ridgway",
  },
  {
    postal_code: "32351",
    full_city_name: "Quincy, FL",
    city_name: "Quincy",
  },
  {
    postal_code: "75633",
    full_city_name: "Carthage, TX",
    city_name: "Carthage",
  },
  {
    postal_code: "94037",
    full_city_name: "Montara, CA",
    city_name: "Montara",
  },
  {
    postal_code: "69143",
    full_city_name: "Hershey, NE",
    city_name: "Hershey",
  },
  {
    postal_code: "56450",
    full_city_name: "Garrison, MN",
    city_name: "Garrison",
  },
  {
    postal_code: "65747",
    full_city_name: "Shell Knob, MO",
    city_name: "Shell Knob",
  },
  {
    postal_code: "43907",
    full_city_name: "Cadiz, OH",
    city_name: "Cadiz",
  },
  {
    postal_code: "39479",
    full_city_name: "Seminary, MS",
    city_name: "Seminary",
  },
  {
    postal_code: "05773",
    full_city_name: "Wallingford, VT",
    city_name: "Wallingford",
  },
  {
    postal_code: "48808",
    full_city_name: "Bath, MI",
    city_name: "Bath",
  },
  {
    postal_code: "56381",
    full_city_name: "Starbuck, MN",
    city_name: "Starbuck",
  },
  {
    postal_code: "18847",
    full_city_name: "Susquehanna, PA",
    city_name: "Susquehanna",
  },
  {
    postal_code: "85354",
    full_city_name: "Tonopah, AZ",
    city_name: "Tonopah",
  },
  {
    postal_code: "14534",
    full_city_name: "Pittsford, NY",
    city_name: "Pittsford",
  },
  {
    postal_code: "17777",
    full_city_name: "Watsontown, PA",
    city_name: "Watsontown",
  },
  {
    postal_code: "44608",
    full_city_name: "Beach City, OH",
    city_name: "Beach City",
  },
  {
    postal_code: "79508",
    full_city_name: "Buffalo Gap, TX",
    city_name: "Buffalo Gap",
  },
  {
    postal_code: "12469",
    full_city_name: "Preston Hollow, NY",
    city_name: "Preston Hollow",
  },
  {
    postal_code: "11693",
    full_city_name: "Far Rockaway, NY",
    city_name: "Far Rockaway",
  },
  {
    postal_code: "54107",
    full_city_name: "Bonduel, WI",
    city_name: "Bonduel",
  },
  {
    postal_code: "43456",
    full_city_name: "Put In Bay, OH",
    city_name: "Put In Bay",
  },
  {
    postal_code: "04769",
    full_city_name: "Presque Isle, ME",
    city_name: "Presque Isle",
  },
  {
    postal_code: "47408",
    full_city_name: "Bloomington, IN",
    city_name: "Bloomington",
  },
  {
    postal_code: "88065",
    full_city_name: "Tyrone, NM",
    city_name: "Tyrone",
  },
  {
    postal_code: "42519",
    full_city_name: "Burnside, KY",
    city_name: "Burnside",
  },
  {
    postal_code: "42629",
    full_city_name: "Jamestown, KY",
    city_name: "Jamestown",
  },
  {
    postal_code: "87718",
    full_city_name: "Eagle Nest, NM",
    city_name: "Eagle Nest",
  },
  {
    postal_code: "80759",
    full_city_name: "Yuma, CO",
    city_name: "Yuma",
  },
  {
    postal_code: "03586",
    full_city_name: "Sugar Hill, NH",
    city_name: "Sugar Hill",
  },
  {
    postal_code: "45887",
    full_city_name: "Spencerville, OH",
    city_name: "Spencerville",
  },
  {
    postal_code: "43783",
    full_city_name: "Somerset, OH",
    city_name: "Somerset",
  },
  {
    postal_code: "12918",
    full_city_name: "Cadyville, NY",
    city_name: "Cadyville",
  },
  {
    postal_code: "02827",
    full_city_name: "Greene, RI",
    city_name: "Greene",
  },
  {
    postal_code: "45871",
    full_city_name: "New Knoxville, OH",
    city_name: "New Knoxville",
  },
  {
    postal_code: "40729",
    full_city_name: "East Bernstadt, KY",
    city_name: "East Bernstadt",
  },
  {
    postal_code: "61559",
    full_city_name: "Princeville, IL",
    city_name: "Princeville",
  },
  {
    postal_code: "44452",
    full_city_name: "North Lima, OH",
    city_name: "North Lima",
  },
  {
    postal_code: "05361",
    full_city_name: "Whitingham, VT",
    city_name: "Whitingham",
  },
  {
    postal_code: "49891",
    full_city_name: "Trenary, MI",
    city_name: "Trenary",
  },
  {
    postal_code: "48173",
    full_city_name: "Rockwood, MI",
    city_name: "Rockwood",
  },
  {
    postal_code: "44656",
    full_city_name: "Mineral City, OH",
    city_name: "Mineral City",
  },
  {
    postal_code: "13116",
    full_city_name: "Minoa, NY",
    city_name: "Minoa",
  },
  {
    postal_code: "61753",
    full_city_name: "Lexington, IL",
    city_name: "Lexington",
  },
  {
    postal_code: "27239",
    full_city_name: "Denton, NC",
    city_name: "Denton",
  },
  {
    postal_code: "49709",
    full_city_name: "Atlanta, MI",
    city_name: "Atlanta",
  },
  {
    postal_code: "93656",
    full_city_name: "Riverdale, CA",
    city_name: "Riverdale",
  },
  {
    postal_code: "87557",
    full_city_name: "Ranchos De Taos, NM",
    city_name: "Ranchos De Taos",
  },
  {
    postal_code: "99350",
    full_city_name: "Prosser, WA",
    city_name: "Prosser",
  },
  {
    postal_code: "62441",
    full_city_name: "Marshall, IL",
    city_name: "Marshall",
  },
  {
    postal_code: "11788",
    full_city_name: "Hauppauge, NY",
    city_name: "Hauppauge",
  },
  {
    postal_code: "80648",
    full_city_name: "Nunn, CO",
    city_name: "Nunn",
  },
  {
    postal_code: "29581",
    full_city_name: "Nichols, SC",
    city_name: "Nichols",
  },
  {
    postal_code: "51632",
    full_city_name: "Clarinda, IA",
    city_name: "Clarinda",
  },
  {
    postal_code: "64054",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "18229",
    full_city_name: "Jim Thorpe, PA",
    city_name: "Jim Thorpe",
  },
  {
    postal_code: "13656",
    full_city_name: "La Fargeville, NY",
    city_name: "La Fargeville",
  },
  {
    postal_code: "03902",
    full_city_name: "Cape Neddick, ME",
    city_name: "Cape Neddick",
  },
  {
    postal_code: "26704",
    full_city_name: "Augusta, WV",
    city_name: "Augusta",
  },
  {
    postal_code: "48174",
    full_city_name: "Romulus, MI",
    city_name: "Romulus",
  },
  {
    postal_code: "49251",
    full_city_name: "Leslie, MI",
    city_name: "Leslie",
  },
  {
    postal_code: "65360",
    full_city_name: "Windsor, MO",
    city_name: "Windsor",
  },
  {
    postal_code: "44473",
    full_city_name: "Vienna, OH",
    city_name: "Vienna",
  },
  {
    postal_code: "18325",
    full_city_name: "Canadensis, PA",
    city_name: "Canadensis",
  },
  {
    postal_code: "02478",
    full_city_name: "Belmont, MA",
    city_name: "Belmont",
  },
  {
    postal_code: "70431",
    full_city_name: "Bush, LA",
    city_name: "Bush",
  },
  {
    postal_code: "12565",
    full_city_name: "Philmont, NY",
    city_name: "Philmont",
  },
  {
    postal_code: "62938",
    full_city_name: "Golconda, IL",
    city_name: "Golconda",
  },
  {
    postal_code: "01862",
    full_city_name: "North Billerica, MA",
    city_name: "North Billerica",
  },
  {
    postal_code: "64776",
    full_city_name: "Osceola, MO",
    city_name: "Osceola",
  },
  {
    postal_code: "27043",
    full_city_name: "Pinnacle, NC",
    city_name: "Pinnacle",
  },
  {
    postal_code: "65641",
    full_city_name: "Eagle Rock, MO",
    city_name: "Eagle Rock",
  },
  {
    postal_code: "20688",
    full_city_name: "Solomons, MD",
    city_name: "Solomons",
  },
  {
    postal_code: "72560",
    full_city_name: "Mountain View, AR",
    city_name: "Mountain View",
  },
  {
    postal_code: "50248",
    full_city_name: "Story City, IA",
    city_name: "Story City",
  },
  {
    postal_code: "53038",
    full_city_name: "Johnson Creek, WI",
    city_name: "Johnson Creek",
  },
  {
    postal_code: "49420",
    full_city_name: "Hart, MI",
    city_name: "Hart",
  },
  {
    postal_code: "54110",
    full_city_name: "Brillion, WI",
    city_name: "Brillion",
  },
  {
    postal_code: "53014",
    full_city_name: "Chilton, WI",
    city_name: "Chilton",
  },
  {
    postal_code: "52591",
    full_city_name: "Sigourney, IA",
    city_name: "Sigourney",
  },
  {
    postal_code: "79758",
    full_city_name: "Gardendale, TX",
    city_name: "Gardendale",
  },
  {
    postal_code: "17313",
    full_city_name: "Dallastown, PA",
    city_name: "Dallastown",
  },
  {
    postal_code: "52240",
    full_city_name: "Iowa City, IA",
    city_name: "Iowa City",
  },
  {
    postal_code: "30721",
    full_city_name: "Dalton, GA",
    city_name: "Dalton",
  },
  {
    postal_code: "11930",
    full_city_name: "Amagansett, NY",
    city_name: "Amagansett",
  },
  {
    postal_code: "04976",
    full_city_name: "Skowhegan, ME",
    city_name: "Skowhegan",
  },
  {
    postal_code: "75790",
    full_city_name: "Van, TX",
    city_name: "Van",
  },
  {
    postal_code: "54817",
    full_city_name: "Birchwood, WI",
    city_name: "Birchwood",
  },
  {
    postal_code: "76857",
    full_city_name: "May, TX",
    city_name: "May",
  },
  {
    postal_code: "97825",
    full_city_name: "Dayville, OR",
    city_name: "Dayville",
  },
  {
    postal_code: "55053",
    full_city_name: "Nerstrand, MN",
    city_name: "Nerstrand",
  },
  {
    postal_code: "29620",
    full_city_name: "Abbeville, SC",
    city_name: "Abbeville",
  },
  {
    postal_code: "54435",
    full_city_name: "Gleason, WI",
    city_name: "Gleason",
  },
  {
    postal_code: "27011",
    full_city_name: "Boonville, NC",
    city_name: "Boonville",
  },
  {
    postal_code: "18834",
    full_city_name: "New Milford, PA",
    city_name: "New Milford",
  },
  {
    postal_code: "04941",
    full_city_name: "Freedom, ME",
    city_name: "Freedom",
  },
  {
    postal_code: "39213",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "18917",
    full_city_name: "Dublin, PA",
    city_name: "Dublin",
  },
  {
    postal_code: "01225",
    full_city_name: "Cheshire, MA",
    city_name: "Cheshire",
  },
  {
    postal_code: "62548",
    full_city_name: "Mount Pulaski, IL",
    city_name: "Mount Pulaski",
  },
  {
    postal_code: "23924",
    full_city_name: "Chase City, VA",
    city_name: "Chase City",
  },
  {
    postal_code: "93602",
    full_city_name: "Auberry, CA",
    city_name: "Auberry",
  },
  {
    postal_code: "45817",
    full_city_name: "Bluffton, OH",
    city_name: "Bluffton",
  },
  {
    postal_code: "17870",
    full_city_name: "Selinsgrove, PA",
    city_name: "Selinsgrove",
  },
  {
    postal_code: "30521",
    full_city_name: "Carnesville, GA",
    city_name: "Carnesville",
  },
  {
    postal_code: "44906",
    full_city_name: "Mansfield, OH",
    city_name: "Mansfield",
  },
  {
    postal_code: "80422",
    full_city_name: "Black Hawk, CO",
    city_name: "Black Hawk",
  },
  {
    postal_code: "40461",
    full_city_name: "Paint Lick, KY",
    city_name: "Paint Lick",
  },
  {
    postal_code: "79545",
    full_city_name: "Roscoe, TX",
    city_name: "Roscoe",
  },
  {
    postal_code: "71373",
    full_city_name: "Vidalia, LA",
    city_name: "Vidalia",
  },
  {
    postal_code: "42724",
    full_city_name: "Cecilia, KY",
    city_name: "Cecilia",
  },
  {
    postal_code: "01571",
    full_city_name: "Dudley, MA",
    city_name: "Dudley",
  },
  {
    postal_code: "68430",
    full_city_name: "Roca, NE",
    city_name: "Roca",
  },
  {
    postal_code: "11370",
    full_city_name: "East Elmhurst, NY",
    city_name: "East Elmhurst",
  },
  {
    postal_code: "13143",
    full_city_name: "Red Creek, NY",
    city_name: "Red Creek",
  },
  {
    postal_code: "53504",
    full_city_name: "Argyle, WI",
    city_name: "Argyle",
  },
  {
    postal_code: "17847",
    full_city_name: "Milton, PA",
    city_name: "Milton",
  },
  {
    postal_code: "13811",
    full_city_name: "Newark Valley, NY",
    city_name: "Newark Valley",
  },
  {
    postal_code: "61734",
    full_city_name: "Delavan, IL",
    city_name: "Delavan",
  },
  {
    postal_code: "64067",
    full_city_name: "Lexington, MO",
    city_name: "Lexington",
  },
  {
    postal_code: "04047",
    full_city_name: "Parsonsfield, ME",
    city_name: "Parsonsfield",
  },
  {
    postal_code: "80117",
    full_city_name: "Kiowa, CO",
    city_name: "Kiowa",
  },
  {
    postal_code: "15027",
    full_city_name: "Conway, PA",
    city_name: "Conway",
  },
  {
    postal_code: "04622",
    full_city_name: "Cherryfield, ME",
    city_name: "Cherryfield",
  },
  {
    postal_code: "63650",
    full_city_name: "Ironton, MO",
    city_name: "Ironton",
  },
  {
    postal_code: "39175",
    full_city_name: "Utica, MS",
    city_name: "Utica",
  },
  {
    postal_code: "02738",
    full_city_name: "Marion, MA",
    city_name: "Marion",
  },
  {
    postal_code: "36024",
    full_city_name: "Eclectic, AL",
    city_name: "Eclectic",
  },
  {
    postal_code: "30056",
    full_city_name: "Newborn, GA",
    city_name: "Newborn",
  },
  {
    postal_code: "56215",
    full_city_name: "Benson, MN",
    city_name: "Benson",
  },
  {
    postal_code: "59868",
    full_city_name: "Seeley Lake, MT",
    city_name: "Seeley Lake",
  },
  {
    postal_code: "78368",
    full_city_name: "Mathis, TX",
    city_name: "Mathis",
  },
  {
    postal_code: "64011",
    full_city_name: "Bates City, MO",
    city_name: "Bates City",
  },
  {
    postal_code: "85621",
    full_city_name: "Nogales, AZ",
    city_name: "Nogales",
  },
  {
    postal_code: "95703",
    full_city_name: "Applegate, CA",
    city_name: "Applegate",
  },
  {
    postal_code: "45370",
    full_city_name: "Spring Valley, OH",
    city_name: "Spring Valley",
  },
  {
    postal_code: "02109",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "02747",
    full_city_name: "North Dartmouth, MA",
    city_name: "North Dartmouth",
  },
  {
    postal_code: "79504",
    full_city_name: "Baird, TX",
    city_name: "Baird",
  },
  {
    postal_code: "72956",
    full_city_name: "Van Buren, AR",
    city_name: "Van Buren",
  },
  {
    postal_code: "76462",
    full_city_name: "Lipan, TX",
    city_name: "Lipan",
  },
  {
    postal_code: "16301",
    full_city_name: "Oil City, PA",
    city_name: "Oil City",
  },
  {
    postal_code: "43137",
    full_city_name: "Lockbourne, OH",
    city_name: "Lockbourne",
  },
  {
    postal_code: "25601",
    full_city_name: "Logan, WV",
    city_name: "Logan",
  },
  {
    postal_code: "04496",
    full_city_name: "Winterport, ME",
    city_name: "Winterport",
  },
  {
    postal_code: "12062",
    full_city_name: "East Nassau, NY",
    city_name: "East Nassau",
  },
  {
    postal_code: "04222",
    full_city_name: "Durham, ME",
    city_name: "Durham",
  },
  {
    postal_code: "28678",
    full_city_name: "Stony Point, NC",
    city_name: "Stony Point",
  },
  {
    postal_code: "90304",
    full_city_name: "Inglewood, CA",
    city_name: "Inglewood",
  },
  {
    postal_code: "04937",
    full_city_name: "Fairfield, ME",
    city_name: "Fairfield",
  },
  {
    postal_code: "41144",
    full_city_name: "Greenup, KY",
    city_name: "Greenup",
  },
  {
    postal_code: "12566",
    full_city_name: "Pine Bush, NY",
    city_name: "Pine Bush",
  },
  {
    postal_code: "50073",
    full_city_name: "Elkhart, IA",
    city_name: "Elkhart",
  },
  {
    postal_code: "38544",
    full_city_name: "Baxter, TN",
    city_name: "Baxter",
  },
  {
    postal_code: "43150",
    full_city_name: "Rushville, OH",
    city_name: "Rushville",
  },
  {
    postal_code: "53032",
    full_city_name: "Horicon, WI",
    city_name: "Horicon",
  },
  {
    postal_code: "38915",
    full_city_name: "Bruce, MS",
    city_name: "Bruce",
  },
  {
    postal_code: "66217",
    full_city_name: "Shawnee, KS",
    city_name: "Shawnee",
  },
  {
    postal_code: "77371",
    full_city_name: "Shepherd, TX",
    city_name: "Shepherd",
  },
  {
    postal_code: "02375",
    full_city_name: "South Easton, MA",
    city_name: "South Easton",
  },
  {
    postal_code: "16373",
    full_city_name: "Emlenton, PA",
    city_name: "Emlenton",
  },
  {
    postal_code: "97435",
    full_city_name: "Drain, OR",
    city_name: "Drain",
  },
  {
    postal_code: "03582",
    full_city_name: "Groveton, NH",
    city_name: "Groveton",
  },
  {
    postal_code: "42261",
    full_city_name: "Morgantown, KY",
    city_name: "Morgantown",
  },
  {
    postal_code: "40440",
    full_city_name: "Junction City, KY",
    city_name: "Junction City",
  },
  {
    postal_code: "12305",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "69357",
    full_city_name: "Mitchell, NE",
    city_name: "Mitchell",
  },
  {
    postal_code: "77954",
    full_city_name: "Cuero, TX",
    city_name: "Cuero",
  },
  {
    postal_code: "05679",
    full_city_name: "Williamstown, VT",
    city_name: "Williamstown",
  },
  {
    postal_code: "63801",
    full_city_name: "Sikeston, MO",
    city_name: "Sikeston",
  },
  {
    postal_code: "92823",
    full_city_name: "Brea, CA",
    city_name: "Brea",
  },
  {
    postal_code: "49107",
    full_city_name: "Buchanan, MI",
    city_name: "Buchanan",
  },
  {
    postal_code: "23606",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "23959",
    full_city_name: "Phenix, VA",
    city_name: "Phenix",
  },
  {
    postal_code: "78002",
    full_city_name: "Atascosa, TX",
    city_name: "Atascosa",
  },
  {
    postal_code: "06279",
    full_city_name: "Willington, CT",
    city_name: "Willington",
  },
  {
    postal_code: "61856",
    full_city_name: "Monticello, IL",
    city_name: "Monticello",
  },
  {
    postal_code: "65622",
    full_city_name: "Buffalo, MO",
    city_name: "Buffalo",
  },
  {
    postal_code: "01057",
    full_city_name: "Monson, MA",
    city_name: "Monson",
  },
  {
    postal_code: "02052",
    full_city_name: "Medfield, MA",
    city_name: "Medfield",
  },
  {
    postal_code: "43310",
    full_city_name: "Belle Center, OH",
    city_name: "Belle Center",
  },
  {
    postal_code: "44705",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "65326",
    full_city_name: "Edwards, MO",
    city_name: "Edwards",
  },
  {
    postal_code: "48186",
    full_city_name: "Westland, MI",
    city_name: "Westland",
  },
  {
    postal_code: "54874",
    full_city_name: "South Range, WI",
    city_name: "South Range",
  },
  {
    postal_code: "48746",
    full_city_name: "Millington, MI",
    city_name: "Millington",
  },
  {
    postal_code: "03215",
    full_city_name: "Waterville Valley, NH",
    city_name: "Waterville Valley",
  },
  {
    postal_code: "37711",
    full_city_name: "Bulls Gap, TN",
    city_name: "Bulls Gap",
  },
  {
    postal_code: "55955",
    full_city_name: "Mantorville, MN",
    city_name: "Mantorville",
  },
  {
    postal_code: "63740",
    full_city_name: "Chaffee, MO",
    city_name: "Chaffee",
  },
  {
    postal_code: "20676",
    full_city_name: "Port Republic, MD",
    city_name: "Port Republic",
  },
  {
    postal_code: "29047",
    full_city_name: "Elloree, SC",
    city_name: "Elloree",
  },
  {
    postal_code: "62952",
    full_city_name: "Jonesboro, IL",
    city_name: "Jonesboro",
  },
  {
    postal_code: "13803",
    full_city_name: "Marathon, NY",
    city_name: "Marathon",
  },
  {
    postal_code: "02562",
    full_city_name: "Sagamore Beach, MA",
    city_name: "Sagamore Beach",
  },
  {
    postal_code: "48185",
    full_city_name: "Westland, MI",
    city_name: "Westland",
  },
  {
    postal_code: "30663",
    full_city_name: "Rutledge, GA",
    city_name: "Rutledge",
  },
  {
    postal_code: "44103",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "44402",
    full_city_name: "Bristolville, OH",
    city_name: "Bristolville",
  },
  {
    postal_code: "27017",
    full_city_name: "Dobson, NC",
    city_name: "Dobson",
  },
  {
    postal_code: "46166",
    full_city_name: "Paragon, IN",
    city_name: "Paragon",
  },
  {
    postal_code: "21654",
    full_city_name: "Oxford, MD",
    city_name: "Oxford",
  },
  {
    postal_code: "04256",
    full_city_name: "Mechanic Falls, ME",
    city_name: "Mechanic Falls",
  },
  {
    postal_code: "47715",
    full_city_name: "Evansville, IN",
    city_name: "Evansville",
  },
  {
    postal_code: "28642",
    full_city_name: "Jonesville, NC",
    city_name: "Jonesville",
  },
  {
    postal_code: "36260",
    full_city_name: "Eastaboga, AL",
    city_name: "Eastaboga",
  },
  {
    postal_code: "42345",
    full_city_name: "Greenville, KY",
    city_name: "Greenville",
  },
  {
    postal_code: "23917",
    full_city_name: "Boydton, VA",
    city_name: "Boydton",
  },
  {
    postal_code: "01071",
    full_city_name: "Russell, MA",
    city_name: "Russell",
  },
  {
    postal_code: "03785",
    full_city_name: "Woodsville, NH",
    city_name: "Woodsville",
  },
  {
    postal_code: "40740",
    full_city_name: "Lily, KY",
    city_name: "Lily",
  },
  {
    postal_code: "96755",
    full_city_name: "Kapaau, HI",
    city_name: "Kapaau",
  },
  {
    postal_code: "98674",
    full_city_name: "Woodland, WA",
    city_name: "Woodland",
  },
  {
    postal_code: "02138",
    full_city_name: "Cambridge, MA",
    city_name: "Cambridge",
  },
  {
    postal_code: "24477",
    full_city_name: "Stuarts Draft, VA",
    city_name: "Stuarts Draft",
  },
  {
    postal_code: "29545",
    full_city_name: "Green Sea, SC",
    city_name: "Green Sea",
  },
  {
    postal_code: "50056",
    full_city_name: "Colo, IA",
    city_name: "Colo",
  },
  {
    postal_code: "56098",
    full_city_name: "Winnebago, MN",
    city_name: "Winnebago",
  },
  {
    postal_code: "42726",
    full_city_name: "Clarkson, KY",
    city_name: "Clarkson",
  },
  {
    postal_code: "89419",
    full_city_name: "Lovelock, NV",
    city_name: "Lovelock",
  },
  {
    postal_code: "16023",
    full_city_name: "Cabot, PA",
    city_name: "Cabot",
  },
  {
    postal_code: "14817",
    full_city_name: "Brooktondale, NY",
    city_name: "Brooktondale",
  },
  {
    postal_code: "13438",
    full_city_name: "Remsen, NY",
    city_name: "Remsen",
  },
  {
    postal_code: "01518",
    full_city_name: "Fiskdale, MA",
    city_name: "Fiskdale",
  },
  {
    postal_code: "03849",
    full_city_name: "Madison, NH",
    city_name: "Madison",
  },
  {
    postal_code: "43001",
    full_city_name: "Alexandria, OH",
    city_name: "Alexandria",
  },
  {
    postal_code: "95942",
    full_city_name: "Forest Ranch, CA",
    city_name: "Forest Ranch",
  },
  {
    postal_code: "45123",
    full_city_name: "Greenfield, OH",
    city_name: "Greenfield",
  },
  {
    postal_code: "20751",
    full_city_name: "Deale, MD",
    city_name: "Deale",
  },
  {
    postal_code: "04348",
    full_city_name: "Jefferson, ME",
    city_name: "Jefferson",
  },
  {
    postal_code: "44264",
    full_city_name: "Peninsula, OH",
    city_name: "Peninsula",
  },
  {
    postal_code: "22827",
    full_city_name: "Elkton, VA",
    city_name: "Elkton",
  },
  {
    postal_code: "78390",
    full_city_name: "Taft, TX",
    city_name: "Taft",
  },
  {
    postal_code: "04989",
    full_city_name: "Vassalboro, ME",
    city_name: "Vassalboro",
  },
  {
    postal_code: "06277",
    full_city_name: "Thompson, CT",
    city_name: "Thompson",
  },
  {
    postal_code: "43756",
    full_city_name: "Mcconnelsville, OH",
    city_name: "Mcconnelsville",
  },
  {
    postal_code: "12920",
    full_city_name: "Chateaugay, NY",
    city_name: "Chateaugay",
  },
  {
    postal_code: "61570",
    full_city_name: "Washburn, IL",
    city_name: "Washburn",
  },
  {
    postal_code: "05454",
    full_city_name: "Fairfax, VT",
    city_name: "Fairfax",
  },
  {
    postal_code: "62257",
    full_city_name: "Marissa, IL",
    city_name: "Marissa",
  },
  {
    postal_code: "94939",
    full_city_name: "Larkspur, CA",
    city_name: "Larkspur",
  },
  {
    postal_code: "95426",
    full_city_name: "Cobb, CA",
    city_name: "Cobb",
  },
  {
    postal_code: "44255",
    full_city_name: "Mantua, OH",
    city_name: "Mantua",
  },
  {
    postal_code: "95629",
    full_city_name: "Fiddletown, CA",
    city_name: "Fiddletown",
  },
  {
    postal_code: "57793",
    full_city_name: "Whitewood, SD",
    city_name: "Whitewood",
  },
  {
    postal_code: "16933",
    full_city_name: "Mansfield, PA",
    city_name: "Mansfield",
  },
  {
    postal_code: "31211",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "01056",
    full_city_name: "Ludlow, MA",
    city_name: "Ludlow",
  },
  {
    postal_code: "76634",
    full_city_name: "Clifton, TX",
    city_name: "Clifton",
  },
  {
    postal_code: "54102",
    full_city_name: "Amberg, WI",
    city_name: "Amberg",
  },
  {
    postal_code: "34449",
    full_city_name: "Inglis, FL",
    city_name: "Inglis",
  },
  {
    postal_code: "41056",
    full_city_name: "Maysville, KY",
    city_name: "Maysville",
  },
  {
    postal_code: "13862",
    full_city_name: "Whitney Point, NY",
    city_name: "Whitney Point",
  },
  {
    postal_code: "61310",
    full_city_name: "Amboy, IL",
    city_name: "Amboy",
  },
  {
    postal_code: "05832",
    full_city_name: "East Burke, VT",
    city_name: "East Burke",
  },
  {
    postal_code: "75770",
    full_city_name: "Larue, TX",
    city_name: "Larue",
  },
  {
    postal_code: "30735",
    full_city_name: "Resaca, GA",
    city_name: "Resaca",
  },
  {
    postal_code: "13118",
    full_city_name: "Moravia, NY",
    city_name: "Moravia",
  },
  {
    postal_code: "48892",
    full_city_name: "Webberville, MI",
    city_name: "Webberville",
  },
  {
    postal_code: "70052",
    full_city_name: "Gramercy, LA",
    city_name: "Gramercy",
  },
  {
    postal_code: "43910",
    full_city_name: "Bloomingdale, OH",
    city_name: "Bloomingdale",
  },
  {
    postal_code: "11804",
    full_city_name: "Old Bethpage, NY",
    city_name: "Old Bethpage",
  },
  {
    postal_code: "18417",
    full_city_name: "Equinunk, PA",
    city_name: "Equinunk",
  },
  {
    postal_code: "12861",
    full_city_name: "Putnam Station, NY",
    city_name: "Putnam Station",
  },
  {
    postal_code: "16134",
    full_city_name: "Jamestown, PA",
    city_name: "Jamestown",
  },
  {
    postal_code: "43334",
    full_city_name: "Marengo, OH",
    city_name: "Marengo",
  },
  {
    postal_code: "95470",
    full_city_name: "Redwood Valley, CA",
    city_name: "Redwood Valley",
  },
  {
    postal_code: "18011",
    full_city_name: "Alburtis, PA",
    city_name: "Alburtis",
  },
  {
    postal_code: "83612",
    full_city_name: "Council, ID",
    city_name: "Council",
  },
  {
    postal_code: "03282",
    full_city_name: "Wentworth, NH",
    city_name: "Wentworth",
  },
  {
    postal_code: "02134",
    full_city_name: "Allston, MA",
    city_name: "Allston",
  },
  {
    postal_code: "24571",
    full_city_name: "Lynch Station, VA",
    city_name: "Lynch Station",
  },
  {
    postal_code: "39577",
    full_city_name: "Wiggins, MS",
    city_name: "Wiggins",
  },
  {
    postal_code: "53960",
    full_city_name: "Rio, WI",
    city_name: "Rio",
  },
  {
    postal_code: "37012",
    full_city_name: "Alexandria, TN",
    city_name: "Alexandria",
  },
  {
    postal_code: "11752",
    full_city_name: "Islip Terrace, NY",
    city_name: "Islip Terrace",
  },
  {
    postal_code: "29353",
    full_city_name: "Jonesville, SC",
    city_name: "Jonesville",
  },
  {
    postal_code: "54026",
    full_city_name: "Star Prairie, WI",
    city_name: "Star Prairie",
  },
  {
    postal_code: "04953",
    full_city_name: "Newport, ME",
    city_name: "Newport",
  },
  {
    postal_code: "43135",
    full_city_name: "Laurelville, OH",
    city_name: "Laurelville",
  },
  {
    postal_code: "37307",
    full_city_name: "Benton, TN",
    city_name: "Benton",
  },
  {
    postal_code: "38357",
    full_city_name: "Michie, TN",
    city_name: "Michie",
  },
  {
    postal_code: "15312",
    full_city_name: "Avella, PA",
    city_name: "Avella",
  },
  {
    postal_code: "59425",
    full_city_name: "Conrad, MT",
    city_name: "Conrad",
  },
  {
    postal_code: "42765",
    full_city_name: "Munfordville, KY",
    city_name: "Munfordville",
  },
  {
    postal_code: "79068",
    full_city_name: "Panhandle, TX",
    city_name: "Panhandle",
  },
  {
    postal_code: "12066",
    full_city_name: "Esperance, NY",
    city_name: "Esperance",
  },
  {
    postal_code: "15747",
    full_city_name: "Home, PA",
    city_name: "Home",
  },
  {
    postal_code: "95327",
    full_city_name: "Jamestown, CA",
    city_name: "Jamestown",
  },
  {
    postal_code: "22652",
    full_city_name: "Fort Valley, VA",
    city_name: "Fort Valley",
  },
  {
    postal_code: "56159",
    full_city_name: "Mountain Lake, MN",
    city_name: "Mountain Lake",
  },
  {
    postal_code: "55006",
    full_city_name: "Braham, MN",
    city_name: "Braham",
  },
  {
    postal_code: "42743",
    full_city_name: "Greensburg, KY",
    city_name: "Greensburg",
  },
  {
    postal_code: "84339",
    full_city_name: "Wellsville, UT",
    city_name: "Wellsville",
  },
  {
    postal_code: "54835",
    full_city_name: "Exeland, WI",
    city_name: "Exeland",
  },
  {
    postal_code: "44429",
    full_city_name: "Lake Milton, OH",
    city_name: "Lake Milton",
  },
  {
    postal_code: "77032",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "96754",
    full_city_name: "Kilauea, HI",
    city_name: "Kilauea",
  },
  {
    postal_code: "55614",
    full_city_name: "Silver Bay, MN",
    city_name: "Silver Bay",
  },
  {
    postal_code: "28366",
    full_city_name: "Newton Grove, NC",
    city_name: "Newton Grove",
  },
  {
    postal_code: "62465",
    full_city_name: "Strasburg, IL",
    city_name: "Strasburg",
  },
  {
    postal_code: "53573",
    full_city_name: "Muscoda, WI",
    city_name: "Muscoda",
  },
  {
    postal_code: "48048",
    full_city_name: "New Haven, MI",
    city_name: "New Haven",
  },
  {
    postal_code: "61561",
    full_city_name: "Roanoke, IL",
    city_name: "Roanoke",
  },
  {
    postal_code: "36750",
    full_city_name: "Maplesville, AL",
    city_name: "Maplesville",
  },
  {
    postal_code: "49781",
    full_city_name: "Saint Ignace, MI",
    city_name: "Saint Ignace",
  },
  {
    postal_code: "75414",
    full_city_name: "Bells, TX",
    city_name: "Bells",
  },
  {
    postal_code: "39428",
    full_city_name: "Collins, MS",
    city_name: "Collins",
  },
  {
    postal_code: "43447",
    full_city_name: "Millbury, OH",
    city_name: "Millbury",
  },
  {
    postal_code: "03447",
    full_city_name: "Fitzwilliam, NH",
    city_name: "Fitzwilliam",
  },
  {
    postal_code: "23830",
    full_city_name: "Carson, VA",
    city_name: "Carson",
  },
  {
    postal_code: "62520",
    full_city_name: "Dawson, IL",
    city_name: "Dawson",
  },
  {
    postal_code: "38225",
    full_city_name: "Dresden, TN",
    city_name: "Dresden",
  },
  {
    postal_code: "83112",
    full_city_name: "Bedford, WY",
    city_name: "Bedford",
  },
  {
    postal_code: "29335",
    full_city_name: "Enoree, SC",
    city_name: "Enoree",
  },
  {
    postal_code: "54011",
    full_city_name: "Ellsworth, WI",
    city_name: "Ellsworth",
  },
  {
    postal_code: "40117",
    full_city_name: "Ekron, KY",
    city_name: "Ekron",
  },
  {
    postal_code: "61275",
    full_city_name: "Port Byron, IL",
    city_name: "Port Byron",
  },
  {
    postal_code: "68362",
    full_city_name: "Gilead, NE",
    city_name: "Gilead",
  },
  {
    postal_code: "04742",
    full_city_name: "Fort Fairfield, ME",
    city_name: "Fort Fairfield",
  },
  {
    postal_code: "68377",
    full_city_name: "Jansen, NE",
    city_name: "Jansen",
  },
  {
    postal_code: "81003",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "80825",
    full_city_name: "Kit Carson, CO",
    city_name: "Kit Carson",
  },
  {
    postal_code: "70734",
    full_city_name: "Geismar, LA",
    city_name: "Geismar",
  },
  {
    postal_code: "12070",
    full_city_name: "Fort Johnson, NY",
    city_name: "Fort Johnson",
  },
  {
    postal_code: "36280",
    full_city_name: "Woodland, AL",
    city_name: "Woodland",
  },
  {
    postal_code: "03452",
    full_city_name: "Jaffrey, NH",
    city_name: "Jaffrey",
  },
  {
    postal_code: "20693",
    full_city_name: "Welcome, MD",
    city_name: "Welcome",
  },
  {
    postal_code: "17853",
    full_city_name: "Mount Pleasant Mills, PA",
    city_name: "Mount Pleasant Mills",
  },
  {
    postal_code: "78659",
    full_city_name: "Paige, TX",
    city_name: "Paige",
  },
  {
    postal_code: "52730",
    full_city_name: "Camanche, IA",
    city_name: "Camanche",
  },
  {
    postal_code: "76665",
    full_city_name: "Meridian, TX",
    city_name: "Meridian",
  },
  {
    postal_code: "49402",
    full_city_name: "Branch, MI",
    city_name: "Branch",
  },
  {
    postal_code: "13441",
    full_city_name: "Rome, NY",
    city_name: "Rome",
  },
  {
    postal_code: "95328",
    full_city_name: "Keyes, CA",
    city_name: "Keyes",
  },
  {
    postal_code: "84320",
    full_city_name: "Lewiston, UT",
    city_name: "Lewiston",
  },
  {
    postal_code: "50123",
    full_city_name: "Humeston, IA",
    city_name: "Humeston",
  },
  {
    postal_code: "97032",
    full_city_name: "Hubbard, OR",
    city_name: "Hubbard",
  },
  {
    postal_code: "12307",
    full_city_name: "Schenectady, NY",
    city_name: "Schenectady",
  },
  {
    postal_code: "67862",
    full_city_name: "Manter, KS",
    city_name: "Manter",
  },
  {
    postal_code: "54637",
    full_city_name: "Hustler, WI",
    city_name: "Hustler",
  },
  {
    postal_code: "28726",
    full_city_name: "East Flat Rock, NC",
    city_name: "East Flat Rock",
  },
  {
    postal_code: "19520",
    full_city_name: "Elverson, PA",
    city_name: "Elverson",
  },
  {
    postal_code: "11719",
    full_city_name: "Brookhaven, NY",
    city_name: "Brookhaven",
  },
  {
    postal_code: "84634",
    full_city_name: "Gunnison, UT",
    city_name: "Gunnison",
  },
  {
    postal_code: "84307",
    full_city_name: "Corinne, UT",
    city_name: "Corinne",
  },
  {
    postal_code: "56628",
    full_city_name: "Bigfork, MN",
    city_name: "Bigfork",
  },
  {
    postal_code: "60165",
    full_city_name: "Stone Park, IL",
    city_name: "Stone Park",
  },
  {
    postal_code: "78010",
    full_city_name: "Center Point, TX",
    city_name: "Center Point",
  },
  {
    postal_code: "75844",
    full_city_name: "Grapeland, TX",
    city_name: "Grapeland",
  },
  {
    postal_code: "78361",
    full_city_name: "Hebbronville, TX",
    city_name: "Hebbronville",
  },
  {
    postal_code: "54149",
    full_city_name: "Mountain, WI",
    city_name: "Mountain",
  },
  {
    postal_code: "37916",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "55960",
    full_city_name: "Oronoco, MN",
    city_name: "Oronoco",
  },
  {
    postal_code: "72044",
    full_city_name: "Edgemont, AR",
    city_name: "Edgemont",
  },
  {
    postal_code: "63730",
    full_city_name: "Advance, MO",
    city_name: "Advance",
  },
  {
    postal_code: "01562",
    full_city_name: "Spencer, MA",
    city_name: "Spencer",
  },
  {
    postal_code: "55972",
    full_city_name: "Saint Charles, MN",
    city_name: "Saint Charles",
  },
  {
    postal_code: "34797",
    full_city_name: "Yalaha, FL",
    city_name: "Yalaha",
  },
  {
    postal_code: "13111",
    full_city_name: "Martville, NY",
    city_name: "Martville",
  },
  {
    postal_code: "13120",
    full_city_name: "Nedrow, NY",
    city_name: "Nedrow",
  },
  {
    postal_code: "65452",
    full_city_name: "Crocker, MO",
    city_name: "Crocker",
  },
  {
    postal_code: "15530",
    full_city_name: "Berlin, PA",
    city_name: "Berlin",
  },
  {
    postal_code: "55977",
    full_city_name: "Taopi, MN",
    city_name: "Taopi",
  },
  {
    postal_code: "31811",
    full_city_name: "Hamilton, GA",
    city_name: "Hamilton",
  },
  {
    postal_code: "15731",
    full_city_name: "Coral, PA",
    city_name: "Coral",
  },
  {
    postal_code: "81523",
    full_city_name: "Glade Park, CO",
    city_name: "Glade Park",
  },
  {
    postal_code: "45679",
    full_city_name: "Seaman, OH",
    city_name: "Seaman",
  },
  {
    postal_code: "01879",
    full_city_name: "Tyngsboro, MA",
    city_name: "Tyngsboro",
  },
  {
    postal_code: "18453",
    full_city_name: "Pleasant Mount, PA",
    city_name: "Pleasant Mount",
  },
  {
    postal_code: "08310",
    full_city_name: "Buena, NJ",
    city_name: "Buena",
  },
  {
    postal_code: "03580",
    full_city_name: "Franconia, NH",
    city_name: "Franconia",
  },
  {
    postal_code: "62230",
    full_city_name: "Breese, IL",
    city_name: "Breese",
  },
  {
    postal_code: "79235",
    full_city_name: "Floydada, TX",
    city_name: "Floydada",
  },
  {
    postal_code: "21561",
    full_city_name: "Swanton, MD",
    city_name: "Swanton",
  },
  {
    postal_code: "61369",
    full_city_name: "Toluca, IL",
    city_name: "Toluca",
  },
  {
    postal_code: "24465",
    full_city_name: "Monterey, VA",
    city_name: "Monterey",
  },
  {
    postal_code: "57751",
    full_city_name: "Keystone, SD",
    city_name: "Keystone",
  },
  {
    postal_code: "61041",
    full_city_name: "Hanover, IL",
    city_name: "Hanover",
  },
  {
    postal_code: "27923",
    full_city_name: "Coinjock, NC",
    city_name: "Coinjock",
  },
  {
    postal_code: "01756",
    full_city_name: "Mendon, MA",
    city_name: "Mendon",
  },
  {
    postal_code: "12137",
    full_city_name: "Pattersonville, NY",
    city_name: "Pattersonville",
  },
  {
    postal_code: "78372",
    full_city_name: "Orange Grove, TX",
    city_name: "Orange Grove",
  },
  {
    postal_code: "28612",
    full_city_name: "Connelly Springs, NC",
    city_name: "Connelly Springs",
  },
  {
    postal_code: "78147",
    full_city_name: "Poth, TX",
    city_name: "Poth",
  },
  {
    postal_code: "16038",
    full_city_name: "Harrisville, PA",
    city_name: "Harrisville",
  },
  {
    postal_code: "03278",
    full_city_name: "Warner, NH",
    city_name: "Warner",
  },
  {
    postal_code: "48822",
    full_city_name: "Eagle, MI",
    city_name: "Eagle",
  },
  {
    postal_code: "23947",
    full_city_name: "Keysville, VA",
    city_name: "Keysville",
  },
  {
    postal_code: "80133",
    full_city_name: "Palmer Lake, CO",
    city_name: "Palmer Lake",
  },
  {
    postal_code: "12106",
    full_city_name: "Kinderhook, NY",
    city_name: "Kinderhook",
  },
  {
    postal_code: "01603",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "83873",
    full_city_name: "Wallace, ID",
    city_name: "Wallace",
  },
  {
    postal_code: "54545",
    full_city_name: "Manitowish Waters, WI",
    city_name: "Manitowish Waters",
  },
  {
    postal_code: "18630",
    full_city_name: "Meshoppen, PA",
    city_name: "Meshoppen",
  },
  {
    postal_code: "46048",
    full_city_name: "Ingalls, IN",
    city_name: "Ingalls",
  },
  {
    postal_code: "25187",
    full_city_name: "Southside, WV",
    city_name: "Southside",
  },
  {
    postal_code: "53807",
    full_city_name: "Cuba City, WI",
    city_name: "Cuba City",
  },
  {
    postal_code: "72142",
    full_city_name: "Scott, AR",
    city_name: "Scott",
  },
  {
    postal_code: "12472",
    full_city_name: "Rosendale, NY",
    city_name: "Rosendale",
  },
  {
    postal_code: "56316",
    full_city_name: "Brooten, MN",
    city_name: "Brooten",
  },
  {
    postal_code: "12042",
    full_city_name: "Climax, NY",
    city_name: "Climax",
  },
  {
    postal_code: "93648",
    full_city_name: "Parlier, CA",
    city_name: "Parlier",
  },
  {
    postal_code: "45054",
    full_city_name: "Oregonia, OH",
    city_name: "Oregonia",
  },
  {
    postal_code: "62275",
    full_city_name: "Pocahontas, IL",
    city_name: "Pocahontas",
  },
  {
    postal_code: "04048",
    full_city_name: "Limerick, ME",
    city_name: "Limerick",
  },
  {
    postal_code: "70084",
    full_city_name: "Reserve, LA",
    city_name: "Reserve",
  },
  {
    postal_code: "99009",
    full_city_name: "Elk, WA",
    city_name: "Elk",
  },
  {
    postal_code: "35952",
    full_city_name: "Altoona, AL",
    city_name: "Altoona",
  },
  {
    postal_code: "23882",
    full_city_name: "Stony Creek, VA",
    city_name: "Stony Creek",
  },
  {
    postal_code: "75423",
    full_city_name: "Celeste, TX",
    city_name: "Celeste",
  },
  {
    postal_code: "48165",
    full_city_name: "New Hudson, MI",
    city_name: "New Hudson",
  },
  {
    postal_code: "95410",
    full_city_name: "Albion, CA",
    city_name: "Albion",
  },
  {
    postal_code: "94925",
    full_city_name: "Corte Madera, CA",
    city_name: "Corte Madera",
  },
  {
    postal_code: "37726",
    full_city_name: "Deer Lodge, TN",
    city_name: "Deer Lodge",
  },
  {
    postal_code: "12193",
    full_city_name: "Westerlo, NY",
    city_name: "Westerlo",
  },
  {
    postal_code: "03470",
    full_city_name: "Winchester, NH",
    city_name: "Winchester",
  },
  {
    postal_code: "51536",
    full_city_name: "Hancock, IA",
    city_name: "Hancock",
  },
  {
    postal_code: "17724",
    full_city_name: "Canton, PA",
    city_name: "Canton",
  },
  {
    postal_code: "49895",
    full_city_name: "Wetmore, MI",
    city_name: "Wetmore",
  },
  {
    postal_code: "45369",
    full_city_name: "South Vienna, OH",
    city_name: "South Vienna",
  },
  {
    postal_code: "19066",
    full_city_name: "Merion Station, PA",
    city_name: "Merion Station",
  },
  {
    postal_code: "28478",
    full_city_name: "Willard, NC",
    city_name: "Willard",
  },
  {
    postal_code: "06241",
    full_city_name: "Dayville, CT",
    city_name: "Dayville",
  },
  {
    postal_code: "65354",
    full_city_name: "Syracuse, MO",
    city_name: "Syracuse",
  },
  {
    postal_code: "22644",
    full_city_name: "Maurertown, VA",
    city_name: "Maurertown",
  },
  {
    postal_code: "02421",
    full_city_name: "Lexington, MA",
    city_name: "Lexington",
  },
  {
    postal_code: "53516",
    full_city_name: "Blanchardville, WI",
    city_name: "Blanchardville",
  },
  {
    postal_code: "53929",
    full_city_name: "Elroy, WI",
    city_name: "Elroy",
  },
  {
    postal_code: "26521",
    full_city_name: "Blacksville, WV",
    city_name: "Blacksville",
  },
  {
    postal_code: "25840",
    full_city_name: "Fayetteville, WV",
    city_name: "Fayetteville",
  },
  {
    postal_code: "28644",
    full_city_name: "Laurel Springs, NC",
    city_name: "Laurel Springs",
  },
  {
    postal_code: "36268",
    full_city_name: "Munford, AL",
    city_name: "Munford",
  },
  {
    postal_code: "56340",
    full_city_name: "Holdingford, MN",
    city_name: "Holdingford",
  },
  {
    postal_code: "51579",
    full_city_name: "Woodbine, IA",
    city_name: "Woodbine",
  },
  {
    postal_code: "36025",
    full_city_name: "Elmore, AL",
    city_name: "Elmore",
  },
  {
    postal_code: "05676",
    full_city_name: "Waterbury, VT",
    city_name: "Waterbury",
  },
  {
    postal_code: "72006",
    full_city_name: "Augusta, AR",
    city_name: "Augusta",
  },
  {
    postal_code: "64856",
    full_city_name: "Pineville, MO",
    city_name: "Pineville",
  },
  {
    postal_code: "63361",
    full_city_name: "Montgomery City, MO",
    city_name: "Montgomery City",
  },
  {
    postal_code: "54613",
    full_city_name: "Arkdale, WI",
    city_name: "Arkdale",
  },
  {
    postal_code: "13731",
    full_city_name: "Andes, NY",
    city_name: "Andes",
  },
  {
    postal_code: "16426",
    full_city_name: "Mc Kean, PA",
    city_name: "Mc Kean",
  },
  {
    postal_code: "95258",
    full_city_name: "Woodbridge, CA",
    city_name: "Woodbridge",
  },
  {
    postal_code: "03602",
    full_city_name: "Alstead, NH",
    city_name: "Alstead",
  },
  {
    postal_code: "67579",
    full_city_name: "Sterling, KS",
    city_name: "Sterling",
  },
  {
    postal_code: "76639",
    full_city_name: "Dawson, TX",
    city_name: "Dawson",
  },
  {
    postal_code: "35143",
    full_city_name: "Shelby, AL",
    city_name: "Shelby",
  },
  {
    postal_code: "78941",
    full_city_name: "Flatonia, TX",
    city_name: "Flatonia",
  },
  {
    postal_code: "13156",
    full_city_name: "Sterling, NY",
    city_name: "Sterling",
  },
  {
    postal_code: "12423",
    full_city_name: "East Durham, NY",
    city_name: "East Durham",
  },
  {
    postal_code: "45661",
    full_city_name: "Piketon, OH",
    city_name: "Piketon",
  },
  {
    postal_code: "15479",
    full_city_name: "Smithton, PA",
    city_name: "Smithton",
  },
  {
    postal_code: "25387",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "56307",
    full_city_name: "Albany, MN",
    city_name: "Albany",
  },
  {
    postal_code: "56357",
    full_city_name: "Oak Park, MN",
    city_name: "Oak Park",
  },
  {
    postal_code: "49625",
    full_city_name: "Copemish, MI",
    city_name: "Copemish",
  },
  {
    postal_code: "61257",
    full_city_name: "Hillsdale, IL",
    city_name: "Hillsdale",
  },
  {
    postal_code: "55940",
    full_city_name: "Hayfield, MN",
    city_name: "Hayfield",
  },
  {
    postal_code: "40143",
    full_city_name: "Hardinsburg, KY",
    city_name: "Hardinsburg",
  },
  {
    postal_code: "25880",
    full_city_name: "Mount Hope, WV",
    city_name: "Mount Hope",
  },
  {
    postal_code: "32764",
    full_city_name: "Osteen, FL",
    city_name: "Osteen",
  },
  {
    postal_code: "44234",
    full_city_name: "Hiram, OH",
    city_name: "Hiram",
  },
  {
    postal_code: "24162",
    full_city_name: "Shawsville, VA",
    city_name: "Shawsville",
  },
  {
    postal_code: "56062",
    full_city_name: "Madelia, MN",
    city_name: "Madelia",
  },
  {
    postal_code: "63087",
    full_city_name: "Valles Mines, MO",
    city_name: "Valles Mines",
  },
  {
    postal_code: "79343",
    full_city_name: "Lorenzo, TX",
    city_name: "Lorenzo",
  },
  {
    postal_code: "15840",
    full_city_name: "Falls Creek, PA",
    city_name: "Falls Creek",
  },
  {
    postal_code: "15620",
    full_city_name: "Bradenville, PA",
    city_name: "Bradenville",
  },
  {
    postal_code: "45830",
    full_city_name: "Columbus Grove, OH",
    city_name: "Columbus Grove",
  },
  {
    postal_code: "46799",
    full_city_name: "Zanesville, IN",
    city_name: "Zanesville",
  },
  {
    postal_code: "45880",
    full_city_name: "Payne, OH",
    city_name: "Payne",
  },
  {
    postal_code: "55055",
    full_city_name: "Newport, MN",
    city_name: "Newport",
  },
  {
    postal_code: "03823",
    full_city_name: "Madbury, NH",
    city_name: "Madbury",
  },
  {
    postal_code: "45120",
    full_city_name: "Felicity, OH",
    city_name: "Felicity",
  },
  {
    postal_code: "17744",
    full_city_name: "Linden, PA",
    city_name: "Linden",
  },
  {
    postal_code: "03851",
    full_city_name: "Milton, NH",
    city_name: "Milton",
  },
  {
    postal_code: "49879",
    full_city_name: "Republic, MI",
    city_name: "Republic",
  },
  {
    postal_code: "86332",
    full_city_name: "Kirkland, AZ",
    city_name: "Kirkland",
  },
  {
    postal_code: "56672",
    full_city_name: "Remer, MN",
    city_name: "Remer",
  },
  {
    postal_code: "27009",
    full_city_name: "Belews Creek, NC",
    city_name: "Belews Creek",
  },
  {
    postal_code: "72921",
    full_city_name: "Alma, AR",
    city_name: "Alma",
  },
  {
    postal_code: "18042",
    full_city_name: "Easton, PA",
    city_name: "Easton",
  },
  {
    postal_code: "41222",
    full_city_name: "Hagerhill, KY",
    city_name: "Hagerhill",
  },
  {
    postal_code: "49677",
    full_city_name: "Reed City, MI",
    city_name: "Reed City",
  },
  {
    postal_code: "74962",
    full_city_name: "Vian, OK",
    city_name: "Vian",
  },
  {
    postal_code: "26415",
    full_city_name: "Pennsboro, WV",
    city_name: "Pennsboro",
  },
  {
    postal_code: "64740",
    full_city_name: "Deepwater, MO",
    city_name: "Deepwater",
  },
  {
    postal_code: "62037",
    full_city_name: "Grafton, IL",
    city_name: "Grafton",
  },
  {
    postal_code: "03467",
    full_city_name: "Westmoreland, NH",
    city_name: "Westmoreland",
  },
  {
    postal_code: "54888",
    full_city_name: "Trego, WI",
    city_name: "Trego",
  },
  {
    postal_code: "64469",
    full_city_name: "Maysville, MO",
    city_name: "Maysville",
  },
  {
    postal_code: "37723",
    full_city_name: "Crab Orchard, TN",
    city_name: "Crab Orchard",
  },
  {
    postal_code: "44253",
    full_city_name: "Litchfield, OH",
    city_name: "Litchfield",
  },
  {
    postal_code: "62933",
    full_city_name: "Energy, IL",
    city_name: "Energy",
  },
  {
    postal_code: "75692",
    full_city_name: "Waskom, TX",
    city_name: "Waskom",
  },
  {
    postal_code: "50638",
    full_city_name: "Grundy Center, IA",
    city_name: "Grundy Center",
  },
  {
    postal_code: "03887",
    full_city_name: "Union, NH",
    city_name: "Union",
  },
  {
    postal_code: "13332",
    full_city_name: "Earlville, NY",
    city_name: "Earlville",
  },
  {
    postal_code: "86337",
    full_city_name: "Seligman, AZ",
    city_name: "Seligman",
  },
  {
    postal_code: "03603",
    full_city_name: "Charlestown, NH",
    city_name: "Charlestown",
  },
  {
    postal_code: "04412",
    full_city_name: "Brewer, ME",
    city_name: "Brewer",
  },
  {
    postal_code: "10018",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "15944",
    full_city_name: "New Florence, PA",
    city_name: "New Florence",
  },
  {
    postal_code: "25843",
    full_city_name: "Ghent, WV",
    city_name: "Ghent",
  },
  {
    postal_code: "46545",
    full_city_name: "Mishawaka, IN",
    city_name: "Mishawaka",
  },
  {
    postal_code: "24236",
    full_city_name: "Damascus, VA",
    city_name: "Damascus",
  },
  {
    postal_code: "39443",
    full_city_name: "Laurel, MS",
    city_name: "Laurel",
  },
  {
    postal_code: "78672",
    full_city_name: "Tow, TX",
    city_name: "Tow",
  },
  {
    postal_code: "85324",
    full_city_name: "Black Canyon City, AZ",
    city_name: "Black Canyon City",
  },
  {
    postal_code: "52773",
    full_city_name: "Walcott, IA",
    city_name: "Walcott",
  },
  {
    postal_code: "62084",
    full_city_name: "Roxana, IL",
    city_name: "Roxana",
  },
  {
    postal_code: "27966",
    full_city_name: "Powells Point, NC",
    city_name: "Powells Point",
  },
  {
    postal_code: "03057",
    full_city_name: "Mont Vernon, NH",
    city_name: "Mont Vernon",
  },
  {
    postal_code: "60146",
    full_city_name: "Kirkland, IL",
    city_name: "Kirkland",
  },
  {
    postal_code: "04038",
    full_city_name: "Gorham, ME",
    city_name: "Gorham",
  },
  {
    postal_code: "54837",
    full_city_name: "Frederic, WI",
    city_name: "Frederic",
  },
  {
    postal_code: "55735",
    full_city_name: "Finlayson, MN",
    city_name: "Finlayson",
  },
  {
    postal_code: "50028",
    full_city_name: "Baxter, IA",
    city_name: "Baxter",
  },
  {
    postal_code: "44431",
    full_city_name: "Leetonia, OH",
    city_name: "Leetonia",
  },
  {
    postal_code: "95811",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "43431",
    full_city_name: "Gibsonburg, OH",
    city_name: "Gibsonburg",
  },
  {
    postal_code: "80612",
    full_city_name: "Carr, CO",
    city_name: "Carr",
  },
  {
    postal_code: "49945",
    full_city_name: "Lake Linden, MI",
    city_name: "Lake Linden",
  },
  {
    postal_code: "06379",
    full_city_name: "Pawcatuck, CT",
    city_name: "Pawcatuck",
  },
  {
    postal_code: "54853",
    full_city_name: "Luck, WI",
    city_name: "Luck",
  },
  {
    postal_code: "87540",
    full_city_name: "Lamy, NM",
    city_name: "Lamy",
  },
  {
    postal_code: "54161",
    full_city_name: "Pound, WI",
    city_name: "Pound",
  },
  {
    postal_code: "48193",
    full_city_name: "Riverview, MI",
    city_name: "Riverview",
  },
  {
    postal_code: "24184",
    full_city_name: "Wirtz, VA",
    city_name: "Wirtz",
  },
  {
    postal_code: "02191",
    full_city_name: "North Weymouth, MA",
    city_name: "North Weymouth",
  },
  {
    postal_code: "94525",
    full_city_name: "Crockett, CA",
    city_name: "Crockett",
  },
  {
    postal_code: "38311",
    full_city_name: "Bath Springs, TN",
    city_name: "Bath Springs",
  },
  {
    postal_code: "52228",
    full_city_name: "Fairfax, IA",
    city_name: "Fairfax",
  },
  {
    postal_code: "52778",
    full_city_name: "Wilton, IA",
    city_name: "Wilton",
  },
  {
    postal_code: "62233",
    full_city_name: "Chester, IL",
    city_name: "Chester",
  },
  {
    postal_code: "20862",
    full_city_name: "Brinklow, MD",
    city_name: "Brinklow",
  },
  {
    postal_code: "48747",
    full_city_name: "Munger, MI",
    city_name: "Munger",
  },
  {
    postal_code: "60150",
    full_city_name: "Malta, IL",
    city_name: "Malta",
  },
  {
    postal_code: "43060",
    full_city_name: "North Lewisburg, OH",
    city_name: "North Lewisburg",
  },
  {
    postal_code: "03255",
    full_city_name: "Newbury, NH",
    city_name: "Newbury",
  },
  {
    postal_code: "27880",
    full_city_name: "Sims, NC",
    city_name: "Sims",
  },
  {
    postal_code: "84513",
    full_city_name: "Castle Dale, UT",
    city_name: "Castle Dale",
  },
  {
    postal_code: "72135",
    full_city_name: "Roland, AR",
    city_name: "Roland",
  },
  {
    postal_code: "03462",
    full_city_name: "Spofford, NH",
    city_name: "Spofford",
  },
  {
    postal_code: "01560",
    full_city_name: "South Grafton, MA",
    city_name: "South Grafton",
  },
  {
    postal_code: "33857",
    full_city_name: "Lorida, FL",
    city_name: "Lorida",
  },
  {
    postal_code: "56452",
    full_city_name: "Hackensack, MN",
    city_name: "Hackensack",
  },
  {
    postal_code: "12952",
    full_city_name: "Lyon Mountain, NY",
    city_name: "Lyon Mountain",
  },
  {
    postal_code: "28372",
    full_city_name: "Pembroke, NC",
    city_name: "Pembroke",
  },
  {
    postal_code: "44824",
    full_city_name: "Castalia, OH",
    city_name: "Castalia",
  },
  {
    postal_code: "64466",
    full_city_name: "Maitland, MO",
    city_name: "Maitland",
  },
  {
    postal_code: "37348",
    full_city_name: "Kelso, TN",
    city_name: "Kelso",
  },
  {
    postal_code: "83554",
    full_city_name: "White Bird, ID",
    city_name: "White Bird",
  },
  {
    postal_code: "38563",
    full_city_name: "Gordonsville, TN",
    city_name: "Gordonsville",
  },
  {
    postal_code: "15610",
    full_city_name: "Acme, PA",
    city_name: "Acme",
  },
  {
    postal_code: "55975",
    full_city_name: "Spring Valley, MN",
    city_name: "Spring Valley",
  },
  {
    postal_code: "49340",
    full_city_name: "Remus, MI",
    city_name: "Remus",
  },
  {
    postal_code: "65275",
    full_city_name: "Paris, MO",
    city_name: "Paris",
  },
  {
    postal_code: "32202",
    full_city_name: "Jacksonville, FL",
    city_name: "Jacksonville",
  },
  {
    postal_code: "02668",
    full_city_name: "West Barnstable, MA",
    city_name: "West Barnstable",
  },
  {
    postal_code: "74131",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "23944",
    full_city_name: "Kenbridge, VA",
    city_name: "Kenbridge",
  },
  {
    postal_code: "55983",
    full_city_name: "Wanamingo, MN",
    city_name: "Wanamingo",
  },
  {
    postal_code: "24472",
    full_city_name: "Raphine, VA",
    city_name: "Raphine",
  },
  {
    postal_code: "21791",
    full_city_name: "Union Bridge, MD",
    city_name: "Union Bridge",
  },
  {
    postal_code: "78022",
    full_city_name: "George West, TX",
    city_name: "George West",
  },
  {
    postal_code: "05261",
    full_city_name: "Pownal, VT",
    city_name: "Pownal",
  },
  {
    postal_code: "78583",
    full_city_name: "Rio Hondo, TX",
    city_name: "Rio Hondo",
  },
  {
    postal_code: "27960",
    full_city_name: "Ocracoke, NC",
    city_name: "Ocracoke",
  },
  {
    postal_code: "56726",
    full_city_name: "Greenbush, MN",
    city_name: "Greenbush",
  },
  {
    postal_code: "72027",
    full_city_name: "Center Ridge, AR",
    city_name: "Center Ridge",
  },
  {
    postal_code: "15140",
    full_city_name: "Pitcairn, PA",
    city_name: "Pitcairn",
  },
  {
    postal_code: "74041",
    full_city_name: "Kiefer, OK",
    city_name: "Kiefer",
  },
  {
    postal_code: "53936",
    full_city_name: "Grand Marsh, WI",
    city_name: "Grand Marsh",
  },
  {
    postal_code: "97360",
    full_city_name: "Mill City, OR",
    city_name: "Mill City",
  },
  {
    postal_code: "61569",
    full_city_name: "Trivoli, IL",
    city_name: "Trivoli",
  },
  {
    postal_code: "48428",
    full_city_name: "Dryden, MI",
    city_name: "Dryden",
  },
  {
    postal_code: "54814",
    full_city_name: "Bayfield, WI",
    city_name: "Bayfield",
  },
  {
    postal_code: "03263",
    full_city_name: "Pittsfield, NH",
    city_name: "Pittsfield",
  },
  {
    postal_code: "18452",
    full_city_name: "Peckville, PA",
    city_name: "Peckville",
  },
  {
    postal_code: "43045",
    full_city_name: "Milford Center, OH",
    city_name: "Milford Center",
  },
  {
    postal_code: "56065",
    full_city_name: "Mapleton, MN",
    city_name: "Mapleton",
  },
  {
    postal_code: "67072",
    full_city_name: "Latham, KS",
    city_name: "Latham",
  },
  {
    postal_code: "62939",
    full_city_name: "Goreville, IL",
    city_name: "Goreville",
  },
  {
    postal_code: "46128",
    full_city_name: "Fillmore, IN",
    city_name: "Fillmore",
  },
  {
    postal_code: "61326",
    full_city_name: "Granville, IL",
    city_name: "Granville",
  },
  {
    postal_code: "15015",
    full_city_name: "Bradfordwoods, PA",
    city_name: "Bradfordwoods",
  },
  {
    postal_code: "25123",
    full_city_name: "Leon, WV",
    city_name: "Leon",
  },
  {
    postal_code: "19503",
    full_city_name: "Bally, PA",
    city_name: "Bally",
  },
  {
    postal_code: "45053",
    full_city_name: "Okeana, OH",
    city_name: "Okeana",
  },
  {
    postal_code: "21520",
    full_city_name: "Accident, MD",
    city_name: "Accident",
  },
  {
    postal_code: "18472",
    full_city_name: "Waymart, PA",
    city_name: "Waymart",
  },
  {
    postal_code: "85137",
    full_city_name: "Kearny, AZ",
    city_name: "Kearny",
  },
  {
    postal_code: "99122",
    full_city_name: "Davenport, WA",
    city_name: "Davenport",
  },
  {
    postal_code: "80510",
    full_city_name: "Allenspark, CO",
    city_name: "Allenspark",
  },
  {
    postal_code: "90401",
    full_city_name: "Santa Monica, CA",
    city_name: "Santa Monica",
  },
  {
    postal_code: "55342",
    full_city_name: "Hector, MN",
    city_name: "Hector",
  },
  {
    postal_code: "02149",
    full_city_name: "Everett, MA",
    city_name: "Everett",
  },
  {
    postal_code: "48886",
    full_city_name: "Six Lakes, MI",
    city_name: "Six Lakes",
  },
  {
    postal_code: "56310",
    full_city_name: "Avon, MN",
    city_name: "Avon",
  },
  {
    postal_code: "45875",
    full_city_name: "Ottawa, OH",
    city_name: "Ottawa",
  },
  {
    postal_code: "16115",
    full_city_name: "Darlington, PA",
    city_name: "Darlington",
  },
  {
    postal_code: "29067",
    full_city_name: "Kershaw, SC",
    city_name: "Kershaw",
  },
  {
    postal_code: "44216",
    full_city_name: "Clinton, OH",
    city_name: "Clinton",
  },
  {
    postal_code: "55934",
    full_city_name: "Eyota, MN",
    city_name: "Eyota",
  },
  {
    postal_code: "98376",
    full_city_name: "Quilcene, WA",
    city_name: "Quilcene",
  },
  {
    postal_code: "03225",
    full_city_name: "Center Barnstead, NH",
    city_name: "Center Barnstead",
  },
  {
    postal_code: "95445",
    full_city_name: "Gualala, CA",
    city_name: "Gualala",
  },
  {
    postal_code: "27233",
    full_city_name: "Climax, NC",
    city_name: "Climax",
  },
  {
    postal_code: "77485",
    full_city_name: "Wallis, TX",
    city_name: "Wallis",
  },
  {
    postal_code: "23106",
    full_city_name: "Manquin, VA",
    city_name: "Manquin",
  },
  {
    postal_code: "78956",
    full_city_name: "Schulenburg, TX",
    city_name: "Schulenburg",
  },
  {
    postal_code: "15673",
    full_city_name: "North Apollo, PA",
    city_name: "North Apollo",
  },
  {
    postal_code: "49092",
    full_city_name: "Tekonsha, MI",
    city_name: "Tekonsha",
  },
  {
    postal_code: "68357",
    full_city_name: "Filley, NE",
    city_name: "Filley",
  },
  {
    postal_code: "56241",
    full_city_name: "Granite Falls, MN",
    city_name: "Granite Falls",
  },
  {
    postal_code: "75417",
    full_city_name: "Bogata, TX",
    city_name: "Bogata",
  },
  {
    postal_code: "43522",
    full_city_name: "Grand Rapids, OH",
    city_name: "Grand Rapids",
  },
  {
    postal_code: "67456",
    full_city_name: "Lindsborg, KS",
    city_name: "Lindsborg",
  },
  {
    postal_code: "43112",
    full_city_name: "Carroll, OH",
    city_name: "Carroll",
  },
  {
    postal_code: "55718",
    full_city_name: "Carlton, MN",
    city_name: "Carlton",
  },
  {
    postal_code: "04106",
    full_city_name: "South Portland, ME",
    city_name: "South Portland",
  },
  {
    postal_code: "56547",
    full_city_name: "Glyndon, MN",
    city_name: "Glyndon",
  },
  {
    postal_code: "47280",
    full_city_name: "Taylorsville, IN",
    city_name: "Taylorsville",
  },
  {
    postal_code: "17954",
    full_city_name: "Minersville, PA",
    city_name: "Minersville",
  },
  {
    postal_code: "03882",
    full_city_name: "Effingham, NH",
    city_name: "Effingham",
  },
  {
    postal_code: "95139",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "01030",
    full_city_name: "Feeding Hills, MA",
    city_name: "Feeding Hills",
  },
  {
    postal_code: "03052",
    full_city_name: "Litchfield, NH",
    city_name: "Litchfield",
  },
  {
    postal_code: "44085",
    full_city_name: "Rome, OH",
    city_name: "Rome",
  },
  {
    postal_code: "38006",
    full_city_name: "Bells, TN",
    city_name: "Bells",
  },
  {
    postal_code: "53521",
    full_city_name: "Brooklyn, WI",
    city_name: "Brooklyn",
  },
  {
    postal_code: "67468",
    full_city_name: "Morganville, KS",
    city_name: "Morganville",
  },
  {
    postal_code: "32058",
    full_city_name: "Lawtey, FL",
    city_name: "Lawtey",
  },
  {
    postal_code: "03264",
    full_city_name: "Plymouth, NH",
    city_name: "Plymouth",
  },
  {
    postal_code: "14865",
    full_city_name: "Montour Falls, NY",
    city_name: "Montour Falls",
  },
  {
    postal_code: "03908",
    full_city_name: "South Berwick, ME",
    city_name: "South Berwick",
  },
  {
    postal_code: "40011",
    full_city_name: "Campbellsburg, KY",
    city_name: "Campbellsburg",
  },
  {
    postal_code: "68623",
    full_city_name: "Belgrade, NE",
    city_name: "Belgrade",
  },
  {
    postal_code: "43462",
    full_city_name: "Rudolph, OH",
    city_name: "Rudolph",
  },
  {
    postal_code: "40019",
    full_city_name: "Eminence, KY",
    city_name: "Eminence",
  },
  {
    postal_code: "93646",
    full_city_name: "Orange Cove, CA",
    city_name: "Orange Cove",
  },
  {
    postal_code: "55985",
    full_city_name: "West Concord, MN",
    city_name: "West Concord",
  },
  {
    postal_code: "53559",
    full_city_name: "Marshall, WI",
    city_name: "Marshall",
  },
  {
    postal_code: "43136",
    full_city_name: "Lithopolis, OH",
    city_name: "Lithopolis",
  },
  {
    postal_code: "46532",
    full_city_name: "Hamlet, IN",
    city_name: "Hamlet",
  },
  {
    postal_code: "94005",
    full_city_name: "Brisbane, CA",
    city_name: "Brisbane",
  },
  {
    postal_code: "03886",
    full_city_name: "Tamworth, NH",
    city_name: "Tamworth",
  },
  {
    postal_code: "44412",
    full_city_name: "Diamond, OH",
    city_name: "Diamond",
  },
  {
    postal_code: "95441",
    full_city_name: "Geyserville, CA",
    city_name: "Geyserville",
  },
  {
    postal_code: "31778",
    full_city_name: "Pavo, GA",
    city_name: "Pavo",
  },
  {
    postal_code: "56684",
    full_city_name: "Trail, MN",
    city_name: "Trail",
  },
  {
    postal_code: "56557",
    full_city_name: "Mahnomen, MN",
    city_name: "Mahnomen",
  },
  {
    postal_code: "80645",
    full_city_name: "La Salle, CO",
    city_name: "La Salle",
  },
  {
    postal_code: "55354",
    full_city_name: "Lester Prairie, MN",
    city_name: "Lester Prairie",
  },
  {
    postal_code: "45384",
    full_city_name: "Wilberforce, OH",
    city_name: "Wilberforce",
  },
  {
    postal_code: "24431",
    full_city_name: "Crimora, VA",
    city_name: "Crimora",
  },
  {
    postal_code: "03440",
    full_city_name: "Antrim, NH",
    city_name: "Antrim",
  },
  {
    postal_code: "63462",
    full_city_name: "Perry, MO",
    city_name: "Perry",
  },
  {
    postal_code: "60145",
    full_city_name: "Kingston, IL",
    city_name: "Kingston",
  },
  {
    postal_code: "50146",
    full_city_name: "Linden, IA",
    city_name: "Linden",
  },
  {
    postal_code: "49328",
    full_city_name: "Hopkins, MI",
    city_name: "Hopkins",
  },
  {
    postal_code: "43046",
    full_city_name: "Millersport, OH",
    city_name: "Millersport",
  },
  {
    postal_code: "13417",
    full_city_name: "New York Mills, NY",
    city_name: "New York Mills",
  },
  {
    postal_code: "95046",
    full_city_name: "San Martin, CA",
    city_name: "San Martin",
  },
  {
    postal_code: "53577",
    full_city_name: "Plain, WI",
    city_name: "Plain",
  },
  {
    postal_code: "79915",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "02114",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "11518",
    full_city_name: "East Rockaway, NY",
    city_name: "East Rockaway",
  },
  {
    postal_code: "78559",
    full_city_name: "La Feria, TX",
    city_name: "La Feria",
  },
  {
    postal_code: "30564",
    full_city_name: "Murrayville, GA",
    city_name: "Murrayville",
  },
  {
    postal_code: "78402",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "64061",
    full_city_name: "Kingsville, MO",
    city_name: "Kingsville",
  },
  {
    postal_code: "13470",
    full_city_name: "Stratford, NY",
    city_name: "Stratford",
  },
  {
    postal_code: "30678",
    full_city_name: "White Plains, GA",
    city_name: "White Plains",
  },
  {
    postal_code: "23043",
    full_city_name: "Deltaville, VA",
    city_name: "Deltaville",
  },
  {
    postal_code: "95246",
    full_city_name: "Mountain Ranch, CA",
    city_name: "Mountain Ranch",
  },
  {
    postal_code: "08106",
    full_city_name: "Audubon, NJ",
    city_name: "Audubon",
  },
  {
    postal_code: "56542",
    full_city_name: "Fosston, MN",
    city_name: "Fosston",
  },
  {
    postal_code: "01721",
    full_city_name: "Ashland, MA",
    city_name: "Ashland",
  },
  {
    postal_code: "24486",
    full_city_name: "Weyers Cave, VA",
    city_name: "Weyers Cave",
  },
  {
    postal_code: "04963",
    full_city_name: "Oakland, ME",
    city_name: "Oakland",
  },
  {
    postal_code: "06519",
    full_city_name: "New Haven, CT",
    city_name: "New Haven",
  },
  {
    postal_code: "78059",
    full_city_name: "Natalia, TX",
    city_name: "Natalia",
  },
  {
    postal_code: "20117",
    full_city_name: "Middleburg, VA",
    city_name: "Middleburg",
  },
  {
    postal_code: "06089",
    full_city_name: "Weatogue, CT",
    city_name: "Weatogue",
  },
  {
    postal_code: "01915",
    full_city_name: "Beverly, MA",
    city_name: "Beverly",
  },
  {
    postal_code: "39154",
    full_city_name: "Raymond, MS",
    city_name: "Raymond",
  },
  {
    postal_code: "02141",
    full_city_name: "Cambridge, MA",
    city_name: "Cambridge",
  },
  {
    postal_code: "96141",
    full_city_name: "Homewood, CA",
    city_name: "Homewood",
  },
  {
    postal_code: "54742",
    full_city_name: "Fall Creek, WI",
    city_name: "Fall Creek",
  },
  {
    postal_code: "02053",
    full_city_name: "Medway, MA",
    city_name: "Medway",
  },
  {
    postal_code: "47006",
    full_city_name: "Batesville, IN",
    city_name: "Batesville",
  },
  {
    postal_code: "70446",
    full_city_name: "Loranger, LA",
    city_name: "Loranger",
  },
  {
    postal_code: "10552",
    full_city_name: "Mount Vernon, NY",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "10547",
    full_city_name: "Mohegan Lake, NY",
    city_name: "Mohegan Lake",
  },
  {
    postal_code: "04849",
    full_city_name: "Lincolnville, ME",
    city_name: "Lincolnville",
  },
  {
    postal_code: "71409",
    full_city_name: "Boyce, LA",
    city_name: "Boyce",
  },
  {
    postal_code: "04426",
    full_city_name: "Dover Foxcroft, ME",
    city_name: "Dover Foxcroft",
  },
  {
    postal_code: "08093",
    full_city_name: "Westville, NJ",
    city_name: "Westville",
  },
  {
    postal_code: "27970",
    full_city_name: "Roper, NC",
    city_name: "Roper",
  },
  {
    postal_code: "82054",
    full_city_name: "Carpenter, WY",
    city_name: "Carpenter",
  },
  {
    postal_code: "12815",
    full_city_name: "Brant Lake, NY",
    city_name: "Brant Lake",
  },
  {
    postal_code: "18947",
    full_city_name: "Pipersville, PA",
    city_name: "Pipersville",
  },
  {
    postal_code: "04429",
    full_city_name: "Holden, ME",
    city_name: "Holden",
  },
  {
    postal_code: "83628",
    full_city_name: "Homedale, ID",
    city_name: "Homedale",
  },
  {
    postal_code: "55084",
    full_city_name: "Taylors Falls, MN",
    city_name: "Taylors Falls",
  },
  {
    postal_code: "24441",
    full_city_name: "Grottoes, VA",
    city_name: "Grottoes",
  },
  {
    postal_code: "50636",
    full_city_name: "Greene, IA",
    city_name: "Greene",
  },
  {
    postal_code: "49969",
    full_city_name: "Watersmeet, MI",
    city_name: "Watersmeet",
  },
  {
    postal_code: "77012",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "01887",
    full_city_name: "Wilmington, MA",
    city_name: "Wilmington",
  },
  {
    postal_code: "01069",
    full_city_name: "Palmer, MA",
    city_name: "Palmer",
  },
  {
    postal_code: "02719",
    full_city_name: "Fairhaven, MA",
    city_name: "Fairhaven",
  },
  {
    postal_code: "65441",
    full_city_name: "Bourbon, MO",
    city_name: "Bourbon",
  },
  {
    postal_code: "27572",
    full_city_name: "Rougemont, NC",
    city_name: "Rougemont",
  },
  {
    postal_code: "19706",
    full_city_name: "Delaware City, DE",
    city_name: "Delaware City",
  },
  {
    postal_code: "15637",
    full_city_name: "Herminie, PA",
    city_name: "Herminie",
  },
  {
    postal_code: "62320",
    full_city_name: "Camp Point, IL",
    city_name: "Camp Point",
  },
  {
    postal_code: "01432",
    full_city_name: "Ayer, MA",
    city_name: "Ayer",
  },
  {
    postal_code: "17363",
    full_city_name: "Stewartstown, PA",
    city_name: "Stewartstown",
  },
  {
    postal_code: "02748",
    full_city_name: "South Dartmouth, MA",
    city_name: "South Dartmouth",
  },
  {
    postal_code: "02190",
    full_city_name: "South Weymouth, MA",
    city_name: "South Weymouth",
  },
  {
    postal_code: "01507",
    full_city_name: "Charlton, MA",
    city_name: "Charlton",
  },
  {
    postal_code: "18045",
    full_city_name: "Easton, PA",
    city_name: "Easton",
  },
  {
    postal_code: "70548",
    full_city_name: "Kaplan, LA",
    city_name: "Kaplan",
  },
  {
    postal_code: "22567",
    full_city_name: "Unionville, VA",
    city_name: "Unionville",
  },
  {
    postal_code: "14470",
    full_city_name: "Holley, NY",
    city_name: "Holley",
  },
  {
    postal_code: "14513",
    full_city_name: "Newark, NY",
    city_name: "Newark",
  },
  {
    postal_code: "22642",
    full_city_name: "Linden, VA",
    city_name: "Linden",
  },
  {
    postal_code: "15014",
    full_city_name: "Brackenridge, PA",
    city_name: "Brackenridge",
  },
  {
    postal_code: "40977",
    full_city_name: "Pineville, KY",
    city_name: "Pineville",
  },
  {
    postal_code: "68730",
    full_city_name: "Crofton, NE",
    city_name: "Crofton",
  },
  {
    postal_code: "55074",
    full_city_name: "Shafer, MN",
    city_name: "Shafer",
  },
  {
    postal_code: "77081",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "02558",
    full_city_name: "Onset, MA",
    city_name: "Onset",
  },
  {
    postal_code: "01860",
    full_city_name: "Merrimac, MA",
    city_name: "Merrimac",
  },
  {
    postal_code: "01331",
    full_city_name: "Athol, MA",
    city_name: "Athol",
  },
  {
    postal_code: "10035",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "05465",
    full_city_name: "Jericho, VT",
    city_name: "Jericho",
  },
  {
    postal_code: "76802",
    full_city_name: "Early, TX",
    city_name: "Early",
  },
  {
    postal_code: "72642",
    full_city_name: "Lakeview, AR",
    city_name: "Lakeview",
  },
  {
    postal_code: "02630",
    full_city_name: "Barnstable, MA",
    city_name: "Barnstable",
  },
  {
    postal_code: "81235",
    full_city_name: "Lake City, CO",
    city_name: "Lake City",
  },
  {
    postal_code: "81067",
    full_city_name: "Rocky Ford, CO",
    city_name: "Rocky Ford",
  },
  {
    postal_code: "19034",
    full_city_name: "Fort Washington, PA",
    city_name: "Fort Washington",
  },
  {
    postal_code: "28582",
    full_city_name: "Stella, NC",
    city_name: "Stella",
  },
  {
    postal_code: "43604",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "74365",
    full_city_name: "Salina, OK",
    city_name: "Salina",
  },
  {
    postal_code: "78631",
    full_city_name: "Harper, TX",
    city_name: "Harper",
  },
  {
    postal_code: "11023",
    full_city_name: "Great Neck, NY",
    city_name: "Great Neck",
  },
  {
    postal_code: "37189",
    full_city_name: "Whites Creek, TN",
    city_name: "Whites Creek",
  },
  {
    postal_code: "66103",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "43145",
    full_city_name: "New Holland, OH",
    city_name: "New Holland",
  },
  {
    postal_code: "01746",
    full_city_name: "Holliston, MA",
    city_name: "Holliston",
  },
  {
    postal_code: "02145",
    full_city_name: "Somerville, MA",
    city_name: "Somerville",
  },
  {
    postal_code: "22851",
    full_city_name: "Stanley, VA",
    city_name: "Stanley",
  },
  {
    postal_code: "20714",
    full_city_name: "North Beach, MD",
    city_name: "North Beach",
  },
  {
    postal_code: "82941",
    full_city_name: "Pinedale, WY",
    city_name: "Pinedale",
  },
  {
    postal_code: "06354",
    full_city_name: "Moosup, CT",
    city_name: "Moosup",
  },
  {
    postal_code: "48072",
    full_city_name: "Berkley, MI",
    city_name: "Berkley",
  },
  {
    postal_code: "97112",
    full_city_name: "Cloverdale, OR",
    city_name: "Cloverdale",
  },
  {
    postal_code: "54757",
    full_city_name: "New Auburn, WI",
    city_name: "New Auburn",
  },
  {
    postal_code: "64640",
    full_city_name: "Gallatin, MO",
    city_name: "Gallatin",
  },
  {
    postal_code: "80758",
    full_city_name: "Wray, CO",
    city_name: "Wray",
  },
  {
    postal_code: "98546",
    full_city_name: "Grapeview, WA",
    city_name: "Grapeview",
  },
  {
    postal_code: "64725",
    full_city_name: "Archie, MO",
    city_name: "Archie",
  },
  {
    postal_code: "05452",
    full_city_name: "Essex Junction, VT",
    city_name: "Essex Junction",
  },
  {
    postal_code: "79347",
    full_city_name: "Muleshoe, TX",
    city_name: "Muleshoe",
  },
  {
    postal_code: "25545",
    full_city_name: "Ona, WV",
    city_name: "Ona",
  },
  {
    postal_code: "05065",
    full_city_name: "Sharon, VT",
    city_name: "Sharon",
  },
  {
    postal_code: "05733",
    full_city_name: "Brandon, VT",
    city_name: "Brandon",
  },
  {
    postal_code: "54151",
    full_city_name: "Niagara, WI",
    city_name: "Niagara",
  },
  {
    postal_code: "56278",
    full_city_name: "Ortonville, MN",
    city_name: "Ortonville",
  },
  {
    postal_code: "66040",
    full_city_name: "La Cygne, KS",
    city_name: "La Cygne",
  },
  {
    postal_code: "14612",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "16507",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "66064",
    full_city_name: "Osawatomie, KS",
    city_name: "Osawatomie",
  },
  {
    postal_code: "03865",
    full_city_name: "Plaistow, NH",
    city_name: "Plaistow",
  },
  {
    postal_code: "21660",
    full_city_name: "Ridgely, MD",
    city_name: "Ridgely",
  },
  {
    postal_code: "02364",
    full_city_name: "Kingston, MA",
    city_name: "Kingston",
  },
  {
    postal_code: "24319",
    full_city_name: "Chilhowie, VA",
    city_name: "Chilhowie",
  },
  {
    postal_code: "89510",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "61373",
    full_city_name: "Utica, IL",
    city_name: "Utica",
  },
  {
    postal_code: "04076",
    full_city_name: "Shapleigh, ME",
    city_name: "Shapleigh",
  },
  {
    postal_code: "96114",
    full_city_name: "Janesville, CA",
    city_name: "Janesville",
  },
  {
    postal_code: "55992",
    full_city_name: "Zumbrota, MN",
    city_name: "Zumbrota",
  },
  {
    postal_code: "12974",
    full_city_name: "Port Henry, NY",
    city_name: "Port Henry",
  },
  {
    postal_code: "40360",
    full_city_name: "Owingsville, KY",
    city_name: "Owingsville",
  },
  {
    postal_code: "93301",
    full_city_name: "Bakersfield, CA",
    city_name: "Bakersfield",
  },
  {
    postal_code: "18433",
    full_city_name: "Jermyn, PA",
    city_name: "Jermyn",
  },
  {
    postal_code: "02330",
    full_city_name: "Carver, MA",
    city_name: "Carver",
  },
  {
    postal_code: "84317",
    full_city_name: "Huntsville, UT",
    city_name: "Huntsville",
  },
  {
    postal_code: "42642",
    full_city_name: "Russell Springs, KY",
    city_name: "Russell Springs",
  },
  {
    postal_code: "18102",
    full_city_name: "Allentown, PA",
    city_name: "Allentown",
  },
  {
    postal_code: "06807",
    full_city_name: "Cos Cob, CT",
    city_name: "Cos Cob",
  },
  {
    postal_code: "04468",
    full_city_name: "Old Town, ME",
    city_name: "Old Town",
  },
  {
    postal_code: "91205",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "85288",
    full_city_name: "Tempe, AZ",
    city_name: "Tempe",
  },
  {
    postal_code: "12166",
    full_city_name: "Sprakers, NY",
    city_name: "Sprakers",
  },
  {
    postal_code: "05354",
    full_city_name: "Vernon, VT",
    city_name: "Vernon",
  },
  {
    postal_code: "29010",
    full_city_name: "Bishopville, SC",
    city_name: "Bishopville",
  },
  {
    postal_code: "01540",
    full_city_name: "Oxford, MA",
    city_name: "Oxford",
  },
  {
    postal_code: "10550",
    full_city_name: "Mount Vernon, NY",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "24526",
    full_city_name: "Big Island, VA",
    city_name: "Big Island",
  },
  {
    postal_code: "31562",
    full_city_name: "Saint George, GA",
    city_name: "Saint George",
  },
  {
    postal_code: "56330",
    full_city_name: "Foreston, MN",
    city_name: "Foreston",
  },
  {
    postal_code: "03862",
    full_city_name: "North Hampton, NH",
    city_name: "North Hampton",
  },
  {
    postal_code: "80820",
    full_city_name: "Guffey, CO",
    city_name: "Guffey",
  },
  {
    postal_code: "50849",
    full_city_name: "Greenfield, IA",
    city_name: "Greenfield",
  },
  {
    postal_code: "96032",
    full_city_name: "Fort Jones, CA",
    city_name: "Fort Jones",
  },
  {
    postal_code: "49960",
    full_city_name: "Rockland, MI",
    city_name: "Rockland",
  },
  {
    postal_code: "93207",
    full_city_name: "California Hot Springs, CA",
    city_name: "California Hot Springs",
  },
  {
    postal_code: "05860",
    full_city_name: "Orleans, VT",
    city_name: "Orleans",
  },
  {
    postal_code: "48438",
    full_city_name: "Goodrich, MI",
    city_name: "Goodrich",
  },
  {
    postal_code: "48659",
    full_city_name: "Sterling, MI",
    city_name: "Sterling",
  },
  {
    postal_code: "03245",
    full_city_name: "Holderness, NH",
    city_name: "Holderness",
  },
  {
    postal_code: "01966",
    full_city_name: "Rockport, MA",
    city_name: "Rockport",
  },
  {
    postal_code: "60157",
    full_city_name: "Medinah, IL",
    city_name: "Medinah",
  },
  {
    postal_code: "63456",
    full_city_name: "Monroe City, MO",
    city_name: "Monroe City",
  },
  {
    postal_code: "35603",
    full_city_name: "Decatur, AL",
    city_name: "Decatur",
  },
  {
    postal_code: "20712",
    full_city_name: "Mount Rainier, MD",
    city_name: "Mount Rainier",
  },
  {
    postal_code: "06231",
    full_city_name: "Amston, CT",
    city_name: "Amston",
  },
  {
    postal_code: "45169",
    full_city_name: "Sabina, OH",
    city_name: "Sabina",
  },
  {
    postal_code: "93256",
    full_city_name: "Pixley, CA",
    city_name: "Pixley",
  },
  {
    postal_code: "64734",
    full_city_name: "Cleveland, MO",
    city_name: "Cleveland",
  },
  {
    postal_code: "06255",
    full_city_name: "North Grosvenordale, CT",
    city_name: "North Grosvenordale",
  },
  {
    postal_code: "06438",
    full_city_name: "Haddam, CT",
    city_name: "Haddam",
  },
  {
    postal_code: "93440",
    full_city_name: "Los Alamos, CA",
    city_name: "Los Alamos",
  },
  {
    postal_code: "62264",
    full_city_name: "New Athens, IL",
    city_name: "New Athens",
  },
  {
    postal_code: "18518",
    full_city_name: "Old Forge, PA",
    city_name: "Old Forge",
  },
  {
    postal_code: "38838",
    full_city_name: "Dennis, MS",
    city_name: "Dennis",
  },
  {
    postal_code: "77060",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "34681",
    full_city_name: "Crystal Beach, FL",
    city_name: "Crystal Beach",
  },
  {
    postal_code: "08201",
    full_city_name: "Absecon, NJ",
    city_name: "Absecon",
  },
  {
    postal_code: "39573",
    full_city_name: "Perkinston, MS",
    city_name: "Perkinston",
  },
  {
    postal_code: "93664",
    full_city_name: "Shaver Lake, CA",
    city_name: "Shaver Lake",
  },
  {
    postal_code: "06331",
    full_city_name: "Canterbury, CT",
    city_name: "Canterbury",
  },
  {
    postal_code: "17754",
    full_city_name: "Montoursville, PA",
    city_name: "Montoursville",
  },
  {
    postal_code: "78945",
    full_city_name: "La Grange, TX",
    city_name: "La Grange",
  },
  {
    postal_code: "31816",
    full_city_name: "Manchester, GA",
    city_name: "Manchester",
  },
  {
    postal_code: "81130",
    full_city_name: "Creede, CO",
    city_name: "Creede",
  },
  {
    postal_code: "05846",
    full_city_name: "Island Pond, VT",
    city_name: "Island Pond",
  },
  {
    postal_code: "01830",
    full_city_name: "Haverhill, MA",
    city_name: "Haverhill",
  },
  {
    postal_code: "63382",
    full_city_name: "Vandalia, MO",
    city_name: "Vandalia",
  },
  {
    postal_code: "03307",
    full_city_name: "Loudon, NH",
    city_name: "Loudon",
  },
  {
    postal_code: "53583",
    full_city_name: "Sauk City, WI",
    city_name: "Sauk City",
  },
  {
    postal_code: "53502",
    full_city_name: "Albany, WI",
    city_name: "Albany",
  },
  {
    postal_code: "95004",
    full_city_name: "Aromas, CA",
    city_name: "Aromas",
  },
  {
    postal_code: "64840",
    full_city_name: "Diamond, MO",
    city_name: "Diamond",
  },
  {
    postal_code: "53091",
    full_city_name: "Theresa, WI",
    city_name: "Theresa",
  },
  {
    postal_code: "99360",
    full_city_name: "Touchet, WA",
    city_name: "Touchet",
  },
  {
    postal_code: "48726",
    full_city_name: "Cass City, MI",
    city_name: "Cass City",
  },
  {
    postal_code: "01772",
    full_city_name: "Southborough, MA",
    city_name: "Southborough",
  },
  {
    postal_code: "16433",
    full_city_name: "Saegertown, PA",
    city_name: "Saegertown",
  },
  {
    postal_code: "02186",
    full_city_name: "Milton, MA",
    city_name: "Milton",
  },
  {
    postal_code: "54810",
    full_city_name: "Balsam Lake, WI",
    city_name: "Balsam Lake",
  },
  {
    postal_code: "08740",
    full_city_name: "Ocean Gate, NJ",
    city_name: "Ocean Gate",
  },
  {
    postal_code: "56334",
    full_city_name: "Glenwood, MN",
    city_name: "Glenwood",
  },
  {
    postal_code: "49436",
    full_city_name: "Mears, MI",
    city_name: "Mears",
  },
  {
    postal_code: "45232",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "01520",
    full_city_name: "Holden, MA",
    city_name: "Holden",
  },
  {
    postal_code: "37779",
    full_city_name: "Luttrell, TN",
    city_name: "Luttrell",
  },
  {
    postal_code: "02458",
    full_city_name: "Newton, MA",
    city_name: "Newton",
  },
  {
    postal_code: "45872",
    full_city_name: "North Baltimore, OH",
    city_name: "North Baltimore",
  },
  {
    postal_code: "53518",
    full_city_name: "Blue River, WI",
    city_name: "Blue River",
  },
  {
    postal_code: "47840",
    full_city_name: "Centerpoint, IN",
    city_name: "Centerpoint",
  },
  {
    postal_code: "25159",
    full_city_name: "Poca, WV",
    city_name: "Poca",
  },
  {
    postal_code: "48622",
    full_city_name: "Farwell, MI",
    city_name: "Farwell",
  },
  {
    postal_code: "81527",
    full_city_name: "Whitewater, CO",
    city_name: "Whitewater",
  },
  {
    postal_code: "24277",
    full_city_name: "Pennington Gap, VA",
    city_name: "Pennington Gap",
  },
  {
    postal_code: "23605",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "01778",
    full_city_name: "Wayland, MA",
    city_name: "Wayland",
  },
  {
    postal_code: "56368",
    full_city_name: "Richmond, MN",
    city_name: "Richmond",
  },
  {
    postal_code: "28682",
    full_city_name: "Terrell, NC",
    city_name: "Terrell",
  },
  {
    postal_code: "79411",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "61849",
    full_city_name: "Homer, IL",
    city_name: "Homer",
  },
  {
    postal_code: "10509",
    full_city_name: "Brewster, NY",
    city_name: "Brewster",
  },
  {
    postal_code: "04947",
    full_city_name: "Kingfield, ME",
    city_name: "Kingfield",
  },
  {
    postal_code: "70754",
    full_city_name: "Livingston, LA",
    city_name: "Livingston",
  },
  {
    postal_code: "26601",
    full_city_name: "Sutton, WV",
    city_name: "Sutton",
  },
  {
    postal_code: "19032",
    full_city_name: "Folcroft, PA",
    city_name: "Folcroft",
  },
  {
    postal_code: "72067",
    full_city_name: "Higden, AR",
    city_name: "Higden",
  },
  {
    postal_code: "10701",
    full_city_name: "Yonkers, NY",
    city_name: "Yonkers",
  },
  {
    postal_code: "21661",
    full_city_name: "Rock Hall, MD",
    city_name: "Rock Hall",
  },
  {
    postal_code: "10950",
    full_city_name: "Monroe, NY",
    city_name: "Monroe",
  },
  {
    postal_code: "08734",
    full_city_name: "Lanoka Harbor, NJ",
    city_name: "Lanoka Harbor",
  },
  {
    postal_code: "12586",
    full_city_name: "Walden, NY",
    city_name: "Walden",
  },
  {
    postal_code: "02460",
    full_city_name: "Newtonville, MA",
    city_name: "Newtonville",
  },
  {
    postal_code: "21658",
    full_city_name: "Queenstown, MD",
    city_name: "Queenstown",
  },
  {
    postal_code: "29624",
    full_city_name: "Anderson, SC",
    city_name: "Anderson",
  },
  {
    postal_code: "36201",
    full_city_name: "Anniston, AL",
    city_name: "Anniston",
  },
  {
    postal_code: "60603",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "29661",
    full_city_name: "Marietta, SC",
    city_name: "Marietta",
  },
  {
    postal_code: "37387",
    full_city_name: "Tracy City, TN",
    city_name: "Tracy City",
  },
  {
    postal_code: "54889",
    full_city_name: "Turtle Lake, WI",
    city_name: "Turtle Lake",
  },
  {
    postal_code: "06247",
    full_city_name: "Hampton, CT",
    city_name: "Hampton",
  },
  {
    postal_code: "02726",
    full_city_name: "Somerset, MA",
    city_name: "Somerset",
  },
  {
    postal_code: "30147",
    full_city_name: "Lindale, GA",
    city_name: "Lindale",
  },
  {
    postal_code: "66610",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "78652",
    full_city_name: "Manchaca, TX",
    city_name: "Manchaca",
  },
  {
    postal_code: "21084",
    full_city_name: "Jarrettsville, MD",
    city_name: "Jarrettsville",
  },
  {
    postal_code: "08223",
    full_city_name: "Marmora, NJ",
    city_name: "Marmora",
  },
  {
    postal_code: "08757",
    full_city_name: "Toms River, NJ",
    city_name: "Toms River",
  },
  {
    postal_code: "81423",
    full_city_name: "Norwood, CO",
    city_name: "Norwood",
  },
  {
    postal_code: "04101",
    full_city_name: "Portland, ME",
    city_name: "Portland",
  },
  {
    postal_code: "28701",
    full_city_name: "Alexander, NC",
    city_name: "Alexander",
  },
  {
    postal_code: "17948",
    full_city_name: "Mahanoy City, PA",
    city_name: "Mahanoy City",
  },
  {
    postal_code: "49449",
    full_city_name: "Pentwater, MI",
    city_name: "Pentwater",
  },
  {
    postal_code: "42567",
    full_city_name: "Eubank, KY",
    city_name: "Eubank",
  },
  {
    postal_code: "75432",
    full_city_name: "Cooper, TX",
    city_name: "Cooper",
  },
  {
    postal_code: "18960",
    full_city_name: "Sellersville, PA",
    city_name: "Sellersville",
  },
  {
    postal_code: "66604",
    full_city_name: "Topeka, KS",
    city_name: "Topeka",
  },
  {
    postal_code: "45334",
    full_city_name: "Jackson Center, OH",
    city_name: "Jackson Center",
  },
  {
    postal_code: "55012",
    full_city_name: "Center City, MN",
    city_name: "Center City",
  },
  {
    postal_code: "61754",
    full_city_name: "Mc Lean, IL",
    city_name: "Mc Lean",
  },
  {
    postal_code: "16314",
    full_city_name: "Cochranton, PA",
    city_name: "Cochranton",
  },
  {
    postal_code: "50228",
    full_city_name: "Prairie City, IA",
    city_name: "Prairie City",
  },
  {
    postal_code: "70090",
    full_city_name: "Vacherie, LA",
    city_name: "Vacherie",
  },
  {
    postal_code: "18419",
    full_city_name: "Factoryville, PA",
    city_name: "Factoryville",
  },
  {
    postal_code: "12853",
    full_city_name: "North Creek, NY",
    city_name: "North Creek",
  },
  {
    postal_code: "08068",
    full_city_name: "Pemberton, NJ",
    city_name: "Pemberton",
  },
  {
    postal_code: "02056",
    full_city_name: "Norfolk, MA",
    city_name: "Norfolk",
  },
  {
    postal_code: "21087",
    full_city_name: "Kingsville, MD",
    city_name: "Kingsville",
  },
  {
    postal_code: "92561",
    full_city_name: "Mountain Center, CA",
    city_name: "Mountain Center",
  },
  {
    postal_code: "13340",
    full_city_name: "Frankfort, NY",
    city_name: "Frankfort",
  },
  {
    postal_code: "19137",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "33827",
    full_city_name: "Babson Park, FL",
    city_name: "Babson Park",
  },
  {
    postal_code: "15860",
    full_city_name: "Sigel, PA",
    city_name: "Sigel",
  },
  {
    postal_code: "11419",
    full_city_name: "South Richmond Hill, NY",
    city_name: "South Richmond Hill",
  },
  {
    postal_code: "11941",
    full_city_name: "Eastport, NY",
    city_name: "Eastport",
  },
  {
    postal_code: "18031",
    full_city_name: "Breinigsville, PA",
    city_name: "Breinigsville",
  },
  {
    postal_code: "18707",
    full_city_name: "Mountain Top, PA",
    city_name: "Mountain Top",
  },
  {
    postal_code: "37302",
    full_city_name: "Apison, TN",
    city_name: "Apison",
  },
  {
    postal_code: "41139",
    full_city_name: "Flatwoods, KY",
    city_name: "Flatwoods",
  },
  {
    postal_code: "48450",
    full_city_name: "Lexington, MI",
    city_name: "Lexington",
  },
  {
    postal_code: "03229",
    full_city_name: "Contoocook, NH",
    city_name: "Contoocook",
  },
  {
    postal_code: "20754",
    full_city_name: "Dunkirk, MD",
    city_name: "Dunkirk",
  },
  {
    postal_code: "56172",
    full_city_name: "Slayton, MN",
    city_name: "Slayton",
  },
  {
    postal_code: "06412",
    full_city_name: "Chester, CT",
    city_name: "Chester",
  },
  {
    postal_code: "43025",
    full_city_name: "Hebron, OH",
    city_name: "Hebron",
  },
  {
    postal_code: "98850",
    full_city_name: "Rock Island, WA",
    city_name: "Rock Island",
  },
  {
    postal_code: "50025",
    full_city_name: "Audubon, IA",
    city_name: "Audubon",
  },
  {
    postal_code: "53944",
    full_city_name: "Lyndon Station, WI",
    city_name: "Lyndon Station",
  },
  {
    postal_code: "54830",
    full_city_name: "Danbury, WI",
    city_name: "Danbury",
  },
  {
    postal_code: "13363",
    full_city_name: "Lee Center, NY",
    city_name: "Lee Center",
  },
  {
    postal_code: "49765",
    full_city_name: "Onaway, MI",
    city_name: "Onaway",
  },
  {
    postal_code: "62363",
    full_city_name: "Pittsfield, IL",
    city_name: "Pittsfield",
  },
  {
    postal_code: "18415",
    full_city_name: "Damascus, PA",
    city_name: "Damascus",
  },
  {
    postal_code: "19611",
    full_city_name: "Reading, PA",
    city_name: "Reading",
  },
  {
    postal_code: "13673",
    full_city_name: "Philadelphia, NY",
    city_name: "Philadelphia",
  },
  {
    postal_code: "05677",
    full_city_name: "Waterbury Center, VT",
    city_name: "Waterbury Center",
  },
  {
    postal_code: "88023",
    full_city_name: "Bayard, NM",
    city_name: "Bayard",
  },
  {
    postal_code: "45064",
    full_city_name: "Somerville, OH",
    city_name: "Somerville",
  },
  {
    postal_code: "45613",
    full_city_name: "Beaver, OH",
    city_name: "Beaver",
  },
  {
    postal_code: "48023",
    full_city_name: "Fair Haven, MI",
    city_name: "Fair Haven",
  },
  {
    postal_code: "83837",
    full_city_name: "Kellogg, ID",
    city_name: "Kellogg",
  },
  {
    postal_code: "37687",
    full_city_name: "Roan Mountain, TN",
    city_name: "Roan Mountain",
  },
  {
    postal_code: "59063",
    full_city_name: "Park City, MT",
    city_name: "Park City",
  },
  {
    postal_code: "53556",
    full_city_name: "Lone Rock, WI",
    city_name: "Lone Rock",
  },
  {
    postal_code: "55951",
    full_city_name: "Le Roy, MN",
    city_name: "Le Roy",
  },
  {
    postal_code: "63461",
    full_city_name: "Palmyra, MO",
    city_name: "Palmyra",
  },
  {
    postal_code: "18517",
    full_city_name: "Taylor, PA",
    city_name: "Taylor",
  },
  {
    postal_code: "44254",
    full_city_name: "Lodi, OH",
    city_name: "Lodi",
  },
  {
    postal_code: "31765",
    full_city_name: "Meigs, GA",
    city_name: "Meigs",
  },
  {
    postal_code: "65552",
    full_city_name: "Plato, MO",
    city_name: "Plato",
  },
  {
    postal_code: "38483",
    full_city_name: "Summertown, TN",
    city_name: "Summertown",
  },
  {
    postal_code: "10458",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "72370",
    full_city_name: "Osceola, AR",
    city_name: "Osceola",
  },
  {
    postal_code: "01473",
    full_city_name: "Westminster, MA",
    city_name: "Westminster",
  },
  {
    postal_code: "75446",
    full_city_name: "Honey Grove, TX",
    city_name: "Honey Grove",
  },
  {
    postal_code: "92105",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "62450",
    full_city_name: "Olney, IL",
    city_name: "Olney",
  },
  {
    postal_code: "38847",
    full_city_name: "Golden, MS",
    city_name: "Golden",
  },
  {
    postal_code: "03872",
    full_city_name: "Sanbornville, NH",
    city_name: "Sanbornville",
  },
  {
    postal_code: "37375",
    full_city_name: "Sewanee, TN",
    city_name: "Sewanee",
  },
  {
    postal_code: "98568",
    full_city_name: "Oakville, WA",
    city_name: "Oakville",
  },
  {
    postal_code: "11935",
    full_city_name: "Cutchogue, NY",
    city_name: "Cutchogue",
  },
  {
    postal_code: "21028",
    full_city_name: "Churchville, MD",
    city_name: "Churchville",
  },
  {
    postal_code: "01949",
    full_city_name: "Middleton, MA",
    city_name: "Middleton",
  },
  {
    postal_code: "47043",
    full_city_name: "Vevay, IN",
    city_name: "Vevay",
  },
  {
    postal_code: "02025",
    full_city_name: "Cohasset, MA",
    city_name: "Cohasset",
  },
  {
    postal_code: "15636",
    full_city_name: "Harrison City, PA",
    city_name: "Harrison City",
  },
  {
    postal_code: "74964",
    full_city_name: "Watts, OK",
    city_name: "Watts",
  },
  {
    postal_code: "40006",
    full_city_name: "Bedford, KY",
    city_name: "Bedford",
  },
  {
    postal_code: "30543",
    full_city_name: "Gillsville, GA",
    city_name: "Gillsville",
  },
  {
    postal_code: "01266",
    full_city_name: "West Stockbridge, MA",
    city_name: "West Stockbridge",
  },
  {
    postal_code: "28610",
    full_city_name: "Claremont, NC",
    city_name: "Claremont",
  },
  {
    postal_code: "01463",
    full_city_name: "Pepperell, MA",
    city_name: "Pepperell",
  },
  {
    postal_code: "01702",
    full_city_name: "Framingham, MA",
    city_name: "Framingham",
  },
  {
    postal_code: "15552",
    full_city_name: "Meyersdale, PA",
    city_name: "Meyersdale",
  },
  {
    postal_code: "21648",
    full_city_name: "Madison, MD",
    city_name: "Madison",
  },
  {
    postal_code: "20658",
    full_city_name: "Marbury, MD",
    city_name: "Marbury",
  },
  {
    postal_code: "29828",
    full_city_name: "Gloverville, SC",
    city_name: "Gloverville",
  },
  {
    postal_code: "53560",
    full_city_name: "Mazomanie, WI",
    city_name: "Mazomanie",
  },
  {
    postal_code: "13812",
    full_city_name: "Nichols, NY",
    city_name: "Nichols",
  },
  {
    postal_code: "43128",
    full_city_name: "Jeffersonville, OH",
    city_name: "Jeffersonville",
  },
  {
    postal_code: "98925",
    full_city_name: "Easton, WA",
    city_name: "Easton",
  },
  {
    postal_code: "68446",
    full_city_name: "Syracuse, NE",
    city_name: "Syracuse",
  },
  {
    postal_code: "05866",
    full_city_name: "Sheffield, VT",
    city_name: "Sheffield",
  },
  {
    postal_code: "37616",
    full_city_name: "Afton, TN",
    city_name: "Afton",
  },
  {
    postal_code: "56465",
    full_city_name: "Merrifield, MN",
    city_name: "Merrifield",
  },
  {
    postal_code: "37332",
    full_city_name: "Evensville, TN",
    city_name: "Evensville",
  },
  {
    postal_code: "45341",
    full_city_name: "Medway, OH",
    city_name: "Medway",
  },
  {
    postal_code: "89429",
    full_city_name: "Silver Springs, NV",
    city_name: "Silver Springs",
  },
  {
    postal_code: "49051",
    full_city_name: "East Leroy, MI",
    city_name: "East Leroy",
  },
  {
    postal_code: "24482",
    full_city_name: "Verona, VA",
    city_name: "Verona",
  },
  {
    postal_code: "28009",
    full_city_name: "Badin, NC",
    city_name: "Badin",
  },
  {
    postal_code: "55779",
    full_city_name: "Saginaw, MN",
    city_name: "Saginaw",
  },
  {
    postal_code: "02476",
    full_city_name: "Arlington, MA",
    city_name: "Arlington",
  },
  {
    postal_code: "68073",
    full_city_name: "Yutan, NE",
    city_name: "Yutan",
  },
  {
    postal_code: "67431",
    full_city_name: "Chapman, KS",
    city_name: "Chapman",
  },
  {
    postal_code: "53726",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "50251",
    full_city_name: "Sully, IA",
    city_name: "Sully",
  },
  {
    postal_code: "75638",
    full_city_name: "Daingerfield, TX",
    city_name: "Daingerfield",
  },
  {
    postal_code: "48219",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "15825",
    full_city_name: "Brookville, PA",
    city_name: "Brookville",
  },
  {
    postal_code: "10468",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "21632",
    full_city_name: "Federalsburg, MD",
    city_name: "Federalsburg",
  },
  {
    postal_code: "04051",
    full_city_name: "Lovell, ME",
    city_name: "Lovell",
  },
  {
    postal_code: "81328",
    full_city_name: "Mancos, CO",
    city_name: "Mancos",
  },
  {
    postal_code: "50601",
    full_city_name: "Ackley, IA",
    city_name: "Ackley",
  },
  {
    postal_code: "77665",
    full_city_name: "Winnie, TX",
    city_name: "Winnie",
  },
  {
    postal_code: "13801",
    full_city_name: "Mc Donough, NY",
    city_name: "Mc Donough",
  },
  {
    postal_code: "60551",
    full_city_name: "Sheridan, IL",
    city_name: "Sheridan",
  },
  {
    postal_code: "75980",
    full_city_name: "Zavalla, TX",
    city_name: "Zavalla",
  },
  {
    postal_code: "02067",
    full_city_name: "Sharon, MA",
    city_name: "Sharon",
  },
  {
    postal_code: "81001",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "02839",
    full_city_name: "Mapleville, RI",
    city_name: "Mapleville",
  },
  {
    postal_code: "74120",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "54557",
    full_city_name: "Presque Isle, WI",
    city_name: "Presque Isle",
  },
  {
    postal_code: "22735",
    full_city_name: "Reva, VA",
    city_name: "Reva",
  },
  {
    postal_code: "62822",
    full_city_name: "Christopher, IL",
    city_name: "Christopher",
  },
  {
    postal_code: "38824",
    full_city_name: "Baldwyn, MS",
    city_name: "Baldwyn",
  },
  {
    postal_code: "84642",
    full_city_name: "Manti, UT",
    city_name: "Manti",
  },
  {
    postal_code: "12147",
    full_city_name: "Rensselaerville, NY",
    city_name: "Rensselaerville",
  },
  {
    postal_code: "76458",
    full_city_name: "Jacksboro, TX",
    city_name: "Jacksboro",
  },
  {
    postal_code: "79935",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "54540",
    full_city_name: "Land O Lakes, WI",
    city_name: "Land O Lakes",
  },
  {
    postal_code: "26452",
    full_city_name: "Weston, WV",
    city_name: "Weston",
  },
  {
    postal_code: "84021",
    full_city_name: "Duchesne, UT",
    city_name: "Duchesne",
  },
  {
    postal_code: "78205",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "74730",
    full_city_name: "Calera, OK",
    city_name: "Calera",
  },
  {
    postal_code: "26757",
    full_city_name: "Romney, WV",
    city_name: "Romney",
  },
  {
    postal_code: "33834",
    full_city_name: "Bowling Green, FL",
    city_name: "Bowling Green",
  },
  {
    postal_code: "29520",
    full_city_name: "Cheraw, SC",
    city_name: "Cheraw",
  },
  {
    postal_code: "01906",
    full_city_name: "Saugus, MA",
    city_name: "Saugus",
  },
  {
    postal_code: "39483",
    full_city_name: "Foxworth, MS",
    city_name: "Foxworth",
  },
  {
    postal_code: "66743",
    full_city_name: "Girard, KS",
    city_name: "Girard",
  },
  {
    postal_code: "16159",
    full_city_name: "West Middlesex, PA",
    city_name: "West Middlesex",
  },
  {
    postal_code: "12514",
    full_city_name: "Clinton Corners, NY",
    city_name: "Clinton Corners",
  },
  {
    postal_code: "37370",
    full_city_name: "Riceville, TN",
    city_name: "Riceville",
  },
  {
    postal_code: "12546",
    full_city_name: "Millerton, NY",
    city_name: "Millerton",
  },
  {
    postal_code: "25530",
    full_city_name: "Kenova, WV",
    city_name: "Kenova",
  },
  {
    postal_code: "18972",
    full_city_name: "Upper Black Eddy, PA",
    city_name: "Upper Black Eddy",
  },
  {
    postal_code: "92333",
    full_city_name: "Fawnskin, CA",
    city_name: "Fawnskin",
  },
  {
    postal_code: "53105",
    full_city_name: "Burlington, WI",
    city_name: "Burlington",
  },
  {
    postal_code: "78102",
    full_city_name: "Beeville, TX",
    city_name: "Beeville",
  },
  {
    postal_code: "48348",
    full_city_name: "Clarkston, MI",
    city_name: "Clarkston",
  },
  {
    postal_code: "54819",
    full_city_name: "Bruce, WI",
    city_name: "Bruce",
  },
  {
    postal_code: "61542",
    full_city_name: "Lewistown, IL",
    city_name: "Lewistown",
  },
  {
    postal_code: "22724",
    full_city_name: "Jeffersonton, VA",
    city_name: "Jeffersonton",
  },
  {
    postal_code: "03574",
    full_city_name: "Bethlehem, NH",
    city_name: "Bethlehem",
  },
  {
    postal_code: "27806",
    full_city_name: "Aurora, NC",
    city_name: "Aurora",
  },
  {
    postal_code: "76271",
    full_city_name: "Tioga, TX",
    city_name: "Tioga",
  },
  {
    postal_code: "56097",
    full_city_name: "Wells, MN",
    city_name: "Wells",
  },
  {
    postal_code: "32668",
    full_city_name: "Morriston, FL",
    city_name: "Morriston",
  },
  {
    postal_code: "04863",
    full_city_name: "Vinalhaven, ME",
    city_name: "Vinalhaven",
  },
  {
    postal_code: "77625",
    full_city_name: "Kountze, TX",
    city_name: "Kountze",
  },
  {
    postal_code: "11356",
    full_city_name: "College Point, NY",
    city_name: "College Point",
  },
  {
    postal_code: "45214",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "72519",
    full_city_name: "Calico Rock, AR",
    city_name: "Calico Rock",
  },
  {
    postal_code: "32195",
    full_city_name: "Weirsdale, FL",
    city_name: "Weirsdale",
  },
  {
    postal_code: "62243",
    full_city_name: "Freeburg, IL",
    city_name: "Freeburg",
  },
  {
    postal_code: "54960",
    full_city_name: "Neshkoro, WI",
    city_name: "Neshkoro",
  },
  {
    postal_code: "10465",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "54923",
    full_city_name: "Berlin, WI",
    city_name: "Berlin",
  },
  {
    postal_code: "33430",
    full_city_name: "Belle Glade, FL",
    city_name: "Belle Glade",
  },
  {
    postal_code: "53158",
    full_city_name: "Pleasant Prairie, WI",
    city_name: "Pleasant Prairie",
  },
  {
    postal_code: "12430",
    full_city_name: "Fleischmanns, NY",
    city_name: "Fleischmanns",
  },
  {
    postal_code: "12932",
    full_city_name: "Elizabethtown, NY",
    city_name: "Elizabethtown",
  },
  {
    postal_code: "37061",
    full_city_name: "Erin, TN",
    city_name: "Erin",
  },
  {
    postal_code: "28167",
    full_city_name: "Union Mills, NC",
    city_name: "Union Mills",
  },
  {
    postal_code: "76035",
    full_city_name: "Cresson, TX",
    city_name: "Cresson",
  },
  {
    postal_code: "13132",
    full_city_name: "Pennellville, NY",
    city_name: "Pennellville",
  },
  {
    postal_code: "23030",
    full_city_name: "Charles City, VA",
    city_name: "Charles City",
  },
  {
    postal_code: "79065",
    full_city_name: "Pampa, TX",
    city_name: "Pampa",
  },
  {
    postal_code: "64429",
    full_city_name: "Cameron, MO",
    city_name: "Cameron",
  },
  {
    postal_code: "53121",
    full_city_name: "Elkhorn, WI",
    city_name: "Elkhorn",
  },
  {
    postal_code: "46391",
    full_city_name: "Westville, IN",
    city_name: "Westville",
  },
  {
    postal_code: "50061",
    full_city_name: "Cumming, IA",
    city_name: "Cumming",
  },
  {
    postal_code: "44907",
    full_city_name: "Mansfield, OH",
    city_name: "Mansfield",
  },
  {
    postal_code: "03036",
    full_city_name: "Chester, NH",
    city_name: "Chester",
  },
  {
    postal_code: "18954",
    full_city_name: "Richboro, PA",
    city_name: "Richboro",
  },
  {
    postal_code: "18017",
    full_city_name: "Bethlehem, PA",
    city_name: "Bethlehem",
  },
  {
    postal_code: "48164",
    full_city_name: "New Boston, MI",
    city_name: "New Boston",
  },
  {
    postal_code: "50533",
    full_city_name: "Eagle Grove, IA",
    city_name: "Eagle Grove",
  },
  {
    postal_code: "81132",
    full_city_name: "Del Norte, CO",
    city_name: "Del Norte",
  },
  {
    postal_code: "45891",
    full_city_name: "Van Wert, OH",
    city_name: "Van Wert",
  },
  {
    postal_code: "92676",
    full_city_name: "Silverado, CA",
    city_name: "Silverado",
  },
  {
    postal_code: "76055",
    full_city_name: "Itasca, TX",
    city_name: "Itasca",
  },
  {
    postal_code: "23039",
    full_city_name: "Crozier, VA",
    city_name: "Crozier",
  },
  {
    postal_code: "87520",
    full_city_name: "Chama, NM",
    city_name: "Chama",
  },
  {
    postal_code: "77859",
    full_city_name: "Hearne, TX",
    city_name: "Hearne",
  },
  {
    postal_code: "17569",
    full_city_name: "Reinholds, PA",
    city_name: "Reinholds",
  },
  {
    postal_code: "55005",
    full_city_name: "Bethel, MN",
    city_name: "Bethel",
  },
  {
    postal_code: "65047",
    full_city_name: "Kaiser, MO",
    city_name: "Kaiser",
  },
  {
    postal_code: "97624",
    full_city_name: "Chiloquin, OR",
    city_name: "Chiloquin",
  },
  {
    postal_code: "13214",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "48133",
    full_city_name: "Erie, MI",
    city_name: "Erie",
  },
  {
    postal_code: "27929",
    full_city_name: "Currituck, NC",
    city_name: "Currituck",
  },
  {
    postal_code: "06112",
    full_city_name: "Hartford, CT",
    city_name: "Hartford",
  },
  {
    postal_code: "46366",
    full_city_name: "North Judson, IN",
    city_name: "North Judson",
  },
  {
    postal_code: "14750",
    full_city_name: "Lakewood, NY",
    city_name: "Lakewood",
  },
  {
    postal_code: "05343",
    full_city_name: "Jamaica, VT",
    city_name: "Jamaica",
  },
  {
    postal_code: "01516",
    full_city_name: "Douglas, MA",
    city_name: "Douglas",
  },
  {
    postal_code: "70512",
    full_city_name: "Arnaudville, LA",
    city_name: "Arnaudville",
  },
  {
    postal_code: "38680",
    full_city_name: "Walls, MS",
    city_name: "Walls",
  },
  {
    postal_code: "84526",
    full_city_name: "Helper, UT",
    city_name: "Helper",
  },
  {
    postal_code: "18235",
    full_city_name: "Lehighton, PA",
    city_name: "Lehighton",
  },
  {
    postal_code: "07208",
    full_city_name: "Elizabeth, NJ",
    city_name: "Elizabeth",
  },
  {
    postal_code: "30427",
    full_city_name: "Glennville, GA",
    city_name: "Glennville",
  },
  {
    postal_code: "23666",
    full_city_name: "Hampton, VA",
    city_name: "Hampton",
  },
  {
    postal_code: "04572",
    full_city_name: "Waldoboro, ME",
    city_name: "Waldoboro",
  },
  {
    postal_code: "24370",
    full_city_name: "Saltville, VA",
    city_name: "Saltville",
  },
  {
    postal_code: "54821",
    full_city_name: "Cable, WI",
    city_name: "Cable",
  },
  {
    postal_code: "20833",
    full_city_name: "Brookeville, MD",
    city_name: "Brookeville",
  },
  {
    postal_code: "26263",
    full_city_name: "Dryfork, WV",
    city_name: "Dryfork",
  },
  {
    postal_code: "43412",
    full_city_name: "Curtice, OH",
    city_name: "Curtice",
  },
  {
    postal_code: "59920",
    full_city_name: "Kila, MT",
    city_name: "Kila",
  },
  {
    postal_code: "48360",
    full_city_name: "Lake Orion, MI",
    city_name: "Lake Orion",
  },
  {
    postal_code: "78623",
    full_city_name: "Fischer, TX",
    city_name: "Fischer",
  },
  {
    postal_code: "33128",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "01007",
    full_city_name: "Belchertown, MA",
    city_name: "Belchertown",
  },
  {
    postal_code: "29702",
    full_city_name: "Blacksburg, SC",
    city_name: "Blacksburg",
  },
  {
    postal_code: "55771",
    full_city_name: "Orr, MN",
    city_name: "Orr",
  },
  {
    postal_code: "56515",
    full_city_name: "Battle Lake, MN",
    city_name: "Battle Lake",
  },
  {
    postal_code: "21903",
    full_city_name: "Perryville, MD",
    city_name: "Perryville",
  },
  {
    postal_code: "91914",
    full_city_name: "Chula Vista, CA",
    city_name: "Chula Vista",
  },
  {
    postal_code: "26241",
    full_city_name: "Elkins, WV",
    city_name: "Elkins",
  },
  {
    postal_code: "81425",
    full_city_name: "Olathe, CO",
    city_name: "Olathe",
  },
  {
    postal_code: "03819",
    full_city_name: "Danville, NH",
    city_name: "Danville",
  },
  {
    postal_code: "11765",
    full_city_name: "Mill Neck, NY",
    city_name: "Mill Neck",
  },
  {
    postal_code: "98815",
    full_city_name: "Cashmere, WA",
    city_name: "Cashmere",
  },
  {
    postal_code: "92365",
    full_city_name: "Newberry Springs, CA",
    city_name: "Newberry Springs",
  },
  {
    postal_code: "54941",
    full_city_name: "Green Lake, WI",
    city_name: "Green Lake",
  },
  {
    postal_code: "39194",
    full_city_name: "Yazoo City, MS",
    city_name: "Yazoo City",
  },
  {
    postal_code: "19057",
    full_city_name: "Levittown, PA",
    city_name: "Levittown",
  },
  {
    postal_code: "44609",
    full_city_name: "Beloit, OH",
    city_name: "Beloit",
  },
  {
    postal_code: "56088",
    full_city_name: "Truman, MN",
    city_name: "Truman",
  },
  {
    postal_code: "36726",
    full_city_name: "Camden, AL",
    city_name: "Camden",
  },
  {
    postal_code: "62420",
    full_city_name: "Casey, IL",
    city_name: "Casey",
  },
  {
    postal_code: "11934",
    full_city_name: "Center Moriches, NY",
    city_name: "Center Moriches",
  },
  {
    postal_code: "23841",
    full_city_name: "Dinwiddie, VA",
    city_name: "Dinwiddie",
  },
  {
    postal_code: "13460",
    full_city_name: "Sherburne, NY",
    city_name: "Sherburne",
  },
  {
    postal_code: "18812",
    full_city_name: "Brackney, PA",
    city_name: "Brackney",
  },
  {
    postal_code: "61053",
    full_city_name: "Mount Carroll, IL",
    city_name: "Mount Carroll",
  },
  {
    postal_code: "13411",
    full_city_name: "New Berlin, NY",
    city_name: "New Berlin",
  },
  {
    postal_code: "70744",
    full_city_name: "Holden, LA",
    city_name: "Holden",
  },
  {
    postal_code: "39474",
    full_city_name: "Prentiss, MS",
    city_name: "Prentiss",
  },
  {
    postal_code: "59327",
    full_city_name: "Forsyth, MT",
    city_name: "Forsyth",
  },
  {
    postal_code: "27521",
    full_city_name: "Coats, NC",
    city_name: "Coats",
  },
  {
    postal_code: "62253",
    full_city_name: "Keyesport, IL",
    city_name: "Keyesport",
  },
  {
    postal_code: "01013",
    full_city_name: "Chicopee, MA",
    city_name: "Chicopee",
  },
  {
    postal_code: "28693",
    full_city_name: "Warrensville, NC",
    city_name: "Warrensville",
  },
  {
    postal_code: "40347",
    full_city_name: "Midway, KY",
    city_name: "Midway",
  },
  {
    postal_code: "61376",
    full_city_name: "Walnut, IL",
    city_name: "Walnut",
  },
  {
    postal_code: "50250",
    full_city_name: "Stuart, IA",
    city_name: "Stuart",
  },
  {
    postal_code: "13776",
    full_city_name: "Gilbertsville, NY",
    city_name: "Gilbertsville",
  },
  {
    postal_code: "55748",
    full_city_name: "Hill City, MN",
    city_name: "Hill City",
  },
  {
    postal_code: "99169",
    full_city_name: "Ritzville, WA",
    city_name: "Ritzville",
  },
  {
    postal_code: "03903",
    full_city_name: "Eliot, ME",
    city_name: "Eliot",
  },
  {
    postal_code: "55721",
    full_city_name: "Cohasset, MN",
    city_name: "Cohasset",
  },
  {
    postal_code: "78026",
    full_city_name: "Jourdanton, TX",
    city_name: "Jourdanton",
  },
  {
    postal_code: "44445",
    full_city_name: "New Waterford, OH",
    city_name: "New Waterford",
  },
  {
    postal_code: "73104",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "50060",
    full_city_name: "Corydon, IA",
    city_name: "Corydon",
  },
  {
    postal_code: "99709",
    full_city_name: "Fairbanks, AK",
    city_name: "Fairbanks",
  },
  {
    postal_code: "04573",
    full_city_name: "Walpole, ME",
    city_name: "Walpole",
  },
  {
    postal_code: "58785",
    full_city_name: "Surrey, ND",
    city_name: "Surrey",
  },
  {
    postal_code: "13135",
    full_city_name: "Phoenix, NY",
    city_name: "Phoenix",
  },
  {
    postal_code: "38313",
    full_city_name: "Beech Bluff, TN",
    city_name: "Beech Bluff",
  },
  {
    postal_code: "46165",
    full_city_name: "North Salem, IN",
    city_name: "North Salem",
  },
  {
    postal_code: "27503",
    full_city_name: "Bahama, NC",
    city_name: "Bahama",
  },
  {
    postal_code: "50170",
    full_city_name: "Monroe, IA",
    city_name: "Monroe",
  },
  {
    postal_code: "61723",
    full_city_name: "Atlanta, IL",
    city_name: "Atlanta",
  },
  {
    postal_code: "72153",
    full_city_name: "Shirley, AR",
    city_name: "Shirley",
  },
  {
    postal_code: "55750",
    full_city_name: "Hoyt Lakes, MN",
    city_name: "Hoyt Lakes",
  },
  {
    postal_code: "54454",
    full_city_name: "Milladore, WI",
    city_name: "Milladore",
  },
  {
    postal_code: "12942",
    full_city_name: "Keene, NY",
    city_name: "Keene",
  },
  {
    postal_code: "49274",
    full_city_name: "Reading, MI",
    city_name: "Reading",
  },
  {
    postal_code: "93109",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "66935",
    full_city_name: "Belleville, KS",
    city_name: "Belleville",
  },
  {
    postal_code: "05474",
    full_city_name: "North Hero, VT",
    city_name: "North Hero",
  },
  {
    postal_code: "99032",
    full_city_name: "Sprague, WA",
    city_name: "Sprague",
  },
  {
    postal_code: "44504",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "44837",
    full_city_name: "Greenwich, OH",
    city_name: "Greenwich",
  },
  {
    postal_code: "50161",
    full_city_name: "Maxwell, IA",
    city_name: "Maxwell",
  },
  {
    postal_code: "30182",
    full_city_name: "Waco, GA",
    city_name: "Waco",
  },
  {
    postal_code: "35188",
    full_city_name: "Woodstock, AL",
    city_name: "Woodstock",
  },
  {
    postal_code: "37902",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "75163",
    full_city_name: "Trinidad, TX",
    city_name: "Trinidad",
  },
  {
    postal_code: "74421",
    full_city_name: "Beggs, OK",
    city_name: "Beggs",
  },
  {
    postal_code: "54121",
    full_city_name: "Florence, WI",
    city_name: "Florence",
  },
  {
    postal_code: "40322",
    full_city_name: "Frenchburg, KY",
    city_name: "Frenchburg",
  },
  {
    postal_code: "05602",
    full_city_name: "Montpelier, VT",
    city_name: "Montpelier",
  },
  {
    postal_code: "54414",
    full_city_name: "Birnamwood, WI",
    city_name: "Birnamwood",
  },
  {
    postal_code: "24361",
    full_city_name: "Meadowview, VA",
    city_name: "Meadowview",
  },
  {
    postal_code: "96719",
    full_city_name: "Hawi, HI",
    city_name: "Hawi",
  },
  {
    postal_code: "79536",
    full_city_name: "Merkel, TX",
    city_name: "Merkel",
  },
  {
    postal_code: "18445",
    full_city_name: "Newfoundland, PA",
    city_name: "Newfoundland",
  },
  {
    postal_code: "77358",
    full_city_name: "New Waverly, TX",
    city_name: "New Waverly",
  },
  {
    postal_code: "40831",
    full_city_name: "Harlan, KY",
    city_name: "Harlan",
  },
  {
    postal_code: "20722",
    full_city_name: "Brentwood, MD",
    city_name: "Brentwood",
  },
  {
    postal_code: "30185",
    full_city_name: "Whitesburg, GA",
    city_name: "Whitesburg",
  },
  {
    postal_code: "32305",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "47130",
    full_city_name: "Jeffersonville, IN",
    city_name: "Jeffersonville",
  },
  {
    postal_code: "14624",
    full_city_name: "Rochester, NY",
    city_name: "Rochester",
  },
  {
    postal_code: "02659",
    full_city_name: "South Chatham, MA",
    city_name: "South Chatham",
  },
  {
    postal_code: "08059",
    full_city_name: "Mount Ephraim, NJ",
    city_name: "Mount Ephraim",
  },
  {
    postal_code: "78119",
    full_city_name: "Kenedy, TX",
    city_name: "Kenedy",
  },
  {
    postal_code: "03811",
    full_city_name: "Atkinson, NH",
    city_name: "Atkinson",
  },
  {
    postal_code: "63068",
    full_city_name: "New Haven, MO",
    city_name: "New Haven",
  },
  {
    postal_code: "44428",
    full_city_name: "Kinsman, OH",
    city_name: "Kinsman",
  },
  {
    postal_code: "95619",
    full_city_name: "Diamond Springs, CA",
    city_name: "Diamond Springs",
  },
  {
    postal_code: "20710",
    full_city_name: "Bladensburg, MD",
    city_name: "Bladensburg",
  },
  {
    postal_code: "98591",
    full_city_name: "Toledo, WA",
    city_name: "Toledo",
  },
  {
    postal_code: "20677",
    full_city_name: "Port Tobacco, MD",
    city_name: "Port Tobacco",
  },
  {
    postal_code: "04654",
    full_city_name: "Machias, ME",
    city_name: "Machias",
  },
  {
    postal_code: "61084",
    full_city_name: "Stillman Valley, IL",
    city_name: "Stillman Valley",
  },
  {
    postal_code: "64016",
    full_city_name: "Buckner, MO",
    city_name: "Buckner",
  },
  {
    postal_code: "01609",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "01119",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "76476",
    full_city_name: "Tolar, TX",
    city_name: "Tolar",
  },
  {
    postal_code: "93626",
    full_city_name: "Friant, CA",
    city_name: "Friant",
  },
  {
    postal_code: "44401",
    full_city_name: "Berlin Center, OH",
    city_name: "Berlin Center",
  },
  {
    postal_code: "03070",
    full_city_name: "New Boston, NH",
    city_name: "New Boston",
  },
  {
    postal_code: "55357",
    full_city_name: "Loretto, MN",
    city_name: "Loretto",
  },
  {
    postal_code: "43542",
    full_city_name: "Monclova, OH",
    city_name: "Monclova",
  },
  {
    postal_code: "22580",
    full_city_name: "Woodford, VA",
    city_name: "Woodford",
  },
  {
    postal_code: "04107",
    full_city_name: "Cape Elizabeth, ME",
    city_name: "Cape Elizabeth",
  },
  {
    postal_code: "98940",
    full_city_name: "Ronald, WA",
    city_name: "Ronald",
  },
  {
    postal_code: "43764",
    full_city_name: "New Lexington, OH",
    city_name: "New Lexington",
  },
  {
    postal_code: "27243",
    full_city_name: "Efland, NC",
    city_name: "Efland",
  },
  {
    postal_code: "53515",
    full_city_name: "Black Earth, WI",
    city_name: "Black Earth",
  },
  {
    postal_code: "97222",
    full_city_name: "Portland, OR",
    city_name: "Portland",
  },
  {
    postal_code: "63353",
    full_city_name: "Louisiana, MO",
    city_name: "Louisiana",
  },
  {
    postal_code: "98557",
    full_city_name: "Mccleary, WA",
    city_name: "Mccleary",
  },
  {
    postal_code: "83803",
    full_city_name: "Bayview, ID",
    city_name: "Bayview",
  },
  {
    postal_code: "95912",
    full_city_name: "Arbuckle, CA",
    city_name: "Arbuckle",
  },
  {
    postal_code: "29635",
    full_city_name: "Cleveland, SC",
    city_name: "Cleveland",
  },
  {
    postal_code: "75561",
    full_city_name: "Hooks, TX",
    city_name: "Hooks",
  },
  {
    postal_code: "56342",
    full_city_name: "Isle, MN",
    city_name: "Isle",
  },
  {
    postal_code: "24293",
    full_city_name: "Wise, VA",
    city_name: "Wise",
  },
  {
    postal_code: "75237",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "29664",
    full_city_name: "Mountain Rest, SC",
    city_name: "Mountain Rest",
  },
  {
    postal_code: "93427",
    full_city_name: "Buellton, CA",
    city_name: "Buellton",
  },
  {
    postal_code: "74056",
    full_city_name: "Pawhuska, OK",
    city_name: "Pawhuska",
  },
  {
    postal_code: "60040",
    full_city_name: "Highwood, IL",
    city_name: "Highwood",
  },
  {
    postal_code: "44620",
    full_city_name: "Dellroy, OH",
    city_name: "Dellroy",
  },
  {
    postal_code: "35224",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "39701",
    full_city_name: "Columbus, MS",
    city_name: "Columbus",
  },
  {
    postal_code: "81323",
    full_city_name: "Dolores, CO",
    city_name: "Dolores",
  },
  {
    postal_code: "15445",
    full_city_name: "Hopwood, PA",
    city_name: "Hopwood",
  },
  {
    postal_code: "12845",
    full_city_name: "Lake George, NY",
    city_name: "Lake George",
  },
  {
    postal_code: "65078",
    full_city_name: "Stover, MO",
    city_name: "Stover",
  },
  {
    postal_code: "55927",
    full_city_name: "Dodge Center, MN",
    city_name: "Dodge Center",
  },
  {
    postal_code: "52060",
    full_city_name: "Maquoketa, IA",
    city_name: "Maquoketa",
  },
  {
    postal_code: "02129",
    full_city_name: "Charlestown, MA",
    city_name: "Charlestown",
  },
  {
    postal_code: "57745",
    full_city_name: "Hill City, SD",
    city_name: "Hill City",
  },
  {
    postal_code: "77642",
    full_city_name: "Port Arthur, TX",
    city_name: "Port Arthur",
  },
  {
    postal_code: "64105",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "55615",
    full_city_name: "Tofte, MN",
    city_name: "Tofte",
  },
  {
    postal_code: "55322",
    full_city_name: "Cologne, MN",
    city_name: "Cologne",
  },
  {
    postal_code: "12056",
    full_city_name: "Duanesburg, NY",
    city_name: "Duanesburg",
  },
  {
    postal_code: "98388",
    full_city_name: "Steilacoom, WA",
    city_name: "Steilacoom",
  },
  {
    postal_code: "61281",
    full_city_name: "Sherrard, IL",
    city_name: "Sherrard",
  },
  {
    postal_code: "64153",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "56572",
    full_city_name: "Pelican Rapids, MN",
    city_name: "Pelican Rapids",
  },
  {
    postal_code: "27883",
    full_city_name: "Stantonsburg, NC",
    city_name: "Stantonsburg",
  },
  {
    postal_code: "76443",
    full_city_name: "Cross Plains, TX",
    city_name: "Cross Plains",
  },
  {
    postal_code: "66075",
    full_city_name: "Pleasanton, KS",
    city_name: "Pleasanton",
  },
  {
    postal_code: "54406",
    full_city_name: "Amherst, WI",
    city_name: "Amherst",
  },
  {
    postal_code: "81643",
    full_city_name: "Mesa, CO",
    city_name: "Mesa",
  },
  {
    postal_code: "56057",
    full_city_name: "Le Center, MN",
    city_name: "Le Center",
  },
  {
    postal_code: "60520",
    full_city_name: "Hinckley, IL",
    city_name: "Hinckley",
  },
  {
    postal_code: "96022",
    full_city_name: "Cottonwood, CA",
    city_name: "Cottonwood",
  },
  {
    postal_code: "96093",
    full_city_name: "Weaverville, CA",
    city_name: "Weaverville",
  },
  {
    postal_code: "95638",
    full_city_name: "Herald, CA",
    city_name: "Herald",
  },
  {
    postal_code: "54949",
    full_city_name: "Manawa, WI",
    city_name: "Manawa",
  },
  {
    postal_code: "05148",
    full_city_name: "Londonderry, VT",
    city_name: "Londonderry",
  },
  {
    postal_code: "04861",
    full_city_name: "Thomaston, ME",
    city_name: "Thomaston",
  },
  {
    postal_code: "63341",
    full_city_name: "Defiance, MO",
    city_name: "Defiance",
  },
  {
    postal_code: "80444",
    full_city_name: "Georgetown, CO",
    city_name: "Georgetown",
  },
  {
    postal_code: "54165",
    full_city_name: "Seymour, WI",
    city_name: "Seymour",
  },
  {
    postal_code: "95975",
    full_city_name: "Rough And Ready, CA",
    city_name: "Rough And Ready",
  },
  {
    postal_code: "61016",
    full_city_name: "Cherry Valley, IL",
    city_name: "Cherry Valley",
  },
  {
    postal_code: "28643",
    full_city_name: "Lansing, NC",
    city_name: "Lansing",
  },
  {
    postal_code: "42348",
    full_city_name: "Hawesville, KY",
    city_name: "Hawesville",
  },
  {
    postal_code: "59922",
    full_city_name: "Lakeside, MT",
    city_name: "Lakeside",
  },
  {
    postal_code: "77630",
    full_city_name: "Orange, TX",
    city_name: "Orange",
  },
  {
    postal_code: "75202",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "33194",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "05764",
    full_city_name: "Poultney, VT",
    city_name: "Poultney",
  },
  {
    postal_code: "18064",
    full_city_name: "Nazareth, PA",
    city_name: "Nazareth",
  },
  {
    postal_code: "18612",
    full_city_name: "Dallas, PA",
    city_name: "Dallas",
  },
  {
    postal_code: "61739",
    full_city_name: "Fairbury, IL",
    city_name: "Fairbury",
  },
  {
    postal_code: "48234",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "05055",
    full_city_name: "Norwich, VT",
    city_name: "Norwich",
  },
  {
    postal_code: "02093",
    full_city_name: "Wrentham, MA",
    city_name: "Wrentham",
  },
  {
    postal_code: "33855",
    full_city_name: "Indian Lake Estates, FL",
    city_name: "Indian Lake Estates",
  },
  {
    postal_code: "21201",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "01570",
    full_city_name: "Webster, MA",
    city_name: "Webster",
  },
  {
    postal_code: "20158",
    full_city_name: "Hamilton, VA",
    city_name: "Hamilton",
  },
  {
    postal_code: "15089",
    full_city_name: "West Newton, PA",
    city_name: "West Newton",
  },
  {
    postal_code: "98856",
    full_city_name: "Twisp, WA",
    city_name: "Twisp",
  },
  {
    postal_code: "43762",
    full_city_name: "New Concord, OH",
    city_name: "New Concord",
  },
  {
    postal_code: "74427",
    full_city_name: "Cookson, OK",
    city_name: "Cookson",
  },
  {
    postal_code: "29048",
    full_city_name: "Eutawville, SC",
    city_name: "Eutawville",
  },
  {
    postal_code: "78377",
    full_city_name: "Refugio, TX",
    city_name: "Refugio",
  },
  {
    postal_code: "66092",
    full_city_name: "Wellsville, KS",
    city_name: "Wellsville",
  },
  {
    postal_code: "12547",
    full_city_name: "Milton, NY",
    city_name: "Milton",
  },
  {
    postal_code: "16686",
    full_city_name: "Tyrone, PA",
    city_name: "Tyrone",
  },
  {
    postal_code: "75778",
    full_city_name: "Murchison, TX",
    city_name: "Murchison",
  },
  {
    postal_code: "71109",
    full_city_name: "Shreveport, LA",
    city_name: "Shreveport",
  },
  {
    postal_code: "16901",
    full_city_name: "Wellsboro, PA",
    city_name: "Wellsboro",
  },
  {
    postal_code: "45701",
    full_city_name: "Athens, OH",
    city_name: "Athens",
  },
  {
    postal_code: "02481",
    full_city_name: "Wellesley Hills, MA",
    city_name: "Wellesley Hills",
  },
  {
    postal_code: "30415",
    full_city_name: "Brooklet, GA",
    city_name: "Brooklet",
  },
  {
    postal_code: "56283",
    full_city_name: "Redwood Falls, MN",
    city_name: "Redwood Falls",
  },
  {
    postal_code: "42749",
    full_city_name: "Horse Cave, KY",
    city_name: "Horse Cave",
  },
  {
    postal_code: "12847",
    full_city_name: "Long Lake, NY",
    city_name: "Long Lake",
  },
  {
    postal_code: "21623",
    full_city_name: "Church Hill, MD",
    city_name: "Church Hill",
  },
  {
    postal_code: "28585",
    full_city_name: "Trenton, NC",
    city_name: "Trenton",
  },
  {
    postal_code: "02081",
    full_city_name: "Walpole, MA",
    city_name: "Walpole",
  },
  {
    postal_code: "02804",
    full_city_name: "Ashaway, RI",
    city_name: "Ashaway",
  },
  {
    postal_code: "68305",
    full_city_name: "Auburn, NE",
    city_name: "Auburn",
  },
  {
    postal_code: "02762",
    full_city_name: "Plainville, MA",
    city_name: "Plainville",
  },
  {
    postal_code: "47990",
    full_city_name: "Waynetown, IN",
    city_name: "Waynetown",
  },
  {
    postal_code: "01450",
    full_city_name: "Groton, MA",
    city_name: "Groton",
  },
  {
    postal_code: "57732",
    full_city_name: "Deadwood, SD",
    city_name: "Deadwood",
  },
  {
    postal_code: "12466",
    full_city_name: "Port Ewen, NY",
    city_name: "Port Ewen",
  },
  {
    postal_code: "94710",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "45806",
    full_city_name: "Lima, OH",
    city_name: "Lima",
  },
  {
    postal_code: "61434",
    full_city_name: "Galva, IL",
    city_name: "Galva",
  },
  {
    postal_code: "32052",
    full_city_name: "Jasper, FL",
    city_name: "Jasper",
  },
  {
    postal_code: "04444",
    full_city_name: "Hampden, ME",
    city_name: "Hampden",
  },
  {
    postal_code: "02333",
    full_city_name: "East Bridgewater, MA",
    city_name: "East Bridgewater",
  },
  {
    postal_code: "12992",
    full_city_name: "West Chazy, NY",
    city_name: "West Chazy",
  },
  {
    postal_code: "27962",
    full_city_name: "Plymouth, NC",
    city_name: "Plymouth",
  },
  {
    postal_code: "01430",
    full_city_name: "Ashburnham, MA",
    city_name: "Ashburnham",
  },
  {
    postal_code: "70392",
    full_city_name: "Patterson, LA",
    city_name: "Patterson",
  },
  {
    postal_code: "43019",
    full_city_name: "Fredericktown, OH",
    city_name: "Fredericktown",
  },
  {
    postal_code: "86432",
    full_city_name: "Littlefield, AZ",
    city_name: "Littlefield",
  },
  {
    postal_code: "06330",
    full_city_name: "Baltic, CT",
    city_name: "Baltic",
  },
  {
    postal_code: "48455",
    full_city_name: "Metamora, MI",
    city_name: "Metamora",
  },
  {
    postal_code: "40152",
    full_city_name: "Mc Daniels, KY",
    city_name: "Mc Daniels",
  },
  {
    postal_code: "12424",
    full_city_name: "East Jewett, NY",
    city_name: "East Jewett",
  },
  {
    postal_code: "43102",
    full_city_name: "Amanda, OH",
    city_name: "Amanda",
  },
  {
    postal_code: "63459",
    full_city_name: "New London, MO",
    city_name: "New London",
  },
  {
    postal_code: "20861",
    full_city_name: "Ashton, MD",
    city_name: "Ashton",
  },
  {
    postal_code: "87529",
    full_city_name: "El Prado, NM",
    city_name: "El Prado",
  },
  {
    postal_code: "04055",
    full_city_name: "Naples, ME",
    city_name: "Naples",
  },
  {
    postal_code: "21911",
    full_city_name: "Rising Sun, MD",
    city_name: "Rising Sun",
  },
  {
    postal_code: "05829",
    full_city_name: "Derby, VT",
    city_name: "Derby",
  },
  {
    postal_code: "30171",
    full_city_name: "Rydal, GA",
    city_name: "Rydal",
  },
  {
    postal_code: "37051",
    full_city_name: "Cumberland Furnace, TN",
    city_name: "Cumberland Furnace",
  },
  {
    postal_code: "56484",
    full_city_name: "Walker, MN",
    city_name: "Walker",
  },
  {
    postal_code: "91203",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "01832",
    full_city_name: "Haverhill, MA",
    city_name: "Haverhill",
  },
  {
    postal_code: "33109",
    full_city_name: "Miami Beach, FL",
    city_name: "Miami Beach",
  },
  {
    postal_code: "98855",
    full_city_name: "Tonasket, WA",
    city_name: "Tonasket",
  },
  {
    postal_code: "02571",
    full_city_name: "Wareham, MA",
    city_name: "Wareham",
  },
  {
    postal_code: "98826",
    full_city_name: "Leavenworth, WA",
    city_name: "Leavenworth",
  },
  {
    postal_code: "30552",
    full_city_name: "Lakemont, GA",
    city_name: "Lakemont",
  },
  {
    postal_code: "21111",
    full_city_name: "Monkton, MD",
    city_name: "Monkton",
  },
  {
    postal_code: "13309",
    full_city_name: "Boonville, NY",
    city_name: "Boonville",
  },
  {
    postal_code: "16946",
    full_city_name: "Tioga, PA",
    city_name: "Tioga",
  },
  {
    postal_code: "59932",
    full_city_name: "Somers, MT",
    city_name: "Somers",
  },
  {
    postal_code: "47396",
    full_city_name: "Yorktown, IN",
    city_name: "Yorktown",
  },
  {
    postal_code: "27350",
    full_city_name: "Sophia, NC",
    city_name: "Sophia",
  },
  {
    postal_code: "12121",
    full_city_name: "Melrose, NY",
    city_name: "Melrose",
  },
  {
    postal_code: "20645",
    full_city_name: "Issue, MD",
    city_name: "Issue",
  },
  {
    postal_code: "17045",
    full_city_name: "Liverpool, PA",
    city_name: "Liverpool",
  },
  {
    postal_code: "26047",
    full_city_name: "New Cumberland, WV",
    city_name: "New Cumberland",
  },
  {
    postal_code: "06763",
    full_city_name: "Morris, CT",
    city_name: "Morris",
  },
  {
    postal_code: "13416",
    full_city_name: "Newport, NY",
    city_name: "Newport",
  },
  {
    postal_code: "20842",
    full_city_name: "Dickerson, MD",
    city_name: "Dickerson",
  },
  {
    postal_code: "40437",
    full_city_name: "Hustonville, KY",
    city_name: "Hustonville",
  },
  {
    postal_code: "61074",
    full_city_name: "Savanna, IL",
    city_name: "Savanna",
  },
  {
    postal_code: "94703",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "87020",
    full_city_name: "Grants, NM",
    city_name: "Grants",
  },
  {
    postal_code: "21154",
    full_city_name: "Street, MD",
    city_name: "Street",
  },
  {
    postal_code: "18507",
    full_city_name: "Moosic, PA",
    city_name: "Moosic",
  },
  {
    postal_code: "39170",
    full_city_name: "Terry, MS",
    city_name: "Terry",
  },
  {
    postal_code: "22937",
    full_city_name: "Esmont, VA",
    city_name: "Esmont",
  },
  {
    postal_code: "57066",
    full_city_name: "Tyndall, SD",
    city_name: "Tyndall",
  },
  {
    postal_code: "52342",
    full_city_name: "Toledo, IA",
    city_name: "Toledo",
  },
  {
    postal_code: "42167",
    full_city_name: "Tompkinsville, KY",
    city_name: "Tompkinsville",
  },
  {
    postal_code: "23693",
    full_city_name: "Yorktown, VA",
    city_name: "Yorktown",
  },
  {
    postal_code: "41164",
    full_city_name: "Olive Hill, KY",
    city_name: "Olive Hill",
  },
  {
    postal_code: "46996",
    full_city_name: "Winamac, IN",
    city_name: "Winamac",
  },
  {
    postal_code: "17362",
    full_city_name: "Spring Grove, PA",
    city_name: "Spring Grove",
  },
  {
    postal_code: "32680",
    full_city_name: "Old Town, FL",
    city_name: "Old Town",
  },
  {
    postal_code: "12503",
    full_city_name: "Ancramdale, NY",
    city_name: "Ancramdale",
  },
  {
    postal_code: "43008",
    full_city_name: "Buckeye Lake, OH",
    city_name: "Buckeye Lake",
  },
  {
    postal_code: "22645",
    full_city_name: "Middletown, VA",
    city_name: "Middletown",
  },
  {
    postal_code: "68059",
    full_city_name: "Springfield, NE",
    city_name: "Springfield",
  },
  {
    postal_code: "40371",
    full_city_name: "Salt Lick, KY",
    city_name: "Salt Lick",
  },
  {
    postal_code: "01543",
    full_city_name: "Rutland, MA",
    city_name: "Rutland",
  },
  {
    postal_code: "37755",
    full_city_name: "Helenwood, TN",
    city_name: "Helenwood",
  },
  {
    postal_code: "48401",
    full_city_name: "Applegate, MI",
    city_name: "Applegate",
  },
  {
    postal_code: "85606",
    full_city_name: "Cochise, AZ",
    city_name: "Cochise",
  },
  {
    postal_code: "51521",
    full_city_name: "Avoca, IA",
    city_name: "Avoca",
  },
  {
    postal_code: "32621",
    full_city_name: "Bronson, FL",
    city_name: "Bronson",
  },
  {
    postal_code: "30619",
    full_city_name: "Arnoldsville, GA",
    city_name: "Arnoldsville",
  },
  {
    postal_code: "35091",
    full_city_name: "Kimberly, AL",
    city_name: "Kimberly",
  },
  {
    postal_code: "79323",
    full_city_name: "Denver City, TX",
    city_name: "Denver City",
  },
  {
    postal_code: "62016",
    full_city_name: "Carrollton, IL",
    city_name: "Carrollton",
  },
  {
    postal_code: "63072",
    full_city_name: "Robertsville, MO",
    city_name: "Robertsville",
  },
  {
    postal_code: "15035",
    full_city_name: "East Mc Keesport, PA",
    city_name: "East Mc Keesport",
  },
  {
    postal_code: "13775",
    full_city_name: "Franklin, NY",
    city_name: "Franklin",
  },
  {
    postal_code: "23219",
    full_city_name: "Richmond, VA",
    city_name: "Richmond",
  },
  {
    postal_code: "26150",
    full_city_name: "Mineral Wells, WV",
    city_name: "Mineral Wells",
  },
  {
    postal_code: "03269",
    full_city_name: "Sanbornton, NH",
    city_name: "Sanbornton",
  },
  {
    postal_code: "46774",
    full_city_name: "New Haven, IN",
    city_name: "New Haven",
  },
  {
    postal_code: "02132",
    full_city_name: "West Roxbury, MA",
    city_name: "West Roxbury",
  },
  {
    postal_code: "85624",
    full_city_name: "Patagonia, AZ",
    city_name: "Patagonia",
  },
  {
    postal_code: "07203",
    full_city_name: "Roselle, NJ",
    city_name: "Roselle",
  },
  {
    postal_code: "40962",
    full_city_name: "Manchester, KY",
    city_name: "Manchester",
  },
  {
    postal_code: "75964",
    full_city_name: "Nacogdoches, TX",
    city_name: "Nacogdoches",
  },
  {
    postal_code: "59330",
    full_city_name: "Glendive, MT",
    city_name: "Glendive",
  },
  {
    postal_code: "08852",
    full_city_name: "Monmouth Junction, NJ",
    city_name: "Monmouth Junction",
  },
  {
    postal_code: "95626",
    full_city_name: "Elverta, CA",
    city_name: "Elverta",
  },
  {
    postal_code: "98941",
    full_city_name: "Roslyn, WA",
    city_name: "Roslyn",
  },
  {
    postal_code: "95379",
    full_city_name: "Tuolumne, CA",
    city_name: "Tuolumne",
  },
  {
    postal_code: "05403",
    full_city_name: "South Burlington, VT",
    city_name: "South Burlington",
  },
  {
    postal_code: "10710",
    full_city_name: "Yonkers, NY",
    city_name: "Yonkers",
  },
  {
    postal_code: "36801",
    full_city_name: "Opelika, AL",
    city_name: "Opelika",
  },
  {
    postal_code: "56573",
    full_city_name: "Perham, MN",
    city_name: "Perham",
  },
  {
    postal_code: "64477",
    full_city_name: "Plattsburg, MO",
    city_name: "Plattsburg",
  },
  {
    postal_code: "28736",
    full_city_name: "Glenville, NC",
    city_name: "Glenville",
  },
  {
    postal_code: "49858",
    full_city_name: "Menominee, MI",
    city_name: "Menominee",
  },
  {
    postal_code: "57769",
    full_city_name: "Piedmont, SD",
    city_name: "Piedmont",
  },
  {
    postal_code: "38059",
    full_city_name: "Newbern, TN",
    city_name: "Newbern",
  },
  {
    postal_code: "93460",
    full_city_name: "Santa Ynez, CA",
    city_name: "Santa Ynez",
  },
  {
    postal_code: "28398",
    full_city_name: "Warsaw, NC",
    city_name: "Warsaw",
  },
  {
    postal_code: "30290",
    full_city_name: "Tyrone, GA",
    city_name: "Tyrone",
  },
  {
    postal_code: "48071",
    full_city_name: "Madison Heights, MI",
    city_name: "Madison Heights",
  },
  {
    postal_code: "05450",
    full_city_name: "Enosburg Falls, VT",
    city_name: "Enosburg Falls",
  },
  {
    postal_code: "01907",
    full_city_name: "Swampscott, MA",
    city_name: "Swampscott",
  },
  {
    postal_code: "60602",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "55353",
    full_city_name: "Kimball, MN",
    city_name: "Kimball",
  },
  {
    postal_code: "15052",
    full_city_name: "Industry, PA",
    city_name: "Industry",
  },
  {
    postal_code: "10598",
    full_city_name: "Yorktown Heights, NY",
    city_name: "Yorktown Heights",
  },
  {
    postal_code: "04270",
    full_city_name: "Oxford, ME",
    city_name: "Oxford",
  },
  {
    postal_code: "30303",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "06093",
    full_city_name: "West Suffield, CT",
    city_name: "West Suffield",
  },
  {
    postal_code: "95335",
    full_city_name: "Long Barn, CA",
    city_name: "Long Barn",
  },
  {
    postal_code: "37327",
    full_city_name: "Dunlap, TN",
    city_name: "Dunlap",
  },
  {
    postal_code: "19022",
    full_city_name: "Crum Lynne, PA",
    city_name: "Crum Lynne",
  },
  {
    postal_code: "99402",
    full_city_name: "Asotin, WA",
    city_name: "Asotin",
  },
  {
    postal_code: "45696",
    full_city_name: "Willow Wood, OH",
    city_name: "Willow Wood",
  },
  {
    postal_code: "50166",
    full_city_name: "Milo, IA",
    city_name: "Milo",
  },
  {
    postal_code: "84333",
    full_city_name: "Richmond, UT",
    city_name: "Richmond",
  },
  {
    postal_code: "49101",
    full_city_name: "Baroda, MI",
    city_name: "Baroda",
  },
  {
    postal_code: "29020",
    full_city_name: "Camden, SC",
    city_name: "Camden",
  },
  {
    postal_code: "27299",
    full_city_name: "Linwood, NC",
    city_name: "Linwood",
  },
  {
    postal_code: "60922",
    full_city_name: "Chebanse, IL",
    city_name: "Chebanse",
  },
  {
    postal_code: "98593",
    full_city_name: "Vader, WA",
    city_name: "Vader",
  },
  {
    postal_code: "27573",
    full_city_name: "Roxboro, NC",
    city_name: "Roxboro",
  },
  {
    postal_code: "93247",
    full_city_name: "Lindsay, CA",
    city_name: "Lindsay",
  },
  {
    postal_code: "64507",
    full_city_name: "Saint Joseph, MO",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "67107",
    full_city_name: "Moundridge, KS",
    city_name: "Moundridge",
  },
  {
    postal_code: "55790",
    full_city_name: "Tower, MN",
    city_name: "Tower",
  },
  {
    postal_code: "45869",
    full_city_name: "New Bremen, OH",
    city_name: "New Bremen",
  },
  {
    postal_code: "04040",
    full_city_name: "Harrison, ME",
    city_name: "Harrison",
  },
  {
    postal_code: "54736",
    full_city_name: "Durand, WI",
    city_name: "Durand",
  },
  {
    postal_code: "06088",
    full_city_name: "East Windsor, CT",
    city_name: "East Windsor",
  },
  {
    postal_code: "40456",
    full_city_name: "Mount Vernon, KY",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "75473",
    full_city_name: "Powderly, TX",
    city_name: "Powderly",
  },
  {
    postal_code: "89444",
    full_city_name: "Wellington, NV",
    city_name: "Wellington",
  },
  {
    postal_code: "47460",
    full_city_name: "Spencer, IN",
    city_name: "Spencer",
  },
  {
    postal_code: "51510",
    full_city_name: "Carter Lake, IA",
    city_name: "Carter Lake",
  },
  {
    postal_code: "78387",
    full_city_name: "Sinton, TX",
    city_name: "Sinton",
  },
  {
    postal_code: "37722",
    full_city_name: "Cosby, TN",
    city_name: "Cosby",
  },
  {
    postal_code: "72110",
    full_city_name: "Morrilton, AR",
    city_name: "Morrilton",
  },
  {
    postal_code: "13317",
    full_city_name: "Canajoharie, NY",
    city_name: "Canajoharie",
  },
  {
    postal_code: "78203",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "19975",
    full_city_name: "Selbyville, DE",
    city_name: "Selbyville",
  },
  {
    postal_code: "74647",
    full_city_name: "Newkirk, OK",
    city_name: "Newkirk",
  },
  {
    postal_code: "13691",
    full_city_name: "Theresa, NY",
    city_name: "Theresa",
  },
  {
    postal_code: "56655",
    full_city_name: "Longville, MN",
    city_name: "Longville",
  },
  {
    postal_code: "32038",
    full_city_name: "Fort White, FL",
    city_name: "Fort White",
  },
  {
    postal_code: "75158",
    full_city_name: "Scurry, TX",
    city_name: "Scurry",
  },
  {
    postal_code: "60964",
    full_city_name: "Saint Anne, IL",
    city_name: "Saint Anne",
  },
  {
    postal_code: "13114",
    full_city_name: "Mexico, NY",
    city_name: "Mexico",
  },
  {
    postal_code: "02090",
    full_city_name: "Westwood, MA",
    city_name: "Westwood",
  },
  {
    postal_code: "46172",
    full_city_name: "Roachdale, IN",
    city_name: "Roachdale",
  },
  {
    postal_code: "76093",
    full_city_name: "Rio Vista, TX",
    city_name: "Rio Vista",
  },
  {
    postal_code: "42544",
    full_city_name: "Nancy, KY",
    city_name: "Nancy",
  },
  {
    postal_code: "38663",
    full_city_name: "Ripley, MS",
    city_name: "Ripley",
  },
  {
    postal_code: "95917",
    full_city_name: "Biggs, CA",
    city_name: "Biggs",
  },
  {
    postal_code: "75750",
    full_city_name: "Arp, TX",
    city_name: "Arp",
  },
  {
    postal_code: "75424",
    full_city_name: "Blue Ridge, TX",
    city_name: "Blue Ridge",
  },
  {
    postal_code: "21643",
    full_city_name: "Hurlock, MD",
    city_name: "Hurlock",
  },
  {
    postal_code: "44710",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "21655",
    full_city_name: "Preston, MD",
    city_name: "Preston",
  },
  {
    postal_code: "27258",
    full_city_name: "Haw River, NC",
    city_name: "Haw River",
  },
  {
    postal_code: "55390",
    full_city_name: "Waverly, MN",
    city_name: "Waverly",
  },
  {
    postal_code: "81419",
    full_city_name: "Hotchkiss, CO",
    city_name: "Hotchkiss",
  },
  {
    postal_code: "49807",
    full_city_name: "Bark River, MI",
    city_name: "Bark River",
  },
  {
    postal_code: "27524",
    full_city_name: "Four Oaks, NC",
    city_name: "Four Oaks",
  },
  {
    postal_code: "64053",
    full_city_name: "Independence, MO",
    city_name: "Independence",
  },
  {
    postal_code: "80808",
    full_city_name: "Calhan, CO",
    city_name: "Calhan",
  },
  {
    postal_code: "95461",
    full_city_name: "Middletown, CA",
    city_name: "Middletown",
  },
  {
    postal_code: "01536",
    full_city_name: "North Grafton, MA",
    city_name: "North Grafton",
  },
  {
    postal_code: "89445",
    full_city_name: "Winnemucca, NV",
    city_name: "Winnemucca",
  },
  {
    postal_code: "52246",
    full_city_name: "Iowa City, IA",
    city_name: "Iowa City",
  },
  {
    postal_code: "18405",
    full_city_name: "Beach Lake, PA",
    city_name: "Beach Lake",
  },
  {
    postal_code: "37308",
    full_city_name: "Birchwood, TN",
    city_name: "Birchwood",
  },
  {
    postal_code: "04416",
    full_city_name: "Bucksport, ME",
    city_name: "Bucksport",
  },
  {
    postal_code: "38310",
    full_city_name: "Adamsville, TN",
    city_name: "Adamsville",
  },
  {
    postal_code: "39074",
    full_city_name: "Forest, MS",
    city_name: "Forest",
  },
  {
    postal_code: "61744",
    full_city_name: "Gridley, IL",
    city_name: "Gridley",
  },
  {
    postal_code: "01028",
    full_city_name: "East Longmeadow, MA",
    city_name: "East Longmeadow",
  },
  {
    postal_code: "79525",
    full_city_name: "Hawley, TX",
    city_name: "Hawley",
  },
  {
    postal_code: "56441",
    full_city_name: "Crosby, MN",
    city_name: "Crosby",
  },
  {
    postal_code: "80118",
    full_city_name: "Larkspur, CO",
    city_name: "Larkspur",
  },
  {
    postal_code: "62075",
    full_city_name: "Nokomis, IL",
    city_name: "Nokomis",
  },
  {
    postal_code: "16316",
    full_city_name: "Conneaut Lake, PA",
    city_name: "Conneaut Lake",
  },
  {
    postal_code: "27910",
    full_city_name: "Ahoskie, NC",
    city_name: "Ahoskie",
  },
  {
    postal_code: "26164",
    full_city_name: "Ravenswood, WV",
    city_name: "Ravenswood",
  },
  {
    postal_code: "70577",
    full_city_name: "Port Barre, LA",
    city_name: "Port Barre",
  },
  {
    postal_code: "53549",
    full_city_name: "Jefferson, WI",
    city_name: "Jefferson",
  },
  {
    postal_code: "44680",
    full_city_name: "Strasburg, OH",
    city_name: "Strasburg",
  },
  {
    postal_code: "01301",
    full_city_name: "Greenfield, MA",
    city_name: "Greenfield",
  },
  {
    postal_code: "55775",
    full_city_name: "Pengilly, MN",
    city_name: "Pengilly",
  },
  {
    postal_code: "37846",
    full_city_name: "Philadelphia, TN",
    city_name: "Philadelphia",
  },
  {
    postal_code: "65338",
    full_city_name: "Lincoln, MO",
    city_name: "Lincoln",
  },
  {
    postal_code: "29645",
    full_city_name: "Gray Court, SC",
    city_name: "Gray Court",
  },
  {
    postal_code: "93449",
    full_city_name: "Pismo Beach, CA",
    city_name: "Pismo Beach",
  },
  {
    postal_code: "72635",
    full_city_name: "Gassville, AR",
    city_name: "Gassville",
  },
  {
    postal_code: "56072",
    full_city_name: "New Richland, MN",
    city_name: "New Richland",
  },
  {
    postal_code: "20759",
    full_city_name: "Fulton, MD",
    city_name: "Fulton",
  },
  {
    postal_code: "14821",
    full_city_name: "Campbell, NY",
    city_name: "Campbell",
  },
  {
    postal_code: "06756",
    full_city_name: "Goshen, CT",
    city_name: "Goshen",
  },
  {
    postal_code: "21756",
    full_city_name: "Keedysville, MD",
    city_name: "Keedysville",
  },
  {
    postal_code: "27025",
    full_city_name: "Madison, NC",
    city_name: "Madison",
  },
  {
    postal_code: "74344",
    full_city_name: "Grove, OK",
    city_name: "Grove",
  },
  {
    postal_code: "45225",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "76164",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "30139",
    full_city_name: "Fairmount, GA",
    city_name: "Fairmount",
  },
  {
    postal_code: "75647",
    full_city_name: "Gladewater, TX",
    city_name: "Gladewater",
  },
  {
    postal_code: "55783",
    full_city_name: "Sturgeon Lake, MN",
    city_name: "Sturgeon Lake",
  },
  {
    postal_code: "80135",
    full_city_name: "Sedalia, CO",
    city_name: "Sedalia",
  },
  {
    postal_code: "30531",
    full_city_name: "Cornelia, GA",
    city_name: "Cornelia",
  },
  {
    postal_code: "25420",
    full_city_name: "Gerrardstown, WV",
    city_name: "Gerrardstown",
  },
  {
    postal_code: "54208",
    full_city_name: "Denmark, WI",
    city_name: "Denmark",
  },
  {
    postal_code: "12154",
    full_city_name: "Schaghticoke, NY",
    city_name: "Schaghticoke",
  },
  {
    postal_code: "03864",
    full_city_name: "Ossipee, NH",
    city_name: "Ossipee",
  },
  {
    postal_code: "17044",
    full_city_name: "Lewistown, PA",
    city_name: "Lewistown",
  },
  {
    postal_code: "59722",
    full_city_name: "Deer Lodge, MT",
    city_name: "Deer Lodge",
  },
  {
    postal_code: "01254",
    full_city_name: "Richmond, MA",
    city_name: "Richmond",
  },
  {
    postal_code: "81008",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "53946",
    full_city_name: "Markesan, WI",
    city_name: "Markesan",
  },
  {
    postal_code: "55707",
    full_city_name: "Barnum, MN",
    city_name: "Barnum",
  },
  {
    postal_code: "72687",
    full_city_name: "Yellville, AR",
    city_name: "Yellville",
  },
  {
    postal_code: "46534",
    full_city_name: "Knox, IN",
    city_name: "Knox",
  },
  {
    postal_code: "40769",
    full_city_name: "Williamsburg, KY",
    city_name: "Williamsburg",
  },
  {
    postal_code: "64037",
    full_city_name: "Higginsville, MO",
    city_name: "Higginsville",
  },
  {
    postal_code: "32180",
    full_city_name: "Pierson, FL",
    city_name: "Pierson",
  },
  {
    postal_code: "64465",
    full_city_name: "Lathrop, MO",
    city_name: "Lathrop",
  },
  {
    postal_code: "12887",
    full_city_name: "Whitehall, NY",
    city_name: "Whitehall",
  },
  {
    postal_code: "53179",
    full_city_name: "Trevor, WI",
    city_name: "Trevor",
  },
  {
    postal_code: "06060",
    full_city_name: "North Granby, CT",
    city_name: "North Granby",
  },
  {
    postal_code: "84312",
    full_city_name: "Garland, UT",
    city_name: "Garland",
  },
  {
    postal_code: "12529",
    full_city_name: "Hillsdale, NY",
    city_name: "Hillsdale",
  },
  {
    postal_code: "59923",
    full_city_name: "Libby, MT",
    city_name: "Libby",
  },
  {
    postal_code: "78834",
    full_city_name: "Carrizo Springs, TX",
    city_name: "Carrizo Springs",
  },
  {
    postal_code: "54554",
    full_city_name: "Phelps, WI",
    city_name: "Phelps",
  },
  {
    postal_code: "93675",
    full_city_name: "Squaw Valley, CA",
    city_name: "Squaw Valley",
  },
  {
    postal_code: "03048",
    full_city_name: "Greenville, NH",
    city_name: "Greenville",
  },
  {
    postal_code: "55767",
    full_city_name: "Moose Lake, MN",
    city_name: "Moose Lake",
  },
  {
    postal_code: "40050",
    full_city_name: "New Castle, KY",
    city_name: "New Castle",
  },
  {
    postal_code: "97411",
    full_city_name: "Bandon, OR",
    city_name: "Bandon",
  },
  {
    postal_code: "97733",
    full_city_name: "Crescent, OR",
    city_name: "Crescent",
  },
  {
    postal_code: "30178",
    full_city_name: "Taylorsville, GA",
    city_name: "Taylorsville",
  },
  {
    postal_code: "32193",
    full_city_name: "Welaka, FL",
    city_name: "Welaka",
  },
  {
    postal_code: "66071",
    full_city_name: "Paola, KS",
    city_name: "Paola",
  },
  {
    postal_code: "42351",
    full_city_name: "Lewisport, KY",
    city_name: "Lewisport",
  },
  {
    postal_code: "75755",
    full_city_name: "Big Sandy, TX",
    city_name: "Big Sandy",
  },
  {
    postal_code: "77662",
    full_city_name: "Vidor, TX",
    city_name: "Vidor",
  },
  {
    postal_code: "72554",
    full_city_name: "Mammoth Spring, AR",
    city_name: "Mammoth Spring",
  },
  {
    postal_code: "75490",
    full_city_name: "Trenton, TX",
    city_name: "Trenton",
  },
  {
    postal_code: "12463",
    full_city_name: "Palenville, NY",
    city_name: "Palenville",
  },
  {
    postal_code: "14843",
    full_city_name: "Hornell, NY",
    city_name: "Hornell",
  },
  {
    postal_code: "25276",
    full_city_name: "Spencer, WV",
    city_name: "Spencer",
  },
  {
    postal_code: "55705",
    full_city_name: "Aurora, MN",
    city_name: "Aurora",
  },
  {
    postal_code: "34474",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "92341",
    full_city_name: "Green Valley Lake, CA",
    city_name: "Green Valley Lake",
  },
  {
    postal_code: "90716",
    full_city_name: "Hawaiian Gardens, CA",
    city_name: "Hawaiian Gardens",
  },
  {
    postal_code: "37915",
    full_city_name: "Knoxville, TN",
    city_name: "Knoxville",
  },
  {
    postal_code: "47446",
    full_city_name: "Mitchell, IN",
    city_name: "Mitchell",
  },
  {
    postal_code: "29148",
    full_city_name: "Summerton, SC",
    city_name: "Summerton",
  },
  {
    postal_code: "89820",
    full_city_name: "Battle Mountain, NV",
    city_name: "Battle Mountain",
  },
  {
    postal_code: "11705",
    full_city_name: "Bayport, NY",
    city_name: "Bayport",
  },
  {
    postal_code: "28681",
    full_city_name: "Taylorsville, NC",
    city_name: "Taylorsville",
  },
  {
    postal_code: "08221",
    full_city_name: "Linwood, NJ",
    city_name: "Linwood",
  },
  {
    postal_code: "01850",
    full_city_name: "Lowell, MA",
    city_name: "Lowell",
  },
  {
    postal_code: "45359",
    full_city_name: "Pleasant Hill, OH",
    city_name: "Pleasant Hill",
  },
  {
    postal_code: "68157",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "54013",
    full_city_name: "Glenwood City, WI",
    city_name: "Glenwood City",
  },
  {
    postal_code: "24554",
    full_city_name: "Gladys, VA",
    city_name: "Gladys",
  },
  {
    postal_code: "16666",
    full_city_name: "Osceola Mills, PA",
    city_name: "Osceola Mills",
  },
  {
    postal_code: "43044",
    full_city_name: "Mechanicsburg, OH",
    city_name: "Mechanicsburg",
  },
  {
    postal_code: "29728",
    full_city_name: "Pageland, SC",
    city_name: "Pageland",
  },
  {
    postal_code: "37866",
    full_city_name: "Sharps Chapel, TN",
    city_name: "Sharps Chapel",
  },
  {
    postal_code: "70374",
    full_city_name: "Lockport, LA",
    city_name: "Lockport",
  },
  {
    postal_code: "10037",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "61833",
    full_city_name: "Tilton, IL",
    city_name: "Tilton",
  },
  {
    postal_code: "15557",
    full_city_name: "Rockwood, PA",
    city_name: "Rockwood",
  },
  {
    postal_code: "18923",
    full_city_name: "Fountainville, PA",
    city_name: "Fountainville",
  },
  {
    postal_code: "15834",
    full_city_name: "Emporium, PA",
    city_name: "Emporium",
  },
  {
    postal_code: "23850",
    full_city_name: "Ford, VA",
    city_name: "Ford",
  },
  {
    postal_code: "45159",
    full_city_name: "New Vienna, OH",
    city_name: "New Vienna",
  },
  {
    postal_code: "31606",
    full_city_name: "Valdosta, GA",
    city_name: "Valdosta",
  },
  {
    postal_code: "66534",
    full_city_name: "Sabetha, KS",
    city_name: "Sabetha",
  },
  {
    postal_code: "63349",
    full_city_name: "Hawk Point, MO",
    city_name: "Hawk Point",
  },
  {
    postal_code: "36104",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "11749",
    full_city_name: "Islandia, NY",
    city_name: "Islandia",
  },
  {
    postal_code: "49916",
    full_city_name: "Chassell, MI",
    city_name: "Chassell",
  },
  {
    postal_code: "13787",
    full_city_name: "Harpursville, NY",
    city_name: "Harpursville",
  },
  {
    postal_code: "05830",
    full_city_name: "Derby Line, VT",
    city_name: "Derby Line",
  },
  {
    postal_code: "28163",
    full_city_name: "Stanfield, NC",
    city_name: "Stanfield",
  },
  {
    postal_code: "29014",
    full_city_name: "Blackstock, SC",
    city_name: "Blackstock",
  },
  {
    postal_code: "13308",
    full_city_name: "Blossvale, NY",
    city_name: "Blossvale",
  },
  {
    postal_code: "90012",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "97327",
    full_city_name: "Brownsville, OR",
    city_name: "Brownsville",
  },
  {
    postal_code: "95663",
    full_city_name: "Penryn, CA",
    city_name: "Penryn",
  },
  {
    postal_code: "30734",
    full_city_name: "Ranger, GA",
    city_name: "Ranger",
  },
  {
    postal_code: "28128",
    full_city_name: "Norwood, NC",
    city_name: "Norwood",
  },
  {
    postal_code: "43342",
    full_city_name: "Prospect, OH",
    city_name: "Prospect",
  },
  {
    postal_code: "78025",
    full_city_name: "Ingram, TX",
    city_name: "Ingram",
  },
  {
    postal_code: "80734",
    full_city_name: "Holyoke, CO",
    city_name: "Holyoke",
  },
  {
    postal_code: "90028",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "49870",
    full_city_name: "Norway, MI",
    city_name: "Norway",
  },
  {
    postal_code: "59825",
    full_city_name: "Clinton, MT",
    city_name: "Clinton",
  },
  {
    postal_code: "66002",
    full_city_name: "Atchison, KS",
    city_name: "Atchison",
  },
  {
    postal_code: "76941",
    full_city_name: "Mertzon, TX",
    city_name: "Mertzon",
  },
  {
    postal_code: "50074",
    full_city_name: "Ellston, IA",
    city_name: "Ellston",
  },
  {
    postal_code: "27842",
    full_city_name: "Henrico, NC",
    city_name: "Henrico",
  },
  {
    postal_code: "49233",
    full_city_name: "Cement City, MI",
    city_name: "Cement City",
  },
  {
    postal_code: "03584",
    full_city_name: "Lancaster, NH",
    city_name: "Lancaster",
  },
  {
    postal_code: "45325",
    full_city_name: "Farmersville, OH",
    city_name: "Farmersville",
  },
  {
    postal_code: "44502",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "76711",
    full_city_name: "Waco, TX",
    city_name: "Waco",
  },
  {
    postal_code: "49285",
    full_city_name: "Stockbridge, MI",
    city_name: "Stockbridge",
  },
  {
    postal_code: "55921",
    full_city_name: "Caledonia, MN",
    city_name: "Caledonia",
  },
  {
    postal_code: "54124",
    full_city_name: "Gillett, WI",
    city_name: "Gillett",
  },
  {
    postal_code: "54534",
    full_city_name: "Hurley, WI",
    city_name: "Hurley",
  },
  {
    postal_code: "13739",
    full_city_name: "Bloomville, NY",
    city_name: "Bloomville",
  },
  {
    postal_code: "05674",
    full_city_name: "Warren, VT",
    city_name: "Warren",
  },
  {
    postal_code: "50472",
    full_city_name: "Saint Ansgar, IA",
    city_name: "Saint Ansgar",
  },
  {
    postal_code: "60421",
    full_city_name: "Elwood, IL",
    city_name: "Elwood",
  },
  {
    postal_code: "54984",
    full_city_name: "Wild Rose, WI",
    city_name: "Wild Rose",
  },
  {
    postal_code: "43154",
    full_city_name: "Stoutsville, OH",
    city_name: "Stoutsville",
  },
  {
    postal_code: "43517",
    full_city_name: "Edgerton, OH",
    city_name: "Edgerton",
  },
  {
    postal_code: "04282",
    full_city_name: "Turner, ME",
    city_name: "Turner",
  },
  {
    postal_code: "32666",
    full_city_name: "Melrose, FL",
    city_name: "Melrose",
  },
  {
    postal_code: "97004",
    full_city_name: "Beavercreek, OR",
    city_name: "Beavercreek",
  },
  {
    postal_code: "48462",
    full_city_name: "Ortonville, MI",
    city_name: "Ortonville",
  },
  {
    postal_code: "06752",
    full_city_name: "Bridgewater, CT",
    city_name: "Bridgewater",
  },
  {
    postal_code: "29510",
    full_city_name: "Andrews, SC",
    city_name: "Andrews",
  },
  {
    postal_code: "40468",
    full_city_name: "Perryville, KY",
    city_name: "Perryville",
  },
  {
    postal_code: "12871",
    full_city_name: "Schuylerville, NY",
    city_name: "Schuylerville",
  },
  {
    postal_code: "48383",
    full_city_name: "White Lake, MI",
    city_name: "White Lake",
  },
  {
    postal_code: "05906",
    full_city_name: "Lunenburg, VT",
    city_name: "Lunenburg",
  },
  {
    postal_code: "56373",
    full_city_name: "Royalton, MN",
    city_name: "Royalton",
  },
  {
    postal_code: "36352",
    full_city_name: "Newton, AL",
    city_name: "Newton",
  },
  {
    postal_code: "12136",
    full_city_name: "Old Chatham, NY",
    city_name: "Old Chatham",
  },
  {
    postal_code: "28705",
    full_city_name: "Bakersville, NC",
    city_name: "Bakersville",
  },
  {
    postal_code: "31408",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "16921",
    full_city_name: "Gaines, PA",
    city_name: "Gaines",
  },
  {
    postal_code: "12979",
    full_city_name: "Rouses Point, NY",
    city_name: "Rouses Point",
  },
  {
    postal_code: "37101",
    full_city_name: "Mc Ewen, TN",
    city_name: "Mc Ewen",
  },
  {
    postal_code: "12944",
    full_city_name: "Keeseville, NY",
    city_name: "Keeseville",
  },
  {
    postal_code: "52301",
    full_city_name: "Marengo, IA",
    city_name: "Marengo",
  },
  {
    postal_code: "01841",
    full_city_name: "Lawrence, MA",
    city_name: "Lawrence",
  },
  {
    postal_code: "44618",
    full_city_name: "Dalton, OH",
    city_name: "Dalton",
  },
  {
    postal_code: "65680",
    full_city_name: "Kissee Mills, MO",
    city_name: "Kissee Mills",
  },
  {
    postal_code: "44235",
    full_city_name: "Homerville, OH",
    city_name: "Homerville",
  },
  {
    postal_code: "68463",
    full_city_name: "Weeping Water, NE",
    city_name: "Weeping Water",
  },
  {
    postal_code: "17529",
    full_city_name: "Gordonville, PA",
    city_name: "Gordonville",
  },
  {
    postal_code: "56477",
    full_city_name: "Sebeka, MN",
    city_name: "Sebeka",
  },
  {
    postal_code: "70444",
    full_city_name: "Kentwood, LA",
    city_name: "Kentwood",
  },
  {
    postal_code: "55964",
    full_city_name: "Plainview, MN",
    city_name: "Plainview",
  },
  {
    postal_code: "13730",
    full_city_name: "Afton, NY",
    city_name: "Afton",
  },
  {
    postal_code: "21830",
    full_city_name: "Hebron, MD",
    city_name: "Hebron",
  },
  {
    postal_code: "03581",
    full_city_name: "Gorham, NH",
    city_name: "Gorham",
  },
  {
    postal_code: "48744",
    full_city_name: "Mayville, MI",
    city_name: "Mayville",
  },
  {
    postal_code: "36272",
    full_city_name: "Piedmont, AL",
    city_name: "Piedmont",
  },
  {
    postal_code: "39216",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "90201",
    full_city_name: "Bell Gardens, CA",
    city_name: "Bell Gardens",
  },
  {
    postal_code: "04037",
    full_city_name: "Fryeburg, ME",
    city_name: "Fryeburg",
  },
  {
    postal_code: "06018",
    full_city_name: "Canaan, CT",
    city_name: "Canaan",
  },
  {
    postal_code: "93286",
    full_city_name: "Woodlake, CA",
    city_name: "Woodlake",
  },
  {
    postal_code: "48895",
    full_city_name: "Williamston, MI",
    city_name: "Williamston",
  },
  {
    postal_code: "41092",
    full_city_name: "Verona, KY",
    city_name: "Verona",
  },
  {
    postal_code: "01821",
    full_city_name: "Billerica, MA",
    city_name: "Billerica",
  },
  {
    postal_code: "02188",
    full_city_name: "Weymouth, MA",
    city_name: "Weymouth",
  },
  {
    postal_code: "55072",
    full_city_name: "Sandstone, MN",
    city_name: "Sandstone",
  },
  {
    postal_code: "93647",
    full_city_name: "Orosi, CA",
    city_name: "Orosi",
  },
  {
    postal_code: "80720",
    full_city_name: "Akron, CO",
    city_name: "Akron",
  },
  {
    postal_code: "37656",
    full_city_name: "Fall Branch, TN",
    city_name: "Fall Branch",
  },
  {
    postal_code: "47993",
    full_city_name: "Williamsport, IN",
    city_name: "Williamsport",
  },
  {
    postal_code: "95919",
    full_city_name: "Brownsville, CA",
    city_name: "Brownsville",
  },
  {
    postal_code: "52339",
    full_city_name: "Tama, IA",
    city_name: "Tama",
  },
  {
    postal_code: "75057",
    full_city_name: "Lewisville, TX",
    city_name: "Lewisville",
  },
  {
    postal_code: "44688",
    full_city_name: "Waynesburg, OH",
    city_name: "Waynesburg",
  },
  {
    postal_code: "59011",
    full_city_name: "Big Timber, MT",
    city_name: "Big Timber",
  },
  {
    postal_code: "28531",
    full_city_name: "Harkers Island, NC",
    city_name: "Harkers Island",
  },
  {
    postal_code: "07803",
    full_city_name: "Mine Hill, NJ",
    city_name: "Mine Hill",
  },
  {
    postal_code: "36250",
    full_city_name: "Alexandria, AL",
    city_name: "Alexandria",
  },
  {
    postal_code: "60518",
    full_city_name: "Earlville, IL",
    city_name: "Earlville",
  },
  {
    postal_code: "93701",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "43316",
    full_city_name: "Carey, OH",
    city_name: "Carey",
  },
  {
    postal_code: "53520",
    full_city_name: "Brodhead, WI",
    city_name: "Brodhead",
  },
  {
    postal_code: "02472",
    full_city_name: "Watertown, MA",
    city_name: "Watertown",
  },
  {
    postal_code: "41063",
    full_city_name: "Morning View, KY",
    city_name: "Morning View",
  },
  {
    postal_code: "86017",
    full_city_name: "Munds Park, AZ",
    city_name: "Munds Park",
  },
  {
    postal_code: "38367",
    full_city_name: "Ramer, TN",
    city_name: "Ramer",
  },
  {
    postal_code: "45144",
    full_city_name: "Manchester, OH",
    city_name: "Manchester",
  },
  {
    postal_code: "15622",
    full_city_name: "Champion, PA",
    city_name: "Champion",
  },
  {
    postal_code: "12149",
    full_city_name: "Richmondville, NY",
    city_name: "Richmondville",
  },
  {
    postal_code: "18434",
    full_city_name: "Jessup, PA",
    city_name: "Jessup",
  },
  {
    postal_code: "59436",
    full_city_name: "Fairfield, MT",
    city_name: "Fairfield",
  },
  {
    postal_code: "21904",
    full_city_name: "Port Deposit, MD",
    city_name: "Port Deposit",
  },
  {
    postal_code: "03234",
    full_city_name: "Epsom, NH",
    city_name: "Epsom",
  },
  {
    postal_code: "44437",
    full_city_name: "Mc Donald, OH",
    city_name: "Mc Donald",
  },
  {
    postal_code: "43938",
    full_city_name: "Mingo Junction, OH",
    city_name: "Mingo Junction",
  },
  {
    postal_code: "95633",
    full_city_name: "Garden Valley, CA",
    city_name: "Garden Valley",
  },
  {
    postal_code: "05408",
    full_city_name: "Burlington, VT",
    city_name: "Burlington",
  },
  {
    postal_code: "01095",
    full_city_name: "Wilbraham, MA",
    city_name: "Wilbraham",
  },
  {
    postal_code: "06419",
    full_city_name: "Killingworth, CT",
    city_name: "Killingworth",
  },
  {
    postal_code: "06455",
    full_city_name: "Middlefield, CT",
    city_name: "Middlefield",
  },
  {
    postal_code: "93103",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "12501",
    full_city_name: "Amenia, NY",
    city_name: "Amenia",
  },
  {
    postal_code: "61813",
    full_city_name: "Bement, IL",
    city_name: "Bement",
  },
  {
    postal_code: "03237",
    full_city_name: "Gilmanton, NH",
    city_name: "Gilmanton",
  },
  {
    postal_code: "61736",
    full_city_name: "Downs, IL",
    city_name: "Downs",
  },
  {
    postal_code: "47150",
    full_city_name: "New Albany, IN",
    city_name: "New Albany",
  },
  {
    postal_code: "12173",
    full_city_name: "Stuyvesant, NY",
    city_name: "Stuyvesant",
  },
  {
    postal_code: "48933",
    full_city_name: "Lansing, MI",
    city_name: "Lansing",
  },
  {
    postal_code: "24149",
    full_city_name: "Riner, VA",
    city_name: "Riner",
  },
  {
    postal_code: "45383",
    full_city_name: "West Milton, OH",
    city_name: "West Milton",
  },
  {
    postal_code: "13833",
    full_city_name: "Port Crane, NY",
    city_name: "Port Crane",
  },
  {
    postal_code: "38451",
    full_city_name: "Culleoka, TN",
    city_name: "Culleoka",
  },
  {
    postal_code: "54539",
    full_city_name: "Lake Tomahawk, WI",
    city_name: "Lake Tomahawk",
  },
  {
    postal_code: "03037",
    full_city_name: "Deerfield, NH",
    city_name: "Deerfield",
  },
  {
    postal_code: "61956",
    full_city_name: "Villa Grove, IL",
    city_name: "Villa Grove",
  },
  {
    postal_code: "61536",
    full_city_name: "Hanna City, IL",
    city_name: "Hanna City",
  },
  {
    postal_code: "55397",
    full_city_name: "Young America, MN",
    city_name: "Young America",
  },
  {
    postal_code: "37035",
    full_city_name: "Chapmansboro, TN",
    city_name: "Chapmansboro",
  },
  {
    postal_code: "12592",
    full_city_name: "Wassaic, NY",
    city_name: "Wassaic",
  },
  {
    postal_code: "14171",
    full_city_name: "West Valley, NY",
    city_name: "West Valley",
  },
  {
    postal_code: "98601",
    full_city_name: "Amboy, WA",
    city_name: "Amboy",
  },
  {
    postal_code: "75708",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "43724",
    full_city_name: "Caldwell, OH",
    city_name: "Caldwell",
  },
  {
    postal_code: "55384",
    full_city_name: "Spring Park, MN",
    city_name: "Spring Park",
  },
  {
    postal_code: "56455",
    full_city_name: "Ironton, MN",
    city_name: "Ironton",
  },
  {
    postal_code: "12052",
    full_city_name: "Cropseyville, NY",
    city_name: "Cropseyville",
  },
  {
    postal_code: "39459",
    full_city_name: "Moselle, MS",
    city_name: "Moselle",
  },
  {
    postal_code: "95134",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "25045",
    full_city_name: "Clendenin, WV",
    city_name: "Clendenin",
  },
  {
    postal_code: "43155",
    full_city_name: "Sugar Grove, OH",
    city_name: "Sugar Grove",
  },
  {
    postal_code: "56438",
    full_city_name: "Browerville, MN",
    city_name: "Browerville",
  },
  {
    postal_code: "53503",
    full_city_name: "Arena, WI",
    city_name: "Arena",
  },
  {
    postal_code: "60940",
    full_city_name: "Grant Park, IL",
    city_name: "Grant Park",
  },
  {
    postal_code: "45697",
    full_city_name: "Winchester, OH",
    city_name: "Winchester",
  },
  {
    postal_code: "83849",
    full_city_name: "Osburn, ID",
    city_name: "Osburn",
  },
  {
    postal_code: "12084",
    full_city_name: "Guilderland, NY",
    city_name: "Guilderland",
  },
  {
    postal_code: "43003",
    full_city_name: "Ashley, OH",
    city_name: "Ashley",
  },
  {
    postal_code: "53911",
    full_city_name: "Arlington, WI",
    city_name: "Arlington",
  },
  {
    postal_code: "49751",
    full_city_name: "Johannesburg, MI",
    city_name: "Johannesburg",
  },
  {
    postal_code: "45858",
    full_city_name: "Mc Comb, OH",
    city_name: "Mc Comb",
  },
  {
    postal_code: "03034",
    full_city_name: "Candia, NH",
    city_name: "Candia",
  },
  {
    postal_code: "27976",
    full_city_name: "South Mills, NC",
    city_name: "South Mills",
  },
  {
    postal_code: "45368",
    full_city_name: "South Charleston, OH",
    city_name: "South Charleston",
  },
  {
    postal_code: "10004",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "60552",
    full_city_name: "Somonauk, IL",
    city_name: "Somonauk",
  },
  {
    postal_code: "12581",
    full_city_name: "Stanfordville, NY",
    city_name: "Stanfordville",
  },
  {
    postal_code: "46327",
    full_city_name: "Hammond, IN",
    city_name: "Hammond",
  },
  {
    postal_code: "61752",
    full_city_name: "Le Roy, IL",
    city_name: "Le Roy",
  },
  {
    postal_code: "01863",
    full_city_name: "North Chelmsford, MA",
    city_name: "North Chelmsford",
  },
  {
    postal_code: "58368",
    full_city_name: "Rugby, ND",
    city_name: "Rugby",
  },
  {
    postal_code: "62231",
    full_city_name: "Carlyle, IL",
    city_name: "Carlyle",
  },
  {
    postal_code: "44451",
    full_city_name: "North Jackson, OH",
    city_name: "North Jackson",
  },
  {
    postal_code: "75472",
    full_city_name: "Point, TX",
    city_name: "Point",
  },
  {
    postal_code: "01568",
    full_city_name: "Upton, MA",
    city_name: "Upton",
  },
  {
    postal_code: "02721",
    full_city_name: "Fall River, MA",
    city_name: "Fall River",
  },
  {
    postal_code: "06234",
    full_city_name: "Brooklyn, CT",
    city_name: "Brooklyn",
  },
  {
    postal_code: "17063",
    full_city_name: "Milroy, PA",
    city_name: "Milroy",
  },
  {
    postal_code: "02184",
    full_city_name: "Braintree, MA",
    city_name: "Braintree",
  },
  {
    postal_code: "17560",
    full_city_name: "New Providence, PA",
    city_name: "New Providence",
  },
  {
    postal_code: "06092",
    full_city_name: "West Simsbury, CT",
    city_name: "West Simsbury",
  },
  {
    postal_code: "94904",
    full_city_name: "Greenbrae, CA",
    city_name: "Greenbrae",
  },
  {
    postal_code: "61001",
    full_city_name: "Apple River, IL",
    city_name: "Apple River",
  },
  {
    postal_code: "13648",
    full_city_name: "Harrisville, NY",
    city_name: "Harrisville",
  },
  {
    postal_code: "23890",
    full_city_name: "Waverly, VA",
    city_name: "Waverly",
  },
  {
    postal_code: "61546",
    full_city_name: "Manito, IL",
    city_name: "Manito",
  },
  {
    postal_code: "54129",
    full_city_name: "Hilbert, WI",
    city_name: "Hilbert",
  },
  {
    postal_code: "26034",
    full_city_name: "Chester, WV",
    city_name: "Chester",
  },
  {
    postal_code: "49249",
    full_city_name: "Jerome, MI",
    city_name: "Jerome",
  },
  {
    postal_code: "88317",
    full_city_name: "Cloudcroft, NM",
    city_name: "Cloudcroft",
  },
  {
    postal_code: "43107",
    full_city_name: "Bremen, OH",
    city_name: "Bremen",
  },
  {
    postal_code: "49730",
    full_city_name: "Elmira, MI",
    city_name: "Elmira",
  },
  {
    postal_code: "02045",
    full_city_name: "Hull, MA",
    city_name: "Hull",
  },
  {
    postal_code: "13605",
    full_city_name: "Adams, NY",
    city_name: "Adams",
  },
  {
    postal_code: "63037",
    full_city_name: "Gerald, MO",
    city_name: "Gerald",
  },
  {
    postal_code: "12958",
    full_city_name: "Mooers, NY",
    city_name: "Mooers",
  },
  {
    postal_code: "90701",
    full_city_name: "Artesia, CA",
    city_name: "Artesia",
  },
  {
    postal_code: "55360",
    full_city_name: "Mayer, MN",
    city_name: "Mayer",
  },
  {
    postal_code: "02777",
    full_city_name: "Swansea, MA",
    city_name: "Swansea",
  },
  {
    postal_code: "03220",
    full_city_name: "Belmont, NH",
    city_name: "Belmont",
  },
  {
    postal_code: "74119",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "77465",
    full_city_name: "Palacios, TX",
    city_name: "Palacios",
  },
  {
    postal_code: "78596",
    full_city_name: "Weslaco, TX",
    city_name: "Weslaco",
  },
  {
    postal_code: "61748",
    full_city_name: "Hudson, IL",
    city_name: "Hudson",
  },
  {
    postal_code: "55120",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "61046",
    full_city_name: "Lanark, IL",
    city_name: "Lanark",
  },
  {
    postal_code: "02453",
    full_city_name: "Waltham, MA",
    city_name: "Waltham",
  },
  {
    postal_code: "32347",
    full_city_name: "Perry, FL",
    city_name: "Perry",
  },
  {
    postal_code: "66043",
    full_city_name: "Lansing, KS",
    city_name: "Lansing",
  },
  {
    postal_code: "05458",
    full_city_name: "Grand Isle, VT",
    city_name: "Grand Isle",
  },
  {
    postal_code: "65667",
    full_city_name: "Hartville, MO",
    city_name: "Hartville",
  },
  {
    postal_code: "16833",
    full_city_name: "Curwensville, PA",
    city_name: "Curwensville",
  },
  {
    postal_code: "20622",
    full_city_name: "Charlotte Hall, MD",
    city_name: "Charlotte Hall",
  },
  {
    postal_code: "77534",
    full_city_name: "Danbury, TX",
    city_name: "Danbury",
  },
  {
    postal_code: "06278",
    full_city_name: "Ashford, CT",
    city_name: "Ashford",
  },
  {
    postal_code: "95672",
    full_city_name: "Rescue, CA",
    city_name: "Rescue",
  },
  {
    postal_code: "45135",
    full_city_name: "Leesburg, OH",
    city_name: "Leesburg",
  },
  {
    postal_code: "65556",
    full_city_name: "Richland, MO",
    city_name: "Richland",
  },
  {
    postal_code: "31321",
    full_city_name: "Pembroke, GA",
    city_name: "Pembroke",
  },
  {
    postal_code: "40051",
    full_city_name: "New Haven, KY",
    city_name: "New Haven",
  },
  {
    postal_code: "96064",
    full_city_name: "Montague, CA",
    city_name: "Montague",
  },
  {
    postal_code: "28790",
    full_city_name: "Zirconia, NC",
    city_name: "Zirconia",
  },
  {
    postal_code: "14840",
    full_city_name: "Hammondsport, NY",
    city_name: "Hammondsport",
  },
  {
    postal_code: "10452",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "93285",
    full_city_name: "Wofford Heights, CA",
    city_name: "Wofford Heights",
  },
  {
    postal_code: "43713",
    full_city_name: "Barnesville, OH",
    city_name: "Barnesville",
  },
  {
    postal_code: "10069",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "20685",
    full_city_name: "Saint Leonard, MD",
    city_name: "Saint Leonard",
  },
  {
    postal_code: "98236",
    full_city_name: "Clinton, WA",
    city_name: "Clinton",
  },
  {
    postal_code: "02171",
    full_city_name: "Quincy, MA",
    city_name: "Quincy",
  },
  {
    postal_code: "49224",
    full_city_name: "Albion, MI",
    city_name: "Albion",
  },
  {
    postal_code: "46392",
    full_city_name: "Wheatfield, IN",
    city_name: "Wheatfield",
  },
  {
    postal_code: "96748",
    full_city_name: "Kaunakakai, HI",
    city_name: "Kaunakakai",
  },
  {
    postal_code: "32112",
    full_city_name: "Crescent City, FL",
    city_name: "Crescent City",
  },
  {
    postal_code: "08070",
    full_city_name: "Pennsville, NJ",
    city_name: "Pennsville",
  },
  {
    postal_code: "11516",
    full_city_name: "Cedarhurst, NY",
    city_name: "Cedarhurst",
  },
  {
    postal_code: "02026",
    full_city_name: "Dedham, MA",
    city_name: "Dedham",
  },
  {
    postal_code: "04102",
    full_city_name: "Portland, ME",
    city_name: "Portland",
  },
  {
    postal_code: "15323",
    full_city_name: "Claysville, PA",
    city_name: "Claysville",
  },
  {
    postal_code: "26187",
    full_city_name: "Williamstown, WV",
    city_name: "Williamstown",
  },
  {
    postal_code: "32134",
    full_city_name: "Fort Mc Coy, FL",
    city_name: "Fort Mc Coy",
  },
  {
    postal_code: "03451",
    full_city_name: "Hinsdale, NH",
    city_name: "Hinsdale",
  },
  {
    postal_code: "11769",
    full_city_name: "Oakdale, NY",
    city_name: "Oakdale",
  },
  {
    postal_code: "52772",
    full_city_name: "Tipton, IA",
    city_name: "Tipton",
  },
  {
    postal_code: "79355",
    full_city_name: "Plains, TX",
    city_name: "Plains",
  },
  {
    postal_code: "54111",
    full_city_name: "Cecil, WI",
    city_name: "Cecil",
  },
  {
    postal_code: "53574",
    full_city_name: "New Glarus, WI",
    city_name: "New Glarus",
  },
  {
    postal_code: "03869",
    full_city_name: "Rollinsford, NH",
    city_name: "Rollinsford",
  },
  {
    postal_code: "78056",
    full_city_name: "Mico, TX",
    city_name: "Mico",
  },
  {
    postal_code: "45631",
    full_city_name: "Gallipolis, OH",
    city_name: "Gallipolis",
  },
  {
    postal_code: "41030",
    full_city_name: "Crittenden, KY",
    city_name: "Crittenden",
  },
  {
    postal_code: "13108",
    full_city_name: "Marcellus, NY",
    city_name: "Marcellus",
  },
  {
    postal_code: "75117",
    full_city_name: "Edgewood, TX",
    city_name: "Edgewood",
  },
  {
    postal_code: "33471",
    full_city_name: "Moore Haven, FL",
    city_name: "Moore Haven",
  },
  {
    postal_code: "56576",
    full_city_name: "Richville, MN",
    city_name: "Richville",
  },
  {
    postal_code: "06870",
    full_city_name: "Old Greenwich, CT",
    city_name: "Old Greenwich",
  },
  {
    postal_code: "25813",
    full_city_name: "Beaver, WV",
    city_name: "Beaver",
  },
  {
    postal_code: "01460",
    full_city_name: "Littleton, MA",
    city_name: "Littleton",
  },
  {
    postal_code: "03836",
    full_city_name: "Freedom, NH",
    city_name: "Freedom",
  },
  {
    postal_code: "98841",
    full_city_name: "Omak, WA",
    city_name: "Omak",
  },
  {
    postal_code: "70711",
    full_city_name: "Albany, LA",
    city_name: "Albany",
  },
  {
    postal_code: "78405",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "72833",
    full_city_name: "Danville, AR",
    city_name: "Danville",
  },
  {
    postal_code: "01510",
    full_city_name: "Clinton, MA",
    city_name: "Clinton",
  },
  {
    postal_code: "65704",
    full_city_name: "Mansfield, MO",
    city_name: "Mansfield",
  },
  {
    postal_code: "55382",
    full_city_name: "South Haven, MN",
    city_name: "South Haven",
  },
  {
    postal_code: "56481",
    full_city_name: "Verndale, MN",
    city_name: "Verndale",
  },
  {
    postal_code: "83287",
    full_city_name: "Fish Haven, ID",
    city_name: "Fish Haven",
  },
  {
    postal_code: "14812",
    full_city_name: "Beaver Dams, NY",
    city_name: "Beaver Dams",
  },
  {
    postal_code: "76225",
    full_city_name: "Alvord, TX",
    city_name: "Alvord",
  },
  {
    postal_code: "19033",
    full_city_name: "Folsom, PA",
    city_name: "Folsom",
  },
  {
    postal_code: "44021",
    full_city_name: "Burton, OH",
    city_name: "Burton",
  },
  {
    postal_code: "45308",
    full_city_name: "Bradford, OH",
    city_name: "Bradford",
  },
  {
    postal_code: "40370",
    full_city_name: "Sadieville, KY",
    city_name: "Sadieville",
  },
  {
    postal_code: "03285",
    full_city_name: "Thornton, NH",
    city_name: "Thornton",
  },
  {
    postal_code: "13224",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "54968",
    full_city_name: "Princeton, WI",
    city_name: "Princeton",
  },
  {
    postal_code: "24248",
    full_city_name: "Ewing, VA",
    city_name: "Ewing",
  },
  {
    postal_code: "12859",
    full_city_name: "Porter Corners, NY",
    city_name: "Porter Corners",
  },
  {
    postal_code: "76437",
    full_city_name: "Cisco, TX",
    city_name: "Cisco",
  },
  {
    postal_code: "56359",
    full_city_name: "Onamia, MN",
    city_name: "Onamia",
  },
  {
    postal_code: "49631",
    full_city_name: "Evart, MI",
    city_name: "Evart",
  },
  {
    postal_code: "05663",
    full_city_name: "Northfield, VT",
    city_name: "Northfield",
  },
  {
    postal_code: "61080",
    full_city_name: "South Beloit, IL",
    city_name: "South Beloit",
  },
  {
    postal_code: "15067",
    full_city_name: "New Eagle, PA",
    city_name: "New Eagle",
  },
  {
    postal_code: "05089",
    full_city_name: "Windsor, VT",
    city_name: "Windsor",
  },
  {
    postal_code: "03064",
    full_city_name: "Nashua, NH",
    city_name: "Nashua",
  },
  {
    postal_code: "12903",
    full_city_name: "Plattsburgh, NY",
    city_name: "Plattsburgh",
  },
  {
    postal_code: "89439",
    full_city_name: "Verdi, NV",
    city_name: "Verdi",
  },
  {
    postal_code: "60071",
    full_city_name: "Richmond, IL",
    city_name: "Richmond",
  },
  {
    postal_code: "32744",
    full_city_name: "Lake Helen, FL",
    city_name: "Lake Helen",
  },
  {
    postal_code: "49461",
    full_city_name: "Whitehall, MI",
    city_name: "Whitehall",
  },
  {
    postal_code: "04364",
    full_city_name: "Winthrop, ME",
    city_name: "Winthrop",
  },
  {
    postal_code: "03904",
    full_city_name: "Kittery, ME",
    city_name: "Kittery",
  },
  {
    postal_code: "19311",
    full_city_name: "Avondale, PA",
    city_name: "Avondale",
  },
  {
    postal_code: "17236",
    full_city_name: "Mercersburg, PA",
    city_name: "Mercersburg",
  },
  {
    postal_code: "98548",
    full_city_name: "Hoodsport, WA",
    city_name: "Hoodsport",
  },
  {
    postal_code: "78208",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "13142",
    full_city_name: "Pulaski, NY",
    city_name: "Pulaski",
  },
  {
    postal_code: "13622",
    full_city_name: "Chaumont, NY",
    city_name: "Chaumont",
  },
  {
    postal_code: "12578",
    full_city_name: "Salt Point, NY",
    city_name: "Salt Point",
  },
  {
    postal_code: "01440",
    full_city_name: "Gardner, MA",
    city_name: "Gardner",
  },
  {
    postal_code: "06281",
    full_city_name: "Woodstock, CT",
    city_name: "Woodstock",
  },
  {
    postal_code: "44442",
    full_city_name: "New Middletown, OH",
    city_name: "New Middletown",
  },
  {
    postal_code: "43143",
    full_city_name: "Mount Sterling, OH",
    city_name: "Mount Sterling",
  },
  {
    postal_code: "12059",
    full_city_name: "East Berne, NY",
    city_name: "East Berne",
  },
  {
    postal_code: "28510",
    full_city_name: "Arapahoe, NC",
    city_name: "Arapahoe",
  },
  {
    postal_code: "37873",
    full_city_name: "Surgoinsville, TN",
    city_name: "Surgoinsville",
  },
  {
    postal_code: "16828",
    full_city_name: "Centre Hall, PA",
    city_name: "Centre Hall",
  },
  {
    postal_code: "26170",
    full_city_name: "Saint Marys, WV",
    city_name: "Saint Marys",
  },
  {
    postal_code: "28032",
    full_city_name: "Cramerton, NC",
    city_name: "Cramerton",
  },
  {
    postal_code: "06375",
    full_city_name: "Quaker Hill, CT",
    city_name: "Quaker Hill",
  },
  {
    postal_code: "17702",
    full_city_name: "Williamsport, PA",
    city_name: "Williamsport",
  },
  {
    postal_code: "13030",
    full_city_name: "Bridgeport, NY",
    city_name: "Bridgeport",
  },
  {
    postal_code: "02136",
    full_city_name: "Hyde Park, MA",
    city_name: "Hyde Park",
  },
  {
    postal_code: "62260",
    full_city_name: "Millstadt, IL",
    city_name: "Millstadt",
  },
  {
    postal_code: "97498",
    full_city_name: "Yachats, OR",
    city_name: "Yachats",
  },
  {
    postal_code: "29472",
    full_city_name: "Ridgeville, SC",
    city_name: "Ridgeville",
  },
  {
    postal_code: "78013",
    full_city_name: "Comfort, TX",
    city_name: "Comfort",
  },
  {
    postal_code: "44507",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "08824",
    full_city_name: "Kendall Park, NJ",
    city_name: "Kendall Park",
  },
  {
    postal_code: "53094",
    full_city_name: "Watertown, WI",
    city_name: "Watertown",
  },
  {
    postal_code: "27863",
    full_city_name: "Pikeville, NC",
    city_name: "Pikeville",
  },
  {
    postal_code: "01923",
    full_city_name: "Danvers, MA",
    city_name: "Danvers",
  },
  {
    postal_code: "10473",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "10456",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "95932",
    full_city_name: "Colusa, CA",
    city_name: "Colusa",
  },
  {
    postal_code: "02445",
    full_city_name: "Brookline, MA",
    city_name: "Brookline",
  },
  {
    postal_code: "12072",
    full_city_name: "Fultonville, NY",
    city_name: "Fultonville",
  },
  {
    postal_code: "70129",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "72958",
    full_city_name: "Waldron, AR",
    city_name: "Waldron",
  },
  {
    postal_code: "79036",
    full_city_name: "Fritch, TX",
    city_name: "Fritch",
  },
  {
    postal_code: "94515",
    full_city_name: "Calistoga, CA",
    city_name: "Calistoga",
  },
  {
    postal_code: "56623",
    full_city_name: "Baudette, MN",
    city_name: "Baudette",
  },
  {
    postal_code: "75602",
    full_city_name: "Longview, TX",
    city_name: "Longview",
  },
  {
    postal_code: "31523",
    full_city_name: "Brunswick, GA",
    city_name: "Brunswick",
  },
  {
    postal_code: "95982",
    full_city_name: "Sutter, CA",
    city_name: "Sutter",
  },
  {
    postal_code: "11104",
    full_city_name: "Sunnyside, NY",
    city_name: "Sunnyside",
  },
  {
    postal_code: "56444",
    full_city_name: "Deerwood, MN",
    city_name: "Deerwood",
  },
  {
    postal_code: "04441",
    full_city_name: "Greenville, ME",
    city_name: "Greenville",
  },
  {
    postal_code: "17018",
    full_city_name: "Dauphin, PA",
    city_name: "Dauphin",
  },
  {
    postal_code: "54499",
    full_city_name: "Wittenberg, WI",
    city_name: "Wittenberg",
  },
  {
    postal_code: "16830",
    full_city_name: "Clearfield, PA",
    city_name: "Clearfield",
  },
  {
    postal_code: "67543",
    full_city_name: "Haven, KS",
    city_name: "Haven",
  },
  {
    postal_code: "17094",
    full_city_name: "Thompsontown, PA",
    city_name: "Thompsontown",
  },
  {
    postal_code: "99170",
    full_city_name: "Rosalia, WA",
    city_name: "Rosalia",
  },
  {
    postal_code: "77013",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "04862",
    full_city_name: "Union, ME",
    city_name: "Union",
  },
  {
    postal_code: "11952",
    full_city_name: "Mattituck, NY",
    city_name: "Mattituck",
  },
  {
    postal_code: "01938",
    full_city_name: "Ipswich, MA",
    city_name: "Ipswich",
  },
  {
    postal_code: "95247",
    full_city_name: "Murphys, CA",
    city_name: "Murphys",
  },
  {
    postal_code: "33841",
    full_city_name: "Fort Meade, FL",
    city_name: "Fort Meade",
  },
  {
    postal_code: "42445",
    full_city_name: "Princeton, KY",
    city_name: "Princeton",
  },
  {
    postal_code: "94028",
    full_city_name: "Portola Valley, CA",
    city_name: "Portola Valley",
  },
  {
    postal_code: "54945",
    full_city_name: "Iola, WI",
    city_name: "Iola",
  },
  {
    postal_code: "32625",
    full_city_name: "Cedar Key, FL",
    city_name: "Cedar Key",
  },
  {
    postal_code: "56649",
    full_city_name: "International Falls, MN",
    city_name: "International Falls",
  },
  {
    postal_code: "56466",
    full_city_name: "Motley, MN",
    city_name: "Motley",
  },
  {
    postal_code: "61428",
    full_city_name: "Dahinda, IL",
    city_name: "Dahinda",
  },
  {
    postal_code: "35116",
    full_city_name: "Morris, AL",
    city_name: "Morris",
  },
  {
    postal_code: "01532",
    full_city_name: "Northborough, MA",
    city_name: "Northborough",
  },
  {
    postal_code: "44288",
    full_city_name: "Windham, OH",
    city_name: "Windham",
  },
  {
    postal_code: "42327",
    full_city_name: "Calhoun, KY",
    city_name: "Calhoun",
  },
  {
    postal_code: "45416",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "14204",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "35031",
    full_city_name: "Blountsville, AL",
    city_name: "Blountsville",
  },
  {
    postal_code: "78861",
    full_city_name: "Hondo, TX",
    city_name: "Hondo",
  },
  {
    postal_code: "84027",
    full_city_name: "Fruitland, UT",
    city_name: "Fruitland",
  },
  {
    postal_code: "02176",
    full_city_name: "Melrose, MA",
    city_name: "Melrose",
  },
  {
    postal_code: "95987",
    full_city_name: "Williams, CA",
    city_name: "Williams",
  },
  {
    postal_code: "42256",
    full_city_name: "Lewisburg, KY",
    city_name: "Lewisburg",
  },
  {
    postal_code: "44104",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "84071",
    full_city_name: "Stockton, UT",
    city_name: "Stockton",
  },
  {
    postal_code: "28395",
    full_city_name: "Wade, NC",
    city_name: "Wade",
  },
  {
    postal_code: "92276",
    full_city_name: "Thousand Palms, CA",
    city_name: "Thousand Palms",
  },
  {
    postal_code: "27052",
    full_city_name: "Walnut Cove, NC",
    city_name: "Walnut Cove",
  },
  {
    postal_code: "78140",
    full_city_name: "Nixon, TX",
    city_name: "Nixon",
  },
  {
    postal_code: "05851",
    full_city_name: "Lyndonville, VT",
    city_name: "Lyndonville",
  },
  {
    postal_code: "34705",
    full_city_name: "Astatula, FL",
    city_name: "Astatula",
  },
  {
    postal_code: "43348",
    full_city_name: "Russells Point, OH",
    city_name: "Russells Point",
  },
  {
    postal_code: "47954",
    full_city_name: "Ladoga, IN",
    city_name: "Ladoga",
  },
  {
    postal_code: "12075",
    full_city_name: "Ghent, NY",
    city_name: "Ghent",
  },
  {
    postal_code: "33476",
    full_city_name: "Pahokee, FL",
    city_name: "Pahokee",
  },
  {
    postal_code: "60034",
    full_city_name: "Hebron, IL",
    city_name: "Hebron",
  },
  {
    postal_code: "44311",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "40372",
    full_city_name: "Salvisa, KY",
    city_name: "Salvisa",
  },
  {
    postal_code: "29696",
    full_city_name: "West Union, SC",
    city_name: "West Union",
  },
  {
    postal_code: "25918",
    full_city_name: "Shady Spring, WV",
    city_name: "Shady Spring",
  },
  {
    postal_code: "27882",
    full_city_name: "Spring Hope, NC",
    city_name: "Spring Hope",
  },
  {
    postal_code: "19013",
    full_city_name: "Chester, PA",
    city_name: "Chester",
  },
  {
    postal_code: "59741",
    full_city_name: "Manhattan, MT",
    city_name: "Manhattan",
  },
  {
    postal_code: "68524",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "27557",
    full_city_name: "Middlesex, NC",
    city_name: "Middlesex",
  },
  {
    postal_code: "49098",
    full_city_name: "Watervliet, MI",
    city_name: "Watervliet",
  },
  {
    postal_code: "93601",
    full_city_name: "Ahwahnee, CA",
    city_name: "Ahwahnee",
  },
  {
    postal_code: "23027",
    full_city_name: "Cartersville, VA",
    city_name: "Cartersville",
  },
  {
    postal_code: "01904",
    full_city_name: "Lynn, MA",
    city_name: "Lynn",
  },
  {
    postal_code: "96137",
    full_city_name: "Westwood, CA",
    city_name: "Westwood",
  },
  {
    postal_code: "36264",
    full_city_name: "Heflin, AL",
    city_name: "Heflin",
  },
  {
    postal_code: "97385",
    full_city_name: "Sublimity, OR",
    city_name: "Sublimity",
  },
  {
    postal_code: "02638",
    full_city_name: "Dennis, MA",
    city_name: "Dennis",
  },
  {
    postal_code: "02837",
    full_city_name: "Little Compton, RI",
    city_name: "Little Compton",
  },
  {
    postal_code: "29924",
    full_city_name: "Hampton, SC",
    city_name: "Hampton",
  },
  {
    postal_code: "15144",
    full_city_name: "Springdale, PA",
    city_name: "Springdale",
  },
  {
    postal_code: "20197",
    full_city_name: "Waterford, VA",
    city_name: "Waterford",
  },
  {
    postal_code: "51652",
    full_city_name: "Sidney, IA",
    city_name: "Sidney",
  },
  {
    postal_code: "93562",
    full_city_name: "Trona, CA",
    city_name: "Trona",
  },
  {
    postal_code: "95464",
    full_city_name: "Nice, CA",
    city_name: "Nice",
  },
  {
    postal_code: "61944",
    full_city_name: "Paris, IL",
    city_name: "Paris",
  },
  {
    postal_code: "83237",
    full_city_name: "Franklin, ID",
    city_name: "Franklin",
  },
  {
    postal_code: "08020",
    full_city_name: "Clarksboro, NJ",
    city_name: "Clarksboro",
  },
  {
    postal_code: "43844",
    full_city_name: "Warsaw, OH",
    city_name: "Warsaw",
  },
  {
    postal_code: "40057",
    full_city_name: "Pleasureville, KY",
    city_name: "Pleasureville",
  },
  {
    postal_code: "03276",
    full_city_name: "Tilton, NH",
    city_name: "Tilton",
  },
  {
    postal_code: "44644",
    full_city_name: "Malvern, OH",
    city_name: "Malvern",
  },
  {
    postal_code: "27968",
    full_city_name: "Rodanthe, NC",
    city_name: "Rodanthe",
  },
  {
    postal_code: "80428",
    full_city_name: "Clark, CO",
    city_name: "Clark",
  },
  {
    postal_code: "77713",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "32064",
    full_city_name: "Live Oak, FL",
    city_name: "Live Oak",
  },
  {
    postal_code: "23608",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "59935",
    full_city_name: "Troy, MT",
    city_name: "Troy",
  },
  {
    postal_code: "13480",
    full_city_name: "Waterville, NY",
    city_name: "Waterville",
  },
  {
    postal_code: "62839",
    full_city_name: "Flora, IL",
    city_name: "Flora",
  },
  {
    postal_code: "25508",
    full_city_name: "Chapmanville, WV",
    city_name: "Chapmanville",
  },
  {
    postal_code: "86334",
    full_city_name: "Paulden, AZ",
    city_name: "Paulden",
  },
  {
    postal_code: "71351",
    full_city_name: "Marksville, LA",
    city_name: "Marksville",
  },
  {
    postal_code: "98337",
    full_city_name: "Bremerton, WA",
    city_name: "Bremerton",
  },
  {
    postal_code: "38008",
    full_city_name: "Bolivar, TN",
    city_name: "Bolivar",
  },
  {
    postal_code: "17756",
    full_city_name: "Muncy, PA",
    city_name: "Muncy",
  },
  {
    postal_code: "12023",
    full_city_name: "Berne, NY",
    city_name: "Berne",
  },
  {
    postal_code: "37324",
    full_city_name: "Decherd, TN",
    city_name: "Decherd",
  },
  {
    postal_code: "29684",
    full_city_name: "Starr, SC",
    city_name: "Starr",
  },
  {
    postal_code: "01027",
    full_city_name: "Easthampton, MA",
    city_name: "Easthampton",
  },
  {
    postal_code: "55101",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "61088",
    full_city_name: "Winnebago, IL",
    city_name: "Winnebago",
  },
  {
    postal_code: "24251",
    full_city_name: "Gate City, VA",
    city_name: "Gate City",
  },
  {
    postal_code: "02807",
    full_city_name: "Block Island, RI",
    city_name: "Block Island",
  },
  {
    postal_code: "63624",
    full_city_name: "Bismarck, MO",
    city_name: "Bismarck",
  },
  {
    postal_code: "15473",
    full_city_name: "Perryopolis, PA",
    city_name: "Perryopolis",
  },
  {
    postal_code: "44875",
    full_city_name: "Shelby, OH",
    city_name: "Shelby",
  },
  {
    postal_code: "21639",
    full_city_name: "Greensboro, MD",
    city_name: "Greensboro",
  },
  {
    postal_code: "83611",
    full_city_name: "Cascade, ID",
    city_name: "Cascade",
  },
  {
    postal_code: "05661",
    full_city_name: "Morrisville, VT",
    city_name: "Morrisville",
  },
  {
    postal_code: "04103",
    full_city_name: "Portland, ME",
    city_name: "Portland",
  },
  {
    postal_code: "77514",
    full_city_name: "Anahuac, TX",
    city_name: "Anahuac",
  },
  {
    postal_code: "55054",
    full_city_name: "Elko New Market, MN",
    city_name: "Elko New Market",
  },
  {
    postal_code: "54021",
    full_city_name: "Prescott, WI",
    city_name: "Prescott",
  },
  {
    postal_code: "61010",
    full_city_name: "Byron, IL",
    city_name: "Byron",
  },
  {
    postal_code: "42718",
    full_city_name: "Campbellsville, KY",
    city_name: "Campbellsville",
  },
  {
    postal_code: "61764",
    full_city_name: "Pontiac, IL",
    city_name: "Pontiac",
  },
  {
    postal_code: "61348",
    full_city_name: "Oglesby, IL",
    city_name: "Oglesby",
  },
  {
    postal_code: "93737",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "45694",
    full_city_name: "Wheelersburg, OH",
    city_name: "Wheelersburg",
  },
  {
    postal_code: "01089",
    full_city_name: "West Springfield, MA",
    city_name: "West Springfield",
  },
  {
    postal_code: "84111",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "94123",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "94511",
    full_city_name: "Bethel Island, CA",
    city_name: "Bethel Island",
  },
  {
    postal_code: "32320",
    full_city_name: "Apalachicola, FL",
    city_name: "Apalachicola",
  },
  {
    postal_code: "13082",
    full_city_name: "Kirkville, NY",
    city_name: "Kirkville",
  },
  {
    postal_code: "62254",
    full_city_name: "Lebanon, IL",
    city_name: "Lebanon",
  },
  {
    postal_code: "03592",
    full_city_name: "Pittsburg, NH",
    city_name: "Pittsburg",
  },
  {
    postal_code: "53523",
    full_city_name: "Cambridge, WI",
    city_name: "Cambridge",
  },
  {
    postal_code: "39209",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "62832",
    full_city_name: "Du Quoin, IL",
    city_name: "Du Quoin",
  },
  {
    postal_code: "02339",
    full_city_name: "Hanover, MA",
    city_name: "Hanover",
  },
  {
    postal_code: "61738",
    full_city_name: "El Paso, IL",
    city_name: "El Paso",
  },
  {
    postal_code: "11944",
    full_city_name: "Greenport, NY",
    city_name: "Greenport",
  },
  {
    postal_code: "60301",
    full_city_name: "Oak Park, IL",
    city_name: "Oak Park",
  },
  {
    postal_code: "48471",
    full_city_name: "Sandusky, MI",
    city_name: "Sandusky",
  },
  {
    postal_code: "81428",
    full_city_name: "Paonia, CO",
    city_name: "Paonia",
  },
  {
    postal_code: "70591",
    full_city_name: "Welsh, LA",
    city_name: "Welsh",
  },
  {
    postal_code: "77640",
    full_city_name: "Port Arthur, TX",
    city_name: "Port Arthur",
  },
  {
    postal_code: "55808",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "28463",
    full_city_name: "Tabor City, NC",
    city_name: "Tabor City",
  },
  {
    postal_code: "98333",
    full_city_name: "Fox Island, WA",
    city_name: "Fox Island",
  },
  {
    postal_code: "55019",
    full_city_name: "Dundas, MN",
    city_name: "Dundas",
  },
  {
    postal_code: "47842",
    full_city_name: "Clinton, IN",
    city_name: "Clinton",
  },
  {
    postal_code: "78934",
    full_city_name: "Columbus, TX",
    city_name: "Columbus",
  },
  {
    postal_code: "34488",
    full_city_name: "Silver Springs, FL",
    city_name: "Silver Springs",
  },
  {
    postal_code: "95728",
    full_city_name: "Soda Springs, CA",
    city_name: "Soda Springs",
  },
  {
    postal_code: "03773",
    full_city_name: "Newport, NH",
    city_name: "Newport",
  },
  {
    postal_code: "22620",
    full_city_name: "Boyce, VA",
    city_name: "Boyce",
  },
  {
    postal_code: "61054",
    full_city_name: "Mount Morris, IL",
    city_name: "Mount Morris",
  },
  {
    postal_code: "40906",
    full_city_name: "Barbourville, KY",
    city_name: "Barbourville",
  },
  {
    postal_code: "95045",
    full_city_name: "San Juan Bautista, CA",
    city_name: "San Juan Bautista",
  },
  {
    postal_code: "54738",
    full_city_name: "Eleva, WI",
    city_name: "Eleva",
  },
  {
    postal_code: "13077",
    full_city_name: "Homer, NY",
    city_name: "Homer",
  },
  {
    postal_code: "01527",
    full_city_name: "Millbury, MA",
    city_name: "Millbury",
  },
  {
    postal_code: "59859",
    full_city_name: "Plains, MT",
    city_name: "Plains",
  },
  {
    postal_code: "54538",
    full_city_name: "Lac Du Flambeau, WI",
    city_name: "Lac Du Flambeau",
  },
  {
    postal_code: "03235",
    full_city_name: "Franklin, NH",
    city_name: "Franklin",
  },
  {
    postal_code: "95497",
    full_city_name: "The Sea Ranch, CA",
    city_name: "The Sea Ranch",
  },
  {
    postal_code: "95368",
    full_city_name: "Salida, CA",
    city_name: "Salida",
  },
  {
    postal_code: "14132",
    full_city_name: "Sanborn, NY",
    city_name: "Sanborn",
  },
  {
    postal_code: "02124",
    full_city_name: "Dorchester Center, MA",
    city_name: "Dorchester Center",
  },
  {
    postal_code: "48205",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "76541",
    full_city_name: "Killeen, TX",
    city_name: "Killeen",
  },
  {
    postal_code: "24101",
    full_city_name: "Hardy, VA",
    city_name: "Hardy",
  },
  {
    postal_code: "16150",
    full_city_name: "Sharpsville, PA",
    city_name: "Sharpsville",
  },
  {
    postal_code: "32618",
    full_city_name: "Archer, FL",
    city_name: "Archer",
  },
  {
    postal_code: "75792",
    full_city_name: "Winona, TX",
    city_name: "Winona",
  },
  {
    postal_code: "04072",
    full_city_name: "Saco, ME",
    city_name: "Saco",
  },
  {
    postal_code: "44889",
    full_city_name: "Wakeman, OH",
    city_name: "Wakeman",
  },
  {
    postal_code: "03280",
    full_city_name: "Washington, NH",
    city_name: "Washington",
  },
  {
    postal_code: "01606",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "36274",
    full_city_name: "Roanoke, AL",
    city_name: "Roanoke",
  },
  {
    postal_code: "03812",
    full_city_name: "Bartlett, NH",
    city_name: "Bartlett",
  },
  {
    postal_code: "13145",
    full_city_name: "Sandy Creek, NY",
    city_name: "Sandy Creek",
  },
  {
    postal_code: "27041",
    full_city_name: "Pilot Mountain, NC",
    city_name: "Pilot Mountain",
  },
  {
    postal_code: "97448",
    full_city_name: "Junction City, OR",
    city_name: "Junction City",
  },
  {
    postal_code: "44287",
    full_city_name: "West Salem, OH",
    city_name: "West Salem",
  },
  {
    postal_code: "67665",
    full_city_name: "Russell, KS",
    city_name: "Russell",
  },
  {
    postal_code: "76245",
    full_city_name: "Gordonville, TX",
    city_name: "Gordonville",
  },
  {
    postal_code: "98361",
    full_city_name: "Packwood, WA",
    city_name: "Packwood",
  },
  {
    postal_code: "01742",
    full_city_name: "Concord, MA",
    city_name: "Concord",
  },
  {
    postal_code: "63023",
    full_city_name: "Dittmer, MO",
    city_name: "Dittmer",
  },
  {
    postal_code: "11024",
    full_city_name: "Great Neck, NY",
    city_name: "Great Neck",
  },
  {
    postal_code: "29431",
    full_city_name: "Bonneau, SC",
    city_name: "Bonneau",
  },
  {
    postal_code: "47918",
    full_city_name: "Attica, IN",
    city_name: "Attica",
  },
  {
    postal_code: "92071",
    full_city_name: "Santee, CA",
    city_name: "Santee",
  },
  {
    postal_code: "15697",
    full_city_name: "Youngwood, PA",
    city_name: "Youngwood",
  },
  {
    postal_code: "79501",
    full_city_name: "Anson, TX",
    city_name: "Anson",
  },
  {
    postal_code: "11964",
    full_city_name: "Shelter Island, NY",
    city_name: "Shelter Island",
  },
  {
    postal_code: "53910",
    full_city_name: "Adams, WI",
    city_name: "Adams",
  },
  {
    postal_code: "89411",
    full_city_name: "Genoa, NV",
    city_name: "Genoa",
  },
  {
    postal_code: "67546",
    full_city_name: "Inman, KS",
    city_name: "Inman",
  },
  {
    postal_code: "61606",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "46280",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "13348",
    full_city_name: "Hartwick, NY",
    city_name: "Hartwick",
  },
  {
    postal_code: "50225",
    full_city_name: "Pleasantville, IA",
    city_name: "Pleasantville",
  },
  {
    postal_code: "95365",
    full_city_name: "Planada, CA",
    city_name: "Planada",
  },
  {
    postal_code: "43821",
    full_city_name: "Dresden, OH",
    city_name: "Dresden",
  },
  {
    postal_code: "05255",
    full_city_name: "Manchester Center, VT",
    city_name: "Manchester Center",
  },
  {
    postal_code: "77435",
    full_city_name: "East Bernard, TX",
    city_name: "East Bernard",
  },
  {
    postal_code: "18355",
    full_city_name: "Scotrun, PA",
    city_name: "Scotrun",
  },
  {
    postal_code: "54531",
    full_city_name: "Hazelhurst, WI",
    city_name: "Hazelhurst",
  },
  {
    postal_code: "64402",
    full_city_name: "Albany, MO",
    city_name: "Albany",
  },
  {
    postal_code: "44010",
    full_city_name: "Austinburg, OH",
    city_name: "Austinburg",
  },
  {
    postal_code: "54922",
    full_city_name: "Bear Creek, WI",
    city_name: "Bear Creek",
  },
  {
    postal_code: "76272",
    full_city_name: "Valley View, TX",
    city_name: "Valley View",
  },
  {
    postal_code: "50070",
    full_city_name: "Dexter, IA",
    city_name: "Dexter",
  },
  {
    postal_code: "56633",
    full_city_name: "Cass Lake, MN",
    city_name: "Cass Lake",
  },
  {
    postal_code: "16346",
    full_city_name: "Seneca, PA",
    city_name: "Seneca",
  },
  {
    postal_code: "03839",
    full_city_name: "Rochester, NH",
    city_name: "Rochester",
  },
  {
    postal_code: "03223",
    full_city_name: "Campton, NH",
    city_name: "Campton",
  },
  {
    postal_code: "03262",
    full_city_name: "North Woodstock, NH",
    city_name: "North Woodstock",
  },
  {
    postal_code: "41040",
    full_city_name: "Falmouth, KY",
    city_name: "Falmouth",
  },
  {
    postal_code: "46030",
    full_city_name: "Arcadia, IN",
    city_name: "Arcadia",
  },
  {
    postal_code: "91731",
    full_city_name: "El Monte, CA",
    city_name: "El Monte",
  },
  {
    postal_code: "19365",
    full_city_name: "Parkesburg, PA",
    city_name: "Parkesburg",
  },
  {
    postal_code: "43105",
    full_city_name: "Baltimore, OH",
    city_name: "Baltimore",
  },
  {
    postal_code: "46312",
    full_city_name: "East Chicago, IN",
    city_name: "East Chicago",
  },
  {
    postal_code: "43240",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "62615",
    full_city_name: "Auburn, IL",
    city_name: "Auburn",
  },
  {
    postal_code: "52411",
    full_city_name: "Cedar Rapids, IA",
    city_name: "Cedar Rapids",
  },
  {
    postal_code: "54748",
    full_city_name: "Jim Falls, WI",
    city_name: "Jim Falls",
  },
  {
    postal_code: "01223",
    full_city_name: "Becket, MA",
    city_name: "Becket",
  },
  {
    postal_code: "27829",
    full_city_name: "Fountain, NC",
    city_name: "Fountain",
  },
  {
    postal_code: "44403",
    full_city_name: "Brookfield, OH",
    city_name: "Brookfield",
  },
  {
    postal_code: "27344",
    full_city_name: "Siler City, NC",
    city_name: "Siler City",
  },
  {
    postal_code: "11962",
    full_city_name: "Sagaponack, NY",
    city_name: "Sagaponack",
  },
  {
    postal_code: "21713",
    full_city_name: "Boonsboro, MD",
    city_name: "Boonsboro",
  },
  {
    postal_code: "48357",
    full_city_name: "Highland, MI",
    city_name: "Highland",
  },
  {
    postal_code: "13808",
    full_city_name: "Morris, NY",
    city_name: "Morris",
  },
  {
    postal_code: "99033",
    full_city_name: "Tekoa, WA",
    city_name: "Tekoa",
  },
  {
    postal_code: "37692",
    full_city_name: "Unicoi, TN",
    city_name: "Unicoi",
  },
  {
    postal_code: "61533",
    full_city_name: "Glasford, IL",
    city_name: "Glasford",
  },
  {
    postal_code: "01469",
    full_city_name: "Townsend, MA",
    city_name: "Townsend",
  },
  {
    postal_code: "97495",
    full_city_name: "Winchester, OR",
    city_name: "Winchester",
  },
  {
    postal_code: "37032",
    full_city_name: "Cedar Hill, TN",
    city_name: "Cedar Hill",
  },
  {
    postal_code: "92285",
    full_city_name: "Landers, CA",
    city_name: "Landers",
  },
  {
    postal_code: "94065",
    full_city_name: "Redwood City, CA",
    city_name: "Redwood City",
  },
  {
    postal_code: "11940",
    full_city_name: "East Moriches, NY",
    city_name: "East Moriches",
  },
  {
    postal_code: "49339",
    full_city_name: "Pierson, MI",
    city_name: "Pierson",
  },
  {
    postal_code: "43608",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "47471",
    full_city_name: "Worthington, IN",
    city_name: "Worthington",
  },
  {
    postal_code: "89415",
    full_city_name: "Hawthorne, NV",
    city_name: "Hawthorne",
  },
  {
    postal_code: "43416",
    full_city_name: "Elmore, OH",
    city_name: "Elmore",
  },
  {
    postal_code: "60151",
    full_city_name: "Maple Park, IL",
    city_name: "Maple Park",
  },
  {
    postal_code: "82201",
    full_city_name: "Wheatland, WY",
    city_name: "Wheatland",
  },
  {
    postal_code: "93516",
    full_city_name: "Boron, CA",
    city_name: "Boron",
  },
  {
    postal_code: "32352",
    full_city_name: "Quincy, FL",
    city_name: "Quincy",
  },
  {
    postal_code: "77995",
    full_city_name: "Yoakum, TX",
    city_name: "Yoakum",
  },
  {
    postal_code: "49920",
    full_city_name: "Crystal Falls, MI",
    city_name: "Crystal Falls",
  },
  {
    postal_code: "84629",
    full_city_name: "Fairview, UT",
    city_name: "Fairview",
  },
  {
    postal_code: "28160",
    full_city_name: "Spindale, NC",
    city_name: "Spindale",
  },
  {
    postal_code: "01748",
    full_city_name: "Hopkinton, MA",
    city_name: "Hopkinton",
  },
  {
    postal_code: "32948",
    full_city_name: "Fellsmere, FL",
    city_name: "Fellsmere",
  },
  {
    postal_code: "72106",
    full_city_name: "Mayflower, AR",
    city_name: "Mayflower",
  },
  {
    postal_code: "11428",
    full_city_name: "Queens Village, NY",
    city_name: "Queens Village",
  },
  {
    postal_code: "81240",
    full_city_name: "Penrose, CO",
    city_name: "Penrose",
  },
  {
    postal_code: "13131",
    full_city_name: "Parish, NY",
    city_name: "Parish",
  },
  {
    postal_code: "53715",
    full_city_name: "Madison, WI",
    city_name: "Madison",
  },
  {
    postal_code: "34216",
    full_city_name: "Anna Maria, FL",
    city_name: "Anna Maria",
  },
  {
    postal_code: "44707",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "71405",
    full_city_name: "Ball, LA",
    city_name: "Ball",
  },
  {
    postal_code: "44681",
    full_city_name: "Sugarcreek, OH",
    city_name: "Sugarcreek",
  },
  {
    postal_code: "12834",
    full_city_name: "Greenwich, NY",
    city_name: "Greenwich",
  },
  {
    postal_code: "12522",
    full_city_name: "Dover Plains, NY",
    city_name: "Dover Plains",
  },
  {
    postal_code: "77517",
    full_city_name: "Santa Fe, TX",
    city_name: "Santa Fe",
  },
  {
    postal_code: "32359",
    full_city_name: "Steinhatchee, FL",
    city_name: "Steinhatchee",
  },
  {
    postal_code: "03256",
    full_city_name: "New Hampton, NH",
    city_name: "New Hampton",
  },
  {
    postal_code: "14136",
    full_city_name: "Silver Creek, NY",
    city_name: "Silver Creek",
  },
  {
    postal_code: "25015",
    full_city_name: "Belle, WV",
    city_name: "Belle",
  },
  {
    postal_code: "95458",
    full_city_name: "Lucerne, CA",
    city_name: "Lucerne",
  },
  {
    postal_code: "24340",
    full_city_name: "Glade Spring, VA",
    city_name: "Glade Spring",
  },
  {
    postal_code: "05340",
    full_city_name: "Bondville, VT",
    city_name: "Bondville",
  },
  {
    postal_code: "03848",
    full_city_name: "Kingston, NH",
    city_name: "Kingston",
  },
  {
    postal_code: "43344",
    full_city_name: "Richwood, OH",
    city_name: "Richwood",
  },
  {
    postal_code: "13320",
    full_city_name: "Cherry Valley, NY",
    city_name: "Cherry Valley",
  },
  {
    postal_code: "92606",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "78263",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "76689",
    full_city_name: "Valley Mills, TX",
    city_name: "Valley Mills",
  },
  {
    postal_code: "95658",
    full_city_name: "Newcastle, CA",
    city_name: "Newcastle",
  },
  {
    postal_code: "81144",
    full_city_name: "Monte Vista, CO",
    city_name: "Monte Vista",
  },
  {
    postal_code: "51442",
    full_city_name: "Denison, IA",
    city_name: "Denison",
  },
  {
    postal_code: "88352",
    full_city_name: "Tularosa, NM",
    city_name: "Tularosa",
  },
  {
    postal_code: "63764",
    full_city_name: "Marble Hill, MO",
    city_name: "Marble Hill",
  },
  {
    postal_code: "53933",
    full_city_name: "Fox Lake, WI",
    city_name: "Fox Lake",
  },
  {
    postal_code: "59865",
    full_city_name: "Saint Ignatius, MT",
    city_name: "Saint Ignatius",
  },
  {
    postal_code: "91803",
    full_city_name: "Alhambra, CA",
    city_name: "Alhambra",
  },
  {
    postal_code: "81635",
    full_city_name: "Parachute, CO",
    city_name: "Parachute",
  },
  {
    postal_code: "65233",
    full_city_name: "Boonville, MO",
    city_name: "Boonville",
  },
  {
    postal_code: "32132",
    full_city_name: "Edgewater, FL",
    city_name: "Edgewater",
  },
  {
    postal_code: "42518",
    full_city_name: "Bronston, KY",
    city_name: "Bronston",
  },
  {
    postal_code: "18465",
    full_city_name: "Thompson, PA",
    city_name: "Thompson",
  },
  {
    postal_code: "73077",
    full_city_name: "Perry, OK",
    city_name: "Perry",
  },
  {
    postal_code: "16424",
    full_city_name: "Linesville, PA",
    city_name: "Linesville",
  },
  {
    postal_code: "39669",
    full_city_name: "Woodville, MS",
    city_name: "Woodville",
  },
  {
    postal_code: "01569",
    full_city_name: "Uxbridge, MA",
    city_name: "Uxbridge",
  },
  {
    postal_code: "16503",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "28124",
    full_city_name: "Mount Pleasant, NC",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "42086",
    full_city_name: "West Paducah, KY",
    city_name: "West Paducah",
  },
  {
    postal_code: "85610",
    full_city_name: "Elfrida, AZ",
    city_name: "Elfrida",
  },
  {
    postal_code: "77364",
    full_city_name: "Pointblank, TX",
    city_name: "Pointblank",
  },
  {
    postal_code: "28692",
    full_city_name: "Vilas, NC",
    city_name: "Vilas",
  },
  {
    postal_code: "26040",
    full_city_name: "Mcmechen, WV",
    city_name: "Mcmechen",
  },
  {
    postal_code: "44084",
    full_city_name: "Rock Creek, OH",
    city_name: "Rock Creek",
  },
  {
    postal_code: "38382",
    full_city_name: "Trenton, TN",
    city_name: "Trenton",
  },
  {
    postal_code: "30633",
    full_city_name: "Danielsville, GA",
    city_name: "Danielsville",
  },
  {
    postal_code: "04457",
    full_city_name: "Lincoln, ME",
    city_name: "Lincoln",
  },
  {
    postal_code: "01952",
    full_city_name: "Salisbury, MA",
    city_name: "Salisbury",
  },
  {
    postal_code: "98937",
    full_city_name: "Naches, WA",
    city_name: "Naches",
  },
  {
    postal_code: "61085",
    full_city_name: "Stockton, IL",
    city_name: "Stockton",
  },
  {
    postal_code: "44307",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "61568",
    full_city_name: "Tremont, IL",
    city_name: "Tremont",
  },
  {
    postal_code: "08560",
    full_city_name: "Titusville, NJ",
    city_name: "Titusville",
  },
  {
    postal_code: "42064",
    full_city_name: "Marion, KY",
    city_name: "Marion",
  },
  {
    postal_code: "10541",
    full_city_name: "Mahopac, NY",
    city_name: "Mahopac",
  },
  {
    postal_code: "43357",
    full_city_name: "West Liberty, OH",
    city_name: "West Liberty",
  },
  {
    postal_code: "98247",
    full_city_name: "Everson, WA",
    city_name: "Everson",
  },
  {
    postal_code: "01720",
    full_city_name: "Acton, MA",
    city_name: "Acton",
  },
  {
    postal_code: "72658",
    full_city_name: "Norfork, AR",
    city_name: "Norfork",
  },
  {
    postal_code: "55003",
    full_city_name: "Bayport, MN",
    city_name: "Bayport",
  },
  {
    postal_code: "65203",
    full_city_name: "Columbia, MO",
    city_name: "Columbia",
  },
  {
    postal_code: "37367",
    full_city_name: "Pikeville, TN",
    city_name: "Pikeville",
  },
  {
    postal_code: "14810",
    full_city_name: "Bath, NY",
    city_name: "Bath",
  },
  {
    postal_code: "02359",
    full_city_name: "Pembroke, MA",
    city_name: "Pembroke",
  },
  {
    postal_code: "01108",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "44062",
    full_city_name: "Middlefield, OH",
    city_name: "Middlefield",
  },
  {
    postal_code: "48189",
    full_city_name: "Whitmore Lake, MI",
    city_name: "Whitmore Lake",
  },
  {
    postal_code: "45662",
    full_city_name: "Portsmouth, OH",
    city_name: "Portsmouth",
  },
  {
    postal_code: "96760",
    full_city_name: "Kurtistown, HI",
    city_name: "Kurtistown",
  },
  {
    postal_code: "02474",
    full_city_name: "Arlington, MA",
    city_name: "Arlington",
  },
  {
    postal_code: "93022",
    full_city_name: "Oak View, CA",
    city_name: "Oak View",
  },
  {
    postal_code: "43072",
    full_city_name: "Saint Paris, OH",
    city_name: "Saint Paris",
  },
  {
    postal_code: "01605",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "77864",
    full_city_name: "Madisonville, TX",
    city_name: "Madisonville",
  },
  {
    postal_code: "02356",
    full_city_name: "North Easton, MA",
    city_name: "North Easton",
  },
  {
    postal_code: "70544",
    full_city_name: "Jeanerette, LA",
    city_name: "Jeanerette",
  },
  {
    postal_code: "02125",
    full_city_name: "Dorchester, MA",
    city_name: "Dorchester",
  },
  {
    postal_code: "15033",
    full_city_name: "Donora, PA",
    city_name: "Donora",
  },
  {
    postal_code: "98944",
    full_city_name: "Sunnyside, WA",
    city_name: "Sunnyside",
  },
  {
    postal_code: "60476",
    full_city_name: "Thornton, IL",
    city_name: "Thornton",
  },
  {
    postal_code: "50174",
    full_city_name: "Murray, IA",
    city_name: "Murray",
  },
  {
    postal_code: "32130",
    full_city_name: "De Leon Springs, FL",
    city_name: "De Leon Springs",
  },
  {
    postal_code: "95567",
    full_city_name: "Smith River, CA",
    city_name: "Smith River",
  },
  {
    postal_code: "06365",
    full_city_name: "Preston, CT",
    city_name: "Preston",
  },
  {
    postal_code: "77587",
    full_city_name: "South Houston, TX",
    city_name: "South Houston",
  },
  {
    postal_code: "44470",
    full_city_name: "Southington, OH",
    city_name: "Southington",
  },
  {
    postal_code: "59079",
    full_city_name: "Shepherd, MT",
    city_name: "Shepherd",
  },
  {
    postal_code: "64831",
    full_city_name: "Anderson, MO",
    city_name: "Anderson",
  },
  {
    postal_code: "22656",
    full_city_name: "Stephenson, VA",
    city_name: "Stephenson",
  },
  {
    postal_code: "90038",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "19440",
    full_city_name: "Hatfield, PA",
    city_name: "Hatfield",
  },
  {
    postal_code: "95255",
    full_city_name: "West Point, CA",
    city_name: "West Point",
  },
  {
    postal_code: "59644",
    full_city_name: "Townsend, MT",
    city_name: "Townsend",
  },
  {
    postal_code: "11565",
    full_city_name: "Malverne, NY",
    city_name: "Malverne",
  },
  {
    postal_code: "01253",
    full_city_name: "Otis, MA",
    city_name: "Otis",
  },
  {
    postal_code: "15627",
    full_city_name: "Derry, PA",
    city_name: "Derry",
  },
  {
    postal_code: "44251",
    full_city_name: "Westfield Center, OH",
    city_name: "Westfield Center",
  },
  {
    postal_code: "14883",
    full_city_name: "Spencer, NY",
    city_name: "Spencer",
  },
  {
    postal_code: "82729",
    full_city_name: "Sundance, WY",
    city_name: "Sundance",
  },
  {
    postal_code: "05735",
    full_city_name: "Castleton, VT",
    city_name: "Castleton",
  },
  {
    postal_code: "38330",
    full_city_name: "Dyer, TN",
    city_name: "Dyer",
  },
  {
    postal_code: "60420",
    full_city_name: "Dwight, IL",
    city_name: "Dwight",
  },
  {
    postal_code: "13459",
    full_city_name: "Sharon Springs, NY",
    city_name: "Sharon Springs",
  },
  {
    postal_code: "95669",
    full_city_name: "Plymouth, CA",
    city_name: "Plymouth",
  },
  {
    postal_code: "50063",
    full_city_name: "Dallas Center, IA",
    city_name: "Dallas Center",
  },
  {
    postal_code: "72616",
    full_city_name: "Berryville, AR",
    city_name: "Berryville",
  },
  {
    postal_code: "91201",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "12928",
    full_city_name: "Crown Point, NY",
    city_name: "Crown Point",
  },
  {
    postal_code: "01583",
    full_city_name: "West Boylston, MA",
    city_name: "West Boylston",
  },
  {
    postal_code: "56055",
    full_city_name: "Lake Crystal, MN",
    city_name: "Lake Crystal",
  },
  {
    postal_code: "02180",
    full_city_name: "Stoneham, MA",
    city_name: "Stoneham",
  },
  {
    postal_code: "02808",
    full_city_name: "Bradford, RI",
    city_name: "Bradford",
  },
  {
    postal_code: "12816",
    full_city_name: "Cambridge, NY",
    city_name: "Cambridge",
  },
  {
    postal_code: "67801",
    full_city_name: "Dodge City, KS",
    city_name: "Dodge City",
  },
  {
    postal_code: "45218",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "39090",
    full_city_name: "Kosciusko, MS",
    city_name: "Kosciusko",
  },
  {
    postal_code: "90023",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "92354",
    full_city_name: "Loma Linda, CA",
    city_name: "Loma Linda",
  },
  {
    postal_code: "46750",
    full_city_name: "Huntington, IN",
    city_name: "Huntington",
  },
  {
    postal_code: "36110",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "94306",
    full_city_name: "Palo Alto, CA",
    city_name: "Palo Alto",
  },
  {
    postal_code: "37219",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "37681",
    full_city_name: "Limestone, TN",
    city_name: "Limestone",
  },
  {
    postal_code: "78370",
    full_city_name: "Odem, TX",
    city_name: "Odem",
  },
  {
    postal_code: "85929",
    full_city_name: "Lakeside, AZ",
    city_name: "Lakeside",
  },
  {
    postal_code: "32730",
    full_city_name: "Casselberry, FL",
    city_name: "Casselberry",
  },
  {
    postal_code: "78725",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "75571",
    full_city_name: "Omaha, TX",
    city_name: "Omaha",
  },
  {
    postal_code: "64109",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "29470",
    full_city_name: "Ravenel, SC",
    city_name: "Ravenel",
  },
  {
    postal_code: "95220",
    full_city_name: "Acampo, CA",
    city_name: "Acampo",
  },
  {
    postal_code: "13796",
    full_city_name: "Laurens, NY",
    city_name: "Laurens",
  },
  {
    postal_code: "54829",
    full_city_name: "Cumberland, WI",
    city_name: "Cumberland",
  },
  {
    postal_code: "21131",
    full_city_name: "Phoenix, MD",
    city_name: "Phoenix",
  },
  {
    postal_code: "75756",
    full_city_name: "Brownsboro, TX",
    city_name: "Brownsboro",
  },
  {
    postal_code: "16365",
    full_city_name: "Warren, PA",
    city_name: "Warren",
  },
  {
    postal_code: "19350",
    full_city_name: "Landenberg, PA",
    city_name: "Landenberg",
  },
  {
    postal_code: "77708",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "30510",
    full_city_name: "Alto, GA",
    city_name: "Alto",
  },
  {
    postal_code: "45338",
    full_city_name: "Lewisburg, OH",
    city_name: "Lewisburg",
  },
  {
    postal_code: "04358",
    full_city_name: "South China, ME",
    city_name: "South China",
  },
  {
    postal_code: "56347",
    full_city_name: "Long Prairie, MN",
    city_name: "Long Prairie",
  },
  {
    postal_code: "12060",
    full_city_name: "East Chatham, NY",
    city_name: "East Chatham",
  },
  {
    postal_code: "52563",
    full_city_name: "Hedrick, IA",
    city_name: "Hedrick",
  },
  {
    postal_code: "23692",
    full_city_name: "Yorktown, VA",
    city_name: "Yorktown",
  },
  {
    postal_code: "28551",
    full_city_name: "La Grange, NC",
    city_name: "La Grange",
  },
  {
    postal_code: "68355",
    full_city_name: "Falls City, NE",
    city_name: "Falls City",
  },
  {
    postal_code: "16053",
    full_city_name: "Renfrew, PA",
    city_name: "Renfrew",
  },
  {
    postal_code: "21673",
    full_city_name: "Trappe, MD",
    city_name: "Trappe",
  },
  {
    postal_code: "99141",
    full_city_name: "Kettle Falls, WA",
    city_name: "Kettle Falls",
  },
  {
    postal_code: "02835",
    full_city_name: "Jamestown, RI",
    city_name: "Jamestown",
  },
  {
    postal_code: "85194",
    full_city_name: "Casa Grande, AZ",
    city_name: "Casa Grande",
  },
  {
    postal_code: "04039",
    full_city_name: "Gray, ME",
    city_name: "Gray",
  },
  {
    postal_code: "13210",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "13830",
    full_city_name: "Oxford, NY",
    city_name: "Oxford",
  },
  {
    postal_code: "64076",
    full_city_name: "Odessa, MO",
    city_name: "Odessa",
  },
  {
    postal_code: "78605",
    full_city_name: "Bertram, TX",
    city_name: "Bertram",
  },
  {
    postal_code: "76255",
    full_city_name: "Nocona, TX",
    city_name: "Nocona",
  },
  {
    postal_code: "44481",
    full_city_name: "Warren, OH",
    city_name: "Warren",
  },
  {
    postal_code: "23936",
    full_city_name: "Dillwyn, VA",
    city_name: "Dillwyn",
  },
  {
    postal_code: "62278",
    full_city_name: "Red Bud, IL",
    city_name: "Red Bud",
  },
  {
    postal_code: "90248",
    full_city_name: "Gardena, CA",
    city_name: "Gardena",
  },
  {
    postal_code: "13329",
    full_city_name: "Dolgeville, NY",
    city_name: "Dolgeville",
  },
  {
    postal_code: "27936",
    full_city_name: "Frisco, NC",
    city_name: "Frisco",
  },
  {
    postal_code: "85350",
    full_city_name: "Somerton, AZ",
    city_name: "Somerton",
  },
  {
    postal_code: "83118",
    full_city_name: "Etna, WY",
    city_name: "Etna",
  },
  {
    postal_code: "04345",
    full_city_name: "Gardiner, ME",
    city_name: "Gardiner",
  },
  {
    postal_code: "75785",
    full_city_name: "Rusk, TX",
    city_name: "Rusk",
  },
  {
    postal_code: "27019",
    full_city_name: "Germanton, NC",
    city_name: "Germanton",
  },
  {
    postal_code: "43061",
    full_city_name: "Ostrander, OH",
    city_name: "Ostrander",
  },
  {
    postal_code: "59917",
    full_city_name: "Eureka, MT",
    city_name: "Eureka",
  },
  {
    postal_code: "81101",
    full_city_name: "Alamosa, CO",
    city_name: "Alamosa",
  },
  {
    postal_code: "15963",
    full_city_name: "Windber, PA",
    city_name: "Windber",
  },
  {
    postal_code: "97133",
    full_city_name: "North Plains, OR",
    city_name: "North Plains",
  },
  {
    postal_code: "29477",
    full_city_name: "Saint George, SC",
    city_name: "Saint George",
  },
  {
    postal_code: "76059",
    full_city_name: "Keene, TX",
    city_name: "Keene",
  },
  {
    postal_code: "59873",
    full_city_name: "Thompson Falls, MT",
    city_name: "Thompson Falls",
  },
  {
    postal_code: "15081",
    full_city_name: "South Heights, PA",
    city_name: "South Heights",
  },
  {
    postal_code: "15086",
    full_city_name: "Warrendale, PA",
    city_name: "Warrendale",
  },
  {
    postal_code: "59752",
    full_city_name: "Three Forks, MT",
    city_name: "Three Forks",
  },
  {
    postal_code: "64123",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "27583",
    full_city_name: "Timberlake, NC",
    city_name: "Timberlake",
  },
  {
    postal_code: "44302",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "46394",
    full_city_name: "Whiting, IN",
    city_name: "Whiting",
  },
  {
    postal_code: "48415",
    full_city_name: "Birch Run, MI",
    city_name: "Birch Run",
  },
  {
    postal_code: "06469",
    full_city_name: "Moodus, CT",
    city_name: "Moodus",
  },
  {
    postal_code: "61616",
    full_city_name: "Peoria Heights, IL",
    city_name: "Peoria Heights",
  },
  {
    postal_code: "40379",
    full_city_name: "Stamping Ground, KY",
    city_name: "Stamping Ground",
  },
  {
    postal_code: "01501",
    full_city_name: "Auburn, MA",
    city_name: "Auburn",
  },
  {
    postal_code: "35005",
    full_city_name: "Adamsville, AL",
    city_name: "Adamsville",
  },
  {
    postal_code: "01075",
    full_city_name: "South Hadley, MA",
    city_name: "South Hadley",
  },
  {
    postal_code: "49756",
    full_city_name: "Lewiston, MI",
    city_name: "Lewiston",
  },
  {
    postal_code: "47978",
    full_city_name: "Rensselaer, IN",
    city_name: "Rensselaer",
  },
  {
    postal_code: "85935",
    full_city_name: "Pinetop, AZ",
    city_name: "Pinetop",
  },
  {
    postal_code: "02370",
    full_city_name: "Rockland, MA",
    city_name: "Rockland",
  },
  {
    postal_code: "34756",
    full_city_name: "Montverde, FL",
    city_name: "Montverde",
  },
  {
    postal_code: "40162",
    full_city_name: "Rineyville, KY",
    city_name: "Rineyville",
  },
  {
    postal_code: "35228",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "53813",
    full_city_name: "Lancaster, WI",
    city_name: "Lancaster",
  },
  {
    postal_code: "90033",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "19939",
    full_city_name: "Dagsboro, DE",
    city_name: "Dagsboro",
  },
  {
    postal_code: "54656",
    full_city_name: "Sparta, WI",
    city_name: "Sparta",
  },
  {
    postal_code: "02667",
    full_city_name: "Wellfleet, MA",
    city_name: "Wellfleet",
  },
  {
    postal_code: "06442",
    full_city_name: "Ivoryton, CT",
    city_name: "Ivoryton",
  },
  {
    postal_code: "39581",
    full_city_name: "Pascagoula, MS",
    city_name: "Pascagoula",
  },
  {
    postal_code: "64124",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "40516",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "33051",
    full_city_name: "Key Colony Beach, FL",
    city_name: "Key Colony Beach",
  },
  {
    postal_code: "22824",
    full_city_name: "Edinburg, VA",
    city_name: "Edinburg",
  },
  {
    postal_code: "31320",
    full_city_name: "Midway, GA",
    city_name: "Midway",
  },
  {
    postal_code: "80542",
    full_city_name: "Mead, CO",
    city_name: "Mead",
  },
  {
    postal_code: "18446",
    full_city_name: "Nicholson, PA",
    city_name: "Nicholson",
  },
  {
    postal_code: "76667",
    full_city_name: "Mexia, TX",
    city_name: "Mexia",
  },
  {
    postal_code: "28777",
    full_city_name: "Spruce Pine, NC",
    city_name: "Spruce Pine",
  },
  {
    postal_code: "62012",
    full_city_name: "Brighton, IL",
    city_name: "Brighton",
  },
  {
    postal_code: "28762",
    full_city_name: "Old Fort, NC",
    city_name: "Old Fort",
  },
  {
    postal_code: "42206",
    full_city_name: "Auburn, KY",
    city_name: "Auburn",
  },
  {
    postal_code: "51331",
    full_city_name: "Arnolds Park, IA",
    city_name: "Arnolds Park",
  },
  {
    postal_code: "56435",
    full_city_name: "Backus, MN",
    city_name: "Backus",
  },
  {
    postal_code: "42320",
    full_city_name: "Beaver Dam, KY",
    city_name: "Beaver Dam",
  },
  {
    postal_code: "03741",
    full_city_name: "Canaan, NH",
    city_name: "Canaan",
  },
  {
    postal_code: "63334",
    full_city_name: "Bowling Green, MO",
    city_name: "Bowling Green",
  },
  {
    postal_code: "77905",
    full_city_name: "Victoria, TX",
    city_name: "Victoria",
  },
  {
    postal_code: "63133",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "05495",
    full_city_name: "Williston, VT",
    city_name: "Williston",
  },
  {
    postal_code: "02135",
    full_city_name: "Brighton, MA",
    city_name: "Brighton",
  },
  {
    postal_code: "02859",
    full_city_name: "Pascoag, RI",
    city_name: "Pascoag",
  },
  {
    postal_code: "04462",
    full_city_name: "Millinocket, ME",
    city_name: "Millinocket",
  },
  {
    postal_code: "75763",
    full_city_name: "Frankston, TX",
    city_name: "Frankston",
  },
  {
    postal_code: "70584",
    full_city_name: "Sunset, LA",
    city_name: "Sunset",
  },
  {
    postal_code: "59758",
    full_city_name: "West Yellowstone, MT",
    city_name: "West Yellowstone",
  },
  {
    postal_code: "93501",
    full_city_name: "Mojave, CA",
    city_name: "Mojave",
  },
  {
    postal_code: "28771",
    full_city_name: "Robbinsville, NC",
    city_name: "Robbinsville",
  },
  {
    postal_code: "72117",
    full_city_name: "North Little Rock, AR",
    city_name: "North Little Rock",
  },
  {
    postal_code: "95446",
    full_city_name: "Guerneville, CA",
    city_name: "Guerneville",
  },
  {
    postal_code: "75497",
    full_city_name: "Yantis, TX",
    city_name: "Yantis",
  },
  {
    postal_code: "53952",
    full_city_name: "Oxford, WI",
    city_name: "Oxford",
  },
  {
    postal_code: "72638",
    full_city_name: "Green Forest, AR",
    city_name: "Green Forest",
  },
  {
    postal_code: "60936",
    full_city_name: "Gibson City, IL",
    city_name: "Gibson City",
  },
  {
    postal_code: "76444",
    full_city_name: "De Leon, TX",
    city_name: "De Leon",
  },
  {
    postal_code: "66219",
    full_city_name: "Lenexa, KS",
    city_name: "Lenexa",
  },
  {
    postal_code: "54755",
    full_city_name: "Mondovi, WI",
    city_name: "Mondovi",
  },
  {
    postal_code: "05151",
    full_city_name: "Perkinsville, VT",
    city_name: "Perkinsville",
  },
  {
    postal_code: "24556",
    full_city_name: "Goode, VA",
    city_name: "Goode",
  },
  {
    postal_code: "71602",
    full_city_name: "White Hall, AR",
    city_name: "White Hall",
  },
  {
    postal_code: "62995",
    full_city_name: "Vienna, IL",
    city_name: "Vienna",
  },
  {
    postal_code: "63127",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "11415",
    full_city_name: "Kew Gardens, NY",
    city_name: "Kew Gardens",
  },
  {
    postal_code: "16827",
    full_city_name: "Boalsburg, PA",
    city_name: "Boalsburg",
  },
  {
    postal_code: "91024",
    full_city_name: "Sierra Madre, CA",
    city_name: "Sierra Madre",
  },
  {
    postal_code: "64720",
    full_city_name: "Adrian, MO",
    city_name: "Adrian",
  },
  {
    postal_code: "53964",
    full_city_name: "Westfield, WI",
    city_name: "Westfield",
  },
  {
    postal_code: "22920",
    full_city_name: "Afton, VA",
    city_name: "Afton",
  },
  {
    postal_code: "56048",
    full_city_name: "Janesville, MN",
    city_name: "Janesville",
  },
  {
    postal_code: "95334",
    full_city_name: "Livingston, CA",
    city_name: "Livingston",
  },
  {
    postal_code: "74965",
    full_city_name: "Westville, OK",
    city_name: "Westville",
  },
  {
    postal_code: "15022",
    full_city_name: "Charleroi, PA",
    city_name: "Charleroi",
  },
  {
    postal_code: "53561",
    full_city_name: "Merrimac, WI",
    city_name: "Merrimac",
  },
  {
    postal_code: "11509",
    full_city_name: "Atlantic Beach, NY",
    city_name: "Atlantic Beach",
  },
  {
    postal_code: "43730",
    full_city_name: "Corning, OH",
    city_name: "Corning",
  },
  {
    postal_code: "02790",
    full_city_name: "Westport, MA",
    city_name: "Westport",
  },
  {
    postal_code: "48166",
    full_city_name: "Newport, MI",
    city_name: "Newport",
  },
  {
    postal_code: "87010",
    full_city_name: "Cerrillos, NM",
    city_name: "Cerrillos",
  },
  {
    postal_code: "74451",
    full_city_name: "Park Hill, OK",
    city_name: "Park Hill",
  },
  {
    postal_code: "55769",
    full_city_name: "Nashwauk, MN",
    city_name: "Nashwauk",
  },
  {
    postal_code: "27943",
    full_city_name: "Hatteras, NC",
    city_name: "Hatteras",
  },
  {
    postal_code: "37869",
    full_city_name: "Sneedville, TN",
    city_name: "Sneedville",
  },
  {
    postal_code: "70113",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "94041",
    full_city_name: "Mountain View, CA",
    city_name: "Mountain View",
  },
  {
    postal_code: "40387",
    full_city_name: "Wellington, KY",
    city_name: "Wellington",
  },
  {
    postal_code: "46071",
    full_city_name: "Thorntown, IN",
    city_name: "Thorntown",
  },
  {
    postal_code: "61064",
    full_city_name: "Polo, IL",
    city_name: "Polo",
  },
  {
    postal_code: "54722",
    full_city_name: "Augusta, WI",
    city_name: "Augusta",
  },
  {
    postal_code: "02825",
    full_city_name: "Foster, RI",
    city_name: "Foster",
  },
  {
    postal_code: "66111",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "13825",
    full_city_name: "Otego, NY",
    city_name: "Otego",
  },
  {
    postal_code: "29482",
    full_city_name: "Sullivans Island, SC",
    city_name: "Sullivans Island",
  },
  {
    postal_code: "22044",
    full_city_name: "Falls Church, VA",
    city_name: "Falls Church",
  },
  {
    postal_code: "53528",
    full_city_name: "Cross Plains, WI",
    city_name: "Cross Plains",
  },
  {
    postal_code: "54424",
    full_city_name: "Deerbrook, WI",
    city_name: "Deerbrook",
  },
  {
    postal_code: "49749",
    full_city_name: "Indian River, MI",
    city_name: "Indian River",
  },
  {
    postal_code: "54646",
    full_city_name: "Necedah, WI",
    city_name: "Necedah",
  },
  {
    postal_code: "56378",
    full_city_name: "Sauk Centre, MN",
    city_name: "Sauk Centre",
  },
  {
    postal_code: "35085",
    full_city_name: "Jemison, AL",
    city_name: "Jemison",
  },
  {
    postal_code: "40068",
    full_city_name: "Smithfield, KY",
    city_name: "Smithfield",
  },
  {
    postal_code: "60970",
    full_city_name: "Watseka, IL",
    city_name: "Watseka",
  },
  {
    postal_code: "48656",
    full_city_name: "Saint Helen, MI",
    city_name: "Saint Helen",
  },
  {
    postal_code: "43465",
    full_city_name: "Walbridge, OH",
    city_name: "Walbridge",
  },
  {
    postal_code: "96025",
    full_city_name: "Dunsmuir, CA",
    city_name: "Dunsmuir",
  },
  {
    postal_code: "42171",
    full_city_name: "Smiths Grove, KY",
    city_name: "Smiths Grove",
  },
  {
    postal_code: "06280",
    full_city_name: "Windham, CT",
    city_name: "Windham",
  },
  {
    postal_code: "46405",
    full_city_name: "Lake Station, IN",
    city_name: "Lake Station",
  },
  {
    postal_code: "56544",
    full_city_name: "Frazee, MN",
    city_name: "Frazee",
  },
  {
    postal_code: "70653",
    full_city_name: "Merryville, LA",
    city_name: "Merryville",
  },
  {
    postal_code: "32617",
    full_city_name: "Anthony, FL",
    city_name: "Anthony",
  },
  {
    postal_code: "12474",
    full_city_name: "Roxbury, NY",
    city_name: "Roxbury",
  },
  {
    postal_code: "13637",
    full_city_name: "Evans Mills, NY",
    city_name: "Evans Mills",
  },
  {
    postal_code: "38023",
    full_city_name: "Drummonds, TN",
    city_name: "Drummonds",
  },
  {
    postal_code: "53963",
    full_city_name: "Waupun, WI",
    city_name: "Waupun",
  },
  {
    postal_code: "41006",
    full_city_name: "Butler, KY",
    city_name: "Butler",
  },
  {
    postal_code: "22737",
    full_city_name: "Rixeyville, VA",
    city_name: "Rixeyville",
  },
  {
    postal_code: "61241",
    full_city_name: "Colona, IL",
    city_name: "Colona",
  },
  {
    postal_code: "49862",
    full_city_name: "Munising, MI",
    city_name: "Munising",
  },
  {
    postal_code: "12068",
    full_city_name: "Fonda, NY",
    city_name: "Fonda",
  },
  {
    postal_code: "75706",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "93553",
    full_city_name: "Pearblossom, CA",
    city_name: "Pearblossom",
  },
  {
    postal_code: "62902",
    full_city_name: "Carbondale, IL",
    city_name: "Carbondale",
  },
  {
    postal_code: "95971",
    full_city_name: "Quincy, CA",
    city_name: "Quincy",
  },
  {
    postal_code: "13753",
    full_city_name: "Delhi, NY",
    city_name: "Delhi",
  },
  {
    postal_code: "46552",
    full_city_name: "New Carlisle, IN",
    city_name: "New Carlisle",
  },
  {
    postal_code: "03063",
    full_city_name: "Nashua, NH",
    city_name: "Nashua",
  },
  {
    postal_code: "18519",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "02666",
    full_city_name: "Truro, MA",
    city_name: "Truro",
  },
  {
    postal_code: "60942",
    full_city_name: "Hoopeston, IL",
    city_name: "Hoopeston",
  },
  {
    postal_code: "55321",
    full_city_name: "Cokato, MN",
    city_name: "Cokato",
  },
  {
    postal_code: "95692",
    full_city_name: "Wheatland, CA",
    city_name: "Wheatland",
  },
  {
    postal_code: "03782",
    full_city_name: "Sunapee, NH",
    city_name: "Sunapee",
  },
  {
    postal_code: "67443",
    full_city_name: "Galva, KS",
    city_name: "Galva",
  },
  {
    postal_code: "13615",
    full_city_name: "Brownville, NY",
    city_name: "Brownville",
  },
  {
    postal_code: "47246",
    full_city_name: "Hope, IN",
    city_name: "Hope",
  },
  {
    postal_code: "90063",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "70538",
    full_city_name: "Franklin, LA",
    city_name: "Franklin",
  },
  {
    postal_code: "40380",
    full_city_name: "Stanton, KY",
    city_name: "Stanton",
  },
  {
    postal_code: "59833",
    full_city_name: "Florence, MT",
    city_name: "Florence",
  },
  {
    postal_code: "43906",
    full_city_name: "Bellaire, OH",
    city_name: "Bellaire",
  },
  {
    postal_code: "44654",
    full_city_name: "Millersburg, OH",
    city_name: "Millersburg",
  },
  {
    postal_code: "91733",
    full_city_name: "South El Monte, CA",
    city_name: "South El Monte",
  },
  {
    postal_code: "17059",
    full_city_name: "Mifflintown, PA",
    city_name: "Mifflintown",
  },
  {
    postal_code: "65655",
    full_city_name: "Gainesville, MO",
    city_name: "Gainesville",
  },
  {
    postal_code: "26541",
    full_city_name: "Maidsville, WV",
    city_name: "Maidsville",
  },
  {
    postal_code: "19373",
    full_city_name: "Thornton, PA",
    city_name: "Thornton",
  },
  {
    postal_code: "38363",
    full_city_name: "Parsons, TN",
    city_name: "Parsons",
  },
  {
    postal_code: "61534",
    full_city_name: "Green Valley, IL",
    city_name: "Green Valley",
  },
  {
    postal_code: "44842",
    full_city_name: "Loudonville, OH",
    city_name: "Loudonville",
  },
  {
    postal_code: "02019",
    full_city_name: "Bellingham, MA",
    city_name: "Bellingham",
  },
  {
    postal_code: "27851",
    full_city_name: "Lucama, NC",
    city_name: "Lucama",
  },
  {
    postal_code: "95324",
    full_city_name: "Hilmar, CA",
    city_name: "Hilmar",
  },
  {
    postal_code: "93110",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "62293",
    full_city_name: "Trenton, IL",
    city_name: "Trenton",
  },
  {
    postal_code: "14172",
    full_city_name: "Wilson, NY",
    city_name: "Wilson",
  },
  {
    postal_code: "78722",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "61540",
    full_city_name: "Lacon, IL",
    city_name: "Lacon",
  },
  {
    postal_code: "43351",
    full_city_name: "Upper Sandusky, OH",
    city_name: "Upper Sandusky",
  },
  {
    postal_code: "54666",
    full_city_name: "Warrens, WI",
    city_name: "Warrens",
  },
  {
    postal_code: "44621",
    full_city_name: "Dennison, OH",
    city_name: "Dennison",
  },
  {
    postal_code: "13461",
    full_city_name: "Sherrill, NY",
    city_name: "Sherrill",
  },
  {
    postal_code: "25312",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "01588",
    full_city_name: "Whitinsville, MA",
    city_name: "Whitinsville",
  },
  {
    postal_code: "56362",
    full_city_name: "Paynesville, MN",
    city_name: "Paynesville",
  },
  {
    postal_code: "76115",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "72173",
    full_city_name: "Vilonia, AR",
    city_name: "Vilonia",
  },
  {
    postal_code: "97368",
    full_city_name: "Otis, OR",
    city_name: "Otis",
  },
  {
    postal_code: "17320",
    full_city_name: "Fairfield, PA",
    city_name: "Fairfield",
  },
  {
    postal_code: "75645",
    full_city_name: "Gilmer, TX",
    city_name: "Gilmer",
  },
  {
    postal_code: "43619",
    full_city_name: "Northwood, OH",
    city_name: "Northwood",
  },
  {
    postal_code: "60957",
    full_city_name: "Paxton, IL",
    city_name: "Paxton",
  },
  {
    postal_code: "28544",
    full_city_name: "Midway Park, NC",
    city_name: "Midway Park",
  },
  {
    postal_code: "25550",
    full_city_name: "Point Pleasant, WV",
    city_name: "Point Pleasant",
  },
  {
    postal_code: "41169",
    full_city_name: "Russell, KY",
    city_name: "Russell",
  },
  {
    postal_code: "40312",
    full_city_name: "Clay City, KY",
    city_name: "Clay City",
  },
  {
    postal_code: "04843",
    full_city_name: "Camden, ME",
    city_name: "Camden",
  },
  {
    postal_code: "98589",
    full_city_name: "Tenino, WA",
    city_name: "Tenino",
  },
  {
    postal_code: "86325",
    full_city_name: "Cornville, AZ",
    city_name: "Cornville",
  },
  {
    postal_code: "55709",
    full_city_name: "Bovey, MN",
    city_name: "Bovey",
  },
  {
    postal_code: "21797",
    full_city_name: "Woodbine, MD",
    city_name: "Woodbine",
  },
  {
    postal_code: "53533",
    full_city_name: "Dodgeville, WI",
    city_name: "Dodgeville",
  },
  {
    postal_code: "12542",
    full_city_name: "Marlboro, NY",
    city_name: "Marlboro",
  },
  {
    postal_code: "96103",
    full_city_name: "Blairsden Graeagle, CA",
    city_name: "Blairsden Graeagle",
  },
  {
    postal_code: "78942",
    full_city_name: "Giddings, TX",
    city_name: "Giddings",
  },
  {
    postal_code: "40409",
    full_city_name: "Brodhead, KY",
    city_name: "Brodhead",
  },
  {
    postal_code: "21783",
    full_city_name: "Smithsburg, MD",
    city_name: "Smithsburg",
  },
  {
    postal_code: "02351",
    full_city_name: "Abington, MA",
    city_name: "Abington",
  },
  {
    postal_code: "90303",
    full_city_name: "Inglewood, CA",
    city_name: "Inglewood",
  },
  {
    postal_code: "64844",
    full_city_name: "Granby, MO",
    city_name: "Granby",
  },
  {
    postal_code: "13346",
    full_city_name: "Hamilton, NY",
    city_name: "Hamilton",
  },
  {
    postal_code: "02072",
    full_city_name: "Stoughton, MA",
    city_name: "Stoughton",
  },
  {
    postal_code: "02148",
    full_city_name: "Malden, MA",
    city_name: "Malden",
  },
  {
    postal_code: "05478",
    full_city_name: "Saint Albans, VT",
    city_name: "Saint Albans",
  },
  {
    postal_code: "08071",
    full_city_name: "Pitman, NJ",
    city_name: "Pitman",
  },
  {
    postal_code: "28530",
    full_city_name: "Grifton, NC",
    city_name: "Grifton",
  },
  {
    postal_code: "49236",
    full_city_name: "Clinton, MI",
    city_name: "Clinton",
  },
  {
    postal_code: "01002",
    full_city_name: "Amherst, MA",
    city_name: "Amherst",
  },
  {
    postal_code: "11109",
    full_city_name: "Long Island City, NY",
    city_name: "Long Island City",
  },
  {
    postal_code: "90029",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "96725",
    full_city_name: "Holualoa, HI",
    city_name: "Holualoa",
  },
  {
    postal_code: "63101",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "11378",
    full_city_name: "Maspeth, NY",
    city_name: "Maspeth",
  },
  {
    postal_code: "83252",
    full_city_name: "Malad City, ID",
    city_name: "Malad City",
  },
  {
    postal_code: "56013",
    full_city_name: "Blue Earth, MN",
    city_name: "Blue Earth",
  },
  {
    postal_code: "10467",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "01462",
    full_city_name: "Lunenburg, MA",
    city_name: "Lunenburg",
  },
  {
    postal_code: "46124",
    full_city_name: "Edinburgh, IN",
    city_name: "Edinburgh",
  },
  {
    postal_code: "91207",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "52531",
    full_city_name: "Albia, IA",
    city_name: "Albia",
  },
  {
    postal_code: "46173",
    full_city_name: "Rushville, IN",
    city_name: "Rushville",
  },
  {
    postal_code: "38375",
    full_city_name: "Selmer, TN",
    city_name: "Selmer",
  },
  {
    postal_code: "39051",
    full_city_name: "Carthage, MS",
    city_name: "Carthage",
  },
  {
    postal_code: "05156",
    full_city_name: "Springfield, VT",
    city_name: "Springfield",
  },
  {
    postal_code: "98349",
    full_city_name: "Lakebay, WA",
    city_name: "Lakebay",
  },
  {
    postal_code: "96140",
    full_city_name: "Carnelian Bay, CA",
    city_name: "Carnelian Bay",
  },
  {
    postal_code: "22427",
    full_city_name: "Bowling Green, VA",
    city_name: "Bowling Green",
  },
  {
    postal_code: "37336",
    full_city_name: "Georgetown, TN",
    city_name: "Georgetown",
  },
  {
    postal_code: "20106",
    full_city_name: "Amissville, VA",
    city_name: "Amissville",
  },
  {
    postal_code: "54896",
    full_city_name: "Winter, WI",
    city_name: "Winter",
  },
  {
    postal_code: "06063",
    full_city_name: "Barkhamsted, CT",
    city_name: "Barkhamsted",
  },
  {
    postal_code: "97116",
    full_city_name: "Forest Grove, OR",
    city_name: "Forest Grove",
  },
  {
    postal_code: "49946",
    full_city_name: "Lanse, MI",
    city_name: "Lanse",
  },
  {
    postal_code: "01020",
    full_city_name: "Chicopee, MA",
    city_name: "Chicopee",
  },
  {
    postal_code: "01776",
    full_city_name: "Sudbury, MA",
    city_name: "Sudbury",
  },
  {
    postal_code: "20841",
    full_city_name: "Boyds, MD",
    city_name: "Boyds",
  },
  {
    postal_code: "65542",
    full_city_name: "Licking, MO",
    city_name: "Licking",
  },
  {
    postal_code: "72634",
    full_city_name: "Flippin, AR",
    city_name: "Flippin",
  },
  {
    postal_code: "62286",
    full_city_name: "Sparta, IL",
    city_name: "Sparta",
  },
  {
    postal_code: "29554",
    full_city_name: "Hemingway, SC",
    city_name: "Hemingway",
  },
  {
    postal_code: "16701",
    full_city_name: "Bradford, PA",
    city_name: "Bradford",
  },
  {
    postal_code: "21029",
    full_city_name: "Clarksville, MD",
    city_name: "Clarksville",
  },
  {
    postal_code: "80467",
    full_city_name: "Oak Creek, CO",
    city_name: "Oak Creek",
  },
  {
    postal_code: "27048",
    full_city_name: "Stoneville, NC",
    city_name: "Stoneville",
  },
  {
    postal_code: "15656",
    full_city_name: "Leechburg, PA",
    city_name: "Leechburg",
  },
  {
    postal_code: "64759",
    full_city_name: "Lamar, MO",
    city_name: "Lamar",
  },
  {
    postal_code: "03846",
    full_city_name: "Jackson, NH",
    city_name: "Jackson",
  },
  {
    postal_code: "61547",
    full_city_name: "Mapleton, IL",
    city_name: "Mapleton",
  },
  {
    postal_code: "02343",
    full_city_name: "Holbrook, MA",
    city_name: "Holbrook",
  },
  {
    postal_code: "66018",
    full_city_name: "De Soto, KS",
    city_name: "De Soto",
  },
  {
    postal_code: "02116",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "06784",
    full_city_name: "Sherman, CT",
    city_name: "Sherman",
  },
  {
    postal_code: "12502",
    full_city_name: "Ancram, NY",
    city_name: "Ancram",
  },
  {
    postal_code: "62263",
    full_city_name: "Nashville, IL",
    city_name: "Nashville",
  },
  {
    postal_code: "95694",
    full_city_name: "Winters, CA",
    city_name: "Winters",
  },
  {
    postal_code: "72722",
    full_city_name: "Decatur, AR",
    city_name: "Decatur",
  },
  {
    postal_code: "55981",
    full_city_name: "Wabasha, MN",
    city_name: "Wabasha",
  },
  {
    postal_code: "28651",
    full_city_name: "Millers Creek, NC",
    city_name: "Millers Creek",
  },
  {
    postal_code: "05155",
    full_city_name: "South Londonderry, VT",
    city_name: "South Londonderry",
  },
  {
    postal_code: "12832",
    full_city_name: "Granville, NY",
    city_name: "Granville",
  },
  {
    postal_code: "10034",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "27871",
    full_city_name: "Robersonville, NC",
    city_name: "Robersonville",
  },
  {
    postal_code: "05488",
    full_city_name: "Swanton, VT",
    city_name: "Swanton",
  },
  {
    postal_code: "45387",
    full_city_name: "Yellow Springs, OH",
    city_name: "Yellow Springs",
  },
  {
    postal_code: "41008",
    full_city_name: "Carrollton, KY",
    city_name: "Carrollton",
  },
  {
    postal_code: "03813",
    full_city_name: "Center Conway, NH",
    city_name: "Center Conway",
  },
  {
    postal_code: "15104",
    full_city_name: "Braddock, PA",
    city_name: "Braddock",
  },
  {
    postal_code: "46147",
    full_city_name: "Jamestown, IN",
    city_name: "Jamestown",
  },
  {
    postal_code: "70345",
    full_city_name: "Cut Off, LA",
    city_name: "Cut Off",
  },
  {
    postal_code: "12589",
    full_city_name: "Wallkill, NY",
    city_name: "Wallkill",
  },
  {
    postal_code: "49270",
    full_city_name: "Petersburg, MI",
    city_name: "Petersburg",
  },
  {
    postal_code: "03824",
    full_city_name: "Durham, NH",
    city_name: "Durham",
  },
  {
    postal_code: "36107",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "48049",
    full_city_name: "North Street, MI",
    city_name: "North Street",
  },
  {
    postal_code: "21017",
    full_city_name: "Belcamp, MD",
    city_name: "Belcamp",
  },
  {
    postal_code: "49454",
    full_city_name: "Scottville, MI",
    city_name: "Scottville",
  },
  {
    postal_code: "48756",
    full_city_name: "Prescott, MI",
    city_name: "Prescott",
  },
  {
    postal_code: "55325",
    full_city_name: "Dassel, MN",
    city_name: "Dassel",
  },
  {
    postal_code: "16121",
    full_city_name: "Farrell, PA",
    city_name: "Farrell",
  },
  {
    postal_code: "66211",
    full_city_name: "Leawood, KS",
    city_name: "Leawood",
  },
  {
    postal_code: "67661",
    full_city_name: "Phillipsburg, KS",
    city_name: "Phillipsburg",
  },
  {
    postal_code: "61605",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "20634",
    full_city_name: "Great Mills, MD",
    city_name: "Great Mills",
  },
  {
    postal_code: "27808",
    full_city_name: "Bath, NC",
    city_name: "Bath",
  },
  {
    postal_code: "13807",
    full_city_name: "Milford, NY",
    city_name: "Milford",
  },
  {
    postal_code: "12057",
    full_city_name: "Eagle Bridge, NY",
    city_name: "Eagle Bridge",
  },
  {
    postal_code: "36863",
    full_city_name: "Lanett, AL",
    city_name: "Lanett",
  },
  {
    postal_code: "15502",
    full_city_name: "Hidden Valley, PA",
    city_name: "Hidden Valley",
  },
  {
    postal_code: "99109",
    full_city_name: "Chewelah, WA",
    city_name: "Chewelah",
  },
  {
    postal_code: "56442",
    full_city_name: "Crosslake, MN",
    city_name: "Crosslake",
  },
  {
    postal_code: "95988",
    full_city_name: "Willows, CA",
    city_name: "Willows",
  },
  {
    postal_code: "90057",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "12567",
    full_city_name: "Pine Plains, NY",
    city_name: "Pine Plains",
  },
  {
    postal_code: "04915",
    full_city_name: "Belfast, ME",
    city_name: "Belfast",
  },
  {
    postal_code: "56273",
    full_city_name: "New London, MN",
    city_name: "New London",
  },
  {
    postal_code: "52761",
    full_city_name: "Muscatine, IA",
    city_name: "Muscatine",
  },
  {
    postal_code: "70087",
    full_city_name: "Saint Rose, LA",
    city_name: "Saint Rose",
  },
  {
    postal_code: "29458",
    full_city_name: "Mc Clellanville, SC",
    city_name: "Mc Clellanville",
  },
  {
    postal_code: "16102",
    full_city_name: "New Castle, PA",
    city_name: "New Castle",
  },
  {
    postal_code: "63343",
    full_city_name: "Elsberry, MO",
    city_name: "Elsberry",
  },
  {
    postal_code: "97101",
    full_city_name: "Amity, OR",
    city_name: "Amity",
  },
  {
    postal_code: "55734",
    full_city_name: "Eveleth, MN",
    city_name: "Eveleth",
  },
  {
    postal_code: "06043",
    full_city_name: "Bolton, CT",
    city_name: "Bolton",
  },
  {
    postal_code: "40033",
    full_city_name: "Lebanon, KY",
    city_name: "Lebanon",
  },
  {
    postal_code: "66112",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "01001",
    full_city_name: "Agawam, MA",
    city_name: "Agawam",
  },
  {
    postal_code: "32425",
    full_city_name: "Bonifay, FL",
    city_name: "Bonifay",
  },
  {
    postal_code: "37331",
    full_city_name: "Etowah, TN",
    city_name: "Etowah",
  },
  {
    postal_code: "32189",
    full_city_name: "Satsuma, FL",
    city_name: "Satsuma",
  },
  {
    postal_code: "37886",
    full_city_name: "Walland, TN",
    city_name: "Walland",
  },
  {
    postal_code: "21795",
    full_city_name: "Williamsport, MD",
    city_name: "Williamsport",
  },
  {
    postal_code: "77957",
    full_city_name: "Edna, TX",
    city_name: "Edna",
  },
  {
    postal_code: "18013",
    full_city_name: "Bangor, PA",
    city_name: "Bangor",
  },
  {
    postal_code: "02832",
    full_city_name: "Hope Valley, RI",
    city_name: "Hope Valley",
  },
  {
    postal_code: "01106",
    full_city_name: "Longmeadow, MA",
    city_name: "Longmeadow",
  },
  {
    postal_code: "98640",
    full_city_name: "Ocean Park, WA",
    city_name: "Ocean Park",
  },
  {
    postal_code: "45220",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "11960",
    full_city_name: "Remsenburg, NY",
    city_name: "Remsenburg",
  },
  {
    postal_code: "95428",
    full_city_name: "Covelo, CA",
    city_name: "Covelo",
  },
  {
    postal_code: "77039",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "42633",
    full_city_name: "Monticello, KY",
    city_name: "Monticello",
  },
  {
    postal_code: "30628",
    full_city_name: "Colbert, GA",
    city_name: "Colbert",
  },
  {
    postal_code: "69138",
    full_city_name: "Gothenburg, NE",
    city_name: "Gothenburg",
  },
  {
    postal_code: "26416",
    full_city_name: "Philippi, WV",
    city_name: "Philippi",
  },
  {
    postal_code: "43011",
    full_city_name: "Centerburg, OH",
    city_name: "Centerburg",
  },
  {
    postal_code: "10461",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "59864",
    full_city_name: "Ronan, MT",
    city_name: "Ronan",
  },
  {
    postal_code: "05468",
    full_city_name: "Milton, VT",
    city_name: "Milton",
  },
  {
    postal_code: "75410",
    full_city_name: "Alba, TX",
    city_name: "Alba",
  },
  {
    postal_code: "38834",
    full_city_name: "Corinth, MS",
    city_name: "Corinth",
  },
  {
    postal_code: "94930",
    full_city_name: "Fairfax, CA",
    city_name: "Fairfax",
  },
  {
    postal_code: "61063",
    full_city_name: "Pecatonica, IL",
    city_name: "Pecatonica",
  },
  {
    postal_code: "37329",
    full_city_name: "Englewood, TN",
    city_name: "Englewood",
  },
  {
    postal_code: "03576",
    full_city_name: "Colebrook, NH",
    city_name: "Colebrook",
  },
  {
    postal_code: "56096",
    full_city_name: "Waterville, MN",
    city_name: "Waterville",
  },
  {
    postal_code: "03755",
    full_city_name: "Hanover, NH",
    city_name: "Hanover",
  },
  {
    postal_code: "30625",
    full_city_name: "Buckhead, GA",
    city_name: "Buckhead",
  },
  {
    postal_code: "97532",
    full_city_name: "Merlin, OR",
    city_name: "Merlin",
  },
  {
    postal_code: "37191",
    full_city_name: "Woodlawn, TN",
    city_name: "Woodlawn",
  },
  {
    postal_code: "75931",
    full_city_name: "Brookeland, TX",
    city_name: "Brookeland",
  },
  {
    postal_code: "38635",
    full_city_name: "Holly Springs, MS",
    city_name: "Holly Springs",
  },
  {
    postal_code: "77002",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "92359",
    full_city_name: "Mentone, CA",
    city_name: "Mentone",
  },
  {
    postal_code: "24450",
    full_city_name: "Lexington, VA",
    city_name: "Lexington",
  },
  {
    postal_code: "43326",
    full_city_name: "Kenton, OH",
    city_name: "Kenton",
  },
  {
    postal_code: "61104",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "37185",
    full_city_name: "Waverly, TN",
    city_name: "Waverly",
  },
  {
    postal_code: "27281",
    full_city_name: "Jackson Springs, NC",
    city_name: "Jackson Springs",
  },
  {
    postal_code: "95116",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "81154",
    full_city_name: "South Fork, CO",
    city_name: "South Fork",
  },
  {
    postal_code: "98239",
    full_city_name: "Coupeville, WA",
    city_name: "Coupeville",
  },
  {
    postal_code: "65483",
    full_city_name: "Houston, MO",
    city_name: "Houston",
  },
  {
    postal_code: "65052",
    full_city_name: "Linn Creek, MO",
    city_name: "Linn Creek",
  },
  {
    postal_code: "34734",
    full_city_name: "Gotha, FL",
    city_name: "Gotha",
  },
  {
    postal_code: "46118",
    full_city_name: "Clayton, IN",
    city_name: "Clayton",
  },
  {
    postal_code: "03217",
    full_city_name: "Ashland, NH",
    city_name: "Ashland",
  },
  {
    postal_code: "16229",
    full_city_name: "Freeport, PA",
    city_name: "Freeport",
  },
  {
    postal_code: "14891",
    full_city_name: "Watkins Glen, NY",
    city_name: "Watkins Glen",
  },
  {
    postal_code: "84647",
    full_city_name: "Mount Pleasant, UT",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "03585",
    full_city_name: "Lisbon, NH",
    city_name: "Lisbon",
  },
  {
    postal_code: "02766",
    full_city_name: "Norton, MA",
    city_name: "Norton",
  },
  {
    postal_code: "02347",
    full_city_name: "Lakeville, MA",
    city_name: "Lakeville",
  },
  {
    postal_code: "01757",
    full_city_name: "Milford, MA",
    city_name: "Milford",
  },
  {
    postal_code: "45660",
    full_city_name: "Peebles, OH",
    city_name: "Peebles",
  },
  {
    postal_code: "48637",
    full_city_name: "Merrill, MI",
    city_name: "Merrill",
  },
  {
    postal_code: "06248",
    full_city_name: "Hebron, CT",
    city_name: "Hebron",
  },
  {
    postal_code: "94158",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "77657",
    full_city_name: "Lumberton, TX",
    city_name: "Lumberton",
  },
  {
    postal_code: "85631",
    full_city_name: "San Manuel, AZ",
    city_name: "San Manuel",
  },
  {
    postal_code: "70466",
    full_city_name: "Tickfaw, LA",
    city_name: "Tickfaw",
  },
  {
    postal_code: "96795",
    full_city_name: "Waimanalo, HI",
    city_name: "Waimanalo",
  },
  {
    postal_code: "93630",
    full_city_name: "Kerman, CA",
    city_name: "Kerman",
  },
  {
    postal_code: "93960",
    full_city_name: "Soledad, CA",
    city_name: "Soledad",
  },
  {
    postal_code: "16441",
    full_city_name: "Waterford, PA",
    city_name: "Waterford",
  },
  {
    postal_code: "55812",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "28129",
    full_city_name: "Oakboro, NC",
    city_name: "Oakboro",
  },
  {
    postal_code: "24557",
    full_city_name: "Gretna, VA",
    city_name: "Gretna",
  },
  {
    postal_code: "62690",
    full_city_name: "Virden, IL",
    city_name: "Virden",
  },
  {
    postal_code: "02332",
    full_city_name: "Duxbury, MA",
    city_name: "Duxbury",
  },
  {
    postal_code: "37690",
    full_city_name: "Telford, TN",
    city_name: "Telford",
  },
  {
    postal_code: "56063",
    full_city_name: "Madison Lake, MN",
    city_name: "Madison Lake",
  },
  {
    postal_code: "54727",
    full_city_name: "Cadott, WI",
    city_name: "Cadott",
  },
  {
    postal_code: "54871",
    full_city_name: "Shell Lake, WI",
    city_name: "Shell Lake",
  },
  {
    postal_code: "21773",
    full_city_name: "Myersville, MD",
    city_name: "Myersville",
  },
  {
    postal_code: "01890",
    full_city_name: "Winchester, MA",
    city_name: "Winchester",
  },
  {
    postal_code: "29429",
    full_city_name: "Awendaw, SC",
    city_name: "Awendaw",
  },
  {
    postal_code: "61047",
    full_city_name: "Leaf River, IL",
    city_name: "Leaf River",
  },
  {
    postal_code: "44440",
    full_city_name: "Mineral Ridge, OH",
    city_name: "Mineral Ridge",
  },
  {
    postal_code: "08732",
    full_city_name: "Island Heights, NJ",
    city_name: "Island Heights",
  },
  {
    postal_code: "02189",
    full_city_name: "East Weymouth, MA",
    city_name: "East Weymouth",
  },
  {
    postal_code: "06249",
    full_city_name: "Lebanon, CT",
    city_name: "Lebanon",
  },
  {
    postal_code: "79079",
    full_city_name: "Shamrock, TX",
    city_name: "Shamrock",
  },
  {
    postal_code: "37098",
    full_city_name: "Lyles, TN",
    city_name: "Lyles",
  },
  {
    postal_code: "22213",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "40076",
    full_city_name: "Waddy, KY",
    city_name: "Waddy",
  },
  {
    postal_code: "39556",
    full_city_name: "Kiln, MS",
    city_name: "Kiln",
  },
  {
    postal_code: "92241",
    full_city_name: "Desert Hot Springs, CA",
    city_name: "Desert Hot Springs",
  },
  {
    postal_code: "30104",
    full_city_name: "Aragon, GA",
    city_name: "Aragon",
  },
  {
    postal_code: "97366",
    full_city_name: "South Beach, OR",
    city_name: "South Beach",
  },
  {
    postal_code: "28518",
    full_city_name: "Beulaville, NC",
    city_name: "Beulaville",
  },
  {
    postal_code: "44262",
    full_city_name: "Munroe Falls, OH",
    city_name: "Munroe Falls",
  },
  {
    postal_code: "02720",
    full_city_name: "Fall River, MA",
    city_name: "Fall River",
  },
  {
    postal_code: "90670",
    full_city_name: "Santa Fe Springs, CA",
    city_name: "Santa Fe Springs",
  },
  {
    postal_code: "06260",
    full_city_name: "Putnam, CT",
    city_name: "Putnam",
  },
  {
    postal_code: "64145",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "96142",
    full_city_name: "Tahoma, CA",
    city_name: "Tahoma",
  },
  {
    postal_code: "19102",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "01760",
    full_city_name: "Natick, MA",
    city_name: "Natick",
  },
  {
    postal_code: "19405",
    full_city_name: "Bridgeport, PA",
    city_name: "Bridgeport",
  },
  {
    postal_code: "01824",
    full_city_name: "Chelmsford, MA",
    city_name: "Chelmsford",
  },
  {
    postal_code: "08107",
    full_city_name: "Oaklyn, NJ",
    city_name: "Oaklyn",
  },
  {
    postal_code: "72103",
    full_city_name: "Mabelvale, AR",
    city_name: "Mabelvale",
  },
  {
    postal_code: "52358",
    full_city_name: "West Branch, IA",
    city_name: "West Branch",
  },
  {
    postal_code: "89704",
    full_city_name: "Washoe Valley, NV",
    city_name: "Washoe Valley",
  },
  {
    postal_code: "95665",
    full_city_name: "Pine Grove, CA",
    city_name: "Pine Grove",
  },
  {
    postal_code: "49938",
    full_city_name: "Ironwood, MI",
    city_name: "Ironwood",
  },
  {
    postal_code: "62626",
    full_city_name: "Carlinville, IL",
    city_name: "Carlinville",
  },
  {
    postal_code: "03766",
    full_city_name: "Lebanon, NH",
    city_name: "Lebanon",
  },
  {
    postal_code: "68410",
    full_city_name: "Nebraska City, NE",
    city_name: "Nebraska City",
  },
  {
    postal_code: "33786",
    full_city_name: "Belleair Beach, FL",
    city_name: "Belleair Beach",
  },
  {
    postal_code: "55723",
    full_city_name: "Cook, MN",
    city_name: "Cook",
  },
  {
    postal_code: "28454",
    full_city_name: "Maple Hill, NC",
    city_name: "Maple Hill",
  },
  {
    postal_code: "37640",
    full_city_name: "Butler, TN",
    city_name: "Butler",
  },
  {
    postal_code: "45052",
    full_city_name: "North Bend, OH",
    city_name: "North Bend",
  },
  {
    postal_code: "30753",
    full_city_name: "Trion, GA",
    city_name: "Trion",
  },
  {
    postal_code: "44072",
    full_city_name: "Novelty, OH",
    city_name: "Novelty",
  },
  {
    postal_code: "37709",
    full_city_name: "Blaine, TN",
    city_name: "Blaine",
  },
  {
    postal_code: "70075",
    full_city_name: "Meraux, LA",
    city_name: "Meraux",
  },
  {
    postal_code: "29654",
    full_city_name: "Honea Path, SC",
    city_name: "Honea Path",
  },
  {
    postal_code: "96122",
    full_city_name: "Portola, CA",
    city_name: "Portola",
  },
  {
    postal_code: "75859",
    full_city_name: "Streetman, TX",
    city_name: "Streetman",
  },
  {
    postal_code: "08512",
    full_city_name: "Cranbury, NJ",
    city_name: "Cranbury",
  },
  {
    postal_code: "11797",
    full_city_name: "Woodbury, NY",
    city_name: "Woodbury",
  },
  {
    postal_code: "76066",
    full_city_name: "Millsap, TX",
    city_name: "Millsap",
  },
  {
    postal_code: "15461",
    full_city_name: "Masontown, PA",
    city_name: "Masontown",
  },
  {
    postal_code: "11369",
    full_city_name: "East Elmhurst, NY",
    city_name: "East Elmhurst",
  },
  {
    postal_code: "06335",
    full_city_name: "Gales Ferry, CT",
    city_name: "Gales Ferry",
  },
  {
    postal_code: "26070",
    full_city_name: "Wellsburg, WV",
    city_name: "Wellsburg",
  },
  {
    postal_code: "28377",
    full_city_name: "Red Springs, NC",
    city_name: "Red Springs",
  },
  {
    postal_code: "75124",
    full_city_name: "Eustace, TX",
    city_name: "Eustace",
  },
  {
    postal_code: "02670",
    full_city_name: "West Dennis, MA",
    city_name: "West Dennis",
  },
  {
    postal_code: "94920",
    full_city_name: "Belvedere Tiburon, CA",
    city_name: "Belvedere Tiburon",
  },
  {
    postal_code: "53531",
    full_city_name: "Deerfield, WI",
    city_name: "Deerfield",
  },
  {
    postal_code: "75932",
    full_city_name: "Burkeville, TX",
    city_name: "Burkeville",
  },
  {
    postal_code: "53955",
    full_city_name: "Poynette, WI",
    city_name: "Poynette",
  },
  {
    postal_code: "21048",
    full_city_name: "Finksburg, MD",
    city_name: "Finksburg",
  },
  {
    postal_code: "78383",
    full_city_name: "Sandia, TX",
    city_name: "Sandia",
  },
  {
    postal_code: "93604",
    full_city_name: "Bass Lake, CA",
    city_name: "Bass Lake",
  },
  {
    postal_code: "27260",
    full_city_name: "High Point, NC",
    city_name: "High Point",
  },
  {
    postal_code: "45304",
    full_city_name: "Arcanum, OH",
    city_name: "Arcanum",
  },
  {
    postal_code: "24368",
    full_city_name: "Rural Retreat, VA",
    city_name: "Rural Retreat",
  },
  {
    postal_code: "77085",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "75860",
    full_city_name: "Teague, TX",
    city_name: "Teague",
  },
  {
    postal_code: "54838",
    full_city_name: "Gordon, WI",
    city_name: "Gordon",
  },
  {
    postal_code: "98253",
    full_city_name: "Greenbank, WA",
    city_name: "Greenbank",
  },
  {
    postal_code: "03461",
    full_city_name: "Rindge, NH",
    city_name: "Rindge",
  },
  {
    postal_code: "12962",
    full_city_name: "Morrisonville, NY",
    city_name: "Morrisonville",
  },
  {
    postal_code: "28586",
    full_city_name: "Vanceboro, NC",
    city_name: "Vanceboro",
  },
  {
    postal_code: "80650",
    full_city_name: "Pierce, CO",
    city_name: "Pierce",
  },
  {
    postal_code: "80466",
    full_city_name: "Nederland, CO",
    city_name: "Nederland",
  },
  {
    postal_code: "46310",
    full_city_name: "Demotte, IN",
    city_name: "Demotte",
  },
  {
    postal_code: "10039",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "43149",
    full_city_name: "Rockbridge, OH",
    city_name: "Rockbridge",
  },
  {
    postal_code: "71302",
    full_city_name: "Alexandria, LA",
    city_name: "Alexandria",
  },
  {
    postal_code: "83110",
    full_city_name: "Afton, WY",
    city_name: "Afton",
  },
  {
    postal_code: "48866",
    full_city_name: "Ovid, MI",
    city_name: "Ovid",
  },
  {
    postal_code: "03032",
    full_city_name: "Auburn, NH",
    city_name: "Auburn",
  },
  {
    postal_code: "54180",
    full_city_name: "Wrightstown, WI",
    city_name: "Wrightstown",
  },
  {
    postal_code: "16438",
    full_city_name: "Union City, PA",
    city_name: "Union City",
  },
  {
    postal_code: "65240",
    full_city_name: "Centralia, MO",
    city_name: "Centralia",
  },
  {
    postal_code: "95005",
    full_city_name: "Ben Lomond, CA",
    city_name: "Ben Lomond",
  },
  {
    postal_code: "20794",
    full_city_name: "Jessup, MD",
    city_name: "Jessup",
  },
  {
    postal_code: "01880",
    full_city_name: "Wakefield, MA",
    city_name: "Wakefield",
  },
  {
    postal_code: "53954",
    full_city_name: "Pardeeville, WI",
    city_name: "Pardeeville",
  },
  {
    postal_code: "54562",
    full_city_name: "Three Lakes, WI",
    city_name: "Three Lakes",
  },
  {
    postal_code: "64747",
    full_city_name: "Garden City, MO",
    city_name: "Garden City",
  },
  {
    postal_code: "54002",
    full_city_name: "Baldwin, WI",
    city_name: "Baldwin",
  },
  {
    postal_code: "29685",
    full_city_name: "Sunset, SC",
    city_name: "Sunset",
  },
  {
    postal_code: "92084",
    full_city_name: "Vista, CA",
    city_name: "Vista",
  },
  {
    postal_code: "25130",
    full_city_name: "Madison, WV",
    city_name: "Madison",
  },
  {
    postal_code: "53805",
    full_city_name: "Boscobel, WI",
    city_name: "Boscobel",
  },
  {
    postal_code: "78752",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "46126",
    full_city_name: "Fairland, IN",
    city_name: "Fairland",
  },
  {
    postal_code: "99003",
    full_city_name: "Chattaroy, WA",
    city_name: "Chattaroy",
  },
  {
    postal_code: "03748",
    full_city_name: "Enfield, NH",
    city_name: "Enfield",
  },
  {
    postal_code: "95316",
    full_city_name: "Denair, CA",
    city_name: "Denair",
  },
  {
    postal_code: "42366",
    full_city_name: "Philpot, KY",
    city_name: "Philpot",
  },
  {
    postal_code: "61880",
    full_city_name: "Tolono, IL",
    city_name: "Tolono",
  },
  {
    postal_code: "11429",
    full_city_name: "Queens Village, NY",
    city_name: "Queens Village",
  },
  {
    postal_code: "12481",
    full_city_name: "Shokan, NY",
    city_name: "Shokan",
  },
  {
    postal_code: "02048",
    full_city_name: "Mansfield, MA",
    city_name: "Mansfield",
  },
  {
    postal_code: "21541",
    full_city_name: "Mc Henry, MD",
    city_name: "Mc Henry",
  },
  {
    postal_code: "50261",
    full_city_name: "Van Meter, IA",
    city_name: "Van Meter",
  },
  {
    postal_code: "78726",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "68970",
    full_city_name: "Red Cloud, NE",
    city_name: "Red Cloud",
  },
  {
    postal_code: "13743",
    full_city_name: "Candor, NY",
    city_name: "Candor",
  },
  {
    postal_code: "54140",
    full_city_name: "Little Chute, WI",
    city_name: "Little Chute",
  },
  {
    postal_code: "18510",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "47904",
    full_city_name: "Lafayette, IN",
    city_name: "Lafayette",
  },
  {
    postal_code: "30683",
    full_city_name: "Winterville, GA",
    city_name: "Winterville",
  },
  {
    postal_code: "55037",
    full_city_name: "Hinckley, MN",
    city_name: "Hinckley",
  },
  {
    postal_code: "12919",
    full_city_name: "Champlain, NY",
    city_name: "Champlain",
  },
  {
    postal_code: "76527",
    full_city_name: "Florence, TX",
    city_name: "Florence",
  },
  {
    postal_code: "13439",
    full_city_name: "Richfield Springs, NY",
    city_name: "Richfield Springs",
  },
  {
    postal_code: "28763",
    full_city_name: "Otto, NC",
    city_name: "Otto",
  },
  {
    postal_code: "61240",
    full_city_name: "Coal Valley, IL",
    city_name: "Coal Valley",
  },
  {
    postal_code: "53565",
    full_city_name: "Mineral Point, WI",
    city_name: "Mineral Point",
  },
  {
    postal_code: "93953",
    full_city_name: "Pebble Beach, CA",
    city_name: "Pebble Beach",
  },
  {
    postal_code: "46403",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "78957",
    full_city_name: "Smithville, TX",
    city_name: "Smithville",
  },
  {
    postal_code: "63073",
    full_city_name: "Saint Albans, MO",
    city_name: "Saint Albans",
  },
  {
    postal_code: "61530",
    full_city_name: "Eureka, IL",
    city_name: "Eureka",
  },
  {
    postal_code: "27510",
    full_city_name: "Carrboro, NC",
    city_name: "Carrboro",
  },
  {
    postal_code: "36582",
    full_city_name: "Theodore, AL",
    city_name: "Theodore",
  },
  {
    postal_code: "49448",
    full_city_name: "Nunica, MI",
    city_name: "Nunica",
  },
  {
    postal_code: "79070",
    full_city_name: "Perryton, TX",
    city_name: "Perryton",
  },
  {
    postal_code: "84627",
    full_city_name: "Ephraim, UT",
    city_name: "Ephraim",
  },
  {
    postal_code: "72738",
    full_city_name: "Hindsville, AR",
    city_name: "Hindsville",
  },
  {
    postal_code: "11789",
    full_city_name: "Sound Beach, NY",
    city_name: "Sound Beach",
  },
  {
    postal_code: "98953",
    full_city_name: "Zillah, WA",
    city_name: "Zillah",
  },
  {
    postal_code: "47220",
    full_city_name: "Brownstown, IN",
    city_name: "Brownstown",
  },
  {
    postal_code: "77547",
    full_city_name: "Galena Park, TX",
    city_name: "Galena Park",
  },
  {
    postal_code: "01940",
    full_city_name: "Lynnfield, MA",
    city_name: "Lynnfield",
  },
  {
    postal_code: "72086",
    full_city_name: "Lonoke, AR",
    city_name: "Lonoke",
  },
  {
    postal_code: "93013",
    full_city_name: "Carpinteria, CA",
    city_name: "Carpinteria",
  },
  {
    postal_code: "11219",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "45219",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "84762",
    full_city_name: "Duck Creek Village, UT",
    city_name: "Duck Creek Village",
  },
  {
    postal_code: "49129",
    full_city_name: "Union Pier, MI",
    city_name: "Union Pier",
  },
  {
    postal_code: "52003",
    full_city_name: "Dubuque, IA",
    city_name: "Dubuque",
  },
  {
    postal_code: "42602",
    full_city_name: "Albany, KY",
    city_name: "Albany",
  },
  {
    postal_code: "65648",
    full_city_name: "Fair Grove, MO",
    city_name: "Fair Grove",
  },
  {
    postal_code: "12526",
    full_city_name: "Germantown, NY",
    city_name: "Germantown",
  },
  {
    postal_code: "43912",
    full_city_name: "Bridgeport, OH",
    city_name: "Bridgeport",
  },
  {
    postal_code: "52748",
    full_city_name: "Eldridge, IA",
    city_name: "Eldridge",
  },
  {
    postal_code: "79902",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "95018",
    full_city_name: "Felton, CA",
    city_name: "Felton",
  },
  {
    postal_code: "27316",
    full_city_name: "Ramseur, NC",
    city_name: "Ramseur",
  },
  {
    postal_code: "61252",
    full_city_name: "Fulton, IL",
    city_name: "Fulton",
  },
  {
    postal_code: "37880",
    full_city_name: "Ten Mile, TN",
    city_name: "Ten Mile",
  },
  {
    postal_code: "90232",
    full_city_name: "Culver City, CA",
    city_name: "Culver City",
  },
  {
    postal_code: "45347",
    full_city_name: "New Paris, OH",
    city_name: "New Paris",
  },
  {
    postal_code: "56621",
    full_city_name: "Bagley, MN",
    city_name: "Bagley",
  },
  {
    postal_code: "12148",
    full_city_name: "Rexford, NY",
    city_name: "Rexford",
  },
  {
    postal_code: "84754",
    full_city_name: "Monroe, UT",
    city_name: "Monroe",
  },
  {
    postal_code: "20837",
    full_city_name: "Poolesville, MD",
    city_name: "Poolesville",
  },
  {
    postal_code: "86322",
    full_city_name: "Camp Verde, AZ",
    city_name: "Camp Verde",
  },
  {
    postal_code: "54568",
    full_city_name: "Woodruff, WI",
    city_name: "Woodruff",
  },
  {
    postal_code: "48117",
    full_city_name: "Carleton, MI",
    city_name: "Carleton",
  },
  {
    postal_code: "49013",
    full_city_name: "Bangor, MI",
    city_name: "Bangor",
  },
  {
    postal_code: "66053",
    full_city_name: "Louisburg, KS",
    city_name: "Louisburg",
  },
  {
    postal_code: "04841",
    full_city_name: "Rockland, ME",
    city_name: "Rockland",
  },
  {
    postal_code: "46567",
    full_city_name: "Syracuse, IN",
    city_name: "Syracuse",
  },
  {
    postal_code: "01749",
    full_city_name: "Hudson, MA",
    city_name: "Hudson",
  },
  {
    postal_code: "56467",
    full_city_name: "Nevis, MN",
    city_name: "Nevis",
  },
  {
    postal_code: "02341",
    full_city_name: "Hanson, MA",
    city_name: "Hanson",
  },
  {
    postal_code: "98936",
    full_city_name: "Moxee, WA",
    city_name: "Moxee",
  },
  {
    postal_code: "27939",
    full_city_name: "Grandy, NC",
    city_name: "Grandy",
  },
  {
    postal_code: "96021",
    full_city_name: "Corning, CA",
    city_name: "Corning",
  },
  {
    postal_code: "22625",
    full_city_name: "Cross Junction, VA",
    city_name: "Cross Junction",
  },
  {
    postal_code: "11786",
    full_city_name: "Shoreham, NY",
    city_name: "Shoreham",
  },
  {
    postal_code: "43543",
    full_city_name: "Montpelier, OH",
    city_name: "Montpelier",
  },
  {
    postal_code: "60203",
    full_city_name: "Evanston, IL",
    city_name: "Evanston",
  },
  {
    postal_code: "01867",
    full_city_name: "Reading, MA",
    city_name: "Reading",
  },
  {
    postal_code: "34946",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "32102",
    full_city_name: "Astor, FL",
    city_name: "Astor",
  },
  {
    postal_code: "29439",
    full_city_name: "Folly Beach, SC",
    city_name: "Folly Beach",
  },
  {
    postal_code: "59730",
    full_city_name: "Gallatin Gateway, MT",
    city_name: "Gallatin Gateway",
  },
  {
    postal_code: "90056",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "36551",
    full_city_name: "Loxley, AL",
    city_name: "Loxley",
  },
  {
    postal_code: "28743",
    full_city_name: "Hot Springs, NC",
    city_name: "Hot Springs",
  },
  {
    postal_code: "38826",
    full_city_name: "Belden, MS",
    city_name: "Belden",
  },
  {
    postal_code: "01235",
    full_city_name: "Hinsdale, MA",
    city_name: "Hinsdale",
  },
  {
    postal_code: "15046",
    full_city_name: "Crescent, PA",
    city_name: "Crescent",
  },
  {
    postal_code: "54428",
    full_city_name: "Elcho, WI",
    city_name: "Elcho",
  },
  {
    postal_code: "80651",
    full_city_name: "Platteville, CO",
    city_name: "Platteville",
  },
  {
    postal_code: "30641",
    full_city_name: "Good Hope, GA",
    city_name: "Good Hope",
  },
  {
    postal_code: "68110",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "14814",
    full_city_name: "Big Flats, NY",
    city_name: "Big Flats",
  },
  {
    postal_code: "56085",
    full_city_name: "Sleepy Eye, MN",
    city_name: "Sleepy Eye",
  },
  {
    postal_code: "55805",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "26250",
    full_city_name: "Belington, WV",
    city_name: "Belington",
  },
  {
    postal_code: "65324",
    full_city_name: "Climax Springs, MO",
    city_name: "Climax Springs",
  },
  {
    postal_code: "37860",
    full_city_name: "Russellville, TN",
    city_name: "Russellville",
  },
  {
    postal_code: "40202",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "02021",
    full_city_name: "Canton, MA",
    city_name: "Canton",
  },
  {
    postal_code: "20639",
    full_city_name: "Huntingtown, MD",
    city_name: "Huntingtown",
  },
  {
    postal_code: "36575",
    full_city_name: "Semmes, AL",
    city_name: "Semmes",
  },
  {
    postal_code: "02062",
    full_city_name: "Norwood, MA",
    city_name: "Norwood",
  },
  {
    postal_code: "08542",
    full_city_name: "Princeton, NJ",
    city_name: "Princeton",
  },
  {
    postal_code: "24360",
    full_city_name: "Max Meadows, VA",
    city_name: "Max Meadows",
  },
  {
    postal_code: "06757",
    full_city_name: "Kent, CT",
    city_name: "Kent",
  },
  {
    postal_code: "51537",
    full_city_name: "Harlan, IA",
    city_name: "Harlan",
  },
  {
    postal_code: "43947",
    full_city_name: "Shadyside, OH",
    city_name: "Shadyside",
  },
  {
    postal_code: "06250",
    full_city_name: "Mansfield Center, CT",
    city_name: "Mansfield Center",
  },
  {
    postal_code: "70437",
    full_city_name: "Folsom, LA",
    city_name: "Folsom",
  },
  {
    postal_code: "04210",
    full_city_name: "Auburn, ME",
    city_name: "Auburn",
  },
  {
    postal_code: "30530",
    full_city_name: "Commerce, GA",
    city_name: "Commerce",
  },
  {
    postal_code: "13849",
    full_city_name: "Unadilla, NY",
    city_name: "Unadilla",
  },
  {
    postal_code: "24266",
    full_city_name: "Lebanon, VA",
    city_name: "Lebanon",
  },
  {
    postal_code: "02822",
    full_city_name: "Exeter, RI",
    city_name: "Exeter",
  },
  {
    postal_code: "60512",
    full_city_name: "Bristol, IL",
    city_name: "Bristol",
  },
  {
    postal_code: "13350",
    full_city_name: "Herkimer, NY",
    city_name: "Herkimer",
  },
  {
    postal_code: "54015",
    full_city_name: "Hammond, WI",
    city_name: "Hammond",
  },
  {
    postal_code: "90036",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "44432",
    full_city_name: "Lisbon, OH",
    city_name: "Lisbon",
  },
  {
    postal_code: "78065",
    full_city_name: "Poteet, TX",
    city_name: "Poteet",
  },
  {
    postal_code: "02324",
    full_city_name: "Bridgewater, MA",
    city_name: "Bridgewater",
  },
  {
    postal_code: "35083",
    full_city_name: "Holly Pond, AL",
    city_name: "Holly Pond",
  },
  {
    postal_code: "03446",
    full_city_name: "Swanzey, NH",
    city_name: "Swanzey",
  },
  {
    postal_code: "04281",
    full_city_name: "South Paris, ME",
    city_name: "South Paris",
  },
  {
    postal_code: "38606",
    full_city_name: "Batesville, MS",
    city_name: "Batesville",
  },
  {
    postal_code: "52726",
    full_city_name: "Blue Grass, IA",
    city_name: "Blue Grass",
  },
  {
    postal_code: "04043",
    full_city_name: "Kennebunk, ME",
    city_name: "Kennebunk",
  },
  {
    postal_code: "24219",
    full_city_name: "Big Stone Gap, VA",
    city_name: "Big Stone Gap",
  },
  {
    postal_code: "99204",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "81432",
    full_city_name: "Ridgway, CO",
    city_name: "Ridgway",
  },
  {
    postal_code: "13783",
    full_city_name: "Hancock, NY",
    city_name: "Hancock",
  },
  {
    postal_code: "38701",
    full_city_name: "Greenville, MS",
    city_name: "Greenville",
  },
  {
    postal_code: "40447",
    full_city_name: "Mc Kee, KY",
    city_name: "Mc Kee",
  },
  {
    postal_code: "54840",
    full_city_name: "Grantsburg, WI",
    city_name: "Grantsburg",
  },
  {
    postal_code: "94805",
    full_city_name: "Richmond, CA",
    city_name: "Richmond",
  },
  {
    postal_code: "01803",
    full_city_name: "Burlington, MA",
    city_name: "Burlington",
  },
  {
    postal_code: "51566",
    full_city_name: "Red Oak, IA",
    city_name: "Red Oak",
  },
  {
    postal_code: "99344",
    full_city_name: "Othello, WA",
    city_name: "Othello",
  },
  {
    postal_code: "33592",
    full_city_name: "Thonotosassa, FL",
    city_name: "Thonotosassa",
  },
  {
    postal_code: "56474",
    full_city_name: "Pine River, MN",
    city_name: "Pine River",
  },
  {
    postal_code: "46181",
    full_city_name: "Trafalgar, IN",
    city_name: "Trafalgar",
  },
  {
    postal_code: "12076",
    full_city_name: "Gilboa, NY",
    city_name: "Gilboa",
  },
  {
    postal_code: "20832",
    full_city_name: "Olney, MD",
    city_name: "Olney",
  },
  {
    postal_code: "20141",
    full_city_name: "Round Hill, VA",
    city_name: "Round Hill",
  },
  {
    postal_code: "92657",
    full_city_name: "Newport Coast, CA",
    city_name: "Newport Coast",
  },
  {
    postal_code: "85552",
    full_city_name: "Thatcher, AZ",
    city_name: "Thatcher",
  },
  {
    postal_code: "92590",
    full_city_name: "Temecula, CA",
    city_name: "Temecula",
  },
  {
    postal_code: "19096",
    full_city_name: "Wynnewood, PA",
    city_name: "Wynnewood",
  },
  {
    postal_code: "12846",
    full_city_name: "Lake Luzerne, NY",
    city_name: "Lake Luzerne",
  },
  {
    postal_code: "29506",
    full_city_name: "Florence, SC",
    city_name: "Florence",
  },
  {
    postal_code: "54872",
    full_city_name: "Siren, WI",
    city_name: "Siren",
  },
  {
    postal_code: "44040",
    full_city_name: "Gates Mills, OH",
    city_name: "Gates Mills",
  },
  {
    postal_code: "48890",
    full_city_name: "Sunfield, MI",
    city_name: "Sunfield",
  },
  {
    postal_code: "95033",
    full_city_name: "Los Gatos, CA",
    city_name: "Los Gatos",
  },
  {
    postal_code: "95073",
    full_city_name: "Soquel, CA",
    city_name: "Soquel",
  },
  {
    postal_code: "06237",
    full_city_name: "Columbia, CT",
    city_name: "Columbia",
  },
  {
    postal_code: "45714",
    full_city_name: "Belpre, OH",
    city_name: "Belpre",
  },
  {
    postal_code: "50213",
    full_city_name: "Osceola, IA",
    city_name: "Osceola",
  },
  {
    postal_code: "02831",
    full_city_name: "Hope, RI",
    city_name: "Hope",
  },
  {
    postal_code: "12117",
    full_city_name: "Mayfield, NY",
    city_name: "Mayfield",
  },
  {
    postal_code: "55373",
    full_city_name: "Rockford, MN",
    city_name: "Rockford",
  },
  {
    postal_code: "27921",
    full_city_name: "Camden, NC",
    city_name: "Camden",
  },
  {
    postal_code: "04011",
    full_city_name: "Brunswick, ME",
    city_name: "Brunswick",
  },
  {
    postal_code: "72774",
    full_city_name: "West Fork, AR",
    city_name: "West Fork",
  },
  {
    postal_code: "55349",
    full_city_name: "Howard Lake, MN",
    city_name: "Howard Lake",
  },
  {
    postal_code: "78264",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "93532",
    full_city_name: "Lake Hughes, CA",
    city_name: "Lake Hughes",
  },
  {
    postal_code: "12123",
    full_city_name: "Nassau, NY",
    city_name: "Nassau",
  },
  {
    postal_code: "54001",
    full_city_name: "Amery, WI",
    city_name: "Amery",
  },
  {
    postal_code: "84042",
    full_city_name: "Lindon, UT",
    city_name: "Lindon",
  },
  {
    postal_code: "56461",
    full_city_name: "Laporte, MN",
    city_name: "Laporte",
  },
  {
    postal_code: "61610",
    full_city_name: "Creve Coeur, IL",
    city_name: "Creve Coeur",
  },
  {
    postal_code: "78343",
    full_city_name: "Bishop, TX",
    city_name: "Bishop",
  },
  {
    postal_code: "45130",
    full_city_name: "Hamersville, OH",
    city_name: "Hamersville",
  },
  {
    postal_code: "35171",
    full_city_name: "Thorsby, AL",
    city_name: "Thorsby",
  },
  {
    postal_code: "12167",
    full_city_name: "Stamford, NY",
    city_name: "Stamford",
  },
  {
    postal_code: "24558",
    full_city_name: "Halifax, VA",
    city_name: "Halifax",
  },
  {
    postal_code: "11379",
    full_city_name: "Middle Village, NY",
    city_name: "Middle Village",
  },
  {
    postal_code: "59034",
    full_city_name: "Hardin, MT",
    city_name: "Hardin",
  },
  {
    postal_code: "11691",
    full_city_name: "Far Rockaway, NY",
    city_name: "Far Rockaway",
  },
  {
    postal_code: "77418",
    full_city_name: "Bellville, TX",
    city_name: "Bellville",
  },
  {
    postal_code: "28103",
    full_city_name: "Marshville, NC",
    city_name: "Marshville",
  },
  {
    postal_code: "76567",
    full_city_name: "Rockdale, TX",
    city_name: "Rockdale",
  },
  {
    postal_code: "70422",
    full_city_name: "Amite, LA",
    city_name: "Amite",
  },
  {
    postal_code: "70032",
    full_city_name: "Arabi, LA",
    city_name: "Arabi",
  },
  {
    postal_code: "30607",
    full_city_name: "Athens, GA",
    city_name: "Athens",
  },
  {
    postal_code: "56265",
    full_city_name: "Montevideo, MN",
    city_name: "Montevideo",
  },
  {
    postal_code: "43832",
    full_city_name: "Newcomerstown, OH",
    city_name: "Newcomerstown",
  },
  {
    postal_code: "36078",
    full_city_name: "Tallassee, AL",
    city_name: "Tallassee",
  },
  {
    postal_code: "17350",
    full_city_name: "New Oxford, PA",
    city_name: "New Oxford",
  },
  {
    postal_code: "45142",
    full_city_name: "Lynchburg, OH",
    city_name: "Lynchburg",
  },
  {
    postal_code: "44413",
    full_city_name: "East Palestine, OH",
    city_name: "East Palestine",
  },
  {
    postal_code: "64129",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "28098",
    full_city_name: "Lowell, NC",
    city_name: "Lowell",
  },
  {
    postal_code: "35186",
    full_city_name: "Wilsonville, AL",
    city_name: "Wilsonville",
  },
  {
    postal_code: "16037",
    full_city_name: "Harmony, PA",
    city_name: "Harmony",
  },
  {
    postal_code: "28018",
    full_city_name: "Bostic, NC",
    city_name: "Bostic",
  },
  {
    postal_code: "61102",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "43607",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "06426",
    full_city_name: "Essex, CT",
    city_name: "Essex",
  },
  {
    postal_code: "15902",
    full_city_name: "Johnstown, PA",
    city_name: "Johnstown",
  },
  {
    postal_code: "79363",
    full_city_name: "Shallowater, TX",
    city_name: "Shallowater",
  },
  {
    postal_code: "26101",
    full_city_name: "Parkersburg, WV",
    city_name: "Parkersburg",
  },
  {
    postal_code: "50212",
    full_city_name: "Ogden, IA",
    city_name: "Ogden",
  },
  {
    postal_code: "11106",
    full_city_name: "Astoria, NY",
    city_name: "Astoria",
  },
  {
    postal_code: "24517",
    full_city_name: "Altavista, VA",
    city_name: "Altavista",
  },
  {
    postal_code: "18421",
    full_city_name: "Forest City, PA",
    city_name: "Forest City",
  },
  {
    postal_code: "78616",
    full_city_name: "Dale, TX",
    city_name: "Dale",
  },
  {
    postal_code: "54024",
    full_city_name: "Saint Croix Falls, WI",
    city_name: "Saint Croix Falls",
  },
  {
    postal_code: "52353",
    full_city_name: "Washington, IA",
    city_name: "Washington",
  },
  {
    postal_code: "27214",
    full_city_name: "Browns Summit, NC",
    city_name: "Browns Summit",
  },
  {
    postal_code: "90301",
    full_city_name: "Inglewood, CA",
    city_name: "Inglewood",
  },
  {
    postal_code: "85735",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "02118",
    full_city_name: "Boston, MA",
    city_name: "Boston",
  },
  {
    postal_code: "32040",
    full_city_name: "Glen Saint Mary, FL",
    city_name: "Glen Saint Mary",
  },
  {
    postal_code: "53598",
    full_city_name: "Windsor, WI",
    city_name: "Windsor",
  },
  {
    postal_code: "21204",
    full_city_name: "Towson, MD",
    city_name: "Towson",
  },
  {
    postal_code: "10466",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "20607",
    full_city_name: "Accokeek, MD",
    city_name: "Accokeek",
  },
  {
    postal_code: "64040",
    full_city_name: "Holden, MO",
    city_name: "Holden",
  },
  {
    postal_code: "12842",
    full_city_name: "Indian Lake, NY",
    city_name: "Indian Lake",
  },
  {
    postal_code: "06901",
    full_city_name: "Stamford, CT",
    city_name: "Stamford",
  },
  {
    postal_code: "40069",
    full_city_name: "Springfield, KY",
    city_name: "Springfield",
  },
  {
    postal_code: "74631",
    full_city_name: "Blackwell, OK",
    city_name: "Blackwell",
  },
  {
    postal_code: "55020",
    full_city_name: "Elko New Market, MN",
    city_name: "Elko New Market",
  },
  {
    postal_code: "45430",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "37031",
    full_city_name: "Castalian Springs, TN",
    city_name: "Castalian Springs",
  },
  {
    postal_code: "03290",
    full_city_name: "Nottingham, NH",
    city_name: "Nottingham",
  },
  {
    postal_code: "90755",
    full_city_name: "Signal Hill, CA",
    city_name: "Signal Hill",
  },
  {
    postal_code: "15320",
    full_city_name: "Carmichaels, PA",
    city_name: "Carmichaels",
  },
  {
    postal_code: "96790",
    full_city_name: "Kula, HI",
    city_name: "Kula",
  },
  {
    postal_code: "17740",
    full_city_name: "Jersey Shore, PA",
    city_name: "Jersey Shore",
  },
  {
    postal_code: "40507",
    full_city_name: "Lexington, KY",
    city_name: "Lexington",
  },
  {
    postal_code: "44233",
    full_city_name: "Hinckley, OH",
    city_name: "Hinckley",
  },
  {
    postal_code: "90013",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "49878",
    full_city_name: "Rapid River, MI",
    city_name: "Rapid River",
  },
  {
    postal_code: "43968",
    full_city_name: "Wellsville, OH",
    city_name: "Wellsville",
  },
  {
    postal_code: "73103",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "13073",
    full_city_name: "Groton, NY",
    city_name: "Groton",
  },
  {
    postal_code: "13053",
    full_city_name: "Dryden, NY",
    city_name: "Dryden",
  },
  {
    postal_code: "48658",
    full_city_name: "Standish, MI",
    city_name: "Standish",
  },
  {
    postal_code: "71403",
    full_city_name: "Anacoco, LA",
    city_name: "Anacoco",
  },
  {
    postal_code: "62239",
    full_city_name: "Dupo, IL",
    city_name: "Dupo",
  },
  {
    postal_code: "56187",
    full_city_name: "Worthington, MN",
    city_name: "Worthington",
  },
  {
    postal_code: "94931",
    full_city_name: "Cotati, CA",
    city_name: "Cotati",
  },
  {
    postal_code: "60501",
    full_city_name: "Summit Argo, IL",
    city_name: "Summit Argo",
  },
  {
    postal_code: "37338",
    full_city_name: "Graysville, TN",
    city_name: "Graysville",
  },
  {
    postal_code: "16417",
    full_city_name: "Girard, PA",
    city_name: "Girard",
  },
  {
    postal_code: "02128",
    full_city_name: "East Boston, MA",
    city_name: "East Boston",
  },
  {
    postal_code: "53594",
    full_city_name: "Waterloo, WI",
    city_name: "Waterloo",
  },
  {
    postal_code: "38849",
    full_city_name: "Guntown, MS",
    city_name: "Guntown",
  },
  {
    postal_code: "23069",
    full_city_name: "Hanover, VA",
    city_name: "Hanover",
  },
  {
    postal_code: "98380",
    full_city_name: "Seabeck, WA",
    city_name: "Seabeck",
  },
  {
    postal_code: "11724",
    full_city_name: "Cold Spring Harbor, NY",
    city_name: "Cold Spring Harbor",
  },
  {
    postal_code: "68763",
    full_city_name: "Oneill, NE",
    city_name: "Oneill",
  },
  {
    postal_code: "27571",
    full_city_name: "Rolesville, NC",
    city_name: "Rolesville",
  },
  {
    postal_code: "49953",
    full_city_name: "Ontonagon, MI",
    city_name: "Ontonagon",
  },
  {
    postal_code: "68467",
    full_city_name: "York, NE",
    city_name: "York",
  },
  {
    postal_code: "20036",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "83313",
    full_city_name: "Bellevue, ID",
    city_name: "Bellevue",
  },
  {
    postal_code: "55071",
    full_city_name: "Saint Paul Park, MN",
    city_name: "Saint Paul Park",
  },
  {
    postal_code: "22311",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "17032",
    full_city_name: "Halifax, PA",
    city_name: "Halifax",
  },
  {
    postal_code: "44231",
    full_city_name: "Garrettsville, OH",
    city_name: "Garrettsville",
  },
  {
    postal_code: "48655",
    full_city_name: "Saint Charles, MI",
    city_name: "Saint Charles",
  },
  {
    postal_code: "03855",
    full_city_name: "New Durham, NH",
    city_name: "New Durham",
  },
  {
    postal_code: "05345",
    full_city_name: "Newfane, VT",
    city_name: "Newfane",
  },
  {
    postal_code: "96148",
    full_city_name: "Tahoe Vista, CA",
    city_name: "Tahoe Vista",
  },
  {
    postal_code: "03561",
    full_city_name: "Littleton, NH",
    city_name: "Littleton",
  },
  {
    postal_code: "21793",
    full_city_name: "Walkersville, MD",
    city_name: "Walkersville",
  },
  {
    postal_code: "16226",
    full_city_name: "Ford City, PA",
    city_name: "Ford City",
  },
  {
    postal_code: "05091",
    full_city_name: "Woodstock, VT",
    city_name: "Woodstock",
  },
  {
    postal_code: "04074",
    full_city_name: "Scarborough, ME",
    city_name: "Scarborough",
  },
  {
    postal_code: "56554",
    full_city_name: "Lake Park, MN",
    city_name: "Lake Park",
  },
  {
    postal_code: "37705",
    full_city_name: "Andersonville, TN",
    city_name: "Andersonville",
  },
  {
    postal_code: "60096",
    full_city_name: "Winthrop Harbor, IL",
    city_name: "Winthrop Harbor",
  },
  {
    postal_code: "21850",
    full_city_name: "Pittsville, MD",
    city_name: "Pittsville",
  },
  {
    postal_code: "90211",
    full_city_name: "Beverly Hills, CA",
    city_name: "Beverly Hills",
  },
  {
    postal_code: "74864",
    full_city_name: "Prague, OK",
    city_name: "Prague",
  },
  {
    postal_code: "12809",
    full_city_name: "Argyle, NY",
    city_name: "Argyle",
  },
  {
    postal_code: "38553",
    full_city_name: "Clarkrange, TN",
    city_name: "Clarkrange",
  },
  {
    postal_code: "94556",
    full_city_name: "Moraga, CA",
    city_name: "Moraga",
  },
  {
    postal_code: "12032",
    full_city_name: "Caroga Lake, NY",
    city_name: "Caroga Lake",
  },
  {
    postal_code: "11208",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "43502",
    full_city_name: "Archbold, OH",
    city_name: "Archbold",
  },
  {
    postal_code: "90022",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "04105",
    full_city_name: "Falmouth, ME",
    city_name: "Falmouth",
  },
  {
    postal_code: "03275",
    full_city_name: "Suncook, NH",
    city_name: "Suncook",
  },
  {
    postal_code: "05301",
    full_city_name: "Brattleboro, VT",
    city_name: "Brattleboro",
  },
  {
    postal_code: "44851",
    full_city_name: "New London, OH",
    city_name: "New London",
  },
  {
    postal_code: "06378",
    full_city_name: "Stonington, CT",
    city_name: "Stonington",
  },
  {
    postal_code: "30217",
    full_city_name: "Franklin, GA",
    city_name: "Franklin",
  },
  {
    postal_code: "36310",
    full_city_name: "Abbeville, AL",
    city_name: "Abbeville",
  },
  {
    postal_code: "37882",
    full_city_name: "Townsend, TN",
    city_name: "Townsend",
  },
  {
    postal_code: "59759",
    full_city_name: "Whitehall, MT",
    city_name: "Whitehall",
  },
  {
    postal_code: "77037",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "46164",
    full_city_name: "Nineveh, IN",
    city_name: "Nineveh",
  },
  {
    postal_code: "30558",
    full_city_name: "Maysville, GA",
    city_name: "Maysville",
  },
  {
    postal_code: "21638",
    full_city_name: "Grasonville, MD",
    city_name: "Grasonville",
  },
  {
    postal_code: "22936",
    full_city_name: "Earlysville, VA",
    city_name: "Earlysville",
  },
  {
    postal_code: "54547",
    full_city_name: "Mercer, WI",
    city_name: "Mercer",
  },
  {
    postal_code: "39530",
    full_city_name: "Biloxi, MS",
    city_name: "Biloxi",
  },
  {
    postal_code: "65793",
    full_city_name: "Willow Springs, MO",
    city_name: "Willow Springs",
  },
  {
    postal_code: "76043",
    full_city_name: "Glen Rose, TX",
    city_name: "Glen Rose",
  },
  {
    postal_code: "77705",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "12850",
    full_city_name: "Middle Grove, NY",
    city_name: "Middle Grove",
  },
  {
    postal_code: "26037",
    full_city_name: "Follansbee, WV",
    city_name: "Follansbee",
  },
  {
    postal_code: "12513",
    full_city_name: "Claverack, NY",
    city_name: "Claverack",
  },
  {
    postal_code: "44280",
    full_city_name: "Valley City, OH",
    city_name: "Valley City",
  },
  {
    postal_code: "01905",
    full_city_name: "Lynn, MA",
    city_name: "Lynn",
  },
  {
    postal_code: "37870",
    full_city_name: "Speedwell, TN",
    city_name: "Speedwell",
  },
  {
    postal_code: "04856",
    full_city_name: "Rockport, ME",
    city_name: "Rockport",
  },
  {
    postal_code: "78225",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "52742",
    full_city_name: "De Witt, IA",
    city_name: "De Witt",
  },
  {
    postal_code: "30601",
    full_city_name: "Athens, GA",
    city_name: "Athens",
  },
  {
    postal_code: "90015",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "56464",
    full_city_name: "Menahga, MN",
    city_name: "Menahga",
  },
  {
    postal_code: "77707",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "38066",
    full_city_name: "Rossville, TN",
    city_name: "Rossville",
  },
  {
    postal_code: "04938",
    full_city_name: "Farmington, ME",
    city_name: "Farmington",
  },
  {
    postal_code: "38063",
    full_city_name: "Ripley, TN",
    city_name: "Ripley",
  },
  {
    postal_code: "19933",
    full_city_name: "Bridgeville, DE",
    city_name: "Bridgeville",
  },
  {
    postal_code: "23664",
    full_city_name: "Hampton, VA",
    city_name: "Hampton",
  },
  {
    postal_code: "21102",
    full_city_name: "Manchester, MD",
    city_name: "Manchester",
  },
  {
    postal_code: "04578",
    full_city_name: "Wiscasset, ME",
    city_name: "Wiscasset",
  },
  {
    postal_code: "78231",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "03086",
    full_city_name: "Wilton, NH",
    city_name: "Wilton",
  },
  {
    postal_code: "11751",
    full_city_name: "Islip, NY",
    city_name: "Islip",
  },
  {
    postal_code: "10031",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "06831",
    full_city_name: "Greenwich, CT",
    city_name: "Greenwich",
  },
  {
    postal_code: "43830",
    full_city_name: "Nashport, OH",
    city_name: "Nashport",
  },
  {
    postal_code: "94609",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "93465",
    full_city_name: "Templeton, CA",
    city_name: "Templeton",
  },
  {
    postal_code: "62951",
    full_city_name: "Johnston City, IL",
    city_name: "Johnston City",
  },
  {
    postal_code: "35151",
    full_city_name: "Sylacauga, AL",
    city_name: "Sylacauga",
  },
  {
    postal_code: "94552",
    full_city_name: "Castro Valley, CA",
    city_name: "Castro Valley",
  },
  {
    postal_code: "90260",
    full_city_name: "Lawndale, CA",
    city_name: "Lawndale",
  },
  {
    postal_code: "77964",
    full_city_name: "Hallettsville, TX",
    city_name: "Hallettsville",
  },
  {
    postal_code: "28756",
    full_city_name: "Mill Spring, NC",
    city_name: "Mill Spring",
  },
  {
    postal_code: "05859",
    full_city_name: "North Troy, VT",
    city_name: "North Troy",
  },
  {
    postal_code: "28088",
    full_city_name: "Landis, NC",
    city_name: "Landis",
  },
  {
    postal_code: "95386",
    full_city_name: "Waterford, CA",
    city_name: "Waterford",
  },
  {
    postal_code: "01602",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "14871",
    full_city_name: "Pine City, NY",
    city_name: "Pine City",
  },
  {
    postal_code: "54156",
    full_city_name: "Pembine, WI",
    city_name: "Pembine",
  },
  {
    postal_code: "15071",
    full_city_name: "Oakdale, PA",
    city_name: "Oakdale",
  },
  {
    postal_code: "53147",
    full_city_name: "Lake Geneva, WI",
    city_name: "Lake Geneva",
  },
  {
    postal_code: "47932",
    full_city_name: "Covington, IN",
    city_name: "Covington",
  },
  {
    postal_code: "61354",
    full_city_name: "Peru, IL",
    city_name: "Peru",
  },
  {
    postal_code: "05001",
    full_city_name: "White River Junction, VT",
    city_name: "White River Junction",
  },
  {
    postal_code: "62088",
    full_city_name: "Staunton, IL",
    city_name: "Staunton",
  },
  {
    postal_code: "61873",
    full_city_name: "Saint Joseph, IL",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "05401",
    full_city_name: "Burlington, VT",
    city_name: "Burlington",
  },
  {
    postal_code: "65633",
    full_city_name: "Crane, MO",
    city_name: "Crane",
  },
  {
    postal_code: "34981",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "01826",
    full_city_name: "Dracut, MA",
    city_name: "Dracut",
  },
  {
    postal_code: "36277",
    full_city_name: "Weaver, AL",
    city_name: "Weaver",
  },
  {
    postal_code: "92844",
    full_city_name: "Garden Grove, CA",
    city_name: "Garden Grove",
  },
  {
    postal_code: "90715",
    full_city_name: "Lakewood, CA",
    city_name: "Lakewood",
  },
  {
    postal_code: "90255",
    full_city_name: "Huntington Park, CA",
    city_name: "Huntington Park",
  },
  {
    postal_code: "48054",
    full_city_name: "East China, MI",
    city_name: "East China",
  },
  {
    postal_code: "01886",
    full_city_name: "Westford, MA",
    city_name: "Westford",
  },
  {
    postal_code: "12083",
    full_city_name: "Greenville, NY",
    city_name: "Greenville",
  },
  {
    postal_code: "75941",
    full_city_name: "Diboll, TX",
    city_name: "Diboll",
  },
  {
    postal_code: "60468",
    full_city_name: "Peotone, IL",
    city_name: "Peotone",
  },
  {
    postal_code: "45807",
    full_city_name: "Lima, OH",
    city_name: "Lima",
  },
  {
    postal_code: "23084",
    full_city_name: "Kents Store, VA",
    city_name: "Kents Store",
  },
  {
    postal_code: "04064",
    full_city_name: "Old Orchard Beach, ME",
    city_name: "Old Orchard Beach",
  },
  {
    postal_code: "17043",
    full_city_name: "Lemoyne, PA",
    city_name: "Lemoyne",
  },
  {
    postal_code: "92861",
    full_city_name: "Villa Park, CA",
    city_name: "Villa Park",
  },
  {
    postal_code: "15135",
    full_city_name: "Mckeesport, PA",
    city_name: "Mckeesport",
  },
  {
    postal_code: "11702",
    full_city_name: "Babylon, NY",
    city_name: "Babylon",
  },
  {
    postal_code: "86335",
    full_city_name: "Rimrock, AZ",
    city_name: "Rimrock",
  },
  {
    postal_code: "67420",
    full_city_name: "Beloit, KS",
    city_name: "Beloit",
  },
  {
    postal_code: "01701",
    full_city_name: "Framingham, MA",
    city_name: "Framingham",
  },
  {
    postal_code: "35064",
    full_city_name: "Fairfield, AL",
    city_name: "Fairfield",
  },
  {
    postal_code: "31328",
    full_city_name: "Tybee Island, GA",
    city_name: "Tybee Island",
  },
  {
    postal_code: "12986",
    full_city_name: "Tupper Lake, NY",
    city_name: "Tupper Lake",
  },
  {
    postal_code: "28773",
    full_city_name: "Saluda, NC",
    city_name: "Saluda",
  },
  {
    postal_code: "16033",
    full_city_name: "Evans City, PA",
    city_name: "Evans City",
  },
  {
    postal_code: "95322",
    full_city_name: "Gustine, CA",
    city_name: "Gustine",
  },
  {
    postal_code: "80813",
    full_city_name: "Cripple Creek, CO",
    city_name: "Cripple Creek",
  },
  {
    postal_code: "76201",
    full_city_name: "Denton, TX",
    city_name: "Denton",
  },
  {
    postal_code: "20851",
    full_city_name: "Rockville, MD",
    city_name: "Rockville",
  },
  {
    postal_code: "01267",
    full_city_name: "Williamstown, MA",
    city_name: "Williamstown",
  },
  {
    postal_code: "17020",
    full_city_name: "Duncannon, PA",
    city_name: "Duncannon",
  },
  {
    postal_code: "28580",
    full_city_name: "Snow Hill, NC",
    city_name: "Snow Hill",
  },
  {
    postal_code: "39470",
    full_city_name: "Poplarville, MS",
    city_name: "Poplarville",
  },
  {
    postal_code: "48848",
    full_city_name: "Laingsburg, MI",
    city_name: "Laingsburg",
  },
  {
    postal_code: "80536",
    full_city_name: "Livermore, CO",
    city_name: "Livermore",
  },
  {
    postal_code: "02346",
    full_city_name: "Middleboro, MA",
    city_name: "Middleboro",
  },
  {
    postal_code: "75058",
    full_city_name: "Gunter, TX",
    city_name: "Gunter",
  },
  {
    postal_code: "49240",
    full_city_name: "Grass Lake, MI",
    city_name: "Grass Lake",
  },
  {
    postal_code: "59648",
    full_city_name: "Wolf Creek, MT",
    city_name: "Wolf Creek",
  },
  {
    postal_code: "12155",
    full_city_name: "Schenevus, NY",
    city_name: "Schenevus",
  },
  {
    postal_code: "75752",
    full_city_name: "Athens, TX",
    city_name: "Athens",
  },
  {
    postal_code: "25832",
    full_city_name: "Daniels, WV",
    city_name: "Daniels",
  },
  {
    postal_code: "04605",
    full_city_name: "Ellsworth, ME",
    city_name: "Ellsworth",
  },
  {
    postal_code: "75491",
    full_city_name: "Whitewright, TX",
    city_name: "Whitewright",
  },
  {
    postal_code: "35020",
    full_city_name: "Bessemer, AL",
    city_name: "Bessemer",
  },
  {
    postal_code: "04530",
    full_city_name: "Bath, ME",
    city_name: "Bath",
  },
  {
    postal_code: "31415",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "35960",
    full_city_name: "Centre, AL",
    city_name: "Centre",
  },
  {
    postal_code: "90245",
    full_city_name: "El Segundo, CA",
    city_name: "El Segundo",
  },
  {
    postal_code: "78416",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "95110",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "53536",
    full_city_name: "Evansville, WI",
    city_name: "Evansville",
  },
  {
    postal_code: "01240",
    full_city_name: "Lenox, MA",
    city_name: "Lenox",
  },
  {
    postal_code: "13843",
    full_city_name: "South New Berlin, NY",
    city_name: "South New Berlin",
  },
  {
    postal_code: "93706",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "25213",
    full_city_name: "Winfield, WV",
    city_name: "Winfield",
  },
  {
    postal_code: "95953",
    full_city_name: "Live Oak, CA",
    city_name: "Live Oak",
  },
  {
    postal_code: "52537",
    full_city_name: "Bloomfield, IA",
    city_name: "Bloomfield",
  },
  {
    postal_code: "54728",
    full_city_name: "Chetek, WI",
    city_name: "Chetek",
  },
  {
    postal_code: "77651",
    full_city_name: "Port Neches, TX",
    city_name: "Port Neches",
  },
  {
    postal_code: "21769",
    full_city_name: "Middletown, MD",
    city_name: "Middletown",
  },
  {
    postal_code: "95119",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "55947",
    full_city_name: "La Crescent, MN",
    city_name: "La Crescent",
  },
  {
    postal_code: "95121",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "29689",
    full_city_name: "Townville, SC",
    city_name: "Townville",
  },
  {
    postal_code: "63016",
    full_city_name: "Cedar Hill, MO",
    city_name: "Cedar Hill",
  },
  {
    postal_code: "28684",
    full_city_name: "Todd, NC",
    city_name: "Todd",
  },
  {
    postal_code: "60163",
    full_city_name: "Berkeley, IL",
    city_name: "Berkeley",
  },
  {
    postal_code: "94510",
    full_city_name: "Benicia, CA",
    city_name: "Benicia",
  },
  {
    postal_code: "53941",
    full_city_name: "La Valle, WI",
    city_name: "La Valle",
  },
  {
    postal_code: "43515",
    full_city_name: "Delta, OH",
    city_name: "Delta",
  },
  {
    postal_code: "49665",
    full_city_name: "Marion, MI",
    city_name: "Marion",
  },
  {
    postal_code: "06612",
    full_city_name: "Easton, CT",
    city_name: "Easton",
  },
  {
    postal_code: "01118",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "51012",
    full_city_name: "Cherokee, IA",
    city_name: "Cherokee",
  },
  {
    postal_code: "01960",
    full_city_name: "Peabody, MA",
    city_name: "Peabody",
  },
  {
    postal_code: "85701",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "27371",
    full_city_name: "Troy, NC",
    city_name: "Troy",
  },
  {
    postal_code: "49028",
    full_city_name: "Bronson, MI",
    city_name: "Bronson",
  },
  {
    postal_code: "14067",
    full_city_name: "Gasport, NY",
    city_name: "Gasport",
  },
  {
    postal_code: "06853",
    full_city_name: "Norwalk, CT",
    city_name: "Norwalk",
  },
  {
    postal_code: "40390",
    full_city_name: "Wilmore, KY",
    city_name: "Wilmore",
  },
  {
    postal_code: "92868",
    full_city_name: "Orange, CA",
    city_name: "Orange",
  },
  {
    postal_code: "62288",
    full_city_name: "Steeleville, IL",
    city_name: "Steeleville",
  },
  {
    postal_code: "59072",
    full_city_name: "Roundup, MT",
    city_name: "Roundup",
  },
  {
    postal_code: "95329",
    full_city_name: "La Grange, CA",
    city_name: "La Grange",
  },
  {
    postal_code: "24328",
    full_city_name: "Fancy Gap, VA",
    city_name: "Fancy Gap",
  },
  {
    postal_code: "23927",
    full_city_name: "Clarksville, VA",
    city_name: "Clarksville",
  },
  {
    postal_code: "79316",
    full_city_name: "Brownfield, TX",
    city_name: "Brownfield",
  },
  {
    postal_code: "10040",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "21046",
    full_city_name: "Columbia, MD",
    city_name: "Columbia",
  },
  {
    postal_code: "39455",
    full_city_name: "Lumberton, MS",
    city_name: "Lumberton",
  },
  {
    postal_code: "63103",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "01109",
    full_city_name: "Springfield, MA",
    city_name: "Springfield",
  },
  {
    postal_code: "21163",
    full_city_name: "Woodstock, MD",
    city_name: "Woodstock",
  },
  {
    postal_code: "10280",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "70420",
    full_city_name: "Abita Springs, LA",
    city_name: "Abita Springs",
  },
  {
    postal_code: "48169",
    full_city_name: "Pinckney, MI",
    city_name: "Pinckney",
  },
  {
    postal_code: "21035",
    full_city_name: "Davidsonville, MD",
    city_name: "Davidsonville",
  },
  {
    postal_code: "48134",
    full_city_name: "Flat Rock, MI",
    city_name: "Flat Rock",
  },
  {
    postal_code: "21120",
    full_city_name: "Parkton, MD",
    city_name: "Parkton",
  },
  {
    postal_code: "65066",
    full_city_name: "Owensville, MO",
    city_name: "Owensville",
  },
  {
    postal_code: "37048",
    full_city_name: "Cottontown, TN",
    city_name: "Cottontown",
  },
  {
    postal_code: "89106",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "85625",
    full_city_name: "Pearce, AZ",
    city_name: "Pearce",
  },
  {
    postal_code: "30313",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "81223",
    full_city_name: "Cotopaxi, CO",
    city_name: "Cotopaxi",
  },
  {
    postal_code: "13360",
    full_city_name: "Inlet, NY",
    city_name: "Inlet",
  },
  {
    postal_code: "19072",
    full_city_name: "Narberth, PA",
    city_name: "Narberth",
  },
  {
    postal_code: "37871",
    full_city_name: "Strawberry Plains, TN",
    city_name: "Strawberry Plains",
  },
  {
    postal_code: "11560",
    full_city_name: "Locust Valley, NY",
    city_name: "Locust Valley",
  },
  {
    postal_code: "47872",
    full_city_name: "Rockville, IN",
    city_name: "Rockville",
  },
  {
    postal_code: "78852",
    full_city_name: "Eagle Pass, TX",
    city_name: "Eagle Pass",
  },
  {
    postal_code: "44672",
    full_city_name: "Sebring, OH",
    city_name: "Sebring",
  },
  {
    postal_code: "29449",
    full_city_name: "Hollywood, SC",
    city_name: "Hollywood",
  },
  {
    postal_code: "36108",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "12027",
    full_city_name: "Burnt Hills, NY",
    city_name: "Burnt Hills",
  },
  {
    postal_code: "66032",
    full_city_name: "Garnett, KS",
    city_name: "Garnett",
  },
  {
    postal_code: "48626",
    full_city_name: "Hemlock, MI",
    city_name: "Hemlock",
  },
  {
    postal_code: "64744",
    full_city_name: "El Dorado Springs, MO",
    city_name: "El Dorado Springs",
  },
  {
    postal_code: "37322",
    full_city_name: "Decatur, TN",
    city_name: "Decatur",
  },
  {
    postal_code: "80106",
    full_city_name: "Elbert, CO",
    city_name: "Elbert",
  },
  {
    postal_code: "86324",
    full_city_name: "Clarkdale, AZ",
    city_name: "Clarkdale",
  },
  {
    postal_code: "83128",
    full_city_name: "Alpine, WY",
    city_name: "Alpine",
  },
  {
    postal_code: "43331",
    full_city_name: "Lakeview, OH",
    city_name: "Lakeview",
  },
  {
    postal_code: "02043",
    full_city_name: "Hingham, MA",
    city_name: "Hingham",
  },
  {
    postal_code: "08077",
    full_city_name: "Riverton, NJ",
    city_name: "Riverton",
  },
  {
    postal_code: "28753",
    full_city_name: "Marshall, NC",
    city_name: "Marshall",
  },
  {
    postal_code: "72744",
    full_city_name: "Lincoln, AR",
    city_name: "Lincoln",
  },
  {
    postal_code: "93111",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "79404",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "78612",
    full_city_name: "Cedar Creek, TX",
    city_name: "Cedar Creek",
  },
  {
    postal_code: "02139",
    full_city_name: "Cambridge, MA",
    city_name: "Cambridge",
  },
  {
    postal_code: "94121",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "27301",
    full_city_name: "Mc Leansville, NC",
    city_name: "Mc Leansville",
  },
  {
    postal_code: "94063",
    full_city_name: "Redwood City, CA",
    city_name: "Redwood City",
  },
  {
    postal_code: "06706",
    full_city_name: "Waterbury, CT",
    city_name: "Waterbury",
  },
  {
    postal_code: "05056",
    full_city_name: "Plymouth, VT",
    city_name: "Plymouth",
  },
  {
    postal_code: "44201",
    full_city_name: "Atwater, OH",
    city_name: "Atwater",
  },
  {
    postal_code: "12516",
    full_city_name: "Copake, NY",
    city_name: "Copake",
  },
  {
    postal_code: "49931",
    full_city_name: "Houghton, MI",
    city_name: "Houghton",
  },
  {
    postal_code: "42347",
    full_city_name: "Hartford, KY",
    city_name: "Hartford",
  },
  {
    postal_code: "61528",
    full_city_name: "Edwards, IL",
    city_name: "Edwards",
  },
  {
    postal_code: "76834",
    full_city_name: "Coleman, TX",
    city_name: "Coleman",
  },
  {
    postal_code: "75480",
    full_city_name: "Scroggins, TX",
    city_name: "Scroggins",
  },
  {
    postal_code: "94706",
    full_city_name: "Albany, CA",
    city_name: "Albany",
  },
  {
    postal_code: "08530",
    full_city_name: "Lambertville, NJ",
    city_name: "Lambertville",
  },
  {
    postal_code: "51601",
    full_city_name: "Shenandoah, IA",
    city_name: "Shenandoah",
  },
  {
    postal_code: "33873",
    full_city_name: "Wauchula, FL",
    city_name: "Wauchula",
  },
  {
    postal_code: "48017",
    full_city_name: "Clawson, MI",
    city_name: "Clawson",
  },
  {
    postal_code: "12196",
    full_city_name: "West Sand Lake, NY",
    city_name: "West Sand Lake",
  },
  {
    postal_code: "76448",
    full_city_name: "Eastland, TX",
    city_name: "Eastland",
  },
  {
    postal_code: "01854",
    full_city_name: "Lowell, MA",
    city_name: "Lowell",
  },
  {
    postal_code: "22305",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "14882",
    full_city_name: "Lansing, NY",
    city_name: "Lansing",
  },
  {
    postal_code: "78756",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "90017",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "79364",
    full_city_name: "Slaton, TX",
    city_name: "Slaton",
  },
  {
    postal_code: "93927",
    full_city_name: "Greenfield, CA",
    city_name: "Greenfield",
  },
  {
    postal_code: "54986",
    full_city_name: "Winneconne, WI",
    city_name: "Winneconne",
  },
  {
    postal_code: "02556",
    full_city_name: "North Falmouth, MA",
    city_name: "North Falmouth",
  },
  {
    postal_code: "28450",
    full_city_name: "Lake Waccamaw, NC",
    city_name: "Lake Waccamaw",
  },
  {
    postal_code: "50169",
    full_city_name: "Mitchellville, IA",
    city_name: "Mitchellville",
  },
  {
    postal_code: "21754",
    full_city_name: "Ijamsville, MD",
    city_name: "Ijamsville",
  },
  {
    postal_code: "94965",
    full_city_name: "Sausalito, CA",
    city_name: "Sausalito",
  },
  {
    postal_code: "56058",
    full_city_name: "Le Sueur, MN",
    city_name: "Le Sueur",
  },
  {
    postal_code: "95122",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "49837",
    full_city_name: "Gladstone, MI",
    city_name: "Gladstone",
  },
  {
    postal_code: "28360",
    full_city_name: "Lumberton, NC",
    city_name: "Lumberton",
  },
  {
    postal_code: "80814",
    full_city_name: "Divide, CO",
    city_name: "Divide",
  },
  {
    postal_code: "03249",
    full_city_name: "Gilford, NH",
    city_name: "Gilford",
  },
  {
    postal_code: "13068",
    full_city_name: "Freeville, NY",
    city_name: "Freeville",
  },
  {
    postal_code: "59729",
    full_city_name: "Ennis, MT",
    city_name: "Ennis",
  },
  {
    postal_code: "56288",
    full_city_name: "Spicer, MN",
    city_name: "Spicer",
  },
  {
    postal_code: "95130",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "95709",
    full_city_name: "Camino, CA",
    city_name: "Camino",
  },
  {
    postal_code: "95315",
    full_city_name: "Delhi, CA",
    city_name: "Delhi",
  },
  {
    postal_code: "55806",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "20166",
    full_city_name: "Sterling, VA",
    city_name: "Sterling",
  },
  {
    postal_code: "05446",
    full_city_name: "Colchester, VT",
    city_name: "Colchester",
  },
  {
    postal_code: "55073",
    full_city_name: "Scandia, MN",
    city_name: "Scandia",
  },
  {
    postal_code: "75152",
    full_city_name: "Palmer, TX",
    city_name: "Palmer",
  },
  {
    postal_code: "60021",
    full_city_name: "Fox River Grove, IL",
    city_name: "Fox River Grove",
  },
  {
    postal_code: "54822",
    full_city_name: "Cameron, WI",
    city_name: "Cameron",
  },
  {
    postal_code: "45381",
    full_city_name: "West Alexandria, OH",
    city_name: "West Alexandria",
  },
  {
    postal_code: "46962",
    full_city_name: "North Manchester, IN",
    city_name: "North Manchester",
  },
  {
    postal_code: "12122",
    full_city_name: "Middleburgh, NY",
    city_name: "Middleburgh",
  },
  {
    postal_code: "12157",
    full_city_name: "Schoharie, NY",
    city_name: "Schoharie",
  },
  {
    postal_code: "79045",
    full_city_name: "Hereford, TX",
    city_name: "Hereford",
  },
  {
    postal_code: "75201",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "31522",
    full_city_name: "Saint Simons Island, GA",
    city_name: "Saint Simons Island",
  },
  {
    postal_code: "30537",
    full_city_name: "Dillard, GA",
    city_name: "Dillard",
  },
  {
    postal_code: "27522",
    full_city_name: "Creedmoor, NC",
    city_name: "Creedmoor",
  },
  {
    postal_code: "10940",
    full_city_name: "Middletown, NY",
    city_name: "Middletown",
  },
  {
    postal_code: "28365",
    full_city_name: "Mount Olive, NC",
    city_name: "Mount Olive",
  },
  {
    postal_code: "18810",
    full_city_name: "Athens, PA",
    city_name: "Athens",
  },
  {
    postal_code: "93240",
    full_city_name: "Lake Isabella, CA",
    city_name: "Lake Isabella",
  },
  {
    postal_code: "80829",
    full_city_name: "Manitou Springs, CO",
    city_name: "Manitou Springs",
  },
  {
    postal_code: "16423",
    full_city_name: "Lake City, PA",
    city_name: "Lake City",
  },
  {
    postal_code: "95634",
    full_city_name: "Georgetown, CA",
    city_name: "Georgetown",
  },
  {
    postal_code: "77856",
    full_city_name: "Franklin, TX",
    city_name: "Franklin",
  },
  {
    postal_code: "62906",
    full_city_name: "Anna, IL",
    city_name: "Anna",
  },
  {
    postal_code: "15332",
    full_city_name: "Finleyville, PA",
    city_name: "Finleyville",
  },
  {
    postal_code: "76457",
    full_city_name: "Hico, TX",
    city_name: "Hico",
  },
  {
    postal_code: "94133",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "20135",
    full_city_name: "Bluemont, VA",
    city_name: "Bluemont",
  },
  {
    postal_code: "32033",
    full_city_name: "Elkton, FL",
    city_name: "Elkton",
  },
  {
    postal_code: "64865",
    full_city_name: "Seneca, MO",
    city_name: "Seneca",
  },
  {
    postal_code: "90717",
    full_city_name: "Lomita, CA",
    city_name: "Lomita",
  },
  {
    postal_code: "12202",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "55069",
    full_city_name: "Rush City, MN",
    city_name: "Rush City",
  },
  {
    postal_code: "06105",
    full_city_name: "Hartford, CT",
    city_name: "Hartford",
  },
  {
    postal_code: "23417",
    full_city_name: "Onancock, VA",
    city_name: "Onancock",
  },
  {
    postal_code: "71328",
    full_city_name: "Deville, LA",
    city_name: "Deville",
  },
  {
    postal_code: "31331",
    full_city_name: "Townsend, GA",
    city_name: "Townsend",
  },
  {
    postal_code: "12197",
    full_city_name: "Worcester, NY",
    city_name: "Worcester",
  },
  {
    postal_code: "95010",
    full_city_name: "Capitola, CA",
    city_name: "Capitola",
  },
  {
    postal_code: "55792",
    full_city_name: "Virginia, MN",
    city_name: "Virginia",
  },
  {
    postal_code: "43410",
    full_city_name: "Clyde, OH",
    city_name: "Clyde",
  },
  {
    postal_code: "37708",
    full_city_name: "Bean Station, TN",
    city_name: "Bean Station",
  },
  {
    postal_code: "02169",
    full_city_name: "Quincy, MA",
    city_name: "Quincy",
  },
  {
    postal_code: "27244",
    full_city_name: "Elon, NC",
    city_name: "Elon",
  },
  {
    postal_code: "65610",
    full_city_name: "Billings, MO",
    city_name: "Billings",
  },
  {
    postal_code: "12996",
    full_city_name: "Willsboro, NY",
    city_name: "Willsboro",
  },
  {
    postal_code: "63019",
    full_city_name: "Crystal City, MO",
    city_name: "Crystal City",
  },
  {
    postal_code: "79763",
    full_city_name: "Odessa, TX",
    city_name: "Odessa",
  },
  {
    postal_code: "21223",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "55719",
    full_city_name: "Chisholm, MN",
    city_name: "Chisholm",
  },
  {
    postal_code: "03857",
    full_city_name: "Newmarket, NH",
    city_name: "Newmarket",
  },
  {
    postal_code: "37658",
    full_city_name: "Hampton, TN",
    city_name: "Hampton",
  },
  {
    postal_code: "14867",
    full_city_name: "Newfield, NY",
    city_name: "Newfield",
  },
  {
    postal_code: "12827",
    full_city_name: "Fort Ann, NY",
    city_name: "Fort Ann",
  },
  {
    postal_code: "43103",
    full_city_name: "Ashville, OH",
    city_name: "Ashville",
  },
  {
    postal_code: "66214",
    full_city_name: "Overland Park, KS",
    city_name: "Overland Park",
  },
  {
    postal_code: "12885",
    full_city_name: "Warrensburg, NY",
    city_name: "Warrensburg",
  },
  {
    postal_code: "28040",
    full_city_name: "Ellenboro, NC",
    city_name: "Ellenboro",
  },
  {
    postal_code: "17340",
    full_city_name: "Littlestown, PA",
    city_name: "Littlestown",
  },
  {
    postal_code: "84318",
    full_city_name: "Hyde Park, UT",
    city_name: "Hyde Park",
  },
  {
    postal_code: "11423",
    full_city_name: "Hollis, NY",
    city_name: "Hollis",
  },
  {
    postal_code: "55375",
    full_city_name: "Saint Bonifacius, MN",
    city_name: "Saint Bonifacius",
  },
  {
    postal_code: "13612",
    full_city_name: "Black River, NY",
    city_name: "Black River",
  },
  {
    postal_code: "19807",
    full_city_name: "Wilmington, DE",
    city_name: "Wilmington",
  },
  {
    postal_code: "12138",
    full_city_name: "Petersburg, NY",
    city_name: "Petersburg",
  },
  {
    postal_code: "55341",
    full_city_name: "Hanover, MN",
    city_name: "Hanover",
  },
  {
    postal_code: "33136",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "44833",
    full_city_name: "Galion, OH",
    city_name: "Galion",
  },
  {
    postal_code: "64062",
    full_city_name: "Lawson, MO",
    city_name: "Lawson",
  },
  {
    postal_code: "98446",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "06441",
    full_city_name: "Higganum, CT",
    city_name: "Higganum",
  },
  {
    postal_code: "98327",
    full_city_name: "Dupont, WA",
    city_name: "Dupont",
  },
  {
    postal_code: "15904",
    full_city_name: "Johnstown, PA",
    city_name: "Johnstown",
  },
  {
    postal_code: "31645",
    full_city_name: "Ray City, GA",
    city_name: "Ray City",
  },
  {
    postal_code: "94030",
    full_city_name: "Millbrae, CA",
    city_name: "Millbrae",
  },
  {
    postal_code: "92845",
    full_city_name: "Garden Grove, CA",
    city_name: "Garden Grove",
  },
  {
    postal_code: "15748",
    full_city_name: "Homer City, PA",
    city_name: "Homer City",
  },
  {
    postal_code: "60456",
    full_city_name: "Hometown, IL",
    city_name: "Hometown",
  },
  {
    postal_code: "35046",
    full_city_name: "Clanton, AL",
    city_name: "Clanton",
  },
  {
    postal_code: "25504",
    full_city_name: "Barboursville, WV",
    city_name: "Barboursville",
  },
  {
    postal_code: "55976",
    full_city_name: "Stewartville, MN",
    city_name: "Stewartville",
  },
  {
    postal_code: "75233",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "74059",
    full_city_name: "Perkins, OK",
    city_name: "Perkins",
  },
  {
    postal_code: "78363",
    full_city_name: "Kingsville, TX",
    city_name: "Kingsville",
  },
  {
    postal_code: "95685",
    full_city_name: "Sutter Creek, CA",
    city_name: "Sutter Creek",
  },
  {
    postal_code: "55413",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "78124",
    full_city_name: "Marion, TX",
    city_name: "Marion",
  },
  {
    postal_code: "49230",
    full_city_name: "Brooklyn, MI",
    city_name: "Brooklyn",
  },
  {
    postal_code: "95623",
    full_city_name: "El Dorado, CA",
    city_name: "El Dorado",
  },
  {
    postal_code: "64730",
    full_city_name: "Butler, MO",
    city_name: "Butler",
  },
  {
    postal_code: "44485",
    full_city_name: "Warren, OH",
    city_name: "Warren",
  },
  {
    postal_code: "76424",
    full_city_name: "Breckenridge, TX",
    city_name: "Breckenridge",
  },
  {
    postal_code: "01864",
    full_city_name: "North Reading, MA",
    city_name: "North Reading",
  },
  {
    postal_code: "70462",
    full_city_name: "Springfield, LA",
    city_name: "Springfield",
  },
  {
    postal_code: "75147",
    full_city_name: "Mabank, TX",
    city_name: "Mabank",
  },
  {
    postal_code: "78204",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "29574",
    full_city_name: "Mullins, SC",
    city_name: "Mullins",
  },
  {
    postal_code: "06906",
    full_city_name: "Stamford, CT",
    city_name: "Stamford",
  },
  {
    postal_code: "87901",
    full_city_name: "Truth Or Consequences, NM",
    city_name: "Truth Or Consequences",
  },
  {
    postal_code: "27313",
    full_city_name: "Pleasant Garden, NC",
    city_name: "Pleasant Garden",
  },
  {
    postal_code: "83856",
    full_city_name: "Priest River, ID",
    city_name: "Priest River",
  },
  {
    postal_code: "27810",
    full_city_name: "Belhaven, NC",
    city_name: "Belhaven",
  },
  {
    postal_code: "15717",
    full_city_name: "Blairsville, PA",
    city_name: "Blairsville",
  },
  {
    postal_code: "12835",
    full_city_name: "Hadley, NY",
    city_name: "Hadley",
  },
  {
    postal_code: "18661",
    full_city_name: "White Haven, PA",
    city_name: "White Haven",
  },
  {
    postal_code: "13778",
    full_city_name: "Greene, NY",
    city_name: "Greene",
  },
  {
    postal_code: "95222",
    full_city_name: "Angels Camp, CA",
    city_name: "Angels Camp",
  },
  {
    postal_code: "29385",
    full_city_name: "Wellford, SC",
    city_name: "Wellford",
  },
  {
    postal_code: "20903",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "22727",
    full_city_name: "Madison, VA",
    city_name: "Madison",
  },
  {
    postal_code: "37806",
    full_city_name: "Mascot, TN",
    city_name: "Mascot",
  },
  {
    postal_code: "06779",
    full_city_name: "Oakville, CT",
    city_name: "Oakville",
  },
  {
    postal_code: "77562",
    full_city_name: "Highlands, TX",
    city_name: "Highlands",
  },
  {
    postal_code: "27932",
    full_city_name: "Edenton, NC",
    city_name: "Edenton",
  },
  {
    postal_code: "10469",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "27822",
    full_city_name: "Elm City, NC",
    city_name: "Elm City",
  },
  {
    postal_code: "62049",
    full_city_name: "Hillsboro, IL",
    city_name: "Hillsboro",
  },
  {
    postal_code: "01545",
    full_city_name: "Shrewsbury, MA",
    city_name: "Shrewsbury",
  },
  {
    postal_code: "28127",
    full_city_name: "New London, NC",
    city_name: "New London",
  },
  {
    postal_code: "44509",
    full_city_name: "Youngstown, OH",
    city_name: "Youngstown",
  },
  {
    postal_code: "44706",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "84004",
    full_city_name: "Alpine, UT",
    city_name: "Alpine",
  },
  {
    postal_code: "54114",
    full_city_name: "Crivitz, WI",
    city_name: "Crivitz",
  },
  {
    postal_code: "78112",
    full_city_name: "Elmendorf, TX",
    city_name: "Elmendorf",
  },
  {
    postal_code: "85361",
    full_city_name: "Wittmann, AZ",
    city_name: "Wittmann",
  },
  {
    postal_code: "26155",
    full_city_name: "New Martinsville, WV",
    city_name: "New Martinsville",
  },
  {
    postal_code: "05363",
    full_city_name: "Wilmington, VT",
    city_name: "Wilmington",
  },
  {
    postal_code: "78733",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "20781",
    full_city_name: "Hyattsville, MD",
    city_name: "Hyattsville",
  },
  {
    postal_code: "21226",
    full_city_name: "Curtis Bay, MD",
    city_name: "Curtis Bay",
  },
  {
    postal_code: "18447",
    full_city_name: "Olyphant, PA",
    city_name: "Olyphant",
  },
  {
    postal_code: "40311",
    full_city_name: "Carlisle, KY",
    city_name: "Carlisle",
  },
  {
    postal_code: "68066",
    full_city_name: "Wahoo, NE",
    city_name: "Wahoo",
  },
  {
    postal_code: "42127",
    full_city_name: "Cave City, KY",
    city_name: "Cave City",
  },
  {
    postal_code: "55425",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "43315",
    full_city_name: "Cardington, OH",
    city_name: "Cardington",
  },
  {
    postal_code: "37887",
    full_city_name: "Wartburg, TN",
    city_name: "Wartburg",
  },
  {
    postal_code: "74960",
    full_city_name: "Stilwell, OK",
    city_name: "Stilwell",
  },
  {
    postal_code: "93271",
    full_city_name: "Three Rivers, CA",
    city_name: "Three Rivers",
  },
  {
    postal_code: "03055",
    full_city_name: "Milford, NH",
    city_name: "Milford",
  },
  {
    postal_code: "03809",
    full_city_name: "Alton, NH",
    city_name: "Alton",
  },
  {
    postal_code: "12053",
    full_city_name: "Delanson, NY",
    city_name: "Delanson",
  },
  {
    postal_code: "76557",
    full_city_name: "Moody, TX",
    city_name: "Moody",
  },
  {
    postal_code: "61755",
    full_city_name: "Mackinaw, IL",
    city_name: "Mackinaw",
  },
  {
    postal_code: "63147",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "54848",
    full_city_name: "Ladysmith, WI",
    city_name: "Ladysmith",
  },
  {
    postal_code: "30113",
    full_city_name: "Buchanan, GA",
    city_name: "Buchanan",
  },
  {
    postal_code: "90602",
    full_city_name: "Whittier, CA",
    city_name: "Whittier",
  },
  {
    postal_code: "27536",
    full_city_name: "Henderson, NC",
    city_name: "Henderson",
  },
  {
    postal_code: "96727",
    full_city_name: "Honokaa, HI",
    city_name: "Honokaa",
  },
  {
    postal_code: "46069",
    full_city_name: "Sheridan, IN",
    city_name: "Sheridan",
  },
  {
    postal_code: "91775",
    full_city_name: "San Gabriel, CA",
    city_name: "San Gabriel",
  },
  {
    postal_code: "24563",
    full_city_name: "Hurt, VA",
    city_name: "Hurt",
  },
  {
    postal_code: "06855",
    full_city_name: "Norwalk, CT",
    city_name: "Norwalk",
  },
  {
    postal_code: "12151",
    full_city_name: "Round Lake, NY",
    city_name: "Round Lake",
  },
  {
    postal_code: "55358",
    full_city_name: "Maple Lake, MN",
    city_name: "Maple Lake",
  },
  {
    postal_code: "95320",
    full_city_name: "Escalon, CA",
    city_name: "Escalon",
  },
  {
    postal_code: "55011",
    full_city_name: "Cedar, MN",
    city_name: "Cedar",
  },
  {
    postal_code: "95954",
    full_city_name: "Magalia, CA",
    city_name: "Magalia",
  },
  {
    postal_code: "98424",
    full_city_name: "Fife, WA",
    city_name: "Fife",
  },
  {
    postal_code: "05673",
    full_city_name: "Waitsfield, VT",
    city_name: "Waitsfield",
  },
  {
    postal_code: "12455",
    full_city_name: "Margaretville, NY",
    city_name: "Margaretville",
  },
  {
    postal_code: "48821",
    full_city_name: "Dimondale, MI",
    city_name: "Dimondale",
  },
  {
    postal_code: "46550",
    full_city_name: "Nappanee, IN",
    city_name: "Nappanee",
  },
  {
    postal_code: "19095",
    full_city_name: "Wyncote, PA",
    city_name: "Wyncote",
  },
  {
    postal_code: "37330",
    full_city_name: "Estill Springs, TN",
    city_name: "Estill Springs",
  },
  {
    postal_code: "03878",
    full_city_name: "Somersworth, NH",
    city_name: "Somersworth",
  },
  {
    postal_code: "55121",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "04005",
    full_city_name: "Biddeford, ME",
    city_name: "Biddeford",
  },
  {
    postal_code: "25430",
    full_city_name: "Kearneysville, WV",
    city_name: "Kearneysville",
  },
  {
    postal_code: "01801",
    full_city_name: "Woburn, MA",
    city_name: "Woburn",
  },
  {
    postal_code: "40026",
    full_city_name: "Goshen, KY",
    city_name: "Goshen",
  },
  {
    postal_code: "03753",
    full_city_name: "Grantham, NH",
    city_name: "Grantham",
  },
  {
    postal_code: "72417",
    full_city_name: "Brookland, AR",
    city_name: "Brookland",
  },
  {
    postal_code: "06085",
    full_city_name: "Unionville, CT",
    city_name: "Unionville",
  },
  {
    postal_code: "50321",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "92661",
    full_city_name: "Newport Beach, CA",
    city_name: "Newport Beach",
  },
  {
    postal_code: "11576",
    full_city_name: "Roslyn, NY",
    city_name: "Roslyn",
  },
  {
    postal_code: "16866",
    full_city_name: "Philipsburg, PA",
    city_name: "Philipsburg",
  },
  {
    postal_code: "53818",
    full_city_name: "Platteville, WI",
    city_name: "Platteville",
  },
  {
    postal_code: "28638",
    full_city_name: "Hudson, NC",
    city_name: "Hudson",
  },
  {
    postal_code: "19444",
    full_city_name: "Lafayette Hill, PA",
    city_name: "Lafayette Hill",
  },
  {
    postal_code: "68508",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "97136",
    full_city_name: "Rockaway Beach, OR",
    city_name: "Rockaway Beach",
  },
  {
    postal_code: "49930",
    full_city_name: "Hancock, MI",
    city_name: "Hancock",
  },
  {
    postal_code: "72732",
    full_city_name: "Garfield, AR",
    city_name: "Garfield",
  },
  {
    postal_code: "78648",
    full_city_name: "Luling, TX",
    city_name: "Luling",
  },
  {
    postal_code: "76531",
    full_city_name: "Hamilton, TX",
    city_name: "Hamilton",
  },
  {
    postal_code: "55363",
    full_city_name: "Montrose, MN",
    city_name: "Montrose",
  },
  {
    postal_code: "65084",
    full_city_name: "Versailles, MO",
    city_name: "Versailles",
  },
  {
    postal_code: "03901",
    full_city_name: "Berwick, ME",
    city_name: "Berwick",
  },
  {
    postal_code: "83254",
    full_city_name: "Montpelier, ID",
    city_name: "Montpelier",
  },
  {
    postal_code: "52172",
    full_city_name: "Waukon, IA",
    city_name: "Waukon",
  },
  {
    postal_code: "95614",
    full_city_name: "Cool, CA",
    city_name: "Cool",
  },
  {
    postal_code: "75428",
    full_city_name: "Commerce, TX",
    city_name: "Commerce",
  },
  {
    postal_code: "45122",
    full_city_name: "Goshen, OH",
    city_name: "Goshen",
  },
  {
    postal_code: "16354",
    full_city_name: "Titusville, PA",
    city_name: "Titusville",
  },
  {
    postal_code: "74126",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "79331",
    full_city_name: "Lamesa, TX",
    city_name: "Lamesa",
  },
  {
    postal_code: "21663",
    full_city_name: "Saint Michaels, MD",
    city_name: "Saint Michaels",
  },
  {
    postal_code: "15330",
    full_city_name: "Eighty Four, PA",
    city_name: "Eighty Four",
  },
  {
    postal_code: "75979",
    full_city_name: "Woodville, TX",
    city_name: "Woodville",
  },
  {
    postal_code: "11755",
    full_city_name: "Lake Grove, NY",
    city_name: "Lake Grove",
  },
  {
    postal_code: "93108",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "83353",
    full_city_name: "Sun Valley, ID",
    city_name: "Sun Valley",
  },
  {
    postal_code: "48028",
    full_city_name: "Harsens Island, MI",
    city_name: "Harsens Island",
  },
  {
    postal_code: "28174",
    full_city_name: "Wingate, NC",
    city_name: "Wingate",
  },
  {
    postal_code: "63552",
    full_city_name: "Macon, MO",
    city_name: "Macon",
  },
  {
    postal_code: "95326",
    full_city_name: "Hughson, CA",
    city_name: "Hughson",
  },
  {
    postal_code: "55706",
    full_city_name: "Babbitt, MN",
    city_name: "Babbitt",
  },
  {
    postal_code: "38011",
    full_city_name: "Brighton, TN",
    city_name: "Brighton",
  },
  {
    postal_code: "75754",
    full_city_name: "Ben Wheeler, TX",
    city_name: "Ben Wheeler",
  },
  {
    postal_code: "93930",
    full_city_name: "King City, CA",
    city_name: "King City",
  },
  {
    postal_code: "30747",
    full_city_name: "Summerville, GA",
    city_name: "Summerville",
  },
  {
    postal_code: "46036",
    full_city_name: "Elwood, IN",
    city_name: "Elwood",
  },
  {
    postal_code: "91770",
    full_city_name: "Rosemead, CA",
    city_name: "Rosemead",
  },
  {
    postal_code: "59019",
    full_city_name: "Columbus, MT",
    city_name: "Columbus",
  },
  {
    postal_code: "13029",
    full_city_name: "Brewerton, NY",
    city_name: "Brewerton",
  },
  {
    postal_code: "94579",
    full_city_name: "San Leandro, CA",
    city_name: "San Leandro",
  },
  {
    postal_code: "55307",
    full_city_name: "Arlington, MN",
    city_name: "Arlington",
  },
  {
    postal_code: "03222",
    full_city_name: "Bristol, NH",
    city_name: "Bristol",
  },
  {
    postal_code: "46072",
    full_city_name: "Tipton, IN",
    city_name: "Tipton",
  },
  {
    postal_code: "61745",
    full_city_name: "Heyworth, IL",
    city_name: "Heyworth",
  },
  {
    postal_code: "50022",
    full_city_name: "Atlantic, IA",
    city_name: "Atlantic",
  },
  {
    postal_code: "76384",
    full_city_name: "Vernon, TX",
    city_name: "Vernon",
  },
  {
    postal_code: "25311",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "93620",
    full_city_name: "Dos Palos, CA",
    city_name: "Dos Palos",
  },
  {
    postal_code: "84501",
    full_city_name: "Price, UT",
    city_name: "Price",
  },
  {
    postal_code: "55320",
    full_city_name: "Clearwater, MN",
    city_name: "Clearwater",
  },
  {
    postal_code: "49802",
    full_city_name: "Kingsford, MI",
    city_name: "Kingsford",
  },
  {
    postal_code: "03825",
    full_city_name: "Barrington, NH",
    city_name: "Barrington",
  },
  {
    postal_code: "67026",
    full_city_name: "Clearwater, KS",
    city_name: "Clearwater",
  },
  {
    postal_code: "76446",
    full_city_name: "Dublin, TX",
    city_name: "Dublin",
  },
  {
    postal_code: "04090",
    full_city_name: "Wells, ME",
    city_name: "Wells",
  },
  {
    postal_code: "49065",
    full_city_name: "Lawton, MI",
    city_name: "Lawton",
  },
  {
    postal_code: "38462",
    full_city_name: "Hohenwald, TN",
    city_name: "Hohenwald",
  },
  {
    postal_code: "18657",
    full_city_name: "Tunkhannock, PA",
    city_name: "Tunkhannock",
  },
  {
    postal_code: "98563",
    full_city_name: "Montesano, WA",
    city_name: "Montesano",
  },
  {
    postal_code: "04009",
    full_city_name: "Bridgton, ME",
    city_name: "Bridgton",
  },
  {
    postal_code: "01420",
    full_city_name: "Fitchburg, MA",
    city_name: "Fitchburg",
  },
  {
    postal_code: "74048",
    full_city_name: "Nowata, OK",
    city_name: "Nowata",
  },
  {
    postal_code: "70092",
    full_city_name: "Violet, LA",
    city_name: "Violet",
  },
  {
    postal_code: "70438",
    full_city_name: "Franklinton, LA",
    city_name: "Franklinton",
  },
  {
    postal_code: "27027",
    full_city_name: "Mayodan, NC",
    city_name: "Mayodan",
  },
  {
    postal_code: "76102",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "95722",
    full_city_name: "Meadow Vista, CA",
    city_name: "Meadow Vista",
  },
  {
    postal_code: "97135",
    full_city_name: "Pacific City, OR",
    city_name: "Pacific City",
  },
  {
    postal_code: "63703",
    full_city_name: "Cape Girardeau, MO",
    city_name: "Cape Girardeau",
  },
  {
    postal_code: "68949",
    full_city_name: "Holdrege, NE",
    city_name: "Holdrege",
  },
  {
    postal_code: "78009",
    full_city_name: "Castroville, TX",
    city_name: "Castroville",
  },
  {
    postal_code: "56482",
    full_city_name: "Wadena, MN",
    city_name: "Wadena",
  },
  {
    postal_code: "22974",
    full_city_name: "Troy, VA",
    city_name: "Troy",
  },
  {
    postal_code: "46160",
    full_city_name: "Morgantown, IN",
    city_name: "Morgantown",
  },
  {
    postal_code: "27574",
    full_city_name: "Roxboro, NC",
    city_name: "Roxboro",
  },
  {
    postal_code: "18372",
    full_city_name: "Tannersville, PA",
    city_name: "Tannersville",
  },
  {
    postal_code: "74128",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "37410",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "34269",
    full_city_name: "Arcadia, FL",
    city_name: "Arcadia",
  },
  {
    postal_code: "15632",
    full_city_name: "Export, PA",
    city_name: "Export",
  },
  {
    postal_code: "68003",
    full_city_name: "Ashland, NE",
    city_name: "Ashland",
  },
  {
    postal_code: "22172",
    full_city_name: "Triangle, VA",
    city_name: "Triangle",
  },
  {
    postal_code: "56468",
    full_city_name: "Nisswa, MN",
    city_name: "Nisswa",
  },
  {
    postal_code: "55963",
    full_city_name: "Pine Island, MN",
    city_name: "Pine Island",
  },
  {
    postal_code: "54859",
    full_city_name: "Minong, WI",
    city_name: "Minong",
  },
  {
    postal_code: "60408",
    full_city_name: "Braidwood, IL",
    city_name: "Braidwood",
  },
  {
    postal_code: "18464",
    full_city_name: "Tafton, PA",
    city_name: "Tafton",
  },
  {
    postal_code: "43566",
    full_city_name: "Waterville, OH",
    city_name: "Waterville",
  },
  {
    postal_code: "55302",
    full_city_name: "Annandale, MN",
    city_name: "Annandale",
  },
  {
    postal_code: "28356",
    full_city_name: "Linden, NC",
    city_name: "Linden",
  },
  {
    postal_code: "20764",
    full_city_name: "Shady Side, MD",
    city_name: "Shady Side",
  },
  {
    postal_code: "94118",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "38965",
    full_city_name: "Water Valley, MS",
    city_name: "Water Valley",
  },
  {
    postal_code: "94022",
    full_city_name: "Los Altos, CA",
    city_name: "Los Altos",
  },
  {
    postal_code: "79510",
    full_city_name: "Clyde, TX",
    city_name: "Clyde",
  },
  {
    postal_code: "01453",
    full_city_name: "Leominster, MA",
    city_name: "Leominster",
  },
  {
    postal_code: "02559",
    full_city_name: "Pocasset, MA",
    city_name: "Pocasset",
  },
  {
    postal_code: "02740",
    full_city_name: "New Bedford, MA",
    city_name: "New Bedford",
  },
  {
    postal_code: "90094",
    full_city_name: "Playa Vista, CA",
    city_name: "Playa Vista",
  },
  {
    postal_code: "03458",
    full_city_name: "Peterborough, NH",
    city_name: "Peterborough",
  },
  {
    postal_code: "77328",
    full_city_name: "Cleveland, TX",
    city_name: "Cleveland",
  },
  {
    postal_code: "61282",
    full_city_name: "Silvis, IL",
    city_name: "Silvis",
  },
  {
    postal_code: "27263",
    full_city_name: "High Point, NC",
    city_name: "High Point",
  },
  {
    postal_code: "15208",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "13624",
    full_city_name: "Clayton, NY",
    city_name: "Clayton",
  },
  {
    postal_code: "12413",
    full_city_name: "Cairo, NY",
    city_name: "Cairo",
  },
  {
    postal_code: "32131",
    full_city_name: "East Palatka, FL",
    city_name: "East Palatka",
  },
  {
    postal_code: "94127",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "06787",
    full_city_name: "Thomaston, CT",
    city_name: "Thomaston",
  },
  {
    postal_code: "22810",
    full_city_name: "Basye, VA",
    city_name: "Basye",
  },
  {
    postal_code: "50588",
    full_city_name: "Storm Lake, IA",
    city_name: "Storm Lake",
  },
  {
    postal_code: "76642",
    full_city_name: "Groesbeck, TX",
    city_name: "Groesbeck",
  },
  {
    postal_code: "84101",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "56479",
    full_city_name: "Staples, MN",
    city_name: "Staples",
  },
  {
    postal_code: "48144",
    full_city_name: "Lambertville, MI",
    city_name: "Lambertville",
  },
  {
    postal_code: "48116",
    full_city_name: "Brighton, MI",
    city_name: "Brighton",
  },
  {
    postal_code: "94027",
    full_city_name: "Atherton, CA",
    city_name: "Atherton",
  },
  {
    postal_code: "13036",
    full_city_name: "Central Square, NY",
    city_name: "Central Square",
  },
  {
    postal_code: "43469",
    full_city_name: "Woodville, OH",
    city_name: "Woodville",
  },
  {
    postal_code: "47012",
    full_city_name: "Brookville, IN",
    city_name: "Brookville",
  },
  {
    postal_code: "79904",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "79512",
    full_city_name: "Colorado City, TX",
    city_name: "Colorado City",
  },
  {
    postal_code: "44286",
    full_city_name: "Richfield, OH",
    city_name: "Richfield",
  },
  {
    postal_code: "29915",
    full_city_name: "Daufuskie Island, SC",
    city_name: "Daufuskie Island",
  },
  {
    postal_code: "44050",
    full_city_name: "Lagrange, OH",
    city_name: "Lagrange",
  },
  {
    postal_code: "03816",
    full_city_name: "Center Tuftonboro, NH",
    city_name: "Center Tuftonboro",
  },
  {
    postal_code: "12833",
    full_city_name: "Greenfield Center, NY",
    city_name: "Greenfield Center",
  },
  {
    postal_code: "39563",
    full_city_name: "Moss Point, MS",
    city_name: "Moss Point",
  },
  {
    postal_code: "28626",
    full_city_name: "Fleetwood, NC",
    city_name: "Fleetwood",
  },
  {
    postal_code: "75453",
    full_city_name: "Lone Oak, TX",
    city_name: "Lone Oak",
  },
  {
    postal_code: "12870",
    full_city_name: "Schroon Lake, NY",
    city_name: "Schroon Lake",
  },
  {
    postal_code: "20769",
    full_city_name: "Glenn Dale, MD",
    city_name: "Glenn Dale",
  },
  {
    postal_code: "98244",
    full_city_name: "Deming, WA",
    city_name: "Deming",
  },
  {
    postal_code: "02660",
    full_city_name: "South Dennis, MA",
    city_name: "South Dennis",
  },
  {
    postal_code: "04967",
    full_city_name: "Pittsfield, ME",
    city_name: "Pittsfield",
  },
  {
    postal_code: "92313",
    full_city_name: "Grand Terrace, CA",
    city_name: "Grand Terrace",
  },
  {
    postal_code: "75210",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "37030",
    full_city_name: "Carthage, TN",
    city_name: "Carthage",
  },
  {
    postal_code: "01085",
    full_city_name: "Westfield, MA",
    city_name: "Westfield",
  },
  {
    postal_code: "77461",
    full_city_name: "Needville, TX",
    city_name: "Needville",
  },
  {
    postal_code: "18509",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "75141",
    full_city_name: "Hutchins, TX",
    city_name: "Hutchins",
  },
  {
    postal_code: "54555",
    full_city_name: "Phillips, WI",
    city_name: "Phillips",
  },
  {
    postal_code: "77480",
    full_city_name: "Sweeny, TX",
    city_name: "Sweeny",
  },
  {
    postal_code: "62859",
    full_city_name: "Mc Leansboro, IL",
    city_name: "Mc Leansboro",
  },
  {
    postal_code: "95684",
    full_city_name: "Somerset, CA",
    city_name: "Somerset",
  },
  {
    postal_code: "18438",
    full_city_name: "Lakeville, PA",
    city_name: "Lakeville",
  },
  {
    postal_code: "95605",
    full_city_name: "West Sacramento, CA",
    city_name: "West Sacramento",
  },
  {
    postal_code: "22542",
    full_city_name: "Rhoadesville, VA",
    city_name: "Rhoadesville",
  },
  {
    postal_code: "43558",
    full_city_name: "Swanton, OH",
    city_name: "Swanton",
  },
  {
    postal_code: "54730",
    full_city_name: "Colfax, WI",
    city_name: "Colfax",
  },
  {
    postal_code: "44612",
    full_city_name: "Bolivar, OH",
    city_name: "Bolivar",
  },
  {
    postal_code: "55130",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "90020",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "55041",
    full_city_name: "Lake City, MN",
    city_name: "Lake City",
  },
  {
    postal_code: "13203",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "04240",
    full_city_name: "Lewiston, ME",
    city_name: "Lewiston",
  },
  {
    postal_code: "06374",
    full_city_name: "Plainfield, CT",
    city_name: "Plainfield",
  },
  {
    postal_code: "91208",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "90623",
    full_city_name: "La Palma, CA",
    city_name: "La Palma",
  },
  {
    postal_code: "65559",
    full_city_name: "Saint James, MO",
    city_name: "Saint James",
  },
  {
    postal_code: "95631",
    full_city_name: "Foresthill, CA",
    city_name: "Foresthill",
  },
  {
    postal_code: "75459",
    full_city_name: "Howe, TX",
    city_name: "Howe",
  },
  {
    postal_code: "03109",
    full_city_name: "Manchester, NH",
    city_name: "Manchester",
  },
  {
    postal_code: "12865",
    full_city_name: "Salem, NY",
    city_name: "Salem",
  },
  {
    postal_code: "68520",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "32732",
    full_city_name: "Geneva, FL",
    city_name: "Geneva",
  },
  {
    postal_code: "97760",
    full_city_name: "Terrebonne, OR",
    city_name: "Terrebonne",
  },
  {
    postal_code: "95832",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "43318",
    full_city_name: "De Graff, OH",
    city_name: "De Graff",
  },
  {
    postal_code: "94606",
    full_city_name: "Oakland, CA",
    city_name: "Oakland",
  },
  {
    postal_code: "94572",
    full_city_name: "Rodeo, CA",
    city_name: "Rodeo",
  },
  {
    postal_code: "43080",
    full_city_name: "Utica, OH",
    city_name: "Utica",
  },
  {
    postal_code: "48065",
    full_city_name: "Romeo, MI",
    city_name: "Romeo",
  },
  {
    postal_code: "35128",
    full_city_name: "Pell City, AL",
    city_name: "Pell City",
  },
  {
    postal_code: "96097",
    full_city_name: "Yreka, CA",
    city_name: "Yreka",
  },
  {
    postal_code: "25702",
    full_city_name: "Huntington, WV",
    city_name: "Huntington",
  },
  {
    postal_code: "01752",
    full_city_name: "Marlborough, MA",
    city_name: "Marlborough",
  },
  {
    postal_code: "46167",
    full_city_name: "Pittsboro, IN",
    city_name: "Pittsboro",
  },
  {
    postal_code: "95963",
    full_city_name: "Orland, CA",
    city_name: "Orland",
  },
  {
    postal_code: "75021",
    full_city_name: "Denison, TX",
    city_name: "Denison",
  },
  {
    postal_code: "23015",
    full_city_name: "Beaverdam, VA",
    city_name: "Beaverdam",
  },
  {
    postal_code: "79415",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "36027",
    full_city_name: "Eufaula, AL",
    city_name: "Eufaula",
  },
  {
    postal_code: "63780",
    full_city_name: "Scott City, MO",
    city_name: "Scott City",
  },
  {
    postal_code: "46322",
    full_city_name: "Highland, IN",
    city_name: "Highland",
  },
  {
    postal_code: "11237",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "30646",
    full_city_name: "Hull, GA",
    city_name: "Hull",
  },
  {
    postal_code: "77029",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "46341",
    full_city_name: "Hebron, IN",
    city_name: "Hebron",
  },
  {
    postal_code: "03818",
    full_city_name: "Conway, NH",
    city_name: "Conway",
  },
  {
    postal_code: "79403",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "30176",
    full_city_name: "Tallapoosa, GA",
    city_name: "Tallapoosa",
  },
  {
    postal_code: "30401",
    full_city_name: "Swainsboro, GA",
    city_name: "Swainsboro",
  },
  {
    postal_code: "37683",
    full_city_name: "Mountain City, TN",
    city_name: "Mountain City",
  },
  {
    postal_code: "93210",
    full_city_name: "Coalinga, CA",
    city_name: "Coalinga",
  },
  {
    postal_code: "21217",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "75440",
    full_city_name: "Emory, TX",
    city_name: "Emory",
  },
  {
    postal_code: "64843",
    full_city_name: "Goodman, MO",
    city_name: "Goodman",
  },
  {
    postal_code: "76230",
    full_city_name: "Bowie, TX",
    city_name: "Bowie",
  },
  {
    postal_code: "28337",
    full_city_name: "Elizabethtown, NC",
    city_name: "Elizabethtown",
  },
  {
    postal_code: "45315",
    full_city_name: "Clayton, OH",
    city_name: "Clayton",
  },
  {
    postal_code: "22611",
    full_city_name: "Berryville, VA",
    city_name: "Berryville",
  },
  {
    postal_code: "01604",
    full_city_name: "Worcester, MA",
    city_name: "Worcester",
  },
  {
    postal_code: "54452",
    full_city_name: "Merrill, WI",
    city_name: "Merrill",
  },
  {
    postal_code: "22958",
    full_city_name: "Nellysford, VA",
    city_name: "Nellysford",
  },
  {
    postal_code: "88312",
    full_city_name: "Alto, NM",
    city_name: "Alto",
  },
  {
    postal_code: "21032",
    full_city_name: "Crownsville, MD",
    city_name: "Crownsville",
  },
  {
    postal_code: "11766",
    full_city_name: "Mount Sinai, NY",
    city_name: "Mount Sinai",
  },
  {
    postal_code: "16137",
    full_city_name: "Mercer, PA",
    city_name: "Mercer",
  },
  {
    postal_code: "94089",
    full_city_name: "Sunnyvale, CA",
    city_name: "Sunnyvale",
  },
  {
    postal_code: "74848",
    full_city_name: "Holdenville, OK",
    city_name: "Holdenville",
  },
  {
    postal_code: "05143",
    full_city_name: "Chester, VT",
    city_name: "Chester",
  },
  {
    postal_code: "55388",
    full_city_name: "Watertown, MN",
    city_name: "Watertown",
  },
  {
    postal_code: "54724",
    full_city_name: "Bloomer, WI",
    city_name: "Bloomer",
  },
  {
    postal_code: "23602",
    full_city_name: "Newport News, VA",
    city_name: "Newport News",
  },
  {
    postal_code: "29330",
    full_city_name: "Cowpens, SC",
    city_name: "Cowpens",
  },
  {
    postal_code: "08312",
    full_city_name: "Clayton, NJ",
    city_name: "Clayton",
  },
  {
    postal_code: "37686",
    full_city_name: "Piney Flats, TN",
    city_name: "Piney Flats",
  },
  {
    postal_code: "28466",
    full_city_name: "Wallace, NC",
    city_name: "Wallace",
  },
  {
    postal_code: "03598",
    full_city_name: "Whitefield, NH",
    city_name: "Whitefield",
  },
  {
    postal_code: "46120",
    full_city_name: "Cloverdale, IN",
    city_name: "Cloverdale",
  },
  {
    postal_code: "20866",
    full_city_name: "Burtonsville, MD",
    city_name: "Burtonsville",
  },
  {
    postal_code: "20012",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "27973",
    full_city_name: "Shawboro, NC",
    city_name: "Shawboro",
  },
  {
    postal_code: "01844",
    full_city_name: "Methuen, MA",
    city_name: "Methuen",
  },
  {
    postal_code: "28722",
    full_city_name: "Columbus, NC",
    city_name: "Columbus",
  },
  {
    postal_code: "45879",
    full_city_name: "Paulding, OH",
    city_name: "Paulding",
  },
  {
    postal_code: "01970",
    full_city_name: "Salem, MA",
    city_name: "Salem",
  },
  {
    postal_code: "45335",
    full_city_name: "Jamestown, OH",
    city_name: "Jamestown",
  },
  {
    postal_code: "52753",
    full_city_name: "Le Claire, IA",
    city_name: "Le Claire",
  },
  {
    postal_code: "92372",
    full_city_name: "Pinon Hills, CA",
    city_name: "Pinon Hills",
  },
  {
    postal_code: "06057",
    full_city_name: "New Hartford, CT",
    city_name: "New Hartford",
  },
  {
    postal_code: "32696",
    full_city_name: "Williston, FL",
    city_name: "Williston",
  },
  {
    postal_code: "43460",
    full_city_name: "Rossford, OH",
    city_name: "Rossford",
  },
  {
    postal_code: "55352",
    full_city_name: "Jordan, MN",
    city_name: "Jordan",
  },
  {
    postal_code: "01913",
    full_city_name: "Amesbury, MA",
    city_name: "Amesbury",
  },
  {
    postal_code: "12090",
    full_city_name: "Hoosick Falls, NY",
    city_name: "Hoosick Falls",
  },
  {
    postal_code: "17866",
    full_city_name: "Coal Township, PA",
    city_name: "Coal Township",
  },
  {
    postal_code: "18929",
    full_city_name: "Jamison, PA",
    city_name: "Jamison",
  },
  {
    postal_code: "19070",
    full_city_name: "Morton, PA",
    city_name: "Morton",
  },
  {
    postal_code: "06422",
    full_city_name: "Durham, CT",
    city_name: "Durham",
  },
  {
    postal_code: "93101",
    full_city_name: "Santa Barbara, CA",
    city_name: "Santa Barbara",
  },
  {
    postal_code: "70070",
    full_city_name: "Luling, LA",
    city_name: "Luling",
  },
  {
    postal_code: "59870",
    full_city_name: "Stevensville, MT",
    city_name: "Stevensville",
  },
  {
    postal_code: "48837",
    full_city_name: "Grand Ledge, MI",
    city_name: "Grand Ledge",
  },
  {
    postal_code: "39562",
    full_city_name: "Moss Point, MS",
    city_name: "Moss Point",
  },
  {
    postal_code: "70443",
    full_city_name: "Independence, LA",
    city_name: "Independence",
  },
  {
    postal_code: "41102",
    full_city_name: "Ashland, KY",
    city_name: "Ashland",
  },
  {
    postal_code: "48158",
    full_city_name: "Manchester, MI",
    city_name: "Manchester",
  },
  {
    postal_code: "90001",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "25541",
    full_city_name: "Milton, WV",
    city_name: "Milton",
  },
  {
    postal_code: "45217",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "64108",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "49841",
    full_city_name: "Gwinn, MI",
    city_name: "Gwinn",
  },
  {
    postal_code: "27317",
    full_city_name: "Randleman, NC",
    city_name: "Randleman",
  },
  {
    postal_code: "90061",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "65336",
    full_city_name: "Knob Noster, MO",
    city_name: "Knob Noster",
  },
  {
    postal_code: "32976",
    full_city_name: "Sebastian, FL",
    city_name: "Sebastian",
  },
  {
    postal_code: "15906",
    full_city_name: "Johnstown, PA",
    city_name: "Johnstown",
  },
  {
    postal_code: "49866",
    full_city_name: "Negaunee, MI",
    city_name: "Negaunee",
  },
  {
    postal_code: "49117",
    full_city_name: "New Buffalo, MI",
    city_name: "New Buffalo",
  },
  {
    postal_code: "06071",
    full_city_name: "Somers, CT",
    city_name: "Somers",
  },
  {
    postal_code: "08097",
    full_city_name: "Woodbury Heights, NJ",
    city_name: "Woodbury Heights",
  },
  {
    postal_code: "33921",
    full_city_name: "Boca Grande, FL",
    city_name: "Boca Grande",
  },
  {
    postal_code: "05250",
    full_city_name: "Arlington, VT",
    city_name: "Arlington",
  },
  {
    postal_code: "77078",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "93212",
    full_city_name: "Corcoran, CA",
    city_name: "Corcoran",
  },
  {
    postal_code: "11411",
    full_city_name: "Cambria Heights, NY",
    city_name: "Cambria Heights",
  },
  {
    postal_code: "77488",
    full_city_name: "Wharton, TX",
    city_name: "Wharton",
  },
  {
    postal_code: "46408",
    full_city_name: "Gary, IN",
    city_name: "Gary",
  },
  {
    postal_code: "70053",
    full_city_name: "Gretna, LA",
    city_name: "Gretna",
  },
  {
    postal_code: "37890",
    full_city_name: "White Pine, TN",
    city_name: "White Pine",
  },
  {
    postal_code: "64127",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "37769",
    full_city_name: "Rocky Top, TN",
    city_name: "Rocky Top",
  },
  {
    postal_code: "12037",
    full_city_name: "Chatham, NY",
    city_name: "Chatham",
  },
  {
    postal_code: "63115",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "86333",
    full_city_name: "Mayer, AZ",
    city_name: "Mayer",
  },
  {
    postal_code: "42122",
    full_city_name: "Alvaton, KY",
    city_name: "Alvaton",
  },
  {
    postal_code: "95816",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "98596",
    full_city_name: "Winlock, WA",
    city_name: "Winlock",
  },
  {
    postal_code: "53578",
    full_city_name: "Prairie Du Sac, WI",
    city_name: "Prairie Du Sac",
  },
  {
    postal_code: "45404",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "24354",
    full_city_name: "Marion, VA",
    city_name: "Marion",
  },
  {
    postal_code: "28170",
    full_city_name: "Wadesboro, NC",
    city_name: "Wadesboro",
  },
  {
    postal_code: "12443",
    full_city_name: "Hurley, NY",
    city_name: "Hurley",
  },
  {
    postal_code: "90404",
    full_city_name: "Santa Monica, CA",
    city_name: "Santa Monica",
  },
  {
    postal_code: "44270",
    full_city_name: "Rittman, OH",
    city_name: "Rittman",
  },
  {
    postal_code: "24211",
    full_city_name: "Abingdon, VA",
    city_name: "Abingdon",
  },
  {
    postal_code: "92624",
    full_city_name: "Capistrano Beach, CA",
    city_name: "Capistrano Beach",
  },
  {
    postal_code: "01845",
    full_city_name: "North Andover, MA",
    city_name: "North Andover",
  },
  {
    postal_code: "40067",
    full_city_name: "Simpsonville, KY",
    city_name: "Simpsonville",
  },
  {
    postal_code: "28729",
    full_city_name: "Etowah, NC",
    city_name: "Etowah",
  },
  {
    postal_code: "55355",
    full_city_name: "Litchfield, MN",
    city_name: "Litchfield",
  },
  {
    postal_code: "91723",
    full_city_name: "Covina, CA",
    city_name: "Covina",
  },
  {
    postal_code: "22842",
    full_city_name: "Mount Jackson, VA",
    city_name: "Mount Jackson",
  },
  {
    postal_code: "12143",
    full_city_name: "Ravena, NY",
    city_name: "Ravena",
  },
  {
    postal_code: "55103",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "92604",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "63664",
    full_city_name: "Potosi, MO",
    city_name: "Potosi",
  },
  {
    postal_code: "28571",
    full_city_name: "Oriental, NC",
    city_name: "Oriental",
  },
  {
    postal_code: "77017",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "66901",
    full_city_name: "Concordia, KS",
    city_name: "Concordia",
  },
  {
    postal_code: "37665",
    full_city_name: "Kingsport, TN",
    city_name: "Kingsport",
  },
  {
    postal_code: "78643",
    full_city_name: "Llano, TX",
    city_name: "Llano",
  },
  {
    postal_code: "89034",
    full_city_name: "Mesquite, NV",
    city_name: "Mesquite",
  },
  {
    postal_code: "85933",
    full_city_name: "Overgaard, AZ",
    city_name: "Overgaard",
  },
  {
    postal_code: "54971",
    full_city_name: "Ripon, WI",
    city_name: "Ripon",
  },
  {
    postal_code: "11932",
    full_city_name: "Bridgehampton, NY",
    city_name: "Bridgehampton",
  },
  {
    postal_code: "54876",
    full_city_name: "Stone Lake, WI",
    city_name: "Stone Lake",
  },
  {
    postal_code: "08022",
    full_city_name: "Columbus, NJ",
    city_name: "Columbus",
  },
  {
    postal_code: "38019",
    full_city_name: "Covington, TN",
    city_name: "Covington",
  },
  {
    postal_code: "94960",
    full_city_name: "San Anselmo, CA",
    city_name: "San Anselmo",
  },
  {
    postal_code: "94945",
    full_city_name: "Novato, CA",
    city_name: "Novato",
  },
  {
    postal_code: "56470",
    full_city_name: "Park Rapids, MN",
    city_name: "Park Rapids",
  },
  {
    postal_code: "02646",
    full_city_name: "Harwich Port, MA",
    city_name: "Harwich Port",
  },
  {
    postal_code: "11213",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "98541",
    full_city_name: "Elma, WA",
    city_name: "Elma",
  },
  {
    postal_code: "24522",
    full_city_name: "Appomattox, VA",
    city_name: "Appomattox",
  },
  {
    postal_code: "01930",
    full_city_name: "Gloucester, MA",
    city_name: "Gloucester",
  },
  {
    postal_code: "16059",
    full_city_name: "Valencia, PA",
    city_name: "Valencia",
  },
  {
    postal_code: "69301",
    full_city_name: "Alliance, NE",
    city_name: "Alliance",
  },
  {
    postal_code: "99025",
    full_city_name: "Newman Lake, WA",
    city_name: "Newman Lake",
  },
  {
    postal_code: "06524",
    full_city_name: "Bethany, CT",
    city_name: "Bethany",
  },
  {
    postal_code: "27306",
    full_city_name: "Mount Gilead, NC",
    city_name: "Mount Gilead",
  },
  {
    postal_code: "22209",
    full_city_name: "Arlington, VA",
    city_name: "Arlington",
  },
  {
    postal_code: "81005",
    full_city_name: "Pueblo, CO",
    city_name: "Pueblo",
  },
  {
    postal_code: "84050",
    full_city_name: "Morgan, UT",
    city_name: "Morgan",
  },
  {
    postal_code: "95405",
    full_city_name: "Santa Rosa, CA",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "64137",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "72150",
    full_city_name: "Sheridan, AR",
    city_name: "Sheridan",
  },
  {
    postal_code: "12204",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "73128",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "90305",
    full_city_name: "Inglewood, CA",
    city_name: "Inglewood",
  },
  {
    postal_code: "21001",
    full_city_name: "Aberdeen, MD",
    city_name: "Aberdeen",
  },
  {
    postal_code: "02828",
    full_city_name: "Greenville, RI",
    city_name: "Greenville",
  },
  {
    postal_code: "44890",
    full_city_name: "Willard, OH",
    city_name: "Willard",
  },
  {
    postal_code: "39437",
    full_city_name: "Ellisville, MS",
    city_name: "Ellisville",
  },
  {
    postal_code: "15501",
    full_city_name: "Somerset, PA",
    city_name: "Somerset",
  },
  {
    postal_code: "80643",
    full_city_name: "Keenesburg, CO",
    city_name: "Keenesburg",
  },
  {
    postal_code: "94502",
    full_city_name: "Alameda, CA",
    city_name: "Alameda",
  },
  {
    postal_code: "21788",
    full_city_name: "Thurmont, MD",
    city_name: "Thurmont",
  },
  {
    postal_code: "03062",
    full_city_name: "Nashua, NH",
    city_name: "Nashua",
  },
  {
    postal_code: "06759",
    full_city_name: "Litchfield, CT",
    city_name: "Litchfield",
  },
  {
    postal_code: "96041",
    full_city_name: "Hayfork, CA",
    city_name: "Hayfork",
  },
  {
    postal_code: "55079",
    full_city_name: "Stacy, MN",
    city_name: "Stacy",
  },
  {
    postal_code: "02639",
    full_city_name: "Dennis Port, MA",
    city_name: "Dennis Port",
  },
  {
    postal_code: "38012",
    full_city_name: "Brownsville, TN",
    city_name: "Brownsville",
  },
  {
    postal_code: "60195",
    full_city_name: "Schaumburg, IL",
    city_name: "Schaumburg",
  },
  {
    postal_code: "65806",
    full_city_name: "Springfield, MO",
    city_name: "Springfield",
  },
  {
    postal_code: "75457",
    full_city_name: "Mount Vernon, TX",
    city_name: "Mount Vernon",
  },
  {
    postal_code: "37879",
    full_city_name: "Tazewell, TN",
    city_name: "Tazewell",
  },
  {
    postal_code: "55810",
    full_city_name: "Duluth, MN",
    city_name: "Duluth",
  },
  {
    postal_code: "06403",
    full_city_name: "Beacon Falls, CT",
    city_name: "Beacon Falls",
  },
  {
    postal_code: "64117",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "07863",
    full_city_name: "Oxford, NJ",
    city_name: "Oxford",
  },
  {
    postal_code: "27055",
    full_city_name: "Yadkinville, NC",
    city_name: "Yadkinville",
  },
  {
    postal_code: "22560",
    full_city_name: "Tappahannock, VA",
    city_name: "Tappahannock",
  },
  {
    postal_code: "41097",
    full_city_name: "Williamstown, KY",
    city_name: "Williamstown",
  },
  {
    postal_code: "20115",
    full_city_name: "Marshall, VA",
    city_name: "Marshall",
  },
  {
    postal_code: "88063",
    full_city_name: "Sunland Park, NM",
    city_name: "Sunland Park",
  },
  {
    postal_code: "02874",
    full_city_name: "Saunderstown, RI",
    city_name: "Saunderstown",
  },
  {
    postal_code: "19056",
    full_city_name: "Levittown, PA",
    city_name: "Levittown",
  },
  {
    postal_code: "03033",
    full_city_name: "Brookline, NH",
    city_name: "Brookline",
  },
  {
    postal_code: "27051",
    full_city_name: "Walkertown, NC",
    city_name: "Walkertown",
  },
  {
    postal_code: "02563",
    full_city_name: "Sandwich, MA",
    city_name: "Sandwich",
  },
  {
    postal_code: "90005",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "33597",
    full_city_name: "Webster, FL",
    city_name: "Webster",
  },
  {
    postal_code: "90806",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "03885",
    full_city_name: "Stratham, NH",
    city_name: "Stratham",
  },
  {
    postal_code: "20636",
    full_city_name: "Hollywood, MD",
    city_name: "Hollywood",
  },
  {
    postal_code: "75109",
    full_city_name: "Corsicana, TX",
    city_name: "Corsicana",
  },
  {
    postal_code: "02745",
    full_city_name: "New Bedford, MA",
    city_name: "New Bedford",
  },
  {
    postal_code: "53576",
    full_city_name: "Orfordville, WI",
    city_name: "Orfordville",
  },
  {
    postal_code: "70582",
    full_city_name: "Saint Martinville, LA",
    city_name: "Saint Martinville",
  },
  {
    postal_code: "63113",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "75835",
    full_city_name: "Crockett, TX",
    city_name: "Crockett",
  },
  {
    postal_code: "53555",
    full_city_name: "Lodi, WI",
    city_name: "Lodi",
  },
  {
    postal_code: "11363",
    full_city_name: "Little Neck, NY",
    city_name: "Little Neck",
  },
  {
    postal_code: "95388",
    full_city_name: "Winton, CA",
    city_name: "Winton",
  },
  {
    postal_code: "98823",
    full_city_name: "Ephrata, WA",
    city_name: "Ephrata",
  },
  {
    postal_code: "53949",
    full_city_name: "Montello, WI",
    city_name: "Montello",
  },
  {
    postal_code: "96741",
    full_city_name: "Kalaheo, HI",
    city_name: "Kalaheo",
  },
  {
    postal_code: "97753",
    full_city_name: "Powell Butte, OR",
    city_name: "Powell Butte",
  },
  {
    postal_code: "75789",
    full_city_name: "Troup, TX",
    city_name: "Troup",
  },
  {
    postal_code: "24590",
    full_city_name: "Scottsville, VA",
    city_name: "Scottsville",
  },
  {
    postal_code: "50216",
    full_city_name: "Panora, IA",
    city_name: "Panora",
  },
  {
    postal_code: "01852",
    full_city_name: "Lowell, MA",
    city_name: "Lowell",
  },
  {
    postal_code: "04917",
    full_city_name: "Belgrade, ME",
    city_name: "Belgrade",
  },
  {
    postal_code: "25143",
    full_city_name: "Nitro, WV",
    city_name: "Nitro",
  },
  {
    postal_code: "94702",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "91501",
    full_city_name: "Burbank, CA",
    city_name: "Burbank",
  },
  {
    postal_code: "90249",
    full_city_name: "Gardena, CA",
    city_name: "Gardena",
  },
  {
    postal_code: "02302",
    full_city_name: "Brockton, MA",
    city_name: "Brockton",
  },
  {
    postal_code: "81230",
    full_city_name: "Gunnison, CO",
    city_name: "Gunnison",
  },
  {
    postal_code: "85045",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "75704",
    full_city_name: "Tyler, TX",
    city_name: "Tyler",
  },
  {
    postal_code: "92256",
    full_city_name: "Morongo Valley, CA",
    city_name: "Morongo Valley",
  },
  {
    postal_code: "50220",
    full_city_name: "Perry, IA",
    city_name: "Perry",
  },
  {
    postal_code: "15926",
    full_city_name: "Central City, PA",
    city_name: "Central City",
  },
  {
    postal_code: "06377",
    full_city_name: "Sterling, CT",
    city_name: "Sterling",
  },
  {
    postal_code: "92567",
    full_city_name: "Nuevo, CA",
    city_name: "Nuevo",
  },
  {
    postal_code: "05753",
    full_city_name: "Middlebury, VT",
    city_name: "Middlebury",
  },
  {
    postal_code: "52632",
    full_city_name: "Keokuk, IA",
    city_name: "Keokuk",
  },
  {
    postal_code: "93710",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "70586",
    full_city_name: "Ville Platte, LA",
    city_name: "Ville Platte",
  },
  {
    postal_code: "85305",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "76050",
    full_city_name: "Grandview, TX",
    city_name: "Grandview",
  },
  {
    postal_code: "21758",
    full_city_name: "Knoxville, MD",
    city_name: "Knoxville",
  },
  {
    postal_code: "95948",
    full_city_name: "Gridley, CA",
    city_name: "Gridley",
  },
  {
    postal_code: "38843",
    full_city_name: "Fulton, MS",
    city_name: "Fulton",
  },
  {
    postal_code: "60401",
    full_city_name: "Beecher, IL",
    city_name: "Beecher",
  },
  {
    postal_code: "61342",
    full_city_name: "Mendota, IL",
    city_name: "Mendota",
  },
  {
    postal_code: "49913",
    full_city_name: "Calumet, MI",
    city_name: "Calumet",
  },
  {
    postal_code: "02131",
    full_city_name: "Roslindale, MA",
    city_name: "Roslindale",
  },
  {
    postal_code: "72632",
    full_city_name: "Eureka Springs, AR",
    city_name: "Eureka Springs",
  },
  {
    postal_code: "49031",
    full_city_name: "Cassopolis, MI",
    city_name: "Cassopolis",
  },
  {
    postal_code: "77011",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "43605",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "42376",
    full_city_name: "Utica, KY",
    city_name: "Utica",
  },
  {
    postal_code: "45252",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "78566",
    full_city_name: "Los Fresnos, TX",
    city_name: "Los Fresnos",
  },
  {
    postal_code: "93723",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "27837",
    full_city_name: "Grimesland, NC",
    city_name: "Grimesland",
  },
  {
    postal_code: "85643",
    full_city_name: "Willcox, AZ",
    city_name: "Willcox",
  },
  {
    postal_code: "29379",
    full_city_name: "Union, SC",
    city_name: "Union",
  },
  {
    postal_code: "90037",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "49332",
    full_city_name: "Mecosta, MI",
    city_name: "Mecosta",
  },
  {
    postal_code: "91755",
    full_city_name: "Monterey Park, CA",
    city_name: "Monterey Park",
  },
  {
    postal_code: "54801",
    full_city_name: "Spooner, WI",
    city_name: "Spooner",
  },
  {
    postal_code: "06472",
    full_city_name: "Northford, CT",
    city_name: "Northford",
  },
  {
    postal_code: "79108",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "50156",
    full_city_name: "Madrid, IA",
    city_name: "Madrid",
  },
  {
    postal_code: "98465",
    full_city_name: "Tacoma, WA",
    city_name: "Tacoma",
  },
  {
    postal_code: "56320",
    full_city_name: "Cold Spring, MN",
    city_name: "Cold Spring",
  },
  {
    postal_code: "43526",
    full_city_name: "Hicksville, OH",
    city_name: "Hicksville",
  },
  {
    postal_code: "48314",
    full_city_name: "Sterling Heights, MI",
    city_name: "Sterling Heights",
  },
  {
    postal_code: "12580",
    full_city_name: "Staatsburg, NY",
    city_name: "Staatsburg",
  },
  {
    postal_code: "32145",
    full_city_name: "Hastings, FL",
    city_name: "Hastings",
  },
  {
    postal_code: "37811",
    full_city_name: "Mooresburg, TN",
    city_name: "Mooresburg",
  },
  {
    postal_code: "44074",
    full_city_name: "Oberlin, OH",
    city_name: "Oberlin",
  },
  {
    postal_code: "90606",
    full_city_name: "Whittier, CA",
    city_name: "Whittier",
  },
  {
    postal_code: "81303",
    full_city_name: "Durango, CO",
    city_name: "Durango",
  },
  {
    postal_code: "61607",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "20872",
    full_city_name: "Damascus, MD",
    city_name: "Damascus",
  },
  {
    postal_code: "37820",
    full_city_name: "New Market, TN",
    city_name: "New Market",
  },
  {
    postal_code: "14886",
    full_city_name: "Trumansburg, NY",
    city_name: "Trumansburg",
  },
  {
    postal_code: "20737",
    full_city_name: "Riverdale, MD",
    city_name: "Riverdale",
  },
  {
    postal_code: "38028",
    full_city_name: "Eads, TN",
    city_name: "Eads",
  },
  {
    postal_code: "49854",
    full_city_name: "Manistique, MI",
    city_name: "Manistique",
  },
  {
    postal_code: "90290",
    full_city_name: "Topanga, CA",
    city_name: "Topanga",
  },
  {
    postal_code: "18840",
    full_city_name: "Sayre, PA",
    city_name: "Sayre",
  },
  {
    postal_code: "06026",
    full_city_name: "East Granby, CT",
    city_name: "East Granby",
  },
  {
    postal_code: "28159",
    full_city_name: "Spencer, NC",
    city_name: "Spencer",
  },
  {
    postal_code: "75240",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "14105",
    full_city_name: "Middleport, NY",
    city_name: "Middleport",
  },
  {
    postal_code: "48723",
    full_city_name: "Caro, MI",
    city_name: "Caro",
  },
  {
    postal_code: "20882",
    full_city_name: "Gaithersburg, MD",
    city_name: "Gaithersburg",
  },
  {
    postal_code: "43031",
    full_city_name: "Johnstown, OH",
    city_name: "Johnstown",
  },
  {
    postal_code: "35222",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "57730",
    full_city_name: "Custer, SD",
    city_name: "Custer",
  },
  {
    postal_code: "30621",
    full_city_name: "Bishop, GA",
    city_name: "Bishop",
  },
  {
    postal_code: "04073",
    full_city_name: "Sanford, ME",
    city_name: "Sanford",
  },
  {
    postal_code: "50309",
    full_city_name: "Des Moines, IA",
    city_name: "Des Moines",
  },
  {
    postal_code: "31014",
    full_city_name: "Cochran, GA",
    city_name: "Cochran",
  },
  {
    postal_code: "06226",
    full_city_name: "Willimantic, CT",
    city_name: "Willimantic",
  },
  {
    postal_code: "79360",
    full_city_name: "Seminole, TX",
    city_name: "Seminole",
  },
  {
    postal_code: "60624",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "05201",
    full_city_name: "Bennington, VT",
    city_name: "Bennington",
  },
  {
    postal_code: "87547",
    full_city_name: "White Rock, NM",
    city_name: "White Rock",
  },
  {
    postal_code: "53925",
    full_city_name: "Columbus, WI",
    city_name: "Columbus",
  },
  {
    postal_code: "30288",
    full_city_name: "Conley, GA",
    city_name: "Conley",
  },
  {
    postal_code: "85007",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "90067",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "94705",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "41014",
    full_city_name: "Covington, KY",
    city_name: "Covington",
  },
  {
    postal_code: "28675",
    full_city_name: "Sparta, NC",
    city_name: "Sparta",
  },
  {
    postal_code: "75161",
    full_city_name: "Terrell, TX",
    city_name: "Terrell",
  },
  {
    postal_code: "56387",
    full_city_name: "Waite Park, MN",
    city_name: "Waite Park",
  },
  {
    postal_code: "06786",
    full_city_name: "Terryville, CT",
    city_name: "Terryville",
  },
  {
    postal_code: "17033",
    full_city_name: "Hershey, PA",
    city_name: "Hershey",
  },
  {
    postal_code: "18435",
    full_city_name: "Lackawaxen, PA",
    city_name: "Lackawaxen",
  },
  {
    postal_code: "45323",
    full_city_name: "Enon, OH",
    city_name: "Enon",
  },
  {
    postal_code: "43162",
    full_city_name: "West Jefferson, OH",
    city_name: "West Jefferson",
  },
  {
    postal_code: "45345",
    full_city_name: "New Lebanon, OH",
    city_name: "New Lebanon",
  },
  {
    postal_code: "37650",
    full_city_name: "Erwin, TN",
    city_name: "Erwin",
  },
  {
    postal_code: "36360",
    full_city_name: "Ozark, AL",
    city_name: "Ozark",
  },
  {
    postal_code: "20637",
    full_city_name: "Hughesville, MD",
    city_name: "Hughesville",
  },
  {
    postal_code: "77879",
    full_city_name: "Somerville, TX",
    city_name: "Somerville",
  },
  {
    postal_code: "63670",
    full_city_name: "Sainte Genevieve, MO",
    city_name: "Sainte Genevieve",
  },
  {
    postal_code: "98506",
    full_city_name: "Olympia, WA",
    city_name: "Olympia",
  },
  {
    postal_code: "65041",
    full_city_name: "Hermann, MO",
    city_name: "Hermann",
  },
  {
    postal_code: "62062",
    full_city_name: "Maryville, IL",
    city_name: "Maryville",
  },
  {
    postal_code: "60606",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "12184",
    full_city_name: "Valatie, NY",
    city_name: "Valatie",
  },
  {
    postal_code: "55319",
    full_city_name: "Clear Lake, MN",
    city_name: "Clear Lake",
  },
  {
    postal_code: "16057",
    full_city_name: "Slippery Rock, PA",
    city_name: "Slippery Rock",
  },
  {
    postal_code: "37025",
    full_city_name: "Bon Aqua, TN",
    city_name: "Bon Aqua",
  },
  {
    postal_code: "55604",
    full_city_name: "Grand Marais, MN",
    city_name: "Grand Marais",
  },
  {
    postal_code: "36105",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "62274",
    full_city_name: "Pinckneyville, IL",
    city_name: "Pinckneyville",
  },
  {
    postal_code: "34945",
    full_city_name: "Fort Pierce, FL",
    city_name: "Fort Pierce",
  },
  {
    postal_code: "43076",
    full_city_name: "Thornville, OH",
    city_name: "Thornville",
  },
  {
    postal_code: "06106",
    full_city_name: "Hartford, CT",
    city_name: "Hartford",
  },
  {
    postal_code: "27817",
    full_city_name: "Chocowinity, NC",
    city_name: "Chocowinity",
  },
  {
    postal_code: "77518",
    full_city_name: "Bacliff, TX",
    city_name: "Bacliff",
  },
  {
    postal_code: "41074",
    full_city_name: "Dayton, KY",
    city_name: "Dayton",
  },
  {
    postal_code: "69153",
    full_city_name: "Ogallala, NE",
    city_name: "Ogallala",
  },
  {
    postal_code: "38326",
    full_city_name: "Counce, TN",
    city_name: "Counce",
  },
  {
    postal_code: "70578",
    full_city_name: "Rayne, LA",
    city_name: "Rayne",
  },
  {
    postal_code: "01238",
    full_city_name: "Lee, MA",
    city_name: "Lee",
  },
  {
    postal_code: "48509",
    full_city_name: "Burton, MI",
    city_name: "Burton",
  },
  {
    postal_code: "74868",
    full_city_name: "Seminole, OK",
    city_name: "Seminole",
  },
  {
    postal_code: "11568",
    full_city_name: "Old Westbury, NY",
    city_name: "Old Westbury",
  },
  {
    postal_code: "45157",
    full_city_name: "New Richmond, OH",
    city_name: "New Richmond",
  },
  {
    postal_code: "02892",
    full_city_name: "West Kingston, RI",
    city_name: "West Kingston",
  },
  {
    postal_code: "60480",
    full_city_name: "Willow Springs, IL",
    city_name: "Willow Springs",
  },
  {
    postal_code: "80951",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "79930",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "36206",
    full_city_name: "Anniston, AL",
    city_name: "Anniston",
  },
  {
    postal_code: "14870",
    full_city_name: "Painted Post, NY",
    city_name: "Painted Post",
  },
  {
    postal_code: "27565",
    full_city_name: "Oxford, NC",
    city_name: "Oxford",
  },
  {
    postal_code: "12442",
    full_city_name: "Hunter, NY",
    city_name: "Hunter",
  },
  {
    postal_code: "84017",
    full_city_name: "Coalville, UT",
    city_name: "Coalville",
  },
  {
    postal_code: "94024",
    full_city_name: "Los Altos, CA",
    city_name: "Los Altos",
  },
  {
    postal_code: "94507",
    full_city_name: "Alamo, CA",
    city_name: "Alamo",
  },
  {
    postal_code: "86046",
    full_city_name: "Williams, AZ",
    city_name: "Williams",
  },
  {
    postal_code: "89501",
    full_city_name: "Reno, NV",
    city_name: "Reno",
  },
  {
    postal_code: "11703",
    full_city_name: "North Babylon, NY",
    city_name: "North Babylon",
  },
  {
    postal_code: "28339",
    full_city_name: "Erwin, NC",
    city_name: "Erwin",
  },
  {
    postal_code: "12029",
    full_city_name: "Canaan, NY",
    city_name: "Canaan",
  },
  {
    postal_code: "84741",
    full_city_name: "Kanab, UT",
    city_name: "Kanab",
  },
  {
    postal_code: "50112",
    full_city_name: "Grinnell, IA",
    city_name: "Grinnell",
  },
  {
    postal_code: "53959",
    full_city_name: "Reedsburg, WI",
    city_name: "Reedsburg",
  },
  {
    postal_code: "92377",
    full_city_name: "Rialto, CA",
    city_name: "Rialto",
  },
  {
    postal_code: "95423",
    full_city_name: "Clearlake Oaks, CA",
    city_name: "Clearlake Oaks",
  },
  {
    postal_code: "45207",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "43953",
    full_city_name: "Steubenville, OH",
    city_name: "Steubenville",
  },
  {
    postal_code: "10029",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "92832",
    full_city_name: "Fullerton, CA",
    city_name: "Fullerton",
  },
  {
    postal_code: "89447",
    full_city_name: "Yerington, NV",
    city_name: "Yerington",
  },
  {
    postal_code: "06035",
    full_city_name: "Granby, CT",
    city_name: "Granby",
  },
  {
    postal_code: "79103",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "45619",
    full_city_name: "Chesapeake, OH",
    city_name: "Chesapeake",
  },
  {
    postal_code: "75654",
    full_city_name: "Henderson, TX",
    city_name: "Henderson",
  },
  {
    postal_code: "63645",
    full_city_name: "Fredericktown, MO",
    city_name: "Fredericktown",
  },
  {
    postal_code: "10030",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "44090",
    full_city_name: "Wellington, OH",
    city_name: "Wellington",
  },
  {
    postal_code: "75125",
    full_city_name: "Ferris, TX",
    city_name: "Ferris",
  },
  {
    postal_code: "37840",
    full_city_name: "Oliver Springs, TN",
    city_name: "Oliver Springs",
  },
  {
    postal_code: "02917",
    full_city_name: "Smithfield, RI",
    city_name: "Smithfield",
  },
  {
    postal_code: "12521",
    full_city_name: "Craryville, NY",
    city_name: "Craryville",
  },
  {
    postal_code: "04276",
    full_city_name: "Rumford, ME",
    city_name: "Rumford",
  },
  {
    postal_code: "63357",
    full_city_name: "Marthasville, MO",
    city_name: "Marthasville",
  },
  {
    postal_code: "41129",
    full_city_name: "Catlettsburg, KY",
    city_name: "Catlettsburg",
  },
  {
    postal_code: "85003",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "28747",
    full_city_name: "Lake Toxaway, NC",
    city_name: "Lake Toxaway",
  },
  {
    postal_code: "20912",
    full_city_name: "Takoma Park, MD",
    city_name: "Takoma Park",
  },
  {
    postal_code: "91746",
    full_city_name: "La Puente, CA",
    city_name: "La Puente",
  },
  {
    postal_code: "25438",
    full_city_name: "Ranson, WV",
    city_name: "Ranson",
  },
  {
    postal_code: "21755",
    full_city_name: "Jefferson, MD",
    city_name: "Jefferson",
  },
  {
    postal_code: "24538",
    full_city_name: "Concord, VA",
    city_name: "Concord",
  },
  {
    postal_code: "46324",
    full_city_name: "Hammond, IN",
    city_name: "Hammond",
  },
  {
    postal_code: "92866",
    full_city_name: "Orange, CA",
    city_name: "Orange",
  },
  {
    postal_code: "43935",
    full_city_name: "Martins Ferry, OH",
    city_name: "Martins Ferry",
  },
  {
    postal_code: "38068",
    full_city_name: "Somerville, TN",
    city_name: "Somerville",
  },
  {
    postal_code: "75801",
    full_city_name: "Palestine, TX",
    city_name: "Palestine",
  },
  {
    postal_code: "34484",
    full_city_name: "Oxford, FL",
    city_name: "Oxford",
  },
  {
    postal_code: "06096",
    full_city_name: "Windsor Locks, CT",
    city_name: "Windsor Locks",
  },
  {
    postal_code: "68527",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "64485",
    full_city_name: "Savannah, MO",
    city_name: "Savannah",
  },
  {
    postal_code: "21047",
    full_city_name: "Fallston, MD",
    city_name: "Fallston",
  },
  {
    postal_code: "62471",
    full_city_name: "Vandalia, IL",
    city_name: "Vandalia",
  },
  {
    postal_code: "46017",
    full_city_name: "Anderson, IN",
    city_name: "Anderson",
  },
  {
    postal_code: "01226",
    full_city_name: "Dalton, MA",
    city_name: "Dalton",
  },
  {
    postal_code: "27045",
    full_city_name: "Rural Hall, NC",
    city_name: "Rural Hall",
  },
  {
    postal_code: "48817",
    full_city_name: "Corunna, MI",
    city_name: "Corunna",
  },
  {
    postal_code: "74079",
    full_city_name: "Stroud, OK",
    city_name: "Stroud",
  },
  {
    postal_code: "45118",
    full_city_name: "Fayetteville, OH",
    city_name: "Fayetteville",
  },
  {
    postal_code: "17360",
    full_city_name: "Seven Valleys, PA",
    city_name: "Seven Valleys",
  },
  {
    postal_code: "35212",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "15129",
    full_city_name: "South Park, PA",
    city_name: "South Park",
  },
  {
    postal_code: "13339",
    full_city_name: "Fort Plain, NY",
    city_name: "Fort Plain",
  },
  {
    postal_code: "97370",
    full_city_name: "Philomath, OR",
    city_name: "Philomath",
  },
  {
    postal_code: "95006",
    full_city_name: "Boulder Creek, CA",
    city_name: "Boulder Creek",
  },
  {
    postal_code: "48879",
    full_city_name: "Saint Johns, MI",
    city_name: "Saint Johns",
  },
  {
    postal_code: "06417",
    full_city_name: "Deep River, CT",
    city_name: "Deep River",
  },
  {
    postal_code: "33440",
    full_city_name: "Clewiston, FL",
    city_name: "Clewiston",
  },
  {
    postal_code: "63775",
    full_city_name: "Perryville, MO",
    city_name: "Perryville",
  },
  {
    postal_code: "56367",
    full_city_name: "Rice, MN",
    city_name: "Rice",
  },
  {
    postal_code: "77801",
    full_city_name: "Bryan, TX",
    city_name: "Bryan",
  },
  {
    postal_code: "15342",
    full_city_name: "Houston, PA",
    city_name: "Houston",
  },
  {
    postal_code: "11771",
    full_city_name: "Oyster Bay, NY",
    city_name: "Oyster Bay",
  },
  {
    postal_code: "06051",
    full_city_name: "New Britain, CT",
    city_name: "New Britain",
  },
  {
    postal_code: "45171",
    full_city_name: "Sardinia, OH",
    city_name: "Sardinia",
  },
  {
    postal_code: "30557",
    full_city_name: "Martin, GA",
    city_name: "Martin",
  },
  {
    postal_code: "15683",
    full_city_name: "Scottdale, PA",
    city_name: "Scottdale",
  },
  {
    postal_code: "75956",
    full_city_name: "Kirbyville, TX",
    city_name: "Kirbyville",
  },
  {
    postal_code: "84738",
    full_city_name: "Ivins, UT",
    city_name: "Ivins",
  },
  {
    postal_code: "10462",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "65625",
    full_city_name: "Cassville, MO",
    city_name: "Cassville",
  },
  {
    postal_code: "77031",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "55359",
    full_city_name: "Maple Plain, MN",
    city_name: "Maple Plain",
  },
  {
    postal_code: "06447",
    full_city_name: "Marlborough, CT",
    city_name: "Marlborough",
  },
  {
    postal_code: "93644",
    full_city_name: "Oakhurst, CA",
    city_name: "Oakhurst",
  },
  {
    postal_code: "76060",
    full_city_name: "Kennedale, TX",
    city_name: "Kennedale",
  },
  {
    postal_code: "90621",
    full_city_name: "Buena Park, CA",
    city_name: "Buena Park",
  },
  {
    postal_code: "25071",
    full_city_name: "Elkview, WV",
    city_name: "Elkview",
  },
  {
    postal_code: "75236",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "49103",
    full_city_name: "Berrien Springs, MI",
    city_name: "Berrien Springs",
  },
  {
    postal_code: "28043",
    full_city_name: "Forest City, NC",
    city_name: "Forest City",
  },
  {
    postal_code: "44303",
    full_city_name: "Akron, OH",
    city_name: "Akron",
  },
  {
    postal_code: "72206",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "33868",
    full_city_name: "Polk City, FL",
    city_name: "Polk City",
  },
  {
    postal_code: "75140",
    full_city_name: "Grand Saline, TX",
    city_name: "Grand Saline",
  },
  {
    postal_code: "13865",
    full_city_name: "Windsor, NY",
    city_name: "Windsor",
  },
  {
    postal_code: "43571",
    full_city_name: "Whitehouse, OH",
    city_name: "Whitehouse",
  },
  {
    postal_code: "28138",
    full_city_name: "Rockwell, NC",
    city_name: "Rockwell",
  },
  {
    postal_code: "02127",
    full_city_name: "South Boston, MA",
    city_name: "South Boston",
  },
  {
    postal_code: "40013",
    full_city_name: "Coxs Creek, KY",
    city_name: "Coxs Creek",
  },
  {
    postal_code: "37825",
    full_city_name: "New Tazewell, TN",
    city_name: "New Tazewell",
  },
  {
    postal_code: "06525",
    full_city_name: "Woodbridge, CT",
    city_name: "Woodbridge",
  },
  {
    postal_code: "45002",
    full_city_name: "Cleves, OH",
    city_name: "Cleves",
  },
  {
    postal_code: "12545",
    full_city_name: "Millbrook, NY",
    city_name: "Millbrook",
  },
  {
    postal_code: "47356",
    full_city_name: "Middletown, IN",
    city_name: "Middletown",
  },
  {
    postal_code: "50047",
    full_city_name: "Carlisle, IA",
    city_name: "Carlisle",
  },
  {
    postal_code: "94102",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "77087",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "14892",
    full_city_name: "Waverly, NY",
    city_name: "Waverly",
  },
  {
    postal_code: "76449",
    full_city_name: "Graford, TX",
    city_name: "Graford",
  },
  {
    postal_code: "77093",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "18512",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "61341",
    full_city_name: "Marseilles, IL",
    city_name: "Marseilles",
  },
  {
    postal_code: "91106",
    full_city_name: "Pasadena, CA",
    city_name: "Pasadena",
  },
  {
    postal_code: "45680",
    full_city_name: "South Point, OH",
    city_name: "South Point",
  },
  {
    postal_code: "93924",
    full_city_name: "Carmel Valley, CA",
    city_name: "Carmel Valley",
  },
  {
    postal_code: "91754",
    full_city_name: "Monterey Park, CA",
    city_name: "Monterey Park",
  },
  {
    postal_code: "91303",
    full_city_name: "Canoga Park, CA",
    city_name: "Canoga Park",
  },
  {
    postal_code: "45121",
    full_city_name: "Georgetown, OH",
    city_name: "Georgetown",
  },
  {
    postal_code: "28754",
    full_city_name: "Mars Hill, NC",
    city_name: "Mars Hill",
  },
  {
    postal_code: "01230",
    full_city_name: "Great Barrington, MA",
    city_name: "Great Barrington",
  },
  {
    postal_code: "02771",
    full_city_name: "Seekonk, MA",
    city_name: "Seekonk",
  },
  {
    postal_code: "28107",
    full_city_name: "Midland, NC",
    city_name: "Midland",
  },
  {
    postal_code: "94708",
    full_city_name: "Berkeley, CA",
    city_name: "Berkeley",
  },
  {
    postal_code: "63038",
    full_city_name: "Wildwood, MO",
    city_name: "Wildwood",
  },
  {
    postal_code: "34251",
    full_city_name: "Myakka City, FL",
    city_name: "Myakka City",
  },
  {
    postal_code: "28761",
    full_city_name: "Nebo, NC",
    city_name: "Nebo",
  },
  {
    postal_code: "02066",
    full_city_name: "Scituate, MA",
    city_name: "Scituate",
  },
  {
    postal_code: "11212",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "06423",
    full_city_name: "East Haddam, CT",
    city_name: "East Haddam",
  },
  {
    postal_code: "43056",
    full_city_name: "Heath, OH",
    city_name: "Heath",
  },
  {
    postal_code: "11961",
    full_city_name: "Ridge, NY",
    city_name: "Ridge",
  },
  {
    postal_code: "37861",
    full_city_name: "Rutledge, TN",
    city_name: "Rutledge",
  },
  {
    postal_code: "91108",
    full_city_name: "San Marino, CA",
    city_name: "San Marino",
  },
  {
    postal_code: "77026",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "24343",
    full_city_name: "Hillsville, VA",
    city_name: "Hillsville",
  },
  {
    postal_code: "86413",
    full_city_name: "Golden Valley, AZ",
    city_name: "Golden Valley",
  },
  {
    postal_code: "61301",
    full_city_name: "La Salle, IL",
    city_name: "La Salle",
  },
  {
    postal_code: "78751",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "15223",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "38852",
    full_city_name: "Iuka, MS",
    city_name: "Iuka",
  },
  {
    postal_code: "06498",
    full_city_name: "Westbrook, CT",
    city_name: "Westbrook",
  },
  {
    postal_code: "43964",
    full_city_name: "Toronto, OH",
    city_name: "Toronto",
  },
  {
    postal_code: "53934",
    full_city_name: "Friendship, WI",
    city_name: "Friendship",
  },
  {
    postal_code: "93204",
    full_city_name: "Avenal, CA",
    city_name: "Avenal",
  },
  {
    postal_code: "15857",
    full_city_name: "Saint Marys, PA",
    city_name: "Saint Marys",
  },
  {
    postal_code: "29571",
    full_city_name: "Marion, SC",
    city_name: "Marion",
  },
  {
    postal_code: "19107",
    full_city_name: "Philadelphia, PA",
    city_name: "Philadelphia",
  },
  {
    postal_code: "75148",
    full_city_name: "Malakoff, TX",
    city_name: "Malakoff",
  },
  {
    postal_code: "21152",
    full_city_name: "Sparks Glencoe, MD",
    city_name: "Sparks Glencoe",
  },
  {
    postal_code: "37737",
    full_city_name: "Friendsville, TN",
    city_name: "Friendsville",
  },
  {
    postal_code: "44683",
    full_city_name: "Uhrichsville, OH",
    city_name: "Uhrichsville",
  },
  {
    postal_code: "03860",
    full_city_name: "North Conway, NH",
    city_name: "North Conway",
  },
  {
    postal_code: "55344",
    full_city_name: "Eden Prairie, MN",
    city_name: "Eden Prairie",
  },
  {
    postal_code: "95304",
    full_city_name: "Tracy, CA",
    city_name: "Tracy",
  },
  {
    postal_code: "23040",
    full_city_name: "Cumberland, VA",
    city_name: "Cumberland",
  },
  {
    postal_code: "48750",
    full_city_name: "Oscoda, MI",
    city_name: "Oscoda",
  },
  {
    postal_code: "47304",
    full_city_name: "Muncie, IN",
    city_name: "Muncie",
  },
  {
    postal_code: "98816",
    full_city_name: "Chelan, WA",
    city_name: "Chelan",
  },
  {
    postal_code: "80540",
    full_city_name: "Lyons, CO",
    city_name: "Lyons",
  },
  {
    postal_code: "46225",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "79107",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "93265",
    full_city_name: "Springville, CA",
    city_name: "Springville",
  },
  {
    postal_code: "44117",
    full_city_name: "Euclid, OH",
    city_name: "Euclid",
  },
  {
    postal_code: "15021",
    full_city_name: "Burgettstown, PA",
    city_name: "Burgettstown",
  },
  {
    postal_code: "91345",
    full_city_name: "Mission Hills, CA",
    city_name: "Mission Hills",
  },
  {
    postal_code: "84066",
    full_city_name: "Roosevelt, UT",
    city_name: "Roosevelt",
  },
  {
    postal_code: "61061",
    full_city_name: "Oregon, IL",
    city_name: "Oregon",
  },
  {
    postal_code: "91801",
    full_city_name: "Alhambra, CA",
    city_name: "Alhambra",
  },
  {
    postal_code: "61364",
    full_city_name: "Streator, IL",
    city_name: "Streator",
  },
  {
    postal_code: "77422",
    full_city_name: "Brazoria, TX",
    city_name: "Brazoria",
  },
  {
    postal_code: "95425",
    full_city_name: "Cloverdale, CA",
    city_name: "Cloverdale",
  },
  {
    postal_code: "86040",
    full_city_name: "Page, AZ",
    city_name: "Page",
  },
  {
    postal_code: "95642",
    full_city_name: "Jackson, CA",
    city_name: "Jackson",
  },
  {
    postal_code: "68108",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "52627",
    full_city_name: "Fort Madison, IA",
    city_name: "Fort Madison",
  },
  {
    postal_code: "60633",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "13040",
    full_city_name: "Cincinnatus, NY",
    city_name: "Cincinnatus",
  },
  {
    postal_code: "93725",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "43338",
    full_city_name: "Mount Gilead, OH",
    city_name: "Mount Gilead",
  },
  {
    postal_code: "95066",
    full_city_name: "Scotts Valley, CA",
    city_name: "Scotts Valley",
  },
  {
    postal_code: "15417",
    full_city_name: "Brownsville, PA",
    city_name: "Brownsville",
  },
  {
    postal_code: "80440",
    full_city_name: "Fairplay, CO",
    city_name: "Fairplay",
  },
  {
    postal_code: "13733",
    full_city_name: "Bainbridge, NY",
    city_name: "Bainbridge",
  },
  {
    postal_code: "79102",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "45204",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "55009",
    full_city_name: "Cannon Falls, MN",
    city_name: "Cannon Falls",
  },
  {
    postal_code: "12134",
    full_city_name: "Northville, NY",
    city_name: "Northville",
  },
  {
    postal_code: "06016",
    full_city_name: "Broad Brook, CT",
    city_name: "Broad Brook",
  },
  {
    postal_code: "97391",
    full_city_name: "Toledo, OR",
    city_name: "Toledo",
  },
  {
    postal_code: "22835",
    full_city_name: "Luray, VA",
    city_name: "Luray",
  },
  {
    postal_code: "02760",
    full_city_name: "North Attleboro, MA",
    city_name: "North Attleboro",
  },
  {
    postal_code: "87740",
    full_city_name: "Raton, NM",
    city_name: "Raton",
  },
  {
    postal_code: "93612",
    full_city_name: "Clovis, CA",
    city_name: "Clovis",
  },
  {
    postal_code: "55731",
    full_city_name: "Ely, MN",
    city_name: "Ely",
  },
  {
    postal_code: "46034",
    full_city_name: "Cicero, IN",
    city_name: "Cicero",
  },
  {
    postal_code: "78016",
    full_city_name: "Devine, TX",
    city_name: "Devine",
  },
  {
    postal_code: "95407",
    full_city_name: "Santa Rosa, CA",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "10512",
    full_city_name: "Carmel, NY",
    city_name: "Carmel",
  },
  {
    postal_code: "12158",
    full_city_name: "Selkirk, NY",
    city_name: "Selkirk",
  },
  {
    postal_code: "15065",
    full_city_name: "Natrona Heights, PA",
    city_name: "Natrona Heights",
  },
  {
    postal_code: "77619",
    full_city_name: "Groves, TX",
    city_name: "Groves",
  },
  {
    postal_code: "85714",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "30184",
    full_city_name: "White, GA",
    city_name: "White",
  },
  {
    postal_code: "21770",
    full_city_name: "Monrovia, MD",
    city_name: "Monrovia",
  },
  {
    postal_code: "78380",
    full_city_name: "Robstown, TX",
    city_name: "Robstown",
  },
  {
    postal_code: "44110",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "01220",
    full_city_name: "Adams, MA",
    city_name: "Adams",
  },
  {
    postal_code: "14036",
    full_city_name: "Corfu, NY",
    city_name: "Corfu",
  },
  {
    postal_code: "07756",
    full_city_name: "Ocean Grove, NJ",
    city_name: "Ocean Grove",
  },
  {
    postal_code: "37187",
    full_city_name: "White Bluff, TN",
    city_name: "White Bluff",
  },
  {
    postal_code: "15131",
    full_city_name: "Mckeesport, PA",
    city_name: "Mckeesport",
  },
  {
    postal_code: "25271",
    full_city_name: "Ripley, WV",
    city_name: "Ripley",
  },
  {
    postal_code: "78257",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "56763",
    full_city_name: "Warroad, MN",
    city_name: "Warroad",
  },
  {
    postal_code: "18801",
    full_city_name: "Montrose, PA",
    city_name: "Montrose",
  },
  {
    postal_code: "32322",
    full_city_name: "Carrabelle, FL",
    city_name: "Carrabelle",
  },
  {
    postal_code: "15074",
    full_city_name: "Rochester, PA",
    city_name: "Rochester",
  },
  {
    postal_code: "02814",
    full_city_name: "Chepachet, RI",
    city_name: "Chepachet",
  },
  {
    postal_code: "50109",
    full_city_name: "Granger, IA",
    city_name: "Granger",
  },
  {
    postal_code: "76011",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "90744",
    full_city_name: "Wilmington, CA",
    city_name: "Wilmington",
  },
  {
    postal_code: "38618",
    full_city_name: "Coldwater, MS",
    city_name: "Coldwater",
  },
  {
    postal_code: "78069",
    full_city_name: "Somerset, TX",
    city_name: "Somerset",
  },
  {
    postal_code: "04062",
    full_city_name: "Windham, ME",
    city_name: "Windham",
  },
  {
    postal_code: "44081",
    full_city_name: "Perry, OH",
    city_name: "Perry",
  },
  {
    postal_code: "12553",
    full_city_name: "New Windsor, NY",
    city_name: "New Windsor",
  },
  {
    postal_code: "45693",
    full_city_name: "West Union, OH",
    city_name: "West Union",
  },
  {
    postal_code: "92841",
    full_city_name: "Garden Grove, CA",
    city_name: "Garden Grove",
  },
  {
    postal_code: "54558",
    full_city_name: "Saint Germain, WI",
    city_name: "Saint Germain",
  },
  {
    postal_code: "49201",
    full_city_name: "Jackson, MI",
    city_name: "Jackson",
  },
  {
    postal_code: "70555",
    full_city_name: "Maurice, LA",
    city_name: "Maurice",
  },
  {
    postal_code: "15145",
    full_city_name: "Turtle Creek, PA",
    city_name: "Turtle Creek",
  },
  {
    postal_code: "70526",
    full_city_name: "Crowley, LA",
    city_name: "Crowley",
  },
  {
    postal_code: "33843",
    full_city_name: "Frostproof, FL",
    city_name: "Frostproof",
  },
  {
    postal_code: "91411",
    full_city_name: "Van Nuys, CA",
    city_name: "Van Nuys",
  },
  {
    postal_code: "30337",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "28697",
    full_city_name: "Wilkesboro, NC",
    city_name: "Wilkesboro",
  },
  {
    postal_code: "56069",
    full_city_name: "Montgomery, MN",
    city_name: "Montgomery",
  },
  {
    postal_code: "32266",
    full_city_name: "Neptune Beach, FL",
    city_name: "Neptune Beach",
  },
  {
    postal_code: "76073",
    full_city_name: "Paradise, TX",
    city_name: "Paradise",
  },
  {
    postal_code: "96101",
    full_city_name: "Alturas, CA",
    city_name: "Alturas",
  },
  {
    postal_code: "50627",
    full_city_name: "Eldora, IA",
    city_name: "Eldora",
  },
  {
    postal_code: "37752",
    full_city_name: "Harrogate, TN",
    city_name: "Harrogate",
  },
  {
    postal_code: "94132",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "18414",
    full_city_name: "Dalton, PA",
    city_name: "Dalton",
  },
  {
    postal_code: "20018",
    full_city_name: "Washington, DC",
    city_name: "Washington",
  },
  {
    postal_code: "77861",
    full_city_name: "Iola, TX",
    city_name: "Iola",
  },
  {
    postal_code: "40965",
    full_city_name: "Middlesboro, KY",
    city_name: "Middlesboro",
  },
  {
    postal_code: "75840",
    full_city_name: "Fairfield, TX",
    city_name: "Fairfield",
  },
  {
    postal_code: "23919",
    full_city_name: "Bracey, VA",
    city_name: "Bracey",
  },
  {
    postal_code: "60954",
    full_city_name: "Momence, IL",
    city_name: "Momence",
  },
  {
    postal_code: "15042",
    full_city_name: "Freedom, PA",
    city_name: "Freedom",
  },
  {
    postal_code: "14070",
    full_city_name: "Gowanda, NY",
    city_name: "Gowanda",
  },
  {
    postal_code: "28640",
    full_city_name: "Jefferson, NC",
    city_name: "Jefferson",
  },
  {
    postal_code: "87701",
    full_city_name: "Las Vegas, NM",
    city_name: "Las Vegas",
  },
  {
    postal_code: "11559",
    full_city_name: "Lawrence, NY",
    city_name: "Lawrence",
  },
  {
    postal_code: "78101",
    full_city_name: "Adkins, TX",
    city_name: "Adkins",
  },
  {
    postal_code: "18353",
    full_city_name: "Saylorsburg, PA",
    city_name: "Saylorsburg",
  },
  {
    postal_code: "21716",
    full_city_name: "Brunswick, MD",
    city_name: "Brunswick",
  },
  {
    postal_code: "64504",
    full_city_name: "Saint Joseph, MO",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "20616",
    full_city_name: "Bryans Road, MD",
    city_name: "Bryans Road",
  },
  {
    postal_code: "96143",
    full_city_name: "Kings Beach, CA",
    city_name: "Kings Beach",
  },
  {
    postal_code: "01237",
    full_city_name: "Lanesborough, MA",
    city_name: "Lanesborough",
  },
  {
    postal_code: "56425",
    full_city_name: "Baxter, MN",
    city_name: "Baxter",
  },
  {
    postal_code: "32950",
    full_city_name: "Malabar, FL",
    city_name: "Malabar",
  },
  {
    postal_code: "24382",
    full_city_name: "Wytheville, VA",
    city_name: "Wytheville",
  },
  {
    postal_code: "38829",
    full_city_name: "Booneville, MS",
    city_name: "Booneville",
  },
  {
    postal_code: "90062",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "57717",
    full_city_name: "Belle Fourche, SD",
    city_name: "Belle Fourche",
  },
  {
    postal_code: "13754",
    full_city_name: "Deposit, NY",
    city_name: "Deposit",
  },
  {
    postal_code: "84648",
    full_city_name: "Nephi, UT",
    city_name: "Nephi",
  },
  {
    postal_code: "06370",
    full_city_name: "Oakdale, CT",
    city_name: "Oakdale",
  },
  {
    postal_code: "85145",
    full_city_name: "Red Rock, AZ",
    city_name: "Red Rock",
  },
  {
    postal_code: "90504",
    full_city_name: "Torrance, CA",
    city_name: "Torrance",
  },
  {
    postal_code: "72104",
    full_city_name: "Malvern, AR",
    city_name: "Malvern",
  },
  {
    postal_code: "62946",
    full_city_name: "Harrisburg, IL",
    city_name: "Harrisburg",
  },
  {
    postal_code: "24504",
    full_city_name: "Lynchburg, VA",
    city_name: "Lynchburg",
  },
  {
    postal_code: "44405",
    full_city_name: "Campbell, OH",
    city_name: "Campbell",
  },
  {
    postal_code: "60112",
    full_city_name: "Cortland, IL",
    city_name: "Cortland",
  },
  {
    postal_code: "16146",
    full_city_name: "Sharon, PA",
    city_name: "Sharon",
  },
  {
    postal_code: "23102",
    full_city_name: "Maidens, VA",
    city_name: "Maidens",
  },
  {
    postal_code: "65757",
    full_city_name: "Strafford, MO",
    city_name: "Strafford",
  },
  {
    postal_code: "02644",
    full_city_name: "Forestdale, MA",
    city_name: "Forestdale",
  },
  {
    postal_code: "35203",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "22923",
    full_city_name: "Barboursville, VA",
    city_name: "Barboursville",
  },
  {
    postal_code: "06073",
    full_city_name: "South Glastonbury, CT",
    city_name: "South Glastonbury",
  },
  {
    postal_code: "90723",
    full_city_name: "Paramount, CA",
    city_name: "Paramount",
  },
  {
    postal_code: "65653",
    full_city_name: "Forsyth, MO",
    city_name: "Forsyth",
  },
  {
    postal_code: "78256",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "27828",
    full_city_name: "Farmville, NC",
    city_name: "Farmville",
  },
  {
    postal_code: "40359",
    full_city_name: "Owenton, KY",
    city_name: "Owenton",
  },
  {
    postal_code: "22664",
    full_city_name: "Woodstock, VA",
    city_name: "Woodstock",
  },
  {
    postal_code: "02648",
    full_city_name: "Marstons Mills, MA",
    city_name: "Marstons Mills",
  },
  {
    postal_code: "29325",
    full_city_name: "Clinton, SC",
    city_name: "Clinton",
  },
  {
    postal_code: "89705",
    full_city_name: "Carson City, NV",
    city_name: "Carson City",
  },
  {
    postal_code: "02916",
    full_city_name: "Rumford, RI",
    city_name: "Rumford",
  },
  {
    postal_code: "46204",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "41035",
    full_city_name: "Dry Ridge, KY",
    city_name: "Dry Ridge",
  },
  {
    postal_code: "06268",
    full_city_name: "Storrs Mansfield, CT",
    city_name: "Storrs Mansfield",
  },
  {
    postal_code: "92806",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "48650",
    full_city_name: "Pinconning, MI",
    city_name: "Pinconning",
  },
  {
    postal_code: "24592",
    full_city_name: "South Boston, VA",
    city_name: "South Boston",
  },
  {
    postal_code: "85363",
    full_city_name: "Youngtown, AZ",
    city_name: "Youngtown",
  },
  {
    postal_code: "03254",
    full_city_name: "Moultonborough, NH",
    city_name: "Moultonborough",
  },
  {
    postal_code: "47905",
    full_city_name: "Lafayette, IN",
    city_name: "Lafayette",
  },
  {
    postal_code: "22308",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "28621",
    full_city_name: "Elkin, NC",
    city_name: "Elkin",
  },
  {
    postal_code: "44703",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "15024",
    full_city_name: "Cheswick, PA",
    city_name: "Cheswick",
  },
  {
    postal_code: "68372",
    full_city_name: "Hickman, NE",
    city_name: "Hickman",
  },
  {
    postal_code: "78639",
    full_city_name: "Kingsland, TX",
    city_name: "Kingsland",
  },
  {
    postal_code: "39567",
    full_city_name: "Pascagoula, MS",
    city_name: "Pascagoula",
  },
  {
    postal_code: "06029",
    full_city_name: "Ellington, CT",
    city_name: "Ellington",
  },
  {
    postal_code: "95357",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "93703",
    full_city_name: "Fresno, CA",
    city_name: "Fresno",
  },
  {
    postal_code: "48505",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "60458",
    full_city_name: "Justice, IL",
    city_name: "Justice",
  },
  {
    postal_code: "37020",
    full_city_name: "Bell Buckle, TN",
    city_name: "Bell Buckle",
  },
  {
    postal_code: "64106",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "55439",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "27537",
    full_city_name: "Henderson, NC",
    city_name: "Henderson",
  },
  {
    postal_code: "63077",
    full_city_name: "Saint Clair, MO",
    city_name: "Saint Clair",
  },
  {
    postal_code: "75254",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "43146",
    full_city_name: "Orient, OH",
    city_name: "Orient",
  },
  {
    postal_code: "11021",
    full_city_name: "Great Neck, NY",
    city_name: "Great Neck",
  },
  {
    postal_code: "12051",
    full_city_name: "Coxsackie, NY",
    city_name: "Coxsackie",
  },
  {
    postal_code: "28555",
    full_city_name: "Maysville, NC",
    city_name: "Maysville",
  },
  {
    postal_code: "83804",
    full_city_name: "Blanchard, ID",
    city_name: "Blanchard",
  },
  {
    postal_code: "50273",
    full_city_name: "Winterset, IA",
    city_name: "Winterset",
  },
  {
    postal_code: "62960",
    full_city_name: "Metropolis, IL",
    city_name: "Metropolis",
  },
  {
    postal_code: "10451",
    full_city_name: "Bronx, NY",
    city_name: "Bronx",
  },
  {
    postal_code: "60176",
    full_city_name: "Schiller Park, IL",
    city_name: "Schiller Park",
  },
  {
    postal_code: "79121",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "12043",
    full_city_name: "Cobleskill, NY",
    city_name: "Cobleskill",
  },
  {
    postal_code: "46106",
    full_city_name: "Bargersville, IN",
    city_name: "Bargersville",
  },
  {
    postal_code: "14903",
    full_city_name: "Elmira, NY",
    city_name: "Elmira",
  },
  {
    postal_code: "37645",
    full_city_name: "Mount Carmel, TN",
    city_name: "Mount Carmel",
  },
  {
    postal_code: "48307",
    full_city_name: "Rochester, MI",
    city_name: "Rochester",
  },
  {
    postal_code: "21202",
    full_city_name: "Baltimore, MD",
    city_name: "Baltimore",
  },
  {
    postal_code: "61254",
    full_city_name: "Geneseo, IL",
    city_name: "Geneseo",
  },
  {
    postal_code: "08035",
    full_city_name: "Haddon Heights, NJ",
    city_name: "Haddon Heights",
  },
  {
    postal_code: "44811",
    full_city_name: "Bellevue, OH",
    city_name: "Bellevue",
  },
  {
    postal_code: "24084",
    full_city_name: "Dublin, VA",
    city_name: "Dublin",
  },
  {
    postal_code: "49038",
    full_city_name: "Coloma, MI",
    city_name: "Coloma",
  },
  {
    postal_code: "07801",
    full_city_name: "Dover, NJ",
    city_name: "Dover",
  },
  {
    postal_code: "67432",
    full_city_name: "Clay Center, KS",
    city_name: "Clay Center",
  },
  {
    postal_code: "38242",
    full_city_name: "Paris, TN",
    city_name: "Paris",
  },
  {
    postal_code: "02151",
    full_city_name: "Revere, MA",
    city_name: "Revere",
  },
  {
    postal_code: "30021",
    full_city_name: "Clarkston, GA",
    city_name: "Clarkston",
  },
  {
    postal_code: "76273",
    full_city_name: "Whitesboro, TX",
    city_name: "Whitesboro",
  },
  {
    postal_code: "81413",
    full_city_name: "Cedaredge, CO",
    city_name: "Cedaredge",
  },
  {
    postal_code: "68434",
    full_city_name: "Seward, NE",
    city_name: "Seward",
  },
  {
    postal_code: "90041",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "70062",
    full_city_name: "Kenner, LA",
    city_name: "Kenner",
  },
  {
    postal_code: "96067",
    full_city_name: "Mount Shasta, CA",
    city_name: "Mount Shasta",
  },
  {
    postal_code: "03894",
    full_city_name: "Wolfeboro, NH",
    city_name: "Wolfeboro",
  },
  {
    postal_code: "66725",
    full_city_name: "Columbus, KS",
    city_name: "Columbus",
  },
  {
    postal_code: "11971",
    full_city_name: "Southold, NY",
    city_name: "Southold",
  },
  {
    postal_code: "28659",
    full_city_name: "North Wilkesboro, NC",
    city_name: "North Wilkesboro",
  },
  {
    postal_code: "79556",
    full_city_name: "Sweetwater, TX",
    city_name: "Sweetwater",
  },
  {
    postal_code: "62881",
    full_city_name: "Salem, IL",
    city_name: "Salem",
  },
  {
    postal_code: "32640",
    full_city_name: "Hawthorne, FL",
    city_name: "Hawthorne",
  },
  {
    postal_code: "06604",
    full_city_name: "Bridgeport, CT",
    city_name: "Bridgeport",
  },
  {
    postal_code: "90810",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "92878",
    full_city_name: "Corona, CA",
    city_name: "Corona",
  },
  {
    postal_code: "15084",
    full_city_name: "Tarentum, PA",
    city_name: "Tarentum",
  },
  {
    postal_code: "29322",
    full_city_name: "Campobello, SC",
    city_name: "Campobello",
  },
  {
    postal_code: "55414",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "28501",
    full_city_name: "Kinston, NC",
    city_name: "Kinston",
  },
  {
    postal_code: "95453",
    full_city_name: "Lakeport, CA",
    city_name: "Lakeport",
  },
  {
    postal_code: "99201",
    full_city_name: "Spokane, WA",
    city_name: "Spokane",
  },
  {
    postal_code: "53558",
    full_city_name: "Mcfarland, WI",
    city_name: "Mcfarland",
  },
  {
    postal_code: "78644",
    full_city_name: "Lockhart, TX",
    city_name: "Lockhart",
  },
  {
    postal_code: "91763",
    full_city_name: "Montclair, CA",
    city_name: "Montclair",
  },
  {
    postal_code: "70121",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "17963",
    full_city_name: "Pine Grove, PA",
    city_name: "Pine Grove",
  },
  {
    postal_code: "11206",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "70047",
    full_city_name: "Destrehan, LA",
    city_name: "Destrehan",
  },
  {
    postal_code: "44904",
    full_city_name: "Mansfield, OH",
    city_name: "Mansfield",
  },
  {
    postal_code: "48651",
    full_city_name: "Prudenville, MI",
    city_name: "Prudenville",
  },
  {
    postal_code: "35068",
    full_city_name: "Fultondale, AL",
    city_name: "Fultondale",
  },
  {
    postal_code: "83263",
    full_city_name: "Preston, ID",
    city_name: "Preston",
  },
  {
    postal_code: "75949",
    full_city_name: "Huntington, TX",
    city_name: "Huntington",
  },
  {
    postal_code: "28021",
    full_city_name: "Cherryville, NC",
    city_name: "Cherryville",
  },
  {
    postal_code: "94301",
    full_city_name: "Palo Alto, CA",
    city_name: "Palo Alto",
  },
  {
    postal_code: "35114",
    full_city_name: "Alabaster, AL",
    city_name: "Alabaster",
  },
  {
    postal_code: "10307",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "38024",
    full_city_name: "Dyersburg, TN",
    city_name: "Dyersburg",
  },
  {
    postal_code: "39565",
    full_city_name: "Vancleave, MS",
    city_name: "Vancleave",
  },
  {
    postal_code: "05751",
    full_city_name: "Killington, VT",
    city_name: "Killington",
  },
  {
    postal_code: "94019",
    full_city_name: "Half Moon Bay, CA",
    city_name: "Half Moon Bay",
  },
  {
    postal_code: "98580",
    full_city_name: "Roy, WA",
    city_name: "Roy",
  },
  {
    postal_code: "70525",
    full_city_name: "Church Point, LA",
    city_name: "Church Point",
  },
  {
    postal_code: "35147",
    full_city_name: "Sterrett, AL",
    city_name: "Sterrett",
  },
  {
    postal_code: "06712",
    full_city_name: "Prospect, CT",
    city_name: "Prospect",
  },
  {
    postal_code: "60160",
    full_city_name: "Melrose Park, IL",
    city_name: "Melrose Park",
  },
  {
    postal_code: "03042",
    full_city_name: "Epping, NH",
    city_name: "Epping",
  },
  {
    postal_code: "32820",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "22124",
    full_city_name: "Oakton, VA",
    city_name: "Oakton",
  },
  {
    postal_code: "06605",
    full_city_name: "Bridgeport, CT",
    city_name: "Bridgeport",
  },
  {
    postal_code: "31807",
    full_city_name: "Ellerslie, GA",
    city_name: "Ellerslie",
  },
  {
    postal_code: "80236",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "78052",
    full_city_name: "Lytle, TX",
    city_name: "Lytle",
  },
  {
    postal_code: "60469",
    full_city_name: "Posen, IL",
    city_name: "Posen",
  },
  {
    postal_code: "22553",
    full_city_name: "Spotsylvania, VA",
    city_name: "Spotsylvania",
  },
  {
    postal_code: "90603",
    full_city_name: "Whittier, CA",
    city_name: "Whittier",
  },
  {
    postal_code: "78332",
    full_city_name: "Alice, TX",
    city_name: "Alice",
  },
  {
    postal_code: "30566",
    full_city_name: "Oakwood, GA",
    city_name: "Oakwood",
  },
  {
    postal_code: "15062",
    full_city_name: "Monessen, PA",
    city_name: "Monessen",
  },
  {
    postal_code: "12484",
    full_city_name: "Stone Ridge, NY",
    city_name: "Stone Ridge",
  },
  {
    postal_code: "12074",
    full_city_name: "Galway, NY",
    city_name: "Galway",
  },
  {
    postal_code: "18458",
    full_city_name: "Shohola, PA",
    city_name: "Shohola",
  },
  {
    postal_code: "68064",
    full_city_name: "Valley, NE",
    city_name: "Valley",
  },
  {
    postal_code: "15367",
    full_city_name: "Venetia, PA",
    city_name: "Venetia",
  },
  {
    postal_code: "35476",
    full_city_name: "Northport, AL",
    city_name: "Northport",
  },
  {
    postal_code: "95358",
    full_city_name: "Modesto, CA",
    city_name: "Modesto",
  },
  {
    postal_code: "19560",
    full_city_name: "Temple, PA",
    city_name: "Temple",
  },
  {
    postal_code: "34972",
    full_city_name: "Okeechobee, FL",
    city_name: "Okeechobee",
  },
  {
    postal_code: "34479",
    full_city_name: "Ocala, FL",
    city_name: "Ocala",
  },
  {
    postal_code: "50511",
    full_city_name: "Algona, IA",
    city_name: "Algona",
  },
  {
    postal_code: "18508",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "76645",
    full_city_name: "Hillsboro, TX",
    city_name: "Hillsboro",
  },
  {
    postal_code: "97341",
    full_city_name: "Depoe Bay, OR",
    city_name: "Depoe Bay",
  },
  {
    postal_code: "94134",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "75948",
    full_city_name: "Hemphill, TX",
    city_name: "Hemphill",
  },
  {
    postal_code: "63124",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "63362",
    full_city_name: "Moscow Mills, MO",
    city_name: "Moscow Mills",
  },
  {
    postal_code: "37403",
    full_city_name: "Chattanooga, TN",
    city_name: "Chattanooga",
  },
  {
    postal_code: "91776",
    full_city_name: "San Gabriel, CA",
    city_name: "San Gabriel",
  },
  {
    postal_code: "33135",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "38570",
    full_city_name: "Livingston, TN",
    city_name: "Livingston",
  },
  {
    postal_code: "12883",
    full_city_name: "Ticonderoga, NY",
    city_name: "Ticonderoga",
  },
  {
    postal_code: "34448",
    full_city_name: "Homosassa, FL",
    city_name: "Homosassa",
  },
  {
    postal_code: "25510",
    full_city_name: "Culloden, WV",
    city_name: "Culloden",
  },
  {
    postal_code: "75114",
    full_city_name: "Crandall, TX",
    city_name: "Crandall",
  },
  {
    postal_code: "26537",
    full_city_name: "Kingwood, WV",
    city_name: "Kingwood",
  },
  {
    postal_code: "22150",
    full_city_name: "Springfield, VA",
    city_name: "Springfield",
  },
  {
    postal_code: "45229",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "39574",
    full_city_name: "Saucier, MS",
    city_name: "Saucier",
  },
  {
    postal_code: "61727",
    full_city_name: "Clinton, IL",
    city_name: "Clinton",
  },
  {
    postal_code: "56374",
    full_city_name: "Saint Joseph, MN",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "54944",
    full_city_name: "Hortonville, WI",
    city_name: "Hortonville",
  },
  {
    postal_code: "40108",
    full_city_name: "Brandenburg, KY",
    city_name: "Brandenburg",
  },
  {
    postal_code: "55306",
    full_city_name: "Burnsville, MN",
    city_name: "Burnsville",
  },
  {
    postal_code: "44615",
    full_city_name: "Carrollton, OH",
    city_name: "Carrollton",
  },
  {
    postal_code: "94574",
    full_city_name: "Saint Helena, CA",
    city_name: "Saint Helena",
  },
  {
    postal_code: "74523",
    full_city_name: "Antlers, OK",
    city_name: "Antlers",
  },
  {
    postal_code: "33815",
    full_city_name: "Lakeland, FL",
    city_name: "Lakeland",
  },
  {
    postal_code: "65453",
    full_city_name: "Cuba, MO",
    city_name: "Cuba",
  },
  {
    postal_code: "56031",
    full_city_name: "Fairmont, MN",
    city_name: "Fairmont",
  },
  {
    postal_code: "29812",
    full_city_name: "Barnwell, SC",
    city_name: "Barnwell",
  },
  {
    postal_code: "11790",
    full_city_name: "Stony Brook, NY",
    city_name: "Stony Brook",
  },
  {
    postal_code: "06052",
    full_city_name: "New Britain, CT",
    city_name: "New Britain",
  },
  {
    postal_code: "28657",
    full_city_name: "Newland, NC",
    city_name: "Newland",
  },
  {
    postal_code: "05819",
    full_city_name: "Saint Johnsbury, VT",
    city_name: "Saint Johnsbury",
  },
  {
    postal_code: "15232",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "34436",
    full_city_name: "Floral City, FL",
    city_name: "Floral City",
  },
  {
    postal_code: "68462",
    full_city_name: "Waverly, NE",
    city_name: "Waverly",
  },
  {
    postal_code: "35127",
    full_city_name: "Pleasant Grove, AL",
    city_name: "Pleasant Grove",
  },
  {
    postal_code: "65560",
    full_city_name: "Salem, MO",
    city_name: "Salem",
  },
  {
    postal_code: "13904",
    full_city_name: "Binghamton, NY",
    city_name: "Binghamton",
  },
  {
    postal_code: "70583",
    full_city_name: "Scott, LA",
    city_name: "Scott",
  },
  {
    postal_code: "16412",
    full_city_name: "Edinboro, PA",
    city_name: "Edinboro",
  },
  {
    postal_code: "77474",
    full_city_name: "Sealy, TX",
    city_name: "Sealy",
  },
  {
    postal_code: "75803",
    full_city_name: "Palestine, TX",
    city_name: "Palestine",
  },
  {
    postal_code: "45638",
    full_city_name: "Ironton, OH",
    city_name: "Ironton",
  },
  {
    postal_code: "18103",
    full_city_name: "Allentown, PA",
    city_name: "Allentown",
  },
  {
    postal_code: "79336",
    full_city_name: "Levelland, TX",
    city_name: "Levelland",
  },
  {
    postal_code: "15219",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "15063",
    full_city_name: "Monongahela, PA",
    city_name: "Monongahela",
  },
  {
    postal_code: "92356",
    full_city_name: "Lucerne Valley, CA",
    city_name: "Lucerne Valley",
  },
  {
    postal_code: "60135",
    full_city_name: "Genoa, IL",
    city_name: "Genoa",
  },
  {
    postal_code: "52544",
    full_city_name: "Centerville, IA",
    city_name: "Centerville",
  },
  {
    postal_code: "37618",
    full_city_name: "Bluff City, TN",
    city_name: "Bluff City",
  },
  {
    postal_code: "60623",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "06515",
    full_city_name: "New Haven, CT",
    city_name: "New Haven",
  },
  {
    postal_code: "75253",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "20905",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "43203",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "62056",
    full_city_name: "Litchfield, IL",
    city_name: "Litchfield",
  },
  {
    postal_code: "54720",
    full_city_name: "Altoona, WI",
    city_name: "Altoona",
  },
  {
    postal_code: "06401",
    full_city_name: "Ansonia, CT",
    city_name: "Ansonia",
  },
  {
    postal_code: "52556",
    full_city_name: "Fairfield, IA",
    city_name: "Fairfield",
  },
  {
    postal_code: "67335",
    full_city_name: "Cherryvale, KS",
    city_name: "Cherryvale",
  },
  {
    postal_code: "03909",
    full_city_name: "York, ME",
    city_name: "York",
  },
  {
    postal_code: "38574",
    full_city_name: "Monterey, TN",
    city_name: "Monterey",
  },
  {
    postal_code: "40351",
    full_city_name: "Morehead, KY",
    city_name: "Morehead",
  },
  {
    postal_code: "78586",
    full_city_name: "San Benito, TX",
    city_name: "San Benito",
  },
  {
    postal_code: "28023",
    full_city_name: "China Grove, NC",
    city_name: "China Grove",
  },
  {
    postal_code: "92614",
    full_city_name: "Irvine, CA",
    city_name: "Irvine",
  },
  {
    postal_code: "12025",
    full_city_name: "Broadalbin, NY",
    city_name: "Broadalbin",
  },
  {
    postal_code: "63088",
    full_city_name: "Valley Park, MO",
    city_name: "Valley Park",
  },
  {
    postal_code: "98055",
    full_city_name: "Renton, WA",
    city_name: "Renton",
  },
  {
    postal_code: "75783",
    full_city_name: "Quitman, TX",
    city_name: "Quitman",
  },
  {
    postal_code: "61068",
    full_city_name: "Rochelle, IL",
    city_name: "Rochelle",
  },
  {
    postal_code: "90254",
    full_city_name: "Hermosa Beach, CA",
    city_name: "Hermosa Beach",
  },
  {
    postal_code: "56431",
    full_city_name: "Aitkin, MN",
    city_name: "Aitkin",
  },
  {
    postal_code: "06013",
    full_city_name: "Burlington, CT",
    city_name: "Burlington",
  },
  {
    postal_code: "54025",
    full_city_name: "Somerset, WI",
    city_name: "Somerset",
  },
  {
    postal_code: "44112",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "61270",
    full_city_name: "Morrison, IL",
    city_name: "Morrison",
  },
  {
    postal_code: "76258",
    full_city_name: "Pilot Point, TX",
    city_name: "Pilot Point",
  },
  {
    postal_code: "99027",
    full_city_name: "Otis Orchards, WA",
    city_name: "Otis Orchards",
  },
  {
    postal_code: "22973",
    full_city_name: "Stanardsville, VA",
    city_name: "Stanardsville",
  },
  {
    postal_code: "02038",
    full_city_name: "Franklin, MA",
    city_name: "Franklin",
  },
  {
    postal_code: "61525",
    full_city_name: "Dunlap, IL",
    city_name: "Dunlap",
  },
  {
    postal_code: "93458",
    full_city_name: "Santa Maria, CA",
    city_name: "Santa Maria",
  },
  {
    postal_code: "08609",
    full_city_name: "Trenton, NJ",
    city_name: "Trenton",
  },
  {
    postal_code: "37642",
    full_city_name: "Church Hill, TN",
    city_name: "Church Hill",
  },
  {
    postal_code: "75223",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "45506",
    full_city_name: "Springfield, OH",
    city_name: "Springfield",
  },
  {
    postal_code: "60043",
    full_city_name: "Kenilworth, IL",
    city_name: "Kenilworth",
  },
  {
    postal_code: "19904",
    full_city_name: "Dover, DE",
    city_name: "Dover",
  },
  {
    postal_code: "54548",
    full_city_name: "Minocqua, WI",
    city_name: "Minocqua",
  },
  {
    postal_code: "85623",
    full_city_name: "Oracle, AZ",
    city_name: "Oracle",
  },
  {
    postal_code: "12210",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "34289",
    full_city_name: "North Port, FL",
    city_name: "North Port",
  },
  {
    postal_code: "74426",
    full_city_name: "Checotah, OK",
    city_name: "Checotah",
  },
  {
    postal_code: "32060",
    full_city_name: "Live Oak, FL",
    city_name: "Live Oak",
  },
  {
    postal_code: "43545",
    full_city_name: "Napoleon, OH",
    city_name: "Napoleon",
  },
  {
    postal_code: "70427",
    full_city_name: "Bogalusa, LA",
    city_name: "Bogalusa",
  },
  {
    postal_code: "95640",
    full_city_name: "Ione, CA",
    city_name: "Ione",
  },
  {
    postal_code: "33973",
    full_city_name: "Lehigh Acres, FL",
    city_name: "Lehigh Acres",
  },
  {
    postal_code: "05059",
    full_city_name: "Quechee, VT",
    city_name: "Quechee",
  },
  {
    postal_code: "35121",
    full_city_name: "Oneonta, AL",
    city_name: "Oneonta",
  },
  {
    postal_code: "11713",
    full_city_name: "Bellport, NY",
    city_name: "Bellport",
  },
  {
    postal_code: "17851",
    full_city_name: "Mount Carmel, PA",
    city_name: "Mount Carmel",
  },
  {
    postal_code: "39232",
    full_city_name: "Flowood, MS",
    city_name: "Flowood",
  },
  {
    postal_code: "65459",
    full_city_name: "Dixon, MO",
    city_name: "Dixon",
  },
  {
    postal_code: "33567",
    full_city_name: "Plant City, FL",
    city_name: "Plant City",
  },
  {
    postal_code: "44273",
    full_city_name: "Seville, OH",
    city_name: "Seville",
  },
  {
    postal_code: "06110",
    full_city_name: "West Hartford, CT",
    city_name: "West Hartford",
  },
  {
    postal_code: "49935",
    full_city_name: "Iron River, MI",
    city_name: "Iron River",
  },
  {
    postal_code: "15204",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "92703",
    full_city_name: "Santa Ana, CA",
    city_name: "Santa Ana",
  },
  {
    postal_code: "45068",
    full_city_name: "Waynesville, OH",
    city_name: "Waynesville",
  },
  {
    postal_code: "92231",
    full_city_name: "Calexico, CA",
    city_name: "Calexico",
  },
  {
    postal_code: "60155",
    full_city_name: "Broadview, IL",
    city_name: "Broadview",
  },
  {
    postal_code: "20736",
    full_city_name: "Owings, MD",
    city_name: "Owings",
  },
  {
    postal_code: "28768",
    full_city_name: "Pisgah Forest, NC",
    city_name: "Pisgah Forest",
  },
  {
    postal_code: "62246",
    full_city_name: "Greenville, IL",
    city_name: "Greenville",
  },
  {
    postal_code: "63143",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "40336",
    full_city_name: "Irvine, KY",
    city_name: "Irvine",
  },
  {
    postal_code: "62052",
    full_city_name: "Jerseyville, IL",
    city_name: "Jerseyville",
  },
  {
    postal_code: "44260",
    full_city_name: "Mogadore, OH",
    city_name: "Mogadore",
  },
  {
    postal_code: "03038",
    full_city_name: "Derry, NH",
    city_name: "Derry",
  },
  {
    postal_code: "11417",
    full_city_name: "Ozone Park, NY",
    city_name: "Ozone Park",
  },
  {
    postal_code: "43528",
    full_city_name: "Holland, OH",
    city_name: "Holland",
  },
  {
    postal_code: "80723",
    full_city_name: "Brush, CO",
    city_name: "Brush",
  },
  {
    postal_code: "50595",
    full_city_name: "Webster City, IA",
    city_name: "Webster City",
  },
  {
    postal_code: "11232",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "22032",
    full_city_name: "Fairfax, VA",
    city_name: "Fairfax",
  },
  {
    postal_code: "61550",
    full_city_name: "Morton, IL",
    city_name: "Morton",
  },
  {
    postal_code: "63389",
    full_city_name: "Winfield, MO",
    city_name: "Winfield",
  },
  {
    postal_code: "55108",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "52802",
    full_city_name: "Davenport, IA",
    city_name: "Davenport",
  },
  {
    postal_code: "90302",
    full_city_name: "Inglewood, CA",
    city_name: "Inglewood",
  },
  {
    postal_code: "48131",
    full_city_name: "Dundee, MI",
    city_name: "Dundee",
  },
  {
    postal_code: "40484",
    full_city_name: "Stanford, KY",
    city_name: "Stanford",
  },
  {
    postal_code: "79549",
    full_city_name: "Snyder, TX",
    city_name: "Snyder",
  },
  {
    postal_code: "66025",
    full_city_name: "Eudora, KS",
    city_name: "Eudora",
  },
  {
    postal_code: "06359",
    full_city_name: "North Stonington, CT",
    city_name: "North Stonington",
  },
  {
    postal_code: "99021",
    full_city_name: "Mead, WA",
    city_name: "Mead",
  },
  {
    postal_code: "13207",
    full_city_name: "Syracuse, NY",
    city_name: "Syracuse",
  },
  {
    postal_code: "98252",
    full_city_name: "Granite Falls, WA",
    city_name: "Granite Falls",
  },
  {
    postal_code: "78362",
    full_city_name: "Ingleside, TX",
    city_name: "Ingleside",
  },
  {
    postal_code: "57747",
    full_city_name: "Hot Springs, SD",
    city_name: "Hot Springs",
  },
  {
    postal_code: "16407",
    full_city_name: "Corry, PA",
    city_name: "Corry",
  },
  {
    postal_code: "12983",
    full_city_name: "Saranac Lake, NY",
    city_name: "Saranac Lake",
  },
  {
    postal_code: "70535",
    full_city_name: "Eunice, LA",
    city_name: "Eunice",
  },
  {
    postal_code: "55435",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "94941",
    full_city_name: "Mill Valley, CA",
    city_name: "Mill Valley",
  },
  {
    postal_code: "33513",
    full_city_name: "Bushnell, FL",
    city_name: "Bushnell",
  },
  {
    postal_code: "53948",
    full_city_name: "Mauston, WI",
    city_name: "Mauston",
  },
  {
    postal_code: "60621",
    full_city_name: "Chicago, IL",
    city_name: "Chicago",
  },
  {
    postal_code: "23875",
    full_city_name: "Prince George, VA",
    city_name: "Prince George",
  },
  {
    postal_code: "37641",
    full_city_name: "Chuckey, TN",
    city_name: "Chuckey",
  },
  {
    postal_code: "11977",
    full_city_name: "Westhampton, NY",
    city_name: "Westhampton",
  },
  {
    postal_code: "39429",
    full_city_name: "Columbia, MS",
    city_name: "Columbia",
  },
  {
    postal_code: "93428",
    full_city_name: "Cambria, CA",
    city_name: "Cambria",
  },
  {
    postal_code: "18403",
    full_city_name: "Archbald, PA",
    city_name: "Archbald",
  },
  {
    postal_code: "40023",
    full_city_name: "Fisherville, KY",
    city_name: "Fisherville",
  },
  {
    postal_code: "71701",
    full_city_name: "Camden, AR",
    city_name: "Camden",
  },
  {
    postal_code: "41101",
    full_city_name: "Ashland, KY",
    city_name: "Ashland",
  },
  {
    postal_code: "53821",
    full_city_name: "Prairie Du Chien, WI",
    city_name: "Prairie Du Chien",
  },
  {
    postal_code: "11954",
    full_city_name: "Montauk, NY",
    city_name: "Montauk",
  },
  {
    postal_code: "12188",
    full_city_name: "Waterford, NY",
    city_name: "Waterford",
  },
  {
    postal_code: "76633",
    full_city_name: "China Spring, TX",
    city_name: "China Spring",
  },
  {
    postal_code: "18336",
    full_city_name: "Matamoras, PA",
    city_name: "Matamoras",
  },
  {
    postal_code: "30175",
    full_city_name: "Talking Rock, GA",
    city_name: "Talking Rock",
  },
  {
    postal_code: "55336",
    full_city_name: "Glencoe, MN",
    city_name: "Glencoe",
  },
  {
    postal_code: "77627",
    full_city_name: "Nederland, TX",
    city_name: "Nederland",
  },
  {
    postal_code: "54615",
    full_city_name: "Black River Falls, WI",
    city_name: "Black River Falls",
  },
  {
    postal_code: "32735",
    full_city_name: "Grand Island, FL",
    city_name: "Grand Island",
  },
  {
    postal_code: "95249",
    full_city_name: "San Andreas, CA",
    city_name: "San Andreas",
  },
  {
    postal_code: "46157",
    full_city_name: "Monrovia, IN",
    city_name: "Monrovia",
  },
  {
    postal_code: "80305",
    full_city_name: "Boulder, CO",
    city_name: "Boulder",
  },
  {
    postal_code: "24202",
    full_city_name: "Bristol, VA",
    city_name: "Bristol",
  },
  {
    postal_code: "06355",
    full_city_name: "Mystic, CT",
    city_name: "Mystic",
  },
  {
    postal_code: "81226",
    full_city_name: "Florence, CO",
    city_name: "Florence",
  },
  {
    postal_code: "94517",
    full_city_name: "Clayton, CA",
    city_name: "Clayton",
  },
  {
    postal_code: "13838",
    full_city_name: "Sidney, NY",
    city_name: "Sidney",
  },
  {
    postal_code: "48192",
    full_city_name: "Wyandotte, MI",
    city_name: "Wyandotte",
  },
  {
    postal_code: "15222",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "12550",
    full_city_name: "Newburgh, NY",
    city_name: "Newburgh",
  },
  {
    postal_code: "55616",
    full_city_name: "Two Harbors, MN",
    city_name: "Two Harbors",
  },
  {
    postal_code: "59044",
    full_city_name: "Laurel, MT",
    city_name: "Laurel",
  },
  {
    postal_code: "35217",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "91011",
    full_city_name: "La Canada Flintridge, CA",
    city_name: "La Canada Flintridge",
  },
  {
    postal_code: "79562",
    full_city_name: "Tuscola, TX",
    city_name: "Tuscola",
  },
  {
    postal_code: "53581",
    full_city_name: "Richland Center, WI",
    city_name: "Richland Center",
  },
  {
    postal_code: "33956",
    full_city_name: "Saint James City, FL",
    city_name: "Saint James City",
  },
  {
    postal_code: "26104",
    full_city_name: "Parkersburg, WV",
    city_name: "Parkersburg",
  },
  {
    postal_code: "85602",
    full_city_name: "Benson, AZ",
    city_name: "Benson",
  },
  {
    postal_code: "15425",
    full_city_name: "Connellsville, PA",
    city_name: "Connellsville",
  },
  {
    postal_code: "33838",
    full_city_name: "Dundee, FL",
    city_name: "Dundee",
  },
  {
    postal_code: "95490",
    full_city_name: "Willits, CA",
    city_name: "Willits",
  },
  {
    postal_code: "60064",
    full_city_name: "North Chicago, IL",
    city_name: "North Chicago",
  },
  {
    postal_code: "85087",
    full_city_name: "New River, AZ",
    city_name: "New River",
  },
  {
    postal_code: "91506",
    full_city_name: "Burbank, CA",
    city_name: "Burbank",
  },
  {
    postal_code: "20180",
    full_city_name: "Lovettsville, VA",
    city_name: "Lovettsville",
  },
  {
    postal_code: "91748",
    full_city_name: "Rowland Heights, CA",
    city_name: "Rowland Heights",
  },
  {
    postal_code: "30054",
    full_city_name: "Oxford, GA",
    city_name: "Oxford",
  },
  {
    postal_code: "76450",
    full_city_name: "Graham, TX",
    city_name: "Graham",
  },
  {
    postal_code: "13827",
    full_city_name: "Owego, NY",
    city_name: "Owego",
  },
  {
    postal_code: "44026",
    full_city_name: "Chesterland, OH",
    city_name: "Chesterland",
  },
  {
    postal_code: "23181",
    full_city_name: "West Point, VA",
    city_name: "West Point",
  },
  {
    postal_code: "30662",
    full_city_name: "Royston, GA",
    city_name: "Royston",
  },
  {
    postal_code: "14108",
    full_city_name: "Newfane, NY",
    city_name: "Newfane",
  },
  {
    postal_code: "37385",
    full_city_name: "Tellico Plains, TN",
    city_name: "Tellico Plains",
  },
  {
    postal_code: "16063",
    full_city_name: "Zelienople, PA",
    city_name: "Zelienople",
  },
  {
    postal_code: "56329",
    full_city_name: "Foley, MN",
    city_name: "Foley",
  },
  {
    postal_code: "06382",
    full_city_name: "Uncasville, CT",
    city_name: "Uncasville",
  },
  {
    postal_code: "97351",
    full_city_name: "Independence, OR",
    city_name: "Independence",
  },
  {
    postal_code: "77706",
    full_city_name: "Beaumont, TX",
    city_name: "Beaumont",
  },
  {
    postal_code: "37037",
    full_city_name: "Christiana, TN",
    city_name: "Christiana",
  },
  {
    postal_code: "42262",
    full_city_name: "Oak Grove, KY",
    city_name: "Oak Grove",
  },
  {
    postal_code: "16403",
    full_city_name: "Cambridge Springs, PA",
    city_name: "Cambridge Springs",
  },
  {
    postal_code: "13856",
    full_city_name: "Walton, NY",
    city_name: "Walton",
  },
  {
    postal_code: "34688",
    full_city_name: "Tarpon Springs, FL",
    city_name: "Tarpon Springs",
  },
  {
    postal_code: "53950",
    full_city_name: "New Lisbon, WI",
    city_name: "New Lisbon",
  },
  {
    postal_code: "02885",
    full_city_name: "Warren, RI",
    city_name: "Warren",
  },
  {
    postal_code: "78404",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "76022",
    full_city_name: "Bedford, TX",
    city_name: "Bedford",
  },
  {
    postal_code: "33922",
    full_city_name: "Bokeelia, FL",
    city_name: "Bokeelia",
  },
  {
    postal_code: "16511",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "75137",
    full_city_name: "Duncanville, TX",
    city_name: "Duncanville",
  },
  {
    postal_code: "62285",
    full_city_name: "Smithton, IL",
    city_name: "Smithton",
  },
  {
    postal_code: "45669",
    full_city_name: "Proctorville, OH",
    city_name: "Proctorville",
  },
  {
    postal_code: "94530",
    full_city_name: "El Cerrito, CA",
    city_name: "El Cerrito",
  },
  {
    postal_code: "67901",
    full_city_name: "Liberal, KS",
    city_name: "Liberal",
  },
  {
    postal_code: "76310",
    full_city_name: "Wichita Falls, TX",
    city_name: "Wichita Falls",
  },
  {
    postal_code: "64501",
    full_city_name: "Saint Joseph, MO",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "02780",
    full_city_name: "Taunton, MA",
    city_name: "Taunton",
  },
  {
    postal_code: "39202",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "94503",
    full_city_name: "American Canyon, CA",
    city_name: "American Canyon",
  },
  {
    postal_code: "02769",
    full_city_name: "Rehoboth, MA",
    city_name: "Rehoboth",
  },
  {
    postal_code: "39475",
    full_city_name: "Purvis, MS",
    city_name: "Purvis",
  },
  {
    postal_code: "83127",
    full_city_name: "Thayne, WY",
    city_name: "Thayne",
  },
  {
    postal_code: "46321",
    full_city_name: "Munster, IN",
    city_name: "Munster",
  },
  {
    postal_code: "95054",
    full_city_name: "Santa Clara, CA",
    city_name: "Santa Clara",
  },
  {
    postal_code: "25401",
    full_city_name: "Martinsburg, WV",
    city_name: "Martinsburg",
  },
  {
    postal_code: "95437",
    full_city_name: "Fort Bragg, CA",
    city_name: "Fort Bragg",
  },
  {
    postal_code: "80816",
    full_city_name: "Florissant, CO",
    city_name: "Florissant",
  },
  {
    postal_code: "33158",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "95666",
    full_city_name: "Pioneer, CA",
    city_name: "Pioneer",
  },
  {
    postal_code: "75203",
    full_city_name: "Dallas, TX",
    city_name: "Dallas",
  },
  {
    postal_code: "44230",
    full_city_name: "Doylestown, OH",
    city_name: "Doylestown",
  },
  {
    postal_code: "30527",
    full_city_name: "Clermont, GA",
    city_name: "Clermont",
  },
  {
    postal_code: "56501",
    full_city_name: "Detroit Lakes, MN",
    city_name: "Detroit Lakes",
  },
  {
    postal_code: "02537",
    full_city_name: "East Sandwich, MA",
    city_name: "East Sandwich",
  },
  {
    postal_code: "38320",
    full_city_name: "Camden, TN",
    city_name: "Camden",
  },
  {
    postal_code: "96094",
    full_city_name: "Weed, CA",
    city_name: "Weed",
  },
  {
    postal_code: "94122",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "76539",
    full_city_name: "Kempner, TX",
    city_name: "Kempner",
  },
  {
    postal_code: "39183",
    full_city_name: "Vicksburg, MS",
    city_name: "Vicksburg",
  },
  {
    postal_code: "32148",
    full_city_name: "Interlachen, FL",
    city_name: "Interlachen",
  },
  {
    postal_code: "98070",
    full_city_name: "Vashon, WA",
    city_name: "Vashon",
  },
  {
    postal_code: "45409",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "14213",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "29356",
    full_city_name: "Landrum, SC",
    city_name: "Landrum",
  },
  {
    postal_code: "21114",
    full_city_name: "Crofton, MD",
    city_name: "Crofton",
  },
  {
    postal_code: "94559",
    full_city_name: "Napa, CA",
    city_name: "Napa",
  },
  {
    postal_code: "89449",
    full_city_name: "Stateline, NV",
    city_name: "Stateline",
  },
  {
    postal_code: "95448",
    full_city_name: "Healdsburg, CA",
    city_name: "Healdsburg",
  },
  {
    postal_code: "06098",
    full_city_name: "Winsted, CT",
    city_name: "Winsted",
  },
  {
    postal_code: "37701",
    full_city_name: "Alcoa, TN",
    city_name: "Alcoa",
  },
  {
    postal_code: "80615",
    full_city_name: "Eaton, CO",
    city_name: "Eaton",
  },
  {
    postal_code: "92843",
    full_city_name: "Garden Grove, CA",
    city_name: "Garden Grove",
  },
  {
    postal_code: "12446",
    full_city_name: "Kerhonkson, NY",
    city_name: "Kerhonkson",
  },
  {
    postal_code: "55051",
    full_city_name: "Mora, MN",
    city_name: "Mora",
  },
  {
    postal_code: "28746",
    full_city_name: "Lake Lure, NC",
    city_name: "Lake Lure",
  },
  {
    postal_code: "55760",
    full_city_name: "Mcgregor, MN",
    city_name: "Mcgregor",
  },
  {
    postal_code: "15037",
    full_city_name: "Elizabeth, PA",
    city_name: "Elizabeth",
  },
  {
    postal_code: "02675",
    full_city_name: "Yarmouth Port, MA",
    city_name: "Yarmouth Port",
  },
  {
    postal_code: "46323",
    full_city_name: "Hammond, IN",
    city_name: "Hammond",
  },
  {
    postal_code: "29438",
    full_city_name: "Edisto Island, SC",
    city_name: "Edisto Island",
  },
  {
    postal_code: "72227",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "77086",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "45249",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "70546",
    full_city_name: "Jennings, LA",
    city_name: "Jennings",
  },
  {
    postal_code: "74010",
    full_city_name: "Bristow, OK",
    city_name: "Bristow",
  },
  {
    postal_code: "44718",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "54020",
    full_city_name: "Osceola, WI",
    city_name: "Osceola",
  },
  {
    postal_code: "77360",
    full_city_name: "Onalaska, TX",
    city_name: "Onalaska",
  },
  {
    postal_code: "41016",
    full_city_name: "Covington, KY",
    city_name: "Covington",
  },
  {
    postal_code: "90031",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "43074",
    full_city_name: "Sunbury, OH",
    city_name: "Sunbury",
  },
  {
    postal_code: "15126",
    full_city_name: "Imperial, PA",
    city_name: "Imperial",
  },
  {
    postal_code: "92391",
    full_city_name: "Twin Peaks, CA",
    city_name: "Twin Peaks",
  },
  {
    postal_code: "13732",
    full_city_name: "Apalachin, NY",
    city_name: "Apalachin",
  },
  {
    postal_code: "89433",
    full_city_name: "Sun Valley, NV",
    city_name: "Sun Valley",
  },
  {
    postal_code: "40208",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "44044",
    full_city_name: "Grafton, OH",
    city_name: "Grafton",
  },
  {
    postal_code: "18407",
    full_city_name: "Carbondale, PA",
    city_name: "Carbondale",
  },
  {
    postal_code: "60162",
    full_city_name: "Hillside, IL",
    city_name: "Hillside",
  },
  {
    postal_code: "78238",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "06477",
    full_city_name: "Orange, CT",
    city_name: "Orange",
  },
  {
    postal_code: "77563",
    full_city_name: "Hitchcock, TX",
    city_name: "Hitchcock",
  },
  {
    postal_code: "74023",
    full_city_name: "Cushing, OK",
    city_name: "Cushing",
  },
  {
    postal_code: "92802",
    full_city_name: "Anaheim, CA",
    city_name: "Anaheim",
  },
  {
    postal_code: "53572",
    full_city_name: "Mount Horeb, WI",
    city_name: "Mount Horeb",
  },
  {
    postal_code: "87532",
    full_city_name: "Espanola, NM",
    city_name: "Espanola",
  },
  {
    postal_code: "63348",
    full_city_name: "Foristell, MO",
    city_name: "Foristell",
  },
  {
    postal_code: "67152",
    full_city_name: "Wellington, KS",
    city_name: "Wellington",
  },
  {
    postal_code: "63051",
    full_city_name: "House Springs, MO",
    city_name: "House Springs",
  },
  {
    postal_code: "74106",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "16428",
    full_city_name: "North East, PA",
    city_name: "North East",
  },
  {
    postal_code: "15370",
    full_city_name: "Waynesburg, PA",
    city_name: "Waynesburg",
  },
  {
    postal_code: "83333",
    full_city_name: "Hailey, ID",
    city_name: "Hailey",
  },
  {
    postal_code: "25443",
    full_city_name: "Shepherdstown, WV",
    city_name: "Shepherdstown",
  },
  {
    postal_code: "18848",
    full_city_name: "Towanda, PA",
    city_name: "Towanda",
  },
  {
    postal_code: "76106",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "90018",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "44408",
    full_city_name: "Columbiana, OH",
    city_name: "Columbiana",
  },
  {
    postal_code: "11795",
    full_city_name: "West Islip, NY",
    city_name: "West Islip",
  },
  {
    postal_code: "56751",
    full_city_name: "Roseau, MN",
    city_name: "Roseau",
  },
  {
    postal_code: "03743",
    full_city_name: "Claremont, NH",
    city_name: "Claremont",
  },
  {
    postal_code: "06471",
    full_city_name: "North Branford, CT",
    city_name: "North Branford",
  },
  {
    postal_code: "93654",
    full_city_name: "Reedley, CA",
    city_name: "Reedley",
  },
  {
    postal_code: "77504",
    full_city_name: "Pasadena, TX",
    city_name: "Pasadena",
  },
  {
    postal_code: "95117",
    full_city_name: "San Jose, CA",
    city_name: "San Jose",
  },
  {
    postal_code: "76205",
    full_city_name: "Denton, TX",
    city_name: "Denton",
  },
  {
    postal_code: "27505",
    full_city_name: "Broadway, NC",
    city_name: "Broadway",
  },
  {
    postal_code: "38340",
    full_city_name: "Henderson, TN",
    city_name: "Henderson",
  },
  {
    postal_code: "70006",
    full_city_name: "Metairie, LA",
    city_name: "Metairie",
  },
  {
    postal_code: "45216",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "44657",
    full_city_name: "Minerva, OH",
    city_name: "Minerva",
  },
  {
    postal_code: "61866",
    full_city_name: "Rantoul, IL",
    city_name: "Rantoul",
  },
  {
    postal_code: "91792",
    full_city_name: "West Covina, CA",
    city_name: "West Covina",
  },
  {
    postal_code: "67124",
    full_city_name: "Pratt, KS",
    city_name: "Pratt",
  },
  {
    postal_code: "06791",
    full_city_name: "Harwinton, CT",
    city_name: "Harwinton",
  },
  {
    postal_code: "12953",
    full_city_name: "Malone, NY",
    city_name: "Malone",
  },
  {
    postal_code: "94040",
    full_city_name: "Mountain View, CA",
    city_name: "Mountain View",
  },
  {
    postal_code: "46231",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "77503",
    full_city_name: "Pasadena, TX",
    city_name: "Pasadena",
  },
  {
    postal_code: "46121",
    full_city_name: "Coatesville, IN",
    city_name: "Coatesville",
  },
  {
    postal_code: "55107",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "33765",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "93591",
    full_city_name: "Palmdale, CA",
    city_name: "Palmdale",
  },
  {
    postal_code: "56258",
    full_city_name: "Marshall, MN",
    city_name: "Marshall",
  },
  {
    postal_code: "93463",
    full_city_name: "Solvang, CA",
    city_name: "Solvang",
  },
  {
    postal_code: "90804",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "12972",
    full_city_name: "Peru, NY",
    city_name: "Peru",
  },
  {
    postal_code: "94578",
    full_city_name: "San Leandro, CA",
    city_name: "San Leandro",
  },
  {
    postal_code: "76015",
    full_city_name: "Arlington, TX",
    city_name: "Arlington",
  },
  {
    postal_code: "46278",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "27892",
    full_city_name: "Williamston, NC",
    city_name: "Williamston",
  },
  {
    postal_code: "95693",
    full_city_name: "Wilton, CA",
    city_name: "Wilton",
  },
  {
    postal_code: "72176",
    full_city_name: "Ward, AR",
    city_name: "Ward",
  },
  {
    postal_code: "29118",
    full_city_name: "Orangeburg, SC",
    city_name: "Orangeburg",
  },
  {
    postal_code: "94564",
    full_city_name: "Pinole, CA",
    city_name: "Pinole",
  },
  {
    postal_code: "84701",
    full_city_name: "Richfield, UT",
    city_name: "Richfield",
  },
  {
    postal_code: "13326",
    full_city_name: "Cooperstown, NY",
    city_name: "Cooperstown",
  },
  {
    postal_code: "78003",
    full_city_name: "Bandera, TX",
    city_name: "Bandera",
  },
  {
    postal_code: "81526",
    full_city_name: "Palisade, CO",
    city_name: "Palisade",
  },
  {
    postal_code: "47805",
    full_city_name: "Terre Haute, IN",
    city_name: "Terre Haute",
  },
  {
    postal_code: "75494",
    full_city_name: "Winnsboro, TX",
    city_name: "Winnsboro",
  },
  {
    postal_code: "48059",
    full_city_name: "Fort Gratiot, MI",
    city_name: "Fort Gratiot",
  },
  {
    postal_code: "53551",
    full_city_name: "Lake Mills, WI",
    city_name: "Lake Mills",
  },
  {
    postal_code: "55920",
    full_city_name: "Byron, MN",
    city_name: "Byron",
  },
  {
    postal_code: "70002",
    full_city_name: "Metairie, LA",
    city_name: "Metairie",
  },
  {
    postal_code: "15005",
    full_city_name: "Baden, PA",
    city_name: "Baden",
  },
  {
    postal_code: "34737",
    full_city_name: "Howey In The Hills, FL",
    city_name: "Howey In The Hills",
  },
  {
    postal_code: "02903",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "78621",
    full_city_name: "Elgin, TX",
    city_name: "Elgin",
  },
  {
    postal_code: "70445",
    full_city_name: "Lacombe, LA",
    city_name: "Lacombe",
  },
  {
    postal_code: "93117",
    full_city_name: "Goleta, CA",
    city_name: "Goleta",
  },
  {
    postal_code: "19468",
    full_city_name: "Royersford, PA",
    city_name: "Royersford",
  },
  {
    postal_code: "60950",
    full_city_name: "Manteno, IL",
    city_name: "Manteno",
  },
  {
    postal_code: "77445",
    full_city_name: "Hempstead, TX",
    city_name: "Hempstead",
  },
  {
    postal_code: "37617",
    full_city_name: "Blountville, TN",
    city_name: "Blountville",
  },
  {
    postal_code: "30108",
    full_city_name: "Bowdon, GA",
    city_name: "Bowdon",
  },
  {
    postal_code: "30329",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "35045",
    full_city_name: "Clanton, AL",
    city_name: "Clanton",
  },
  {
    postal_code: "52641",
    full_city_name: "Mount Pleasant, IA",
    city_name: "Mount Pleasant",
  },
  {
    postal_code: "29657",
    full_city_name: "Liberty, SC",
    city_name: "Liberty",
  },
  {
    postal_code: "74857",
    full_city_name: "Newalla, OK",
    city_name: "Newalla",
  },
  {
    postal_code: "50201",
    full_city_name: "Nevada, IA",
    city_name: "Nevada",
  },
  {
    postal_code: "46001",
    full_city_name: "Alexandria, IN",
    city_name: "Alexandria",
  },
  {
    postal_code: "93662",
    full_city_name: "Selma, CA",
    city_name: "Selma",
  },
  {
    postal_code: "45176",
    full_city_name: "Williamsburg, OH",
    city_name: "Williamsburg",
  },
  {
    postal_code: "77506",
    full_city_name: "Pasadena, TX",
    city_name: "Pasadena",
  },
  {
    postal_code: "25704",
    full_city_name: "Huntington, WV",
    city_name: "Huntington",
  },
  {
    postal_code: "91103",
    full_city_name: "Pasadena, CA",
    city_name: "Pasadena",
  },
  {
    postal_code: "37742",
    full_city_name: "Greenback, TN",
    city_name: "Greenback",
  },
  {
    postal_code: "02898",
    full_city_name: "Wyoming, RI",
    city_name: "Wyoming",
  },
  {
    postal_code: "65781",
    full_city_name: "Willard, MO",
    city_name: "Willard",
  },
  {
    postal_code: "06320",
    full_city_name: "New London, CT",
    city_name: "New London",
  },
  {
    postal_code: "12206",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "45885",
    full_city_name: "Saint Marys, OH",
    city_name: "Saint Marys",
  },
  {
    postal_code: "28731",
    full_city_name: "Flat Rock, NC",
    city_name: "Flat Rock",
  },
  {
    postal_code: "02907",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "60416",
    full_city_name: "Coal City, IL",
    city_name: "Coal City",
  },
  {
    postal_code: "75182",
    full_city_name: "Sunnyvale, TX",
    city_name: "Sunnyvale",
  },
  {
    postal_code: "54155",
    full_city_name: "Oneida, WI",
    city_name: "Oneida",
  },
  {
    postal_code: "93950",
    full_city_name: "Pacific Grove, CA",
    city_name: "Pacific Grove",
  },
  {
    postal_code: "25309",
    full_city_name: "South Charleston, WV",
    city_name: "South Charleston",
  },
  {
    postal_code: "44714",
    full_city_name: "Canton, OH",
    city_name: "Canton",
  },
  {
    postal_code: "16502",
    full_city_name: "Erie, PA",
    city_name: "Erie",
  },
  {
    postal_code: "64503",
    full_city_name: "Saint Joseph, MO",
    city_name: "Saint Joseph",
  },
  {
    postal_code: "40118",
    full_city_name: "Fairdale, KY",
    city_name: "Fairdale",
  },
  {
    postal_code: "37321",
    full_city_name: "Dayton, TN",
    city_name: "Dayton",
  },
  {
    postal_code: "32091",
    full_city_name: "Starke, FL",
    city_name: "Starke",
  },
  {
    postal_code: "24588",
    full_city_name: "Rustburg, VA",
    city_name: "Rustburg",
  },
  {
    postal_code: "62812",
    full_city_name: "Benton, IL",
    city_name: "Benton",
  },
  {
    postal_code: "76442",
    full_city_name: "Comanche, TX",
    city_name: "Comanche",
  },
  {
    postal_code: "93433",
    full_city_name: "Grover Beach, CA",
    city_name: "Grover Beach",
  },
  {
    postal_code: "15613",
    full_city_name: "Apollo, PA",
    city_name: "Apollo",
  },
  {
    postal_code: "91202",
    full_city_name: "Glendale, CA",
    city_name: "Glendale",
  },
  {
    postal_code: "15690",
    full_city_name: "Vandergrift, PA",
    city_name: "Vandergrift",
  },
  {
    postal_code: "17745",
    full_city_name: "Lock Haven, PA",
    city_name: "Lock Haven",
  },
  {
    postal_code: "61523",
    full_city_name: "Chillicothe, IL",
    city_name: "Chillicothe",
  },
  {
    postal_code: "90813",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "77331",
    full_city_name: "Coldspring, TX",
    city_name: "Coldspring",
  },
  {
    postal_code: "90077",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "45402",
    full_city_name: "Dayton, OH",
    city_name: "Dayton",
  },
  {
    postal_code: "38351",
    full_city_name: "Lexington, TN",
    city_name: "Lexington",
  },
  {
    postal_code: "83316",
    full_city_name: "Buhl, ID",
    city_name: "Buhl",
  },
  {
    postal_code: "85501",
    full_city_name: "Globe, AZ",
    city_name: "Globe",
  },
  {
    postal_code: "16127",
    full_city_name: "Grove City, PA",
    city_name: "Grove City",
  },
  {
    postal_code: "25303",
    full_city_name: "South Charleston, WV",
    city_name: "South Charleston",
  },
  {
    postal_code: "64110",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "20646",
    full_city_name: "La Plata, MD",
    city_name: "La Plata",
  },
  {
    postal_code: "29706",
    full_city_name: "Chester, SC",
    city_name: "Chester",
  },
  {
    postal_code: "27549",
    full_city_name: "Louisburg, NC",
    city_name: "Louisburg",
  },
  {
    postal_code: "65340",
    full_city_name: "Marshall, MO",
    city_name: "Marshall",
  },
  {
    postal_code: "17751",
    full_city_name: "Mill Hall, PA",
    city_name: "Mill Hall",
  },
  {
    postal_code: "91791",
    full_city_name: "West Covina, CA",
    city_name: "West Covina",
  },
  {
    postal_code: "56082",
    full_city_name: "Saint Peter, MN",
    city_name: "Saint Peter",
  },
  {
    postal_code: "77437",
    full_city_name: "El Campo, TX",
    city_name: "El Campo",
  },
  {
    postal_code: "15658",
    full_city_name: "Ligonier, PA",
    city_name: "Ligonier",
  },
  {
    postal_code: "90242",
    full_city_name: "Downey, CA",
    city_name: "Downey",
  },
  {
    postal_code: "15133",
    full_city_name: "Mckeesport, PA",
    city_name: "Mckeesport",
  },
  {
    postal_code: "80903",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "92225",
    full_city_name: "Blythe, CA",
    city_name: "Blythe",
  },
  {
    postal_code: "45895",
    full_city_name: "Wapakoneta, OH",
    city_name: "Wapakoneta",
  },
  {
    postal_code: "62024",
    full_city_name: "East Alton, IL",
    city_name: "East Alton",
  },
  {
    postal_code: "93907",
    full_city_name: "Salinas, CA",
    city_name: "Salinas",
  },
  {
    postal_code: "28371",
    full_city_name: "Parkton, NC",
    city_name: "Parkton",
  },
  {
    postal_code: "76103",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "46107",
    full_city_name: "Beech Grove, IN",
    city_name: "Beech Grove",
  },
  {
    postal_code: "55115",
    full_city_name: "Saint Paul, MN",
    city_name: "Saint Paul",
  },
  {
    postal_code: "37218",
    full_city_name: "Nashville, TN",
    city_name: "Nashville",
  },
  {
    postal_code: "92831",
    full_city_name: "Fullerton, CA",
    city_name: "Fullerton",
  },
  {
    postal_code: "78728",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "75765",
    full_city_name: "Hawkins, TX",
    city_name: "Hawkins",
  },
  {
    postal_code: "12033",
    full_city_name: "Castleton On Hudson, NY",
    city_name: "Castleton On Hudson",
  },
  {
    postal_code: "28472",
    full_city_name: "Whiteville, NC",
    city_name: "Whiteville",
  },
  {
    postal_code: "12209",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "63117",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "11030",
    full_city_name: "Manhasset, NY",
    city_name: "Manhasset",
  },
  {
    postal_code: "85387",
    full_city_name: "Surprise, AZ",
    city_name: "Surprise",
  },
  {
    postal_code: "28714",
    full_city_name: "Burnsville, NC",
    city_name: "Burnsville",
  },
  {
    postal_code: "94087",
    full_city_name: "Sunnyvale, CA",
    city_name: "Sunnyvale",
  },
  {
    postal_code: "13421",
    full_city_name: "Oneida, NY",
    city_name: "Oneida",
  },
  {
    postal_code: "90405",
    full_city_name: "Santa Monica, CA",
    city_name: "Santa Monica",
  },
  {
    postal_code: "43021",
    full_city_name: "Galena, OH",
    city_name: "Galena",
  },
  {
    postal_code: "38344",
    full_city_name: "Huntingdon, TN",
    city_name: "Huntingdon",
  },
  {
    postal_code: "29544",
    full_city_name: "Galivants Ferry, SC",
    city_name: "Galivants Ferry",
  },
  {
    postal_code: "18426",
    full_city_name: "Greentown, PA",
    city_name: "Greentown",
  },
  {
    postal_code: "06114",
    full_city_name: "Hartford, CT",
    city_name: "Hartford",
  },
  {
    postal_code: "22046",
    full_city_name: "Falls Church, VA",
    city_name: "Falls Church",
  },
  {
    postal_code: "15066",
    full_city_name: "New Brighton, PA",
    city_name: "New Brighton",
  },
  {
    postal_code: "95713",
    full_city_name: "Colfax, CA",
    city_name: "Colfax",
  },
  {
    postal_code: "61264",
    full_city_name: "Milan, IL",
    city_name: "Milan",
  },
  {
    postal_code: "76426",
    full_city_name: "Bridgeport, TX",
    city_name: "Bridgeport",
  },
  {
    postal_code: "26041",
    full_city_name: "Moundsville, WV",
    city_name: "Moundsville",
  },
  {
    postal_code: "25304",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "41073",
    full_city_name: "Bellevue, KY",
    city_name: "Bellevue",
  },
  {
    postal_code: "64085",
    full_city_name: "Richmond, MO",
    city_name: "Richmond",
  },
  {
    postal_code: "79412",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "77067",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "91101",
    full_city_name: "Pasadena, CA",
    city_name: "Pasadena",
  },
  {
    postal_code: "19044",
    full_city_name: "Horsham, PA",
    city_name: "Horsham",
  },
  {
    postal_code: "33548",
    full_city_name: "Lutz, FL",
    city_name: "Lutz",
  },
  {
    postal_code: "45154",
    full_city_name: "Mount Orab, OH",
    city_name: "Mount Orab",
  },
  {
    postal_code: "85119",
    full_city_name: "Apache Junction, AZ",
    city_name: "Apache Junction",
  },
  {
    postal_code: "72210",
    full_city_name: "Little Rock, AR",
    city_name: "Little Rock",
  },
  {
    postal_code: "48611",
    full_city_name: "Auburn, MI",
    city_name: "Auburn",
  },
  {
    postal_code: "91377",
    full_city_name: "Oak Park, CA",
    city_name: "Oak Park",
  },
  {
    postal_code: "06907",
    full_city_name: "Stamford, CT",
    city_name: "Stamford",
  },
  {
    postal_code: "72631",
    full_city_name: "Eureka Springs, AR",
    city_name: "Eureka Springs",
  },
  {
    postal_code: "75644",
    full_city_name: "Gilmer, TX",
    city_name: "Gilmer",
  },
  {
    postal_code: "45309",
    full_city_name: "Brookville, OH",
    city_name: "Brookville",
  },
  {
    postal_code: "77038",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "15224",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "39576",
    full_city_name: "Waveland, MS",
    city_name: "Waveland",
  },
  {
    postal_code: "95818",
    full_city_name: "Sacramento, CA",
    city_name: "Sacramento",
  },
  {
    postal_code: "03106",
    full_city_name: "Hooksett, NH",
    city_name: "Hooksett",
  },
  {
    postal_code: "44820",
    full_city_name: "Bucyrus, OH",
    city_name: "Bucyrus",
  },
  {
    postal_code: "08518",
    full_city_name: "Florence, NJ",
    city_name: "Florence",
  },
  {
    postal_code: "94124",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "23842",
    full_city_name: "Disputanta, VA",
    city_name: "Disputanta",
  },
  {
    postal_code: "17801",
    full_city_name: "Sunbury, PA",
    city_name: "Sunbury",
  },
  {
    postal_code: "79414",
    full_city_name: "Lubbock, TX",
    city_name: "Lubbock",
  },
  {
    postal_code: "16002",
    full_city_name: "Butler, PA",
    city_name: "Butler",
  },
  {
    postal_code: "90814",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "49829",
    full_city_name: "Escanaba, MI",
    city_name: "Escanaba",
  },
  {
    postal_code: "21158",
    full_city_name: "Westminster, MD",
    city_name: "Westminster",
  },
  {
    postal_code: "16201",
    full_city_name: "Kittanning, PA",
    city_name: "Kittanning",
  },
  {
    postal_code: "85006",
    full_city_name: "Phoenix, AZ",
    city_name: "Phoenix",
  },
  {
    postal_code: "34428",
    full_city_name: "Crystal River, FL",
    city_name: "Crystal River",
  },
  {
    postal_code: "72007",
    full_city_name: "Austin, AR",
    city_name: "Austin",
  },
  {
    postal_code: "39482",
    full_city_name: "Sumrall, MS",
    city_name: "Sumrall",
  },
  {
    postal_code: "77575",
    full_city_name: "Liberty, TX",
    city_name: "Liberty",
  },
  {
    postal_code: "37885",
    full_city_name: "Vonore, TN",
    city_name: "Vonore",
  },
  {
    postal_code: "50324",
    full_city_name: "Windsor Heights, IA",
    city_name: "Windsor Heights",
  },
  {
    postal_code: "40741",
    full_city_name: "London, KY",
    city_name: "London",
  },
  {
    postal_code: "76111",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "23294",
    full_city_name: "Henrico, VA",
    city_name: "Henrico",
  },
  {
    postal_code: "92316",
    full_city_name: "Bloomington, CA",
    city_name: "Bloomington",
  },
  {
    postal_code: "64112",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "78214",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "07764",
    full_city_name: "West Long Branch, NJ",
    city_name: "West Long Branch",
  },
  {
    postal_code: "90293",
    full_city_name: "Playa Del Rey, CA",
    city_name: "Playa Del Rey",
  },
  {
    postal_code: "97394",
    full_city_name: "Waldport, OR",
    city_name: "Waldport",
  },
  {
    postal_code: "48506",
    full_city_name: "Flint, MI",
    city_name: "Flint",
  },
  {
    postal_code: "28480",
    full_city_name: "Wrightsville Beach, NC",
    city_name: "Wrightsville Beach",
  },
  {
    postal_code: "93514",
    full_city_name: "Bishop, CA",
    city_name: "Bishop",
  },
  {
    postal_code: "68127",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "45106",
    full_city_name: "Bethel, OH",
    city_name: "Bethel",
  },
  {
    postal_code: "93402",
    full_city_name: "Los Osos, CA",
    city_name: "Los Osos",
  },
  {
    postal_code: "11714",
    full_city_name: "Bethpage, NY",
    city_name: "Bethpage",
  },
  {
    postal_code: "33031",
    full_city_name: "Homestead, FL",
    city_name: "Homestead",
  },
  {
    postal_code: "38358",
    full_city_name: "Milan, TN",
    city_name: "Milan",
  },
  {
    postal_code: "06037",
    full_city_name: "Berlin, CT",
    city_name: "Berlin",
  },
  {
    postal_code: "78736",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "70037",
    full_city_name: "Belle Chasse, LA",
    city_name: "Belle Chasse",
  },
  {
    postal_code: "32328",
    full_city_name: "Eastpoint, FL",
    city_name: "Eastpoint",
  },
  {
    postal_code: "97333",
    full_city_name: "Corvallis, OR",
    city_name: "Corvallis",
  },
  {
    postal_code: "84315",
    full_city_name: "Hooper, UT",
    city_name: "Hooper",
  },
  {
    postal_code: "28449",
    full_city_name: "Kure Beach, NC",
    city_name: "Kure Beach",
  },
  {
    postal_code: "24210",
    full_city_name: "Abingdon, VA",
    city_name: "Abingdon",
  },
  {
    postal_code: "07704",
    full_city_name: "Fair Haven, NJ",
    city_name: "Fair Haven",
  },
  {
    postal_code: "43567",
    full_city_name: "Wauseon, OH",
    city_name: "Wauseon",
  },
  {
    postal_code: "32317",
    full_city_name: "Tallahassee, FL",
    city_name: "Tallahassee",
  },
  {
    postal_code: "02830",
    full_city_name: "Harrisville, RI",
    city_name: "Harrisville",
  },
  {
    postal_code: "03031",
    full_city_name: "Amherst, NH",
    city_name: "Amherst",
  },
  {
    postal_code: "75474",
    full_city_name: "Quinlan, TX",
    city_name: "Quinlan",
  },
  {
    postal_code: "78739",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "55327",
    full_city_name: "Dayton, MN",
    city_name: "Dayton",
  },
  {
    postal_code: "54143",
    full_city_name: "Marinette, WI",
    city_name: "Marinette",
  },
  {
    postal_code: "32333",
    full_city_name: "Havana, FL",
    city_name: "Havana",
  },
  {
    postal_code: "50801",
    full_city_name: "Creston, IA",
    city_name: "Creston",
  },
  {
    postal_code: "70510",
    full_city_name: "Abbeville, LA",
    city_name: "Abbeville",
  },
  {
    postal_code: "75951",
    full_city_name: "Jasper, TX",
    city_name: "Jasper",
  },
  {
    postal_code: "60022",
    full_city_name: "Glencoe, IL",
    city_name: "Glencoe",
  },
  {
    postal_code: "16415",
    full_city_name: "Fairview, PA",
    city_name: "Fairview",
  },
  {
    postal_code: "60484",
    full_city_name: "University Park, IL",
    city_name: "University Park",
  },
  {
    postal_code: "22307",
    full_city_name: "Alexandria, VA",
    city_name: "Alexandria",
  },
  {
    postal_code: "87059",
    full_city_name: "Tijeras, NM",
    city_name: "Tijeras",
  },
  {
    postal_code: "43617",
    full_city_name: "Toledo, OH",
    city_name: "Toledo",
  },
  {
    postal_code: "34753",
    full_city_name: "Mascotte, FL",
    city_name: "Mascotte",
  },
  {
    postal_code: "87004",
    full_city_name: "Bernalillo, NM",
    city_name: "Bernalillo",
  },
  {
    postal_code: "03570",
    full_city_name: "Berlin, NH",
    city_name: "Berlin",
  },
  {
    postal_code: "90403",
    full_city_name: "Santa Monica, CA",
    city_name: "Santa Monica",
  },
  {
    postal_code: "78336",
    full_city_name: "Aransas Pass, TX",
    city_name: "Aransas Pass",
  },
  {
    postal_code: "61548",
    full_city_name: "Metamora, IL",
    city_name: "Metamora",
  },
  {
    postal_code: "15085",
    full_city_name: "Trafford, PA",
    city_name: "Trafford",
  },
  {
    postal_code: "96020",
    full_city_name: "Chester, CA",
    city_name: "Chester",
  },
  {
    postal_code: "79714",
    full_city_name: "Andrews, TX",
    city_name: "Andrews",
  },
  {
    postal_code: "48809",
    full_city_name: "Belding, MI",
    city_name: "Belding",
  },
  {
    postal_code: "18058",
    full_city_name: "Kunkletown, PA",
    city_name: "Kunkletown",
  },
  {
    postal_code: "93618",
    full_city_name: "Dinuba, CA",
    city_name: "Dinuba",
  },
  {
    postal_code: "60481",
    full_city_name: "Wilmington, IL",
    city_name: "Wilmington",
  },
  {
    postal_code: "36301",
    full_city_name: "Dothan, AL",
    city_name: "Dothan",
  },
  {
    postal_code: "95472",
    full_city_name: "Sebastopol, CA",
    city_name: "Sebastopol",
  },
  {
    postal_code: "91766",
    full_city_name: "Pomona, CA",
    city_name: "Pomona",
  },
  {
    postal_code: "20910",
    full_city_name: "Silver Spring, MD",
    city_name: "Silver Spring",
  },
  {
    postal_code: "40242",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "99005",
    full_city_name: "Colbert, WA",
    city_name: "Colbert",
  },
  {
    postal_code: "08502",
    full_city_name: "Belle Mead, NJ",
    city_name: "Belle Mead",
  },
  {
    postal_code: "38058",
    full_city_name: "Munford, TN",
    city_name: "Munford",
  },
  {
    postal_code: "80907",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "53566",
    full_city_name: "Monroe, WI",
    city_name: "Monroe",
  },
  {
    postal_code: "56093",
    full_city_name: "Waseca, MN",
    city_name: "Waseca",
  },
  {
    postal_code: "15137",
    full_city_name: "North Versailles, PA",
    city_name: "North Versailles",
  },
  {
    postal_code: "90064",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "62206",
    full_city_name: "East Saint Louis, IL",
    city_name: "East Saint Louis",
  },
  {
    postal_code: "62966",
    full_city_name: "Murphysboro, IL",
    city_name: "Murphysboro",
  },
  {
    postal_code: "28457",
    full_city_name: "Rocky Point, NC",
    city_name: "Rocky Point",
  },
  {
    postal_code: "25313",
    full_city_name: "Charleston, WV",
    city_name: "Charleston",
  },
  {
    postal_code: "18504",
    full_city_name: "Scranton, PA",
    city_name: "Scranton",
  },
  {
    postal_code: "95401",
    full_city_name: "Santa Rosa, CA",
    city_name: "Santa Rosa",
  },
  {
    postal_code: "06238",
    full_city_name: "Coventry, CT",
    city_name: "Coventry",
  },
  {
    postal_code: "10310",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "11434",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "21012",
    full_city_name: "Arnold, MD",
    city_name: "Arnold",
  },
  {
    postal_code: "43227",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "90008",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "25064",
    full_city_name: "Dunbar, WV",
    city_name: "Dunbar",
  },
  {
    postal_code: "78229",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "79706",
    full_city_name: "Midland, TX",
    city_name: "Midland",
  },
  {
    postal_code: "78266",
    full_city_name: "San Antonio, TX",
    city_name: "San Antonio",
  },
  {
    postal_code: "78064",
    full_city_name: "Pleasanton, TX",
    city_name: "Pleasanton",
  },
  {
    postal_code: "76085",
    full_city_name: "Weatherford, TX",
    city_name: "Weatherford",
  },
  {
    postal_code: "33070",
    full_city_name: "Tavernier, FL",
    city_name: "Tavernier",
  },
  {
    postal_code: "60433",
    full_city_name: "Joliet, IL",
    city_name: "Joliet",
  },
  {
    postal_code: "05672",
    full_city_name: "Stowe, VT",
    city_name: "Stowe",
  },
  {
    postal_code: "43506",
    full_city_name: "Bryan, OH",
    city_name: "Bryan",
  },
  {
    postal_code: "95969",
    full_city_name: "Paradise, CA",
    city_name: "Paradise",
  },
  {
    postal_code: "78732",
    full_city_name: "Austin, TX",
    city_name: "Austin",
  },
  {
    postal_code: "92274",
    full_city_name: "Thermal, CA",
    city_name: "Thermal",
  },
  {
    postal_code: "43205",
    full_city_name: "Columbus, OH",
    city_name: "Columbus",
  },
  {
    postal_code: "94589",
    full_city_name: "Vallejo, CA",
    city_name: "Vallejo",
  },
  {
    postal_code: "99156",
    full_city_name: "Newport, WA",
    city_name: "Newport",
  },
  {
    postal_code: "47265",
    full_city_name: "North Vernon, IN",
    city_name: "North Vernon",
  },
  {
    postal_code: "37757",
    full_city_name: "Jacksboro, TN",
    city_name: "Jacksboro",
  },
  {
    postal_code: "69341",
    full_city_name: "Gering, NE",
    city_name: "Gering",
  },
  {
    postal_code: "06479",
    full_city_name: "Plantsville, CT",
    city_name: "Plantsville",
  },
  {
    postal_code: "62918",
    full_city_name: "Carterville, IL",
    city_name: "Carterville",
  },
  {
    postal_code: "06890",
    full_city_name: "Southport, CT",
    city_name: "Southport",
  },
  {
    postal_code: "70517",
    full_city_name: "Breaux Bridge, LA",
    city_name: "Breaux Bridge",
  },
  {
    postal_code: "10302",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "80545",
    full_city_name: "Red Feather Lakes, CO",
    city_name: "Red Feather Lakes",
  },
  {
    postal_code: "92704",
    full_city_name: "Santa Ana, CA",
    city_name: "Santa Ana",
  },
  {
    postal_code: "75082",
    full_city_name: "Richardson, TX",
    city_name: "Richardson",
  },
  {
    postal_code: "75001",
    full_city_name: "Addison, TX",
    city_name: "Addison",
  },
  {
    postal_code: "61603",
    full_city_name: "Peoria, IL",
    city_name: "Peoria",
  },
  {
    postal_code: "33527",
    full_city_name: "Dover, FL",
    city_name: "Dover",
  },
  {
    postal_code: "02130",
    full_city_name: "Jamaica Plain, MA",
    city_name: "Jamaica Plain",
  },
  {
    postal_code: "63134",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "55045",
    full_city_name: "Lindstrom, MN",
    city_name: "Lindstrom",
  },
  {
    postal_code: "68528",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "83422",
    full_city_name: "Driggs, ID",
    city_name: "Driggs",
  },
  {
    postal_code: "08105",
    full_city_name: "Camden, NJ",
    city_name: "Camden",
  },
  {
    postal_code: "63070",
    full_city_name: "Pevely, MO",
    city_name: "Pevely",
  },
  {
    postal_code: "03257",
    full_city_name: "New London, NH",
    city_name: "New London",
  },
  {
    postal_code: "70452",
    full_city_name: "Pearl River, LA",
    city_name: "Pearl River",
  },
  {
    postal_code: "45822",
    full_city_name: "Celina, OH",
    city_name: "Celina",
  },
  {
    postal_code: "68112",
    full_city_name: "Omaha, NE",
    city_name: "Omaha",
  },
  {
    postal_code: "43950",
    full_city_name: "Saint Clairsville, OH",
    city_name: "Saint Clairsville",
  },
  {
    postal_code: "60712",
    full_city_name: "Lincolnwood, IL",
    city_name: "Lincolnwood",
  },
  {
    postal_code: "15116",
    full_city_name: "Glenshaw, PA",
    city_name: "Glenshaw",
  },
  {
    postal_code: "77094",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "97408",
    full_city_name: "Eugene, OR",
    city_name: "Eugene",
  },
  {
    postal_code: "94116",
    full_city_name: "San Francisco, CA",
    city_name: "San Francisco",
  },
  {
    postal_code: "25560",
    full_city_name: "Scott Depot, WV",
    city_name: "Scott Depot",
  },
  {
    postal_code: "74401",
    full_city_name: "Muskogee, OK",
    city_name: "Muskogee",
  },
  {
    postal_code: "62095",
    full_city_name: "Wood River, IL",
    city_name: "Wood River",
  },
  {
    postal_code: "26201",
    full_city_name: "Buckhannon, WV",
    city_name: "Buckhannon",
  },
  {
    postal_code: "70125",
    full_city_name: "New Orleans, LA",
    city_name: "New Orleans",
  },
  {
    postal_code: "33762",
    full_city_name: "Clearwater, FL",
    city_name: "Clearwater",
  },
  {
    postal_code: "43138",
    full_city_name: "Logan, OH",
    city_name: "Logan",
  },
  {
    postal_code: "53901",
    full_city_name: "Portage, WI",
    city_name: "Portage",
  },
  {
    postal_code: "23901",
    full_city_name: "Farmville, VA",
    city_name: "Farmville",
  },
  {
    postal_code: "75482",
    full_city_name: "Sulphur Springs, TX",
    city_name: "Sulphur Springs",
  },
  {
    postal_code: "54868",
    full_city_name: "Rice Lake, WI",
    city_name: "Rice Lake",
  },
  {
    postal_code: "61103",
    full_city_name: "Rockford, IL",
    city_name: "Rockford",
  },
  {
    postal_code: "20657",
    full_city_name: "Lusby, MD",
    city_name: "Lusby",
  },
  {
    postal_code: "10032",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "45107",
    full_city_name: "Blanchester, OH",
    city_name: "Blanchester",
  },
  {
    postal_code: "65605",
    full_city_name: "Aurora, MO",
    city_name: "Aurora",
  },
  {
    postal_code: "95650",
    full_city_name: "Loomis, CA",
    city_name: "Loomis",
  },
  {
    postal_code: "75169",
    full_city_name: "Wills Point, TX",
    city_name: "Wills Point",
  },
  {
    postal_code: "20877",
    full_city_name: "Gaithersburg, MD",
    city_name: "Gaithersburg",
  },
  {
    postal_code: "75050",
    full_city_name: "Grand Prairie, TX",
    city_name: "Grand Prairie",
  },
  {
    postal_code: "55944",
    full_city_name: "Kasson, MN",
    city_name: "Kasson",
  },
  {
    postal_code: "28785",
    full_city_name: "Waynesville, NC",
    city_name: "Waynesville",
  },
  {
    postal_code: "15214",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "75791",
    full_city_name: "Whitehouse, TX",
    city_name: "Whitehouse",
  },
  {
    postal_code: "60475",
    full_city_name: "Steger, IL",
    city_name: "Steger",
  },
  {
    postal_code: "18036",
    full_city_name: "Coopersburg, PA",
    city_name: "Coopersburg",
  },
  {
    postal_code: "55441",
    full_city_name: "Minneapolis, MN",
    city_name: "Minneapolis",
  },
  {
    postal_code: "06478",
    full_city_name: "Oxford, CT",
    city_name: "Oxford",
  },
  {
    postal_code: "90222",
    full_city_name: "Compton, CA",
    city_name: "Compton",
  },
  {
    postal_code: "32934",
    full_city_name: "Melbourne, FL",
    city_name: "Melbourne",
  },
  {
    postal_code: "74447",
    full_city_name: "Okmulgee, OK",
    city_name: "Okmulgee",
  },
  {
    postal_code: "28694",
    full_city_name: "West Jefferson, NC",
    city_name: "West Jefferson",
  },
  {
    postal_code: "61874",
    full_city_name: "Savoy, IL",
    city_name: "Savoy",
  },
  {
    postal_code: "28730",
    full_city_name: "Fairview, NC",
    city_name: "Fairview",
  },
  {
    postal_code: "06451",
    full_city_name: "Meriden, CT",
    city_name: "Meriden",
  },
  {
    postal_code: "56701",
    full_city_name: "Thief River Falls, MN",
    city_name: "Thief River Falls",
  },
  {
    postal_code: "20124",
    full_city_name: "Clifton, VA",
    city_name: "Clifton",
  },
  {
    postal_code: "70836",
    full_city_name: "Baton Rouge, LA",
    city_name: "Baton Rouge",
  },
  {
    postal_code: "85310",
    full_city_name: "Glendale, AZ",
    city_name: "Glendale",
  },
  {
    postal_code: "80905",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "37764",
    full_city_name: "Kodak, TN",
    city_name: "Kodak",
  },
  {
    postal_code: "97415",
    full_city_name: "Brookings, OR",
    city_name: "Brookings",
  },
  {
    postal_code: "77036",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "11233",
    full_city_name: "Brooklyn, NY",
    city_name: "Brooklyn",
  },
  {
    postal_code: "35210",
    full_city_name: "Birmingham, AL",
    city_name: "Birmingham",
  },
  {
    postal_code: "60464",
    full_city_name: "Palos Park, IL",
    city_name: "Palos Park",
  },
  {
    postal_code: "77048",
    full_city_name: "Houston, TX",
    city_name: "Houston",
  },
  {
    postal_code: "90034",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "45226",
    full_city_name: "Cincinnati, OH",
    city_name: "Cincinnati",
  },
  {
    postal_code: "46208",
    full_city_name: "Indianapolis, IN",
    city_name: "Indianapolis",
  },
  {
    postal_code: "98368",
    full_city_name: "Port Townsend, WA",
    city_name: "Port Townsend",
  },
  {
    postal_code: "93225",
    full_city_name: "Frazier Park, CA",
    city_name: "Frazier Park",
  },
  {
    postal_code: "71301",
    full_city_name: "Alexandria, LA",
    city_name: "Alexandria",
  },
  {
    postal_code: "75116",
    full_city_name: "Duncanville, TX",
    city_name: "Duncanville",
  },
  {
    postal_code: "15207",
    full_city_name: "Pittsburgh, PA",
    city_name: "Pittsburgh",
  },
  {
    postal_code: "78063",
    full_city_name: "Pipe Creek, TX",
    city_name: "Pipe Creek",
  },
  {
    postal_code: "10033",
    full_city_name: "New York, NY",
    city_name: "New York",
  },
  {
    postal_code: "22151",
    full_city_name: "Springfield, VA",
    city_name: "Springfield",
  },
  {
    postal_code: "55362",
    full_city_name: "Monticello, MN",
    city_name: "Monticello",
  },
  {
    postal_code: "98109",
    full_city_name: "Seattle, WA",
    city_name: "Seattle",
  },
  {
    postal_code: "96130",
    full_city_name: "Susanville, CA",
    city_name: "Susanville",
  },
  {
    postal_code: "70401",
    full_city_name: "Hammond, LA",
    city_name: "Hammond",
  },
  {
    postal_code: "94402",
    full_city_name: "San Mateo, CA",
    city_name: "San Mateo",
  },
  {
    postal_code: "20745",
    full_city_name: "Oxon Hill, MD",
    city_name: "Oxon Hill",
  },
  {
    postal_code: "10309",
    full_city_name: "Staten Island, NY",
    city_name: "Staten Island",
  },
  {
    postal_code: "63138",
    full_city_name: "Saint Louis, MO",
    city_name: "Saint Louis",
  },
  {
    postal_code: "76655",
    full_city_name: "Lorena, TX",
    city_name: "Lorena",
  },
  {
    postal_code: "40203",
    full_city_name: "Louisville, KY",
    city_name: "Louisville",
  },
  {
    postal_code: "79124",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "28016",
    full_city_name: "Bessemer City, NC",
    city_name: "Bessemer City",
  },
  {
    postal_code: "73114",
    full_city_name: "Oklahoma City, OK",
    city_name: "Oklahoma City",
  },
  {
    postal_code: "43402",
    full_city_name: "Bowling Green, OH",
    city_name: "Bowling Green",
  },
  {
    postal_code: "17025",
    full_city_name: "Enola, PA",
    city_name: "Enola",
  },
  {
    postal_code: "77901",
    full_city_name: "Victoria, TX",
    city_name: "Victoria",
  },
  {
    postal_code: "83202",
    full_city_name: "Pocatello, ID",
    city_name: "Pocatello",
  },
  {
    postal_code: "62801",
    full_city_name: "Centralia, IL",
    city_name: "Centralia",
  },
  {
    postal_code: "79761",
    full_city_name: "Odessa, TX",
    city_name: "Odessa",
  },
  {
    postal_code: "37760",
    full_city_name: "Jefferson City, TN",
    city_name: "Jefferson City",
  },
  {
    postal_code: "76117",
    full_city_name: "Haltom City, TX",
    city_name: "Haltom City",
  },
  {
    postal_code: "32736",
    full_city_name: "Eustis, FL",
    city_name: "Eustis",
  },
  {
    postal_code: "28630",
    full_city_name: "Granite Falls, NC",
    city_name: "Granite Falls",
  },
  {
    postal_code: "66106",
    full_city_name: "Kansas City, KS",
    city_name: "Kansas City",
  },
  {
    postal_code: "92386",
    full_city_name: "Sugarloaf, CA",
    city_name: "Sugarloaf",
  },
  {
    postal_code: "73601",
    full_city_name: "Clinton, OK",
    city_name: "Clinton",
  },
  {
    postal_code: "93543",
    full_city_name: "Littlerock, CA",
    city_name: "Littlerock",
  },
  {
    postal_code: "20814",
    full_city_name: "Bethesda, MD",
    city_name: "Bethesda",
  },
  {
    postal_code: "32784",
    full_city_name: "Umatilla, FL",
    city_name: "Umatilla",
  },
  {
    postal_code: "84102",
    full_city_name: "Salt Lake City, UT",
    city_name: "Salt Lake City",
  },
  {
    postal_code: "96785",
    full_city_name: "Volcano, HI",
    city_name: "Volcano",
  },
  {
    postal_code: "55356",
    full_city_name: "Long Lake, MN",
    city_name: "Long Lake",
  },
  {
    postal_code: "44119",
    full_city_name: "Cleveland, OH",
    city_name: "Cleveland",
  },
  {
    postal_code: "24151",
    full_city_name: "Rocky Mount, VA",
    city_name: "Rocky Mount",
  },
  {
    postal_code: "15101",
    full_city_name: "Allison Park, PA",
    city_name: "Allison Park",
  },
  {
    postal_code: "54017",
    full_city_name: "New Richmond, WI",
    city_name: "New Richmond",
  },
  {
    postal_code: "78550",
    full_city_name: "Harlingen, TX",
    city_name: "Harlingen",
  },
  {
    postal_code: "43616",
    full_city_name: "Oregon, OH",
    city_name: "Oregon",
  },
  {
    postal_code: "81416",
    full_city_name: "Delta, CO",
    city_name: "Delta",
  },
  {
    postal_code: "55013",
    full_city_name: "Chisago City, MN",
    city_name: "Chisago City",
  },
  {
    postal_code: "27408",
    full_city_name: "Greensboro, NC",
    city_name: "Greensboro",
  },
  {
    postal_code: "46373",
    full_city_name: "Saint John, IN",
    city_name: "Saint John",
  },
  {
    postal_code: "90066",
    full_city_name: "Los Angeles, CA",
    city_name: "Los Angeles",
  },
  {
    postal_code: "49022",
    full_city_name: "Benton Harbor, MI",
    city_name: "Benton Harbor",
  },
  {
    postal_code: "19464",
    full_city_name: "Pottstown, PA",
    city_name: "Pottstown",
  },
  {
    postal_code: "08533",
    full_city_name: "New Egypt, NJ",
    city_name: "New Egypt",
  },
  {
    postal_code: "55076",
    full_city_name: "Inver Grove Heights, MN",
    city_name: "Inver Grove Heights",
  },
  {
    postal_code: "89143",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "76036",
    full_city_name: "Crowley, TX",
    city_name: "Crowley",
  },
  {
    postal_code: "34104",
    full_city_name: "Naples, FL",
    city_name: "Naples",
  },
  {
    postal_code: "81650",
    full_city_name: "Rifle, CO",
    city_name: "Rifle",
  },
  {
    postal_code: "75028",
    full_city_name: "Flower Mound, TX",
    city_name: "Flower Mound",
  },
  {
    postal_code: "60083",
    full_city_name: "Wadsworth, IL",
    city_name: "Wadsworth",
  },
  {
    postal_code: "60014",
    full_city_name: "Crystal Lake, IL",
    city_name: "Crystal Lake",
  },
  {
    postal_code: "28739",
    full_city_name: "Hendersonville, NC",
    city_name: "Hendersonville",
  },
  {
    postal_code: "33009",
    full_city_name: "Hallandale, FL",
    city_name: "Hallandale",
  },
  {
    postal_code: "14031",
    full_city_name: "Clarence, NY",
    city_name: "Clarence",
  },
  {
    postal_code: "93420",
    full_city_name: "Arroyo Grande, CA",
    city_name: "Arroyo Grande",
  },
  {
    postal_code: "59803",
    full_city_name: "Missoula, MT",
    city_name: "Missoula",
  },
  {
    postal_code: "80443",
    full_city_name: "Frisco, CO",
    city_name: "Frisco",
  },
  {
    postal_code: "98075",
    full_city_name: "Sammamish, WA",
    city_name: "Sammamish",
  },
  {
    postal_code: "07747",
    full_city_name: "Matawan, NJ",
    city_name: "Matawan",
  },
  {
    postal_code: "52568",
    full_city_name: "Martinsburg, IA",
    city_name: "Martinsburg",
  },
  {
    postal_code: "51459",
    full_city_name: "Ralston, IA",
    city_name: "Ralston",
  },
  {
    postal_code: "58430",
    full_city_name: "Denhoff, ND",
    city_name: "Denhoff",
  },
  {
    postal_code: "18709",
    full_city_name: "Luzerne, PA",
    city_name: "Luzerne",
  },
  {
    postal_code: "58481",
    full_city_name: "Spiritwood, ND",
    city_name: "Spiritwood",
  },
  {
    postal_code: "87049",
    full_city_name: "San Fidel, NM",
    city_name: "San Fidel",
  },
  {
    postal_code: "73931",
    full_city_name: "Balko, OK",
    city_name: "Balko",
  },
  {
    postal_code: "66962",
    full_city_name: "Palmer, KS",
    city_name: "Palmer",
  },
  {
    postal_code: "83435",
    full_city_name: "Monteview, ID",
    city_name: "Monteview",
  },
  {
    postal_code: "62370",
    full_city_name: "Rockport, IL",
    city_name: "Rockport",
  },
  {
    postal_code: "50145",
    full_city_name: "Liberty Center, IA",
    city_name: "Liberty Center",
  },
  {
    postal_code: "59528",
    full_city_name: "Hingham, MT",
    city_name: "Hingham",
  },
  {
    postal_code: "46704",
    full_city_name: "Arcola, IN",
    city_name: "Arcola",
  },
  {
    postal_code: "61543",
    full_city_name: "Liverpool, IL",
    city_name: "Liverpool",
  },
  {
    postal_code: "78353",
    full_city_name: "Encino, TX",
    city_name: "Encino",
  },
  {
    postal_code: "67071",
    full_city_name: "Lake City, KS",
    city_name: "Lake City",
  },
  {
    postal_code: "16253",
    full_city_name: "Seminole, PA",
    city_name: "Seminole",
  },
  {
    postal_code: "68433",
    full_city_name: "Salem, NE",
    city_name: "Salem",
  },
  {
    postal_code: "57218",
    full_city_name: "Brandt, SD",
    city_name: "Brandt",
  },
  {
    postal_code: "66017",
    full_city_name: "Denton, KS",
    city_name: "Denton",
  },
  {
    postal_code: "50837",
    full_city_name: "Bridgewater, IA",
    city_name: "Bridgewater",
  },
  {
    postal_code: "97533",
    full_city_name: "Murphy, OR",
    city_name: "Murphy",
  },
  {
    postal_code: "33847",
    full_city_name: "Homeland, FL",
    city_name: "Homeland",
  },
  {
    postal_code: "67656",
    full_city_name: "Ogallah, KS",
    city_name: "Ogallah",
  },
  {
    postal_code: "97826",
    full_city_name: "Echo, OR",
    city_name: "Echo",
  },
  {
    postal_code: "68324",
    full_city_name: "Burr, NE",
    city_name: "Burr",
  },
  {
    postal_code: "25837",
    full_city_name: "Edmond, WV",
    city_name: "Edmond",
  },
  {
    postal_code: "72394",
    full_city_name: "Widener, AR",
    city_name: "Widener",
  },
  {
    postal_code: "59250",
    full_city_name: "Opheim, MT",
    city_name: "Opheim",
  },
  {
    postal_code: "57432",
    full_city_name: "Claremont, SD",
    city_name: "Claremont",
  },
  {
    postal_code: "84764",
    full_city_name: "Bryce, UT",
    city_name: "Bryce",
  },
  {
    postal_code: "93673",
    full_city_name: "Traver, CA",
    city_name: "Traver",
  },
  {
    postal_code: "41766",
    full_city_name: "Thousandsticks, KY",
    city_name: "Thousandsticks",
  },
  {
    postal_code: "78406",
    full_city_name: "Corpus Christi, TX",
    city_name: "Corpus Christi",
  },
  {
    postal_code: "62701",
    full_city_name: "Springfield, IL",
    city_name: "Springfield",
  },
  {
    postal_code: "41712",
    full_city_name: "Ary, KY",
    city_name: "Ary",
  },
  {
    postal_code: "73503",
    full_city_name: "Fort Sill, OK",
    city_name: "Fort Sill",
  },
  {
    postal_code: "76459",
    full_city_name: "Jermyn, TX",
    city_name: "Jermyn",
  },
  {
    postal_code: "38721",
    full_city_name: "Anguilla, MS",
    city_name: "Anguilla",
  },
  {
    postal_code: "38949",
    full_city_name: "Paris, MS",
    city_name: "Paris",
  },
  {
    postal_code: "23709",
    full_city_name: "Portsmouth, VA",
    city_name: "Portsmouth",
  },
  {
    postal_code: "50064",
    full_city_name: "Dana, IA",
    city_name: "Dana",
  },
  {
    postal_code: "66550",
    full_city_name: "Wetmore, KS",
    city_name: "Wetmore",
  },
  {
    postal_code: "25634",
    full_city_name: "Mallory, WV",
    city_name: "Mallory",
  },
  {
    postal_code: "29592",
    full_city_name: "Sellers, SC",
    city_name: "Sellers",
  },
  {
    postal_code: "64657",
    full_city_name: "Mc Fall, MO",
    city_name: "Mc Fall",
  },
  {
    postal_code: "66940",
    full_city_name: "Cuba, KS",
    city_name: "Cuba",
  },
  {
    postal_code: "26720",
    full_city_name: "Gormania, WV",
    city_name: "Gormania",
  },
  {
    postal_code: "87316",
    full_city_name: "Fort Wingate, NM",
    city_name: "Fort Wingate",
  },
  {
    postal_code: "06389",
    full_city_name: "Yantic, CT",
    city_name: "Yantic",
  },
  {
    postal_code: "67634",
    full_city_name: "Dorrance, KS",
    city_name: "Dorrance",
  },
  {
    postal_code: "01029",
    full_city_name: "East Otis, MA",
    city_name: "East Otis",
  },
  {
    postal_code: "58269",
    full_city_name: "Osnabrock, ND",
    city_name: "Osnabrock",
  },
  {
    postal_code: "82224",
    full_city_name: "Lost Springs, WY",
    city_name: "Lost Springs",
  },
  {
    postal_code: "58250",
    full_city_name: "Lankin, ND",
    city_name: "Lankin",
  },
  {
    postal_code: "51011",
    full_city_name: "Chatsworth, IA",
    city_name: "Chatsworth",
  },
  {
    postal_code: "12961",
    full_city_name: "Moriah Center, NY",
    city_name: "Moriah Center",
  },
  {
    postal_code: "16724",
    full_city_name: "Crosby, PA",
    city_name: "Crosby",
  },
  {
    postal_code: "97819",
    full_city_name: "Bridgeport, OR",
    city_name: "Bridgeport",
  },
  {
    postal_code: "24828",
    full_city_name: "Davy, WV",
    city_name: "Davy",
  },
  {
    postal_code: "01380",
    full_city_name: "Wendell Depot, MA",
    city_name: "Wendell Depot",
  },
  {
    postal_code: "99734",
    full_city_name: "Prudhoe Bay, AK",
    city_name: "Prudhoe Bay",
  },
  {
    postal_code: "15020",
    full_city_name: "Bunola, PA",
    city_name: "Bunola",
  },
  {
    postal_code: "99549",
    full_city_name: "Port Heiden, AK",
    city_name: "Port Heiden",
  },
  {
    postal_code: "68882",
    full_city_name: "Wolbach, NE",
    city_name: "Wolbach",
  },
  {
    postal_code: "99703",
    full_city_name: "Fort Wainwright, AK",
    city_name: "Fort Wainwright",
  },
  {
    postal_code: "59462",
    full_city_name: "Moccasin, MT",
    city_name: "Moccasin",
  },
  {
    postal_code: "73549",
    full_city_name: "Headrick, OK",
    city_name: "Headrick",
  },
  {
    postal_code: "67674",
    full_city_name: "Walker, KS",
    city_name: "Walker",
  },
  {
    postal_code: "59217",
    full_city_name: "Crane, MT",
    city_name: "Crane",
  },
  {
    postal_code: "63837",
    full_city_name: "Clarkton, MO",
    city_name: "Clarkton",
  },
  {
    postal_code: "58225",
    full_city_name: "Drayton, ND",
    city_name: "Drayton",
  },
  {
    postal_code: "68929",
    full_city_name: "Bloomington, NE",
    city_name: "Bloomington",
  },
  {
    postal_code: "73733",
    full_city_name: "Douglas, OK",
    city_name: "Douglas",
  },
  {
    postal_code: "62843",
    full_city_name: "Golden Gate, IL",
    city_name: "Golden Gate",
  },
  {
    postal_code: "34445",
    full_city_name: "Holder, FL",
    city_name: "Holder",
  },
  {
    postal_code: "13123",
    full_city_name: "North Bay, NY",
    city_name: "North Bay",
  },
  {
    postal_code: "39767",
    full_city_name: "Stewart, MS",
    city_name: "Stewart",
  },
  {
    postal_code: "80742",
    full_city_name: "New Raymer, CO",
    city_name: "New Raymer",
  },
  {
    postal_code: "79748",
    full_city_name: "Knott, TX",
    city_name: "Knott",
  },
  {
    postal_code: "67743",
    full_city_name: "Levant, KS",
    city_name: "Levant",
  },
  {
    postal_code: "69039",
    full_city_name: "Moorefield, NE",
    city_name: "Moorefield",
  },
  {
    postal_code: "56249",
    full_city_name: "Holloway, MN",
    city_name: "Holloway",
  },
  {
    postal_code: "30322",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "52166",
    full_city_name: "Saint Lucas, IA",
    city_name: "Saint Lucas",
  },
  {
    postal_code: "15361",
    full_city_name: "Southview, PA",
    city_name: "Southview",
  },
  {
    postal_code: "67127",
    full_city_name: "Protection, KS",
    city_name: "Protection",
  },
  {
    postal_code: "01347",
    full_city_name: "Lake Pleasant, MA",
    city_name: "Lake Pleasant",
  },
  {
    postal_code: "89165",
    full_city_name: "Las Vegas, NV",
    city_name: "Las Vegas",
  },
  {
    postal_code: "37396",
    full_city_name: "Whiteside, TN",
    city_name: "Whiteside",
  },
  {
    postal_code: "57041",
    full_city_name: "Lyons, SD",
    city_name: "Lyons",
  },
  {
    postal_code: "16852",
    full_city_name: "Madisonburg, PA",
    city_name: "Madisonburg",
  },
  {
    postal_code: "46502",
    full_city_name: "Atwood, IN",
    city_name: "Atwood",
  },
  {
    postal_code: "36436",
    full_city_name: "Dickinson, AL",
    city_name: "Dickinson",
  },
  {
    postal_code: "73720",
    full_city_name: "Bison, OK",
    city_name: "Bison",
  },
  {
    postal_code: "71853",
    full_city_name: "Ogden, AR",
    city_name: "Ogden",
  },
  {
    postal_code: "19733",
    full_city_name: "Saint Georges, DE",
    city_name: "Saint Georges",
  },
  {
    postal_code: "62444",
    full_city_name: "Mode, IL",
    city_name: "Mode",
  },
  {
    postal_code: "68350",
    full_city_name: "Endicott, NE",
    city_name: "Endicott",
  },
  {
    postal_code: "67628",
    full_city_name: "Cedar, KS",
    city_name: "Cedar",
  },
  {
    postal_code: "57756",
    full_city_name: "Manderson, SD",
    city_name: "Manderson",
  },
  {
    postal_code: "59067",
    full_city_name: "Rapelje, MT",
    city_name: "Rapelje",
  },
  {
    postal_code: "62090",
    full_city_name: "Venice, IL",
    city_name: "Venice",
  },
  {
    postal_code: "58277",
    full_city_name: "Sharon, ND",
    city_name: "Sharon",
  },
  {
    postal_code: "72199",
    full_city_name: "North Little Rock, AR",
    city_name: "North Little Rock",
  },
  {
    postal_code: "64497",
    full_city_name: "Weatherby, MO",
    city_name: "Weatherby",
  },
  {
    postal_code: "88411",
    full_city_name: "Bard, NM",
    city_name: "Bard",
  },
  {
    postal_code: "83853",
    full_city_name: "Porthill, ID",
    city_name: "Porthill",
  },
  {
    postal_code: "47573",
    full_city_name: "Ragsdale, IN",
    city_name: "Ragsdale",
  },
  {
    postal_code: "67475",
    full_city_name: "Ramona, KS",
    city_name: "Ramona",
  },
  {
    postal_code: "41822",
    full_city_name: "Hindman, KY",
    city_name: "Hindman",
  },
  {
    postal_code: "36759",
    full_city_name: "Marion Junction, AL",
    city_name: "Marion Junction",
  },
  {
    postal_code: "67644",
    full_city_name: "Kirwin, KS",
    city_name: "Kirwin",
  },
  {
    postal_code: "25915",
    full_city_name: "Rhodell, WV",
    city_name: "Rhodell",
  },
  {
    postal_code: "63458",
    full_city_name: "Newark, MO",
    city_name: "Newark",
  },
  {
    postal_code: "69367",
    full_city_name: "Whitney, NE",
    city_name: "Whitney",
  },
  {
    postal_code: "08750",
    full_city_name: "Sea Girt, NJ",
    city_name: "Sea Girt",
  },
  {
    postal_code: "24059",
    full_city_name: "Bent Mountain, VA",
    city_name: "Bent Mountain",
  },
  {
    postal_code: "14083",
    full_city_name: "Java Village, NY",
    city_name: "Java Village",
  },
  {
    postal_code: "65058",
    full_city_name: "Meta, MO",
    city_name: "Meta",
  },
  {
    postal_code: "69218",
    full_city_name: "Merriman, NE",
    city_name: "Merriman",
  },
  {
    postal_code: "62217",
    full_city_name: "Baldwin, IL",
    city_name: "Baldwin",
  },
  {
    postal_code: "57265",
    full_city_name: "Strandburg, SD",
    city_name: "Strandburg",
  },
  {
    postal_code: "57256",
    full_city_name: "Ortley, SD",
    city_name: "Ortley",
  },
  {
    postal_code: "62892",
    full_city_name: "Vernon, IL",
    city_name: "Vernon",
  },
  {
    postal_code: "47836",
    full_city_name: "Bridgeton, IN",
    city_name: "Bridgeton",
  },
  {
    postal_code: "54207",
    full_city_name: "Collins, WI",
    city_name: "Collins",
  },
  {
    postal_code: "08073",
    full_city_name: "Rancocas, NJ",
    city_name: "Rancocas",
  },
  {
    postal_code: "57706",
    full_city_name: "Ellsworth Afb, SD",
    city_name: "Ellsworth Afb",
  },
  {
    postal_code: "40110",
    full_city_name: "Clermont, KY",
    city_name: "Clermont",
  },
  {
    postal_code: "15744",
    full_city_name: "Hamilton, PA",
    city_name: "Hamilton",
  },
  {
    postal_code: "11430",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "47871",
    full_city_name: "Riley, IN",
    city_name: "Riley",
  },
  {
    postal_code: "13418",
    full_city_name: "North Brookfield, NY",
    city_name: "North Brookfield",
  },
  {
    postal_code: "72330",
    full_city_name: "Dyess, AR",
    city_name: "Dyess",
  },
  {
    postal_code: "72338",
    full_city_name: "Frenchmans Bayou, AR",
    city_name: "Frenchmans Bayou",
  },
  {
    postal_code: "38047",
    full_city_name: "Lenox, TN",
    city_name: "Lenox",
  },
  {
    postal_code: "88436",
    full_city_name: "Sedan, NM",
    city_name: "Sedan",
  },
  {
    postal_code: "80824",
    full_city_name: "Kirk, CO",
    city_name: "Kirk",
  },
  {
    postal_code: "61815",
    full_city_name: "Bondville, IL",
    city_name: "Bondville",
  },
  {
    postal_code: "62356",
    full_city_name: "New Canton, IL",
    city_name: "New Canton",
  },
  {
    postal_code: "38957",
    full_city_name: "Sumner, MS",
    city_name: "Sumner",
  },
  {
    postal_code: "89826",
    full_city_name: "Jarbidge, NV",
    city_name: "Jarbidge",
  },
  {
    postal_code: "94964",
    full_city_name: "San Quentin, CA",
    city_name: "San Quentin",
  },
  {
    postal_code: "99745",
    full_city_name: "Hughes, AK",
    city_name: "Hughes",
  },
  {
    postal_code: "73758",
    full_city_name: "Manchester, OK",
    city_name: "Manchester",
  },
  {
    postal_code: "72055",
    full_city_name: "Gillett, AR",
    city_name: "Gillett",
  },
  {
    postal_code: "58778",
    full_city_name: "Ruso, ND",
    city_name: "Ruso",
  },
  {
    postal_code: "43962",
    full_city_name: "Summitville, OH",
    city_name: "Summitville",
  },
  {
    postal_code: "62434",
    full_city_name: "Ingraham, IL",
    city_name: "Ingraham",
  },
  {
    postal_code: "97909",
    full_city_name: "Jamieson, OR",
    city_name: "Jamieson",
  },
  {
    postal_code: "25870",
    full_city_name: "Maben, WV",
    city_name: "Maben",
  },
  {
    postal_code: "62963",
    full_city_name: "Mound City, IL",
    city_name: "Mound City",
  },
  {
    postal_code: "72052",
    full_city_name: "Garner, AR",
    city_name: "Garner",
  },
  {
    postal_code: "71079",
    full_city_name: "Summerfield, LA",
    city_name: "Summerfield",
  },
  {
    postal_code: "68365",
    full_city_name: "Grafton, NE",
    city_name: "Grafton",
  },
  {
    postal_code: "25857",
    full_city_name: "Josephine, WV",
    city_name: "Josephine",
  },
  {
    postal_code: "71472",
    full_city_name: "Sieper, LA",
    city_name: "Sieper",
  },
  {
    postal_code: "69032",
    full_city_name: "Hayes Center, NE",
    city_name: "Hayes Center",
  },
  {
    postal_code: "58711",
    full_city_name: "Antler, ND",
    city_name: "Antler",
  },
  {
    postal_code: "67513",
    full_city_name: "Alexander, KS",
    city_name: "Alexander",
  },
  {
    postal_code: "41421",
    full_city_name: "Elkfork, KY",
    city_name: "Elkfork",
  },
  {
    postal_code: "03897",
    full_city_name: "Wonalancet, NH",
    city_name: "Wonalancet",
  },
  {
    postal_code: "40801",
    full_city_name: "Ages Brookside, KY",
    city_name: "Ages Brookside",
  },
  {
    postal_code: "41262",
    full_city_name: "Tomahawk, KY",
    city_name: "Tomahawk",
  },
  {
    postal_code: "63675",
    full_city_name: "Vulcan, MO",
    city_name: "Vulcan",
  },
  {
    postal_code: "58332",
    full_city_name: "Esmond, ND",
    city_name: "Esmond",
  },
  {
    postal_code: "98524",
    full_city_name: "Allyn, WA",
    city_name: "Allyn",
  },
  {
    postal_code: "38944",
    full_city_name: "Minter City, MS",
    city_name: "Minter City",
  },
  {
    postal_code: "25206",
    full_city_name: "Van, WV",
    city_name: "Van",
  },
  {
    postal_code: "80802",
    full_city_name: "Arapahoe, CO",
    city_name: "Arapahoe",
  },
  {
    postal_code: "51452",
    full_city_name: "Lidderdale, IA",
    city_name: "Lidderdale",
  },
  {
    postal_code: "48862",
    full_city_name: "North Star, MI",
    city_name: "North Star",
  },
  {
    postal_code: "26585",
    full_city_name: "Metz, WV",
    city_name: "Metz",
  },
  {
    postal_code: "17120",
    full_city_name: "Harrisburg, PA",
    city_name: "Harrisburg",
  },
  {
    postal_code: "88250",
    full_city_name: "Hope, NM",
    city_name: "Hope",
  },
  {
    postal_code: "43925",
    full_city_name: "East Springfield, OH",
    city_name: "East Springfield",
  },
  {
    postal_code: "68831",
    full_city_name: "Dannebrog, NE",
    city_name: "Dannebrog",
  },
  {
    postal_code: "57649",
    full_city_name: "Prairie City, SD",
    city_name: "Prairie City",
  },
  {
    postal_code: "43439",
    full_city_name: "Lacarne, OH",
    city_name: "Lacarne",
  },
  {
    postal_code: "26587",
    full_city_name: "Rachel, WV",
    city_name: "Rachel",
  },
  {
    postal_code: "41360",
    full_city_name: "Pine Ridge, KY",
    city_name: "Pine Ridge",
  },
  {
    postal_code: "76865",
    full_city_name: "Norton, TX",
    city_name: "Norton",
  },
  {
    postal_code: "66428",
    full_city_name: "Goff, KS",
    city_name: "Goff",
  },
  {
    postal_code: "45033",
    full_city_name: "Hooven, OH",
    city_name: "Hooven",
  },
  {
    postal_code: "82071",
    full_city_name: "Laramie, WY",
    city_name: "Laramie",
  },
  {
    postal_code: "57521",
    full_city_name: "Belvidere, SD",
    city_name: "Belvidere",
  },
  {
    postal_code: "59025",
    full_city_name: "Decker, MT",
    city_name: "Decker",
  },
  {
    postal_code: "67017",
    full_city_name: "Benton, KS",
    city_name: "Benton",
  },
  {
    postal_code: "68749",
    full_city_name: "Magnet, NE",
    city_name: "Magnet",
  },
  {
    postal_code: "29152",
    full_city_name: "Shaw Afb, SC",
    city_name: "Shaw Afb",
  },
  {
    postal_code: "79220",
    full_city_name: "Afton, TX",
    city_name: "Afton",
  },
  {
    postal_code: "95653",
    full_city_name: "Madison, CA",
    city_name: "Madison",
  },
  {
    postal_code: "58239",
    full_city_name: "Hannah, ND",
    city_name: "Hannah",
  },
  {
    postal_code: "16692",
    full_city_name: "Westover, PA",
    city_name: "Westover",
  },
  {
    postal_code: "68878",
    full_city_name: "Sumner, NE",
    city_name: "Sumner",
  },
  {
    postal_code: "59841",
    full_city_name: "Pinesdale, MT",
    city_name: "Pinesdale",
  },
  {
    postal_code: "68531",
    full_city_name: "Lincoln, NE",
    city_name: "Lincoln",
  },
  {
    postal_code: "58058",
    full_city_name: "Mantador, ND",
    city_name: "Mantador",
  },
  {
    postal_code: "52359",
    full_city_name: "West Chester, IA",
    city_name: "West Chester",
  },
  {
    postal_code: "37328",
    full_city_name: "Elora, TN",
    city_name: "Elora",
  },
  {
    postal_code: "35061",
    full_city_name: "Dolomite, AL",
    city_name: "Dolomite",
  },
  {
    postal_code: "58833",
    full_city_name: "Ambrose, ND",
    city_name: "Ambrose",
  },
  {
    postal_code: "58006",
    full_city_name: "Arthur, ND",
    city_name: "Arthur",
  },
  {
    postal_code: "56122",
    full_city_name: "Chandler, MN",
    city_name: "Chandler",
  },
  {
    postal_code: "39736",
    full_city_name: "Artesia, MS",
    city_name: "Artesia",
  },
  {
    postal_code: "39771",
    full_city_name: "Walthall, MS",
    city_name: "Walthall",
  },
  {
    postal_code: "87312",
    full_city_name: "Continental Divide, NM",
    city_name: "Continental Divide",
  },
  {
    postal_code: "38924",
    full_city_name: "Cruger, MS",
    city_name: "Cruger",
  },
  {
    postal_code: "66946",
    full_city_name: "Hollenberg, KS",
    city_name: "Hollenberg",
  },
  {
    postal_code: "72333",
    full_city_name: "Elaine, AR",
    city_name: "Elaine",
  },
  {
    postal_code: "67737",
    full_city_name: "Grainfield, KS",
    city_name: "Grainfield",
  },
  {
    postal_code: "97908",
    full_city_name: "Ironside, OR",
    city_name: "Ironside",
  },
  {
    postal_code: "57640",
    full_city_name: "Lodgepole, SD",
    city_name: "Lodgepole",
  },
  {
    postal_code: "24845",
    full_city_name: "Ikes Fork, WV",
    city_name: "Ikes Fork",
  },
  {
    postal_code: "58439",
    full_city_name: "Forbes, ND",
    city_name: "Forbes",
  },
  {
    postal_code: "59058",
    full_city_name: "Mosby, MT",
    city_name: "Mosby",
  },
  {
    postal_code: "66406",
    full_city_name: "Beattie, KS",
    city_name: "Beattie",
  },
  {
    postal_code: "63820",
    full_city_name: "Anniston, MO",
    city_name: "Anniston",
  },
  {
    postal_code: "17250",
    full_city_name: "Rouzerville, PA",
    city_name: "Rouzerville",
  },
  {
    postal_code: "56577",
    full_city_name: "Richwood, MN",
    city_name: "Richwood",
  },
  {
    postal_code: "58755",
    full_city_name: "Mcgregor, ND",
    city_name: "Mcgregor",
  },
  {
    postal_code: "25025",
    full_city_name: "Blount, WV",
    city_name: "Blount",
  },
  {
    postal_code: "40827",
    full_city_name: "Essie, KY",
    city_name: "Essie",
  },
  {
    postal_code: "62238",
    full_city_name: "Cutler, IL",
    city_name: "Cutler",
  },
  {
    postal_code: "36776",
    full_city_name: "Sawyerville, AL",
    city_name: "Sawyerville",
  },
  {
    postal_code: "13163",
    full_city_name: "Wampsville, NY",
    city_name: "Wampsville",
  },
  {
    postal_code: "24808",
    full_city_name: "Anawalt, WV",
    city_name: "Anawalt",
  },
  {
    postal_code: "50671",
    full_city_name: "Stanley, IA",
    city_name: "Stanley",
  },
  {
    postal_code: "15548",
    full_city_name: "Kantner, PA",
    city_name: "Kantner",
  },
  {
    postal_code: "73544",
    full_city_name: "Gould, OK",
    city_name: "Gould",
  },
  {
    postal_code: "68335",
    full_city_name: "Davenport, NE",
    city_name: "Davenport",
  },
  {
    postal_code: "58844",
    full_city_name: "Fortuna, ND",
    city_name: "Fortuna",
  },
  {
    postal_code: "79058",
    full_city_name: "Masterson, TX",
    city_name: "Masterson",
  },
  {
    postal_code: "71426",
    full_city_name: "Fisher, LA",
    city_name: "Fisher",
  },
  {
    postal_code: "71630",
    full_city_name: "Arkansas City, AR",
    city_name: "Arkansas City",
  },
  {
    postal_code: "72165",
    full_city_name: "Thida, AR",
    city_name: "Thida",
  },
  {
    postal_code: "69221",
    full_city_name: "Wood Lake, NE",
    city_name: "Wood Lake",
  },
  {
    postal_code: "51062",
    full_city_name: "Westfield, IA",
    city_name: "Westfield",
  },
  {
    postal_code: "57245",
    full_city_name: "Kranzburg, SD",
    city_name: "Kranzburg",
  },
  {
    postal_code: "58562",
    full_city_name: "New Leipzig, ND",
    city_name: "New Leipzig",
  },
  {
    postal_code: "82731",
    full_city_name: "Weston, WY",
    city_name: "Weston",
  },
  {
    postal_code: "67057",
    full_city_name: "Hardtner, KS",
    city_name: "Hardtner",
  },
  {
    postal_code: "67112",
    full_city_name: "Nashville, KS",
    city_name: "Nashville",
  },
  {
    postal_code: "82838",
    full_city_name: "Parkman, WY",
    city_name: "Parkman",
  },
  {
    postal_code: "69023",
    full_city_name: "Champion, NE",
    city_name: "Champion",
  },
  {
    postal_code: "89311",
    full_city_name: "Baker, NV",
    city_name: "Baker",
  },
  {
    postal_code: "68828",
    full_city_name: "Comstock, NE",
    city_name: "Comstock",
  },
  {
    postal_code: "52232",
    full_city_name: "Hartwick, IA",
    city_name: "Hartwick",
  },
  {
    postal_code: "57052",
    full_city_name: "Olivet, SD",
    city_name: "Olivet",
  },
  {
    postal_code: "68948",
    full_city_name: "Holbrook, NE",
    city_name: "Holbrook",
  },
  {
    postal_code: "97065",
    full_city_name: "Wasco, OR",
    city_name: "Wasco",
  },
  {
    postal_code: "66544",
    full_city_name: "Vermillion, KS",
    city_name: "Vermillion",
  },
  {
    postal_code: "41052",
    full_city_name: "Jonesville, KY",
    city_name: "Jonesville",
  },
  {
    postal_code: "25986",
    full_city_name: "Spring Dale, WV",
    city_name: "Spring Dale",
  },
  {
    postal_code: "68975",
    full_city_name: "Saronville, NE",
    city_name: "Saronville",
  },
  {
    postal_code: "58363",
    full_city_name: "Perth, ND",
    city_name: "Perth",
  },
  {
    postal_code: "65034",
    full_city_name: "Fortuna, MO",
    city_name: "Fortuna",
  },
  {
    postal_code: "36585",
    full_city_name: "Wagarville, AL",
    city_name: "Wagarville",
  },
  {
    postal_code: "99626",
    full_city_name: "Lower Kalskag, AK",
    city_name: "Lower Kalskag",
  },
  {
    postal_code: "57633",
    full_city_name: "Isabel, SD",
    city_name: "Isabel",
  },
  {
    postal_code: "78536",
    full_city_name: "Delmita, TX",
    city_name: "Delmita",
  },
  {
    postal_code: "32437",
    full_city_name: "Ebro, FL",
    city_name: "Ebro",
  },
  {
    postal_code: "57233",
    full_city_name: "Erwin, SD",
    city_name: "Erwin",
  },
  {
    postal_code: "15745",
    full_city_name: "Heilwood, PA",
    city_name: "Heilwood",
  },
  {
    postal_code: "42032",
    full_city_name: "Columbus, KY",
    city_name: "Columbus",
  },
  {
    postal_code: "68445",
    full_city_name: "Swanton, NE",
    city_name: "Swanton",
  },
  {
    postal_code: "76544",
    full_city_name: "Fort Hood, TX",
    city_name: "Fort Hood",
  },
  {
    postal_code: "26546",
    full_city_name: "Pursglove, WV",
    city_name: "Pursglove",
  },
  {
    postal_code: "47863",
    full_city_name: "New Goshen, IN",
    city_name: "New Goshen",
  },
  {
    postal_code: "84753",
    full_city_name: "Modena, UT",
    city_name: "Modena",
  },
  {
    postal_code: "43434",
    full_city_name: "Harbor View, OH",
    city_name: "Harbor View",
  },
  {
    postal_code: "56146",
    full_city_name: "Kanaranzi, MN",
    city_name: "Kanaranzi",
  },
  {
    postal_code: "89409",
    full_city_name: "Gabbs, NV",
    city_name: "Gabbs",
  },
  {
    postal_code: "54978",
    full_city_name: "Tilleda, WI",
    city_name: "Tilleda",
  },
  {
    postal_code: "69161",
    full_city_name: "Seneca, NE",
    city_name: "Seneca",
  },
  {
    postal_code: "12207",
    full_city_name: "Albany, NY",
    city_name: "Albany",
  },
  {
    postal_code: "57536",
    full_city_name: "Harrold, SD",
    city_name: "Harrold",
  },
  {
    postal_code: "48063",
    full_city_name: "Columbus, MI",
    city_name: "Columbus",
  },
  {
    postal_code: "64847",
    full_city_name: "Lanagan, MO",
    city_name: "Lanagan",
  },
  {
    postal_code: "24628",
    full_city_name: "Maxie, VA",
    city_name: "Maxie",
  },
  {
    postal_code: "99636",
    full_city_name: "New Stuyahok, AK",
    city_name: "New Stuyahok",
  },
  {
    postal_code: "73719",
    full_city_name: "Amorita, OK",
    city_name: "Amorita",
  },
  {
    postal_code: "23186",
    full_city_name: "Williamsburg, VA",
    city_name: "Williamsburg",
  },
  {
    postal_code: "85141",
    full_city_name: "Picacho, AZ",
    city_name: "Picacho",
  },
  {
    postal_code: "79777",
    full_city_name: "Pyote, TX",
    city_name: "Pyote",
  },
  {
    postal_code: "42742",
    full_city_name: "Gradyville, KY",
    city_name: "Gradyville",
  },
  {
    postal_code: "58443",
    full_city_name: "Glenfield, ND",
    city_name: "Glenfield",
  },
  {
    postal_code: "24862",
    full_city_name: "Mohawk, WV",
    city_name: "Mohawk",
  },
  {
    postal_code: "68062",
    full_city_name: "Thurston, NE",
    city_name: "Thurston",
  },
  {
    postal_code: "50467",
    full_city_name: "Rock Falls, IA",
    city_name: "Rock Falls",
  },
  {
    postal_code: "70643",
    full_city_name: "Grand Chenier, LA",
    city_name: "Grand Chenier",
  },
  {
    postal_code: "63434",
    full_city_name: "Bethel, MO",
    city_name: "Bethel",
  },
  {
    postal_code: "25612",
    full_city_name: "Chauncey, WV",
    city_name: "Chauncey",
  },
  {
    postal_code: "41714",
    full_city_name: "Bear Branch, KY",
    city_name: "Bear Branch",
  },
  {
    postal_code: "99551",
    full_city_name: "Akiachak, AK",
    city_name: "Akiachak",
  },
  {
    postal_code: "87746",
    full_city_name: "Solano, NM",
    city_name: "Solano",
  },
  {
    postal_code: "69333",
    full_city_name: "Ashby, NE",
    city_name: "Ashby",
  },
  {
    postal_code: "41719",
    full_city_name: "Bonnyman, KY",
    city_name: "Bonnyman",
  },
  {
    postal_code: "93262",
    full_city_name: "Sequoia National Park, CA",
    city_name: "Sequoia National Park",
  },
  {
    postal_code: "40995",
    full_city_name: "Trosper, KY",
    city_name: "Trosper",
  },
  {
    postal_code: "70632",
    full_city_name: "Creole, LA",
    city_name: "Creole",
  },
  {
    postal_code: "15364",
    full_city_name: "Sycamore, PA",
    city_name: "Sycamore",
  },
  {
    postal_code: "58560",
    full_city_name: "Moffit, ND",
    city_name: "Moffit",
  },
  {
    postal_code: "35774",
    full_city_name: "Trenton, AL",
    city_name: "Trenton",
  },
  {
    postal_code: "26636",
    full_city_name: "Rosedale, WV",
    city_name: "Rosedale",
  },
  {
    postal_code: "15492",
    full_city_name: "Wickhaven, PA",
    city_name: "Wickhaven",
  },
  {
    postal_code: "59489",
    full_city_name: "Winifred, MT",
    city_name: "Winifred",
  },
  {
    postal_code: "02047",
    full_city_name: "Humarock, MA",
    city_name: "Humarock",
  },
  {
    postal_code: "01260",
    full_city_name: "South Lee, MA",
    city_name: "South Lee",
  },
  {
    postal_code: "26611",
    full_city_name: "Cedarville, WV",
    city_name: "Cedarville",
  },
  {
    postal_code: "79261",
    full_city_name: "Turkey, TX",
    city_name: "Turkey",
  },
  {
    postal_code: "17836",
    full_city_name: "Leck Kill, PA",
    city_name: "Leck Kill",
  },
  {
    postal_code: "99154",
    full_city_name: "Mohler, WA",
    city_name: "Mohler",
  },
  {
    postal_code: "58484",
    full_city_name: "Sutton, ND",
    city_name: "Sutton",
  },
  {
    postal_code: "41729",
    full_city_name: "Combs, KY",
    city_name: "Combs",
  },
  {
    postal_code: "16910",
    full_city_name: "Alba, PA",
    city_name: "Alba",
  },
  {
    postal_code: "57625",
    full_city_name: "Eagle Butte, SD",
    city_name: "Eagle Butte",
  },
  {
    postal_code: "64442",
    full_city_name: "Eagleville, MO",
    city_name: "Eagleville",
  },
  {
    postal_code: "80722",
    full_city_name: "Atwood, CO",
    city_name: "Atwood",
  },
  {
    postal_code: "58266",
    full_city_name: "Niagara, ND",
    city_name: "Niagara",
  },
  {
    postal_code: "57468",
    full_city_name: "Pierpont, SD",
    city_name: "Pierpont",
  },
  {
    postal_code: "73043",
    full_city_name: "Greenfield, OK",
    city_name: "Greenfield",
  },
  {
    postal_code: "36008",
    full_city_name: "Booth, AL",
    city_name: "Booth",
  },
  {
    postal_code: "73097",
    full_city_name: "Wheatland, OK",
    city_name: "Wheatland",
  },
  {
    postal_code: "99726",
    full_city_name: "Bettles Field, AK",
    city_name: "Bettles Field",
  },
  {
    postal_code: "89425",
    full_city_name: "Orovada, NV",
    city_name: "Orovada",
  },
  {
    postal_code: "97817",
    full_city_name: "Bates, OR",
    city_name: "Bates",
  },
  {
    postal_code: "68773",
    full_city_name: "Royal, NE",
    city_name: "Royal",
  },
  {
    postal_code: "57620",
    full_city_name: "Bison, SD",
    city_name: "Bison",
  },
  {
    postal_code: "45688",
    full_city_name: "Waterloo, OH",
    city_name: "Waterloo",
  },
  {
    postal_code: "51345",
    full_city_name: "Harris, IA",
    city_name: "Harris",
  },
  {
    postal_code: "56519",
    full_city_name: "Borup, MN",
    city_name: "Borup",
  },
  {
    postal_code: "56325",
    full_city_name: "Elrosa, MN",
    city_name: "Elrosa",
  },
  {
    postal_code: "25047",
    full_city_name: "Clothier, WV",
    city_name: "Clothier",
  },
  {
    postal_code: "24815",
    full_city_name: "Berwind, WV",
    city_name: "Berwind",
  },
  {
    postal_code: "01084",
    full_city_name: "West Chesterfield, MA",
    city_name: "West Chesterfield",
  },
  {
    postal_code: "50567",
    full_city_name: "Nemaha, IA",
    city_name: "Nemaha",
  },
  {
    postal_code: "64438",
    full_city_name: "Darlington, MO",
    city_name: "Darlington",
  },
  {
    postal_code: "58377",
    full_city_name: "Starkweather, ND",
    city_name: "Starkweather",
  },
  {
    postal_code: "16850",
    full_city_name: "Lecontes Mills, PA",
    city_name: "Lecontes Mills",
  },
  {
    postal_code: "61416",
    full_city_name: "Bardolph, IL",
    city_name: "Bardolph",
  },
  {
    postal_code: "79846",
    full_city_name: "Redford, TX",
    city_name: "Redford",
  },
  {
    postal_code: "17968",
    full_city_name: "Sacramento, PA",
    city_name: "Sacramento",
  },
  {
    postal_code: "58362",
    full_city_name: "Penn, ND",
    city_name: "Penn",
  },
  {
    postal_code: "72578",
    full_city_name: "Sturkie, AR",
    city_name: "Sturkie",
  },
  {
    postal_code: "45826",
    full_city_name: "Chickasaw, OH",
    city_name: "Chickasaw",
  },
  {
    postal_code: "31551",
    full_city_name: "Mershon, GA",
    city_name: "Mershon",
  },
  {
    postal_code: "62879",
    full_city_name: "Sailor Springs, IL",
    city_name: "Sailor Springs",
  },
  {
    postal_code: "51630",
    full_city_name: "Blanchard, IA",
    city_name: "Blanchard",
  },
  {
    postal_code: "62475",
    full_city_name: "West Liberty, IL",
    city_name: "West Liberty",
  },
  {
    postal_code: "43322",
    full_city_name: "Green Camp, OH",
    city_name: "Green Camp",
  },
  {
    postal_code: "57553",
    full_city_name: "Milesville, SD",
    city_name: "Milesville",
  },
  {
    postal_code: "51562",
    full_city_name: "Panama, IA",
    city_name: "Panama",
  },
  {
    postal_code: "56731",
    full_city_name: "Humboldt, MN",
    city_name: "Humboldt",
  },
  {
    postal_code: "85709",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "37733",
    full_city_name: "Rugby, TN",
    city_name: "Rugby",
  },
  {
    postal_code: "47420",
    full_city_name: "Avoca, IN",
    city_name: "Avoca",
  },
  {
    postal_code: "76490",
    full_city_name: "Whitt, TX",
    city_name: "Whitt",
  },
  {
    postal_code: "43010",
    full_city_name: "Catawba, OH",
    city_name: "Catawba",
  },
  {
    postal_code: "80822",
    full_city_name: "Joes, CO",
    city_name: "Joes",
  },
  {
    postal_code: "68858",
    full_city_name: "Miller, NE",
    city_name: "Miller",
  },
  {
    postal_code: "68429",
    full_city_name: "Reynolds, NE",
    city_name: "Reynolds",
  },
  {
    postal_code: "67417",
    full_city_name: "Aurora, KS",
    city_name: "Aurora",
  },
  {
    postal_code: "30597",
    full_city_name: "Dahlonega, GA",
    city_name: "Dahlonega",
  },
  {
    postal_code: "39113",
    full_city_name: "Mayersville, MS",
    city_name: "Mayersville",
  },
  {
    postal_code: "57236",
    full_city_name: "Garden City, SD",
    city_name: "Garden City",
  },
  {
    postal_code: "57473",
    full_city_name: "Seneca, SD",
    city_name: "Seneca",
  },
  {
    postal_code: "82243",
    full_city_name: "Veteran, WY",
    city_name: "Veteran",
  },
  {
    postal_code: "64682",
    full_city_name: "Tina, MO",
    city_name: "Tina",
  },
  {
    postal_code: "39322",
    full_city_name: "Buckatunna, MS",
    city_name: "Buckatunna",
  },
  {
    postal_code: "47997",
    full_city_name: "Yeoman, IN",
    city_name: "Yeoman",
  },
  {
    postal_code: "73646",
    full_city_name: "Fay, OK",
    city_name: "Fay",
  },
  {
    postal_code: "99751",
    full_city_name: "Kobuk, AK",
    city_name: "Kobuk",
  },
  {
    postal_code: "58762",
    full_city_name: "Newburg, ND",
    city_name: "Newburg",
  },
  {
    postal_code: "68726",
    full_city_name: "Clearwater, NE",
    city_name: "Clearwater",
  },
  {
    postal_code: "66850",
    full_city_name: "Elmdale, KS",
    city_name: "Elmdale",
  },
  {
    postal_code: "58627",
    full_city_name: "Fairfield, ND",
    city_name: "Fairfield",
  },
  {
    postal_code: "68734",
    full_city_name: "Emmet, NE",
    city_name: "Emmet",
  },
  {
    postal_code: "73933",
    full_city_name: "Boise City, OK",
    city_name: "Boise City",
  },
  {
    postal_code: "31081",
    full_city_name: "Rupert, GA",
    city_name: "Rupert",
  },
  {
    postal_code: "58212",
    full_city_name: "Aneta, ND",
    city_name: "Aneta",
  },
  {
    postal_code: "59050",
    full_city_name: "Lodge Grass, MT",
    city_name: "Lodge Grass",
  },
  {
    postal_code: "64457",
    full_city_name: "Guilford, MO",
    city_name: "Guilford",
  },
  {
    postal_code: "63345",
    full_city_name: "Farber, MO",
    city_name: "Farber",
  },
  {
    postal_code: "95970",
    full_city_name: "Princeton, CA",
    city_name: "Princeton",
  },
  {
    postal_code: "71642",
    full_city_name: "Fountain Hill, AR",
    city_name: "Fountain Hill",
  },
  {
    postal_code: "99621",
    full_city_name: "Kwethluk, AK",
    city_name: "Kwethluk",
  },
  {
    postal_code: "40161",
    full_city_name: "Rhodelia, KY",
    city_name: "Rhodelia",
  },
  {
    postal_code: "56583",
    full_city_name: "Tintah, MN",
    city_name: "Tintah",
  },
  {
    postal_code: "01074",
    full_city_name: "South Barre, MA",
    city_name: "South Barre",
  },
  {
    postal_code: "68789",
    full_city_name: "Winnetoon, NE",
    city_name: "Winnetoon",
  },
  {
    postal_code: "68871",
    full_city_name: "Rockville, NE",
    city_name: "Rockville",
  },
  {
    postal_code: "78949",
    full_city_name: "Muldoon, TX",
    city_name: "Muldoon",
  },
  {
    postal_code: "59039",
    full_city_name: "Ingomar, MT",
    city_name: "Ingomar",
  },
  {
    postal_code: "50128",
    full_city_name: "Jamaica, IA",
    city_name: "Jamaica",
  },
  {
    postal_code: "66711",
    full_city_name: "Arcadia, KS",
    city_name: "Arcadia",
  },
  {
    postal_code: "75441",
    full_city_name: "Enloe, TX",
    city_name: "Enloe",
  },
  {
    postal_code: "26228",
    full_city_name: "Kanawha Head, WV",
    city_name: "Kanawha Head",
  },
  {
    postal_code: "41534",
    full_city_name: "Hellier, KY",
    city_name: "Hellier",
  },
  {
    postal_code: "68667",
    full_city_name: "Surprise, NE",
    city_name: "Surprise",
  },
  {
    postal_code: "47964",
    full_city_name: "Mount Ayr, IN",
    city_name: "Mount Ayr",
  },
  {
    postal_code: "25547",
    full_city_name: "Pecks Mill, WV",
    city_name: "Pecks Mill",
  },
  {
    postal_code: "79056",
    full_city_name: "Lipscomb, TX",
    city_name: "Lipscomb",
  },
  {
    postal_code: "58311",
    full_city_name: "Alsen, ND",
    city_name: "Alsen",
  },
  {
    postal_code: "88424",
    full_city_name: "Grenville, NM",
    city_name: "Grenville",
  },
  {
    postal_code: "68327",
    full_city_name: "Chester, NE",
    city_name: "Chester",
  },
  {
    postal_code: "68735",
    full_city_name: "Ewing, NE",
    city_name: "Ewing",
  },
  {
    postal_code: "68844",
    full_city_name: "Hazard, NE",
    city_name: "Hazard",
  },
  {
    postal_code: "89830",
    full_city_name: "Montello, NV",
    city_name: "Montello",
  },
  {
    postal_code: "45657",
    full_city_name: "Otway, OH",
    city_name: "Otway",
  },
  {
    postal_code: "49688",
    full_city_name: "Tustin, MI",
    city_name: "Tustin",
  },
  {
    postal_code: "74531",
    full_city_name: "Calvin, OK",
    city_name: "Calvin",
  },
  {
    postal_code: "44048",
    full_city_name: "Kingsville, OH",
    city_name: "Kingsville",
  },
  {
    postal_code: "74831",
    full_city_name: "Byars, OK",
    city_name: "Byars",
  },
  {
    postal_code: "44068",
    full_city_name: "North Kingsville, OH",
    city_name: "North Kingsville",
  },
  {
    postal_code: "95720",
    full_city_name: "Kyburz, CA",
    city_name: "Kyburz",
  },
  {
    postal_code: "15007",
    full_city_name: "Bakerstown, PA",
    city_name: "Bakerstown",
  },
  {
    postal_code: "26230",
    full_city_name: "Pickens, WV",
    city_name: "Pickens",
  },
  {
    postal_code: "68635",
    full_city_name: "Dwight, NE",
    city_name: "Dwight",
  },
  {
    postal_code: "18230",
    full_city_name: "Junedale, PA",
    city_name: "Junedale",
  },
  {
    postal_code: "87522",
    full_city_name: "Chimayo, NM",
    city_name: "Chimayo",
  },
  {
    postal_code: "31624",
    full_city_name: "Axson, GA",
    city_name: "Axson",
  },
  {
    postal_code: "17239",
    full_city_name: "Neelyton, PA",
    city_name: "Neelyton",
  },
  {
    postal_code: "30428",
    full_city_name: "Glenwood, GA",
    city_name: "Glenwood",
  },
  {
    postal_code: "58043",
    full_city_name: "Havana, ND",
    city_name: "Havana",
  },
  {
    postal_code: "99640",
    full_city_name: "Nondalton, AK",
    city_name: "Nondalton",
  },
  {
    postal_code: "68732",
    full_city_name: "Dixon, NE",
    city_name: "Dixon",
  },
  {
    postal_code: "52534",
    full_city_name: "Beacon, IA",
    city_name: "Beacon",
  },
  {
    postal_code: "99124",
    full_city_name: "Elmer City, WA",
    city_name: "Elmer City",
  },
  {
    postal_code: "67446",
    full_city_name: "Glen Elder, KS",
    city_name: "Glen Elder",
  },
  {
    postal_code: "75157",
    full_city_name: "Rosser, TX",
    city_name: "Rosser",
  },
  {
    postal_code: "03291",
    full_city_name: "West Nottingham, NH",
    city_name: "West Nottingham",
  },
  {
    postal_code: "23691",
    full_city_name: "Yorktown, VA",
    city_name: "Yorktown",
  },
  {
    postal_code: "51244",
    full_city_name: "Matlock, IA",
    city_name: "Matlock",
  },
  {
    postal_code: "85721",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "24731",
    full_city_name: "Kegley, WV",
    city_name: "Kegley",
  },
  {
    postal_code: "18622",
    full_city_name: "Huntington Mills, PA",
    city_name: "Huntington Mills",
  },
  {
    postal_code: "18084",
    full_city_name: "Sumneytown, PA",
    city_name: "Sumneytown",
  },
  {
    postal_code: "50272",
    full_city_name: "Williamson, IA",
    city_name: "Williamson",
  },
  {
    postal_code: "48320",
    full_city_name: "Keego Harbor, MI",
    city_name: "Keego Harbor",
  },
  {
    postal_code: "04239",
    full_city_name: "Jay, ME",
    city_name: "Jay",
  },
  {
    postal_code: "67065",
    full_city_name: "Isabel, KS",
    city_name: "Isabel",
  },
  {
    postal_code: "31065",
    full_city_name: "Montrose, GA",
    city_name: "Montrose",
  },
  {
    postal_code: "15465",
    full_city_name: "Mount Braddock, PA",
    city_name: "Mount Braddock",
  },
  {
    postal_code: "39326",
    full_city_name: "Daleville, MS",
    city_name: "Daleville",
  },
  {
    postal_code: "71019",
    full_city_name: "Coushatta, LA",
    city_name: "Coushatta",
  },
  {
    postal_code: "59848",
    full_city_name: "Lonepine, MT",
    city_name: "Lonepine",
  },
  {
    postal_code: "60960",
    full_city_name: "Rankin, IL",
    city_name: "Rankin",
  },
  {
    postal_code: "14208",
    full_city_name: "Buffalo, NY",
    city_name: "Buffalo",
  },
  {
    postal_code: "12864",
    full_city_name: "Sabael, NY",
    city_name: "Sabael",
  },
  {
    postal_code: "38585",
    full_city_name: "Spencer, TN",
    city_name: "Spencer",
  },
  {
    postal_code: "46930",
    full_city_name: "Fowlerton, IN",
    city_name: "Fowlerton",
  },
  {
    postal_code: "69212",
    full_city_name: "Crookston, NE",
    city_name: "Crookston",
  },
  {
    postal_code: "50569",
    full_city_name: "Otho, IA",
    city_name: "Otho",
  },
  {
    postal_code: "01066",
    full_city_name: "North Hatfield, MA",
    city_name: "North Hatfield",
  },
  {
    postal_code: "25845",
    full_city_name: "Glen Fork, WV",
    city_name: "Glen Fork",
  },
  {
    postal_code: "04280",
    full_city_name: "Sabattus, ME",
    city_name: "Sabattus",
  },
  {
    postal_code: "73638",
    full_city_name: "Crawford, OK",
    city_name: "Crawford",
  },
  {
    postal_code: "41408",
    full_city_name: "Cannel City, KY",
    city_name: "Cannel City",
  },
  {
    postal_code: "28310",
    full_city_name: "Fort Bragg, NC",
    city_name: "Fort Bragg",
  },
  {
    postal_code: "58216",
    full_city_name: "Bathgate, ND",
    city_name: "Bathgate",
  },
  {
    postal_code: "25160",
    full_city_name: "Pond Gap, WV",
    city_name: "Pond Gap",
  },
  {
    postal_code: "15362",
    full_city_name: "Spraggs, PA",
    city_name: "Spraggs",
  },
  {
    postal_code: "76155",
    full_city_name: "Fort Worth, TX",
    city_name: "Fort Worth",
  },
  {
    postal_code: "44659",
    full_city_name: "Mount Eaton, OH",
    city_name: "Mount Eaton",
  },
  {
    postal_code: "67627",
    full_city_name: "Catharine, KS",
    city_name: "Catharine",
  },
  {
    postal_code: "39189",
    full_city_name: "Walnut Grove, MS",
    city_name: "Walnut Grove",
  },
  {
    postal_code: "16212",
    full_city_name: "Cadogan, PA",
    city_name: "Cadogan",
  },
  {
    postal_code: "15449",
    full_city_name: "Keisterville, PA",
    city_name: "Keisterville",
  },
  {
    postal_code: "55933",
    full_city_name: "Elkton, MN",
    city_name: "Elkton",
  },
  {
    postal_code: "81047",
    full_city_name: "Holly, CO",
    city_name: "Holly",
  },
  {
    postal_code: "56291",
    full_city_name: "Taunton, MN",
    city_name: "Taunton",
  },
  {
    postal_code: "68943",
    full_city_name: "Hardy, NE",
    city_name: "Hardy",
  },
  {
    postal_code: "50862",
    full_city_name: "Sharpsburg, IA",
    city_name: "Sharpsburg",
  },
  {
    postal_code: "64092",
    full_city_name: "Waldron, MO",
    city_name: "Waldron",
  },
  {
    postal_code: "99011",
    full_city_name: "Fairchild Air Force Base, WA",
    city_name: "Fairchild Air Force Base",
  },
  {
    postal_code: "43414",
    full_city_name: "Dunbridge, OH",
    city_name: "Dunbridge",
  },
  {
    postal_code: "93512",
    full_city_name: "Benton, CA",
    city_name: "Benton",
  },
  {
    postal_code: "64654",
    full_city_name: "Lock Springs, MO",
    city_name: "Lock Springs",
  },
  {
    postal_code: "57214",
    full_city_name: "Badger, SD",
    city_name: "Badger",
  },
  {
    postal_code: "67510",
    full_city_name: "Abbyville, KS",
    city_name: "Abbyville",
  },
  {
    postal_code: "13087",
    full_city_name: "Little York, NY",
    city_name: "Little York",
  },
  {
    postal_code: "41367",
    full_city_name: "Rowdy, KY",
    city_name: "Rowdy",
  },
  {
    postal_code: "45864",
    full_city_name: "Miller City, OH",
    city_name: "Miller City",
  },
  {
    postal_code: "62436",
    full_city_name: "Jewett, IL",
    city_name: "Jewett",
  },
  {
    postal_code: "47247",
    full_city_name: "Jonesville, IN",
    city_name: "Jonesville",
  },
  {
    postal_code: "71406",
    full_city_name: "Belmont, LA",
    city_name: "Belmont",
  },
  {
    postal_code: "45888",
    full_city_name: "Uniopolis, OH",
    city_name: "Uniopolis",
  },
  {
    postal_code: "50455",
    full_city_name: "Mc Intire, IA",
    city_name: "Mc Intire",
  },
  {
    postal_code: "52727",
    full_city_name: "Bryant, IA",
    city_name: "Bryant",
  },
  {
    postal_code: "50664",
    full_city_name: "Oran, IA",
    city_name: "Oran",
  },
  {
    postal_code: "74117",
    full_city_name: "Tulsa, OK",
    city_name: "Tulsa",
  },
  {
    postal_code: "98357",
    full_city_name: "Neah Bay, WA",
    city_name: "Neah Bay",
  },
  {
    postal_code: "68458",
    full_city_name: "Virginia, NE",
    city_name: "Virginia",
  },
  {
    postal_code: "50078",
    full_city_name: "Ferguson, IA",
    city_name: "Ferguson",
  },
  {
    postal_code: "98939",
    full_city_name: "Parker, WA",
    city_name: "Parker",
  },
  {
    postal_code: "58422",
    full_city_name: "Cathay, ND",
    city_name: "Cathay",
  },
  {
    postal_code: "59735",
    full_city_name: "Harrison, MT",
    city_name: "Harrison",
  },
  {
    postal_code: "53942",
    full_city_name: "Lime Ridge, WI",
    city_name: "Lime Ridge",
  },
  {
    postal_code: "51231",
    full_city_name: "Archer, IA",
    city_name: "Archer",
  },
  {
    postal_code: "51647",
    full_city_name: "Northboro, IA",
    city_name: "Northboro",
  },
  {
    postal_code: "64072",
    full_city_name: "Missouri City, MO",
    city_name: "Missouri City",
  },
  {
    postal_code: "52548",
    full_city_name: "Chillicothe, IA",
    city_name: "Chillicothe",
  },
  {
    postal_code: "68348",
    full_city_name: "Elk Creek, NE",
    city_name: "Elk Creek",
  },
  {
    postal_code: "40939",
    full_city_name: "Fourmile, KY",
    city_name: "Fourmile",
  },
  {
    postal_code: "17027",
    full_city_name: "Grantham, PA",
    city_name: "Grantham",
  },
  {
    postal_code: "57061",
    full_city_name: "Sinai, SD",
    city_name: "Sinai",
  },
  {
    postal_code: "58486",
    full_city_name: "Sykeston, ND",
    city_name: "Sykeston",
  },
  {
    postal_code: "64433",
    full_city_name: "Conception, MO",
    city_name: "Conception",
  },
  {
    postal_code: "68955",
    full_city_name: "Juniata, NE",
    city_name: "Juniata",
  },
  {
    postal_code: "30438",
    full_city_name: "Manassas, GA",
    city_name: "Manassas",
  },
  {
    postal_code: "95627",
    full_city_name: "Esparto, CA",
    city_name: "Esparto",
  },
  {
    postal_code: "96115",
    full_city_name: "Lake City, CA",
    city_name: "Lake City",
  },
  {
    postal_code: "61564",
    full_city_name: "South Pekin, IL",
    city_name: "South Pekin",
  },
  {
    postal_code: "12168",
    full_city_name: "Stephentown, NY",
    city_name: "Stephentown",
  },
  {
    postal_code: "12854",
    full_city_name: "North Granville, NY",
    city_name: "North Granville",
  },
  {
    postal_code: "13795",
    full_city_name: "Kirkwood, NY",
    city_name: "Kirkwood",
  },
  {
    postal_code: "13491",
    full_city_name: "West Winfield, NY",
    city_name: "West Winfield",
  },
  {
    postal_code: "28385",
    full_city_name: "Salemburg, NC",
    city_name: "Salemburg",
  },
  {
    postal_code: "63460",
    full_city_name: "Novelty, MO",
    city_name: "Novelty",
  },
  {
    postal_code: "05101",
    full_city_name: "Bellows Falls, VT",
    city_name: "Bellows Falls",
  },
  {
    postal_code: "06232",
    full_city_name: "Andover, CT",
    city_name: "Andover",
  },
  {
    postal_code: "75452",
    full_city_name: "Leonard, TX",
    city_name: "Leonard",
  },
  {
    postal_code: "33538",
    full_city_name: "Lake Panasoffkee, FL",
    city_name: "Lake Panasoffkee",
  },
  {
    postal_code: "43449",
    full_city_name: "Oak Harbor, OH",
    city_name: "Oak Harbor",
  },
  {
    postal_code: "76233",
    full_city_name: "Collinsville, TX",
    city_name: "Collinsville",
  },
  {
    postal_code: "62346",
    full_city_name: "La Prairie, IL",
    city_name: "La Prairie",
  },
  {
    postal_code: "56553",
    full_city_name: "Kent, MN",
    city_name: "Kent",
  },
  {
    postal_code: "43536",
    full_city_name: "Mark Center, OH",
    city_name: "Mark Center",
  },
  {
    postal_code: "30332",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "44670",
    full_city_name: "Robertsville, OH",
    city_name: "Robertsville",
  },
  {
    postal_code: "66939",
    full_city_name: "Courtland, KS",
    city_name: "Courtland",
  },
  {
    postal_code: "17534",
    full_city_name: "Intercourse, PA",
    city_name: "Intercourse",
  },
  {
    postal_code: "16682",
    full_city_name: "Sproul, PA",
    city_name: "Sproul",
  },
  {
    postal_code: "57534",
    full_city_name: "Hamill, SD",
    city_name: "Hamill",
  },
  {
    postal_code: "73642",
    full_city_name: "Durham, OK",
    city_name: "Durham",
  },
  {
    postal_code: "41760",
    full_city_name: "Scuddy, KY",
    city_name: "Scuddy",
  },
  {
    postal_code: "63450",
    full_city_name: "Lentner, MO",
    city_name: "Lentner",
  },
  {
    postal_code: "99613",
    full_city_name: "King Salmon, AK",
    city_name: "King Salmon",
  },
  {
    postal_code: "88036",
    full_city_name: "Fort Bayard, NM",
    city_name: "Fort Bayard",
  },
  {
    postal_code: "71375",
    full_city_name: "Waterproof, LA",
    city_name: "Waterproof",
  },
  {
    postal_code: "44639",
    full_city_name: "Leesville, OH",
    city_name: "Leesville",
  },
  {
    postal_code: "13826",
    full_city_name: "Ouaquaga, NY",
    city_name: "Ouaquaga",
  },
  {
    postal_code: "58353",
    full_city_name: "Mylo, ND",
    city_name: "Mylo",
  },
  {
    postal_code: "68440",
    full_city_name: "Steele City, NE",
    city_name: "Steele City",
  },
  {
    postal_code: "78371",
    full_city_name: "Oilton, TX",
    city_name: "Oilton",
  },
  {
    postal_code: "57076",
    full_city_name: "Winfred, SD",
    city_name: "Winfred",
  },
  {
    postal_code: "66728",
    full_city_name: "Crestline, KS",
    city_name: "Crestline",
  },
  {
    postal_code: "87750",
    full_city_name: "Valmora, NM",
    city_name: "Valmora",
  },
  {
    postal_code: "04019",
    full_city_name: "Cliff Island, ME",
    city_name: "Cliff Island",
  },
  {
    postal_code: "69211",
    full_city_name: "Cody, NE",
    city_name: "Cody",
  },
  {
    postal_code: "52036",
    full_city_name: "Delaware, IA",
    city_name: "Delaware",
  },
  {
    postal_code: "97336",
    full_city_name: "Crawfordsville, OR",
    city_name: "Crawfordsville",
  },
  {
    postal_code: "08556",
    full_city_name: "Rosemont, NJ",
    city_name: "Rosemont",
  },
  {
    postal_code: "58224",
    full_city_name: "Dahlen, ND",
    city_name: "Dahlen",
  },
  {
    postal_code: "26348",
    full_city_name: "Folsom, WV",
    city_name: "Folsom",
  },
  {
    postal_code: "86023",
    full_city_name: "Grand Canyon, AZ",
    city_name: "Grand Canyon",
  },
  {
    postal_code: "78951",
    full_city_name: "Oakland, TX",
    city_name: "Oakland",
  },
  {
    postal_code: "22741",
    full_city_name: "Stevensburg, VA",
    city_name: "Stevensburg",
  },
  {
    postal_code: "57646",
    full_city_name: "Mound City, SD",
    city_name: "Mound City",
  },
  {
    postal_code: "52312",
    full_city_name: "Morley, IA",
    city_name: "Morley",
  },
  {
    postal_code: "26561",
    full_city_name: "Big Run, WV",
    city_name: "Big Run",
  },
  {
    postal_code: "58219",
    full_city_name: "Caledonia, ND",
    city_name: "Caledonia",
  },
  {
    postal_code: "41721",
    full_city_name: "Buckhorn, KY",
    city_name: "Buckhorn",
  },
  {
    postal_code: "68817",
    full_city_name: "Ashton, NE",
    city_name: "Ashton",
  },
  {
    postal_code: "64486",
    full_city_name: "Sheridan, MO",
    city_name: "Sheridan",
  },
  {
    postal_code: "16416",
    full_city_name: "Garland, PA",
    city_name: "Garland",
  },
  {
    postal_code: "87060",
    full_city_name: "Tome, NM",
    city_name: "Tome",
  },
  {
    postal_code: "15720",
    full_city_name: "Brush Valley, PA",
    city_name: "Brush Valley",
  },
  {
    postal_code: "77440",
    full_city_name: "Elmaton, TX",
    city_name: "Elmaton",
  },
  {
    postal_code: "65286",
    full_city_name: "Triplett, MO",
    city_name: "Triplett",
  },
  {
    postal_code: "68842",
    full_city_name: "Greeley, NE",
    city_name: "Greeley",
  },
  {
    postal_code: "70436",
    full_city_name: "Fluker, LA",
    city_name: "Fluker",
  },
  {
    postal_code: "42354",
    full_city_name: "Mc Henry, KY",
    city_name: "Mc Henry",
  },
  {
    postal_code: "58062",
    full_city_name: "Nome, ND",
    city_name: "Nome",
  },
  {
    postal_code: "52050",
    full_city_name: "Greeley, IA",
    city_name: "Greeley",
  },
  {
    postal_code: "79718",
    full_city_name: "Balmorhea, TX",
    city_name: "Balmorhea",
  },
  {
    postal_code: "80225",
    full_city_name: "Denver, CO",
    city_name: "Denver",
  },
  {
    postal_code: "79540",
    full_city_name: "Old Glory, TX",
    city_name: "Old Glory",
  },
  {
    postal_code: "99555",
    full_city_name: "Aleknagik, AK",
    city_name: "Aleknagik",
  },
  {
    postal_code: "93276",
    full_city_name: "Tupman, CA",
    city_name: "Tupman",
  },
  {
    postal_code: "31905",
    full_city_name: "Fort Benning, GA",
    city_name: "Fort Benning",
  },
  {
    postal_code: "42788",
    full_city_name: "White Mills, KY",
    city_name: "White Mills",
  },
  {
    postal_code: "82217",
    full_city_name: "Hawk Springs, WY",
    city_name: "Hawk Springs",
  },
  {
    postal_code: "52573",
    full_city_name: "Mount Sterling, IA",
    city_name: "Mount Sterling",
  },
  {
    postal_code: "70654",
    full_city_name: "Mittie, LA",
    city_name: "Mittie",
  },
  {
    postal_code: "57767",
    full_city_name: "Owanka, SD",
    city_name: "Owanka",
  },
  {
    postal_code: "52566",
    full_city_name: "Kirkville, IA",
    city_name: "Kirkville",
  },
  {
    postal_code: "26638",
    full_city_name: "Shock, WV",
    city_name: "Shock",
  },
  {
    postal_code: "68042",
    full_city_name: "Memphis, NE",
    city_name: "Memphis",
  },
  {
    postal_code: "97312",
    full_city_name: "Salem, OR",
    city_name: "Salem",
  },
  {
    postal_code: "99782",
    full_city_name: "Wainwright, AK",
    city_name: "Wainwright",
  },
  {
    postal_code: "47556",
    full_city_name: "Mariah Hill, IN",
    city_name: "Mariah Hill",
  },
  {
    postal_code: "16659",
    full_city_name: "Loysburg, PA",
    city_name: "Loysburg",
  },
  {
    postal_code: "41825",
    full_city_name: "Jackhorn, KY",
    city_name: "Jackhorn",
  },
  {
    postal_code: "46508",
    full_city_name: "Burket, IN",
    city_name: "Burket",
  },
  {
    postal_code: "74576",
    full_city_name: "Wardville, OK",
    city_name: "Wardville",
  },
  {
    postal_code: "65048",
    full_city_name: "Koeltztown, MO",
    city_name: "Koeltztown",
  },
  {
    postal_code: "51447",
    full_city_name: "Kirkman, IA",
    city_name: "Kirkman",
  },
  {
    postal_code: "82510",
    full_city_name: "Arapahoe, WY",
    city_name: "Arapahoe",
  },
  {
    postal_code: "67023",
    full_city_name: "Cambridge, KS",
    city_name: "Cambridge",
  },
  {
    postal_code: "58440",
    full_city_name: "Fredonia, ND",
    city_name: "Fredonia",
  },
  {
    postal_code: "73747",
    full_city_name: "Isabella, OK",
    city_name: "Isabella",
  },
  {
    postal_code: "59258",
    full_city_name: "Reserve, MT",
    city_name: "Reserve",
  },
  {
    postal_code: "68759",
    full_city_name: "Newport, NE",
    city_name: "Newport",
  },
  {
    postal_code: "99564",
    full_city_name: "Chignik, AK",
    city_name: "Chignik",
  },
  {
    postal_code: "52771",
    full_city_name: "Teeds Grove, IA",
    city_name: "Teeds Grove",
  },
  {
    postal_code: "50552",
    full_city_name: "Knierim, IA",
    city_name: "Knierim",
  },
  {
    postal_code: "72339",
    full_city_name: "Gilmore, AR",
    city_name: "Gilmore",
  },
  {
    postal_code: "58313",
    full_city_name: "Balta, ND",
    city_name: "Balta",
  },
  {
    postal_code: "36550",
    full_city_name: "Little River, AL",
    city_name: "Little River",
  },
  {
    postal_code: "31098",
    full_city_name: "Warner Robins, GA",
    city_name: "Warner Robins",
  },
  {
    postal_code: "58281",
    full_city_name: "Wales, ND",
    city_name: "Wales",
  },
  {
    postal_code: "58448",
    full_city_name: "Hannaford, ND",
    city_name: "Hannaford",
  },
  {
    postal_code: "67675",
    full_city_name: "Woodston, KS",
    city_name: "Woodston",
  },
  {
    postal_code: "31315",
    full_city_name: "Fort Stewart, GA",
    city_name: "Fort Stewart",
  },
  {
    postal_code: "69041",
    full_city_name: "Parks, NE",
    city_name: "Parks",
  },
  {
    postal_code: "68964",
    full_city_name: "Oak, NE",
    city_name: "Oak",
  },
  {
    postal_code: "58386",
    full_city_name: "York, ND",
    city_name: "York",
  },
  {
    postal_code: "18430",
    full_city_name: "Herrick Center, PA",
    city_name: "Herrick Center",
  },
  {
    postal_code: "59077",
    full_city_name: "Sand Springs, MT",
    city_name: "Sand Springs",
  },
  {
    postal_code: "56313",
    full_city_name: "Bock, MN",
    city_name: "Bock",
  },
  {
    postal_code: "72313",
    full_city_name: "Bassett, AR",
    city_name: "Bassett",
  },
  {
    postal_code: "88343",
    full_city_name: "Picacho, NM",
    city_name: "Picacho",
  },
  {
    postal_code: "73437",
    full_city_name: "Graham, OK",
    city_name: "Graham",
  },
  {
    postal_code: "30334",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "68719",
    full_city_name: "Bristow, NE",
    city_name: "Bristow",
  },
  {
    postal_code: "45721",
    full_city_name: "Coal Run, OH",
    city_name: "Coal Run",
  },
  {
    postal_code: "41352",
    full_city_name: "Mize, KY",
    city_name: "Mize",
  },
  {
    postal_code: "67643",
    full_city_name: "Jennings, KS",
    city_name: "Jennings",
  },
  {
    postal_code: "59276",
    full_city_name: "Whitetail, MT",
    city_name: "Whitetail",
  },
  {
    postal_code: "81231",
    full_city_name: "Gunnison, CO",
    city_name: "Gunnison",
  },
  {
    postal_code: "41612",
    full_city_name: "Bypro, KY",
    city_name: "Bypro",
  },
  {
    postal_code: "58343",
    full_city_name: "Knox, ND",
    city_name: "Knox",
  },
  {
    postal_code: "41848",
    full_city_name: "Roxana, KY",
    city_name: "Roxana",
  },
  {
    postal_code: "67012",
    full_city_name: "Beaumont, KS",
    city_name: "Beaumont",
  },
  {
    postal_code: "73855",
    full_city_name: "Rosston, OK",
    city_name: "Rosston",
  },
  {
    postal_code: "66442",
    full_city_name: "Fort Riley, KS",
    city_name: "Fort Riley",
  },
  {
    postal_code: "58838",
    full_city_name: "Cartwright, ND",
    city_name: "Cartwright",
  },
  {
    postal_code: "57538",
    full_city_name: "Herrick, SD",
    city_name: "Herrick",
  },
  {
    postal_code: "68972",
    full_city_name: "Riverton, NE",
    city_name: "Riverton",
  },
  {
    postal_code: "68974",
    full_city_name: "Ruskin, NE",
    city_name: "Ruskin",
  },
  {
    postal_code: "67155",
    full_city_name: "Wilmore, KS",
    city_name: "Wilmore",
  },
  {
    postal_code: "79535",
    full_city_name: "Maryneal, TX",
    city_name: "Maryneal",
  },
  {
    postal_code: "36754",
    full_city_name: "Magnolia, AL",
    city_name: "Magnolia",
  },
  {
    postal_code: "83235",
    full_city_name: "Ellis, ID",
    city_name: "Ellis",
  },
  {
    postal_code: "88133",
    full_city_name: "Saint Vrain, NM",
    city_name: "Saint Vrain",
  },
  {
    postal_code: "58013",
    full_city_name: "Cayuga, ND",
    city_name: "Cayuga",
  },
  {
    postal_code: "67109",
    full_city_name: "Mullinville, KS",
    city_name: "Mullinville",
  },
  {
    postal_code: "58355",
    full_city_name: "Nekoma, ND",
    city_name: "Nekoma",
  },
  {
    postal_code: "14720",
    full_city_name: "Celoron, NY",
    city_name: "Celoron",
  },
  {
    postal_code: "50269",
    full_city_name: "Whitten, IA",
    city_name: "Whitten",
  },
  {
    postal_code: "66404",
    full_city_name: "Baileyville, KS",
    city_name: "Baileyville",
  },
  {
    postal_code: "33199",
    full_city_name: "Miami, FL",
    city_name: "Miami",
  },
  {
    postal_code: "87528",
    full_city_name: "Dulce, NM",
    city_name: "Dulce",
  },
  {
    postal_code: "41725",
    full_city_name: "Carrie, KY",
    city_name: "Carrie",
  },
  {
    postal_code: "16430",
    full_city_name: "North Springfield, PA",
    city_name: "North Springfield",
  },
  {
    postal_code: "57656",
    full_city_name: "Timber Lake, SD",
    city_name: "Timber Lake",
  },
  {
    postal_code: "99565",
    full_city_name: "Chignik Lagoon, AK",
    city_name: "Chignik Lagoon",
  },
  {
    postal_code: "40818",
    full_city_name: "Coalgood, KY",
    city_name: "Coalgood",
  },
  {
    postal_code: "63879",
    full_city_name: "Wardell, MO",
    city_name: "Wardell",
  },
  {
    postal_code: "41849",
    full_city_name: "Seco, KY",
    city_name: "Seco",
  },
  {
    postal_code: "24536",
    full_city_name: "Coleman Falls, VA",
    city_name: "Coleman Falls",
  },
  {
    postal_code: "24869",
    full_city_name: "North Spring, WV",
    city_name: "North Spring",
  },
  {
    postal_code: "76940",
    full_city_name: "Mereta, TX",
    city_name: "Mereta",
  },
  {
    postal_code: "47916",
    full_city_name: "Alamo, IN",
    city_name: "Alamo",
  },
  {
    postal_code: "59933",
    full_city_name: "Stryker, MT",
    city_name: "Stryker",
  },
  {
    postal_code: "15484",
    full_city_name: "Uledi, PA",
    city_name: "Uledi",
  },
  {
    postal_code: "08544",
    full_city_name: "Princeton, NJ",
    city_name: "Princeton",
  },
  {
    postal_code: "50839",
    full_city_name: "Carbon, IA",
    city_name: "Carbon",
  },
  {
    postal_code: "71828",
    full_city_name: "Cale, AR",
    city_name: "Cale",
  },
  {
    postal_code: "28575",
    full_city_name: "Salter Path, NC",
    city_name: "Salter Path",
  },
  {
    postal_code: "57632",
    full_city_name: "Herreid, SD",
    city_name: "Herreid",
  },
  {
    postal_code: "40334",
    full_city_name: "Hope, KY",
    city_name: "Hope",
  },
  {
    postal_code: "50102",
    full_city_name: "Garden City, IA",
    city_name: "Garden City",
  },
  {
    postal_code: "97751",
    full_city_name: "Paulina, OR",
    city_name: "Paulina",
  },
  {
    postal_code: "32356",
    full_city_name: "Salem, FL",
    city_name: "Salem",
  },
  {
    postal_code: "65735",
    full_city_name: "Quincy, MO",
    city_name: "Quincy",
  },
  {
    postal_code: "98824",
    full_city_name: "George, WA",
    city_name: "George",
  },
  {
    postal_code: "87736",
    full_city_name: "Rainsville, NM",
    city_name: "Rainsville",
  },
  {
    postal_code: "64022",
    full_city_name: "Dover, MO",
    city_name: "Dover",
  },
  {
    postal_code: "57027",
    full_city_name: "Fairview, SD",
    city_name: "Fairview",
  },
  {
    postal_code: "57644",
    full_city_name: "Meadow, SD",
    city_name: "Meadow",
  },
  {
    postal_code: "54150",
    full_city_name: "Neopit, WI",
    city_name: "Neopit",
  },
  {
    postal_code: "52348",
    full_city_name: "Vining, IA",
    city_name: "Vining",
  },
  {
    postal_code: "88213",
    full_city_name: "Caprock, NM",
    city_name: "Caprock",
  },
  {
    postal_code: "63543",
    full_city_name: "Gorin, MO",
    city_name: "Gorin",
  },
  {
    postal_code: "23943",
    full_city_name: "Hampden Sydney, VA",
    city_name: "Hampden Sydney",
  },
  {
    postal_code: "79240",
    full_city_name: "Lelia Lake, TX",
    city_name: "Lelia Lake",
  },
  {
    postal_code: "64458",
    full_city_name: "Hatfield, MO",
    city_name: "Hatfield",
  },
  {
    postal_code: "20670",
    full_city_name: "Patuxent River, MD",
    city_name: "Patuxent River",
  },
  {
    postal_code: "51341",
    full_city_name: "Gillett Grove, IA",
    city_name: "Gillett Grove",
  },
  {
    postal_code: "82227",
    full_city_name: "Manville, WY",
    city_name: "Manville",
  },
  {
    postal_code: "51645",
    full_city_name: "Imogene, IA",
    city_name: "Imogene",
  },
  {
    postal_code: "79906",
    full_city_name: "El Paso, TX",
    city_name: "El Paso",
  },
  {
    postal_code: "83533",
    full_city_name: "Greencreek, ID",
    city_name: "Greencreek",
  },
  {
    postal_code: "25180",
    full_city_name: "Saxon, WV",
    city_name: "Saxon",
  },
  {
    postal_code: "80721",
    full_city_name: "Amherst, CO",
    city_name: "Amherst",
  },
  {
    postal_code: "49792",
    full_city_name: "Tower, MI",
    city_name: "Tower",
  },
  {
    postal_code: "92134",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "70352",
    full_city_name: "Donner, LA",
    city_name: "Donner",
  },
  {
    postal_code: "43330",
    full_city_name: "Kirby, OH",
    city_name: "Kirby",
  },
  {
    postal_code: "56720",
    full_city_name: "Donaldson, MN",
    city_name: "Donaldson",
  },
  {
    postal_code: "82630",
    full_city_name: "Arminto, WY",
    city_name: "Arminto",
  },
  {
    postal_code: "28633",
    full_city_name: "Lenoir, NC",
    city_name: "Lenoir",
  },
  {
    postal_code: "48242",
    full_city_name: "Detroit, MI",
    city_name: "Detroit",
  },
  {
    postal_code: "21402",
    full_city_name: "Annapolis, MD",
    city_name: "Annapolis",
  },
  {
    postal_code: "22904",
    full_city_name: "Charlottesville, VA",
    city_name: "Charlottesville",
  },
  {
    postal_code: "80862",
    full_city_name: "Wild Horse, CO",
    city_name: "Wild Horse",
  },
  {
    postal_code: "26289",
    full_city_name: "Red Creek, WV",
    city_name: "Red Creek",
  },
  {
    postal_code: "72384",
    full_city_name: "Turrell, AR",
    city_name: "Turrell",
  },
  {
    postal_code: "41632",
    full_city_name: "Gunlock, KY",
    city_name: "Gunlock",
  },
  {
    postal_code: "65042",
    full_city_name: "High Point, MO",
    city_name: "High Point",
  },
  {
    postal_code: "33965",
    full_city_name: "Fort Myers, FL",
    city_name: "Fort Myers",
  },
  {
    postal_code: "41566",
    full_city_name: "Steele, KY",
    city_name: "Steele",
  },
  {
    postal_code: "68954",
    full_city_name: "Inland, NE",
    city_name: "Inland",
  },
  {
    postal_code: "46967",
    full_city_name: "Onward, IN",
    city_name: "Onward",
  },
  {
    postal_code: "72465",
    full_city_name: "Sedgwick, AR",
    city_name: "Sedgwick",
  },
  {
    postal_code: "35457",
    full_city_name: "Echola, AL",
    city_name: "Echola",
  },
  {
    postal_code: "58338",
    full_city_name: "Hampden, ND",
    city_name: "Hampden",
  },
  {
    postal_code: "99136",
    full_city_name: "Hay, WA",
    city_name: "Hay",
  },
  {
    postal_code: "66859",
    full_city_name: "Lost Springs, KS",
    city_name: "Lost Springs",
  },
  {
    postal_code: "72424",
    full_city_name: "Datto, AR",
    city_name: "Datto",
  },
  {
    postal_code: "93666",
    full_city_name: "Sultana, CA",
    city_name: "Sultana",
  },
  {
    postal_code: "67418",
    full_city_name: "Barnard, KS",
    city_name: "Barnard",
  },
  {
    postal_code: "40982",
    full_city_name: "Scalf, KY",
    city_name: "Scalf",
  },
  {
    postal_code: "59240",
    full_city_name: "Glentana, MT",
    city_name: "Glentana",
  },
  {
    postal_code: "58426",
    full_city_name: "Courtenay, ND",
    city_name: "Courtenay",
  },
  {
    postal_code: "15764",
    full_city_name: "Oliveburg, PA",
    city_name: "Oliveburg",
  },
  {
    postal_code: "15760",
    full_city_name: "Marsteller, PA",
    city_name: "Marsteller",
  },
  {
    postal_code: "42223",
    full_city_name: "Fort Campbell, TN",
    city_name: "Fort Campbell",
  },
  {
    postal_code: "69171",
    full_city_name: "Willow Island, NE",
    city_name: "Willow Island",
  },
  {
    postal_code: "65657",
    full_city_name: "Garrison, MO",
    city_name: "Garrison",
  },
  {
    postal_code: "40808",
    full_city_name: "Big Laurel, KY",
    city_name: "Big Laurel",
  },
  {
    postal_code: "62809",
    full_city_name: "Barnhill, IL",
    city_name: "Barnhill",
  },
  {
    postal_code: "58477",
    full_city_name: "Regan, ND",
    city_name: "Regan",
  },
  {
    postal_code: "65728",
    full_city_name: "Ponce De Leon, MO",
    city_name: "Ponce De Leon",
  },
  {
    postal_code: "73744",
    full_city_name: "Hitchcock, OK",
    city_name: "Hitchcock",
  },
  {
    postal_code: "26215",
    full_city_name: "Cleveland, WV",
    city_name: "Cleveland",
  },
  {
    postal_code: "36849",
    full_city_name: "Auburn University, AL",
    city_name: "Auburn University",
  },
  {
    postal_code: "58464",
    full_city_name: "Mchenry, ND",
    city_name: "Mchenry",
  },
  {
    postal_code: "63432",
    full_city_name: "Arbela, MO",
    city_name: "Arbela",
  },
  {
    postal_code: "94535",
    full_city_name: "Travis Afb, CA",
    city_name: "Travis Afb",
  },
  {
    postal_code: "31409",
    full_city_name: "Savannah, GA",
    city_name: "Savannah",
  },
  {
    postal_code: "44640",
    full_city_name: "Limaville, OH",
    city_name: "Limaville",
  },
  {
    postal_code: "58345",
    full_city_name: "Lawton, ND",
    city_name: "Lawton",
  },
  {
    postal_code: "97712",
    full_city_name: "Brothers, OR",
    city_name: "Brothers",
  },
  {
    postal_code: "80735",
    full_city_name: "Idalia, CO",
    city_name: "Idalia",
  },
  {
    postal_code: "98364",
    full_city_name: "Port Gamble, WA",
    city_name: "Port Gamble",
  },
  {
    postal_code: "56591",
    full_city_name: "White Earth, MN",
    city_name: "White Earth",
  },
  {
    postal_code: "84322",
    full_city_name: "Logan, UT",
    city_name: "Logan",
  },
  {
    postal_code: "76061",
    full_city_name: "Lillian, TX",
    city_name: "Lillian",
  },
  {
    postal_code: "64102",
    full_city_name: "Kansas City, MO",
    city_name: "Kansas City",
  },
  {
    postal_code: "68923",
    full_city_name: "Atlanta, NE",
    city_name: "Atlanta",
  },
  {
    postal_code: "59244",
    full_city_name: "Larslan, MT",
    city_name: "Larslan",
  },
  {
    postal_code: "04683",
    full_city_name: "Sunset, ME",
    city_name: "Sunset",
  },
  {
    postal_code: "82434",
    full_city_name: "Otto, WY",
    city_name: "Otto",
  },
  {
    postal_code: "66863",
    full_city_name: "Neal, KS",
    city_name: "Neal",
  },
  {
    postal_code: "15776",
    full_city_name: "Sprankle Mills, PA",
    city_name: "Sprankle Mills",
  },
  {
    postal_code: "86020",
    full_city_name: "Cameron, AZ",
    city_name: "Cameron",
  },
  {
    postal_code: "90840",
    full_city_name: "Long Beach, CA",
    city_name: "Long Beach",
  },
  {
    postal_code: "73944",
    full_city_name: "Hardesty, OK",
    city_name: "Hardesty",
  },
  {
    postal_code: "67452",
    full_city_name: "Hunter, KS",
    city_name: "Hunter",
  },
  {
    postal_code: "71952",
    full_city_name: "Langley, AR",
    city_name: "Langley",
  },
  {
    postal_code: "59418",
    full_city_name: "Buffalo, MT",
    city_name: "Buffalo",
  },
  {
    postal_code: "58496",
    full_city_name: "Woodworth, ND",
    city_name: "Woodworth",
  },
  {
    postal_code: "26675",
    full_city_name: "Keslers Cross Lanes, WV",
    city_name: "Keslers Cross Lanes",
  },
  {
    postal_code: "68945",
    full_city_name: "Heartwell, NE",
    city_name: "Heartwell",
  },
  {
    postal_code: "72905",
    full_city_name: "Fort Smith, AR",
    city_name: "Fort Smith",
  },
  {
    postal_code: "57787",
    full_city_name: "Union Center, SD",
    city_name: "Union Center",
  },
  {
    postal_code: "58478",
    full_city_name: "Robinson, ND",
    city_name: "Robinson",
  },
  {
    postal_code: "30602",
    full_city_name: "Athens, GA",
    city_name: "Athens",
  },
  {
    postal_code: "83648",
    full_city_name: "Mountain Home Afb, ID",
    city_name: "Mountain Home Afb",
  },
  {
    postal_code: "71459",
    full_city_name: "Fort Polk, LA",
    city_name: "Fort Polk",
  },
  {
    postal_code: "58532",
    full_city_name: "Driscoll, ND",
    city_name: "Driscoll",
  },
  {
    postal_code: "59002",
    full_city_name: "Acton, MT",
    city_name: "Acton",
  },
  {
    postal_code: "58568",
    full_city_name: "Selfridge, ND",
    city_name: "Selfridge",
  },
  {
    postal_code: "72467",
    full_city_name: "State University, AR",
    city_name: "State University",
  },
  {
    postal_code: "57576",
    full_city_name: "Vivian, SD",
    city_name: "Vivian",
  },
  {
    postal_code: "79759",
    full_city_name: "Notrees, TX",
    city_name: "Notrees",
  },
  {
    postal_code: "67734",
    full_city_name: "Gem, KS",
    city_name: "Gem",
  },
  {
    postal_code: "64639",
    full_city_name: "De Witt, MO",
    city_name: "De Witt",
  },
  {
    postal_code: "68315",
    full_city_name: "Belvidere, NE",
    city_name: "Belvidere",
  },
  {
    postal_code: "66942",
    full_city_name: "Formoso, KS",
    city_name: "Formoso",
  },
  {
    postal_code: "57520",
    full_city_name: "Agar, SD",
    city_name: "Agar",
  },
  {
    postal_code: "66780",
    full_city_name: "Walnut, KS",
    city_name: "Walnut",
  },
  {
    postal_code: "48109",
    full_city_name: "Ann Arbor, MI",
    city_name: "Ann Arbor",
  },
  {
    postal_code: "97735",
    full_city_name: "Fort Rock, OR",
    city_name: "Fort Rock",
  },
  {
    postal_code: "77986",
    full_city_name: "Sublime, TX",
    city_name: "Sublime",
  },
  {
    postal_code: "68881",
    full_city_name: "Westerville, NE",
    city_name: "Westerville",
  },
  {
    postal_code: "41812",
    full_city_name: "Deane, KY",
    city_name: "Deane",
  },
  {
    postal_code: "61941",
    full_city_name: "Murdock, IL",
    city_name: "Murdock",
  },
  {
    postal_code: "57258",
    full_city_name: "Raymond, SD",
    city_name: "Raymond",
  },
  {
    postal_code: "59082",
    full_city_name: "Springdale, MT",
    city_name: "Springdale",
  },
  {
    postal_code: "84743",
    full_city_name: "Kingston, UT",
    city_name: "Kingston",
  },
  {
    postal_code: "57642",
    full_city_name: "Mc Laughlin, SD",
    city_name: "Mc Laughlin",
  },
  {
    postal_code: "97711",
    full_city_name: "Ashwood, OR",
    city_name: "Ashwood",
  },
  {
    postal_code: "67733",
    full_city_name: "Edson, KS",
    city_name: "Edson",
  },
  {
    postal_code: "71748",
    full_city_name: "Ivan, AR",
    city_name: "Ivan",
  },
  {
    postal_code: "72515",
    full_city_name: "Bexar, AR",
    city_name: "Bexar",
  },
  {
    postal_code: "25044",
    full_city_name: "Clear Creek, WV",
    city_name: "Clear Creek",
  },
  {
    postal_code: "58487",
    full_city_name: "Tappen, ND",
    city_name: "Tappen",
  },
  {
    postal_code: "26586",
    full_city_name: "Montana Mines, WV",
    city_name: "Montana Mines",
  },
  {
    postal_code: "36115",
    full_city_name: "Montgomery, AL",
    city_name: "Montgomery",
  },
  {
    postal_code: "84534",
    full_city_name: "Montezuma Creek, UT",
    city_name: "Montezuma Creek",
  },
  {
    postal_code: "31207",
    full_city_name: "Macon, GA",
    city_name: "Macon",
  },
  {
    postal_code: "57224",
    full_city_name: "Claire City, SD",
    city_name: "Claire City",
  },
  {
    postal_code: "67629",
    full_city_name: "Clayton, KS",
    city_name: "Clayton",
  },
  {
    postal_code: "95211",
    full_city_name: "Stockton, CA",
    city_name: "Stockton",
  },
  {
    postal_code: "31062",
    full_city_name: "Milledgeville, GA",
    city_name: "Milledgeville",
  },
  {
    postal_code: "59022",
    full_city_name: "Crow Agency, MT",
    city_name: "Crow Agency",
  },
  {
    postal_code: "67659",
    full_city_name: "Penokee, KS",
    city_name: "Penokee",
  },
  {
    postal_code: "58423",
    full_city_name: "Chaseley, ND",
    city_name: "Chaseley",
  },
  {
    postal_code: "43523",
    full_city_name: "Grelton, OH",
    city_name: "Grelton",
  },
  {
    postal_code: "58372",
    full_city_name: "Sarles, ND",
    city_name: "Sarles",
  },
  {
    postal_code: "79094",
    full_city_name: "Wayside, TX",
    city_name: "Wayside",
  },
  {
    postal_code: "99155",
    full_city_name: "Nespelem, WA",
    city_name: "Nespelem",
  },
  {
    postal_code: "71002",
    full_city_name: "Ashland, LA",
    city_name: "Ashland",
  },
  {
    postal_code: "30912",
    full_city_name: "Augusta, GA",
    city_name: "Augusta",
  },
  {
    postal_code: "79085",
    full_city_name: "Summerfield, TX",
    city_name: "Summerfield",
  },
  {
    postal_code: "62098",
    full_city_name: "Wrights, IL",
    city_name: "Wrights",
  },
  {
    postal_code: "36722",
    full_city_name: "Arlington, AL",
    city_name: "Arlington",
  },
  {
    postal_code: "59242",
    full_city_name: "Homestead, MT",
    city_name: "Homestead",
  },
  {
    postal_code: "81071",
    full_city_name: "Sheridan Lake, CO",
    city_name: "Sheridan Lake",
  },
  {
    postal_code: "76831",
    full_city_name: "Castell, TX",
    city_name: "Castell",
  },
  {
    postal_code: "97736",
    full_city_name: "Frenchglen, OR",
    city_name: "Frenchglen",
  },
  {
    postal_code: "69331",
    full_city_name: "Angora, NE",
    city_name: "Angora",
  },
  {
    postal_code: "80914",
    full_city_name: "Colorado Springs, CO",
    city_name: "Colorado Springs",
  },
  {
    postal_code: "25008",
    full_city_name: "Artie, WV",
    city_name: "Artie",
  },
  {
    postal_code: "67143",
    full_city_name: "Sun City, KS",
    city_name: "Sun City",
  },
  {
    postal_code: "97761",
    full_city_name: "Warm Springs, OR",
    city_name: "Warm Springs",
  },
  {
    postal_code: "46971",
    full_city_name: "Grissom Arb, IN",
    city_name: "Grissom Arb",
  },
  {
    postal_code: "66959",
    full_city_name: "Munden, KS",
    city_name: "Munden",
  },
  {
    postal_code: "06349",
    full_city_name: "Groton, CT",
    city_name: "Groton",
  },
  {
    postal_code: "99580",
    full_city_name: "Ekwok, AK",
    city_name: "Ekwok",
  },
  {
    postal_code: "57477",
    full_city_name: "Turton, SD",
    city_name: "Turton",
  },
  {
    postal_code: "71674",
    full_city_name: "Watson, AR",
    city_name: "Watson",
  },
  {
    postal_code: "99607",
    full_city_name: "Kalskag, AK",
    city_name: "Kalskag",
  },
  {
    postal_code: "89022",
    full_city_name: "Manhattan, NV",
    city_name: "Manhattan",
  },
  {
    postal_code: "54641",
    full_city_name: "Mather, WI",
    city_name: "Mather",
  },
  {
    postal_code: "30118",
    full_city_name: "Carrollton, GA",
    city_name: "Carrollton",
  },
  {
    postal_code: "15378",
    full_city_name: "Westland, PA",
    city_name: "Westland",
  },
  {
    postal_code: "58847",
    full_city_name: "Keene, ND",
    city_name: "Keene",
  },
  {
    postal_code: "57775",
    full_city_name: "Quinn, SD",
    city_name: "Quinn",
  },
  {
    postal_code: "29425",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "58057",
    full_city_name: "Mcleod, ND",
    city_name: "Mcleod",
  },
  {
    postal_code: "79111",
    full_city_name: "Amarillo, TX",
    city_name: "Amarillo",
  },
  {
    postal_code: "84313",
    full_city_name: "Grouse Creek, UT",
    city_name: "Grouse Creek",
  },
  {
    postal_code: "66760",
    full_city_name: "Opolis, KS",
    city_name: "Opolis",
  },
  {
    postal_code: "16444",
    full_city_name: "Edinboro, PA",
    city_name: "Edinboro",
  },
  {
    postal_code: "63760",
    full_city_name: "Leopold, MO",
    city_name: "Leopold",
  },
  {
    postal_code: "73770",
    full_city_name: "Southard, OK",
    city_name: "Southard",
  },
  {
    postal_code: "25608",
    full_city_name: "Baisden, WV",
    city_name: "Baisden",
  },
  {
    postal_code: "52562",
    full_city_name: "Hayesville, IA",
    city_name: "Hayesville",
  },
  {
    postal_code: "68837",
    full_city_name: "Elyria, NE",
    city_name: "Elyria",
  },
  {
    postal_code: "38542",
    full_city_name: "Allred, TN",
    city_name: "Allred",
  },
  {
    postal_code: "69167",
    full_city_name: "Tryon, NE",
    city_name: "Tryon",
  },
  {
    postal_code: "13602",
    full_city_name: "Fort Drum, NY",
    city_name: "Fort Drum",
  },
  {
    postal_code: "66417",
    full_city_name: "Corning, KS",
    city_name: "Corning",
  },
  {
    postal_code: "68309",
    full_city_name: "Barneston, NE",
    city_name: "Barneston",
  },
  {
    postal_code: "26203",
    full_city_name: "Erbacon, WV",
    city_name: "Erbacon",
  },
  {
    postal_code: "87320",
    full_city_name: "Mexican Springs, NM",
    city_name: "Mexican Springs",
  },
  {
    postal_code: "97044",
    full_city_name: "Odell, OR",
    city_name: "Odell",
  },
  {
    postal_code: "81087",
    full_city_name: "Vilas, CO",
    city_name: "Vilas",
  },
  {
    postal_code: "83256",
    full_city_name: "Moreland, ID",
    city_name: "Moreland",
  },
  {
    postal_code: "56729",
    full_city_name: "Halma, MN",
    city_name: "Halma",
  },
  {
    postal_code: "73571",
    full_city_name: "Vinson, OK",
    city_name: "Vinson",
  },
  {
    postal_code: "71864",
    full_city_name: "Willisville, AR",
    city_name: "Willisville",
  },
  {
    postal_code: "75687",
    full_city_name: "Price, TX",
    city_name: "Price",
  },
  {
    postal_code: "49848",
    full_city_name: "Ingalls, MI",
    city_name: "Ingalls",
  },
  {
    postal_code: "68813",
    full_city_name: "Anselmo, NE",
    city_name: "Anselmo",
  },
  {
    postal_code: "50551",
    full_city_name: "Jolley, IA",
    city_name: "Jolley",
  },
  {
    postal_code: "72139",
    full_city_name: "Russell, AR",
    city_name: "Russell",
  },
  {
    postal_code: "47580",
    full_city_name: "Schnellville, IN",
    city_name: "Schnellville",
  },
  {
    postal_code: "50573",
    full_city_name: "Plover, IA",
    city_name: "Plover",
  },
  {
    postal_code: "68441",
    full_city_name: "Steinauer, NE",
    city_name: "Steinauer",
  },
  {
    postal_code: "78385",
    full_city_name: "Sarita, TX",
    city_name: "Sarita",
  },
  {
    postal_code: "17232",
    full_city_name: "Lurgan, PA",
    city_name: "Lurgan",
  },
  {
    postal_code: "55605",
    full_city_name: "Grand Portage, MN",
    city_name: "Grand Portage",
  },
  {
    postal_code: "01063",
    full_city_name: "Northampton, MA",
    city_name: "Northampton",
  },
  {
    postal_code: "29424",
    full_city_name: "Charleston, SC",
    city_name: "Charleston",
  },
  {
    postal_code: "73658",
    full_city_name: "Oakwood, OK",
    city_name: "Oakwood",
  },
  {
    postal_code: "40843",
    full_city_name: "Holmes Mill, KY",
    city_name: "Holmes Mill",
  },
  {
    postal_code: "58415",
    full_city_name: "Berlin, ND",
    city_name: "Berlin",
  },
  {
    postal_code: "81029",
    full_city_name: "Campo, CO",
    city_name: "Campo",
  },
  {
    postal_code: "93624",
    full_city_name: "Five Points, CA",
    city_name: "Five Points",
  },
  {
    postal_code: "35036",
    full_city_name: "Brookside, AL",
    city_name: "Brookside",
  },
  {
    postal_code: "25231",
    full_city_name: "Advent, WV",
    city_name: "Advent",
  },
  {
    postal_code: "57251",
    full_city_name: "Marvin, SD",
    city_name: "Marvin",
  },
  {
    postal_code: "88427",
    full_city_name: "Mcalister, NM",
    city_name: "Mcalister",
  },
  {
    postal_code: "59260",
    full_city_name: "Richland, MT",
    city_name: "Richland",
  },
  {
    postal_code: "64496",
    full_city_name: "Watson, MO",
    city_name: "Watson",
  },
  {
    postal_code: "50623",
    full_city_name: "Dewar, IA",
    city_name: "Dewar",
  },
  {
    postal_code: "21010",
    full_city_name: "Gunpowder, MD",
    city_name: "Gunpowder",
  },
  {
    postal_code: "06269",
    full_city_name: "Storrs Mansfield, CT",
    city_name: "Storrs Mansfield",
  },
  {
    postal_code: "92310",
    full_city_name: "Fort Irwin, CA",
    city_name: "Fort Irwin",
  },
  {
    postal_code: "33394",
    full_city_name: "Fort Lauderdale, FL",
    city_name: "Fort Lauderdale",
  },
  {
    postal_code: "79016",
    full_city_name: "Canyon, TX",
    city_name: "Canyon",
  },
  {
    postal_code: "92135",
    full_city_name: "San Diego, CA",
    city_name: "San Diego",
  },
  {
    postal_code: "32925",
    full_city_name: "Patrick Afb, FL",
    city_name: "Patrick Afb",
  },
  {
    postal_code: "89831",
    full_city_name: "Mountain City, NV",
    city_name: "Mountain City",
  },
  {
    postal_code: "57584",
    full_city_name: "Witten, SD",
    city_name: "Witten",
  },
  {
    postal_code: "50545",
    full_city_name: "Hardy, IA",
    city_name: "Hardy",
  },
  {
    postal_code: "79738",
    full_city_name: "Gail, TX",
    city_name: "Gail",
  },
  {
    postal_code: "24724",
    full_city_name: "Freeman, WV",
    city_name: "Freeman",
  },
  {
    postal_code: "59339",
    full_city_name: "Lindsay, MT",
    city_name: "Lindsay",
  },
  {
    postal_code: "80045",
    full_city_name: "Aurora, CO",
    city_name: "Aurora",
  },
  {
    postal_code: "63535",
    full_city_name: "Coatsville, MO",
    city_name: "Coatsville",
  },
  {
    postal_code: "99772",
    full_city_name: "Shishmaref, AK",
    city_name: "Shishmaref",
  },
  {
    postal_code: "58017",
    full_city_name: "Cogswell, ND",
    city_name: "Cogswell",
  },
  {
    postal_code: "28547",
    full_city_name: "Camp Lejeune, NC",
    city_name: "Camp Lejeune",
  },
  {
    postal_code: "92328",
    full_city_name: "Death Valley, CA",
    city_name: "Death Valley",
  },
  {
    postal_code: "17076",
    full_city_name: "Oakland Mills, PA",
    city_name: "Oakland Mills",
  },
  {
    postal_code: "58065",
    full_city_name: "Pillsbury, ND",
    city_name: "Pillsbury",
  },
  {
    postal_code: "93437",
    full_city_name: "Lompoc, CA",
    city_name: "Lompoc",
  },
  {
    postal_code: "71831",
    full_city_name: "Columbus, AR",
    city_name: "Columbus",
  },
  {
    postal_code: "85707",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "57371",
    full_city_name: "Ree Heights, SD",
    city_name: "Ree Heights",
  },
  {
    postal_code: "57716",
    full_city_name: "Batesland, SD",
    city_name: "Batesland",
  },
  {
    postal_code: "66960",
    full_city_name: "Narka, KS",
    city_name: "Narka",
  },
  {
    postal_code: "15416",
    full_city_name: "Brownfield, PA",
    city_name: "Brownfield",
  },
  {
    postal_code: "79537",
    full_city_name: "Nolan, TX",
    city_name: "Nolan",
  },
  {
    postal_code: "68755",
    full_city_name: "Naper, NE",
    city_name: "Naper",
  },
  {
    postal_code: "29634",
    full_city_name: "Clemson, SC",
    city_name: "Clemson",
  },
  {
    postal_code: "23884",
    full_city_name: "Sussex, VA",
    city_name: "Sussex",
  },
  {
    postal_code: "88419",
    full_city_name: "Folsom, NM",
    city_name: "Folsom",
  },
  {
    postal_code: "87322",
    full_city_name: "Rehoboth, NM",
    city_name: "Rehoboth",
  },
  {
    postal_code: "68622",
    full_city_name: "Bartlett, NE",
    city_name: "Bartlett",
  },
  {
    postal_code: "63466",
    full_city_name: "Saint Patrick, MO",
    city_name: "Saint Patrick",
  },
  {
    postal_code: "57537",
    full_city_name: "Hayes, SD",
    city_name: "Hayes",
  },
  {
    postal_code: "96162",
    full_city_name: "Truckee, CA",
    city_name: "Truckee",
  },
  {
    postal_code: "73947",
    full_city_name: "Keyes, OK",
    city_name: "Keyes",
  },
  {
    postal_code: "85535",
    full_city_name: "Eden, AZ",
    city_name: "Eden",
  },
  {
    postal_code: "87011",
    full_city_name: "Claunch, NM",
    city_name: "Claunch",
  },
  {
    postal_code: "30320",
    full_city_name: "Atlanta, GA",
    city_name: "Atlanta",
  },
  {
    postal_code: "39201",
    full_city_name: "Jackson, MS",
    city_name: "Jackson",
  },
  {
    postal_code: "84408",
    full_city_name: "Ogden, UT",
    city_name: "Ogden",
  },
  {
    postal_code: "28542",
    full_city_name: "Camp Lejeune, NC",
    city_name: "Camp Lejeune",
  },
  {
    postal_code: "45897",
    full_city_name: "Williamstown, OH",
    city_name: "Williamstown",
  },
  {
    postal_code: "69219",
    full_city_name: "Nenzel, NE",
    city_name: "Nenzel",
  },
  {
    postal_code: "79324",
    full_city_name: "Enochs, TX",
    city_name: "Enochs",
  },
  {
    postal_code: "84602",
    full_city_name: "Provo, UT",
    city_name: "Provo",
  },
  {
    postal_code: "50427",
    full_city_name: "Chapin, IA",
    city_name: "Chapin",
  },
  {
    postal_code: "36915",
    full_city_name: "Needham, AL",
    city_name: "Needham",
  },
  {
    postal_code: "69354",
    full_city_name: "Marsland, NE",
    city_name: "Marsland",
  },
  {
    postal_code: "07495",
    full_city_name: "Mahwah, NJ",
    city_name: "Mahwah",
  },
  {
    postal_code: "85724",
    full_city_name: "Tucson, AZ",
    city_name: "Tucson",
  },
  {
    postal_code: "02912",
    full_city_name: "Providence, RI",
    city_name: "Providence",
  },
  {
    postal_code: "04737",
    full_city_name: "Clayton Lake, ME",
    city_name: "Clayton Lake",
  },
  {
    postal_code: "72329",
    full_city_name: "Driver, AR",
    city_name: "Driver",
  },
  {
    postal_code: "69216",
    full_city_name: "Kilgore, NE",
    city_name: "Kilgore",
  },
  {
    postal_code: "79344",
    full_city_name: "Maple, TX",
    city_name: "Maple",
  },
  {
    postal_code: "41160",
    full_city_name: "Mazie, KY",
    city_name: "Mazie",
  },
  {
    postal_code: "02841",
    full_city_name: "Newport, RI",
    city_name: "Newport",
  },
  {
    postal_code: "85329",
    full_city_name: "Cashion, AZ",
    city_name: "Cashion",
  },
  {
    postal_code: "72820",
    full_city_name: "Alix, AR",
    city_name: "Alix",
  },
  {
    postal_code: "25848",
    full_city_name: "Glen Rogers, WV",
    city_name: "Glen Rogers",
  },
  {
    postal_code: "67150",
    full_city_name: "Waldron, KS",
    city_name: "Waldron",
  },
  {
    postal_code: "26667",
    full_city_name: "Drennen, WV",
    city_name: "Drennen",
  },
  {
    postal_code: "76873",
    full_city_name: "Rockwood, TX",
    city_name: "Rockwood",
  },
  {
    postal_code: "65409",
    full_city_name: "Rolla, MO",
    city_name: "Rolla",
  },
  {
    postal_code: "24887",
    full_city_name: "Switchback, WV",
    city_name: "Switchback",
  },
  {
    postal_code: "82638",
    full_city_name: "Hiland, WY",
    city_name: "Hiland",
  },
  {
    postal_code: "04013",
    full_city_name: "Bustins Island, ME",
    city_name: "Bustins Island",
  },
  {
    postal_code: "85550",
    full_city_name: "San Carlos, AZ",
    city_name: "San Carlos",
  },
  {
    postal_code: "84728",
    full_city_name: "Garrison, UT",
    city_name: "Garrison",
  },
  {
    postal_code: "32815",
    full_city_name: "Orlando, FL",
    city_name: "Orlando",
  },
  {
    postal_code: "82242",
    full_city_name: "Van Tassell, WY",
    city_name: "Van Tassell",
  },
  {
    postal_code: "11437",
    full_city_name: "Jamaica, NY",
    city_name: "Jamaica",
  },
  {
    postal_code: "79519",
    full_city_name: "Goldsboro, TX",
    city_name: "Goldsboro",
  },
  {
    postal_code: "77617",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25826",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28017",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74050",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28603",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74947",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17254",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95430",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46537",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66420",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45783",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18413",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96745",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95309",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12483",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89152",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75942",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24604",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61276",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44285",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13814",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19451",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28647",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74945",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14029",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14732",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48896",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74456",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75565",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25911",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36057",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49409",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63365",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43336",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93665",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74942",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87576",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78567",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08245",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07926",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81414",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34729",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21670",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05047",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62348",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27593",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18244",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27555",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23450",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05159",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29578",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70391",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05665",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28583",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38869",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72217",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36371",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98238",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39062",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38050",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04016",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47366",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24063",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25149",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54215",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24817",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63868",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31003",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41517",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08855",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93605",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13747",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21528",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17083",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77374",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93530",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14766",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32133",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06383",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72770",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08217",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63840",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49258",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17884",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29447",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93227",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95656",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49610",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31013",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17862",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12872",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70464",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08870",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72059",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62825",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07961",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49084",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78553",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85744",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94950",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15561",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47361",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46595",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05481",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44232",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60113",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12784",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17085",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49826",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18251",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49877",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48190",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32655",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21762",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01936",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08038",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18454",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87574",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20201",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07096",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01614",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70550",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94562",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87153",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99102",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43325",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06372",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28246",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22513",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77639",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21023",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78335",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59013",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85536",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06064",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60168",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12040",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83343",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71759",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13064",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13305",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04420",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28671",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22517",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28136",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76199",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72037",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62256",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01252",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14537",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17762",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42322",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93232",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12749",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75978",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87543",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41549",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70551",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96011",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28664",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32192",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15379",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19367",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22748",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12781",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40523",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37364",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40955",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22555",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53962",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77870",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62663",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45307",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07051",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24327",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13762",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54240",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31083",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12949",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18239",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13455",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21867",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65636",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18223",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76253",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73455",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44848",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26275",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44265",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70616",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05470",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71230",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25692",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54976",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65675",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28652",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28765",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96751",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36763",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30085",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98297",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18459",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73137",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22472",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74951",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18081",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77533",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24177",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25441",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17317",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23414",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75636",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22446",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95532",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23899",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99693",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66742",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19395",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99550",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71021",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01508",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18065",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16856",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02051",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42374",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43934",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33164",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22040",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62222",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12769",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08320",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25855",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83866",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77580",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23304",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96090",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53171",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83671",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39477",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43048",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38455",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47337",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93261",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85633",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95625",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43142",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81009",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26823",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45131",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23183",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78592",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49289",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16048",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05469",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78268",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29861",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70580",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14745",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61426",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78622",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06904",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75780",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52344",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75711",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28661",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53026",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38679",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39552",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75963",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14827",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07007",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43937",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72526",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34451",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48318",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71247",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18234",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29319",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71253",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36476",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32410",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25879",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72735",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45172",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12820",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93258",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73567",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44610",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54654",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08862",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "91948",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83281",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30665",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36087",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62273",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30449",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39635",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76503",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23479",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05074",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48812",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08095",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62861",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96029",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18256",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41520",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18457",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43981",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04431",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29731",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28662",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21542",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88349",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86512",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72115",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74837",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17932",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25696",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18958",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24624",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30070",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72214",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79233",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12939",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29822",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75782",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77376",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35185",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99918",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70541",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43916",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47458",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29921",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21105",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14893",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78339",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73624",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25090",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18980",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08888",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92222",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78568",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18030",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53584",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60944",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74431",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43985",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36865",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04056",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30129",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37089",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13678",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30359",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20113",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43905",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28108",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14786",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "55601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79032",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45413",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87583",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18016",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99791",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37382",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85531",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02634",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24469",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28903",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01438",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78960",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85627",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95613",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32616",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75713",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74530",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63758",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05085",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05460",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17016",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65327",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28053",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73033",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46984",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14856",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17833",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18247",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07710",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12132",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23347",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78352",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48758",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28616",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24878",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17865",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45636",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82615",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25109",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73555",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96784",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35559",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99725",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29338",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17343",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05848",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39829",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49081",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06373",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30380",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29519",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14887",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46789",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76953",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73487",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29062",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03911",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06701",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22924",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21866",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84626",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17568",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23389",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30429",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46916",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13749",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49723",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20686",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46393",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13645",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02883",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39457",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27827",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27879",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53109",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33429",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79954",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24871",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77368",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49611",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05731",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17503",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61236",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70523",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08023",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23064",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71330",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45166",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79740",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15693",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96155",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73436",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61433",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46063",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47875",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75573",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32664",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95680",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26058",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52631",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36543",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73066",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32772",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07870",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18086",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21765",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13352",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35631",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71320",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35649",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24608",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76246",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48830",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08218",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30573",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02651",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79003",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35056",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06115",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28388",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62532",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32633",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14863",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49797",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19450",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12975",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30639",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54324",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37304",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95559",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72462",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24826",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26707",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99694",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14461",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28077",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08240",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93928",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65278",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80471",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49627",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49335",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29844",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12073",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25652",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23431",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34460",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47870",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36753",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16245",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18356",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22579",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49239",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73435",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31627",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02829",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38324",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36513",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47344",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83465",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78853",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22226",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72169",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79369",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22833",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44693",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97384",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13674",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77326",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28223",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28332",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82450",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11247",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89450",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48177",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75848",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17010",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96092",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76686",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22548",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08072",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85942",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45132",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31799",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75444",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38235",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26576",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33820",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78125",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59604",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72316",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29665",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17310",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60147",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15930",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24218",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99764",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18349",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27582",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32173",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37161",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73743",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02345",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37326",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43721",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77325",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77428",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68109",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30172",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88888",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06350",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15779",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30647",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38966",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01885",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54890",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43510",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23443",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04077",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71910",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35032",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32662",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65055",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49666",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38835",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47370",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05633",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48633",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25614",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70073",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96135",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27881",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84740",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23427",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43928",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77483",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38622",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28109",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25653",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47335",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38389",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43531",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10540",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71480",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01111",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24246",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05740",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56541",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89140",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30581",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74468",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17072",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53127",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98046",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74340",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46379",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29744",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23313",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14169",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73746",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17829",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27374",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20160",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02344",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37056",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75934",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53148",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63674",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70569",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22227",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15691",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32147",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17945",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79708",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07875",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27868",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79776",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88202",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16244",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32639",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18440",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74844",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16367",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15680",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43505",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77631",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36536",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85359",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31727",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25205",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07879",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77476",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46965",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12915",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62876",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25639",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17779",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07970",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75443",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13121",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07880",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28710",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23486",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07938",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23341",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74750",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02672",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70747",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12767",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05823",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75456",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77993",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34660",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20138",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04926",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62766",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22945",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22160",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99832",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92599",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72133",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22523",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76804",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24716",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95986",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34679",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75772",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25637",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88254",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16631",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94573",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96765",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62610",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77451",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49458",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37744",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49734",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92286",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45633",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92366",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28042",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08352",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76466",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36313",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25904",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17946",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24505",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19430",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72107",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12345",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30414",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02065",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77960",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47869",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48627",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99624",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54464",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25942",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95537",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23407",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45643",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25688",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38820",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62953",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49077",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59771",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23408",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37394",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77976",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47983",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25723",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16541",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07844",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53940",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12133",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94953",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96084",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19490",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76369",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75164",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44881",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27985",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45361",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99708",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70340",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76654",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78767",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94516",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48844",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12089",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17748",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81227",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70575",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44661",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72613",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99836",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18946",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77334",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18320",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63860",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03769",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61328",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61331",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29834",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54760",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40448",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38088",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37732",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28641",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20301",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24888",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62662",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13341",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24843",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12568",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74559",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85360",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73491",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16544",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78670",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11386",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75642",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32182",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53159",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78044",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49416",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99566",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29656",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10915",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47966",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88263",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01094",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98353",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84605",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25927",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13677",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75658",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96037",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89595",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75682",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28102",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75865",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20139",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13062",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89016",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27102",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95940",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70640",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22622",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27836",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77876",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13847",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32111",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03105",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75832",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22646",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83229",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54926",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77866",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13647",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27967",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17726",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16246",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15365",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47617",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27293",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78585",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12724",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60974",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22320",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42631",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27340",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62519",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32681",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17231",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70787",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79258",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70465",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72615",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12995",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16003",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33836",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36564",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23401",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32326",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17920",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77970",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23184",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70421",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23941",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30271",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07979",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05849",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21653",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95712",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54743",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08739",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45336",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32957",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71754",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03832",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38168",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63387",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74556",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61237",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20660",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17256",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "64781",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83826",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08252",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15752",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75858",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61524",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54842",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62921",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76939",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21916",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10982",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29826",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "57358",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49634",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70576",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18912",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02761",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72322",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46942",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08064",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77454",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45837",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99754",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95950",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07962",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18813",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72475",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72906",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26259",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15339",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10120",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61562",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43520",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18231",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75764",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99790",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99710",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30229",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28380",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95433",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05049",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65615",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95924",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15454",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63066",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77367",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34146",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27907",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73403",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26436",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75562",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23441",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44493",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38772",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12929",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29331",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33152",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31810",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99335",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63874",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28350",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08347",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95639",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25011",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76485",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40339",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23003",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18820",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25873",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78561",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98082",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99841",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36559",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48852",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04262",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78505",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95418",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47021",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32361",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54532",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24457",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78074",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63880",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73050",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48882",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10133",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99345",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32706",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49764",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93649",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17858",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63467",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25836",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13631",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13457",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38764",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98206",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95347",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53535",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19535",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04288",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71410",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96078",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22442",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43733",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17936",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45848",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79768",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39167",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70371",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77464",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21022",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24880",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70743",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65038",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79710",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47367",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18935",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62852",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18323",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30562",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34230",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83468",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19369",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24813",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25156",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47880",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35070",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30164",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75666",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77626",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12471",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13154",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97528",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36470",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32079",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93661",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80477",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29329",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18242",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78351",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46345",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87032",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29503",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12537",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99732",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87518",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28072",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99252",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16672",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65532",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28509",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75694",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38365",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28725",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30460",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77466",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26578",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62961",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96127",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27877",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30133",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04855",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35403",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35477",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21020",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23131",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28628",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30081",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35584",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16140",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27256",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95435",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54429",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99644",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08808",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54936",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78564",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32183",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78115",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77369",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15072",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44274",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73556",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95481",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02334",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29598",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18410",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81420",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32314",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25606",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77989",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16911",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15640",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25721",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17081",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38644",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31034",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72654",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49964",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06263",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24533",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24612",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13138",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25853",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08885",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35573",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48175",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08315",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18105",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17506",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15948",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98343",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22038",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "55609",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85732",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20213",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41713",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65890",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62997",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21721",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04565",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95486",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56335",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10503",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22530",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08042",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19351",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48816",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12441",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24576",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71840",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31556",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87654",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "64858",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70540",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80539",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05768",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16361",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86029",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54985",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24857",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72657",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99731",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "90223",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29679",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38254",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06829",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78674",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77988",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77852",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70646",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44636",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18348",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21843",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88115",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28666",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02662",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02643",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75097",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53195",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85548",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17861",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70451",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71477",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17253",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12544",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18843",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49674",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30250",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21106",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49628",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99635",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53056",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08219",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78540",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31004",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76370",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26574",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25871",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23397",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31307",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08246",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79105",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68501",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31209",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17943",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95675",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89155",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24411",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16681",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30266",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70651",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95233",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05678",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25875",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04098",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07833",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79012",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05344",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25818",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47878",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62279",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47445",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24854",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37682",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45222",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08018",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14542",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04082",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17069",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72431",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05746",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62266",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34973",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04675",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32644",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75759",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95325",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45155",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29695",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63742",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66110",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25040",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98559",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30732",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59760",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45055",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13404",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98397",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05861",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43735",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26209",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23398",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19955",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02564",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18625",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53157",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80497",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25630",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98164",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96074",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04637",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14410",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30412",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08250",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23316",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13628",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12420",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47683",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27676",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46796",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13113",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52071",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20790",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17730",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15674",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29324",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28749",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49808",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02647",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01093",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44874",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22524",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74438",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79383",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27873",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74350",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77855",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44033",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43441",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29636",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62965",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32658",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62891",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98207",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19564",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47388",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07752",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16725",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76206",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28089",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60144",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89028",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54643",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43083",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19511",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72659",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56436",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02553",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17979",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44080",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27697",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27887",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43433",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12527",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13139",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25906",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02574",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03910",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14742",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84024",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83635",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28667",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32747",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43967",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77453",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43711",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47437",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25909",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45330",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44687",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12195",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53152",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21852",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15447",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19421",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61057",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28367",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27819",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19437",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45739",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30140",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43909",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35149",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05741",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27010",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20627",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19442",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61037",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12510",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98527",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28519",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15455",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78565",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12417",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77661",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16035",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12082",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76268",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34447",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79021",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54980",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29675",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12240",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18827",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70558",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63047",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85551",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92406",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56056",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38007",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44088",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33858",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43553",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13102",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18012",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37707",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12848",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93724",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14774",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23939",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13643",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44690",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25907",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36204",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05451",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12429",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01842",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12050",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47263",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11243",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49626",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24836",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29386",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30289",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99014",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48143",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26366",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28750",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75132",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63774",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28757",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45158",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52701",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49717",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12541",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18921",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56344",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05657",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85349",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37315",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12933",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98637",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61077",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38338",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93921",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12452",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83354",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32572",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12085",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19481",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37868",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28076",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74018",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "90010",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32692",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77353",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87519",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28093",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28106",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96714",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95646",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85117",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96737",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15448",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31603",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28755",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72123",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36253",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35013",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02060",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63853",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27640",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41142",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77350",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72711",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11380",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49063",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88058",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74446",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31010",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81043",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17261",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27359",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92226",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39818",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15689",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22965",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25036",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49430",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47107",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96739",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77842",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40730",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95353",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37845",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30539",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25667",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38825",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12128",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26544",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98287",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29604",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30703",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36766",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23242",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43974",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76650",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03847",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05009",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12107",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36533",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25707",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11405",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37011",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28653",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33318",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17533",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17272",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81232",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23241",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18627",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48620",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78558",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30724",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78764",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43073",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79785",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24619",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14529",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17944",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07877",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20401",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42060",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25183",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27841",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45617",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74529",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14778",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27201",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43349",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36547",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30321",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02361",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25851",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45063",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36741",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29816",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81329",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45115",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18922",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28788",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31776",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83403",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93275",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99020",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27894",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38722",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44871",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07509",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02350",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93009",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30503",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53064",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03866",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39558",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28169",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18956",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27867",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17885",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34430",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22577",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75990",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07820",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29594",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12551",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "50255",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10129",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76628",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44860",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45316",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30284",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63878",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18963",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02355",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52243",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18911",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32316",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25326",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30275",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95813",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19470",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43077",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24881",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89160",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13051",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31051",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43970",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17056",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19482",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18473",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23260",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "00501",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99174",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33970",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30726",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35560",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11022",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15230",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78347",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07846",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66855",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27825",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48908",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17567",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72124",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17880",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70571",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46125",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20206",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92418",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70165",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33945",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85723",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16629",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17311",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46937",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05838",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21609",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71279",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52652",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37044",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30063",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39885",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22565",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98811",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18949",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79711",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78523",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71339",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10103",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73184",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38223",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18910",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25972",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20726",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05664",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75685",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39832",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92247",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76469",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79314",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87735",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37864",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43005",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07428",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03073",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62541",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38745",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46995",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43786",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78765",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77542",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77882",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73520",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41542",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92864",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02584",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36065",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80163",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73901",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72575",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53957",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31318",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39753",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24606",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72164",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "91017",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77404",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74821",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20188",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78952",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30514",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41778",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38767",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30464",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29632",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27594",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02669",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30150",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72180",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38723",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74935",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28688",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30448",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53099",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18916",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16427",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43226",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03746",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62023",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77555",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29648",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72391",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31706",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20529",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24203",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46771",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98293",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37927",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41368",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33975",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03749",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34487",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25919",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83606",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19472",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16223",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25611",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02117",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19523",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71281",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16730",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79780",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79120",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15443",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26572",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54645",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16312",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32042",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89438",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34423",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27259",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88033",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37644",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79760",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60519",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02661",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72053",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27213",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18653",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78661",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22957",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28744",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25672",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12260",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46922",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02358",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18046",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35615",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38760",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23955",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25628",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53060",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72503",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19443",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10587",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30031",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75425",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24846",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10105",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12504",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40153",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14876",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29122",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14429",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54764",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71772",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70078",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27202",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10981",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10932",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30169",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96801",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79380",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54543",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02205",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32710",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41561",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28424",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72216",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27878",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98554",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08213",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70727",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01822",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99228",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72737",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34992",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43109",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44856",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47435",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17580",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08557",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01805",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16654",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14854",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38036",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12181",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12506",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "90251",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35082",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96160",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56084",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28145",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22107",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04227",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45713",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74034",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44828",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30237",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93044",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "67363",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02337",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "91334",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33845",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95416",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77481",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18814",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74460",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45624",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05750",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95229",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98920",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95958",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27351",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76533",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03468",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93504",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30396",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42216",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48833",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17570",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36261",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34755",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62622",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49311",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47654",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44665",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46047",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45070",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22116",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71659",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42037",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38776",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93592",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20811",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35048",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19478",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37383",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33883",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61630",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27861",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72183",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30604",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01525",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14518",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37152",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "69363",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62336",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68139",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66036",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88062",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "58702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15028",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30756",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27373",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70470",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10157",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10542",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98111",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30086",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82605",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31202",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38781",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28368",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17750",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36568",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35577",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54903",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03289",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49761",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23127",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24851",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07837",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74535",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31086",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11352",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23155",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11431",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87319",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25331",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25423",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29021",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28370",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01613",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33307",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33198",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74459",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24126",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47851",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30369",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20875",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97440",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88004",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76685",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23105",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36275",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29616",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94914",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38946",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24212",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72403",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31319",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29901",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83531",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40844",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94807",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47033",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21501",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02348",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71218",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77641",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14547",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85130",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16512",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18953",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23067",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19358",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77834",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27584",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "62767",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22529",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35187",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17504",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18039",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01021",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80261",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72427",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25810",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46372",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21556",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32362",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77549",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30916",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48667",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23115",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77725",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41810",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25022",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23467",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74836",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20643",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20128",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01526",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70585",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15682",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77961",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03822",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37116",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07019",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12141",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28123",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20013",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74345",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70139",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45350",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92329",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78104",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70718",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35112",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92678",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08543",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72203",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18816",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95518",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19961",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75047",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92357",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79091",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10108",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07855",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44862",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13745",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75014",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95867",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85327",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72733",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36556",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12223",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25943",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40018",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78054",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19360",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35482",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77473",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78551",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87305",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31769",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71329",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25337",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20122",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70459",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20847",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19612",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18943",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54912",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38649",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05466",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11351",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46624",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77561",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98322",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98228",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04629",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45004",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13758",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66063",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45481",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42460",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "50831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40536",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38378",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95173",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75788",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13761",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "55474",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36446",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43111",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63079",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81658",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70524",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14130",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03238",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45884",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "55816",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54969",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15664",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28284",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32862",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60926",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04028",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89180",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96850",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61613",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85380",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14453",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49281",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45787",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54816",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10172",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12301",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31534",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37314",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71233",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19516",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46945",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23429",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12248",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63826",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84091",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28236",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75712",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18971",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78143",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75849",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72078",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17537",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37378",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70519",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23162",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27512",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "64141",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06050",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28770",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94083",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95373",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98129",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95538",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07890",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19457",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16413",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46325",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72108",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08868",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37384",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37235",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39182",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44816",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19316",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98544",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89314",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74761",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70404",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22451",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29597",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44416",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75779",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63779",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72182",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71914",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63065",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12055",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78842",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97491",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92659",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77987",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53234",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08220",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72441",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20546",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47536",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60939",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41526",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85628",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14110",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17605",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68103",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98324",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29804",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39649",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60566",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30015",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18501",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46513",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27906",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42022",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48480",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13362",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82218",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72325",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "64051",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29217",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42332",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84011",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93709",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72918",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20235",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39282",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38623",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27552",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19052",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98609",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28459",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43951",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25727",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27495",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25686",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39555",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33191",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97494",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04489",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03252",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30304",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35201",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17933",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99209",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45051",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30301",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59403",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12588",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28201",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35964",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53547",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30049",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "59806",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14588",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26504",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20218",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87594",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82335",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63053",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20857",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75504",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60701",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80006",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22526",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13024",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88009",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60932",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32936",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49257",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43761",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28362",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20172",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78246",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42457",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18449",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04954",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74076",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65673",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97409",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83206",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02133",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "83203",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04228",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73070",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56075",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44242",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33222",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32716",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13737",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03111",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31059",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81046",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36868",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01090",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22471",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43216",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13845",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15336",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "41426",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26349",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10313",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10203",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02454",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02366",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35767",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02331",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04291",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29216",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53138",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56593",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94543",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28554",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35181",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08903",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40058",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63752",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01784",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72178",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27331",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54735",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "82073",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18611",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31026",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71903",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04057",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75483",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28082",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43941",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28111",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43117",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80046",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27811",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02362",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98511",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "80960",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29336",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46067",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72829",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33949",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28024",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07978",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95480",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "54649",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02823",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33022",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27833",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29641",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21139",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86405",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04234",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49516",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40166",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04343",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23178",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38739",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40410",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22230",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39181",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31067",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48332",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77726",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38021",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39121",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29442",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76468",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72445",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "50395",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16058",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11551",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24895",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40452",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19903",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28564",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46572",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01441",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44901",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01561",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25729",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29721",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24129",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40405",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37719",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79053",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20140",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74071",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32559",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02742",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77305",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44501",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24024",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02663",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88241",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10106",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23694",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40256",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47202",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33339",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36015",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30459",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65546",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26463",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29304",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75660",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04935",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48232",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28074",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75011",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56341",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78269",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46355",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37222",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93456",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28563",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23018",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15439",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03469",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10163",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84090",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48605",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70521",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29939",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34778",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "93423",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04070",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "52809",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18060",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29677",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49351",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "53187",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07091",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94966",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31310",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92393",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99214",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92652",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01229",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22313",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77452",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96151",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21759",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30162",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06792",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74943",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "64013",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77432",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89507",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76241",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74141",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44210",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06601",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "61702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27230",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60522",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07845",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20810",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33871",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20560",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29923",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06537",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "90651",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37227",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34656",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02455",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88102",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04962",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34136",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29528",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11969",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14168",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12235",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30599",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78283",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32635",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42102",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29225",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10080",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23326",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75915",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78646",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25720",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18981",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30142",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12584",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72043",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27342",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20375",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01703",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30638",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48901",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47902",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33583",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15415",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70156",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14851",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30042",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07099",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18043",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28758",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23190",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29622",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75017",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72765",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75671",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35471",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71823",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47852",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75834",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04944",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88255",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76267",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13851",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35266",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25690",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37933",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76465",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56561",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36202",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07839",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22122",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05453",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20210",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60454",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28038",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "12257",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27636",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27532",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16804",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30061",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37901",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20131",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30664",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03843",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "26671",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38874",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27216",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99220",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47733",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18957",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38959",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27533",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20704",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "91333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21092",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "81002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "47706",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36068",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "50359",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29846",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02059",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27656",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "87504",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29603",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10258",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "94119",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99333",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "63171",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14452",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77431",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02887",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "90083",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35220",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92863",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89136",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "99608",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36512",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42152",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "28019",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06487",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27506",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33163",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92605",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "08899",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23515",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21150",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20303",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "34270",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06072",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73113",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29904",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "56002",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79712",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04933",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48909",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25691",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "19544",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "32523",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "49020",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75461",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98025",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "36831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24811",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70143",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20534",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98384",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06101",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "16366",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "86545",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22909",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "84158",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29602",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13794",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77391",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77867",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40392",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "17843",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45235",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95234",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98921",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29938",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30074",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "68026",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "65302",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46249",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46251",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "01831",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "27570",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "58502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23081",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77243",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97464",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92702",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98540",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48608",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29222",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38877",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78673",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "42502",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30580",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02027",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "04078",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "48233",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38803",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30264",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "11241",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45234",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "89006",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "22403",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07765",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74444",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37422",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "45802",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75680",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "13401",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77460",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29652",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "78761",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75564",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "43738",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "35899",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "57709",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29348",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20703",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70044",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33550",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "95611",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79231",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "85726",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "88355",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "79931",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "03896",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33106",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "14430",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05490",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31708",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "57402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39436",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "50402",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74043",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "76113",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30608",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73140",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "96807",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "07653",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "75606",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "98041",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "77875",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "73157",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72811",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "37662",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "24719",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33448",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "15723",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25026",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "92149",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "39130",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "29734",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "31221",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "66201",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "46015",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "06540",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "23001",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05863",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70469",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "10169",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "71414",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "60055",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "33568",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "25866",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "72018",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44630",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "21560",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "20108",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "74866",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "97307",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "18918",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "02862",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30475",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "40434",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "05485",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "38639",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "44648",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "70728",
    full_city_name: null,
    city_name: null,
  },
  {
    postal_code: "30098",
    full_city_name: null,
    city_name: null,
  },
];
