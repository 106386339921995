import { SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import CurrentLocationIcon from "../public/assets/current-location.svg";
import { Flex } from "components/Flex";
import { useUserData } from "hooks/useUser";
import {
  findCountyFromFeature,
  findMetroFromCounty,
  findStateFromFeature,
} from "lib/helpers/geoSearchHelpers";
import { geoSettingsMap } from "lib/mapSettings";
import { countyList } from "lib/options/countyList";
import { countyZipMap } from "lib/options/countyZipMap";
import { USCenterLatLong, defaultZoomLevel } from "lib/options/mapConstants";
import { metroList } from "lib/options/metroList";
import { metroMap } from "lib/options/metroMap";
import { stateList } from "lib/options/stateList";
import { zipMetro } from "lib/zipMetro";
import { debounce, isEmpty, keys } from "lodash";
import { event } from "nextjs-google-analytics";
import { posthog } from "posthog-js";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useLocalStorage } from "react-use";
import { getDataByLatLng, getDataByPlace } from "services/geocodeService";
import {
  Geo,
  MapboxGeoMap,
  MapBoxGeoType,
  SearchFrom,
  SearchId,
} from "types/MapContext";
import { Feature } from "types/geocode";
import { CountyOptions, MetroOption, StateOption } from "types/options";
import useProgressQuery from "./useProgressQuery";
import { useRouter } from "next/router";
import Image from "next/image";
import Instagram from "../public/assets/icons/Instagram.svg";

const [defaultLong, defaultLat] = USCenterLatLong;

interface SearchOptions {
  forceGeo?: Geo;
  forceLatLng?: { lng: string; lat: string };
  forceZoom?: number;
  onlyFly?: boolean;
  searchedFrom?: string;
}
export interface GeoSearch {
  geo: Geo;
  lat: number;
  long: number;
  zoom: number;
  searchId: SearchId;
  filteredMetros: MetroOption[];
  filteredStates: StateOption[];
  stateList: StateOption[];
  metroFilter: MetroOption[];
  countyList: CountyOptions[];
  searchedGeo: string;
  searchValue: string;
  showFocusToolTip: boolean;
  focusSearch: boolean;
  selectedFeature: Feature | null;
  defaultMetroFeature: Feature | null;
  suggestions: SelectProps["options"];
  currentLocationSuggestion: DefaultOptionType;
  filteredCounties: CountyOptions[];
  graphContext;
  setGraphContext;
  setFilteredCounties: Dispatch<SetStateAction<CountyOptions[]>>;
  setLat: Dispatch<SetStateAction<number>>;
  setLong: Dispatch<SetStateAction<number>>;
  setGeo: Dispatch<SetStateAction<Geo>>;
  setZoom: Dispatch<SetStateAction<number>>;
  setSearchId: Dispatch<SetStateAction<SearchId>>;
  setSuggestions: Dispatch<SetStateAction<DefaultOptionType[]>>;
  setFilteredMetros: Dispatch<SetStateAction<MetroOption[]>>;
  setFilteredStates: Dispatch<SetStateAction<StateOption[]>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSearchedGeo: Dispatch<SetStateAction<string>>;
  setSelectedFeature: Dispatch<SetStateAction<Feature | null>>;
  setDefaultMetroFeature: Dispatch<SetStateAction<Feature | null>>;
  setShowFocusTooltip: Dispatch<SetStateAction<boolean>>;
  setFocusSearch: Dispatch<SetStateAction<boolean>>;
  handleInputChange(value: string): void;
  searchByQuery(
    query: string,
    options: SearchOptions,
    shouldClearSearch?: boolean,
  ): Promise<void>;
  searchByLatLng(
    lngLat: { lng: string; lat: string },
    options?: SearchOptions,
  ): Promise<void>;
  searchByFeature: (feature: Feature, options: SearchOptions) => void;
  moveToDefaultMetro(defaultMetro?: MetroOption): void;
  reset(): void;
  setFromLastVisitedArea(): void;
  searchZipsViaState: string;
  setSearchZipsViaState: Dispatch<SetStateAction<string>>;
  filterYear: string;
  filterMonth: string;
  setFilterYear: Dispatch<SetStateAction<string>>;
  setFilterMonth: Dispatch<SetStateAction<string>>;
}

interface LastVisitedArea {
  lat: number;
  long: number;
  geo: Geo;
  filteredStates: StateOption[];
  filteredMetros: MetroOption[];
  zoom: any;
}

export const SearchContext = createContext<GeoSearch | null>(null);

export const GeoSearchProvider = ({ children }) => {
  const user = useUserData();
  const { setProgressQuery } = useProgressQuery();

  const [savedGeo, setSavedGeo] = useLocalStorage<Geo>("geo", Geo.METRO, {
    raw: true,
  });
  const [lastVisitedArea, setLastVisitedArea] =
    useLocalStorage<LastVisitedArea | null>("lastVisitedArea", null);

  const [geo, setGeo] = useState<Geo>(savedGeo || Geo.METRO);
  const [lat, setLat] = useState(defaultLat);
  const [long, setLong] = useState(defaultLong);
  const [zoom, setZoom] = useState<number>(
    lastVisitedArea?.zoom ? lastVisitedArea?.zoom : defaultZoomLevel,
  );
  const [filteredStates, setFilteredStates] = useState<StateOption[]>([]);
  const [filteredMetros, setFilteredMetros] = useState<MetroOption[]>([]);
  const [filteredCounties, setFilteredCounties] = useState<CountyOptions[]>([]);
  const [searchZipsViaState, setSearchZipsViaState] =
    useState<string>("All States");
  const [metroFilter, setMetroFilter] = useState<MetroOption[]>(
    metroList.filter((metro) => !metro.label.includes(", PR")),
  );
  const [filterYear, setFilterYear] = useState("");
  const [filterMonth, setFilterMonth] = useState("");
  const [searchId, setSearchId] = useState<SearchId>({} as SearchId);
  const router = useRouter();
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState<DefaultOptionType[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null);
  const [defaultMetroFeature, setDefaultMetroFeature] =
    useState<Feature | null>(null);
  const [searchedGeo, setSearchedGeo] = useState<string>("");
  const [focusSearch, setFocusSearch] = useState<boolean>(false);
  const [showFocusToolTip, setShowFocusTooltip] = useState(false);
  const [graphContext, setGraphContext] = useState(null);

  useEffect(() => {
    setSavedGeo(geo);
    if (geo !== Geo.ZIP) {
      setZoom(defaultZoomLevel);
    } else if (!filteredMetros.length && lastVisitedArea?.geo !== Geo.ZIP) {
      searchByQuery(
        "67544" as string,
        {
          forceGeo: Geo.ZIP,
          forceLatLng: undefined,
          forceZoom: parseFloat(defaultZoomLevel.toString() as string),
        },
        true,
      );
    }
  }, [geo]);

  useEffect(() => {
    const showSearchTooltip =
      geo === Geo.ZIP && !filteredCounties.length && !filteredMetros.length;

    setFocusSearch(geo === Geo.ZIP);
    const isGrpahViewClosed =
      router.isReady && router.query?.graphView !== "true";
    setShowFocusTooltip(showSearchTooltip && isGrpahViewClosed);
  }, [geo, filteredMetros, filteredCounties]);

  useEffect(() => {
    if (geo === Geo.ZIP && !!filteredMetros.length) {
      const lastVisitedArea = {
        lat,
        long,
        geo,
        filteredMetros,
        filteredStates,
        zoom: zoom,
      };
      setLastVisitedArea(lastVisitedArea);
    } else if (geo !== Geo.ZIP) {
      setLastVisitedArea(null);
    }
  }, [geo, filteredMetros]);
  const getPlaceDetails = (feature: Feature) => {
    const searchContext = feature.context;
    const zip = feature.place_type.includes(MapBoxGeoType.ZIP)
      ? feature.text
      : null;
    const metro = null;
    const county = searchContext.find((item) => item.id.includes("district"))
      ?.text;
    const statename = searchContext.find((item) => item.id.includes("region"))
      ?.text;
    const attributes = {
      ...(zip ? { zip } : {}),
      ...(metro ? { metro } : {}),
      ...(county ? { county } : {}),
      state: statename,
    };
    return attributes;
  };
  const searchByFeature = (
    feature: Feature,
    options: SearchOptions = {},
    shouldClearSearch?: boolean,
  ) => {
    try {
      event(`map-control-${geo}`, {
        category: "Map Control",
        label: geo,
      });

      const [mapBoxGeoType] = feature.place_type;
      const searchedGeo = options?.forceGeo
        ? options?.forceGeo
        : MapboxGeoMap[mapBoxGeoType];
      const searchedFrom = options.searchedFrom || SearchFrom.MAP;
      const attributes = getPlaceDetails(feature);
      posthog.capture("Search", {
        geo: Object.keys(attributes).includes(Geo.ZIP)
          ? Geo.ZIP
          : MapboxGeoMap[mapBoxGeoType],
        searchedFrom,
        place: feature.place_name,
        ...attributes,
        platform: "Web",
      });

      let nextGeo = searchedGeo || geo;

      if (!options.onlyFly) {
        if (mapBoxGeoType === MapBoxGeoType.STATE) {
          nextGeo = Geo.STATE;
          const state = stateList.find((state) =>
            state.label.startsWith(feature.text),
          ) as StateOption;
          setFilteredStates([state]);
          setFilteredCounties([]);
          setFilteredMetros(
            metroFilter.filter(
              (metro) => +metro["FIPS State Code"] === +state.value,
            ) || [],
          );
        } else if (mapBoxGeoType === MapBoxGeoType.COUNTY) {
          nextGeo = Geo.COUNTY;

          const stateName = findStateFromFeature(feature);
          const countyName = findCountyFromFeature(feature);

          const state = stateList.find(
            (state) => state.label === stateName,
          ) as StateOption;

          const county = countyList.find(
            (county) => county.label === `${countyName}, ${stateName}`,
          ) as CountyOptions;

          setFilteredStates([state]);
          setFilteredMetros([]);
          setFilteredCounties([county]);
        } else if (mapBoxGeoType === MapBoxGeoType.CITY) {
          nextGeo = Geo.ZIP;

          // As there are many zips that are missing from our data, if a user looks up such a zip
          // we look up metro based on the county (that zip belongs to) instead,
          // so there should almost always be some results to show.
          let metro = findMetroFromCounty(feature, metroFilter);

          if (!metro) {
            toast("Oops, This area is not available yet...", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
              closeOnClick: true,
            });
            return;
          }

          const region = findStateFromFeature(feature);
          const state = stateList.find(
            (state) => state.label.toLowerCase() === region.toLowerCase(),
          );

          setFilteredStates(state ? [state] : []);
          setFilteredMetros([metro]);
        } else if (mapBoxGeoType === MapBoxGeoType.ZIP) {
          nextGeo = Geo.ZIP;

          let metro: MetroOption | null | undefined = metroFilter.find(
            (metro) => +metro.value === +zipMetro[feature.text],
          );

          if (!metro) metro = findMetroFromCounty(feature, metroFilter);
          if (!metro) {
            const metro_code = zipMetro[+feature.text];
            metro = metroFilter.find((item) => item.value == metro_code);
          }
          if (!metro) {
            const countyName = keys(countyZipMap).find(
              (key) =>
                !!countyZipMap[key].find(
                  (zip: number) => zip.toString() === feature.text,
                ),
            );

            const county = countyList.filter((c) => c.label === countyName);

            if (!county) {
              toast("Oops, This area is not available yet...", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
                closeOnClick: true,
              });
              return;
            }

            setFilteredCounties(county);
          }

          const region = findStateFromFeature(feature);
          const state = stateList.find(
            (state) => state.label.toLowerCase() === region.toLowerCase(),
          );

          setFilteredStates(state ? [state] : []);
          setFilteredMetros(!!metro ? [metro] : []);
        }
      }

      const long = +(
        options?.forceLatLng?.lng || feature.geometry.coordinates[0]
      );
      const lat = +(
        options?.forceLatLng?.lat || feature.geometry.coordinates[1]
      );
      const zoom = options?.forceZoom
        ? options?.forceZoom
        : geoSettingsMap[options?.forceGeo || nextGeo]?.defaultZoom;

      setFocusSearch(false);
      if (!shouldClearSearch) {
        setSearchValue(feature.text);
      }
      setSelectedFeature(feature);
      setZoom(zoom);
      setLong(long);
      setLat(lat);
      setSearchId({ long, lat, zoom });

      if (!options.onlyFly) {
        setGeo(options?.forceGeo || nextGeo);
        setSearchedGeo(nextGeo);
      }

      const query = feature?.place_name;
      setProgressQuery((prevState) => ({
        ...prevState,
        query,
        lnglat: JSON.stringify({ lat, lng: long }),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = async (value: string) => {
    setSearchValue(value);
    debouncedFetchSuggestions(value);
  };

  const reset = () => {
    setSearchValue("");
    setSuggestions([]);
    setFilteredMetros([]);
    setFilteredStates([]);
    setDefaultMetroFeature(null);
    setSelectedFeature(null);
    setGeo(Geo.METRO);
    setSearchedGeo("");
    setFilterMonth("");
    setFilterYear("");

    const flyTo = {
      long: defaultLong,
      lat: defaultLat,
      zoom: geoSettingsMap[Geo.METRO]?.defaultZoom,
    };

    setZoom(flyTo.zoom);
    setLong(flyTo.long);
    setLat(flyTo.lat);
    setSearchId(flyTo);
  };

  const moveToDefaultMetro = async (defaultMetro = user?.defaultMetro) => {
    if (!defaultMetro) return;

    const metro = metroMap.find(
      (metro) => +defaultMetro.value === +metro?.["CBSA Code"],
    );

    const query = metro?.["CBSA Full Title"]
      .split(",")[0]
      .replaceAll("-", " ")
      .concat(`, ${metro?.["State Name"]}`) as string;

    const res = await getDataByPlace(query, {
      country: "us",
      types: "region,district,place,postcode",
    });

    if (!res) return;

    if (res.features.length) {
      const [feature] = res.features;
      const [long, lat] = feature.geometry.coordinates;
      const state = stateList.find(
        (state) => +state.value === +defaultMetro["FIPS State Code"],
      );

      setSearchValue("");
      setSelectedFeature(feature);
      setDefaultMetroFeature(feature);
      setFilteredStates([state as StateOption]);
      setFilteredMetros([defaultMetro]);
      setGeo(Geo.METRO);
      setLong(long);
      setLat(lat);
      setZoom(geoSettingsMap[Geo.METRO]?.defaultZoom);
      setSearchId({
        long,
        lat,
        zoom: geoSettingsMap[Geo.METRO]?.defaultZoom,
      });
    }
  };

  const searchByQuery = async (
    query: string,
    options?: SearchOptions,
    shouldClearSearch?: boolean,
  ) => {
    const res = await getDataByPlace(query, {
      country: "us",
      types: "region,district,place,postcode",
    });

    if (res?.features?.length) {
      const [feature] = res.features;
      if (!shouldClearSearch) {
        setSearchValue(feature.text);
      }
      searchByFeature(feature, options, shouldClearSearch);
      setSuggestions(
        res.features.map((feature) => createSuggestionObject(feature)),
      );
    }
  };

  const searchByLatLng = async (
    lngLat: { lng: string; lat: string },
    options?: SearchOptions,
  ) => {
    const res = await getDataByLatLng(lngLat.lat, lngLat.lng, {
      country: "us",
      types: "region,district,place,postcode",
    });

    if (res) {
      const feature = res.features.find((feature) =>
        feature.place_type.includes(MapBoxGeoType[geo.toUpperCase()]),
      );

      if (feature) {
        setSearchValue(feature.text);
        searchByFeature(feature, options);
        setSuggestions(
          res.features.map((feature) => createSuggestionObject(feature)),
        );
      }
    }
  };

  const createSuggestionObject = (feature: Feature) => {
    return {
      label: (
        <span>
          {feature.text}{" "}
          <span css={{ fontSize: 13, color: "#808080" }}>
            {feature.place_name}
          </span>
        </span>
      ),
      value: feature.id,
      feature,
      onClick: (onlyFly: boolean) => searchByFeature(feature, { onlyFly }),
    };
  };

  const searchFromCurrentLocation = async () => {
    const _onLocationGranted = () => {
      navigator.geolocation?.getCurrentPosition(
        async ({ coords }) => {
          const { latitude, longitude } = coords;

          const res = await getDataByLatLng(
            latitude.toString(),
            longitude.toString(),
            {
              country: "us",
              types: "postcode",
            },
          );

          if (res?.features?.length) {
            const feature = res.features[0];

            setSearchValue(feature.text);
            //searchByFeature(feature);
            const suggestions = res.features.map((feature) =>
              createSuggestionObject(feature),
            );
            setSuggestions(suggestions);
            if (suggestions.length) {
              suggestions[0].onClick(false);
            }
          }
        },
        (error) => {
          console.error(error);
        },
      );
    };

    if (navigator.permissions?.query) {
      const result = await navigator.permissions.query({ name: "geolocation" });
      const permission = result.state;

      if (["granted", "prompt"].includes(permission)) {
        _onLocationGranted();
      }
    } else if (navigator.geolocation) {
      _onLocationGranted();
    }
  };

  const currentLocationSuggestion: DefaultOptionType = {
    label: (
      <Flex>
        <Image
          alt="CurrentLocation"
          src={CurrentLocationIcon}
          css={{ width: 17, height: 17, marginTop: 2 }}
        />
        <span css={{ marginLeft: 6 }}>Use my current location</span>
      </Flex>
    ),
    value: "current-location",
    onClick: () => searchFromCurrentLocation(),
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(async (value: string) => {
      if (value.length) {
        const res = await getDataByPlace(value, {
          country: "us",
          types: "region,district,place,postcode",
        });

        setSuggestions(
          (res?.features || []).map((feature) =>
            createSuggestionObject(feature),
          ),
        );
      } else {
        setSuggestions([]);
      }
    }, 500),
    [],
  );

  const setFromLastVisitedArea = () => {
    if (!isEmpty(lastVisitedArea)) {
      setLat(lastVisitedArea.lat);
      setLong(lastVisitedArea.long);
      setGeo(lastVisitedArea.geo);
      setSearchedGeo(lastVisitedArea.geo);
      setFilteredMetros(lastVisitedArea.filteredMetros);
      setFilteredStates(lastVisitedArea.filteredStates);
      setSearchId({
        lat: lastVisitedArea.lat,
        long: lastVisitedArea.long,
        zoom: lastVisitedArea.zoom,
      });
    }
  };

  return (
    <SearchContext.Provider
      value={{
        lat,
        long,
        zoom,
        filteredStates,
        searchId,
        geo,
        stateList,
        metroFilter,
        countyList,
        filteredMetros,
        filteredCounties,
        searchedGeo,
        showFocusToolTip,
        focusSearch,
        selectedFeature,
        defaultMetroFeature,
        searchValue,
        suggestions,
        currentLocationSuggestion,
        graphContext,
        searchZipsViaState,
        filterYear,
        filterMonth,
        setGraphContext,
        setGeo,
        setFilteredStates,
        setFilteredMetros,
        setFilteredCounties,
        setLat,
        setLong,
        setZoom,
        setSearchId,
        reset,
        setSearchValue,
        setSuggestions,
        handleInputChange,
        setSelectedFeature,
        searchByFeature,
        setDefaultMetroFeature,
        moveToDefaultMetro,
        searchByQuery,
        searchByLatLng,
        setSearchedGeo,
        setShowFocusTooltip,
        setFocusSearch,
        setFromLastVisitedArea,
        setSearchZipsViaState,
        setFilterYear,
        setFilterMonth,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useGeoSearch = () => {
  const values = useContext(SearchContext);

  if (!values) throw new Error("Cannot use GeoSearch outside of Provider");

  return values;
};

export default useGeoSearch;
