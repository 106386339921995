import * as Sentry from "@sentry/react";
import { Analytics } from "@vercel/analytics/react";
import ProfileFeedbackButton from "components/Feedback/ProfileFeedbackButton";
import WelcomePopUp from "components/auth/WelcomePopUp";
import { CancelSubscription } from "components/billings/CancelSubscription";
import { CancelSubscriptionFeedback } from "components/billings/CancelSubscriptionFeedback";
import { DowngradeConfirmation } from "components/billings/DowngradeConfirmationModal";
import { DowngradeSuccessfulModal } from "components/billings/DowngradeSuccessfulModal";
import { UpgradePlanModal } from "components/billings/UpgradePlanModal";
import PaymentModal from "components/plan/PaymentModal";
import PlanModal from "components/plan/PlanModal";
import { Provider } from "components/provider";
import theme from "lib/theme";
import localForage from "localforage";
import Head from "next/head";
import { useRouter } from "next/router";
import { GoogleAnalytics } from "nextjs-google-analytics";
import NextNProgress from "nextjs-progressbar";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { AuthModal } from "../components/auth/AuthModal";
import { TellUsMore } from "../components/auth/TellUsMore";
import { redirect } from "next/navigation";

// Styles
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "antd/dist/antd.css";
import "antd/dist/antd.variable.min.css";
import { CheaperSubscription } from "components/billings/CheaperSubscription";
import { DowngradingModal } from "components/billings/DowngradingModal";
import "rc-slider/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.css";
import { RenewPlanModal } from "components/billings/RenewPlanModal";
import SignupCheckoutModal from "components/SignupWithCheckout/SignupCheckoutModal";
import { YourRole } from "components/auth/YourRole";
import DeleteAccount from "components/auth/DeleteAccount";

if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_SECRET as string, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(false);
    },
  });
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const userName = useRef("");
  const whoAreYou = useRef("");
  const isMobiletPage =
    router.pathname === "/feed" || router.pathname === "/result";

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_IS_MAINTENANCE_ACTIVE === "true") {
      router.push("/maintenance");
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = () =>
      posthog.capture("$pageview", { platform: "Web" });
    router.events.on("routeChangeComplete", handleRouteChange);

    localForage.clear();
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  Sentry.addGlobalEventProcessor((event) => {
    if (
      event?.exception &&
      event?.exception?.values &&
      event?.exception?.values[0]
    ) {
      const errorValue = event.exception.values[0];
      if (errorValue.type === "Error" && errorValue.stacktrace) {
        const frames = errorValue.stacktrace.frames;
        console.log("[ERROR]- Sentry Logs ->", event);
        if (frames && frames.length > 0) {
          const culpritFrame = frames[0];
          event.extra = {
            ...event.extra,
            culpritFrame: {
              filename: culpritFrame.filename,
              function: culpritFrame.function,
              lineno: culpritFrame.lineno,
              colno: culpritFrame.colno,
            },
          };
        }
      }
    }
    return event;
  });

  return (
    <>
      <PostHogProvider client={posthog}>
        <Head>
          <title>Reventure App</title>
          <meta
            name="viewport"
            content="width=device-width, user-scalable=no"
          />
          <meta property="og:title" content="Reventure App" />
          <meta
            property="og:image"
            content={
              isMobiletPage
                ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/mobile-preview.png`
                : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/app-preview-screenshot.png`
            }
          />
          <meta
            property="og:description"
            content="Real Estate Insights in Minutes"
          />
          <meta
            property="og:url"
            content={process.env.NEXT_PUBLIC_REVENTURE_APP_URL}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
        </Head>
        <GoogleAnalytics gaMeasurementId={process.env.NEXT_PUBLIC_GA_ID} />
        <Provider>
          <NextNProgress color={theme.colors.primary} />
          <Component {...pageProps} />
          <ToastContainer />
          <Analytics />
          <AuthModal userName={userName} whoAreYou={whoAreYou} />
          {/* <TellUsMore /> */}
          <YourRole />
          {/* <PlanModal /> */}
          <SignupCheckoutModal userName={userName} whoAreYou={whoAreYou} />
          <DeleteAccount />
          <PaymentModal />
          <UpgradePlanModal />
          <DowngradeConfirmation />
          <DowngradingModal />
          <RenewPlanModal />
          <DowngradeSuccessfulModal />
          <CancelSubscription />
          <CheaperSubscription />
          <CancelSubscriptionFeedback />
          <WelcomePopUp />
          {router.pathname === "/profile/help" ? "" : <ProfileFeedbackButton />}
        </Provider>
      </PostHogProvider>
    </>
  );
}

export default MyApp;
