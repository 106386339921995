export enum Plan {
  Free = "Free",
  Premium_Monthly = "Premium Monthly",
  Premium_Yearly = "Premium Yearly",
  State_Level_Access_Monthly = "State Level Access Monthly",
  State_Level_Access_Yearly = "State Level Access Yearly",
}

export enum PlanType {
  Free = "Free",
  Premium = "Premium",
  State_Level_Access = "State Level Access",
}

export enum PlanCycle {
  Yearly = "Yearly",
  Monthly = "Monthly",
}

type Interval = "day" | "month" | "week" | "year";

type CreatePrice = {
  unit_amount: number;
  currency: string;
  recurring: { interval: Interval };
  product_data: {
    name: Plan;
  };
};

export const planConfig: { [x: string]: { amount: number; cycle: PlanCycle } } =
  {
    [Plan.Free]: {
      amount: 0,
      cycle: PlanCycle.Monthly,
    },
    [Plan.Premium_Monthly]: {
      amount: 3900,
      cycle: PlanCycle.Monthly,
    },
    [Plan.Premium_Yearly]: {
      amount: 39900,
      cycle: PlanCycle.Yearly,
    },
    [Plan.State_Level_Access_Monthly]: {
      amount: 1900,
      cycle: PlanCycle.Monthly,
    },
    [Plan.State_Level_Access_Yearly]: {
      amount: 19900,
      cycle: PlanCycle.Yearly,
    },
  };

export const planPrices = [
  {
    unit_amount: 0,
    currency: "usd",
    recurring: { interval: "month" },
    product_data: {
      name: Plan.Free,
    },
  },
  {
    unit_amount: planConfig[Plan.Premium_Monthly].amount,
    currency: "usd",
    recurring: { interval: "month" },
    product_data: {
      name: Plan.Premium_Monthly,
    },
  },
  {
    unit_amount: planConfig[Plan.Premium_Yearly].amount,
    currency: "usd",
    recurring: { interval: "year" },
    product_data: {
      name: Plan.Premium_Yearly,
    },
  },
  {
    unit_amount: planConfig[Plan.State_Level_Access_Monthly].amount,
    currency: "usd",
    recurring: { interval: "month" },
    product_data: {
      name: Plan.State_Level_Access_Monthly,
    },
  },
  {
    unit_amount: planConfig[Plan.State_Level_Access_Yearly].amount,
    currency: "usd",
    recurring: { interval: "year" },
    product_data: {
      name: Plan.State_Level_Access_Yearly,
    },
  },
] as CreatePrice[];


