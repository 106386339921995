import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { usePostHog } from "posthog-js/react";
import { useTheme } from "@emotion/react";
import { Geo } from "types/MapContext";

const DownloadGraph = ({
  geo,
  info,
  downloadReportDataMonthly,
  downloadReportDataYearly,
  onDownloadSuccess,
  isMonthlyActive,
}) => {
  const theme = useTheme();
  const posthog = usePostHog();
  const tabOrder = isMonthlyActive
    ? ["Monthly", "Yearly"]
    : ["Yearly", "Monthly"];
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();
    const addTab = (data, type) => {
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, type);
    };
    if (
      downloadReportDataMonthly.length > 0 &&
      downloadReportDataYearly.length > 0
    ) {
      tabOrder.forEach((tab) => {
        addTab(
          tab === "Monthly"
            ? downloadReportDataMonthly
            : downloadReportDataYearly,
          tab,
        );
      });
    } else if (
      downloadReportDataYearly.length === 0 &&
      !downloadReportDataMonthly[0]?.date?.toString().includes("/")
    ) {
      addTab(downloadReportDataMonthly, "Yearly");
    } else if (downloadReportDataMonthly[0]?.date.toString().includes("/")) {
      addTab(downloadReportDataMonthly, "Monthly");
    }

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    saveAs(
      blob,
      `reventure-data-${geo}-${
        geo === Geo.ZIP ? info.GEOID : info.FULLNAME || info.NAME
      }.xlsx`,
    );

    if (onDownloadSuccess) {
      onDownloadSuccess();
    }
  };

  const s2ab = (s: string) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return (
    <Button
      type="primary"
      css={{
        background: theme.colors.black,
        borderColor: theme.colors.black,
      }}
      icon={<DownloadOutlined />}
      onClick={() => {
        posthog.capture("Download Graph Data", { platform: "Web" });
        downloadExcel();
      }}
    ></Button>
  );
};

export default DownloadGraph;
