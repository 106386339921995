import {Query} from "@cubejs-client/core";
import {useCubeQuery} from "@cubejs-client/react";
import {
  getHistoricalTooltipQueryCodeMap,
  getHistoricalTooltipQueryList,
  getQueryCodeMap,
  getQueryList,
  getScoreQueryCodeMap,
  getTooltipQueryCodeMap,
  getTooltipQueryList,
} from "lib/cube/utils";
import {countyZipMap} from "lib/options/countyZipMap";
import metroZipMap from "lib/options/metroZipMap";
import {stateZipMap} from "lib/options/stateZipMap";
import {mapKeys} from "lodash";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Geo} from "types/MapContext";
import {Cube, CurrentDataPoints, RawCurrentDataPoints} from "types/cube";
import useGeoSearch from "./useGeoSearch";
import {useUserData} from "./useUser";
import {useRouter} from "next/router";
import {stateCoordinates} from "lib/constants";

interface MapData {
  isCurrentLoading: boolean;
  currentData: CurrentDataPoints[];
}

const MapDataContext = createContext<MapData | undefined>(undefined);

const createTooltipQuery = (
    geo: Geo,
    additionalFilters: Query["filters"] = [],
    skipPremium?: boolean
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getTooltipQueryList("current", skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

const createHistoricalTooltipQuery = (
    geo: Geo,
    additionalFilters: Query["filters"] = [],
    filterYear: any,
    skipPremium?: boolean
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getHistoricalTooltipQueryList("current", filterYear , skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

const createHistoricalQuery = (
  geo: Geo,
  additionalFilters: Query["filters"] = [],
  skipPremium?: boolean,
  date?: string
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getQueryList("time-series", skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    {
      member: "time_series_data_set.date",
      operator: "equals",
      values: [
          date as string
      ]
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

const createQuery = (
    geo: Geo,
    additionalFilters: Query["filters"] = [],
    skipPremium?: boolean,
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getQueryList("current", skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

const createScoreQuery = (
    geo: Geo,
    additionalFilters: Query["filters"] = [],
    skipPremium?: boolean,
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getQueryList("score-current", skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

const createHistoricalScoreQuery = (
    geo: Geo,
    additionalFilters: Query["filters"] = [],
    date?: string,
    skipPremium?: boolean,
): Query => {
  const dimensions: Query["dimensions"] = [
    `${Cube.GEO_DATA}.geo_name`,
    `${Cube.GEO_DATA}.geo_code`,
    `${Cube.GEO_DATA}.state_code`,

    ...getQueryList("score-time-series", skipPremium),
  ];

  const filters: Query["filters"] = [
    {
      member: "geo_data.geo_type",
      operator: "equals",
      values: [geo],
    },
    {
      member: "score_time_series_data_set.date",
      operator: "equals",
      values: [
        date as string
      ]
    },
    ...additionalFilters,
  ];

  return { dimensions, filters };
};

export const MapDataProvider = ({ children }) => {

  const {
    geo,
    selectedFeature,
    searchedGeo,
    filteredMetros,
    filteredCounties,
    setZoom,
    setLat,
    setLong,
    setSearchId,
    searchZipsViaState,
    filterYear,
    filterMonth
  } = useGeoSearch();
  const user = useUserData();

  const router = useRouter();

  const [cache, setCache] = useState<{ [key: string]: any }>({});

  const filterCodes = (): number[] => {
    if (searchedGeo === Geo.STATE && selectedFeature) {
      return stateZipMap[selectedFeature.text] || [];
    }

    if (searchedGeo === Geo.COUNTY && filteredCounties.length) {
      return filteredCounties
          .map((county) => countyZipMap[county.label])
          .flat();
    }

    if (searchedGeo === Geo.ZIP || !searchedGeo) {
      const zips: number[] = [];

      filteredMetros.forEach((metro) => {
        if (metroZipMap[metro.value]) {
          zips.push(...metroZipMap[metro.value]);
        }
      });

      filteredCounties.forEach((county) => {
        if (countyZipMap[county.label]) {
          zips.push(...countyZipMap[county.label]);
        }
      });

      return zips;
    }

    return [];
  };
  const filterZipCodes = (list) => {
    return list.map((code) => {
      let stringifiedCode = code.toString();
      if (stringifiedCode.length == 3) stringifiedCode = "00" + stringifiedCode;
      if (stringifiedCode.length == 4) stringifiedCode = "0" + stringifiedCode;
      return stringifiedCode;
    });
  };

  const filteredZips = useMemo(() => {
    if (geo !== Geo.ZIP) return [];
    const zips =
        searchZipsViaState !== "All States"
            ? stateZipMap[searchZipsViaState].slice(0, 3000)
            : filterCodes();
    return filterZipCodes(zips);
  }, [selectedFeature, geo, filteredMetros, searchZipsViaState]);

  let cacheKey = geo.valueOf()  + !user.isPremiumOrBasic + filterMonth + filterYear;
  const cubeQuery = useMemo(() => {
    let queryObject = filterYear != "" && filterMonth != "" ? createHistoricalQuery(
      geo,
      filteredZips?.length
        ? [
            {
              member: "geo_data.geo_code",
              operator: "equals",
              values: filteredZips,
            },
          ]
        : [],
      !user.isPremiumOrBasic,
      filterYear + '-' + filterMonth
    ): createQuery(
        geo,
        filteredZips?.length
            ? [
              {
                member: "geo_data.geo_code",
                operator: "equals",
                values: filteredZips,
              },
            ]
            : [],
        !user.isPremiumOrBasic
    );
    cacheKey = geo + !user.isPremiumOrBasic + filterMonth + filterYear;
    return queryObject;
  }, [geo, filteredZips, filterMonth, filterYear, user.isPremiumOrBasic]);

  const cubeScoreQuery = useMemo(() => {
    let queryObject = filterYear != "" && filterMonth != ""
        ?createHistoricalScoreQuery(
            geo,
            filteredZips?.length
                ? [
                  {
                    member: "geo_data.geo_code",
                    operator: "equals",
                    values: filteredZips,
                  },
                ]
                : [],
            filterYear + '-' + filterMonth,
            !user.isPremiumOrBasic,
        ):
        createScoreQuery(
        geo,
        filteredZips?.length
            ? [
              {
                member: "geo_data.geo_code",
                operator: "equals",
                values: filteredZips,
              },
            ]
            : [],
        !user.isPremiumOrBasic,
    );
    cacheKey = geo + !user.isPremiumOrBasic + filterMonth + filterYear;
    return queryObject;
  }, [geo, filteredZips, filterMonth, filterYear, user.isPremiumOrBasic]);

  const cubeTooltipQuery = useMemo(() => {
    let queryObject = filterYear != ''
    ?createHistoricalTooltipQuery(
            geo,
            filteredZips?.length
                ? [
                  {
                    member: "geo_data.geo_code",
                    operator: "equals",
                    values: filteredZips,
                  },
                ]
                : [],
            filterYear,
            !user.isPremiumOrBasic,
        )
    :createTooltipQuery(
        geo,
        filteredZips?.length
            ? [
              {
                member: "geo_data.geo_code",
                operator: "equals",
                values: filteredZips,
              },
            ]
            : [],
        !user.isPremiumOrBasic,
    );
    cacheKey = geo + !user.isPremiumOrBasic + filterMonth + filterYear;
    return queryObject;
  }, [geo, filteredZips, filterMonth, filterYear, user.isPremiumOrBasic]);

  const shouldReturnData = !(geo === Geo.ZIP) || !!filteredZips?.length;

  let { isLoading: isCurrentLoading, resultSet: currentResultSet } =
      useCubeQuery<RawCurrentDataPoints>(cubeQuery, {
      skip: !shouldReturnData || router.pathname.includes("/report-pdf") || cache[cacheKey] != undefined,
        resetResultSetOnChange: true,
        castNumerics: true,
      });

  let { isLoading: isCurrentScoreLoading, resultSet: currentScoreResultSet } =
      useCubeQuery<RawCurrentDataPoints>(cubeScoreQuery, {
        skip: !shouldReturnData || router.pathname.includes("/report-pdf") || cache[cacheKey] != undefined,
        resetResultSetOnChange: true,
        castNumerics: true,
      });

  let { isLoading: isCurrentTooltipLoading, resultSet: currentTooltipResultSet } =
      useCubeQuery<RawCurrentDataPoints>(cubeTooltipQuery, {
        skip: !shouldReturnData || router.pathname.includes("/report-pdf") || cache[cacheKey] != undefined,
        resetResultSetOnChange: true,
        castNumerics: true,
      });

  const currentDataSet = useMemo(() => {
      if (!shouldReturnData) return [];
      if (searchZipsViaState !== "All States" && geo === Geo.ZIP) {
        const zipMetro = stateCoordinates?.find(
            (item) => item.name === searchZipsViaState,
        );
        if (zipMetro) {
          setZoom(6);
          setLat(zipMetro.lat);
          setLong(zipMetro.lon);
          setSearchId({
            lat: zipMetro.lat,
            long: zipMetro.lon,
            zoom: 6,
          });
        }
      }

      const queryCodeMap = getQueryCodeMap("current");
      const queryScoreCodeMap = getScoreQueryCodeMap();

      return (
          currentResultSet?.rawData()?.map((item) => {
            const areaData = mapKeys(item, (v, k) => {
              return queryCodeMap[k] || queryScoreCodeMap[k] || k.split(".")[1];
            });
            areaData.geo = geo;
            return areaData;
          }) || []
      );
  }, [currentResultSet, filterMonth, filterYear]) as unknown as CurrentDataPoints[];

  const currentScoreDataSet = useMemo(() => {
    if (!shouldReturnData) return [];
    if (searchZipsViaState !== "All States" && geo === Geo.ZIP) {
      const zipMetro = stateCoordinates?.find(
          (item) => item.name === searchZipsViaState,
      );
      if (zipMetro) {
        setZoom(6);
        setLat(zipMetro.lat);
        setLong(zipMetro.lon);
        setSearchId({
          lat: zipMetro.lat,
          long: zipMetro.lon,
          zoom: 6,
        });
      }
    }

    const queryCodeMap = getQueryCodeMap("current");
    const queryScoreCodeMap = getScoreQueryCodeMap();

    return (
        currentScoreResultSet?.rawData()?.map((item) => {
          const areaData = mapKeys(item, (v, k) => {
            return queryCodeMap[k] || queryScoreCodeMap[k] || k.split(".")[1];
          });
          areaData.geo = geo;
          return areaData;
        }) || []
    );
  }, [currentScoreResultSet]) as unknown as CurrentDataPoints[];

  const currentTooltipDataSet = useMemo(() => {
    if (!shouldReturnData) return [];
    if (searchZipsViaState !== "All States" && geo === Geo.ZIP) {
      const zipMetro = stateCoordinates?.find(
          (item) => item.name === searchZipsViaState,
      );
      if (zipMetro) {
        setZoom(6);
        setLat(zipMetro.lat);
        setLong(zipMetro.lon);
        setSearchId({
          lat: zipMetro.lat,
          long: zipMetro.lon,
          zoom: 6,
        });
      }
    }

    const queryCodeMap = filterYear != '' ? getHistoricalTooltipQueryCodeMap("current",filterYear):getTooltipQueryCodeMap("current");
    const queryScoreCodeMap = getScoreQueryCodeMap();

    return (
        currentTooltipResultSet?.rawData()?.map((item) => {
          const areaData = mapKeys(item, (v, k) => {
            return queryCodeMap[k] || queryScoreCodeMap[k] || k.split(".")[1];
          });
          areaData.geo = geo;
          return areaData;
        }) || []
    );
  }, [currentTooltipResultSet]) as unknown as CurrentDataPoints[];

  const renameAttributes = (arr: any[], suffix: string, newSuffix: string): any[] => {
    return arr.map(item => {
      if (typeof item !== "object" || item === null) {
        return item;
      }

      const newObj: Record<string, any> = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (key.includes(suffix)) {
            const newKey = key.replace(suffix, newSuffix);
            newObj[newKey] = item[key];
          } else {
            newObj[key] = item[key];
          }
        }
      }
      return newObj;
    });
  };


  const mergeDataSetsByGeoName = (array1: any[], array2: any[]): any[] => {

    if(cache[cacheKey] != undefined)
      return cache[cacheKey];

    const combinedArray = [...array1, ...array2];

    const mergedObjects = combinedArray.reduce((acc, current) => {
      const geoName = current.geo_name;

      if (!acc.has(geoName)) {
        acc.set(geoName, { ...current });
      } else {
        acc.set(geoName, { ...acc.get(geoName), ...current });
      }

      return acc;
    }, new Map());
    return Array.from(mergedObjects.values());
  };

  useEffect(() => {
    if (geo != Geo.ZIP && cacheKey && !cache[cacheKey]
        && currentResultSet != undefined && currentDataSet != undefined && currentDataSet?.length > 0 && !isCurrentLoading
        && currentScoreResultSet != undefined && currentScoreDataSet != undefined && currentScoreDataSet?.length > 0 && !isCurrentScoreLoading
        && currentTooltipResultSet != undefined && currentTooltipDataSet != undefined && currentTooltipDataSet?.length > 0 && !isCurrentTooltipLoading
    ) {
      setCache((prevCache) => ({
        ...prevCache,
        [cacheKey]: mergeDataSetsByGeoName(mergeDataSetsByGeoName(currentDataSet,currentScoreDataSet),currentTooltipDataSet),
      }));
    }
  }, [currentResultSet, currentScoreResultSet, currentTooltipResultSet]);


  const currentData = useMemo(() => {
    return  mergeDataSetsByGeoName(mergeDataSetsByGeoName(currentDataSet,currentScoreDataSet),currentTooltipDataSet);
  }, [currentResultSet, currentScoreResultSet, currentTooltipResultSet, cacheKey, shouldReturnData]) as unknown as CurrentDataPoints[];

  return (
      <MapDataContext.Provider
          value={{
            currentData,
            isCurrentLoading,
          }}
      >
        {children}
      </MapDataContext.Provider>
  );
};

const useMapData = () => {
  const values = useContext(MapDataContext);
  if (!values) {
    throw new Error("Cannot use MapData outside MapDataContext");
  }

  return values;
};

export default useMapData;
