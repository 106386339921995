import { BinaryFilter } from "@cubejs-client/core";
import { useCubeQuery } from "@cubejs-client/react";
import { checkNumberValidity } from "lib/helpers/common";
import { convertCubeKeyName } from "lib/helpers/convertCubeKeyName";
import { getTooltipNameForCode } from "lib/helpers/exploratoryHelpers";
import { formatterMap } from "lib/options/formatterMap";
import { SecondaryDatapoint } from "lib/options/timeseriesDatapointConfig";
import scoreConfigs from "lib/scoreBoard/scoreConfigs";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Geo } from "types/MapContext";
import { CombinedDatapoints, Cube, Datapoints } from "types/cube";

type Props = {
  primaryDatapoint: CombinedDatapoints;
  subPrimaryDatapoint: CombinedDatapoints;
  secondaryDatapoints?: SecondaryDatapoint[];
  historicalComparison?: CombinedDatapoints;
  geoCode: string;
  geo: Geo;
  datapoint: CombinedDatapoints;
  stateCode?: string;
  isMOM?: boolean;
  hasMonthly?: boolean;
  hasActiveMonth?: boolean;
  showYearly?: boolean;
  showSecondaryLine?: boolean;
  startYear?: number;
  startMonth?: number;
};

type Data = {
  [x: string]: number | string;
  year: number;
  date: string;
};

export type GraphTooltipValue = {
  [x: string]: {
    label: string;
    value: string;
  }[];
};

export type PlotData = {
  label: number | string;
  value: number;
  date: number | string;
};

export const useTimeSeriesData = ({
  primaryDatapoint,
  subPrimaryDatapoint,
  historicalComparison,
  secondaryDatapoints = [],
  geoCode,
  datapoint,
  geo,
  stateCode,
  isMOM,
  hasActiveMonth,
  hasMonthly,
  startYear,
  showYearly,
  showSecondaryLine,
  startMonth,
}: Props) => {
  const [plotData, setPlotData] = useState<PlotData[]>([]);
  const [subPlotData, setSubPlotData] = useState<PlotData[]>([]);

  const activeMonthRef = useRef<number | undefined>();
  const latestMonthRef = useRef<number | undefined>();
  const [tooltipValues, setTooltipValues] = useState<GraphTooltipValue>();
  const [subTooltipValues, setSubTooltipValues] = useState<GraphTooltipValue>();

  const { primaryFormatter } = formatterMap[primaryDatapoint];
  const isMonthlyGraphShown = hasMonthly && !showYearly;

  const cube = useMemo(() => {
    const isScoreData = scoreConfigs.some(
      (c) =>
        c.code === datapoint || c.extraData?.some((e) => e.code === datapoint),
    );
    return isScoreData ? Cube.TIME_SERIES_SCORE : Cube.TIME_SERIES_DATA_SET;
  }, [datapoint]);

  const datapointsToFetch = Array.from(
    new Set([
      `${cube}.${primaryDatapoint}`,
      `${cube}.year`,
      `${cube}.date`,
      ...secondaryDatapoints
        .map((dp) =>
          dp.calculation
            ? dp.calculation.datapoints.map((dp) => `${cube}.${dp.datapoint}`)
            : `${cube}.${dp.datapoint}`,
        )
        .flat(),
      ...(historicalComparison ? [`${cube}.${historicalComparison}`] : []),
    ]),
  );

  const { isLoading: isTimeSeriesLoading, resultSet } = useCubeQuery<Data>(
    {
      dimensions: datapointsToFetch,
      filters: [
        ...(geo === Geo.COUNTY
          ? ([
              {
                member: "geo_data.state_code",
                operator: "equals",
                values: [stateCode],
              },
              {
                member: "geo_data.geo_code",
                operator: "equals",
                values: [geoCode],
              },
            ] as BinaryFilter[])
          : ([
              {
                member: "geo_data.geo_code",
                operator: "equals",
                values: [geoCode],
              },
            ] as BinaryFilter[])),
        { member: "geo_data.geo_type", operator: "equals", values: [geo] },
      ],
      order: {
        [`${cube}.date`]: "desc",
      },
    },
    { castNumerics: true, resetResultSetOnChange: false },
  );

  const data = resultSet?.rawData();
  useEffect(() => {
    setPlotData([]);
    setSubPlotData([]);
    setTooltipValues(undefined);
    setSubTooltipValues(undefined);
    activeMonthRef.current = undefined;
    latestMonthRef.current = undefined;

    if (data) {
      const transformedData = transformRawData(data);

      const filteredData = filterDataForPlot(transformedData);

      setPlotData(getPlotValues(filteredData));
      setSubPlotData(getSubPlotValues(filteredData));

      const historicalComparisonValues = getHistoricalComparisonObject(
        transformedData,
        filteredData,
        transformedData,
      );

      setTooltipValues(
        computeTooltipValues(
          filteredData,
          historicalComparisonValues,
          transformedData,
        ),
      );

      setSubTooltipValues(
        computeSubTooltipValues(filteredData, transformedData),
      );
    }
  }, [data, showYearly, showSecondaryLine]);

  const transformRawData = (data: Data[]) => {
    return data.map((item) => {
      const obj = {};
      Object.keys(item).forEach((key) => {
        const cubeDimension = convertCubeKeyName(key);
        obj[cubeDimension] = item[key];
      });
      if (!showYearly && hasActiveMonth) {
        hasActiveMonth = false;
      }
      if (checkNumberValidity(obj[primaryDatapoint]) && hasActiveMonth) {
        activeMonthRef.current =
          activeMonthRef.current || moment(obj["date"]).month() + 1;
        latestMonthRef.current =
          latestMonthRef.current || moment(obj["date"]).month() + 1;
      }
      return obj as Data;
    });
  };

  const filterDataForPlot = (data: Data[]) => {
    const yearsTracked = {};
    return data
      .filter((item) => {
        if (isMonthlyGraphShown) {
          return checkNumberValidity(item[datapoint]);
        }
        if (startYear && item.year < startYear) {
          return false;
        }
        if (
          startMonth &&
          moment(item.date).month() + 1 < startMonth &&
          startYear &&
          startYear === item.year
        ) {
          return false;
        }
        if (
          !checkNumberValidity(item[primaryDatapoint]) ||
          yearsTracked[item.year]
        ) {
          return false;
        }
        if (activeMonthRef.current) {
          const month = moment(item.date).month() + 1;
          if (month !== activeMonthRef.current) {
            return false;
          }
        }
        if (!isMOM) {
          yearsTracked[item.year] = 1;
        }
        return true;
      })
      .sort((a, b) => moment(a.date).unix() - moment(b.date).unix());
  };

  const getPlotValues = (data: Data[]) => {
    return data.map((item) => ({
      value: item[primaryDatapoint] as number,
      label:
        isMOM ||
        isMonthlyGraphShown ||
        datapoint === "buy_versus_rent_percentage"
          ? moment(item.date).format("MM/DD/YY")
          : (item.year as number | string),
      date: item.date,
    }));
  };

  const getSubPlotValues = (data: Data[]) => {
    return data.map((item) => ({
      value: item[subPrimaryDatapoint] as number,
      label:
        isMOM || isMonthlyGraphShown
          ? moment(item.date).format("MM/DD/YY")
          : (item.year as number | string),
      date: item.date,
    }));
  };

  const getHistoricalComparisonObject = (
    data: Data[],
    filteredData: Data[],
    transformedData: Data[],
  ) => {
    if (historicalComparison) {
      const isFiveYearComparison =
        primaryDatapoint === Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE;

      const firstValue = data.find((item) => {
        const targetYear = isFiveYearComparison
          ? filteredData[0].year - 5
          : filteredData[0].year - 1;

        const targetMonth =
          activeMonthRef.current || moment(filteredData[0].date).month() + 1;

        const itemMonth = moment(item.date).month() + 1;

        if (!item[historicalComparison]) {
          return false;
        }

        if (item.year === targetYear && itemMonth === targetMonth) {
          return true;
        }
        return false;
      });

      const filteredTransformedData = transformedData.filter((data) =>
        checkNumberValidity(data[historicalComparison]),
      );

      const historicalComparisonValues = filteredData.reduce<{
        [x: string]: {
          prevDate: string | undefined;
          prevValue: string | number;
          currentDate: string;
          currentValue: string | number;
        };
      }>((compObj, item) => {
        const targetYear = isFiveYearComparison ? item.year - 5 : item.year - 1;
        const targetMonth =
          activeMonthRef.current || moment(item.date).month() + 1;

        const prevYearData = transformedData.find(
          (data) =>
            data.year === targetYear &&
            moment(data.date).month() + 1 === targetMonth &&
            data[historicalComparison],
        );

        compObj[item.date] = {
          prevDate: prevYearData?.date || firstValue?.date || item.date,
          prevValue:
            prevYearData?.[historicalComparison] ||
            firstValue?.[historicalComparison] ||
            0,
          currentDate: item.date,
          currentValue: item[historicalComparison],
        };

        return compObj;
      }, {});
      return historicalComparisonValues;
    }
    return {};
  };

  const formatTooltipDate = (date: string) => {
    return moment(date).format(
      activeMonthRef.current || hasMonthly ? "MMM. YYYY" : "YYYY",
    );
  };

  const formatTooltipValue = (value, datapoint) => {
    const { primaryFormatter: pFormatter, secondaryFormatter: sFormatter } =
      formatterMap[datapoint];
    const formatter = sFormatter || pFormatter;
    return formatter(value);
  };

  const getSecondaryDatapointLabel = (
    currentData: Data,
    secondaryDatapoint: SecondaryDatapoint,
  ) => {
    if (secondaryDatapoint.fetchPrev) {
      return moment(currentData.date).subtract("1", "M").format("MMM. YYYY");
    }

    return moment(currentData.date).format("MMM. YYYY");
  };

  const getSecondaryDatapointValue = (
    currentData: Data,
    secondaryDatapoint: SecondaryDatapoint,
    transformedData: Data[],
  ) => {
    if (secondaryDatapoint.fetchSpecificDate) {
      const { year, month } = secondaryDatapoint.fetchSpecificDate;
      const specificDateData = transformedData.find(
        (item) => item.year === year && moment(item.date).month() + 1 === month,
      );
      return specificDateData?.[secondaryDatapoint.datapoint] || 0;
    }

    if (secondaryDatapoint.fetchPrev) {
      const currentDataMonth = moment(currentData.date).month() + 1;
      currentData =
        transformedData.find((item) => {
          const currentMonth = moment(item.date).month() + 1;
          if (isMOM) {
            return currentDataMonth === 1
              ? item.year === currentData.year - 1 && currentMonth === 12
              : currentData.year === item.year &&
                  currentDataMonth - 1 === currentMonth;
          }
        }) || currentData;
    }
    if (secondaryDatapoint.calculation) {
      const values = secondaryDatapoint.calculation.datapoints.map((dp) => {
        return Number(
          transformedData.find(
            (item) => item.year === currentData.year && item[dp.datapoint],
          )?.[dp.datapoint] || 0,
        );
      });
      return secondaryDatapoint.calculation.getValue(...values);
    }
    if (secondaryDatapoint.replaceWithPrevYear) {
      const data = transformedData.find((item) => {
        const current =
          item.year === currentData.year && item[secondaryDatapoint.datapoint];

        if (!current) {
          return (
            item.year === currentData.year - 1 &&
            item[secondaryDatapoint.datapoint]
          );
        }

        return current;
      });

      return data?.[secondaryDatapoint.datapoint] || 0;
    }
    if (currentData[secondaryDatapoint.datapoint]) {
      return Number(currentData[secondaryDatapoint.datapoint] || 0);
    } else {
      if (secondaryDatapoint.isYearly) {
        return Number(
          transformedData.find(
            (item) =>
              item.year === currentData.year &&
              item[secondaryDatapoint.datapoint],
          )?.[secondaryDatapoint.datapoint] || 0,
        );
      }
      return 0;
    }
  };

  const computeTooltipValues = (
    data: Data[],
    historicalComparisonObject,
    transformedData: Data[],
  ) => {
    const computedTooltipValues = {};

    data.forEach((item) => {
      const key = item.date;

      const formattedDate = isMonthlyGraphShown
        ? moment(item.date).format("MMM. YYYY")
        : isMOM
          ? moment(item.date).format("MMM. YYYY")
          : formatTooltipDate(item.date);

      computedTooltipValues[key] =
        historicalComparison && historicalComparisonObject
          ? [
              ...(activeMonthRef.current
                ? [
                    {
                      label: "Date",
                      value: formattedDate,
                    },
                  ]
                : []),
              {
                label: getTooltipNameForCode(primaryDatapoint),
                value: primaryFormatter(Number(item[primaryDatapoint])),
              },
              {
                label: formatTooltipDate(
                  historicalComparisonObject[item.date].prevDate,
                ),
                value: formatTooltipValue(
                  historicalComparisonObject[item.date].prevValue,
                  historicalComparison,
                ),
              },
              {
                label: formatTooltipDate(
                  historicalComparisonObject[item.date].currentDate,
                ),
                value: formatTooltipValue(
                  historicalComparisonObject[item.date].currentValue,
                  historicalComparison,
                ),
              },
            ]
          : tooltipData(formattedDate, item, transformedData);
    });
    return computedTooltipValues;
  };

  const computeSubTooltipValues = (data: Data[], transformedData: Data[]) => {
    const computedTooltipValues = {};

    data.forEach((item) => {
      const key = item.date;

      const formattedDate = isMonthlyGraphShown
        ? moment(item.date).format("MMM. YYYY")
        : isMOM
          ? moment(item.date).format("MMM. YYYY")
          : formatTooltipDate(item.date);

      computedTooltipValues[key] = subTooltipData(
        formattedDate,
        item,
        transformedData,
      );
    });
    return computedTooltipValues;
  };

  const tooltipData = (formattedDate, item, transformedData) => {
    return primaryDatapoint != Datapoints.SALARY_TO_AFFORD_HOUSE &&
      primaryDatapoint != Datapoints.VACANCY_RATE
      ? [
          {
            label: "Date",
            value: formattedDate,
          },
          {
            label: getTooltipNameForCode(primaryDatapoint),
            value: primaryFormatter(Number(item[primaryDatapoint])),
          },
          ...secondaryDatapoints
            .filter(
              (dp) =>
                dp.isSecondaryGraph === false ||
                dp.isSecondaryGraph === undefined,
            )
            .map((dp) => {
              return {
                label: dp.label || getSecondaryDatapointLabel(item, dp),
                value: formatTooltipValue(
                  getSecondaryDatapointValue(item, dp, transformedData),
                  dp.datapoint,
                ),
              };
            }),
        ]
      : [
          {
            label: "Date",
            value: formattedDate,
          },
          ...secondaryDatapoints
            .filter(
              (dp) =>
                dp.isSecondaryGraph === false ||
                dp.isSecondaryGraph === undefined,
            )
            .map((dp) => {
              return {
                label: dp.label || getSecondaryDatapointLabel(item, dp),
                value: formatTooltipValue(
                  getSecondaryDatapointValue(item, dp, transformedData),
                  dp.datapoint,
                ),
              };
            }),
          {
            label: getTooltipNameForCode(primaryDatapoint),
            value: primaryFormatter(Number(item[primaryDatapoint])),
          },
        ];
  };

  const subTooltipData = (formattedDate, item, transformedData) => {
    return [
      {
        label: "Date",
        value: formattedDate,
      },
      ...secondaryDatapoints
        .filter((dp) => dp.isSecondaryGraph === true)
        .map((dp) => {
          return {
            label: dp.label || getSecondaryDatapointLabel(item, dp),
            value: formatTooltipValue(
              getSecondaryDatapointValue(item, dp, transformedData),
              dp.datapoint,
            ),
          };
        }),
    ];
  };

  return {
    tooltipValues,
    subTooltipValues,
    plotData,
    subPlotData,
    isTimeSeriesLoading,
    activeMonth: activeMonthRef.current,
    latestMonth: latestMonthRef.current,
  };
};
