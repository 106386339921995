// @ts-nocheck
import {useMemo} from "react";
import {useCubeQuery} from "@cubejs-client/react";
import {Query} from "@cubejs-client/core";
import {Geo} from "types/MapContext";
import scoreConfigs from "../lib/scoreBoard/scoreConfigs";
import {Cube} from "../types/cube";
import useFilters from "./useFilters";

const extractDatesIfNotLoading = (resultSet, isDataLoading, cube) => {
    if (isDataLoading) {
        return [];
    }
    return resultSet?.rawData()?.map(
        (item) => item[`${cube}.date`]
    ) || [];
};

export const useMapDateRange = (geo: Geo) => {
    const {exploratory} = useFilters();

    const createQuery = (
        geo: Geo,
        cube: string
    ): Query => {
        const dimensions: Query["dimensions"] = [`${cube}.date`];

        const filters: Query["filters"] = [
            {
                member: "geo_data.geo_type",
                operator: "equals",
                values: [geo],
            },
            {
                "member": `${cube}.${exploratory}`,
                "operator": "notEquals",
                "values": [
                    null as any
                ]
            }
        ];
        return {dimensions, filters};
    };


    const cube = useMemo(() => {
        const isScoreData = scoreConfigs.some(
            (c) =>
                c.code === exploratory || c.extraData?.some((e) => e.code === exploratory),
        );
        return isScoreData ? Cube.TIME_SERIES_SCORE : Cube.TIME_SERIES_DATA_SET;
    }, [exploratory]);

    const cubeQuery = useMemo(() => {
        return createQuery(
            geo,
            cube
        );
    }, [geo, exploratory]);

    const {isLoading: isDataLoading, resultSet} = useCubeQuery(cubeQuery, {
        skip: false,
        resetResultSetOnChange: true,
        castNumerics: true,
    });

    const dates = useMemo(() => {
        return extractDatesIfNotLoading(resultSet, isDataLoading, cube);
    }, [resultSet]);

    return {isDataLoading, dates};
};
