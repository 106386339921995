import { allExploratoryOptions } from "lib/options/exploratory";
import { exploratoryMap } from "lib/options/exploratoryMap";
import scoreDatapointsMap from "lib/scoreBoard/scoreDatapointsMap";
import { keys } from "lodash";
import { ExploratorySources } from "types/exploratory";
import {
  getNameForScoreCode,
  getShortSummaryForScoreCode,
  getSummaryForScoreCode,
} from "./scoreHelpers";
import { Datapoints } from "../../types/cube";

export const getSourceLink = (source: ExploratorySources, value?: string) => {
  const links = {
    [ExploratorySources.ZILLOW]: "https://www.zillow.com/research/data/",
    [ExploratorySources.REALTOR]: "https://www.realtor.com/research/data/",
    [ExploratorySources.FRED]:
      "https://fred.stlouisfed.org/series/MORTGAGE30US",
    [ExploratorySources.ACS]:
      "https://www.census.gov/programs-surveys/acs/data.html",
    [ExploratorySources.ACS_MIGRATION]:
      "https://www.census.gov/programs-surveys/popest/data/tables.html",
    [ExploratorySources.BLS]: "https://www.bls.gov/",
  };

  if (value && ["migration_percentage", "total_migration"].includes(value)) {
    return links[ExploratorySources.ACS_MIGRATION];
  }
  return links[source] || "";
};

export const getTooltipNameForCode = (code: string) => {
  return isCustomTooltipName(code)
    ? isCustomTooltipNameForCode(code, getNameForCode(code))
    : getNameForCode(code);
};

export const getNameForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].name;

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].name;
    }
  }

  return getNameForScoreCode(code) || "";
};

const isCustomTooltipName = (code: String) => {
  return code === Datapoints.PERCENT_CRASH_GREAT_RECESSION;
};

const isCustomTooltipNameForCode = (code: String, name: String) => {
  if (code === Datapoints.PERCENT_CRASH_GREAT_RECESSION) {
    return name.replace("% ", "");
  }
};

export const getSummaryForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].summary;

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].summary;
    }
  }

  return getSummaryForScoreCode(code) || "";
};

export const getShortSummaryForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].summary;

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].shortSummary;
    }
  }

  return getShortSummaryForScoreCode(code) || "";
};

export const getCodeForLabel = (label: string) => {
  for (const property in exploratoryMap) {
    if (exploratoryMap[property].name === label) {
      return exploratoryMap[property].code;
    }
  }

  return "";
};

export const getSourceForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].sources[0];

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].sources[0];
    }
  }
  return "";
};

export const getSourcesForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].sources;

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].sources;
    }
  }
  return "";
};

export const getSourcesTextForCode = (code: string, hideLinks?: boolean) => {
  const isScore = isExploratoryScore(code);

  if (isScore) return "Reventure App";

  const mapping = exploratoryMap[code];
  if (!!mapping) {
    let sources: string;

    if (mapping.calculated && hideLinks) {
      sources = `Reventure App Calculation`;
    } else {
      sources = mapping?.sources
        .map(
          (source) =>
            `<a href="${getSourceLink(
              source,
              mapping.code,
            )}" target="_blank">${source}</a>`,
        )
        .join(" / ");
      if (mapping?.calculated) {
        sources = `Reventure App Calculation (Data from ${sources})`;
      }
    }
    return sources;
  }

  return "";
};

export const getDescriptionForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].description;

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].description;
    }
  }
  return "";
};

export const getCensusCodesForExploratory = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].censusCodes || [];

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property]?.censusCodes || [];
    }
  }
  return "";
};

export const getRangeForCode = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code].range;

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property].range;
    }
  }
  return "";
};

export const getExploratoryMap = (code: string) => {
  if (exploratoryMap[code]) return exploratoryMap[code];

  for (const property in exploratoryMap) {
    if (exploratoryMap[property].code === code) {
      return exploratoryMap[property];
    }
  }
};

export const getExploratoryVideoCode = (code: string) => {
  const exploratory = allExploratoryOptions.find(
    (exploratory) => exploratory.value === code,
  );

  if (exploratory) {
    return exploratory.ytVideoCode;
  }

  return "";
};

export const isExploratoryScore = (code: string) => {
  return keys(scoreDatapointsMap).includes(code);
};
