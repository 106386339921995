import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { posthog } from "posthog-js";
import { useEffect } from "react";
import { TooltipRenderProps } from "react-joyride";

export const TooltipComponent = ({
  tooltipProps,
  step,
  size,
  index: stepIndex,
  skipProps,
  primaryProps,
  isLastStep,
  captureOnboardingEvents,
  handleTimeout,
  handleNext,
}: TooltipRenderProps & {
  captureOnboardingEvents?: boolean;
  handleTimeout: (v: number) => void;
  handleNext: () => void;
}) => {
  useEffect(() => {
    if (isLastStep && captureOnboardingEvents) {
      posthog.capture("Onboarding Completed", { platform: "Web" });
    }
  }, [isLastStep]);

  return (
    <Flex
      {...tooltipProps}
      direction="column"
      gap={6}
      css={(theme) => {
        return {
          background: theme.colors.primary,
          width: 260,
          borderRadius: theme.radii.medium,
          padding: 16,
          color: "#FFF",
        };
      }}
    >
      {step.title && (
        <Text css={(theme) => ({ fontSize: theme.fontSizes.medium })} bolder>
          {step.title}
        </Text>
      )}
      <Flex
        align="center"
        justify="space-between"
        css={{ marginTop: 16, width: "100%" }}
      >
        <Button
          {...skipProps}
          css={{ background: "none", padding: 0 }}
          onClick={(e) => {
            if (captureOnboardingEvents) {
              posthog.capture("Onboarding", {
                percentage: (stepIndex / size) * 100 + "%",
                platform: "Web",
              });
            }
            handleTimeout(20000);
          }}
        >
          Skip
        </Button>
        <Flex gap={3}>
          {Array.from(Array(size).keys()).map((_, index) => (
            <div
              key={_}
              css={(theme) => ({
                height: 6,
                width: 6,
                opacity: stepIndex === index ? 1 : 0.4,
                background: "#FFF",
                borderRadius: theme.radii.full,
              })}
            />
          ))}
        </Flex>
        <Button
          css={(theme) => ({
            background: "#FFF",
            color: theme.colors.primary,
            padding: "4px 8px",
            borderRadius: theme.radii.smaller,
          })}
          {...primaryProps}
          onClick={handleNext}
        >
          {isLastStep ? "Close" : "Next"}
        </Button>
      </Flex>
    </Flex>
  );
};
