import posthog from "posthog-js";

const getNetworkType = (): string => {
  if (
    navigator.connection &&
    (navigator.connection.effectiveType || navigator.connection.type)
  ) {
    return navigator.connection.type || navigator.connection.effectiveType;
  }
  return "unknown";
};

export const trackNetworkType = () => {
  const networkType = getNetworkType();
  posthog.capture("network_type", {
    network_type: networkType,
    platform: "Web",
  });
};
