import { Geo } from "./MapContext";

export enum Cube {
  GEO_DATA = "geo_data",
  CURRENT_DATA_SET = "current_data_set",
  TIME_SERIES_DATA_SET = "time_series_data_set",
  CURRENT_SCORE = "score_current_set",
  TIME_SERIES_SCORE = "score_time_series_data_set",
}

export enum Datapoints {
  HOME_VALUE = "home_value",
  HOME_VALUE_GROWTH_RATE = "home_value_growth_rate",
  HOME_VALUE_FIVE_YEAR_GROWTH_RATE = "home_value_five_year_growth_rate",
  HOME_VALUE_TO_EARNINGS_RATIO = "home_value_to_earnings_ratio",
  HOME_VALUE_TO_ANNUAL_RENT_RATIO = "home_value_to_annual_rent_ratio",
  OVER_UNDER_VALUED_PERCENTAGE = "over_under_valued_percentage",
  TYPICAL_HOUSE_PAYMENT = "typical_house_payment",
  SALARY_TO_AFFORD_HOUSE = "salary_to_afford_house",
  HOUSE_PAYMENT_RATE_OF_MED_INCOME = "house_payment_rate_of_med_income",
  PROPERTY_TAX_RATE = "property_tax_rate",
  BUY_VERSUS_RENT_PERCENTAGE = "buy_versus_rent_percentage",
  PERCENT_CRASH_GREAT_RECESSION = "percent_crash_great_recession",
  MOM_HOME_VALUE_GROWTH = "mom_home_value_growth",
  TOTAL_ACTIVE_INVENTORY = "total_active_inventory",
  YOY_INVENTORY_CHANGE_PERCENTAGE = "yoy_inventory_change_percentage",
  SHARE_OF_LISTINGS_WITH_PRICE_CUT = "share_of_listings_with_price_cut",
  DAYS_ON_MARKET = "days_on_market",
  YOY_DAYS_ON_MARKET = "yoy_days_on_market",
  INVENTORY_PERCENTAGE_OF_HOUSES = "inventory_percentage_of_houses",
  MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE = "mom_active_inventory_change_percentage",
  POPULATION = "population",
  POPULATION_GROWTH_RATE = "population_growth_rate",
  MEDIAN_HOUSEHOLD_INCOME = "median_household_income",
  COLLEGE_DEGREE_RATE = "college_degree_rate",
  TOTAL_EDUCATION_POPULATION = "total_education_population",
  POPULATION_WITH_BACHELORS_OR_HIGHER = "population_with_bachelors_or_higher",
  MALE_BACHELORS_DEGREE = "male_bachelors_degree",
  MALE_MASTER_DEGREE = "male_master_degree",
  MALE_PROFESSIONAL_DEGREE = "male_professional_degree",
  MALE_DOCTORATE_DEGREE = "male_doctorate_degree",
  FEMALE_BACHELORS_DEGREE = "female_bachelors_degree",
  FEMALE_MASTER_DEGREE = "female_master_degree",
  FEMALE_PROFESSIONAL_DEGREE = "female_professional_degree",
  FEMALE_DOCTORATE_DEGREE = "female_doctorate_degree",
  MEDIAN_INCOME_GROWTH_RATE = "median_income_growth_rate",
  HOME_OWNERSHIP_RATE = "home_ownership_rate",
  MORTGAGED_HOME_PERCENTAGE = "mortgaged_home_percentage",
  MEDIAN_AGE = "median_age",
  POVERTY_PERCENTAGE = "poverty_percentage",
  HOUSING_UNITS = "housing_units",
  HOUSING_UNIT_GROWTH = "housing_unit_growth",
  RENT_FOR_APARTMENTS = "rent_for_apartments",
  RENT_FOR_HOUSES = "rent_for_houses",
  HOUSEHOLDS = "households",
  CAP_RATE = "cap_rate",
  RENT_PERCENTAGE_OF_INCOME = "rent_percentage_of_income",
  ABSENTEE_OWNER_PERCENTAGE = "absentee_owner_percentage",
  TOTAL_MIGRATION = "total_migration",
  MIGRATION_PERCENTAGE = "migration_percentage",
  FAIR_HOME_VALUE = "fair_home_value",
  TYPICAL_YEARLY_HOUSE_PAYMENT = "typical_yearly_house_payment",
  YEARLY_APARTMENT_RENT = "yearly_apartment_rent",
  GROSS_RENT_INCOME = "gross_rent_income",
  TOTAL_INVENTORY = "total_inventory",
  LISTINGS_WITH_PRICE_CUT = "listings_with_price_cut",
  INSURANCE_TAXES_MISC_COSTS = "insurance_taxes_misc_costs",
  PRINCIPLE_INTEREST = "principle_interest",
  POPULATION_LIVING_IN_POVERTY = "population_living_in_poverty",
  HOUSING_UNITS_WITH_A_MORTGAGE = "housing_units_with_a_mortgage",
  ABSENTEE_OWNED_UNITS = "absentee_owned_units",
  ACTIVE_LISTING_COUNT_YY = "active_listing_count_yy",
  MEDIAN_TAXES = "median_taxes",
  PROPERTY_TAX = "property_tax",
  NET_RENT_INCOME = "net_rent_income",
  OWNERSHIP_HHS = "ownership_hhs",
  MORTGAGE_RATE = "mortgage_rate",
  EXPENSES = "expenses",
  RENT_GROWTH_YOY = "rent_growth_yoy",
  PER_CHANGE_HOME_VALUE_JUNE = "per_change_home_value_june",

  MEDIAN_LISTING_PRICE = "median_listing_price",
  MEDIAN_LISTING_PRICE_YOY = "median_listing_price_yoy",
  NEW_LISTING_COUNT = "new_listing_count",
  NEW_LISTING_COUNT_YOY = "new_listing_count_yoy",
  FOR_SALE_INVENTORY_V_AVG = "for_sale_inventory_v_avg",
  BUILDING_PERMITS = "building_permits",
  SINGLE_FAMILY_UNITS_PERMITTED = "single_family_units_permitted",
  MULTI_FAMILY_UNITS_PERMITTED = "multi_family_units_permitted",
  TRAILING_TWELVE_MONTHS_BUILDING_PERMITS = "trailing_twelve_months_building_permits",
  // TRAILING_TWELVE_MONTHS_SINGLE_PERMITS = "trailing_twelve_months_single_permits",
  // TRAILING_TWELVE_MONTHS_MULTI_PERMITS = "trailing_twelve_months_multi_permits",
  // BUILDING_PERMIT_PERCENTAGE = "building_permit_percentage",
  // UNEMPLOYMENT_RATE = "unemployment_rate",
  LONG_TERM_FSI_AVG = "long_term_fsi_avg",
  EMPLOYMENT_LEVEL = "employment_level",
  UNEMPLOYMENT_LEVEL = "unemployment_level",
  TOTAL_NONFARM_PAYROLL = "total_nonfarm_payroll",
  TOTAL_PRIVATE_WEEKLY_HOURS = "total_private_weekly_hours",
  TOTAL_PRIVATE_HOURLY_EARNINGS = "total_private_hourly_earnings",
  TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS = "trailing_twelve_months_total_private_weekly_hours",
  TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS = "trailing_twelve_months_total_private_hourly_earnings",
  TRAILING_WEEKLY_EARNINGS = "trailing_weekly_earnings",
  VACANCY_RATE = "vacancy_rate",
  VACANT_HOUSE = "vacant_house",

  ABSENTEE_OWNED_PLUS_OWNERSHIP_HHS = "absentee_owned_plus_ownership_hhs",
  MIGRATION_POPULATION = "migration_population",
  CPI_YOY_PERCENTAGE = "cpi_yoy_percentage",
  MONTHLY_TREASURY_AVG_YIELD = "monthly_treasury_avg_yield",
  REMOTE_WORK_PERCENTAGE = "remote_work_percentage",
  WORK_FROM_HOME = "work_from_home",
  TOTAL_EMPLOYMENT = "total_employment",
  VOTING_DEMOCRAT_PERCENTAGE = "voting_democrat_percentage",
  TOTAL_DEMOCRAT_VOTES = "total_democrat_votes",
  TOTAL_DEMOCRAT_VOTES_CAST = "total_democrat_votes_cast",
  VOTING_REPUBLICAN_PERCENTAGE = "voting_republican_percentage",
  TOTAL_REPUBLICAN_VOTES = "total_republican_votes",
  TOTAL_REPUBLICAN_VOTES_CAST = "total_republican_votes_cast",
}

export enum ScoreDatapoints {
  RECENT_APPRECIATION_PERCENTAGE = "recent_appreciation_percentage",
  RECENT_APPRECIATION_SCORE = "recent_appreciation_score",
  INVENTORY_LEVELS_PERCENTAGE = "inventory_levels_percentage",
  INVENTORY_LEVELS_SCORE = "inventory_levels_score",
  PRICE_CUTS_PERCENTAGE = "price_cuts_percentage",
  PRICE_CUTS_SCORE = "price_cuts_score",
  DAYS_ON_MARKET_PERCENTAGE = "days_on_market_percentage",
  DAYS_ON_MARKET_SCORE = "days_on_market_score",
  MORTGAGE_RATES_YOY_PERCENTAGE = "mortgage_rates_yoy_percentage",
  MORTGAGE_RATES_FIVE_YEAR_PERCENTAGE = "mortgage_rates_five_year_percentage",
  MORTGAGE_RATES_SCORE = "mortgage_rates_score",
  HOME_PRICE_MOMENTUM_SCORE = "home_price_momentum_score",
  LONG_TERM_APPRECIATION_PERCENTILE = "long_term_appreciation_percentile",
  POVERTY_RATE_PERCENTILE = "poverty_rate_percentile",
  MEDIAN_HOUSEHOLD_INCOME_PERCENTILE = "median_household_income_percentile",
  DEMOGRAPHIC_GROWTH_PERCENTILE = "demographic_growth_percentile",
  VALUE_INCOME_RATIO_PERCENTILE = "value_income_ratio_percentile",
  HOME_BUYER_SCORE = "home_buyer_score",
  WEALTH_INCOME_PERCENTILE = "wealth_income_percentile",
  INVESTOR_SCORE = "investor_score",
  RENT_PERCENTILE = "rent_percentile",
  CAP_RATE_PERCENTILE = "cap_rate_percentile",
  OVERVALUATION_PERCENTILE = "overvaluation_percentile",
}

export type CombinedDatapoints = Datapoints | ScoreDatapoints;

export interface RawCurrentDataPoints {
  [key: string]: number | string;
}

export type CurrentDataPoints = {
  geo: Geo;
  geo_name: string;
  geo_code: string;
  state_code: string;

  inventory_as_percentage_of_houses_total_active_inventory_tooltip: number;
  inventory_as_percentage_of_houses_absentee_owned_units_tooltip: number;
  inventory_as_percentage_of_houses_ownership_hhs_tooltip: number;
  home_ownership_rate_ownership_hhs_tooltip: number;
  home_ownership_rate_households_tooltip: number;
  mortgaged_home_percentage_housing_units_with_a_mortgage_tooltip: number;
  mortgaged_home_percentage_ownership_hhs_tooltip: number;
  poverty_rate_population_living_in_poverty_tooltip: number;
  poverty_rate_population_tooltip: number;
  housing_units_population_living_in_poverty_tooltip: number;
  housing_units_population_tooltip: number;
  value_income_ratio_home_value_tooltip: number;
  value_rent_ratio_home_value_tooltip: number;
  value_rent_ratio_rent_for_houses_tooltip: number;
  value_income_ratio_median_household_income_tooltip: number;
  over_valued_percent_home_value_tooltip: number;
  over_valued_percent_fair_home_value_tooltip: number;
  monthly_house_payment_principle_interest_tooltip: number;
  monthly_house_payment_insurance_taxes_misc_costs_tooltip: number;
  monthly_house_payment_mortgage_rate_tooltip: number;
  house_payment_as_percentage_of_income_typical_yearly_house_payment_tooltip: number;
  house_payment_as_percentage_of_income_median_household_income_tooltip: number;
  house_payment_as_percentage_of_income_mortgage_rate_tooltip: number;
  price_cut_percentage_listings_with_price_cut_tooltip: number;
  price_cut_percentage_total_inventory_tooltip: number;
  buy_v_rent_differential_typical_yearly_house_payment_tooltip: number;
  buy_v_rent_differential_yearly_apartment_rent_tooltip: number;
  shadow_inventory_percentage_absentee_owned_units_tooltip: number;
  shadow_inventory_percentage_ownership_hhs_tooltip: number;
  rent_as_percentage_of_income_yearly_apartment_rent_tooltip: number;
  rent_as_percentage_of_income_median_household_income_tooltip: number;
  cap_rate_gross_rent_income_tooltip: number;
  cap_rate_expenses_tooltip: number;
  cap_rate_net_rent_income_tooltip: number;
  cap_rate_home_value_tooltip: number;
  migration_percent_of_population_total_migration_tooltip: number;
  migration_percent_of_population_population_tooltip: number;
  home_value_growth_rate_tooltip_home_value_latest_value: number;
  home_value_growth_rate_tooltip_home_value_latest_date: string;
  home_value_growth_rate_tooltip_home_value_prev_value: number;
  home_value_growth_rate_tooltip_home_value_prev_date: string;
  home_value_five_year_growth_rate_tooltip_home_value_latest_value: number;
  home_value_five_year_growth_rate_tooltip_home_value_latest_date: string;
  home_value_five_year_growth_rate_tooltip_home_value_prev_value: number;
  home_value_five_year_growth_rate_tooltip_home_value_prev_date: string;
  rent_growth_yoy_tooltip_rent_for_apartments_latest_value: number;
  rent_growth_yoy_tooltip_rent_for_apartments_latest_date: string;
  rent_growth_yoy_tooltip_rent_for_apartments_prev_value: number;
  rent_growth_yoy_tooltip_rent_for_apartments_prev_date: string;
  home_ownership_rate_tooltip_ownership_hhs: number;
  home_ownership_rate_tooltip_households: number;
  inventory_percentage_of_houses_tooltip_total_active_inventory: number;
  inventory_percentage_of_houses_tooltip_absentee_owned_units: number;
  inventory_percentage_of_houses_tooltip_ownership_hhs: number;
  poverty_percentage_tooltip_population_living_in_poverty: number;
  poverty_percentage_tooltip_population: number;
  mortgaged_home_percentage_tooltip_housing_units_with_a_mortgage: number;
  mortgaged_home_percentage_tooltip_ownership_hhs: number;
  rent_percentage_of_income_tooltip_yearly_apartment_rent: number;
  rent_percentage_of_income_tooltip_median_household_income: number;
  housing_units_tooltip_population_living_in_poverty: number;
  housing_units_tooltip_population: number;
  home_value_to_earnings_ratio_tooltip_home_value: number;
  home_value_to_earnings_ratio_tooltip_median_household_income: number;
  home_value_to_annual_rent_ratio_tooltip_home_value: number;
  home_value_to_annual_rent_ratio_tooltip_rent_for_houses: number;
  share_of_listings_with_price_cut_tooltip_listings_with_price_cut: number;
  share_of_listings_with_price_cut_tooltip_total_inventory: number;
  migration_percentage_tooltip_total_migration: number;
  migration_percentage_tooltip_population: number;
  mom_home_value_growth_tooltip_home_value_latest_value: number;
  mom_home_value_growth_tooltip_home_value_latest_date: string;
  mom_home_value_growth_tooltip_home_value_prev_value: number;
  mom_home_value_growth_tooltip_home_value_prev_date: string;
  yoy_inventory_change_percentage_tooltip_total_active_inventory_latest_value: number;
  yoy_inventory_change_percentage_tooltip_total_active_inventory_latest_date: string;
  yoy_inventory_change_percentage_tooltip_total_active_inventory_prev_value: number;
  yoy_inventory_change_percentage_tooltip_total_active_inventory_prev_date: string;
  housing_unit_growth_tooltip_housing_units_latest_value: number;
  housing_unit_growth_tooltip_housing_units_latest_date: string;
  housing_unit_growth_tooltip_housing_units_prev_value: number;
  housing_unit_growth_tooltip_housing_units_prev_date: string;
  yoy_days_on_market_tooltip_days_on_market_latest_value: number;
  yoy_days_on_market_tooltip_days_on_market_latest_date: string;
  yoy_days_on_market_tooltip_days_on_market_prev_value: number;
  yoy_days_on_market_tooltip_days_on_market_prev_date: string;
  population_growth_rate_tooltip_population_latest_value: number;
  population_growth_rate_tooltip_population_latest_date: string;
  population_growth_rate_tooltip_population_prev_value: number;
  population_growth_rate_tooltip_population_prev_date: string;
  median_income_growth_rate_tooltip_median_household_income_latest_value: number;
  median_income_growth_rate_tooltip_median_household_income_latest_date: string;
  median_income_growth_rate_tooltip_median_household_income_prev_value: number;
  median_income_growth_rate_tooltip_median_household_income_prev_date: string;
  cap_rate_tooltip_gross_rent_income: number;
  cap_rate_tooltip_expenses: number;
  cap_rate_tooltip_net_rent_income: number;
  cap_rate_tooltip_home_value: number;
  property_tax_rate_tooltip_home_value: number;
  property_tax_rate_tooltip_property_tax: number;
  mom_active_inventory_change_percentage_tooltip_total_active_inventory_latest_value: number;
  mom_active_inventory_change_percentage_tooltip_total_active_inventory_latest_date: string;
  mom_active_inventory_change_percentage_tooltip_total_active_inventory_prev_value: number;
  mom_active_inventory_change_percentage_tooltip_total_active_inventory_prev_date: string;
  absentee_owner_percentage_tooltip_absentee_owned_units: number;
  absentee_owner_percentage_tooltip_ownership_hhs: number;
  over_under_valued_percentage_tooltip_home_value: number;
  over_under_valued_percentage_tooltip_fair_home_value: number;
  typical_house_payment_tooltip_principle_interest: number;
  typical_house_payment_tooltip_insurance_taxes_misc_costs: number;
  typical_house_payment_tooltip_mortgage_rate: number;
  house_payment_rate_of_med_income_tooltip_typical_yearly_house_payment: number;
  house_payment_rate_of_med_income_tooltip_median_household_income: number;
  house_payment_rate_of_med_income_tooltip_mortgage_rate: number;
  buy_versus_rent_percentage_tooltip_typical_yearly_house_payment: number;
  buy_versus_rent_percentage_tooltip_yearly_apartment_rent: number;
  percent_crash_great_recession_tooltip_years_home_value_2007: number;
  percent_crash_great_recession_tooltip_years_home_value_2012: number;
  per_change_home_value_june_tooltip_home_value: number;
  per_change_home_value_june_tooltip_dates_home_value_6_2022: number;
  median_listing_price_yoy_tooltip_median_listing_price_latest_value: number;
  median_listing_price_yoy_tooltip_median_listing_price_latest_date: number;
  median_listing_price_yoy_tooltip_median_listing_price_prev_value: number;
  median_listing_price_yoy_tooltip_median_listing_price_prev_date: number;
  new_listing_count_yoy_tooltip_new_listing_count_latest_value: number;
  new_listing_count_yoy_tooltip_new_listing_count_latest_date: number;
  new_listing_count_yoy_tooltip_new_listing_count_prev_value: number;
  new_listing_count_yoy_tooltip_new_listing_count_prev_date: number;
  for_sale_inventory_v_avg_tooltip_long_term_fsi_avg: number;
  for_sale_inventory_v_avg_tooltip_total_active_inventory: number;
  building_permit_percentage_latest_tooltip_migration_population: number;
  building_permit_percentage_tooltip_building_permits: number;
  salary_to_afford_house_tooltip_typical_house_payment: number;
  salary_to_afford_house_tooltip_typical_yearly_house_payment: number;
  vacancy_rate_tooltip_housing_units: number;
  vacancy_rate_tooltip_vacant_house: number;
  home_value_growth_rate_tooltip_cpi_yoy_percentage: number;
  cap_rate_tooltip_monthly_treasury_avg_yield: number;
  salary_to_afford_house_tooltip_median_household_income: number;
  home_value_tooltip_fair_home_value: number;
  college_degree_rate_tooltip_total_education_population: number;
  college_degree_rate_tooltip_population_with_bachelors_or_higher: number;
  remote_work_percentage_tooltip_work_from_home: number;
  remote_work_percentage_tooltip_total_employment: number;
  voting_democrat_percentage_tooltip_total_democrat_votes: number;
  voting_democrat_percentage_tooltip_total_democrat_votes_cast: number;
  voting_republican_percentage_tooltip_total_republican_votes: number;
  voting_republican_percentage_tooltip_total_republican_votes_cast: number;
  voting_republican_percentage_tooltip_voting_democrat_percentage: number;
  voting_democrat_percentage_tooltip_voting_republican_percentage: number;
} & Record<Datapoints, number> &
  Record<ScoreDatapoints, number>;
