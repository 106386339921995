import { countyList } from "lib/options/countyList";
import { countyMetroMap } from "lib/options/countyMetroMap";
import { countyZipMap } from "lib/options/countyZipMap";
import { metroMap } from "lib/options/metroMap";
import { stateList } from "lib/options/stateList";
import { stateZipMap } from "lib/options/stateZipMap";
import { zipMetro } from "lib/zipMetro";
import { MapboxGeoMap, MapBoxGeoType } from "types/MapContext";
import { Feature } from "types/geocode";
import { MetroOption } from "types/options";
import { ZipToCity } from "lib/ziptocity.js";

export const findMetroFromCounty = (
  feature: Feature,
  metroFilter: MetroOption[],
): MetroOption | undefined | null => {
  const district = findCountyFromFeature(feature);
  const region = findStateFromFeature(feature);

  const foundMetroMap = countyMetroMap.find(
    (item) =>
      item.countycountyequivalent
        .toLowerCase()
        .includes(district.toLowerCase()) &&
      item.statename.toLowerCase().includes(region.toLowerCase()),
  );

  if (!foundMetroMap) {
    // if we couldn't find metro from county we look with zips from that county.
    const metro = findMetroFromCountyZips(feature, metroFilter);
    return metro ? metro : null;
  }

  return metroFilter.find((metro) => +metro.value === +foundMetroMap?.cbsacode);
};

export const findMetroFromCountyZips = (
  feature: Feature,
  metroFilter: MetroOption[],
) => {
  const zips = countyZipMap[
    `${findCountyFromFeature(feature)}, ${findStateFromFeature(feature)}`
  ] as number[];

  if (!zips) return null;

  const foundZip = zips.find((zip) => !!zipMetro[zip]);

  return metroFilter.find(
    (metro) => +zipMetro[foundZip as number] === +metro.value,
  );
};

export const findCountyFromFeature = (feature: Feature) => {
  if (feature.place_type[0] === MapBoxGeoType.COUNTY) return feature.text;

  const county = feature.context.find((ctx) =>
    ctx.id.includes(MapBoxGeoType.COUNTY),
  );

  return county ? county.text : "";
};

export const findStateFromFeature = (feature: Feature) => {
  if (feature.place_type[0] === MapBoxGeoType.STATE) return feature.text;

  const state = feature.context.find((ctx) =>
    ctx.id.includes(MapBoxGeoType.STATE),
  );
  return state ? state.text : "";
};

export const findFeatureGeo = (feature: Feature) => {
  return MapboxGeoMap[feature.place_type[0]];
};

export function findCountyFromZip(zip: string | number, stateAbr?: boolean) {
  for (let key in countyZipMap) {
    const found = countyZipMap[key].some((value) => +value === +zip);
    if (found) {
      if (stateAbr) {
        const county = countyList.find((c) => c.label === key);

        return [key.split(",")[0], ", ", county?.stateAbbr].join("");
      }
      return key;
    }
  }
}

export function findMetroFromZip(zip: string | number) {
  const normalizedZip = String(zip).replace(/^0+/, "");
  const metroCode = zipMetro[normalizedZip];
  if (metroCode) {
    const metro = metroMap.find((m) => +m["CBSA Code"] === +metroCode);

    return metro || null;
  }
  return null;
}

export function findCityFromZip(zip: string | number): string | undefined {
  const zipString = String(zip);
  const entry = ZipToCity.find((entry) => entry.postal_code === zipString);
  return entry ? entry.city_name : undefined;
}

export function findMetroFromCountyCode(countyCode: string | number) {
  const metro = metroMap.find((m) => +m["FIPS County Code"] === +countyCode);

  return metro || null;
}

export function findStateFromCode(stateCode: string | number) {
  return stateList.find((s) => +s.value === +stateCode) || null;
}

export function findStateFromZipCode(zipCode: number | string) {
  for (let key in stateZipMap) {
    const found = stateZipMap[key].find((z: number) => z === +zipCode);
    if (found) {
      return stateList.find((s) => s.label === key);
    }
  }
}
