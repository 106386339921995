import { Modal } from "antd";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { useUserData } from "hooks/useUser";
import { useState } from "react";
import { toast } from "react-toastify";
import { createHelpRequest } from "services/ZendeskService";
import { Text } from "components/Text";

export const FeedbackModal = ({ show, setShow, texts }) => {
  const user = useUserData();
  const [comment, setComment] = useState<string>("");
  const [userEmail, setUserEmail] = useState(user?.email || "");
  const [emailError, setEmailError] = useState(false);
  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  return (
    <Modal
      open={show}
      footer={null}
      bodyStyle={{ padding: 15 }}
      title={texts.title || "Contact Us"}
      destroyOnClose
      zIndex={1500}
      onCancel={() => setShow(false)}
    >
      <Flex direction="column" justify="center" align="center">
        {!user.email && (
          <input
            value={userEmail}
            onChange={(e) => {
              setUserEmail(e.target.value);
              setEmailError(false);
            }}
            onBlur={() =>
              setEmailError(!!userEmail && !isValidEmail(userEmail))
            }
            placeholder="Enter your email"
            css={(theme) => ({
              borderRadius: theme.radii.small,
              border: `1px solid ${
                emailError ? theme.colors.red : theme.colors.inputBorder
              }`,
              width: "90%",
              marginBottom: "10px",
              padding: 5,
              ":focus": {
                outline: "none",
              },
            })}
          />
        )}
        {emailError && (
          <Text
            css={(theme) => ({
              width: "90%",
              margin: "-15px 0 2px 0",
              padding: 5,
              color: theme.colors.red,
            })}
          >
            Invalid Email
          </Text>
        )}
        <textarea
          css={(theme) => ({
            borderRadius: theme.radii.small,
            border: `1px solid ${theme.colors.inputBorder}`,
            width: "90%",
            padding: 5,
            ":focus": {
              outline: "none",
            },
            resize: "unset",
          })}
          rows={10}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={texts.placeholder}
        />
        {!user.email && (
          <Text
            css={(theme) => ({
              color: theme.colors.text3,
            })}
          >
            If you would like a response to your feedback, please leave your
            email
          </Text>
        )}
        <Button
          css={(theme) => ({
            height: 48,
            background: theme.colors.primary,
            borderRadius: theme.radii.small,
            width: "90%",
            margin: "15px 0",
          })}
          variant="primary"
          disabled={!comment || emailError}
          onClick={() => {
            createHelpRequest({
              comment,
              user: { email: userEmail, name: user?.name },
              subject: texts.subject,
            });
            setShow(false);
            setComment("");
            toast.success("Email sent to support");
          }}
        >
          Send Feedback
        </Button>
      </Flex>
    </Modal>
  );
};
