import { useTheme } from "@emotion/react";
import { UilTable } from "@iconscout/react-unicons";
import { DatePicker, DatePickerProps, Switch } from "antd";
import { Flex } from "components/Flex";
import FilterIcon from "components/icons/FilterIcon";
import useGeoSearch from "hooks/useGeoSearch";
import { MapContext, ResponsiveContext } from "lib/context";
import { event } from "nextjs-google-analytics";
import { usePostHog } from "posthog-js/react";
import { FC, useContext, useEffect, useState } from "react";
import FiltersModal from "../MapFilter/FiltersModal";
import RoundButton from "./RoundButton";
import { useMapDateRange } from "../../hooks/useMapDateRange";
import moment, { Moment } from "moment";
import useFilters from "../../hooks/useFilters";
import dayjs from "dayjs";

const MonthPicker = DatePicker.MonthPicker;
interface Props {
  sidebarVisible: boolean;
  lastFilterAsExploratory: boolean;
}

const MapControls: FC<Props> = ({
  sidebarVisible,
  lastFilterAsExploratory,
}) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const posthog = usePostHog();
  const { showToolTip, setShowToolTip, setTableView } = useContext(MapContext);
  const { geo, setFilterYear, setFilterMonth, filterYear, filterMonth } =
    useGeoSearch();
  const { isDataLoading, dates } = useMapDateRange(geo);
  const { exploratory } = useFilters();
  const customFormat: DatePickerProps["format"] = (value) =>
    `Date: ${value.format("MMM YYYY")}`;

  const theme = useTheme();
  const [datePickerKey, setDatePickerKey] = useState(0);
  const [latestDate, setLatestDate] = useState<Moment | undefined>(undefined);

  const disableFutureMonths = (current) => {
    if (!current || !dates) return true;
    const currentDateString = current.format("YYYY-MM");
    return !dates.some((date) => date.startsWith(currentDateString));
  };

  const handleDateChange = (date, dateString) => {
    const lastDayOfMonth = dayjs(date).endOf("month"); // Ensure it's the last day of the month
    if (latestDate && !latestDate.format("YYYY-MM").startsWith(dateString)) {
      setFilterMonth(lastDayOfMonth.format("MM-DD")); // Use the adjusted date
      setFilterYear(lastDayOfMonth.format("YYYY"));
    } else {
      setFilterMonth("");
      setFilterYear("");
    }
  };

  useEffect(() => {
    setDatePickerKey((prev) => prev + 1);
    setLatestDate(
      dates && dates.length > 0
        ? moment(
            dates.sort(
              (a, b) => new Date(b).getTime() - new Date(a).getTime(),
            )[0],
          )
        : undefined,
    );
  }, [dates]);

  useEffect(() => {
    setFilterMonth("");
    setFilterYear("");
  }, [exploratory]);

  const [showFilters, setShowFilters] = useState(false);

  if (isTabletOrMobile)
    return (
      <>
        <Flex
          direction="column"
          css={(theme) => ({
            position: "absolute",
            top: 150,
            zIndex: 150,
            right: theme.margin.medium,
            gap: theme.space[1],
          })}
        >
          <RoundButton
            title="Table"
            icon={<UilTable css={{ color: theme.colors.white }} />}
            color={theme.colors.white}
            backgroundColor={theme.colors.primary}
            onClick={() => setTableView((prev) => !prev)}
          />

          <RoundButton
            title="Filters"
            icon={
              <FilterIcon width={20} height={20} color={theme.colors.white} />
            }
            color={theme.colors.white}
            backgroundColor={theme.colors.primary}
            onClick={() => setShowFilters(true)}
          />
        </Flex>

        <FiltersModal
          geo={geo}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
          lastFilterAsExploratory={lastFilterAsExploratory}
        />
      </>
    );

  return (
    <Flex
      justify="end"
      css={(theme) => ({
        position: "absolute",
        bottom: 19,
        zIndex: 150,
        gap: theme.space[1],
        left: sidebarVisible ? 312 : 40,
        transition: "left 0.3s ease",
        WebkitTransition: "left 0.3s ease",
        MozTransition: "left 0.3s ease",
        msTransition: "left 0.3s ease",
        OTransition: "left 0.3s ease",
      })}
    >
      <Flex
        align="center"
        justify="center"
        css={(theme) => ({
          width: 111,
          height: 38,
          backgroundColor: theme.colors.white,
          borderRadius: 65,
          gap: theme.gap[3],
          fontSize: theme.fontSizes.medium,
          fontWeight: theme.fontWeights.bold,
          cursor: "pointer",
        })}
        onClick={() => setShowToolTip((prev) => !prev)}
      >
        <Switch checked={showToolTip} size="small" />
        <span>Tooltip</span>
      </Flex>

      <Flex
        align="center"
        justify="center"
        css={(theme) => ({
          width: 138,
          height: 38,
          backgroundColor: theme.colors.white,
          borderRadius: 65,
          gap: theme.gap[3],
          fontSize: theme.fontSizes.medium,
          fontWeight: theme.fontWeights.bold,
          cursor: "pointer",
        })}
        onClick={() => {
          event(`map-control-table-view`, {
            category: "Map Control",
            label: "Table View",
          });
          posthog.capture("Opened Table", { platform: "Web" });
          setTableView((prev) => !prev);
        }}
      >
        <UilTable />
        <span>Table View</span>
      </Flex>
      <Flex>
        <DatePicker
          size="large"
          key={datePickerKey} // Use the key to force re-render
          defaultValue={latestDate}
          picker="month"
          format={customFormat}
          onChange={handleDateChange}
          style={{
            backgroundColor: theme.colors.white,
            borderRadius: 65,
          }}
          disabledDate={disableFutureMonths}
        />
      </Flex>
    </Flex>
  );
};

export default MapControls;
