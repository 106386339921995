import { useTheme } from "@emotion/react";
import { Flex } from "components/Flex";
import { GraphContext } from "components/GraphContext";
import { PlotData } from "hooks/useTimeSeriesData";
import { ResponsiveContext } from "lib/context";
import { findStateFromCode } from "lib/helpers/geoSearchHelpers";
import { getCountyName, getMetroName, getStateName } from "lib/helpers/getArea";
import { TimeSeriesConfig } from "lib/options/timeseriesDatapointConfig";
import Image from "next/image";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useContext } from "react";
import { Geo } from "types/MapContext";
import { CombinedDatapoints, ScoreDatapoints } from "types/cube";
import GraphSwitch from "./GraphSwitch";
import useScreenSize from "../../../lib/helpers/useScreenSize";
import { exploratoryMap } from "lib/options/exploratoryMap";
import { Button } from "antd";
import { DownloadOutlined } from "components/icons/DownloadOutlined";
import { metroList } from "lib/options/metroList";
import zipStateMap from "lib/options/zipStateMap";
import { metroMap } from "lib/options/metroMap";

const GraphScoreCardHeader = ({
  geo,
  info,
  plotData,
  showYearly,
  activeMonth,
  latestMonth,
  exploratory,
  exploratoryConfig,
  onShareClick,
  setShowYearly,
  setShowShareModal,
  page,
  handleDownloadClick,
}: {
  geo: Geo;
  info: any;
  plotData: PlotData[];
  showYearly: boolean;
  activeMonth?: number;
  latestMonth?: number;
  exploratory: CombinedDatapoints;
  exploratoryConfig: TimeSeriesConfig;
  onShareClick?(): void;
  setShowYearly: Dispatch<SetStateAction<boolean>>;
  setShowShareModal: Dispatch<SetStateAction<boolean>>;
  page?: string;
  handleDownloadClick?(data): void;
}) => {
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const theme = useTheme();
  const router = useRouter();
  const screenSize = useScreenSize();
  const fullName = info?.NAME || info?.name;
  const getMetroState = () => {
    const metro = metroMap?.find(
      (metro) => metro["CBSA Code"] === parseInt(info?.GEOID),
    );
    return metro ? metro["State Name"] : null;
  };
  const state =
    geo === Geo.STATE
      ? null
      : geo === Geo.ZIP
      ? getStateName({
          ...info,
          state: zipStateMap[(+info?.GEOID).toString()],
        })
      : geo === Geo.METRO
      ? getMetroState()
      : getStateName({
          ...info,
          state: info?.state ? info?.state : info?.STATE,
        });

  return (
    <Flex direction="column" css={{ width: "100%" }}>
      {screenSize.width >= 976 && screenSize.width <= 1450 && (
        <Flex
          css={(theme) => ({
            minHeight: "40px",
          })}
        ></Flex>
      )}
      <Flex
        css={{
          minWidth: "100%",
          justifyContent:
            screenSize.width >= 976 && screenSize.width <= 1450
              ? "start"
              : "center",
        }}
        direction={isTabletOrMobile ? "column-reverse" : "row"}
      >
        <Flex
          flex={1}
          css={{
            marginTop:
              screenSize.width >= 976 && screenSize.width <= 1450
                ? "6px"
                : "0px",
          }}
        >
          {isTabletOrMobile ? (
            <div />
          ) : (
            <GraphContext
              geo={geo}
              activeMonth={activeMonth}
              latestMonth={latestMonth}
              plotData={plotData}
              exploratory={exploratory}
              context={info}
              fullName={fullName}
            />
          )}
        </Flex>
        <Flex
          direction="column"
          align="center"
          gap={0}
          flex={1}
          css={{
            alignSelf: isTabletOrMobile ? "center" : "auto",
            width: "100%",
            alignItems:
              screenSize.width >= 976 && screenSize.width <= 1450
                ? "self-start"
                : "center",
            marginLeft:
              screenSize.width >= 976 && screenSize.width <= 1300
                ? "10px"
                : "0px",
            textAlign:
              screenSize.width >= 976 && screenSize.width <= 1450
                ? "left"
                : "center",
          }}
        >
          <span
            css={{
              color: theme.colors.primary,
              fontSize: isTabletOrMobile ? "24px" : theme.fontSizes.heading1,
              fontWeight: theme.fontWeights.bold,
              width: "100%",
              padding: "0 15px",
            }}
          >
            {geo === Geo.COUNTY
              ? fullName?.split(",")[0] || ""
              : fullName?.length > 10
              ? info.BASENAME || fullName.split(",")[0]
              : fullName}
          </span>
          <span style={{ width: "100%" }}>
            {geo === Geo.ZIP
              ? getCountyName(info.GEOID)
              : geo === Geo.COUNTY
              ? getMetroName(info, geo)
              : geo === Geo.METRO
              ? findStateFromCode(info?.state)?.label || state
              : ""}
          </span>
        </Flex>
        <Flex
          flex={1}
          css={{
            maxWidth:
              screenSize.width >= 976 && screenSize.width <= 1450
                ? "0px"
                : "auto",
            marginTop:
              screenSize.width >= 976 && screenSize.width <= 1450
                ? "6px"
                : "0px",
          }}
        >
          {exploratoryConfig?.hasMonthly && page !== "scores" && (
            <GraphSwitch
              showYearly={showYearly}
              setShowYearly={setShowYearly}
            />
          )}
          {page === "scores" && (
            <Button
              onClick={() => {
                if (handleDownloadClick) {
                  handleDownloadClick({
                    GEOID:
                      geo === Geo.COUNTY
                        ? info.GEOID.toString().slice(2)
                        : info.GEOID.toString(),
                    STATE:
                      geo === Geo.METRO
                        ? metroList.find((item) => item.value == info.GEOID)?.[
                            "FIPS State Code"
                          ]
                        : info.STATE,
                  });
                }
              }}
              css={{
                background: theme.colors.white,
                padding: "6px 7px",
                right: router.asPath.startsWith("/scores") ? 120 : 160,
                height: 37,
                top: 18,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                gap: 7,
                border: `1px solid ${theme.colors.inputBorder}`,
                fontSize: theme.fontSizes.small,
              }}
            >
              <DownloadOutlined /> Report
            </Button>
          )}
          <Flex
            css={(theme) => ({
              height: 37,
              position: "absolute",
              right: router.asPath.startsWith("/scores") ? 20 : 70,
              top: 18,
              borderRadius: "4px",
              background: theme.colors.lightBg,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              width: 80,
              cursor: "pointer",
              fontSize: theme.fontSizes.default,
              ":hover": {
                opacity: 0.7,
              },
            })}
            onClick={() => {
              if (setShowShareModal) {
                setShowShareModal(true);
              }
              if (onShareClick) {
                onShareClick();
              }
            }}
          >
            <span style={{ marginRight: 4 }}>Share</span>
            <Image alt="share" width={14} height={14} src="/share.svg" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GraphScoreCardHeader;
