export const defaultGroupSelectOptions = {
  Demographic: ["median_household_income", "population", "home_ownership_rate"],
  "Real Estate": [
    "home_value",
      "home_value_growth_rate",
      "home_value_five_year_growth_rate",
    "mom_home_value_growth",
  ],
  Premium: [
    "over_under_valued_percentage",
    "home_value_to_med_hh_income_ratio",
    "home_value_to_earnings_ratio",
    "house_payment_rate_of_med_income",
  ],
};

export const popoverContent = (
  <p>
    Improvements are being made to the calculations for this Data Point. <br />{" "}
    Stay tuned for updates.
  </p>
);

export const presetExploratoryMap = {
  highest_growth_areas: {
    code: "highest_growth_areas",
    name: "Highest Growth Areas",
    filterName: "Highest Growth Areas",
    preset: true,
  },
  best_value_markets_for_investors: {
    code: "best_value_markets_for_investors",
    name: "Best Value Markets for Investors",
    filterName: "Best Value Markets for Investors",
    preset: true,
  },
  biggest_housing_bubbles: {
    code: "biggest_housing_bubbles",
    name: "Biggest Housing Bubbles",
    filterName: "Biggest Housing Bubbles",
    preset: true,
  },
  safest_places_to_buy: {
    code: "safest_places_to_buy",
    name: "Safest Places to Buy",
    filterName: "Safest Places to Buy",
    preset: true,
  },
  reventures_top_markets_2022: {
    code: "reventures_top_markets_2022",
    name: "Reventure's Top Markets 2022",
    filterName: "Reventure's Top Markets 2022",
    preset: true,
  },
};

export const presetExploratoryOptions = [
  {
    value: "highest_growth_areas",
    label: "Highest Growth Areas",
    dependencies: [
      {
        value: "population_growth_rate",
        label: "Population Growth",
        isHidden: true,
        val: ["h", "vh"],
      },
      {
        value: "median_income_growth_rate",
        label: "Income Growth",
        isHidden: true,
        val: ["h", "vh"],
      },
      {
        value: "home_value_growth_rate",
        label: "Home Value Growth (YoY)",
        isHidden: true,
        val: ["m", "h", "vh"],
      },
    ],
  },

  {
    value: "best_value_markets_for_investors",
    label: "Best Value Markets for Investors",
    dependencies: [
      {
        value: "median_home_price",
        label: "Median Home Price",
        isHidden: true,
        val: ["m", "vl", "l"],
      },
      {
        value: "home_value_to_med_hh_income_ratio",
        label: "Value / Income Ratio",
        isHidden: true,
        val: ["l", "vl"],
      },
      {
        value: "population_growth_rate",
        label: "Population Growth",
        isHidden: true,
        val: ["h", "vh"],
      },
    ],
  },
  {
    value: "biggest_housing_bubbles",
    label: "Biggest Housing Bubbles",
    dependencies: [
      {
        value: "home_value_growth_rate",
        label: "Home Value Growth (YoY)",
        isHidden: true,
        val: ["h", "vh"],
      },
      {
        value: "home_value_to_med_hh_income_ratio",
        label: "Value / Income Ratio",
        isHidden: true,
        val: ["h", "vh"],
      },
      {
        value: "population_growth_rate",
        label: "Population Growth",
        isHidden: true,
        val: ["h", "vh"],
      },
    ],
  },
  {
    value: "safest_places_to_buy",
    label: "Safest Places to Buy",
    dependencies: [
      {
        value: "poverty_percentage",
        label: "Poverty Rate",
        isHidden: true,
        val: ["vl", "l"],
      },
      {
        value: "home_value_to_med_hh_income_ratio",
        label: "Value / Income Ratio",
        isHidden: true,
        val: ["vl", "l"],
      },
      {
        value: "home_value_growth_rate",
        label: "Home Value Growth (YoY)",
        isHidden: true,
        val: ["vl", "l"],
      },
    ],
  },
  {
    value: "reventures_top_markets_2022",
    label: "Reventure's Top Markets 2022",
    dependencies: [
      {
        value: "home_value_growth_rate",
        label: "Home Value Growth (YoY)",
        isHidden: true,
        val: ["vl", "l"],
      },
      {
        value: "home_value_to_med_hh_income_ratio",
        label: "Value / Income Ratio",
        isHidden: true,
        val: ["vl", "l", "m"],
      },
      {
        value: "population_growth_rate",
        label: "Population Growth",
        isHidden: true,
        val: ["m", "h", "vh"],
      },
      {
        value: "poverty_percentage",
        label: "Poverty Rate",
        isHidden: true,
        val: ["vl", "l"],
      },
    ],
  },
];

export const interestRateList = [
  {
    DATE: "1971-04-02",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1971-04-09",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1971-04-16",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1971-04-23",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1971-04-30",
    MORTGAGE30US: 7.29,
  },
  {
    DATE: "1971-05-07",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1971-05-14",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1971-05-21",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1971-05-28",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1971-06-04",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1971-06-11",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1971-06-18",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-06-25",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-07-02",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-07-09",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-07-16",
    MORTGAGE30US: 7.6,
  },
  {
    DATE: "1971-07-23",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1971-07-30",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1971-08-06",
    MORTGAGE30US: 7.66,
  },
  {
    DATE: "1971-08-13",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "1971-08-20",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1971-08-27",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1971-09-03",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1971-09-10",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1971-09-17",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1971-09-24",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1971-10-01",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1971-10-08",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1971-10-15",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1971-10-22",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1971-10-29",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1971-11-05",
    MORTGAGE30US: 7.59,
  },
  {
    DATE: "1971-11-12",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1971-11-19",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-11-26",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1971-12-03",
    MORTGAGE30US: 7.49,
  },
  {
    DATE: "1971-12-10",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1971-12-17",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1971-12-24",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1971-12-31",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1972-01-07",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1972-01-14",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1972-01-21",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-01-28",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-02-04",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1972-02-11",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1972-02-18",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-02-25",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-03-03",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1972-03-10",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1972-03-17",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-03-24",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-03-31",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1972-04-07",
    MORTGAGE30US: 7.25,
  },
  {
    DATE: "1972-04-14",
    MORTGAGE30US: 7.29,
  },
  {
    DATE: "1972-04-21",
    MORTGAGE30US: 7.29,
  },
  {
    DATE: "1972-04-28",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1972-05-05",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1972-05-12",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-05-19",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-05-26",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-06-02",
    MORTGAGE30US: 7.36,
  },
  {
    DATE: "1972-06-09",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-06-16",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-06-23",
    MORTGAGE30US: 7.36,
  },
  {
    DATE: "1972-06-30",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-07-07",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-07-14",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-07-21",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-07-28",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-08-04",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1972-08-11",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-08-18",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-08-25",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-01",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-08",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-15",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-22",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-09-29",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-10-06",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-10-13",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-10-20",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1972-10-27",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-11-03",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-11-10",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-11-17",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-11-24",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1972-12-01",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1972-12-08",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-12-15",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-12-22",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1972-12-29",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-01-05",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1973-01-12",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1973-01-19",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1973-01-26",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1973-02-02",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1973-02-09",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1973-02-16",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-02-23",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-02",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-09",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-16",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-23",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-30",
    MORTGAGE30US: 7.49,
  },
  {
    DATE: "1973-04-06",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1973-04-13",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1973-04-20",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1973-04-27",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1973-05-04",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1973-05-11",
    MORTGAGE30US: 7.64,
  },
  {
    DATE: "1973-05-18",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1973-05-24",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1973-06-01",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1973-06-08",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "1973-06-15",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "1973-06-22",
    MORTGAGE30US: 7.75,
  },
  {
    DATE: "1973-06-29",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1973-07-06",
    MORTGAGE30US: 7.89,
  },
  {
    DATE: "1973-07-13",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1973-07-20",
    MORTGAGE30US: 8.12,
  },
  {
    DATE: "1973-07-27",
    MORTGAGE30US: 8.18,
  },
  {
    DATE: "1973-08-03",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "1973-08-10",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1973-08-17",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1973-08-24",
    MORTGAGE30US: 8.61,
  },
  {
    DATE: "1973-08-31",
    MORTGAGE30US: 8.66,
  },
  {
    DATE: "1973-09-07",
    MORTGAGE30US: 8.77,
  },
  {
    DATE: "1973-09-14",
    MORTGAGE30US: 8.81,
  },
  {
    DATE: "1973-09-21",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1973-09-28",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1973-10-05",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1973-10-12",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1973-10-19",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1973-10-26",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1973-11-02",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "1973-11-09",
    MORTGAGE30US: 8.59,
  },
  {
    DATE: "1973-11-16",
    MORTGAGE30US: 8.58,
  },
  {
    DATE: "1973-11-23",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1973-11-30",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1973-12-07",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1973-12-14",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1973-12-21",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1973-12-28",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1974-01-04",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1974-01-11",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1974-01-18",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1974-01-25",
    MORTGAGE30US: 8.52,
  },
  {
    DATE: "1974-02-01",
    MORTGAGE30US: 8.48,
  },
  {
    DATE: "1974-02-08",
    MORTGAGE30US: 8.46,
  },
  {
    DATE: "1974-02-15",
    MORTGAGE30US: 8.46,
  },
  {
    DATE: "1974-02-22",
    MORTGAGE30US: 8.42,
  },
  {
    DATE: "1974-03-01",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1974-03-08",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1974-03-15",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1974-03-22",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1974-03-29",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1974-04-05",
    MORTGAGE30US: 8.44,
  },
  {
    DATE: "1974-04-12",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1974-04-19",
    MORTGAGE30US: 8.63,
  },
  {
    DATE: "1974-04-26",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1974-05-03",
    MORTGAGE30US: 8.87,
  },
  {
    DATE: "1974-05-10",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1974-05-17",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1974-05-24",
    MORTGAGE30US: 9.01,
  },
  {
    DATE: "1974-05-31",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1974-06-07",
    MORTGAGE30US: 9.06,
  },
  {
    DATE: "1974-06-14",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1974-06-21",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1974-06-28",
    MORTGAGE30US: 9.1,
  },
  {
    DATE: "1974-07-05",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1974-07-12",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1974-07-19",
    MORTGAGE30US: 9.37,
  },
  {
    DATE: "1974-07-26",
    MORTGAGE30US: 9.42,
  },
  {
    DATE: "1974-08-02",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1974-08-09",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1974-08-16",
    MORTGAGE30US: 9.6,
  },
  {
    DATE: "1974-08-23",
    MORTGAGE30US: 9.62,
  },
  {
    DATE: "1974-08-30",
    MORTGAGE30US: 9.74,
  },
  {
    DATE: "1974-09-06",
    MORTGAGE30US: 9.88,
  },
  {
    DATE: "1974-09-13",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1974-09-20",
    MORTGAGE30US: 10,
  },
  {
    DATE: "1974-09-27",
    MORTGAGE30US: 10.03,
  },
  {
    DATE: "1974-10-04",
    MORTGAGE30US: 10.02,
  },
  {
    DATE: "1974-10-11",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1974-10-18",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1974-10-25",
    MORTGAGE30US: 9.94,
  },
  {
    DATE: "1974-11-01",
    MORTGAGE30US: 9.87,
  },
  {
    DATE: "1974-11-08",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1974-11-15",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1974-11-22",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1974-11-29",
    MORTGAGE30US: 9.72,
  },
  {
    DATE: "1974-12-06",
    MORTGAGE30US: 9.69,
  },
  {
    DATE: "1974-12-13",
    MORTGAGE30US: 9.63,
  },
  {
    DATE: "1974-12-20",
    MORTGAGE30US: 9.58,
  },
  {
    DATE: "1974-12-27",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1975-01-03",
    MORTGAGE30US: 9.6,
  },
  {
    DATE: "1975-01-10",
    MORTGAGE30US: 9.49,
  },
  {
    DATE: "1975-01-17",
    MORTGAGE30US: 9.43,
  },
  {
    DATE: "1975-01-24",
    MORTGAGE30US: 9.35,
  },
  {
    DATE: "1975-01-31",
    MORTGAGE30US: 9.29,
  },
  {
    DATE: "1975-02-07",
    MORTGAGE30US: 9.2,
  },
  {
    DATE: "1975-02-14",
    MORTGAGE30US: 9.13,
  },
  {
    DATE: "1975-02-21",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1975-02-28",
    MORTGAGE30US: 9.02,
  },
  {
    DATE: "1975-03-07",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1975-03-14",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-03-21",
    MORTGAGE30US: 8.86,
  },
  {
    DATE: "1975-03-28",
    MORTGAGE30US: 8.86,
  },
  {
    DATE: "1975-04-04",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1975-04-11",
    MORTGAGE30US: 8.81,
  },
  {
    DATE: "1975-04-18",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1975-04-25",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1975-05-02",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1975-05-09",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1975-05-16",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1975-05-23",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1975-05-30",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-06-06",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-06-13",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1975-06-20",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1975-06-27",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1975-07-03",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1975-07-11",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1975-07-18",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-07-25",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-08-01",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-08-08",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-08-15",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1975-08-22",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1975-08-29",
    MORTGAGE30US: 9.02,
  },
  {
    DATE: "1975-09-05",
    MORTGAGE30US: 9.08,
  },
  {
    DATE: "1975-09-12",
    MORTGAGE30US: 9.16,
  },
  {
    DATE: "1975-09-19",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1975-09-26",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1975-10-03",
    MORTGAGE30US: 9.2,
  },
  {
    DATE: "1975-10-10",
    MORTGAGE30US: 9.21,
  },
  {
    DATE: "1975-10-17",
    MORTGAGE30US: 9.21,
  },
  {
    DATE: "1975-10-24",
    MORTGAGE30US: 9.26,
  },
  {
    DATE: "1975-10-31",
    MORTGAGE30US: 9.24,
  },
  {
    DATE: "1975-11-07",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1975-11-14",
    MORTGAGE30US: 9.16,
  },
  {
    DATE: "1975-11-21",
    MORTGAGE30US: 9.12,
  },
  {
    DATE: "1975-11-28",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1975-12-05",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1975-12-12",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1975-12-19",
    MORTGAGE30US: 9.08,
  },
  {
    DATE: "1975-12-26",
    MORTGAGE30US: 9.09,
  },
  {
    DATE: "1976-01-02",
    MORTGAGE30US: 9.1,
  },
  {
    DATE: "1976-01-09",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1976-01-16",
    MORTGAGE30US: 9.01,
  },
  {
    DATE: "1976-01-23",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-01-30",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-02-06",
    MORTGAGE30US: 8.86,
  },
  {
    DATE: "1976-02-13",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1976-02-20",
    MORTGAGE30US: 8.81,
  },
  {
    DATE: "1976-02-27",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-03-05",
    MORTGAGE30US: 8.77,
  },
  {
    DATE: "1976-03-12",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-03-19",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-03-26",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-04-02",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1976-04-09",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1976-04-16",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-04-23",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-04-30",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-05-07",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-05-14",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-05-21",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-05-28",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-06-04",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-06-11",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1976-06-18",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1976-06-25",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-07-02",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1976-07-09",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1976-07-16",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1976-07-23",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-07-30",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1976-08-06",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-08-13",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-08-20",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-08-27",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-09-03",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-09-10",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1976-09-17",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1976-09-24",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1976-10-01",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-10-08",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1976-10-15",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1976-10-22",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1976-10-29",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-11-05",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1976-11-12",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-11-19",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-11-26",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-03",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-10",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-17",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-24",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-12-31",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-01-07",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-01-14",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1977-01-21",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1977-01-28",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1977-02-04",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1977-02-11",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-02-18",
    MORTGAGE30US: 8.65,
  },
  {
    DATE: "1977-02-25",
    MORTGAGE30US: 8.65,
  },
  {
    DATE: "1977-03-04",
    MORTGAGE30US: 8.65,
  },
  {
    DATE: "1977-03-11",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-03-18",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-03-25",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-04-01",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-04-08",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1977-04-15",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1977-04-22",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-04-29",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-05-06",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-05-13",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1977-05-20",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-05-27",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-03",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-10",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-17",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-24",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1977-07-01",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-07-08",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-07-15",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-07-22",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-07-29",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-08-05",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-08-12",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-08-19",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-08-26",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-09-02",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1977-09-09",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-09-16",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-09-23",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-09-30",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-10-07",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-10-14",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-10-21",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-10-28",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-11-04",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-11-11",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-11-18",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-11-25",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-12-02",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-09",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-16",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-23",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-30",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1978-01-06",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1978-01-13",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1978-01-20",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1978-01-27",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1978-02-03",
    MORTGAGE30US: 9.13,
  },
  {
    DATE: "1978-02-10",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-02-17",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-02-24",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-03-03",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-03-10",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-03-17",
    MORTGAGE30US: 9.23,
  },
  {
    DATE: "1978-03-24",
    MORTGAGE30US: 9.23,
  },
  {
    DATE: "1978-03-31",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1978-04-07",
    MORTGAGE30US: 9.28,
  },
  {
    DATE: "1978-04-14",
    MORTGAGE30US: 9.33,
  },
  {
    DATE: "1978-04-21",
    MORTGAGE30US: 9.38,
  },
  {
    DATE: "1978-04-28",
    MORTGAGE30US: 9.43,
  },
  {
    DATE: "1978-05-05",
    MORTGAGE30US: 9.48,
  },
  {
    DATE: "1978-05-12",
    MORTGAGE30US: 9.55,
  },
  {
    DATE: "1978-05-19",
    MORTGAGE30US: 9.58,
  },
  {
    DATE: "1978-05-26",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1978-06-02",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1978-06-09",
    MORTGAGE30US: 9.7,
  },
  {
    DATE: "1978-06-16",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-06-23",
    MORTGAGE30US: 9.7,
  },
  {
    DATE: "1978-06-30",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-07-07",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-07-14",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-07-21",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-07-28",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-08-04",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-08-11",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-08-18",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-08-25",
    MORTGAGE30US: 9.8,
  },
  {
    DATE: "1978-09-01",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-08",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-15",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-22",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-29",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-10-06",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1978-10-13",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1978-10-20",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1978-10-27",
    MORTGAGE30US: 9.88,
  },
  {
    DATE: "1978-11-03",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1978-11-10",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1978-11-17",
    MORTGAGE30US: 10.2,
  },
  {
    DATE: "1978-11-24",
    MORTGAGE30US: 10.28,
  },
  {
    DATE: "1978-12-01",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1978-12-08",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1978-12-15",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1978-12-22",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1978-12-29",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1979-01-05",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1979-01-12",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1979-01-19",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-01-26",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-02-02",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-02-09",
    MORTGAGE30US: 10.43,
  },
  {
    DATE: "1979-02-16",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-02-23",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-03-02",
    MORTGAGE30US: 10.43,
  },
  {
    DATE: "1979-03-09",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-03-16",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-03-23",
    MORTGAGE30US: 10.45,
  },
  {
    DATE: "1979-03-30",
    MORTGAGE30US: 10.45,
  },
  {
    DATE: "1979-04-06",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1979-04-13",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1979-04-20",
    MORTGAGE30US: 10.5,
  },
  {
    DATE: "1979-04-27",
    MORTGAGE30US: 10.53,
  },
  {
    DATE: "1979-05-04",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1979-05-11",
    MORTGAGE30US: 10.68,
  },
  {
    DATE: "1979-05-18",
    MORTGAGE30US: 10.73,
  },
  {
    DATE: "1979-05-25",
    MORTGAGE30US: 10.75,
  },
  {
    DATE: "1979-06-01",
    MORTGAGE30US: 10.9,
  },
  {
    DATE: "1979-06-08",
    MORTGAGE30US: 11.03,
  },
  {
    DATE: "1979-06-15",
    MORTGAGE30US: 11.05,
  },
  {
    DATE: "1979-06-22",
    MORTGAGE30US: 11.1,
  },
  {
    DATE: "1979-06-29",
    MORTGAGE30US: 11.1,
  },
  {
    DATE: "1979-07-06",
    MORTGAGE30US: 11.13,
  },
  {
    DATE: "1979-07-13",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-07-20",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-07-27",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-03",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-10",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-17",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-24",
    MORTGAGE30US: 11.1,
  },
  {
    DATE: "1979-08-31",
    MORTGAGE30US: 11.13,
  },
  {
    DATE: "1979-09-07",
    MORTGAGE30US: 11.2,
  },
  {
    DATE: "1979-09-14",
    MORTGAGE30US: 11.3,
  },
  {
    DATE: "1979-09-21",
    MORTGAGE30US: 11.35,
  },
  {
    DATE: "1979-09-28",
    MORTGAGE30US: 11.35,
  },
  {
    DATE: "1979-10-05",
    MORTGAGE30US: 11.35,
  },
  {
    DATE: "1979-10-12",
    MORTGAGE30US: 11.45,
  },
  {
    DATE: "1979-10-19",
    MORTGAGE30US: 11.75,
  },
  {
    DATE: "1979-10-26",
    MORTGAGE30US: 12,
  },
  {
    DATE: "1979-11-02",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1979-11-09",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1979-11-16",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1979-11-23",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1979-11-30",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-07",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-14",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-21",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-28",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1980-01-04",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-01-11",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1980-01-18",
    MORTGAGE30US: 12.87,
  },
  {
    DATE: "1980-01-25",
    MORTGAGE30US: 12.89,
  },
  {
    DATE: "1980-02-01",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-02-08",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-02-15",
    MORTGAGE30US: 12.88,
  },
  {
    DATE: "1980-02-22",
    MORTGAGE30US: 13.03,
  },
  {
    DATE: "1980-02-29",
    MORTGAGE30US: 13.59,
  },
  {
    DATE: "1980-03-07",
    MORTGAGE30US: 14,
  },
  {
    DATE: "1980-03-14",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1980-03-21",
    MORTGAGE30US: 15.7,
  },
  {
    DATE: "1980-03-28",
    MORTGAGE30US: 16.03,
  },
  {
    DATE: "1980-04-04",
    MORTGAGE30US: 16.35,
  },
  {
    DATE: "1980-04-11",
    MORTGAGE30US: 16.35,
  },
  {
    DATE: "1980-04-18",
    MORTGAGE30US: 16.35,
  },
  {
    DATE: "1980-04-25",
    MORTGAGE30US: 16.25,
  },
  {
    DATE: "1980-05-02",
    MORTGAGE30US: 15.9,
  },
  {
    DATE: "1980-05-09",
    MORTGAGE30US: 14.68,
  },
  {
    DATE: "1980-05-16",
    MORTGAGE30US: 14.15,
  },
  {
    DATE: "1980-05-23",
    MORTGAGE30US: 13.38,
  },
  {
    DATE: "1980-05-30",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1980-06-06",
    MORTGAGE30US: 13.06,
  },
  {
    DATE: "1980-06-13",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-06-20",
    MORTGAGE30US: 12.58,
  },
  {
    DATE: "1980-06-27",
    MORTGAGE30US: 12.35,
  },
  {
    DATE: "1980-07-04",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1980-07-11",
    MORTGAGE30US: 12.23,
  },
  {
    DATE: "1980-07-18",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1980-07-25",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1980-08-01",
    MORTGAGE30US: 12.25,
  },
  {
    DATE: "1980-08-08",
    MORTGAGE30US: 12.25,
  },
  {
    DATE: "1980-08-15",
    MORTGAGE30US: 12.55,
  },
  {
    DATE: "1980-08-22",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1980-08-29",
    MORTGAGE30US: 12.95,
  },
  {
    DATE: "1980-09-05",
    MORTGAGE30US: 13.03,
  },
  {
    DATE: "1980-09-12",
    MORTGAGE30US: 13.08,
  },
  {
    DATE: "1980-09-19",
    MORTGAGE30US: 13.25,
  },
  {
    DATE: "1980-09-26",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1980-10-03",
    MORTGAGE30US: 13.6,
  },
  {
    DATE: "1980-10-10",
    MORTGAGE30US: 13.73,
  },
  {
    DATE: "1980-10-17",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1980-10-24",
    MORTGAGE30US: 13.85,
  },
  {
    DATE: "1980-10-31",
    MORTGAGE30US: 14,
  },
  {
    DATE: "1980-11-07",
    MORTGAGE30US: 14.08,
  },
  {
    DATE: "1980-11-14",
    MORTGAGE30US: 14.18,
  },
  {
    DATE: "1980-11-21",
    MORTGAGE30US: 14.28,
  },
  {
    DATE: "1980-11-28",
    MORTGAGE30US: 14.28,
  },
  {
    DATE: "1980-12-05",
    MORTGAGE30US: 14.43,
  },
  {
    DATE: "1980-12-12",
    MORTGAGE30US: 14.83,
  },
  {
    DATE: "1980-12-19",
    MORTGAGE30US: 14.95,
  },
  {
    DATE: "1980-12-26",
    MORTGAGE30US: 14.95,
  },
  {
    DATE: "1981-01-02",
    MORTGAGE30US: 14.95,
  },
  {
    DATE: "1981-01-09",
    MORTGAGE30US: 14.8,
  },
  {
    DATE: "1981-01-16",
    MORTGAGE30US: 14.85,
  },
  {
    DATE: "1981-01-23",
    MORTGAGE30US: 14.85,
  },
  {
    DATE: "1981-01-30",
    MORTGAGE30US: 15.07,
  },
  {
    DATE: "1981-02-06",
    MORTGAGE30US: 15,
  },
  {
    DATE: "1981-02-13",
    MORTGAGE30US: 15.03,
  },
  {
    DATE: "1981-02-20",
    MORTGAGE30US: 15.2,
  },
  {
    DATE: "1981-02-27",
    MORTGAGE30US: 15.3,
  },
  {
    DATE: "1981-03-06",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-03-13",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-03-20",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-03-27",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-04-03",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-04-10",
    MORTGAGE30US: 15.5,
  },
  {
    DATE: "1981-04-17",
    MORTGAGE30US: 15.65,
  },
  {
    DATE: "1981-04-24",
    MORTGAGE30US: 15.77,
  },
  {
    DATE: "1981-05-01",
    MORTGAGE30US: 15.82,
  },
  {
    DATE: "1981-05-08",
    MORTGAGE30US: 16.12,
  },
  {
    DATE: "1981-05-15",
    MORTGAGE30US: 16.64,
  },
  {
    DATE: "1981-05-22",
    MORTGAGE30US: 16.63,
  },
  {
    DATE: "1981-05-29",
    MORTGAGE30US: 16.8,
  },
  {
    DATE: "1981-06-05",
    MORTGAGE30US: 16.76,
  },
  {
    DATE: "1981-06-12",
    MORTGAGE30US: 16.69,
  },
  {
    DATE: "1981-06-19",
    MORTGAGE30US: 16.71,
  },
  {
    DATE: "1981-06-26",
    MORTGAGE30US: 16.62,
  },
  {
    DATE: "1981-07-03",
    MORTGAGE30US: 16.64,
  },
  {
    DATE: "1981-07-10",
    MORTGAGE30US: 16.79,
  },
  {
    DATE: "1981-07-17",
    MORTGAGE30US: 16.74,
  },
  {
    DATE: "1981-07-24",
    MORTGAGE30US: 16.88,
  },
  {
    DATE: "1981-07-31",
    MORTGAGE30US: 17.11,
  },
  {
    DATE: "1981-08-07",
    MORTGAGE30US: 17.13,
  },
  {
    DATE: "1981-08-14",
    MORTGAGE30US: 17.27,
  },
  {
    DATE: "1981-08-21",
    MORTGAGE30US: 17.26,
  },
  {
    DATE: "1981-08-28",
    MORTGAGE30US: 17.48,
  },
  {
    DATE: "1981-09-04",
    MORTGAGE30US: 17.79,
  },
  {
    DATE: "1981-09-11",
    MORTGAGE30US: 18.22,
  },
  {
    DATE: "1981-09-18",
    MORTGAGE30US: 18.27,
  },
  {
    DATE: "1981-09-25",
    MORTGAGE30US: 18.36,
  },
  {
    DATE: "1981-10-02",
    MORTGAGE30US: 18.28,
  },
  {
    DATE: "1981-10-09",
    MORTGAGE30US: 18.63,
  },
  {
    DATE: "1981-10-16",
    MORTGAGE30US: 18.53,
  },
  {
    DATE: "1981-10-21",
    MORTGAGE30US: 18.39,
  },
  {
    DATE: "1981-10-30",
    MORTGAGE30US: 18.44,
  },
  {
    DATE: "1981-11-06",
    MORTGAGE30US: 18.37,
  },
  {
    DATE: "1981-11-13",
    MORTGAGE30US: 18.02,
  },
  {
    DATE: "1981-11-20",
    MORTGAGE30US: 17.7,
  },
  {
    DATE: "1981-11-27",
    MORTGAGE30US: 17.21,
  },
  {
    DATE: "1981-12-04",
    MORTGAGE30US: 16.9,
  },
  {
    DATE: "1981-12-11",
    MORTGAGE30US: 16.94,
  },
  {
    DATE: "1981-12-18",
    MORTGAGE30US: 16.9,
  },
  {
    DATE: "1981-12-25",
    MORTGAGE30US: 16.95,
  },
  {
    DATE: "1981-12-31",
    MORTGAGE30US: 17.04,
  },
  {
    DATE: "1982-01-08",
    MORTGAGE30US: 17.3,
  },
  {
    DATE: "1982-01-15",
    MORTGAGE30US: 17.44,
  },
  {
    DATE: "1982-01-22",
    MORTGAGE30US: 17.61,
  },
  {
    DATE: "1982-01-29",
    MORTGAGE30US: 17.59,
  },
  {
    DATE: "1982-02-05",
    MORTGAGE30US: 17.56,
  },
  {
    DATE: "1982-02-12",
    MORTGAGE30US: 17.65,
  },
  {
    DATE: "1982-02-19",
    MORTGAGE30US: 17.66,
  },
  {
    DATE: "1982-02-26",
    MORTGAGE30US: 17.52,
  },
  {
    DATE: "1982-03-05",
    MORTGAGE30US: 17.29,
  },
  {
    DATE: "1982-03-12",
    MORTGAGE30US: 17.19,
  },
  {
    DATE: "1982-03-19",
    MORTGAGE30US: 17.12,
  },
  {
    DATE: "1982-03-26",
    MORTGAGE30US: 17.04,
  },
  {
    DATE: "1982-04-02",
    MORTGAGE30US: 16.95,
  },
  {
    DATE: "1982-04-09",
    MORTGAGE30US: 16.91,
  },
  {
    DATE: "1982-04-16",
    MORTGAGE30US: 16.93,
  },
  {
    DATE: "1982-04-23",
    MORTGAGE30US: 16.86,
  },
  {
    DATE: "1982-04-30",
    MORTGAGE30US: 16.81,
  },
  {
    DATE: "1982-05-07",
    MORTGAGE30US: 16.78,
  },
  {
    DATE: "1982-05-14",
    MORTGAGE30US: 16.63,
  },
  {
    DATE: "1982-05-21",
    MORTGAGE30US: 16.67,
  },
  {
    DATE: "1982-05-28",
    MORTGAGE30US: 16.63,
  },
  {
    DATE: "1982-06-04",
    MORTGAGE30US: 16.65,
  },
  {
    DATE: "1982-06-11",
    MORTGAGE30US: 16.7,
  },
  {
    DATE: "1982-06-18",
    MORTGAGE30US: 16.71,
  },
  {
    DATE: "1982-06-25",
    MORTGAGE30US: 16.73,
  },
  {
    DATE: "1982-07-02",
    MORTGAGE30US: 16.87,
  },
  {
    DATE: "1982-07-09",
    MORTGAGE30US: 16.93,
  },
  {
    DATE: "1982-07-16",
    MORTGAGE30US: 16.88,
  },
  {
    DATE: "1982-07-23",
    MORTGAGE30US: 16.75,
  },
  {
    DATE: "1982-07-30",
    MORTGAGE30US: 16.65,
  },
  {
    DATE: "1982-08-06",
    MORTGAGE30US: 16.55,
  },
  {
    DATE: "1982-08-13",
    MORTGAGE30US: 16.44,
  },
  {
    DATE: "1982-08-20",
    MORTGAGE30US: 16.21,
  },
  {
    DATE: "1982-08-27",
    MORTGAGE30US: 15.88,
  },
  {
    DATE: "1982-09-03",
    MORTGAGE30US: 15.59,
  },
  {
    DATE: "1982-09-10",
    MORTGAGE30US: 15.56,
  },
  {
    DATE: "1982-09-17",
    MORTGAGE30US: 15.38,
  },
  {
    DATE: "1982-09-24",
    MORTGAGE30US: 15.19,
  },
  {
    DATE: "1982-10-01",
    MORTGAGE30US: 15.13,
  },
  {
    DATE: "1982-10-08",
    MORTGAGE30US: 14.96,
  },
  {
    DATE: "1982-10-15",
    MORTGAGE30US: 14.6,
  },
  {
    DATE: "1982-10-22",
    MORTGAGE30US: 14.2,
  },
  {
    DATE: "1982-10-29",
    MORTGAGE30US: 14.15,
  },
  {
    DATE: "1982-11-05",
    MORTGAGE30US: 13.91,
  },
  {
    DATE: "1982-11-12",
    MORTGAGE30US: 13.84,
  },
  {
    DATE: "1982-11-19",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1982-11-26",
    MORTGAGE30US: 13.77,
  },
  {
    DATE: "1982-12-03",
    MORTGAGE30US: 13.66,
  },
  {
    DATE: "1982-12-10",
    MORTGAGE30US: 13.66,
  },
  {
    DATE: "1982-12-17",
    MORTGAGE30US: 13.63,
  },
  {
    DATE: "1982-12-24",
    MORTGAGE30US: 13.6,
  },
  {
    DATE: "1982-12-31",
    MORTGAGE30US: 13.57,
  },
  {
    DATE: "1983-01-07",
    MORTGAGE30US: 13.46,
  },
  {
    DATE: "1983-01-14",
    MORTGAGE30US: 13.31,
  },
  {
    DATE: "1983-01-21",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1983-01-28",
    MORTGAGE30US: 13.1,
  },
  {
    DATE: "1983-02-04",
    MORTGAGE30US: 13.06,
  },
  {
    DATE: "1983-02-11",
    MORTGAGE30US: 13.06,
  },
  {
    DATE: "1983-02-18",
    MORTGAGE30US: 13.07,
  },
  {
    DATE: "1983-02-25",
    MORTGAGE30US: 12.98,
  },
  {
    DATE: "1983-03-04",
    MORTGAGE30US: 12.74,
  },
  {
    DATE: "1983-03-11",
    MORTGAGE30US: 12.79,
  },
  {
    DATE: "1983-03-18",
    MORTGAGE30US: 12.81,
  },
  {
    DATE: "1983-03-25",
    MORTGAGE30US: 12.86,
  },
  {
    DATE: "1983-04-01",
    MORTGAGE30US: 12.82,
  },
  {
    DATE: "1983-04-08",
    MORTGAGE30US: 12.82,
  },
  {
    DATE: "1983-04-15",
    MORTGAGE30US: 12.79,
  },
  {
    DATE: "1983-04-22",
    MORTGAGE30US: 12.75,
  },
  {
    DATE: "1983-04-29",
    MORTGAGE30US: 12.73,
  },
  {
    DATE: "1983-05-06",
    MORTGAGE30US: 12.71,
  },
  {
    DATE: "1983-05-13",
    MORTGAGE30US: 12.59,
  },
  {
    DATE: "1983-05-20",
    MORTGAGE30US: 12.55,
  },
  {
    DATE: "1983-05-27",
    MORTGAGE30US: 12.68,
  },
  {
    DATE: "1983-06-03",
    MORTGAGE30US: 12.74,
  },
  {
    DATE: "1983-06-10",
    MORTGAGE30US: 12.82,
  },
  {
    DATE: "1983-06-17",
    MORTGAGE30US: 12.96,
  },
  {
    DATE: "1983-06-24",
    MORTGAGE30US: 12.96,
  },
  {
    DATE: "1983-07-01",
    MORTGAGE30US: 13.08,
  },
  {
    DATE: "1983-07-08",
    MORTGAGE30US: 13.3,
  },
  {
    DATE: "1983-07-15",
    MORTGAGE30US: 13.5,
  },
  {
    DATE: "1983-07-22",
    MORTGAGE30US: 13.58,
  },
  {
    DATE: "1983-07-29",
    MORTGAGE30US: 13.65,
  },
  {
    DATE: "1983-08-05",
    MORTGAGE30US: 13.73,
  },
  {
    DATE: "1983-08-12",
    MORTGAGE30US: 13.84,
  },
  {
    DATE: "1983-08-19",
    MORTGAGE30US: 13.89,
  },
  {
    DATE: "1983-08-26",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1983-09-02",
    MORTGAGE30US: 13.77,
  },
  {
    DATE: "1983-09-09",
    MORTGAGE30US: 13.77,
  },
  {
    DATE: "1983-09-16",
    MORTGAGE30US: 13.72,
  },
  {
    DATE: "1983-09-23",
    MORTGAGE30US: 13.72,
  },
  {
    DATE: "1983-09-30",
    MORTGAGE30US: 13.65,
  },
  {
    DATE: "1983-10-07",
    MORTGAGE30US: 13.59,
  },
  {
    DATE: "1983-10-14",
    MORTGAGE30US: 13.6,
  },
  {
    DATE: "1983-10-21",
    MORTGAGE30US: 13.52,
  },
  {
    DATE: "1983-10-28",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1983-11-04",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1983-11-11",
    MORTGAGE30US: 13.47,
  },
  {
    DATE: "1983-11-18",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1983-11-25",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1983-12-02",
    MORTGAGE30US: 13.41,
  },
  {
    DATE: "1983-12-09",
    MORTGAGE30US: 13.38,
  },
  {
    DATE: "1983-12-16",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1983-12-23",
    MORTGAGE30US: 13.46,
  },
  {
    DATE: "1983-12-30",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1984-01-06",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1984-01-13",
    MORTGAGE30US: 13.4,
  },
  {
    DATE: "1984-01-20",
    MORTGAGE30US: 13.35,
  },
  {
    DATE: "1984-01-27",
    MORTGAGE30US: 13.29,
  },
  {
    DATE: "1984-02-03",
    MORTGAGE30US: 13.26,
  },
  {
    DATE: "1984-02-10",
    MORTGAGE30US: 13.23,
  },
  {
    DATE: "1984-02-17",
    MORTGAGE30US: 13.19,
  },
  {
    DATE: "1984-02-24",
    MORTGAGE30US: 13.25,
  },
  {
    DATE: "1984-03-02",
    MORTGAGE30US: 13.23,
  },
  {
    DATE: "1984-03-09",
    MORTGAGE30US: 13.3,
  },
  {
    DATE: "1984-03-16",
    MORTGAGE30US: 13.37,
  },
  {
    DATE: "1984-03-23",
    MORTGAGE30US: 13.48,
  },
  {
    DATE: "1984-03-30",
    MORTGAGE30US: 13.55,
  },
  {
    DATE: "1984-04-06",
    MORTGAGE30US: 13.63,
  },
  {
    DATE: "1984-04-13",
    MORTGAGE30US: 13.58,
  },
  {
    DATE: "1984-04-20",
    MORTGAGE30US: 13.67,
  },
  {
    DATE: "1984-04-27",
    MORTGAGE30US: 13.73,
  },
  {
    DATE: "1984-05-04",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1984-05-11",
    MORTGAGE30US: 13.87,
  },
  {
    DATE: "1984-05-18",
    MORTGAGE30US: 14.04,
  },
  {
    DATE: "1984-05-25",
    MORTGAGE30US: 14.08,
  },
  {
    DATE: "1984-06-01",
    MORTGAGE30US: 14.29,
  },
  {
    DATE: "1984-06-08",
    MORTGAGE30US: 14.33,
  },
  {
    DATE: "1984-06-15",
    MORTGAGE30US: 14.47,
  },
  {
    DATE: "1984-06-22",
    MORTGAGE30US: 14.49,
  },
  {
    DATE: "1984-06-29",
    MORTGAGE30US: 14.5,
  },
  {
    DATE: "1984-07-06",
    MORTGAGE30US: 14.66,
  },
  {
    DATE: "1984-07-13",
    MORTGAGE30US: 14.68,
  },
  {
    DATE: "1984-07-20",
    MORTGAGE30US: 14.66,
  },
  {
    DATE: "1984-07-27",
    MORTGAGE30US: 14.67,
  },
  {
    DATE: "1984-08-03",
    MORTGAGE30US: 14.68,
  },
  {
    DATE: "1984-08-10",
    MORTGAGE30US: 14.54,
  },
  {
    DATE: "1984-08-17",
    MORTGAGE30US: 14.39,
  },
  {
    DATE: "1984-08-24",
    MORTGAGE30US: 14.36,
  },
  {
    DATE: "1984-08-31",
    MORTGAGE30US: 14.38,
  },
  {
    DATE: "1984-09-07",
    MORTGAGE30US: 14.42,
  },
  {
    DATE: "1984-09-14",
    MORTGAGE30US: 14.43,
  },
  {
    DATE: "1984-09-21",
    MORTGAGE30US: 14.29,
  },
  {
    DATE: "1984-09-28",
    MORTGAGE30US: 14.26,
  },
  {
    DATE: "1984-10-05",
    MORTGAGE30US: 14.18,
  },
  {
    DATE: "1984-10-12",
    MORTGAGE30US: 14.19,
  },
  {
    DATE: "1984-10-19",
    MORTGAGE30US: 14.1,
  },
  {
    DATE: "1984-10-26",
    MORTGAGE30US: 14.05,
  },
  {
    DATE: "1984-11-02",
    MORTGAGE30US: 13.85,
  },
  {
    DATE: "1984-11-09",
    MORTGAGE30US: 13.74,
  },
  {
    DATE: "1984-11-16",
    MORTGAGE30US: 13.63,
  },
  {
    DATE: "1984-11-23",
    MORTGAGE30US: 13.55,
  },
  {
    DATE: "1984-11-30",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1984-12-07",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1984-12-14",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1984-12-21",
    MORTGAGE30US: 13.18,
  },
  {
    DATE: "1984-12-28",
    MORTGAGE30US: 13.14,
  },
  {
    DATE: "1985-01-04",
    MORTGAGE30US: 13.1,
  },
  {
    DATE: "1985-01-11",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1985-01-18",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1985-01-25",
    MORTGAGE30US: 12.96,
  },
  {
    DATE: "1985-02-01",
    MORTGAGE30US: 12.93,
  },
  {
    DATE: "1985-02-08",
    MORTGAGE30US: 12.91,
  },
  {
    DATE: "1985-02-15",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1985-02-22",
    MORTGAGE30US: 12.94,
  },
  {
    DATE: "1985-03-01",
    MORTGAGE30US: 13.02,
  },
  {
    DATE: "1985-03-08",
    MORTGAGE30US: 13.1,
  },
  {
    DATE: "1985-03-15",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1985-03-22",
    MORTGAGE30US: 13.24,
  },
  {
    DATE: "1985-03-29",
    MORTGAGE30US: 13.29,
  },
  {
    DATE: "1985-04-05",
    MORTGAGE30US: 13.27,
  },
  {
    DATE: "1985-04-12",
    MORTGAGE30US: 13.23,
  },
  {
    DATE: "1985-04-19",
    MORTGAGE30US: 13.16,
  },
  {
    DATE: "1985-04-26",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1985-05-03",
    MORTGAGE30US: 13.07,
  },
  {
    DATE: "1985-05-10",
    MORTGAGE30US: 13.02,
  },
  {
    DATE: "1985-05-17",
    MORTGAGE30US: 12.94,
  },
  {
    DATE: "1985-05-24",
    MORTGAGE30US: 12.83,
  },
  {
    DATE: "1985-05-31",
    MORTGAGE30US: 12.71,
  },
  {
    DATE: "1985-06-07",
    MORTGAGE30US: 12.39,
  },
  {
    DATE: "1985-06-14",
    MORTGAGE30US: 12.27,
  },
  {
    DATE: "1985-06-21",
    MORTGAGE30US: 12.05,
  },
  {
    DATE: "1985-06-28",
    MORTGAGE30US: 12.15,
  },
  {
    DATE: "1985-07-05",
    MORTGAGE30US: 12.13,
  },
  {
    DATE: "1985-07-12",
    MORTGAGE30US: 12.03,
  },
  {
    DATE: "1985-07-19",
    MORTGAGE30US: 11.94,
  },
  {
    DATE: "1985-07-26",
    MORTGAGE30US: 12.03,
  },
  {
    DATE: "1985-08-02",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-08-09",
    MORTGAGE30US: 12.23,
  },
  {
    DATE: "1985-08-16",
    MORTGAGE30US: 12.24,
  },
  {
    DATE: "1985-08-23",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1985-08-30",
    MORTGAGE30US: 12.11,
  },
  {
    DATE: "1985-09-06",
    MORTGAGE30US: 12.15,
  },
  {
    DATE: "1985-09-13",
    MORTGAGE30US: 12.24,
  },
  {
    DATE: "1985-09-20",
    MORTGAGE30US: 12.21,
  },
  {
    DATE: "1985-09-27",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-10-04",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-10-11",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-10-18",
    MORTGAGE30US: 12.13,
  },
  {
    DATE: "1985-10-25",
    MORTGAGE30US: 12.07,
  },
  {
    DATE: "1985-11-01",
    MORTGAGE30US: 12.01,
  },
  {
    DATE: "1985-11-08",
    MORTGAGE30US: 11.9,
  },
  {
    DATE: "1985-11-15",
    MORTGAGE30US: 11.79,
  },
  {
    DATE: "1985-11-22",
    MORTGAGE30US: 11.64,
  },
  {
    DATE: "1985-11-29",
    MORTGAGE30US: 11.58,
  },
  {
    DATE: "1985-12-06",
    MORTGAGE30US: 11.5,
  },
  {
    DATE: "1985-12-13",
    MORTGAGE30US: 11.31,
  },
  {
    DATE: "1985-12-20",
    MORTGAGE30US: 11.14,
  },
  {
    DATE: "1985-12-27",
    MORTGAGE30US: 11.09,
  },
  {
    DATE: "1986-01-03",
    MORTGAGE30US: 10.81,
  },
  {
    DATE: "1986-01-10",
    MORTGAGE30US: 10.77,
  },
  {
    DATE: "1986-01-17",
    MORTGAGE30US: 10.99,
  },
  {
    DATE: "1986-01-24",
    MORTGAGE30US: 10.97,
  },
  {
    DATE: "1986-01-31",
    MORTGAGE30US: 10.89,
  },
  {
    DATE: "1986-02-07",
    MORTGAGE30US: 10.85,
  },
  {
    DATE: "1986-02-14",
    MORTGAGE30US: 10.8,
  },
  {
    DATE: "1986-02-21",
    MORTGAGE30US: 10.68,
  },
  {
    DATE: "1986-02-28",
    MORTGAGE30US: 10.51,
  },
  {
    DATE: "1986-03-07",
    MORTGAGE30US: 10.2,
  },
  {
    DATE: "1986-03-14",
    MORTGAGE30US: 10.01,
  },
  {
    DATE: "1986-03-21",
    MORTGAGE30US: 10.01,
  },
  {
    DATE: "1986-03-28",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1986-04-04",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1986-04-11",
    MORTGAGE30US: 9.98,
  },
  {
    DATE: "1986-04-18",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1986-04-25",
    MORTGAGE30US: 9.86,
  },
  {
    DATE: "1986-05-02",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1986-05-09",
    MORTGAGE30US: 10,
  },
  {
    DATE: "1986-05-16",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1986-05-23",
    MORTGAGE30US: 10.36,
  },
  {
    DATE: "1986-05-30",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1986-06-06",
    MORTGAGE30US: 10.74,
  },
  {
    DATE: "1986-06-13",
    MORTGAGE30US: 10.76,
  },
  {
    DATE: "1986-06-20",
    MORTGAGE30US: 10.61,
  },
  {
    DATE: "1986-06-27",
    MORTGAGE30US: 10.62,
  },
  {
    DATE: "1986-07-04",
    MORTGAGE30US: 10.61,
  },
  {
    DATE: "1986-07-11",
    MORTGAGE30US: 10.59,
  },
  {
    DATE: "1986-07-18",
    MORTGAGE30US: 10.43,
  },
  {
    DATE: "1986-07-25",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1986-08-01",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1986-08-08",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1986-08-15",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1986-08-22",
    MORTGAGE30US: 10.04,
  },
  {
    DATE: "1986-08-29",
    MORTGAGE30US: 9.93,
  },
  {
    DATE: "1986-09-05",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1986-09-12",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1986-09-19",
    MORTGAGE30US: 10.07,
  },
  {
    DATE: "1986-09-26",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1986-10-03",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1986-10-10",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1986-10-17",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1986-10-24",
    MORTGAGE30US: 9.95,
  },
  {
    DATE: "1986-10-31",
    MORTGAGE30US: 9.89,
  },
  {
    DATE: "1986-11-07",
    MORTGAGE30US: 9.83,
  },
  {
    DATE: "1986-11-14",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1986-11-21",
    MORTGAGE30US: 9.64,
  },
  {
    DATE: "1986-11-28",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1986-12-05",
    MORTGAGE30US: 9.3,
  },
  {
    DATE: "1986-12-12",
    MORTGAGE30US: 9.35,
  },
  {
    DATE: "1986-12-19",
    MORTGAGE30US: 9.3,
  },
  {
    DATE: "1986-12-26",
    MORTGAGE30US: 9.29,
  },
  {
    DATE: "1987-01-02",
    MORTGAGE30US: 9.37,
  },
  {
    DATE: "1987-01-09",
    MORTGAGE30US: 9.32,
  },
  {
    DATE: "1987-01-16",
    MORTGAGE30US: 9.21,
  },
  {
    DATE: "1987-01-23",
    MORTGAGE30US: 9.04,
  },
  {
    DATE: "1987-01-30",
    MORTGAGE30US: 9.08,
  },
  {
    DATE: "1987-02-06",
    MORTGAGE30US: 9.06,
  },
  {
    DATE: "1987-02-13",
    MORTGAGE30US: 9.09,
  },
  {
    DATE: "1987-02-20",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1987-02-27",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1987-03-06",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1987-03-13",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1987-03-20",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1987-03-27",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1987-04-03",
    MORTGAGE30US: 9.26,
  },
  {
    DATE: "1987-04-10",
    MORTGAGE30US: 9.43,
  },
  {
    DATE: "1987-04-17",
    MORTGAGE30US: 10.27,
  },
  {
    DATE: "1987-04-24",
    MORTGAGE30US: 10.37,
  },
  {
    DATE: "1987-05-01",
    MORTGAGE30US: 10.47,
  },
  {
    DATE: "1987-05-08",
    MORTGAGE30US: 10.52,
  },
  {
    DATE: "1987-05-15",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1987-05-22",
    MORTGAGE30US: 10.81,
  },
  {
    DATE: "1987-05-29",
    MORTGAGE30US: 10.7,
  },
  {
    DATE: "1987-06-05",
    MORTGAGE30US: 10.7,
  },
  {
    DATE: "1987-06-12",
    MORTGAGE30US: 10.66,
  },
  {
    DATE: "1987-06-19",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1987-06-26",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1987-07-03",
    MORTGAGE30US: 10.36,
  },
  {
    DATE: "1987-07-10",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1987-07-17",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1987-07-24",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1987-07-31",
    MORTGAGE30US: 10.27,
  },
  {
    DATE: "1987-08-07",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1987-08-14",
    MORTGAGE30US: 10.34,
  },
  {
    DATE: "1987-08-21",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1987-08-28",
    MORTGAGE30US: 10.33,
  },
  {
    DATE: "1987-09-04",
    MORTGAGE30US: 10.63,
  },
  {
    DATE: "1987-09-11",
    MORTGAGE30US: 10.91,
  },
  {
    DATE: "1987-09-18",
    MORTGAGE30US: 10.99,
  },
  {
    DATE: "1987-09-25",
    MORTGAGE30US: 11.02,
  },
  {
    DATE: "1987-10-02",
    MORTGAGE30US: 11.18,
  },
  {
    DATE: "1987-10-09",
    MORTGAGE30US: 11.21,
  },
  {
    DATE: "1987-10-16",
    MORTGAGE30US: 11.58,
  },
  {
    DATE: "1987-10-23",
    MORTGAGE30US: 11.36,
  },
  {
    DATE: "1987-10-30",
    MORTGAGE30US: 10.97,
  },
  {
    DATE: "1987-11-06",
    MORTGAGE30US: 10.79,
  },
  {
    DATE: "1987-11-13",
    MORTGAGE30US: 10.66,
  },
  {
    DATE: "1987-11-20",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1987-11-27",
    MORTGAGE30US: 10.55,
  },
  {
    DATE: "1987-12-04",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1987-12-11",
    MORTGAGE30US: 10.66,
  },
  {
    DATE: "1987-12-18",
    MORTGAGE30US: 10.69,
  },
  {
    DATE: "1987-12-25",
    MORTGAGE30US: 10.64,
  },
  {
    DATE: "1987-12-31",
    MORTGAGE30US: 10.61,
  },
  {
    DATE: "1988-01-08",
    MORTGAGE30US: 10.5,
  },
  {
    DATE: "1988-01-15",
    MORTGAGE30US: 10.53,
  },
  {
    DATE: "1988-01-22",
    MORTGAGE30US: 10.34,
  },
  {
    DATE: "1988-01-29",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1988-02-05",
    MORTGAGE30US: 9.94,
  },
  {
    DATE: "1988-02-12",
    MORTGAGE30US: 9.84,
  },
  {
    DATE: "1988-02-19",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1988-02-26",
    MORTGAGE30US: 9.87,
  },
  {
    DATE: "1988-03-04",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1988-03-11",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1988-03-18",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1988-03-25",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1988-04-01",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1988-04-08",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1988-04-15",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1988-04-22",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1988-04-29",
    MORTGAGE30US: 10.28,
  },
  {
    DATE: "1988-05-06",
    MORTGAGE30US: 10.32,
  },
  {
    DATE: "1988-05-13",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-05-20",
    MORTGAGE30US: 10.52,
  },
  {
    DATE: "1988-05-27",
    MORTGAGE30US: 10.58,
  },
  {
    DATE: "1988-06-03",
    MORTGAGE30US: 10.58,
  },
  {
    DATE: "1988-06-10",
    MORTGAGE30US: 10.51,
  },
  {
    DATE: "1988-06-17",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1988-06-24",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-07-01",
    MORTGAGE30US: 10.39,
  },
  {
    DATE: "1988-07-08",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1988-07-15",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1988-07-22",
    MORTGAGE30US: 10.46,
  },
  {
    DATE: "1988-07-29",
    MORTGAGE30US: 10.49,
  },
  {
    DATE: "1988-08-05",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1988-08-12",
    MORTGAGE30US: 10.57,
  },
  {
    DATE: "1988-08-19",
    MORTGAGE30US: 10.71,
  },
  {
    DATE: "1988-08-26",
    MORTGAGE30US: 10.67,
  },
  {
    DATE: "1988-09-02",
    MORTGAGE30US: 10.65,
  },
  {
    DATE: "1988-09-09",
    MORTGAGE30US: 10.53,
  },
  {
    DATE: "1988-09-16",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-09-23",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-09-30",
    MORTGAGE30US: 10.42,
  },
  {
    DATE: "1988-10-07",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1988-10-14",
    MORTGAGE30US: 10.33,
  },
  {
    DATE: "1988-10-21",
    MORTGAGE30US: 10.28,
  },
  {
    DATE: "1988-10-28",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1988-11-04",
    MORTGAGE30US: 10.12,
  },
  {
    DATE: "1988-11-11",
    MORTGAGE30US: 10.24,
  },
  {
    DATE: "1988-11-18",
    MORTGAGE30US: 10.31,
  },
  {
    DATE: "1988-11-25",
    MORTGAGE30US: 10.39,
  },
  {
    DATE: "1988-12-02",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1988-12-09",
    MORTGAGE30US: 10.46,
  },
  {
    DATE: "1988-12-16",
    MORTGAGE30US: 10.71,
  },
  {
    DATE: "1988-12-23",
    MORTGAGE30US: 10.68,
  },
  {
    DATE: "1988-12-30",
    MORTGAGE30US: 10.77,
  },
  {
    DATE: "1989-01-06",
    MORTGAGE30US: 10.8,
  },
  {
    DATE: "1989-01-13",
    MORTGAGE30US: 10.81,
  },
  {
    DATE: "1989-01-20",
    MORTGAGE30US: 10.71,
  },
  {
    DATE: "1989-01-27",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1989-02-03",
    MORTGAGE30US: 10.55,
  },
  {
    DATE: "1989-02-10",
    MORTGAGE30US: 10.56,
  },
  {
    DATE: "1989-02-17",
    MORTGAGE30US: 10.69,
  },
  {
    DATE: "1989-02-24",
    MORTGAGE30US: 10.78,
  },
  {
    DATE: "1989-03-03",
    MORTGAGE30US: 10.91,
  },
  {
    DATE: "1989-03-10",
    MORTGAGE30US: 10.86,
  },
  {
    DATE: "1989-03-17",
    MORTGAGE30US: 10.98,
  },
  {
    DATE: "1989-03-24",
    MORTGAGE30US: 11.22,
  },
  {
    DATE: "1989-03-31",
    MORTGAGE30US: 11.19,
  },
  {
    DATE: "1989-04-07",
    MORTGAGE30US: 11.07,
  },
  {
    DATE: "1989-04-14",
    MORTGAGE30US: 11.11,
  },
  {
    DATE: "1989-04-21",
    MORTGAGE30US: 10.99,
  },
  {
    DATE: "1989-04-28",
    MORTGAGE30US: 11.03,
  },
  {
    DATE: "1989-05-05",
    MORTGAGE30US: 10.97,
  },
  {
    DATE: "1989-05-12",
    MORTGAGE30US: 10.93,
  },
  {
    DATE: "1989-05-19",
    MORTGAGE30US: 10.69,
  },
  {
    DATE: "1989-05-26",
    MORTGAGE30US: 10.5,
  },
  {
    DATE: "1989-06-02",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1989-06-09",
    MORTGAGE30US: 10.2,
  },
  {
    DATE: "1989-06-16",
    MORTGAGE30US: 10.04,
  },
  {
    DATE: "1989-06-23",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1989-06-30",
    MORTGAGE30US: 10.07,
  },
  {
    DATE: "1989-07-07",
    MORTGAGE30US: 10.03,
  },
  {
    DATE: "1989-07-14",
    MORTGAGE30US: 9.82,
  },
  {
    DATE: "1989-07-21",
    MORTGAGE30US: 9.87,
  },
  {
    DATE: "1989-07-28",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1989-08-04",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1989-08-11",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1989-08-18",
    MORTGAGE30US: 10.09,
  },
  {
    DATE: "1989-08-25",
    MORTGAGE30US: 10.21,
  },
  {
    DATE: "1989-09-01",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1989-09-08",
    MORTGAGE30US: 10.17,
  },
  {
    DATE: "1989-09-15",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1989-09-22",
    MORTGAGE30US: 10.03,
  },
  {
    DATE: "1989-09-29",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1989-10-06",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1989-10-13",
    MORTGAGE30US: 9.95,
  },
  {
    DATE: "1989-10-20",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1989-10-27",
    MORTGAGE30US: 9.82,
  },
  {
    DATE: "1989-11-03",
    MORTGAGE30US: 9.82,
  },
  {
    DATE: "1989-11-10",
    MORTGAGE30US: 9.79,
  },
  {
    DATE: "1989-11-17",
    MORTGAGE30US: 9.72,
  },
  {
    DATE: "1989-11-24",
    MORTGAGE30US: 9.74,
  },
  {
    DATE: "1989-12-01",
    MORTGAGE30US: 9.74,
  },
  {
    DATE: "1989-12-08",
    MORTGAGE30US: 9.76,
  },
  {
    DATE: "1989-12-15",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1989-12-22",
    MORTGAGE30US: 9.69,
  },
  {
    DATE: "1989-12-29",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1990-01-05",
    MORTGAGE30US: 9.83,
  },
  {
    DATE: "1990-01-12",
    MORTGAGE30US: 9.8,
  },
  {
    DATE: "1990-01-19",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1990-01-26",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1990-02-02",
    MORTGAGE30US: 10.17,
  },
  {
    DATE: "1990-02-09",
    MORTGAGE30US: 10.21,
  },
  {
    DATE: "1990-02-16",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1990-02-23",
    MORTGAGE30US: 10.31,
  },
  {
    DATE: "1990-03-02",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1990-03-09",
    MORTGAGE30US: 10.29,
  },
  {
    DATE: "1990-03-16",
    MORTGAGE30US: 10.34,
  },
  {
    DATE: "1990-03-23",
    MORTGAGE30US: 10.26,
  },
  {
    DATE: "1990-03-30",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1990-04-06",
    MORTGAGE30US: 10.26,
  },
  {
    DATE: "1990-04-13",
    MORTGAGE30US: 10.25,
  },
  {
    DATE: "1990-04-20",
    MORTGAGE30US: 10.41,
  },
  {
    DATE: "1990-04-27",
    MORTGAGE30US: 10.56,
  },
  {
    DATE: "1990-05-04",
    MORTGAGE30US: 10.67,
  },
  {
    DATE: "1990-05-11",
    MORTGAGE30US: 10.54,
  },
  {
    DATE: "1990-05-18",
    MORTGAGE30US: 10.37,
  },
  {
    DATE: "1990-05-25",
    MORTGAGE30US: 10.33,
  },
  {
    DATE: "1990-06-01",
    MORTGAGE30US: 10.29,
  },
  {
    DATE: "1990-06-08",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1990-06-15",
    MORTGAGE30US: 10.12,
  },
  {
    DATE: "1990-06-22",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1990-06-29",
    MORTGAGE30US: 10.15,
  },
  {
    DATE: "1990-07-06",
    MORTGAGE30US: 10.06,
  },
  {
    DATE: "1990-07-13",
    MORTGAGE30US: 10.11,
  },
  {
    DATE: "1990-07-20",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1990-07-27",
    MORTGAGE30US: 9.98,
  },
  {
    DATE: "1990-08-03",
    MORTGAGE30US: 9.84,
  },
  {
    DATE: "1990-08-10",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1990-08-17",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1990-08-24",
    MORTGAGE30US: 10.29,
  },
  {
    DATE: "1990-08-31",
    MORTGAGE30US: 10.24,
  },
  {
    DATE: "1990-09-07",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1990-09-14",
    MORTGAGE30US: 10.13,
  },
  {
    DATE: "1990-09-21",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1990-09-28",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1990-10-05",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1990-10-12",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1990-10-19",
    MORTGAGE30US: 10.24,
  },
  {
    DATE: "1990-10-26",
    MORTGAGE30US: 10.17,
  },
  {
    DATE: "1990-11-02",
    MORTGAGE30US: 10.13,
  },
  {
    DATE: "1990-11-09",
    MORTGAGE30US: 10.09,
  },
  {
    DATE: "1990-11-16",
    MORTGAGE30US: 10.02,
  },
  {
    DATE: "1990-11-23",
    MORTGAGE30US: 9.93,
  },
  {
    DATE: "1990-11-30",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1990-12-07",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1990-12-14",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1990-12-21",
    MORTGAGE30US: 9.64,
  },
  {
    DATE: "1990-12-28",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1991-01-04",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1991-01-11",
    MORTGAGE30US: 9.63,
  },
  {
    DATE: "1991-01-18",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1991-01-25",
    MORTGAGE30US: 9.61,
  },
  {
    DATE: "1991-02-01",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1991-02-08",
    MORTGAGE30US: 9.36,
  },
  {
    DATE: "1991-02-15",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1991-02-22",
    MORTGAGE30US: 9.29,
  },
  {
    DATE: "1991-03-01",
    MORTGAGE30US: 9.4,
  },
  {
    DATE: "1991-03-08",
    MORTGAGE30US: 9.49,
  },
  {
    DATE: "1991-03-15",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1991-03-22",
    MORTGAGE30US: 9.59,
  },
  {
    DATE: "1991-03-29",
    MORTGAGE30US: 9.52,
  },
  {
    DATE: "1991-04-05",
    MORTGAGE30US: 9.49,
  },
  {
    DATE: "1991-04-12",
    MORTGAGE30US: 9.48,
  },
  {
    DATE: "1991-04-19",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-04-26",
    MORTGAGE30US: 9.53,
  },
  {
    DATE: "1991-05-03",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-05-10",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-05-17",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1991-05-24",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-05-31",
    MORTGAGE30US: 9.45,
  },
  {
    DATE: "1991-06-07",
    MORTGAGE30US: 9.48,
  },
  {
    DATE: "1991-06-14",
    MORTGAGE30US: 9.66,
  },
  {
    DATE: "1991-06-21",
    MORTGAGE30US: 9.65,
  },
  {
    DATE: "1991-06-28",
    MORTGAGE30US: 9.67,
  },
  {
    DATE: "1991-07-05",
    MORTGAGE30US: 9.62,
  },
  {
    DATE: "1991-07-12",
    MORTGAGE30US: 9.64,
  },
  {
    DATE: "1991-07-19",
    MORTGAGE30US: 9.54,
  },
  {
    DATE: "1991-07-26",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1991-08-02",
    MORTGAGE30US: 9.44,
  },
  {
    DATE: "1991-08-09",
    MORTGAGE30US: 9.27,
  },
  {
    DATE: "1991-08-16",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1991-08-23",
    MORTGAGE30US: 9.17,
  },
  {
    DATE: "1991-08-30",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1991-09-06",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1991-09-13",
    MORTGAGE30US: 9.02,
  },
  {
    DATE: "1991-09-20",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1991-09-27",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1991-10-04",
    MORTGAGE30US: 8.87,
  },
  {
    DATE: "1991-10-11",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1991-10-18",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1991-10-25",
    MORTGAGE30US: 8.91,
  },
  {
    DATE: "1991-11-01",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1991-11-08",
    MORTGAGE30US: 8.76,
  },
  {
    DATE: "1991-11-15",
    MORTGAGE30US: 8.69,
  },
  {
    DATE: "1991-11-22",
    MORTGAGE30US: 8.63,
  },
  {
    DATE: "1991-11-29",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1991-12-06",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "1991-12-13",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1991-12-20",
    MORTGAGE30US: 8.49,
  },
  {
    DATE: "1991-12-27",
    MORTGAGE30US: 8.35,
  },
  {
    DATE: "1992-01-03",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "1992-01-10",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1992-01-17",
    MORTGAGE30US: 8.45,
  },
  {
    DATE: "1992-01-24",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1992-01-31",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1992-02-07",
    MORTGAGE30US: 8.67,
  },
  {
    DATE: "1992-02-14",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1992-02-21",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1992-02-28",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1992-03-06",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1992-03-13",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1992-03-20",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1992-03-27",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1992-04-03",
    MORTGAGE30US: 8.96,
  },
  {
    DATE: "1992-04-10",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1992-04-17",
    MORTGAGE30US: 8.76,
  },
  {
    DATE: "1992-04-24",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1992-05-01",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1992-05-08",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1992-05-15",
    MORTGAGE30US: 8.64,
  },
  {
    DATE: "1992-05-22",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1992-05-29",
    MORTGAGE30US: 8.6,
  },
  {
    DATE: "1992-06-05",
    MORTGAGE30US: 8.59,
  },
  {
    DATE: "1992-06-12",
    MORTGAGE30US: 8.54,
  },
  {
    DATE: "1992-06-19",
    MORTGAGE30US: 8.48,
  },
  {
    DATE: "1992-06-26",
    MORTGAGE30US: 8.43,
  },
  {
    DATE: "1992-07-03",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1992-07-10",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "1992-07-17",
    MORTGAGE30US: 8.09,
  },
  {
    DATE: "1992-07-24",
    MORTGAGE30US: 8.08,
  },
  {
    DATE: "1992-07-31",
    MORTGAGE30US: 8.05,
  },
  {
    DATE: "1992-08-07",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "1992-08-14",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "1992-08-21",
    MORTGAGE30US: 7.87,
  },
  {
    DATE: "1992-08-28",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1992-09-04",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1992-09-11",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1992-09-18",
    MORTGAGE30US: 7.89,
  },
  {
    DATE: "1992-09-24",
    MORTGAGE30US: 8.02,
  },
  {
    DATE: "1992-10-02",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1992-10-09",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1992-10-16",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "1992-10-23",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1992-10-30",
    MORTGAGE30US: 8.21,
  },
  {
    DATE: "1992-11-06",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1992-11-13",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "1992-11-20",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "1992-11-27",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1992-12-04",
    MORTGAGE30US: 8.34,
  },
  {
    DATE: "1992-12-11",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1992-12-18",
    MORTGAGE30US: 8.19,
  },
  {
    DATE: "1992-12-25",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "1992-12-31",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "1993-01-08",
    MORTGAGE30US: 8.07,
  },
  {
    DATE: "1993-01-15",
    MORTGAGE30US: 8.04,
  },
  {
    DATE: "1993-01-22",
    MORTGAGE30US: 8,
  },
  {
    DATE: "1993-01-29",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1993-02-05",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1993-02-12",
    MORTGAGE30US: 7.75,
  },
  {
    DATE: "1993-02-19",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1993-02-26",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1993-03-05",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1993-03-12",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1993-03-19",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1993-03-26",
    MORTGAGE30US: 7.5,
  },
  {
    DATE: "1993-04-02",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1993-04-09",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1993-04-16",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1993-04-23",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1993-04-30",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1993-05-07",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1993-05-14",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1993-05-21",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1993-05-28",
    MORTGAGE30US: 7.5,
  },
  {
    DATE: "1993-06-04",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1993-06-11",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1993-06-18",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1993-06-25",
    MORTGAGE30US: 7.34,
  },
  {
    DATE: "1993-07-02",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1993-07-09",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "1993-07-16",
    MORTGAGE30US: 7.16,
  },
  {
    DATE: "1993-07-23",
    MORTGAGE30US: 7.2,
  },
  {
    DATE: "1993-07-30",
    MORTGAGE30US: 7.25,
  },
  {
    DATE: "1993-08-06",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "1993-08-13",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1993-08-20",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "1993-08-27",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1993-09-03",
    MORTGAGE30US: 6.93,
  },
  {
    DATE: "1993-09-10",
    MORTGAGE30US: 6.82,
  },
  {
    DATE: "1993-09-17",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "1993-09-24",
    MORTGAGE30US: 6.95,
  },
  {
    DATE: "1993-10-01",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1993-10-08",
    MORTGAGE30US: 6.87,
  },
  {
    DATE: "1993-10-15",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "1993-10-22",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "1993-10-29",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "1993-11-05",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1993-11-12",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "1993-11-19",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1993-11-26",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1993-12-04",
    MORTGAGE30US: 7.25,
  },
  {
    DATE: "1993-12-10",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "1993-12-17",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1993-12-24",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1993-12-31",
    MORTGAGE30US: 7.13,
  },
  {
    DATE: "1994-01-07",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1994-01-14",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1994-01-21",
    MORTGAGE30US: 7.05,
  },
  {
    DATE: "1994-01-28",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1994-02-04",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1994-02-11",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "1994-02-18",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1994-02-25",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1994-03-04",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1994-03-11",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1994-03-18",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1994-03-25",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1994-04-01",
    MORTGAGE30US: 8.04,
  },
  {
    DATE: "1994-04-08",
    MORTGAGE30US: 8.47,
  },
  {
    DATE: "1994-04-15",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "1994-04-22",
    MORTGAGE30US: 8.49,
  },
  {
    DATE: "1994-04-29",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "1994-05-06",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1994-05-13",
    MORTGAGE30US: 8.77,
  },
  {
    DATE: "1994-05-20",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1994-05-27",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1994-06-03",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1994-06-10",
    MORTGAGE30US: 8.25,
  },
  {
    DATE: "1994-06-17",
    MORTGAGE30US: 8.33,
  },
  {
    DATE: "1994-06-24",
    MORTGAGE30US: 8.46,
  },
  {
    DATE: "1994-07-01",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1994-07-08",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1994-07-15",
    MORTGAGE30US: 8.72,
  },
  {
    DATE: "1994-07-22",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1994-07-29",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1994-08-05",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "1994-08-12",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1994-08-19",
    MORTGAGE30US: 8.54,
  },
  {
    DATE: "1994-08-26",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1994-09-02",
    MORTGAGE30US: 8.48,
  },
  {
    DATE: "1994-09-09",
    MORTGAGE30US: 8.51,
  },
  {
    DATE: "1994-09-16",
    MORTGAGE30US: 8.66,
  },
  {
    DATE: "1994-09-23",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1994-09-30",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1994-10-07",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1994-10-14",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1994-10-21",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1994-10-28",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1994-11-04",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1994-11-11",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1994-11-18",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1994-11-25",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1994-12-02",
    MORTGAGE30US: 9.23,
  },
  {
    DATE: "1994-12-09",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1994-12-16",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1994-12-23",
    MORTGAGE30US: 9.18,
  },
  {
    DATE: "1994-12-30",
    MORTGAGE30US: 9.18,
  },
  {
    DATE: "1995-01-06",
    MORTGAGE30US: 9.22,
  },
  {
    DATE: "1995-01-13",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1995-01-20",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1995-01-27",
    MORTGAGE30US: 9.13,
  },
  {
    DATE: "1995-02-03",
    MORTGAGE30US: 8.94,
  },
  {
    DATE: "1995-02-10",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1995-02-17",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1995-02-24",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1995-03-03",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1995-03-10",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "1995-03-17",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "1995-03-24",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1995-03-31",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "1995-04-07",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1995-04-14",
    MORTGAGE30US: 8.37,
  },
  {
    DATE: "1995-04-21",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "1995-04-28",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "1995-05-05",
    MORTGAGE30US: 8.27,
  },
  {
    DATE: "1995-05-12",
    MORTGAGE30US: 7.87,
  },
  {
    DATE: "1995-05-19",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "1995-05-26",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1995-06-02",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1995-06-09",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1995-06-16",
    MORTGAGE30US: 7.55,
  },
  {
    DATE: "1995-06-23",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1995-06-30",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1995-07-07",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1995-07-14",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1995-07-21",
    MORTGAGE30US: 7.6,
  },
  {
    DATE: "1995-07-28",
    MORTGAGE30US: 7.79,
  },
  {
    DATE: "1995-08-04",
    MORTGAGE30US: 7.82,
  },
  {
    DATE: "1995-08-11",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1995-08-18",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1995-08-25",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1995-09-01",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1995-09-08",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1995-09-15",
    MORTGAGE30US: 7.6,
  },
  {
    DATE: "1995-09-22",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1995-09-29",
    MORTGAGE30US: 7.62,
  },
  {
    DATE: "1995-10-06",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1995-10-13",
    MORTGAGE30US: 7.5,
  },
  {
    DATE: "1995-10-20",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1995-10-27",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1995-11-03",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1995-11-09",
    MORTGAGE30US: 7.37,
  },
  {
    DATE: "1995-11-17",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1995-11-24",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1995-12-01",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1995-12-08",
    MORTGAGE30US: 7.18,
  },
  {
    DATE: "1995-12-15",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1995-12-22",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1995-12-29",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1996-01-05",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-01-12",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1996-01-19",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-01-26",
    MORTGAGE30US: 7,
  },
  {
    DATE: "1996-02-02",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-02-09",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-02-16",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1996-02-23",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1996-03-01",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1996-03-08",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1996-03-15",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "1996-03-22",
    MORTGAGE30US: 7.81,
  },
  {
    DATE: "1996-03-29",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1996-04-05",
    MORTGAGE30US: 7.78,
  },
  {
    DATE: "1996-04-12",
    MORTGAGE30US: 8.05,
  },
  {
    DATE: "1996-04-19",
    MORTGAGE30US: 7.95,
  },
  {
    DATE: "1996-04-26",
    MORTGAGE30US: 7.92,
  },
  {
    DATE: "1996-05-03",
    MORTGAGE30US: 7.99,
  },
  {
    DATE: "1996-05-10",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "1996-05-17",
    MORTGAGE30US: 8.08,
  },
  {
    DATE: "1996-05-24",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1996-05-31",
    MORTGAGE30US: 8.03,
  },
  {
    DATE: "1996-06-07",
    MORTGAGE30US: 8.3,
  },
  {
    DATE: "1996-06-14",
    MORTGAGE30US: 8.39,
  },
  {
    DATE: "1996-06-21",
    MORTGAGE30US: 8.3,
  },
  {
    DATE: "1996-06-28",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1996-07-05",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "1996-07-12",
    MORTGAGE30US: 8.42,
  },
  {
    DATE: "1996-07-19",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1996-07-26",
    MORTGAGE30US: 8.19,
  },
  {
    DATE: "1996-08-02",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1996-08-09",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1996-08-16",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1996-08-23",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1996-08-30",
    MORTGAGE30US: 8.09,
  },
  {
    DATE: "1996-09-06",
    MORTGAGE30US: 8.34,
  },
  {
    DATE: "1996-09-13",
    MORTGAGE30US: 8.28,
  },
  {
    DATE: "1996-09-20",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "1996-09-27",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "1996-10-04",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "1996-10-11",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1996-10-18",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1996-10-25",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1996-11-01",
    MORTGAGE30US: 7.78,
  },
  {
    DATE: "1996-11-08",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1996-11-15",
    MORTGAGE30US: 7.59,
  },
  {
    DATE: "1996-11-22",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1996-11-29",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1996-12-06",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1996-12-13",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1996-12-20",
    MORTGAGE30US: 7.74,
  },
  {
    DATE: "1996-12-27",
    MORTGAGE30US: 7.64,
  },
  {
    DATE: "1997-01-03",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1997-01-10",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1997-01-17",
    MORTGAGE30US: 7.87,
  },
  {
    DATE: "1997-01-24",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1997-01-31",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1997-02-07",
    MORTGAGE30US: 7.74,
  },
  {
    DATE: "1997-02-14",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1997-02-21",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1997-02-28",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1997-03-07",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1997-03-14",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1997-03-21",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1997-03-28",
    MORTGAGE30US: 7.97,
  },
  {
    DATE: "1997-04-04",
    MORTGAGE30US: 8.18,
  },
  {
    DATE: "1997-04-11",
    MORTGAGE30US: 8.15,
  },
  {
    DATE: "1997-04-18",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "1997-04-25",
    MORTGAGE30US: 8.08,
  },
  {
    DATE: "1997-05-02",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1997-05-09",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1997-05-16",
    MORTGAGE30US: 7.91,
  },
  {
    DATE: "1997-05-23",
    MORTGAGE30US: 7.92,
  },
  {
    DATE: "1997-05-30",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1997-06-06",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1997-06-13",
    MORTGAGE30US: 7.72,
  },
  {
    DATE: "1997-06-20",
    MORTGAGE30US: 7.61,
  },
  {
    DATE: "1997-06-27",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1997-07-04",
    MORTGAGE30US: 7.62,
  },
  {
    DATE: "1997-07-11",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1997-07-18",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1997-07-25",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1997-08-01",
    MORTGAGE30US: 7.36,
  },
  {
    DATE: "1997-08-08",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1997-08-15",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1997-08-22",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1997-08-29",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1997-09-05",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1997-09-12",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1997-09-19",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1997-09-26",
    MORTGAGE30US: 7.28,
  },
  {
    DATE: "1997-10-03",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1997-10-10",
    MORTGAGE30US: 7.26,
  },
  {
    DATE: "1997-10-17",
    MORTGAGE30US: 7.34,
  },
  {
    DATE: "1997-10-24",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1997-10-31",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "1997-11-07",
    MORTGAGE30US: 7.24,
  },
  {
    DATE: "1997-11-14",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1997-11-21",
    MORTGAGE30US: 7.18,
  },
  {
    DATE: "1997-11-28",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1997-12-05",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1997-12-12",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1997-12-19",
    MORTGAGE30US: 7.07,
  },
  {
    DATE: "1997-12-26",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1998-01-02",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "1998-01-09",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-01-16",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1998-01-23",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1998-01-30",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "1998-02-06",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "1998-02-13",
    MORTGAGE30US: 7.06,
  },
  {
    DATE: "1998-02-20",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1998-02-28",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "1998-03-06",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "1998-03-13",
    MORTGAGE30US: 7.16,
  },
  {
    DATE: "1998-03-20",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1998-03-27",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1998-04-03",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1998-04-10",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "1998-04-17",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1998-04-24",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1998-05-01",
    MORTGAGE30US: 7.22,
  },
  {
    DATE: "1998-05-08",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "1998-05-15",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "1998-05-22",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "1998-05-29",
    MORTGAGE30US: 7.07,
  },
  {
    DATE: "1998-06-05",
    MORTGAGE30US: 7.05,
  },
  {
    DATE: "1998-06-12",
    MORTGAGE30US: 7.04,
  },
  {
    DATE: "1998-06-19",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-06-26",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "1998-07-03",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "1998-07-10",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "1998-07-17",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-07-24",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "1998-07-31",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1998-08-07",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-08-14",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "1998-08-21",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "1998-08-28",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "1998-09-04",
    MORTGAGE30US: 6.82,
  },
  {
    DATE: "1998-09-11",
    MORTGAGE30US: 6.77,
  },
  {
    DATE: "1998-09-18",
    MORTGAGE30US: 6.66,
  },
  {
    DATE: "1998-09-25",
    MORTGAGE30US: 6.64,
  },
  {
    DATE: "1998-10-02",
    MORTGAGE30US: 6.6,
  },
  {
    DATE: "1998-10-09",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "1998-10-16",
    MORTGAGE30US: 6.9,
  },
  {
    DATE: "1998-10-23",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "1998-10-30",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "1998-11-06",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1998-11-13",
    MORTGAGE30US: 6.93,
  },
  {
    DATE: "1998-11-20",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "1998-11-27",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "1998-12-04",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "1998-12-11",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "1998-12-18",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "1998-12-25",
    MORTGAGE30US: 6.77,
  },
  {
    DATE: "1998-12-31",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "1999-01-08",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "1999-01-15",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "1999-01-22",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "1999-01-29",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "1999-02-05",
    MORTGAGE30US: 6.75,
  },
  {
    DATE: "1999-02-12",
    MORTGAGE30US: 6.77,
  },
  {
    DATE: "1999-02-19",
    MORTGAGE30US: 6.82,
  },
  {
    DATE: "1999-02-26",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1999-03-05",
    MORTGAGE30US: 7.06,
  },
  {
    DATE: "1999-03-12",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1999-03-19",
    MORTGAGE30US: 7.01,
  },
  {
    DATE: "1999-03-26",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "1999-04-02",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "1999-04-09",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "1999-04-16",
    MORTGAGE30US: 6.87,
  },
  {
    DATE: "1999-04-23",
    MORTGAGE30US: 6.88,
  },
  {
    DATE: "1999-04-30",
    MORTGAGE30US: 6.93,
  },
  {
    DATE: "1999-05-07",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1999-05-14",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "1999-05-21",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1999-05-28",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1999-06-04",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1999-06-11",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1999-06-18",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1999-06-25",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1999-07-02",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1999-07-09",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1999-07-16",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1999-07-23",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1999-07-30",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1999-08-06",
    MORTGAGE30US: 7.89,
  },
  {
    DATE: "1999-08-13",
    MORTGAGE30US: 8.15,
  },
  {
    DATE: "1999-08-20",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1999-08-27",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1999-09-03",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "1999-09-10",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1999-09-17",
    MORTGAGE30US: 7.82,
  },
  {
    DATE: "1999-09-24",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1999-10-01",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1999-10-08",
    MORTGAGE30US: 7.82,
  },
  {
    DATE: "1999-10-15",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1999-10-22",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1999-10-29",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "1999-11-05",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1999-11-12",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1999-11-19",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1999-11-26",
    MORTGAGE30US: 7.75,
  },
  {
    DATE: "1999-12-03",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1999-12-10",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1999-12-17",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1999-12-24",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "1999-12-31",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "2000-01-07",
    MORTGAGE30US: 8.15,
  },
  {
    DATE: "2000-01-14",
    MORTGAGE30US: 8.18,
  },
  {
    DATE: "2000-01-21",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "2000-01-28",
    MORTGAGE30US: 8.25,
  },
  {
    DATE: "2000-02-04",
    MORTGAGE30US: 8.25,
  },
  {
    DATE: "2000-02-11",
    MORTGAGE30US: 8.36,
  },
  {
    DATE: "2000-02-18",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "2000-02-25",
    MORTGAGE30US: 8.31,
  },
  {
    DATE: "2000-03-03",
    MORTGAGE30US: 8.27,
  },
  {
    DATE: "2000-03-10",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "2000-03-17",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "2000-03-24",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "2000-03-31",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "2000-04-07",
    MORTGAGE30US: 8.2,
  },
  {
    DATE: "2000-04-14",
    MORTGAGE30US: 8.12,
  },
  {
    DATE: "2000-04-21",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "2000-04-28",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "2000-05-05",
    MORTGAGE30US: 8.28,
  },
  {
    DATE: "2000-05-12",
    MORTGAGE30US: 8.52,
  },
  {
    DATE: "2000-05-19",
    MORTGAGE30US: 8.64,
  },
  {
    DATE: "2000-05-26",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "2000-06-02",
    MORTGAGE30US: 8.54,
  },
  {
    DATE: "2000-06-09",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "2000-06-16",
    MORTGAGE30US: 8.22,
  },
  {
    DATE: "2000-06-23",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "2000-06-30",
    MORTGAGE30US: 8.22,
  },
  {
    DATE: "2000-07-07",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "2000-07-14",
    MORTGAGE30US: 8.09,
  },
  {
    DATE: "2000-07-21",
    MORTGAGE30US: 8.21,
  },
  {
    DATE: "2000-07-28",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "2000-08-04",
    MORTGAGE30US: 8.12,
  },
  {
    DATE: "2000-08-11",
    MORTGAGE30US: 8.04,
  },
  {
    DATE: "2000-08-18",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "2000-08-25",
    MORTGAGE30US: 7.99,
  },
  {
    DATE: "2000-09-01",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "2000-09-08",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "2000-09-15",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "2000-09-22",
    MORTGAGE30US: 7.9,
  },
  {
    DATE: "2000-09-29",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "2000-10-06",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "2000-10-13",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "2000-10-20",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "2000-10-27",
    MORTGAGE30US: 7.68,
  },
  {
    DATE: "2000-11-03",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "2000-11-10",
    MORTGAGE30US: 7.79,
  },
  {
    DATE: "2000-11-17",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "2000-11-24",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "2000-12-01",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "2000-12-08",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "2000-12-15",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "2000-12-22",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "2000-12-29",
    MORTGAGE30US: 7.13,
  },
  {
    DATE: "2001-01-05",
    MORTGAGE30US: 7.07,
  },
  {
    DATE: "2001-01-12",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2001-01-19",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "2001-01-26",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "2001-02-02",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "2001-02-09",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "2001-02-16",
    MORTGAGE30US: 7.01,
  },
  {
    DATE: "2001-02-23",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "2001-03-02",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "2001-03-09",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "2001-03-16",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2001-03-23",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2001-03-30",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "2001-04-06",
    MORTGAGE30US: 7.01,
  },
  {
    DATE: "2001-04-13",
    MORTGAGE30US: 7.04,
  },
  {
    DATE: "2001-04-20",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-04-27",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "2001-05-04",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-05-11",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "2001-05-18",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-05-25",
    MORTGAGE30US: 7.2,
  },
  {
    DATE: "2001-06-01",
    MORTGAGE30US: 7.24,
  },
  {
    DATE: "2001-06-08",
    MORTGAGE30US: 7.2,
  },
  {
    DATE: "2001-06-15",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-06-22",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "2001-06-29",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "2001-07-06",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "2001-07-13",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "2001-07-20",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2001-07-27",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "2001-08-03",
    MORTGAGE30US: 7,
  },
  {
    DATE: "2001-08-10",
    MORTGAGE30US: 7,
  },
  {
    DATE: "2001-08-17",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "2001-08-24",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "2001-08-31",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "2001-09-07",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2001-09-14",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "2001-09-21",
    MORTGAGE30US: 6.8,
  },
  {
    DATE: "2001-09-28",
    MORTGAGE30US: 6.72,
  },
  {
    DATE: "2001-10-05",
    MORTGAGE30US: 6.64,
  },
  {
    DATE: "2001-10-12",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2001-10-19",
    MORTGAGE30US: 6.61,
  },
  {
    DATE: "2001-10-26",
    MORTGAGE30US: 6.64,
  },
  {
    DATE: "2001-11-02",
    MORTGAGE30US: 6.56,
  },
  {
    DATE: "2001-11-09",
    MORTGAGE30US: 6.45,
  },
  {
    DATE: "2001-11-16",
    MORTGAGE30US: 6.51,
  },
  {
    DATE: "2001-11-23",
    MORTGAGE30US: 6.75,
  },
  {
    DATE: "2001-11-30",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "2001-12-07",
    MORTGAGE30US: 6.84,
  },
  {
    DATE: "2001-12-14",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "2001-12-21",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "2001-12-28",
    MORTGAGE30US: 7.16,
  },
  {
    DATE: "2002-01-04",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2002-01-11",
    MORTGAGE30US: 7.06,
  },
  {
    DATE: "2002-01-18",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "2002-01-25",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2002-02-01",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "2002-02-08",
    MORTGAGE30US: 6.88,
  },
  {
    DATE: "2002-02-15",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "2002-02-22",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2002-03-01",
    MORTGAGE30US: 6.8,
  },
  {
    DATE: "2002-03-08",
    MORTGAGE30US: 6.87,
  },
  {
    DATE: "2002-03-15",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2002-03-22",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2002-03-29",
    MORTGAGE30US: 7.18,
  },
  {
    DATE: "2002-04-05",
    MORTGAGE30US: 7.13,
  },
  {
    DATE: "2002-04-12",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "2002-04-19",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "2002-04-26",
    MORTGAGE30US: 6.88,
  },
  {
    DATE: "2002-05-03",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "2002-05-10",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "2002-05-17",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2002-05-24",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2002-05-31",
    MORTGAGE30US: 6.76,
  },
  {
    DATE: "2002-06-07",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2002-06-14",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2002-06-21",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2002-06-28",
    MORTGAGE30US: 6.55,
  },
  {
    DATE: "2002-07-05",
    MORTGAGE30US: 6.57,
  },
  {
    DATE: "2002-07-12",
    MORTGAGE30US: 6.54,
  },
  {
    DATE: "2002-07-19",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "2002-07-26",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2002-08-02",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2002-08-09",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2002-08-16",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2002-08-23",
    MORTGAGE30US: 6.27,
  },
  {
    DATE: "2002-08-30",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2002-09-06",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2002-09-13",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2002-09-20",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2002-09-27",
    MORTGAGE30US: 5.99,
  },
  {
    DATE: "2002-10-04",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2002-10-11",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2002-10-18",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2002-10-25",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2002-11-01",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2002-11-08",
    MORTGAGE30US: 6.11,
  },
  {
    DATE: "2002-11-15",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2002-11-22",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2002-11-29",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2002-12-06",
    MORTGAGE30US: 6.19,
  },
  {
    DATE: "2002-12-13",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2002-12-20",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2002-12-27",
    MORTGAGE30US: 5.93,
  },
  {
    DATE: "2003-01-03",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2003-01-10",
    MORTGAGE30US: 5.95,
  },
  {
    DATE: "2003-01-17",
    MORTGAGE30US: 5.97,
  },
  {
    DATE: "2003-01-24",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2003-01-31",
    MORTGAGE30US: 5.9,
  },
  {
    DATE: "2003-02-07",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2003-02-14",
    MORTGAGE30US: 5.86,
  },
  {
    DATE: "2003-02-21",
    MORTGAGE30US: 5.84,
  },
  {
    DATE: "2003-02-28",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-03-07",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2003-03-14",
    MORTGAGE30US: 5.61,
  },
  {
    DATE: "2003-03-21",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-03-28",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2003-04-04",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-04-11",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2003-04-18",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2003-04-25",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-05-02",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2003-05-09",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2003-05-16",
    MORTGAGE30US: 5.45,
  },
  {
    DATE: "2003-05-23",
    MORTGAGE30US: 5.34,
  },
  {
    DATE: "2003-05-30",
    MORTGAGE30US: 5.31,
  },
  {
    DATE: "2003-06-06",
    MORTGAGE30US: 5.26,
  },
  {
    DATE: "2003-06-13",
    MORTGAGE30US: 5.21,
  },
  {
    DATE: "2003-06-20",
    MORTGAGE30US: 5.21,
  },
  {
    DATE: "2003-06-27",
    MORTGAGE30US: 5.24,
  },
  {
    DATE: "2003-07-04",
    MORTGAGE30US: 5.4,
  },
  {
    DATE: "2003-07-11",
    MORTGAGE30US: 5.52,
  },
  {
    DATE: "2003-07-18",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2003-07-25",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2003-08-01",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2003-08-08",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2003-08-15",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2003-08-22",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2003-08-29",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2003-09-05",
    MORTGAGE30US: 6.44,
  },
  {
    DATE: "2003-09-12",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2003-09-19",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2003-09-26",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2003-10-03",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2003-10-10",
    MORTGAGE30US: 5.95,
  },
  {
    DATE: "2003-10-17",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2003-10-24",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2003-10-31",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2003-11-07",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2003-11-14",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2003-11-21",
    MORTGAGE30US: 5.83,
  },
  {
    DATE: "2003-11-28",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2003-12-05",
    MORTGAGE30US: 6.02,
  },
  {
    DATE: "2003-12-12",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2003-12-19",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2003-12-26",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2003-12-31",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2004-01-08",
    MORTGAGE30US: 5.87,
  },
  {
    DATE: "2004-01-15",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2004-01-22",
    MORTGAGE30US: 5.64,
  },
  {
    DATE: "2004-01-29",
    MORTGAGE30US: 5.68,
  },
  {
    DATE: "2004-02-05",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2004-02-12",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2004-02-19",
    MORTGAGE30US: 5.58,
  },
  {
    DATE: "2004-02-26",
    MORTGAGE30US: 5.58,
  },
  {
    DATE: "2004-03-04",
    MORTGAGE30US: 5.59,
  },
  {
    DATE: "2004-03-11",
    MORTGAGE30US: 5.41,
  },
  {
    DATE: "2004-03-18",
    MORTGAGE30US: 5.38,
  },
  {
    DATE: "2004-03-25",
    MORTGAGE30US: 5.4,
  },
  {
    DATE: "2004-04-01",
    MORTGAGE30US: 5.52,
  },
  {
    DATE: "2004-04-08",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2004-04-15",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2004-04-22",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2004-04-29",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2004-05-06",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2004-05-13",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2004-05-20",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2004-05-27",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2004-06-03",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2004-06-10",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2004-06-17",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2004-06-24",
    MORTGAGE30US: 6.25,
  },
  {
    DATE: "2004-07-01",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2004-07-08",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2004-07-15",
    MORTGAGE30US: 6,
  },
  {
    DATE: "2004-07-22",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2004-07-29",
    MORTGAGE30US: 6.08,
  },
  {
    DATE: "2004-08-05",
    MORTGAGE30US: 5.99,
  },
  {
    DATE: "2004-08-12",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2004-08-19",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2004-08-26",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2004-09-02",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2004-09-09",
    MORTGAGE30US: 5.83,
  },
  {
    DATE: "2004-09-16",
    MORTGAGE30US: 5.75,
  },
  {
    DATE: "2004-09-23",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2004-09-30",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2004-10-07",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2004-10-14",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2004-10-21",
    MORTGAGE30US: 5.69,
  },
  {
    DATE: "2004-10-28",
    MORTGAGE30US: 5.64,
  },
  {
    DATE: "2004-11-04",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2004-11-11",
    MORTGAGE30US: 5.76,
  },
  {
    DATE: "2004-11-18",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2004-11-24",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2004-12-02",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2004-12-09",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2004-12-16",
    MORTGAGE30US: 5.68,
  },
  {
    DATE: "2004-12-22",
    MORTGAGE30US: 5.75,
  },
  {
    DATE: "2004-12-29",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2005-01-06",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-01-13",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2005-01-20",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2005-01-27",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2005-02-03",
    MORTGAGE30US: 5.63,
  },
  {
    DATE: "2005-02-10",
    MORTGAGE30US: 5.57,
  },
  {
    DATE: "2005-02-17",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2005-02-24",
    MORTGAGE30US: 5.69,
  },
  {
    DATE: "2005-03-03",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2005-03-10",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2005-03-17",
    MORTGAGE30US: 5.95,
  },
  {
    DATE: "2005-03-24",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2005-03-31",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2005-04-07",
    MORTGAGE30US: 5.93,
  },
  {
    DATE: "2005-04-14",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2005-04-21",
    MORTGAGE30US: 5.8,
  },
  {
    DATE: "2005-04-28",
    MORTGAGE30US: 5.78,
  },
  {
    DATE: "2005-05-05",
    MORTGAGE30US: 5.75,
  },
  {
    DATE: "2005-05-12",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-05-19",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2005-05-26",
    MORTGAGE30US: 5.65,
  },
  {
    DATE: "2005-06-02",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2005-06-09",
    MORTGAGE30US: 5.56,
  },
  {
    DATE: "2005-06-16",
    MORTGAGE30US: 5.63,
  },
  {
    DATE: "2005-06-23",
    MORTGAGE30US: 5.57,
  },
  {
    DATE: "2005-06-30",
    MORTGAGE30US: 5.53,
  },
  {
    DATE: "2005-07-07",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2005-07-14",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2005-07-21",
    MORTGAGE30US: 5.73,
  },
  {
    DATE: "2005-07-28",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-08-04",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2005-08-11",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2005-08-18",
    MORTGAGE30US: 5.8,
  },
  {
    DATE: "2005-08-25",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-09-01",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2005-09-08",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2005-09-15",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2005-09-22",
    MORTGAGE30US: 5.8,
  },
  {
    DATE: "2005-09-29",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2005-10-06",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2005-10-13",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2005-10-20",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2005-10-27",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2005-11-03",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2005-11-10",
    MORTGAGE30US: 6.36,
  },
  {
    DATE: "2005-11-17",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2005-11-23",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2005-12-01",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2005-12-08",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2005-12-15",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2005-12-22",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2005-12-29",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2006-01-05",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2006-01-12",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2006-01-19",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2006-01-26",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2006-02-02",
    MORTGAGE30US: 6.23,
  },
  {
    DATE: "2006-02-09",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2006-02-16",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2006-02-23",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2006-03-02",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2006-03-09",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2006-03-16",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2006-03-23",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2006-03-30",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2006-04-06",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2006-04-13",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "2006-04-20",
    MORTGAGE30US: 6.53,
  },
  {
    DATE: "2006-04-27",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2006-05-04",
    MORTGAGE30US: 6.59,
  },
  {
    DATE: "2006-05-11",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2006-05-18",
    MORTGAGE30US: 6.6,
  },
  {
    DATE: "2006-05-25",
    MORTGAGE30US: 6.62,
  },
  {
    DATE: "2006-06-01",
    MORTGAGE30US: 6.67,
  },
  {
    DATE: "2006-06-08",
    MORTGAGE30US: 6.62,
  },
  {
    DATE: "2006-06-15",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2006-06-22",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2006-06-29",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "2006-07-06",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "2006-07-13",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "2006-07-20",
    MORTGAGE30US: 6.8,
  },
  {
    DATE: "2006-07-27",
    MORTGAGE30US: 6.72,
  },
  {
    DATE: "2006-08-03",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2006-08-10",
    MORTGAGE30US: 6.55,
  },
  {
    DATE: "2006-08-17",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2006-08-24",
    MORTGAGE30US: 6.48,
  },
  {
    DATE: "2006-08-31",
    MORTGAGE30US: 6.44,
  },
  {
    DATE: "2006-09-07",
    MORTGAGE30US: 6.47,
  },
  {
    DATE: "2006-09-14",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2006-09-21",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2006-09-28",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2006-10-05",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2006-10-12",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2006-10-19",
    MORTGAGE30US: 6.36,
  },
  {
    DATE: "2006-10-26",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2006-11-02",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2006-11-09",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2006-11-16",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2006-11-22",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2006-11-30",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2006-12-07",
    MORTGAGE30US: 6.11,
  },
  {
    DATE: "2006-12-14",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2006-12-21",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2006-12-28",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2007-01-04",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2007-01-11",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2007-01-18",
    MORTGAGE30US: 6.23,
  },
  {
    DATE: "2007-01-25",
    MORTGAGE30US: 6.25,
  },
  {
    DATE: "2007-02-01",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2007-02-08",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2007-02-15",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2007-02-22",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2007-03-01",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2007-03-08",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2007-03-15",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2007-03-22",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-03-29",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-04-05",
    MORTGAGE30US: 6.17,
  },
  {
    DATE: "2007-04-12",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2007-04-19",
    MORTGAGE30US: 6.17,
  },
  {
    DATE: "2007-04-26",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-05-03",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-05-10",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2007-05-17",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2007-05-24",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2007-05-31",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2007-06-07",
    MORTGAGE30US: 6.53,
  },
  {
    DATE: "2007-06-14",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "2007-06-21",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "2007-06-28",
    MORTGAGE30US: 6.67,
  },
  {
    DATE: "2007-07-05",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2007-07-12",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "2007-07-19",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "2007-07-26",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "2007-08-02",
    MORTGAGE30US: 6.68,
  },
  {
    DATE: "2007-08-09",
    MORTGAGE30US: 6.59,
  },
  {
    DATE: "2007-08-16",
    MORTGAGE30US: 6.62,
  },
  {
    DATE: "2007-08-23",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2007-08-30",
    MORTGAGE30US: 6.45,
  },
  {
    DATE: "2007-09-06",
    MORTGAGE30US: 6.46,
  },
  {
    DATE: "2007-09-13",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2007-09-20",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2007-09-27",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2007-10-04",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2007-10-11",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2007-10-18",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2007-10-25",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2007-11-01",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2007-11-08",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2007-11-15",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2007-11-21",
    MORTGAGE30US: 6.2,
  },
  {
    DATE: "2007-11-29",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2007-12-06",
    MORTGAGE30US: 5.96,
  },
  {
    DATE: "2007-12-13",
    MORTGAGE30US: 6.11,
  },
  {
    DATE: "2007-12-20",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2007-12-27",
    MORTGAGE30US: 6.17,
  },
  {
    DATE: "2008-01-03",
    MORTGAGE30US: 6.07,
  },
  {
    DATE: "2008-01-10",
    MORTGAGE30US: 5.87,
  },
  {
    DATE: "2008-01-17",
    MORTGAGE30US: 5.69,
  },
  {
    DATE: "2008-01-24",
    MORTGAGE30US: 5.48,
  },
  {
    DATE: "2008-01-31",
    MORTGAGE30US: 5.68,
  },
  {
    DATE: "2008-02-07",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2008-02-14",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2008-02-21",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2008-02-28",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2008-03-06",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2008-03-13",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2008-03-20",
    MORTGAGE30US: 5.87,
  },
  {
    DATE: "2008-03-27",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2008-04-03",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2008-04-10",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2008-04-17",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2008-04-24",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2008-05-01",
    MORTGAGE30US: 6.06,
  },
  {
    DATE: "2008-05-08",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2008-05-15",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2008-05-22",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2008-05-29",
    MORTGAGE30US: 6.08,
  },
  {
    DATE: "2008-06-05",
    MORTGAGE30US: 6.09,
  },
  {
    DATE: "2008-06-12",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2008-06-19",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2008-06-26",
    MORTGAGE30US: 6.45,
  },
  {
    DATE: "2008-07-03",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2008-07-10",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2008-07-17",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2008-07-24",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2008-07-31",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2008-08-07",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2008-08-14",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2008-08-21",
    MORTGAGE30US: 6.47,
  },
  {
    DATE: "2008-08-28",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2008-09-04",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2008-09-11",
    MORTGAGE30US: 5.93,
  },
  {
    DATE: "2008-09-18",
    MORTGAGE30US: 5.78,
  },
  {
    DATE: "2008-09-25",
    MORTGAGE30US: 6.09,
  },
  {
    DATE: "2008-10-02",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2008-10-09",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2008-10-16",
    MORTGAGE30US: 6.46,
  },
  {
    DATE: "2008-10-23",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2008-10-30",
    MORTGAGE30US: 6.46,
  },
  {
    DATE: "2008-11-06",
    MORTGAGE30US: 6.2,
  },
  {
    DATE: "2008-11-13",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2008-11-20",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2008-11-26",
    MORTGAGE30US: 5.97,
  },
  {
    DATE: "2008-12-04",
    MORTGAGE30US: 5.53,
  },
  {
    DATE: "2008-12-11",
    MORTGAGE30US: 5.47,
  },
  {
    DATE: "2008-12-18",
    MORTGAGE30US: 5.19,
  },
  {
    DATE: "2008-12-24",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2008-12-31",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2009-01-08",
    MORTGAGE30US: 5.01,
  },
  {
    DATE: "2009-01-15",
    MORTGAGE30US: 4.96,
  },
  {
    DATE: "2009-01-22",
    MORTGAGE30US: 5.12,
  },
  {
    DATE: "2009-01-29",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2009-02-05",
    MORTGAGE30US: 5.25,
  },
  {
    DATE: "2009-02-12",
    MORTGAGE30US: 5.16,
  },
  {
    DATE: "2009-02-19",
    MORTGAGE30US: 5.04,
  },
  {
    DATE: "2009-02-26",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2009-03-05",
    MORTGAGE30US: 5.15,
  },
  {
    DATE: "2009-03-12",
    MORTGAGE30US: 5.03,
  },
  {
    DATE: "2009-03-19",
    MORTGAGE30US: 4.98,
  },
  {
    DATE: "2009-03-26",
    MORTGAGE30US: 4.85,
  },
  {
    DATE: "2009-04-02",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2009-04-09",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2009-04-16",
    MORTGAGE30US: 4.82,
  },
  {
    DATE: "2009-04-23",
    MORTGAGE30US: 4.8,
  },
  {
    DATE: "2009-04-30",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2009-05-07",
    MORTGAGE30US: 4.84,
  },
  {
    DATE: "2009-05-14",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2009-05-21",
    MORTGAGE30US: 4.82,
  },
  {
    DATE: "2009-05-28",
    MORTGAGE30US: 4.91,
  },
  {
    DATE: "2009-06-04",
    MORTGAGE30US: 5.29,
  },
  {
    DATE: "2009-06-11",
    MORTGAGE30US: 5.59,
  },
  {
    DATE: "2009-06-18",
    MORTGAGE30US: 5.38,
  },
  {
    DATE: "2009-06-25",
    MORTGAGE30US: 5.42,
  },
  {
    DATE: "2009-07-02",
    MORTGAGE30US: 5.32,
  },
  {
    DATE: "2009-07-09",
    MORTGAGE30US: 5.2,
  },
  {
    DATE: "2009-07-16",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2009-07-23",
    MORTGAGE30US: 5.2,
  },
  {
    DATE: "2009-07-30",
    MORTGAGE30US: 5.25,
  },
  {
    DATE: "2009-08-06",
    MORTGAGE30US: 5.22,
  },
  {
    DATE: "2009-08-13",
    MORTGAGE30US: 5.29,
  },
  {
    DATE: "2009-08-20",
    MORTGAGE30US: 5.12,
  },
  {
    DATE: "2009-08-27",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2009-09-03",
    MORTGAGE30US: 5.08,
  },
  {
    DATE: "2009-09-10",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2009-09-17",
    MORTGAGE30US: 5.04,
  },
  {
    DATE: "2009-09-24",
    MORTGAGE30US: 5.04,
  },
  {
    DATE: "2009-10-01",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2009-10-08",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2009-10-15",
    MORTGAGE30US: 4.92,
  },
  {
    DATE: "2009-10-22",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2009-10-29",
    MORTGAGE30US: 5.03,
  },
  {
    DATE: "2009-11-05",
    MORTGAGE30US: 4.98,
  },
  {
    DATE: "2009-11-12",
    MORTGAGE30US: 4.91,
  },
  {
    DATE: "2009-11-19",
    MORTGAGE30US: 4.83,
  },
  {
    DATE: "2009-11-25",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2009-12-03",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2009-12-10",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2009-12-17",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2009-12-24",
    MORTGAGE30US: 5.05,
  },
  {
    DATE: "2009-12-31",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2010-01-07",
    MORTGAGE30US: 5.09,
  },
  {
    DATE: "2010-01-14",
    MORTGAGE30US: 5.06,
  },
  {
    DATE: "2010-01-21",
    MORTGAGE30US: 4.99,
  },
  {
    DATE: "2010-01-28",
    MORTGAGE30US: 4.98,
  },
  {
    DATE: "2010-02-04",
    MORTGAGE30US: 5.01,
  },
  {
    DATE: "2010-02-11",
    MORTGAGE30US: 4.97,
  },
  {
    DATE: "2010-02-18",
    MORTGAGE30US: 4.93,
  },
  {
    DATE: "2010-02-25",
    MORTGAGE30US: 5.05,
  },
  {
    DATE: "2010-03-04",
    MORTGAGE30US: 4.97,
  },
  {
    DATE: "2010-03-11",
    MORTGAGE30US: 4.95,
  },
  {
    DATE: "2010-03-18",
    MORTGAGE30US: 4.96,
  },
  {
    DATE: "2010-03-25",
    MORTGAGE30US: 4.99,
  },
  {
    DATE: "2010-04-01",
    MORTGAGE30US: 5.08,
  },
  {
    DATE: "2010-04-08",
    MORTGAGE30US: 5.21,
  },
  {
    DATE: "2010-04-15",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2010-04-22",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2010-04-29",
    MORTGAGE30US: 5.06,
  },
  {
    DATE: "2010-05-06",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2010-05-13",
    MORTGAGE30US: 4.93,
  },
  {
    DATE: "2010-05-20",
    MORTGAGE30US: 4.84,
  },
  {
    DATE: "2010-05-27",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2010-06-03",
    MORTGAGE30US: 4.79,
  },
  {
    DATE: "2010-06-10",
    MORTGAGE30US: 4.72,
  },
  {
    DATE: "2010-06-17",
    MORTGAGE30US: 4.75,
  },
  {
    DATE: "2010-06-24",
    MORTGAGE30US: 4.69,
  },
  {
    DATE: "2010-07-01",
    MORTGAGE30US: 4.58,
  },
  {
    DATE: "2010-07-08",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2010-07-15",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2010-07-22",
    MORTGAGE30US: 4.56,
  },
  {
    DATE: "2010-07-29",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2010-08-05",
    MORTGAGE30US: 4.49,
  },
  {
    DATE: "2010-08-12",
    MORTGAGE30US: 4.44,
  },
  {
    DATE: "2010-08-19",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2010-08-26",
    MORTGAGE30US: 4.36,
  },
  {
    DATE: "2010-09-02",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2010-09-09",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2010-09-16",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2010-09-23",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2010-09-30",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2010-10-07",
    MORTGAGE30US: 4.27,
  },
  {
    DATE: "2010-10-14",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2010-10-21",
    MORTGAGE30US: 4.21,
  },
  {
    DATE: "2010-10-28",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2010-11-04",
    MORTGAGE30US: 4.24,
  },
  {
    DATE: "2010-11-11",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2010-11-18",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2010-11-24",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2010-12-02",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2010-12-09",
    MORTGAGE30US: 4.61,
  },
  {
    DATE: "2010-12-16",
    MORTGAGE30US: 4.83,
  },
  {
    DATE: "2010-12-23",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2010-12-30",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2011-01-06",
    MORTGAGE30US: 4.77,
  },
  {
    DATE: "2011-01-13",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2011-01-20",
    MORTGAGE30US: 4.74,
  },
  {
    DATE: "2011-01-27",
    MORTGAGE30US: 4.8,
  },
  {
    DATE: "2011-02-03",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2011-02-10",
    MORTGAGE30US: 5.05,
  },
  {
    DATE: "2011-02-17",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2011-02-24",
    MORTGAGE30US: 4.95,
  },
  {
    DATE: "2011-03-03",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2011-03-10",
    MORTGAGE30US: 4.88,
  },
  {
    DATE: "2011-03-17",
    MORTGAGE30US: 4.76,
  },
  {
    DATE: "2011-03-24",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2011-03-31",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2011-04-07",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2011-04-14",
    MORTGAGE30US: 4.91,
  },
  {
    DATE: "2011-04-21",
    MORTGAGE30US: 4.8,
  },
  {
    DATE: "2011-04-28",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2011-05-05",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2011-05-12",
    MORTGAGE30US: 4.63,
  },
  {
    DATE: "2011-05-19",
    MORTGAGE30US: 4.61,
  },
  {
    DATE: "2011-05-26",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2011-06-02",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2011-06-09",
    MORTGAGE30US: 4.49,
  },
  {
    DATE: "2011-06-16",
    MORTGAGE30US: 4.5,
  },
  {
    DATE: "2011-06-23",
    MORTGAGE30US: 4.5,
  },
  {
    DATE: "2011-06-30",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2011-07-07",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2011-07-14",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2011-07-21",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2011-07-28",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2011-08-04",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2011-08-11",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2011-08-18",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2011-08-25",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2011-09-01",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2011-09-08",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2011-09-15",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2011-09-22",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2011-09-29",
    MORTGAGE30US: 4.01,
  },
  {
    DATE: "2011-10-06",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2011-10-13",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2011-10-20",
    MORTGAGE30US: 4.11,
  },
  {
    DATE: "2011-10-27",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2011-11-03",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2011-11-10",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2011-11-17",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2011-11-23",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2011-12-01",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2011-12-08",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2011-12-15",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2011-12-22",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2011-12-29",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2012-01-05",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2012-01-12",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2012-01-19",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-01-26",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2012-02-02",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2012-02-09",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2012-02-16",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2012-02-23",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2012-03-01",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2012-03-08",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-03-15",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2012-03-22",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2012-03-29",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2012-04-05",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2012-04-12",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-04-19",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2012-04-26",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-05-03",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2012-05-10",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2012-05-17",
    MORTGAGE30US: 3.79,
  },
  {
    DATE: "2012-05-24",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2012-05-31",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2012-06-07",
    MORTGAGE30US: 3.67,
  },
  {
    DATE: "2012-06-14",
    MORTGAGE30US: 3.71,
  },
  {
    DATE: "2012-06-21",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2012-06-28",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2012-07-05",
    MORTGAGE30US: 3.62,
  },
  {
    DATE: "2012-07-12",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2012-07-19",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2012-07-26",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2012-08-02",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2012-08-09",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2012-08-16",
    MORTGAGE30US: 3.62,
  },
  {
    DATE: "2012-08-23",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2012-08-30",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2012-09-06",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2012-09-13",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2012-09-20",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2012-09-27",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2012-10-04",
    MORTGAGE30US: 3.36,
  },
  {
    DATE: "2012-10-11",
    MORTGAGE30US: 3.39,
  },
  {
    DATE: "2012-10-18",
    MORTGAGE30US: 3.37,
  },
  {
    DATE: "2012-10-25",
    MORTGAGE30US: 3.41,
  },
  {
    DATE: "2012-11-01",
    MORTGAGE30US: 3.39,
  },
  {
    DATE: "2012-11-08",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2012-11-15",
    MORTGAGE30US: 3.34,
  },
  {
    DATE: "2012-11-21",
    MORTGAGE30US: 3.31,
  },
  {
    DATE: "2012-11-29",
    MORTGAGE30US: 3.32,
  },
  {
    DATE: "2012-12-06",
    MORTGAGE30US: 3.34,
  },
  {
    DATE: "2012-12-13",
    MORTGAGE30US: 3.32,
  },
  {
    DATE: "2012-12-20",
    MORTGAGE30US: 3.37,
  },
  {
    DATE: "2012-12-27",
    MORTGAGE30US: 3.35,
  },
  {
    DATE: "2013-01-03",
    MORTGAGE30US: 3.34,
  },
  {
    DATE: "2013-01-10",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2013-01-17",
    MORTGAGE30US: 3.38,
  },
  {
    DATE: "2013-01-24",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2013-01-31",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2013-02-07",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2013-02-14",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2013-02-21",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2013-02-28",
    MORTGAGE30US: 3.51,
  },
  {
    DATE: "2013-03-07",
    MORTGAGE30US: 3.52,
  },
  {
    DATE: "2013-03-14",
    MORTGAGE30US: 3.63,
  },
  {
    DATE: "2013-03-21",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2013-03-28",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2013-04-04",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2013-04-11",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2013-04-18",
    MORTGAGE30US: 3.41,
  },
  {
    DATE: "2013-04-25",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2013-05-02",
    MORTGAGE30US: 3.35,
  },
  {
    DATE: "2013-05-09",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2013-05-16",
    MORTGAGE30US: 3.51,
  },
  {
    DATE: "2013-05-23",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2013-05-30",
    MORTGAGE30US: 3.81,
  },
  {
    DATE: "2013-06-06",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2013-06-13",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2013-06-20",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2013-06-27",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2013-07-03",
    MORTGAGE30US: 4.29,
  },
  {
    DATE: "2013-07-11",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2013-07-18",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2013-07-25",
    MORTGAGE30US: 4.31,
  },
  {
    DATE: "2013-08-01",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2013-08-08",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2013-08-15",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2013-08-22",
    MORTGAGE30US: 4.58,
  },
  {
    DATE: "2013-08-29",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2013-09-05",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2013-09-12",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2013-09-19",
    MORTGAGE30US: 4.5,
  },
  {
    DATE: "2013-09-26",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2013-10-03",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2013-10-10",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2013-10-17",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2013-10-24",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2013-10-31",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2013-11-07",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2013-11-14",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2013-11-21",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2013-11-27",
    MORTGAGE30US: 4.29,
  },
  {
    DATE: "2013-12-05",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2013-12-12",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2013-12-19",
    MORTGAGE30US: 4.47,
  },
  {
    DATE: "2013-12-26",
    MORTGAGE30US: 4.48,
  },
  {
    DATE: "2014-01-02",
    MORTGAGE30US: 4.53,
  },
  {
    DATE: "2014-01-09",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2014-01-16",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2014-01-23",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2014-01-30",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2014-02-06",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2014-02-13",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2014-02-20",
    MORTGAGE30US: 4.33,
  },
  {
    DATE: "2014-02-27",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2014-03-06",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2014-03-13",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2014-03-20",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2014-03-27",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2014-04-03",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2014-04-10",
    MORTGAGE30US: 4.34,
  },
  {
    DATE: "2014-04-17",
    MORTGAGE30US: 4.27,
  },
  {
    DATE: "2014-04-24",
    MORTGAGE30US: 4.33,
  },
  {
    DATE: "2014-05-01",
    MORTGAGE30US: 4.29,
  },
  {
    DATE: "2014-05-08",
    MORTGAGE30US: 4.21,
  },
  {
    DATE: "2014-05-15",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2014-05-22",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-05-29",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-06-05",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-06-12",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2014-06-19",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2014-06-26",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-07-03",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-07-10",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2014-07-17",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2014-07-24",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2014-07-31",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-08-07",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-08-14",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-08-21",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2014-08-28",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2014-09-04",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2014-09-11",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-09-18",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2014-09-25",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2014-10-02",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2014-10-09",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-10-16",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2014-10-23",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2014-10-30",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2014-11-06",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2014-11-13",
    MORTGAGE30US: 4.01,
  },
  {
    DATE: "2014-11-20",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2014-11-26",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2014-12-04",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2014-12-11",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2014-12-18",
    MORTGAGE30US: 3.8,
  },
  {
    DATE: "2014-12-24",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2014-12-31",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-01-08",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2015-01-15",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2015-01-22",
    MORTGAGE30US: 3.63,
  },
  {
    DATE: "2015-01-29",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2015-02-05",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2015-02-12",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2015-02-19",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2015-02-26",
    MORTGAGE30US: 3.8,
  },
  {
    DATE: "2015-03-05",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2015-03-12",
    MORTGAGE30US: 3.86,
  },
  {
    DATE: "2015-03-19",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2015-03-26",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2015-04-02",
    MORTGAGE30US: 3.7,
  },
  {
    DATE: "2015-04-09",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2015-04-16",
    MORTGAGE30US: 3.67,
  },
  {
    DATE: "2015-04-23",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2015-04-30",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2015-05-07",
    MORTGAGE30US: 3.8,
  },
  {
    DATE: "2015-05-14",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2015-05-21",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2015-05-28",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-06-04",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-06-11",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2015-06-18",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2015-06-25",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2015-07-02",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2015-07-09",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2015-07-16",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2015-07-23",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2015-07-30",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2015-08-06",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2015-08-13",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2015-08-20",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2015-08-27",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2015-09-03",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2015-09-10",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2015-09-17",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2015-09-24",
    MORTGAGE30US: 3.86,
  },
  {
    DATE: "2015-10-01",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2015-10-08",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2015-10-15",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2015-10-22",
    MORTGAGE30US: 3.79,
  },
  {
    DATE: "2015-10-29",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2015-11-05",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-11-12",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2015-11-19",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2015-11-25",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2015-12-03",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2015-12-10",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2015-12-17",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2015-12-24",
    MORTGAGE30US: 3.96,
  },
  {
    DATE: "2015-12-31",
    MORTGAGE30US: 4.01,
  },
  {
    DATE: "2016-01-07",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2016-01-14",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2016-01-21",
    MORTGAGE30US: 3.81,
  },
  {
    DATE: "2016-01-28",
    MORTGAGE30US: 3.79,
  },
  {
    DATE: "2016-02-04",
    MORTGAGE30US: 3.72,
  },
  {
    DATE: "2016-02-11",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2016-02-18",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2016-02-25",
    MORTGAGE30US: 3.62,
  },
  {
    DATE: "2016-03-03",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2016-03-10",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2016-03-17",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2016-03-24",
    MORTGAGE30US: 3.71,
  },
  {
    DATE: "2016-03-31",
    MORTGAGE30US: 3.71,
  },
  {
    DATE: "2016-04-07",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2016-04-14",
    MORTGAGE30US: 3.58,
  },
  {
    DATE: "2016-04-21",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2016-04-28",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2016-05-05",
    MORTGAGE30US: 3.61,
  },
  {
    DATE: "2016-05-12",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2016-05-19",
    MORTGAGE30US: 3.58,
  },
  {
    DATE: "2016-05-26",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2016-06-02",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2016-06-09",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2016-06-16",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2016-06-23",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2016-06-30",
    MORTGAGE30US: 3.48,
  },
  {
    DATE: "2016-07-07",
    MORTGAGE30US: 3.41,
  },
  {
    DATE: "2016-07-14",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2016-07-21",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2016-07-28",
    MORTGAGE30US: 3.48,
  },
  {
    DATE: "2016-08-04",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2016-08-11",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2016-08-18",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2016-08-25",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2016-09-01",
    MORTGAGE30US: 3.46,
  },
  {
    DATE: "2016-09-08",
    MORTGAGE30US: 3.44,
  },
  {
    DATE: "2016-09-15",
    MORTGAGE30US: 3.5,
  },
  {
    DATE: "2016-09-22",
    MORTGAGE30US: 3.48,
  },
  {
    DATE: "2016-09-29",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2016-10-06",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2016-10-13",
    MORTGAGE30US: 3.47,
  },
  {
    DATE: "2016-10-20",
    MORTGAGE30US: 3.52,
  },
  {
    DATE: "2016-10-27",
    MORTGAGE30US: 3.47,
  },
  {
    DATE: "2016-11-03",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2016-11-10",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2016-11-17",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2016-11-23",
    MORTGAGE30US: 4.03,
  },
  {
    DATE: "2016-12-01",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2016-12-08",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2016-12-15",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2016-12-22",
    MORTGAGE30US: 4.3,
  },
  {
    DATE: "2016-12-29",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2017-01-05",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2017-01-12",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2017-01-19",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2017-01-26",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2017-02-02",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2017-02-09",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2017-02-16",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2017-02-23",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2017-03-02",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2017-03-09",
    MORTGAGE30US: 4.21,
  },
  {
    DATE: "2017-03-16",
    MORTGAGE30US: 4.3,
  },
  {
    DATE: "2017-03-23",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2017-03-30",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2017-04-06",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2017-04-13",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2017-04-20",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2017-04-27",
    MORTGAGE30US: 4.03,
  },
  {
    DATE: "2017-05-04",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2017-05-11",
    MORTGAGE30US: 4.05,
  },
  {
    DATE: "2017-05-18",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2017-05-25",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2017-06-01",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-06-08",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2017-06-15",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2017-06-22",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-06-29",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2017-07-06",
    MORTGAGE30US: 3.96,
  },
  {
    DATE: "2017-07-13",
    MORTGAGE30US: 4.03,
  },
  {
    DATE: "2017-07-20",
    MORTGAGE30US: 3.96,
  },
  {
    DATE: "2017-07-27",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2017-08-03",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2017-08-10",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-08-17",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2017-08-24",
    MORTGAGE30US: 3.86,
  },
  {
    DATE: "2017-08-31",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2017-09-07",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2017-09-14",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2017-09-21",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2017-09-28",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2017-10-05",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2017-10-12",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2017-10-19",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2017-10-26",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-11-02",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-11-09",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-11-16",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2017-11-22",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2017-11-30",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-12-07",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-12-14",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2017-12-21",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-12-28",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2018-01-04",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2018-01-11",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2018-01-18",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2018-01-25",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2018-02-01",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2018-02-08",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2018-02-15",
    MORTGAGE30US: 4.38,
  },
  {
    DATE: "2018-02-22",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2018-03-01",
    MORTGAGE30US: 4.43,
  },
  {
    DATE: "2018-03-08",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2018-03-15",
    MORTGAGE30US: 4.44,
  },
  {
    DATE: "2018-03-22",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2018-03-29",
    MORTGAGE30US: 4.44,
  },
  {
    DATE: "2018-04-05",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2018-04-12",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2018-04-19",
    MORTGAGE30US: 4.47,
  },
  {
    DATE: "2018-04-26",
    MORTGAGE30US: 4.58,
  },
  {
    DATE: "2018-05-03",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2018-05-10",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2018-05-17",
    MORTGAGE30US: 4.61,
  },
  {
    DATE: "2018-05-24",
    MORTGAGE30US: 4.66,
  },
  {
    DATE: "2018-05-31",
    MORTGAGE30US: 4.56,
  },
  {
    DATE: "2018-06-07",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2018-06-14",
    MORTGAGE30US: 4.62,
  },
  {
    DATE: "2018-06-21",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2018-06-28",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2018-07-05",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2018-07-12",
    MORTGAGE30US: 4.53,
  },
  {
    DATE: "2018-07-19",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2018-07-26",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2018-08-02",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2018-08-09",
    MORTGAGE30US: 4.59,
  },
  {
    DATE: "2018-08-16",
    MORTGAGE30US: 4.53,
  },
  {
    DATE: "2018-08-23",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2018-08-30",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2018-09-06",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2018-09-13",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2018-09-20",
    MORTGAGE30US: 4.65,
  },
  {
    DATE: "2018-09-27",
    MORTGAGE30US: 4.72,
  },
  {
    DATE: "2018-10-04",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2018-10-11",
    MORTGAGE30US: 4.9,
  },
  {
    DATE: "2018-10-18",
    MORTGAGE30US: 4.85,
  },
  {
    DATE: "2018-10-25",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2018-11-01",
    MORTGAGE30US: 4.83,
  },
  {
    DATE: "2018-11-08",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2018-11-15",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2018-11-21",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2018-11-29",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2018-12-06",
    MORTGAGE30US: 4.75,
  },
  {
    DATE: "2018-12-13",
    MORTGAGE30US: 4.63,
  },
  {
    DATE: "2018-12-20",
    MORTGAGE30US: 4.62,
  },
  {
    DATE: "2018-12-27",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2019-01-03",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2019-01-10",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2019-01-17",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2019-01-24",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2019-01-31",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2019-02-07",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2019-02-14",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2019-02-21",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2019-02-28",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2019-03-07",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2019-03-14",
    MORTGAGE30US: 4.31,
  },
  {
    DATE: "2019-03-21",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2019-03-28",
    MORTGAGE30US: 4.06,
  },
  {
    DATE: "2019-04-04",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2019-04-11",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2019-04-18",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2019-04-25",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2019-05-02",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2019-05-09",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2019-05-16",
    MORTGAGE30US: 4.07,
  },
  {
    DATE: "2019-05-23",
    MORTGAGE30US: 4.06,
  },
  {
    DATE: "2019-05-30",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2019-06-06",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2019-06-13",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2019-06-20",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2019-06-27",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-07-03",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-07-11",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-07-18",
    MORTGAGE30US: 3.81,
  },
  {
    DATE: "2019-07-25",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-08-01",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-08-08",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2019-08-15",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2019-08-22",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2019-08-29",
    MORTGAGE30US: 3.58,
  },
  {
    DATE: "2019-09-05",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2019-09-12",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2019-09-19",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-09-26",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2019-10-03",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2019-10-10",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2019-10-17",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2019-10-24",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-10-31",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2019-11-07",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2019-11-14",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-11-21",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2019-11-27",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2019-12-05",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2019-12-12",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-12-19",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-12-26",
    MORTGAGE30US: 3.74,
  },
  {
    DATE: "2020-01-02",
    MORTGAGE30US: 3.72,
  },
  {
    DATE: "2020-01-09",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2020-01-16",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2020-01-23",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2020-01-30",
    MORTGAGE30US: 3.51,
  },
  {
    DATE: "2020-02-06",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2020-02-13",
    MORTGAGE30US: 3.47,
  },
  {
    DATE: "2020-02-20",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2020-02-27",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2020-03-05",
    MORTGAGE30US: 3.29,
  },
  {
    DATE: "2020-03-12",
    MORTGAGE30US: 3.36,
  },
  {
    DATE: "2020-03-19",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2020-03-26",
    MORTGAGE30US: 3.5,
  },
  {
    DATE: "2020-04-02",
    MORTGAGE30US: 3.33,
  },
  {
    DATE: "2020-04-09",
    MORTGAGE30US: 3.33,
  },
  {
    DATE: "2020-04-16",
    MORTGAGE30US: 3.31,
  },
  {
    DATE: "2020-04-23",
    MORTGAGE30US: 3.33,
  },
  {
    DATE: "2020-04-30",
    MORTGAGE30US: 3.23,
  },
  {
    DATE: "2020-05-07",
    MORTGAGE30US: 3.26,
  },
  {
    DATE: "2020-05-14",
    MORTGAGE30US: 3.28,
  },
  {
    DATE: "2020-05-21",
    MORTGAGE30US: 3.24,
  },
  {
    DATE: "2020-05-28",
    MORTGAGE30US: 3.15,
  },
  {
    DATE: "2020-06-04",
    MORTGAGE30US: 3.18,
  },
  {
    DATE: "2020-06-11",
    MORTGAGE30US: 3.21,
  },
  {
    DATE: "2020-06-18",
    MORTGAGE30US: 3.13,
  },
  {
    DATE: "2020-06-25",
    MORTGAGE30US: 3.13,
  },
  {
    DATE: "2020-07-02",
    MORTGAGE30US: 3.07,
  },
  {
    DATE: "2020-07-09",
    MORTGAGE30US: 3.03,
  },
  {
    DATE: "2020-07-16",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2020-07-23",
    MORTGAGE30US: 3.01,
  },
  {
    DATE: "2020-07-30",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2020-08-06",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2020-08-13",
    MORTGAGE30US: 2.96,
  },
  {
    DATE: "2020-08-20",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2020-08-27",
    MORTGAGE30US: 2.91,
  },
  {
    DATE: "2020-09-03",
    MORTGAGE30US: 2.93,
  },
  {
    DATE: "2020-09-10",
    MORTGAGE30US: 2.86,
  },
  {
    DATE: "2020-09-17",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2020-09-24",
    MORTGAGE30US: 2.9,
  },
  {
    DATE: "2020-10-01",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2020-10-08",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2020-10-15",
    MORTGAGE30US: 2.81,
  },
  {
    DATE: "2020-10-22",
    MORTGAGE30US: 2.8,
  },
  {
    DATE: "2020-10-29",
    MORTGAGE30US: 2.81,
  },
  {
    DATE: "2020-11-05",
    MORTGAGE30US: 2.78,
  },
  {
    DATE: "2020-11-12",
    MORTGAGE30US: 2.84,
  },
  {
    DATE: "2020-11-19",
    MORTGAGE30US: 2.72,
  },
  {
    DATE: "2020-11-25",
    MORTGAGE30US: 2.72,
  },
  {
    DATE: "2020-12-03",
    MORTGAGE30US: 2.71,
  },
  {
    DATE: "2020-12-10",
    MORTGAGE30US: 2.71,
  },
  {
    DATE: "2020-12-17",
    MORTGAGE30US: 2.67,
  },
  {
    DATE: "2020-12-24",
    MORTGAGE30US: 2.66,
  },
  {
    DATE: "2020-12-31",
    MORTGAGE30US: 2.67,
  },
  {
    DATE: "2021-01-07",
    MORTGAGE30US: 2.65,
  },
  {
    DATE: "2021-01-14",
    MORTGAGE30US: 2.79,
  },
  {
    DATE: "2021-01-21",
    MORTGAGE30US: 2.77,
  },
  {
    DATE: "2021-01-28",
    MORTGAGE30US: 2.73,
  },
  {
    DATE: "2021-02-04",
    MORTGAGE30US: 2.73,
  },
  {
    DATE: "2021-02-11",
    MORTGAGE30US: 2.73,
  },
  {
    DATE: "2021-02-18",
    MORTGAGE30US: 2.81,
  },
  {
    DATE: "2021-02-25",
    MORTGAGE30US: 2.97,
  },
  {
    DATE: "2021-03-04",
    MORTGAGE30US: 3.02,
  },
  {
    DATE: "2021-03-11",
    MORTGAGE30US: 3.05,
  },
  {
    DATE: "2021-03-18",
    MORTGAGE30US: 3.09,
  },
  {
    DATE: "2021-03-25",
    MORTGAGE30US: 3.17,
  },
  {
    DATE: "2021-04-01",
    MORTGAGE30US: 3.18,
  },
  {
    DATE: "2021-04-08",
    MORTGAGE30US: 3.13,
  },
  {
    DATE: "2021-04-15",
    MORTGAGE30US: 3.04,
  },
  {
    DATE: "2021-04-22",
    MORTGAGE30US: 2.97,
  },
  {
    DATE: "2021-04-29",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2021-05-06",
    MORTGAGE30US: 2.96,
  },
  {
    DATE: "2021-05-13",
    MORTGAGE30US: 2.94,
  },
  {
    DATE: "2021-05-20",
    MORTGAGE30US: 3,
  },
  {
    DATE: "2021-05-27",
    MORTGAGE30US: 2.95,
  },
  {
    DATE: "2021-06-03",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2021-06-10",
    MORTGAGE30US: 2.96,
  },
  {
    DATE: "2021-06-17",
    MORTGAGE30US: 2.93,
  },
  {
    DATE: "2021-06-24",
    MORTGAGE30US: 3.02,
  },
  {
    DATE: "2021-07-01",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2021-07-08",
    MORTGAGE30US: 2.9,
  },
  {
    DATE: "2021-07-15",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2021-07-22",
    MORTGAGE30US: 2.78,
  },
  {
    DATE: "2021-07-29",
    MORTGAGE30US: 2.8,
  },
  {
    DATE: "2021-08-05",
    MORTGAGE30US: 2.77,
  },
  {
    DATE: "2021-08-12",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2021-08-19",
    MORTGAGE30US: 2.86,
  },
  {
    DATE: "2021-08-26",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2021-09-02",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2021-09-09",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2021-09-16",
    MORTGAGE30US: 2.86,
  },
  {
    DATE: "2021-09-23",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2021-09-30",
    MORTGAGE30US: 3.01,
  },
  {
    DATE: "2021-10-07",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2021-10-14",
    MORTGAGE30US: 3.05,
  },
  {
    DATE: "2021-10-21",
    MORTGAGE30US: 3.09,
  },
  {
    DATE: "2021-10-28",
    MORTGAGE30US: 3.14,
  },
  {
    DATE: "2021-11-04",
    MORTGAGE30US: 3.09,
  },
  {
    DATE: "2021-11-10",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2021-11-18",
    MORTGAGE30US: 3.1,
  },
  {
    DATE: "2021-11-24",
    MORTGAGE30US: 3.1,
  },
  {
    DATE: "2021-12-02",
    MORTGAGE30US: 3.11,
  },
  {
    DATE: "2021-12-09",
    MORTGAGE30US: 3.1,
  },
  {
    DATE: "2021-12-16",
    MORTGAGE30US: 3.12,
  },
  {
    DATE: "2021-12-23",
    MORTGAGE30US: 3.05,
  },
  {
    DATE: "2021-12-30",
    MORTGAGE30US: 3.11,
  },
  {
    DATE: "2022-01-06",
    MORTGAGE30US: 3.22,
  },
  {
    DATE: "2022-01-13",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2022-01-20",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2022-01-27",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2022-02-03",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2022-02-10",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2022-02-17",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2022-02-24",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2022-03-03",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2022-03-10",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2022-03-17",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2022-03-24",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2022-03-31",
    MORTGAGE30US: 4.67,
  },
  {
    DATE: "2022-04-07",
    MORTGAGE30US: 4.72,
  },
  {
    DATE: "2022-04-14",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2022-04-21",
    MORTGAGE30US: 5.11,
  },
  {
    DATE: "2022-04-28",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2022-05-05",
    MORTGAGE30US: 5.27,
  },
  {
    DATE: "2022-05-12",
    MORTGAGE30US: 5.3,
  },
  {
    DATE: "2022-05-19",
    MORTGAGE30US: 5.25,
  },
  {
    DATE: "2022-05-26",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2022-06-02",
    MORTGAGE30US: 5.09,
  },
  {
    DATE: "2022-06-09",
    MORTGAGE30US: 5.23,
  },
  {
    DATE: "2022-06-16",
    MORTGAGE30US: 5.78,
  },
  {
    DATE: "2022-06-23",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2022-06-30",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2022-07-07",
    MORTGAGE30US: 5.3,
  },
  {
    DATE: "2022-07-14",
    MORTGAGE30US: 5.51,
  },
  {
    DATE: "2022-07-21",
    MORTGAGE30US: 5.54,
  },
  {
    DATE: "2022-07-28",
    MORTGAGE30US: 5.3,
  },
  {
    DATE: "2022-08-04",
    MORTGAGE30US: 4.99,
  },
  {
    DATE: "2022-08-11",
    MORTGAGE30US: 5.22,
  },
  {
    DATE: "2022-08-18",
    MORTGAGE30US: 5.13,
  },
  {
    DATE: "2022-08-25",
    MORTGAGE30US: 5.55,
  },
  {
    DATE: "2022-09-01",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2022-09-08",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2022-09-15",
    MORTGAGE30US: 6.02,
  },
  {
    DATE: "2022-09-22",
    MORTGAGE30US: 6.29,
  },
  {
    DATE: "2022-09-29",
    MORTGAGE30US: 6.7,
  },
  {
    DATE: "2022-10-06",
    MORTGAGE30US: 6.66,
  },
  {
    DATE: "2022-10-13",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "2022-10-20",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "2022-10-27",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2022-11-03",
    MORTGAGE30US: 6.95,
  },
  {
    DATE: "2022-11-10",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2022-11-17",
    MORTGAGE30US: 6.61,
  },
  {
    DATE: "2022-11-23",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2022-12-01",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "2022-12-08",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2022-12-15",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2022-12-22",
    MORTGAGE30US: 6.27,
  },
  {
    DATE: "2022-12-29",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2023-01-05",
    MORTGAGE30US: 6.48,
  },
  {
    DATE: "2023-01-12",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2023-01-19",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2023-01-26",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2023-02-02",
    MORTGAGE30US: 6.09,
  },
  {
    DATE: "2023-02-09",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2023-02-16",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2023-02-23",
    MORTGAGE30US: 6.5,
  },
  {
    DATE: "2023-03-02",
    MORTGAGE30US: 6.65,
  },
  {
    DATE: "2023-03-09",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "2023-03-16",
    MORTGAGE30US: 6.6,
  },
  {
    DATE: "2023-03-23",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2023-03-30",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2023-04-06",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2023-04-13",
    MORTGAGE30US: 6.27,
  },
  {
    DATE: "2023-04-20",
    MORTGAGE30US: 6.39,
  },
  {
    DATE: "2023-04-27",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2023-05-04",
    MORTGAGE30US: 6.39,
  },
  {
    DATE: "2023-05-11",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2023-05-18",
    MORTGAGE30US: 6.39,
  },
  {
    DATE: "2023-05-25",
    MORTGAGE30US: 6.57,
  },
  {
    DATE: "2023-06-01",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "2023-06-08",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2023-06-15",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "2023-06-22",
    MORTGAGE30US: 6.67,
  },
  {
    DATE: "2023-06-29",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2023-07-06",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2023-07-13",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2023-07-20",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "2023-07-27",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2023-08-03",
    MORTGAGE30US: 6.9,
  },
  {
    DATE: "2023-08-10",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2023-08-17",
    MORTGAGE30US: 7.09,
  },
];

export const currentInterestRate =
  interestRateList[interestRateList.length - 1]["MORTGAGE30US"] / 100;

export const mortgageAverageList = [
  {
    DATE: "1971-04-02",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1971-04-09",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1971-04-16",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1971-04-23",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1971-04-30",
    MORTGAGE30US: 7.29,
  },
  {
    DATE: "1971-05-07",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1971-05-14",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1971-05-21",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1971-05-28",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1971-06-04",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1971-06-11",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1971-06-18",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-06-25",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-07-02",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-07-09",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-07-16",
    MORTGAGE30US: 7.6,
  },
  {
    DATE: "1971-07-23",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1971-07-30",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1971-08-06",
    MORTGAGE30US: 7.66,
  },
  {
    DATE: "1971-08-13",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "1971-08-20",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1971-08-27",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1971-09-03",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1971-09-10",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1971-09-17",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1971-09-24",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1971-10-01",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1971-10-08",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1971-10-15",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1971-10-22",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1971-10-29",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1971-11-05",
    MORTGAGE30US: 7.59,
  },
  {
    DATE: "1971-11-12",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1971-11-19",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1971-11-26",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1971-12-03",
    MORTGAGE30US: 7.49,
  },
  {
    DATE: "1971-12-10",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1971-12-17",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1971-12-24",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1971-12-31",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1972-01-07",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1972-01-14",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1972-01-21",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-01-28",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-02-04",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1972-02-11",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1972-02-18",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-02-25",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-03-03",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1972-03-10",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1972-03-17",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-03-24",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1972-03-31",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1972-04-07",
    MORTGAGE30US: 7.25,
  },
  {
    DATE: "1972-04-14",
    MORTGAGE30US: 7.29,
  },
  {
    DATE: "1972-04-21",
    MORTGAGE30US: 7.29,
  },
  {
    DATE: "1972-04-28",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1972-05-05",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1972-05-12",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-05-19",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-05-26",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-06-02",
    MORTGAGE30US: 7.36,
  },
  {
    DATE: "1972-06-09",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-06-16",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-06-23",
    MORTGAGE30US: 7.36,
  },
  {
    DATE: "1972-06-30",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-07-07",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1972-07-14",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-07-21",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-07-28",
    MORTGAGE30US: 7.4,
  },
  {
    DATE: "1972-08-04",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1972-08-11",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-08-18",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-08-25",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-01",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-08",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-15",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-09-22",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-09-29",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-10-06",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-10-13",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1972-10-20",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1972-10-27",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-11-03",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-11-10",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-11-17",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1972-11-24",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1972-12-01",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1972-12-08",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-12-15",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1972-12-22",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1972-12-29",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-01-05",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1973-01-12",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1973-01-19",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1973-01-26",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1973-02-02",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1973-02-09",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1973-02-16",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-02-23",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-02",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-09",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-16",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-23",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1973-03-30",
    MORTGAGE30US: 7.49,
  },
  {
    DATE: "1973-04-06",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1973-04-13",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1973-04-20",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1973-04-27",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1973-05-04",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1973-05-11",
    MORTGAGE30US: 7.64,
  },
  {
    DATE: "1973-05-18",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1973-05-24",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1973-06-01",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1973-06-08",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "1973-06-15",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "1973-06-22",
    MORTGAGE30US: 7.75,
  },
  {
    DATE: "1973-06-29",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1973-07-06",
    MORTGAGE30US: 7.89,
  },
  {
    DATE: "1973-07-13",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1973-07-20",
    MORTGAGE30US: 8.12,
  },
  {
    DATE: "1973-07-27",
    MORTGAGE30US: 8.18,
  },
  {
    DATE: "1973-08-03",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "1973-08-10",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1973-08-17",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1973-08-24",
    MORTGAGE30US: 8.61,
  },
  {
    DATE: "1973-08-31",
    MORTGAGE30US: 8.66,
  },
  {
    DATE: "1973-09-07",
    MORTGAGE30US: 8.77,
  },
  {
    DATE: "1973-09-14",
    MORTGAGE30US: 8.81,
  },
  {
    DATE: "1973-09-21",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1973-09-28",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1973-10-05",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1973-10-12",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1973-10-19",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1973-10-26",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1973-11-02",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "1973-11-09",
    MORTGAGE30US: 8.59,
  },
  {
    DATE: "1973-11-16",
    MORTGAGE30US: 8.58,
  },
  {
    DATE: "1973-11-23",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1973-11-30",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1973-12-07",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1973-12-14",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1973-12-21",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1973-12-28",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1974-01-04",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1974-01-11",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1974-01-18",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1974-01-25",
    MORTGAGE30US: 8.52,
  },
  {
    DATE: "1974-02-01",
    MORTGAGE30US: 8.48,
  },
  {
    DATE: "1974-02-08",
    MORTGAGE30US: 8.46,
  },
  {
    DATE: "1974-02-15",
    MORTGAGE30US: 8.46,
  },
  {
    DATE: "1974-02-22",
    MORTGAGE30US: 8.42,
  },
  {
    DATE: "1974-03-01",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1974-03-08",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1974-03-15",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1974-03-22",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1974-03-29",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1974-04-05",
    MORTGAGE30US: 8.44,
  },
  {
    DATE: "1974-04-12",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1974-04-19",
    MORTGAGE30US: 8.63,
  },
  {
    DATE: "1974-04-26",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1974-05-03",
    MORTGAGE30US: 8.87,
  },
  {
    DATE: "1974-05-10",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1974-05-17",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1974-05-24",
    MORTGAGE30US: 9.01,
  },
  {
    DATE: "1974-05-31",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1974-06-07",
    MORTGAGE30US: 9.06,
  },
  {
    DATE: "1974-06-14",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1974-06-21",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1974-06-28",
    MORTGAGE30US: 9.1,
  },
  {
    DATE: "1974-07-05",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1974-07-12",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1974-07-19",
    MORTGAGE30US: 9.37,
  },
  {
    DATE: "1974-07-26",
    MORTGAGE30US: 9.42,
  },
  {
    DATE: "1974-08-02",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1974-08-09",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1974-08-16",
    MORTGAGE30US: 9.6,
  },
  {
    DATE: "1974-08-23",
    MORTGAGE30US: 9.62,
  },
  {
    DATE: "1974-08-30",
    MORTGAGE30US: 9.74,
  },
  {
    DATE: "1974-09-06",
    MORTGAGE30US: 9.88,
  },
  {
    DATE: "1974-09-13",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1974-09-20",
    MORTGAGE30US: 10,
  },
  {
    DATE: "1974-09-27",
    MORTGAGE30US: 10.03,
  },
  {
    DATE: "1974-10-04",
    MORTGAGE30US: 10.02,
  },
  {
    DATE: "1974-10-11",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1974-10-18",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1974-10-25",
    MORTGAGE30US: 9.94,
  },
  {
    DATE: "1974-11-01",
    MORTGAGE30US: 9.87,
  },
  {
    DATE: "1974-11-08",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1974-11-15",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1974-11-22",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1974-11-29",
    MORTGAGE30US: 9.72,
  },
  {
    DATE: "1974-12-06",
    MORTGAGE30US: 9.69,
  },
  {
    DATE: "1974-12-13",
    MORTGAGE30US: 9.63,
  },
  {
    DATE: "1974-12-20",
    MORTGAGE30US: 9.58,
  },
  {
    DATE: "1974-12-27",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1975-01-03",
    MORTGAGE30US: 9.6,
  },
  {
    DATE: "1975-01-10",
    MORTGAGE30US: 9.49,
  },
  {
    DATE: "1975-01-17",
    MORTGAGE30US: 9.43,
  },
  {
    DATE: "1975-01-24",
    MORTGAGE30US: 9.35,
  },
  {
    DATE: "1975-01-31",
    MORTGAGE30US: 9.29,
  },
  {
    DATE: "1975-02-07",
    MORTGAGE30US: 9.2,
  },
  {
    DATE: "1975-02-14",
    MORTGAGE30US: 9.13,
  },
  {
    DATE: "1975-02-21",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1975-02-28",
    MORTGAGE30US: 9.02,
  },
  {
    DATE: "1975-03-07",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1975-03-14",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-03-21",
    MORTGAGE30US: 8.86,
  },
  {
    DATE: "1975-03-28",
    MORTGAGE30US: 8.86,
  },
  {
    DATE: "1975-04-04",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1975-04-11",
    MORTGAGE30US: 8.81,
  },
  {
    DATE: "1975-04-18",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1975-04-25",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1975-05-02",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1975-05-09",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1975-05-16",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1975-05-23",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1975-05-30",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-06-06",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-06-13",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1975-06-20",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1975-06-27",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1975-07-03",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1975-07-11",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1975-07-18",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-07-25",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-08-01",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-08-08",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1975-08-15",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1975-08-22",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1975-08-29",
    MORTGAGE30US: 9.02,
  },
  {
    DATE: "1975-09-05",
    MORTGAGE30US: 9.08,
  },
  {
    DATE: "1975-09-12",
    MORTGAGE30US: 9.16,
  },
  {
    DATE: "1975-09-19",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1975-09-26",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1975-10-03",
    MORTGAGE30US: 9.2,
  },
  {
    DATE: "1975-10-10",
    MORTGAGE30US: 9.21,
  },
  {
    DATE: "1975-10-17",
    MORTGAGE30US: 9.21,
  },
  {
    DATE: "1975-10-24",
    MORTGAGE30US: 9.26,
  },
  {
    DATE: "1975-10-31",
    MORTGAGE30US: 9.24,
  },
  {
    DATE: "1975-11-07",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1975-11-14",
    MORTGAGE30US: 9.16,
  },
  {
    DATE: "1975-11-21",
    MORTGAGE30US: 9.12,
  },
  {
    DATE: "1975-11-28",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1975-12-05",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1975-12-12",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1975-12-19",
    MORTGAGE30US: 9.08,
  },
  {
    DATE: "1975-12-26",
    MORTGAGE30US: 9.09,
  },
  {
    DATE: "1976-01-02",
    MORTGAGE30US: 9.1,
  },
  {
    DATE: "1976-01-09",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1976-01-16",
    MORTGAGE30US: 9.01,
  },
  {
    DATE: "1976-01-23",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-01-30",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-02-06",
    MORTGAGE30US: 8.86,
  },
  {
    DATE: "1976-02-13",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1976-02-20",
    MORTGAGE30US: 8.81,
  },
  {
    DATE: "1976-02-27",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-03-05",
    MORTGAGE30US: 8.77,
  },
  {
    DATE: "1976-03-12",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-03-19",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-03-26",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-04-02",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1976-04-09",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1976-04-16",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-04-23",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-04-30",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-05-07",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-05-14",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1976-05-21",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-05-28",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-06-04",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-06-11",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1976-06-18",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1976-06-25",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-07-02",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1976-07-09",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1976-07-16",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1976-07-23",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-07-30",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1976-08-06",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-08-13",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-08-20",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-08-27",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-09-03",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1976-09-10",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1976-09-17",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1976-09-24",
    MORTGAGE30US: 8.97,
  },
  {
    DATE: "1976-10-01",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-10-08",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1976-10-15",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1976-10-22",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1976-10-29",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1976-11-05",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1976-11-12",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-11-19",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-11-26",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-03",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-10",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-17",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1976-12-24",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1976-12-31",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-01-07",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-01-14",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1977-01-21",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1977-01-28",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1977-02-04",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1977-02-11",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-02-18",
    MORTGAGE30US: 8.65,
  },
  {
    DATE: "1977-02-25",
    MORTGAGE30US: 8.65,
  },
  {
    DATE: "1977-03-04",
    MORTGAGE30US: 8.65,
  },
  {
    DATE: "1977-03-11",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-03-18",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-03-25",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-04-01",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1977-04-08",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1977-04-15",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1977-04-22",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-04-29",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-05-06",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1977-05-13",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1977-05-20",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-05-27",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-03",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-10",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-17",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1977-06-24",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1977-07-01",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-07-08",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-07-15",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-07-22",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-07-29",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-08-05",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-08-12",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-08-19",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-08-26",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-09-02",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1977-09-09",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-09-16",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-09-23",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-09-30",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-10-07",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-10-14",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-10-21",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-10-28",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-11-04",
    MORTGAGE30US: 8.9,
  },
  {
    DATE: "1977-11-11",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-11-18",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-11-25",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1977-12-02",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-09",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-16",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-23",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1977-12-30",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1978-01-06",
    MORTGAGE30US: 9,
  },
  {
    DATE: "1978-01-13",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1978-01-20",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1978-01-27",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1978-02-03",
    MORTGAGE30US: 9.13,
  },
  {
    DATE: "1978-02-10",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-02-17",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-02-24",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-03-03",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-03-10",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1978-03-17",
    MORTGAGE30US: 9.23,
  },
  {
    DATE: "1978-03-24",
    MORTGAGE30US: 9.23,
  },
  {
    DATE: "1978-03-31",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1978-04-07",
    MORTGAGE30US: 9.28,
  },
  {
    DATE: "1978-04-14",
    MORTGAGE30US: 9.33,
  },
  {
    DATE: "1978-04-21",
    MORTGAGE30US: 9.38,
  },
  {
    DATE: "1978-04-28",
    MORTGAGE30US: 9.43,
  },
  {
    DATE: "1978-05-05",
    MORTGAGE30US: 9.48,
  },
  {
    DATE: "1978-05-12",
    MORTGAGE30US: 9.55,
  },
  {
    DATE: "1978-05-19",
    MORTGAGE30US: 9.58,
  },
  {
    DATE: "1978-05-26",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1978-06-02",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1978-06-09",
    MORTGAGE30US: 9.7,
  },
  {
    DATE: "1978-06-16",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-06-23",
    MORTGAGE30US: 9.7,
  },
  {
    DATE: "1978-06-30",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-07-07",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-07-14",
    MORTGAGE30US: 9.73,
  },
  {
    DATE: "1978-07-21",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-07-28",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-08-04",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-08-11",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-08-18",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-08-25",
    MORTGAGE30US: 9.8,
  },
  {
    DATE: "1978-09-01",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-08",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-15",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-22",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1978-09-29",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1978-10-06",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1978-10-13",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1978-10-20",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1978-10-27",
    MORTGAGE30US: 9.88,
  },
  {
    DATE: "1978-11-03",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1978-11-10",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1978-11-17",
    MORTGAGE30US: 10.2,
  },
  {
    DATE: "1978-11-24",
    MORTGAGE30US: 10.28,
  },
  {
    DATE: "1978-12-01",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1978-12-08",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1978-12-15",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1978-12-22",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1978-12-29",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1979-01-05",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1979-01-12",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1979-01-19",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-01-26",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-02-02",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-02-09",
    MORTGAGE30US: 10.43,
  },
  {
    DATE: "1979-02-16",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-02-23",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-03-02",
    MORTGAGE30US: 10.43,
  },
  {
    DATE: "1979-03-09",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-03-16",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1979-03-23",
    MORTGAGE30US: 10.45,
  },
  {
    DATE: "1979-03-30",
    MORTGAGE30US: 10.45,
  },
  {
    DATE: "1979-04-06",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1979-04-13",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1979-04-20",
    MORTGAGE30US: 10.5,
  },
  {
    DATE: "1979-04-27",
    MORTGAGE30US: 10.53,
  },
  {
    DATE: "1979-05-04",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1979-05-11",
    MORTGAGE30US: 10.68,
  },
  {
    DATE: "1979-05-18",
    MORTGAGE30US: 10.73,
  },
  {
    DATE: "1979-05-25",
    MORTGAGE30US: 10.75,
  },
  {
    DATE: "1979-06-01",
    MORTGAGE30US: 10.9,
  },
  {
    DATE: "1979-06-08",
    MORTGAGE30US: 11.03,
  },
  {
    DATE: "1979-06-15",
    MORTGAGE30US: 11.05,
  },
  {
    DATE: "1979-06-22",
    MORTGAGE30US: 11.1,
  },
  {
    DATE: "1979-06-29",
    MORTGAGE30US: 11.1,
  },
  {
    DATE: "1979-07-06",
    MORTGAGE30US: 11.13,
  },
  {
    DATE: "1979-07-13",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-07-20",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-07-27",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-03",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-10",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-17",
    MORTGAGE30US: 11.08,
  },
  {
    DATE: "1979-08-24",
    MORTGAGE30US: 11.1,
  },
  {
    DATE: "1979-08-31",
    MORTGAGE30US: 11.13,
  },
  {
    DATE: "1979-09-07",
    MORTGAGE30US: 11.2,
  },
  {
    DATE: "1979-09-14",
    MORTGAGE30US: 11.3,
  },
  {
    DATE: "1979-09-21",
    MORTGAGE30US: 11.35,
  },
  {
    DATE: "1979-09-28",
    MORTGAGE30US: 11.35,
  },
  {
    DATE: "1979-10-05",
    MORTGAGE30US: 11.35,
  },
  {
    DATE: "1979-10-12",
    MORTGAGE30US: 11.45,
  },
  {
    DATE: "1979-10-19",
    MORTGAGE30US: 11.75,
  },
  {
    DATE: "1979-10-26",
    MORTGAGE30US: 12,
  },
  {
    DATE: "1979-11-02",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1979-11-09",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1979-11-16",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1979-11-23",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1979-11-30",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-07",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-14",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-21",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1979-12-28",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1980-01-04",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-01-11",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1980-01-18",
    MORTGAGE30US: 12.87,
  },
  {
    DATE: "1980-01-25",
    MORTGAGE30US: 12.89,
  },
  {
    DATE: "1980-02-01",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-02-08",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-02-15",
    MORTGAGE30US: 12.88,
  },
  {
    DATE: "1980-02-22",
    MORTGAGE30US: 13.03,
  },
  {
    DATE: "1980-02-29",
    MORTGAGE30US: 13.59,
  },
  {
    DATE: "1980-03-07",
    MORTGAGE30US: 14,
  },
  {
    DATE: "1980-03-14",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1980-03-21",
    MORTGAGE30US: 15.7,
  },
  {
    DATE: "1980-03-28",
    MORTGAGE30US: 16.03,
  },
  {
    DATE: "1980-04-04",
    MORTGAGE30US: 16.35,
  },
  {
    DATE: "1980-04-11",
    MORTGAGE30US: 16.35,
  },
  {
    DATE: "1980-04-18",
    MORTGAGE30US: 16.35,
  },
  {
    DATE: "1980-04-25",
    MORTGAGE30US: 16.25,
  },
  {
    DATE: "1980-05-02",
    MORTGAGE30US: 15.9,
  },
  {
    DATE: "1980-05-09",
    MORTGAGE30US: 14.68,
  },
  {
    DATE: "1980-05-16",
    MORTGAGE30US: 14.15,
  },
  {
    DATE: "1980-05-23",
    MORTGAGE30US: 13.38,
  },
  {
    DATE: "1980-05-30",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1980-06-06",
    MORTGAGE30US: 13.06,
  },
  {
    DATE: "1980-06-13",
    MORTGAGE30US: 12.85,
  },
  {
    DATE: "1980-06-20",
    MORTGAGE30US: 12.58,
  },
  {
    DATE: "1980-06-27",
    MORTGAGE30US: 12.35,
  },
  {
    DATE: "1980-07-04",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1980-07-11",
    MORTGAGE30US: 12.23,
  },
  {
    DATE: "1980-07-18",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1980-07-25",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1980-08-01",
    MORTGAGE30US: 12.25,
  },
  {
    DATE: "1980-08-08",
    MORTGAGE30US: 12.25,
  },
  {
    DATE: "1980-08-15",
    MORTGAGE30US: 12.55,
  },
  {
    DATE: "1980-08-22",
    MORTGAGE30US: 12.8,
  },
  {
    DATE: "1980-08-29",
    MORTGAGE30US: 12.95,
  },
  {
    DATE: "1980-09-05",
    MORTGAGE30US: 13.03,
  },
  {
    DATE: "1980-09-12",
    MORTGAGE30US: 13.08,
  },
  {
    DATE: "1980-09-19",
    MORTGAGE30US: 13.25,
  },
  {
    DATE: "1980-09-26",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1980-10-03",
    MORTGAGE30US: 13.6,
  },
  {
    DATE: "1980-10-10",
    MORTGAGE30US: 13.73,
  },
  {
    DATE: "1980-10-17",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1980-10-24",
    MORTGAGE30US: 13.85,
  },
  {
    DATE: "1980-10-31",
    MORTGAGE30US: 14,
  },
  {
    DATE: "1980-11-07",
    MORTGAGE30US: 14.08,
  },
  {
    DATE: "1980-11-14",
    MORTGAGE30US: 14.18,
  },
  {
    DATE: "1980-11-21",
    MORTGAGE30US: 14.28,
  },
  {
    DATE: "1980-11-28",
    MORTGAGE30US: 14.28,
  },
  {
    DATE: "1980-12-05",
    MORTGAGE30US: 14.43,
  },
  {
    DATE: "1980-12-12",
    MORTGAGE30US: 14.83,
  },
  {
    DATE: "1980-12-19",
    MORTGAGE30US: 14.95,
  },
  {
    DATE: "1980-12-26",
    MORTGAGE30US: 14.95,
  },
  {
    DATE: "1981-01-02",
    MORTGAGE30US: 14.95,
  },
  {
    DATE: "1981-01-09",
    MORTGAGE30US: 14.8,
  },
  {
    DATE: "1981-01-16",
    MORTGAGE30US: 14.85,
  },
  {
    DATE: "1981-01-23",
    MORTGAGE30US: 14.85,
  },
  {
    DATE: "1981-01-30",
    MORTGAGE30US: 15.07,
  },
  {
    DATE: "1981-02-06",
    MORTGAGE30US: 15,
  },
  {
    DATE: "1981-02-13",
    MORTGAGE30US: 15.03,
  },
  {
    DATE: "1981-02-20",
    MORTGAGE30US: 15.2,
  },
  {
    DATE: "1981-02-27",
    MORTGAGE30US: 15.3,
  },
  {
    DATE: "1981-03-06",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-03-13",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-03-20",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-03-27",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-04-03",
    MORTGAGE30US: 15.4,
  },
  {
    DATE: "1981-04-10",
    MORTGAGE30US: 15.5,
  },
  {
    DATE: "1981-04-17",
    MORTGAGE30US: 15.65,
  },
  {
    DATE: "1981-04-24",
    MORTGAGE30US: 15.77,
  },
  {
    DATE: "1981-05-01",
    MORTGAGE30US: 15.82,
  },
  {
    DATE: "1981-05-08",
    MORTGAGE30US: 16.12,
  },
  {
    DATE: "1981-05-15",
    MORTGAGE30US: 16.64,
  },
  {
    DATE: "1981-05-22",
    MORTGAGE30US: 16.63,
  },
  {
    DATE: "1981-05-29",
    MORTGAGE30US: 16.8,
  },
  {
    DATE: "1981-06-05",
    MORTGAGE30US: 16.76,
  },
  {
    DATE: "1981-06-12",
    MORTGAGE30US: 16.69,
  },
  {
    DATE: "1981-06-19",
    MORTGAGE30US: 16.71,
  },
  {
    DATE: "1981-06-26",
    MORTGAGE30US: 16.62,
  },
  {
    DATE: "1981-07-03",
    MORTGAGE30US: 16.64,
  },
  {
    DATE: "1981-07-10",
    MORTGAGE30US: 16.79,
  },
  {
    DATE: "1981-07-17",
    MORTGAGE30US: 16.74,
  },
  {
    DATE: "1981-07-24",
    MORTGAGE30US: 16.88,
  },
  {
    DATE: "1981-07-31",
    MORTGAGE30US: 17.11,
  },
  {
    DATE: "1981-08-07",
    MORTGAGE30US: 17.13,
  },
  {
    DATE: "1981-08-14",
    MORTGAGE30US: 17.27,
  },
  {
    DATE: "1981-08-21",
    MORTGAGE30US: 17.26,
  },
  {
    DATE: "1981-08-28",
    MORTGAGE30US: 17.48,
  },
  {
    DATE: "1981-09-04",
    MORTGAGE30US: 17.79,
  },
  {
    DATE: "1981-09-11",
    MORTGAGE30US: 18.22,
  },
  {
    DATE: "1981-09-18",
    MORTGAGE30US: 18.27,
  },
  {
    DATE: "1981-09-25",
    MORTGAGE30US: 18.36,
  },
  {
    DATE: "1981-10-02",
    MORTGAGE30US: 18.28,
  },
  {
    DATE: "1981-10-09",
    MORTGAGE30US: 18.63,
  },
  {
    DATE: "1981-10-16",
    MORTGAGE30US: 18.53,
  },
  {
    DATE: "1981-10-21",
    MORTGAGE30US: 18.39,
  },
  {
    DATE: "1981-10-30",
    MORTGAGE30US: 18.44,
  },
  {
    DATE: "1981-11-06",
    MORTGAGE30US: 18.37,
  },
  {
    DATE: "1981-11-13",
    MORTGAGE30US: 18.02,
  },
  {
    DATE: "1981-11-20",
    MORTGAGE30US: 17.7,
  },
  {
    DATE: "1981-11-27",
    MORTGAGE30US: 17.21,
  },
  {
    DATE: "1981-12-04",
    MORTGAGE30US: 16.9,
  },
  {
    DATE: "1981-12-11",
    MORTGAGE30US: 16.94,
  },
  {
    DATE: "1981-12-18",
    MORTGAGE30US: 16.9,
  },
  {
    DATE: "1981-12-25",
    MORTGAGE30US: 16.95,
  },
  {
    DATE: "1981-12-31",
    MORTGAGE30US: 17.04,
  },
  {
    DATE: "1982-01-08",
    MORTGAGE30US: 17.3,
  },
  {
    DATE: "1982-01-15",
    MORTGAGE30US: 17.44,
  },
  {
    DATE: "1982-01-22",
    MORTGAGE30US: 17.61,
  },
  {
    DATE: "1982-01-29",
    MORTGAGE30US: 17.59,
  },
  {
    DATE: "1982-02-05",
    MORTGAGE30US: 17.56,
  },
  {
    DATE: "1982-02-12",
    MORTGAGE30US: 17.65,
  },
  {
    DATE: "1982-02-19",
    MORTGAGE30US: 17.66,
  },
  {
    DATE: "1982-02-26",
    MORTGAGE30US: 17.52,
  },
  {
    DATE: "1982-03-05",
    MORTGAGE30US: 17.29,
  },
  {
    DATE: "1982-03-12",
    MORTGAGE30US: 17.19,
  },
  {
    DATE: "1982-03-19",
    MORTGAGE30US: 17.12,
  },
  {
    DATE: "1982-03-26",
    MORTGAGE30US: 17.04,
  },
  {
    DATE: "1982-04-02",
    MORTGAGE30US: 16.95,
  },
  {
    DATE: "1982-04-09",
    MORTGAGE30US: 16.91,
  },
  {
    DATE: "1982-04-16",
    MORTGAGE30US: 16.93,
  },
  {
    DATE: "1982-04-23",
    MORTGAGE30US: 16.86,
  },
  {
    DATE: "1982-04-30",
    MORTGAGE30US: 16.81,
  },
  {
    DATE: "1982-05-07",
    MORTGAGE30US: 16.78,
  },
  {
    DATE: "1982-05-14",
    MORTGAGE30US: 16.63,
  },
  {
    DATE: "1982-05-21",
    MORTGAGE30US: 16.67,
  },
  {
    DATE: "1982-05-28",
    MORTGAGE30US: 16.63,
  },
  {
    DATE: "1982-06-04",
    MORTGAGE30US: 16.65,
  },
  {
    DATE: "1982-06-11",
    MORTGAGE30US: 16.7,
  },
  {
    DATE: "1982-06-18",
    MORTGAGE30US: 16.71,
  },
  {
    DATE: "1982-06-25",
    MORTGAGE30US: 16.73,
  },
  {
    DATE: "1982-07-02",
    MORTGAGE30US: 16.87,
  },
  {
    DATE: "1982-07-09",
    MORTGAGE30US: 16.93,
  },
  {
    DATE: "1982-07-16",
    MORTGAGE30US: 16.88,
  },
  {
    DATE: "1982-07-23",
    MORTGAGE30US: 16.75,
  },
  {
    DATE: "1982-07-30",
    MORTGAGE30US: 16.65,
  },
  {
    DATE: "1982-08-06",
    MORTGAGE30US: 16.55,
  },
  {
    DATE: "1982-08-13",
    MORTGAGE30US: 16.44,
  },
  {
    DATE: "1982-08-20",
    MORTGAGE30US: 16.21,
  },
  {
    DATE: "1982-08-27",
    MORTGAGE30US: 15.88,
  },
  {
    DATE: "1982-09-03",
    MORTGAGE30US: 15.59,
  },
  {
    DATE: "1982-09-10",
    MORTGAGE30US: 15.56,
  },
  {
    DATE: "1982-09-17",
    MORTGAGE30US: 15.38,
  },
  {
    DATE: "1982-09-24",
    MORTGAGE30US: 15.19,
  },
  {
    DATE: "1982-10-01",
    MORTGAGE30US: 15.13,
  },
  {
    DATE: "1982-10-08",
    MORTGAGE30US: 14.96,
  },
  {
    DATE: "1982-10-15",
    MORTGAGE30US: 14.6,
  },
  {
    DATE: "1982-10-22",
    MORTGAGE30US: 14.2,
  },
  {
    DATE: "1982-10-29",
    MORTGAGE30US: 14.15,
  },
  {
    DATE: "1982-11-05",
    MORTGAGE30US: 13.91,
  },
  {
    DATE: "1982-11-12",
    MORTGAGE30US: 13.84,
  },
  {
    DATE: "1982-11-19",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1982-11-26",
    MORTGAGE30US: 13.77,
  },
  {
    DATE: "1982-12-03",
    MORTGAGE30US: 13.66,
  },
  {
    DATE: "1982-12-10",
    MORTGAGE30US: 13.66,
  },
  {
    DATE: "1982-12-17",
    MORTGAGE30US: 13.63,
  },
  {
    DATE: "1982-12-24",
    MORTGAGE30US: 13.6,
  },
  {
    DATE: "1982-12-31",
    MORTGAGE30US: 13.57,
  },
  {
    DATE: "1983-01-07",
    MORTGAGE30US: 13.46,
  },
  {
    DATE: "1983-01-14",
    MORTGAGE30US: 13.31,
  },
  {
    DATE: "1983-01-21",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1983-01-28",
    MORTGAGE30US: 13.1,
  },
  {
    DATE: "1983-02-04",
    MORTGAGE30US: 13.06,
  },
  {
    DATE: "1983-02-11",
    MORTGAGE30US: 13.06,
  },
  {
    DATE: "1983-02-18",
    MORTGAGE30US: 13.07,
  },
  {
    DATE: "1983-02-25",
    MORTGAGE30US: 12.98,
  },
  {
    DATE: "1983-03-04",
    MORTGAGE30US: 12.74,
  },
  {
    DATE: "1983-03-11",
    MORTGAGE30US: 12.79,
  },
  {
    DATE: "1983-03-18",
    MORTGAGE30US: 12.81,
  },
  {
    DATE: "1983-03-25",
    MORTGAGE30US: 12.86,
  },
  {
    DATE: "1983-04-01",
    MORTGAGE30US: 12.82,
  },
  {
    DATE: "1983-04-08",
    MORTGAGE30US: 12.82,
  },
  {
    DATE: "1983-04-15",
    MORTGAGE30US: 12.79,
  },
  {
    DATE: "1983-04-22",
    MORTGAGE30US: 12.75,
  },
  {
    DATE: "1983-04-29",
    MORTGAGE30US: 12.73,
  },
  {
    DATE: "1983-05-06",
    MORTGAGE30US: 12.71,
  },
  {
    DATE: "1983-05-13",
    MORTGAGE30US: 12.59,
  },
  {
    DATE: "1983-05-20",
    MORTGAGE30US: 12.55,
  },
  {
    DATE: "1983-05-27",
    MORTGAGE30US: 12.68,
  },
  {
    DATE: "1983-06-03",
    MORTGAGE30US: 12.74,
  },
  {
    DATE: "1983-06-10",
    MORTGAGE30US: 12.82,
  },
  {
    DATE: "1983-06-17",
    MORTGAGE30US: 12.96,
  },
  {
    DATE: "1983-06-24",
    MORTGAGE30US: 12.96,
  },
  {
    DATE: "1983-07-01",
    MORTGAGE30US: 13.08,
  },
  {
    DATE: "1983-07-08",
    MORTGAGE30US: 13.3,
  },
  {
    DATE: "1983-07-15",
    MORTGAGE30US: 13.5,
  },
  {
    DATE: "1983-07-22",
    MORTGAGE30US: 13.58,
  },
  {
    DATE: "1983-07-29",
    MORTGAGE30US: 13.65,
  },
  {
    DATE: "1983-08-05",
    MORTGAGE30US: 13.73,
  },
  {
    DATE: "1983-08-12",
    MORTGAGE30US: 13.84,
  },
  {
    DATE: "1983-08-19",
    MORTGAGE30US: 13.89,
  },
  {
    DATE: "1983-08-26",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1983-09-02",
    MORTGAGE30US: 13.77,
  },
  {
    DATE: "1983-09-09",
    MORTGAGE30US: 13.77,
  },
  {
    DATE: "1983-09-16",
    MORTGAGE30US: 13.72,
  },
  {
    DATE: "1983-09-23",
    MORTGAGE30US: 13.72,
  },
  {
    DATE: "1983-09-30",
    MORTGAGE30US: 13.65,
  },
  {
    DATE: "1983-10-07",
    MORTGAGE30US: 13.59,
  },
  {
    DATE: "1983-10-14",
    MORTGAGE30US: 13.6,
  },
  {
    DATE: "1983-10-21",
    MORTGAGE30US: 13.52,
  },
  {
    DATE: "1983-10-28",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1983-11-04",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1983-11-11",
    MORTGAGE30US: 13.47,
  },
  {
    DATE: "1983-11-18",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1983-11-25",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1983-12-02",
    MORTGAGE30US: 13.41,
  },
  {
    DATE: "1983-12-09",
    MORTGAGE30US: 13.38,
  },
  {
    DATE: "1983-12-16",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1983-12-23",
    MORTGAGE30US: 13.46,
  },
  {
    DATE: "1983-12-30",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1984-01-06",
    MORTGAGE30US: 13.43,
  },
  {
    DATE: "1984-01-13",
    MORTGAGE30US: 13.4,
  },
  {
    DATE: "1984-01-20",
    MORTGAGE30US: 13.35,
  },
  {
    DATE: "1984-01-27",
    MORTGAGE30US: 13.29,
  },
  {
    DATE: "1984-02-03",
    MORTGAGE30US: 13.26,
  },
  {
    DATE: "1984-02-10",
    MORTGAGE30US: 13.23,
  },
  {
    DATE: "1984-02-17",
    MORTGAGE30US: 13.19,
  },
  {
    DATE: "1984-02-24",
    MORTGAGE30US: 13.25,
  },
  {
    DATE: "1984-03-02",
    MORTGAGE30US: 13.23,
  },
  {
    DATE: "1984-03-09",
    MORTGAGE30US: 13.3,
  },
  {
    DATE: "1984-03-16",
    MORTGAGE30US: 13.37,
  },
  {
    DATE: "1984-03-23",
    MORTGAGE30US: 13.48,
  },
  {
    DATE: "1984-03-30",
    MORTGAGE30US: 13.55,
  },
  {
    DATE: "1984-04-06",
    MORTGAGE30US: 13.63,
  },
  {
    DATE: "1984-04-13",
    MORTGAGE30US: 13.58,
  },
  {
    DATE: "1984-04-20",
    MORTGAGE30US: 13.67,
  },
  {
    DATE: "1984-04-27",
    MORTGAGE30US: 13.73,
  },
  {
    DATE: "1984-05-04",
    MORTGAGE30US: 13.78,
  },
  {
    DATE: "1984-05-11",
    MORTGAGE30US: 13.87,
  },
  {
    DATE: "1984-05-18",
    MORTGAGE30US: 14.04,
  },
  {
    DATE: "1984-05-25",
    MORTGAGE30US: 14.08,
  },
  {
    DATE: "1984-06-01",
    MORTGAGE30US: 14.29,
  },
  {
    DATE: "1984-06-08",
    MORTGAGE30US: 14.33,
  },
  {
    DATE: "1984-06-15",
    MORTGAGE30US: 14.47,
  },
  {
    DATE: "1984-06-22",
    MORTGAGE30US: 14.49,
  },
  {
    DATE: "1984-06-29",
    MORTGAGE30US: 14.5,
  },
  {
    DATE: "1984-07-06",
    MORTGAGE30US: 14.66,
  },
  {
    DATE: "1984-07-13",
    MORTGAGE30US: 14.68,
  },
  {
    DATE: "1984-07-20",
    MORTGAGE30US: 14.66,
  },
  {
    DATE: "1984-07-27",
    MORTGAGE30US: 14.67,
  },
  {
    DATE: "1984-08-03",
    MORTGAGE30US: 14.68,
  },
  {
    DATE: "1984-08-10",
    MORTGAGE30US: 14.54,
  },
  {
    DATE: "1984-08-17",
    MORTGAGE30US: 14.39,
  },
  {
    DATE: "1984-08-24",
    MORTGAGE30US: 14.36,
  },
  {
    DATE: "1984-08-31",
    MORTGAGE30US: 14.38,
  },
  {
    DATE: "1984-09-07",
    MORTGAGE30US: 14.42,
  },
  {
    DATE: "1984-09-14",
    MORTGAGE30US: 14.43,
  },
  {
    DATE: "1984-09-21",
    MORTGAGE30US: 14.29,
  },
  {
    DATE: "1984-09-28",
    MORTGAGE30US: 14.26,
  },
  {
    DATE: "1984-10-05",
    MORTGAGE30US: 14.18,
  },
  {
    DATE: "1984-10-12",
    MORTGAGE30US: 14.19,
  },
  {
    DATE: "1984-10-19",
    MORTGAGE30US: 14.1,
  },
  {
    DATE: "1984-10-26",
    MORTGAGE30US: 14.05,
  },
  {
    DATE: "1984-11-02",
    MORTGAGE30US: 13.85,
  },
  {
    DATE: "1984-11-09",
    MORTGAGE30US: 13.74,
  },
  {
    DATE: "1984-11-16",
    MORTGAGE30US: 13.63,
  },
  {
    DATE: "1984-11-23",
    MORTGAGE30US: 13.55,
  },
  {
    DATE: "1984-11-30",
    MORTGAGE30US: 13.42,
  },
  {
    DATE: "1984-12-07",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1984-12-14",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1984-12-21",
    MORTGAGE30US: 13.18,
  },
  {
    DATE: "1984-12-28",
    MORTGAGE30US: 13.14,
  },
  {
    DATE: "1985-01-04",
    MORTGAGE30US: 13.1,
  },
  {
    DATE: "1985-01-11",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1985-01-18",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1985-01-25",
    MORTGAGE30US: 12.96,
  },
  {
    DATE: "1985-02-01",
    MORTGAGE30US: 12.93,
  },
  {
    DATE: "1985-02-08",
    MORTGAGE30US: 12.91,
  },
  {
    DATE: "1985-02-15",
    MORTGAGE30US: 12.9,
  },
  {
    DATE: "1985-02-22",
    MORTGAGE30US: 12.94,
  },
  {
    DATE: "1985-03-01",
    MORTGAGE30US: 13.02,
  },
  {
    DATE: "1985-03-08",
    MORTGAGE30US: 13.1,
  },
  {
    DATE: "1985-03-15",
    MORTGAGE30US: 13.2,
  },
  {
    DATE: "1985-03-22",
    MORTGAGE30US: 13.24,
  },
  {
    DATE: "1985-03-29",
    MORTGAGE30US: 13.29,
  },
  {
    DATE: "1985-04-05",
    MORTGAGE30US: 13.27,
  },
  {
    DATE: "1985-04-12",
    MORTGAGE30US: 13.23,
  },
  {
    DATE: "1985-04-19",
    MORTGAGE30US: 13.16,
  },
  {
    DATE: "1985-04-26",
    MORTGAGE30US: 13.12,
  },
  {
    DATE: "1985-05-03",
    MORTGAGE30US: 13.07,
  },
  {
    DATE: "1985-05-10",
    MORTGAGE30US: 13.02,
  },
  {
    DATE: "1985-05-17",
    MORTGAGE30US: 12.94,
  },
  {
    DATE: "1985-05-24",
    MORTGAGE30US: 12.83,
  },
  {
    DATE: "1985-05-31",
    MORTGAGE30US: 12.71,
  },
  {
    DATE: "1985-06-07",
    MORTGAGE30US: 12.39,
  },
  {
    DATE: "1985-06-14",
    MORTGAGE30US: 12.27,
  },
  {
    DATE: "1985-06-21",
    MORTGAGE30US: 12.05,
  },
  {
    DATE: "1985-06-28",
    MORTGAGE30US: 12.15,
  },
  {
    DATE: "1985-07-05",
    MORTGAGE30US: 12.13,
  },
  {
    DATE: "1985-07-12",
    MORTGAGE30US: 12.03,
  },
  {
    DATE: "1985-07-19",
    MORTGAGE30US: 11.94,
  },
  {
    DATE: "1985-07-26",
    MORTGAGE30US: 12.03,
  },
  {
    DATE: "1985-08-02",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-08-09",
    MORTGAGE30US: 12.23,
  },
  {
    DATE: "1985-08-16",
    MORTGAGE30US: 12.24,
  },
  {
    DATE: "1985-08-23",
    MORTGAGE30US: 12.18,
  },
  {
    DATE: "1985-08-30",
    MORTGAGE30US: 12.11,
  },
  {
    DATE: "1985-09-06",
    MORTGAGE30US: 12.15,
  },
  {
    DATE: "1985-09-13",
    MORTGAGE30US: 12.24,
  },
  {
    DATE: "1985-09-20",
    MORTGAGE30US: 12.21,
  },
  {
    DATE: "1985-09-27",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-10-04",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-10-11",
    MORTGAGE30US: 12.17,
  },
  {
    DATE: "1985-10-18",
    MORTGAGE30US: 12.13,
  },
  {
    DATE: "1985-10-25",
    MORTGAGE30US: 12.07,
  },
  {
    DATE: "1985-11-01",
    MORTGAGE30US: 12.01,
  },
  {
    DATE: "1985-11-08",
    MORTGAGE30US: 11.9,
  },
  {
    DATE: "1985-11-15",
    MORTGAGE30US: 11.79,
  },
  {
    DATE: "1985-11-22",
    MORTGAGE30US: 11.64,
  },
  {
    DATE: "1985-11-29",
    MORTGAGE30US: 11.58,
  },
  {
    DATE: "1985-12-06",
    MORTGAGE30US: 11.5,
  },
  {
    DATE: "1985-12-13",
    MORTGAGE30US: 11.31,
  },
  {
    DATE: "1985-12-20",
    MORTGAGE30US: 11.14,
  },
  {
    DATE: "1985-12-27",
    MORTGAGE30US: 11.09,
  },
  {
    DATE: "1986-01-03",
    MORTGAGE30US: 10.81,
  },
  {
    DATE: "1986-01-10",
    MORTGAGE30US: 10.77,
  },
  {
    DATE: "1986-01-17",
    MORTGAGE30US: 10.99,
  },
  {
    DATE: "1986-01-24",
    MORTGAGE30US: 10.97,
  },
  {
    DATE: "1986-01-31",
    MORTGAGE30US: 10.89,
  },
  {
    DATE: "1986-02-07",
    MORTGAGE30US: 10.85,
  },
  {
    DATE: "1986-02-14",
    MORTGAGE30US: 10.8,
  },
  {
    DATE: "1986-02-21",
    MORTGAGE30US: 10.68,
  },
  {
    DATE: "1986-02-28",
    MORTGAGE30US: 10.51,
  },
  {
    DATE: "1986-03-07",
    MORTGAGE30US: 10.2,
  },
  {
    DATE: "1986-03-14",
    MORTGAGE30US: 10.01,
  },
  {
    DATE: "1986-03-21",
    MORTGAGE30US: 10.01,
  },
  {
    DATE: "1986-03-28",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1986-04-04",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1986-04-11",
    MORTGAGE30US: 9.98,
  },
  {
    DATE: "1986-04-18",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1986-04-25",
    MORTGAGE30US: 9.86,
  },
  {
    DATE: "1986-05-02",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1986-05-09",
    MORTGAGE30US: 10,
  },
  {
    DATE: "1986-05-16",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1986-05-23",
    MORTGAGE30US: 10.36,
  },
  {
    DATE: "1986-05-30",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1986-06-06",
    MORTGAGE30US: 10.74,
  },
  {
    DATE: "1986-06-13",
    MORTGAGE30US: 10.76,
  },
  {
    DATE: "1986-06-20",
    MORTGAGE30US: 10.61,
  },
  {
    DATE: "1986-06-27",
    MORTGAGE30US: 10.62,
  },
  {
    DATE: "1986-07-04",
    MORTGAGE30US: 10.61,
  },
  {
    DATE: "1986-07-11",
    MORTGAGE30US: 10.59,
  },
  {
    DATE: "1986-07-18",
    MORTGAGE30US: 10.43,
  },
  {
    DATE: "1986-07-25",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1986-08-01",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1986-08-08",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1986-08-15",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1986-08-22",
    MORTGAGE30US: 10.04,
  },
  {
    DATE: "1986-08-29",
    MORTGAGE30US: 9.93,
  },
  {
    DATE: "1986-09-05",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1986-09-12",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1986-09-19",
    MORTGAGE30US: 10.07,
  },
  {
    DATE: "1986-09-26",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1986-10-03",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1986-10-10",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1986-10-17",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1986-10-24",
    MORTGAGE30US: 9.95,
  },
  {
    DATE: "1986-10-31",
    MORTGAGE30US: 9.89,
  },
  {
    DATE: "1986-11-07",
    MORTGAGE30US: 9.83,
  },
  {
    DATE: "1986-11-14",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1986-11-21",
    MORTGAGE30US: 9.64,
  },
  {
    DATE: "1986-11-28",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1986-12-05",
    MORTGAGE30US: 9.3,
  },
  {
    DATE: "1986-12-12",
    MORTGAGE30US: 9.35,
  },
  {
    DATE: "1986-12-19",
    MORTGAGE30US: 9.3,
  },
  {
    DATE: "1986-12-26",
    MORTGAGE30US: 9.29,
  },
  {
    DATE: "1987-01-02",
    MORTGAGE30US: 9.37,
  },
  {
    DATE: "1987-01-09",
    MORTGAGE30US: 9.32,
  },
  {
    DATE: "1987-01-16",
    MORTGAGE30US: 9.21,
  },
  {
    DATE: "1987-01-23",
    MORTGAGE30US: 9.04,
  },
  {
    DATE: "1987-01-30",
    MORTGAGE30US: 9.08,
  },
  {
    DATE: "1987-02-06",
    MORTGAGE30US: 9.06,
  },
  {
    DATE: "1987-02-13",
    MORTGAGE30US: 9.09,
  },
  {
    DATE: "1987-02-20",
    MORTGAGE30US: 9.11,
  },
  {
    DATE: "1987-02-27",
    MORTGAGE30US: 9.07,
  },
  {
    DATE: "1987-03-06",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1987-03-13",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1987-03-20",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1987-03-27",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1987-04-03",
    MORTGAGE30US: 9.26,
  },
  {
    DATE: "1987-04-10",
    MORTGAGE30US: 9.43,
  },
  {
    DATE: "1987-04-17",
    MORTGAGE30US: 10.27,
  },
  {
    DATE: "1987-04-24",
    MORTGAGE30US: 10.37,
  },
  {
    DATE: "1987-05-01",
    MORTGAGE30US: 10.47,
  },
  {
    DATE: "1987-05-08",
    MORTGAGE30US: 10.52,
  },
  {
    DATE: "1987-05-15",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1987-05-22",
    MORTGAGE30US: 10.81,
  },
  {
    DATE: "1987-05-29",
    MORTGAGE30US: 10.7,
  },
  {
    DATE: "1987-06-05",
    MORTGAGE30US: 10.7,
  },
  {
    DATE: "1987-06-12",
    MORTGAGE30US: 10.66,
  },
  {
    DATE: "1987-06-19",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1987-06-26",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1987-07-03",
    MORTGAGE30US: 10.36,
  },
  {
    DATE: "1987-07-10",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1987-07-17",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1987-07-24",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1987-07-31",
    MORTGAGE30US: 10.27,
  },
  {
    DATE: "1987-08-07",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1987-08-14",
    MORTGAGE30US: 10.34,
  },
  {
    DATE: "1987-08-21",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1987-08-28",
    MORTGAGE30US: 10.33,
  },
  {
    DATE: "1987-09-04",
    MORTGAGE30US: 10.63,
  },
  {
    DATE: "1987-09-11",
    MORTGAGE30US: 10.91,
  },
  {
    DATE: "1987-09-18",
    MORTGAGE30US: 10.99,
  },
  {
    DATE: "1987-09-25",
    MORTGAGE30US: 11.02,
  },
  {
    DATE: "1987-10-02",
    MORTGAGE30US: 11.18,
  },
  {
    DATE: "1987-10-09",
    MORTGAGE30US: 11.21,
  },
  {
    DATE: "1987-10-16",
    MORTGAGE30US: 11.58,
  },
  {
    DATE: "1987-10-23",
    MORTGAGE30US: 11.36,
  },
  {
    DATE: "1987-10-30",
    MORTGAGE30US: 10.97,
  },
  {
    DATE: "1987-11-06",
    MORTGAGE30US: 10.79,
  },
  {
    DATE: "1987-11-13",
    MORTGAGE30US: 10.66,
  },
  {
    DATE: "1987-11-20",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1987-11-27",
    MORTGAGE30US: 10.55,
  },
  {
    DATE: "1987-12-04",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1987-12-11",
    MORTGAGE30US: 10.66,
  },
  {
    DATE: "1987-12-18",
    MORTGAGE30US: 10.69,
  },
  {
    DATE: "1987-12-25",
    MORTGAGE30US: 10.64,
  },
  {
    DATE: "1987-12-31",
    MORTGAGE30US: 10.61,
  },
  {
    DATE: "1988-01-08",
    MORTGAGE30US: 10.5,
  },
  {
    DATE: "1988-01-15",
    MORTGAGE30US: 10.53,
  },
  {
    DATE: "1988-01-22",
    MORTGAGE30US: 10.34,
  },
  {
    DATE: "1988-01-29",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1988-02-05",
    MORTGAGE30US: 9.94,
  },
  {
    DATE: "1988-02-12",
    MORTGAGE30US: 9.84,
  },
  {
    DATE: "1988-02-19",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1988-02-26",
    MORTGAGE30US: 9.87,
  },
  {
    DATE: "1988-03-04",
    MORTGAGE30US: 9.85,
  },
  {
    DATE: "1988-03-11",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1988-03-18",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1988-03-25",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1988-04-01",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1988-04-08",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1988-04-15",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1988-04-22",
    MORTGAGE30US: 10.3,
  },
  {
    DATE: "1988-04-29",
    MORTGAGE30US: 10.28,
  },
  {
    DATE: "1988-05-06",
    MORTGAGE30US: 10.32,
  },
  {
    DATE: "1988-05-13",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-05-20",
    MORTGAGE30US: 10.52,
  },
  {
    DATE: "1988-05-27",
    MORTGAGE30US: 10.58,
  },
  {
    DATE: "1988-06-03",
    MORTGAGE30US: 10.58,
  },
  {
    DATE: "1988-06-10",
    MORTGAGE30US: 10.51,
  },
  {
    DATE: "1988-06-17",
    MORTGAGE30US: 10.35,
  },
  {
    DATE: "1988-06-24",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-07-01",
    MORTGAGE30US: 10.39,
  },
  {
    DATE: "1988-07-08",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1988-07-15",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1988-07-22",
    MORTGAGE30US: 10.46,
  },
  {
    DATE: "1988-07-29",
    MORTGAGE30US: 10.49,
  },
  {
    DATE: "1988-08-05",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1988-08-12",
    MORTGAGE30US: 10.57,
  },
  {
    DATE: "1988-08-19",
    MORTGAGE30US: 10.71,
  },
  {
    DATE: "1988-08-26",
    MORTGAGE30US: 10.67,
  },
  {
    DATE: "1988-09-02",
    MORTGAGE30US: 10.65,
  },
  {
    DATE: "1988-09-09",
    MORTGAGE30US: 10.53,
  },
  {
    DATE: "1988-09-16",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-09-23",
    MORTGAGE30US: 10.4,
  },
  {
    DATE: "1988-09-30",
    MORTGAGE30US: 10.42,
  },
  {
    DATE: "1988-10-07",
    MORTGAGE30US: 10.38,
  },
  {
    DATE: "1988-10-14",
    MORTGAGE30US: 10.33,
  },
  {
    DATE: "1988-10-21",
    MORTGAGE30US: 10.28,
  },
  {
    DATE: "1988-10-28",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1988-11-04",
    MORTGAGE30US: 10.12,
  },
  {
    DATE: "1988-11-11",
    MORTGAGE30US: 10.24,
  },
  {
    DATE: "1988-11-18",
    MORTGAGE30US: 10.31,
  },
  {
    DATE: "1988-11-25",
    MORTGAGE30US: 10.39,
  },
  {
    DATE: "1988-12-02",
    MORTGAGE30US: 10.44,
  },
  {
    DATE: "1988-12-09",
    MORTGAGE30US: 10.46,
  },
  {
    DATE: "1988-12-16",
    MORTGAGE30US: 10.71,
  },
  {
    DATE: "1988-12-23",
    MORTGAGE30US: 10.68,
  },
  {
    DATE: "1988-12-30",
    MORTGAGE30US: 10.77,
  },
  {
    DATE: "1989-01-06",
    MORTGAGE30US: 10.8,
  },
  {
    DATE: "1989-01-13",
    MORTGAGE30US: 10.81,
  },
  {
    DATE: "1989-01-20",
    MORTGAGE30US: 10.71,
  },
  {
    DATE: "1989-01-27",
    MORTGAGE30US: 10.6,
  },
  {
    DATE: "1989-02-03",
    MORTGAGE30US: 10.55,
  },
  {
    DATE: "1989-02-10",
    MORTGAGE30US: 10.56,
  },
  {
    DATE: "1989-02-17",
    MORTGAGE30US: 10.69,
  },
  {
    DATE: "1989-02-24",
    MORTGAGE30US: 10.78,
  },
  {
    DATE: "1989-03-03",
    MORTGAGE30US: 10.91,
  },
  {
    DATE: "1989-03-10",
    MORTGAGE30US: 10.86,
  },
  {
    DATE: "1989-03-17",
    MORTGAGE30US: 10.98,
  },
  {
    DATE: "1989-03-24",
    MORTGAGE30US: 11.22,
  },
  {
    DATE: "1989-03-31",
    MORTGAGE30US: 11.19,
  },
  {
    DATE: "1989-04-07",
    MORTGAGE30US: 11.07,
  },
  {
    DATE: "1989-04-14",
    MORTGAGE30US: 11.11,
  },
  {
    DATE: "1989-04-21",
    MORTGAGE30US: 10.99,
  },
  {
    DATE: "1989-04-28",
    MORTGAGE30US: 11.03,
  },
  {
    DATE: "1989-05-05",
    MORTGAGE30US: 10.97,
  },
  {
    DATE: "1989-05-12",
    MORTGAGE30US: 10.93,
  },
  {
    DATE: "1989-05-19",
    MORTGAGE30US: 10.69,
  },
  {
    DATE: "1989-05-26",
    MORTGAGE30US: 10.5,
  },
  {
    DATE: "1989-06-02",
    MORTGAGE30US: 10.48,
  },
  {
    DATE: "1989-06-09",
    MORTGAGE30US: 10.2,
  },
  {
    DATE: "1989-06-16",
    MORTGAGE30US: 10.04,
  },
  {
    DATE: "1989-06-23",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1989-06-30",
    MORTGAGE30US: 10.07,
  },
  {
    DATE: "1989-07-07",
    MORTGAGE30US: 10.03,
  },
  {
    DATE: "1989-07-14",
    MORTGAGE30US: 9.82,
  },
  {
    DATE: "1989-07-21",
    MORTGAGE30US: 9.87,
  },
  {
    DATE: "1989-07-28",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1989-08-04",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1989-08-11",
    MORTGAGE30US: 9.96,
  },
  {
    DATE: "1989-08-18",
    MORTGAGE30US: 10.09,
  },
  {
    DATE: "1989-08-25",
    MORTGAGE30US: 10.21,
  },
  {
    DATE: "1989-09-01",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1989-09-08",
    MORTGAGE30US: 10.17,
  },
  {
    DATE: "1989-09-15",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1989-09-22",
    MORTGAGE30US: 10.03,
  },
  {
    DATE: "1989-09-29",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1989-10-06",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1989-10-13",
    MORTGAGE30US: 9.95,
  },
  {
    DATE: "1989-10-20",
    MORTGAGE30US: 9.92,
  },
  {
    DATE: "1989-10-27",
    MORTGAGE30US: 9.82,
  },
  {
    DATE: "1989-11-03",
    MORTGAGE30US: 9.82,
  },
  {
    DATE: "1989-11-10",
    MORTGAGE30US: 9.79,
  },
  {
    DATE: "1989-11-17",
    MORTGAGE30US: 9.72,
  },
  {
    DATE: "1989-11-24",
    MORTGAGE30US: 9.74,
  },
  {
    DATE: "1989-12-01",
    MORTGAGE30US: 9.74,
  },
  {
    DATE: "1989-12-08",
    MORTGAGE30US: 9.76,
  },
  {
    DATE: "1989-12-15",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1989-12-22",
    MORTGAGE30US: 9.69,
  },
  {
    DATE: "1989-12-29",
    MORTGAGE30US: 9.78,
  },
  {
    DATE: "1990-01-05",
    MORTGAGE30US: 9.83,
  },
  {
    DATE: "1990-01-12",
    MORTGAGE30US: 9.8,
  },
  {
    DATE: "1990-01-19",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1990-01-26",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1990-02-02",
    MORTGAGE30US: 10.17,
  },
  {
    DATE: "1990-02-09",
    MORTGAGE30US: 10.21,
  },
  {
    DATE: "1990-02-16",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1990-02-23",
    MORTGAGE30US: 10.31,
  },
  {
    DATE: "1990-03-02",
    MORTGAGE30US: 10.23,
  },
  {
    DATE: "1990-03-09",
    MORTGAGE30US: 10.29,
  },
  {
    DATE: "1990-03-16",
    MORTGAGE30US: 10.34,
  },
  {
    DATE: "1990-03-23",
    MORTGAGE30US: 10.26,
  },
  {
    DATE: "1990-03-30",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1990-04-06",
    MORTGAGE30US: 10.26,
  },
  {
    DATE: "1990-04-13",
    MORTGAGE30US: 10.25,
  },
  {
    DATE: "1990-04-20",
    MORTGAGE30US: 10.41,
  },
  {
    DATE: "1990-04-27",
    MORTGAGE30US: 10.56,
  },
  {
    DATE: "1990-05-04",
    MORTGAGE30US: 10.67,
  },
  {
    DATE: "1990-05-11",
    MORTGAGE30US: 10.54,
  },
  {
    DATE: "1990-05-18",
    MORTGAGE30US: 10.37,
  },
  {
    DATE: "1990-05-25",
    MORTGAGE30US: 10.33,
  },
  {
    DATE: "1990-06-01",
    MORTGAGE30US: 10.29,
  },
  {
    DATE: "1990-06-08",
    MORTGAGE30US: 10.1,
  },
  {
    DATE: "1990-06-15",
    MORTGAGE30US: 10.12,
  },
  {
    DATE: "1990-06-22",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1990-06-29",
    MORTGAGE30US: 10.15,
  },
  {
    DATE: "1990-07-06",
    MORTGAGE30US: 10.06,
  },
  {
    DATE: "1990-07-13",
    MORTGAGE30US: 10.11,
  },
  {
    DATE: "1990-07-20",
    MORTGAGE30US: 9.99,
  },
  {
    DATE: "1990-07-27",
    MORTGAGE30US: 9.98,
  },
  {
    DATE: "1990-08-03",
    MORTGAGE30US: 9.84,
  },
  {
    DATE: "1990-08-10",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1990-08-17",
    MORTGAGE30US: 10.05,
  },
  {
    DATE: "1990-08-24",
    MORTGAGE30US: 10.29,
  },
  {
    DATE: "1990-08-31",
    MORTGAGE30US: 10.24,
  },
  {
    DATE: "1990-09-07",
    MORTGAGE30US: 10.19,
  },
  {
    DATE: "1990-09-14",
    MORTGAGE30US: 10.13,
  },
  {
    DATE: "1990-09-21",
    MORTGAGE30US: 10.16,
  },
  {
    DATE: "1990-09-28",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1990-10-05",
    MORTGAGE30US: 10.08,
  },
  {
    DATE: "1990-10-12",
    MORTGAGE30US: 10.22,
  },
  {
    DATE: "1990-10-19",
    MORTGAGE30US: 10.24,
  },
  {
    DATE: "1990-10-26",
    MORTGAGE30US: 10.17,
  },
  {
    DATE: "1990-11-02",
    MORTGAGE30US: 10.13,
  },
  {
    DATE: "1990-11-09",
    MORTGAGE30US: 10.09,
  },
  {
    DATE: "1990-11-16",
    MORTGAGE30US: 10.02,
  },
  {
    DATE: "1990-11-23",
    MORTGAGE30US: 9.93,
  },
  {
    DATE: "1990-11-30",
    MORTGAGE30US: 9.9,
  },
  {
    DATE: "1990-12-07",
    MORTGAGE30US: 9.81,
  },
  {
    DATE: "1990-12-14",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1990-12-21",
    MORTGAGE30US: 9.64,
  },
  {
    DATE: "1990-12-28",
    MORTGAGE30US: 9.68,
  },
  {
    DATE: "1991-01-04",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1991-01-11",
    MORTGAGE30US: 9.63,
  },
  {
    DATE: "1991-01-18",
    MORTGAGE30US: 9.75,
  },
  {
    DATE: "1991-01-25",
    MORTGAGE30US: 9.61,
  },
  {
    DATE: "1991-02-01",
    MORTGAGE30US: 9.56,
  },
  {
    DATE: "1991-02-08",
    MORTGAGE30US: 9.36,
  },
  {
    DATE: "1991-02-15",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1991-02-22",
    MORTGAGE30US: 9.29,
  },
  {
    DATE: "1991-03-01",
    MORTGAGE30US: 9.4,
  },
  {
    DATE: "1991-03-08",
    MORTGAGE30US: 9.49,
  },
  {
    DATE: "1991-03-15",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1991-03-22",
    MORTGAGE30US: 9.59,
  },
  {
    DATE: "1991-03-29",
    MORTGAGE30US: 9.52,
  },
  {
    DATE: "1991-04-05",
    MORTGAGE30US: 9.49,
  },
  {
    DATE: "1991-04-12",
    MORTGAGE30US: 9.48,
  },
  {
    DATE: "1991-04-19",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-04-26",
    MORTGAGE30US: 9.53,
  },
  {
    DATE: "1991-05-03",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-05-10",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-05-17",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1991-05-24",
    MORTGAGE30US: 9.47,
  },
  {
    DATE: "1991-05-31",
    MORTGAGE30US: 9.45,
  },
  {
    DATE: "1991-06-07",
    MORTGAGE30US: 9.48,
  },
  {
    DATE: "1991-06-14",
    MORTGAGE30US: 9.66,
  },
  {
    DATE: "1991-06-21",
    MORTGAGE30US: 9.65,
  },
  {
    DATE: "1991-06-28",
    MORTGAGE30US: 9.67,
  },
  {
    DATE: "1991-07-05",
    MORTGAGE30US: 9.62,
  },
  {
    DATE: "1991-07-12",
    MORTGAGE30US: 9.64,
  },
  {
    DATE: "1991-07-19",
    MORTGAGE30US: 9.54,
  },
  {
    DATE: "1991-07-26",
    MORTGAGE30US: 9.5,
  },
  {
    DATE: "1991-08-02",
    MORTGAGE30US: 9.44,
  },
  {
    DATE: "1991-08-09",
    MORTGAGE30US: 9.27,
  },
  {
    DATE: "1991-08-16",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1991-08-23",
    MORTGAGE30US: 9.17,
  },
  {
    DATE: "1991-08-30",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1991-09-06",
    MORTGAGE30US: 9.14,
  },
  {
    DATE: "1991-09-13",
    MORTGAGE30US: 9.02,
  },
  {
    DATE: "1991-09-20",
    MORTGAGE30US: 8.95,
  },
  {
    DATE: "1991-09-27",
    MORTGAGE30US: 8.92,
  },
  {
    DATE: "1991-10-04",
    MORTGAGE30US: 8.87,
  },
  {
    DATE: "1991-10-11",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1991-10-18",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1991-10-25",
    MORTGAGE30US: 8.91,
  },
  {
    DATE: "1991-11-01",
    MORTGAGE30US: 8.78,
  },
  {
    DATE: "1991-11-08",
    MORTGAGE30US: 8.76,
  },
  {
    DATE: "1991-11-15",
    MORTGAGE30US: 8.69,
  },
  {
    DATE: "1991-11-22",
    MORTGAGE30US: 8.63,
  },
  {
    DATE: "1991-11-29",
    MORTGAGE30US: 8.7,
  },
  {
    DATE: "1991-12-06",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "1991-12-13",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1991-12-20",
    MORTGAGE30US: 8.49,
  },
  {
    DATE: "1991-12-27",
    MORTGAGE30US: 8.35,
  },
  {
    DATE: "1992-01-03",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "1992-01-10",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1992-01-17",
    MORTGAGE30US: 8.45,
  },
  {
    DATE: "1992-01-24",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1992-01-31",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1992-02-07",
    MORTGAGE30US: 8.67,
  },
  {
    DATE: "1992-02-14",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1992-02-21",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1992-02-28",
    MORTGAGE30US: 8.83,
  },
  {
    DATE: "1992-03-06",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1992-03-13",
    MORTGAGE30US: 8.88,
  },
  {
    DATE: "1992-03-20",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1992-03-27",
    MORTGAGE30US: 8.98,
  },
  {
    DATE: "1992-04-03",
    MORTGAGE30US: 8.96,
  },
  {
    DATE: "1992-04-10",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1992-04-17",
    MORTGAGE30US: 8.76,
  },
  {
    DATE: "1992-04-24",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1992-05-01",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1992-05-08",
    MORTGAGE30US: 8.75,
  },
  {
    DATE: "1992-05-15",
    MORTGAGE30US: 8.64,
  },
  {
    DATE: "1992-05-22",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1992-05-29",
    MORTGAGE30US: 8.6,
  },
  {
    DATE: "1992-06-05",
    MORTGAGE30US: 8.59,
  },
  {
    DATE: "1992-06-12",
    MORTGAGE30US: 8.54,
  },
  {
    DATE: "1992-06-19",
    MORTGAGE30US: 8.48,
  },
  {
    DATE: "1992-06-26",
    MORTGAGE30US: 8.43,
  },
  {
    DATE: "1992-07-03",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1992-07-10",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "1992-07-17",
    MORTGAGE30US: 8.09,
  },
  {
    DATE: "1992-07-24",
    MORTGAGE30US: 8.08,
  },
  {
    DATE: "1992-07-31",
    MORTGAGE30US: 8.05,
  },
  {
    DATE: "1992-08-07",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "1992-08-14",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "1992-08-21",
    MORTGAGE30US: 7.87,
  },
  {
    DATE: "1992-08-28",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1992-09-04",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1992-09-11",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1992-09-18",
    MORTGAGE30US: 7.89,
  },
  {
    DATE: "1992-09-24",
    MORTGAGE30US: 8.02,
  },
  {
    DATE: "1992-10-02",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1992-10-09",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1992-10-16",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "1992-10-23",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1992-10-30",
    MORTGAGE30US: 8.21,
  },
  {
    DATE: "1992-11-06",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1992-11-13",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "1992-11-20",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "1992-11-27",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1992-12-04",
    MORTGAGE30US: 8.34,
  },
  {
    DATE: "1992-12-11",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1992-12-18",
    MORTGAGE30US: 8.19,
  },
  {
    DATE: "1992-12-25",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "1992-12-31",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "1993-01-08",
    MORTGAGE30US: 8.07,
  },
  {
    DATE: "1993-01-15",
    MORTGAGE30US: 8.04,
  },
  {
    DATE: "1993-01-22",
    MORTGAGE30US: 8,
  },
  {
    DATE: "1993-01-29",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1993-02-05",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1993-02-12",
    MORTGAGE30US: 7.75,
  },
  {
    DATE: "1993-02-19",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1993-02-26",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1993-03-05",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1993-03-12",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1993-03-19",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1993-03-26",
    MORTGAGE30US: 7.5,
  },
  {
    DATE: "1993-04-02",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1993-04-09",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1993-04-16",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1993-04-23",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1993-04-30",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1993-05-07",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1993-05-14",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "1993-05-21",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1993-05-28",
    MORTGAGE30US: 7.5,
  },
  {
    DATE: "1993-06-04",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1993-06-11",
    MORTGAGE30US: 7.48,
  },
  {
    DATE: "1993-06-18",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1993-06-25",
    MORTGAGE30US: 7.34,
  },
  {
    DATE: "1993-07-02",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1993-07-09",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "1993-07-16",
    MORTGAGE30US: 7.16,
  },
  {
    DATE: "1993-07-23",
    MORTGAGE30US: 7.2,
  },
  {
    DATE: "1993-07-30",
    MORTGAGE30US: 7.25,
  },
  {
    DATE: "1993-08-06",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "1993-08-13",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1993-08-20",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "1993-08-27",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1993-09-03",
    MORTGAGE30US: 6.93,
  },
  {
    DATE: "1993-09-10",
    MORTGAGE30US: 6.82,
  },
  {
    DATE: "1993-09-17",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "1993-09-24",
    MORTGAGE30US: 6.95,
  },
  {
    DATE: "1993-10-01",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1993-10-08",
    MORTGAGE30US: 6.87,
  },
  {
    DATE: "1993-10-15",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "1993-10-22",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "1993-10-29",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "1993-11-05",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1993-11-12",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "1993-11-19",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1993-11-26",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1993-12-04",
    MORTGAGE30US: 7.25,
  },
  {
    DATE: "1993-12-10",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "1993-12-17",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1993-12-24",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1993-12-31",
    MORTGAGE30US: 7.13,
  },
  {
    DATE: "1994-01-07",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1994-01-14",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1994-01-21",
    MORTGAGE30US: 7.05,
  },
  {
    DATE: "1994-01-28",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1994-02-04",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1994-02-11",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "1994-02-18",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1994-02-25",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1994-03-04",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1994-03-11",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1994-03-18",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1994-03-25",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1994-04-01",
    MORTGAGE30US: 8.04,
  },
  {
    DATE: "1994-04-08",
    MORTGAGE30US: 8.47,
  },
  {
    DATE: "1994-04-15",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "1994-04-22",
    MORTGAGE30US: 8.49,
  },
  {
    DATE: "1994-04-29",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "1994-05-06",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1994-05-13",
    MORTGAGE30US: 8.77,
  },
  {
    DATE: "1994-05-20",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1994-05-27",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1994-06-03",
    MORTGAGE30US: 8.55,
  },
  {
    DATE: "1994-06-10",
    MORTGAGE30US: 8.25,
  },
  {
    DATE: "1994-06-17",
    MORTGAGE30US: 8.33,
  },
  {
    DATE: "1994-06-24",
    MORTGAGE30US: 8.46,
  },
  {
    DATE: "1994-07-01",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1994-07-08",
    MORTGAGE30US: 8.68,
  },
  {
    DATE: "1994-07-15",
    MORTGAGE30US: 8.72,
  },
  {
    DATE: "1994-07-22",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1994-07-29",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1994-08-05",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "1994-08-12",
    MORTGAGE30US: 8.57,
  },
  {
    DATE: "1994-08-19",
    MORTGAGE30US: 8.54,
  },
  {
    DATE: "1994-08-26",
    MORTGAGE30US: 8.56,
  },
  {
    DATE: "1994-09-02",
    MORTGAGE30US: 8.48,
  },
  {
    DATE: "1994-09-09",
    MORTGAGE30US: 8.51,
  },
  {
    DATE: "1994-09-16",
    MORTGAGE30US: 8.66,
  },
  {
    DATE: "1994-09-23",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1994-09-30",
    MORTGAGE30US: 8.82,
  },
  {
    DATE: "1994-10-07",
    MORTGAGE30US: 8.89,
  },
  {
    DATE: "1994-10-14",
    MORTGAGE30US: 8.93,
  },
  {
    DATE: "1994-10-21",
    MORTGAGE30US: 8.85,
  },
  {
    DATE: "1994-10-28",
    MORTGAGE30US: 9.03,
  },
  {
    DATE: "1994-11-04",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1994-11-11",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1994-11-18",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1994-11-25",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1994-12-02",
    MORTGAGE30US: 9.23,
  },
  {
    DATE: "1994-12-09",
    MORTGAGE30US: 9.15,
  },
  {
    DATE: "1994-12-16",
    MORTGAGE30US: 9.25,
  },
  {
    DATE: "1994-12-23",
    MORTGAGE30US: 9.18,
  },
  {
    DATE: "1994-12-30",
    MORTGAGE30US: 9.18,
  },
  {
    DATE: "1995-01-06",
    MORTGAGE30US: 9.22,
  },
  {
    DATE: "1995-01-13",
    MORTGAGE30US: 9.19,
  },
  {
    DATE: "1995-01-20",
    MORTGAGE30US: 9.05,
  },
  {
    DATE: "1995-01-27",
    MORTGAGE30US: 9.13,
  },
  {
    DATE: "1995-02-03",
    MORTGAGE30US: 8.94,
  },
  {
    DATE: "1995-02-10",
    MORTGAGE30US: 8.8,
  },
  {
    DATE: "1995-02-17",
    MORTGAGE30US: 8.84,
  },
  {
    DATE: "1995-02-24",
    MORTGAGE30US: 8.73,
  },
  {
    DATE: "1995-03-03",
    MORTGAGE30US: 8.53,
  },
  {
    DATE: "1995-03-10",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "1995-03-17",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "1995-03-24",
    MORTGAGE30US: 8.4,
  },
  {
    DATE: "1995-03-31",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "1995-04-07",
    MORTGAGE30US: 8.41,
  },
  {
    DATE: "1995-04-14",
    MORTGAGE30US: 8.37,
  },
  {
    DATE: "1995-04-21",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "1995-04-28",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "1995-05-05",
    MORTGAGE30US: 8.27,
  },
  {
    DATE: "1995-05-12",
    MORTGAGE30US: 7.87,
  },
  {
    DATE: "1995-05-19",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "1995-05-26",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1995-06-02",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1995-06-09",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1995-06-16",
    MORTGAGE30US: 7.55,
  },
  {
    DATE: "1995-06-23",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1995-06-30",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1995-07-07",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1995-07-14",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1995-07-21",
    MORTGAGE30US: 7.6,
  },
  {
    DATE: "1995-07-28",
    MORTGAGE30US: 7.79,
  },
  {
    DATE: "1995-08-04",
    MORTGAGE30US: 7.82,
  },
  {
    DATE: "1995-08-11",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1995-08-18",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1995-08-25",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1995-09-01",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1995-09-08",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1995-09-15",
    MORTGAGE30US: 7.6,
  },
  {
    DATE: "1995-09-22",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1995-09-29",
    MORTGAGE30US: 7.62,
  },
  {
    DATE: "1995-10-06",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1995-10-13",
    MORTGAGE30US: 7.5,
  },
  {
    DATE: "1995-10-20",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1995-10-27",
    MORTGAGE30US: 7.45,
  },
  {
    DATE: "1995-11-03",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1995-11-09",
    MORTGAGE30US: 7.37,
  },
  {
    DATE: "1995-11-17",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1995-11-24",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1995-12-01",
    MORTGAGE30US: 7.33,
  },
  {
    DATE: "1995-12-08",
    MORTGAGE30US: 7.18,
  },
  {
    DATE: "1995-12-15",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1995-12-22",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1995-12-29",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1996-01-05",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-01-12",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1996-01-19",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-01-26",
    MORTGAGE30US: 7,
  },
  {
    DATE: "1996-02-02",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-02-09",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1996-02-16",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1996-02-23",
    MORTGAGE30US: 7.32,
  },
  {
    DATE: "1996-03-01",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1996-03-08",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1996-03-15",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "1996-03-22",
    MORTGAGE30US: 7.81,
  },
  {
    DATE: "1996-03-29",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1996-04-05",
    MORTGAGE30US: 7.78,
  },
  {
    DATE: "1996-04-12",
    MORTGAGE30US: 8.05,
  },
  {
    DATE: "1996-04-19",
    MORTGAGE30US: 7.95,
  },
  {
    DATE: "1996-04-26",
    MORTGAGE30US: 7.92,
  },
  {
    DATE: "1996-05-03",
    MORTGAGE30US: 7.99,
  },
  {
    DATE: "1996-05-10",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "1996-05-17",
    MORTGAGE30US: 8.08,
  },
  {
    DATE: "1996-05-24",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1996-05-31",
    MORTGAGE30US: 8.03,
  },
  {
    DATE: "1996-06-07",
    MORTGAGE30US: 8.3,
  },
  {
    DATE: "1996-06-14",
    MORTGAGE30US: 8.39,
  },
  {
    DATE: "1996-06-21",
    MORTGAGE30US: 8.3,
  },
  {
    DATE: "1996-06-28",
    MORTGAGE30US: 8.29,
  },
  {
    DATE: "1996-07-05",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "1996-07-12",
    MORTGAGE30US: 8.42,
  },
  {
    DATE: "1996-07-19",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1996-07-26",
    MORTGAGE30US: 8.19,
  },
  {
    DATE: "1996-08-02",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "1996-08-09",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1996-08-16",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1996-08-23",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1996-08-30",
    MORTGAGE30US: 8.09,
  },
  {
    DATE: "1996-09-06",
    MORTGAGE30US: 8.34,
  },
  {
    DATE: "1996-09-13",
    MORTGAGE30US: 8.28,
  },
  {
    DATE: "1996-09-20",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "1996-09-27",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "1996-10-04",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "1996-10-11",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1996-10-18",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1996-10-25",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1996-11-01",
    MORTGAGE30US: 7.78,
  },
  {
    DATE: "1996-11-08",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1996-11-15",
    MORTGAGE30US: 7.59,
  },
  {
    DATE: "1996-11-22",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1996-11-29",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1996-12-06",
    MORTGAGE30US: 7.44,
  },
  {
    DATE: "1996-12-13",
    MORTGAGE30US: 7.57,
  },
  {
    DATE: "1996-12-20",
    MORTGAGE30US: 7.74,
  },
  {
    DATE: "1996-12-27",
    MORTGAGE30US: 7.64,
  },
  {
    DATE: "1997-01-03",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1997-01-10",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1997-01-17",
    MORTGAGE30US: 7.87,
  },
  {
    DATE: "1997-01-24",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1997-01-31",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1997-02-07",
    MORTGAGE30US: 7.74,
  },
  {
    DATE: "1997-02-14",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1997-02-21",
    MORTGAGE30US: 7.56,
  },
  {
    DATE: "1997-02-28",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1997-03-07",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1997-03-14",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1997-03-21",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1997-03-28",
    MORTGAGE30US: 7.97,
  },
  {
    DATE: "1997-04-04",
    MORTGAGE30US: 8.18,
  },
  {
    DATE: "1997-04-11",
    MORTGAGE30US: 8.15,
  },
  {
    DATE: "1997-04-18",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "1997-04-25",
    MORTGAGE30US: 8.08,
  },
  {
    DATE: "1997-05-02",
    MORTGAGE30US: 8.01,
  },
  {
    DATE: "1997-05-09",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1997-05-16",
    MORTGAGE30US: 7.91,
  },
  {
    DATE: "1997-05-23",
    MORTGAGE30US: 7.92,
  },
  {
    DATE: "1997-05-30",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "1997-06-06",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1997-06-13",
    MORTGAGE30US: 7.72,
  },
  {
    DATE: "1997-06-20",
    MORTGAGE30US: 7.61,
  },
  {
    DATE: "1997-06-27",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1997-07-04",
    MORTGAGE30US: 7.62,
  },
  {
    DATE: "1997-07-11",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1997-07-18",
    MORTGAGE30US: 7.47,
  },
  {
    DATE: "1997-07-25",
    MORTGAGE30US: 7.43,
  },
  {
    DATE: "1997-08-01",
    MORTGAGE30US: 7.36,
  },
  {
    DATE: "1997-08-08",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1997-08-15",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "1997-08-22",
    MORTGAGE30US: 7.46,
  },
  {
    DATE: "1997-08-29",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1997-09-05",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1997-09-12",
    MORTGAGE30US: 7.53,
  },
  {
    DATE: "1997-09-19",
    MORTGAGE30US: 7.38,
  },
  {
    DATE: "1997-09-26",
    MORTGAGE30US: 7.28,
  },
  {
    DATE: "1997-10-03",
    MORTGAGE30US: 7.31,
  },
  {
    DATE: "1997-10-10",
    MORTGAGE30US: 7.26,
  },
  {
    DATE: "1997-10-17",
    MORTGAGE30US: 7.34,
  },
  {
    DATE: "1997-10-24",
    MORTGAGE30US: 7.35,
  },
  {
    DATE: "1997-10-31",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "1997-11-07",
    MORTGAGE30US: 7.24,
  },
  {
    DATE: "1997-11-14",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1997-11-21",
    MORTGAGE30US: 7.18,
  },
  {
    DATE: "1997-11-28",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1997-12-05",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1997-12-12",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1997-12-19",
    MORTGAGE30US: 7.07,
  },
  {
    DATE: "1997-12-26",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1998-01-02",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "1998-01-09",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-01-16",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1998-01-23",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1998-01-30",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "1998-02-06",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "1998-02-13",
    MORTGAGE30US: 7.06,
  },
  {
    DATE: "1998-02-20",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "1998-02-28",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "1998-03-06",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "1998-03-13",
    MORTGAGE30US: 7.16,
  },
  {
    DATE: "1998-03-20",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1998-03-27",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "1998-04-03",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1998-04-10",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "1998-04-17",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "1998-04-24",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "1998-05-01",
    MORTGAGE30US: 7.22,
  },
  {
    DATE: "1998-05-08",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "1998-05-15",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "1998-05-22",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "1998-05-29",
    MORTGAGE30US: 7.07,
  },
  {
    DATE: "1998-06-05",
    MORTGAGE30US: 7.05,
  },
  {
    DATE: "1998-06-12",
    MORTGAGE30US: 7.04,
  },
  {
    DATE: "1998-06-19",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-06-26",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "1998-07-03",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "1998-07-10",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "1998-07-17",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-07-24",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "1998-07-31",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "1998-08-07",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "1998-08-14",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "1998-08-21",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "1998-08-28",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "1998-09-04",
    MORTGAGE30US: 6.82,
  },
  {
    DATE: "1998-09-11",
    MORTGAGE30US: 6.77,
  },
  {
    DATE: "1998-09-18",
    MORTGAGE30US: 6.66,
  },
  {
    DATE: "1998-09-25",
    MORTGAGE30US: 6.64,
  },
  {
    DATE: "1998-10-02",
    MORTGAGE30US: 6.6,
  },
  {
    DATE: "1998-10-09",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "1998-10-16",
    MORTGAGE30US: 6.9,
  },
  {
    DATE: "1998-10-23",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "1998-10-30",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "1998-11-06",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1998-11-13",
    MORTGAGE30US: 6.93,
  },
  {
    DATE: "1998-11-20",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "1998-11-27",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "1998-12-04",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "1998-12-11",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "1998-12-18",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "1998-12-25",
    MORTGAGE30US: 6.77,
  },
  {
    DATE: "1998-12-31",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "1999-01-08",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "1999-01-15",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "1999-01-22",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "1999-01-29",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "1999-02-05",
    MORTGAGE30US: 6.75,
  },
  {
    DATE: "1999-02-12",
    MORTGAGE30US: 6.77,
  },
  {
    DATE: "1999-02-19",
    MORTGAGE30US: 6.82,
  },
  {
    DATE: "1999-02-26",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "1999-03-05",
    MORTGAGE30US: 7.06,
  },
  {
    DATE: "1999-03-12",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "1999-03-19",
    MORTGAGE30US: 7.01,
  },
  {
    DATE: "1999-03-26",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "1999-04-02",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "1999-04-09",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "1999-04-16",
    MORTGAGE30US: 6.87,
  },
  {
    DATE: "1999-04-23",
    MORTGAGE30US: 6.88,
  },
  {
    DATE: "1999-04-30",
    MORTGAGE30US: 6.93,
  },
  {
    DATE: "1999-05-07",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "1999-05-14",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "1999-05-21",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1999-05-28",
    MORTGAGE30US: 7.23,
  },
  {
    DATE: "1999-06-04",
    MORTGAGE30US: 7.41,
  },
  {
    DATE: "1999-06-11",
    MORTGAGE30US: 7.51,
  },
  {
    DATE: "1999-06-18",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1999-06-25",
    MORTGAGE30US: 7.63,
  },
  {
    DATE: "1999-07-02",
    MORTGAGE30US: 7.71,
  },
  {
    DATE: "1999-07-09",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "1999-07-16",
    MORTGAGE30US: 7.58,
  },
  {
    DATE: "1999-07-23",
    MORTGAGE30US: 7.52,
  },
  {
    DATE: "1999-07-30",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1999-08-06",
    MORTGAGE30US: 7.89,
  },
  {
    DATE: "1999-08-13",
    MORTGAGE30US: 8.15,
  },
  {
    DATE: "1999-08-20",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1999-08-27",
    MORTGAGE30US: 7.8,
  },
  {
    DATE: "1999-09-03",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "1999-09-10",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "1999-09-17",
    MORTGAGE30US: 7.82,
  },
  {
    DATE: "1999-09-24",
    MORTGAGE30US: 7.76,
  },
  {
    DATE: "1999-10-01",
    MORTGAGE30US: 7.7,
  },
  {
    DATE: "1999-10-08",
    MORTGAGE30US: 7.82,
  },
  {
    DATE: "1999-10-15",
    MORTGAGE30US: 7.85,
  },
  {
    DATE: "1999-10-22",
    MORTGAGE30US: 7.93,
  },
  {
    DATE: "1999-10-29",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "1999-11-05",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1999-11-12",
    MORTGAGE30US: 7.67,
  },
  {
    DATE: "1999-11-19",
    MORTGAGE30US: 7.69,
  },
  {
    DATE: "1999-11-26",
    MORTGAGE30US: 7.75,
  },
  {
    DATE: "1999-12-03",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1999-12-10",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "1999-12-17",
    MORTGAGE30US: 7.86,
  },
  {
    DATE: "1999-12-24",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "1999-12-31",
    MORTGAGE30US: 8.06,
  },
  {
    DATE: "2000-01-07",
    MORTGAGE30US: 8.15,
  },
  {
    DATE: "2000-01-14",
    MORTGAGE30US: 8.18,
  },
  {
    DATE: "2000-01-21",
    MORTGAGE30US: 8.26,
  },
  {
    DATE: "2000-01-28",
    MORTGAGE30US: 8.25,
  },
  {
    DATE: "2000-02-04",
    MORTGAGE30US: 8.25,
  },
  {
    DATE: "2000-02-11",
    MORTGAGE30US: 8.36,
  },
  {
    DATE: "2000-02-18",
    MORTGAGE30US: 8.38,
  },
  {
    DATE: "2000-02-25",
    MORTGAGE30US: 8.31,
  },
  {
    DATE: "2000-03-03",
    MORTGAGE30US: 8.27,
  },
  {
    DATE: "2000-03-10",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "2000-03-17",
    MORTGAGE30US: 8.24,
  },
  {
    DATE: "2000-03-24",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "2000-03-31",
    MORTGAGE30US: 8.23,
  },
  {
    DATE: "2000-04-07",
    MORTGAGE30US: 8.2,
  },
  {
    DATE: "2000-04-14",
    MORTGAGE30US: 8.12,
  },
  {
    DATE: "2000-04-21",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "2000-04-28",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "2000-05-05",
    MORTGAGE30US: 8.28,
  },
  {
    DATE: "2000-05-12",
    MORTGAGE30US: 8.52,
  },
  {
    DATE: "2000-05-19",
    MORTGAGE30US: 8.64,
  },
  {
    DATE: "2000-05-26",
    MORTGAGE30US: 8.62,
  },
  {
    DATE: "2000-06-02",
    MORTGAGE30US: 8.54,
  },
  {
    DATE: "2000-06-09",
    MORTGAGE30US: 8.32,
  },
  {
    DATE: "2000-06-16",
    MORTGAGE30US: 8.22,
  },
  {
    DATE: "2000-06-23",
    MORTGAGE30US: 8.14,
  },
  {
    DATE: "2000-06-30",
    MORTGAGE30US: 8.22,
  },
  {
    DATE: "2000-07-07",
    MORTGAGE30US: 8.16,
  },
  {
    DATE: "2000-07-14",
    MORTGAGE30US: 8.09,
  },
  {
    DATE: "2000-07-21",
    MORTGAGE30US: 8.21,
  },
  {
    DATE: "2000-07-28",
    MORTGAGE30US: 8.13,
  },
  {
    DATE: "2000-08-04",
    MORTGAGE30US: 8.12,
  },
  {
    DATE: "2000-08-11",
    MORTGAGE30US: 8.04,
  },
  {
    DATE: "2000-08-18",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "2000-08-25",
    MORTGAGE30US: 7.99,
  },
  {
    DATE: "2000-09-01",
    MORTGAGE30US: 7.96,
  },
  {
    DATE: "2000-09-08",
    MORTGAGE30US: 7.94,
  },
  {
    DATE: "2000-09-15",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "2000-09-22",
    MORTGAGE30US: 7.9,
  },
  {
    DATE: "2000-09-29",
    MORTGAGE30US: 7.88,
  },
  {
    DATE: "2000-10-06",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "2000-10-13",
    MORTGAGE30US: 7.84,
  },
  {
    DATE: "2000-10-20",
    MORTGAGE30US: 7.83,
  },
  {
    DATE: "2000-10-27",
    MORTGAGE30US: 7.68,
  },
  {
    DATE: "2000-11-03",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "2000-11-10",
    MORTGAGE30US: 7.79,
  },
  {
    DATE: "2000-11-17",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "2000-11-24",
    MORTGAGE30US: 7.73,
  },
  {
    DATE: "2000-12-01",
    MORTGAGE30US: 7.65,
  },
  {
    DATE: "2000-12-08",
    MORTGAGE30US: 7.54,
  },
  {
    DATE: "2000-12-15",
    MORTGAGE30US: 7.42,
  },
  {
    DATE: "2000-12-22",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "2000-12-29",
    MORTGAGE30US: 7.13,
  },
  {
    DATE: "2001-01-05",
    MORTGAGE30US: 7.07,
  },
  {
    DATE: "2001-01-12",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2001-01-19",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "2001-01-26",
    MORTGAGE30US: 7.15,
  },
  {
    DATE: "2001-02-02",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "2001-02-09",
    MORTGAGE30US: 6.98,
  },
  {
    DATE: "2001-02-16",
    MORTGAGE30US: 7.01,
  },
  {
    DATE: "2001-02-23",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "2001-03-02",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "2001-03-09",
    MORTGAGE30US: 6.97,
  },
  {
    DATE: "2001-03-16",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2001-03-23",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2001-03-30",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "2001-04-06",
    MORTGAGE30US: 7.01,
  },
  {
    DATE: "2001-04-13",
    MORTGAGE30US: 7.04,
  },
  {
    DATE: "2001-04-20",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-04-27",
    MORTGAGE30US: 7.12,
  },
  {
    DATE: "2001-05-04",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-05-11",
    MORTGAGE30US: 7.1,
  },
  {
    DATE: "2001-05-18",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-05-25",
    MORTGAGE30US: 7.2,
  },
  {
    DATE: "2001-06-01",
    MORTGAGE30US: 7.24,
  },
  {
    DATE: "2001-06-08",
    MORTGAGE30US: 7.2,
  },
  {
    DATE: "2001-06-15",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2001-06-22",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "2001-06-29",
    MORTGAGE30US: 7.11,
  },
  {
    DATE: "2001-07-06",
    MORTGAGE30US: 7.19,
  },
  {
    DATE: "2001-07-13",
    MORTGAGE30US: 7.21,
  },
  {
    DATE: "2001-07-20",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2001-07-27",
    MORTGAGE30US: 7.03,
  },
  {
    DATE: "2001-08-03",
    MORTGAGE30US: 7,
  },
  {
    DATE: "2001-08-10",
    MORTGAGE30US: 7,
  },
  {
    DATE: "2001-08-17",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "2001-08-24",
    MORTGAGE30US: 6.91,
  },
  {
    DATE: "2001-08-31",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "2001-09-07",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2001-09-14",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "2001-09-21",
    MORTGAGE30US: 6.8,
  },
  {
    DATE: "2001-09-28",
    MORTGAGE30US: 6.72,
  },
  {
    DATE: "2001-10-05",
    MORTGAGE30US: 6.64,
  },
  {
    DATE: "2001-10-12",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2001-10-19",
    MORTGAGE30US: 6.61,
  },
  {
    DATE: "2001-10-26",
    MORTGAGE30US: 6.64,
  },
  {
    DATE: "2001-11-02",
    MORTGAGE30US: 6.56,
  },
  {
    DATE: "2001-11-09",
    MORTGAGE30US: 6.45,
  },
  {
    DATE: "2001-11-16",
    MORTGAGE30US: 6.51,
  },
  {
    DATE: "2001-11-23",
    MORTGAGE30US: 6.75,
  },
  {
    DATE: "2001-11-30",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "2001-12-07",
    MORTGAGE30US: 6.84,
  },
  {
    DATE: "2001-12-14",
    MORTGAGE30US: 7.09,
  },
  {
    DATE: "2001-12-21",
    MORTGAGE30US: 7.17,
  },
  {
    DATE: "2001-12-28",
    MORTGAGE30US: 7.16,
  },
  {
    DATE: "2002-01-04",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2002-01-11",
    MORTGAGE30US: 7.06,
  },
  {
    DATE: "2002-01-18",
    MORTGAGE30US: 6.83,
  },
  {
    DATE: "2002-01-25",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2002-02-01",
    MORTGAGE30US: 7.02,
  },
  {
    DATE: "2002-02-08",
    MORTGAGE30US: 6.88,
  },
  {
    DATE: "2002-02-15",
    MORTGAGE30US: 6.86,
  },
  {
    DATE: "2002-02-22",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2002-03-01",
    MORTGAGE30US: 6.8,
  },
  {
    DATE: "2002-03-08",
    MORTGAGE30US: 6.87,
  },
  {
    DATE: "2002-03-15",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2002-03-22",
    MORTGAGE30US: 7.14,
  },
  {
    DATE: "2002-03-29",
    MORTGAGE30US: 7.18,
  },
  {
    DATE: "2002-04-05",
    MORTGAGE30US: 7.13,
  },
  {
    DATE: "2002-04-12",
    MORTGAGE30US: 6.99,
  },
  {
    DATE: "2002-04-19",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "2002-04-26",
    MORTGAGE30US: 6.88,
  },
  {
    DATE: "2002-05-03",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "2002-05-10",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "2002-05-17",
    MORTGAGE30US: 6.89,
  },
  {
    DATE: "2002-05-24",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2002-05-31",
    MORTGAGE30US: 6.76,
  },
  {
    DATE: "2002-06-07",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2002-06-14",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2002-06-21",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2002-06-28",
    MORTGAGE30US: 6.55,
  },
  {
    DATE: "2002-07-05",
    MORTGAGE30US: 6.57,
  },
  {
    DATE: "2002-07-12",
    MORTGAGE30US: 6.54,
  },
  {
    DATE: "2002-07-19",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "2002-07-26",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2002-08-02",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2002-08-09",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2002-08-16",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2002-08-23",
    MORTGAGE30US: 6.27,
  },
  {
    DATE: "2002-08-30",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2002-09-06",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2002-09-13",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2002-09-20",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2002-09-27",
    MORTGAGE30US: 5.99,
  },
  {
    DATE: "2002-10-04",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2002-10-11",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2002-10-18",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2002-10-25",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2002-11-01",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2002-11-08",
    MORTGAGE30US: 6.11,
  },
  {
    DATE: "2002-11-15",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2002-11-22",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2002-11-29",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2002-12-06",
    MORTGAGE30US: 6.19,
  },
  {
    DATE: "2002-12-13",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2002-12-20",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2002-12-27",
    MORTGAGE30US: 5.93,
  },
  {
    DATE: "2003-01-03",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2003-01-10",
    MORTGAGE30US: 5.95,
  },
  {
    DATE: "2003-01-17",
    MORTGAGE30US: 5.97,
  },
  {
    DATE: "2003-01-24",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2003-01-31",
    MORTGAGE30US: 5.9,
  },
  {
    DATE: "2003-02-07",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2003-02-14",
    MORTGAGE30US: 5.86,
  },
  {
    DATE: "2003-02-21",
    MORTGAGE30US: 5.84,
  },
  {
    DATE: "2003-02-28",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-03-07",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2003-03-14",
    MORTGAGE30US: 5.61,
  },
  {
    DATE: "2003-03-21",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-03-28",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2003-04-04",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-04-11",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2003-04-18",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2003-04-25",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2003-05-02",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2003-05-09",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2003-05-16",
    MORTGAGE30US: 5.45,
  },
  {
    DATE: "2003-05-23",
    MORTGAGE30US: 5.34,
  },
  {
    DATE: "2003-05-30",
    MORTGAGE30US: 5.31,
  },
  {
    DATE: "2003-06-06",
    MORTGAGE30US: 5.26,
  },
  {
    DATE: "2003-06-13",
    MORTGAGE30US: 5.21,
  },
  {
    DATE: "2003-06-20",
    MORTGAGE30US: 5.21,
  },
  {
    DATE: "2003-06-27",
    MORTGAGE30US: 5.24,
  },
  {
    DATE: "2003-07-04",
    MORTGAGE30US: 5.4,
  },
  {
    DATE: "2003-07-11",
    MORTGAGE30US: 5.52,
  },
  {
    DATE: "2003-07-18",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2003-07-25",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2003-08-01",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2003-08-08",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2003-08-15",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2003-08-22",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2003-08-29",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2003-09-05",
    MORTGAGE30US: 6.44,
  },
  {
    DATE: "2003-09-12",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2003-09-19",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2003-09-26",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2003-10-03",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2003-10-10",
    MORTGAGE30US: 5.95,
  },
  {
    DATE: "2003-10-17",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2003-10-24",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2003-10-31",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2003-11-07",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2003-11-14",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2003-11-21",
    MORTGAGE30US: 5.83,
  },
  {
    DATE: "2003-11-28",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2003-12-05",
    MORTGAGE30US: 6.02,
  },
  {
    DATE: "2003-12-12",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2003-12-19",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2003-12-26",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2003-12-31",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2004-01-08",
    MORTGAGE30US: 5.87,
  },
  {
    DATE: "2004-01-15",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2004-01-22",
    MORTGAGE30US: 5.64,
  },
  {
    DATE: "2004-01-29",
    MORTGAGE30US: 5.68,
  },
  {
    DATE: "2004-02-05",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2004-02-12",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2004-02-19",
    MORTGAGE30US: 5.58,
  },
  {
    DATE: "2004-02-26",
    MORTGAGE30US: 5.58,
  },
  {
    DATE: "2004-03-04",
    MORTGAGE30US: 5.59,
  },
  {
    DATE: "2004-03-11",
    MORTGAGE30US: 5.41,
  },
  {
    DATE: "2004-03-18",
    MORTGAGE30US: 5.38,
  },
  {
    DATE: "2004-03-25",
    MORTGAGE30US: 5.4,
  },
  {
    DATE: "2004-04-01",
    MORTGAGE30US: 5.52,
  },
  {
    DATE: "2004-04-08",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2004-04-15",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2004-04-22",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2004-04-29",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2004-05-06",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2004-05-13",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2004-05-20",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2004-05-27",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2004-06-03",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2004-06-10",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2004-06-17",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2004-06-24",
    MORTGAGE30US: 6.25,
  },
  {
    DATE: "2004-07-01",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2004-07-08",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2004-07-15",
    MORTGAGE30US: 6,
  },
  {
    DATE: "2004-07-22",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2004-07-29",
    MORTGAGE30US: 6.08,
  },
  {
    DATE: "2004-08-05",
    MORTGAGE30US: 5.99,
  },
  {
    DATE: "2004-08-12",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2004-08-19",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2004-08-26",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2004-09-02",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2004-09-09",
    MORTGAGE30US: 5.83,
  },
  {
    DATE: "2004-09-16",
    MORTGAGE30US: 5.75,
  },
  {
    DATE: "2004-09-23",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2004-09-30",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2004-10-07",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2004-10-14",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2004-10-21",
    MORTGAGE30US: 5.69,
  },
  {
    DATE: "2004-10-28",
    MORTGAGE30US: 5.64,
  },
  {
    DATE: "2004-11-04",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2004-11-11",
    MORTGAGE30US: 5.76,
  },
  {
    DATE: "2004-11-18",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2004-11-24",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2004-12-02",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2004-12-09",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2004-12-16",
    MORTGAGE30US: 5.68,
  },
  {
    DATE: "2004-12-22",
    MORTGAGE30US: 5.75,
  },
  {
    DATE: "2004-12-29",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2005-01-06",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-01-13",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2005-01-20",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2005-01-27",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2005-02-03",
    MORTGAGE30US: 5.63,
  },
  {
    DATE: "2005-02-10",
    MORTGAGE30US: 5.57,
  },
  {
    DATE: "2005-02-17",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2005-02-24",
    MORTGAGE30US: 5.69,
  },
  {
    DATE: "2005-03-03",
    MORTGAGE30US: 5.79,
  },
  {
    DATE: "2005-03-10",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2005-03-17",
    MORTGAGE30US: 5.95,
  },
  {
    DATE: "2005-03-24",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2005-03-31",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2005-04-07",
    MORTGAGE30US: 5.93,
  },
  {
    DATE: "2005-04-14",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2005-04-21",
    MORTGAGE30US: 5.8,
  },
  {
    DATE: "2005-04-28",
    MORTGAGE30US: 5.78,
  },
  {
    DATE: "2005-05-05",
    MORTGAGE30US: 5.75,
  },
  {
    DATE: "2005-05-12",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-05-19",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2005-05-26",
    MORTGAGE30US: 5.65,
  },
  {
    DATE: "2005-06-02",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2005-06-09",
    MORTGAGE30US: 5.56,
  },
  {
    DATE: "2005-06-16",
    MORTGAGE30US: 5.63,
  },
  {
    DATE: "2005-06-23",
    MORTGAGE30US: 5.57,
  },
  {
    DATE: "2005-06-30",
    MORTGAGE30US: 5.53,
  },
  {
    DATE: "2005-07-07",
    MORTGAGE30US: 5.62,
  },
  {
    DATE: "2005-07-14",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2005-07-21",
    MORTGAGE30US: 5.73,
  },
  {
    DATE: "2005-07-28",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-08-04",
    MORTGAGE30US: 5.82,
  },
  {
    DATE: "2005-08-11",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2005-08-18",
    MORTGAGE30US: 5.8,
  },
  {
    DATE: "2005-08-25",
    MORTGAGE30US: 5.77,
  },
  {
    DATE: "2005-09-01",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2005-09-08",
    MORTGAGE30US: 5.71,
  },
  {
    DATE: "2005-09-15",
    MORTGAGE30US: 5.74,
  },
  {
    DATE: "2005-09-22",
    MORTGAGE30US: 5.8,
  },
  {
    DATE: "2005-09-29",
    MORTGAGE30US: 5.91,
  },
  {
    DATE: "2005-10-06",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2005-10-13",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2005-10-20",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2005-10-27",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2005-11-03",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2005-11-10",
    MORTGAGE30US: 6.36,
  },
  {
    DATE: "2005-11-17",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2005-11-23",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2005-12-01",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2005-12-08",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2005-12-15",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2005-12-22",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2005-12-29",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2006-01-05",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2006-01-12",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2006-01-19",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2006-01-26",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2006-02-02",
    MORTGAGE30US: 6.23,
  },
  {
    DATE: "2006-02-09",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2006-02-16",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2006-02-23",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2006-03-02",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2006-03-09",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2006-03-16",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2006-03-23",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2006-03-30",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2006-04-06",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2006-04-13",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "2006-04-20",
    MORTGAGE30US: 6.53,
  },
  {
    DATE: "2006-04-27",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2006-05-04",
    MORTGAGE30US: 6.59,
  },
  {
    DATE: "2006-05-11",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2006-05-18",
    MORTGAGE30US: 6.6,
  },
  {
    DATE: "2006-05-25",
    MORTGAGE30US: 6.62,
  },
  {
    DATE: "2006-06-01",
    MORTGAGE30US: 6.67,
  },
  {
    DATE: "2006-06-08",
    MORTGAGE30US: 6.62,
  },
  {
    DATE: "2006-06-15",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2006-06-22",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2006-06-29",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "2006-07-06",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "2006-07-13",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "2006-07-20",
    MORTGAGE30US: 6.8,
  },
  {
    DATE: "2006-07-27",
    MORTGAGE30US: 6.72,
  },
  {
    DATE: "2006-08-03",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2006-08-10",
    MORTGAGE30US: 6.55,
  },
  {
    DATE: "2006-08-17",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2006-08-24",
    MORTGAGE30US: 6.48,
  },
  {
    DATE: "2006-08-31",
    MORTGAGE30US: 6.44,
  },
  {
    DATE: "2006-09-07",
    MORTGAGE30US: 6.47,
  },
  {
    DATE: "2006-09-14",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2006-09-21",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2006-09-28",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2006-10-05",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2006-10-12",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2006-10-19",
    MORTGAGE30US: 6.36,
  },
  {
    DATE: "2006-10-26",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2006-11-02",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2006-11-09",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2006-11-16",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2006-11-22",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2006-11-30",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2006-12-07",
    MORTGAGE30US: 6.11,
  },
  {
    DATE: "2006-12-14",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2006-12-21",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2006-12-28",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2007-01-04",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2007-01-11",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2007-01-18",
    MORTGAGE30US: 6.23,
  },
  {
    DATE: "2007-01-25",
    MORTGAGE30US: 6.25,
  },
  {
    DATE: "2007-02-01",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2007-02-08",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2007-02-15",
    MORTGAGE30US: 6.3,
  },
  {
    DATE: "2007-02-22",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2007-03-01",
    MORTGAGE30US: 6.18,
  },
  {
    DATE: "2007-03-08",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2007-03-15",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2007-03-22",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-03-29",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-04-05",
    MORTGAGE30US: 6.17,
  },
  {
    DATE: "2007-04-12",
    MORTGAGE30US: 6.22,
  },
  {
    DATE: "2007-04-19",
    MORTGAGE30US: 6.17,
  },
  {
    DATE: "2007-04-26",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-05-03",
    MORTGAGE30US: 6.16,
  },
  {
    DATE: "2007-05-10",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2007-05-17",
    MORTGAGE30US: 6.21,
  },
  {
    DATE: "2007-05-24",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2007-05-31",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2007-06-07",
    MORTGAGE30US: 6.53,
  },
  {
    DATE: "2007-06-14",
    MORTGAGE30US: 6.74,
  },
  {
    DATE: "2007-06-21",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "2007-06-28",
    MORTGAGE30US: 6.67,
  },
  {
    DATE: "2007-07-05",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2007-07-12",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "2007-07-19",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "2007-07-26",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "2007-08-02",
    MORTGAGE30US: 6.68,
  },
  {
    DATE: "2007-08-09",
    MORTGAGE30US: 6.59,
  },
  {
    DATE: "2007-08-16",
    MORTGAGE30US: 6.62,
  },
  {
    DATE: "2007-08-23",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2007-08-30",
    MORTGAGE30US: 6.45,
  },
  {
    DATE: "2007-09-06",
    MORTGAGE30US: 6.46,
  },
  {
    DATE: "2007-09-13",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2007-09-20",
    MORTGAGE30US: 6.34,
  },
  {
    DATE: "2007-09-27",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2007-10-04",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2007-10-11",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2007-10-18",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2007-10-25",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2007-11-01",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2007-11-08",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2007-11-15",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2007-11-21",
    MORTGAGE30US: 6.2,
  },
  {
    DATE: "2007-11-29",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2007-12-06",
    MORTGAGE30US: 5.96,
  },
  {
    DATE: "2007-12-13",
    MORTGAGE30US: 6.11,
  },
  {
    DATE: "2007-12-20",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2007-12-27",
    MORTGAGE30US: 6.17,
  },
  {
    DATE: "2008-01-03",
    MORTGAGE30US: 6.07,
  },
  {
    DATE: "2008-01-10",
    MORTGAGE30US: 5.87,
  },
  {
    DATE: "2008-01-17",
    MORTGAGE30US: 5.69,
  },
  {
    DATE: "2008-01-24",
    MORTGAGE30US: 5.48,
  },
  {
    DATE: "2008-01-31",
    MORTGAGE30US: 5.68,
  },
  {
    DATE: "2008-02-07",
    MORTGAGE30US: 5.67,
  },
  {
    DATE: "2008-02-14",
    MORTGAGE30US: 5.72,
  },
  {
    DATE: "2008-02-21",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2008-02-28",
    MORTGAGE30US: 6.24,
  },
  {
    DATE: "2008-03-06",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2008-03-13",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2008-03-20",
    MORTGAGE30US: 5.87,
  },
  {
    DATE: "2008-03-27",
    MORTGAGE30US: 5.85,
  },
  {
    DATE: "2008-04-03",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2008-04-10",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2008-04-17",
    MORTGAGE30US: 5.88,
  },
  {
    DATE: "2008-04-24",
    MORTGAGE30US: 6.03,
  },
  {
    DATE: "2008-05-01",
    MORTGAGE30US: 6.06,
  },
  {
    DATE: "2008-05-08",
    MORTGAGE30US: 6.05,
  },
  {
    DATE: "2008-05-15",
    MORTGAGE30US: 6.01,
  },
  {
    DATE: "2008-05-22",
    MORTGAGE30US: 5.98,
  },
  {
    DATE: "2008-05-29",
    MORTGAGE30US: 6.08,
  },
  {
    DATE: "2008-06-05",
    MORTGAGE30US: 6.09,
  },
  {
    DATE: "2008-06-12",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2008-06-19",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2008-06-26",
    MORTGAGE30US: 6.45,
  },
  {
    DATE: "2008-07-03",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2008-07-10",
    MORTGAGE30US: 6.37,
  },
  {
    DATE: "2008-07-17",
    MORTGAGE30US: 6.26,
  },
  {
    DATE: "2008-07-24",
    MORTGAGE30US: 6.63,
  },
  {
    DATE: "2008-07-31",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2008-08-07",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2008-08-14",
    MORTGAGE30US: 6.52,
  },
  {
    DATE: "2008-08-21",
    MORTGAGE30US: 6.47,
  },
  {
    DATE: "2008-08-28",
    MORTGAGE30US: 6.4,
  },
  {
    DATE: "2008-09-04",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2008-09-11",
    MORTGAGE30US: 5.93,
  },
  {
    DATE: "2008-09-18",
    MORTGAGE30US: 5.78,
  },
  {
    DATE: "2008-09-25",
    MORTGAGE30US: 6.09,
  },
  {
    DATE: "2008-10-02",
    MORTGAGE30US: 6.1,
  },
  {
    DATE: "2008-10-09",
    MORTGAGE30US: 5.94,
  },
  {
    DATE: "2008-10-16",
    MORTGAGE30US: 6.46,
  },
  {
    DATE: "2008-10-23",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2008-10-30",
    MORTGAGE30US: 6.46,
  },
  {
    DATE: "2008-11-06",
    MORTGAGE30US: 6.2,
  },
  {
    DATE: "2008-11-13",
    MORTGAGE30US: 6.14,
  },
  {
    DATE: "2008-11-20",
    MORTGAGE30US: 6.04,
  },
  {
    DATE: "2008-11-26",
    MORTGAGE30US: 5.97,
  },
  {
    DATE: "2008-12-04",
    MORTGAGE30US: 5.53,
  },
  {
    DATE: "2008-12-11",
    MORTGAGE30US: 5.47,
  },
  {
    DATE: "2008-12-18",
    MORTGAGE30US: 5.19,
  },
  {
    DATE: "2008-12-24",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2008-12-31",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2009-01-08",
    MORTGAGE30US: 5.01,
  },
  {
    DATE: "2009-01-15",
    MORTGAGE30US: 4.96,
  },
  {
    DATE: "2009-01-22",
    MORTGAGE30US: 5.12,
  },
  {
    DATE: "2009-01-29",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2009-02-05",
    MORTGAGE30US: 5.25,
  },
  {
    DATE: "2009-02-12",
    MORTGAGE30US: 5.16,
  },
  {
    DATE: "2009-02-19",
    MORTGAGE30US: 5.04,
  },
  {
    DATE: "2009-02-26",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2009-03-05",
    MORTGAGE30US: 5.15,
  },
  {
    DATE: "2009-03-12",
    MORTGAGE30US: 5.03,
  },
  {
    DATE: "2009-03-19",
    MORTGAGE30US: 4.98,
  },
  {
    DATE: "2009-03-26",
    MORTGAGE30US: 4.85,
  },
  {
    DATE: "2009-04-02",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2009-04-09",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2009-04-16",
    MORTGAGE30US: 4.82,
  },
  {
    DATE: "2009-04-23",
    MORTGAGE30US: 4.8,
  },
  {
    DATE: "2009-04-30",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2009-05-07",
    MORTGAGE30US: 4.84,
  },
  {
    DATE: "2009-05-14",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2009-05-21",
    MORTGAGE30US: 4.82,
  },
  {
    DATE: "2009-05-28",
    MORTGAGE30US: 4.91,
  },
  {
    DATE: "2009-06-04",
    MORTGAGE30US: 5.29,
  },
  {
    DATE: "2009-06-11",
    MORTGAGE30US: 5.59,
  },
  {
    DATE: "2009-06-18",
    MORTGAGE30US: 5.38,
  },
  {
    DATE: "2009-06-25",
    MORTGAGE30US: 5.42,
  },
  {
    DATE: "2009-07-02",
    MORTGAGE30US: 5.32,
  },
  {
    DATE: "2009-07-09",
    MORTGAGE30US: 5.2,
  },
  {
    DATE: "2009-07-16",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2009-07-23",
    MORTGAGE30US: 5.2,
  },
  {
    DATE: "2009-07-30",
    MORTGAGE30US: 5.25,
  },
  {
    DATE: "2009-08-06",
    MORTGAGE30US: 5.22,
  },
  {
    DATE: "2009-08-13",
    MORTGAGE30US: 5.29,
  },
  {
    DATE: "2009-08-20",
    MORTGAGE30US: 5.12,
  },
  {
    DATE: "2009-08-27",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2009-09-03",
    MORTGAGE30US: 5.08,
  },
  {
    DATE: "2009-09-10",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2009-09-17",
    MORTGAGE30US: 5.04,
  },
  {
    DATE: "2009-09-24",
    MORTGAGE30US: 5.04,
  },
  {
    DATE: "2009-10-01",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2009-10-08",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2009-10-15",
    MORTGAGE30US: 4.92,
  },
  {
    DATE: "2009-10-22",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2009-10-29",
    MORTGAGE30US: 5.03,
  },
  {
    DATE: "2009-11-05",
    MORTGAGE30US: 4.98,
  },
  {
    DATE: "2009-11-12",
    MORTGAGE30US: 4.91,
  },
  {
    DATE: "2009-11-19",
    MORTGAGE30US: 4.83,
  },
  {
    DATE: "2009-11-25",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2009-12-03",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2009-12-10",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2009-12-17",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2009-12-24",
    MORTGAGE30US: 5.05,
  },
  {
    DATE: "2009-12-31",
    MORTGAGE30US: 5.14,
  },
  {
    DATE: "2010-01-07",
    MORTGAGE30US: 5.09,
  },
  {
    DATE: "2010-01-14",
    MORTGAGE30US: 5.06,
  },
  {
    DATE: "2010-01-21",
    MORTGAGE30US: 4.99,
  },
  {
    DATE: "2010-01-28",
    MORTGAGE30US: 4.98,
  },
  {
    DATE: "2010-02-04",
    MORTGAGE30US: 5.01,
  },
  {
    DATE: "2010-02-11",
    MORTGAGE30US: 4.97,
  },
  {
    DATE: "2010-02-18",
    MORTGAGE30US: 4.93,
  },
  {
    DATE: "2010-02-25",
    MORTGAGE30US: 5.05,
  },
  {
    DATE: "2010-03-04",
    MORTGAGE30US: 4.97,
  },
  {
    DATE: "2010-03-11",
    MORTGAGE30US: 4.95,
  },
  {
    DATE: "2010-03-18",
    MORTGAGE30US: 4.96,
  },
  {
    DATE: "2010-03-25",
    MORTGAGE30US: 4.99,
  },
  {
    DATE: "2010-04-01",
    MORTGAGE30US: 5.08,
  },
  {
    DATE: "2010-04-08",
    MORTGAGE30US: 5.21,
  },
  {
    DATE: "2010-04-15",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2010-04-22",
    MORTGAGE30US: 5.07,
  },
  {
    DATE: "2010-04-29",
    MORTGAGE30US: 5.06,
  },
  {
    DATE: "2010-05-06",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2010-05-13",
    MORTGAGE30US: 4.93,
  },
  {
    DATE: "2010-05-20",
    MORTGAGE30US: 4.84,
  },
  {
    DATE: "2010-05-27",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2010-06-03",
    MORTGAGE30US: 4.79,
  },
  {
    DATE: "2010-06-10",
    MORTGAGE30US: 4.72,
  },
  {
    DATE: "2010-06-17",
    MORTGAGE30US: 4.75,
  },
  {
    DATE: "2010-06-24",
    MORTGAGE30US: 4.69,
  },
  {
    DATE: "2010-07-01",
    MORTGAGE30US: 4.58,
  },
  {
    DATE: "2010-07-08",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2010-07-15",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2010-07-22",
    MORTGAGE30US: 4.56,
  },
  {
    DATE: "2010-07-29",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2010-08-05",
    MORTGAGE30US: 4.49,
  },
  {
    DATE: "2010-08-12",
    MORTGAGE30US: 4.44,
  },
  {
    DATE: "2010-08-19",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2010-08-26",
    MORTGAGE30US: 4.36,
  },
  {
    DATE: "2010-09-02",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2010-09-09",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2010-09-16",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2010-09-23",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2010-09-30",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2010-10-07",
    MORTGAGE30US: 4.27,
  },
  {
    DATE: "2010-10-14",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2010-10-21",
    MORTGAGE30US: 4.21,
  },
  {
    DATE: "2010-10-28",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2010-11-04",
    MORTGAGE30US: 4.24,
  },
  {
    DATE: "2010-11-11",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2010-11-18",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2010-11-24",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2010-12-02",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2010-12-09",
    MORTGAGE30US: 4.61,
  },
  {
    DATE: "2010-12-16",
    MORTGAGE30US: 4.83,
  },
  {
    DATE: "2010-12-23",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2010-12-30",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2011-01-06",
    MORTGAGE30US: 4.77,
  },
  {
    DATE: "2011-01-13",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2011-01-20",
    MORTGAGE30US: 4.74,
  },
  {
    DATE: "2011-01-27",
    MORTGAGE30US: 4.8,
  },
  {
    DATE: "2011-02-03",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2011-02-10",
    MORTGAGE30US: 5.05,
  },
  {
    DATE: "2011-02-17",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2011-02-24",
    MORTGAGE30US: 4.95,
  },
  {
    DATE: "2011-03-03",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2011-03-10",
    MORTGAGE30US: 4.88,
  },
  {
    DATE: "2011-03-17",
    MORTGAGE30US: 4.76,
  },
  {
    DATE: "2011-03-24",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2011-03-31",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2011-04-07",
    MORTGAGE30US: 4.87,
  },
  {
    DATE: "2011-04-14",
    MORTGAGE30US: 4.91,
  },
  {
    DATE: "2011-04-21",
    MORTGAGE30US: 4.8,
  },
  {
    DATE: "2011-04-28",
    MORTGAGE30US: 4.78,
  },
  {
    DATE: "2011-05-05",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2011-05-12",
    MORTGAGE30US: 4.63,
  },
  {
    DATE: "2011-05-19",
    MORTGAGE30US: 4.61,
  },
  {
    DATE: "2011-05-26",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2011-06-02",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2011-06-09",
    MORTGAGE30US: 4.49,
  },
  {
    DATE: "2011-06-16",
    MORTGAGE30US: 4.5,
  },
  {
    DATE: "2011-06-23",
    MORTGAGE30US: 4.5,
  },
  {
    DATE: "2011-06-30",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2011-07-07",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2011-07-14",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2011-07-21",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2011-07-28",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2011-08-04",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2011-08-11",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2011-08-18",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2011-08-25",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2011-09-01",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2011-09-08",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2011-09-15",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2011-09-22",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2011-09-29",
    MORTGAGE30US: 4.01,
  },
  {
    DATE: "2011-10-06",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2011-10-13",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2011-10-20",
    MORTGAGE30US: 4.11,
  },
  {
    DATE: "2011-10-27",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2011-11-03",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2011-11-10",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2011-11-17",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2011-11-23",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2011-12-01",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2011-12-08",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2011-12-15",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2011-12-22",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2011-12-29",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2012-01-05",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2012-01-12",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2012-01-19",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-01-26",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2012-02-02",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2012-02-09",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2012-02-16",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2012-02-23",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2012-03-01",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2012-03-08",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-03-15",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2012-03-22",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2012-03-29",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2012-04-05",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2012-04-12",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-04-19",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2012-04-26",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2012-05-03",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2012-05-10",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2012-05-17",
    MORTGAGE30US: 3.79,
  },
  {
    DATE: "2012-05-24",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2012-05-31",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2012-06-07",
    MORTGAGE30US: 3.67,
  },
  {
    DATE: "2012-06-14",
    MORTGAGE30US: 3.71,
  },
  {
    DATE: "2012-06-21",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2012-06-28",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2012-07-05",
    MORTGAGE30US: 3.62,
  },
  {
    DATE: "2012-07-12",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2012-07-19",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2012-07-26",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2012-08-02",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2012-08-09",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2012-08-16",
    MORTGAGE30US: 3.62,
  },
  {
    DATE: "2012-08-23",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2012-08-30",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2012-09-06",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2012-09-13",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2012-09-20",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2012-09-27",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2012-10-04",
    MORTGAGE30US: 3.36,
  },
  {
    DATE: "2012-10-11",
    MORTGAGE30US: 3.39,
  },
  {
    DATE: "2012-10-18",
    MORTGAGE30US: 3.37,
  },
  {
    DATE: "2012-10-25",
    MORTGAGE30US: 3.41,
  },
  {
    DATE: "2012-11-01",
    MORTGAGE30US: 3.39,
  },
  {
    DATE: "2012-11-08",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2012-11-15",
    MORTGAGE30US: 3.34,
  },
  {
    DATE: "2012-11-21",
    MORTGAGE30US: 3.31,
  },
  {
    DATE: "2012-11-29",
    MORTGAGE30US: 3.32,
  },
  {
    DATE: "2012-12-06",
    MORTGAGE30US: 3.34,
  },
  {
    DATE: "2012-12-13",
    MORTGAGE30US: 3.32,
  },
  {
    DATE: "2012-12-20",
    MORTGAGE30US: 3.37,
  },
  {
    DATE: "2012-12-27",
    MORTGAGE30US: 3.35,
  },
  {
    DATE: "2013-01-03",
    MORTGAGE30US: 3.34,
  },
  {
    DATE: "2013-01-10",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2013-01-17",
    MORTGAGE30US: 3.38,
  },
  {
    DATE: "2013-01-24",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2013-01-31",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2013-02-07",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2013-02-14",
    MORTGAGE30US: 3.53,
  },
  {
    DATE: "2013-02-21",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2013-02-28",
    MORTGAGE30US: 3.51,
  },
  {
    DATE: "2013-03-07",
    MORTGAGE30US: 3.52,
  },
  {
    DATE: "2013-03-14",
    MORTGAGE30US: 3.63,
  },
  {
    DATE: "2013-03-21",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2013-03-28",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2013-04-04",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2013-04-11",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2013-04-18",
    MORTGAGE30US: 3.41,
  },
  {
    DATE: "2013-04-25",
    MORTGAGE30US: 3.4,
  },
  {
    DATE: "2013-05-02",
    MORTGAGE30US: 3.35,
  },
  {
    DATE: "2013-05-09",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2013-05-16",
    MORTGAGE30US: 3.51,
  },
  {
    DATE: "2013-05-23",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2013-05-30",
    MORTGAGE30US: 3.81,
  },
  {
    DATE: "2013-06-06",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2013-06-13",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2013-06-20",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2013-06-27",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2013-07-03",
    MORTGAGE30US: 4.29,
  },
  {
    DATE: "2013-07-11",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2013-07-18",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2013-07-25",
    MORTGAGE30US: 4.31,
  },
  {
    DATE: "2013-08-01",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2013-08-08",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2013-08-15",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2013-08-22",
    MORTGAGE30US: 4.58,
  },
  {
    DATE: "2013-08-29",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2013-09-05",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2013-09-12",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2013-09-19",
    MORTGAGE30US: 4.5,
  },
  {
    DATE: "2013-09-26",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2013-10-03",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2013-10-10",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2013-10-17",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2013-10-24",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2013-10-31",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2013-11-07",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2013-11-14",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2013-11-21",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2013-11-27",
    MORTGAGE30US: 4.29,
  },
  {
    DATE: "2013-12-05",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2013-12-12",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2013-12-19",
    MORTGAGE30US: 4.47,
  },
  {
    DATE: "2013-12-26",
    MORTGAGE30US: 4.48,
  },
  {
    DATE: "2014-01-02",
    MORTGAGE30US: 4.53,
  },
  {
    DATE: "2014-01-09",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2014-01-16",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2014-01-23",
    MORTGAGE30US: 4.39,
  },
  {
    DATE: "2014-01-30",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2014-02-06",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2014-02-13",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2014-02-20",
    MORTGAGE30US: 4.33,
  },
  {
    DATE: "2014-02-27",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2014-03-06",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2014-03-13",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2014-03-20",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2014-03-27",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2014-04-03",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2014-04-10",
    MORTGAGE30US: 4.34,
  },
  {
    DATE: "2014-04-17",
    MORTGAGE30US: 4.27,
  },
  {
    DATE: "2014-04-24",
    MORTGAGE30US: 4.33,
  },
  {
    DATE: "2014-05-01",
    MORTGAGE30US: 4.29,
  },
  {
    DATE: "2014-05-08",
    MORTGAGE30US: 4.21,
  },
  {
    DATE: "2014-05-15",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2014-05-22",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-05-29",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-06-05",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-06-12",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2014-06-19",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2014-06-26",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-07-03",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-07-10",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2014-07-17",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2014-07-24",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2014-07-31",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-08-07",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2014-08-14",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-08-21",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2014-08-28",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2014-09-04",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2014-09-11",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-09-18",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2014-09-25",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2014-10-02",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2014-10-09",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2014-10-16",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2014-10-23",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2014-10-30",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2014-11-06",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2014-11-13",
    MORTGAGE30US: 4.01,
  },
  {
    DATE: "2014-11-20",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2014-11-26",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2014-12-04",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2014-12-11",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2014-12-18",
    MORTGAGE30US: 3.8,
  },
  {
    DATE: "2014-12-24",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2014-12-31",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-01-08",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2015-01-15",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2015-01-22",
    MORTGAGE30US: 3.63,
  },
  {
    DATE: "2015-01-29",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2015-02-05",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2015-02-12",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2015-02-19",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2015-02-26",
    MORTGAGE30US: 3.8,
  },
  {
    DATE: "2015-03-05",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2015-03-12",
    MORTGAGE30US: 3.86,
  },
  {
    DATE: "2015-03-19",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2015-03-26",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2015-04-02",
    MORTGAGE30US: 3.7,
  },
  {
    DATE: "2015-04-09",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2015-04-16",
    MORTGAGE30US: 3.67,
  },
  {
    DATE: "2015-04-23",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2015-04-30",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2015-05-07",
    MORTGAGE30US: 3.8,
  },
  {
    DATE: "2015-05-14",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2015-05-21",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2015-05-28",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-06-04",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-06-11",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2015-06-18",
    MORTGAGE30US: 4,
  },
  {
    DATE: "2015-06-25",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2015-07-02",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2015-07-09",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2015-07-16",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2015-07-23",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2015-07-30",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2015-08-06",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2015-08-13",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2015-08-20",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2015-08-27",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2015-09-03",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2015-09-10",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2015-09-17",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2015-09-24",
    MORTGAGE30US: 3.86,
  },
  {
    DATE: "2015-10-01",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2015-10-08",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2015-10-15",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2015-10-22",
    MORTGAGE30US: 3.79,
  },
  {
    DATE: "2015-10-29",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2015-11-05",
    MORTGAGE30US: 3.87,
  },
  {
    DATE: "2015-11-12",
    MORTGAGE30US: 3.98,
  },
  {
    DATE: "2015-11-19",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2015-11-25",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2015-12-03",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2015-12-10",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2015-12-17",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2015-12-24",
    MORTGAGE30US: 3.96,
  },
  {
    DATE: "2015-12-31",
    MORTGAGE30US: 4.01,
  },
  {
    DATE: "2016-01-07",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2016-01-14",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2016-01-21",
    MORTGAGE30US: 3.81,
  },
  {
    DATE: "2016-01-28",
    MORTGAGE30US: 3.79,
  },
  {
    DATE: "2016-02-04",
    MORTGAGE30US: 3.72,
  },
  {
    DATE: "2016-02-11",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2016-02-18",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2016-02-25",
    MORTGAGE30US: 3.62,
  },
  {
    DATE: "2016-03-03",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2016-03-10",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2016-03-17",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2016-03-24",
    MORTGAGE30US: 3.71,
  },
  {
    DATE: "2016-03-31",
    MORTGAGE30US: 3.71,
  },
  {
    DATE: "2016-04-07",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2016-04-14",
    MORTGAGE30US: 3.58,
  },
  {
    DATE: "2016-04-21",
    MORTGAGE30US: 3.59,
  },
  {
    DATE: "2016-04-28",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2016-05-05",
    MORTGAGE30US: 3.61,
  },
  {
    DATE: "2016-05-12",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2016-05-19",
    MORTGAGE30US: 3.58,
  },
  {
    DATE: "2016-05-26",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2016-06-02",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2016-06-09",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2016-06-16",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2016-06-23",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2016-06-30",
    MORTGAGE30US: 3.48,
  },
  {
    DATE: "2016-07-07",
    MORTGAGE30US: 3.41,
  },
  {
    DATE: "2016-07-14",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2016-07-21",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2016-07-28",
    MORTGAGE30US: 3.48,
  },
  {
    DATE: "2016-08-04",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2016-08-11",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2016-08-18",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2016-08-25",
    MORTGAGE30US: 3.43,
  },
  {
    DATE: "2016-09-01",
    MORTGAGE30US: 3.46,
  },
  {
    DATE: "2016-09-08",
    MORTGAGE30US: 3.44,
  },
  {
    DATE: "2016-09-15",
    MORTGAGE30US: 3.5,
  },
  {
    DATE: "2016-09-22",
    MORTGAGE30US: 3.48,
  },
  {
    DATE: "2016-09-29",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2016-10-06",
    MORTGAGE30US: 3.42,
  },
  {
    DATE: "2016-10-13",
    MORTGAGE30US: 3.47,
  },
  {
    DATE: "2016-10-20",
    MORTGAGE30US: 3.52,
  },
  {
    DATE: "2016-10-27",
    MORTGAGE30US: 3.47,
  },
  {
    DATE: "2016-11-03",
    MORTGAGE30US: 3.54,
  },
  {
    DATE: "2016-11-10",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2016-11-17",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2016-11-23",
    MORTGAGE30US: 4.03,
  },
  {
    DATE: "2016-12-01",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2016-12-08",
    MORTGAGE30US: 4.13,
  },
  {
    DATE: "2016-12-15",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2016-12-22",
    MORTGAGE30US: 4.3,
  },
  {
    DATE: "2016-12-29",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2017-01-05",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2017-01-12",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2017-01-19",
    MORTGAGE30US: 4.09,
  },
  {
    DATE: "2017-01-26",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2017-02-02",
    MORTGAGE30US: 4.19,
  },
  {
    DATE: "2017-02-09",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2017-02-16",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2017-02-23",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2017-03-02",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2017-03-09",
    MORTGAGE30US: 4.21,
  },
  {
    DATE: "2017-03-16",
    MORTGAGE30US: 4.3,
  },
  {
    DATE: "2017-03-23",
    MORTGAGE30US: 4.23,
  },
  {
    DATE: "2017-03-30",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2017-04-06",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2017-04-13",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2017-04-20",
    MORTGAGE30US: 3.97,
  },
  {
    DATE: "2017-04-27",
    MORTGAGE30US: 4.03,
  },
  {
    DATE: "2017-05-04",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2017-05-11",
    MORTGAGE30US: 4.05,
  },
  {
    DATE: "2017-05-18",
    MORTGAGE30US: 4.02,
  },
  {
    DATE: "2017-05-25",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2017-06-01",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-06-08",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2017-06-15",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2017-06-22",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-06-29",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2017-07-06",
    MORTGAGE30US: 3.96,
  },
  {
    DATE: "2017-07-13",
    MORTGAGE30US: 4.03,
  },
  {
    DATE: "2017-07-20",
    MORTGAGE30US: 3.96,
  },
  {
    DATE: "2017-07-27",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2017-08-03",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2017-08-10",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-08-17",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2017-08-24",
    MORTGAGE30US: 3.86,
  },
  {
    DATE: "2017-08-31",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2017-09-07",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2017-09-14",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2017-09-21",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2017-09-28",
    MORTGAGE30US: 3.83,
  },
  {
    DATE: "2017-10-05",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2017-10-12",
    MORTGAGE30US: 3.91,
  },
  {
    DATE: "2017-10-19",
    MORTGAGE30US: 3.88,
  },
  {
    DATE: "2017-10-26",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-11-02",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-11-09",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-11-16",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2017-11-22",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2017-11-30",
    MORTGAGE30US: 3.9,
  },
  {
    DATE: "2017-12-07",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-12-14",
    MORTGAGE30US: 3.93,
  },
  {
    DATE: "2017-12-21",
    MORTGAGE30US: 3.94,
  },
  {
    DATE: "2017-12-28",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2018-01-04",
    MORTGAGE30US: 3.95,
  },
  {
    DATE: "2018-01-11",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2018-01-18",
    MORTGAGE30US: 4.04,
  },
  {
    DATE: "2018-01-25",
    MORTGAGE30US: 4.15,
  },
  {
    DATE: "2018-02-01",
    MORTGAGE30US: 4.22,
  },
  {
    DATE: "2018-02-08",
    MORTGAGE30US: 4.32,
  },
  {
    DATE: "2018-02-15",
    MORTGAGE30US: 4.38,
  },
  {
    DATE: "2018-02-22",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2018-03-01",
    MORTGAGE30US: 4.43,
  },
  {
    DATE: "2018-03-08",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2018-03-15",
    MORTGAGE30US: 4.44,
  },
  {
    DATE: "2018-03-22",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2018-03-29",
    MORTGAGE30US: 4.44,
  },
  {
    DATE: "2018-04-05",
    MORTGAGE30US: 4.4,
  },
  {
    DATE: "2018-04-12",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2018-04-19",
    MORTGAGE30US: 4.47,
  },
  {
    DATE: "2018-04-26",
    MORTGAGE30US: 4.58,
  },
  {
    DATE: "2018-05-03",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2018-05-10",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2018-05-17",
    MORTGAGE30US: 4.61,
  },
  {
    DATE: "2018-05-24",
    MORTGAGE30US: 4.66,
  },
  {
    DATE: "2018-05-31",
    MORTGAGE30US: 4.56,
  },
  {
    DATE: "2018-06-07",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2018-06-14",
    MORTGAGE30US: 4.62,
  },
  {
    DATE: "2018-06-21",
    MORTGAGE30US: 4.57,
  },
  {
    DATE: "2018-06-28",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2018-07-05",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2018-07-12",
    MORTGAGE30US: 4.53,
  },
  {
    DATE: "2018-07-19",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2018-07-26",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2018-08-02",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2018-08-09",
    MORTGAGE30US: 4.59,
  },
  {
    DATE: "2018-08-16",
    MORTGAGE30US: 4.53,
  },
  {
    DATE: "2018-08-23",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2018-08-30",
    MORTGAGE30US: 4.52,
  },
  {
    DATE: "2018-09-06",
    MORTGAGE30US: 4.54,
  },
  {
    DATE: "2018-09-13",
    MORTGAGE30US: 4.6,
  },
  {
    DATE: "2018-09-20",
    MORTGAGE30US: 4.65,
  },
  {
    DATE: "2018-09-27",
    MORTGAGE30US: 4.72,
  },
  {
    DATE: "2018-10-04",
    MORTGAGE30US: 4.71,
  },
  {
    DATE: "2018-10-11",
    MORTGAGE30US: 4.9,
  },
  {
    DATE: "2018-10-18",
    MORTGAGE30US: 4.85,
  },
  {
    DATE: "2018-10-25",
    MORTGAGE30US: 4.86,
  },
  {
    DATE: "2018-11-01",
    MORTGAGE30US: 4.83,
  },
  {
    DATE: "2018-11-08",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2018-11-15",
    MORTGAGE30US: 4.94,
  },
  {
    DATE: "2018-11-21",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2018-11-29",
    MORTGAGE30US: 4.81,
  },
  {
    DATE: "2018-12-06",
    MORTGAGE30US: 4.75,
  },
  {
    DATE: "2018-12-13",
    MORTGAGE30US: 4.63,
  },
  {
    DATE: "2018-12-20",
    MORTGAGE30US: 4.62,
  },
  {
    DATE: "2018-12-27",
    MORTGAGE30US: 4.55,
  },
  {
    DATE: "2019-01-03",
    MORTGAGE30US: 4.51,
  },
  {
    DATE: "2019-01-10",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2019-01-17",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2019-01-24",
    MORTGAGE30US: 4.45,
  },
  {
    DATE: "2019-01-31",
    MORTGAGE30US: 4.46,
  },
  {
    DATE: "2019-02-07",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2019-02-14",
    MORTGAGE30US: 4.37,
  },
  {
    DATE: "2019-02-21",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2019-02-28",
    MORTGAGE30US: 4.35,
  },
  {
    DATE: "2019-03-07",
    MORTGAGE30US: 4.41,
  },
  {
    DATE: "2019-03-14",
    MORTGAGE30US: 4.31,
  },
  {
    DATE: "2019-03-21",
    MORTGAGE30US: 4.28,
  },
  {
    DATE: "2019-03-28",
    MORTGAGE30US: 4.06,
  },
  {
    DATE: "2019-04-04",
    MORTGAGE30US: 4.08,
  },
  {
    DATE: "2019-04-11",
    MORTGAGE30US: 4.12,
  },
  {
    DATE: "2019-04-18",
    MORTGAGE30US: 4.17,
  },
  {
    DATE: "2019-04-25",
    MORTGAGE30US: 4.2,
  },
  {
    DATE: "2019-05-02",
    MORTGAGE30US: 4.14,
  },
  {
    DATE: "2019-05-09",
    MORTGAGE30US: 4.1,
  },
  {
    DATE: "2019-05-16",
    MORTGAGE30US: 4.07,
  },
  {
    DATE: "2019-05-23",
    MORTGAGE30US: 4.06,
  },
  {
    DATE: "2019-05-30",
    MORTGAGE30US: 3.99,
  },
  {
    DATE: "2019-06-06",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2019-06-13",
    MORTGAGE30US: 3.82,
  },
  {
    DATE: "2019-06-20",
    MORTGAGE30US: 3.84,
  },
  {
    DATE: "2019-06-27",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-07-03",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-07-11",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-07-18",
    MORTGAGE30US: 3.81,
  },
  {
    DATE: "2019-07-25",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-08-01",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-08-08",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2019-08-15",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2019-08-22",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2019-08-29",
    MORTGAGE30US: 3.58,
  },
  {
    DATE: "2019-09-05",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2019-09-12",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2019-09-19",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-09-26",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2019-10-03",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2019-10-10",
    MORTGAGE30US: 3.57,
  },
  {
    DATE: "2019-10-17",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2019-10-24",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-10-31",
    MORTGAGE30US: 3.78,
  },
  {
    DATE: "2019-11-07",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2019-11-14",
    MORTGAGE30US: 3.75,
  },
  {
    DATE: "2019-11-21",
    MORTGAGE30US: 3.66,
  },
  {
    DATE: "2019-11-27",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2019-12-05",
    MORTGAGE30US: 3.68,
  },
  {
    DATE: "2019-12-12",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-12-19",
    MORTGAGE30US: 3.73,
  },
  {
    DATE: "2019-12-26",
    MORTGAGE30US: 3.74,
  },
  {
    DATE: "2020-01-02",
    MORTGAGE30US: 3.72,
  },
  {
    DATE: "2020-01-09",
    MORTGAGE30US: 3.64,
  },
  {
    DATE: "2020-01-16",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2020-01-23",
    MORTGAGE30US: 3.6,
  },
  {
    DATE: "2020-01-30",
    MORTGAGE30US: 3.51,
  },
  {
    DATE: "2020-02-06",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2020-02-13",
    MORTGAGE30US: 3.47,
  },
  {
    DATE: "2020-02-20",
    MORTGAGE30US: 3.49,
  },
  {
    DATE: "2020-02-27",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2020-03-05",
    MORTGAGE30US: 3.29,
  },
  {
    DATE: "2020-03-12",
    MORTGAGE30US: 3.36,
  },
  {
    DATE: "2020-03-19",
    MORTGAGE30US: 3.65,
  },
  {
    DATE: "2020-03-26",
    MORTGAGE30US: 3.5,
  },
  {
    DATE: "2020-04-02",
    MORTGAGE30US: 3.33,
  },
  {
    DATE: "2020-04-09",
    MORTGAGE30US: 3.33,
  },
  {
    DATE: "2020-04-16",
    MORTGAGE30US: 3.31,
  },
  {
    DATE: "2020-04-23",
    MORTGAGE30US: 3.33,
  },
  {
    DATE: "2020-04-30",
    MORTGAGE30US: 3.23,
  },
  {
    DATE: "2020-05-07",
    MORTGAGE30US: 3.26,
  },
  {
    DATE: "2020-05-14",
    MORTGAGE30US: 3.28,
  },
  {
    DATE: "2020-05-21",
    MORTGAGE30US: 3.24,
  },
  {
    DATE: "2020-05-28",
    MORTGAGE30US: 3.15,
  },
  {
    DATE: "2020-06-04",
    MORTGAGE30US: 3.18,
  },
  {
    DATE: "2020-06-11",
    MORTGAGE30US: 3.21,
  },
  {
    DATE: "2020-06-18",
    MORTGAGE30US: 3.13,
  },
  {
    DATE: "2020-06-25",
    MORTGAGE30US: 3.13,
  },
  {
    DATE: "2020-07-02",
    MORTGAGE30US: 3.07,
  },
  {
    DATE: "2020-07-09",
    MORTGAGE30US: 3.03,
  },
  {
    DATE: "2020-07-16",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2020-07-23",
    MORTGAGE30US: 3.01,
  },
  {
    DATE: "2020-07-30",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2020-08-06",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2020-08-13",
    MORTGAGE30US: 2.96,
  },
  {
    DATE: "2020-08-20",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2020-08-27",
    MORTGAGE30US: 2.91,
  },
  {
    DATE: "2020-09-03",
    MORTGAGE30US: 2.93,
  },
  {
    DATE: "2020-09-10",
    MORTGAGE30US: 2.86,
  },
  {
    DATE: "2020-09-17",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2020-09-24",
    MORTGAGE30US: 2.9,
  },
  {
    DATE: "2020-10-01",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2020-10-08",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2020-10-15",
    MORTGAGE30US: 2.81,
  },
  {
    DATE: "2020-10-22",
    MORTGAGE30US: 2.8,
  },
  {
    DATE: "2020-10-29",
    MORTGAGE30US: 2.81,
  },
  {
    DATE: "2020-11-05",
    MORTGAGE30US: 2.78,
  },
  {
    DATE: "2020-11-12",
    MORTGAGE30US: 2.84,
  },
  {
    DATE: "2020-11-19",
    MORTGAGE30US: 2.72,
  },
  {
    DATE: "2020-11-25",
    MORTGAGE30US: 2.72,
  },
  {
    DATE: "2020-12-03",
    MORTGAGE30US: 2.71,
  },
  {
    DATE: "2020-12-10",
    MORTGAGE30US: 2.71,
  },
  {
    DATE: "2020-12-17",
    MORTGAGE30US: 2.67,
  },
  {
    DATE: "2020-12-24",
    MORTGAGE30US: 2.66,
  },
  {
    DATE: "2020-12-31",
    MORTGAGE30US: 2.67,
  },
  {
    DATE: "2021-01-07",
    MORTGAGE30US: 2.65,
  },
  {
    DATE: "2021-01-14",
    MORTGAGE30US: 2.79,
  },
  {
    DATE: "2021-01-21",
    MORTGAGE30US: 2.77,
  },
  {
    DATE: "2021-01-28",
    MORTGAGE30US: 2.73,
  },
  {
    DATE: "2021-02-04",
    MORTGAGE30US: 2.73,
  },
  {
    DATE: "2021-02-11",
    MORTGAGE30US: 2.73,
  },
  {
    DATE: "2021-02-18",
    MORTGAGE30US: 2.81,
  },
  {
    DATE: "2021-02-25",
    MORTGAGE30US: 2.97,
  },
  {
    DATE: "2021-03-04",
    MORTGAGE30US: 3.02,
  },
  {
    DATE: "2021-03-11",
    MORTGAGE30US: 3.05,
  },
  {
    DATE: "2021-03-18",
    MORTGAGE30US: 3.09,
  },
  {
    DATE: "2021-03-25",
    MORTGAGE30US: 3.17,
  },
  {
    DATE: "2021-04-01",
    MORTGAGE30US: 3.18,
  },
  {
    DATE: "2021-04-08",
    MORTGAGE30US: 3.13,
  },
  {
    DATE: "2021-04-15",
    MORTGAGE30US: 3.04,
  },
  {
    DATE: "2021-04-22",
    MORTGAGE30US: 2.97,
  },
  {
    DATE: "2021-04-29",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2021-05-06",
    MORTGAGE30US: 2.96,
  },
  {
    DATE: "2021-05-13",
    MORTGAGE30US: 2.94,
  },
  {
    DATE: "2021-05-20",
    MORTGAGE30US: 3,
  },
  {
    DATE: "2021-05-27",
    MORTGAGE30US: 2.95,
  },
  {
    DATE: "2021-06-03",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2021-06-10",
    MORTGAGE30US: 2.96,
  },
  {
    DATE: "2021-06-17",
    MORTGAGE30US: 2.93,
  },
  {
    DATE: "2021-06-24",
    MORTGAGE30US: 3.02,
  },
  {
    DATE: "2021-07-01",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2021-07-08",
    MORTGAGE30US: 2.9,
  },
  {
    DATE: "2021-07-15",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2021-07-22",
    MORTGAGE30US: 2.78,
  },
  {
    DATE: "2021-07-29",
    MORTGAGE30US: 2.8,
  },
  {
    DATE: "2021-08-05",
    MORTGAGE30US: 2.77,
  },
  {
    DATE: "2021-08-12",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2021-08-19",
    MORTGAGE30US: 2.86,
  },
  {
    DATE: "2021-08-26",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2021-09-02",
    MORTGAGE30US: 2.87,
  },
  {
    DATE: "2021-09-09",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2021-09-16",
    MORTGAGE30US: 2.86,
  },
  {
    DATE: "2021-09-23",
    MORTGAGE30US: 2.88,
  },
  {
    DATE: "2021-09-30",
    MORTGAGE30US: 3.01,
  },
  {
    DATE: "2021-10-07",
    MORTGAGE30US: 2.99,
  },
  {
    DATE: "2021-10-14",
    MORTGAGE30US: 3.05,
  },
  {
    DATE: "2021-10-21",
    MORTGAGE30US: 3.09,
  },
  {
    DATE: "2021-10-28",
    MORTGAGE30US: 3.14,
  },
  {
    DATE: "2021-11-04",
    MORTGAGE30US: 3.09,
  },
  {
    DATE: "2021-11-10",
    MORTGAGE30US: 2.98,
  },
  {
    DATE: "2021-11-18",
    MORTGAGE30US: 3.1,
  },
  {
    DATE: "2021-11-24",
    MORTGAGE30US: 3.1,
  },
  {
    DATE: "2021-12-02",
    MORTGAGE30US: 3.11,
  },
  {
    DATE: "2021-12-09",
    MORTGAGE30US: 3.1,
  },
  {
    DATE: "2021-12-16",
    MORTGAGE30US: 3.12,
  },
  {
    DATE: "2021-12-23",
    MORTGAGE30US: 3.05,
  },
  {
    DATE: "2021-12-30",
    MORTGAGE30US: 3.11,
  },
  {
    DATE: "2022-01-06",
    MORTGAGE30US: 3.22,
  },
  {
    DATE: "2022-01-13",
    MORTGAGE30US: 3.45,
  },
  {
    DATE: "2022-01-20",
    MORTGAGE30US: 3.56,
  },
  {
    DATE: "2022-01-27",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2022-02-03",
    MORTGAGE30US: 3.55,
  },
  {
    DATE: "2022-02-10",
    MORTGAGE30US: 3.69,
  },
  {
    DATE: "2022-02-17",
    MORTGAGE30US: 3.92,
  },
  {
    DATE: "2022-02-24",
    MORTGAGE30US: 3.89,
  },
  {
    DATE: "2022-03-03",
    MORTGAGE30US: 3.76,
  },
  {
    DATE: "2022-03-10",
    MORTGAGE30US: 3.85,
  },
  {
    DATE: "2022-03-17",
    MORTGAGE30US: 4.16,
  },
  {
    DATE: "2022-03-24",
    MORTGAGE30US: 4.42,
  },
  {
    DATE: "2022-03-31",
    MORTGAGE30US: 4.67,
  },
  {
    DATE: "2022-04-07",
    MORTGAGE30US: 4.72,
  },
  {
    DATE: "2022-04-14",
    MORTGAGE30US: 5,
  },
  {
    DATE: "2022-04-21",
    MORTGAGE30US: 5.11,
  },
  {
    DATE: "2022-04-28",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2022-05-05",
    MORTGAGE30US: 5.27,
  },
  {
    DATE: "2022-05-12",
    MORTGAGE30US: 5.3,
  },
  {
    DATE: "2022-05-19",
    MORTGAGE30US: 5.25,
  },
  {
    DATE: "2022-05-26",
    MORTGAGE30US: 5.1,
  },
  {
    DATE: "2022-06-02",
    MORTGAGE30US: 5.09,
  },
  {
    DATE: "2022-06-09",
    MORTGAGE30US: 5.23,
  },
  {
    DATE: "2022-06-16",
    MORTGAGE30US: 5.78,
  },
  {
    DATE: "2022-06-23",
    MORTGAGE30US: 5.81,
  },
  {
    DATE: "2022-06-30",
    MORTGAGE30US: 5.7,
  },
  {
    DATE: "2022-07-07",
    MORTGAGE30US: 5.3,
  },
  {
    DATE: "2022-07-14",
    MORTGAGE30US: 5.51,
  },
  {
    DATE: "2022-07-21",
    MORTGAGE30US: 5.54,
  },
  {
    DATE: "2022-07-28",
    MORTGAGE30US: 5.3,
  },
  {
    DATE: "2022-08-04",
    MORTGAGE30US: 4.99,
  },
  {
    DATE: "2022-08-11",
    MORTGAGE30US: 5.22,
  },
  {
    DATE: "2022-08-18",
    MORTGAGE30US: 5.13,
  },
  {
    DATE: "2022-08-25",
    MORTGAGE30US: 5.55,
  },
  {
    DATE: "2022-09-01",
    MORTGAGE30US: 5.66,
  },
  {
    DATE: "2022-09-08",
    MORTGAGE30US: 5.89,
  },
  {
    DATE: "2022-09-15",
    MORTGAGE30US: 6.02,
  },
  {
    DATE: "2022-09-22",
    MORTGAGE30US: 6.29,
  },
  {
    DATE: "2022-09-29",
    MORTGAGE30US: 6.7,
  },
  {
    DATE: "2022-10-06",
    MORTGAGE30US: 6.66,
  },
  {
    DATE: "2022-10-13",
    MORTGAGE30US: 6.92,
  },
  {
    DATE: "2022-10-20",
    MORTGAGE30US: 6.94,
  },
  {
    DATE: "2022-10-27",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2022-11-03",
    MORTGAGE30US: 6.95,
  },
  {
    DATE: "2022-11-10",
    MORTGAGE30US: 7.08,
  },
  {
    DATE: "2022-11-17",
    MORTGAGE30US: 6.61,
  },
  {
    DATE: "2022-11-23",
    MORTGAGE30US: 6.58,
  },
  {
    DATE: "2022-12-01",
    MORTGAGE30US: 6.49,
  },
  {
    DATE: "2022-12-08",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2022-12-15",
    MORTGAGE30US: 6.31,
  },
  {
    DATE: "2022-12-22",
    MORTGAGE30US: 6.27,
  },
  {
    DATE: "2022-12-29",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2023-01-05",
    MORTGAGE30US: 6.48,
  },
  {
    DATE: "2023-01-12",
    MORTGAGE30US: 6.33,
  },
  {
    DATE: "2023-01-19",
    MORTGAGE30US: 6.15,
  },
  {
    DATE: "2023-01-26",
    MORTGAGE30US: 6.13,
  },
  {
    DATE: "2023-02-02",
    MORTGAGE30US: 6.09,
  },
  {
    DATE: "2023-02-09",
    MORTGAGE30US: 6.12,
  },
  {
    DATE: "2023-02-16",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2023-02-23",
    MORTGAGE30US: 6.5,
  },
  {
    DATE: "2023-03-02",
    MORTGAGE30US: 6.65,
  },
  {
    DATE: "2023-03-09",
    MORTGAGE30US: 6.73,
  },
  {
    DATE: "2023-03-16",
    MORTGAGE30US: 6.6,
  },
  {
    DATE: "2023-03-23",
    MORTGAGE30US: 6.42,
  },
  {
    DATE: "2023-03-30",
    MORTGAGE30US: 6.32,
  },
  {
    DATE: "2023-04-06",
    MORTGAGE30US: 6.28,
  },
  {
    DATE: "2023-04-13",
    MORTGAGE30US: 6.27,
  },
  {
    DATE: "2023-04-20",
    MORTGAGE30US: 6.39,
  },
  {
    DATE: "2023-04-27",
    MORTGAGE30US: 6.43,
  },
  {
    DATE: "2023-05-04",
    MORTGAGE30US: 6.39,
  },
  {
    DATE: "2023-05-11",
    MORTGAGE30US: 6.35,
  },
  {
    DATE: "2023-05-18",
    MORTGAGE30US: 6.39,
  },
  {
    DATE: "2023-05-25",
    MORTGAGE30US: 6.57,
  },
  {
    DATE: "2023-06-01",
    MORTGAGE30US: 6.79,
  },
  {
    DATE: "2023-06-08",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2023-06-15",
    MORTGAGE30US: 6.69,
  },
  {
    DATE: "2023-06-22",
    MORTGAGE30US: 6.67,
  },
  {
    DATE: "2023-06-29",
    MORTGAGE30US: 6.71,
  },
  {
    DATE: "2023-07-06",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2023-07-13",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2023-07-20",
    MORTGAGE30US: 6.78,
  },
  {
    DATE: "2023-07-27",
    MORTGAGE30US: 6.81,
  },
  {
    DATE: "2023-08-03",
    MORTGAGE30US: 6.9,
  },
  {
    DATE: "2023-08-10",
    MORTGAGE30US: 6.96,
  },
  {
    DATE: "2023-08-17",
    MORTGAGE30US: 7.09,
  },
];

export const currentInterestRateAverage =
  mortgageAverageList[mortgageAverageList.length - 1]["MORTGAGE30US"] / 100;

// export const filterMap = {
//     state: {
//         demographic: ['Population', 'Median HH Income', 'Millenial Percentage', 'Senior Percentage', 'Median Age', 'Homeownership Rate', 'Poverty Rate'],
//         economic: ['Payroll Jobs', 'Average Hourly Wage', 'Average Weekly Hours Worked', 'Annual Earnings'],
//         growth: ['Home Value Growth Rate', 'Population Growth'],
//         realEstate: ['Home Value', 'Value / Income Ratio',]
//     },
//     metro: {
//         demographic: ['Population', 'Median HH Income', 'Millenial Percentage', 'Senior Percentage', 'Median Age', 'Homeownership Rate', 'Poverty Rate'],
//         economic: ['Payroll Jobs', 'Average Hourly Wage', 'Average Weekly Hours Worked', 'Annual Earnings'],
//         growth: ['Home Value Growth Rate', 'Population Growth'],
//         realEstate: ['Home Value', 'Value / Income Ratio',]
//     },
//     city: {
//         demographic: ['Population', 'Median HH Income', 'Millenial Percentage', 'Senior Percentage', 'Median Age', 'Homeownership Rate', 'Poverty Rate'],
//         economic: ['Payroll Jobs', 'Average Hourly Wage', 'Average Weekly Hours Worked', 'Annual Earnings'],
//         growth: ['Home Value Growth Rate', 'Population Growth'],
//         realEstate: ['Home Value', 'Value / Income Ratio',]
//     },
//     zip: {
//         demographic: ['Population', 'Median HH Income', 'Millenial Percentage', 'Senior Percentage', 'Median Age', 'Homeownership Rate', 'Poverty Rate'],
//         economic: ['Payroll Jobs', 'Average Hourly Wage', 'Average Weekly Hours Worked', 'Annual Earnings'],
//         growth: ['Home Value Growth Rate', 'Population Growth'],
//         realEstate: ['Home Value', 'Value / Income Ratio',]
//     }
// }

export const stateCoordinates = [
  {
    name: "Alabama",
    lat: 32.7794,
    lon: -86.8287,
    zoom: 5,
  },
  {
    name: "Alaska",
    lat: 64.0685,
    lon: -152.2782,
    zoom: 3,
  },
  {
    name: "Arizona",
    lat: 34.2744,
    lon: -111.6602,
    zoom: 5,
  },
  {
    name: "Arkansas",
    lat: 34.8938,
    lon: -92.4426,
    zoom: 5,
  },
  {
    name: "California",
    lat: 37.1841,
    lon: -119.4696,
    zoom: 5,
  },
  {
    name: "Colorado",
    lat: 38.9972,
    lon: -105.5478,
    zoom: 5,
  },
  {
    name: "Connecticut",
    lat: 41.6219,
    lon: -72.7273,
    zoom: 7,
  },
  {
    name: "Delaware",
    lat: 38.9896,
    lon: -75.505,
    zoom: 7,
  },
  // {
  //   "name": "Washington DC",
  //   "lat": 38.9101,
  //   "lon": -77.0147,
  //   "zoom": 7
  // },
  {
    name: "Florida",
    lat: 28.6305,
    lon: -82.4497,
    zoom: 5,
  },
  {
    name: "Georgia",
    lat: 32.6415,
    lon: -83.4426,
    zoom: 5,
  },
  {
    name: "Hawaii",
    lat: 20.2927,
    lon: -156.3737,
    zoom: 7,
  },
  {
    name: "Idaho",
    lat: 44.3509,
    lon: -114.613,
    zoom: 5,
  },
  {
    name: "Illinois",
    lat: 40.0417,
    lon: -89.1965,
    zoom: 5,
  },
  {
    name: "Indiana",
    lat: 39.8942,
    lon: -86.2816,
    zoom: 5,
  },
  {
    name: "Iowa",
    lat: 42.0751,
    lon: -93.496,
    zoom: 5,
  },
  {
    name: "Kansas",
    lat: 38.4937,
    lon: -98.3804,
    zoom: 5,
  },
  {
    name: "Kentucky",
    lat: 37.5347,
    lon: -85.3021,
    zoom: 5,
  },
  {
    name: "Louisiana",
    lat: 31.0689,
    lon: -91.9968,
    zoom: 5,
  },
  {
    name: "Maine",
    lat: 45.3695,
    lon: -69.2428,
    zoom: 6,
  },
  {
    name: "Maryland",
    lat: 39.055,
    lon: -76.7909,
    zoom: 7,
  },
  {
    name: "Massachusetts",
    lat: 42.2596,
    lon: -71.8083,
    zoom: 6,
  },
  {
    name: "Michigan",
    lat: 44.3467,
    lon: -85.4102,
    zoom: 5,
  },
  {
    name: "Minnesota",
    lat: 46.2807,
    lon: -94.3053,
    zoom: 5,
  },
  {
    name: "Mississippi",
    lat: 32.7364,
    lon: -89.6678,
    zoom: 5,
  },
  {
    name: "Missouri",
    lat: 38.3566,
    lon: -92.458,
    zoom: 5,
  },
  {
    name: "Montana",
    lat: 47.0527,
    lon: -109.6333,
    zoom: 5,
  },
  {
    name: "Nebraska",
    lat: 41.5378,
    lon: -99.7951,
    zoom: 5,
  },
  {
    name: "Nevada",
    lat: 39.3289,
    lon: -116.6312,
    zoom: 5,
  },
  {
    name: "New Hampshire",
    lat: 43.6805,
    lon: -71.5811,
    zoom: 6,
  },
  {
    name: "New Jersey",
    lat: 40.1907,
    lon: -74.6728,
    zoom: 5,
  },
  {
    name: "New Mexico",
    lat: 34.4071,
    lon: -106.1126,
    zoom: 5,
  },
  {
    name: "New York",
    lat: 42.9538,
    lon: -75.5268,
    zoom: 5,
  },
  {
    name: "North Carolina",
    lat: 35.5557,
    lon: -79.3877,
    zoom: 5,
  },
  {
    name: "North Dakota",
    lat: 47.4501,
    lon: -100.4659,
    zoom: 5,
  },
  {
    name: "Ohio",
    lat: 40.2862,
    lon: -82.7937,
    zoom: 5,
  },
  {
    name: "Oklahoma",
    lat: 35.5889,
    lon: -97.4943,
    zoom: 5,
  },
  {
    name: "Oregon",
    lat: 43.9336,
    lon: -120.5583,
    zoom: 5,
  },
  {
    name: "Pennsylvania",
    lat: 40.8781,
    lon: -77.7996,
    zoom: 5,
  },
  {
    name: "Rhode Island",
    lat: 41.6762,
    lon: -71.5562,
    zoom: 7,
  },
  {
    name: "South Carolina",
    lat: 33.9169,
    lon: -80.8964,
    zoom: 5,
  },
  {
    name: "South Dakota",
    lat: 44.4443,
    lon: -100.2263,
    zoom: 5,
  },
  {
    name: "Tennessee",
    lat: 35.858,
    lon: -86.3505,
    zoom: 5,
  },
  {
    name: "Texas",
    lat: 31.4757,
    lon: -99.3312,
    zoom: 5,
  },
  {
    name: "Utah",
    lat: 39.3055,
    lon: -111.6703,
    zoom: 5,
  },
  {
    name: "Vermont",
    lat: 44.0687,
    lon: -72.6658,
    zoom: 6,
  },
  {
    name: "Virginia",
    lat: 37.5215,
    lon: -78.8537,
    zoom: 5,
  },
  {
    name: "Washington",
    lat: 47.3826,
    lon: -120.4472,
    zoom: 5,
  },
  {
    name: "West Virginia",
    lat: 38.6409,
    lon: -80.6227,
    zoom: 5,
  },
  {
    name: "Wisconsin",
    lat: 44.6243,
    lon: -89.9941,
    zoom: 5,
  },
  {
    name: "Wyoming",
    lat: 42.9957,
    lon: -107.5512,
    zoom: 5,
  },
];

export const metroZillowMap = [
  {
    RegionID: 102001,
    SizeRank: 0,
    RegionName: "United States",
    RegionType: "Country",
    Metro: "",
    State: "",
    "Metro, State": "",
    "CBSA Code": "",
  },
  {
    RegionID: 394913,
    SizeRank: 1,
    RegionName: "New York, NY",
    RegionType: "Msa",
    Metro: "New York",
    State: "NY",
    "Metro, State": "New York, NY",
    "CBSA Code": 35620,
  },
  {
    RegionID: 753899,
    SizeRank: 2,
    RegionName: "Los Angeles-Long Beach-Anaheim, CA",
    RegionType: "Msa",
    Metro: "Los Angeles",
    State: "CA",
    "Metro, State": "Los Angeles, CA",
    "CBSA Code": 31080,
  },
  {
    RegionID: 394463,
    SizeRank: 3,
    RegionName: "Chicago, IL",
    RegionType: "Msa",
    Metro: "Chicago",
    State: "IL",
    "Metro, State": "Chicago, IL",
    "CBSA Code": 16980,
  },
  {
    RegionID: 394514,
    SizeRank: 4,
    RegionName: "Dallas-Fort Worth, TX",
    RegionType: "Msa",
    Metro: "Dallas",
    State: "TX",
    "Metro, State": "Dallas, TX",
    "CBSA Code": 19100,
  },
  {
    RegionID: 394974,
    SizeRank: 5,
    RegionName: "Philadelphia, PA",
    RegionType: "Msa",
    Metro: "Philadelphia",
    State: "PA",
    "Metro, State": "Philadelphia, PA",
    "CBSA Code": 37980,
  },
  {
    RegionID: 394692,
    SizeRank: 6,
    RegionName: "Houston, TX",
    RegionType: "Msa",
    Metro: "Houston",
    State: "TX",
    "Metro, State": "Houston, TX",
    "CBSA Code": 26420,
  },
  {
    RegionID: 395209,
    SizeRank: 7,
    RegionName: "Washington, DC",
    RegionType: "Msa",
    Metro: "Washington",
    State: "DC",
    "Metro, State": "Washington, DC",
    "CBSA Code": 47900,
  },
  {
    RegionID: 394856,
    SizeRank: 8,
    RegionName: "Miami-Fort Lauderdale, FL",
    RegionType: "Msa",
    Metro: "Miami",
    State: "FL",
    "Metro, State": "Miami, FL",
    "CBSA Code": 33100,
  },
  {
    RegionID: 394347,
    SizeRank: 9,
    RegionName: "Atlanta, GA",
    RegionType: "Msa",
    Metro: "Atlanta",
    State: "GA",
    "Metro, State": "Atlanta, GA",
    "CBSA Code": 12060,
  },
  {
    RegionID: 394404,
    SizeRank: 10,
    RegionName: "Boston, MA",
    RegionType: "Msa",
    Metro: "Boston",
    State: "MA",
    "Metro, State": "Boston, MA",
    "CBSA Code": 14460,
  },
  {
    RegionID: 395057,
    SizeRank: 11,
    RegionName: "San Francisco, CA",
    RegionType: "Msa",
    Metro: "San Francisco",
    State: "CA",
    "Metro, State": "San Francisco, CA",
    "CBSA Code": 41860,
  },
  {
    RegionID: 394532,
    SizeRank: 12,
    RegionName: "Detroit, MI",
    RegionType: "Msa",
    Metro: "Detroit",
    State: "MI",
    "Metro, State": "Detroit, MI",
    "CBSA Code": 19820,
  },
  {
    RegionID: 395025,
    SizeRank: 13,
    RegionName: "Riverside, CA",
    RegionType: "Msa",
    Metro: "Riverside",
    State: "CA",
    "Metro, State": "Riverside, CA",
    "CBSA Code": 40140,
  },
  {
    RegionID: 394976,
    SizeRank: 14,
    RegionName: "Phoenix, AZ",
    RegionType: "Msa",
    Metro: "Phoenix",
    State: "AZ",
    "Metro, State": "Phoenix, AZ",
    "CBSA Code": 38060,
  },
  {
    RegionID: 395078,
    SizeRank: 15,
    RegionName: "Seattle, WA",
    RegionType: "Msa",
    Metro: "Seattle",
    State: "WA",
    "Metro, State": "Seattle, WA",
    "CBSA Code": 42660,
  },
  {
    RegionID: 394865,
    SizeRank: 16,
    RegionName: "Minneapolis-St Paul, MN",
    RegionType: "Msa",
    Metro: "Minneapolis",
    State: "MN",
    "Metro, State": "Minneapolis, MN",
    "CBSA Code": 33460,
  },
  {
    RegionID: 395056,
    SizeRank: 17,
    RegionName: "San Diego, CA",
    RegionType: "Msa",
    Metro: "San Diego",
    State: "CA",
    "Metro, State": "San Diego, CA",
    "CBSA Code": 41740,
  },
  {
    RegionID: 395121,
    SizeRank: 18,
    RegionName: "St. Louis, MO",
    RegionType: "Msa",
    Metro: "St. Louis",
    State: "MO",
    "Metro, State": "St. Louis, MO",
    "CBSA Code": 41180,
  },
  {
    RegionID: 395148,
    SizeRank: 19,
    RegionName: "Tampa, FL",
    RegionType: "Msa",
    Metro: "Tampa",
    State: "FL",
    "Metro, State": "Tampa, FL",
    "CBSA Code": 45300,
  },
  {
    RegionID: 394358,
    SizeRank: 20,
    RegionName: "Baltimore, MD",
    RegionType: "Msa",
    Metro: "Baltimore",
    State: "MD",
    "Metro, State": "Baltimore, MD",
    "CBSA Code": 12580,
  },
  {
    RegionID: 394530,
    SizeRank: 21,
    RegionName: "Denver, CO",
    RegionType: "Msa",
    Metro: "Denver",
    State: "CO",
    "Metro, State": "Denver, CO",
    "CBSA Code": 19740,
  },
  {
    RegionID: 394982,
    SizeRank: 22,
    RegionName: "Pittsburgh, PA",
    RegionType: "Msa",
    Metro: "Pittsburgh",
    State: "PA",
    "Metro, State": "Pittsburgh, PA",
    "CBSA Code": 38300,
  },
  {
    RegionID: 394998,
    SizeRank: 23,
    RegionName: "Portland, OR",
    RegionType: "Msa",
    Metro: "Portland",
    State: "OR",
    "Metro, State": "Portland, OR",
    "CBSA Code": 38900,
  },
  {
    RegionID: 394458,
    SizeRank: 24,
    RegionName: "Charlotte, NC",
    RegionType: "Msa",
    Metro: "Charlotte",
    State: "NC",
    "Metro, State": "Charlotte, NC",
    "CBSA Code": 16740,
  },
  {
    RegionID: 395045,
    SizeRank: 25,
    RegionName: "Sacramento, CA",
    RegionType: "Msa",
    Metro: "Sacramento",
    State: "CA",
    "Metro, State": "Sacramento, CA",
    "CBSA Code": 40900,
  },
  {
    RegionID: 395055,
    SizeRank: 26,
    RegionName: "San Antonio, TX",
    RegionType: "Msa",
    Metro: "San Antonio",
    State: "TX",
    "Metro, State": "San Antonio, TX",
    "CBSA Code": 41700,
  },
  {
    RegionID: 394943,
    SizeRank: 27,
    RegionName: "Orlando, FL",
    RegionType: "Msa",
    Metro: "Orlando",
    State: "FL",
    "Metro, State": "Orlando, FL",
    "CBSA Code": 36740,
  },
  {
    RegionID: 394466,
    SizeRank: 28,
    RegionName: "Cincinnati, OH",
    RegionType: "Msa",
    Metro: "Cincinnati",
    State: "OH",
    "Metro, State": "Cincinnati, OH",
    "CBSA Code": 17140,
  },
  {
    RegionID: 394475,
    SizeRank: 29,
    RegionName: "Cleveland, OH",
    RegionType: "Msa",
    Metro: "Cleveland",
    State: "OH",
    "Metro, State": "Cleveland, OH",
    "CBSA Code": 17460,
  },
  {
    RegionID: 394735,
    SizeRank: 30,
    RegionName: "Kansas City, MO",
    RegionType: "Msa",
    Metro: "Kansas City",
    State: "MO",
    "Metro, State": "Kansas City, MO",
    "CBSA Code": 28140,
  },
  {
    RegionID: 394775,
    SizeRank: 31,
    RegionName: "Las Vegas, NV",
    RegionType: "Msa",
    Metro: "Las Vegas",
    State: "NV",
    "Metro, State": "Las Vegas, NV",
    "CBSA Code": 29820,
  },
  {
    RegionID: 394492,
    SizeRank: 32,
    RegionName: "Columbus, OH",
    RegionType: "Msa",
    Metro: "Columbus",
    State: "OH",
    "Metro, State": "Columbus, OH",
    "CBSA Code": 18140,
  },
  {
    RegionID: 394705,
    SizeRank: 33,
    RegionName: "Indianapolis, IN",
    RegionType: "Msa",
    Metro: "Indianapolis",
    State: "IN",
    "Metro, State": "Indianapolis, IN",
    "CBSA Code": 26900,
  },
  {
    RegionID: 395059,
    SizeRank: 34,
    RegionName: "San Jose, CA",
    RegionType: "Msa",
    Metro: "San Jose",
    State: "CA",
    "Metro, State": "San Jose, CA",
    "CBSA Code": 41940,
  },
  {
    RegionID: 394355,
    SizeRank: 35,
    RegionName: "Austin, TX",
    RegionType: "Msa",
    Metro: "Austin",
    State: "TX",
    "Metro, State": "Austin, TX",
    "CBSA Code": 12420,
  },
  {
    RegionID: 395194,
    SizeRank: 36,
    RegionName: "Virginia Beach, VA",
    RegionType: "Msa",
    Metro: "Virginia Beach",
    State: "VA",
    "Metro, State": "Virginia Beach, VA",
    "CBSA Code": 47260,
  },
  {
    RegionID: 394902,
    SizeRank: 37,
    RegionName: "Nashville, TN",
    RegionType: "Msa",
    Metro: "Nashville",
    State: "TN",
    "Metro, State": "Nashville, TN",
    "CBSA Code": 34980,
  },
  {
    RegionID: 395005,
    SizeRank: 38,
    RegionName: "Providence, RI",
    RegionType: "Msa",
    Metro: "Providence",
    State: "RI",
    "Metro, State": "Providence, RI",
    "CBSA Code": 39300,
  },
  {
    RegionID: 394862,
    SizeRank: 39,
    RegionName: "Milwaukee, WI",
    RegionType: "Msa",
    Metro: "Milwaukee",
    State: "WI",
    "Metro, State": "Milwaukee, WI",
    "CBSA Code": 33340,
  },
  {
    RegionID: 394714,
    SizeRank: 40,
    RegionName: "Jacksonville, FL",
    RegionType: "Msa",
    Metro: "Jacksonville",
    State: "FL",
    "Metro, State": "Jacksonville, FL",
    "CBSA Code": 27260,
  },
  {
    RegionID: 394849,
    SizeRank: 41,
    RegionName: "Memphis, TN",
    RegionType: "Msa",
    Metro: "Memphis",
    State: "TN",
    "Metro, State": "Memphis, TN",
    "CBSA Code": 32820,
  },
  {
    RegionID: 394935,
    SizeRank: 42,
    RegionName: "Oklahoma City, OK",
    RegionType: "Msa",
    Metro: "Oklahoma City",
    State: "OK",
    "Metro, State": "Oklahoma City, OK",
    "CBSA Code": 36420,
  },
  {
    RegionID: 394807,
    SizeRank: 43,
    RegionName: "Louisville-Jefferson County, KY",
    RegionType: "Msa",
    Metro: "Louisville",
    State: "KY",
    "Metro, State": "Louisville, KY",
    "CBSA Code": 31140,
  },
  {
    RegionID: 394669,
    SizeRank: 44,
    RegionName: "Hartford, CT",
    RegionType: "Msa",
    Metro: "Hartford",
    State: "CT",
    "Metro, State": "Hartford, CT",
    "CBSA Code": 25540,
  },
  {
    RegionID: 395022,
    SizeRank: 45,
    RegionName: "Richmond, VA",
    RegionType: "Msa",
    Metro: "Richmond",
    State: "VA",
    "Metro, State": "Richmond, VA",
    "CBSA Code": 40060,
  },
  {
    RegionID: 394910,
    SizeRank: 46,
    RegionName: "New Orleans, LA",
    RegionType: "Msa",
    Metro: "New Orleans",
    State: "LA",
    "Metro, State": "New Orleans, LA",
    "CBSA Code": 35380,
  },
  {
    RegionID: 394425,
    SizeRank: 47,
    RegionName: "Buffalo, NY",
    RegionType: "Msa",
    Metro: "Buffalo",
    State: "NY",
    "Metro, State": "Buffalo, NY",
    "CBSA Code": 15380,
  },
  {
    RegionID: 395012,
    SizeRank: 48,
    RegionName: "Raleigh, NC",
    RegionType: "Msa",
    Metro: "Raleigh",
    State: "NC",
    "Metro, State": "Raleigh, NC",
    "CBSA Code": 39580,
  },
  {
    RegionID: 394388,
    SizeRank: 49,
    RegionName: "Birmingham, AL",
    RegionType: "Msa",
    Metro: "Birmingham",
    State: "AL",
    "Metro, State": "Birmingham, AL",
    "CBSA Code": 13820,
  },
  {
    RegionID: 395053,
    SizeRank: 50,
    RegionName: "Salt Lake City, UT",
    RegionType: "Msa",
    Metro: "Salt Lake City",
    State: "UT",
    "Metro, State": "Salt Lake City, UT",
    "CBSA Code": 41620,
  },
  {
    RegionID: 395031,
    SizeRank: 51,
    RegionName: "Rochester, NY",
    RegionType: "Msa",
    Metro: "Rochester",
    State: "NY",
    "Metro, State": "Rochester, NY",
    "CBSA Code": 40380,
  },
  {
    RegionID: 394640,
    SizeRank: 52,
    RegionName: "Grand Rapids, MI",
    RegionType: "Msa",
    Metro: "Grand Rapids",
    State: "MI",
    "Metro, State": "Grand Rapids, MI",
    "CBSA Code": 24340,
  },
  {
    RegionID: 395167,
    SizeRank: 53,
    RegionName: "Tucson, AZ",
    RegionType: "Msa",
    Metro: "Tucson",
    State: "AZ",
    "Metro, State": "Tucson, AZ",
    "CBSA Code": 46060,
  },
  {
    RegionID: 753924,
    SizeRank: 54,
    RegionName: "Urban Honolulu, HI",
    RegionType: "Msa",
    Metro: "Urban Honolulu",
    State: "HI",
    "Metro, State": "Urban Honolulu, HI",
    "CBSA Code": 46520,
  },
  {
    RegionID: 395169,
    SizeRank: 55,
    RegionName: "Tulsa, OK",
    RegionType: "Msa",
    Metro: "Tulsa",
    State: "OK",
    "Metro, State": "Tulsa, OK",
    "CBSA Code": 46140,
  },
  {
    RegionID: 394619,
    SizeRank: 56,
    RegionName: "Fresno, CA",
    RegionType: "Msa",
    Metro: "Fresno",
    State: "CA",
    "Metro, State": "Fresno, CA",
    "CBSA Code": 23420,
  },
  {
    RegionID: 395238,
    SizeRank: 57,
    RegionName: "Worcester, MA",
    RegionType: "Msa",
    Metro: "Worcester",
    State: "MA",
    "Metro, State": "Worcester, MA",
    "CBSA Code": 49340,
  },
  {
    RegionID: 394415,
    SizeRank: 58,
    RegionName: "Stamford, CT",
    RegionType: "Msa",
    Metro: "Stamford",
    State: "CT",
    "Metro, State": "Stamford, CT",
    "CBSA Code": 14860,
  },
  {
    RegionID: 394312,
    SizeRank: 59,
    RegionName: "Albuquerque, NM",
    RegionType: "Msa",
    Metro: "Albuquerque",
    State: "NM",
    "Metro, State": "Albuquerque, NM",
    "CBSA Code": 10740,
  },
  {
    RegionID: 394308,
    SizeRank: 60,
    RegionName: "Albany, NY",
    RegionType: "Msa",
    Metro: "Albany",
    State: "NY",
    "Metro, State": "Albany, NY",
    "CBSA Code": 10580,
  },
  {
    RegionID: 394938,
    SizeRank: 61,
    RegionName: "Omaha, NE",
    RegionType: "Msa",
    Metro: "Omaha",
    State: "NE",
    "Metro, State": "Omaha, NE",
    "CBSA Code": 36540,
  },
  {
    RegionID: 394908,
    SizeRank: 62,
    RegionName: "New Haven, CT",
    RegionType: "Msa",
    Metro: "New Haven",
    State: "CT",
    "Metro, State": "New Haven, CT",
    "CBSA Code": 35300,
  },
  {
    RegionID: 394357,
    SizeRank: 63,
    RegionName: "Bakersfield, CA",
    RegionType: "Msa",
    Metro: "Bakersfield",
    State: "CA",
    "Metro, State": "Bakersfield, CA",
    "CBSA Code": 12540,
  },
  {
    RegionID: 394753,
    SizeRank: 64,
    RegionName: "Knoxville, TN",
    RegionType: "Msa",
    Metro: "Knoxville",
    State: "TN",
    "Metro, State": "Knoxville, TN",
    "CBSA Code": 28940,
  },
  {
    RegionID: 394653,
    SizeRank: 65,
    RegionName: "Greenville, SC",
    RegionType: "Msa",
    Metro: "Greenville",
    State: "SC",
    "Metro, State": "Greenville, SC",
    "CBSA Code": 24860,
  },
  {
    RegionID: 394952,
    SizeRank: 66,
    RegionName: "Ventura, CA",
    RegionType: "Msa",
    Metro: "Ventura",
    State: "CA",
    "Metro, State": "Ventura, CA",
    "CBSA Code": 37100,
  },
  {
    RegionID: 394318,
    SizeRank: 67,
    RegionName: "Allentown, PA",
    RegionType: "Msa",
    Metro: "Allentown",
    State: "PA",
    "Metro, State": "Allentown, PA",
    "CBSA Code": 10900,
  },
  {
    RegionID: 394561,
    SizeRank: 68,
    RegionName: "El Paso, TX",
    RegionType: "Msa",
    Metro: "El Paso",
    State: "TX",
    "Metro, State": "El Paso, TX",
    "CBSA Code": 21340,
  },
  {
    RegionID: 394367,
    SizeRank: 69,
    RegionName: "Baton Rouge, LA",
    RegionType: "Msa",
    Metro: "Baton Rouge",
    State: "LA",
    "Metro, State": "Baton Rouge, LA",
    "CBSA Code": 12940,
  },
  {
    RegionID: 394521,
    SizeRank: 70,
    RegionName: "Dayton, OH",
    RegionType: "Msa",
    Metro: "Dayton",
    State: "OH",
    "Metro, State": "Dayton, OH",
    "CBSA Code": 19430,
  },
  {
    RegionID: 394843,
    SizeRank: 71,
    RegionName: "McAllen, TX",
    RegionType: "Msa",
    Metro: "McAllen",
    State: "TX",
    "Metro, State": "McAllen, TX",
    "CBSA Code": 32580,
  },
  {
    RegionID: 394486,
    SizeRank: 72,
    RegionName: "Columbia, SC",
    RegionType: "Msa",
    Metro: "Columbia",
    State: "SC",
    "Metro, State": "Columbia, SC",
    "CBSA Code": 17900,
  },
  {
    RegionID: 394648,
    SizeRank: 73,
    RegionName: "Greensboro, NC",
    RegionType: "Msa",
    Metro: "Greensboro",
    State: "NC",
    "Metro, State": "Greensboro, NC",
    "CBSA Code": 24660,
  },
  {
    RegionID: 394304,
    SizeRank: 74,
    RegionName: "Akron, OH",
    RegionType: "Msa",
    Metro: "Akron",
    State: "OH",
    "Metro, State": "Akron, OH",
    "CBSA Code": 10420,
  },
  {
    RegionID: 753906,
    SizeRank: 75,
    RegionName: "North Port-Sarasota-Bradenton, FL",
    RegionType: "Msa",
    Metro: "North Port",
    State: "FL",
    "Metro, State": "North Port, FL",
    "CBSA Code": 35840,
  },
  {
    RegionID: 394798,
    SizeRank: 76,
    RegionName: "Little Rock, AR",
    RegionType: "Msa",
    Metro: "Little Rock",
    State: "AR",
    "Metro, State": "Little Rock, AR",
    "CBSA Code": 30780,
  },
  {
    RegionID: 395134,
    SizeRank: 77,
    RegionName: "Stockton, CA",
    RegionType: "Msa",
    Metro: "Stockton",
    State: "CA",
    "Metro, State": "Stockton, CA",
    "CBSA Code": 44700,
  },
  {
    RegionID: 394457,
    SizeRank: 78,
    RegionName: "Charleston, SC",
    RegionType: "Msa",
    Metro: "Charleston",
    State: "SC",
    "Metro, State": "Charleston, SC",
    "CBSA Code": 16700,
  },
  {
    RegionID: 395143,
    SizeRank: 79,
    RegionName: "Syracuse, NY",
    RegionType: "Msa",
    Metro: "Syracuse",
    State: "NY",
    "Metro, State": "Syracuse, NY",
    "CBSA Code": 45060,
  },
  {
    RegionID: 394484,
    SizeRank: 80,
    RegionName: "Colorado Springs, CO",
    RegionType: "Msa",
    Metro: "Colorado Springs",
    State: "CO",
    "Metro, State": "Colorado Springs, CO",
    "CBSA Code": 17820,
  },
  {
    RegionID: 395235,
    SizeRank: 81,
    RegionName: "Winston-Salem, NC",
    RegionType: "Msa",
    Metro: "Winston",
    State: "NC",
    "Metro, State": "Winston, NC",
    "CBSA Code": 49180,
  },
  {
    RegionID: 395224,
    SizeRank: 82,
    RegionName: "Wichita, KS",
    RegionType: "Msa",
    Metro: "Wichita",
    State: "KS",
    "Metro, State": "Wichita, KS",
    "CBSA Code": 48620,
  },
  {
    RegionID: 395115,
    SizeRank: 83,
    RegionName: "Springfield, MA",
    RegionType: "Msa",
    Metro: "Springfield",
    State: "MA",
    "Metro, State": "Springfield, MA",
    "CBSA Code": 44140,
  },
  {
    RegionID: 394440,
    SizeRank: 84,
    RegionName: "Fort Myers, FL",
    RegionType: "Msa",
    Metro: "Fort Myers",
    State: "FL",
    "Metro, State": "Fort Myers, FL",
    "CBSA Code": 15980,
  },
  {
    RegionID: 394399,
    SizeRank: 85,
    RegionName: "Boise City, ID",
    RegionType: "Msa",
    Metro: "Boise City",
    State: "ID",
    "Metro, State": "Boise City, ID",
    "CBSA Code": 14260,
  },
  {
    RegionID: 395160,
    SizeRank: 86,
    RegionName: "Toledo, OH",
    RegionType: "Msa",
    Metro: "Toledo",
    State: "OH",
    "Metro, State": "Toledo, OH",
    "CBSA Code": 45780,
  },
  {
    RegionID: 394816,
    SizeRank: 87,
    RegionName: "Madison, WI",
    RegionType: "Msa",
    Metro: "Madison",
    State: "WI",
    "Metro, State": "Madison, WI",
    "CBSA Code": 31540,
  },
  {
    RegionID: 394766,
    SizeRank: 88,
    RegionName: "Lakeland, FL",
    RegionType: "Msa",
    Metro: "Lakeland",
    State: "FL",
    "Metro, State": "Lakeland, FL",
    "CBSA Code": 29460,
  },
  {
    RegionID: 394931,
    SizeRank: 89,
    RegionName: "Ogden, UT",
    RegionType: "Msa",
    Metro: "Ogden",
    State: "UT",
    "Metro, State": "Ogden, UT",
    "CBSA Code": 36260,
  },
  {
    RegionID: 394528,
    SizeRank: 90,
    RegionName: "Daytona Beach, FL",
    RegionType: "Msa",
    Metro: "Daytona Beach",
    State: "FL",
    "Metro, State": "Daytona Beach, FL",
    "CBSA Code": 19660,
  },
  {
    RegionID: 394531,
    SizeRank: 91,
    RegionName: "Des Moines, IA",
    RegionType: "Msa",
    Metro: "Des Moines",
    State: "IA",
    "Metro, State": "Des Moines, IA",
    "CBSA Code": 19780,
  },
  {
    RegionID: 394711,
    SizeRank: 92,
    RegionName: "Jackson, MS",
    RegionType: "Msa",
    Metro: "Jackson",
    State: "MS",
    "Metro, State": "Jackson, MS",
    "CBSA Code": 27140,
  },
  {
    RegionID: 395245,
    SizeRank: 93,
    RegionName: "Youngstown, OH",
    RegionType: "Msa",
    Metro: "Youngstown",
    State: "OH",
    "Metro, State": "Youngstown, OH",
    "CBSA Code": 49660,
  },
  {
    RegionID: 394352,
    SizeRank: 94,
    RegionName: "Augusta, GA",
    RegionType: "Msa",
    Metro: "Augusta",
    State: "GA",
    "Metro, State": "Augusta, GA",
    "CBSA Code": 12260,
  },
  {
    RegionID: 395075,
    SizeRank: 95,
    RegionName: "Scranton, PA",
    RegionType: "Msa",
    Metro: "Scranton",
    State: "PA",
    "Metro, State": "Scranton, PA",
    "CBSA Code": 42540,
  },
  {
    RegionID: 394666,
    SizeRank: 96,
    RegionName: "Harrisburg, PA",
    RegionType: "Msa",
    Metro: "Harrisburg",
    State: "PA",
    "Metro, State": "Harrisburg, PA",
    "CBSA Code": 25420,
  },
  {
    RegionID: 394957,
    SizeRank: 97,
    RegionName: "Melbourne, FL",
    RegionType: "Msa",
    Metro: "Melbourne",
    State: "FL",
    "Metro, State": "Melbourne, FL",
    "CBSA Code": 37340,
  },
  {
    RegionID: 394460,
    SizeRank: 98,
    RegionName: "Chattanooga, TN",
    RegionType: "Msa",
    Metro: "Chattanooga",
    State: "TN",
    "Metro, State": "Chattanooga, TN",
    "CBSA Code": 16860,
  },
  {
    RegionID: 395113,
    SizeRank: 99,
    RegionName: "Spokane, WA",
    RegionType: "Msa",
    Metro: "Spokane",
    State: "WA",
    "Metro, State": "Spokane, WA",
    "CBSA Code": 44060,
  },
  {
    RegionID: 395006,
    SizeRank: 100,
    RegionName: "Provo, UT",
    RegionType: "Msa",
    Metro: "Provo",
    State: "UT",
    "Metro, State": "Provo, UT",
    "CBSA Code": 39340,
  },
  {
    RegionID: 394768,
    SizeRank: 101,
    RegionName: "Lancaster, PA",
    RegionType: "Msa",
    Metro: "Lancaster",
    State: "PA",
    "Metro, State": "Lancaster, PA",
    "CBSA Code": 29540,
  },
  {
    RegionID: 394871,
    SizeRank: 102,
    RegionName: "Modesto, CA",
    RegionType: "Msa",
    Metro: "Modesto",
    State: "CA",
    "Metro, State": "Modesto, CA",
    "CBSA Code": 33700,
  },
  {
    RegionID: 394997,
    SizeRank: 103,
    RegionName: "Portland, ME",
    RegionType: "Msa",
    Metro: "Portland",
    State: "ME",
    "Metro, State": "Portland, ME",
    "CBSA Code": 38860,
  },
  {
    RegionID: 394549,
    SizeRank: 104,
    RegionName: "Durham, NC",
    RegionType: "Msa",
    Metro: "Durham",
    State: "NC",
    "Metro, State": "Durham, NC",
    "CBSA Code": 20500,
  },
  {
    RegionID: 395068,
    SizeRank: 105,
    RegionName: "Santa Rosa, CA",
    RegionType: "Msa",
    Metro: "Santa Rosa",
    State: "CA",
    "Metro, State": "Santa Rosa, CA",
    "CBSA Code": 42220,
  },
  {
    RegionID: 394792,
    SizeRank: 106,
    RegionName: "Lexington, KY",
    RegionType: "Msa",
    Metro: "Lexington",
    State: "KY",
    "Metro, State": "Lexington, KY",
    "CBSA Code": 30460,
  },
  {
    RegionID: 394761,
    SizeRank: 107,
    RegionName: "Lafayette, LA",
    RegionType: "Msa",
    Metro: "Lafayette",
    State: "LA",
    "Metro, State": "Lafayette, LA",
    "CBSA Code": 29180,
  },
  {
    RegionID: 394770,
    SizeRank: 108,
    RegionName: "Lansing, MI",
    RegionType: "Msa",
    Metro: "Lansing",
    State: "MI",
    "Metro, State": "Lansing, MI",
    "CBSA Code": 29620,
  },
  {
    RegionID: 394590,
    SizeRank: 109,
    RegionName: "Fayetteville, AR",
    RegionType: "Msa",
    Metro: "Fayetteville",
    State: "AR",
    "Metro, State": "Fayetteville, AR",
    "CBSA Code": 22220,
  },
  {
    RegionID: 394971,
    SizeRank: 110,
    RegionName: "Pensacola, FL",
    RegionType: "Msa",
    Metro: "Pensacola",
    State: "FL",
    "Metro, State": "Pensacola, FL",
    "CBSA Code": 37860,
  },
  {
    RegionID: 395195,
    SizeRank: 111,
    RegionName: "Visalia, CA",
    RegionType: "Msa",
    Metro: "Visalia",
    State: "CA",
    "Metro, State": "Visalia, CA",
    "CBSA Code": 47300,
  },
  {
    RegionID: 395096,
    SizeRank: 112,
    RegionName: "Shreveport, LA",
    RegionType: "Msa",
    Metro: "Shreveport",
    State: "LA",
    "Metro, State": "Shreveport, LA",
    "CBSA Code": 43340,
  },
  {
    RegionID: 395116,
    SizeRank: 113,
    RegionName: "Springfield, MO",
    RegionType: "Msa",
    Metro: "Springfield",
    State: "MO",
    "Metro, State": "Springfield, MO",
    "CBSA Code": 44180,
  },
  {
    RegionID: 395244,
    SizeRank: 114,
    RegionName: "York, PA",
    RegionType: "Msa",
    Metro: "York",
    State: "PA",
    "Metro, State": "York, PA",
    "CBSA Code": 49620,
  },
  {
    RegionID: 394502,
    SizeRank: 115,
    RegionName: "Corpus Christi, TX",
    RegionType: "Msa",
    Metro: "Corpus Christi",
    State: "TX",
    "Metro, State": "Corpus Christi, TX",
    "CBSA Code": 18580,
  },
  {
    RegionID: 394596,
    SizeRank: 116,
    RegionName: "Flint, MI",
    RegionType: "Msa",
    Metro: "Flint",
    State: "MI",
    "Metro, State": "Flint, MI",
    "CBSA Code": 22420,
  },
  {
    RegionID: 395019,
    SizeRank: 117,
    RegionName: "Reno, NV",
    RegionType: "Msa",
    Metro: "Reno",
    State: "NV",
    "Metro, State": "Reno, NV",
    "CBSA Code": 39900,
  },
  {
    RegionID: 394338,
    SizeRank: 118,
    RegionName: "Asheville, NC",
    RegionType: "Msa",
    Metro: "Asheville",
    State: "NC",
    "Metro, State": "Asheville, NC",
    "CBSA Code": 11700,
  },
  {
    RegionID: 394995,
    SizeRank: 119,
    RegionName: "Port St. Lucie, FL",
    RegionType: "Msa",
    Metro: "Port St. Lucie",
    State: "FL",
    "Metro, State": "Port St. Lucie, FL",
    "CBSA Code": 38940,
  },
  {
    RegionID: 753917,
    SizeRank: 120,
    RegionName: "Santa Maria-Santa Barbara, CA",
    RegionType: "Msa",
    Metro: "Santa Maria",
    State: "CA",
    "Metro, State": "Santa Maria, CA",
    "CBSA Code": 42200,
  },
  {
    RegionID: 394698,
    SizeRank: 121,
    RegionName: "Huntsville, AL",
    RegionType: "Msa",
    Metro: "Huntsville",
    State: "AL",
    "Metro, State": "Huntsville, AL",
    "CBSA Code": 26620,
  },
  {
    RegionID: 394612,
    SizeRank: 122,
    RegionName: "Fort Wayne, IN",
    RegionType: "Msa",
    Metro: "Fort Wayne",
    State: "IN",
    "Metro, State": "Fort Wayne, IN",
    "CBSA Code": 23060,
  },
  {
    RegionID: 395050,
    SizeRank: 123,
    RegionName: "Salinas, CA",
    RegionType: "Msa",
    Metro: "Salinas",
    State: "CA",
    "Metro, State": "Salinas, CA",
    "CBSA Code": 41500,
  },
  {
    RegionID: 395183,
    SizeRank: 124,
    RegionName: "Vallejo, CA",
    RegionType: "Msa",
    Metro: "Vallejo",
    State: "CA",
    "Metro, State": "Vallejo, CA",
    "CBSA Code": 46700,
  },
  {
    RegionID: 394870,
    SizeRank: 125,
    RegionName: "Mobile, AL",
    RegionType: "Msa",
    Metro: "Mobile",
    State: "AL",
    "Metro, State": "Mobile, AL",
    "CBSA Code": 33660,
  },
  {
    RegionID: 395015,
    SizeRank: 126,
    RegionName: "Reading, PA",
    RegionType: "Msa",
    Metro: "Reading",
    State: "PA",
    "Metro, State": "Reading, PA",
    "CBSA Code": 39740,
  },
  {
    RegionID: 394421,
    SizeRank: 127,
    RegionName: "Brownsville, TX",
    RegionType: "Msa",
    Metro: "Brownsville",
    State: "TX",
    "Metro, State": "Brownsville, TX",
    "CBSA Code": 15180,
  },
  {
    RegionID: 394746,
    SizeRank: 128,
    RegionName: "Killeen, TX",
    RegionType: "Msa",
    Metro: "Killeen",
    State: "TX",
    "Metro, State": "Killeen, TX",
    "CBSA Code": 28660,
  },
  {
    RegionID: 394439,
    SizeRank: 129,
    RegionName: "Canton, OH",
    RegionType: "Msa",
    Metro: "Canton",
    State: "OH",
    "Metro, State": "Canton, OH",
    "CBSA Code": 15940,
  },
  {
    RegionID: 394372,
    SizeRank: 130,
    RegionName: "Beaumont, TX",
    RegionType: "Msa",
    Metro: "Beaumont",
    State: "TX",
    "Metro, State": "Beaumont, TX",
    "CBSA Code": 13140,
  },
  {
    RegionID: 394820,
    SizeRank: 131,
    RegionName: "Manchester, NH",
    RegionType: "Msa",
    Metro: "Manchester",
    State: "NH",
    "Metro, State": "Manchester, NH",
    "CBSA Code": 31700,
  },
  {
    RegionID: 395048,
    SizeRank: 132,
    RegionName: "Salem, OR",
    RegionType: "Msa",
    Metro: "Salem",
    State: "OR",
    "Metro, State": "Salem, OR",
    "CBSA Code": 41420,
  },
  {
    RegionID: 394327,
    SizeRank: 133,
    RegionName: "Anchorage, AK",
    RegionType: "Msa",
    Metro: "Anchorage",
    State: "AK",
    "Metro, State": "Anchorage, AK",
    "CBSA Code": 11260,
  },
  {
    RegionID: 394520,
    SizeRank: 134,
    RegionName: "Davenport, IL",
    RegionType: "Msa",
    Metro: "Davenport",
    State: "IA",
    "Metro, State": "Davenport, IA",
    "CBSA Code": 19340,
  },
  {
    RegionID: 394972,
    SizeRank: 135,
    RegionName: "Peoria, IL",
    RegionType: "Msa",
    Metro: "Peoria",
    State: "IL",
    "Metro, State": "Peoria, IL",
    "CBSA Code": 37900,
  },
  {
    RegionID: 394898,
    SizeRank: 136,
    RegionName: "Myrtle Beach, SC",
    RegionType: "Msa",
    Metro: "Myrtle Beach",
    State: "SC",
    "Metro, State": "Myrtle Beach, SC",
    "CBSA Code": 34820,
  },
  {
    RegionID: 394875,
    SizeRank: 137,
    RegionName: "Montgomery, AL",
    RegionType: "Msa",
    Metro: "Montgomery",
    State: "AL",
    "Metro, State": "Montgomery, AL",
    "CBSA Code": 33860,
  },
  {
    RegionID: 395051,
    SizeRank: 138,
    RegionName: "Salisbury, MD",
    RegionType: "Msa",
    Metro: "Salisbury",
    State: "MD",
    "Metro, State": "Salisbury, MD",
    "CBSA Code": 41540,
  },
  {
    RegionID: 394658,
    SizeRank: 139,
    RegionName: "Gulfport, MS",
    RegionType: "Msa",
    Metro: "Gulfport",
    State: "MS",
    "Metro, State": "Gulfport, MS",
    "CBSA Code": 25060,
  },
  {
    RegionID: 395146,
    SizeRank: 140,
    RegionName: "Tallahassee, FL",
    RegionType: "Msa",
    Metro: "Tallahassee",
    State: "FL",
    "Metro, State": "Tallahassee, FL",
    "CBSA Code": 45220,
  },
  {
    RegionID: 395164,
    SizeRank: 141,
    RegionName: "Trenton, NJ",
    RegionType: "Msa",
    Metro: "Trenton",
    State: "NJ",
    "Metro, State": "Trenton, NJ",
    "CBSA Code": 45940,
  },
  {
    RegionID: 394589,
    SizeRank: 142,
    RegionName: "Fayetteville, NC",
    RegionType: "Msa",
    Metro: "Fayetteville",
    State: "NC",
    "Metro, State": "Fayetteville, NC",
    "CBSA Code": 22180,
  },
  {
    RegionID: 394679,
    SizeRank: 143,
    RegionName: "Hickory, NC",
    RegionType: "Msa",
    Metro: "Hickory",
    State: "NC",
    "Metro, State": "Hickory, NC",
    "CBSA Code": 25860,
  },
  {
    RegionID: 394697,
    SizeRank: 144,
    RegionName: "Huntington, WV",
    RegionType: "Msa",
    Metro: "Huntington",
    State: "WV",
    "Metro, State": "Huntington, WV",
    "CBSA Code": 26580,
  },
  {
    RegionID: 394576,
    SizeRank: 145,
    RegionName: "Eugene, OR",
    RegionType: "Msa",
    Metro: "Eugene",
    State: "OR",
    "Metro, State": "Eugene, OR",
    "CBSA Code": 21660,
  },
  {
    RegionID: 395033,
    SizeRank: 146,
    RegionName: "Rockford, IL",
    RegionType: "Msa",
    Metro: "Rockford",
    State: "IL",
    "Metro, State": "Rockford, IL",
    "CBSA Code": 40420,
  },
  {
    RegionID: 395070,
    SizeRank: 147,
    RegionName: "Savannah, GA",
    RegionType: "Msa",
    Metro: "Savannah",
    State: "GA",
    "Metro, State": "Savannah, GA",
    "CBSA Code": 42340,
  },
  {
    RegionID: 394332,
    SizeRank: 148,
    RegionName: "Ann Arbor, MI",
    RegionType: "Msa",
    Metro: "Ann Arbor",
    State: "MI",
    "Metro, State": "Ann Arbor, MI",
    "CBSA Code": 11460,
  },
  {
    RegionID: 394927,
    SizeRank: 149,
    RegionName: "Ocala, FL",
    RegionType: "Msa",
    Metro: "Ocala",
    State: "FL",
    "Metro, State": "Ocala, FL",
    "CBSA Code": 36100,
  },
  {
    RegionID: 394732,
    SizeRank: 150,
    RegionName: "Kalamazoo, MI",
    RegionType: "Msa",
    Metro: "Kalamazoo",
    State: "MI",
    "Metro, State": "Kalamazoo, MI",
    "CBSA Code": 28020,
  },
  {
    RegionID: 394901,
    SizeRank: 151,
    RegionName: "Naples, FL",
    RegionType: "Msa",
    Metro: "Naples",
    State: "FL",
    "Metro, State": "Naples, FL",
    "CBSA Code": 34940,
  },
  {
    RegionID: 395107,
    SizeRank: 152,
    RegionName: "South Bend, IN",
    RegionType: "Msa",
    Metro: "South Bend",
    State: "IN",
    "Metro, State": "South Bend, IN",
    "CBSA Code": 43780,
  },
  {
    RegionID: 395109,
    SizeRank: 153,
    RegionName: "Spartanburg, SC",
    RegionType: "Msa",
    Metro: "Spartanburg",
    State: "SC",
    "Metro, State": "Spartanburg, SC",
    "CBSA Code": 43900,
  },
  {
    RegionID: 394579,
    SizeRank: 154,
    RegionName: "Evansville, IN",
    RegionType: "Msa",
    Metro: "Evansville",
    State: "IN",
    "Metro, State": "Evansville, IN",
    "CBSA Code": 21780,
  },
  {
    RegionID: 394747,
    SizeRank: 155,
    RegionName: "Kingsport, TN",
    RegionType: "Msa",
    Metro: "Kingsport",
    State: "TN",
    "Metro, State": "Kingsport, TN",
    "CBSA Code": 28700,
  },
  {
    RegionID: 395028,
    SizeRank: 156,
    RegionName: "Roanoke, VA",
    RegionType: "Msa",
    Metro: "Roanoke",
    State: "VA",
    "Metro, State": "Roanoke, VA",
    "CBSA Code": 40220,
  },
  {
    RegionID: 394646,
    SizeRank: 157,
    RegionName: "Green Bay, WI",
    RegionType: "Msa",
    Metro: "Green Bay",
    State: "WI",
    "Metro, State": "Green Bay, WI",
    "CBSA Code": 24580,
  },
  {
    RegionID: 394796,
    SizeRank: 158,
    RegionName: "Lincoln, NE",
    RegionType: "Msa",
    Metro: "Lincoln",
    State: "NE",
    "Metro, State": "Lincoln, NE",
    "CBSA Code": 30700,
  },
  {
    RegionID: 394602,
    SizeRank: 159,
    RegionName: "Fort Collins, CO",
    RegionType: "Msa",
    Metro: "Fort Collins",
    State: "CO",
    "Metro, State": "Fort Collins, CO",
    "CBSA Code": 22660,
  },
  {
    RegionID: 395179,
    SizeRank: 160,
    RegionName: "Utica, NY",
    RegionType: "Msa",
    Metro: "Utica",
    State: "NY",
    "Metro, State": "Utica, NY",
    "CBSA Code": 46540,
  },
  {
    RegionID: 394488,
    SizeRank: 161,
    RegionName: "Columbus, GA",
    RegionType: "Msa",
    Metro: "Columbus",
    State: "GA",
    "Metro, State": "Columbus, GA",
    "CBSA Code": 17980,
  },
  {
    RegionID: 394405,
    SizeRank: 162,
    RegionName: "Boulder, CO",
    RegionType: "Msa",
    Metro: "Boulder",
    State: "CO",
    "Metro, State": "Boulder, CO",
    "CBSA Code": 14500,
  },
  {
    RegionID: 394808,
    SizeRank: 163,
    RegionName: "Lubbock, TX",
    RegionType: "Msa",
    Metro: "Lubbock",
    State: "TX",
    "Metro, State": "Lubbock, TX",
    "CBSA Code": 31180,
  },
  {
    RegionID: 394572,
    SizeRank: 164,
    RegionName: "Erie, PA",
    RegionType: "Msa",
    Metro: "Erie",
    State: "PA",
    "Metro, State": "Erie, PA",
    "CBSA Code": 21500,
  },
  {
    RegionID: 394609,
    SizeRank: 165,
    RegionName: "Fort Smith, AR",
    RegionType: "Msa",
    Metro: "Fort Smith",
    State: "AR",
    "Metro, State": "Fort Smith, AR",
    "CBSA Code": 22900,
  },
  {
    RegionID: 394543,
    SizeRank: 166,
    RegionName: "Duluth, MN",
    RegionType: "Msa",
    Metro: "Duluth",
    State: "MN",
    "Metro, State": "Duluth, MN",
    "CBSA Code": 20260,
  },
  {
    RegionID: 394348,
    SizeRank: 167,
    RegionName: "Atlantic City, NJ",
    RegionType: "Msa",
    Metro: "Atlantic City",
    State: "NJ",
    "Metro, State": "Atlantic City, NJ",
    "CBSA Code": 12100,
  },
  {
    RegionID: 394924,
    SizeRank: 168,
    RegionName: "New London, CT",
    RegionType: "Msa",
    Metro: "New London",
    State: "CT",
    "Metro, State": "New London, CT",
    "CBSA Code": 35980,
  },
  {
    RegionID: 395061,
    SizeRank: 169,
    RegionName: "San Luis Obispo, CA",
    RegionType: "Msa",
    Metro: "San Luis Obispo",
    State: "CA",
    "Metro, State": "San Luis Obispo, CA",
    "CBSA Code": 42020,
  },
  {
    RegionID: 394622,
    SizeRank: 170,
    RegionName: "Gainesville, FL",
    RegionType: "Msa",
    Metro: "Gainesville",
    State: "FL",
    "Metro, State": "Gainesville, FL",
    "CBSA Code": 23540,
  },
  {
    RegionID: 395065,
    SizeRank: 171,
    RegionName: "Santa Cruz, CA",
    RegionType: "Msa",
    Metro: "Santa Cruz",
    State: "CA",
    "Metro, State": "Santa Cruz, CA",
    "CBSA Code": 42100,
  },
  {
    RegionID: 394471,
    SizeRank: 172,
    RegionName: "Clarksville, TN",
    RegionType: "Msa",
    Metro: "Clarksville",
    State: "TN",
    "Metro, State": "Clarksville, TN",
    "CBSA Code": 17300,
  },
  {
    RegionID: 394447,
    SizeRank: 173,
    RegionName: "Cedar Rapids, IA",
    RegionType: "Msa",
    Metro: "Cedar Rapids",
    State: "IA",
    "Metro, State": "Cedar Rapids, IA",
    "CBSA Code": 16300,
  },
  {
    RegionID: 394851,
    SizeRank: 174,
    RegionName: "Merced, CA",
    RegionType: "Msa",
    Metro: "Merced",
    State: "CA",
    "Metro, State": "Merced, CA",
    "CBSA Code": 32900,
  },
  {
    RegionID: 395229,
    SizeRank: 175,
    RegionName: "Wilmington, NC",
    RegionType: "Msa",
    Metro: "Wilmington",
    State: "NC",
    "Metro, State": "Wilmington, NC",
    "CBSA Code": 48900,
  },
  {
    RegionID: 394741,
    SizeRank: 176,
    RegionName: "Kennewick, WA",
    RegionType: "Msa",
    Metro: "Kennewick",
    State: "WA",
    "Metro, State": "Kennewick, WA",
    "CBSA Code": 28420,
  },
  {
    RegionID: 394645,
    SizeRank: 177,
    RegionName: "Greeley, CO",
    RegionType: "Msa",
    Metro: "Greeley",
    State: "CO",
    "Metro, State": "Greeley, CO",
    "CBSA Code": 24540,
  },
  {
    RegionID: 395197,
    SizeRank: 178,
    RegionName: "Waco, TX",
    RegionType: "Msa",
    Metro: "Waco",
    State: "TX",
    "Metro, State": "Waco, TX",
    "CBSA Code": 47380,
  },
  {
    RegionID: 394937,
    SizeRank: 179,
    RegionName: "Olympia, WA",
    RegionType: "Msa",
    Metro: "Olympia",
    State: "WA",
    "Metro, State": "Olympia, WA",
    "CBSA Code": 36500,
  },
  {
    RegionID: 394323,
    SizeRank: 180,
    RegionName: "Amarillo, TX",
    RegionType: "Msa",
    Metro: "Amarillo",
    State: "TX",
    "Metro, State": "Amarillo, TX",
    "CBSA Code": 11100,
  },
  {
    RegionID: 394387,
    SizeRank: 181,
    RegionName: "Binghamton, NY",
    RegionType: "Msa",
    Metro: "Binghamton",
    State: "NY",
    "Metro, State": "Binghamton, NY",
    "CBSA Code": 13780,
  },
  {
    RegionID: 394660,
    SizeRank: 182,
    RegionName: "Hagerstown, WV",
    RegionType: "Msa",
    Metro: "Hagerstown",
    State: "MD",
    "Metro, State": "Hagerstown, MD",
    "CBSA Code": 25180,
  },
  {
    RegionID: 394412,
    SizeRank: 183,
    RegionName: "Bremerton, WA",
    RegionType: "Msa",
    Metro: "Bremerton",
    State: "WA",
    "Metro, State": "Bremerton, WA",
    "CBSA Code": 14740,
  },
  {
    RegionID: 394772,
    SizeRank: 184,
    RegionName: "Laredo, TX",
    RegionType: "Msa",
    Metro: "Laredo",
    State: "TX",
    "Metro, State": "Laredo, TX",
    "CBSA Code": 29700,
  },
  {
    RegionID: 394811,
    SizeRank: 185,
    RegionName: "Lynchburg, VA",
    RegionType: "Msa",
    Metro: "Lynchburg",
    State: "VA",
    "Metro, State": "Lynchburg, VA",
    "CBSA Code": 31340,
  },
  {
    RegionID: 395240,
    SizeRank: 186,
    RegionName: "Yakima, WA",
    RegionType: "Msa",
    Metro: "Yakima",
    State: "WA",
    "Metro, State": "Yakima, WA",
    "CBSA Code": 49420,
  },
  {
    RegionID: 753875,
    SizeRank: 187,
    RegionName: "Crestview-Fort Walton Beach-Destin, FL",
    RegionType: "Msa",
    Metro: "Crestview",
    State: "FL",
    "Metro, State": "Crestview, FL",
    "CBSA Code": 18880,
  },
  {
    RegionID: 395161,
    SizeRank: 188,
    RegionName: "Topeka, KS",
    RegionType: "Msa",
    Metro: "Topeka",
    State: "KS",
    "Metro, State": "Topeka, KS",
    "CBSA Code": 45820,
  },
  {
    RegionID: 394813,
    SizeRank: 189,
    RegionName: "Macon-Bibb County, GA",
    RegionType: "Msa",
    Metro: "Macon",
    State: "GA",
    "Metro, State": "Macon, GA",
    "CBSA Code": 31420,
  },
  {
    RegionID: 394454,
    SizeRank: 190,
    RegionName: "Champaign-Urbana, IL",
    RegionType: "Msa",
    Metro: "Champaign",
    State: "IL",
    "Metro, State": "Champaign, IL",
    "CBSA Code": 16580,
  },
  {
    RegionID: 395171,
    SizeRank: 191,
    RegionName: "Tuscaloosa, AL",
    RegionType: "Msa",
    Metro: "Tuscaloosa",
    State: "AL",
    "Metro, State": "Tuscaloosa, AL",
    "CBSA Code": 46220,
  },
  {
    RegionID: 394483,
    SizeRank: 192,
    RegionName: "College Station, TX",
    RegionType: "Msa",
    Metro: "College Station",
    State: "TX",
    "Metro, State": "College Station, TX",
    "CBSA Code": 17780,
  },
  {
    RegionID: 395103,
    SizeRank: 193,
    RegionName: "Sioux Falls, SD",
    RegionType: "Msa",
    Metro: "Sioux Falls",
    State: "SD",
    "Metro, State": "Sioux Falls, SD",
    "CBSA Code": 43620,
  },
  {
    RegionID: 394455,
    SizeRank: 194,
    RegionName: "Charleston, WV",
    RegionType: "Msa",
    Metro: "Charleston",
    State: "WV",
    "Metro, State": "Charleston, WV",
    "CBSA Code": 16620,
  },
  {
    RegionID: 394334,
    SizeRank: 195,
    RegionName: "Appleton, WI",
    RegionType: "Msa",
    Metro: "Appleton",
    State: "WI",
    "Metro, State": "Appleton, WI",
    "CBSA Code": 11540,
  },
  {
    RegionID: 394464,
    SizeRank: 196,
    RegionName: "Chico, CA",
    RegionType: "Msa",
    Metro: "Chico",
    State: "CA",
    "Metro, State": "Chico, CA",
    "CBSA Code": 17020,
  },
  {
    RegionID: 394459,
    SizeRank: 197,
    RegionName: "Charlottesville, VA",
    RegionType: "Msa",
    Metro: "Charlottesville",
    State: "VA",
    "Metro, State": "Charlottesville, VA",
    "CBSA Code": 16820,
  },
  {
    RegionID: 394468,
    SizeRank: 198,
    RegionName: "Claremont, NH",
    RegionType: "Msa",
    Metro: "Claremont",
    State: "NH",
    "Metro, State": "Claremont, NH",
    "CBSA Code": "-",
  },
  {
    RegionID: 394361,
    SizeRank: 199,
    RegionName: "Barnstable Town, MA",
    RegionType: "Msa",
    Metro: "Barnstable Town",
    State: "MA",
    "Metro, State": "Barnstable Town, MA",
    "CBSA Code": 12700,
  },
  {
    RegionID: 394803,
    SizeRank: 200,
    RegionName: "Longview, TX",
    RegionType: "Msa",
    Metro: "Longview",
    State: "TX",
    "Metro, State": "Longview, TX",
    "CBSA Code": 30980,
  },
  {
    RegionID: 394429,
    SizeRank: 201,
    RegionName: "Burlington, VT",
    RegionType: "Msa",
    Metro: "Burlington",
    State: "VT",
    "Metro, State": "Burlington, VT",
    "CBSA Code": 15540,
  },
  {
    RegionID: 395002,
    SizeRank: 202,
    RegionName: "Prescott, AZ",
    RegionType: "Msa",
    Metro: "Prescott",
    State: "AZ",
    "Metro, State": "Prescott, AZ",
    "CBSA Code": 39150,
  },
  {
    RegionID: 395114,
    SizeRank: 203,
    RegionName: "Springfield, IL",
    RegionType: "Msa",
    Metro: "Springfield",
    State: "IL",
    "Metro, State": "Springfield, IL",
    "CBSA Code": 44100,
  },
  {
    RegionID: 395174,
    SizeRank: 204,
    RegionName: "Tyler, TX",
    RegionType: "Msa",
    Metro: "Tyler",
    State: "TX",
    "Metro, State": "Tyler, TX",
    "CBSA Code": 46340,
  },
  {
    RegionID: 394773,
    SizeRank: 205,
    RegionName: "Las Cruces, NM",
    RegionType: "Msa",
    Metro: "Las Cruces",
    State: "NM",
    "Metro, State": "Las Cruces, NM",
    "CBSA Code": 29740,
  },
  {
    RegionID: 394585,
    SizeRank: 206,
    RegionName: "Fargo, ND",
    RegionType: "Msa",
    Metro: "Fargo",
    State: "ND",
    "Metro, State": "Fargo, ND",
    "CBSA Code": 22020,
  },
  {
    RegionID: 394691,
    SizeRank: 207,
    RegionName: "Houma, LA",
    RegionType: "Msa",
    Metro: "Houma",
    State: "LA",
    "Metro, State": "Houma, LA",
    "CBSA Code": 26380,
  },
  {
    RegionID: 395030,
    SizeRank: 208,
    RegionName: "Rochester, MN",
    RegionType: "Msa",
    Metro: "Rochester",
    State: "MN",
    "Metro, State": "Rochester, MN",
    "CBSA Code": 40340,
  },
  {
    RegionID: 394597,
    SizeRank: 209,
    RegionName: "Florence, SC",
    RegionType: "Msa",
    Metro: "Florence",
    State: "SC",
    "Metro, State": "Florence, SC",
    "CBSA Code": 22500,
  },
  {
    RegionID: 394848,
    SizeRank: 210,
    RegionName: "Medford, OR",
    RegionType: "Msa",
    Metro: "Medford",
    State: "OR",
    "Metro, State": "Medford, OR",
    "CBSA Code": 32780,
  },
  {
    RegionID: 753895,
    SizeRank: 211,
    RegionName: "Lafayette-West Lafayette, IN",
    RegionType: "Msa",
    Metro: "Lafayette",
    State: "IN",
    "Metro, State": "Lafayette, IN",
    "CBSA Code": 29200,
  },
  {
    RegionID: 394378,
    SizeRank: 212,
    RegionName: "Bellingham, WA",
    RegionType: "Msa",
    Metro: "Bellingham",
    State: "WA",
    "Metro, State": "Bellingham, WA",
    "CBSA Code": 13380,
  },
  {
    RegionID: 394765,
    SizeRank: 213,
    RegionName: "Lake Havasu City, AZ",
    RegionType: "Msa",
    Metro: "Lake Havasu City",
    State: "AZ",
    "Metro, State": "Lake Havasu City, AZ",
    "CBSA Code": 29420,
  },
  {
    RegionID: 395047,
    SizeRank: 214,
    RegionName: "Saginaw, MI",
    RegionType: "Msa",
    Metro: "Saginaw",
    State: "MI",
    "Metro, State": "Saginaw, MI",
    "CBSA Code": 40980,
  },
  {
    RegionID: 394763,
    SizeRank: 215,
    RegionName: "Lake Charles, LA",
    RegionType: "Msa",
    Metro: "Lake Charles",
    State: "LA",
    "Metro, State": "Lake Charles, LA",
    "CBSA Code": 29340,
  },
  {
    RegionID: 394726,
    SizeRank: 216,
    RegionName: "Johnson City, TN",
    RegionType: "Msa",
    Metro: "Johnson City",
    State: "TN",
    "Metro, State": "Johnson City, TN",
    "CBSA Code": 27740,
  },
  {
    RegionID: 394565,
    SizeRank: 217,
    RegionName: "Elkhart, IN",
    RegionType: "Msa",
    Metro: "Elkhart",
    State: "IN",
    "Metro, State": "Elkhart, IN",
    "CBSA Code": 21140,
  },
  {
    RegionID: 395247,
    SizeRank: 218,
    RegionName: "Yuma, AZ",
    RegionType: "Msa",
    Metro: "Yuma",
    State: "AZ",
    "Metro, State": "Yuma, AZ",
    "CBSA Code": 49740,
  },
  {
    RegionID: 395011,
    SizeRank: 219,
    RegionName: "Racine, WI",
    RegionType: "Msa",
    Metro: "Racine",
    State: "WI",
    "Metro, State": "Racine, WI",
    "CBSA Code": 39540,
  },
  {
    RegionID: 394346,
    SizeRank: 220,
    RegionName: "Athens, GA",
    RegionType: "Msa",
    Metro: "Athens",
    State: "GA",
    "Metro, State": "Athens, GA",
    "CBSA Code": 12020,
  },
  {
    RegionID: 395162,
    SizeRank: 221,
    RegionName: "Torrington, CT",
    RegionType: "Msa",
    Metro: "Torrington",
    State: "CT",
    "Metro, State": "Torrington, CT",
    "CBSA Code": 45860,
  },
  {
    RegionID: 395118,
    SizeRank: 222,
    RegionName: "St. Cloud, MN",
    RegionType: "Msa",
    Metro: "St. Cloud",
    State: "MN",
    "Metro, State": "St. Cloud, MN",
    "CBSA Code": 41060,
  },
  {
    RegionID: 394681,
    SizeRank: 223,
    RegionName: "Hilton Head Island, SC",
    RegionType: "Msa",
    Metro: "Hilton Head Island",
    State: "SC",
    "Metro, State": "Hilton Head Island, SC",
    "CBSA Code": 25940,
  },
  {
    RegionID: 753869,
    SizeRank: 224,
    RegionName: "Bloomington, IL",
    RegionType: "Msa",
    Metro: "Bloomington",
    State: "IL",
    "Metro, State": "Bloomington, IL",
    "CBSA Code": 14010,
  },
  {
    RegionID: 394680,
    SizeRank: 225,
    RegionName: "Hilo, HI",
    RegionType: "Msa",
    Metro: "Hilo",
    State: "HI",
    "Metro, State": "Hilo, HI",
    "CBSA Code": 25900,
  },
  {
    RegionID: 394960,
    SizeRank: 226,
    RegionName: "Panama City, FL",
    RegionType: "Msa",
    Metro: "Panama City",
    State: "FL",
    "Metro, State": "Panama City, FL",
    "CBSA Code": 37460,
  },
  {
    RegionID: 394748,
    SizeRank: 227,
    RegionName: "Kingston, NY",
    RegionType: "Msa",
    Metro: "Kingston",
    State: "NY",
    "Metro, State": "Kingston, NY",
    "CBSA Code": 28740,
  },
  {
    RegionID: 394519,
    SizeRank: 228,
    RegionName: "Daphne, AL",
    RegionType: "Msa",
    Metro: "Daphne",
    State: "AL",
    "Metro, State": "Daphne, AL",
    "CBSA Code": 19300,
  },
  {
    RegionID: 394623,
    SizeRank: 229,
    RegionName: "Gainesville, GA",
    RegionType: "Msa",
    Metro: "Gainesville",
    State: "GA",
    "Metro, State": "Gainesville, GA",
    "CBSA Code": 23580,
  },
  {
    RegionID: 395202,
    SizeRank: 230,
    RegionName: "Warner Robins, GA",
    RegionType: "Msa",
    Metro: "Warner Robins",
    State: "GA",
    "Metro, State": "Warner Robins, GA",
    "CBSA Code": 47580,
  },
  {
    RegionID: 394392,
    SizeRank: 231,
    RegionName: "Blacksburg, VA",
    RegionType: "Msa",
    Metro: "Blacksburg",
    State: "VA",
    "Metro, State": "Blacksburg, VA",
    "CBSA Code": 13980,
  },
  {
    RegionID: 394716,
    SizeRank: 232,
    RegionName: "Jacksonville, NC",
    RegionType: "Msa",
    Metro: "Jacksonville",
    State: "NC",
    "Metro, State": "Jacksonville, NC",
    "CBSA Code": 27340,
  },
  {
    RegionID: 395018,
    SizeRank: 233,
    RegionName: "Redding, CA",
    RegionType: "Msa",
    Metro: "Redding",
    State: "CA",
    "Metro, State": "Redding, CA",
    "CBSA Code": 39820,
  },
  {
    RegionID: 394872,
    SizeRank: 234,
    RegionName: "Monroe, LA",
    RegionType: "Msa",
    Metro: "Monroe",
    State: "LA",
    "Metro, State": "Monroe, LA",
    "CBSA Code": 33740,
  },
  {
    RegionID: 394729,
    SizeRank: 235,
    RegionName: "Joplin, MO",
    RegionType: "Msa",
    Metro: "Joplin",
    State: "MO",
    "Metro, State": "Joplin, MO",
    "CBSA Code": 27900,
  },
  {
    RegionID: 394559,
    SizeRank: 236,
    RegionName: "El Centro, CA",
    RegionType: "Msa",
    Metro: "El Centro",
    State: "CA",
    "Metro, State": "El Centro, CA",
    "CBSA Code": 20940,
  },
  {
    RegionID: 395151,
    SizeRank: 237,
    RegionName: "Terre Haute, IN",
    RegionType: "Msa",
    Metro: "Terre Haute",
    State: "IN",
    "Metro, State": "Terre Haute, IN",
    "CBSA Code": 45460,
  },
  {
    RegionID: 394896,
    SizeRank: 238,
    RegionName: "Muskegon, MI",
    RegionType: "Msa",
    Metro: "Muskegon",
    State: "MI",
    "Metro, State": "Muskegon, MI",
    "CBSA Code": 34740,
  },
  {
    RegionID: 394553,
    SizeRank: 239,
    RegionName: "East Stroudsburg, PA",
    RegionType: "Msa",
    Metro: "East Stroudsburg",
    State: "PA",
    "Metro, State": "East Stroudsburg, PA",
    "CBSA Code": 20700,
  },
  {
    RegionID: 395102,
    SizeRank: 240,
    RegionName: "Sioux City, IA",
    RegionType: "Msa",
    Metro: "Sioux City",
    State: "IA",
    "Metro, State": "Sioux City, IA",
    "CBSA Code": 43580,
  },
  {
    RegionID: 394651,
    SizeRank: 241,
    RegionName: "Greenville, NC",
    RegionType: "Msa",
    Metro: "Greenville",
    State: "NC",
    "Metro, State": "Greenville, NC",
    "CBSA Code": 24780,
  },
  {
    RegionID: 395210,
    SizeRank: 242,
    RegionName: "Waterloo, IA",
    RegionType: "Msa",
    Metro: "Waterloo",
    State: "IA",
    "Metro, State": "Waterloo, IA",
    "CBSA Code": 47940,
  },
  {
    RegionID: 394944,
    SizeRank: 243,
    RegionName: "Oshkosh, WI",
    RegionType: "Msa",
    Metro: "Oshkosh",
    State: "WI",
    "Metro, State": "Oshkosh, WI",
    "CBSA Code": 36780,
  },
  {
    RegionID: 395246,
    SizeRank: 244,
    RegionName: "Yuba City, CA",
    RegionType: "Msa",
    Metro: "Yuba City",
    State: "CA",
    "Metro, State": "Yuba City, CA",
    "CBSA Code": 49700,
  },
  {
    RegionID: 394299,
    SizeRank: 245,
    RegionName: "Abilene, TX",
    RegionType: "Msa",
    Metro: "Abilene",
    State: "TX",
    "Metro, State": "Abilene, TX",
    "CBSA Code": 10180,
  },
  {
    RegionID: 394485,
    SizeRank: 246,
    RegionName: "Columbia, MO",
    RegionType: "Msa",
    Metro: "Columbia",
    State: "MO",
    "Metro, State": "Columbia, MO",
    "CBSA Code": 17860,
  },
  {
    RegionID: 394539,
    SizeRank: 247,
    RegionName: "Dover, DE",
    RegionType: "Msa",
    Metro: "Dover",
    State: "DE",
    "Metro, State": "Dover, DE",
    "CBSA Code": 20100,
  },
  {
    RegionID: 394555,
    SizeRank: 248,
    RegionName: "Eau Claire, WI",
    RegionType: "Msa",
    Metro: "Eau Claire",
    State: "WI",
    "Metro, State": "Eau Claire, WI",
    "CBSA Code": 20740,
  },
  {
    RegionID: 394720,
    SizeRank: 249,
    RegionName: "Janesville, WI",
    RegionType: "Msa",
    Metro: "Janesville",
    State: "WI",
    "Metro, State": "Janesville, WI",
    "CBSA Code": 27500,
  },
  {
    RegionID: 394710,
    SizeRank: 250,
    RegionName: "Jackson, MI",
    RegionType: "Msa",
    Metro: "Jackson",
    State: "MI",
    "Metro, State": "Jackson, MI",
    "CBSA Code": 27100,
  },
  {
    RegionID: 395009,
    SizeRank: 251,
    RegionName: "Punta Gorda, FL",
    RegionType: "Msa",
    Metro: "Punta Gorda",
    State: "FL",
    "Metro, State": "Punta Gorda, FL",
    "CBSA Code": 39460,
  },
  {
    RegionID: 394393,
    SizeRank: 252,
    RegionName: "Bloomington, IN",
    RegionType: "Msa",
    Metro: "Bloomington",
    State: "IN",
    "Metro, State": "Bloomington, IN",
    "CBSA Code": 14020,
  },
  {
    RegionID: 395007,
    SizeRank: 253,
    RegionName: "Pueblo, CO",
    RegionType: "Msa",
    Metro: "Pueblo",
    State: "CO",
    "Metro, State": "Pueblo, CO",
    "CBSA Code": 39380,
  },
  {
    RegionID: 394386,
    SizeRank: 254,
    RegionName: "Billings, MT",
    RegionType: "Msa",
    Metro: "Billings",
    State: "MT",
    "Metro, State": "Billings, MT",
    "CBSA Code": 13740,
  },
  {
    RegionID: 394406,
    SizeRank: 255,
    RegionName: "Bowling Green, KY",
    RegionType: "Msa",
    Metro: "Bowling Green",
    State: "KY",
    "Metro, State": "Bowling Green, KY",
    "CBSA Code": 14540,
  },
  {
    RegionID: 394380,
    SizeRank: 256,
    RegionName: "Bend, OR",
    RegionType: "Msa",
    Metro: "Bend",
    State: "OR",
    "Metro, State": "Bend, OR",
    "CBSA Code": 13460,
  },
  {
    RegionID: 394306,
    SizeRank: 257,
    RegionName: "Albany, GA",
    RegionType: "Msa",
    Metro: "Albany",
    State: "GA",
    "Metro, State": "Albany, GA",
    "CBSA Code": 10500,
  },
  {
    RegionID: 395193,
    SizeRank: 258,
    RegionName: "Vineland, NJ",
    RegionType: "Msa",
    Metro: "Vineland",
    State: "NJ",
    "Metro, State": "Vineland, NJ",
    "CBSA Code": 47220,
  },
  {
    RegionID: 394917,
    SizeRank: 259,
    RegionName: "Niles, MI",
    RegionType: "Msa",
    Metro: "Niles",
    State: "MI",
    "Metro, State": "Niles, MI",
    "CBSA Code": 35660,
  },
  {
    RegionID: 394731,
    SizeRank: 260,
    RegionName: "Kahului, HI",
    RegionType: "Msa",
    Metro: "Kahului",
    State: "HI",
    "Metro, State": "Kahului, HI",
    "CBSA Code": 27980,
  },
  {
    RegionID: 394946,
    SizeRank: 261,
    RegionName: "Ottawa, IL",
    RegionType: "Msa",
    Metro: "Ottawa",
    State: "IL",
    "Metro, State": "Ottawa, IL",
    "CBSA Code": 36837,
  },
  {
    RegionID: 395125,
    SizeRank: 262,
    RegionName: "State College, PA",
    RegionType: "Msa",
    Metro: "State College",
    State: "PA",
    "Metro, State": "State College, PA",
    "CBSA Code": 44300,
  },
  {
    RegionID: 394359,
    SizeRank: 263,
    RegionName: "Bangor, ME",
    RegionType: "Msa",
    Metro: "Bangor",
    State: "ME",
    "Metro, State": "Bangor, ME",
    "CBSA Code": 12620,
  },
  {
    RegionID: 394314,
    SizeRank: 264,
    RegionName: "Alexandria, LA",
    RegionType: "Msa",
    Metro: "Alexandria",
    State: "LA",
    "Metro, State": "Alexandria, LA",
    "CBSA Code": 10780,
  },
  {
    RegionID: 394523,
    SizeRank: 265,
    RegionName: "Decatur, AL",
    RegionType: "Msa",
    Metro: "Decatur",
    State: "AL",
    "Metro, State": "Decatur, AL",
    "CBSA Code": 19460,
  },
  {
    RegionID: 394662,
    SizeRank: 266,
    RegionName: "Hanford, CA",
    RegionType: "Msa",
    Metro: "Hanford",
    State: "CA",
    "Metro, State": "Hanford, CA",
    "CBSA Code": 25260,
  },
  {
    RegionID: 394707,
    SizeRank: 267,
    RegionName: "Iowa City, IA",
    RegionType: "Msa",
    Metro: "Iowa City",
    State: "IA",
    "Metro, State": "Iowa City, IA",
    "CBSA Code": 26980,
  },
  {
    RegionID: 395036,
    SizeRank: 268,
    RegionName: "Rocky Mount, NC",
    RegionType: "Msa",
    Metro: "Rocky Mount",
    State: "NC",
    "Metro, State": "Rocky Mount, NC",
    "CBSA Code": 40580,
  },
  {
    RegionID: 394873,
    SizeRank: 269,
    RegionName: "Monroe, MI",
    RegionType: "Msa",
    Metro: "Monroe",
    State: "MI",
    "Metro, State": "Monroe, MI",
    "CBSA Code": 33780,
  },
  {
    RegionID: 395223,
    SizeRank: 270,
    RegionName: "Wichita Falls, TX",
    RegionType: "Msa",
    Metro: "Wichita Falls",
    State: "TX",
    "Metro, State": "Wichita Falls, TX",
    "CBSA Code": 48660,
  },
  {
    RegionID: 394428,
    SizeRank: 271,
    RegionName: "Burlington, NC",
    RegionType: "Msa",
    Metro: "Burlington",
    State: "NC",
    "Metro, State": "Burlington, NC",
    "CBSA Code": 15500,
  },
  {
    RegionID: 394814,
    SizeRank: 272,
    RegionName: "Madera, CA",
    RegionType: "Msa",
    Metro: "Madera",
    State: "CA",
    "Metro, State": "Madera, CA",
    "CBSA Code": 31460,
  },
  {
    RegionID: 394723,
    SizeRank: 273,
    RegionName: "Jefferson City, MO",
    RegionType: "Msa",
    Metro: "Jefferson City",
    State: "MO",
    "Metro, State": "Jefferson City, MO",
    "CBSA Code": 27620,
  },
  {
    RegionID: 394453,
    SizeRank: 274,
    RegionName: "Chambersburg, PA",
    RegionType: "Msa",
    Metro: "Chambersburg",
    State: "PA",
    "Metro, State": "Chambersburg, PA",
    "CBSA Code": 16540,
  },
  {
    RegionID: 395152,
    SizeRank: 275,
    RegionName: "Texarkana, TX",
    RegionType: "Msa",
    Metro: "Texarkana",
    State: "TX",
    "Metro, State": "Texarkana, TX",
    "CBSA Code": 45500,
  },
  {
    RegionID: 394563,
    SizeRank: 276,
    RegionName: "Elizabethtown, KY",
    RegionType: "Msa",
    Metro: "Elizabethtown",
    State: "KY",
    "Metro, State": "Elizabethtown, KY",
    "CBSA Code": 21060,
  },
  {
    RegionID: 395000,
    SizeRank: 277,
    RegionName: "Pottsville, PA",
    RegionType: "Msa",
    Metro: "Pottsville",
    State: "PA",
    "Metro, State": "Pottsville, PA",
    "CBSA Code": 39060,
  },
  {
    RegionID: 395221,
    SizeRank: 278,
    RegionName: "Wheeling, OH",
    RegionType: "Msa",
    Metro: "Wheeling",
    State: "WV",
    "Metro, State": "Wheeling, WV",
    "CBSA Code": 48540,
  },
  {
    RegionID: 394598,
    SizeRank: 279,
    RegionName: "Florence, AL",
    RegionType: "Msa",
    Metro: "Florence",
    State: "AL",
    "Metro, State": "Florence, AL",
    "CBSA Code": 22520,
  },
  {
    RegionID: 394639,
    SizeRank: 280,
    RegionName: "Grand Junction, CO",
    RegionType: "Msa",
    Metro: "Grand Junction",
    State: "CO",
    "Metro, State": "Grand Junction, CO",
    "CBSA Code": 24300,
  },
  {
    RegionID: 394493,
    SizeRank: 281,
    RegionName: "Concord, NH",
    RegionType: "Msa",
    Metro: "Concord",
    State: "NH",
    "Metro, State": "Concord, NH",
    "CBSA Code": 18180,
  },
  {
    RegionID: 394537,
    SizeRank: 282,
    RegionName: "Dothan, AL",
    RegionType: "Msa",
    Metro: "Dothan",
    State: "AL",
    "Metro, State": "Dothan, AL",
    "CBSA Code": 20020,
  },
  {
    RegionID: 395066,
    SizeRank: 283,
    RegionName: "Santa Fe, NM",
    RegionType: "Msa",
    Metro: "Santa Fe",
    State: "NM",
    "Metro, State": "Santa Fe, NM",
    "CBSA Code": 42140,
  },
  {
    RegionID: 394727,
    SizeRank: 284,
    RegionName: "Johnstown, PA",
    RegionType: "Msa",
    Metro: "Johnstown",
    State: "PA",
    "Metro, State": "Johnstown, PA",
    "CBSA Code": 27780,
  },
  {
    RegionID: 395163,
    SizeRank: 285,
    RegionName: "Traverse City, MI",
    RegionType: "Msa",
    Metro: "Traverse City",
    State: "MI",
    "Metro, State": "Traverse City, MI",
    "CBSA Code": 45900,
  },
  {
    RegionID: 394671,
    SizeRank: 286,
    RegionName: "Hattiesburg, MS",
    RegionType: "Msa",
    Metro: "Hattiesburg",
    State: "MS",
    "Metro, State": "Hattiesburg, MS",
    "CBSA Code": 25620,
  },
  {
    RegionID: 394515,
    SizeRank: 287,
    RegionName: "Dalton, GA",
    RegionType: "Msa",
    Metro: "Dalton",
    State: "GA",
    "Metro, State": "Dalton, GA",
    "CBSA Code": 19140,
  },
  {
    RegionID: 394860,
    SizeRank: 288,
    RegionName: "Midland, TX",
    RegionType: "Msa",
    Metro: "Midland",
    State: "TX",
    "Metro, State": "Midland, TX",
    "CBSA Code": 33260,
  },
  {
    RegionID: 394685,
    SizeRank: 289,
    RegionName: "Homosassa Springs, FL",
    RegionType: "Msa",
    Metro: "Homosassa Springs",
    State: "FL",
    "Metro, State": "Homosassa Springs, FL",
    "CBSA Code": 26140,
  },
  {
    RegionID: 394351,
    SizeRank: 290,
    RegionName: "Auburn, AL",
    RegionType: "Msa",
    Metro: "Auburn",
    State: "AL",
    "Metro, State": "Auburn, AL",
    "CBSA Code": 12220,
  },
  {
    RegionID: 395182,
    SizeRank: 291,
    RegionName: "Valdosta, GA",
    RegionType: "Msa",
    Metro: "Valdosta",
    State: "GA",
    "Metro, State": "Valdosta, GA",
    "CBSA Code": 46660,
  },
  {
    RegionID: 394480,
    SizeRank: 292,
    RegionName: "Coeur d'Alene, ID",
    RegionType: "Msa",
    Metro: "Coeur d'Alene",
    State: "ID",
    "Metro, State": "Coeur d'Alene, ID",
    "CBSA Code": 17660,
  },
  {
    RegionID: 395117,
    SizeRank: 293,
    RegionName: "Springfield, OH",
    RegionType: "Msa",
    Metro: "Springfield",
    State: "OH",
    "Metro, State": "Springfield, OH",
    "CBSA Code": 44220,
  },
  {
    RegionID: 395119,
    SizeRank: 294,
    RegionName: "St. George, UT",
    RegionType: "Msa",
    Metro: "St. George",
    State: "UT",
    "Metro, State": "St. George, UT",
    "CBSA Code": 41100,
  },
  {
    RegionID: 395079,
    SizeRank: 295,
    RegionName: "Vero Beach, FL",
    RegionType: "Msa",
    Metro: "Vero Beach",
    State: "FL",
    "Metro, State": "Vero Beach, FL",
    "CBSA Code": 42680,
  },
  {
    RegionID: 394930,
    SizeRank: 296,
    RegionName: "Odessa, TX",
    RegionType: "Msa",
    Metro: "Odessa",
    State: "TX",
    "Metro, State": "Odessa, TX",
    "CBSA Code": 36220,
  },
  {
    RegionID: 394900,
    SizeRank: 297,
    RegionName: "Napa, CA",
    RegionType: "Msa",
    Metro: "Napa",
    State: "CA",
    "Metro, State": "Napa, CA",
    "CBSA Code": 34900,
  },
  {
    RegionID: 395170,
    SizeRank: 298,
    RegionName: "Tupelo, MS",
    RegionType: "Msa",
    Metro: "Tupelo",
    State: "MS",
    "Metro, State": "Tupelo, MS",
    "CBSA Code": 46180,
  },
  {
    RegionID: 394368,
    SizeRank: 299,
    RegionName: "Battle Creek, MI",
    RegionType: "Msa",
    Metro: "Battle Creek",
    State: "MI",
    "Metro, State": "Battle Creek, MI",
    "CBSA Code": 12980,
  },
  {
    RegionID: 394719,
    SizeRank: 300,
    RegionName: "Jamestown, NY",
    RegionType: "Msa",
    Metro: "Jamestown",
    State: "NY",
    "Metro, State": "Jamestown, NY",
    "CBSA Code": 27460,
  },
  {
    RegionID: 394577,
    SizeRank: 301,
    RegionName: "Eureka, CA",
    RegionType: "Msa",
    Metro: "Eureka",
    State: "CA",
    "Metro, State": "Eureka, CA",
    "CBSA Code": 21700,
  },
  {
    RegionID: 395013,
    SizeRank: 302,
    RegionName: "Rapid City, SD",
    RegionType: "Msa",
    Metro: "Rapid City",
    State: "SD",
    "Metro, State": "Rapid City, SD",
    "CBSA Code": 39660,
  },
  {
    RegionID: 394595,
    SizeRank: 303,
    RegionName: "Flagstaff, AZ",
    RegionType: "Msa",
    Metro: "Flagstaff",
    State: "AZ",
    "Metro, State": "Flagstaff, AZ",
    "CBSA Code": 22380,
  },
  {
    RegionID: 394810,
    SizeRank: 304,
    RegionName: "Lumberton, NC",
    RegionType: "Msa",
    Metro: "Lumberton",
    State: "NC",
    "Metro, State": "Lumberton, NC",
    "CBSA Code": 31300,
  },
  {
    RegionID: 395215,
    SizeRank: 305,
    RegionName: "Wausau, WI",
    RegionType: "Msa",
    Metro: "Wausau",
    State: "WI",
    "Metro, State": "Wausau, WI",
    "CBSA Code": 48140,
  },
  {
    RegionID: 394756,
    SizeRank: 306,
    RegionName: "La Crosse, WI",
    RegionType: "Msa",
    Metro: "La Crosse",
    State: "WI",
    "Metro, State": "La Crosse, WI",
    "CBSA Code": 29100,
  },
  {
    RegionID: 394783,
    SizeRank: 307,
    RegionName: "Lebanon, PA",
    RegionType: "Msa",
    Metro: "Lebanon",
    State: "PA",
    "Metro, State": "Lebanon, PA",
    "CBSA Code": 30140,
  },
  {
    RegionID: 394703,
    SizeRank: 308,
    RegionName: "Idaho Falls, ID",
    RegionType: "Msa",
    Metro: "Idaho Falls",
    State: "ID",
    "Metro, State": "Idaho Falls, ID",
    "CBSA Code": 26820,
  },
  {
    RegionID: 395098,
    SizeRank: 309,
    RegionName: "Sierra Vista, AZ",
    RegionType: "Msa",
    Metro: "Sierra Vista",
    State: "AZ",
    "Metro, State": "Sierra Vista, AZ",
    "CBSA Code": 43420,
  },
  {
    RegionID: 394983,
    SizeRank: 310,
    RegionName: "Pittsfield, MA",
    RegionType: "Msa",
    Metro: "Pittsfield",
    State: "MA",
    "Metro, State": "Pittsfield, MA",
    "CBSA Code": 38340,
  },
  {
    RegionID: 394780,
    SizeRank: 311,
    RegionName: "Lawton, OK",
    RegionType: "Msa",
    Metro: "Lawton",
    State: "OK",
    "Metro, State": "Lawton, OK",
    "CBSA Code": 30020,
  },
  {
    RegionID: 394588,
    SizeRank: 312,
    RegionName: "Farmington, NM",
    RegionType: "Msa",
    Metro: "Farmington",
    State: "NM",
    "Metro, State": "Farmington, NM",
    "CBSA Code": 22140,
  },
  {
    RegionID: 394712,
    SizeRank: 313,
    RegionName: "Jackson, TN",
    RegionType: "Msa",
    Metro: "Jackson",
    State: "TN",
    "Metro, State": "Jackson, TN",
    "CBSA Code": 27180,
  },
  {
    RegionID: 394879,
    SizeRank: 314,
    RegionName: "Morgantown, WV",
    RegionType: "Msa",
    Metro: "Morgantown",
    State: "WV",
    "Metro, State": "Morgantown, WV",
    "CBSA Code": 34060,
  },
  {
    RegionID: 394633,
    SizeRank: 315,
    RegionName: "Glens Falls, NY",
    RegionType: "Msa",
    Metro: "Glens Falls",
    State: "NY",
    "Metro, State": "Glens Falls, NY",
    "CBSA Code": 24020,
  },
  {
    RegionID: 395232,
    SizeRank: 316,
    RegionName: "Winchester, VA",
    RegionType: "Msa",
    Metro: "Winchester",
    State: "VA",
    "Metro, State": "Winchester, VA",
    "CBSA Code": 49020,
  },
  {
    RegionID: 395120,
    SizeRank: 317,
    RegionName: "St. Joseph, MO",
    RegionType: "Msa",
    Metro: "St. Joseph",
    State: "MO",
    "Metro, State": "St. Joseph, MO",
    "CBSA Code": 41140,
  },
  {
    RegionID: 394321,
    SizeRank: 318,
    RegionName: "Altoona, PA",
    RegionType: "Msa",
    Metro: "Altoona",
    State: "PA",
    "Metro, State": "Altoona, PA",
    "CBSA Code": 11020,
  },
  {
    RegionID: 394905,
    SizeRank: 319,
    RegionName: "New Bern, NC",
    RegionType: "Msa",
    Metro: "New Bern",
    State: "NC",
    "Metro, State": "New Bern, NC",
    "CBSA Code": 35100,
  },
  {
    RegionID: 394442,
    SizeRank: 320,
    RegionName: "Carbondale, IL",
    RegionType: "Msa",
    Metro: "Carbondale",
    State: "IL",
    "Metro, State": "Carbondale, IL",
    "CBSA Code": 16060,
  },
  {
    RegionID: 394802,
    SizeRank: 321,
    RegionName: "London, KY",
    RegionType: "Msa",
    Metro: "London",
    State: "KY",
    "Metro, State": "London, KY",
    "CBSA Code": 30940,
  },
  {
    RegionID: 394800,
    SizeRank: 322,
    RegionName: "Logan, ID",
    RegionType: "Msa",
    Metro: "Logan",
    State: "ID",
    "Metro, State": "Logan, ID",
    "CBSA Code": "-",
  },
  {
    RegionID: 394668,
    SizeRank: 323,
    RegionName: "Harrisonburg, VA",
    RegionType: "Msa",
    Metro: "Harrisonburg",
    State: "VA",
    "Metro, State": "Harrisonburg, VA",
    "CBSA Code": 25500,
  },
  {
    RegionID: 394374,
    SizeRank: 324,
    RegionName: "Beckley, WV",
    RegionType: "Msa",
    Metro: "Beckley",
    State: "WV",
    "Metro, State": "Beckley, WV",
    "CBSA Code": 13220,
  },
  {
    RegionID: 394824,
    SizeRank: 325,
    RegionName: "Mansfield, OH",
    RegionType: "Msa",
    Metro: "Mansfield",
    State: "OH",
    "Metro, State": "Mansfield, OH",
    "CBSA Code": 31900,
  },
  {
    RegionID: 395217,
    SizeRank: 326,
    RegionName: "Weirton, OH",
    RegionType: "Msa",
    Metro: "Weirton",
    State: "OH",
    "Metro, State": "Weirton, OH",
    "CBSA Code": "-",
  },
  {
    RegionID: 394635,
    SizeRank: 327,
    RegionName: "Goldsboro, NC",
    RegionType: "Msa",
    Metro: "Goldsboro",
    State: "NC",
    "Metro, State": "Goldsboro, NC",
    "CBSA Code": 24140,
  },
  {
    RegionID: 394353,
    SizeRank: 328,
    RegionName: "Augusta, ME",
    RegionType: "Msa",
    Metro: "Augusta",
    State: "ME",
    "Metro, State": "Augusta, ME",
    "CBSA Code": 12300,
  },
  {
    RegionID: 394661,
    SizeRank: 329,
    RegionName: "Hammond, LA",
    RegionType: "Msa",
    Metro: "Hammond",
    State: "LA",
    "Metro, State": "Hammond, LA",
    "CBSA Code": 25220,
  },
  {
    RegionID: 394728,
    SizeRank: 330,
    RegionName: "Jonesboro, AR",
    RegionType: "Msa",
    Metro: "Jonesboro",
    State: "AR",
    "Metro, State": "Jonesboro, AR",
    "CBSA Code": 27860,
  },
  {
    RegionID: 395094,
    SizeRank: 331,
    RegionName: "Sherman, TX",
    RegionType: "Msa",
    Metro: "Sherman",
    State: "TX",
    "Metro, State": "Sherman, TX",
    "CBSA Code": 43300,
  },
  {
    RegionID: 394333,
    SizeRank: 332,
    RegionName: "Anniston, AL",
    RegionType: "Msa",
    Metro: "Anniston",
    State: "AL",
    "Metro, State": "Anniston, AL",
    "CBSA Code": 11500,
  },
  {
    RegionID: 395128,
    SizeRank: 333,
    RegionName: "Staunton, VA",
    RegionType: "Msa",
    Metro: "Staunton",
    State: "VA",
    "Metro, State": "Staunton, VA",
    "CBSA Code": 44420,
  },
  {
    RegionID: 394893,
    SizeRank: 334,
    RegionName: "Muncie, IN",
    RegionType: "Msa",
    Metro: "Muncie",
    State: "IN",
    "Metro, State": "Muncie, IN",
    "CBSA Code": 34620,
  },
  {
    RegionID: 394890,
    SizeRank: 335,
    RegionName: "Mount Vernon, WA",
    RegionType: "Msa",
    Metro: "Mount Vernon",
    State: "WA",
    "Metro, State": "Mount Vernon, WA",
    "CBSA Code": 34580,
  },
  {
    RegionID: 394307,
    SizeRank: 336,
    RegionName: "Albany, OR",
    RegionType: "Msa",
    Metro: "Albany",
    State: "OR",
    "Metro, State": "Albany, OR",
    "CBSA Code": 10540,
  },
  {
    RegionID: 395213,
    SizeRank: 337,
    RegionName: "Watertown, NY",
    RegionType: "Msa",
    Metro: "Watertown",
    State: "NY",
    "Metro, State": "Watertown, NY",
    "CBSA Code": 48060,
  },
  {
    RegionID: 395225,
    SizeRank: 338,
    RegionName: "Williamsport, PA",
    RegionType: "Msa",
    Metro: "Williamsport",
    State: "PA",
    "Metro, State": "Williamsport, PA",
    "CBSA Code": 48700,
  },
  {
    RegionID: 394474,
    SizeRank: 339,
    RegionName: "Cleveland, TN",
    RegionType: "Msa",
    Metro: "Cleveland",
    State: "TN",
    "Metro, State": "Cleveland, TN",
    "CBSA Code": 17420,
  },
  {
    RegionID: 395089,
    SizeRank: 340,
    RegionName: "Sheboygan, WI",
    RegionType: "Msa",
    Metro: "Sheboygan",
    State: "WI",
    "Metro, State": "Sheboygan, WI",
    "CBSA Code": 43100,
  },
  {
    RegionID: 394390,
    SizeRank: 341,
    RegionName: "Bismarck, ND",
    RegionType: "Msa",
    Metro: "Bismarck",
    State: "ND",
    "Metro, State": "Bismarck, ND",
    "CBSA Code": 13900,
  },
  {
    RegionID: 394949,
    SizeRank: 342,
    RegionName: "Owensboro, KY",
    RegionType: "Msa",
    Metro: "Owensboro",
    State: "KY",
    "Metro, State": "Owensboro, KY",
    "CBSA Code": 36980,
  },
  {
    RegionID: 394546,
    SizeRank: 343,
    RegionName: "Dunn, NC",
    RegionType: "Msa",
    Metro: "Dunn",
    State: "NC",
    "Metro, State": "Dunn, NC",
    "CBSA Code": "-",
  },
  {
    RegionID: 395237,
    SizeRank: 344,
    RegionName: "Wooster, OH",
    RegionType: "Msa",
    Metro: "Wooster",
    State: "OH",
    "Metro, State": "Wooster, OH",
    "CBSA Code": 49300,
  },
  {
    RegionID: 394880,
    SizeRank: 345,
    RegionName: "Morristown, TN",
    RegionType: "Msa",
    Metro: "Morristown",
    State: "TN",
    "Metro, State": "Morristown, TN",
    "CBSA Code": 34100,
  },
  {
    RegionID: 394734,
    SizeRank: 346,
    RegionName: "Kankakee, IL",
    RegionType: "Msa",
    Metro: "Kankakee",
    State: "IL",
    "Metro, State": "Kankakee, IL",
    "CBSA Code": 28100,
  },
  {
    RegionID: 394423,
    SizeRank: 347,
    RegionName: "Brunswick, GA",
    RegionType: "Msa",
    Metro: "Brunswick",
    State: "GA",
    "Metro, State": "Brunswick, GA",
    "CBSA Code": 15260,
  },
  {
    RegionID: 394932,
    SizeRank: 348,
    RegionName: "Ogdensburg, NY",
    RegionType: "Msa",
    Metro: "Ogdensburg",
    State: "NY",
    "Metro, State": "Ogdensburg, NY",
    "CBSA Code": 36300,
  },
  {
    RegionID: 395054,
    SizeRank: 349,
    RegionName: "San Angelo, TX",
    RegionType: "Msa",
    Metro: "San Angelo",
    State: "TX",
    "Metro, State": "San Angelo, TX",
    "CBSA Code": 41660,
  },
  {
    RegionID: 394857,
    SizeRank: 350,
    RegionName: "Michigan City, IN",
    RegionType: "Msa",
    Metro: "Michigan City",
    State: "IN",
    "Metro, State": "Michigan City, IN",
    "CBSA Code": 33140,
  },
  {
    RegionID: 753890,
    SizeRank: 351,
    RegionName: "Holland, MI",
    RegionType: "Msa",
    Metro: "Holland",
    State: "MI",
    "Metro, State": "Holland, MI",
    "CBSA Code": 26090,
  },
  {
    RegionID: 395218,
    SizeRank: 352,
    RegionName: "Wenatchee, WA",
    RegionType: "Msa",
    Metro: "Wenatchee",
    State: "WA",
    "Metro, State": "Wenatchee, WA",
    "CBSA Code": 48300,
  },
  {
    RegionID: 394778,
    SizeRank: 353,
    RegionName: "Lawrence, KS",
    RegionType: "Msa",
    Metro: "Lawrence",
    State: "KS",
    "Metro, State": "Lawrence, KS",
    "CBSA Code": 29940,
  },
  {
    RegionID: 394524,
    SizeRank: 354,
    RegionName: "Decatur, IL",
    RegionType: "Msa",
    Metro: "Decatur",
    State: "IL",
    "Metro, State": "Decatur, IL",
    "CBSA Code": 19500,
  },
  {
    RegionID: 394867,
    SizeRank: 355,
    RegionName: "Missoula, MT",
    RegionType: "Msa",
    Metro: "Missoula",
    State: "MT",
    "Metro, State": "Missoula, MT",
    "CBSA Code": 33540,
  },
  {
    RegionID: 753915,
    SizeRank: 356,
    RegionName: "Salem, OH",
    RegionType: "Msa",
    Metro: "Salem",
    State: "OH",
    "Metro, State": "Salem, OH",
    "CBSA Code": 41400,
  },
  {
    RegionID: 394369,
    SizeRank: 357,
    RegionName: "Bay City, MI",
    RegionType: "Msa",
    Metro: "Bay City",
    State: "MI",
    "Metro, State": "Bay City, MI",
    "CBSA Code": 13020,
  },
  {
    RegionID: 394788,
    SizeRank: 358,
    RegionName: "Lewiston, ME",
    RegionType: "Msa",
    Metro: "Lewiston",
    State: "ME",
    "Metro, State": "Lewiston, ME",
    "CBSA Code": 30340,
  },
  {
    RegionID: 395039,
    SizeRank: 359,
    RegionName: "Roseburg, OR",
    RegionType: "Msa",
    Metro: "Roseburg",
    State: "OR",
    "Metro, State": "Roseburg, OR",
    "CBSA Code": 40700,
  },
  {
    RegionID: 395139,
    SizeRank: 360,
    RegionName: "Sumter, SC",
    RegionType: "Msa",
    Metro: "Sumter",
    State: "SC",
    "Metro, State": "Sumter, SC",
    "CBSA Code": 44940,
  },
  {
    RegionID: 394852,
    SizeRank: 361,
    RegionName: "Meridian, MS",
    RegionType: "Msa",
    Metro: "Meridian",
    State: "MS",
    "Metro, State": "Meridian, MS",
    "CBSA Code": 32940,
  },
  {
    RegionID: 395095,
    SizeRank: 361,
    RegionName: "Show Low, AZ",
    RegionType: "Msa",
    Metro: "Show Low",
    State: "AZ",
    "Metro, State": "Show Low, AZ",
    "CBSA Code": 43320,
  },
  {
    RegionID: 394396,
    SizeRank: 363,
    RegionName: "Bluefield, WV",
    RegionType: "Msa",
    Metro: "Bluefield",
    State: "WV",
    "Metro, State": "Bluefield, WV",
    "CBSA Code": 14140,
  },
  {
    RegionID: 394518,
    SizeRank: 364,
    RegionName: "Danville, VA",
    RegionType: "Msa",
    Metro: "Danville",
    State: "VA",
    "Metro, State": "Danville, VA",
    "CBSA Code": 19260,
  },
  {
    RegionID: 394794,
    SizeRank: 365,
    RegionName: "Lima, OH",
    RegionType: "Msa",
    Metro: "Lima",
    State: "OH",
    "Metro, State": "Lima, OH",
    "CBSA Code": 30620,
  },
  {
    RegionID: 394495,
    SizeRank: 366,
    RegionName: "Cookeville, TN",
    RegionType: "Msa",
    Metro: "Cookeville",
    State: "TN",
    "Metro, State": "Cookeville, TN",
    "CBSA Code": 18260,
  },
  {
    RegionID: 753872,
    SizeRank: 367,
    RegionName: "California-Lexington Park, MD",
    RegionType: "Msa",
    Metro: "California",
    State: "MD",
    "Metro, State": "California, MD",
    "CBSA Code": 15680,
  },
  {
    RegionID: 394620,
    SizeRank: 368,
    RegionName: "Gadsden, AL",
    RegionType: "Msa",
    Metro: "Gadsden",
    State: "AL",
    "Metro, State": "Gadsden, AL",
    "CBSA Code": 23460,
  },
  {
    RegionID: 394513,
    SizeRank: 369,
    RegionName: "Cumberland, MD",
    RegionType: "Msa",
    Metro: "Cumberland",
    State: "MD",
    "Metro, State": "Cumberland, MD",
    "CBSA Code": 19060,
  },
  {
    RegionID: 394804,
    SizeRank: 370,
    RegionName: "Longview, WA",
    RegionType: "Msa",
    Metro: "Longview",
    State: "WA",
    "Metro, State": "Longview, WA",
    "CBSA Code": 31020,
  },
  {
    RegionID: 395222,
    SizeRank: 371,
    RegionName: "Whitewater, WI",
    RegionType: "Msa",
    Metro: "Whitewater",
    State: "WI",
    "Metro, State": "Whitewater, WI",
    "CBSA Code": 48580,
  },
  {
    RegionID: 394599,
    SizeRank: 372,
    RegionName: "Fond du Lac, WI",
    RegionType: "Msa",
    Metro: "Fond du Lac",
    State: "WI",
    "Metro, State": "Fond du Lac, WI",
    "CBSA Code": 22540,
  },
  {
    RegionID: 394709,
    SizeRank: 373,
    RegionName: "Ithaca, NY",
    RegionType: "Msa",
    Metro: "Ithaca",
    State: "NY",
    "Metro, State": "Ithaca, NY",
    "CBSA Code": 27060,
  },
  {
    RegionID: 394340,
    SizeRank: 374,
    RegionName: "Ashtabula, OH",
    RegionType: "Msa",
    Metro: "Ashtabula",
    State: "OH",
    "Metro, State": "Ashtabula, OH",
    "CBSA Code": 11780,
  },
  {
    RegionID: 394630,
    SizeRank: 375,
    RegionName: "Gettysburg, PA",
    RegionType: "Msa",
    Metro: "Gettysburg",
    State: "PA",
    "Metro, State": "Gettysburg, PA",
    "CBSA Code": 23900,
  },
  {
    RegionID: 394980,
    SizeRank: 376,
    RegionName: "Pine Bluff, AR",
    RegionType: "Msa",
    Metro: "Pine Bluff",
    State: "AR",
    "Metro, State": "Pine Bluff, AR",
    "CBSA Code": 38220,
  },
  {
    RegionID: 395168,
    SizeRank: 377,
    RegionName: "Tullahoma, TN",
    RegionType: "Msa",
    Metro: "Tullahoma",
    State: "TN",
    "Metro, State": "Tullahoma, TN",
    "CBSA Code": 46100,
  },
  {
    RegionID: 395023,
    SizeRank: 378,
    RegionName: "Richmond, KY",
    RegionType: "Msa",
    Metro: "Richmond",
    State: "KY",
    "Metro, State": "Richmond, KY",
    "CBSA Code": 40080,
  },
  {
    RegionID: 394302,
    SizeRank: 379,
    RegionName: "Adrian, MI",
    RegionType: "Msa",
    Metro: "Adrian",
    State: "MI",
    "Metro, State": "Adrian, MI",
    "CBSA Code": 10300,
  },
  {
    RegionID: 395173,
    SizeRank: 380,
    RegionName: "Twin Falls, ID",
    RegionType: "Msa",
    Metro: "Twin Falls",
    State: "ID",
    "Metro, State": "Twin Falls, ID",
    "CBSA Code": 46300,
  },
  {
    RegionID: 394501,
    SizeRank: 381,
    RegionName: "Corning, NY",
    RegionType: "Msa",
    Metro: "Corning",
    State: "NY",
    "Metro, State": "Corning, NY",
    "CBSA Code": 18500,
  },
  {
    RegionID: 395080,
    SizeRank: 382,
    RegionName: "Sebring, FL",
    RegionType: "Msa",
    Metro: "Sebring",
    State: "FL",
    "Metro, State": "Sebring, FL",
    "CBSA Code": 42700,
  },
  {
    RegionID: 395166,
    SizeRank: 383,
    RegionName: "Truckee, CA",
    RegionType: "Msa",
    Metro: "Truckee",
    State: "CA",
    "Metro, State": "Truckee, CA",
    "CBSA Code": 46020,
  },
  {
    RegionID: 394953,
    SizeRank: 384,
    RegionName: "Paducah, KY",
    RegionType: "Msa",
    Metro: "Paducah",
    State: "KY",
    "Metro, State": "Paducah, KY",
    "CBSA Code": 37140,
  },
  {
    RegionID: 394637,
    SizeRank: 385,
    RegionName: "Grand Forks, ND",
    RegionType: "Msa",
    Metro: "Grand Forks",
    State: "ND",
    "Metro, State": "Grand Forks, ND",
    "CBSA Code": 24220,
  },
  {
    RegionID: 395090,
    SizeRank: 386,
    RegionName: "Shelby, NC",
    RegionType: "Msa",
    Metro: "Shelby",
    State: "NC",
    "Metro, State": "Shelby, NC",
    "CBSA Code": 43140,
  },
  {
    RegionID: 394580,
    SizeRank: 387,
    RegionName: "Fairbanks, AK",
    RegionType: "Msa",
    Metro: "Fairbanks",
    State: "AK",
    "Metro, State": "Fairbanks, AK",
    "CBSA Code": 21820,
  },
  {
    RegionID: 394928,
    SizeRank: 388,
    RegionName: "Ocean City, NJ",
    RegionType: "Msa",
    Metro: "Ocean City",
    State: "NJ",
    "Metro, State": "Ocean City, NJ",
    "CBSA Code": 36140,
  },
  {
    RegionID: 394823,
    SizeRank: 389,
    RegionName: "Mankato, MN",
    RegionType: "Msa",
    Metro: "Mankato",
    State: "MN",
    "Metro, State": "Mankato, MN",
    "CBSA Code": 31860,
  },
  {
    RegionID: 395038,
    SizeRank: 390,
    RegionName: "Rome, GA",
    RegionType: "Msa",
    Metro: "Rome",
    State: "GA",
    "Metro, State": "Rome, GA",
    "CBSA Code": 40660,
  },
  {
    RegionID: 394441,
    SizeRank: 391,
    RegionName: "Cape Girardeau, MO",
    RegionType: "Msa",
    Metro: "Cape Girardeau",
    State: "MO",
    "Metro, State": "Cape Girardeau, MO",
    "CBSA Code": 16020,
  },
  {
    RegionID: 394689,
    SizeRank: 392,
    RegionName: "Hot Springs, AR",
    RegionType: "Msa",
    Metro: "Hot Springs",
    State: "AR",
    "Metro, State": "Hot Springs, AR",
    "CBSA Code": 26300,
  },
  {
    RegionID: 394655,
    SizeRank: 393,
    RegionName: "Greenwood, SC",
    RegionType: "Msa",
    Metro: "Greenwood",
    State: "SC",
    "Metro, State": "Greenwood, SC",
    "CBSA Code": 24940,
  },
  {
    RegionID: 395140,
    SizeRank: 394,
    RegionName: "Sunbury, PA",
    RegionType: "Msa",
    Metro: "Sunbury",
    State: "PA",
    "Metro, State": "Sunbury, PA",
    "CBSA Code": 44980,
  },
  {
    RegionID: 394469,
    SizeRank: 395,
    RegionName: "Clarksburg, WV",
    RegionType: "Msa",
    Metro: "Clarksburg",
    State: "WV",
    "Metro, State": "Clarksburg, WV",
    "CBSA Code": 17220,
  },
  {
    RegionID: 395190,
    SizeRank: 396,
    RegionName: "Victoria, TX",
    RegionType: "Msa",
    Metro: "Victoria",
    State: "TX",
    "Metro, State": "Victoria, TX",
    "CBSA Code": 47020,
  },
  {
    RegionID: 395145,
    SizeRank: 397,
    RegionName: "Talladega, AL",
    RegionType: "Msa",
    Metro: "Talladega",
    State: "AL",
    "Metro, State": "Talladega, AL",
    "CBSA Code": 45180,
  },
  {
    RegionID: 394542,
    SizeRank: 398,
    RegionName: "Dubuque, IA",
    RegionType: "Msa",
    Metro: "Dubuque",
    State: "IA",
    "Metro, State": "Dubuque, IA",
    "CBSA Code": 20220,
  },
  {
    RegionID: 395153,
    SizeRank: 399,
    RegionName: "The Villages, FL",
    RegionType: "Msa",
    Metro: "The Villages",
    State: "FL",
    "Metro, State": "The Villages, FL",
    "CBSA Code": 45540,
  },
  {
    RegionID: 394311,
    SizeRank: 400,
    RegionName: "Albertville, AL",
    RegionType: "Msa",
    Metro: "Albertville",
    State: "AL",
    "Metro, State": "Albertville, AL",
    "CBSA Code": 10700,
  },
  {
    RegionID: 394821,
    SizeRank: 401,
    RegionName: "Manhattan, KS",
    RegionType: "Msa",
    Metro: "Manhattan",
    State: "KS",
    "Metro, State": "Manhattan, KS",
    "CBSA Code": 31740,
  },
  {
    RegionID: 394964,
    SizeRank: 402,
    RegionName: "Parkersburg, WV",
    RegionType: "Msa",
    Metro: "Parkersburg",
    State: "WV",
    "Metro, State": "Parkersburg, WV",
    "CBSA Code": 37620,
  },
  {
    RegionID: 394911,
    SizeRank: 403,
    RegionName: "New Philadelphia, OH",
    RegionType: "Msa",
    Metro: "New Philadelphia",
    State: "OH",
    "Metro, State": "New Philadelphia, OH",
    "CBSA Code": 35420,
  },
  {
    RegionID: 394942,
    SizeRank: 404,
    RegionName: "Orangeburg, SC",
    RegionType: "Msa",
    Metro: "Orangeburg",
    State: "SC",
    "Metro, State": "Orangeburg, SC",
    "CBSA Code": 36700,
  },
  {
    RegionID: 394462,
    SizeRank: 405,
    RegionName: "Cheyenne, WY",
    RegionType: "Msa",
    Metro: "Cheyenne",
    State: "WY",
    "Metro, State": "Cheyenne, WY",
    "CBSA Code": 16940,
  },
  {
    RegionID: 394907,
    SizeRank: 406,
    RegionName: "New Castle, PA",
    RegionType: "Msa",
    Metro: "New Castle",
    State: "PA",
    "Metro, State": "New Castle, PA",
    "CBSA Code": 35260,
  },
  {
    RegionID: 394410,
    SizeRank: 407,
    RegionName: "Brainerd, MN",
    RegionType: "Msa",
    Metro: "Brainerd",
    State: "MN",
    "Metro, State": "Brainerd, MN",
    "CBSA Code": 14660,
  },
  {
    RegionID: 394733,
    SizeRank: 408,
    RegionName: "Kalispell, MT",
    RegionType: "Msa",
    Metro: "Kalispell",
    State: "MT",
    "Metro, State": "Kalispell, MT",
    "CBSA Code": 28060,
  },
  {
    RegionID: 395086,
    SizeRank: 409,
    RegionName: "Sevierville, TN",
    RegionType: "Msa",
    Metro: "Sevierville",
    State: "TN",
    "Metro, State": "Sevierville, TN",
    "CBSA Code": 42940,
  },
  {
    RegionID: 394325,
    SizeRank: 410,
    RegionName: "Ames, IA",
    RegionType: "Msa",
    Metro: "Ames",
    State: "IA",
    "Metro, State": "Ames, IA",
    "CBSA Code": 11180,
  },
  {
    RegionID: 394407,
    SizeRank: 411,
    RegionName: "Bozeman, MT",
    RegionType: "Msa",
    Metro: "Bozeman",
    State: "MT",
    "Metro, State": "Bozeman, MT",
    "CBSA Code": 14580,
  },
  {
    RegionID: 394882,
    SizeRank: 412,
    RegionName: "Moses Lake, WA",
    RegionType: "Msa",
    Metro: "Moses Lake",
    State: "WA",
    "Metro, State": "Moses Lake, WA",
    "CBSA Code": 34180,
  },
  {
    RegionID: 394704,
    SizeRank: 413,
    RegionName: "Indiana, PA",
    RegionType: "Msa",
    Metro: "Indiana",
    State: "PA",
    "Metro, State": "Indiana, PA",
    "CBSA Code": 26860,
  },
  {
    RegionID: 394568,
    SizeRank: 414,
    RegionName: "Elmira, NY",
    RegionType: "Msa",
    Metro: "Elmira",
    State: "NY",
    "Metro, State": "Elmira, NY",
    "CBSA Code": 21300,
  },
  {
    RegionID: 394847,
    SizeRank: 415,
    RegionName: "Meadville, PA",
    RegionType: "Msa",
    Metro: "Meadville",
    State: "PA",
    "Metro, State": "Meadville, PA",
    "CBSA Code": 32740,
  },
  {
    RegionID: 394373,
    SizeRank: 416,
    RegionName: "Beaver Dam, WI",
    RegionType: "Msa",
    Metro: "Beaver Dam",
    State: "WI",
    "Metro, State": "Beaver Dam, WI",
    "CBSA Code": 13180,
  },
  {
    RegionID: 753912,
    SizeRank: 417,
    RegionName: "Pinehurst-Southern Pines, NC",
    RegionType: "Msa",
    Metro: "Pinehurst",
    State: "NC",
    "Metro, State": "Pinehurst, NC",
    "CBSA Code": 38240,
  },
  {
    RegionID: 395175,
    SizeRank: 418,
    RegionName: "Ukiah, CA",
    RegionType: "Msa",
    Metro: "Ukiah",
    State: "CA",
    "Metro, State": "Ukiah, CA",
    "CBSA Code": 46380,
  },
  {
    RegionID: 753887,
    SizeRank: 419,
    RegionName: "Hermiston-Pendleton, OR",
    RegionType: "Msa",
    Metro: "Hermiston",
    State: "OR",
    "Metro, State": "Hermiston, OR",
    "CBSA Code": 25840,
  },
  {
    RegionID: 394809,
    SizeRank: 420,
    RegionName: "Lufkin, TX",
    RegionType: "Msa",
    Metro: "Lufkin",
    State: "TX",
    "Metro, State": "Lufkin, TX",
    "CBSA Code": 31260,
  },
  {
    RegionID: 395248,
    SizeRank: 421,
    RegionName: "Zanesville, OH",
    RegionType: "Msa",
    Metro: "Zanesville",
    State: "OH",
    "Metro, State": "Zanesville, OH",
    "CBSA Code": 49780,
  },
  {
    RegionID: 394505,
    SizeRank: 422,
    RegionName: "Corvallis, OR",
    RegionType: "Msa",
    Metro: "Corvallis",
    State: "OR",
    "Metro, State": "Corvallis, OR",
    "CBSA Code": 18700,
  },
  {
    RegionID: 394395,
    SizeRank: 423,
    RegionName: "Bloomsburg, PA",
    RegionType: "Msa",
    Metro: "Bloomsburg",
    State: "PA",
    "Metro, State": "Bloomsburg, PA",
    "CBSA Code": 14100,
  },
  {
    RegionID: 394776,
    SizeRank: 424,
    RegionName: "Laurel, MS",
    RegionType: "Msa",
    Metro: "Laurel",
    State: "MS",
    "Metro, State": "Laurel, MS",
    "CBSA Code": 29860,
  },
  {
    RegionID: 395042,
    SizeRank: 425,
    RegionName: "Russellville, AR",
    RegionType: "Msa",
    Metro: "Russellville",
    State: "AR",
    "Metro, State": "Russellville, AR",
    "CBSA Code": 40780,
  },
  {
    RegionID: 394411,
    SizeRank: 426,
    RegionName: "Branson, MO",
    RegionType: "Msa",
    Metro: "Branson",
    State: "MO",
    "Metro, State": "Branson, MO",
    "CBSA Code": 14700,
  },
  {
    RegionID: 395212,
    SizeRank: 427,
    RegionName: "Watertown, WI",
    RegionType: "Msa",
    Metro: "Watertown",
    State: "WI",
    "Metro, State": "Watertown, WI",
    "CBSA Code": 48020,
  },
  {
    RegionID: 394859,
    SizeRank: 428,
    RegionName: "Midland, MI",
    RegionType: "Msa",
    Metro: "Midland",
    State: "MI",
    "Metro, State": "Midland, MI",
    "CBSA Code": 33220,
  },
  {
    RegionID: 394941,
    SizeRank: 429,
    RegionName: "Opelousas, LA",
    RegionType: "Msa",
    Metro: "Opelousas",
    State: "LA",
    "Metro, State": "Opelousas, LA",
    "CBSA Code": 36660,
  },
  {
    RegionID: 394988,
    SizeRank: 430,
    RegionName: "Pocatello, ID",
    RegionType: "Msa",
    Metro: "Pocatello",
    State: "ID",
    "Metro, State": "Pocatello, ID",
    "CBSA Code": 38540,
  },
  {
    RegionID: 394755,
    SizeRank: 431,
    RegionName: "Kokomo, IN",
    RegionType: "Msa",
    Metro: "Kokomo",
    State: "IN",
    "Metro, State": "Kokomo, IN",
    "CBSA Code": 29020,
  },
  {
    RegionID: 394641,
    SizeRank: 432,
    RegionName: "Grants Pass, OR",
    RegionType: "Msa",
    Metro: "Grants Pass",
    State: "OR",
    "Metro, State": "Grants Pass, OR",
    "CBSA Code": 24420,
  },
  {
    RegionID: 394699,
    SizeRank: 433,
    RegionName: "Huntsville, TX",
    RegionType: "Msa",
    Metro: "Huntsville",
    State: "TX",
    "Metro, State": "Huntsville, TX",
    "CBSA Code": 26660,
  },
  {
    RegionID: 394986,
    SizeRank: 434,
    RegionName: "Plattsburgh, NY",
    RegionType: "Msa",
    Metro: "Plattsburgh",
    State: "NY",
    "Metro, State": "Plattsburgh, NY",
    "CBSA Code": 38460,
  },
  {
    RegionID: 394638,
    SizeRank: 435,
    RegionName: "Grand Island, NE",
    RegionType: "Msa",
    Metro: "Grand Island",
    State: "NE",
    "Metro, State": "Grand Island, NE",
    "CBSA Code": 24260,
  },
  {
    RegionID: 394541,
    SizeRank: 436,
    RegionName: "DuBois, PA",
    RegionType: "Msa",
    Metro: "DuBois",
    State: "PA",
    "Metro, State": "DuBois, PA",
    "CBSA Code": 20180,
  },
  {
    RegionID: 394516,
    SizeRank: 437,
    RegionName: "Danville, IL",
    RegionType: "Msa",
    Metro: "Danville",
    State: "IL",
    "Metro, State": "Danville, IL",
    "CBSA Code": 19180,
  },
  {
    RegionID: 394822,
    SizeRank: 438,
    RegionName: "Manitowoc, WI",
    RegionType: "Msa",
    Metro: "Manitowoc",
    State: "WI",
    "Metro, State": "Manitowoc, WI",
    "CBSA Code": 31820,
  },
  {
    RegionID: 394644,
    SizeRank: 439,
    RegionName: "Great Falls, MT",
    RegionType: "Msa",
    Metro: "Great Falls",
    State: "MT",
    "Metro, State": "Great Falls, MT",
    "CBSA Code": 24500,
  },
  {
    RegionID: 395231,
    SizeRank: 440,
    RegionName: "Wilson, NC",
    RegionType: "Msa",
    Metro: "Wilson",
    State: "NC",
    "Metro, State": "Wilson, NC",
    "CBSA Code": 48980,
  },
  {
    RegionID: 394511,
    SizeRank: 441,
    RegionName: "Cullman, AL",
    RegionType: "Msa",
    Metro: "Cullman",
    State: "AL",
    "Metro, State": "Cullman, AL",
    "CBSA Code": 18980,
  },
  {
    RegionID: 394936,
    SizeRank: 442,
    RegionName: "Olean, NY",
    RegionType: "Msa",
    Metro: "Olean",
    State: "NY",
    "Metro, State": "Olean, NY",
    "CBSA Code": 36460,
  },
  {
    RegionID: 394350,
    SizeRank: 443,
    RegionName: "Auburn, NY",
    RegionType: "Msa",
    Metro: "Auburn",
    State: "NY",
    "Metro, State": "Auburn, NY",
    "CBSA Code": 12180,
  },
  {
    RegionID: 394999,
    SizeRank: 444,
    RegionName: "Portsmouth, OH",
    RegionType: "Msa",
    Metro: "Portsmouth",
    State: "OH",
    "Metro, State": "Portsmouth, OH",
    "CBSA Code": 39020,
  },
  {
    RegionID: 394345,
    SizeRank: 445,
    RegionName: "Athens, TX",
    RegionType: "Msa",
    Metro: "Athens",
    State: "TX",
    "Metro, State": "Athens, TX",
    "CBSA Code": 11980,
  },
  {
    RegionID: 394925,
    SizeRank: 446,
    RegionName: "Oak Harbor, WA",
    RegionType: "Msa",
    Metro: "Oak Harbor",
    State: "WA",
    "Metro, State": "Oak Harbor, WA",
    "CBSA Code": 36020,
  },
  {
    RegionID: 394465,
    SizeRank: 447,
    RegionName: "Chillicothe, OH",
    RegionType: "Msa",
    Metro: "Chillicothe",
    State: "OH",
    "Metro, State": "Chillicothe, OH",
    "CBSA Code": 17060,
  },
  {
    RegionID: 394682,
    SizeRank: 448,
    RegionName: "Hinesville, GA",
    RegionType: "Msa",
    Metro: "Hinesville",
    State: "GA",
    "Metro, State": "Hinesville, GA",
    "CBSA Code": 25980,
  },
  {
    RegionID: 395106,
    SizeRank: 449,
    RegionName: "Somerset, PA",
    RegionType: "Msa",
    Metro: "Somerset",
    State: "PA",
    "Metro, State": "Somerset, PA",
    "CBSA Code": 43740,
  },
  {
    RegionID: 395205,
    SizeRank: 450,
    RegionName: "Warsaw, IN",
    RegionType: "Msa",
    Metro: "Warsaw",
    State: "IN",
    "Metro, State": "Warsaw, IN",
    "CBSA Code": 47700,
  },
  {
    RegionID: 395133,
    SizeRank: 451,
    RegionName: "Stillwater, OK",
    RegionType: "Msa",
    Metro: "Stillwater",
    State: "OK",
    "Metro, State": "Stillwater, OK",
    "CBSA Code": 44660,
  },
  {
    RegionID: 395010,
    SizeRank: 452,
    RegionName: "Quincy, IL",
    RegionType: "Msa",
    Metro: "Quincy",
    State: "IL",
    "Metro, State": "Quincy, IL",
    "CBSA Code": 39500,
  },
  {
    RegionID: 394738,
    SizeRank: 453,
    RegionName: "Keene, NH",
    RegionType: "Msa",
    Metro: "Keene",
    State: "NH",
    "Metro, State": "Keene, NH",
    "CBSA Code": 28300,
  },
  {
    RegionID: 395062,
    SizeRank: 454,
    RegionName: "Sandusky, OH",
    RegionType: "Msa",
    Metro: "Sandusky",
    State: "OH",
    "Metro, State": "Sandusky, OH",
    "CBSA Code": 41780,
  },
  {
    RegionID: 395077,
    SizeRank: 455,
    RegionName: "Searcy, AR",
    RegionType: "Msa",
    Metro: "Searcy",
    State: "AR",
    "Metro, State": "Searcy, AR",
    "CBSA Code": 42620,
  },
  {
    RegionID: 394489,
    SizeRank: 456,
    RegionName: "Columbus, IN",
    RegionType: "Msa",
    Metro: "Columbus",
    State: "IN",
    "Metro, State": "Columbus, IN",
    "CBSA Code": 18020,
  },
  {
    RegionID: 395027,
    SizeRank: 457,
    RegionName: "Roanoke Rapids, NC",
    RegionType: "Msa",
    Metro: "Roanoke Rapids",
    State: "NC",
    "Metro, State": "Roanoke Rapids, NC",
    "CBSA Code": 40260,
  },
  {
    RegionID: 394452,
    SizeRank: 458,
    RegionName: "Centralia, WA",
    RegionType: "Msa",
    Metro: "Centralia",
    State: "WA",
    "Metro, State": "Centralia, WA",
    "CBSA Code": 16500,
  },
  {
    RegionID: 394445,
    SizeRank: 459,
    RegionName: "Casper, WY",
    RegionType: "Msa",
    Metro: "Casper",
    State: "WY",
    "Metro, State": "Casper, WY",
    "CBSA Code": 16220,
  },
  {
    RegionID: 394675,
    SizeRank: 460,
    RegionName: "Helena, MT",
    RegionType: "Msa",
    Metro: "Helena",
    State: "MT",
    "Metro, State": "Helena, MT",
    "CBSA Code": 25740,
  },
  {
    RegionID: 394593,
    SizeRank: 461,
    RegionName: "Findlay, OH",
    RegionType: "Msa",
    Metro: "Findlay",
    State: "OH",
    "Metro, State": "Findlay, OH",
    "CBSA Code": 22300,
  },
  {
    RegionID: 753928,
    SizeRank: 462,
    RegionName: "Wisconsin Rapids-Marshfield, WI",
    RegionType: "Msa",
    Metro: "Wisconsin Rapids",
    State: "WI",
    "Metro, State": "Wisconsin Rapids, WI",
    "CBSA Code": 49220,
  },
  {
    RegionID: 394955,
    SizeRank: 463,
    RegionName: "Palatka, FL",
    RegionType: "Msa",
    Metro: "Palatka",
    State: "FL",
    "Metro, State": "Palatka, FL",
    "CBSA Code": 37260,
  },
  {
    RegionID: 395085,
    SizeRank: 464,
    RegionName: "Seneca, SC",
    RegionType: "Msa",
    Metro: "Seneca",
    State: "SC",
    "Metro, State": "Seneca, SC",
    "CBSA Code": 42860,
  },
  {
    RegionID: 394884,
    SizeRank: 465,
    RegionName: "Mount Airy, NC",
    RegionType: "Msa",
    Metro: "Mount Airy",
    State: "NC",
    "Metro, State": "Mount Airy, NC",
    "CBSA Code": 34340,
  },
  {
    RegionID: 753881,
    SizeRank: 466,
    RegionName: "Glenwood Springs, CO",
    RegionType: "Msa",
    Metro: "Glenwood Springs",
    State: "CO",
    "Metro, State": "Glenwood Springs, CO",
    "CBSA Code": 24060,
  },
  {
    RegionID: 394744,
    SizeRank: 467,
    RegionName: "Key West, FL",
    RegionType: "Msa",
    Metro: "Key West",
    State: "FL",
    "Metro, State": "Key West, FL",
    "CBSA Code": 28580,
  },
  {
    RegionID: 394298,
    SizeRank: 468,
    RegionName: "Aberdeen, WA",
    RegionType: "Msa",
    Metro: "Aberdeen",
    State: "WA",
    "Metro, State": "Aberdeen, WA",
    "CBSA Code": 10140,
  },
  {
    RegionID: 394626,
    SizeRank: 469,
    RegionName: "Gallup, NM",
    RegionType: "Msa",
    Metro: "Gallup",
    State: "NM",
    "Metro, State": "Gallup, NM",
    "CBSA Code": 23700,
  },
  {
    RegionID: 394994,
    SizeRank: 470,
    RegionName: "Port Angeles, WA",
    RegionType: "Msa",
    Metro: "Port Angeles",
    State: "WA",
    "Metro, State": "Port Angeles, WA",
    "CBSA Code": 38820,
  },
  {
    RegionID: 753883,
    SizeRank: 471,
    RegionName: "Greenfield Town, MA",
    RegionType: "Msa",
    Metro: "Greenfield Town",
    State: "MA",
    "Metro, State": "Greenfield Town, MA",
    "CBSA Code": "-",
  },
  {
    RegionID: 394897,
    SizeRank: 473,
    RegionName: "Muskogee, OK",
    RegionType: "Msa",
    Metro: "Muskogee",
    State: "OK",
    "Metro, State": "Muskogee, OK",
    "CBSA Code": 34780,
  },
  {
    RegionID: 394614,
    SizeRank: 474,
    RegionName: "Frankfort, KY",
    RegionType: "Msa",
    Metro: "Frankfort",
    State: "KY",
    "Metro, State": "Frankfort, KY",
    "CBSA Code": 23180,
  },
  {
    RegionID: 394950,
    SizeRank: 475,
    RegionName: "Owosso, MI",
    RegionType: "Msa",
    Metro: "Owosso",
    State: "MI",
    "Metro, State": "Owosso, MI",
    "CBSA Code": "-",
  },
  {
    RegionID: 394885,
    SizeRank: 476,
    RegionName: "Mount Pleasant, MI",
    RegionType: "Msa",
    Metro: "Mount Pleasant",
    State: "MI",
    "Metro, State": "Mount Pleasant, MI",
    "CBSA Code": 34380,
  },
  {
    RegionID: 395126,
    SizeRank: 477,
    RegionName: "Statesboro, GA",
    RegionType: "Msa",
    Metro: "Statesboro",
    State: "GA",
    "Metro, State": "Statesboro, GA",
    "CBSA Code": 44340,
  },
  {
    RegionID: 394826,
    SizeRank: 478,
    RegionName: "Marion, IN",
    RegionType: "Msa",
    Metro: "Marion",
    State: "IN",
    "Metro, State": "Marion, IN",
    "CBSA Code": 31980,
  },
  {
    RegionID: 395132,
    SizeRank: 479,
    RegionName: "Stevens Point, WI",
    RegionType: "Msa",
    Metro: "Stevens Point",
    State: "WI",
    "Metro, State": "Stevens Point, WI",
    "CBSA Code": 44620,
  },
  {
    RegionID: 394866,
    SizeRank: 480,
    RegionName: "Minot, ND",
    RegionType: "Msa",
    Metro: "Minot",
    State: "ND",
    "Metro, State": "Minot, ND",
    "CBSA Code": 33500,
  },
  {
    RegionID: 395088,
    SizeRank: 481,
    RegionName: "Shawnee, OK",
    RegionType: "Msa",
    Metro: "Shawnee",
    State: "OK",
    "Metro, State": "Shawnee, OK",
    "CBSA Code": 43060,
  },
  {
    RegionID: 394922,
    SizeRank: 482,
    RegionName: "North Wilkesboro, NC",
    RegionType: "Msa",
    Metro: "North Wilkesboro",
    State: "NC",
    "Metro, State": "North Wilkesboro, NC",
    "CBSA Code": 35900,
  },
  {
    RegionID: 395021,
    SizeRank: 483,
    RegionName: "Richmond, IN",
    RegionType: "Msa",
    Metro: "Richmond",
    State: "IN",
    "Metro, State": "Richmond, IN",
    "CBSA Code": 39980,
  },
  {
    RegionID: 394647,
    SizeRank: 484,
    RegionName: "Greeneville, TN",
    RegionType: "Msa",
    Metro: "Greeneville",
    State: "TN",
    "Metro, State": "Greeneville, TN",
    "CBSA Code": 24620,
  },
  {
    RegionID: 394836,
    SizeRank: 485,
    RegionName: "Martinsville, VA",
    RegionType: "Msa",
    Metro: "Martinsville",
    State: "VA",
    "Metro, State": "Martinsville, VA",
    "CBSA Code": 32300,
  },
  {
    RegionID: 394600,
    SizeRank: 486,
    RegionName: "Forest City, NC",
    RegionType: "Msa",
    Metro: "Forest City",
    State: "NC",
    "Metro, State": "Forest City, NC",
    "CBSA Code": 22580,
  },
  {
    RegionID: 394764,
    SizeRank: 487,
    RegionName: "Lake City, FL",
    RegionType: "Msa",
    Metro: "Lake City",
    State: "FL",
    "Metro, State": "Lake City, FL",
    "CBSA Code": 29380,
  },
  {
    RegionID: 394736,
    SizeRank: 488,
    RegionName: "Kapaa, HI",
    RegionType: "Msa",
    Metro: "Kapaa",
    State: "HI",
    "Metro, State": "Kapaa, HI",
    "CBSA Code": 28180,
  },
  {
    RegionID: 394829,
    SizeRank: 489,
    RegionName: "Marquette, MI",
    RegionType: "Msa",
    Metro: "Marquette",
    State: "MI",
    "Metro, State": "Marquette, MI",
    "CBSA Code": 32100,
  },
  {
    RegionID: 394762,
    SizeRank: 490,
    RegionName: "LaGrange, GA",
    RegionType: "Msa",
    Metro: "LaGrange",
    State: "GA",
    "Metro, State": "LaGrange, GA",
    "CBSA Code": 29300,
  },
  {
    RegionID: 394827,
    SizeRank: 491,
    RegionName: "Marion, OH",
    RegionType: "Msa",
    Metro: "Marion",
    State: "OH",
    "Metro, State": "Marion, OH",
    "CBSA Code": 32020,
  },
  {
    RegionID: 394877,
    SizeRank: 492,
    RegionName: "Morehead City, NC",
    RegionType: "Msa",
    Metro: "Morehead City",
    State: "NC",
    "Metro, State": "Morehead City, NC",
    "CBSA Code": 33980,
  },
  {
    RegionID: 394752,
    SizeRank: 493,
    RegionName: "Klamath Falls, OR",
    RegionType: "Msa",
    Metro: "Klamath Falls",
    State: "OR",
    "Metro, State": "Klamath Falls, OR",
    "CBSA Code": 28900,
  },
  {
    RegionID: 394825,
    SizeRank: 494,
    RegionName: "Marinette, WI",
    RegionType: "Msa",
    Metro: "Marinette",
    State: "WI",
    "Metro, State": "Marinette, WI",
    "CBSA Code": 31940,
  },
  {
    RegionID: 395040,
    SizeRank: 495,
    RegionName: "Roswell, NM",
    RegionType: "Msa",
    Metro: "Roswell",
    State: "NM",
    "Metro, State": "Roswell, NM",
    "CBSA Code": 40740,
  },
  {
    RegionID: 394832,
    SizeRank: 496,
    RegionName: "Marshall, TX",
    RegionType: "Msa",
    Metro: "Marshall",
    State: "TX",
    "Metro, State": "Marshall, TX",
    "CBSA Code": "-",
  },
  {
    RegionID: 394587,
    SizeRank: 497,
    RegionName: "Farmington, MO",
    RegionType: "Msa",
    Metro: "Farmington",
    State: "MO",
    "Metro, State": "Farmington, MO",
    "CBSA Code": 22100,
  },
  {
    RegionID: 394456,
    SizeRank: 498,
    RegionName: "Charleston, IL",
    RegionType: "Msa",
    Metro: "Charleston",
    State: "IL",
    "Metro, State": "Charleston, IL",
    "CBSA Code": 16660,
  },
  {
    RegionID: 394343,
    SizeRank: 499,
    RegionName: "Athens, OH",
    RegionType: "Msa",
    Metro: "Athens",
    State: "OH",
    "Metro, State": "Athens, OH",
    "CBSA Code": 11900,
  },
  {
    RegionID: 394683,
    SizeRank: 500,
    RegionName: "Hobbs, NM",
    RegionType: "Msa",
    Metro: "Hobbs",
    State: "NM",
    "Metro, State": "Hobbs, NM",
    "CBSA Code": 26020,
  },
  {
    RegionID: 394472,
    SizeRank: 501,
    RegionName: "Clearlake, CA",
    RegionType: "Msa",
    Metro: "Clearlake",
    State: "CA",
    "Metro, State": "Clearlake, CA",
    "CBSA Code": 17340,
  },
  {
    RegionID: 394899,
    SizeRank: 502,
    RegionName: "Nacogdoches, TX",
    RegionType: "Msa",
    Metro: "Nacogdoches",
    State: "TX",
    "Metro, State": "Nacogdoches, TX",
    "CBSA Code": 34860,
  },
  {
    RegionID: 394701,
    SizeRank: 503,
    RegionName: "Hutchinson, KS",
    RegionType: "Msa",
    Metro: "Hutchinson",
    State: "KS",
    "Metro, State": "Hutchinson, KS",
    "CBSA Code": 26740,
  },
  {
    RegionID: 394586,
    SizeRank: 504,
    RegionName: "Faribault, MN",
    RegionType: "Msa",
    Metro: "Faribault",
    State: "MN",
    "Metro, State": "Faribault, MN",
    "CBSA Code": 22060,
  },
  {
    RegionID: 394562,
    SizeRank: 505,
    RegionName: "Elizabeth City, NC",
    RegionType: "Msa",
    Metro: "Elizabeth City",
    State: "NC",
    "Metro, State": "Elizabeth City, NC",
    "CBSA Code": 21020,
  },
  {
    RegionID: 753891,
    SizeRank: 506,
    RegionName: "Ionia, MI",
    RegionType: "Msa",
    Metro: "Ionia",
    State: "MI",
    "Metro, State": "Ionia, MI",
    "CBSA Code": "-",
  },
  {
    RegionID: 394305,
    SizeRank: 507,
    RegionName: "Alamogordo, NM",
    RegionType: "Msa",
    Metro: "Alamogordo",
    State: "NM",
    "Metro, State": "Alamogordo, NM",
    "CBSA Code": 10460,
  },
  {
    RegionID: 395016,
    SizeRank: 508,
    RegionName: "Red Bluff, CA",
    RegionType: "Msa",
    Metro: "Red Bluff",
    State: "CA",
    "Metro, State": "Red Bluff, CA",
    "CBSA Code": 39780,
  },
  {
    RegionID: 394693,
    SizeRank: 509,
    RegionName: "Hudson, NY",
    RegionType: "Msa",
    Metro: "Hudson",
    State: "NY",
    "Metro, State": "Hudson, NY",
    "CBSA Code": 26460,
  },
  {
    RegionID: 395105,
    SizeRank: 510,
    RegionName: "Somerset, KY",
    RegionType: "Msa",
    Metro: "Somerset",
    State: "KY",
    "Metro, State": "Somerset, KY",
    "CBSA Code": 43700,
  },
  {
    RegionID: 394496,
    SizeRank: 511,
    RegionName: "Coos Bay, OR",
    RegionType: "Msa",
    Metro: "Coos Bay",
    State: "OR",
    "Metro, State": "Coos Bay, OR",
    "CBSA Code": 18300,
  },
  {
    RegionID: 395199,
    SizeRank: 512,
    RegionName: "Walla Walla, WA",
    RegionType: "Msa",
    Metro: "Walla Walla",
    State: "WA",
    "Metro, State": "Walla Walla, WA",
    "CBSA Code": 47460,
  },
  {
    RegionID: 395071,
    SizeRank: 513,
    RegionName: "Sayre, PA",
    RegionType: "Msa",
    Metro: "Sayre",
    State: "PA",
    "Metro, State": "Sayre, PA",
    "CBSA Code": 42380,
  },
  {
    RegionID: 394939,
    SizeRank: 514,
    RegionName: "Oneonta, NY",
    RegionType: "Msa",
    Metro: "Oneonta",
    State: "NY",
    "Metro, State": "Oneonta, NY",
    "CBSA Code": 36580,
  },
  {
    RegionID: 394605,
    SizeRank: 515,
    RegionName: "Fort Madison, IA",
    RegionType: "Msa",
    Metro: "Fort Madison",
    State: "IA",
    "Metro, State": "Fort Madison, IA",
    "CBSA Code": 22800,
  },
  {
    RegionID: 394360,
    SizeRank: 516,
    RegionName: "Baraboo, WI",
    RegionType: "Msa",
    Metro: "Baraboo",
    State: "WI",
    "Metro, State": "Baraboo, WI",
    "CBSA Code": 12660,
  },
  {
    RegionID: 753903,
    SizeRank: 517,
    RegionName: "Marietta, OH",
    RegionType: "Msa",
    Metro: "Marietta",
    State: "OH",
    "Metro, State": "Marietta, OH",
    "CBSA Code": 31930,
  },
  {
    RegionID: 395049,
    SizeRank: 518,
    RegionName: "Salina, KS",
    RegionType: "Msa",
    Metro: "Salina",
    State: "KS",
    "Metro, State": "Salina, KS",
    "CBSA Code": 41460,
  },
  {
    RegionID: 395044,
    SizeRank: 519,
    RegionName: "Rutland, VT",
    RegionType: "Msa",
    Metro: "Rutland",
    State: "VT",
    "Metro, State": "Rutland, VT",
    "CBSA Code": 40860,
  },
  {
    RegionID: 753866,
    SizeRank: 520,
    RegionName: "Big Stone Gap, VA",
    RegionType: "Msa",
    Metro: "Big Stone Gap",
    State: "VA",
    "Metro, State": "Big Stone Gap, VA",
    "CBSA Code": 13720,
  },
  {
    RegionID: 395136,
    SizeRank: 521,
    RegionName: "Sturgis, MI",
    RegionType: "Msa",
    Metro: "Sturgis",
    State: "MI",
    "Metro, State": "Sturgis, MI",
    "CBSA Code": 44780,
  },
  {
    RegionID: 395024,
    SizeRank: 522,
    RegionName: "Rio Grande City, TX",
    RegionType: "Msa",
    Metro: "Rio Grande City",
    State: "TX",
    "Metro, State": "Rio Grande City, TX",
    "CBSA Code": 40100,
  },
  {
    RegionID: 394618,
    SizeRank: 523,
    RegionName: "Fremont, OH",
    RegionType: "Msa",
    Metro: "Fremont",
    State: "OH",
    "Metro, State": "Fremont, OH",
    "CBSA Code": 23380,
  },
  {
    RegionID: 394889,
    SizeRank: 524,
    RegionName: "Mount Vernon, OH",
    RegionType: "Msa",
    Metro: "Mount Vernon",
    State: "OH",
    "Metro, State": "Mount Vernon, OH",
    "CBSA Code": 34540,
  },
  {
    RegionID: 394787,
    SizeRank: 525,
    RegionName: "Lewiston, ID",
    RegionType: "Msa",
    Metro: "Lewiston",
    State: "ID",
    "Metro, State": "Lewiston, ID",
    "CBSA Code": 30300,
  },
  {
    RegionID: 395092,
    SizeRank: 526,
    RegionName: "Shelton, WA",
    RegionType: "Msa",
    Metro: "Shelton",
    State: "WA",
    "Metro, State": "Shelton, WA",
    "CBSA Code": 43220,
  },
  {
    RegionID: 394309,
    SizeRank: 527,
    RegionName: "Albemarle, NC",
    RegionType: "Msa",
    Metro: "Albemarle",
    State: "NC",
    "Metro, State": "Albemarle, NC",
    "CBSA Code": 10620,
  },
  {
    RegionID: 394570,
    SizeRank: 528,
    RegionName: "Enid, OK",
    RegionType: "Msa",
    Metro: "Enid",
    State: "OK",
    "Metro, State": "Enid, OK",
    "CBSA Code": 21420,
  },
  {
    RegionID: 753893,
    SizeRank: 529,
    RegionName: "Jefferson, GA",
    RegionType: "Msa",
    Metro: "Jefferson",
    State: "GA",
    "Metro, State": "Jefferson, GA",
    "CBSA Code": 27600,
  },
  {
    RegionID: 394629,
    SizeRank: 530,
    RegionName: "Georgetown, SC",
    RegionType: "Msa",
    Metro: "Georgetown",
    State: "SC",
    "Metro, State": "Georgetown, SC",
    "CBSA Code": 23860,
  },
  {
    RegionID: 394759,
    SizeRank: 531,
    RegionName: "Laconia, NH",
    RegionType: "Msa",
    Metro: "Laconia",
    State: "NH",
    "Metro, State": "Laconia, NH",
    "CBSA Code": 29060,
  },
  {
    RegionID: 394365,
    SizeRank: 532,
    RegionName: "Batavia, NY",
    RegionType: "Msa",
    Metro: "Batavia",
    State: "NY",
    "Metro, State": "Batavia, NY",
    "CBSA Code": 12860,
  },
  {
    RegionID: 753910,
    SizeRank: 533,
    RegionName: "Oxford, NC",
    RegionType: "Msa",
    Metro: "Oxford",
    State: "NC",
    "Metro, State": "Oxford, NC",
    "CBSA Code": "-",
  },
  {
    RegionID: 394490,
    SizeRank: 534,
    RegionName: "Columbus, MS",
    RegionType: "Msa",
    Metro: "Columbus",
    State: "MS",
    "Metro, State": "Columbus, MS",
    "CBSA Code": 18060,
  },
  {
    RegionID: 394923,
    SizeRank: 535,
    RegionName: "Norwalk, OH",
    RegionType: "Msa",
    Metro: "Norwalk",
    State: "OH",
    "Metro, State": "Norwalk, OH",
    "CBSA Code": 35940,
  },
  {
    RegionID: 394362,
    SizeRank: 536,
    RegionName: "Barre, VT",
    RegionType: "Msa",
    Metro: "Barre",
    State: "VT",
    "Metro, State": "Barre, VT",
    "CBSA Code": 12740,
  },
  {
    RegionID: 394750,
    SizeRank: 537,
    RegionName: "Kinston, NC",
    RegionType: "Msa",
    Metro: "Kinston",
    State: "NC",
    "Metro, State": "Kinston, NC",
    "CBSA Code": 28820,
  },
  {
    RegionID: 395131,
    SizeRank: 538,
    RegionName: "Sterling, IL",
    RegionType: "Msa",
    Metro: "Sterling",
    State: "IL",
    "Metro, State": "Sterling, IL",
    "CBSA Code": 44580,
  },
  {
    RegionID: 394956,
    SizeRank: 539,
    RegionName: "Palestine, TX",
    RegionType: "Msa",
    Metro: "Palestine",
    State: "TX",
    "Metro, State": "Palestine, TX",
    "CBSA Code": 37300,
  },
  {
    RegionID: 394540,
    SizeRank: 540,
    RegionName: "Dublin, GA",
    RegionType: "Msa",
    Metro: "Dublin",
    State: "GA",
    "Metro, State": "Dublin, GA",
    "CBSA Code": 20140,
  },
  {
    RegionID: 395189,
    SizeRank: 541,
    RegionName: "Vicksburg, MS",
    RegionType: "Msa",
    Metro: "Vicksburg",
    State: "MS",
    "Metro, State": "Vicksburg, MS",
    "CBSA Code": 46980,
  },
  {
    RegionID: 394989,
    SizeRank: 542,
    RegionName: "Point Pleasant, WV",
    RegionType: "Msa",
    Metro: "Point Pleasant",
    State: "WV",
    "Metro, State": "Point Pleasant, WV",
    "CBSA Code": 38580,
  },
  {
    RegionID: 395063,
    SizeRank: 543,
    RegionName: "Sanford, NC",
    RegionType: "Msa",
    Metro: "Sanford",
    State: "NC",
    "Metro, State": "Sanford, NC",
    "CBSA Code": 41820,
  },
  {
    RegionID: 394591,
    SizeRank: 544,
    RegionName: "Fergus Falls, MN",
    RegionType: "Msa",
    Metro: "Fergus Falls",
    State: "MN",
    "Metro, State": "Fergus Falls, MN",
    "CBSA Code": 22260,
  },
  {
    RegionID: 395157,
    SizeRank: 545,
    RegionName: "Tiffin, OH",
    RegionType: "Msa",
    Metro: "Tiffin",
    State: "OH",
    "Metro, State": "Tiffin, OH",
    "CBSA Code": 45660,
  },
  {
    RegionID: 394582,
    SizeRank: 546,
    RegionName: "Fairmont, WV",
    RegionType: "Msa",
    Metro: "Fairmont",
    State: "WV",
    "Metro, State": "Fairmont, WV",
    "CBSA Code": 21900,
  },
  {
    RegionID: 394509,
    SizeRank: 547,
    RegionName: "Crossville, TN",
    RegionType: "Msa",
    Metro: "Crossville",
    State: "TN",
    "Metro, State": "Crossville, TN",
    "CBSA Code": 18900,
  },
  {
    RegionID: 394977,
    SizeRank: 548,
    RegionName: "Picayune, MS",
    RegionType: "Msa",
    Metro: "Picayune",
    State: "MS",
    "Metro, State": "Picayune, MS",
    "CBSA Code": 38100,
  },
  {
    RegionID: 394634,
    SizeRank: 549,
    RegionName: "Gloversville, NY",
    RegionType: "Msa",
    Metro: "Gloversville",
    State: "NY",
    "Metro, State": "Gloversville, NY",
    "CBSA Code": 24100,
  },
  {
    RegionID: 753920,
    SizeRank: 550,
    RegionName: "Sonora, CA",
    RegionType: "Msa",
    Metro: "Sonora",
    State: "CA",
    "Metro, State": "Sonora, CA",
    "CBSA Code": 43760,
  },
  {
    RegionID: 394621,
    SizeRank: 551,
    RegionName: "Gaffney, SC",
    RegionType: "Msa",
    Metro: "Gaffney",
    State: "SC",
    "Metro, State": "Gaffney, SC",
    "CBSA Code": 23500,
  },
  {
    RegionID: 394444,
    SizeRank: 552,
    RegionName: "Carson City, NV",
    RegionType: "Msa",
    Metro: "Carson City",
    State: "NV",
    "Metro, State": "Carson City, NV",
    "CBSA Code": 16180,
  },
  {
    RegionID: 394432,
    SizeRank: 553,
    RegionName: "Calhoun, GA",
    RegionType: "Msa",
    Metro: "Calhoun",
    State: "GA",
    "Metro, State": "Calhoun, GA",
    "CBSA Code": 15660,
  },
  {
    RegionID: 394861,
    SizeRank: 554,
    RegionName: "Milledgeville, GA",
    RegionType: "Msa",
    Metro: "Milledgeville",
    State: "GA",
    "Metro, State": "Milledgeville, GA",
    "CBSA Code": 33300,
  },
  {
    RegionID: 395216,
    SizeRank: 555,
    RegionName: "Waycross, GA",
    RegionType: "Msa",
    Metro: "Waycross",
    State: "GA",
    "Metro, State": "Waycross, GA",
    "CBSA Code": 48180,
  },
  {
    RegionID: 394933,
    SizeRank: 556,
    RegionName: "Oil City, PA",
    RegionType: "Msa",
    Metro: "Oil City",
    State: "PA",
    "Metro, State": "Oil City, PA",
    "CBSA Code": 36340,
  },
  {
    RegionID: 394721,
    SizeRank: 557,
    RegionName: "Jasper, IN",
    RegionType: "Msa",
    Metro: "Jasper",
    State: "IN",
    "Metro, State": "Jasper, IN",
    "CBSA Code": 27540,
  },
  {
    RegionID: 394878,
    SizeRank: 558,
    RegionName: "Morgan City, LA",
    RegionType: "Msa",
    Metro: "Morgan City",
    State: "LA",
    "Metro, State": "Morgan City, LA",
    "CBSA Code": 34020,
  },
  {
    RegionID: 394551,
    SizeRank: 559,
    RegionName: "Eagle Pass, TX",
    RegionType: "Msa",
    Metro: "Eagle Pass",
    State: "TX",
    "Metro, State": "Eagle Pass, TX",
    "CBSA Code": 20580,
  },
  {
    RegionID: 394940,
    SizeRank: 560,
    RegionName: "Ontario, OR",
    RegionType: "Msa",
    Metro: "Ontario",
    State: "OR",
    "Metro, State": "Ontario, OR",
    "CBSA Code": 36620,
  },
  {
    RegionID: 394967,
    SizeRank: 562,
    RegionName: "Payson, AZ",
    RegionType: "Msa",
    Metro: "Payson",
    State: "AZ",
    "Metro, State": "Payson, AZ",
    "CBSA Code": 37740,
  },
  {
    RegionID: 394844,
    SizeRank: 563,
    RegionName: "McComb, MS",
    RegionType: "Msa",
    Metro: "McComb",
    State: "MS",
    "Metro, State": "McComb, MS",
    "CBSA Code": 32620,
  },
  {
    RegionID: 395029,
    SizeRank: 564,
    RegionName: "Rochelle, IL",
    RegionType: "Msa",
    Metro: "Rochelle",
    State: "IL",
    "Metro, State": "Rochelle, IL",
    "CBSA Code": 40300,
  },
  {
    RegionID: 395073,
    SizeRank: 565,
    RegionName: "Scottsboro, AL",
    RegionType: "Msa",
    Metro: "Scottsboro",
    State: "AL",
    "Metro, State": "Scottsboro, AL",
    "CBSA Code": 42460,
  },
  {
    RegionID: 394517,
    SizeRank: 566,
    RegionName: "Danville, KY",
    RegionType: "Msa",
    Metro: "Danville",
    State: "KY",
    "Metro, State": "Danville, KY",
    "CBSA Code": 19220,
  },
  {
    RegionID: 394339,
    SizeRank: 567,
    RegionName: "Ashland, OH",
    RegionType: "Msa",
    Metro: "Ashland",
    State: "OH",
    "Metro, State": "Ashland, OH",
    "CBSA Code": 11740,
  },
  {
    RegionID: 394903,
    SizeRank: 568,
    RegionName: "Natchez, MS",
    RegionType: "Msa",
    Metro: "Natchez",
    State: "MS",
    "Metro, State": "Natchez, MS",
    "CBSA Code": 35020,
  },
  {
    RegionID: 394652,
    SizeRank: 569,
    RegionName: "Greenville, OH",
    RegionType: "Msa",
    Metro: "Greenville",
    State: "OH",
    "Metro, State": "Greenville, OH",
    "CBSA Code": 24820,
  },
  {
    RegionID: 394625,
    SizeRank: 570,
    RegionName: "Galesburg, IL",
    RegionType: "Msa",
    Metro: "Galesburg",
    State: "IL",
    "Metro, State": "Galesburg, IL",
    "CBSA Code": 23660,
  },
  {
    RegionID: 395204,
    SizeRank: 571,
    RegionName: "Warrensburg, MO",
    RegionType: "Msa",
    Metro: "Warrensburg",
    State: "MO",
    "Metro, State": "Warrensburg, MO",
    "CBSA Code": 47660,
  },
  {
    RegionID: 394737,
    SizeRank: 572,
    RegionName: "Kearney, NE",
    RegionType: "Msa",
    Metro: "Kearney",
    State: "NE",
    "Metro, State": "Kearney, NE",
    "CBSA Code": 28260,
  },
  {
    RegionID: 394608,
    SizeRank: 573,
    RegionName: "Fort Polk South, LA",
    RegionType: "Msa",
    Metro: "Fort Polk South",
    State: "LA",
    "Metro, State": "Fort Polk South, LA",
    "CBSA Code": 22860,
  },
  {
    RegionID: 394604,
    SizeRank: 574,
    RegionName: "Fort Leonard Wood, MO",
    RegionType: "Msa",
    Metro: "Fort Leonard Wood",
    State: "MO",
    "Metro, State": "Fort Leonard Wood, MO",
    "CBSA Code": 22780,
  },
  {
    RegionID: 394632,
    SizeRank: 575,
    RegionName: "Glasgow, KY",
    RegionType: "Msa",
    Metro: "Glasgow",
    State: "KY",
    "Metro, State": "Glasgow, KY",
    "CBSA Code": 23980,
  },
  {
    RegionID: 394344,
    SizeRank: 576,
    RegionName: "Athens, TN",
    RegionType: "Msa",
    Metro: "Athens",
    State: "TN",
    "Metro, State": "Athens, TN",
    "CBSA Code": 11940,
  },
  {
    RegionID: 394556,
    SizeRank: 577,
    RegionName: "Edwards, CO",
    RegionType: "Msa",
    Metro: "Edwards",
    State: "CO",
    "Metro, State": "Edwards, CO",
    "CBSA Code": 20780,
  },
  {
    RegionID: 394592,
    SizeRank: 578,
    RegionName: "Fernley, NV",
    RegionType: "Msa",
    Metro: "Fernley",
    State: "NV",
    "Metro, State": "Fernley, NV",
    "CBSA Code": 22280,
  },
  {
    RegionID: 394838,
    SizeRank: 579,
    RegionName: "Mason City, IA",
    RegionType: "Msa",
    Metro: "Mason City",
    State: "IA",
    "Metro, State": "Mason City, IA",
    "CBSA Code": 32380,
  },
  {
    RegionID: 394819,
    SizeRank: 580,
    RegionName: "Malone, NY",
    RegionType: "Msa",
    Metro: "Malone",
    State: "NY",
    "Metro, State": "Malone, NY",
    "CBSA Code": 31660,
  },
  {
    RegionID: 395234,
    SizeRank: 581,
    RegionName: "Winona, MN",
    RegionType: "Msa",
    Metro: "Winona",
    State: "MN",
    "Metro, State": "Winona, MN",
    "CBSA Code": 49100,
  },
  {
    RegionID: 394547,
    SizeRank: 582,
    RegionName: "Durango, CO",
    RegionType: "Msa",
    Metro: "Durango",
    State: "CO",
    "Metro, State": "Durango, CO",
    "CBSA Code": 20420,
  },
  {
    RegionID: 394985,
    SizeRank: 583,
    RegionName: "Platteville, WI",
    RegionType: "Msa",
    Metro: "Platteville",
    State: "WI",
    "Metro, State": "Platteville, WI",
    "CBSA Code": 38420,
  },
  {
    RegionID: 394402,
    SizeRank: 585,
    RegionName: "Boone, NC",
    RegionType: "Msa",
    Metro: "Boone",
    State: "NC",
    "Metro, State": "Boone, NC",
    "CBSA Code": 14380,
  },
  {
    RegionID: 394363,
    SizeRank: 586,
    RegionName: "Bartlesville, OK",
    RegionType: "Msa",
    Metro: "Bartlesville",
    State: "OK",
    "Metro, State": "Bartlesville, OK",
    "CBSA Code": 12780,
  },
  {
    RegionID: 394717,
    SizeRank: 587,
    RegionName: "Jacksonville, TX",
    RegionType: "Msa",
    Metro: "Jacksonville",
    State: "TX",
    "Metro, State": "Jacksonville, TX",
    "CBSA Code": 27380,
  },
  {
    RegionID: 394566,
    SizeRank: 588,
    RegionName: "Elko, NV",
    RegionType: "Msa",
    Metro: "Elko",
    State: "NV",
    "Metro, State": "Elko, NV",
    "CBSA Code": 21220,
  },
  {
    RegionID: 395020,
    SizeRank: 589,
    RegionName: "Rexburg, ID",
    RegionType: "Msa",
    Metro: "Rexburg",
    State: "ID",
    "Metro, State": "Rexburg, ID",
    "CBSA Code": 39940,
  },
  {
    RegionID: 395122,
    SizeRank: 590,
    RegionName: "St. Marys, GA",
    RegionType: "Msa",
    Metro: "St. Marys",
    State: "GA",
    "Metro, State": "St. Marys, GA",
    "CBSA Code": 41220,
  },
  {
    RegionID: 753911,
    SizeRank: 591,
    RegionName: "Ozark, AL",
    RegionType: "Msa",
    Metro: "Ozark",
    State: "AL",
    "Metro, State": "Ozark, AL",
    "CBSA Code": 37120,
  },
  {
    RegionID: 394326,
    SizeRank: 592,
    RegionName: "Amsterdam, NY",
    RegionType: "Msa",
    Metro: "Amsterdam",
    State: "NY",
    "Metro, State": "Amsterdam, NY",
    "CBSA Code": 11220,
  },
  {
    RegionID: 394571,
    SizeRank: 593,
    RegionName: "Enterprise, AL",
    RegionType: "Msa",
    Metro: "Enterprise",
    State: "AL",
    "Metro, State": "Enterprise, AL",
    "CBSA Code": 21460,
  },
  {
    RegionID: 394963,
    SizeRank: 594,
    RegionName: "Paris, TX",
    RegionType: "Msa",
    Metro: "Paris",
    State: "TX",
    "Metro, State": "Paris, TX",
    "CBSA Code": 37580,
  },
  {
    RegionID: 394742,
    SizeRank: 595,
    RegionName: "Kerrville, TX",
    RegionType: "Msa",
    Metro: "Kerrville",
    State: "TX",
    "Metro, State": "Kerrville, TX",
    "CBSA Code": 28500,
  },
  {
    RegionID: 394906,
    SizeRank: 596,
    RegionName: "New Castle, IN",
    RegionType: "Msa",
    Metro: "New Castle",
    State: "IN",
    "Metro, State": "New Castle, IN",
    "CBSA Code": 35220,
  },
  {
    RegionID: 395097,
    SizeRank: 597,
    RegionName: "Sidney, OH",
    RegionType: "Msa",
    Metro: "Sidney",
    State: "OH",
    "Metro, State": "Sidney, OH",
    "CBSA Code": 43380,
  },
  {
    RegionID: 394504,
    SizeRank: 598,
    RegionName: "Cortland, NY",
    RegionType: "Msa",
    Metro: "Cortland",
    State: "NY",
    "Metro, State": "Cortland, NY",
    "CBSA Code": 18660,
  },
  {
    RegionID: 394477,
    SizeRank: 599,
    RegionName: "Clinton, IA",
    RegionType: "Msa",
    Metro: "Clinton",
    State: "IA",
    "Metro, State": "Clinton, IA",
    "CBSA Code": 17540,
  },
  {
    RegionID: 394527,
    SizeRank: 600,
    RegionName: "Del Rio, TX",
    RegionType: "Msa",
    Metro: "Del Rio",
    State: "TX",
    "Metro, State": "Del Rio, TX",
    "CBSA Code": 19620,
  },
  {
    RegionID: 394478,
    SizeRank: 601,
    RegionName: "Clovis, NM",
    RegionType: "Msa",
    Metro: "Clovis",
    State: "NM",
    "Metro, State": "Clovis, NM",
    "CBSA Code": 17580,
  },
  {
    RegionID: 394919,
    SizeRank: 602,
    RegionName: "Norfolk, NE",
    RegionType: "Msa",
    Metro: "Norfolk",
    State: "NE",
    "Metro, State": "Norfolk, NE",
    "CBSA Code": 35740,
  },
  {
    RegionID: 395208,
    SizeRank: 603,
    RegionName: "Washington, NC",
    RegionType: "Msa",
    Metro: "Washington",
    State: "NC",
    "Metro, State": "Washington, NC",
    "CBSA Code": 47820,
  },
  {
    RegionID: 394503,
    SizeRank: 604,
    RegionName: "Corsicana, TX",
    RegionType: "Msa",
    Metro: "Corsicana",
    State: "TX",
    "Metro, State": "Corsicana, TX",
    "CBSA Code": 18620,
  },
  {
    RegionID: 394616,
    SizeRank: 605,
    RegionName: "Freeport, IL",
    RegionType: "Msa",
    Metro: "Freeport",
    State: "IL",
    "Metro, State": "Freeport, IL",
    "CBSA Code": 23300,
  },
  {
    RegionID: 395124,
    SizeRank: 606,
    RegionName: "Starkville, MS",
    RegionType: "Msa",
    Metro: "Starkville",
    State: "MS",
    "Metro, State": "Starkville, MS",
    "CBSA Code": 44260,
  },
  {
    RegionID: 394427,
    SizeRank: 607,
    RegionName: "Burlington, IA",
    RegionType: "Msa",
    Metro: "Burlington",
    State: "IA",
    "Metro, State": "Burlington, IA",
    "CBSA Code": 15460,
  },
  {
    RegionID: 394431,
    SizeRank: 608,
    RegionName: "Cadillac, MI",
    RegionType: "Msa",
    Metro: "Cadillac",
    State: "MI",
    "Metro, State": "Cadillac, MI",
    "CBSA Code": 15620,
  },
  {
    RegionID: 394336,
    SizeRank: 609,
    RegionName: "Ardmore, OK",
    RegionType: "Msa",
    Metro: "Ardmore",
    State: "OK",
    "Metro, State": "Ardmore, OK",
    "CBSA Code": 11620,
  },
  {
    RegionID: 394739,
    SizeRank: 610,
    RegionName: "Kendallville, IN",
    RegionType: "Msa",
    Metro: "Kendallville",
    State: "IN",
    "Metro, State": "Kendallville, IN",
    "CBSA Code": 28340,
  },
  {
    RegionID: 394918,
    SizeRank: 611,
    RegionName: "Nogales, AZ",
    RegionType: "Msa",
    Metro: "Nogales",
    State: "AZ",
    "Metro, State": "Nogales, AZ",
    "CBSA Code": 35700,
  },
  {
    RegionID: 394951,
    SizeRank: 612,
    RegionName: "Oxford, MS",
    RegionType: "Msa",
    Metro: "Oxford",
    State: "MS",
    "Metro, State": "Oxford, MS",
    "CBSA Code": 37060,
  },
  {
    RegionID: 394398,
    SizeRank: 613,
    RegionName: "Bogalusa, LA",
    RegionType: "Msa",
    Metro: "Bogalusa",
    State: "LA",
    "Metro, State": "Bogalusa, LA",
    "CBSA Code": 14220,
  },
  {
    RegionID: 394987,
    SizeRank: 614,
    RegionName: "Plymouth, IN",
    RegionType: "Msa",
    Metro: "Plymouth",
    State: "IN",
    "Metro, State": "Plymouth, IN",
    "CBSA Code": 38500,
  },
  {
    RegionID: 394628,
    SizeRank: 615,
    RegionName: "Gardnerville Ranchos, NV",
    RegionType: "Msa",
    Metro: "Gardnerville Ranchos",
    State: "NV",
    "Metro, State": "Gardnerville Ranchos, NV",
    "CBSA Code": 23820,
  },
  {
    RegionID: 395144,
    SizeRank: 616,
    RegionName: "Tahlequah, OK",
    RegionType: "Msa",
    Metro: "Tahlequah",
    State: "OK",
    "Metro, State": "Tahlequah, OK",
    "CBSA Code": 45140,
  },
  {
    RegionID: 394817,
    SizeRank: 617,
    RegionName: "Madisonville, KY",
    RegionType: "Msa",
    Metro: "Madisonville",
    State: "KY",
    "Metro, State": "Madisonville, KY",
    "CBSA Code": 31580,
  },
  {
    RegionID: 394437,
    SizeRank: 618,
    RegionName: "Cañon City, CO",
    RegionType: "Msa",
    Metro: "Cañon City",
    State: "CO",
    "Metro, State": "Cañon City, CO",
    "CBSA Code": "-",
  },
  {
    RegionID: 395043,
    SizeRank: 619,
    RegionName: "Ruston, LA",
    RegionType: "Msa",
    Metro: "Ruston",
    State: "LA",
    "Metro, State": "Ruston, LA",
    "CBSA Code": 40820,
  },
  {
    RegionID: 753889,
    SizeRank: 620,
    RegionName: "Hillsdale, MI",
    RegionType: "Msa",
    Metro: "Hillsdale",
    State: "MI",
    "Metro, State": "Hillsdale, MI",
    "CBSA Code": 25880,
  },
  {
    RegionID: 394789,
    SizeRank: 621,
    RegionName: "Lewistown, PA",
    RegionType: "Msa",
    Metro: "Lewistown",
    State: "PA",
    "Metro, State": "Lewistown, PA",
    "CBSA Code": 30380,
  },
  {
    RegionID: 395034,
    SizeRank: 622,
    RegionName: "Rockingham, NC",
    RegionType: "Msa",
    Metro: "Rockingham",
    State: "NC",
    "Metro, State": "Rockingham, NC",
    "CBSA Code": 40460,
  },
  {
    RegionID: 394990,
    SizeRank: 623,
    RegionName: "Ponca City, OK",
    RegionType: "Msa",
    Metro: "Ponca City",
    State: "OK",
    "Metro, State": "Ponca City, OK",
    "CBSA Code": 38620,
  },
  {
    RegionID: 394397,
    SizeRank: 624,
    RegionName: "Blytheville, AR",
    RegionType: "Msa",
    Metro: "Blytheville",
    State: "AR",
    "Metro, State": "Blytheville, AR",
    "CBSA Code": 14180,
  },
  {
    RegionID: 395017,
    SizeRank: 625,
    RegionName: "Red Wing, MN",
    RegionType: "Msa",
    Metro: "Red Wing",
    State: "MN",
    "Metro, State": "Red Wing, MN",
    "CBSA Code": 39860,
  },
  {
    RegionID: 753918,
    SizeRank: 626,
    RegionName: "Shawano, WI",
    RegionType: "Msa",
    Metro: "Shawano",
    State: "WI",
    "Metro, State": "Shawano, WI",
    "CBSA Code": 43020,
  },
  {
    RegionID: 394446,
    SizeRank: 627,
    RegionName: "Cedar City, UT",
    RegionType: "Msa",
    Metro: "Cedar City",
    State: "UT",
    "Metro, State": "Cedar City, UT",
    "CBSA Code": 16260,
  },
  {
    RegionID: 394375,
    SizeRank: 628,
    RegionName: "Bedford, IN",
    RegionType: "Msa",
    Metro: "Bedford",
    State: "IN",
    "Metro, State": "Bedford, IN",
    "CBSA Code": 13260,
  },
  {
    RegionID: 394631,
    SizeRank: 629,
    RegionName: "Gillette, WY",
    RegionType: "Msa",
    Metro: "Gillette",
    State: "WY",
    "Metro, State": "Gillette, WY",
    "CBSA Code": 23940,
  },
  {
    RegionID: 753905,
    SizeRank: 630,
    RegionName: "Newport, OR",
    RegionType: "Msa",
    Metro: "Newport",
    State: "OR",
    "Metro, State": "Newport, OR",
    "CBSA Code": 35440,
  },
  {
    RegionID: 395201,
    SizeRank: 631,
    RegionName: "Wapakoneta, OH",
    RegionType: "Msa",
    Metro: "Wapakoneta",
    State: "OH",
    "Metro, State": "Wapakoneta, OH",
    "CBSA Code": 47540,
  },
  {
    RegionID: 394695,
    SizeRank: 632,
    RegionName: "Huntingdon, PA",
    RegionType: "Msa",
    Metro: "Huntingdon",
    State: "PA",
    "Metro, State": "Huntingdon, PA",
    "CBSA Code": 26500,
  },
  {
    RegionID: 394377,
    SizeRank: 633,
    RegionName: "Bellefontaine, OH",
    RegionType: "Msa",
    Metro: "Bellefontaine",
    State: "OH",
    "Metro, State": "Bellefontaine, OH",
    "CBSA Code": 13340,
  },
  {
    RegionID: 394842,
    SizeRank: 634,
    RegionName: "McAlester, OK",
    RegionType: "Msa",
    Metro: "McAlester",
    State: "OK",
    "Metro, State": "McAlester, OK",
    "CBSA Code": 32540,
  },
  {
    RegionID: 394391,
    SizeRank: 635,
    RegionName: "Blackfoot, ID",
    RegionType: "Msa",
    Metro: "Blackfoot",
    State: "ID",
    "Metro, State": "Blackfoot, ID",
    "CBSA Code": 13940,
  },
  {
    RegionID: 394883,
    SizeRank: 636,
    RegionName: "Moultrie, GA",
    RegionType: "Msa",
    Metro: "Moultrie",
    State: "GA",
    "Metro, State": "Moultrie, GA",
    "CBSA Code": 34220,
  },
  {
    RegionID: 394677,
    SizeRank: 637,
    RegionName: "Henderson, NC",
    RegionType: "Msa",
    Metro: "Henderson",
    State: "NC",
    "Metro, State": "Henderson, NC",
    "CBSA Code": 25780,
  },
  {
    RegionID: 394482,
    SizeRank: 638,
    RegionName: "Coldwater, MI",
    RegionType: "Msa",
    Metro: "Coldwater",
    State: "MI",
    "Metro, State": "Coldwater, MI",
    "CBSA Code": 17740,
  },
  {
    RegionID: 394667,
    SizeRank: 639,
    RegionName: "Harrison, AR",
    RegionType: "Msa",
    Metro: "Harrison",
    State: "AR",
    "Metro, State": "Harrison, AR",
    "CBSA Code": 25460,
  },
  {
    RegionID: 395037,
    SizeRank: 640,
    RegionName: "Rolla, MO",
    RegionType: "Msa",
    Metro: "Rolla",
    State: "MO",
    "Metro, State": "Rolla, MO",
    "CBSA Code": 40620,
  },
  {
    RegionID: 395091,
    SizeRank: 641,
    RegionName: "Shelbyville, TN",
    RegionType: "Msa",
    Metro: "Shelbyville",
    State: "TN",
    "Metro, State": "Shelbyville, TN",
    "CBSA Code": 43180,
  },
  {
    RegionID: 394545,
    SizeRank: 643,
    RegionName: "Duncan, OK",
    RegionType: "Msa",
    Metro: "Duncan",
    State: "OK",
    "Metro, State": "Duncan, OK",
    "CBSA Code": 20340,
  },
  {
    RegionID: 753904,
    SizeRank: 644,
    RegionName: "Marion, NC",
    RegionType: "Msa",
    Metro: "Marion",
    State: "NC",
    "Metro, State": "Marion, NC",
    "CBSA Code": 32000,
  },
  {
    RegionID: 394785,
    SizeRank: 645,
    RegionName: "Lewisburg, PA",
    RegionType: "Msa",
    Metro: "Lewisburg",
    State: "PA",
    "Metro, State": "Lewisburg, PA",
    "CBSA Code": 30260,
  },
  {
    RegionID: 395008,
    SizeRank: 646,
    RegionName: "Pullman, WA",
    RegionType: "Msa",
    Metro: "Pullman",
    State: "WA",
    "Metro, State": "Pullman, WA",
    "CBSA Code": 39420,
  },
  {
    RegionID: 395155,
    SizeRank: 647,
    RegionName: "Thomasville, GA",
    RegionType: "Msa",
    Metro: "Thomasville",
    State: "GA",
    "Metro, State": "Thomasville, GA",
    "CBSA Code": 45620,
  },
  {
    RegionID: 394379,
    SizeRank: 648,
    RegionName: "Bemidji, MN",
    RegionType: "Msa",
    Metro: "Bemidji",
    State: "MN",
    "Metro, State": "Bemidji, MN",
    "CBSA Code": 13420,
  },
  {
    RegionID: 394887,
    SizeRank: 649,
    RegionName: "Mount Sterling, KY",
    RegionType: "Msa",
    Metro: "Mount Sterling",
    State: "KY",
    "Metro, State": "Mount Sterling, KY",
    "CBSA Code": 34460,
  },
  {
    RegionID: 394947,
    SizeRank: 650,
    RegionName: "Ottumwa, IA",
    RegionType: "Msa",
    Metro: "Ottumwa",
    State: "IA",
    "Metro, State": "Ottumwa, IA",
    "CBSA Code": 36900,
  },
  {
    RegionID: 394954,
    SizeRank: 651,
    RegionName: "Pahrump, NV",
    RegionType: "Msa",
    Metro: "Pahrump",
    State: "NV",
    "Metro, State": "Pahrump, NV",
    "CBSA Code": 37220,
  },
  {
    RegionID: 394850,
    SizeRank: 652,
    RegionName: "Menomonie, WI",
    RegionType: "Msa",
    Metro: "Menomonie",
    State: "WI",
    "Metro, State": "Menomonie, WI",
    "CBSA Code": 32860,
  },
  {
    RegionID: 395083,
    SizeRank: 653,
    RegionName: "Selma, AL",
    RegionType: "Msa",
    Metro: "Selma",
    State: "AL",
    "Metro, State": "Selma, AL",
    "CBSA Code": 42820,
  },
  {
    RegionID: 395032,
    SizeRank: 654,
    RegionName: "Rock Springs, WY",
    RegionType: "Msa",
    Metro: "Rock Springs",
    State: "WY",
    "Metro, State": "Rock Springs, WY",
    "CBSA Code": 40540,
  },
  {
    RegionID: 394424,
    SizeRank: 655,
    RegionName: "Bucyrus, OH",
    RegionType: "Msa",
    Metro: "Bucyrus",
    State: "OH",
    "Metro, State": "Bucyrus, OH",
    "CBSA Code": 15340,
  },
  {
    RegionID: 394409,
    SizeRank: 656,
    RegionName: "Bradford, PA",
    RegionType: "Msa",
    Metro: "Bradford",
    State: "PA",
    "Metro, State": "Bradford, PA",
    "CBSA Code": 14620,
  },
  {
    RegionID: 753865,
    SizeRank: 657,
    RegionName: "Bardstown, KY",
    RegionType: "Msa",
    Metro: "Bardstown",
    State: "KY",
    "Metro, State": "Bardstown, KY",
    "CBSA Code": 12680,
  },
  {
    RegionID: 394500,
    SizeRank: 658,
    RegionName: "Cornelia, GA",
    RegionType: "Msa",
    Metro: "Cornelia",
    State: "GA",
    "Metro, State": "Cornelia, GA",
    "CBSA Code": 18460,
  },
  {
    RegionID: 394426,
    SizeRank: 659,
    RegionName: "Burley, ID",
    RegionType: "Msa",
    Metro: "Burley",
    State: "ID",
    "Metro, State": "Burley, ID",
    "CBSA Code": 15420,
  },
  {
    RegionID: 394654,
    SizeRank: 660,
    RegionName: "Greenwood, MS",
    RegionType: "Msa",
    Metro: "Greenwood",
    State: "MS",
    "Metro, State": "Greenwood, MS",
    "CBSA Code": 24900,
  },
  {
    RegionID: 394384,
    SizeRank: 661,
    RegionName: "Big Rapids, MI",
    RegionType: "Msa",
    Metro: "Big Rapids",
    State: "MI",
    "Metro, State": "Big Rapids, MI",
    "CBSA Code": 13660,
  },
  {
    RegionID: 394993,
    SizeRank: 662,
    RegionName: "Poplar Bluff, MO",
    RegionType: "Msa",
    Metro: "Poplar Bluff",
    State: "MO",
    "Metro, State": "Poplar Bluff, MO",
    "CBSA Code": 38740,
  },
  {
    RegionID: 394895,
    SizeRank: 663,
    RegionName: "Muscatine, IA",
    RegionType: "Msa",
    Metro: "Muscatine",
    State: "IA",
    "Metro, State": "Muscatine, IA",
    "CBSA Code": 34700,
  },
  {
    RegionID: 394319,
    SizeRank: 664,
    RegionName: "Alma, MI",
    RegionType: "Msa",
    Metro: "Alma",
    State: "MI",
    "Metro, State": "Alma, MI",
    "CBSA Code": 10940,
  },
  {
    RegionID: 394548,
    SizeRank: 665,
    RegionName: "Durant, OK",
    RegionType: "Msa",
    Metro: "Durant",
    State: "OK",
    "Metro, State": "Durant, OK",
    "CBSA Code": 20460,
  },
  {
    RegionID: 395087,
    SizeRank: 666,
    RegionName: "Seymour, IN",
    RegionType: "Msa",
    Metro: "Seymour",
    State: "IN",
    "Metro, State": "Seymour, IN",
    "CBSA Code": 42980,
  },
  {
    RegionID: 394538,
    SizeRank: 667,
    RegionName: "Douglas, GA",
    RegionType: "Msa",
    Metro: "Douglas",
    State: "GA",
    "Metro, State": "Douglas, GA",
    "CBSA Code": 20060,
  },
  {
    RegionID: 395228,
    SizeRank: 668,
    RegionName: "Willmar, MN",
    RegionType: "Msa",
    Metro: "Willmar",
    State: "MN",
    "Metro, State": "Willmar, MN",
    "CBSA Code": 48820,
  },
  {
    RegionID: 394349,
    SizeRank: 669,
    RegionName: "Auburn, IN",
    RegionType: "Msa",
    Metro: "Auburn",
    State: "IN",
    "Metro, State": "Auburn, IN",
    "CBSA Code": 12140,
  },
  {
    RegionID: 395081,
    SizeRank: 670,
    RegionName: "Sedalia, MO",
    RegionType: "Msa",
    Metro: "Sedalia",
    State: "MO",
    "Metro, State": "Sedalia, MO",
    "CBSA Code": 42740,
  },
  {
    RegionID: 394961,
    SizeRank: 671,
    RegionName: "Paragould, AR",
    RegionType: "Msa",
    Metro: "Paragould",
    State: "AR",
    "Metro, State": "Paragould, AR",
    "CBSA Code": 37500,
  },
  {
    RegionID: 395230,
    SizeRank: 672,
    RegionName: "Wilmington, OH",
    RegionType: "Msa",
    Metro: "Wilmington",
    State: "OH",
    "Metro, State": "Wilmington, OH",
    "CBSA Code": 48940,
  },
  {
    RegionID: 394779,
    SizeRank: 673,
    RegionName: "Lawrenceburg, TN",
    RegionType: "Msa",
    Metro: "Lawrenceburg",
    State: "TN",
    "Metro, State": "Lawrenceburg, TN",
    "CBSA Code": 29980,
  },
  {
    RegionID: 395203,
    SizeRank: 674,
    RegionName: "Warren, PA",
    RegionType: "Msa",
    Metro: "Warren",
    State: "PA",
    "Metro, State": "Warren, PA",
    "CBSA Code": 47620,
  },
  {
    RegionID: 394560,
    SizeRank: 675,
    RegionName: "El Dorado, AR",
    RegionType: "Msa",
    Metro: "El Dorado",
    State: "AR",
    "Metro, State": "El Dorado, AR",
    "CBSA Code": 20980,
  },
  {
    RegionID: 394891,
    SizeRank: 677,
    RegionName: "Mountain Home, AR",
    RegionType: "Msa",
    Metro: "Mountain Home",
    State: "AR",
    "Metro, State": "Mountain Home, AR",
    "CBSA Code": 34260,
  },
  {
    RegionID: 394448,
    SizeRank: 678,
    RegionName: "Cedartown, GA",
    RegionType: "Msa",
    Metro: "Cedartown",
    State: "GA",
    "Metro, State": "Cedartown, GA",
    "CBSA Code": 16340,
  },
  {
    RegionID: 753913,
    SizeRank: 679,
    RegionName: "Port Clinton, OH",
    RegionType: "Msa",
    Metro: "Port Clinton",
    State: "OH",
    "Metro, State": "Port Clinton, OH",
    "CBSA Code": "-",
  },
  {
    RegionID: 394558,
    SizeRank: 680,
    RegionName: "El Campo, TX",
    RegionType: "Msa",
    Metro: "El Campo",
    State: "TX",
    "Metro, State": "El Campo, TX",
    "CBSA Code": 20900,
  },
  {
    RegionID: 394876,
    SizeRank: 681,
    RegionName: "Montrose, CO",
    RegionType: "Msa",
    Metro: "Montrose",
    State: "CO",
    "Metro, State": "Montrose, CO",
    "CBSA Code": 33940,
  },
  {
    RegionID: 394567,
    SizeRank: 682,
    RegionName: "Ellensburg, WA",
    RegionType: "Msa",
    Metro: "Ellensburg",
    State: "WA",
    "Metro, State": "Ellensburg, WA",
    "CBSA Code": 21260,
  },
  {
    RegionID: 394715,
    SizeRank: 683,
    RegionName: "Jacksonville, IL",
    RegionType: "Msa",
    Metro: "Jacksonville",
    State: "IL",
    "Metro, State": "Jacksonville, IL",
    "CBSA Code": 27300,
  },
  {
    RegionID: 753916,
    SizeRank: 684,
    RegionName: "Sandpoint, ID",
    RegionType: "Msa",
    Metro: "Sandpoint",
    State: "ID",
    "Metro, State": "Sandpoint, ID",
    "CBSA Code": 41760,
  },
  {
    RegionID: 394316,
    SizeRank: 685,
    RegionName: "Alice, TX",
    RegionType: "Msa",
    Metro: "Alice",
    State: "TX",
    "Metro, State": "Alice, TX",
    "CBSA Code": 10860,
  },
  {
    RegionID: 394449,
    SizeRank: 686,
    RegionName: "Celina, OH",
    RegionType: "Msa",
    Metro: "Celina",
    State: "OH",
    "Metro, State": "Celina, OH",
    "CBSA Code": 16380,
  },
  {
    RegionID: 394627,
    SizeRank: 687,
    RegionName: "Garden City, KS",
    RegionType: "Msa",
    Metro: "Garden City",
    State: "KS",
    "Metro, State": "Garden City, KS",
    "CBSA Code": 23780,
  },
  {
    RegionID: 394833,
    SizeRank: 688,
    RegionName: "Marshalltown, IA",
    RegionType: "Msa",
    Metro: "Marshalltown",
    State: "IA",
    "Metro, State": "Marshalltown, IA",
    "CBSA Code": 32260,
  },
  {
    RegionID: 394297,
    SizeRank: 689,
    RegionName: "Aberdeen, SD",
    RegionType: "Msa",
    Metro: "Aberdeen",
    State: "SD",
    "Metro, State": "Aberdeen, SD",
    "CBSA Code": 10100,
  },
  {
    RegionID: 395219,
    SizeRank: 690,
    RegionName: "West Plains, MO",
    RegionType: "Msa",
    Metro: "West Plains",
    State: "MO",
    "Metro, State": "West Plains, MO",
    "CBSA Code": 48460,
  },
  {
    RegionID: 753877,
    SizeRank: 691,
    RegionName: "Cullowhee, NC",
    RegionType: "Msa",
    Metro: "Cullowhee",
    State: "NC",
    "Metro, State": "Cullowhee, NC",
    "CBSA Code": 19000,
  },
  {
    RegionID: 394574,
    SizeRank: 692,
    RegionName: "Espa±ola, NM",
    RegionType: "Msa",
    Metro: "Espa±ola",
    State: "NM",
    "Metro, State": "Espa±ola, NM",
    "CBSA Code": "-",
  },
  {
    RegionID: 395026,
    SizeRank: 693,
    RegionName: "Riverton, WY",
    RegionType: "Msa",
    Metro: "Riverton",
    State: "WY",
    "Metro, State": "Riverton, WY",
    "CBSA Code": 40180,
  },
  {
    RegionID: 395158,
    SizeRank: 694,
    RegionName: "Tifton, GA",
    RegionType: "Msa",
    Metro: "Tifton",
    State: "GA",
    "Metro, State": "Tifton, GA",
    "CBSA Code": 45700,
  },
  {
    RegionID: 395178,
    SizeRank: 695,
    RegionName: "Urbana, OH",
    RegionType: "Msa",
    Metro: "Urbana",
    State: "OH",
    "Metro, State": "Urbana, OH",
    "CBSA Code": 46500,
  },
  {
    RegionID: 394434,
    SizeRank: 696,
    RegionName: "Cambridge, OH",
    RegionType: "Msa",
    Metro: "Cambridge",
    State: "OH",
    "Metro, State": "Cambridge, OH",
    "CBSA Code": 15740,
  },
  {
    RegionID: 394934,
    SizeRank: 697,
    RegionName: "Okeechobee, FL",
    RegionType: "Msa",
    Metro: "Okeechobee",
    State: "FL",
    "Metro, State": "Okeechobee, FL",
    "CBSA Code": 36380,
  },
  {
    RegionID: 394845,
    SizeRank: 698,
    RegionName: "McMinnville, TN",
    RegionType: "Msa",
    Metro: "McMinnville",
    State: "TN",
    "Metro, State": "McMinnville, TN",
    "CBSA Code": 32660,
  },
  {
    RegionID: 395082,
    SizeRank: 699,
    RegionName: "Selinsgrove, PA",
    RegionType: "Msa",
    Metro: "Selinsgrove",
    State: "PA",
    "Metro, State": "Selinsgrove, PA",
    "CBSA Code": 42780,
  },
  {
    RegionID: 394904,
    SizeRank: 700,
    RegionName: "Natchitoches, LA",
    RegionType: "Msa",
    Metro: "Natchitoches",
    State: "LA",
    "Metro, State": "Natchitoches, LA",
    "CBSA Code": 35060,
  },
  {
    RegionID: 394383,
    SizeRank: 702,
    RegionName: "Berlin, NH",
    RegionType: "Msa",
    Metro: "Berlin",
    State: "NH",
    "Metro, State": "Berlin, NH",
    "CBSA Code": 13620,
  },
  {
    RegionID: 394799,
    SizeRank: 703,
    RegionName: "Lock Haven, PA",
    RegionType: "Msa",
    Metro: "Lock Haven",
    State: "PA",
    "Metro, State": "Lock Haven, PA",
    "CBSA Code": 30820,
  },
  {
    RegionID: 395099,
    SizeRank: 704,
    RegionName: "Sikeston, MO",
    RegionType: "Msa",
    Metro: "Sikeston",
    State: "MO",
    "Metro, State": "Sikeston, MO",
    "CBSA Code": 43460,
  },
  {
    RegionID: 394354,
    SizeRank: 705,
    RegionName: "Austin, MN",
    RegionType: "Msa",
    Metro: "Austin",
    State: "MN",
    "Metro, State": "Austin, MN",
    "CBSA Code": 12380,
  },
  {
    RegionID: 394476,
    SizeRank: 706,
    RegionName: "Clewiston, FL",
    RegionType: "Msa",
    Metro: "Clewiston",
    State: "FL",
    "Metro, State": "Clewiston, FL",
    "CBSA Code": 17500,
  },
  {
    RegionID: 394981,
    SizeRank: 707,
    RegionName: "Pittsburg, KS",
    RegionType: "Msa",
    Metro: "Pittsburg",
    State: "KS",
    "Metro, State": "Pittsburg, KS",
    "CBSA Code": 38260,
  },
  {
    RegionID: 394526,
    SizeRank: 708,
    RegionName: "Defiance, OH",
    RegionType: "Msa",
    Metro: "Defiance",
    State: "OH",
    "Metro, State": "Defiance, OH",
    "CBSA Code": 19580,
  },
  {
    RegionID: 395072,
    SizeRank: 709,
    RegionName: "Scottsbluff, NE",
    RegionType: "Msa",
    Metro: "Scottsbluff",
    State: "NE",
    "Metro, State": "Scottsbluff, NE",
    "CBSA Code": 42420,
  },
  {
    RegionID: 394801,
    SizeRank: 710,
    RegionName: "Logansport, IN",
    RegionType: "Msa",
    Metro: "Logansport",
    State: "IN",
    "Metro, State": "Logansport, IN",
    "CBSA Code": 30900,
  },
  {
    RegionID: 394992,
    SizeRank: 711,
    RegionName: "Pontiac, IL",
    RegionType: "Msa",
    Metro: "Pontiac",
    State: "IL",
    "Metro, State": "Pontiac, IL",
    "CBSA Code": 38700,
  },
  {
    RegionID: 394663,
    SizeRank: 712,
    RegionName: "Hannibal, MO",
    RegionType: "Msa",
    Metro: "Hannibal",
    State: "MO",
    "Metro, State": "Hannibal, MO",
    "CBSA Code": 25300,
  },
  {
    RegionID: 394888,
    SizeRank: 713,
    RegionName: "Mount Vernon, IL",
    RegionType: "Msa",
    Metro: "Mount Vernon",
    State: "IL",
    "Metro, State": "Mount Vernon, IL",
    "CBSA Code": 34500,
  },
  {
    RegionID: 394690,
    SizeRank: 714,
    RegionName: "Houghton, MI",
    RegionType: "Msa",
    Metro: "Houghton",
    State: "MI",
    "Metro, State": "Houghton, MI",
    "CBSA Code": 26340,
  },
  {
    RegionID: 395176,
    SizeRank: 715,
    RegionName: "Union City, TN",
    RegionType: "Msa",
    Metro: "Union City",
    State: "TN",
    "Metro, State": "Union City, TN",
    "CBSA Code": 46460,
  },
  {
    RegionID: 395069,
    SizeRank: 716,
    RegionName: "Sault Ste. Marie, MI",
    RegionType: "Msa",
    Metro: "Sault Ste. Marie",
    State: "MI",
    "Metro, State": "Sault Ste. Marie, MI",
    "CBSA Code": 42300,
  },
  {
    RegionID: 395192,
    SizeRank: 717,
    RegionName: "Vincennes, IN",
    RegionType: "Msa",
    Metro: "Vincennes",
    State: "IN",
    "Metro, State": "Vincennes, IN",
    "CBSA Code": 47180,
  },
  {
    RegionID: 394624,
    SizeRank: 718,
    RegionName: "Gainesville, TX",
    RegionType: "Msa",
    Metro: "Gainesville",
    State: "TX",
    "Metro, State": "Gainesville, TX",
    "CBSA Code": 23620,
  },
  {
    RegionID: 394550,
    SizeRank: 719,
    RegionName: "Dyersburg, TN",
    RegionType: "Msa",
    Metro: "Dyersburg",
    State: "TN",
    "Metro, State": "Dyersburg, TN",
    "CBSA Code": 20540,
  },
  {
    RegionID: 394745,
    SizeRank: 720,
    RegionName: "Kill Devil Hills, NC",
    RegionType: "Msa",
    Metro: "Kill Devil Hills",
    State: "NC",
    "Metro, State": "Kill Devil Hills, NC",
    "CBSA Code": 28620,
  },
  {
    RegionID: 394507,
    SizeRank: 722,
    RegionName: "Crawfordsville, IN",
    RegionType: "Msa",
    Metro: "Crawfordsville",
    State: "IN",
    "Metro, State": "Crawfordsville, IN",
    "CBSA Code": 18820,
  },
  {
    RegionID: 394422,
    SizeRank: 723,
    RegionName: "Brownwood, TX",
    RegionType: "Msa",
    Metro: "Brownwood",
    State: "TX",
    "Metro, State": "Brownwood, TX",
    "CBSA Code": 15220,
  },
  {
    RegionID: 395129,
    SizeRank: 725,
    RegionName: "Stephenville, TX",
    RegionType: "Msa",
    Metro: "Stephenville",
    State: "TX",
    "Metro, State": "Stephenville, TX",
    "CBSA Code": 44500,
  },
  {
    RegionID: 394324,
    SizeRank: 726,
    RegionName: "Americus, GA",
    RegionType: "Msa",
    Metro: "Americus",
    State: "GA",
    "Metro, State": "Americus, GA",
    "CBSA Code": 11140,
  },
  {
    RegionID: 394554,
    SizeRank: 727,
    RegionName: "Easton, MD",
    RegionType: "Msa",
    Metro: "Easton",
    State: "MD",
    "Metro, State": "Easton, MD",
    "CBSA Code": 20660,
  },
  {
    RegionID: 394920,
    SizeRank: 728,
    RegionName: "North Platte, NE",
    RegionType: "Msa",
    Metro: "North Platte",
    State: "NE",
    "Metro, State": "North Platte, NE",
    "CBSA Code": 35820,
  },
  {
    RegionID: 394914,
    SizeRank: 729,
    RegionName: "Newberry, SC",
    RegionType: "Msa",
    Metro: "Newberry",
    State: "SC",
    "Metro, State": "Newberry, SC",
    "CBSA Code": 35140,
  },
  {
    RegionID: 394300,
    SizeRank: 730,
    RegionName: "Ada, OK",
    RegionType: "Msa",
    Metro: "Ada",
    State: "OK",
    "Metro, State": "Ada, OK",
    "CBSA Code": 10220,
  },
  {
    RegionID: 394881,
    SizeRank: 731,
    RegionName: "Moscow, ID",
    RegionType: "Msa",
    Metro: "Moscow",
    State: "ID",
    "Metro, State": "Moscow, ID",
    "CBSA Code": 34140,
  },
  {
    RegionID: 395046,
    SizeRank: 732,
    RegionName: "Safford, AZ",
    RegionType: "Msa",
    Metro: "Safford",
    State: "AZ",
    "Metro, State": "Safford, AZ",
    "CBSA Code": 40940,
  },
  {
    RegionID: 394894,
    SizeRank: 733,
    RegionName: "Murray, KY",
    RegionType: "Msa",
    Metro: "Murray",
    State: "KY",
    "Metro, State": "Murray, KY",
    "CBSA Code": 34660,
  },
  {
    RegionID: 394382,
    SizeRank: 734,
    RegionName: "Bennington, VT",
    RegionType: "Msa",
    Metro: "Bennington",
    State: "VT",
    "Metro, State": "Bennington, VT",
    "CBSA Code": 13540,
  },
  {
    RegionID: 394696,
    SizeRank: 735,
    RegionName: "Huntington, IN",
    RegionType: "Msa",
    Metro: "Huntington",
    State: "IN",
    "Metro, State": "Huntington, IN",
    "CBSA Code": 26540,
  },
  {
    RegionID: 394840,
    SizeRank: 736,
    RegionName: "Mayfield, KY",
    RegionType: "Msa",
    Metro: "Mayfield",
    State: "KY",
    "Metro, State": "Mayfield, KY",
    "CBSA Code": 32460,
  },
  {
    RegionID: 394438,
    SizeRank: 737,
    RegionName: "Canton, IL",
    RegionType: "Msa",
    Metro: "Canton",
    State: "IL",
    "Metro, State": "Canton, IL",
    "CBSA Code": "-",
  },
  {
    RegionID: 394573,
    SizeRank: 737,
    RegionName: "Escanaba, MI",
    RegionType: "Msa",
    Metro: "Escanaba",
    State: "MI",
    "Metro, State": "Escanaba, MI",
    "CBSA Code": 21540,
  },
  {
    RegionID: 394499,
    SizeRank: 739,
    RegionName: "Corinth, MS",
    RegionType: "Msa",
    Metro: "Corinth",
    State: "MS",
    "Metro, State": "Corinth, MS",
    "CBSA Code": 18420,
  },
  {
    RegionID: 394341,
    SizeRank: 740,
    RegionName: "Astoria, OR",
    RegionType: "Msa",
    Metro: "Astoria",
    State: "OR",
    "Metro, State": "Astoria, OR",
    "CBSA Code": 11820,
  },
  {
    RegionID: 394973,
    SizeRank: 741,
    RegionName: "Peru, IN",
    RegionType: "Msa",
    Metro: "Peru",
    State: "IN",
    "Metro, State": "Peru, IN",
    "CBSA Code": 37940,
  },
  {
    RegionID: 394506,
    SizeRank: 742,
    RegionName: "Coshocton, OH",
    RegionType: "Msa",
    Metro: "Coshocton",
    State: "OH",
    "Metro, State": "Coshocton, OH",
    "CBSA Code": 18740,
  },
  {
    RegionID: 394916,
    SizeRank: 743,
    RegionName: "Newton, IA",
    RegionType: "Msa",
    Metro: "Newton",
    State: "IA",
    "Metro, State": "Newton, IA",
    "CBSA Code": "-",
  },
  {
    RegionID: 753898,
    SizeRank: 744,
    RegionName: "Logan, WV",
    RegionType: "Msa",
    Metro: "Logan",
    State: "WV",
    "Metro, State": "Logan, WV",
    "CBSA Code": "-",
  },
  {
    RegionID: 394370,
    SizeRank: 745,
    RegionName: "Bay City, TX",
    RegionType: "Msa",
    Metro: "Bay City",
    State: "TX",
    "Metro, State": "Bay City, TX",
    "CBSA Code": 13060,
  },
  {
    RegionID: 394617,
    SizeRank: 746,
    RegionName: "Fremont, NE",
    RegionType: "Msa",
    Metro: "Fremont",
    State: "NE",
    "Metro, State": "Fremont, NE",
    "CBSA Code": 23340,
  },
  {
    RegionID: 394702,
    SizeRank: 747,
    RegionName: "Hutchinson, MN",
    RegionType: "Msa",
    Metro: "Hutchinson",
    State: "MN",
    "Metro, State": "Hutchinson, MN",
    "CBSA Code": 26780,
  },
  {
    RegionID: 394366,
    SizeRank: 748,
    RegionName: "Batesville, AR",
    RegionType: "Msa",
    Metro: "Batesville",
    State: "AR",
    "Metro, State": "Batesville, AR",
    "CBSA Code": 12900,
  },
  {
    RegionID: 394948,
    SizeRank: 749,
    RegionName: "Owatonna, MN",
    RegionType: "Msa",
    Metro: "Owatonna",
    State: "MN",
    "Metro, State": "Owatonna, MN",
    "CBSA Code": 36940,
  },
  {
    RegionID: 395191,
    SizeRank: 750,
    RegionName: "Vidalia, GA",
    RegionType: "Msa",
    Metro: "Vidalia",
    State: "GA",
    "Metro, State": "Vidalia, GA",
    "CBSA Code": 47080,
  },
  {
    RegionID: 753922,
    SizeRank: 751,
    RegionName: "Summit Park, UT",
    RegionType: "Msa",
    Metro: "Summit Park",
    State: "UT",
    "Metro, State": "Summit Park, UT",
    "CBSA Code": "-",
  },
  {
    RegionID: 753864,
    SizeRank: 752,
    RegionName: "Arkansas City-Winfield, KS",
    RegionType: "Msa",
    Metro: "Arkansas City",
    State: "KS",
    "Metro, State": "Arkansas City, KS",
    "CBSA Code": "-",
  },
  {
    RegionID: 394771,
    SizeRank: 753,
    RegionName: "Laramie, WY",
    RegionType: "Msa",
    Metro: "Laramie",
    State: "WY",
    "Metro, State": "Laramie, WY",
    "CBSA Code": 29660,
  },
  {
    RegionID: 394984,
    SizeRank: 754,
    RegionName: "Plainview, TX",
    RegionType: "Msa",
    Metro: "Plainview",
    State: "TX",
    "Metro, State": "Plainview, TX",
    "CBSA Code": 38380,
  },
  {
    RegionID: 394385,
    SizeRank: 755,
    RegionName: "Big Spring, TX",
    RegionType: "Msa",
    Metro: "Big Spring",
    State: "TX",
    "Metro, State": "Big Spring, TX",
    "CBSA Code": 13700,
  },
  {
    RegionID: 394777,
    SizeRank: 756,
    RegionName: "Laurinburg, NC",
    RegionType: "Msa",
    Metro: "Laurinburg",
    State: "NC",
    "Metro, State": "Laurinburg, NC",
    "CBSA Code": 29900,
  },
  {
    RegionID: 394535,
    SizeRank: 757,
    RegionName: "Dixon, IL",
    RegionType: "Msa",
    Metro: "Dixon",
    State: "IL",
    "Metro, State": "Dixon, IL",
    "CBSA Code": 19940,
  },
  {
    RegionID: 394315,
    SizeRank: 758,
    RegionName: "Alexandria, MN",
    RegionType: "Msa",
    Metro: "Alexandria",
    State: "MN",
    "Metro, State": "Alexandria, MN",
    "CBSA Code": 10820,
  },
  {
    RegionID: 394915,
    SizeRank: 759,
    RegionName: "Newport, TN",
    RegionType: "Msa",
    Metro: "Newport",
    State: "TN",
    "Metro, State": "Newport, TN",
    "CBSA Code": 35460,
  },
  {
    RegionID: 394522,
    SizeRank: 760,
    RegionName: "DeRidder, LA",
    RegionType: "Msa",
    Metro: "DeRidder",
    State: "LA",
    "Metro, State": "DeRidder, LA",
    "CBSA Code": 19760,
  },
  {
    RegionID: 394781,
    SizeRank: 761,
    RegionName: "Lebanon, MO",
    RegionType: "Msa",
    Metro: "Lebanon",
    State: "MO",
    "Metro, State": "Lebanon, MO",
    "CBSA Code": 30060,
  },
  {
    RegionID: 394481,
    SizeRank: 762,
    RegionName: "Coffeyville, KS",
    RegionType: "Msa",
    Metro: "Coffeyville",
    State: "KS",
    "Metro, State": "Coffeyville, KS",
    "CBSA Code": 17700,
  },
  {
    RegionID: 395084,
    SizeRank: 763,
    RegionName: "Seneca Falls, NY",
    RegionType: "Msa",
    Metro: "Seneca Falls",
    State: "NY",
    "Metro, State": "Seneca Falls, NY",
    "CBSA Code": 42900,
  },
  {
    RegionID: 395137,
    SizeRank: 764,
    RegionName: "Sulphur Springs, TX",
    RegionType: "Msa",
    Metro: "Sulphur Springs",
    State: "TX",
    "Metro, State": "Sulphur Springs, TX",
    "CBSA Code": 44860,
  },
  {
    RegionID: 394835,
    SizeRank: 765,
    RegionName: "Martin, TN",
    RegionType: "Msa",
    Metro: "Martin",
    State: "TN",
    "Metro, State": "Martin, TN",
    "CBSA Code": 32280,
  },
  {
    RegionID: 395141,
    SizeRank: 766,
    RegionName: "Susanville, CA",
    RegionType: "Msa",
    Metro: "Susanville",
    State: "CA",
    "Metro, State": "Susanville, CA",
    "CBSA Code": 45000,
  },
  {
    RegionID: 394417,
    SizeRank: 767,
    RegionName: "Brookhaven, MS",
    RegionType: "Msa",
    Metro: "Brookhaven",
    State: "MS",
    "Metro, State": "Brookhaven, MS",
    "CBSA Code": 15020,
  },
  {
    RegionID: 394335,
    SizeRank: 768,
    RegionName: "Arcadia, FL",
    RegionType: "Msa",
    Metro: "Arcadia",
    State: "FL",
    "Metro, State": "Arcadia, FL",
    "CBSA Code": 11580,
  },
  {
    RegionID: 395150,
    SizeRank: 769,
    RegionName: "Taylorville, IL",
    RegionType: "Msa",
    Metro: "Taylorville",
    State: "IL",
    "Metro, State": "Taylorville, IL",
    "CBSA Code": 45380,
  },
  {
    RegionID: 394525,
    SizeRank: 770,
    RegionName: "Decatur, IN",
    RegionType: "Msa",
    Metro: "Decatur",
    State: "IN",
    "Metro, State": "Decatur, IN",
    "CBSA Code": 19540,
  },
  {
    RegionID: 753894,
    SizeRank: 771,
    RegionName: "Junction City, KS",
    RegionType: "Msa",
    Metro: "Junction City",
    State: "KS",
    "Metro, State": "Junction City, KS",
    "CBSA Code": "-",
  },
  {
    RegionID: 394557,
    SizeRank: 772,
    RegionName: "Effingham, IL",
    RegionType: "Msa",
    Metro: "Effingham",
    State: "IL",
    "Metro, State": "Effingham, IL",
    "CBSA Code": 20820,
  },
  {
    RegionID: 395184,
    SizeRank: 773,
    RegionName: "Valley, AL",
    RegionType: "Msa",
    Metro: "Valley",
    State: "AL",
    "Metro, State": "Valley, AL",
    "CBSA Code": "-",
  },
  {
    RegionID: 394430,
    SizeRank: 774,
    RegionName: "Butte, MT",
    RegionType: "Msa",
    Metro: "Butte",
    State: "MT",
    "Metro, State": "Butte, MT",
    "CBSA Code": 15580,
  },
  {
    RegionID: 394331,
    SizeRank: 775,
    RegionName: "Angola, IN",
    RegionType: "Msa",
    Metro: "Angola",
    State: "IN",
    "Metro, State": "Angola, IN",
    "CBSA Code": 11420,
  },
  {
    RegionID: 394536,
    SizeRank: 778,
    RegionName: "Dodge City, KS",
    RegionType: "Msa",
    Metro: "Dodge City",
    State: "KS",
    "Metro, State": "Dodge City, KS",
    "CBSA Code": 19980,
  },
  {
    RegionID: 394413,
    SizeRank: 779,
    RegionName: "Brenham, TX",
    RegionType: "Msa",
    Metro: "Brenham",
    State: "TX",
    "Metro, State": "Brenham, TX",
    "CBSA Code": 14780,
  },
  {
    RegionID: 394569,
    SizeRank: 780,
    RegionName: "Emporia, KS",
    RegionType: "Msa",
    Metro: "Emporia",
    State: "KS",
    "Metro, State": "Emporia, KS",
    "CBSA Code": 21380,
  },
  {
    RegionID: 753892,
    SizeRank: 782,
    RegionName: "Jackson, OH",
    RegionType: "Msa",
    Metro: "Jackson",
    State: "OH",
    "Metro, State": "Jackson, OH",
    "CBSA Code": 27160,
  },
  {
    RegionID: 394613,
    SizeRank: 783,
    RegionName: "Frankfort, IN",
    RegionType: "Msa",
    Metro: "Frankfort",
    State: "IN",
    "Metro, State": "Frankfort, IN",
    "CBSA Code": 23140,
  },
  {
    RegionID: 394414,
    SizeRank: 784,
    RegionName: "Brevard, NC",
    RegionType: "Msa",
    Metro: "Brevard",
    State: "NC",
    "Metro, State": "Brevard, NC",
    "CBSA Code": 14820,
  },
  {
    RegionID: 395149,
    SizeRank: 785,
    RegionName: "Taos, NM",
    RegionType: "Msa",
    Metro: "Taos",
    State: "NM",
    "Metro, State": "Taos, NM",
    "CBSA Code": 45340,
  },
  {
    RegionID: 753902,
    SizeRank: 786,
    RegionName: "Malvern, AR",
    RegionType: "Msa",
    Metro: "Malvern",
    State: "AR",
    "Metro, State": "Malvern, AR",
    "CBSA Code": 31680,
  },
  {
    RegionID: 395165,
    SizeRank: 787,
    RegionName: "Troy, AL",
    RegionType: "Msa",
    Metro: "Troy",
    State: "AL",
    "Metro, State": "Troy, AL",
    "CBSA Code": 45980,
  },
  {
    RegionID: 395196,
    SizeRank: 788,
    RegionName: "Wabash, IN",
    RegionType: "Msa",
    Metro: "Wabash",
    State: "IN",
    "Metro, State": "Wabash, IN",
    "CBSA Code": 47340,
  },
  {
    RegionID: 394433,
    SizeRank: 789,
    RegionName: "Cambridge, MD",
    RegionType: "Msa",
    Metro: "Cambridge",
    State: "MD",
    "Metro, State": "Cambridge, MD",
    "CBSA Code": 15700,
  },
  {
    RegionID: 394812,
    SizeRank: 790,
    RegionName: "Macomb, IL",
    RegionType: "Msa",
    Metro: "Macomb",
    State: "IL",
    "Metro, State": "Macomb, IL",
    "CBSA Code": 31380,
  },
  {
    RegionID: 395187,
    SizeRank: 791,
    RegionName: "Vernal, UT",
    RegionType: "Msa",
    Metro: "Vernal",
    State: "UT",
    "Metro, State": "Vernal, UT",
    "CBSA Code": 46860,
  },
  {
    RegionID: 394749,
    SizeRank: 792,
    RegionName: "Kingsville, TX",
    RegionType: "Msa",
    Metro: "Kingsville",
    State: "TX",
    "Metro, State": "Kingsville, TX",
    "CBSA Code": 28780,
  },
  {
    RegionID: 394815,
    SizeRank: 793,
    RegionName: "Madison, IN",
    RegionType: "Msa",
    Metro: "Madison",
    State: "IN",
    "Metro, State": "Madison, IN",
    "CBSA Code": 31500,
  },
  {
    RegionID: 394886,
    SizeRank: 794,
    RegionName: "Mount Pleasant, TX",
    RegionType: "Msa",
    Metro: "Mount Pleasant",
    State: "TX",
    "Metro, State": "Mount Pleasant, TX",
    "CBSA Code": 34420,
  },
  {
    RegionID: 394962,
    SizeRank: 795,
    RegionName: "Paris, TN",
    RegionType: "Msa",
    Metro: "Paris",
    State: "TN",
    "Metro, State": "Paris, TN",
    "CBSA Code": 37540,
  },
  {
    RegionID: 394491,
    SizeRank: 796,
    RegionName: "Columbus, NE",
    RegionType: "Msa",
    Metro: "Columbus",
    State: "NE",
    "Metro, State": "Columbus, NE",
    "CBSA Code": 18100,
  },
  {
    RegionID: 394419,
    SizeRank: 797,
    RegionName: "Brookings, SD",
    RegionType: "Msa",
    Metro: "Brookings",
    State: "SD",
    "Metro, State": "Brookings, SD",
    "CBSA Code": 15100,
  },
  {
    RegionID: 394740,
    SizeRank: 798,
    RegionName: "Kennett, MO",
    RegionType: "Msa",
    Metro: "Kennett",
    State: "MO",
    "Metro, State": "Kennett, MO",
    "CBSA Code": 28380,
  },
  {
    RegionID: 394376,
    SizeRank: 800,
    RegionName: "Beeville, TX",
    RegionType: "Msa",
    Metro: "Beeville",
    State: "TX",
    "Metro, State": "Beeville, TX",
    "CBSA Code": 13300,
  },
  {
    RegionID: 394855,
    SizeRank: 801,
    RegionName: "Miami, OK",
    RegionType: "Msa",
    Metro: "Miami",
    State: "OK",
    "Metro, State": "Miami, OK",
    "CBSA Code": 33060,
  },
  {
    RegionID: 753878,
    SizeRank: 802,
    RegionName: "Dayton, TN",
    RegionType: "Msa",
    Metro: "Dayton",
    State: "TN",
    "Metro, State": "Dayton, TN",
    "CBSA Code": 19420,
  },
  {
    RegionID: 395207,
    SizeRank: 803,
    RegionName: "Washington, IN",
    RegionType: "Msa",
    Metro: "Washington",
    State: "IN",
    "Metro, State": "Washington, IN",
    "CBSA Code": 47780,
  },
  {
    RegionID: 394435,
    SizeRank: 807,
    RegionName: "Camden, AR",
    RegionType: "Msa",
    Metro: "Camden",
    State: "AR",
    "Metro, State": "Camden, AR",
    "CBSA Code": 15780,
  },
  {
    RegionID: 394713,
    SizeRank: 808,
    RegionName: "Jackson, WY",
    RegionType: "Msa",
    Metro: "Jackson",
    State: "WY",
    "Metro, State": "Jackson, WY",
    "CBSA Code": 27220,
  },
  {
    RegionID: 394670,
    SizeRank: 809,
    RegionName: "Hastings, NE",
    RegionType: "Msa",
    Metro: "Hastings",
    State: "NE",
    "Metro, State": "Hastings, NE",
    "CBSA Code": 25580,
  },
  {
    RegionID: 394730,
    SizeRank: 810,
    RegionName: "Juneau, AK",
    RegionType: "Msa",
    Metro: "Juneau",
    State: "AK",
    "Metro, State": "Juneau, AK",
    "CBSA Code": 27940,
  },
  {
    RegionID: 394310,
    SizeRank: 811,
    RegionName: "Albert Lea, MN",
    RegionType: "Msa",
    Metro: "Albert Lea",
    State: "MN",
    "Metro, State": "Albert Lea, MN",
    "CBSA Code": 10660,
  },
  {
    RegionID: 394786,
    SizeRank: 812,
    RegionName: "Lewisburg, TN",
    RegionType: "Msa",
    Metro: "Lewisburg",
    State: "TN",
    "Metro, State": "Lewisburg, TN",
    "CBSA Code": 30280,
  },
  {
    RegionID: 394708,
    SizeRank: 813,
    RegionName: "Iron Mountain, MI",
    RegionType: "Msa",
    Metro: "Iron Mountain",
    State: "MI",
    "Metro, State": "Iron Mountain, MI",
    "CBSA Code": 27020,
  },
  {
    RegionID: 394795,
    SizeRank: 814,
    RegionName: "Lincoln, IL",
    RegionType: "Msa",
    Metro: "Lincoln",
    State: "IL",
    "Metro, State": "Lincoln, IL",
    "CBSA Code": 30660,
  },
  {
    RegionID: 394725,
    SizeRank: 815,
    RegionName: "Jesup, GA",
    RegionType: "Msa",
    Metro: "Jesup",
    State: "GA",
    "Metro, State": "Jesup, GA",
    "CBSA Code": 27700,
  },
  {
    RegionID: 394751,
    SizeRank: 816,
    RegionName: "Kirksville, MO",
    RegionType: "Msa",
    Metro: "Kirksville",
    State: "MO",
    "Metro, State": "Kirksville, MO",
    "CBSA Code": 28860,
  },
  {
    RegionID: 394320,
    SizeRank: 818,
    RegionName: "Alpena, MI",
    RegionType: "Msa",
    Metro: "Alpena",
    State: "MI",
    "Metro, State": "Alpena, MI",
    "CBSA Code": 10980,
  },
  {
    RegionID: 395100,
    SizeRank: 819,
    RegionName: "Silver City, NM",
    RegionType: "Msa",
    Metro: "Silver City",
    State: "NM",
    "Metro, State": "Silver City, NM",
    "CBSA Code": 43500,
  },
  {
    RegionID: 394706,
    SizeRank: 820,
    RegionName: "Indianola, MS",
    RegionType: "Msa",
    Metro: "Indianola",
    State: "MS",
    "Metro, State": "Indianola, MS",
    "CBSA Code": 26940,
  },
  {
    RegionID: 753879,
    SizeRank: 821,
    RegionName: "Elkins, WV",
    RegionType: "Msa",
    Metro: "Elkins",
    State: "WV",
    "Metro, State": "Elkins, WV",
    "CBSA Code": 21180,
  },
  {
    RegionID: 394774,
    SizeRank: 822,
    RegionName: "Las Vegas, NM",
    RegionType: "Msa",
    Metro: "Las Vegas",
    State: "NM",
    "Metro, State": "Las Vegas, NM",
    "CBSA Code": 29780,
  },
  {
    RegionID: 394846,
    SizeRank: 823,
    RegionName: "McPherson, KS",
    RegionType: "Msa",
    Metro: "McPherson",
    State: "KS",
    "Metro, State": "McPherson, KS",
    "CBSA Code": 32700,
  },
  {
    RegionID: 395093,
    SizeRank: 824,
    RegionName: "Sheridan, WY",
    RegionType: "Msa",
    Metro: "Sheridan",
    State: "WY",
    "Metro, State": "Sheridan, WY",
    "CBSA Code": 43260,
  },
  {
    RegionID: 395206,
    SizeRank: 825,
    RegionName: "Washington Court House, OH",
    RegionType: "Msa",
    Metro: "Washington Court House",
    State: "OH",
    "Metro, State": "Washington Court House, OH",
    "CBSA Code": 47920,
  },
  {
    RegionID: 394381,
    SizeRank: 826,
    RegionName: "Bennettsville, SC",
    RegionType: "Msa",
    Metro: "Bennettsville",
    State: "SC",
    "Metro, State": "Bennettsville, SC",
    "CBSA Code": 13500,
  },
  {
    RegionID: 395185,
    SizeRank: 827,
    RegionName: "Van Wert, OH",
    RegionType: "Msa",
    Metro: "Van Wert",
    State: "OH",
    "Metro, State": "Van Wert, OH",
    "CBSA Code": 46780,
  },
  {
    RegionID: 394853,
    SizeRank: 828,
    RegionName: "Merrill, WI",
    RegionType: "Msa",
    Metro: "Merrill",
    State: "WI",
    "Metro, State": "Merrill, WI",
    "CBSA Code": "-",
  },
  {
    RegionID: 753901,
    SizeRank: 829,
    RegionName: "Ludington, MI",
    RegionType: "Msa",
    Metro: "Ludington",
    State: "MI",
    "Metro, State": "Ludington, MI",
    "CBSA Code": 31220,
  },
  {
    RegionID: 394858,
    SizeRank: 830,
    RegionName: "Middlesborough, KY",
    RegionType: "Msa",
    Metro: "Middlesborough",
    State: "KY",
    "Metro, State": "Middlesborough, KY",
    "CBSA Code": 33180,
  },
  {
    RegionID: 394508,
    SizeRank: 831,
    RegionName: "Crescent City, CA",
    RegionType: "Msa",
    Metro: "Crescent City",
    State: "CA",
    "Metro, State": "Crescent City, CA",
    "CBSA Code": 18860,
  },
  {
    RegionID: 394921,
    SizeRank: 832,
    RegionName: "North Vernon, IN",
    RegionType: "Msa",
    Metro: "North Vernon",
    State: "IN",
    "Metro, State": "North Vernon, IN",
    "CBSA Code": 35860,
  },
  {
    RegionID: 394673,
    SizeRank: 833,
    RegionName: "Hays, KS",
    RegionType: "Msa",
    Metro: "Hays",
    State: "KS",
    "Metro, State": "Hays, KS",
    "CBSA Code": 25700,
  },
  {
    RegionID: 394601,
    SizeRank: 834,
    RegionName: "Forrest City, AR",
    RegionType: "Msa",
    Metro: "Forrest City",
    State: "AR",
    "Metro, State": "Forrest City, AR",
    "CBSA Code": 22620,
  },
  {
    RegionID: 394606,
    SizeRank: 835,
    RegionName: "Fort Morgan, CO",
    RegionType: "Msa",
    Metro: "Fort Morgan",
    State: "CO",
    "Metro, State": "Fort Morgan, CO",
    "CBSA Code": 22820,
  },
  {
    RegionID: 394864,
    SizeRank: 836,
    RegionName: "Mineral Wells, TX",
    RegionType: "Msa",
    Metro: "Mineral Wells",
    State: "TX",
    "Metro, State": "Mineral Wells, TX",
    "CBSA Code": 33420,
  },
  {
    RegionID: 753871,
    SizeRank: 837,
    RegionName: "Breckenridge, CO",
    RegionType: "Msa",
    Metro: "Breckenridge",
    State: "CO",
    "Metro, State": "Breckenridge, CO",
    "CBSA Code": 14720,
  },
  {
    RegionID: 394364,
    SizeRank: 838,
    RegionName: "Bastrop, LA",
    RegionType: "Msa",
    Metro: "Bastrop",
    State: "LA",
    "Metro, State": "Bastrop, LA",
    "CBSA Code": "-",
  },
  {
    RegionID: 394356,
    SizeRank: 839,
    RegionName: "Bainbridge, GA",
    RegionType: "Msa",
    Metro: "Bainbridge",
    State: "GA",
    "Metro, State": "Bainbridge, GA",
    "CBSA Code": 12460,
  },
  {
    RegionID: 395214,
    SizeRank: 840,
    RegionName: "Wauchula, FL",
    RegionType: "Msa",
    Metro: "Wauchula",
    State: "FL",
    "Metro, State": "Wauchula, FL",
    "CBSA Code": 48100,
  },
  {
    RegionID: 753884,
    SizeRank: 841,
    RegionName: "Hailey, ID",
    RegionType: "Msa",
    Metro: "Hailey",
    State: "ID",
    "Metro, State": "Hailey, ID",
    "CBSA Code": 25200,
  },
  {
    RegionID: 394643,
    SizeRank: 842,
    RegionName: "Great Bend, KS",
    RegionType: "Msa",
    Metro: "Great Bend",
    State: "KS",
    "Metro, State": "Great Bend, KS",
    "CBSA Code": 24460,
  },
  {
    RegionID: 753926,
    SizeRank: 843,
    RegionName: "Weatherford, OK",
    RegionType: "Msa",
    Metro: "Weatherford",
    State: "OK",
    "Metro, State": "Weatherford, OK",
    "CBSA Code": 48220,
  },
  {
    RegionID: 395211,
    SizeRank: 844,
    RegionName: "Watertown, SD",
    RegionType: "Msa",
    Metro: "Watertown",
    State: "SD",
    "Metro, State": "Watertown, SD",
    "CBSA Code": 47980,
  },
  {
    RegionID: 394642,
    SizeRank: 845,
    RegionName: "Grants, NM",
    RegionType: "Msa",
    Metro: "Grants",
    State: "NM",
    "Metro, State": "Grants, NM",
    "CBSA Code": 24380,
  },
  {
    RegionID: 395154,
    SizeRank: 846,
    RegionName: "Thomaston, GA",
    RegionType: "Msa",
    Metro: "Thomaston",
    State: "GA",
    "Metro, State": "Thomaston, GA",
    "CBSA Code": 45580,
  },
  {
    RegionID: 394892,
    SizeRank: 847,
    RegionName: "Mountain Home, ID",
    RegionType: "Msa",
    Metro: "Mountain Home",
    State: "ID",
    "Metro, State": "Mountain Home, ID",
    "CBSA Code": 34300,
  },
  {
    RegionID: 394322,
    SizeRank: 848,
    RegionName: "Altus, OK",
    RegionType: "Msa",
    Metro: "Altus",
    State: "OK",
    "Metro, State": "Altus, OK",
    "CBSA Code": 11060,
  },
  {
    RegionID: 395181,
    SizeRank: 849,
    RegionName: "Uvalde, TX",
    RegionType: "Msa",
    Metro: "Uvalde",
    State: "TX",
    "Metro, State": "Uvalde, TX",
    "CBSA Code": 46620,
  },
  {
    RegionID: 394791,
    SizeRank: 850,
    RegionName: "Lexington, NE",
    RegionType: "Msa",
    Metro: "Lexington",
    State: "NE",
    "Metro, State": "Lexington, NE",
    "CBSA Code": 30420,
  },
  {
    RegionID: 394401,
    SizeRank: 851,
    RegionName: "Boone, IA",
    RegionType: "Msa",
    Metro: "Boone",
    State: "IA",
    "Metro, State": "Boone, IA",
    "CBSA Code": "-",
  },
  {
    RegionID: 395159,
    SizeRank: 852,
    RegionName: "Toccoa, GA",
    RegionType: "Msa",
    Metro: "Toccoa",
    State: "GA",
    "Metro, State": "Toccoa, GA",
    "CBSA Code": 45740,
  },
  {
    RegionID: 394470,
    SizeRank: 853,
    RegionName: "Clarksdale, MS",
    RegionType: "Msa",
    Metro: "Clarksdale",
    State: "MS",
    "Metro, State": "Clarksdale, MS",
    "CBSA Code": 17260,
  },
  {
    RegionID: 395138,
    SizeRank: 854,
    RegionName: "Summerville, GA",
    RegionType: "Msa",
    Metro: "Summerville",
    State: "GA",
    "Metro, State": "Summerville, GA",
    "CBSA Code": 44900,
  },
  {
    RegionID: 753909,
    SizeRank: 855,
    RegionName: "Ottawa, KS",
    RegionType: "Msa",
    Metro: "Ottawa",
    State: "KS",
    "Metro, State": "Ottawa, KS",
    "CBSA Code": 36840,
  },
  {
    RegionID: 394912,
    SizeRank: 856,
    RegionName: "New Ulm, MN",
    RegionType: "Msa",
    Metro: "New Ulm",
    State: "MN",
    "Metro, State": "New Ulm, MN",
    "CBSA Code": 35580,
  },
  {
    RegionID: 394830,
    SizeRank: 857,
    RegionName: "Marshall, MN",
    RegionType: "Msa",
    Metro: "Marshall",
    State: "MN",
    "Metro, State": "Marshall, MN",
    "CBSA Code": 32140,
  },
  {
    RegionID: 394758,
    SizeRank: 858,
    RegionName: "La Grande, OR",
    RegionType: "Msa",
    Metro: "La Grande",
    State: "OR",
    "Metro, State": "La Grande, OR",
    "CBSA Code": 29260,
  },
  {
    RegionID: 394649,
    SizeRank: 859,
    RegionName: "Greensburg, IN",
    RegionType: "Msa",
    Metro: "Greensburg",
    State: "IN",
    "Metro, State": "Greensburg, IN",
    "CBSA Code": 24700,
  },
  {
    RegionID: 394854,
    SizeRank: 860,
    RegionName: "Mexico, MO",
    RegionType: "Msa",
    Metro: "Mexico",
    State: "MO",
    "Metro, State": "Mexico, MO",
    "CBSA Code": 33020,
  },
  {
    RegionID: 394869,
    SizeRank: 861,
    RegionName: "Moberly, MO",
    RegionType: "Msa",
    Metro: "Moberly",
    State: "MO",
    "Metro, State": "Moberly, MO",
    "CBSA Code": 33620,
  },
  {
    RegionID: 753923,
    SizeRank: 862,
    RegionName: "The Dalles, OR",
    RegionType: "Msa",
    Metro: "The Dalles",
    State: "OR",
    "Metro, State": "The Dalles, OR",
    "CBSA Code": 45520,
  },
  {
    RegionID: 394529,
    SizeRank: 863,
    RegionName: "Deming, NM",
    RegionType: "Msa",
    Metro: "Deming",
    State: "NM",
    "Metro, State": "Deming, NM",
    "CBSA Code": 19700,
  },
  {
    RegionID: 394584,
    SizeRank: 864,
    RegionName: "Fallon, NV",
    RegionType: "Msa",
    Metro: "Fallon",
    State: "NV",
    "Metro, State": "Fallon, NV",
    "CBSA Code": 21980,
  },
  {
    RegionID: 394615,
    SizeRank: 865,
    RegionName: "Fredericksburg, TX",
    RegionType: "Msa",
    Metro: "Fredericksburg",
    State: "TX",
    "Metro, State": "Fredericksburg, TX",
    "CBSA Code": 23240,
  },
  {
    RegionID: 394818,
    SizeRank: 866,
    RegionName: "Magnolia, AR",
    RegionType: "Msa",
    Metro: "Magnolia",
    State: "AR",
    "Metro, State": "Magnolia, AR",
    "CBSA Code": 31620,
  },
  {
    RegionID: 394436,
    SizeRank: 867,
    RegionName: "Campbellsville, KY",
    RegionType: "Msa",
    Metro: "Campbellsville",
    State: "KY",
    "Metro, State": "Campbellsville, KY",
    "CBSA Code": 15820,
  },
  {
    RegionID: 394494,
    SizeRank: 868,
    RegionName: "Connersville, IN",
    RegionType: "Msa",
    Metro: "Connersville",
    State: "IN",
    "Metro, State": "Connersville, IN",
    "CBSA Code": 18220,
  },
  {
    RegionID: 394533,
    SizeRank: 869,
    RegionName: "Dickinson, ND",
    RegionType: "Msa",
    Metro: "Dickinson",
    State: "ND",
    "Metro, State": "Dickinson, ND",
    "CBSA Code": 19860,
  },
  {
    RegionID: 395110,
    SizeRank: 870,
    RegionName: "Spearfish, SD",
    RegionType: "Msa",
    Metro: "Spearfish",
    State: "SD",
    "Metro, State": "Spearfish, SD",
    "CBSA Code": 43940,
  },
  {
    RegionID: 394674,
    SizeRank: 871,
    RegionName: "Heber, UT",
    RegionType: "Msa",
    Metro: "Heber",
    State: "UT",
    "Metro, State": "Heber, UT",
    "CBSA Code": 25720,
  },
  {
    RegionID: 753921,
    SizeRank: 872,
    RegionName: "Steamboat Springs, CO",
    RegionType: "Msa",
    Metro: "Steamboat Springs",
    State: "CO",
    "Metro, State": "Steamboat Springs, CO",
    "CBSA Code": 44460,
  },
  {
    RegionID: 394498,
    SizeRank: 873,
    RegionName: "Cordele, GA",
    RegionType: "Msa",
    Metro: "Cordele",
    State: "GA",
    "Metro, State": "Cordele, GA",
    "CBSA Code": 18380,
  },
  {
    RegionID: 394831,
    SizeRank: 874,
    RegionName: "Marshall, MO",
    RegionType: "Msa",
    Metro: "Marshall",
    State: "MO",
    "Metro, State": "Marshall, MO",
    "CBSA Code": 32180,
  },
  {
    RegionID: 394837,
    SizeRank: 874,
    RegionName: "Maryville, MO",
    RegionType: "Msa",
    Metro: "Maryville",
    State: "MO",
    "Metro, State": "Maryville, MO",
    "CBSA Code": 32340,
  },
  {
    RegionID: 394337,
    SizeRank: 876,
    RegionName: "Arkadelphia, AR",
    RegionType: "Msa",
    Metro: "Arkadelphia",
    State: "AR",
    "Metro, State": "Arkadelphia, AR",
    "CBSA Code": 11660,
  },
  {
    RegionID: 394793,
    SizeRank: 877,
    RegionName: "Liberal, KS",
    RegionType: "Msa",
    Metro: "Liberal",
    State: "KS",
    "Metro, State": "Liberal, KS",
    "CBSA Code": 30580,
  },
  {
    RegionID: 394784,
    SizeRank: 878,
    RegionName: "Levelland, TX",
    RegionType: "Msa",
    Metro: "Levelland",
    State: "TX",
    "Metro, State": "Levelland, TX",
    "CBSA Code": 30220,
  },
  {
    RegionID: 395198,
    SizeRank: 879,
    RegionName: "Wahpeton, ND",
    RegionType: "Msa",
    Metro: "Wahpeton",
    State: "ND",
    "Metro, State": "Wahpeton, ND",
    "CBSA Code": 47420,
  },
  {
    RegionID: 394868,
    SizeRank: 880,
    RegionName: "Mitchell, SD",
    RegionType: "Msa",
    Metro: "Mitchell",
    State: "SD",
    "Metro, State": "Mitchell, SD",
    "CBSA Code": 33580,
  },
  {
    RegionID: 395130,
    SizeRank: 881,
    RegionName: "Sterling, CO",
    RegionType: "Msa",
    Metro: "Sterling",
    State: "CO",
    "Metro, State": "Sterling, CO",
    "CBSA Code": 44540,
  },
  {
    RegionID: 394959,
    SizeRank: 882,
    RegionName: "Pampa, TX",
    RegionType: "Msa",
    Metro: "Pampa",
    State: "TX",
    "Metro, State": "Pampa, TX",
    "CBSA Code": 37420,
  },
  {
    RegionID: 395241,
    SizeRank: 883,
    RegionName: "Yankton, SD",
    RegionType: "Msa",
    Metro: "Yankton",
    State: "SD",
    "Metro, State": "Yankton, SD",
    "CBSA Code": 49460,
  },
  {
    RegionID: 395227,
    SizeRank: 884,
    RegionName: "Williston, ND",
    RegionType: "Msa",
    Metro: "Williston",
    State: "ND",
    "Metro, State": "Williston, ND",
    "CBSA Code": 48780,
  },
  {
    RegionID: 394945,
    SizeRank: 885,
    RegionName: "Oskaloosa, IA",
    RegionType: "Msa",
    Metro: "Oskaloosa",
    State: "IA",
    "Metro, State": "Oskaloosa, IA",
    "CBSA Code": 36820,
  },
  {
    RegionID: 394418,
    SizeRank: 886,
    RegionName: "Brookings, OR",
    RegionType: "Msa",
    Metro: "Brookings",
    State: "OR",
    "Metro, State": "Brookings, OR",
    "CBSA Code": 15060,
  },
  {
    RegionID: 394687,
    SizeRank: 887,
    RegionName: "Hood River, OR",
    RegionType: "Msa",
    Metro: "Hood River",
    State: "OR",
    "Metro, State": "Hood River, OR",
    "CBSA Code": 26220,
  },
  {
    RegionID: 394371,
    SizeRank: 888,
    RegionName: "Beatrice, NE",
    RegionType: "Msa",
    Metro: "Beatrice",
    State: "NE",
    "Metro, State": "Beatrice, NE",
    "CBSA Code": 13100,
  },
  {
    RegionID: 394403,
    SizeRank: 889,
    RegionName: "Borger, TX",
    RegionType: "Msa",
    Metro: "Borger",
    State: "TX",
    "Metro, State": "Borger, TX",
    "CBSA Code": 14420,
  },
  {
    RegionID: 395014,
    SizeRank: 890,
    RegionName: "Raymondville, TX",
    RegionType: "Msa",
    Metro: "Raymondville",
    State: "TX",
    "Metro, State": "Raymondville, TX",
    "CBSA Code": 39700,
  },
  {
    RegionID: 394564,
    SizeRank: 891,
    RegionName: "Elk City, OK",
    RegionType: "Msa",
    Metro: "Elk City",
    State: "OK",
    "Metro, State": "Elk City, OK",
    "CBSA Code": 21120,
  },
  {
    RegionID: 394656,
    SizeRank: 892,
    RegionName: "Grenada, MS",
    RegionType: "Msa",
    Metro: "Grenada",
    State: "MS",
    "Metro, State": "Grenada, MS",
    "CBSA Code": 24980,
  },
  {
    RegionID: 394544,
    SizeRank: 893,
    RegionName: "Dumas, TX",
    RegionType: "Msa",
    Metro: "Dumas",
    State: "TX",
    "Metro, State": "Dumas, TX",
    "CBSA Code": 20300,
  },
  {
    RegionID: 394676,
    SizeRank: 894,
    RegionName: "Helena, AR",
    RegionType: "Msa",
    Metro: "Helena",
    State: "AR",
    "Metro, State": "Helena, AR",
    "CBSA Code": 25760,
  },
  {
    RegionID: 394965,
    SizeRank: 895,
    RegionName: "Parsons, KS",
    RegionType: "Msa",
    Metro: "Parsons",
    State: "KS",
    "Metro, State": "Parsons, KS",
    "CBSA Code": 37660,
  },
  {
    RegionID: 395003,
    SizeRank: 896,
    RegionName: "Price, UT",
    RegionType: "Msa",
    Metro: "Price",
    State: "UT",
    "Metro, State": "Price, UT",
    "CBSA Code": 39220,
  },
  {
    RegionID: 753914,
    SizeRank: 897,
    RegionName: "Port Lavaca, TX",
    RegionType: "Msa",
    Metro: "Port Lavaca",
    State: "TX",
    "Metro, State": "Port Lavaca, TX",
    "CBSA Code": 38920,
  },
  {
    RegionID: 395239,
    SizeRank: 898,
    RegionName: "Worthington, MN",
    RegionType: "Msa",
    Metro: "Worthington",
    State: "MN",
    "Metro, State": "Worthington, MN",
    "CBSA Code": 49380,
  },
  {
    RegionID: 394979,
    SizeRank: 899,
    RegionName: "Pierre, SD",
    RegionType: "Msa",
    Metro: "Pierre",
    State: "SD",
    "Metro, State": "Pierre, SD",
    "CBSA Code": 38180,
  },
  {
    RegionID: 394578,
    SizeRank: 900,
    RegionName: "Evanston, WY",
    RegionType: "Msa",
    Metro: "Evanston",
    State: "WY",
    "Metro, State": "Evanston, WY",
    "CBSA Code": 21740,
  },
  {
    RegionID: 394718,
    SizeRank: 901,
    RegionName: "Jamestown, ND",
    RegionType: "Msa",
    Metro: "Jamestown",
    State: "ND",
    "Metro, State": "Jamestown, ND",
    "CBSA Code": 27420,
  },
  {
    RegionID: 395004,
    SizeRank: 902,
    RegionName: "Prineville, OR",
    RegionType: "Msa",
    Metro: "Prineville",
    State: "OR",
    "Metro, State": "Prineville, OR",
    "CBSA Code": 39260,
  },
  {
    RegionID: 394659,
    SizeRank: 905,
    RegionName: "Guymon, OK",
    RegionType: "Msa",
    Metro: "Guymon",
    State: "OK",
    "Metro, State": "Guymon, OK",
    "CBSA Code": 25100,
  },
  {
    RegionID: 395135,
    SizeRank: 908,
    RegionName: "Storm Lake, IA",
    RegionType: "Msa",
    Metro: "Storm Lake",
    State: "IA",
    "Metro, State": "Storm Lake, IA",
    "CBSA Code": 44740,
  },
  {
    RegionID: 395236,
    SizeRank: 909,
    RegionName: "Woodward, OK",
    RegionType: "Msa",
    Metro: "Woodward",
    State: "OK",
    "Metro, State": "Woodward, OK",
    "CBSA Code": 49260,
  },
  {
    RegionID: 394996,
    SizeRank: 910,
    RegionName: "Portales, NM",
    RegionType: "Msa",
    Metro: "Portales",
    State: "NM",
    "Metro, State": "Portales, NM",
    "CBSA Code": 38780,
  },
  {
    RegionID: 394678,
    SizeRank: 911,
    RegionName: "Hereford, TX",
    RegionType: "Msa",
    Metro: "Hereford",
    State: "TX",
    "Metro, State": "Hereford, TX",
    "CBSA Code": 25820,
  },
  {
    RegionID: 753908,
    SizeRank: 913,
    RegionName: "Othello, WA",
    RegionType: "Msa",
    Metro: "Othello",
    State: "WA",
    "Metro, State": "Othello, WA",
    "CBSA Code": 36830,
  },
  {
    RegionID: 394805,
    SizeRank: 914,
    RegionName: "Los Alamos, NM",
    RegionType: "Msa",
    Metro: "Los Alamos",
    State: "NM",
    "Metro, State": "Los Alamos, NM",
    "CBSA Code": 31060,
  },
  {
    RegionID: 394594,
    SizeRank: 915,
    RegionName: "Fitzgerald, GA",
    RegionType: "Msa",
    Metro: "Fitzgerald",
    State: "GA",
    "Metro, State": "Fitzgerald, GA",
    "CBSA Code": 22340,
  },
  {
    RegionID: 394841,
    SizeRank: 916,
    RegionName: "Maysville, KY",
    RegionType: "Msa",
    Metro: "Maysville",
    State: "KY",
    "Metro, State": "Maysville, KY",
    "CBSA Code": 32500,
  },
  {
    RegionID: 394700,
    SizeRank: 917,
    RegionName: "Huron, SD",
    RegionType: "Msa",
    Metro: "Huron",
    State: "SD",
    "Metro, State": "Huron, SD",
    "CBSA Code": 26700,
  },
  {
    RegionID: 394342,
    SizeRank: 918,
    RegionName: "Atchison, KS",
    RegionType: "Msa",
    Metro: "Atchison",
    State: "KS",
    "Metro, State": "Atchison, KS",
    "CBSA Code": 11860,
  },
  {
    RegionID: 395104,
    SizeRank: 919,
    RegionName: "Snyder, TX",
    RegionType: "Msa",
    Metro: "Snyder",
    State: "TX",
    "Metro, State": "Snyder, TX",
    "CBSA Code": 43660,
  },
  {
    RegionID: 753880,
    SizeRank: 920,
    RegionName: "Fairfield, IA",
    RegionType: "Msa",
    Metro: "Fairfield",
    State: "IA",
    "Metro, State": "Fairfield, IA",
    "CBSA Code": 21840,
  },
  {
    RegionID: 395111,
    SizeRank: 921,
    RegionName: "Spencer, IA",
    RegionType: "Msa",
    Metro: "Spencer",
    State: "IA",
    "Metro, State": "Spencer, IA",
    "CBSA Code": 43980,
  },
  {
    RegionID: 395112,
    SizeRank: 921,
    RegionName: "Spirit Lake, IA",
    RegionType: "Msa",
    Metro: "Spirit Lake",
    State: "IA",
    "Metro, State": "Spirit Lake, IA",
    "CBSA Code": 44020,
  },
  {
    RegionID: 753925,
    SizeRank: 923,
    RegionName: "Vineyard Haven, MA",
    RegionType: "Msa",
    Metro: "Vineyard Haven",
    State: "MA",
    "Metro, State": "Vineyard Haven, MA",
    "CBSA Code": 47240,
  },
  {
    RegionID: 753927,
    SizeRank: 924,
    RegionName: "Winnemucca, NV",
    RegionType: "Msa",
    Metro: "Winnemucca",
    State: "NV",
    "Metro, State": "Winnemucca, NV",
    "CBSA Code": 49080,
  },
  {
    RegionID: 395142,
    SizeRank: 925,
    RegionName: "Sweetwater, TX",
    RegionType: "Msa",
    Metro: "Sweetwater",
    State: "TX",
    "Metro, State": "Sweetwater, TX",
    "CBSA Code": 45020,
  },
  {
    RegionID: 394330,
    SizeRank: 926,
    RegionName: "Andrews, TX",
    RegionType: "Msa",
    Metro: "Andrews",
    State: "TX",
    "Metro, State": "Andrews, TX",
    "CBSA Code": 11380,
  },
  {
    RegionID: 753929,
    SizeRank: 927,
    RegionName: "Zapata, TX",
    RegionType: "Msa",
    Metro: "Zapata",
    State: "TX",
    "Metro, State": "Zapata, TX",
    "CBSA Code": 49820,
  },
  {
    RegionID: 395186,
    SizeRank: 928,
    RegionName: "Vermillion, SD",
    RegionType: "Msa",
    Metro: "Vermillion",
    State: "SD",
    "Metro, State": "Vermillion, SD",
    "CBSA Code": 46820,
  },
  {
    RegionID: 394767,
    SizeRank: 929,
    RegionName: "Lamesa, TX",
    RegionType: "Msa",
    Metro: "Lamesa",
    State: "TX",
    "Metro, State": "Lamesa, TX",
    "CBSA Code": 29500,
  },
  {
    RegionID: 753874,
    SizeRank: 930,
    RegionName: "Craig, CO",
    RegionType: "Msa",
    Metro: "Craig",
    State: "CO",
    "Metro, State": "Craig, CO",
    "CBSA Code": 18780,
  },
  {
    RegionID: 394968,
    SizeRank: 931,
    RegionName: "Pecos, TX",
    RegionType: "Msa",
    Metro: "Pecos",
    State: "TX",
    "Metro, State": "Pecos, TX",
    "CBSA Code": 37780,
  },
  {
    RegionID: 395188,
    SizeRank: 932,
    RegionName: "Vernon, TX",
    RegionType: "Msa",
    Metro: "Vernon",
    State: "TX",
    "Metro, State": "Vernon, TX",
    "CBSA Code": 46900,
  },
  {
    RegionID: 394743,
    SizeRank: 933,
    RegionName: "Ketchikan, AK",
    RegionType: "Msa",
    Metro: "Ketchikan",
    State: "AK",
    "Metro, State": "Ketchikan, AK",
    "CBSA Code": 28540,
  },
];

export const stateMap = [
  {
    stateCode: "00",
    stateName: "All States",
    stateAbbr: "ALL STATE",
  },
  {
    stateCode: "01",
    stateName: "Alabama",
    stateAbbr: "AL",
  },
  {
    stateCode: "02",
    stateName: "Alaska",
    stateAbbr: "AK",
  },
  {
    stateCode: "04",
    stateName: "Arizona",
    stateAbbr: "AZ",
  },
  {
    stateCode: "05",
    stateName: "Arkansas",
    stateAbbr: "AR",
  },
  {
    stateCode: "06",
    stateName: "California",
    stateAbbr: "CA",
  },
  {
    stateCode: "08",
    stateName: "Colorado",
    stateAbbr: "CO",
  },
  {
    stateCode: "09",
    stateName: "Connecticut",
    stateAbbr: "CT",
  },
  {
    stateCode: 10,
    stateName: "Delaware",
    stateAbbr: "DE",
  },
  {
    stateCode: 11,
    stateName: "District of Columbia",
    stateAbbr: "DC",
  },
  {
    stateCode: 12,
    stateName: "Florida",
    stateAbbr: "FL",
  },
  {
    stateCode: 13,
    stateName: "Georgia",
    stateAbbr: "GA",
  },
  {
    stateCode: 15,
    stateName: "Hawaii",
    stateAbbr: "HI",
  },
  {
    stateCode: 16,
    stateName: "Idaho",
    stateAbbr: "ID",
  },
  {
    stateCode: 17,
    stateName: "Illinois",
    stateAbbr: "IL",
  },
  {
    stateCode: 18,
    stateName: "Indiana",
    stateAbbr: "IN",
  },
  {
    stateCode: 19,
    stateName: "Iowa",
    stateAbbr: "IA",
  },
  {
    stateCode: 20,
    stateName: "Kansas",
    stateAbbr: "KS",
  },
  {
    stateCode: 21,
    stateName: "Kentucky",
    stateAbbr: "KY",
  },
  {
    stateCode: 22,
    stateName: "Louisiana",
    stateAbbr: "LA",
  },
  {
    stateCode: 23,
    stateName: "Maine",
    stateAbbr: "ME",
  },
  {
    stateCode: 24,
    stateName: "Maryland",
    stateAbbr: "MD",
  },
  {
    stateCode: 25,
    stateName: "Massachusetts",
    stateAbbr: "MA",
  },
  {
    stateCode: 26,
    stateName: "Michigan",
    stateAbbr: "MI",
  },
  {
    stateCode: 27,
    stateName: "Minnesota",
    stateAbbr: "MN",
  },
  {
    stateCode: 28,
    stateName: "Mississippi",
    stateAbbr: "MS",
  },
  {
    stateCode: 29,
    stateName: "Missouri",
    stateAbbr: "MO",
  },
  {
    stateCode: 30,
    stateName: "Montana",
    stateAbbr: "MT",
  },
  {
    stateCode: 31,
    stateName: "Nebraska",
    stateAbbr: "NE",
  },
  {
    stateCode: 32,
    stateName: "Nevada",
    stateAbbr: "NV",
  },
  {
    stateCode: 33,
    stateName: "New Hampshire",
    stateAbbr: "NH",
  },
  {
    stateCode: 34,
    stateName: "New Jersey",
    stateAbbr: "NJ",
  },
  {
    stateCode: 35,
    stateName: "New Mexico",
    stateAbbr: "NM",
  },
  {
    stateCode: 36,
    stateName: "New York",
    stateAbbr: "NY",
  },
  {
    stateCode: 37,
    stateName: "North Carolina",
    stateAbbr: "NC",
  },
  {
    stateCode: 38,
    stateName: "North Dakota",
    stateAbbr: "ND",
  },
  {
    stateCode: 39,
    stateName: "Ohio",
    stateAbbr: "OH",
  },
  {
    stateCode: 40,
    stateName: "Oklahoma",
    stateAbbr: "OK",
  },
  {
    stateCode: 41,
    stateName: "Oregon",
    stateAbbr: "OR",
  },
  {
    stateCode: 42,
    stateName: "Pennsylvania",
    stateAbbr: "PA",
  },
  {
    stateCode: 44,
    stateName: "Rhode Island",
    stateAbbr: "RI",
  },
  {
    stateCode: 45,
    stateName: "South Carolina",
    stateAbbr: "SC",
  },
  {
    stateCode: 46,
    stateName: "South Dakota",
    stateAbbr: "SD",
  },
  {
    stateCode: 47,
    stateName: "Tennessee",
    stateAbbr: "TN",
  },
  {
    stateCode: 48,
    stateName: "Texas",
    stateAbbr: "TX",
  },
  {
    stateCode: 49,
    stateName: "Utah",
    stateAbbr: "UT",
  },
  {
    stateCode: 50,
    stateName: "Vermont",
    stateAbbr: "VT",
  },
  {
    stateCode: 51,
    stateName: "Virginia",
    stateAbbr: "VA",
  },
  {
    stateCode: 53,
    stateName: "Washington",
    stateAbbr: "WA",
  },
  {
    stateCode: 54,
    stateName: "West Virginia",
    stateAbbr: "WV",
  },
  {
    stateCode: 55,
    stateName: "Wisconsin",
    stateAbbr: "WI",
  },
  {
    stateCode: 56,
    stateName: "Wyoming",
    stateAbbr: "WY",
  },
  {
    stateCode: 72,
    stateName: "Puerto Rico",
    stateAbbr: "PR",
  },
  {
    stateCode: 78,
    stateName: "Virgin Islands",
    stateAbbr: "VI",
  },
  {
    stateCode: 99,
    stateName: "All Metropolitan Statistical Areas",
    stateAbbr: "ALL MSA",
  },
];

export const zipMetroMap = {
  501: 35620,
  601: 38660,
  602: 10380,
  603: 10380,
  604: 10380,
  605: 10380,
  606: 99999,
  610: 10380,
  611: 10380,
  612: 41980,
  613: 11640,
  614: 11640,
  616: 11640,
  617: 41980,
  622: 41900,
  623: 41900,
  624: 38660,
  627: 11640,
  631: 10380,
  637: 41900,
  638: 41980,
  641: 38660,
  646: 41980,
  647: 41900,
  650: 11640,
  652: 11640,
  653: 49500,
  656: 49500,
  659: 11640,
  660: 32420,
  662: 11640,
  664: 27580,
  667: 41900,
  669: 10380,
  670: 32420,
  674: 41980,
  676: 10380,
  677: 10380,
  678: 11640,
  680: 32420,
  681: 32420,
  682: 32420,
  683: 32420,
  685: 10380,
  687: 41980,
  688: 11640,
  690: 10380,
  692: 41980,
  693: 41980,
  694: 41980,
  698: 41900,
  703: 41980,
  704: 17640,
  705: 41980,
  707: 41980,
  714: 25020,
  715: 38660,
  716: 38660,
  717: 38660,
  718: 41980,
  719: 41980,
  720: 38660,
  721: 41980,
  723: 41980,
  725: 41980,
  726: 41980,
  727: 41980,
  728: 38660,
  729: 41980,
  730: 38660,
  731: 38660,
  732: 38660,
  733: 38660,
  735: 41980,
  736: 41980,
  737: 41980,
  738: 41980,
  739: 41980,
  740: 41980,
  741: 41980,
  742: 41980,
  744: 41980,
  745: 41980,
  751: 42180,
  754: 41980,
  757: 17620,
  765: 99999,
  766: 38660,
  767: 41980,
  769: 17620,
  771: 41980,
  772: 41980,
  773: 41980,
  775: 99999,
  777: 41980,
  778: 41980,
  780: 38660,
  782: 41980,
  783: 41980,
  784: 25020,
  785: 25020,
  786: 41980,
  791: 41980,
  792: 41980,
  794: 41980,
  795: 38660,
  801: 99999,
  802: 99999,
  803: 99999,
  804: 99999,
  820: 99999,
  830: 99999,
  831: 99999,
  840: 99999,
  850: 99999,
  901: 41980,
  902: 41980,
  907: 41980,
  908: 41980,
  909: 41980,
  910: 41980,
  911: 41980,
  912: 41980,
  913: 41980,
  914: 41980,
  915: 41980,
  916: 41980,
  917: 41980,
  918: 41980,
  919: 41980,
  920: 41980,
  921: 41980,
  922: 41980,
  923: 41980,
  924: 41980,
  925: 41980,
  926: 41980,
  927: 41980,
  928: 41980,
  929: 41980,
  931: 41980,
  934: 41980,
  935: 41980,
  936: 41980,
  937: 41980,
  940: 41980,
  949: 41980,
  950: 41980,
  951: 41980,
  952: 41980,
  953: 41980,
  954: 41980,
  956: 41980,
  957: 41980,
  958: 41980,
  959: 41980,
  960: 41980,
  961: 41980,
  962: 41980,
  963: 41980,
  965: 41980,
  966: 41980,
  968: 41980,
  969: 41980,
  970: 41980,
  971: 41980,
  975: 41980,
  976: 41980,
  977: 41980,
  979: 41980,
  981: 41980,
  982: 41980,
  983: 41980,
  984: 41980,
  985: 41980,
  986: 41980,
  987: 41980,
  988: 41980,
  1001: 44140,
  1002: 44140,
  1003: 44140,
  1004: 44140,
  1005: 49340,
  1007: 44140,
  1008: 38340,
  1009: 44140,
  1010: 44140,
  1011: 44140,
  1012: 44140,
  1013: 44140,
  1014: 44140,
  1020: 44140,
  1021: 44140,
  1022: 44140,
  1026: 38340,
  1027: 44140,
  1028: 44140,
  1029: 38340,
  1030: 44140,
  1031: 49340,
  1032: 44140,
  1033: 44140,
  1034: 44140,
  1035: 44140,
  1036: 44140,
  1037: 49340,
  1038: 44140,
  1039: 44140,
  1040: 44140,
  1041: 44140,
  1050: 44140,
  1053: 44140,
  1054: 44140,
  1056: 44140,
  1057: 44140,
  1059: 44140,
  1060: 44140,
  1061: 44140,
  1062: 44140,
  1063: 44140,
  1066: 44140,
  1068: 49340,
  1069: 44140,
  1070: 38340,
  1071: 44140,
  1072: 44140,
  1073: 44140,
  1074: 49340,
  1075: 44140,
  1077: 44140,
  1079: 44140,
  1080: 44140,
  1081: 44140,
  1082: 49340,
  1083: 49340,
  1084: 44140,
  1085: 44140,
  1088: 44140,
  1089: 44140,
  1090: 44140,
  1092: 49340,
  1093: 44140,
  1094: 49340,
  1095: 44140,
  1096: 44140,
  1097: 44140,
  1098: 44140,
  1101: 44140,
  1103: 44140,
  1104: 44140,
  1105: 44140,
  1106: 44140,
  1107: 44140,
  1108: 44140,
  1109: 44140,
  1111: 44140,
  1115: 44140,
  1116: 44140,
  1118: 44140,
  1119: 44140,
  1128: 44140,
  1129: 44140,
  1138: 44140,
  1139: 44140,
  1144: 44140,
  1151: 44140,
  1152: 44140,
  1199: 44140,
  1201: 38340,
  1202: 38340,
  1220: 38340,
  1222: 38340,
  1223: 38340,
  1224: 38340,
  1225: 38340,
  1226: 38340,
  1227: 38340,
  1229: 38340,
  1230: 38340,
  1235: 38340,
  1236: 38340,
  1237: 38340,
  1238: 38340,
  1240: 38340,
  1242: 38340,
  1243: 44140,
  1244: 38340,
  1245: 38340,
  1247: 44140,
  1252: 38340,
  1253: 38340,
  1254: 38340,
  1255: 38340,
  1256: 38340,
  1257: 38340,
  1258: 38340,
  1259: 38340,
  1260: 38340,
  1262: 38340,
  1263: 38340,
  1264: 38340,
  1266: 38340,
  1267: 38340,
  1270: 38340,
  1301: 44140,
  1302: 44140,
  1330: 44140,
  1331: 44140,
  1337: 44140,
  1338: 44140,
  1339: 44140,
  1340: 44140,
  1341: 44140,
  1342: 44140,
  1343: 44140,
  1344: 44140,
  1346: 44140,
  1347: 44140,
  1349: 44140,
  1350: 44140,
  1351: 44140,
  1354: 44140,
  1355: 44140,
  1360: 44140,
  1364: 49340,
  1366: 49340,
  1367: 44140,
  1368: 49340,
  1370: 44140,
  1373: 44140,
  1375: 44140,
  1376: 44140,
  1378: 44140,
  1379: 44140,
  1380: 44140,
  1420: 49340,
  1430: 49340,
  1431: 49340,
  1432: 14460,
  1434: 14460,
  1436: 49340,
  1438: 49340,
  1440: 49340,
  1441: 49340,
  1450: 14460,
  1451: 14460,
  1452: 49340,
  1453: 49340,
  1460: 14460,
  1462: 14460,
  1463: 14460,
  1464: 49340,
  1467: 49340,
  1468: 49340,
  1469: 14460,
  1472: 14460,
  1473: 49340,
  1474: 14460,
  1475: 49340,
  1501: 49340,
  1503: 14460,
  1504: 14460,
  1505: 49340,
  1506: 49340,
  1507: 49340,
  1508: 49340,
  1509: 49340,
  1510: 49340,
  1515: 49340,
  1516: 49340,
  1518: 49340,
  1519: 49340,
  1520: 49340,
  1521: 44140,
  1522: 49340,
  1523: 49340,
  1524: 49340,
  1525: 49340,
  1526: 49340,
  1527: 49340,
  1529: 49340,
  1531: 49340,
  1532: 14460,
  1534: 49340,
  1535: 49340,
  1536: 49340,
  1537: 49340,
  1538: 49340,
  1540: 49340,
  1541: 49340,
  1542: 49340,
  1543: 49340,
  1545: 49340,
  1546: 49340,
  1550: 49340,
  1560: 49340,
  1561: 49340,
  1562: 49340,
  1564: 49340,
  1566: 49340,
  1568: 49340,
  1569: 49340,
  1570: 49340,
  1571: 49340,
  1581: 49340,
  1583: 49340,
  1585: 49340,
  1586: 49340,
  1588: 49340,
  1590: 49340,
  1601: 49340,
  1602: 49340,
  1603: 49340,
  1604: 49340,
  1605: 49340,
  1606: 49340,
  1607: 49340,
  1608: 49340,
  1609: 49340,
  1610: 49340,
  1611: 49340,
  1612: 49340,
  1613: 49340,
  1614: 49340,
  1653: 49340,
  1655: 49340,
  1701: 14460,
  1702: 14460,
  1703: 14460,
  1704: 14460,
  1718: 14460,
  1719: 14460,
  1720: 14460,
  1721: 14460,
  1730: 14460,
  1731: 14460,
  1740: 49340,
  1741: 14460,
  1742: 14460,
  1745: 49340,
  1746: 14460,
  1747: 49340,
  1748: 14460,
  1749: 49340,
  1752: 14460,
  1754: 14460,
  1756: 49340,
  1757: 49340,
  1760: 14460,
  1770: 14460,
  1772: 14460,
  1773: 14460,
  1775: 14460,
  1776: 14460,
  1778: 14460,
  1784: 14460,
  1801: 14460,
  1803: 14460,
  1805: 14460,
  1810: 14460,
  1812: 14460,
  1821: 14460,
  1822: 14460,
  1824: 14460,
  1826: 14460,
  1827: 14460,
  1830: 14460,
  1831: 14460,
  1832: 14460,
  1833: 14460,
  1834: 14460,
  1835: 14460,
  1840: 14460,
  1841: 14460,
  1842: 14460,
  1843: 14460,
  1844: 14460,
  1845: 14460,
  1850: 14460,
  1851: 14460,
  1852: 14460,
  1853: 14460,
  1854: 14460,
  1860: 14460,
  1862: 14460,
  1863: 14460,
  1864: 14460,
  1865: 14460,
  1866: 14460,
  1867: 14460,
  1876: 14460,
  1879: 14460,
  1880: 14460,
  1885: 14460,
  1886: 14460,
  1887: 14460,
  1888: 14460,
  1889: 14460,
  1890: 14460,
  1899: 14460,
  1901: 14460,
  1902: 14460,
  1903: 14460,
  1904: 14460,
  1905: 14460,
  1906: 14460,
  1907: 14460,
  1908: 14460,
  1910: 14460,
  1913: 14460,
  1915: 14460,
  1921: 14460,
  1922: 14460,
  1923: 14460,
  1929: 14460,
  1930: 14460,
  1931: 14460,
  1936: 14460,
  1937: 14460,
  1938: 14460,
  1940: 14460,
  1944: 14460,
  1945: 14460,
  1949: 14460,
  1950: 14460,
  1951: 14460,
  1952: 14460,
  1960: 14460,
  1961: 14460,
  1965: 14460,
  1966: 14460,
  1969: 14460,
  1970: 14460,
  1971: 14460,
  1982: 14460,
  1983: 14460,
  1984: 14460,
  1985: 14460,
  2018: 14460,
  2019: 14460,
  2020: 14460,
  2021: 14460,
  2025: 14460,
  2026: 14460,
  2027: 14460,
  2030: 14460,
  2032: 14460,
  2035: 14460,
  2038: 14460,
  2041: 14460,
  2043: 14460,
  2044: 14460,
  2045: 14460,
  2047: 14460,
  2048: 14460,
  2050: 14460,
  2051: 14460,
  2052: 14460,
  2053: 14460,
  2054: 14460,
  2056: 14460,
  2059: 14460,
  2060: 14460,
  2061: 14460,
  2062: 14460,
  2065: 14460,
  2066: 14460,
  2067: 14460,
  2070: 14460,
  2071: 14460,
  2072: 14460,
  2081: 14460,
  2090: 14460,
  2093: 14460,
  2108: 14460,
  2109: 14460,
  2110: 14460,
  2111: 14460,
  2112: 14460,
  2113: 14460,
  2114: 14460,
  2115: 14460,
  2116: 14460,
  2117: 14460,
  2118: 14460,
  2119: 14460,
  2120: 14460,
  2121: 14460,
  2122: 14460,
  2123: 14460,
  2124: 14460,
  2125: 14460,
  2126: 14460,
  2127: 14460,
  2128: 14460,
  2129: 14460,
  2130: 14460,
  2131: 14460,
  2132: 14460,
  2133: 14460,
  2134: 14460,
  2135: 14460,
  2136: 14460,
  2137: 14460,
  2138: 14460,
  2139: 14460,
  2140: 14460,
  2141: 14460,
  2142: 14460,
  2143: 14460,
  2144: 14460,
  2145: 14460,
  2148: 14460,
  2149: 14460,
  2150: 14460,
  2151: 14460,
  2152: 14460,
  2155: 14460,
  2163: 14460,
  2169: 14460,
  2170: 14460,
  2171: 14460,
  2176: 14460,
  2180: 14460,
  2184: 14460,
  2185: 14460,
  2186: 14460,
  2188: 14460,
  2189: 14460,
  2190: 14460,
  2191: 14460,
  2196: 14460,
  2199: 14460,
  2201: 14460,
  2203: 14460,
  2205: 14460,
  2210: 14460,
  2215: 14460,
  2217: 14460,
  2222: 14460,
  2238: 14460,
  2269: 14460,
  2301: 14460,
  2302: 14460,
  2303: 14460,
  2304: 14460,
  2305: 14460,
  2322: 14460,
  2324: 14460,
  2325: 14460,
  2327: 14460,
  2330: 14460,
  2331: 14460,
  2332: 14460,
  2333: 14460,
  2334: 39300,
  2337: 14460,
  2338: 14460,
  2339: 14460,
  2341: 14460,
  2343: 14460,
  2344: 14460,
  2345: 14460,
  2346: 14460,
  2347: 39300,
  2348: 14460,
  2349: 14460,
  2350: 14460,
  2351: 14460,
  2355: 14460,
  2356: 14460,
  2357: 39300,
  2358: 14460,
  2359: 14460,
  2360: 14460,
  2361: 14460,
  2362: 14460,
  2364: 14460,
  2366: 14460,
  2367: 14460,
  2368: 14460,
  2370: 14460,
  2375: 14460,
  2379: 14460,
  2381: 14460,
  2382: 14460,
  2420: 14460,
  2421: 14460,
  2445: 14460,
  2446: 14460,
  2447: 14460,
  2451: 14460,
  2452: 14460,
  2453: 14460,
  2454: 14460,
  2455: 14460,
  2458: 14460,
  2459: 14460,
  2460: 14460,
  2461: 14460,
  2462: 14460,
  2464: 14460,
  2465: 14460,
  2466: 14460,
  2467: 14460,
  2468: 14460,
  2471: 14460,
  2472: 14460,
  2474: 14460,
  2476: 14460,
  2477: 14460,
  2478: 14460,
  2481: 14460,
  2482: 14460,
  2492: 14460,
  2493: 14460,
  2494: 14460,
  2532: 12700,
  2534: 12700,
  2535: 47240,
  2536: 12700,
  2537: 12700,
  2538: 14460,
  2539: 47240,
  2540: 12700,
  2541: 12700,
  2542: 12700,
  2543: 12700,
  2552: 47240,
  2553: 12700,
  2554: 99999,
  2556: 12700,
  2557: 47240,
  2558: 14460,
  2559: 12700,
  2561: 12700,
  2562: 12700,
  2563: 12700,
  2564: 99999,
  2568: 39300,
  2571: 14460,
  2574: 12700,
  2575: 47240,
  2576: 14460,
  2584: 99999,
  2601: 12700,
  2630: 12700,
  2631: 12700,
  2632: 12700,
  2633: 12700,
  2634: 12700,
  2635: 12700,
  2637: 12700,
  2638: 12700,
  2639: 12700,
  2641: 12700,
  2642: 12700,
  2643: 12700,
  2644: 12700,
  2645: 12700,
  2646: 12700,
  2647: 12700,
  2648: 12700,
  2649: 12700,
  2650: 12700,
  2651: 12700,
  2652: 12700,
  2653: 12700,
  2655: 12700,
  2657: 12700,
  2659: 12700,
  2660: 12700,
  2661: 12700,
  2662: 12700,
  2663: 12700,
  2664: 12700,
  2666: 12700,
  2667: 12700,
  2668: 12700,
  2669: 12700,
  2670: 12700,
  2671: 12700,
  2672: 12700,
  2673: 12700,
  2675: 12700,
  2702: 39300,
  2703: 39300,
  2712: 39300,
  2713: 47240,
  2715: 39300,
  2717: 39300,
  2718: 39300,
  2719: 39300,
  2720: 39300,
  2721: 39300,
  2722: 39300,
  2723: 39300,
  2724: 39300,
  2725: 39300,
  2726: 39300,
  2738: 14460,
  2739: 14460,
  2740: 39300,
  2741: 39300,
  2742: 39300,
  2743: 39300,
  2744: 39300,
  2745: 39300,
  2746: 39300,
  2747: 39300,
  2748: 39300,
  2760: 39300,
  2761: 39300,
  2762: 14460,
  2763: 39300,
  2764: 39300,
  2766: 39300,
  2767: 14460,
  2768: 39300,
  2769: 39300,
  2770: 39300,
  2771: 39300,
  2777: 39300,
  2779: 14460,
  2780: 39300,
  2790: 39300,
  2791: 39300,
  2801: 39300,
  2802: 39300,
  2804: 39300,
  2806: 39300,
  2807: 39300,
  2808: 39300,
  2809: 39300,
  2812: 39300,
  2813: 39300,
  2814: 39300,
  2815: 39300,
  2816: 39300,
  2817: 39300,
  2818: 39300,
  2822: 39300,
  2823: 39300,
  2824: 39300,
  2825: 39300,
  2826: 39300,
  2827: 39300,
  2828: 39300,
  2829: 39300,
  2830: 39300,
  2831: 39300,
  2832: 39300,
  2833: 39300,
  2835: 39300,
  2836: 39300,
  2837: 39300,
  2838: 39300,
  2839: 39300,
  2840: 39300,
  2841: 39300,
  2842: 39300,
  2852: 39300,
  2857: 39300,
  2858: 39300,
  2859: 39300,
  2860: 39300,
  2861: 39300,
  2862: 39300,
  2863: 39300,
  2864: 39300,
  2865: 39300,
  2871: 39300,
  2872: 39300,
  2873: 39300,
  2874: 39300,
  2875: 39300,
  2876: 39300,
  2877: 39300,
  2878: 39300,
  2879: 39300,
  2880: 39300,
  2881: 39300,
  2882: 39300,
  2883: 39300,
  2885: 39300,
  2886: 39300,
  2887: 39300,
  2888: 39300,
  2889: 39300,
  2891: 39300,
  2892: 39300,
  2893: 39300,
  2894: 39300,
  2895: 39300,
  2896: 39300,
  2898: 39300,
  2901: 39300,
  2902: 39300,
  2903: 39300,
  2904: 39300,
  2905: 39300,
  2906: 39300,
  2907: 39300,
  2908: 39300,
  2909: 39300,
  2910: 39300,
  2911: 39300,
  2912: 39300,
  2914: 39300,
  2915: 39300,
  2916: 39300,
  2917: 39300,
  2918: 39300,
  2919: 39300,
  2920: 39300,
  2921: 39300,
  2940: 39300,
  3031: 31700,
  3032: 14460,
  3033: 31700,
  3034: 18180,
  3036: 14460,
  3037: 14460,
  3038: 14460,
  3041: 14460,
  3042: 14460,
  3043: 31700,
  3044: 14460,
  3045: 31700,
  3046: 18180,
  3047: 31700,
  3048: 31700,
  3049: 31700,
  3051: 31700,
  3052: 31700,
  3053: 14460,
  3054: 31700,
  3055: 31700,
  3057: 31700,
  3060: 31700,
  3061: 31700,
  3062: 31700,
  3063: 31700,
  3064: 31700,
  3070: 31700,
  3071: 31700,
  3073: 14460,
  3076: 31700,
  3077: 14460,
  3079: 14460,
  3082: 31700,
  3084: 31700,
  3086: 31700,
  3087: 14460,
  3101: 31700,
  3102: 31700,
  3103: 31700,
  3104: 18180,
  3105: 31700,
  3106: 31700,
  3108: 31700,
  3109: 31700,
  3110: 31700,
  3111: 31700,
  3215: 30100,
  3216: 18180,
  3217: 29060,
  3218: 29060,
  3220: 29060,
  3221: 18180,
  3222: 30100,
  3223: 30100,
  3224: 18180,
  3225: 29060,
  3226: 99999,
  3227: 99999,
  3229: 18180,
  3230: 30100,
  3231: 18180,
  3233: 18180,
  3234: 14460,
  3235: 18180,
  3237: 29060,
  3238: 30100,
  3240: 30100,
  3241: 30100,
  3242: 18180,
  3243: 18180,
  3244: 30100,
  3245: 29060,
  3246: 29060,
  3249: 29060,
  3251: 30100,
  3252: 29060,
  3253: 29060,
  3254: 29060,
  3255: 18180,
  3256: 29060,
  3257: 18180,
  3258: 18180,
  3259: 99999,
  3260: 18180,
  3261: 14460,
  3262: 30100,
  3263: 18180,
  3264: 30100,
  3266: 30100,
  3268: 18180,
  3269: 29060,
  3272: 18180,
  3273: 18180,
  3275: 18180,
  3276: 29060,
  3278: 18180,
  3279: 30100,
  3280: 18180,
  3281: 18180,
  3282: 30100,
  3284: 30100,
  3285: 30100,
  3287: 18180,
  3289: 29060,
  3290: 14460,
  3291: 14460,
  3299: 29060,
  3301: 18180,
  3302: 18180,
  3303: 18180,
  3304: 18180,
  3305: 18180,
  3307: 18180,
  3431: 28300,
  3435: 28300,
  3440: 31700,
  3441: 28300,
  3442: 31700,
  3443: 28300,
  3444: 28300,
  3445: 28300,
  3446: 28300,
  3447: 28300,
  3448: 28300,
  3449: 31700,
  3450: 28300,
  3451: 28300,
  3452: 28300,
  3455: 28300,
  3456: 30100,
  3457: 28300,
  3458: 28300,
  3461: 28300,
  3462: 28300,
  3464: 28300,
  3465: 28300,
  3466: 28300,
  3467: 28300,
  3468: 31700,
  3469: 28300,
  3470: 28300,
  3561: 30100,
  3570: 13620,
  3574: 30100,
  3575: 13620,
  3576: 13620,
  3579: 13620,
  3580: 30100,
  3581: 13620,
  3582: 13620,
  3583: 13620,
  3584: 13620,
  3585: 30100,
  3586: 30100,
  3588: 13620,
  3589: 13620,
  3590: 13620,
  3592: 13620,
  3593: 13620,
  3595: 13620,
  3597: 13620,
  3598: 30100,
  3601: 30100,
  3602: 28300,
  3603: 30100,
  3604: 28300,
  3605: 30100,
  3607: 30100,
  3608: 28300,
  3609: 28300,
  3740: 30100,
  3741: 30100,
  3743: 30100,
  3745: 30100,
  3746: 30100,
  3748: 30100,
  3749: 30100,
  3750: 30100,
  3751: 30100,
  3752: 30100,
  3753: 30100,
  3754: 30100,
  3755: 30100,
  3756: 30100,
  3765: 30100,
  3766: 30100,
  3768: 30100,
  3769: 30100,
  3770: 30100,
  3771: 30100,
  3773: 30100,
  3774: 30100,
  3777: 30100,
  3779: 30100,
  3780: 30100,
  3781: 30100,
  3782: 18180,
  3784: 30100,
  3785: 30100,
  3801: 14460,
  3802: 14460,
  3803: 14460,
  3804: 38860,
  3809: 29060,
  3810: 29060,
  3811: 14460,
  3812: 99999,
  3813: 99999,
  3814: 99999,
  3815: 14460,
  3816: 99999,
  3817: 99999,
  3818: 99999,
  3819: 14460,
  3820: 14460,
  3821: 14460,
  3822: 14460,
  3823: 14460,
  3824: 14460,
  3825: 14460,
  3826: 14460,
  3827: 14460,
  3830: 99999,
  3832: 99999,
  3833: 14460,
  3835: 14460,
  3836: 99999,
  3837: 29060,
  3838: 99999,
  3839: 14460,
  3840: 14460,
  3841: 14460,
  3842: 14460,
  3843: 14460,
  3844: 14460,
  3845: 99999,
  3846: 99999,
  3847: 99999,
  3848: 14460,
  3849: 99999,
  3850: 99999,
  3851: 14460,
  3852: 14460,
  3853: 99999,
  3854: 14460,
  3855: 14460,
  3856: 14460,
  3857: 14460,
  3858: 14460,
  3859: 14460,
  3860: 99999,
  3861: 14460,
  3862: 14460,
  3864: 99999,
  3865: 14460,
  3866: 14460,
  3867: 14460,
  3868: 14460,
  3869: 14460,
  3870: 14460,
  3871: 14460,
  3872: 99999,
  3873: 14460,
  3874: 14460,
  3875: 99999,
  3878: 14460,
  3882: 99999,
  3883: 99999,
  3884: 14460,
  3885: 14460,
  3886: 99999,
  3887: 99999,
  3890: 99999,
  3894: 99999,
  3896: 99999,
  3897: 99999,
  3901: 38860,
  3902: 38860,
  3903: 38860,
  3904: 38860,
  3905: 38860,
  3906: 38860,
  3907: 38860,
  3908: 38860,
  3909: 38860,
  3910: 38860,
  3911: 38860,
  4001: 38860,
  4002: 38860,
  4003: 38860,
  4004: 38860,
  4005: 38860,
  4006: 38860,
  4008: 38860,
  4009: 38860,
  4010: 99999,
  4011: 38860,
  4013: 38860,
  4014: 38860,
  4015: 38860,
  4016: 99999,
  4017: 38860,
  4019: 38860,
  4020: 38860,
  4021: 38860,
  4022: 99999,
  4024: 38860,
  4027: 38860,
  4028: 38860,
  4029: 38860,
  4030: 38860,
  4032: 38860,
  4033: 38860,
  4034: 38860,
  4037: 99999,
  4038: 38860,
  4039: 38860,
  4040: 38860,
  4041: 99999,
  4042: 38860,
  4043: 38860,
  4046: 38860,
  4047: 38860,
  4048: 38860,
  4049: 38860,
  4050: 38860,
  4051: 99999,
  4054: 38860,
  4055: 38860,
  4056: 38860,
  4057: 38860,
  4061: 38860,
  4062: 38860,
  4063: 38860,
  4064: 38860,
  4066: 38860,
  4068: 99999,
  4069: 38860,
  4070: 38860,
  4071: 38860,
  4072: 38860,
  4073: 38860,
  4074: 38860,
  4076: 38860,
  4077: 38860,
  4078: 38860,
  4079: 38860,
  4082: 38860,
  4083: 38860,
  4084: 38860,
  4085: 38860,
  4086: 38860,
  4087: 38860,
  4088: 99999,
  4090: 38860,
  4091: 38860,
  4092: 38860,
  4093: 38860,
  4094: 38860,
  4095: 38860,
  4096: 38860,
  4097: 38860,
  4098: 38860,
  4101: 38860,
  4102: 38860,
  4103: 38860,
  4104: 38860,
  4105: 38860,
  4106: 38860,
  4107: 38860,
  4108: 38860,
  4109: 38860,
  4110: 38860,
  4112: 38860,
  4116: 38860,
  4122: 38860,
  4123: 38860,
  4124: 38860,
  4210: 30340,
  4211: 30340,
  4212: 30340,
  4216: 99999,
  4217: 99999,
  4219: 99999,
  4220: 30340,
  4221: 99999,
  4222: 38860,
  4223: 30340,
  4224: 99999,
  4225: 99999,
  4226: 99999,
  4227: 99999,
  4228: 30340,
  4230: 30340,
  4231: 99999,
  4234: 99999,
  4236: 30340,
  4237: 99999,
  4238: 30340,
  4239: 30340,
  4240: 30340,
  4241: 30340,
  4243: 30340,
  4250: 30340,
  4252: 38860,
  4253: 30340,
  4254: 30340,
  4255: 99999,
  4256: 30340,
  4257: 99999,
  4258: 30340,
  4259: 12300,
  4260: 30340,
  4261: 99999,
  4262: 99999,
  4263: 30340,
  4265: 12300,
  4266: 30340,
  4267: 99999,
  4268: 99999,
  4270: 30340,
  4271: 99999,
  4274: 38860,
  4275: 99999,
  4276: 99999,
  4280: 30340,
  4281: 99999,
  4282: 30340,
  4284: 12300,
  4285: 99999,
  4286: 99999,
  4287: 38860,
  4288: 30340,
  4289: 99999,
  4290: 99999,
  4291: 30340,
  4292: 99999,
  4294: 99999,
  4330: 12300,
  4332: 12300,
  4333: 12300,
  4336: 12300,
  4338: 12300,
  4341: 99999,
  4342: 99999,
  4343: 12300,
  4344: 12300,
  4345: 12300,
  4346: 12300,
  4347: 12300,
  4348: 99999,
  4349: 12300,
  4350: 12300,
  4351: 12300,
  4352: 12300,
  4353: 99999,
  4354: 99999,
  4355: 12300,
  4357: 12300,
  4358: 99999,
  4359: 12300,
  4360: 12300,
  4363: 12300,
  4364: 12300,
  4401: 12620,
  4402: 12620,
  4406: 99999,
  4408: 99999,
  4410: 12620,
  4411: 12620,
  4412: 12620,
  4413: 99999,
  4414: 99999,
  4415: 99999,
  4416: 99999,
  4417: 12620,
  4418: 12620,
  4419: 12620,
  4420: 99999,
  4421: 99999,
  4422: 12620,
  4424: 99999,
  4426: 99999,
  4427: 12620,
  4428: 12620,
  4429: 12620,
  4430: 12620,
  4431: 99999,
  4434: 12620,
  4435: 12620,
  4438: 99999,
  4441: 99999,
  4442: 99999,
  4443: 99999,
  4444: 12620,
  4448: 12620,
  4449: 12620,
  4450: 12620,
  4451: 12620,
  4453: 12620,
  4454: 99999,
  4455: 12620,
  4456: 12620,
  4457: 12620,
  4459: 99999,
  4460: 12620,
  4461: 12620,
  4462: 12620,
  4463: 99999,
  4464: 99999,
  4468: 12620,
  4469: 12620,
  4471: 99999,
  4472: 99999,
  4473: 12620,
  4474: 12620,
  4475: 12620,
  4476: 99999,
  4478: 99999,
  4479: 99999,
  4481: 99999,
  4485: 99999,
  4487: 12620,
  4488: 12620,
  4489: 12620,
  4490: 99999,
  4491: 99999,
  4492: 99999,
  4493: 12620,
  4495: 12620,
  4496: 99999,
  4497: 12620,
  4530: 38860,
  4535: 99999,
  4537: 99999,
  4538: 99999,
  4539: 99999,
  4541: 99999,
  4543: 99999,
  4544: 99999,
  4547: 99999,
  4548: 38860,
  4551: 99999,
  4553: 99999,
  4554: 99999,
  4555: 99999,
  4556: 99999,
  4558: 99999,
  4562: 38860,
  4563: 99999,
  4564: 99999,
  4565: 38860,
  4568: 99999,
  4570: 99999,
  4571: 99999,
  4572: 99999,
  4573: 99999,
  4574: 99999,
  4575: 99999,
  4576: 99999,
  4578: 99999,
  4579: 38860,
  4605: 99999,
  4606: 99999,
  4607: 99999,
  4609: 99999,
  4611: 99999,
  4612: 99999,
  4613: 99999,
  4614: 99999,
  4616: 99999,
  4617: 99999,
  4619: 99999,
  4622: 99999,
  4623: 99999,
  4624: 99999,
  4625: 99999,
  4626: 99999,
  4627: 99999,
  4628: 99999,
  4629: 99999,
  4630: 99999,
  4631: 99999,
  4634: 99999,
  4635: 99999,
  4637: 99999,
  4640: 99999,
  4642: 99999,
  4643: 99999,
  4644: 99999,
  4645: 99999,
  4646: 99999,
  4648: 99999,
  4649: 99999,
  4650: 99999,
  4652: 99999,
  4653: 99999,
  4654: 99999,
  4655: 99999,
  4657: 99999,
  4658: 99999,
  4660: 99999,
  4662: 99999,
  4664: 99999,
  4666: 99999,
  4667: 99999,
  4668: 99999,
  4669: 99999,
  4671: 99999,
  4672: 99999,
  4673: 99999,
  4674: 99999,
  4675: 99999,
  4676: 99999,
  4677: 99999,
  4679: 99999,
  4680: 99999,
  4681: 99999,
  4683: 99999,
  4684: 99999,
  4685: 99999,
  4686: 99999,
  4691: 99999,
  4693: 99999,
  4694: 99999,
  4730: 99999,
  4732: 99999,
  4733: 99999,
  4734: 99999,
  4735: 99999,
  4736: 99999,
  4737: 99999,
  4738: 99999,
  4739: 99999,
  4740: 99999,
  4742: 99999,
  4743: 99999,
  4744: 99999,
  4745: 99999,
  4746: 99999,
  4747: 99999,
  4750: 99999,
  4751: 99999,
  4756: 99999,
  4757: 99999,
  4758: 99999,
  4760: 99999,
  4761: 99999,
  4762: 99999,
  4763: 99999,
  4764: 99999,
  4765: 12620,
  4766: 99999,
  4768: 99999,
  4769: 99999,
  4772: 99999,
  4773: 99999,
  4774: 99999,
  4775: 99999,
  4776: 99999,
  4777: 99999,
  4779: 99999,
  4780: 99999,
  4781: 99999,
  4783: 99999,
  4785: 99999,
  4786: 99999,
  4787: 99999,
  4841: 99999,
  4843: 99999,
  4847: 99999,
  4848: 99999,
  4849: 99999,
  4850: 99999,
  4851: 99999,
  4852: 99999,
  4853: 99999,
  4854: 99999,
  4855: 99999,
  4856: 99999,
  4858: 99999,
  4859: 99999,
  4860: 99999,
  4861: 99999,
  4862: 99999,
  4863: 99999,
  4864: 99999,
  4865: 99999,
  4901: 12300,
  4903: 12300,
  4910: 12300,
  4911: 99999,
  4912: 99999,
  4915: 99999,
  4917: 12300,
  4918: 12300,
  4920: 99999,
  4921: 99999,
  4922: 99999,
  4923: 99999,
  4924: 99999,
  4925: 99999,
  4926: 12300,
  4927: 12300,
  4928: 12620,
  4929: 99999,
  4930: 12620,
  4932: 99999,
  4933: 12620,
  4935: 12300,
  4936: 99999,
  4937: 99999,
  4938: 99999,
  4939: 12620,
  4940: 99999,
  4941: 99999,
  4942: 99999,
  4943: 99999,
  4944: 99999,
  4945: 99999,
  4947: 99999,
  4949: 99999,
  4950: 99999,
  4951: 99999,
  4952: 99999,
  4953: 12620,
  4954: 99999,
  4955: 99999,
  4956: 99999,
  4957: 99999,
  4958: 99999,
  4961: 99999,
  4962: 12300,
  4963: 12300,
  4964: 99999,
  4965: 99999,
  4966: 99999,
  4967: 99999,
  4969: 12620,
  4970: 99999,
  4971: 99999,
  4973: 99999,
  4974: 99999,
  4975: 99999,
  4976: 99999,
  4978: 99999,
  4979: 99999,
  4981: 99999,
  4982: 99999,
  4983: 99999,
  4984: 99999,
  4985: 99999,
  4986: 99999,
  4987: 99999,
  4988: 12300,
  4989: 12300,
  4992: 99999,
  5001: 30100,
  5009: 30100,
  5030: 30100,
  5031: 30100,
  5032: 30100,
  5033: 30100,
  5034: 30100,
  5035: 30100,
  5036: 30100,
  5037: 30100,
  5038: 30100,
  5039: 30100,
  5040: 30100,
  5041: 30100,
  5042: 99999,
  5043: 30100,
  5045: 30100,
  5046: 30100,
  5047: 30100,
  5048: 30100,
  5049: 30100,
  5050: 99999,
  5051: 30100,
  5052: 30100,
  5053: 30100,
  5054: 30100,
  5055: 30100,
  5056: 30100,
  5058: 30100,
  5059: 30100,
  5060: 12740,
  5061: 30100,
  5062: 30100,
  5065: 30100,
  5067: 30100,
  5068: 30100,
  5069: 30100,
  5070: 30100,
  5071: 30100,
  5072: 30100,
  5073: 30100,
  5074: 30100,
  5075: 30100,
  5076: 30100,
  5077: 30100,
  5079: 30100,
  5081: 30100,
  5083: 30100,
  5084: 30100,
  5085: 30100,
  5086: 30100,
  5088: 30100,
  5089: 30100,
  5091: 30100,
  5101: 99999,
  5141: 99999,
  5142: 30100,
  5143: 30100,
  5146: 99999,
  5148: 30100,
  5149: 30100,
  5150: 30100,
  5151: 30100,
  5152: 13540,
  5153: 30100,
  5154: 99999,
  5155: 99999,
  5156: 30100,
  5158: 99999,
  5159: 99999,
  5161: 13540,
  5201: 13540,
  5250: 13540,
  5251: 40860,
  5252: 13540,
  5253: 13540,
  5254: 13540,
  5255: 13540,
  5257: 13540,
  5260: 13540,
  5261: 13540,
  5262: 13540,
  5301: 99999,
  5340: 13540,
  5341: 99999,
  5342: 99999,
  5343: 13540,
  5344: 99999,
  5345: 99999,
  5346: 99999,
  5350: 99999,
  5351: 99999,
  5352: 13540,
  5353: 99999,
  5354: 99999,
  5355: 99999,
  5356: 99999,
  5357: 99999,
  5358: 99999,
  5359: 99999,
  5360: 99999,
  5361: 99999,
  5362: 99999,
  5363: 13540,
  5401: 15540,
  5402: 15540,
  5403: 15540,
  5404: 15540,
  5405: 15540,
  5406: 15540,
  5407: 15540,
  5408: 15540,
  5439: 15540,
  5440: 15540,
  5441: 15540,
  5442: 99999,
  5443: 99999,
  5444: 99999,
  5445: 99999,
  5446: 15540,
  5447: 15540,
  5448: 15540,
  5449: 15540,
  5450: 15540,
  5451: 15540,
  5452: 15540,
  5453: 15540,
  5454: 15540,
  5455: 15540,
  5456: 99999,
  5457: 15540,
  5458: 15540,
  5459: 15540,
  5460: 15540,
  5461: 15540,
  5462: 15540,
  5463: 15540,
  5464: 15540,
  5465: 15540,
  5466: 15540,
  5468: 15540,
  5469: 99999,
  5470: 15540,
  5471: 15540,
  5472: 99999,
  5473: 99999,
  5474: 15540,
  5476: 99999,
  5477: 15540,
  5478: 15540,
  5479: 15540,
  5481: 15540,
  5482: 15540,
  5483: 15540,
  5485: 15540,
  5486: 15540,
  5487: 15540,
  5488: 15540,
  5489: 99999,
  5490: 15540,
  5491: 99999,
  5492: 99999,
  5494: 15540,
  5495: 15540,
  5501: 14460,
  5601: 12740,
  5602: 12740,
  5603: 12740,
  5604: 12740,
  5609: 12740,
  5620: 12740,
  5633: 12740,
  5640: 12740,
  5641: 30100,
  5647: 99999,
  5648: 12740,
  5649: 30100,
  5650: 12740,
  5651: 12740,
  5652: 99999,
  5653: 99999,
  5654: 12740,
  5655: 99999,
  5656: 99999,
  5657: 99999,
  5658: 12740,
  5660: 12740,
  5661: 99999,
  5662: 99999,
  5663: 12740,
  5664: 12740,
  5665: 99999,
  5666: 12740,
  5667: 12740,
  5669: 12740,
  5670: 12740,
  5671: 12740,
  5672: 99999,
  5673: 12740,
  5674: 12740,
  5675: 30100,
  5676: 15540,
  5677: 12740,
  5678: 12740,
  5679: 30100,
  5680: 12740,
  5681: 12740,
  5682: 12740,
  5701: 40860,
  5702: 40860,
  5730: 40860,
  5731: 40860,
  5732: 40860,
  5733: 40860,
  5734: 99999,
  5735: 40860,
  5736: 40860,
  5737: 40860,
  5738: 40860,
  5739: 40860,
  5740: 99999,
  5741: 40860,
  5742: 40860,
  5743: 40860,
  5744: 40860,
  5745: 40860,
  5746: 30100,
  5747: 99999,
  5748: 99999,
  5750: 40860,
  5751: 40860,
  5753: 99999,
  5757: 40860,
  5758: 40860,
  5759: 40860,
  5760: 40860,
  5761: 13540,
  5762: 30100,
  5763: 40860,
  5764: 40860,
  5765: 40860,
  5766: 99999,
  5767: 99999,
  5768: 13540,
  5769: 99999,
  5770: 99999,
  5772: 30100,
  5773: 40860,
  5774: 40860,
  5775: 13540,
  5776: 13540,
  5777: 40860,
  5778: 99999,
  5819: 99999,
  5820: 99999,
  5821: 99999,
  5822: 99999,
  5823: 99999,
  5824: 99999,
  5825: 99999,
  5826: 99999,
  5827: 99999,
  5828: 99999,
  5829: 99999,
  5830: 99999,
  5832: 99999,
  5833: 99999,
  5836: 99999,
  5837: 99999,
  5838: 99999,
  5839: 99999,
  5840: 99999,
  5841: 99999,
  5842: 99999,
  5843: 99999,
  5845: 99999,
  5846: 99999,
  5847: 99999,
  5848: 99999,
  5849: 99999,
  5850: 99999,
  5851: 99999,
  5853: 99999,
  5855: 99999,
  5857: 99999,
  5858: 99999,
  5859: 99999,
  5860: 99999,
  5861: 99999,
  5862: 99999,
  5863: 99999,
  5866: 99999,
  5867: 99999,
  5868: 99999,
  5871: 99999,
  5872: 99999,
  5873: 12740,
  5874: 99999,
  5875: 99999,
  5901: 99999,
  5902: 99999,
  5903: 99999,
  5904: 99999,
  5905: 99999,
  5906: 99999,
  5907: 99999,
  6001: 25540,
  6002: 25540,
  6006: 25540,
  6010: 25540,
  6011: 25540,
  6013: 25540,
  6016: 25540,
  6018: 45860,
  6019: 25540,
  6020: 25540,
  6021: 45860,
  6022: 25540,
  6023: 25540,
  6024: 45860,
  6025: 25540,
  6026: 25540,
  6027: 25540,
  6028: 25540,
  6029: 25540,
  6030: 25540,
  6031: 45860,
  6032: 25540,
  6033: 25540,
  6034: 25540,
  6035: 25540,
  6037: 35300,
  6039: 45860,
  6040: 25540,
  6041: 25540,
  6042: 25540,
  6043: 25540,
  6045: 25540,
  6050: 25540,
  6051: 25540,
  6052: 25540,
  6053: 25540,
  6057: 45860,
  6058: 45860,
  6059: 45860,
  6060: 25540,
  6061: 45860,
  6062: 25540,
  6063: 45860,
  6064: 25540,
  6065: 45860,
  6066: 25540,
  6067: 25540,
  6068: 45860,
  6069: 45860,
  6070: 25540,
  6071: 25540,
  6072: 25540,
  6073: 25540,
  6074: 25540,
  6075: 25540,
  6076: 25540,
  6077: 25540,
  6078: 25540,
  6079: 45860,
  6080: 25540,
  6081: 25540,
  6082: 25540,
  6083: 25540,
  6084: 25540,
  6085: 25540,
  6088: 25540,
  6089: 25540,
  6090: 25540,
  6091: 25540,
  6092: 25540,
  6093: 25540,
  6095: 25540,
  6096: 25540,
  6098: 45860,
  6101: 25540,
  6102: 25540,
  6103: 25540,
  6105: 25540,
  6106: 25540,
  6107: 25540,
  6108: 25540,
  6109: 25540,
  6110: 25540,
  6111: 25540,
  6112: 25540,
  6114: 25540,
  6115: 25540,
  6117: 25540,
  6118: 25540,
  6119: 25540,
  6120: 25540,
  6123: 25540,
  6126: 25540,
  6127: 25540,
  6128: 25540,
  6129: 25540,
  6131: 25540,
  6132: 25540,
  6133: 25540,
  6134: 25540,
  6137: 25540,
  6138: 25540,
  6141: 25540,
  6142: 25540,
  6143: 25540,
  6144: 25540,
  6145: 25540,
  6146: 25540,
  6147: 25540,
  6152: 25540,
  6153: 25540,
  6154: 25540,
  6155: 25540,
  6156: 25540,
  6160: 25540,
  6161: 25540,
  6167: 25540,
  6183: 25540,
  6199: 25540,
  6226: 49340,
  6230: 49340,
  6231: 25540,
  6232: 25540,
  6233: 49340,
  6234: 49340,
  6235: 49340,
  6237: 25540,
  6238: 25540,
  6239: 49340,
  6241: 49340,
  6242: 49340,
  6243: 49340,
  6244: 49340,
  6246: 49340,
  6247: 49340,
  6248: 25540,
  6249: 35980,
  6250: 49340,
  6251: 25540,
  6254: 35980,
  6255: 49340,
  6256: 49340,
  6258: 49340,
  6259: 49340,
  6260: 49340,
  6262: 49340,
  6263: 49340,
  6264: 49340,
  6266: 49340,
  6267: 49340,
  6268: 25540,
  6269: 25540,
  6277: 49340,
  6278: 49340,
  6279: 49340,
  6280: 49340,
  6281: 49340,
  6282: 49340,
  6320: 35980,
  6330: 35980,
  6331: 49340,
  6332: 49340,
  6333: 35980,
  6334: 35980,
  6335: 35980,
  6336: 35980,
  6338: 35980,
  6339: 35980,
  6340: 35980,
  6349: 35980,
  6350: 35980,
  6351: 49340,
  6353: 35980,
  6354: 49340,
  6355: 35980,
  6357: 35980,
  6359: 35980,
  6360: 35980,
  6365: 35980,
  6370: 35980,
  6371: 35980,
  6372: 35980,
  6373: 49340,
  6374: 35980,
  6375: 35980,
  6376: 35980,
  6377: 49340,
  6378: 35980,
  6379: 35980,
  6380: 35980,
  6382: 35980,
  6383: 35980,
  6384: 49340,
  6385: 35980,
  6387: 49340,
  6388: 35980,
  6389: 35980,
  6390: 35620,
  6401: 35300,
  6403: 35300,
  6404: 14860,
  6405: 35300,
  6408: 35300,
  6409: 25540,
  6410: 35300,
  6411: 35300,
  6412: 25540,
  6413: 25540,
  6414: 25540,
  6415: 35980,
  6416: 25540,
  6417: 25540,
  6418: 14860,
  6419: 25540,
  6420: 25540,
  6422: 35300,
  6423: 25540,
  6424: 25540,
  6426: 25540,
  6437: 35300,
  6438: 25540,
  6439: 35980,
  6440: 14860,
  6441: 25540,
  6442: 25540,
  6443: 35300,
  6444: 25540,
  6447: 25540,
  6450: 35300,
  6451: 25540,
  6455: 25540,
  6456: 25540,
  6457: 25540,
  6459: 25540,
  6460: 35300,
  6461: 35300,
  6467: 25540,
  6468: 14860,
  6469: 25540,
  6470: 14860,
  6471: 35300,
  6472: 35300,
  6473: 35300,
  6475: 25540,
  6477: 35300,
  6478: 35300,
  6479: 35300,
  6480: 25540,
  6481: 25540,
  6482: 14860,
  6483: 35300,
  6484: 14860,
  6487: 35300,
  6488: 35300,
  6489: 25540,
  6491: 14860,
  6492: 35300,
  6493: 35300,
  6494: 35300,
  6495: 35300,
  6498: 25540,
  6501: 35300,
  6502: 35300,
  6503: 35300,
  6504: 35300,
  6505: 35300,
  6506: 35300,
  6507: 35300,
  6508: 35300,
  6509: 35300,
  6510: 35300,
  6511: 35300,
  6512: 35300,
  6513: 35300,
  6514: 35300,
  6515: 35300,
  6516: 35300,
  6517: 35300,
  6518: 35300,
  6519: 35300,
  6520: 35300,
  6521: 35300,
  6524: 35300,
  6525: 35300,
  6530: 35300,
  6531: 35300,
  6532: 35300,
  6533: 35300,
  6534: 35300,
  6535: 35300,
  6536: 35300,
  6537: 35300,
  6538: 35300,
  6540: 35300,
  6601: 14860,
  6602: 14860,
  6604: 14860,
  6605: 14860,
  6606: 14860,
  6607: 14860,
  6608: 14860,
  6610: 14860,
  6611: 14860,
  6612: 14860,
  6614: 14860,
  6615: 14860,
  6673: 14860,
  6699: 14860,
  6701: 35300,
  6702: 35300,
  6703: 35300,
  6704: 35300,
  6705: 35300,
  6706: 35300,
  6708: 35300,
  6710: 35300,
  6712: 35300,
  6716: 35300,
  6720: 35300,
  6721: 35300,
  6722: 35300,
  6723: 35300,
  6724: 35300,
  6749: 35300,
  6750: 45860,
  6751: 45860,
  6752: 45860,
  6753: 45860,
  6754: 45860,
  6755: 45860,
  6756: 45860,
  6757: 45860,
  6758: 45860,
  6759: 45860,
  6762: 35300,
  6763: 45860,
  6770: 35300,
  6776: 45860,
  6777: 45860,
  6778: 45860,
  6779: 35300,
  6781: 45860,
  6782: 45860,
  6783: 45860,
  6784: 14860,
  6785: 45860,
  6786: 45860,
  6787: 45860,
  6790: 45860,
  6791: 45860,
  6792: 45860,
  6793: 45860,
  6794: 45860,
  6795: 45860,
  6796: 45860,
  6798: 45860,
  6801: 14860,
  6804: 45860,
  6807: 14860,
  6810: 14860,
  6811: 14860,
  6812: 14860,
  6813: 14860,
  6820: 14860,
  6824: 14860,
  6825: 14860,
  6828: 14860,
  6829: 14860,
  6830: 14860,
  6831: 14860,
  6836: 14860,
  6838: 14860,
  6840: 14860,
  6850: 14860,
  6851: 14860,
  6852: 14860,
  6853: 14860,
  6854: 14860,
  6855: 14860,
  6856: 14860,
  6858: 14860,
  6870: 14860,
  6875: 14860,
  6876: 14860,
  6877: 14860,
  6878: 14860,
  6880: 14860,
  6881: 14860,
  6883: 14860,
  6888: 14860,
  6890: 14860,
  6896: 14860,
  6897: 14860,
  6901: 14860,
  6902: 14860,
  6903: 14860,
  6904: 14860,
  6905: 14860,
  6906: 14860,
  6907: 14860,
  6910: 14860,
  6911: 14860,
  6912: 14860,
  6926: 14860,
  7001: 35620,
  7002: 35620,
  7003: 35620,
  7004: 35620,
  7005: 35620,
  7006: 35620,
  7007: 35620,
  7008: 35620,
  7009: 35620,
  7010: 35620,
  7011: 35620,
  7012: 35620,
  7013: 35620,
  7014: 35620,
  7015: 35620,
  7016: 35620,
  7017: 35620,
  7018: 35620,
  7019: 35620,
  7020: 35620,
  7021: 35620,
  7022: 35620,
  7023: 35620,
  7024: 35620,
  7026: 35620,
  7027: 35620,
  7028: 35620,
  7029: 35620,
  7030: 35620,
  7031: 35620,
  7032: 35620,
  7033: 35620,
  7034: 35620,
  7035: 35620,
  7036: 35620,
  7039: 35620,
  7040: 35620,
  7041: 35620,
  7042: 35620,
  7043: 35620,
  7044: 35620,
  7045: 35620,
  7046: 35620,
  7047: 35620,
  7050: 35620,
  7051: 35620,
  7052: 35620,
  7054: 35620,
  7055: 35620,
  7057: 35620,
  7058: 35620,
  7059: 35620,
  7060: 35620,
  7061: 35620,
  7062: 35620,
  7063: 35620,
  7064: 35620,
  7065: 35620,
  7066: 35620,
  7067: 35620,
  7068: 35620,
  7069: 35620,
  7070: 35620,
  7071: 35620,
  7072: 35620,
  7073: 35620,
  7074: 35620,
  7075: 35620,
  7076: 35620,
  7077: 35620,
  7078: 35620,
  7079: 35620,
  7080: 35620,
  7081: 35620,
  7082: 35620,
  7083: 35620,
  7086: 35620,
  7087: 35620,
  7088: 35620,
  7090: 35620,
  7091: 35620,
  7092: 35620,
  7093: 35620,
  7094: 35620,
  7095: 35620,
  7096: 35620,
  7097: 35620,
  7099: 35620,
  7101: 35620,
  7102: 35620,
  7103: 35620,
  7104: 35620,
  7105: 35620,
  7106: 35620,
  7107: 35620,
  7108: 35620,
  7109: 35620,
  7110: 35620,
  7111: 35620,
  7112: 35620,
  7114: 35620,
  7175: 35620,
  7201: 35620,
  7202: 35620,
  7203: 35620,
  7204: 35620,
  7205: 35620,
  7206: 35620,
  7207: 35620,
  7208: 35620,
  7302: 35620,
  7303: 35620,
  7304: 35620,
  7305: 35620,
  7306: 35620,
  7307: 35620,
  7310: 35620,
  7311: 35620,
  7399: 35620,
  7401: 35620,
  7403: 35620,
  7405: 35620,
  7407: 35620,
  7410: 35620,
  7416: 35620,
  7417: 35620,
  7418: 35620,
  7419: 35620,
  7420: 35620,
  7421: 35620,
  7422: 35620,
  7423: 35620,
  7424: 35620,
  7428: 35620,
  7430: 35620,
  7432: 35620,
  7435: 35620,
  7436: 35620,
  7438: 35620,
  7439: 35620,
  7440: 35620,
  7442: 35620,
  7444: 35620,
  7446: 35620,
  7450: 35620,
  7451: 35620,
  7452: 35620,
  7456: 35620,
  7457: 35620,
  7458: 35620,
  7460: 35620,
  7461: 35620,
  7462: 35620,
  7463: 35620,
  7465: 35620,
  7470: 35620,
  7474: 35620,
  7480: 35620,
  7481: 35620,
  7495: 35620,
  7501: 35620,
  7502: 35620,
  7503: 35620,
  7504: 35620,
  7505: 35620,
  7506: 35620,
  7507: 35620,
  7508: 35620,
  7509: 35620,
  7510: 35620,
  7511: 35620,
  7512: 35620,
  7513: 35620,
  7514: 35620,
  7522: 35620,
  7524: 35620,
  7538: 35620,
  7543: 35620,
  7544: 35620,
  7601: 35620,
  7602: 35620,
  7603: 35620,
  7604: 35620,
  7605: 35620,
  7606: 35620,
  7607: 35620,
  7608: 35620,
  7620: 35620,
  7621: 35620,
  7624: 35620,
  7626: 35620,
  7627: 35620,
  7628: 35620,
  7630: 35620,
  7631: 35620,
  7632: 35620,
  7640: 35620,
  7641: 35620,
  7642: 35620,
  7643: 35620,
  7644: 35620,
  7645: 35620,
  7646: 35620,
  7647: 35620,
  7648: 35620,
  7649: 35620,
  7650: 35620,
  7652: 35620,
  7653: 35620,
  7656: 35620,
  7657: 35620,
  7660: 35620,
  7661: 35620,
  7662: 35620,
  7663: 35620,
  7666: 35620,
  7670: 35620,
  7675: 35620,
  7676: 35620,
  7677: 35620,
  7699: 35620,
  7701: 35620,
  7702: 35620,
  7704: 35620,
  7710: 35620,
  7711: 35620,
  7712: 35620,
  7716: 35620,
  7717: 35620,
  7718: 35620,
  7719: 35620,
  7720: 35620,
  7721: 35620,
  7722: 35620,
  7723: 35620,
  7724: 35620,
  7726: 35620,
  7727: 35620,
  7728: 35620,
  7730: 35620,
  7731: 35620,
  7732: 35620,
  7733: 35620,
  7734: 35620,
  7735: 35620,
  7737: 35620,
  7738: 35620,
  7739: 35620,
  7740: 35620,
  7746: 35620,
  7747: 35620,
  7748: 35620,
  7750: 35620,
  7751: 35620,
  7752: 35620,
  7753: 35620,
  7754: 35620,
  7755: 35620,
  7756: 35620,
  7757: 35620,
  7758: 35620,
  7760: 35620,
  7762: 35620,
  7763: 35620,
  7764: 35620,
  7765: 35620,
  7799: 35620,
  7801: 35620,
  7802: 35620,
  7803: 35620,
  7806: 35620,
  7820: 10900,
  7821: 10900,
  7822: 35620,
  7823: 10900,
  7825: 35620,
  7826: 35620,
  7827: 35620,
  7828: 10900,
  7830: 35620,
  7831: 35620,
  7832: 10900,
  7833: 10900,
  7834: 35620,
  7836: 35620,
  7837: 35620,
  7838: 10900,
  7839: 35620,
  7840: 10900,
  7842: 35620,
  7843: 35620,
  7844: 10900,
  7845: 35620,
  7846: 10900,
  7847: 35620,
  7848: 35620,
  7849: 35620,
  7850: 35620,
  7851: 35620,
  7852: 35620,
  7853: 35620,
  7855: 35620,
  7856: 35620,
  7857: 35620,
  7860: 35620,
  7863: 10900,
  7865: 35620,
  7866: 35620,
  7869: 35620,
  7870: 35620,
  7871: 35620,
  7874: 10900,
  7875: 35620,
  7876: 35620,
  7877: 35620,
  7878: 35620,
  7879: 35620,
  7880: 10900,
  7882: 10900,
  7885: 35620,
  7890: 35620,
  7901: 35620,
  7902: 35620,
  7920: 35620,
  7921: 35620,
  7922: 35620,
  7924: 35620,
  7926: 35620,
  7927: 35620,
  7928: 35620,
  7930: 35620,
  7931: 35620,
  7932: 35620,
  7933: 35620,
  7934: 35620,
  7935: 35620,
  7936: 35620,
  7938: 35620,
  7939: 35620,
  7940: 35620,
  7945: 35620,
  7946: 35620,
  7950: 35620,
  7960: 35620,
  7961: 35620,
  7962: 35620,
  7963: 35620,
  7970: 35620,
  7974: 35620,
  7976: 35620,
  7977: 35620,
  7978: 35620,
  7979: 35620,
  7980: 35620,
  7981: 35620,
  8001: 37980,
  8002: 37980,
  8003: 37980,
  8004: 37980,
  8005: 35620,
  8006: 35620,
  8007: 37980,
  8008: 35620,
  8009: 37980,
  8010: 37980,
  8011: 37980,
  8012: 37980,
  8014: 37980,
  8015: 37980,
  8016: 37980,
  8018: 37980,
  8019: 37980,
  8020: 37980,
  8021: 37980,
  8022: 37980,
  8023: 37980,
  8025: 37980,
  8026: 37980,
  8027: 37980,
  8028: 37980,
  8029: 37980,
  8030: 37980,
  8031: 37980,
  8032: 37980,
  8033: 37980,
  8034: 37980,
  8035: 37980,
  8036: 37980,
  8037: 37980,
  8038: 37980,
  8039: 37980,
  8041: 37980,
  8042: 37980,
  8043: 37980,
  8045: 37980,
  8046: 37980,
  8048: 37980,
  8049: 37980,
  8050: 35620,
  8051: 37980,
  8052: 37980,
  8053: 37980,
  8054: 37980,
  8055: 37980,
  8056: 37980,
  8057: 37980,
  8059: 37980,
  8060: 37980,
  8061: 37980,
  8062: 37980,
  8063: 37980,
  8064: 37980,
  8065: 37980,
  8066: 37980,
  8067: 37980,
  8068: 37980,
  8069: 37980,
  8070: 37980,
  8071: 37980,
  8072: 37980,
  8073: 37980,
  8074: 37980,
  8075: 37980,
  8077: 37980,
  8078: 37980,
  8079: 37980,
  8080: 37980,
  8081: 37980,
  8083: 37980,
  8084: 37980,
  8085: 37980,
  8086: 37980,
  8087: 37980,
  8088: 37980,
  8089: 37980,
  8090: 37980,
  8091: 37980,
  8092: 35620,
  8093: 37980,
  8094: 12100,
  8095: 37980,
  8096: 37980,
  8097: 37980,
  8098: 37980,
  8099: 37980,
  8101: 37980,
  8102: 37980,
  8103: 37980,
  8104: 37980,
  8105: 37980,
  8106: 37980,
  8107: 37980,
  8108: 37980,
  8109: 37980,
  8110: 37980,
  8201: 12100,
  8202: 36140,
  8203: 12100,
  8204: 36140,
  8205: 12100,
  8210: 36140,
  8212: 36140,
  8213: 12100,
  8214: 36140,
  8215: 12100,
  8217: 12100,
  8218: 36140,
  8219: 36140,
  8220: 12100,
  8221: 12100,
  8223: 36140,
  8224: 37980,
  8225: 12100,
  8226: 36140,
  8230: 36140,
  8231: 12100,
  8232: 12100,
  8234: 12100,
  8240: 12100,
  8241: 12100,
  8242: 36140,
  8243: 36140,
  8244: 12100,
  8245: 36140,
  8246: 36140,
  8247: 36140,
  8248: 36140,
  8250: 36140,
  8251: 36140,
  8252: 36140,
  8260: 36140,
  8270: 47220,
  8302: 37980,
  8310: 12100,
  8311: 47220,
  8312: 37980,
  8313: 47220,
  8314: 47220,
  8315: 47220,
  8316: 47220,
  8317: 12100,
  8318: 37980,
  8319: 12100,
  8320: 47220,
  8321: 47220,
  8322: 37980,
  8323: 47220,
  8324: 47220,
  8326: 12100,
  8327: 47220,
  8328: 37980,
  8329: 47220,
  8330: 12100,
  8332: 47220,
  8340: 12100,
  8341: 12100,
  8342: 12100,
  8343: 37980,
  8344: 12100,
  8345: 47220,
  8346: 12100,
  8347: 37980,
  8348: 47220,
  8349: 47220,
  8350: 12100,
  8352: 47220,
  8353: 47220,
  8360: 37980,
  8361: 47220,
  8362: 47220,
  8401: 12100,
  8402: 12100,
  8403: 12100,
  8404: 12100,
  8405: 12100,
  8406: 12100,
  8501: 45940,
  8502: 35620,
  8504: 35620,
  8505: 37980,
  8510: 35620,
  8511: 37980,
  8512: 35620,
  8514: 35620,
  8515: 45940,
  8518: 37980,
  8520: 35620,
  8525: 35620,
  8527: 35620,
  8528: 35620,
  8530: 35620,
  8533: 35620,
  8534: 45940,
  8535: 35620,
  8536: 35620,
  8540: 35620,
  8542: 45940,
  8543: 45940,
  8544: 45940,
  8550: 45940,
  8551: 35620,
  8553: 35620,
  8554: 37980,
  8555: 35620,
  8556: 35620,
  8557: 35620,
  8558: 45940,
  8559: 35620,
  8560: 45940,
  8561: 45940,
  8562: 35620,
  8601: 45940,
  8602: 45940,
  8603: 45940,
  8604: 45940,
  8605: 45940,
  8606: 45940,
  8607: 45940,
  8608: 45940,
  8609: 45940,
  8610: 45940,
  8611: 45940,
  8618: 45940,
  8619: 45940,
  8620: 35620,
  8628: 45940,
  8629: 45940,
  8638: 45940,
  8640: 37980,
  8641: 37980,
  8648: 45940,
  8650: 45940,
  8690: 45940,
  8691: 35620,
  8701: 35620,
  8720: 35620,
  8721: 35620,
  8722: 35620,
  8723: 35620,
  8724: 35620,
  8730: 35620,
  8731: 35620,
  8732: 35620,
  8733: 35620,
  8734: 35620,
  8735: 35620,
  8736: 35620,
  8738: 35620,
  8739: 35620,
  8740: 35620,
  8741: 35620,
  8742: 35620,
  8750: 35620,
  8751: 35620,
  8752: 35620,
  8753: 35620,
  8754: 35620,
  8755: 35620,
  8757: 35620,
  8758: 35620,
  8759: 35620,
  8801: 35620,
  8802: 10900,
  8803: 35620,
  8804: 10900,
  8805: 35620,
  8807: 35620,
  8808: 10900,
  8809: 35620,
  8810: 35620,
  8812: 35620,
  8816: 35620,
  8817: 35620,
  8818: 35620,
  8820: 35620,
  8821: 35620,
  8822: 35620,
  8823: 35620,
  8824: 35620,
  8825: 35620,
  8826: 35620,
  8827: 35620,
  8828: 35620,
  8829: 35620,
  8830: 35620,
  8831: 35620,
  8832: 35620,
  8833: 35620,
  8835: 35620,
  8836: 35620,
  8837: 35620,
  8840: 35620,
  8844: 35620,
  8846: 35620,
  8848: 10900,
  8850: 35620,
  8852: 35620,
  8853: 35620,
  8854: 35620,
  8855: 35620,
  8857: 35620,
  8858: 35620,
  8859: 35620,
  8861: 35620,
  8862: 35620,
  8863: 35620,
  8865: 10900,
  8867: 35620,
  8868: 35620,
  8869: 35620,
  8870: 35620,
  8872: 35620,
  8873: 35620,
  8875: 35620,
  8876: 35620,
  8879: 35620,
  8880: 35620,
  8882: 35620,
  8884: 35620,
  8885: 35620,
  8886: 10900,
  8887: 35620,
  8888: 35620,
  8889: 35620,
  8899: 35620,
  8901: 35620,
  8902: 35620,
  8903: 35620,
  8904: 35620,
  8906: 35620,
  8933: 35620,
  10001: 35620,
  10002: 35620,
  10003: 35620,
  10004: 35620,
  10005: 35620,
  10006: 35620,
  10007: 35620,
  10008: 35620,
  10009: 35620,
  10010: 35620,
  10011: 35620,
  10012: 35620,
  10013: 35620,
  10014: 35620,
  10016: 35620,
  10017: 35620,
  10018: 35620,
  10019: 35620,
  10020: 35620,
  10021: 35620,
  10022: 35620,
  10023: 35620,
  10024: 35620,
  10025: 35620,
  10026: 35620,
  10027: 35620,
  10028: 35620,
  10029: 35620,
  10030: 35620,
  10031: 35620,
  10032: 35620,
  10033: 35620,
  10034: 35620,
  10035: 35620,
  10036: 35620,
  10037: 35620,
  10038: 35620,
  10039: 35620,
  10040: 35620,
  10041: 35620,
  10043: 35620,
  10044: 35620,
  10045: 35620,
  10055: 35620,
  10065: 35620,
  10069: 35620,
  10075: 35620,
  10080: 35620,
  10081: 35620,
  10101: 35620,
  10103: 35620,
  10104: 35620,
  10105: 35620,
  10106: 35620,
  10107: 35620,
  10108: 35620,
  10110: 35620,
  10111: 35620,
  10112: 35620,
  10113: 35620,
  10115: 35620,
  10116: 35620,
  10117: 35620,
  10118: 35620,
  10119: 35620,
  10120: 35620,
  10121: 35620,
  10122: 35620,
  10123: 35620,
  10124: 35620,
  10125: 35620,
  10126: 35620,
  10128: 35620,
  10129: 35620,
  10130: 35620,
  10131: 35620,
  10133: 35620,
  10138: 35620,
  10150: 35620,
  10151: 35620,
  10152: 35620,
  10153: 35620,
  10154: 35620,
  10155: 35620,
  10156: 35620,
  10157: 35620,
  10158: 35620,
  10159: 35620,
  10160: 35620,
  10162: 35620,
  10163: 35620,
  10164: 35620,
  10165: 35620,
  10166: 35620,
  10167: 35620,
  10168: 35620,
  10169: 35620,
  10170: 35620,
  10171: 35620,
  10172: 35620,
  10173: 35620,
  10174: 35620,
  10175: 35620,
  10176: 35620,
  10177: 35620,
  10178: 35620,
  10179: 35620,
  10185: 35620,
  10199: 35620,
  10203: 35620,
  10211: 35620,
  10212: 35620,
  10213: 35620,
  10258: 35620,
  10268: 35620,
  10269: 35620,
  10271: 35620,
  10272: 35620,
  10274: 35620,
  10275: 35620,
  10276: 35620,
  10277: 35620,
  10278: 35620,
  10279: 35620,
  10280: 35620,
  10281: 35620,
  10282: 35620,
  10285: 35620,
  10301: 35620,
  10302: 35620,
  10303: 35620,
  10304: 35620,
  10305: 35620,
  10306: 35620,
  10307: 35620,
  10308: 35620,
  10309: 35620,
  10310: 35620,
  10311: 35620,
  10312: 35620,
  10313: 35620,
  10314: 35620,
  10451: 35620,
  10452: 35620,
  10453: 35620,
  10454: 35620,
  10455: 35620,
  10456: 35620,
  10457: 35620,
  10458: 35620,
  10459: 35620,
  10460: 35620,
  10461: 35620,
  10462: 35620,
  10463: 35620,
  10464: 35620,
  10465: 35620,
  10466: 35620,
  10467: 35620,
  10468: 35620,
  10469: 35620,
  10470: 35620,
  10471: 35620,
  10472: 35620,
  10473: 35620,
  10474: 35620,
  10475: 35620,
  10501: 35620,
  10502: 35620,
  10503: 35620,
  10504: 35620,
  10505: 35620,
  10506: 35620,
  10507: 35620,
  10509: 35620,
  10510: 35620,
  10511: 35620,
  10512: 39100,
  10514: 35620,
  10516: 35620,
  10517: 35620,
  10518: 35620,
  10519: 35620,
  10520: 35620,
  10522: 35620,
  10523: 35620,
  10524: 35620,
  10526: 35620,
  10527: 35620,
  10528: 35620,
  10530: 35620,
  10532: 35620,
  10533: 35620,
  10535: 35620,
  10536: 35620,
  10537: 35620,
  10538: 35620,
  10540: 35620,
  10541: 35620,
  10542: 35620,
  10543: 35620,
  10545: 35620,
  10546: 35620,
  10547: 35620,
  10548: 35620,
  10549: 35620,
  10550: 35620,
  10551: 35620,
  10552: 35620,
  10553: 35620,
  10560: 35620,
  10562: 35620,
  10566: 35620,
  10567: 35620,
  10570: 35620,
  10573: 35620,
  10576: 35620,
  10577: 35620,
  10578: 35620,
  10579: 35620,
  10580: 35620,
  10583: 35620,
  10587: 35620,
  10588: 35620,
  10589: 35620,
  10590: 35620,
  10591: 35620,
  10594: 35620,
  10595: 35620,
  10596: 35620,
  10597: 35620,
  10598: 35620,
  10601: 35620,
  10602: 35620,
  10603: 35620,
  10604: 35620,
  10605: 35620,
  10606: 35620,
  10607: 35620,
  10610: 35620,
  10701: 35620,
  10702: 35620,
  10703: 35620,
  10704: 35620,
  10705: 35620,
  10706: 35620,
  10707: 35620,
  10708: 35620,
  10709: 35620,
  10710: 35620,
  10801: 35620,
  10802: 35620,
  10803: 35620,
  10804: 35620,
  10805: 35620,
  10901: 35620,
  10910: 39100,
  10911: 35620,
  10912: 39100,
  10913: 35620,
  10914: 39100,
  10915: 39100,
  10916: 39100,
  10917: 39100,
  10918: 39100,
  10919: 39100,
  10920: 35620,
  10921: 39100,
  10922: 39100,
  10923: 35620,
  10924: 39100,
  10925: 39100,
  10926: 39100,
  10927: 35620,
  10928: 39100,
  10930: 39100,
  10931: 35620,
  10932: 39100,
  10933: 39100,
  10940: 99999,
  10941: 39100,
  10949: 39100,
  10950: 39100,
  10952: 35620,
  10953: 39100,
  10954: 35620,
  10956: 35620,
  10958: 39100,
  10959: 39100,
  10960: 35620,
  10962: 35620,
  10963: 99999,
  10964: 35620,
  10965: 35620,
  10968: 35620,
  10969: 39100,
  10970: 35620,
  10973: 39100,
  10974: 35620,
  10975: 39100,
  10976: 35620,
  10977: 35620,
  10979: 39100,
  10980: 35620,
  10981: 39100,
  10982: 35620,
  10983: 35620,
  10984: 35620,
  10985: 39100,
  10986: 35620,
  10987: 35620,
  10988: 39100,
  10989: 35620,
  10990: 39100,
  10992: 39100,
  10993: 35620,
  10994: 35620,
  10996: 39100,
  10998: 39100,
  11001: 35620,
  11002: 35620,
  11003: 35620,
  11004: 35620,
  11005: 35620,
  11010: 35620,
  11020: 35620,
  11021: 35620,
  11022: 35620,
  11023: 35620,
  11024: 35620,
  11030: 35620,
  11040: 35620,
  11042: 35620,
  11050: 35620,
  11096: 35620,
  11101: 35620,
  11102: 35620,
  11103: 35620,
  11104: 35620,
  11105: 35620,
  11106: 35620,
  11109: 35620,
  11201: 35620,
  11202: 35620,
  11203: 35620,
  11204: 35620,
  11205: 35620,
  11206: 35620,
  11207: 35620,
  11208: 35620,
  11209: 35620,
  11210: 35620,
  11211: 35620,
  11212: 35620,
  11213: 35620,
  11214: 35620,
  11215: 35620,
  11216: 35620,
  11217: 35620,
  11218: 35620,
  11219: 35620,
  11220: 35620,
  11221: 35620,
  11222: 35620,
  11223: 35620,
  11224: 35620,
  11225: 35620,
  11226: 35620,
  11228: 35620,
  11229: 35620,
  11230: 35620,
  11231: 35620,
  11232: 35620,
  11233: 35620,
  11234: 35620,
  11235: 35620,
  11236: 35620,
  11237: 35620,
  11238: 35620,
  11239: 35620,
  11241: 35620,
  11242: 35620,
  11243: 35620,
  11245: 35620,
  11247: 35620,
  11249: 35620,
  11251: 35620,
  11252: 35620,
  11256: 35620,
  11351: 35620,
  11352: 35620,
  11354: 35620,
  11355: 35620,
  11356: 35620,
  11357: 35620,
  11358: 35620,
  11359: 35620,
  11360: 35620,
  11361: 35620,
  11362: 35620,
  11363: 35620,
  11364: 35620,
  11365: 35620,
  11366: 35620,
  11367: 35620,
  11368: 35620,
  11369: 35620,
  11370: 35620,
  11371: 35620,
  11372: 35620,
  11373: 35620,
  11374: 35620,
  11375: 35620,
  11377: 35620,
  11378: 35620,
  11379: 35620,
  11380: 35620,
  11381: 35620,
  11385: 35620,
  11386: 35620,
  11405: 35620,
  11411: 35620,
  11412: 35620,
  11413: 35620,
  11414: 35620,
  11415: 35620,
  11416: 35620,
  11417: 35620,
  11418: 35620,
  11419: 35620,
  11420: 35620,
  11421: 35620,
  11422: 35620,
  11423: 35620,
  11424: 35620,
  11425: 35620,
  11426: 35620,
  11427: 35620,
  11428: 35620,
  11429: 35620,
  11430: 35620,
  11431: 35620,
  11432: 35620,
  11433: 35620,
  11434: 35620,
  11435: 35620,
  11436: 35620,
  11437: 35620,
  11439: 35620,
  11451: 35620,
  11499: 35620,
  11501: 35620,
  11507: 35620,
  11509: 35620,
  11510: 35620,
  11514: 35620,
  11516: 35620,
  11518: 35620,
  11520: 35620,
  11530: 35620,
  11531: 35620,
  11542: 35620,
  11545: 35620,
  11547: 35620,
  11548: 35620,
  11549: 35620,
  11550: 35620,
  11551: 35620,
  11552: 35620,
  11553: 35620,
  11554: 35620,
  11556: 35620,
  11557: 35620,
  11558: 35620,
  11559: 35620,
  11560: 35620,
  11561: 35620,
  11563: 35620,
  11565: 35620,
  11566: 35620,
  11568: 35620,
  11569: 35620,
  11570: 35620,
  11571: 35620,
  11572: 35620,
  11575: 35620,
  11576: 35620,
  11577: 35620,
  11579: 35620,
  11580: 35620,
  11581: 35620,
  11582: 35620,
  11590: 35620,
  11596: 35620,
  11598: 35620,
  11599: 35620,
  11691: 35620,
  11692: 35620,
  11693: 35620,
  11694: 35620,
  11697: 35620,
  11701: 35620,
  11702: 35620,
  11703: 35620,
  11704: 35620,
  11705: 35620,
  11706: 35620,
  11709: 35620,
  11710: 35620,
  11713: 35620,
  11714: 35620,
  11715: 35620,
  11716: 35620,
  11717: 35620,
  11718: 35620,
  11719: 35620,
  11720: 35620,
  11721: 35620,
  11722: 35620,
  11724: 35620,
  11725: 35620,
  11726: 35620,
  11727: 35620,
  11729: 35620,
  11730: 35620,
  11731: 35620,
  11732: 35620,
  11733: 35620,
  11735: 35620,
  11738: 35620,
  11739: 35620,
  11740: 35620,
  11741: 35620,
  11742: 35620,
  11743: 35620,
  11746: 35620,
  11747: 35620,
  11749: 35620,
  11751: 35620,
  11752: 35620,
  11753: 35620,
  11754: 35620,
  11755: 35620,
  11756: 35620,
  11757: 35620,
  11758: 35620,
  11762: 35620,
  11763: 35620,
  11764: 35620,
  11765: 35620,
  11766: 35620,
  11767: 35620,
  11768: 35620,
  11769: 35620,
  11770: 35620,
  11771: 35620,
  11772: 35620,
  11773: 35620,
  11776: 35620,
  11777: 35620,
  11778: 35620,
  11779: 35620,
  11780: 35620,
  11782: 35620,
  11783: 35620,
  11784: 35620,
  11786: 35620,
  11787: 35620,
  11788: 35620,
  11789: 35620,
  11790: 35620,
  11791: 35620,
  11792: 35620,
  11793: 35620,
  11794: 35620,
  11795: 35620,
  11796: 35620,
  11797: 35620,
  11798: 35620,
  11801: 35620,
  11802: 35620,
  11803: 35620,
  11804: 35620,
  11815: 35620,
  11901: 35620,
  11930: 35620,
  11931: 35620,
  11932: 35620,
  11933: 35620,
  11934: 35620,
  11935: 35620,
  11937: 35620,
  11939: 35620,
  11940: 35620,
  11941: 35620,
  11942: 35620,
  11944: 35620,
  11946: 35620,
  11947: 35620,
  11948: 35620,
  11949: 35620,
  11950: 35620,
  11951: 35620,
  11952: 35620,
  11953: 35620,
  11954: 35620,
  11955: 35620,
  11956: 35620,
  11957: 35620,
  11958: 35620,
  11959: 35620,
  11960: 35620,
  11961: 35620,
  11962: 35620,
  11963: 35620,
  11964: 35620,
  11965: 35620,
  11967: 35620,
  11968: 35620,
  11969: 35620,
  11970: 35620,
  11971: 35620,
  11972: 35620,
  11973: 35620,
  11975: 35620,
  11976: 35620,
  11977: 35620,
  11978: 35620,
  11980: 35620,
  12007: 10580,
  12008: 10580,
  12009: 10580,
  12010: 11220,
  12015: 99999,
  12017: 26460,
  12018: 10580,
  12019: 10580,
  12020: 10580,
  12022: 10580,
  12023: 10580,
  12024: 10580,
  12025: 10580,
  12027: 10580,
  12028: 24020,
  12029: 26460,
  12031: 10580,
  12032: 24100,
  12033: 10580,
  12035: 10580,
  12036: 36580,
  12037: 26460,
  12040: 10580,
  12041: 10580,
  12042: 99999,
  12043: 36580,
  12045: 10580,
  12046: 99999,
  12047: 10580,
  12050: 26460,
  12051: 99999,
  12052: 10580,
  12053: 10580,
  12054: 10580,
  12055: 10580,
  12056: 10580,
  12057: 10580,
  12058: 99999,
  12059: 10580,
  12060: 26460,
  12061: 10580,
  12062: 26460,
  12063: 10580,
  12064: 10580,
  12065: 10580,
  12066: 11220,
  12067: 10580,
  12068: 11220,
  12069: 11220,
  12070: 11220,
  12071: 10580,
  12072: 11220,
  12073: 10580,
  12074: 10580,
  12075: 26460,
  12076: 10580,
  12077: 10580,
  12078: 24100,
  12082: 10580,
  12083: 99999,
  12084: 10580,
  12085: 10580,
  12086: 11220,
  12087: 99999,
  12089: 10580,
  12090: 10580,
  12092: 10580,
  12093: 99999,
  12094: 24020,
  12095: 24100,
  12106: 26460,
  12107: 10580,
  12108: 99999,
  12110: 10580,
  12115: 26460,
  12116: 36580,
  12117: 24100,
  12118: 10580,
  12120: 99999,
  12121: 10580,
  12122: 10580,
  12123: 26460,
  12124: 99999,
  12125: 26460,
  12128: 10580,
  12130: 26460,
  12131: 10580,
  12132: 26460,
  12133: 10580,
  12134: 24100,
  12136: 26460,
  12137: 10580,
  12138: 10580,
  12139: 99999,
  12140: 10580,
  12141: 10580,
  12143: 99999,
  12144: 10580,
  12147: 10580,
  12148: 10580,
  12149: 36580,
  12150: 10580,
  12151: 10580,
  12153: 10580,
  12154: 24020,
  12155: 36580,
  12156: 10580,
  12157: 10580,
  12158: 10580,
  12159: 10580,
  12160: 10580,
  12161: 10580,
  12164: 99999,
  12165: 26460,
  12166: 11220,
  12167: 99999,
  12168: 26460,
  12169: 10580,
  12170: 10580,
  12172: 26460,
  12173: 26460,
  12174: 26460,
  12175: 10580,
  12176: 99999,
  12177: 11220,
  12180: 10580,
  12181: 10580,
  12182: 10580,
  12183: 10580,
  12184: 26460,
  12185: 24020,
  12186: 10580,
  12187: 10580,
  12188: 10580,
  12189: 10580,
  12190: 99999,
  12192: 99999,
  12193: 10580,
  12194: 10580,
  12195: 26460,
  12196: 10580,
  12197: 36580,
  12198: 10580,
  12201: 10580,
  12202: 10580,
  12203: 10580,
  12204: 10580,
  12205: 10580,
  12206: 10580,
  12207: 10580,
  12208: 10580,
  12209: 10580,
  12210: 10580,
  12211: 10580,
  12212: 10580,
  12220: 10580,
  12222: 10580,
  12223: 10580,
  12224: 10580,
  12226: 10580,
  12227: 10580,
  12232: 10580,
  12233: 10580,
  12234: 10580,
  12235: 10580,
  12236: 10580,
  12237: 10580,
  12239: 10580,
  12240: 10580,
  12245: 10580,
  12246: 10580,
  12247: 10580,
  12248: 10580,
  12249: 10580,
  12255: 10580,
  12257: 10580,
  12260: 10580,
  12288: 10580,
  12301: 10580,
  12302: 10580,
  12303: 10580,
  12304: 10580,
  12305: 10580,
  12306: 10580,
  12307: 10580,
  12308: 10580,
  12309: 10580,
  12345: 10580,
  12401: 28740,
  12402: 28740,
  12404: 28740,
  12405: 99999,
  12406: 99999,
  12407: 99999,
  12409: 28740,
  12410: 28740,
  12411: 28740,
  12412: 28740,
  12413: 99999,
  12414: 99999,
  12416: 28740,
  12417: 28740,
  12418: 99999,
  12419: 28740,
  12420: 28740,
  12421: 99999,
  12422: 99999,
  12423: 99999,
  12424: 99999,
  12427: 99999,
  12428: 28740,
  12429: 28740,
  12430: 99999,
  12431: 99999,
  12432: 28740,
  12433: 28740,
  12434: 99999,
  12435: 99999,
  12436: 99999,
  12438: 99999,
  12439: 99999,
  12440: 28740,
  12441: 28740,
  12442: 99999,
  12443: 28740,
  12444: 99999,
  12446: 28740,
  12448: 28740,
  12449: 28740,
  12450: 99999,
  12451: 99999,
  12452: 99999,
  12453: 28740,
  12454: 99999,
  12455: 28740,
  12456: 28740,
  12457: 28740,
  12458: 28740,
  12459: 99999,
  12460: 99999,
  12461: 28740,
  12463: 28740,
  12464: 28740,
  12465: 99999,
  12466: 28740,
  12468: 99999,
  12469: 99999,
  12470: 99999,
  12471: 28740,
  12472: 28740,
  12473: 99999,
  12474: 99999,
  12475: 28740,
  12477: 28740,
  12480: 28740,
  12481: 28740,
  12482: 99999,
  12483: 28740,
  12484: 28740,
  12485: 99999,
  12486: 28740,
  12487: 28740,
  12489: 28740,
  12490: 28740,
  12491: 28740,
  12492: 99999,
  12493: 28740,
  12494: 28740,
  12495: 28740,
  12496: 99999,
  12498: 28740,
  12501: 39100,
  12502: 26460,
  12503: 26460,
  12504: 39100,
  12506: 39100,
  12507: 39100,
  12508: 39100,
  12510: 39100,
  12511: 39100,
  12512: 39100,
  12513: 26460,
  12514: 39100,
  12515: 28740,
  12516: 26460,
  12517: 26460,
  12518: 39100,
  12520: 39100,
  12521: 26460,
  12522: 39100,
  12523: 26460,
  12524: 35620,
  12525: 28740,
  12526: 26460,
  12527: 39100,
  12528: 28740,
  12529: 26460,
  12530: 26460,
  12531: 39100,
  12533: 35620,
  12534: 26460,
  12537: 39100,
  12538: 39100,
  12540: 39100,
  12541: 26460,
  12542: 39100,
  12543: 39100,
  12544: 26460,
  12545: 39100,
  12546: 39100,
  12547: 28740,
  12548: 28740,
  12549: 39100,
  12550: 28740,
  12551: 39100,
  12552: 39100,
  12553: 39100,
  12555: 39100,
  12561: 28740,
  12563: 35620,
  12564: 39100,
  12565: 26460,
  12566: 28740,
  12567: 39100,
  12568: 28740,
  12569: 39100,
  12570: 39100,
  12571: 39100,
  12572: 39100,
  12574: 39100,
  12575: 39100,
  12577: 39100,
  12578: 39100,
  12580: 39100,
  12581: 39100,
  12582: 39100,
  12583: 26460,
  12584: 39100,
  12585: 39100,
  12586: 39100,
  12588: 28740,
  12589: 28740,
  12590: 39100,
  12592: 39100,
  12594: 39100,
  12601: 39100,
  12602: 39100,
  12603: 39100,
  12604: 39100,
  12701: 99999,
  12719: 99999,
  12720: 99999,
  12721: 39100,
  12722: 99999,
  12723: 99999,
  12724: 99999,
  12725: 28740,
  12726: 99999,
  12729: 99999,
  12732: 99999,
  12733: 99999,
  12734: 99999,
  12736: 99999,
  12737: 99999,
  12738: 99999,
  12740: 28740,
  12741: 99999,
  12742: 99999,
  12743: 99999,
  12745: 99999,
  12746: 39100,
  12747: 99999,
  12748: 99999,
  12749: 99999,
  12750: 99999,
  12751: 99999,
  12752: 99999,
  12754: 99999,
  12758: 28740,
  12759: 99999,
  12760: 99999,
  12762: 99999,
  12763: 28740,
  12764: 99999,
  12765: 99999,
  12766: 99999,
  12767: 99999,
  12768: 99999,
  12769: 99999,
  12770: 99999,
  12771: 39100,
  12775: 99999,
  12776: 99999,
  12777: 99999,
  12778: 99999,
  12779: 99999,
  12780: 99999,
  12781: 99999,
  12783: 99999,
  12784: 99999,
  12785: 39100,
  12786: 99999,
  12787: 99999,
  12788: 99999,
  12789: 99999,
  12790: 99999,
  12791: 99999,
  12792: 99999,
  12801: 24020,
  12803: 10580,
  12804: 24020,
  12808: 24020,
  12809: 24020,
  12810: 24020,
  12811: 24020,
  12812: 99999,
  12814: 24020,
  12815: 24020,
  12816: 24020,
  12817: 24020,
  12819: 24020,
  12820: 24020,
  12821: 24020,
  12822: 10580,
  12823: 24020,
  12824: 24020,
  12827: 24020,
  12828: 24020,
  12831: 10580,
  12832: 24020,
  12833: 10580,
  12834: 24020,
  12835: 10580,
  12836: 24020,
  12837: 24020,
  12838: 24020,
  12839: 24020,
  12841: 24020,
  12842: 99999,
  12843: 24020,
  12844: 24020,
  12845: 24020,
  12846: 24020,
  12847: 99999,
  12848: 24020,
  12849: 24020,
  12850: 10580,
  12851: 99999,
  12852: 99999,
  12853: 99999,
  12854: 24020,
  12855: 99999,
  12856: 24020,
  12857: 99999,
  12858: 99999,
  12859: 10580,
  12860: 24020,
  12861: 99999,
  12862: 24020,
  12863: 10580,
  12864: 99999,
  12865: 24020,
  12866: 10580,
  12870: 24020,
  12871: 10580,
  12872: 99999,
  12873: 24020,
  12874: 24020,
  12878: 24020,
  12879: 99999,
  12883: 24020,
  12884: 10580,
  12885: 24020,
  12886: 24020,
  12887: 24020,
  12901: 38460,
  12903: 38460,
  12910: 38460,
  12911: 38460,
  12912: 31660,
  12913: 31660,
  12914: 36300,
  12915: 31660,
  12916: 31660,
  12917: 31660,
  12918: 38460,
  12919: 38460,
  12920: 38460,
  12921: 38460,
  12922: 36300,
  12923: 38460,
  12924: 38460,
  12926: 31660,
  12927: 36300,
  12928: 99999,
  12929: 38460,
  12930: 36300,
  12932: 99999,
  12933: 38460,
  12934: 38460,
  12935: 38460,
  12936: 99999,
  12937: 31660,
  12939: 31660,
  12941: 99999,
  12942: 99999,
  12943: 99999,
  12944: 38460,
  12945: 31660,
  12946: 99999,
  12949: 36300,
  12950: 99999,
  12952: 38460,
  12953: 31660,
  12955: 38460,
  12956: 99999,
  12957: 31660,
  12958: 38460,
  12959: 38460,
  12960: 99999,
  12961: 99999,
  12962: 38460,
  12964: 99999,
  12965: 36300,
  12966: 31660,
  12967: 36300,
  12969: 31660,
  12970: 31660,
  12972: 38460,
  12973: 36300,
  12974: 99999,
  12975: 99999,
  12976: 31660,
  12977: 99999,
  12978: 38460,
  12979: 38460,
  12980: 31660,
  12981: 38460,
  12983: 99999,
  12985: 38460,
  12986: 31660,
  12987: 99999,
  12989: 31660,
  12992: 38460,
  12993: 99999,
  12995: 31660,
  12996: 99999,
  12997: 38460,
  12998: 99999,
  13020: 45060,
  13021: 12180,
  13022: 12180,
  13024: 12180,
  13026: 12180,
  13027: 12180,
  13028: 45060,
  13029: 45060,
  13030: 45060,
  13031: 45060,
  13032: 46540,
  13033: 45060,
  13034: 12180,
  13035: 45060,
  13036: 45060,
  13037: 45060,
  13039: 45060,
  13040: 99999,
  13041: 45060,
  13042: 45060,
  13043: 45060,
  13044: 45060,
  13045: 18660,
  13051: 45060,
  13052: 18660,
  13053: 27060,
  13054: 46540,
  13057: 45060,
  13060: 45060,
  13061: 45060,
  13062: 27060,
  13063: 45060,
  13064: 12180,
  13065: 42900,
  13066: 45060,
  13068: 27060,
  13069: 45060,
  13071: 12180,
  13072: 99999,
  13073: 12180,
  13074: 45060,
  13076: 45060,
  13077: 45060,
  13078: 45060,
  13080: 45060,
  13081: 27060,
  13082: 45060,
  13083: 48060,
  13084: 45060,
  13087: 18660,
  13088: 45060,
  13089: 45060,
  13090: 45060,
  13092: 12180,
  13093: 45060,
  13101: 18660,
  13102: 27060,
  13103: 45060,
  13104: 45060,
  13108: 45060,
  13110: 45060,
  13111: 12180,
  13112: 45060,
  13113: 12180,
  13114: 45060,
  13115: 45060,
  13116: 45060,
  13117: 12180,
  13118: 12180,
  13119: 45060,
  13120: 45060,
  13121: 45060,
  13122: 45060,
  13123: 46540,
  13124: 99999,
  13126: 45060,
  13131: 45060,
  13132: 45060,
  13134: 45060,
  13135: 45060,
  13136: 99999,
  13137: 45060,
  13138: 45060,
  13139: 12180,
  13140: 12180,
  13141: 18660,
  13142: 45060,
  13143: 12180,
  13144: 45060,
  13145: 45060,
  13146: 12180,
  13147: 12180,
  13148: 42900,
  13152: 12180,
  13153: 45060,
  13154: 40380,
  13155: 99999,
  13156: 45060,
  13157: 46540,
  13158: 18660,
  13159: 45060,
  13160: 12180,
  13162: 46540,
  13163: 45060,
  13164: 45060,
  13165: 40380,
  13166: 45060,
  13167: 45060,
  13201: 45060,
  13202: 45060,
  13203: 45060,
  13204: 45060,
  13205: 45060,
  13206: 45060,
  13207: 45060,
  13208: 45060,
  13209: 45060,
  13210: 45060,
  13211: 45060,
  13212: 45060,
  13214: 45060,
  13215: 45060,
  13217: 45060,
  13218: 45060,
  13219: 45060,
  13220: 45060,
  13224: 45060,
  13225: 45060,
  13235: 45060,
  13244: 45060,
  13252: 45060,
  13261: 45060,
  13290: 45060,
  13301: 46540,
  13302: 45060,
  13303: 46540,
  13304: 46540,
  13305: 99999,
  13308: 46540,
  13309: 46540,
  13310: 45060,
  13312: 99999,
  13313: 46540,
  13314: 45060,
  13315: 36580,
  13316: 45060,
  13317: 36580,
  13318: 46540,
  13319: 46540,
  13320: 11220,
  13321: 46540,
  13322: 46540,
  13323: 46540,
  13324: 46540,
  13325: 99999,
  13326: 36580,
  13327: 99999,
  13328: 46540,
  13329: 24100,
  13331: 99999,
  13332: 99999,
  13333: 36580,
  13334: 45060,
  13335: 36580,
  13337: 36580,
  13338: 46540,
  13339: 24100,
  13340: 46540,
  13341: 46540,
  13342: 36580,
  13343: 99999,
  13345: 99999,
  13346: 45060,
  13348: 36580,
  13350: 46540,
  13352: 46540,
  13353: 99999,
  13354: 46540,
  13355: 45060,
  13357: 46540,
  13360: 46540,
  13361: 36580,
  13362: 46540,
  13363: 46540,
  13364: 45060,
  13365: 46540,
  13367: 99999,
  13368: 99999,
  13401: 46540,
  13402: 46540,
  13403: 46540,
  13404: 99999,
  13406: 46540,
  13407: 46540,
  13408: 45060,
  13409: 46540,
  13410: 11220,
  13411: 99999,
  13413: 46540,
  13415: 36580,
  13416: 46540,
  13417: 46540,
  13418: 45060,
  13420: 46540,
  13421: 45060,
  13424: 46540,
  13425: 45060,
  13426: 45060,
  13428: 11220,
  13431: 46540,
  13433: 99999,
  13435: 46540,
  13436: 99999,
  13437: 99999,
  13438: 46540,
  13439: 36580,
  13440: 46540,
  13441: 46540,
  13442: 46540,
  13450: 36580,
  13452: 24100,
  13454: 46540,
  13455: 46540,
  13456: 46540,
  13457: 36580,
  13459: 10580,
  13460: 45060,
  13461: 46540,
  13464: 45060,
  13468: 36580,
  13469: 46540,
  13470: 24100,
  13471: 46540,
  13472: 46540,
  13473: 99999,
  13475: 46540,
  13476: 46540,
  13477: 46540,
  13478: 46540,
  13479: 46540,
  13480: 45060,
  13482: 36580,
  13483: 46540,
  13484: 45060,
  13485: 99999,
  13486: 46540,
  13488: 36580,
  13489: 46540,
  13490: 46540,
  13491: 46540,
  13492: 46540,
  13493: 99999,
  13494: 46540,
  13495: 46540,
  13501: 46540,
  13502: 46540,
  13503: 46540,
  13504: 46540,
  13505: 46540,
  13601: 48060,
  13602: 48060,
  13603: 48060,
  13605: 48060,
  13606: 48060,
  13607: 48060,
  13608: 48060,
  13611: 48060,
  13612: 48060,
  13613: 36300,
  13614: 36300,
  13615: 48060,
  13616: 48060,
  13617: 36300,
  13618: 48060,
  13619: 99999,
  13620: 99999,
  13621: 36300,
  13622: 48060,
  13623: 36300,
  13624: 48060,
  13625: 36300,
  13626: 48060,
  13628: 48060,
  13630: 36300,
  13631: 48060,
  13632: 48060,
  13633: 36300,
  13634: 48060,
  13635: 36300,
  13636: 48060,
  13637: 48060,
  13638: 48060,
  13639: 36300,
  13640: 48060,
  13641: 48060,
  13642: 36300,
  13643: 48060,
  13645: 36300,
  13646: 48060,
  13647: 36300,
  13648: 36300,
  13649: 36300,
  13650: 48060,
  13651: 48060,
  13652: 36300,
  13654: 36300,
  13655: 31660,
  13656: 48060,
  13658: 36300,
  13659: 48060,
  13660: 36300,
  13661: 45060,
  13662: 36300,
  13664: 36300,
  13665: 99999,
  13666: 36300,
  13667: 36300,
  13668: 36300,
  13669: 36300,
  13670: 36300,
  13671: 48060,
  13672: 36300,
  13673: 48060,
  13674: 48060,
  13675: 48060,
  13676: 36300,
  13677: 36300,
  13678: 36300,
  13679: 48060,
  13680: 36300,
  13681: 36300,
  13682: 99999,
  13683: 31660,
  13684: 36300,
  13685: 48060,
  13687: 36300,
  13690: 36300,
  13691: 48060,
  13692: 48060,
  13693: 48060,
  13694: 36300,
  13695: 36300,
  13696: 36300,
  13697: 36300,
  13699: 36300,
  13730: 13780,
  13731: 28740,
  13732: 13780,
  13733: 99999,
  13734: 13780,
  13736: 27060,
  13737: 13780,
  13738: 18660,
  13739: 99999,
  13740: 99999,
  13743: 13780,
  13744: 13780,
  13745: 13780,
  13746: 99999,
  13747: 36580,
  13748: 13780,
  13749: 13780,
  13750: 99999,
  13751: 99999,
  13752: 99999,
  13753: 99999,
  13754: 99999,
  13755: 99999,
  13756: 99999,
  13757: 99999,
  13758: 99999,
  13760: 13780,
  13761: 13780,
  13762: 13780,
  13774: 99999,
  13775: 36580,
  13776: 36580,
  13777: 13780,
  13778: 13780,
  13780: 99999,
  13782: 99999,
  13783: 13780,
  13784: 18660,
  13786: 99999,
  13787: 99999,
  13788: 99999,
  13790: 13780,
  13794: 13780,
  13795: 13780,
  13796: 36580,
  13797: 13780,
  13801: 99999,
  13802: 13780,
  13803: 13780,
  13804: 99999,
  13806: 99999,
  13807: 36580,
  13808: 36580,
  13809: 36580,
  13810: 36580,
  13811: 13780,
  13812: 13780,
  13813: 99999,
  13814: 99999,
  13815: 99999,
  13820: 99999,
  13825: 99999,
  13826: 13780,
  13827: 13780,
  13830: 99999,
  13832: 99999,
  13833: 13780,
  13834: 36580,
  13835: 13780,
  13838: 36580,
  13839: 99999,
  13840: 13780,
  13841: 18660,
  13842: 99999,
  13843: 99999,
  13844: 99999,
  13845: 13780,
  13846: 99999,
  13847: 99999,
  13848: 13780,
  13849: 99999,
  13850: 13780,
  13851: 13780,
  13856: 99999,
  13859: 36580,
  13860: 99999,
  13861: 36580,
  13862: 13780,
  13863: 18660,
  13864: 27060,
  13865: 13780,
  13901: 13780,
  13902: 13780,
  13903: 13780,
  13904: 13780,
  13905: 13780,
  14001: 12860,
  14004: 12860,
  14005: 12860,
  14006: 15380,
  14008: 15380,
  14009: 99999,
  14010: 15380,
  14011: 12860,
  14012: 40380,
  14013: 15380,
  14020: 12860,
  14021: 12860,
  14024: 99999,
  14025: 15380,
  14026: 15380,
  14027: 15380,
  14028: 15380,
  14029: 99999,
  14030: 15380,
  14031: 15380,
  14032: 15380,
  14033: 15380,
  14034: 15380,
  14035: 15380,
  14036: 15380,
  14037: 15380,
  14038: 15380,
  14039: 99999,
  14040: 12860,
  14041: 36460,
  14042: 36460,
  14043: 15380,
  14047: 15380,
  14048: 27460,
  14051: 15380,
  14052: 15380,
  14054: 12860,
  14055: 15380,
  14056: 12860,
  14057: 15380,
  14058: 12860,
  14059: 15380,
  14060: 99999,
  14061: 15380,
  14062: 36460,
  14063: 27460,
  14065: 99999,
  14066: 99999,
  14067: 15380,
  14068: 15380,
  14069: 15380,
  14070: 36460,
  14072: 15380,
  14075: 15380,
  14080: 15380,
  14081: 36460,
  14082: 99999,
  14083: 99999,
  14085: 15380,
  14086: 15380,
  14091: 15380,
  14092: 15380,
  14094: 15380,
  14095: 15380,
  14098: 40380,
  14101: 36460,
  14102: 15380,
  14103: 12860,
  14105: 40380,
  14107: 15380,
  14108: 15380,
  14109: 15380,
  14110: 15380,
  14111: 15380,
  14112: 15380,
  14113: 99999,
  14120: 15380,
  14125: 12860,
  14126: 15380,
  14127: 15380,
  14129: 36460,
  14130: 99999,
  14131: 15380,
  14132: 15380,
  14134: 15380,
  14135: 27460,
  14136: 27460,
  14138: 36460,
  14139: 15380,
  14140: 15380,
  14141: 36460,
  14143: 12860,
  14144: 15380,
  14145: 99999,
  14150: 15380,
  14151: 15380,
  14166: 27460,
  14167: 99999,
  14168: 36460,
  14169: 15380,
  14170: 15380,
  14171: 36460,
  14172: 15380,
  14173: 15380,
  14174: 15380,
  14201: 15380,
  14202: 15380,
  14203: 15380,
  14204: 15380,
  14205: 15380,
  14206: 15380,
  14207: 15380,
  14208: 15380,
  14209: 15380,
  14210: 15380,
  14211: 15380,
  14212: 15380,
  14213: 15380,
  14214: 15380,
  14215: 15380,
  14216: 15380,
  14217: 15380,
  14218: 15380,
  14219: 15380,
  14220: 15380,
  14221: 15380,
  14222: 15380,
  14223: 15380,
  14224: 15380,
  14225: 15380,
  14226: 15380,
  14227: 15380,
  14228: 15380,
  14231: 15380,
  14233: 15380,
  14240: 15380,
  14241: 15380,
  14260: 15380,
  14263: 15380,
  14265: 15380,
  14269: 15380,
  14270: 15380,
  14272: 15380,
  14273: 15380,
  14276: 15380,
  14280: 15380,
  14301: 15380,
  14302: 15380,
  14303: 15380,
  14304: 15380,
  14305: 15380,
  14410: 40380,
  14411: 40380,
  14413: 40380,
  14414: 40380,
  14415: 40380,
  14416: 40380,
  14418: 40380,
  14420: 40380,
  14422: 40380,
  14423: 40380,
  14424: 40380,
  14425: 40380,
  14427: 99999,
  14428: 40380,
  14429: 40380,
  14430: 40380,
  14432: 40380,
  14433: 40380,
  14435: 40380,
  14437: 99999,
  14441: 40380,
  14445: 40380,
  14449: 40380,
  14450: 40380,
  14452: 40380,
  14453: 40380,
  14454: 40380,
  14456: 42900,
  14461: 40380,
  14462: 40380,
  14463: 40380,
  14464: 40380,
  14466: 40380,
  14467: 40380,
  14468: 40380,
  14469: 40380,
  14470: 40380,
  14471: 40380,
  14472: 40380,
  14475: 40380,
  14476: 40380,
  14477: 40380,
  14478: 40380,
  14479: 40380,
  14480: 40380,
  14481: 99999,
  14482: 40380,
  14485: 40380,
  14486: 40380,
  14487: 40380,
  14488: 40380,
  14489: 42900,
  14502: 40380,
  14504: 40380,
  14505: 40380,
  14506: 40380,
  14507: 40380,
  14508: 40380,
  14510: 40380,
  14511: 40380,
  14512: 18500,
  14513: 40380,
  14514: 40380,
  14515: 40380,
  14516: 40380,
  14517: 40380,
  14518: 40380,
  14519: 40380,
  14520: 40380,
  14521: 42900,
  14522: 40380,
  14525: 12860,
  14526: 40380,
  14527: 40380,
  14529: 18500,
  14530: 99999,
  14532: 42900,
  14533: 99999,
  14534: 40380,
  14536: 99999,
  14537: 40380,
  14538: 40380,
  14539: 40380,
  14541: 42900,
  14542: 40380,
  14543: 40380,
  14544: 40380,
  14545: 40380,
  14546: 40380,
  14547: 40380,
  14548: 40380,
  14549: 99999,
  14550: 99999,
  14551: 40380,
  14555: 40380,
  14556: 40380,
  14557: 12860,
  14558: 40380,
  14559: 40380,
  14560: 40380,
  14561: 40380,
  14563: 40380,
  14564: 40380,
  14568: 40380,
  14569: 99999,
  14571: 40380,
  14572: 40380,
  14580: 40380,
  14585: 40380,
  14586: 40380,
  14588: 42900,
  14589: 40380,
  14590: 40380,
  14591: 12860,
  14592: 40380,
  14602: 40380,
  14603: 40380,
  14604: 40380,
  14605: 40380,
  14606: 40380,
  14607: 40380,
  14608: 40380,
  14609: 40380,
  14610: 40380,
  14611: 40380,
  14612: 40380,
  14613: 40380,
  14614: 40380,
  14615: 40380,
  14616: 40380,
  14617: 40380,
  14618: 40380,
  14619: 40380,
  14620: 40380,
  14621: 40380,
  14622: 40380,
  14623: 40380,
  14624: 40380,
  14625: 40380,
  14626: 40380,
  14627: 40380,
  14638: 40380,
  14639: 40380,
  14642: 40380,
  14643: 40380,
  14646: 40380,
  14647: 40380,
  14649: 40380,
  14650: 40380,
  14651: 40380,
  14652: 40380,
  14653: 40380,
  14692: 40380,
  14701: 27460,
  14702: 27460,
  14706: 36460,
  14707: 99999,
  14708: 99999,
  14709: 99999,
  14710: 27460,
  14711: 99999,
  14712: 27460,
  14714: 99999,
  14715: 99999,
  14716: 27460,
  14717: 99999,
  14718: 27460,
  14719: 36460,
  14720: 27460,
  14721: 99999,
  14722: 27460,
  14723: 27460,
  14724: 27460,
  14726: 36460,
  14727: 36460,
  14728: 27460,
  14729: 36460,
  14730: 36460,
  14731: 36460,
  14732: 27460,
  14733: 27460,
  14735: 99999,
  14736: 27460,
  14737: 99999,
  14738: 27460,
  14739: 99999,
  14740: 27460,
  14741: 36460,
  14742: 27460,
  14743: 36460,
  14744: 99999,
  14745: 99999,
  14747: 36460,
  14748: 36460,
  14750: 27460,
  14751: 36460,
  14752: 27460,
  14753: 36460,
  14754: 99999,
  14755: 36460,
  14757: 27460,
  14758: 27460,
  14760: 36460,
  14766: 36460,
  14767: 27460,
  14769: 27460,
  14770: 36460,
  14772: 36460,
  14774: 99999,
  14775: 27460,
  14777: 99999,
  14778: 36460,
  14779: 36460,
  14781: 27460,
  14782: 27460,
  14783: 36460,
  14784: 27460,
  14785: 27460,
  14786: 99999,
  14787: 27460,
  14788: 36460,
  14801: 18500,
  14802: 99999,
  14803: 99999,
  14804: 18500,
  14805: 21300,
  14806: 18500,
  14807: 18500,
  14808: 18500,
  14809: 18500,
  14810: 18500,
  14812: 18500,
  14813: 99999,
  14814: 18500,
  14815: 99999,
  14816: 21300,
  14817: 27060,
  14818: 99999,
  14819: 18500,
  14820: 18500,
  14821: 18500,
  14822: 99999,
  14823: 18500,
  14824: 21300,
  14825: 21300,
  14826: 18500,
  14827: 18500,
  14830: 21300,
  14831: 18500,
  14836: 40380,
  14837: 99999,
  14838: 99999,
  14839: 18500,
  14840: 18500,
  14841: 42900,
  14842: 40380,
  14843: 99999,
  14845: 21300,
  14846: 40380,
  14847: 42900,
  14850: 27060,
  14851: 27060,
  14852: 27060,
  14853: 27060,
  14854: 27060,
  14855: 18500,
  14856: 18500,
  14857: 40380,
  14858: 18500,
  14859: 21300,
  14860: 42900,
  14861: 21300,
  14863: 99999,
  14864: 99999,
  14865: 99999,
  14867: 27060,
  14869: 99999,
  14870: 99999,
  14871: 21300,
  14872: 21300,
  14873: 40380,
  14874: 18500,
  14876: 99999,
  14877: 18500,
  14878: 40380,
  14879: 18500,
  14880: 99999,
  14881: 27060,
  14882: 12180,
  14883: 21300,
  14884: 99999,
  14885: 18500,
  14886: 27060,
  14887: 99999,
  14889: 99999,
  14891: 99999,
  14892: 21300,
  14893: 99999,
  14894: 21300,
  14895: 99999,
  14897: 18500,
  14898: 18500,
  14901: 21300,
  14902: 21300,
  14903: 21300,
  14904: 21300,
  14905: 21300,
  15001: 38300,
  15003: 38300,
  15004: 38300,
  15005: 38300,
  15006: 38300,
  15007: 38300,
  15009: 38300,
  15010: 38300,
  15012: 38300,
  15014: 38300,
  15015: 38300,
  15017: 38300,
  15018: 38300,
  15019: 38300,
  15020: 38300,
  15021: 38300,
  15022: 38300,
  15024: 38300,
  15025: 38300,
  15026: 38300,
  15027: 38300,
  15028: 38300,
  15030: 38300,
  15031: 38300,
  15032: 38300,
  15033: 38300,
  15034: 38300,
  15035: 38300,
  15037: 38300,
  15038: 38300,
  15042: 38300,
  15043: 38300,
  15044: 38300,
  15045: 38300,
  15046: 38300,
  15047: 38300,
  15049: 38300,
  15050: 38300,
  15051: 38300,
  15052: 38300,
  15053: 38300,
  15054: 38300,
  15055: 38300,
  15056: 38300,
  15057: 38300,
  15059: 38300,
  15060: 38300,
  15061: 38300,
  15062: 38300,
  15063: 38300,
  15064: 38300,
  15065: 38300,
  15066: 38300,
  15067: 38300,
  15068: 38300,
  15071: 38300,
  15072: 38300,
  15074: 38300,
  15075: 38300,
  15076: 38300,
  15077: 38300,
  15078: 38300,
  15081: 38300,
  15082: 38300,
  15083: 38300,
  15084: 38300,
  15085: 38300,
  15086: 38300,
  15087: 38300,
  15088: 38300,
  15089: 38300,
  15090: 38300,
  15091: 38300,
  15095: 38300,
  15096: 38300,
  15101: 38300,
  15102: 38300,
  15104: 38300,
  15106: 38300,
  15108: 38300,
  15110: 38300,
  15112: 38300,
  15116: 38300,
  15120: 38300,
  15122: 38300,
  15123: 38300,
  15126: 38300,
  15127: 38300,
  15129: 38300,
  15131: 38300,
  15132: 38300,
  15133: 38300,
  15134: 38300,
  15135: 38300,
  15136: 38300,
  15137: 38300,
  15139: 38300,
  15140: 38300,
  15142: 38300,
  15143: 38300,
  15144: 38300,
  15145: 38300,
  15146: 38300,
  15147: 38300,
  15148: 38300,
  15201: 38300,
  15202: 38300,
  15203: 38300,
  15204: 38300,
  15205: 38300,
  15206: 38300,
  15207: 38300,
  15208: 38300,
  15209: 38300,
  15210: 38300,
  15211: 38300,
  15212: 38300,
  15213: 38300,
  15214: 38300,
  15215: 38300,
  15216: 38300,
  15217: 38300,
  15218: 38300,
  15219: 38300,
  15220: 38300,
  15221: 38300,
  15222: 38300,
  15223: 38300,
  15224: 38300,
  15225: 38300,
  15226: 38300,
  15227: 38300,
  15228: 38300,
  15229: 38300,
  15230: 38300,
  15231: 38300,
  15232: 38300,
  15233: 38300,
  15234: 38300,
  15235: 38300,
  15236: 38300,
  15237: 38300,
  15238: 38300,
  15239: 38300,
  15240: 38300,
  15241: 38300,
  15243: 38300,
  15244: 38300,
  15253: 38300,
  15258: 38300,
  15259: 38300,
  15260: 38300,
  15261: 38300,
  15262: 38300,
  15265: 38300,
  15267: 38300,
  15272: 38300,
  15275: 38300,
  15276: 38300,
  15277: 38300,
  15282: 38300,
  15289: 38300,
  15290: 38300,
  15295: 38300,
  15301: 38300,
  15310: 99999,
  15311: 38300,
  15312: 38300,
  15313: 38300,
  15314: 38300,
  15315: 99999,
  15316: 99999,
  15317: 38300,
  15320: 38300,
  15321: 38300,
  15322: 99999,
  15323: 38300,
  15324: 38300,
  15325: 99999,
  15327: 99999,
  15329: 38300,
  15330: 38300,
  15331: 38300,
  15332: 38300,
  15333: 38300,
  15334: 99999,
  15336: 38300,
  15337: 99999,
  15338: 99999,
  15339: 38300,
  15340: 38300,
  15341: 99999,
  15342: 38300,
  15344: 99999,
  15345: 38300,
  15346: 99999,
  15347: 38300,
  15348: 38300,
  15349: 99999,
  15350: 38300,
  15351: 99999,
  15352: 99999,
  15353: 99999,
  15357: 99999,
  15358: 38300,
  15359: 99999,
  15360: 38300,
  15361: 38300,
  15362: 99999,
  15363: 38300,
  15364: 99999,
  15365: 38300,
  15366: 38300,
  15367: 38300,
  15368: 38300,
  15370: 38300,
  15376: 38300,
  15377: 99999,
  15378: 38300,
  15379: 38300,
  15380: 99999,
  15401: 38300,
  15410: 38300,
  15411: 43740,
  15412: 38300,
  15413: 38300,
  15415: 38300,
  15416: 38300,
  15417: 38300,
  15419: 38300,
  15420: 38300,
  15421: 38300,
  15422: 38300,
  15423: 38300,
  15424: 43740,
  15425: 38300,
  15427: 38300,
  15428: 38300,
  15429: 38300,
  15430: 38300,
  15431: 38300,
  15432: 38300,
  15433: 38300,
  15434: 38300,
  15435: 38300,
  15436: 38300,
  15437: 38300,
  15438: 38300,
  15439: 38300,
  15440: 38300,
  15442: 38300,
  15443: 38300,
  15444: 38300,
  15445: 38300,
  15446: 38300,
  15447: 38300,
  15448: 38300,
  15449: 38300,
  15450: 38300,
  15451: 38300,
  15454: 38300,
  15455: 38300,
  15456: 38300,
  15458: 38300,
  15459: 38300,
  15460: 38300,
  15461: 99999,
  15462: 38300,
  15463: 38300,
  15464: 38300,
  15465: 38300,
  15466: 38300,
  15467: 38300,
  15468: 38300,
  15469: 38300,
  15470: 38300,
  15472: 38300,
  15473: 38300,
  15474: 38300,
  15475: 38300,
  15476: 38300,
  15477: 38300,
  15478: 38300,
  15479: 38300,
  15480: 38300,
  15482: 38300,
  15483: 38300,
  15484: 38300,
  15485: 43740,
  15486: 38300,
  15488: 38300,
  15489: 38300,
  15490: 38300,
  15492: 38300,
  15501: 38300,
  15502: 43740,
  15510: 43740,
  15520: 43740,
  15521: 99999,
  15522: 99999,
  15530: 99999,
  15531: 43740,
  15532: 43740,
  15533: 99999,
  15534: 43740,
  15535: 99999,
  15536: 99999,
  15537: 99999,
  15538: 43740,
  15539: 99999,
  15540: 43740,
  15541: 43740,
  15542: 43740,
  15544: 43740,
  15545: 99999,
  15546: 43740,
  15547: 43740,
  15548: 43740,
  15549: 43740,
  15550: 99999,
  15551: 43740,
  15552: 43740,
  15553: 43740,
  15554: 99999,
  15555: 43740,
  15557: 43740,
  15558: 43740,
  15559: 43740,
  15560: 43740,
  15561: 43740,
  15562: 43740,
  15563: 43740,
  15564: 43740,
  15565: 43740,
  15601: 38300,
  15606: 38300,
  15610: 38300,
  15611: 38300,
  15612: 38300,
  15613: 38300,
  15615: 38300,
  15616: 38300,
  15617: 38300,
  15618: 38300,
  15619: 38300,
  15620: 38300,
  15621: 38300,
  15622: 43740,
  15623: 38300,
  15624: 38300,
  15625: 38300,
  15626: 38300,
  15627: 38300,
  15628: 38300,
  15629: 38300,
  15631: 38300,
  15632: 38300,
  15633: 38300,
  15634: 38300,
  15635: 38300,
  15636: 38300,
  15637: 38300,
  15638: 38300,
  15639: 38300,
  15640: 38300,
  15641: 38300,
  15642: 38300,
  15644: 38300,
  15646: 38300,
  15647: 38300,
  15650: 38300,
  15655: 38300,
  15656: 38300,
  15658: 38300,
  15660: 38300,
  15661: 38300,
  15662: 38300,
  15663: 38300,
  15664: 38300,
  15665: 38300,
  15666: 38300,
  15668: 38300,
  15670: 38300,
  15671: 38300,
  15672: 38300,
  15673: 38300,
  15674: 38300,
  15675: 38300,
  15676: 38300,
  15677: 38300,
  15678: 38300,
  15679: 38300,
  15680: 38300,
  15681: 38300,
  15682: 38300,
  15683: 38300,
  15684: 38300,
  15685: 38300,
  15686: 38300,
  15687: 38300,
  15688: 38300,
  15689: 38300,
  15690: 38300,
  15691: 38300,
  15692: 38300,
  15693: 38300,
  15695: 38300,
  15696: 38300,
  15697: 38300,
  15698: 38300,
  15701: 26860,
  15705: 26860,
  15710: 26860,
  15711: 99999,
  15712: 26860,
  15713: 26860,
  15714: 26860,
  15715: 99999,
  15716: 26860,
  15717: 38300,
  15720: 26860,
  15721: 20180,
  15722: 27780,
  15723: 26860,
  15724: 26860,
  15725: 26860,
  15727: 26860,
  15728: 26860,
  15729: 26860,
  15730: 99999,
  15731: 26860,
  15732: 26860,
  15733: 99999,
  15734: 26860,
  15736: 38300,
  15737: 27780,
  15738: 27780,
  15739: 26860,
  15741: 26860,
  15742: 20180,
  15744: 99999,
  15745: 26860,
  15746: 26860,
  15747: 26860,
  15748: 26860,
  15750: 26860,
  15752: 26860,
  15753: 20180,
  15754: 26860,
  15756: 26860,
  15757: 26860,
  15758: 26860,
  15759: 26860,
  15760: 27780,
  15761: 26860,
  15762: 27780,
  15763: 99999,
  15764: 99999,
  15765: 26860,
  15767: 20180,
  15770: 99999,
  15771: 26860,
  15772: 99999,
  15773: 27780,
  15774: 38300,
  15775: 27780,
  15776: 99999,
  15777: 26860,
  15778: 99999,
  15779: 38300,
  15780: 99999,
  15781: 99999,
  15783: 26860,
  15784: 99999,
  15801: 20180,
  15821: 41260,
  15822: 41260,
  15823: 20180,
  15824: 99999,
  15825: 99999,
  15827: 41260,
  15828: 41260,
  15829: 99999,
  15831: 41260,
  15832: 41260,
  15834: 99999,
  15840: 20180,
  15841: 41260,
  15845: 41260,
  15846: 41260,
  15847: 99999,
  15848: 20180,
  15849: 20180,
  15851: 20180,
  15853: 99999,
  15856: 20180,
  15857: 41260,
  15860: 41260,
  15861: 99999,
  15863: 99999,
  15864: 99999,
  15865: 99999,
  15866: 20180,
  15868: 41260,
  15870: 14620,
  15901: 27780,
  15902: 27780,
  15904: 43740,
  15905: 43740,
  15906: 27780,
  15907: 27780,
  15909: 27780,
  15915: 27780,
  15920: 26860,
  15921: 27780,
  15922: 27780,
  15923: 38300,
  15924: 43740,
  15925: 27780,
  15926: 43740,
  15927: 27780,
  15928: 43740,
  15929: 26860,
  15930: 27780,
  15931: 27780,
  15934: 27780,
  15935: 43740,
  15936: 43740,
  15937: 43740,
  15938: 27780,
  15940: 27780,
  15942: 27780,
  15943: 27780,
  15944: 38300,
  15945: 27780,
  15946: 27780,
  15948: 27780,
  15949: 26860,
  15951: 27780,
  15952: 27780,
  15953: 43740,
  15954: 38300,
  15955: 27780,
  15956: 27780,
  15957: 27780,
  15958: 27780,
  15959: 43740,
  15960: 27780,
  15961: 27780,
  15962: 27780,
  15963: 27780,
  16001: 38300,
  16002: 38300,
  16003: 38300,
  16020: 38300,
  16022: 38300,
  16023: 38300,
  16024: 38300,
  16025: 38300,
  16027: 38300,
  16028: 38300,
  16029: 38300,
  16030: 38300,
  16033: 38300,
  16034: 38300,
  16035: 38300,
  16036: 99999,
  16037: 38300,
  16038: 49660,
  16039: 38300,
  16040: 38300,
  16041: 38300,
  16045: 38300,
  16046: 38300,
  16048: 38300,
  16049: 38300,
  16050: 38300,
  16051: 35260,
  16052: 38300,
  16053: 38300,
  16054: 99999,
  16055: 38300,
  16056: 38300,
  16057: 35260,
  16058: 99999,
  16059: 38300,
  16061: 38300,
  16063: 38300,
  16066: 38300,
  16101: 35260,
  16102: 35260,
  16105: 35260,
  16108: 35260,
  16110: 32740,
  16111: 32740,
  16112: 35260,
  16113: 49660,
  16114: 49660,
  16115: 38300,
  16116: 35260,
  16117: 38300,
  16120: 38300,
  16121: 49660,
  16123: 35260,
  16124: 49660,
  16125: 49660,
  16127: 49660,
  16130: 49660,
  16131: 32740,
  16132: 35260,
  16133: 49660,
  16134: 49660,
  16136: 38300,
  16137: 49660,
  16140: 35260,
  16141: 35260,
  16142: 49660,
  16143: 35260,
  16145: 49660,
  16146: 49660,
  16148: 49660,
  16150: 49660,
  16151: 49660,
  16153: 49660,
  16154: 49660,
  16155: 35260,
  16156: 35260,
  16157: 38300,
  16159: 35260,
  16160: 35260,
  16161: 49660,
  16172: 35260,
  16201: 38300,
  16210: 38300,
  16211: 26860,
  16212: 38300,
  16213: 99999,
  16214: 99999,
  16217: 99999,
  16218: 38300,
  16220: 99999,
  16221: 99999,
  16222: 99999,
  16223: 99999,
  16224: 99999,
  16225: 99999,
  16226: 38300,
  16228: 38300,
  16229: 38300,
  16230: 99999,
  16232: 99999,
  16233: 99999,
  16234: 99999,
  16235: 99999,
  16236: 38300,
  16238: 38300,
  16239: 99999,
  16240: 99999,
  16242: 38300,
  16244: 38300,
  16245: 38300,
  16246: 26860,
  16248: 99999,
  16249: 38300,
  16250: 38300,
  16253: 38300,
  16254: 99999,
  16255: 99999,
  16256: 26860,
  16258: 99999,
  16259: 38300,
  16260: 99999,
  16262: 38300,
  16263: 38300,
  16301: 36340,
  16311: 49660,
  16312: 47620,
  16313: 47620,
  16314: 32740,
  16316: 32740,
  16317: 36340,
  16319: 36340,
  16321: 99999,
  16322: 99999,
  16323: 36340,
  16326: 99999,
  16327: 36340,
  16328: 32740,
  16329: 47620,
  16331: 99999,
  16332: 99999,
  16333: 14620,
  16334: 99999,
  16335: 32740,
  16340: 47620,
  16341: 99999,
  16342: 49660,
  16343: 36340,
  16344: 36340,
  16345: 47620,
  16346: 36340,
  16347: 99999,
  16350: 47620,
  16351: 99999,
  16352: 47620,
  16353: 36340,
  16354: 47620,
  16360: 32740,
  16361: 99999,
  16362: 49660,
  16364: 99999,
  16365: 47620,
  16366: 47620,
  16367: 47620,
  16368: 47620,
  16369: 47620,
  16370: 99999,
  16371: 47620,
  16372: 36340,
  16373: 38300,
  16374: 36340,
  16375: 99999,
  16388: 32740,
  16401: 32740,
  16402: 47620,
  16403: 21500,
  16404: 32740,
  16405: 47620,
  16406: 32740,
  16407: 47620,
  16410: 21500,
  16411: 21500,
  16412: 32740,
  16413: 21500,
  16415: 21500,
  16416: 47620,
  16417: 21500,
  16420: 32740,
  16421: 21500,
  16422: 32740,
  16423: 21500,
  16424: 32740,
  16426: 21500,
  16427: 21500,
  16428: 21500,
  16430: 21500,
  16433: 32740,
  16434: 47620,
  16435: 21500,
  16436: 47620,
  16438: 32740,
  16440: 32740,
  16441: 32740,
  16442: 21500,
  16443: 21500,
  16444: 21500,
  16475: 21500,
  16501: 21500,
  16502: 21500,
  16503: 21500,
  16504: 21500,
  16505: 21500,
  16506: 21500,
  16507: 21500,
  16508: 21500,
  16509: 21500,
  16510: 21500,
  16511: 21500,
  16512: 21500,
  16514: 21500,
  16515: 21500,
  16530: 21500,
  16534: 21500,
  16541: 21500,
  16544: 21500,
  16546: 21500,
  16550: 21500,
  16563: 21500,
  16565: 21500,
  16601: 27780,
  16602: 11020,
  16603: 11020,
  16611: 11020,
  16613: 11020,
  16616: 20180,
  16617: 11020,
  16619: 27780,
  16620: 20180,
  16621: 26500,
  16622: 26500,
  16623: 26500,
  16624: 27780,
  16625: 99999,
  16627: 27780,
  16629: 11020,
  16630: 27780,
  16631: 11020,
  16633: 99999,
  16634: 26500,
  16635: 27780,
  16636: 27780,
  16637: 11020,
  16638: 26500,
  16639: 27780,
  16640: 27780,
  16641: 11020,
  16644: 27780,
  16645: 20180,
  16646: 20180,
  16647: 26500,
  16648: 11020,
  16650: 99999,
  16651: 20180,
  16652: 26500,
  16654: 26500,
  16655: 99999,
  16656: 20180,
  16657: 99999,
  16659: 99999,
  16660: 26500,
  16661: 20180,
  16662: 99999,
  16663: 20180,
  16664: 99999,
  16665: 11020,
  16666: 44300,
  16667: 99999,
  16668: 27780,
  16669: 26500,
  16670: 99999,
  16671: 20180,
  16672: 99999,
  16673: 11020,
  16674: 26500,
  16675: 27780,
  16677: 44300,
  16678: 26500,
  16679: 26500,
  16680: 20180,
  16681: 20180,
  16682: 11020,
  16683: 26500,
  16684: 11020,
  16685: 26500,
  16686: 11020,
  16689: 99999,
  16691: 99999,
  16692: 20180,
  16693: 11020,
  16694: 26500,
  16695: 99999,
  16701: 14620,
  16720: 99999,
  16724: 14620,
  16725: 14620,
  16726: 14620,
  16727: 14620,
  16729: 14620,
  16730: 14620,
  16731: 14620,
  16732: 14620,
  16733: 14620,
  16734: 41260,
  16735: 41260,
  16738: 14620,
  16740: 14620,
  16743: 14620,
  16744: 14620,
  16745: 14620,
  16746: 99999,
  16748: 14620,
  16749: 14620,
  16750: 14620,
  16801: 44300,
  16802: 44300,
  16803: 44300,
  16804: 44300,
  16820: 44300,
  16821: 20180,
  16822: 44300,
  16823: 44300,
  16825: 20180,
  16826: 44300,
  16827: 44300,
  16828: 44300,
  16829: 44300,
  16830: 20180,
  16832: 44300,
  16833: 20180,
  16834: 20180,
  16835: 44300,
  16836: 20180,
  16837: 20180,
  16838: 20180,
  16839: 20180,
  16840: 20180,
  16841: 44300,
  16843: 20180,
  16844: 44300,
  16845: 20180,
  16847: 20180,
  16848: 30820,
  16849: 20180,
  16850: 20180,
  16851: 44300,
  16852: 44300,
  16853: 44300,
  16854: 44300,
  16855: 20180,
  16856: 44300,
  16858: 20180,
  16859: 44300,
  16860: 20180,
  16861: 20180,
  16863: 20180,
  16864: 44300,
  16865: 44300,
  16866: 20180,
  16868: 44300,
  16870: 11020,
  16871: 20180,
  16872: 44300,
  16873: 20180,
  16874: 44300,
  16875: 44300,
  16876: 20180,
  16877: 44300,
  16878: 20180,
  16879: 20180,
  16881: 20180,
  16882: 44300,
  16901: 99999,
  16910: 42380,
  16911: 99999,
  16912: 99999,
  16914: 42380,
  16915: 99999,
  16917: 99999,
  16920: 99999,
  16921: 99999,
  16922: 99999,
  16923: 99999,
  16925: 42380,
  16926: 42380,
  16927: 99999,
  16928: 99999,
  16929: 99999,
  16930: 48700,
  16932: 99999,
  16933: 99999,
  16935: 99999,
  16936: 99999,
  16937: 99999,
  16938: 99999,
  16939: 99999,
  16940: 99999,
  16941: 99999,
  16942: 99999,
  16943: 99999,
  16945: 42380,
  16946: 99999,
  16947: 42380,
  16948: 99999,
  16950: 99999,
  17001: 25420,
  17002: 26500,
  17003: 30140,
  17004: 30380,
  17005: 25420,
  17006: 25420,
  17007: 25420,
  17009: 30380,
  17010: 30140,
  17011: 25420,
  17013: 25420,
  17014: 99999,
  17015: 25420,
  17016: 30140,
  17017: 25420,
  17018: 25420,
  17019: 23900,
  17020: 25420,
  17021: 26500,
  17022: 25420,
  17023: 25420,
  17024: 25420,
  17025: 25420,
  17026: 30140,
  17027: 25420,
  17028: 25420,
  17029: 30380,
  17030: 25420,
  17032: 25420,
  17033: 30140,
  17034: 25420,
  17035: 25420,
  17036: 25420,
  17037: 25420,
  17038: 30140,
  17039: 30140,
  17040: 25420,
  17041: 30140,
  17042: 30140,
  17043: 25420,
  17044: 30380,
  17045: 25420,
  17046: 30140,
  17047: 25420,
  17048: 25420,
  17049: 99999,
  17050: 25420,
  17051: 26500,
  17052: 26500,
  17053: 25420,
  17054: 30380,
  17055: 25420,
  17056: 99999,
  17057: 25420,
  17058: 99999,
  17059: 30380,
  17060: 30380,
  17061: 25420,
  17062: 25420,
  17063: 42780,
  17064: 30140,
  17065: 25420,
  17066: 26500,
  17067: 39740,
  17068: 25420,
  17069: 25420,
  17070: 49620,
  17072: 25420,
  17073: 39740,
  17074: 25420,
  17075: 30380,
  17076: 99999,
  17077: 30140,
  17078: 25420,
  17080: 25420,
  17081: 25420,
  17082: 99999,
  17083: 30140,
  17084: 30380,
  17085: 30140,
  17086: 99999,
  17087: 30140,
  17088: 30140,
  17089: 25420,
  17090: 25420,
  17093: 25420,
  17094: 99999,
  17097: 25420,
  17098: 25420,
  17099: 30380,
  17101: 25420,
  17102: 25420,
  17103: 25420,
  17104: 25420,
  17105: 25420,
  17106: 25420,
  17107: 25420,
  17108: 25420,
  17109: 25420,
  17110: 25420,
  17111: 25420,
  17112: 25420,
  17113: 25420,
  17120: 25420,
  17121: 25420,
  17122: 25420,
  17123: 25420,
  17124: 25420,
  17125: 25420,
  17126: 25420,
  17127: 25420,
  17128: 25420,
  17129: 25420,
  17130: 25420,
  17177: 25420,
  17201: 16540,
  17202: 16540,
  17210: 16540,
  17211: 99999,
  17212: 99999,
  17213: 26500,
  17214: 16540,
  17215: 99999,
  17217: 16540,
  17219: 16540,
  17220: 16540,
  17221: 16540,
  17222: 23900,
  17223: 99999,
  17224: 99999,
  17225: 16540,
  17228: 99999,
  17229: 26500,
  17231: 16540,
  17232: 16540,
  17233: 99999,
  17235: 16540,
  17236: 16540,
  17237: 16540,
  17238: 99999,
  17239: 26500,
  17240: 16540,
  17241: 25420,
  17243: 26500,
  17244: 16540,
  17246: 16540,
  17247: 16540,
  17249: 26500,
  17250: 16540,
  17251: 16540,
  17252: 16540,
  17253: 26500,
  17254: 16540,
  17255: 26500,
  17256: 16540,
  17257: 16540,
  17260: 26500,
  17261: 16540,
  17262: 16540,
  17263: 16540,
  17264: 99999,
  17265: 16540,
  17266: 25420,
  17267: 99999,
  17268: 16540,
  17271: 16540,
  17272: 16540,
  17301: 23900,
  17302: 49620,
  17303: 23900,
  17304: 23900,
  17306: 23900,
  17307: 23900,
  17309: 49620,
  17310: 23900,
  17311: 49620,
  17312: 49620,
  17313: 49620,
  17314: 49620,
  17315: 49620,
  17316: 23900,
  17317: 49620,
  17318: 49620,
  17319: 49620,
  17320: 23900,
  17321: 49620,
  17322: 49620,
  17323: 49620,
  17324: 25420,
  17325: 23900,
  17327: 49620,
  17329: 49620,
  17331: 49620,
  17337: 23900,
  17339: 49620,
  17340: 23900,
  17342: 49620,
  17343: 23900,
  17344: 23900,
  17345: 49620,
  17347: 49620,
  17349: 49620,
  17350: 23900,
  17352: 49620,
  17353: 23900,
  17355: 49620,
  17356: 49620,
  17358: 49620,
  17360: 49620,
  17361: 49620,
  17362: 49620,
  17363: 49620,
  17364: 49620,
  17365: 49620,
  17366: 49620,
  17368: 49620,
  17370: 49620,
  17371: 49620,
  17372: 49620,
  17375: 23900,
  17401: 49620,
  17402: 49620,
  17403: 49620,
  17404: 49620,
  17405: 49620,
  17406: 49620,
  17407: 49620,
  17408: 49620,
  17501: 29540,
  17502: 29540,
  17503: 29540,
  17504: 29540,
  17505: 29540,
  17506: 29540,
  17507: 29540,
  17508: 29540,
  17509: 29540,
  17512: 29540,
  17516: 29540,
  17517: 29540,
  17518: 29540,
  17519: 29540,
  17520: 29540,
  17521: 29540,
  17522: 29540,
  17527: 37980,
  17528: 29540,
  17529: 29540,
  17532: 29540,
  17533: 29540,
  17534: 29540,
  17535: 29540,
  17536: 29540,
  17537: 29540,
  17538: 29540,
  17540: 29540,
  17543: 29540,
  17545: 30140,
  17547: 29540,
  17550: 29540,
  17551: 29540,
  17552: 29540,
  17554: 29540,
  17555: 29540,
  17557: 29540,
  17560: 29540,
  17562: 29540,
  17563: 29540,
  17564: 29540,
  17565: 29540,
  17566: 29540,
  17567: 29540,
  17568: 29540,
  17569: 29540,
  17570: 29540,
  17572: 29540,
  17575: 29540,
  17576: 29540,
  17578: 29540,
  17579: 29540,
  17580: 29540,
  17581: 29540,
  17582: 29540,
  17584: 29540,
  17585: 29540,
  17601: 29540,
  17602: 29540,
  17603: 29540,
  17604: 29540,
  17605: 29540,
  17608: 29540,
  17699: 29540,
  17701: 48700,
  17702: 48700,
  17703: 48700,
  17705: 48700,
  17720: 48700,
  17721: 30820,
  17723: 48700,
  17724: 48700,
  17726: 30820,
  17727: 48700,
  17728: 48700,
  17729: 30820,
  17730: 44980,
  17731: 99999,
  17735: 42380,
  17737: 48700,
  17739: 48700,
  17740: 48700,
  17742: 48700,
  17744: 48700,
  17745: 48700,
  17747: 30820,
  17748: 30820,
  17749: 44980,
  17750: 30820,
  17751: 30820,
  17752: 48700,
  17754: 48700,
  17756: 14100,
  17758: 99999,
  17760: 30820,
  17762: 48700,
  17763: 48700,
  17764: 30820,
  17765: 99999,
  17768: 99999,
  17769: 48700,
  17771: 48700,
  17772: 14100,
  17774: 14100,
  17776: 48700,
  17777: 44980,
  17778: 30820,
  17779: 30820,
  17801: 44980,
  17810: 48700,
  17812: 42780,
  17813: 42780,
  17814: 99999,
  17815: 42540,
  17820: 44980,
  17821: 14100,
  17822: 14100,
  17823: 39060,
  17824: 44980,
  17827: 42780,
  17829: 30260,
  17830: 44980,
  17831: 42780,
  17832: 44980,
  17833: 42780,
  17834: 44980,
  17835: 30260,
  17836: 44980,
  17837: 30260,
  17840: 44980,
  17841: 42780,
  17842: 42780,
  17843: 42780,
  17844: 30260,
  17845: 30260,
  17846: 48700,
  17847: 14100,
  17850: 44980,
  17851: 44980,
  17853: 42780,
  17855: 30260,
  17856: 30260,
  17857: 44980,
  17858: 14100,
  17859: 14100,
  17860: 44980,
  17861: 42780,
  17862: 42780,
  17864: 42780,
  17865: 44980,
  17866: 44980,
  17867: 44980,
  17868: 44980,
  17870: 42780,
  17872: 44980,
  17876: 42780,
  17877: 44980,
  17878: 42540,
  17880: 30260,
  17881: 44980,
  17882: 42780,
  17884: 14100,
  17885: 30260,
  17886: 30260,
  17887: 30260,
  17888: 14100,
  17889: 42780,
  17901: 39060,
  17920: 14100,
  17921: 14100,
  17922: 39060,
  17923: 39060,
  17925: 39060,
  17929: 39060,
  17930: 39060,
  17931: 39060,
  17932: 39060,
  17933: 39060,
  17934: 39060,
  17935: 39060,
  17936: 39060,
  17938: 39060,
  17941: 44980,
  17943: 39060,
  17944: 39060,
  17945: 39060,
  17946: 39060,
  17948: 39060,
  17949: 39060,
  17951: 39060,
  17952: 39060,
  17953: 39060,
  17954: 39060,
  17957: 39060,
  17959: 39060,
  17960: 39060,
  17961: 39060,
  17963: 30140,
  17964: 44980,
  17965: 39060,
  17966: 39060,
  17967: 14100,
  17968: 39060,
  17970: 39060,
  17972: 39060,
  17974: 39060,
  17976: 39060,
  17978: 25420,
  17979: 39060,
  17980: 39060,
  17981: 39060,
  17982: 39060,
  17983: 39060,
  17985: 14100,
  18002: 10900,
  18011: 39740,
  18012: 10900,
  18013: 10900,
  18014: 10900,
  18015: 10900,
  18016: 10900,
  18017: 10900,
  18018: 10900,
  18020: 10900,
  18030: 10900,
  18031: 10900,
  18032: 10900,
  18034: 10900,
  18035: 10900,
  18036: 37980,
  18037: 10900,
  18038: 10900,
  18039: 37980,
  18040: 10900,
  18041: 10900,
  18042: 37980,
  18043: 10900,
  18044: 10900,
  18045: 10900,
  18046: 10900,
  18049: 10900,
  18051: 10900,
  18052: 10900,
  18053: 10900,
  18054: 37980,
  18055: 37980,
  18056: 39740,
  18058: 20700,
  18059: 10900,
  18060: 10900,
  18062: 39740,
  18063: 10900,
  18064: 10900,
  18065: 10900,
  18066: 10900,
  18067: 10900,
  18068: 10900,
  18069: 10900,
  18070: 37980,
  18071: 20700,
  18072: 10900,
  18073: 37980,
  18074: 37980,
  18076: 37980,
  18077: 37980,
  18078: 10900,
  18079: 10900,
  18080: 10900,
  18081: 37980,
  18083: 10900,
  18084: 37980,
  18085: 10900,
  18086: 10900,
  18087: 10900,
  18088: 10900,
  18091: 10900,
  18092: 10900,
  18101: 10900,
  18102: 10900,
  18103: 10900,
  18104: 10900,
  18105: 10900,
  18106: 10900,
  18109: 10900,
  18195: 10900,
  18201: 10900,
  18202: 39060,
  18210: 20700,
  18211: 10900,
  18212: 10900,
  18214: 39060,
  18216: 42540,
  18218: 39060,
  18219: 42540,
  18220: 39060,
  18221: 42540,
  18222: 42540,
  18223: 42540,
  18224: 10900,
  18225: 42540,
  18229: 10900,
  18230: 10900,
  18231: 39060,
  18232: 10900,
  18234: 42540,
  18235: 10900,
  18237: 10900,
  18239: 42540,
  18240: 10900,
  18241: 39060,
  18242: 39060,
  18244: 10900,
  18245: 39060,
  18246: 42540,
  18247: 42540,
  18248: 39060,
  18249: 42540,
  18250: 10900,
  18251: 42540,
  18252: 10900,
  18254: 10900,
  18255: 42540,
  18256: 42540,
  18301: 20700,
  18302: 20700,
  18320: 20700,
  18321: 20700,
  18322: 20700,
  18323: 20700,
  18324: 35620,
  18325: 35620,
  18326: 35620,
  18327: 20700,
  18328: 35620,
  18330: 20700,
  18331: 20700,
  18332: 20700,
  18333: 20700,
  18334: 20700,
  18335: 20700,
  18336: 35620,
  18337: 35620,
  18340: 35620,
  18341: 20700,
  18342: 20700,
  18343: 10900,
  18344: 20700,
  18346: 20700,
  18347: 20700,
  18348: 20700,
  18349: 20700,
  18350: 20700,
  18351: 10900,
  18352: 20700,
  18353: 20700,
  18354: 20700,
  18355: 20700,
  18356: 20700,
  18357: 20700,
  18360: 20700,
  18370: 20700,
  18371: 35620,
  18372: 20700,
  18403: 42540,
  18405: 35620,
  18407: 99999,
  18410: 42540,
  18411: 42540,
  18413: 99999,
  18414: 42540,
  18415: 99999,
  18416: 42540,
  18417: 99999,
  18419: 99999,
  18420: 42540,
  18421: 99999,
  18424: 20700,
  18425: 35620,
  18426: 35620,
  18427: 99999,
  18428: 35620,
  18430: 99999,
  18431: 99999,
  18433: 42540,
  18434: 42540,
  18435: 35620,
  18436: 42540,
  18437: 99999,
  18438: 99999,
  18439: 99999,
  18440: 42540,
  18441: 99999,
  18443: 99999,
  18444: 42540,
  18445: 99999,
  18446: 99999,
  18447: 42540,
  18448: 42540,
  18449: 99999,
  18451: 35620,
  18452: 42540,
  18453: 99999,
  18454: 99999,
  18455: 99999,
  18456: 99999,
  18457: 35620,
  18458: 35620,
  18459: 99999,
  18460: 99999,
  18461: 99999,
  18462: 99999,
  18463: 99999,
  18464: 35620,
  18465: 99999,
  18466: 20700,
  18469: 99999,
  18470: 99999,
  18471: 42540,
  18472: 42540,
  18473: 99999,
  18501: 42540,
  18503: 42540,
  18504: 42540,
  18505: 42540,
  18507: 42540,
  18508: 42540,
  18509: 42540,
  18510: 42540,
  18512: 42540,
  18515: 42540,
  18517: 42540,
  18518: 42540,
  18519: 42540,
  18540: 42540,
  18601: 42540,
  18602: 42540,
  18603: 42540,
  18610: 20700,
  18611: 42540,
  18612: 42540,
  18614: 42540,
  18615: 42540,
  18616: 42380,
  18617: 42540,
  18618: 42540,
  18619: 99999,
  18621: 42540,
  18622: 42540,
  18623: 99999,
  18624: 10900,
  18625: 42540,
  18626: 99999,
  18627: 42540,
  18628: 99999,
  18629: 99999,
  18630: 99999,
  18631: 14100,
  18632: 99999,
  18634: 42540,
  18635: 14100,
  18636: 42540,
  18640: 42540,
  18641: 42540,
  18642: 42540,
  18643: 42540,
  18644: 42540,
  18651: 42540,
  18653: 42540,
  18654: 42540,
  18655: 42540,
  18656: 42540,
  18657: 42540,
  18660: 42540,
  18661: 10900,
  18701: 42540,
  18702: 42540,
  18703: 42540,
  18704: 42540,
  18705: 42540,
  18706: 42540,
  18707: 42540,
  18708: 42540,
  18709: 42540,
  18710: 42540,
  18711: 42540,
  18762: 42540,
  18764: 42540,
  18765: 42540,
  18766: 42540,
  18769: 42540,
  18801: 99999,
  18810: 42380,
  18812: 99999,
  18813: 99999,
  18814: 42380,
  18816: 99999,
  18817: 42380,
  18818: 99999,
  18820: 99999,
  18821: 99999,
  18822: 99999,
  18823: 99999,
  18824: 99999,
  18825: 99999,
  18826: 99999,
  18827: 99999,
  18828: 99999,
  18829: 99999,
  18830: 99999,
  18831: 42380,
  18832: 42380,
  18833: 99999,
  18834: 99999,
  18837: 42380,
  18840: 42380,
  18842: 99999,
  18843: 99999,
  18844: 99999,
  18845: 42380,
  18846: 42380,
  18847: 99999,
  18848: 42380,
  18850: 42380,
  18851: 99999,
  18853: 42380,
  18854: 42380,
  18901: 37980,
  18902: 37980,
  18910: 37980,
  18911: 37980,
  18912: 37980,
  18913: 37980,
  18914: 37980,
  18915: 37980,
  18916: 37980,
  18917: 37980,
  18918: 37980,
  18920: 37980,
  18921: 37980,
  18922: 37980,
  18923: 37980,
  18925: 37980,
  18927: 37980,
  18928: 37980,
  18929: 37980,
  18930: 37980,
  18931: 37980,
  18932: 37980,
  18933: 37980,
  18934: 37980,
  18935: 37980,
  18936: 37980,
  18938: 37980,
  18940: 37980,
  18942: 37980,
  18943: 37980,
  18944: 37980,
  18946: 37980,
  18947: 37980,
  18949: 37980,
  18950: 37980,
  18951: 10900,
  18953: 37980,
  18954: 37980,
  18955: 37980,
  18956: 37980,
  18957: 37980,
  18958: 37980,
  18960: 37980,
  18962: 37980,
  18963: 37980,
  18964: 37980,
  18966: 37980,
  18968: 37980,
  18969: 37980,
  18970: 37980,
  18971: 37980,
  18972: 37980,
  18974: 37980,
  18976: 37980,
  18977: 37980,
  18979: 37980,
  18980: 37980,
  18981: 37980,
  19001: 37980,
  19002: 37980,
  19003: 37980,
  19004: 37980,
  19006: 37980,
  19007: 37980,
  19008: 37980,
  19009: 37980,
  19010: 37980,
  19012: 37980,
  19013: 37980,
  19014: 37980,
  19015: 37980,
  19016: 37980,
  19017: 37980,
  19018: 37980,
  19020: 37980,
  19021: 37980,
  19022: 37980,
  19023: 37980,
  19025: 37980,
  19026: 37980,
  19027: 37980,
  19028: 37980,
  19029: 37980,
  19030: 37980,
  19031: 37980,
  19032: 37980,
  19033: 37980,
  19034: 37980,
  19035: 37980,
  19036: 37980,
  19038: 37980,
  19039: 37980,
  19040: 37980,
  19041: 37980,
  19043: 37980,
  19044: 37980,
  19046: 37980,
  19047: 37980,
  19050: 37980,
  19052: 37980,
  19053: 37980,
  19054: 37980,
  19055: 37980,
  19056: 37980,
  19057: 37980,
  19058: 37980,
  19060: 37980,
  19061: 37980,
  19063: 37980,
  19064: 37980,
  19066: 37980,
  19067: 37980,
  19070: 37980,
  19072: 37980,
  19073: 37980,
  19074: 37980,
  19075: 37980,
  19076: 37980,
  19078: 37980,
  19079: 37980,
  19081: 37980,
  19082: 37980,
  19083: 37980,
  19085: 37980,
  19086: 37980,
  19087: 37980,
  19090: 37980,
  19091: 37980,
  19094: 37980,
  19095: 37980,
  19096: 37980,
  19101: 37980,
  19102: 37980,
  19103: 37980,
  19104: 37980,
  19105: 37980,
  19106: 37980,
  19107: 37980,
  19108: 37980,
  19109: 37980,
  19110: 37980,
  19111: 37980,
  19112: 37980,
  19113: 37980,
  19114: 37980,
  19115: 37980,
  19116: 37980,
  19118: 37980,
  19119: 37980,
  19120: 37980,
  19121: 37980,
  19122: 37980,
  19123: 37980,
  19124: 37980,
  19125: 37980,
  19126: 37980,
  19127: 37980,
  19128: 37980,
  19129: 37980,
  19130: 37980,
  19131: 37980,
  19132: 37980,
  19133: 37980,
  19134: 37980,
  19135: 37980,
  19136: 37980,
  19137: 37980,
  19138: 37980,
  19139: 37980,
  19140: 37980,
  19141: 37980,
  19142: 37980,
  19143: 37980,
  19144: 37980,
  19145: 37980,
  19146: 37980,
  19147: 37980,
  19148: 37980,
  19149: 37980,
  19150: 37980,
  19151: 37980,
  19152: 37980,
  19153: 37980,
  19154: 37980,
  19160: 37980,
  19172: 37980,
  19176: 37980,
  19181: 37980,
  19190: 37980,
  19192: 37980,
  19301: 37980,
  19310: 37980,
  19311: 37980,
  19312: 37980,
  19316: 37980,
  19317: 37980,
  19318: 37980,
  19319: 37980,
  19320: 37980,
  19330: 37980,
  19331: 37980,
  19333: 37980,
  19335: 37980,
  19339: 37980,
  19341: 37980,
  19342: 37980,
  19343: 37980,
  19344: 29540,
  19345: 37980,
  19346: 37980,
  19347: 37980,
  19348: 37980,
  19350: 37980,
  19351: 37980,
  19352: 37980,
  19354: 37980,
  19355: 37980,
  19357: 37980,
  19358: 37980,
  19360: 37980,
  19362: 29540,
  19363: 37980,
  19365: 37980,
  19366: 37980,
  19367: 37980,
  19369: 37980,
  19371: 37980,
  19372: 37980,
  19373: 37980,
  19374: 37980,
  19375: 37980,
  19376: 37980,
  19380: 37980,
  19381: 37980,
  19382: 37980,
  19383: 37980,
  19390: 37980,
  19395: 37980,
  19399: 37980,
  19401: 37980,
  19403: 37980,
  19404: 37980,
  19405: 25420,
  19406: 37980,
  19407: 37980,
  19408: 37980,
  19409: 37980,
  19421: 37980,
  19422: 37980,
  19423: 37980,
  19425: 37980,
  19426: 37980,
  19428: 37980,
  19430: 37980,
  19432: 37980,
  19435: 37980,
  19436: 37980,
  19437: 37980,
  19438: 37980,
  19440: 37980,
  19442: 37980,
  19443: 37980,
  19444: 37980,
  19446: 37980,
  19450: 37980,
  19451: 37980,
  19453: 37980,
  19454: 37980,
  19456: 37980,
  19457: 37980,
  19460: 37980,
  19462: 37980,
  19464: 39740,
  19465: 37980,
  19468: 37980,
  19470: 37980,
  19472: 37980,
  19473: 37980,
  19474: 37980,
  19475: 37980,
  19477: 37980,
  19478: 37980,
  19480: 37980,
  19481: 37980,
  19482: 37980,
  19484: 37980,
  19486: 37980,
  19490: 37980,
  19492: 37980,
  19501: 29540,
  19503: 39740,
  19504: 37980,
  19505: 39740,
  19506: 39740,
  19507: 39740,
  19508: 39740,
  19510: 39740,
  19511: 39740,
  19512: 39740,
  19516: 39740,
  19518: 39740,
  19519: 39740,
  19520: 39740,
  19522: 39740,
  19523: 39740,
  19525: 37980,
  19526: 39740,
  19529: 10900,
  19530: 10900,
  19533: 39740,
  19534: 39740,
  19535: 39740,
  19536: 39740,
  19538: 39740,
  19539: 10900,
  19540: 39740,
  19541: 39740,
  19543: 37980,
  19544: 39740,
  19545: 39740,
  19547: 39740,
  19548: 39740,
  19549: 39060,
  19550: 39740,
  19551: 39740,
  19554: 39740,
  19555: 39740,
  19559: 39740,
  19560: 39740,
  19562: 39740,
  19564: 39740,
  19565: 39740,
  19567: 30140,
  19601: 39740,
  19602: 39740,
  19603: 39740,
  19604: 39740,
  19605: 39740,
  19606: 39740,
  19607: 39740,
  19608: 39740,
  19609: 39740,
  19610: 39740,
  19611: 39740,
  19612: 39740,
  19701: 37980,
  19702: 37980,
  19703: 37980,
  19706: 37980,
  19707: 37980,
  19708: 37980,
  19709: 37980,
  19710: 37980,
  19711: 37980,
  19713: 37980,
  19714: 37980,
  19715: 37980,
  19716: 37980,
  19717: 37980,
  19718: 37980,
  19720: 37980,
  19721: 37980,
  19726: 37980,
  19730: 37980,
  19731: 37980,
  19732: 37980,
  19733: 37980,
  19734: 37980,
  19735: 37980,
  19736: 37980,
  19801: 37980,
  19802: 37980,
  19803: 37980,
  19804: 37980,
  19805: 37980,
  19806: 37980,
  19807: 37980,
  19808: 37980,
  19809: 37980,
  19810: 37980,
  19884: 37980,
  19890: 37980,
  19892: 37980,
  19895: 37980,
  19897: 37980,
  19898: 37980,
  19899: 37980,
  19901: 20100,
  19902: 20100,
  19903: 20100,
  19904: 20100,
  19930: 41540,
  19931: 41540,
  19933: 41540,
  19934: 20100,
  19936: 20100,
  19938: 37980,
  19939: 41540,
  19940: 41540,
  19941: 41540,
  19943: 20100,
  19944: 41540,
  19945: 41540,
  19946: 20100,
  19947: 41540,
  19950: 41540,
  19951: 41540,
  19952: 20100,
  19953: 20100,
  19954: 20100,
  19955: 20100,
  19956: 41540,
  19958: 41540,
  19960: 41540,
  19961: 20100,
  19962: 20100,
  19963: 20100,
  19964: 20100,
  19966: 41540,
  19967: 41540,
  19968: 41540,
  19969: 41540,
  19970: 41540,
  19971: 41540,
  19973: 41540,
  19975: 41540,
  19977: 37980,
  19979: 20100,
  19980: 20100,
  20001: 47900,
  20002: 47900,
  20003: 47900,
  20004: 47900,
  20005: 47900,
  20006: 47900,
  20007: 47900,
  20008: 47900,
  20009: 47900,
  20010: 47900,
  20011: 47900,
  20012: 47900,
  20013: 47900,
  20015: 47900,
  20016: 47900,
  20017: 47900,
  20018: 47900,
  20019: 47900,
  20020: 47900,
  20024: 47900,
  20026: 47900,
  20027: 47900,
  20029: 47900,
  20030: 47900,
  20032: 47900,
  20033: 47900,
  20035: 47900,
  20036: 47900,
  20037: 47900,
  20038: 47900,
  20039: 47900,
  20040: 47900,
  20041: 47900,
  20042: 47900,
  20043: 47900,
  20044: 47900,
  20045: 47900,
  20047: 47900,
  20049: 47900,
  20052: 47900,
  20053: 47900,
  20055: 47900,
  20056: 47900,
  20057: 47900,
  20058: 47900,
  20059: 47900,
  20060: 47900,
  20062: 47900,
  20063: 47900,
  20064: 47900,
  20065: 47900,
  20066: 47900,
  20069: 47900,
  20070: 47900,
  20071: 47900,
  20073: 47900,
  20076: 47900,
  20090: 47900,
  20091: 47900,
  20101: 47900,
  20103: 47900,
  20104: 47900,
  20105: 47900,
  20106: 47900,
  20108: 47900,
  20109: 47900,
  20110: 47900,
  20111: 47900,
  20112: 47900,
  20113: 47900,
  20115: 47900,
  20117: 47900,
  20119: 47900,
  20120: 47900,
  20121: 47900,
  20122: 47900,
  20124: 47900,
  20128: 47900,
  20129: 47900,
  20130: 47900,
  20131: 47900,
  20132: 47900,
  20135: 47900,
  20136: 47900,
  20137: 47900,
  20138: 47900,
  20139: 47900,
  20140: 47900,
  20141: 47900,
  20143: 47900,
  20144: 47900,
  20146: 47900,
  20147: 47900,
  20148: 47900,
  20149: 47900,
  20151: 47900,
  20152: 47900,
  20153: 47900,
  20155: 47900,
  20156: 47900,
  20158: 47900,
  20159: 47900,
  20160: 47900,
  20164: 47900,
  20165: 47900,
  20166: 47900,
  20167: 47900,
  20168: 47900,
  20169: 47900,
  20170: 47900,
  20171: 47900,
  20172: 47900,
  20175: 47900,
  20176: 47900,
  20177: 47900,
  20178: 47900,
  20180: 47900,
  20181: 47900,
  20182: 47900,
  20184: 47900,
  20186: 47900,
  20187: 47900,
  20188: 47900,
  20189: 47900,
  20190: 47900,
  20191: 47900,
  20192: 47900,
  20194: 47900,
  20195: 47900,
  20197: 47900,
  20198: 47900,
  20201: 47900,
  20202: 47900,
  20203: 47900,
  20204: 47900,
  20206: 47900,
  20207: 47900,
  20208: 47900,
  20210: 47900,
  20211: 47900,
  20212: 47900,
  20213: 47900,
  20215: 47900,
  20216: 47900,
  20217: 47900,
  20218: 47900,
  20219: 47900,
  20220: 47900,
  20221: 47900,
  20222: 47900,
  20223: 47900,
  20224: 47900,
  20226: 47900,
  20227: 47900,
  20228: 47900,
  20229: 47900,
  20230: 47900,
  20233: 47900,
  20235: 47900,
  20237: 47900,
  20239: 47900,
  20240: 47900,
  20241: 47900,
  20242: 47900,
  20244: 47900,
  20245: 47900,
  20250: 47900,
  20254: 47900,
  20260: 47900,
  20268: 47900,
  20270: 47900,
  20289: 47900,
  20301: 47900,
  20303: 47900,
  20306: 47900,
  20310: 47900,
  20318: 47900,
  20319: 47900,
  20330: 47900,
  20340: 47900,
  20350: 47900,
  20370: 47900,
  20372: 47900,
  20373: 47900,
  20374: 47900,
  20375: 47900,
  20376: 47900,
  20380: 47900,
  20388: 47900,
  20389: 47900,
  20390: 47900,
  20391: 47900,
  20392: 47900,
  20395: 47900,
  20398: 47900,
  20401: 47900,
  20402: 47900,
  20403: 47900,
  20404: 47900,
  20405: 47900,
  20406: 47900,
  20407: 47900,
  20408: 47900,
  20410: 47900,
  20411: 47900,
  20412: 47900,
  20413: 47900,
  20414: 47900,
  20415: 47900,
  20416: 47900,
  20417: 47900,
  20418: 47900,
  20419: 47900,
  20420: 47900,
  20421: 47900,
  20422: 47900,
  20423: 47900,
  20424: 47900,
  20425: 47900,
  20426: 47900,
  20427: 47900,
  20428: 47900,
  20429: 47900,
  20431: 47900,
  20433: 47900,
  20434: 47900,
  20435: 47900,
  20436: 47900,
  20437: 47900,
  20439: 47900,
  20440: 47900,
  20441: 47900,
  20442: 47900,
  20444: 47900,
  20447: 47900,
  20451: 47900,
  20456: 47900,
  20460: 47900,
  20463: 47900,
  20472: 47900,
  20500: 47900,
  20503: 47900,
  20504: 47900,
  20505: 47900,
  20506: 47900,
  20507: 47900,
  20508: 47900,
  20509: 47900,
  20510: 47900,
  20515: 47900,
  20520: 47900,
  20521: 47900,
  20522: 47900,
  20523: 47900,
  20524: 47900,
  20525: 47900,
  20526: 47900,
  20527: 47900,
  20528: 47900,
  20529: 47900,
  20530: 47900,
  20531: 47900,
  20533: 47900,
  20534: 47900,
  20535: 47900,
  20536: 47900,
  20538: 47900,
  20539: 47900,
  20540: 47900,
  20541: 47900,
  20542: 47900,
  20543: 47900,
  20544: 47900,
  20546: 47900,
  20547: 47900,
  20548: 47900,
  20549: 47900,
  20551: 47900,
  20552: 47900,
  20553: 47900,
  20554: 47900,
  20555: 47900,
  20557: 47900,
  20559: 47900,
  20560: 47900,
  20565: 47900,
  20566: 47900,
  20570: 47900,
  20571: 47900,
  20572: 47900,
  20573: 47900,
  20575: 47900,
  20576: 47900,
  20577: 47900,
  20579: 47900,
  20580: 47900,
  20581: 47900,
  20585: 47900,
  20586: 47900,
  20588: 47900,
  20590: 47900,
  20591: 47900,
  20593: 47900,
  20594: 47900,
  20597: 47900,
  20598: 47900,
  20599: 47900,
  20601: 47900,
  20602: 47900,
  20603: 47900,
  20604: 47900,
  20606: 15680,
  20607: 47900,
  20608: 47900,
  20609: 15680,
  20610: 47900,
  20611: 47900,
  20612: 47900,
  20613: 47900,
  20615: 47900,
  20616: 47900,
  20617: 47900,
  20618: 15680,
  20619: 15680,
  20620: 15680,
  20621: 15680,
  20622: 47900,
  20623: 47900,
  20624: 15680,
  20625: 47900,
  20626: 15680,
  20627: 15680,
  20628: 15680,
  20629: 47900,
  20630: 15680,
  20632: 47900,
  20634: 15680,
  20635: 15680,
  20636: 15680,
  20637: 47900,
  20639: 47900,
  20640: 47900,
  20643: 47900,
  20645: 47900,
  20646: 47900,
  20650: 15680,
  20653: 15680,
  20656: 15680,
  20657: 47900,
  20658: 47900,
  20659: 47900,
  20660: 15680,
  20661: 47900,
  20662: 47900,
  20664: 47900,
  20667: 15680,
  20670: 15680,
  20674: 15680,
  20675: 47900,
  20676: 47900,
  20677: 47900,
  20678: 47900,
  20680: 15680,
  20684: 15680,
  20685: 47900,
  20686: 15680,
  20687: 15680,
  20688: 47900,
  20689: 47900,
  20690: 15680,
  20692: 15680,
  20693: 47900,
  20695: 47900,
  20701: 12580,
  20703: 47900,
  20704: 47900,
  20705: 47900,
  20706: 47900,
  20707: 47900,
  20708: 47900,
  20709: 47900,
  20710: 47900,
  20711: 12580,
  20712: 47900,
  20714: 12580,
  20715: 47900,
  20716: 47900,
  20717: 47900,
  20718: 47900,
  20720: 47900,
  20721: 47900,
  20722: 47900,
  20723: 12580,
  20724: 12580,
  20725: 47900,
  20726: 47900,
  20731: 47900,
  20732: 47900,
  20733: 12580,
  20735: 47900,
  20736: 12580,
  20737: 47900,
  20738: 47900,
  20740: 47900,
  20741: 47900,
  20742: 47900,
  20743: 47900,
  20744: 47900,
  20745: 47900,
  20746: 47900,
  20747: 47900,
  20748: 47900,
  20749: 47900,
  20750: 47900,
  20751: 12580,
  20752: 47900,
  20753: 47900,
  20754: 12580,
  20755: 12580,
  20757: 47900,
  20758: 12580,
  20759: 12580,
  20762: 47900,
  20763: 12580,
  20764: 12580,
  20765: 12580,
  20768: 47900,
  20769: 47900,
  20770: 47900,
  20771: 47900,
  20772: 47900,
  20773: 47900,
  20774: 47900,
  20775: 47900,
  20776: 12580,
  20777: 12580,
  20778: 12580,
  20779: 12580,
  20781: 47900,
  20782: 47900,
  20783: 47900,
  20784: 47900,
  20785: 47900,
  20787: 47900,
  20790: 47900,
  20791: 47900,
  20792: 47900,
  20794: 12580,
  20799: 47900,
  20810: 47900,
  20811: 47900,
  20812: 47900,
  20814: 47900,
  20815: 47900,
  20816: 47900,
  20817: 47900,
  20818: 47900,
  20824: 47900,
  20825: 47900,
  20827: 47900,
  20830: 47900,
  20832: 47900,
  20833: 12580,
  20837: 47900,
  20838: 47900,
  20839: 47900,
  20841: 47900,
  20842: 47900,
  20847: 47900,
  20848: 47900,
  20849: 47900,
  20850: 47900,
  20851: 47900,
  20852: 47900,
  20853: 47900,
  20854: 47900,
  20855: 47900,
  20857: 47900,
  20859: 47900,
  20860: 47900,
  20861: 47900,
  20862: 47900,
  20866: 47900,
  20868: 47900,
  20871: 47900,
  20872: 47900,
  20874: 47900,
  20875: 47900,
  20876: 47900,
  20877: 47900,
  20878: 47900,
  20879: 47900,
  20880: 47900,
  20882: 47900,
  20883: 47900,
  20884: 47900,
  20885: 47900,
  20886: 47900,
  20889: 47900,
  20891: 47900,
  20892: 47900,
  20894: 47900,
  20895: 47900,
  20896: 47900,
  20898: 47900,
  20899: 47900,
  20901: 47900,
  20902: 47900,
  20903: 47900,
  20904: 47900,
  20905: 47900,
  20906: 47900,
  20907: 47900,
  20910: 47900,
  20911: 47900,
  20912: 47900,
  20913: 47900,
  20914: 47900,
  20915: 47900,
  20916: 47900,
  20918: 47900,
  20993: 47900,
  21001: 12580,
  21005: 12580,
  21009: 12580,
  21010: 12580,
  21012: 12580,
  21013: 12580,
  21014: 12580,
  21015: 12580,
  21017: 12580,
  21018: 12580,
  21020: 12580,
  21022: 12580,
  21023: 12580,
  21027: 12580,
  21028: 12580,
  21029: 12580,
  21030: 12580,
  21031: 12580,
  21032: 12580,
  21034: 12580,
  21035: 12580,
  21036: 12580,
  21037: 12580,
  21040: 12580,
  21041: 12580,
  21042: 12580,
  21043: 12580,
  21044: 12580,
  21045: 12580,
  21046: 12580,
  21047: 12580,
  21048: 12580,
  21050: 12580,
  21051: 12580,
  21052: 12580,
  21053: 12580,
  21054: 12580,
  21056: 12580,
  21057: 12580,
  21060: 12580,
  21061: 12580,
  21062: 12580,
  21065: 12580,
  21071: 12580,
  21074: 12580,
  21075: 12580,
  21076: 12580,
  21077: 12580,
  21078: 12580,
  21082: 12580,
  21084: 12580,
  21085: 12580,
  21087: 12580,
  21090: 12580,
  21092: 12580,
  21093: 12580,
  21094: 12580,
  21102: 12580,
  21104: 12580,
  21105: 12580,
  21106: 12580,
  21108: 12580,
  21111: 12580,
  21113: 12580,
  21114: 12580,
  21117: 12580,
  21120: 12580,
  21122: 12580,
  21123: 12580,
  21128: 12580,
  21130: 12580,
  21131: 12580,
  21132: 12580,
  21133: 12580,
  21136: 12580,
  21139: 12580,
  21140: 12580,
  21144: 12580,
  21146: 12580,
  21150: 12580,
  21152: 12580,
  21153: 12580,
  21154: 12580,
  21155: 12580,
  21156: 12580,
  21157: 12580,
  21158: 12580,
  21160: 12580,
  21161: 12580,
  21162: 12580,
  21163: 12580,
  21201: 12580,
  21202: 12580,
  21203: 12580,
  21204: 12580,
  21205: 12580,
  21206: 12580,
  21207: 12580,
  21208: 12580,
  21209: 12580,
  21210: 12580,
  21211: 12580,
  21212: 12580,
  21213: 12580,
  21214: 12580,
  21215: 12580,
  21216: 12580,
  21217: 12580,
  21218: 12580,
  21219: 12580,
  21220: 12580,
  21221: 12580,
  21222: 12580,
  21223: 12580,
  21224: 12580,
  21225: 12580,
  21226: 12580,
  21227: 12580,
  21228: 12580,
  21229: 12580,
  21230: 12580,
  21231: 12580,
  21233: 12580,
  21234: 12580,
  21235: 12580,
  21236: 12580,
  21237: 12580,
  21239: 12580,
  21240: 12580,
  21241: 12580,
  21244: 12580,
  21250: 12580,
  21251: 12580,
  21252: 12580,
  21263: 12580,
  21270: 12580,
  21278: 12580,
  21281: 12580,
  21282: 12580,
  21284: 12580,
  21285: 12580,
  21286: 12580,
  21287: 12580,
  21290: 12580,
  21401: 12580,
  21402: 12580,
  21403: 12580,
  21404: 12580,
  21405: 12580,
  21409: 12580,
  21411: 12580,
  21412: 12580,
  21501: 19060,
  21502: 19060,
  21503: 19060,
  21504: 19060,
  21505: 19060,
  21520: 99999,
  21521: 99999,
  21522: 99999,
  21523: 99999,
  21524: 19060,
  21528: 19060,
  21529: 19060,
  21530: 19060,
  21531: 99999,
  21532: 19060,
  21536: 99999,
  21538: 99999,
  21539: 99999,
  21540: 19060,
  21541: 99999,
  21542: 19060,
  21543: 19060,
  21545: 19060,
  21550: 99999,
  21555: 19060,
  21556: 19060,
  21557: 19060,
  21560: 19060,
  21561: 99999,
  21562: 19060,
  21601: 20660,
  21607: 99999,
  21609: 99999,
  21610: 99999,
  21612: 20660,
  21613: 15700,
  21617: 12580,
  21619: 12580,
  21620: 99999,
  21622: 15700,
  21623: 12580,
  21624: 20660,
  21625: 20660,
  21626: 15700,
  21627: 15700,
  21628: 12580,
  21629: 99999,
  21631: 15700,
  21632: 99999,
  21634: 15700,
  21635: 99999,
  21636: 99999,
  21638: 12580,
  21639: 99999,
  21640: 12580,
  21641: 99999,
  21643: 15700,
  21644: 12580,
  21645: 99999,
  21647: 20660,
  21648: 15700,
  21649: 99999,
  21650: 99999,
  21651: 99999,
  21652: 20660,
  21653: 20660,
  21654: 20660,
  21655: 99999,
  21656: 12580,
  21657: 20660,
  21658: 12580,
  21659: 15700,
  21660: 99999,
  21661: 99999,
  21662: 20660,
  21663: 20660,
  21664: 15700,
  21665: 20660,
  21666: 12580,
  21667: 99999,
  21668: 12580,
  21669: 15700,
  21670: 12580,
  21671: 20660,
  21672: 15700,
  21673: 20660,
  21675: 15700,
  21676: 20660,
  21677: 15700,
  21678: 99999,
  21679: 12580,
  21690: 99999,
  21701: 47900,
  21702: 47900,
  21703: 47900,
  21704: 47900,
  21705: 47900,
  21710: 47900,
  21711: 25180,
  21713: 25180,
  21714: 47900,
  21715: 25180,
  21716: 47900,
  21717: 47900,
  21718: 47900,
  21719: 47900,
  21720: 25180,
  21721: 25180,
  21722: 25180,
  21723: 12580,
  21727: 47900,
  21733: 25180,
  21734: 25180,
  21737: 12580,
  21738: 12580,
  21740: 25180,
  21741: 25180,
  21742: 25180,
  21746: 25180,
  21750: 19060,
  21754: 47900,
  21755: 25180,
  21756: 25180,
  21757: 47900,
  21758: 25180,
  21759: 47900,
  21762: 47900,
  21765: 12580,
  21766: 19060,
  21767: 25180,
  21769: 25180,
  21770: 47900,
  21771: 12580,
  21773: 47900,
  21774: 47900,
  21775: 47900,
  21776: 12580,
  21777: 47900,
  21778: 47900,
  21779: 25180,
  21780: 25180,
  21781: 25180,
  21782: 25180,
  21783: 47900,
  21784: 12580,
  21787: 12580,
  21788: 47900,
  21790: 47900,
  21791: 47900,
  21792: 41540,
  21793: 47900,
  21794: 12580,
  21795: 25180,
  21797: 12580,
  21798: 47900,
  21801: 41540,
  21802: 41540,
  21803: 41540,
  21804: 41540,
  21810: 41540,
  21811: 41540,
  21813: 41540,
  21814: 41540,
  21817: 41540,
  21821: 41540,
  21822: 41540,
  21824: 41540,
  21826: 41540,
  21829: 41540,
  21830: 41540,
  21835: 15700,
  21836: 41540,
  21837: 41540,
  21838: 41540,
  21840: 41540,
  21841: 41540,
  21842: 41540,
  21843: 41540,
  21849: 41540,
  21850: 41540,
  21851: 41540,
  21852: 41540,
  21853: 41540,
  21856: 41540,
  21857: 41540,
  21861: 41540,
  21862: 41540,
  21863: 41540,
  21864: 41540,
  21865: 41540,
  21866: 41540,
  21867: 41540,
  21869: 15700,
  21871: 41540,
  21872: 41540,
  21874: 41540,
  21875: 41540,
  21890: 41540,
  21901: 37980,
  21902: 37980,
  21903: 37980,
  21904: 37980,
  21911: 37980,
  21912: 37980,
  21913: 37980,
  21914: 37980,
  21915: 37980,
  21916: 37980,
  21917: 37980,
  21918: 37980,
  21919: 37980,
  21920: 37980,
  21921: 37980,
  21922: 37980,
  21930: 99999,
  22003: 47900,
  22009: 47900,
  22015: 47900,
  22025: 47900,
  22026: 47900,
  22027: 47900,
  22030: 47900,
  22031: 47900,
  22032: 47900,
  22033: 47900,
  22035: 47900,
  22037: 47900,
  22038: 47900,
  22039: 47900,
  22040: 47900,
  22041: 47900,
  22042: 47900,
  22043: 47900,
  22044: 47900,
  22046: 47900,
  22060: 47900,
  22066: 47900,
  22067: 47900,
  22079: 47900,
  22081: 47900,
  22082: 47900,
  22101: 47900,
  22102: 47900,
  22103: 47900,
  22106: 47900,
  22107: 47900,
  22108: 47900,
  22109: 47900,
  22116: 47900,
  22121: 47900,
  22122: 47900,
  22124: 47900,
  22125: 47900,
  22134: 47900,
  22135: 47900,
  22150: 47900,
  22151: 47900,
  22152: 47900,
  22153: 47900,
  22158: 47900,
  22160: 47900,
  22172: 47900,
  22180: 47900,
  22181: 47900,
  22182: 47900,
  22183: 47900,
  22185: 47900,
  22191: 47900,
  22192: 47900,
  22193: 47900,
  22194: 47900,
  22195: 47900,
  22199: 47900,
  22201: 47900,
  22202: 47900,
  22203: 47900,
  22204: 47900,
  22205: 47900,
  22206: 47900,
  22207: 47900,
  22209: 47900,
  22210: 47900,
  22211: 47900,
  22213: 47900,
  22214: 47900,
  22215: 47900,
  22216: 47900,
  22217: 47900,
  22219: 47900,
  22225: 47900,
  22226: 47900,
  22227: 47900,
  22230: 47900,
  22240: 47900,
  22241: 47900,
  22242: 47900,
  22243: 47900,
  22244: 47900,
  22245: 47900,
  22301: 47900,
  22302: 47900,
  22303: 47900,
  22304: 47900,
  22305: 47900,
  22306: 47900,
  22307: 47900,
  22308: 47900,
  22309: 47900,
  22310: 47900,
  22311: 47900,
  22312: 47900,
  22313: 47900,
  22314: 47900,
  22315: 47900,
  22320: 47900,
  22332: 47900,
  22333: 47900,
  22350: 47900,
  22401: 47900,
  22402: 47900,
  22403: 47900,
  22404: 47900,
  22405: 47900,
  22406: 47900,
  22407: 47900,
  22408: 47900,
  22412: 47900,
  22427: 99999,
  22428: 99999,
  22430: 47900,
  22432: 99999,
  22433: 99999,
  22435: 99999,
  22436: 99999,
  22437: 99999,
  22438: 99999,
  22442: 99999,
  22443: 99999,
  22446: 99999,
  22448: 99999,
  22451: 99999,
  22454: 99999,
  22460: 99999,
  22463: 47900,
  22469: 99999,
  22471: 47900,
  22472: 99999,
  22473: 99999,
  22476: 99999,
  22480: 99999,
  22481: 99999,
  22482: 99999,
  22485: 99999,
  22488: 99999,
  22501: 99999,
  22503: 99999,
  22504: 99999,
  22507: 99999,
  22508: 47900,
  22509: 99999,
  22511: 99999,
  22513: 99999,
  22514: 99999,
  22517: 99999,
  22520: 99999,
  22523: 99999,
  22524: 99999,
  22526: 99999,
  22528: 99999,
  22529: 99999,
  22530: 99999,
  22534: 47900,
  22535: 99999,
  22538: 99999,
  22539: 99999,
  22542: 99999,
  22544: 99999,
  22545: 47900,
  22546: 47900,
  22547: 99999,
  22548: 99999,
  22551: 47900,
  22552: 99999,
  22553: 47900,
  22554: 47900,
  22555: 47900,
  22556: 47900,
  22558: 99999,
  22560: 99999,
  22565: 47900,
  22567: 47900,
  22572: 99999,
  22576: 99999,
  22577: 99999,
  22578: 99999,
  22579: 99999,
  22580: 47900,
  22581: 99999,
  22601: 49020,
  22602: 49020,
  22603: 49020,
  22604: 49020,
  22610: 47900,
  22611: 47900,
  22620: 47900,
  22622: 49020,
  22623: 47900,
  22624: 49020,
  22625: 49020,
  22626: 99999,
  22627: 47900,
  22630: 47900,
  22637: 49020,
  22639: 47900,
  22640: 47900,
  22641: 99999,
  22642: 47900,
  22643: 47900,
  22644: 99999,
  22645: 49020,
  22646: 47900,
  22649: 47900,
  22650: 99999,
  22652: 99999,
  22654: 99999,
  22655: 47900,
  22656: 49020,
  22657: 47900,
  22660: 99999,
  22663: 47900,
  22664: 99999,
  22701: 47900,
  22709: 47900,
  22711: 47900,
  22712: 47900,
  22713: 47900,
  22714: 47900,
  22715: 47900,
  22716: 47900,
  22718: 47900,
  22719: 47900,
  22720: 47900,
  22722: 47900,
  22723: 47900,
  22724: 47900,
  22725: 47900,
  22726: 47900,
  22727: 47900,
  22728: 47900,
  22729: 47900,
  22730: 47900,
  22731: 47900,
  22732: 47900,
  22733: 47900,
  22734: 47900,
  22735: 47900,
  22736: 47900,
  22737: 47900,
  22738: 47900,
  22740: 47900,
  22741: 47900,
  22742: 47900,
  22743: 99999,
  22747: 47900,
  22748: 47900,
  22749: 47900,
  22801: 25500,
  22802: 25500,
  22803: 25500,
  22807: 25500,
  22810: 99999,
  22811: 25500,
  22812: 44420,
  22815: 99999,
  22820: 25500,
  22821: 25500,
  22824: 99999,
  22827: 25500,
  22830: 25500,
  22831: 25500,
  22832: 25500,
  22833: 25500,
  22834: 25500,
  22835: 99999,
  22840: 25500,
  22841: 44420,
  22842: 99999,
  22843: 44420,
  22844: 99999,
  22845: 99999,
  22846: 25500,
  22847: 99999,
  22848: 25500,
  22849: 99999,
  22850: 25500,
  22851: 99999,
  22853: 25500,
  22901: 16820,
  22902: 16820,
  22903: 16820,
  22904: 16820,
  22905: 16820,
  22906: 16820,
  22908: 16820,
  22909: 16820,
  22911: 16820,
  22920: 16820,
  22922: 16820,
  22923: 99999,
  22924: 16820,
  22931: 16820,
  22932: 16820,
  22935: 16820,
  22936: 16820,
  22937: 16820,
  22938: 16820,
  22939: 44420,
  22940: 16820,
  22942: 99999,
  22943: 16820,
  22945: 16820,
  22946: 16820,
  22947: 16820,
  22948: 47900,
  22949: 16820,
  22952: 44420,
  22957: 99999,
  22958: 16820,
  22959: 16820,
  22960: 47900,
  22963: 16820,
  22964: 16820,
  22965: 16820,
  22967: 16820,
  22968: 16820,
  22969: 16820,
  22971: 16820,
  22972: 47900,
  22973: 47900,
  22974: 99999,
  22976: 16820,
  22980: 44420,
  22987: 16820,
  22989: 47900,
  23001: 47260,
  23002: 99999,
  23003: 47260,
  23004: 99999,
  23005: 40060,
  23009: 40060,
  23011: 40060,
  23015: 47900,
  23018: 47260,
  23021: 47260,
  23022: 16820,
  23023: 99999,
  23024: 40060,
  23025: 47260,
  23027: 99999,
  23030: 40060,
  23031: 99999,
  23032: 99999,
  23035: 47260,
  23038: 99999,
  23039: 40060,
  23040: 99999,
  23043: 99999,
  23045: 47260,
  23047: 40060,
  23050: 47260,
  23055: 16820,
  23056: 47260,
  23058: 40060,
  23059: 40060,
  23060: 40060,
  23061: 47260,
  23062: 47260,
  23063: 40060,
  23064: 47260,
  23065: 40060,
  23066: 47260,
  23067: 40060,
  23068: 47260,
  23069: 40060,
  23070: 99999,
  23071: 99999,
  23072: 47260,
  23075: 40060,
  23076: 47260,
  23079: 99999,
  23081: 47260,
  23083: 99999,
  23084: 99999,
  23085: 40060,
  23086: 40060,
  23089: 40060,
  23090: 47260,
  23091: 40060,
  23092: 99999,
  23093: 99999,
  23102: 40060,
  23103: 40060,
  23105: 40060,
  23106: 40060,
  23108: 40060,
  23109: 47260,
  23110: 40060,
  23111: 40060,
  23112: 40060,
  23113: 40060,
  23114: 40060,
  23115: 99999,
  23116: 40060,
  23117: 99999,
  23119: 47260,
  23120: 40060,
  23123: 99999,
  23124: 40060,
  23125: 47260,
  23126: 40060,
  23127: 47260,
  23128: 47260,
  23129: 40060,
  23130: 47260,
  23131: 47260,
  23138: 47260,
  23139: 99999,
  23140: 40060,
  23141: 40060,
  23146: 40060,
  23147: 40060,
  23148: 40060,
  23149: 99999,
  23150: 40060,
  23153: 40060,
  23154: 47260,
  23155: 47260,
  23156: 40060,
  23160: 40060,
  23161: 40060,
  23162: 40060,
  23163: 47260,
  23168: 47260,
  23169: 99999,
  23170: 99999,
  23173: 40060,
  23175: 99999,
  23176: 99999,
  23177: 40060,
  23178: 47260,
  23180: 99999,
  23181: 40060,
  23183: 47260,
  23184: 47260,
  23185: 40060,
  23186: 47260,
  23187: 47260,
  23188: 47260,
  23190: 47260,
  23192: 40060,
  23218: 40060,
  23219: 40060,
  23220: 40060,
  23221: 40060,
  23222: 40060,
  23223: 40060,
  23224: 40060,
  23225: 40060,
  23226: 40060,
  23227: 40060,
  23228: 40060,
  23229: 40060,
  23230: 40060,
  23231: 40060,
  23232: 40060,
  23233: 40060,
  23234: 40060,
  23235: 40060,
  23236: 40060,
  23237: 40060,
  23238: 40060,
  23241: 40060,
  23242: 40060,
  23249: 40060,
  23250: 40060,
  23255: 40060,
  23260: 40060,
  23261: 40060,
  23269: 40060,
  23273: 40060,
  23284: 40060,
  23286: 40060,
  23293: 40060,
  23294: 40060,
  23297: 40060,
  23298: 40060,
  23301: 99999,
  23302: 99999,
  23303: 99999,
  23304: 47260,
  23306: 99999,
  23307: 99999,
  23308: 99999,
  23310: 99999,
  23313: 99999,
  23314: 47260,
  23315: 47260,
  23316: 99999,
  23320: 47260,
  23321: 47260,
  23322: 47260,
  23323: 47260,
  23324: 47260,
  23325: 47260,
  23326: 47260,
  23327: 47260,
  23328: 47260,
  23336: 99999,
  23337: 99999,
  23341: 99999,
  23347: 99999,
  23350: 99999,
  23354: 99999,
  23356: 99999,
  23357: 99999,
  23358: 99999,
  23359: 99999,
  23389: 99999,
  23395: 99999,
  23397: 47260,
  23398: 99999,
  23401: 99999,
  23404: 99999,
  23405: 99999,
  23407: 99999,
  23408: 99999,
  23409: 99999,
  23410: 99999,
  23413: 99999,
  23414: 99999,
  23415: 99999,
  23416: 99999,
  23417: 99999,
  23418: 99999,
  23420: 99999,
  23421: 99999,
  23422: 99999,
  23423: 99999,
  23424: 47260,
  23426: 99999,
  23427: 99999,
  23429: 99999,
  23430: 99999,
  23431: 47260,
  23432: 47260,
  23433: 47260,
  23434: 47260,
  23435: 47260,
  23436: 47260,
  23437: 47260,
  23438: 47260,
  23439: 47260,
  23440: 99999,
  23441: 99999,
  23442: 99999,
  23443: 99999,
  23450: 47260,
  23451: 47260,
  23452: 47260,
  23453: 47260,
  23454: 47260,
  23455: 47260,
  23456: 47260,
  23457: 47260,
  23459: 47260,
  23460: 47260,
  23461: 47260,
  23462: 47260,
  23463: 47260,
  23464: 47260,
  23466: 47260,
  23467: 47260,
  23471: 47260,
  23479: 47260,
  23480: 99999,
  23486: 99999,
  23487: 47260,
  23488: 99999,
  23501: 47260,
  23502: 47260,
  23503: 47260,
  23504: 47260,
  23505: 47260,
  23507: 47260,
  23508: 47260,
  23509: 47260,
  23510: 47260,
  23511: 47260,
  23513: 47260,
  23514: 47260,
  23515: 47260,
  23517: 47260,
  23518: 47260,
  23523: 47260,
  23529: 47260,
  23541: 47260,
  23551: 47260,
  23601: 47260,
  23602: 47260,
  23603: 47260,
  23604: 47260,
  23605: 47260,
  23606: 47260,
  23607: 47260,
  23608: 47260,
  23609: 47260,
  23612: 47260,
  23628: 47260,
  23630: 47260,
  23651: 47260,
  23661: 47260,
  23662: 47260,
  23663: 47260,
  23664: 47260,
  23665: 47260,
  23666: 47260,
  23667: 47260,
  23668: 47260,
  23669: 47260,
  23670: 47260,
  23681: 47260,
  23690: 47260,
  23691: 47260,
  23692: 47260,
  23693: 47260,
  23694: 47260,
  23696: 47260,
  23701: 47260,
  23702: 47260,
  23703: 47260,
  23704: 47260,
  23705: 47260,
  23707: 47260,
  23708: 47260,
  23709: 47260,
  23801: 40060,
  23803: 40060,
  23804: 40060,
  23805: 40060,
  23821: 99999,
  23824: 99999,
  23827: 47260,
  23828: 47260,
  23829: 40060,
  23830: 40060,
  23831: 40060,
  23832: 40060,
  23833: 40060,
  23834: 40060,
  23836: 40060,
  23837: 47260,
  23838: 40060,
  23839: 99999,
  23840: 40060,
  23841: 40060,
  23842: 99999,
  23843: 99999,
  23844: 47260,
  23845: 99999,
  23846: 99999,
  23847: 99999,
  23850: 40060,
  23851: 47260,
  23856: 99999,
  23857: 99999,
  23860: 40060,
  23866: 99999,
  23867: 99999,
  23868: 99999,
  23870: 99999,
  23872: 40060,
  23873: 99999,
  23874: 47260,
  23875: 40060,
  23876: 99999,
  23878: 40060,
  23879: 99999,
  23881: 99999,
  23882: 40060,
  23883: 99999,
  23884: 40060,
  23885: 40060,
  23887: 99999,
  23888: 47260,
  23889: 99999,
  23890: 99999,
  23891: 40060,
  23893: 99999,
  23894: 40060,
  23897: 40060,
  23898: 47260,
  23899: 99999,
  23901: 99999,
  23909: 99999,
  23915: 99999,
  23917: 99999,
  23919: 99999,
  23920: 99999,
  23921: 99999,
  23922: 99999,
  23923: 99999,
  23924: 99999,
  23927: 99999,
  23930: 99999,
  23934: 99999,
  23936: 99999,
  23937: 99999,
  23938: 99999,
  23939: 31340,
  23941: 99999,
  23942: 99999,
  23943: 99999,
  23944: 99999,
  23947: 99999,
  23950: 99999,
  23952: 99999,
  23954: 99999,
  23955: 99999,
  23958: 99999,
  23959: 99999,
  23960: 31340,
  23962: 99999,
  23963: 99999,
  23964: 99999,
  23966: 40060,
  23967: 99999,
  23968: 99999,
  23970: 99999,
  23974: 99999,
  23976: 99999,
  24001: 40220,
  24002: 40220,
  24003: 40220,
  24004: 40220,
  24005: 40220,
  24006: 40220,
  24007: 40220,
  24008: 40220,
  24009: 40220,
  24010: 40220,
  24011: 40220,
  24012: 40220,
  24013: 40220,
  24014: 40220,
  24015: 40220,
  24016: 40220,
  24017: 40220,
  24018: 40220,
  24019: 40220,
  24020: 40220,
  24022: 40220,
  24023: 40220,
  24024: 40220,
  24025: 40220,
  24026: 40220,
  24027: 40220,
  24028: 40220,
  24029: 40220,
  24030: 40220,
  24031: 40220,
  24032: 40220,
  24033: 40220,
  24034: 40220,
  24035: 40220,
  24036: 40220,
  24037: 40220,
  24038: 40220,
  24053: 99999,
  24054: 19260,
  24055: 99999,
  24058: 13980,
  24059: 99999,
  24060: 13980,
  24061: 13980,
  24062: 13980,
  24063: 13980,
  24064: 31340,
  24065: 40220,
  24066: 99999,
  24067: 40220,
  24068: 13980,
  24069: 19260,
  24070: 13980,
  24072: 99999,
  24073: 13980,
  24076: 99999,
  24077: 40220,
  24078: 32300,
  24079: 99999,
  24082: 99999,
  24083: 40220,
  24084: 14140,
  24085: 99999,
  24086: 13980,
  24087: 40220,
  24088: 40220,
  24089: 32300,
  24090: 40220,
  24091: 40220,
  24092: 40220,
  24093: 13980,
  24095: 31340,
  24101: 40220,
  24102: 32300,
  24104: 31340,
  24105: 99999,
  24111: 13980,
  24112: 40220,
  24120: 99999,
  24121: 31340,
  24122: 31340,
  24124: 13980,
  24126: 13980,
  24127: 40220,
  24128: 13980,
  24129: 13980,
  24130: 40220,
  24131: 40220,
  24132: 13980,
  24133: 99999,
  24134: 14140,
  24136: 13980,
  24137: 40220,
  24138: 99999,
  24139: 31340,
  24141: 99999,
  24142: 13980,
  24146: 40220,
  24147: 13980,
  24148: 32300,
  24149: 13980,
  24150: 13980,
  24151: 40220,
  24153: 40220,
  24155: 40220,
  24161: 19260,
  24162: 99999,
  24165: 32300,
  24167: 13980,
  24168: 32300,
  24171: 99999,
  24174: 31340,
  24175: 40220,
  24176: 19260,
  24177: 99999,
  24179: 31340,
  24184: 40220,
  24185: 99999,
  24201: 28700,
  24202: 28700,
  24203: 28700,
  24209: 28700,
  24210: 28700,
  24211: 28700,
  24212: 28700,
  24215: 13720,
  24216: 13720,
  24217: 99999,
  24218: 99999,
  24219: 13720,
  24220: 99999,
  24221: 99999,
  24224: 99999,
  24225: 99999,
  24226: 99999,
  24228: 99999,
  24230: 13720,
  24236: 99999,
  24237: 99999,
  24239: 99999,
  24243: 99999,
  24244: 99999,
  24245: 28700,
  24246: 13720,
  24248: 99999,
  24250: 28700,
  24251: 28700,
  24256: 99999,
  24258: 28700,
  24260: 99999,
  24263: 99999,
  24265: 99999,
  24266: 99999,
  24269: 99999,
  24270: 28700,
  24271: 99999,
  24272: 99999,
  24273: 13720,
  24277: 99999,
  24279: 13720,
  24280: 99999,
  24281: 99999,
  24282: 99999,
  24283: 13720,
  24290: 28700,
  24292: 99999,
  24293: 13720,
  24301: 13980,
  24311: 99999,
  24312: 99999,
  24313: 13980,
  24314: 14140,
  24315: 14140,
  24316: 99999,
  24317: 99999,
  24318: 99999,
  24319: 99999,
  24322: 99999,
  24323: 99999,
  24324: 99999,
  24325: 99999,
  24326: 99999,
  24327: 28700,
  24328: 99999,
  24330: 99999,
  24333: 99999,
  24340: 28700,
  24343: 99999,
  24347: 99999,
  24348: 99999,
  24350: 99999,
  24351: 99999,
  24352: 99999,
  24354: 99999,
  24360: 99999,
  24361: 28700,
  24363: 99999,
  24366: 14140,
  24368: 99999,
  24370: 99999,
  24374: 99999,
  24375: 99999,
  24377: 14140,
  24378: 99999,
  24380: 99999,
  24381: 99999,
  24382: 99999,
  24401: 44420,
  24402: 44420,
  24411: 44420,
  24412: 99999,
  24413: 99999,
  24415: 99999,
  24416: 99999,
  24421: 44420,
  24422: 40220,
  24426: 99999,
  24430: 44420,
  24431: 44420,
  24432: 44420,
  24433: 99999,
  24435: 99999,
  24437: 44420,
  24438: 40220,
  24439: 44420,
  24440: 44420,
  24441: 44420,
  24442: 99999,
  24445: 99999,
  24448: 99999,
  24450: 99999,
  24457: 99999,
  24458: 99999,
  24459: 44420,
  24460: 99999,
  24463: 44420,
  24464: 16820,
  24465: 99999,
  24467: 44420,
  24469: 44420,
  24471: 25500,
  24472: 44420,
  24473: 99999,
  24474: 99999,
  24476: 99999,
  24477: 44420,
  24479: 44420,
  24482: 44420,
  24483: 31340,
  24484: 99999,
  24485: 44420,
  24486: 25500,
  24487: 99999,
  24501: 31340,
  24502: 31340,
  24503: 31340,
  24504: 31340,
  24505: 31340,
  24506: 31340,
  24513: 31340,
  24514: 31340,
  24515: 31340,
  24517: 31340,
  24520: 99999,
  24521: 31340,
  24522: 31340,
  24523: 31340,
  24526: 31340,
  24527: 19260,
  24528: 31340,
  24529: 99999,
  24530: 19260,
  24531: 19260,
  24533: 31340,
  24534: 99999,
  24535: 99999,
  24536: 31340,
  24538: 31340,
  24539: 99999,
  24540: 19260,
  24541: 19260,
  24549: 19260,
  24550: 31340,
  24551: 31340,
  24553: 16820,
  24554: 31340,
  24555: 99999,
  24556: 31340,
  24557: 19260,
  24558: 99999,
  24562: 99999,
  24563: 19260,
  24565: 19260,
  24566: 19260,
  24569: 31340,
  24570: 31340,
  24571: 31340,
  24572: 31340,
  24574: 31340,
  24576: 31340,
  24577: 19260,
  24578: 99999,
  24579: 99999,
  24580: 99999,
  24581: 16820,
  24586: 19260,
  24588: 31340,
  24589: 99999,
  24590: 99999,
  24592: 19260,
  24593: 31340,
  24594: 19260,
  24595: 31340,
  24597: 99999,
  24598: 99999,
  24599: 99999,
  24601: 14140,
  24602: 14140,
  24603: 99999,
  24604: 14140,
  24605: 14140,
  24606: 14140,
  24607: 99999,
  24608: 14140,
  24609: 99999,
  24612: 14140,
  24613: 14140,
  24614: 99999,
  24619: 14140,
  24620: 99999,
  24622: 99999,
  24624: 99999,
  24627: 99999,
  24628: 99999,
  24630: 14140,
  24631: 99999,
  24634: 99999,
  24635: 14140,
  24637: 14140,
  24639: 99999,
  24640: 14140,
  24641: 14140,
  24646: 99999,
  24647: 14140,
  24649: 99999,
  24651: 14140,
  24656: 99999,
  24657: 99999,
  24658: 99999,
  24701: 14140,
  24712: 14140,
  24714: 14140,
  24715: 14140,
  24716: 99999,
  24719: 99999,
  24724: 14140,
  24726: 99999,
  24729: 14140,
  24731: 14140,
  24732: 14140,
  24733: 14140,
  24736: 99999,
  24737: 14140,
  24738: 14140,
  24739: 14140,
  24740: 14140,
  24747: 14140,
  24801: 99999,
  24808: 99999,
  24811: 99999,
  24813: 99999,
  24815: 99999,
  24816: 99999,
  24817: 99999,
  24818: 99999,
  24822: 99999,
  24823: 99999,
  24826: 99999,
  24827: 99999,
  24828: 99999,
  24829: 99999,
  24830: 99999,
  24831: 99999,
  24834: 99999,
  24836: 99999,
  24839: 99999,
  24843: 99999,
  24844: 99999,
  24845: 99999,
  24846: 99999,
  24847: 99999,
  24848: 99999,
  24849: 99999,
  24850: 99999,
  24851: 99999,
  24853: 99999,
  24854: 99999,
  24855: 99999,
  24857: 99999,
  24860: 99999,
  24861: 99999,
  24862: 99999,
  24866: 99999,
  24867: 99999,
  24868: 99999,
  24869: 99999,
  24870: 99999,
  24871: 99999,
  24872: 99999,
  24873: 99999,
  24874: 99999,
  24878: 99999,
  24879: 99999,
  24880: 99999,
  24881: 99999,
  24882: 99999,
  24884: 99999,
  24887: 99999,
  24888: 99999,
  24892: 99999,
  24894: 99999,
  24895: 99999,
  24898: 99999,
  24901: 99999,
  24910: 99999,
  24915: 99999,
  24916: 99999,
  24918: 99999,
  24920: 99999,
  24924: 99999,
  24925: 99999,
  24927: 99999,
  24931: 99999,
  24934: 99999,
  24935: 99999,
  24938: 99999,
  24941: 99999,
  24943: 99999,
  24944: 99999,
  24945: 99999,
  24946: 99999,
  24951: 99999,
  24954: 99999,
  24957: 99999,
  24962: 99999,
  24963: 99999,
  24966: 99999,
  24970: 99999,
  24974: 99999,
  24976: 99999,
  24977: 99999,
  24981: 99999,
  24983: 99999,
  24984: 99999,
  24985: 99999,
  24986: 99999,
  24991: 99999,
  24993: 99999,
  25002: 13220,
  25003: 16620,
  25005: 99999,
  25007: 13220,
  25008: 13220,
  25009: 16620,
  25011: 26580,
  25015: 16620,
  25019: 16620,
  25021: 16620,
  25022: 34350,
  25024: 16620,
  25025: 16620,
  25026: 16620,
  25028: 16620,
  25030: 16620,
  25031: 13220,
  25033: 26580,
  25035: 16620,
  25036: 13220,
  25039: 16620,
  25040: 13220,
  25043: 16620,
  25044: 13220,
  25045: 16620,
  25047: 34350,
  25048: 13220,
  25049: 16620,
  25051: 16620,
  25053: 16620,
  25054: 16620,
  25059: 16620,
  25060: 13220,
  25061: 16620,
  25062: 13220,
  25063: 16620,
  25064: 16620,
  25067: 16620,
  25070: 26580,
  25071: 16620,
  25075: 16620,
  25076: 34350,
  25079: 16620,
  25081: 16620,
  25082: 26580,
  25083: 13220,
  25085: 13220,
  25086: 16620,
  25088: 16620,
  25090: 13220,
  25093: 16620,
  25102: 16620,
  25103: 16620,
  25106: 38580,
  25107: 16620,
  25108: 16620,
  25109: 26580,
  25110: 16620,
  25111: 16620,
  25112: 16620,
  25113: 16620,
  25114: 16620,
  25115: 13220,
  25118: 13220,
  25119: 13220,
  25121: 34350,
  25123: 38580,
  25124: 16620,
  25125: 99999,
  25126: 16620,
  25130: 16620,
  25132: 16620,
  25133: 16620,
  25134: 16620,
  25136: 16620,
  25139: 13220,
  25140: 13220,
  25141: 16620,
  25142: 16620,
  25143: 16620,
  25148: 16620,
  25149: 16620,
  25154: 16620,
  25156: 16620,
  25159: 16620,
  25160: 16620,
  25161: 13220,
  25162: 16620,
  25164: 16620,
  25165: 16620,
  25168: 26580,
  25169: 16620,
  25173: 13220,
  25174: 13220,
  25177: 26580,
  25180: 13220,
  25181: 16620,
  25183: 34350,
  25185: 13220,
  25186: 13220,
  25187: 38580,
  25193: 16620,
  25201: 16620,
  25202: 16620,
  25203: 16620,
  25204: 16620,
  25205: 16620,
  25206: 16620,
  25208: 16620,
  25209: 13220,
  25211: 16620,
  25213: 26580,
  25214: 16620,
  25231: 16620,
  25234: 99999,
  25235: 16620,
  25239: 38580,
  25241: 38580,
  25243: 16620,
  25244: 99999,
  25245: 16620,
  25247: 38580,
  25248: 26580,
  25251: 99999,
  25252: 99999,
  25253: 38580,
  25259: 99999,
  25260: 38580,
  25261: 99999,
  25262: 16620,
  25264: 16620,
  25265: 38580,
  25266: 99999,
  25267: 99999,
  25268: 99999,
  25270: 37620,
  25271: 16620,
  25275: 37620,
  25276: 99999,
  25285: 16620,
  25286: 16620,
  25287: 38580,
  25301: 16620,
  25302: 16620,
  25303: 16620,
  25304: 16620,
  25305: 16620,
  25306: 16620,
  25309: 16620,
  25311: 16620,
  25312: 26580,
  25313: 16620,
  25314: 16620,
  25315: 16620,
  25317: 16620,
  25320: 16620,
  25321: 16620,
  25322: 16620,
  25323: 16620,
  25324: 16620,
  25325: 16620,
  25326: 16620,
  25327: 16620,
  25328: 16620,
  25329: 16620,
  25330: 16620,
  25331: 16620,
  25332: 16620,
  25333: 16620,
  25334: 16620,
  25335: 16620,
  25336: 16620,
  25337: 16620,
  25338: 16620,
  25339: 16620,
  25361: 16620,
  25362: 16620,
  25364: 16620,
  25387: 16620,
  25389: 16620,
  25396: 16620,
  25401: 25180,
  25402: 25180,
  25403: 25180,
  25404: 47900,
  25405: 25180,
  25411: 25180,
  25413: 25180,
  25414: 47900,
  25419: 25180,
  25420: 25180,
  25421: 25180,
  25422: 25180,
  25423: 47900,
  25425: 47900,
  25427: 25180,
  25428: 25180,
  25430: 47900,
  25431: 49020,
  25432: 47900,
  25434: 49020,
  25437: 49020,
  25438: 47900,
  25441: 47900,
  25442: 47900,
  25443: 47900,
  25444: 49020,
  25446: 47900,
  25501: 16620,
  25502: 38580,
  25503: 38580,
  25504: 26580,
  25505: 34350,
  25506: 16620,
  25507: 26580,
  25508: 16620,
  25510: 26580,
  25511: 26580,
  25512: 26580,
  25514: 26580,
  25515: 38580,
  25517: 26580,
  25520: 26580,
  25521: 16620,
  25523: 16620,
  25524: 34350,
  25526: 26580,
  25529: 16620,
  25530: 26580,
  25534: 26580,
  25535: 26580,
  25537: 26580,
  25540: 16620,
  25541: 38580,
  25544: 16620,
  25545: 26580,
  25547: 34350,
  25550: 38580,
  25555: 26580,
  25557: 26580,
  25559: 26580,
  25560: 26580,
  25562: 26580,
  25564: 16620,
  25565: 16620,
  25567: 16620,
  25569: 26580,
  25570: 26580,
  25571: 16620,
  25573: 16620,
  25601: 34350,
  25606: 34350,
  25607: 34350,
  25608: 99999,
  25611: 34350,
  25612: 34350,
  25614: 34350,
  25617: 99999,
  25621: 99999,
  25624: 34350,
  25625: 34350,
  25628: 34350,
  25630: 34350,
  25632: 34350,
  25634: 34350,
  25635: 34350,
  25637: 34350,
  25638: 34350,
  25639: 34350,
  25644: 34350,
  25646: 34350,
  25647: 34350,
  25649: 34350,
  25650: 99999,
  25651: 99999,
  25652: 34350,
  25653: 34350,
  25654: 34350,
  25661: 99999,
  25666: 99999,
  25667: 99999,
  25669: 26580,
  25670: 99999,
  25671: 34350,
  25672: 99999,
  25674: 99999,
  25676: 99999,
  25678: 99999,
  25685: 99999,
  25686: 99999,
  25688: 99999,
  25690: 99999,
  25691: 99999,
  25692: 99999,
  25696: 99999,
  25699: 16620,
  25701: 26580,
  25702: 26580,
  25703: 26580,
  25704: 26580,
  25705: 26580,
  25706: 26580,
  25707: 26580,
  25708: 26580,
  25709: 26580,
  25710: 26580,
  25711: 26580,
  25712: 26580,
  25713: 26580,
  25714: 26580,
  25715: 26580,
  25716: 26580,
  25717: 26580,
  25718: 26580,
  25719: 26580,
  25720: 26580,
  25721: 26580,
  25722: 26580,
  25723: 26580,
  25724: 26580,
  25725: 26580,
  25726: 26580,
  25727: 26580,
  25728: 26580,
  25729: 26580,
  25755: 26580,
  25772: 26580,
  25773: 26580,
  25774: 26580,
  25775: 26580,
  25776: 26580,
  25777: 26580,
  25778: 26580,
  25779: 26580,
  25801: 13220,
  25802: 13220,
  25810: 99999,
  25811: 13220,
  25812: 13220,
  25813: 13220,
  25817: 13220,
  25818: 13220,
  25820: 14140,
  25823: 13220,
  25825: 13220,
  25826: 99999,
  25827: 13220,
  25831: 13220,
  25832: 13220,
  25836: 13220,
  25837: 13220,
  25839: 13220,
  25840: 13220,
  25841: 99999,
  25843: 13220,
  25844: 13220,
  25845: 99999,
  25846: 13220,
  25848: 99999,
  25849: 13220,
  25851: 13220,
  25853: 13220,
  25854: 13220,
  25855: 13220,
  25857: 13220,
  25860: 13220,
  25862: 13220,
  25864: 13220,
  25865: 13220,
  25866: 13220,
  25868: 13220,
  25870: 99999,
  25871: 13220,
  25873: 13220,
  25875: 99999,
  25876: 99999,
  25878: 13220,
  25879: 13220,
  25880: 13220,
  25882: 99999,
  25888: 13220,
  25901: 13220,
  25902: 14140,
  25904: 13220,
  25906: 13220,
  25907: 13220,
  25908: 13220,
  25909: 13220,
  25911: 13220,
  25913: 99999,
  25915: 13220,
  25916: 99999,
  25917: 13220,
  25918: 13220,
  25919: 13220,
  25920: 13220,
  25921: 13220,
  25922: 14140,
  25927: 13220,
  25928: 99999,
  25932: 13220,
  25936: 13220,
  25938: 13220,
  25942: 13220,
  25943: 99999,
  25951: 13220,
  25958: 99999,
  25962: 99999,
  25966: 99999,
  25969: 99999,
  25971: 14140,
  25972: 99999,
  25976: 99999,
  25977: 99999,
  25978: 99999,
  25979: 14140,
  25981: 99999,
  25984: 99999,
  25985: 99999,
  25986: 99999,
  25989: 99999,
  26003: 48540,
  26030: 48260,
  26031: 48540,
  26032: 48260,
  26033: 99999,
  26034: 48260,
  26035: 48260,
  26036: 48540,
  26037: 48260,
  26038: 48540,
  26039: 99999,
  26040: 48540,
  26041: 48540,
  26047: 48260,
  26050: 48260,
  26055: 99999,
  26056: 48260,
  26058: 48260,
  26059: 48540,
  26060: 48540,
  26062: 48260,
  26070: 48540,
  26074: 48540,
  26075: 48260,
  26101: 37620,
  26102: 37620,
  26103: 37620,
  26104: 37620,
  26105: 37620,
  26106: 37620,
  26120: 37620,
  26121: 37620,
  26133: 37620,
  26134: 99999,
  26136: 99999,
  26137: 99999,
  26138: 99999,
  26141: 37620,
  26142: 37620,
  26143: 37620,
  26146: 99999,
  26147: 99999,
  26148: 99999,
  26149: 99999,
  26150: 37620,
  26151: 99999,
  26152: 99999,
  26155: 99999,
  26159: 99999,
  26160: 16620,
  26161: 37620,
  26162: 99999,
  26164: 16620,
  26167: 99999,
  26169: 16620,
  26170: 99999,
  26175: 99999,
  26178: 99999,
  26180: 37620,
  26181: 37620,
  26184: 37620,
  26187: 37620,
  26201: 99999,
  26202: 99999,
  26203: 99999,
  26205: 99999,
  26206: 99999,
  26208: 99999,
  26209: 99999,
  26210: 99999,
  26215: 99999,
  26217: 99999,
  26218: 99999,
  26222: 99999,
  26224: 99999,
  26228: 99999,
  26229: 99999,
  26230: 21180,
  26234: 99999,
  26236: 99999,
  26237: 99999,
  26238: 99999,
  26241: 21180,
  26250: 99999,
  26253: 21180,
  26254: 99999,
  26257: 21180,
  26259: 21180,
  26260: 99999,
  26261: 99999,
  26263: 21180,
  26264: 99999,
  26266: 99999,
  26267: 21180,
  26268: 21180,
  26269: 99999,
  26270: 99999,
  26271: 99999,
  26273: 21180,
  26275: 99999,
  26276: 99999,
  26278: 21180,
  26280: 21180,
  26282: 99999,
  26283: 99999,
  26285: 21180,
  26287: 34060,
  26288: 99999,
  26289: 99999,
  26291: 99999,
  26292: 99999,
  26293: 21180,
  26294: 99999,
  26296: 21180,
  26298: 99999,
  26301: 17220,
  26306: 17220,
  26320: 99999,
  26321: 99999,
  26323: 17220,
  26325: 99999,
  26327: 99999,
  26330: 17220,
  26335: 99999,
  26337: 37620,
  26338: 99999,
  26339: 17220,
  26342: 99999,
  26343: 99999,
  26346: 99999,
  26347: 17220,
  26348: 99999,
  26349: 99999,
  26351: 99999,
  26354: 34060,
  26361: 17220,
  26362: 99999,
  26366: 17220,
  26369: 17220,
  26372: 99999,
  26374: 17220,
  26376: 99999,
  26377: 99999,
  26378: 17220,
  26384: 99999,
  26385: 99999,
  26386: 17220,
  26404: 17220,
  26405: 99999,
  26408: 17220,
  26410: 34060,
  26411: 17220,
  26412: 99999,
  26415: 99999,
  26416: 17220,
  26419: 99999,
  26421: 99999,
  26422: 17220,
  26424: 17220,
  26425: 34060,
  26426: 17220,
  26430: 99999,
  26431: 21900,
  26434: 99999,
  26435: 17220,
  26436: 17220,
  26437: 99999,
  26438: 17220,
  26440: 99999,
  26443: 17220,
  26444: 34060,
  26447: 99999,
  26448: 17220,
  26451: 17220,
  26452: 99999,
  26456: 99999,
  26463: 17220,
  26501: 34060,
  26504: 34060,
  26505: 34060,
  26506: 34060,
  26507: 34060,
  26508: 34060,
  26519: 34060,
  26520: 34060,
  26521: 34060,
  26524: 34060,
  26525: 34060,
  26527: 34060,
  26531: 34060,
  26534: 34060,
  26537: 34060,
  26541: 34060,
  26542: 34060,
  26543: 34060,
  26544: 34060,
  26546: 34060,
  26547: 34060,
  26554: 21900,
  26555: 21900,
  26559: 21900,
  26560: 21900,
  26561: 99999,
  26562: 34060,
  26563: 21900,
  26566: 21900,
  26568: 17220,
  26570: 21900,
  26571: 21900,
  26572: 21900,
  26574: 21900,
  26575: 99999,
  26576: 21900,
  26578: 21900,
  26581: 99999,
  26582: 21900,
  26585: 21900,
  26586: 21900,
  26587: 21900,
  26588: 34060,
  26590: 34060,
  26591: 21900,
  26601: 99999,
  26610: 99999,
  26611: 99999,
  26615: 99999,
  26617: 16620,
  26619: 99999,
  26621: 99999,
  26623: 99999,
  26624: 99999,
  26627: 99999,
  26629: 99999,
  26631: 99999,
  26636: 99999,
  26638: 99999,
  26651: 99999,
  26656: 13220,
  26660: 99999,
  26662: 99999,
  26667: 99999,
  26671: 99999,
  26675: 99999,
  26676: 99999,
  26678: 99999,
  26679: 99999,
  26680: 99999,
  26681: 99999,
  26684: 99999,
  26690: 13220,
  26691: 99999,
  26704: 49020,
  26705: 34060,
  26707: 99999,
  26710: 49020,
  26711: 49020,
  26714: 49020,
  26716: 34060,
  26717: 99999,
  26719: 19060,
  26720: 99999,
  26722: 49020,
  26726: 99999,
  26731: 19060,
  26739: 99999,
  26743: 19060,
  26750: 19060,
  26753: 19060,
  26755: 49020,
  26757: 19060,
  26761: 49020,
  26763: 49020,
  26764: 34060,
  26767: 19060,
  26801: 99999,
  26802: 99999,
  26804: 99999,
  26807: 99999,
  26808: 49020,
  26810: 99999,
  26812: 99999,
  26814: 99999,
  26815: 99999,
  26817: 49020,
  26818: 99999,
  26823: 49020,
  26833: 99999,
  26836: 99999,
  26838: 99999,
  26845: 49020,
  26847: 99999,
  26851: 49020,
  26852: 49020,
  26855: 99999,
  26865: 49020,
  26866: 99999,
  26884: 99999,
  26886: 99999,
  27006: 49180,
  27007: 34340,
  27009: 49180,
  27010: 49180,
  27011: 49180,
  27012: 49180,
  27013: 16740,
  27014: 49180,
  27016: 49180,
  27017: 34340,
  27018: 49180,
  27019: 49180,
  27020: 49180,
  27021: 49180,
  27022: 49180,
  27023: 49180,
  27024: 34340,
  27025: 49180,
  27027: 24660,
  27028: 16740,
  27030: 34340,
  27040: 49180,
  27041: 34340,
  27042: 49180,
  27043: 34340,
  27045: 49180,
  27046: 49180,
  27047: 34340,
  27048: 24660,
  27049: 34340,
  27050: 49180,
  27051: 49180,
  27052: 49180,
  27053: 34340,
  27054: 16740,
  27055: 16740,
  27094: 49180,
  27099: 49180,
  27101: 49180,
  27102: 49180,
  27103: 49180,
  27104: 49180,
  27105: 49180,
  27106: 49180,
  27107: 49180,
  27108: 49180,
  27109: 49180,
  27110: 49180,
  27111: 49180,
  27113: 49180,
  27114: 49180,
  27115: 49180,
  27116: 49180,
  27117: 49180,
  27120: 49180,
  27127: 49180,
  27130: 49180,
  27150: 49180,
  27152: 49180,
  27155: 49180,
  27157: 49180,
  27199: 49180,
  27201: 15500,
  27202: 15500,
  27203: 24660,
  27205: 24660,
  27207: 20500,
  27208: 20500,
  27209: 38240,
  27212: 99999,
  27213: 20500,
  27214: 24660,
  27215: 15500,
  27216: 15500,
  27217: 99999,
  27229: 40460,
  27230: 24660,
  27231: 20500,
  27233: 24660,
  27235: 24660,
  27239: 24660,
  27242: 38240,
  27243: 20500,
  27244: 24660,
  27247: 99999,
  27248: 24660,
  27249: 99999,
  27252: 38240,
  27253: 15500,
  27256: 20500,
  27258: 15500,
  27259: 38240,
  27260: 24660,
  27261: 24660,
  27262: 49180,
  27263: 24660,
  27265: 49180,
  27268: 24660,
  27278: 20500,
  27281: 40460,
  27282: 24660,
  27283: 24660,
  27284: 24660,
  27285: 49180,
  27288: 24660,
  27289: 24660,
  27291: 20500,
  27292: 24660,
  27293: 49180,
  27294: 49180,
  27295: 49180,
  27298: 24660,
  27299: 49180,
  27301: 24660,
  27302: 99999,
  27305: 99999,
  27306: 99999,
  27310: 24660,
  27311: 24660,
  27312: 20500,
  27313: 24660,
  27314: 99999,
  27315: 99999,
  27316: 24660,
  27317: 24660,
  27320: 24660,
  27325: 38240,
  27326: 24660,
  27330: 41820,
  27331: 41820,
  27332: 20500,
  27340: 15500,
  27341: 99999,
  27342: 24660,
  27343: 99999,
  27344: 20500,
  27349: 15500,
  27350: 24660,
  27351: 49180,
  27355: 20500,
  27356: 99999,
  27357: 24660,
  27358: 24660,
  27359: 15500,
  27360: 49180,
  27370: 24660,
  27371: 99999,
  27373: 49180,
  27374: 49180,
  27375: 24660,
  27376: 38240,
  27377: 24660,
  27379: 99999,
  27401: 24660,
  27402: 24660,
  27403: 24660,
  27404: 24660,
  27405: 24660,
  27406: 24660,
  27407: 24660,
  27408: 24660,
  27409: 24660,
  27410: 24660,
  27411: 24660,
  27412: 24660,
  27413: 24660,
  27415: 24660,
  27416: 24660,
  27417: 24660,
  27419: 24660,
  27420: 24660,
  27425: 24660,
  27429: 24660,
  27435: 24660,
  27438: 24660,
  27455: 24660,
  27495: 24660,
  27497: 24660,
  27498: 24660,
  27499: 24660,
  27501: 39580,
  27502: 20500,
  27503: 20500,
  27504: 22180,
  27505: 41820,
  27506: 22180,
  27507: 25780,
  27508: 39580,
  27509: 20500,
  27510: 20500,
  27511: 39580,
  27512: 39580,
  27513: 39580,
  27514: 20500,
  27515: 20500,
  27516: 20500,
  27517: 20500,
  27518: 39580,
  27519: 20500,
  27520: 39580,
  27521: 22180,
  27522: 20500,
  27523: 20500,
  27524: 24140,
  27525: 20500,
  27526: 22180,
  27527: 39580,
  27528: 39580,
  27529: 39580,
  27530: 24140,
  27531: 24140,
  27532: 24140,
  27533: 24140,
  27534: 99999,
  27536: 25780,
  27537: 99999,
  27539: 39580,
  27540: 39580,
  27541: 20500,
  27542: 24140,
  27543: 22180,
  27544: 39580,
  27545: 39580,
  27546: 22180,
  27549: 39580,
  27551: 99999,
  27552: 22180,
  27553: 99999,
  27555: 39580,
  27556: 25780,
  27557: 48980,
  27559: 20500,
  27560: 39580,
  27562: 39580,
  27563: 99999,
  27565: 25780,
  27568: 39580,
  27569: 24140,
  27570: 99999,
  27571: 39580,
  27572: 20500,
  27573: 20500,
  27574: 20500,
  27576: 39580,
  27577: 39580,
  27581: 20500,
  27582: 20500,
  27583: 20500,
  27584: 25780,
  27586: 99999,
  27587: 39580,
  27588: 39580,
  27589: 99999,
  27591: 39580,
  27592: 39580,
  27593: 39580,
  27594: 99999,
  27596: 39580,
  27597: 39580,
  27599: 20500,
  27601: 39580,
  27602: 39580,
  27603: 39580,
  27604: 39580,
  27605: 39580,
  27606: 39580,
  27607: 39580,
  27608: 39580,
  27609: 39580,
  27610: 39580,
  27611: 39580,
  27612: 39580,
  27613: 20500,
  27614: 39580,
  27615: 39580,
  27616: 39580,
  27617: 20500,
  27619: 39580,
  27620: 39580,
  27622: 39580,
  27623: 39580,
  27624: 39580,
  27627: 39580,
  27628: 39580,
  27629: 39580,
  27636: 39580,
  27640: 39580,
  27656: 39580,
  27658: 39580,
  27661: 39580,
  27675: 39580,
  27676: 39580,
  27690: 39580,
  27697: 39580,
  27699: 39580,
  27701: 20500,
  27702: 20500,
  27703: 39580,
  27704: 20500,
  27705: 20500,
  27706: 20500,
  27707: 20500,
  27708: 20500,
  27709: 20500,
  27710: 20500,
  27712: 20500,
  27713: 20500,
  27715: 20500,
  27717: 20500,
  27722: 20500,
  27801: 40580,
  27802: 40580,
  27803: 48980,
  27804: 40580,
  27805: 40260,
  27806: 47820,
  27807: 40580,
  27808: 47820,
  27809: 40580,
  27810: 47820,
  27811: 24780,
  27812: 40580,
  27813: 48980,
  27814: 47820,
  27815: 40580,
  27816: 39580,
  27817: 47820,
  27818: 99999,
  27819: 40580,
  27820: 40260,
  27821: 47820,
  27822: 40580,
  27823: 40260,
  27824: 99999,
  27825: 99999,
  27826: 99999,
  27827: 24780,
  27828: 24780,
  27829: 40580,
  27830: 48980,
  27831: 40260,
  27832: 40260,
  27833: 24780,
  27834: 24780,
  27835: 24780,
  27836: 24780,
  27837: 24780,
  27839: 40260,
  27840: 99999,
  27841: 99999,
  27842: 40260,
  27843: 40260,
  27844: 40260,
  27845: 40260,
  27846: 99999,
  27847: 99999,
  27849: 99999,
  27850: 40260,
  27851: 48980,
  27852: 40580,
  27853: 40260,
  27855: 40260,
  27856: 40580,
  27857: 99999,
  27858: 24780,
  27860: 47820,
  27861: 99999,
  27862: 40260,
  27863: 24140,
  27864: 40580,
  27865: 47820,
  27866: 40260,
  27867: 40260,
  27868: 40580,
  27869: 99999,
  27870: 40260,
  27871: 47820,
  27872: 99999,
  27873: 48980,
  27874: 40260,
  27875: 99999,
  27876: 40260,
  27877: 40260,
  27878: 40580,
  27879: 24780,
  27880: 48980,
  27881: 40580,
  27882: 40580,
  27883: 99999,
  27884: 24780,
  27885: 99999,
  27886: 24780,
  27887: 40260,
  27888: 48980,
  27889: 24780,
  27890: 40260,
  27891: 40580,
  27892: 99999,
  27893: 48980,
  27894: 48980,
  27895: 48980,
  27896: 48980,
  27897: 99999,
  27906: 21020,
  27907: 21020,
  27909: 21020,
  27910: 99999,
  27915: 28620,
  27916: 47260,
  27917: 47260,
  27919: 21020,
  27920: 28620,
  27921: 47260,
  27922: 99999,
  27923: 47260,
  27924: 99999,
  27925: 99999,
  27926: 47260,
  27927: 47260,
  27928: 99999,
  27929: 47260,
  27932: 21020,
  27935: 47260,
  27936: 28620,
  27937: 47260,
  27938: 47260,
  27939: 47260,
  27941: 47260,
  27942: 99999,
  27943: 28620,
  27944: 21020,
  27946: 21020,
  27947: 47260,
  27948: 28620,
  27949: 28620,
  27950: 47260,
  27953: 28620,
  27954: 28620,
  27956: 47260,
  27957: 99999,
  27958: 47260,
  27959: 28620,
  27960: 99999,
  27962: 99999,
  27964: 47260,
  27965: 47260,
  27966: 47260,
  27967: 99999,
  27968: 28620,
  27969: 47260,
  27970: 99999,
  27973: 47260,
  27974: 47260,
  27976: 47260,
  27978: 28620,
  27979: 47260,
  27980: 99999,
  27981: 28620,
  27983: 99999,
  27985: 21020,
  27986: 99999,
  28001: 10620,
  28002: 10620,
  28006: 16740,
  28007: 16740,
  28009: 10620,
  28010: 16740,
  28012: 16740,
  28016: 16740,
  28017: 43140,
  28018: 22580,
  28019: 22580,
  28020: 22580,
  28021: 43140,
  28023: 16740,
  28024: 22580,
  28025: 16740,
  28026: 16740,
  28027: 16740,
  28031: 16740,
  28032: 16740,
  28033: 16740,
  28034: 16740,
  28035: 16740,
  28036: 16740,
  28037: 25860,
  28038: 43140,
  28039: 16740,
  28040: 43140,
  28041: 16740,
  28042: 43140,
  28043: 22580,
  28052: 16740,
  28053: 16740,
  28054: 16740,
  28055: 16740,
  28056: 16740,
  28070: 16740,
  28071: 16740,
  28072: 16740,
  28073: 43140,
  28074: 22580,
  28075: 16740,
  28076: 22580,
  28077: 16740,
  28078: 16740,
  28079: 16740,
  28080: 16740,
  28081: 16740,
  28082: 16740,
  28083: 16740,
  28086: 43140,
  28088: 16740,
  28089: 43140,
  28090: 16740,
  28091: 16740,
  28092: 16740,
  28093: 16740,
  28097: 10620,
  28098: 16740,
  28101: 16740,
  28102: 16740,
  28103: 16740,
  28104: 16740,
  28105: 16740,
  28106: 16740,
  28107: 10620,
  28108: 16740,
  28109: 10620,
  28110: 16740,
  28111: 16740,
  28112: 16740,
  28114: 22580,
  28115: 16740,
  28117: 16740,
  28119: 16740,
  28120: 16740,
  28123: 16740,
  28124: 16740,
  28125: 16740,
  28126: 16740,
  28127: 99999,
  28128: 10620,
  28129: 10620,
  28130: 16740,
  28133: 16740,
  28134: 16740,
  28135: 16740,
  28136: 43140,
  28137: 10620,
  28138: 16740,
  28139: 99999,
  28144: 16740,
  28145: 16740,
  28146: 16740,
  28147: 16740,
  28150: 22580,
  28151: 43140,
  28152: 43140,
  28159: 16740,
  28160: 22580,
  28163: 10620,
  28164: 16740,
  28166: 16740,
  28167: 32000,
  28168: 25860,
  28169: 43140,
  28170: 16740,
  28173: 16740,
  28174: 16740,
  28201: 16740,
  28202: 16740,
  28203: 16740,
  28204: 16740,
  28205: 16740,
  28206: 16740,
  28207: 16740,
  28208: 16740,
  28209: 16740,
  28210: 16740,
  28211: 16740,
  28212: 16740,
  28213: 16740,
  28214: 16740,
  28215: 16740,
  28216: 16740,
  28217: 16740,
  28219: 16740,
  28220: 16740,
  28221: 16740,
  28222: 16740,
  28223: 16740,
  28224: 16740,
  28226: 16740,
  28227: 16740,
  28228: 16740,
  28229: 16740,
  28230: 16740,
  28231: 16740,
  28232: 16740,
  28233: 16740,
  28234: 16740,
  28235: 16740,
  28236: 16740,
  28237: 16740,
  28241: 16740,
  28244: 16740,
  28246: 16740,
  28247: 16740,
  28256: 16740,
  28262: 16740,
  28266: 16740,
  28269: 16740,
  28270: 16740,
  28271: 16740,
  28273: 16740,
  28274: 16740,
  28277: 16740,
  28278: 16740,
  28280: 16740,
  28281: 16740,
  28282: 16740,
  28284: 16740,
  28285: 16740,
  28287: 16740,
  28288: 16740,
  28297: 16740,
  28299: 16740,
  28301: 22180,
  28302: 22180,
  28303: 22180,
  28304: 22180,
  28305: 22180,
  28306: 22180,
  28307: 22180,
  28308: 22180,
  28309: 22180,
  28310: 22180,
  28311: 22180,
  28312: 22180,
  28314: 22180,
  28315: 38240,
  28318: 22180,
  28320: 99999,
  28323: 22180,
  28325: 99999,
  28326: 22180,
  28327: 38240,
  28328: 99999,
  28329: 99999,
  28330: 40460,
  28331: 22180,
  28332: 99999,
  28333: 24140,
  28334: 39580,
  28337: 99999,
  28338: 40460,
  28339: 22180,
  28340: 31300,
  28341: 99999,
  28342: 22180,
  28343: 29900,
  28344: 22180,
  28345: 40460,
  28347: 40460,
  28348: 22180,
  28349: 99999,
  28350: 38240,
  28351: 40460,
  28352: 31300,
  28353: 29900,
  28355: 41820,
  28356: 22180,
  28357: 22180,
  28358: 31300,
  28359: 31300,
  28360: 31300,
  28362: 31300,
  28363: 29900,
  28364: 29900,
  28365: 24140,
  28366: 39580,
  28367: 40460,
  28368: 22180,
  28369: 31300,
  28370: 38240,
  28371: 22180,
  28372: 31300,
  28373: 38240,
  28374: 38240,
  28375: 31300,
  28376: 22180,
  28377: 31300,
  28378: 31300,
  28379: 40460,
  28380: 40460,
  28382: 99999,
  28383: 31300,
  28384: 31300,
  28385: 99999,
  28386: 22180,
  28387: 38240,
  28388: 38240,
  28390: 22180,
  28391: 99999,
  28392: 99999,
  28393: 99999,
  28394: 38240,
  28395: 22180,
  28396: 29900,
  28398: 99999,
  28399: 99999,
  28401: 34820,
  28402: 48900,
  28403: 48900,
  28404: 48900,
  28405: 48900,
  28406: 48900,
  28408: 48900,
  28409: 48900,
  28410: 48900,
  28411: 48900,
  28412: 48900,
  28420: 34820,
  28421: 99999,
  28422: 34820,
  28423: 99999,
  28424: 99999,
  28425: 48900,
  28428: 48900,
  28429: 48900,
  28430: 99999,
  28431: 99999,
  28432: 99999,
  28433: 99999,
  28434: 99999,
  28435: 48900,
  28436: 34820,
  28438: 99999,
  28439: 99999,
  28441: 22180,
  28442: 99999,
  28443: 48900,
  28444: 99999,
  28445: 48900,
  28447: 48900,
  28448: 99999,
  28449: 48900,
  28450: 99999,
  28451: 34820,
  28452: 34820,
  28453: 99999,
  28454: 48900,
  28455: 99999,
  28456: 99999,
  28457: 48900,
  28458: 99999,
  28459: 34820,
  28460: 27340,
  28461: 34820,
  28462: 34820,
  28463: 99999,
  28464: 99999,
  28465: 34820,
  28466: 99999,
  28467: 34820,
  28468: 34820,
  28469: 34820,
  28470: 34820,
  28472: 99999,
  28478: 48900,
  28479: 34820,
  28480: 48900,
  28501: 28820,
  28504: 28820,
  28508: 99999,
  28509: 35100,
  28510: 35100,
  28511: 33980,
  28512: 33980,
  28513: 24780,
  28515: 35100,
  28516: 33980,
  28518: 27340,
  28519: 35100,
  28520: 33980,
  28521: 99999,
  28522: 35100,
  28523: 35100,
  28524: 33980,
  28525: 28820,
  28526: 35100,
  28527: 35100,
  28528: 33980,
  28529: 35100,
  28530: 35100,
  28531: 33980,
  28532: 33980,
  28533: 35100,
  28537: 35100,
  28538: 28820,
  28539: 27340,
  28540: 27340,
  28541: 27340,
  28542: 27340,
  28543: 27340,
  28544: 27340,
  28545: 27340,
  28546: 27340,
  28547: 27340,
  28551: 28820,
  28552: 35100,
  28553: 33980,
  28554: 99999,
  28555: 27340,
  28556: 35100,
  28557: 33980,
  28560: 35100,
  28561: 35100,
  28562: 35100,
  28563: 35100,
  28564: 35100,
  28570: 33980,
  28571: 35100,
  28572: 28820,
  28573: 35100,
  28574: 35100,
  28575: 33980,
  28577: 33980,
  28578: 24140,
  28579: 33980,
  28580: 28820,
  28581: 33980,
  28582: 33980,
  28583: 35100,
  28584: 27340,
  28585: 35100,
  28586: 35100,
  28587: 35100,
  28590: 24780,
  28594: 33980,
  28601: 25860,
  28602: 25860,
  28603: 25860,
  28604: 14380,
  28605: 14380,
  28606: 25860,
  28607: 14380,
  28608: 14380,
  28609: 25860,
  28610: 25860,
  28611: 99999,
  28612: 43140,
  28613: 25860,
  28615: 99999,
  28616: 99999,
  28617: 99999,
  28618: 99999,
  28619: 25860,
  28621: 34340,
  28622: 99999,
  28623: 99999,
  28624: 35900,
  28625: 49180,
  28626: 99999,
  28627: 99999,
  28628: 25860,
  28629: 99999,
  28630: 25860,
  28631: 99999,
  28633: 25860,
  28634: 49180,
  28635: 35900,
  28636: 16740,
  28637: 25860,
  28638: 25860,
  28640: 99999,
  28641: 25860,
  28642: 35900,
  28643: 99999,
  28644: 35900,
  28645: 25860,
  28646: 99999,
  28647: 25860,
  28649: 35900,
  28650: 16740,
  28651: 35900,
  28652: 99999,
  28653: 99999,
  28654: 35900,
  28655: 32000,
  28656: 35900,
  28657: 99999,
  28658: 25860,
  28659: 35900,
  28660: 16740,
  28661: 25860,
  28662: 99999,
  28663: 99999,
  28664: 99999,
  28665: 35900,
  28666: 25860,
  28667: 25860,
  28668: 99999,
  28669: 34340,
  28670: 35900,
  28671: 25860,
  28672: 99999,
  28673: 16740,
  28675: 99999,
  28676: 35900,
  28677: 16740,
  28678: 16740,
  28679: 14380,
  28680: 25860,
  28681: 25860,
  28682: 25860,
  28683: 35900,
  28684: 14380,
  28685: 35900,
  28687: 16740,
  28688: 16740,
  28689: 35900,
  28690: 25860,
  28692: 14380,
  28693: 99999,
  28694: 99999,
  28697: 35900,
  28698: 14380,
  28699: 16740,
  28701: 11700,
  28702: 99999,
  28704: 11700,
  28705: 99999,
  28707: 19000,
  28708: 14820,
  28709: 11700,
  28710: 11700,
  28711: 22580,
  28712: 14820,
  28713: 19000,
  28714: 32000,
  28715: 11700,
  28716: 11700,
  28717: 19000,
  28718: 14820,
  28719: 11700,
  28720: 22580,
  28721: 11700,
  28722: 99999,
  28723: 11700,
  28724: 11700,
  28725: 19000,
  28726: 11700,
  28727: 11700,
  28728: 11700,
  28729: 11700,
  28730: 11700,
  28731: 11700,
  28732: 11700,
  28733: 99999,
  28734: 99999,
  28735: 11700,
  28736: 19000,
  28738: 11700,
  28739: 11700,
  28740: 99999,
  28741: 99999,
  28742: 11700,
  28743: 11700,
  28744: 99999,
  28745: 11700,
  28746: 99999,
  28747: 19000,
  28748: 11700,
  28749: 32000,
  28750: 99999,
  28751: 19000,
  28752: 22580,
  28753: 11700,
  28754: 11700,
  28755: 99999,
  28756: 11700,
  28757: 11700,
  28758: 11700,
  28759: 14820,
  28760: 11700,
  28761: 25860,
  28762: 32000,
  28763: 99999,
  28765: 99999,
  28766: 11700,
  28768: 11700,
  28770: 11700,
  28771: 99999,
  28772: 14820,
  28773: 99999,
  28774: 19000,
  28775: 99999,
  28776: 11700,
  28777: 32000,
  28778: 11700,
  28779: 99999,
  28781: 99999,
  28782: 99999,
  28783: 19000,
  28784: 11700,
  28785: 11700,
  28786: 11700,
  28787: 11700,
  28788: 19000,
  28789: 19000,
  28790: 11700,
  28791: 11700,
  28792: 11700,
  28793: 11700,
  28801: 11700,
  28802: 11700,
  28803: 11700,
  28804: 11700,
  28805: 11700,
  28806: 11700,
  28810: 11700,
  28813: 11700,
  28814: 11700,
  28815: 11700,
  28816: 11700,
  28901: 99999,
  28902: 99999,
  28903: 99999,
  28904: 99999,
  28905: 99999,
  28906: 99999,
  28909: 99999,
  29001: 44940,
  29002: 17900,
  29003: 99999,
  29006: 17900,
  29009: 99999,
  29010: 99999,
  29014: 17900,
  29015: 17900,
  29016: 17900,
  29018: 16700,
  29020: 17900,
  29021: 17900,
  29030: 17900,
  29031: 16740,
  29032: 99999,
  29033: 17900,
  29036: 35140,
  29037: 24860,
  29038: 99999,
  29039: 36700,
  29040: 99999,
  29041: 44940,
  29042: 99999,
  29044: 17900,
  29045: 17900,
  29046: 99999,
  29047: 36700,
  29048: 36700,
  29051: 44940,
  29052: 17900,
  29053: 17900,
  29054: 17900,
  29055: 16740,
  29056: 99999,
  29058: 17900,
  29059: 36700,
  29061: 17900,
  29062: 44940,
  29063: 17900,
  29065: 17900,
  29067: 17900,
  29069: 22500,
  29070: 17900,
  29071: 17900,
  29072: 17900,
  29073: 17900,
  29074: 17900,
  29075: 17900,
  29078: 17900,
  29079: 22500,
  29080: 99999,
  29081: 99999,
  29082: 99999,
  29101: 99999,
  29102: 44940,
  29104: 99999,
  29105: 17900,
  29107: 36700,
  29108: 35140,
  29111: 99999,
  29112: 36700,
  29113: 36700,
  29114: 44940,
  29115: 36700,
  29116: 36700,
  29117: 36700,
  29118: 36700,
  29122: 35140,
  29123: 17900,
  29125: 44940,
  29126: 35140,
  29127: 17900,
  29128: 17900,
  29129: 17900,
  29130: 17900,
  29132: 17900,
  29133: 36700,
  29135: 17900,
  29137: 12260,
  29138: 12260,
  29142: 36700,
  29145: 35140,
  29146: 12260,
  29147: 17900,
  29148: 44940,
  29150: 44940,
  29151: 44940,
  29152: 44940,
  29153: 44940,
  29154: 44940,
  29160: 36700,
  29161: 22500,
  29162: 44940,
  29163: 36700,
  29164: 12260,
  29166: 17900,
  29168: 44940,
  29169: 17900,
  29170: 17900,
  29171: 17900,
  29172: 17900,
  29175: 17900,
  29177: 17900,
  29178: 35140,
  29180: 17900,
  29201: 17900,
  29202: 17900,
  29203: 17900,
  29204: 17900,
  29205: 17900,
  29206: 17900,
  29207: 17900,
  29208: 17900,
  29209: 17900,
  29210: 17900,
  29211: 17900,
  29212: 17900,
  29214: 17900,
  29215: 17900,
  29216: 17900,
  29217: 17900,
  29218: 17900,
  29219: 17900,
  29220: 17900,
  29221: 17900,
  29222: 17900,
  29223: 17900,
  29224: 17900,
  29225: 17900,
  29226: 17900,
  29227: 17900,
  29229: 17900,
  29230: 17900,
  29240: 17900,
  29250: 17900,
  29260: 17900,
  29290: 17900,
  29292: 17900,
  29301: 43900,
  29302: 43900,
  29303: 43900,
  29304: 43900,
  29305: 43900,
  29306: 23500,
  29307: 23500,
  29316: 43900,
  29319: 43900,
  29320: 43900,
  29321: 46420,
  29322: 43900,
  29323: 23500,
  29324: 43900,
  29325: 24860,
  29329: 43900,
  29330: 43900,
  29331: 43900,
  29332: 35140,
  29333: 43900,
  29334: 43900,
  29335: 43900,
  29336: 43900,
  29338: 43900,
  29340: 23500,
  29341: 23500,
  29346: 43900,
  29348: 43900,
  29349: 43900,
  29351: 24860,
  29353: 46420,
  29355: 24860,
  29356: 24860,
  29360: 24860,
  29364: 46420,
  29365: 43900,
  29368: 43900,
  29369: 43900,
  29370: 24860,
  29372: 46420,
  29373: 43900,
  29374: 46420,
  29375: 43900,
  29376: 43900,
  29377: 43900,
  29378: 43900,
  29379: 46420,
  29384: 24860,
  29385: 43900,
  29386: 43900,
  29388: 24860,
  29395: 46420,
  29401: 16700,
  29402: 16700,
  29403: 16700,
  29404: 16700,
  29405: 16700,
  29406: 16700,
  29407: 16700,
  29409: 16700,
  29410: 16700,
  29412: 16700,
  29413: 16700,
  29414: 16700,
  29415: 16700,
  29416: 16700,
  29417: 16700,
  29418: 16700,
  29419: 16700,
  29420: 16700,
  29422: 16700,
  29423: 16700,
  29424: 16700,
  29425: 16700,
  29426: 16700,
  29429: 16700,
  29431: 16700,
  29432: 99999,
  29434: 16700,
  29435: 99999,
  29436: 16700,
  29437: 16700,
  29438: 16700,
  29439: 16700,
  29440: 99999,
  29442: 23860,
  29445: 16700,
  29446: 99999,
  29447: 16700,
  29448: 16700,
  29449: 16700,
  29450: 16700,
  29451: 16700,
  29452: 99999,
  29453: 16700,
  29455: 16700,
  29456: 16700,
  29457: 16700,
  29458: 16700,
  29461: 16700,
  29464: 16700,
  29465: 16700,
  29466: 16700,
  29468: 16700,
  29469: 16700,
  29470: 16700,
  29471: 16700,
  29472: 16700,
  29474: 99999,
  29475: 99999,
  29476: 16700,
  29477: 36700,
  29479: 16700,
  29481: 99999,
  29482: 16700,
  29483: 16700,
  29484: 16700,
  29485: 16700,
  29486: 16700,
  29487: 16700,
  29488: 99999,
  29492: 16700,
  29493: 99999,
  29501: 22500,
  29502: 22500,
  29503: 22500,
  29504: 22500,
  29505: 22500,
  29506: 22500,
  29510: 99999,
  29511: 34820,
  29512: 13500,
  29516: 13500,
  29518: 99999,
  29519: 99999,
  29520: 99999,
  29525: 13500,
  29526: 34820,
  29527: 34820,
  29528: 34820,
  29530: 22500,
  29532: 22500,
  29536: 99999,
  29540: 22500,
  29541: 22500,
  29543: 99999,
  29544: 34820,
  29545: 34820,
  29546: 99999,
  29547: 99999,
  29550: 99999,
  29551: 22500,
  29554: 99999,
  29555: 22500,
  29556: 99999,
  29560: 99999,
  29563: 99999,
  29564: 99999,
  29565: 13500,
  29566: 34820,
  29567: 99999,
  29568: 34820,
  29569: 34820,
  29570: 13500,
  29571: 99999,
  29572: 34820,
  29574: 99999,
  29575: 34820,
  29576: 23860,
  29577: 34820,
  29578: 34820,
  29579: 34820,
  29580: 99999,
  29581: 99999,
  29582: 34820,
  29583: 22500,
  29584: 99999,
  29585: 23860,
  29587: 34820,
  29588: 34820,
  29589: 99999,
  29590: 99999,
  29591: 22500,
  29592: 99999,
  29593: 22500,
  29594: 13500,
  29596: 13500,
  29597: 34820,
  29598: 34820,
  29601: 24860,
  29602: 24860,
  29603: 24860,
  29604: 24860,
  29605: 24860,
  29606: 24860,
  29607: 24860,
  29608: 24860,
  29609: 24860,
  29610: 24860,
  29611: 24860,
  29612: 24860,
  29613: 24860,
  29614: 24860,
  29615: 24860,
  29616: 24860,
  29617: 24860,
  29620: 99999,
  29621: 24860,
  29622: 24860,
  29623: 24860,
  29624: 24860,
  29625: 24860,
  29626: 24860,
  29627: 99999,
  29628: 99999,
  29630: 24860,
  29631: 24860,
  29632: 24860,
  29633: 24860,
  29634: 24860,
  29635: 24860,
  29636: 24860,
  29638: 24940,
  29639: 99999,
  29640: 24860,
  29641: 24860,
  29642: 24860,
  29643: 42860,
  29644: 24860,
  29645: 43900,
  29646: 99999,
  29647: 24940,
  29648: 24940,
  29649: 99999,
  29650: 43900,
  29651: 24860,
  29652: 24860,
  29653: 99999,
  29654: 24860,
  29655: 99999,
  29656: 24860,
  29657: 24860,
  29658: 42860,
  29659: 99999,
  29661: 24860,
  29662: 24860,
  29664: 42860,
  29665: 42860,
  29666: 17900,
  29667: 24860,
  29669: 24860,
  29670: 24860,
  29671: 24860,
  29672: 42860,
  29673: 24860,
  29675: 42860,
  29676: 42860,
  29677: 24860,
  29678: 42860,
  29679: 42860,
  29680: 24860,
  29681: 24860,
  29682: 24860,
  29683: 24860,
  29684: 24860,
  29685: 24860,
  29686: 42860,
  29687: 24860,
  29688: 24860,
  29689: 24860,
  29690: 24860,
  29691: 42860,
  29692: 99999,
  29693: 42860,
  29695: 24940,
  29696: 42860,
  29697: 24860,
  29702: 23500,
  29703: 16740,
  29704: 16740,
  29706: 16740,
  29707: 16740,
  29708: 16740,
  29709: 99999,
  29710: 16740,
  29712: 16740,
  29714: 16740,
  29715: 16740,
  29716: 16740,
  29717: 16740,
  29718: 99999,
  29720: 99999,
  29721: 16740,
  29726: 16740,
  29727: 99999,
  29728: 99999,
  29729: 16740,
  29730: 16740,
  29731: 16740,
  29732: 16740,
  29733: 16740,
  29734: 16740,
  29741: 99999,
  29742: 16740,
  29743: 16740,
  29744: 16740,
  29745: 16740,
  29801: 12260,
  29802: 12260,
  29803: 12260,
  29804: 12260,
  29805: 12260,
  29808: 99999,
  29809: 12260,
  29810: 99999,
  29812: 99999,
  29813: 99999,
  29816: 12260,
  29817: 99999,
  29819: 99999,
  29821: 12260,
  29822: 12260,
  29824: 17900,
  29826: 99999,
  29827: 99999,
  29828: 12260,
  29829: 12260,
  29831: 12260,
  29832: 17900,
  29834: 12260,
  29835: 12260,
  29836: 99999,
  29838: 99999,
  29839: 12260,
  29840: 99999,
  29841: 12260,
  29842: 12260,
  29843: 99999,
  29844: 99999,
  29845: 12260,
  29846: 99999,
  29847: 12260,
  29848: 24940,
  29849: 99999,
  29850: 12260,
  29851: 12260,
  29853: 99999,
  29856: 12260,
  29860: 12260,
  29861: 12260,
  29899: 99999,
  29901: 25940,
  29902: 25940,
  29903: 25940,
  29904: 25940,
  29905: 25940,
  29906: 25940,
  29907: 25940,
  29909: 25940,
  29910: 25940,
  29911: 99999,
  29912: 25940,
  29915: 25940,
  29916: 99999,
  29918: 99999,
  29920: 25940,
  29921: 99999,
  29922: 99999,
  29923: 99999,
  29924: 99999,
  29925: 25940,
  29926: 25940,
  29927: 25940,
  29928: 25940,
  29929: 99999,
  29931: 25940,
  29932: 99999,
  29933: 99999,
  29934: 99999,
  29935: 25940,
  29936: 25940,
  29938: 25940,
  29939: 99999,
  29940: 25940,
  29941: 25940,
  29943: 25940,
  29944: 25940,
  29945: 99999,
  30002: 12060,
  30003: 12060,
  30004: 12060,
  30005: 12060,
  30006: 12060,
  30007: 12060,
  30008: 12060,
  30009: 12060,
  30010: 12060,
  30011: 12060,
  30012: 12060,
  30013: 12060,
  30014: 12060,
  30015: 12060,
  30016: 12060,
  30017: 12060,
  30018: 12060,
  30019: 12060,
  30021: 12060,
  30022: 12060,
  30023: 12060,
  30024: 12060,
  30025: 12060,
  30028: 12060,
  30030: 12060,
  30031: 12060,
  30032: 12060,
  30033: 12060,
  30034: 12060,
  30035: 12060,
  30036: 12060,
  30037: 12060,
  30038: 12060,
  30039: 12060,
  30040: 12060,
  30041: 12060,
  30042: 12060,
  30043: 12060,
  30044: 12060,
  30045: 12060,
  30046: 12060,
  30047: 12060,
  30048: 12060,
  30049: 12060,
  30052: 12060,
  30054: 12060,
  30055: 12060,
  30056: 12060,
  30058: 12060,
  30060: 12060,
  30061: 12060,
  30062: 12060,
  30063: 12060,
  30064: 12060,
  30065: 12060,
  30066: 12060,
  30067: 12060,
  30068: 12060,
  30069: 12060,
  30070: 12060,
  30071: 12060,
  30072: 12060,
  30074: 12060,
  30075: 12060,
  30076: 12060,
  30077: 12060,
  30078: 12060,
  30079: 12060,
  30080: 12060,
  30081: 12060,
  30082: 12060,
  30083: 12060,
  30084: 12060,
  30085: 12060,
  30086: 12060,
  30087: 12060,
  30088: 12060,
  30090: 12060,
  30091: 12060,
  30092: 12060,
  30093: 12060,
  30094: 12060,
  30095: 12060,
  30096: 12060,
  30097: 12060,
  30098: 12060,
  30099: 12060,
  30101: 12060,
  30102: 12060,
  30103: 12060,
  30104: 40660,
  30105: 40660,
  30106: 12060,
  30107: 12060,
  30108: 12060,
  30109: 12060,
  30110: 12060,
  30111: 12060,
  30112: 12060,
  30113: 12060,
  30114: 12060,
  30115: 12060,
  30116: 12060,
  30117: 12060,
  30118: 12060,
  30119: 12060,
  30120: 12060,
  30121: 12060,
  30122: 12060,
  30123: 12060,
  30124: 16340,
  30125: 16340,
  30126: 12060,
  30127: 12060,
  30129: 40660,
  30132: 12060,
  30133: 12060,
  30134: 12060,
  30135: 12060,
  30137: 12060,
  30138: 16340,
  30139: 15660,
  30140: 12060,
  30141: 12060,
  30142: 12060,
  30143: 12060,
  30144: 12060,
  30145: 12060,
  30146: 12060,
  30147: 16340,
  30148: 12060,
  30149: 40660,
  30150: 12060,
  30151: 12060,
  30152: 12060,
  30153: 12060,
  30154: 12060,
  30156: 12060,
  30157: 12060,
  30160: 12060,
  30161: 40660,
  30162: 40660,
  30164: 40660,
  30165: 44900,
  30168: 12060,
  30169: 12060,
  30170: 12060,
  30171: 12060,
  30172: 40660,
  30173: 40660,
  30175: 12060,
  30176: 12060,
  30177: 12060,
  30178: 16340,
  30179: 12060,
  30180: 12060,
  30182: 12060,
  30183: 12060,
  30184: 12060,
  30185: 12060,
  30187: 12060,
  30188: 12060,
  30189: 12060,
  30204: 12060,
  30205: 12060,
  30206: 12060,
  30212: 12060,
  30213: 12060,
  30214: 12060,
  30215: 12060,
  30216: 31420,
  30217: 29300,
  30218: 12060,
  30220: 12060,
  30222: 12060,
  30223: 12060,
  30224: 12060,
  30228: 12060,
  30229: 12060,
  30230: 12060,
  30233: 12060,
  30234: 12060,
  30236: 12060,
  30237: 12060,
  30238: 12060,
  30240: 29300,
  30241: 12060,
  30248: 12060,
  30250: 12060,
  30251: 12060,
  30252: 12060,
  30253: 12060,
  30256: 45580,
  30257: 12060,
  30258: 45580,
  30259: 12060,
  30260: 12060,
  30261: 29300,
  30263: 12060,
  30264: 12060,
  30265: 12060,
  30266: 12060,
  30268: 12060,
  30269: 12060,
  30271: 12060,
  30272: 12060,
  30273: 12060,
  30274: 12060,
  30275: 12060,
  30276: 12060,
  30277: 12060,
  30281: 12060,
  30284: 12060,
  30285: 12060,
  30286: 45580,
  30287: 12060,
  30288: 12060,
  30289: 12060,
  30290: 12060,
  30291: 12060,
  30292: 12060,
  30293: 12060,
  30294: 12060,
  30295: 12060,
  30296: 12060,
  30297: 12060,
  30298: 12060,
  30301: 12060,
  30302: 12060,
  30303: 12060,
  30304: 12060,
  30305: 12060,
  30306: 12060,
  30307: 12060,
  30308: 12060,
  30309: 12060,
  30310: 12060,
  30311: 12060,
  30312: 12060,
  30313: 12060,
  30314: 12060,
  30315: 12060,
  30316: 12060,
  30317: 12060,
  30318: 12060,
  30319: 12060,
  30320: 12060,
  30321: 12060,
  30322: 12060,
  30324: 12060,
  30325: 12060,
  30326: 12060,
  30327: 12060,
  30328: 12060,
  30329: 12060,
  30331: 12060,
  30332: 12060,
  30333: 12060,
  30334: 12060,
  30336: 12060,
  30337: 12060,
  30338: 12060,
  30339: 12060,
  30340: 12060,
  30341: 12060,
  30342: 12060,
  30343: 12060,
  30344: 12060,
  30345: 12060,
  30346: 12060,
  30349: 12060,
  30350: 12060,
  30354: 12060,
  30355: 12060,
  30356: 12060,
  30357: 12060,
  30358: 12060,
  30359: 12060,
  30360: 12060,
  30361: 12060,
  30362: 12060,
  30363: 12060,
  30364: 12060,
  30366: 12060,
  30368: 12060,
  30369: 12060,
  30377: 12060,
  30378: 12060,
  30380: 12060,
  30385: 12060,
  30388: 12060,
  30396: 12060,
  30398: 12060,
  30401: 99999,
  30410: 47080,
  30411: 20140,
  30412: 47080,
  30413: 20140,
  30414: 99999,
  30415: 44340,
  30417: 99999,
  30420: 99999,
  30421: 99999,
  30423: 99999,
  30424: 99999,
  30425: 99999,
  30426: 12260,
  30427: 25980,
  30428: 20140,
  30429: 99999,
  30434: 99999,
  30436: 20140,
  30438: 99999,
  30439: 44340,
  30441: 12260,
  30442: 12260,
  30445: 47080,
  30446: 42340,
  30448: 99999,
  30449: 99999,
  30450: 99999,
  30451: 99999,
  30452: 99999,
  30453: 99999,
  30454: 20140,
  30455: 99999,
  30456: 12260,
  30457: 99999,
  30458: 44340,
  30459: 44340,
  30460: 44340,
  30461: 44340,
  30464: 99999,
  30467: 99999,
  30470: 20140,
  30471: 44340,
  30473: 47080,
  30474: 47080,
  30475: 47080,
  30477: 99999,
  30499: 99999,
  30501: 23580,
  30502: 23580,
  30503: 23580,
  30504: 23580,
  30506: 12060,
  30507: 23580,
  30510: 23580,
  30511: 18460,
  30512: 99999,
  30513: 99999,
  30514: 99999,
  30515: 12060,
  30516: 99999,
  30517: 12060,
  30518: 12060,
  30519: 23580,
  30520: 99999,
  30521: 99999,
  30522: 99999,
  30523: 99999,
  30525: 99999,
  30527: 23580,
  30528: 23580,
  30529: 27600,
  30530: 99999,
  30531: 18460,
  30533: 23580,
  30534: 99999,
  30535: 18460,
  30536: 12060,
  30537: 99999,
  30538: 45740,
  30539: 99999,
  30540: 12060,
  30541: 99999,
  30542: 23580,
  30543: 23580,
  30545: 99999,
  30546: 99999,
  30547: 99999,
  30548: 12060,
  30549: 27600,
  30552: 99999,
  30553: 99999,
  30554: 23580,
  30555: 99999,
  30557: 99999,
  30558: 99999,
  30559: 99999,
  30560: 99999,
  30562: 99999,
  30563: 18460,
  30564: 23580,
  30565: 12020,
  30566: 23580,
  30567: 23580,
  30568: 99999,
  30571: 18460,
  30572: 99999,
  30573: 99999,
  30575: 23580,
  30576: 99999,
  30577: 99999,
  30580: 18460,
  30581: 99999,
  30582: 99999,
  30597: 99999,
  30598: 45740,
  30599: 27600,
  30601: 12020,
  30602: 12020,
  30603: 12020,
  30604: 12020,
  30605: 12020,
  30606: 12020,
  30607: 12020,
  30608: 12020,
  30609: 12020,
  30619: 12020,
  30620: 12060,
  30621: 12020,
  30622: 27600,
  30623: 12060,
  30624: 12020,
  30625: 12060,
  30627: 12020,
  30628: 12020,
  30629: 12020,
  30630: 12020,
  30631: 99999,
  30633: 99999,
  30634: 99999,
  30635: 99999,
  30638: 12020,
  30639: 99999,
  30641: 12060,
  30642: 12060,
  30643: 99999,
  30645: 12020,
  30646: 27600,
  30647: 12020,
  30648: 12020,
  30650: 12060,
  30655: 12060,
  30656: 12060,
  30660: 12020,
  30662: 12020,
  30663: 12060,
  30664: 99999,
  30665: 99999,
  30666: 27600,
  30667: 12020,
  30668: 12260,
  30669: 12020,
  30673: 99999,
  30677: 12020,
  30678: 99999,
  30680: 27600,
  30683: 12020,
  30701: 15660,
  30703: 15660,
  30705: 99999,
  30707: 16860,
  30708: 19140,
  30710: 16860,
  30711: 19140,
  30719: 19140,
  30720: 19140,
  30721: 19140,
  30722: 19140,
  30724: 19140,
  30725: 16860,
  30726: 16860,
  30728: 16860,
  30730: 44900,
  30731: 16860,
  30732: 15660,
  30733: 15660,
  30734: 15660,
  30735: 19140,
  30736: 19140,
  30738: 16860,
  30739: 16860,
  30740: 19140,
  30741: 16860,
  30742: 16860,
  30746: 16860,
  30747: 16860,
  30750: 16860,
  30751: 19140,
  30752: 16860,
  30753: 44900,
  30755: 16860,
  30756: 19140,
  30757: 16860,
  30802: 12260,
  30803: 99999,
  30805: 12260,
  30806: 12260,
  30807: 99999,
  30808: 12260,
  30809: 12260,
  30810: 99999,
  30812: 12260,
  30813: 12260,
  30814: 12260,
  30815: 12260,
  30816: 99999,
  30817: 99999,
  30818: 99999,
  30819: 99999,
  30820: 33300,
  30821: 12260,
  30822: 99999,
  30823: 99999,
  30824: 12260,
  30828: 12260,
  30830: 12260,
  30833: 99999,
  30901: 12260,
  30903: 12260,
  30904: 12260,
  30905: 12260,
  30906: 12260,
  30907: 12260,
  30909: 12260,
  30912: 12260,
  30914: 12260,
  30916: 12260,
  30917: 12260,
  30919: 12260,
  31001: 99999,
  31002: 20140,
  31003: 99999,
  31004: 31420,
  31005: 47580,
  31006: 17980,
  31007: 99999,
  31008: 31420,
  31009: 20140,
  31010: 18380,
  31011: 99999,
  31012: 20140,
  31013: 47580,
  31014: 31420,
  31015: 18380,
  31016: 45580,
  31017: 20140,
  31018: 99999,
  31019: 20140,
  31020: 31420,
  31021: 99999,
  31022: 20140,
  31023: 99999,
  31024: 99999,
  31025: 47580,
  31026: 99999,
  31027: 20140,
  31028: 47580,
  31029: 31420,
  31030: 31420,
  31031: 33300,
  31032: 31420,
  31033: 33300,
  31034: 33300,
  31035: 20140,
  31036: 47580,
  31037: 20140,
  31038: 31420,
  31039: 99999,
  31040: 20140,
  31041: 99999,
  31042: 20140,
  31044: 99999,
  31045: 99999,
  31046: 31420,
  31047: 47580,
  31049: 20140,
  31050: 31420,
  31051: 99999,
  31052: 31420,
  31054: 99999,
  31055: 99999,
  31057: 99999,
  31058: 11140,
  31059: 33300,
  31060: 99999,
  31061: 99999,
  31062: 33300,
  31063: 99999,
  31064: 12060,
  31065: 20140,
  31066: 31420,
  31067: 99999,
  31068: 99999,
  31069: 47580,
  31070: 99999,
  31071: 99999,
  31072: 99999,
  31075: 20140,
  31076: 47580,
  31077: 99999,
  31078: 31420,
  31079: 99999,
  31081: 11140,
  31082: 99999,
  31083: 99999,
  31085: 12060,
  31086: 31420,
  31087: 99999,
  31088: 47580,
  31089: 99999,
  31090: 99999,
  31091: 47580,
  31092: 99999,
  31093: 47580,
  31094: 99999,
  31095: 47580,
  31096: 20140,
  31097: 45580,
  31098: 47580,
  31106: 12060,
  31107: 12060,
  31119: 12060,
  31126: 12060,
  31131: 12060,
  31136: 12060,
  31139: 12060,
  31141: 12060,
  31145: 12060,
  31146: 12060,
  31150: 12060,
  31156: 12060,
  31169: 12060,
  31201: 31420,
  31203: 31420,
  31204: 31420,
  31205: 31420,
  31206: 31420,
  31207: 31420,
  31208: 31420,
  31209: 31420,
  31210: 31420,
  31211: 31420,
  31213: 31420,
  31216: 31420,
  31217: 31420,
  31220: 31420,
  31221: 31420,
  31295: 31420,
  31301: 25980,
  31302: 42340,
  31303: 42340,
  31304: 15260,
  31305: 15260,
  31307: 42340,
  31308: 44340,
  31309: 25980,
  31310: 25980,
  31312: 42340,
  31313: 25980,
  31314: 25980,
  31315: 25980,
  31316: 25980,
  31318: 42340,
  31319: 15260,
  31320: 25980,
  31321: 42340,
  31322: 42340,
  31323: 15260,
  31324: 42340,
  31326: 42340,
  31327: 15260,
  31328: 42340,
  31329: 42340,
  31331: 15260,
  31333: 25980,
  31401: 42340,
  31402: 42340,
  31403: 42340,
  31404: 42340,
  31405: 42340,
  31406: 42340,
  31407: 42340,
  31408: 42340,
  31409: 42340,
  31410: 42340,
  31411: 42340,
  31412: 42340,
  31414: 42340,
  31415: 42340,
  31416: 42340,
  31418: 42340,
  31419: 42340,
  31420: 42340,
  31421: 42340,
  31501: 48180,
  31502: 48180,
  31503: 15260,
  31510: 99999,
  31512: 20060,
  31513: 99999,
  31516: 48180,
  31518: 48180,
  31519: 99999,
  31520: 15260,
  31521: 15260,
  31522: 15260,
  31523: 15260,
  31524: 15260,
  31525: 15260,
  31527: 15260,
  31532: 99999,
  31533: 20060,
  31534: 20060,
  31535: 20060,
  31537: 15260,
  31539: 99999,
  31542: 15260,
  31543: 27700,
  31544: 99999,
  31545: 27700,
  31546: 27700,
  31547: 41220,
  31548: 41220,
  31549: 99999,
  31550: 48180,
  31551: 99999,
  31552: 20060,
  31553: 15260,
  31554: 20060,
  31555: 99999,
  31556: 48180,
  31557: 48180,
  31558: 41220,
  31560: 27700,
  31561: 15260,
  31562: 99999,
  31563: 99999,
  31564: 48180,
  31565: 15260,
  31566: 15260,
  31567: 20060,
  31568: 41220,
  31569: 41220,
  31598: 27700,
  31599: 27700,
  31601: 46660,
  31602: 46660,
  31603: 46660,
  31604: 46660,
  31605: 46660,
  31606: 46660,
  31620: 99999,
  31622: 99999,
  31623: 99999,
  31624: 48180,
  31625: 46660,
  31626: 45620,
  31627: 99999,
  31629: 45620,
  31630: 46660,
  31631: 46660,
  31632: 99999,
  31634: 99999,
  31635: 99999,
  31636: 46660,
  31637: 45700,
  31638: 46660,
  31639: 99999,
  31641: 46660,
  31642: 99999,
  31643: 46660,
  31645: 99999,
  31647: 99999,
  31648: 46660,
  31649: 46660,
  31650: 20060,
  31698: 46660,
  31699: 46660,
  31701: 10500,
  31702: 10500,
  31703: 10500,
  31704: 10500,
  31705: 10500,
  31706: 10500,
  31707: 10500,
  31708: 10500,
  31709: 11140,
  31711: 11140,
  31712: 18380,
  31714: 45700,
  31716: 99999,
  31719: 11140,
  31720: 45620,
  31721: 10500,
  31722: 34220,
  31727: 45700,
  31730: 99999,
  31733: 99999,
  31735: 11140,
  31738: 34220,
  31743: 10500,
  31744: 34220,
  31747: 34220,
  31749: 99999,
  31750: 99999,
  31753: 34220,
  31756: 34220,
  31757: 45620,
  31758: 45620,
  31763: 10500,
  31764: 10500,
  31765: 99999,
  31768: 10500,
  31769: 99999,
  31771: 34220,
  31772: 10500,
  31773: 34220,
  31774: 20060,
  31775: 10500,
  31776: 34220,
  31778: 46660,
  31779: 45620,
  31780: 11140,
  31781: 10500,
  31782: 10500,
  31783: 22340,
  31784: 99999,
  31787: 11140,
  31788: 46660,
  31789: 10500,
  31790: 99999,
  31791: 10500,
  31792: 45620,
  31793: 45700,
  31794: 45700,
  31795: 45700,
  31796: 10500,
  31798: 99999,
  31799: 45620,
  31801: 17980,
  31803: 99999,
  31804: 17980,
  31805: 17980,
  31806: 99999,
  31807: 17980,
  31808: 17980,
  31810: 17980,
  31811: 17980,
  31812: 99999,
  31815: 17980,
  31816: 17980,
  31820: 17980,
  31821: 17980,
  31822: 29300,
  31823: 17980,
  31824: 17980,
  31825: 17980,
  31826: 17980,
  31827: 17980,
  31829: 17980,
  31830: 17980,
  31831: 17980,
  31832: 99999,
  31833: 29300,
  31836: 17980,
  31901: 17980,
  31902: 17980,
  31903: 17980,
  31904: 17980,
  31905: 17980,
  31906: 17980,
  31907: 17980,
  31908: 17980,
  31909: 17980,
  31914: 17980,
  31917: 17980,
  31995: 17980,
  31998: 17980,
  31999: 17980,
  32003: 27260,
  32004: 27260,
  32006: 27260,
  32007: 37260,
  32008: 23540,
  32009: 27260,
  32011: 27260,
  32013: 99999,
  32024: 99999,
  32025: 29380,
  32033: 27260,
  32034: 27260,
  32035: 27260,
  32038: 29380,
  32040: 27260,
  32041: 27260,
  32042: 99999,
  32043: 37260,
  32044: 99999,
  32046: 27260,
  32050: 27260,
  32052: 99999,
  32053: 99999,
  32054: 99999,
  32055: 29380,
  32056: 29380,
  32058: 99999,
  32059: 99999,
  32060: 99999,
  32061: 29380,
  32062: 99999,
  32063: 27260,
  32064: 99999,
  32065: 27260,
  32066: 99999,
  32067: 27260,
  32068: 27260,
  32071: 99999,
  32073: 27260,
  32079: 27260,
  32080: 27260,
  32081: 27260,
  32082: 27260,
  32083: 99999,
  32084: 27260,
  32085: 27260,
  32086: 27260,
  32087: 27260,
  32091: 27260,
  32092: 27260,
  32094: 99999,
  32095: 27260,
  32096: 29380,
  32097: 27260,
  32099: 27260,
  32102: 36740,
  32105: 19660,
  32110: 19660,
  32111: 36100,
  32112: 37260,
  32113: 36100,
  32114: 19660,
  32115: 19660,
  32117: 19660,
  32118: 19660,
  32119: 19660,
  32120: 19660,
  32121: 19660,
  32122: 19660,
  32124: 19660,
  32125: 19660,
  32126: 19660,
  32127: 19660,
  32128: 19660,
  32129: 19660,
  32130: 19660,
  32131: 37260,
  32132: 19660,
  32133: 36100,
  32134: 36740,
  32135: 19660,
  32136: 19660,
  32137: 19660,
  32138: 37260,
  32139: 37260,
  32140: 27260,
  32141: 19660,
  32143: 19660,
  32145: 27260,
  32147: 37260,
  32148: 37260,
  32158: 36740,
  32159: 36740,
  32160: 27260,
  32162: 36100,
  32163: 45540,
  32164: 19660,
  32168: 19660,
  32169: 19660,
  32170: 19660,
  32173: 19660,
  32174: 19660,
  32175: 19660,
  32176: 19660,
  32177: 37260,
  32178: 37260,
  32179: 36100,
  32180: 19660,
  32181: 37260,
  32182: 36100,
  32185: 37260,
  32187: 37260,
  32189: 37260,
  32190: 19660,
  32192: 36100,
  32193: 37260,
  32195: 36100,
  32198: 19660,
  32202: 27260,
  32203: 27260,
  32204: 27260,
  32205: 27260,
  32206: 27260,
  32207: 27260,
  32208: 27260,
  32209: 27260,
  32210: 27260,
  32211: 27260,
  32212: 27260,
  32214: 27260,
  32216: 27260,
  32217: 27260,
  32218: 27260,
  32219: 27260,
  32220: 27260,
  32221: 27260,
  32222: 27260,
  32223: 27260,
  32224: 27260,
  32225: 27260,
  32226: 27260,
  32227: 27260,
  32228: 27260,
  32229: 27260,
  32233: 27260,
  32234: 27260,
  32235: 27260,
  32236: 27260,
  32238: 27260,
  32239: 27260,
  32240: 27260,
  32241: 27260,
  32244: 27260,
  32245: 27260,
  32246: 27260,
  32247: 27260,
  32250: 27260,
  32254: 27260,
  32255: 27260,
  32256: 27260,
  32257: 27260,
  32258: 27260,
  32259: 27260,
  32260: 27260,
  32266: 27260,
  32277: 27260,
  32301: 45220,
  32302: 45220,
  32303: 45220,
  32304: 45220,
  32305: 45220,
  32306: 45220,
  32307: 45220,
  32308: 45220,
  32309: 45220,
  32310: 45220,
  32311: 45220,
  32312: 45220,
  32313: 45220,
  32314: 45220,
  32315: 45220,
  32316: 45220,
  32317: 45220,
  32318: 45220,
  32320: 99999,
  32321: 99999,
  32322: 99999,
  32324: 45220,
  32326: 45220,
  32327: 45220,
  32328: 99999,
  32330: 45220,
  32331: 45220,
  32332: 45220,
  32333: 45220,
  32334: 99999,
  32336: 99999,
  32337: 45220,
  32340: 99999,
  32343: 45220,
  32344: 45220,
  32345: 45220,
  32346: 99999,
  32347: 99999,
  32348: 99999,
  32350: 99999,
  32351: 99999,
  32352: 45220,
  32355: 45220,
  32356: 99999,
  32357: 99999,
  32358: 45220,
  32359: 99999,
  32360: 99999,
  32361: 45220,
  32362: 45220,
  32399: 45220,
  32401: 37460,
  32402: 37460,
  32403: 37460,
  32404: 37460,
  32405: 37460,
  32406: 37460,
  32407: 37460,
  32408: 37460,
  32409: 37460,
  32410: 37460,
  32411: 37460,
  32413: 18880,
  32417: 37460,
  32420: 99999,
  32421: 99999,
  32422: 18880,
  32423: 99999,
  32424: 99999,
  32425: 99999,
  32426: 99999,
  32427: 99999,
  32428: 99999,
  32430: 99999,
  32431: 99999,
  32432: 99999,
  32433: 99999,
  32435: 18880,
  32437: 37460,
  32438: 37460,
  32439: 18880,
  32440: 99999,
  32442: 99999,
  32443: 99999,
  32444: 37460,
  32445: 99999,
  32446: 99999,
  32447: 99999,
  32448: 99999,
  32449: 99999,
  32455: 99999,
  32456: 37460,
  32459: 18880,
  32460: 99999,
  32461: 18880,
  32462: 18880,
  32463: 99999,
  32464: 99999,
  32465: 37460,
  32466: 37460,
  32501: 37860,
  32502: 37860,
  32503: 37860,
  32504: 37860,
  32505: 37860,
  32506: 37860,
  32507: 37860,
  32508: 37860,
  32509: 37860,
  32511: 37860,
  32512: 37860,
  32513: 37860,
  32514: 37860,
  32516: 37860,
  32520: 37860,
  32522: 37860,
  32523: 37860,
  32524: 37860,
  32526: 37860,
  32530: 37860,
  32531: 18880,
  32533: 37860,
  32534: 37860,
  32535: 37860,
  32536: 18880,
  32538: 18880,
  32539: 18880,
  32540: 18880,
  32541: 18880,
  32542: 18880,
  32544: 18880,
  32547: 18880,
  32548: 18880,
  32549: 18880,
  32550: 18880,
  32559: 37860,
  32560: 37860,
  32561: 37860,
  32562: 37860,
  32563: 37860,
  32564: 18880,
  32565: 37860,
  32566: 37860,
  32567: 18880,
  32568: 37860,
  32569: 18880,
  32570: 37860,
  32571: 37860,
  32572: 37860,
  32577: 37860,
  32578: 18880,
  32579: 18880,
  32580: 18880,
  32583: 37860,
  32588: 18880,
  32591: 37860,
  32601: 23540,
  32603: 23540,
  32604: 23540,
  32605: 23540,
  32606: 23540,
  32607: 23540,
  32608: 23540,
  32609: 23540,
  32610: 23540,
  32611: 23540,
  32612: 23540,
  32614: 23540,
  32615: 23540,
  32616: 23540,
  32617: 36100,
  32618: 23540,
  32619: 23540,
  32621: 23540,
  32622: 99999,
  32625: 23540,
  32626: 23540,
  32627: 23540,
  32628: 99999,
  32631: 23540,
  32633: 23540,
  32634: 36100,
  32635: 23540,
  32639: 23540,
  32640: 37260,
  32641: 23540,
  32643: 29380,
  32644: 23540,
  32648: 99999,
  32653: 23540,
  32654: 23540,
  32656: 37260,
  32658: 23540,
  32662: 23540,
  32663: 36100,
  32664: 36100,
  32666: 37260,
  32667: 23540,
  32668: 36100,
  32669: 23540,
  32680: 99999,
  32681: 36100,
  32683: 23540,
  32686: 36100,
  32692: 99999,
  32693: 23540,
  32694: 23540,
  32696: 36100,
  32697: 99999,
  32701: 36740,
  32702: 36740,
  32703: 36740,
  32704: 36740,
  32706: 19660,
  32707: 36740,
  32708: 36740,
  32709: 36740,
  32710: 36740,
  32712: 36740,
  32713: 19660,
  32714: 36740,
  32715: 36740,
  32716: 36740,
  32718: 36740,
  32719: 36740,
  32720: 19660,
  32721: 19660,
  32723: 19660,
  32724: 19660,
  32725: 19660,
  32726: 36740,
  32727: 36740,
  32728: 19660,
  32730: 36740,
  32732: 19660,
  32733: 36740,
  32735: 36740,
  32736: 36740,
  32738: 19660,
  32739: 19660,
  32744: 19660,
  32746: 36740,
  32747: 36740,
  32750: 36740,
  32751: 36740,
  32752: 36740,
  32753: 19660,
  32754: 19660,
  32756: 36740,
  32757: 36740,
  32759: 19660,
  32762: 36740,
  32763: 19660,
  32764: 19660,
  32765: 36740,
  32766: 36740,
  32767: 36740,
  32768: 36740,
  32771: 36740,
  32772: 36740,
  32773: 36740,
  32774: 19660,
  32775: 37340,
  32776: 36740,
  32777: 36740,
  32778: 36740,
  32779: 36740,
  32780: 37340,
  32781: 37340,
  32783: 37340,
  32784: 36100,
  32789: 36740,
  32790: 36740,
  32791: 36740,
  32792: 36740,
  32793: 36740,
  32794: 36740,
  32795: 36740,
  32796: 37340,
  32798: 36740,
  32799: 36740,
  32801: 36740,
  32802: 36740,
  32803: 36740,
  32804: 36740,
  32805: 36740,
  32806: 36740,
  32807: 36740,
  32808: 36740,
  32809: 36740,
  32810: 36740,
  32811: 36740,
  32812: 36740,
  32814: 36740,
  32815: 37340,
  32816: 36740,
  32817: 36740,
  32818: 36740,
  32819: 36740,
  32820: 36740,
  32821: 36740,
  32822: 36740,
  32824: 36740,
  32825: 36740,
  32826: 36740,
  32827: 36740,
  32828: 36740,
  32829: 36740,
  32830: 36740,
  32831: 36740,
  32832: 36740,
  32833: 36740,
  32834: 36740,
  32835: 36740,
  32836: 36740,
  32837: 36740,
  32839: 36740,
  32853: 36740,
  32854: 36740,
  32855: 36740,
  32856: 36740,
  32857: 36740,
  32858: 36740,
  32859: 36740,
  32860: 36740,
  32861: 36740,
  32862: 36740,
  32867: 36740,
  32868: 36740,
  32869: 36740,
  32872: 36740,
  32877: 36740,
  32878: 36740,
  32899: 37340,
  32901: 37340,
  32902: 37340,
  32903: 37340,
  32904: 37340,
  32905: 37340,
  32906: 37340,
  32907: 37340,
  32908: 37340,
  32909: 37340,
  32910: 37340,
  32911: 37340,
  32912: 37340,
  32920: 37340,
  32922: 37340,
  32923: 37340,
  32924: 37340,
  32925: 37340,
  32926: 37340,
  32927: 37340,
  32931: 37340,
  32932: 37340,
  32934: 37340,
  32935: 37340,
  32936: 37340,
  32937: 37340,
  32940: 37340,
  32941: 37340,
  32948: 42680,
  32949: 37340,
  32950: 37340,
  32951: 37340,
  32952: 37340,
  32953: 37340,
  32954: 37340,
  32955: 37340,
  32956: 37340,
  32957: 42680,
  32958: 42680,
  32959: 37340,
  32960: 42680,
  32961: 42680,
  32962: 42680,
  32963: 42680,
  32964: 42680,
  32965: 42680,
  32966: 42680,
  32967: 42680,
  32968: 42680,
  32969: 42680,
  32970: 42680,
  32971: 42680,
  32976: 37340,
  32978: 42680,
  33001: 28580,
  33002: 33100,
  33004: 33100,
  33008: 33100,
  33009: 33100,
  33010: 33100,
  33011: 33100,
  33012: 33100,
  33013: 33100,
  33014: 33100,
  33015: 33100,
  33016: 33100,
  33017: 33100,
  33018: 33100,
  33019: 33100,
  33020: 33100,
  33021: 33100,
  33022: 33100,
  33023: 33100,
  33024: 33100,
  33025: 33100,
  33026: 33100,
  33027: 33100,
  33028: 33100,
  33029: 33100,
  33030: 33100,
  33031: 33100,
  33032: 33100,
  33033: 33100,
  33034: 28580,
  33035: 33100,
  33036: 28580,
  33037: 28580,
  33039: 33100,
  33040: 28580,
  33041: 28580,
  33042: 28580,
  33043: 28580,
  33045: 28580,
  33050: 28580,
  33051: 28580,
  33052: 28580,
  33054: 33100,
  33055: 33100,
  33056: 33100,
  33060: 33100,
  33061: 33100,
  33062: 33100,
  33063: 33100,
  33064: 33100,
  33065: 33100,
  33066: 33100,
  33067: 33100,
  33068: 33100,
  33069: 33100,
  33070: 28580,
  33071: 33100,
  33073: 33100,
  33074: 33100,
  33075: 33100,
  33076: 33100,
  33077: 33100,
  33081: 33100,
  33082: 33100,
  33083: 33100,
  33084: 33100,
  33090: 33100,
  33092: 33100,
  33093: 33100,
  33097: 33100,
  33101: 33100,
  33106: 33100,
  33109: 33100,
  33112: 33100,
  33114: 33100,
  33116: 33100,
  33119: 33100,
  33122: 33100,
  33124: 33100,
  33125: 33100,
  33126: 33100,
  33127: 33100,
  33128: 33100,
  33129: 33100,
  33130: 33100,
  33131: 33100,
  33132: 33100,
  33133: 33100,
  33134: 33100,
  33135: 33100,
  33136: 33100,
  33137: 33100,
  33138: 33100,
  33139: 33100,
  33140: 33100,
  33141: 33100,
  33142: 33100,
  33143: 33100,
  33144: 33100,
  33145: 33100,
  33146: 33100,
  33147: 33100,
  33149: 33100,
  33150: 33100,
  33152: 33100,
  33153: 33100,
  33154: 33100,
  33155: 33100,
  33156: 33100,
  33157: 33100,
  33158: 33100,
  33160: 33100,
  33161: 33100,
  33162: 33100,
  33163: 33100,
  33164: 33100,
  33165: 33100,
  33166: 33100,
  33167: 33100,
  33168: 33100,
  33169: 33100,
  33170: 33100,
  33172: 33100,
  33173: 33100,
  33174: 33100,
  33175: 33100,
  33176: 33100,
  33177: 33100,
  33178: 33100,
  33179: 33100,
  33180: 33100,
  33181: 33100,
  33182: 33100,
  33183: 33100,
  33184: 33100,
  33185: 33100,
  33186: 33100,
  33187: 33100,
  33189: 33100,
  33190: 33100,
  33191: 33100,
  33192: 33100,
  33193: 33100,
  33194: 33100,
  33195: 33100,
  33196: 33100,
  33197: 33100,
  33198: 33100,
  33199: 33100,
  33206: 33100,
  33222: 33100,
  33231: 33100,
  33233: 33100,
  33234: 33100,
  33238: 33100,
  33239: 33100,
  33242: 33100,
  33243: 33100,
  33245: 33100,
  33247: 33100,
  33255: 33100,
  33256: 33100,
  33257: 33100,
  33261: 33100,
  33265: 33100,
  33266: 33100,
  33269: 33100,
  33280: 33100,
  33283: 33100,
  33296: 33100,
  33301: 33100,
  33302: 33100,
  33303: 33100,
  33304: 33100,
  33305: 33100,
  33306: 33100,
  33307: 33100,
  33308: 33100,
  33309: 33100,
  33310: 33100,
  33311: 33100,
  33312: 33100,
  33313: 33100,
  33314: 33100,
  33315: 33100,
  33316: 33100,
  33317: 33100,
  33318: 33100,
  33319: 33100,
  33320: 33100,
  33321: 33100,
  33322: 33100,
  33323: 33100,
  33324: 33100,
  33325: 33100,
  33326: 33100,
  33327: 33100,
  33328: 33100,
  33329: 33100,
  33330: 33100,
  33331: 33100,
  33332: 33100,
  33334: 33100,
  33335: 33100,
  33338: 33100,
  33339: 33100,
  33345: 33100,
  33346: 33100,
  33348: 33100,
  33351: 33100,
  33355: 33100,
  33359: 33100,
  33388: 33100,
  33394: 33100,
  33401: 33100,
  33402: 33100,
  33403: 33100,
  33404: 33100,
  33405: 33100,
  33406: 33100,
  33407: 33100,
  33408: 33100,
  33409: 33100,
  33410: 33100,
  33411: 33100,
  33412: 33100,
  33413: 33100,
  33414: 33100,
  33415: 33100,
  33416: 33100,
  33417: 33100,
  33418: 33100,
  33419: 33100,
  33420: 33100,
  33421: 33100,
  33422: 33100,
  33424: 33100,
  33425: 33100,
  33426: 33100,
  33427: 33100,
  33428: 33100,
  33429: 33100,
  33430: 33100,
  33431: 33100,
  33432: 33100,
  33433: 33100,
  33434: 33100,
  33435: 33100,
  33436: 33100,
  33437: 33100,
  33438: 33100,
  33440: 17500,
  33441: 33100,
  33442: 33100,
  33443: 33100,
  33444: 33100,
  33445: 33100,
  33446: 33100,
  33448: 33100,
  33449: 33100,
  33454: 33100,
  33455: 38940,
  33458: 33100,
  33459: 33100,
  33460: 33100,
  33461: 33100,
  33462: 33100,
  33463: 33100,
  33464: 33100,
  33465: 33100,
  33466: 33100,
  33467: 33100,
  33468: 33100,
  33469: 38940,
  33470: 33100,
  33471: 99999,
  33472: 33100,
  33473: 33100,
  33474: 33100,
  33475: 38940,
  33476: 33100,
  33477: 33100,
  33478: 38940,
  33480: 33100,
  33481: 33100,
  33482: 33100,
  33483: 33100,
  33484: 33100,
  33486: 33100,
  33487: 33100,
  33488: 33100,
  33493: 33100,
  33496: 33100,
  33497: 33100,
  33498: 33100,
  33499: 33100,
  33503: 45300,
  33508: 45300,
  33509: 45300,
  33510: 45300,
  33511: 45300,
  33513: 45540,
  33514: 45540,
  33521: 45540,
  33523: 45300,
  33524: 45300,
  33525: 45300,
  33526: 45300,
  33527: 45300,
  33530: 45300,
  33534: 45300,
  33537: 45300,
  33538: 45540,
  33539: 45300,
  33540: 45300,
  33541: 45300,
  33542: 45300,
  33543: 45300,
  33544: 45300,
  33545: 45300,
  33547: 29460,
  33548: 45300,
  33549: 45300,
  33550: 45300,
  33556: 45300,
  33558: 45300,
  33559: 45300,
  33563: 45300,
  33564: 45300,
  33565: 45300,
  33566: 45300,
  33567: 45300,
  33568: 45300,
  33569: 45300,
  33570: 45300,
  33571: 45300,
  33572: 45300,
  33573: 45300,
  33574: 45300,
  33575: 45300,
  33576: 45300,
  33578: 45300,
  33579: 45300,
  33583: 45300,
  33584: 45300,
  33585: 45540,
  33586: 45300,
  33587: 45300,
  33592: 45300,
  33593: 45300,
  33594: 45300,
  33595: 45300,
  33596: 45300,
  33597: 45300,
  33598: 35840,
  33601: 45300,
  33602: 45300,
  33603: 45300,
  33604: 45300,
  33605: 45300,
  33606: 45300,
  33607: 45300,
  33609: 45300,
  33610: 45300,
  33611: 45300,
  33612: 45300,
  33613: 45300,
  33614: 45300,
  33615: 45300,
  33616: 45300,
  33617: 45300,
  33618: 45300,
  33619: 45300,
  33620: 45300,
  33621: 45300,
  33622: 45300,
  33623: 45300,
  33624: 45300,
  33625: 45300,
  33626: 45300,
  33629: 45300,
  33630: 45300,
  33634: 45300,
  33635: 45300,
  33637: 45300,
  33646: 45300,
  33647: 45300,
  33672: 45300,
  33673: 45300,
  33674: 45300,
  33675: 45300,
  33677: 45300,
  33679: 45300,
  33680: 45300,
  33681: 45300,
  33682: 45300,
  33684: 45300,
  33685: 45300,
  33687: 45300,
  33688: 45300,
  33689: 45300,
  33694: 45300,
  33701: 45300,
  33702: 45300,
  33703: 45300,
  33704: 45300,
  33705: 45300,
  33706: 45300,
  33707: 45300,
  33708: 45300,
  33709: 45300,
  33710: 45300,
  33711: 45300,
  33712: 45300,
  33713: 45300,
  33714: 45300,
  33715: 45300,
  33716: 45300,
  33729: 45300,
  33730: 45300,
  33731: 45300,
  33732: 45300,
  33733: 45300,
  33734: 45300,
  33736: 45300,
  33738: 45300,
  33741: 45300,
  33742: 45300,
  33743: 45300,
  33744: 45300,
  33747: 45300,
  33755: 45300,
  33756: 45300,
  33757: 45300,
  33758: 45300,
  33759: 45300,
  33760: 45300,
  33761: 45300,
  33762: 45300,
  33763: 45300,
  33764: 45300,
  33765: 45300,
  33766: 45300,
  33767: 45300,
  33770: 45300,
  33771: 45300,
  33772: 45300,
  33773: 45300,
  33774: 45300,
  33775: 45300,
  33776: 45300,
  33777: 45300,
  33778: 45300,
  33779: 45300,
  33780: 45300,
  33781: 45300,
  33782: 45300,
  33784: 45300,
  33785: 45300,
  33786: 45300,
  33801: 29460,
  33802: 29460,
  33803: 29460,
  33804: 29460,
  33805: 29460,
  33806: 29460,
  33807: 29460,
  33809: 45300,
  33810: 29460,
  33811: 29460,
  33812: 29460,
  33813: 29460,
  33815: 29460,
  33820: 29460,
  33823: 29460,
  33825: 42700,
  33827: 29460,
  33830: 29460,
  33831: 29460,
  33834: 48100,
  33835: 29460,
  33836: 29460,
  33837: 29460,
  33838: 29460,
  33839: 29460,
  33840: 29460,
  33841: 48100,
  33843: 42700,
  33844: 29460,
  33845: 29460,
  33846: 29460,
  33847: 29460,
  33848: 36740,
  33849: 45300,
  33850: 29460,
  33851: 29460,
  33852: 42700,
  33853: 29460,
  33854: 29460,
  33855: 29460,
  33856: 29460,
  33857: 42700,
  33858: 29460,
  33859: 29460,
  33860: 29460,
  33862: 42700,
  33863: 29460,
  33865: 48100,
  33867: 29460,
  33868: 29460,
  33870: 42700,
  33871: 42700,
  33872: 42700,
  33873: 48100,
  33875: 42700,
  33876: 42700,
  33877: 29460,
  33880: 29460,
  33881: 29460,
  33882: 29460,
  33883: 29460,
  33884: 29460,
  33885: 29460,
  33890: 48100,
  33896: 29460,
  33897: 36740,
  33898: 29460,
  33901: 15980,
  33902: 15980,
  33903: 15980,
  33904: 15980,
  33905: 15980,
  33906: 15980,
  33907: 15980,
  33908: 15980,
  33909: 15980,
  33910: 15980,
  33912: 15980,
  33913: 15980,
  33914: 15980,
  33915: 15980,
  33916: 15980,
  33917: 39460,
  33918: 15980,
  33919: 15980,
  33920: 15980,
  33921: 15980,
  33922: 15980,
  33924: 15980,
  33928: 34940,
  33929: 15980,
  33930: 17500,
  33931: 15980,
  33932: 15980,
  33935: 17500,
  33936: 17500,
  33938: 39460,
  33944: 99999,
  33945: 15980,
  33946: 39460,
  33947: 39460,
  33948: 39460,
  33949: 39460,
  33950: 39460,
  33951: 39460,
  33952: 39460,
  33953: 39460,
  33954: 39460,
  33955: 39460,
  33956: 15980,
  33957: 15980,
  33960: 99999,
  33965: 15980,
  33966: 15980,
  33967: 15980,
  33970: 15980,
  33971: 15980,
  33972: 15980,
  33973: 15980,
  33974: 15980,
  33975: 17500,
  33976: 15980,
  33980: 39460,
  33981: 39460,
  33982: 39460,
  33983: 39460,
  33990: 15980,
  33991: 15980,
  33993: 15980,
  33994: 15980,
  34101: 34940,
  34102: 34940,
  34103: 34940,
  34104: 34940,
  34105: 34940,
  34106: 34940,
  34107: 34940,
  34108: 34940,
  34109: 34940,
  34110: 15980,
  34112: 34940,
  34113: 34940,
  34114: 34940,
  34116: 34940,
  34117: 34940,
  34119: 34940,
  34120: 34940,
  34133: 15980,
  34134: 34940,
  34135: 34940,
  34136: 15980,
  34138: 34940,
  34139: 34940,
  34140: 34940,
  34141: 34940,
  34142: 34940,
  34145: 34940,
  34146: 34940,
  34201: 35840,
  34202: 35840,
  34203: 35840,
  34204: 35840,
  34205: 35840,
  34206: 35840,
  34207: 35840,
  34208: 35840,
  34209: 35840,
  34210: 35840,
  34211: 35840,
  34212: 35840,
  34215: 35840,
  34216: 35840,
  34217: 35840,
  34219: 35840,
  34220: 35840,
  34221: 35840,
  34222: 35840,
  34223: 39460,
  34224: 39460,
  34228: 35840,
  34229: 35840,
  34230: 35840,
  34231: 35840,
  34232: 35840,
  34233: 35840,
  34234: 35840,
  34235: 35840,
  34236: 35840,
  34237: 35840,
  34238: 35840,
  34239: 35840,
  34240: 35840,
  34241: 35840,
  34242: 35840,
  34243: 35840,
  34249: 35840,
  34250: 35840,
  34251: 35840,
  34260: 35840,
  34264: 35840,
  34266: 42700,
  34267: 11580,
  34268: 11580,
  34269: 39460,
  34270: 35840,
  34272: 35840,
  34274: 35840,
  34275: 35840,
  34276: 35840,
  34277: 35840,
  34280: 35840,
  34282: 35840,
  34284: 35840,
  34285: 35840,
  34286: 35840,
  34287: 35840,
  34288: 35840,
  34289: 35840,
  34290: 35840,
  34291: 35840,
  34292: 35840,
  34293: 35840,
  34295: 35840,
  34420: 36100,
  34421: 36100,
  34423: 26140,
  34428: 26140,
  34429: 26140,
  34430: 36100,
  34431: 23540,
  34432: 36100,
  34433: 26140,
  34434: 26140,
  34436: 26140,
  34441: 26140,
  34442: 26140,
  34445: 26140,
  34446: 26140,
  34447: 26140,
  34448: 26140,
  34449: 26140,
  34450: 26140,
  34451: 26140,
  34452: 26140,
  34453: 26140,
  34460: 26140,
  34461: 26140,
  34464: 26140,
  34465: 26140,
  34470: 36100,
  34471: 36100,
  34472: 36100,
  34473: 36100,
  34474: 36100,
  34475: 36100,
  34476: 36100,
  34477: 36100,
  34478: 36100,
  34479: 36100,
  34480: 36100,
  34481: 36100,
  34482: 36100,
  34483: 36100,
  34484: 36100,
  34487: 26140,
  34488: 36100,
  34489: 36100,
  34491: 45540,
  34492: 36100,
  34498: 23540,
  34601: 45300,
  34602: 45300,
  34603: 45300,
  34604: 45300,
  34605: 45300,
  34606: 45300,
  34607: 45300,
  34608: 45300,
  34609: 45300,
  34610: 45300,
  34611: 45300,
  34613: 45300,
  34614: 45300,
  34636: 45300,
  34637: 45300,
  34638: 45300,
  34639: 45300,
  34652: 45300,
  34653: 45300,
  34654: 45300,
  34655: 45300,
  34656: 45300,
  34660: 45300,
  34661: 45300,
  34667: 45300,
  34668: 45300,
  34669: 45300,
  34673: 45300,
  34674: 45300,
  34677: 45300,
  34679: 45300,
  34680: 45300,
  34681: 45300,
  34682: 45300,
  34683: 45300,
  34684: 45300,
  34685: 45300,
  34688: 45300,
  34689: 45300,
  34690: 45300,
  34691: 45300,
  34692: 45300,
  34695: 45300,
  34697: 45300,
  34698: 45300,
  34705: 36740,
  34711: 36740,
  34712: 36740,
  34713: 36740,
  34714: 29460,
  34715: 36740,
  34729: 36740,
  34731: 36740,
  34734: 36740,
  34736: 36740,
  34737: 36740,
  34739: 36740,
  34740: 36740,
  34741: 36740,
  34742: 36740,
  34743: 36740,
  34744: 36740,
  34745: 36740,
  34746: 36740,
  34747: 36740,
  34748: 36740,
  34749: 36740,
  34753: 36740,
  34755: 36740,
  34756: 36740,
  34758: 36740,
  34759: 29460,
  34760: 36740,
  34761: 36740,
  34762: 36740,
  34769: 36740,
  34770: 36740,
  34771: 36740,
  34772: 36740,
  34773: 36740,
  34777: 36740,
  34778: 36740,
  34785: 45540,
  34786: 36740,
  34787: 36740,
  34788: 36740,
  34789: 36740,
  34797: 36740,
  34945: 38940,
  34946: 38940,
  34947: 38940,
  34948: 38940,
  34949: 38940,
  34950: 38940,
  34951: 42680,
  34952: 38940,
  34953: 38940,
  34954: 38940,
  34956: 38940,
  34957: 38940,
  34958: 38940,
  34972: 36740,
  34973: 36380,
  34974: 42700,
  34979: 38940,
  34981: 38940,
  34982: 38940,
  34983: 38940,
  34984: 38940,
  34985: 38940,
  34986: 38940,
  34987: 38940,
  34988: 38940,
  34990: 38940,
  34991: 38940,
  34992: 38940,
  34994: 38940,
  34995: 38940,
  34996: 38940,
  34997: 38940,
  35004: 13820,
  35005: 13820,
  35006: 46220,
  35007: 13820,
  35010: 10760,
  35011: 10760,
  35013: 13820,
  35014: 45180,
  35015: 13820,
  35016: 19460,
  35019: 19460,
  35020: 13820,
  35021: 13820,
  35022: 13820,
  35023: 13820,
  35031: 10700,
  35032: 45180,
  35033: 27530,
  35034: 13820,
  35035: 13820,
  35036: 13820,
  35038: 27530,
  35040: 13820,
  35042: 13820,
  35043: 13820,
  35044: 45180,
  35045: 13820,
  35046: 10760,
  35048: 13820,
  35049: 13820,
  35051: 13820,
  35052: 13820,
  35053: 18980,
  35054: 13820,
  35055: 18980,
  35056: 18980,
  35057: 99999,
  35058: 19460,
  35060: 13820,
  35061: 13820,
  35062: 13820,
  35063: 13820,
  35064: 13820,
  35068: 13820,
  35070: 18980,
  35071: 13820,
  35072: 45180,
  35073: 13820,
  35074: 13820,
  35077: 18980,
  35078: 13820,
  35079: 13820,
  35080: 13820,
  35082: 45180,
  35083: 18980,
  35085: 13820,
  35087: 10700,
  35089: 10760,
  35091: 13820,
  35094: 13820,
  35096: 11500,
  35097: 13820,
  35098: 99999,
  35111: 46220,
  35112: 13820,
  35114: 13820,
  35115: 13820,
  35116: 13820,
  35117: 13820,
  35118: 13820,
  35119: 13820,
  35120: 13820,
  35121: 13820,
  35123: 13820,
  35124: 13820,
  35125: 13820,
  35126: 13820,
  35127: 13820,
  35128: 13820,
  35130: 27530,
  35131: 13820,
  35133: 13820,
  35135: 13820,
  35136: 10760,
  35137: 13820,
  35139: 13820,
  35142: 13820,
  35143: 13820,
  35146: 13820,
  35147: 13820,
  35148: 13820,
  35149: 45180,
  35150: 45180,
  35151: 45180,
  35160: 45180,
  35171: 13820,
  35172: 13820,
  35173: 13820,
  35175: 10700,
  35176: 13820,
  35178: 13820,
  35179: 18980,
  35180: 13820,
  35181: 13820,
  35182: 13820,
  35183: 10760,
  35184: 13820,
  35185: 13820,
  35186: 13820,
  35187: 13820,
  35188: 13820,
  35201: 13820,
  35202: 13820,
  35203: 13820,
  35204: 13820,
  35205: 13820,
  35206: 13820,
  35207: 13820,
  35208: 13820,
  35209: 13820,
  35210: 13820,
  35211: 13820,
  35212: 13820,
  35213: 13820,
  35214: 13820,
  35215: 13820,
  35216: 13820,
  35217: 13820,
  35218: 13820,
  35219: 13820,
  35220: 13820,
  35221: 13820,
  35222: 13820,
  35223: 13820,
  35224: 13820,
  35226: 13820,
  35228: 13820,
  35229: 13820,
  35231: 13820,
  35232: 13820,
  35233: 13820,
  35234: 13820,
  35235: 13820,
  35236: 13820,
  35238: 13820,
  35242: 13820,
  35243: 13820,
  35244: 13820,
  35249: 13820,
  35253: 13820,
  35254: 13820,
  35255: 13820,
  35259: 13820,
  35260: 13820,
  35261: 13820,
  35266: 13820,
  35270: 13820,
  35285: 13820,
  35293: 13820,
  35294: 13820,
  35295: 13820,
  35401: 46220,
  35402: 46220,
  35403: 46220,
  35404: 46220,
  35405: 46220,
  35406: 46220,
  35407: 46220,
  35440: 46220,
  35441: 46220,
  35442: 46220,
  35443: 46220,
  35444: 13820,
  35446: 46220,
  35447: 46220,
  35448: 46220,
  35449: 46220,
  35452: 46220,
  35453: 46220,
  35456: 46220,
  35457: 46220,
  35458: 46220,
  35459: 99999,
  35460: 99999,
  35461: 46220,
  35462: 46220,
  35463: 46220,
  35464: 99999,
  35466: 46220,
  35469: 46220,
  35470: 99999,
  35471: 46220,
  35473: 46220,
  35474: 46220,
  35475: 46220,
  35476: 46220,
  35477: 99999,
  35478: 46220,
  35480: 46220,
  35481: 46220,
  35482: 46220,
  35486: 46220,
  35490: 46220,
  35491: 46220,
  35501: 27530,
  35502: 27530,
  35503: 99999,
  35504: 27530,
  35540: 18980,
  35541: 27530,
  35542: 99999,
  35543: 99999,
  35544: 99999,
  35545: 99999,
  35546: 99999,
  35548: 99999,
  35549: 27530,
  35550: 27530,
  35551: 99999,
  35552: 99999,
  35553: 99999,
  35554: 99999,
  35555: 46220,
  35559: 99999,
  35560: 27530,
  35563: 99999,
  35564: 99999,
  35565: 99999,
  35570: 99999,
  35571: 99999,
  35572: 99999,
  35573: 27530,
  35574: 99999,
  35575: 99999,
  35576: 46220,
  35577: 99999,
  35578: 99999,
  35579: 99999,
  35580: 27530,
  35581: 99999,
  35582: 99999,
  35584: 27530,
  35585: 99999,
  35586: 99999,
  35587: 27530,
  35592: 99999,
  35593: 99999,
  35594: 99999,
  35601: 19460,
  35602: 19460,
  35603: 19460,
  35610: 26620,
  35611: 26620,
  35612: 26620,
  35613: 26620,
  35614: 22520,
  35615: 26620,
  35616: 22520,
  35617: 22520,
  35618: 19460,
  35619: 19460,
  35620: 26620,
  35621: 18980,
  35622: 18980,
  35630: 22520,
  35631: 22520,
  35632: 22520,
  35633: 22520,
  35634: 22520,
  35640: 19460,
  35643: 19460,
  35645: 22520,
  35646: 22520,
  35647: 26620,
  35648: 22520,
  35649: 26620,
  35650: 19460,
  35651: 19460,
  35652: 26620,
  35653: 22520,
  35654: 99999,
  35660: 22520,
  35661: 22520,
  35670: 19460,
  35671: 26620,
  35672: 22520,
  35673: 19460,
  35674: 99999,
  35677: 22520,
  35739: 26620,
  35740: 42460,
  35741: 26620,
  35742: 26620,
  35744: 42460,
  35745: 42460,
  35746: 42460,
  35747: 10700,
  35748: 26620,
  35749: 26620,
  35750: 26620,
  35751: 42460,
  35752: 42460,
  35754: 19460,
  35755: 42460,
  35756: 26620,
  35757: 26620,
  35758: 26620,
  35759: 26620,
  35760: 10700,
  35761: 26620,
  35762: 26620,
  35763: 26620,
  35764: 42460,
  35765: 22840,
  35766: 42460,
  35767: 26620,
  35768: 42460,
  35769: 42460,
  35771: 22840,
  35772: 42460,
  35773: 26620,
  35774: 42460,
  35775: 19460,
  35776: 10700,
  35801: 26620,
  35802: 26620,
  35803: 26620,
  35804: 26620,
  35805: 26620,
  35806: 26620,
  35807: 26620,
  35808: 26620,
  35809: 26620,
  35810: 26620,
  35811: 26620,
  35812: 26620,
  35813: 26620,
  35814: 26620,
  35815: 26620,
  35816: 26620,
  35824: 26620,
  35893: 26620,
  35894: 26620,
  35895: 26620,
  35896: 26620,
  35897: 26620,
  35898: 26620,
  35899: 26620,
  35901: 23460,
  35902: 23460,
  35903: 23460,
  35904: 23460,
  35905: 23460,
  35906: 13820,
  35907: 23460,
  35950: 10700,
  35951: 22840,
  35952: 13820,
  35953: 13820,
  35954: 22840,
  35956: 10700,
  35957: 22840,
  35958: 42460,
  35959: 99999,
  35960: 99999,
  35961: 22840,
  35962: 10700,
  35963: 22840,
  35964: 10700,
  35966: 22840,
  35967: 22840,
  35968: 22840,
  35971: 22840,
  35972: 23460,
  35973: 99999,
  35974: 22840,
  35975: 22840,
  35976: 13820,
  35978: 22840,
  35979: 22840,
  35980: 10700,
  35981: 22840,
  35983: 22840,
  35984: 99999,
  35986: 22840,
  35987: 13820,
  35988: 22840,
  35989: 22840,
  35990: 23460,
  36003: 33860,
  36005: 45980,
  36006: 33860,
  36008: 33860,
  36009: 21460,
  36010: 37120,
  36013: 33860,
  36015: 99999,
  36016: 21640,
  36017: 21640,
  36020: 33860,
  36022: 33860,
  36024: 33860,
  36025: 33860,
  36026: 10760,
  36027: 17980,
  36028: 99999,
  36029: 33860,
  36030: 99999,
  36031: 99999,
  36032: 99999,
  36033: 99999,
  36034: 21460,
  36035: 99999,
  36036: 99999,
  36037: 99999,
  36038: 99999,
  36039: 99999,
  36040: 33860,
  36041: 99999,
  36042: 99999,
  36043: 33860,
  36046: 33860,
  36047: 33860,
  36048: 21640,
  36049: 99999,
  36051: 13820,
  36052: 33860,
  36053: 17980,
  36054: 33860,
  36057: 33860,
  36064: 33860,
  36065: 33860,
  36066: 33860,
  36067: 33860,
  36068: 33860,
  36069: 33860,
  36071: 99999,
  36072: 21640,
  36075: 99999,
  36078: 99999,
  36079: 21460,
  36080: 33860,
  36081: 99999,
  36082: 45980,
  36083: 99999,
  36087: 99999,
  36088: 99999,
  36089: 99999,
  36091: 13820,
  36092: 33860,
  36093: 33860,
  36101: 33860,
  36102: 33860,
  36103: 33860,
  36104: 33860,
  36105: 33860,
  36106: 33860,
  36107: 33860,
  36108: 33860,
  36109: 33860,
  36110: 33860,
  36111: 33860,
  36112: 33860,
  36113: 33860,
  36114: 33860,
  36115: 33860,
  36116: 33860,
  36117: 33860,
  36118: 33860,
  36119: 33860,
  36120: 33860,
  36121: 33860,
  36123: 33860,
  36124: 33860,
  36125: 33860,
  36130: 33860,
  36131: 33860,
  36201: 11500,
  36202: 11500,
  36203: 45180,
  36204: 11500,
  36205: 11500,
  36206: 11500,
  36207: 11500,
  36250: 11500,
  36251: 99999,
  36253: 11500,
  36254: 11500,
  36255: 10760,
  36256: 10760,
  36257: 11500,
  36258: 99999,
  36260: 45180,
  36261: 99999,
  36262: 99999,
  36263: 99999,
  36264: 99999,
  36265: 11500,
  36266: 99999,
  36267: 99999,
  36268: 99999,
  36269: 99999,
  36271: 23460,
  36272: 99999,
  36273: 99999,
  36274: 99999,
  36275: 99999,
  36276: 29300,
  36277: 11500,
  36278: 99999,
  36279: 11500,
  36280: 99999,
  36301: 20020,
  36302: 20020,
  36303: 20020,
  36304: 20020,
  36305: 20020,
  36310: 20020,
  36311: 21640,
  36312: 20020,
  36313: 20020,
  36314: 20020,
  36316: 21460,
  36317: 20020,
  36318: 21460,
  36319: 20020,
  36320: 20020,
  36321: 20020,
  36322: 20020,
  36323: 99999,
  36330: 37120,
  36340: 20020,
  36343: 20020,
  36344: 20020,
  36345: 20020,
  36346: 21460,
  36349: 20020,
  36350: 37120,
  36351: 21460,
  36352: 20020,
  36353: 20020,
  36360: 21460,
  36361: 37120,
  36362: 37120,
  36370: 20020,
  36371: 37120,
  36373: 20020,
  36374: 37120,
  36375: 20020,
  36376: 20020,
  36401: 99999,
  36420: 99999,
  36421: 99999,
  36425: 99999,
  36426: 12120,
  36432: 99999,
  36435: 99999,
  36436: 99999,
  36439: 99999,
  36441: 12120,
  36442: 99999,
  36444: 99999,
  36445: 99999,
  36446: 99999,
  36451: 99999,
  36453: 21460,
  36454: 99999,
  36455: 99999,
  36456: 99999,
  36458: 99999,
  36460: 99999,
  36467: 21460,
  36470: 99999,
  36471: 99999,
  36473: 99999,
  36474: 99999,
  36475: 99999,
  36476: 99999,
  36477: 20020,
  36480: 99999,
  36481: 99999,
  36482: 99999,
  36483: 99999,
  36502: 99999,
  36503: 12120,
  36505: 33660,
  36507: 19300,
  36509: 33660,
  36511: 19300,
  36512: 33660,
  36513: 33660,
  36518: 33660,
  36521: 33660,
  36522: 33660,
  36523: 33660,
  36524: 99999,
  36525: 33660,
  36526: 19300,
  36527: 19300,
  36528: 33660,
  36529: 33660,
  36530: 19300,
  36532: 19300,
  36533: 19300,
  36535: 19300,
  36536: 19300,
  36538: 33660,
  36539: 33660,
  36540: 99999,
  36541: 33660,
  36542: 19300,
  36543: 12120,
  36544: 33660,
  36545: 99999,
  36547: 19300,
  36548: 33660,
  36549: 19300,
  36550: 19300,
  36551: 19300,
  36553: 33660,
  36555: 19300,
  36556: 33660,
  36558: 99999,
  36559: 19300,
  36560: 33660,
  36561: 19300,
  36562: 12120,
  36564: 19300,
  36567: 19300,
  36568: 33660,
  36569: 33660,
  36571: 33660,
  36572: 33660,
  36574: 19300,
  36575: 33660,
  36576: 19300,
  36577: 19300,
  36578: 19300,
  36579: 19300,
  36580: 19300,
  36581: 33660,
  36582: 33660,
  36583: 33660,
  36584: 33660,
  36585: 33660,
  36587: 33660,
  36590: 33660,
  36601: 33660,
  36602: 33660,
  36603: 33660,
  36604: 33660,
  36605: 33660,
  36606: 33660,
  36607: 33660,
  36608: 33660,
  36609: 33660,
  36610: 33660,
  36611: 33660,
  36612: 33660,
  36613: 33660,
  36615: 33660,
  36616: 33660,
  36617: 33660,
  36618: 33660,
  36619: 33660,
  36633: 33660,
  36640: 33660,
  36644: 33660,
  36652: 33660,
  36660: 33660,
  36663: 33660,
  36670: 33660,
  36671: 33660,
  36675: 33660,
  36685: 33660,
  36688: 33660,
  36689: 33660,
  36691: 33660,
  36693: 33660,
  36695: 33660,
  36701: 42820,
  36703: 42820,
  36720: 99999,
  36722: 99999,
  36723: 99999,
  36726: 99999,
  36727: 99999,
  36728: 99999,
  36732: 99999,
  36736: 99999,
  36738: 46220,
  36740: 46220,
  36741: 99999,
  36742: 46220,
  36744: 46220,
  36745: 99999,
  36748: 99999,
  36749: 42820,
  36750: 13820,
  36751: 99999,
  36752: 33860,
  36753: 99999,
  36754: 99999,
  36756: 99999,
  36758: 42820,
  36759: 99999,
  36761: 33860,
  36763: 99999,
  36764: 99999,
  36765: 46220,
  36766: 99999,
  36767: 42820,
  36768: 99999,
  36769: 99999,
  36773: 42820,
  36775: 42820,
  36776: 46220,
  36782: 99999,
  36783: 99999,
  36784: 99999,
  36785: 42820,
  36786: 46220,
  36790: 13820,
  36792: 13820,
  36793: 99999,
  36801: 29300,
  36803: 12220,
  36804: 99999,
  36830: 99999,
  36831: 12220,
  36832: 99999,
  36849: 12220,
  36850: 29300,
  36851: 17980,
  36852: 12220,
  36853: 10760,
  36854: 12220,
  36855: 29300,
  36856: 17980,
  36858: 17980,
  36860: 99999,
  36861: 10760,
  36862: 29300,
  36863: 29300,
  36865: 12220,
  36866: 12220,
  36867: 17980,
  36868: 17980,
  36869: 17980,
  36870: 17980,
  36871: 17980,
  36874: 17980,
  36875: 17980,
  36877: 17980,
  36879: 10760,
  36901: 99999,
  36904: 99999,
  36907: 99999,
  36908: 99999,
  36910: 99999,
  36912: 99999,
  36913: 99999,
  36915: 99999,
  36916: 99999,
  36919: 99999,
  36921: 99999,
  36922: 99999,
  36925: 99999,
  37010: 17300,
  37011: 34980,
  37012: 99999,
  37013: 34980,
  37014: 34980,
  37015: 17300,
  37016: 34980,
  37018: 43180,
  37019: 30280,
  37020: 34980,
  37022: 34980,
  37023: 17300,
  37024: 34980,
  37025: 34980,
  37026: 34980,
  37027: 34980,
  37028: 17300,
  37029: 34980,
  37030: 18260,
  37031: 34980,
  37032: 34980,
  37033: 99999,
  37034: 43180,
  37035: 17300,
  37036: 34980,
  37037: 46100,
  37040: 17300,
  37041: 17300,
  37042: 17300,
  37043: 34980,
  37044: 17300,
  37046: 34980,
  37047: 30280,
  37048: 34980,
  37049: 34980,
  37050: 99999,
  37051: 34980,
  37052: 17300,
  37055: 34980,
  37056: 34980,
  37057: 34980,
  37058: 17300,
  37059: 99999,
  37060: 30280,
  37061: 99999,
  37062: 34980,
  37063: 34980,
  37064: 34980,
  37065: 34980,
  37066: 34980,
  37067: 34980,
  37068: 34980,
  37069: 34980,
  37070: 34980,
  37071: 34980,
  37072: 34980,
  37073: 34980,
  37074: 34980,
  37075: 34980,
  37076: 34980,
  37077: 34980,
  37078: 99999,
  37079: 17300,
  37080: 34980,
  37082: 34980,
  37083: 34980,
  37085: 34980,
  37086: 34980,
  37087: 34980,
  37088: 34980,
  37089: 34980,
  37090: 34980,
  37091: 34980,
  37095: 34980,
  37096: 99999,
  37097: 99999,
  37098: 99999,
  37101: 34980,
  37110: 16860,
  37115: 34980,
  37116: 34980,
  37118: 34980,
  37119: 34980,
  37121: 34980,
  37122: 34980,
  37127: 34980,
  37128: 34980,
  37129: 34980,
  37130: 34980,
  37131: 34980,
  37132: 34980,
  37133: 34980,
  37134: 99999,
  37135: 34980,
  37137: 99999,
  37138: 34980,
  37140: 99999,
  37141: 34980,
  37142: 17300,
  37143: 34980,
  37144: 46100,
  37145: 34980,
  37146: 34980,
  37148: 34980,
  37149: 34980,
  37150: 99999,
  37151: 34980,
  37152: 34980,
  37153: 43180,
  37160: 34980,
  37161: 43180,
  37165: 34980,
  37166: 34980,
  37167: 34980,
  37171: 17300,
  37172: 34980,
  37174: 34980,
  37175: 17300,
  37178: 99999,
  37179: 34980,
  37180: 30280,
  37181: 99999,
  37183: 46100,
  37184: 99999,
  37185: 99999,
  37186: 34980,
  37187: 34980,
  37188: 34980,
  37189: 34980,
  37190: 34980,
  37191: 17300,
  37201: 34980,
  37202: 34980,
  37203: 34980,
  37204: 34980,
  37205: 34980,
  37206: 34980,
  37207: 34980,
  37208: 34980,
  37209: 34980,
  37210: 34980,
  37211: 34980,
  37212: 34980,
  37213: 34980,
  37214: 34980,
  37215: 34980,
  37216: 34980,
  37217: 34980,
  37218: 34980,
  37219: 34980,
  37220: 34980,
  37221: 34980,
  37222: 34980,
  37224: 34980,
  37227: 34980,
  37228: 34980,
  37229: 34980,
  37232: 34980,
  37234: 34980,
  37235: 34980,
  37236: 34980,
  37238: 34980,
  37240: 34980,
  37242: 34980,
  37243: 34980,
  37246: 34980,
  37250: 34980,
  37301: 99999,
  37302: 16860,
  37303: 11940,
  37304: 16860,
  37305: 99999,
  37306: 46100,
  37307: 17420,
  37308: 16860,
  37309: 17420,
  37310: 17420,
  37311: 16860,
  37312: 17420,
  37313: 99999,
  37314: 99999,
  37315: 16860,
  37317: 17420,
  37318: 46100,
  37320: 17420,
  37321: 99999,
  37322: 11940,
  37323: 17420,
  37324: 46100,
  37325: 17420,
  37326: 17420,
  37327: 16860,
  37328: 46100,
  37329: 99999,
  37330: 46100,
  37331: 11940,
  37332: 99999,
  37333: 17420,
  37334: 99999,
  37335: 46100,
  37336: 16860,
  37337: 18900,
  37338: 99999,
  37339: 99999,
  37340: 16860,
  37341: 16860,
  37342: 46100,
  37343: 16860,
  37345: 99999,
  37347: 16860,
  37348: 99999,
  37350: 16860,
  37351: 16860,
  37352: 46100,
  37353: 16860,
  37354: 99999,
  37355: 46100,
  37356: 99999,
  37357: 32660,
  37359: 99999,
  37360: 43180,
  37361: 17420,
  37362: 17420,
  37363: 16860,
  37364: 17420,
  37365: 16860,
  37366: 16860,
  37367: 19420,
  37369: 99999,
  37370: 11940,
  37371: 11940,
  37373: 19420,
  37374: 99999,
  37375: 46100,
  37376: 46100,
  37377: 16860,
  37378: 32660,
  37379: 99999,
  37380: 46100,
  37381: 18900,
  37382: 46100,
  37383: 46100,
  37384: 16860,
  37385: 17420,
  37387: 99999,
  37388: 46100,
  37389: 46100,
  37391: 17420,
  37394: 32660,
  37396: 16860,
  37397: 16860,
  37398: 46100,
  37401: 16860,
  37402: 16860,
  37403: 16860,
  37404: 16860,
  37405: 16860,
  37406: 16860,
  37407: 16860,
  37408: 16860,
  37409: 16860,
  37410: 16860,
  37411: 16860,
  37412: 16860,
  37414: 16860,
  37415: 16860,
  37416: 16860,
  37419: 16860,
  37421: 16860,
  37422: 16860,
  37424: 16860,
  37450: 16860,
  37501: 32820,
  37601: 27740,
  37602: 27740,
  37604: 27740,
  37605: 27740,
  37614: 27740,
  37615: 27740,
  37616: 24620,
  37617: 28700,
  37618: 28700,
  37620: 28700,
  37621: 28700,
  37625: 28700,
  37640: 99999,
  37641: 27740,
  37642: 28700,
  37643: 27740,
  37644: 27740,
  37645: 28700,
  37650: 27740,
  37656: 24620,
  37657: 27740,
  37658: 27740,
  37659: 27740,
  37660: 28700,
  37662: 28700,
  37663: 27740,
  37664: 28700,
  37665: 28700,
  37669: 28700,
  37680: 99999,
  37681: 27740,
  37682: 27740,
  37683: 99999,
  37684: 27740,
  37686: 27740,
  37687: 27740,
  37688: 99999,
  37690: 27740,
  37691: 99999,
  37692: 27740,
  37694: 28700,
  37699: 28700,
  37701: 28940,
  37705: 28940,
  37707: 99999,
  37708: 34100,
  37709: 28940,
  37710: 28940,
  37711: 24620,
  37713: 35460,
  37714: 28940,
  37715: 99999,
  37716: 28940,
  37717: 28940,
  37719: 28940,
  37721: 28940,
  37722: 42940,
  37723: 18900,
  37724: 99999,
  37725: 42940,
  37726: 99999,
  37727: 35460,
  37729: 28940,
  37730: 99999,
  37731: 28700,
  37732: 99999,
  37733: 28940,
  37737: 28940,
  37738: 42940,
  37742: 28940,
  37743: 24620,
  37744: 24620,
  37745: 28700,
  37748: 28940,
  37752: 99999,
  37753: 35460,
  37754: 28940,
  37755: 99999,
  37756: 28940,
  37757: 28940,
  37760: 34100,
  37762: 28940,
  37763: 28940,
  37764: 42940,
  37765: 99999,
  37766: 28940,
  37769: 28940,
  37770: 28940,
  37771: 28940,
  37772: 28940,
  37774: 99999,
  37777: 28940,
  37778: 34100,
  37779: 28940,
  37801: 28940,
  37802: 28940,
  37803: 28940,
  37804: 28940,
  37806: 34100,
  37807: 28940,
  37809: 24620,
  37810: 24620,
  37811: 34100,
  37813: 34100,
  37814: 34100,
  37815: 34100,
  37816: 34100,
  37818: 24620,
  37819: 28940,
  37820: 42940,
  37821: 34100,
  37825: 28940,
  37826: 11940,
  37828: 28940,
  37829: 28940,
  37830: 28940,
  37831: 28940,
  37840: 28940,
  37841: 99999,
  37843: 35460,
  37845: 28940,
  37846: 99999,
  37847: 28940,
  37848: 34100,
  37849: 28940,
  37851: 99999,
  37852: 28940,
  37853: 28940,
  37854: 28940,
  37857: 28700,
  37860: 34100,
  37861: 34100,
  37862: 42940,
  37863: 42940,
  37864: 42940,
  37865: 28940,
  37866: 28940,
  37868: 42940,
  37869: 99999,
  37870: 28940,
  37871: 34100,
  37872: 28940,
  37873: 28700,
  37874: 28940,
  37876: 34100,
  37877: 34100,
  37878: 28940,
  37879: 99999,
  37880: 28940,
  37881: 28700,
  37882: 28940,
  37885: 28940,
  37886: 28940,
  37887: 28940,
  37888: 34100,
  37890: 34100,
  37891: 34100,
  37892: 99999,
  37901: 28940,
  37902: 28940,
  37909: 28940,
  37912: 28940,
  37914: 28940,
  37915: 28940,
  37916: 28940,
  37917: 28940,
  37918: 28940,
  37919: 28940,
  37920: 28940,
  37921: 28940,
  37922: 28940,
  37923: 28940,
  37924: 28940,
  37927: 28940,
  37928: 28940,
  37929: 28940,
  37930: 28940,
  37931: 28940,
  37932: 28940,
  37933: 28940,
  37934: 28940,
  37938: 28940,
  37939: 28940,
  37940: 28940,
  37950: 28940,
  37995: 28940,
  37996: 28940,
  37997: 28940,
  37998: 28940,
  38001: 27180,
  38002: 32820,
  38004: 32820,
  38006: 27180,
  38007: 20540,
  38008: 99999,
  38011: 32820,
  38012: 15140,
  38014: 32820,
  38015: 32820,
  38016: 32820,
  38017: 32820,
  38018: 32820,
  38019: 32820,
  38021: 27180,
  38023: 32820,
  38024: 20540,
  38025: 20540,
  38027: 32820,
  38028: 32820,
  38029: 32820,
  38030: 20540,
  38034: 20540,
  38036: 32820,
  38037: 99999,
  38039: 32820,
  38040: 20540,
  38041: 99999,
  38042: 32820,
  38044: 99999,
  38045: 32820,
  38046: 32820,
  38047: 20540,
  38048: 32820,
  38049: 15140,
  38050: 27180,
  38052: 99999,
  38053: 32820,
  38054: 32820,
  38055: 32820,
  38057: 32820,
  38058: 32820,
  38059: 20540,
  38060: 32820,
  38061: 99999,
  38063: 15140,
  38066: 32820,
  38067: 99999,
  38068: 32820,
  38069: 15140,
  38070: 20540,
  38071: 32820,
  38075: 32820,
  38076: 32820,
  38077: 99999,
  38079: 99999,
  38080: 99999,
  38083: 32820,
  38088: 32820,
  38101: 32820,
  38103: 32820,
  38104: 32820,
  38105: 32820,
  38106: 32820,
  38107: 32820,
  38108: 32820,
  38109: 32820,
  38111: 32820,
  38112: 32820,
  38113: 32820,
  38114: 32820,
  38115: 32820,
  38116: 32820,
  38117: 32820,
  38118: 32820,
  38119: 32820,
  38120: 32820,
  38122: 32820,
  38124: 32820,
  38125: 32820,
  38126: 32820,
  38127: 32820,
  38128: 32820,
  38130: 32820,
  38131: 32820,
  38132: 32820,
  38133: 32820,
  38134: 32820,
  38135: 32820,
  38136: 32820,
  38137: 32820,
  38138: 32820,
  38139: 32820,
  38141: 32820,
  38152: 32820,
  38157: 32820,
  38161: 32820,
  38166: 32820,
  38167: 32820,
  38168: 32820,
  38173: 32820,
  38174: 32820,
  38175: 32820,
  38177: 32820,
  38181: 32820,
  38182: 32820,
  38183: 32820,
  38184: 32820,
  38186: 32820,
  38187: 32820,
  38188: 32820,
  38190: 32820,
  38193: 32820,
  38197: 32820,
  38201: 32280,
  38220: 27180,
  38221: 99999,
  38222: 37540,
  38223: 37540,
  38224: 37540,
  38225: 32280,
  38226: 32280,
  38229: 37540,
  38230: 32280,
  38231: 99999,
  38232: 46460,
  38233: 46460,
  38235: 99999,
  38236: 37540,
  38237: 46460,
  38238: 32280,
  38240: 20540,
  38241: 32280,
  38242: 32280,
  38251: 37540,
  38253: 46460,
  38254: 46460,
  38255: 46460,
  38256: 37540,
  38257: 46460,
  38258: 99999,
  38259: 46460,
  38260: 46460,
  38261: 32280,
  38271: 46460,
  38281: 46460,
  38301: 15140,
  38302: 27180,
  38303: 27180,
  38305: 99999,
  38308: 27180,
  38310: 99999,
  38311: 99999,
  38313: 99999,
  38315: 27180,
  38316: 27180,
  38317: 99999,
  38318: 99999,
  38320: 99999,
  38321: 99999,
  38324: 99999,
  38326: 99999,
  38327: 99999,
  38328: 99999,
  38329: 99999,
  38330: 27180,
  38331: 27180,
  38332: 99999,
  38333: 99999,
  38334: 99999,
  38336: 27180,
  38337: 27180,
  38338: 27180,
  38339: 99999,
  38340: 99999,
  38341: 99999,
  38342: 99999,
  38343: 27180,
  38344: 99999,
  38345: 27180,
  38346: 27180,
  38347: 27180,
  38348: 99999,
  38351: 99999,
  38352: 27180,
  38355: 27180,
  38356: 99999,
  38357: 99999,
  38358: 27180,
  38359: 27180,
  38361: 99999,
  38362: 27180,
  38363: 99999,
  38365: 99999,
  38366: 27180,
  38367: 99999,
  38368: 27180,
  38369: 27180,
  38370: 99999,
  38371: 99999,
  38372: 99999,
  38374: 99999,
  38375: 99999,
  38376: 99999,
  38378: 27180,
  38379: 99999,
  38380: 99999,
  38381: 99999,
  38382: 27180,
  38387: 99999,
  38388: 99999,
  38389: 27180,
  38390: 99999,
  38391: 27180,
  38392: 15140,
  38393: 99999,
  38401: 34980,
  38402: 34980,
  38425: 99999,
  38449: 99999,
  38450: 99999,
  38451: 99999,
  38452: 99999,
  38453: 99999,
  38454: 99999,
  38455: 99999,
  38456: 29980,
  38457: 29980,
  38459: 99999,
  38460: 99999,
  38461: 34980,
  38462: 99999,
  38463: 29980,
  38464: 29980,
  38468: 99999,
  38469: 29980,
  38471: 99999,
  38472: 34980,
  38473: 29980,
  38474: 29980,
  38475: 99999,
  38476: 99999,
  38477: 99999,
  38478: 99999,
  38481: 29980,
  38482: 34980,
  38483: 29980,
  38485: 99999,
  38486: 29980,
  38487: 99999,
  38488: 99999,
  38501: 18260,
  38502: 18260,
  38503: 18260,
  38504: 99999,
  38506: 99999,
  38541: 99999,
  38542: 18260,
  38543: 18260,
  38544: 99999,
  38545: 18260,
  38547: 34980,
  38548: 18260,
  38549: 99999,
  38550: 32660,
  38551: 99999,
  38552: 34980,
  38553: 99999,
  38554: 18260,
  38555: 18900,
  38556: 99999,
  38557: 18900,
  38558: 18900,
  38559: 99999,
  38560: 34980,
  38562: 99999,
  38563: 34980,
  38564: 18260,
  38565: 99999,
  38567: 99999,
  38568: 99999,
  38569: 34980,
  38570: 18260,
  38571: 18900,
  38572: 18900,
  38573: 18260,
  38574: 18260,
  38575: 99999,
  38577: 99999,
  38578: 18900,
  38579: 99999,
  38580: 18260,
  38581: 32660,
  38582: 18260,
  38583: 18900,
  38585: 99999,
  38587: 99999,
  38588: 34980,
  38589: 99999,
  38601: 37060,
  38602: 32820,
  38603: 99999,
  38606: 37060,
  38610: 99999,
  38611: 32820,
  38614: 17380,
  38617: 17260,
  38618: 32820,
  38619: 99999,
  38620: 99999,
  38621: 99999,
  38622: 99999,
  38623: 99999,
  38625: 99999,
  38626: 17260,
  38627: 46180,
  38629: 99999,
  38631: 17260,
  38632: 32820,
  38633: 99999,
  38635: 99999,
  38637: 32820,
  38638: 32820,
  38639: 17260,
  38641: 32820,
  38642: 32820,
  38643: 99999,
  38644: 17260,
  38645: 99999,
  38646: 99999,
  38647: 99999,
  38649: 32820,
  38650: 99999,
  38651: 32820,
  38652: 99999,
  38654: 32820,
  38655: 99999,
  38658: 99999,
  38659: 32820,
  38661: 32820,
  38663: 99999,
  38664: 32820,
  38665: 99999,
  38666: 99999,
  38668: 99999,
  38670: 99999,
  38671: 32820,
  38672: 32820,
  38673: 37060,
  38674: 99999,
  38676: 32820,
  38677: 37060,
  38679: 32820,
  38680: 32820,
  38683: 18420,
  38685: 37060,
  38686: 32820,
  38701: 24740,
  38703: 17380,
  38720: 17380,
  38721: 24740,
  38722: 24740,
  38723: 24740,
  38725: 17380,
  38726: 17380,
  38730: 17380,
  38731: 24740,
  38732: 17380,
  38733: 17380,
  38736: 24900,
  38737: 24900,
  38738: 26940,
  38739: 17260,
  38740: 17380,
  38744: 24740,
  38745: 99999,
  38746: 17380,
  38748: 24740,
  38751: 24740,
  38753: 99999,
  38754: 24740,
  38756: 24740,
  38759: 17380,
  38760: 24740,
  38761: 26940,
  38762: 17380,
  38764: 17380,
  38765: 99999,
  38767: 17260,
  38768: 26940,
  38769: 17380,
  38771: 26940,
  38772: 17380,
  38773: 24740,
  38774: 17380,
  38776: 24740,
  38778: 26940,
  38780: 24740,
  38781: 17380,
  38782: 24740,
  38801: 46180,
  38802: 46180,
  38803: 46180,
  38804: 46180,
  38820: 46180,
  38821: 99999,
  38824: 99999,
  38825: 99999,
  38826: 46180,
  38827: 99999,
  38828: 46180,
  38829: 99999,
  38833: 18420,
  38834: 18420,
  38835: 18420,
  38838: 99999,
  38839: 99999,
  38841: 99999,
  38843: 46180,
  38844: 99999,
  38846: 18420,
  38847: 99999,
  38848: 99999,
  38849: 99999,
  38850: 46180,
  38851: 99999,
  38852: 99999,
  38855: 46180,
  38856: 46180,
  38857: 46180,
  38858: 99999,
  38859: 46180,
  38860: 99999,
  38862: 46180,
  38863: 37060,
  38864: 99999,
  38865: 18420,
  38866: 46180,
  38868: 99999,
  38869: 46180,
  38870: 46180,
  38871: 99999,
  38873: 99999,
  38874: 46180,
  38875: 99999,
  38876: 46180,
  38877: 99999,
  38878: 99999,
  38879: 46180,
  38880: 46180,
  38901: 24980,
  38913: 99999,
  38914: 99999,
  38915: 37060,
  38916: 99999,
  38917: 24900,
  38920: 99999,
  38921: 99999,
  38922: 24980,
  38923: 24900,
  38924: 27140,
  38925: 99999,
  38927: 99999,
  38928: 99999,
  38929: 44260,
  38930: 24900,
  38940: 24900,
  38941: 24900,
  38943: 24900,
  38944: 99999,
  38946: 24900,
  38947: 24900,
  38948: 99999,
  38949: 37060,
  38950: 99999,
  38951: 99999,
  38952: 24900,
  38953: 24980,
  38954: 27140,
  38957: 99999,
  38959: 24900,
  38960: 24980,
  38961: 24980,
  38962: 99999,
  38963: 17260,
  38964: 99999,
  38965: 37060,
  38966: 99999,
  38967: 99999,
  39038: 26940,
  39039: 27140,
  39040: 27140,
  39041: 27140,
  39042: 27140,
  39043: 27140,
  39044: 27140,
  39045: 27140,
  39046: 27140,
  39047: 27140,
  39051: 99999,
  39054: 99999,
  39056: 27140,
  39057: 99999,
  39058: 27140,
  39059: 27140,
  39060: 27140,
  39061: 99999,
  39062: 27140,
  39063: 27140,
  39066: 27140,
  39067: 99999,
  39069: 99999,
  39071: 27140,
  39073: 27140,
  39074: 99999,
  39077: 27140,
  39078: 27140,
  39079: 27140,
  39080: 99999,
  39082: 27140,
  39083: 27140,
  39086: 99999,
  39087: 99999,
  39088: 27140,
  39090: 99999,
  39092: 99999,
  39094: 99999,
  39095: 27140,
  39096: 99999,
  39097: 99999,
  39098: 99999,
  39108: 99999,
  39109: 99999,
  39110: 27140,
  39111: 99999,
  39113: 99999,
  39114: 99999,
  39115: 99999,
  39116: 99999,
  39117: 99999,
  39119: 25620,
  39120: 99999,
  39121: 35020,
  39122: 35020,
  39130: 27140,
  39140: 27140,
  39144: 27140,
  39145: 27140,
  39146: 27140,
  39148: 27140,
  39149: 27140,
  39150: 99999,
  39151: 27140,
  39152: 99999,
  39153: 99999,
  39154: 27140,
  39156: 46980,
  39157: 27140,
  39158: 27140,
  39159: 99999,
  39160: 99999,
  39161: 27140,
  39162: 27140,
  39163: 27140,
  39165: 35020,
  39166: 99999,
  39167: 27140,
  39168: 99999,
  39169: 27140,
  39170: 27140,
  39171: 99999,
  39173: 27140,
  39174: 27140,
  39175: 27140,
  39176: 99999,
  39177: 99999,
  39179: 27140,
  39180: 46980,
  39181: 46980,
  39182: 46980,
  39183: 46980,
  39189: 99999,
  39190: 35020,
  39191: 99999,
  39192: 99999,
  39193: 27140,
  39194: 99999,
  39201: 27140,
  39202: 27140,
  39203: 27140,
  39204: 27140,
  39205: 27140,
  39206: 27140,
  39207: 27140,
  39208: 27140,
  39209: 27140,
  39210: 27140,
  39211: 27140,
  39212: 27140,
  39213: 27140,
  39215: 27140,
  39216: 27140,
  39217: 27140,
  39218: 27140,
  39225: 27140,
  39232: 27140,
  39236: 27140,
  39269: 27140,
  39272: 27140,
  39283: 27140,
  39284: 27140,
  39286: 27140,
  39288: 27140,
  39296: 27140,
  39298: 27140,
  39301: 32940,
  39302: 32940,
  39303: 32940,
  39304: 32940,
  39305: 32940,
  39307: 32940,
  39309: 32940,
  39320: 32940,
  39322: 99999,
  39323: 32940,
  39324: 99999,
  39325: 99999,
  39326: 32940,
  39327: 99999,
  39328: 99999,
  39330: 29860,
  39332: 99999,
  39335: 32940,
  39336: 29860,
  39337: 99999,
  39338: 99999,
  39339: 99999,
  39341: 99999,
  39342: 32940,
  39345: 29860,
  39346: 99999,
  39347: 29860,
  39348: 29860,
  39350: 99999,
  39352: 32940,
  39354: 32940,
  39355: 32940,
  39356: 29860,
  39358: 32940,
  39359: 99999,
  39360: 32940,
  39361: 32940,
  39362: 99999,
  39363: 32940,
  39364: 32940,
  39365: 99999,
  39366: 32940,
  39367: 99999,
  39401: 29860,
  39402: 25620,
  39403: 25620,
  39404: 25620,
  39406: 25620,
  39407: 25620,
  39421: 99999,
  39422: 99999,
  39423: 25620,
  39425: 25620,
  39426: 25060,
  39427: 99999,
  39428: 25620,
  39429: 99999,
  39436: 29860,
  39437: 29860,
  39439: 99999,
  39440: 29860,
  39443: 99999,
  39451: 99999,
  39452: 25060,
  39455: 25620,
  39456: 99999,
  39457: 38100,
  39459: 25620,
  39460: 29860,
  39461: 99999,
  39462: 25620,
  39463: 38100,
  39464: 25620,
  39465: 25620,
  39466: 38100,
  39470: 38100,
  39474: 99999,
  39475: 25620,
  39476: 25620,
  39477: 29860,
  39478: 99999,
  39479: 25620,
  39480: 29860,
  39481: 29860,
  39482: 25620,
  39483: 99999,
  39501: 25060,
  39502: 25060,
  39503: 25060,
  39505: 25060,
  39506: 25060,
  39507: 25060,
  39520: 25060,
  39522: 25060,
  39525: 25060,
  39529: 25060,
  39530: 25060,
  39531: 25060,
  39532: 25060,
  39533: 25060,
  39534: 25060,
  39535: 25060,
  39540: 25060,
  39552: 25060,
  39553: 25060,
  39555: 25060,
  39556: 25060,
  39558: 25060,
  39560: 25060,
  39561: 25060,
  39562: 25060,
  39563: 25060,
  39564: 25060,
  39565: 25060,
  39566: 25060,
  39567: 25060,
  39569: 25060,
  39571: 25060,
  39572: 25060,
  39573: 25060,
  39574: 25060,
  39576: 25060,
  39577: 25060,
  39581: 25060,
  39595: 25060,
  39601: 15020,
  39602: 15020,
  39603: 15020,
  39629: 15020,
  39630: 99999,
  39631: 99999,
  39632: 32620,
  39633: 99999,
  39635: 32620,
  39638: 99999,
  39641: 99999,
  39643: 99999,
  39645: 99999,
  39647: 15020,
  39648: 32620,
  39649: 32620,
  39652: 99999,
  39653: 99999,
  39654: 27140,
  39656: 99999,
  39657: 32620,
  39661: 35020,
  39662: 99999,
  39663: 99999,
  39664: 99999,
  39665: 99999,
  39666: 32620,
  39667: 32620,
  39668: 99999,
  39669: 99999,
  39701: 18060,
  39702: 18060,
  39703: 18060,
  39704: 18060,
  39705: 18060,
  39710: 18060,
  39730: 99999,
  39735: 44260,
  39736: 18060,
  39737: 44260,
  39739: 44260,
  39740: 18060,
  39741: 48500,
  39743: 99999,
  39744: 44260,
  39745: 99999,
  39746: 99999,
  39747: 99999,
  39750: 44260,
  39751: 99999,
  39752: 99999,
  39753: 18060,
  39755: 44260,
  39756: 48500,
  39759: 48500,
  39760: 44260,
  39762: 44260,
  39766: 18060,
  39767: 99999,
  39769: 99999,
  39771: 44260,
  39772: 99999,
  39773: 48500,
  39776: 99999,
  39813: 99999,
  39815: 12460,
  39817: 12460,
  39818: 12460,
  39819: 12460,
  39823: 99999,
  39824: 99999,
  39825: 12460,
  39826: 10500,
  39827: 99999,
  39828: 99999,
  39829: 99999,
  39832: 99999,
  39834: 12460,
  39836: 99999,
  39837: 99999,
  39840: 99999,
  39841: 99999,
  39842: 10500,
  39845: 99999,
  39846: 99999,
  39851: 99999,
  39852: 12460,
  39854: 17980,
  39859: 99999,
  39861: 99999,
  39862: 99999,
  39866: 99999,
  39867: 17980,
  39870: 99999,
  39877: 99999,
  39885: 10500,
  39886: 99999,
  39897: 12460,
  39901: 12060,
  40003: 31140,
  40004: 12680,
  40006: 31140,
  40007: 31140,
  40008: 31140,
  40009: 15820,
  40010: 31140,
  40011: 99999,
  40012: 12680,
  40013: 12680,
  40014: 31140,
  40018: 31140,
  40019: 31140,
  40020: 12680,
  40022: 31140,
  40023: 31140,
  40025: 31140,
  40026: 31140,
  40027: 31140,
  40031: 31140,
  40032: 31140,
  40033: 15820,
  40036: 31140,
  40037: 99999,
  40040: 99999,
  40041: 31140,
  40045: 99999,
  40046: 31140,
  40047: 31140,
  40048: 12680,
  40049: 99999,
  40050: 31140,
  40051: 12680,
  40052: 21060,
  40055: 31140,
  40056: 31140,
  40057: 31140,
  40058: 31140,
  40059: 31140,
  40060: 99999,
  40061: 99999,
  40062: 99999,
  40063: 99999,
  40065: 31140,
  40066: 31140,
  40067: 31140,
  40068: 31140,
  40069: 12680,
  40070: 31140,
  40071: 12680,
  40075: 99999,
  40076: 23180,
  40077: 31140,
  40078: 99999,
  40104: 21060,
  40107: 12680,
  40108: 21060,
  40109: 31140,
  40110: 31140,
  40111: 99999,
  40115: 99999,
  40117: 21060,
  40118: 31140,
  40119: 99999,
  40121: 21060,
  40122: 21060,
  40129: 31140,
  40140: 21060,
  40142: 99999,
  40143: 99999,
  40144: 99999,
  40145: 99999,
  40146: 99999,
  40150: 21060,
  40152: 99999,
  40153: 99999,
  40155: 21060,
  40157: 99999,
  40159: 21060,
  40160: 21060,
  40161: 99999,
  40162: 21060,
  40165: 31140,
  40166: 31140,
  40170: 99999,
  40171: 99999,
  40175: 99999,
  40176: 21060,
  40177: 21060,
  40178: 99999,
  40201: 31140,
  40202: 31140,
  40203: 31140,
  40204: 31140,
  40205: 31140,
  40206: 31140,
  40207: 31140,
  40208: 31140,
  40209: 31140,
  40210: 31140,
  40211: 31140,
  40212: 31140,
  40213: 31140,
  40214: 31140,
  40215: 31140,
  40216: 31140,
  40217: 31140,
  40218: 31140,
  40219: 31140,
  40220: 31140,
  40221: 31140,
  40222: 31140,
  40223: 31140,
  40225: 31140,
  40228: 31140,
  40229: 31140,
  40231: 31140,
  40232: 31140,
  40233: 31140,
  40241: 31140,
  40242: 31140,
  40243: 31140,
  40245: 31140,
  40250: 31140,
  40251: 31140,
  40252: 31140,
  40253: 31140,
  40255: 31140,
  40256: 31140,
  40257: 31140,
  40258: 31140,
  40259: 31140,
  40261: 31140,
  40266: 31140,
  40268: 31140,
  40269: 31140,
  40270: 31140,
  40272: 31140,
  40280: 31140,
  40281: 31140,
  40282: 31140,
  40283: 31140,
  40285: 31140,
  40289: 31140,
  40291: 31140,
  40292: 31140,
  40299: 31140,
  40310: 99999,
  40311: 99999,
  40312: 40080,
  40313: 99999,
  40316: 34460,
  40317: 99999,
  40319: 99999,
  40322: 34460,
  40324: 30460,
  40328: 19220,
  40330: 99999,
  40334: 34460,
  40336: 99999,
  40337: 99999,
  40339: 30460,
  40340: 30460,
  40342: 31140,
  40346: 34460,
  40347: 23180,
  40348: 30460,
  40350: 99999,
  40351: 34460,
  40353: 30460,
  40355: 99999,
  40356: 30460,
  40357: 30460,
  40358: 34460,
  40359: 99999,
  40360: 34460,
  40361: 99999,
  40363: 99999,
  40370: 30460,
  40371: 99999,
  40372: 23180,
  40374: 99999,
  40376: 99999,
  40379: 99999,
  40380: 40080,
  40383: 30460,
  40385: 40080,
  40387: 99999,
  40390: 30460,
  40391: 34460,
  40392: 30460,
  40402: 99999,
  40403: 99999,
  40405: 40080,
  40409: 19220,
  40410: 99999,
  40419: 99999,
  40422: 19220,
  40434: 99999,
  40437: 99999,
  40440: 19220,
  40442: 19220,
  40444: 19220,
  40445: 99999,
  40447: 40080,
  40448: 19220,
  40452: 19220,
  40456: 43700,
  40460: 99999,
  40461: 99999,
  40464: 99999,
  40468: 19220,
  40472: 99999,
  40473: 99999,
  40475: 40080,
  40476: 40080,
  40481: 99999,
  40484: 99999,
  40486: 99999,
  40489: 99999,
  40492: 99999,
  40502: 30460,
  40503: 30460,
  40504: 30460,
  40505: 30460,
  40506: 30460,
  40507: 30460,
  40508: 30460,
  40509: 30460,
  40510: 30460,
  40511: 30460,
  40512: 30460,
  40513: 30460,
  40514: 30460,
  40515: 30460,
  40516: 30460,
  40517: 30460,
  40522: 30460,
  40523: 30460,
  40524: 30460,
  40526: 30460,
  40533: 30460,
  40536: 30460,
  40544: 30460,
  40555: 30460,
  40574: 30460,
  40575: 30460,
  40576: 30460,
  40577: 30460,
  40578: 30460,
  40579: 30460,
  40580: 30460,
  40581: 30460,
  40582: 30460,
  40583: 30460,
  40588: 30460,
  40591: 30460,
  40601: 30460,
  40602: 23180,
  40604: 23180,
  40618: 23180,
  40619: 23180,
  40620: 23180,
  40621: 23180,
  40701: 30940,
  40724: 30940,
  40729: 99999,
  40730: 30940,
  40734: 30940,
  40737: 30940,
  40740: 30940,
  40741: 30940,
  40744: 30940,
  40750: 30940,
  40755: 30940,
  40759: 30940,
  40763: 30940,
  40769: 99999,
  40771: 30940,
  40801: 99999,
  40803: 99999,
  40806: 99999,
  40807: 99999,
  40808: 99999,
  40810: 99999,
  40813: 33180,
  40815: 99999,
  40816: 99999,
  40818: 99999,
  40819: 99999,
  40820: 99999,
  40823: 99999,
  40824: 99999,
  40826: 99999,
  40827: 99999,
  40828: 99999,
  40829: 99999,
  40830: 99999,
  40831: 99999,
  40840: 99999,
  40843: 99999,
  40844: 99999,
  40845: 33180,
  40847: 99999,
  40849: 99999,
  40854: 99999,
  40855: 99999,
  40856: 33180,
  40858: 99999,
  40862: 99999,
  40863: 99999,
  40865: 99999,
  40868: 99999,
  40870: 99999,
  40873: 99999,
  40874: 99999,
  40902: 33180,
  40903: 30940,
  40906: 30940,
  40913: 33180,
  40914: 99999,
  40915: 30940,
  40921: 30940,
  40923: 30940,
  40927: 99999,
  40930: 33180,
  40932: 30940,
  40935: 30940,
  40939: 33180,
  40940: 33180,
  40941: 30940,
  40943: 30940,
  40944: 30940,
  40946: 30940,
  40949: 30940,
  40951: 30940,
  40953: 30940,
  40955: 33180,
  40958: 33180,
  40962: 30940,
  40964: 99999,
  40965: 33180,
  40972: 99999,
  40977: 33180,
  40979: 99999,
  40982: 30940,
  40983: 30940,
  40988: 33180,
  40995: 30940,
  40997: 30940,
  41001: 17140,
  41002: 17140,
  41003: 99999,
  41004: 17140,
  41005: 17140,
  41006: 17140,
  41007: 17140,
  41008: 99999,
  41010: 30460,
  41011: 17140,
  41012: 17140,
  41014: 17140,
  41015: 17140,
  41016: 17140,
  41017: 17140,
  41018: 17140,
  41022: 17140,
  41025: 17140,
  41030: 17140,
  41031: 30460,
  41033: 17140,
  41034: 32500,
  41035: 99999,
  41037: 99999,
  41039: 99999,
  41040: 99999,
  41041: 99999,
  41042: 17140,
  41043: 17140,
  41044: 17140,
  41045: 17140,
  41046: 99999,
  41048: 17140,
  41049: 99999,
  41051: 17140,
  41052: 17140,
  41053: 17140,
  41055: 99999,
  41056: 99999,
  41059: 17140,
  41061: 17140,
  41062: 32500,
  41063: 17140,
  41064: 17140,
  41071: 17140,
  41072: 17140,
  41073: 17140,
  41074: 17140,
  41075: 17140,
  41076: 17140,
  41080: 17140,
  41081: 99999,
  41083: 17140,
  41085: 17140,
  41086: 17140,
  41091: 17140,
  41092: 17140,
  41093: 99999,
  41094: 17140,
  41095: 17140,
  41096: 32500,
  41097: 17140,
  41098: 99999,
  41099: 17140,
  41101: 26580,
  41102: 26580,
  41105: 26580,
  41121: 26580,
  41124: 99999,
  41129: 99999,
  41132: 99999,
  41135: 99999,
  41139: 26580,
  41141: 99999,
  41142: 26580,
  41143: 26580,
  41144: 26580,
  41146: 26580,
  41149: 99999,
  41159: 99999,
  41160: 99999,
  41164: 26580,
  41166: 99999,
  41168: 26580,
  41169: 26580,
  41171: 99999,
  41173: 26580,
  41174: 26580,
  41175: 99999,
  41179: 32500,
  41180: 26580,
  41181: 99999,
  41183: 26580,
  41189: 99999,
  41201: 99999,
  41203: 99999,
  41204: 99999,
  41214: 99999,
  41216: 99999,
  41219: 99999,
  41222: 99999,
  41224: 99999,
  41226: 99999,
  41230: 99999,
  41231: 99999,
  41232: 99999,
  41234: 99999,
  41238: 99999,
  41240: 99999,
  41250: 99999,
  41254: 99999,
  41255: 99999,
  41256: 99999,
  41257: 99999,
  41260: 99999,
  41262: 99999,
  41263: 99999,
  41264: 99999,
  41265: 99999,
  41267: 99999,
  41268: 99999,
  41271: 99999,
  41274: 99999,
  41301: 99999,
  41310: 99999,
  41311: 99999,
  41314: 99999,
  41317: 99999,
  41332: 99999,
  41339: 99999,
  41347: 99999,
  41348: 99999,
  41351: 99999,
  41352: 99999,
  41360: 99999,
  41364: 99999,
  41365: 99999,
  41366: 99999,
  41367: 99999,
  41368: 99999,
  41385: 99999,
  41386: 99999,
  41390: 99999,
  41397: 99999,
  41408: 99999,
  41413: 99999,
  41421: 99999,
  41425: 34460,
  41426: 99999,
  41451: 99999,
  41464: 99999,
  41465: 99999,
  41472: 99999,
  41501: 99999,
  41502: 99999,
  41503: 99999,
  41512: 99999,
  41513: 99999,
  41514: 99999,
  41517: 99999,
  41519: 99999,
  41520: 99999,
  41522: 99999,
  41524: 99999,
  41526: 99999,
  41527: 99999,
  41528: 99999,
  41531: 99999,
  41534: 99999,
  41535: 99999,
  41537: 99999,
  41538: 99999,
  41539: 99999,
  41540: 99999,
  41542: 99999,
  41543: 99999,
  41544: 99999,
  41547: 99999,
  41548: 99999,
  41549: 99999,
  41553: 99999,
  41554: 99999,
  41555: 99999,
  41557: 99999,
  41558: 99999,
  41559: 99999,
  41560: 99999,
  41561: 99999,
  41562: 99999,
  41563: 99999,
  41564: 99999,
  41566: 99999,
  41567: 99999,
  41568: 99999,
  41571: 99999,
  41572: 99999,
  41601: 99999,
  41602: 99999,
  41603: 99999,
  41604: 99999,
  41605: 99999,
  41606: 99999,
  41607: 99999,
  41612: 99999,
  41615: 99999,
  41616: 99999,
  41619: 99999,
  41621: 99999,
  41622: 99999,
  41630: 99999,
  41631: 99999,
  41632: 99999,
  41635: 99999,
  41636: 99999,
  41640: 99999,
  41642: 99999,
  41643: 99999,
  41645: 99999,
  41647: 99999,
  41649: 99999,
  41650: 99999,
  41651: 99999,
  41653: 99999,
  41655: 99999,
  41659: 99999,
  41660: 99999,
  41663: 99999,
  41666: 99999,
  41667: 99999,
  41669: 99999,
  41701: 99999,
  41712: 99999,
  41713: 99999,
  41714: 99999,
  41719: 99999,
  41721: 99999,
  41722: 99999,
  41723: 99999,
  41725: 99999,
  41727: 99999,
  41729: 99999,
  41731: 99999,
  41735: 99999,
  41736: 99999,
  41739: 99999,
  41740: 99999,
  41743: 99999,
  41745: 99999,
  41746: 99999,
  41749: 99999,
  41751: 99999,
  41754: 99999,
  41759: 99999,
  41760: 99999,
  41762: 99999,
  41763: 99999,
  41764: 99999,
  41766: 99999,
  41772: 99999,
  41773: 99999,
  41774: 99999,
  41775: 99999,
  41776: 99999,
  41777: 99999,
  41778: 99999,
  41804: 99999,
  41810: 99999,
  41812: 99999,
  41815: 99999,
  41817: 99999,
  41819: 99999,
  41821: 99999,
  41822: 99999,
  41824: 99999,
  41825: 99999,
  41826: 99999,
  41828: 99999,
  41831: 99999,
  41832: 99999,
  41833: 99999,
  41834: 99999,
  41835: 99999,
  41836: 99999,
  41837: 99999,
  41838: 99999,
  41839: 99999,
  41840: 99999,
  41843: 99999,
  41844: 99999,
  41845: 99999,
  41847: 99999,
  41848: 99999,
  41849: 99999,
  41855: 99999,
  41858: 99999,
  41859: 99999,
  41861: 99999,
  41862: 99999,
  42001: 37140,
  42002: 37140,
  42003: 32460,
  42020: 34660,
  42021: 99999,
  42022: 37140,
  42023: 99999,
  42024: 37140,
  42025: 34660,
  42027: 32460,
  42028: 37140,
  42029: 37140,
  42031: 99999,
  42032: 99999,
  42033: 99999,
  42035: 99999,
  42036: 34660,
  42037: 99999,
  42038: 17300,
  42039: 99999,
  42040: 32460,
  42041: 99999,
  42044: 99999,
  42045: 99999,
  42047: 37140,
  42048: 34660,
  42049: 32460,
  42050: 99999,
  42051: 32460,
  42053: 37140,
  42054: 32460,
  42055: 99999,
  42056: 37140,
  42058: 37140,
  42060: 37140,
  42061: 32460,
  42063: 32460,
  42064: 99999,
  42066: 99999,
  42069: 99999,
  42071: 32460,
  42076: 34660,
  42078: 37140,
  42079: 32460,
  42081: 37140,
  42082: 37140,
  42083: 37140,
  42085: 32460,
  42086: 37140,
  42087: 37140,
  42088: 99999,
  42101: 14540,
  42102: 14540,
  42103: 14540,
  42104: 99999,
  42120: 14540,
  42122: 14540,
  42123: 23980,
  42124: 23980,
  42127: 23980,
  42129: 99999,
  42130: 23980,
  42131: 23980,
  42133: 23980,
  42134: 14540,
  42140: 99999,
  42141: 23980,
  42151: 99999,
  42152: 23980,
  42153: 14540,
  42154: 23980,
  42156: 23980,
  42157: 23980,
  42159: 14540,
  42160: 14540,
  42163: 14540,
  42164: 14540,
  42166: 99999,
  42167: 99999,
  42170: 14540,
  42171: 14540,
  42201: 14540,
  42202: 99999,
  42204: 99999,
  42206: 99999,
  42207: 99999,
  42210: 99999,
  42211: 99999,
  42214: 15820,
  42215: 17300,
  42216: 99999,
  42217: 17300,
  42219: 14540,
  42220: 16420,
  42221: 99999,
  42223: 17300,
  42232: 17300,
  42234: 99999,
  42236: 17300,
  42240: 99999,
  42241: 17300,
  42252: 14540,
  42254: 17300,
  42256: 14540,
  42259: 14540,
  42261: 14540,
  42262: 17300,
  42265: 99999,
  42266: 99999,
  42273: 14540,
  42274: 99999,
  42275: 14540,
  42276: 99999,
  42280: 99999,
  42285: 14540,
  42286: 17300,
  42288: 14540,
  42301: 21780,
  42302: 36980,
  42303: 36980,
  42304: 36980,
  42320: 99999,
  42321: 16420,
  42322: 36980,
  42323: 16420,
  42324: 16420,
  42325: 16420,
  42326: 16420,
  42327: 36980,
  42328: 99999,
  42330: 36980,
  42332: 16420,
  42333: 14540,
  42337: 16420,
  42338: 99999,
  42339: 99999,
  42343: 99999,
  42344: 16420,
  42345: 16420,
  42347: 99999,
  42348: 36980,
  42349: 99999,
  42350: 36980,
  42351: 36980,
  42352: 36980,
  42354: 99999,
  42355: 36980,
  42356: 36980,
  42361: 99999,
  42364: 36980,
  42366: 36980,
  42367: 16420,
  42368: 99999,
  42369: 99999,
  42370: 99999,
  42371: 36980,
  42372: 36980,
  42374: 16420,
  42376: 36980,
  42378: 99999,
  42402: 21780,
  42404: 99999,
  42406: 99999,
  42408: 31580,
  42409: 99999,
  42410: 31580,
  42411: 99999,
  42413: 31580,
  42419: 21780,
  42420: 21780,
  42431: 31580,
  42436: 31580,
  42437: 99999,
  42440: 31580,
  42441: 31580,
  42442: 17300,
  42444: 99999,
  42445: 17300,
  42450: 99999,
  42451: 21780,
  42452: 21780,
  42453: 17300,
  42455: 99999,
  42456: 31580,
  42457: 21780,
  42458: 21780,
  42459: 99999,
  42460: 99999,
  42461: 99999,
  42462: 99999,
  42463: 99999,
  42464: 17300,
  42501: 43700,
  42502: 43700,
  42503: 43700,
  42516: 43700,
  42518: 43700,
  42519: 43700,
  42528: 99999,
  42533: 43700,
  42539: 99999,
  42541: 99999,
  42544: 43700,
  42553: 99999,
  42558: 43700,
  42564: 43700,
  42565: 99999,
  42566: 99999,
  42567: 19220,
  42602: 99999,
  42603: 99999,
  42629: 99999,
  42631: 99999,
  42633: 99999,
  42634: 99999,
  42635: 99999,
  42638: 99999,
  42642: 43700,
  42647: 99999,
  42649: 99999,
  42653: 99999,
  42701: 21060,
  42702: 21060,
  42712: 99999,
  42713: 99999,
  42715: 23980,
  42716: 15820,
  42717: 23980,
  42718: 99999,
  42720: 99999,
  42721: 99999,
  42722: 15820,
  42724: 21060,
  42726: 99999,
  42728: 99999,
  42729: 99999,
  42732: 21060,
  42733: 99999,
  42740: 21060,
  42741: 99999,
  42742: 99999,
  42743: 15820,
  42746: 23980,
  42748: 21060,
  42749: 99999,
  42753: 99999,
  42754: 99999,
  42757: 15820,
  42758: 15820,
  42759: 99999,
  42762: 99999,
  42764: 15820,
  42765: 99999,
  42776: 21060,
  42782: 99999,
  42784: 21060,
  42788: 21060,
  43001: 18140,
  43002: 18140,
  43003: 18140,
  43004: 18140,
  43005: 34540,
  43006: 18740,
  43007: 18140,
  43008: 18140,
  43009: 46500,
  43010: 44220,
  43011: 18140,
  43013: 18140,
  43014: 34540,
  43015: 18140,
  43016: 18140,
  43017: 18140,
  43018: 18140,
  43019: 18140,
  43021: 18140,
  43022: 34540,
  43023: 18140,
  43025: 18140,
  43026: 18140,
  43027: 18140,
  43028: 34540,
  43029: 18140,
  43030: 18140,
  43031: 18140,
  43033: 18140,
  43035: 18140,
  43036: 18140,
  43037: 34540,
  43040: 18140,
  43044: 46500,
  43045: 46500,
  43046: 18140,
  43047: 46500,
  43048: 34540,
  43050: 18140,
  43054: 18140,
  43055: 18140,
  43056: 49780,
  43058: 18140,
  43060: 18140,
  43061: 18140,
  43062: 18140,
  43064: 18140,
  43065: 18140,
  43066: 18140,
  43067: 13340,
  43068: 18140,
  43069: 18140,
  43070: 46500,
  43071: 18140,
  43072: 46500,
  43073: 18140,
  43074: 18140,
  43076: 18140,
  43077: 18140,
  43078: 44220,
  43080: 34540,
  43081: 18140,
  43082: 18140,
  43083: 46500,
  43084: 18140,
  43085: 18140,
  43086: 18140,
  43101: 18140,
  43102: 18140,
  43103: 18140,
  43105: 18140,
  43106: 47920,
  43107: 18140,
  43109: 18140,
  43110: 18140,
  43111: 18140,
  43112: 18140,
  43113: 18140,
  43115: 18140,
  43116: 18140,
  43117: 18140,
  43119: 18140,
  43123: 18140,
  43125: 18140,
  43126: 18140,
  43127: 18140,
  43128: 47920,
  43130: 18140,
  43135: 17060,
  43136: 18140,
  43137: 18140,
  43138: 18140,
  43140: 18140,
  43142: 47920,
  43143: 47920,
  43144: 18140,
  43145: 18140,
  43146: 18140,
  43147: 18140,
  43148: 18140,
  43149: 18140,
  43150: 18140,
  43151: 18140,
  43152: 99999,
  43153: 18140,
  43154: 18140,
  43155: 18140,
  43156: 18140,
  43157: 18140,
  43158: 18140,
  43160: 47920,
  43162: 18140,
  43164: 17060,
  43194: 18140,
  43195: 18140,
  43201: 18140,
  43202: 18140,
  43203: 18140,
  43204: 18140,
  43205: 18140,
  43206: 18140,
  43207: 18140,
  43209: 18140,
  43210: 18140,
  43211: 18140,
  43212: 18140,
  43213: 18140,
  43214: 18140,
  43215: 18140,
  43216: 18140,
  43217: 18140,
  43218: 18140,
  43219: 18140,
  43220: 18140,
  43221: 18140,
  43222: 18140,
  43223: 18140,
  43224: 18140,
  43226: 18140,
  43227: 18140,
  43228: 18140,
  43229: 18140,
  43230: 18140,
  43231: 18140,
  43232: 18140,
  43234: 18140,
  43235: 18140,
  43236: 18140,
  43240: 18140,
  43251: 18140,
  43287: 18140,
  43291: 18140,
  43302: 18140,
  43310: 13340,
  43311: 13340,
  43314: 18140,
  43315: 18140,
  43316: 99999,
  43317: 18140,
  43318: 46500,
  43319: 18140,
  43320: 18140,
  43321: 18140,
  43322: 32020,
  43323: 99999,
  43324: 13340,
  43325: 18140,
  43326: 32020,
  43330: 99999,
  43331: 99999,
  43332: 99999,
  43333: 13340,
  43334: 18140,
  43336: 13340,
  43337: 99999,
  43338: 18140,
  43340: 99999,
  43341: 32020,
  43342: 18140,
  43343: 43380,
  43344: 18140,
  43345: 18140,
  43346: 99999,
  43347: 13340,
  43348: 13340,
  43349: 18140,
  43350: 18140,
  43351: 99999,
  43356: 32020,
  43357: 46500,
  43358: 18140,
  43359: 99999,
  43360: 13340,
  43402: 45780,
  43403: 45780,
  43406: 23380,
  43407: 23380,
  43408: 45780,
  43410: 23380,
  43412: 45780,
  43413: 45780,
  43414: 45780,
  43416: 23380,
  43420: 45780,
  43430: 45780,
  43431: 23380,
  43432: 45780,
  43433: 45780,
  43434: 45780,
  43435: 23380,
  43436: 45780,
  43437: 45780,
  43438: 41780,
  43439: 45780,
  43440: 45780,
  43441: 45780,
  43442: 23380,
  43443: 45780,
  43445: 45780,
  43446: 45780,
  43447: 45780,
  43449: 45780,
  43450: 45780,
  43451: 45780,
  43452: 45780,
  43456: 45780,
  43457: 45660,
  43458: 45780,
  43460: 45780,
  43462: 45780,
  43463: 45780,
  43464: 23380,
  43465: 45780,
  43466: 45780,
  43467: 45780,
  43468: 45780,
  43469: 23380,
  43501: 99999,
  43502: 45780,
  43504: 45780,
  43505: 99999,
  43506: 99999,
  43510: 99999,
  43511: 45780,
  43512: 19580,
  43515: 99999,
  43516: 99999,
  43517: 99999,
  43518: 99999,
  43519: 19580,
  43520: 19580,
  43521: 45780,
  43522: 99999,
  43523: 99999,
  43524: 99999,
  43525: 45780,
  43526: 99999,
  43527: 19580,
  43528: 45780,
  43529: 45780,
  43530: 19580,
  43531: 99999,
  43532: 45780,
  43533: 45780,
  43534: 99999,
  43535: 99999,
  43536: 19580,
  43537: 45780,
  43540: 45780,
  43541: 45780,
  43542: 45780,
  43543: 99999,
  43545: 99999,
  43547: 45780,
  43548: 99999,
  43549: 19580,
  43551: 45780,
  43552: 45780,
  43553: 45780,
  43554: 99999,
  43555: 99999,
  43556: 99999,
  43557: 99999,
  43558: 45780,
  43560: 45780,
  43565: 45780,
  43566: 45780,
  43567: 99999,
  43569: 45780,
  43570: 99999,
  43571: 45780,
  43601: 45780,
  43604: 45780,
  43605: 45780,
  43606: 45780,
  43607: 45780,
  43608: 45780,
  43609: 45780,
  43610: 45780,
  43611: 45780,
  43612: 45780,
  43613: 45780,
  43614: 45780,
  43615: 45780,
  43616: 45780,
  43617: 45780,
  43619: 45780,
  43620: 45780,
  43623: 45780,
  43635: 45780,
  43652: 45780,
  43654: 45780,
  43656: 45780,
  43657: 45780,
  43659: 45780,
  43660: 45780,
  43661: 45780,
  43666: 45780,
  43667: 45780,
  43701: 18140,
  43702: 49780,
  43711: 99999,
  43713: 99999,
  43716: 99999,
  43717: 99999,
  43718: 48540,
  43719: 48540,
  43720: 99999,
  43721: 18140,
  43722: 15740,
  43723: 15740,
  43724: 99999,
  43725: 15740,
  43727: 49780,
  43728: 11900,
  43730: 18140,
  43731: 99999,
  43732: 99999,
  43733: 15740,
  43734: 49780,
  43735: 49780,
  43736: 15740,
  43738: 49780,
  43739: 18140,
  43740: 18140,
  43746: 49780,
  43747: 99999,
  43748: 18140,
  43749: 35420,
  43750: 15740,
  43752: 99999,
  43754: 99999,
  43755: 15740,
  43756: 49780,
  43757: 99999,
  43758: 99999,
  43759: 48540,
  43760: 49780,
  43761: 18140,
  43762: 49780,
  43764: 18140,
  43766: 18140,
  43767: 49780,
  43768: 15740,
  43771: 49780,
  43772: 99999,
  43773: 48540,
  43777: 99999,
  43778: 15740,
  43779: 99999,
  43780: 15740,
  43782: 18140,
  43783: 18140,
  43786: 99999,
  43787: 31930,
  43788: 99999,
  43791: 49780,
  43793: 99999,
  43802: 49780,
  43803: 18740,
  43804: 99999,
  43805: 18740,
  43811: 18740,
  43812: 18740,
  43821: 18740,
  43822: 18140,
  43824: 18740,
  43828: 18740,
  43830: 18140,
  43832: 35420,
  43836: 18740,
  43837: 35420,
  43840: 35420,
  43842: 49780,
  43843: 34540,
  43844: 34540,
  43845: 18740,
  43901: 48260,
  43902: 48540,
  43903: 15940,
  43905: 48540,
  43906: 48540,
  43907: 99999,
  43908: 15940,
  43909: 48540,
  43910: 99999,
  43912: 48540,
  43913: 48260,
  43914: 99999,
  43915: 99999,
  43916: 48540,
  43917: 48260,
  43920: 41400,
  43925: 48260,
  43926: 48260,
  43927: 48540,
  43928: 48540,
  43930: 48260,
  43931: 99999,
  43932: 48260,
  43933: 48540,
  43934: 48540,
  43935: 48540,
  43937: 48540,
  43938: 48260,
  43939: 48260,
  43940: 48540,
  43941: 48260,
  43942: 48540,
  43943: 48260,
  43944: 48260,
  43945: 48260,
  43946: 99999,
  43947: 48540,
  43948: 48260,
  43950: 48540,
  43951: 48540,
  43952: 48260,
  43953: 48260,
  43961: 48260,
  43962: 41400,
  43963: 48260,
  43964: 48260,
  43967: 48540,
  43968: 41400,
  43970: 48260,
  43971: 48540,
  43972: 48540,
  43973: 35420,
  43974: 99999,
  43976: 99999,
  43977: 99999,
  43981: 99999,
  43983: 48540,
  43984: 99999,
  43985: 48540,
  43986: 48260,
  43988: 15940,
  44001: 17460,
  44003: 11780,
  44004: 11780,
  44010: 11780,
  44011: 17460,
  44012: 17460,
  44017: 17460,
  44021: 17460,
  44022: 17460,
  44023: 17460,
  44024: 17460,
  44026: 17460,
  44028: 17460,
  44030: 11780,
  44032: 11780,
  44033: 17460,
  44035: 17460,
  44036: 17460,
  44039: 17460,
  44040: 17460,
  44041: 11780,
  44044: 17460,
  44045: 17460,
  44046: 17460,
  44047: 11780,
  44048: 11780,
  44049: 17460,
  44050: 17460,
  44052: 17460,
  44053: 17460,
  44054: 17460,
  44055: 17460,
  44056: 10420,
  44057: 17460,
  44060: 17460,
  44061: 17460,
  44062: 49660,
  44064: 11780,
  44065: 17460,
  44067: 10420,
  44068: 11780,
  44070: 17460,
  44072: 17460,
  44073: 17460,
  44074: 17460,
  44076: 49660,
  44077: 17460,
  44080: 17460,
  44081: 17460,
  44082: 11780,
  44084: 11780,
  44085: 11780,
  44086: 11780,
  44087: 10420,
  44088: 11780,
  44089: 41780,
  44090: 17460,
  44092: 17460,
  44093: 11780,
  44094: 17460,
  44095: 17460,
  44096: 17460,
  44099: 11780,
  44101: 17460,
  44102: 17460,
  44103: 17460,
  44104: 17460,
  44105: 17460,
  44106: 17460,
  44107: 17460,
  44108: 17460,
  44109: 17460,
  44110: 17460,
  44111: 17460,
  44112: 17460,
  44113: 17460,
  44114: 17460,
  44115: 17460,
  44116: 17460,
  44117: 17460,
  44118: 17460,
  44119: 17460,
  44120: 17460,
  44121: 17460,
  44122: 17460,
  44123: 17460,
  44124: 17460,
  44125: 17460,
  44126: 17460,
  44127: 17460,
  44128: 17460,
  44129: 17460,
  44130: 17460,
  44131: 17460,
  44132: 17460,
  44133: 17460,
  44134: 17460,
  44135: 17460,
  44136: 17460,
  44137: 17460,
  44138: 17460,
  44139: 17460,
  44140: 17460,
  44141: 17460,
  44142: 17460,
  44143: 17460,
  44144: 17460,
  44145: 17460,
  44146: 17460,
  44147: 17460,
  44149: 17460,
  44181: 17460,
  44195: 17460,
  44199: 17460,
  44201: 15940,
  44202: 17460,
  44203: 17460,
  44210: 10420,
  44211: 10420,
  44212: 17460,
  44214: 49300,
  44215: 17460,
  44216: 10420,
  44217: 49300,
  44221: 10420,
  44222: 10420,
  44223: 10420,
  44224: 10420,
  44230: 49300,
  44231: 17460,
  44232: 10420,
  44233: 17460,
  44234: 10420,
  44235: 17460,
  44236: 10420,
  44237: 10420,
  44240: 10420,
  44241: 10420,
  44242: 10420,
  44243: 10420,
  44250: 10420,
  44251: 17460,
  44253: 17460,
  44254: 17460,
  44255: 10420,
  44256: 17460,
  44258: 17460,
  44260: 15940,
  44262: 10420,
  44264: 10420,
  44265: 10420,
  44266: 10420,
  44270: 17460,
  44272: 10420,
  44273: 49300,
  44274: 17460,
  44275: 17460,
  44276: 49300,
  44278: 10420,
  44280: 17460,
  44281: 49300,
  44282: 17460,
  44285: 10420,
  44286: 10420,
  44287: 11740,
  44288: 49660,
  44301: 10420,
  44302: 10420,
  44303: 10420,
  44304: 10420,
  44305: 10420,
  44306: 10420,
  44307: 10420,
  44308: 10420,
  44309: 10420,
  44310: 10420,
  44311: 10420,
  44312: 10420,
  44313: 10420,
  44314: 10420,
  44315: 10420,
  44316: 10420,
  44317: 10420,
  44319: 10420,
  44320: 10420,
  44321: 17460,
  44325: 10420,
  44326: 10420,
  44328: 10420,
  44333: 17460,
  44334: 10420,
  44396: 10420,
  44401: 49660,
  44402: 49660,
  44403: 49660,
  44404: 49660,
  44405: 49660,
  44406: 49660,
  44408: 41400,
  44410: 49660,
  44411: 10420,
  44412: 49660,
  44413: 41400,
  44415: 41400,
  44416: 49660,
  44417: 49660,
  44418: 49660,
  44420: 49660,
  44422: 49660,
  44423: 41400,
  44424: 49660,
  44425: 49660,
  44427: 41400,
  44428: 11780,
  44429: 10420,
  44430: 49660,
  44431: 41400,
  44432: 41400,
  44436: 49660,
  44437: 49660,
  44438: 49660,
  44439: 49660,
  44440: 49660,
  44441: 41400,
  44442: 49660,
  44443: 41400,
  44444: 49660,
  44445: 41400,
  44446: 49660,
  44449: 10420,
  44450: 49660,
  44451: 49660,
  44452: 49660,
  44453: 49660,
  44454: 49660,
  44455: 41400,
  44460: 41400,
  44470: 10420,
  44471: 49660,
  44473: 49660,
  44481: 49660,
  44483: 49660,
  44484: 49660,
  44485: 49660,
  44490: 49660,
  44491: 49660,
  44492: 41400,
  44493: 41400,
  44501: 49660,
  44502: 49660,
  44503: 49660,
  44504: 49660,
  44505: 49660,
  44506: 49660,
  44507: 49660,
  44509: 49660,
  44510: 49660,
  44511: 49660,
  44512: 49660,
  44513: 49660,
  44514: 49660,
  44515: 49660,
  44555: 49660,
  44601: 49660,
  44606: 49300,
  44607: 15940,
  44608: 15940,
  44609: 49660,
  44610: 99999,
  44611: 99999,
  44612: 15940,
  44613: 15940,
  44614: 15940,
  44615: 15940,
  44617: 99999,
  44618: 15940,
  44619: 41400,
  44620: 15940,
  44621: 35420,
  44622: 35420,
  44624: 15940,
  44625: 41400,
  44626: 35420,
  44627: 99999,
  44628: 99999,
  44629: 35420,
  44630: 15940,
  44632: 10420,
  44633: 99999,
  44634: 41400,
  44636: 49300,
  44637: 18740,
  44638: 49300,
  44639: 15940,
  44640: 15940,
  44641: 15940,
  44643: 35420,
  44644: 15940,
  44645: 10420,
  44646: 15940,
  44647: 15940,
  44648: 15940,
  44650: 15940,
  44651: 15940,
  44652: 15940,
  44653: 35420,
  44654: 99999,
  44656: 15940,
  44657: 41400,
  44659: 49300,
  44660: 99999,
  44661: 99999,
  44662: 15940,
  44663: 35420,
  44665: 41400,
  44666: 49300,
  44667: 49300,
  44669: 15940,
  44670: 15940,
  44671: 35420,
  44672: 49660,
  44675: 15940,
  44676: 99999,
  44677: 49300,
  44678: 35420,
  44679: 35420,
  44680: 15940,
  44681: 35420,
  44682: 35420,
  44683: 35420,
  44685: 10420,
  44687: 99999,
  44688: 15940,
  44689: 15940,
  44690: 99999,
  44691: 11740,
  44693: 99999,
  44695: 15940,
  44697: 35420,
  44699: 99999,
  44702: 15940,
  44703: 15940,
  44704: 15940,
  44705: 15940,
  44706: 15940,
  44707: 15940,
  44708: 15940,
  44709: 15940,
  44710: 15940,
  44711: 15940,
  44714: 15940,
  44718: 15940,
  44720: 10420,
  44721: 15940,
  44730: 15940,
  44735: 15940,
  44750: 15940,
  44767: 15940,
  44799: 15940,
  44802: 45660,
  44804: 22300,
  44805: 11740,
  44807: 45660,
  44809: 45660,
  44811: 41780,
  44813: 18140,
  44814: 41780,
  44815: 45660,
  44816: 41780,
  44817: 45780,
  44818: 15340,
  44820: 99999,
  44822: 11740,
  44824: 41780,
  44825: 15340,
  44826: 41780,
  44827: 15340,
  44828: 45660,
  44830: 45780,
  44833: 15340,
  44836: 23380,
  44837: 35940,
  44838: 11740,
  44839: 41780,
  44840: 49300,
  44841: 23380,
  44842: 34540,
  44843: 11740,
  44844: 45660,
  44845: 45660,
  44846: 41780,
  44847: 35940,
  44848: 11740,
  44849: 15340,
  44850: 35940,
  44851: 17460,
  44853: 45660,
  44854: 45660,
  44855: 35940,
  44856: 15340,
  44857: 35940,
  44859: 17460,
  44860: 15340,
  44861: 45660,
  44862: 31900,
  44864: 11740,
  44865: 31900,
  44866: 11740,
  44867: 45660,
  44870: 41780,
  44871: 41780,
  44874: 11740,
  44875: 15340,
  44878: 35940,
  44880: 11740,
  44881: 15340,
  44882: 45660,
  44883: 45660,
  44887: 15340,
  44888: 35940,
  44889: 17460,
  44890: 35940,
  44901: 31900,
  44902: 31900,
  44903: 11740,
  44904: 18140,
  44905: 31900,
  44906: 31900,
  44907: 31900,
  45001: 17140,
  45002: 17140,
  45003: 17140,
  45004: 17140,
  45005: 19430,
  45011: 17140,
  45012: 17140,
  45013: 17140,
  45014: 17140,
  45015: 17140,
  45018: 17140,
  45030: 17140,
  45032: 17140,
  45033: 17140,
  45034: 17140,
  45036: 17140,
  45039: 17140,
  45040: 17140,
  45041: 17140,
  45042: 19430,
  45044: 17140,
  45050: 17140,
  45051: 17140,
  45052: 17140,
  45053: 17140,
  45054: 17140,
  45055: 17140,
  45056: 99999,
  45061: 17140,
  45062: 17140,
  45063: 17140,
  45064: 99999,
  45065: 17140,
  45066: 19430,
  45067: 17140,
  45068: 48940,
  45069: 17140,
  45070: 99999,
  45071: 17140,
  45101: 99999,
  45102: 17140,
  45103: 17140,
  45105: 99999,
  45106: 17140,
  45107: 17140,
  45111: 17140,
  45112: 17140,
  45113: 17140,
  45115: 17140,
  45118: 99999,
  45119: 17140,
  45120: 17140,
  45121: 17140,
  45122: 17140,
  45123: 17060,
  45130: 17140,
  45131: 17140,
  45132: 99999,
  45133: 99999,
  45135: 47920,
  45140: 17140,
  45142: 48940,
  45144: 17140,
  45146: 48940,
  45147: 17140,
  45148: 48940,
  45150: 17140,
  45152: 17140,
  45153: 17140,
  45154: 99999,
  45155: 99999,
  45156: 17140,
  45157: 17140,
  45158: 17140,
  45159: 99999,
  45160: 17140,
  45162: 17140,
  45164: 48940,
  45166: 48940,
  45167: 17140,
  45168: 17140,
  45169: 19430,
  45171: 17140,
  45172: 99999,
  45174: 17140,
  45176: 17140,
  45177: 48940,
  45201: 17140,
  45202: 17140,
  45203: 17140,
  45204: 17140,
  45205: 17140,
  45206: 17140,
  45207: 17140,
  45208: 17140,
  45209: 17140,
  45211: 17140,
  45212: 17140,
  45213: 17140,
  45214: 17140,
  45215: 17140,
  45216: 17140,
  45217: 17140,
  45218: 17140,
  45219: 17140,
  45220: 17140,
  45222: 17140,
  45223: 17140,
  45224: 17140,
  45225: 17140,
  45226: 17140,
  45227: 17140,
  45229: 17140,
  45230: 17140,
  45231: 17140,
  45232: 17140,
  45233: 17140,
  45234: 17140,
  45235: 17140,
  45236: 17140,
  45237: 17140,
  45238: 17140,
  45239: 17140,
  45240: 17140,
  45241: 17140,
  45242: 17140,
  45243: 17140,
  45244: 17140,
  45245: 17140,
  45246: 17140,
  45247: 17140,
  45248: 17140,
  45249: 17140,
  45250: 17140,
  45251: 17140,
  45252: 17140,
  45253: 17140,
  45254: 17140,
  45255: 17140,
  45258: 17140,
  45262: 17140,
  45263: 17140,
  45267: 17140,
  45275: 17140,
  45301: 19430,
  45302: 43380,
  45303: 24820,
  45304: 99999,
  45305: 19430,
  45306: 47540,
  45307: 19430,
  45308: 24820,
  45309: 99999,
  45310: 24820,
  45311: 99999,
  45312: 44220,
  45314: 44220,
  45315: 19430,
  45316: 19430,
  45317: 19430,
  45318: 24820,
  45319: 44220,
  45320: 99999,
  45321: 99999,
  45322: 19430,
  45323: 44220,
  45324: 44220,
  45325: 19430,
  45326: 19430,
  45327: 99999,
  45328: 24820,
  45330: 99999,
  45331: 24820,
  45332: 24820,
  45333: 43380,
  45334: 47540,
  45335: 48940,
  45336: 43380,
  45337: 24820,
  45338: 99999,
  45339: 19430,
  45340: 43380,
  45341: 44220,
  45342: 19430,
  45343: 19430,
  45344: 46500,
  45345: 19430,
  45346: 24820,
  45347: 99999,
  45348: 16380,
  45349: 44220,
  45350: 24820,
  45351: 24820,
  45352: 24820,
  45353: 43380,
  45354: 19430,
  45356: 19430,
  45358: 24820,
  45359: 19430,
  45360: 43380,
  45361: 19430,
  45362: 24820,
  45363: 43380,
  45365: 19430,
  45367: 43380,
  45368: 19430,
  45369: 44220,
  45370: 19430,
  45371: 19430,
  45372: 44220,
  45373: 19430,
  45374: 19430,
  45377: 19430,
  45378: 19430,
  45380: 43380,
  45381: 19430,
  45382: 24820,
  45383: 19430,
  45384: 19430,
  45385: 19430,
  45387: 44220,
  45388: 24820,
  45389: 46500,
  45390: 24820,
  45401: 19430,
  45402: 19430,
  45403: 19430,
  45404: 19430,
  45405: 19430,
  45406: 19430,
  45409: 19430,
  45410: 19430,
  45413: 19430,
  45414: 19430,
  45415: 19430,
  45416: 19430,
  45417: 19430,
  45419: 19430,
  45420: 19430,
  45422: 19430,
  45423: 19430,
  45424: 19430,
  45426: 19430,
  45428: 19430,
  45429: 19430,
  45430: 19430,
  45431: 19430,
  45432: 19430,
  45433: 19430,
  45434: 19430,
  45435: 19430,
  45437: 19430,
  45439: 19430,
  45440: 19430,
  45441: 19430,
  45448: 19430,
  45449: 19430,
  45458: 17140,
  45459: 19430,
  45469: 19430,
  45470: 19430,
  45475: 19430,
  45481: 19430,
  45490: 19430,
  45501: 44220,
  45502: 46500,
  45503: 44220,
  45504: 44220,
  45505: 44220,
  45506: 44220,
  45601: 27160,
  45612: 17060,
  45613: 39020,
  45614: 38580,
  45616: 39020,
  45617: 17060,
  45618: 99999,
  45619: 26580,
  45620: 99999,
  45621: 27160,
  45622: 18140,
  45623: 38580,
  45624: 99999,
  45628: 17060,
  45629: 39020,
  45630: 39020,
  45631: 38580,
  45633: 17060,
  45634: 27160,
  45636: 39020,
  45638: 39020,
  45640: 27160,
  45642: 99999,
  45643: 38580,
  45644: 17060,
  45645: 26580,
  45646: 99999,
  45647: 17060,
  45648: 39020,
  45650: 99999,
  45651: 99999,
  45652: 39020,
  45653: 27160,
  45654: 18140,
  45656: 26580,
  45657: 39020,
  45658: 26580,
  45659: 39020,
  45660: 99999,
  45661: 99999,
  45662: 39020,
  45663: 39020,
  45669: 26580,
  45671: 39020,
  45672: 99999,
  45673: 17060,
  45674: 38580,
  45675: 26580,
  45677: 39020,
  45678: 26580,
  45679: 99999,
  45680: 26580,
  45681: 17060,
  45682: 39020,
  45684: 99999,
  45685: 38580,
  45686: 38580,
  45687: 99999,
  45688: 38580,
  45690: 99999,
  45692: 99999,
  45693: 99999,
  45694: 39020,
  45695: 99999,
  45696: 26580,
  45697: 17140,
  45698: 99999,
  45699: 39020,
  45701: 11900,
  45710: 11900,
  45711: 99999,
  45712: 31930,
  45713: 31930,
  45714: 31930,
  45715: 99999,
  45716: 11900,
  45719: 11900,
  45720: 99999,
  45721: 31930,
  45723: 99999,
  45724: 31930,
  45727: 31930,
  45729: 31930,
  45732: 99999,
  45734: 99999,
  45735: 99999,
  45739: 11900,
  45740: 11900,
  45741: 99999,
  45742: 11900,
  45743: 99999,
  45744: 31930,
  45745: 99999,
  45746: 99999,
  45750: 31930,
  45760: 38580,
  45761: 11900,
  45764: 18140,
  45766: 99999,
  45767: 31930,
  45768: 31930,
  45769: 99999,
  45770: 99999,
  45771: 99999,
  45772: 99999,
  45773: 31930,
  45775: 99999,
  45776: 99999,
  45778: 11900,
  45779: 99999,
  45780: 11900,
  45782: 11900,
  45783: 99999,
  45784: 31930,
  45786: 31930,
  45787: 31930,
  45788: 31930,
  45789: 99999,
  45801: 30620,
  45802: 30620,
  45804: 30620,
  45805: 30620,
  45806: 30620,
  45807: 99999,
  45808: 30620,
  45809: 30620,
  45810: 22300,
  45812: 30620,
  45813: 99999,
  45814: 22300,
  45815: 99999,
  45816: 22300,
  45817: 22300,
  45819: 47540,
  45820: 30620,
  45821: 99999,
  45822: 16380,
  45826: 16380,
  45827: 99999,
  45828: 16380,
  45830: 99999,
  45831: 99999,
  45832: 99999,
  45833: 99999,
  45835: 99999,
  45836: 99999,
  45837: 99999,
  45838: 46780,
  45839: 22300,
  45840: 22300,
  45841: 99999,
  45843: 32020,
  45844: 46780,
  45845: 16380,
  45846: 16380,
  45848: 99999,
  45849: 46780,
  45850: 47540,
  45851: 99999,
  45853: 99999,
  45854: 30620,
  45855: 99999,
  45856: 22300,
  45858: 45780,
  45859: 99999,
  45860: 16380,
  45861: 99999,
  45862: 47540,
  45863: 46780,
  45864: 99999,
  45865: 16380,
  45866: 16380,
  45867: 22300,
  45868: 22300,
  45869: 16380,
  45870: 47540,
  45871: 47540,
  45872: 22300,
  45873: 99999,
  45874: 46780,
  45875: 99999,
  45876: 99999,
  45877: 99999,
  45879: 99999,
  45880: 99999,
  45881: 22300,
  45882: 16380,
  45883: 16380,
  45884: 47540,
  45885: 47540,
  45886: 99999,
  45887: 47540,
  45888: 47540,
  45889: 22300,
  45890: 22300,
  45891: 46780,
  45893: 99999,
  45894: 30620,
  45895: 30620,
  45896: 99999,
  45897: 22300,
  45898: 46780,
  45899: 46780,
  45999: 17140,
  46001: 34620,
  46011: 26900,
  46012: 26900,
  46013: 26900,
  46015: 26900,
  46016: 26900,
  46017: 34620,
  46018: 26900,
  46030: 26900,
  46031: 99999,
  46032: 26900,
  46033: 26900,
  46034: 26900,
  46035: 18820,
  46036: 31980,
  46037: 26900,
  46038: 26900,
  46039: 23140,
  46040: 26900,
  46041: 26900,
  46044: 26900,
  46045: 99999,
  46047: 99999,
  46048: 26900,
  46049: 26900,
  46050: 99999,
  46051: 26900,
  46052: 26900,
  46055: 26900,
  46056: 26900,
  46057: 23140,
  46058: 23140,
  46060: 26900,
  46061: 26900,
  46062: 26900,
  46063: 26900,
  46064: 26900,
  46065: 29200,
  46067: 23140,
  46068: 29020,
  46069: 23140,
  46070: 31980,
  46071: 23140,
  46072: 99999,
  46074: 26900,
  46075: 26900,
  46076: 99999,
  46077: 26900,
  46082: 26900,
  46102: 26900,
  46103: 26900,
  46104: 99999,
  46105: 26900,
  46106: 26900,
  46107: 26900,
  46110: 26900,
  46111: 26900,
  46112: 26900,
  46113: 26900,
  46115: 26900,
  46117: 26900,
  46118: 26900,
  46120: 26900,
  46121: 26900,
  46122: 26900,
  46123: 26900,
  46124: 18020,
  46125: 26900,
  46126: 26900,
  46127: 99999,
  46128: 26900,
  46130: 26900,
  46131: 26900,
  46133: 99999,
  46135: 26900,
  46140: 99999,
  46142: 26900,
  46143: 26900,
  46144: 26900,
  46146: 99999,
  46147: 26900,
  46148: 35220,
  46149: 26900,
  46150: 99999,
  46151: 26900,
  46154: 26900,
  46155: 99999,
  46156: 99999,
  46157: 26900,
  46158: 26900,
  46160: 26900,
  46161: 99999,
  46162: 26900,
  46163: 26900,
  46164: 26900,
  46165: 26900,
  46166: 26900,
  46167: 26900,
  46168: 26900,
  46170: 26900,
  46171: 26900,
  46172: 26900,
  46173: 24700,
  46175: 45460,
  46176: 99999,
  46180: 26900,
  46181: 26900,
  46182: 24700,
  46183: 26900,
  46184: 26900,
  46186: 35220,
  46201: 26900,
  46202: 26900,
  46203: 26900,
  46204: 26900,
  46205: 26900,
  46206: 26900,
  46208: 26900,
  46213: 26900,
  46214: 26900,
  46216: 26900,
  46217: 26900,
  46218: 26900,
  46219: 26900,
  46220: 26900,
  46221: 26900,
  46222: 26900,
  46224: 26900,
  46225: 26900,
  46226: 26900,
  46227: 26900,
  46228: 26900,
  46229: 26900,
  46230: 26900,
  46231: 26900,
  46234: 26900,
  46235: 26900,
  46236: 26900,
  46237: 26900,
  46239: 26900,
  46240: 26900,
  46241: 26900,
  46242: 26900,
  46244: 26900,
  46247: 26900,
  46249: 26900,
  46250: 26900,
  46251: 26900,
  46253: 26900,
  46254: 26900,
  46256: 26900,
  46259: 26900,
  46260: 26900,
  46268: 26900,
  46278: 26900,
  46280: 26900,
  46282: 26900,
  46283: 26900,
  46285: 26900,
  46290: 26900,
  46298: 26900,
  46301: 16980,
  46302: 16980,
  46303: 16980,
  46304: 16980,
  46307: 16980,
  46308: 16980,
  46310: 16980,
  46311: 16980,
  46312: 16980,
  46319: 16980,
  46320: 16980,
  46321: 16980,
  46322: 16980,
  46323: 16980,
  46324: 16980,
  46325: 16980,
  46327: 16980,
  46340: 33140,
  46341: 16980,
  46342: 16980,
  46345: 33140,
  46346: 33140,
  46347: 16980,
  46348: 33140,
  46349: 16980,
  46350: 33140,
  46355: 16980,
  46356: 16980,
  46360: 33140,
  46365: 33140,
  46366: 99999,
  46368: 16980,
  46371: 33140,
  46372: 16980,
  46373: 16980,
  46374: 16980,
  46375: 16980,
  46376: 16980,
  46377: 16980,
  46379: 16980,
  46380: 16980,
  46381: 16980,
  46382: 33140,
  46383: 16980,
  46384: 16980,
  46385: 16980,
  46390: 33140,
  46391: 33140,
  46392: 16980,
  46393: 16980,
  46394: 16980,
  46401: 16980,
  46402: 16980,
  46403: 16980,
  46404: 16980,
  46405: 16980,
  46406: 16980,
  46407: 16980,
  46408: 16980,
  46409: 16980,
  46410: 16980,
  46411: 16980,
  46501: 38500,
  46502: 47700,
  46504: 47700,
  46506: 38500,
  46507: 21140,
  46508: 47700,
  46510: 47700,
  46511: 99999,
  46513: 38500,
  46514: 21140,
  46515: 21140,
  46516: 21140,
  46517: 21140,
  46524: 38500,
  46526: 21140,
  46527: 21140,
  46528: 99999,
  46530: 43780,
  46531: 99999,
  46532: 33140,
  46534: 99999,
  46536: 38500,
  46537: 38500,
  46538: 47700,
  46539: 38500,
  46540: 21140,
  46542: 47700,
  46543: 28340,
  46544: 43780,
  46545: 43780,
  46546: 43780,
  46550: 38500,
  46552: 33140,
  46553: 21140,
  46554: 43780,
  46555: 47700,
  46556: 43780,
  46561: 43780,
  46562: 28340,
  46563: 99999,
  46565: 99999,
  46567: 21140,
  46570: 99999,
  46571: 99999,
  46572: 38500,
  46573: 21140,
  46574: 38500,
  46580: 47700,
  46581: 47700,
  46582: 47700,
  46590: 47700,
  46595: 43780,
  46601: 43780,
  46613: 43780,
  46614: 43780,
  46615: 43780,
  46616: 43780,
  46617: 43780,
  46619: 43780,
  46624: 43780,
  46626: 43780,
  46628: 43780,
  46634: 43780,
  46635: 43780,
  46637: 43780,
  46660: 43780,
  46680: 43780,
  46701: 28340,
  46702: 47340,
  46703: 11420,
  46704: 23060,
  46705: 11420,
  46706: 12140,
  46710: 12140,
  46711: 19540,
  46713: 26540,
  46714: 19540,
  46721: 12140,
  46723: 28340,
  46725: 26540,
  46730: 12140,
  46731: 19540,
  46732: 47700,
  46733: 23060,
  46737: 11420,
  46738: 12140,
  46740: 19540,
  46741: 23060,
  46742: 12140,
  46743: 23060,
  46745: 19540,
  46746: 99999,
  46747: 12140,
  46748: 28340,
  46750: 26540,
  46755: 28340,
  46759: 99999,
  46760: 28340,
  46761: 99999,
  46763: 28340,
  46764: 28340,
  46765: 23060,
  46766: 26540,
  46767: 28340,
  46769: 19540,
  46770: 99999,
  46771: 99999,
  46772: 19540,
  46773: 19540,
  46774: 23060,
  46776: 99999,
  46777: 99999,
  46778: 99999,
  46779: 11420,
  46780: 19540,
  46781: 99999,
  46782: 19540,
  46783: 99999,
  46784: 28340,
  46785: 12140,
  46786: 99999,
  46787: 23060,
  46788: 12140,
  46789: 99999,
  46791: 99999,
  46792: 26540,
  46793: 11420,
  46794: 28340,
  46795: 28340,
  46796: 28340,
  46797: 23060,
  46798: 23060,
  46799: 23060,
  46802: 23060,
  46803: 23060,
  46804: 23060,
  46805: 23060,
  46806: 23060,
  46807: 23060,
  46808: 23060,
  46809: 23060,
  46814: 23060,
  46815: 23060,
  46816: 23060,
  46818: 23060,
  46819: 99999,
  46825: 23060,
  46835: 23060,
  46845: 23060,
  46850: 23060,
  46851: 23060,
  46852: 23060,
  46853: 23060,
  46854: 23060,
  46855: 23060,
  46856: 23060,
  46857: 23060,
  46858: 23060,
  46859: 23060,
  46860: 23060,
  46861: 23060,
  46862: 23060,
  46863: 23060,
  46864: 23060,
  46865: 23060,
  46866: 23060,
  46867: 23060,
  46868: 23060,
  46869: 23060,
  46885: 23060,
  46895: 23060,
  46898: 23060,
  46899: 23060,
  46901: 37940,
  46902: 99999,
  46903: 29020,
  46904: 29020,
  46910: 47700,
  46911: 37940,
  46912: 99999,
  46913: 29200,
  46914: 37940,
  46915: 29200,
  46916: 29200,
  46917: 29200,
  46919: 31980,
  46920: 29200,
  46921: 37940,
  46922: 99999,
  46923: 29200,
  46926: 47340,
  46928: 29020,
  46929: 29200,
  46930: 31980,
  46931: 99999,
  46932: 29200,
  46933: 31980,
  46936: 37940,
  46937: 29020,
  46938: 31980,
  46939: 99999,
  46940: 26540,
  46941: 47340,
  46942: 30900,
  46943: 47340,
  46945: 99999,
  46946: 47340,
  46947: 30900,
  46950: 30900,
  46951: 99999,
  46952: 99999,
  46953: 31980,
  46957: 31980,
  46958: 37940,
  46959: 37940,
  46960: 99999,
  46961: 30900,
  46962: 47700,
  46965: 29020,
  46967: 30900,
  46968: 99999,
  46970: 47340,
  46971: 37940,
  46974: 37940,
  46975: 38500,
  46977: 29200,
  46978: 99999,
  46979: 29200,
  46980: 47340,
  46982: 47700,
  46984: 47340,
  46985: 99999,
  46986: 31980,
  46987: 31980,
  46988: 99999,
  46989: 99999,
  46990: 47340,
  46991: 99999,
  46992: 47340,
  46994: 30900,
  46995: 29020,
  46996: 99999,
  46998: 30900,
  47001: 17140,
  47003: 17140,
  47006: 99999,
  47010: 17140,
  47011: 17140,
  47012: 17140,
  47016: 17140,
  47017: 17140,
  47018: 99999,
  47019: 99999,
  47020: 99999,
  47021: 99999,
  47022: 17140,
  47023: 99999,
  47024: 18220,
  47025: 17140,
  47030: 17140,
  47031: 99999,
  47032: 99999,
  47033: 99999,
  47034: 99999,
  47035: 17140,
  47036: 17140,
  47037: 99999,
  47038: 99999,
  47039: 99999,
  47040: 17140,
  47041: 99999,
  47042: 99999,
  47043: 99999,
  47060: 17140,
  47102: 42500,
  47104: 31140,
  47106: 31140,
  47107: 31140,
  47108: 99999,
  47110: 31140,
  47111: 31140,
  47112: 31140,
  47114: 31140,
  47115: 31140,
  47116: 99999,
  47117: 31140,
  47118: 99999,
  47119: 31140,
  47120: 31140,
  47122: 31140,
  47123: 99999,
  47124: 31140,
  47125: 99999,
  47126: 42500,
  47129: 31140,
  47130: 31140,
  47131: 31140,
  47135: 31140,
  47136: 31140,
  47137: 99999,
  47138: 31500,
  47140: 99999,
  47141: 42500,
  47142: 31140,
  47143: 31140,
  47145: 31140,
  47146: 31140,
  47147: 31500,
  47150: 31140,
  47151: 31140,
  47160: 31140,
  47161: 31140,
  47162: 31140,
  47163: 31140,
  47164: 31140,
  47165: 31140,
  47166: 31140,
  47167: 31140,
  47170: 42500,
  47172: 31140,
  47174: 99999,
  47175: 99999,
  47177: 31140,
  47190: 31140,
  47201: 26900,
  47202: 18020,
  47203: 18020,
  47220: 42980,
  47223: 99999,
  47224: 99999,
  47225: 24700,
  47226: 18020,
  47227: 31500,
  47228: 42980,
  47229: 35860,
  47230: 42500,
  47231: 31500,
  47232: 35860,
  47234: 26900,
  47235: 26900,
  47236: 18020,
  47240: 26900,
  47243: 31500,
  47244: 24700,
  47245: 35860,
  47246: 18020,
  47247: 18020,
  47250: 31500,
  47260: 42980,
  47263: 24700,
  47264: 42980,
  47265: 35860,
  47270: 31500,
  47272: 26900,
  47273: 35860,
  47274: 35860,
  47280: 18020,
  47281: 31140,
  47282: 35860,
  47283: 24700,
  47302: 35220,
  47303: 34620,
  47304: 34620,
  47305: 34620,
  47306: 34620,
  47307: 34620,
  47308: 34620,
  47320: 34620,
  47322: 18220,
  47324: 39980,
  47325: 39980,
  47326: 19540,
  47327: 18220,
  47330: 39980,
  47331: 39980,
  47334: 35220,
  47335: 39980,
  47336: 99999,
  47337: 35220,
  47338: 99999,
  47339: 39980,
  47340: 99999,
  47341: 39980,
  47342: 34620,
  47344: 35220,
  47345: 39980,
  47346: 35220,
  47348: 99999,
  47351: 35220,
  47352: 35220,
  47353: 17140,
  47354: 39980,
  47355: 39980,
  47356: 35220,
  47357: 39980,
  47358: 39980,
  47359: 99999,
  47360: 35220,
  47361: 35220,
  47362: 35220,
  47366: 35220,
  47367: 34620,
  47368: 34620,
  47369: 99999,
  47370: 39980,
  47371: 99999,
  47373: 99999,
  47374: 39980,
  47375: 39980,
  47380: 99999,
  47381: 99999,
  47382: 99999,
  47383: 99999,
  47384: 26900,
  47385: 35220,
  47386: 35220,
  47387: 35220,
  47388: 35220,
  47390: 99999,
  47392: 39980,
  47393: 39980,
  47394: 99999,
  47396: 34620,
  47401: 26900,
  47402: 14020,
  47403: 14020,
  47404: 14020,
  47405: 14020,
  47406: 14020,
  47407: 14020,
  47408: 14020,
  47420: 13260,
  47421: 14020,
  47424: 99999,
  47426: 14020,
  47427: 14020,
  47429: 14020,
  47431: 14020,
  47432: 99999,
  47433: 14020,
  47434: 14020,
  47435: 26900,
  47436: 13260,
  47437: 13260,
  47438: 99999,
  47441: 99999,
  47443: 99999,
  47445: 99999,
  47446: 99999,
  47448: 26900,
  47449: 99999,
  47451: 13260,
  47452: 99999,
  47453: 99999,
  47454: 99999,
  47455: 14020,
  47456: 26900,
  47457: 99999,
  47458: 14020,
  47459: 99999,
  47460: 99999,
  47462: 14020,
  47463: 14020,
  47464: 14020,
  47465: 99999,
  47467: 13260,
  47468: 14020,
  47469: 99999,
  47470: 99999,
  47471: 14020,
  47501: 47780,
  47512: 47180,
  47513: 27540,
  47514: 99999,
  47515: 99999,
  47516: 47180,
  47519: 47780,
  47520: 99999,
  47521: 27540,
  47522: 99999,
  47523: 27540,
  47524: 47180,
  47525: 99999,
  47527: 99999,
  47528: 47180,
  47529: 99999,
  47531: 99999,
  47532: 27540,
  47535: 47180,
  47536: 99999,
  47537: 21780,
  47541: 99999,
  47542: 27540,
  47545: 27540,
  47546: 27540,
  47547: 27540,
  47550: 99999,
  47551: 99999,
  47552: 99999,
  47553: 99999,
  47556: 99999,
  47557: 47180,
  47558: 47780,
  47561: 47180,
  47562: 99999,
  47564: 27540,
  47567: 27540,
  47568: 47780,
  47573: 47180,
  47574: 99999,
  47575: 27540,
  47576: 99999,
  47577: 99999,
  47578: 47180,
  47579: 99999,
  47580: 27540,
  47581: 99999,
  47584: 27540,
  47585: 27540,
  47586: 99999,
  47588: 99999,
  47590: 27540,
  47591: 47180,
  47596: 47180,
  47597: 47180,
  47598: 27540,
  47601: 21780,
  47610: 21780,
  47611: 99999,
  47612: 21780,
  47613: 99999,
  47615: 99999,
  47616: 99999,
  47617: 99999,
  47618: 21780,
  47619: 99999,
  47620: 21780,
  47629: 21780,
  47630: 21780,
  47631: 21780,
  47633: 21780,
  47634: 99999,
  47635: 99999,
  47637: 99999,
  47638: 21780,
  47639: 99999,
  47640: 99999,
  47647: 99999,
  47648: 99999,
  47649: 99999,
  47654: 99999,
  47660: 27540,
  47665: 99999,
  47666: 99999,
  47670: 99999,
  47683: 99999,
  47701: 21780,
  47702: 21780,
  47703: 21780,
  47704: 21780,
  47705: 21780,
  47706: 21780,
  47708: 21780,
  47710: 21780,
  47711: 21780,
  47712: 21780,
  47713: 21780,
  47714: 21780,
  47715: 21780,
  47716: 21780,
  47719: 21780,
  47720: 21780,
  47721: 21780,
  47722: 21780,
  47724: 21780,
  47725: 99999,
  47728: 21780,
  47730: 21780,
  47731: 21780,
  47732: 21780,
  47733: 21780,
  47734: 21780,
  47735: 21780,
  47736: 21780,
  47737: 21780,
  47747: 21780,
  47750: 21780,
  47801: 45460,
  47802: 45460,
  47803: 45460,
  47804: 45460,
  47805: 45460,
  47807: 45460,
  47809: 45460,
  47831: 45460,
  47832: 45460,
  47833: 14020,
  47834: 45460,
  47836: 45460,
  47837: 45460,
  47838: 47180,
  47840: 26900,
  47841: 14020,
  47842: 45460,
  47845: 45460,
  47846: 45460,
  47847: 45460,
  47848: 99999,
  47849: 45460,
  47850: 45460,
  47851: 45460,
  47852: 45460,
  47853: 45460,
  47854: 45460,
  47855: 45460,
  47857: 45460,
  47858: 45460,
  47859: 45460,
  47860: 45460,
  47861: 45460,
  47862: 45460,
  47863: 45460,
  47866: 45460,
  47868: 45460,
  47869: 45460,
  47870: 45460,
  47871: 45460,
  47872: 26900,
  47874: 45460,
  47875: 45460,
  47876: 45460,
  47878: 45460,
  47879: 45460,
  47880: 45460,
  47881: 45460,
  47882: 45460,
  47884: 45460,
  47885: 45460,
  47901: 29200,
  47902: 29200,
  47903: 29200,
  47904: 29200,
  47905: 23140,
  47906: 29200,
  47907: 29200,
  47909: 29200,
  47916: 18820,
  47917: 29200,
  47918: 99999,
  47920: 29200,
  47921: 29200,
  47922: 16980,
  47923: 99999,
  47924: 29200,
  47925: 99999,
  47926: 30900,
  47928: 45460,
  47929: 99999,
  47930: 23140,
  47932: 45460,
  47933: 26900,
  47940: 18820,
  47941: 29200,
  47942: 16980,
  47943: 16980,
  47944: 29200,
  47946: 16980,
  47948: 29200,
  47949: 99999,
  47950: 29200,
  47951: 16980,
  47952: 99999,
  47954: 26900,
  47955: 18820,
  47957: 16980,
  47958: 99999,
  47959: 16980,
  47960: 99999,
  47962: 29200,
  47963: 16980,
  47964: 16980,
  47965: 18820,
  47966: 45460,
  47967: 18820,
  47968: 26900,
  47969: 99999,
  47970: 99999,
  47971: 29200,
  47974: 45460,
  47975: 29200,
  47977: 16980,
  47978: 99999,
  47980: 99999,
  47981: 18820,
  47982: 29200,
  47983: 29200,
  47986: 29200,
  47987: 99999,
  47989: 26900,
  47990: 99999,
  47991: 29200,
  47992: 29200,
  47993: 29200,
  47994: 18820,
  47995: 16980,
  47996: 29200,
  47997: 29200,
  48001: 19820,
  48002: 19820,
  48003: 19820,
  48004: 19820,
  48005: 19820,
  48006: 19820,
  48009: 19820,
  48012: 19820,
  48014: 19820,
  48015: 19820,
  48017: 19820,
  48021: 19820,
  48022: 19820,
  48023: 19820,
  48025: 19820,
  48026: 19820,
  48027: 19820,
  48028: 19820,
  48030: 19820,
  48032: 99999,
  48033: 19820,
  48034: 19820,
  48035: 19820,
  48036: 19820,
  48037: 19820,
  48038: 19820,
  48039: 19820,
  48040: 19820,
  48041: 19820,
  48042: 19820,
  48043: 19820,
  48044: 19820,
  48045: 19820,
  48046: 19820,
  48047: 19820,
  48048: 19820,
  48049: 19820,
  48050: 19820,
  48051: 19820,
  48054: 19820,
  48059: 19820,
  48060: 19820,
  48061: 19820,
  48062: 19820,
  48063: 19820,
  48064: 19820,
  48065: 19820,
  48066: 19820,
  48067: 19820,
  48068: 19820,
  48069: 19820,
  48070: 19820,
  48071: 19820,
  48072: 19820,
  48073: 19820,
  48074: 19820,
  48075: 19820,
  48076: 19820,
  48079: 19820,
  48080: 19820,
  48081: 19820,
  48082: 19820,
  48083: 19820,
  48084: 19820,
  48085: 19820,
  48088: 19820,
  48089: 19820,
  48090: 19820,
  48091: 19820,
  48092: 19820,
  48093: 19820,
  48094: 19820,
  48095: 19820,
  48096: 19820,
  48097: 99999,
  48098: 19820,
  48099: 19820,
  48101: 19820,
  48103: 11460,
  48104: 11460,
  48105: 11460,
  48106: 11460,
  48107: 11460,
  48108: 11460,
  48109: 11460,
  48111: 33780,
  48112: 19820,
  48113: 11460,
  48114: 19820,
  48116: 19820,
  48117: 19820,
  48118: 11460,
  48120: 19820,
  48121: 19820,
  48122: 19820,
  48123: 19820,
  48124: 19820,
  48125: 19820,
  48126: 19820,
  48127: 19820,
  48128: 19820,
  48130: 11460,
  48131: 33780,
  48133: 33780,
  48134: 33780,
  48135: 19820,
  48136: 19820,
  48137: 19820,
  48138: 19820,
  48139: 19820,
  48140: 33780,
  48141: 19820,
  48143: 19820,
  48144: 33780,
  48145: 33780,
  48146: 19820,
  48150: 19820,
  48151: 19820,
  48152: 19820,
  48153: 19820,
  48154: 19820,
  48157: 33780,
  48158: 27100,
  48159: 33780,
  48160: 10300,
  48161: 33780,
  48162: 33780,
  48164: 19820,
  48165: 19820,
  48166: 33780,
  48167: 19820,
  48168: 19820,
  48169: 19820,
  48170: 19820,
  48173: 19820,
  48174: 19820,
  48175: 11460,
  48176: 11460,
  48177: 33780,
  48178: 11460,
  48179: 33780,
  48180: 19820,
  48182: 33780,
  48183: 19820,
  48184: 19820,
  48185: 19820,
  48186: 19820,
  48187: 19820,
  48188: 19820,
  48189: 19820,
  48190: 11460,
  48191: 33780,
  48192: 19820,
  48193: 19820,
  48195: 19820,
  48197: 11460,
  48198: 19820,
  48201: 19820,
  48202: 19820,
  48203: 19820,
  48204: 19820,
  48205: 19820,
  48206: 19820,
  48207: 19820,
  48208: 19820,
  48209: 19820,
  48210: 19820,
  48211: 19820,
  48212: 19820,
  48213: 19820,
  48214: 19820,
  48215: 19820,
  48216: 19820,
  48217: 19820,
  48218: 19820,
  48219: 19820,
  48220: 19820,
  48221: 19820,
  48222: 19820,
  48223: 19820,
  48224: 19820,
  48225: 19820,
  48226: 19820,
  48227: 19820,
  48228: 19820,
  48229: 19820,
  48230: 19820,
  48231: 19820,
  48232: 19820,
  48233: 19820,
  48234: 19820,
  48235: 19820,
  48236: 19820,
  48237: 19820,
  48238: 19820,
  48239: 19820,
  48240: 19820,
  48242: 19820,
  48243: 19820,
  48244: 19820,
  48288: 19820,
  48301: 19820,
  48302: 19820,
  48303: 19820,
  48304: 19820,
  48306: 19820,
  48307: 19820,
  48308: 19820,
  48309: 19820,
  48310: 19820,
  48311: 19820,
  48312: 19820,
  48313: 19820,
  48314: 19820,
  48315: 19820,
  48316: 19820,
  48317: 19820,
  48318: 19820,
  48320: 19820,
  48321: 19820,
  48322: 19820,
  48323: 19820,
  48324: 19820,
  48325: 19820,
  48326: 19820,
  48327: 19820,
  48328: 19820,
  48329: 19820,
  48330: 19820,
  48331: 19820,
  48332: 19820,
  48333: 19820,
  48334: 19820,
  48335: 19820,
  48336: 19820,
  48340: 19820,
  48341: 19820,
  48342: 19820,
  48343: 19820,
  48346: 19820,
  48347: 19820,
  48348: 19820,
  48350: 19820,
  48353: 19820,
  48356: 19820,
  48357: 19820,
  48359: 19820,
  48360: 19820,
  48361: 19820,
  48362: 19820,
  48363: 19820,
  48366: 19820,
  48367: 19820,
  48370: 19820,
  48371: 19820,
  48374: 19820,
  48375: 19820,
  48376: 19820,
  48377: 19820,
  48380: 19820,
  48381: 19820,
  48382: 19820,
  48383: 19820,
  48386: 19820,
  48387: 19820,
  48390: 19820,
  48393: 19820,
  48401: 99999,
  48410: 99999,
  48411: 22420,
  48412: 19820,
  48413: 99999,
  48414: 29620,
  48415: 99999,
  48416: 99999,
  48417: 22420,
  48418: 29620,
  48419: 99999,
  48420: 40980,
  48421: 19820,
  48422: 99999,
  48423: 19820,
  48426: 99999,
  48427: 99999,
  48428: 19820,
  48429: 22420,
  48430: 22420,
  48432: 99999,
  48433: 22420,
  48434: 99999,
  48435: 19820,
  48436: 22420,
  48437: 22420,
  48438: 22420,
  48439: 19820,
  48440: 19820,
  48441: 99999,
  48442: 22420,
  48444: 19820,
  48445: 99999,
  48446: 19820,
  48449: 22420,
  48450: 99999,
  48451: 22420,
  48453: 19820,
  48454: 99999,
  48455: 19820,
  48456: 99999,
  48457: 40980,
  48458: 22420,
  48460: 22420,
  48461: 19820,
  48462: 22420,
  48463: 22420,
  48464: 99999,
  48465: 99999,
  48466: 99999,
  48467: 99999,
  48468: 99999,
  48469: 99999,
  48470: 99999,
  48471: 99999,
  48472: 99999,
  48473: 22420,
  48475: 99999,
  48476: 29620,
  48480: 22420,
  48501: 22420,
  48502: 22420,
  48503: 22420,
  48504: 22420,
  48505: 22420,
  48506: 22420,
  48507: 22420,
  48509: 22420,
  48519: 22420,
  48529: 22420,
  48531: 22420,
  48532: 22420,
  48550: 22420,
  48601: 40980,
  48602: 40980,
  48603: 40980,
  48604: 13020,
  48605: 40980,
  48606: 40980,
  48607: 40980,
  48608: 40980,
  48609: 40980,
  48610: 99999,
  48611: 13020,
  48612: 99999,
  48613: 99999,
  48614: 10940,
  48615: 33220,
  48616: 29620,
  48617: 99999,
  48618: 33220,
  48619: 99999,
  48620: 33220,
  48621: 99999,
  48622: 99999,
  48623: 33220,
  48624: 99999,
  48625: 99999,
  48626: 40980,
  48627: 99999,
  48628: 33220,
  48629: 99999,
  48630: 99999,
  48631: 13020,
  48632: 99999,
  48633: 99999,
  48634: 33220,
  48635: 99999,
  48636: 99999,
  48637: 40980,
  48638: 40980,
  48640: 33220,
  48641: 33220,
  48642: 33220,
  48647: 99999,
  48649: 40980,
  48650: 13020,
  48651: 99999,
  48652: 99999,
  48653: 99999,
  48654: 99999,
  48655: 40980,
  48656: 99999,
  48657: 33220,
  48658: 13020,
  48659: 13020,
  48661: 99999,
  48662: 10940,
  48663: 40980,
  48667: 33220,
  48670: 33220,
  48674: 33220,
  48701: 99999,
  48703: 99999,
  48705: 99999,
  48706: 13020,
  48707: 13020,
  48708: 40980,
  48710: 13020,
  48720: 99999,
  48721: 99999,
  48722: 40980,
  48723: 99999,
  48724: 40980,
  48725: 99999,
  48726: 99999,
  48727: 19820,
  48728: 99999,
  48729: 99999,
  48730: 99999,
  48731: 99999,
  48732: 13020,
  48733: 99999,
  48734: 99999,
  48735: 99999,
  48737: 99999,
  48738: 99999,
  48739: 99999,
  48740: 99999,
  48741: 99999,
  48742: 99999,
  48743: 99999,
  48744: 99999,
  48745: 99999,
  48746: 22420,
  48747: 40980,
  48748: 99999,
  48749: 99999,
  48750: 99999,
  48754: 99999,
  48755: 99999,
  48756: 99999,
  48757: 99999,
  48758: 99999,
  48759: 99999,
  48760: 19820,
  48761: 99999,
  48762: 99999,
  48763: 99999,
  48765: 99999,
  48766: 99999,
  48767: 99999,
  48768: 99999,
  48770: 99999,
  48787: 40980,
  48801: 10940,
  48804: 34380,
  48805: 29620,
  48806: 10940,
  48807: 40980,
  48808: 29620,
  48809: 24340,
  48811: 24340,
  48812: 24340,
  48813: 12980,
  48815: 24340,
  48816: 19820,
  48817: 29620,
  48818: 24340,
  48819: 29620,
  48820: 29620,
  48821: 29620,
  48822: 29620,
  48823: 29620,
  48824: 29620,
  48825: 29620,
  48826: 29620,
  48827: 29620,
  48829: 34380,
  48830: 10940,
  48831: 40980,
  48832: 10940,
  48833: 29620,
  48834: 24340,
  48835: 29620,
  48836: 29620,
  48837: 29620,
  48838: 24340,
  48840: 29620,
  48841: 40980,
  48842: 29620,
  48843: 19820,
  48844: 19820,
  48845: 29620,
  48846: 24340,
  48847: 10940,
  48848: 29620,
  48849: 99999,
  48850: 24340,
  48851: 24340,
  48852: 24340,
  48853: 29620,
  48854: 29620,
  48855: 19820,
  48856: 10940,
  48857: 29620,
  48858: 34380,
  48859: 34380,
  48860: 24340,
  48861: 24340,
  48862: 10940,
  48864: 29620,
  48865: 24340,
  48866: 29620,
  48867: 29620,
  48870: 24340,
  48871: 29620,
  48872: 19820,
  48873: 24340,
  48874: 10940,
  48875: 24340,
  48876: 29620,
  48877: 34380,
  48878: 34380,
  48879: 10940,
  48880: 33220,
  48881: 24340,
  48882: 29620,
  48883: 33220,
  48884: 24340,
  48885: 24340,
  48886: 13660,
  48888: 24340,
  48889: 24340,
  48890: 29620,
  48891: 34380,
  48892: 29620,
  48893: 34380,
  48894: 24340,
  48895: 29620,
  48896: 34380,
  48897: 99999,
  48901: 29620,
  48906: 29620,
  48908: 29620,
  48909: 29620,
  48910: 29620,
  48911: 29620,
  48912: 29620,
  48915: 29620,
  48917: 29620,
  48924: 29620,
  48933: 29620,
  48951: 29620,
  48956: 29620,
  49001: 28020,
  49002: 28020,
  49003: 28020,
  49004: 28020,
  49005: 28020,
  49006: 28020,
  49007: 28020,
  49008: 28020,
  49009: 28020,
  49010: 26090,
  49011: 17740,
  49012: 12980,
  49013: 99999,
  49014: 12980,
  49015: 28020,
  49016: 12980,
  49017: 99999,
  49019: 28020,
  49020: 12980,
  49021: 29620,
  49022: 99999,
  49023: 35660,
  49024: 28020,
  49026: 99999,
  49027: 99999,
  49028: 17740,
  49029: 12980,
  49030: 17740,
  49031: 43780,
  49032: 44780,
  49033: 12980,
  49034: 12980,
  49035: 99999,
  49036: 17740,
  49037: 12980,
  49038: 35660,
  49040: 17740,
  49041: 28020,
  49042: 43780,
  49043: 35660,
  49045: 43780,
  49046: 99999,
  49047: 35660,
  49048: 28020,
  49050: 99999,
  49051: 12980,
  49052: 12980,
  49053: 28020,
  49055: 26090,
  49056: 26090,
  49057: 99999,
  49058: 99999,
  49060: 28020,
  49061: 43780,
  49062: 99999,
  49063: 99999,
  49064: 99999,
  49065: 99999,
  49066: 44780,
  49067: 99999,
  49068: 12980,
  49070: 26090,
  49071: 99999,
  49072: 28020,
  49073: 29620,
  49074: 28020,
  49075: 44780,
  49076: 12980,
  49077: 28020,
  49078: 99999,
  49079: 99999,
  49080: 26090,
  49081: 28020,
  49082: 25880,
  49083: 28020,
  49084: 35660,
  49085: 35660,
  49087: 99999,
  49088: 28020,
  49089: 17740,
  49090: 26090,
  49091: 44780,
  49092: 12980,
  49093: 43780,
  49094: 17740,
  49095: 43780,
  49096: 29620,
  49097: 44780,
  49098: 99999,
  49099: 44780,
  49101: 35660,
  49102: 35660,
  49103: 35660,
  49104: 35660,
  49106: 35660,
  49107: 35660,
  49111: 35660,
  49112: 43780,
  49113: 35660,
  49115: 35660,
  49116: 35660,
  49117: 35660,
  49119: 35660,
  49120: 35660,
  49125: 35660,
  49126: 35660,
  49127: 35660,
  49128: 35660,
  49129: 35660,
  49130: 43780,
  49201: 27100,
  49202: 27100,
  49203: 27100,
  49204: 27100,
  49220: 25880,
  49221: 10300,
  49224: 27100,
  49227: 17740,
  49228: 10300,
  49229: 10300,
  49230: 10300,
  49232: 25880,
  49233: 10300,
  49234: 27100,
  49235: 10300,
  49236: 10300,
  49237: 27100,
  49238: 33780,
  49239: 25880,
  49240: 27100,
  49241: 27100,
  49242: 25880,
  49245: 27100,
  49246: 27100,
  49247: 25880,
  49248: 10300,
  49249: 27100,
  49250: 25880,
  49251: 27100,
  49252: 25880,
  49253: 10300,
  49254: 27100,
  49255: 17740,
  49256: 10300,
  49257: 25880,
  49258: 25880,
  49259: 27100,
  49261: 27100,
  49262: 25880,
  49263: 27100,
  49264: 29620,
  49265: 10300,
  49266: 25880,
  49267: 10300,
  49268: 10300,
  49269: 27100,
  49270: 33780,
  49271: 25880,
  49272: 27100,
  49274: 17740,
  49276: 10300,
  49277: 27100,
  49279: 10300,
  49281: 25880,
  49282: 25880,
  49283: 27100,
  49284: 27100,
  49285: 19820,
  49286: 10300,
  49287: 10300,
  49288: 25880,
  49289: 10300,
  49301: 24340,
  49302: 24340,
  49303: 24340,
  49304: 99999,
  49305: 34380,
  49306: 24340,
  49307: 13660,
  49309: 99999,
  49310: 24340,
  49311: 26090,
  49312: 99999,
  49314: 26090,
  49315: 24340,
  49316: 26090,
  49317: 24340,
  49318: 34740,
  49319: 24340,
  49320: 13660,
  49321: 24340,
  49322: 24340,
  49323: 24340,
  49325: 99999,
  49326: 24340,
  49327: 24340,
  49328: 26090,
  49329: 13660,
  49330: 24340,
  49331: 24340,
  49332: 13660,
  49333: 24340,
  49335: 26090,
  49336: 24340,
  49337: 24340,
  49338: 99999,
  49339: 24340,
  49340: 34380,
  49341: 24340,
  49342: 13660,
  49343: 24340,
  49344: 26090,
  49345: 24340,
  49346: 13660,
  49347: 24340,
  49348: 24340,
  49349: 99999,
  49351: 24340,
  49401: 24340,
  49402: 31220,
  49403: 24340,
  49404: 34740,
  49405: 31220,
  49406: 26090,
  49408: 26090,
  49409: 24340,
  49410: 31220,
  49411: 31220,
  49412: 34740,
  49413: 99999,
  49415: 24340,
  49416: 26090,
  49417: 24340,
  49418: 24340,
  49419: 26090,
  49420: 99999,
  49421: 99999,
  49422: 24340,
  49423: 24340,
  49424: 24340,
  49425: 99999,
  49426: 26090,
  49427: 24340,
  49428: 24340,
  49429: 24340,
  49430: 24340,
  49431: 31220,
  49434: 24340,
  49435: 24340,
  49436: 99999,
  49437: 99999,
  49440: 34740,
  49441: 34740,
  49442: 34740,
  49443: 34740,
  49444: 34740,
  49445: 34740,
  49446: 99999,
  49448: 34740,
  49449: 99999,
  49450: 26090,
  49451: 99999,
  49452: 99999,
  49453: 26090,
  49454: 31220,
  49455: 99999,
  49456: 24340,
  49457: 99999,
  49458: 31220,
  49459: 99999,
  49460: 24340,
  49461: 34740,
  49464: 26090,
  49468: 24340,
  49501: 24340,
  49503: 24340,
  49504: 24340,
  49505: 24340,
  49506: 24340,
  49507: 24340,
  49508: 24340,
  49509: 24340,
  49510: 24340,
  49512: 24340,
  49514: 24340,
  49515: 24340,
  49516: 24340,
  49518: 24340,
  49519: 24340,
  49525: 24340,
  49534: 24340,
  49544: 24340,
  49546: 24340,
  49548: 24340,
  49560: 24340,
  49588: 24340,
  49601: 15620,
  49610: 45900,
  49611: 99999,
  49612: 45900,
  49613: 45900,
  49614: 99999,
  49615: 99999,
  49616: 99999,
  49617: 45900,
  49618: 15620,
  49619: 99999,
  49620: 45900,
  49621: 45900,
  49622: 99999,
  49623: 99999,
  49625: 15620,
  49626: 99999,
  49627: 99999,
  49628: 45900,
  49629: 99999,
  49630: 45900,
  49631: 99999,
  49632: 15620,
  49633: 45900,
  49634: 99999,
  49635: 45900,
  49636: 45900,
  49637: 45900,
  49638: 99999,
  49639: 13660,
  49640: 45900,
  49642: 99999,
  49643: 45900,
  49644: 99999,
  49645: 99999,
  49646: 45900,
  49648: 99999,
  49649: 15620,
  49650: 45900,
  49651: 15620,
  49653: 45900,
  49654: 45900,
  49655: 99999,
  49656: 99999,
  49657: 99999,
  49659: 45900,
  49660: 99999,
  49663: 15620,
  49664: 45900,
  49665: 15620,
  49666: 45900,
  49667: 15620,
  49668: 99999,
  49670: 45900,
  49673: 45900,
  49674: 45900,
  49675: 99999,
  49676: 99999,
  49677: 99999,
  49679: 13660,
  49680: 45900,
  49682: 45900,
  49683: 45900,
  49684: 45900,
  49685: 45900,
  49686: 45900,
  49688: 15620,
  49689: 15620,
  49690: 45900,
  49696: 45900,
  49701: 99999,
  49705: 99999,
  49706: 99999,
  49707: 99999,
  49709: 99999,
  49710: 42300,
  49712: 99999,
  49713: 99999,
  49715: 42300,
  49716: 99999,
  49717: 99999,
  49718: 99999,
  49719: 99999,
  49720: 99999,
  49721: 99999,
  49722: 99999,
  49723: 99999,
  49724: 42300,
  49725: 42300,
  49726: 42300,
  49727: 99999,
  49728: 42300,
  49729: 99999,
  49730: 99999,
  49733: 99999,
  49734: 99999,
  49735: 99999,
  49736: 42300,
  49738: 99999,
  49740: 99999,
  49743: 99999,
  49744: 10980,
  49745: 99999,
  49746: 99999,
  49747: 10980,
  49748: 42300,
  49749: 99999,
  49751: 99999,
  49752: 42300,
  49753: 10980,
  49755: 99999,
  49756: 99999,
  49757: 99999,
  49759: 99999,
  49760: 42300,
  49761: 99999,
  49762: 99999,
  49764: 99999,
  49765: 99999,
  49766: 10980,
  49768: 42300,
  49769: 99999,
  49770: 99999,
  49774: 42300,
  49775: 99999,
  49776: 10980,
  49777: 99999,
  49779: 99999,
  49780: 42300,
  49781: 99999,
  49782: 99999,
  49783: 42300,
  49788: 42300,
  49791: 99999,
  49792: 99999,
  49793: 42300,
  49795: 99999,
  49796: 99999,
  49797: 99999,
  49799: 99999,
  49801: 27020,
  49802: 27020,
  49805: 26340,
  49806: 99999,
  49807: 31940,
  49808: 32100,
  49812: 31940,
  49814: 32100,
  49815: 27020,
  49816: 99999,
  49817: 21540,
  49818: 32100,
  49819: 32100,
  49820: 99999,
  49821: 31940,
  49822: 32100,
  49825: 99999,
  49826: 99999,
  49827: 99999,
  49829: 21540,
  49831: 27020,
  49833: 32100,
  49834: 27020,
  49835: 21540,
  49836: 99999,
  49837: 21540,
  49838: 99999,
  49839: 99999,
  49840: 99999,
  49841: 32100,
  49845: 31940,
  49847: 31940,
  49848: 31940,
  49849: 32100,
  49852: 27020,
  49853: 99999,
  49854: 21540,
  49855: 32100,
  49858: 31940,
  49861: 99999,
  49862: 99999,
  49863: 31940,
  49864: 21540,
  49865: 32100,
  49866: 32100,
  49868: 42300,
  49870: 27020,
  49871: 32100,
  49872: 21540,
  49873: 31940,
  49874: 31940,
  49876: 27020,
  49877: 27020,
  49878: 21540,
  49879: 32100,
  49880: 32100,
  49881: 27020,
  49883: 99999,
  49884: 99999,
  49885: 99999,
  49886: 31940,
  49887: 31940,
  49891: 99999,
  49892: 27020,
  49893: 31940,
  49894: 21540,
  49895: 21540,
  49896: 31940,
  49901: 26340,
  49902: 99999,
  49903: 99999,
  49905: 26340,
  49908: 99999,
  49910: 99999,
  49911: 99999,
  49912: 99999,
  49913: 26340,
  49915: 99999,
  49916: 26340,
  49917: 26340,
  49918: 26340,
  49919: 99999,
  49920: 99999,
  49921: 26340,
  49922: 26340,
  49925: 99999,
  49927: 99999,
  49929: 99999,
  49930: 26340,
  49931: 26340,
  49934: 26340,
  49935: 99999,
  49938: 99999,
  49945: 26340,
  49946: 99999,
  49947: 99999,
  49948: 99999,
  49950: 26340,
  49952: 26340,
  49953: 99999,
  49955: 26340,
  49958: 26340,
  49959: 99999,
  49960: 99999,
  49961: 26340,
  49962: 99999,
  49963: 26340,
  49964: 99999,
  49965: 26340,
  49967: 26340,
  49968: 99999,
  49969: 99999,
  49970: 99999,
  49971: 99999,
  50001: 19780,
  50002: 19780,
  50003: 19780,
  50005: 32260,
  50006: 99999,
  50007: 19780,
  50008: 99999,
  50009: 19780,
  50010: 11180,
  50011: 11180,
  50012: 11180,
  50013: 11180,
  50014: 11180,
  50020: 99999,
  50021: 19780,
  50022: 99999,
  50023: 19780,
  50025: 99999,
  50026: 19780,
  50027: 99999,
  50028: 32260,
  50029: 99999,
  50031: 11180,
  50032: 19780,
  50033: 19780,
  50034: 99999,
  50035: 19780,
  50036: 11180,
  50038: 19780,
  50039: 11180,
  50040: 11180,
  50041: 99999,
  50042: 99999,
  50044: 36820,
  50046: 11180,
  50047: 19780,
  50048: 99999,
  50049: 99999,
  50050: 16140,
  50051: 32260,
  50052: 99999,
  50054: 19780,
  50055: 32260,
  50056: 11180,
  50057: 99999,
  50058: 99999,
  50059: 99999,
  50060: 99999,
  50061: 19780,
  50062: 37800,
  50063: 19780,
  50064: 11180,
  50065: 99999,
  50066: 19780,
  50067: 99999,
  50068: 99999,
  50069: 19780,
  50070: 99999,
  50071: 99999,
  50072: 19780,
  50073: 19780,
  50074: 99999,
  50075: 99999,
  50076: 19780,
  50078: 32260,
  50101: 99999,
  50102: 99999,
  50103: 99999,
  50104: 99999,
  50105: 11180,
  50106: 19780,
  50107: 11180,
  50108: 99999,
  50109: 19780,
  50110: 99999,
  50111: 19780,
  50112: 19780,
  50115: 19780,
  50116: 37800,
  50117: 99999,
  50118: 19780,
  50119: 37800,
  50120: 32260,
  50122: 11180,
  50123: 99999,
  50124: 19780,
  50125: 19780,
  50126: 99999,
  50127: 19780,
  50128: 19780,
  50129: 19780,
  50130: 99999,
  50131: 19780,
  50132: 99999,
  50133: 99999,
  50134: 11180,
  50135: 19780,
  50136: 99999,
  50137: 19780,
  50138: 37800,
  50139: 19780,
  50140: 99999,
  50141: 19780,
  50142: 32260,
  50143: 37800,
  50144: 99999,
  50145: 19780,
  50146: 19780,
  50147: 99999,
  50148: 47940,
  50149: 99999,
  50150: 37800,
  50151: 99999,
  50152: 11180,
  50153: 19780,
  50154: 11180,
  50155: 19780,
  50156: 19780,
  50157: 99999,
  50158: 99999,
  50160: 19780,
  50161: 11180,
  50162: 19780,
  50163: 37800,
  50164: 99999,
  50165: 99999,
  50166: 19780,
  50167: 19780,
  50168: 19780,
  50169: 19780,
  50170: 19780,
  50171: 99999,
  50173: 99999,
  50174: 99999,
  50201: 11180,
  50206: 32260,
  50207: 99999,
  50208: 19780,
  50210: 99999,
  50211: 19780,
  50212: 11180,
  50213: 19780,
  50214: 37800,
  50216: 19780,
  50217: 22700,
  50218: 19780,
  50219: 37800,
  50220: 99999,
  50222: 99999,
  50223: 11180,
  50225: 37800,
  50226: 11180,
  50227: 99999,
  50228: 37800,
  50229: 19780,
  50230: 11180,
  50231: 99999,
  50232: 19780,
  50233: 19780,
  50234: 19780,
  50235: 19780,
  50236: 99999,
  50237: 19780,
  50238: 99999,
  50239: 32260,
  50240: 19780,
  50241: 19780,
  50242: 19780,
  50243: 11180,
  50244: 19780,
  50246: 99999,
  50247: 32260,
  50248: 99999,
  50249: 99999,
  50250: 19780,
  50251: 36820,
  50252: 37800,
  50254: 99999,
  50255: 99999,
  50256: 36820,
  50257: 99999,
  50258: 47940,
  50259: 99999,
  50261: 19780,
  50262: 99999,
  50263: 19780,
  50264: 99999,
  50265: 19780,
  50266: 19780,
  50268: 99999,
  50269: 47940,
  50271: 99999,
  50272: 99999,
  50273: 99999,
  50274: 99999,
  50275: 99999,
  50276: 11180,
  50277: 19780,
  50278: 11180,
  50301: 19780,
  50302: 19780,
  50303: 19780,
  50304: 19780,
  50305: 19780,
  50309: 19780,
  50310: 19780,
  50311: 19780,
  50312: 19780,
  50313: 19780,
  50314: 19780,
  50315: 19780,
  50316: 19780,
  50317: 19780,
  50318: 19780,
  50319: 19780,
  50320: 19780,
  50321: 19780,
  50322: 19780,
  50323: 19780,
  50324: 19780,
  50325: 19780,
  50327: 19780,
  50328: 19780,
  50359: 19780,
  50360: 19780,
  50361: 19780,
  50362: 19780,
  50363: 19780,
  50364: 19780,
  50368: 19780,
  50380: 19780,
  50391: 19780,
  50392: 19780,
  50395: 19780,
  50398: 19780,
  50401: 32380,
  50402: 32380,
  50420: 99999,
  50421: 99999,
  50423: 99999,
  50424: 99999,
  50426: 99999,
  50427: 99999,
  50428: 32380,
  50430: 99999,
  50431: 99999,
  50432: 99999,
  50433: 32380,
  50434: 32380,
  50435: 99999,
  50436: 99999,
  50438: 99999,
  50439: 99999,
  50440: 32380,
  50441: 99999,
  50444: 32380,
  50446: 99999,
  50447: 99999,
  50448: 32380,
  50449: 32380,
  50450: 32380,
  50451: 99999,
  50452: 99999,
  50453: 99999,
  50454: 99999,
  50455: 99999,
  50456: 32380,
  50457: 99999,
  50458: 99999,
  50459: 99999,
  50460: 99999,
  50461: 32380,
  50464: 99999,
  50465: 99999,
  50466: 99999,
  50467: 32380,
  50468: 32380,
  50469: 32380,
  50470: 99999,
  50471: 99999,
  50472: 99999,
  50473: 99999,
  50475: 99999,
  50476: 99999,
  50477: 32380,
  50478: 99999,
  50479: 32380,
  50480: 99999,
  50481: 99999,
  50482: 99999,
  50483: 99999,
  50484: 99999,
  50501: 22700,
  50510: 44740,
  50511: 99999,
  50514: 99999,
  50515: 99999,
  50516: 99999,
  50517: 99999,
  50518: 22700,
  50519: 99999,
  50520: 99999,
  50521: 22700,
  50522: 99999,
  50523: 22700,
  50524: 99999,
  50525: 99999,
  50527: 99999,
  50528: 99999,
  50529: 99999,
  50530: 22700,
  50531: 99999,
  50532: 22700,
  50533: 22700,
  50535: 99999,
  50536: 99999,
  50538: 99999,
  50539: 99999,
  50540: 99999,
  50541: 99999,
  50542: 99999,
  50543: 22700,
  50544: 99999,
  50545: 99999,
  50546: 99999,
  50548: 99999,
  50551: 99999,
  50552: 99999,
  50554: 99999,
  50556: 99999,
  50557: 22700,
  50558: 99999,
  50559: 99999,
  50560: 99999,
  50561: 99999,
  50562: 99999,
  50563: 22700,
  50565: 43980,
  50566: 99999,
  50567: 44740,
  50568: 44740,
  50569: 22700,
  50570: 99999,
  50571: 99999,
  50573: 99999,
  50574: 99999,
  50575: 99999,
  50576: 44740,
  50577: 99999,
  50578: 99999,
  50579: 99999,
  50581: 99999,
  50582: 99999,
  50583: 99999,
  50585: 43980,
  50586: 99999,
  50588: 44740,
  50590: 99999,
  50591: 99999,
  50592: 44740,
  50593: 99999,
  50594: 22700,
  50595: 99999,
  50597: 99999,
  50598: 99999,
  50599: 99999,
  50601: 47940,
  50602: 99999,
  50603: 99999,
  50604: 99999,
  50605: 99999,
  50606: 99999,
  50607: 99999,
  50608: 99999,
  50609: 32260,
  50611: 99999,
  50612: 99999,
  50613: 99999,
  50614: 47940,
  50616: 99999,
  50619: 99999,
  50620: 99999,
  50621: 47940,
  50622: 47940,
  50623: 47940,
  50624: 47940,
  50625: 99999,
  50626: 47940,
  50627: 47940,
  50628: 99999,
  50629: 99999,
  50630: 99999,
  50631: 47940,
  50632: 99999,
  50633: 99999,
  50634: 47940,
  50635: 32260,
  50636: 99999,
  50638: 47940,
  50641: 99999,
  50642: 47940,
  50643: 47940,
  50644: 99999,
  50645: 99999,
  50647: 47940,
  50648: 99999,
  50649: 99999,
  50650: 99999,
  50651: 99999,
  50652: 99999,
  50653: 99999,
  50654: 99999,
  50655: 99999,
  50657: 47940,
  50658: 47940,
  50659: 99999,
  50660: 99999,
  50661: 99999,
  50662: 99999,
  50664: 47940,
  50665: 47940,
  50666: 47940,
  50667: 47940,
  50668: 47940,
  50669: 99999,
  50670: 47940,
  50671: 99999,
  50672: 47940,
  50673: 47940,
  50674: 47940,
  50675: 47940,
  50676: 47940,
  50677: 47940,
  50680: 47940,
  50681: 99999,
  50682: 99999,
  50701: 47940,
  50702: 47940,
  50703: 47940,
  50704: 47940,
  50707: 47940,
  50801: 99999,
  50830: 99999,
  50831: 99999,
  50833: 99999,
  50835: 99999,
  50836: 99999,
  50837: 99999,
  50839: 99999,
  50840: 99999,
  50841: 99999,
  50842: 99999,
  50843: 99999,
  50845: 99999,
  50846: 99999,
  50847: 99999,
  50848: 99999,
  50849: 99999,
  50851: 99999,
  50853: 99999,
  50854: 99999,
  50857: 99999,
  50858: 19780,
  50859: 99999,
  50860: 99999,
  50861: 99999,
  50862: 99999,
  50863: 99999,
  50864: 99999,
  50947: 19780,
  51001: 99999,
  51002: 44740,
  51003: 99999,
  51004: 43580,
  51005: 44740,
  51006: 43580,
  51007: 43580,
  51008: 99999,
  51009: 99999,
  51010: 99999,
  51011: 99999,
  51012: 99999,
  51014: 99999,
  51015: 43580,
  51016: 43580,
  51018: 99999,
  51019: 99999,
  51020: 44740,
  51022: 99999,
  51023: 99999,
  51024: 99999,
  51025: 99999,
  51026: 43580,
  51027: 99999,
  51028: 99999,
  51029: 99999,
  51030: 43580,
  51031: 99999,
  51033: 43980,
  51034: 43580,
  51035: 99999,
  51036: 99999,
  51037: 99999,
  51038: 99999,
  51039: 43580,
  51040: 99999,
  51041: 99999,
  51044: 43580,
  51045: 99999,
  51046: 99999,
  51047: 43980,
  51048: 43580,
  51049: 99999,
  51050: 99999,
  51051: 99999,
  51052: 43580,
  51053: 99999,
  51054: 43580,
  51055: 43580,
  51056: 43580,
  51058: 99999,
  51060: 99999,
  51061: 43580,
  51062: 99999,
  51063: 43580,
  51101: 43580,
  51102: 43580,
  51103: 99999,
  51104: 43580,
  51105: 43580,
  51106: 43580,
  51108: 43580,
  51109: 43580,
  51111: 43580,
  51201: 99999,
  51230: 99999,
  51231: 99999,
  51232: 99999,
  51234: 99999,
  51235: 99999,
  51237: 99999,
  51238: 99999,
  51239: 99999,
  51240: 99999,
  51241: 99999,
  51242: 99999,
  51243: 99999,
  51244: 99999,
  51245: 99999,
  51246: 99999,
  51247: 99999,
  51248: 99999,
  51249: 99999,
  51250: 99999,
  51301: 44020,
  51331: 44020,
  51333: 44020,
  51334: 44020,
  51338: 43980,
  51340: 43980,
  51341: 43980,
  51342: 99999,
  51343: 43980,
  51345: 99999,
  51346: 43980,
  51347: 99999,
  51350: 99999,
  51351: 44020,
  51354: 99999,
  51355: 44020,
  51357: 43980,
  51358: 99999,
  51360: 44020,
  51363: 44020,
  51364: 43980,
  51365: 99999,
  51366: 44740,
  51401: 16140,
  51430: 16140,
  51431: 99999,
  51432: 99999,
  51433: 99999,
  51436: 99999,
  51439: 99999,
  51440: 99999,
  51441: 99999,
  51442: 99999,
  51443: 99999,
  51444: 16140,
  51445: 99999,
  51446: 99999,
  51447: 99999,
  51448: 99999,
  51449: 16140,
  51450: 99999,
  51451: 16140,
  51452: 16140,
  51453: 99999,
  51454: 99999,
  51455: 99999,
  51458: 99999,
  51459: 16140,
  51460: 99999,
  51461: 99999,
  51462: 99999,
  51463: 99999,
  51465: 99999,
  51466: 99999,
  51467: 16140,
  51501: 36540,
  51503: 36540,
  51510: 36540,
  51520: 99999,
  51521: 36540,
  51523: 36540,
  51525: 36540,
  51526: 36540,
  51527: 99999,
  51528: 99999,
  51529: 99999,
  51530: 99999,
  51531: 99999,
  51532: 36540,
  51533: 36540,
  51534: 36540,
  51535: 99999,
  51536: 36540,
  51537: 99999,
  51540: 36540,
  51541: 36540,
  51542: 36540,
  51543: 99999,
  51544: 36540,
  51545: 36540,
  51546: 36540,
  51548: 36540,
  51549: 36540,
  51550: 36540,
  51551: 99999,
  51552: 99999,
  51553: 36540,
  51554: 36540,
  51555: 36540,
  51556: 36540,
  51557: 36540,
  51558: 36540,
  51559: 36540,
  51560: 36540,
  51561: 36540,
  51562: 36540,
  51563: 99999,
  51564: 36540,
  51565: 36540,
  51566: 99999,
  51570: 99999,
  51571: 36540,
  51572: 99999,
  51573: 99999,
  51575: 36540,
  51576: 36540,
  51577: 36540,
  51578: 99999,
  51579: 36540,
  51601: 99999,
  51603: 99999,
  51630: 99999,
  51631: 99999,
  51632: 99999,
  51636: 99999,
  51637: 99999,
  51638: 99999,
  51639: 99999,
  51640: 99999,
  51645: 36540,
  51646: 99999,
  51647: 99999,
  51648: 99999,
  51649: 36540,
  51650: 99999,
  51651: 99999,
  51652: 99999,
  51653: 99999,
  51654: 99999,
  51656: 99999,
  52001: 20220,
  52002: 20220,
  52003: 20220,
  52004: 20220,
  52030: 99999,
  52031: 99999,
  52032: 16300,
  52033: 20220,
  52035: 99999,
  52036: 99999,
  52037: 99999,
  52038: 99999,
  52039: 20220,
  52040: 99999,
  52041: 99999,
  52042: 99999,
  52043: 99999,
  52044: 99999,
  52045: 20220,
  52046: 20220,
  52047: 99999,
  52048: 99999,
  52049: 99999,
  52050: 99999,
  52052: 99999,
  52053: 99999,
  52054: 99999,
  52056: 20220,
  52057: 99999,
  52060: 99999,
  52064: 99999,
  52065: 99999,
  52066: 99999,
  52068: 20220,
  52069: 99999,
  52070: 99999,
  52071: 99999,
  52072: 99999,
  52073: 99999,
  52074: 99999,
  52075: 99999,
  52076: 99999,
  52077: 99999,
  52078: 20220,
  52079: 99999,
  52099: 20220,
  52101: 99999,
  52132: 99999,
  52133: 99999,
  52134: 99999,
  52135: 99999,
  52136: 99999,
  52140: 99999,
  52141: 99999,
  52142: 99999,
  52144: 99999,
  52146: 99999,
  52147: 99999,
  52149: 99999,
  52151: 99999,
  52154: 99999,
  52155: 99999,
  52156: 99999,
  52157: 99999,
  52158: 99999,
  52159: 99999,
  52160: 99999,
  52161: 99999,
  52162: 99999,
  52163: 99999,
  52164: 99999,
  52165: 99999,
  52166: 99999,
  52168: 99999,
  52169: 99999,
  52170: 99999,
  52171: 99999,
  52172: 99999,
  52175: 99999,
  52201: 99999,
  52202: 16300,
  52203: 26980,
  52204: 99999,
  52205: 16300,
  52206: 16300,
  52207: 99999,
  52208: 99999,
  52209: 16300,
  52210: 99999,
  52211: 99999,
  52212: 16300,
  52213: 16300,
  52214: 16300,
  52215: 99999,
  52216: 16300,
  52217: 99999,
  52218: 16300,
  52219: 16300,
  52220: 99999,
  52221: 99999,
  52222: 99999,
  52223: 99999,
  52224: 16300,
  52225: 99999,
  52227: 16300,
  52228: 26980,
  52229: 16300,
  52231: 99999,
  52232: 99999,
  52233: 16300,
  52235: 26980,
  52236: 26980,
  52237: 16300,
  52240: 26980,
  52241: 26980,
  52242: 26980,
  52243: 26980,
  52244: 26980,
  52245: 26980,
  52246: 26980,
  52247: 26980,
  52248: 99999,
  52249: 16300,
  52251: 99999,
  52252: 16300,
  52253: 99999,
  52254: 17540,
  52255: 99999,
  52257: 16300,
  52301: 16300,
  52302: 16300,
  52305: 16300,
  52306: 99999,
  52307: 99999,
  52308: 99999,
  52309: 16300,
  52310: 99999,
  52312: 16300,
  52313: 16300,
  52314: 16300,
  52315: 16300,
  52316: 99999,
  52317: 26980,
  52318: 16300,
  52320: 16300,
  52321: 16300,
  52322: 99999,
  52323: 16300,
  52324: 16300,
  52325: 99999,
  52326: 99999,
  52327: 99999,
  52328: 16300,
  52329: 99999,
  52330: 99999,
  52332: 16300,
  52333: 16300,
  52334: 99999,
  52335: 99999,
  52336: 16300,
  52337: 99999,
  52338: 16300,
  52339: 99999,
  52340: 26980,
  52341: 16300,
  52342: 99999,
  52344: 16300,
  52345: 16300,
  52346: 16300,
  52347: 99999,
  52348: 99999,
  52349: 16300,
  52351: 16300,
  52352: 16300,
  52353: 26980,
  52354: 16300,
  52355: 99999,
  52356: 26980,
  52358: 26980,
  52359: 26980,
  52361: 99999,
  52362: 16300,
  52401: 16300,
  52402: 16300,
  52403: 16300,
  52404: 26980,
  52405: 16300,
  52406: 16300,
  52408: 16300,
  52409: 16300,
  52410: 16300,
  52411: 16300,
  52498: 16300,
  52499: 16300,
  52501: 99999,
  52530: 36900,
  52531: 36900,
  52533: 36900,
  52534: 36820,
  52535: 99999,
  52536: 36900,
  52537: 36900,
  52540: 26980,
  52542: 99999,
  52543: 36820,
  52544: 99999,
  52548: 36900,
  52549: 99999,
  52550: 36820,
  52551: 99999,
  52552: 99999,
  52553: 36820,
  52554: 36900,
  52555: 99999,
  52556: 21840,
  52557: 21840,
  52560: 99999,
  52561: 99999,
  52562: 99999,
  52563: 21840,
  52565: 99999,
  52566: 36900,
  52567: 99999,
  52568: 99999,
  52569: 99999,
  52570: 99999,
  52571: 99999,
  52572: 99999,
  52573: 99999,
  52574: 99999,
  52576: 21840,
  52577: 36820,
  52580: 21840,
  52581: 99999,
  52583: 99999,
  52584: 99999,
  52585: 99999,
  52586: 99999,
  52588: 99999,
  52590: 99999,
  52591: 99999,
  52593: 99999,
  52594: 99999,
  52595: 36820,
  52601: 15460,
  52619: 22800,
  52620: 99999,
  52621: 99999,
  52623: 99999,
  52624: 22800,
  52625: 22800,
  52626: 99999,
  52627: 22800,
  52630: 99999,
  52631: 22800,
  52632: 22800,
  52635: 99999,
  52637: 99999,
  52638: 15460,
  52639: 22800,
  52640: 99999,
  52641: 26980,
  52644: 99999,
  52645: 15460,
  52646: 99999,
  52647: 99999,
  52648: 22800,
  52649: 22800,
  52650: 15460,
  52651: 21840,
  52652: 99999,
  52653: 99999,
  52654: 26980,
  52655: 15460,
  52656: 22800,
  52657: 22800,
  52658: 22800,
  52659: 99999,
  52660: 15460,
  52701: 17540,
  52720: 34700,
  52721: 99999,
  52722: 19340,
  52726: 34700,
  52727: 17540,
  52728: 19340,
  52729: 17540,
  52730: 17540,
  52731: 17540,
  52732: 17540,
  52737: 99999,
  52738: 99999,
  52739: 34700,
  52742: 17540,
  52745: 19340,
  52746: 19340,
  52747: 99999,
  52748: 19340,
  52749: 34700,
  52750: 17540,
  52751: 17540,
  52752: 99999,
  52753: 19340,
  52754: 34700,
  52755: 99999,
  52756: 19340,
  52757: 17540,
  52758: 19340,
  52759: 34700,
  52760: 99999,
  52761: 99999,
  52765: 99999,
  52766: 26980,
  52767: 19340,
  52768: 19340,
  52769: 19340,
  52771: 17540,
  52772: 99999,
  52773: 34700,
  52774: 17540,
  52776: 99999,
  52777: 99999,
  52778: 34700,
  52801: 19340,
  52802: 19340,
  52803: 19340,
  52804: 19340,
  52805: 19340,
  52806: 19340,
  52807: 19340,
  52808: 19340,
  52809: 19340,
  53001: 43100,
  53002: 33340,
  53003: 13180,
  53004: 43100,
  53005: 33340,
  53006: 22540,
  53007: 33340,
  53008: 33340,
  53010: 13180,
  53011: 22540,
  53012: 33340,
  53013: 33340,
  53014: 11540,
  53015: 43100,
  53016: 13180,
  53017: 33340,
  53018: 33340,
  53019: 22540,
  53020: 43100,
  53021: 33340,
  53022: 33340,
  53023: 22540,
  53024: 33340,
  53026: 43100,
  53027: 33340,
  53029: 33340,
  53031: 43100,
  53032: 13180,
  53033: 33340,
  53034: 13180,
  53035: 13180,
  53036: 48020,
  53037: 33340,
  53038: 48020,
  53039: 13180,
  53040: 22540,
  53042: 43100,
  53044: 43100,
  53045: 33340,
  53046: 33340,
  53047: 13180,
  53048: 22540,
  53049: 11540,
  53050: 13180,
  53051: 33340,
  53052: 33340,
  53056: 33340,
  53057: 22540,
  53058: 33340,
  53059: 13180,
  53060: 33340,
  53061: 22540,
  53062: 11540,
  53063: 31820,
  53064: 33340,
  53065: 22540,
  53066: 13180,
  53069: 33340,
  53070: 43100,
  53072: 33340,
  53073: 43100,
  53074: 33340,
  53075: 43100,
  53076: 33340,
  53078: 13180,
  53079: 22540,
  53080: 33340,
  53081: 43100,
  53082: 43100,
  53083: 43100,
  53085: 43100,
  53086: 33340,
  53088: 11540,
  53089: 33340,
  53090: 33340,
  53091: 13180,
  53092: 33340,
  53093: 43100,
  53094: 13180,
  53095: 33340,
  53097: 33340,
  53098: 48020,
  53099: 13180,
  53101: 16980,
  53102: 16980,
  53103: 33340,
  53104: 16980,
  53105: 16980,
  53108: 39540,
  53109: 16980,
  53110: 33340,
  53114: 48580,
  53115: 48580,
  53118: 33340,
  53119: 48580,
  53120: 39540,
  53121: 48580,
  53122: 33340,
  53125: 48580,
  53126: 33340,
  53127: 33340,
  53128: 16980,
  53129: 33340,
  53130: 33340,
  53132: 33340,
  53137: 48020,
  53138: 39540,
  53139: 16980,
  53140: 16980,
  53141: 16980,
  53142: 16980,
  53143: 16980,
  53144: 16980,
  53146: 33340,
  53147: 48580,
  53148: 48580,
  53149: 39540,
  53150: 33340,
  53151: 33340,
  53152: 16980,
  53153: 33340,
  53154: 33340,
  53156: 48020,
  53157: 48580,
  53158: 16980,
  53159: 16980,
  53167: 39540,
  53168: 16980,
  53170: 16980,
  53171: 16980,
  53172: 33340,
  53176: 48580,
  53177: 16980,
  53178: 48020,
  53179: 16980,
  53181: 16980,
  53182: 16980,
  53183: 33340,
  53184: 48580,
  53185: 39540,
  53186: 33340,
  53187: 33340,
  53188: 33340,
  53189: 33340,
  53190: 48580,
  53191: 48580,
  53192: 16980,
  53194: 16980,
  53195: 48580,
  53201: 33340,
  53202: 33340,
  53203: 33340,
  53204: 33340,
  53205: 33340,
  53206: 33340,
  53207: 33340,
  53208: 33340,
  53209: 33340,
  53210: 33340,
  53211: 33340,
  53212: 33340,
  53213: 33340,
  53214: 33340,
  53215: 33340,
  53216: 33340,
  53217: 33340,
  53218: 33340,
  53219: 33340,
  53220: 33340,
  53221: 33340,
  53222: 33340,
  53223: 33340,
  53224: 33340,
  53225: 33340,
  53226: 33340,
  53227: 33340,
  53228: 33340,
  53233: 33340,
  53234: 33340,
  53235: 33340,
  53237: 33340,
  53259: 33340,
  53263: 33340,
  53274: 33340,
  53278: 33340,
  53293: 33340,
  53295: 33340,
  53401: 39540,
  53402: 39540,
  53403: 16980,
  53404: 39540,
  53405: 39540,
  53406: 39540,
  53408: 39540,
  53501: 27500,
  53502: 31540,
  53503: 31540,
  53504: 99999,
  53505: 27500,
  53506: 31540,
  53507: 31540,
  53508: 31540,
  53510: 99999,
  53511: 27500,
  53512: 27500,
  53515: 31540,
  53516: 99999,
  53517: 31540,
  53518: 99999,
  53520: 31540,
  53521: 27500,
  53522: 31540,
  53523: 31540,
  53525: 48580,
  53526: 31540,
  53527: 31540,
  53528: 31540,
  53529: 31540,
  53530: 99999,
  53531: 31540,
  53532: 31540,
  53533: 31540,
  53534: 27500,
  53535: 31540,
  53536: 27500,
  53537: 27500,
  53538: 48020,
  53540: 99999,
  53541: 99999,
  53543: 38420,
  53544: 31540,
  53545: 27500,
  53546: 27500,
  53547: 27500,
  53548: 27500,
  53549: 48020,
  53550: 31540,
  53551: 48020,
  53553: 31540,
  53554: 31540,
  53555: 31540,
  53556: 12660,
  53557: 13180,
  53558: 31540,
  53559: 31540,
  53560: 31540,
  53561: 12660,
  53562: 31540,
  53563: 27500,
  53565: 31540,
  53566: 31540,
  53569: 31540,
  53570: 31540,
  53571: 31540,
  53572: 31540,
  53573: 99999,
  53574: 31540,
  53575: 31540,
  53576: 27500,
  53577: 12660,
  53578: 31540,
  53579: 13180,
  53580: 31540,
  53581: 99999,
  53582: 31540,
  53583: 31540,
  53584: 99999,
  53585: 27500,
  53586: 99999,
  53587: 99999,
  53588: 31540,
  53589: 31540,
  53590: 31540,
  53593: 31540,
  53594: 13180,
  53595: 31540,
  53596: 31540,
  53597: 31540,
  53598: 31540,
  53599: 99999,
  53701: 31540,
  53702: 31540,
  53703: 31540,
  53704: 31540,
  53705: 31540,
  53706: 31540,
  53707: 31540,
  53708: 31540,
  53711: 31540,
  53713: 31540,
  53714: 31540,
  53715: 31540,
  53716: 31540,
  53717: 31540,
  53718: 31540,
  53719: 31540,
  53725: 31540,
  53726: 31540,
  53744: 31540,
  53777: 31540,
  53783: 31540,
  53784: 31540,
  53788: 31540,
  53792: 31540,
  53801: 38420,
  53802: 38420,
  53803: 99999,
  53804: 38420,
  53805: 38420,
  53806: 38420,
  53807: 38420,
  53808: 38420,
  53809: 38420,
  53810: 38420,
  53811: 38420,
  53812: 38420,
  53813: 38420,
  53816: 38420,
  53817: 38420,
  53818: 38420,
  53820: 38420,
  53821: 38420,
  53824: 38420,
  53825: 38420,
  53826: 99999,
  53827: 38420,
  53901: 12660,
  53910: 99999,
  53911: 31540,
  53913: 31540,
  53916: 13180,
  53919: 22540,
  53920: 99999,
  53922: 13180,
  53923: 31540,
  53924: 12660,
  53925: 13180,
  53926: 31540,
  53928: 31540,
  53929: 99999,
  53930: 99999,
  53931: 22540,
  53932: 13180,
  53933: 13180,
  53934: 99999,
  53935: 31540,
  53936: 99999,
  53937: 99999,
  53939: 99999,
  53940: 12660,
  53941: 12660,
  53942: 12660,
  53943: 12660,
  53944: 12660,
  53946: 99999,
  53947: 99999,
  53948: 99999,
  53949: 99999,
  53950: 99999,
  53951: 12660,
  53952: 99999,
  53953: 99999,
  53954: 99999,
  53955: 31540,
  53956: 13180,
  53957: 31540,
  53958: 12660,
  53959: 12660,
  53960: 31540,
  53961: 12660,
  53962: 99999,
  53963: 22540,
  53964: 99999,
  53965: 31540,
  53968: 12660,
  53969: 31540,
  54001: 99999,
  54002: 33460,
  54003: 33460,
  54004: 99999,
  54005: 99999,
  54006: 99999,
  54007: 33460,
  54009: 99999,
  54011: 33460,
  54013: 32860,
  54014: 33460,
  54015: 33460,
  54016: 33460,
  54017: 99999,
  54020: 33460,
  54021: 33460,
  54022: 33460,
  54023: 33460,
  54024: 99999,
  54025: 33460,
  54026: 99999,
  54027: 32860,
  54028: 33460,
  54082: 33460,
  54101: 24580,
  54102: 31940,
  54103: 27020,
  54104: 24580,
  54106: 11540,
  54107: 43020,
  54110: 11540,
  54111: 43020,
  54112: 24580,
  54113: 11540,
  54114: 24580,
  54115: 11540,
  54119: 31940,
  54120: 31940,
  54121: 27020,
  54123: 11540,
  54124: 43020,
  54125: 31940,
  54126: 24580,
  54127: 43020,
  54128: 43020,
  54129: 11540,
  54130: 24580,
  54135: 43020,
  54136: 11540,
  54137: 24580,
  54138: 24580,
  54139: 24580,
  54140: 11540,
  54141: 24580,
  54143: 31940,
  54149: 24580,
  54150: 43020,
  54151: 31940,
  54152: 11540,
  54153: 31940,
  54154: 24580,
  54155: 24580,
  54156: 31940,
  54157: 31940,
  54159: 31940,
  54160: 11540,
  54161: 31940,
  54162: 24580,
  54165: 43020,
  54166: 43020,
  54169: 11540,
  54170: 43020,
  54171: 24580,
  54173: 24580,
  54174: 43020,
  54175: 24580,
  54177: 31940,
  54180: 24580,
  54201: 99999,
  54202: 99999,
  54204: 99999,
  54205: 99999,
  54207: 31820,
  54208: 31820,
  54209: 99999,
  54210: 99999,
  54211: 99999,
  54212: 99999,
  54213: 99999,
  54214: 31820,
  54215: 31820,
  54216: 24580,
  54217: 24580,
  54220: 31820,
  54226: 99999,
  54227: 24580,
  54228: 31820,
  54229: 24580,
  54230: 24580,
  54232: 31820,
  54234: 99999,
  54235: 99999,
  54240: 31820,
  54241: 24580,
  54245: 31820,
  54246: 99999,
  54247: 31820,
  54301: 24580,
  54302: 24580,
  54303: 24580,
  54304: 24580,
  54305: 24580,
  54307: 24580,
  54308: 24580,
  54311: 24580,
  54313: 24580,
  54324: 24580,
  54344: 24580,
  54401: 48140,
  54402: 48140,
  54403: 48140,
  54405: 99999,
  54406: 44620,
  54407: 44620,
  54408: 99999,
  54409: 43020,
  54410: 49220,
  54411: 99999,
  54412: 48140,
  54413: 99999,
  54414: 99999,
  54415: 49220,
  54416: 43020,
  54417: 48140,
  54418: 99999,
  54420: 49220,
  54421: 99999,
  54422: 99999,
  54423: 44620,
  54424: 99999,
  54425: 48140,
  54426: 48140,
  54427: 48140,
  54428: 99999,
  54429: 48140,
  54430: 99999,
  54433: 20740,
  54435: 48140,
  54436: 99999,
  54437: 99999,
  54440: 48140,
  54441: 49220,
  54442: 48140,
  54443: 49220,
  54446: 99999,
  54447: 99999,
  54448: 48140,
  54449: 49220,
  54450: 43020,
  54451: 48140,
  54452: 99999,
  54454: 48140,
  54455: 44620,
  54456: 99999,
  54457: 99999,
  54458: 44620,
  54459: 48140,
  54460: 99999,
  54462: 99999,
  54463: 99999,
  54464: 99999,
  54465: 99999,
  54466: 99999,
  54467: 44620,
  54469: 49220,
  54470: 99999,
  54471: 48140,
  54473: 44620,
  54474: 48140,
  54475: 44620,
  54476: 48140,
  54479: 48140,
  54480: 99999,
  54481: 44620,
  54482: 44620,
  54484: 48140,
  54485: 99999,
  54486: 43020,
  54487: 48140,
  54488: 99999,
  54489: 49220,
  54490: 99999,
  54491: 24580,
  54492: 44620,
  54493: 99999,
  54494: 44620,
  54495: 49220,
  54498: 99999,
  54499: 99999,
  54501: 48140,
  54511: 99999,
  54512: 99999,
  54513: 99999,
  54514: 99999,
  54515: 99999,
  54517: 99999,
  54519: 99999,
  54520: 99999,
  54521: 99999,
  54524: 99999,
  54525: 99999,
  54526: 99999,
  54527: 99999,
  54529: 99999,
  54530: 99999,
  54531: 99999,
  54532: 48140,
  54534: 99999,
  54536: 99999,
  54537: 99999,
  54538: 99999,
  54539: 99999,
  54540: 99999,
  54541: 99999,
  54542: 27020,
  54543: 99999,
  54545: 99999,
  54546: 99999,
  54547: 99999,
  54548: 99999,
  54550: 99999,
  54552: 99999,
  54554: 99999,
  54555: 99999,
  54556: 99999,
  54557: 99999,
  54558: 99999,
  54559: 99999,
  54560: 99999,
  54561: 99999,
  54562: 99999,
  54563: 99999,
  54564: 48140,
  54565: 99999,
  54566: 99999,
  54568: 99999,
  54601: 29100,
  54602: 29100,
  54603: 29100,
  54610: 99999,
  54611: 99999,
  54612: 99999,
  54613: 99999,
  54614: 99999,
  54615: 99999,
  54616: 99999,
  54618: 99999,
  54619: 29100,
  54620: 99999,
  54621: 99999,
  54622: 99999,
  54623: 99999,
  54624: 99999,
  54625: 99999,
  54626: 99999,
  54627: 99999,
  54628: 99999,
  54629: 99999,
  54630: 99999,
  54631: 99999,
  54632: 99999,
  54634: 99999,
  54635: 99999,
  54636: 29100,
  54637: 99999,
  54638: 99999,
  54639: 99999,
  54641: 99999,
  54642: 29100,
  54643: 99999,
  54644: 29100,
  54645: 99999,
  54646: 99999,
  54648: 99999,
  54649: 99999,
  54650: 29100,
  54651: 99999,
  54652: 99999,
  54653: 29100,
  54654: 99999,
  54655: 99999,
  54656: 99999,
  54657: 99999,
  54658: 29100,
  54659: 99999,
  54660: 99999,
  54661: 99999,
  54662: 99999,
  54664: 99999,
  54665: 99999,
  54666: 99999,
  54667: 99999,
  54669: 29100,
  54670: 99999,
  54701: 20740,
  54702: 20740,
  54703: 32860,
  54720: 20740,
  54721: 32860,
  54722: 20740,
  54723: 33460,
  54724: 20740,
  54725: 32860,
  54726: 20740,
  54727: 20740,
  54728: 99999,
  54729: 20740,
  54730: 20740,
  54731: 99999,
  54732: 20740,
  54733: 99999,
  54734: 33460,
  54735: 32860,
  54736: 99999,
  54737: 32860,
  54738: 20740,
  54739: 20740,
  54740: 32860,
  54741: 99999,
  54742: 20740,
  54743: 99999,
  54745: 99999,
  54746: 99999,
  54747: 99999,
  54748: 20740,
  54749: 32860,
  54750: 33460,
  54751: 32860,
  54754: 99999,
  54755: 20740,
  54756: 99999,
  54757: 32860,
  54758: 99999,
  54759: 33460,
  54760: 99999,
  54761: 32860,
  54762: 32860,
  54763: 99999,
  54764: 32860,
  54765: 32860,
  54766: 99999,
  54767: 32860,
  54768: 99999,
  54769: 99999,
  54770: 20740,
  54771: 99999,
  54772: 32860,
  54773: 99999,
  54774: 20740,
  54801: 99999,
  54805: 99999,
  54806: 99999,
  54810: 99999,
  54812: 99999,
  54813: 99999,
  54814: 99999,
  54816: 99999,
  54817: 99999,
  54819: 99999,
  54820: 99999,
  54821: 99999,
  54822: 99999,
  54824: 99999,
  54826: 99999,
  54827: 99999,
  54828: 99999,
  54829: 99999,
  54830: 99999,
  54832: 99999,
  54835: 99999,
  54836: 20260,
  54837: 99999,
  54838: 99999,
  54839: 99999,
  54840: 99999,
  54841: 99999,
  54842: 20260,
  54843: 99999,
  54844: 99999,
  54845: 99999,
  54846: 99999,
  54847: 99999,
  54848: 99999,
  54849: 20260,
  54850: 99999,
  54853: 99999,
  54854: 20260,
  54855: 99999,
  54856: 99999,
  54857: 99999,
  54858: 99999,
  54859: 20260,
  54861: 99999,
  54862: 99999,
  54864: 20260,
  54865: 99999,
  54867: 99999,
  54868: 99999,
  54870: 99999,
  54871: 99999,
  54872: 99999,
  54873: 20260,
  54874: 20260,
  54875: 99999,
  54876: 99999,
  54880: 20260,
  54888: 99999,
  54889: 99999,
  54890: 20260,
  54891: 99999,
  54893: 99999,
  54895: 99999,
  54896: 99999,
  54901: 36780,
  54902: 36780,
  54903: 36780,
  54904: 22540,
  54906: 36780,
  54909: 44620,
  54911: 11540,
  54912: 11540,
  54913: 11540,
  54914: 36780,
  54915: 36780,
  54919: 11540,
  54921: 44620,
  54922: 99999,
  54923: 36780,
  54926: 99999,
  54927: 36780,
  54928: 43020,
  54929: 11540,
  54930: 99999,
  54931: 11540,
  54932: 22540,
  54933: 99999,
  54935: 22540,
  54936: 22540,
  54937: 22540,
  54940: 36780,
  54941: 99999,
  54942: 11540,
  54943: 99999,
  54944: 36780,
  54945: 99999,
  54946: 99999,
  54947: 36780,
  54948: 43020,
  54949: 99999,
  54950: 43020,
  54952: 11540,
  54956: 36780,
  54957: 36780,
  54960: 99999,
  54961: 99999,
  54962: 99999,
  54963: 99999,
  54964: 22540,
  54965: 99999,
  54966: 44620,
  54967: 99999,
  54968: 99999,
  54969: 99999,
  54970: 99999,
  54971: 22540,
  54974: 22540,
  54976: 99999,
  54977: 99999,
  54978: 43020,
  54979: 22540,
  54980: 36780,
  54981: 99999,
  54982: 99999,
  54983: 99999,
  54984: 44620,
  54985: 36780,
  54986: 99999,
  55001: 33460,
  55003: 33460,
  55005: 33460,
  55006: 99999,
  55007: 99999,
  55008: 33460,
  55009: 33460,
  55010: 33460,
  55011: 33460,
  55012: 33460,
  55013: 33460,
  55014: 33460,
  55016: 33460,
  55017: 33460,
  55018: 22060,
  55019: 22060,
  55020: 22060,
  55021: 22060,
  55024: 33460,
  55025: 33460,
  55026: 39860,
  55027: 40340,
  55029: 33460,
  55030: 99999,
  55031: 33460,
  55032: 33460,
  55033: 39860,
  55036: 99999,
  55037: 99999,
  55038: 33460,
  55040: 33460,
  55041: 40340,
  55042: 33460,
  55043: 33460,
  55044: 33460,
  55045: 33460,
  55046: 22060,
  55047: 33460,
  55049: 22060,
  55051: 33460,
  55052: 22060,
  55053: 22060,
  55054: 33460,
  55055: 33460,
  55056: 33460,
  55057: 22060,
  55060: 36940,
  55063: 99999,
  55065: 39860,
  55066: 39860,
  55068: 33460,
  55069: 33460,
  55070: 33460,
  55071: 33460,
  55072: 99999,
  55073: 33460,
  55074: 33460,
  55075: 33460,
  55076: 33460,
  55077: 33460,
  55079: 33460,
  55080: 33460,
  55082: 33460,
  55083: 33460,
  55084: 33460,
  55085: 33460,
  55087: 22060,
  55088: 33460,
  55089: 39860,
  55090: 33460,
  55092: 33460,
  55101: 33460,
  55102: 33460,
  55103: 33460,
  55104: 33460,
  55105: 33460,
  55106: 33460,
  55107: 33460,
  55108: 33460,
  55109: 33460,
  55110: 33460,
  55111: 33460,
  55112: 33460,
  55113: 33460,
  55114: 33460,
  55115: 33460,
  55116: 33460,
  55117: 33460,
  55118: 33460,
  55119: 33460,
  55120: 33460,
  55121: 33460,
  55122: 33460,
  55123: 33460,
  55124: 33460,
  55125: 33460,
  55126: 33460,
  55127: 33460,
  55128: 33460,
  55129: 33460,
  55130: 33460,
  55144: 33460,
  55146: 33460,
  55150: 33460,
  55155: 33460,
  55301: 33460,
  55302: 33460,
  55303: 33460,
  55304: 33460,
  55305: 33460,
  55306: 33460,
  55307: 99999,
  55308: 33460,
  55309: 33460,
  55310: 99999,
  55311: 33460,
  55312: 99999,
  55313: 33460,
  55314: 99999,
  55315: 33460,
  55316: 33460,
  55317: 33460,
  55318: 33460,
  55319: 33460,
  55320: 33460,
  55321: 33460,
  55322: 33460,
  55323: 33460,
  55324: 99999,
  55325: 99999,
  55327: 33460,
  55328: 33460,
  55329: 41060,
  55330: 33460,
  55331: 33460,
  55332: 99999,
  55333: 99999,
  55334: 31860,
  55335: 26780,
  55336: 26780,
  55337: 33460,
  55338: 26780,
  55339: 99999,
  55340: 33460,
  55341: 33460,
  55342: 99999,
  55343: 33460,
  55344: 33460,
  55345: 33460,
  55346: 33460,
  55347: 33460,
  55349: 33460,
  55350: 99999,
  55352: 33460,
  55353: 41060,
  55354: 26780,
  55355: 26780,
  55356: 33460,
  55357: 33460,
  55358: 33460,
  55359: 33460,
  55360: 33460,
  55361: 33460,
  55362: 33460,
  55363: 33460,
  55364: 33460,
  55366: 99999,
  55367: 33460,
  55368: 33460,
  55369: 33460,
  55370: 26780,
  55371: 41060,
  55372: 33460,
  55373: 33460,
  55374: 33460,
  55375: 33460,
  55376: 33460,
  55377: 33460,
  55378: 33460,
  55379: 33460,
  55381: 26780,
  55382: 33460,
  55384: 33460,
  55385: 99999,
  55386: 33460,
  55387: 33460,
  55388: 33460,
  55389: 41060,
  55390: 33460,
  55391: 33460,
  55395: 33460,
  55396: 26780,
  55397: 33460,
  55398: 33460,
  55401: 33460,
  55402: 33460,
  55403: 33460,
  55404: 33460,
  55405: 33460,
  55406: 33460,
  55407: 33460,
  55408: 33460,
  55409: 33460,
  55410: 33460,
  55411: 33460,
  55412: 33460,
  55413: 33460,
  55414: 33460,
  55415: 33460,
  55416: 33460,
  55417: 33460,
  55418: 33460,
  55419: 33460,
  55420: 33460,
  55421: 33460,
  55422: 33460,
  55423: 33460,
  55424: 33460,
  55425: 33460,
  55426: 33460,
  55427: 33460,
  55428: 33460,
  55429: 33460,
  55430: 33460,
  55431: 33460,
  55432: 33460,
  55433: 33460,
  55434: 33460,
  55435: 33460,
  55436: 33460,
  55437: 33460,
  55438: 33460,
  55439: 33460,
  55440: 33460,
  55441: 33460,
  55442: 33460,
  55443: 33460,
  55444: 33460,
  55445: 33460,
  55446: 33460,
  55447: 33460,
  55448: 33460,
  55449: 33460,
  55450: 33460,
  55454: 33460,
  55455: 33460,
  55458: 33460,
  55467: 33460,
  55474: 33460,
  55479: 33460,
  55480: 33460,
  55487: 33460,
  55488: 33460,
  55601: 20260,
  55602: 20260,
  55603: 20260,
  55604: 99999,
  55605: 99999,
  55606: 99999,
  55607: 20260,
  55609: 20260,
  55612: 99999,
  55613: 99999,
  55614: 20260,
  55615: 99999,
  55616: 20260,
  55702: 20260,
  55703: 20260,
  55704: 99999,
  55705: 20260,
  55706: 20260,
  55707: 20260,
  55708: 20260,
  55709: 24330,
  55710: 20260,
  55711: 20260,
  55712: 99999,
  55713: 20260,
  55716: 24330,
  55717: 20260,
  55718: 20260,
  55719: 20260,
  55720: 20260,
  55721: 24330,
  55722: 24330,
  55723: 24330,
  55724: 20260,
  55725: 20260,
  55726: 20260,
  55731: 20260,
  55732: 20260,
  55733: 20260,
  55734: 20260,
  55735: 99999,
  55736: 24330,
  55738: 20260,
  55741: 20260,
  55742: 24330,
  55744: 24330,
  55745: 24330,
  55746: 24330,
  55748: 24330,
  55749: 20260,
  55750: 20260,
  55751: 20260,
  55752: 24330,
  55753: 24330,
  55756: 20260,
  55757: 20260,
  55758: 20260,
  55760: 99999,
  55763: 20260,
  55764: 24330,
  55765: 20260,
  55767: 99999,
  55768: 20260,
  55769: 20260,
  55771: 24330,
  55772: 99999,
  55775: 24330,
  55779: 20260,
  55780: 20260,
  55781: 24330,
  55782: 20260,
  55783: 20260,
  55784: 24330,
  55785: 14660,
  55786: 24330,
  55787: 20260,
  55790: 20260,
  55791: 20260,
  55792: 20260,
  55793: 24330,
  55795: 99999,
  55796: 20260,
  55797: 20260,
  55798: 20260,
  55802: 20260,
  55803: 20260,
  55804: 20260,
  55805: 20260,
  55806: 20260,
  55807: 20260,
  55808: 20260,
  55810: 20260,
  55811: 20260,
  55812: 20260,
  55816: 20260,
  55901: 40340,
  55902: 40340,
  55903: 40340,
  55904: 40340,
  55905: 40340,
  55906: 40340,
  55909: 12380,
  55910: 40340,
  55912: 10660,
  55917: 40340,
  55918: 12380,
  55919: 29100,
  55920: 40340,
  55921: 29100,
  55922: 40340,
  55923: 40340,
  55924: 40340,
  55925: 49100,
  55926: 12380,
  55927: 40340,
  55929: 40340,
  55931: 29100,
  55932: 40340,
  55933: 12380,
  55934: 40340,
  55935: 40340,
  55936: 12380,
  55939: 40340,
  55940: 40340,
  55941: 29100,
  55943: 29100,
  55944: 40340,
  55945: 40340,
  55946: 36940,
  55947: 49100,
  55949: 40340,
  55950: 12380,
  55951: 40340,
  55952: 49100,
  55953: 12380,
  55954: 29100,
  55955: 40340,
  55956: 39860,
  55957: 40340,
  55959: 49100,
  55960: 40340,
  55961: 40340,
  55962: 49100,
  55963: 39860,
  55964: 49100,
  55965: 40340,
  55967: 12380,
  55968: 40340,
  55969: 49100,
  55970: 12380,
  55971: 40340,
  55972: 49100,
  55973: 12380,
  55974: 40340,
  55975: 12380,
  55976: 40340,
  55977: 12380,
  55979: 49100,
  55981: 40340,
  55982: 40340,
  55983: 39860,
  55985: 36940,
  55987: 49100,
  55990: 40340,
  55991: 40340,
  55992: 39860,
  56001: 31860,
  56002: 31860,
  56003: 31860,
  56007: 10660,
  56009: 10660,
  56010: 31860,
  56011: 33460,
  56013: 99999,
  56014: 99999,
  56016: 10660,
  56017: 33460,
  56019: 99999,
  56020: 10660,
  56021: 31860,
  56022: 99999,
  56023: 99999,
  56024: 31860,
  56025: 99999,
  56026: 99999,
  56027: 21860,
  56028: 33460,
  56029: 10660,
  56031: 21860,
  56032: 10660,
  56033: 99999,
  56034: 31860,
  56035: 10660,
  56036: 10660,
  56037: 31860,
  56039: 99999,
  56041: 31860,
  56042: 36940,
  56043: 10660,
  56044: 33460,
  56045: 10660,
  56046: 36940,
  56047: 99999,
  56048: 31860,
  56050: 33460,
  56051: 10660,
  56052: 22060,
  56054: 99999,
  56055: 31860,
  56056: 99999,
  56057: 33460,
  56058: 99999,
  56060: 31860,
  56062: 35580,
  56063: 99999,
  56065: 31860,
  56068: 31860,
  56069: 22060,
  56071: 33460,
  56072: 10660,
  56073: 31860,
  56074: 31860,
  56075: 21860,
  56078: 31860,
  56080: 31860,
  56081: 99999,
  56082: 31860,
  56083: 99999,
  56084: 35580,
  56085: 99999,
  56087: 99999,
  56088: 99999,
  56089: 10660,
  56090: 31860,
  56091: 99999,
  56093: 36940,
  56096: 22060,
  56097: 10660,
  56098: 21860,
  56101: 99999,
  56110: 49380,
  56111: 99999,
  56113: 99999,
  56114: 99999,
  56115: 32140,
  56116: 99999,
  56117: 49380,
  56118: 99999,
  56119: 99999,
  56120: 99999,
  56121: 21860,
  56122: 99999,
  56123: 99999,
  56125: 99999,
  56127: 99999,
  56128: 49380,
  56129: 49380,
  56131: 49380,
  56132: 32140,
  56134: 99999,
  56136: 99999,
  56137: 99999,
  56138: 99999,
  56139: 99999,
  56141: 49380,
  56142: 99999,
  56143: 99999,
  56144: 99999,
  56145: 99999,
  56146: 99999,
  56147: 99999,
  56149: 99999,
  56150: 99999,
  56151: 99999,
  56152: 99999,
  56153: 49380,
  56155: 49380,
  56156: 99999,
  56157: 32140,
  56158: 49380,
  56159: 99999,
  56160: 99999,
  56161: 99999,
  56162: 21860,
  56164: 99999,
  56165: 49380,
  56166: 99999,
  56167: 99999,
  56168: 49380,
  56169: 32140,
  56170: 99999,
  56171: 21860,
  56172: 99999,
  56173: 99999,
  56174: 99999,
  56175: 99999,
  56176: 21860,
  56178: 32140,
  56180: 99999,
  56181: 21860,
  56183: 99999,
  56185: 99999,
  56186: 99999,
  56187: 49380,
  56201: 48820,
  56207: 99999,
  56208: 99999,
  56209: 48820,
  56210: 99999,
  56211: 99999,
  56212: 99999,
  56214: 99999,
  56215: 99999,
  56216: 48820,
  56218: 99999,
  56219: 99999,
  56220: 99999,
  56221: 99999,
  56222: 48820,
  56223: 99999,
  56224: 99999,
  56225: 99999,
  56226: 99999,
  56227: 99999,
  56228: 99999,
  56229: 32140,
  56230: 99999,
  56231: 99999,
  56232: 99999,
  56235: 99999,
  56236: 99999,
  56237: 99999,
  56239: 32140,
  56240: 99999,
  56241: 99999,
  56243: 48820,
  56244: 99999,
  56245: 99999,
  56248: 99999,
  56249: 99999,
  56251: 48820,
  56252: 99999,
  56253: 48820,
  56255: 99999,
  56256: 99999,
  56257: 99999,
  56258: 99999,
  56260: 99999,
  56262: 99999,
  56263: 99999,
  56264: 32140,
  56265: 99999,
  56266: 35580,
  56267: 99999,
  56270: 99999,
  56271: 99999,
  56273: 48820,
  56274: 99999,
  56276: 99999,
  56277: 48820,
  56278: 99999,
  56279: 48820,
  56280: 99999,
  56281: 48820,
  56282: 99999,
  56283: 99999,
  56284: 48820,
  56285: 99999,
  56287: 99999,
  56288: 48820,
  56289: 99999,
  56291: 32140,
  56292: 99999,
  56293: 99999,
  56294: 99999,
  56295: 99999,
  56296: 99999,
  56297: 32140,
  56301: 41060,
  56302: 41060,
  56303: 41060,
  56304: 41060,
  56307: 41060,
  56308: 99999,
  56309: 10820,
  56310: 41060,
  56311: 99999,
  56312: 41060,
  56313: 33460,
  56314: 41060,
  56315: 22260,
  56316: 41060,
  56318: 99999,
  56319: 10820,
  56320: 41060,
  56321: 41060,
  56323: 99999,
  56324: 22260,
  56325: 41060,
  56326: 99999,
  56327: 10820,
  56328: 99999,
  56329: 41060,
  56330: 41060,
  56331: 41060,
  56332: 10820,
  56333: 41060,
  56334: 10820,
  56335: 41060,
  56336: 41060,
  56338: 33460,
  56339: 10820,
  56340: 99999,
  56341: 10820,
  56342: 99999,
  56343: 99999,
  56344: 99999,
  56345: 99999,
  56347: 99999,
  56349: 99999,
  56350: 99999,
  56352: 41060,
  56353: 33460,
  56354: 22260,
  56355: 10820,
  56356: 41060,
  56357: 33460,
  56358: 33460,
  56359: 99999,
  56360: 10820,
  56361: 22260,
  56362: 48820,
  56363: 33460,
  56364: 99999,
  56367: 99999,
  56368: 41060,
  56369: 41060,
  56371: 41060,
  56373: 99999,
  56374: 41060,
  56375: 41060,
  56376: 41060,
  56377: 41060,
  56378: 99999,
  56379: 33460,
  56381: 99999,
  56382: 99999,
  56384: 99999,
  56385: 10820,
  56386: 33460,
  56387: 41060,
  56388: 41060,
  56389: 99999,
  56393: 41060,
  56395: 41060,
  56398: 41060,
  56399: 41060,
  56401: 14660,
  56425: 14660,
  56431: 99999,
  56433: 99999,
  56434: 99999,
  56435: 14660,
  56436: 99999,
  56437: 22260,
  56438: 99999,
  56440: 99999,
  56441: 14660,
  56442: 14660,
  56443: 99999,
  56444: 99999,
  56446: 22260,
  56447: 14660,
  56448: 14660,
  56449: 99999,
  56450: 33460,
  56452: 14660,
  56453: 99999,
  56455: 14660,
  56458: 99999,
  56461: 14660,
  56464: 14660,
  56465: 14660,
  56466: 99999,
  56467: 99999,
  56468: 14660,
  56469: 99999,
  56470: 99999,
  56472: 14660,
  56473: 14660,
  56474: 14660,
  56475: 99999,
  56477: 22260,
  56479: 14660,
  56481: 99999,
  56482: 22260,
  56484: 14660,
  56501: 22260,
  56502: 99999,
  56510: 99999,
  56511: 99999,
  56514: 47420,
  56515: 22260,
  56516: 99999,
  56517: 24220,
  56518: 22260,
  56519: 99999,
  56520: 47420,
  56521: 99999,
  56522: 22260,
  56523: 24220,
  56524: 22260,
  56525: 22020,
  56527: 22260,
  56528: 22260,
  56529: 22020,
  56531: 22260,
  56533: 22260,
  56534: 22260,
  56535: 99999,
  56536: 22020,
  56537: 22260,
  56538: 22260,
  56540: 99999,
  56541: 99999,
  56542: 24220,
  56543: 22260,
  56544: 99999,
  56545: 99999,
  56546: 99999,
  56547: 22020,
  56548: 99999,
  56549: 22020,
  56550: 99999,
  56551: 22260,
  56552: 22020,
  56553: 47420,
  56554: 22020,
  56556: 24220,
  56557: 99999,
  56560: 47420,
  56561: 22020,
  56562: 22020,
  56563: 22020,
  56565: 47420,
  56566: 99999,
  56567: 22260,
  56568: 24220,
  56569: 99999,
  56570: 99999,
  56571: 22260,
  56572: 47420,
  56573: 22260,
  56574: 22020,
  56575: 99999,
  56576: 22260,
  56577: 99999,
  56578: 99999,
  56579: 47420,
  56580: 22020,
  56581: 99999,
  56583: 47420,
  56584: 99999,
  56585: 99999,
  56586: 22260,
  56587: 22260,
  56588: 22260,
  56589: 99999,
  56590: 99999,
  56591: 99999,
  56592: 99999,
  56593: 99999,
  56594: 22020,
  56601: 99999,
  56619: 13420,
  56621: 99999,
  56623: 99999,
  56626: 14660,
  56627: 99999,
  56628: 24330,
  56629: 99999,
  56630: 24330,
  56631: 24330,
  56633: 13420,
  56634: 99999,
  56636: 24330,
  56637: 24330,
  56639: 99999,
  56641: 14660,
  56644: 99999,
  56646: 24220,
  56647: 13420,
  56649: 99999,
  56650: 13420,
  56651: 24220,
  56652: 13420,
  56653: 99999,
  56654: 99999,
  56655: 14660,
  56657: 24330,
  56658: 99999,
  56659: 24330,
  56660: 99999,
  56661: 13420,
  56662: 14660,
  56663: 13420,
  56666: 13420,
  56667: 13420,
  56668: 99999,
  56669: 99999,
  56670: 13420,
  56671: 13420,
  56672: 14660,
  56673: 99999,
  56676: 13420,
  56678: 99999,
  56679: 99999,
  56680: 24330,
  56681: 24330,
  56683: 13420,
  56684: 24220,
  56685: 13420,
  56686: 99999,
  56688: 24330,
  56701: 99999,
  56710: 99999,
  56711: 99999,
  56713: 99999,
  56714: 99999,
  56715: 99999,
  56716: 99999,
  56720: 99999,
  56721: 24220,
  56722: 24220,
  56723: 24220,
  56724: 99999,
  56725: 99999,
  56726: 99999,
  56727: 99999,
  56728: 99999,
  56729: 99999,
  56731: 99999,
  56732: 99999,
  56733: 99999,
  56734: 99999,
  56735: 99999,
  56736: 99999,
  56737: 99999,
  56738: 99999,
  56740: 99999,
  56741: 99999,
  56742: 99999,
  56744: 99999,
  56748: 99999,
  56750: 24220,
  56751: 99999,
  56754: 99999,
  56755: 99999,
  56756: 99999,
  56757: 99999,
  56758: 99999,
  56759: 99999,
  56760: 99999,
  56761: 99999,
  56762: 24220,
  56763: 99999,
  56998: 47900,
  57001: 43620,
  57002: 15100,
  57003: 43620,
  57004: 46820,
  57005: 43620,
  57006: 15100,
  57007: 15100,
  57010: 43580,
  57012: 43620,
  57013: 43620,
  57014: 43620,
  57015: 43620,
  57016: 99999,
  57017: 99999,
  57018: 43620,
  57020: 43620,
  57021: 43620,
  57022: 43620,
  57024: 99999,
  57025: 43580,
  57026: 15100,
  57027: 43620,
  57028: 99999,
  57029: 43620,
  57030: 43620,
  57031: 46820,
  57032: 43620,
  57033: 43620,
  57034: 43620,
  57035: 43620,
  57036: 43620,
  57037: 49460,
  57038: 43580,
  57039: 43620,
  57040: 49460,
  57041: 43620,
  57042: 43620,
  57043: 99999,
  57045: 49460,
  57046: 49460,
  57047: 43620,
  57048: 99999,
  57049: 43580,
  57050: 99999,
  57051: 99999,
  57052: 99999,
  57053: 43620,
  57054: 99999,
  57055: 43620,
  57057: 99999,
  57058: 99999,
  57059: 99999,
  57061: 15100,
  57062: 99999,
  57063: 49460,
  57064: 43620,
  57065: 99999,
  57066: 99999,
  57067: 49460,
  57068: 43620,
  57069: 46820,
  57070: 49460,
  57071: 15100,
  57072: 49460,
  57073: 46820,
  57075: 99999,
  57076: 99999,
  57077: 43620,
  57078: 49460,
  57101: 43620,
  57103: 43620,
  57104: 43620,
  57105: 43620,
  57106: 43620,
  57107: 43620,
  57108: 43620,
  57109: 43620,
  57110: 43620,
  57118: 43620,
  57186: 43620,
  57193: 43620,
  57197: 43620,
  57198: 43620,
  57201: 47980,
  57212: 99999,
  57213: 15100,
  57214: 99999,
  57216: 99999,
  57217: 99999,
  57218: 99999,
  57219: 99999,
  57220: 15100,
  57221: 99999,
  57223: 99999,
  57224: 99999,
  57225: 99999,
  57226: 47980,
  57227: 99999,
  57231: 99999,
  57232: 99999,
  57233: 47980,
  57234: 99999,
  57235: 99999,
  57236: 99999,
  57237: 99999,
  57238: 47980,
  57239: 99999,
  57241: 47980,
  57242: 47980,
  57243: 47980,
  57245: 47980,
  57246: 99999,
  57247: 99999,
  57248: 99999,
  57249: 99999,
  57251: 99999,
  57252: 99999,
  57255: 99999,
  57256: 99999,
  57257: 99999,
  57258: 99999,
  57259: 99999,
  57260: 99999,
  57261: 99999,
  57262: 99999,
  57263: 99999,
  57264: 47980,
  57265: 99999,
  57266: 99999,
  57268: 99999,
  57269: 99999,
  57270: 99999,
  57271: 99999,
  57272: 47980,
  57273: 99999,
  57274: 99999,
  57276: 15100,
  57278: 99999,
  57279: 99999,
  57301: 33580,
  57311: 99999,
  57312: 99999,
  57313: 99999,
  57314: 99999,
  57315: 99999,
  57317: 99999,
  57319: 33580,
  57321: 33580,
  57322: 99999,
  57323: 99999,
  57324: 26700,
  57325: 99999,
  57328: 99999,
  57329: 99999,
  57330: 99999,
  57331: 99999,
  57332: 99999,
  57334: 99999,
  57335: 99999,
  57337: 99999,
  57339: 99999,
  57340: 99999,
  57341: 26700,
  57342: 99999,
  57344: 99999,
  57345: 99999,
  57346: 99999,
  57348: 99999,
  57349: 99999,
  57350: 99999,
  57353: 99999,
  57354: 99999,
  57355: 99999,
  57356: 99999,
  57358: 99999,
  57359: 99999,
  57361: 99999,
  57362: 99999,
  57363: 99999,
  57364: 99999,
  57365: 99999,
  57366: 99999,
  57367: 99999,
  57368: 99999,
  57369: 99999,
  57370: 99999,
  57371: 99999,
  57373: 99999,
  57374: 43620,
  57375: 33580,
  57376: 99999,
  57379: 26700,
  57380: 99999,
  57381: 99999,
  57382: 26700,
  57383: 99999,
  57384: 26700,
  57385: 26700,
  57386: 99999,
  57399: 26700,
  57401: 10100,
  57402: 10100,
  57420: 99999,
  57421: 99999,
  57422: 10100,
  57424: 99999,
  57426: 10100,
  57427: 10100,
  57428: 10100,
  57429: 99999,
  57430: 99999,
  57432: 99999,
  57433: 10100,
  57434: 10100,
  57435: 99999,
  57436: 99999,
  57437: 99999,
  57438: 99999,
  57439: 10100,
  57440: 99999,
  57441: 10100,
  57442: 99999,
  57445: 99999,
  57446: 10100,
  57448: 10100,
  57449: 10100,
  57450: 99999,
  57451: 10100,
  57452: 99999,
  57454: 99999,
  57455: 99999,
  57456: 10100,
  57457: 99999,
  57460: 10100,
  57461: 10100,
  57465: 99999,
  57466: 99999,
  57467: 99999,
  57468: 99999,
  57469: 99999,
  57470: 99999,
  57471: 10100,
  57472: 99999,
  57473: 99999,
  57474: 99999,
  57475: 99999,
  57476: 99999,
  57477: 99999,
  57479: 10100,
  57481: 99999,
  57501: 38180,
  57520: 99999,
  57521: 99999,
  57522: 38180,
  57523: 99999,
  57528: 99999,
  57529: 99999,
  57531: 99999,
  57532: 99999,
  57533: 99999,
  57534: 99999,
  57536: 99999,
  57537: 38180,
  57538: 99999,
  57540: 99999,
  57541: 99999,
  57543: 99999,
  57544: 99999,
  57547: 99999,
  57548: 99999,
  57551: 99999,
  57552: 99999,
  57553: 39660,
  57555: 99999,
  57559: 99999,
  57560: 99999,
  57562: 99999,
  57563: 99999,
  57564: 38180,
  57566: 99999,
  57567: 99999,
  57568: 99999,
  57569: 99999,
  57570: 99999,
  57571: 99999,
  57572: 99999,
  57574: 99999,
  57576: 99999,
  57577: 99999,
  57579: 99999,
  57580: 99999,
  57584: 99999,
  57585: 99999,
  57601: 99999,
  57620: 99999,
  57621: 99999,
  57622: 99999,
  57623: 99999,
  57625: 99999,
  57626: 39660,
  57630: 99999,
  57631: 99999,
  57632: 99999,
  57633: 99999,
  57634: 99999,
  57636: 99999,
  57638: 99999,
  57639: 99999,
  57640: 99999,
  57641: 99999,
  57642: 99999,
  57644: 99999,
  57645: 99999,
  57646: 99999,
  57648: 99999,
  57649: 99999,
  57650: 99999,
  57651: 99999,
  57652: 99999,
  57656: 99999,
  57657: 99999,
  57658: 99999,
  57659: 99999,
  57660: 99999,
  57661: 99999,
  57701: 39660,
  57702: 39660,
  57703: 39660,
  57706: 39660,
  57709: 39660,
  57714: 99999,
  57716: 99999,
  57717: 43940,
  57718: 39660,
  57719: 39660,
  57720: 99999,
  57722: 99999,
  57724: 99999,
  57725: 39660,
  57730: 39660,
  57732: 43940,
  57735: 99999,
  57737: 39660,
  57738: 99999,
  57741: 39660,
  57744: 39660,
  57745: 39660,
  57747: 99999,
  57748: 39660,
  57750: 99999,
  57751: 99999,
  57752: 99999,
  57754: 39660,
  57755: 99999,
  57756: 99999,
  57758: 39660,
  57759: 43940,
  57760: 99999,
  57761: 39660,
  57762: 99999,
  57763: 99999,
  57764: 99999,
  57766: 99999,
  57767: 39660,
  57769: 39660,
  57770: 99999,
  57772: 99999,
  57773: 99999,
  57775: 99999,
  57776: 99999,
  57779: 43940,
  57780: 39660,
  57782: 99999,
  57783: 43940,
  57785: 39660,
  57787: 39660,
  57788: 99999,
  57790: 39660,
  57791: 39660,
  57792: 39660,
  57793: 39660,
  57794: 99999,
  57799: 43940,
  58001: 47420,
  58002: 22020,
  58004: 22020,
  58005: 22020,
  58006: 22020,
  58007: 22020,
  58008: 47420,
  58009: 99999,
  58011: 22020,
  58012: 22020,
  58013: 99999,
  58015: 22020,
  58016: 99999,
  58017: 99999,
  58018: 47420,
  58021: 22020,
  58027: 99999,
  58029: 22020,
  58030: 47420,
  58031: 99999,
  58032: 99999,
  58033: 99999,
  58035: 99999,
  58036: 22020,
  58038: 99999,
  58040: 99999,
  58041: 47420,
  58042: 22020,
  58043: 99999,
  58045: 99999,
  58046: 22020,
  58047: 47420,
  58048: 99999,
  58049: 99999,
  58051: 47420,
  58052: 22020,
  58053: 47420,
  58054: 99999,
  58056: 99999,
  58057: 47420,
  58058: 47420,
  58059: 22020,
  58060: 47420,
  58061: 47420,
  58062: 99999,
  58063: 99999,
  58064: 99999,
  58065: 99999,
  58067: 99999,
  58068: 99999,
  58069: 99999,
  58071: 99999,
  58072: 99999,
  58075: 47420,
  58076: 47420,
  58077: 47420,
  58078: 22020,
  58079: 22020,
  58081: 47420,
  58102: 22020,
  58103: 22020,
  58104: 22020,
  58105: 22020,
  58106: 22020,
  58107: 22020,
  58108: 22020,
  58109: 22020,
  58121: 22020,
  58122: 22020,
  58124: 22020,
  58125: 22020,
  58126: 22020,
  58201: 24220,
  58202: 24220,
  58203: 24220,
  58204: 24220,
  58205: 24220,
  58206: 24220,
  58207: 24220,
  58208: 24220,
  58210: 99999,
  58212: 24220,
  58214: 24220,
  58216: 99999,
  58218: 99999,
  58219: 99999,
  58220: 99999,
  58222: 99999,
  58223: 99999,
  58224: 99999,
  58225: 99999,
  58227: 99999,
  58228: 24220,
  58229: 99999,
  58230: 99999,
  58231: 99999,
  58233: 24220,
  58235: 24220,
  58236: 99999,
  58237: 99999,
  58238: 99999,
  58239: 99999,
  58240: 24220,
  58241: 99999,
  58243: 99999,
  58244: 24220,
  58249: 99999,
  58250: 99999,
  58251: 24220,
  58254: 99999,
  58255: 99999,
  58256: 24220,
  58257: 99999,
  58258: 24220,
  58259: 99999,
  58260: 99999,
  58261: 24220,
  58262: 99999,
  58265: 99999,
  58266: 24220,
  58267: 24220,
  58269: 99999,
  58270: 99999,
  58271: 99999,
  58272: 99999,
  58273: 99999,
  58274: 99999,
  58275: 24220,
  58276: 99999,
  58277: 99999,
  58278: 24220,
  58281: 99999,
  58282: 99999,
  58301: 99999,
  58310: 99999,
  58311: 99999,
  58313: 99999,
  58316: 99999,
  58317: 99999,
  58318: 99999,
  58321: 99999,
  58323: 99999,
  58324: 99999,
  58325: 99999,
  58327: 99999,
  58329: 99999,
  58330: 99999,
  58331: 99999,
  58332: 99999,
  58335: 99999,
  58338: 99999,
  58339: 99999,
  58341: 99999,
  58343: 99999,
  58344: 99999,
  58345: 99999,
  58346: 99999,
  58348: 99999,
  58351: 99999,
  58352: 99999,
  58353: 99999,
  58355: 99999,
  58356: 99999,
  58357: 99999,
  58361: 99999,
  58362: 99999,
  58363: 99999,
  58365: 99999,
  58366: 99999,
  58367: 99999,
  58368: 33500,
  58369: 99999,
  58370: 99999,
  58372: 99999,
  58374: 99999,
  58377: 99999,
  58379: 99999,
  58380: 99999,
  58381: 99999,
  58382: 99999,
  58384: 33500,
  58385: 99999,
  58386: 99999,
  58401: 27420,
  58402: 27420,
  58405: 27420,
  58413: 99999,
  58415: 99999,
  58416: 99999,
  58418: 99999,
  58420: 27420,
  58421: 27420,
  58422: 99999,
  58423: 99999,
  58424: 27420,
  58425: 99999,
  58426: 27420,
  58428: 99999,
  58429: 99999,
  58430: 99999,
  58431: 99999,
  58433: 99999,
  58436: 99999,
  58438: 99999,
  58439: 99999,
  58440: 99999,
  58441: 99999,
  58442: 99999,
  58443: 99999,
  58444: 99999,
  58445: 99999,
  58448: 99999,
  58451: 99999,
  58452: 99999,
  58454: 99999,
  58455: 99999,
  58456: 99999,
  58458: 99999,
  58460: 99999,
  58461: 99999,
  58463: 99999,
  58464: 99999,
  58466: 99999,
  58467: 27420,
  58472: 27420,
  58474: 99999,
  58475: 99999,
  58476: 27420,
  58477: 13900,
  58478: 99999,
  58479: 99999,
  58480: 99999,
  58481: 99999,
  58482: 99999,
  58483: 27420,
  58484: 99999,
  58486: 99999,
  58487: 99999,
  58488: 99999,
  58490: 99999,
  58492: 27420,
  58494: 13900,
  58495: 99999,
  58496: 99999,
  58497: 99999,
  58501: 13900,
  58502: 13900,
  58503: 13900,
  58504: 99999,
  58505: 13900,
  58506: 13900,
  58507: 13900,
  58520: 13900,
  58521: 13900,
  58523: 13900,
  58524: 99999,
  58528: 99999,
  58529: 99999,
  58530: 13900,
  58531: 99999,
  58532: 99999,
  58533: 99999,
  58535: 13900,
  58538: 99999,
  58540: 99999,
  58541: 99999,
  58542: 99999,
  58544: 99999,
  58545: 13900,
  58549: 99999,
  58552: 99999,
  58554: 13900,
  58558: 13900,
  58559: 99999,
  58560: 13900,
  58561: 99999,
  58562: 99999,
  58563: 13900,
  58564: 99999,
  58565: 99999,
  58566: 13900,
  58568: 99999,
  58569: 13900,
  58570: 13900,
  58571: 99999,
  58572: 13900,
  58573: 99999,
  58575: 99999,
  58576: 99999,
  58577: 99999,
  58579: 99999,
  58580: 99999,
  58581: 99999,
  58601: 19860,
  58602: 19860,
  58620: 19860,
  58621: 99999,
  58622: 99999,
  58623: 99999,
  58625: 99999,
  58626: 99999,
  58627: 19860,
  58630: 99999,
  58631: 13900,
  58632: 99999,
  58634: 19860,
  58636: 99999,
  58638: 99999,
  58639: 99999,
  58640: 99999,
  58641: 19860,
  58642: 19860,
  58643: 99999,
  58644: 99999,
  58645: 99999,
  58646: 99999,
  58647: 99999,
  58649: 99999,
  58650: 99999,
  58651: 99999,
  58652: 99999,
  58653: 99999,
  58654: 99999,
  58655: 19860,
  58656: 19860,
  58701: 33500,
  58702: 33500,
  58703: 33500,
  58704: 33500,
  58705: 33500,
  58707: 33500,
  58710: 33500,
  58711: 99999,
  58712: 33500,
  58713: 33500,
  58716: 33500,
  58718: 99999,
  58721: 33500,
  58722: 33500,
  58723: 33500,
  58725: 33500,
  58727: 99999,
  58730: 99999,
  58731: 33500,
  58733: 33500,
  58734: 99999,
  58735: 33500,
  58736: 33500,
  58737: 99999,
  58740: 33500,
  58741: 33500,
  58744: 33500,
  58746: 99999,
  58748: 33500,
  58750: 99999,
  58752: 99999,
  58755: 99999,
  58756: 99999,
  58757: 99999,
  58758: 33500,
  58759: 33500,
  58760: 99999,
  58761: 33500,
  58762: 33500,
  58763: 99999,
  58765: 99999,
  58768: 33500,
  58769: 99999,
  58770: 99999,
  58771: 99999,
  58772: 99999,
  58773: 99999,
  58775: 99999,
  58776: 99999,
  58778: 33500,
  58779: 99999,
  58781: 33500,
  58782: 99999,
  58783: 99999,
  58784: 99999,
  58785: 33500,
  58787: 33500,
  58788: 99999,
  58789: 99999,
  58790: 33500,
  58792: 33500,
  58793: 99999,
  58794: 48780,
  58795: 99999,
  58801: 48780,
  58802: 48780,
  58803: 48780,
  58830: 48780,
  58831: 99999,
  58833: 99999,
  58835: 99999,
  58838: 99999,
  58843: 48780,
  58844: 99999,
  58845: 99999,
  58847: 99999,
  58849: 48780,
  58852: 48780,
  58853: 48780,
  58854: 99999,
  58856: 48780,
  59001: 13740,
  59002: 13740,
  59003: 99999,
  59004: 99999,
  59006: 13740,
  59007: 13740,
  59008: 13740,
  59010: 99999,
  59011: 13740,
  59012: 99999,
  59013: 13740,
  59014: 13740,
  59015: 13740,
  59016: 99999,
  59018: 99999,
  59019: 13740,
  59020: 99999,
  59022: 99999,
  59024: 13740,
  59025: 99999,
  59026: 13740,
  59027: 99999,
  59028: 13740,
  59029: 13740,
  59030: 99999,
  59031: 99999,
  59032: 99999,
  59033: 99999,
  59034: 99999,
  59035: 99999,
  59036: 99999,
  59037: 13740,
  59038: 99999,
  59039: 99999,
  59041: 13740,
  59043: 99999,
  59044: 13740,
  59046: 99999,
  59047: 99999,
  59050: 99999,
  59052: 99999,
  59053: 99999,
  59054: 99999,
  59055: 99999,
  59057: 13740,
  59058: 99999,
  59059: 99999,
  59061: 13740,
  59062: 99999,
  59063: 13740,
  59064: 13740,
  59065: 99999,
  59066: 99999,
  59067: 13740,
  59068: 13740,
  59069: 13740,
  59070: 13740,
  59071: 13740,
  59072: 99999,
  59074: 13740,
  59075: 99999,
  59076: 99999,
  59077: 99999,
  59078: 99999,
  59079: 13740,
  59081: 99999,
  59082: 99999,
  59083: 99999,
  59084: 99999,
  59085: 99999,
  59086: 99999,
  59087: 99999,
  59088: 13740,
  59089: 99999,
  59101: 13740,
  59102: 13740,
  59103: 13740,
  59104: 13740,
  59105: 13740,
  59106: 13740,
  59107: 13740,
  59108: 13740,
  59111: 13740,
  59201: 99999,
  59211: 99999,
  59212: 99999,
  59213: 99999,
  59214: 99999,
  59215: 99999,
  59217: 99999,
  59218: 99999,
  59219: 99999,
  59221: 99999,
  59222: 99999,
  59223: 99999,
  59225: 99999,
  59226: 99999,
  59230: 99999,
  59231: 99999,
  59240: 99999,
  59241: 99999,
  59242: 99999,
  59243: 99999,
  59244: 99999,
  59247: 99999,
  59248: 99999,
  59250: 99999,
  59252: 99999,
  59253: 99999,
  59254: 99999,
  59255: 99999,
  59256: 99999,
  59257: 99999,
  59258: 99999,
  59259: 99999,
  59260: 99999,
  59261: 99999,
  59262: 99999,
  59263: 99999,
  59270: 99999,
  59273: 99999,
  59274: 99999,
  59275: 99999,
  59276: 99999,
  59301: 99999,
  59311: 99999,
  59312: 99999,
  59313: 99999,
  59314: 99999,
  59315: 99999,
  59316: 99999,
  59317: 99999,
  59318: 99999,
  59319: 99999,
  59322: 99999,
  59323: 99999,
  59324: 99999,
  59326: 99999,
  59327: 99999,
  59330: 99999,
  59332: 99999,
  59333: 99999,
  59336: 99999,
  59337: 99999,
  59338: 99999,
  59339: 99999,
  59341: 99999,
  59343: 99999,
  59344: 99999,
  59345: 99999,
  59347: 99999,
  59349: 99999,
  59351: 99999,
  59353: 99999,
  59354: 99999,
  59401: 24500,
  59402: 24500,
  59403: 24500,
  59404: 99999,
  59405: 24500,
  59406: 24500,
  59410: 25740,
  59411: 99999,
  59412: 24500,
  59414: 24500,
  59416: 99999,
  59417: 99999,
  59418: 99999,
  59419: 99999,
  59420: 99999,
  59421: 25740,
  59422: 99999,
  59424: 99999,
  59425: 99999,
  59427: 99999,
  59430: 99999,
  59432: 99999,
  59433: 99999,
  59434: 99999,
  59435: 99999,
  59436: 99999,
  59440: 99999,
  59441: 99999,
  59442: 99999,
  59443: 24500,
  59444: 99999,
  59446: 99999,
  59447: 99999,
  59448: 99999,
  59450: 99999,
  59451: 99999,
  59452: 99999,
  59453: 99999,
  59454: 99999,
  59456: 99999,
  59457: 99999,
  59460: 99999,
  59461: 99999,
  59462: 99999,
  59463: 24500,
  59464: 99999,
  59465: 24500,
  59466: 99999,
  59467: 99999,
  59468: 24500,
  59469: 99999,
  59471: 99999,
  59472: 24500,
  59474: 99999,
  59477: 24500,
  59479: 99999,
  59480: 24500,
  59482: 99999,
  59483: 24500,
  59484: 99999,
  59485: 24500,
  59486: 99999,
  59487: 99999,
  59489: 99999,
  59501: 99999,
  59520: 99999,
  59521: 99999,
  59522: 99999,
  59523: 99999,
  59524: 99999,
  59525: 99999,
  59526: 99999,
  59527: 99999,
  59528: 99999,
  59529: 99999,
  59530: 99999,
  59531: 99999,
  59532: 99999,
  59535: 99999,
  59537: 99999,
  59538: 99999,
  59540: 99999,
  59542: 99999,
  59544: 99999,
  59545: 99999,
  59546: 99999,
  59547: 99999,
  59601: 25740,
  59602: 25740,
  59604: 25740,
  59620: 25740,
  59623: 25740,
  59624: 25740,
  59625: 25740,
  59626: 25740,
  59631: 25740,
  59632: 25740,
  59633: 25740,
  59634: 25740,
  59635: 25740,
  59636: 25740,
  59638: 25740,
  59639: 25740,
  59640: 25740,
  59641: 99999,
  59642: 99999,
  59643: 99999,
  59644: 99999,
  59645: 99999,
  59647: 99999,
  59648: 25740,
  59701: 15580,
  59702: 15580,
  59703: 15580,
  59710: 99999,
  59711: 99999,
  59713: 99999,
  59714: 14580,
  59715: 99999,
  59716: 99999,
  59717: 14580,
  59718: 14580,
  59719: 14580,
  59720: 99999,
  59721: 99999,
  59722: 99999,
  59724: 99999,
  59725: 99999,
  59727: 99999,
  59728: 99999,
  59729: 99999,
  59730: 14580,
  59731: 99999,
  59732: 99999,
  59733: 99999,
  59735: 99999,
  59736: 99999,
  59739: 99999,
  59740: 99999,
  59741: 99999,
  59743: 15580,
  59745: 99999,
  59746: 99999,
  59747: 99999,
  59748: 15580,
  59749: 99999,
  59750: 15580,
  59751: 99999,
  59752: 99999,
  59754: 99999,
  59755: 99999,
  59756: 99999,
  59758: 14580,
  59759: 25740,
  59760: 14580,
  59761: 99999,
  59762: 99999,
  59771: 14580,
  59772: 14580,
  59801: 33540,
  59802: 33540,
  59803: 33540,
  59804: 33540,
  59806: 33540,
  59807: 33540,
  59808: 33540,
  59812: 33540,
  59820: 33540,
  59821: 99999,
  59823: 33540,
  59824: 99999,
  59825: 99999,
  59826: 99999,
  59827: 99999,
  59828: 99999,
  59829: 99999,
  59830: 99999,
  59831: 99999,
  59832: 99999,
  59833: 99999,
  59834: 33540,
  59835: 99999,
  59837: 99999,
  59840: 99999,
  59841: 99999,
  59842: 99999,
  59843: 99999,
  59844: 99999,
  59845: 99999,
  59846: 33540,
  59847: 33540,
  59848: 99999,
  59851: 33540,
  59853: 99999,
  59854: 99999,
  59855: 99999,
  59856: 99999,
  59858: 99999,
  59859: 99999,
  59860: 99999,
  59863: 99999,
  59864: 99999,
  59865: 99999,
  59866: 99999,
  59867: 99999,
  59868: 33540,
  59870: 99999,
  59871: 99999,
  59872: 99999,
  59873: 99999,
  59874: 99999,
  59875: 99999,
  59901: 28060,
  59903: 28060,
  59904: 28060,
  59910: 99999,
  59911: 99999,
  59912: 28060,
  59913: 28060,
  59914: 99999,
  59915: 99999,
  59916: 28060,
  59917: 99999,
  59918: 99999,
  59919: 28060,
  59920: 28060,
  59922: 28060,
  59923: 99999,
  59925: 28060,
  59926: 28060,
  59927: 28060,
  59928: 28060,
  59929: 99999,
  59930: 99999,
  59931: 99999,
  59932: 28060,
  59933: 99999,
  59934: 99999,
  59935: 99999,
  59936: 28060,
  59937: 28060,
  60002: 16980,
  60004: 16980,
  60005: 16980,
  60006: 16980,
  60007: 16980,
  60008: 16980,
  60009: 16980,
  60010: 16980,
  60011: 16980,
  60012: 16980,
  60013: 16980,
  60014: 16980,
  60015: 16980,
  60016: 16980,
  60017: 16980,
  60018: 16980,
  60020: 16980,
  60021: 16980,
  60022: 16980,
  60025: 16980,
  60026: 16980,
  60029: 16980,
  60030: 16980,
  60031: 16980,
  60033: 40420,
  60034: 16980,
  60035: 16980,
  60037: 16980,
  60038: 16980,
  60039: 16980,
  60040: 16980,
  60041: 16980,
  60042: 16980,
  60043: 16980,
  60044: 16980,
  60045: 16980,
  60046: 16980,
  60047: 16980,
  60048: 16980,
  60050: 16980,
  60051: 16980,
  60053: 16980,
  60055: 16980,
  60056: 16980,
  60060: 16980,
  60061: 16980,
  60062: 16980,
  60064: 16980,
  60065: 16980,
  60067: 16980,
  60068: 16980,
  60069: 16980,
  60070: 16980,
  60071: 16980,
  60072: 16980,
  60073: 16980,
  60074: 16980,
  60075: 16980,
  60076: 16980,
  60077: 16980,
  60078: 16980,
  60079: 16980,
  60081: 16980,
  60082: 16980,
  60083: 16980,
  60084: 16980,
  60085: 16980,
  60086: 16980,
  60087: 16980,
  60088: 16980,
  60089: 16980,
  60090: 16980,
  60091: 16980,
  60093: 16980,
  60095: 16980,
  60096: 16980,
  60097: 16980,
  60098: 16980,
  60099: 16980,
  60101: 16980,
  60102: 16980,
  60103: 16980,
  60104: 16980,
  60106: 16980,
  60107: 16980,
  60108: 16980,
  60109: 16980,
  60110: 16980,
  60111: 16980,
  60112: 16980,
  60113: 40300,
  60115: 16980,
  60117: 16980,
  60118: 16980,
  60119: 16980,
  60120: 16980,
  60121: 16980,
  60122: 16980,
  60123: 16980,
  60124: 16980,
  60126: 16980,
  60129: 40300,
  60130: 16980,
  60131: 16980,
  60133: 16980,
  60134: 16980,
  60135: 16980,
  60136: 16980,
  60137: 16980,
  60138: 16980,
  60139: 16980,
  60140: 16980,
  60141: 16980,
  60142: 16980,
  60143: 16980,
  60144: 16980,
  60145: 40420,
  60146: 16980,
  60147: 16980,
  60148: 16980,
  60150: 16980,
  60151: 16980,
  60152: 40420,
  60153: 16980,
  60154: 16980,
  60155: 16980,
  60156: 16980,
  60157: 16980,
  60159: 16980,
  60160: 16980,
  60161: 16980,
  60162: 16980,
  60163: 16980,
  60164: 16980,
  60165: 16980,
  60168: 16980,
  60169: 16980,
  60171: 16980,
  60172: 16980,
  60173: 16980,
  60174: 16980,
  60175: 16980,
  60176: 16980,
  60177: 16980,
  60178: 16980,
  60179: 16980,
  60180: 16980,
  60181: 16980,
  60183: 16980,
  60184: 16980,
  60185: 16980,
  60186: 16980,
  60187: 16980,
  60188: 16980,
  60189: 16980,
  60190: 16980,
  60191: 16980,
  60192: 16980,
  60193: 16980,
  60194: 16980,
  60195: 16980,
  60196: 16980,
  60199: 16980,
  60201: 16980,
  60202: 16980,
  60203: 16980,
  60204: 16980,
  60208: 16980,
  60301: 16980,
  60302: 16980,
  60303: 16980,
  60304: 16980,
  60305: 16980,
  60401: 16980,
  60402: 16980,
  60403: 16980,
  60404: 16980,
  60406: 16980,
  60407: 16980,
  60408: 16980,
  60409: 16980,
  60410: 16980,
  60411: 16980,
  60412: 16980,
  60415: 16980,
  60416: 16980,
  60417: 16980,
  60418: 16980,
  60419: 16980,
  60420: 16980,
  60421: 16980,
  60422: 16980,
  60423: 16980,
  60424: 28100,
  60425: 16980,
  60426: 16980,
  60428: 16980,
  60429: 16980,
  60430: 16980,
  60431: 16980,
  60432: 16980,
  60433: 16980,
  60434: 16980,
  60435: 16980,
  60436: 16980,
  60437: 16980,
  60438: 16980,
  60439: 16980,
  60440: 16980,
  60441: 16980,
  60442: 16980,
  60443: 16980,
  60444: 16980,
  60445: 16980,
  60446: 16980,
  60447: 16980,
  60448: 16980,
  60449: 16980,
  60450: 16980,
  60451: 16980,
  60452: 16980,
  60453: 16980,
  60454: 16980,
  60455: 16980,
  60456: 16980,
  60457: 16980,
  60458: 16980,
  60459: 16980,
  60460: 38700,
  60461: 16980,
  60462: 16980,
  60463: 16980,
  60464: 16980,
  60465: 16980,
  60466: 16980,
  60467: 16980,
  60468: 28100,
  60469: 16980,
  60470: 38700,
  60471: 16980,
  60472: 16980,
  60473: 16980,
  60474: 16980,
  60475: 16980,
  60476: 16980,
  60477: 16980,
  60478: 16980,
  60479: 16980,
  60480: 16980,
  60481: 28100,
  60482: 16980,
  60484: 16980,
  60487: 16980,
  60490: 16980,
  60491: 16980,
  60499: 16980,
  60501: 16980,
  60502: 16980,
  60503: 16980,
  60504: 16980,
  60505: 16980,
  60506: 16980,
  60507: 16980,
  60510: 16980,
  60511: 16980,
  60512: 16980,
  60513: 16980,
  60514: 16980,
  60515: 16980,
  60516: 16980,
  60517: 16980,
  60518: 19940,
  60519: 16980,
  60520: 16980,
  60521: 16980,
  60522: 16980,
  60523: 16980,
  60525: 16980,
  60526: 16980,
  60527: 16980,
  60530: 16980,
  60531: 36837,
  60532: 16980,
  60534: 16980,
  60536: 16980,
  60537: 16980,
  60538: 16980,
  60539: 16980,
  60540: 16980,
  60541: 16980,
  60542: 16980,
  60543: 16980,
  60544: 16980,
  60545: 16980,
  60546: 16980,
  60548: 36837,
  60549: 36837,
  60550: 16980,
  60551: 16980,
  60552: 36837,
  60553: 16980,
  60554: 16980,
  60555: 16980,
  60556: 16980,
  60557: 36837,
  60558: 16980,
  60559: 16980,
  60560: 16980,
  60561: 16980,
  60563: 16980,
  60564: 16980,
  60565: 16980,
  60566: 16980,
  60567: 16980,
  60569: 16980,
  60585: 16980,
  60586: 16980,
  60598: 16980,
  60599: 16980,
  60601: 16980,
  60602: 16980,
  60603: 16980,
  60604: 16980,
  60605: 16980,
  60606: 16980,
  60607: 16980,
  60608: 16980,
  60609: 16980,
  60610: 16980,
  60611: 16980,
  60612: 16980,
  60613: 16980,
  60614: 16980,
  60615: 16980,
  60616: 16980,
  60617: 16980,
  60618: 16980,
  60619: 16980,
  60620: 16980,
  60621: 16980,
  60622: 16980,
  60623: 16980,
  60624: 16980,
  60625: 16980,
  60626: 16980,
  60628: 16980,
  60629: 16980,
  60630: 16980,
  60631: 16980,
  60632: 16980,
  60633: 16980,
  60634: 16980,
  60636: 16980,
  60637: 16980,
  60638: 16980,
  60639: 16980,
  60640: 16980,
  60641: 16980,
  60642: 16980,
  60643: 16980,
  60644: 16980,
  60645: 16980,
  60646: 16980,
  60647: 16980,
  60649: 16980,
  60651: 16980,
  60652: 16980,
  60653: 16980,
  60654: 16980,
  60655: 16980,
  60656: 16980,
  60657: 16980,
  60659: 16980,
  60660: 16980,
  60661: 16980,
  60666: 16980,
  60668: 16980,
  60669: 16980,
  60670: 16980,
  60673: 16980,
  60674: 16980,
  60675: 16980,
  60677: 16980,
  60678: 16980,
  60682: 16980,
  60684: 16980,
  60685: 16980,
  60686: 16980,
  60687: 16980,
  60688: 16980,
  60689: 16980,
  60690: 16980,
  60693: 16980,
  60694: 16980,
  60695: 16980,
  60697: 16980,
  60699: 16980,
  60701: 16980,
  60706: 16980,
  60707: 16980,
  60712: 16980,
  60714: 16980,
  60803: 16980,
  60804: 16980,
  60805: 16980,
  60827: 16980,
  60901: 28100,
  60910: 28100,
  60911: 99999,
  60912: 99999,
  60913: 28100,
  60914: 28100,
  60915: 28100,
  60917: 99999,
  60918: 99999,
  60919: 99999,
  60920: 38700,
  60921: 99999,
  60922: 99999,
  60924: 99999,
  60926: 99999,
  60927: 99999,
  60928: 99999,
  60929: 99999,
  60930: 99999,
  60931: 99999,
  60932: 19180,
  60933: 99999,
  60934: 38700,
  60935: 28100,
  60936: 99999,
  60938: 99999,
  60939: 99999,
  60940: 28100,
  60941: 28100,
  60942: 19180,
  60944: 28100,
  60945: 99999,
  60946: 38700,
  60948: 99999,
  60949: 99999,
  60950: 16980,
  60951: 99999,
  60952: 38700,
  60953: 99999,
  60954: 28100,
  60955: 99999,
  60956: 99999,
  60957: 16580,
  60958: 28100,
  60959: 99999,
  60960: 99999,
  60961: 28100,
  60962: 99999,
  60963: 19180,
  60964: 28100,
  60966: 99999,
  60967: 99999,
  60968: 99999,
  60969: 28100,
  60970: 99999,
  60973: 99999,
  60974: 99999,
  61001: 99999,
  61006: 40300,
  61007: 23300,
  61008: 40420,
  61010: 40300,
  61011: 40420,
  61012: 16980,
  61013: 23300,
  61014: 44580,
  61015: 40300,
  61016: 40420,
  61018: 23300,
  61019: 40420,
  61020: 40420,
  61021: 40300,
  61024: 40420,
  61025: 99999,
  61027: 23300,
  61028: 99999,
  61030: 40300,
  61031: 40300,
  61032: 23300,
  61036: 99999,
  61037: 44580,
  61038: 16980,
  61039: 40300,
  61041: 99999,
  61042: 19940,
  61043: 40300,
  61044: 99999,
  61046: 99999,
  61047: 23300,
  61048: 99999,
  61049: 40300,
  61050: 23300,
  61051: 40300,
  61052: 40420,
  61053: 99999,
  61054: 40300,
  61057: 19940,
  61059: 99999,
  61060: 23300,
  61061: 40300,
  61062: 99999,
  61063: 40420,
  61064: 44580,
  61065: 40420,
  61067: 23300,
  61068: 40300,
  61070: 23300,
  61071: 19940,
  61072: 40420,
  61073: 40420,
  61074: 99999,
  61075: 99999,
  61077: 40420,
  61078: 23300,
  61079: 40420,
  61080: 40420,
  61081: 19940,
  61084: 40420,
  61085: 99999,
  61087: 23300,
  61088: 40420,
  61089: 23300,
  61091: 40300,
  61101: 40420,
  61102: 40300,
  61103: 40420,
  61104: 40420,
  61105: 40420,
  61107: 40420,
  61108: 40420,
  61109: 40300,
  61110: 40420,
  61111: 40420,
  61112: 40420,
  61114: 40420,
  61115: 40420,
  61125: 40420,
  61126: 40420,
  61130: 40420,
  61131: 40420,
  61132: 40420,
  61201: 19340,
  61204: 19340,
  61230: 44580,
  61231: 19340,
  61232: 19340,
  61233: 19340,
  61234: 19340,
  61235: 19340,
  61236: 19340,
  61237: 19340,
  61238: 19340,
  61239: 19340,
  61240: 19340,
  61241: 19340,
  61242: 19340,
  61243: 44580,
  61244: 19340,
  61250: 19340,
  61251: 44580,
  61252: 44580,
  61254: 19340,
  61256: 19340,
  61257: 19340,
  61258: 19340,
  61259: 19340,
  61260: 19340,
  61261: 44580,
  61262: 19340,
  61263: 19340,
  61264: 19340,
  61265: 19340,
  61266: 19340,
  61270: 99999,
  61272: 19340,
  61273: 19340,
  61274: 19340,
  61275: 19340,
  61276: 19340,
  61277: 19340,
  61278: 19340,
  61279: 19340,
  61281: 19340,
  61282: 19340,
  61283: 19340,
  61284: 19340,
  61285: 99999,
  61299: 19340,
  61301: 36837,
  61310: 19940,
  61311: 38700,
  61312: 36837,
  61313: 38700,
  61314: 36837,
  61315: 36837,
  61316: 36837,
  61317: 36837,
  61318: 19940,
  61319: 38700,
  61320: 36837,
  61321: 37900,
  61322: 36837,
  61323: 36837,
  61324: 19940,
  61325: 36837,
  61326: 36837,
  61327: 36837,
  61328: 36837,
  61329: 36837,
  61330: 36837,
  61331: 19940,
  61332: 36837,
  61333: 38700,
  61334: 37900,
  61335: 36837,
  61336: 36837,
  61337: 36837,
  61338: 36837,
  61340: 36837,
  61341: 36837,
  61342: 36837,
  61344: 36837,
  61345: 36837,
  61346: 36837,
  61348: 36837,
  61349: 19940,
  61350: 36837,
  61353: 19940,
  61354: 36837,
  61356: 36837,
  61358: 37900,
  61359: 36837,
  61360: 36837,
  61361: 36837,
  61362: 36837,
  61363: 36837,
  61364: 38700,
  61367: 19940,
  61368: 36837,
  61369: 37900,
  61370: 36837,
  61371: 36837,
  61372: 36837,
  61373: 36837,
  61374: 36837,
  61375: 37900,
  61376: 19940,
  61377: 37900,
  61378: 19940,
  61379: 36837,
  61401: 23660,
  61402: 23660,
  61410: 23660,
  61411: 31380,
  61412: 19340,
  61413: 19340,
  61414: 23660,
  61415: 31380,
  61416: 31380,
  61417: 99999,
  61418: 99999,
  61419: 19340,
  61420: 31380,
  61421: 37900,
  61422: 31380,
  61423: 99999,
  61424: 37900,
  61425: 15460,
  61426: 37900,
  61427: 37900,
  61428: 23660,
  61430: 23660,
  61431: 37900,
  61432: 37900,
  61433: 37900,
  61434: 23660,
  61435: 99999,
  61436: 23660,
  61437: 15460,
  61438: 31380,
  61439: 23660,
  61440: 99999,
  61441: 37900,
  61442: 19340,
  61443: 37900,
  61447: 99999,
  61448: 23660,
  61449: 23660,
  61450: 22800,
  61451: 37900,
  61452: 31380,
  61453: 99999,
  61454: 15460,
  61455: 31380,
  61458: 23660,
  61459: 37900,
  61460: 15460,
  61462: 99999,
  61465: 19340,
  61466: 99999,
  61467: 23660,
  61468: 19340,
  61469: 15460,
  61470: 31380,
  61471: 15460,
  61472: 19340,
  61473: 99999,
  61474: 99999,
  61475: 99999,
  61476: 19340,
  61477: 37900,
  61478: 99999,
  61479: 37900,
  61480: 15460,
  61482: 37900,
  61483: 37900,
  61484: 37900,
  61485: 23660,
  61486: 19340,
  61488: 23660,
  61489: 23660,
  61490: 23660,
  61491: 37900,
  61501: 37900,
  61516: 37900,
  61517: 37900,
  61519: 37900,
  61520: 37900,
  61523: 37900,
  61524: 37900,
  61525: 37900,
  61526: 37900,
  61528: 37900,
  61529: 23660,
  61530: 37900,
  61531: 37900,
  61532: 99999,
  61533: 37900,
  61534: 99999,
  61535: 37900,
  61536: 37900,
  61537: 37900,
  61539: 37900,
  61540: 37900,
  61541: 37900,
  61542: 37900,
  61543: 37900,
  61544: 23660,
  61545: 37900,
  61546: 99999,
  61547: 37900,
  61548: 37900,
  61550: 37900,
  61552: 37900,
  61553: 37900,
  61554: 37900,
  61555: 37900,
  61558: 37900,
  61559: 37900,
  61560: 37900,
  61561: 37900,
  61562: 37900,
  61563: 37900,
  61564: 37900,
  61565: 37900,
  61567: 99999,
  61568: 37900,
  61569: 37900,
  61570: 37900,
  61571: 37900,
  61572: 23660,
  61601: 37900,
  61602: 37900,
  61603: 37900,
  61604: 37900,
  61605: 37900,
  61606: 37900,
  61607: 37900,
  61610: 37900,
  61611: 37900,
  61612: 37900,
  61613: 37900,
  61614: 37900,
  61615: 37900,
  61616: 37900,
  61625: 37900,
  61629: 37900,
  61630: 37900,
  61633: 37900,
  61634: 37900,
  61635: 37900,
  61636: 37900,
  61637: 37900,
  61638: 37900,
  61639: 37900,
  61641: 37900,
  61643: 37900,
  61651: 37900,
  61652: 37900,
  61653: 37900,
  61654: 37900,
  61655: 37900,
  61656: 37900,
  61701: 14010,
  61702: 14010,
  61704: 14010,
  61705: 14010,
  61709: 14010,
  61720: 99999,
  61721: 30660,
  61722: 14010,
  61723: 30660,
  61724: 14010,
  61725: 14010,
  61726: 38700,
  61727: 99999,
  61728: 38700,
  61729: 14010,
  61730: 14010,
  61731: 14010,
  61732: 37900,
  61733: 37900,
  61734: 37900,
  61735: 99999,
  61736: 14010,
  61737: 14010,
  61738: 14010,
  61739: 38700,
  61740: 37900,
  61741: 38700,
  61742: 37900,
  61743: 38700,
  61744: 38700,
  61745: 14010,
  61747: 37900,
  61748: 14010,
  61749: 30660,
  61750: 99999,
  61751: 30660,
  61752: 99999,
  61753: 14010,
  61754: 14010,
  61755: 14010,
  61756: 99999,
  61758: 14010,
  61759: 37900,
  61760: 37900,
  61761: 14010,
  61764: 38700,
  61769: 38700,
  61770: 14010,
  61771: 37900,
  61772: 14010,
  61773: 99999,
  61774: 14010,
  61775: 38700,
  61776: 14010,
  61777: 99999,
  61778: 99999,
  61790: 14010,
  61791: 14010,
  61799: 14010,
  61801: 16580,
  61802: 16580,
  61803: 16580,
  61810: 19180,
  61811: 19180,
  61812: 19180,
  61813: 16580,
  61814: 19180,
  61815: 16580,
  61816: 16580,
  61817: 19180,
  61818: 16580,
  61820: 16580,
  61821: 16580,
  61822: 16580,
  61824: 16580,
  61825: 16580,
  61826: 16580,
  61830: 16580,
  61831: 19180,
  61832: 19180,
  61833: 19180,
  61834: 19180,
  61839: 16580,
  61840: 16580,
  61841: 19180,
  61842: 16580,
  61843: 14010,
  61844: 16580,
  61845: 14010,
  61846: 19180,
  61847: 16580,
  61848: 19180,
  61849: 16580,
  61850: 99999,
  61851: 16580,
  61852: 99999,
  61853: 16580,
  61854: 16580,
  61855: 16580,
  61856: 16580,
  61857: 19180,
  61858: 19180,
  61859: 16580,
  61862: 19180,
  61863: 99999,
  61864: 16580,
  61865: 19180,
  61866: 16580,
  61870: 99999,
  61871: 16580,
  61872: 99999,
  61873: 16580,
  61874: 16580,
  61875: 16580,
  61876: 19180,
  61877: 16580,
  61878: 16580,
  61880: 16580,
  61882: 99999,
  61883: 19180,
  61884: 16580,
  61910: 99999,
  61911: 16660,
  61912: 99999,
  61913: 16580,
  61914: 99999,
  61917: 99999,
  61919: 99999,
  61920: 16660,
  61924: 99999,
  61925: 19500,
  61928: 99999,
  61929: 16580,
  61930: 16660,
  61931: 16660,
  61932: 99999,
  61933: 16660,
  61936: 16580,
  61937: 16580,
  61938: 16660,
  61940: 99999,
  61941: 99999,
  61942: 99999,
  61943: 16660,
  61944: 99999,
  61949: 99999,
  61951: 16660,
  61953: 16580,
  61955: 99999,
  61956: 99999,
  61957: 99999,
  62001: 41180,
  62002: 41180,
  62006: 41180,
  62009: 41180,
  62010: 41180,
  62011: 99999,
  62012: 41180,
  62013: 41180,
  62014: 41180,
  62015: 99999,
  62016: 99999,
  62017: 41180,
  62018: 41180,
  62019: 99999,
  62021: 41180,
  62022: 41180,
  62023: 41180,
  62024: 41180,
  62025: 41180,
  62026: 41180,
  62027: 99999,
  62028: 41180,
  62030: 41180,
  62031: 41180,
  62032: 99999,
  62033: 41180,
  62034: 41180,
  62035: 41180,
  62036: 41180,
  62037: 41180,
  62040: 41180,
  62044: 41180,
  62045: 41180,
  62046: 41180,
  62047: 41180,
  62048: 41180,
  62049: 99999,
  62050: 99999,
  62051: 99999,
  62052: 41180,
  62053: 41180,
  62054: 41180,
  62056: 99999,
  62058: 41180,
  62059: 41180,
  62060: 41180,
  62061: 41180,
  62062: 41180,
  62063: 41180,
  62065: 41180,
  62067: 41180,
  62069: 99999,
  62070: 41180,
  62071: 41180,
  62074: 41180,
  62075: 45380,
  62076: 99999,
  62077: 41180,
  62078: 99999,
  62079: 41180,
  62080: 99999,
  62081: 99999,
  62082: 99999,
  62083: 99999,
  62084: 41180,
  62085: 41180,
  62086: 41180,
  62087: 41180,
  62088: 41180,
  62089: 99999,
  62090: 41180,
  62091: 41180,
  62092: 99999,
  62093: 41180,
  62094: 99999,
  62095: 41180,
  62097: 41180,
  62098: 99999,
  62201: 41180,
  62202: 41180,
  62203: 41180,
  62204: 41180,
  62205: 41180,
  62206: 41180,
  62207: 41180,
  62208: 41180,
  62214: 99999,
  62215: 41180,
  62216: 41180,
  62217: 99999,
  62218: 41180,
  62219: 41180,
  62220: 41180,
  62221: 41180,
  62222: 41180,
  62223: 41180,
  62225: 41180,
  62226: 41180,
  62230: 41180,
  62231: 41180,
  62232: 41180,
  62233: 99999,
  62234: 41180,
  62236: 41180,
  62237: 99999,
  62238: 99999,
  62239: 41180,
  62240: 41180,
  62241: 99999,
  62242: 99999,
  62243: 41180,
  62244: 41180,
  62245: 41180,
  62246: 41180,
  62248: 41180,
  62249: 41180,
  62250: 41180,
  62252: 41180,
  62253: 41180,
  62254: 41180,
  62255: 41180,
  62256: 41180,
  62257: 99999,
  62258: 41180,
  62259: 99999,
  62260: 41180,
  62261: 99999,
  62262: 41180,
  62263: 99999,
  62264: 41180,
  62265: 41180,
  62266: 41180,
  62268: 99999,
  62269: 41180,
  62271: 99999,
  62272: 16060,
  62273: 41180,
  62274: 99999,
  62275: 41180,
  62277: 99999,
  62278: 99999,
  62279: 41180,
  62280: 99999,
  62281: 41180,
  62282: 41180,
  62284: 41180,
  62285: 41180,
  62286: 99999,
  62288: 99999,
  62289: 41180,
  62292: 99999,
  62293: 41180,
  62294: 41180,
  62295: 41180,
  62297: 99999,
  62298: 41180,
  62301: 39500,
  62305: 39500,
  62306: 39500,
  62311: 39500,
  62312: 39500,
  62313: 22800,
  62314: 39500,
  62316: 22800,
  62319: 99999,
  62320: 39500,
  62321: 22800,
  62323: 99999,
  62324: 39500,
  62325: 39500,
  62326: 31380,
  62329: 22800,
  62330: 15460,
  62334: 22800,
  62336: 22800,
  62338: 39500,
  62339: 39500,
  62340: 99999,
  62341: 22800,
  62343: 39500,
  62344: 99999,
  62345: 99999,
  62346: 99999,
  62347: 39500,
  62348: 39500,
  62349: 39500,
  62351: 22800,
  62352: 99999,
  62353: 99999,
  62354: 22800,
  62355: 99999,
  62356: 99999,
  62357: 99999,
  62358: 22800,
  62359: 39500,
  62360: 39500,
  62361: 99999,
  62362: 99999,
  62363: 27300,
  62365: 39500,
  62366: 41180,
  62367: 22800,
  62370: 99999,
  62373: 39500,
  62374: 31380,
  62375: 99999,
  62376: 39500,
  62378: 99999,
  62379: 22800,
  62380: 22800,
  62401: 20820,
  62410: 99999,
  62411: 99999,
  62413: 99999,
  62414: 20820,
  62417: 99999,
  62418: 99999,
  62419: 99999,
  62420: 16660,
  62421: 99999,
  62422: 99999,
  62423: 99999,
  62424: 99999,
  62425: 99999,
  62426: 20820,
  62427: 99999,
  62428: 99999,
  62431: 99999,
  62432: 99999,
  62433: 99999,
  62434: 99999,
  62436: 99999,
  62438: 99999,
  62439: 99999,
  62440: 16660,
  62441: 99999,
  62442: 99999,
  62443: 99999,
  62444: 99999,
  62445: 16660,
  62446: 99999,
  62447: 99999,
  62448: 99999,
  62449: 99999,
  62450: 99999,
  62451: 99999,
  62452: 99999,
  62454: 99999,
  62458: 99999,
  62459: 99999,
  62460: 99999,
  62461: 99999,
  62462: 20820,
  62463: 99999,
  62464: 99999,
  62465: 99999,
  62466: 99999,
  62467: 99999,
  62468: 16660,
  62469: 16660,
  62471: 99999,
  62473: 20820,
  62474: 16660,
  62475: 99999,
  62476: 99999,
  62477: 99999,
  62478: 99999,
  62479: 99999,
  62480: 99999,
  62481: 99999,
  62501: 99999,
  62510: 45380,
  62512: 30660,
  62513: 19500,
  62514: 19500,
  62515: 44100,
  62517: 45380,
  62518: 30660,
  62519: 30660,
  62520: 44100,
  62521: 19500,
  62522: 19500,
  62523: 19500,
  62524: 19500,
  62525: 19500,
  62526: 19500,
  62530: 44100,
  62531: 45380,
  62532: 19500,
  62533: 99999,
  62534: 99999,
  62535: 19500,
  62536: 44100,
  62537: 19500,
  62538: 45380,
  62539: 19500,
  62540: 45380,
  62541: 30660,
  62543: 30660,
  62544: 99999,
  62545: 44100,
  62546: 99999,
  62547: 45380,
  62548: 30660,
  62549: 19500,
  62550: 19500,
  62551: 19500,
  62553: 99999,
  62554: 19500,
  62555: 45380,
  62556: 45380,
  62557: 45380,
  62558: 44100,
  62560: 41180,
  62561: 44100,
  62563: 44100,
  62565: 99999,
  62567: 45380,
  62568: 45380,
  62570: 45380,
  62571: 99999,
  62572: 41180,
  62573: 19500,
  62601: 27300,
  62610: 27300,
  62611: 27300,
  62612: 27300,
  62613: 44100,
  62615: 44100,
  62617: 99999,
  62618: 99999,
  62621: 27300,
  62622: 99999,
  62624: 99999,
  62625: 44100,
  62626: 41180,
  62627: 99999,
  62628: 27300,
  62629: 44100,
  62630: 41180,
  62631: 27300,
  62633: 99999,
  62634: 30660,
  62635: 30660,
  62638: 41180,
  62639: 99999,
  62640: 99999,
  62642: 44100,
  62643: 30660,
  62644: 37900,
  62649: 41180,
  62650: 27300,
  62651: 27300,
  62655: 99999,
  62656: 30660,
  62659: 44100,
  62660: 27300,
  62661: 44100,
  62662: 44100,
  62663: 27300,
  62664: 99999,
  62665: 27300,
  62666: 30660,
  62667: 27300,
  62668: 27300,
  62670: 27300,
  62671: 99999,
  62672: 41180,
  62673: 44100,
  62674: 27300,
  62675: 99999,
  62677: 99999,
  62681: 99999,
  62682: 37900,
  62684: 44100,
  62685: 41180,
  62688: 44100,
  62689: 44100,
  62690: 44100,
  62691: 99999,
  62692: 44100,
  62693: 30660,
  62694: 99999,
  62695: 27300,
  62701: 44100,
  62702: 44100,
  62703: 44100,
  62704: 44100,
  62705: 44100,
  62706: 44100,
  62707: 44100,
  62708: 44100,
  62711: 44100,
  62712: 44100,
  62715: 44100,
  62716: 44100,
  62719: 44100,
  62722: 44100,
  62723: 44100,
  62726: 44100,
  62736: 44100,
  62739: 44100,
  62756: 44100,
  62757: 44100,
  62762: 44100,
  62763: 44100,
  62764: 44100,
  62765: 44100,
  62766: 44100,
  62767: 44100,
  62769: 44100,
  62776: 44100,
  62781: 44100,
  62791: 44100,
  62796: 44100,
  62801: 99999,
  62803: 99999,
  62806: 99999,
  62807: 16460,
  62808: 34500,
  62809: 99999,
  62810: 99999,
  62811: 99999,
  62812: 99999,
  62814: 34500,
  62815: 99999,
  62816: 34500,
  62817: 99999,
  62818: 99999,
  62819: 99999,
  62820: 99999,
  62821: 99999,
  62822: 99999,
  62823: 99999,
  62824: 99999,
  62825: 99999,
  62827: 99999,
  62828: 99999,
  62829: 99999,
  62830: 16460,
  62831: 34500,
  62832: 16060,
  62833: 99999,
  62834: 99999,
  62835: 99999,
  62836: 34500,
  62837: 99999,
  62838: 99999,
  62839: 99999,
  62840: 99999,
  62841: 99999,
  62842: 99999,
  62843: 99999,
  62844: 99999,
  62846: 34500,
  62848: 99999,
  62849: 16460,
  62850: 99999,
  62851: 99999,
  62852: 99999,
  62853: 34500,
  62854: 99999,
  62856: 99999,
  62858: 99999,
  62859: 99999,
  62860: 99999,
  62861: 99999,
  62862: 99999,
  62863: 99999,
  62864: 34500,
  62865: 99999,
  62867: 99999,
  62868: 99999,
  62869: 99999,
  62870: 16460,
  62871: 99999,
  62872: 34500,
  62874: 99999,
  62875: 41180,
  62876: 99999,
  62877: 99999,
  62878: 99999,
  62879: 99999,
  62880: 99999,
  62881: 16460,
  62882: 41180,
  62883: 99999,
  62884: 34500,
  62885: 99999,
  62886: 99999,
  62887: 99999,
  62888: 99999,
  62889: 34500,
  62890: 99999,
  62891: 99999,
  62892: 16460,
  62893: 34500,
  62894: 34500,
  62895: 99999,
  62896: 99999,
  62897: 99999,
  62898: 34500,
  62899: 99999,
  62901: 16060,
  62902: 99999,
  62903: 16060,
  62905: 99999,
  62906: 99999,
  62907: 16060,
  62908: 37140,
  62909: 16060,
  62910: 99999,
  62912: 16060,
  62914: 16020,
  62915: 16060,
  62916: 16060,
  62917: 99999,
  62918: 16060,
  62919: 99999,
  62920: 16060,
  62921: 16060,
  62922: 99999,
  62923: 99999,
  62924: 16060,
  62926: 99999,
  62927: 16060,
  62928: 99999,
  62930: 99999,
  62931: 99999,
  62932: 16060,
  62933: 16060,
  62934: 99999,
  62935: 99999,
  62938: 16060,
  62939: 99999,
  62940: 16060,
  62941: 99999,
  62942: 16060,
  62943: 37140,
  62946: 99999,
  62947: 99999,
  62948: 16060,
  62949: 16060,
  62950: 16060,
  62951: 16060,
  62952: 16020,
  62953: 37140,
  62954: 99999,
  62956: 37140,
  62957: 99999,
  62958: 16060,
  62959: 16060,
  62960: 99999,
  62961: 99999,
  62962: 16020,
  62963: 99999,
  62964: 99999,
  62965: 99999,
  62966: 16060,
  62967: 16060,
  62969: 16020,
  62970: 99999,
  62972: 99999,
  62973: 99999,
  62974: 16060,
  62975: 16060,
  62976: 99999,
  62977: 99999,
  62979: 99999,
  62982: 99999,
  62983: 99999,
  62984: 99999,
  62985: 99999,
  62987: 16060,
  62988: 16020,
  62990: 16020,
  62992: 99999,
  62993: 16020,
  62994: 16060,
  62995: 16060,
  62996: 99999,
  62997: 99999,
  62998: 99999,
  62999: 99999,
  63005: 41180,
  63006: 41180,
  63010: 41180,
  63011: 41180,
  63012: 41180,
  63013: 41180,
  63014: 41180,
  63015: 41180,
  63016: 41180,
  63017: 41180,
  63019: 41180,
  63020: 22100,
  63021: 41180,
  63022: 41180,
  63023: 41180,
  63024: 41180,
  63025: 41180,
  63026: 41180,
  63028: 99999,
  63030: 99999,
  63031: 41180,
  63032: 41180,
  63033: 41180,
  63034: 41180,
  63036: 99999,
  63037: 99999,
  63038: 41180,
  63039: 41180,
  63040: 41180,
  63041: 41180,
  63042: 41180,
  63043: 41180,
  63044: 41180,
  63045: 41180,
  63047: 41180,
  63048: 41180,
  63049: 41180,
  63050: 41180,
  63051: 41180,
  63052: 41180,
  63053: 41180,
  63055: 41180,
  63056: 41180,
  63057: 41180,
  63060: 41180,
  63061: 41180,
  63065: 41180,
  63066: 41180,
  63068: 99999,
  63069: 41180,
  63070: 41180,
  63071: 99999,
  63072: 41180,
  63073: 41180,
  63074: 41180,
  63077: 41180,
  63079: 41180,
  63080: 99999,
  63084: 41180,
  63087: 41180,
  63088: 41180,
  63089: 41180,
  63090: 41180,
  63091: 41180,
  63101: 41180,
  63102: 41180,
  63103: 41180,
  63104: 41180,
  63105: 41180,
  63106: 41180,
  63107: 41180,
  63108: 41180,
  63109: 41180,
  63110: 41180,
  63111: 41180,
  63112: 41180,
  63113: 41180,
  63114: 41180,
  63115: 41180,
  63116: 41180,
  63117: 41180,
  63118: 41180,
  63119: 41180,
  63120: 41180,
  63121: 41180,
  63122: 41180,
  63123: 41180,
  63124: 41180,
  63125: 41180,
  63126: 41180,
  63127: 41180,
  63128: 41180,
  63129: 41180,
  63130: 41180,
  63131: 41180,
  63132: 41180,
  63133: 41180,
  63134: 41180,
  63135: 41180,
  63136: 41180,
  63137: 41180,
  63138: 41180,
  63139: 41180,
  63140: 41180,
  63141: 41180,
  63143: 41180,
  63144: 41180,
  63145: 41180,
  63146: 41180,
  63147: 41180,
  63150: 41180,
  63151: 41180,
  63155: 41180,
  63156: 41180,
  63160: 41180,
  63164: 41180,
  63166: 41180,
  63167: 41180,
  63171: 41180,
  63177: 41180,
  63178: 41180,
  63180: 41180,
  63301: 41180,
  63302: 41180,
  63303: 41180,
  63304: 41180,
  63330: 99999,
  63332: 41180,
  63333: 41180,
  63334: 99999,
  63336: 99999,
  63338: 41180,
  63339: 99999,
  63341: 41180,
  63342: 41180,
  63343: 99999,
  63344: 41180,
  63345: 33020,
  63346: 41180,
  63347: 41180,
  63348: 41180,
  63349: 41180,
  63350: 99999,
  63351: 41180,
  63352: 33020,
  63353: 99999,
  63357: 41180,
  63359: 33020,
  63361: 99999,
  63362: 41180,
  63363: 41180,
  63365: 41180,
  63366: 41180,
  63367: 41180,
  63368: 41180,
  63369: 41180,
  63373: 41180,
  63376: 41180,
  63377: 41180,
  63378: 41180,
  63379: 41180,
  63380: 41180,
  63381: 99999,
  63382: 25300,
  63383: 41180,
  63384: 99999,
  63385: 41180,
  63386: 41180,
  63387: 41180,
  63388: 27620,
  63389: 41180,
  63390: 41180,
  63401: 25300,
  63430: 22800,
  63431: 99999,
  63432: 99999,
  63433: 99999,
  63434: 99999,
  63435: 22800,
  63436: 25300,
  63437: 99999,
  63438: 25300,
  63439: 99999,
  63440: 99999,
  63441: 25300,
  63442: 99999,
  63443: 25300,
  63445: 22800,
  63446: 39500,
  63447: 39500,
  63448: 39500,
  63450: 99999,
  63451: 99999,
  63452: 39500,
  63453: 22800,
  63454: 39500,
  63456: 25300,
  63457: 39500,
  63458: 99999,
  63459: 99999,
  63460: 99999,
  63461: 25300,
  63462: 99999,
  63463: 25300,
  63465: 22800,
  63466: 22800,
  63467: 25300,
  63468: 99999,
  63469: 99999,
  63471: 39500,
  63472: 22800,
  63473: 39500,
  63474: 22800,
  63501: 28860,
  63530: 99999,
  63531: 99999,
  63532: 99999,
  63533: 28860,
  63534: 99999,
  63535: 28860,
  63536: 28860,
  63537: 99999,
  63538: 99999,
  63539: 99999,
  63540: 28860,
  63541: 28860,
  63543: 99999,
  63544: 99999,
  63545: 99999,
  63546: 28860,
  63547: 99999,
  63548: 28860,
  63549: 28860,
  63551: 99999,
  63552: 99999,
  63555: 99999,
  63556: 99999,
  63557: 28860,
  63558: 99999,
  63559: 99999,
  63560: 99999,
  63561: 28860,
  63563: 99999,
  63565: 99999,
  63566: 99999,
  63567: 99999,
  63601: 99999,
  63620: 99999,
  63621: 99999,
  63622: 99999,
  63623: 99999,
  63624: 99999,
  63625: 99999,
  63626: 22100,
  63627: 41180,
  63628: 22100,
  63629: 99999,
  63630: 99999,
  63631: 99999,
  63633: 99999,
  63636: 99999,
  63637: 22100,
  63638: 99999,
  63640: 99999,
  63645: 22100,
  63648: 99999,
  63650: 22100,
  63651: 22100,
  63653: 22100,
  63654: 22100,
  63655: 99999,
  63656: 22100,
  63660: 99999,
  63662: 16020,
  63663: 99999,
  63664: 99999,
  63665: 99999,
  63666: 99999,
  63670: 99999,
  63673: 99999,
  63674: 99999,
  63675: 99999,
  63701: 16020,
  63702: 16020,
  63703: 16020,
  63730: 16020,
  63732: 99999,
  63735: 99999,
  63736: 43460,
  63737: 99999,
  63738: 99999,
  63739: 16020,
  63740: 43460,
  63742: 43460,
  63743: 16020,
  63744: 16020,
  63745: 16020,
  63746: 99999,
  63747: 16020,
  63748: 99999,
  63750: 16020,
  63751: 16020,
  63752: 16020,
  63755: 16020,
  63758: 43460,
  63760: 16020,
  63763: 99999,
  63764: 16020,
  63766: 16020,
  63767: 43460,
  63769: 16020,
  63770: 16020,
  63771: 99999,
  63774: 43460,
  63775: 99999,
  63776: 99999,
  63779: 16020,
  63780: 43460,
  63781: 99999,
  63782: 16020,
  63783: 99999,
  63784: 43460,
  63785: 16020,
  63787: 16020,
  63801: 43460,
  63820: 99999,
  63821: 28380,
  63822: 28380,
  63823: 99999,
  63824: 43460,
  63825: 99999,
  63826: 99999,
  63827: 99999,
  63828: 99999,
  63829: 28380,
  63830: 99999,
  63833: 99999,
  63834: 43460,
  63837: 28380,
  63839: 99999,
  63840: 99999,
  63841: 99999,
  63845: 99999,
  63846: 99999,
  63847: 28380,
  63848: 99999,
  63849: 99999,
  63850: 99999,
  63851: 99999,
  63852: 99999,
  63853: 99999,
  63855: 99999,
  63857: 99999,
  63860: 99999,
  63862: 99999,
  63863: 28380,
  63866: 99999,
  63867: 99999,
  63868: 99999,
  63869: 99999,
  63870: 99999,
  63873: 99999,
  63874: 99999,
  63875: 28380,
  63876: 28380,
  63877: 99999,
  63878: 99999,
  63879: 99999,
  63880: 28380,
  63881: 99999,
  63882: 99999,
  63901: 99999,
  63902: 38740,
  63931: 38740,
  63932: 38740,
  63933: 28380,
  63934: 99999,
  63935: 38740,
  63936: 99999,
  63937: 99999,
  63939: 38740,
  63940: 38740,
  63941: 99999,
  63942: 38740,
  63943: 99999,
  63944: 99999,
  63945: 38740,
  63950: 99999,
  63951: 99999,
  63952: 99999,
  63953: 38740,
  63954: 38740,
  63955: 38740,
  63956: 99999,
  63957: 99999,
  63960: 99999,
  63961: 38740,
  63962: 38740,
  63964: 99999,
  63965: 99999,
  63966: 99999,
  63967: 99999,
  64001: 28140,
  64002: 28140,
  64011: 47660,
  64012: 28140,
  64013: 28140,
  64014: 28140,
  64015: 28140,
  64016: 28140,
  64017: 28140,
  64018: 28140,
  64019: 47660,
  64020: 47660,
  64021: 28140,
  64022: 28140,
  64024: 28140,
  64028: 28140,
  64029: 28140,
  64030: 28140,
  64034: 28140,
  64035: 99999,
  64036: 28140,
  64037: 47660,
  64040: 47660,
  64048: 28140,
  64050: 28140,
  64051: 28140,
  64052: 28140,
  64053: 28140,
  64054: 28140,
  64055: 28140,
  64056: 28140,
  64057: 28140,
  64058: 28140,
  64060: 28140,
  64061: 28140,
  64062: 28140,
  64063: 28140,
  64064: 28140,
  64065: 28140,
  64066: 28140,
  64067: 28140,
  64068: 28140,
  64069: 28140,
  64070: 28140,
  64071: 47660,
  64072: 28140,
  64073: 28140,
  64074: 28140,
  64075: 28140,
  64076: 47660,
  64077: 28140,
  64078: 28140,
  64079: 28140,
  64080: 28140,
  64081: 28140,
  64082: 28140,
  64083: 28140,
  64084: 28140,
  64085: 28140,
  64086: 28140,
  64088: 28140,
  64089: 28140,
  64090: 28140,
  64092: 28140,
  64093: 47660,
  64096: 28140,
  64097: 28140,
  64098: 28140,
  64101: 28140,
  64102: 28140,
  64105: 28140,
  64106: 28140,
  64108: 28140,
  64109: 28140,
  64110: 28140,
  64111: 28140,
  64112: 28140,
  64113: 28140,
  64114: 28140,
  64116: 28140,
  64117: 28140,
  64118: 28140,
  64119: 28140,
  64120: 28140,
  64121: 28140,
  64123: 28140,
  64124: 28140,
  64125: 28140,
  64126: 28140,
  64127: 28140,
  64128: 28140,
  64129: 28140,
  64130: 28140,
  64131: 28140,
  64132: 28140,
  64133: 28140,
  64134: 28140,
  64136: 28140,
  64137: 28140,
  64138: 28140,
  64139: 28140,
  64141: 28140,
  64144: 28140,
  64145: 28140,
  64146: 28140,
  64147: 28140,
  64148: 28140,
  64149: 28140,
  64150: 28140,
  64151: 28140,
  64152: 28140,
  64153: 28140,
  64154: 28140,
  64155: 28140,
  64156: 28140,
  64157: 28140,
  64158: 28140,
  64161: 28140,
  64162: 28140,
  64163: 28140,
  64164: 28140,
  64165: 28140,
  64166: 28140,
  64167: 28140,
  64168: 28140,
  64170: 28140,
  64171: 28140,
  64188: 28140,
  64190: 28140,
  64197: 28140,
  64198: 28140,
  64199: 28140,
  64401: 41140,
  64402: 99999,
  64420: 99999,
  64421: 41140,
  64422: 41140,
  64423: 32340,
  64424: 99999,
  64426: 99999,
  64427: 32340,
  64428: 32340,
  64429: 99999,
  64430: 41140,
  64431: 32340,
  64432: 32340,
  64433: 32340,
  64434: 32340,
  64436: 41140,
  64437: 99999,
  64438: 99999,
  64439: 41140,
  64440: 28140,
  64441: 99999,
  64442: 99999,
  64443: 41140,
  64444: 41140,
  64445: 32340,
  64446: 99999,
  64448: 41140,
  64449: 41140,
  64451: 99999,
  64453: 99999,
  64454: 41140,
  64455: 41140,
  64456: 99999,
  64457: 41140,
  64458: 99999,
  64459: 41140,
  64461: 32340,
  64463: 41140,
  64465: 28140,
  64466: 99999,
  64467: 99999,
  64468: 32340,
  64469: 99999,
  64470: 99999,
  64471: 99999,
  64473: 99999,
  64474: 28140,
  64475: 99999,
  64476: 32340,
  64477: 28140,
  64479: 32340,
  64480: 41140,
  64481: 99999,
  64482: 99999,
  64483: 41140,
  64484: 28140,
  64485: 41140,
  64486: 32340,
  64487: 32340,
  64489: 99999,
  64490: 41140,
  64491: 99999,
  64492: 28140,
  64493: 28140,
  64494: 41140,
  64496: 99999,
  64497: 99999,
  64498: 99999,
  64499: 99999,
  64501: 41140,
  64502: 41140,
  64503: 41140,
  64504: 41140,
  64505: 41140,
  64506: 41140,
  64507: 41140,
  64508: 41140,
  64601: 99999,
  64620: 99999,
  64622: 99999,
  64623: 99999,
  64624: 28140,
  64625: 99999,
  64628: 99999,
  64630: 99999,
  64631: 99999,
  64632: 99999,
  64633: 99999,
  64635: 99999,
  64636: 99999,
  64637: 28140,
  64638: 99999,
  64639: 99999,
  64640: 99999,
  64641: 99999,
  64642: 99999,
  64643: 99999,
  64644: 28140,
  64645: 99999,
  64646: 99999,
  64647: 99999,
  64648: 99999,
  64649: 99999,
  64650: 28140,
  64651: 99999,
  64652: 99999,
  64653: 99999,
  64654: 99999,
  64655: 99999,
  64656: 99999,
  64657: 99999,
  64658: 99999,
  64659: 99999,
  64660: 99999,
  64661: 99999,
  64664: 99999,
  64667: 99999,
  64668: 99999,
  64670: 41140,
  64671: 28140,
  64672: 99999,
  64673: 99999,
  64674: 99999,
  64676: 99999,
  64679: 99999,
  64680: 99999,
  64681: 99999,
  64682: 99999,
  64683: 99999,
  64686: 99999,
  64688: 99999,
  64689: 99999,
  64701: 28140,
  64720: 28140,
  64722: 28140,
  64723: 28140,
  64724: 99999,
  64725: 28140,
  64726: 99999,
  64728: 99999,
  64730: 28140,
  64733: 99999,
  64734: 28140,
  64735: 99999,
  64738: 99999,
  64739: 28140,
  64740: 99999,
  64741: 99999,
  64742: 28140,
  64743: 28140,
  64744: 99999,
  64745: 28140,
  64746: 28140,
  64747: 28140,
  64748: 27900,
  64750: 99999,
  64752: 99999,
  64755: 27900,
  64756: 99999,
  64759: 99999,
  64761: 99999,
  64762: 99999,
  64763: 99999,
  64765: 99999,
  64767: 99999,
  64769: 99999,
  64770: 99999,
  64771: 99999,
  64772: 99999,
  64776: 99999,
  64778: 99999,
  64779: 99999,
  64780: 28140,
  64781: 99999,
  64783: 99999,
  64784: 99999,
  64788: 28140,
  64790: 99999,
  64801: 27900,
  64802: 27900,
  64803: 27900,
  64804: 27900,
  64830: 27900,
  64831: 99999,
  64832: 99999,
  64833: 27900,
  64834: 27900,
  64835: 27900,
  64836: 27900,
  64840: 27900,
  64841: 27900,
  64842: 27900,
  64843: 27900,
  64844: 27900,
  64847: 99999,
  64848: 27900,
  64849: 27900,
  64850: 99999,
  64854: 99999,
  64855: 27900,
  64856: 99999,
  64857: 27900,
  64858: 27900,
  64859: 27900,
  64861: 27900,
  64862: 27900,
  64863: 99999,
  64864: 27900,
  64865: 99999,
  64866: 27900,
  64867: 99999,
  64868: 99999,
  64870: 27900,
  64873: 99999,
  64874: 99999,
  64999: 28140,
  65001: 99999,
  65010: 17860,
  65011: 27620,
  65013: 99999,
  65014: 27620,
  65016: 27620,
  65017: 99999,
  65018: 27620,
  65020: 99999,
  65023: 27620,
  65024: 27620,
  65025: 17860,
  65026: 99999,
  65032: 27620,
  65034: 99999,
  65035: 27620,
  65037: 99999,
  65038: 99999,
  65039: 27620,
  65040: 27620,
  65041: 41180,
  65042: 27620,
  65043: 27620,
  65046: 27620,
  65047: 99999,
  65048: 27620,
  65049: 99999,
  65050: 27620,
  65051: 27620,
  65052: 99999,
  65053: 27620,
  65054: 27620,
  65055: 27620,
  65058: 99999,
  65059: 27620,
  65061: 99999,
  65062: 27620,
  65063: 27620,
  65064: 27620,
  65065: 99999,
  65066: 41180,
  65067: 27620,
  65068: 27620,
  65069: 99999,
  65072: 99999,
  65074: 99999,
  65075: 99999,
  65076: 27620,
  65077: 27620,
  65078: 42740,
  65079: 99999,
  65080: 27620,
  65081: 27620,
  65082: 99999,
  65083: 99999,
  65084: 99999,
  65085: 27620,
  65101: 27620,
  65102: 27620,
  65103: 27620,
  65104: 27620,
  65105: 27620,
  65109: 27620,
  65110: 27620,
  65111: 27620,
  65201: 27620,
  65202: 17860,
  65203: 17860,
  65205: 17860,
  65211: 17860,
  65212: 17860,
  65215: 17860,
  65216: 17860,
  65217: 17860,
  65218: 17860,
  65230: 17860,
  65231: 33020,
  65232: 33020,
  65233: 17860,
  65236: 99999,
  65237: 17860,
  65239: 33620,
  65240: 33020,
  65243: 99999,
  65244: 33620,
  65246: 99999,
  65247: 99999,
  65248: 17860,
  65250: 17860,
  65251: 27620,
  65254: 17860,
  65255: 17860,
  65256: 17860,
  65257: 33620,
  65258: 99999,
  65259: 33620,
  65260: 99999,
  65261: 99999,
  65262: 27620,
  65263: 99999,
  65264: 33020,
  65265: 99999,
  65270: 99999,
  65274: 17860,
  65275: 99999,
  65276: 17860,
  65278: 33620,
  65279: 17860,
  65280: 33020,
  65281: 17860,
  65282: 99999,
  65283: 99999,
  65284: 33020,
  65285: 33020,
  65286: 99999,
  65287: 17860,
  65299: 17860,
  65301: 17860,
  65302: 42740,
  65305: 47660,
  65320: 32180,
  65321: 32180,
  65322: 32180,
  65323: 99999,
  65324: 99999,
  65325: 42740,
  65326: 99999,
  65327: 32180,
  65329: 99999,
  65330: 32180,
  65332: 42740,
  65333: 32180,
  65334: 42740,
  65335: 42740,
  65336: 42740,
  65337: 47660,
  65338: 99999,
  65339: 32180,
  65340: 42740,
  65344: 32180,
  65345: 42740,
  65347: 42740,
  65348: 42740,
  65349: 32180,
  65350: 17860,
  65351: 28140,
  65354: 99999,
  65355: 99999,
  65360: 42740,
  65401: 40620,
  65402: 40620,
  65409: 40620,
  65436: 99999,
  65438: 99999,
  65439: 99999,
  65440: 99999,
  65441: 41180,
  65443: 99999,
  65444: 99999,
  65446: 99999,
  65449: 99999,
  65452: 99999,
  65453: 41180,
  65456: 99999,
  65457: 22780,
  65459: 99999,
  65461: 22780,
  65462: 40620,
  65463: 44180,
  65464: 99999,
  65466: 99999,
  65468: 99999,
  65470: 99999,
  65473: 22780,
  65479: 99999,
  65483: 99999,
  65484: 99999,
  65486: 99999,
  65501: 99999,
  65529: 40620,
  65532: 99999,
  65534: 30060,
  65535: 99999,
  65536: 99999,
  65541: 99999,
  65542: 99999,
  65543: 99999,
  65546: 99999,
  65548: 99999,
  65550: 40620,
  65552: 30060,
  65555: 99999,
  65556: 22780,
  65557: 99999,
  65559: 99999,
  65560: 99999,
  65564: 99999,
  65565: 99999,
  65566: 99999,
  65567: 30060,
  65570: 99999,
  65571: 99999,
  65580: 99999,
  65582: 27620,
  65583: 22780,
  65584: 22780,
  65586: 99999,
  65588: 99999,
  65589: 99999,
  65590: 44180,
  65591: 99999,
  65601: 44180,
  65603: 99999,
  65604: 44180,
  65605: 99999,
  65606: 99999,
  65607: 99999,
  65608: 14700,
  65609: 99999,
  65610: 99999,
  65611: 99999,
  65612: 99999,
  65613: 44180,
  65614: 14700,
  65615: 14700,
  65616: 99999,
  65617: 44180,
  65618: 99999,
  65619: 44180,
  65620: 44180,
  65622: 44180,
  65623: 99999,
  65624: 99999,
  65625: 99999,
  65626: 99999,
  65627: 14700,
  65629: 44180,
  65630: 44180,
  65631: 44180,
  65632: 44180,
  65633: 99999,
  65634: 99999,
  65635: 99999,
  65636: 44180,
  65637: 99999,
  65638: 99999,
  65640: 99999,
  65641: 99999,
  65644: 44180,
  65646: 99999,
  65647: 99999,
  65648: 44180,
  65649: 44180,
  65650: 99999,
  65652: 99999,
  65653: 44180,
  65654: 99999,
  65655: 99999,
  65656: 44180,
  65657: 44180,
  65658: 99999,
  65660: 99999,
  65661: 99999,
  65662: 44180,
  65663: 44180,
  65664: 99999,
  65666: 99999,
  65667: 44180,
  65668: 99999,
  65669: 99999,
  65672: 14700,
  65673: 14700,
  65674: 99999,
  65675: 99999,
  65676: 99999,
  65679: 14700,
  65680: 14700,
  65681: 99999,
  65682: 99999,
  65685: 44180,
  65686: 99999,
  65688: 48460,
  65689: 48460,
  65690: 99999,
  65692: 48460,
  65702: 99999,
  65704: 99999,
  65705: 44180,
  65706: 99999,
  65707: 99999,
  65708: 99999,
  65710: 44180,
  65711: 99999,
  65712: 99999,
  65713: 44180,
  65714: 44180,
  65715: 99999,
  65717: 99999,
  65720: 99999,
  65721: 44180,
  65722: 30060,
  65723: 27900,
  65724: 99999,
  65725: 44180,
  65726: 14700,
  65727: 44180,
  65728: 44180,
  65729: 99999,
  65730: 99999,
  65731: 14700,
  65732: 99999,
  65733: 99999,
  65734: 99999,
  65735: 99999,
  65737: 44180,
  65738: 99999,
  65739: 14700,
  65740: 14700,
  65741: 99999,
  65742: 44180,
  65744: 14700,
  65745: 99999,
  65746: 99999,
  65747: 99999,
  65752: 99999,
  65753: 44180,
  65754: 99999,
  65755: 99999,
  65756: 99999,
  65757: 44180,
  65759: 44180,
  65760: 99999,
  65761: 99999,
  65762: 99999,
  65764: 44180,
  65765: 44180,
  65766: 99999,
  65767: 99999,
  65768: 99999,
  65769: 99999,
  65770: 99999,
  65771: 44180,
  65772: 99999,
  65773: 99999,
  65774: 99999,
  65775: 48460,
  65777: 48460,
  65778: 99999,
  65779: 99999,
  65781: 44180,
  65783: 44180,
  65784: 99999,
  65785: 99999,
  65786: 44180,
  65787: 99999,
  65788: 48460,
  65789: 48460,
  65790: 48460,
  65791: 48460,
  65793: 99999,
  65801: 44180,
  65802: 44180,
  65803: 44180,
  65804: 44180,
  65805: 44180,
  65806: 44180,
  65807: 44180,
  65808: 44180,
  65809: 44180,
  65810: 44180,
  65814: 44180,
  65890: 44180,
  65897: 44180,
  65898: 44180,
  65899: 44180,
  66002: 41140,
  66006: 29940,
  66007: 28140,
  66008: 41140,
  66010: 99999,
  66012: 28140,
  66013: 28140,
  66014: 28140,
  66015: 99999,
  66016: 11860,
  66017: 99999,
  66018: 28140,
  66020: 28140,
  66021: 29940,
  66023: 11860,
  66024: 41140,
  66025: 28140,
  66026: 28140,
  66027: 28140,
  66030: 28140,
  66031: 28140,
  66032: 99999,
  66033: 28140,
  66035: 41140,
  66036: 28140,
  66039: 99999,
  66040: 28140,
  66041: 11860,
  66042: 36840,
  66043: 28140,
  66044: 29940,
  66045: 29940,
  66046: 29940,
  66047: 29940,
  66048: 28140,
  66049: 29940,
  66050: 29940,
  66051: 28140,
  66052: 28140,
  66053: 28140,
  66054: 28140,
  66056: 28140,
  66058: 45820,
  66060: 11860,
  66061: 28140,
  66062: 28140,
  66063: 28140,
  66064: 36840,
  66066: 45820,
  66067: 36840,
  66070: 45820,
  66071: 28140,
  66072: 28140,
  66073: 45820,
  66075: 28140,
  66076: 36840,
  66078: 36840,
  66079: 28140,
  66080: 99999,
  66083: 28140,
  66085: 28140,
  66086: 45820,
  66087: 41140,
  66088: 45820,
  66090: 41140,
  66091: 99999,
  66092: 29940,
  66093: 99999,
  66094: 99999,
  66095: 45820,
  66097: 28140,
  66101: 28140,
  66102: 28140,
  66103: 28140,
  66104: 28140,
  66105: 28140,
  66106: 28140,
  66109: 28140,
  66111: 28140,
  66112: 28140,
  66115: 28140,
  66117: 28140,
  66118: 28140,
  66119: 28140,
  66160: 28140,
  66201: 28140,
  66202: 28140,
  66203: 28140,
  66204: 28140,
  66205: 28140,
  66206: 28140,
  66207: 28140,
  66208: 28140,
  66209: 28140,
  66210: 28140,
  66211: 28140,
  66212: 28140,
  66213: 28140,
  66214: 28140,
  66215: 28140,
  66216: 28140,
  66217: 28140,
  66218: 28140,
  66219: 28140,
  66220: 28140,
  66221: 28140,
  66223: 28140,
  66224: 28140,
  66225: 28140,
  66226: 28140,
  66227: 28140,
  66250: 28140,
  66251: 28140,
  66276: 28140,
  66282: 28140,
  66283: 28140,
  66285: 28140,
  66286: 28140,
  66401: 45820,
  66402: 45820,
  66403: 99999,
  66404: 99999,
  66406: 99999,
  66407: 45820,
  66408: 99999,
  66409: 29940,
  66411: 99999,
  66412: 99999,
  66413: 45820,
  66414: 45820,
  66415: 99999,
  66416: 45820,
  66417: 99999,
  66418: 45820,
  66419: 45820,
  66420: 45820,
  66422: 31740,
  66423: 45820,
  66424: 11860,
  66425: 99999,
  66427: 31740,
  66428: 45820,
  66429: 45820,
  66431: 45820,
  66432: 99999,
  66434: 99999,
  66436: 11860,
  66438: 99999,
  66439: 45820,
  66440: 45820,
  66441: 31740,
  66442: 31740,
  66449: 31740,
  66451: 45820,
  66501: 45820,
  66502: 45820,
  66503: 31740,
  66505: 31740,
  66506: 31740,
  66507: 45820,
  66508: 99999,
  66509: 45820,
  66510: 45820,
  66512: 45820,
  66514: 31740,
  66515: 99999,
  66516: 45820,
  66517: 31740,
  66518: 99999,
  66520: 31740,
  66521: 99999,
  66522: 99999,
  66523: 21380,
  66524: 36840,
  66526: 45820,
  66527: 99999,
  66528: 36840,
  66531: 31740,
  66532: 99999,
  66533: 45820,
  66534: 99999,
  66535: 31740,
  66536: 45820,
  66537: 45820,
  66538: 99999,
  66539: 45820,
  66540: 99999,
  66541: 99999,
  66542: 45820,
  66543: 45820,
  66544: 99999,
  66546: 45820,
  66547: 45820,
  66548: 99999,
  66549: 99999,
  66550: 99999,
  66552: 11860,
  66554: 31740,
  66601: 45820,
  66603: 45820,
  66604: 45820,
  66605: 45820,
  66606: 45820,
  66607: 45820,
  66608: 45820,
  66609: 45820,
  66610: 45820,
  66611: 45820,
  66612: 45820,
  66614: 45820,
  66615: 45820,
  66616: 45820,
  66617: 45820,
  66618: 45820,
  66619: 45820,
  66620: 45820,
  66621: 45820,
  66622: 45820,
  66624: 45820,
  66625: 45820,
  66626: 45820,
  66629: 45820,
  66630: 45820,
  66636: 45820,
  66647: 45820,
  66667: 45820,
  66675: 45820,
  66683: 45820,
  66699: 45820,
  66701: 99999,
  66710: 99999,
  66711: 99999,
  66712: 38260,
  66713: 99999,
  66714: 99999,
  66716: 28140,
  66717: 99999,
  66720: 99999,
  66724: 38260,
  66725: 99999,
  66728: 99999,
  66732: 99999,
  66733: 99999,
  66734: 38260,
  66735: 38260,
  66736: 17700,
  66738: 99999,
  66739: 99999,
  66740: 99999,
  66741: 99999,
  66742: 99999,
  66743: 38260,
  66746: 38260,
  66748: 99999,
  66749: 99999,
  66751: 99999,
  66753: 37660,
  66754: 28140,
  66755: 99999,
  66756: 38260,
  66757: 17700,
  66758: 99999,
  66760: 38260,
  66761: 99999,
  66762: 38260,
  66763: 38260,
  66767: 28140,
  66769: 99999,
  66770: 99999,
  66771: 99999,
  66772: 99999,
  66773: 99999,
  66775: 99999,
  66776: 37660,
  66777: 99999,
  66778: 99999,
  66779: 99999,
  66780: 38260,
  66781: 99999,
  66782: 99999,
  66783: 99999,
  66801: 21380,
  66830: 21380,
  66833: 21380,
  66834: 99999,
  66835: 21380,
  66838: 99999,
  66839: 99999,
  66840: 21380,
  66842: 48620,
  66843: 21380,
  66845: 21380,
  66846: 45820,
  66849: 99999,
  66850: 21380,
  66851: 99999,
  66852: 99999,
  66853: 99999,
  66854: 99999,
  66855: 99999,
  66856: 45820,
  66857: 99999,
  66858: 21380,
  66859: 99999,
  66860: 21380,
  66861: 99999,
  66862: 21380,
  66863: 99999,
  66864: 99999,
  66865: 21380,
  66866: 48620,
  66868: 99999,
  66869: 21380,
  66870: 99999,
  66871: 99999,
  66872: 31740,
  66873: 99999,
  66901: 99999,
  66930: 99999,
  66932: 99999,
  66933: 99999,
  66935: 99999,
  66936: 99999,
  66937: 99999,
  66938: 99999,
  66939: 99999,
  66940: 99999,
  66941: 99999,
  66942: 99999,
  66943: 99999,
  66944: 99999,
  66945: 99999,
  66946: 99999,
  66948: 99999,
  66949: 99999,
  66951: 99999,
  66952: 99999,
  66953: 99999,
  66955: 99999,
  66956: 99999,
  66958: 99999,
  66959: 99999,
  66960: 99999,
  66961: 99999,
  66962: 99999,
  66963: 99999,
  66964: 99999,
  66966: 99999,
  66967: 99999,
  66968: 99999,
  66970: 99999,
  67001: 48620,
  67002: 48620,
  67003: 99999,
  67004: 99999,
  67005: 49060,
  67008: 48620,
  67009: 99999,
  67010: 48620,
  67012: 48620,
  67013: 48620,
  67016: 48620,
  67017: 48620,
  67018: 99999,
  67019: 49060,
  67020: 48620,
  67021: 99999,
  67022: 48620,
  67023: 49060,
  67024: 99999,
  67025: 99999,
  67026: 48620,
  67028: 99999,
  67029: 99999,
  67030: 48620,
  67031: 48620,
  67035: 26740,
  67036: 99999,
  67037: 48620,
  67038: 49060,
  67039: 49060,
  67041: 48620,
  67042: 48620,
  67045: 99999,
  67047: 99999,
  67049: 99999,
  67050: 48620,
  67051: 49060,
  67052: 48620,
  67053: 99999,
  67054: 99999,
  67055: 48620,
  67056: 48620,
  67057: 99999,
  67058: 99999,
  67059: 99999,
  67060: 48620,
  67061: 99999,
  67062: 48620,
  67063: 99999,
  67065: 99999,
  67066: 99999,
  67067: 48620,
  67068: 99999,
  67070: 99999,
  67071: 99999,
  67072: 49060,
  67073: 99999,
  67074: 48620,
  67101: 48620,
  67102: 49060,
  67103: 48620,
  67104: 99999,
  67105: 48620,
  67106: 99999,
  67107: 32700,
  67108: 48620,
  67109: 99999,
  67110: 48620,
  67111: 99999,
  67112: 99999,
  67114: 99999,
  67117: 48620,
  67118: 48620,
  67119: 48620,
  67120: 48620,
  67122: 99999,
  67123: 48620,
  67124: 99999,
  67127: 99999,
  67131: 48620,
  67132: 48620,
  67133: 48620,
  67134: 99999,
  67135: 48620,
  67137: 99999,
  67138: 99999,
  67140: 48620,
  67142: 99999,
  67143: 99999,
  67144: 48620,
  67146: 48620,
  67147: 48620,
  67149: 48620,
  67150: 99999,
  67151: 99999,
  67152: 48620,
  67154: 48620,
  67155: 99999,
  67156: 49060,
  67159: 99999,
  67201: 48620,
  67202: 48620,
  67203: 48620,
  67204: 48620,
  67205: 48620,
  67206: 48620,
  67207: 48620,
  67208: 48620,
  67209: 48620,
  67210: 48620,
  67211: 48620,
  67212: 48620,
  67213: 48620,
  67214: 48620,
  67215: 48620,
  67216: 48620,
  67217: 48620,
  67218: 48620,
  67219: 48620,
  67220: 48620,
  67221: 48620,
  67223: 48620,
  67226: 48620,
  67227: 48620,
  67228: 48620,
  67230: 48620,
  67232: 48620,
  67235: 48620,
  67260: 48620,
  67275: 48620,
  67276: 48620,
  67277: 48620,
  67278: 48620,
  67301: 17700,
  67330: 37660,
  67332: 37660,
  67333: 17700,
  67334: 99999,
  67335: 37660,
  67336: 37660,
  67337: 37660,
  67340: 17700,
  67341: 99999,
  67342: 37660,
  67344: 99999,
  67345: 99999,
  67346: 99999,
  67347: 17700,
  67349: 99999,
  67351: 17700,
  67352: 99999,
  67353: 99999,
  67354: 37660,
  67355: 99999,
  67356: 37660,
  67357: 99999,
  67360: 99999,
  67361: 99999,
  67363: 17700,
  67364: 17700,
  67401: 41460,
  67402: 41460,
  67410: 99999,
  67416: 41460,
  67417: 99999,
  67418: 99999,
  67420: 99999,
  67422: 41460,
  67423: 99999,
  67425: 41460,
  67427: 24460,
  67428: 32700,
  67430: 99999,
  67431: 99999,
  67432: 99999,
  67436: 99999,
  67437: 99999,
  67438: 99999,
  67439: 99999,
  67441: 99999,
  67442: 41460,
  67443: 32700,
  67444: 99999,
  67445: 99999,
  67446: 99999,
  67447: 31740,
  67448: 32700,
  67449: 99999,
  67450: 99999,
  67451: 99999,
  67452: 99999,
  67454: 99999,
  67455: 99999,
  67456: 32700,
  67457: 99999,
  67458: 99999,
  67459: 99999,
  67460: 32700,
  67464: 32700,
  67466: 41460,
  67467: 99999,
  67468: 99999,
  67470: 41460,
  67473: 99999,
  67474: 99999,
  67475: 99999,
  67476: 32700,
  67478: 99999,
  67480: 99999,
  67481: 99999,
  67482: 99999,
  67483: 99999,
  67484: 41460,
  67485: 99999,
  67487: 99999,
  67490: 99999,
  67491: 99999,
  67492: 99999,
  67501: 26740,
  67502: 26740,
  67505: 26740,
  67510: 26740,
  67511: 99999,
  67512: 26740,
  67513: 99999,
  67514: 26740,
  67515: 99999,
  67516: 99999,
  67518: 99999,
  67519: 99999,
  67520: 25700,
  67521: 99999,
  67522: 48620,
  67523: 99999,
  67524: 99999,
  67525: 24460,
  67526: 99999,
  67529: 99999,
  67530: 24460,
  67543: 26740,
  67544: 24460,
  67545: 99999,
  67546: 99999,
  67547: 99999,
  67548: 99999,
  67550: 99999,
  67552: 99999,
  67553: 99999,
  67554: 99999,
  67556: 25700,
  67557: 99999,
  67559: 99999,
  67560: 99999,
  67561: 99999,
  67563: 19980,
  67564: 24460,
  67565: 24460,
  67566: 26740,
  67567: 24460,
  67568: 26740,
  67570: 99999,
  67572: 99999,
  67573: 99999,
  67574: 99999,
  67575: 99999,
  67576: 99999,
  67578: 99999,
  67579: 26740,
  67581: 26740,
  67583: 99999,
  67584: 99999,
  67585: 26740,
  67601: 25700,
  67621: 99999,
  67622: 99999,
  67623: 99999,
  67625: 99999,
  67626: 99999,
  67627: 25700,
  67628: 99999,
  67629: 99999,
  67631: 99999,
  67632: 99999,
  67634: 99999,
  67635: 99999,
  67637: 25700,
  67638: 99999,
  67639: 99999,
  67640: 99999,
  67642: 99999,
  67643: 99999,
  67644: 99999,
  67645: 99999,
  67646: 99999,
  67647: 99999,
  67648: 99999,
  67649: 99999,
  67650: 99999,
  67651: 99999,
  67653: 99999,
  67654: 99999,
  67656: 99999,
  67657: 99999,
  67658: 99999,
  67659: 25700,
  67660: 25700,
  67661: 99999,
  67663: 99999,
  67664: 99999,
  67665: 24460,
  67667: 25700,
  67669: 99999,
  67671: 25700,
  67672: 99999,
  67673: 99999,
  67674: 25700,
  67675: 99999,
  67701: 99999,
  67730: 99999,
  67731: 99999,
  67732: 99999,
  67733: 99999,
  67734: 99999,
  67735: 99999,
  67736: 99999,
  67737: 99999,
  67738: 99999,
  67739: 99999,
  67740: 99999,
  67741: 99999,
  67743: 99999,
  67744: 99999,
  67745: 99999,
  67747: 99999,
  67748: 99999,
  67749: 99999,
  67751: 99999,
  67752: 99999,
  67753: 99999,
  67756: 99999,
  67757: 99999,
  67758: 99999,
  67761: 99999,
  67762: 99999,
  67764: 99999,
  67801: 99999,
  67831: 99999,
  67834: 99999,
  67835: 19980,
  67836: 99999,
  67837: 99999,
  67838: 23780,
  67839: 23780,
  67840: 99999,
  67841: 19980,
  67842: 19980,
  67843: 99999,
  67844: 19980,
  67846: 99999,
  67849: 99999,
  67850: 99999,
  67851: 99999,
  67853: 23780,
  67854: 99999,
  67855: 99999,
  67857: 99999,
  67859: 30580,
  67860: 99999,
  67861: 99999,
  67862: 99999,
  67863: 99999,
  67864: 99999,
  67865: 19980,
  67867: 99999,
  67868: 23780,
  67869: 30580,
  67870: 30580,
  67871: 99999,
  67876: 19980,
  67877: 30580,
  67878: 99999,
  67879: 99999,
  67880: 99999,
  67882: 19980,
  67901: 30580,
  67950: 99999,
  67951: 99999,
  67952: 30580,
  67953: 99999,
  67954: 99999,
  68001: 99999,
  68002: 36540,
  68003: 36540,
  68004: 99999,
  68005: 36540,
  68007: 36540,
  68008: 36540,
  68010: 36540,
  68014: 99999,
  68015: 36540,
  68016: 36540,
  68017: 30700,
  68018: 36540,
  68019: 99999,
  68020: 99999,
  68022: 36540,
  68023: 36540,
  68025: 23340,
  68026: 23340,
  68028: 36540,
  68029: 99999,
  68030: 43580,
  68031: 99999,
  68033: 36540,
  68034: 36540,
  68036: 36540,
  68037: 36540,
  68038: 99999,
  68039: 99999,
  68040: 36540,
  68041: 36540,
  68042: 36540,
  68044: 36540,
  68045: 99999,
  68046: 36540,
  68047: 99999,
  68048: 36540,
  68050: 36540,
  68055: 99999,
  68056: 36540,
  68057: 23340,
  68058: 36540,
  68059: 36540,
  68061: 99999,
  68062: 99999,
  68063: 23340,
  68064: 36540,
  68065: 30700,
  68066: 36540,
  68067: 99999,
  68068: 36540,
  68069: 36540,
  68070: 36540,
  68071: 99999,
  68072: 23340,
  68073: 36540,
  68102: 36540,
  68103: 36540,
  68104: 36540,
  68105: 36540,
  68106: 36540,
  68107: 36540,
  68108: 36540,
  68109: 36540,
  68110: 36540,
  68111: 36540,
  68112: 36540,
  68113: 36540,
  68114: 36540,
  68116: 36540,
  68117: 36540,
  68118: 36540,
  68122: 36540,
  68123: 36540,
  68124: 36540,
  68127: 36540,
  68128: 36540,
  68130: 36540,
  68131: 36540,
  68132: 36540,
  68133: 36540,
  68134: 36540,
  68135: 36540,
  68136: 36540,
  68137: 36540,
  68138: 36540,
  68139: 36540,
  68142: 36540,
  68144: 36540,
  68145: 36540,
  68147: 36540,
  68152: 36540,
  68154: 36540,
  68157: 36540,
  68164: 36540,
  68175: 36540,
  68178: 36540,
  68179: 36540,
  68180: 36540,
  68182: 36540,
  68183: 36540,
  68197: 36540,
  68198: 36540,
  68301: 30700,
  68303: 99999,
  68304: 36540,
  68305: 99999,
  68307: 36540,
  68309: 13100,
  68310: 13100,
  68313: 30700,
  68314: 30700,
  68315: 99999,
  68316: 99999,
  68317: 30700,
  68318: 13100,
  68319: 99999,
  68320: 99999,
  68321: 99999,
  68322: 99999,
  68323: 99999,
  68324: 99999,
  68325: 99999,
  68326: 99999,
  68327: 99999,
  68328: 13100,
  68329: 99999,
  68330: 30700,
  68331: 30700,
  68332: 99999,
  68333: 30700,
  68335: 99999,
  68336: 30700,
  68337: 99999,
  68338: 99999,
  68339: 30700,
  68340: 99999,
  68341: 99999,
  68342: 13100,
  68343: 30700,
  68344: 99999,
  68345: 99999,
  68346: 99999,
  68347: 99999,
  68348: 99999,
  68349: 36540,
  68350: 99999,
  68351: 99999,
  68352: 99999,
  68354: 99999,
  68355: 99999,
  68357: 99999,
  68358: 30700,
  68359: 99999,
  68360: 30700,
  68361: 99999,
  68362: 99999,
  68364: 30700,
  68365: 99999,
  68366: 30700,
  68367: 30700,
  68368: 30700,
  68370: 99999,
  68371: 99999,
  68372: 30700,
  68375: 99999,
  68376: 99999,
  68377: 99999,
  68378: 99999,
  68379: 99999,
  68380: 99999,
  68381: 13100,
  68382: 99999,
  68401: 99999,
  68402: 30700,
  68403: 36540,
  68404: 30700,
  68405: 30700,
  68406: 99999,
  68407: 36540,
  68409: 36540,
  68410: 99999,
  68413: 99999,
  68414: 99999,
  68415: 13100,
  68416: 99999,
  68417: 99999,
  68418: 36540,
  68419: 30700,
  68420: 99999,
  68421: 99999,
  68422: 13100,
  68423: 99999,
  68424: 13100,
  68428: 30700,
  68429: 99999,
  68430: 30700,
  68431: 99999,
  68433: 99999,
  68434: 30700,
  68436: 99999,
  68437: 99999,
  68438: 30700,
  68439: 30700,
  68440: 99999,
  68441: 99999,
  68442: 99999,
  68443: 99999,
  68444: 99999,
  68445: 99999,
  68446: 99999,
  68447: 99999,
  68448: 99999,
  68450: 99999,
  68452: 99999,
  68453: 99999,
  68454: 36540,
  68455: 36540,
  68456: 30700,
  68457: 99999,
  68458: 99999,
  68460: 30700,
  68461: 30700,
  68462: 30700,
  68463: 36540,
  68464: 99999,
  68465: 13100,
  68466: 13100,
  68467: 99999,
  68501: 30700,
  68502: 30700,
  68503: 30700,
  68504: 30700,
  68505: 30700,
  68506: 30700,
  68507: 30700,
  68508: 30700,
  68510: 30700,
  68512: 30700,
  68514: 30700,
  68516: 30700,
  68517: 30700,
  68520: 30700,
  68521: 30700,
  68522: 30700,
  68523: 30700,
  68524: 30700,
  68526: 30700,
  68527: 30700,
  68528: 30700,
  68529: 30700,
  68531: 30700,
  68532: 30700,
  68542: 30700,
  68583: 30700,
  68588: 30700,
  68601: 18100,
  68602: 18100,
  68620: 99999,
  68621: 23340,
  68622: 99999,
  68623: 99999,
  68624: 99999,
  68626: 99999,
  68627: 99999,
  68628: 99999,
  68629: 35740,
  68631: 35740,
  68632: 99999,
  68633: 23340,
  68634: 18100,
  68635: 99999,
  68636: 99999,
  68637: 99999,
  68638: 99999,
  68640: 99999,
  68641: 35740,
  68642: 18100,
  68643: 35740,
  68644: 18100,
  68647: 18100,
  68648: 36540,
  68649: 23340,
  68651: 99999,
  68652: 99999,
  68653: 18100,
  68654: 99999,
  68655: 99999,
  68658: 99999,
  68659: 99999,
  68660: 99999,
  68661: 99999,
  68662: 99999,
  68663: 24260,
  68664: 23340,
  68665: 99999,
  68666: 99999,
  68667: 99999,
  68669: 30700,
  68701: 99999,
  68702: 35740,
  68710: 43580,
  68711: 99999,
  68713: 99999,
  68714: 99999,
  68715: 35740,
  68716: 99999,
  68717: 99999,
  68718: 99999,
  68719: 99999,
  68720: 99999,
  68722: 99999,
  68723: 99999,
  68724: 99999,
  68725: 99999,
  68726: 99999,
  68727: 99999,
  68728: 43580,
  68729: 35740,
  68730: 99999,
  68731: 43580,
  68732: 43580,
  68733: 43580,
  68734: 99999,
  68735: 99999,
  68736: 99999,
  68738: 35740,
  68739: 43580,
  68740: 35740,
  68741: 43580,
  68742: 99999,
  68743: 43580,
  68745: 99999,
  68746: 99999,
  68747: 35740,
  68748: 35740,
  68749: 99999,
  68751: 43580,
  68752: 35740,
  68753: 99999,
  68755: 99999,
  68756: 99999,
  68757: 43580,
  68758: 35740,
  68759: 99999,
  68760: 99999,
  68761: 99999,
  68763: 99999,
  68764: 99999,
  68765: 35740,
  68766: 99999,
  68767: 99999,
  68768: 99999,
  68769: 99999,
  68770: 43580,
  68771: 35740,
  68773: 99999,
  68774: 99999,
  68776: 43580,
  68777: 99999,
  68778: 99999,
  68779: 35740,
  68780: 99999,
  68781: 35740,
  68783: 99999,
  68784: 43580,
  68785: 43580,
  68786: 99999,
  68787: 43580,
  68788: 23340,
  68789: 99999,
  68790: 99999,
  68791: 99999,
  68792: 43580,
  68801: 24260,
  68802: 24260,
  68803: 24260,
  68810: 24260,
  68812: 28260,
  68813: 99999,
  68814: 99999,
  68815: 99999,
  68816: 24260,
  68817: 24260,
  68818: 99999,
  68820: 24260,
  68821: 99999,
  68822: 99999,
  68823: 99999,
  68824: 24260,
  68825: 99999,
  68826: 24260,
  68827: 24260,
  68828: 99999,
  68831: 24260,
  68832: 99999,
  68833: 35820,
  68834: 99999,
  68835: 24260,
  68836: 28260,
  68837: 99999,
  68838: 24260,
  68840: 28260,
  68841: 99999,
  68842: 99999,
  68843: 99999,
  68844: 99999,
  68845: 28260,
  68846: 99999,
  68847: 28260,
  68848: 28260,
  68849: 28260,
  68850: 99999,
  68852: 99999,
  68853: 99999,
  68854: 99999,
  68855: 99999,
  68856: 99999,
  68858: 30420,
  68859: 99999,
  68860: 99999,
  68861: 28260,
  68862: 99999,
  68863: 30420,
  68864: 24260,
  68865: 24260,
  68866: 99999,
  68869: 24260,
  68870: 28260,
  68871: 99999,
  68872: 24260,
  68873: 24260,
  68874: 99999,
  68875: 99999,
  68876: 28260,
  68878: 99999,
  68879: 99999,
  68881: 99999,
  68882: 24260,
  68883: 24260,
  68901: 25580,
  68902: 25580,
  68920: 99999,
  68922: 99999,
  68923: 99999,
  68924: 28260,
  68925: 25580,
  68926: 99999,
  68927: 99999,
  68928: 25580,
  68929: 99999,
  68930: 99999,
  68932: 99999,
  68933: 99999,
  68934: 99999,
  68935: 99999,
  68936: 30420,
  68937: 30420,
  68938: 99999,
  68939: 28260,
  68940: 99999,
  68941: 25580,
  68942: 99999,
  68943: 99999,
  68944: 99999,
  68945: 25580,
  68946: 99999,
  68947: 28260,
  68948: 99999,
  68949: 99999,
  68950: 25580,
  68952: 99999,
  68954: 99999,
  68955: 25580,
  68956: 25580,
  68957: 25580,
  68958: 99999,
  68959: 28260,
  68960: 99999,
  68961: 99999,
  68964: 99999,
  68966: 99999,
  68967: 30420,
  68969: 99999,
  68970: 99999,
  68971: 99999,
  68972: 28260,
  68973: 25580,
  68974: 99999,
  68975: 99999,
  68976: 30420,
  68977: 99999,
  68978: 99999,
  68979: 99999,
  68980: 25580,
  68981: 28260,
  68982: 28260,
  69001: 99999,
  69020: 99999,
  69021: 99999,
  69022: 30420,
  69023: 99999,
  69024: 99999,
  69025: 35820,
  69026: 99999,
  69027: 99999,
  69028: 30420,
  69029: 30420,
  69030: 99999,
  69032: 99999,
  69033: 99999,
  69034: 99999,
  69036: 99999,
  69037: 99999,
  69038: 35820,
  69039: 35820,
  69040: 99999,
  69041: 99999,
  69042: 99999,
  69043: 99999,
  69044: 99999,
  69045: 99999,
  69046: 99999,
  69101: 35820,
  69103: 35820,
  69120: 99999,
  69121: 99999,
  69122: 99999,
  69123: 35820,
  69125: 99999,
  69127: 99999,
  69128: 99999,
  69129: 99999,
  69130: 30420,
  69131: 99999,
  69132: 35820,
  69133: 99999,
  69134: 99999,
  69135: 99999,
  69138: 35820,
  69140: 99999,
  69141: 99999,
  69142: 99999,
  69143: 35820,
  69144: 99999,
  69145: 99999,
  69146: 99999,
  69147: 99999,
  69148: 99999,
  69149: 99999,
  69150: 99999,
  69151: 35820,
  69152: 99999,
  69153: 99999,
  69154: 99999,
  69155: 35820,
  69156: 99999,
  69157: 99999,
  69161: 99999,
  69162: 99999,
  69163: 99999,
  69165: 35820,
  69166: 99999,
  69167: 99999,
  69168: 99999,
  69169: 99999,
  69170: 35820,
  69171: 30420,
  69201: 99999,
  69210: 99999,
  69211: 99999,
  69212: 99999,
  69214: 99999,
  69216: 99999,
  69217: 99999,
  69218: 99999,
  69219: 99999,
  69220: 99999,
  69221: 99999,
  69301: 99999,
  69331: 99999,
  69333: 99999,
  69334: 42420,
  69335: 99999,
  69336: 99999,
  69337: 99999,
  69339: 99999,
  69340: 99999,
  69341: 42420,
  69343: 99999,
  69345: 42420,
  69346: 42420,
  69347: 99999,
  69348: 42420,
  69350: 99999,
  69351: 99999,
  69352: 42420,
  69353: 42420,
  69354: 99999,
  69355: 42420,
  69356: 42420,
  69357: 42420,
  69358: 42420,
  69360: 99999,
  69361: 42420,
  69363: 42420,
  69365: 99999,
  69366: 99999,
  69367: 99999,
  70001: 35380,
  70002: 35380,
  70003: 35380,
  70004: 35380,
  70005: 35380,
  70006: 35380,
  70009: 35380,
  70010: 35380,
  70011: 35380,
  70030: 35380,
  70031: 35380,
  70032: 35380,
  70033: 35380,
  70036: 35380,
  70037: 35380,
  70039: 35380,
  70040: 35380,
  70041: 35380,
  70043: 35380,
  70044: 35380,
  70047: 35380,
  70049: 35380,
  70050: 35380,
  70051: 35380,
  70052: 35380,
  70053: 35380,
  70054: 35380,
  70055: 35380,
  70056: 35380,
  70057: 35380,
  70058: 35380,
  70059: 35380,
  70062: 35380,
  70063: 35380,
  70064: 35380,
  70065: 35380,
  70067: 35380,
  70068: 35380,
  70069: 35380,
  70070: 35380,
  70071: 35380,
  70072: 35380,
  70073: 35380,
  70075: 35380,
  70076: 35380,
  70078: 35380,
  70079: 35380,
  70080: 35380,
  70082: 35380,
  70083: 35380,
  70084: 35380,
  70085: 35380,
  70086: 35380,
  70087: 35380,
  70090: 35380,
  70091: 35380,
  70092: 35380,
  70094: 35380,
  70096: 35380,
  70112: 35380,
  70113: 35380,
  70114: 35380,
  70115: 35380,
  70116: 35380,
  70117: 35380,
  70118: 35380,
  70119: 35380,
  70121: 35380,
  70122: 35380,
  70123: 35380,
  70124: 35380,
  70125: 35380,
  70126: 35380,
  70127: 35380,
  70128: 35380,
  70129: 35380,
  70130: 35380,
  70131: 35380,
  70139: 35380,
  70141: 35380,
  70143: 35380,
  70145: 35380,
  70146: 35380,
  70148: 35380,
  70150: 35380,
  70151: 35380,
  70152: 35380,
  70153: 35380,
  70156: 35380,
  70157: 35380,
  70158: 35380,
  70163: 35380,
  70165: 35380,
  70170: 35380,
  70172: 35380,
  70174: 35380,
  70175: 35380,
  70176: 35380,
  70177: 35380,
  70178: 35380,
  70179: 35380,
  70181: 35380,
  70182: 35380,
  70183: 35380,
  70184: 35380,
  70185: 35380,
  70186: 35380,
  70190: 35380,
  70301: 12940,
  70339: 29180,
  70340: 34020,
  70341: 12940,
  70342: 34020,
  70343: 26380,
  70344: 26380,
  70345: 26380,
  70346: 12940,
  70352: 26380,
  70353: 26380,
  70354: 26380,
  70355: 26380,
  70356: 26380,
  70357: 26380,
  70358: 35380,
  70359: 26380,
  70360: 26380,
  70361: 26380,
  70363: 26380,
  70364: 26380,
  70371: 26380,
  70372: 12940,
  70373: 26380,
  70374: 26380,
  70375: 26380,
  70377: 26380,
  70380: 12940,
  70381: 34020,
  70390: 12940,
  70391: 12940,
  70392: 34020,
  70393: 12940,
  70394: 26380,
  70395: 26380,
  70397: 26380,
  70401: 25220,
  70402: 25220,
  70403: 12940,
  70404: 25220,
  70420: 35380,
  70421: 25220,
  70422: 12940,
  70426: 14220,
  70427: 35380,
  70429: 14220,
  70431: 35380,
  70433: 35380,
  70434: 35380,
  70435: 25220,
  70436: 12940,
  70437: 35380,
  70438: 14220,
  70441: 12940,
  70442: 25220,
  70443: 25220,
  70444: 12940,
  70445: 35380,
  70446: 25220,
  70447: 35380,
  70448: 35380,
  70449: 12940,
  70450: 14220,
  70451: 25220,
  70452: 35380,
  70453: 12940,
  70454: 25220,
  70455: 25220,
  70456: 12940,
  70457: 35380,
  70458: 35380,
  70459: 35380,
  70460: 35380,
  70461: 35380,
  70462: 25220,
  70463: 35380,
  70464: 35380,
  70465: 25220,
  70466: 12940,
  70469: 35380,
  70470: 35380,
  70471: 35380,
  70501: 29180,
  70502: 29180,
  70503: 29180,
  70504: 29180,
  70505: 29180,
  70506: 29180,
  70507: 29180,
  70508: 29180,
  70509: 29180,
  70510: 29180,
  70512: 29180,
  70513: 29180,
  70514: 34020,
  70515: 29180,
  70516: 29180,
  70517: 29180,
  70518: 29180,
  70519: 29180,
  70520: 36660,
  70521: 29180,
  70522: 34020,
  70523: 34020,
  70524: 99999,
  70525: 29180,
  70526: 29180,
  70528: 29180,
  70529: 29180,
  70531: 29180,
  70532: 27660,
  70533: 29180,
  70534: 29180,
  70535: 29180,
  70537: 29180,
  70538: 34020,
  70540: 34020,
  70541: 36660,
  70542: 29340,
  70543: 29180,
  70544: 29180,
  70546: 29180,
  70548: 29180,
  70549: 29340,
  70550: 36660,
  70551: 36660,
  70552: 29180,
  70554: 99999,
  70555: 29180,
  70556: 29180,
  70558: 29180,
  70559: 29180,
  70560: 29180,
  70562: 29180,
  70563: 29180,
  70569: 29180,
  70570: 99999,
  70571: 36660,
  70575: 29180,
  70576: 99999,
  70577: 36660,
  70578: 29180,
  70580: 99999,
  70581: 27660,
  70582: 29180,
  70583: 29180,
  70584: 36660,
  70585: 99999,
  70586: 36660,
  70589: 99999,
  70591: 27660,
  70592: 29180,
  70596: 29180,
  70598: 29180,
  70601: 29340,
  70602: 29340,
  70605: 29340,
  70606: 29340,
  70607: 29340,
  70611: 27660,
  70612: 29340,
  70615: 29340,
  70616: 29340,
  70629: 29340,
  70630: 29340,
  70631: 29340,
  70632: 29340,
  70633: 19760,
  70634: 22860,
  70637: 99999,
  70638: 99999,
  70639: 22860,
  70640: 99999,
  70643: 29340,
  70644: 99999,
  70645: 29340,
  70646: 29340,
  70647: 29340,
  70648: 27660,
  70650: 27660,
  70651: 99999,
  70652: 19760,
  70653: 19760,
  70654: 99999,
  70655: 99999,
  70656: 10780,
  70657: 99999,
  70658: 99999,
  70659: 22860,
  70660: 19760,
  70661: 19760,
  70662: 99999,
  70663: 29340,
  70664: 29340,
  70665: 29340,
  70668: 29340,
  70669: 29340,
  70704: 12940,
  70706: 12940,
  70710: 12940,
  70711: 12940,
  70712: 12940,
  70714: 12940,
  70715: 12940,
  70718: 12940,
  70719: 12940,
  70721: 12940,
  70722: 12940,
  70723: 35380,
  70725: 12940,
  70726: 12940,
  70727: 12940,
  70728: 12940,
  70729: 12940,
  70730: 12940,
  70732: 12940,
  70733: 12940,
  70734: 12940,
  70736: 12940,
  70737: 12940,
  70739: 12940,
  70740: 12940,
  70743: 35380,
  70744: 12940,
  70747: 12940,
  70748: 12940,
  70749: 12940,
  70750: 36660,
  70752: 12940,
  70753: 12940,
  70754: 12940,
  70755: 12940,
  70756: 12940,
  70757: 12940,
  70759: 12940,
  70760: 12940,
  70761: 12940,
  70762: 12940,
  70763: 35380,
  70764: 12940,
  70767: 12940,
  70769: 12940,
  70770: 12940,
  70772: 12940,
  70773: 12940,
  70774: 12940,
  70775: 12940,
  70776: 12940,
  70777: 12940,
  70778: 12940,
  70780: 12940,
  70782: 12940,
  70783: 12940,
  70784: 12940,
  70785: 12940,
  70786: 12940,
  70787: 12940,
  70788: 12940,
  70789: 12940,
  70791: 12940,
  70792: 35380,
  70801: 12940,
  70802: 12940,
  70803: 12940,
  70804: 12940,
  70805: 12940,
  70806: 12940,
  70807: 12940,
  70808: 12940,
  70809: 12940,
  70810: 12940,
  70811: 12940,
  70812: 12940,
  70814: 12940,
  70815: 12940,
  70816: 12940,
  70817: 12940,
  70818: 12940,
  70819: 12940,
  70820: 12940,
  70821: 12940,
  70825: 12940,
  70827: 12940,
  70835: 12940,
  70836: 12940,
  70874: 12940,
  70879: 12940,
  70884: 12940,
  70892: 12940,
  70895: 12940,
  70896: 12940,
  70898: 12940,
  71001: 99999,
  71002: 35060,
  71003: 40820,
  71004: 43340,
  71006: 43340,
  71007: 43340,
  71008: 99999,
  71009: 43340,
  71016: 35060,
  71018: 33380,
  71019: 35060,
  71021: 33380,
  71023: 43340,
  71024: 33380,
  71027: 43340,
  71028: 99999,
  71029: 43340,
  71030: 43340,
  71031: 35060,
  71032: 43340,
  71033: 43340,
  71034: 99999,
  71037: 33380,
  71038: 99999,
  71039: 33380,
  71040: 99999,
  71043: 43340,
  71044: 43340,
  71045: 99999,
  71046: 43340,
  71047: 43340,
  71048: 99999,
  71049: 43340,
  71051: 43340,
  71052: 43340,
  71055: 33380,
  71060: 43340,
  71061: 43340,
  71063: 43340,
  71064: 43340,
  71065: 99999,
  71066: 35060,
  71067: 43340,
  71068: 99999,
  71069: 43340,
  71070: 35060,
  71071: 43340,
  71072: 33380,
  71073: 33380,
  71075: 33380,
  71078: 43340,
  71079: 99999,
  71080: 99999,
  71082: 43340,
  71101: 43340,
  71102: 43340,
  71103: 43340,
  71104: 43340,
  71105: 43340,
  71106: 43340,
  71107: 43340,
  71108: 43340,
  71109: 43340,
  71110: 43340,
  71111: 43340,
  71112: 43340,
  71113: 43340,
  71115: 99999,
  71118: 43340,
  71119: 43340,
  71129: 43340,
  71133: 43340,
  71134: 43340,
  71135: 43340,
  71136: 43340,
  71137: 43340,
  71138: 43340,
  71148: 43340,
  71149: 43340,
  71161: 43340,
  71162: 43340,
  71163: 43340,
  71164: 43340,
  71165: 43340,
  71166: 43340,
  71171: 43340,
  71172: 43340,
  71201: 33740,
  71202: 33740,
  71203: 33740,
  71207: 33740,
  71209: 33740,
  71210: 33740,
  71211: 33740,
  71212: 33740,
  71213: 33740,
  71218: 99999,
  71219: 99999,
  71220: 33740,
  71222: 33740,
  71223: 33740,
  71225: 33740,
  71226: 99999,
  71227: 33740,
  71229: 33740,
  71230: 99999,
  71232: 99999,
  71233: 99999,
  71234: 40820,
  71235: 33740,
  71237: 99999,
  71238: 99999,
  71240: 33740,
  71241: 33740,
  71242: 99999,
  71243: 99999,
  71245: 40820,
  71247: 99999,
  71249: 99999,
  71250: 33740,
  71251: 99999,
  71253: 99999,
  71254: 99999,
  71256: 33740,
  71259: 99999,
  71260: 33740,
  71261: 33740,
  71263: 99999,
  71264: 33740,
  71266: 99999,
  71268: 99999,
  71269: 33740,
  71270: 40820,
  71272: 40820,
  71273: 40820,
  71275: 40820,
  71276: 99999,
  71277: 33740,
  71279: 99999,
  71280: 33740,
  71281: 33740,
  71282: 99999,
  71286: 99999,
  71291: 33740,
  71292: 33740,
  71294: 33740,
  71295: 99999,
  71301: 10780,
  71302: 10780,
  71303: 10780,
  71306: 10780,
  71307: 10780,
  71315: 10780,
  71316: 35020,
  71320: 99999,
  71322: 10780,
  71323: 99999,
  71324: 99999,
  71325: 99999,
  71326: 99999,
  71327: 99999,
  71328: 10780,
  71329: 99999,
  71330: 10780,
  71331: 99999,
  71333: 99999,
  71334: 99999,
  71336: 99999,
  71339: 99999,
  71340: 99999,
  71341: 99999,
  71342: 99999,
  71343: 35020,
  71345: 36660,
  71346: 10780,
  71348: 10780,
  71350: 99999,
  71351: 10780,
  71353: 36660,
  71354: 99999,
  71355: 99999,
  71356: 36660,
  71357: 99999,
  71358: 36660,
  71359: 10780,
  71360: 10780,
  71361: 10780,
  71362: 99999,
  71363: 99999,
  71365: 10780,
  71366: 99999,
  71367: 99999,
  71368: 99999,
  71369: 99999,
  71371: 99999,
  71373: 35020,
  71375: 99999,
  71378: 99999,
  71401: 99999,
  71403: 22860,
  71404: 99999,
  71405: 10780,
  71406: 99999,
  71407: 10780,
  71409: 10780,
  71410: 99999,
  71411: 35060,
  71414: 35060,
  71415: 99999,
  71416: 35060,
  71417: 10780,
  71418: 99999,
  71419: 43340,
  71422: 99999,
  71423: 10780,
  71424: 10780,
  71425: 99999,
  71426: 99999,
  71427: 10780,
  71428: 35060,
  71429: 99999,
  71430: 10780,
  71432: 10780,
  71433: 10780,
  71434: 35060,
  71435: 99999,
  71438: 10780,
  71439: 22860,
  71440: 99999,
  71441: 99999,
  71446: 22860,
  71447: 22860,
  71448: 10780,
  71449: 99999,
  71450: 99999,
  71452: 35060,
  71454: 10780,
  71455: 35060,
  71456: 35060,
  71457: 99999,
  71458: 35060,
  71459: 22860,
  71460: 99999,
  71461: 22860,
  71462: 99999,
  71463: 10780,
  71465: 99999,
  71466: 10780,
  71467: 10780,
  71468: 35060,
  71469: 99999,
  71471: 99999,
  71472: 10780,
  71473: 99999,
  71474: 22860,
  71475: 22860,
  71477: 10780,
  71479: 99999,
  71480: 99999,
  71483: 99999,
  71485: 10780,
  71486: 99999,
  71496: 22860,
  71497: 35060,
  71601: 38220,
  71602: 30780,
  71603: 30780,
  71630: 99999,
  71631: 99999,
  71635: 99999,
  71638: 99999,
  71639: 38220,
  71640: 99999,
  71642: 99999,
  71643: 38220,
  71644: 38220,
  71646: 99999,
  71647: 99999,
  71651: 99999,
  71652: 99999,
  71653: 99999,
  71654: 99999,
  71655: 99999,
  71658: 99999,
  71659: 38220,
  71660: 99999,
  71661: 99999,
  71662: 99999,
  71663: 99999,
  71665: 38220,
  71667: 38220,
  71670: 99999,
  71671: 38220,
  71674: 99999,
  71675: 38220,
  71676: 99999,
  71677: 99999,
  71678: 38220,
  71701: 15780,
  71720: 15780,
  71721: 11660,
  71722: 26260,
  71724: 20980,
  71725: 99999,
  71726: 15780,
  71728: 11660,
  71730: 20980,
  71731: 20980,
  71740: 31620,
  71742: 15780,
  71743: 11660,
  71744: 15780,
  71745: 15780,
  71747: 20980,
  71748: 99999,
  71749: 20980,
  71750: 20980,
  71751: 15780,
  71752: 31620,
  71753: 20980,
  71754: 31620,
  71758: 20980,
  71759: 20980,
  71762: 15780,
  71763: 99999,
  71764: 20980,
  71765: 20980,
  71766: 15780,
  71770: 26260,
  71772: 11660,
  71801: 26260,
  71820: 45500,
  71822: 45500,
  71823: 99999,
  71825: 26260,
  71826: 99999,
  71827: 99999,
  71828: 26260,
  71831: 99999,
  71832: 99999,
  71833: 99999,
  71834: 45500,
  71835: 26260,
  71836: 45500,
  71837: 45500,
  71838: 26260,
  71839: 45500,
  71840: 45500,
  71841: 99999,
  71842: 99999,
  71845: 99999,
  71846: 99999,
  71847: 26260,
  71851: 99999,
  71852: 26260,
  71853: 45500,
  71854: 45500,
  71855: 26260,
  71857: 15780,
  71858: 26260,
  71859: 99999,
  71860: 26260,
  71861: 99999,
  71862: 26260,
  71864: 26260,
  71865: 45500,
  71866: 45500,
  71901: 31680,
  71902: 26300,
  71903: 26300,
  71909: 30780,
  71910: 26300,
  71913: 26300,
  71914: 26300,
  71921: 31680,
  71922: 99999,
  71923: 31680,
  71929: 31680,
  71932: 99999,
  71933: 99999,
  71935: 99999,
  71937: 99999,
  71940: 99999,
  71941: 31680,
  71942: 31680,
  71943: 99999,
  71944: 99999,
  71945: 99999,
  71949: 26300,
  71950: 99999,
  71952: 99999,
  71953: 99999,
  71956: 26300,
  71957: 99999,
  71958: 99999,
  71959: 99999,
  71960: 99999,
  71961: 99999,
  71962: 11660,
  71964: 26300,
  71965: 99999,
  71968: 26300,
  71969: 99999,
  71970: 99999,
  71971: 99999,
  71972: 99999,
  71973: 99999,
  71998: 11660,
  71999: 11660,
  72001: 30780,
  72002: 30780,
  72003: 99999,
  72004: 99999,
  72005: 99999,
  72006: 99999,
  72007: 30780,
  72010: 42620,
  72011: 30780,
  72012: 42620,
  72013: 99999,
  72014: 99999,
  72015: 30780,
  72016: 30780,
  72017: 99999,
  72018: 30780,
  72019: 30780,
  72020: 12900,
  72021: 99999,
  72022: 30780,
  72023: 30780,
  72024: 99999,
  72025: 30780,
  72026: 99999,
  72027: 99999,
  72028: 99999,
  72029: 99999,
  72030: 99999,
  72031: 99999,
  72032: 30780,
  72033: 30780,
  72034: 30780,
  72035: 30780,
  72036: 99999,
  72037: 30780,
  72038: 99999,
  72039: 30780,
  72040: 99999,
  72041: 99999,
  72042: 99999,
  72043: 99999,
  72044: 99999,
  72045: 42620,
  72046: 30780,
  72047: 30780,
  72048: 99999,
  72051: 99999,
  72052: 42620,
  72053: 30780,
  72055: 99999,
  72057: 38220,
  72058: 30780,
  72059: 99999,
  72060: 99999,
  72061: 30780,
  72063: 99999,
  72064: 30780,
  72065: 30780,
  72066: 99999,
  72067: 99999,
  72068: 42620,
  72069: 99999,
  72070: 30780,
  72072: 30780,
  72073: 38220,
  72074: 99999,
  72075: 99999,
  72076: 30780,
  72078: 30780,
  72079: 38220,
  72080: 99999,
  72081: 42620,
  72082: 42620,
  72083: 30780,
  72084: 31680,
  72085: 42620,
  72086: 30780,
  72087: 30780,
  72088: 99999,
  72089: 30780,
  72099: 30780,
  72101: 99999,
  72102: 42620,
  72103: 30780,
  72104: 30780,
  72106: 30780,
  72107: 99999,
  72108: 99999,
  72110: 99999,
  72111: 42620,
  72112: 12900,
  72113: 30780,
  72114: 30780,
  72115: 30780,
  72116: 30780,
  72117: 30780,
  72118: 30780,
  72119: 30780,
  72120: 30780,
  72121: 42620,
  72122: 30780,
  72123: 99999,
  72124: 30780,
  72125: 30780,
  72126: 30780,
  72127: 99999,
  72128: 31680,
  72129: 30780,
  72130: 99999,
  72131: 99999,
  72132: 30780,
  72133: 38220,
  72134: 99999,
  72135: 30780,
  72136: 42620,
  72137: 42620,
  72139: 42620,
  72140: 99999,
  72141: 99999,
  72142: 30780,
  72143: 42620,
  72145: 42620,
  72149: 42620,
  72150: 38220,
  72152: 38220,
  72153: 99999,
  72156: 99999,
  72157: 99999,
  72160: 30780,
  72164: 30780,
  72165: 12900,
  72166: 99999,
  72167: 30780,
  72168: 38220,
  72169: 99999,
  72170: 99999,
  72173: 30780,
  72175: 38220,
  72176: 30780,
  72178: 42620,
  72179: 99999,
  72180: 30780,
  72181: 30780,
  72182: 38220,
  72183: 30780,
  72190: 30780,
  72199: 30780,
  72201: 30780,
  72202: 30780,
  72203: 30780,
  72204: 30780,
  72205: 30780,
  72206: 30780,
  72207: 30780,
  72209: 30780,
  72210: 30780,
  72211: 30780,
  72212: 30780,
  72214: 30780,
  72215: 30780,
  72216: 30780,
  72217: 30780,
  72219: 30780,
  72221: 30780,
  72222: 30780,
  72223: 30780,
  72225: 30780,
  72227: 30780,
  72231: 30780,
  72255: 30780,
  72260: 30780,
  72301: 32820,
  72310: 14180,
  72311: 99999,
  72312: 25760,
  72313: 14180,
  72315: 14180,
  72316: 14180,
  72320: 99999,
  72321: 14180,
  72322: 22620,
  72324: 99999,
  72325: 32820,
  72326: 22620,
  72327: 32820,
  72328: 25760,
  72329: 14180,
  72330: 14180,
  72331: 99999,
  72332: 32820,
  72333: 25760,
  72335: 99999,
  72338: 14180,
  72339: 32820,
  72340: 22620,
  72341: 99999,
  72342: 25760,
  72346: 99999,
  72347: 99999,
  72348: 22620,
  72350: 14180,
  72351: 14180,
  72352: 99999,
  72353: 25760,
  72354: 27860,
  72355: 25760,
  72358: 14180,
  72359: 22620,
  72360: 25760,
  72364: 32820,
  72365: 27860,
  72366: 99999,
  72367: 25760,
  72368: 99999,
  72369: 25760,
  72370: 14180,
  72372: 22620,
  72373: 99999,
  72374: 25760,
  72376: 32820,
  72377: 27860,
  72379: 99999,
  72383: 25760,
  72384: 32820,
  72386: 27860,
  72387: 99999,
  72389: 25760,
  72390: 25760,
  72391: 14180,
  72392: 99999,
  72394: 22620,
  72395: 14180,
  72396: 22620,
  72401: 37500,
  72402: 27860,
  72403: 27860,
  72404: 27860,
  72405: 37500,
  72410: 99999,
  72411: 27860,
  72412: 37500,
  72413: 99999,
  72414: 27860,
  72415: 99999,
  72416: 99999,
  72417: 37500,
  72419: 14180,
  72421: 99999,
  72422: 99999,
  72424: 99999,
  72425: 37500,
  72426: 14180,
  72427: 27860,
  72428: 14180,
  72429: 27860,
  72430: 99999,
  72431: 99999,
  72432: 27860,
  72433: 99999,
  72434: 99999,
  72435: 99999,
  72436: 99999,
  72437: 27860,
  72438: 14180,
  72440: 99999,
  72441: 99999,
  72442: 14180,
  72443: 37500,
  72444: 99999,
  72445: 99999,
  72447: 27860,
  72449: 99999,
  72450: 27860,
  72451: 37500,
  72453: 37500,
  72454: 99999,
  72455: 99999,
  72456: 99999,
  72457: 99999,
  72458: 99999,
  72459: 12900,
  72460: 99999,
  72461: 37500,
  72462: 99999,
  72464: 99999,
  72465: 99999,
  72466: 12900,
  72467: 27860,
  72469: 99999,
  72470: 99999,
  72471: 99999,
  72472: 27860,
  72473: 99999,
  72474: 37500,
  72475: 27860,
  72476: 27860,
  72478: 99999,
  72479: 27860,
  72482: 12900,
  72501: 12900,
  72503: 12900,
  72512: 99999,
  72513: 99999,
  72515: 99999,
  72517: 99999,
  72519: 99999,
  72520: 99999,
  72521: 12900,
  72522: 12900,
  72523: 99999,
  72524: 12900,
  72526: 12900,
  72527: 12900,
  72528: 99999,
  72529: 12900,
  72530: 99999,
  72531: 99999,
  72532: 99999,
  72533: 99999,
  72534: 12900,
  72536: 99999,
  72537: 34260,
  72538: 34260,
  72539: 99999,
  72540: 99999,
  72542: 12900,
  72543: 99999,
  72544: 34260,
  72545: 99999,
  72546: 99999,
  72550: 12900,
  72553: 12900,
  72554: 99999,
  72555: 99999,
  72556: 99999,
  72560: 99999,
  72561: 99999,
  72562: 12900,
  72564: 12900,
  72565: 99999,
  72566: 99999,
  72567: 99999,
  72568: 42620,
  72569: 12900,
  72571: 12900,
  72572: 99999,
  72573: 99999,
  72575: 12900,
  72576: 99999,
  72577: 99999,
  72578: 99999,
  72579: 12900,
  72581: 99999,
  72583: 99999,
  72584: 99999,
  72585: 99999,
  72587: 99999,
  72601: 25460,
  72602: 25460,
  72611: 25460,
  72613: 99999,
  72615: 25460,
  72616: 99999,
  72617: 99999,
  72619: 99999,
  72623: 34260,
  72624: 99999,
  72626: 34260,
  72628: 25460,
  72629: 99999,
  72631: 99999,
  72632: 22220,
  72633: 99999,
  72634: 99999,
  72635: 34260,
  72636: 99999,
  72638: 25460,
  72639: 99999,
  72640: 25460,
  72641: 25460,
  72642: 34260,
  72643: 25460,
  72644: 99999,
  72645: 99999,
  72648: 25460,
  72650: 99999,
  72651: 34260,
  72653: 99999,
  72654: 34260,
  72655: 25460,
  72657: 99999,
  72658: 34260,
  72659: 34260,
  72660: 99999,
  72661: 99999,
  72662: 99999,
  72663: 99999,
  72666: 25460,
  72668: 99999,
  72669: 99999,
  72670: 25460,
  72672: 99999,
  72675: 99999,
  72677: 99999,
  72679: 99999,
  72680: 99999,
  72682: 99999,
  72683: 25460,
  72685: 25460,
  72686: 40780,
  72687: 99999,
  72701: 22220,
  72702: 22220,
  72703: 22220,
  72704: 22220,
  72711: 22220,
  72712: 22220,
  72713: 22220,
  72714: 22220,
  72715: 22220,
  72716: 22220,
  72717: 22220,
  72718: 22220,
  72719: 22220,
  72721: 22900,
  72722: 22220,
  72727: 22220,
  72728: 22220,
  72729: 22220,
  72730: 22220,
  72732: 22220,
  72733: 22220,
  72734: 22220,
  72735: 22220,
  72736: 22220,
  72737: 22220,
  72738: 22220,
  72739: 22220,
  72740: 99999,
  72741: 22220,
  72742: 25460,
  72744: 22220,
  72745: 22220,
  72747: 22220,
  72749: 22220,
  72751: 22220,
  72752: 99999,
  72753: 22220,
  72756: 22220,
  72757: 22220,
  72758: 22220,
  72760: 22220,
  72761: 22220,
  72762: 22220,
  72764: 22220,
  72765: 22220,
  72766: 22220,
  72768: 22220,
  72769: 22220,
  72770: 22220,
  72773: 22220,
  72774: 22220,
  72776: 22220,
  72801: 40780,
  72802: 40780,
  72811: 40780,
  72812: 40780,
  72820: 22900,
  72821: 22900,
  72823: 40780,
  72824: 40780,
  72826: 99999,
  72827: 40780,
  72828: 40780,
  72829: 40780,
  72830: 99999,
  72832: 99999,
  72833: 99999,
  72834: 99999,
  72835: 99999,
  72837: 40780,
  72838: 40780,
  72839: 99999,
  72840: 99999,
  72841: 40780,
  72842: 99999,
  72843: 40780,
  72845: 99999,
  72846: 40780,
  72847: 40780,
  72851: 99999,
  72852: 99999,
  72853: 30780,
  72854: 99999,
  72855: 99999,
  72856: 25460,
  72857: 40780,
  72858: 40780,
  72860: 40780,
  72863: 99999,
  72865: 99999,
  72901: 22900,
  72902: 22900,
  72903: 22900,
  72904: 22900,
  72905: 22900,
  72906: 22900,
  72908: 22900,
  72913: 22900,
  72914: 22900,
  72916: 22900,
  72917: 22900,
  72918: 22900,
  72919: 22900,
  72921: 22900,
  72923: 22900,
  72926: 99999,
  72927: 22900,
  72928: 99999,
  72930: 22900,
  72932: 22900,
  72933: 99999,
  72934: 22900,
  72935: 22900,
  72936: 22900,
  72937: 22900,
  72938: 22900,
  72940: 22900,
  72941: 22900,
  72943: 22900,
  72944: 22900,
  72945: 22900,
  72946: 22900,
  72947: 22900,
  72948: 22220,
  72949: 22900,
  72950: 99999,
  72951: 22900,
  72952: 22900,
  72955: 22900,
  72956: 22900,
  72957: 22900,
  72958: 99999,
  72959: 22220,
  73001: 99999,
  73002: 36420,
  73003: 36420,
  73004: 36420,
  73005: 99999,
  73006: 99999,
  73007: 36420,
  73008: 36420,
  73009: 99999,
  73010: 36420,
  73011: 99999,
  73012: 36420,
  73013: 36420,
  73014: 36420,
  73015: 99999,
  73016: 36420,
  73017: 30020,
  73018: 36420,
  73019: 36420,
  73020: 36420,
  73021: 99999,
  73022: 36420,
  73023: 36420,
  73024: 48220,
  73025: 36420,
  73026: 36420,
  73027: 36420,
  73028: 99999,
  73029: 99999,
  73030: 11620,
  73031: 36420,
  73032: 99999,
  73033: 99999,
  73034: 36420,
  73036: 36420,
  73038: 99999,
  73040: 36420,
  73041: 99999,
  73042: 99999,
  73043: 99999,
  73044: 36420,
  73045: 36420,
  73047: 99999,
  73048: 48220,
  73049: 36420,
  73050: 36420,
  73051: 43060,
  73052: 36420,
  73053: 99999,
  73054: 36420,
  73055: 36420,
  73056: 36420,
  73057: 36420,
  73058: 36420,
  73059: 99999,
  73061: 46140,
  73062: 99999,
  73063: 44660,
  73064: 36420,
  73065: 36420,
  73066: 36420,
  73067: 36420,
  73068: 36420,
  73069: 36420,
  73070: 36420,
  73071: 36420,
  73072: 36420,
  73073: 21420,
  73074: 36420,
  73075: 99999,
  73077: 99999,
  73078: 36420,
  73079: 99999,
  73080: 36420,
  73082: 30020,
  73083: 36420,
  73084: 36420,
  73085: 36420,
  73086: 99999,
  73089: 36420,
  73090: 36420,
  73092: 36420,
  73093: 36420,
  73095: 36420,
  73096: 99999,
  73097: 36420,
  73098: 99999,
  73099: 36420,
  73101: 36420,
  73102: 36420,
  73103: 36420,
  73104: 36420,
  73105: 36420,
  73106: 36420,
  73107: 36420,
  73108: 36420,
  73109: 36420,
  73110: 36420,
  73111: 36420,
  73112: 36420,
  73113: 36420,
  73114: 36420,
  73115: 36420,
  73116: 36420,
  73117: 36420,
  73118: 36420,
  73119: 36420,
  73120: 36420,
  73121: 36420,
  73122: 36420,
  73123: 36420,
  73125: 36420,
  73127: 36420,
  73128: 36420,
  73129: 36420,
  73130: 36420,
  73131: 36420,
  73132: 36420,
  73134: 36420,
  73135: 36420,
  73136: 36420,
  73137: 36420,
  73139: 36420,
  73140: 36420,
  73141: 36420,
  73142: 36420,
  73143: 36420,
  73144: 36420,
  73145: 36420,
  73146: 36420,
  73147: 36420,
  73148: 36420,
  73149: 36420,
  73150: 36420,
  73151: 36420,
  73152: 36420,
  73153: 36420,
  73154: 36420,
  73155: 36420,
  73156: 36420,
  73157: 36420,
  73159: 36420,
  73160: 36420,
  73162: 36420,
  73165: 36420,
  73169: 36420,
  73170: 36420,
  73172: 36420,
  73173: 36420,
  73179: 36420,
  73184: 36420,
  73189: 36420,
  73190: 36420,
  73194: 36420,
  73195: 36420,
  73301: 12420,
  73401: 11620,
  73402: 11620,
  73403: 11620,
  73425: 20340,
  73430: 11620,
  73432: 99999,
  73433: 11620,
  73434: 99999,
  73435: 11620,
  73436: 11620,
  73437: 11620,
  73438: 11620,
  73439: 99999,
  73440: 99999,
  73441: 11620,
  73442: 20340,
  73443: 11620,
  73444: 99999,
  73446: 99999,
  73447: 99999,
  73448: 11620,
  73449: 20460,
  73450: 99999,
  73453: 11620,
  73455: 99999,
  73456: 11620,
  73458: 11620,
  73459: 11620,
  73460: 99999,
  73461: 99999,
  73463: 11620,
  73481: 20340,
  73487: 11620,
  73488: 11620,
  73491: 20340,
  73501: 20340,
  73502: 30020,
  73503: 30020,
  73505: 30020,
  73506: 30020,
  73507: 30020,
  73520: 99999,
  73521: 11060,
  73522: 11060,
  73523: 11060,
  73526: 99999,
  73527: 30020,
  73528: 99999,
  73529: 20340,
  73530: 99999,
  73531: 30020,
  73532: 11060,
  73533: 20340,
  73534: 20340,
  73537: 99999,
  73538: 30020,
  73539: 11060,
  73540: 30020,
  73541: 30020,
  73542: 99999,
  73543: 30020,
  73544: 99999,
  73546: 99999,
  73547: 99999,
  73548: 99999,
  73549: 11060,
  73550: 99999,
  73551: 99999,
  73552: 99999,
  73553: 99999,
  73554: 99999,
  73555: 99999,
  73556: 11060,
  73557: 30020,
  73559: 99999,
  73560: 11060,
  73562: 30020,
  73564: 99999,
  73565: 99999,
  73566: 99999,
  73567: 30020,
  73568: 30020,
  73569: 99999,
  73570: 99999,
  73571: 99999,
  73572: 30020,
  73573: 99999,
  73601: 99999,
  73620: 48220,
  73622: 99999,
  73624: 99999,
  73625: 48220,
  73626: 99999,
  73627: 21120,
  73628: 99999,
  73632: 99999,
  73638: 99999,
  73639: 48220,
  73641: 99999,
  73642: 99999,
  73644: 21120,
  73645: 21120,
  73646: 99999,
  73647: 99999,
  73650: 48220,
  73651: 99999,
  73654: 48220,
  73655: 99999,
  73658: 99999,
  73659: 99999,
  73660: 99999,
  73661: 99999,
  73662: 99999,
  73663: 99999,
  73664: 21120,
  73666: 99999,
  73667: 99999,
  73668: 21120,
  73669: 99999,
  73673: 21120,
  73701: 21420,
  73702: 21420,
  73703: 21420,
  73705: 21420,
  73716: 99999,
  73717: 99999,
  73718: 99999,
  73719: 99999,
  73720: 21420,
  73722: 99999,
  73724: 99999,
  73726: 99999,
  73727: 21420,
  73728: 99999,
  73729: 99999,
  73730: 21420,
  73731: 99999,
  73733: 21420,
  73734: 99999,
  73735: 21420,
  73736: 21420,
  73737: 99999,
  73738: 21420,
  73739: 21420,
  73741: 99999,
  73742: 21420,
  73743: 21420,
  73744: 99999,
  73746: 99999,
  73747: 99999,
  73749: 99999,
  73750: 99999,
  73753: 21420,
  73754: 99999,
  73755: 99999,
  73756: 99999,
  73757: 99999,
  73758: 99999,
  73759: 99999,
  73760: 99999,
  73761: 21420,
  73762: 99999,
  73763: 99999,
  73764: 99999,
  73766: 99999,
  73768: 99999,
  73770: 99999,
  73771: 99999,
  73772: 99999,
  73773: 21420,
  73801: 49260,
  73802: 49260,
  73832: 49260,
  73834: 99999,
  73835: 99999,
  73838: 99999,
  73840: 49260,
  73841: 49260,
  73842: 99999,
  73843: 49260,
  73844: 99999,
  73848: 99999,
  73851: 99999,
  73852: 99999,
  73853: 49260,
  73855: 99999,
  73857: 49260,
  73858: 49260,
  73859: 99999,
  73860: 99999,
  73901: 25100,
  73931: 99999,
  73932: 99999,
  73933: 99999,
  73937: 99999,
  73938: 99999,
  73939: 25100,
  73942: 25100,
  73944: 25100,
  73945: 25100,
  73946: 99999,
  73947: 99999,
  73949: 25100,
  73950: 99999,
  73951: 25100,
  73960: 99999,
  74001: 46140,
  74002: 46140,
  74003: 12780,
  74005: 12780,
  74006: 12780,
  74008: 46140,
  74010: 46140,
  74011: 46140,
  74012: 46140,
  74013: 46140,
  74014: 46140,
  74015: 46140,
  74016: 46140,
  74017: 46140,
  74018: 46140,
  74019: 99999,
  74020: 46140,
  74021: 46140,
  74022: 46140,
  74023: 46140,
  74026: 36420,
  74027: 99999,
  74028: 36420,
  74029: 12780,
  74030: 46140,
  74031: 46140,
  74032: 99999,
  74033: 46140,
  74034: 46140,
  74035: 46140,
  74036: 99999,
  74037: 46140,
  74038: 46140,
  74039: 46140,
  74041: 46140,
  74042: 99999,
  74043: 46140,
  74044: 46140,
  74045: 44660,
  74046: 46140,
  74047: 46140,
  74048: 12780,
  74050: 46140,
  74051: 46140,
  74052: 46140,
  74053: 46140,
  74054: 46140,
  74055: 46140,
  74056: 46140,
  74058: 46140,
  74059: 36420,
  74060: 46140,
  74061: 46140,
  74062: 44660,
  74063: 46140,
  74066: 46140,
  74067: 46140,
  74068: 46140,
  74070: 46140,
  74071: 46140,
  74072: 99999,
  74073: 46140,
  74074: 44660,
  74075: 99999,
  74076: 44660,
  74077: 44660,
  74078: 44660,
  74079: 36420,
  74080: 46140,
  74081: 46140,
  74082: 12780,
  74083: 12780,
  74084: 46140,
  74085: 46140,
  74101: 46140,
  74103: 46140,
  74104: 46140,
  74105: 46140,
  74106: 46140,
  74107: 46140,
  74108: 46140,
  74110: 46140,
  74112: 46140,
  74114: 46140,
  74115: 46140,
  74116: 46140,
  74117: 46140,
  74119: 46140,
  74120: 46140,
  74126: 46140,
  74127: 46140,
  74128: 46140,
  74129: 46140,
  74130: 46140,
  74131: 46140,
  74132: 46140,
  74133: 46140,
  74134: 46140,
  74135: 46140,
  74136: 46140,
  74137: 46140,
  74141: 46140,
  74145: 46140,
  74146: 46140,
  74147: 46140,
  74148: 46140,
  74149: 46140,
  74150: 46140,
  74152: 46140,
  74153: 46140,
  74155: 46140,
  74156: 46140,
  74157: 46140,
  74158: 46140,
  74159: 46140,
  74169: 46140,
  74170: 46140,
  74171: 46140,
  74172: 46140,
  74182: 46140,
  74186: 46140,
  74187: 46140,
  74192: 46140,
  74193: 46140,
  74301: 99999,
  74330: 99999,
  74331: 33060,
  74332: 99999,
  74333: 99999,
  74335: 33060,
  74337: 46140,
  74338: 99999,
  74339: 33060,
  74340: 99999,
  74342: 99999,
  74343: 99999,
  74344: 99999,
  74345: 99999,
  74346: 99999,
  74347: 45140,
  74349: 99999,
  74350: 99999,
  74352: 99999,
  74354: 99999,
  74358: 33060,
  74359: 45140,
  74360: 33060,
  74361: 99999,
  74363: 33060,
  74364: 45140,
  74365: 99999,
  74366: 99999,
  74367: 99999,
  74368: 99999,
  74369: 99999,
  74370: 33060,
  74401: 34780,
  74402: 34780,
  74403: 46140,
  74421: 46140,
  74422: 46140,
  74423: 34780,
  74425: 32540,
  74426: 99999,
  74427: 45140,
  74428: 99999,
  74429: 46140,
  74430: 32540,
  74431: 46140,
  74432: 32540,
  74434: 34780,
  74435: 34780,
  74436: 46140,
  74437: 46140,
  74438: 99999,
  74441: 45140,
  74442: 32540,
  74444: 45140,
  74445: 99999,
  74446: 46140,
  74447: 99999,
  74450: 99999,
  74451: 45140,
  74452: 99999,
  74454: 46140,
  74455: 99999,
  74456: 46140,
  74457: 45140,
  74458: 46140,
  74459: 99999,
  74460: 46140,
  74462: 99999,
  74463: 34780,
  74464: 45140,
  74465: 45140,
  74467: 46140,
  74468: 34780,
  74469: 99999,
  74470: 34780,
  74471: 45140,
  74472: 99999,
  74501: 32540,
  74502: 32540,
  74521: 99999,
  74522: 32540,
  74523: 99999,
  74525: 99999,
  74528: 32540,
  74529: 32540,
  74530: 99999,
  74531: 99999,
  74533: 99999,
  74534: 99999,
  74535: 99999,
  74536: 32540,
  74538: 99999,
  74540: 99999,
  74543: 99999,
  74545: 99999,
  74546: 32540,
  74547: 32540,
  74549: 99999,
  74552: 99999,
  74553: 32540,
  74554: 32540,
  74555: 99999,
  74556: 99999,
  74557: 99999,
  74558: 99999,
  74559: 99999,
  74560: 32540,
  74561: 32540,
  74562: 99999,
  74563: 99999,
  74565: 32540,
  74567: 99999,
  74569: 99999,
  74570: 99999,
  74571: 99999,
  74572: 99999,
  74574: 99999,
  74576: 32540,
  74577: 99999,
  74578: 99999,
  74601: 38620,
  74602: 38620,
  74604: 46140,
  74630: 99999,
  74631: 38620,
  74632: 38620,
  74633: 46140,
  74636: 99999,
  74637: 46140,
  74640: 99999,
  74641: 38620,
  74643: 99999,
  74644: 99999,
  74646: 99999,
  74647: 38620,
  74650: 46140,
  74651: 99999,
  74652: 38620,
  74653: 38620,
  74701: 20460,
  74702: 20460,
  74720: 20460,
  74721: 20460,
  74722: 99999,
  74723: 20460,
  74724: 99999,
  74726: 20460,
  74727: 99999,
  74728: 99999,
  74729: 99999,
  74730: 20460,
  74731: 20460,
  74733: 20460,
  74734: 99999,
  74735: 99999,
  74736: 99999,
  74737: 99999,
  74738: 99999,
  74740: 99999,
  74741: 20460,
  74743: 99999,
  74745: 99999,
  74748: 99999,
  74750: 99999,
  74752: 99999,
  74753: 20460,
  74754: 99999,
  74755: 99999,
  74756: 99999,
  74759: 99999,
  74760: 99999,
  74761: 99999,
  74764: 99999,
  74766: 99999,
  74801: 43060,
  74802: 43060,
  74804: 43060,
  74820: 10220,
  74821: 10220,
  74824: 44660,
  74825: 99999,
  74826: 43060,
  74827: 99999,
  74829: 99999,
  74830: 99999,
  74831: 10220,
  74832: 36420,
  74833: 99999,
  74834: 36420,
  74836: 99999,
  74837: 99999,
  74839: 99999,
  74840: 43060,
  74842: 10220,
  74843: 10220,
  74844: 10220,
  74845: 99999,
  74848: 99999,
  74849: 43060,
  74850: 99999,
  74851: 36420,
  74852: 36420,
  74854: 43060,
  74855: 43060,
  74856: 99999,
  74857: 43060,
  74859: 99999,
  74860: 99999,
  74864: 36420,
  74865: 10220,
  74866: 43060,
  74867: 99999,
  74868: 99999,
  74869: 36420,
  74871: 99999,
  74872: 10220,
  74873: 43060,
  74875: 36420,
  74878: 36420,
  74880: 46140,
  74881: 36420,
  74883: 99999,
  74884: 99999,
  74901: 99999,
  74902: 99999,
  74930: 99999,
  74931: 99999,
  74932: 99999,
  74935: 99999,
  74936: 22900,
  74937: 99999,
  74939: 99999,
  74940: 99999,
  74941: 99999,
  74942: 99999,
  74943: 99999,
  74944: 99999,
  74945: 22900,
  74946: 22900,
  74947: 99999,
  74948: 22900,
  74949: 99999,
  74951: 99999,
  74953: 99999,
  74954: 22900,
  74955: 22900,
  74956: 99999,
  74957: 99999,
  74959: 99999,
  74960: 99999,
  74962: 45140,
  74963: 99999,
  74964: 99999,
  74965: 99999,
  74966: 99999,
  75001: 19100,
  75002: 19100,
  75006: 19100,
  75007: 19100,
  75009: 19100,
  75010: 19100,
  75011: 19100,
  75013: 19100,
  75014: 19100,
  75015: 19100,
  75016: 19100,
  75017: 19100,
  75019: 19100,
  75020: 43300,
  75021: 43300,
  75022: 19100,
  75023: 19100,
  75024: 19100,
  75025: 19100,
  75026: 19100,
  75027: 19100,
  75028: 19100,
  75029: 19100,
  75030: 19100,
  75032: 19100,
  75033: 19100,
  75034: 19100,
  75035: 19100,
  75036: 19100,
  75038: 19100,
  75039: 19100,
  75040: 19100,
  75041: 19100,
  75042: 19100,
  75043: 19100,
  75044: 19100,
  75045: 19100,
  75046: 19100,
  75047: 19100,
  75048: 19100,
  75049: 19100,
  75050: 19100,
  75051: 19100,
  75052: 19100,
  75053: 19100,
  75054: 19100,
  75056: 19100,
  75057: 19100,
  75058: 43300,
  75059: 19100,
  75060: 19100,
  75061: 19100,
  75062: 19100,
  75063: 19100,
  75065: 19100,
  75067: 19100,
  75068: 19100,
  75069: 19100,
  75070: 19100,
  75071: 19100,
  75072: 19100,
  75074: 19100,
  75075: 19100,
  75076: 43300,
  75077: 19100,
  75078: 19100,
  75080: 19100,
  75081: 19100,
  75082: 19100,
  75083: 19100,
  75085: 19100,
  75086: 19100,
  75087: 19100,
  75088: 19100,
  75089: 19100,
  75090: 43300,
  75091: 43300,
  75092: 43300,
  75093: 19100,
  75094: 19100,
  75097: 19100,
  75098: 19100,
  75099: 19100,
  75101: 19100,
  75102: 18620,
  75103: 99999,
  75104: 19100,
  75105: 18620,
  75106: 19100,
  75109: 18620,
  75110: 18620,
  75114: 19100,
  75115: 19100,
  75116: 19100,
  75117: 99999,
  75118: 19100,
  75119: 19100,
  75121: 19100,
  75123: 19100,
  75124: 99999,
  75125: 19100,
  75126: 19100,
  75127: 99999,
  75132: 19100,
  75134: 19100,
  75135: 19100,
  75137: 19100,
  75138: 19100,
  75140: 46340,
  75141: 19100,
  75142: 19100,
  75143: 11980,
  75144: 18620,
  75146: 19100,
  75147: 19100,
  75148: 11980,
  75149: 19100,
  75150: 19100,
  75151: 18620,
  75152: 19100,
  75153: 18620,
  75154: 19100,
  75155: 18620,
  75156: 19100,
  75157: 19100,
  75158: 19100,
  75159: 19100,
  75160: 19100,
  75161: 19100,
  75163: 11980,
  75164: 19100,
  75165: 19100,
  75166: 19100,
  75167: 19100,
  75168: 19100,
  75169: 99999,
  75172: 19100,
  75173: 19100,
  75180: 19100,
  75181: 19100,
  75182: 19100,
  75185: 19100,
  75187: 19100,
  75189: 19100,
  75201: 19100,
  75202: 19100,
  75203: 19100,
  75204: 19100,
  75205: 19100,
  75206: 19100,
  75207: 19100,
  75208: 19100,
  75209: 19100,
  75210: 19100,
  75211: 19100,
  75212: 19100,
  75214: 19100,
  75215: 19100,
  75216: 19100,
  75217: 19100,
  75218: 19100,
  75219: 19100,
  75220: 19100,
  75221: 19100,
  75222: 19100,
  75223: 19100,
  75224: 19100,
  75225: 19100,
  75226: 19100,
  75227: 19100,
  75228: 19100,
  75229: 19100,
  75230: 19100,
  75231: 19100,
  75232: 19100,
  75233: 19100,
  75234: 19100,
  75235: 19100,
  75236: 19100,
  75237: 19100,
  75238: 19100,
  75240: 19100,
  75241: 19100,
  75242: 19100,
  75243: 19100,
  75244: 19100,
  75246: 19100,
  75247: 19100,
  75248: 19100,
  75249: 19100,
  75250: 19100,
  75251: 19100,
  75252: 19100,
  75253: 19100,
  75254: 19100,
  75260: 19100,
  75261: 19100,
  75270: 19100,
  75287: 19100,
  75313: 19100,
  75315: 19100,
  75336: 19100,
  75339: 19100,
  75342: 19100,
  75354: 19100,
  75355: 19100,
  75356: 19100,
  75357: 19100,
  75360: 19100,
  75367: 19100,
  75370: 19100,
  75371: 19100,
  75372: 19100,
  75374: 19100,
  75376: 19100,
  75378: 19100,
  75379: 19100,
  75380: 19100,
  75381: 19100,
  75382: 19100,
  75390: 19100,
  75398: 19100,
  75401: 19100,
  75402: 19100,
  75403: 19100,
  75404: 19100,
  75407: 19100,
  75409: 19100,
  75410: 99999,
  75411: 37580,
  75412: 99999,
  75413: 14300,
  75414: 14300,
  75415: 99999,
  75416: 37580,
  75417: 99999,
  75418: 14300,
  75420: 44860,
  75421: 37580,
  75422: 19100,
  75423: 19100,
  75424: 14300,
  75425: 37580,
  75426: 99999,
  75428: 99999,
  75431: 99999,
  75432: 99999,
  75433: 44860,
  75434: 37580,
  75435: 37580,
  75436: 99999,
  75437: 44860,
  75438: 14300,
  75439: 14300,
  75440: 99999,
  75441: 99999,
  75442: 19100,
  75443: 14300,
  75444: 99999,
  75446: 14300,
  75447: 14300,
  75448: 99999,
  75449: 19100,
  75450: 99999,
  75451: 99999,
  75452: 19100,
  75453: 99999,
  75454: 19100,
  75455: 99999,
  75456: 34420,
  75457: 44860,
  75458: 19100,
  75459: 43300,
  75460: 37580,
  75461: 37580,
  75462: 37580,
  75468: 37580,
  75469: 19100,
  75470: 37580,
  75471: 99999,
  75472: 44860,
  75473: 37580,
  75474: 19100,
  75475: 14300,
  75476: 14300,
  75477: 37580,
  75478: 99999,
  75479: 43300,
  75480: 99999,
  75481: 44860,
  75482: 44860,
  75483: 44860,
  75485: 19100,
  75486: 37580,
  75487: 34420,
  75488: 14300,
  75489: 43300,
  75490: 14300,
  75491: 14300,
  75492: 14300,
  75493: 34420,
  75494: 30980,
  75495: 19100,
  75496: 19100,
  75497: 99999,
  75501: 99999,
  75503: 45500,
  75504: 45500,
  75505: 45500,
  75550: 99999,
  75551: 99999,
  75554: 99999,
  75555: 99999,
  75556: 99999,
  75558: 99999,
  75559: 45500,
  75560: 99999,
  75561: 45500,
  75562: 99999,
  75563: 99999,
  75564: 99999,
  75565: 99999,
  75566: 99999,
  75567: 45500,
  75568: 99999,
  75569: 45500,
  75570: 45500,
  75571: 34420,
  75572: 99999,
  75573: 45500,
  75574: 45500,
  75599: 45500,
  75601: 30980,
  75602: 30980,
  75603: 30980,
  75604: 30980,
  75605: 30980,
  75606: 30980,
  75607: 30980,
  75608: 30980,
  75615: 30980,
  75630: 99999,
  75631: 99999,
  75633: 99999,
  75636: 99999,
  75637: 99999,
  75638: 99999,
  75639: 30980,
  75640: 99999,
  75641: 30980,
  75642: 30980,
  75643: 99999,
  75644: 34420,
  75645: 30980,
  75647: 46340,
  75650: 30980,
  75651: 99999,
  75652: 99999,
  75654: 30980,
  75656: 99999,
  75657: 99999,
  75658: 30980,
  75659: 30980,
  75660: 30980,
  75661: 30980,
  75662: 30980,
  75663: 30980,
  75666: 30980,
  75667: 30980,
  75668: 99999,
  75669: 30980,
  75670: 30980,
  75671: 30980,
  75672: 99999,
  75680: 30980,
  75681: 30980,
  75682: 30980,
  75683: 99999,
  75684: 46340,
  75685: 99999,
  75686: 30980,
  75687: 30980,
  75688: 30980,
  75689: 30980,
  75691: 99999,
  75692: 30980,
  75693: 30980,
  75694: 30980,
  75701: 46340,
  75702: 46340,
  75703: 46340,
  75704: 46340,
  75705: 46340,
  75706: 46340,
  75707: 46340,
  75708: 46340,
  75709: 46340,
  75710: 46340,
  75711: 46340,
  75712: 46340,
  75713: 46340,
  75750: 46340,
  75751: 37300,
  75752: 37300,
  75754: 99999,
  75755: 30980,
  75756: 99999,
  75757: 46340,
  75758: 99999,
  75759: 27380,
  75760: 34860,
  75762: 46340,
  75763: 11980,
  75764: 27380,
  75765: 99999,
  75766: 27380,
  75770: 37300,
  75771: 46340,
  75772: 27380,
  75773: 99999,
  75778: 11980,
  75779: 37300,
  75780: 27380,
  75782: 11980,
  75783: 99999,
  75784: 27380,
  75785: 27380,
  75788: 34860,
  75789: 30980,
  75790: 46340,
  75791: 46340,
  75792: 46340,
  75798: 46340,
  75799: 46340,
  75801: 37300,
  75802: 37300,
  75803: 11980,
  75831: 99999,
  75832: 37300,
  75833: 99999,
  75834: 99999,
  75835: 99999,
  75838: 99999,
  75839: 37300,
  75840: 99999,
  75844: 99999,
  75845: 99999,
  75846: 99999,
  75847: 99999,
  75848: 99999,
  75849: 99999,
  75850: 99999,
  75851: 99999,
  75852: 99999,
  75853: 11980,
  75855: 99999,
  75856: 99999,
  75858: 99999,
  75859: 18620,
  75860: 99999,
  75861: 37300,
  75862: 99999,
  75865: 99999,
  75880: 37300,
  75882: 37300,
  75884: 37300,
  75886: 37300,
  75901: 31260,
  75902: 31260,
  75903: 31260,
  75904: 31260,
  75915: 31260,
  75925: 27380,
  75926: 99999,
  75928: 99999,
  75929: 99999,
  75930: 99999,
  75931: 99999,
  75932: 99999,
  75933: 99999,
  75934: 99999,
  75935: 99999,
  75936: 99999,
  75937: 34860,
  75938: 99999,
  75939: 99999,
  75941: 31260,
  75942: 99999,
  75943: 34860,
  75944: 34860,
  75946: 30980,
  75948: 99999,
  75949: 31260,
  75951: 31260,
  75954: 99999,
  75956: 99999,
  75958: 34860,
  75959: 99999,
  75960: 99999,
  75961: 99999,
  75963: 34860,
  75964: 34860,
  75965: 34860,
  75966: 99999,
  75968: 99999,
  75969: 31260,
  75972: 99999,
  75973: 99999,
  75974: 99999,
  75975: 99999,
  75976: 27380,
  75977: 99999,
  75978: 34860,
  75979: 99999,
  75980: 31260,
  75990: 99999,
  76001: 19100,
  76002: 19100,
  76003: 19100,
  76004: 19100,
  76005: 19100,
  76006: 19100,
  76007: 19100,
  76008: 19100,
  76009: 19100,
  76010: 19100,
  76011: 19100,
  76012: 19100,
  76013: 19100,
  76014: 19100,
  76015: 19100,
  76016: 19100,
  76017: 19100,
  76018: 19100,
  76019: 19100,
  76020: 19100,
  76021: 19100,
  76022: 19100,
  76023: 19100,
  76028: 19100,
  76031: 19100,
  76033: 19100,
  76034: 19100,
  76035: 19100,
  76036: 19100,
  76039: 19100,
  76040: 19100,
  76041: 19100,
  76043: 99999,
  76044: 24180,
  76048: 99999,
  76049: 24180,
  76050: 99999,
  76051: 19100,
  76052: 19100,
  76053: 19100,
  76054: 19100,
  76055: 99999,
  76058: 19100,
  76059: 19100,
  76060: 19100,
  76061: 19100,
  76063: 19100,
  76064: 19100,
  76065: 19100,
  76066: 19100,
  76067: 33420,
  76070: 19100,
  76071: 19100,
  76073: 19100,
  76077: 99999,
  76078: 19100,
  76082: 19100,
  76084: 19100,
  76085: 19100,
  76086: 19100,
  76087: 24180,
  76088: 19100,
  76092: 19100,
  76093: 19100,
  76094: 19100,
  76095: 19100,
  76096: 19100,
  76097: 19100,
  76098: 19100,
  76099: 19100,
  76101: 19100,
  76102: 19100,
  76103: 19100,
  76104: 19100,
  76105: 19100,
  76106: 19100,
  76107: 19100,
  76108: 19100,
  76109: 19100,
  76110: 19100,
  76111: 19100,
  76112: 19100,
  76113: 19100,
  76114: 19100,
  76115: 19100,
  76116: 19100,
  76117: 19100,
  76118: 19100,
  76119: 19100,
  76120: 19100,
  76121: 19100,
  76122: 19100,
  76123: 19100,
  76124: 19100,
  76126: 19100,
  76127: 19100,
  76129: 19100,
  76130: 19100,
  76131: 19100,
  76132: 19100,
  76133: 19100,
  76134: 19100,
  76135: 19100,
  76136: 19100,
  76137: 19100,
  76140: 19100,
  76147: 19100,
  76148: 19100,
  76155: 19100,
  76161: 19100,
  76162: 19100,
  76163: 19100,
  76164: 19100,
  76177: 19100,
  76179: 19100,
  76180: 19100,
  76182: 19100,
  76185: 19100,
  76190: 19100,
  76191: 19100,
  76192: 19100,
  76193: 19100,
  76195: 19100,
  76196: 19100,
  76197: 19100,
  76199: 19100,
  76201: 19100,
  76202: 19100,
  76203: 19100,
  76204: 19100,
  76205: 19100,
  76206: 19100,
  76207: 19100,
  76208: 19100,
  76209: 19100,
  76210: 19100,
  76225: 99999,
  76226: 19100,
  76227: 19100,
  76228: 99999,
  76230: 48660,
  76233: 23620,
  76234: 99999,
  76238: 23620,
  76239: 23620,
  76240: 43300,
  76241: 23620,
  76244: 19100,
  76245: 43300,
  76246: 19100,
  76247: 19100,
  76248: 19100,
  76249: 19100,
  76250: 23620,
  76251: 99999,
  76252: 23620,
  76253: 23620,
  76255: 99999,
  76258: 19100,
  76259: 19100,
  76261: 48660,
  76262: 19100,
  76263: 23620,
  76264: 43300,
  76265: 23620,
  76266: 19100,
  76267: 19100,
  76268: 43300,
  76270: 19100,
  76271: 43300,
  76272: 23620,
  76273: 23620,
  76301: 48660,
  76302: 48660,
  76305: 48660,
  76306: 48660,
  76307: 48660,
  76308: 48660,
  76309: 48660,
  76310: 48660,
  76311: 48660,
  76351: 48660,
  76352: 48660,
  76354: 48660,
  76357: 48660,
  76360: 46900,
  76363: 99999,
  76364: 46900,
  76365: 99999,
  76366: 48660,
  76367: 48660,
  76369: 48660,
  76370: 48660,
  76371: 99999,
  76372: 99999,
  76373: 46900,
  76374: 48660,
  76377: 48660,
  76379: 48660,
  76380: 99999,
  76384: 99999,
  76388: 99999,
  76389: 48660,
  76401: 44500,
  76402: 44500,
  76424: 99999,
  76426: 19100,
  76427: 99999,
  76429: 99999,
  76430: 99999,
  76431: 19100,
  76432: 99999,
  76433: 24180,
  76435: 99999,
  76436: 44500,
  76437: 99999,
  76439: 19100,
  76442: 99999,
  76443: 99999,
  76444: 99999,
  76445: 99999,
  76446: 44500,
  76448: 99999,
  76449: 33420,
  76450: 33420,
  76452: 99999,
  76453: 44500,
  76454: 99999,
  76455: 99999,
  76457: 44500,
  76458: 19100,
  76459: 99999,
  76460: 99999,
  76461: 44500,
  76462: 19100,
  76463: 44500,
  76464: 10180,
  76465: 44500,
  76466: 99999,
  76467: 24180,
  76468: 99999,
  76469: 10180,
  76470: 99999,
  76471: 15220,
  76472: 33420,
  76474: 15220,
  76475: 33420,
  76476: 44500,
  76481: 99999,
  76483: 99999,
  76484: 33420,
  76485: 19100,
  76486: 99999,
  76487: 99999,
  76490: 19100,
  76491: 99999,
  76501: 28660,
  76502: 28660,
  76503: 28660,
  76504: 28660,
  76508: 28660,
  76511: 99999,
  76513: 28660,
  76518: 99999,
  76519: 28660,
  76520: 99999,
  76522: 28660,
  76523: 99999,
  76524: 47380,
  76525: 99999,
  76526: 28660,
  76527: 28660,
  76528: 99999,
  76530: 99999,
  76531: 99999,
  76533: 28660,
  76534: 28660,
  76537: 12420,
  76538: 99999,
  76539: 99999,
  76540: 28660,
  76541: 28660,
  76542: 28660,
  76543: 28660,
  76544: 28660,
  76547: 28660,
  76548: 28660,
  76549: 28660,
  76550: 28660,
  76554: 28660,
  76556: 99999,
  76557: 28660,
  76558: 28660,
  76559: 28660,
  76561: 28660,
  76564: 28660,
  76565: 99999,
  76566: 28660,
  76567: 99999,
  76569: 28660,
  76570: 28660,
  76571: 12420,
  76573: 12420,
  76574: 12420,
  76577: 12420,
  76578: 99999,
  76579: 47380,
  76596: 28660,
  76597: 28660,
  76598: 28660,
  76599: 28660,
  76621: 47380,
  76622: 99999,
  76623: 19100,
  76624: 99999,
  76626: 19100,
  76627: 99999,
  76628: 99999,
  76629: 47380,
  76630: 47380,
  76631: 99999,
  76632: 47380,
  76633: 99999,
  76634: 28660,
  76635: 99999,
  76636: 99999,
  76637: 99999,
  76638: 28660,
  76639: 18620,
  76640: 47380,
  76641: 19100,
  76642: 99999,
  76643: 47380,
  76645: 99999,
  76648: 18620,
  76649: 99999,
  76650: 99999,
  76651: 19100,
  76652: 99999,
  76653: 17780,
  76654: 47380,
  76655: 47380,
  76656: 47380,
  76657: 28660,
  76660: 99999,
  76661: 47380,
  76664: 47380,
  76665: 99999,
  76666: 99999,
  76667: 99999,
  76670: 99999,
  76671: 99999,
  76673: 99999,
  76676: 99999,
  76678: 99999,
  76679: 18620,
  76680: 47380,
  76681: 18620,
  76682: 99999,
  76684: 47380,
  76685: 47380,
  76686: 99999,
  76687: 17780,
  76689: 28660,
  76690: 44500,
  76691: 99999,
  76692: 99999,
  76693: 18620,
  76701: 47380,
  76702: 47380,
  76703: 47380,
  76704: 47380,
  76705: 47380,
  76706: 47380,
  76707: 47380,
  76708: 47380,
  76710: 47380,
  76711: 47380,
  76712: 47380,
  76714: 47380,
  76716: 47380,
  76797: 47380,
  76798: 47380,
  76799: 47380,
  76801: 15220,
  76802: 15220,
  76803: 15220,
  76804: 15220,
  76820: 99999,
  76821: 99999,
  76823: 15220,
  76824: 28660,
  76825: 99999,
  76827: 15220,
  76828: 99999,
  76831: 99999,
  76832: 99999,
  76834: 99999,
  76836: 99999,
  76837: 99999,
  76841: 99999,
  76842: 99999,
  76844: 99999,
  76845: 99999,
  76848: 99999,
  76849: 99999,
  76852: 99999,
  76853: 99999,
  76854: 99999,
  76855: 99999,
  76856: 99999,
  76857: 99999,
  76858: 99999,
  76859: 99999,
  76861: 99999,
  76862: 99999,
  76864: 99999,
  76865: 99999,
  76866: 99999,
  76869: 99999,
  76870: 99999,
  76871: 99999,
  76872: 99999,
  76873: 99999,
  76874: 99999,
  76875: 99999,
  76877: 99999,
  76878: 15220,
  76880: 99999,
  76882: 99999,
  76883: 99999,
  76884: 99999,
  76885: 99999,
  76886: 41660,
  76887: 99999,
  76888: 99999,
  76890: 15220,
  76901: 41660,
  76902: 41660,
  76903: 41660,
  76904: 41660,
  76905: 41660,
  76906: 41660,
  76908: 41660,
  76909: 41660,
  76930: 41660,
  76932: 99999,
  76933: 99999,
  76934: 41660,
  76935: 41660,
  76936: 99999,
  76937: 41660,
  76939: 41660,
  76940: 41660,
  76941: 41660,
  76943: 99999,
  76945: 99999,
  76949: 99999,
  76950: 99999,
  76951: 41660,
  76953: 99999,
  76955: 41660,
  76957: 41660,
  76958: 41660,
  77001: 26420,
  77002: 26420,
  77003: 26420,
  77004: 26420,
  77005: 26420,
  77006: 26420,
  77007: 26420,
  77008: 26420,
  77009: 26420,
  77010: 26420,
  77011: 26420,
  77012: 26420,
  77013: 26420,
  77014: 26420,
  77015: 26420,
  77016: 26420,
  77017: 26420,
  77018: 26420,
  77019: 26420,
  77020: 26420,
  77021: 26420,
  77022: 26420,
  77023: 26420,
  77024: 26420,
  77025: 26420,
  77026: 26420,
  77027: 26420,
  77028: 26420,
  77029: 26420,
  77030: 26420,
  77031: 26420,
  77032: 26420,
  77033: 26420,
  77034: 26420,
  77035: 26420,
  77036: 26420,
  77037: 26420,
  77038: 26420,
  77039: 26420,
  77040: 26420,
  77041: 26420,
  77042: 26420,
  77043: 26420,
  77044: 26420,
  77045: 26420,
  77046: 26420,
  77047: 26420,
  77048: 26420,
  77049: 26420,
  77050: 26420,
  77051: 26420,
  77052: 26420,
  77053: 26420,
  77054: 26420,
  77055: 26420,
  77056: 26420,
  77057: 26420,
  77058: 26420,
  77059: 26420,
  77060: 26420,
  77061: 26420,
  77062: 26420,
  77063: 26420,
  77064: 26420,
  77065: 26420,
  77066: 26420,
  77067: 26420,
  77068: 26420,
  77069: 26420,
  77070: 26420,
  77071: 26420,
  77072: 26420,
  77073: 26420,
  77074: 26420,
  77075: 26420,
  77076: 26420,
  77077: 26420,
  77078: 26420,
  77079: 26420,
  77080: 26420,
  77081: 26420,
  77082: 26420,
  77083: 26420,
  77084: 26420,
  77085: 26420,
  77086: 26420,
  77087: 26420,
  77088: 26420,
  77089: 26420,
  77090: 26420,
  77091: 26420,
  77092: 26420,
  77093: 26420,
  77094: 26420,
  77095: 26420,
  77096: 26420,
  77098: 26420,
  77099: 26420,
  77204: 26420,
  77205: 26420,
  77206: 26420,
  77207: 26420,
  77208: 26420,
  77210: 26420,
  77215: 26420,
  77217: 26420,
  77218: 26420,
  77219: 26420,
  77220: 26420,
  77221: 26420,
  77222: 26420,
  77223: 26420,
  77224: 26420,
  77225: 26420,
  77226: 26420,
  77227: 26420,
  77228: 26420,
  77230: 26420,
  77231: 26420,
  77233: 26420,
  77234: 26420,
  77235: 26420,
  77236: 26420,
  77237: 26420,
  77238: 26420,
  77240: 26420,
  77241: 26420,
  77242: 26420,
  77243: 26420,
  77244: 26420,
  77245: 26420,
  77248: 26420,
  77249: 26420,
  77251: 26420,
  77252: 26420,
  77253: 26420,
  77254: 26420,
  77255: 26420,
  77256: 26420,
  77257: 26420,
  77258: 26420,
  77259: 26420,
  77261: 26420,
  77262: 26420,
  77263: 26420,
  77265: 26420,
  77266: 26420,
  77267: 26420,
  77268: 26420,
  77269: 26420,
  77270: 26420,
  77271: 26420,
  77272: 26420,
  77273: 26420,
  77274: 26420,
  77275: 26420,
  77277: 26420,
  77279: 26420,
  77280: 26420,
  77282: 26420,
  77284: 26420,
  77287: 26420,
  77288: 26420,
  77289: 26420,
  77290: 26420,
  77291: 26420,
  77292: 26420,
  77293: 26420,
  77301: 26420,
  77302: 26420,
  77303: 26420,
  77304: 26420,
  77305: 26420,
  77306: 26420,
  77315: 26420,
  77316: 26420,
  77318: 26420,
  77320: 26660,
  77325: 26420,
  77326: 99999,
  77327: 99999,
  77328: 26420,
  77331: 99999,
  77332: 99999,
  77333: 26420,
  77334: 26660,
  77335: 99999,
  77336: 26420,
  77338: 26420,
  77339: 26420,
  77340: 26660,
  77341: 26660,
  77342: 26660,
  77345: 26420,
  77346: 26420,
  77347: 26420,
  77350: 99999,
  77351: 99999,
  77353: 26420,
  77354: 26420,
  77355: 26420,
  77356: 99999,
  77357: 26420,
  77358: 26420,
  77359: 99999,
  77360: 99999,
  77362: 26420,
  77363: 99999,
  77364: 99999,
  77365: 26420,
  77367: 26660,
  77368: 26420,
  77369: 26420,
  77371: 99999,
  77372: 26420,
  77373: 26420,
  77374: 13140,
  77375: 26420,
  77376: 13140,
  77377: 26420,
  77378: 26660,
  77379: 26420,
  77380: 26420,
  77381: 26420,
  77382: 26420,
  77383: 26420,
  77384: 26420,
  77385: 26420,
  77386: 26420,
  77387: 26420,
  77388: 26420,
  77389: 26420,
  77391: 26420,
  77393: 26420,
  77396: 26420,
  77399: 99999,
  77401: 26420,
  77402: 26420,
  77404: 13060,
  77406: 26420,
  77407: 26420,
  77410: 26420,
  77411: 26420,
  77412: 99999,
  77413: 26420,
  77414: 13060,
  77415: 13060,
  77417: 26420,
  77418: 26420,
  77419: 13060,
  77420: 13060,
  77422: 26420,
  77423: 26420,
  77426: 14780,
  77428: 13060,
  77429: 26420,
  77430: 26420,
  77431: 26420,
  77432: 20900,
  77433: 26420,
  77434: 99999,
  77435: 26420,
  77436: 20900,
  77437: 20900,
  77440: 13060,
  77441: 26420,
  77442: 99999,
  77443: 20900,
  77444: 26420,
  77445: 26420,
  77446: 26420,
  77447: 26420,
  77448: 20900,
  77449: 26420,
  77450: 26420,
  77451: 26420,
  77452: 26420,
  77453: 20900,
  77454: 20900,
  77455: 20900,
  77456: 13060,
  77457: 13060,
  77458: 13060,
  77459: 26420,
  77460: 99999,
  77461: 26420,
  77463: 26420,
  77464: 26420,
  77465: 99999,
  77466: 26420,
  77467: 20900,
  77468: 13060,
  77469: 26420,
  77470: 99999,
  77471: 26420,
  77473: 26420,
  77474: 26420,
  77475: 99999,
  77476: 26420,
  77477: 26420,
  77478: 26420,
  77479: 26420,
  77480: 13060,
  77481: 26420,
  77482: 13060,
  77483: 13060,
  77484: 99999,
  77485: 26420,
  77486: 26420,
  77487: 26420,
  77488: 20900,
  77489: 26420,
  77491: 26420,
  77492: 26420,
  77493: 26420,
  77494: 26420,
  77496: 26420,
  77497: 26420,
  77498: 26420,
  77501: 26420,
  77502: 26420,
  77503: 26420,
  77504: 26420,
  77505: 26420,
  77506: 26420,
  77507: 26420,
  77508: 26420,
  77510: 26420,
  77511: 26420,
  77512: 26420,
  77514: 26420,
  77515: 26420,
  77516: 26420,
  77517: 26420,
  77518: 26420,
  77519: 13140,
  77520: 26420,
  77521: 26420,
  77522: 26420,
  77523: 26420,
  77530: 26420,
  77531: 26420,
  77532: 26420,
  77533: 26420,
  77534: 26420,
  77535: 26420,
  77536: 26420,
  77538: 26420,
  77539: 26420,
  77541: 26420,
  77542: 26420,
  77545: 26420,
  77546: 26420,
  77547: 26420,
  77549: 26420,
  77550: 26420,
  77551: 26420,
  77552: 26420,
  77553: 26420,
  77554: 26420,
  77555: 26420,
  77560: 26420,
  77561: 26420,
  77562: 26420,
  77563: 26420,
  77564: 26420,
  77565: 26420,
  77566: 26420,
  77568: 26420,
  77571: 26420,
  77572: 26420,
  77573: 26420,
  77574: 26420,
  77575: 26420,
  77577: 26420,
  77578: 26420,
  77580: 26420,
  77581: 26420,
  77582: 26420,
  77583: 26420,
  77584: 26420,
  77585: 13140,
  77586: 26420,
  77587: 26420,
  77588: 26420,
  77590: 26420,
  77591: 26420,
  77597: 26420,
  77598: 26420,
  77611: 13140,
  77612: 99999,
  77613: 13140,
  77614: 99999,
  77615: 99999,
  77616: 99999,
  77617: 26420,
  77619: 13140,
  77622: 13140,
  77623: 26420,
  77624: 99999,
  77625: 13140,
  77626: 13140,
  77627: 13140,
  77629: 13140,
  77630: 13140,
  77631: 13140,
  77632: 13140,
  77639: 13140,
  77640: 13140,
  77642: 13140,
  77650: 26420,
  77651: 13140,
  77655: 13140,
  77656: 13140,
  77657: 13140,
  77659: 13140,
  77660: 99999,
  77661: 26420,
  77662: 13140,
  77663: 13140,
  77664: 99999,
  77665: 26420,
  77670: 13140,
  77701: 13140,
  77702: 13140,
  77703: 13140,
  77704: 13140,
  77705: 13140,
  77706: 13140,
  77707: 13140,
  77708: 13140,
  77713: 13140,
  77720: 13140,
  77725: 13140,
  77726: 13140,
  77801: 17780,
  77802: 17780,
  77803: 17780,
  77805: 17780,
  77806: 17780,
  77807: 17780,
  77808: 17780,
  77830: 99999,
  77831: 26660,
  77833: 26420,
  77834: 14780,
  77835: 26420,
  77836: 17780,
  77837: 17780,
  77838: 17780,
  77840: 17780,
  77841: 17780,
  77842: 17780,
  77843: 17780,
  77844: 17780,
  77845: 17780,
  77850: 99999,
  77852: 17780,
  77853: 99999,
  77855: 99999,
  77856: 17780,
  77857: 99999,
  77859: 17780,
  77861: 99999,
  77862: 17780,
  77863: 17780,
  77864: 99999,
  77865: 17780,
  77866: 17780,
  77867: 17780,
  77868: 17780,
  77870: 17780,
  77871: 99999,
  77872: 99999,
  77873: 26660,
  77875: 99999,
  77876: 99999,
  77878: 17780,
  77879: 17780,
  77880: 14780,
  77881: 17780,
  77882: 17780,
  77901: 47020,
  77902: 47020,
  77903: 47020,
  77904: 47020,
  77905: 47020,
  77950: 99999,
  77951: 47020,
  77954: 99999,
  77957: 99999,
  77960: 47020,
  77961: 99999,
  77962: 99999,
  77963: 47020,
  77964: 99999,
  77967: 99999,
  77968: 47020,
  77969: 99999,
  77970: 99999,
  77971: 99999,
  77973: 47020,
  77974: 47020,
  77975: 99999,
  77976: 47020,
  77977: 47020,
  77978: 38920,
  77979: 47020,
  77982: 38920,
  77983: 38920,
  77984: 99999,
  77986: 99999,
  77987: 99999,
  77988: 47020,
  77989: 99999,
  77990: 99999,
  77991: 99999,
  77993: 47020,
  77994: 99999,
  77995: 99999,
  78001: 99999,
  78002: 41700,
  78003: 28500,
  78004: 41700,
  78005: 41700,
  78006: 41700,
  78007: 99999,
  78008: 41700,
  78009: 41700,
  78010: 28500,
  78011: 41700,
  78012: 41700,
  78013: 41700,
  78014: 37770,
  78015: 41700,
  78016: 37770,
  78017: 37770,
  78019: 99999,
  78021: 99999,
  78022: 99999,
  78023: 41700,
  78024: 28500,
  78025: 28500,
  78026: 41700,
  78027: 99999,
  78028: 23240,
  78029: 28500,
  78039: 41700,
  78040: 29700,
  78041: 29700,
  78042: 29700,
  78043: 29700,
  78044: 29700,
  78045: 29700,
  78046: 29700,
  78050: 41700,
  78052: 41700,
  78054: 41700,
  78055: 28500,
  78056: 41700,
  78057: 37770,
  78058: 28500,
  78059: 41700,
  78060: 99999,
  78061: 99999,
  78062: 99999,
  78063: 28500,
  78064: 41700,
  78065: 41700,
  78066: 41700,
  78067: 49820,
  78069: 41700,
  78070: 99999,
  78071: 99999,
  78072: 99999,
  78073: 41700,
  78074: 41700,
  78075: 99999,
  78076: 49820,
  78101: 41700,
  78102: 99999,
  78104: 13300,
  78107: 47020,
  78108: 41700,
  78109: 41700,
  78111: 99999,
  78112: 41700,
  78113: 41700,
  78114: 41700,
  78115: 41700,
  78116: 99999,
  78117: 99999,
  78118: 41700,
  78119: 13300,
  78121: 41700,
  78122: 99999,
  78123: 41700,
  78124: 41700,
  78125: 13300,
  78130: 12420,
  78131: 41700,
  78132: 41700,
  78133: 41700,
  78135: 41700,
  78140: 41700,
  78141: 99999,
  78142: 13300,
  78143: 41700,
  78144: 99999,
  78145: 13300,
  78146: 13300,
  78147: 41700,
  78148: 41700,
  78150: 41700,
  78151: 99999,
  78152: 41700,
  78154: 41700,
  78155: 41700,
  78156: 41700,
  78159: 99999,
  78160: 99999,
  78161: 41700,
  78162: 13300,
  78163: 41700,
  78164: 47020,
  78201: 41700,
  78202: 41700,
  78203: 41700,
  78204: 41700,
  78205: 41700,
  78206: 41700,
  78207: 41700,
  78208: 41700,
  78209: 41700,
  78210: 41700,
  78211: 41700,
  78212: 41700,
  78213: 41700,
  78214: 41700,
  78215: 41700,
  78216: 41700,
  78217: 41700,
  78218: 41700,
  78219: 41700,
  78220: 41700,
  78221: 41700,
  78222: 41700,
  78223: 41700,
  78224: 41700,
  78225: 41700,
  78226: 41700,
  78227: 41700,
  78228: 41700,
  78229: 41700,
  78230: 41700,
  78231: 41700,
  78232: 41700,
  78233: 41700,
  78234: 41700,
  78235: 41700,
  78236: 41700,
  78237: 41700,
  78238: 41700,
  78239: 41700,
  78240: 41700,
  78241: 41700,
  78242: 41700,
  78243: 41700,
  78244: 41700,
  78245: 41700,
  78246: 41700,
  78247: 41700,
  78248: 41700,
  78249: 41700,
  78250: 41700,
  78251: 41700,
  78252: 41700,
  78253: 41700,
  78254: 41700,
  78255: 41700,
  78256: 41700,
  78257: 41700,
  78258: 41700,
  78259: 41700,
  78260: 41700,
  78261: 41700,
  78263: 41700,
  78264: 41700,
  78265: 41700,
  78266: 41700,
  78268: 41700,
  78269: 41700,
  78270: 41700,
  78278: 41700,
  78279: 41700,
  78280: 41700,
  78283: 41700,
  78284: 41700,
  78285: 41700,
  78288: 41700,
  78291: 41700,
  78292: 41700,
  78293: 41700,
  78294: 41700,
  78295: 41700,
  78296: 41700,
  78297: 41700,
  78298: 41700,
  78299: 41700,
  78330: 18580,
  78332: 10860,
  78335: 18580,
  78336: 40530,
  78338: 28780,
  78339: 18580,
  78340: 99999,
  78341: 10860,
  78342: 10860,
  78343: 18580,
  78344: 29700,
  78347: 18580,
  78349: 10860,
  78350: 99999,
  78351: 18580,
  78352: 18580,
  78353: 99999,
  78355: 10860,
  78357: 10860,
  78358: 40530,
  78359: 18580,
  78360: 99999,
  78361: 49820,
  78362: 18580,
  78363: 18580,
  78368: 13300,
  78369: 29700,
  78370: 18580,
  78371: 29700,
  78372: 99999,
  78373: 18580,
  78374: 18580,
  78375: 10860,
  78376: 10860,
  78377: 99999,
  78379: 28780,
  78380: 10860,
  78381: 40530,
  78382: 40530,
  78383: 10860,
  78384: 10860,
  78385: 28780,
  78387: 13300,
  78389: 99999,
  78390: 18580,
  78391: 13300,
  78393: 99999,
  78401: 18580,
  78402: 18580,
  78403: 18580,
  78404: 18580,
  78405: 18580,
  78406: 18580,
  78407: 18580,
  78408: 18580,
  78409: 18580,
  78410: 18580,
  78411: 18580,
  78412: 18580,
  78413: 18580,
  78414: 18580,
  78415: 18580,
  78416: 18580,
  78417: 18580,
  78418: 18580,
  78419: 18580,
  78426: 18580,
  78427: 18580,
  78460: 18580,
  78463: 18580,
  78465: 18580,
  78466: 18580,
  78467: 18580,
  78468: 18580,
  78469: 18580,
  78480: 18580,
  78501: 32580,
  78502: 32580,
  78503: 32580,
  78504: 32580,
  78505: 32580,
  78516: 32580,
  78520: 15180,
  78521: 15180,
  78522: 15180,
  78523: 15180,
  78526: 15180,
  78535: 15180,
  78536: 40100,
  78537: 32580,
  78538: 32580,
  78539: 32580,
  78540: 32580,
  78541: 32580,
  78542: 32580,
  78543: 32580,
  78545: 40100,
  78547: 40100,
  78548: 40100,
  78549: 32580,
  78550: 39700,
  78551: 15180,
  78552: 15180,
  78553: 15180,
  78557: 32580,
  78558: 32580,
  78559: 15180,
  78560: 32580,
  78561: 39700,
  78562: 32580,
  78563: 32580,
  78564: 49820,
  78565: 32580,
  78566: 15180,
  78567: 15180,
  78568: 15180,
  78569: 15180,
  78570: 32580,
  78572: 32580,
  78573: 32580,
  78574: 32580,
  78575: 15180,
  78576: 32580,
  78577: 32580,
  78578: 15180,
  78579: 32580,
  78580: 39700,
  78582: 40100,
  78583: 15180,
  78584: 49820,
  78585: 40100,
  78586: 15180,
  78588: 40100,
  78589: 32580,
  78590: 39700,
  78591: 40100,
  78592: 15180,
  78593: 15180,
  78594: 39700,
  78595: 40100,
  78596: 32580,
  78597: 15180,
  78598: 39700,
  78599: 32580,
  78602: 12420,
  78604: 99999,
  78605: 12420,
  78606: 12420,
  78607: 99999,
  78608: 99999,
  78609: 99999,
  78610: 12420,
  78611: 99999,
  78612: 12420,
  78613: 12420,
  78614: 99999,
  78615: 12420,
  78616: 12420,
  78617: 12420,
  78618: 23240,
  78619: 12420,
  78620: 99999,
  78621: 12420,
  78622: 12420,
  78623: 12420,
  78624: 99999,
  78626: 12420,
  78627: 12420,
  78628: 12420,
  78629: 99999,
  78630: 12420,
  78631: 28500,
  78632: 12420,
  78633: 12420,
  78634: 12420,
  78635: 23240,
  78636: 99999,
  78638: 41700,
  78639: 99999,
  78640: 12420,
  78641: 12420,
  78642: 99999,
  78643: 99999,
  78644: 12420,
  78645: 12420,
  78646: 12420,
  78648: 99999,
  78650: 12420,
  78651: 12420,
  78652: 12420,
  78653: 12420,
  78654: 99999,
  78655: 41700,
  78656: 12420,
  78657: 99999,
  78659: 99999,
  78660: 12420,
  78661: 12420,
  78662: 12420,
  78663: 99999,
  78664: 12420,
  78665: 12420,
  78666: 41700,
  78667: 12420,
  78669: 12420,
  78670: 41700,
  78671: 99999,
  78672: 99999,
  78673: 12420,
  78674: 12420,
  78675: 23240,
  78676: 99999,
  78677: 99999,
  78680: 12420,
  78681: 12420,
  78682: 12420,
  78683: 12420,
  78691: 12420,
  78701: 12420,
  78702: 12420,
  78703: 12420,
  78704: 12420,
  78705: 12420,
  78708: 12420,
  78709: 12420,
  78710: 12420,
  78711: 12420,
  78712: 12420,
  78713: 12420,
  78714: 12420,
  78715: 12420,
  78716: 12420,
  78717: 12420,
  78718: 12420,
  78719: 12420,
  78720: 12420,
  78721: 12420,
  78722: 12420,
  78723: 12420,
  78724: 12420,
  78725: 12420,
  78726: 12420,
  78727: 12420,
  78728: 12420,
  78729: 12420,
  78730: 12420,
  78731: 12420,
  78732: 12420,
  78733: 12420,
  78734: 12420,
  78735: 12420,
  78736: 12420,
  78737: 12420,
  78738: 12420,
  78739: 12420,
  78741: 12420,
  78742: 12420,
  78744: 12420,
  78745: 12420,
  78746: 12420,
  78747: 12420,
  78748: 12420,
  78749: 12420,
  78750: 12420,
  78751: 12420,
  78752: 12420,
  78753: 12420,
  78754: 12420,
  78755: 12420,
  78756: 12420,
  78757: 12420,
  78758: 12420,
  78759: 12420,
  78760: 12420,
  78761: 12420,
  78762: 12420,
  78763: 12420,
  78764: 12420,
  78765: 12420,
  78766: 12420,
  78767: 12420,
  78768: 12420,
  78772: 12420,
  78774: 12420,
  78778: 12420,
  78779: 12420,
  78783: 12420,
  78799: 12420,
  78801: 99999,
  78802: 46620,
  78827: 99999,
  78828: 99999,
  78829: 99999,
  78830: 99999,
  78832: 99999,
  78833: 99999,
  78834: 99999,
  78836: 99999,
  78837: 19620,
  78838: 46620,
  78839: 99999,
  78840: 99999,
  78841: 19620,
  78842: 19620,
  78843: 19620,
  78847: 19620,
  78850: 41700,
  78851: 99999,
  78852: 20580,
  78853: 20580,
  78860: 20580,
  78861: 41700,
  78870: 46620,
  78871: 19620,
  78872: 99999,
  78873: 99999,
  78877: 99999,
  78879: 99999,
  78880: 99999,
  78881: 46620,
  78883: 41700,
  78884: 41700,
  78885: 41700,
  78886: 41700,
  78931: 26420,
  78932: 99999,
  78933: 26420,
  78934: 99999,
  78935: 99999,
  78938: 99999,
  78940: 99999,
  78941: 12420,
  78942: 99999,
  78943: 99999,
  78944: 26420,
  78945: 99999,
  78946: 99999,
  78947: 99999,
  78948: 99999,
  78949: 99999,
  78950: 26420,
  78951: 99999,
  78952: 99999,
  78953: 12420,
  78954: 99999,
  78956: 99999,
  78957: 12420,
  78959: 12420,
  78960: 99999,
  78962: 99999,
  78963: 99999,
  79001: 11100,
  79002: 37420,
  79003: 99999,
  79005: 99999,
  79007: 14420,
  79008: 14420,
  79009: 99999,
  79010: 11100,
  79011: 99999,
  79012: 11100,
  79013: 20300,
  79014: 99999,
  79015: 11100,
  79016: 11100,
  79018: 20300,
  79019: 11100,
  79021: 38380,
  79022: 20300,
  79024: 99999,
  79025: 25820,
  79027: 99999,
  79029: 99999,
  79031: 99999,
  79032: 38380,
  79033: 99999,
  79034: 99999,
  79035: 99999,
  79036: 11100,
  79039: 11100,
  79040: 99999,
  79041: 38380,
  79042: 99999,
  79043: 38380,
  79044: 99999,
  79045: 25820,
  79046: 99999,
  79051: 99999,
  79052: 99999,
  79053: 99999,
  79054: 37420,
  79056: 99999,
  79057: 37420,
  79058: 11100,
  79059: 37420,
  79061: 37420,
  79062: 14420,
  79063: 99999,
  79064: 38380,
  79065: 99999,
  79066: 37420,
  79068: 11100,
  79070: 99999,
  79072: 38380,
  79073: 38380,
  79078: 14420,
  79079: 99999,
  79080: 11100,
  79081: 99999,
  79082: 99999,
  79083: 20300,
  79084: 99999,
  79085: 99999,
  79086: 20300,
  79087: 99999,
  79088: 99999,
  79091: 11100,
  79092: 25820,
  79093: 99999,
  79094: 99999,
  79095: 99999,
  79096: 99999,
  79097: 37420,
  79098: 25820,
  79101: 11100,
  79102: 11100,
  79103: 11100,
  79104: 11100,
  79105: 11100,
  79106: 11100,
  79107: 11100,
  79108: 11100,
  79109: 11100,
  79110: 11100,
  79111: 11100,
  79114: 11100,
  79116: 11100,
  79117: 11100,
  79118: 11100,
  79119: 11100,
  79120: 11100,
  79121: 11100,
  79124: 11100,
  79159: 11100,
  79201: 99999,
  79220: 99999,
  79221: 99999,
  79223: 99999,
  79225: 46900,
  79226: 11100,
  79227: 99999,
  79229: 99999,
  79230: 99999,
  79231: 99999,
  79233: 99999,
  79234: 99999,
  79235: 31180,
  79236: 99999,
  79237: 99999,
  79239: 99999,
  79240: 99999,
  79241: 99999,
  79243: 31180,
  79244: 99999,
  79245: 99999,
  79247: 99999,
  79248: 99999,
  79250: 38380,
  79251: 99999,
  79252: 99999,
  79255: 99999,
  79256: 99999,
  79257: 99999,
  79258: 99999,
  79259: 99999,
  79261: 99999,
  79311: 31180,
  79312: 99999,
  79313: 99999,
  79314: 99999,
  79316: 99999,
  79322: 31180,
  79323: 99999,
  79324: 99999,
  79325: 99999,
  79326: 99999,
  79329: 31180,
  79330: 99999,
  79331: 33260,
  79336: 99999,
  79339: 30220,
  79342: 99999,
  79343: 31180,
  79344: 99999,
  79345: 31180,
  79346: 99999,
  79347: 99999,
  79350: 31180,
  79351: 99999,
  79353: 30220,
  79355: 99999,
  79356: 31180,
  79357: 31180,
  79358: 30220,
  79359: 99999,
  79360: 99999,
  79363: 30220,
  79364: 99999,
  79366: 31180,
  79367: 30220,
  79369: 99999,
  79370: 99999,
  79371: 99999,
  79372: 30220,
  79373: 31180,
  79376: 99999,
  79377: 29500,
  79378: 99999,
  79379: 99999,
  79380: 30220,
  79381: 31180,
  79382: 31180,
  79383: 31180,
  79401: 31180,
  79402: 31180,
  79403: 31180,
  79404: 31180,
  79406: 31180,
  79407: 31180,
  79408: 31180,
  79409: 31180,
  79410: 31180,
  79411: 31180,
  79412: 31180,
  79413: 31180,
  79414: 31180,
  79415: 31180,
  79416: 31180,
  79423: 31180,
  79424: 31180,
  79430: 31180,
  79452: 31180,
  79453: 31180,
  79464: 31180,
  79490: 31180,
  79491: 31180,
  79493: 31180,
  79499: 31180,
  79501: 10180,
  79502: 99999,
  79503: 10180,
  79504: 10180,
  79505: 99999,
  79506: 99999,
  79508: 10180,
  79510: 10180,
  79511: 13700,
  79512: 43660,
  79516: 43660,
  79517: 99999,
  79518: 99999,
  79519: 99999,
  79520: 10180,
  79521: 10180,
  79525: 10180,
  79526: 43660,
  79527: 99999,
  79528: 99999,
  79529: 99999,
  79530: 99999,
  79532: 43660,
  79533: 10180,
  79534: 99999,
  79535: 45020,
  79536: 45020,
  79537: 45020,
  79538: 99999,
  79539: 99999,
  79540: 99999,
  79541: 10180,
  79543: 99999,
  79544: 99999,
  79545: 43660,
  79546: 43660,
  79547: 99999,
  79548: 99999,
  79549: 99999,
  79553: 10180,
  79556: 99999,
  79560: 99999,
  79561: 10180,
  79562: 10180,
  79563: 10180,
  79565: 99999,
  79566: 10180,
  79567: 10180,
  79601: 10180,
  79602: 10180,
  79603: 10180,
  79604: 10180,
  79605: 10180,
  79606: 10180,
  79607: 10180,
  79608: 10180,
  79697: 10180,
  79698: 10180,
  79699: 10180,
  79701: 33260,
  79702: 33260,
  79703: 33260,
  79704: 33260,
  79705: 33260,
  79706: 33260,
  79707: 36220,
  79708: 33260,
  79710: 33260,
  79711: 33260,
  79712: 33260,
  79713: 29500,
  79714: 11380,
  79718: 37780,
  79719: 99999,
  79720: 13700,
  79730: 99999,
  79731: 99999,
  79733: 13700,
  79734: 99999,
  79735: 99999,
  79738: 99999,
  79739: 99999,
  79740: 99999,
  79741: 36220,
  79742: 99999,
  79743: 99999,
  79744: 99999,
  79745: 99999,
  79748: 13700,
  79749: 33260,
  79752: 99999,
  79754: 37780,
  79755: 99999,
  79756: 99999,
  79758: 36220,
  79759: 36220,
  79760: 36220,
  79761: 36220,
  79762: 36220,
  79763: 36220,
  79764: 36220,
  79765: 33260,
  79766: 36220,
  79768: 36220,
  79769: 36220,
  79770: 37780,
  79772: 37780,
  79776: 36220,
  79777: 99999,
  79778: 99999,
  79780: 37780,
  79781: 99999,
  79782: 33260,
  79783: 33260,
  79785: 37780,
  79788: 99999,
  79789: 99999,
  79821: 21340,
  79830: 99999,
  79832: 99999,
  79834: 99999,
  79835: 21340,
  79836: 21340,
  79837: 21340,
  79838: 21340,
  79839: 21340,
  79842: 99999,
  79843: 99999,
  79845: 99999,
  79846: 99999,
  79847: 21340,
  79848: 99999,
  79849: 21340,
  79851: 21340,
  79852: 99999,
  79853: 21340,
  79854: 99999,
  79855: 99999,
  79901: 21340,
  79902: 21340,
  79903: 21340,
  79904: 21340,
  79905: 21340,
  79906: 21340,
  79907: 21340,
  79908: 21340,
  79910: 21340,
  79911: 21340,
  79912: 21340,
  79913: 21340,
  79914: 21340,
  79915: 21340,
  79916: 21340,
  79917: 21340,
  79918: 21340,
  79922: 21340,
  79923: 21340,
  79924: 21340,
  79925: 21340,
  79926: 21340,
  79927: 21340,
  79928: 21340,
  79929: 21340,
  79930: 21340,
  79931: 21340,
  79932: 21340,
  79934: 21340,
  79935: 21340,
  79936: 21340,
  79937: 21340,
  79938: 21340,
  79940: 21340,
  79941: 21340,
  79942: 21340,
  79943: 21340,
  79944: 21340,
  79945: 21340,
  79946: 21340,
  79947: 21340,
  79948: 21340,
  79949: 21340,
  79950: 21340,
  79951: 21340,
  79952: 21340,
  79953: 21340,
  79954: 21340,
  79955: 21340,
  79968: 21340,
  79995: 21340,
  79996: 21340,
  79997: 21340,
  80001: 19740,
  80002: 19740,
  80003: 19740,
  80004: 19740,
  80005: 19740,
  80006: 19740,
  80007: 19740,
  80010: 19740,
  80011: 19740,
  80012: 19740,
  80013: 19740,
  80014: 19740,
  80015: 19740,
  80016: 19740,
  80017: 19740,
  80018: 19740,
  80019: 19740,
  80020: 19740,
  80021: 14500,
  80022: 19740,
  80023: 19740,
  80024: 19740,
  80025: 14500,
  80026: 14500,
  80027: 14500,
  80030: 19740,
  80031: 19740,
  80033: 19740,
  80034: 19740,
  80035: 19740,
  80036: 19740,
  80037: 19740,
  80038: 19740,
  80040: 19740,
  80041: 19740,
  80042: 19740,
  80044: 19740,
  80045: 19740,
  80046: 19740,
  80047: 19740,
  80101: 19740,
  80102: 19740,
  80103: 19740,
  80104: 19740,
  80105: 19740,
  80106: 17820,
  80107: 19740,
  80108: 19740,
  80109: 19740,
  80110: 19740,
  80111: 19740,
  80112: 19740,
  80113: 19740,
  80116: 19740,
  80117: 19740,
  80118: 17820,
  80120: 19740,
  80121: 19740,
  80122: 19740,
  80123: 19740,
  80124: 19740,
  80125: 19740,
  80126: 19740,
  80127: 19740,
  80128: 19740,
  80129: 19740,
  80130: 19740,
  80131: 19740,
  80132: 17820,
  80133: 17820,
  80134: 19740,
  80135: 19740,
  80136: 19740,
  80137: 19740,
  80138: 19740,
  80150: 19740,
  80151: 19740,
  80155: 19740,
  80160: 19740,
  80161: 19740,
  80162: 19740,
  80163: 19740,
  80165: 19740,
  80166: 19740,
  80201: 19740,
  80202: 19740,
  80203: 19740,
  80204: 19740,
  80205: 19740,
  80206: 19740,
  80207: 19740,
  80208: 19740,
  80209: 19740,
  80210: 19740,
  80211: 19740,
  80212: 19740,
  80214: 19740,
  80215: 19740,
  80216: 19740,
  80217: 19740,
  80218: 19740,
  80219: 19740,
  80220: 19740,
  80221: 19740,
  80222: 19740,
  80223: 19740,
  80224: 19740,
  80225: 19740,
  80226: 19740,
  80227: 19740,
  80228: 19740,
  80229: 19740,
  80230: 19740,
  80231: 19740,
  80232: 19740,
  80233: 19740,
  80234: 19740,
  80235: 19740,
  80236: 19740,
  80237: 19740,
  80238: 19740,
  80239: 19740,
  80241: 19740,
  80243: 19740,
  80246: 19740,
  80247: 19740,
  80248: 19740,
  80249: 19740,
  80250: 19740,
  80256: 19740,
  80257: 19740,
  80259: 19740,
  80260: 19740,
  80261: 19740,
  80263: 19740,
  80264: 19740,
  80265: 19740,
  80266: 19740,
  80271: 19740,
  80273: 19740,
  80274: 19740,
  80281: 19740,
  80290: 19740,
  80291: 19740,
  80293: 19740,
  80294: 19740,
  80299: 19740,
  80301: 14500,
  80302: 14500,
  80303: 14500,
  80304: 14500,
  80305: 14500,
  80306: 14500,
  80307: 14500,
  80308: 14500,
  80309: 14500,
  80310: 14500,
  80314: 14500,
  80401: 19740,
  80402: 19740,
  80403: 14500,
  80419: 19740,
  80420: 19740,
  80421: 19740,
  80422: 14500,
  80423: 99999,
  80424: 14720,
  80425: 19740,
  80426: 20780,
  80427: 19740,
  80428: 44460,
  80429: 99999,
  80430: 22660,
  80432: 19740,
  80433: 19740,
  80434: 99999,
  80435: 14720,
  80436: 19740,
  80437: 19740,
  80438: 19740,
  80439: 19740,
  80440: 19740,
  80442: 99999,
  80443: 14720,
  80444: 19740,
  80446: 99999,
  80447: 99999,
  80448: 19740,
  80449: 19740,
  80451: 99999,
  80452: 19740,
  80453: 19740,
  80454: 19740,
  80455: 14500,
  80456: 19740,
  80457: 19740,
  80459: 99999,
  80461: 99999,
  80463: 44460,
  80465: 19740,
  80466: 14500,
  80467: 44460,
  80468: 99999,
  80469: 44460,
  80470: 19740,
  80471: 14500,
  80473: 99999,
  80474: 19740,
  80475: 19740,
  80476: 19740,
  80477: 44460,
  80478: 99999,
  80479: 44460,
  80480: 99999,
  80481: 14500,
  80482: 99999,
  80483: 44460,
  80487: 44460,
  80497: 14720,
  80498: 20780,
  80501: 14500,
  80502: 14500,
  80503: 14500,
  80504: 22660,
  80510: 22660,
  80511: 22660,
  80512: 22660,
  80513: 24540,
  80514: 24540,
  80515: 22660,
  80516: 19740,
  80517: 22660,
  80520: 24540,
  80521: 22660,
  80522: 22660,
  80523: 22660,
  80524: 22660,
  80525: 22660,
  80526: 22660,
  80527: 22660,
  80528: 22660,
  80530: 24540,
  80532: 22660,
  80533: 14500,
  80534: 24540,
  80535: 22660,
  80536: 22660,
  80537: 24540,
  80538: 22660,
  80539: 22660,
  80540: 22660,
  80541: 22660,
  80542: 24540,
  80543: 24540,
  80544: 14500,
  80545: 22660,
  80546: 24540,
  80547: 22660,
  80549: 22660,
  80550: 22660,
  80551: 24540,
  80553: 22660,
  80601: 19740,
  80602: 19740,
  80603: 24540,
  80610: 24540,
  80611: 24540,
  80612: 24540,
  80614: 19740,
  80615: 24540,
  80620: 24540,
  80621: 24540,
  80622: 24540,
  80623: 24540,
  80624: 24540,
  80631: 24540,
  80632: 24540,
  80633: 24540,
  80634: 24540,
  80638: 24540,
  80639: 24540,
  80640: 19740,
  80642: 19740,
  80643: 19740,
  80644: 24540,
  80645: 24540,
  80646: 24540,
  80648: 24540,
  80649: 24540,
  80650: 24540,
  80651: 24540,
  80652: 24540,
  80653: 22820,
  80654: 22820,
  80701: 19740,
  80705: 22820,
  80720: 44540,
  80721: 99999,
  80722: 44540,
  80723: 22820,
  80726: 44540,
  80727: 99999,
  80728: 44540,
  80729: 24540,
  80731: 99999,
  80732: 24540,
  80733: 22820,
  80734: 99999,
  80735: 99999,
  80736: 44540,
  80737: 99999,
  80740: 99999,
  80741: 99999,
  80742: 22820,
  80743: 99999,
  80744: 99999,
  80745: 44540,
  80746: 99999,
  80747: 44540,
  80749: 99999,
  80750: 22820,
  80751: 44540,
  80754: 22820,
  80755: 99999,
  80757: 99999,
  80758: 99999,
  80759: 99999,
  80801: 99999,
  80802: 99999,
  80804: 99999,
  80805: 99999,
  80807: 99999,
  80808: 17820,
  80809: 17820,
  80810: 99999,
  80812: 99999,
  80813: 17820,
  80814: 17820,
  80815: 99999,
  80816: 19740,
  80817: 17820,
  80818: 99999,
  80819: 17820,
  80820: 19740,
  80821: 99999,
  80822: 99999,
  80823: 99999,
  80824: 99999,
  80825: 99999,
  80826: 99999,
  80827: 17820,
  80828: 99999,
  80829: 17820,
  80830: 19740,
  80831: 19740,
  80832: 99999,
  80833: 99999,
  80834: 99999,
  80835: 17820,
  80836: 99999,
  80840: 17820,
  80841: 17820,
  80860: 17820,
  80861: 99999,
  80862: 99999,
  80863: 19740,
  80864: 17820,
  80866: 17820,
  80901: 17820,
  80902: 17820,
  80903: 17820,
  80904: 17820,
  80905: 17820,
  80906: 17820,
  80907: 17820,
  80908: 17820,
  80909: 17820,
  80910: 17820,
  80911: 17820,
  80912: 17820,
  80913: 17820,
  80914: 17820,
  80915: 17820,
  80916: 17820,
  80917: 17820,
  80918: 17820,
  80919: 17820,
  80920: 17820,
  80921: 17820,
  80922: 17820,
  80923: 17820,
  80924: 17820,
  80925: 17820,
  80926: 17820,
  80927: 17820,
  80928: 17820,
  80929: 17820,
  80930: 17820,
  80931: 17820,
  80932: 17820,
  80933: 17820,
  80934: 17820,
  80935: 17820,
  80936: 17820,
  80937: 17820,
  80938: 17820,
  80939: 17820,
  80941: 17820,
  80942: 17820,
  80946: 17820,
  80947: 17820,
  80949: 17820,
  80950: 17820,
  80951: 17820,
  80960: 17820,
  80962: 17820,
  80970: 17820,
  80977: 17820,
  80997: 17820,
  81001: 39380,
  81002: 39380,
  81003: 39380,
  81004: 39380,
  81005: 39380,
  81006: 39380,
  81007: 39380,
  81008: 39380,
  81009: 39380,
  81010: 39380,
  81011: 39380,
  81012: 39380,
  81019: 39380,
  81020: 99999,
  81021: 99999,
  81022: 39380,
  81023: 39380,
  81024: 99999,
  81025: 39380,
  81027: 99999,
  81029: 99999,
  81030: 99999,
  81033: 99999,
  81034: 99999,
  81036: 99999,
  81039: 99999,
  81040: 99999,
  81041: 99999,
  81043: 99999,
  81044: 99999,
  81045: 99999,
  81046: 99999,
  81047: 99999,
  81049: 99999,
  81050: 99999,
  81052: 99999,
  81054: 99999,
  81055: 99999,
  81057: 99999,
  81058: 99999,
  81059: 99999,
  81062: 99999,
  81063: 99999,
  81064: 99999,
  81067: 99999,
  81069: 99999,
  81071: 99999,
  81073: 99999,
  81076: 99999,
  81077: 99999,
  81081: 99999,
  81082: 99999,
  81084: 99999,
  81087: 99999,
  81089: 99999,
  81090: 99999,
  81091: 99999,
  81092: 99999,
  81101: 99999,
  81102: 99999,
  81120: 99999,
  81121: 99999,
  81122: 20420,
  81123: 99999,
  81124: 99999,
  81125: 99999,
  81126: 99999,
  81128: 99999,
  81129: 99999,
  81130: 99999,
  81131: 99999,
  81132: 99999,
  81133: 99999,
  81135: 99999,
  81136: 99999,
  81137: 20420,
  81138: 99999,
  81140: 99999,
  81141: 99999,
  81143: 99999,
  81144: 99999,
  81146: 99999,
  81147: 99999,
  81148: 99999,
  81149: 99999,
  81151: 99999,
  81152: 99999,
  81154: 99999,
  81155: 99999,
  81157: 99999,
  81201: 99999,
  81210: 99999,
  81211: 99999,
  81212: 15860,
  81215: 15860,
  81220: 33940,
  81221: 15860,
  81222: 15860,
  81223: 99999,
  81224: 99999,
  81225: 99999,
  81226: 99999,
  81227: 99999,
  81228: 99999,
  81230: 99999,
  81231: 99999,
  81232: 15860,
  81233: 15860,
  81235: 99999,
  81236: 99999,
  81237: 99999,
  81239: 99999,
  81240: 15860,
  81241: 99999,
  81242: 99999,
  81243: 99999,
  81244: 15860,
  81248: 99999,
  81251: 99999,
  81252: 99999,
  81253: 39380,
  81290: 15860,
  81301: 20420,
  81302: 20420,
  81303: 20420,
  81320: 99999,
  81321: 99999,
  81323: 99999,
  81324: 99999,
  81325: 99999,
  81326: 20420,
  81327: 99999,
  81328: 20420,
  81329: 20420,
  81330: 99999,
  81331: 99999,
  81332: 99999,
  81334: 99999,
  81335: 99999,
  81401: 33940,
  81402: 33940,
  81403: 33940,
  81410: 99999,
  81411: 33940,
  81413: 99999,
  81414: 99999,
  81415: 99999,
  81416: 99999,
  81418: 99999,
  81419: 99999,
  81420: 99999,
  81422: 33940,
  81423: 33940,
  81424: 33940,
  81425: 33940,
  81426: 99999,
  81427: 33940,
  81428: 99999,
  81429: 33940,
  81430: 99999,
  81431: 33940,
  81432: 33940,
  81433: 99999,
  81434: 99999,
  81435: 99999,
  81501: 24300,
  81502: 24300,
  81503: 24300,
  81504: 24300,
  81505: 24300,
  81506: 24300,
  81507: 24300,
  81520: 24300,
  81521: 24300,
  81522: 24300,
  81523: 24300,
  81524: 24300,
  81525: 24300,
  81526: 24300,
  81527: 24300,
  81601: 24060,
  81602: 24060,
  81610: 18780,
  81611: 24060,
  81612: 24060,
  81615: 24060,
  81620: 20780,
  81621: 20780,
  81623: 24060,
  81624: 24300,
  81625: 44460,
  81630: 24300,
  81631: 20780,
  81632: 20780,
  81633: 18780,
  81635: 24300,
  81637: 24060,
  81638: 99999,
  81639: 44460,
  81640: 18780,
  81641: 24060,
  81642: 20780,
  81643: 24300,
  81645: 20780,
  81646: 24300,
  81647: 24060,
  81648: 99999,
  81649: 20780,
  81650: 99999,
  81652: 24060,
  81653: 18780,
  81654: 24060,
  81655: 20780,
  81656: 24060,
  81657: 20780,
  81658: 20780,
  82001: 16940,
  82002: 16940,
  82003: 16940,
  82005: 16940,
  82007: 16940,
  82009: 16940,
  82010: 16940,
  82050: 16940,
  82051: 29660,
  82052: 29660,
  82053: 16940,
  82054: 16940,
  82055: 29660,
  82058: 29660,
  82059: 16940,
  82060: 16940,
  82063: 29660,
  82070: 29660,
  82071: 29660,
  82072: 29660,
  82073: 29660,
  82081: 16940,
  82082: 16940,
  82083: 29660,
  82084: 29660,
  82190: 27220,
  82201: 29660,
  82210: 99999,
  82212: 99999,
  82213: 99999,
  82214: 99999,
  82215: 99999,
  82217: 99999,
  82218: 99999,
  82219: 99999,
  82221: 99999,
  82222: 99999,
  82223: 99999,
  82224: 99999,
  82225: 99999,
  82227: 99999,
  82229: 99999,
  82240: 99999,
  82242: 99999,
  82243: 99999,
  82244: 99999,
  82301: 40180,
  82310: 40180,
  82321: 99999,
  82322: 40540,
  82323: 99999,
  82324: 99999,
  82325: 99999,
  82327: 99999,
  82329: 29660,
  82331: 99999,
  82332: 99999,
  82334: 99999,
  82335: 99999,
  82336: 40540,
  82401: 99999,
  82410: 99999,
  82411: 99999,
  82412: 99999,
  82414: 99999,
  82420: 99999,
  82421: 99999,
  82422: 99999,
  82423: 99999,
  82426: 99999,
  82428: 99999,
  82430: 99999,
  82431: 99999,
  82432: 99999,
  82433: 99999,
  82434: 99999,
  82435: 99999,
  82440: 99999,
  82441: 99999,
  82442: 99999,
  82443: 99999,
  82450: 99999,
  82501: 40180,
  82510: 40180,
  82512: 40180,
  82513: 40180,
  82514: 40180,
  82515: 40180,
  82516: 40180,
  82520: 40180,
  82523: 40180,
  82524: 40180,
  82601: 16220,
  82602: 16220,
  82604: 16220,
  82605: 16220,
  82609: 16220,
  82615: 99999,
  82620: 16220,
  82630: 16220,
  82633: 99999,
  82635: 16220,
  82636: 99999,
  82637: 99999,
  82638: 16220,
  82639: 16220,
  82640: 99999,
  82642: 40180,
  82643: 16220,
  82644: 16220,
  82646: 16220,
  82648: 16220,
  82649: 40180,
  82701: 99999,
  82710: 23940,
  82711: 23940,
  82712: 23940,
  82714: 23940,
  82715: 23940,
  82716: 23940,
  82717: 23940,
  82718: 23940,
  82720: 23940,
  82721: 23940,
  82723: 23940,
  82725: 23940,
  82727: 23940,
  82729: 23940,
  82730: 23940,
  82731: 23940,
  82732: 23940,
  82801: 43260,
  82831: 43260,
  82832: 43260,
  82833: 43260,
  82834: 99999,
  82835: 43260,
  82836: 43260,
  82837: 43260,
  82838: 43260,
  82839: 43260,
  82840: 99999,
  82842: 43260,
  82844: 43260,
  82845: 43260,
  82901: 40540,
  82902: 40540,
  82922: 99999,
  82923: 99999,
  82925: 99999,
  82929: 40540,
  82930: 21740,
  82931: 21740,
  82932: 40540,
  82933: 21740,
  82934: 40540,
  82935: 40540,
  82936: 21740,
  82937: 21740,
  82939: 21740,
  82941: 99999,
  82942: 40540,
  82943: 40540,
  82944: 21740,
  82945: 40540,
  83001: 27220,
  83002: 27220,
  83011: 27220,
  83012: 27220,
  83013: 27220,
  83014: 27220,
  83025: 27220,
  83101: 99999,
  83110: 99999,
  83111: 99999,
  83112: 99999,
  83113: 99999,
  83114: 99999,
  83115: 99999,
  83116: 99999,
  83118: 99999,
  83119: 99999,
  83120: 99999,
  83122: 99999,
  83123: 99999,
  83124: 99999,
  83126: 99999,
  83127: 99999,
  83128: 99999,
  83201: 38540,
  83202: 13940,
  83203: 13940,
  83204: 38540,
  83205: 38540,
  83206: 38540,
  83209: 38540,
  83210: 13940,
  83211: 13940,
  83212: 38540,
  83213: 26820,
  83214: 38540,
  83215: 13940,
  83217: 99999,
  83218: 13940,
  83220: 99999,
  83221: 13940,
  83223: 99999,
  83226: 99999,
  83227: 99999,
  83228: 30860,
  83229: 99999,
  83232: 30860,
  83233: 99999,
  83234: 38540,
  83235: 99999,
  83236: 13940,
  83237: 30860,
  83238: 99999,
  83239: 99999,
  83241: 99999,
  83243: 99999,
  83244: 26820,
  83245: 38540,
  83246: 38540,
  83250: 38540,
  83251: 99999,
  83252: 99999,
  83253: 99999,
  83254: 99999,
  83255: 26820,
  83256: 13940,
  83261: 99999,
  83262: 13940,
  83263: 30860,
  83271: 38540,
  83272: 99999,
  83274: 13940,
  83276: 99999,
  83277: 13940,
  83278: 99999,
  83281: 38540,
  83283: 30860,
  83285: 26820,
  83286: 30860,
  83287: 99999,
  83301: 46300,
  83302: 46300,
  83303: 46300,
  83311: 15420,
  83312: 15420,
  83313: 25200,
  83314: 99999,
  83316: 99999,
  83318: 15420,
  83320: 25200,
  83321: 46300,
  83322: 25200,
  83323: 15420,
  83324: 99999,
  83325: 46300,
  83327: 34300,
  83328: 46300,
  83330: 99999,
  83332: 46300,
  83333: 25200,
  83334: 46300,
  83335: 15420,
  83336: 15420,
  83337: 99999,
  83338: 99999,
  83340: 25200,
  83341: 46300,
  83342: 15420,
  83343: 15420,
  83344: 46300,
  83346: 15420,
  83347: 15420,
  83348: 25200,
  83349: 99999,
  83350: 15420,
  83352: 99999,
  83353: 25200,
  83354: 25200,
  83355: 99999,
  83401: 26820,
  83402: 13940,
  83403: 26820,
  83404: 13940,
  83405: 26820,
  83406: 26820,
  83414: 27220,
  83420: 39940,
  83421: 39940,
  83422: 39940,
  83423: 99999,
  83424: 27220,
  83425: 26820,
  83427: 26820,
  83428: 26820,
  83429: 39940,
  83431: 26820,
  83433: 39940,
  83434: 26820,
  83435: 99999,
  83436: 27220,
  83438: 39940,
  83440: 39940,
  83441: 39940,
  83442: 26820,
  83443: 26820,
  83444: 26820,
  83445: 39940,
  83446: 99999,
  83448: 39940,
  83449: 26820,
  83450: 26820,
  83451: 39940,
  83452: 27220,
  83454: 26820,
  83455: 27220,
  83460: 39940,
  83462: 99999,
  83463: 99999,
  83464: 99999,
  83465: 99999,
  83466: 99999,
  83467: 99999,
  83468: 99999,
  83469: 99999,
  83501: 30300,
  83520: 99999,
  83522: 99999,
  83523: 99999,
  83524: 30300,
  83525: 99999,
  83526: 99999,
  83530: 99999,
  83531: 99999,
  83533: 99999,
  83535: 34140,
  83536: 99999,
  83537: 99999,
  83539: 99999,
  83540: 30300,
  83541: 99999,
  83542: 99999,
  83543: 99999,
  83544: 99999,
  83545: 30300,
  83546: 99999,
  83547: 99999,
  83548: 30300,
  83549: 99999,
  83552: 99999,
  83553: 99999,
  83554: 99999,
  83555: 30300,
  83602: 14260,
  83604: 14260,
  83605: 14260,
  83606: 14260,
  83607: 36620,
  83610: 99999,
  83611: 99999,
  83612: 99999,
  83615: 99999,
  83616: 14260,
  83617: 14260,
  83619: 36620,
  83622: 14260,
  83623: 34300,
  83624: 34300,
  83626: 14260,
  83627: 34300,
  83628: 14260,
  83629: 14260,
  83630: 14260,
  83631: 14260,
  83632: 99999,
  83633: 34300,
  83634: 14260,
  83635: 99999,
  83636: 14260,
  83637: 14260,
  83638: 99999,
  83639: 14260,
  83641: 14260,
  83642: 14260,
  83643: 99999,
  83644: 14260,
  83645: 99999,
  83646: 14260,
  83647: 34300,
  83648: 34300,
  83650: 14260,
  83651: 14260,
  83653: 14260,
  83654: 99999,
  83655: 14260,
  83656: 14260,
  83657: 14260,
  83660: 14260,
  83661: 36620,
  83666: 14260,
  83669: 14260,
  83670: 14260,
  83671: 99999,
  83672: 99999,
  83676: 14260,
  83677: 99999,
  83680: 14260,
  83686: 14260,
  83687: 14260,
  83701: 14260,
  83702: 14260,
  83703: 14260,
  83704: 14260,
  83705: 14260,
  83706: 14260,
  83707: 14260,
  83708: 14260,
  83709: 14260,
  83711: 14260,
  83712: 14260,
  83713: 14260,
  83714: 14260,
  83715: 14260,
  83716: 34300,
  83717: 14260,
  83719: 14260,
  83720: 14260,
  83724: 14260,
  83725: 14260,
  83728: 14260,
  83729: 14260,
  83735: 14260,
  83801: 17660,
  83802: 99999,
  83803: 41760,
  83804: 41760,
  83805: 99999,
  83806: 34140,
  83808: 99999,
  83809: 41760,
  83810: 99999,
  83811: 41760,
  83812: 99999,
  83813: 41760,
  83814: 17660,
  83815: 17660,
  83816: 17660,
  83821: 41760,
  83822: 41760,
  83823: 34140,
  83824: 34140,
  83825: 41760,
  83826: 99999,
  83827: 99999,
  83830: 99999,
  83832: 30300,
  83833: 17660,
  83834: 34140,
  83835: 17660,
  83836: 41760,
  83837: 99999,
  83839: 99999,
  83840: 41760,
  83841: 41760,
  83842: 17660,
  83843: 34140,
  83844: 34140,
  83845: 99999,
  83846: 99999,
  83847: 99999,
  83848: 41760,
  83849: 99999,
  83850: 99999,
  83851: 17660,
  83852: 41760,
  83853: 99999,
  83854: 17660,
  83855: 34140,
  83856: 41760,
  83857: 34140,
  83858: 17660,
  83860: 41760,
  83861: 17660,
  83864: 41760,
  83865: 41760,
  83866: 99999,
  83867: 99999,
  83868: 99999,
  83869: 41760,
  83870: 99999,
  83871: 34140,
  83872: 34140,
  83873: 99999,
  83874: 99999,
  83876: 17660,
  83877: 17660,
  84001: 99999,
  84002: 99999,
  84003: 39340,
  84004: 39340,
  84005: 39340,
  84006: 41620,
  84007: 99999,
  84008: 46860,
  84009: 41620,
  84010: 36260,
  84011: 36260,
  84013: 39340,
  84014: 36260,
  84015: 36260,
  84016: 36260,
  84017: 36260,
  84018: 36260,
  84020: 41620,
  84021: 99999,
  84022: 41620,
  84023: 99999,
  84024: 25720,
  84025: 36260,
  84026: 46860,
  84027: 99999,
  84028: 99999,
  84029: 41620,
  84031: 99999,
  84032: 25720,
  84033: 25720,
  84034: 41620,
  84035: 46860,
  84036: 25720,
  84037: 36260,
  84038: 99999,
  84039: 46860,
  84040: 36260,
  84041: 36260,
  84042: 39340,
  84043: 39340,
  84044: 41620,
  84045: 39340,
  84046: 99999,
  84047: 41620,
  84049: 25720,
  84050: 36260,
  84051: 99999,
  84052: 46860,
  84053: 99999,
  84054: 36260,
  84055: 25720,
  84056: 36260,
  84057: 39340,
  84058: 39340,
  84059: 39340,
  84060: 25720,
  84061: 25720,
  84062: 39340,
  84063: 46860,
  84064: 99999,
  84065: 41620,
  84066: 46860,
  84067: 36260,
  84068: 25720,
  84069: 41620,
  84070: 41620,
  84071: 41620,
  84072: 99999,
  84073: 99999,
  84074: 41620,
  84075: 36260,
  84076: 46860,
  84078: 46860,
  84079: 46860,
  84080: 41620,
  84081: 41620,
  84082: 25720,
  84083: 41620,
  84084: 41620,
  84085: 46860,
  84086: 99999,
  84087: 36260,
  84088: 41620,
  84089: 36260,
  84090: 41620,
  84091: 41620,
  84092: 41620,
  84093: 41620,
  84094: 41620,
  84095: 41620,
  84096: 41620,
  84097: 39340,
  84098: 25720,
  84101: 41620,
  84102: 41620,
  84103: 41620,
  84104: 41620,
  84105: 41620,
  84106: 41620,
  84107: 41620,
  84108: 41620,
  84109: 41620,
  84110: 41620,
  84111: 41620,
  84112: 41620,
  84113: 41620,
  84114: 41620,
  84115: 41620,
  84116: 41620,
  84117: 41620,
  84118: 41620,
  84119: 41620,
  84120: 41620,
  84121: 41620,
  84122: 41620,
  84123: 41620,
  84124: 41620,
  84125: 41620,
  84126: 41620,
  84127: 41620,
  84128: 41620,
  84129: 41620,
  84132: 41620,
  84133: 41620,
  84134: 41620,
  84138: 41620,
  84139: 41620,
  84143: 41620,
  84148: 41620,
  84150: 41620,
  84151: 41620,
  84152: 41620,
  84157: 41620,
  84158: 41620,
  84165: 41620,
  84170: 41620,
  84171: 41620,
  84180: 41620,
  84184: 41620,
  84190: 41620,
  84199: 41620,
  84201: 36260,
  84301: 36260,
  84302: 36260,
  84304: 30860,
  84305: 30860,
  84306: 36260,
  84307: 36260,
  84308: 30860,
  84309: 36260,
  84310: 36260,
  84311: 36260,
  84312: 36260,
  84313: 36260,
  84314: 36260,
  84315: 36260,
  84316: 36260,
  84317: 36260,
  84318: 30860,
  84319: 30860,
  84320: 30860,
  84321: 30860,
  84322: 30860,
  84323: 30860,
  84324: 36260,
  84325: 30860,
  84326: 30860,
  84327: 30860,
  84328: 30860,
  84329: 36260,
  84330: 36260,
  84331: 36260,
  84332: 30860,
  84333: 30860,
  84334: 36260,
  84335: 30860,
  84336: 36260,
  84337: 36260,
  84338: 30860,
  84339: 30860,
  84340: 36260,
  84341: 30860,
  84401: 36260,
  84402: 36260,
  84403: 36260,
  84404: 36260,
  84405: 36260,
  84407: 36260,
  84408: 36260,
  84409: 36260,
  84412: 36260,
  84414: 36260,
  84415: 36260,
  84501: 39220,
  84510: 99999,
  84511: 99999,
  84512: 99999,
  84513: 99999,
  84515: 99999,
  84516: 99999,
  84518: 99999,
  84520: 39220,
  84521: 99999,
  84522: 99999,
  84523: 99999,
  84525: 99999,
  84526: 39220,
  84528: 99999,
  84529: 39220,
  84530: 99999,
  84531: 99999,
  84532: 99999,
  84533: 99999,
  84534: 99999,
  84535: 99999,
  84536: 99999,
  84537: 99999,
  84539: 39220,
  84540: 99999,
  84542: 39220,
  84601: 39340,
  84602: 39340,
  84603: 39340,
  84604: 25720,
  84605: 39340,
  84606: 39340,
  84620: 99999,
  84621: 99999,
  84622: 99999,
  84623: 99999,
  84624: 99999,
  84626: 39340,
  84627: 99999,
  84628: 39340,
  84629: 39340,
  84630: 99999,
  84631: 99999,
  84632: 99999,
  84633: 39340,
  84634: 99999,
  84635: 99999,
  84636: 99999,
  84637: 99999,
  84638: 99999,
  84639: 39340,
  84640: 99999,
  84642: 99999,
  84643: 99999,
  84644: 99999,
  84645: 39340,
  84646: 99999,
  84647: 99999,
  84648: 39340,
  84649: 99999,
  84651: 39340,
  84652: 99999,
  84653: 39340,
  84654: 99999,
  84655: 39340,
  84656: 99999,
  84657: 99999,
  84660: 39340,
  84662: 99999,
  84663: 39340,
  84664: 39340,
  84665: 99999,
  84667: 99999,
  84701: 99999,
  84710: 99999,
  84711: 99999,
  84712: 99999,
  84713: 99999,
  84714: 16260,
  84715: 99999,
  84716: 99999,
  84718: 99999,
  84719: 16260,
  84720: 41100,
  84721: 16260,
  84722: 16260,
  84723: 99999,
  84724: 99999,
  84725: 41100,
  84726: 99999,
  84728: 99999,
  84729: 99999,
  84730: 99999,
  84731: 99999,
  84732: 99999,
  84733: 41100,
  84734: 99999,
  84735: 99999,
  84736: 99999,
  84737: 41100,
  84738: 41100,
  84739: 99999,
  84740: 99999,
  84741: 99999,
  84742: 16260,
  84743: 99999,
  84744: 99999,
  84745: 41100,
  84746: 41100,
  84747: 99999,
  84749: 99999,
  84750: 99999,
  84751: 99999,
  84752: 99999,
  84753: 16260,
  84754: 99999,
  84755: 99999,
  84756: 16260,
  84757: 41100,
  84758: 99999,
  84759: 99999,
  84760: 16260,
  84761: 16260,
  84762: 99999,
  84763: 41100,
  84764: 99999,
  84765: 41100,
  84766: 99999,
  84767: 41100,
  84770: 41100,
  84771: 41100,
  84772: 16260,
  84773: 99999,
  84774: 41100,
  84775: 99999,
  84776: 99999,
  84779: 41100,
  84780: 41100,
  84781: 41100,
  84782: 41100,
  84783: 41100,
  84784: 41100,
  84790: 41100,
  84791: 41100,
  85001: 38060,
  85002: 38060,
  85003: 38060,
  85004: 38060,
  85005: 38060,
  85006: 38060,
  85007: 38060,
  85008: 38060,
  85009: 38060,
  85010: 38060,
  85011: 38060,
  85012: 38060,
  85013: 38060,
  85014: 38060,
  85015: 38060,
  85016: 38060,
  85017: 38060,
  85018: 38060,
  85019: 38060,
  85020: 38060,
  85021: 38060,
  85022: 38060,
  85023: 38060,
  85024: 38060,
  85026: 38060,
  85027: 38060,
  85028: 38060,
  85029: 38060,
  85030: 38060,
  85031: 38060,
  85032: 38060,
  85033: 38060,
  85034: 38060,
  85035: 38060,
  85036: 38060,
  85037: 38060,
  85040: 38060,
  85041: 38060,
  85042: 38060,
  85043: 38060,
  85044: 38060,
  85045: 38060,
  85046: 38060,
  85048: 38060,
  85050: 38060,
  85051: 38060,
  85053: 38060,
  85054: 38060,
  85060: 38060,
  85061: 38060,
  85063: 38060,
  85064: 38060,
  85066: 38060,
  85067: 38060,
  85068: 38060,
  85069: 38060,
  85070: 38060,
  85071: 38060,
  85074: 38060,
  85076: 38060,
  85078: 38060,
  85079: 38060,
  85080: 38060,
  85082: 38060,
  85083: 38060,
  85085: 38060,
  85086: 38060,
  85087: 38060,
  85117: 38060,
  85118: 38060,
  85119: 38060,
  85120: 38060,
  85121: 38060,
  85122: 38060,
  85123: 38060,
  85127: 38060,
  85128: 38060,
  85130: 38060,
  85131: 38060,
  85132: 38060,
  85135: 37740,
  85137: 38060,
  85138: 38060,
  85139: 38060,
  85140: 38060,
  85141: 38060,
  85142: 38060,
  85143: 38060,
  85145: 38060,
  85147: 38060,
  85172: 38060,
  85173: 38060,
  85178: 38060,
  85191: 38060,
  85192: 38060,
  85193: 38060,
  85194: 38060,
  85201: 38060,
  85202: 38060,
  85203: 38060,
  85204: 38060,
  85205: 38060,
  85206: 38060,
  85207: 38060,
  85208: 38060,
  85209: 38060,
  85210: 38060,
  85211: 38060,
  85212: 38060,
  85213: 38060,
  85214: 38060,
  85215: 38060,
  85216: 38060,
  85224: 38060,
  85225: 38060,
  85226: 38060,
  85233: 38060,
  85234: 38060,
  85236: 38060,
  85244: 38060,
  85246: 38060,
  85248: 38060,
  85249: 38060,
  85250: 38060,
  85251: 38060,
  85252: 38060,
  85253: 38060,
  85254: 38060,
  85255: 38060,
  85256: 38060,
  85257: 38060,
  85258: 38060,
  85259: 38060,
  85260: 38060,
  85261: 38060,
  85262: 38060,
  85263: 38060,
  85264: 38060,
  85266: 38060,
  85267: 38060,
  85268: 38060,
  85269: 38060,
  85271: 38060,
  85274: 38060,
  85275: 38060,
  85277: 38060,
  85280: 38060,
  85281: 38060,
  85282: 38060,
  85283: 38060,
  85284: 38060,
  85285: 38060,
  85286: 38060,
  85287: 38060,
  85295: 38060,
  85296: 38060,
  85297: 38060,
  85298: 38060,
  85299: 38060,
  85301: 38060,
  85302: 38060,
  85303: 38060,
  85304: 38060,
  85305: 38060,
  85306: 38060,
  85307: 38060,
  85308: 38060,
  85309: 38060,
  85310: 38060,
  85311: 38060,
  85312: 38060,
  85318: 38060,
  85320: 38060,
  85321: 46060,
  85322: 38060,
  85323: 38060,
  85324: 39150,
  85325: 99999,
  85326: 38060,
  85327: 38060,
  85328: 99999,
  85329: 38060,
  85331: 38060,
  85332: 39150,
  85333: 49740,
  85334: 99999,
  85335: 38060,
  85336: 49740,
  85337: 38060,
  85338: 38060,
  85339: 38060,
  85340: 38060,
  85341: 46060,
  85342: 39150,
  85343: 38060,
  85344: 99999,
  85345: 38060,
  85346: 99999,
  85347: 49740,
  85348: 99999,
  85349: 49740,
  85350: 49740,
  85351: 38060,
  85352: 49740,
  85353: 38060,
  85354: 38060,
  85355: 38060,
  85356: 49740,
  85357: 99999,
  85359: 99999,
  85360: 29420,
  85361: 38060,
  85362: 39150,
  85363: 38060,
  85364: 49740,
  85365: 49740,
  85366: 49740,
  85367: 49740,
  85372: 38060,
  85373: 38060,
  85374: 38060,
  85375: 38060,
  85376: 38060,
  85377: 38060,
  85378: 38060,
  85379: 38060,
  85380: 38060,
  85381: 38060,
  85382: 38060,
  85383: 38060,
  85385: 38060,
  85387: 38060,
  85388: 38060,
  85390: 39150,
  85392: 38060,
  85395: 38060,
  85396: 38060,
  85501: 37740,
  85530: 40940,
  85531: 40940,
  85532: 37740,
  85533: 99999,
  85534: 99999,
  85535: 40940,
  85536: 40940,
  85539: 38060,
  85540: 99999,
  85541: 37740,
  85542: 37740,
  85543: 40940,
  85544: 37740,
  85545: 38060,
  85546: 40940,
  85547: 37740,
  85548: 40940,
  85550: 40940,
  85551: 40940,
  85552: 40940,
  85553: 37740,
  85554: 37740,
  85601: 46060,
  85602: 43420,
  85603: 43420,
  85605: 43420,
  85606: 43420,
  85607: 43420,
  85608: 43420,
  85609: 43420,
  85610: 43420,
  85611: 35700,
  85613: 43420,
  85614: 46060,
  85615: 43420,
  85616: 43420,
  85617: 43420,
  85618: 38060,
  85619: 46060,
  85620: 43420,
  85621: 35700,
  85622: 46060,
  85623: 38060,
  85624: 35700,
  85625: 43420,
  85626: 43420,
  85627: 43420,
  85628: 35700,
  85629: 46060,
  85630: 43420,
  85631: 38060,
  85632: 43420,
  85633: 46060,
  85634: 46060,
  85635: 43420,
  85636: 43420,
  85637: 35700,
  85638: 43420,
  85639: 46060,
  85640: 35700,
  85641: 46060,
  85643: 43420,
  85645: 35700,
  85646: 35700,
  85648: 35700,
  85650: 43420,
  85652: 46060,
  85653: 38060,
  85654: 46060,
  85658: 38060,
  85701: 46060,
  85702: 46060,
  85703: 46060,
  85704: 46060,
  85705: 46060,
  85706: 46060,
  85707: 46060,
  85708: 46060,
  85709: 46060,
  85710: 46060,
  85711: 46060,
  85712: 46060,
  85713: 46060,
  85714: 46060,
  85715: 46060,
  85716: 46060,
  85717: 46060,
  85718: 46060,
  85719: 46060,
  85721: 46060,
  85723: 46060,
  85724: 46060,
  85725: 46060,
  85726: 46060,
  85728: 46060,
  85730: 46060,
  85731: 46060,
  85732: 46060,
  85733: 46060,
  85734: 46060,
  85735: 46060,
  85736: 46060,
  85737: 46060,
  85738: 46060,
  85739: 38060,
  85740: 46060,
  85741: 46060,
  85742: 46060,
  85743: 46060,
  85744: 46060,
  85745: 46060,
  85746: 46060,
  85747: 46060,
  85748: 46060,
  85749: 46060,
  85750: 46060,
  85751: 46060,
  85752: 46060,
  85754: 46060,
  85755: 46060,
  85756: 46060,
  85757: 46060,
  85901: 99999,
  85902: 43320,
  85911: 43320,
  85920: 99999,
  85922: 99999,
  85923: 43320,
  85924: 99999,
  85925: 99999,
  85926: 43320,
  85927: 99999,
  85928: 43320,
  85929: 43320,
  85930: 99999,
  85931: 22380,
  85932: 99999,
  85933: 43320,
  85934: 43320,
  85935: 43320,
  85936: 99999,
  85937: 99999,
  85938: 99999,
  85939: 43320,
  85940: 99999,
  85941: 43320,
  85942: 43320,
  86001: 22380,
  86002: 22380,
  86003: 22380,
  86004: 22380,
  86005: 22380,
  86011: 22380,
  86015: 22380,
  86016: 22380,
  86017: 22380,
  86018: 22380,
  86020: 22380,
  86021: 29420,
  86022: 22380,
  86023: 22380,
  86024: 22380,
  86025: 43320,
  86028: 99999,
  86029: 43320,
  86030: 43320,
  86031: 43320,
  86032: 43320,
  86033: 43320,
  86034: 43320,
  86035: 22380,
  86036: 22380,
  86038: 22380,
  86039: 43320,
  86040: 22380,
  86042: 43320,
  86043: 43320,
  86044: 22380,
  86045: 22380,
  86046: 39150,
  86047: 22380,
  86052: 22380,
  86053: 22380,
  86054: 43320,
  86301: 39150,
  86302: 39150,
  86303: 39150,
  86304: 39150,
  86305: 39150,
  86312: 39150,
  86313: 39150,
  86314: 39150,
  86315: 39150,
  86320: 39150,
  86321: 39150,
  86322: 39150,
  86323: 39150,
  86324: 39150,
  86325: 39150,
  86326: 39150,
  86327: 39150,
  86329: 39150,
  86331: 39150,
  86332: 39150,
  86333: 39150,
  86334: 39150,
  86335: 39150,
  86336: 22380,
  86337: 39150,
  86338: 39150,
  86339: 22380,
  86340: 39150,
  86342: 39150,
  86343: 39150,
  86351: 39150,
  86401: 29420,
  86402: 29420,
  86403: 29420,
  86404: 29420,
  86405: 29420,
  86406: 29420,
  86409: 29420,
  86411: 29420,
  86412: 29420,
  86413: 29420,
  86426: 29420,
  86427: 29420,
  86429: 29420,
  86430: 29420,
  86431: 29420,
  86432: 29420,
  86433: 29420,
  86434: 29420,
  86435: 22380,
  86436: 29420,
  86437: 29420,
  86438: 29420,
  86439: 29420,
  86440: 29420,
  86441: 29420,
  86442: 29420,
  86443: 29420,
  86444: 29420,
  86445: 29420,
  86446: 29420,
  86502: 99999,
  86503: 99999,
  86504: 99999,
  86505: 99999,
  86506: 99999,
  86507: 99999,
  86508: 99999,
  86510: 43320,
  86511: 99999,
  86512: 99999,
  86514: 99999,
  86515: 99999,
  86520: 43320,
  86535: 99999,
  86538: 99999,
  86540: 99999,
  86544: 99999,
  86545: 99999,
  86547: 99999,
  86556: 99999,
  87001: 10740,
  87002: 10740,
  87004: 10740,
  87005: 24380,
  87006: 10740,
  87007: 24380,
  87008: 10740,
  87009: 10740,
  87010: 42140,
  87011: 99999,
  87012: 21580,
  87013: 21580,
  87014: 24380,
  87015: 42140,
  87016: 10740,
  87017: 21580,
  87018: 10740,
  87020: 24380,
  87021: 24380,
  87022: 10740,
  87023: 10740,
  87024: 10740,
  87025: 10740,
  87026: 10740,
  87027: 10740,
  87028: 99999,
  87029: 21580,
  87031: 10740,
  87032: 10740,
  87034: 24380,
  87035: 10740,
  87036: 10740,
  87037: 22140,
  87038: 24380,
  87040: 24380,
  87041: 10740,
  87042: 10740,
  87043: 10740,
  87044: 10740,
  87045: 23700,
  87046: 21580,
  87047: 42140,
  87048: 10740,
  87049: 24380,
  87051: 24380,
  87052: 10740,
  87053: 10740,
  87056: 42140,
  87059: 10740,
  87060: 10740,
  87061: 10740,
  87062: 99999,
  87063: 10740,
  87064: 21580,
  87068: 10740,
  87070: 10740,
  87072: 10740,
  87083: 10740,
  87101: 10740,
  87102: 10740,
  87103: 10740,
  87104: 10740,
  87105: 10740,
  87106: 10740,
  87107: 10740,
  87108: 10740,
  87109: 10740,
  87110: 10740,
  87111: 10740,
  87112: 10740,
  87113: 10740,
  87114: 10740,
  87115: 10740,
  87116: 10740,
  87117: 10740,
  87119: 10740,
  87120: 10740,
  87121: 10740,
  87122: 10740,
  87123: 10740,
  87124: 10740,
  87125: 10740,
  87131: 10740,
  87144: 10740,
  87151: 10740,
  87153: 10740,
  87154: 10740,
  87174: 10740,
  87176: 10740,
  87181: 10740,
  87184: 10740,
  87185: 10740,
  87187: 10740,
  87190: 10740,
  87191: 10740,
  87192: 10740,
  87193: 10740,
  87194: 10740,
  87195: 10740,
  87196: 10740,
  87197: 10740,
  87198: 10740,
  87199: 10740,
  87301: 23700,
  87302: 23700,
  87305: 23700,
  87310: 23700,
  87311: 23700,
  87312: 23700,
  87313: 23700,
  87315: 24380,
  87316: 23700,
  87317: 23700,
  87319: 23700,
  87320: 23700,
  87321: 23700,
  87322: 23700,
  87323: 23700,
  87325: 23700,
  87326: 23700,
  87327: 23700,
  87328: 23700,
  87347: 23700,
  87357: 24380,
  87364: 22140,
  87365: 23700,
  87401: 22140,
  87402: 22140,
  87410: 22140,
  87412: 21580,
  87413: 22140,
  87415: 22140,
  87416: 22140,
  87417: 22140,
  87418: 22140,
  87419: 22140,
  87420: 22140,
  87421: 22140,
  87455: 22140,
  87461: 22140,
  87499: 22140,
  87501: 42140,
  87502: 42140,
  87504: 42140,
  87505: 42140,
  87506: 31060,
  87507: 42140,
  87508: 42140,
  87510: 21580,
  87511: 21580,
  87512: 45340,
  87513: 45340,
  87514: 45340,
  87515: 21580,
  87516: 21580,
  87517: 45340,
  87518: 21580,
  87519: 45340,
  87520: 21580,
  87521: 45340,
  87522: 21580,
  87523: 21580,
  87524: 45340,
  87525: 45340,
  87527: 21580,
  87528: 21580,
  87529: 45340,
  87530: 21580,
  87531: 21580,
  87532: 42140,
  87535: 42140,
  87537: 21580,
  87538: 29780,
  87539: 21580,
  87540: 42140,
  87543: 45340,
  87544: 31060,
  87547: 31060,
  87548: 21580,
  87549: 45340,
  87551: 21580,
  87552: 42140,
  87553: 45340,
  87554: 21580,
  87556: 45340,
  87557: 45340,
  87558: 45340,
  87560: 29780,
  87562: 29780,
  87564: 45340,
  87565: 29780,
  87566: 21580,
  87567: 42140,
  87569: 29780,
  87571: 45340,
  87573: 29780,
  87574: 42140,
  87575: 21580,
  87576: 45340,
  87577: 45340,
  87578: 21580,
  87579: 45340,
  87580: 45340,
  87581: 21580,
  87582: 21580,
  87583: 29780,
  87592: 42140,
  87594: 42140,
  87654: 99999,
  87701: 29780,
  87710: 99999,
  87711: 99999,
  87712: 29780,
  87713: 29780,
  87714: 99999,
  87715: 29780,
  87718: 99999,
  87722: 29780,
  87723: 29780,
  87724: 99999,
  87728: 99999,
  87729: 99999,
  87730: 99999,
  87731: 29780,
  87732: 29780,
  87733: 99999,
  87734: 29780,
  87735: 29780,
  87736: 29780,
  87740: 99999,
  87742: 29780,
  87743: 99999,
  87745: 29780,
  87746: 99999,
  87747: 99999,
  87749: 99999,
  87750: 29780,
  87752: 29780,
  87753: 29780,
  87801: 99999,
  87820: 99999,
  87821: 43500,
  87823: 99999,
  87824: 99999,
  87825: 99999,
  87827: 99999,
  87828: 99999,
  87829: 99999,
  87830: 99999,
  87831: 99999,
  87832: 99999,
  87901: 99999,
  87930: 99999,
  87931: 99999,
  87933: 99999,
  87935: 99999,
  87936: 29740,
  87937: 29740,
  87939: 99999,
  87940: 29740,
  87941: 29740,
  87942: 99999,
  87943: 99999,
  88001: 29740,
  88002: 29740,
  88003: 29740,
  88004: 29740,
  88005: 29740,
  88007: 29740,
  88008: 29740,
  88009: 99999,
  88011: 29740,
  88012: 29740,
  88013: 29740,
  88020: 99999,
  88021: 29740,
  88022: 43500,
  88023: 43500,
  88024: 29740,
  88025: 43500,
  88026: 43500,
  88027: 29740,
  88028: 43500,
  88029: 19700,
  88030: 19700,
  88031: 19700,
  88032: 29740,
  88033: 29740,
  88034: 43500,
  88036: 43500,
  88038: 43500,
  88039: 99999,
  88040: 43500,
  88041: 43500,
  88042: 99999,
  88043: 43500,
  88044: 29740,
  88045: 99999,
  88046: 29740,
  88047: 29740,
  88048: 29740,
  88049: 43500,
  88051: 43500,
  88052: 29740,
  88053: 43500,
  88054: 29740,
  88055: 43500,
  88056: 99999,
  88058: 29740,
  88061: 43500,
  88062: 43500,
  88063: 29740,
  88065: 43500,
  88072: 29740,
  88081: 10460,
  88101: 38780,
  88102: 17580,
  88103: 17580,
  88112: 99999,
  88113: 38780,
  88114: 38780,
  88115: 38780,
  88116: 40740,
  88118: 17580,
  88119: 99999,
  88120: 99999,
  88121: 99999,
  88122: 38780,
  88123: 38780,
  88124: 99999,
  88125: 38780,
  88126: 38780,
  88130: 17580,
  88132: 38780,
  88133: 17580,
  88134: 99999,
  88135: 17580,
  88136: 99999,
  88201: 40740,
  88202: 40740,
  88203: 40740,
  88210: 16100,
  88213: 26020,
  88220: 16100,
  88221: 16100,
  88230: 40740,
  88231: 26020,
  88232: 40740,
  88240: 26020,
  88241: 26020,
  88242: 26020,
  88244: 26020,
  88250: 16100,
  88252: 26020,
  88253: 16100,
  88254: 16100,
  88255: 16100,
  88256: 16100,
  88260: 26020,
  88262: 26020,
  88263: 16100,
  88264: 26020,
  88265: 26020,
  88267: 26020,
  88268: 16100,
  88301: 40760,
  88310: 10460,
  88311: 10460,
  88312: 40760,
  88314: 10460,
  88316: 40760,
  88317: 10460,
  88318: 40760,
  88321: 10740,
  88323: 40760,
  88324: 40760,
  88325: 10460,
  88330: 10460,
  88336: 40760,
  88337: 10460,
  88338: 40760,
  88339: 40740,
  88340: 10460,
  88341: 40760,
  88342: 10460,
  88343: 40760,
  88344: 40740,
  88345: 40760,
  88346: 40760,
  88347: 10460,
  88348: 40760,
  88349: 10460,
  88350: 10460,
  88351: 40760,
  88352: 10460,
  88353: 99999,
  88354: 10460,
  88355: 40760,
  88401: 99999,
  88410: 99999,
  88411: 99999,
  88414: 99999,
  88415: 99999,
  88416: 29780,
  88417: 99999,
  88418: 99999,
  88419: 99999,
  88421: 29780,
  88422: 99999,
  88424: 99999,
  88426: 99999,
  88427: 17580,
  88430: 99999,
  88431: 99999,
  88433: 99999,
  88434: 99999,
  88435: 99999,
  88436: 99999,
  88439: 29780,
  88888: 47900,
  89001: 99999,
  89002: 29820,
  89003: 37220,
  89004: 29820,
  89005: 29820,
  89006: 29820,
  89007: 29820,
  89008: 99999,
  89009: 29820,
  89010: 99999,
  89011: 29820,
  89012: 29820,
  89013: 99999,
  89014: 29820,
  89015: 29820,
  89016: 29820,
  89017: 99999,
  89018: 29820,
  89019: 29820,
  89020: 37220,
  89021: 29820,
  89022: 37220,
  89023: 37220,
  89024: 29820,
  89025: 29820,
  89027: 29820,
  89028: 29820,
  89029: 29820,
  89030: 29820,
  89031: 29820,
  89032: 29820,
  89033: 29820,
  89034: 29820,
  89036: 29820,
  89037: 29820,
  89039: 29820,
  89040: 29820,
  89041: 37220,
  89042: 99999,
  89043: 99999,
  89044: 29820,
  89045: 37220,
  89046: 29820,
  89047: 99999,
  89048: 37220,
  89049: 37220,
  89052: 29820,
  89053: 29820,
  89054: 29820,
  89060: 37220,
  89061: 37220,
  89074: 29820,
  89077: 29820,
  89081: 29820,
  89084: 29820,
  89085: 29820,
  89086: 29820,
  89087: 29820,
  89101: 29820,
  89102: 29820,
  89103: 29820,
  89104: 29820,
  89105: 29820,
  89106: 29820,
  89107: 29820,
  89108: 29820,
  89109: 29820,
  89110: 29820,
  89111: 29820,
  89112: 29820,
  89113: 29820,
  89114: 29820,
  89115: 29820,
  89116: 29820,
  89117: 29820,
  89118: 29820,
  89119: 29820,
  89120: 29820,
  89121: 29820,
  89122: 29820,
  89123: 29820,
  89124: 29820,
  89125: 29820,
  89126: 29820,
  89127: 29820,
  89128: 29820,
  89129: 29820,
  89130: 29820,
  89131: 29820,
  89132: 29820,
  89133: 29820,
  89134: 29820,
  89135: 29820,
  89136: 29820,
  89137: 29820,
  89138: 29820,
  89139: 29820,
  89140: 29820,
  89141: 29820,
  89142: 29820,
  89143: 29820,
  89144: 29820,
  89145: 29820,
  89146: 29820,
  89147: 29820,
  89148: 29820,
  89149: 29820,
  89150: 29820,
  89151: 29820,
  89152: 29820,
  89153: 29820,
  89154: 29820,
  89155: 29820,
  89156: 29820,
  89157: 29820,
  89158: 29820,
  89160: 29820,
  89161: 29820,
  89162: 29820,
  89165: 29820,
  89166: 29820,
  89169: 29820,
  89170: 29820,
  89173: 29820,
  89178: 29820,
  89179: 29820,
  89180: 29820,
  89183: 29820,
  89191: 29820,
  89193: 29820,
  89195: 29820,
  89199: 29820,
  89301: 99999,
  89310: 99999,
  89311: 99999,
  89314: 99999,
  89316: 21220,
  89317: 99999,
  89318: 99999,
  89319: 99999,
  89402: 39900,
  89403: 22280,
  89404: 49080,
  89406: 22280,
  89407: 21980,
  89408: 21980,
  89409: 37220,
  89410: 23820,
  89411: 23820,
  89412: 39900,
  89413: 23820,
  89414: 49080,
  89415: 99999,
  89418: 99999,
  89419: 99999,
  89420: 99999,
  89421: 49080,
  89422: 99999,
  89423: 16180,
  89424: 39900,
  89425: 49080,
  89426: 49080,
  89427: 99999,
  89428: 22280,
  89429: 22280,
  89430: 22280,
  89431: 39900,
  89432: 39900,
  89433: 39900,
  89434: 39900,
  89435: 39900,
  89436: 39900,
  89437: 39900,
  89438: 49080,
  89439: 39900,
  89440: 39900,
  89441: 39900,
  89442: 39900,
  89444: 22280,
  89445: 99999,
  89446: 49080,
  89447: 22280,
  89448: 23820,
  89449: 23820,
  89450: 39900,
  89451: 39900,
  89460: 23820,
  89496: 21980,
  89501: 39900,
  89502: 39900,
  89503: 39900,
  89504: 39900,
  89505: 39900,
  89506: 39900,
  89507: 39900,
  89508: 39900,
  89509: 39900,
  89510: 39900,
  89511: 39900,
  89512: 39900,
  89513: 39900,
  89515: 39900,
  89519: 39900,
  89521: 39900,
  89523: 39900,
  89533: 39900,
  89557: 39900,
  89570: 39900,
  89595: 39900,
  89599: 39900,
  89701: 23820,
  89702: 16180,
  89703: 16180,
  89704: 39900,
  89705: 23820,
  89706: 16180,
  89711: 16180,
  89712: 16180,
  89713: 16180,
  89714: 16180,
  89721: 16180,
  89801: 21220,
  89803: 21220,
  89815: 21220,
  89820: 21220,
  89821: 21220,
  89822: 21220,
  89823: 21220,
  89825: 21220,
  89826: 21220,
  89828: 21220,
  89830: 21220,
  89831: 21220,
  89832: 21220,
  89833: 21220,
  89834: 21220,
  89835: 21220,
  89883: 21220,
  90001: 31080,
  90002: 31080,
  90003: 31080,
  90004: 31080,
  90005: 31080,
  90006: 31080,
  90007: 31080,
  90008: 31080,
  90009: 31080,
  90010: 31080,
  90011: 31080,
  90012: 31080,
  90013: 31080,
  90014: 31080,
  90015: 31080,
  90016: 31080,
  90017: 31080,
  90018: 31080,
  90019: 31080,
  90020: 31080,
  90021: 31080,
  90022: 31080,
  90023: 31080,
  90024: 31080,
  90025: 31080,
  90026: 31080,
  90027: 31080,
  90028: 31080,
  90029: 31080,
  90030: 31080,
  90031: 31080,
  90032: 31080,
  90033: 31080,
  90034: 31080,
  90035: 31080,
  90036: 31080,
  90037: 31080,
  90038: 31080,
  90039: 31080,
  90040: 31080,
  90041: 31080,
  90042: 31080,
  90043: 31080,
  90044: 31080,
  90045: 31080,
  90046: 31080,
  90047: 31080,
  90048: 31080,
  90049: 31080,
  90050: 31080,
  90051: 31080,
  90052: 31080,
  90053: 31080,
  90054: 31080,
  90055: 31080,
  90056: 31080,
  90057: 31080,
  90058: 31080,
  90059: 31080,
  90060: 31080,
  90061: 31080,
  90062: 31080,
  90063: 31080,
  90064: 31080,
  90065: 31080,
  90066: 31080,
  90067: 31080,
  90068: 31080,
  90069: 31080,
  90070: 31080,
  90071: 31080,
  90072: 31080,
  90073: 31080,
  90074: 31080,
  90075: 31080,
  90076: 31080,
  90077: 31080,
  90078: 31080,
  90079: 31080,
  90081: 31080,
  90082: 31080,
  90083: 31080,
  90084: 31080,
  90086: 31080,
  90087: 31080,
  90089: 31080,
  90091: 31080,
  90093: 31080,
  90094: 31080,
  90095: 31080,
  90099: 31080,
  90201: 31080,
  90202: 31080,
  90209: 31080,
  90210: 31080,
  90211: 31080,
  90212: 31080,
  90213: 31080,
  90220: 31080,
  90221: 31080,
  90222: 31080,
  90223: 31080,
  90224: 31080,
  90230: 31080,
  90231: 31080,
  90232: 31080,
  90239: 31080,
  90240: 31080,
  90241: 31080,
  90242: 31080,
  90245: 31080,
  90247: 31080,
  90248: 31080,
  90249: 31080,
  90250: 31080,
  90251: 31080,
  90254: 31080,
  90255: 31080,
  90260: 31080,
  90262: 31080,
  90263: 31080,
  90264: 31080,
  90265: 37100,
  90266: 31080,
  90267: 31080,
  90270: 31080,
  90272: 31080,
  90274: 31080,
  90275: 31080,
  90277: 31080,
  90278: 31080,
  90280: 31080,
  90290: 31080,
  90291: 31080,
  90292: 31080,
  90293: 31080,
  90294: 31080,
  90295: 31080,
  90296: 31080,
  90301: 31080,
  90302: 31080,
  90303: 31080,
  90304: 31080,
  90305: 31080,
  90306: 31080,
  90307: 31080,
  90308: 31080,
  90309: 31080,
  90310: 31080,
  90401: 31080,
  90402: 31080,
  90403: 31080,
  90404: 31080,
  90405: 31080,
  90406: 31080,
  90408: 31080,
  90409: 31080,
  90501: 31080,
  90502: 31080,
  90503: 31080,
  90504: 31080,
  90505: 31080,
  90506: 31080,
  90507: 31080,
  90508: 31080,
  90509: 31080,
  90510: 31080,
  90601: 31080,
  90602: 31080,
  90603: 31080,
  90604: 31080,
  90605: 31080,
  90606: 31080,
  90607: 31080,
  90608: 31080,
  90609: 31080,
  90610: 31080,
  90620: 31080,
  90621: 31080,
  90622: 31080,
  90623: 31080,
  90624: 31080,
  90630: 31080,
  90631: 31080,
  90632: 31080,
  90633: 31080,
  90637: 31080,
  90638: 31080,
  90639: 31080,
  90640: 31080,
  90650: 31080,
  90651: 31080,
  90652: 31080,
  90660: 31080,
  90661: 31080,
  90662: 31080,
  90670: 31080,
  90671: 31080,
  90680: 31080,
  90701: 31080,
  90702: 31080,
  90703: 31080,
  90704: 31080,
  90706: 31080,
  90707: 31080,
  90710: 31080,
  90711: 31080,
  90712: 31080,
  90713: 31080,
  90714: 31080,
  90715: 31080,
  90716: 31080,
  90717: 31080,
  90720: 31080,
  90721: 31080,
  90723: 31080,
  90731: 31080,
  90732: 31080,
  90733: 31080,
  90734: 31080,
  90740: 31080,
  90742: 31080,
  90743: 31080,
  90744: 31080,
  90745: 31080,
  90746: 31080,
  90747: 31080,
  90748: 31080,
  90749: 31080,
  90755: 31080,
  90801: 31080,
  90802: 31080,
  90803: 31080,
  90804: 31080,
  90805: 31080,
  90806: 31080,
  90807: 31080,
  90808: 31080,
  90809: 31080,
  90810: 31080,
  90813: 31080,
  90814: 31080,
  90815: 31080,
  90822: 31080,
  90831: 31080,
  90832: 31080,
  90833: 31080,
  90840: 31080,
  90844: 31080,
  90846: 31080,
  90853: 31080,
  90895: 31080,
  91001: 31080,
  91003: 31080,
  91006: 31080,
  91007: 31080,
  91008: 31080,
  91009: 31080,
  91010: 31080,
  91011: 31080,
  91012: 31080,
  91016: 31080,
  91017: 31080,
  91020: 31080,
  91021: 31080,
  91023: 31080,
  91024: 31080,
  91025: 31080,
  91030: 31080,
  91031: 31080,
  91040: 31080,
  91041: 31080,
  91042: 31080,
  91043: 31080,
  91046: 31080,
  91066: 31080,
  91077: 31080,
  91101: 31080,
  91102: 31080,
  91103: 31080,
  91104: 31080,
  91105: 31080,
  91106: 31080,
  91107: 31080,
  91108: 31080,
  91109: 31080,
  91114: 31080,
  91115: 31080,
  91116: 31080,
  91117: 31080,
  91118: 31080,
  91121: 31080,
  91123: 31080,
  91124: 31080,
  91125: 31080,
  91126: 31080,
  91129: 31080,
  91182: 31080,
  91184: 31080,
  91185: 31080,
  91188: 31080,
  91189: 31080,
  91199: 31080,
  91201: 31080,
  91202: 31080,
  91203: 31080,
  91204: 31080,
  91205: 31080,
  91206: 31080,
  91207: 31080,
  91208: 31080,
  91209: 31080,
  91210: 31080,
  91214: 31080,
  91221: 31080,
  91222: 31080,
  91224: 31080,
  91225: 31080,
  91226: 31080,
  91301: 31080,
  91302: 31080,
  91303: 31080,
  91304: 31080,
  91305: 31080,
  91306: 31080,
  91307: 31080,
  91308: 31080,
  91309: 31080,
  91310: 31080,
  91311: 37100,
  91313: 31080,
  91316: 31080,
  91319: 37100,
  91320: 37100,
  91321: 31080,
  91322: 31080,
  91324: 31080,
  91325: 31080,
  91326: 31080,
  91327: 31080,
  91328: 31080,
  91329: 31080,
  91330: 31080,
  91331: 31080,
  91333: 31080,
  91334: 31080,
  91335: 31080,
  91337: 31080,
  91340: 31080,
  91341: 31080,
  91342: 31080,
  91343: 31080,
  91344: 31080,
  91345: 31080,
  91346: 31080,
  91350: 31080,
  91351: 31080,
  91352: 31080,
  91353: 31080,
  91354: 31080,
  91355: 31080,
  91356: 31080,
  91357: 31080,
  91358: 37100,
  91359: 37100,
  91360: 37100,
  91361: 37100,
  91362: 31080,
  91364: 31080,
  91365: 31080,
  91367: 31080,
  91371: 31080,
  91372: 31080,
  91376: 31080,
  91377: 37100,
  91380: 31080,
  91381: 31080,
  91382: 31080,
  91383: 31080,
  91384: 31080,
  91385: 31080,
  91386: 31080,
  91387: 31080,
  91390: 31080,
  91392: 31080,
  91393: 31080,
  91394: 31080,
  91395: 31080,
  91396: 31080,
  91401: 31080,
  91402: 31080,
  91403: 31080,
  91404: 31080,
  91405: 31080,
  91406: 31080,
  91407: 31080,
  91408: 31080,
  91409: 31080,
  91411: 31080,
  91412: 31080,
  91413: 31080,
  91416: 31080,
  91423: 31080,
  91426: 31080,
  91436: 31080,
  91470: 31080,
  91482: 31080,
  91495: 31080,
  91496: 31080,
  91499: 31080,
  91501: 31080,
  91502: 31080,
  91503: 31080,
  91504: 31080,
  91505: 31080,
  91506: 31080,
  91507: 31080,
  91508: 31080,
  91510: 31080,
  91521: 31080,
  91522: 31080,
  91523: 31080,
  91601: 31080,
  91602: 31080,
  91603: 31080,
  91604: 31080,
  91605: 31080,
  91606: 31080,
  91607: 31080,
  91608: 31080,
  91609: 31080,
  91610: 31080,
  91611: 31080,
  91612: 31080,
  91614: 31080,
  91615: 31080,
  91617: 31080,
  91701: 40140,
  91702: 31080,
  91706: 31080,
  91708: 40140,
  91709: 31080,
  91710: 40140,
  91711: 31080,
  91714: 31080,
  91715: 31080,
  91716: 31080,
  91722: 31080,
  91723: 31080,
  91724: 31080,
  91729: 40140,
  91730: 40140,
  91731: 31080,
  91732: 31080,
  91733: 31080,
  91734: 31080,
  91737: 40140,
  91739: 40140,
  91740: 31080,
  91741: 31080,
  91743: 40140,
  91744: 31080,
  91745: 31080,
  91746: 31080,
  91747: 31080,
  91748: 31080,
  91750: 31080,
  91752: 40140,
  91754: 31080,
  91755: 31080,
  91756: 31080,
  91759: 40140,
  91761: 40140,
  91762: 40140,
  91763: 40140,
  91764: 40140,
  91765: 31080,
  91766: 40140,
  91767: 31080,
  91768: 31080,
  91769: 31080,
  91770: 31080,
  91771: 31080,
  91772: 31080,
  91773: 31080,
  91775: 31080,
  91776: 31080,
  91778: 31080,
  91780: 31080,
  91784: 40140,
  91785: 40140,
  91786: 31080,
  91788: 31080,
  91789: 31080,
  91790: 31080,
  91791: 31080,
  91792: 31080,
  91793: 31080,
  91801: 31080,
  91802: 31080,
  91803: 31080,
  91804: 31080,
  91896: 31080,
  91899: 31080,
  91901: 41740,
  91902: 41740,
  91903: 41740,
  91905: 41740,
  91906: 41740,
  91908: 41740,
  91910: 41740,
  91911: 41740,
  91912: 41740,
  91913: 41740,
  91914: 41740,
  91915: 41740,
  91916: 41740,
  91917: 41740,
  91921: 41740,
  91931: 41740,
  91932: 41740,
  91933: 41740,
  91934: 41740,
  91935: 41740,
  91941: 41740,
  91942: 41740,
  91943: 41740,
  91944: 41740,
  91945: 41740,
  91946: 41740,
  91948: 41740,
  91950: 41740,
  91951: 41740,
  91962: 41740,
  91963: 41740,
  91976: 41740,
  91977: 41740,
  91978: 41740,
  91979: 41740,
  91980: 41740,
  92003: 41740,
  92004: 41740,
  92007: 41740,
  92008: 41740,
  92009: 41740,
  92010: 41740,
  92011: 41740,
  92013: 41740,
  92014: 41740,
  92018: 41740,
  92019: 41740,
  92020: 41740,
  92021: 41740,
  92022: 41740,
  92023: 41740,
  92024: 41740,
  92025: 41740,
  92026: 41740,
  92027: 41740,
  92028: 41740,
  92029: 41740,
  92030: 41740,
  92033: 41740,
  92036: 41740,
  92037: 41740,
  92038: 41740,
  92039: 41740,
  92040: 41740,
  92046: 41740,
  92049: 41740,
  92051: 41740,
  92052: 41740,
  92054: 41740,
  92055: 41740,
  92056: 41740,
  92057: 41740,
  92058: 41740,
  92059: 41740,
  92060: 41740,
  92061: 41740,
  92064: 41740,
  92065: 41740,
  92066: 41740,
  92067: 41740,
  92068: 41740,
  92069: 41740,
  92070: 41740,
  92071: 41740,
  92072: 41740,
  92074: 41740,
  92075: 41740,
  92078: 41740,
  92079: 41740,
  92081: 41740,
  92082: 41740,
  92083: 41740,
  92084: 41740,
  92085: 41740,
  92086: 41740,
  92088: 41740,
  92091: 41740,
  92092: 41740,
  92093: 41740,
  92096: 41740,
  92101: 41740,
  92102: 41740,
  92103: 41740,
  92104: 41740,
  92105: 41740,
  92106: 41740,
  92107: 41740,
  92108: 41740,
  92109: 41740,
  92110: 41740,
  92111: 41740,
  92112: 41740,
  92113: 41740,
  92114: 41740,
  92115: 41740,
  92116: 41740,
  92117: 41740,
  92118: 41740,
  92119: 41740,
  92120: 41740,
  92121: 41740,
  92122: 41740,
  92123: 41740,
  92124: 41740,
  92126: 41740,
  92127: 41740,
  92128: 41740,
  92129: 41740,
  92130: 41740,
  92131: 41740,
  92132: 41740,
  92134: 41740,
  92135: 41740,
  92136: 41740,
  92137: 41740,
  92138: 41740,
  92139: 41740,
  92140: 41740,
  92142: 41740,
  92143: 41740,
  92145: 41740,
  92147: 41740,
  92149: 41740,
  92150: 41740,
  92152: 41740,
  92153: 41740,
  92154: 41740,
  92155: 41740,
  92158: 41740,
  92159: 41740,
  92160: 41740,
  92161: 41740,
  92163: 41740,
  92165: 41740,
  92166: 41740,
  92167: 41740,
  92168: 41740,
  92169: 41740,
  92170: 41740,
  92171: 41740,
  92172: 41740,
  92173: 41740,
  92174: 41740,
  92175: 41740,
  92176: 41740,
  92177: 41740,
  92178: 41740,
  92179: 41740,
  92182: 41740,
  92191: 41740,
  92192: 41740,
  92193: 41740,
  92195: 41740,
  92196: 41740,
  92197: 41740,
  92198: 41740,
  92199: 41740,
  92201: 40140,
  92202: 40140,
  92203: 40140,
  92210: 40140,
  92211: 40140,
  92220: 40140,
  92222: 20940,
  92223: 40140,
  92225: 40140,
  92226: 40140,
  92227: 20940,
  92230: 40140,
  92231: 20940,
  92232: 20940,
  92233: 20940,
  92234: 40140,
  92235: 40140,
  92236: 40140,
  92239: 40140,
  92240: 40140,
  92241: 40140,
  92242: 40140,
  92243: 20940,
  92244: 20940,
  92247: 40140,
  92248: 40140,
  92249: 20940,
  92250: 20940,
  92251: 20940,
  92252: 40140,
  92253: 40140,
  92254: 40140,
  92255: 40140,
  92256: 40140,
  92257: 20940,
  92258: 40140,
  92259: 20940,
  92260: 40140,
  92261: 40140,
  92262: 40140,
  92263: 40140,
  92264: 40140,
  92266: 20940,
  92267: 40140,
  92268: 40140,
  92270: 40140,
  92273: 20940,
  92274: 40140,
  92275: 20940,
  92276: 40140,
  92277: 40140,
  92278: 40140,
  92280: 40140,
  92281: 20940,
  92282: 40140,
  92283: 20940,
  92284: 40140,
  92285: 40140,
  92286: 40140,
  92301: 31080,
  92304: 40140,
  92305: 40140,
  92307: 40140,
  92308: 40140,
  92309: 40140,
  92310: 40140,
  92311: 40140,
  92312: 40140,
  92313: 40140,
  92314: 40140,
  92315: 40140,
  92316: 40140,
  92317: 40140,
  92318: 40140,
  92320: 40140,
  92321: 40140,
  92322: 40140,
  92323: 40140,
  92324: 40140,
  92325: 40140,
  92327: 40140,
  92328: 99999,
  92329: 40140,
  92331: 40140,
  92332: 40140,
  92333: 40140,
  92334: 40140,
  92335: 40140,
  92336: 40140,
  92337: 40140,
  92338: 40140,
  92339: 40140,
  92340: 40140,
  92341: 40140,
  92342: 40140,
  92344: 40140,
  92345: 40140,
  92346: 40140,
  92347: 40140,
  92350: 40140,
  92352: 40140,
  92354: 40140,
  92356: 40140,
  92357: 40140,
  92358: 40140,
  92359: 40140,
  92363: 40140,
  92364: 40140,
  92365: 40140,
  92366: 40140,
  92368: 40140,
  92369: 40140,
  92371: 40140,
  92372: 40140,
  92373: 40140,
  92374: 40140,
  92375: 40140,
  92376: 40140,
  92377: 40140,
  92378: 40140,
  92382: 40140,
  92384: 99999,
  92385: 40140,
  92386: 40140,
  92389: 99999,
  92391: 40140,
  92392: 40140,
  92393: 40140,
  92394: 40140,
  92395: 40140,
  92397: 31080,
  92398: 40140,
  92399: 40140,
  92401: 40140,
  92402: 40140,
  92403: 40140,
  92404: 40140,
  92405: 40140,
  92406: 40140,
  92407: 40140,
  92408: 40140,
  92410: 40140,
  92411: 40140,
  92413: 40140,
  92415: 40140,
  92418: 40140,
  92423: 40140,
  92427: 40140,
  92501: 40140,
  92502: 40140,
  92503: 40140,
  92504: 40140,
  92505: 40140,
  92506: 40140,
  92507: 40140,
  92508: 40140,
  92509: 40140,
  92513: 40140,
  92514: 40140,
  92516: 40140,
  92517: 40140,
  92518: 40140,
  92519: 40140,
  92521: 40140,
  92522: 40140,
  92530: 40140,
  92531: 40140,
  92532: 40140,
  92536: 40140,
  92539: 40140,
  92543: 40140,
  92544: 40140,
  92545: 40140,
  92546: 40140,
  92548: 40140,
  92549: 40140,
  92551: 40140,
  92552: 40140,
  92553: 40140,
  92555: 40140,
  92556: 40140,
  92557: 40140,
  92561: 40140,
  92562: 40140,
  92563: 40140,
  92564: 40140,
  92567: 40140,
  92570: 40140,
  92571: 40140,
  92572: 40140,
  92581: 40140,
  92582: 40140,
  92583: 40140,
  92584: 40140,
  92585: 40140,
  92586: 40140,
  92587: 40140,
  92589: 40140,
  92590: 40140,
  92591: 40140,
  92592: 40140,
  92593: 40140,
  92595: 40140,
  92596: 40140,
  92599: 40140,
  92602: 31080,
  92603: 31080,
  92604: 31080,
  92605: 31080,
  92606: 31080,
  92607: 31080,
  92609: 31080,
  92610: 31080,
  92612: 31080,
  92614: 31080,
  92615: 31080,
  92616: 31080,
  92617: 31080,
  92618: 31080,
  92619: 31080,
  92620: 31080,
  92623: 31080,
  92624: 31080,
  92625: 31080,
  92626: 31080,
  92627: 31080,
  92628: 31080,
  92629: 31080,
  92630: 31080,
  92637: 31080,
  92646: 31080,
  92647: 31080,
  92648: 31080,
  92649: 31080,
  92650: 31080,
  92651: 31080,
  92652: 31080,
  92653: 31080,
  92654: 31080,
  92655: 31080,
  92656: 31080,
  92657: 31080,
  92658: 31080,
  92659: 31080,
  92660: 31080,
  92661: 31080,
  92662: 31080,
  92663: 31080,
  92672: 41740,
  92673: 31080,
  92674: 31080,
  92675: 31080,
  92676: 31080,
  92677: 31080,
  92678: 31080,
  92679: 31080,
  92683: 31080,
  92684: 31080,
  92685: 31080,
  92688: 31080,
  92690: 31080,
  92691: 31080,
  92692: 31080,
  92693: 31080,
  92694: 31080,
  92697: 31080,
  92698: 31080,
  92701: 31080,
  92702: 31080,
  92703: 31080,
  92704: 31080,
  92705: 31080,
  92706: 31080,
  92707: 31080,
  92708: 31080,
  92711: 31080,
  92728: 31080,
  92735: 31080,
  92780: 31080,
  92781: 31080,
  92782: 31080,
  92799: 31080,
  92801: 31080,
  92802: 31080,
  92803: 31080,
  92804: 31080,
  92805: 31080,
  92806: 31080,
  92807: 31080,
  92808: 31080,
  92809: 31080,
  92811: 31080,
  92812: 31080,
  92814: 31080,
  92815: 31080,
  92816: 31080,
  92817: 31080,
  92821: 31080,
  92822: 31080,
  92823: 31080,
  92825: 31080,
  92831: 31080,
  92832: 31080,
  92833: 31080,
  92834: 31080,
  92835: 31080,
  92836: 31080,
  92837: 31080,
  92838: 31080,
  92840: 31080,
  92841: 31080,
  92842: 31080,
  92843: 31080,
  92844: 31080,
  92845: 31080,
  92846: 31080,
  92856: 31080,
  92857: 31080,
  92859: 31080,
  92860: 40140,
  92861: 31080,
  92862: 31080,
  92863: 31080,
  92864: 31080,
  92865: 31080,
  92866: 31080,
  92867: 31080,
  92868: 31080,
  92869: 31080,
  92870: 31080,
  92871: 31080,
  92877: 40140,
  92878: 40140,
  92879: 40140,
  92880: 40140,
  92881: 40140,
  92882: 40140,
  92883: 40140,
  92885: 31080,
  92886: 31080,
  92887: 31080,
  92899: 31080,
  93001: 37100,
  93002: 37100,
  93003: 37100,
  93004: 37100,
  93005: 37100,
  93006: 37100,
  93007: 37100,
  93009: 37100,
  93010: 37100,
  93011: 37100,
  93012: 37100,
  93013: 37100,
  93014: 42200,
  93015: 37100,
  93016: 37100,
  93020: 37100,
  93021: 37100,
  93022: 37100,
  93023: 37100,
  93024: 37100,
  93030: 37100,
  93031: 37100,
  93032: 37100,
  93033: 37100,
  93034: 37100,
  93035: 37100,
  93036: 37100,
  93040: 37100,
  93041: 37100,
  93042: 37100,
  93043: 37100,
  93044: 37100,
  93060: 37100,
  93061: 37100,
  93062: 37100,
  93063: 37100,
  93064: 37100,
  93065: 37100,
  93066: 37100,
  93067: 42200,
  93094: 37100,
  93099: 37100,
  93101: 42200,
  93102: 42200,
  93103: 42200,
  93105: 42200,
  93106: 42200,
  93108: 42200,
  93109: 42200,
  93110: 42200,
  93111: 42200,
  93116: 42200,
  93117: 42200,
  93118: 42200,
  93120: 42200,
  93121: 42200,
  93130: 42200,
  93140: 42200,
  93150: 42200,
  93160: 42200,
  93190: 42200,
  93199: 42200,
  93201: 47300,
  93202: 25260,
  93203: 12540,
  93204: 25260,
  93205: 12540,
  93206: 12540,
  93207: 47300,
  93208: 47300,
  93210: 41500,
  93212: 47300,
  93215: 12540,
  93218: 47300,
  93219: 47300,
  93220: 12540,
  93221: 47300,
  93222: 12540,
  93223: 47300,
  93224: 12540,
  93225: 12540,
  93226: 12540,
  93227: 47300,
  93230: 25260,
  93232: 25260,
  93234: 23420,
  93235: 47300,
  93237: 47300,
  93238: 12540,
  93239: 25260,
  93240: 12540,
  93241: 12540,
  93242: 25260,
  93243: 12540,
  93244: 47300,
  93245: 25260,
  93246: 25260,
  93247: 47300,
  93249: 12540,
  93250: 12540,
  93251: 12540,
  93252: 37100,
  93254: 42200,
  93255: 12540,
  93256: 47300,
  93257: 47300,
  93258: 47300,
  93260: 47300,
  93261: 47300,
  93262: 47300,
  93263: 12540,
  93265: 47300,
  93266: 25260,
  93267: 47300,
  93268: 12540,
  93270: 47300,
  93271: 47300,
  93272: 47300,
  93274: 47300,
  93275: 47300,
  93276: 12540,
  93277: 47300,
  93278: 47300,
  93279: 47300,
  93280: 12540,
  93283: 12540,
  93285: 12540,
  93286: 47300,
  93287: 12540,
  93290: 47300,
  93291: 47300,
  93292: 47300,
  93301: 12540,
  93302: 12540,
  93303: 12540,
  93304: 12540,
  93305: 12540,
  93306: 12540,
  93307: 12540,
  93308: 12540,
  93309: 12540,
  93311: 12540,
  93312: 12540,
  93313: 12540,
  93314: 12540,
  93380: 12540,
  93384: 12540,
  93385: 12540,
  93386: 12540,
  93387: 12540,
  93388: 12540,
  93389: 12540,
  93390: 12540,
  93401: 42020,
  93402: 42020,
  93403: 42020,
  93405: 42020,
  93406: 42020,
  93407: 42020,
  93408: 42020,
  93409: 42020,
  93410: 42020,
  93412: 42020,
  93420: 42020,
  93421: 42020,
  93422: 42020,
  93423: 42020,
  93424: 42020,
  93426: 41500,
  93427: 42200,
  93428: 42020,
  93429: 42200,
  93430: 42020,
  93432: 42020,
  93433: 42020,
  93434: 42200,
  93435: 42020,
  93436: 42200,
  93437: 42200,
  93438: 42200,
  93440: 42200,
  93441: 42200,
  93442: 42020,
  93443: 42020,
  93444: 42020,
  93445: 42020,
  93446: 42020,
  93447: 42020,
  93448: 42020,
  93449: 42020,
  93450: 41500,
  93451: 41500,
  93452: 42020,
  93453: 42020,
  93454: 42200,
  93455: 42200,
  93456: 42200,
  93457: 42200,
  93458: 42200,
  93460: 42200,
  93461: 42020,
  93463: 42200,
  93464: 42200,
  93465: 42020,
  93475: 42020,
  93483: 42020,
  93501: 12540,
  93502: 12540,
  93504: 12540,
  93505: 12540,
  93510: 31080,
  93512: 99999,
  93513: 99999,
  93514: 99999,
  93515: 99999,
  93516: 40140,
  93517: 99999,
  93518: 12540,
  93522: 99999,
  93523: 12540,
  93524: 12540,
  93526: 99999,
  93527: 12540,
  93528: 12540,
  93529: 99999,
  93530: 99999,
  93531: 12540,
  93532: 31080,
  93534: 31080,
  93535: 31080,
  93536: 31080,
  93539: 31080,
  93541: 99999,
  93543: 31080,
  93544: 31080,
  93545: 99999,
  93546: 99999,
  93549: 99999,
  93550: 31080,
  93551: 31080,
  93552: 31080,
  93553: 31080,
  93554: 12540,
  93555: 40140,
  93556: 12540,
  93560: 31080,
  93561: 12540,
  93562: 40140,
  93563: 31080,
  93581: 12540,
  93584: 31080,
  93586: 31080,
  93590: 31080,
  93591: 31080,
  93592: 40140,
  93599: 31080,
  93601: 99999,
  93602: 31460,
  93603: 47300,
  93604: 31460,
  93605: 23420,
  93606: 23420,
  93608: 23420,
  93609: 23420,
  93610: 31460,
  93611: 23420,
  93612: 23420,
  93613: 23420,
  93614: 31460,
  93615: 47300,
  93616: 23420,
  93618: 23420,
  93619: 23420,
  93620: 31460,
  93621: 23420,
  93622: 32900,
  93623: 99999,
  93624: 23420,
  93625: 23420,
  93626: 31460,
  93627: 23420,
  93628: 23420,
  93630: 23420,
  93631: 23420,
  93633: 47300,
  93634: 23420,
  93635: 32900,
  93636: 31460,
  93637: 31460,
  93638: 31460,
  93639: 31460,
  93640: 23420,
  93641: 47300,
  93642: 23420,
  93643: 31460,
  93644: 31460,
  93645: 31460,
  93646: 47300,
  93647: 23420,
  93648: 23420,
  93649: 23420,
  93650: 23420,
  93651: 23420,
  93652: 23420,
  93653: 99999,
  93654: 47300,
  93656: 23420,
  93657: 23420,
  93660: 23420,
  93661: 32900,
  93662: 23420,
  93664: 23420,
  93665: 32900,
  93666: 47300,
  93667: 23420,
  93668: 23420,
  93669: 31460,
  93670: 47300,
  93673: 47300,
  93675: 23420,
  93701: 23420,
  93702: 23420,
  93703: 23420,
  93704: 23420,
  93705: 23420,
  93706: 23420,
  93707: 23420,
  93708: 23420,
  93709: 23420,
  93710: 23420,
  93711: 23420,
  93712: 23420,
  93714: 23420,
  93715: 23420,
  93716: 23420,
  93717: 23420,
  93718: 23420,
  93720: 31460,
  93721: 23420,
  93722: 23420,
  93723: 23420,
  93724: 23420,
  93725: 23420,
  93726: 23420,
  93727: 23420,
  93728: 23420,
  93729: 23420,
  93730: 23420,
  93737: 23420,
  93740: 23420,
  93741: 23420,
  93744: 23420,
  93745: 23420,
  93747: 23420,
  93750: 23420,
  93755: 23420,
  93760: 23420,
  93761: 23420,
  93764: 23420,
  93765: 23420,
  93771: 23420,
  93772: 23420,
  93773: 23420,
  93774: 23420,
  93775: 23420,
  93776: 23420,
  93777: 23420,
  93778: 23420,
  93779: 23420,
  93786: 23420,
  93790: 23420,
  93791: 23420,
  93792: 23420,
  93793: 23420,
  93794: 23420,
  93844: 23420,
  93888: 23420,
  93901: 41500,
  93902: 41500,
  93905: 41500,
  93906: 41500,
  93907: 41500,
  93908: 41500,
  93912: 41500,
  93915: 41500,
  93920: 41500,
  93921: 41500,
  93922: 41500,
  93923: 41500,
  93924: 41500,
  93925: 41940,
  93926: 41500,
  93927: 41500,
  93928: 41500,
  93930: 41500,
  93932: 41500,
  93933: 41500,
  93940: 41500,
  93942: 41500,
  93943: 41500,
  93944: 41500,
  93950: 41500,
  93953: 41500,
  93954: 41500,
  93955: 41500,
  93960: 41500,
  93962: 41500,
  94002: 41860,
  94005: 41860,
  94010: 41860,
  94011: 41860,
  94014: 41860,
  94015: 41860,
  94017: 41860,
  94018: 41860,
  94019: 41860,
  94020: 41860,
  94021: 41860,
  94022: 41940,
  94023: 41940,
  94024: 41940,
  94025: 41860,
  94026: 41860,
  94027: 41860,
  94028: 41860,
  94030: 41860,
  94035: 41940,
  94037: 41860,
  94038: 41860,
  94039: 41940,
  94040: 41940,
  94041: 41940,
  94042: 41940,
  94043: 41940,
  94044: 41860,
  94060: 41860,
  94061: 41860,
  94062: 41860,
  94063: 41860,
  94064: 41860,
  94065: 41860,
  94066: 41860,
  94070: 41860,
  94074: 41860,
  94080: 41860,
  94083: 41860,
  94085: 41940,
  94086: 41940,
  94087: 41940,
  94088: 41940,
  94089: 41940,
  94102: 41860,
  94103: 41860,
  94104: 41860,
  94105: 41860,
  94107: 41860,
  94108: 41860,
  94109: 41860,
  94110: 41860,
  94111: 41860,
  94112: 41860,
  94114: 41860,
  94115: 41860,
  94116: 41860,
  94117: 41860,
  94118: 41860,
  94119: 41860,
  94121: 41860,
  94122: 41860,
  94123: 41860,
  94124: 41860,
  94125: 41860,
  94126: 41860,
  94127: 41860,
  94128: 41860,
  94129: 41860,
  94130: 41860,
  94131: 41860,
  94132: 41860,
  94133: 41860,
  94134: 41860,
  94140: 41860,
  94141: 41860,
  94143: 41860,
  94146: 41860,
  94147: 41860,
  94158: 41860,
  94159: 41860,
  94164: 41860,
  94188: 41860,
  94301: 41940,
  94302: 41940,
  94303: 41940,
  94304: 41940,
  94305: 41940,
  94306: 41940,
  94309: 41940,
  94401: 41860,
  94402: 41860,
  94403: 41860,
  94404: 41860,
  94497: 41860,
  94501: 41860,
  94502: 41860,
  94503: 46700,
  94505: 41860,
  94506: 41860,
  94507: 41860,
  94508: 34900,
  94509: 41860,
  94510: 46700,
  94511: 41860,
  94512: 46700,
  94513: 41860,
  94514: 41860,
  94515: 42220,
  94516: 41860,
  94517: 41860,
  94518: 41860,
  94519: 41860,
  94520: 41860,
  94521: 41860,
  94522: 41860,
  94523: 41860,
  94524: 41860,
  94525: 41860,
  94526: 41860,
  94528: 41860,
  94530: 41860,
  94531: 41860,
  94533: 46700,
  94534: 46700,
  94535: 46700,
  94536: 41860,
  94537: 41860,
  94538: 41860,
  94539: 41860,
  94540: 41860,
  94541: 41860,
  94542: 41860,
  94543: 41860,
  94544: 41860,
  94545: 41860,
  94546: 41860,
  94547: 41860,
  94548: 41860,
  94549: 41860,
  94550: 41860,
  94551: 41860,
  94552: 41860,
  94553: 41860,
  94555: 41860,
  94556: 41860,
  94557: 41860,
  94558: 42220,
  94559: 34900,
  94560: 41860,
  94561: 41860,
  94562: 34900,
  94563: 41860,
  94564: 41860,
  94565: 41860,
  94566: 41860,
  94567: 34900,
  94568: 41860,
  94569: 41860,
  94570: 41860,
  94571: 40900,
  94572: 41860,
  94573: 34900,
  94574: 42220,
  94575: 41860,
  94576: 34900,
  94577: 41860,
  94578: 41860,
  94579: 41860,
  94580: 41860,
  94581: 34900,
  94582: 41860,
  94583: 41860,
  94585: 46700,
  94586: 41860,
  94587: 41860,
  94588: 41860,
  94589: 46700,
  94590: 46700,
  94591: 46700,
  94592: 46700,
  94595: 41860,
  94596: 41860,
  94597: 41860,
  94598: 41860,
  94599: 34900,
  94601: 41860,
  94602: 41860,
  94603: 41860,
  94604: 41860,
  94605: 41860,
  94606: 41860,
  94607: 41860,
  94608: 41860,
  94609: 41860,
  94610: 41860,
  94611: 41860,
  94612: 41860,
  94613: 41860,
  94614: 41860,
  94615: 41860,
  94618: 41860,
  94619: 41860,
  94620: 41860,
  94621: 41860,
  94623: 41860,
  94624: 41860,
  94661: 41860,
  94662: 41860,
  94666: 41860,
  94701: 41860,
  94702: 41860,
  94703: 41860,
  94704: 41860,
  94705: 41860,
  94706: 41860,
  94707: 41860,
  94708: 41860,
  94709: 41860,
  94710: 41860,
  94712: 41860,
  94720: 41860,
  94801: 41860,
  94802: 41860,
  94803: 41860,
  94804: 41860,
  94805: 41860,
  94806: 41860,
  94807: 41860,
  94820: 41860,
  94850: 41860,
  94901: 41860,
  94903: 41860,
  94904: 41860,
  94912: 41860,
  94913: 41860,
  94914: 41860,
  94915: 41860,
  94920: 41860,
  94922: 42220,
  94923: 42220,
  94924: 41860,
  94925: 41860,
  94927: 42220,
  94928: 42220,
  94929: 41860,
  94930: 41860,
  94931: 42220,
  94933: 41860,
  94937: 41860,
  94938: 41860,
  94939: 41860,
  94940: 41860,
  94941: 41860,
  94942: 41860,
  94945: 41860,
  94946: 41860,
  94947: 41860,
  94948: 41860,
  94949: 41860,
  94950: 41860,
  94951: 42220,
  94952: 41860,
  94953: 42220,
  94954: 42220,
  94955: 42220,
  94956: 41860,
  94957: 41860,
  94960: 41860,
  94963: 41860,
  94964: 41860,
  94965: 41860,
  94966: 41860,
  94970: 41860,
  94971: 41860,
  94972: 42220,
  94973: 41860,
  94974: 41860,
  94975: 42220,
  94976: 41860,
  94977: 41860,
  94978: 41860,
  94979: 41860,
  94999: 42220,
  95001: 42100,
  95002: 41940,
  95003: 42100,
  95004: 41940,
  95005: 42100,
  95006: 42100,
  95007: 42100,
  95008: 41940,
  95009: 41940,
  95010: 42100,
  95011: 41940,
  95012: 41500,
  95013: 41940,
  95014: 41940,
  95015: 41940,
  95017: 42100,
  95018: 42100,
  95019: 42100,
  95020: 41940,
  95021: 41940,
  95023: 41940,
  95024: 41940,
  95026: 41940,
  95030: 41940,
  95031: 41940,
  95032: 41940,
  95033: 42100,
  95035: 41860,
  95036: 41940,
  95037: 41940,
  95038: 41940,
  95039: 41500,
  95041: 42100,
  95043: 41940,
  95044: 41940,
  95045: 41940,
  95046: 41940,
  95050: 41940,
  95051: 41940,
  95052: 41940,
  95053: 41940,
  95054: 41940,
  95055: 41940,
  95056: 41940,
  95060: 42100,
  95061: 42100,
  95062: 42100,
  95063: 42100,
  95064: 42100,
  95065: 42100,
  95066: 42100,
  95067: 42100,
  95070: 41940,
  95071: 41940,
  95073: 42100,
  95075: 41940,
  95076: 42100,
  95077: 42100,
  95101: 41940,
  95103: 41940,
  95106: 41940,
  95108: 41940,
  95109: 41940,
  95110: 41940,
  95111: 41940,
  95112: 41940,
  95113: 41940,
  95116: 41940,
  95117: 41940,
  95118: 41940,
  95119: 41940,
  95120: 41940,
  95121: 41940,
  95122: 41940,
  95123: 41940,
  95124: 41940,
  95125: 41940,
  95126: 41940,
  95127: 41940,
  95128: 41940,
  95129: 41940,
  95130: 41940,
  95131: 41940,
  95132: 41940,
  95133: 41940,
  95134: 41940,
  95135: 41940,
  95136: 41940,
  95138: 41940,
  95139: 41940,
  95140: 41940,
  95141: 41940,
  95148: 41940,
  95150: 41940,
  95151: 41940,
  95152: 41940,
  95153: 41940,
  95154: 41940,
  95155: 41940,
  95156: 41940,
  95157: 41940,
  95158: 41940,
  95159: 41940,
  95160: 41940,
  95161: 41940,
  95164: 41940,
  95170: 41940,
  95172: 41940,
  95173: 41940,
  95191: 41940,
  95192: 41940,
  95196: 41940,
  95201: 44700,
  95202: 44700,
  95203: 44700,
  95204: 44700,
  95205: 44700,
  95206: 44700,
  95207: 44700,
  95208: 44700,
  95209: 44700,
  95210: 44700,
  95211: 44700,
  95212: 44700,
  95213: 44700,
  95215: 44700,
  95219: 44700,
  95220: 44700,
  95221: 99999,
  95222: 99999,
  95223: 99999,
  95224: 99999,
  95225: 99999,
  95226: 99999,
  95227: 44700,
  95228: 99999,
  95229: 99999,
  95230: 33700,
  95231: 44700,
  95232: 99999,
  95233: 99999,
  95234: 44700,
  95236: 44700,
  95237: 44700,
  95240: 44700,
  95241: 44700,
  95242: 44700,
  95245: 99999,
  95246: 99999,
  95247: 99999,
  95248: 99999,
  95249: 99999,
  95251: 99999,
  95252: 99999,
  95253: 44700,
  95254: 99999,
  95255: 99999,
  95257: 99999,
  95258: 44700,
  95267: 44700,
  95269: 44700,
  95301: 32900,
  95303: 32900,
  95304: 44700,
  95305: 43760,
  95306: 99999,
  95307: 33700,
  95309: 43760,
  95310: 43760,
  95311: 43760,
  95312: 32900,
  95313: 33700,
  95315: 32900,
  95316: 33700,
  95317: 32900,
  95318: 99999,
  95319: 33700,
  95320: 44700,
  95321: 43760,
  95322: 32900,
  95323: 33700,
  95324: 33700,
  95325: 99999,
  95326: 33700,
  95327: 43760,
  95328: 33700,
  95329: 33700,
  95330: 44700,
  95333: 32900,
  95334: 32900,
  95335: 43760,
  95336: 44700,
  95337: 44700,
  95338: 99999,
  95340: 32900,
  95341: 32900,
  95343: 32900,
  95344: 32900,
  95345: 99999,
  95346: 43760,
  95347: 43760,
  95348: 32900,
  95350: 33700,
  95351: 33700,
  95352: 33700,
  95353: 33700,
  95354: 33700,
  95355: 33700,
  95356: 33700,
  95357: 33700,
  95358: 33700,
  95360: 33700,
  95361: 44700,
  95363: 33700,
  95364: 43760,
  95365: 32900,
  95366: 44700,
  95367: 33700,
  95368: 33700,
  95369: 99999,
  95370: 43760,
  95372: 43760,
  95373: 43760,
  95374: 32900,
  95375: 43760,
  95376: 44700,
  95377: 41860,
  95378: 44700,
  95379: 43760,
  95380: 32900,
  95381: 33700,
  95382: 33700,
  95383: 43760,
  95385: 44700,
  95386: 33700,
  95387: 33700,
  95388: 32900,
  95389: 99999,
  95391: 44700,
  95397: 33700,
  95401: 42220,
  95402: 42220,
  95403: 42220,
  95404: 42220,
  95405: 42220,
  95406: 42220,
  95407: 42220,
  95409: 42220,
  95410: 46380,
  95412: 42220,
  95415: 46380,
  95416: 42220,
  95417: 46380,
  95418: 46380,
  95419: 42220,
  95420: 46380,
  95421: 42220,
  95422: 17340,
  95423: 17340,
  95424: 17340,
  95425: 42220,
  95426: 17340,
  95427: 46380,
  95428: 46380,
  95429: 46380,
  95430: 42220,
  95431: 42220,
  95432: 46380,
  95433: 42220,
  95435: 17340,
  95436: 42220,
  95437: 46380,
  95439: 42220,
  95441: 42220,
  95442: 42220,
  95443: 17340,
  95444: 42220,
  95445: 42220,
  95446: 42220,
  95448: 42220,
  95449: 46380,
  95450: 42220,
  95451: 17340,
  95452: 42220,
  95453: 17340,
  95454: 46380,
  95456: 46380,
  95457: 17340,
  95458: 17340,
  95459: 46380,
  95460: 46380,
  95461: 17340,
  95462: 42220,
  95463: 46380,
  95464: 17340,
  95465: 42220,
  95466: 46380,
  95467: 17340,
  95468: 46380,
  95469: 46380,
  95470: 46380,
  95471: 42220,
  95472: 42220,
  95473: 42220,
  95476: 34900,
  95480: 42220,
  95481: 46380,
  95482: 46380,
  95485: 17340,
  95486: 42220,
  95487: 42220,
  95488: 46380,
  95490: 46380,
  95492: 42220,
  95493: 17340,
  95494: 46380,
  95497: 42220,
  95501: 21700,
  95502: 21700,
  95503: 21700,
  95511: 21700,
  95514: 21700,
  95518: 21700,
  95519: 21700,
  95521: 21700,
  95524: 21700,
  95525: 21700,
  95526: 21700,
  95527: 99999,
  95528: 21700,
  95531: 18860,
  95532: 18860,
  95534: 21700,
  95536: 21700,
  95537: 21700,
  95538: 18860,
  95540: 21700,
  95542: 46380,
  95543: 18860,
  95545: 21700,
  95546: 21700,
  95547: 21700,
  95548: 18860,
  95549: 21700,
  95550: 21700,
  95551: 21700,
  95552: 99999,
  95553: 21700,
  95554: 21700,
  95555: 21700,
  95556: 21700,
  95558: 21700,
  95559: 21700,
  95560: 21700,
  95562: 21700,
  95563: 99999,
  95564: 21700,
  95565: 21700,
  95567: 18860,
  95568: 99999,
  95569: 21700,
  95570: 21700,
  95571: 21700,
  95573: 21700,
  95585: 46380,
  95587: 21700,
  95589: 46380,
  95595: 99999,
  95601: 99999,
  95602: 40900,
  95603: 40900,
  95604: 40900,
  95605: 40900,
  95606: 40900,
  95607: 40900,
  95608: 40900,
  95609: 40900,
  95610: 40900,
  95611: 40900,
  95612: 40900,
  95613: 40900,
  95614: 40900,
  95615: 40900,
  95616: 40900,
  95617: 40900,
  95618: 40900,
  95619: 40900,
  95620: 46700,
  95621: 40900,
  95623: 40900,
  95624: 40900,
  95625: 46700,
  95626: 49700,
  95627: 40900,
  95628: 40900,
  95629: 40900,
  95630: 40900,
  95631: 40900,
  95632: 44700,
  95633: 40900,
  95634: 40900,
  95635: 40900,
  95636: 40900,
  95637: 40900,
  95638: 40900,
  95639: 40900,
  95640: 99999,
  95641: 40900,
  95642: 99999,
  95645: 40900,
  95646: 99999,
  95648: 40900,
  95650: 40900,
  95651: 40900,
  95652: 40900,
  95653: 40900,
  95654: 99999,
  95655: 40900,
  95656: 40900,
  95658: 40900,
  95659: 49700,
  95660: 40900,
  95661: 40900,
  95662: 40900,
  95663: 40900,
  95664: 40900,
  95665: 99999,
  95666: 40900,
  95667: 40900,
  95668: 49700,
  95669: 99999,
  95670: 40900,
  95671: 40900,
  95672: 40900,
  95673: 40900,
  95674: 49700,
  95675: 99999,
  95676: 49700,
  95677: 40900,
  95678: 40900,
  95679: 40900,
  95680: 40900,
  95681: 40900,
  95682: 40900,
  95683: 40900,
  95684: 40900,
  95685: 99999,
  95686: 44700,
  95687: 46700,
  95688: 46700,
  95689: 99999,
  95690: 46700,
  95691: 40900,
  95692: 49700,
  95693: 40900,
  95694: 46700,
  95695: 40900,
  95696: 46700,
  95697: 40900,
  95698: 40900,
  95699: 99999,
  95701: 40900,
  95703: 40900,
  95709: 40900,
  95712: 46020,
  95713: 40900,
  95714: 40900,
  95715: 40900,
  95717: 40900,
  95720: 40900,
  95721: 40900,
  95722: 40900,
  95724: 40900,
  95726: 40900,
  95728: 46020,
  95735: 40900,
  95736: 40900,
  95741: 40900,
  95742: 40900,
  95746: 40900,
  95747: 40900,
  95757: 40900,
  95758: 40900,
  95759: 40900,
  95762: 40900,
  95763: 40900,
  95765: 40900,
  95776: 40900,
  95798: 40900,
  95799: 40900,
  95811: 40900,
  95812: 40900,
  95813: 40900,
  95814: 40900,
  95815: 40900,
  95816: 40900,
  95817: 40900,
  95818: 40900,
  95819: 40900,
  95820: 40900,
  95821: 40900,
  95822: 40900,
  95823: 40900,
  95824: 40900,
  95825: 40900,
  95826: 40900,
  95827: 40900,
  95828: 40900,
  95829: 40900,
  95830: 40900,
  95831: 40900,
  95832: 40900,
  95833: 40900,
  95834: 40900,
  95835: 40900,
  95836: 49700,
  95837: 49700,
  95838: 40900,
  95840: 40900,
  95841: 40900,
  95842: 40900,
  95843: 40900,
  95851: 40900,
  95852: 40900,
  95853: 40900,
  95860: 40900,
  95864: 40900,
  95865: 40900,
  95866: 40900,
  95867: 40900,
  95894: 40900,
  95901: 17020,
  95903: 49700,
  95910: 99999,
  95912: 40900,
  95913: 99999,
  95914: 49700,
  95915: 99999,
  95916: 17020,
  95917: 17020,
  95918: 49700,
  95919: 49700,
  95920: 99999,
  95922: 99999,
  95923: 99999,
  95924: 46020,
  95925: 49700,
  95926: 17020,
  95927: 17020,
  95928: 17020,
  95929: 17020,
  95930: 49700,
  95932: 99999,
  95934: 99999,
  95935: 49700,
  95936: 99999,
  95937: 40900,
  95938: 17020,
  95939: 99999,
  95940: 17020,
  95941: 49700,
  95942: 17020,
  95943: 99999,
  95944: 99999,
  95945: 46020,
  95946: 46020,
  95947: 99999,
  95948: 49700,
  95949: 46020,
  95950: 99999,
  95951: 99999,
  95953: 49700,
  95954: 17020,
  95955: 99999,
  95956: 99999,
  95957: 49700,
  95958: 17020,
  95959: 46020,
  95960: 46020,
  95961: 49700,
  95962: 49700,
  95963: 39780,
  95965: 17020,
  95966: 17020,
  95967: 17020,
  95968: 17020,
  95969: 17020,
  95970: 99999,
  95971: 99999,
  95972: 49700,
  95973: 17020,
  95974: 17020,
  95975: 46020,
  95976: 17020,
  95977: 46020,
  95978: 17020,
  95979: 99999,
  95980: 99999,
  95981: 99999,
  95982: 49700,
  95983: 99999,
  95984: 99999,
  95986: 46020,
  95987: 99999,
  95988: 99999,
  95991: 49700,
  95992: 49700,
  95993: 49700,
  96001: 39820,
  96002: 39820,
  96003: 39820,
  96006: 99999,
  96007: 39780,
  96008: 39820,
  96009: 45000,
  96010: 99999,
  96011: 39820,
  96013: 39820,
  96014: 99999,
  96015: 99999,
  96016: 39820,
  96017: 39820,
  96019: 39820,
  96020: 99999,
  96021: 39780,
  96022: 39780,
  96023: 99999,
  96024: 99999,
  96025: 39820,
  96027: 99999,
  96028: 39820,
  96029: 39780,
  96031: 99999,
  96032: 99999,
  96033: 39820,
  96034: 99999,
  96035: 39780,
  96037: 99999,
  96038: 99999,
  96039: 99999,
  96040: 39820,
  96041: 99999,
  96044: 99999,
  96046: 99999,
  96047: 39820,
  96048: 99999,
  96049: 39820,
  96050: 99999,
  96051: 39820,
  96052: 99999,
  96054: 99999,
  96055: 39780,
  96056: 99999,
  96057: 99999,
  96058: 99999,
  96059: 39780,
  96061: 39780,
  96062: 39820,
  96063: 39780,
  96064: 99999,
  96065: 39820,
  96067: 99999,
  96068: 45000,
  96069: 39820,
  96071: 39820,
  96073: 39820,
  96074: 39780,
  96075: 39780,
  96076: 99999,
  96078: 39780,
  96080: 39780,
  96084: 39820,
  96085: 99999,
  96086: 99999,
  96087: 39820,
  96088: 39820,
  96089: 39820,
  96090: 39780,
  96091: 99999,
  96092: 39780,
  96093: 99999,
  96094: 99999,
  96095: 39820,
  96096: 39820,
  96097: 99999,
  96099: 39820,
  96101: 99999,
  96103: 99999,
  96104: 99999,
  96105: 99999,
  96106: 99999,
  96107: 99999,
  96108: 99999,
  96109: 45000,
  96110: 99999,
  96111: 46020,
  96112: 99999,
  96113: 45000,
  96114: 99999,
  96115: 99999,
  96116: 99999,
  96117: 45000,
  96118: 99999,
  96119: 45000,
  96120: 99999,
  96121: 45000,
  96122: 99999,
  96123: 45000,
  96124: 99999,
  96125: 99999,
  96126: 99999,
  96127: 45000,
  96128: 45000,
  96129: 99999,
  96130: 45000,
  96132: 45000,
  96133: 99999,
  96134: 99999,
  96135: 99999,
  96136: 45000,
  96137: 45000,
  96140: 40900,
  96141: 40900,
  96142: 40900,
  96143: 40900,
  96145: 40900,
  96146: 40900,
  96148: 40900,
  96150: 40900,
  96151: 40900,
  96155: 40900,
  96158: 40900,
  96160: 46020,
  96161: 40900,
  96162: 46020,
  96701: 46520,
  96703: 28180,
  96704: 25900,
  96705: 28180,
  96706: 46520,
  96707: 46520,
  96708: 27980,
  96709: 46520,
  96710: 25900,
  96712: 46520,
  96713: 27980,
  96714: 28180,
  96715: 28180,
  96716: 28180,
  96717: 46520,
  96718: 25900,
  96719: 25900,
  96720: 25900,
  96721: 25900,
  96722: 28180,
  96725: 25900,
  96726: 25900,
  96727: 25900,
  96728: 25900,
  96729: 27980,
  96730: 46520,
  96731: 46520,
  96732: 27980,
  96733: 27980,
  96734: 46520,
  96737: 25900,
  96738: 25900,
  96739: 25900,
  96740: 25900,
  96741: 28180,
  96742: 99999,
  96743: 25900,
  96744: 46520,
  96745: 25900,
  96746: 28180,
  96747: 28180,
  96748: 27980,
  96749: 25900,
  96750: 25900,
  96751: 28180,
  96752: 28180,
  96753: 27980,
  96754: 28180,
  96755: 25900,
  96756: 28180,
  96757: 27980,
  96759: 46520,
  96760: 25900,
  96761: 27980,
  96762: 46520,
  96763: 27980,
  96764: 25900,
  96765: 28180,
  96766: 28180,
  96767: 27980,
  96768: 27980,
  96769: 28180,
  96770: 27980,
  96771: 25900,
  96772: 25900,
  96773: 25900,
  96774: 25900,
  96776: 25900,
  96777: 25900,
  96778: 25900,
  96779: 27980,
  96780: 25900,
  96781: 25900,
  96782: 46520,
  96783: 25900,
  96784: 27980,
  96785: 25900,
  96786: 46520,
  96788: 27980,
  96789: 46520,
  96790: 27980,
  96791: 46520,
  96792: 46520,
  96793: 27980,
  96795: 46520,
  96796: 28180,
  96797: 46520,
  96801: 46520,
  96802: 46520,
  96803: 46520,
  96804: 46520,
  96805: 46520,
  96806: 46520,
  96807: 46520,
  96808: 46520,
  96809: 46520,
  96810: 46520,
  96811: 46520,
  96812: 46520,
  96813: 46520,
  96814: 46520,
  96815: 46520,
  96816: 46520,
  96817: 46520,
  96818: 46520,
  96819: 46520,
  96820: 46520,
  96821: 46520,
  96822: 46520,
  96823: 46520,
  96824: 46520,
  96825: 46520,
  96826: 46520,
  96828: 46520,
  96830: 46520,
  96836: 46520,
  96837: 46520,
  96839: 46520,
  96848: 46520,
  96850: 46520,
  96853: 46520,
  96854: 46520,
  96857: 46520,
  96858: 46520,
  96859: 46520,
  96860: 46520,
  96861: 46520,
  96863: 46520,
  96898: 46520,
  96910: 99999,
  96913: 99999,
  96915: 99999,
  96921: 99999,
  96928: 99999,
  96929: 99999,
  96932: 99999,
  97001: 45520,
  97002: 41420,
  97003: 38900,
  97004: 38900,
  97005: 38900,
  97006: 38900,
  97007: 38900,
  97008: 38900,
  97009: 38900,
  97010: 38900,
  97011: 38900,
  97013: 38900,
  97014: 26220,
  97015: 38900,
  97016: 11820,
  97017: 38900,
  97018: 38900,
  97019: 38900,
  97020: 41420,
  97021: 45520,
  97022: 38900,
  97023: 38900,
  97024: 38900,
  97026: 41420,
  97027: 38900,
  97028: 38900,
  97029: 99999,
  97030: 38900,
  97031: 26220,
  97032: 38900,
  97033: 99999,
  97034: 38900,
  97035: 38900,
  97036: 38900,
  97037: 45520,
  97038: 38900,
  97039: 99999,
  97040: 45520,
  97041: 26220,
  97042: 38900,
  97044: 26220,
  97045: 38900,
  97048: 38900,
  97049: 38900,
  97050: 99999,
  97051: 38900,
  97053: 38900,
  97054: 38900,
  97055: 38900,
  97056: 38900,
  97057: 45520,
  97058: 45520,
  97060: 38900,
  97062: 38900,
  97063: 45520,
  97064: 38900,
  97065: 99999,
  97067: 38900,
  97068: 38900,
  97070: 38900,
  97071: 38900,
  97075: 38900,
  97078: 38900,
  97079: 38900,
  97080: 38900,
  97086: 38900,
  97089: 38900,
  97101: 38900,
  97102: 11820,
  97103: 11820,
  97106: 38900,
  97107: 99999,
  97108: 99999,
  97109: 38900,
  97110: 11820,
  97111: 38900,
  97112: 99999,
  97113: 38900,
  97114: 38900,
  97115: 38900,
  97116: 38900,
  97117: 38900,
  97118: 99999,
  97119: 38900,
  97121: 11820,
  97122: 99999,
  97123: 38900,
  97124: 38900,
  97125: 38900,
  97127: 38900,
  97128: 38900,
  97129: 38900,
  97130: 99999,
  97131: 11820,
  97132: 38900,
  97133: 38900,
  97134: 99999,
  97135: 99999,
  97136: 99999,
  97137: 41420,
  97138: 11820,
  97140: 38900,
  97141: 99999,
  97144: 38900,
  97145: 11820,
  97146: 11820,
  97147: 99999,
  97148: 38900,
  97149: 99999,
  97201: 38900,
  97202: 38900,
  97203: 38900,
  97204: 38900,
  97205: 38900,
  97206: 38900,
  97207: 38900,
  97208: 38900,
  97209: 38900,
  97210: 38900,
  97211: 38900,
  97212: 38900,
  97213: 38900,
  97214: 38900,
  97215: 38900,
  97216: 38900,
  97217: 38900,
  97218: 38900,
  97219: 38900,
  97220: 38900,
  97221: 38900,
  97222: 38900,
  97223: 38900,
  97224: 38900,
  97225: 38900,
  97227: 38900,
  97228: 38900,
  97229: 38900,
  97230: 38900,
  97231: 38900,
  97232: 38900,
  97233: 38900,
  97236: 38900,
  97238: 38900,
  97239: 38900,
  97240: 38900,
  97242: 38900,
  97250: 38900,
  97251: 38900,
  97252: 38900,
  97253: 38900,
  97254: 38900,
  97266: 38900,
  97267: 38900,
  97268: 38900,
  97269: 38900,
  97280: 38900,
  97281: 38900,
  97282: 38900,
  97283: 38900,
  97286: 38900,
  97290: 38900,
  97291: 38900,
  97292: 38900,
  97293: 38900,
  97294: 38900,
  97296: 38900,
  97298: 38900,
  97301: 41420,
  97302: 41420,
  97303: 41420,
  97304: 41420,
  97305: 41420,
  97306: 41420,
  97307: 41420,
  97308: 41420,
  97309: 41420,
  97310: 41420,
  97311: 41420,
  97312: 41420,
  97314: 41420,
  97317: 41420,
  97321: 18700,
  97322: 10540,
  97324: 21660,
  97325: 41420,
  97326: 18700,
  97327: 10540,
  97329: 10540,
  97330: 18700,
  97331: 18700,
  97333: 18700,
  97335: 10540,
  97336: 10540,
  97338: 41420,
  97339: 18700,
  97341: 35440,
  97342: 41420,
  97343: 35440,
  97344: 41420,
  97345: 10540,
  97346: 10540,
  97347: 41420,
  97348: 10540,
  97350: 10540,
  97351: 41420,
  97352: 41420,
  97355: 10540,
  97357: 35440,
  97358: 10540,
  97360: 10540,
  97361: 18700,
  97362: 38900,
  97364: 35440,
  97365: 35440,
  97366: 35440,
  97367: 35440,
  97368: 99999,
  97369: 35440,
  97370: 18700,
  97371: 41420,
  97373: 41420,
  97374: 10540,
  97375: 41420,
  97376: 35440,
  97377: 10540,
  97378: 41420,
  97380: 35440,
  97381: 41420,
  97383: 10540,
  97384: 41420,
  97385: 41420,
  97386: 10540,
  97388: 35440,
  97389: 10540,
  97390: 21660,
  97391: 35440,
  97392: 41420,
  97394: 35440,
  97396: 41420,
  97401: 21660,
  97402: 21660,
  97403: 21660,
  97404: 21660,
  97405: 21660,
  97406: 15060,
  97407: 18300,
  97408: 21660,
  97409: 21660,
  97410: 40700,
  97411: 18300,
  97412: 21660,
  97413: 21660,
  97414: 18300,
  97415: 15060,
  97416: 40700,
  97417: 40700,
  97419: 21660,
  97420: 18300,
  97423: 18300,
  97424: 40700,
  97426: 21660,
  97429: 40700,
  97430: 21660,
  97431: 21660,
  97432: 40700,
  97434: 21660,
  97435: 40700,
  97436: 40700,
  97437: 21660,
  97438: 21660,
  97439: 21660,
  97440: 21660,
  97441: 40700,
  97442: 40700,
  97443: 40700,
  97444: 15060,
  97446: 21660,
  97447: 40700,
  97448: 21660,
  97449: 18300,
  97450: 18300,
  97451: 21660,
  97452: 21660,
  97453: 21660,
  97454: 21660,
  97455: 21660,
  97456: 18700,
  97457: 40700,
  97458: 18300,
  97459: 18300,
  97461: 21660,
  97462: 40700,
  97463: 21660,
  97464: 15060,
  97465: 15060,
  97466: 18300,
  97467: 40700,
  97469: 40700,
  97470: 40700,
  97471: 40700,
  97473: 40700,
  97475: 21660,
  97476: 15060,
  97477: 21660,
  97478: 21660,
  97479: 40700,
  97480: 21660,
  97481: 40700,
  97484: 40700,
  97486: 40700,
  97487: 21660,
  97488: 21660,
  97489: 21660,
  97490: 21660,
  97491: 15060,
  97492: 21660,
  97493: 40700,
  97494: 40700,
  97495: 40700,
  97496: 40700,
  97497: 24420,
  97498: 21660,
  97499: 40700,
  97501: 32780,
  97502: 32780,
  97503: 32780,
  97504: 32780,
  97520: 32780,
  97522: 32780,
  97523: 24420,
  97524: 32780,
  97525: 32780,
  97526: 32780,
  97527: 32780,
  97528: 24420,
  97530: 24420,
  97531: 24420,
  97532: 24420,
  97533: 24420,
  97534: 24420,
  97535: 32780,
  97536: 32780,
  97537: 32780,
  97538: 24420,
  97539: 32780,
  97540: 32780,
  97541: 32780,
  97543: 24420,
  97544: 32780,
  97601: 28900,
  97602: 28900,
  97603: 28900,
  97604: 28900,
  97620: 99999,
  97621: 28900,
  97622: 28900,
  97623: 28900,
  97624: 28900,
  97625: 28900,
  97626: 28900,
  97627: 28900,
  97630: 99999,
  97632: 28900,
  97633: 28900,
  97634: 28900,
  97635: 99999,
  97636: 99999,
  97637: 99999,
  97638: 99999,
  97639: 28900,
  97640: 99999,
  97641: 99999,
  97701: 13460,
  97702: 13460,
  97703: 13460,
  97707: 13460,
  97708: 13460,
  97709: 13460,
  97710: 99999,
  97711: 99999,
  97712: 13460,
  97720: 99999,
  97721: 99999,
  97722: 99999,
  97730: 99999,
  97731: 28900,
  97732: 99999,
  97733: 28900,
  97734: 99999,
  97735: 99999,
  97736: 99999,
  97737: 28900,
  97738: 99999,
  97739: 28900,
  97741: 99999,
  97750: 99999,
  97751: 39260,
  97752: 39260,
  97753: 39260,
  97754: 39260,
  97756: 39260,
  97758: 99999,
  97759: 99999,
  97760: 13460,
  97761: 99999,
  97801: 25840,
  97810: 25840,
  97812: 99999,
  97813: 25840,
  97814: 29260,
  97817: 99999,
  97818: 25840,
  97819: 99999,
  97820: 99999,
  97823: 25840,
  97824: 29260,
  97825: 99999,
  97826: 25840,
  97827: 29260,
  97828: 99999,
  97830: 99999,
  97833: 99999,
  97834: 99999,
  97835: 25840,
  97836: 25840,
  97837: 99999,
  97838: 25840,
  97839: 25840,
  97840: 99999,
  97841: 29260,
  97842: 99999,
  97843: 99999,
  97844: 25840,
  97845: 99999,
  97846: 99999,
  97848: 99999,
  97850: 29260,
  97856: 99999,
  97857: 99999,
  97859: 25840,
  97862: 25840,
  97864: 99999,
  97865: 99999,
  97867: 29260,
  97868: 25840,
  97869: 99999,
  97870: 99999,
  97873: 99999,
  97874: 99999,
  97875: 25840,
  97876: 29260,
  97877: 99999,
  97880: 25840,
  97882: 25840,
  97883: 29260,
  97884: 99999,
  97885: 99999,
  97886: 29260,
  97901: 36620,
  97902: 36620,
  97903: 36620,
  97904: 99999,
  97905: 99999,
  97906: 36620,
  97907: 36620,
  97908: 36620,
  97909: 36620,
  97910: 36620,
  97911: 36620,
  97913: 36620,
  97914: 36620,
  97917: 36620,
  97918: 36620,
  97920: 36620,
  98001: 42660,
  98002: 42660,
  98003: 42660,
  98004: 42660,
  98005: 42660,
  98006: 42660,
  98007: 42660,
  98008: 42660,
  98009: 42660,
  98010: 42660,
  98011: 42660,
  98012: 42660,
  98014: 42660,
  98015: 42660,
  98019: 42660,
  98020: 42660,
  98021: 42660,
  98022: 42660,
  98023: 42660,
  98024: 42660,
  98025: 42660,
  98026: 42660,
  98027: 42660,
  98028: 42660,
  98029: 42660,
  98030: 42660,
  98031: 42660,
  98032: 42660,
  98033: 42660,
  98034: 42660,
  98035: 42660,
  98036: 42660,
  98037: 42660,
  98038: 42660,
  98039: 42660,
  98040: 42660,
  98041: 42660,
  98042: 42660,
  98043: 42660,
  98045: 42660,
  98046: 42660,
  98047: 42660,
  98050: 42660,
  98051: 42660,
  98052: 42660,
  98053: 42660,
  98055: 42660,
  98056: 42660,
  98057: 42660,
  98058: 42660,
  98059: 42660,
  98061: 14740,
  98062: 42660,
  98063: 42660,
  98064: 42660,
  98065: 42660,
  98068: 21260,
  98070: 42660,
  98071: 42660,
  98072: 42660,
  98073: 42660,
  98074: 42660,
  98075: 42660,
  98077: 42660,
  98082: 42660,
  98083: 42660,
  98087: 42660,
  98089: 42660,
  98092: 42660,
  98093: 42660,
  98101: 42660,
  98102: 42660,
  98103: 42660,
  98104: 42660,
  98105: 42660,
  98106: 42660,
  98107: 42660,
  98108: 42660,
  98109: 42660,
  98110: 42660,
  98111: 42660,
  98112: 42660,
  98113: 42660,
  98114: 42660,
  98115: 42660,
  98116: 42660,
  98117: 42660,
  98118: 42660,
  98119: 42660,
  98121: 42660,
  98122: 42660,
  98124: 42660,
  98125: 42660,
  98126: 42660,
  98127: 42660,
  98129: 42660,
  98131: 42660,
  98133: 42660,
  98134: 42660,
  98136: 42660,
  98138: 42660,
  98139: 42660,
  98144: 42660,
  98145: 42660,
  98146: 42660,
  98148: 42660,
  98154: 42660,
  98155: 42660,
  98158: 42660,
  98161: 42660,
  98164: 42660,
  98165: 42660,
  98166: 42660,
  98168: 42660,
  98170: 42660,
  98174: 42660,
  98175: 42660,
  98177: 42660,
  98178: 42660,
  98181: 42660,
  98185: 42660,
  98188: 42660,
  98191: 42660,
  98194: 42660,
  98195: 42660,
  98198: 42660,
  98199: 42660,
  98201: 42660,
  98203: 42660,
  98204: 42660,
  98206: 42660,
  98207: 42660,
  98208: 42660,
  98213: 42660,
  98220: 13380,
  98221: 34580,
  98222: 99999,
  98223: 42660,
  98224: 42660,
  98225: 13380,
  98226: 13380,
  98227: 13380,
  98228: 13380,
  98229: 13380,
  98230: 13380,
  98231: 13380,
  98232: 34580,
  98233: 34580,
  98235: 34580,
  98236: 36020,
  98237: 13380,
  98238: 34580,
  98239: 36020,
  98240: 13380,
  98241: 42660,
  98243: 99999,
  98244: 13380,
  98245: 99999,
  98247: 13380,
  98248: 13380,
  98249: 36020,
  98250: 99999,
  98251: 42660,
  98252: 42660,
  98253: 36020,
  98255: 34580,
  98256: 42660,
  98257: 34580,
  98258: 42660,
  98259: 42660,
  98260: 36020,
  98261: 99999,
  98262: 13380,
  98263: 34580,
  98264: 13380,
  98266: 13380,
  98267: 34580,
  98270: 42660,
  98271: 42660,
  98272: 42660,
  98273: 34580,
  98274: 34580,
  98275: 42660,
  98276: 13380,
  98277: 36020,
  98278: 36020,
  98279: 99999,
  98280: 99999,
  98281: 13380,
  98282: 42660,
  98283: 13380,
  98284: 34580,
  98286: 99999,
  98287: 42660,
  98288: 42660,
  98290: 42660,
  98291: 42660,
  98292: 34580,
  98293: 42660,
  98294: 42660,
  98295: 13380,
  98296: 42660,
  98297: 99999,
  98303: 42660,
  98304: 16500,
  98305: 38820,
  98310: 14740,
  98311: 14740,
  98312: 43220,
  98314: 14740,
  98315: 14740,
  98320: 99999,
  98321: 42660,
  98322: 14740,
  98323: 42660,
  98324: 38820,
  98325: 99999,
  98326: 38820,
  98327: 42660,
  98328: 42660,
  98329: 14740,
  98330: 16500,
  98331: 38820,
  98332: 42660,
  98333: 42660,
  98335: 42660,
  98336: 16500,
  98337: 14740,
  98338: 42660,
  98339: 99999,
  98340: 14740,
  98342: 14740,
  98343: 38820,
  98344: 42660,
  98345: 14740,
  98346: 14740,
  98348: 42660,
  98349: 42660,
  98350: 38820,
  98351: 42660,
  98352: 42660,
  98353: 14740,
  98354: 42660,
  98355: 16500,
  98356: 16500,
  98357: 38820,
  98358: 99999,
  98359: 14740,
  98360: 42660,
  98361: 16500,
  98362: 38820,
  98363: 38820,
  98364: 14740,
  98365: 99999,
  98366: 14740,
  98367: 14740,
  98368: 99999,
  98370: 14740,
  98371: 42660,
  98372: 42660,
  98373: 42660,
  98374: 42660,
  98375: 42660,
  98376: 99999,
  98377: 16500,
  98380: 43220,
  98381: 38820,
  98382: 99999,
  98383: 14740,
  98384: 14740,
  98385: 42660,
  98386: 14740,
  98387: 42660,
  98388: 42660,
  98390: 42660,
  98391: 42660,
  98392: 14740,
  98393: 14740,
  98394: 42660,
  98395: 42660,
  98396: 42660,
  98397: 42660,
  98398: 42660,
  98401: 42660,
  98402: 42660,
  98403: 42660,
  98404: 42660,
  98405: 42660,
  98406: 42660,
  98407: 42660,
  98408: 42660,
  98409: 42660,
  98411: 42660,
  98413: 42660,
  98416: 42660,
  98417: 42660,
  98418: 42660,
  98419: 42660,
  98421: 42660,
  98422: 42660,
  98424: 42660,
  98430: 42660,
  98431: 42660,
  98433: 42660,
  98438: 42660,
  98439: 42660,
  98443: 42660,
  98444: 42660,
  98445: 42660,
  98446: 42660,
  98447: 42660,
  98448: 42660,
  98464: 42660,
  98465: 42660,
  98466: 42660,
  98467: 42660,
  98490: 42660,
  98493: 42660,
  98496: 42660,
  98498: 42660,
  98499: 42660,
  98501: 36500,
  98502: 36500,
  98503: 36500,
  98505: 36500,
  98506: 36500,
  98507: 36500,
  98508: 36500,
  98509: 36500,
  98511: 36500,
  98512: 36500,
  98513: 36500,
  98516: 36500,
  98520: 10140,
  98522: 16500,
  98524: 43220,
  98526: 10140,
  98527: 99999,
  98528: 42660,
  98530: 36500,
  98531: 16500,
  98532: 16500,
  98533: 16500,
  98535: 10140,
  98536: 10140,
  98537: 10140,
  98538: 16500,
  98539: 16500,
  98540: 36500,
  98541: 43220,
  98542: 16500,
  98544: 16500,
  98546: 43220,
  98547: 10140,
  98548: 43220,
  98550: 10140,
  98552: 10140,
  98554: 99999,
  98555: 43220,
  98556: 36500,
  98557: 43220,
  98558: 42660,
  98559: 10140,
  98560: 43220,
  98561: 99999,
  98562: 10140,
  98563: 43220,
  98564: 16500,
  98565: 16500,
  98566: 10140,
  98568: 16500,
  98569: 10140,
  98570: 16500,
  98571: 10140,
  98572: 99999,
  98575: 10140,
  98576: 36500,
  98577: 99999,
  98579: 16500,
  98580: 42660,
  98581: 31020,
  98582: 16500,
  98583: 10140,
  98584: 43220,
  98585: 16500,
  98586: 99999,
  98587: 10140,
  98588: 43220,
  98589: 36500,
  98590: 99999,
  98591: 16500,
  98592: 43220,
  98593: 16500,
  98595: 10140,
  98596: 16500,
  98597: 36500,
  98601: 38900,
  98602: 99999,
  98603: 31020,
  98604: 38900,
  98605: 99999,
  98606: 38900,
  98607: 38900,
  98609: 31020,
  98610: 38900,
  98611: 31020,
  98612: 99999,
  98613: 99999,
  98614: 99999,
  98616: 38900,
  98617: 99999,
  98619: 99999,
  98620: 99999,
  98621: 99999,
  98622: 38900,
  98623: 99999,
  98624: 99999,
  98625: 31020,
  98626: 31020,
  98628: 99999,
  98629: 38900,
  98631: 99999,
  98632: 31020,
  98635: 99999,
  98637: 99999,
  98638: 99999,
  98639: 38900,
  98640: 99999,
  98641: 99999,
  98642: 38900,
  98643: 99999,
  98644: 99999,
  98645: 31020,
  98647: 99999,
  98648: 38900,
  98649: 31020,
  98650: 99999,
  98651: 99999,
  98660: 38900,
  98661: 38900,
  98662: 38900,
  98663: 38900,
  98664: 38900,
  98665: 38900,
  98666: 38900,
  98668: 38900,
  98670: 99999,
  98671: 38900,
  98672: 99999,
  98673: 99999,
  98674: 38900,
  98675: 38900,
  98682: 38900,
  98683: 38900,
  98684: 38900,
  98685: 38900,
  98686: 38900,
  98687: 38900,
  98801: 48300,
  98802: 48300,
  98807: 48300,
  98811: 48300,
  98812: 21260,
  98813: 99999,
  98814: 99999,
  98815: 48300,
  98816: 48300,
  98817: 48300,
  98819: 99999,
  98821: 48300,
  98822: 48300,
  98823: 34180,
  98824: 34180,
  98826: 48300,
  98827: 99999,
  98828: 48300,
  98829: 99999,
  98830: 48300,
  98831: 48300,
  98832: 36830,
  98833: 99999,
  98834: 99999,
  98836: 48300,
  98837: 34180,
  98840: 99999,
  98841: 99999,
  98843: 48300,
  98844: 99999,
  98845: 48300,
  98846: 99999,
  98847: 48300,
  98848: 34180,
  98849: 99999,
  98850: 48300,
  98851: 34180,
  98852: 48300,
  98853: 34180,
  98855: 99999,
  98856: 99999,
  98857: 36830,
  98858: 48300,
  98859: 99999,
  98860: 34180,
  98862: 99999,
  98901: 21260,
  98902: 49420,
  98903: 49420,
  98907: 49420,
  98908: 49420,
  98909: 49420,
  98920: 49420,
  98921: 49420,
  98922: 21260,
  98923: 49420,
  98925: 21260,
  98926: 21260,
  98930: 28420,
  98932: 49420,
  98933: 49420,
  98934: 21260,
  98935: 99999,
  98936: 49420,
  98937: 42660,
  98938: 49420,
  98939: 49420,
  98940: 21260,
  98941: 21260,
  98942: 49420,
  98943: 21260,
  98944: 28420,
  98946: 21260,
  98947: 49420,
  98948: 49420,
  98950: 21260,
  98951: 49420,
  98952: 49420,
  98953: 49420,
  99001: 44060,
  99003: 44060,
  99004: 44060,
  99005: 44060,
  99006: 99999,
  99008: 99999,
  99009: 44060,
  99011: 44060,
  99012: 44060,
  99013: 44060,
  99014: 44060,
  99016: 44060,
  99017: 36830,
  99018: 44060,
  99019: 44060,
  99020: 44060,
  99021: 44060,
  99022: 44060,
  99023: 44060,
  99025: 44060,
  99026: 44060,
  99027: 44060,
  99029: 99999,
  99030: 44060,
  99031: 44060,
  99032: 36830,
  99033: 39420,
  99034: 44060,
  99036: 44060,
  99037: 44060,
  99039: 44060,
  99040: 44060,
  99101: 44060,
  99102: 39420,
  99103: 34180,
  99104: 39420,
  99105: 36830,
  99109: 44060,
  99110: 44060,
  99111: 39420,
  99113: 39420,
  99114: 44060,
  99115: 34180,
  99116: 99999,
  99117: 99999,
  99118: 99999,
  99119: 99999,
  99121: 99999,
  99122: 99999,
  99123: 34180,
  99124: 99999,
  99125: 39420,
  99126: 44060,
  99128: 39420,
  99129: 44060,
  99130: 39420,
  99131: 44060,
  99133: 48300,
  99134: 99999,
  99135: 34180,
  99136: 39420,
  99137: 44060,
  99138: 99999,
  99139: 99999,
  99140: 99999,
  99141: 44060,
  99143: 99999,
  99144: 99999,
  99146: 99999,
  99147: 99999,
  99148: 44060,
  99149: 39420,
  99150: 99999,
  99151: 44060,
  99152: 99999,
  99153: 99999,
  99154: 99999,
  99155: 99999,
  99156: 44060,
  99157: 44060,
  99158: 39420,
  99159: 99999,
  99160: 44060,
  99161: 39420,
  99163: 39420,
  99164: 39420,
  99166: 99999,
  99167: 44060,
  99169: 36830,
  99170: 44060,
  99171: 39420,
  99173: 44060,
  99174: 39420,
  99176: 39420,
  99179: 39420,
  99180: 99999,
  99181: 44060,
  99185: 99999,
  99201: 44060,
  99202: 44060,
  99203: 44060,
  99204: 44060,
  99205: 44060,
  99206: 44060,
  99207: 44060,
  99208: 44060,
  99209: 44060,
  99210: 44060,
  99211: 44060,
  99212: 44060,
  99213: 44060,
  99214: 44060,
  99216: 44060,
  99217: 44060,
  99218: 44060,
  99219: 44060,
  99220: 44060,
  99223: 44060,
  99224: 44060,
  99228: 44060,
  99251: 44060,
  99252: 44060,
  99256: 44060,
  99258: 44060,
  99260: 44060,
  99301: 28420,
  99302: 28420,
  99320: 28420,
  99321: 34180,
  99322: 49420,
  99323: 47460,
  99324: 47460,
  99326: 36830,
  99328: 47460,
  99329: 47460,
  99330: 28420,
  99333: 39420,
  99335: 28420,
  99336: 28420,
  99337: 28420,
  99338: 28420,
  99341: 36830,
  99343: 28420,
  99344: 36830,
  99345: 28420,
  99346: 28420,
  99347: 99999,
  99348: 99999,
  99349: 34180,
  99350: 99999,
  99352: 28420,
  99353: 28420,
  99354: 28420,
  99356: 99999,
  99357: 34180,
  99359: 99999,
  99360: 47460,
  99361: 47460,
  99362: 47460,
  99363: 47460,
  99371: 36830,
  99401: 30300,
  99402: 30300,
  99403: 30300,
  99501: 11260,
  99502: 11260,
  99503: 11260,
  99504: 11260,
  99505: 11260,
  99506: 11260,
  99507: 11260,
  99508: 11260,
  99509: 11260,
  99510: 11260,
  99511: 11260,
  99513: 11260,
  99514: 11260,
  99515: 11260,
  99516: 11260,
  99517: 11260,
  99518: 11260,
  99519: 11260,
  99520: 11260,
  99521: 11260,
  99522: 11260,
  99523: 11260,
  99524: 11260,
  99529: 11260,
  99530: 11260,
  99540: 11260,
  99545: 99999,
  99546: 99999,
  99547: 99999,
  99548: 99999,
  99549: 99999,
  99550: 99999,
  99551: 99999,
  99552: 99999,
  99553: 99999,
  99554: 99999,
  99555: 99999,
  99556: 99999,
  99557: 99999,
  99558: 99999,
  99559: 99999,
  99561: 99999,
  99563: 99999,
  99564: 99999,
  99565: 99999,
  99566: 99999,
  99567: 11260,
  99568: 99999,
  99569: 99999,
  99571: 99999,
  99572: 99999,
  99573: 99999,
  99574: 99999,
  99575: 99999,
  99576: 99999,
  99577: 11260,
  99578: 99999,
  99579: 99999,
  99580: 99999,
  99581: 99999,
  99583: 99999,
  99585: 99999,
  99586: 99999,
  99587: 11260,
  99588: 99999,
  99589: 99999,
  99590: 99999,
  99591: 99999,
  99599: 11260,
  99602: 99999,
  99603: 99999,
  99604: 99999,
  99605: 99999,
  99606: 99999,
  99607: 99999,
  99608: 99999,
  99609: 99999,
  99610: 99999,
  99611: 99999,
  99612: 99999,
  99613: 99999,
  99614: 99999,
  99615: 99999,
  99619: 99999,
  99620: 99999,
  99621: 99999,
  99622: 99999,
  99623: 11260,
  99624: 99999,
  99625: 99999,
  99626: 99999,
  99627: 99999,
  99628: 99999,
  99630: 99999,
  99631: 99999,
  99632: 99999,
  99633: 99999,
  99634: 99999,
  99635: 99999,
  99636: 99999,
  99637: 99999,
  99638: 99999,
  99639: 99999,
  99640: 99999,
  99641: 99999,
  99643: 99999,
  99644: 99999,
  99645: 11260,
  99647: 99999,
  99648: 99999,
  99649: 99999,
  99650: 99999,
  99651: 99999,
  99652: 11260,
  99653: 99999,
  99654: 11260,
  99655: 99999,
  99656: 99999,
  99657: 99999,
  99658: 99999,
  99659: 99999,
  99660: 99999,
  99661: 99999,
  99662: 99999,
  99663: 99999,
  99664: 99999,
  99665: 99999,
  99666: 99999,
  99667: 11260,
  99668: 99999,
  99669: 99999,
  99670: 99999,
  99671: 99999,
  99672: 99999,
  99674: 11260,
  99675: 99999,
  99676: 11260,
  99677: 99999,
  99678: 99999,
  99679: 99999,
  99680: 99999,
  99681: 99999,
  99682: 99999,
  99683: 11260,
  99684: 99999,
  99685: 99999,
  99686: 99999,
  99687: 11260,
  99688: 11260,
  99689: 99999,
  99690: 99999,
  99691: 99999,
  99692: 99999,
  99693: 99999,
  99694: 11260,
  99701: 21820,
  99702: 21820,
  99703: 21820,
  99704: 99999,
  99705: 21820,
  99706: 21820,
  99707: 21820,
  99708: 21820,
  99709: 21820,
  99712: 21820,
  99714: 21820,
  99720: 99999,
  99721: 99999,
  99722: 99999,
  99723: 99999,
  99724: 99999,
  99725: 21820,
  99726: 99999,
  99727: 99999,
  99729: 99999,
  99730: 99999,
  99731: 99999,
  99732: 99999,
  99733: 99999,
  99734: 99999,
  99736: 99999,
  99737: 99999,
  99738: 99999,
  99739: 99999,
  99740: 99999,
  99741: 99999,
  99742: 99999,
  99743: 99999,
  99744: 99999,
  99745: 99999,
  99746: 99999,
  99747: 99999,
  99748: 99999,
  99749: 99999,
  99750: 99999,
  99751: 99999,
  99752: 99999,
  99753: 99999,
  99754: 99999,
  99755: 99999,
  99756: 99999,
  99757: 99999,
  99758: 99999,
  99759: 99999,
  99760: 99999,
  99761: 99999,
  99762: 99999,
  99763: 99999,
  99764: 99999,
  99765: 99999,
  99766: 99999,
  99767: 99999,
  99768: 99999,
  99769: 99999,
  99770: 99999,
  99771: 99999,
  99772: 99999,
  99773: 99999,
  99774: 99999,
  99775: 21820,
  99776: 99999,
  99777: 99999,
  99778: 99999,
  99780: 99999,
  99781: 99999,
  99782: 99999,
  99783: 99999,
  99784: 99999,
  99785: 99999,
  99786: 99999,
  99788: 99999,
  99789: 99999,
  99790: 21820,
  99791: 99999,
  99801: 27940,
  99811: 27940,
  99820: 99999,
  99824: 27940,
  99825: 99999,
  99826: 99999,
  99827: 99999,
  99829: 99999,
  99830: 99999,
  99832: 99999,
  99833: 99999,
  99835: 99999,
  99836: 99999,
  99840: 99999,
  99841: 99999,
  99901: 28540,
  99903: 99999,
  99918: 99999,
  99919: 99999,
  99921: 99999,
  99922: 99999,
  99923: 99999,
  99925: 99999,
  99926: 99999,
  99927: 99999,
  99928: 28540,
  99929: 99999,
};

export const metroNamesAndGeoId = [
  ["NAME", "metropolitan/micropolitan statistical area"],
  ["Aberdeen, SD Micro Area", "10100"],
  ["Aberdeen, WA Micro Area", "10140"],
  ["Abilene, TX Metro Area", "10180"],
  ["Ada, OK Micro Area", "10220"],
  ["Adrian, MI Micro Area", "10300"],
  ["Aguadilla-Isabela, PR Metro Area", "10380"],
  ["Akron, OH Metro Area", "10420"],
  ["Alamogordo, NM Micro Area", "10460"],
  ["Albany, GA Metro Area", "10500"],
  ["Albany-Lebanon, OR Metro Area", "10540"],
  ["Albany-Schenectady-Troy, NY Metro Area", "10580"],
  ["Albemarle, NC Micro Area", "10620"],
  ["Albert Lea, MN Micro Area", "10660"],
  ["Albertville, AL Micro Area", "10700"],
  ["Albuquerque, NM Metro Area", "10740"],
  ["Alexander City, AL Micro Area", "10760"],
  ["Alexandria, LA Metro Area", "10780"],
  ["Alexandria, MN Micro Area", "10820"],
  ["Alice, TX Micro Area", "10860"],
  ["Allentown-Bethlehem-Easton, PA-NJ Metro Area", "10900"],
  ["Alma, MI Micro Area", "10940"],
  ["Alpena, MI Micro Area", "10980"],
  ["Altoona, PA Metro Area", "11020"],
  ["Altus, OK Micro Area", "11060"],
  ["Amarillo, TX Metro Area", "11100"],
  ["Americus, GA Micro Area", "11140"],
  ["Ames, IA Metro Area", "11180"],
  ["Amsterdam, NY Micro Area", "11220"],
  ["Anchorage, AK Metro Area", "11260"],
  ["Andrews, TX Micro Area", "11380"],
  ["Angola, IN Micro Area", "11420"],
  ["Ann Arbor, MI Metro Area", "11460"],
  ["Anniston-Oxford, AL Metro Area", "11500"],
  ["Appleton, WI Metro Area", "11540"],
  ["Arcadia, FL Micro Area", "11580"],
  ["Ardmore, OK Micro Area", "11620"],
  ["Arecibo, PR Metro Area", "11640"],
  ["Arkadelphia, AR Micro Area", "11660"],
  ["Asheville, NC Metro Area", "11700"],
  ["Ashland, OH Micro Area", "11740"],
  ["Ashtabula, OH Micro Area", "11780"],
  ["Astoria, OR Micro Area", "11820"],
  ["Atchison, KS Micro Area", "11860"],
  ["Athens, OH Micro Area", "11900"],
  ["Athens, TN Micro Area", "11940"],
  ["Athens, TX Micro Area", "11980"],
  ["Athens-Clarke County, GA Metro Area", "12020"],
  ["Atlanta-Sandy Springs-Alpharetta, GA Metro Area", "12060"],
  ["Atlantic City-Hammonton, NJ Metro Area", "12100"],
  ["Atmore, AL Micro Area", "12120"],
  ["Auburn, IN Micro Area", "12140"],
  ["Auburn, NY Micro Area", "12180"],
  ["Auburn-Opelika, AL Metro Area", "12220"],
  ["Augusta-Richmond County, GA-SC Metro Area", "12260"],
  ["Augusta-Waterville, ME Micro Area", "12300"],
  ["Austin, MN Micro Area", "12380"],
  ["Austin-Round Rock-Georgetown, TX Metro Area", "12420"],
  ["Bainbridge, GA Micro Area", "12460"],
  ["Bakersfield, CA Metro Area", "12540"],
  ["Baltimore-Columbia-Towson, MD Metro Area", "12580"],
  ["Bangor, ME Metro Area", "12620"],
  ["Baraboo, WI Micro Area", "12660"],
  ["Bardstown, KY Micro Area", "12680"],
  ["Barnstable Town, MA Metro Area", "12700"],
  ["Barre, VT Micro Area", "12740"],
  ["Bartlesville, OK Micro Area", "12780"],
  ["Batavia, NY Micro Area", "12860"],
  ["Batesville, AR Micro Area", "12900"],
  ["Baton Rouge, LA Metro Area", "12940"],
  ["Battle Creek, MI Metro Area", "12980"],
  ["Bay City, MI Metro Area", "13020"],
  ["Bay City, TX Micro Area", "13060"],
  ["Beatrice, NE Micro Area", "13100"],
  ["Beaumont-Port Arthur, TX Metro Area", "13140"],
  ["Beaver Dam, WI Micro Area", "13180"],
  ["Beckley, WV Metro Area", "13220"],
  ["Bedford, IN Micro Area", "13260"],
  ["Beeville, TX Micro Area", "13300"],
  ["Bellefontaine, OH Micro Area", "13340"],
  ["Bellingham, WA Metro Area", "13380"],
  ["Bemidji, MN Micro Area", "13420"],
  ["Bend, OR Metro Area", "13460"],
  ["Bennettsville, SC Micro Area", "13500"],
  ["Bennington, VT Micro Area", "13540"],
  ["Berlin, NH Micro Area", "13620"],
  ["Big Rapids, MI Micro Area", "13660"],
  ["Big Spring, TX Micro Area", "13700"],
  ["Big Stone Gap, VA Micro Area", "13720"],
  ["Billings, MT Metro Area", "13740"],
  ["Binghamton, NY Metro Area", "13780"],
  ["Birmingham-Hoover, AL Metro Area", "13820"],
  ["Bismarck, ND Metro Area", "13900"],
  ["Blackfoot, ID Micro Area", "13940"],
  ["Blacksburg-Christiansburg, VA Metro Area", "13980"],
  ["Bloomington, IL Metro Area", "14010"],
  ["Bloomington, IN Metro Area", "14020"],
  ["Bloomsburg-Berwick, PA Metro Area", "14100"],
  ["Bluefield, WV-VA Micro Area", "14140"],
  ["Bluffton, IN Micro Area", "14160"],
  ["Blytheville, AR Micro Area", "14180"],
  ["Bogalusa, LA Micro Area", "14220"],
  ["Boise City, ID Metro Area", "14260"],
  ["Bonham, TX Micro Area", "14300"],
  ["Boone, NC Micro Area", "14380"],
  ["Borger, TX Micro Area", "14420"],
  ["Boston-Cambridge-Newton, MA-NH Metro Area", "14460"],
  ["Boulder, CO Metro Area", "14500"],
  ["Bowling Green, KY Metro Area", "14540"],
  ["Bozeman, MT Micro Area", "14580"],
  ["Bradford, PA Micro Area", "14620"],
  ["Brainerd, MN Micro Area", "14660"],
  ["Branson, MO Micro Area", "14700"],
  ["Breckenridge, CO Micro Area", "14720"],
  ["Bremerton-Silverdale-Port Orchard, WA Metro Area", "14740"],
  ["Brenham, TX Micro Area", "14780"],
  ["Brevard, NC Micro Area", "14820"],
  ["Bridgeport-Stamford-Norwalk, CT Metro Area", "14860"],
  ["Brookhaven, MS Micro Area", "15020"],
  ["Brookings, OR Micro Area", "15060"],
  ["Brookings, SD Micro Area", "15100"],
  ["Brownsville, TN Micro Area", "15140"],
  ["Brownsville-Harlingen, TX Metro Area", "15180"],
  ["Brownwood, TX Micro Area", "15220"],
  ["Brunswick, GA Metro Area", "15260"],
  ["Bucyrus-Galion, OH Micro Area", "15340"],
  ["Buffalo-Cheektowaga, NY Metro Area", "15380"],
  ["Burley, ID Micro Area", "15420"],
  ["Burlington, IA-IL Micro Area", "15460"],
  ["Burlington, NC Metro Area", "15500"],
  ["Burlington-South Burlington, VT Metro Area", "15540"],
  ["Butte-Silver Bow, MT Micro Area", "15580"],
  ["Cadillac, MI Micro Area", "15620"],
  ["Calhoun, GA Micro Area", "15660"],
  ["California-Lexington Park, MD Metro Area", "15680"],
  ["Cambridge, MD Micro Area", "15700"],
  ["Cambridge, OH Micro Area", "15740"],
  ["Camden, AR Micro Area", "15780"],
  ["Campbellsville, KY Micro Area", "15820"],
  ["Cañon City, CO Micro Area", "15860"],
  ["Canton-Massillon, OH Metro Area", "15940"],
  ["Cape Coral-Fort Myers, FL Metro Area", "15980"],
  ["Cape Girardeau, MO-IL Metro Area", "16020"],
  ["Carbondale-Marion, IL Metro Area", "16060"],
  ["Carlsbad-Artesia, NM Micro Area", "16100"],
  ["Carroll, IA Micro Area", "16140"],
  ["Carson City, NV Metro Area", "16180"],
  ["Casper, WY Metro Area", "16220"],
  ["Cedar City, UT Micro Area", "16260"],
  ["Cedar Rapids, IA Metro Area", "16300"],
  ["Cedartown, GA Micro Area", "16340"],
  ["Celina, OH Micro Area", "16380"],
  ["Central City, KY Micro Area", "16420"],
  ["Centralia, IL Micro Area", "16460"],
  ["Centralia, WA Micro Area", "16500"],
  ["Chambersburg-Waynesboro, PA Metro Area", "16540"],
  ["Champaign-Urbana, IL Metro Area", "16580"],
  ["Charleston, WV Metro Area", "16620"],
  ["Charleston-Mattoon, IL Micro Area", "16660"],
  ["Charleston-North Charleston, SC Metro Area", "16700"],
  ["Charlotte-Concord-Gastonia, NC-SC Metro Area", "16740"],
  ["Charlottesville, VA Metro Area", "16820"],
  ["Chattanooga, TN-GA Metro Area", "16860"],
  ["Cheyenne, WY Metro Area", "16940"],
  ["Chicago-Naperville-Elgin, IL-IN-WI Metro Area", "16980"],
  ["Chico, CA Metro Area", "17020"],
  ["Chillicothe, OH Micro Area", "17060"],
  ["Cincinnati, OH-KY-IN Metro Area", "17140"],
  ["Clarksburg, WV Micro Area", "17220"],
  ["Clarksdale, MS Micro Area", "17260"],
  ["Clarksville, TN-KY Metro Area", "17300"],
  ["Clearlake, CA Micro Area", "17340"],
  ["Cleveland, MS Micro Area", "17380"],
  ["Cleveland, TN Metro Area", "17420"],
  ["Cleveland-Elyria, OH Metro Area", "17460"],
  ["Clewiston, FL Micro Area", "17500"],
  ["Clinton, IA Micro Area", "17540"],
  ["Clovis, NM Micro Area", "17580"],
  ["Coamo, PR Micro Area", "17620"],
  ["Coco, PR Micro Area", "17640"],
  ["Coeur d'Alene, ID Metro Area", "17660"],
  ["Coffeyville, KS Micro Area", "17700"],
  ["Coldwater, MI Micro Area", "17740"],
  ["College Station-Bryan, TX Metro Area", "17780"],
  ["Colorado Springs, CO Metro Area", "17820"],
  ["Columbia, MO Metro Area", "17860"],
  ["Columbia, SC Metro Area", "17900"],
  ["Columbus, GA-AL Metro Area", "17980"],
  ["Columbus, IN Metro Area", "18020"],
  ["Columbus, MS Micro Area", "18060"],
  ["Columbus, NE Micro Area", "18100"],
  ["Columbus, OH Metro Area", "18140"],
  ["Concord, NH Micro Area", "18180"],
  ["Connersville, IN Micro Area", "18220"],
  ["Cookeville, TN Micro Area", "18260"],
  ["Coos Bay, OR Micro Area", "18300"],
  ["Cordele, GA Micro Area", "18380"],
  ["Corinth, MS Micro Area", "18420"],
  ["Cornelia, GA Micro Area", "18460"],
  ["Corning, NY Micro Area", "18500"],
  ["Corpus Christi, TX Metro Area", "18580"],
  ["Corsicana, TX Micro Area", "18620"],
  ["Cortland, NY Micro Area", "18660"],
  ["Corvallis, OR Metro Area", "18700"],
  ["Coshocton, OH Micro Area", "18740"],
  ["Craig, CO Micro Area", "18780"],
  ["Crawfordsville, IN Micro Area", "18820"],
  ["Crescent City, CA Micro Area", "18860"],
  ["Crestview-Fort Walton Beach-Destin, FL Metro Area", "18880"],
  ["Crossville, TN Micro Area", "18900"],
  ["Cullman, AL Micro Area", "18980"],
  ["Cullowhee, NC Micro Area", "19000"],
  ["Cumberland, MD-WV Metro Area", "19060"],
  ["Dallas-Fort Worth-Arlington, TX Metro Area", "19100"],
  ["Dalton, GA Metro Area", "19140"],
  ["Danville, IL Metro Area", "19180"],
  ["Danville, KY Micro Area", "19220"],
  ["Danville, VA Micro Area", "19260"],
  ["Daphne-Fairhope-Foley, AL Metro Area", "19300"],
  ["Davenport-Moline-Rock Island, IA-IL Metro Area", "19340"],
  ["Dayton, TN Micro Area", "19420"],
  ["Dayton-Kettering, OH Metro Area", "19430"],
  ["Decatur, AL Metro Area", "19460"],
  ["Decatur, IL Metro Area", "19500"],
  ["Decatur, IN Micro Area", "19540"],
  ["Defiance, OH Micro Area", "19580"],
  ["Del Rio, TX Micro Area", "19620"],
  ["Deltona-Daytona Beach-Ormond Beach, FL Metro Area", "19660"],
  ["Deming, NM Micro Area", "19700"],
  ["Denver-Aurora-Lakewood, CO Metro Area", "19740"],
  ["DeRidder, LA Micro Area", "19760"],
  ["Des Moines-West Des Moines, IA Metro Area", "19780"],
  ["Detroit-Warren-Dearborn, MI Metro Area", "19820"],
  ["Dickinson, ND Micro Area", "19860"],
  ["Dixon, IL Micro Area", "19940"],
  ["Dodge City, KS Micro Area", "19980"],
  ["Dothan, AL Metro Area", "20020"],
  ["Douglas, GA Micro Area", "20060"],
  ["Dover, DE Metro Area", "20100"],
  ["Dublin, GA Micro Area", "20140"],
  ["DuBois, PA Micro Area", "20180"],
  ["Dubuque, IA Metro Area", "20220"],
  ["Duluth, MN-WI Metro Area", "20260"],
  ["Dumas, TX Micro Area", "20300"],
  ["Duncan, OK Micro Area", "20340"],
  ["Durango, CO Micro Area", "20420"],
  ["Durant, OK Micro Area", "20460"],
  ["Durham-Chapel Hill, NC Metro Area", "20500"],
  ["Dyersburg, TN Micro Area", "20540"],
  ["Eagle Pass, TX Micro Area", "20580"],
  ["Easton, MD Micro Area", "20660"],
  ["East Stroudsburg, PA Metro Area", "20700"],
  ["Eau Claire, WI Metro Area", "20740"],
  ["Edwards, CO Micro Area", "20780"],
  ["Effingham, IL Micro Area", "20820"],
  ["El Campo, TX Micro Area", "20900"],
  ["El Centro, CA Metro Area", "20940"],
  ["El Dorado, AR Micro Area", "20980"],
  ["Elizabeth City, NC Micro Area", "21020"],
  ["Elizabethtown-Fort Knox, KY Metro Area", "21060"],
  ["Elk City, OK Micro Area", "21120"],
  ["Elkhart-Goshen, IN Metro Area", "21140"],
  ["Elkins, WV Micro Area", "21180"],
  ["Elko, NV Micro Area", "21220"],
  ["Ellensburg, WA Micro Area", "21260"],
  ["Elmira, NY Metro Area", "21300"],
  ["El Paso, TX Metro Area", "21340"],
  ["Emporia, KS Micro Area", "21380"],
  ["Enid, OK Metro Area", "21420"],
  ["Enterprise, AL Micro Area", "21460"],
  ["Erie, PA Metro Area", "21500"],
  ["Escanaba, MI Micro Area", "21540"],
  ["Española, NM Micro Area", "21580"],
  ["Eufaula, AL-GA Micro Area", "21640"],
  ["Eugene-Springfield, OR Metro Area", "21660"],
  ["Eureka-Arcata, CA Micro Area", "21700"],
  ["Evanston, WY Micro Area", "21740"],
  ["Evansville, IN-KY Metro Area", "21780"],
  ["Fairbanks, AK Metro Area", "21820"],
  ["Fairfield, IA Micro Area", "21840"],
  ["Fairmont, MN Micro Area", "21860"],
  ["Fairmont, WV Micro Area", "21900"],
  ["Fallon, NV Micro Area", "21980"],
  ["Fargo, ND-MN Metro Area", "22020"],
  ["Faribault-Northfield, MN Micro Area", "22060"],
  ["Farmington, MO Micro Area", "22100"],
  ["Farmington, NM Metro Area", "22140"],
  ["Fayetteville, NC Metro Area", "22180"],
  ["Fayetteville-Springdale-Rogers, AR Metro Area", "22220"],
  ["Fergus Falls, MN Micro Area", "22260"],
  ["Fernley, NV Micro Area", "22280"],
  ["Findlay, OH Micro Area", "22300"],
  ["Fitzgerald, GA Micro Area", "22340"],
  ["Flagstaff, AZ Metro Area", "22380"],
  ["Flint, MI Metro Area", "22420"],
  ["Florence, SC Metro Area", "22500"],
  ["Florence-Muscle Shoals, AL Metro Area", "22520"],
  ["Fond du Lac, WI Metro Area", "22540"],
  ["Forest City, NC Micro Area", "22580"],
  ["Forrest City, AR Micro Area", "22620"],
  ["Fort Collins, CO Metro Area", "22660"],
  ["Fort Dodge, IA Micro Area", "22700"],
  ["Fort Leonard Wood, MO Micro Area", "22780"],
  ["Fort Madison-Keokuk, IA-IL-MO Micro Area", "22800"],
  ["Fort Morgan, CO Micro Area", "22820"],
  ["Fort Payne, AL Micro Area", "22840"],
  ["Fort Polk South, LA Micro Area", "22860"],
  ["Fort Smith, AR-OK Metro Area", "22900"],
  ["Fort Wayne, IN Metro Area", "23060"],
  ["Frankfort, IN Micro Area", "23140"],
  ["Frankfort, KY Micro Area", "23180"],
  ["Fredericksburg, TX Micro Area", "23240"],
  ["Freeport, IL Micro Area", "23300"],
  ["Fremont, NE Micro Area", "23340"],
  ["Fremont, OH Micro Area", "23380"],
  ["Fresno, CA Metro Area", "23420"],
  ["Gadsden, AL Metro Area", "23460"],
  ["Gaffney, SC Micro Area", "23500"],
  ["Gainesville, FL Metro Area", "23540"],
  ["Gainesville, GA Metro Area", "23580"],
  ["Gainesville, TX Micro Area", "23620"],
  ["Galesburg, IL Micro Area", "23660"],
  ["Gallup, NM Micro Area", "23700"],
  ["Garden City, KS Micro Area", "23780"],
  ["Gardnerville Ranchos, NV Micro Area", "23820"],
  ["Georgetown, SC Micro Area", "23860"],
  ["Gettysburg, PA Metro Area", "23900"],
  ["Gillette, WY Micro Area", "23940"],
  ["Glasgow, KY Micro Area", "23980"],
  ["Glens Falls, NY Metro Area", "24020"],
  ["Glenwood Springs, CO Micro Area", "24060"],
  ["Gloversville, NY Micro Area", "24100"],
  ["Goldsboro, NC Metro Area", "24140"],
  ["Granbury, TX Micro Area", "24180"],
  ["Grand Forks, ND-MN Metro Area", "24220"],
  ["Grand Island, NE Metro Area", "24260"],
  ["Grand Junction, CO Metro Area", "24300"],
  ["Grand Rapids, MN Micro Area", "24330"],
  ["Grand Rapids-Kentwood, MI Metro Area", "24340"],
  ["Grants, NM Micro Area", "24380"],
  ["Grants Pass, OR Metro Area", "24420"],
  ["Great Bend, KS Micro Area", "24460"],
  ["Great Falls, MT Metro Area", "24500"],
  ["Greeley, CO Metro Area", "24540"],
  ["Green Bay, WI Metro Area", "24580"],
  ["Greeneville, TN Micro Area", "24620"],
  ["Greensboro-High Point, NC Metro Area", "24660"],
  ["Greensburg, IN Micro Area", "24700"],
  ["Greenville, MS Micro Area", "24740"],
  ["Greenville, NC Metro Area", "24780"],
  ["Greenville, OH Micro Area", "24820"],
  ["Greenville-Anderson, SC Metro Area", "24860"],
  ["Greenwood, MS Micro Area", "24900"],
  ["Greenwood, SC Micro Area", "24940"],
  ["Grenada, MS Micro Area", "24980"],
  ["Guayama, PR Metro Area", "25020"],
  ["Gulfport-Biloxi, MS Metro Area", "25060"],
  ["Guymon, OK Micro Area", "25100"],
  ["Hagerstown-Martinsburg, MD-WV Metro Area", "25180"],
  ["Hailey, ID Micro Area", "25200"],
  ["Hammond, LA Metro Area", "25220"],
  ["Hanford-Corcoran, CA Metro Area", "25260"],
  ["Hannibal, MO Micro Area", "25300"],
  ["Harrisburg-Carlisle, PA Metro Area", "25420"],
  ["Harrison, AR Micro Area", "25460"],
  ["Harrisonburg, VA Metro Area", "25500"],
  ["Hartford-East Hartford-Middletown, CT Metro Area", "25540"],
  ["Hastings, NE Micro Area", "25580"],
  ["Hattiesburg, MS Metro Area", "25620"],
  ["Hays, KS Micro Area", "25700"],
  ["Heber, UT Micro Area", "25720"],
  ["Helena, MT Micro Area", "25740"],
  ["Helena-West Helena, AR Micro Area", "25760"],
  ["Henderson, NC Micro Area", "25780"],
  ["Hereford, TX Micro Area", "25820"],
  ["Hermiston-Pendleton, OR Micro Area", "25840"],
  ["Hickory-Lenoir-Morganton, NC Metro Area", "25860"],
  ["Hillsdale, MI Micro Area", "25880"],
  ["Hilo, HI Micro Area", "25900"],
  ["Hilton Head Island-Bluffton, SC Metro Area", "25940"],
  ["Hinesville, GA Metro Area", "25980"],
  ["Hobbs, NM Micro Area", "26020"],
  ["Holland, MI Micro Area", "26090"],
  ["Homosassa Springs, FL Metro Area", "26140"],
  ["Hood River, OR Micro Area", "26220"],
  ["Hope, AR Micro Area", "26260"],
  ["Hot Springs, AR Metro Area", "26300"],
  ["Houghton, MI Micro Area", "26340"],
  ["Houma-Thibodaux, LA Metro Area", "26380"],
  ["Houston-The Woodlands-Sugar Land, TX Metro Area", "26420"],
  ["Hudson, NY Micro Area", "26460"],
  ["Huntingdon, PA Micro Area", "26500"],
  ["Huntington, IN Micro Area", "26540"],
  ["Huntington-Ashland, WV-KY-OH Metro Area", "26580"],
  ["Huntsville, AL Metro Area", "26620"],
  ["Huntsville, TX Micro Area", "26660"],
  ["Huron, SD Micro Area", "26700"],
  ["Hutchinson, KS Micro Area", "26740"],
  ["Hutchinson, MN Micro Area", "26780"],
  ["Idaho Falls, ID Metro Area", "26820"],
  ["Indiana, PA Micro Area", "26860"],
  ["Indianapolis-Carmel-Anderson, IN Metro Area", "26900"],
  ["Indianola, MS Micro Area", "26940"],
  ["Iowa City, IA Metro Area", "26980"],
  ["Iron Mountain, MI-WI Micro Area", "27020"],
  ["Ithaca, NY Metro Area", "27060"],
  ["Jackson, MI Metro Area", "27100"],
  ["Jackson, MS Metro Area", "27140"],
  ["Jackson, OH Micro Area", "27160"],
  ["Jackson, TN Metro Area", "27180"],
  ["Jackson, WY-ID Micro Area", "27220"],
  ["Jacksonville, FL Metro Area", "27260"],
  ["Jacksonville, IL Micro Area", "27300"],
  ["Jacksonville, NC Metro Area", "27340"],
  ["Jacksonville, TX Micro Area", "27380"],
  ["Jamestown, ND Micro Area", "27420"],
  ["Jamestown-Dunkirk-Fredonia, NY Micro Area", "27460"],
  ["Janesville-Beloit, WI Metro Area", "27500"],
  ["Jasper, AL Micro Area", "27530"],
  ["Jasper, IN Micro Area", "27540"],
  ["Jayuya, PR Micro Area", "27580"],
  ["Jefferson, GA Micro Area", "27600"],
  ["Jefferson City, MO Metro Area", "27620"],
  ["Jennings, LA Micro Area", "27660"],
  ["Jesup, GA Micro Area", "27700"],
  ["Johnson City, TN Metro Area", "27740"],
  ["Johnstown, PA Metro Area", "27780"],
  ["Jonesboro, AR Metro Area", "27860"],
  ["Joplin, MO Metro Area", "27900"],
  ["Juneau, AK Micro Area", "27940"],
  ["Kahului-Wailuku-Lahaina, HI Metro Area", "27980"],
  ["Kalamazoo-Portage, MI Metro Area", "28020"],
  ["Kalispell, MT Micro Area", "28060"],
  ["Kankakee, IL Metro Area", "28100"],
  ["Kansas City, MO-KS Metro Area", "28140"],
  ["Kapaa, HI Micro Area", "28180"],
  ["Kearney, NE Micro Area", "28260"],
  ["Keene, NH Micro Area", "28300"],
  ["Kendallville, IN Micro Area", "28340"],
  ["Kennett, MO Micro Area", "28380"],
  ["Kennewick-Richland, WA Metro Area", "28420"],
  ["Kerrville, TX Micro Area", "28500"],
  ["Ketchikan, AK Micro Area", "28540"],
  ["Key West, FL Micro Area", "28580"],
  ["Kill Devil Hills, NC Micro Area", "28620"],
  ["Killeen-Temple, TX Metro Area", "28660"],
  ["Kingsport-Bristol, TN-VA Metro Area", "28700"],
  ["Kingston, NY Metro Area", "28740"],
  ["Kingsville, TX Micro Area", "28780"],
  ["Kinston, NC Micro Area", "28820"],
  ["Kirksville, MO Micro Area", "28860"],
  ["Klamath Falls, OR Micro Area", "28900"],
  ["Knoxville, TN Metro Area", "28940"],
  ["Kokomo, IN Metro Area", "29020"],
  ["Laconia, NH Micro Area", "29060"],
  ["La Crosse-Onalaska, WI-MN Metro Area", "29100"],
  ["Lafayette, LA Metro Area", "29180"],
  ["Lafayette-West Lafayette, IN Metro Area", "29200"],
  ["La Grande, OR Micro Area", "29260"],
  ["LaGrange, GA-AL Micro Area", "29300"],
  ["Lake Charles, LA Metro Area", "29340"],
  ["Lake City, FL Micro Area", "29380"],
  ["Lake Havasu City-Kingman, AZ Metro Area", "29420"],
  ["Lakeland-Winter Haven, FL Metro Area", "29460"],
  ["Lamesa, TX Micro Area", "29500"],
  ["Lancaster, PA Metro Area", "29540"],
  ["Lansing-East Lansing, MI Metro Area", "29620"],
  ["Laramie, WY Micro Area", "29660"],
  ["Laredo, TX Metro Area", "29700"],
  ["Las Cruces, NM Metro Area", "29740"],
  ["Las Vegas, NM Micro Area", "29780"],
  ["Las Vegas-Henderson-Paradise, NV Metro Area", "29820"],
  ["Laurel, MS Micro Area", "29860"],
  ["Laurinburg, NC Micro Area", "29900"],
  ["Lawrence, KS Metro Area", "29940"],
  ["Lawrenceburg, TN Micro Area", "29980"],
  ["Lawton, OK Metro Area", "30020"],
  ["Lebanon, MO Micro Area", "30060"],
  ["Lebanon, NH-VT Micro Area", "30100"],
  ["Lebanon, PA Metro Area", "30140"],
  ["Levelland, TX Micro Area", "30220"],
  ["Lewisburg, PA Micro Area", "30260"],
  ["Lewisburg, TN Micro Area", "30280"],
  ["Lewiston, ID-WA Metro Area", "30300"],
  ["Lewiston-Auburn, ME Metro Area", "30340"],
  ["Lewistown, PA Micro Area", "30380"],
  ["Lexington, NE Micro Area", "30420"],
  ["Lexington-Fayette, KY Metro Area", "30460"],
  ["Liberal, KS Micro Area", "30580"],
  ["Lima, OH Metro Area", "30620"],
  ["Lincoln, IL Micro Area", "30660"],
  ["Lincoln, NE Metro Area", "30700"],
  ["Little Rock-North Little Rock-Conway, AR Metro Area", "30780"],
  ["Lock Haven, PA Micro Area", "30820"],
  ["Logan, UT-ID Metro Area", "30860"],
  ["Logansport, IN Micro Area", "30900"],
  ["London, KY Micro Area", "30940"],
  ["Longview, TX Metro Area", "30980"],
  ["Longview, WA Metro Area", "31020"],
  ["Los Alamos, NM Micro Area", "31060"],
  ["Los Angeles-Long Beach-Anaheim, CA Metro Area", "31080"],
  ["Louisville/Jefferson County, KY-IN Metro Area", "31140"],
  ["Lubbock, TX Metro Area", "31180"],
  ["Ludington, MI Micro Area", "31220"],
  ["Lufkin, TX Micro Area", "31260"],
  ["Lumberton, NC Micro Area", "31300"],
  ["Lynchburg, VA Metro Area", "31340"],
  ["Macomb, IL Micro Area", "31380"],
  ["Macon-Bibb County, GA Metro Area", "31420"],
  ["Madera, CA Metro Area", "31460"],
  ["Madison, IN Micro Area", "31500"],
  ["Madison, WI Metro Area", "31540"],
  ["Madisonville, KY Micro Area", "31580"],
  ["Magnolia, AR Micro Area", "31620"],
  ["Malone, NY Micro Area", "31660"],
  ["Malvern, AR Micro Area", "31680"],
  ["Manchester-Nashua, NH Metro Area", "31700"],
  ["Manhattan, KS Metro Area", "31740"],
  ["Manitowoc, WI Micro Area", "31820"],
  ["Mankato, MN Metro Area", "31860"],
  ["Mansfield, OH Metro Area", "31900"],
  ["Marietta, OH Micro Area", "31930"],
  ["Marinette, WI-MI Micro Area", "31940"],
  ["Marion, IN Micro Area", "31980"],
  ["Marion, NC Micro Area", "32000"],
  ["Marion, OH Micro Area", "32020"],
  ["Marquette, MI Micro Area", "32100"],
  ["Marshall, MN Micro Area", "32140"],
  ["Marshall, MO Micro Area", "32180"],
  ["Marshalltown, IA Micro Area", "32260"],
  ["Martin, TN Micro Area", "32280"],
  ["Martinsville, VA Micro Area", "32300"],
  ["Maryville, MO Micro Area", "32340"],
  ["Mason City, IA Micro Area", "32380"],
  ["Mayagüez, PR Metro Area", "32420"],
  ["Mayfield, KY Micro Area", "32460"],
  ["Maysville, KY Micro Area", "32500"],
  ["McAlester, OK Micro Area", "32540"],
  ["McAllen-Edinburg-Mission, TX Metro Area", "32580"],
  ["McComb, MS Micro Area", "32620"],
  ["McMinnville, TN Micro Area", "32660"],
  ["McPherson, KS Micro Area", "32700"],
  ["Meadville, PA Micro Area", "32740"],
  ["Medford, OR Metro Area", "32780"],
  ["Memphis, TN-MS-AR Metro Area", "32820"],
  ["Menomonie, WI Micro Area", "32860"],
  ["Merced, CA Metro Area", "32900"],
  ["Meridian, MS Micro Area", "32940"],
  ["Mexico, MO Micro Area", "33020"],
  ["Miami, OK Micro Area", "33060"],
  ["Miami-Fort Lauderdale-Pompano Beach, FL Metro Area", "33100"],
  ["Michigan City-La Porte, IN Metro Area", "33140"],
  ["Middlesborough, KY Micro Area", "33180"],
  ["Midland, MI Metro Area", "33220"],
  ["Midland, TX Metro Area", "33260"],
  ["Milledgeville, GA Micro Area", "33300"],
  ["Milwaukee-Waukesha, WI Metro Area", "33340"],
  ["Minden, LA Micro Area", "33380"],
  ["Mineral Wells, TX Micro Area", "33420"],
  ["Minneapolis-St. Paul-Bloomington, MN-WI Metro Area", "33460"],
  ["Minot, ND Micro Area", "33500"],
  ["Missoula, MT Metro Area", "33540"],
  ["Mitchell, SD Micro Area", "33580"],
  ["Moberly, MO Micro Area", "33620"],
  ["Mobile, AL Metro Area", "33660"],
  ["Modesto, CA Metro Area", "33700"],
  ["Monroe, LA Metro Area", "33740"],
  ["Monroe, MI Metro Area", "33780"],
  ["Montgomery, AL Metro Area", "33860"],
  ["Montrose, CO Micro Area", "33940"],
  ["Morehead City, NC Micro Area", "33980"],
  ["Morgan City, LA Micro Area", "34020"],
  ["Morgantown, WV Metro Area", "34060"],
  ["Morristown, TN Metro Area", "34100"],
  ["Moscow, ID Micro Area", "34140"],
  ["Moses Lake, WA Micro Area", "34180"],
  ["Moultrie, GA Micro Area", "34220"],
  ["Mountain Home, AR Micro Area", "34260"],
  ["Mountain Home, ID Micro Area", "34300"],
  ["Mount Airy, NC Micro Area", "34340"],
  ["Mount Gay-Shamrock, WV Micro Area", "34350"],
  ["Mount Pleasant, MI Micro Area", "34380"],
  ["Mount Pleasant, TX Micro Area", "34420"],
  ["Mount Sterling, KY Micro Area", "34460"],
  ["Mount Vernon, IL Micro Area", "34500"],
  ["Mount Vernon, OH Micro Area", "34540"],
  ["Mount Vernon-Anacortes, WA Metro Area", "34580"],
  ["Muncie, IN Metro Area", "34620"],
  ["Murray, KY Micro Area", "34660"],
  ["Muscatine, IA Micro Area", "34700"],
  ["Muskegon, MI Metro Area", "34740"],
  ["Muskogee, OK Micro Area", "34780"],
  ["Myrtle Beach-Conway-North Myrtle Beach, SC-NC Metro Area", "34820"],
  ["Nacogdoches, TX Micro Area", "34860"],
  ["Napa, CA Metro Area", "34900"],
  ["Naples-Marco Island, FL Metro Area", "34940"],
  ["Nashville-Davidson--Murfreesboro--Franklin, TN Metro Area", "34980"],
  ["Natchez, MS-LA Micro Area", "35020"],
  ["Natchitoches, LA Micro Area", "35060"],
  ["New Bern, NC Metro Area", "35100"],
  ["Newberry, SC Micro Area", "35140"],
  ["New Castle, IN Micro Area", "35220"],
  ["New Castle, PA Micro Area", "35260"],
  ["New Haven-Milford, CT Metro Area", "35300"],
  ["New Orleans-Metairie, LA Metro Area", "35380"],
  ["New Philadelphia-Dover, OH Micro Area", "35420"],
  ["Newport, OR Micro Area", "35440"],
  ["Newport, TN Micro Area", "35460"],
  ["New Ulm, MN Micro Area", "35580"],
  ["New York-Newark-Jersey City, NY-NJ-PA Metro Area", "35620"],
  ["Niles, MI Metro Area", "35660"],
  ["Nogales, AZ Micro Area", "35700"],
  ["Norfolk, NE Micro Area", "35740"],
  ["North Platte, NE Micro Area", "35820"],
  ["North Port-Sarasota-Bradenton, FL Metro Area", "35840"],
  ["North Vernon, IN Micro Area", "35860"],
  ["North Wilkesboro, NC Micro Area", "35900"],
  ["Norwalk, OH Micro Area", "35940"],
  ["Norwich-New London, CT Metro Area", "35980"],
  ["Oak Harbor, WA Micro Area", "36020"],
  ["Ocala, FL Metro Area", "36100"],
  ["Ocean City, NJ Metro Area", "36140"],
  ["Odessa, TX Metro Area", "36220"],
  ["Ogden-Clearfield, UT Metro Area", "36260"],
  ["Ogdensburg-Massena, NY Micro Area", "36300"],
  ["Oil City, PA Micro Area", "36340"],
  ["Okeechobee, FL Micro Area", "36380"],
  ["Oklahoma City, OK Metro Area", "36420"],
  ["Olean, NY Micro Area", "36460"],
  ["Olympia-Lacey-Tumwater, WA Metro Area", "36500"],
  ["Omaha-Council Bluffs, NE-IA Metro Area", "36540"],
  ["Oneonta, NY Micro Area", "36580"],
  ["Ontario, OR-ID Micro Area", "36620"],
  ["Opelousas, LA Micro Area", "36660"],
  ["Orangeburg, SC Micro Area", "36700"],
  ["Orlando-Kissimmee-Sanford, FL Metro Area", "36740"],
  ["Oshkosh-Neenah, WI Metro Area", "36780"],
  ["Oskaloosa, IA Micro Area", "36820"],
  ["Othello, WA Micro Area", "36830"],
  ["Ottawa, IL Micro Area", "36837"],
  ["Ottawa, KS Micro Area", "36840"],
  ["Ottumwa, IA Micro Area", "36900"],
  ["Owatonna, MN Micro Area", "36940"],
  ["Owensboro, KY Metro Area", "36980"],
  ["Oxford, MS Micro Area", "37060"],
  ["Oxnard-Thousand Oaks-Ventura, CA Metro Area", "37100"],
  ["Ozark, AL Micro Area", "37120"],
  ["Paducah, KY-IL Micro Area", "37140"],
  ["Pahrump, NV Micro Area", "37220"],
  ["Palatka, FL Micro Area", "37260"],
  ["Palestine, TX Micro Area", "37300"],
  ["Palm Bay-Melbourne-Titusville, FL Metro Area", "37340"],
  ["Pampa, TX Micro Area", "37420"],
  ["Panama City, FL Metro Area", "37460"],
  ["Paragould, AR Micro Area", "37500"],
  ["Paris, TN Micro Area", "37540"],
  ["Paris, TX Micro Area", "37580"],
  ["Parkersburg-Vienna, WV Metro Area", "37620"],
  ["Parsons, KS Micro Area", "37660"],
  ["Payson, AZ Micro Area", "37740"],
  ["Pearsall, TX Micro Area", "37770"],
  ["Pecos, TX Micro Area", "37780"],
  ["Pella, IA Micro Area", "37800"],
  ["Pensacola-Ferry Pass-Brent, FL Metro Area", "37860"],
  ["Peoria, IL Metro Area", "37900"],
  ["Peru, IN Micro Area", "37940"],
  ["Philadelphia-Camden-Wilmington, PA-NJ-DE-MD Metro Area", "37980"],
  ["Phoenix-Mesa-Chandler, AZ Metro Area", "38060"],
  ["Picayune, MS Micro Area", "38100"],
  ["Pierre, SD Micro Area", "38180"],
  ["Pine Bluff, AR Metro Area", "38220"],
  ["Pinehurst-Southern Pines, NC Micro Area", "38240"],
  ["Pittsburg, KS Micro Area", "38260"],
  ["Pittsburgh, PA Metro Area", "38300"],
  ["Pittsfield, MA Metro Area", "38340"],
  ["Plainview, TX Micro Area", "38380"],
  ["Platteville, WI Micro Area", "38420"],
  ["Plattsburgh, NY Micro Area", "38460"],
  ["Plymouth, IN Micro Area", "38500"],
  ["Pocatello, ID Metro Area", "38540"],
  ["Point Pleasant, WV-OH Micro Area", "38580"],
  ["Ponca City, OK Micro Area", "38620"],
  ["Ponce, PR Metro Area", "38660"],
  ["Pontiac, IL Micro Area", "38700"],
  ["Poplar Bluff, MO Micro Area", "38740"],
  ["Portales, NM Micro Area", "38780"],
  ["Port Angeles, WA Micro Area", "38820"],
  ["Portland-South Portland, ME Metro Area", "38860"],
  ["Portland-Vancouver-Hillsboro, OR-WA Metro Area", "38900"],
  ["Port Lavaca, TX Micro Area", "38920"],
  ["Port St. Lucie, FL Metro Area", "38940"],
  ["Portsmouth, OH Micro Area", "39020"],
  ["Pottsville, PA Micro Area", "39060"],
  ["Poughkeepsie-Newburgh-Middletown, NY Metro Area", "39100"],
  ["Prescott Valley-Prescott, AZ Metro Area", "39150"],
  ["Price, UT Micro Area", "39220"],
  ["Prineville, OR Micro Area", "39260"],
  ["Providence-Warwick, RI-MA Metro Area", "39300"],
  ["Provo-Orem, UT Metro Area", "39340"],
  ["Pueblo, CO Metro Area", "39380"],
  ["Pullman, WA Micro Area", "39420"],
  ["Punta Gorda, FL Metro Area", "39460"],
  ["Quincy, IL-MO Micro Area", "39500"],
  ["Racine, WI Metro Area", "39540"],
  ["Raleigh-Cary, NC Metro Area", "39580"],
  ["Rapid City, SD Metro Area", "39660"],
  ["Raymondville, TX Micro Area", "39700"],
  ["Reading, PA Metro Area", "39740"],
  ["Red Bluff, CA Micro Area", "39780"],
  ["Redding, CA Metro Area", "39820"],
  ["Red Wing, MN Micro Area", "39860"],
  ["Reno, NV Metro Area", "39900"],
  ["Rexburg, ID Micro Area", "39940"],
  ["Richmond, IN Micro Area", "39980"],
  ["Richmond, VA Metro Area", "40060"],
  ["Richmond-Berea, KY Micro Area", "40080"],
  ["Rio Grande City-Roma, TX Micro Area", "40100"],
  ["Riverside-San Bernardino-Ontario, CA Metro Area", "40140"],
  ["Riverton, WY Micro Area", "40180"],
  ["Roanoke, VA Metro Area", "40220"],
  ["Roanoke Rapids, NC Micro Area", "40260"],
  ["Rochelle, IL Micro Area", "40300"],
  ["Rochester, MN Metro Area", "40340"],
  ["Rochester, NY Metro Area", "40380"],
  ["Rock Springs, WY Micro Area", "40540"],
  ["Rockford, IL Metro Area", "40420"],
  ["Rockingham, NC Micro Area", "40460"],
  ["Rockport, TX Micro Area", "40530"],
  ["Rocky Mount, NC Metro Area", "40580"],
  ["Rolla, MO Micro Area", "40620"],
  ["Rome, GA Metro Area", "40660"],
  ["Roseburg, OR Micro Area", "40700"],
  ["Roswell, NM Micro Area", "40740"],
  ["Ruidoso, NM Micro Area", "40760"],
  ["Russellville, AR Micro Area", "40780"],
  ["Ruston, LA Micro Area", "40820"],
  ["Rutland, VT Micro Area", "40860"],
  ["Sacramento-Roseville-Folsom, CA Metro Area", "40900"],
  ["Safford, AZ Micro Area", "40940"],
  ["Saginaw, MI Metro Area", "40980"],
  ["St. Cloud, MN Metro Area", "41060"],
  ["St. George, UT Metro Area", "41100"],
  ["St. Joseph, MO-KS Metro Area", "41140"],
  ["St. Louis, MO-IL Metro Area", "41180"],
  ["St. Marys, GA Micro Area", "41220"],
  ["St. Marys, PA Micro Area", "41260"],
  ["Salem, OH Micro Area", "41400"],
  ["Salem, OR Metro Area", "41420"],
  ["Salina, KS Micro Area", "41460"],
  ["Salinas, CA Metro Area", "41500"],
  ["Salisbury, MD-DE Metro Area", "41540"],
  ["Salt Lake City, UT Metro Area", "41620"],
  ["San Angelo, TX Metro Area", "41660"],
  ["San Antonio-New Braunfels, TX Metro Area", "41700"],
  ["San Diego-Chula Vista-Carlsbad, CA Metro Area", "41740"],
  ["Sandpoint, ID Micro Area", "41760"],
  ["Sandusky, OH Micro Area", "41780"],
  ["Sanford, NC Micro Area", "41820"],
  ["San Francisco-Oakland-Berkeley, CA Metro Area", "41860"],
  ["San Germán, PR Metro Area", "41900"],
  ["San Jose-Sunnyvale-Santa Clara, CA Metro Area", "41940"],
  ["San Juan-Bayamón-Caguas, PR Metro Area", "41980"],
  ["San Luis Obispo-Paso Robles, CA Metro Area", "42020"],
  ["Santa Cruz-Watsonville, CA Metro Area", "42100"],
  ["Santa Fe, NM Metro Area", "42140"],
  ["Santa Isabel, PR Micro Area", "42180"],
  ["Santa Maria-Santa Barbara, CA Metro Area", "42200"],
  ["Santa Rosa-Petaluma, CA Metro Area", "42220"],
  ["Sault Ste. Marie, MI Micro Area", "42300"],
  ["Savannah, GA Metro Area", "42340"],
  ["Sayre, PA Micro Area", "42380"],
  ["Scottsbluff, NE Micro Area", "42420"],
  ["Scottsboro, AL Micro Area", "42460"],
  ["Scottsburg, IN Micro Area", "42500"],
  ["Scranton--Wilkes-Barre, PA Metro Area", "42540"],
  ["Searcy, AR Micro Area", "42620"],
  ["Seattle-Tacoma-Bellevue, WA Metro Area", "42660"],
  ["Sebastian-Vero Beach, FL Metro Area", "42680"],
  ["Sebring-Avon Park, FL Metro Area", "42700"],
  ["Sedalia, MO Micro Area", "42740"],
  ["Selinsgrove, PA Micro Area", "42780"],
  ["Selma, AL Micro Area", "42820"],
  ["Seneca, SC Micro Area", "42860"],
  ["Seneca Falls, NY Micro Area", "42900"],
  ["Sevierville, TN Micro Area", "42940"],
  ["Seymour, IN Micro Area", "42980"],
  ["Shawano, WI Micro Area", "43020"],
  ["Shawnee, OK Micro Area", "43060"],
  ["Sheboygan, WI Metro Area", "43100"],
  ["Shelby, NC Micro Area", "43140"],
  ["Shelbyville, TN Micro Area", "43180"],
  ["Shelton, WA Micro Area", "43220"],
  ["Sheridan, WY Micro Area", "43260"],
  ["Sherman-Denison, TX Metro Area", "43300"],
  ["Show Low, AZ Micro Area", "43320"],
  ["Shreveport-Bossier City, LA Metro Area", "43340"],
  ["Sidney, OH Micro Area", "43380"],
  ["Sierra Vista-Douglas, AZ Metro Area", "43420"],
  ["Sikeston, MO Micro Area", "43460"],
  ["Silver City, NM Micro Area", "43500"],
  ["Sioux City, IA-NE-SD Metro Area", "43580"],
  ["Sioux Falls, SD Metro Area", "43620"],
  ["Snyder, TX Micro Area", "43660"],
  ["Somerset, KY Micro Area", "43700"],
  ["Somerset, PA Micro Area", "43740"],
  ["Sonora, CA Micro Area", "43760"],
  ["South Bend-Mishawaka, IN-MI Metro Area", "43780"],
  ["Spartanburg, SC Metro Area", "43900"],
  ["Spearfish, SD Micro Area", "43940"],
  ["Spencer, IA Micro Area", "43980"],
  ["Spirit Lake, IA Micro Area", "44020"],
  ["Spokane-Spokane Valley, WA Metro Area", "44060"],
  ["Springfield, IL Metro Area", "44100"],
  ["Springfield, MA Metro Area", "44140"],
  ["Springfield, MO Metro Area", "44180"],
  ["Springfield, OH Metro Area", "44220"],
  ["Starkville, MS Micro Area", "44260"],
  ["State College, PA Metro Area", "44300"],
  ["Statesboro, GA Micro Area", "44340"],
  ["Staunton, VA Metro Area", "44420"],
  ["Steamboat Springs, CO Micro Area", "44460"],
  ["Stephenville, TX Micro Area", "44500"],
  ["Sterling, CO Micro Area", "44540"],
  ["Sterling, IL Micro Area", "44580"],
  ["Stevens Point, WI Micro Area", "44620"],
  ["Stillwater, OK Micro Area", "44660"],
  ["Stockton, CA Metro Area", "44700"],
  ["Storm Lake, IA Micro Area", "44740"],
  ["Sturgis, MI Micro Area", "44780"],
  ["Sulphur Springs, TX Micro Area", "44860"],
  ["Summerville, GA Micro Area", "44900"],
  ["Sumter, SC Metro Area", "44940"],
  ["Sunbury, PA Micro Area", "44980"],
  ["Susanville, CA Micro Area", "45000"],
  ["Sweetwater, TX Micro Area", "45020"],
  ["Syracuse, NY Metro Area", "45060"],
  ["Tahlequah, OK Micro Area", "45140"],
  ["Talladega-Sylacauga, AL Micro Area", "45180"],
  ["Tallahassee, FL Metro Area", "45220"],
  ["Tampa-St. Petersburg-Clearwater, FL Metro Area", "45300"],
  ["Texarkana, TX-AR Metro Area", "45500"],
  ["Taos, NM Micro Area", "45340"],
  ["Taylorville, IL Micro Area", "45380"],
  ["Terre Haute, IN Metro Area", "45460"],
  ["The Dalles, OR Micro Area", "45520"],
  ["The Villages, FL Metro Area", "45540"],
  ["Thomaston, GA Micro Area", "45580"],
  ["Thomasville, GA Micro Area", "45620"],
  ["Tiffin, OH Micro Area", "45660"],
  ["Tifton, GA Micro Area", "45700"],
  ["Toccoa, GA Micro Area", "45740"],
  ["Toledo, OH Metro Area", "45780"],
  ["Topeka, KS Metro Area", "45820"],
  ["Torrington, CT Micro Area", "45860"],
  ["Traverse City, MI Micro Area", "45900"],
  ["Trenton-Princeton, NJ Metro Area", "45940"],
  ["Troy, AL Micro Area", "45980"],
  ["Truckee-Grass Valley, CA Micro Area", "46020"],
  ["Tucson, AZ Metro Area", "46060"],
  ["Tullahoma-Manchester, TN Micro Area", "46100"],
  ["Tulsa, OK Metro Area", "46140"],
  ["Tupelo, MS Micro Area", "46180"],
  ["Tuscaloosa, AL Metro Area", "46220"],
  ["Twin Falls, ID Metro Area", "46300"],
  ["Tyler, TX Metro Area", "46340"],
  ["Ukiah, CA Micro Area", "46380"],
  ["Union, SC Micro Area", "46420"],
  ["Union City, TN Micro Area", "46460"],
  ["Urbana, OH Micro Area", "46500"],
  ["Urban Honolulu, HI Metro Area", "46520"],
  ["Utica-Rome, NY Metro Area", "46540"],
  ["Uvalde, TX Micro Area", "46620"],
  ["Valdosta, GA Metro Area", "46660"],
  ["Vallejo, CA Metro Area", "46700"],
  ["Van Wert, OH Micro Area", "46780"],
  ["Vermillion, SD Micro Area", "46820"],
  ["Vernal, UT Micro Area", "46860"],
  ["Vernon, TX Micro Area", "46900"],
  ["Vicksburg, MS Micro Area", "46980"],
  ["Victoria, TX Metro Area", "47020"],
  ["Vidalia, GA Micro Area", "47080"],
  ["Vincennes, IN Micro Area", "47180"],
  ["Vineland-Bridgeton, NJ Metro Area", "47220"],
  ["Vineyard Haven, MA Micro Area", "47240"],
  ["Virginia Beach-Norfolk-Newport News, VA-NC Metro Area", "47260"],
  ["Visalia, CA Metro Area", "47300"],
  ["Wabash, IN Micro Area", "47340"],
  ["Waco, TX Metro Area", "47380"],
  ["Wahpeton, ND-MN Micro Area", "47420"],
  ["Walla Walla, WA Metro Area", "47460"],
  ["Wapakoneta, OH Micro Area", "47540"],
  ["Warner Robins, GA Metro Area", "47580"],
  ["Warren, PA Micro Area", "47620"],
  ["Warrensburg, MO Micro Area", "47660"],
  ["Warsaw, IN Micro Area", "47700"],
  ["Washington, IN Micro Area", "47780"],
  ["Washington, NC Micro Area", "47820"],
  ["Washington-Arlington-Alexandria, DC-VA-MD-WV Metro Area", "47900"],
  ["Washington Court House, OH Micro Area", "47920"],
  ["Waterloo-Cedar Falls, IA Metro Area", "47940"],
  ["Watertown, SD Micro Area", "47980"],
  ["Watertown-Fort Atkinson, WI Micro Area", "48020"],
  ["Watertown-Fort Drum, NY Metro Area", "48060"],
  ["Wauchula, FL Micro Area", "48100"],
  ["Wausau-Weston, WI Metro Area", "48140"],
  ["Waycross, GA Micro Area", "48180"],
  ["Weatherford, OK Micro Area", "48220"],
  ["Weirton-Steubenville, WV-OH Metro Area", "48260"],
  ["Wenatchee, WA Metro Area", "48300"],
  ["West Plains, MO Micro Area", "48460"],
  ["West Point, MS Micro Area", "48500"],
  ["Wheeling, WV-OH Metro Area", "48540"],
  ["Whitewater, WI Micro Area", "48580"],
  ["Wichita, KS Metro Area", "48620"],
  ["Wichita Falls, TX Metro Area", "48660"],
  ["Williamsport, PA Metro Area", "48700"],
  ["Williston, ND Micro Area", "48780"],
  ["Willmar, MN Micro Area", "48820"],
  ["Wilmington, NC Metro Area", "48900"],
  ["Wilmington, OH Micro Area", "48940"],
  ["Wilson, NC Micro Area", "48980"],
  ["Winchester, VA-WV Metro Area", "49020"],
  ["Winfield, KS Micro Area", "49060"],
  ["Winnemucca, NV Micro Area", "49080"],
  ["Winona, MN Micro Area", "49100"],
  ["Winston-Salem, NC Metro Area", "49180"],
  ["Wisconsin Rapids-Marshfield, WI Micro Area", "49220"],
  ["Woodward, OK Micro Area", "49260"],
  ["Wooster, OH Micro Area", "49300"],
  ["Worcester, MA-CT Metro Area", "49340"],
  ["Worthington, MN Micro Area", "49380"],
  ["Yakima, WA Metro Area", "49420"],
  ["Yankton, SD Micro Area", "49460"],
  ["Yauco, PR Metro Area", "49500"],
  ["York-Hanover, PA Metro Area", "49620"],
  ["Youngstown-Warren-Boardman, OH-PA Metro Area", "49660"],
  ["Yuba City, CA Metro Area", "49700"],
  ["Yuma, AZ Metro Area", "49740"],
  ["Zanesville, OH Micro Area", "49780"],
  ["Zapata, TX Micro Area", "49820"],
];

/**
 * A map where the keys are the ZIP codes and values are the county FIPS codes.
 *
 * @var {Record<string, string>}
 */
export const zipCountyMap = {
  10001: "36061",
  10002: "36061",
  10003: "36061",
  10004: "36061",
  10005: "36061",
  10006: "36061",
  10007: "36061",
  10009: "36061",
  1001: "25013",
  10010: "36061",
  10011: "36061",
  10012: "36061",
  10013: "36061",
  10014: "36061",
  10016: "36061",
  10017: "36061",
  10018: "36061",
  10019: "36061",
  1002: "25015",
  10021: "36061",
  10022: "36061",
  10023: "36061",
  10024: "36061",
  10025: "36061",
  10026: "36061",
  10027: "36061",
  10028: "36061",
  10029: "36061",
  10030: "36061",
  10031: "36061",
  10032: "36061",
  10033: "36061",
  10034: "36061",
  10035: "36061",
  10036: "36061",
  10037: "36061",
  10039: "36061",
  10040: "36061",
  10044: "36061",
  1005: "25027",
  10065: "36061",
  10069: "36061",
  1007: "25015",
  10075: "36061",
  1008: "25013",
  1010: "25013",
  1011: "25013",
  1012: "25015",
  10128: "36061",
  1013: "25013",
  1020: "25013",
  1022: "25013",
  1026: "25015",
  1027: "25015",
  1028: "25013",
  10280: "36061",
  10282: "36061",
  1030: "25013",
  10301: "36085",
  10302: "36085",
  10303: "36085",
  10304: "36085",
  10305: "36085",
  10306: "36085",
  10307: "36085",
  10308: "36085",
  10309: "36085",
  1031: "25027",
  10310: "36085",
  10312: "36085",
  10314: "36085",
  1032: "25015",
  1033: "25015",
  1034: "25013",
  1035: "25015",
  1036: "25013",
  1037: "25027",
  1038: "25015",
  1039: "25015",
  1040: "25013",
  10451: "36005",
  10452: "36005",
  10453: "36005",
  10454: "36005",
  10455: "36005",
  10456: "36005",
  10457: "36005",
  10458: "36005",
  10459: "36005",
  10460: "36005",
  10461: "36005",
  10462: "36005",
  10463: "36005",
  10464: "36005",
  10465: "36005",
  10466: "36005",
  10467: "36005",
  10468: "36005",
  10469: "36005",
  10470: "36005",
  10471: "36005",
  10472: "36005",
  10473: "36005",
  1050: "25013",
  10501: "36119",
  10502: "36119",
  10504: "36119",
  10505: "36119",
  10506: "36119",
  10507: "36119",
  10509: "36079",
  10510: "36119",
  10511: "36119",
  10512: "36079",
  10514: "36119",
  10516: "36079",
  10520: "36119",
  10522: "36119",
  10523: "36119",
  10524: "36079",
  10526: "36119",
  10527: "36119",
  10528: "36119",
  1053: "25015",
  10530: "36119",
  10532: "36119",
  10533: "36119",
  10536: "36119",
  10537: "36079",
  10538: "36119",
  1054: "25011",
  10541: "36079",
  10543: "36119",
  10546: "36119",
  10547: "36119",
  10548: "36119",
  10549: "36119",
  10550: "36119",
  10552: "36119",
  10553: "36119",
  1056: "25013",
  10560: "36119",
  10562: "36119",
  10566: "36119",
  10567: "36119",
  1057: "25013",
  10570: "36119",
  10573: "36119",
  10576: "36119",
  10577: "36119",
  10578: "36119",
  10579: "36079",
  10580: "36119",
  10583: "36119",
  10588: "36119",
  10589: "36119",
  10590: "36119",
  10591: "36119",
  10594: "36119",
  10595: "36119",
  10596: "36119",
  10597: "36119",
  10598: "36119",
  1060: "25015",
  10601: "36119",
  10603: "36119",
  10604: "36119",
  10605: "36119",
  10606: "36119",
  10607: "36119",
  1062: "25015",
  1068: "25027",
  1069: "25013",
  1070: "25015",
  10701: "36119",
  10703: "36119",
  10704: "36119",
  10705: "36119",
  10706: "36119",
  10707: "36119",
  10708: "36119",
  10709: "36119",
  1071: "25013",
  10710: "36119",
  1072: "25011",
  1073: "25015",
  1075: "25015",
  1077: "25013",
  1080: "25013",
  10801: "36119",
  10803: "36119",
  10804: "36119",
  10805: "36119",
  1081: "25013",
  1082: "25015",
  1083: "25027",
  1085: "25013",
  1088: "25015",
  1089: "25013",
  10901: "36087",
  10913: "36087",
  10914: "36071",
  10916: "36071",
  10917: "36071",
  10918: "36071",
  10919: "36071",
  1092: "25027",
  10920: "36087",
  10921: "36071",
  10922: "36071",
  10923: "36087",
  10924: "36071",
  10925: "36071",
  10926: "36071",
  10927: "36087",
  10928: "36071",
  1093: "25011",
  10930: "36071",
  10931: "36087",
  10940: "36071",
  10941: "36071",
  1095: "25013",
  10950: "36071",
  10952: "36087",
  10954: "36087",
  10956: "36087",
  10958: "36071",
  1096: "25015",
  10960: "36087",
  10962: "36087",
  10963: "36071",
  10964: "36087",
  10965: "36087",
  10968: "36087",
  10969: "36071",
  10970: "36087",
  10973: "36071",
  10974: "36087",
  10976: "36087",
  10977: "36087",
  1098: "25015",
  10980: "36087",
  10983: "36087",
  10984: "36087",
  10986: "36087",
  10987: "36071",
  10988: "36071",
  10989: "36087",
  10990: "36071",
  10992: "36071",
  10993: "36087",
  10994: "36087",
  10998: "36071",
  11001: "36059",
  11003: "36059",
  11004: "36081",
  11010: "36059",
  11020: "36059",
  11021: "36059",
  11023: "36059",
  11024: "36059",
  1103: "25013",
  11030: "36059",
  1104: "25013",
  11040: "36059",
  1105: "25013",
  11050: "36059",
  1106: "25013",
  1107: "25013",
  1108: "25013",
  1109: "25013",
  11096: "36059",
  11101: "36081",
  11102: "36081",
  11103: "36081",
  11104: "36081",
  11105: "36081",
  11106: "36081",
  1118: "25013",
  1119: "25013",
  11201: "36047",
  11203: "36047",
  11204: "36047",
  11205: "36047",
  11206: "36047",
  11207: "36047",
  11208: "36047",
  11209: "36047",
  11210: "36047",
  11211: "36047",
  11212: "36047",
  11213: "36047",
  11214: "36047",
  11215: "36047",
  11216: "36047",
  11217: "36047",
  11218: "36047",
  11219: "36047",
  11220: "36047",
  11221: "36047",
  11222: "36047",
  11223: "36047",
  11224: "36047",
  11225: "36047",
  11226: "36047",
  11228: "36047",
  11229: "36047",
  11230: "36047",
  11231: "36047",
  11232: "36047",
  11233: "36047",
  11234: "36047",
  11235: "36047",
  11236: "36047",
  11237: "36047",
  11238: "36047",
  11239: "36047",
  11249: "36047",
  1128: "25013",
  1129: "25013",
  11354: "36081",
  11355: "36081",
  11356: "36081",
  11357: "36081",
  11358: "36081",
  11360: "36081",
  11361: "36081",
  11362: "36081",
  11363: "36081",
  11364: "36081",
  11365: "36081",
  11366: "36081",
  11367: "36081",
  11368: "36081",
  11369: "36081",
  11370: "36081",
  11372: "36081",
  11373: "36081",
  11374: "36081",
  11375: "36081",
  11377: "36081",
  11378: "36081",
  11379: "36081",
  11385: "36081",
  11411: "36081",
  11412: "36081",
  11413: "36081",
  11414: "36081",
  11415: "36081",
  11416: "36081",
  11417: "36081",
  11418: "36081",
  11419: "36081",
  11420: "36081",
  11421: "36081",
  11422: "36081",
  11423: "36081",
  11426: "36081",
  11427: "36081",
  11428: "36081",
  11429: "36081",
  11432: "36081",
  11433: "36081",
  11434: "36081",
  11435: "36081",
  11436: "36081",
  11501: "36059",
  11507: "36059",
  11509: "36059",
  1151: "25013",
  11510: "36059",
  11514: "36059",
  11516: "36059",
  11518: "36059",
  11520: "36059",
  11530: "36059",
  11542: "36059",
  11545: "36059",
  11547: "36059",
  11548: "36059",
  11550: "36059",
  11552: "36059",
  11553: "36059",
  11554: "36059",
  11557: "36059",
  11558: "36059",
  11559: "36059",
  11560: "36059",
  11561: "36059",
  11563: "36059",
  11565: "36059",
  11566: "36059",
  11568: "36059",
  11569: "36059",
  11570: "36059",
  11572: "36059",
  11575: "36059",
  11576: "36059",
  11577: "36059",
  11579: "36059",
  11580: "36059",
  11581: "36059",
  11590: "36059",
  11596: "36059",
  11598: "36059",
  11691: "36081",
  11692: "36081",
  11693: "36081",
  11694: "36081",
  11701: "36103",
  11702: "36103",
  11703: "36103",
  11704: "36103",
  11705: "36103",
  11706: "36103",
  11709: "36059",
  11710: "36059",
  11713: "36103",
  11714: "36059",
  11715: "36103",
  11716: "36103",
  11717: "36103",
  11718: "36103",
  11719: "36103",
  11720: "36103",
  11721: "36103",
  11722: "36103",
  11724: "36103",
  11725: "36103",
  11726: "36103",
  11727: "36103",
  11729: "36103",
  11730: "36103",
  11731: "36103",
  11732: "36059",
  11733: "36103",
  11735: "36059",
  11738: "36103",
  11739: "36103",
  11740: "36103",
  11741: "36103",
  11742: "36103",
  11743: "36103",
  11746: "36103",
  11747: "36103",
  11749: "36103",
  11751: "36103",
  11752: "36103",
  11753: "36059",
  11754: "36103",
  11755: "36103",
  11756: "36059",
  11757: "36103",
  11758: "36059",
  11762: "36059",
  11763: "36103",
  11764: "36103",
  11765: "36059",
  11766: "36103",
  11767: "36103",
  11768: "36103",
  11769: "36103",
  11770: "36103",
  11771: "36059",
  11772: "36103",
  11776: "36103",
  11777: "36103",
  11778: "36103",
  11779: "36103",
  11780: "36103",
  11782: "36103",
  11783: "36059",
  11784: "36103",
  11786: "36103",
  11787: "36103",
  11788: "36103",
  11789: "36103",
  11790: "36103",
  11791: "36059",
  11792: "36103",
  11793: "36059",
  11795: "36103",
  11796: "36103",
  11797: "36059",
  11798: "36103",
  11801: "36059",
  11803: "36059",
  11804: "36059",
  11901: "36103",
  11930: "36103",
  11932: "36103",
  11933: "36103",
  11934: "36103",
  11935: "36103",
  11937: "36103",
  11939: "36103",
  11940: "36103",
  11941: "36103",
  11942: "36103",
  11944: "36103",
  11946: "36103",
  11948: "36103",
  11949: "36103",
  11950: "36103",
  11952: "36103",
  11953: "36103",
  11954: "36103",
  11955: "36103",
  11957: "36103",
  11958: "36103",
  11959: "36103",
  11960: "36103",
  11961: "36103",
  11962: "36103",
  11963: "36103",
  11964: "36103",
  11965: "36103",
  11967: "36103",
  11968: "36103",
  11971: "36103",
  11972: "36103",
  11975: "36103",
  11976: "36103",
  11977: "36103",
  11978: "36103",
  11980: "36103",
  12008: "36093",
  12009: "36001",
  1201: "25003",
  12010: "36057",
  12015: "36039",
  12017: "36021",
  12018: "36083",
  12019: "36091",
  12020: "36091",
  12022: "36083",
  12023: "36095",
  12024: "36021",
  12025: "36035",
  12027: "36091",
  12028: "36115",
  12029: "36021",
  12032: "36035",
  12033: "36083",
  12035: "36095",
  12036: "36095",
  12037: "36021",
  12041: "36001",
  12042: "36039",
  12043: "36095",
  12045: "36001",
  12046: "36001",
  12047: "36001",
  12051: "36039",
  12052: "36083",
  12053: "36093",
  12054: "36001",
  12056: "36093",
  12057: "36115",
  12058: "36039",
  12059: "36001",
  12060: "36021",
  12061: "36083",
  12062: "36083",
  12063: "36083",
  12064: "36077",
  12065: "36091",
  12066: "36095",
  12067: "36001",
  12068: "36057",
  12070: "36035",
  12071: "36095",
  12072: "36057",
  12074: "36091",
  12075: "36021",
  12076: "36095",
  12077: "36001",
  12078: "36035",
  12083: "36001",
  12084: "36001",
  12086: "36035",
  12087: "36039",
  12090: "36083",
  12092: "36095",
  12093: "36095",
  12094: "36083",
  12095: "36035",
  12106: "36021",
  12108: "36041",
  12110: "36001",
  12116: "36077",
  12117: "36035",
  12118: "36091",
  12120: "36001",
  12121: "36083",
  12122: "36095",
  12123: "36083",
  12124: "36039",
  12125: "36021",
  12130: "36021",
  12134: "36035",
  12136: "36021",
  12137: "36093",
  12138: "36083",
  12139: "36041",
  12140: "36083",
  12143: "36001",
  12144: "36083",
  12147: "36001",
  12148: "36091",
  12149: "36095",
  12150: "36093",
  12151: "36091",
  12153: "36083",
  12154: "36083",
  12155: "36077",
  12156: "36083",
  12157: "36095",
  12158: "36001",
  12159: "36001",
  12160: "36095",
  12164: "36041",
  12165: "36021",
  12166: "36057",
  12167: "36025",
  12168: "36083",
  12169: "36083",
  12170: "36091",
  12173: "36021",
  12175: "36095",
  12176: "36039",
  12177: "36057",
  12180: "36083",
  12182: "36083",
  12183: "36001",
  12184: "36021",
  12185: "36083",
  12186: "36001",
  12187: "36095",
  12188: "36091",
  12189: "36001",
  12190: "36041",
  12192: "36039",
  12193: "36001",
  12194: "36095",
  12196: "36083",
  12197: "36077",
  12198: "36083",
  1220: "25003",
  12202: "36001",
  12203: "36001",
  12204: "36001",
  12205: "36001",
  12206: "36001",
  12208: "36001",
  12209: "36001",
  12210: "36001",
  12211: "36001",
  1222: "25003",
  1223: "25003",
  1224: "25003",
  1225: "25003",
  1226: "25003",
  1230: "25003",
  12302: "36093",
  12303: "36093",
  12304: "36093",
  12305: "36093",
  12306: "36093",
  12307: "36093",
  12308: "36093",
  12309: "36093",
  1235: "25003",
  1236: "25003",
  1237: "25003",
  1238: "25003",
  1240: "25003",
  12401: "36111",
  12404: "36111",
  12405: "36039",
  12406: "36111",
  12407: "36039",
  12409: "36111",
  12410: "36111",
  12411: "36111",
  12412: "36111",
  12413: "36039",
  12414: "36039",
  12416: "36111",
  12418: "36039",
  12419: "36111",
  12420: "36111",
  12421: "36025",
  12422: "36039",
  12423: "36039",
  12424: "36039",
  12427: "36039",
  12428: "36111",
  1243: "25015",
  12430: "36025",
  12431: "36039",
  12433: "36111",
  12435: "36111",
  12436: "36039",
  12439: "36039",
  12440: "36111",
  12442: "36039",
  12443: "36111",
  12444: "36039",
  12446: "36111",
  12448: "36111",
  12449: "36111",
  1245: "25003",
  12450: "36039",
  12451: "36039",
  12453: "36111",
  12454: "36039",
  12455: "36025",
  12456: "36111",
  12457: "36111",
  12458: "36111",
  12460: "36001",
  12461: "36111",
  12463: "36039",
  12464: "36111",
  12465: "36111",
  12466: "36111",
  12468: "36039",
  12469: "36001",
  1247: "25003",
  12470: "36039",
  12472: "36111",
  12473: "36039",
  12474: "36025",
  12477: "36111",
  12480: "36111",
  12481: "36111",
  12484: "36111",
  12485: "36039",
  12486: "36111",
  12487: "36111",
  12489: "36111",
  12491: "36111",
  12492: "36039",
  12493: "36111",
  12494: "36111",
  12495: "36111",
  12496: "36039",
  12498: "36111",
  12501: "36027",
  12502: "36021",
  12503: "36021",
  12508: "36027",
  12513: "36021",
  12514: "36027",
  12515: "36111",
  12516: "36021",
  12517: "36021",
  12518: "36071",
  12520: "36071",
  12521: "36021",
  12522: "36027",
  12523: "36021",
  12524: "36027",
  12525: "36111",
  12526: "36021",
  12528: "36111",
  12529: "36021",
  1253: "25003",
  12531: "36027",
  12533: "36027",
  12534: "36021",
  12538: "36027",
  1254: "25003",
  12540: "36027",
  12542: "36111",
  12543: "36071",
  12545: "36027",
  12546: "36027",
  12547: "36111",
  12548: "36111",
  12549: "36071",
  1255: "25003",
  12550: "36071",
  12553: "36071",
  1256: "25003",
  12561: "36111",
  12563: "36079",
  12564: "36027",
  12565: "36021",
  12566: "36111",
  12567: "36027",
  12569: "36027",
  1257: "25003",
  12570: "36027",
  12571: "36027",
  12572: "36027",
  12574: "36027",
  12575: "36071",
  12577: "36071",
  12578: "36027",
  1258: "25003",
  12580: "36027",
  12581: "36027",
  12582: "36027",
  12583: "36021",
  12585: "36027",
  12586: "36071",
  12589: "36111",
  1259: "25003",
  12590: "36027",
  12592: "36027",
  12594: "36027",
  12601: "36027",
  12603: "36027",
  1262: "25003",
  1264: "25003",
  1266: "25003",
  1267: "25003",
  1270: "25003",
  12701: "36105",
  12719: "36105",
  12720: "36105",
  12721: "36105",
  12723: "36105",
  12724: "36105",
  12725: "36111",
  12726: "36105",
  12729: "36071",
  12732: "36105",
  12733: "36105",
  12734: "36105",
  12736: "36105",
  12737: "36105",
  12740: "36105",
  12742: "36105",
  12743: "36105",
  12746: "36071",
  12747: "36105",
  12748: "36105",
  12751: "36105",
  12754: "36105",
  12758: "36105",
  12759: "36105",
  12760: "36105",
  12763: "36105",
  12764: "36105",
  12765: "36105",
  12766: "36105",
  12768: "36105",
  12770: "36105",
  12771: "36071",
  12775: "36105",
  12776: "36105",
  12777: "36105",
  12779: "36105",
  12780: "36071",
  12783: "36105",
  12785: "36071",
  12786: "36105",
  12788: "36105",
  12789: "36105",
  12790: "36105",
  12791: "36105",
  12792: "36105",
  12801: "36113",
  12803: "36091",
  12804: "36113",
  12808: "36113",
  12809: "36115",
  12810: "36113",
  12811: "36113",
  12812: "36041",
  12814: "36113",
  12815: "36113",
  12816: "36115",
  12817: "36113",
  12819: "36115",
  12820: "36113",
  12821: "36115",
  12822: "36091",
  12823: "36115",
  12824: "36113",
  12827: "36115",
  12828: "36115",
  12831: "36091",
  12832: "36115",
  12833: "36091",
  12834: "36115",
  12835: "36091",
  12836: "36113",
  12837: "36115",
  12838: "36115",
  12839: "36115",
  12841: "36115",
  12842: "36041",
  12843: "36113",
  12844: "36113",
  12845: "36113",
  12846: "36113",
  12847: "36041",
  12849: "36115",
  12850: "36091",
  12851: "36031",
  12852: "36031",
  12853: "36113",
  12855: "36031",
  12856: "36113",
  12857: "36031",
  12859: "36091",
  12860: "36113",
  12861: "36115",
  12863: "36091",
  12865: "36115",
  12866: "36091",
  12870: "36031",
  12871: "36091",
  12873: "36115",
  12874: "36113",
  12878: "36113",
  12883: "36031",
  12884: "36091",
  12885: "36113",
  12886: "36113",
  12887: "36115",
  12901: "36019",
  12903: "36019",
  12910: "36019",
  12912: "36031",
  12913: "36033",
  12914: "36033",
  12916: "36033",
  12917: "36033",
  12918: "36019",
  12919: "36019",
  12920: "36033",
  12921: "36019",
  12923: "36019",
  12926: "36033",
  12927: "36089",
  12928: "36031",
  12929: "36019",
  12930: "36033",
  12932: "36031",
  12934: "36019",
  12935: "36019",
  12936: "36031",
  12937: "36033",
  12941: "36031",
  12942: "36031",
  12943: "36031",
  12944: "36031",
  12945: "36033",
  12946: "36031",
  12950: "36031",
  12952: "36019",
  12953: "36033",
  12955: "36019",
  12956: "36031",
  12957: "36033",
  12958: "36019",
  12959: "36019",
  12960: "36031",
  12962: "36019",
  12965: "36089",
  12966: "36033",
  12967: "36089",
  12969: "36033",
  12970: "36033",
  12972: "36019",
  12973: "36089",
  12974: "36031",
  12975: "36031",
  12976: "36033",
  12978: "36019",
  12979: "36019",
  12980: "36033",
  12981: "36019",
  12983: "36033",
  12985: "36019",
  12986: "36033",
  12987: "36031",
  12989: "36033",
  12992: "36019",
  12993: "36031",
  12996: "36031",
  12997: "36031",
  12998: "36031",
  1301: "25011",
  13021: "36011",
  13026: "36011",
  13027: "36067",
  13028: "36075",
  13029: "36067",
  13030: "36053",
  13031: "36067",
  13032: "36053",
  13033: "36011",
  13034: "36011",
  13035: "36053",
  13036: "36075",
  13037: "36053",
  13039: "36067",
  13040: "36023",
  13041: "36067",
  13042: "36065",
  13044: "36075",
  13045: "36023",
  13052: "36053",
  13053: "36109",
  13054: "36065",
  13057: "36067",
  13060: "36067",
  13061: "36053",
  13063: "36067",
  13066: "36067",
  13068: "36109",
  13069: "36075",
  13071: "36011",
  13072: "36053",
  13073: "36109",
  13074: "36075",
  13076: "36075",
  13077: "36023",
  13078: "36067",
  13080: "36067",
  13081: "36011",
  13082: "36053",
  13083: "36075",
  13084: "36067",
  13088: "36067",
  13090: "36067",
  13092: "36011",
  13101: "36023",
  13104: "36067",
  13108: "36067",
  13110: "36067",
  13111: "36011",
  13112: "36067",
  13114: "36075",
  13116: "36067",
  13118: "36011",
  13120: "36067",
  13122: "36053",
  13126: "36075",
  13131: "36075",
  13132: "36075",
  13135: "36075",
  13136: "36017",
  13140: "36011",
  13141: "36023",
  13142: "36075",
  13143: "36117",
  13144: "36075",
  13145: "36075",
  13146: "36117",
  13147: "36011",
  13148: "36099",
  13152: "36067",
  13155: "36017",
  13156: "36011",
  13157: "36065",
  13158: "36023",
  13159: "36067",
  13160: "36011",
  13162: "36065",
  13164: "36067",
  13165: "36099",
  13166: "36011",
  13167: "36075",
  13202: "36067",
  13203: "36067",
  13204: "36067",
  13205: "36067",
  13206: "36067",
  13207: "36067",
  13208: "36067",
  13209: "36067",
  13210: "36067",
  13211: "36067",
  13212: "36067",
  13214: "36067",
  13215: "36067",
  13219: "36067",
  13224: "36067",
  1330: "25011",
  13302: "36075",
  13303: "36065",
  13304: "36065",
  13308: "36065",
  13309: "36065",
  1331: "25027",
  13310: "36053",
  13313: "36065",
  13314: "36053",
  13315: "36077",
  13316: "36065",
  13317: "36057",
  13318: "36065",
  13319: "36065",
  13320: "36077",
  13321: "36065",
  13322: "36043",
  13323: "36065",
  13324: "36043",
  13325: "36049",
  13326: "36077",
  13327: "36049",
  13328: "36065",
  13329: "36035",
  13331: "36043",
  13332: "36053",
  13334: "36053",
  13335: "36077",
  13337: "36077",
  13338: "36065",
  13339: "36057",
  13340: "36043",
  13343: "36049",
  13345: "36049",
  13346: "36053",
  13348: "36077",
  13350: "36043",
  13354: "36065",
  13355: "36053",
  13357: "36043",
  13360: "36041",
  13361: "36043",
  13363: "36065",
  13365: "36043",
  13367: "36049",
  13368: "36049",
  1337: "25011",
  1338: "25011",
  1339: "25011",
  1340: "25011",
  13402: "36053",
  13403: "36065",
  13406: "36043",
  13407: "36043",
  13408: "36053",
  13409: "36053",
  1341: "25011",
  13410: "36057",
  13411: "36077",
  13413: "36065",
  13416: "36043",
  13417: "36065",
  1342: "25011",
  13420: "36043",
  13421: "36053",
  13424: "36065",
  13425: "36065",
  13428: "36057",
  13431: "36043",
  13433: "36049",
  13435: "36065",
  13436: "36041",
  13437: "36075",
  13438: "36065",
  13439: "36043",
  1344: "25011",
  13440: "36065",
  13450: "36077",
  13452: "36035",
  13454: "36043",
  13456: "36065",
  13459: "36095",
  1346: "25011",
  13460: "36017",
  13461: "36065",
  13464: "36017",
  13468: "36077",
  13469: "36065",
  13470: "36035",
  13471: "36065",
  13472: "36043",
  13473: "36049",
  13476: "36065",
  13477: "36065",
  13478: "36065",
  13480: "36065",
  13485: "36053",
  13486: "36065",
  13488: "36077",
  13489: "36049",
  1349: "25011",
  13490: "36065",
  13491: "36043",
  13492: "36065",
  13493: "36075",
  13494: "36065",
  13495: "36065",
  13501: "36065",
  13502: "36065",
  1351: "25011",
  1354: "25011",
  1355: "25011",
  1360: "25011",
  13601: "36045",
  13605: "36045",
  13606: "36045",
  13607: "36045",
  13608: "36045",
  13612: "36045",
  13613: "36089",
  13614: "36089",
  13615: "36045",
  13616: "36045",
  13617: "36089",
  13618: "36045",
  13619: "36045",
  13620: "36049",
  13621: "36089",
  13622: "36045",
  13624: "36045",
  13625: "36089",
  13626: "36049",
  13630: "36089",
  13634: "36045",
  13635: "36089",
  13636: "36045",
  13637: "36045",
  13638: "36045",
  1364: "25011",
  13640: "36045",
  13642: "36089",
  13646: "36089",
  13648: "36049",
  13650: "36045",
  13652: "36089",
  13654: "36089",
  13656: "36045",
  13658: "36089",
  13659: "36045",
  1366: "25027",
  13660: "36089",
  13661: "36045",
  13662: "36089",
  13664: "36089",
  13665: "36049",
  13666: "36089",
  13667: "36089",
  13668: "36089",
  13669: "36089",
  1367: "25011",
  13670: "36089",
  13672: "36089",
  13673: "36045",
  13676: "36089",
  13679: "36045",
  1368: "25027",
  13680: "36089",
  13681: "36089",
  13682: "36045",
  13684: "36089",
  13685: "36045",
  13687: "36089",
  13690: "36089",
  13691: "36045",
  13693: "36045",
  13694: "36089",
  13697: "36089",
  1370: "25011",
  1373: "25011",
  13730: "36017",
  13731: "36025",
  13732: "36107",
  13733: "36017",
  13734: "36107",
  13736: "36107",
  13739: "36025",
  13740: "36025",
  13743: "36107",
  13744: "36007",
  13746: "36007",
  13748: "36007",
  1375: "25011",
  13750: "36025",
  13752: "36025",
  13753: "36025",
  13754: "36025",
  13755: "36025",
  13756: "36025",
  13757: "36025",
  1376: "25011",
  13760: "36007",
  13775: "36025",
  13776: "36077",
  13777: "36007",
  13778: "36017",
  1378: "25011",
  13780: "36017",
  13782: "36025",
  13783: "36025",
  13784: "36023",
  13786: "36025",
  13787: "36007",
  13788: "36025",
  1379: "25011",
  13790: "36007",
  13795: "36007",
  13796: "36077",
  13797: "36007",
  13801: "36017",
  13802: "36007",
  13803: "36023",
  13804: "36025",
  13807: "36077",
  13808: "36077",
  13809: "36077",
  13810: "36077",
  13811: "36107",
  13812: "36107",
  13813: "36007",
  13815: "36017",
  13820: "36077",
  13825: "36077",
  13827: "36107",
  13830: "36017",
  13832: "36017",
  13833: "36007",
  13835: "36107",
  13838: "36025",
  13839: "36025",
  13841: "36017",
  13842: "36025",
  13843: "36077",
  13844: "36017",
  13846: "36025",
  13849: "36077",
  13850: "36007",
  13856: "36025",
  13861: "36077",
  13862: "36007",
  13863: "36023",
  13864: "36107",
  13865: "36007",
  13901: "36007",
  13903: "36007",
  13904: "36007",
  13905: "36007",
  14001: "36029",
  14004: "36029",
  14005: "36037",
  14006: "36029",
  14008: "36063",
  14009: "36121",
  14011: "36121",
  14012: "36063",
  14013: "36037",
  14020: "36037",
  14024: "36121",
  14025: "36029",
  14026: "36029",
  14028: "36063",
  14030: "36029",
  14031: "36029",
  14032: "36029",
  14033: "36029",
  14034: "36029",
  14036: "36037",
  14037: "36121",
  14040: "36037",
  14042: "36009",
  14043: "36029",
  14047: "36029",
  14048: "36013",
  14051: "36029",
  14052: "36029",
  14054: "36037",
  14055: "36029",
  14057: "36029",
  14058: "36037",
  14059: "36029",
  14060: "36003",
  14062: "36013",
  14063: "36013",
  14065: "36009",
  14066: "36121",
  14067: "36063",
  14068: "36029",
  14069: "36029",
  14070: "36009",
  14072: "36029",
  14075: "36029",
  14080: "36029",
  14081: "36029",
  14082: "36121",
  14085: "36029",
  14086: "36029",
  14091: "36029",
  14092: "36063",
  14094: "36063",
  14098: "36073",
  14101: "36009",
  14102: "36029",
  14103: "36073",
  14105: "36063",
  14108: "36063",
  14111: "36029",
  14113: "36121",
  14120: "36063",
  14125: "36037",
  14126: "36063",
  14127: "36029",
  14129: "36009",
  14130: "36121",
  14131: "36063",
  14132: "36063",
  14134: "36029",
  14136: "36013",
  14138: "36009",
  14139: "36029",
  14141: "36029",
  14143: "36037",
  14145: "36121",
  14150: "36029",
  14167: "36121",
  14170: "36029",
  14171: "36009",
  14172: "36063",
  14173: "36009",
  14174: "36063",
  1420: "25027",
  14201: "36029",
  14202: "36029",
  14204: "36029",
  14206: "36029",
  14207: "36029",
  14208: "36029",
  14209: "36029",
  14210: "36029",
  14211: "36029",
  14212: "36029",
  14213: "36029",
  14214: "36029",
  14215: "36029",
  14216: "36029",
  14217: "36029",
  14218: "36029",
  14219: "36029",
  14220: "36029",
  14221: "36029",
  14222: "36029",
  14223: "36029",
  14224: "36029",
  14225: "36029",
  14226: "36029",
  14227: "36029",
  14228: "36029",
  1430: "25027",
  14301: "36063",
  14303: "36063",
  14304: "36063",
  14305: "36063",
  1431: "25017",
  1432: "25017",
  1434: "25027",
  1436: "25027",
  1440: "25027",
  14411: "36073",
  14414: "36051",
  14416: "36037",
  14418: "36123",
  14420: "36055",
  14422: "36037",
  14423: "36051",
  14424: "36069",
  14425: "36069",
  14427: "36121",
  14428: "36055",
  14432: "36069",
  14433: "36117",
  14435: "36051",
  14437: "36051",
  14441: "36123",
  14445: "36055",
  14450: "36055",
  14454: "36051",
  14456: "36069",
  14462: "36051",
  14464: "36055",
  14466: "36069",
  14467: "36055",
  14468: "36055",
  14469: "36069",
  14470: "36073",
  14471: "36069",
  14472: "36055",
  14475: "36069",
  14476: "36073",
  14477: "36073",
  14478: "36123",
  14480: "36051",
  14481: "36051",
  14482: "36037",
  14485: "36051",
  14486: "36051",
  14487: "36051",
  14489: "36117",
  1450: "25017",
  14502: "36117",
  14504: "36069",
  14505: "36117",
  14506: "36055",
  14507: "36123",
  1451: "25027",
  14510: "36051",
  14511: "36055",
  14512: "36069",
  14513: "36117",
  14514: "36055",
  14516: "36117",
  14517: "36051",
  14519: "36117",
  1452: "25027",
  14521: "36099",
  14522: "36117",
  14525: "36037",
  14526: "36055",
  14527: "36123",
  1453: "25027",
  14530: "36121",
  14532: "36069",
  14533: "36051",
  14534: "36055",
  14536: "36121",
  14537: "36069",
  14541: "36099",
  14543: "36055",
  14544: "36069",
  14546: "36055",
  14548: "36069",
  14550: "36121",
  14551: "36117",
  14555: "36117",
  14559: "36055",
  14560: "36069",
  14561: "36069",
  14564: "36069",
  14568: "36117",
  14569: "36121",
  14571: "36073",
  14572: "36101",
  14580: "36055",
  14586: "36055",
  14589: "36117",
  14590: "36117",
  14591: "36121",
  14592: "36051",
  1460: "25017",
  14605: "36055",
  14606: "36055",
  14607: "36055",
  14608: "36055",
  14609: "36055",
  14610: "36055",
  14611: "36055",
  14612: "36055",
  14613: "36055",
  14615: "36055",
  14616: "36055",
  14617: "36055",
  14618: "36055",
  14619: "36055",
  1462: "25027",
  14620: "36055",
  14621: "36055",
  14622: "36055",
  14623: "36055",
  14624: "36055",
  14625: "36055",
  14626: "36055",
  1463: "25017",
  1464: "25017",
  1468: "25027",
  1469: "25017",
  14701: "36013",
  14706: "36009",
  14709: "36003",
  14710: "36013",
  14711: "36003",
  14712: "36013",
  14714: "36003",
  14715: "36003",
  14716: "36013",
  14717: "36003",
  14718: "36013",
  14719: "36009",
  14720: "36013",
  14722: "36013",
  14723: "36013",
  14724: "36013",
  14726: "36009",
  14727: "36003",
  14728: "36013",
  14729: "36009",
  1473: "25027",
  14731: "36009",
  14733: "36013",
  14735: "36003",
  14736: "36013",
  14737: "36009",
  14738: "36013",
  14739: "36003",
  1474: "25017",
  14740: "36013",
  14741: "36009",
  14742: "36013",
  14743: "36009",
  14744: "36003",
  14747: "36013",
  14748: "36009",
  1475: "25027",
  14750: "36013",
  14752: "36013",
  14753: "36009",
  14754: "36003",
  14755: "36009",
  14757: "36013",
  14760: "36009",
  14767: "36013",
  14769: "36013",
  14770: "36009",
  14772: "36009",
  14775: "36013",
  14777: "36003",
  14779: "36009",
  14781: "36013",
  14782: "36013",
  14784: "36013",
  14787: "36013",
  14801: "36101",
  14802: "36003",
  14803: "36003",
  14804: "36003",
  14805: "36097",
  14806: "36003",
  14807: "36101",
  14808: "36101",
  14809: "36101",
  14810: "36101",
  14812: "36097",
  14813: "36003",
  14814: "36015",
  14815: "36097",
  14816: "36015",
  14817: "36109",
  14818: "36097",
  14819: "36101",
  14820: "36101",
  14821: "36101",
  14822: "36003",
  14823: "36101",
  14824: "36097",
  14825: "36015",
  14826: "36101",
  14830: "36101",
  14836: "36051",
  14837: "36123",
  14838: "36015",
  14839: "36101",
  14840: "36101",
  14841: "36097",
  14842: "36123",
  14843: "36101",
  14845: "36015",
  14846: "36051",
  14847: "36099",
  14850: "36109",
  14855: "36101",
  14858: "36101",
  14859: "36107",
  14860: "36099",
  14861: "36015",
  14864: "36097",
  14865: "36097",
  14867: "36109",
  14869: "36097",
  14870: "36101",
  14871: "36015",
  14872: "36015",
  14873: "36101",
  14874: "36101",
  14877: "36101",
  14878: "36097",
  14879: "36101",
  14880: "36003",
  14882: "36109",
  14883: "36107",
  14884: "36003",
  14885: "36101",
  14886: "36109",
  14889: "36015",
  14891: "36097",
  14892: "36107",
  14894: "36015",
  14895: "36003",
  14897: "36003",
  14898: "36101",
  14901: "36015",
  14903: "36015",
  14904: "36015",
  14905: "36015",
  15001: "42007",
  15003: "42007",
  15005: "42007",
  15006: "42003",
  15007: "42003",
  15009: "42007",
  1501: "25027",
  15010: "42007",
  15012: "42129",
  15014: "42003",
  15015: "42003",
  15017: "42003",
  15018: "42129",
  15019: "42125",
  15020: "42125",
  15021: "42125",
  15022: "42125",
  15024: "42003",
  15025: "42003",
  15026: "42007",
  15027: "42007",
  1503: "25027",
  15030: "42003",
  15031: "42003",
  15033: "42125",
  15034: "42003",
  15035: "42003",
  15037: "42003",
  15038: "42125",
  1504: "25027",
  15042: "42007",
  15043: "42007",
  15044: "42003",
  15045: "42003",
  15046: "42003",
  15047: "42003",
  15049: "42003",
  1505: "25027",
  15050: "42007",
  15051: "42003",
  15052: "42007",
  15053: "42125",
  15054: "42125",
  15055: "42125",
  15056: "42003",
  15057: "42003",
  15059: "42007",
  1506: "25027",
  15060: "42125",
  15061: "42007",
  15062: "42129",
  15063: "42125",
  15064: "42003",
  15065: "42003",
  15066: "42007",
  15067: "42125",
  15068: "42129",
  1507: "25027",
  15071: "42003",
  15074: "42007",
  15076: "42003",
  15078: "42125",
  15081: "42007",
  15082: "42003",
  15083: "42129",
  15084: "42003",
  15085: "42129",
  15086: "42019",
  15088: "42003",
  15089: "42129",
  15090: "42003",
  1510: "25027",
  15101: "42003",
  15102: "42003",
  15104: "42003",
  15106: "42003",
  15108: "42003",
  15110: "42003",
  15112: "42003",
  15116: "42003",
  15120: "42003",
  15122: "42003",
  15126: "42003",
  15129: "42003",
  15131: "42003",
  15132: "42003",
  15133: "42003",
  15135: "42003",
  15136: "42003",
  15137: "42003",
  15139: "42003",
  15140: "42003",
  15142: "42003",
  15143: "42003",
  15144: "42003",
  15145: "42003",
  15146: "42003",
  15147: "42003",
  15148: "42003",
  1515: "25027",
  1516: "25027",
  1518: "25027",
  1519: "25027",
  1520: "25027",
  15201: "42003",
  15202: "42003",
  15203: "42003",
  15204: "42003",
  15205: "42003",
  15206: "42003",
  15207: "42003",
  15208: "42003",
  15209: "42003",
  1521: "25013",
  15210: "42003",
  15211: "42003",
  15212: "42003",
  15213: "42003",
  15214: "42003",
  15215: "42003",
  15216: "42003",
  15217: "42003",
  15218: "42003",
  15219: "42003",
  1522: "25027",
  15220: "42003",
  15221: "42003",
  15222: "42003",
  15223: "42003",
  15224: "42003",
  15225: "42003",
  15226: "42003",
  15227: "42003",
  15228: "42003",
  15229: "42003",
  1523: "25027",
  15232: "42003",
  15233: "42003",
  15234: "42003",
  15235: "42003",
  15236: "42003",
  15237: "42003",
  15238: "42003",
  15239: "42003",
  1524: "25027",
  15241: "42003",
  15243: "42003",
  1527: "25027",
  1529: "25027",
  15301: "42125",
  1531: "25027",
  15311: "42125",
  15312: "42125",
  15313: "42125",
  15314: "42125",
  15317: "42125",
  1532: "25027",
  15320: "42059",
  15321: "42125",
  15322: "42059",
  15323: "42125",
  15324: "42125",
  15329: "42125",
  15330: "42125",
  15331: "42125",
  15332: "42125",
  15333: "42125",
  1534: "25027",
  15340: "42125",
  15342: "42125",
  15344: "42059",
  15345: "42125",
  15347: "42125",
  1535: "25027",
  15350: "42125",
  15358: "42125",
  1536: "25027",
  15360: "42125",
  15363: "42125",
  15367: "42125",
  15368: "42125",
  1537: "25027",
  15370: "42059",
  15376: "42125",
  15377: "42125",
  1540: "25027",
  15401: "42051",
  1541: "25027",
  15410: "42051",
  15411: "42111",
  15412: "42125",
  15413: "42051",
  15417: "42125",
  15419: "42125",
  1542: "25027",
  15420: "42051",
  15422: "42051",
  15423: "42125",
  15424: "42111",
  15425: "42051",
  15428: "42051",
  15429: "42125",
  1543: "25027",
  15430: "42051",
  15431: "42051",
  15432: "42125",
  15433: "42051",
  15435: "42051",
  15436: "42051",
  15437: "42051",
  15438: "42051",
  15440: "42051",
  15442: "42051",
  15443: "42051",
  15444: "42051",
  15445: "42051",
  15446: "42051",
  15447: "42051",
  15449: "42051",
  1545: "25027",
  15450: "42051",
  15451: "42051",
  15455: "42051",
  15456: "42051",
  15458: "42051",
  15459: "42051",
  15461: "42051",
  15462: "42051",
  15464: "42051",
  15466: "42051",
  15468: "42051",
  15469: "42051",
  15470: "42051",
  15473: "42051",
  15474: "42051",
  15475: "42051",
  15476: "42051",
  15477: "42125",
  15478: "42051",
  15479: "42129",
  15480: "42051",
  15482: "42051",
  15483: "42125",
  15486: "42051",
  15489: "42051",
  15490: "42051",
  1550: "25027",
  15501: "42111",
  15502: "42111",
  15521: "42009",
  15522: "42009",
  15530: "42111",
  15531: "42111",
  15533: "42009",
  15535: "42009",
  15536: "42057",
  15537: "42009",
  15538: "42111",
  15540: "42111",
  15541: "42111",
  15542: "42111",
  15545: "42009",
  15546: "42111",
  15547: "42111",
  15550: "42009",
  15551: "42111",
  15552: "42111",
  15554: "42009",
  15557: "42111",
  15558: "42111",
  15559: "42009",
  15563: "42111",
  1560: "25027",
  15601: "42129",
  15610: "42129",
  15611: "42129",
  15612: "42129",
  15613: "42129",
  15615: "42129",
  15617: "42129",
  15618: "42005",
  15619: "42129",
  1562: "25027",
  15620: "42129",
  15622: "42051",
  15623: "42129",
  15624: "42129",
  15626: "42129",
  15627: "42129",
  15628: "42129",
  15629: "42129",
  15631: "42051",
  15632: "42129",
  15634: "42129",
  15635: "42129",
  15636: "42129",
  15637: "42129",
  15639: "42129",
  1564: "25027",
  15640: "42129",
  15641: "42129",
  15642: "42129",
  15644: "42129",
  15646: "42129",
  15647: "42129",
  15650: "42129",
  15655: "42129",
  15656: "42129",
  15658: "42129",
  1566: "25027",
  15660: "42129",
  15661: "42129",
  15663: "42129",
  15665: "42129",
  15666: "42129",
  15668: "42129",
  15670: "42129",
  15671: "42129",
  15672: "42129",
  15673: "42005",
  15675: "42129",
  15677: "42129",
  15678: "42129",
  15679: "42129",
  1568: "25027",
  15681: "42063",
  15683: "42129",
  15684: "42129",
  15686: "42005",
  15687: "42129",
  15688: "42129",
  1569: "25027",
  15690: "42129",
  15692: "42129",
  15697: "42129",
  15698: "42129",
  1570: "25027",
  15701: "42063",
  1571: "25027",
  15713: "42063",
  15714: "42021",
  15716: "42063",
  15717: "42063",
  15722: "42021",
  15724: "42063",
  15725: "42063",
  15727: "42063",
  15728: "42063",
  15729: "42063",
  15731: "42063",
  15732: "42063",
  15734: "42063",
  15736: "42005",
  15739: "42063",
  15742: "42063",
  15747: "42063",
  15748: "42063",
  15753: "42033",
  15754: "42063",
  15756: "42063",
  15757: "42033",
  15759: "42063",
  15765: "42063",
  15767: "42065",
  15771: "42063",
  15772: "42063",
  15774: "42063",
  15801: "42033",
  1581: "25027",
  15821: "42047",
  15823: "42047",
  15824: "42065",
  15825: "42065",
  15827: "42047",
  1583: "25027",
  15832: "42023",
  15834: "42023",
  15840: "42065",
  15845: "42047",
  15846: "42047",
  15848: "42033",
  15849: "42033",
  1585: "25027",
  15851: "42065",
  15853: "42047",
  15856: "42033",
  15857: "42047",
  15860: "42065",
  15866: "42033",
  15868: "42047",
  15870: "42047",
  1588: "25027",
  1590: "25027",
  15901: "42021",
  15902: "42021",
  15904: "42021",
  15905: "42021",
  15906: "42021",
  15909: "42021",
  15920: "42063",
  15923: "42129",
  15924: "42111",
  15926: "42111",
  15928: "42111",
  15931: "42021",
  15935: "42111",
  15936: "42111",
  15937: "42111",
  15938: "42021",
  15942: "42021",
  15943: "42021",
  15944: "42129",
  15946: "42021",
  15949: "42063",
  15954: "42063",
  15955: "42021",
  15956: "42021",
  15957: "42063",
  15958: "42021",
  15961: "42063",
  15963: "42021",
  16001: "42019",
  16002: "42019",
  1602: "25027",
  16020: "42019",
  16022: "42019",
  16023: "42019",
  16025: "42019",
  16028: "42005",
  16029: "42019",
  1603: "25027",
  16033: "42019",
  16034: "42019",
  16037: "42019",
  16038: "42019",
  1604: "25027",
  16040: "42019",
  16041: "42019",
  16045: "42019",
  16046: "42019",
  16049: "42005",
  1605: "25027",
  16050: "42019",
  16051: "42019",
  16052: "42019",
  16053: "42019",
  16055: "42019",
  16056: "42019",
  16057: "42019",
  16059: "42019",
  1606: "25027",
  16061: "42019",
  16063: "42019",
  16066: "42019",
  1607: "25027",
  1608: "25027",
  1609: "25027",
  1610: "25027",
  16101: "42073",
  16102: "42073",
  16105: "42073",
  1611: "25027",
  16110: "42039",
  16111: "42039",
  16112: "42073",
  16113: "42085",
  16114: "42085",
  16115: "42007",
  16116: "42073",
  16117: "42073",
  1612: "25027",
  16120: "42073",
  16121: "42085",
  16123: "42007",
  16124: "42085",
  16125: "42085",
  16127: "42085",
  16130: "42085",
  16131: "42039",
  16132: "42073",
  16133: "42085",
  16134: "42039",
  16136: "42007",
  16137: "42085",
  16141: "42073",
  16142: "42085",
  16143: "42073",
  16145: "42085",
  16146: "42085",
  16148: "42085",
  16150: "42085",
  16153: "42085",
  16154: "42085",
  16156: "42073",
  16157: "42073",
  16159: "42085",
  16160: "42073",
  16161: "42085",
  16201: "42005",
  16210: "42005",
  16212: "42005",
  16214: "42031",
  16218: "42005",
  16222: "42005",
  16226: "42005",
  16228: "42005",
  16229: "42005",
  16232: "42031",
  16233: "42031",
  16238: "42005",
  16239: "42053",
  16240: "42005",
  16242: "42005",
  16244: "42005",
  16245: "42005",
  16248: "42031",
  16249: "42005",
  16250: "42005",
  16254: "42031",
  16256: "42063",
  16259: "42005",
  16262: "42005",
  16263: "42005",
  16301: "42121",
  16311: "42085",
  16313: "42123",
  16314: "42039",
  16316: "42039",
  16317: "42121",
  16319: "42121",
  16323: "42121",
  16327: "42039",
  16329: "42123",
  16335: "42039",
  16340: "42123",
  16341: "42121",
  16342: "42121",
  16343: "42121",
  16344: "42121",
  16345: "42123",
  16346: "42121",
  16347: "42123",
  16350: "42123",
  16351: "42123",
  16353: "42053",
  16354: "42039",
  16360: "42039",
  16362: "42121",
  16364: "42121",
  16365: "42123",
  16371: "42123",
  16372: "42121",
  16373: "42121",
  16374: "42121",
  16401: "42049",
  16402: "42123",
  16403: "42039",
  16404: "42039",
  16405: "42123",
  16406: "42039",
  16407: "42049",
  16410: "42049",
  16411: "42049",
  16412: "42049",
  16415: "42049",
  16417: "42049",
  16420: "42123",
  16421: "42049",
  16423: "42049",
  16424: "42039",
  16426: "42049",
  16428: "42049",
  16430: "42049",
  16433: "42039",
  16434: "42039",
  16435: "42039",
  16436: "42123",
  16438: "42049",
  16440: "42039",
  16441: "42049",
  16442: "42049",
  16443: "42049",
  16502: "42049",
  16503: "42049",
  16504: "42049",
  16505: "42049",
  16506: "42049",
  16507: "42049",
  16508: "42049",
  16509: "42049",
  16510: "42049",
  16511: "42049",
  16601: "42013",
  16602: "42013",
  16611: "42061",
  16613: "42021",
  16617: "42013",
  16620: "42033",
  16621: "42061",
  16623: "42061",
  16625: "42013",
  16627: "42033",
  16630: "42021",
  16634: "42061",
  16635: "42013",
  16637: "42013",
  16639: "42021",
  16640: "42021",
  16641: "42021",
  16646: "42021",
  16647: "42061",
  16648: "42013",
  16650: "42009",
  16651: "42033",
  16652: "42061",
  16655: "42009",
  16656: "42033",
  16657: "42061",
  16661: "42033",
  16662: "42013",
  16664: "42009",
  16666: "42033",
  16667: "42009",
  16668: "42021",
  16669: "42061",
  16671: "42033",
  16673: "42013",
  16674: "42061",
  16677: "42027",
  16678: "42061",
  16680: "42033",
  16682: "42013",
  16683: "42061",
  16685: "42061",
  16686: "42013",
  16689: "42057",
  16691: "42057",
  16692: "42033",
  16693: "42013",
  16695: "42009",
  16701: "42083",
  16720: "42105",
  16731: "42083",
  16734: "42047",
  16735: "42083",
  16743: "42083",
  16748: "42105",
  16749: "42083",
  16801: "42027",
  16803: "42027",
  16820: "42027",
  16821: "42033",
  16822: "42035",
  16823: "42027",
  16826: "42027",
  16827: "42027",
  16828: "42027",
  16829: "42027",
  16830: "42033",
  16832: "42027",
  16833: "42033",
  16834: "42033",
  16836: "42033",
  16837: "42033",
  16838: "42033",
  16839: "42033",
  16840: "42033",
  16841: "42027",
  16843: "42033",
  16844: "42027",
  16845: "42027",
  16847: "42033",
  16849: "42033",
  16851: "42027",
  16852: "42027",
  16853: "42027",
  16854: "42027",
  16858: "42033",
  16859: "42027",
  16860: "42033",
  16861: "42033",
  16863: "42033",
  16865: "42027",
  16866: "42027",
  16868: "42027",
  16870: "42027",
  16871: "42035",
  16872: "42027",
  16874: "42027",
  16875: "42027",
  16876: "42033",
  16877: "42027",
  16878: "42033",
  16879: "42033",
  16881: "42033",
  16882: "42027",
  16901: "42117",
  16912: "42117",
  16914: "42015",
  16915: "42105",
  16917: "42117",
  16920: "42117",
  16921: "42117",
  16922: "42105",
  16925: "42015",
  16926: "42015",
  16928: "42117",
  16929: "42117",
  16930: "42117",
  16932: "42117",
  16933: "42117",
  16935: "42117",
  16936: "42117",
  16938: "42117",
  16942: "42117",
  16943: "42105",
  16946: "42117",
  16947: "42015",
  16948: "42105",
  16950: "42117",
  17002: "42087",
  17003: "42075",
  17004: "42087",
  17005: "42043",
  17006: "42099",
  17007: "42041",
  17009: "42087",
  1701: "25017",
  17011: "42041",
  17013: "42041",
  17015: "42041",
  17017: "42097",
  17018: "42043",
  17019: "42133",
  1702: "25017",
  17020: "42099",
  17021: "42067",
  17022: "42071",
  17023: "42043",
  17024: "42099",
  17025: "42041",
  17026: "42075",
  17028: "42043",
  17029: "42087",
  17030: "42043",
  17032: "42043",
  17033: "42043",
  17034: "42043",
  17035: "42067",
  17036: "42043",
  17037: "42099",
  17038: "42075",
  17040: "42099",
  17042: "42075",
  17043: "42041",
  17044: "42087",
  17045: "42099",
  17046: "42075",
  17047: "42099",
  17048: "42043",
  17049: "42067",
  17050: "42041",
  17051: "42087",
  17052: "42061",
  17053: "42099",
  17055: "42041",
  17057: "42043",
  17058: "42067",
  17059: "42067",
  17060: "42061",
  17061: "42043",
  17062: "42099",
  17063: "42087",
  17064: "42075",
  17065: "42041",
  17066: "42061",
  17067: "42075",
  17068: "42099",
  17070: "42133",
  17073: "42075",
  17074: "42099",
  17078: "42075",
  17080: "42043",
  17082: "42067",
  17084: "42087",
  17086: "42067",
  17087: "42011",
  17088: "42075",
  17090: "42099",
  17093: "42041",
  17094: "42067",
  17098: "42043",
  17099: "42087",
  17101: "42043",
  17102: "42043",
  17103: "42043",
  17104: "42043",
  17109: "42043",
  17110: "42043",
  17111: "42043",
  17112: "42043",
  17113: "42043",
  1718: "25017",
  1719: "25017",
  1720: "25017",
  17201: "42055",
  17202: "42055",
  1721: "25017",
  17212: "42057",
  17213: "42061",
  17214: "42055",
  17215: "42057",
  17219: "42055",
  17220: "42055",
  17221: "42055",
  17222: "42055",
  17223: "42057",
  17224: "42055",
  17225: "42055",
  17228: "42057",
  17229: "42057",
  17233: "42057",
  17235: "42055",
  17236: "42055",
  17237: "42055",
  17238: "42057",
  17240: "42041",
  17241: "42041",
  17243: "42061",
  17244: "42055",
  17249: "42061",
  17252: "42055",
  17255: "42061",
  17257: "42041",
  17260: "42061",
  17262: "42055",
  17264: "42061",
  17265: "42055",
  17266: "42041",
  17267: "42057",
  17268: "42055",
  17271: "42055",
  1730: "25017",
  17301: "42001",
  17302: "42133",
  17304: "42001",
  17306: "42001",
  17307: "42001",
  17309: "42133",
  17313: "42133",
  17314: "42133",
  17315: "42133",
  17316: "42001",
  17318: "42133",
  17319: "42133",
  17320: "42001",
  17321: "42133",
  17322: "42133",
  17324: "42041",
  17325: "42001",
  17327: "42133",
  17329: "42133",
  17331: "42133",
  17339: "42133",
  17340: "42001",
  17344: "42001",
  17345: "42133",
  17347: "42133",
  17349: "42133",
  17350: "42001",
  17352: "42133",
  17353: "42001",
  17356: "42133",
  17360: "42133",
  17361: "42133",
  17362: "42133",
  17363: "42133",
  17364: "42133",
  17365: "42133",
  17366: "42133",
  17368: "42133",
  17370: "42133",
  17372: "42001",
  1740: "25027",
  17401: "42133",
  17402: "42133",
  17403: "42133",
  17404: "42133",
  17406: "42133",
  17407: "42133",
  17408: "42133",
  1741: "25017",
  1742: "25017",
  1745: "25027",
  1746: "25017",
  1747: "25027",
  1748: "25017",
  1749: "25017",
  17501: "42071",
  17502: "42071",
  17505: "42071",
  17508: "42071",
  17509: "42071",
  17512: "42071",
  17516: "42071",
  17517: "42071",
  17518: "42071",
  17519: "42071",
  1752: "25017",
  17520: "42071",
  17522: "42071",
  17527: "42071",
  17529: "42071",
  17532: "42071",
  17535: "42071",
  17536: "42071",
  17538: "42071",
  1754: "25017",
  17540: "42071",
  17543: "42071",
  17545: "42071",
  17547: "42071",
  17550: "42071",
  17551: "42071",
  17552: "42071",
  17554: "42071",
  17555: "42071",
  17557: "42071",
  1756: "25027",
  17560: "42071",
  17562: "42071",
  17563: "42071",
  17565: "42071",
  17566: "42071",
  17569: "42071",
  1757: "25027",
  17572: "42071",
  17578: "42071",
  17579: "42071",
  17581: "42071",
  17582: "42071",
  17584: "42071",
  1760: "25017",
  17601: "42071",
  17602: "42071",
  17603: "42071",
  1770: "25017",
  17701: "42081",
  17702: "42081",
  1772: "25027",
  17721: "42035",
  17723: "42081",
  17724: "42015",
  17727: "42081",
  17728: "42081",
  17729: "42105",
  1773: "25017",
  17731: "42113",
  17737: "42081",
  17740: "42081",
  17744: "42081",
  17745: "42035",
  17747: "42035",
  1775: "25017",
  17751: "42035",
  17752: "42081",
  17754: "42081",
  17756: "42081",
  17758: "42113",
  1776: "25017",
  17760: "42035",
  17762: "42081",
  17763: "42081",
  17764: "42035",
  17765: "42081",
  17768: "42113",
  17771: "42081",
  17772: "42097",
  17774: "42081",
  17776: "42081",
  17777: "42097",
  17778: "42035",
  1778: "25017",
  17801: "42097",
  17810: "42119",
  17812: "42109",
  17813: "42109",
  17814: "42037",
  17815: "42037",
  17820: "42037",
  17821: "42093",
  17823: "42097",
  17824: "42097",
  17827: "42109",
  17830: "42097",
  17832: "42097",
  17834: "42097",
  17837: "42119",
  17840: "42097",
  17841: "42087",
  17842: "42109",
  17844: "42119",
  17845: "42119",
  17847: "42097",
  17850: "42097",
  17851: "42097",
  17853: "42109",
  17855: "42119",
  17856: "42119",
  17857: "42097",
  17860: "42097",
  17864: "42109",
  17866: "42097",
  17870: "42109",
  17872: "42097",
  17876: "42109",
  17878: "42037",
  17881: "42097",
  17886: "42119",
  17889: "42109",
  17901: "42107",
  17921: "42107",
  17922: "42107",
  17929: "42107",
  17931: "42107",
  17935: "42107",
  17938: "42107",
  17948: "42107",
  17954: "42107",
  17959: "42107",
  17960: "42107",
  17961: "42107",
  17963: "42107",
  17965: "42107",
  17967: "42107",
  17970: "42107",
  17972: "42107",
  17976: "42107",
  17978: "42043",
  17980: "42107",
  17981: "42107",
  17983: "42107",
  17985: "42037",
  1801: "25017",
  18011: "42011",
  18013: "42095",
  18014: "42095",
  18015: "42095",
  18017: "42095",
  18018: "42077",
  18020: "42095",
  1803: "25017",
  18030: "42025",
  18031: "42077",
  18032: "42077",
  18034: "42077",
  18035: "42095",
  18036: "42077",
  18037: "42077",
  18038: "42095",
  18040: "42095",
  18041: "42091",
  18042: "42095",
  18045: "42095",
  18049: "42077",
  18051: "42077",
  18052: "42077",
  18053: "42077",
  18054: "42091",
  18055: "42095",
  18056: "42011",
  18058: "42089",
  18059: "42077",
  18062: "42077",
  18063: "42095",
  18064: "42095",
  18066: "42077",
  18067: "42095",
  18069: "42077",
  18070: "42091",
  18071: "42025",
  18072: "42095",
  18073: "42091",
  18074: "42091",
  18076: "42091",
  18077: "42017",
  18078: "42077",
  18079: "42077",
  18080: "42077",
  18083: "42095",
  18085: "42095",
  18087: "42077",
  18088: "42095",
  18091: "42095",
  18092: "42077",
  1810: "25009",
  18101: "42077",
  18102: "42077",
  18103: "42077",
  18104: "42077",
  18106: "42077",
  18109: "42077",
  18201: "42079",
  18202: "42079",
  1821: "25017",
  18210: "42025",
  18211: "42107",
  18214: "42107",
  18216: "42025",
  18218: "42107",
  18219: "42079",
  18222: "42079",
  18224: "42079",
  18229: "42025",
  18232: "42025",
  18235: "42025",
  18237: "42107",
  1824: "25017",
  18240: "42025",
  18241: "42079",
  18249: "42079",
  18250: "42025",
  18252: "42107",
  18254: "42025",
  18255: "42025",
  1826: "25017",
  1827: "25017",
  1830: "25009",
  18301: "42089",
  18302: "42089",
  1832: "25009",
  18321: "42089",
  18322: "42089",
  18323: "42089",
  18324: "42103",
  18325: "42089",
  18326: "42089",
  18327: "42089",
  18328: "42103",
  1833: "25009",
  18330: "42089",
  18331: "42089",
  18332: "42089",
  18333: "42089",
  18334: "42089",
  18336: "42103",
  18337: "42103",
  1834: "25009",
  18343: "42095",
  18344: "42089",
  18346: "42089",
  18347: "42089",
  1835: "25009",
  18350: "42089",
  18353: "42089",
  18354: "42089",
  18355: "42089",
  18360: "42089",
  18370: "42089",
  18371: "42103",
  18372: "42089",
  1840: "25009",
  18403: "42069",
  18405: "42127",
  18407: "42069",
  1841: "25009",
  18411: "42069",
  18414: "42069",
  18415: "42127",
  18417: "42127",
  18419: "42131",
  18421: "42115",
  18424: "42127",
  18425: "42103",
  18426: "42103",
  18428: "42103",
  1843: "25009",
  18431: "42127",
  18433: "42069",
  18434: "42069",
  18435: "42103",
  18436: "42127",
  18437: "42127",
  18438: "42127",
  18439: "42127",
  1844: "25009",
  18443: "42127",
  18444: "42069",
  18445: "42103",
  18446: "42131",
  18447: "42069",
  1845: "25009",
  18451: "42103",
  18452: "42069",
  18453: "42127",
  18456: "42127",
  18458: "42103",
  18461: "42127",
  18462: "42127",
  18464: "42103",
  18465: "42115",
  18466: "42089",
  18469: "42127",
  18470: "42115",
  18471: "42069",
  18472: "42127",
  1850: "25017",
  18503: "42069",
  18504: "42069",
  18505: "42069",
  18507: "42069",
  18508: "42069",
  18509: "42069",
  1851: "25017",
  18510: "42069",
  18512: "42069",
  18517: "42069",
  18518: "42069",
  18519: "42069",
  1852: "25017",
  1854: "25017",
  1860: "25009",
  18603: "42037",
  18610: "42089",
  18612: "42079",
  18614: "42113",
  18615: "42131",
  18616: "42113",
  18617: "42079",
  18618: "42079",
  18619: "42113",
  1862: "25017",
  18621: "42079",
  18623: "42131",
  18624: "42025",
  18626: "42113",
  18628: "42113",
  18629: "42131",
  1863: "25017",
  18630: "42115",
  18632: "42113",
  18634: "42079",
  18635: "42037",
  18636: "42079",
  1864: "25017",
  18640: "42079",
  18641: "42079",
  18642: "42079",
  18643: "42079",
  18644: "42079",
  18651: "42079",
  18653: "42069",
  18655: "42079",
  18656: "42079",
  18657: "42131",
  18660: "42079",
  18661: "42079",
  1867: "25017",
  18701: "42079",
  18702: "42079",
  18704: "42079",
  18705: "42079",
  18706: "42079",
  18707: "42079",
  18708: "42079",
  18709: "42079",
  1876: "25017",
  1879: "25017",
  1880: "25017",
  18801: "42115",
  18810: "42015",
  18812: "42115",
  18822: "42115",
  18829: "42015",
  18831: "42015",
  18832: "42015",
  18833: "42015",
  18834: "42115",
  18837: "42015",
  18840: "42015",
  18845: "42015",
  18846: "42015",
  18847: "42115",
  18848: "42015",
  18850: "42015",
  18851: "42015",
  18853: "42015",
  18854: "42015",
  1886: "25017",
  1887: "25017",
  1890: "25017",
  18901: "42017",
  18902: "42017",
  18913: "42017",
  18914: "42017",
  18915: "42091",
  18917: "42017",
  18920: "42017",
  18923: "42017",
  18925: "42017",
  18929: "42017",
  18930: "42017",
  18932: "42017",
  18933: "42017",
  18934: "42017",
  18936: "42091",
  18938: "42017",
  18940: "42017",
  18942: "42017",
  18944: "42017",
  18947: "42017",
  18951: "42017",
  18954: "42017",
  18955: "42017",
  18960: "42017",
  18964: "42091",
  18966: "42017",
  18969: "42091",
  18972: "42017",
  18974: "42017",
  18976: "42017",
  18977: "42017",
  19001: "42091",
  19002: "42091",
  19003: "42045",
  19004: "42091",
  19006: "42091",
  19007: "42017",
  19008: "42045",
  19009: "42091",
  1901: "25009",
  19010: "42091",
  19012: "42091",
  19013: "42045",
  19014: "42045",
  19015: "42045",
  19018: "42045",
  1902: "25009",
  19020: "42017",
  19021: "42017",
  19022: "42045",
  19023: "42045",
  19025: "42091",
  19026: "42045",
  19027: "42091",
  19029: "42045",
  19030: "42017",
  19031: "42091",
  19032: "42045",
  19033: "42045",
  19034: "42091",
  19035: "42091",
  19036: "42045",
  19038: "42091",
  1904: "25009",
  19040: "42091",
  19041: "42045",
  19043: "42045",
  19044: "42091",
  19046: "42091",
  19047: "42017",
  1905: "25009",
  19050: "42045",
  19053: "42017",
  19054: "42017",
  19055: "42017",
  19056: "42017",
  19057: "42017",
  1906: "25009",
  19060: "42045",
  19061: "42045",
  19063: "42045",
  19064: "42045",
  19066: "42091",
  19067: "42017",
  1907: "25009",
  19070: "42045",
  19072: "42091",
  19073: "42045",
  19074: "42045",
  19075: "42091",
  19076: "42045",
  19078: "42045",
  19079: "42045",
  1908: "25009",
  19081: "42045",
  19082: "42045",
  19083: "42045",
  19085: "42045",
  19086: "42045",
  19087: "42029",
  19090: "42091",
  19094: "42045",
  19095: "42091",
  19096: "42091",
  19102: "42101",
  19103: "42101",
  19104: "42101",
  19106: "42101",
  19107: "42101",
  19111: "42101",
  19114: "42101",
  19115: "42101",
  19116: "42101",
  19118: "42101",
  19119: "42101",
  19120: "42101",
  19121: "42101",
  19122: "42101",
  19123: "42101",
  19124: "42101",
  19125: "42101",
  19126: "42101",
  19127: "42101",
  19128: "42101",
  19129: "42101",
  1913: "25009",
  19130: "42101",
  19131: "42101",
  19132: "42101",
  19133: "42101",
  19134: "42101",
  19135: "42101",
  19136: "42101",
  19137: "42101",
  19138: "42101",
  19139: "42101",
  19140: "42101",
  19141: "42101",
  19142: "42101",
  19143: "42101",
  19144: "42101",
  19145: "42101",
  19146: "42101",
  19147: "42101",
  19148: "42101",
  19149: "42101",
  1915: "25009",
  19150: "42101",
  19151: "42101",
  19152: "42101",
  19153: "42101",
  19154: "42101",
  1921: "25009",
  1922: "25009",
  1923: "25009",
  1929: "25009",
  1930: "25009",
  19301: "42029",
  19310: "42029",
  19311: "42029",
  19312: "42029",
  19317: "42045",
  19319: "42045",
  19320: "42029",
  19330: "42029",
  19333: "42029",
  19335: "42029",
  19341: "42029",
  19342: "42045",
  19343: "42029",
  19344: "42029",
  19348: "42029",
  19350: "42029",
  19352: "42029",
  19355: "42029",
  19362: "42029",
  19363: "42029",
  19365: "42029",
  19372: "42029",
  19373: "42045",
  19374: "42029",
  1938: "25009",
  19380: "42029",
  19382: "42029",
  19390: "42029",
  1940: "25009",
  19401: "42091",
  19403: "42091",
  19405: "42091",
  19406: "42091",
  19422: "42091",
  19425: "42029",
  19426: "42091",
  19428: "42091",
  19435: "42091",
  19437: "42091",
  19438: "42091",
  1944: "25009",
  19440: "42091",
  19444: "42091",
  19446: "42091",
  1945: "25009",
  19453: "42091",
  19454: "42091",
  19456: "42091",
  19460: "42029",
  19462: "42091",
  19464: "42091",
  19465: "42029",
  19468: "42091",
  19473: "42091",
  19474: "42091",
  19475: "42029",
  19481: "42029",
  1949: "25009",
  19492: "42091",
  1950: "25009",
  19501: "42071",
  19503: "42011",
  19504: "42011",
  19505: "42011",
  19506: "42011",
  19507: "42011",
  19508: "42011",
  1951: "25009",
  19510: "42011",
  19512: "42011",
  19518: "42011",
  1952: "25009",
  19520: "42029",
  19522: "42011",
  19525: "42091",
  19526: "42011",
  19529: "42011",
  19530: "42011",
  19533: "42011",
  19534: "42011",
  19539: "42011",
  19540: "42011",
  19541: "42011",
  19543: "42011",
  19547: "42011",
  19551: "42011",
  19555: "42011",
  19560: "42011",
  19562: "42011",
  19565: "42011",
  19567: "42011",
  1960: "25009",
  19601: "42011",
  19602: "42011",
  19604: "42011",
  19605: "42011",
  19606: "42011",
  19607: "42011",
  19608: "42011",
  19609: "42011",
  19610: "42011",
  19611: "42011",
  1966: "25009",
  1969: "25009",
  1970: "25009",
  19701: "10003",
  19702: "10003",
  19703: "10003",
  19706: "10003",
  19707: "10003",
  19709: "10003",
  19711: "10003",
  19713: "10003",
  19720: "10003",
  19730: "10003",
  19731: "10003",
  19732: "10003",
  19733: "10003",
  19734: "10003",
  19801: "10003",
  19802: "10003",
  19803: "10003",
  19804: "10003",
  19805: "10003",
  19806: "10003",
  19807: "10003",
  19808: "10003",
  19809: "10003",
  19810: "10003",
  1982: "25009",
  1983: "25009",
  1984: "25009",
  1985: "25009",
  19901: "10001",
  19904: "10001",
  19930: "10005",
  19931: "10005",
  19933: "10005",
  19934: "10001",
  19938: "10001",
  19939: "10005",
  19940: "10005",
  19941: "10005",
  19943: "10001",
  19944: "10005",
  19945: "10005",
  19946: "10001",
  19947: "10005",
  19950: "10005",
  19951: "10005",
  19952: "10001",
  19953: "10001",
  19954: "10001",
  19956: "10005",
  19958: "10005",
  19960: "10005",
  19962: "10001",
  19963: "10001",
  19964: "10001",
  19966: "10005",
  19967: "10005",
  19968: "10005",
  19970: "10005",
  19971: "10005",
  19973: "10005",
  19975: "10005",
  19977: "10001",
  19979: "10001",
  20001: "11001",
  20002: "11001",
  20003: "11001",
  20004: "11001",
  20005: "11001",
  20006: "11001",
  20007: "11001",
  20008: "11001",
  20009: "11001",
  20010: "11001",
  20011: "11001",
  20012: "11001",
  20015: "11001",
  20016: "11001",
  20017: "11001",
  20018: "11001",
  20019: "11001",
  20020: "11001",
  20024: "11001",
  20032: "11001",
  20036: "11001",
  20037: "11001",
  20105: "51107",
  20106: "51157",
  20109: "51153",
  20110: "51683",
  20111: "51685",
  20112: "51153",
  20115: "51061",
  20117: "51107",
  20119: "51061",
  20120: "51059",
  20121: "51059",
  20124: "51059",
  20129: "51107",
  20130: "51043",
  20132: "51107",
  20135: "51043",
  20136: "51153",
  20137: "51061",
  20141: "51107",
  20143: "51153",
  20144: "51061",
  20147: "51107",
  20148: "51107",
  20151: "51059",
  20152: "51107",
  20155: "51153",
  20158: "51107",
  20164: "51107",
  20165: "51107",
  20166: "51107",
  20169: "51153",
  20170: "51059",
  20171: "51059",
  20175: "51107",
  20176: "51107",
  20180: "51107",
  20181: "51153",
  20184: "51061",
  20186: "51061",
  20187: "51061",
  2019: "25021",
  20190: "51059",
  20191: "51059",
  20194: "51059",
  20197: "51107",
  20198: "51061",
  2021: "25021",
  2025: "25021",
  2026: "25021",
  2030: "25021",
  2032: "25021",
  2035: "25021",
  2038: "25021",
  2043: "25023",
  2045: "25023",
  2048: "25005",
  2050: "25023",
  2052: "25021",
  2053: "25021",
  2054: "25021",
  2056: "25021",
  20601: "24017",
  20602: "24017",
  20603: "24017",
  20606: "24037",
  20607: "24033",
  20608: "24033",
  20609: "24037",
  2061: "25023",
  20611: "24017",
  20612: "24017",
  20613: "24033",
  20615: "24009",
  20616: "24017",
  20617: "24017",
  20618: "24037",
  20619: "24037",
  2062: "25021",
  20620: "24037",
  20621: "24037",
  20622: "24037",
  20623: "24033",
  20624: "24037",
  20625: "24017",
  20626: "24037",
  20628: "24037",
  20629: "24009",
  20630: "24037",
  20632: "24017",
  20634: "24037",
  20636: "24037",
  20637: "24017",
  20639: "24009",
  20640: "24017",
  20645: "24017",
  20646: "24017",
  20650: "24037",
  20653: "24037",
  20657: "24009",
  20658: "24017",
  20659: "24037",
  2066: "25023",
  20662: "24017",
  20664: "24017",
  20667: "24037",
  2067: "25021",
  20674: "24037",
  20675: "24017",
  20676: "24009",
  20677: "24017",
  20678: "24009",
  20680: "24037",
  20684: "24037",
  20685: "24009",
  20687: "24037",
  20688: "24009",
  20689: "24009",
  20690: "24037",
  20692: "24037",
  20693: "24017",
  20695: "24017",
  20705: "24033",
  20706: "24033",
  20707: "24033",
  20708: "24033",
  2071: "25021",
  20710: "24033",
  20711: "24003",
  20712: "24033",
  20714: "24009",
  20715: "24033",
  20716: "24033",
  2072: "25021",
  20720: "24033",
  20721: "24033",
  20722: "24033",
  20723: "24027",
  20724: "24003",
  20732: "24009",
  20733: "24003",
  20735: "24033",
  20736: "24009",
  20737: "24033",
  20740: "24033",
  20743: "24033",
  20744: "24033",
  20745: "24033",
  20746: "24033",
  20747: "24033",
  20748: "24033",
  20751: "24003",
  20754: "24009",
  20758: "24003",
  20759: "24027",
  20763: "24027",
  20764: "24003",
  20765: "24003",
  20769: "24033",
  20770: "24033",
  20772: "24033",
  20774: "24033",
  20776: "24003",
  20777: "24027",
  20778: "24003",
  20779: "24003",
  20781: "24033",
  20782: "24033",
  20783: "24033",
  20784: "24033",
  20785: "24033",
  20794: "24027",
  2081: "25021",
  20812: "24031",
  20814: "24031",
  20815: "24031",
  20816: "24031",
  20817: "24031",
  20818: "24031",
  20832: "24031",
  20833: "24031",
  20837: "24031",
  20838: "24031",
  20839: "24031",
  20841: "24031",
  20842: "24031",
  20850: "24031",
  20851: "24031",
  20852: "24031",
  20853: "24031",
  20854: "24031",
  20855: "24031",
  20860: "24031",
  20861: "24031",
  20862: "24031",
  20866: "24031",
  20868: "24031",
  20871: "24031",
  20872: "24031",
  20874: "24031",
  20876: "24031",
  20877: "24031",
  20878: "24031",
  20879: "24031",
  20880: "24031",
  20882: "24031",
  20886: "24031",
  20895: "24031",
  20896: "24031",
  2090: "25021",
  20901: "24031",
  20902: "24031",
  20903: "24031",
  20904: "24031",
  20905: "24031",
  20906: "24031",
  20910: "24031",
  20912: "24031",
  2093: "25021",
  21001: "24025",
  21009: "24025",
  21012: "24003",
  21013: "24005",
  21014: "24025",
  21015: "24025",
  21017: "24025",
  21028: "24025",
  21029: "24027",
  21030: "24005",
  21032: "24003",
  21034: "24025",
  21035: "24003",
  21036: "24027",
  21037: "24003",
  21040: "24025",
  21042: "24027",
  21043: "24027",
  21044: "24027",
  21045: "24027",
  21046: "24027",
  21047: "24025",
  21048: "24013",
  21050: "24025",
  21051: "24005",
  21052: "24005",
  21053: "24005",
  21054: "24003",
  21056: "24003",
  21057: "24005",
  21060: "24003",
  21061: "24003",
  21071: "24005",
  21074: "24013",
  21075: "24027",
  21076: "24003",
  21077: "24003",
  21078: "24025",
  2108: "25025",
  21082: "24005",
  21084: "24025",
  21085: "24025",
  21087: "24005",
  2109: "25025",
  21090: "24003",
  21093: "24005",
  2110: "25025",
  21102: "24013",
  21104: "24013",
  21108: "24003",
  2111: "25025",
  21111: "24005",
  21113: "24003",
  21114: "24003",
  21117: "24005",
  21120: "24005",
  21122: "24003",
  21128: "24005",
  2113: "25025",
  21131: "24005",
  21132: "24025",
  21133: "24005",
  21136: "24005",
  2114: "25025",
  21140: "24003",
  21144: "24003",
  21146: "24003",
  2115: "25025",
  21152: "24005",
  21153: "24005",
  21154: "24025",
  21155: "24005",
  21156: "24005",
  21157: "24013",
  21158: "24013",
  2116: "25025",
  21160: "24025",
  21161: "24005",
  21162: "24005",
  21163: "24005",
  2118: "25025",
  2119: "25025",
  2120: "25025",
  21201: "24510",
  21202: "24510",
  21204: "24005",
  21205: "24510",
  21206: "24510",
  21207: "24005",
  21208: "24005",
  21209: "24005",
  2121: "25025",
  21210: "24510",
  21211: "24510",
  21212: "24510",
  21213: "24510",
  21214: "24510",
  21215: "24510",
  21216: "24510",
  21217: "24510",
  21218: "24510",
  21219: "24005",
  2122: "25025",
  21220: "24005",
  21221: "24005",
  21222: "24005",
  21223: "24510",
  21224: "24510",
  21225: "24003",
  21226: "24003",
  21227: "24005",
  21228: "24005",
  21229: "24510",
  21230: "24510",
  21231: "24510",
  21234: "24005",
  21236: "24005",
  21237: "24005",
  21239: "24510",
  2124: "25025",
  21244: "24005",
  2125: "25025",
  2126: "25025",
  2127: "25025",
  2128: "25025",
  21286: "24005",
  2129: "25025",
  2130: "25025",
  2131: "25025",
  2132: "25025",
  2134: "25025",
  2135: "25025",
  2136: "25025",
  2138: "25017",
  2139: "25017",
  2140: "25017",
  21401: "24003",
  21403: "24003",
  21405: "24003",
  21409: "24003",
  2141: "25017",
  2142: "25017",
  2143: "25017",
  2144: "25017",
  2145: "25017",
  2148: "25017",
  2149: "25017",
  2150: "25025",
  21502: "24001",
  2151: "25025",
  2152: "25025",
  21520: "24023",
  21521: "24023",
  21523: "24023",
  21524: "24001",
  21529: "24001",
  21530: "24001",
  21531: "24023",
  21532: "24001",
  21536: "24023",
  21538: "24023",
  21539: "24001",
  21541: "24023",
  21545: "24001",
  2155: "25017",
  21550: "24023",
  21555: "24001",
  21557: "24001",
  21561: "24023",
  21562: "24001",
  21601: "24041",
  21607: "24035",
  21610: "24029",
  21612: "24041",
  21613: "24019",
  21617: "24035",
  21619: "24035",
  21620: "24029",
  21622: "24019",
  21623: "24035",
  21624: "24041",
  21625: "24041",
  21628: "24035",
  21629: "24011",
  21631: "24019",
  21632: "24011",
  21634: "24019",
  21635: "24029",
  21636: "24011",
  21638: "24035",
  21639: "24011",
  21640: "24011",
  21643: "24019",
  21645: "24029",
  21647: "24041",
  21648: "24019",
  21649: "24011",
  21650: "24029",
  21651: "24035",
  21652: "24041",
  21654: "24041",
  21655: "24011",
  21657: "24035",
  21658: "24035",
  21659: "24019",
  21660: "24011",
  21661: "24029",
  21662: "24041",
  21663: "24041",
  21664: "24019",
  21665: "24041",
  21666: "24035",
  21667: "24029",
  21668: "24035",
  21669: "24019",
  21671: "24041",
  21672: "24019",
  21673: "24041",
  21676: "24041",
  21677: "24019",
  21678: "24029",
  21679: "24041",
  2169: "25021",
  2170: "25021",
  21701: "24021",
  21702: "24021",
  21703: "24021",
  21704: "24021",
  2171: "25021",
  21710: "24021",
  21711: "24043",
  21713: "24043",
  21716: "24021",
  21719: "24043",
  21722: "24043",
  21723: "24027",
  21727: "24021",
  21733: "24043",
  21734: "24043",
  21737: "24027",
  21738: "24027",
  21740: "24043",
  21742: "24043",
  21750: "24043",
  21754: "24021",
  21755: "24021",
  21756: "24043",
  21757: "24021",
  21758: "24021",
  2176: "25017",
  21762: "24021",
  21766: "24001",
  21767: "24043",
  21769: "24021",
  21770: "24021",
  21771: "24021",
  21773: "24021",
  21774: "24021",
  21776: "24013",
  21777: "24021",
  21778: "24021",
  21779: "24043",
  21780: "24021",
  21782: "24043",
  21783: "24043",
  21784: "24013",
  21787: "24013",
  21788: "24021",
  21791: "24021",
  21793: "24021",
  21794: "24027",
  21795: "24043",
  21797: "24027",
  21798: "24021",
  2180: "25017",
  21801: "24045",
  21804: "24045",
  21811: "24047",
  21813: "24047",
  21814: "24045",
  21817: "24039",
  21821: "24039",
  21822: "24045",
  21824: "24039",
  21826: "24045",
  21829: "24047",
  21830: "24045",
  21837: "24045",
  21838: "24039",
  2184: "25021",
  21840: "24045",
  21841: "24047",
  21842: "24047",
  21849: "24045",
  21850: "24045",
  21851: "24047",
  21853: "24039",
  21856: "24045",
  2186: "25021",
  21861: "24045",
  21863: "24047",
  21864: "24047",
  21865: "24045",
  21869: "24019",
  21871: "24039",
  21872: "24047",
  21874: "24045",
  21875: "24045",
  2188: "25021",
  2189: "25021",
  2190: "25021",
  21901: "24015",
  21903: "24015",
  21904: "24015",
  2191: "25021",
  21911: "24015",
  21912: "24015",
  21913: "24015",
  21914: "24015",
  21915: "24015",
  21917: "24015",
  21918: "24015",
  21919: "24015",
  21920: "24015",
  21921: "24015",
  2199: "25025",
  22003: "51059",
  22015: "51059",
  22025: "51153",
  22026: "51153",
  22027: "51059",
  22030: "51059",
  22031: "51059",
  22032: "51059",
  22033: "51059",
  22039: "51059",
  22041: "51059",
  22042: "51059",
  22043: "51059",
  22044: "51059",
  22046: "51610",
  22060: "51059",
  22066: "51059",
  22079: "51059",
  2210: "25025",
  22101: "51059",
  22102: "51059",
  22124: "51059",
  22125: "51153",
  22134: "51153",
  2215: "25025",
  22150: "51059",
  22151: "51059",
  22152: "51059",
  22153: "51059",
  22172: "51153",
  22180: "51059",
  22181: "51059",
  22182: "51059",
  22191: "51153",
  22192: "51153",
  22193: "51153",
  22201: "51013",
  22202: "51013",
  22203: "51013",
  22204: "51013",
  22205: "51013",
  22206: "51013",
  22207: "51013",
  22209: "51013",
  22213: "51013",
  22301: "51510",
  22302: "51013",
  22303: "51059",
  22304: "51510",
  22305: "51510",
  22306: "51059",
  22307: "51059",
  22308: "51059",
  22309: "51059",
  22310: "51059",
  22311: "51510",
  22312: "51059",
  22314: "51510",
  22315: "51059",
  22401: "51630",
  22405: "51179",
  22406: "51179",
  22407: "51177",
  22408: "51177",
  22427: "51033",
  22432: "51133",
  22435: "51133",
  22436: "51057",
  22437: "51057",
  22438: "51057",
  22443: "51193",
  22454: "51057",
  22460: "51159",
  22469: "51193",
  22473: "51133",
  22476: "51057",
  22480: "51103",
  22482: "51133",
  22485: "51099",
  22488: "51193",
  22503: "51103",
  22504: "51057",
  22508: "51137",
  22511: "51133",
  22514: "51033",
  22520: "51193",
  22534: "51177",
  22535: "51033",
  22539: "51133",
  22542: "51137",
  22546: "51033",
  22551: "51177",
  22553: "51177",
  22554: "51179",
  22556: "51179",
  22560: "51057",
  22567: "51137",
  22572: "51159",
  22576: "51103",
  22578: "51103",
  22580: "51033",
  22601: "51840",
  22602: "51840",
  22603: "51069",
  22610: "51187",
  22611: "51043",
  22620: "51043",
  22623: "51157",
  22624: "51069",
  22625: "51069",
  22627: "51157",
  22630: "51187",
  22637: "51069",
  22639: "51061",
  22640: "51157",
  22641: "51171",
  22642: "51187",
  22643: "51061",
  22644: "51171",
  22645: "51069",
  22650: "51139",
  22652: "51171",
  22654: "51069",
  22655: "51069",
  22656: "51069",
  22657: "51171",
  22660: "51171",
  22663: "51043",
  22664: "51171",
  22701: "51047",
  22709: "51113",
  22712: "51061",
  22713: "51047",
  22714: "51047",
  22715: "51113",
  22716: "51157",
  22718: "51047",
  22719: "51113",
  22720: "51061",
  22722: "51113",
  22724: "51047",
  22726: "51047",
  22727: "51113",
  22728: "51061",
  22731: "51113",
  22732: "51113",
  22733: "51047",
  22734: "51061",
  22735: "51047",
  22736: "51047",
  22737: "51047",
  22738: "51113",
  22740: "51157",
  22742: "51061",
  22743: "51113",
  22747: "51157",
  22749: "51157",
  22801: "51660",
  22802: "51660",
  22810: "51171",
  22812: "51165",
  22815: "51165",
  22821: "51165",
  22824: "51171",
  22827: "51165",
  22830: "51165",
  22831: "51165",
  22834: "51165",
  22835: "51139",
  22840: "51165",
  22841: "51165",
  22842: "51171",
  22843: "51015",
  22844: "51171",
  22846: "51165",
  22847: "51171",
  22849: "51139",
  22850: "51165",
  22851: "51139",
  22853: "51165",
  22901: "51003",
  22902: "51003",
  22903: "51540",
  22911: "51003",
  22920: "51125",
  22922: "51125",
  22923: "51137",
  22931: "51003",
  22932: "51003",
  22935: "51079",
  22936: "51003",
  22937: "51003",
  22938: "51125",
  22939: "51015",
  22940: "51003",
  22942: "51109",
  22943: "51003",
  22947: "51003",
  22949: "51125",
  22952: "51015",
  22958: "51125",
  22959: "51003",
  22960: "51137",
  22963: "51065",
  22964: "51125",
  22967: "51125",
  22968: "51079",
  22969: "51125",
  22971: "51125",
  22972: "51137",
  22973: "51079",
  22974: "51065",
  22976: "51125",
  22980: "51820",
  23002: "51007",
  23004: "51029",
  23005: "51085",
  23009: "51101",
  2301: "25023",
  23011: "51127",
  23015: "51085",
  2302: "25023",
  23022: "51065",
  23024: "51109",
  23027: "51049",
  23030: "51036",
  23032: "51119",
  23035: "51115",
  23038: "51075",
  23039: "51075",
  23040: "51049",
  23043: "51119",
  23047: "51085",
  23050: "51115",
  23055: "51065",
  23056: "51115",
  23059: "51085",
  23060: "51087",
  23061: "51073",
  23062: "51073",
  23063: "51075",
  23065: "51075",
  23069: "51085",
  23070: "51119",
  23071: "51119",
  23072: "51073",
  23075: "51087",
  23076: "51115",
  23079: "51119",
  23083: "51007",
  23084: "51065",
  23086: "51101",
  23089: "51127",
  23092: "51119",
  23093: "51109",
  23102: "51075",
  23103: "51075",
  23106: "51101",
  23109: "51115",
  23110: "51097",
  23111: "51085",
  23112: "51041",
  23113: "51041",
  23114: "51041",
  23116: "51085",
  23117: "51109",
  23120: "51041",
  23123: "51029",
  23124: "51127",
  23128: "51115",
  23129: "51075",
  23138: "51115",
  23139: "51145",
  23140: "51127",
  23141: "51127",
  23146: "51085",
  23148: "51097",
  23149: "51119",
  23150: "51087",
  23153: "51075",
  23156: "51097",
  23168: "51095",
  23169: "51119",
  23175: "51119",
  23176: "51119",
  23177: "51097",
  23180: "51119",
  23181: "51101",
  23185: "51830",
  23188: "51095",
  23192: "51085",
  23219: "51760",
  2322: "25021",
  23220: "51760",
  23221: "51760",
  23222: "51760",
  23223: "51087",
  23224: "51760",
  23225: "51760",
  23226: "51087",
  23227: "51760",
  23228: "51087",
  23229: "51087",
  23230: "51087",
  23231: "51087",
  23233: "51087",
  23234: "51041",
  23235: "51041",
  23236: "51041",
  23237: "51041",
  23238: "51087",
  2324: "25023",
  23294: "51087",
  2330: "25023",
  23301: "51001",
  23303: "51001",
  23306: "51001",
  23308: "51001",
  23310: "51131",
  23314: "51093",
  23315: "51093",
  2332: "25023",
  23320: "51550",
  23321: "51550",
  23322: "51550",
  23323: "51550",
  23324: "51550",
  23325: "51550",
  2333: "25023",
  23336: "51001",
  23337: "51001",
  23350: "51131",
  23356: "51001",
  23357: "51001",
  23358: "51001",
  23359: "51001",
  2338: "25023",
  2339: "25023",
  23395: "51001",
  2341: "25023",
  23410: "51001",
  23415: "51001",
  23416: "51001",
  23417: "51001",
  23418: "51001",
  23420: "51001",
  23421: "51001",
  23426: "51001",
  23427: "51001",
  2343: "25021",
  23430: "51093",
  23432: "51800",
  23433: "51800",
  23434: "51800",
  23435: "51800",
  23436: "51800",
  23437: "51800",
  23438: "51800",
  23440: "51001",
  23442: "51001",
  23451: "51810",
  23452: "51810",
  23453: "51810",
  23454: "51810",
  23455: "51810",
  23456: "51810",
  23457: "51810",
  2346: "25023",
  23462: "51810",
  23464: "51810",
  2347: "25023",
  23487: "51093",
  23488: "51001",
  23502: "51710",
  23503: "51710",
  23504: "51710",
  23505: "51710",
  23507: "51710",
  23508: "51710",
  23509: "51710",
  2351: "25023",
  23510: "51710",
  23513: "51710",
  23517: "51710",
  23518: "51710",
  23523: "51710",
  2356: "25005",
  2359: "25023",
  2360: "25023",
  23601: "51700",
  23602: "51700",
  23603: "51700",
  23605: "51700",
  23606: "51700",
  23607: "51700",
  23608: "51700",
  2364: "25023",
  23661: "51650",
  23662: "51735",
  23663: "51650",
  23664: "51650",
  23666: "51650",
  23669: "51650",
  2367: "25023",
  2368: "25021",
  23690: "51199",
  23692: "51199",
  23693: "51199",
  23696: "51199",
  2370: "25023",
  23701: "51740",
  23702: "51740",
  23703: "51740",
  23704: "51740",
  23707: "51740",
  2375: "25005",
  2379: "25023",
  23803: "51730",
  23805: "51149",
  2382: "25023",
  23821: "51025",
  23824: "51135",
  23827: "51175",
  23828: "51175",
  23829: "51175",
  23830: "51149",
  23831: "51041",
  23832: "51041",
  23833: "51053",
  23834: "51041",
  23836: "51041",
  23837: "51175",
  23838: "51041",
  23839: "51181",
  23840: "51053",
  23841: "51053",
  23842: "51149",
  23843: "51025",
  23844: "51175",
  23845: "51025",
  23846: "51181",
  23847: "51081",
  23850: "51053",
  23851: "51620",
  23856: "51025",
  23857: "51025",
  23860: "51670",
  23866: "51175",
  23867: "51081",
  23868: "51025",
  23872: "51053",
  23874: "51175",
  23875: "51149",
  23876: "51025",
  23878: "51175",
  23879: "51081",
  23881: "51181",
  23882: "51183",
  23883: "51181",
  23885: "51053",
  23887: "51025",
  23888: "51181",
  23889: "51025",
  23890: "51183",
  23893: "51025",
  23894: "51053",
  23897: "51183",
  23898: "51093",
  23901: "51147",
  23915: "51117",
  23917: "51117",
  23919: "51117",
  23920: "51025",
  23921: "51029",
  23922: "51135",
  23923: "51037",
  23924: "51117",
  23927: "51117",
  23930: "51135",
  23934: "51147",
  23936: "51029",
  23937: "51037",
  23938: "51025",
  23942: "51147",
  23944: "51111",
  23947: "51111",
  23950: "51117",
  23954: "51147",
  23958: "51147",
  23959: "51037",
  23960: "51147",
  23962: "51037",
  23963: "51037",
  23964: "51037",
  23966: "51147",
  23967: "51037",
  23968: "51117",
  23970: "51117",
  23974: "51111",
  24012: "51770",
  24013: "51770",
  24014: "51770",
  24015: "51770",
  24016: "51770",
  24017: "51770",
  24018: "51161",
  24019: "51161",
  24053: "51141",
  24054: "51089",
  24055: "51089",
  24059: "51161",
  24060: "51121",
  24064: "51023",
  24065: "51067",
  24066: "51023",
  24067: "51067",
  24069: "51143",
  24070: "51045",
  24072: "51063",
  24073: "51121",
  24076: "51141",
  24077: "51023",
  24078: "51089",
  24079: "51063",
  24083: "51023",
  24084: "51155",
  24085: "51023",
  24087: "51121",
  24088: "51067",
  24089: "51089",
  24090: "51023",
  24091: "51063",
  24092: "51067",
  24095: "51019",
  24101: "51067",
  24102: "51067",
  24104: "51019",
  24105: "51063",
  24112: "51690",
  24120: "51141",
  24121: "51019",
  24122: "51019",
  24124: "51071",
  24127: "51045",
  24128: "51071",
  24133: "51141",
  24134: "51071",
  24136: "51071",
  24137: "51067",
  24138: "51121",
  24139: "51143",
  24141: "51750",
  24147: "51071",
  24148: "51089",
  24149: "51121",
  24150: "51071",
  24151: "51067",
  24153: "51775",
  24161: "51143",
  24162: "51121",
  24165: "51089",
  24167: "51071",
  24171: "51141",
  24174: "51019",
  24175: "51023",
  24176: "51067",
  24179: "51161",
  24184: "51067",
  2420: "25017",
  24201: "51520",
  24202: "51191",
  2421: "25017",
  24210: "51191",
  24211: "51191",
  24216: "51195",
  24219: "51195",
  24221: "51105",
  24224: "51167",
  24225: "51167",
  24226: "51051",
  24228: "51051",
  24230: "51195",
  24236: "51191",
  24237: "51051",
  24243: "51105",
  24244: "51169",
  24245: "51169",
  24248: "51105",
  24250: "51169",
  24251: "51169",
  24256: "51051",
  24258: "51169",
  24260: "51167",
  24263: "51105",
  24265: "51105",
  24266: "51167",
  24270: "51191",
  24271: "51169",
  24273: "51720",
  24277: "51105",
  24279: "51195",
  24280: "51167",
  24281: "51105",
  24282: "51105",
  24283: "51195",
  24290: "51169",
  24292: "51077",
  24293: "51195",
  24301: "51155",
  24311: "51173",
  24312: "51035",
  24313: "51197",
  24314: "51021",
  24315: "51021",
  24317: "51035",
  24318: "51021",
  24319: "51173",
  24323: "51197",
  24324: "51155",
  24325: "51035",
  24326: "51077",
  24328: "51035",
  24330: "51077",
  24333: "51640",
  24340: "51191",
  24343: "51035",
  24347: "51155",
  24348: "51077",
  24350: "51197",
  24351: "51035",
  24352: "51035",
  24354: "51173",
  24360: "51197",
  24361: "51191",
  24363: "51077",
  24366: "51021",
  24368: "51197",
  24370: "51173",
  24374: "51197",
  24375: "51173",
  24378: "51077",
  24380: "51063",
  24381: "51035",
  24382: "51197",
  24401: "51790",
  24413: "51091",
  24416: "51530",
  24421: "51015",
  24422: "51005",
  24426: "51005",
  24430: "51015",
  24431: "51015",
  24432: "51017",
  24435: "51163",
  24437: "51015",
  24439: "51163",
  24440: "51015",
  24441: "51015",
  24442: "51091",
  24445: "51005",
  24448: "51005",
  2445: "25021",
  24450: "51163",
  24457: "51005",
  24458: "51091",
  24459: "51163",
  2446: "25021",
  24460: "51017",
  24464: "51125",
  24465: "51091",
  24467: "51015",
  24471: "51165",
  24472: "51163",
  24473: "51163",
  24474: "51005",
  24477: "51015",
  24479: "51015",
  24482: "51015",
  24483: "51163",
  24484: "51017",
  24485: "51015",
  24486: "51015",
  24487: "51017",
  24501: "51680",
  24502: "51680",
  24503: "51680",
  24504: "51680",
  2451: "25017",
  24517: "51031",
  2452: "25017",
  24521: "51009",
  24522: "51011",
  24523: "51019",
  24526: "51019",
  24527: "51143",
  24528: "51031",
  24529: "51117",
  2453: "25017",
  24530: "51143",
  24531: "51143",
  24536: "51019",
  24538: "51011",
  24540: "51590",
  24541: "51590",
  24549: "51143",
  24550: "51031",
  24551: "51019",
  24553: "51125",
  24554: "51031",
  24555: "51163",
  24556: "51019",
  24557: "51143",
  24558: "51083",
  24562: "51029",
  24563: "51143",
  24565: "51143",
  24566: "51143",
  24569: "51143",
  24571: "51019",
  24572: "51009",
  24574: "51009",
  24577: "51083",
  24578: "51163",
  24579: "51163",
  2458: "25017",
  24580: "51117",
  24586: "51143",
  24588: "51031",
  24589: "51083",
  2459: "25017",
  24590: "51065",
  24592: "51083",
  24593: "51011",
  24594: "51083",
  24597: "51083",
  24599: "51029",
  2460: "25017",
  24602: "51185",
  24603: "51027",
  24605: "51185",
  24609: "51185",
  2461: "25017",
  24613: "51185",
  24614: "51027",
  2462: "25017",
  24620: "51027",
  24622: "51027",
  24630: "51185",
  24631: "51027",
  24634: "51027",
  24635: "51185",
  24637: "51185",
  24639: "51185",
  2464: "25017",
  24641: "51185",
  24646: "51027",
  24649: "51167",
  2465: "25017",
  24651: "51185",
  24656: "51027",
  2466: "25017",
  2467: "25021",
  2468: "25017",
  24701: "54055",
  24712: "54055",
  24714: "54055",
  24715: "54055",
  2472: "25017",
  24724: "54055",
  24726: "54109",
  24731: "54055",
  24733: "54055",
  24736: "54055",
  24737: "54055",
  24739: "54055",
  2474: "25017",
  24740: "54055",
  24747: "54055",
  2476: "25017",
  2478: "25017",
  24801: "54047",
  2481: "25021",
  24815: "54047",
  24818: "54109",
  2482: "25021",
  24822: "54109",
  24823: "54109",
  24827: "54109",
  24828: "54047",
  24831: "54047",
  24836: "54047",
  24839: "54109",
  24844: "54047",
  24850: "54047",
  24853: "54047",
  24857: "54109",
  24860: "54109",
  24862: "54047",
  24868: "54047",
  24870: "54109",
  24872: "54047",
  24873: "54047",
  24874: "54109",
  24879: "54047",
  24882: "54109",
  24892: "54047",
  24901: "54025",
  24910: "54025",
  24915: "54075",
  24916: "54025",
  24918: "54063",
  2492: "25021",
  24920: "54075",
  24924: "54075",
  24925: "54025",
  24927: "54075",
  2493: "25017",
  24931: "54025",
  24934: "54075",
  24935: "54089",
  24938: "54025",
  2494: "25021",
  24941: "54063",
  24944: "54075",
  24945: "54063",
  24946: "54075",
  24951: "54063",
  24954: "54075",
  24957: "54025",
  24963: "54063",
  24966: "54025",
  24970: "54025",
  24976: "54063",
  24977: "54025",
  24981: "54089",
  24983: "54063",
  24986: "54025",
  24991: "54025",
  25003: "54039",
  25005: "54087",
  25007: "54081",
  25009: "54005",
  25015: "54039",
  25019: "54015",
  25024: "54005",
  25028: "54005",
  25030: "54015",
  25031: "54019",
  25033: "54079",
  25035: "54039",
  25039: "54039",
  25043: "54015",
  25045: "54039",
  25048: "54081",
  25049: "54005",
  25053: "54005",
  25059: "54019",
  25060: "54081",
  25063: "54015",
  25064: "54039",
  25067: "54039",
  25070: "54079",
  25071: "54039",
  25075: "54039",
  25081: "54005",
  25082: "54053",
  25083: "54019",
  25085: "54019",
  25086: "54039",
  25093: "54005",
  25102: "54039",
  25103: "54039",
  25106: "54053",
  25107: "54039",
  25108: "54005",
  25109: "54079",
  25110: "54039",
  25111: "54015",
  25113: "54015",
  25114: "54005",
  25118: "54019",
  25119: "54019",
  25121: "54045",
  25123: "54053",
  25124: "54079",
  25125: "54015",
  25126: "54039",
  25130: "54005",
  25132: "54039",
  25133: "54015",
  25134: "54039",
  25136: "54019",
  25139: "54019",
  25140: "54081",
  25142: "54005",
  25143: "54039",
  25154: "54005",
  25159: "54079",
  25161: "54019",
  25162: "54039",
  25164: "54015",
  25165: "54005",
  25168: "54079",
  25169: "54005",
  25173: "54019",
  25174: "54081",
  25177: "54039",
  25181: "54005",
  25186: "54019",
  25187: "54053",
  25193: "54005",
  25202: "54039",
  25208: "54005",
  25209: "54081",
  25213: "54079",
  25214: "54039",
  25234: "54013",
  25235: "54013",
  25239: "54035",
  25241: "54035",
  25243: "54087",
  25244: "54035",
  25245: "54035",
  25247: "54053",
  25248: "54035",
  25251: "54087",
  25252: "54035",
  25253: "54053",
  25259: "54087",
  25260: "54053",
  25262: "54035",
  25264: "54053",
  25265: "54053",
  25266: "54087",
  25267: "54021",
  25268: "54013",
  25270: "54087",
  25271: "54035",
  25275: "54035",
  25276: "54087",
  25285: "54015",
  25286: "54087",
  25287: "54053",
  25301: "54039",
  25302: "54039",
  25303: "54039",
  25304: "54039",
  25306: "54039",
  25309: "54039",
  25311: "54039",
  25312: "54039",
  25313: "54039",
  25314: "54039",
  25315: "54039",
  2532: "25001",
  25320: "54039",
  2535: "25007",
  2536: "25001",
  2537: "25001",
  2538: "25023",
  25387: "54039",
  2539: "25007",
  2540: "25001",
  25401: "54003",
  25403: "54003",
  25404: "54003",
  25405: "54003",
  25411: "54065",
  25413: "54003",
  25414: "54037",
  25419: "54003",
  25420: "54003",
  25422: "54065",
  25425: "54037",
  25427: "54003",
  25428: "54003",
  2543: "25001",
  25430: "54037",
  25431: "54027",
  25434: "54027",
  25437: "54027",
  25438: "54037",
  25442: "54037",
  25443: "54037",
  25444: "54027",
  25446: "54037",
  25501: "54043",
  25502: "54053",
  25503: "54053",
  25504: "54011",
  25506: "54043",
  25507: "54099",
  25508: "54045",
  25510: "54011",
  25511: "54099",
  25512: "54099",
  25514: "54099",
  25515: "54053",
  25517: "54099",
  25520: "54011",
  25521: "54043",
  25523: "54043",
  25524: "54045",
  25526: "54079",
  25529: "54005",
  25530: "54099",
  25535: "54099",
  25537: "54011",
  2554: "25019",
  25541: "54011",
  25545: "54011",
  25547: "54045",
  25550: "54053",
  25555: "54099",
  25557: "54043",
  25559: "54011",
  2556: "25001",
  25560: "54079",
  25564: "54043",
  25565: "54005",
  25567: "54043",
  2557: "25007",
  25570: "54099",
  25571: "54043",
  25573: "54043",
  2559: "25001",
  25601: "54045",
  25606: "54045",
  25607: "54045",
  25608: "54059",
  25617: "54045",
  2562: "25001",
  25621: "54059",
  25625: "54045",
  2563: "25001",
  25632: "54045",
  25635: "54045",
  25638: "54045",
  25646: "54045",
  25649: "54045",
  25651: "54059",
  25661: "54059",
  25669: "54099",
  25670: "54059",
  25671: "54059",
  25674: "54059",
  25676: "54059",
  25678: "54059",
  2568: "25007",
  25701: "54011",
  25702: "54011",
  25703: "54011",
  25704: "54099",
  25705: "54011",
  2571: "25023",
  2575: "25007",
  2576: "25023",
  25801: "54081",
  25811: "54081",
  25812: "54019",
  25813: "54081",
  25817: "54081",
  25820: "54055",
  25823: "54081",
  25825: "54081",
  25827: "54081",
  25831: "54019",
  25832: "54081",
  25837: "54019",
  25839: "54081",
  25840: "54019",
  25841: "54055",
  25843: "54081",
  25844: "54081",
  25845: "54109",
  25849: "54081",
  25854: "54019",
  25862: "54019",
  25865: "54081",
  25868: "54019",
  25876: "54109",
  25880: "54081",
  25882: "54109",
  25901: "54019",
  25902: "54081",
  25908: "54081",
  25915: "54081",
  25917: "54019",
  25918: "54081",
  25920: "54081",
  25921: "54081",
  25922: "54055",
  25928: "54109",
  25932: "54081",
  25938: "54019",
  25951: "54089",
  25958: "54025",
  25962: "54019",
  25969: "54089",
  25971: "54055",
  25972: "54025",
  25976: "54019",
  25978: "54089",
  25979: "54089",
  25981: "54025",
  25984: "54025",
  25985: "54089",
  25989: "54081",
  26003: "54069",
  2601: "25001",
  26030: "54009",
  26031: "54051",
  26032: "54009",
  26033: "54051",
  26034: "54029",
  26035: "54009",
  26036: "54051",
  26037: "54009",
  26038: "54051",
  26039: "54051",
  26040: "54051",
  26041: "54051",
  26047: "54029",
  26050: "54029",
  26055: "54103",
  26059: "54069",
  26060: "54069",
  26062: "54029",
  26070: "54009",
  26074: "54069",
  26075: "54009",
  26101: "54107",
  26104: "54107",
  26105: "54107",
  26133: "54107",
  26134: "54073",
  26136: "54013",
  26137: "54021",
  26141: "54013",
  26142: "54107",
  26143: "54105",
  26146: "54095",
  26147: "54013",
  26149: "54095",
  26150: "54107",
  26151: "54013",
  26155: "54103",
  26159: "54103",
  26160: "54105",
  26164: "54035",
  26167: "54103",
  26169: "54107",
  26170: "54073",
  26175: "54095",
  26178: "54085",
  26180: "54107",
  26181: "54107",
  26184: "54107",
  26187: "54107",
  26201: "54097",
  26202: "54067",
  26205: "54067",
  26206: "54101",
  26208: "54101",
  26209: "54075",
  26217: "54101",
  26218: "54097",
  26222: "54101",
  26224: "54097",
  26234: "54097",
  26237: "54097",
  26238: "54001",
  26241: "54083",
  26250: "54001",
  26253: "54083",
  26254: "54083",
  26257: "54083",
  26260: "54093",
  26261: "54067",
  26263: "54083",
  26264: "54075",
  26267: "54083",
  26268: "54083",
  26269: "54093",
  26270: "54083",
  26273: "54083",
  26276: "54083",
  26278: "54083",
  26280: "54083",
  26282: "54083",
  26283: "54083",
  26285: "54083",
  26287: "54093",
  26288: "54101",
  26291: "54075",
  26292: "54093",
  26293: "54083",
  26294: "54083",
  2630: "25001",
  26301: "54033",
  2631: "25001",
  2632: "25001",
  26320: "54095",
  26321: "54041",
  26323: "54033",
  2633: "25001",
  26330: "54033",
  26335: "54007",
  26337: "54085",
  26338: "54041",
  26342: "54021",
  26343: "54041",
  26346: "54085",
  26347: "54091",
  2635: "25001",
  26351: "54021",
  26354: "54091",
  26362: "54085",
  26369: "54033",
  26372: "54041",
  26374: "54077",
  26376: "54041",
  26377: "54103",
  26378: "54041",
  2638: "25001",
  26384: "54021",
  26385: "54033",
  26386: "54033",
  2639: "25001",
  26405: "54001",
  26408: "54033",
  26410: "54077",
  26411: "54017",
  26412: "54007",
  26415: "54085",
  26416: "54001",
  26419: "54103",
  2642: "25001",
  26425: "54077",
  26426: "54033",
  26430: "54021",
  26431: "54033",
  26437: "54103",
  2644: "25001",
  26440: "54091",
  26444: "54077",
  26447: "54041",
  26448: "54033",
  2645: "25001",
  26451: "54033",
  26452: "54041",
  26456: "54017",
  2646: "25001",
  2648: "25001",
  2649: "25001",
  2650: "25001",
  26501: "54061",
  26505: "54061",
  26508: "54061",
  26519: "54077",
  2652: "25001",
  26521: "54061",
  26525: "54077",
  2653: "25001",
  26537: "54077",
  26541: "54061",
  26542: "54077",
  26547: "54077",
  2655: "25001",
  26554: "54049",
  26559: "54049",
  26560: "54049",
  26562: "54103",
  26563: "54049",
  26568: "54033",
  2657: "25001",
  26570: "54061",
  26571: "54049",
  26574: "54049",
  26575: "54103",
  26576: "54049",
  26581: "54103",
  26582: "54049",
  26585: "54049",
  26587: "54049",
  26588: "54049",
  2659: "25001",
  26590: "54061",
  26591: "54049",
  2660: "25001",
  26601: "54007",
  26610: "54067",
  26611: "54021",
  26619: "54007",
  26621: "54007",
  26623: "54007",
  26624: "54007",
  26627: "54007",
  26629: "54007",
  26631: "54007",
  2664: "25001",
  26651: "54067",
  26656: "54019",
  2666: "25001",
  26662: "54067",
  2667: "25001",
  26676: "54067",
  26678: "54067",
  26679: "54067",
  2668: "25001",
  26680: "54025",
  26681: "54067",
  26690: "54067",
  26691: "54067",
  2670: "25001",
  26704: "54027",
  26705: "54077",
  26707: "54023",
  2671: "25001",
  26710: "54057",
  26711: "54027",
  26714: "54027",
  26716: "54077",
  26717: "54057",
  26719: "54057",
  26720: "54023",
  26722: "54027",
  26726: "24001",
  2673: "25001",
  26731: "54023",
  26739: "54023",
  26743: "54057",
  2675: "25001",
  26750: "54057",
  26753: "54057",
  26755: "54027",
  26757: "54027",
  26761: "54027",
  26763: "54027",
  26764: "54077",
  26767: "54057",
  26801: "54031",
  26802: "54071",
  26804: "54071",
  26807: "54071",
  26808: "54027",
  26810: "54031",
  26812: "54031",
  26814: "54071",
  26815: "54071",
  26817: "54027",
  26818: "54031",
  26833: "54023",
  26836: "54031",
  26845: "54031",
  26847: "54023",
  26851: "54031",
  26852: "54027",
  26855: "54023",
  26865: "54027",
  26866: "54071",
  26884: "54071",
  27006: "37059",
  27007: "37171",
  27009: "37067",
  27011: "37197",
  27012: "37067",
  27013: "37159",
  27014: "37059",
  27016: "37169",
  27017: "37171",
  27018: "37197",
  27019: "37169",
  2702: "25005",
  27020: "37197",
  27021: "37169",
  27022: "37169",
  27023: "37067",
  27024: "37171",
  27025: "37157",
  27027: "37157",
  27028: "37059",
  2703: "25005",
  27030: "37171",
  27040: "37067",
  27041: "37171",
  27042: "37169",
  27043: "37169",
  27045: "37067",
  27046: "37169",
  27048: "37157",
  27050: "37067",
  27051: "37067",
  27052: "37169",
  27053: "37169",
  27054: "37159",
  27055: "37197",
  27101: "37067",
  27103: "37067",
  27104: "37067",
  27105: "37067",
  27106: "37067",
  27107: "37067",
  27127: "37067",
  2713: "25007",
  2715: "25005",
  2717: "25005",
  2718: "25005",
  2719: "25005",
  2720: "25005",
  27203: "37151",
  27205: "37151",
  27207: "37037",
  27208: "37037",
  27209: "37123",
  2721: "25005",
  27212: "37033",
  27214: "37081",
  27215: "37001",
  27217: "37001",
  27229: "37123",
  2723: "25005",
  27231: "37135",
  27233: "37151",
  27235: "37081",
  27239: "37057",
  2724: "25005",
  27242: "37125",
  27243: "37135",
  27244: "37001",
  27248: "37151",
  27249: "37081",
  2725: "25005",
  27252: "37037",
  27253: "37001",
  27258: "37001",
  2726: "25005",
  27260: "37081",
  27262: "37081",
  27263: "37151",
  27265: "37081",
  27278: "37135",
  27281: "37125",
  27282: "37081",
  27283: "37081",
  27284: "37067",
  27288: "37157",
  27291: "37033",
  27292: "37057",
  27295: "37057",
  27298: "37151",
  27299: "37057",
  27301: "37081",
  27302: "37001",
  27305: "37033",
  27306: "37123",
  27310: "37081",
  27311: "37033",
  27312: "37037",
  27313: "37081",
  27314: "37033",
  27315: "37033",
  27316: "37151",
  27317: "37151",
  27320: "37157",
  27325: "37125",
  27326: "37157",
  27330: "37105",
  27332: "37105",
  27341: "37151",
  27343: "37145",
  27344: "37037",
  27349: "37001",
  27350: "37151",
  27355: "37151",
  27356: "37123",
  27357: "37157",
  27358: "37081",
  27360: "37057",
  27370: "37151",
  27371: "37123",
  27376: "37125",
  27377: "37081",
  27379: "37033",
  2738: "25023",
  2739: "25023",
  2740: "25005",
  27401: "37081",
  27403: "37081",
  27405: "37081",
  27406: "37081",
  27407: "37081",
  27408: "37081",
  27409: "37081",
  27410: "37081",
  2743: "25005",
  2744: "25005",
  2745: "25005",
  27455: "37081",
  2746: "25005",
  2747: "25005",
  2748: "25005",
  27501: "37085",
  27502: "37183",
  27503: "37063",
  27504: "37101",
  27505: "37085",
  27507: "37181",
  27508: "37069",
  27509: "37077",
  27510: "37135",
  27511: "37183",
  27513: "37183",
  27514: "37135",
  27516: "37135",
  27517: "37135",
  27518: "37183",
  27519: "37183",
  27520: "37101",
  27521: "37085",
  27522: "37077",
  27523: "37183",
  27524: "37101",
  27525: "37069",
  27526: "37183",
  27527: "37101",
  27529: "37183",
  27530: "37191",
  27534: "37191",
  27536: "37181",
  27537: "37181",
  27539: "37183",
  27540: "37183",
  27541: "37145",
  27542: "37101",
  27544: "37181",
  27545: "37183",
  27546: "37085",
  27549: "37069",
  27551: "37185",
  27553: "37181",
  27557: "37127",
  27559: "37037",
  27560: "37183",
  27562: "37183",
  27563: "37185",
  27565: "37077",
  27569: "37101",
  27571: "37183",
  27572: "37063",
  27573: "37145",
  27574: "37145",
  27576: "37101",
  27577: "37101",
  27581: "37077",
  27583: "37145",
  27587: "37183",
  27589: "37185",
  27591: "37183",
  27592: "37101",
  27596: "37069",
  27597: "37183",
  2760: "25005",
  27601: "37183",
  27603: "37183",
  27604: "37183",
  27605: "37183",
  27606: "37183",
  27607: "37183",
  27608: "37183",
  27609: "37183",
  27610: "37183",
  27612: "37183",
  27613: "37183",
  27614: "37183",
  27615: "37183",
  27616: "37183",
  27617: "37183",
  2762: "25021",
  2763: "25005",
  2764: "25005",
  2766: "25005",
  2767: "25005",
  2769: "25005",
  2770: "25023",
  27701: "37063",
  27703: "37063",
  27704: "37063",
  27705: "37063",
  27707: "37063",
  2771: "25005",
  27712: "37063",
  27713: "37063",
  2777: "25005",
  2779: "25005",
  2780: "25005",
  27801: "37065",
  27803: "37127",
  27804: "37127",
  27805: "37091",
  27806: "37013",
  27807: "37127",
  27808: "37013",
  27809: "37127",
  27810: "37013",
  27812: "37065",
  27813: "37195",
  27814: "37013",
  27816: "37069",
  27817: "37013",
  27820: "37131",
  27822: "37195",
  27823: "37083",
  27824: "37095",
  27826: "37095",
  27828: "37147",
  27829: "37147",
  27830: "37191",
  27831: "37131",
  27832: "37131",
  27834: "37147",
  27837: "37147",
  27839: "37083",
  27840: "37117",
  27842: "37131",
  27843: "37117",
  27844: "37083",
  27845: "37131",
  27846: "37117",
  27847: "37015",
  27849: "37083",
  27850: "37083",
  27851: "37195",
  27852: "37065",
  27853: "37131",
  27855: "37091",
  27856: "37127",
  27857: "37117",
  27858: "37147",
  27860: "37013",
  27862: "37131",
  27863: "37191",
  27864: "37065",
  27866: "37131",
  27869: "37131",
  27870: "37083",
  27871: "37117",
  27872: "37015",
  27874: "37083",
  27875: "37095",
  27876: "37131",
  27880: "37195",
  27882: "37127",
  27883: "37079",
  27884: "37147",
  27885: "37095",
  27886: "37065",
  27888: "37079",
  27889: "37013",
  27890: "37083",
  27891: "37127",
  27892: "37117",
  27893: "37195",
  27896: "37195",
  27897: "37131",
  2790: "25005",
  27909: "37139",
  2791: "25005",
  27910: "37091",
  27915: "37055",
  27916: "37053",
  27917: "37053",
  27919: "37143",
  27920: "37055",
  27921: "37029",
  27923: "37053",
  27924: "37015",
  27925: "37177",
  27926: "37073",
  27927: "37053",
  27928: "37187",
  27929: "37053",
  27932: "37041",
  27935: "37073",
  27936: "37055",
  27937: "37073",
  27938: "37073",
  27939: "37053",
  27941: "37053",
  27943: "37055",
  27944: "37143",
  27946: "37073",
  27947: "37053",
  27948: "37055",
  27949: "37055",
  27950: "37053",
  27953: "37055",
  27954: "37055",
  27956: "37053",
  27957: "37015",
  27958: "37053",
  27959: "37055",
  27962: "37187",
  27964: "37053",
  27965: "37053",
  27966: "37053",
  27968: "37055",
  27970: "37187",
  27972: "37055",
  27973: "37053",
  27974: "37029",
  27976: "37029",
  27978: "37055",
  27979: "37073",
  27980: "37041",
  27981: "37055",
  27982: "37055",
  27983: "37015",
  28001: "37167",
  28006: "37109",
  28009: "37167",
  28012: "37071",
  28016: "37071",
  28017: "37045",
  28018: "37161",
  28020: "37045",
  28021: "37071",
  28023: "37159",
  28025: "37025",
  28027: "37025",
  28031: "37119",
  28032: "37071",
  28033: "37109",
  28034: "37071",
  28036: "37119",
  28037: "37109",
  28038: "37045",
  2804: "44009",
  28040: "37161",
  28042: "37045",
  28043: "37161",
  28052: "37071",
  28054: "37071",
  28056: "37071",
  2806: "44001",
  2807: "44009",
  28071: "37159",
  28073: "37045",
  28075: "37025",
  28078: "37119",
  28079: "37179",
  2808: "44009",
  28080: "37109",
  28081: "37025",
  28083: "37025",
  28086: "37045",
  28088: "37159",
  2809: "44001",
  28090: "37045",
  28091: "37007",
  28092: "37109",
  28097: "37167",
  28098: "37071",
  28101: "37071",
  28103: "37179",
  28104: "37179",
  28105: "37119",
  28107: "37025",
  28110: "37179",
  28112: "37179",
  28114: "37161",
  28115: "37097",
  28117: "37097",
  28119: "37007",
  2812: "44009",
  28120: "37071",
  28124: "37025",
  28125: "37159",
  28127: "37167",
  28128: "37167",
  28129: "37167",
  2813: "44009",
  28133: "37007",
  28134: "37119",
  28135: "37007",
  28136: "37045",
  28137: "37167",
  28138: "37159",
  28139: "37161",
  2814: "44007",
  28144: "37159",
  28146: "37159",
  28147: "37159",
  2815: "44007",
  28150: "37045",
  28152: "37045",
  28159: "37159",
  2816: "44003",
  28160: "37161",
  28163: "37167",
  28164: "37071",
  28166: "37097",
  28167: "37161",
  28168: "37035",
  2817: "44003",
  28170: "37007",
  28173: "37179",
  28174: "37179",
  2818: "44003",
  28202: "37119",
  28203: "37119",
  28204: "37119",
  28205: "37119",
  28206: "37119",
  28207: "37119",
  28208: "37119",
  28209: "37119",
  28210: "37119",
  28211: "37119",
  28212: "37119",
  28213: "37119",
  28214: "37119",
  28215: "37119",
  28216: "37119",
  28217: "37119",
  2822: "44009",
  28226: "37119",
  28227: "37119",
  2825: "44007",
  2826: "44007",
  28262: "37119",
  28269: "37119",
  2827: "44003",
  28270: "37119",
  28273: "37119",
  28277: "37119",
  28278: "37119",
  2828: "44007",
  2830: "44007",
  28301: "37051",
  28303: "37051",
  28304: "37051",
  28305: "37051",
  28306: "37051",
  2831: "44003",
  28311: "37051",
  28312: "37051",
  28314: "37051",
  28315: "37125",
  28318: "37163",
  2832: "44009",
  28320: "37017",
  28323: "37085",
  28326: "37085",
  28327: "37125",
  28328: "37163",
  2833: "44009",
  28333: "37191",
  28334: "37085",
  28337: "37017",
  28338: "37153",
  28339: "37085",
  28340: "37155",
  28341: "37061",
  28343: "37165",
  28344: "37051",
  28345: "37153",
  28348: "37051",
  28349: "37061",
  2835: "44005",
  28351: "37165",
  28352: "37165",
  28356: "37085",
  28357: "37093",
  28358: "37155",
  28360: "37155",
  28363: "37165",
  28364: "37155",
  28365: "37061",
  28366: "37163",
  28369: "37155",
  2837: "44005",
  28371: "37155",
  28372: "37155",
  28373: "37125",
  28374: "37125",
  28376: "37093",
  28377: "37155",
  28379: "37153",
  2838: "44007",
  28382: "37163",
  28383: "37155",
  28384: "37155",
  28385: "37163",
  28386: "37093",
  28387: "37125",
  2839: "44007",
  28390: "37085",
  28391: "37051",
  28393: "37163",
  28394: "37125",
  28395: "37051",
  28396: "37165",
  28398: "37061",
  2840: "44005",
  28401: "37129",
  28403: "37129",
  28405: "37129",
  28409: "37129",
  28411: "37129",
  28412: "37129",
  2842: "44005",
  28420: "37019",
  28421: "37141",
  28422: "37019",
  28423: "37047",
  28425: "37141",
  28428: "37129",
  28429: "37129",
  28430: "37047",
  28431: "37047",
  28432: "37047",
  28433: "37017",
  28435: "37141",
  28436: "37047",
  28438: "37047",
  28439: "37047",
  28441: "37163",
  28442: "37047",
  28443: "37141",
  28444: "37163",
  28445: "37133",
  28447: "37163",
  28449: "37129",
  28450: "37047",
  28451: "37019",
  28453: "37061",
  28454: "37133",
  28455: "37047",
  28456: "37047",
  28457: "37141",
  28458: "37061",
  28460: "37133",
  28461: "37019",
  28462: "37019",
  28463: "37047",
  28464: "37061",
  28465: "37019",
  28466: "37061",
  28467: "37019",
  28468: "37019",
  28469: "37019",
  28470: "37019",
  28472: "37047",
  28478: "37141",
  28479: "37019",
  28480: "37129",
  28501: "37107",
  28504: "37107",
  28508: "37061",
  28510: "37137",
  28511: "37031",
  28512: "37031",
  28513: "37147",
  28515: "37137",
  28516: "37031",
  28518: "37061",
  2852: "44009",
  28520: "37031",
  28521: "37061",
  28523: "37049",
  28524: "37031",
  28525: "37107",
  28526: "37049",
  28527: "37049",
  28528: "37031",
  28529: "37137",
  28530: "37147",
  28531: "37031",
  28532: "37049",
  28538: "37079",
  28539: "37133",
  28540: "37133",
  28544: "37133",
  28546: "37133",
  28551: "37107",
  28552: "37137",
  28553: "37031",
  28555: "37133",
  28556: "37137",
  28557: "37031",
  28560: "37049",
  28562: "37049",
  2857: "44007",
  28570: "37031",
  28571: "37137",
  28572: "37107",
  28573: "37103",
  28574: "37133",
  28577: "37031",
  28578: "37191",
  28579: "37031",
  2858: "44007",
  28580: "37079",
  28581: "37031",
  28582: "37133",
  28584: "37031",
  28585: "37103",
  28586: "37049",
  28587: "37137",
  2859: "44007",
  28590: "37147",
  28594: "37031",
  2860: "44007",
  28601: "37035",
  28602: "37035",
  28604: "37011",
  28605: "37189",
  28606: "37193",
  28607: "37189",
  28609: "37035",
  2861: "44007",
  28610: "37035",
  28611: "37027",
  28612: "37023",
  28613: "37035",
  28615: "37009",
  28617: "37009",
  28618: "37189",
  28621: "37171",
  28622: "37011",
  28623: "37005",
  28624: "37193",
  28625: "37097",
  28626: "37009",
  28627: "37005",
  2863: "44007",
  28630: "37027",
  28631: "37009",
  28634: "37097",
  28635: "37193",
  28636: "37003",
  28638: "37027",
  2864: "44007",
  28640: "37009",
  28642: "37197",
  28643: "37009",
  28644: "37005",
  28645: "37027",
  28646: "37011",
  2865: "44007",
  28650: "37035",
  28651: "37193",
  28654: "37193",
  28655: "37023",
  28657: "37011",
  28658: "37035",
  28659: "37193",
  28660: "37097",
  28663: "37005",
  28665: "37193",
  28668: "37005",
  28669: "37193",
  28670: "37193",
  28673: "37035",
  28675: "37005",
  28676: "37171",
  28677: "37097",
  28678: "37003",
  28679: "37189",
  28681: "37003",
  28682: "37035",
  28683: "37171",
  28684: "37189",
  28685: "37193",
  28689: "37097",
  28690: "37023",
  28692: "37189",
  28693: "37009",
  28694: "37009",
  28697: "37193",
  28698: "37189",
  28701: "37021",
  28702: "37075",
  28704: "37021",
  28705: "37121",
  28707: "37099",
  28708: "37175",
  28709: "37021",
  2871: "44005",
  28711: "37021",
  28712: "37175",
  28713: "37173",
  28714: "37199",
  28715: "37021",
  28716: "37087",
  28717: "37099",
  28718: "37175",
  28721: "37087",
  28722: "37149",
  28723: "37099",
  28726: "37089",
  28729: "37089",
  2873: "44009",
  28730: "37021",
  28731: "37089",
  28732: "37089",
  28734: "37113",
  28736: "37099",
  28739: "37089",
  2874: "44009",
  28740: "37199",
  28741: "37113",
  28742: "37089",
  28743: "37115",
  28745: "37087",
  28746: "37161",
  28747: "37175",
  28748: "37021",
  28751: "37087",
  28752: "37111",
  28753: "37115",
  28754: "37115",
  28756: "37149",
  28759: "37089",
  28761: "37111",
  28762: "37111",
  28763: "37113",
  28766: "37175",
  28768: "37175",
  28771: "37075",
  28772: "37175",
  28773: "37149",
  28774: "37175",
  28775: "37113",
  28777: "37121",
  28778: "37021",
  28779: "37099",
  2878: "44005",
  28781: "37113",
  28782: "37149",
  28783: "37099",
  28785: "37087",
  28786: "37087",
  28787: "37021",
  28789: "37099",
  2879: "44009",
  28790: "37089",
  28791: "37089",
  28792: "37089",
  28801: "37021",
  28803: "37021",
  28804: "37021",
  28805: "37021",
  28806: "37021",
  2881: "44009",
  2882: "44009",
  2885: "44001",
  2886: "44003",
  2888: "44003",
  2889: "44003",
  28901: "37039",
  28902: "37039",
  28904: "37043",
  28905: "37039",
  28906: "37039",
  28909: "37043",
  2891: "44009",
  2892: "44009",
  2893: "44003",
  2894: "44009",
  2895: "44007",
  2896: "44007",
  2898: "44009",
  29001: "45027",
  29003: "45009",
  29006: "45063",
  29009: "45055",
  29010: "45061",
  29014: "45023",
  29015: "45039",
  29016: "45079",
  29018: "45075",
  29020: "45055",
  2903: "44007",
  29030: "45017",
  29031: "45023",
  29032: "45055",
  29033: "45063",
  29036: "45063",
  29037: "45071",
  29038: "45075",
  29039: "45075",
  2904: "44007",
  29040: "45085",
  29042: "45009",
  29044: "45079",
  29045: "45055",
  29047: "45075",
  29048: "45075",
  2905: "44007",
  29051: "45085",
  29052: "45079",
  29053: "45063",
  29054: "45063",
  29055: "45023",
  29056: "45089",
  29058: "45057",
  29059: "45075",
  2906: "44007",
  29061: "45079",
  29063: "45079",
  29065: "45039",
  29067: "45057",
  29069: "45031",
  2907: "44007",
  29070: "45063",
  29072: "45063",
  29073: "45063",
  29074: "45055",
  29075: "45071",
  29078: "45055",
  2908: "44007",
  29080: "45085",
  29081: "45009",
  29082: "45029",
  2909: "44007",
  2910: "44007",
  29101: "45025",
  29102: "45027",
  29104: "45061",
  29105: "45003",
  29107: "45075",
  29108: "45071",
  2911: "44007",
  29112: "45075",
  29114: "45041",
  29115: "45075",
  29118: "45075",
  29123: "45063",
  29125: "45085",
  29126: "45071",
  29127: "45071",
  29128: "45085",
  29129: "45003",
  29130: "45039",
  29135: "45017",
  29137: "45003",
  29138: "45081",
  2914: "44007",
  29142: "45075",
  29146: "45003",
  29148: "45027",
  2915: "44007",
  29150: "45085",
  29153: "45085",
  29154: "45085",
  2916: "44007",
  29160: "45063",
  29161: "45041",
  29162: "45027",
  29163: "45075",
  29164: "45003",
  29168: "45085",
  29169: "45063",
  2917: "44007",
  29170: "45063",
  29172: "45063",
  29175: "45055",
  29178: "45071",
  29180: "45039",
  2919: "44007",
  2920: "44007",
  29201: "45079",
  29203: "45079",
  29204: "45079",
  29205: "45079",
  29206: "45079",
  29209: "45079",
  2921: "44007",
  29210: "45079",
  29212: "45063",
  29223: "45079",
  29229: "45079",
  29301: "45083",
  29302: "45083",
  29303: "45083",
  29306: "45083",
  29307: "45083",
  29316: "45083",
  29320: "45083",
  29322: "45083",
  29323: "45083",
  29325: "45059",
  29330: "45021",
  29332: "45059",
  29333: "45083",
  29334: "45083",
  29335: "45083",
  29340: "45021",
  29341: "45021",
  29349: "45083",
  29351: "45059",
  29353: "45087",
  29355: "45059",
  29356: "45045",
  29360: "45059",
  29365: "45083",
  29368: "45083",
  29369: "45083",
  29370: "45059",
  29372: "45083",
  29374: "45083",
  29376: "45083",
  29377: "45083",
  29379: "45087",
  29384: "45059",
  29385: "45083",
  29388: "45083",
  29401: "45019",
  29403: "45019",
  29405: "45019",
  29406: "45019",
  29407: "45019",
  29410: "45015",
  29412: "45019",
  29414: "45019",
  29418: "45019",
  29420: "45035",
  29426: "45019",
  29429: "45019",
  29431: "45015",
  29432: "45075",
  29434: "45015",
  29435: "45029",
  29436: "45015",
  29437: "45035",
  29438: "45019",
  29439: "45019",
  29440: "45043",
  29445: "45015",
  29446: "45029",
  29448: "45035",
  29449: "45019",
  29450: "45015",
  29451: "45019",
  29453: "45015",
  29455: "45019",
  29456: "45035",
  29458: "45019",
  29461: "45015",
  29464: "45019",
  29466: "45019",
  29468: "45015",
  29469: "45015",
  29470: "45019",
  29471: "45035",
  29472: "45035",
  29474: "45029",
  29475: "45029",
  29477: "45035",
  29479: "45015",
  29481: "45029",
  29482: "45019",
  29483: "45035",
  29485: "45035",
  29486: "45015",
  29487: "45019",
  29488: "45029",
  29492: "45015",
  29501: "45041",
  29505: "45041",
  29506: "45041",
  29510: "45043",
  29511: "45051",
  29512: "45069",
  29516: "45069",
  29518: "45089",
  29520: "45025",
  29525: "45069",
  29526: "45051",
  29527: "45051",
  29530: "45041",
  29532: "45031",
  29536: "45033",
  29540: "45031",
  29541: "45041",
  29543: "45033",
  29544: "45051",
  29545: "45051",
  29546: "45067",
  29547: "45033",
  29550: "45031",
  29554: "45089",
  29555: "45041",
  29556: "45089",
  29560: "45041",
  29563: "45033",
  29564: "45089",
  29565: "45033",
  29566: "45051",
  29568: "45051",
  29569: "45051",
  29570: "45069",
  29571: "45067",
  29572: "45051",
  29574: "45067",
  29575: "45051",
  29576: "45051",
  29577: "45051",
  29579: "45051",
  29580: "45089",
  29581: "45051",
  29582: "45051",
  29583: "45041",
  29584: "45025",
  29585: "45043",
  29588: "45051",
  29590: "45089",
  29591: "45041",
  29592: "45067",
  29593: "45025",
  29596: "45069",
  29601: "45045",
  29605: "45045",
  29607: "45045",
  29609: "45045",
  29611: "45045",
  29615: "45045",
  29617: "45045",
  29620: "45001",
  29621: "45007",
  29624: "45007",
  29625: "45007",
  29626: "45007",
  29627: "45007",
  29628: "45001",
  29630: "45077",
  29631: "45077",
  29635: "45045",
  29638: "45001",
  29639: "45001",
  29640: "45077",
  29642: "45077",
  29643: "45073",
  29644: "45045",
  29645: "45059",
  29646: "45047",
  29649: "45047",
  29650: "45045",
  29651: "45045",
  29653: "45047",
  29654: "45007",
  29655: "45007",
  29657: "45077",
  29658: "45073",
  29661: "45045",
  29662: "45045",
  29664: "45073",
  29666: "45047",
  29667: "45077",
  29669: "45045",
  29670: "45007",
  29671: "45077",
  29672: "45073",
  29673: "45045",
  29676: "45073",
  29678: "45073",
  29680: "45045",
  29681: "45045",
  29682: "45077",
  29683: "45045",
  29684: "45007",
  29685: "45077",
  29686: "45073",
  29687: "45045",
  29689: "45007",
  29690: "45045",
  29691: "45073",
  29692: "45059",
  29693: "45073",
  29696: "45073",
  29697: "45007",
  29702: "45021",
  29704: "45091",
  29706: "45023",
  29707: "45057",
  29708: "45091",
  29709: "45025",
  29710: "45091",
  29712: "45023",
  29714: "45023",
  29715: "45091",
  29717: "45091",
  29718: "45025",
  29720: "45057",
  29726: "45091",
  29727: "45025",
  29728: "45025",
  29729: "45023",
  29730: "45091",
  29732: "45091",
  29741: "45025",
  29742: "45091",
  29743: "45091",
  29745: "45091",
  29801: "45003",
  29803: "45003",
  29805: "45003",
  29809: "45003",
  29810: "45005",
  29812: "45011",
  29817: "45011",
  29819: "45047",
  29821: "45037",
  29824: "45037",
  29827: "45005",
  29828: "45003",
  29829: "45003",
  29831: "45003",
  29832: "45037",
  29835: "45065",
  29841: "45003",
  29842: "45003",
  29843: "45009",
  29847: "45037",
  29848: "45047",
  29851: "45003",
  29853: "45011",
  29856: "45003",
  29860: "45037",
  29902: "45013",
  29906: "45013",
  29907: "45013",
  29909: "45013",
  29910: "45013",
  29911: "45049",
  29915: "45013",
  29916: "45049",
  29918: "45049",
  29920: "45013",
  29922: "45053",
  29924: "45049",
  29926: "45013",
  29927: "45053",
  29928: "45013",
  29929: "45029",
  29935: "45013",
  29936: "45053",
  29940: "45013",
  29941: "45013",
  29944: "45049",
  29945: "45013",
  30002: "13089",
  30004: "13121",
  30005: "13121",
  30008: "13067",
  30009: "13121",
  30011: "13013",
  30012: "13247",
  30013: "13247",
  30014: "13217",
  30016: "13217",
  30017: "13135",
  30019: "13135",
  30021: "13089",
  30022: "13121",
  30024: "13135",
  30025: "13297",
  30028: "13117",
  30030: "13089",
  30032: "13089",
  30033: "13089",
  30034: "13089",
  30035: "13089",
  30038: "13089",
  30039: "13135",
  30040: "13117",
  30041: "13117",
  30043: "13135",
  30044: "13135",
  30045: "13135",
  30046: "13135",
  30047: "13135",
  30052: "13297",
  30054: "13217",
  30055: "13217",
  30056: "13159",
  30058: "13089",
  30060: "13067",
  30062: "13067",
  30064: "13067",
  30066: "13067",
  30067: "13067",
  30068: "13067",
  30071: "13135",
  30072: "13089",
  30075: "13121",
  30076: "13121",
  30078: "13135",
  30079: "13089",
  30080: "13067",
  30082: "13067",
  30083: "13089",
  30084: "13089",
  30087: "13089",
  30088: "13089",
  30092: "13135",
  30093: "13135",
  30094: "13247",
  30096: "13135",
  30097: "13135",
  30101: "13067",
  30102: "13057",
  30103: "13015",
  30104: "13233",
  30105: "13115",
  30106: "13067",
  30107: "13057",
  30108: "13045",
  30110: "13143",
  30113: "13143",
  30114: "13057",
  30115: "13057",
  30116: "13045",
  30117: "13045",
  30120: "13015",
  30121: "13015",
  30122: "13097",
  30124: "13115",
  30125: "13233",
  30126: "13067",
  30127: "13067",
  30132: "13223",
  30134: "13097",
  30135: "13097",
  30137: "13015",
  30139: "13129",
  30141: "13223",
  30143: "13227",
  30144: "13067",
  30145: "13015",
  30147: "13115",
  30148: "13227",
  30152: "13067",
  30153: "13233",
  30157: "13223",
  30161: "13115",
  30165: "13115",
  30168: "13067",
  30170: "13045",
  30171: "13015",
  30173: "13115",
  30175: "13227",
  30176: "13143",
  30177: "13227",
  30178: "13233",
  30179: "13045",
  30180: "13045",
  30182: "13045",
  30183: "13057",
  30184: "13015",
  30185: "13045",
  30187: "13097",
  30188: "13057",
  30189: "13057",
  30204: "13171",
  30205: "13113",
  30206: "13231",
  30213: "13121",
  30214: "13113",
  30215: "13113",
  30216: "13035",
  30217: "13149",
  30218: "13199",
  30220: "13077",
  30222: "13199",
  30223: "13255",
  30224: "13255",
  30228: "13063",
  30230: "13285",
  30233: "13035",
  30234: "13035",
  30236: "13063",
  30238: "13063",
  30240: "13285",
  30241: "13285",
  30248: "13151",
  30251: "13199",
  30252: "13151",
  30253: "13151",
  30256: "13231",
  30257: "13171",
  30258: "13231",
  30259: "13077",
  30260: "13063",
  30263: "13077",
  30265: "13077",
  30268: "13121",
  30269: "13113",
  30273: "13063",
  30274: "13063",
  30276: "13077",
  30277: "13077",
  30281: "13151",
  30285: "13293",
  30286: "13293",
  30288: "13089",
  30290: "13113",
  30291: "13121",
  30292: "13231",
  30293: "13199",
  30294: "13151",
  30295: "13231",
  30296: "13063",
  30297: "13063",
  30303: "13121",
  30305: "13121",
  30306: "13121",
  30307: "13089",
  30308: "13121",
  30309: "13121",
  3031: "33011",
  30310: "13121",
  30311: "13121",
  30312: "13121",
  30313: "13121",
  30314: "13121",
  30315: "13121",
  30316: "13089",
  30317: "13089",
  30318: "13121",
  30319: "13089",
  3032: "33015",
  30324: "13121",
  30326: "13121",
  30327: "13121",
  30328: "13121",
  30329: "13089",
  3033: "33011",
  30331: "13121",
  30336: "13121",
  30337: "13121",
  30338: "13089",
  30339: "13067",
  3034: "33015",
  30340: "13089",
  30341: "13089",
  30342: "13121",
  30344: "13121",
  30345: "13089",
  30346: "13089",
  30349: "13121",
  30350: "13121",
  30354: "13121",
  3036: "33015",
  30360: "13089",
  30363: "13121",
  3037: "33015",
  3038: "33015",
  30401: "13107",
  30410: "13209",
  30411: "13309",
  30413: "13303",
  30415: "13031",
  30417: "13109",
  3042: "33015",
  30420: "13043",
  30421: "13267",
  30425: "13107",
  30426: "13033",
  30427: "13267",
  30428: "13309",
  3043: "33011",
  30434: "13163",
  30436: "13279",
  30439: "13043",
  3044: "33015",
  30441: "13107",
  30442: "13165",
  30445: "13209",
  30446: "13251",
  3045: "33011",
  30450: "13031",
  30452: "13031",
  30453: "13267",
  30454: "13175",
  30455: "13251",
  30456: "13033",
  30457: "13283",
  30458: "13031",
  3046: "33013",
  30461: "13031",
  30467: "13251",
  3047: "33011",
  30470: "13283",
  30471: "13107",
  30473: "13209",
  30474: "13279",
  30477: "13163",
  3048: "33011",
  3049: "33011",
  30501: "13139",
  30504: "13139",
  30506: "13139",
  30507: "13139",
  3051: "33011",
  30510: "13137",
  30511: "13011",
  30512: "13291",
  30513: "13111",
  30516: "13147",
  30517: "13135",
  30518: "13135",
  30519: "13135",
  3052: "33011",
  30520: "13147",
  30521: "13119",
  30522: "13123",
  30523: "13137",
  30525: "13241",
  30527: "13139",
  30528: "13311",
  30529: "13157",
  3053: "33015",
  30530: "13011",
  30531: "13137",
  30533: "13187",
  30534: "13085",
  30535: "13137",
  30536: "13123",
  30537: "13241",
  30538: "13257",
  3054: "33011",
  30540: "13123",
  30541: "13111",
  30542: "13139",
  30543: "13139",
  30545: "13311",
  30546: "13281",
  30547: "13011",
  30548: "13013",
  30549: "13157",
  3055: "33011",
  30552: "13241",
  30553: "13119",
  30554: "13139",
  30555: "13111",
  30557: "13257",
  30558: "13011",
  30559: "13111",
  30560: "13111",
  30563: "13137",
  30564: "13187",
  30565: "13157",
  30566: "13139",
  30567: "13157",
  30568: "13241",
  3057: "33011",
  30571: "13311",
  30572: "13111",
  30575: "13139",
  30576: "13241",
  30577: "13257",
  30582: "13281",
  3060: "33011",
  30601: "13059",
  30605: "13059",
  30606: "13059",
  30607: "13059",
  30619: "13221",
  3062: "33011",
  30620: "13013",
  30621: "13219",
  30622: "13219",
  30624: "13105",
  30625: "13211",
  30627: "13221",
  30628: "13195",
  30629: "13195",
  3063: "33011",
  30630: "13221",
  30631: "13265",
  30633: "13195",
  30634: "13105",
  30635: "13105",
  3064: "33011",
  30641: "13297",
  30642: "13133",
  30643: "13147",
  30646: "13195",
  30648: "13221",
  30650: "13211",
  30655: "13297",
  30656: "13297",
  30660: "13317",
  30662: "13119",
  30663: "13211",
  30666: "13013",
  30667: "13221",
  30668: "13317",
  30669: "13133",
  30673: "13317",
  30677: "13219",
  30678: "13133",
  30680: "13013",
  30683: "13059",
  3070: "33011",
  30701: "13129",
  30705: "13213",
  30707: "13295",
  30708: "13213",
  3071: "33011",
  30710: "13313",
  30711: "13213",
  30720: "13313",
  30721: "13313",
  30725: "13295",
  30728: "13295",
  30730: "13055",
  30731: "13295",
  30733: "13129",
  30734: "13129",
  30735: "13129",
  30736: "13047",
  30738: "13083",
  30739: "13295",
  30740: "13313",
  30741: "13295",
  30742: "13047",
  30746: "13129",
  30747: "13055",
  30750: "13083",
  30752: "13083",
  30753: "13295",
  30755: "13313",
  30757: "13083",
  3076: "33011",
  3077: "33015",
  3079: "33015",
  30802: "13073",
  30803: "13163",
  30805: "13033",
  30808: "13189",
  30809: "13073",
  30810: "13125",
  30813: "13073",
  30814: "13073",
  30815: "13245",
  30816: "13163",
  30817: "13181",
  30818: "13163",
  3082: "33011",
  30820: "13125",
  30821: "13301",
  30823: "13163",
  30824: "13189",
  30828: "13301",
  30830: "13033",
  30833: "13163",
  3084: "33011",
  3086: "33011",
  3087: "33015",
  30901: "13245",
  30904: "13245",
  30906: "13245",
  30907: "13073",
  30909: "13245",
  31001: "13315",
  31002: "13107",
  31005: "13153",
  31006: "13269",
  31007: "13093",
  31008: "13225",
  31009: "13175",
  3101: "33011",
  31011: "13091",
  31012: "13091",
  31014: "13023",
  31015: "13081",
  31016: "13079",
  31017: "13289",
  31018: "13303",
  31019: "13175",
  3102: "33011",
  31020: "13289",
  31021: "13175",
  31022: "13175",
  31023: "13091",
  31024: "13237",
  31025: "13153",
  31027: "13175",
  31028: "13153",
  31029: "13207",
  3103: "33011",
  31030: "13225",
  31031: "13319",
  31032: "13169",
  31033: "13169",
  31035: "13303",
  31036: "13235",
  31037: "13271",
  31038: "13169",
  3104: "33011",
  31041: "13193",
  31042: "13319",
  31044: "13289",
  31046: "13207",
  31047: "13153",
  31049: "13167",
  31050: "13079",
  31052: "13021",
  31054: "13319",
  31055: "13271",
  31057: "13193",
  31058: "13269",
  3106: "33013",
  31060: "13271",
  31061: "13009",
  31063: "13193",
  31064: "13159",
  31065: "13175",
  31066: "13079",
  31068: "13193",
  31069: "13153",
  31070: "13093",
  31071: "13235",
  31072: "13315",
  31075: "13175",
  31076: "13269",
  31077: "13091",
  31078: "13079",
  31079: "13315",
  31081: "13269",
  31082: "13303",
  31085: "13159",
  31087: "13141",
  31088: "13153",
  31089: "13303",
  3109: "33011",
  31090: "13319",
  31091: "13093",
  31092: "13093",
  31093: "13153",
  31094: "13303",
  31096: "13167",
  31097: "13293",
  3110: "33011",
  31201: "13021",
  31204: "13021",
  31206: "13021",
  31210: "13021",
  31211: "13021",
  31216: "13021",
  31217: "13021",
  31220: "13021",
  31301: "13179",
  31302: "13051",
  31303: "13103",
  31305: "13191",
  31307: "13103",
  31308: "13029",
  31309: "13179",
  31312: "13103",
  31313: "13179",
  31316: "13183",
  31318: "13103",
  31320: "13179",
  31321: "13029",
  31322: "13051",
  31323: "13179",
  31324: "13029",
  31326: "13103",
  31328: "13051",
  31329: "13103",
  31331: "13191",
  31401: "13051",
  31404: "13051",
  31405: "13051",
  31406: "13051",
  31407: "13051",
  31408: "13051",
  31410: "13051",
  31411: "13051",
  31415: "13051",
  31419: "13051",
  31501: "13299",
  31503: "13299",
  31510: "13005",
  31512: "13069",
  31513: "13001",
  31516: "13229",
  31518: "13001",
  31519: "13069",
  31520: "13127",
  31522: "13127",
  31523: "13127",
  31525: "13127",
  31527: "13127",
  31532: "13161",
  31533: "13069",
  31535: "13069",
  31537: "13049",
  31539: "13161",
  31542: "13025",
  31543: "13025",
  31544: "13271",
  31545: "13305",
  31546: "13305",
  31548: "13039",
  31549: "13271",
  31550: "13299",
  31551: "13229",
  31552: "13299",
  31553: "13025",
  31554: "13069",
  31555: "13305",
  31557: "13229",
  31558: "13039",
  31560: "13305",
  31561: "13127",
  31562: "13049",
  31563: "13001",
  31565: "13039",
  31566: "13025",
  31567: "13069",
  31568: "13039",
  31569: "13039",
  31601: "13185",
  31602: "13185",
  31605: "13185",
  31606: "13185",
  31620: "13075",
  31622: "13019",
  31624: "13003",
  31625: "13027",
  31626: "13275",
  31629: "13027",
  31630: "13065",
  31631: "13101",
  31632: "13185",
  31634: "13065",
  31635: "13173",
  31636: "13185",
  31637: "13019",
  31638: "13027",
  31639: "13019",
  31641: "13185",
  31642: "13003",
  31643: "13027",
  31645: "13019",
  31647: "13075",
  31648: "13101",
  31649: "13173",
  31650: "13069",
  31701: "13095",
  31705: "13095",
  31707: "13095",
  31709: "13261",
  31711: "13261",
  31712: "13081",
  31714: "13287",
  31716: "13205",
  31719: "13261",
  31721: "13095",
  31722: "13071",
  31730: "13205",
  31733: "13277",
  31735: "13261",
  31738: "13275",
  31743: "13177",
  31744: "13071",
  31749: "13019",
  31750: "13017",
  31756: "13071",
  31757: "13275",
  31763: "13177",
  31764: "13261",
  31765: "13275",
  31768: "13071",
  31771: "13071",
  31773: "13275",
  31774: "13155",
  31775: "13071",
  31778: "13027",
  31779: "13205",
  31780: "13261",
  31781: "13321",
  31783: "13155",
  31784: "13205",
  31787: "13177",
  31788: "13071",
  31789: "13321",
  31790: "13287",
  31791: "13321",
  31792: "13275",
  31793: "13277",
  31794: "13277",
  31795: "13321",
  31796: "13321",
  31798: "13155",
  31801: "13263",
  31803: "13197",
  31804: "13145",
  31805: "13053",
  31806: "13249",
  31807: "13145",
  31808: "13145",
  31811: "13145",
  31812: "13263",
  31815: "13259",
  31816: "13199",
  31820: "13215",
  31821: "13259",
  31822: "13285",
  31823: "13145",
  31824: "13307",
  31825: "13259",
  31826: "13145",
  31827: "13263",
  31829: "13215",
  31830: "13199",
  31831: "13145",
  31833: "13285",
  31836: "13263",
  31901: "13215",
  31903: "13215",
  31904: "13215",
  31906: "13215",
  31907: "13215",
  31909: "13215",
  32003: "12019",
  32008: "12121",
  32009: "12089",
  32011: "12089",
  32024: "12023",
  32025: "12023",
  32033: "12109",
  32034: "12089",
  32038: "12023",
  32040: "12003",
  32043: "12019",
  32044: "12007",
  32046: "12089",
  32052: "12047",
  32053: "12047",
  32054: "12125",
  32055: "12023",
  32058: "12007",
  32059: "12079",
  32060: "12121",
  32063: "12003",
  32064: "12121",
  32065: "12019",
  32066: "12067",
  32068: "12019",
  32071: "12121",
  32073: "12019",
  32080: "12109",
  32081: "12109",
  32082: "12109",
  32084: "12109",
  32086: "12109",
  32087: "12003",
  32091: "12007",
  32092: "12109",
  32095: "12109",
  32096: "12047",
  32097: "12089",
  32102: "12069",
  32110: "12035",
  32112: "12107",
  32113: "12083",
  32114: "12127",
  32117: "12127",
  32118: "12127",
  32119: "12127",
  32124: "12127",
  32127: "12127",
  32128: "12127",
  32129: "12127",
  32130: "12127",
  32131: "12107",
  32132: "12127",
  32134: "12083",
  32136: "12035",
  32137: "12035",
  32139: "12107",
  32140: "12107",
  32141: "12127",
  32145: "12109",
  32148: "12107",
  3215: "33009",
  32159: "12069",
  3216: "33013",
  32162: "12119",
  32163: "12119",
  32164: "12035",
  32168: "12127",
  32169: "12127",
  3217: "33009",
  32174: "12127",
  32176: "12127",
  32177: "12107",
  32179: "12083",
  3218: "33001",
  32180: "12127",
  32181: "12107",
  32187: "12107",
  32189: "12107",
  32190: "12127",
  32193: "12107",
  32195: "12083",
  3220: "33001",
  32202: "12031",
  32204: "12031",
  32205: "12031",
  32206: "12031",
  32207: "12031",
  32208: "12031",
  32209: "12031",
  3221: "33013",
  32210: "12031",
  32211: "12031",
  32216: "12031",
  32217: "12031",
  32218: "12031",
  32219: "12031",
  3222: "33009",
  32220: "12031",
  32221: "12031",
  32222: "12031",
  32223: "12031",
  32224: "12031",
  32225: "12031",
  32226: "12031",
  3223: "33009",
  32233: "12031",
  32234: "12031",
  3224: "33013",
  32244: "12031",
  32246: "12031",
  3225: "33001",
  32250: "12031",
  32254: "12031",
  32256: "12031",
  32257: "12031",
  32258: "12031",
  32259: "12109",
  3226: "33001",
  32266: "12031",
  3227: "33003",
  32277: "12031",
  3229: "33013",
  3230: "33013",
  32301: "12073",
  32303: "12073",
  32304: "12073",
  32305: "12073",
  32308: "12073",
  32309: "12073",
  32310: "12073",
  32311: "12073",
  32312: "12073",
  32317: "12073",
  32320: "12037",
  32321: "12077",
  32322: "12037",
  32323: "12037",
  32324: "12039",
  32327: "12129",
  32328: "12037",
  32330: "12039",
  32331: "12079",
  32332: "12039",
  32333: "12039",
  32334: "12077",
  32336: "12065",
  3234: "33013",
  32340: "12079",
  32343: "12039",
  32344: "12065",
  32346: "12129",
  32347: "12123",
  32348: "12123",
  3235: "33013",
  32350: "12079",
  32351: "12039",
  32352: "12039",
  32355: "12129",
  32358: "12129",
  32359: "12029",
  3237: "33001",
  3240: "33009",
  32401: "12005",
  32404: "12005",
  32405: "12005",
  32407: "12005",
  32408: "12005",
  32409: "12005",
  3241: "33009",
  32413: "12005",
  3242: "33013",
  32420: "12063",
  32421: "12013",
  32423: "12063",
  32424: "12013",
  32425: "12059",
  32426: "12063",
  32427: "12133",
  32428: "12133",
  3243: "33013",
  32430: "12013",
  32431: "12063",
  32433: "12131",
  32435: "12131",
  32438: "12005",
  32439: "12131",
  3244: "33011",
  32440: "12063",
  32442: "12063",
  32443: "12063",
  32444: "12005",
  32445: "12063",
  32446: "12063",
  32448: "12063",
  32449: "12013",
  3245: "33009",
  32455: "12131",
  32456: "12045",
  32459: "12131",
  3246: "33001",
  32460: "12063",
  32461: "12131",
  32462: "12133",
  32464: "12059",
  32465: "12045",
  32466: "12005",
  3249: "33001",
  32501: "12033",
  32502: "12033",
  32503: "12033",
  32504: "12033",
  32505: "12033",
  32506: "12033",
  32507: "12033",
  3251: "33009",
  32514: "12033",
  32526: "12033",
  3253: "33001",
  32531: "12091",
  32533: "12033",
  32534: "12033",
  32535: "12033",
  32536: "12091",
  32539: "12091",
  3254: "33003",
  32541: "12091",
  32547: "12091",
  32548: "12091",
  3255: "33013",
  32550: "12131",
  3256: "33001",
  32561: "12113",
  32563: "12113",
  32564: "12091",
  32565: "12113",
  32566: "12113",
  32567: "12091",
  32568: "12033",
  32569: "12091",
  3257: "33013",
  32570: "12113",
  32571: "12113",
  32577: "12033",
  32578: "12091",
  32579: "12091",
  3258: "33013",
  32580: "12091",
  32583: "12113",
  3259: "33003",
  32601: "12001",
  32603: "12001",
  32605: "12001",
  32606: "12001",
  32607: "12001",
  32608: "12001",
  32609: "12001",
  3261: "33015",
  32615: "12001",
  32617: "12083",
  32618: "12001",
  32619: "12041",
  3262: "33009",
  32621: "12075",
  32622: "12007",
  32625: "12075",
  32626: "12075",
  32628: "12029",
  3263: "33013",
  32631: "12001",
  3264: "33009",
  32640: "12001",
  32641: "12001",
  32643: "12001",
  32648: "12029",
  32653: "12001",
  32656: "12019",
  3266: "33009",
  32664: "12083",
  32666: "12019",
  32667: "12001",
  32668: "12075",
  32669: "12001",
  3268: "33013",
  32680: "12029",
  32686: "12083",
  3269: "33001",
  32693: "12041",
  32694: "12001",
  32696: "12075",
  32701: "12117",
  32702: "12083",
  32703: "12095",
  32707: "12117",
  32708: "12117",
  32709: "12095",
  32712: "12095",
  32713: "12127",
  32714: "12117",
  32720: "12127",
  32724: "12127",
  32725: "12127",
  32726: "12069",
  32730: "12117",
  32732: "12117",
  32735: "12069",
  32736: "12069",
  32738: "12127",
  32744: "12127",
  32746: "12117",
  3275: "33013",
  32750: "12117",
  32751: "12095",
  32754: "12009",
  32757: "12069",
  32759: "12127",
  3276: "33013",
  32763: "12127",
  32764: "12127",
  32765: "12117",
  32766: "12117",
  32767: "12069",
  32771: "12117",
  32773: "12117",
  32776: "12069",
  32778: "12069",
  32779: "12117",
  3278: "33013",
  32780: "12009",
  32784: "12069",
  32789: "12095",
  3279: "33009",
  32792: "12095",
  32796: "12009",
  32798: "12095",
  3280: "33019",
  32801: "12095",
  32803: "12095",
  32804: "12095",
  32805: "12095",
  32806: "12095",
  32807: "12095",
  32808: "12095",
  32809: "12095",
  3281: "33011",
  32810: "12095",
  32811: "12095",
  32812: "12095",
  32814: "12095",
  32817: "12095",
  32818: "12095",
  32819: "12095",
  3282: "33009",
  32820: "12095",
  32821: "12095",
  32822: "12095",
  32824: "12095",
  32825: "12095",
  32826: "12095",
  32827: "12095",
  32828: "12095",
  32829: "12095",
  32832: "12095",
  32833: "12095",
  32835: "12095",
  32836: "12095",
  32837: "12095",
  32839: "12095",
  3284: "33019",
  3285: "33009",
  3287: "33013",
  3290: "33015",
  32901: "12009",
  32903: "12009",
  32904: "12009",
  32905: "12009",
  32907: "12009",
  32908: "12009",
  32909: "12009",
  32920: "12009",
  32922: "12009",
  32926: "12009",
  32927: "12009",
  3293: "33009",
  32931: "12009",
  32934: "12009",
  32935: "12009",
  32937: "12009",
  32940: "12009",
  32948: "12061",
  32949: "12009",
  32950: "12009",
  32951: "12009",
  32952: "12009",
  32953: "12009",
  32955: "12009",
  32958: "12061",
  32960: "12061",
  32962: "12061",
  32963: "12061",
  32966: "12061",
  32967: "12061",
  32968: "12061",
  32976: "12009",
  33001: "12087",
  33004: "12011",
  33009: "12011",
  3301: "33013",
  33010: "12086",
  33012: "12086",
  33013: "12086",
  33014: "12086",
  33015: "12086",
  33016: "12086",
  33018: "12086",
  33019: "12011",
  33020: "12011",
  33021: "12011",
  33023: "12011",
  33024: "12011",
  33025: "12011",
  33026: "12011",
  33027: "12011",
  33028: "12011",
  33029: "12011",
  3303: "33013",
  33030: "12086",
  33031: "12086",
  33032: "12086",
  33033: "12086",
  33034: "12086",
  33035: "12086",
  33036: "12087",
  33037: "12087",
  3304: "33013",
  33040: "12087",
  33042: "12087",
  33043: "12087",
  33050: "12087",
  33051: "12087",
  33054: "12086",
  33055: "12086",
  33056: "12086",
  33060: "12011",
  33062: "12011",
  33063: "12011",
  33064: "12011",
  33065: "12011",
  33066: "12011",
  33067: "12011",
  33068: "12011",
  33069: "12011",
  3307: "33013",
  33070: "12087",
  33071: "12011",
  33073: "12011",
  33076: "12011",
  33109: "12086",
  33122: "12086",
  33125: "12086",
  33126: "12086",
  33127: "12086",
  33128: "12086",
  33129: "12086",
  33130: "12086",
  33131: "12086",
  33132: "12086",
  33133: "12086",
  33134: "12086",
  33135: "12086",
  33136: "12086",
  33137: "12086",
  33138: "12086",
  33139: "12086",
  33140: "12086",
  33141: "12086",
  33142: "12086",
  33143: "12086",
  33144: "12086",
  33145: "12086",
  33146: "12086",
  33147: "12086",
  33149: "12086",
  33150: "12086",
  33154: "12086",
  33155: "12086",
  33156: "12086",
  33157: "12086",
  33158: "12086",
  33160: "12086",
  33161: "12086",
  33162: "12086",
  33165: "12086",
  33166: "12086",
  33167: "12086",
  33168: "12086",
  33169: "12086",
  33170: "12086",
  33172: "12086",
  33173: "12086",
  33174: "12086",
  33175: "12086",
  33176: "12086",
  33177: "12086",
  33178: "12086",
  33179: "12086",
  33180: "12086",
  33181: "12086",
  33182: "12086",
  33183: "12086",
  33184: "12086",
  33185: "12086",
  33186: "12086",
  33187: "12086",
  33189: "12086",
  33190: "12086",
  33193: "12086",
  33194: "12086",
  33196: "12086",
  33301: "12011",
  33304: "12011",
  33305: "12011",
  33306: "12011",
  33308: "12011",
  33309: "12011",
  33311: "12011",
  33312: "12011",
  33313: "12011",
  33314: "12011",
  33315: "12011",
  33316: "12011",
  33317: "12011",
  33319: "12011",
  33321: "12011",
  33322: "12011",
  33323: "12011",
  33324: "12011",
  33325: "12011",
  33326: "12011",
  33327: "12011",
  33328: "12011",
  33330: "12011",
  33331: "12011",
  33332: "12011",
  33334: "12011",
  33351: "12011",
  33401: "12099",
  33403: "12099",
  33404: "12099",
  33405: "12099",
  33406: "12099",
  33407: "12099",
  33408: "12099",
  33409: "12099",
  33410: "12099",
  33411: "12099",
  33412: "12099",
  33413: "12099",
  33414: "12099",
  33415: "12099",
  33417: "12099",
  33418: "12099",
  33426: "12099",
  33428: "12099",
  33430: "12099",
  33431: "12099",
  33432: "12099",
  33433: "12099",
  33434: "12099",
  33435: "12099",
  33436: "12099",
  33437: "12099",
  33440: "12051",
  33441: "12011",
  33442: "12011",
  33444: "12099",
  33445: "12099",
  33446: "12099",
  33449: "12099",
  33455: "12085",
  33458: "12099",
  33460: "12099",
  33461: "12099",
  33462: "12099",
  33463: "12099",
  33467: "12099",
  33469: "12099",
  33470: "12099",
  33471: "12043",
  33472: "12099",
  33473: "12099",
  33476: "12099",
  33477: "12099",
  33478: "12099",
  33480: "12099",
  33483: "12099",
  33484: "12099",
  33486: "12099",
  33487: "12099",
  33493: "12099",
  33496: "12099",
  33498: "12099",
  33510: "12057",
  33511: "12057",
  33513: "12119",
  33514: "12119",
  33523: "12101",
  33525: "12101",
  33527: "12057",
  33534: "12057",
  33538: "12119",
  33540: "12101",
  33541: "12101",
  33542: "12101",
  33543: "12101",
  33544: "12101",
  33545: "12101",
  33547: "12057",
  33548: "12057",
  33549: "12057",
  33556: "12057",
  33558: "12057",
  33559: "12057",
  33563: "12057",
  33565: "12057",
  33566: "12057",
  33567: "12057",
  33569: "12057",
  33570: "12057",
  33572: "12057",
  33573: "12057",
  33576: "12101",
  33578: "12057",
  33579: "12057",
  33584: "12057",
  33585: "12119",
  33592: "12057",
  33594: "12057",
  33596: "12057",
  33597: "12119",
  33598: "12057",
  33602: "12057",
  33603: "12057",
  33604: "12057",
  33605: "12057",
  33606: "12057",
  33607: "12057",
  33609: "12057",
  33610: "12057",
  33611: "12057",
  33612: "12057",
  33613: "12057",
  33614: "12057",
  33615: "12057",
  33616: "12057",
  33617: "12057",
  33618: "12057",
  33619: "12057",
  33624: "12057",
  33625: "12057",
  33626: "12057",
  33629: "12057",
  33634: "12057",
  33635: "12057",
  33637: "12057",
  33647: "12057",
  33701: "12103",
  33702: "12103",
  33703: "12103",
  33704: "12103",
  33705: "12103",
  33706: "12103",
  33707: "12103",
  33708: "12103",
  33709: "12103",
  33710: "12103",
  33711: "12103",
  33712: "12103",
  33713: "12103",
  33714: "12103",
  33715: "12103",
  33716: "12103",
  33755: "12103",
  33756: "12103",
  33759: "12103",
  33760: "12103",
  33761: "12103",
  33762: "12103",
  33763: "12103",
  33764: "12103",
  33765: "12103",
  33767: "12103",
  33770: "12103",
  33771: "12103",
  33772: "12103",
  33773: "12103",
  33774: "12103",
  33776: "12103",
  33777: "12103",
  33778: "12103",
  33781: "12103",
  33782: "12103",
  33785: "12103",
  33786: "12103",
  33801: "12105",
  33803: "12105",
  33805: "12105",
  33809: "12105",
  33810: "12105",
  33811: "12105",
  33812: "12105",
  33813: "12105",
  33815: "12105",
  33823: "12105",
  33825: "12055",
  33827: "12105",
  33830: "12105",
  33834: "12049",
  33837: "12105",
  33838: "12105",
  33839: "12105",
  33840: "12105",
  33841: "12105",
  33843: "12105",
  33844: "12105",
  33848: "12097",
  33849: "12105",
  33850: "12105",
  33851: "12105",
  33852: "12055",
  33853: "12105",
  33854: "12105",
  33855: "12105",
  33857: "12055",
  33859: "12105",
  33860: "12105",
  33865: "12049",
  33867: "12097",
  33868: "12105",
  33870: "12055",
  33872: "12055",
  33873: "12049",
  33875: "12055",
  33876: "12055",
  33877: "12105",
  33880: "12105",
  33881: "12105",
  33884: "12105",
  33890: "12049",
  33896: "12105",
  33897: "12105",
  33898: "12105",
  33901: "12071",
  33903: "12071",
  33904: "12071",
  33905: "12071",
  33907: "12071",
  33908: "12071",
  33909: "12071",
  33912: "12071",
  33913: "12071",
  33914: "12071",
  33916: "12071",
  33917: "12071",
  33919: "12071",
  33920: "12071",
  33921: "12015",
  33922: "12071",
  33924: "12071",
  33928: "12071",
  33930: "12051",
  33931: "12071",
  33935: "12043",
  33936: "12071",
  33946: "12015",
  33947: "12015",
  33948: "12015",
  33950: "12015",
  33952: "12015",
  33953: "12015",
  33954: "12015",
  33955: "12015",
  33956: "12071",
  33957: "12071",
  33960: "12055",
  33966: "12071",
  33967: "12071",
  33971: "12071",
  33972: "12071",
  33973: "12071",
  33974: "12071",
  33976: "12071",
  33980: "12015",
  33981: "12015",
  33982: "12015",
  33983: "12015",
  33990: "12071",
  33991: "12071",
  33993: "12071",
  34102: "12021",
  34103: "12021",
  34104: "12021",
  34105: "12021",
  34108: "12021",
  34109: "12021",
  34110: "12021",
  34112: "12021",
  34113: "12021",
  34114: "12021",
  34116: "12021",
  34117: "12021",
  34119: "12021",
  34120: "12021",
  34134: "12071",
  34135: "12071",
  34138: "12021",
  34139: "12021",
  34140: "12021",
  34142: "12021",
  34145: "12021",
  34201: "12081",
  34202: "12081",
  34203: "12081",
  34205: "12081",
  34207: "12081",
  34208: "12081",
  34209: "12081",
  34210: "12081",
  34211: "12081",
  34212: "12081",
  34215: "12081",
  34216: "12081",
  34217: "12081",
  34219: "12081",
  34221: "12081",
  34222: "12081",
  34223: "12115",
  34224: "12015",
  34228: "12115",
  34229: "12115",
  34231: "12115",
  34232: "12115",
  34233: "12115",
  34234: "12115",
  34235: "12115",
  34236: "12115",
  34237: "12115",
  34238: "12115",
  34239: "12115",
  34240: "12115",
  34241: "12115",
  34242: "12115",
  34243: "12081",
  34250: "12081",
  34251: "12081",
  34266: "12027",
  34269: "12027",
  34275: "12115",
  34285: "12115",
  34286: "12115",
  34287: "12115",
  34288: "12115",
  34289: "12115",
  34291: "12115",
  34292: "12115",
  34293: "12115",
  3431: "33005",
  3440: "33011",
  3441: "33005",
  3442: "33011",
  34420: "12083",
  34428: "12017",
  34429: "12017",
  3443: "33005",
  34431: "12083",
  34432: "12083",
  34433: "12017",
  34434: "12017",
  34436: "12017",
  3444: "33005",
  34442: "12017",
  34446: "12017",
  34448: "12017",
  34449: "12075",
  3445: "33005",
  34450: "12017",
  34452: "12017",
  34453: "12017",
  3446: "33005",
  34461: "12017",
  34465: "12017",
  3447: "33005",
  34470: "12083",
  34471: "12083",
  34472: "12083",
  34473: "12083",
  34474: "12083",
  34475: "12083",
  34476: "12083",
  34479: "12083",
  3448: "33005",
  34480: "12083",
  34481: "12083",
  34482: "12083",
  34484: "12119",
  34488: "12083",
  3449: "33011",
  34491: "12083",
  34498: "12075",
  3450: "33005",
  3451: "33005",
  3452: "33005",
  3455: "33005",
  3456: "33005",
  3457: "33005",
  3458: "33011",
  34601: "12053",
  34602: "12053",
  34604: "12053",
  34606: "12053",
  34607: "12053",
  34608: "12053",
  34609: "12053",
  3461: "33005",
  34610: "12101",
  34613: "12053",
  34614: "12053",
  3462: "33005",
  34637: "12101",
  34638: "12101",
  34639: "12101",
  3464: "33005",
  3465: "33005",
  34652: "12101",
  34653: "12101",
  34654: "12101",
  34655: "12101",
  3466: "33005",
  34661: "12053",
  34667: "12101",
  34668: "12101",
  34669: "12101",
  3467: "33005",
  34677: "12103",
  34681: "12103",
  34683: "12103",
  34684: "12103",
  34685: "12103",
  34688: "12103",
  34689: "12103",
  34690: "12101",
  34691: "12101",
  34695: "12103",
  34698: "12103",
  3470: "33005",
  34705: "12069",
  34711: "12069",
  34714: "12069",
  34715: "12069",
  34731: "12069",
  34734: "12095",
  34736: "12069",
  34737: "12069",
  34739: "12097",
  34741: "12097",
  34743: "12097",
  34744: "12097",
  34746: "12097",
  34747: "12097",
  34748: "12069",
  34753: "12069",
  34756: "12069",
  34758: "12097",
  34759: "12105",
  34760: "12095",
  34761: "12095",
  34762: "12069",
  34769: "12097",
  34771: "12097",
  34772: "12097",
  34773: "12097",
  34785: "12119",
  34786: "12095",
  34787: "12095",
  34788: "12069",
  34797: "12069",
  34945: "12111",
  34946: "12111",
  34947: "12111",
  34949: "12111",
  34950: "12111",
  34951: "12111",
  34952: "12111",
  34953: "12111",
  34956: "12085",
  34957: "12085",
  34972: "12093",
  34974: "12093",
  34981: "12111",
  34982: "12111",
  34983: "12111",
  34984: "12111",
  34986: "12111",
  34987: "12111",
  34990: "12085",
  34994: "12085",
  34996: "12085",
  34997: "12085",
  35004: "1115",
  35005: "1073",
  35006: "1073",
  35007: "1117",
  35010: "1123",
  35014: "1121",
  35016: "1095",
  35019: "1043",
  35020: "1073",
  35022: "1073",
  35023: "1073",
  35031: "1009",
  35033: "1043",
  35034: "1007",
  35035: "1007",
  35040: "1117",
  35042: "1007",
  35043: "1117",
  35044: "1121",
  35045: "1021",
  35046: "1021",
  35049: "1009",
  35051: "1117",
  35053: "1043",
  35054: "1115",
  35055: "1043",
  35057: "1043",
  35058: "1043",
  35060: "1073",
  35061: "1073",
  35062: "1073",
  35063: "1127",
  35064: "1073",
  35068: "1073",
  35071: "1073",
  35072: "1027",
  35073: "1073",
  35077: "1043",
  35078: "1117",
  35079: "1009",
  35080: "1117",
  35083: "1043",
  35085: "1021",
  35087: "1043",
  35089: "1037",
  35091: "1073",
  35094: "1073",
  35096: "1121",
  35097: "1009",
  35098: "1043",
  35111: "1125",
  35114: "1117",
  35115: "1117",
  35116: "1073",
  35117: "1073",
  35118: "1073",
  35120: "1115",
  35121: "1009",
  35124: "1117",
  35125: "1115",
  35126: "1073",
  35127: "1073",
  35128: "1115",
  35130: "1127",
  35131: "1115",
  35133: "1009",
  35135: "1115",
  35136: "1037",
  35143: "1117",
  35146: "1115",
  35147: "1117",
  35148: "1127",
  35150: "1121",
  35151: "1121",
  35160: "1121",
  35171: "1021",
  35172: "1009",
  35173: "1073",
  35175: "1095",
  35178: "1117",
  35179: "1043",
  35180: "1073",
  35184: "1007",
  35186: "1117",
  35188: "1007",
  35203: "1073",
  35204: "1073",
  35205: "1073",
  35206: "1073",
  35207: "1073",
  35208: "1073",
  35209: "1073",
  35210: "1073",
  35211: "1073",
  35212: "1073",
  35213: "1073",
  35214: "1073",
  35215: "1073",
  35216: "1073",
  35217: "1073",
  35218: "1073",
  35221: "1073",
  35222: "1073",
  35223: "1073",
  35224: "1073",
  35226: "1073",
  35228: "1073",
  35233: "1073",
  35234: "1073",
  35235: "1073",
  35242: "1117",
  35243: "1073",
  35244: "1117",
  35401: "1125",
  35404: "1125",
  35405: "1125",
  35406: "1125",
  35441: "1065",
  35442: "1107",
  35443: "1063",
  35444: "1125",
  35446: "1125",
  35447: "1107",
  35452: "1125",
  35453: "1125",
  35456: "1125",
  35459: "1119",
  35460: "1119",
  35461: "1107",
  35462: "1063",
  35463: "1125",
  35466: "1107",
  35470: "1119",
  35473: "1125",
  35474: "1065",
  35475: "1125",
  35476: "1125",
  35480: "1125",
  35481: "1107",
  35490: "1125",
  35501: "1127",
  35503: "1127",
  35504: "1127",
  35540: "1133",
  35541: "1133",
  35546: "1057",
  35549: "1127",
  35550: "1127",
  35552: "1093",
  35553: "1133",
  35554: "1057",
  35555: "1057",
  35563: "1093",
  35564: "1093",
  35565: "1133",
  35570: "1093",
  35571: "1059",
  35572: "1133",
  35574: "1075",
  35575: "1133",
  35576: "1075",
  35578: "1127",
  35579: "1127",
  35580: "1127",
  35581: "1059",
  35582: "1059",
  35585: "1059",
  35586: "1075",
  35592: "1075",
  35593: "1059",
  35594: "1093",
  35601: "1103",
  35603: "1103",
  3561: "33009",
  35610: "1077",
  35611: "1083",
  35613: "1083",
  35614: "1083",
  35616: "1033",
  35618: "1079",
  35619: "1103",
  35620: "1083",
  35621: "1103",
  35622: "1103",
  35630: "1077",
  35633: "1077",
  35634: "1077",
  35640: "1103",
  35643: "1079",
  35645: "1077",
  35646: "1033",
  35647: "1083",
  35648: "1077",
  35650: "1079",
  35651: "1079",
  35652: "1077",
  35653: "1059",
  35654: "1059",
  35660: "1033",
  35661: "1033",
  35670: "1103",
  35671: "1083",
  35672: "1079",
  35673: "1079",
  35674: "1033",
  35677: "1077",
  3570: "33007",
  35739: "1083",
  3574: "33009",
  35740: "1071",
  35741: "1089",
  35744: "1071",
  35747: "1095",
  35748: "1089",
  35749: "1089",
  35750: "1089",
  35752: "1071",
  35754: "1103",
  35755: "1071",
  35756: "1083",
  35757: "1089",
  35758: "1089",
  35759: "1089",
  3576: "33007",
  35760: "1089",
  35761: "1089",
  35763: "1089",
  35765: "1071",
  35768: "1071",
  35769: "1071",
  35771: "1071",
  35772: "1071",
  35773: "1089",
  35776: "1071",
  3579: "33007",
  3580: "33009",
  35801: "1089",
  35802: "1089",
  35803: "1089",
  35805: "1089",
  35806: "1089",
  3581: "33007",
  35810: "1089",
  35811: "1089",
  35816: "1089",
  3582: "33007",
  35824: "1089",
  3583: "33007",
  3584: "33007",
  3585: "33009",
  3586: "33009",
  3588: "33007",
  3590: "33007",
  35901: "1055",
  35903: "1055",
  35904: "1055",
  35905: "1055",
  35906: "1055",
  35907: "1055",
  3592: "33007",
  3593: "33007",
  35950: "1095",
  35951: "1095",
  35952: "1055",
  35953: "1115",
  35954: "1055",
  35956: "1055",
  35957: "1095",
  35958: "1071",
  35959: "1019",
  35960: "1019",
  35961: "1049",
  35962: "1049",
  35963: "1049",
  35966: "1071",
  35967: "1049",
  35968: "1049",
  35971: "1049",
  35972: "1055",
  35973: "1019",
  35974: "1049",
  35975: "1049",
  35976: "1095",
  35978: "1049",
  35979: "1049",
  3598: "33007",
  35980: "1095",
  35981: "1049",
  35983: "1019",
  35984: "1049",
  35986: "1049",
  35987: "1115",
  35988: "1049",
  35989: "1049",
  36003: "1001",
  36005: "1109",
  36006: "1001",
  36009: "1041",
  3601: "33019",
  36010: "1109",
  36013: "1101",
  36016: "1005",
  3602: "33005",
  36020: "1051",
  36022: "1051",
  36024: "1051",
  36025: "1051",
  36026: "1037",
  36027: "1005",
  36028: "1039",
  36029: "1011",
  3603: "33019",
  36032: "1085",
  36033: "1013",
  36034: "1109",
  36035: "1109",
  36036: "1101",
  36037: "1013",
  36042: "1013",
  36043: "1085",
  36046: "1041",
  36047: "1085",
  36049: "1041",
  3605: "33019",
  36051: "1001",
  36052: "1101",
  36053: "1011",
  36054: "1051",
  36064: "1101",
  36066: "1001",
  36067: "1001",
  36069: "1101",
  3607: "33019",
  36075: "1087",
  36078: "1051",
  36079: "1109",
  3608: "33005",
  36080: "1051",
  36081: "1109",
  36083: "1087",
  36088: "1087",
  36089: "1011",
  3609: "33005",
  36091: "1021",
  36092: "1051",
  36093: "1051",
  36104: "1101",
  36105: "1101",
  36106: "1101",
  36107: "1101",
  36108: "1101",
  36109: "1101",
  36110: "1101",
  36111: "1101",
  36116: "1101",
  36117: "1101",
  36201: "1015",
  36203: "1015",
  36205: "1015",
  36206: "1015",
  36207: "1015",
  36250: "1015",
  36251: "1027",
  36255: "1027",
  36256: "1123",
  36258: "1029",
  36260: "1015",
  36262: "1029",
  36264: "1029",
  36265: "1015",
  36266: "1027",
  36268: "1121",
  36269: "1029",
  36271: "1015",
  36272: "1015",
  36273: "1029",
  36274: "1111",
  36276: "1111",
  36277: "1015",
  36278: "1111",
  36279: "1015",
  36280: "1111",
  36301: "1069",
  36303: "1069",
  36305: "1069",
  36310: "1067",
  36311: "1045",
  36312: "1069",
  36314: "1061",
  36316: "1061",
  36317: "1067",
  36318: "1061",
  36319: "1067",
  36320: "1069",
  36321: "1069",
  36322: "1045",
  36323: "1031",
  36330: "1031",
  36340: "1061",
  36343: "1069",
  36344: "1061",
  36345: "1067",
  36346: "1031",
  36350: "1045",
  36351: "1031",
  36352: "1069",
  36353: "1067",
  36360: "1045",
  36370: "1069",
  36371: "1045",
  36373: "1067",
  36374: "1045",
  36375: "1061",
  36376: "1069",
  36401: "1035",
  36420: "1039",
  36421: "1039",
  36426: "1053",
  36432: "1035",
  36436: "1025",
  36441: "1053",
  36442: "1039",
  36451: "1025",
  36453: "1031",
  36456: "1035",
  36460: "1099",
  36467: "1039",
  36474: "1039",
  36475: "1035",
  36477: "1061",
  36482: "1025",
  36483: "1053",
  36502: "1053",
  36505: "1097",
  36507: "1003",
  36509: "1097",
  36511: "1003",
  36521: "1097",
  36522: "1097",
  36523: "1097",
  36524: "1025",
  36525: "1097",
  36526: "1003",
  36527: "1003",
  36528: "1097",
  36530: "1003",
  36532: "1003",
  36535: "1003",
  36540: "1025",
  36541: "1097",
  36542: "1003",
  36544: "1097",
  36545: "1025",
  36549: "1003",
  36551: "1003",
  36555: "1003",
  36558: "1129",
  36560: "1097",
  36561: "1003",
  36562: "1003",
  36567: "1003",
  36571: "1097",
  36572: "1097",
  36574: "1003",
  36575: "1097",
  36576: "1003",
  36578: "1003",
  36579: "1003",
  36580: "1003",
  36582: "1097",
  36587: "1097",
  36602: "1097",
  36603: "1097",
  36604: "1097",
  36605: "1097",
  36606: "1097",
  36607: "1097",
  36608: "1097",
  36609: "1097",
  36610: "1097",
  36611: "1097",
  36612: "1097",
  36613: "1097",
  36617: "1097",
  36618: "1097",
  36619: "1097",
  36693: "1097",
  36695: "1097",
  36701: "1047",
  36702: "1047",
  36703: "1047",
  36726: "1131",
  36732: "1091",
  36740: "1063",
  36742: "1091",
  36744: "1065",
  36748: "1091",
  36749: "1001",
  36750: "1021",
  36751: "1025",
  36752: "1085",
  36756: "1105",
  36758: "1047",
  36759: "1047",
  36761: "1085",
  36765: "1065",
  36767: "1047",
  36773: "1047",
  36775: "1047",
  36784: "1025",
  36785: "1085",
  36792: "1007",
  36793: "1007",
  36801: "1081",
  36804: "1081",
  36830: "1081",
  36832: "1081",
  36850: "1123",
  36852: "1017",
  36853: "1123",
  36854: "1017",
  36855: "1017",
  36856: "1113",
  36858: "1113",
  36860: "1113",
  36861: "1123",
  36862: "1017",
  36863: "1017",
  36866: "1087",
  36867: "1113",
  36869: "1113",
  36870: "1081",
  36871: "1113",
  36874: "1081",
  36875: "1113",
  36877: "1081",
  36879: "1081",
  36904: "1023",
  36907: "1119",
  36925: "1119",
  37010: "47125",
  37012: "47041",
  37013: "47037",
  37014: "47187",
  37015: "47021",
  37016: "47015",
  37018: "47031",
  37019: "47117",
  37020: "47149",
  37022: "47165",
  37023: "47161",
  37025: "47081",
  37026: "47015",
  37027: "47187",
  37028: "47161",
  37029: "47043",
  37030: "47159",
  37031: "47165",
  37032: "47147",
  37033: "47081",
  37034: "47117",
  37035: "47021",
  37036: "47043",
  37037: "47149",
  37040: "47125",
  37042: "47125",
  37043: "47125",
  37046: "47187",
  37047: "47117",
  37048: "47165",
  37049: "47147",
  37050: "47083",
  37051: "47043",
  37052: "47125",
  37055: "47043",
  37057: "47169",
  37058: "47161",
  37059: "47041",
  37060: "47149",
  37061: "47083",
  37062: "47187",
  37064: "47187",
  37066: "47165",
  37067: "47187",
  37069: "47187",
  37072: "47165",
  37073: "47147",
  37074: "47169",
  37075: "47165",
  37076: "47037",
  37078: "47085",
  37079: "47161",
  37080: "47037",
  37082: "47021",
  37083: "47111",
  37085: "47149",
  37086: "47149",
  37087: "47189",
  37090: "47189",
  37091: "47117",
  37095: "47041",
  37096: "47135",
  37097: "47135",
  37098: "47081",
  37101: "47085",
  37110: "47177",
  37115: "47037",
  37118: "47149",
  37122: "47189",
  37127: "47149",
  37128: "47149",
  37129: "47149",
  37130: "47149",
  37134: "47085",
  37135: "47187",
  37137: "47081",
  37138: "47037",
  37141: "47147",
  37142: "47125",
  37143: "47021",
  37144: "47103",
  37145: "47159",
  37146: "47021",
  37148: "47165",
  37149: "47149",
  37150: "47111",
  37151: "47159",
  37153: "47149",
  37160: "47003",
  37166: "47041",
  37167: "47149",
  37171: "47125",
  37172: "47147",
  37174: "47119",
  37175: "47161",
  37178: "47161",
  37179: "47187",
  37180: "47003",
  37181: "47043",
  37183: "47003",
  37184: "47189",
  37185: "47085",
  37186: "47165",
  37187: "47043",
  37188: "47147",
  37189: "47037",
  37190: "47015",
  37191: "47125",
  37201: "47037",
  37203: "47037",
  37204: "47037",
  37205: "47037",
  37206: "47037",
  37207: "47037",
  37208: "47037",
  37209: "47037",
  37210: "47037",
  37211: "47037",
  37212: "47037",
  37214: "47037",
  37215: "47037",
  37216: "47037",
  37217: "47037",
  37218: "47037",
  37219: "47037",
  37220: "47037",
  37221: "47037",
  37301: "47061",
  37302: "47065",
  37303: "47107",
  37305: "47061",
  37306: "47051",
  37307: "47139",
  37308: "47065",
  37309: "47107",
  37310: "47011",
  37311: "47011",
  37312: "47011",
  37313: "47061",
  37317: "47139",
  37318: "47051",
  37321: "47143",
  37322: "47121",
  37323: "47011",
  37324: "47051",
  37325: "47139",
  37327: "47153",
  37328: "47103",
  37329: "47107",
  37330: "47051",
  37331: "47107",
  37332: "47143",
  37333: "47139",
  37334: "47103",
  37335: "47103",
  37336: "47121",
  37337: "47143",
  37338: "47007",
  37339: "47061",
  37340: "47115",
  37341: "47065",
  37342: "47031",
  37343: "47065",
  37345: "47051",
  37347: "47115",
  37348: "47103",
  37350: "47065",
  37351: "47065",
  37352: "47127",
  37353: "47011",
  37354: "47123",
  37355: "47031",
  37356: "47115",
  37357: "47177",
  37359: "47127",
  37360: "47003",
  37361: "47139",
  37362: "47139",
  37363: "47065",
  37365: "47061",
  37366: "47061",
  37367: "47007",
  37369: "47139",
  37370: "47107",
  37373: "47065",
  37374: "47115",
  37375: "47051",
  37376: "47051",
  37377: "47065",
  37379: "47065",
  37380: "47115",
  37381: "47143",
  37385: "47123",
  37387: "47061",
  37388: "47031",
  37391: "47139",
  37396: "47115",
  37397: "47115",
  37398: "47051",
  3740: "33009",
  37402: "47065",
  37403: "47065",
  37404: "47065",
  37405: "47065",
  37406: "47065",
  37407: "47065",
  37408: "47065",
  37409: "47065",
  3741: "33009",
  37410: "47065",
  37411: "47065",
  37412: "47065",
  37415: "47065",
  37416: "47065",
  37419: "47065",
  37421: "47065",
  3743: "33019",
  3745: "33019",
  3748: "33009",
  3750: "33009",
  3752: "33019",
  3753: "33019",
  3755: "33009",
  37601: "47179",
  37604: "47179",
  37615: "47179",
  37616: "47059",
  37617: "47163",
  37618: "47163",
  37620: "47163",
  37640: "47091",
  37641: "47059",
  37642: "47073",
  37643: "47019",
  37645: "47073",
  3765: "33009",
  37650: "47171",
  37656: "47179",
  37657: "47171",
  37658: "47019",
  37659: "47179",
  3766: "33009",
  37660: "47163",
  37663: "47163",
  37664: "47163",
  37665: "47163",
  3768: "33009",
  37680: "47091",
  37681: "47179",
  37683: "47091",
  37686: "47163",
  37687: "47019",
  37688: "47091",
  37690: "47179",
  37691: "47091",
  37692: "47171",
  37694: "47019",
  3770: "33019",
  37701: "47009",
  37705: "47001",
  37708: "47057",
  37709: "47057",
  3771: "33009",
  37710: "47001",
  37711: "47073",
  37713: "47029",
  37714: "47013",
  37715: "47025",
  37716: "47001",
  37721: "47093",
  37722: "47029",
  37723: "47035",
  37724: "47025",
  37725: "47089",
  37726: "47129",
  37727: "47029",
  37729: "47013",
  3773: "33019",
  37731: "47073",
  37737: "47009",
  37738: "47155",
  3774: "33009",
  37742: "47009",
  37743: "47059",
  37745: "47059",
  37748: "47145",
  37752: "47025",
  37753: "47029",
  37754: "47001",
  37755: "47151",
  37756: "47151",
  37757: "47013",
  37760: "47089",
  37762: "47013",
  37763: "47145",
  37764: "47155",
  37765: "47067",
  37766: "47013",
  37769: "47001",
  3777: "33009",
  37770: "47129",
  37771: "47105",
  37772: "47105",
  37774: "47105",
  37777: "47009",
  37779: "47173",
  3779: "33009",
  3780: "33009",
  37801: "47009",
  37803: "47009",
  37804: "47009",
  37806: "47093",
  37807: "47173",
  37809: "47059",
  3781: "33019",
  37810: "47059",
  37811: "47073",
  37813: "47063",
  37814: "47063",
  37818: "47059",
  37819: "47013",
  3782: "33019",
  37820: "47089",
  37821: "47029",
  37825: "47025",
  37826: "47107",
  37828: "47001",
  37829: "47129",
  37830: "47001",
  3784: "33009",
  37840: "47129",
  37841: "47151",
  37843: "47029",
  37846: "47105",
  37847: "47013",
  37848: "47057",
  37849: "47093",
  3785: "33009",
  37852: "47151",
  37853: "47009",
  37854: "47145",
  37857: "47073",
  37860: "47063",
  37861: "47057",
  37862: "47155",
  37863: "47155",
  37865: "47155",
  37866: "47173",
  37869: "47067",
  37870: "47025",
  37871: "47093",
  37872: "47129",
  37873: "47073",
  37874: "47123",
  37876: "47155",
  37877: "47063",
  37878: "47009",
  37879: "47025",
  37880: "47145",
  37881: "47067",
  37882: "47009",
  37885: "47123",
  37886: "47009",
  37887: "47129",
  37888: "47057",
  37890: "47089",
  37891: "47073",
  37892: "47151",
  37902: "47093",
  37909: "47093",
  37912: "47093",
  37914: "47093",
  37915: "47093",
  37916: "47093",
  37917: "47093",
  37918: "47093",
  37919: "47093",
  37920: "47093",
  37921: "47093",
  37922: "47093",
  37923: "47093",
  37924: "47093",
  37931: "47093",
  37932: "47093",
  37934: "47093",
  37938: "47093",
  38001: "47033",
  38002: "47157",
  38004: "47167",
  38006: "47033",
  38008: "47069",
  3801: "33015",
  38011: "47167",
  38012: "47075",
  38015: "47167",
  38016: "47157",
  38017: "47157",
  38018: "47157",
  38019: "47167",
  38023: "47167",
  38024: "47045",
  38028: "47157",
  38030: "47045",
  38034: "47033",
  38037: "47075",
  38039: "47069",
  38040: "47097",
  38041: "47097",
  38042: "47069",
  38044: "47069",
  38049: "47047",
  38052: "47069",
  38053: "47157",
  38057: "47047",
  38058: "47167",
  38059: "47045",
  38060: "47047",
  38061: "47109",
  38063: "47097",
  38066: "47047",
  38067: "47069",
  38068: "47047",
  38069: "47075",
  38075: "47069",
  38076: "47047",
  38079: "47095",
  38080: "47095",
  3809: "33001",
  3810: "33001",
  38103: "47157",
  38104: "47157",
  38105: "47157",
  38106: "47157",
  38107: "47157",
  38108: "47157",
  38109: "47157",
  3811: "33015",
  38111: "47157",
  38112: "47157",
  38114: "47157",
  38115: "47157",
  38116: "47157",
  38117: "47157",
  38118: "47157",
  38119: "47157",
  3812: "33003",
  38120: "47157",
  38122: "47157",
  38125: "47157",
  38126: "47157",
  38127: "47157",
  38128: "47157",
  3813: "33003",
  38133: "47157",
  38134: "47157",
  38135: "47157",
  38138: "47157",
  38139: "47157",
  3814: "33003",
  38141: "47157",
  3816: "33003",
  3817: "33003",
  3818: "33003",
  3819: "33015",
  3820: "33017",
  38201: "47017",
  38220: "47017",
  38221: "47005",
  38222: "47079",
  38224: "47079",
  38225: "47183",
  38226: "47183",
  38229: "47183",
  3823: "33017",
  38230: "47183",
  38231: "47079",
  38232: "47131",
  38233: "47053",
  38236: "47079",
  38237: "47183",
  3824: "33017",
  38240: "47131",
  38241: "47183",
  38242: "47079",
  3825: "33017",
  38251: "47079",
  38253: "47131",
  38255: "47183",
  38256: "47079",
  38257: "47131",
  38258: "47017",
  38259: "47045",
  3826: "33015",
  38260: "47131",
  38261: "47131",
  3827: "33015",
  3830: "33003",
  38301: "47113",
  38305: "47113",
  38310: "47109",
  38311: "47039",
  38313: "47113",
  38315: "47109",
  38316: "47053",
  38317: "47017",
  38318: "47017",
  3832: "33003",
  38320: "47005",
  38321: "47077",
  38326: "47071",
  38327: "47071",
  38328: "47077",
  38329: "47039",
  3833: "33015",
  38330: "47053",
  38332: "47023",
  38333: "47005",
  38334: "47109",
  38337: "47033",
  38339: "47109",
  38340: "47023",
  38341: "47005",
  38342: "47017",
  38343: "47053",
  38344: "47017",
  38345: "47077",
  38347: "47023",
  38348: "47017",
  3835: "33017",
  38351: "47077",
  38352: "47023",
  38355: "47053",
  38356: "47113",
  38357: "47109",
  38358: "47053",
  38359: "47071",
  3836: "33003",
  38361: "47071",
  38362: "47113",
  38363: "47039",
  38366: "47113",
  38367: "47109",
  38368: "47077",
  38369: "47053",
  3837: "33001",
  38370: "47071",
  38371: "47077",
  38372: "47071",
  38374: "47077",
  38375: "47109",
  38376: "47071",
  38379: "47109",
  3838: "33003",
  38380: "47039",
  38381: "47069",
  38382: "47053",
  38387: "47017",
  38388: "47077",
  3839: "33017",
  38390: "47017",
  38391: "47113",
  38392: "47113",
  3840: "33015",
  38401: "47119",
  3841: "33015",
  3842: "33015",
  38425: "47181",
  3844: "33015",
  38449: "47055",
  3845: "33003",
  38450: "47181",
  38451: "47119",
  38452: "47181",
  38453: "47103",
  38454: "47081",
  38456: "47099",
  38457: "47099",
  38459: "47103",
  3846: "33003",
  38460: "47055",
  38461: "47119",
  38462: "47101",
  38463: "47181",
  38464: "47099",
  38468: "47099",
  38469: "47099",
  38471: "47181",
  38472: "47055",
  38473: "47055",
  38474: "47119",
  38475: "47071",
  38476: "47081",
  38477: "47055",
  38478: "47055",
  3848: "33015",
  38481: "47099",
  38482: "47119",
  38483: "47099",
  38485: "47181",
  38486: "47099",
  38487: "47119",
  38488: "47103",
  3849: "33003",
  38501: "47141",
  38504: "47049",
  38506: "47141",
  3851: "33017",
  3852: "33017",
  3853: "33003",
  3854: "33015",
  38541: "47133",
  38543: "47133",
  38544: "47141",
  38545: "47087",
  38547: "47159",
  38548: "47141",
  38549: "47137",
  3855: "33017",
  38551: "47027",
  38552: "47159",
  38553: "47049",
  38554: "47133",
  38555: "47035",
  38556: "47049",
  38558: "47035",
  38559: "47175",
  3856: "33015",
  38560: "47159",
  38562: "47087",
  38563: "47159",
  38564: "47087",
  38565: "47049",
  38567: "47159",
  38568: "47027",
  38569: "47041",
  3857: "33015",
  38570: "47133",
  38571: "47035",
  38572: "47035",
  38573: "47133",
  38574: "47141",
  38575: "47027",
  38577: "47137",
  38579: "47185",
  3858: "33015",
  38580: "47133",
  38581: "47177",
  38582: "47141",
  38583: "47185",
  38585: "47175",
  38587: "47185",
  38588: "47087",
  38589: "47049",
  3860: "33003",
  38601: "28071",
  38603: "28009",
  38606: "28107",
  3861: "33017",
  38610: "28139",
  38611: "28093",
  38614: "28027",
  38617: "28027",
  38618: "28137",
  38619: "28107",
  3862: "33015",
  38620: "28107",
  38621: "28107",
  38625: "28139",
  38626: "28143",
  38627: "28071",
  38629: "28139",
  38631: "28027",
  38632: "28033",
  38633: "28009",
  38635: "28093",
  38637: "28033",
  38639: "28027",
  3864: "33003",
  38641: "28033",
  38642: "28009",
  38645: "28027",
  38647: "28009",
  3865: "33015",
  38650: "28145",
  38651: "28033",
  38652: "28145",
  38654: "28033",
  38655: "28071",
  38658: "28107",
  38659: "28093",
  38661: "28093",
  38663: "28139",
  38664: "28143",
  38665: "28137",
  38666: "28107",
  38668: "28137",
  3867: "33017",
  38671: "28033",
  38672: "28033",
  38673: "28071",
  38674: "28139",
  38676: "28143",
  3868: "33017",
  38680: "28033",
  38683: "28003",
  38685: "28093",
  3869: "33017",
  3870: "33015",
  38701: "28151",
  38703: "28151",
  3871: "33015",
  3872: "33003",
  38720: "28027",
  38721: "28125",
  38722: "28151",
  38725: "28011",
  3873: "33015",
  38732: "28011",
  38736: "28133",
  38737: "28133",
  3874: "33015",
  38744: "28151",
  38748: "28151",
  3875: "33003",
  38751: "28133",
  38753: "28133",
  38754: "28053",
  38756: "28151",
  38759: "28011",
  38760: "28151",
  38761: "28133",
  38769: "28011",
  38771: "28133",
  38773: "28133",
  38778: "28133",
  3878: "33017",
  38801: "28081",
  38804: "28081",
  3882: "33003",
  38821: "28095",
  38824: "28117",
  38826: "28081",
  38827: "28141",
  38828: "28145",
  38829: "28117",
  38833: "28141",
  38834: "28003",
  38839: "28013",
  3884: "33017",
  38841: "28115",
  38843: "28057",
  38847: "28057",
  38849: "28081",
  3885: "33015",
  38850: "28017",
  38851: "28017",
  38852: "28141",
  38855: "28057",
  38856: "28057",
  38857: "28081",
  38858: "28081",
  38859: "28117",
  3886: "33003",
  38860: "28017",
  38862: "28081",
  38863: "28115",
  38864: "28115",
  38865: "28003",
  38866: "28081",
  38868: "28081",
  3887: "33017",
  38870: "28095",
  38871: "28115",
  38873: "28141",
  38876: "28057",
  38878: "28013",
  3890: "33003",
  38901: "28043",
  38913: "28013",
  38914: "28013",
  38915: "28013",
  38916: "28013",
  38917: "28015",
  38920: "28135",
  38921: "28135",
  38922: "28161",
  38923: "28015",
  38924: "28051",
  38925: "28097",
  38927: "28135",
  38929: "28043",
  38930: "28083",
  3894: "33003",
  38940: "28043",
  38941: "28083",
  38943: "28015",
  38944: "28083",
  38946: "28083",
  38948: "28161",
  38950: "28135",
  38951: "28013",
  38952: "28083",
  38953: "28161",
  38954: "28083",
  38957: "28135",
  38961: "28135",
  38963: "28027",
  38965: "28161",
  38966: "28135",
  38967: "28097",
  3901: "23031",
  3902: "23031",
  3903: "23031",
  39038: "28053",
  39039: "28163",
  3904: "23031",
  39040: "28163",
  39041: "28049",
  39042: "28121",
  39044: "28127",
  39046: "28089",
  39047: "28121",
  3905: "23031",
  39051: "28079",
  39054: "28125",
  39056: "28049",
  39057: "28101",
  39059: "28029",
  3906: "23031",
  39063: "28051",
  39066: "28049",
  39067: "28007",
  39069: "28063",
  3907: "23031",
  39071: "28089",
  39073: "28121",
  39074: "28123",
  39079: "28051",
  3908: "23031",
  39082: "28127",
  39083: "28029",
  39086: "28021",
  3909: "23031",
  39090: "28007",
  39092: "28123",
  39094: "28123",
  39095: "28051",
  39096: "28063",
  39097: "28053",
  39108: "28007",
  39110: "28089",
  39111: "28127",
  39114: "28127",
  39116: "28129",
  39117: "28123",
  39119: "28031",
  39120: "28001",
  39140: "28077",
  39144: "28021",
  39145: "28121",
  39146: "28089",
  39149: "28127",
  39150: "28021",
  39152: "28129",
  39153: "28129",
  39154: "28049",
  39157: "28089",
  39159: "28125",
  39160: "28007",
  39166: "28053",
  39168: "28129",
  39169: "28051",
  39170: "28049",
  39175: "28049",
  39176: "28015",
  39179: "28163",
  39180: "28149",
  39183: "28149",
  39189: "28079",
  39191: "28085",
  39192: "28007",
  39194: "28163",
  39202: "28049",
  39203: "28049",
  39204: "28049",
  39206: "28049",
  39208: "28121",
  39209: "28049",
  39211: "28049",
  39212: "28049",
  39213: "28049",
  39216: "28049",
  39218: "28121",
  39232: "28121",
  39272: "28049",
  39301: "28075",
  39305: "28075",
  39307: "28075",
  39320: "28075",
  39322: "28153",
  39323: "28101",
  39325: "28075",
  39326: "28069",
  39327: "28101",
  39328: "28069",
  39330: "28023",
  39332: "28101",
  39335: "28075",
  39336: "28101",
  39337: "28101",
  39338: "28061",
  39339: "28159",
  39341: "28103",
  39342: "28075",
  39345: "28101",
  39346: "28159",
  39347: "28023",
  39350: "28099",
  39354: "28069",
  39355: "28023",
  39356: "28061",
  39360: "28023",
  39362: "28153",
  39363: "28023",
  39364: "28075",
  39365: "28099",
  39366: "28061",
  39367: "28153",
  39401: "28035",
  39402: "28073",
  39421: "28065",
  39422: "28061",
  39423: "28111",
  39425: "28111",
  39426: "28109",
  39427: "28065",
  39428: "28031",
  39429: "28091",
  39437: "28067",
  39439: "28061",
  39440: "28067",
  39443: "28067",
  39451: "28041",
  39452: "28039",
  39455: "28073",
  39456: "28039",
  39459: "28067",
  39461: "28041",
  39462: "28111",
  39464: "28067",
  39465: "28035",
  39466: "28109",
  39470: "28109",
  39474: "28065",
  39475: "28073",
  39476: "28111",
  39478: "28147",
  39479: "28031",
  39480: "28067",
  39481: "28061",
  39482: "28073",
  39483: "28091",
  39501: "28047",
  39503: "28047",
  39507: "28047",
  39520: "28045",
  39525: "28045",
  39530: "28047",
  39531: "28047",
  39532: "28047",
  39540: "28047",
  39553: "28059",
  39556: "28045",
  39560: "28047",
  39561: "28131",
  39562: "28059",
  39563: "28059",
  39564: "28059",
  39565: "28059",
  39567: "28059",
  39571: "28047",
  39572: "28045",
  39573: "28131",
  39574: "28047",
  39576: "28045",
  39577: "28131",
  39581: "28059",
  39601: "28085",
  39629: "28085",
  39630: "28037",
  39631: "28157",
  39633: "28157",
  39638: "28005",
  39641: "28077",
  39643: "28091",
  39645: "28005",
  39647: "28037",
  39648: "28113",
  39652: "28113",
  39653: "28037",
  39654: "28077",
  39656: "28077",
  39657: "28005",
  39661: "28037",
  39662: "28085",
  39663: "28077",
  39664: "28085",
  39665: "28077",
  39666: "28113",
  39667: "28147",
  39668: "28063",
  39669: "28157",
  39701: "28087",
  39702: "28087",
  39705: "28087",
  39730: "28095",
  39735: "28019",
  39737: "28155",
  39740: "28087",
  39741: "28025",
  39743: "28087",
  39744: "28155",
  39745: "28019",
  39746: "28095",
  39747: "28097",
  39750: "28105",
  39751: "28155",
  39752: "28155",
  39755: "28025",
  39756: "28095",
  39759: "28105",
  39766: "28087",
  39767: "28155",
  39769: "28105",
  39772: "28019",
  39773: "28025",
  39776: "28017",
  39813: "13037",
  39815: "13087",
  39817: "13087",
  39819: "13087",
  39823: "13099",
  39824: "13061",
  39825: "13087",
  39826: "13273",
  39827: "13131",
  39828: "13131",
  39834: "13087",
  39836: "13243",
  39837: "13201",
  39840: "13243",
  39841: "13099",
  39842: "13273",
  39845: "13253",
  39846: "13037",
  39851: "13061",
  39854: "13239",
  39859: "13253",
  39861: "13099",
  39862: "13037",
  39866: "13037",
  39867: "13061",
  39870: "13007",
  39877: "13307",
  39886: "13243",
  39897: "13131",
  40003: "21211",
  40004: "21179",
  40006: "21223",
  40008: "21179",
  40009: "21155",
  4001: "23031",
  40010: "21185",
  40011: "21103",
  40012: "21179",
  40013: "21179",
  40014: "21185",
  40019: "21103",
  4002: "23031",
  40022: "21211",
  40023: "21111",
  40026: "21185",
  4003: "23005",
  40031: "21185",
  40033: "21155",
  40036: "21103",
  40037: "21155",
  40040: "21229",
  40045: "21223",
  40046: "21215",
  40047: "21029",
  4005: "23031",
  40050: "21103",
  40051: "21179",
  40052: "21179",
  40055: "21223",
  40056: "21185",
  40057: "21103",
  40059: "21111",
  40060: "21155",
  40062: "21155",
  40065: "21211",
  40067: "21211",
  40068: "21103",
  40069: "21229",
  40070: "21103",
  40071: "21215",
  40075: "21103",
  40076: "21211",
  40077: "21185",
  40078: "21229",
  4008: "23023",
  4009: "23005",
  4010: "23017",
  40104: "21163",
  40107: "21179",
  40108: "21163",
  40109: "21029",
  4011: "23005",
  40111: "21027",
  40115: "21027",
  40117: "21163",
  40118: "21111",
  40119: "21085",
  40140: "21027",
  40142: "21163",
  40143: "21027",
  40144: "21027",
  40145: "21027",
  40146: "21027",
  4015: "23005",
  40150: "21029",
  40152: "21027",
  40155: "21163",
  40157: "21163",
  40160: "21093",
  40162: "21093",
  40165: "21029",
  4017: "23005",
  40170: "21027",
  40171: "21027",
  40175: "21163",
  40176: "21027",
  40177: "21111",
  40178: "21027",
  4020: "23031",
  40202: "21111",
  40203: "21111",
  40204: "21111",
  40205: "21111",
  40206: "21111",
  40207: "21111",
  40208: "21111",
  40209: "21111",
  4021: "23005",
  40210: "21111",
  40211: "21111",
  40212: "21111",
  40213: "21111",
  40214: "21111",
  40215: "21111",
  40216: "21111",
  40217: "21111",
  40218: "21111",
  40219: "21111",
  4022: "23017",
  40220: "21111",
  40222: "21111",
  40223: "21111",
  40228: "21111",
  40229: "21111",
  40241: "21111",
  40242: "21111",
  40243: "21111",
  40245: "21111",
  40258: "21111",
  4027: "23031",
  40272: "21111",
  4029: "23005",
  40291: "21111",
  40299: "21111",
  4030: "23031",
  40311: "21181",
  40312: "21197",
  40313: "21205",
  40316: "21165",
  4032: "23005",
  40322: "21165",
  40324: "21209",
  40328: "21021",
  40330: "21167",
  40336: "21065",
  40337: "21173",
  40342: "21005",
  40346: "21165",
  40347: "21239",
  40348: "21017",
  40350: "21181",
  40351: "21205",
  40353: "21173",
  40355: "21187",
  40356: "21113",
  40358: "21011",
  40359: "21187",
  40360: "21011",
  40361: "21017",
  40363: "21187",
  4037: "23017",
  40370: "21209",
  40371: "21011",
  40372: "21167",
  40374: "21011",
  40379: "21209",
  4038: "23005",
  40380: "21197",
  40383: "21239",
  40385: "21151",
  40387: "21165",
  4039: "23005",
  40390: "21113",
  40391: "21049",
  4040: "23005",
  40402: "21109",
  40403: "21151",
  40409: "21203",
  4041: "23017",
  40419: "21137",
  4042: "23031",
  40422: "21021",
  4043: "23031",
  40437: "21137",
  40440: "21021",
  40442: "21137",
  40444: "21079",
  40445: "21203",
  40447: "21109",
  40456: "21203",
  4046: "23031",
  40460: "21203",
  40461: "21151",
  40464: "21021",
  40468: "21021",
  4047: "23031",
  40472: "21065",
  40475: "21151",
  4048: "23031",
  40481: "21109",
  40484: "21137",
  40486: "21109",
  40489: "21137",
  4049: "23031",
  4050: "23005",
  40502: "21067",
  40503: "21067",
  40504: "21067",
  40505: "21067",
  40507: "21067",
  40508: "21067",
  40509: "21067",
  4051: "23017",
  40510: "21067",
  40511: "21067",
  40513: "21067",
  40514: "21067",
  40515: "21067",
  40516: "21067",
  40517: "21067",
  4055: "23005",
  40601: "21073",
  4061: "23031",
  4062: "23005",
  4064: "23031",
  4066: "23005",
  4068: "23017",
  4069: "23005",
  40701: "21235",
  4071: "23005",
  4072: "23031",
  40729: "21125",
  4073: "23031",
  40734: "21121",
  40737: "21125",
  4074: "23005",
  40740: "21125",
  40741: "21125",
  40744: "21125",
  40759: "21235",
  4076: "23031",
  40763: "21235",
  40769: "21235",
  40771: "21121",
  4079: "23005",
  40806: "21095",
  40807: "21095",
  40810: "21095",
  40813: "21013",
  40815: "21095",
  40819: "21095",
  40820: "21095",
  40823: "21095",
  40824: "21095",
  40826: "21133",
  40827: "21131",
  40828: "21095",
  40829: "21095",
  4083: "23031",
  40830: "21095",
  40831: "21095",
  4084: "23005",
  40840: "21131",
  40845: "21013",
  40847: "21095",
  4085: "23005",
  40854: "21095",
  40855: "21095",
  40856: "21013",
  40858: "21131",
  4086: "23023",
  40862: "21133",
  40863: "21095",
  40865: "21095",
  40868: "21131",
  4087: "23031",
  40870: "21095",
  40873: "21095",
  40874: "21131",
  4088: "23017",
  4090: "23031",
  40902: "21013",
  40903: "21121",
  40906: "21121",
  40915: "21121",
  4092: "23005",
  40923: "21121",
  40927: "21095",
  4093: "23031",
  40935: "21121",
  40939: "21013",
  40940: "21013",
  40943: "21121",
  40949: "21121",
  4095: "23031",
  40953: "21121",
  40958: "21013",
  4096: "23005",
  40962: "21051",
  40965: "21013",
  4097: "23005",
  40977: "21013",
  40988: "21013",
  41001: "21037",
  41002: "21023",
  41003: "21097",
  41004: "21023",
  41005: "21015",
  41006: "21191",
  41007: "21037",
  41008: "21041",
  4101: "23005",
  41010: "21081",
  41011: "21117",
  41014: "21117",
  41015: "21117",
  41016: "21117",
  41017: "21117",
  41018: "21117",
  4102: "23005",
  4103: "23005",
  41030: "21081",
  41031: "21097",
  41033: "21191",
  41034: "21161",
  41035: "21081",
  41039: "21069",
  41040: "21191",
  41041: "21069",
  41042: "21015",
  41043: "21023",
  41044: "21023",
  41045: "21041",
  41046: "21077",
  41048: "21015",
  41049: "21069",
  4105: "23005",
  41051: "21117",
  41052: "21081",
  41055: "21161",
  41056: "21161",
  41059: "21037",
  4106: "23005",
  41063: "21117",
  41064: "21201",
  4107: "23005",
  41071: "21037",
  41073: "21037",
  41074: "21037",
  41075: "21037",
  41076: "21037",
  4108: "23005",
  41080: "21015",
  41083: "21077",
  41085: "21037",
  41086: "21077",
  4109: "23005",
  41091: "21015",
  41092: "21015",
  41093: "21069",
  41094: "21015",
  41095: "18155",
  41097: "21081",
  41098: "21041",
  4110: "23005",
  41101: "21019",
  41102: "21019",
  41121: "21089",
  41124: "21127",
  41129: "21019",
  41132: "21043",
  41139: "21089",
  41141: "21135",
  41143: "21043",
  41144: "21089",
  41146: "21043",
  41159: "21127",
  41164: "21043",
  41166: "21135",
  41168: "21019",
  41169: "21089",
  41171: "21063",
  41174: "21089",
  41175: "21089",
  41179: "21135",
  41180: "21127",
  41183: "21089",
  41189: "21135",
  41201: "21127",
  41203: "21159",
  41204: "21115",
  41214: "21159",
  41216: "21071",
  41219: "21115",
  41222: "21115",
  41224: "21159",
  41230: "21127",
  41231: "21159",
  41232: "21127",
  41234: "21115",
  41238: "21115",
  41240: "21115",
  41250: "21159",
  41254: "21115",
  41255: "21115",
  41256: "21115",
  41257: "21115",
  41260: "21115",
  41262: "21159",
  41263: "21115",
  41264: "21127",
  41265: "21115",
  41267: "21159",
  41268: "21115",
  41271: "21115",
  41274: "21115",
  41301: "21237",
  41311: "21129",
  41314: "21189",
  41332: "21175",
  41339: "21025",
  41348: "21025",
  41352: "21175",
  41425: "21175",
  41465: "21153",
  41472: "21175",
  41501: "21195",
  41503: "21195",
  41512: "21195",
  41513: "21195",
  41514: "21195",
  41519: "21195",
  41522: "21195",
  41524: "21195",
  41527: "21195",
  41528: "21195",
  41531: "21195",
  41535: "21195",
  41537: "21133",
  41539: "21195",
  41540: "21195",
  41543: "21195",
  41544: "21195",
  41547: "21195",
  41548: "21195",
  41553: "21195",
  41554: "21195",
  41555: "21195",
  41557: "21195",
  41558: "21195",
  41560: "21195",
  41562: "21195",
  41563: "21195",
  41564: "21195",
  41566: "21195",
  41567: "21195",
  41568: "21195",
  41571: "21195",
  41572: "21195",
  41601: "21071",
  41602: "21071",
  41603: "21071",
  41604: "21071",
  41605: "21071",
  41606: "21071",
  41615: "21071",
  41616: "21071",
  41619: "21071",
  41621: "21071",
  41622: "21071",
  41630: "21071",
  41631: "21071",
  41635: "21071",
  41636: "21071",
  41640: "21071",
  41642: "21071",
  41645: "21071",
  41647: "21071",
  41649: "21071",
  41650: "21071",
  41653: "21071",
  41655: "21071",
  41659: "21071",
  41660: "21071",
  41666: "21071",
  41667: "21071",
  41669: "21071",
  41701: "21193",
  41719: "21193",
  41721: "21193",
  41722: "21119",
  41723: "21193",
  41725: "21119",
  41727: "21193",
  41731: "21193",
  41740: "21119",
  41749: "21131",
  41754: "21193",
  41759: "21119",
  41764: "21131",
  41766: "21131",
  41773: "21193",
  41774: "21193",
  41776: "21131",
  41777: "21131",
  41804: "21133",
  41812: "21133",
  41815: "21133",
  41817: "21119",
  41819: "21133",
  41821: "21133",
  41822: "21119",
  41824: "21133",
  41825: "21133",
  41826: "21133",
  41828: "21119",
  41831: "21119",
  41832: "21133",
  41834: "21119",
  41835: "21133",
  41836: "21119",
  41837: "21133",
  41838: "21133",
  41839: "21119",
  41840: "21133",
  41843: "21119",
  41844: "21119",
  41845: "21133",
  41847: "21119",
  41855: "21133",
  41858: "21133",
  41859: "21119",
  41862: "21119",
  42001: "21145",
  42003: "21145",
  42020: "21035",
  42021: "21039",
  42023: "21039",
  42024: "21007",
  42025: "21157",
  42027: "21083",
  42028: "21139",
  42029: "21157",
  42031: "21105",
  42032: "21105",
  42035: "21039",
  42036: "21035",
  42038: "21143",
  42039: "21039",
  42040: "21083",
  42041: "21075",
  42044: "21157",
  42045: "21139",
  42047: "21139",
  42048: "21157",
  42049: "21035",
  42050: "21075",
  42051: "21083",
  42053: "21145",
  42054: "21035",
  42055: "21143",
  42056: "21007",
  42058: "21139",
  42064: "21055",
  42066: "21083",
  42069: "21083",
  42071: "21035",
  42076: "21035",
  42078: "21139",
  42079: "21083",
  42081: "21139",
  42082: "21083",
  42083: "21139",
  42085: "21083",
  42086: "21145",
  42087: "21007",
  42088: "21083",
  4210: "23001",
  42101: "21227",
  42103: "21227",
  42104: "21227",
  42120: "21003",
  42122: "21227",
  42123: "21009",
  42127: "21009",
  42129: "21169",
  42133: "21009",
  42134: "21213",
  42140: "21171",
  42141: "21009",
  42154: "21169",
  42156: "21009",
  42157: "21171",
  42159: "21227",
  42160: "21009",
  42164: "21003",
  42166: "21169",
  42167: "21171",
  4217: "23017",
  42170: "21227",
  42171: "21227",
  4219: "23017",
  4220: "23017",
  42202: "21141",
  42204: "21219",
  42206: "21141",
  42207: "21061",
  4221: "23017",
  42210: "21061",
  42211: "21221",
  42214: "21169",
  42215: "21047",
  42217: "21047",
  4222: "23001",
  42220: "21219",
  42232: "21047",
  42234: "21219",
  42236: "21047",
  4224: "23017",
  42240: "21047",
  42254: "21047",
  42256: "21141",
  42259: "21061",
  42261: "21031",
  42262: "21047",
  42265: "21141",
  42266: "21047",
  42273: "21031",
  42274: "21227",
  42275: "21061",
  42276: "21141",
  42280: "21219",
  42285: "21061",
  42286: "21219",
  42301: "21059",
  42303: "21059",
  42320: "21183",
  42321: "21177",
  42323: "21177",
  42324: "21177",
  42325: "21177",
  42326: "21177",
  42327: "21149",
  42328: "21183",
  42330: "21177",
  42333: "21183",
  42337: "21177",
  42339: "21177",
  42343: "21183",
  42344: "21177",
  42345: "21177",
  42347: "21183",
  42348: "21091",
  42349: "21183",
  42350: "21149",
  42351: "21091",
  42352: "21149",
  42354: "21183",
  42355: "21059",
  4236: "23001",
  42361: "21183",
  42366: "21059",
  42367: "21177",
  42368: "21183",
  42369: "21183",
  42371: "21149",
  42372: "21149",
  42376: "21059",
  42378: "21059",
  4239: "23007",
  4240: "23001",
  42404: "21233",
  42406: "21101",
  42408: "21107",
  42409: "21233",
  42410: "21107",
  42411: "21033",
  42413: "21107",
  42420: "21101",
  42431: "21107",
  42436: "21107",
  42437: "21225",
  42440: "21107",
  42441: "21107",
  42442: "21107",
  42445: "21033",
  42450: "21233",
  42451: "21101",
  42452: "21101",
  42453: "21107",
  42455: "21233",
  42456: "21233",
  42458: "21101",
  42459: "21225",
  42461: "21225",
  42462: "21225",
  42464: "21107",
  4250: "23001",
  42501: "21199",
  42503: "21199",
  42516: "21045",
  42518: "21199",
  42519: "21199",
  4252: "23001",
  42528: "21045",
  4253: "23001",
  42533: "21199",
  42539: "21045",
  4254: "23001",
  42541: "21045",
  42544: "21199",
  4255: "23017",
  42553: "21199",
  4256: "23001",
  42565: "21045",
  42566: "21045",
  42567: "21199",
  4257: "23017",
  4258: "23001",
  4259: "23011",
  4260: "23005",
  42602: "21053",
  42603: "21231",
  4261: "23017",
  42629: "21207",
  4263: "23001",
  42633: "21231",
  42634: "21147",
  42635: "21147",
  42642: "21207",
  42647: "21147",
  42649: "21147",
  4265: "23011",
  42653: "21147",
  4268: "23017",
  4270: "23017",
  42701: "21093",
  42712: "21085",
  42713: "21099",
  42715: "21001",
  42716: "21123",
  42717: "21057",
  42718: "21217",
  42721: "21085",
  42722: "21099",
  42724: "21093",
  42726: "21085",
  42728: "21001",
  42729: "21099",
  42732: "21093",
  42733: "21217",
  4274: "23001",
  42740: "21093",
  42741: "21001",
  42743: "21087",
  42746: "21099",
  42748: "21123",
  42749: "21099",
  42753: "21001",
  42754: "21085",
  42757: "21099",
  4276: "23017",
  42762: "21085",
  42764: "21087",
  42765: "21099",
  42776: "21123",
  42782: "21087",
  42784: "21093",
  42788: "21093",
  4280: "23001",
  4281: "23017",
  4282: "23001",
  4284: "23011",
  4287: "23023",
  4289: "23017",
  4290: "23017",
  4292: "23017",
  4294: "23007",
  43001: "39089",
  43003: "39117",
  43004: "39049",
  43006: "39075",
  43008: "39089",
  43009: "39021",
  43010: "39023",
  43011: "39083",
  43013: "39089",
  43014: "39083",
  43015: "39041",
  43016: "39049",
  43017: "39049",
  43018: "39089",
  43019: "39083",
  43021: "39041",
  43022: "39083",
  43023: "39089",
  43025: "39089",
  43026: "39049",
  43028: "39083",
  43029: "39097",
  43031: "39089",
  43033: "39089",
  43035: "39041",
  43036: "39159",
  43037: "39083",
  43040: "39159",
  43044: "39021",
  43045: "39159",
  43046: "39045",
  43050: "39083",
  43054: "39049",
  43055: "39089",
  43056: "39089",
  43060: "39021",
  43061: "39041",
  43062: "39089",
  43064: "39159",
  43065: "39041",
  43066: "39041",
  43067: "39159",
  43068: "39049",
  43071: "39089",
  43072: "39021",
  43074: "39041",
  43076: "39089",
  43077: "39159",
  43078: "39021",
  43080: "39089",
  43081: "39049",
  43082: "39041",
  43084: "39021",
  43085: "39049",
  43102: "39045",
  43103: "39129",
  43105: "39045",
  43106: "39047",
  43107: "39045",
  43110: "39049",
  43112: "39045",
  43113: "39129",
  43115: "39141",
  43116: "39129",
  43119: "39049",
  43123: "39049",
  43125: "39049",
  43128: "39047",
  43130: "39045",
  43135: "39073",
  43136: "39045",
  43137: "39049",
  43138: "39073",
  43140: "39097",
  43143: "39129",
  43145: "39129",
  43146: "39129",
  43147: "39045",
  43148: "39045",
  43149: "39073",
  43150: "39045",
  43151: "39097",
  43152: "39073",
  43153: "39097",
  43154: "39045",
  43155: "39045",
  43156: "39129",
  43157: "39045",
  43160: "39047",
  43162: "39097",
  43164: "39129",
  43201: "39049",
  43202: "39049",
  43203: "39049",
  43204: "39049",
  43205: "39049",
  43206: "39049",
  43207: "39049",
  43209: "39049",
  43211: "39049",
  43212: "39049",
  43213: "39049",
  43214: "39049",
  43215: "39049",
  43217: "39049",
  43219: "39049",
  43220: "39049",
  43221: "39049",
  43222: "39049",
  43223: "39049",
  43224: "39049",
  43227: "39049",
  43228: "39049",
  43229: "39049",
  43230: "39049",
  43231: "39049",
  43232: "39049",
  43235: "39049",
  43240: "39041",
  4330: "23011",
  43302: "39101",
  43310: "39091",
  43311: "39091",
  43314: "39101",
  43315: "39117",
  43316: "39175",
  43318: "39091",
  43319: "39091",
  43320: "39117",
  43321: "39117",
  43322: "39101",
  43323: "39175",
  43324: "39091",
  43326: "39065",
  43331: "39091",
  43332: "39101",
  43333: "39091",
  43334: "39117",
  43337: "39101",
  43338: "39117",
  43340: "39065",
  43341: "39101",
  43342: "39101",
  43343: "39091",
  43344: "39159",
  43345: "39065",
  43347: "39091",
  43348: "39091",
  43351: "39175",
  43356: "39101",
  43357: "39091",
  43358: "39091",
  43359: "39175",
  43360: "39091",
  43402: "39173",
  43406: "39173",
  43407: "39143",
  43410: "39143",
  43412: "39123",
  43413: "39173",
  43416: "39123",
  4342: "23015",
  43420: "39143",
  43430: "39123",
  43431: "39143",
  43432: "39123",
  43435: "39143",
  43437: "39173",
  43438: "39043",
  4344: "23011",
  43440: "39123",
  43442: "39143",
  43443: "39173",
  43445: "39123",
  43446: "39123",
  43447: "39173",
  43449: "39123",
  4345: "23011",
  43450: "39173",
  43451: "39173",
  43452: "39123",
  43456: "39123",
  43457: "39143",
  4346: "23011",
  43460: "39173",
  43462: "39173",
  43463: "39173",
  43464: "39043",
  43465: "39173",
  43466: "39173",
  43469: "39143",
  4347: "23011",
  4348: "23015",
  4349: "23011",
  4350: "23011",
  43501: "39171",
  43502: "39051",
  43504: "39095",
  43506: "39171",
  4351: "23011",
  43511: "39173",
  43512: "39039",
  43515: "39051",
  43516: "39069",
  43517: "39039",
  43518: "39171",
  4352: "23011",
  43521: "39051",
  43522: "39095",
  43524: "39069",
  43525: "39173",
  43526: "39039",
  43527: "39069",
  43528: "39095",
  4353: "23015",
  43532: "39069",
  43533: "39051",
  43534: "39069",
  43535: "39069",
  43536: "39039",
  43537: "39095",
  4354: "23027",
  43540: "39051",
  43542: "39095",
  43543: "39171",
  43545: "39069",
  43547: "39095",
  43548: "39069",
  43549: "39039",
  4355: "23011",
  43551: "39173",
  43554: "39171",
  43556: "39039",
  43557: "39171",
  43558: "39051",
  43560: "39095",
  43565: "39173",
  43566: "39095",
  43567: "39051",
  43569: "39173",
  4357: "23023",
  43570: "39171",
  43571: "39095",
  4358: "23011",
  43604: "39095",
  43605: "39095",
  43606: "39095",
  43607: "39095",
  43608: "39095",
  43609: "39095",
  43610: "39095",
  43611: "39095",
  43612: "39095",
  43613: "39095",
  43614: "39095",
  43615: "39095",
  43616: "39095",
  43617: "39095",
  43619: "39173",
  43620: "39095",
  43623: "39095",
  4363: "23011",
  4364: "23011",
  43701: "39119",
  43713: "39013",
  43716: "39111",
  43717: "39121",
  43718: "39013",
  43719: "39013",
  43720: "39119",
  43723: "39059",
  43724: "39121",
  43725: "39059",
  43727: "39119",
  43728: "39115",
  43730: "39127",
  43731: "39115",
  43732: "39059",
  43734: "39119",
  43735: "39119",
  43739: "39127",
  43746: "39119",
  43747: "39111",
  43748: "39127",
  43749: "39059",
  43755: "39059",
  43756: "39115",
  43758: "39115",
  43759: "39013",
  43760: "39127",
  43762: "39119",
  43764: "39127",
  43766: "39127",
  43767: "39119",
  43771: "39119",
  43772: "39121",
  43773: "39059",
  43777: "39119",
  43778: "39059",
  43779: "39121",
  43780: "39121",
  43782: "39127",
  43783: "39127",
  43787: "39115",
  43788: "39121",
  43793: "39111",
  43802: "39119",
  43804: "39075",
  43811: "39031",
  43812: "39031",
  43821: "39119",
  43822: "39031",
  43824: "39031",
  43830: "39119",
  43832: "39157",
  43837: "39157",
  43842: "39119",
  43843: "39083",
  43844: "39031",
  43845: "39031",
  43901: "39081",
  43903: "39081",
  43906: "39013",
  43907: "39067",
  43908: "39081",
  43910: "39081",
  43912: "39013",
  43913: "39081",
  43915: "39111",
  43917: "39081",
  43920: "39029",
  43930: "39081",
  43932: "39081",
  43933: "39013",
  43935: "39013",
  43938: "39081",
  43940: "39013",
  43942: "39013",
  43943: "39081",
  43944: "39081",
  43945: "39029",
  43947: "39013",
  43950: "39013",
  43952: "39081",
  43953: "39081",
  43963: "39081",
  43964: "39081",
  43968: "39029",
  43971: "39081",
  43973: "39067",
  43976: "39067",
  43977: "39013",
  43983: "39013",
  43986: "39067",
  43988: "39067",
  44001: "39093",
  44003: "39007",
  44004: "39007",
  4401: "23019",
  44010: "39007",
  44011: "39093",
  44012: "39093",
  44017: "39035",
  44021: "39055",
  44022: "39035",
  44023: "39055",
  44024: "39055",
  44026: "39055",
  44028: "39093",
  44030: "39007",
  44032: "39007",
  44035: "39093",
  44039: "39093",
  44040: "39035",
  44041: "39007",
  44044: "39093",
  44045: "39085",
  44046: "39055",
  44047: "39007",
  44048: "39007",
  44049: "39093",
  44050: "39093",
  44052: "39093",
  44053: "39093",
  44054: "39093",
  44055: "39093",
  44056: "39153",
  44057: "39085",
  4406: "23021",
  44060: "39085",
  44062: "39055",
  44064: "39055",
  44065: "39055",
  44067: "39153",
  44068: "39007",
  44070: "39035",
  44072: "39055",
  44074: "39093",
  44076: "39007",
  44077: "39085",
  4408: "23009",
  44081: "39085",
  44082: "39007",
  44084: "39007",
  44085: "39007",
  44086: "39055",
  44087: "39153",
  44089: "39043",
  44090: "39093",
  44092: "39085",
  44093: "39007",
  44094: "39085",
  44095: "39085",
  44099: "39007",
  44102: "39035",
  44103: "39035",
  44104: "39035",
  44105: "39035",
  44106: "39035",
  44107: "39035",
  44108: "39035",
  44109: "39035",
  4411: "23019",
  44110: "39035",
  44111: "39035",
  44112: "39035",
  44113: "39035",
  44114: "39035",
  44115: "39035",
  44116: "39035",
  44117: "39035",
  44118: "39035",
  44119: "39035",
  4412: "23019",
  44120: "39035",
  44121: "39035",
  44122: "39035",
  44123: "39035",
  44124: "39035",
  44125: "39035",
  44126: "39035",
  44127: "39035",
  44128: "39035",
  44129: "39035",
  44130: "39035",
  44131: "39035",
  44132: "39035",
  44133: "39035",
  44134: "39035",
  44135: "39035",
  44136: "39035",
  44137: "39035",
  44138: "39035",
  44139: "39035",
  4414: "23021",
  44140: "39035",
  44141: "39035",
  44142: "39035",
  44143: "39035",
  44144: "39035",
  44145: "39035",
  44146: "39035",
  44147: "39035",
  44149: "39035",
  4416: "23009",
  4418: "23019",
  4419: "23019",
  44201: "39133",
  44202: "39133",
  44203: "39153",
  44212: "39103",
  44214: "39169",
  44215: "39103",
  44216: "39153",
  44217: "39169",
  4422: "23019",
  44221: "39153",
  44223: "39153",
  44224: "39153",
  44230: "39169",
  44231: "39133",
  44233: "39103",
  44234: "39133",
  44235: "39103",
  44236: "39153",
  4424: "23029",
  44240: "39133",
  44241: "39133",
  44250: "39153",
  44251: "39103",
  44253: "39103",
  44254: "39103",
  44255: "39133",
  44256: "39103",
  4426: "23021",
  44260: "39133",
  44262: "39153",
  44264: "39153",
  44266: "39133",
  4427: "23019",
  44270: "39169",
  44272: "39133",
  44273: "39103",
  44275: "39103",
  44276: "39169",
  44278: "39153",
  4428: "23019",
  44280: "39103",
  44281: "39103",
  44286: "39153",
  44287: "39169",
  44288: "39133",
  4429: "23019",
  4430: "23019",
  44301: "39153",
  44302: "39153",
  44303: "39153",
  44304: "39153",
  44305: "39153",
  44306: "39153",
  44307: "39153",
  44310: "39153",
  44311: "39153",
  44312: "39153",
  44313: "39153",
  44314: "39153",
  44319: "39153",
  44320: "39153",
  44321: "39153",
  44333: "39153",
  4434: "23019",
  4435: "23019",
  4438: "23027",
  44401: "39099",
  44402: "39155",
  44403: "39155",
  44404: "39155",
  44405: "39099",
  44406: "39099",
  44408: "39029",
  4441: "23021",
  44410: "39155",
  44411: "39133",
  44412: "39133",
  44413: "39029",
  44417: "39155",
  44418: "39155",
  44420: "39155",
  44423: "39029",
  44425: "39155",
  44427: "39019",
  44428: "39155",
  44429: "39099",
  4443: "23021",
  44430: "39155",
  44431: "39029",
  44432: "39029",
  44436: "39099",
  44437: "39155",
  44438: "39155",
  4444: "23019",
  44440: "39155",
  44441: "39029",
  44442: "39099",
  44443: "39099",
  44444: "39155",
  44445: "39029",
  44446: "39155",
  44449: "39099",
  44450: "39155",
  44451: "39099",
  44452: "39099",
  44454: "39099",
  44455: "39029",
  44460: "39029",
  44470: "39155",
  44471: "39099",
  44473: "39155",
  4448: "23019",
  44481: "39155",
  44483: "39155",
  44484: "39155",
  44485: "39155",
  4449: "23019",
  44490: "39029",
  44491: "39155",
  4450: "23019",
  44502: "39099",
  44504: "39099",
  44505: "39099",
  44506: "39099",
  44507: "39099",
  44509: "39099",
  44510: "39099",
  44511: "39099",
  44512: "39099",
  44514: "39099",
  44515: "39099",
  4455: "23019",
  4456: "23019",
  4457: "23019",
  44601: "39151",
  44606: "39169",
  44608: "39151",
  44609: "39099",
  4461: "23019",
  44611: "39075",
  44612: "39157",
  44613: "39151",
  44614: "39151",
  44615: "39019",
  44618: "39169",
  44619: "39099",
  4462: "23019",
  44620: "39019",
  44621: "39157",
  44622: "39157",
  44624: "39157",
  44625: "39029",
  44626: "39151",
  44627: "39075",
  44628: "39075",
  44629: "39157",
  4463: "23021",
  44632: "39151",
  44633: "39075",
  44634: "39029",
  44637: "39075",
  44638: "39075",
  4464: "23021",
  44641: "39151",
  44643: "39019",
  44644: "39019",
  44645: "39169",
  44646: "39151",
  44647: "39151",
  44651: "39019",
  44654: "39075",
  44656: "39157",
  44657: "39029",
  44662: "39151",
  44663: "39157",
  44666: "39151",
  44667: "39169",
  44669: "39151",
  44672: "39099",
  44675: "39019",
  44676: "39169",
  44677: "39169",
  4468: "23019",
  44680: "39157",
  44681: "39075",
  44683: "39157",
  44685: "39153",
  44688: "39151",
  44689: "39075",
  44691: "39169",
  44695: "39019",
  44699: "39067",
  44703: "39151",
  44704: "39151",
  44705: "39151",
  44706: "39151",
  44707: "39151",
  44708: "39151",
  44709: "39151",
  4471: "23003",
  44710: "39151",
  44714: "39151",
  44718: "39151",
  4472: "23009",
  44720: "39151",
  44721: "39151",
  4473: "23019",
  44730: "39151",
  4474: "23019",
  4476: "23009",
  4478: "23025",
  4479: "23021",
  44802: "39063",
  44804: "39063",
  44805: "39005",
  44807: "39147",
  4481: "23021",
  44811: "39077",
  44813: "39139",
  44814: "39043",
  44815: "39147",
  44817: "39173",
  44818: "39147",
  44820: "39033",
  44822: "39139",
  44824: "39043",
  44826: "39077",
  44827: "39033",
  44830: "39147",
  44833: "39033",
  44836: "39147",
  44837: "39077",
  44838: "39005",
  44839: "39043",
  44840: "39005",
  44841: "39147",
  44842: "39005",
  44843: "39139",
  44844: "39175",
  44846: "39043",
  44847: "39077",
  44849: "39175",
  44851: "39077",
  44853: "39147",
  44854: "39033",
  44855: "39077",
  44857: "39077",
  44859: "39005",
  44864: "39005",
  44865: "39077",
  44866: "39005",
  44867: "39147",
  4487: "23019",
  44870: "39043",
  44875: "39139",
  44878: "39139",
  4488: "23019",
  44880: "39005",
  44882: "39175",
  44883: "39147",
  44887: "39033",
  44889: "39077",
  44890: "39077",
  44902: "39139",
  44903: "39139",
  44904: "39139",
  44905: "39139",
  44906: "39139",
  44907: "39139",
  4493: "23019",
  4496: "23027",
  45001: "39061",
  45002: "39061",
  45003: "39135",
  45005: "39165",
  45011: "39017",
  45013: "39017",
  45014: "39017",
  45015: "39017",
  45030: "39061",
  45032: "39165",
  45033: "39061",
  45034: "39165",
  45036: "39165",
  45039: "39165",
  45040: "39165",
  45042: "39017",
  45044: "39017",
  45050: "39017",
  45052: "39061",
  45053: "39017",
  45054: "39165",
  45056: "39017",
  45062: "39017",
  45064: "39135",
  45065: "39165",
  45066: "39165",
  45067: "39017",
  45068: "39165",
  45069: "39017",
  45070: "39135",
  45101: "39015",
  45102: "39025",
  45103: "39025",
  45106: "39025",
  45107: "39027",
  45111: "39061",
  45113: "39027",
  45118: "39015",
  45120: "39015",
  45121: "39015",
  45122: "39025",
  45123: "39071",
  45130: "39015",
  45133: "39071",
  45135: "39071",
  45140: "39025",
  45142: "39071",
  45144: "39001",
  45146: "39027",
  45148: "39027",
  45150: "39025",
  45152: "39165",
  45153: "39025",
  45154: "39015",
  45157: "39025",
  45159: "39027",
  45160: "39025",
  45162: "39165",
  45167: "39015",
  45168: "39015",
  45169: "39027",
  45171: "39015",
  45174: "39061",
  45176: "39025",
  45177: "39027",
  45202: "39061",
  45203: "39061",
  45204: "39061",
  45205: "39061",
  45206: "39061",
  45207: "39061",
  45208: "39061",
  45209: "39061",
  45211: "39061",
  45212: "39061",
  45213: "39061",
  45214: "39061",
  45215: "39061",
  45216: "39061",
  45217: "39061",
  45218: "39061",
  45219: "39061",
  45220: "39061",
  45223: "39061",
  45224: "39061",
  45225: "39061",
  45226: "39061",
  45227: "39061",
  45229: "39061",
  45230: "39061",
  45231: "39061",
  45232: "39061",
  45233: "39061",
  45236: "39061",
  45237: "39061",
  45238: "39061",
  45239: "39061",
  45240: "39061",
  45241: "39061",
  45242: "39061",
  45243: "39061",
  45244: "39061",
  45245: "39025",
  45246: "39061",
  45247: "39061",
  45248: "39061",
  45249: "39061",
  45251: "39061",
  45252: "39061",
  45255: "39061",
  4530: "23023",
  45302: "39149",
  45303: "39037",
  45304: "39037",
  45305: "39057",
  45306: "39149",
  45307: "39057",
  45308: "39037",
  45309: "39113",
  45310: "39107",
  45311: "39135",
  45312: "39109",
  45314: "39057",
  45315: "39113",
  45317: "39109",
  45318: "39109",
  45320: "39135",
  45321: "39135",
  45322: "39113",
  45323: "39023",
  45324: "39057",
  45325: "39113",
  45326: "39109",
  45327: "39113",
  45328: "39037",
  45330: "39135",
  45331: "39037",
  45332: "39037",
  45333: "39149",
  45334: "39149",
  45335: "39057",
  45337: "39109",
  45338: "39135",
  45339: "39109",
  45340: "39149",
  45341: "39023",
  45342: "39113",
  45344: "39023",
  45345: "39113",
  45346: "39037",
  45347: "39135",
  45348: "39037",
  45349: "39023",
  45351: "39037",
  45354: "39113",
  45356: "39109",
  45358: "39037",
  45359: "39109",
  45360: "39149",
  45362: "39037",
  45363: "39149",
  45365: "39149",
  45368: "39023",
  45369: "39023",
  4537: "23015",
  45370: "39057",
  45371: "39109",
  45372: "39023",
  45373: "39109",
  45377: "39113",
  45378: "39135",
  4538: "23015",
  45380: "39037",
  45381: "39135",
  45382: "39135",
  45383: "39109",
  45385: "39057",
  45387: "39057",
  45388: "39037",
  45389: "39021",
  4539: "23015",
  45390: "39037",
  45402: "39113",
  45403: "39113",
  45404: "39113",
  45405: "39113",
  45406: "39113",
  45409: "39113",
  45410: "39113",
  45414: "39113",
  45415: "39113",
  45416: "39113",
  45417: "39113",
  45419: "39113",
  45420: "39113",
  45424: "39113",
  45426: "39113",
  45429: "39113",
  4543: "23015",
  45430: "39057",
  45431: "39057",
  45432: "39057",
  45434: "39057",
  45439: "39113",
  4544: "23015",
  45440: "39057",
  45449: "39113",
  45458: "39113",
  45459: "39113",
  4547: "23013",
  4548: "23023",
  45502: "39023",
  45503: "39023",
  45504: "39023",
  45505: "39023",
  45506: "39023",
  4551: "23015",
  4553: "23015",
  4554: "23015",
  4555: "23015",
  4556: "23015",
  45601: "39141",
  45612: "39141",
  45613: "39131",
  45614: "39053",
  45616: "39001",
  45619: "39087",
  4562: "23023",
  45620: "39053",
  45623: "39053",
  45628: "39141",
  45629: "39145",
  4563: "23013",
  45631: "39053",
  45634: "39163",
  45638: "39087",
  45640: "39079",
  45644: "39141",
  45645: "39087",
  45647: "39163",
  45648: "39145",
  45650: "39001",
  45651: "39163",
  45652: "39145",
  45653: "39145",
  45654: "39073",
  45656: "39079",
  45657: "39145",
  45658: "39053",
  45659: "39087",
  45660: "39001",
  45661: "39131",
  45662: "39145",
  45663: "39145",
  45669: "39087",
  45671: "39145",
  45672: "39163",
  45674: "39053",
  45678: "39087",
  45679: "39001",
  4568: "23015",
  45680: "39087",
  45681: "39141",
  45682: "39145",
  45684: "39145",
  45685: "39053",
  45686: "39053",
  45688: "39087",
  45690: "39131",
  45692: "39079",
  45693: "39001",
  45694: "39145",
  45696: "39087",
  45697: "39001",
  45701: "39009",
  45710: "39009",
  45711: "39009",
  45714: "39167",
  45715: "39115",
  45716: "39009",
  45719: "39009",
  4572: "23015",
  45723: "39009",
  45724: "39167",
  45727: "39121",
  45729: "39167",
  45732: "39009",
  45735: "39009",
  4574: "23013",
  45740: "39009",
  45741: "39105",
  45742: "39167",
  45743: "39105",
  45744: "39167",
  45745: "39121",
  45746: "39167",
  45750: "39167",
  4576: "23015",
  45760: "39105",
  45761: "39009",
  45764: "39009",
  45766: "39009",
  45767: "39167",
  45768: "39167",
  45769: "39105",
  45771: "39105",
  45772: "39105",
  45775: "39105",
  45776: "39105",
  45778: "39009",
  45779: "39105",
  4578: "23015",
  45780: "39009",
  45782: "39009",
  45784: "39167",
  45786: "39167",
  45788: "39167",
  4579: "23023",
  45801: "39003",
  45804: "39003",
  45805: "39003",
  45806: "39003",
  45807: "39003",
  45808: "39003",
  45809: "39003",
  45810: "39065",
  45812: "39065",
  45813: "39125",
  45814: "39063",
  45817: "39003",
  45819: "39011",
  45820: "39003",
  45821: "39125",
  45822: "39107",
  45827: "39137",
  45828: "39107",
  45830: "39137",
  45831: "39137",
  45832: "39161",
  45833: "39161",
  45835: "39065",
  45836: "39065",
  45840: "39063",
  45841: "39063",
  45843: "39065",
  45844: "39137",
  45845: "39149",
  45846: "39107",
  45849: "39125",
  45850: "39003",
  45851: "39125",
  45853: "39137",
  45856: "39137",
  45858: "39063",
  45859: "39065",
  45860: "39107",
  45862: "39107",
  45863: "39161",
  45865: "39011",
  45866: "39107",
  45867: "39063",
  45868: "39063",
  45869: "39011",
  45871: "39149",
  45872: "39173",
  45873: "39125",
  45874: "39161",
  45875: "39137",
  45877: "39137",
  45879: "39125",
  45880: "39125",
  45881: "39063",
  45882: "39107",
  45883: "39107",
  45885: "39011",
  45886: "39161",
  45887: "39003",
  45889: "39063",
  45890: "39063",
  45891: "39161",
  45894: "39161",
  45895: "39011",
  45896: "39011",
  45898: "39161",
  46001: "18095",
  46011: "18095",
  46012: "18095",
  46013: "18095",
  46016: "18095",
  46017: "18095",
  46030: "18057",
  46031: "18057",
  46032: "18057",
  46033: "18057",
  46034: "18057",
  46035: "18023",
  46036: "18095",
  46037: "18057",
  46038: "18057",
  46039: "18023",
  46040: "18057",
  46041: "18023",
  46044: "18095",
  46048: "18095",
  46049: "18159",
  4605: "23009",
  46050: "18023",
  46051: "18095",
  46052: "18011",
  46055: "18059",
  46056: "18095",
  46057: "18023",
  46058: "18023",
  4606: "23029",
  46060: "18057",
  46062: "18057",
  46063: "18095",
  46064: "18095",
  46065: "18023",
  46068: "18159",
  46069: "18057",
  4607: "23009",
  46070: "18095",
  46071: "18011",
  46072: "18159",
  46074: "18057",
  46075: "18011",
  46076: "18159",
  46077: "18011",
  4609: "23009",
  46102: "18011",
  46103: "18063",
  46104: "18139",
  46105: "18133",
  46106: "18081",
  46107: "18097",
  46110: "18145",
  46111: "18109",
  46112: "18063",
  46113: "18097",
  46115: "18139",
  46117: "18059",
  46118: "18063",
  46120: "18133",
  46121: "18133",
  46122: "18063",
  46123: "18063",
  46124: "18081",
  46126: "18145",
  46127: "18139",
  46128: "18133",
  46130: "18145",
  46131: "18081",
  46133: "18139",
  46135: "18133",
  4614: "23009",
  46140: "18059",
  46142: "18081",
  46143: "18081",
  46144: "18145",
  46147: "18011",
  46148: "18065",
  46149: "18063",
  46150: "18139",
  46151: "18109",
  46156: "18139",
  46157: "18109",
  46158: "18109",
  4616: "23009",
  46160: "18109",
  46161: "18145",
  46162: "18081",
  46163: "18059",
  46164: "18013",
  46165: "18063",
  46166: "18109",
  46167: "18063",
  46168: "18063",
  4617: "23009",
  46171: "18133",
  46172: "18133",
  46173: "18139",
  46175: "18133",
  46176: "18145",
  46180: "18109",
  46181: "18081",
  46182: "18145",
  46184: "18081",
  46186: "18059",
  4619: "23029",
  46201: "18097",
  46202: "18097",
  46203: "18097",
  46204: "18097",
  46205: "18097",
  46208: "18097",
  46214: "18097",
  46216: "18097",
  46217: "18097",
  46218: "18097",
  46219: "18097",
  4622: "23029",
  46220: "18097",
  46221: "18097",
  46222: "18097",
  46224: "18097",
  46225: "18097",
  46226: "18097",
  46227: "18097",
  46228: "18097",
  46229: "18097",
  4623: "23029",
  46231: "18097",
  46234: "18097",
  46235: "18097",
  46236: "18097",
  46237: "18097",
  46239: "18097",
  46240: "18097",
  46241: "18097",
  46250: "18097",
  46254: "18097",
  46256: "18097",
  46259: "18097",
  4626: "23029",
  46260: "18097",
  46268: "18097",
  4627: "23009",
  46278: "18097",
  46280: "18057",
  4630: "23029",
  46301: "18127",
  46303: "18089",
  46304: "18127",
  46307: "18089",
  4631: "23029",
  46310: "18073",
  46311: "18089",
  46312: "18089",
  46319: "18089",
  46320: "18089",
  46321: "18089",
  46322: "18089",
  46323: "18089",
  46324: "18089",
  46327: "18089",
  4634: "23009",
  46340: "18091",
  46341: "18127",
  46342: "18089",
  46346: "18091",
  46347: "18127",
  46348: "18091",
  46349: "18111",
  46350: "18091",
  46356: "18089",
  46360: "18091",
  46365: "18091",
  46366: "18149",
  46368: "18127",
  46371: "18091",
  46373: "18089",
  46374: "18149",
  46375: "18089",
  46376: "18089",
  46377: "18089",
  46379: "18111",
  46382: "18091",
  46383: "18127",
  46385: "18127",
  46390: "18091",
  46391: "18091",
  46392: "18073",
  46393: "18127",
  46394: "18089",
  4640: "23009",
  46402: "18089",
  46403: "18089",
  46404: "18089",
  46405: "18089",
  46406: "18089",
  46407: "18089",
  46408: "18089",
  46409: "18089",
  46410: "18089",
  4643: "23029",
  4648: "23029",
  4649: "23029",
  46501: "18099",
  46504: "18099",
  46506: "18099",
  46507: "18039",
  46510: "18085",
  46511: "18099",
  46514: "18039",
  46516: "18039",
  46517: "18039",
  4652: "23029",
  46524: "18085",
  46526: "18039",
  46528: "18039",
  46530: "18141",
  46531: "18149",
  46532: "18149",
  46534: "18149",
  46536: "18141",
  46537: "18099",
  46538: "18085",
  46539: "18085",
  4654: "23029",
  46540: "18039",
  46542: "18085",
  46543: "18039",
  46544: "18141",
  46545: "18141",
  4655: "23029",
  46550: "18039",
  46552: "18141",
  46553: "18039",
  46554: "18141",
  46555: "18085",
  46561: "18141",
  46562: "18085",
  46563: "18099",
  46565: "18087",
  46567: "18085",
  4657: "23029",
  46570: "18099",
  46571: "18087",
  46573: "18141",
  46574: "18141",
  4658: "23029",
  46580: "18085",
  46582: "18085",
  46590: "18085",
  4660: "23009",
  46601: "18141",
  46613: "18141",
  46614: "18141",
  46615: "18141",
  46616: "18141",
  46617: "18141",
  46619: "18141",
  46628: "18141",
  46635: "18141",
  46637: "18141",
  4664: "23009",
  4666: "23029",
  4667: "23029",
  4668: "23029",
  46701: "18113",
  46702: "18069",
  46703: "18151",
  46705: "18033",
  46706: "18033",
  46710: "18113",
  46711: "18001",
  46714: "18179",
  46721: "18033",
  46723: "18183",
  46725: "18183",
  46730: "18033",
  46731: "18179",
  46732: "18113",
  46733: "18001",
  46737: "18151",
  46738: "18033",
  46740: "18001",
  46741: "18003",
  46742: "18151",
  46743: "18003",
  46745: "18003",
  46746: "18087",
  46747: "18151",
  46748: "18003",
  46750: "18069",
  46755: "18113",
  46759: "18179",
  4676: "23009",
  46760: "18113",
  46761: "18087",
  46763: "18113",
  46764: "18183",
  46765: "18003",
  46766: "18179",
  46767: "18113",
  4677: "23009",
  46770: "18179",
  46772: "18001",
  46773: "18003",
  46774: "18003",
  46776: "18151",
  46777: "18179",
  46779: "18151",
  46781: "18179",
  46783: "18069",
  46784: "18113",
  46785: "18033",
  46787: "18183",
  46788: "18003",
  4679: "23009",
  46791: "18179",
  46792: "18069",
  46793: "18033",
  46794: "18113",
  46795: "18087",
  46797: "18003",
  46798: "18003",
  46799: "18179",
  4680: "23029",
  46802: "18003",
  46803: "18003",
  46804: "18003",
  46805: "18003",
  46806: "18003",
  46807: "18003",
  46808: "18003",
  46809: "18003",
  4681: "23009",
  46814: "18003",
  46815: "18003",
  46816: "18003",
  46818: "18003",
  46819: "18003",
  46825: "18003",
  46835: "18003",
  4684: "23009",
  46845: "18003",
  46901: "18067",
  46902: "18067",
  4691: "23029",
  46910: "18049",
  46911: "18103",
  46913: "18015",
  46914: "18103",
  46915: "18015",
  46917: "18015",
  46919: "18053",
  46920: "18015",
  46923: "18015",
  46926: "18103",
  46928: "18053",
  46929: "18015",
  4693: "23009",
  46931: "18049",
  46932: "18017",
  46933: "18053",
  46936: "18067",
  46938: "18053",
  46939: "18049",
  4694: "23029",
  46940: "18169",
  46941: "18169",
  46943: "18169",
  46947: "18017",
  46950: "18017",
  46951: "18103",
  46952: "18053",
  46953: "18053",
  46957: "18053",
  46958: "18103",
  46959: "18103",
  46960: "18149",
  46962: "18169",
  46970: "18103",
  46974: "18169",
  46975: "18049",
  46978: "18017",
  46979: "18067",
  46982: "18085",
  46984: "18169",
  46985: "18131",
  46986: "18053",
  46987: "18053",
  46988: "18017",
  46989: "18053",
  46990: "18169",
  46991: "18053",
  46992: "18169",
  46994: "18017",
  46996: "18131",
  47001: "18029",
  47003: "18161",
  47006: "18137",
  47010: "18047",
  47011: "18155",
  47012: "18047",
  47016: "18047",
  47017: "18137",
  47018: "18029",
  47020: "18155",
  47022: "18029",
  47023: "18137",
  47024: "18047",
  47025: "18029",
  47030: "18047",
  47031: "18137",
  47032: "18029",
  47036: "18047",
  47037: "18137",
  47038: "18155",
  47040: "18115",
  47041: "18137",
  47042: "18137",
  47043: "18155",
  47060: "18029",
  47102: "18143",
  47106: "18019",
  47108: "18175",
  47110: "18061",
  47111: "18019",
  47112: "18061",
  47114: "18061",
  47115: "18061",
  47116: "18025",
  47117: "18061",
  47118: "18025",
  47119: "18043",
  47120: "18175",
  47122: "18043",
  47124: "18043",
  47125: "18175",
  47126: "18019",
  47129: "18019",
  47130: "18019",
  47135: "18061",
  47136: "18043",
  47137: "18025",
  47138: "18077",
  47140: "18117",
  47141: "18019",
  47142: "18061",
  47143: "18019",
  47145: "18025",
  47147: "18143",
  47150: "18043",
  47160: "18061",
  47161: "18061",
  47162: "18019",
  47163: "18019",
  47164: "18061",
  47165: "18175",
  47166: "18061",
  47167: "18175",
  47170: "18143",
  47172: "18019",
  47175: "18025",
  47177: "18019",
  47201: "18005",
  47203: "18005",
  47220: "18071",
  47223: "18079",
  47224: "18077",
  47227: "18079",
  47229: "18071",
  47230: "18077",
  47231: "18077",
  47232: "18005",
  47234: "18145",
  47235: "18071",
  47240: "18031",
  47243: "18077",
  47244: "18005",
  47246: "18005",
  47250: "18077",
  47260: "18071",
  47264: "18071",
  47265: "18079",
  47270: "18079",
  47272: "18145",
  47273: "18079",
  47274: "18071",
  47280: "18005",
  47281: "18175",
  47282: "18079",
  47283: "18031",
  4730: "23003",
  47302: "18035",
  47303: "18035",
  47304: "18035",
  47305: "18035",
  47320: "18035",
  47325: "18161",
  47326: "18075",
  47327: "18177",
  47330: "18177",
  47331: "18041",
  47334: "18035",
  47336: "18009",
  47338: "18035",
  47339: "18177",
  47340: "18135",
  47341: "18177",
  47342: "18035",
  47345: "18177",
  47346: "18177",
  47348: "18009",
  47351: "18065",
  47352: "18065",
  47353: "18161",
  47354: "18135",
  47355: "18135",
  47356: "18065",
  47357: "18177",
  47358: "18135",
  47359: "18009",
  4736: "23003",
  47360: "18065",
  47361: "18065",
  47362: "18065",
  47368: "18135",
  47369: "18075",
  47371: "18075",
  47373: "18075",
  47374: "18177",
  47380: "18135",
  47382: "18135",
  47383: "18035",
  47384: "18065",
  47385: "18065",
  47386: "18065",
  47387: "18065",
  47388: "18065",
  4739: "23003",
  47390: "18135",
  47393: "18177",
  47394: "18135",
  47396: "18035",
  4740: "23003",
  47401: "18105",
  47403: "18105",
  47404: "18105",
  47408: "18105",
  4742: "23003",
  47421: "18093",
  47424: "18055",
  47427: "18119",
  47429: "18105",
  4743: "23003",
  47431: "18119",
  47432: "18117",
  47433: "18119",
  47436: "18093",
  47438: "18055",
  47441: "18055",
  47443: "18055",
  47446: "18093",
  47448: "18013",
  47449: "18055",
  47451: "18093",
  47452: "18117",
  47453: "18101",
  47454: "18117",
  47456: "18119",
  47459: "18055",
  47460: "18119",
  47462: "18093",
  47464: "18105",
  47465: "18055",
  47468: "18105",
  47469: "18117",
  4747: "23003",
  47470: "18093",
  47471: "18055",
  4750: "23003",
  47501: "18027",
  47512: "18083",
  47513: "18037",
  47514: "18123",
  47515: "18123",
  47516: "18083",
  47519: "18027",
  47520: "18123",
  47521: "18037",
  47522: "18101",
  47523: "18147",
  47524: "18083",
  47525: "18123",
  47527: "18037",
  47528: "18083",
  47529: "18027",
  47531: "18147",
  47532: "18037",
  47535: "18083",
  47537: "18147",
  47541: "18037",
  47542: "18037",
  47546: "18037",
  47550: "18147",
  47551: "18123",
  47553: "18101",
  47557: "18083",
  47558: "18027",
  4756: "23003",
  47561: "18083",
  47562: "18027",
  47564: "18125",
  47567: "18125",
  47568: "18027",
  4757: "23003",
  47575: "18037",
  47576: "18123",
  47577: "18147",
  47578: "18083",
  47579: "18147",
  4758: "23003",
  47580: "18037",
  47581: "18101",
  47585: "18125",
  47586: "18123",
  47588: "18147",
  47590: "18125",
  47591: "18083",
  47596: "18083",
  47597: "18083",
  47598: "18125",
  47601: "18173",
  47610: "18173",
  47611: "18147",
  47612: "18129",
  47613: "18173",
  47615: "18147",
  47616: "18051",
  47619: "18173",
  47620: "18129",
  4763: "23003",
  47630: "18173",
  47631: "18129",
  47633: "18129",
  47634: "18147",
  47635: "18147",
  47637: "18173",
  47638: "18129",
  47639: "18051",
  47640: "18051",
  47648: "18051",
  47649: "18051",
  4765: "23019",
  47660: "18051",
  47665: "18051",
  47666: "18051",
  47670: "18051",
  4768: "23003",
  4769: "23003",
  47708: "18163",
  47710: "18163",
  47711: "18163",
  47712: "18163",
  47713: "18163",
  47714: "18163",
  47715: "18163",
  47720: "18163",
  47725: "18163",
  4776: "23003",
  47802: "18167",
  47803: "18167",
  47804: "18167",
  47805: "18167",
  47807: "18167",
  47832: "18121",
  47833: "18021",
  47834: "18021",
  47837: "18121",
  47838: "18153",
  47840: "18021",
  47841: "18021",
  47842: "18165",
  47846: "18021",
  47847: "18165",
  47848: "18153",
  47849: "18153",
  4785: "23003",
  47850: "18153",
  47854: "18165",
  47855: "18153",
  47857: "18021",
  47858: "18021",
  47859: "18121",
  4786: "23003",
  47861: "18153",
  47862: "18121",
  47866: "18167",
  47868: "18119",
  47872: "18121",
  47874: "18167",
  47879: "18153",
  47881: "18021",
  47882: "18153",
  47884: "18165",
  47885: "18167",
  47901: "18157",
  47904: "18157",
  47905: "18157",
  47906: "18157",
  47909: "18157",
  47917: "18171",
  47918: "18045",
  47920: "18157",
  47921: "18007",
  47922: "18111",
  47923: "18181",
  47926: "18181",
  47928: "18165",
  47929: "18181",
  47930: "18157",
  47932: "18045",
  47933: "18107",
  47940: "18107",
  47941: "18157",
  47942: "18007",
  47943: "18073",
  47944: "18007",
  47946: "18131",
  47948: "18111",
  47949: "18045",
  47950: "18181",
  47951: "18111",
  47952: "18045",
  47954: "18107",
  47955: "18107",
  47957: "18131",
  47959: "18181",
  47960: "18181",
  47963: "18111",
  47965: "18107",
  47966: "18165",
  47967: "18107",
  47968: "18107",
  47970: "18007",
  47971: "18007",
  47974: "18165",
  47975: "18171",
  47977: "18073",
  47978: "18073",
  47980: "18181",
  47981: "18157",
  47987: "18045",
  47989: "18107",
  47990: "18107",
  47991: "18171",
  47992: "18157",
  47993: "18171",
  47994: "18107",
  47995: "18181",
  48001: "26147",
  48002: "26147",
  48003: "26087",
  48005: "26099",
  48006: "26147",
  48009: "26125",
  48014: "26147",
  48015: "26099",
  48017: "26125",
  48021: "26099",
  48022: "26147",
  48023: "26147",
  48025: "26125",
  48026: "26099",
  48027: "26147",
  48028: "26147",
  48030: "26125",
  48032: "26147",
  48033: "26125",
  48034: "26125",
  48035: "26099",
  48036: "26099",
  48038: "26099",
  48039: "26147",
  48040: "26147",
  48041: "26147",
  48042: "26099",
  48043: "26099",
  48044: "26099",
  48045: "26099",
  48047: "26099",
  48048: "26099",
  48049: "26147",
  48050: "26099",
  48051: "26099",
  48054: "26147",
  48059: "26147",
  48060: "26147",
  48062: "26099",
  48063: "26147",
  48064: "26147",
  48065: "26099",
  48066: "26099",
  48067: "26125",
  48069: "26125",
  48070: "26125",
  48071: "26125",
  48072: "26125",
  48073: "26125",
  48074: "26147",
  48075: "26125",
  48076: "26125",
  48079: "26147",
  48080: "26099",
  48081: "26099",
  48082: "26099",
  48083: "26125",
  48084: "26125",
  48085: "26125",
  48088: "26099",
  48089: "26099",
  48091: "26099",
  48092: "26099",
  48093: "26099",
  48094: "26099",
  48095: "26099",
  48096: "26099",
  48097: "26147",
  48098: "26125",
  48101: "26163",
  48103: "26161",
  48104: "26161",
  48105: "26161",
  48108: "26161",
  48111: "26163",
  48114: "26093",
  48116: "26093",
  48117: "26115",
  48118: "26161",
  48120: "26163",
  48122: "26163",
  48124: "26163",
  48125: "26163",
  48126: "26163",
  48127: "26163",
  48128: "26163",
  48130: "26161",
  48131: "26115",
  48133: "26115",
  48134: "26163",
  48135: "26163",
  48137: "26093",
  48138: "26163",
  48139: "26093",
  48140: "26115",
  48141: "26163",
  48144: "26115",
  48145: "26115",
  48146: "26163",
  48150: "26163",
  48152: "26163",
  48154: "26163",
  48157: "26115",
  48158: "26161",
  48159: "26115",
  48160: "26161",
  48161: "26115",
  48162: "26115",
  48164: "26163",
  48165: "26125",
  48166: "26115",
  48167: "26125",
  48168: "26163",
  48169: "26093",
  48170: "26163",
  48173: "26163",
  48174: "26163",
  48176: "26161",
  48178: "26125",
  48179: "26115",
  48180: "26163",
  48182: "26115",
  48183: "26163",
  48184: "26163",
  48185: "26163",
  48186: "26163",
  48187: "26163",
  48188: "26163",
  48189: "26161",
  48190: "26161",
  48191: "26161",
  48192: "26163",
  48193: "26163",
  48195: "26163",
  48197: "26161",
  48198: "26161",
  48201: "26163",
  48202: "26163",
  48203: "26163",
  48204: "26163",
  48205: "26163",
  48206: "26163",
  48207: "26163",
  48208: "26163",
  48209: "26163",
  48210: "26163",
  48211: "26163",
  48212: "26163",
  48213: "26163",
  48214: "26163",
  48215: "26163",
  48216: "26163",
  48217: "26163",
  48218: "26163",
  48219: "26163",
  48220: "26125",
  48221: "26163",
  48223: "26163",
  48224: "26163",
  48225: "26163",
  48226: "26163",
  48227: "26163",
  48228: "26163",
  48229: "26163",
  48230: "26163",
  48234: "26163",
  48235: "26163",
  48236: "26163",
  48237: "26125",
  48238: "26163",
  48239: "26163",
  48240: "26163",
  48301: "26125",
  48302: "26125",
  48304: "26125",
  48306: "26125",
  48307: "26125",
  48309: "26125",
  48310: "26099",
  48312: "26099",
  48313: "26099",
  48314: "26099",
  48315: "26099",
  48316: "26099",
  48317: "26099",
  48320: "26125",
  48322: "26125",
  48323: "26125",
  48324: "26125",
  48326: "26125",
  48327: "26125",
  48328: "26125",
  48329: "26125",
  48331: "26125",
  48334: "26125",
  48335: "26125",
  48336: "26125",
  48340: "26125",
  48341: "26125",
  48342: "26125",
  48346: "26125",
  48348: "26125",
  48350: "26125",
  48353: "26093",
  48356: "26125",
  48357: "26125",
  48359: "26125",
  48360: "26125",
  48362: "26125",
  48363: "26125",
  48367: "26125",
  48370: "26125",
  48371: "26125",
  48374: "26125",
  48375: "26125",
  48377: "26125",
  48380: "26125",
  48381: "26125",
  48382: "26125",
  48383: "26125",
  48386: "26125",
  48390: "26125",
  48393: "26125",
  48401: "26151",
  4841: "23013",
  48412: "26087",
  48413: "26063",
  48414: "26155",
  48415: "26145",
  48416: "26151",
  48417: "26145",
  48418: "26155",
  48419: "26151",
  48420: "26049",
  48421: "26087",
  48422: "26151",
  48423: "26049",
  48426: "26151",
  48427: "26151",
  48428: "26087",
  48429: "26155",
  4843: "23013",
  48430: "26049",
  48432: "26063",
  48433: "26049",
  48434: "26151",
  48435: "26157",
  48436: "26049",
  48437: "26049",
  48438: "26049",
  48439: "26049",
  48440: "26087",
  48441: "26063",
  48442: "26125",
  48444: "26087",
  48445: "26063",
  48446: "26087",
  48449: "26049",
  48450: "26151",
  48451: "26049",
  48453: "26151",
  48454: "26151",
  48455: "26087",
  48456: "26151",
  48457: "26049",
  48458: "26049",
  48460: "26155",
  48461: "26087",
  48462: "26125",
  48463: "26049",
  48464: "26087",
  48465: "26151",
  48466: "26151",
  48467: "26063",
  48468: "26063",
  48469: "26151",
  4847: "23013",
  48470: "26063",
  48471: "26151",
  48472: "26151",
  48473: "26049",
  48475: "26063",
  48476: "26155",
  4848: "23027",
  4849: "23027",
  48503: "26049",
  48504: "26049",
  48505: "26049",
  48506: "26049",
  48507: "26049",
  48509: "26049",
  48519: "26049",
  48529: "26049",
  4853: "23013",
  48532: "26049",
  4854: "23013",
  4856: "23013",
  4858: "23013",
  4860: "23013",
  48601: "26145",
  48602: "26145",
  48603: "26145",
  48604: "26145",
  48607: "26145",
  48609: "26145",
  4861: "23013",
  48610: "26129",
  48611: "26017",
  48612: "26051",
  48613: "26017",
  48614: "26145",
  48615: "26111",
  48616: "26145",
  48617: "26035",
  48618: "26111",
  48619: "26135",
  4862: "23013",
  48621: "26135",
  48622: "26035",
  48623: "26145",
  48624: "26051",
  48625: "26035",
  48626: "26145",
  48627: "26143",
  48628: "26111",
  48629: "26143",
  4863: "23013",
  48631: "26017",
  48632: "26035",
  48634: "26017",
  48635: "26129",
  48636: "26135",
  48637: "26145",
  48638: "26145",
  4864: "23013",
  48640: "26111",
  48642: "26111",
  48647: "26135",
  48649: "26145",
  48650: "26017",
  48651: "26143",
  48652: "26111",
  48653: "26143",
  48654: "26129",
  48655: "26145",
  48656: "26143",
  48657: "26111",
  48658: "26011",
  48659: "26011",
  48661: "26129",
  48662: "26057",
  48701: "26157",
  48703: "26011",
  48705: "26001",
  48706: "26017",
  48708: "26017",
  48720: "26063",
  48721: "26001",
  48722: "26145",
  48723: "26157",
  48724: "26145",
  48725: "26063",
  48726: "26157",
  48727: "26087",
  48728: "26001",
  48729: "26157",
  48730: "26069",
  48731: "26063",
  48732: "26017",
  48733: "26157",
  48734: "26145",
  48735: "26157",
  48737: "26001",
  48738: "26001",
  48739: "26069",
  48740: "26001",
  48741: "26157",
  48742: "26001",
  48744: "26157",
  48745: "26001",
  48746: "26157",
  48747: "26017",
  48748: "26069",
  48749: "26011",
  48750: "26069",
  48754: "26063",
  48755: "26063",
  48756: "26129",
  48757: "26157",
  48759: "26063",
  48760: "26087",
  48761: "26129",
  48762: "26001",
  48763: "26069",
  48765: "26069",
  48766: "26011",
  48767: "26157",
  48768: "26157",
  48770: "26069",
  48801: "26057",
  48806: "26057",
  48807: "26145",
  48808: "26037",
  48809: "26067",
  48811: "26117",
  48813: "26045",
  48815: "26067",
  48817: "26155",
  48818: "26117",
  48819: "26065",
  48820: "26037",
  48821: "26045",
  48822: "26037",
  48823: "26065",
  48827: "26045",
  48829: "26117",
  48831: "26037",
  48832: "26057",
  48834: "26117",
  48835: "26037",
  48836: "26093",
  48837: "26045",
  48838: "26117",
  48840: "26065",
  48841: "26155",
  48842: "26065",
  48843: "26093",
  48845: "26067",
  48846: "26067",
  48847: "26057",
  48848: "26155",
  48849: "26067",
  48850: "26117",
  48851: "26067",
  48853: "26037",
  48854: "26065",
  48855: "26093",
  48856: "26057",
  48857: "26155",
  48858: "26073",
  48860: "26067",
  48861: "26045",
  48864: "26065",
  48865: "26067",
  48866: "26155",
  48867: "26155",
  48871: "26057",
  48872: "26155",
  48873: "26067",
  48875: "26067",
  48876: "26045",
  48877: "26057",
  48878: "26073",
  48879: "26037",
  48880: "26057",
  48881: "26067",
  48883: "26073",
  48884: "26117",
  48885: "26117",
  48886: "26117",
  48888: "26117",
  48889: "26057",
  48890: "26045",
  48891: "26117",
  48892: "26065",
  48893: "26073",
  48894: "26037",
  48895: "26065",
  48897: "26015",
  48906: "26065",
  48910: "26065",
  48911: "26065",
  48912: "26065",
  48915: "26065",
  48917: "26045",
  48933: "26065",
  49001: "26077",
  49002: "26077",
  49004: "26077",
  49006: "26077",
  49007: "26077",
  49008: "26077",
  49009: "26077",
  4901: "23011",
  49010: "26005",
  49011: "26025",
  49012: "26077",
  49013: "26159",
  49014: "26025",
  49015: "26025",
  49017: "26025",
  49021: "26045",
  49022: "26021",
  49024: "26077",
  49026: "26159",
  49028: "26023",
  49029: "26025",
  49030: "26149",
  49031: "26027",
  49032: "26149",
  49033: "26025",
  49034: "26077",
  49036: "26023",
  49037: "26025",
  49038: "26021",
  49040: "26149",
  49042: "26149",
  49043: "26159",
  49045: "26159",
  49046: "26015",
  49047: "26027",
  49048: "26077",
  49050: "26015",
  49051: "26025",
  49052: "26077",
  49053: "26077",
  49055: "26159",
  49056: "26159",
  49057: "26159",
  49058: "26015",
  49060: "26015",
  49061: "26027",
  49064: "26159",
  49065: "26159",
  49066: "26149",
  49067: "26027",
  49068: "26025",
  49070: "26005",
  49071: "26159",
  49072: "26149",
  49073: "26015",
  49076: "26045",
  49078: "26005",
  49079: "26159",
  49080: "26005",
  49082: "26023",
  49083: "26077",
  49085: "26021",
  49087: "26077",
  49088: "26077",
  49089: "26023",
  49090: "26159",
  49091: "26149",
  49092: "26025",
  49093: "26149",
  49094: "26023",
  49095: "26027",
  49096: "26045",
  49097: "26077",
  49098: "26021",
  49099: "26149",
  4910: "23011",
  49101: "26021",
  49102: "26021",
  49103: "26021",
  49106: "26021",
  49107: "26021",
  4911: "23025",
  49111: "26021",
  49112: "26027",
  49113: "26021",
  49115: "26021",
  49116: "26021",
  49117: "26021",
  49120: "26021",
  49125: "26021",
  49126: "26021",
  49127: "26021",
  49128: "26021",
  49129: "26021",
  49130: "26027",
  4915: "23027",
  4917: "23011",
  4920: "23025",
  49201: "26075",
  49202: "26075",
  49203: "26075",
  4921: "23027",
  4922: "23027",
  49220: "26091",
  49221: "26091",
  49224: "26025",
  49227: "26059",
  49228: "26091",
  49229: "26091",
  49230: "26075",
  49232: "26059",
  49233: "26091",
  49234: "26075",
  49235: "26091",
  49236: "26161",
  49237: "26075",
  49238: "26091",
  4924: "23025",
  49240: "26075",
  49241: "26075",
  49242: "26059",
  49245: "26025",
  49246: "26075",
  49247: "26091",
  49248: "26091",
  49249: "26059",
  49250: "26059",
  49251: "26065",
  49252: "26059",
  49253: "26091",
  49254: "26075",
  49255: "26023",
  49256: "26091",
  49259: "26075",
  49262: "26059",
  49264: "26065",
  49265: "26091",
  49266: "26059",
  49267: "26115",
  49268: "26091",
  49269: "26075",
  4927: "23011",
  49270: "26115",
  49271: "26059",
  49272: "26075",
  49274: "26059",
  49276: "26091",
  49277: "26075",
  49279: "26091",
  4928: "23019",
  49282: "26059",
  49283: "26075",
  49284: "26075",
  49285: "26065",
  49286: "26091",
  49287: "26091",
  49288: "26059",
  4929: "23025",
  4930: "23019",
  49301: "26081",
  49302: "26081",
  49303: "26121",
  49304: "26085",
  49305: "26107",
  49306: "26081",
  49307: "26107",
  49309: "26123",
  49310: "26073",
  49312: "26123",
  49315: "26081",
  49316: "26081",
  49318: "26121",
  49319: "26081",
  4932: "23019",
  49320: "26107",
  49321: "26081",
  49322: "26117",
  49323: "26005",
  49325: "26015",
  49326: "26117",
  49327: "26123",
  49328: "26005",
  49329: "26117",
  49330: "26081",
  49331: "26081",
  49332: "26107",
  49333: "26015",
  49336: "26107",
  49337: "26123",
  49338: "26123",
  49339: "26117",
  49340: "26107",
  49341: "26081",
  49342: "26107",
  49343: "26081",
  49344: "26005",
  49345: "26081",
  49346: "26107",
  49347: "26117",
  49348: "26005",
  49349: "26123",
  4937: "23025",
  4938: "23007",
  49401: "26139",
  49402: "26105",
  49403: "26139",
  49404: "26139",
  49405: "26105",
  49406: "26005",
  49408: "26005",
  4941: "23027",
  49410: "26105",
  49411: "26105",
  49412: "26123",
  49415: "26121",
  49417: "26139",
  49418: "26081",
  49419: "26005",
  4942: "23021",
  49420: "26127",
  49421: "26127",
  49423: "26139",
  49424: "26139",
  49425: "26121",
  49426: "26139",
  49428: "26139",
  4943: "23025",
  49431: "26105",
  49434: "26139",
  49435: "26139",
  49436: "26127",
  49437: "26121",
  49440: "26121",
  49441: "26121",
  49442: "26121",
  49444: "26121",
  49445: "26121",
  49446: "26127",
  49448: "26139",
  49449: "26127",
  4945: "23025",
  49450: "26005",
  49451: "26121",
  49452: "26127",
  49453: "26005",
  49454: "26105",
  49455: "26127",
  49456: "26139",
  49457: "26121",
  49459: "26127",
  49460: "26139",
  49461: "26121",
  49464: "26139",
  4947: "23007",
  4949: "23027",
  4950: "23025",
  49503: "26081",
  49504: "26081",
  49505: "26081",
  49506: "26081",
  49507: "26081",
  49508: "26081",
  49509: "26081",
  4951: "23027",
  49512: "26081",
  49519: "26081",
  4952: "23027",
  49525: "26081",
  4953: "23019",
  49534: "26081",
  49544: "26081",
  49546: "26081",
  49548: "26081",
  4955: "23007",
  4957: "23025",
  4958: "23025",
  49601: "26165",
  4961: "23025",
  49612: "26079",
  49613: "26101",
  49614: "26101",
  49615: "26009",
  49616: "26019",
  49617: "26019",
  49618: "26165",
  49619: "26101",
  49620: "26165",
  49621: "26089",
  49622: "26009",
  49623: "26085",
  49625: "26101",
  49629: "26009",
  4963: "23011",
  49630: "26089",
  49631: "26133",
  49632: "26113",
  49633: "26079",
  49635: "26019",
  49636: "26089",
  49637: "26055",
  49638: "26165",
  49639: "26133",
  49640: "26019",
  49643: "26055",
  49644: "26085",
  49645: "26101",
  49646: "26079",
  49648: "26009",
  49649: "26055",
  4965: "23025",
  49650: "26019",
  49651: "26113",
  49653: "26089",
  49654: "26089",
  49655: "26133",
  49656: "26085",
  49657: "26113",
  49659: "26009",
  4966: "23007",
  49660: "26101",
  49663: "26165",
  49664: "26089",
  49665: "26133",
  49668: "26165",
  4967: "23025",
  49670: "26089",
  49674: "26089",
  49675: "26101",
  49676: "26079",
  49677: "26133",
  49679: "26133",
  49680: "26079",
  49682: "26089",
  49683: "26019",
  49684: "26055",
  49685: "26055",
  49686: "26055",
  49688: "26133",
  49689: "26101",
  4969: "23019",
  49690: "26055",
  49696: "26055",
  4970: "23007",
  49701: "26047",
  49706: "26047",
  49707: "26007",
  49709: "26119",
  4971: "23025",
  49710: "26033",
  49712: "26029",
  49713: "26029",
  49715: "26033",
  49716: "26047",
  49718: "26047",
  49719: "26097",
  49720: "26029",
  49721: "26031",
  49724: "26033",
  49725: "26033",
  49726: "26033",
  49727: "26029",
  49729: "26009",
  4973: "23027",
  49730: "26009",
  49733: "26039",
  49735: "26137",
  49736: "26033",
  49738: "26039",
  4974: "23027",
  49740: "26047",
  49743: "26141",
  49745: "26097",
  49746: "26119",
  49747: "26007",
  49749: "26031",
  49751: "26137",
  49752: "26033",
  49753: "26007",
  49755: "26047",
  49756: "26135",
  49757: "26097",
  49759: "26141",
  4976: "23025",
  49760: "26097",
  49762: "26097",
  49765: "26031",
  49766: "26007",
  49768: "26033",
  49769: "26047",
  49770: "26047",
  49774: "26033",
  49775: "26097",
  49776: "26141",
  49779: "26141",
  4978: "23025",
  49780: "26033",
  49781: "26097",
  49782: "26029",
  49783: "26033",
  49788: "26033",
  4979: "23025",
  49791: "26031",
  49793: "26033",
  49795: "26137",
  49796: "26029",
  49799: "26031",
  49801: "26043",
  49802: "26043",
  49805: "26083",
  49806: "26003",
  49807: "26041",
  4981: "23027",
  49812: "26109",
  49814: "26103",
  49817: "26153",
  49818: "26041",
  49820: "26097",
  49821: "26109",
  49827: "26097",
  49829: "26041",
  4983: "23007",
  49831: "26043",
  49833: "26103",
  49835: "26041",
  49836: "26153",
  49837: "26041",
  49838: "26097",
  49839: "26003",
  4984: "23007",
  49840: "26153",
  49841: "26103",
  49847: "26109",
  49848: "26109",
  49849: "26103",
  49853: "26095",
  49854: "26153",
  49855: "26103",
  49858: "26109",
  4986: "23027",
  49861: "26103",
  49862: "26003",
  49863: "26109",
  49866: "26103",
  49868: "26095",
  4987: "23027",
  49870: "26043",
  49871: "26103",
  49873: "26109",
  49874: "26109",
  49878: "26041",
  49879: "26103",
  4988: "23027",
  49880: "26041",
  49884: "26003",
  49885: "26103",
  49886: "26109",
  49887: "26109",
  4989: "23011",
  49892: "26043",
  49893: "26109",
  49895: "26003",
  49896: "26109",
  49902: "26071",
  49903: "26071",
  49905: "26061",
  49908: "26013",
  49910: "26131",
  49911: "26053",
  49913: "26061",
  49915: "26071",
  49916: "26061",
  49918: "26083",
  49920: "26071",
  49922: "26061",
  49927: "26071",
  49930: "26061",
  49931: "26061",
  49934: "26061",
  49935: "26071",
  49938: "26053",
  49945: "26061",
  49946: "26013",
  49947: "26131",
  49950: "26083",
  49953: "26131",
  49958: "26061",
  49963: "26061",
  49967: "26061",
  49968: "26053",
  49969: "26053",
  49971: "26131",
  50001: "19181",
  50002: "19077",
  50003: "19049",
  50005: "19127",
  50006: "19083",
  50007: "19153",
  50008: "19185",
  50009: "19153",
  5001: "50027",
  50010: "19169",
  50014: "19169",
  50020: "19029",
  50021: "19153",
  50022: "19029",
  50023: "19153",
  50025: "19009",
  50026: "19077",
  50027: "19123",
  50028: "19099",
  50029: "19077",
  50032: "19153",
  50035: "19153",
  50036: "19015",
  50039: "19049",
  50040: "19015",
  50042: "19009",
  50044: "19125",
  50046: "19169",
  50047: "19181",
  50048: "19001",
  50049: "19117",
  50050: "19073",
  50051: "19127",
  50054: "19099",
  50055: "19169",
  50056: "19169",
  50057: "19125",
  50058: "19077",
  50060: "19185",
  50061: "19121",
  50062: "19125",
  50063: "19049",
  50065: "19053",
  50066: "19049",
  50067: "19053",
  50068: "19117",
  50069: "19049",
  50070: "19049",
  50071: "19197",
  50072: "19049",
  50073: "19153",
  50074: "19159",
  50075: "19079",
  50076: "19009",
  50103: "19053",
  50105: "19169",
  50106: "19127",
  50107: "19073",
  50108: "19053",
  50109: "19153",
  50111: "19153",
  50112: "19157",
  50115: "19077",
  50116: "19125",
  50117: "19009",
  50118: "19181",
  50119: "19125",
  50120: "19127",
  50122: "19083",
  50123: "19185",
  50124: "19169",
  50125: "19181",
  50126: "19083",
  50128: "19077",
  50129: "19073",
  50130: "19079",
  50131: "19153",
  50134: "19169",
  50135: "19099",
  50136: "19107",
  50138: "19125",
  50139: "19181",
  50140: "19053",
  50141: "19127",
  50142: "19127",
  50143: "19123",
  50144: "19053",
  50146: "19049",
  50147: "19185",
  50148: "19127",
  50149: "19121",
  50150: "19135",
  50151: "19117",
  50153: "19099",
  50154: "19169",
  50155: "19121",
  50156: "19015",
  50157: "19157",
  50158: "19127",
  50160: "19181",
  50161: "19153",
  50162: "19127",
  50163: "19125",
  50164: "19077",
  50166: "19181",
  50167: "19049",
  50168: "19099",
  50169: "19153",
  50170: "19125",
  50171: "19157",
  50173: "19171",
  50174: "19039",
  50201: "19169",
  50206: "19083",
  50207: "19123",
  50208: "19099",
  50210: "19181",
  50211: "19181",
  50212: "19015",
  50213: "19039",
  50214: "19125",
  50216: "19077",
  50217: "19073",
  50219: "19125",
  50220: "19049",
  50222: "19121",
  50223: "19015",
  50225: "19125",
  50226: "19153",
  50228: "19099",
  50229: "19181",
  50230: "19083",
  50232: "19099",
  50233: "19049",
  50234: "19127",
  50235: "19073",
  50236: "19169",
  50237: "19153",
  50238: "19117",
  50239: "19127",
  50240: "19121",
  50242: "19157",
  50243: "19153",
  50244: "19153",
  50246: "19079",
  50247: "19127",
  50248: "19169",
  50249: "19079",
  50250: "19001",
  50251: "19099",
  50252: "19181",
  50254: "19175",
  50256: "19125",
  50257: "19121",
  50258: "19083",
  50261: "19121",
  50262: "19053",
  50263: "19049",
  50264: "19053",
  50265: "19153",
  50266: "19049",
  50268: "19107",
  50271: "19079",
  50273: "19121",
  50274: "19029",
  50275: "19039",
  50276: "19049",
  50277: "19077",
  50278: "19169",
  50309: "19153",
  50310: "19153",
  50311: "19153",
  50312: "19153",
  50313: "19153",
  50314: "19153",
  50315: "19153",
  50316: "19153",
  50317: "19153",
  5032: "50027",
  50320: "19153",
  50321: "19153",
  50322: "19153",
  50323: "19153",
  50324: "19153",
  50325: "19153",
  50327: "19153",
  5033: "50017",
  5034: "50027",
  5035: "50027",
  5036: "50017",
  5037: "50027",
  5038: "50017",
  5039: "50017",
  5040: "50017",
  50401: "19033",
  5042: "50005",
  50420: "19069",
  50421: "19197",
  50423: "19081",
  50424: "19189",
  50428: "19033",
  5043: "50017",
  50430: "19081",
  50432: "19081",
  50433: "19033",
  50434: "19195",
  50435: "19067",
  50436: "19189",
  50438: "19081",
  50439: "19081",
  50440: "19195",
  50441: "19069",
  50444: "19195",
  50446: "19195",
  50447: "19081",
  50448: "19195",
  50449: "19081",
  5045: "50017",
  50450: "19189",
  50451: "19109",
  50452: "19069",
  50453: "19189",
  50455: "19131",
  50456: "19195",
  50457: "19033",
  50458: "19067",
  50459: "19195",
  5046: "50005",
  50460: "19131",
  50461: "19131",
  50464: "19033",
  50465: "19189",
  50466: "19089",
  50468: "19067",
  50469: "19033",
  50470: "19197",
  50471: "19067",
  50472: "19131",
  50473: "19189",
  50475: "19069",
  50476: "19131",
  50478: "19189",
  50479: "19033",
  5048: "50027",
  50480: "19109",
  50482: "19033",
  50483: "19109",
  50484: "19081",
  50501: "19187",
  5051: "50017",
  50510: "19021",
  50511: "19109",
  50514: "19063",
  50515: "19147",
  50516: "19187",
  50517: "19109",
  50518: "19187",
  50519: "19091",
  50522: "19109",
  50523: "19187",
  50524: "19187",
  50525: "19197",
  50528: "19147",
  50529: "19091",
  5053: "50027",
  50530: "19187",
  50531: "19063",
  50532: "19187",
  50533: "19197",
  50535: "19161",
  50536: "19147",
  50538: "19025",
  50539: "19109",
  50540: "19151",
  50541: "19151",
  50542: "19197",
  50543: "19187",
  50544: "19187",
  50545: "19091",
  50546: "19151",
  50548: "19091",
  5055: "50027",
  50554: "19151",
  50556: "19109",
  50557: "19187",
  50558: "19091",
  50559: "19109",
  5056: "50027",
  50560: "19109",
  50561: "19025",
  50562: "19147",
  50563: "19025",
  50565: "19021",
  50566: "19187",
  50568: "19021",
  50569: "19187",
  50570: "19091",
  50571: "19151",
  50574: "19151",
  50575: "19025",
  50576: "19021",
  50577: "19091",
  50578: "19063",
  50579: "19025",
  5058: "50017",
  50581: "19151",
  50582: "19091",
  50583: "19161",
  50585: "19021",
  50586: "19025",
  50588: "19021",
  5059: "50027",
  50590: "19109",
  50591: "19091",
  50594: "19187",
  50595: "19079",
  50597: "19147",
  50598: "19109",
  50599: "19197",
  5060: "50017",
  50601: "19069",
  50602: "19023",
  50603: "19037",
  50604: "19023",
  50605: "19023",
  50606: "19065",
  50607: "19019",
  50609: "19075",
  5061: "50017",
  50611: "19023",
  50612: "19171",
  50613: "19013",
  50616: "19067",
  50619: "19023",
  5062: "50027",
  50621: "19075",
  50622: "19017",
  50624: "19075",
  50625: "19023",
  50626: "19013",
  50627: "19083",
  50628: "19089",
  50629: "19019",
  50630: "19037",
  50632: "19171",
  50633: "19069",
  50634: "19013",
  50635: "19171",
  50636: "19023",
  50638: "19075",
  50641: "19019",
  50642: "19075",
  50643: "19013",
  50644: "19019",
  50645: "19037",
  50647: "19017",
  50648: "19013",
  5065: "50027",
  50650: "19019",
  50651: "19013",
  50652: "19171",
  50653: "19067",
  50654: "19019",
  50655: "19065",
  50658: "19037",
  50659: "19037",
  50660: "19023",
  50662: "19065",
  50665: "19023",
  50666: "19017",
  50667: "19013",
  50668: "19017",
  50669: "19075",
  5067: "50027",
  50670: "19023",
  50671: "19065",
  50672: "19083",
  50673: "19075",
  50674: "19065",
  50675: "19171",
  50676: "19017",
  50677: "19017",
  5068: "50027",
  50680: "19075",
  50681: "19065",
  50682: "19019",
  5069: "50017",
  5070: "50017",
  50701: "19013",
  50702: "19013",
  50703: "19013",
  50707: "19013",
  5071: "50027",
  5072: "50017",
  5075: "50017",
  5076: "50017",
  5077: "50017",
  5079: "50017",
  50801: "19175",
  5081: "50017",
  50830: "19175",
  50833: "19173",
  50836: "19173",
  50840: "19173",
  50841: "19003",
  50843: "19029",
  50846: "19001",
  50848: "19173",
  50849: "19001",
  50851: "19173",
  50853: "19029",
  50854: "19159",
  5086: "50017",
  50861: "19175",
  50864: "19137",
  5089: "50027",
  5091: "50027",
  51001: "19149",
  51002: "19021",
  51003: "19167",
  51004: "19193",
  51005: "19035",
  51006: "19093",
  51007: "19193",
  51009: "19141",
  5101: "50025",
  51010: "19133",
  51012: "19035",
  51014: "19035",
  51016: "19193",
  51019: "19193",
  51020: "19093",
  51022: "19167",
  51023: "19167",
  51024: "19149",
  51025: "19093",
  51026: "19193",
  51027: "19167",
  51028: "19149",
  51029: "19035",
  51030: "19193",
  51031: "19149",
  51033: "19021",
  51034: "19133",
  51035: "19035",
  51036: "19167",
  51037: "19035",
  51038: "19149",
  51039: "19193",
  51040: "19133",
  51041: "19167",
  51044: "19193",
  51046: "19141",
  51047: "19041",
  51048: "19193",
  51049: "19035",
  51050: "19149",
  51053: "19161",
  51054: "19193",
  51055: "19193",
  51056: "19193",
  51058: "19141",
  51060: "19133",
  51061: "19035",
  51062: "19149",
  51063: "19133",
  51101: "19193",
  51103: "19193",
  51104: "19193",
  51105: "19193",
  51106: "19193",
  51108: "19193",
  51109: "19193",
  51201: "19141",
  51230: "19119",
  51231: "19141",
  51232: "19143",
  51234: "19167",
  51235: "19119",
  51237: "19119",
  51238: "19167",
  51239: "19167",
  51240: "19119",
  51241: "19119",
  51242: "19119",
  51243: "19119",
  51245: "19141",
  51246: "19119",
  51247: "19167",
  51248: "19141",
  51249: "19143",
  51250: "19167",
  51301: "19041",
  51331: "19059",
  51333: "19041",
  51334: "19063",
  51338: "19041",
  51340: "19041",
  51342: "19147",
  51343: "19041",
  51345: "19143",
  51346: "19141",
  51347: "19059",
  51350: "19143",
  51351: "19059",
  51354: "19143",
  51355: "19059",
  51357: "19041",
  51358: "19147",
  51360: "19059",
  51364: "19059",
  51365: "19063",
  51366: "19041",
  51401: "19027",
  5142: "50027",
  5143: "50027",
  51430: "19027",
  51431: "19093",
  51433: "19161",
  51436: "19027",
  51439: "19047",
  51440: "19027",
  51441: "19047",
  51442: "19047",
  51443: "19027",
  51444: "19027",
  51445: "19093",
  51446: "19165",
  51448: "19047",
  51449: "19025",
  51450: "19161",
  51453: "19025",
  51454: "19047",
  51455: "19027",
  51458: "19161",
  5146: "50025",
  51461: "19047",
  51462: "19073",
  51463: "19027",
  51465: "19047",
  51466: "19161",
  51467: "19047",
  5148: "50025",
  5149: "50027",
  5150: "50027",
  51501: "19155",
  51503: "19155",
  5151: "50027",
  51510: "19155",
  5152: "50003",
  51521: "19155",
  51523: "19133",
  51525: "19155",
  51526: "19155",
  51527: "19165",
  51528: "19047",
  51529: "19085",
  5153: "50027",
  51530: "19165",
  51531: "19165",
  51532: "19137",
  51533: "19129",
  51534: "19129",
  51535: "19029",
  51537: "19165",
  5154: "50025",
  51540: "19129",
  51541: "19129",
  51542: "19155",
  51543: "19009",
  51544: "19029",
  51545: "19085",
  51546: "19085",
  5155: "50025",
  51551: "19129",
  51552: "19029",
  51553: "19155",
  51554: "19129",
  51555: "19085",
  51556: "19085",
  51557: "19085",
  51558: "19133",
  51559: "19155",
  5156: "50027",
  51560: "19155",
  51561: "19129",
  51562: "19165",
  51563: "19085",
  51564: "19085",
  51565: "19165",
  51566: "19137",
  51570: "19165",
  51571: "19129",
  51572: "19133",
  51573: "19137",
  51575: "19155",
  51576: "19155",
  51577: "19155",
  51579: "19085",
  5158: "50025",
  51601: "19145",
  5161: "50027",
  51631: "19145",
  51632: "19145",
  51636: "19145",
  51637: "19145",
  51638: "19145",
  51639: "19071",
  51640: "19071",
  51645: "19071",
  51646: "19173",
  51648: "19071",
  51649: "19071",
  51650: "19071",
  51651: "19145",
  51652: "19071",
  51653: "19071",
  51654: "19071",
  52001: "19061",
  52002: "19061",
  52003: "19061",
  5201: "50003",
  52030: "19097",
  52031: "19097",
  52032: "19061",
  52033: "19105",
  52035: "19043",
  52037: "19045",
  52038: "19055",
  52039: "19061",
  52040: "19061",
  52041: "19055",
  52042: "19043",
  52043: "19043",
  52045: "19061",
  52046: "19061",
  52047: "19043",
  52048: "19043",
  52049: "19043",
  52050: "19055",
  52052: "19043",
  52053: "19061",
  52054: "19097",
  52056: "19061",
  52057: "19055",
  52060: "19097",
  52064: "19097",
  52065: "19055",
  52068: "19061",
  52069: "19097",
  52070: "19097",
  52072: "19043",
  52073: "19061",
  52074: "19097",
  52076: "19043",
  52077: "19043",
  52078: "19061",
  52079: "19097",
  52101: "19191",
  52132: "19191",
  52133: "19191",
  52134: "19089",
  52135: "19065",
  52136: "19089",
  52140: "19005",
  52141: "19065",
  52142: "19065",
  52144: "19191",
  52146: "19005",
  52147: "19065",
  52151: "19005",
  52154: "19037",
  52155: "19089",
  52156: "19043",
  52157: "19043",
  52158: "19043",
  52159: "19005",
  52160: "19005",
  52161: "19191",
  52162: "19005",
  52163: "19089",
  52164: "19065",
  52165: "19191",
  52166: "19065",
  52168: "19191",
  52169: "19065",
  52170: "19005",
  52171: "19065",
  52172: "19005",
  52175: "19065",
  52201: "19183",
  52202: "19113",
  52203: "19095",
  52205: "19105",
  52206: "19011",
  52207: "19097",
  52208: "19011",
  52209: "19011",
  52210: "19019",
  52211: "19157",
  52212: "19105",
  52213: "19113",
  52214: "19113",
  52215: "19171",
  52216: "19031",
  52217: "19171",
  52218: "19113",
  52220: "19095",
  52221: "19157",
  52222: "19157",
  52223: "19055",
  52224: "19171",
  52225: "19171",
  52227: "19113",
  52228: "19113",
  52229: "19011",
  52231: "19107",
  52232: "19157",
  52233: "19113",
  52235: "19103",
  52236: "19095",
  52237: "19055",
  52240: "19103",
  52241: "19103",
  52245: "19103",
  52246: "19103",
  52247: "19103",
  52248: "19107",
  52249: "19011",
  52251: "19095",
  52253: "19113",
  52254: "19045",
  52255: "19031",
  52257: "19011",
  52301: "19095",
  52302: "19113",
  52305: "19113",
  52306: "19031",
  52309: "19097",
  52310: "19105",
  52313: "19011",
  52314: "19113",
  52315: "19011",
  52316: "19095",
  52317: "19103",
  52318: "19011",
  52320: "19105",
  52321: "19105",
  52322: "19103",
  52323: "19105",
  52324: "19113",
  52325: "19095",
  52326: "19019",
  52327: "19183",
  52328: "19113",
  52329: "19019",
  52330: "19055",
  52332: "19011",
  52333: "19103",
  52334: "19095",
  52335: "19107",
  52336: "19113",
  52337: "19031",
  52338: "19103",
  52339: "19171",
  52340: "19103",
  52341: "19113",
  52342: "19171",
  52345: "19011",
  52346: "19011",
  52347: "19095",
  52349: "19011",
  52351: "19011",
  52352: "19113",
  52353: "19183",
  52354: "19011",
  52355: "19107",
  52356: "19183",
  52358: "19031",
  52359: "19183",
  52361: "19095",
  52362: "19105",
  52401: "19113",
  52402: "19113",
  52403: "19113",
  52404: "19113",
  52405: "19113",
  52411: "19113",
  5250: "50003",
  52501: "19179",
  5251: "50003",
  5252: "50003",
  5253: "50003",
  52530: "19179",
  52531: "19135",
  52533: "19101",
  52534: "19123",
  52535: "19177",
  52536: "19179",
  52537: "19051",
  52540: "19101",
  52542: "19177",
  52543: "19123",
  52544: "19007",
  52549: "19007",
  5255: "50003",
  52550: "19107",
  52551: "19177",
  52552: "19051",
  52553: "19123",
  52554: "19179",
  52555: "19007",
  52556: "19101",
  52560: "19051",
  52561: "19123",
  52563: "19107",
  52565: "19177",
  52567: "19101",
  52569: "19135",
  5257: "50003",
  52570: "19177",
  52571: "19007",
  52572: "19007",
  52574: "19007",
  52576: "19107",
  52577: "19123",
  52580: "19101",
  52581: "19007",
  52583: "19185",
  52584: "19051",
  52585: "19107",
  52586: "19123",
  52590: "19185",
  52591: "19107",
  52593: "19007",
  52594: "19007",
  52595: "19123",
  52601: "19057",
  5261: "50003",
  52619: "19111",
  5262: "50003",
  52620: "19177",
  52621: "19183",
  52623: "19057",
  52624: "19111",
  52625: "19111",
  52626: "19177",
  52627: "19111",
  52630: "19111",
  52632: "19111",
  52635: "19101",
  52637: "19057",
  52638: "19057",
  52639: "19111",
  52640: "19057",
  52641: "19087",
  52644: "19087",
  52645: "19087",
  52646: "19115",
  52649: "19087",
  52650: "19057",
  52651: "19177",
  52653: "19115",
  52654: "19087",
  52655: "19057",
  52656: "19111",
  52658: "19111",
  52659: "19087",
  52660: "19057",
  52720: "19139",
  52721: "19031",
  52722: "19163",
  52726: "19163",
  52727: "19045",
  52728: "19163",
  52729: "19045",
  52730: "19045",
  52731: "19045",
  52732: "19045",
  52737: "19115",
  52738: "19115",
  52739: "19115",
  52742: "19045",
  52745: "19163",
  52746: "19163",
  52747: "19031",
  52748: "19163",
  52749: "19139",
  52750: "19045",
  52751: "19045",
  52752: "19115",
  52753: "19163",
  52754: "19139",
  52755: "19103",
  52756: "19163",
  52757: "19045",
  52758: "19163",
  52760: "19139",
  52761: "19139",
  52765: "19163",
  52766: "19139",
  52767: "19163",
  52768: "19163",
  52769: "19139",
  52772: "19031",
  52773: "19163",
  52776: "19139",
  52777: "19045",
  52778: "19139",
  52802: "19163",
  52803: "19163",
  52804: "19163",
  52806: "19163",
  52807: "19163",
  53001: "55117",
  53002: "55131",
  53004: "55089",
  53005: "55133",
  53006: "55027",
  53007: "55133",
  5301: "50025",
  53010: "55039",
  53011: "55117",
  53012: "55089",
  53013: "55117",
  53014: "55015",
  53015: "55071",
  53017: "55131",
  53018: "55133",
  53019: "55039",
  53020: "55117",
  53021: "55089",
  53022: "55131",
  53023: "55117",
  53024: "55089",
  53027: "55131",
  53029: "55133",
  53032: "55027",
  53033: "55131",
  53034: "55027",
  53035: "55027",
  53036: "55055",
  53037: "55131",
  53038: "55055",
  53039: "55027",
  53040: "55131",
  53042: "55071",
  53044: "55117",
  53045: "55133",
  53046: "55133",
  53048: "55027",
  53049: "55039",
  53050: "55027",
  53051: "55133",
  53057: "55039",
  53058: "55133",
  53059: "55027",
  53061: "55015",
  53063: "55071",
  53065: "55039",
  53066: "55133",
  53069: "55133",
  53070: "55117",
  53072: "55133",
  53073: "55117",
  53074: "55089",
  53075: "55117",
  53076: "55131",
  53079: "55039",
  53080: "55089",
  53081: "55117",
  53083: "55117",
  53085: "55117",
  53086: "55131",
  53089: "55133",
  53090: "55131",
  53091: "55027",
  53092: "55089",
  53093: "55117",
  53094: "55055",
  53095: "55131",
  53097: "55089",
  53098: "55027",
  53103: "55133",
  53104: "55059",
  53105: "55101",
  53108: "55101",
  53110: "55079",
  53114: "55127",
  53115: "55127",
  53118: "55133",
  53119: "55133",
  53120: "55127",
  53121: "55127",
  53122: "55133",
  53125: "55127",
  53126: "55101",
  53128: "55127",
  53129: "55079",
  53130: "55079",
  53132: "55079",
  53137: "55055",
  53139: "55101",
  53140: "55059",
  53142: "55059",
  53143: "55059",
  53144: "55059",
  53146: "55133",
  53147: "55127",
  53149: "55133",
  53150: "55133",
  53151: "55133",
  53153: "55133",
  53154: "55079",
  53156: "55055",
  53158: "55059",
  53168: "55059",
  53170: "55059",
  53172: "55079",
  53177: "55101",
  53178: "55055",
  53179: "55059",
  53181: "55059",
  53182: "55101",
  53183: "55133",
  53184: "55127",
  53185: "55101",
  53186: "55133",
  53188: "55133",
  53189: "55133",
  53190: "55127",
  53191: "55127",
  53202: "55079",
  53203: "55079",
  53204: "55079",
  53205: "55079",
  53206: "55079",
  53207: "55079",
  53208: "55079",
  53209: "55079",
  53210: "55079",
  53211: "55079",
  53212: "55079",
  53213: "55079",
  53214: "55079",
  53215: "55079",
  53216: "55079",
  53217: "55079",
  53218: "55079",
  53219: "55079",
  53220: "55079",
  53221: "55079",
  53222: "55079",
  53223: "55079",
  53224: "55079",
  53225: "55079",
  53226: "55079",
  53227: "55079",
  53228: "55079",
  53233: "55079",
  53235: "55079",
  5340: "50003",
  53402: "55101",
  53403: "55101",
  53404: "55101",
  53405: "55101",
  53406: "55101",
  5341: "50025",
  5342: "50025",
  5343: "50025",
  5345: "50025",
  5346: "50025",
  5350: "50003",
  53502: "55045",
  53503: "55049",
  53504: "55065",
  53505: "55105",
  53508: "55025",
  5351: "50025",
  53510: "55065",
  53511: "55105",
  53515: "55025",
  53516: "55065",
  53517: "55025",
  53518: "55103",
  5352: "50003",
  53520: "55105",
  53521: "55025",
  53523: "55025",
  53525: "55105",
  53527: "55025",
  53528: "55025",
  53529: "55025",
  5353: "50025",
  53530: "55065",
  53531: "55025",
  53532: "55025",
  53533: "55049",
  53534: "55105",
  53536: "55105",
  53537: "55105",
  53538: "55055",
  5354: "50025",
  53541: "55065",
  53543: "55049",
  53544: "55049",
  53545: "55105",
  53546: "55105",
  53548: "55105",
  53549: "55055",
  5355: "50025",
  53551: "55055",
  53553: "55049",
  53554: "55043",
  53555: "55021",
  53556: "55103",
  53558: "55025",
  53559: "55025",
  5356: "50025",
  53560: "55025",
  53561: "55111",
  53562: "55025",
  53563: "55105",
  53565: "55049",
  53566: "55045",
  53569: "55043",
  53570: "55045",
  53572: "55025",
  53573: "55103",
  53574: "55045",
  53575: "55025",
  53576: "55105",
  53577: "55111",
  53578: "55111",
  53579: "55027",
  5358: "50025",
  53581: "55103",
  53583: "55025",
  53585: "55127",
  53586: "55065",
  53587: "55065",
  53588: "55111",
  53589: "55025",
  5359: "50025",
  53590: "55025",
  53593: "55025",
  53594: "55027",
  53597: "55025",
  53598: "55025",
  5360: "50025",
  5361: "50025",
  5362: "50025",
  5363: "50025",
  53703: "55025",
  53704: "55025",
  53705: "55025",
  53711: "55025",
  53713: "55025",
  53714: "55025",
  53715: "55025",
  53716: "55025",
  53717: "55025",
  53718: "55025",
  53719: "55025",
  53726: "55025",
  53801: "55043",
  53803: "55065",
  53804: "55043",
  53805: "55043",
  53806: "55043",
  53807: "55043",
  53808: "55043",
  53809: "55043",
  53811: "55043",
  53813: "55043",
  53818: "55043",
  53820: "55043",
  53821: "55023",
  53826: "55023",
  53901: "55021",
  53910: "55001",
  53911: "55021",
  53913: "55111",
  53916: "55027",
  53919: "55039",
  53920: "55077",
  53923: "55021",
  53924: "55103",
  53925: "55021",
  53926: "55047",
  53929: "55057",
  53930: "55077",
  53932: "55021",
  53933: "55027",
  53934: "55001",
  53936: "55001",
  53937: "55111",
  53941: "55111",
  53943: "55111",
  53944: "55057",
  53946: "55047",
  53948: "55057",
  53949: "55077",
  53950: "55057",
  53951: "55111",
  53952: "55001",
  53954: "55021",
  53955: "55021",
  53956: "55021",
  53959: "55111",
  53960: "55021",
  53961: "55111",
  53963: "55039",
  53964: "55077",
  53965: "55021",
  53968: "55057",
  54001: "55095",
  54002: "55109",
  54003: "55093",
  54004: "55095",
  54005: "55095",
  54006: "55095",
  54007: "55109",
  54009: "55095",
  5401: "50007",
  54011: "55093",
  54013: "55109",
  54014: "55093",
  54015: "55109",
  54016: "55109",
  54017: "55109",
  54020: "55095",
  54021: "55093",
  54022: "55093",
  54023: "55109",
  54024: "55095",
  54025: "55109",
  54026: "55095",
  54027: "55109",
  54028: "55109",
  5403: "50007",
  5404: "50007",
  5408: "50007",
  54082: "55109",
  54101: "55083",
  54102: "55075",
  54103: "55041",
  54104: "55075",
  54106: "55087",
  54107: "55115",
  54110: "55015",
  54111: "55115",
  54112: "55075",
  54113: "55087",
  54114: "55075",
  54115: "55009",
  54119: "55075",
  54120: "55037",
  54121: "55037",
  54124: "55083",
  54125: "55075",
  54126: "55009",
  54128: "55115",
  54130: "55087",
  54135: "55078",
  54136: "55087",
  54137: "55115",
  54138: "55083",
  54139: "55083",
  54140: "55087",
  54141: "55083",
  54143: "55075",
  54149: "55083",
  54151: "55075",
  54153: "55083",
  54154: "55083",
  54155: "55009",
  54156: "55075",
  54157: "55075",
  54159: "55075",
  54161: "55083",
  54162: "55009",
  54165: "55087",
  54166: "55115",
  54170: "55087",
  54171: "55083",
  54173: "55009",
  54174: "55083",
  54175: "55083",
  54177: "55075",
  54180: "55009",
  54201: "55061",
  54202: "55029",
  54204: "55029",
  54205: "55061",
  54208: "55009",
  54209: "55029",
  54210: "55029",
  54211: "55029",
  54212: "55029",
  54213: "55029",
  54216: "55061",
  54217: "55061",
  54220: "55071",
  54227: "55071",
  54228: "55071",
  54229: "55009",
  54230: "55071",
  54234: "55029",
  54235: "55029",
  54241: "55071",
  54245: "55071",
  54246: "55029",
  54247: "55071",
  54301: "55009",
  54302: "55009",
  54303: "55009",
  54304: "55009",
  54311: "55009",
  54313: "55009",
  5440: "50013",
  54401: "55073",
  54403: "55073",
  54405: "55019",
  54406: "55097",
  54407: "55097",
  54408: "55073",
  54409: "55067",
  5441: "50011",
  54410: "55141",
  54411: "55073",
  54412: "55141",
  54414: "55115",
  54416: "55115",
  5442: "50015",
  54420: "55019",
  54421: "55073",
  54422: "55119",
  54423: "55097",
  54424: "55067",
  54425: "55019",
  54426: "55073",
  54427: "55073",
  54428: "55067",
  5443: "50001",
  54433: "55119",
  54435: "55069",
  54436: "55019",
  54437: "55019",
  5444: "50015",
  54440: "55073",
  54441: "55141",
  54442: "55069",
  54443: "55097",
  54446: "55019",
  54448: "55073",
  54449: "55141",
  5445: "50007",
  54451: "55119",
  54452: "55069",
  54455: "55073",
  54456: "55019",
  54457: "55141",
  54459: "55099",
  5446: "50007",
  54460: "55019",
  54463: "55085",
  54465: "55041",
  54466: "55141",
  54467: "55097",
  54469: "55141",
  54470: "55119",
  54471: "55073",
  54473: "55097",
  54474: "55073",
  54475: "55141",
  54476: "55073",
  54479: "55019",
  5448: "50011",
  54480: "55119",
  54481: "55097",
  54482: "55097",
  54484: "55073",
  54486: "55115",
  54487: "55069",
  54488: "55073",
  54489: "55141",
  54490: "55119",
  54491: "55067",
  54493: "55019",
  54494: "55141",
  54495: "55141",
  54498: "55019",
  54499: "55073",
  5450: "50011",
  54501: "55085",
  54511: "55041",
  54512: "55125",
  54514: "55003",
  54517: "55113",
  54519: "55125",
  5452: "50007",
  54520: "55041",
  54521: "55125",
  54524: "55099",
  54527: "55003",
  54529: "55085",
  54530: "55107",
  54531: "55085",
  54534: "55051",
  54538: "55125",
  54539: "55085",
  5454: "50011",
  54540: "55125",
  54541: "55041",
  54542: "55041",
  54545: "55125",
  54546: "55003",
  54547: "55051",
  54548: "55085",
  5455: "50011",
  54550: "55051",
  54552: "55099",
  54554: "55125",
  54555: "55099",
  54556: "55099",
  54557: "55125",
  54558: "55125",
  5456: "50001",
  54560: "55125",
  54562: "55085",
  54563: "55107",
  54564: "55085",
  54566: "55041",
  54568: "55125",
  5457: "50011",
  5458: "50013",
  5459: "50011",
  54601: "55063",
  54603: "55063",
  5461: "50007",
  54610: "55011",
  54611: "55053",
  54612: "55121",
  54613: "55001",
  54614: "55063",
  54615: "55053",
  54616: "55121",
  54618: "55081",
  54619: "55081",
  5462: "50007",
  54621: "55123",
  54622: "55011",
  54623: "55063",
  54624: "55123",
  54625: "55121",
  54626: "55023",
  54627: "55121",
  54628: "55023",
  54629: "55011",
  5463: "50013",
  54630: "55121",
  54631: "55023",
  54632: "55123",
  54634: "55123",
  54635: "55053",
  54636: "55063",
  54638: "55081",
  54639: "55123",
  5464: "50015",
  54642: "55053",
  54644: "55063",
  54646: "55057",
  54648: "55081",
  5465: "50007",
  54650: "55063",
  54651: "55123",
  54652: "55123",
  54653: "55081",
  54655: "55023",
  54656: "55081",
  54658: "55123",
  54659: "55053",
  54660: "55081",
  54661: "55121",
  54664: "55123",
  54665: "55123",
  54666: "55053",
  54667: "55123",
  54669: "55063",
  54670: "55081",
  5468: "50007",
  54701: "55035",
  54703: "55035",
  5471: "50011",
  5472: "50001",
  54720: "55035",
  54721: "55091",
  54722: "55035",
  54723: "55093",
  54724: "55017",
  54725: "55033",
  54726: "55017",
  54727: "55017",
  54728: "55005",
  54729: "55017",
  5473: "50001",
  54730: "55033",
  54732: "55017",
  54733: "55005",
  54734: "55033",
  54736: "55091",
  54737: "55033",
  54738: "55035",
  54739: "55033",
  5474: "50013",
  54740: "55093",
  54741: "55035",
  54742: "55035",
  54745: "55017",
  54746: "55019",
  54747: "55121",
  54748: "55017",
  54749: "55033",
  54750: "55093",
  54751: "55033",
  54754: "55053",
  54755: "55011",
  54756: "55011",
  54757: "55017",
  54758: "55121",
  54759: "55091",
  5476: "50011",
  54761: "55093",
  54762: "55005",
  54763: "55033",
  54766: "55107",
  54767: "55093",
  54768: "55019",
  54769: "55091",
  5477: "50007",
  54770: "55121",
  54771: "55019",
  54772: "55033",
  54773: "55121",
  5478: "50011",
  54801: "55129",
  54805: "55005",
  54806: "55003",
  54810: "55095",
  54812: "55005",
  54813: "55129",
  54814: "55007",
  54817: "55129",
  54819: "55107",
  5482: "50007",
  54820: "55031",
  54821: "55007",
  54822: "55005",
  54824: "55095",
  54826: "55095",
  54827: "55007",
  54829: "55005",
  5483: "50011",
  54830: "55013",
  54832: "55007",
  54835: "55113",
  54836: "55031",
  54837: "55095",
  54838: "55031",
  54839: "55007",
  54840: "55013",
  54843: "55113",
  54844: "55007",
  54846: "55003",
  54847: "55007",
  54848: "55107",
  54849: "55031",
  54850: "55003",
  54853: "55095",
  54854: "55031",
  54855: "55003",
  54856: "55007",
  54858: "55095",
  54859: "55129",
  5486: "50013",
  54864: "55031",
  54865: "55007",
  54868: "55005",
  5487: "50001",
  54870: "55129",
  54871: "55129",
  54872: "55013",
  54873: "55031",
  54874: "55031",
  54875: "55129",
  54876: "55129",
  5488: "50011",
  54880: "55031",
  54888: "55129",
  54889: "55095",
  5489: "50007",
  54891: "55007",
  54893: "55013",
  54895: "55107",
  54896: "55113",
  54901: "55139",
  54902: "55139",
  54904: "55139",
  54909: "55097",
  5491: "50001",
  54911: "55087",
  54913: "55087",
  54914: "55087",
  54915: "55087",
  5492: "50015",
  54921: "55001",
  54922: "55135",
  54923: "55047",
  54927: "55139",
  54929: "55135",
  54930: "55137",
  54931: "55087",
  54932: "55039",
  54935: "55039",
  54937: "55039",
  5494: "50007",
  54940: "55135",
  54941: "55047",
  54942: "55087",
  54943: "55001",
  54944: "55087",
  54945: "55135",
  54947: "55139",
  54949: "55135",
  5495: "50007",
  54950: "55135",
  54952: "55139",
  54956: "55139",
  54960: "55077",
  54961: "55135",
  54962: "55135",
  54963: "55139",
  54964: "55039",
  54965: "55137",
  54966: "55137",
  54968: "55047",
  54970: "55137",
  54971: "55039",
  54974: "55039",
  54977: "55135",
  54979: "55039",
  54981: "55135",
  54982: "55137",
  54983: "55135",
  54984: "55137",
  54986: "55139",
  55001: "27163",
  55003: "27163",
  55005: "27003",
  55006: "27065",
  55007: "27065",
  55008: "27059",
  55009: "27049",
  55011: "27003",
  55012: "27025",
  55013: "27025",
  55014: "27003",
  55016: "27163",
  55017: "27059",
  55018: "27049",
  55019: "27131",
  55020: "27139",
  55021: "27131",
  55024: "27037",
  55025: "27163",
  55026: "27049",
  55027: "27049",
  55030: "27115",
  55031: "27037",
  55032: "27025",
  55033: "27037",
  55037: "27115",
  55038: "27163",
  55040: "27059",
  55041: "27157",
  55042: "27163",
  55043: "27163",
  55044: "27037",
  55045: "27025",
  55046: "27131",
  55047: "27163",
  55049: "27147",
  55051: "27065",
  55052: "27131",
  55053: "27131",
  55054: "27139",
  55055: "27163",
  55056: "27025",
  55057: "27131",
  55060: "27147",
  55063: "27115",
  55065: "27037",
  55066: "27049",
  55068: "27037",
  55069: "27025",
  55070: "27003",
  55071: "27163",
  55072: "27115",
  55073: "27163",
  55074: "27025",
  55075: "27037",
  55076: "27037",
  55077: "27037",
  55079: "27025",
  55080: "27059",
  55082: "27163",
  55084: "27025",
  55085: "27037",
  55087: "27131",
  55088: "27131",
  55089: "27049",
  55090: "27163",
  55092: "27003",
  55101: "27123",
  55102: "27123",
  55103: "27123",
  55104: "27123",
  55105: "27123",
  55106: "27123",
  55107: "27123",
  55108: "27123",
  55109: "27123",
  55110: "27123",
  55112: "27123",
  55113: "27123",
  55114: "27123",
  55115: "27163",
  55116: "27123",
  55117: "27123",
  55118: "27037",
  55119: "27123",
  55120: "27037",
  55121: "27037",
  55122: "27037",
  55123: "27037",
  55124: "27037",
  55125: "27163",
  55126: "27123",
  55127: "27123",
  55128: "27163",
  55129: "27163",
  55130: "27123",
  55301: "27171",
  55302: "27171",
  55303: "27003",
  55304: "27003",
  55305: "27053",
  55306: "27037",
  55307: "27143",
  55308: "27141",
  55309: "27141",
  55310: "27129",
  55311: "27053",
  55312: "27085",
  55313: "27171",
  55314: "27129",
  55315: "27019",
  55316: "27053",
  55317: "27019",
  55318: "27019",
  55319: "27141",
  55320: "27171",
  55321: "27171",
  55322: "27019",
  55324: "27093",
  55325: "27093",
  55327: "27053",
  55328: "27171",
  55329: "27093",
  55330: "27141",
  55331: "27053",
  55332: "27129",
  55333: "27129",
  55334: "27143",
  55335: "27143",
  55336: "27085",
  55337: "27037",
  55338: "27143",
  55339: "27019",
  55340: "27053",
  55341: "27171",
  55342: "27129",
  55343: "27053",
  55344: "27053",
  55345: "27053",
  55346: "27053",
  55347: "27053",
  55349: "27171",
  55350: "27085",
  55352: "27139",
  55353: "27145",
  55354: "27085",
  55355: "27093",
  55356: "27053",
  55357: "27053",
  55358: "27171",
  55359: "27053",
  55360: "27019",
  55362: "27171",
  55363: "27171",
  55364: "27053",
  55367: "27019",
  55368: "27019",
  55369: "27053",
  55370: "27085",
  55371: "27095",
  55372: "27139",
  55373: "27053",
  55374: "27053",
  55375: "27053",
  55376: "27171",
  55378: "27139",
  55379: "27139",
  55381: "27085",
  55382: "27145",
  55384: "27053",
  55385: "27085",
  55386: "27019",
  55387: "27019",
  55388: "27019",
  55389: "27093",
  55390: "27171",
  55391: "27053",
  55395: "27085",
  55396: "27143",
  55397: "27019",
  55398: "27141",
  55401: "27053",
  55403: "27053",
  55404: "27053",
  55405: "27053",
  55406: "27053",
  55407: "27053",
  55408: "27053",
  55409: "27053",
  55410: "27053",
  55411: "27053",
  55412: "27053",
  55413: "27053",
  55414: "27053",
  55415: "27053",
  55416: "27053",
  55417: "27053",
  55418: "27053",
  55419: "27053",
  55420: "27053",
  55421: "27003",
  55422: "27053",
  55423: "27053",
  55424: "27053",
  55425: "27053",
  55426: "27053",
  55427: "27053",
  55428: "27053",
  55429: "27053",
  55430: "27053",
  55431: "27053",
  55432: "27003",
  55433: "27003",
  55434: "27003",
  55435: "27053",
  55436: "27053",
  55437: "27053",
  55438: "27053",
  55439: "27053",
  55441: "27053",
  55442: "27053",
  55443: "27053",
  55444: "27053",
  55445: "27053",
  55446: "27053",
  55447: "27053",
  55448: "27003",
  55449: "27003",
  55454: "27053",
  55601: "27075",
  55602: "27075",
  55603: "27075",
  55604: "27031",
  55606: "27031",
  55607: "27075",
  55609: "27075",
  55612: "27031",
  55614: "27075",
  55615: "27031",
  55616: "27075",
  55702: "27137",
  55703: "27137",
  55704: "27115",
  55705: "27137",
  55706: "27137",
  55707: "27017",
  55708: "27137",
  55709: "27061",
  55710: "27137",
  55711: "27137",
  55712: "27115",
  55713: "27137",
  55717: "27137",
  55718: "27017",
  55719: "27137",
  55720: "27017",
  55721: "27061",
  55722: "27061",
  55723: "27137",
  55724: "27137",
  55725: "27137",
  55726: "27017",
  55731: "27137",
  55732: "27137",
  55733: "27017",
  55734: "27137",
  55735: "27115",
  55736: "27137",
  55738: "27137",
  55741: "27137",
  55744: "27061",
  55746: "27137",
  55748: "27061",
  55749: "27017",
  55750: "27137",
  55751: "27137",
  55752: "27001",
  55756: "27115",
  55757: "27017",
  55758: "27137",
  55760: "27001",
  55763: "27137",
  55764: "27061",
  55765: "27137",
  55767: "27017",
  55768: "27137",
  55769: "27061",
  55771: "27137",
  55775: "27061",
  55779: "27137",
  55781: "27137",
  55782: "27137",
  55783: "27115",
  55787: "27001",
  55790: "27137",
  55792: "27137",
  55793: "27061",
  55795: "27115",
  55797: "27017",
  55798: "27017",
  55802: "27137",
  55803: "27137",
  55804: "27137",
  55805: "27137",
  55806: "27137",
  55807: "27137",
  55808: "27137",
  55810: "27137",
  55811: "27137",
  55812: "27137",
  55901: "27109",
  55902: "27109",
  55904: "27109",
  55906: "27109",
  55909: "27099",
  55910: "27169",
  55912: "27099",
  55917: "27147",
  55918: "27099",
  55919: "27055",
  55920: "27109",
  55921: "27055",
  55922: "27045",
  55923: "27109",
  55924: "27039",
  55925: "27169",
  55926: "27099",
  55927: "27039",
  55929: "27109",
  55932: "27157",
  55934: "27109",
  55935: "27045",
  55936: "27099",
  55939: "27045",
  55940: "27039",
  55941: "27055",
  55943: "27055",
  55944: "27039",
  55945: "27157",
  55946: "27049",
  55947: "27055",
  55949: "27045",
  55951: "27099",
  55952: "27169",
  55953: "27099",
  55954: "27045",
  55955: "27039",
  55956: "27157",
  55957: "27157",
  55959: "27169",
  55960: "27109",
  55961: "27099",
  55962: "27045",
  55963: "27049",
  55964: "27157",
  55965: "27045",
  55967: "27099",
  55969: "27169",
  55970: "27099",
  55971: "27045",
  55972: "27169",
  55973: "27099",
  55974: "27055",
  55975: "27045",
  55976: "27109",
  55979: "27169",
  55981: "27157",
  55982: "27099",
  55983: "27049",
  55985: "27039",
  55987: "27169",
  55990: "27045",
  55991: "27157",
  55992: "27049",
  56001: "27013",
  56003: "27103",
  56007: "27047",
  56009: "27047",
  56010: "27013",
  56011: "27139",
  56013: "27043",
  56014: "27043",
  56016: "27047",
  56017: "27079",
  56019: "27015",
  5602: "50023",
  56021: "27103",
  56023: "27043",
  56024: "27013",
  56025: "27043",
  56026: "27147",
  56027: "27043",
  56028: "27079",
  56029: "27047",
  56031: "27091",
  56032: "27047",
  56034: "27013",
  56035: "27047",
  56036: "27047",
  56037: "27013",
  56039: "27091",
  56041: "27015",
  56042: "27047",
  56043: "27047",
  56044: "27143",
  56045: "27047",
  56048: "27161",
  56050: "27079",
  56051: "27043",
  56052: "27131",
  56054: "27103",
  56055: "27013",
  56057: "27079",
  56058: "27079",
  56060: "27165",
  56062: "27165",
  56063: "27079",
  56065: "27013",
  56068: "27043",
  56069: "27079",
  56071: "27139",
  56072: "27161",
  56073: "27015",
  56074: "27103",
  56078: "27161",
  56080: "27013",
  56081: "27165",
  56082: "27103",
  56083: "27033",
  56085: "27015",
  56087: "27015",
  56088: "27091",
  56090: "27013",
  56091: "27161",
  56093: "27161",
  56096: "27079",
  56097: "27043",
  56098: "27043",
  56101: "27033",
  56110: "27105",
  56115: "27083",
  56119: "27105",
  56120: "27165",
  56121: "27091",
  56127: "27091",
  56128: "27117",
  56131: "27101",
  56132: "27083",
  56136: "27081",
  56137: "27063",
  56138: "27133",
  56139: "27117",
  56142: "27081",
  56143: "27063",
  56144: "27133",
  56149: "27081",
  56150: "27063",
  56151: "27101",
  56152: "27127",
  56156: "27133",
  56157: "27083",
  56159: "27033",
  56160: "27091",
  56162: "27091",
  56164: "27117",
  56167: "27063",
  56170: "27117",
  56171: "27091",
  56172: "27101",
  56175: "27083",
  56176: "27091",
  56178: "27081",
  56180: "27127",
  56181: "27091",
  56183: "27033",
  56186: "27117",
  56187: "27105",
  56201: "27067",
  56208: "27151",
  56209: "27067",
  56211: "27011",
  56215: "27151",
  56219: "27155",
  56220: "27173",
  56222: "27023",
  56223: "27173",
  56225: "27011",
  56228: "27093",
  56229: "27083",
  56230: "27129",
  56232: "27073",
  56236: "27155",
  56237: "27173",
  56240: "27011",
  56241: "27173",
  56243: "27093",
  56244: "27149",
  56245: "27173",
  56248: "27051",
  56251: "27067",
  56252: "27151",
  56253: "27067",
  56256: "27073",
  56258: "27083",
  56260: "27023",
  56264: "27083",
  56265: "27023",
  56266: "27127",
  56267: "27149",
  56270: "27129",
  56271: "27151",
  56273: "27067",
  56276: "27073",
  56277: "27129",
  56278: "27011",
  56279: "27067",
  56280: "27081",
  56281: "27067",
  56282: "27067",
  56283: "27127",
  56284: "27129",
  56285: "27129",
  56288: "27067",
  56289: "27067",
  56291: "27083",
  56292: "27127",
  56296: "27155",
  56297: "27173",
  56301: "27145",
  56303: "27145",
  56304: "27141",
  56307: "27145",
  56308: "27041",
  56309: "27051",
  56310: "27145",
  56311: "27051",
  56312: "27145",
  56314: "27097",
  56315: "27041",
  56316: "27067",
  56318: "27153",
  56319: "27041",
  56320: "27145",
  56323: "27121",
  56324: "27111",
  56326: "27041",
  56327: "27121",
  56329: "27009",
  56330: "27095",
  56331: "27145",
  56332: "27041",
  56334: "27121",
  56335: "27145",
  56336: "27153",
  56338: "27097",
  56339: "27051",
  56340: "27145",
  56342: "27001",
  56343: "27041",
  56345: "27097",
  56347: "27153",
  56349: "27121",
  56350: "27001",
  56352: "27145",
  56353: "27095",
  56354: "27041",
  56356: "27145",
  56357: "27009",
  56358: "27065",
  56359: "27095",
  56360: "27153",
  56361: "27111",
  56362: "27145",
  56364: "27097",
  56367: "27009",
  56368: "27145",
  56369: "27145",
  56373: "27097",
  56374: "27145",
  56375: "27145",
  56376: "27145",
  56377: "27145",
  56378: "27145",
  56379: "27009",
  56381: "27121",
  56382: "27097",
  56384: "27097",
  56385: "27121",
  56386: "27095",
  56387: "27145",
  56401: "27035",
  5641: "50023",
  56425: "27035",
  56431: "27001",
  56433: "27057",
  56435: "27021",
  56437: "27153",
  56438: "27153",
  56440: "27153",
  56441: "27035",
  56442: "27035",
  56443: "27097",
  56444: "27035",
  56446: "27153",
  56447: "27035",
  56448: "27035",
  56449: "27035",
  56450: "27035",
  56452: "27021",
  56453: "27153",
  56455: "27035",
  56458: "27057",
  56461: "27057",
  56464: "27159",
  56465: "27035",
  56466: "27021",
  56467: "27057",
  56468: "27035",
  56469: "27001",
  5647: "50023",
  56470: "27057",
  56472: "27035",
  56473: "27021",
  56474: "27035",
  56475: "27097",
  56477: "27159",
  56479: "27159",
  5648: "50023",
  56481: "27159",
  56482: "27159",
  56484: "27021",
  5649: "50023",
  5650: "50023",
  56501: "27005",
  5651: "50023",
  56510: "27107",
  56511: "27005",
  56514: "27027",
  56515: "27111",
  56517: "27119",
  56518: "27111",
  5652: "50015",
  56520: "27167",
  56521: "27005",
  56523: "27119",
  56524: "27111",
  56527: "27111",
  56528: "27111",
  56529: "27027",
  5653: "50015",
  56531: "27051",
  56533: "27111",
  56534: "27111",
  56535: "27119",
  56537: "27111",
  5654: "50023",
  56540: "27119",
  56542: "27119",
  56544: "27005",
  56547: "27027",
  56548: "27107",
  56549: "27027",
  5655: "50015",
  56551: "27111",
  56554: "27005",
  56556: "27119",
  56557: "27087",
  5656: "50015",
  56560: "27027",
  56567: "27111",
  56569: "27005",
  56570: "27005",
  56571: "27111",
  56572: "27111",
  56573: "27111",
  56575: "27005",
  56576: "27111",
  56578: "27005",
  56579: "27167",
  5658: "50023",
  56580: "27027",
  56584: "27107",
  56585: "27027",
  56586: "27111",
  56587: "27111",
  56588: "27111",
  56589: "27087",
  56590: "27051",
  56592: "27119",
  5660: "50023",
  56601: "27007",
  5661: "50015",
  56621: "27029",
  56623: "27077",
  56628: "27061",
  5663: "50023",
  56630: "27007",
  56633: "27021",
  56634: "27029",
  56636: "27061",
  56639: "27061",
  56641: "27021",
  56644: "27029",
  56646: "27119",
  56647: "27007",
  56649: "27071",
  56650: "27007",
  56651: "27087",
  56653: "27071",
  56655: "27021",
  56657: "27061",
  56661: "27061",
  56662: "27021",
  56667: "27007",
  56669: "27071",
  5667: "50023",
  56672: "27021",
  56673: "27135",
  56676: "27007",
  56678: "27007",
  56683: "27007",
  56684: "27119",
  56685: "27007",
  56686: "27077",
  5669: "50023",
  56701: "27113",
  56714: "27135",
  56716: "27119",
  5672: "50015",
  56721: "27119",
  56722: "27119",
  56723: "27119",
  56726: "27135",
  56727: "27007",
  56728: "27069",
  5673: "50023",
  56732: "27069",
  56736: "27119",
  5674: "50023",
  56742: "27125",
  56744: "27089",
  56748: "27125",
  5675: "50017",
  56750: "27125",
  56751: "27135",
  56756: "27135",
  5676: "50023",
  56761: "27135",
  56762: "27089",
  56763: "27135",
  5677: "50023",
  5679: "50017",
  5680: "50015",
  5681: "50023",
  5682: "50023",
  57001: "46127",
  57002: "46011",
  57003: "46099",
  57004: "46083",
  57005: "46099",
  57006: "46011",
  5701: "50021",
  57010: "46027",
  57012: "46087",
  57013: "46083",
  57014: "46125",
  57015: "46125",
  57016: "46079",
  57017: "46101",
  57018: "46099",
  57020: "46099",
  57021: "46125",
  57022: "46099",
  57024: "46101",
  57025: "46127",
  57026: "46011",
  57028: "46101",
  57029: "46067",
  57030: "46099",
  57032: "46083",
  57033: "46099",
  57035: "46099",
  57036: "46125",
  57037: "46135",
  57038: "46127",
  57039: "46083",
  57042: "46079",
  57043: "46125",
  57047: "46087",
  57048: "46087",
  57049: "46127",
  57053: "46125",
  57055: "46099",
  57058: "46087",
  57062: "46009",
  57063: "46009",
  57064: "46083",
  57065: "46101",
  57068: "46099",
  57069: "46027",
  57070: "46125",
  57071: "46011",
  57072: "46135",
  57073: "46027",
  57075: "46079",
  57077: "46083",
  57078: "46135",
  57103: "46099",
  57104: "46099",
  57105: "46099",
  57106: "46099",
  57107: "46099",
  57108: "46083",
  57110: "46099",
  57201: "46029",
  57212: "46077",
  57216: "46051",
  57220: "46011",
  57221: "46057",
  57223: "46057",
  57225: "46025",
  57226: "46039",
  57231: "46077",
  57232: "46091",
  57234: "46057",
  57235: "46029",
  57237: "46039",
  57241: "46057",
  57242: "46057",
  57243: "46029",
  57247: "46091",
  57248: "46057",
  57252: "46051",
  57262: "46109",
  57263: "46029",
  57270: "46091",
  57272: "46029",
  57273: "46037",
  57274: "46037",
  57276: "46011",
  5730: "50021",
  57301: "46035",
  57312: "46005",
  57319: "46087",
  5732: "50021",
  57325: "46015",
  5733: "50021",
  57334: "46035",
  5734: "50001",
  5735: "50021",
  57350: "46005",
  5736: "50021",
  57362: "46059",
  57363: "46035",
  57366: "46067",
  57369: "46023",
  5737: "50021",
  57373: "46059",
  57374: "46087",
  5738: "50021",
  57382: "46073",
  57385: "46111",
  5739: "50021",
  57401: "46013",
  5742: "50021",
  57424: "46115",
  5743: "50021",
  57430: "46091",
  57433: "46013",
  57434: "46115",
  57436: "46115",
  5744: "50021",
  57440: "46115",
  57442: "46107",
  57445: "46013",
  57450: "46107",
  57451: "46045",
  57452: "46129",
  57454: "46091",
  57461: "46115",
  57465: "46115",
  57469: "46115",
  5747: "50001",
  57472: "46129",
  57476: "46115",
  57479: "46013",
  5748: "50001",
  57501: "46065",
  5751: "50021",
  57522: "46065",
  5753: "50001",
  57532: "46117",
  57536: "46065",
  57551: "46007",
  57564: "46119",
  5757: "50021",
  5758: "50021",
  57580: "46123",
  5759: "50021",
  5760: "50001",
  57601: "46129",
  5761: "50021",
  5762: "50021",
  57620: "46105",
  57626: "46093",
  5763: "50021",
  57631: "46129",
  57632: "46021",
  57638: "46105",
  5764: "50021",
  57642: "46031",
  57646: "46021",
  57648: "46021",
  5765: "50021",
  5766: "50001",
  5767: "50027",
  5769: "50001",
  5770: "50001",
  57701: "46103",
  57702: "46103",
  57703: "46103",
  57717: "46019",
  57718: "46093",
  57719: "46093",
  5772: "50027",
  57720: "46063",
  5773: "50021",
  57730: "46033",
  57732: "46081",
  57735: "46047",
  5774: "50021",
  57744: "46033",
  57745: "46103",
  57747: "46047",
  5775: "50021",
  57751: "46103",
  57754: "46081",
  57759: "46081",
  5776: "50003",
  57761: "46103",
  57769: "46093",
  5777: "50021",
  57775: "46103",
  5778: "50001",
  57783: "46081",
  57785: "46093",
  57790: "46103",
  57793: "46081",
  58005: "38017",
  58006: "38017",
  58011: "38017",
  58012: "38017",
  58015: "38077",
  58018: "38077",
  58021: "38017",
  58027: "38017",
  58030: "38077",
  58031: "38003",
  58036: "38017",
  58038: "38097",
  58041: "38077",
  58042: "38017",
  58045: "38097",
  58047: "38017",
  58048: "38017",
  58049: "38003",
  58051: "38017",
  58052: "38017",
  58053: "38077",
  58054: "38073",
  58059: "38017",
  58060: "38081",
  58061: "38077",
  58063: "38003",
  58064: "38017",
  58071: "38003",
  58072: "38003",
  58075: "38077",
  58077: "38077",
  58078: "38017",
  58079: "38017",
  58081: "38077",
  58102: "38017",
  58103: "38017",
  58104: "38017",
  5819: "50005",
  5820: "50019",
  58201: "38035",
  58203: "38035",
  5821: "50005",
  58218: "38097",
  5822: "50019",
  58220: "38067",
  58225: "38067",
  58228: "38035",
  58237: "38099",
  5824: "50009",
  58240: "38097",
  58249: "38019",
  58251: "38035",
  58256: "38035",
  58257: "38097",
  5826: "50019",
  58267: "38035",
  5827: "50019",
  58270: "38099",
  58274: "38097",
  58275: "38035",
  58278: "38035",
  5828: "50005",
  5829: "50019",
  5830: "50019",
  58301: "38071",
  58318: "38009",
  5832: "50005",
  58324: "38095",
  58327: "38071",
  58341: "38103",
  58346: "38005",
  58348: "38005",
  58356: "38027",
  5836: "50005",
  5837: "50009",
  58374: "38027",
  58381: "38005",
  58384: "38009",
  5839: "50019",
  58401: "38093",
  5841: "50019",
  58416: "38039",
  5842: "50019",
  58421: "38031",
  58425: "38039",
  58429: "38003",
  5843: "50005",
  58436: "38021",
  58448: "38039",
  5845: "50019",
  5846: "50009",
  58461: "38003",
  58463: "38083",
  5847: "50019",
  58472: "38093",
  58474: "38021",
  58480: "38003",
  58481: "38003",
  58482: "38043",
  58483: "38093",
  58487: "38043",
  58492: "38003",
  58494: "38015",
  58501: "38015",
  58503: "38015",
  58504: "38015",
  5851: "50005",
  58521: "38015",
  58523: "38057",
  5853: "50019",
  58530: "38065",
  58531: "38055",
  58532: "38015",
  58540: "38055",
  58545: "38057",
  5855: "50019",
  58552: "38029",
  58554: "38059",
  58558: "38015",
  58563: "38059",
  58565: "38055",
  5857: "50019",
  58572: "38015",
  58575: "38055",
  58576: "38055",
  58577: "38055",
  58579: "38055",
  5858: "50009",
  5859: "50019",
  5860: "50019",
  58601: "38089",
  5862: "50005",
  58621: "38033",
  58623: "38011",
  58630: "38089",
  58631: "38059",
  58639: "38001",
  58640: "38025",
  58649: "38001",
  58652: "38089",
  58653: "38011",
  58655: "38089",
  58656: "38089",
  5866: "50005",
  5867: "50005",
  58701: "38101",
  58703: "38101",
  58704: "38101",
  5871: "50005",
  58710: "38049",
  58711: "38009",
  58718: "38101",
  5872: "50019",
  58722: "38101",
  5873: "50005",
  58731: "38049",
  58736: "38049",
  5874: "50019",
  58740: "38101",
  58741: "38049",
  58744: "38049",
  58746: "38101",
  5875: "50019",
  58750: "38009",
  58759: "38101",
  58760: "38009",
  58761: "38075",
  58768: "38101",
  58773: "38013",
  58783: "38009",
  58784: "38061",
  58785: "38101",
  58788: "38049",
  58789: "38049",
  58790: "38049",
  58792: "38049",
  58793: "38009",
  58801: "38105",
  58830: "38105",
  58831: "38053",
  58835: "38053",
  58843: "38105",
  58849: "38105",
  58852: "38105",
  58854: "38053",
  59001: "30095",
  59003: "30087",
  59006: "30111",
  59008: "30009",
  59011: "30097",
  59014: "30009",
  59015: "30111",
  59018: "30067",
  59019: "30095",
  59020: "30067",
  59024: "30003",
  59027: "30067",
  59028: "30095",
  59029: "30009",
  5903: "50009",
  59030: "30067",
  59032: "30027",
  59034: "30003",
  59036: "30107",
  59037: "30111",
  59038: "30103",
  59041: "30009",
  59044: "30111",
  59046: "30037",
  59047: "30067",
  5905: "50009",
  59050: "30003",
  59052: "30097",
  59053: "30059",
  59057: "30111",
  5906: "50009",
  59061: "30095",
  59063: "30095",
  59065: "30067",
  59068: "30009",
  59069: "30097",
  5907: "50009",
  59070: "30009",
  59071: "30009",
  59072: "30065",
  59074: "30037",
  59079: "30111",
  59081: "30067",
  59086: "30067",
  59087: "30069",
  59088: "30111",
  59101: "30111",
  59102: "30111",
  59105: "30111",
  59106: "30111",
  59201: "30085",
  59215: "30055",
  59218: "30083",
  59221: "30083",
  59222: "30019",
  59223: "30055",
  59225: "30105",
  59230: "30105",
  59241: "30105",
  59243: "30083",
  59247: "30091",
  59248: "30105",
  59250: "30105",
  59254: "30091",
  59259: "30021",
  59261: "30071",
  59262: "30083",
  59263: "30019",
  59270: "30083",
  59274: "30055",
  59275: "30091",
  59301: "30017",
  59313: "30025",
  59317: "30075",
  59323: "30087",
  59324: "30011",
  59326: "30079",
  59327: "30087",
  59330: "30021",
  59337: "30033",
  59344: "30025",
  59349: "30079",
  59353: "30109",
  59401: "30013",
  59404: "30013",
  59405: "30013",
  59410: "30049",
  59412: "30013",
  59414: "30013",
  59416: "30073",
  59417: "30035",
  59421: "30013",
  59422: "30099",
  59425: "30073",
  59427: "30035",
  59430: "30027",
  59433: "30099",
  59434: "30035",
  59436: "30099",
  59442: "30015",
  59443: "30013",
  59446: "30015",
  59447: "30045",
  59450: "30015",
  59451: "30027",
  59452: "30045",
  59453: "30107",
  59454: "30101",
  59457: "30027",
  59460: "30015",
  59462: "30045",
  59463: "30013",
  59464: "30027",
  59465: "30013",
  59468: "30099",
  59471: "30027",
  59472: "30013",
  59474: "30101",
  59477: "30013",
  59479: "30045",
  59480: "30013",
  59482: "30101",
  59483: "30013",
  59485: "30013",
  59486: "30073",
  59487: "30013",
  59489: "30027",
  59501: "30041",
  59520: "30015",
  59522: "30051",
  59523: "30005",
  59524: "30005",
  59525: "30041",
  59526: "30005",
  59531: "30051",
  59538: "30071",
  59540: "30041",
  59601: "30049",
  59602: "30049",
  59631: "30043",
  59632: "30043",
  59633: "30049",
  59634: "30043",
  59635: "30049",
  59638: "30043",
  59639: "30049",
  59643: "30007",
  59644: "30007",
  59645: "30059",
  59648: "30049",
  59701: "30093",
  59711: "30023",
  59713: "30077",
  59714: "30031",
  59715: "30031",
  59716: "30057",
  59718: "30031",
  59720: "30057",
  59721: "30057",
  59722: "30077",
  59725: "30001",
  59728: "30077",
  59729: "30057",
  59730: "30031",
  59731: "30077",
  59733: "30077",
  59739: "30001",
  59740: "30057",
  59741: "30031",
  59746: "30001",
  59749: "30057",
  59750: "30093",
  59752: "30031",
  59754: "30057",
  59758: "30031",
  59759: "30043",
  59760: "30031",
  59761: "30001",
  59762: "30023",
  59801: "30063",
  59802: "30063",
  59803: "30063",
  59804: "30063",
  59808: "30063",
  59820: "30061",
  59821: "30047",
  59823: "30063",
  59824: "30047",
  59825: "30063",
  59826: "30063",
  59827: "30081",
  59828: "30081",
  59829: "30081",
  59831: "30089",
  59832: "30039",
  59833: "30081",
  59834: "30063",
  59837: "30039",
  59840: "30081",
  59843: "30077",
  59844: "30089",
  59845: "30029",
  59846: "30063",
  59847: "30063",
  59853: "30089",
  59854: "30077",
  59855: "30047",
  59856: "30089",
  59858: "30039",
  59859: "30089",
  59860: "30047",
  59864: "30047",
  59865: "30047",
  59866: "30061",
  59868: "30063",
  59870: "30081",
  59871: "30081",
  59872: "30061",
  59873: "30089",
  59874: "30089",
  59875: "30081",
  59901: "30029",
  59910: "30047",
  59911: "30047",
  59912: "30029",
  59913: "30029",
  59914: "30047",
  59915: "30047",
  59916: "30029",
  59917: "30053",
  59918: "30053",
  59919: "30029",
  59920: "30029",
  59922: "30029",
  59923: "30053",
  59925: "30029",
  59926: "30029",
  59927: "30029",
  59928: "30029",
  59929: "30047",
  59930: "30053",
  59931: "30047",
  59932: "30029",
  59934: "30053",
  59935: "30053",
  59936: "30029",
  59937: "30029",
  60002: "17097",
  60004: "17031",
  60005: "17031",
  60007: "17031",
  60008: "17031",
  6001: "9003",
  60010: "17097",
  60012: "17111",
  60013: "17111",
  60014: "17111",
  60015: "17097",
  60016: "17031",
  60018: "17031",
  6002: "9003",
  60020: "17097",
  60021: "17111",
  60022: "17031",
  60025: "17031",
  60026: "17031",
  60029: "17031",
  60030: "17097",
  60031: "17097",
  60033: "17111",
  60034: "17111",
  60035: "17097",
  60040: "17097",
  60041: "17097",
  60042: "17111",
  60043: "17031",
  60044: "17097",
  60045: "17097",
  60046: "17097",
  60047: "17097",
  60048: "17097",
  60050: "17111",
  60051: "17111",
  60053: "17031",
  60056: "17031",
  60060: "17097",
  60061: "17097",
  60062: "17031",
  60064: "17097",
  60067: "17031",
  60068: "17031",
  60069: "17097",
  60070: "17031",
  60071: "17111",
  60072: "17111",
  60073: "17097",
  60074: "17031",
  60076: "17031",
  60077: "17031",
  60081: "17111",
  60083: "17097",
  60084: "17097",
  60085: "17097",
  60087: "17097",
  60089: "17097",
  60090: "17031",
  60091: "17031",
  60093: "17031",
  60096: "17097",
  60097: "17111",
  60098: "17111",
  60099: "17097",
  6010: "9003",
  60101: "17043",
  60102: "17111",
  60103: "17043",
  60104: "17031",
  60106: "17043",
  60107: "17031",
  60108: "17043",
  60109: "17089",
  60110: "17089",
  60111: "17037",
  60112: "17037",
  60113: "17141",
  60115: "17037",
  60118: "17089",
  60119: "17089",
  60120: "17031",
  60123: "17089",
  60124: "17089",
  60126: "17043",
  6013: "9003",
  60130: "17031",
  60131: "17031",
  60133: "17043",
  60134: "17089",
  60135: "17037",
  60136: "17089",
  60137: "17043",
  60139: "17043",
  60140: "17089",
  60142: "17111",
  60143: "17043",
  60145: "17037",
  60146: "17037",
  60148: "17043",
  60150: "17037",
  60151: "17037",
  60152: "17111",
  60153: "17031",
  60154: "17031",
  60155: "17031",
  60156: "17111",
  60157: "17043",
  6016: "9003",
  60160: "17031",
  60162: "17031",
  60163: "17031",
  60164: "17031",
  60165: "17031",
  60169: "17031",
  60171: "17031",
  60172: "17043",
  60173: "17031",
  60174: "17089",
  60175: "17089",
  60176: "17031",
  60177: "17089",
  60178: "17037",
  6018: "9005",
  60180: "17111",
  60181: "17043",
  60184: "17043",
  60185: "17043",
  60187: "17043",
  60188: "17043",
  60189: "17043",
  6019: "9003",
  60190: "17043",
  60191: "17043",
  60192: "17031",
  60193: "17031",
  60194: "17031",
  60195: "17031",
  60201: "17031",
  60202: "17031",
  60203: "17031",
  6021: "9005",
  6023: "9003",
  6024: "9005",
  6026: "9003",
  6027: "9003",
  6029: "9013",
  60301: "17031",
  60302: "17031",
  60304: "17031",
  60305: "17031",
  6031: "9005",
  6032: "9003",
  6033: "9003",
  6035: "9003",
  6037: "9003",
  6039: "9005",
  6040: "9003",
  60401: "17197",
  60402: "17031",
  60403: "17197",
  60404: "17197",
  60406: "17031",
  60407: "17063",
  60408: "17197",
  60409: "17031",
  60410: "17197",
  60411: "17031",
  60415: "17031",
  60416: "17063",
  60417: "17197",
  60418: "17031",
  60419: "17031",
  6042: "9003",
  60420: "17105",
  60421: "17197",
  60422: "17031",
  60423: "17197",
  60424: "17063",
  60425: "17031",
  60426: "17031",
  60428: "17031",
  60429: "17031",
  6043: "9013",
  60430: "17031",
  60431: "17197",
  60432: "17197",
  60433: "17197",
  60435: "17197",
  60436: "17197",
  60438: "17031",
  60439: "17031",
  60440: "17197",
  60441: "17197",
  60442: "17197",
  60443: "17031",
  60444: "17063",
  60445: "17031",
  60446: "17197",
  60447: "17093",
  60448: "17197",
  60449: "17197",
  60450: "17063",
  60451: "17197",
  60452: "17031",
  60453: "17031",
  60455: "17031",
  60456: "17031",
  60457: "17031",
  60458: "17031",
  60459: "17031",
  60460: "17105",
  60461: "17031",
  60462: "17031",
  60463: "17031",
  60464: "17031",
  60465: "17031",
  60466: "17031",
  60467: "17031",
  60468: "17197",
  60469: "17031",
  60470: "17099",
  60471: "17031",
  60472: "17031",
  60473: "17031",
  60474: "17063",
  60475: "17197",
  60476: "17031",
  60477: "17031",
  60478: "17031",
  60479: "17063",
  60480: "17031",
  60481: "17197",
  60482: "17031",
  60484: "17197",
  60487: "17031",
  60490: "17197",
  60491: "17197",
  60501: "17031",
  60502: "17043",
  60503: "17197",
  60504: "17043",
  60505: "17089",
  60506: "17089",
  6051: "9003",
  60510: "17089",
  60511: "17089",
  60512: "17093",
  60513: "17031",
  60514: "17043",
  60515: "17043",
  60516: "17043",
  60517: "17043",
  60518: "17099",
  6052: "9003",
  60520: "17037",
  60521: "17043",
  60523: "17043",
  60525: "17031",
  60526: "17031",
  60527: "17043",
  6053: "9003",
  60530: "17103",
  60531: "17099",
  60532: "17043",
  60534: "17031",
  60537: "17099",
  60538: "17093",
  60540: "17043",
  60541: "17093",
  60542: "17089",
  60543: "17093",
  60544: "17197",
  60545: "17093",
  60546: "17031",
  60548: "17037",
  60549: "17099",
  60550: "17037",
  60551: "17099",
  60552: "17099",
  60553: "17103",
  60554: "17089",
  60555: "17043",
  60556: "17037",
  60558: "17031",
  60559: "17043",
  60560: "17093",
  60561: "17043",
  60563: "17043",
  60564: "17197",
  60565: "17043",
  6057: "9005",
  6058: "9005",
  60585: "17197",
  60586: "17197",
  6060: "9003",
  60601: "17031",
  60602: "17031",
  60603: "17031",
  60604: "17031",
  60605: "17031",
  60606: "17031",
  60607: "17031",
  60608: "17031",
  60609: "17031",
  60610: "17031",
  60611: "17031",
  60612: "17031",
  60613: "17031",
  60614: "17031",
  60615: "17031",
  60616: "17031",
  60617: "17031",
  60618: "17031",
  60619: "17031",
  6062: "9003",
  60620: "17031",
  60621: "17031",
  60622: "17031",
  60623: "17031",
  60624: "17031",
  60625: "17031",
  60626: "17031",
  60628: "17031",
  60629: "17031",
  6063: "9005",
  60630: "17031",
  60631: "17031",
  60632: "17031",
  60633: "17031",
  60634: "17031",
  60636: "17031",
  60637: "17031",
  60638: "17031",
  60639: "17031",
  60640: "17031",
  60641: "17031",
  60642: "17031",
  60643: "17031",
  60644: "17031",
  60645: "17031",
  60646: "17031",
  60647: "17031",
  60649: "17031",
  6065: "9003",
  60651: "17031",
  60652: "17031",
  60653: "17031",
  60654: "17031",
  60655: "17031",
  60656: "17031",
  60657: "17031",
  60659: "17031",
  6066: "9013",
  60660: "17031",
  60661: "17031",
  6067: "9003",
  6068: "9005",
  6069: "9005",
  6070: "9003",
  60706: "17031",
  60707: "17031",
  6071: "9013",
  60712: "17031",
  60714: "17031",
  6073: "9003",
  6074: "9003",
  6076: "9013",
  6078: "9003",
  60803: "17031",
  60804: "17031",
  60805: "17031",
  6081: "9003",
  6082: "9003",
  60827: "17031",
  6084: "9013",
  6085: "9003",
  6088: "9003",
  6089: "9003",
  6090: "9003",
  60901: "17091",
  6091: "9003",
  60910: "17091",
  60911: "17075",
  60912: "17075",
  60913: "17091",
  60914: "17091",
  60915: "17091",
  60917: "17091",
  60918: "17075",
  60919: "17053",
  6092: "9003",
  60921: "17105",
  60922: "17075",
  60924: "17075",
  60927: "17075",
  60928: "17075",
  60929: "17105",
  6093: "9003",
  60930: "17075",
  60931: "17075",
  60933: "17053",
  60934: "17105",
  60935: "17091",
  60936: "17053",
  60938: "17075",
  60940: "17091",
  60941: "17091",
  60942: "17183",
  60946: "17053",
  60948: "17075",
  6095: "9003",
  60950: "17091",
  60951: "17075",
  60952: "17053",
  60953: "17075",
  60954: "17091",
  60955: "17075",
  60957: "17053",
  60958: "17091",
  60959: "17053",
  6096: "9003",
  60960: "17183",
  60961: "17091",
  60962: "17053",
  60963: "17183",
  60964: "17091",
  60966: "17075",
  60968: "17053",
  60970: "17075",
  60973: "17075",
  6098: "9005",
  61001: "17085",
  61006: "17103",
  61007: "17141",
  61008: "17007",
  61010: "17141",
  61011: "17007",
  61012: "17007",
  61014: "17015",
  61015: "17141",
  61016: "17201",
  61019: "17177",
  61020: "17141",
  61021: "17103",
  61024: "17201",
  61025: "17085",
  61028: "17085",
  6103: "9003",
  61030: "17141",
  61031: "17103",
  61032: "17177",
  61036: "17085",
  61038: "17007",
  61039: "17177",
  61041: "17085",
  61042: "17103",
  61046: "17015",
  61047: "17141",
  61048: "17177",
  61049: "17141",
  6105: "9003",
  61051: "17015",
  61052: "17141",
  61053: "17015",
  61054: "17141",
  6106: "9003",
  61060: "17177",
  61061: "17141",
  61062: "17177",
  61063: "17201",
  61064: "17141",
  61065: "17007",
  61068: "17141",
  6107: "9003",
  61070: "17177",
  61071: "17195",
  61072: "17201",
  61073: "17201",
  61074: "17015",
  61075: "17085",
  61078: "17015",
  6108: "9003",
  61080: "17201",
  61081: "17195",
  61084: "17141",
  61085: "17085",
  61087: "17085",
  61088: "17201",
  61089: "17177",
  6109: "9003",
  6110: "9003",
  61101: "17201",
  61102: "17201",
  61103: "17201",
  61104: "17201",
  61107: "17201",
  61108: "17201",
  61109: "17201",
  6111: "9003",
  61111: "17201",
  61114: "17201",
  61115: "17201",
  6112: "9003",
  6114: "9003",
  6117: "9003",
  6118: "9003",
  6119: "9003",
  6120: "9003",
  61201: "17161",
  61230: "17195",
  61231: "17131",
  61232: "17161",
  61234: "17073",
  61235: "17073",
  61238: "17073",
  61239: "17161",
  61240: "17161",
  61241: "17073",
  61242: "17161",
  61244: "17161",
  61250: "17195",
  61251: "17195",
  61252: "17195",
  61254: "17073",
  61256: "17161",
  61257: "17161",
  61259: "17161",
  61260: "17131",
  61261: "17195",
  61262: "17073",
  61263: "17131",
  61264: "17161",
  61265: "17161",
  61270: "17195",
  61272: "17131",
  61273: "17073",
  61275: "17161",
  61276: "17131",
  61277: "17195",
  61279: "17131",
  61281: "17131",
  61282: "17161",
  61283: "17195",
  61284: "17161",
  61285: "17015",
  61301: "17099",
  61310: "17103",
  61311: "17105",
  61313: "17105",
  61318: "17103",
  61319: "17105",
  61320: "17011",
  61325: "17099",
  61326: "17155",
  61327: "17155",
  61329: "17011",
  61330: "17011",
  61333: "17105",
  61334: "17099",
  61341: "17099",
  61342: "17099",
  61348: "17099",
  61349: "17011",
  61350: "17099",
  61353: "17103",
  61354: "17099",
  61356: "17011",
  61360: "17099",
  61361: "17011",
  61362: "17011",
  61364: "17099",
  61367: "17103",
  61368: "17011",
  61369: "17123",
  61370: "17099",
  61373: "17099",
  61375: "17123",
  61376: "17011",
  61377: "17123",
  61378: "17103",
  61379: "17011",
  61401: "17095",
  61410: "17095",
  61411: "17109",
  61412: "17131",
  61413: "17073",
  61414: "17095",
  61415: "17057",
  61416: "17109",
  61420: "17109",
  61421: "17175",
  61422: "17109",
  61423: "17187",
  61427: "17057",
  61428: "17095",
  61434: "17073",
  61437: "17071",
  61438: "17109",
  61440: "17109",
  61442: "17131",
  61443: "17073",
  61447: "17187",
  61448: "17095",
  61449: "17175",
  61450: "17067",
  61451: "17143",
  61453: "17187",
  61455: "17109",
  61462: "17187",
  61465: "17131",
  61466: "17131",
  61467: "17095",
  61469: "17071",
  61470: "17109",
  61473: "17187",
  61475: "17109",
  61476: "17131",
  61478: "17187",
  61483: "17175",
  61486: "17131",
  61488: "17095",
  61489: "17095",
  61490: "17073",
  61491: "17175",
  61501: "17057",
  61516: "17203",
  61517: "17143",
  61520: "17057",
  61523: "17143",
  61525: "17143",
  61526: "17143",
  61528: "17143",
  61529: "17143",
  61530: "17203",
  61531: "17057",
  61532: "17125",
  61533: "17143",
  61534: "17179",
  61535: "17179",
  61536: "17143",
  61537: "17123",
  61540: "17123",
  61542: "17057",
  61544: "17057",
  61545: "17203",
  61546: "17179",
  61547: "17143",
  61548: "17203",
  61550: "17179",
  61552: "17143",
  61554: "17179",
  61559: "17143",
  61560: "17155",
  61561: "17203",
  61563: "17057",
  61564: "17179",
  61565: "17123",
  61568: "17179",
  61569: "17143",
  61570: "17203",
  61571: "17179",
  61572: "17095",
  61602: "17143",
  61603: "17143",
  61604: "17143",
  61605: "17143",
  61606: "17143",
  61607: "17143",
  61610: "17179",
  61611: "17179",
  61614: "17143",
  61615: "17143",
  61616: "17143",
  61701: "17113",
  61704: "17113",
  61705: "17113",
  61721: "17179",
  61722: "17113",
  61723: "17107",
  61724: "17113",
  61725: "17203",
  61726: "17105",
  61727: "17039",
  61728: "17113",
  61729: "17203",
  61730: "17113",
  61732: "17113",
  61733: "17179",
  61734: "17179",
  61735: "17039",
  61736: "17113",
  61737: "17113",
  61738: "17203",
  61739: "17105",
  61740: "17105",
  61741: "17105",
  61742: "17203",
  61743: "17105",
  61744: "17113",
  61745: "17113",
  61747: "17179",
  61748: "17113",
  61749: "17039",
  61752: "17113",
  61753: "17113",
  61754: "17113",
  61755: "17179",
  61756: "17115",
  61759: "17179",
  61760: "17203",
  61761: "17113",
  61764: "17105",
  61769: "17105",
  61770: "17113",
  61771: "17203",
  61772: "17113",
  61773: "17053",
  61774: "17113",
  61775: "17105",
  61776: "17113",
  61777: "17039",
  61778: "17039",
  61801: "17019",
  61802: "17019",
  61810: "17183",
  61811: "17183",
  61812: "17183",
  61813: "17147",
  61814: "17183",
  61817: "17183",
  61818: "17147",
  61820: "17019",
  61821: "17019",
  61822: "17019",
  61830: "17147",
  61832: "17183",
  61833: "17183",
  61834: "17183",
  61839: "17147",
  61840: "17019",
  61841: "17183",
  61842: "17039",
  61843: "17019",
  61844: "17183",
  61846: "17183",
  61847: "17019",
  61848: "17183",
  61849: "17019",
  61850: "17183",
  61853: "17019",
  61854: "17147",
  61856: "17147",
  61858: "17183",
  61859: "17019",
  61863: "17019",
  61864: "17019",
  61865: "17183",
  61866: "17019",
  61870: "17183",
  61872: "17019",
  61873: "17019",
  61874: "17019",
  61875: "17019",
  61876: "17183",
  61877: "17019",
  61878: "17019",
  61880: "17019",
  61882: "17039",
  61883: "17183",
  61884: "17147",
  61910: "17041",
  61911: "17139",
  61912: "17029",
  61913: "17041",
  61914: "17139",
  61917: "17045",
  61919: "17041",
  61920: "17029",
  61924: "17045",
  61925: "17115",
  61928: "17139",
  61929: "17147",
  61930: "17041",
  61931: "17029",
  61932: "17045",
  61933: "17045",
  61936: "17147",
  61937: "17139",
  61938: "17029",
  61940: "17045",
  61942: "17041",
  61943: "17029",
  61944: "17045",
  61951: "17139",
  61953: "17041",
  61955: "17045",
  61956: "17041",
  61957: "17173",
  62001: "17119",
  62002: "17119",
  62006: "17013",
  62009: "17117",
  62010: "17119",
  62012: "17117",
  62013: "17013",
  62014: "17117",
  62016: "17061",
  62017: "17135",
  62018: "17119",
  62019: "17005",
  62021: "17119",
  62022: "17083",
  62024: "17119",
  62025: "17119",
  62027: "17061",
  62028: "17083",
  62031: "17083",
  62032: "17135",
  62033: "17117",
  62034: "17119",
  62035: "17119",
  62036: "17013",
  62037: "17083",
  62040: "17119",
  62044: "17061",
  62045: "17013",
  62046: "17119",
  62047: "17013",
  62048: "17119",
  62049: "17135",
  62051: "17135",
  62052: "17083",
  62053: "17013",
  62054: "17061",
  62056: "17135",
  62058: "17119",
  62060: "17119",
  62061: "17119",
  62062: "17119",
  62063: "17083",
  62067: "17119",
  62069: "17117",
  62074: "17119",
  62075: "17135",
  62080: "17051",
  62082: "17061",
  62084: "17119",
  62086: "17005",
  62087: "17119",
  62088: "17117",
  62090: "17119",
  62092: "17061",
  62093: "17117",
  62094: "17135",
  62095: "17119",
  62097: "17119",
  62201: "17163",
  62203: "17163",
  62204: "17163",
  62205: "17163",
  62206: "17163",
  62207: "17163",
  62208: "17163",
  62214: "17189",
  62215: "17027",
  62216: "17027",
  62217: "17157",
  62218: "17027",
  62219: "17027",
  62220: "17163",
  62221: "17163",
  62223: "17163",
  62226: "17163",
  62230: "17027",
  62231: "17027",
  62232: "17163",
  62233: "17157",
  62234: "17119",
  62236: "17133",
  62237: "17145",
  62238: "17145",
  62239: "17163",
  62240: "17163",
  62241: "17157",
  62242: "17157",
  62243: "17163",
  62244: "17133",
  62245: "17027",
  62246: "17005",
  62248: "17133",
  62249: "17119",
  62250: "17027",
  62253: "17005",
  62254: "17163",
  62255: "17163",
  62257: "17163",
  62258: "17163",
  6226: "9015",
  62260: "17163",
  62262: "17005",
  62263: "17189",
  62264: "17163",
  62265: "17027",
  62268: "17189",
  62269: "17163",
  62271: "17189",
  62272: "17157",
  62274: "17145",
  62275: "17005",
  62277: "17157",
  62278: "17157",
  62280: "17157",
  62281: "17119",
  62284: "17005",
  62285: "17163",
  62286: "17157",
  62288: "17157",
  62292: "17157",
  62293: "17027",
  62294: "17119",
  62295: "17133",
  62297: "17157",
  62298: "17133",
  62301: "17001",
  62305: "17001",
  6231: "9013",
  62311: "17067",
  62312: "17149",
  62313: "17067",
  62314: "17149",
  62316: "17067",
  6232: "9013",
  62320: "17001",
  62321: "17067",
  62324: "17001",
  62326: "17109",
  62330: "17067",
  62334: "17067",
  62338: "17001",
  6234: "9015",
  62340: "17149",
  62341: "17067",
  62343: "17149",
  62345: "17149",
  62347: "17001",
  6235: "9015",
  62351: "17001",
  62353: "17009",
  62354: "17067",
  62355: "17013",
  62356: "17149",
  62357: "17149",
  62358: "17067",
  62360: "17001",
  62361: "17149",
  62362: "17149",
  62363: "17149",
  62366: "17149",
  62367: "17109",
  6237: "9013",
  62370: "17149",
  62373: "17067",
  62374: "17109",
  62375: "17009",
  62376: "17001",
  62378: "17009",
  62379: "17067",
  6238: "9013",
  62380: "17067",
  6239: "9015",
  62401: "17049",
  6241: "9015",
  62410: "17185",
  62411: "17049",
  62414: "17051",
  62417: "17101",
  62418: "17051",
  62419: "17159",
  6242: "9015",
  62420: "17023",
  62421: "17159",
  62422: "17173",
  62424: "17049",
  62425: "17159",
  62426: "17025",
  62427: "17033",
  62428: "17035",
  62431: "17173",
  62434: "17025",
  62436: "17035",
  62438: "17173",
  62439: "17101",
  62440: "17029",
  62441: "17023",
  62442: "17023",
  62443: "17049",
  62444: "17173",
  62445: "17079",
  62446: "17191",
  62447: "17035",
  62448: "17079",
  62449: "17033",
  62450: "17159",
  62451: "17033",
  62452: "17159",
  62454: "17033",
  62458: "17051",
  62460: "17101",
  62461: "17049",
  62462: "17035",
  62463: "17173",
  62465: "17173",
  62466: "17101",
  62467: "17049",
  62468: "17035",
  62469: "17035",
  6247: "9015",
  62471: "17051",
  62473: "17049",
  62474: "17023",
  62476: "17047",
  62477: "17023",
  62478: "17023",
  6248: "9013",
  6249: "9011",
  6250: "9013",
  62501: "17115",
  62510: "17173",
  62513: "17115",
  62514: "17115",
  62517: "17021",
  62520: "17167",
  62521: "17115",
  62522: "17115",
  62526: "17115",
  62530: "17167",
  62531: "17021",
  62533: "17135",
  62534: "17173",
  62535: "17115",
  62536: "17167",
  62538: "17021",
  62539: "17167",
  6254: "9011",
  62540: "17021",
  62544: "17115",
  62545: "17167",
  62546: "17021",
  62547: "17021",
  62548: "17107",
  62549: "17115",
  6255: "9015",
  62550: "17173",
  62551: "17115",
  62553: "17173",
  62554: "17115",
  62555: "17021",
  62556: "17021",
  62557: "17021",
  62558: "17167",
  6256: "9015",
  62560: "17135",
  62561: "17167",
  62563: "17167",
  62565: "17173",
  62567: "17021",
  62568: "17021",
  62570: "17021",
  62571: "17173",
  62572: "17117",
  62573: "17115",
  6259: "9015",
  6260: "9015",
  62601: "17137",
  62611: "17017",
  62612: "17017",
  62613: "17129",
  62615: "17167",
  62618: "17017",
  62625: "17167",
  62626: "17117",
  62627: "17017",
  62628: "17137",
  62629: "17167",
  62631: "17137",
  62634: "17107",
  62635: "17107",
  62638: "17137",
  6264: "9015",
  62640: "17117",
  62642: "17129",
  62644: "17125",
  62650: "17137",
  62656: "17107",
  6266: "9015",
  62661: "17167",
  62664: "17125",
  62665: "17137",
  62668: "17137",
  62670: "17167",
  62673: "17129",
  62674: "17117",
  62675: "17129",
  62677: "17167",
  6268: "9013",
  62681: "17169",
  62682: "17125",
  62684: "17167",
  62685: "17117",
  62688: "17129",
  62690: "17117",
  62691: "17017",
  62692: "17137",
  62693: "17167",
  62694: "17171",
  62695: "17137",
  62702: "17167",
  62703: "17167",
  62704: "17167",
  62707: "17167",
  62711: "17167",
  62712: "17167",
  6277: "9015",
  6278: "9015",
  6279: "9013",
  6280: "9015",
  62801: "17121",
  62803: "17189",
  62806: "17047",
  62807: "17121",
  62808: "17189",
  6281: "9015",
  62812: "17055",
  62818: "17185",
  6282: "9015",
  62821: "17193",
  62822: "17055",
  62823: "17191",
  62824: "17025",
  62827: "17193",
  62831: "17189",
  62832: "17145",
  62835: "17193",
  62836: "17055",
  62837: "17191",
  62838: "17051",
  62839: "17025",
  62842: "17191",
  62844: "17193",
  62849: "17121",
  62853: "17121",
  62854: "17121",
  62858: "17025",
  62859: "17065",
  62863: "17185",
  62864: "17081",
  62868: "17159",
  62869: "17193",
  62870: "17121",
  62875: "17121",
  62877: "17189",
  62880: "17051",
  62881: "17121",
  62882: "17121",
  62883: "17081",
  62884: "17055",
  62885: "17051",
  62886: "17191",
  62888: "17145",
  62890: "17055",
  62892: "17121",
  62893: "17081",
  62895: "17191",
  62896: "17055",
  62899: "17025",
  62901: "17077",
  62902: "17199",
  62903: "17077",
  62905: "17181",
  62906: "17181",
  62907: "17077",
  62908: "17127",
  62910: "17127",
  62912: "17181",
  62914: "17003",
  62916: "17157",
  62917: "17165",
  62918: "17199",
  62919: "17069",
  62920: "17181",
  62922: "17199",
  62924: "17077",
  62926: "17181",
  62927: "17077",
  62930: "17165",
  62931: "17069",
  62932: "17077",
  62933: "17199",
  62935: "17165",
  62938: "17151",
  62939: "17087",
  62940: "17077",
  62941: "17153",
  62942: "17077",
  62943: "17087",
  62946: "17165",
  62947: "17151",
  62948: "17199",
  62950: "17077",
  62951: "17199",
  62952: "17181",
  62953: "17127",
  62956: "17153",
  62957: "17003",
  62958: "17077",
  62959: "17199",
  62960: "17127",
  62963: "17153",
  62964: "17153",
  62966: "17077",
  62970: "17153",
  62972: "17087",
  62974: "17199",
  62975: "17077",
  62976: "17153",
  62977: "17165",
  62983: "17055",
  62985: "17087",
  62987: "17165",
  62988: "17003",
  62990: "17003",
  62992: "17153",
  62994: "17077",
  62995: "17087",
  62996: "17153",
  62997: "17145",
  62998: "17181",
  62999: "17055",
  63005: "29189",
  63010: "29099",
  63011: "29189",
  63012: "29099",
  63013: "29071",
  63014: "29071",
  63015: "29071",
  63016: "29099",
  63017: "29189",
  63019: "29099",
  63020: "29099",
  63021: "29189",
  63023: "29099",
  63025: "29099",
  63026: "29189",
  63028: "29099",
  63031: "29189",
  63033: "29189",
  63034: "29189",
  63036: "29187",
  63037: "29071",
  63038: "29189",
  63039: "29071",
  63040: "29189",
  63041: "29071",
  63042: "29189",
  63043: "29189",
  63044: "29189",
  63048: "29099",
  63049: "29099",
  63050: "29099",
  63051: "29099",
  63052: "29099",
  63055: "29071",
  63056: "29071",
  63060: "29071",
  63061: "29071",
  63068: "29071",
  63069: "29099",
  63070: "29099",
  63071: "29221",
  63072: "29071",
  63073: "29071",
  63074: "29189",
  63077: "29071",
  63080: "29071",
  63084: "29071",
  63087: "29187",
  63088: "29189",
  63089: "29071",
  63090: "29071",
  63091: "29073",
  63101: "29510",
  63102: "29510",
  63103: "29510",
  63104: "29510",
  63105: "29189",
  63106: "29510",
  63107: "29510",
  63108: "29510",
  63109: "29510",
  63110: "29510",
  63111: "29510",
  63112: "29510",
  63113: "29510",
  63114: "29189",
  63115: "29510",
  63116: "29510",
  63117: "29189",
  63118: "29510",
  63119: "29189",
  63120: "29510",
  63121: "29189",
  63122: "29189",
  63123: "29189",
  63124: "29189",
  63125: "29189",
  63126: "29189",
  63127: "29189",
  63128: "29189",
  63129: "29189",
  63130: "29189",
  63131: "29189",
  63132: "29189",
  63133: "29189",
  63134: "29189",
  63135: "29189",
  63136: "29189",
  63137: "29189",
  63138: "29189",
  63139: "29510",
  63141: "29189",
  63143: "29189",
  63144: "29189",
  63146: "29189",
  63147: "29510",
  6320: "9011",
  6330: "9011",
  63301: "29183",
  63303: "29183",
  63304: "29183",
  6331: "9015",
  6333: "9011",
  63330: "29163",
  63332: "29183",
  63334: "29163",
  63336: "29163",
  63339: "29163",
  6334: "9011",
  63341: "29183",
  63343: "29113",
  63344: "29163",
  63347: "29113",
  63348: "29183",
  63349: "29113",
  6335: "9011",
  63351: "29139",
  63352: "29007",
  63353: "29163",
  63357: "29219",
  63359: "29163",
  63361: "29139",
  63362: "29113",
  63363: "29139",
  63366: "29183",
  63367: "29183",
  63368: "29183",
  63369: "29113",
  63373: "29183",
  63376: "29183",
  63377: "29113",
  63379: "29113",
  63380: "29219",
  63381: "29219",
  63382: "29173",
  63383: "29219",
  63384: "29139",
  63385: "29183",
  63386: "29183",
  63388: "29027",
  63389: "29113",
  6339: "9011",
  63390: "29219",
  6340: "9011",
  63401: "29127",
  63434: "29205",
  63435: "29111",
  63436: "29173",
  63437: "29205",
  63440: "29111",
  63441: "29163",
  63443: "29205",
  63445: "29045",
  63446: "29103",
  63448: "29111",
  63451: "29205",
  63454: "29127",
  63456: "29173",
  63459: "29173",
  63460: "29103",
  63461: "29127",
  63462: "29173",
  63463: "29127",
  63468: "29205",
  63469: "29205",
  63471: "29127",
  63501: "29001",
  6351: "9011",
  63530: "29121",
  63531: "29103",
  63532: "29121",
  63533: "29001",
  63534: "29121",
  63537: "29103",
  6354: "9015",
  63544: "29001",
  63545: "29211",
  63546: "29001",
  63547: "29103",
  63549: "29121",
  6355: "9011",
  63551: "29171",
  63552: "29121",
  63555: "29199",
  63556: "29211",
  63557: "29115",
  63558: "29121",
  63559: "29001",
  63563: "29199",
  63565: "29171",
  6357: "9011",
  6359: "9011",
  6360: "9011",
  63601: "29187",
  63620: "29093",
  63621: "29093",
  63623: "29093",
  63624: "29187",
  63625: "29179",
  63626: "29221",
  63627: "29186",
  63628: "29187",
  63629: "29203",
  63630: "29221",
  63631: "29221",
  63633: "29179",
  63636: "29093",
  63637: "29187",
  63638: "29179",
  63640: "29187",
  63645: "29123",
  63648: "29221",
  6365: "9011",
  63650: "29093",
  63653: "29187",
  63654: "29179",
  63655: "29123",
  63656: "29179",
  63660: "29221",
  63663: "29093",
  63664: "29221",
  63670: "29186",
  63673: "29157",
  6370: "9011",
  63701: "29031",
  63703: "29031",
  6371: "9011",
  63730: "29017",
  63732: "29157",
  63736: "29201",
  63739: "29031",
  6374: "9015",
  63740: "29201",
  63747: "29031",
  63748: "29157",
  6375: "9011",
  63751: "29017",
  63755: "29031",
  63764: "29017",
  63766: "29031",
  63769: "29031",
  6377: "9015",
  63770: "29031",
  63771: "29201",
  63775: "29157",
  6378: "9011",
  63780: "29201",
  63783: "29157",
  63785: "29031",
  6379: "9011",
  6380: "9011",
  63801: "29201",
  6382: "9011",
  63821: "29069",
  63822: "29207",
  63823: "29133",
  63825: "29207",
  63828: "29143",
  63829: "29069",
  63830: "29155",
  63834: "29133",
  63837: "29069",
  6384: "9011",
  63841: "29207",
  63845: "29133",
  63848: "29143",
  6385: "9011",
  63852: "29069",
  63855: "29069",
  63857: "29069",
  63862: "29143",
  63863: "29069",
  63866: "29143",
  63867: "29143",
  63868: "29143",
  63869: "29143",
  63870: "29207",
  63873: "29143",
  63874: "29143",
  63876: "29069",
  63877: "29155",
  63901: "29023",
  63933: "29069",
  63935: "29181",
  63937: "29035",
  63939: "29181",
  63944: "29223",
  63945: "29023",
  63957: "29223",
  63960: "29207",
  63964: "29223",
  63965: "29035",
  63966: "29223",
  63967: "29223",
  64001: "29107",
  6401: "9009",
  64011: "29107",
  64012: "29037",
  64014: "29095",
  64015: "29095",
  64016: "29095",
  64017: "29107",
  64018: "29165",
  64019: "29101",
  64020: "29107",
  64021: "29107",
  64022: "29107",
  64024: "29047",
  64029: "29095",
  6403: "9009",
  64030: "29095",
  64034: "29095",
  64035: "29177",
  64036: "29177",
  64037: "29107",
  64040: "29101",
  64048: "29047",
  6405: "9009",
  64050: "29095",
  64052: "29095",
  64053: "29095",
  64054: "29095",
  64055: "29095",
  64056: "29095",
  64057: "29095",
  64058: "29095",
  64060: "29047",
  64061: "29101",
  64062: "29177",
  64063: "29095",
  64064: "29095",
  64067: "29107",
  64068: "29047",
  64070: "29095",
  64071: "29107",
  64074: "29107",
  64075: "29095",
  64076: "29107",
  64077: "29177",
  64078: "29037",
  64079: "29165",
  64080: "29037",
  64081: "29095",
  64082: "29095",
  64083: "29037",
  64084: "29177",
  64085: "29177",
  64086: "29095",
  64088: "29095",
  64089: "29047",
  6409: "9007",
  64093: "29101",
  64096: "29107",
  64097: "29107",
  64098: "29165",
  6410: "9009",
  64105: "29095",
  64106: "29095",
  64108: "29095",
  64109: "29095",
  64110: "29095",
  64111: "29095",
  64112: "29095",
  64113: "29095",
  64114: "29095",
  64116: "29047",
  64117: "29047",
  64118: "29047",
  64119: "29047",
  6412: "9007",
  64120: "29095",
  64123: "29095",
  64124: "29095",
  64125: "29095",
  64126: "29095",
  64127: "29095",
  64128: "29095",
  64129: "29095",
  6413: "9007",
  64130: "29095",
  64131: "29095",
  64132: "29095",
  64133: "29095",
  64134: "29095",
  64136: "29095",
  64137: "29095",
  64138: "29095",
  64139: "29095",
  64145: "29095",
  64146: "29095",
  64149: "29095",
  6415: "9011",
  64150: "29165",
  64151: "29165",
  64152: "29165",
  64153: "29165",
  64154: "29165",
  64155: "29047",
  64156: "29047",
  64157: "29047",
  64158: "29047",
  6416: "9007",
  64161: "29047",
  64163: "29165",
  64165: "29047",
  64166: "29047",
  64167: "29047",
  6417: "9007",
  6418: "9009",
  6419: "9007",
  6420: "9011",
  6422: "9007",
  6423: "9007",
  6424: "9007",
  6426: "9007",
  6437: "9009",
  6438: "9007",
  64401: "29021",
  64402: "29075",
  6441: "9007",
  6442: "9007",
  64421: "29003",
  64424: "29081",
  64427: "29003",
  64429: "29063",
  6443: "9009",
  64430: "29063",
  64436: "29003",
  64438: "29075",
  64439: "29165",
  64440: "29021",
  64443: "29021",
  64444: "29165",
  64448: "29021",
  64449: "29003",
  64453: "29075",
  64454: "29049",
  64457: "29147",
  64459: "29003",
  64463: "29075",
  64465: "29049",
  64468: "29147",
  64469: "29063",
  6447: "9003",
  64474: "29063",
  64475: "29147",
  64477: "29049",
  64480: "29003",
  64481: "29081",
  64482: "29005",
  64483: "29003",
  64484: "29021",
  64485: "29003",
  64489: "29075",
  64490: "29063",
  64491: "29005",
  64492: "29049",
  64493: "29049",
  64494: "29003",
  64497: "29063",
  6450: "9009",
  64501: "29021",
  64503: "29021",
  64504: "29021",
  64505: "29021",
  64506: "29021",
  64507: "29021",
  6451: "9009",
  6455: "9007",
  6457: "9007",
  6460: "9009",
  64601: "29117",
  6461: "9009",
  64620: "29061",
  64622: "29033",
  64623: "29033",
  64624: "29033",
  64625: "29025",
  64628: "29115",
  64630: "29211",
  64631: "29115",
  64632: "29081",
  64633: "29033",
  64635: "29117",
  64636: "29061",
  64637: "29177",
  64638: "29117",
  64640: "29061",
  64642: "29081",
  64643: "29117",
  64644: "29025",
  64647: "29061",
  64648: "29061",
  64649: "29025",
  64650: "29025",
  64651: "29115",
  64653: "29115",
  64656: "29117",
  64657: "29075",
  64658: "29115",
  64659: "29115",
  64661: "29129",
  64664: "29117",
  64667: "29171",
  64668: "29033",
  64670: "29061",
  64671: "29025",
  64673: "29129",
  64674: "29115",
  64679: "29079",
  6468: "9001",
  64682: "29033",
  64683: "29079",
  64686: "29117",
  64688: "29117",
  64689: "29061",
  6469: "9007",
  6470: "9001",
  64701: "29037",
  6471: "9009",
  6472: "9009",
  64720: "29013",
  64724: "29185",
  64725: "29037",
  64726: "29083",
  64728: "29217",
  6473: "9009",
  64730: "29013",
  64733: "29101",
  64734: "29037",
  64735: "29083",
  64738: "29185",
  64739: "29037",
  64740: "29083",
  64741: "29217",
  64742: "29037",
  64743: "29037",
  64744: "29039",
  64746: "29037",
  64747: "29037",
  64748: "29011",
  6475: "9007",
  64750: "29217",
  64752: "29013",
  64755: "29097",
  64756: "29039",
  64759: "29011",
  64761: "29101",
  64762: "29011",
  64763: "29185",
  64767: "29217",
  6477: "9009",
  64770: "29083",
  64771: "29217",
  64772: "29217",
  64776: "29185",
  64778: "29217",
  64779: "29013",
  6478: "9009",
  64780: "29013",
  64783: "29217",
  64784: "29217",
  64788: "29083",
  6479: "9003",
  64790: "29217",
  6480: "9007",
  64801: "29097",
  64804: "29145",
  6481: "9007",
  6482: "9001",
  6483: "9009",
  64831: "29119",
  64832: "29097",
  64834: "29097",
  64836: "29097",
  6484: "9001",
  64840: "29145",
  64842: "29145",
  64843: "29119",
  64844: "29145",
  64847: "29119",
  64848: "29109",
  64850: "29145",
  64854: "29119",
  64855: "29097",
  64856: "29119",
  64859: "29097",
  64861: "29119",
  64862: "29097",
  64863: "29119",
  64865: "29145",
  64866: "29145",
  64867: "29145",
  64870: "29097",
  64873: "29109",
  6488: "9009",
  6489: "9003",
  6492: "9009",
  6498: "9007",
  65001: "29125",
  65010: "29019",
  65011: "29141",
  65013: "29125",
  65014: "29073",
  65016: "29151",
  65017: "29131",
  65018: "29135",
  65020: "29029",
  65023: "29051",
  65024: "29151",
  65025: "29135",
  65026: "29131",
  65032: "29051",
  65035: "29151",
  65037: "29141",
  65039: "29019",
  65040: "29051",
  65041: "29073",
  65043: "29027",
  65047: "29131",
  65048: "29151",
  65049: "29029",
  65051: "29151",
  65052: "29029",
  65053: "29051",
  65054: "29151",
  65058: "29125",
  65059: "29027",
  65061: "29073",
  65063: "29027",
  65064: "29131",
  65065: "29029",
  65066: "29073",
  65067: "29027",
  65068: "29053",
  65072: "29141",
  65074: "29135",
  65075: "29131",
  65076: "29051",
  65078: "29141",
  65079: "29029",
  65080: "29027",
  65081: "29135",
  65082: "29131",
  65083: "29131",
  65084: "29141",
  65085: "29151",
  6510: "9009",
  65101: "29051",
  65109: "29051",
  6511: "9009",
  6512: "9009",
  6513: "9009",
  6514: "9009",
  6515: "9009",
  6516: "9009",
  6517: "9009",
  6518: "9009",
  6519: "9009",
  65201: "29019",
  65202: "29019",
  65203: "29019",
  65231: "29027",
  65233: "29053",
  65237: "29053",
  65239: "29175",
  6524: "9009",
  65240: "29019",
  65243: "29019",
  65244: "29175",
  65247: "29121",
  65248: "29089",
  6525: "9009",
  65251: "29027",
  65254: "29089",
  65255: "29019",
  65256: "29019",
  65257: "29175",
  65259: "29175",
  65260: "29175",
  65262: "29027",
  65263: "29137",
  65264: "29007",
  65265: "29007",
  65270: "29175",
  65274: "29089",
  65275: "29137",
  65276: "29053",
  65279: "29019",
  65281: "29041",
  65284: "29019",
  65287: "29053",
  65301: "29159",
  65321: "29195",
  65322: "29053",
  65323: "29083",
  65324: "29029",
  65325: "29015",
  65326: "29015",
  65330: "29195",
  65332: "29159",
  65333: "29159",
  65334: "29159",
  65336: "29101",
  65337: "29159",
  65338: "29015",
  65340: "29195",
  65345: "29015",
  65347: "29195",
  65348: "29053",
  65349: "29195",
  65350: "29159",
  65351: "29195",
  65355: "29015",
  65360: "29083",
  65401: "29161",
  65438: "29203",
  65439: "29093",
  65440: "29179",
  65441: "29055",
  65444: "29215",
  65452: "29169",
  65453: "29055",
  65459: "29169",
  65462: "29161",
  65466: "29203",
  65470: "29105",
  65483: "29215",
  65486: "29131",
  65534: "29169",
  65535: "29055",
  65536: "29105",
  65542: "29215",
  65548: "29091",
  65550: "29161",
  65552: "29215",
  65555: "29215",
  65556: "29029",
  65559: "29161",
  65560: "29065",
  65565: "29055",
  65566: "29055",
  65567: "29029",
  65570: "29215",
  65571: "29203",
  65582: "29125",
  65583: "29169",
  65584: "29169",
  65588: "29203",
  65590: "29059",
  65591: "29029",
  65601: "29167",
  65604: "29077",
  65605: "29109",
  65606: "29149",
  65608: "29067",
  65609: "29153",
  65610: "29043",
  65611: "29209",
  65612: "29077",
  65613: "29167",
  65614: "29213",
  65616: "29213",
  65617: "29167",
  65619: "29077",
  65622: "29059",
  65624: "29209",
  65625: "29009",
  65626: "29091",
  65627: "29213",
  65631: "29043",
  65632: "29105",
  65633: "29209",
  65634: "29085",
  65635: "29057",
  65637: "29153",
  65640: "29167",
  65641: "29009",
  65644: "29059",
  65646: "29109",
  65647: "29009",
  65648: "29059",
  65649: "29167",
  65650: "29085",
  65652: "29225",
  65653: "29213",
  65655: "29153",
  65656: "29209",
  65658: "29009",
  65661: "29057",
  65662: "29229",
  65663: "29167",
  65667: "29229",
  65668: "29085",
  65669: "29043",
  65672: "29213",
  65674: "29167",
  65679: "29213",
  65680: "29213",
  65681: "29209",
  65682: "29057",
  65685: "29059",
  65686: "29209",
  65689: "29215",
  65690: "29149",
  65692: "29091",
  65704: "29229",
  65705: "29109",
  65706: "29225",
  65707: "29109",
  65708: "29009",
  65710: "29167",
  65711: "29215",
  65712: "29109",
  65713: "29225",
  65714: "29043",
  65717: "29229",
  65721: "29043",
  65722: "29105",
  65723: "29109",
  65724: "29085",
  65725: "29167",
  65732: "29085",
  65733: "29213",
  65734: "29009",
  65737: "29209",
  65738: "29077",
  65739: "29213",
  65740: "29213",
  65742: "29077",
  65745: "29009",
  65746: "29225",
  65747: "29009",
  65752: "29057",
  65753: "29043",
  65756: "29109",
  65757: "29077",
  65759: "29213",
  65760: "29153",
  65761: "29153",
  65762: "29153",
  65764: "29059",
  65767: "29085",
  65769: "29009",
  65770: "29167",
  65771: "29213",
  65772: "29009",
  65773: "29153",
  65774: "29085",
  65775: "29091",
  65778: "29149",
  65779: "29085",
  65781: "29077",
  65785: "29039",
  65786: "29029",
  65787: "29029",
  65789: "29091",
  65790: "29091",
  65791: "29149",
  65793: "29091",
  65802: "29077",
  65803: "29077",
  65804: "29077",
  65806: "29077",
  65807: "29077",
  65809: "29077",
  65810: "29077",
  66002: "20005",
  66006: "20045",
  66007: "20103",
  66008: "20043",
  66010: "20107",
  66012: "20103",
  66013: "20121",
  66014: "20107",
  66015: "20003",
  66016: "20005",
  66018: "20091",
  66020: "20103",
  66021: "20091",
  66023: "20005",
  66024: "20043",
  66025: "20045",
  66026: "20121",
  66030: "20091",
  66032: "20003",
  66033: "20003",
  66035: "20043",
  66039: "20003",
  6604: "9001",
  66040: "20107",
  66041: "20005",
  66042: "20059",
  66043: "20103",
  66044: "20045",
  66046: "20045",
  66047: "20045",
  66048: "20103",
  66049: "20045",
  6605: "9001",
  66050: "20045",
  66052: "20103",
  66053: "20121",
  66054: "20103",
  66056: "20107",
  66058: "20005",
  6606: "9001",
  66060: "20087",
  66061: "20091",
  66062: "20091",
  66064: "20121",
  66066: "20087",
  66067: "20059",
  6607: "9001",
  66070: "20087",
  66071: "20121",
  66072: "20107",
  66073: "20087",
  66075: "20107",
  66076: "20059",
  66078: "20059",
  66079: "20059",
  6608: "9001",
  66080: "20059",
  66083: "20091",
  66085: "20091",
  66086: "20103",
  66087: "20043",
  66088: "20087",
  66090: "29021",
  66091: "20003",
  66092: "20059",
  66093: "20003",
  66094: "20013",
  66095: "20059",
  66097: "20087",
  6610: "9001",
  66101: "20209",
  66102: "20209",
  66103: "20209",
  66104: "20209",
  66105: "20209",
  66106: "20209",
  66109: "20209",
  6611: "9001",
  66111: "20209",
  66112: "20209",
  6612: "9001",
  6614: "9001",
  6615: "9001",
  66202: "20091",
  66203: "20091",
  66204: "20091",
  66205: "20091",
  66206: "20091",
  66207: "20091",
  66208: "20091",
  66209: "20091",
  66210: "20091",
  66211: "20091",
  66212: "20091",
  66213: "20091",
  66214: "20091",
  66215: "20091",
  66216: "20091",
  66217: "20091",
  66218: "20091",
  66219: "20091",
  66220: "20091",
  66221: "20091",
  66223: "20091",
  66224: "20091",
  66226: "20091",
  66227: "20091",
  66401: "20197",
  66402: "20177",
  66403: "20117",
  66404: "20131",
  66406: "20117",
  66407: "20149",
  66408: "20131",
  66409: "20177",
  66411: "20117",
  66413: "20139",
  66414: "20139",
  66415: "20131",
  66416: "20085",
  66417: "20131",
  66418: "20085",
  66419: "20085",
  66422: "20149",
  66423: "20197",
  66424: "20013",
  66425: "20013",
  66427: "20117",
  66428: "20131",
  66429: "20087",
  66431: "20197",
  66432: "20149",
  66434: "20013",
  66436: "20085",
  66438: "20117",
  66439: "20013",
  66440: "20085",
  66441: "20061",
  66449: "20161",
  66451: "20139",
  66502: "20161",
  66503: "20161",
  66507: "20197",
  66508: "20117",
  66509: "20085",
  66510: "20139",
  66512: "20087",
  66514: "20061",
  66515: "20013",
  66516: "20085",
  66517: "20161",
  66520: "20149",
  66521: "20149",
  66523: "20139",
  66524: "20045",
  66526: "20197",
  66528: "20139",
  66531: "20161",
  66532: "20013",
  66533: "20177",
  66534: "20131",
  66535: "20149",
  66536: "20149",
  66537: "20139",
  66538: "20131",
  66539: "20177",
  66540: "20085",
  66542: "20177",
  66543: "20139",
  66544: "20117",
  66546: "20177",
  66547: "20149",
  66548: "20117",
  66549: "20149",
  66550: "20131",
  66552: "20085",
  66554: "20161",
  66603: "20177",
  66604: "20177",
  66605: "20177",
  66606: "20177",
  66607: "20177",
  66608: "20177",
  66609: "20177",
  66610: "20177",
  66611: "20177",
  66612: "20177",
  66614: "20177",
  66615: "20177",
  66616: "20177",
  66617: "20177",
  66618: "20177",
  66619: "20177",
  66701: "20011",
  66710: "20205",
  66711: "20037",
  66712: "20037",
  66713: "20021",
  66716: "20011",
  66717: "20205",
  66720: "20133",
  66724: "20037",
  66725: "20021",
  66732: "20001",
  66733: "20133",
  66734: "20037",
  66735: "20037",
  66736: "20205",
  66738: "20011",
  66739: "20021",
  66740: "20133",
  66743: "20037",
  66748: "20001",
  66749: "20001",
  66751: "20001",
  66753: "20037",
  66754: "20011",
  66755: "20001",
  66756: "20037",
  66757: "20205",
  66762: "20037",
  66763: "20037",
  66767: "20107",
  66769: "20011",
  66770: "20021",
  66771: "20133",
  66772: "20001",
  66773: "20021",
  66776: "20133",
  66777: "20073",
  66779: "20011",
  66780: "20037",
  66781: "20021",
  66783: "20207",
  66801: "20111",
  66830: "20111",
  66833: "20111",
  66834: "20197",
  66835: "20111",
  66839: "20031",
  66840: "20015",
  66842: "20015",
  66843: "20017",
  66845: "20017",
  66846: "20127",
  66849: "20127",
  66851: "20115",
  66852: "20031",
  66853: "20073",
  66854: "20031",
  66856: "20031",
  66857: "20031",
  66858: "20115",
  66860: "20073",
  66861: "20115",
  66864: "20111",
  66865: "20111",
  66866: "20115",
  66868: "20111",
  66869: "20017",
  66871: "20031",
  66872: "20127",
  66873: "20127",
  66901: "20029",
  66933: "20201",
  66935: "20157",
  66936: "20089",
  66937: "20201",
  66938: "20029",
  66939: "20157",
  66940: "20157",
  66941: "20089",
  66943: "20201",
  66944: "20201",
  66945: "20201",
  66948: "20029",
  66949: "20089",
  66951: "20183",
  66952: "20183",
  66953: "20201",
  66956: "20089",
  66958: "20201",
  66959: "20157",
  66962: "20201",
  66964: "20157",
  66966: "20157",
  66967: "20183",
  66968: "20201",
  66970: "20089",
  67001: "20173",
  67002: "20015",
  67003: "20077",
  67004: "20191",
  67005: "20035",
  67008: "20035",
  67009: "20077",
  67010: "20015",
  67013: "20191",
  67016: "20173",
  67017: "20015",
  67019: "20035",
  67020: "20079",
  67022: "20191",
  67024: "20019",
  67025: "20173",
  67026: "20173",
  67028: "20151",
  67029: "20033",
  67030: "20173",
  67031: "20191",
  67035: "20095",
  67037: "20173",
  67038: "20035",
  67039: "20015",
  6704: "9009",
  67042: "20015",
  67045: "20073",
  67047: "20073",
  6705: "9009",
  67050: "20173",
  67051: "20191",
  67052: "20173",
  67053: "20115",
  67054: "20097",
  67056: "20079",
  67057: "20007",
  67058: "20077",
  67059: "20097",
  6706: "9009",
  67060: "20173",
  67061: "20007",
  67062: "20079",
  67063: "20115",
  67065: "20151",
  67066: "20151",
  67067: "20173",
  67068: "20095",
  67070: "20007",
  67073: "20115",
  67074: "20015",
  6708: "9009",
  6710: "9009",
  67101: "20173",
  67103: "20191",
  67104: "20007",
  67106: "20173",
  67107: "20113",
  67108: "20173",
  67109: "20097",
  67110: "20173",
  67111: "20095",
  67112: "20095",
  67114: "20079",
  67117: "20079",
  67118: "20095",
  67119: "20191",
  6712: "9009",
  67120: "20191",
  67123: "20015",
  67124: "20151",
  67127: "20033",
  67133: "20015",
  67134: "20151",
  67135: "20173",
  67137: "20073",
  67138: "20007",
  67140: "20191",
  67142: "20095",
  67144: "20015",
  67146: "20035",
  67147: "20173",
  67149: "20173",
  67151: "20079",
  67152: "20191",
  67154: "20015",
  67155: "20033",
  67156: "20035",
  67159: "20095",
  6716: "9009",
  67202: "20173",
  67203: "20173",
  67204: "20173",
  67205: "20173",
  67206: "20173",
  67207: "20173",
  67208: "20173",
  67209: "20173",
  67210: "20173",
  67211: "20173",
  67212: "20173",
  67213: "20173",
  67214: "20173",
  67215: "20173",
  67216: "20173",
  67217: "20173",
  67218: "20173",
  67219: "20173",
  67220: "20173",
  67223: "20173",
  67226: "20173",
  67227: "20173",
  67228: "20173",
  67230: "20173",
  67232: "20173",
  67235: "20173",
  67301: "20125",
  67330: "20099",
  67332: "20099",
  67333: "20125",
  67335: "20125",
  67336: "20099",
  67337: "20125",
  67340: "20125",
  67341: "20099",
  67342: "20099",
  67344: "20125",
  67346: "20049",
  67347: "20125",
  67349: "20049",
  67351: "20125",
  67352: "20049",
  67353: "20049",
  67354: "20099",
  67356: "20099",
  67357: "20099",
  67360: "20019",
  67361: "20019",
  67401: "20169",
  67410: "20041",
  67416: "20169",
  67420: "20123",
  67422: "20143",
  67423: "20105",
  67425: "20053",
  67427: "20159",
  67428: "20113",
  67430: "20123",
  67431: "20041",
  67432: "20027",
  67436: "20143",
  67437: "20141",
  67439: "20053",
  67441: "20041",
  67442: "20169",
  67443: "20113",
  67444: "20053",
  67445: "20029",
  67446: "20123",
  67447: "20027",
  67448: "20169",
  67449: "20041",
  67450: "20053",
  67451: "20041",
  67454: "20053",
  67455: "20105",
  67456: "20113",
  67457: "20159",
  67458: "20027",
  67459: "20053",
  67460: "20113",
  67464: "20113",
  67466: "20029",
  67467: "20143",
  67468: "20027",
  67470: "20169",
  67473: "20141",
  67474: "20183",
  67480: "20041",
  67481: "20105",
  67483: "20115",
  67484: "20143",
  67485: "20141",
  67487: "20027",
  67490: "20053",
  67491: "20113",
  67492: "20041",
  6750: "9005",
  67501: "20155",
  67502: "20155",
  67505: "20155",
  6751: "9005",
  67510: "20155",
  67511: "20165",
  67514: "20155",
  67516: "20135",
  6752: "9005",
  67520: "20165",
  67521: "20135",
  67522: "20155",
  67523: "20145",
  67524: "20159",
  67525: "20009",
  67526: "20009",
  67529: "20145",
  6753: "9005",
  67530: "20009",
  6754: "9005",
  67543: "20155",
  67544: "20009",
  67545: "20185",
  67546: "20113",
  67547: "20047",
  67548: "20165",
  6755: "9005",
  67550: "20145",
  67552: "20047",
  67554: "20159",
  67556: "20165",
  67557: "20185",
  6756: "9005",
  67560: "20135",
  67561: "20155",
  67563: "20047",
  67564: "20009",
  67565: "20165",
  67566: "20155",
  67567: "20009",
  67568: "20155",
  6757: "9005",
  67570: "20155",
  67572: "20135",
  67574: "20145",
  67575: "20165",
  67576: "20185",
  67578: "20185",
  67579: "20159",
  67581: "20155",
  67583: "20155",
  67584: "20135",
  6759: "9005",
  67601: "20051",
  6762: "9009",
  67621: "20147",
  67622: "20137",
  67623: "20141",
  67625: "20065",
  6763: "9005",
  67631: "20195",
  67632: "20163",
  67634: "20167",
  67637: "20051",
  67638: "20183",
  67640: "20051",
  67642: "20065",
  67643: "20039",
  67644: "20147",
  67645: "20137",
  67646: "20147",
  67647: "20147",
  67648: "20167",
  67649: "20167",
  67650: "20065",
  67651: "20163",
  67653: "20039",
  67654: "20137",
  67656: "20195",
  67657: "20065",
  67661: "20147",
  67663: "20163",
  67664: "20147",
  67665: "20167",
  67669: "20163",
  67671: "20051",
  67672: "20195",
  67675: "20163",
  6770: "9009",
  67701: "20193",
  67730: "20153",
  67731: "20023",
  67732: "20193",
  67735: "20181",
  67736: "20063",
  67737: "20063",
  67738: "20063",
  67739: "20153",
  67740: "20179",
  67741: "20181",
  67743: "20193",
  67745: "20153",
  67748: "20109",
  67749: "20039",
  67751: "20063",
  67752: "20063",
  67753: "20193",
  67756: "20023",
  67757: "20179",
  67758: "20199",
  6776: "9005",
  67761: "20199",
  67762: "20199",
  67764: "20109",
  6777: "9005",
  6778: "9005",
  6779: "9005",
  67801: "20057",
  6782: "9005",
  6783: "9005",
  67831: "20025",
  67834: "20057",
  67835: "20069",
  67837: "20069",
  67838: "20093",
  67839: "20101",
  6784: "9001",
  67841: "20069",
  67842: "20057",
  67844: "20119",
  67846: "20055",
  67849: "20083",
  6785: "9005",
  67850: "20101",
  67851: "20055",
  67853: "20069",
  67854: "20083",
  67855: "20187",
  67859: "20175",
  6786: "9005",
  67860: "20093",
  67861: "20203",
  67862: "20187",
  67863: "20203",
  67864: "20119",
  67865: "20025",
  67867: "20069",
  67869: "20119",
  6787: "9005",
  67870: "20081",
  67871: "20171",
  67876: "20057",
  67877: "20081",
  67878: "20075",
  67879: "20071",
  67880: "20067",
  67882: "20057",
  6790: "9005",
  67901: "20175",
  6791: "9005",
  6793: "9005",
  6794: "9005",
  6795: "9005",
  67950: "20129",
  67951: "20189",
  67952: "20189",
  67954: "20129",
  6796: "9005",
  6798: "9005",
  68002: "31177",
  68003: "31155",
  68004: "31039",
  68005: "31153",
  68007: "31055",
  68008: "31177",
  6801: "9001",
  68014: "31023",
  68015: "31155",
  68016: "31025",
  68017: "31155",
  68018: "31155",
  68019: "31021",
  68020: "31021",
  68022: "31055",
  68023: "31177",
  68025: "31053",
  68028: "31153",
  68029: "31177",
  68030: "31043",
  68031: "31053",
  68033: "31155",
  68034: "31177",
  68036: "31023",
  68037: "31025",
  68038: "31021",
  6804: "9001",
  68040: "31155",
  68041: "31155",
  68044: "31053",
  68045: "31021",
  68046: "31153",
  68047: "31039",
  68048: "31025",
  68050: "31155",
  68055: "31173",
  68057: "31053",
  68058: "31025",
  68059: "31153",
  68061: "31021",
  68062: "31173",
  68063: "31053",
  68064: "31055",
  68065: "31155",
  68066: "31155",
  68067: "31173",
  68069: "31055",
  6807: "9001",
  68070: "31155",
  68071: "31173",
  68073: "31155",
  6810: "9001",
  68102: "31055",
  68104: "31055",
  68105: "31055",
  68106: "31055",
  68107: "31055",
  68108: "31055",
  6811: "9001",
  68110: "31055",
  68111: "31055",
  68112: "31055",
  68114: "31055",
  68116: "31055",
  68117: "31055",
  68118: "31055",
  6812: "9001",
  68122: "31055",
  68123: "31153",
  68124: "31055",
  68127: "31055",
  68128: "31153",
  68130: "31055",
  68131: "31055",
  68132: "31055",
  68133: "31153",
  68134: "31055",
  68135: "31055",
  68136: "31153",
  68137: "31055",
  68138: "31153",
  68142: "31055",
  68144: "31055",
  68147: "31153",
  68152: "31055",
  68154: "31055",
  68157: "31153",
  68164: "31055",
  6820: "9001",
  6824: "9001",
  6825: "9001",
  6830: "9001",
  68301: "31067",
  68303: "31169",
  68304: "31025",
  68305: "31127",
  68307: "31025",
  6831: "9001",
  68310: "31067",
  68313: "31159",
  68314: "31159",
  68316: "31185",
  68317: "31109",
  68318: "31067",
  68319: "31185",
  68320: "31127",
  68321: "31127",
  68322: "31169",
  68323: "31133",
  68324: "31131",
  68325: "31169",
  68326: "31169",
  68327: "31169",
  68328: "31067",
  68329: "31097",
  68330: "31159",
  68331: "31067",
  68332: "31097",
  68333: "31151",
  68335: "31169",
  68336: "31109",
  68337: "31147",
  68338: "31095",
  68339: "31109",
  68340: "31169",
  68341: "31151",
  68342: "31095",
  68343: "31151",
  68344: "31131",
  68345: "31133",
  68346: "31131",
  68347: "31025",
  68348: "31097",
  68349: "31025",
  68350: "31095",
  68351: "31059",
  68352: "31095",
  68354: "31059",
  68355: "31147",
  68357: "31067",
  68358: "31109",
  68359: "31151",
  68360: "31159",
  68361: "31059",
  68364: "31159",
  68365: "31059",
  68366: "31025",
  68367: "31143",
  68368: "31109",
  68370: "31169",
  68371: "31185",
  68372: "31109",
  68376: "31147",
  68377: "31095",
  68378: "31127",
  68381: "31067",
  6840: "9001",
  68401: "31185",
  68402: "31109",
  68404: "31109",
  68405: "31159",
  68406: "31059",
  68407: "31025",
  68409: "31025",
  68410: "31131",
  68413: "31025",
  68414: "31127",
  68415: "31067",
  68416: "31059",
  68417: "31131",
  68418: "31131",
  68419: "31109",
  68420: "31133",
  68421: "31127",
  68422: "31067",
  68423: "31159",
  68424: "31095",
  68428: "31109",
  68430: "31109",
  68431: "31147",
  68433: "31147",
  68434: "31159",
  68436: "31059",
  68437: "31147",
  68439: "31159",
  68440: "31095",
  68441: "31133",
  68442: "31147",
  68443: "31097",
  68446: "31131",
  68447: "31133",
  68448: "31131",
  68450: "31097",
  68453: "31151",
  68454: "31131",
  68455: "31025",
  68456: "31159",
  68457: "31147",
  68460: "31185",
  68461: "31109",
  68462: "31109",
  68463: "31025",
  68464: "31151",
  68465: "31151",
  68466: "31067",
  68467: "31185",
  6850: "9001",
  68502: "31109",
  68503: "31109",
  68504: "31109",
  68505: "31109",
  68506: "31109",
  68507: "31109",
  68508: "31109",
  6851: "9001",
  68510: "31109",
  68512: "31109",
  68516: "31109",
  68517: "31109",
  68520: "31109",
  68521: "31109",
  68522: "31109",
  68523: "31109",
  68524: "31109",
  68526: "31109",
  68527: "31109",
  68528: "31109",
  6853: "9001",
  68532: "31109",
  6854: "9001",
  6855: "9001",
  68601: "31141",
  68620: "31011",
  68621: "31053",
  68622: "31183",
  68623: "31125",
  68624: "31023",
  68626: "31023",
  68627: "31011",
  68628: "31121",
  68629: "31037",
  68631: "31141",
  68632: "31023",
  68633: "31053",
  68634: "31141",
  68635: "31023",
  68636: "31003",
  68637: "31183",
  68638: "31125",
  68640: "31125",
  68641: "31037",
  68642: "31141",
  68643: "31141",
  68644: "31141",
  68647: "31141",
  68648: "31155",
  68649: "31053",
  68651: "31143",
  68652: "31011",
  68653: "31141",
  68654: "31143",
  68655: "31011",
  68658: "31023",
  68660: "31011",
  68661: "31037",
  68662: "31143",
  68663: "31121",
  68664: "31053",
  68665: "31077",
  68666: "31143",
  68669: "31023",
  6870: "9001",
  68701: "31119",
  68710: "31051",
  68713: "31089",
  68714: "31149",
  68715: "31119",
  68716: "31039",
  68717: "31027",
  68718: "31107",
  68720: "31003",
  68722: "31015",
  68723: "31179",
  68725: "31089",
  68726: "31003",
  68727: "31027",
  68728: "31051",
  68729: "31107",
  68730: "31107",
  68731: "31043",
  68732: "31051",
  68733: "31051",
  68735: "31089",
  68736: "31027",
  68739: "31027",
  68740: "31179",
  68741: "31043",
  68743: "31043",
  68745: "31027",
  68746: "31015",
  68747: "31139",
  68748: "31119",
  68752: "31119",
  68755: "31015",
  68756: "31003",
  68757: "31051",
  68758: "31119",
  68760: "31107",
  68761: "31003",
  68763: "31089",
  68764: "31003",
  68765: "31139",
  68766: "31089",
  68767: "31139",
  68768: "31039",
  68769: "31139",
  6877: "9001",
  68770: "31051",
  68771: "31027",
  68773: "31003",
  68774: "31027",
  68776: "31043",
  68777: "31015",
  68778: "31103",
  68779: "31167",
  6878: "9001",
  68780: "31089",
  68781: "31119",
  68783: "31107",
  68784: "31051",
  68785: "31043",
  68786: "31107",
  68787: "31179",
  68788: "31039",
  68790: "31179",
  68791: "31039",
  68792: "31027",
  6880: "9001",
  68801: "31079",
  68803: "31079",
  68810: "31079",
  68812: "31019",
  68813: "31041",
  68814: "31041",
  68815: "31175",
  68816: "31121",
  68817: "31163",
  68818: "31081",
  68820: "31093",
  68822: "31041",
  68823: "31071",
  68824: "31079",
  68825: "31041",
  68826: "31121",
  68827: "31121",
  6883: "9001",
  68831: "31093",
  68832: "31079",
  68834: "31047",
  68835: "31093",
  68836: "31019",
  68838: "31093",
  68840: "31019",
  68841: "31081",
  68842: "31077",
  68843: "31081",
  68844: "31163",
  68845: "31019",
  68846: "31081",
  68847: "31019",
  68850: "31047",
  68852: "31163",
  68853: "31163",
  68854: "31081",
  68855: "31041",
  68856: "31041",
  68858: "31019",
  68859: "31175",
  68860: "31041",
  68862: "31175",
  68863: "31047",
  68864: "31121",
  68865: "31081",
  68866: "31019",
  68869: "31019",
  68870: "31019",
  68871: "31163",
  68872: "31093",
  68873: "31093",
  68874: "31041",
  68875: "31077",
  68876: "31019",
  68878: "31047",
  68879: "31115",
  68882: "31077",
  68883: "31079",
  6890: "9001",
  68901: "31001",
  68920: "31083",
  68922: "31065",
  68924: "31099",
  68925: "31001",
  68926: "31065",
  68927: "31137",
  68928: "31181",
  68930: "31181",
  68932: "31061",
  68933: "31035",
  68934: "31129",
  68935: "31035",
  68936: "31065",
  68937: "31073",
  68938: "31035",
  68939: "31061",
  68940: "31137",
  68941: "31001",
  68942: "31181",
  68943: "31129",
  68944: "31035",
  68945: "31099",
  68947: "31061",
  68948: "31065",
  68949: "31137",
  68950: "31001",
  68952: "31181",
  68955: "31001",
  68956: "31001",
  68957: "31129",
  68958: "31137",
  68959: "31099",
  6896: "9001",
  68960: "31061",
  68961: "31129",
  68966: "31083",
  68967: "31065",
  6897: "9001",
  68970: "31181",
  68971: "31083",
  68972: "31061",
  68973: "31001",
  68974: "31129",
  68978: "31129",
  68979: "31035",
  68980: "31035",
  68981: "31061",
  68982: "31083",
  69001: "31145",
  6901: "9001",
  6902: "9001",
  69020: "31145",
  69021: "31057",
  69022: "31063",
  69023: "31029",
  69024: "31087",
  69025: "31063",
  69028: "31063",
  69029: "31111",
  6903: "9001",
  69030: "31057",
  69032: "31085",
  69033: "31029",
  69034: "31145",
  69038: "31063",
  69040: "31087",
  69043: "31087",
  69044: "31087",
  69045: "31029",
  69046: "31065",
  6905: "9001",
  6906: "9001",
  6907: "9001",
  69101: "31111",
  69120: "31041",
  69121: "31005",
  69122: "31049",
  69123: "31111",
  69125: "31123",
  69127: "31101",
  69128: "31105",
  69129: "31049",
  69130: "31047",
  69131: "31033",
  69133: "31105",
  69134: "31135",
  69138: "31047",
  69140: "31135",
  69141: "31033",
  69143: "31111",
  69145: "31105",
  69146: "31101",
  69147: "31069",
  69148: "31069",
  69149: "31033",
  69150: "31135",
  69151: "31111",
  69152: "31091",
  69153: "31101",
  69154: "31069",
  69155: "31101",
  69156: "31033",
  69162: "31033",
  69163: "31113",
  69165: "31111",
  69166: "31171",
  69168: "31135",
  69169: "31111",
  69201: "31031",
  69210: "31017",
  69211: "31031",
  69214: "31017",
  69217: "31017",
  69218: "31031",
  69301: "31013",
  69334: "31123",
  69336: "31123",
  69337: "31045",
  69339: "31045",
  69341: "31157",
  69343: "31161",
  69345: "31007",
  69346: "31165",
  69347: "31161",
  69348: "31013",
  69350: "31075",
  69352: "31157",
  69356: "31157",
  69357: "31157",
  69358: "31157",
  69360: "31161",
  69361: "31157",
  69367: "31045",
  70001: "22051",
  70002: "22051",
  70003: "22051",
  70005: "22051",
  70006: "22051",
  7001: "34023",
  7002: "34017",
  7003: "34013",
  70030: "22089",
  70031: "22089",
  70032: "22087",
  70036: "22051",
  70037: "22075",
  70039: "22089",
  7004: "34013",
  70040: "22075",
  70041: "22075",
  70043: "22087",
  70047: "22089",
  7005: "34027",
  70052: "22093",
  70053: "22051",
  70056: "22051",
  70057: "22089",
  70058: "22051",
  7006: "34013",
  70062: "22051",
  70065: "22051",
  70067: "22051",
  70068: "22095",
  70070: "22089",
  70071: "22093",
  70072: "22051",
  70075: "22087",
  70079: "22089",
  7008: "34023",
  70080: "22089",
  70083: "22075",
  70084: "22095",
  70085: "22087",
  70086: "22093",
  70087: "22089",
  7009: "34013",
  70090: "22093",
  70092: "22087",
  70094: "22051",
  7010: "34003",
  7011: "34031",
  70112: "22071",
  70113: "22071",
  70114: "22071",
  70115: "22071",
  70116: "22071",
  70117: "22071",
  70118: "22071",
  70119: "22071",
  7012: "34031",
  70121: "22051",
  70122: "22071",
  70123: "22051",
  70124: "22071",
  70125: "22071",
  70126: "22071",
  70127: "22071",
  70128: "22071",
  70129: "22071",
  7013: "34031",
  70130: "22071",
  70131: "22071",
  7014: "34031",
  7016: "34039",
  7017: "34013",
  7018: "34013",
  7020: "34003",
  7021: "34013",
  7022: "34003",
  7023: "34039",
  7024: "34003",
  7026: "34003",
  7027: "34039",
  7028: "34013",
  7029: "34017",
  7030: "34017",
  70301: "22057",
  7031: "34003",
  7032: "34017",
  7033: "34039",
  70339: "22007",
  7034: "34027",
  70342: "22101",
  70343: "22109",
  70344: "22109",
  70345: "22057",
  70346: "22005",
  7035: "34027",
  70354: "22057",
  70355: "22057",
  70357: "22057",
  70358: "22051",
  70359: "22109",
  7036: "34039",
  70360: "22109",
  70363: "22109",
  70364: "22109",
  70372: "22057",
  70373: "22057",
  70374: "22057",
  70377: "22109",
  70380: "22101",
  7039: "34013",
  70390: "22007",
  70392: "22101",
  70394: "22057",
  70395: "22109",
  70397: "22109",
  7040: "34013",
  70401: "22105",
  70403: "22105",
  7041: "34013",
  7042: "34013",
  70420: "22103",
  70422: "22105",
  70426: "22117",
  70427: "22117",
  7043: "34013",
  70431: "22103",
  70433: "22103",
  70435: "22103",
  70437: "22103",
  70438: "22117",
  7044: "34013",
  70442: "22105",
  70443: "22105",
  70444: "22105",
  70445: "22103",
  70446: "22105",
  70447: "22103",
  70448: "22103",
  70449: "22063",
  7045: "34027",
  70450: "22117",
  70452: "22103",
  70454: "22105",
  70455: "22105",
  70456: "22105",
  70458: "22103",
  7046: "34027",
  70460: "22103",
  70461: "22103",
  70462: "22063",
  70466: "22105",
  7047: "34017",
  70471: "22103",
  7050: "34013",
  70501: "22055",
  70503: "22055",
  70506: "22055",
  70507: "22055",
  70508: "22055",
  70510: "22113",
  70512: "22097",
  70515: "22001",
  70517: "22099",
  70518: "22055",
  7052: "34013",
  70520: "22055",
  70525: "22001",
  70526: "22001",
  70528: "22045",
  70529: "22055",
  70532: "22053",
  70533: "22113",
  70535: "22097",
  70538: "22101",
  7054: "34027",
  70542: "22113",
  70543: "22001",
  70544: "22045",
  70546: "22053",
  70548: "22113",
  70549: "22053",
  7055: "34031",
  70554: "22039",
  70555: "22113",
  70560: "22045",
  70563: "22045",
  7057: "34003",
  70570: "22097",
  70577: "22097",
  70578: "22001",
  7058: "34027",
  70581: "22053",
  70582: "22099",
  70583: "22055",
  70584: "22097",
  70586: "22039",
  70589: "22097",
  7059: "34035",
  70591: "22053",
  70592: "22055",
  7060: "34039",
  70601: "22019",
  70605: "22019",
  70607: "22019",
  70611: "22019",
  70615: "22019",
  7062: "34039",
  7063: "34039",
  70630: "22023",
  70631: "22023",
  70632: "22023",
  70633: "22019",
  70634: "22011",
  7064: "34023",
  70643: "22023",
  70645: "22023",
  70647: "22019",
  70648: "22003",
  7065: "34039",
  70652: "22011",
  70653: "22011",
  70655: "22003",
  70656: "22115",
  70657: "22011",
  70658: "22003",
  7066: "34039",
  70661: "22019",
  70663: "22019",
  70665: "22019",
  70668: "22019",
  70669: "22019",
  7067: "34023",
  7068: "34013",
  7069: "34035",
  7070: "34003",
  70706: "22063",
  7071: "34003",
  70710: "22121",
  70711: "22063",
  70714: "22033",
  70719: "22121",
  7072: "34003",
  70722: "22037",
  70723: "22093",
  70725: "22005",
  70726: "22063",
  7073: "34003",
  70730: "22037",
  70733: "22063",
  70734: "22005",
  70737: "22005",
  70739: "22033",
  7074: "34003",
  70744: "22063",
  70748: "22037",
  70749: "22077",
  7075: "34003",
  70750: "22097",
  70754: "22063",
  7076: "34039",
  70760: "22077",
  70762: "22077",
  70763: "22093",
  70764: "22047",
  70767: "22121",
  70769: "22005",
  7077: "34023",
  70770: "22033",
  70774: "22005",
  70775: "22125",
  70776: "22047",
  70777: "22037",
  70778: "22005",
  7078: "34013",
  70783: "22077",
  70785: "22063",
  70788: "22047",
  7079: "34013",
  70791: "22033",
  7080: "34023",
  70802: "22033",
  70805: "22033",
  70806: "22033",
  70807: "22033",
  70808: "22033",
  70809: "22033",
  7081: "34039",
  70810: "22033",
  70811: "22033",
  70812: "22033",
  70814: "22033",
  70815: "22033",
  70816: "22033",
  70817: "22033",
  70818: "22033",
  70819: "22033",
  7082: "34027",
  70820: "22033",
  7083: "34039",
  7086: "34017",
  7087: "34017",
  7088: "34039",
  7090: "34039",
  7092: "34039",
  7093: "34017",
  7094: "34017",
  7095: "34023",
  71001: "22013",
  71003: "22027",
  71004: "22017",
  71006: "22015",
  71007: "22017",
  71008: "22013",
  71016: "22013",
  71019: "22081",
  7102: "34013",
  71023: "22119",
  71028: "22013",
  7103: "34013",
  71031: "22127",
  71033: "22017",
  71037: "22015",
  71038: "22027",
  71039: "22119",
  7104: "34013",
  71040: "22027",
  71043: "22017",
  71044: "22017",
  71047: "22017",
  71049: "22031",
  7105: "34013",
  71051: "22015",
  71052: "22031",
  71055: "22119",
  7106: "34013",
  71060: "22017",
  71061: "22017",
  71064: "22015",
  71067: "22015",
  71068: "22013",
  71069: "22017",
  7107: "34013",
  71070: "22069",
  71075: "22119",
  71078: "22031",
  7108: "34013",
  71082: "22017",
  7109: "34013",
  7110: "34013",
  71101: "22017",
  71103: "22017",
  71104: "22017",
  71105: "22017",
  71106: "22017",
  71107: "22017",
  71108: "22017",
  71109: "22017",
  7111: "34013",
  71110: "22015",
  71111: "22015",
  71112: "22015",
  71115: "22017",
  71118: "22017",
  71119: "22017",
  7112: "34013",
  71129: "22017",
  7114: "34013",
  71201: "22073",
  71202: "22073",
  71203: "22073",
  71220: "22067",
  71222: "22111",
  71223: "22067",
  71225: "22073",
  71227: "22061",
  71229: "22067",
  71232: "22083",
  71234: "22111",
  71235: "22061",
  71237: "22123",
  71238: "22073",
  71241: "22111",
  71245: "22061",
  71251: "22049",
  71254: "22035",
  71256: "22111",
  71260: "22111",
  71261: "22067",
  71263: "22123",
  71264: "22083",
  71266: "22123",
  71268: "22049",
  71269: "22083",
  71270: "22061",
  71275: "22061",
  71277: "22111",
  71280: "22111",
  71282: "22065",
  71286: "22035",
  71291: "22073",
  71292: "22073",
  71295: "22041",
  71301: "22079",
  71302: "22079",
  71303: "22079",
  71322: "22009",
  71325: "22079",
  71326: "22029",
  71327: "22009",
  71328: "22079",
  71334: "22029",
  71341: "22009",
  71342: "22059",
  71343: "22025",
  71346: "22079",
  71350: "22009",
  71351: "22009",
  71353: "22097",
  71356: "22097",
  71357: "22107",
  71358: "22097",
  71360: "22079",
  71362: "22009",
  71366: "22107",
  71371: "22059",
  71373: "22029",
  71375: "22107",
  71403: "22115",
  71404: "22127",
  71405: "22079",
  71409: "22079",
  71411: "22069",
  71417: "22043",
  71418: "22021",
  71419: "22085",
  71422: "22127",
  71423: "22043",
  71424: "22079",
  71429: "22085",
  71430: "22079",
  71433: "22079",
  71435: "22021",
  71438: "22079",
  71439: "22115",
  71441: "22021",
  71446: "22115",
  71447: "22079",
  71449: "22085",
  71454: "22043",
  71457: "22069",
  71461: "22115",
  71463: "22003",
  71465: "22059",
  71466: "22079",
  71467: "22043",
  71472: "22079",
  71473: "22127",
  71479: "22059",
  71483: "22127",
  71485: "22079",
  71486: "22085",
  71601: "5069",
  71602: "5069",
  71603: "5069",
  71635: "5003",
  71638: "5043",
  71639: "5041",
  71640: "5017",
  71646: "5003",
  71652: "5025",
  71653: "5017",
  71654: "5041",
  71655: "5043",
  71660: "5025",
  71665: "5025",
  71667: "5079",
  71670: "5041",
  71671: "5011",
  71675: "5043",
  71701: "5103",
  71720: "5103",
  71725: "5039",
  71730: "5139",
  71742: "5039",
  71743: "5019",
  71744: "5013",
  71753: "5027",
  71762: "5139",
  71763: "5039",
  71764: "5103",
  71801: "5057",
  71822: "5081",
  71826: "5073",
  71827: "5073",
  71832: "5133",
  71833: "5061",
  71837: "5091",
  71845: "5073",
  71851: "5061",
  71852: "5061",
  71854: "5091",
  71859: "5057",
  71860: "5073",
  71861: "5073",
  71901: "5051",
  71909: "5125",
  71913: "5051",
  71921: "5019",
  71923: "5019",
  71929: "5059",
  71933: "5051",
  71937: "5113",
  71943: "5097",
  71949: "5051",
  71953: "5113",
  71956: "5051",
  71957: "5097",
  71958: "5109",
  71959: "5109",
  71964: "5051",
  71968: "5051",
  71971: "5061",
  72002: "5125",
  72005: "5067",
  72006: "5147",
  72007: "5085",
  7201: "34039",
  72010: "5145",
  72011: "5125",
  72012: "5145",
  72013: "5141",
  72015: "5125",
  72016: "5105",
  72019: "5125",
  7202: "34039",
  72020: "5067",
  72021: "5095",
  72022: "5125",
  72023: "5085",
  72024: "5085",
  72025: "5105",
  7203: "34039",
  72031: "5141",
  72032: "5045",
  72034: "5045",
  72039: "5045",
  7204: "34039",
  72042: "5001",
  72044: "5023",
  72045: "5145",
  72046: "5085",
  7205: "34039",
  72058: "5045",
  7206: "34039",
  72065: "5125",
  72067: "5023",
  72070: "5105",
  72073: "5001",
  72076: "5119",
  7208: "34039",
  72081: "5145",
  72084: "5053",
  72086: "5085",
  72087: "5125",
  72088: "5141",
  72101: "5147",
  72102: "5145",
  72103: "5125",
  72104: "5059",
  72106: "5045",
  72110: "5029",
  72111: "5045",
  72112: "5067",
  72113: "5119",
  72114: "5119",
  72116: "5119",
  72117: "5119",
  72118: "5119",
  72120: "5119",
  72121: "5145",
  72122: "5125",
  72125: "5105",
  72126: "5105",
  72127: "5029",
  72131: "5023",
  72132: "5069",
  72135: "5119",
  72136: "5145",
  72137: "5145",
  72142: "5085",
  72143: "5145",
  72150: "5053",
  72153: "5141",
  72160: "5001",
  72167: "5125",
  72173: "5045",
  72176: "5085",
  72201: "5119",
  72202: "5119",
  72204: "5119",
  72205: "5119",
  72206: "5119",
  72207: "5119",
  72209: "5119",
  72210: "5119",
  72211: "5119",
  72212: "5119",
  72223: "5119",
  72227: "5119",
  72301: "5035",
  72315: "5093",
  72324: "5037",
  72326: "5037",
  72331: "5035",
  72333: "5107",
  72335: "5123",
  72342: "5107",
  72347: "5037",
  72348: "5123",
  72354: "5111",
  72355: "5107",
  72360: "5077",
  72364: "5035",
  72365: "5111",
  72366: "5107",
  72368: "5077",
  72372: "5123",
  72374: "5107",
  72386: "5111",
  72390: "5107",
  72396: "5037",
  72401: "5031",
  72404: "5031",
  72411: "5031",
  72416: "5031",
  72417: "5031",
  72419: "5031",
  72422: "5021",
  72432: "5111",
  72433: "5075",
  72434: "5075",
  72436: "5021",
  72437: "5031",
  72442: "5093",
  72443: "5055",
  72444: "5121",
  72447: "5031",
  72450: "5055",
  72454: "5021",
  72455: "5121",
  72461: "5021",
  72471: "5067",
  72472: "5111",
  72473: "5067",
  72476: "5075",
  72479: "5111",
  72482: "5135",
  72501: "5063",
  72512: "5065",
  72513: "5049",
  72519: "5065",
  72521: "5135",
  72529: "5135",
  72530: "5023",
  72531: "5005",
  72537: "5005",
  72542: "5135",
  72543: "5023",
  72544: "5005",
  72554: "5049",
  72556: "5065",
  72560: "5137",
  72562: "5063",
  72568: "5063",
  72576: "5049",
  72579: "5063",
  72581: "5023",
  72583: "5049",
  72601: "5009",
  72611: "5015",
  72616: "5015",
  72619: "5089",
  72626: "5089",
  72631: "5015",
  72632: "5015",
  72633: "5009",
  72634: "5089",
  72635: "5005",
  72638: "5015",
  72641: "5101",
  72642: "5005",
  72644: "5009",
  72645: "5129",
  72650: "5129",
  72651: "5005",
  72653: "5005",
  72658: "5005",
  72661: "5089",
  72662: "5009",
  72668: "5089",
  72675: "5129",
  72685: "5101",
  72687: "5089",
  72701: "5143",
  72703: "5143",
  72704: "5143",
  72712: "5007",
  72713: "5007",
  72714: "5007",
  72715: "5007",
  72718: "5007",
  72719: "5007",
  72722: "5007",
  72727: "5087",
  72730: "5143",
  72732: "5007",
  72734: "5007",
  72736: "5007",
  72738: "5087",
  72740: "5087",
  72744: "5143",
  72745: "5007",
  72751: "5007",
  72753: "5143",
  72756: "5007",
  72758: "5007",
  72761: "5007",
  72762: "5143",
  72764: "5143",
  72768: "5007",
  72773: "5087",
  72774: "5143",
  72801: "5115",
  72802: "5115",
  72821: "5071",
  72823: "5115",
  72830: "5071",
  72833: "5149",
  72834: "5149",
  72835: "5083",
  72837: "5115",
  72840: "5071",
  72842: "5149",
  72843: "5115",
  72845: "5071",
  72846: "5071",
  72847: "5115",
  72855: "5083",
  72858: "5115",
  72863: "5083",
  72901: "5131",
  72903: "5131",
  72904: "5131",
  72908: "5131",
  72916: "5131",
  72921: "5033",
  72923: "5131",
  72927: "5083",
  72932: "5033",
  72933: "5047",
  72936: "5131",
  72937: "5131",
  72940: "5131",
  72941: "5131",
  72943: "5083",
  72944: "5131",
  72946: "5033",
  72947: "5033",
  72949: "5047",
  72952: "5033",
  72956: "5033",
  72958: "5127",
  72959: "5143",
  73002: "40051",
  73003: "40109",
  73004: "40051",
  73005: "40015",
  73006: "40015",
  73007: "40109",
  73008: "40109",
  73009: "40015",
  73010: "40087",
  73011: "40051",
  73012: "40109",
  73013: "40109",
  73014: "40017",
  73015: "40015",
  73016: "40073",
  73017: "40015",
  73018: "40051",
  7302: "34017",
  73020: "40109",
  73021: "40149",
  73024: "40149",
  73025: "40109",
  73026: "40027",
  73027: "40083",
  73028: "40083",
  73029: "40015",
  73030: "40099",
  73034: "40109",
  73036: "40017",
  73038: "40015",
  7304: "34017",
  73040: "40011",
  73041: "40075",
  73042: "40015",
  73044: "40083",
  73045: "40109",
  73047: "40015",
  73048: "40015",
  73049: "40109",
  7305: "34017",
  73051: "40027",
  73052: "40049",
  73053: "40015",
  73054: "40109",
  73055: "40137",
  73057: "40049",
  73059: "40051",
  7306: "34017",
  73061: "40103",
  73062: "40075",
  73063: "40083",
  73064: "40017",
  73065: "40087",
  73067: "40051",
  73068: "40027",
  73069: "40027",
  7307: "34017",
  73071: "40027",
  73072: "40027",
  73073: "40119",
  73074: "40049",
  73075: "40049",
  73077: "40103",
  73078: "40017",
  73079: "40051",
  73080: "40087",
  73082: "40051",
  73084: "40109",
  73086: "40099",
  73089: "40051",
  73090: "40017",
  73092: "40051",
  73093: "40087",
  73095: "40087",
  73096: "40039",
  73098: "40099",
  73099: "40017",
  7310: "34017",
  73102: "40109",
  73103: "40109",
  73104: "40109",
  73105: "40109",
  73106: "40109",
  73107: "40109",
  73108: "40109",
  73109: "40109",
  73110: "40109",
  73111: "40109",
  73112: "40109",
  73114: "40109",
  73115: "40109",
  73116: "40109",
  73117: "40109",
  73118: "40109",
  73119: "40109",
  73120: "40109",
  73121: "40109",
  73122: "40109",
  73127: "40109",
  73128: "40109",
  73129: "40109",
  73130: "40109",
  73131: "40109",
  73132: "40109",
  73134: "40109",
  73135: "40109",
  73139: "40109",
  73141: "40109",
  73142: "40109",
  73149: "40109",
  73150: "40109",
  73151: "40109",
  73159: "40109",
  73160: "40027",
  73162: "40109",
  73165: "40027",
  73169: "40109",
  73170: "40027",
  73173: "40027",
  73179: "40109",
  73401: "40019",
  73430: "40085",
  73432: "40069",
  73433: "40049",
  73434: "40137",
  73438: "40019",
  73439: "40095",
  73441: "40085",
  73442: "40137",
  73443: "40019",
  73446: "40095",
  73447: "40069",
  73448: "40085",
  73449: "40013",
  73450: "40069",
  73453: "40085",
  73455: "40069",
  73456: "40067",
  73458: "40019",
  73459: "40085",
  73460: "40069",
  73461: "40069",
  73463: "40019",
  73481: "40019",
  73491: "40137",
  73501: "40031",
  73505: "40031",
  73507: "40031",
  73521: "40065",
  73526: "40065",
  73527: "40031",
  73528: "40141",
  73529: "40137",
  73530: "40141",
  73531: "40033",
  73532: "40065",
  73533: "40137",
  73537: "40065",
  73538: "40031",
  73540: "40031",
  73541: "40031",
  73542: "40141",
  73543: "40031",
  73544: "40057",
  73546: "40141",
  73547: "40055",
  73548: "40067",
  73549: "40065",
  73550: "40057",
  73552: "40031",
  73554: "40055",
  73557: "40031",
  73559: "40075",
  73560: "40065",
  73562: "40033",
  73564: "40075",
  73565: "40067",
  73566: "40075",
  73567: "40031",
  73568: "40033",
  73569: "40067",
  73570: "40141",
  73572: "40033",
  73573: "40067",
  73601: "40039",
  73620: "40039",
  73625: "40039",
  73626: "40149",
  73628: "40129",
  73632: "40149",
  73639: "40039",
  73641: "40149",
  73644: "40009",
  73645: "40009",
  73646: "40011",
  73647: "40149",
  73650: "40129",
  73651: "40075",
  73654: "40043",
  73655: "40075",
  73661: "40149",
  73662: "40009",
  73663: "40043",
  73664: "40149",
  73667: "40043",
  73669: "40039",
  73673: "40055",
  73701: "40047",
  73703: "40047",
  73716: "40151",
  73717: "40151",
  73718: "40093",
  73720: "40047",
  73722: "40003",
  73724: "40043",
  73726: "40003",
  73727: "40047",
  73728: "40003",
  73729: "40093",
  73730: "40047",
  73734: "40073",
  73735: "40047",
  73736: "40047",
  73737: "40093",
  73738: "40047",
  73739: "40003",
  73741: "40003",
  73742: "40073",
  73744: "40011",
  73747: "40093",
  73749: "40003",
  73750: "40073",
  73753: "40047",
  73754: "40047",
  73755: "40011",
  73759: "40053",
  73760: "40093",
  73761: "40053",
  73762: "40017",
  73763: "40011",
  73766: "40053",
  73768: "40093",
  73771: "40053",
  73772: "40011",
  73773: "40047",
  73801: "40153",
  73832: "40045",
  73834: "40059",
  73838: "40093",
  73840: "40045",
  73841: "40045",
  73842: "40151",
  73843: "40045",
  73844: "40007",
  73848: "40007",
  73852: "40153",
  73853: "40153",
  73857: "40153",
  73858: "40045",
  73859: "40043",
  73860: "40151",
  73931: "40007",
  73932: "40007",
  73933: "40025",
  73938: "40007",
  73939: "40139",
  73942: "40139",
  73945: "40139",
  73947: "40025",
  73949: "40139",
  73950: "40007",
  73951: "40139",
  74002: "40113",
  74003: "40147",
  74006: "40147",
  74008: "40143",
  7401: "34003",
  74010: "40037",
  74011: "40143",
  74012: "40143",
  74014: "40145",
  74015: "40131",
  74016: "40131",
  74017: "40131",
  74019: "40131",
  74020: "40117",
  74021: "40143",
  74022: "40147",
  74023: "40119",
  74026: "40081",
  74027: "40105",
  74028: "40037",
  74029: "40147",
  7403: "34031",
  74030: "40037",
  74032: "40119",
  74033: "40143",
  74035: "40113",
  74036: "40131",
  74037: "40143",
  74038: "40037",
  74039: "40037",
  74041: "40037",
  74044: "40037",
  74045: "40117",
  74047: "40143",
  74048: "40105",
  7405: "34027",
  74051: "40147",
  74052: "40037",
  74053: "40131",
  74054: "40113",
  74055: "40143",
  74056: "40113",
  74058: "40117",
  74059: "40119",
  74061: "40147",
  74062: "40119",
  74063: "40143",
  74066: "40037",
  7407: "34003",
  74070: "40113",
  74072: "40105",
  74073: "40143",
  74074: "40119",
  74075: "40119",
  74079: "40081",
  74080: "40131",
  74081: "40117",
  74083: "40105",
  74085: "40119",
  7410: "34003",
  74104: "40143",
  74105: "40143",
  74106: "40143",
  74107: "40143",
  74108: "40143",
  74110: "40143",
  74112: "40143",
  74114: "40143",
  74115: "40143",
  74116: "40143",
  74119: "40143",
  74120: "40143",
  74126: "40113",
  74127: "40113",
  74128: "40143",
  74129: "40143",
  74130: "40143",
  74131: "40037",
  74132: "40143",
  74133: "40143",
  74134: "40143",
  74135: "40143",
  74136: "40143",
  74137: "40143",
  74145: "40143",
  74146: "40143",
  7416: "34037",
  7417: "34003",
  7418: "34037",
  7419: "34037",
  7420: "34031",
  7421: "34031",
  7422: "34037",
  7423: "34003",
  7424: "34031",
  7430: "34003",
  74301: "40035",
  7432: "34003",
  74330: "40097",
  74331: "40041",
  74332: "40035",
  74333: "40035",
  74337: "40097",
  74338: "40041",
  74339: "40115",
  74342: "40041",
  74343: "40115",
  74344: "40041",
  74346: "40041",
  74347: "40041",
  7435: "34027",
  74350: "40097",
  74352: "40097",
  74354: "40115",
  74358: "40115",
  7436: "34003",
  74361: "40097",
  74363: "40115",
  74364: "40041",
  74365: "40097",
  74366: "40097",
  74367: "40097",
  74369: "40035",
  74370: "40115",
  7438: "34027",
  7439: "34037",
  7440: "34027",
  74401: "40101",
  74403: "40101",
  7442: "34031",
  74421: "40111",
  74422: "40101",
  74425: "40121",
  74426: "40091",
  74427: "40021",
  74428: "40101",
  74429: "40145",
  74430: "40121",
  74432: "40091",
  74434: "40101",
  74435: "40135",
  74436: "40101",
  74437: "40111",
  7444: "34027",
  74441: "40021",
  74442: "40121",
  74445: "40111",
  74447: "40111",
  74450: "40101",
  74451: "40021",
  74452: "40021",
  74454: "40145",
  74455: "40101",
  74457: "40021",
  7446: "34003",
  74462: "40061",
  74463: "40101",
  74464: "40021",
  74467: "40145",
  74469: "40101",
  74470: "40101",
  74471: "40021",
  74472: "40061",
  7450: "34003",
  74501: "40121",
  7452: "34003",
  74523: "40127",
  74525: "40005",
  74531: "40063",
  74533: "40005",
  74536: "40127",
  74538: "40029",
  74546: "40121",
  74547: "40121",
  74552: "40061",
  74553: "40005",
  74554: "40121",
  74555: "40005",
  7456: "34031",
  74560: "40121",
  74561: "40121",
  74563: "40077",
  74569: "40005",
  7457: "34027",
  74570: "40121",
  74571: "40077",
  74572: "40029",
  74574: "40127",
  74578: "40077",
  7458: "34003",
  7460: "34037",
  74601: "40071",
  74604: "40071",
  7461: "34037",
  7462: "34037",
  7463: "34003",
  74630: "40103",
  74631: "40071",
  74632: "40071",
  74633: "40113",
  74637: "40113",
  74640: "40047",
  74641: "40071",
  74643: "40053",
  74644: "40103",
  74647: "40071",
  7465: "34031",
  74650: "40117",
  74651: "40103",
  74652: "40113",
  74653: "40071",
  7470: "34031",
  74701: "40013",
  74723: "40013",
  74726: "40013",
  74727: "40023",
  74728: "40089",
  74729: "40013",
  74730: "40013",
  74731: "40013",
  74733: "40013",
  74734: "40089",
  74735: "40127",
  74736: "40089",
  74740: "40089",
  74741: "40013",
  74743: "40023",
  74745: "40089",
  74748: "40069",
  74756: "40023",
  74759: "40023",
  74764: "40089",
  74766: "40089",
  7480: "34031",
  74801: "40125",
  74804: "40125",
  7481: "34003",
  74820: "40123",
  74824: "40081",
  74825: "40123",
  74826: "40125",
  74827: "40063",
  74829: "40107",
  74831: "40087",
  74832: "40081",
  74833: "40107",
  74834: "40081",
  74839: "40063",
  74840: "40133",
  74845: "40091",
  74848: "40063",
  74849: "40133",
  74850: "40063",
  74851: "40125",
  74852: "40125",
  74854: "40133",
  74855: "40081",
  74856: "40069",
  74857: "40027",
  74859: "40107",
  74860: "40107",
  74864: "40081",
  74865: "40123",
  74867: "40133",
  74868: "40133",
  74869: "40081",
  74871: "40123",
  74872: "40123",
  74873: "40125",
  74875: "40081",
  74878: "40125",
  74880: "40107",
  74881: "40081",
  74883: "40063",
  74884: "40133",
  74901: "40079",
  74902: "40079",
  74930: "40079",
  74931: "40001",
  74932: "40079",
  74936: "40135",
  74937: "40079",
  74939: "40079",
  74940: "40079",
  74941: "40061",
  74944: "40061",
  74948: "40135",
  74951: "40079",
  74953: "40079",
  74954: "40135",
  74955: "40135",
  74956: "40079",
  74957: "40079",
  74959: "40079",
  74960: "40001",
  74962: "40135",
  74963: "40089",
  74964: "40001",
  74965: "40001",
  74966: "40079",
  75001: "48113",
  75002: "48085",
  75006: "48113",
  75007: "48121",
  75009: "48085",
  7501: "34031",
  75010: "48121",
  75013: "48085",
  75019: "48113",
  7502: "34031",
  75020: "48181",
  75021: "48181",
  75022: "48121",
  75023: "48085",
  75024: "48085",
  75025: "48085",
  75028: "48121",
  7503: "34031",
  75032: "48397",
  75033: "48121",
  75034: "48085",
  75035: "48085",
  75036: "48121",
  75038: "48113",
  75039: "48113",
  7504: "34031",
  75040: "48113",
  75041: "48113",
  75042: "48113",
  75043: "48113",
  75044: "48113",
  75048: "48113",
  7505: "34031",
  75050: "48113",
  75051: "48113",
  75052: "48113",
  75054: "48439",
  75056: "48121",
  75057: "48121",
  75058: "48181",
  7506: "34031",
  75060: "48113",
  75061: "48113",
  75062: "48113",
  75063: "48113",
  75065: "48121",
  75067: "48121",
  75068: "48121",
  75069: "48085",
  75070: "48085",
  75071: "48085",
  75072: "48085",
  75074: "48085",
  75075: "48085",
  75076: "48181",
  75077: "48121",
  75078: "48085",
  7508: "34031",
  75080: "48113",
  75081: "48113",
  75082: "48085",
  75087: "48397",
  75088: "48113",
  75089: "48113",
  75090: "48181",
  75092: "48181",
  75093: "48085",
  75094: "48085",
  75098: "48085",
  75102: "48349",
  75103: "48467",
  75104: "48113",
  75105: "48349",
  75109: "48349",
  75110: "48349",
  75114: "48257",
  75115: "48113",
  75116: "48113",
  75117: "48467",
  75119: "48139",
  7512: "34031",
  75124: "48467",
  75125: "48139",
  75126: "48257",
  7513: "34031",
  75134: "48113",
  75135: "48231",
  75137: "48113",
  7514: "34031",
  75140: "48467",
  75141: "48113",
  75142: "48257",
  75143: "48213",
  75144: "48349",
  75146: "48113",
  75147: "48467",
  75148: "48213",
  75149: "48113",
  75150: "48113",
  75152: "48139",
  75153: "48349",
  75154: "48139",
  75155: "48349",
  75156: "48213",
  75158: "48257",
  75159: "48113",
  75160: "48257",
  75161: "48257",
  75163: "48213",
  75165: "48139",
  75166: "48085",
  75167: "48139",
  75169: "48467",
  75172: "48113",
  75173: "48085",
  75180: "48113",
  75181: "48113",
  75182: "48113",
  75189: "48397",
  75201: "48113",
  75202: "48113",
  75203: "48113",
  75204: "48113",
  75205: "48113",
  75206: "48113",
  75208: "48113",
  75209: "48113",
  75210: "48113",
  75211: "48113",
  75212: "48113",
  75214: "48113",
  75215: "48113",
  75216: "48113",
  75217: "48113",
  75218: "48113",
  75219: "48113",
  7522: "34031",
  75220: "48113",
  75223: "48113",
  75224: "48113",
  75225: "48113",
  75226: "48113",
  75227: "48113",
  75228: "48113",
  75229: "48113",
  75230: "48113",
  75231: "48113",
  75232: "48113",
  75233: "48113",
  75234: "48113",
  75235: "48113",
  75236: "48113",
  75237: "48113",
  75238: "48113",
  7524: "34031",
  75240: "48113",
  75241: "48113",
  75243: "48113",
  75244: "48113",
  75246: "48113",
  75248: "48113",
  75249: "48113",
  75252: "48085",
  75253: "48113",
  75254: "48113",
  75287: "48085",
  75401: "48231",
  75402: "48231",
  75407: "48085",
  75409: "48085",
  75410: "48499",
  75411: "48277",
  75413: "48147",
  75414: "48181",
  75416: "48277",
  75417: "48387",
  75418: "48147",
  75420: "48223",
  75421: "48277",
  75422: "48231",
  75423: "48231",
  75424: "48085",
  75426: "48387",
  75428: "48231",
  75431: "48223",
  75432: "48119",
  75433: "48223",
  75435: "48277",
  75436: "48387",
  75437: "48223",
  75438: "48147",
  75439: "48147",
  75440: "48379",
  75442: "48085",
  75446: "48147",
  75447: "48147",
  75449: "48231",
  75451: "48063",
  75452: "48147",
  75453: "48231",
  75454: "48085",
  75455: "48449",
  75457: "48159",
  75459: "48181",
  75460: "48277",
  75462: "48277",
  75468: "48277",
  75471: "48223",
  75472: "48379",
  75473: "48277",
  75474: "48231",
  75475: "48147",
  75476: "48147",
  75477: "48277",
  75478: "48223",
  75479: "48147",
  75480: "48159",
  75481: "48223",
  75482: "48223",
  75486: "48277",
  75488: "48147",
  75490: "48147",
  75491: "48181",
  75492: "48147",
  75494: "48499",
  75495: "48181",
  75496: "48147",
  75497: "48499",
  75501: "48037",
  75503: "48037",
  75551: "48067",
  75554: "48387",
  75555: "48067",
  75558: "48449",
  75559: "48037",
  75561: "48037",
  75563: "48067",
  75567: "48037",
  75568: "48067",
  75569: "48037",
  75570: "48037",
  75571: "48343",
  75572: "48067",
  75573: "48037",
  75574: "48037",
  75601: "48183",
  75602: "48183",
  75603: "48183",
  75604: "48183",
  75605: "48183",
  75630: "48315",
  75631: "48365",
  75633: "48365",
  75638: "48343",
  75639: "48365",
  75640: "48203",
  75642: "48203",
  75643: "48365",
  75644: "48459",
  75645: "48459",
  75647: "48183",
  75650: "48203",
  75651: "48203",
  75652: "48401",
  75654: "48401",
  75656: "48067",
  75657: "48315",
  75661: "48203",
  75662: "48183",
  75667: "48401",
  75668: "48343",
  75669: "48365",
  75670: "48203",
  75672: "48203",
  75681: "48401",
  75683: "48459",
  75684: "48401",
  75686: "48063",
  75687: "48401",
  75691: "48401",
  75692: "48203",
  75693: "48183",
  75701: "48423",
  75702: "48423",
  75703: "48423",
  75704: "48423",
  75705: "48423",
  75706: "48423",
  75707: "48423",
  75708: "48423",
  75709: "48423",
  75750: "48423",
  75751: "48213",
  75752: "48213",
  75754: "48467",
  75755: "48459",
  75756: "48213",
  75757: "48423",
  75758: "48213",
  75760: "48347",
  75762: "48423",
  75763: "48213",
  75765: "48499",
  75766: "48073",
  75770: "48213",
  75771: "48423",
  75773: "48499",
  75778: "48213",
  75783: "48499",
  75784: "48401",
  75785: "48073",
  75789: "48073",
  75790: "48467",
  75791: "48423",
  75792: "48423",
  75801: "48001",
  75803: "48001",
  75831: "48289",
  75833: "48289",
  75835: "48225",
  75838: "48161",
  75839: "48001",
  75840: "48161",
  75844: "48225",
  75845: "48455",
  75846: "48289",
  75847: "48225",
  75851: "48225",
  75852: "48225",
  75853: "48001",
  75855: "48161",
  75859: "48161",
  75860: "48161",
  75861: "48001",
  75862: "48455",
  75901: "48005",
  75904: "48005",
  75925: "48073",
  75926: "48455",
  75929: "48405",
  75930: "48403",
  75931: "48241",
  75932: "48351",
  75933: "48351",
  75935: "48419",
  75936: "48457",
  75937: "48347",
  75938: "48457",
  75939: "48373",
  75941: "48005",
  75943: "48347",
  75944: "48347",
  75946: "48347",
  75948: "48403",
  75949: "48005",
  75951: "48241",
  75954: "48419",
  75956: "48241",
  75959: "48403",
  75960: "48373",
  75961: "48347",
  75964: "48347",
  75965: "48347",
  75966: "48351",
  75968: "48403",
  75969: "48005",
  75972: "48405",
  75973: "48419",
  75974: "48419",
  75975: "48419",
  75976: "48073",
  75979: "48457",
  75980: "48005",
  76001: "48439",
  76002: "48439",
  76005: "48439",
  76006: "48439",
  76008: "48367",
  76009: "48251",
  7601: "34003",
  76010: "48439",
  76011: "48439",
  76012: "48439",
  76013: "48439",
  76014: "48439",
  76015: "48439",
  76016: "48439",
  76017: "48439",
  76018: "48439",
  76020: "48439",
  76021: "48439",
  76022: "48439",
  76023: "48497",
  76028: "48251",
  7603: "34003",
  76031: "48251",
  76033: "48251",
  76034: "48439",
  76035: "48221",
  76036: "48439",
  76039: "48439",
  7604: "34003",
  76040: "48439",
  76043: "48425",
  76044: "48251",
  76048: "48221",
  76049: "48221",
  7605: "34003",
  76050: "48251",
  76051: "48439",
  76052: "48439",
  76053: "48439",
  76054: "48439",
  76055: "48217",
  76058: "48251",
  76059: "48251",
  7606: "34003",
  76060: "48439",
  76063: "48439",
  76064: "48139",
  76065: "48139",
  76066: "48367",
  76067: "48363",
  7607: "34003",
  76070: "48425",
  76071: "48497",
  76073: "48497",
  76078: "48497",
  76082: "48367",
  76084: "48251",
  76085: "48367",
  76086: "48367",
  76087: "48367",
  76088: "48367",
  76092: "48439",
  76093: "48251",
  76102: "48439",
  76103: "48439",
  76104: "48439",
  76105: "48439",
  76106: "48439",
  76107: "48439",
  76108: "48439",
  76109: "48439",
  76110: "48439",
  76111: "48439",
  76112: "48439",
  76114: "48439",
  76115: "48439",
  76116: "48439",
  76117: "48439",
  76118: "48439",
  76119: "48439",
  76120: "48439",
  76123: "48439",
  76126: "48439",
  76131: "48439",
  76132: "48439",
  76133: "48439",
  76134: "48439",
  76135: "48439",
  76137: "48439",
  76140: "48439",
  76148: "48439",
  76164: "48439",
  76177: "48439",
  76179: "48439",
  76180: "48439",
  76182: "48439",
  7620: "34003",
  76201: "48121",
  76205: "48121",
  76207: "48121",
  76208: "48121",
  76209: "48121",
  7621: "34003",
  76210: "48121",
  76225: "48497",
  76226: "48121",
  76227: "48121",
  76228: "48077",
  76230: "48337",
  76233: "48097",
  76234: "48497",
  76239: "48337",
  7624: "34003",
  76240: "48097",
  76244: "48439",
  76245: "48181",
  76247: "48121",
  76248: "48439",
  76249: "48121",
  76250: "48097",
  76251: "48337",
  76252: "48097",
  76255: "48337",
  76258: "48121",
  76259: "48121",
  7626: "34003",
  76262: "48121",
  76265: "48337",
  76266: "48121",
  7627: "34003",
  76270: "48337",
  76271: "48181",
  76272: "48097",
  76273: "48181",
  7628: "34003",
  7630: "34003",
  76301: "48485",
  76302: "48485",
  76305: "48485",
  76306: "48485",
  76308: "48485",
  76309: "48485",
  7631: "34003",
  76310: "48485",
  7632: "34003",
  76351: "48009",
  76354: "48485",
  76360: "48485",
  76363: "48275",
  76365: "48077",
  76366: "48009",
  76367: "48485",
  76370: "48009",
  76371: "48275",
  76372: "48503",
  76374: "48503",
  76379: "48009",
  76380: "48023",
  76384: "48487",
  76389: "48009",
  7640: "34003",
  76401: "48143",
  7641: "34003",
  7642: "34003",
  76424: "48429",
  76426: "48497",
  76429: "48429",
  7643: "34003",
  76430: "48417",
  76431: "48497",
  76432: "48049",
  76433: "48143",
  76435: "48133",
  76436: "48093",
  76437: "48133",
  7644: "34003",
  76442: "48093",
  76443: "48059",
  76444: "48093",
  76446: "48143",
  76448: "48133",
  76449: "48363",
  7645: "34003",
  76450: "48503",
  76453: "48143",
  76454: "48133",
  76455: "48093",
  76457: "48193",
  76458: "48237",
  7646: "34003",
  76462: "48221",
  76463: "48143",
  7647: "34003",
  76470: "48133",
  76471: "48133",
  76472: "48363",
  76475: "48363",
  76476: "48221",
  7648: "34003",
  76483: "48447",
  76484: "48363",
  76486: "48237",
  76487: "48367",
  7649: "34003",
  7650: "34003",
  76501: "48027",
  76502: "48027",
  76504: "48027",
  76511: "48027",
  76513: "48027",
  76518: "48331",
  76519: "48027",
  7652: "34003",
  76520: "48331",
  76522: "48099",
  76524: "48309",
  76525: "48193",
  76527: "48491",
  76528: "48099",
  76530: "48491",
  76531: "48193",
  76534: "48027",
  76537: "48491",
  76538: "48193",
  76539: "48281",
  76541: "48027",
  76542: "48027",
  76543: "48027",
  76548: "48027",
  76549: "48027",
  76550: "48281",
  76554: "48027",
  76556: "48331",
  76557: "48309",
  76559: "48027",
  7656: "34003",
  76561: "48099",
  76566: "48099",
  76567: "48331",
  76569: "48027",
  7657: "34003",
  76570: "48145",
  76571: "48027",
  76574: "48491",
  76577: "48331",
  76578: "48491",
  76579: "48027",
  7660: "34003",
  7661: "34003",
  7662: "34003",
  76621: "48217",
  76622: "48217",
  76624: "48309",
  76626: "48349",
  76627: "48217",
  76629: "48395",
  7663: "34003",
  76630: "48309",
  76631: "48217",
  76633: "48309",
  76634: "48035",
  76636: "48217",
  76637: "48035",
  76638: "48309",
  76639: "48349",
  76640: "48309",
  76641: "48349",
  76642: "48293",
  76643: "48309",
  76645: "48217",
  76648: "48217",
  76649: "48035",
  76651: "48139",
  76652: "48035",
  76653: "48293",
  76655: "48309",
  76656: "48145",
  76657: "48309",
  7666: "34003",
  76660: "48217",
  76661: "48145",
  76664: "48309",
  76665: "48035",
  76666: "48217",
  76667: "48293",
  76670: "48139",
  76671: "48035",
  76673: "48217",
  76676: "48217",
  76679: "48349",
  76681: "48349",
  76682: "48309",
  76687: "48293",
  76689: "48309",
  76690: "48035",
  76691: "48309",
  76692: "48217",
  76693: "48161",
  7670: "34003",
  76701: "48309",
  76704: "48309",
  76705: "48309",
  76706: "48309",
  76707: "48309",
  76708: "48309",
  76710: "48309",
  76711: "48309",
  76712: "48309",
  7675: "34003",
  7676: "34003",
  7677: "34003",
  76801: "48049",
  76802: "48049",
  76821: "48399",
  76823: "48049",
  76825: "48307",
  76827: "48049",
  76834: "48083",
  76837: "48095",
  76844: "48333",
  76849: "48267",
  76853: "48281",
  76856: "48319",
  76857: "48049",
  76859: "48327",
  76861: "48399",
  76866: "48095",
  76877: "48411",
  76878: "48083",
  76890: "48049",
  76901: "48451",
  76903: "48451",
  76904: "48451",
  76905: "48451",
  76932: "48383",
  76935: "48451",
  76941: "48235",
  76943: "48105",
  76950: "48435",
  76951: "48431",
  77002: "48201",
  77003: "48201",
  77004: "48201",
  77005: "48201",
  77006: "48201",
  77007: "48201",
  77008: "48201",
  77009: "48201",
  7701: "34025",
  77011: "48201",
  77012: "48201",
  77013: "48201",
  77014: "48201",
  77015: "48201",
  77016: "48201",
  77017: "48201",
  77018: "48201",
  77019: "48201",
  7702: "34025",
  77020: "48201",
  77021: "48201",
  77022: "48201",
  77023: "48201",
  77024: "48201",
  77025: "48201",
  77026: "48201",
  77027: "48201",
  77028: "48201",
  77029: "48201",
  77030: "48201",
  77031: "48201",
  77032: "48201",
  77033: "48201",
  77034: "48201",
  77035: "48201",
  77036: "48201",
  77037: "48201",
  77038: "48201",
  77039: "48201",
  7704: "34025",
  77040: "48201",
  77041: "48201",
  77042: "48201",
  77043: "48201",
  77044: "48201",
  77045: "48201",
  77046: "48201",
  77047: "48201",
  77048: "48201",
  77049: "48201",
  77050: "48201",
  77051: "48201",
  77053: "48157",
  77054: "48201",
  77055: "48201",
  77056: "48201",
  77057: "48201",
  77058: "48201",
  77059: "48201",
  77060: "48201",
  77061: "48201",
  77062: "48201",
  77063: "48201",
  77064: "48201",
  77065: "48201",
  77066: "48201",
  77067: "48201",
  77068: "48201",
  77069: "48201",
  77070: "48201",
  77071: "48201",
  77072: "48201",
  77073: "48201",
  77074: "48201",
  77075: "48201",
  77076: "48201",
  77077: "48201",
  77078: "48201",
  77079: "48201",
  77080: "48201",
  77081: "48201",
  77082: "48201",
  77083: "48201",
  77084: "48201",
  77085: "48201",
  77086: "48201",
  77087: "48201",
  77088: "48201",
  77089: "48201",
  77090: "48201",
  77091: "48201",
  77092: "48201",
  77093: "48201",
  77094: "48201",
  77095: "48201",
  77096: "48201",
  77098: "48201",
  77099: "48201",
  7711: "34025",
  7712: "34025",
  7716: "34025",
  7717: "34025",
  7718: "34025",
  7719: "34025",
  7720: "34025",
  7721: "34025",
  7722: "34025",
  7723: "34025",
  7724: "34025",
  7726: "34025",
  7727: "34025",
  7728: "34025",
  7730: "34025",
  77301: "48339",
  77302: "48339",
  77303: "48339",
  77304: "48339",
  77306: "48339",
  7731: "34025",
  77316: "48339",
  77318: "48339",
  7732: "34025",
  77320: "48471",
  77327: "48291",
  77328: "48407",
  7733: "34025",
  77331: "48407",
  77335: "48373",
  77336: "48201",
  77338: "48201",
  77339: "48201",
  7734: "34025",
  77340: "48471",
  77345: "48201",
  77346: "48201",
  7735: "34025",
  77351: "48373",
  77354: "48339",
  77355: "48339",
  77356: "48339",
  77357: "48339",
  77358: "48471",
  77359: "48407",
  77360: "48373",
  77362: "48339",
  77363: "48185",
  77364: "48407",
  77365: "48339",
  7737: "34025",
  77371: "48407",
  77372: "48339",
  77373: "48201",
  77374: "48199",
  77375: "48201",
  77376: "48199",
  77377: "48201",
  77378: "48339",
  77379: "48201",
  7738: "34025",
  77380: "48339",
  77381: "48339",
  77382: "48339",
  77384: "48339",
  77385: "48339",
  77386: "48339",
  77388: "48201",
  77389: "48201",
  7739: "34025",
  77396: "48201",
  7740: "34025",
  77401: "48201",
  77406: "48157",
  77407: "48157",
  77414: "48321",
  77415: "48321",
  77417: "48157",
  77418: "48015",
  77419: "48321",
  77420: "48481",
  77422: "48039",
  77423: "48473",
  77426: "48477",
  77429: "48201",
  77430: "48039",
  77433: "48201",
  77434: "48089",
  77435: "48481",
  77437: "48481",
  77441: "48157",
  77442: "48089",
  77443: "48481",
  77444: "48039",
  77445: "48473",
  77447: "48201",
  77448: "48481",
  77449: "48201",
  77450: "48201",
  77451: "48157",
  77453: "48481",
  77455: "48481",
  77456: "48321",
  77457: "48321",
  77459: "48157",
  7746: "34025",
  77461: "48157",
  77464: "48157",
  77465: "48321",
  77468: "48321",
  77469: "48157",
  7747: "34025",
  77470: "48089",
  77471: "48157",
  77474: "48015",
  77475: "48089",
  77476: "48157",
  77477: "48157",
  77478: "48157",
  77479: "48157",
  7748: "34025",
  77480: "48039",
  77482: "48321",
  77483: "48321",
  77484: "48201",
  77485: "48015",
  77486: "48039",
  77488: "48481",
  77489: "48157",
  77493: "48201",
  77494: "48157",
  77498: "48157",
  7750: "34025",
  77502: "48201",
  77503: "48201",
  77504: "48201",
  77505: "48201",
  77506: "48201",
  7751: "34025",
  77510: "48167",
  77511: "48039",
  77514: "48071",
  77515: "48039",
  77517: "48167",
  77518: "48167",
  77519: "48199",
  77520: "48201",
  77521: "48201",
  77523: "48071",
  7753: "34025",
  77530: "48201",
  77531: "48039",
  77532: "48201",
  77534: "48039",
  77535: "48291",
  77536: "48201",
  77539: "48167",
  77541: "48039",
  77545: "48157",
  77546: "48167",
  77547: "48201",
  7755: "34025",
  77550: "48167",
  77551: "48167",
  77554: "48167",
  7756: "34025",
  77560: "48071",
  77562: "48201",
  77563: "48167",
  77564: "48291",
  77565: "48167",
  77566: "48039",
  77568: "48167",
  7757: "34025",
  77571: "48201",
  77573: "48167",
  77575: "48291",
  77577: "48039",
  77578: "48039",
  7758: "34025",
  77581: "48039",
  77583: "48039",
  77584: "48039",
  77585: "48199",
  77586: "48201",
  77587: "48201",
  77590: "48167",
  77591: "48167",
  77597: "48071",
  77598: "48201",
  7760: "34025",
  77611: "48361",
  77612: "48241",
  77614: "48351",
  77615: "48241",
  77619: "48245",
  7762: "34025",
  77622: "48245",
  77625: "48199",
  77627: "48245",
  77630: "48361",
  77632: "48361",
  7764: "34025",
  77640: "48245",
  77642: "48245",
  77650: "48167",
  77651: "48245",
  77656: "48199",
  77657: "48199",
  77659: "48199",
  77661: "48071",
  77662: "48361",
  77663: "48199",
  77664: "48457",
  77665: "48071",
  77701: "48245",
  77702: "48245",
  77703: "48245",
  77705: "48245",
  77706: "48245",
  77707: "48245",
  77708: "48245",
  77713: "48245",
  77801: "48041",
  77802: "48041",
  77803: "48041",
  77807: "48041",
  77808: "48041",
  77830: "48185",
  77831: "48185",
  77833: "48477",
  77835: "48477",
  77836: "48051",
  77837: "48395",
  77840: "48041",
  77845: "48041",
  77853: "48287",
  77856: "48395",
  77857: "48331",
  77859: "48395",
  77861: "48185",
  77863: "48051",
  77864: "48313",
  77865: "48289",
  77868: "48185",
  77871: "48313",
  77872: "48313",
  77873: "48471",
  77878: "48051",
  77879: "48051",
  77880: "48477",
  77901: "48469",
  77904: "48469",
  77905: "48469",
  77951: "48469",
  77954: "48123",
  77957: "48239",
  77962: "48239",
  77963: "48175",
  77964: "48285",
  77968: "48469",
  77974: "48123",
  77975: "48285",
  77978: "48057",
  77979: "48057",
  77982: "48057",
  77983: "48057",
  77984: "48285",
  77990: "48391",
  77994: "48123",
  77995: "48123",
  78002: "48029",
  78003: "48019",
  78004: "48259",
  78005: "48163",
  78006: "48259",
  78009: "48325",
  7801: "34027",
  78010: "48265",
  78013: "48265",
  78014: "48283",
  78015: "48029",
  78016: "48325",
  78017: "48163",
  78019: "48479",
  78022: "48297",
  78023: "48029",
  78024: "48265",
  78025: "48265",
  78026: "48013",
  78028: "48265",
  7803: "34027",
  78039: "48325",
  78040: "48479",
  78041: "48479",
  78043: "48479",
  78045: "48479",
  78046: "48479",
  78052: "48013",
  78055: "48019",
  78056: "48325",
  78057: "48163",
  78058: "48265",
  78059: "48325",
  78061: "48163",
  78063: "48019",
  78064: "48013",
  78065: "48013",
  78066: "48325",
  78069: "48029",
  78070: "48091",
  78071: "48297",
  78073: "48029",
  78076: "48505",
  78101: "48029",
  78102: "48025",
  78108: "48187",
  78109: "48029",
  78112: "48029",
  78113: "48255",
  78114: "48493",
  78117: "48255",
  78118: "48255",
  78119: "48255",
  78121: "48493",
  78123: "48187",
  78124: "48187",
  78130: "48091",
  78132: "48091",
  78133: "48091",
  78140: "48177",
  78141: "48123",
  78147: "48493",
  78148: "48029",
  78151: "48255",
  78152: "48029",
  78154: "48187",
  78155: "48187",
  78159: "48177",
  78160: "48493",
  78161: "48493",
  78163: "48091",
  78164: "48123",
  78201: "48029",
  78202: "48029",
  78203: "48029",
  78204: "48029",
  78205: "48029",
  78207: "48029",
  78208: "48029",
  78209: "48029",
  7821: "34037",
  78210: "48029",
  78211: "48029",
  78212: "48029",
  78213: "48029",
  78214: "48029",
  78215: "48029",
  78216: "48029",
  78217: "48029",
  78218: "48029",
  78219: "48029",
  7822: "34037",
  78220: "48029",
  78221: "48029",
  78222: "48029",
  78223: "48029",
  78224: "48029",
  78225: "48029",
  78226: "48029",
  78227: "48029",
  78228: "48029",
  78229: "48029",
  7823: "34041",
  78230: "48029",
  78231: "48029",
  78232: "48029",
  78233: "48029",
  78237: "48029",
  78238: "48029",
  78239: "48029",
  78240: "48029",
  78242: "48029",
  78244: "48029",
  78245: "48029",
  78247: "48029",
  78248: "48029",
  78249: "48029",
  7825: "34041",
  78250: "48029",
  78251: "48029",
  78252: "48029",
  78253: "48029",
  78254: "48029",
  78255: "48029",
  78256: "48029",
  78257: "48029",
  78258: "48029",
  78259: "48029",
  7826: "34037",
  78260: "48029",
  78261: "48029",
  78263: "48029",
  78264: "48029",
  78266: "48029",
  7827: "34037",
  7828: "34027",
  7830: "34019",
  7832: "34041",
  78332: "48249",
  78336: "48409",
  7834: "34027",
  78341: "48131",
  78343: "48355",
  78344: "48131",
  78349: "48131",
  78355: "48047",
  78357: "48131",
  78358: "48007",
  7836: "34027",
  78361: "48247",
  78362: "48409",
  78363: "48273",
  78368: "48409",
  78369: "48479",
  78370: "48409",
  78372: "48249",
  78373: "48355",
  78374: "48409",
  78377: "48391",
  78379: "48273",
  7838: "34041",
  78380: "48355",
  78382: "48007",
  78383: "48249",
  78384: "48131",
  78385: "48261",
  78387: "48409",
  78389: "48025",
  78390: "48409",
  78393: "48391",
  7840: "34041",
  78401: "48355",
  78402: "48355",
  78404: "48355",
  78405: "48355",
  78407: "48355",
  78408: "48355",
  78409: "48355",
  78410: "48355",
  78411: "48355",
  78412: "48355",
  78413: "48355",
  78414: "48355",
  78415: "48355",
  78416: "48355",
  78417: "48355",
  78418: "48355",
  7843: "34037",
  7847: "34027",
  7848: "34037",
  7849: "34027",
  7850: "34027",
  78501: "48215",
  78503: "48215",
  78504: "48215",
  78516: "48215",
  7852: "34027",
  78520: "48061",
  78521: "48061",
  78526: "48061",
  7853: "34027",
  78537: "48215",
  78538: "48215",
  78539: "48215",
  78541: "48215",
  78542: "48215",
  78543: "48215",
  78549: "48215",
  78550: "48061",
  78552: "48061",
  78557: "48215",
  78558: "48215",
  78559: "48061",
  7856: "34027",
  78560: "48215",
  78561: "48489",
  78562: "48215",
  78566: "48061",
  78569: "48489",
  7857: "34027",
  78570: "48215",
  78572: "48215",
  78573: "48215",
  78574: "48215",
  78575: "48061",
  78576: "48215",
  78577: "48215",
  78578: "48061",
  78579: "48215",
  78580: "48489",
  78582: "48427",
  78583: "48061",
  78586: "48061",
  78589: "48215",
  78593: "48061",
  78594: "48489",
  78595: "48215",
  78596: "48215",
  78597: "48061",
  78598: "48489",
  78599: "48215",
  7860: "34037",
  78602: "48021",
  78605: "48053",
  78606: "48031",
  78607: "48299",
  78608: "48053",
  78609: "48299",
  78610: "48209",
  78611: "48053",
  78612: "48021",
  78613: "48491",
  78614: "48177",
  78615: "48491",
  78616: "48055",
  78617: "48453",
  78619: "48209",
  78620: "48209",
  78621: "48021",
  78622: "48055",
  78623: "48091",
  78624: "48171",
  78626: "48491",
  78628: "48491",
  78629: "48177",
  7863: "34041",
  78631: "48171",
  78632: "48055",
  78633: "48491",
  78634: "48491",
  78636: "48031",
  78638: "48187",
  78639: "48299",
  78640: "48209",
  78641: "48491",
  78642: "48491",
  78643: "48299",
  78644: "48055",
  78645: "48453",
  78648: "48055",
  7865: "34019",
  78650: "48021",
  78652: "48453",
  78653: "48453",
  78654: "48053",
  78655: "48055",
  78656: "48055",
  78657: "48299",
  78659: "48021",
  7866: "34027",
  78660: "48453",
  78661: "48055",
  78662: "48021",
  78663: "48031",
  78664: "48491",
  78665: "48491",
  78666: "48209",
  78669: "48453",
  78671: "48171",
  78672: "48299",
  78676: "48209",
  78681: "48491",
  7869: "34027",
  78701: "48453",
  78702: "48453",
  78703: "48453",
  78704: "48453",
  78705: "48453",
  7871: "34037",
  78717: "48491",
  78719: "48453",
  78721: "48453",
  78722: "48453",
  78723: "48453",
  78724: "48453",
  78725: "48453",
  78726: "48453",
  78727: "48453",
  78728: "48453",
  78729: "48491",
  78730: "48453",
  78731: "48453",
  78732: "48453",
  78733: "48453",
  78734: "48453",
  78735: "48453",
  78736: "48453",
  78737: "48209",
  78738: "48453",
  78739: "48453",
  7874: "34037",
  78741: "48453",
  78744: "48453",
  78745: "48453",
  78746: "48453",
  78747: "48453",
  78748: "48453",
  78749: "48453",
  78750: "48453",
  78751: "48453",
  78752: "48453",
  78753: "48453",
  78754: "48453",
  78756: "48453",
  78757: "48453",
  78758: "48453",
  78759: "48453",
  7876: "34027",
  7878: "34027",
  78801: "48463",
  7882: "34041",
  78827: "48127",
  78829: "48507",
  78830: "48127",
  78832: "48271",
  78833: "48385",
  78834: "48127",
  78838: "48463",
  78839: "48507",
  78840: "48465",
  7885: "34027",
  78850: "48325",
  78852: "48323",
  78860: "48323",
  78861: "48325",
  78872: "48507",
  78873: "48385",
  78877: "48323",
  78881: "48463",
  78886: "48325",
  78932: "48477",
  78933: "48089",
  78934: "48089",
  78935: "48089",
  78940: "48149",
  78941: "48149",
  78942: "48287",
  78943: "48089",
  78945: "48149",
  78946: "48149",
  78947: "48287",
  78948: "48287",
  78950: "48015",
  78953: "48021",
  78954: "48149",
  78956: "48149",
  78957: "48021",
  78959: "48177",
  78962: "48089",
  79005: "48295",
  79007: "48233",
  7901: "34039",
  79013: "48341",
  79014: "48211",
  79015: "48381",
  79018: "48205",
  79019: "48011",
  79022: "48205",
  79027: "48069",
  79029: "48341",
  79031: "48279",
  79034: "48295",
  79035: "48369",
  79036: "48233",
  79039: "48065",
  79040: "48195",
  79041: "48189",
  79042: "48069",
  79044: "48205",
  79045: "48117",
  79046: "48295",
  79052: "48437",
  79059: "48393",
  79061: "48483",
  79064: "48279",
  79065: "48179",
  79068: "48065",
  79070: "48357",
  79072: "48189",
  79078: "48233",
  79079: "48483",
  79080: "48065",
  79081: "48195",
  79083: "48233",
  79084: "48421",
  79086: "48341",
  79088: "48437",
  79095: "48087",
  79096: "48483",
  79097: "48065",
  79098: "48117",
  79101: "48375",
  79102: "48375",
  79103: "48375",
  79104: "48375",
  79106: "48375",
  79107: "48375",
  79108: "48375",
  79109: "48381",
  79110: "48381",
  79118: "48381",
  79119: "48381",
  79121: "48381",
  79124: "48375",
  7920: "34035",
  79201: "48075",
  7921: "34035",
  7922: "34039",
  79226: "48129",
  79227: "48155",
  79229: "48125",
  79235: "48153",
  7924: "34035",
  79241: "48153",
  79244: "48345",
  79245: "48191",
  79248: "48101",
  79250: "48189",
  79252: "48197",
  79255: "48045",
  79256: "48345",
  79257: "48045",
  79261: "48191",
  7927: "34027",
  7928: "34027",
  7930: "34027",
  7931: "34035",
  79311: "48189",
  79312: "48279",
  79313: "48219",
  79316: "48445",
  7932: "34027",
  79322: "48107",
  79323: "48501",
  79325: "48369",
  79329: "48303",
  7933: "34027",
  79331: "48115",
  79336: "48219",
  79339: "48279",
  7934: "34035",
  79343: "48107",
  79345: "48445",
  79346: "48079",
  79347: "48017",
  7935: "34027",
  79350: "48303",
  79355: "48501",
  79356: "48169",
  79357: "48107",
  79358: "48219",
  79359: "48165",
  7936: "34027",
  79360: "48165",
  79363: "48303",
  79364: "48303",
  79366: "48303",
  79367: "48219",
  79370: "48125",
  79371: "48279",
  79372: "48219",
  79373: "48305",
  79379: "48079",
  79381: "48305",
  79382: "48303",
  7940: "34027",
  79401: "48303",
  79403: "48303",
  79404: "48303",
  79407: "48303",
  79410: "48303",
  79411: "48303",
  79412: "48303",
  79413: "48303",
  79414: "48303",
  79415: "48303",
  79416: "48303",
  79423: "48303",
  79424: "48303",
  7945: "34027",
  7946: "34027",
  7950: "34027",
  79501: "48253",
  79502: "48433",
  79504: "48059",
  79506: "48353",
  79508: "48441",
  79510: "48059",
  79511: "48227",
  79512: "48335",
  79520: "48253",
  79521: "48207",
  79525: "48253",
  79526: "48415",
  79527: "48415",
  79529: "48275",
  79530: "48441",
  79532: "48335",
  79536: "48441",
  79541: "48441",
  79543: "48151",
  79544: "48207",
  79545: "48353",
  79546: "48151",
  79547: "48207",
  79549: "48415",
  79553: "48253",
  79556: "48353",
  79561: "48151",
  79562: "48441",
  79563: "48441",
  79565: "48335",
  79567: "48399",
  7960: "34027",
  79601: "48441",
  79602: "48441",
  79603: "48441",
  79605: "48441",
  79606: "48441",
  79701: "48329",
  79703: "48329",
  79705: "48329",
  79706: "48329",
  79707: "48329",
  79713: "48317",
  79714: "48003",
  79719: "48475",
  79720: "48227",
  79731: "48103",
  79734: "48243",
  79735: "48371",
  79739: "48173",
  7974: "34039",
  79742: "48475",
  79745: "48495",
  79756: "48475",
  79758: "48135",
  7976: "34027",
  79761: "48135",
  79762: "48135",
  79763: "48135",
  79764: "48135",
  79765: "48135",
  79766: "48135",
  79772: "48389",
  79782: "48317",
  79788: "48475",
  7980: "34027",
  7981: "34027",
  79821: "48141",
  79830: "48043",
  79835: "48141",
  79836: "48141",
  79838: "48141",
  79842: "48043",
  79843: "48377",
  79845: "48377",
  79848: "48443",
  79849: "48141",
  79852: "48043",
  79853: "48141",
  79855: "48109",
  79901: "48141",
  79902: "48141",
  79903: "48141",
  79904: "48141",
  79905: "48141",
  79907: "48141",
  79911: "48141",
  79912: "48141",
  79915: "48141",
  79922: "48141",
  79924: "48141",
  79925: "48141",
  79927: "48141",
  79928: "48141",
  79930: "48141",
  79932: "48141",
  79934: "48141",
  79935: "48141",
  79936: "48141",
  79938: "48141",
  80002: "8059",
  80003: "8059",
  80004: "8059",
  80005: "8059",
  80007: "8059",
  8001: "34033",
  80010: "8005",
  80011: "8005",
  80012: "8005",
  80013: "8005",
  80014: "8005",
  80015: "8005",
  80016: "8005",
  80017: "8005",
  80018: "8005",
  80019: "8001",
  8002: "34007",
  80020: "8014",
  80021: "8059",
  80022: "8001",
  80023: "8014",
  80026: "8013",
  80027: "8013",
  8003: "34007",
  80030: "8001",
  80031: "8001",
  80033: "8059",
  8004: "34007",
  8005: "34029",
  8006: "34029",
  8007: "34007",
  8008: "34029",
  8009: "34007",
  8010: "34005",
  80101: "8039",
  80102: "8001",
  80103: "8001",
  80104: "8035",
  80105: "8039",
  80106: "8039",
  80107: "8039",
  80108: "8035",
  80109: "8035",
  80110: "8005",
  80111: "8005",
  80112: "8005",
  80113: "8005",
  80116: "8035",
  80117: "8039",
  80118: "8035",
  8012: "34015",
  80120: "8005",
  80121: "8005",
  80122: "8005",
  80123: "8031",
  80124: "8035",
  80125: "8035",
  80126: "8035",
  80127: "8059",
  80128: "8059",
  80129: "8035",
  80130: "8035",
  80131: "8035",
  80132: "8041",
  80133: "8041",
  80134: "8035",
  80135: "8035",
  80136: "8005",
  80137: "8005",
  80138: "8035",
  8014: "34015",
  8015: "34005",
  8016: "34005",
  8019: "34005",
  8020: "34015",
  80202: "8031",
  80203: "8031",
  80204: "8031",
  80205: "8031",
  80206: "8031",
  80207: "8031",
  80209: "8031",
  8021: "34007",
  80210: "8031",
  80211: "8031",
  80212: "8031",
  80214: "8059",
  80215: "8059",
  80216: "8031",
  80218: "8031",
  80219: "8031",
  8022: "34005",
  80220: "8031",
  80221: "8001",
  80222: "8005",
  80223: "8031",
  80224: "8031",
  80226: "8059",
  80227: "8059",
  80228: "8059",
  80229: "8001",
  80230: "8031",
  80231: "8005",
  80232: "8059",
  80233: "8001",
  80234: "8001",
  80235: "8059",
  80236: "8031",
  80237: "8031",
  80238: "8031",
  80239: "8031",
  80241: "8001",
  80246: "8031",
  80247: "8005",
  80249: "8031",
  8026: "34007",
  80260: "8001",
  8027: "34015",
  8028: "34015",
  8029: "34007",
  8030: "34007",
  80301: "8013",
  80302: "8013",
  80303: "8013",
  80304: "8013",
  80305: "8013",
  8031: "34007",
  8032: "34015",
  8033: "34007",
  8034: "34007",
  8035: "34007",
  8036: "34005",
  8037: "34001",
  80401: "8059",
  80403: "8059",
  8041: "34005",
  80420: "8093",
  80421: "8093",
  80422: "8047",
  80424: "8117",
  80427: "8047",
  80428: "8107",
  8043: "34007",
  80432: "8093",
  80433: "8059",
  80435: "8117",
  80436: "8019",
  80438: "8019",
  80439: "8059",
  80440: "8093",
  80442: "8049",
  80443: "8117",
  80444: "8019",
  80446: "8049",
  80447: "8049",
  80448: "8093",
  80449: "8093",
  8045: "34007",
  80451: "8049",
  80452: "8019",
  80453: "8059",
  80454: "8059",
  80455: "8013",
  80456: "8093",
  80457: "8059",
  80459: "8049",
  8046: "34005",
  80461: "8065",
  80465: "8059",
  80466: "8013",
  80467: "8107",
  80468: "8049",
  80469: "8107",
  80470: "8059",
  80474: "8047",
  80475: "8093",
  80476: "8019",
  80478: "8049",
  8048: "34005",
  80481: "8013",
  80482: "8049",
  80483: "8107",
  80487: "8107",
  8049: "34007",
  80498: "8117",
  8050: "34029",
  80501: "8013",
  80503: "8013",
  80504: "8123",
  8051: "34015",
  80510: "8013",
  80512: "8069",
  80513: "8069",
  80514: "8123",
  80515: "8069",
  80516: "8123",
  80517: "8069",
  8052: "34005",
  80520: "8123",
  80521: "8069",
  80524: "8069",
  80525: "8069",
  80526: "8069",
  80528: "8069",
  8053: "34005",
  80530: "8123",
  80532: "8069",
  80534: "8123",
  80535: "8069",
  80536: "8069",
  80537: "8069",
  80538: "8069",
  8054: "34005",
  80540: "8013",
  80542: "8123",
  80543: "8123",
  80545: "8069",
  80547: "8069",
  80549: "8069",
  8055: "34005",
  80550: "8123",
  8056: "34015",
  8057: "34005",
  8059: "34007",
  8060: "34005",
  80601: "8001",
  80602: "8001",
  80603: "8123",
  8061: "34015",
  80610: "8123",
  80611: "8123",
  80612: "8123",
  80615: "8123",
  8062: "34015",
  80620: "8123",
  80621: "8123",
  80623: "8123",
  80624: "8123",
  8063: "34015",
  80631: "8123",
  80634: "8123",
  80640: "8001",
  80642: "8123",
  80643: "8123",
  80644: "8123",
  80645: "8123",
  80648: "8123",
  80649: "8123",
  8065: "34005",
  80650: "8123",
  80651: "8123",
  80652: "8123",
  80653: "8087",
  80654: "8087",
  8066: "34015",
  8067: "34033",
  8068: "34005",
  8069: "34033",
  8070: "34033",
  80701: "8087",
  80705: "8087",
  8071: "34015",
  80720: "8121",
  80722: "8075",
  80723: "8087",
  80726: "8075",
  80727: "8125",
  80728: "8075",
  80729: "8123",
  80731: "8095",
  80733: "8087",
  80734: "8095",
  80735: "8125",
  80736: "8075",
  80737: "8115",
  80741: "8075",
  80743: "8121",
  80744: "8115",
  80747: "8075",
  80749: "8115",
  8075: "34005",
  80751: "8075",
  80758: "8125",
  80759: "8125",
  8077: "34005",
  8078: "34007",
  8079: "34033",
  8080: "34015",
  80802: "8017",
  80805: "8063",
  80807: "8063",
  80808: "8041",
  80809: "8041",
  8081: "34007",
  80810: "8017",
  80813: "8119",
  80814: "8119",
  80815: "8063",
  80816: "8119",
  80817: "8041",
  80819: "8041",
  80820: "8093",
  80821: "8073",
  80822: "8125",
  80824: "8125",
  80825: "8017",
  80827: "8093",
  80828: "8073",
  80829: "8041",
  8083: "34007",
  80830: "8039",
  80831: "8041",
  80832: "8041",
  80834: "8063",
  80835: "8039",
  80836: "8063",
  8084: "34007",
  8085: "34015",
  8086: "34015",
  80860: "8119",
  80863: "8119",
  80864: "8041",
  8087: "34029",
  8088: "34005",
  8089: "34007",
  8090: "34015",
  80903: "8041",
  80904: "8041",
  80905: "8041",
  80906: "8041",
  80907: "8041",
  80908: "8041",
  80909: "8041",
  8091: "34007",
  80910: "8041",
  80911: "8041",
  80915: "8041",
  80916: "8041",
  80917: "8041",
  80918: "8041",
  80919: "8041",
  8092: "34029",
  80920: "8041",
  80921: "8041",
  80922: "8041",
  80923: "8041",
  80924: "8041",
  80925: "8041",
  80926: "8041",
  80927: "8041",
  80928: "8041",
  8093: "34015",
  8094: "34015",
  80951: "8041",
  8096: "34015",
  8097: "34015",
  8098: "34033",
  81001: "8101",
  81003: "8101",
  81004: "8101",
  81005: "8101",
  81006: "8101",
  81007: "8101",
  81008: "8101",
  81019: "8101",
  8102: "34007",
  81022: "8101",
  81023: "8101",
  81025: "8101",
  8103: "34007",
  81030: "8089",
  81036: "8061",
  81039: "8089",
  8104: "34007",
  81040: "8055",
  81044: "8011",
  81047: "8099",
  8105: "34007",
  81050: "8089",
  81052: "8099",
  81054: "8011",
  81055: "8055",
  81057: "8011",
  81058: "8089",
  8106: "34007",
  81062: "8025",
  81063: "8025",
  81067: "8089",
  81069: "8055",
  8107: "34007",
  81076: "8025",
  81077: "8089",
  8108: "34007",
  81082: "8071",
  81089: "8055",
  8109: "34007",
  81091: "8071",
  81092: "8099",
  8110: "34007",
  81101: "8003",
  81120: "8021",
  81122: "8067",
  81123: "8023",
  81125: "8109",
  81130: "8079",
  81131: "8109",
  81132: "8105",
  81133: "8023",
  81137: "8067",
  81140: "8021",
  81141: "8021",
  81143: "8109",
  81144: "8105",
  81146: "8003",
  81147: "8007",
  81149: "8109",
  81151: "8021",
  81152: "8023",
  81154: "8105",
  81155: "8109",
  81201: "8015",
  81210: "8051",
  81211: "8015",
  81212: "8043",
  81220: "8051",
  81223: "8043",
  81224: "8051",
  81225: "8051",
  81226: "8043",
  81230: "8051",
  81233: "8043",
  81235: "8053",
  81236: "8015",
  81237: "8051",
  81240: "8043",
  81241: "8051",
  81242: "8015",
  81243: "8051",
  81251: "8065",
  81252: "8027",
  81253: "8027",
  81301: "8067",
  81303: "8067",
  81320: "8033",
  81321: "8083",
  81323: "8083",
  81324: "8033",
  81326: "8067",
  81327: "8083",
  81328: "8083",
  81331: "8083",
  81332: "8033",
  81401: "8085",
  81403: "8085",
  81410: "8029",
  81413: "8029",
  81415: "8085",
  81416: "8029",
  81418: "8029",
  81419: "8029",
  81422: "8085",
  81423: "8085",
  81424: "8085",
  81425: "8085",
  81426: "8113",
  81427: "8091",
  81428: "8029",
  81430: "8113",
  81431: "8113",
  81432: "8091",
  81433: "8111",
  81434: "8051",
  81435: "8113",
  81501: "8077",
  81503: "8077",
  81504: "8077",
  81505: "8077",
  81506: "8077",
  81507: "8077",
  81520: "8077",
  81521: "8077",
  81523: "8077",
  81524: "8077",
  81525: "8077",
  81526: "8077",
  81527: "8077",
  81601: "8045",
  81611: "8097",
  81615: "8097",
  81620: "8037",
  81621: "8097",
  81623: "8045",
  81624: "8077",
  81625: "8081",
  81630: "8045",
  81631: "8037",
  81632: "8037",
  81635: "8045",
  81637: "8037",
  81639: "8107",
  81641: "8103",
  81642: "8097",
  81643: "8077",
  81647: "8045",
  81648: "8103",
  81650: "8045",
  81652: "8045",
  81654: "8097",
  81656: "8097",
  81657: "8037",
  82001: "56021",
  82007: "56021",
  82009: "56021",
  8201: "34001",
  8202: "34009",
  8203: "34001",
  8204: "34009",
  8205: "34001",
  82053: "56021",
  82054: "56021",
  82055: "56001",
  82070: "56001",
  82072: "56001",
  82082: "56021",
  82083: "56007",
  8210: "34009",
  8212: "34009",
  8215: "34001",
  82201: "56031",
  8221: "34001",
  82210: "56031",
  82212: "56015",
  82213: "56031",
  82214: "56031",
  82221: "56015",
  82223: "56015",
  82225: "56027",
  8223: "34009",
  82240: "56015",
  82244: "56015",
  8225: "34001",
  8226: "34009",
  8230: "34009",
  82301: "56007",
  8232: "34001",
  82327: "56007",
  82331: "56007",
  8234: "34001",
  82401: "56043",
  8241: "34001",
  82410: "56003",
  82411: "56003",
  82412: "56003",
  82414: "56029",
  8242: "34009",
  82420: "56003",
  82421: "56003",
  82426: "56003",
  8243: "34009",
  82431: "56003",
  82432: "56003",
  82433: "56029",
  82435: "56029",
  8244: "34001",
  82441: "56003",
  82442: "56043",
  82443: "56017",
  8247: "34009",
  8248: "34009",
  82501: "56013",
  8251: "34009",
  82513: "56013",
  82515: "56013",
  82516: "56013",
  82520: "56013",
  82523: "56013",
  8260: "34009",
  82601: "56025",
  82604: "56025",
  82609: "56025",
  82633: "56009",
  82636: "56025",
  82637: "56009",
  82639: "56019",
  82643: "56025",
  82649: "56013",
  8270: "34009",
  82701: "56045",
  82710: "56011",
  82712: "56011",
  82716: "56005",
  82718: "56005",
  82720: "56011",
  82721: "56011",
  82727: "56005",
  82729: "56011",
  82730: "56045",
  82732: "56005",
  82801: "56033",
  82832: "56033",
  82833: "56033",
  82834: "56019",
  82835: "56033",
  82836: "56033",
  82838: "56033",
  82839: "56033",
  82842: "56033",
  82901: "56037",
  82922: "56035",
  82923: "56035",
  82930: "56041",
  82932: "56037",
  82933: "56041",
  82935: "56037",
  82937: "56041",
  82939: "56041",
  82941: "56035",
  82943: "56037",
  82945: "56037",
  83001: "56039",
  83013: "56039",
  83014: "56039",
  8302: "34011",
  83025: "56039",
  8310: "34001",
  83101: "56023",
  8311: "34011",
  83110: "56023",
  83113: "56035",
  83115: "56035",
  8312: "34015",
  83127: "56023",
  83128: "56023",
  8317: "34001",
  8318: "34033",
  8319: "34001",
  83201: "16005",
  83202: "16005",
  83204: "16005",
  8321: "34011",
  83210: "16011",
  83211: "16077",
  83214: "16005",
  83217: "16029",
  8322: "34015",
  83221: "16011",
  83226: "16037",
  83227: "16037",
  83228: "16041",
  8323: "34011",
  83234: "16005",
  83236: "16011",
  83237: "16041",
  83241: "16029",
  83245: "16005",
  83246: "16005",
  83250: "16005",
  83251: "16037",
  83252: "16071",
  83254: "16007",
  8326: "34001",
  83261: "16007",
  83262: "16011",
  83263: "16041",
  8327: "34011",
  83271: "16077",
  83272: "16007",
  83274: "16011",
  83276: "16029",
  83278: "16037",
  8328: "34015",
  83287: "16007",
  8330: "34001",
  83301: "16083",
  83311: "16031",
  83313: "16013",
  83314: "16047",
  83316: "16083",
  83318: "16031",
  8332: "34011",
  83320: "16013",
  83323: "16031",
  83325: "16053",
  83327: "16025",
  83328: "16083",
  83330: "16047",
  83332: "16047",
  83333: "16013",
  83334: "16083",
  83335: "16053",
  83336: "16067",
  83338: "16053",
  83340: "16013",
  83341: "16083",
  83342: "16031",
  83344: "16083",
  83346: "16031",
  83347: "16067",
  83349: "16063",
  83350: "16067",
  83352: "16063",
  83353: "16013",
  83355: "16047",
  8340: "34001",
  83401: "16019",
  83402: "16019",
  83404: "16019",
  83406: "16019",
  8341: "34001",
  83414: "56039",
  83420: "16043",
  83422: "16081",
  83423: "16033",
  83424: "16081",
  83427: "16019",
  83428: "16019",
  83429: "16043",
  8343: "34015",
  83431: "16051",
  83434: "16051",
  83436: "16065",
  8344: "34015",
  83440: "16065",
  83442: "16051",
  83443: "16019",
  83444: "16051",
  83445: "16043",
  83448: "16065",
  83449: "16019",
  8345: "34011",
  83450: "16051",
  83451: "16065",
  83452: "16081",
  83454: "16019",
  83455: "16081",
  8346: "34001",
  83462: "16059",
  83466: "16059",
  83467: "16059",
  8349: "34011",
  8350: "34001",
  83501: "16069",
  83522: "16049",
  83523: "16061",
  83524: "16069",
  8353: "34011",
  83530: "16049",
  83535: "16057",
  83536: "16061",
  83537: "16057",
  83539: "16049",
  83540: "16069",
  83541: "16069",
  83543: "16061",
  83544: "16035",
  83545: "16069",
  83546: "16035",
  83549: "16049",
  83553: "16035",
  83554: "16049",
  83555: "16061",
  8360: "34011",
  83604: "16073",
  83605: "16027",
  83607: "16027",
  8361: "34011",
  83611: "16085",
  83612: "16003",
  83615: "16085",
  83616: "16001",
  83617: "16045",
  83619: "16075",
  83622: "16015",
  83623: "16039",
  83624: "16073",
  83626: "16027",
  83627: "16073",
  83628: "16073",
  83629: "16015",
  83631: "16015",
  83633: "16039",
  83634: "16001",
  83637: "16015",
  83638: "16085",
  83639: "16073",
  83641: "16027",
  83642: "16001",
  83644: "16027",
  83646: "16001",
  83647: "16039",
  83650: "16073",
  83651: "16027",
  83654: "16003",
  83655: "16075",
  83656: "16027",
  83660: "16027",
  83661: "16075",
  83669: "16001",
  83670: "16045",
  83672: "16087",
  83676: "16027",
  83686: "16027",
  83687: "16027",
  83702: "16001",
  83703: "16001",
  83704: "16001",
  83705: "16001",
  83706: "16001",
  83709: "16001",
  83712: "16001",
  83713: "16001",
  83714: "16001",
  83716: "16001",
  83801: "16055",
  83803: "16017",
  83804: "16017",
  83805: "16021",
  83806: "16057",
  83809: "16017",
  83810: "16055",
  83811: "16017",
  83813: "16017",
  83814: "16055",
  83815: "16055",
  83821: "16017",
  83822: "16017",
  83823: "16057",
  83825: "16017",
  83832: "16069",
  83833: "16055",
  83835: "16055",
  83836: "16017",
  83837: "16079",
  83839: "16079",
  83840: "16017",
  83841: "16017",
  83843: "16057",
  83845: "16021",
  83846: "16079",
  83847: "16021",
  83848: "16017",
  83849: "16079",
  83850: "16079",
  83851: "16009",
  83852: "16017",
  83854: "16055",
  83855: "16057",
  83856: "16017",
  83857: "16057",
  83858: "16055",
  83860: "16017",
  83861: "16009",
  83864: "16017",
  83867: "16079",
  83868: "16079",
  83869: "16055",
  83871: "16057",
  83872: "16057",
  83873: "16079",
  83876: "16055",
  84003: "49049",
  84004: "49049",
  84005: "49049",
  84006: "49035",
  84009: "49035",
  8401: "34001",
  84010: "49011",
  84013: "49049",
  84014: "49011",
  84015: "49011",
  84017: "49043",
  8402: "34001",
  84020: "49035",
  84021: "49013",
  84023: "49009",
  84025: "49011",
  84028: "49033",
  84029: "49045",
  8403: "34001",
  84032: "49051",
  84033: "49043",
  84036: "49043",
  84037: "49011",
  84038: "49033",
  84040: "49011",
  84041: "49011",
  84042: "49049",
  84043: "49049",
  84044: "49035",
  84045: "49049",
  84046: "49009",
  84047: "49035",
  84049: "49051",
  84050: "49029",
  84054: "49011",
  84055: "49043",
  84057: "49049",
  84058: "49049",
  84059: "49049",
  8406: "34001",
  84060: "49043",
  84061: "49043",
  84062: "49049",
  84064: "49033",
  84065: "49035",
  84066: "49013",
  84067: "49057",
  84070: "49035",
  84071: "49045",
  84074: "49045",
  84075: "49011",
  84078: "49047",
  84081: "49035",
  84082: "49051",
  84084: "49035",
  84086: "49033",
  84087: "49011",
  84088: "49035",
  84092: "49035",
  84093: "49035",
  84094: "49035",
  84095: "49035",
  84096: "49035",
  84097: "49049",
  84098: "49043",
  84101: "49035",
  84102: "49035",
  84103: "49035",
  84104: "49035",
  84105: "49035",
  84106: "49035",
  84107: "49035",
  84108: "49035",
  84109: "49035",
  84111: "49035",
  84115: "49035",
  84116: "49035",
  84117: "49035",
  84118: "49035",
  84119: "49035",
  84120: "49035",
  84121: "49035",
  84123: "49035",
  84124: "49035",
  84128: "49035",
  84129: "49035",
  84301: "49003",
  84302: "49003",
  84305: "49005",
  84306: "49003",
  84307: "49003",
  84309: "49003",
  84310: "49057",
  84311: "49003",
  84312: "49003",
  84314: "49003",
  84315: "49011",
  84317: "49057",
  84318: "49005",
  84319: "49005",
  84320: "49005",
  84321: "49005",
  84324: "49003",
  84325: "49005",
  84326: "49005",
  84327: "49005",
  84328: "49005",
  84330: "49003",
  84332: "49005",
  84333: "49005",
  84335: "49005",
  84337: "49003",
  84338: "49005",
  84339: "49005",
  84340: "49003",
  84341: "49005",
  84401: "49057",
  84403: "49057",
  84404: "49057",
  84405: "49057",
  84414: "49057",
  84501: "49007",
  84511: "49037",
  84513: "49015",
  84518: "49015",
  84521: "49015",
  84522: "49015",
  84523: "49015",
  84525: "49015",
  84526: "49007",
  84528: "49015",
  84532: "49019",
  84535: "49037",
  84537: "49039",
  84601: "49049",
  84604: "49049",
  84606: "49049",
  84620: "49041",
  84624: "49027",
  84629: "49039",
  84631: "49027",
  84633: "49049",
  84635: "49027",
  84637: "49027",
  84645: "49049",
  84647: "49039",
  84648: "49023",
  84651: "49049",
  84652: "49041",
  84653: "49049",
  84654: "49041",
  84655: "49049",
  84657: "49041",
  84660: "49049",
  84663: "49049",
  84664: "49049",
  84701: "49041",
  84710: "49025",
  84711: "49041",
  84713: "49001",
  84719: "49021",
  84720: "49021",
  84721: "49021",
  84722: "49053",
  84724: "49041",
  84725: "49053",
  84730: "49041",
  84737: "49053",
  84738: "49053",
  84739: "49041",
  84741: "49025",
  84745: "49053",
  84746: "49053",
  84747: "49055",
  84751: "49001",
  84752: "49001",
  84754: "49041",
  84757: "49053",
  84758: "49025",
  84759: "49017",
  84761: "49021",
  84762: "49025",
  84763: "49053",
  84765: "49053",
  84767: "49053",
  84770: "49053",
  84774: "49053",
  84779: "49053",
  84780: "49053",
  84781: "49053",
  84782: "49053",
  84783: "49053",
  84790: "49053",
  85003: "4013",
  85004: "4013",
  85006: "4013",
  85007: "4013",
  85008: "4013",
  85009: "4013",
  8501: "34025",
  85012: "4013",
  85013: "4013",
  85014: "4013",
  85015: "4013",
  85016: "4013",
  85017: "4013",
  85018: "4013",
  85019: "4013",
  8502: "34035",
  85020: "4013",
  85021: "4013",
  85022: "4013",
  85023: "4013",
  85024: "4013",
  85027: "4013",
  85028: "4013",
  85029: "4013",
  85031: "4013",
  85032: "4013",
  85033: "4013",
  85034: "4013",
  85035: "4013",
  85037: "4013",
  85040: "4013",
  85041: "4013",
  85042: "4013",
  85043: "4013",
  85044: "4013",
  85045: "4013",
  85048: "4013",
  8505: "34005",
  85050: "4013",
  85051: "4013",
  85053: "4013",
  85054: "4013",
  85083: "4013",
  85085: "4013",
  85086: "4013",
  85087: "4013",
  8510: "34025",
  8511: "34005",
  85118: "4021",
  85119: "4021",
  8512: "34023",
  85120: "4021",
  85122: "4021",
  85123: "4021",
  85128: "4021",
  85131: "4021",
  85132: "4021",
  85135: "4007",
  85137: "4021",
  85138: "4021",
  85139: "4021",
  8514: "34025",
  85140: "4021",
  85142: "4013",
  85143: "4021",
  85145: "4021",
  8515: "34005",
  85173: "4021",
  8518: "34005",
  85192: "4021",
  85193: "4021",
  85194: "4021",
  8520: "34021",
  85201: "4013",
  85202: "4013",
  85203: "4013",
  85204: "4013",
  85205: "4013",
  85206: "4013",
  85207: "4013",
  85208: "4013",
  85209: "4013",
  85210: "4013",
  85212: "4013",
  85213: "4013",
  85215: "4013",
  85224: "4013",
  85225: "4013",
  85226: "4013",
  85233: "4013",
  85234: "4013",
  85248: "4013",
  85249: "4013",
  8525: "34021",
  85250: "4013",
  85251: "4013",
  85253: "4013",
  85254: "4013",
  85255: "4013",
  85257: "4013",
  85258: "4013",
  85259: "4013",
  85260: "4013",
  85262: "4013",
  85263: "4013",
  85264: "4013",
  85266: "4013",
  85268: "4013",
  8527: "34029",
  85281: "4013",
  85282: "4013",
  85283: "4013",
  85284: "4013",
  85286: "4013",
  85295: "4013",
  85296: "4013",
  85297: "4013",
  85298: "4013",
  8530: "34019",
  85301: "4013",
  85302: "4013",
  85303: "4013",
  85304: "4013",
  85305: "4013",
  85306: "4013",
  85307: "4013",
  85308: "4013",
  85310: "4013",
  85320: "4013",
  85321: "4019",
  85322: "4013",
  85323: "4013",
  85324: "4025",
  85325: "4012",
  85326: "4013",
  8533: "34029",
  85331: "4013",
  85332: "4025",
  85335: "4013",
  85336: "4027",
  85337: "4013",
  85338: "4013",
  85339: "4013",
  8534: "34021",
  85340: "4013",
  85342: "4013",
  85344: "4012",
  85345: "4013",
  85346: "4012",
  85348: "4012",
  85349: "4027",
  8535: "34025",
  85350: "4027",
  85351: "4013",
  85353: "4013",
  85354: "4013",
  85355: "4013",
  85356: "4027",
  8536: "34023",
  85361: "4013",
  85362: "4025",
  85363: "4013",
  85364: "4027",
  85365: "4027",
  85367: "4027",
  85373: "4013",
  85374: "4013",
  85375: "4013",
  85377: "4013",
  85378: "4013",
  85379: "4013",
  85381: "4013",
  85382: "4013",
  85383: "4013",
  85387: "4013",
  85388: "4013",
  85390: "4013",
  85392: "4013",
  85395: "4013",
  85396: "4013",
  8540: "34021",
  8542: "34021",
  8550: "34021",
  85501: "4007",
  8551: "34019",
  8553: "34035",
  85532: "4007",
  85534: "4011",
  85539: "4007",
  8554: "34005",
  85541: "4007",
  85543: "4009",
  85544: "4007",
  85545: "4021",
  85546: "4009",
  8555: "34025",
  85552: "4009",
  85553: "4007",
  85554: "4007",
  8558: "34035",
  8559: "34019",
  8560: "34021",
  85602: "4003",
  85603: "4003",
  85605: "4003",
  85606: "4003",
  85607: "4003",
  85610: "4003",
  85611: "4019",
  85614: "4019",
  85615: "4003",
  85616: "4003",
  85617: "4003",
  85619: "4019",
  8562: "34005",
  85621: "4023",
  85622: "4019",
  85623: "4021",
  85624: "4023",
  85625: "4003",
  85629: "4019",
  85630: "4003",
  85631: "4021",
  85632: "4003",
  85635: "4003",
  85637: "4023",
  85638: "4003",
  85640: "4023",
  85641: "4019",
  85643: "4003",
  85645: "4019",
  85646: "4023",
  85648: "4023",
  85650: "4003",
  85653: "4019",
  85658: "4019",
  85701: "4019",
  85704: "4019",
  85705: "4019",
  85706: "4019",
  85710: "4019",
  85711: "4019",
  85712: "4019",
  85713: "4019",
  85714: "4019",
  85715: "4019",
  85716: "4019",
  85718: "4019",
  85719: "4019",
  85730: "4019",
  85735: "4019",
  85736: "4019",
  85737: "4019",
  85739: "4019",
  85741: "4019",
  85742: "4019",
  85743: "4019",
  85745: "4019",
  85746: "4019",
  85747: "4019",
  85748: "4019",
  85749: "4019",
  85750: "4019",
  85755: "4019",
  85756: "4019",
  85757: "4019",
  85901: "4017",
  85920: "4001",
  85924: "4001",
  85925: "4001",
  85927: "4001",
  85928: "4017",
  85929: "4017",
  85931: "4005",
  85932: "4001",
  85933: "4017",
  85935: "4017",
  85936: "4001",
  85937: "4017",
  85938: "4001",
  85939: "4017",
  85940: "4001",
  86001: "4005",
  86004: "4005",
  86005: "4005",
  86015: "4005",
  86017: "4005",
  86018: "4005",
  86021: "4015",
  86022: "4015",
  86024: "4005",
  86025: "4017",
  86038: "4005",
  86040: "4005",
  86046: "4005",
  86047: "4017",
  8608: "34021",
  8609: "34021",
  8610: "34021",
  8611: "34021",
  8618: "34021",
  8619: "34021",
  8620: "34021",
  8628: "34021",
  8629: "34021",
  86301: "4025",
  86303: "4025",
  86305: "4025",
  86314: "4025",
  86315: "4025",
  86320: "4025",
  86322: "4025",
  86323: "4025",
  86324: "4025",
  86325: "4025",
  86326: "4025",
  86327: "4025",
  86332: "4025",
  86333: "4025",
  86334: "4025",
  86335: "4025",
  86336: "4025",
  86337: "4025",
  86343: "4025",
  86351: "4025",
  8638: "34021",
  86401: "4015",
  86403: "4015",
  86404: "4015",
  86406: "4015",
  86409: "4015",
  86413: "4015",
  86426: "4015",
  86429: "4015",
  86432: "4015",
  86436: "4015",
  86438: "4015",
  86440: "4015",
  86441: "4015",
  86442: "4015",
  86444: "4015",
  8648: "34021",
  8690: "34021",
  8691: "34021",
  87001: "35043",
  87002: "35061",
  87004: "35043",
  87006: "35053",
  87008: "35001",
  8701: "34029",
  87010: "35049",
  87013: "35043",
  87015: "35049",
  87016: "35057",
  87020: "35006",
  87024: "35043",
  87025: "35043",
  87031: "35061",
  87035: "35057",
  87036: "35057",
  87041: "35043",
  87042: "35061",
  87043: "35043",
  87044: "35043",
  87046: "35039",
  87047: "35043",
  87048: "35043",
  87056: "35049",
  87059: "35001",
  87068: "35061",
  87083: "35043",
  87102: "35001",
  87104: "35001",
  87105: "35001",
  87106: "35001",
  87107: "35001",
  87108: "35001",
  87109: "35001",
  87110: "35001",
  87111: "35001",
  87112: "35001",
  87113: "35001",
  87114: "35001",
  87120: "35001",
  87121: "35001",
  87122: "35001",
  87123: "35001",
  87124: "35043",
  87144: "35043",
  8720: "34025",
  8721: "34029",
  8722: "34029",
  8723: "34029",
  8724: "34029",
  8730: "34025",
  87301: "35031",
  8731: "34029",
  87317: "35031",
  8732: "34029",
  8733: "34029",
  8734: "34029",
  8735: "34029",
  8736: "34025",
  8738: "34029",
  8739: "34029",
  8740: "34029",
  87401: "35045",
  87402: "35045",
  8741: "34029",
  87410: "35045",
  87412: "35039",
  87413: "35045",
  87415: "35045",
  87416: "35045",
  87417: "35045",
  87418: "35045",
  87419: "35045",
  8742: "34029",
  87421: "35045",
  8750: "34025",
  87501: "35049",
  87505: "35049",
  87506: "35049",
  87507: "35049",
  87508: "35049",
  8751: "34029",
  87510: "35039",
  8752: "34029",
  87520: "35039",
  87522: "35049",
  87529: "35055",
  8753: "34029",
  87532: "35039",
  87535: "35049",
  87538: "35047",
  87540: "35049",
  87544: "35028",
  87547: "35028",
  8755: "34029",
  87552: "35047",
  87556: "35055",
  87557: "35055",
  87558: "35055",
  87560: "35047",
  87562: "35047",
  87567: "35049",
  8757: "34029",
  87571: "35055",
  87579: "35055",
  8758: "34029",
  87580: "35055",
  8759: "34029",
  87701: "35047",
  87710: "35007",
  87740: "35007",
  87742: "35047",
  87745: "35047",
  87801: "35053",
  87821: "35003",
  87827: "35003",
  87829: "35003",
  87830: "35003",
  87901: "35051",
  87930: "35051",
  87931: "35051",
  87935: "35051",
  87936: "35013",
  87937: "35013",
  87940: "35013",
  87941: "35013",
  87942: "35051",
  88001: "35013",
  88005: "35013",
  88007: "35013",
  88008: "35013",
  8801: "34019",
  88011: "35013",
  88012: "35013",
  8802: "34019",
  88020: "35023",
  88021: "35013",
  88022: "35017",
  88023: "35017",
  88024: "35013",
  88026: "35017",
  88028: "35017",
  88030: "35029",
  88032: "35013",
  88038: "35017",
  88039: "35003",
  8804: "34019",
  88041: "35017",
  88042: "35051",
  88043: "35017",
  88044: "35013",
  88045: "35023",
  88046: "35013",
  88047: "35013",
  88048: "35013",
  88049: "35017",
  8805: "34035",
  88053: "35017",
  88061: "35017",
  88063: "35013",
  88065: "35017",
  8807: "34035",
  88072: "35013",
  88081: "35013",
  8809: "34019",
  8810: "34023",
  88101: "35009",
  88119: "35011",
  8812: "34035",
  88130: "35041",
  8816: "34023",
  8817: "34023",
  8820: "34023",
  88201: "35005",
  88203: "35005",
  88210: "35015",
  8822: "34019",
  88220: "35015",
  8823: "34035",
  88230: "35005",
  88231: "35025",
  8824: "34023",
  88240: "35025",
  88242: "35025",
  8825: "34019",
  88252: "35025",
  8826: "34019",
  88260: "35025",
  88267: "35025",
  8827: "34019",
  8828: "34023",
  8829: "34019",
  8830: "34023",
  88301: "35027",
  8831: "34023",
  88310: "35035",
  88312: "35027",
  88316: "35027",
  88317: "35035",
  88318: "35027",
  88325: "35035",
  8833: "34019",
  88337: "35035",
  88339: "35035",
  88341: "35027",
  88345: "35027",
  88346: "35027",
  88347: "35035",
  88348: "35027",
  8835: "34035",
  88352: "35035",
  8836: "34035",
  8837: "34023",
  8840: "34023",
  88401: "35037",
  88415: "35059",
  88416: "35047",
  88426: "35037",
  88434: "35037",
  8844: "34035",
  8846: "34023",
  8848: "34019",
  8850: "34023",
  8852: "34023",
  8853: "34035",
  8854: "34023",
  8857: "34023",
  8859: "34023",
  8861: "34023",
  8863: "34023",
  8865: "34041",
  8867: "34019",
  8869: "34035",
  8872: "34023",
  8873: "34035",
  8876: "34035",
  8879: "34023",
  8880: "34035",
  8882: "34023",
  8884: "34023",
  8886: "34041",
  8887: "34019",
  8889: "34019",
  89001: "32017",
  89002: "32003",
  89004: "32003",
  89005: "32003",
  89007: "32003",
  89008: "32017",
  8901: "34023",
  89011: "32003",
  89012: "32003",
  89014: "32003",
  89015: "32003",
  89018: "32003",
  89019: "32003",
  8902: "34023",
  89021: "32003",
  89025: "32003",
  89027: "32003",
  89029: "32003",
  89030: "32003",
  89031: "32003",
  89032: "32003",
  89034: "32017",
  8904: "34023",
  89040: "32003",
  89042: "32017",
  89043: "32017",
  89044: "32003",
  89046: "32003",
  89048: "32023",
  89049: "32023",
  89052: "32003",
  89060: "32023",
  89061: "32023",
  89074: "32003",
  89081: "32003",
  89084: "32003",
  89085: "32003",
  89086: "32003",
  89101: "32003",
  89102: "32003",
  89103: "32003",
  89104: "32003",
  89106: "32003",
  89107: "32003",
  89108: "32003",
  89109: "32003",
  89110: "32003",
  89113: "32003",
  89115: "32003",
  89117: "32003",
  89118: "32003",
  89119: "32003",
  89120: "32003",
  89121: "32003",
  89122: "32003",
  89123: "32003",
  89124: "32003",
  89128: "32003",
  89129: "32003",
  89130: "32003",
  89131: "32003",
  89134: "32003",
  89135: "32003",
  89138: "32003",
  89139: "32003",
  89141: "32003",
  89142: "32003",
  89143: "32003",
  89144: "32003",
  89145: "32003",
  89146: "32003",
  89147: "32003",
  89148: "32003",
  89149: "32003",
  89155: "32003",
  89156: "32003",
  89158: "32003",
  89161: "32003",
  89166: "32003",
  89169: "32003",
  89178: "32003",
  89179: "32003",
  89183: "32003",
  89301: "32033",
  89310: "32015",
  89316: "32011",
  89318: "32033",
  89402: "32031",
  89403: "32019",
  89406: "32001",
  89408: "32019",
  89410: "32005",
  89411: "32005",
  89413: "32005",
  89415: "32021",
  89419: "32027",
  89423: "32005",
  89428: "32019",
  89429: "32019",
  89430: "32019",
  89431: "32031",
  89433: "32031",
  89434: "32031",
  89436: "32031",
  89439: "32031",
  89440: "32029",
  89441: "32031",
  89442: "32031",
  89444: "32019",
  89445: "32013",
  89447: "32019",
  89448: "32005",
  89449: "32005",
  89451: "32031",
  89460: "32005",
  89501: "32031",
  89502: "32031",
  89503: "32031",
  89506: "32031",
  89508: "32031",
  89509: "32031",
  89510: "32031",
  89511: "32031",
  89512: "32031",
  89519: "32031",
  89521: "32031",
  89523: "32031",
  89701: "32510",
  89703: "32510",
  89704: "32031",
  89705: "32005",
  89706: "32510",
  89801: "32007",
  89815: "32007",
  89820: "32015",
  89822: "32007",
  89835: "32007",
  89883: "32007",
  90001: "6037",
  90002: "6037",
  90003: "6037",
  90004: "6037",
  90005: "6037",
  90006: "6037",
  90007: "6037",
  90008: "6037",
  90010: "6037",
  90011: "6037",
  90012: "6037",
  90013: "6037",
  90014: "6037",
  90015: "6037",
  90016: "6037",
  90017: "6037",
  90018: "6037",
  90019: "6037",
  90020: "6037",
  90021: "6037",
  90022: "6037",
  90023: "6037",
  90024: "6037",
  90025: "6037",
  90026: "6037",
  90027: "6037",
  90028: "6037",
  90029: "6037",
  90031: "6037",
  90032: "6037",
  90033: "6037",
  90034: "6037",
  90035: "6037",
  90036: "6037",
  90037: "6037",
  90038: "6037",
  90039: "6037",
  90040: "6037",
  90041: "6037",
  90042: "6037",
  90043: "6037",
  90044: "6037",
  90045: "6037",
  90046: "6037",
  90047: "6037",
  90048: "6037",
  90049: "6037",
  90056: "6037",
  90057: "6037",
  90058: "6037",
  90059: "6037",
  90061: "6037",
  90062: "6037",
  90063: "6037",
  90064: "6037",
  90065: "6037",
  90066: "6037",
  90067: "6037",
  90068: "6037",
  90069: "6037",
  90077: "6037",
  90094: "6037",
  90201: "6037",
  90210: "6037",
  90211: "6037",
  90212: "6037",
  90220: "6037",
  90221: "6037",
  90222: "6037",
  90230: "6037",
  90232: "6037",
  90240: "6037",
  90241: "6037",
  90242: "6037",
  90245: "6037",
  90247: "6037",
  90248: "6037",
  90249: "6037",
  90250: "6037",
  90254: "6037",
  90255: "6037",
  90260: "6037",
  90262: "6037",
  90265: "6037",
  90266: "6037",
  90270: "6037",
  90272: "6037",
  90274: "6037",
  90275: "6037",
  90277: "6037",
  90278: "6037",
  90280: "6037",
  90290: "6037",
  90291: "6037",
  90292: "6037",
  90293: "6037",
  90301: "6037",
  90302: "6037",
  90303: "6037",
  90304: "6037",
  90305: "6037",
  90401: "6037",
  90402: "6037",
  90403: "6037",
  90404: "6037",
  90405: "6037",
  90501: "6037",
  90502: "6037",
  90503: "6037",
  90504: "6037",
  90505: "6037",
  90601: "6037",
  90602: "6037",
  90603: "6037",
  90604: "6037",
  90605: "6037",
  90606: "6037",
  90620: "6059",
  90621: "6059",
  90623: "6059",
  90630: "6059",
  90631: "6059",
  90638: "6037",
  90640: "6037",
  90650: "6037",
  90660: "6037",
  90670: "6037",
  90680: "6059",
  90701: "6037",
  90703: "6037",
  90704: "6037",
  90706: "6037",
  90710: "6037",
  90712: "6037",
  90713: "6037",
  90715: "6037",
  90716: "6037",
  90717: "6037",
  90720: "6059",
  90723: "6037",
  90731: "6037",
  90732: "6037",
  90740: "6059",
  90742: "6059",
  90744: "6037",
  90745: "6037",
  90746: "6037",
  90755: "6037",
  90802: "6037",
  90803: "6037",
  90804: "6037",
  90805: "6037",
  90806: "6037",
  90807: "6037",
  90808: "6037",
  90810: "6037",
  90813: "6037",
  90814: "6037",
  90815: "6037",
  91001: "6037",
  91006: "6037",
  91007: "6037",
  91008: "6037",
  91010: "6037",
  91011: "6037",
  91016: "6037",
  91020: "6037",
  91024: "6037",
  91030: "6037",
  91040: "6037",
  91042: "6037",
  91101: "6037",
  91103: "6037",
  91104: "6037",
  91105: "6037",
  91106: "6037",
  91107: "6037",
  91108: "6037",
  91201: "6037",
  91202: "6037",
  91203: "6037",
  91204: "6037",
  91205: "6037",
  91206: "6037",
  91207: "6037",
  91208: "6037",
  91210: "6037",
  91214: "6037",
  91301: "6037",
  91302: "6037",
  91303: "6037",
  91304: "6037",
  91306: "6037",
  91307: "6037",
  91311: "6037",
  91316: "6037",
  91320: "6111",
  91321: "6037",
  91324: "6037",
  91325: "6037",
  91326: "6037",
  91331: "6037",
  91335: "6037",
  91340: "6037",
  91342: "6037",
  91343: "6037",
  91344: "6037",
  91345: "6037",
  91350: "6037",
  91351: "6037",
  91352: "6037",
  91354: "6037",
  91355: "6037",
  91356: "6037",
  91360: "6111",
  91361: "6111",
  91362: "6111",
  91364: "6037",
  91367: "6037",
  91377: "6111",
  91381: "6037",
  91384: "6037",
  91387: "6037",
  91390: "6037",
  91401: "6037",
  91402: "6037",
  91403: "6037",
  91405: "6037",
  91406: "6037",
  91411: "6037",
  91423: "6037",
  91436: "6037",
  91501: "6037",
  91502: "6037",
  91504: "6037",
  91505: "6037",
  91506: "6037",
  91601: "6037",
  91602: "6037",
  91604: "6037",
  91605: "6037",
  91606: "6037",
  91607: "6037",
  91701: "6071",
  91702: "6037",
  91706: "6037",
  91708: "6071",
  91709: "6071",
  91710: "6071",
  91711: "6037",
  91722: "6037",
  91723: "6037",
  91724: "6037",
  91730: "6071",
  91731: "6037",
  91732: "6037",
  91733: "6037",
  91737: "6071",
  91739: "6071",
  91740: "6037",
  91741: "6037",
  91744: "6037",
  91745: "6037",
  91746: "6037",
  91748: "6037",
  91750: "6037",
  91752: "6065",
  91754: "6037",
  91755: "6037",
  91759: "6071",
  91761: "6071",
  91762: "6071",
  91763: "6071",
  91764: "6071",
  91765: "6037",
  91766: "6037",
  91767: "6037",
  91768: "6037",
  91770: "6037",
  91773: "6037",
  91775: "6037",
  91776: "6037",
  91780: "6037",
  91784: "6071",
  91786: "6071",
  91789: "6037",
  91790: "6037",
  91791: "6037",
  91792: "6037",
  91801: "6037",
  91803: "6037",
  91901: "6073",
  91902: "6073",
  91905: "6073",
  91906: "6073",
  91910: "6073",
  91911: "6073",
  91913: "6073",
  91914: "6073",
  91915: "6073",
  91916: "6073",
  91917: "6073",
  91932: "6073",
  91934: "6073",
  91935: "6073",
  91941: "6073",
  91942: "6073",
  91945: "6073",
  91948: "6073",
  91950: "6073",
  91962: "6073",
  91963: "6073",
  91977: "6073",
  91978: "6073",
  92003: "6073",
  92004: "6025",
  92007: "6073",
  92008: "6073",
  92009: "6073",
  92010: "6073",
  92011: "6073",
  92014: "6073",
  92019: "6073",
  92020: "6073",
  92021: "6073",
  92024: "6073",
  92025: "6073",
  92026: "6073",
  92027: "6073",
  92028: "6073",
  92029: "6073",
  92036: "6073",
  92037: "6073",
  92040: "6073",
  92054: "6073",
  92056: "6073",
  92057: "6073",
  92058: "6073",
  92059: "6073",
  92060: "6073",
  92061: "6073",
  92064: "6073",
  92065: "6073",
  92066: "6073",
  92067: "6073",
  92069: "6073",
  92070: "6073",
  92071: "6073",
  92075: "6073",
  92078: "6073",
  92081: "6073",
  92082: "6073",
  92083: "6073",
  92084: "6073",
  92086: "6073",
  92091: "6073",
  92101: "6073",
  92102: "6073",
  92103: "6073",
  92104: "6073",
  92105: "6073",
  92106: "6073",
  92107: "6073",
  92108: "6073",
  92109: "6073",
  92110: "6073",
  92111: "6073",
  92113: "6073",
  92114: "6073",
  92115: "6073",
  92116: "6073",
  92117: "6073",
  92118: "6073",
  92119: "6073",
  92120: "6073",
  92121: "6073",
  92122: "6073",
  92123: "6073",
  92124: "6073",
  92126: "6073",
  92127: "6073",
  92128: "6073",
  92129: "6073",
  92130: "6073",
  92131: "6073",
  92139: "6073",
  92154: "6073",
  92173: "6073",
  92201: "6065",
  92203: "6065",
  92210: "6065",
  92211: "6065",
  92220: "6065",
  92223: "6065",
  92225: "6065",
  92227: "6025",
  92230: "6065",
  92231: "6025",
  92233: "6025",
  92234: "6065",
  92236: "6065",
  92239: "6065",
  92240: "6065",
  92241: "6065",
  92242: "6071",
  92243: "6025",
  92249: "6025",
  92250: "6025",
  92251: "6025",
  92252: "6071",
  92253: "6065",
  92254: "6065",
  92256: "6071",
  92258: "6065",
  92260: "6065",
  92262: "6065",
  92264: "6065",
  92268: "6071",
  92270: "6065",
  92274: "6065",
  92276: "6065",
  92277: "6071",
  92282: "6065",
  92284: "6071",
  92285: "6071",
  92301: "6071",
  92305: "6071",
  92307: "6071",
  92308: "6071",
  92311: "6071",
  92313: "6071",
  92314: "6071",
  92315: "6071",
  92316: "6071",
  92317: "6071",
  92320: "6065",
  92321: "6071",
  92322: "6071",
  92324: "6071",
  92325: "6071",
  92327: "6071",
  92333: "6071",
  92335: "6071",
  92336: "6071",
  92337: "6071",
  92339: "6071",
  92341: "6071",
  92342: "6071",
  92344: "6071",
  92345: "6071",
  92346: "6071",
  92347: "6071",
  92352: "6071",
  92354: "6071",
  92356: "6071",
  92358: "6071",
  92359: "6071",
  92363: "6071",
  92365: "6071",
  92368: "6071",
  92371: "6071",
  92372: "6071",
  92373: "6071",
  92374: "6071",
  92376: "6071",
  92377: "6071",
  92378: "6071",
  92382: "6071",
  92385: "6071",
  92386: "6071",
  92391: "6071",
  92392: "6071",
  92394: "6071",
  92395: "6071",
  92397: "6071",
  92398: "6071",
  92399: "6071",
  92401: "6071",
  92404: "6071",
  92405: "6071",
  92407: "6071",
  92408: "6071",
  92410: "6071",
  92411: "6071",
  92501: "6065",
  92503: "6065",
  92504: "6065",
  92505: "6065",
  92506: "6065",
  92507: "6065",
  92508: "6065",
  92509: "6065",
  92530: "6065",
  92532: "6065",
  92536: "6065",
  92539: "6065",
  92543: "6065",
  92544: "6065",
  92545: "6065",
  92548: "6065",
  92549: "6065",
  92551: "6065",
  92553: "6065",
  92555: "6065",
  92557: "6065",
  92561: "6065",
  92562: "6065",
  92563: "6065",
  92567: "6065",
  92570: "6065",
  92571: "6065",
  92582: "6065",
  92583: "6065",
  92584: "6065",
  92585: "6065",
  92586: "6065",
  92587: "6065",
  92590: "6065",
  92591: "6065",
  92592: "6065",
  92595: "6065",
  92596: "6065",
  92602: "6059",
  92603: "6059",
  92604: "6059",
  92606: "6059",
  92610: "6059",
  92612: "6059",
  92614: "6059",
  92617: "6059",
  92618: "6059",
  92620: "6059",
  92624: "6059",
  92625: "6059",
  92626: "6059",
  92627: "6059",
  92629: "6059",
  92630: "6059",
  92637: "6059",
  92646: "6059",
  92647: "6059",
  92648: "6059",
  92649: "6059",
  92651: "6059",
  92653: "6059",
  92655: "6059",
  92656: "6059",
  92657: "6059",
  92660: "6059",
  92661: "6059",
  92662: "6059",
  92663: "6059",
  92672: "6059",
  92673: "6059",
  92675: "6059",
  92676: "6059",
  92677: "6059",
  92679: "6059",
  92683: "6059",
  92688: "6059",
  92691: "6059",
  92692: "6059",
  92694: "6059",
  92701: "6059",
  92703: "6059",
  92704: "6059",
  92705: "6059",
  92706: "6059",
  92707: "6059",
  92708: "6059",
  92780: "6059",
  92782: "6059",
  92801: "6059",
  92802: "6059",
  92804: "6059",
  92805: "6059",
  92806: "6059",
  92807: "6059",
  92808: "6059",
  92821: "6059",
  92823: "6059",
  92831: "6059",
  92832: "6059",
  92833: "6059",
  92835: "6059",
  92840: "6059",
  92841: "6059",
  92843: "6059",
  92844: "6059",
  92845: "6059",
  92860: "6065",
  92861: "6059",
  92865: "6059",
  92866: "6059",
  92867: "6059",
  92868: "6059",
  92869: "6059",
  92870: "6059",
  92879: "6065",
  92880: "6065",
  92881: "6065",
  92882: "6065",
  92883: "6065",
  92886: "6059",
  92887: "6059",
  93001: "6111",
  93003: "6111",
  93004: "6111",
  93010: "6111",
  93012: "6111",
  93013: "6083",
  93015: "6111",
  93021: "6111",
  93022: "6111",
  93023: "6111",
  93030: "6111",
  93033: "6111",
  93035: "6111",
  93036: "6111",
  93040: "6111",
  93041: "6111",
  93060: "6111",
  93063: "6111",
  93065: "6111",
  93066: "6111",
  93067: "6083",
  93101: "6083",
  93103: "6083",
  93105: "6083",
  93108: "6083",
  93109: "6083",
  93110: "6083",
  93111: "6083",
  93117: "6083",
  93201: "6107",
  93202: "6031",
  93203: "6029",
  93204: "6031",
  93205: "6029",
  93206: "6029",
  93210: "6019",
  93212: "6031",
  93215: "6029",
  93218: "6107",
  93219: "6107",
  93221: "6107",
  93222: "6029",
  93223: "6107",
  93224: "6029",
  93225: "6029",
  93226: "6029",
  93227: "6107",
  93230: "6031",
  93234: "6019",
  93235: "6107",
  93238: "6029",
  93239: "6031",
  93240: "6029",
  93241: "6029",
  93242: "6019",
  93243: "6029",
  93244: "6107",
  93245: "6031",
  93247: "6107",
  93249: "6029",
  93250: "6029",
  93252: "6029",
  93254: "6079",
  93255: "6029",
  93256: "6107",
  93257: "6107",
  93261: "6107",
  93263: "6029",
  93265: "6107",
  93266: "6031",
  93267: "6107",
  93268: "6029",
  93270: "6107",
  93271: "6107",
  93272: "6107",
  93274: "6107",
  93277: "6107",
  93280: "6029",
  93283: "6029",
  93285: "6029",
  93286: "6107",
  93291: "6107",
  93292: "6107",
  93301: "6029",
  93304: "6029",
  93305: "6029",
  93306: "6029",
  93307: "6029",
  93308: "6029",
  93309: "6029",
  93311: "6029",
  93312: "6029",
  93313: "6029",
  93314: "6029",
  93401: "6079",
  93402: "6079",
  93405: "6079",
  93420: "6079",
  93422: "6079",
  93424: "6079",
  93426: "6079",
  93427: "6083",
  93428: "6079",
  93430: "6079",
  93432: "6079",
  93433: "6079",
  93434: "6083",
  93436: "6083",
  93440: "6083",
  93441: "6083",
  93442: "6079",
  93444: "6079",
  93445: "6079",
  93446: "6079",
  93449: "6079",
  93450: "6053",
  93451: "6053",
  93452: "6079",
  93453: "6079",
  93454: "6083",
  93455: "6083",
  93458: "6083",
  93460: "6083",
  93461: "6079",
  93463: "6083",
  93465: "6079",
  93501: "6029",
  93505: "6029",
  93510: "6037",
  93513: "6027",
  93514: "6027",
  93516: "6029",
  93517: "6051",
  93518: "6029",
  93523: "6029",
  93526: "6027",
  93527: "6029",
  93529: "6051",
  93531: "6029",
  93532: "6037",
  93534: "6037",
  93535: "6037",
  93536: "6037",
  93541: "6051",
  93543: "6037",
  93544: "6037",
  93545: "6027",
  93546: "6051",
  93550: "6037",
  93551: "6037",
  93552: "6037",
  93553: "6037",
  93555: "6029",
  93560: "6029",
  93561: "6029",
  93562: "6071",
  93591: "6037",
  93601: "6039",
  93602: "6019",
  93604: "6039",
  93608: "6019",
  93609: "6019",
  93610: "6039",
  93611: "6019",
  93612: "6019",
  93614: "6039",
  93615: "6107",
  93616: "6019",
  93618: "6107",
  93619: "6019",
  93620: "6047",
  93621: "6019",
  93622: "6019",
  93623: "6043",
  93625: "6019",
  93626: "6019",
  93628: "6019",
  93630: "6019",
  93631: "6019",
  93634: "6019",
  93635: "6047",
  93636: "6039",
  93637: "6039",
  93638: "6039",
  93640: "6019",
  93641: "6019",
  93643: "6039",
  93644: "6039",
  93645: "6039",
  93646: "6019",
  93647: "6107",
  93648: "6019",
  93650: "6019",
  93651: "6019",
  93653: "6039",
  93654: "6019",
  93656: "6019",
  93657: "6019",
  93660: "6019",
  93662: "6019",
  93664: "6019",
  93665: "6047",
  93667: "6019",
  93668: "6019",
  93669: "6039",
  93675: "6019",
  93701: "6019",
  93702: "6019",
  93703: "6019",
  93704: "6019",
  93705: "6019",
  93706: "6019",
  93710: "6019",
  93711: "6019",
  93720: "6019",
  93721: "6019",
  93722: "6019",
  93723: "6019",
  93725: "6019",
  93726: "6019",
  93727: "6019",
  93728: "6019",
  93730: "6019",
  93737: "6019",
  93901: "6053",
  93905: "6053",
  93906: "6053",
  93907: "6053",
  93908: "6053",
  93920: "6053",
  93921: "6053",
  93923: "6053",
  93924: "6053",
  93925: "6053",
  93926: "6053",
  93927: "6053",
  93930: "6053",
  93932: "6053",
  93933: "6053",
  93940: "6053",
  93950: "6053",
  93953: "6053",
  93955: "6053",
  93960: "6053",
  93962: "6053",
  94002: "6081",
  94005: "6081",
  94010: "6081",
  94014: "6081",
  94015: "6081",
  94018: "6081",
  94019: "6081",
  94020: "6081",
  94022: "6085",
  94024: "6085",
  94025: "6081",
  94027: "6081",
  94028: "6081",
  94030: "6081",
  94037: "6081",
  94038: "6081",
  94040: "6085",
  94041: "6085",
  94043: "6085",
  94044: "6081",
  94060: "6081",
  94061: "6081",
  94062: "6081",
  94063: "6081",
  94065: "6081",
  94066: "6081",
  94070: "6081",
  94080: "6081",
  94085: "6085",
  94086: "6085",
  94087: "6085",
  94089: "6085",
  94102: "6075",
  94103: "6075",
  94104: "6075",
  94105: "6075",
  94107: "6075",
  94108: "6075",
  94109: "6075",
  94110: "6075",
  94111: "6075",
  94112: "6075",
  94114: "6075",
  94115: "6075",
  94116: "6075",
  94117: "6075",
  94118: "6075",
  94121: "6075",
  94122: "6075",
  94123: "6075",
  94124: "6075",
  94127: "6075",
  94129: "6075",
  94131: "6075",
  94132: "6075",
  94133: "6075",
  94134: "6075",
  94158: "6075",
  94301: "6085",
  94303: "6081",
  94304: "6085",
  94305: "6085",
  94306: "6085",
  94401: "6081",
  94402: "6081",
  94403: "6081",
  94404: "6081",
  94501: "6001",
  94502: "6001",
  94503: "6055",
  94505: "6013",
  94506: "6013",
  94507: "6013",
  94508: "6055",
  94509: "6013",
  94510: "6095",
  94511: "6013",
  94513: "6013",
  94514: "6013",
  94515: "6055",
  94517: "6013",
  94518: "6013",
  94519: "6013",
  94520: "6013",
  94521: "6013",
  94523: "6013",
  94525: "6013",
  94526: "6013",
  94528: "6013",
  94530: "6013",
  94531: "6013",
  94533: "6095",
  94534: "6095",
  94536: "6001",
  94538: "6001",
  94539: "6001",
  94541: "6001",
  94542: "6001",
  94544: "6001",
  94545: "6001",
  94546: "6001",
  94547: "6013",
  94549: "6013",
  94550: "6001",
  94551: "6001",
  94552: "6001",
  94553: "6013",
  94555: "6001",
  94556: "6013",
  94558: "6055",
  94559: "6055",
  94560: "6001",
  94561: "6013",
  94563: "6013",
  94564: "6013",
  94565: "6013",
  94566: "6001",
  94567: "6055",
  94568: "6001",
  94569: "6013",
  94571: "6095",
  94572: "6013",
  94574: "6055",
  94577: "6001",
  94578: "6001",
  94579: "6001",
  94580: "6001",
  94582: "6013",
  94583: "6013",
  94585: "6095",
  94586: "6001",
  94587: "6001",
  94588: "6001",
  94589: "6095",
  94590: "6095",
  94591: "6095",
  94592: "6095",
  94595: "6013",
  94596: "6013",
  94597: "6013",
  94598: "6013",
  94599: "6055",
  94601: "6001",
  94602: "6001",
  94603: "6001",
  94605: "6001",
  94606: "6001",
  94607: "6001",
  94608: "6001",
  94609: "6001",
  94610: "6001",
  94611: "6001",
  94612: "6001",
  94618: "6001",
  94619: "6001",
  94621: "6001",
  94702: "6001",
  94703: "6001",
  94704: "6001",
  94705: "6001",
  94706: "6001",
  94707: "6001",
  94708: "6001",
  94709: "6001",
  94710: "6001",
  94801: "6013",
  94803: "6013",
  94804: "6013",
  94805: "6013",
  94806: "6013",
  94901: "6041",
  94903: "6041",
  94904: "6041",
  94920: "6041",
  94923: "6097",
  94924: "6041",
  94925: "6041",
  94928: "6097",
  94929: "6041",
  94930: "6041",
  94931: "6097",
  94933: "6041",
  94937: "6041",
  94938: "6041",
  94939: "6041",
  94940: "6041",
  94941: "6041",
  94945: "6041",
  94946: "6041",
  94947: "6041",
  94949: "6041",
  94951: "6097",
  94952: "6097",
  94954: "6097",
  94956: "6041",
  94957: "6041",
  94960: "6041",
  94963: "6041",
  94965: "6041",
  94970: "6041",
  94971: "6041",
  94973: "6041",
  95002: "6085",
  95003: "6087",
  95004: "6069",
  95005: "6087",
  95006: "6087",
  95007: "6087",
  95008: "6085",
  95010: "6087",
  95012: "6053",
  95014: "6085",
  95017: "6087",
  95018: "6087",
  95019: "6087",
  95020: "6085",
  95023: "6069",
  95030: "6085",
  95032: "6085",
  95033: "6085",
  95035: "6085",
  95037: "6085",
  95039: "6053",
  95041: "6087",
  95045: "6069",
  95046: "6085",
  95050: "6085",
  95051: "6085",
  95054: "6085",
  95060: "6087",
  95062: "6087",
  95064: "6087",
  95065: "6087",
  95066: "6087",
  95070: "6085",
  95073: "6087",
  95076: "6087",
  95110: "6085",
  95111: "6085",
  95112: "6085",
  95113: "6085",
  95116: "6085",
  95117: "6085",
  95118: "6085",
  95119: "6085",
  95120: "6085",
  95121: "6085",
  95122: "6085",
  95123: "6085",
  95124: "6085",
  95125: "6085",
  95126: "6085",
  95127: "6085",
  95128: "6085",
  95129: "6085",
  95130: "6085",
  95131: "6085",
  95132: "6085",
  95133: "6085",
  95134: "6085",
  95135: "6085",
  95136: "6085",
  95138: "6085",
  95139: "6085",
  95140: "6085",
  95148: "6085",
  95202: "6077",
  95203: "6077",
  95204: "6077",
  95205: "6077",
  95206: "6077",
  95207: "6077",
  95209: "6077",
  95210: "6077",
  95212: "6077",
  95215: "6077",
  95219: "6077",
  95220: "6077",
  95222: "6009",
  95223: "6009",
  95224: "6009",
  95225: "6009",
  95227: "6077",
  95228: "6009",
  95230: "6099",
  95231: "6077",
  95232: "6009",
  95233: "6009",
  95236: "6077",
  95237: "6077",
  95240: "6077",
  95242: "6077",
  95245: "6009",
  95246: "6009",
  95247: "6009",
  95248: "6009",
  95249: "6009",
  95251: "6009",
  95252: "6009",
  95254: "6009",
  95255: "6009",
  95257: "6009",
  95258: "6077",
  95301: "6047",
  95303: "6047",
  95304: "6077",
  95306: "6043",
  95307: "6099",
  95310: "6109",
  95311: "6043",
  95313: "6099",
  95315: "6047",
  95316: "6099",
  95319: "6099",
  95320: "6077",
  95321: "6109",
  95322: "6047",
  95323: "6099",
  95324: "6047",
  95326: "6099",
  95327: "6109",
  95328: "6099",
  95329: "6099",
  95330: "6077",
  95333: "6047",
  95334: "6047",
  95335: "6109",
  95336: "6077",
  95337: "6077",
  95338: "6043",
  95340: "6047",
  95341: "6047",
  95345: "6043",
  95346: "6109",
  95348: "6047",
  95350: "6099",
  95351: "6099",
  95354: "6099",
  95355: "6099",
  95356: "6099",
  95357: "6099",
  95358: "6099",
  95360: "6099",
  95361: "6099",
  95363: "6099",
  95365: "6047",
  95366: "6077",
  95367: "6099",
  95368: "6099",
  95369: "6047",
  95370: "6109",
  95372: "6109",
  95374: "6047",
  95375: "6109",
  95376: "6077",
  95377: "6077",
  95379: "6109",
  95380: "6099",
  95382: "6099",
  95383: "6109",
  95386: "6099",
  95388: "6047",
  95389: "6043",
  95391: "6001",
  95401: "6097",
  95403: "6097",
  95404: "6097",
  95405: "6097",
  95407: "6097",
  95409: "6097",
  95410: "6045",
  95412: "6097",
  95419: "6097",
  95421: "6097",
  95422: "6033",
  95423: "6033",
  95424: "6033",
  95425: "6097",
  95426: "6033",
  95428: "6045",
  95433: "6097",
  95436: "6097",
  95437: "6045",
  95439: "6097",
  95441: "6097",
  95442: "6097",
  95443: "6033",
  95444: "6097",
  95445: "6045",
  95446: "6097",
  95448: "6097",
  95449: "6045",
  95450: "6097",
  95451: "6033",
  95452: "6097",
  95453: "6033",
  95454: "6045",
  95456: "6045",
  95457: "6033",
  95458: "6033",
  95459: "6045",
  95460: "6045",
  95461: "6033",
  95462: "6097",
  95464: "6033",
  95465: "6097",
  95467: "6033",
  95469: "6045",
  95470: "6045",
  95472: "6097",
  95476: "6097",
  95482: "6045",
  95485: "6033",
  95490: "6045",
  95492: "6097",
  95497: "6097",
  95501: "6023",
  95503: "6023",
  95519: "6023",
  95521: "6023",
  95524: "6023",
  95525: "6023",
  95526: "6023",
  95527: "6023",
  95528: "6023",
  95531: "6015",
  95536: "6023",
  95540: "6023",
  95542: "6023",
  95543: "6015",
  95547: "6023",
  95549: "6023",
  95551: "6023",
  95552: "6105",
  95553: "6023",
  95560: "6023",
  95562: "6023",
  95563: "6105",
  95567: "6015",
  95570: "6023",
  95573: "6023",
  95589: "6023",
  95601: "6005",
  95602: "6061",
  95603: "6061",
  95605: "6113",
  95607: "6113",
  95608: "6067",
  95610: "6067",
  95612: "6113",
  95614: "6017",
  95615: "6067",
  95616: "6113",
  95618: "6113",
  95619: "6017",
  95620: "6095",
  95621: "6067",
  95623: "6017",
  95624: "6067",
  95626: "6067",
  95627: "6113",
  95628: "6067",
  95629: "6005",
  95630: "6067",
  95631: "6061",
  95632: "6067",
  95633: "6017",
  95634: "6017",
  95635: "6017",
  95636: "6017",
  95637: "6113",
  95638: "6067",
  95640: "6005",
  95641: "6067",
  95642: "6005",
  95645: "6113",
  95648: "6061",
  95650: "6061",
  95651: "6017",
  95653: "6113",
  95655: "6067",
  95658: "6061",
  95659: "6101",
  95660: "6067",
  95661: "6061",
  95662: "6067",
  95663: "6061",
  95664: "6017",
  95665: "6005",
  95666: "6005",
  95667: "6017",
  95668: "6061",
  95669: "6005",
  95670: "6067",
  95672: "6017",
  95673: "6067",
  95674: "6101",
  95675: "6005",
  95677: "6061",
  95678: "6061",
  95681: "6061",
  95682: "6017",
  95683: "6067",
  95684: "6017",
  95685: "6005",
  95686: "6077",
  95687: "6095",
  95688: "6095",
  95689: "6005",
  95690: "6067",
  95691: "6113",
  95692: "6115",
  95693: "6067",
  95694: "6113",
  95695: "6113",
  95697: "6113",
  95701: "6061",
  95703: "6061",
  95709: "6017",
  95713: "6061",
  95714: "6061",
  95715: "6061",
  95720: "6017",
  95721: "6017",
  95722: "6061",
  95724: "6057",
  95726: "6017",
  95728: "6057",
  95735: "6017",
  95742: "6067",
  95746: "6061",
  95747: "6061",
  95757: "6067",
  95758: "6067",
  95762: "6017",
  95765: "6061",
  95776: "6113",
  95811: "6067",
  95814: "6067",
  95815: "6067",
  95816: "6067",
  95817: "6067",
  95818: "6067",
  95819: "6067",
  95820: "6067",
  95821: "6067",
  95822: "6067",
  95823: "6067",
  95824: "6067",
  95825: "6067",
  95826: "6067",
  95827: "6067",
  95828: "6067",
  95829: "6067",
  95830: "6067",
  95831: "6067",
  95832: "6067",
  95833: "6067",
  95834: "6067",
  95835: "6067",
  95837: "6067",
  95838: "6067",
  95841: "6067",
  95842: "6067",
  95843: "6067",
  95864: "6067",
  95901: "6115",
  95912: "6011",
  95914: "6007",
  95916: "6007",
  95917: "6007",
  95918: "6115",
  95919: "6115",
  95922: "6115",
  95923: "6063",
  95925: "6115",
  95926: "6007",
  95928: "6007",
  95930: "6063",
  95932: "6011",
  95934: "6063",
  95935: "6115",
  95936: "6091",
  95937: "6113",
  95938: "6007",
  95939: "6021",
  95941: "6007",
  95942: "6007",
  95943: "6021",
  95945: "6057",
  95946: "6057",
  95947: "6063",
  95948: "6007",
  95949: "6057",
  95950: "6011",
  95951: "6021",
  95953: "6101",
  95954: "6007",
  95955: "6011",
  95956: "6063",
  95957: "6101",
  95959: "6057",
  95960: "6057",
  95961: "6115",
  95962: "6115",
  95963: "6021",
  95965: "6007",
  95966: "6007",
  95968: "6007",
  95969: "6007",
  95970: "6021",
  95971: "6063",
  95973: "6007",
  95975: "6057",
  95977: "6057",
  95978: "6007",
  95979: "6011",
  95981: "6063",
  95982: "6101",
  95983: "6063",
  95987: "6011",
  95988: "6021",
  95991: "6101",
  95993: "6101",
  96001: "6089",
  96002: "6089",
  96003: "6089",
  96006: "6035",
  96007: "6089",
  96008: "6089",
  96009: "6035",
  96013: "6089",
  96015: "6049",
  96016: "6089",
  96019: "6089",
  96020: "6063",
  96021: "6103",
  96022: "6103",
  96023: "6093",
  96024: "6105",
  96025: "6093",
  96027: "6093",
  96028: "6089",
  96032: "6093",
  96033: "6089",
  96034: "6093",
  96035: "6103",
  96037: "6093",
  96038: "6093",
  96039: "6093",
  96041: "6105",
  96044: "6093",
  96047: "6089",
  96048: "6105",
  96050: "6093",
  96051: "6089",
  96052: "6105",
  96054: "6049",
  96055: "6103",
  96056: "6035",
  96057: "6093",
  96058: "6093",
  96059: "6103",
  96062: "6089",
  96064: "6093",
  96065: "6089",
  96067: "6093",
  96069: "6089",
  96071: "6089",
  96073: "6089",
  96075: "6103",
  96080: "6103",
  96084: "6089",
  96086: "6093",
  96087: "6089",
  96088: "6089",
  96091: "6105",
  96093: "6105",
  96094: "6093",
  96096: "6089",
  96097: "6093",
  96101: "6049",
  96103: "6063",
  96104: "6049",
  96105: "6035",
  96106: "6063",
  96107: "6051",
  96108: "6049",
  96109: "6035",
  96113: "6035",
  96114: "6035",
  96115: "6049",
  96117: "6035",
  96118: "6091",
  96120: "6003",
  96121: "6035",
  96122: "6063",
  96124: "6091",
  96125: "6091",
  96126: "6091",
  96128: "6035",
  96129: "6063",
  96130: "6035",
  96134: "6049",
  96137: "6063",
  96140: "6061",
  96141: "6061",
  96142: "6017",
  96143: "6061",
  96145: "6061",
  96146: "6061",
  96148: "6061",
  96150: "6017",
  96161: "6057",
  96701: "15003",
  96703: "15007",
  96704: "15001",
  96705: "15007",
  96706: "15003",
  96707: "15003",
  96708: "15009",
  96710: "15001",
  96712: "15003",
  96713: "15009",
  96714: "15007",
  96716: "15007",
  96717: "15003",
  96719: "15001",
  96720: "15001",
  96722: "15007",
  96725: "15001",
  96727: "15001",
  96728: "15001",
  96729: "15009",
  96730: "15003",
  96731: "15003",
  96732: "15009",
  96734: "15003",
  96738: "15001",
  96740: "15001",
  96741: "15007",
  96743: "15001",
  96744: "15003",
  96746: "15007",
  96748: "15009",
  96749: "15001",
  96750: "15001",
  96752: "15007",
  96753: "15009",
  96754: "15007",
  96755: "15001",
  96756: "15007",
  96757: "15009",
  96760: "15001",
  96761: "15009",
  96762: "15003",
  96763: "15009",
  96764: "15001",
  96765: "15007",
  96766: "15007",
  96768: "15009",
  96770: "15009",
  96771: "15001",
  96772: "15001",
  96776: "15001",
  96777: "15001",
  96778: "15001",
  96779: "15009",
  96780: "15001",
  96781: "15001",
  96782: "15003",
  96783: "15001",
  96785: "15001",
  96786: "15003",
  96789: "15003",
  96790: "15009",
  96791: "15003",
  96792: "15003",
  96793: "15009",
  96795: "15003",
  96796: "15007",
  96797: "15003",
  96813: "15003",
  96814: "15003",
  96815: "15003",
  96816: "15003",
  96817: "15003",
  96818: "15003",
  96819: "15003",
  96821: "15003",
  96822: "15003",
  96825: "15003",
  96826: "15003",
  97002: "41047",
  97003: "41067",
  97004: "41005",
  97005: "41067",
  97006: "41067",
  97007: "41067",
  97008: "41067",
  97009: "41005",
  97011: "41005",
  97013: "41005",
  97014: "41027",
  97015: "41005",
  97016: "41009",
  97017: "41005",
  97018: "41009",
  97019: "41051",
  97020: "41047",
  97021: "41065",
  97022: "41005",
  97023: "41005",
  97024: "41051",
  97026: "41047",
  97027: "41005",
  97028: "41005",
  97030: "41051",
  97031: "41027",
  97032: "41047",
  97034: "41005",
  97035: "41005",
  97037: "41065",
  97038: "41005",
  97040: "41065",
  97041: "41027",
  97042: "41005",
  97045: "41005",
  97048: "41009",
  97049: "41005",
  97051: "41009",
  97053: "41009",
  97054: "41009",
  97055: "41005",
  97056: "41009",
  97058: "41065",
  97060: "41051",
  97062: "41067",
  97063: "41065",
  97064: "41009",
  97067: "41005",
  97068: "41005",
  97070: "41005",
  97071: "41047",
  97078: "41067",
  97080: "41051",
  97086: "41005",
  97089: "41005",
  97101: "41071",
  97102: "41007",
  97103: "41007",
  97106: "41067",
  97107: "41057",
  97108: "41057",
  97110: "41007",
  97111: "41071",
  97112: "41057",
  97113: "41067",
  97114: "41071",
  97115: "41071",
  97116: "41067",
  97117: "41067",
  97118: "41057",
  97119: "41067",
  97121: "41007",
  97122: "41057",
  97123: "41067",
  97124: "41067",
  97127: "41071",
  97128: "41071",
  97130: "41057",
  97131: "41057",
  97132: "41071",
  97133: "41067",
  97134: "41057",
  97135: "41057",
  97136: "41057",
  97137: "41047",
  97138: "41007",
  97140: "41067",
  97141: "41057",
  97146: "41007",
  97147: "41057",
  97148: "41071",
  97149: "41057",
  97201: "41051",
  97202: "41051",
  97203: "41051",
  97205: "41051",
  97206: "41051",
  97209: "41051",
  97210: "41051",
  97211: "41051",
  97212: "41051",
  97213: "41051",
  97214: "41051",
  97215: "41051",
  97216: "41051",
  97217: "41051",
  97218: "41051",
  97219: "41051",
  97220: "41051",
  97221: "41051",
  97222: "41005",
  97223: "41067",
  97224: "41067",
  97225: "41067",
  97227: "41051",
  97229: "41067",
  97230: "41051",
  97231: "41051",
  97232: "41051",
  97233: "41051",
  97236: "41051",
  97239: "41051",
  97266: "41051",
  97267: "41005",
  97301: "41047",
  97302: "41047",
  97303: "41047",
  97304: "41053",
  97305: "41047",
  97306: "41047",
  97317: "41047",
  97321: "41003",
  97322: "41043",
  97324: "41003",
  97325: "41047",
  97326: "41041",
  97327: "41043",
  97330: "41003",
  97333: "41003",
  97338: "41053",
  97341: "41041",
  97342: "41043",
  97343: "41041",
  97344: "41053",
  97345: "41043",
  97346: "41043",
  97347: "41071",
  97348: "41043",
  97351: "41053",
  97352: "41047",
  97355: "41043",
  97357: "41041",
  97358: "41047",
  97360: "41043",
  97361: "41053",
  97362: "41047",
  97364: "41041",
  97365: "41041",
  97366: "41041",
  97367: "41041",
  97368: "41041",
  97369: "41041",
  97370: "41003",
  97371: "41053",
  97374: "41043",
  97375: "41005",
  97376: "41041",
  97377: "41043",
  97378: "41071",
  97380: "41041",
  97381: "41047",
  97383: "41047",
  97385: "41047",
  97386: "41043",
  97388: "41041",
  97389: "41043",
  97390: "41041",
  97391: "41041",
  97392: "41047",
  97394: "41041",
  97396: "41071",
  97401: "41039",
  97402: "41039",
  97403: "41039",
  97404: "41039",
  97405: "41039",
  97408: "41039",
  97411: "41011",
  97412: "41039",
  97413: "41039",
  97415: "41015",
  97417: "41019",
  97419: "41039",
  97420: "41011",
  97423: "41011",
  97424: "41039",
  97426: "41039",
  97431: "41039",
  97432: "41019",
  97434: "41039",
  97435: "41019",
  97436: "41019",
  97437: "41039",
  97438: "41039",
  97439: "41039",
  97441: "41019",
  97442: "41019",
  97443: "41019",
  97444: "41015",
  97446: "41043",
  97448: "41039",
  97449: "41011",
  97450: "41015",
  97452: "41039",
  97453: "41039",
  97454: "41039",
  97455: "41039",
  97456: "41003",
  97457: "41019",
  97458: "41011",
  97459: "41011",
  97461: "41039",
  97462: "41019",
  97463: "41039",
  97465: "41015",
  97466: "41011",
  97467: "41019",
  97469: "41019",
  97470: "41019",
  97471: "41019",
  97476: "41015",
  97477: "41039",
  97478: "41039",
  97479: "41019",
  97480: "41039",
  97487: "41039",
  97488: "41039",
  97489: "41039",
  97492: "41039",
  97493: "41039",
  97495: "41019",
  97496: "41019",
  97497: "41033",
  97498: "41041",
  97499: "41019",
  97501: "41029",
  97502: "41029",
  97503: "41029",
  97504: "41029",
  97520: "41029",
  97522: "41029",
  97523: "41033",
  97524: "41029",
  97525: "41029",
  97526: "41033",
  97527: "41033",
  97530: "41029",
  97532: "41033",
  97535: "41029",
  97536: "41029",
  97537: "41029",
  97538: "41033",
  97539: "41029",
  97540: "41029",
  97541: "41029",
  97543: "41033",
  97544: "41033",
  97601: "41035",
  97603: "41035",
  97621: "41035",
  97622: "41035",
  97623: "41035",
  97624: "41035",
  97627: "41035",
  97630: "41037",
  97632: "41035",
  97633: "41035",
  97634: "41035",
  97639: "41035",
  97641: "41037",
  97701: "41017",
  97702: "41017",
  97703: "41017",
  97707: "41017",
  97720: "41025",
  97730: "41031",
  97733: "41035",
  97734: "41031",
  97737: "41035",
  97738: "41025",
  97739: "41017",
  97741: "41031",
  97750: "41069",
  97753: "41013",
  97754: "41013",
  97756: "41017",
  97759: "41017",
  97760: "41017",
  97801: "41059",
  97810: "41059",
  97812: "41021",
  97813: "41059",
  97814: "41001",
  97818: "41049",
  97820: "41023",
  97823: "41021",
  97824: "41061",
  97826: "41059",
  97827: "41061",
  97828: "41063",
  97830: "41069",
  97834: "41001",
  97836: "41049",
  97838: "41059",
  97842: "41063",
  97844: "41049",
  97845: "41023",
  97846: "41063",
  97850: "41061",
  97857: "41063",
  97862: "41059",
  97865: "41023",
  97867: "41061",
  97868: "41059",
  97869: "41023",
  97870: "41001",
  97873: "41023",
  97874: "41069",
  97875: "41059",
  97876: "41061",
  97877: "41023",
  97882: "41059",
  97883: "41061",
  97885: "41063",
  97886: "41059",
  97901: "41045",
  97907: "41001",
  97913: "41045",
  97914: "41045",
  97918: "41045",
  98001: "53033",
  98002: "53033",
  98003: "53033",
  98004: "53033",
  98005: "53033",
  98006: "53033",
  98007: "53033",
  98008: "53033",
  98010: "53033",
  98011: "53033",
  98012: "53061",
  98014: "53033",
  98019: "53033",
  98020: "53061",
  98021: "53061",
  98022: "53033",
  98023: "53033",
  98024: "53033",
  98026: "53061",
  98027: "53033",
  98028: "53033",
  98029: "53033",
  98030: "53033",
  98031: "53033",
  98032: "53033",
  98033: "53033",
  98034: "53033",
  98036: "53061",
  98037: "53061",
  98038: "53033",
  98039: "53033",
  98040: "53033",
  98042: "53033",
  98043: "53061",
  98045: "53033",
  98047: "53033",
  98050: "53033",
  98051: "53033",
  98052: "53033",
  98053: "53033",
  98055: "53033",
  98056: "53033",
  98057: "53033",
  98058: "53033",
  98059: "53033",
  98065: "53033",
  98068: "53033",
  98070: "53033",
  98072: "53033",
  98074: "53033",
  98075: "53033",
  98077: "53033",
  98087: "53061",
  98092: "53033",
  98101: "53033",
  98102: "53033",
  98103: "53033",
  98104: "53033",
  98105: "53033",
  98106: "53033",
  98107: "53033",
  98108: "53033",
  98109: "53033",
  98110: "53035",
  98112: "53033",
  98115: "53033",
  98116: "53033",
  98117: "53033",
  98118: "53033",
  98119: "53033",
  98121: "53033",
  98122: "53033",
  98125: "53033",
  98126: "53033",
  98133: "53033",
  98134: "53033",
  98136: "53033",
  98144: "53033",
  98146: "53033",
  98148: "53033",
  98155: "53033",
  98164: "53033",
  98166: "53033",
  98168: "53033",
  98177: "53033",
  98178: "53033",
  98188: "53033",
  98198: "53033",
  98199: "53033",
  98201: "53061",
  98203: "53061",
  98204: "53061",
  98206: "53061",
  98208: "53061",
  98220: "53073",
  98221: "53057",
  98222: "53055",
  98223: "53061",
  98224: "53033",
  98225: "53073",
  98226: "53073",
  98229: "53073",
  98230: "53073",
  98232: "53057",
  98233: "53057",
  98236: "53029",
  98237: "53057",
  98239: "53029",
  98240: "53073",
  98241: "53061",
  98244: "53073",
  98245: "53055",
  98247: "53073",
  98248: "53073",
  98249: "53029",
  98250: "53055",
  98251: "53061",
  98252: "53061",
  98253: "53029",
  98256: "53061",
  98257: "53057",
  98258: "53061",
  98260: "53029",
  98261: "53055",
  98262: "53073",
  98264: "53073",
  98266: "53073",
  98267: "53057",
  98270: "53061",
  98271: "53061",
  98272: "53061",
  98273: "53057",
  98274: "53057",
  98275: "53061",
  98276: "53073",
  98277: "53029",
  98279: "53055",
  98280: "53055",
  98281: "53073",
  98282: "53029",
  98283: "53073",
  98284: "53057",
  98286: "53055",
  98288: "53033",
  98290: "53061",
  98292: "53061",
  98294: "53061",
  98295: "53073",
  98296: "53061",
  98303: "53053",
  98304: "53041",
  98310: "53035",
  98311: "53035",
  98312: "53035",
  98320: "53031",
  98321: "53053",
  98323: "53053",
  98325: "53031",
  98326: "53009",
  98327: "53053",
  98328: "53053",
  98329: "53053",
  98331: "53009",
  98332: "53053",
  98333: "53053",
  98335: "53053",
  98337: "53035",
  98338: "53053",
  98339: "53031",
  98340: "53035",
  98342: "53035",
  98345: "53035",
  98346: "53035",
  98349: "53053",
  98351: "53053",
  98354: "53053",
  98356: "53041",
  98358: "53031",
  98359: "53035",
  98360: "53053",
  98361: "53041",
  98362: "53009",
  98363: "53009",
  98365: "53031",
  98366: "53035",
  98367: "53035",
  98368: "53031",
  98370: "53035",
  98371: "53053",
  98372: "53053",
  98373: "53053",
  98374: "53053",
  98375: "53053",
  98376: "53031",
  98377: "53041",
  98380: "53035",
  98382: "53009",
  98383: "53035",
  98385: "53053",
  98387: "53053",
  98388: "53053",
  98390: "53053",
  98391: "53053",
  98392: "53035",
  98394: "53053",
  98396: "53053",
  98402: "53053",
  98403: "53053",
  98404: "53053",
  98405: "53053",
  98406: "53053",
  98407: "53053",
  98408: "53053",
  98409: "53053",
  98418: "53053",
  98422: "53053",
  98424: "53053",
  98443: "53053",
  98444: "53053",
  98445: "53053",
  98446: "53053",
  98465: "53053",
  98466: "53053",
  98467: "53053",
  98498: "53053",
  98499: "53053",
  98501: "53067",
  98502: "53067",
  98503: "53067",
  98506: "53067",
  98512: "53067",
  98513: "53067",
  98516: "53067",
  98520: "53027",
  98524: "53045",
  98528: "53045",
  98530: "53067",
  98531: "53041",
  98532: "53041",
  98535: "53027",
  98537: "53027",
  98541: "53027",
  98546: "53045",
  98547: "53049",
  98548: "53045",
  98550: "53027",
  98555: "53045",
  98557: "53027",
  98563: "53027",
  98564: "53041",
  98568: "53027",
  98569: "53027",
  98570: "53041",
  98571: "53027",
  98572: "53041",
  98576: "53067",
  98577: "53049",
  98579: "53067",
  98580: "53053",
  98581: "53015",
  98584: "53045",
  98585: "53041",
  98586: "53049",
  98588: "53045",
  98589: "53067",
  98590: "53049",
  98591: "53041",
  98592: "53045",
  98593: "53041",
  98595: "53027",
  98596: "53041",
  98597: "53067",
  98601: "53011",
  98602: "53039",
  98603: "53015",
  98604: "53011",
  98605: "53059",
  98606: "53011",
  98607: "53011",
  98610: "53059",
  98611: "53015",
  98612: "53069",
  98613: "53039",
  98614: "53049",
  98616: "53015",
  98617: "53039",
  98619: "53039",
  98620: "53039",
  98624: "53049",
  98625: "53015",
  98626: "53015",
  98628: "53039",
  98629: "53011",
  98631: "53049",
  98632: "53015",
  98635: "53039",
  98638: "53049",
  98639: "53059",
  98640: "53049",
  98642: "53011",
  98644: "53049",
  98645: "53015",
  98648: "53059",
  98649: "53015",
  98650: "53039",
  98651: "53059",
  98660: "53011",
  98661: "53011",
  98662: "53011",
  98663: "53011",
  98664: "53011",
  98665: "53011",
  98671: "53011",
  98672: "53039",
  98673: "53039",
  98674: "53015",
  98675: "53011",
  98682: "53011",
  98683: "53011",
  98684: "53011",
  98685: "53011",
  98686: "53011",
  98801: "53007",
  98802: "53017",
  98812: "53047",
  98813: "53017",
  98814: "53047",
  98815: "53007",
  98816: "53007",
  98822: "53007",
  98823: "53025",
  98826: "53007",
  98827: "53047",
  98828: "53007",
  98829: "53047",
  98830: "53017",
  98831: "53007",
  98833: "53047",
  98834: "53047",
  98837: "53025",
  98840: "53047",
  98841: "53047",
  98843: "53017",
  98844: "53047",
  98846: "53047",
  98847: "53007",
  98848: "53025",
  98849: "53047",
  98850: "53017",
  98851: "53025",
  98855: "53047",
  98856: "53047",
  98857: "53025",
  98858: "53017",
  98859: "53047",
  98862: "53047",
  98901: "53077",
  98902: "53077",
  98903: "53077",
  98908: "53077",
  98922: "53037",
  98923: "53077",
  98925: "53037",
  98926: "53037",
  98930: "53077",
  98932: "53077",
  98933: "53077",
  98934: "53037",
  98935: "53077",
  98936: "53077",
  98937: "53077",
  98938: "53077",
  98940: "53037",
  98941: "53037",
  98942: "53077",
  98943: "53037",
  98944: "53077",
  98946: "53037",
  98947: "53077",
  98948: "53077",
  98951: "53077",
  98952: "53077",
  98953: "53077",
  99001: "53063",
  99003: "53063",
  99004: "53063",
  99005: "53063",
  99006: "53063",
  99009: "53063",
  99012: "53063",
  99013: "53065",
  99016: "53063",
  99018: "53063",
  99019: "53063",
  99021: "53063",
  99022: "53063",
  99023: "53063",
  99025: "53063",
  99026: "53063",
  99027: "53063",
  99029: "53063",
  99030: "53063",
  99031: "53063",
  99033: "53075",
  99036: "53063",
  99037: "53063",
  99101: "53065",
  99109: "53065",
  99110: "53065",
  99111: "53075",
  99113: "53075",
  99114: "53065",
  99115: "53025",
  99116: "53047",
  99118: "53019",
  99119: "53051",
  99122: "53043",
  99123: "53025",
  99125: "53075",
  99126: "53065",
  99128: "53075",
  99130: "53075",
  99133: "53025",
  99135: "53025",
  99138: "53019",
  99139: "53051",
  99141: "53019",
  99143: "53075",
  99147: "53019",
  99148: "53065",
  99149: "53075",
  99150: "53019",
  99153: "53051",
  99156: "53051",
  99157: "53065",
  99158: "53075",
  99159: "53043",
  99161: "53075",
  99163: "53075",
  99166: "53019",
  99167: "53065",
  99169: "53001",
  99170: "53075",
  99171: "53075",
  99173: "53065",
  99179: "53075",
  99181: "53065",
  99185: "53043",
  99201: "53063",
  99202: "53063",
  99203: "53063",
  99204: "53063",
  99205: "53063",
  99206: "53063",
  99207: "53063",
  99208: "53063",
  99212: "53063",
  99216: "53063",
  99217: "53063",
  99218: "53063",
  99223: "53063",
  99224: "53063",
  99301: "53021",
  99320: "53005",
  99323: "53071",
  99324: "53071",
  99326: "53021",
  99328: "53013",
  99330: "53021",
  99336: "53005",
  99337: "53005",
  99338: "53005",
  99341: "53001",
  99343: "53021",
  99344: "53001",
  99347: "53023",
  99348: "53071",
  99349: "53025",
  99350: "53005",
  99352: "53005",
  99353: "53005",
  99354: "53005",
  99357: "53025",
  99360: "53071",
  99361: "53071",
  99362: "53071",
  99371: "53001",
  99402: "53003",
  99403: "53003",
  99501: "2020",
  99502: "2020",
  99503: "2020",
  99504: "2020",
  99507: "2020",
  99508: "2020",
  99515: "2020",
  99516: "2020",
  99517: "2020",
  99518: "2020",
  99540: "2020",
  99556: "2122",
  99567: "2020",
  99568: "2122",
  99572: "2122",
  99574: "2261",
  99577: "2020",
  99587: "2020",
  99603: "2122",
  99605: "2122",
  99610: "2122",
  99611: "2122",
  99615: "2150",
  99623: "2170",
  99631: "2122",
  99639: "2122",
  99645: "2170",
  99654: "2170",
  99663: "2122",
  99664: "2122",
  99669: "2122",
  99672: "2122",
  99674: "2170",
  99676: "2170",
  99686: "2261",
  99688: "2170",
  99701: "2090",
  99705: "2090",
  99709: "2090",
  99712: "2090",
  99714: "2090",
  99721: "2185",
  99723: "2185",
  99737: "2240",
  99747: "2185",
  99766: "2185",
  99782: "2185",
  99801: "2110",
  99824: "2110",
  99827: "2100",
  99833: "2195",
  99835: "2220",
  99901: "2130",
  99929: "2275",
};

// Generate an array of years from 1990 to the current year
export function generateYearsArray() {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = 1990; year <= currentYear; year++) {
        years.push({ label: String(year), value: String(year) }); // Each option should have label and value
    }
    years.push({ label: 'Select Year', value: '' });
    return years.reverse();
}

// Generate an array of months with names and last day as the value
export function generateMonthsWithLastDayArray() {
    const monthsWithLastDay = [
        { name: 'January' },
        { name: 'February' },
        { name: 'March' },
        { name: 'April' },
        { name: 'May' },
        { name: 'June' },
        { name: 'July' },
        { name: 'August' },
        { name: 'September' },
        { name: 'October' },
        { name: 'November' },
        { name: 'December' }
    ];

    const monthsOptions = monthsWithLastDay.map((month, index) => {
        const lastDay = new Date(2024, index + 1, 0); // Get the last day of the month
        const value = `${String(lastDay.getMonth() + 1).padStart(2, '0')}-${String(lastDay.getDate()).padStart(2, '0')}`;
        return { label: month.name, value }; // Return the correct format
    });
    monthsOptions.push({ label: 'Select Month', value: '' });
    return monthsOptions.reverse(); // Return the formatted months array
}

