// Dependencies
import { Button, Input, Space, Tooltip } from "antd";
import { useRouter } from "next/router";
import { posthog } from "posthog-js";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { Flex } from "./Flex";
import { Modal } from "./Modal";
import { Text } from "./Text";

// Constants
const MODAL_STYLES = {
  background: "white",
  maxWidth: 411,
  minHeight: 200,
  width: "95%",
} as React.CSSProperties;

export default function ShareProgress({
  params,
  showModal,
  setShowModal,
}: {
  params;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const [url, setUrl] = useState("");
  const router = useRouter();

  useEffect(() => {
    const sanitizedParams = Object.keys(params).reduce((prevValue, key) => {
      if (params[key]) {
        prevValue[key] = params[key];
      }
      return prevValue;
    }, {});
    const queryParams = new URLSearchParams(sanitizedParams).toString();
    setUrl(
      `${process.env.NEXT_PUBLIC_APP_URL}${router.pathname}?${queryParams}`,
    );
  }, [params]);

  const { width } = useWindowSize();
  return (
    <>
      {/* <div
        css={(theme) => ({
          position: 'absolute',
          bottom: 240,
          right: 10,
          height: 32,
          width: 32,
          borderRadius: theme.radii.small,
          backgroundColor: theme.colors.white,
          zIndex: 100,
          cursor: 'pointer',
        })}
        onClick={() => {
          setShowModal(true)}}
      >
        <Flex
          align='center'
          justify='center'
          css={(theme) => ({
            height: '100%',
            width: '100%',

            ':hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
          })}
        >
          <Image src={'/share.svg'} alt='Share' width={20} height={20} />
        </Flex>
      </div> */}

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        // style={MODAL_STYLES}
        contentStyle={MODAL_STYLES}
        ariaHideApp={false}
      >
        <Flex direction="column" justify="center" align="center">
          <Text
            align="center"
            css={(theme) => ({
              fontWeight: theme.fontWeights.heading,
              fontSize: theme.fontSizes.heading1 + 2,
            })}
          >
            Share
          </Text>
          <Text
            align="center"
            css={(theme) => ({
              fontWeight: theme.fontWeights.medium,
              fontSize: theme.fontSizes.medium,
            })}
          >
            Share with your friends
          </Text>
          <Space.Compact
            css={() => ({ marginTop: 10 })}
            block
            direction={width < 430 ? "vertical" : "horizontal"}
          >
            <Input
              css={(theme) => ({
                padding: theme.padding.medium,
                fontSize: theme.fontSizes.small,
                borderRadius: theme.radii.small,
                height: 48,
              })}
              disabled
              value={url}
              defaultValue={`${process.env.NEXT_PUBLIC_APP_URL}/`}
            />
            <Tooltip
              title="Copied!"
              color={"green"}
              trigger="click"
              zIndex={9999}
            >
              <Button
                css={(theme) => ({
                  height: 48,
                  background: theme.colors.primary,
                  borderRadius: theme.radii.small,
                })}
                type="primary"
                onClick={() => {
                  posthog.capture("Share", { platform: "Web" });
                  navigator.clipboard.writeText(url);
                }}
              >
                Copy Link
              </Button>
            </Tooltip>
          </Space.Compact>
        </Flex>
      </Modal>
    </>
  );
}
